import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ContentBlock, ContentState } from 'draft-js'

export const PLACEHOLDER = 'PLACEHOLDER'
export const DYNAMIC_TEXT_MARKER = `class="${PLACEHOLDER}"`

const findPlaceholderEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === PLACEHOLDER
    )
  }, callback)
}

const useStyles = makeStyles(
  (theme) => ({
    placeholder: {
      color: theme.colors.link,
    },
  }),
  { name: 'Placeholder' },
)

export interface PlaceholderProps {
  children: React.ReactElement
}

const Placeholder = ({ children }: PlaceholderProps) => {
  const classes = useStyles()

  return <span className={classes.placeholder}>{children}</span>
}

export default {
  strategy: findPlaceholderEntities,
  component: Placeholder,
}
