import React from 'react'
import { TooltipProps as MuiTooltipProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  TextWithTooltip,
  TextWithTooltipProps,
} from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { useCurrentBusinessTimezoneWarning } from '~/utils/useCurrentBusinessTimezone'

const useStyles = makeStyles(
  (theme) => ({
    infoIcon: {
      color: theme.colors.alertWarning,
      fontSize: '1.5rem',
    },
    tooltip: {
      borderColor: theme.colors.alertWarning,
      '&::before': {
        borderTopColor: theme.colors.alertWarning,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'TimezoneWarningLabel' },
)

export interface TimezoneWarningLabelProps extends TextWithTooltipProps {
  TooltipProps?: Partial<MuiTooltipProps>
  classes?: ClassesType<typeof useStyles>
  getChangeTimezoneLink?: (id: string | undefined) => string
}

const TimezoneWarningLabel = ({
  classes: classesProp,
  TooltipProps,
  getChangeTimezoneLink,
  ...rest
}: TimezoneWarningLabelProps) => {
  const classes = useStyles({ classes: classesProp })

  const tooltipText: React.ReactNode = useCurrentBusinessTimezoneWarning({
    getChangeTimezoneLink,
  })

  return (
    <TextWithTooltip
      Icon={WarnAlert}
      TooltipProps={{
        ...(TooltipProps || {}),
        classes: {
          tooltip: classes.tooltip,
          ...TooltipProps?.classes,
        },
      }}
      classes={{
        infoIcon: classes.infoIcon,
      }}
      iconPlacement="left"
      tooltipText={tooltipText}
      {...rest}
    />
  )
}

export default TimezoneWarningLabel
