import React from 'react'
import { makeStyles } from '@mui/styles'
import { Nil } from '@pbt/pbt-ui-components'

import OrderListItem from '~/components/dashboard/soap/order/OrderListItem'
import { Order, ReminderOrderListHandlers } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    orderListItem: {
      maxWidth: ({ includePrice }: UseStylesProps) =>
        includePrice ? '80%' : '100%',
      marginLeft: theme.spacing(-2),
    },
  }),
  { name: 'ReminderOrderListItem' },
)

export interface ReminderOrderListItemProps extends ReminderOrderListHandlers {
  clientId: string | Nil
  disabled?: boolean
  includePrice: boolean
  orderItem: Order
  patientId: string
}

type UseStylesProps = {
  includePrice: boolean
}

const ReminderOrderListItem = ({
  clientId,
  disabled,
  includePrice,
  isCheckedItem,
  onCheckItem,
  onUncheckItem,
  orderItem,
  patientId,
}: ReminderOrderListItemProps) => {
  const classes = useStyles({ includePrice })

  if (!clientId) {
    return null
  }

  return (
    <OrderListItem
      showLabelState
      PriceTextProps={{ variant: 'body2' }}
      classes={{ itemContainer: classes.orderListItem }}
      clientId={clientId}
      includePrice={includePrice}
      isCheckedItem={isCheckedItem}
      isDisabled={disabled}
      order={orderItem}
      patientId={patientId}
      onCheckItem={onCheckItem}
      onUncheckItem={onUncheckItem}
    />
  )
}

export default ReminderOrderListItem
