import React from 'react'
import { Trans } from 'react-i18next'

import Link from '~/components/common/link/Link'

const Vaccines = () => (
  <Trans
    components={{
      linkToInventories: <Link to="/admin/catalog/inventories" />,
      linkToProcedures: <Link to="/admin/catalog/procedures" />,
    }}
    i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.VACCINES.TEXT"
  />
)
export default Vaccines
