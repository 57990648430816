import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import {
  AlertIconType,
  BasePuiDialogProps,
  Nil,
  PuiAlert,
  Text,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  fetchVariations,
  getIsLoading,
  getTotalCount,
  getVariationsList,
  searchVariations,
} from '~/store/duck/onHandCatalog'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import OnHandCatalogPrintProvider from './OnHandCatalogPrintProvider'

interface OnHandCatalogPrintDialogProps extends BasePuiDialogProps {
  activeColumns: string[]
  search: string | Nil
  showPrompt?: boolean
}

const OnHandCatalogPrintDialog = ({
  open,
  onClose,
  activeColumns,
  search,
  showPrompt = true,
}: OnHandCatalogPrintDialogProps) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(getIsLoading)
  const totalCount = useSelector(getTotalCount)
  const variationsList = useSelector(getVariationsList) || []
  const [shouldPrint, setShouldPrint] = useState(!showPrompt)

  const printAfterLoaded = useCloseAfterCreation(
    () => setShouldPrint(true),
    getIsLoading,
  )

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handlePrint = () => {
    printAfterLoaded()
    if (search) {
      dispatch(searchVariations(0, totalCount, search))
    } else {
      dispatch(fetchVariations(0, totalCount))
    }
  }

  return (
    <>
      {!shouldPrint && (
        <PuiAlert
          cancelButtonText={`${i18n.t('Common:GO_BACK')}`}
          content={
            <>
              <Text align="center" mb={3} variant="body">
                {`${i18n.t(
                  'Dialogs:ON-HAND_CATALOG_PRINT_DIALOG.DESCRIPTION',
                )}`}
              </Text>
              {isLoading && <CircularProgress size={32} />}
            </>
          }
          hideCloseButton={isLoading}
          iconType={AlertIconType.WARN}
          okButtonText={`${i18n.t('Common:PRINT_ACTION')}`}
          open={open}
          onCancel={!isLoading ? handleClose : undefined}
          onClose={onClose}
          onOk={!isLoading ? handlePrint : undefined}
        />
      )}
      <OnHandCatalogPrintProvider
        activeColumns={activeColumns}
        itemsToPrint={shouldPrint ? variationsList : []}
        onPrintFinished={handleClose}
      />
    </>
  )
}

export default OnHandCatalogPrintDialog
