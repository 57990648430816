import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Defaults, useInterval } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  clearDevices,
  clearLabOrders,
  fetchDevices,
  fetchLabOrders,
  getLabOrders,
} from '~/store/duck/labOrders'
import { getLabTestsStates, getLabVendors } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import { getClientId, getPatientId, getSoapId } from '~/store/reducers/soap'
import { LabOrder } from '~/types'
import { arrayToMap } from '~/utils'
import useDialog from '~/utils/useDialog'

import { groupLabTests, LabTestGroupKeys } from './labTestUtils'

export function useGetLabOrderData() {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const LabVendors = useSelector(getLabVendors)
  const LabTestsStatesList = useSelector(getLabTestsStates)
  const orders = useSelector(getSelectedOrders)
  const labOrders = useSelector(getLabOrders)

  const [orderManagementDialogVisible, setOrderManagementDialogVisible] =
    useState(false)
  const [currentOrderVendorId, setCurrentOrderVendorId] = useState<string>()

  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )
  const [openRemoveLabOrderDialog, closeRemoveLabOrderDialog] = useDialog(
    DialogNames.REMOVE_LAB_ORDER,
  )

  const vendorsOrdersMap = arrayToMap(
    labOrders,
    ({ vendorId }) => vendorId,
    ({ orders: newOrders }) => newOrders,
  )
  const vendorsHasIntegrationMap = arrayToMap(
    labOrders,
    ({ vendorId }) => vendorId,
    ({ active }) => active,
  )
  const labTestsMap = groupLabTests(LabTestsStatesList, orders)

  const allVendorsWithOrders = R.pipe(
    R.filter(({ id: vendorId }) => Boolean(labTestsMap[vendorId])),
    R.map(({ id: vendorId }) =>
      vendorsOrdersMap[vendorId]?.length > 0
        ? vendorsOrdersMap[vendorId].map((order) => ({ ...order, vendorId }))
        : null,
    ),
    R.filter(Boolean),
    R.flatten,
  )(LabVendors) as LabOrder[]

  const labOrdersList = R.pipe(
    R.map<LabOrder, LabOrder[]>(({ orders: items }) => items),
    R.flatten,
  )(labOrders)

  const ordersMap = arrayToMap(labOrdersList, ({ id }) => id)

  const currentOrderLabTestMap =
    currentOrderVendorId && labTestsMap?.[currentOrderVendorId]
      ? { [currentOrderVendorId]: labTestsMap?.[currentOrderVendorId] }
      : labTestsMap

  const keysToPick = [
    LabTestGroupKeys.READY_FOR_ORDER,
    LabTestGroupKeys.NOT_READY_FOR_ORDER,
  ]

  const vendorsWithNewItems = Object.keys(currentOrderLabTestMap)
    .filter((vendorId) =>
      keysToPick.some(
        (key) => currentOrderLabTestMap[vendorId][key]?.length > 0,
      ),
    )
    .map((vendorId) => ({ vendorId })) as LabOrder[]

  const allLabOrders = allVendorsWithOrders.concat(vendorsWithNewItems)

  useEffect(() => {
    if (soapId) {
      dispatch(fetchLabOrders(soapId))
      dispatch(fetchDevices())
    }
    return () => {
      dispatch(clearLabOrders())
      dispatch(clearDevices())
    }
  }, [soapId])

  useInterval(() => {
    dispatch(fetchLabOrders(soapId))
  }, Defaults.LAB_ORDERS_UPDATE_INTERVAL)

  useEffect(() => {
    if (
      orderManagementDialogVisible &&
      currentOrderVendorId &&
      !labTestsMap?.[currentOrderVendorId]
    ) {
      setCurrentOrderVendorId(undefined)
      setOrderManagementDialogVisible(false)
    }
  }, [labTestsMap, currentOrderVendorId])

  const handleEdit = (orderVendorId: string) => {
    setOrderManagementDialogVisible(true)
    setCurrentOrderVendorId(orderVendorId)
  }

  const handleCancelOrder = (vendorId: string, orderId: string) => {
    openRemoveLabOrderDialog({
      vendorId,
      labOrderId: orderId,
      onClose: closeRemoveLabOrderDialog,
    })
  }

  const handlePrintLabel = (
    labOrderId: string | undefined,
    orderDate: string | undefined,
    vendorId: string,
  ) => {
    openPrintLabOrderLabelDialog({
      clientId,
      patientId,
      labOrderId,
      orderDate,
      vendorId,
    })
  }

  return {
    soapId,
    vendorsHasIntegrationMap,
    allVendorsWithOrders,
    labTestsMap,
    ordersMap,
    allLabOrders,
    currentOrderLabTestMap,
    keysToPick,
    clientId,
    patientId,
    setOrderManagementDialogVisible,
    orderManagementDialogVisible,
    setCurrentOrderVendorId,
    handleCancelOrder,
    handleEdit,
    handlePrintLabel,
  }
}
