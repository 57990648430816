import React from 'react'
import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import FeatureToggle from '~/constants/featureToggle'
import {
  RX_REQUEST_TASK_TYPE,
  VET_DIET_REQUEST_TASK_TYPE,
} from '~/constants/taskConstants'
import { getEventType, getFeatureToggle } from '~/store/reducers/constants'
import { TaskTypesType } from '~/types'

export interface TasksTypeFilterProps extends CheckboxListFilterProps {}

const filterReactiveRxFeatureTasks = ({ name }: { name: TaskTypesType }) =>
  ![RX_REQUEST_TASK_TYPE, VET_DIET_REQUEST_TASK_TYPE].includes(name)

const TasksTypeFilter = (props: TasksTypeFilterProps) => {
  const isReactiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REACTIVE_RX),
  )
  const EventType = useSelector(getEventType)
  const TaskType = Utils.findConstantByName('Task', EventType) || {}
  const TaskTypes = isReactiveRxEnabled
    ? TaskType.subTypes || []
    : (TaskType.subTypes || []).filter(filterReactiveRxFeatureTasks)

  return <CheckboxListFilter items={TaskTypes} {...props} />
}

export default TasksTypeFilter
