import { EditorState } from 'draft-js'
import { FileTemplate, Nil } from '@pbt/pbt-ui-components'

import convertToHTML from '~/components/common/inputs/rich-edit/convertToHTML'
import { createDeepEqualityComparator } from '~/utils'

export const createTemplateFromFile = ({
  raw: file,
  data,
  extension,
  name: fileName,
}: FileTemplate) => ({
  file,
  extension,
  fileName,
  data,
})

export const createTemplateFromHtml = (editorState: EditorState | Nil) => ({
  body: convertToHTML(editorState),
  extension: 'html',
  fileName: null,
  fileUrl: null,
})

export const compareDocument = createDeepEqualityComparator()
