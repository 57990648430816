import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { BasePrice, BaseSearchRequestParams, UnsavedPrice } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchProcedures = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
  categoriesIds: string | Nil,
) =>
  request(
    getAPIPath('admin/services/procedures'),
    { method: 'GET', params: { businessId, from, to, query, categoriesIds } },
    true,
    Schema.proceduresList,
  )

export const fetchProcedure = (businessId: string, procedureId: string) =>
  request(
    getAPIPath(`admin/services/procedures/${procedureId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.procedure,
  )

export const createPrice = (
  businessId: string,
  procedureId: string,
  price: UnsavedPrice,
) =>
  request(
    getAPIPath(`admin/services/procedures/${procedureId}/prices`),
    { method: 'POST', data: price, params: { businessId } },
    true,
  )

export const editPrice = (
  businessId: string,
  procedureId: string,
  price: BasePrice,
) =>
  request(
    getAPIPath(`admin/services/procedures/${procedureId}/prices/${price.id}`),
    { method: 'PUT', data: price, params: { businessId } },
    true,
  )

export const deletePrice = (
  businessId: string,
  procedureId: string,
  priceId: string,
) =>
  request(
    getAPIPath(`admin/services/procedures/${procedureId}/prices/${priceId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const searchProcedures = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('admin/services/procedures/search'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        max,
      },
    },
    true,
  )
