import React from 'react'
import { useSelector } from 'react-redux'
import {
  ConstantWithColors,
  LanguageUtils,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import AccentLabel from '~/components/common/labels/AccentLabel'
import { getInventoryShipmentItemStatus } from '~/store/reducers/constants'

export interface ShipmentItemStatusLabelProps {
  statusId: string | Nil
}

const ShipmentItemStatusLabel = ({
  statusId,
}: ShipmentItemStatusLabelProps) => {
  const InventoryShipmentItemStatus = useSelector(
    getInventoryShipmentItemStatus,
  )
  const status: ConstantWithColors =
    Utils.findById(statusId, InventoryShipmentItemStatus) || {}

  return (
    <AccentLabel
      color={status.color}
      colorVariant={status.colorVariant}
      id={status.id}
      name={LanguageUtils.getTranslatedFieldName(status)}
    />
  )
}

export default ShipmentItemStatusLabel
