import * as API from '~/api'

import catalogDuck from './duck-generators/catalog'
import createDuck from './duck-generators/createDuck'

const wellnessPlansCatalogDuck = createDuck({
  name: 'wellnessPlansCatalog',
  duck: catalogDuck,
  apiEndpoints: (types) => ({
    // TODO: swap to wellness plans endpoints when backend provides them
    // https://petabyte.atlassian.net/browse/DEV-7245
    [types.FETCH_CATALOG_FILTERS]: API.fetchRemindersCatalogFilters,
    [types.FETCH_CATALOG_ITEMS]: API.fetchRemindersCatalogItems,
    [types.FETCH_MORE_CATALOG_ITEMS]: API.fetchRemindersCatalogItems,
  }),
})

export const wellnessPlansCatalogReducer = wellnessPlansCatalogDuck.reducer
export const wellnessPlansCatalogSaga = wellnessPlansCatalogDuck.saga
export const wellnessPlansCatalogSelectors = wellnessPlansCatalogDuck.selectors
export const wellnessPlansCatalogActions = wellnessPlansCatalogDuck.actions
