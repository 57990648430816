import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CustomFieldValidatorState,
  PuiCheckbox,
  PuiDialog,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  useFields,
  Validators,
} from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import { getDocumentInputAction } from '~/store/reducers/constants'
import {
  CheckboxState,
  InternalDispalyPreference,
  internalDispalyPreferenceSelectItems,
} from '~/types'

import { validateSummarizeInOneLineField } from './utils'

const validateRhapsodyDataField = ({
  state: { mapToRhapsody, rhapsodyData },
}: CustomFieldValidatorState) => {
  if (mapToRhapsody) {
    return Validators.notEmptyFormField(rhapsodyData)
  }
  return true
}

interface AddSingleCheckboxDialogProps extends BasePuiDialogProps {
  documentElement?: CheckboxState
  onSave: (data: CheckboxState) => void
}

export const AddSingleCheckboxDialog = ({
  open,
  onClose,
  onSave,
  documentElement,
}: AddSingleCheckboxDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])

  const DocumentInputAction = useSelector(getDocumentInputAction)

  const { fields, validate, reset } = useFields([
    {
      name: 'title',
      label: t('Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.TITLE_QUESTION'),
      validators: ['required'],
      initialValue: documentElement?.label || '',
    },
    {
      name: 'checkbox',
      validators: ['required'],
      type: 'toggle',
      initialValue: false,
    },
    {
      name: 'checkboxText',
      label: t('Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.CHECKBOX'),
      validators: ['required'],
      initialValue: documentElement?.option?.name || '',
    },
    {
      name: 'howToDisplay',
      label: t(
        'Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.CHOOSE_HOW_TO_DISPLAY_IN_RHAPSODY',
      ),
      type: 'select',
      validators: ['required'],
      initialValue: documentElement?.option?.internalDisplayPreference || '',
    },
    {
      name: 'mapToRhapsody',
      label: t(
        'Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.MAP_TO_A_RHAPSODY_DATA_FIELD',
      ),
      validators: ['required'],
      type: 'toggle',
      initialValue: Boolean(documentElement?.option?.inputAction),
    },
    {
      name: 'rhapsodyData',
      label: t(
        'Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.CHOOSE_RHAPSODY_DATA_FIELD_TO_WRITE',
      ),
      type: 'select',
      initialValue: documentElement?.option?.inputAction || '',
      validators: [
        {
          validator: validateRhapsodyDataField,
          validatorName: 'required',
        },
      ],
    },
    {
      name: 'summarizeInOneLine',
      label: t(
        'Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.SUMMARIZE_ANSWER_IN_ONE_LINE',
      ),
      initialValue: documentElement?.option?.internalName || '',
      validators: [
        {
          validator: validateSummarizeInOneLineField,
          validatorName: 'required',
        },
      ],
    },
  ])

  const {
    title,
    checkbox,
    checkboxText,
    howToDisplay,
    mapToRhapsody,
    rhapsodyData,
    summarizeInOneLine,
  } = fields

  const handleAddToForm = () => {
    if (validate()) {
      onSave({
        label: title.value,
        option: {
          id: documentElement?.option.id,
          internalName: summarizeInOneLine.value,
          name: checkboxText.value,
          sequenceNumber: 0,
          internalDisplayPreference: howToDisplay.value,
          inputAction: rhapsodyData.value,
        },
      })
      reset()
      onClose?.()
    }
  }

  const showRhapsodyDataField = mapToRhapsody.value

  const showSummarizeInOneLine =
    howToDisplay.value === InternalDispalyPreference.SUMMARIZED

  return (
    <PuiDialog
      fullWidth
      actions={
        <Grid width={150}>
          <ButtonWithLoader fullWidth onClick={handleAddToForm}>
            {t('Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.ADD_TO_FORM')}
          </ButtonWithLoader>
        </Grid>
      }
      maxWidth="xl"
      open={open}
      title={t('Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.ADD_SINGLE_CHECKBOX')}
      onClose={onClose}
    >
      <Grid container p={2}>
        <Grid item xs={12}>
          <Typography.H3>{title.label}</Typography.H3>
        </Grid>
        <Grid item xs={12}>
          <PuiTextArea
            field={title}
            minRows={2}
            placeholder={t(
              'Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.TYPE_YOUR_QUESTION_HERE',
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography.H3>{checkboxText.label}</Typography.H3>
        </Grid>
        <Grid item xs="auto">
          <PuiCheckbox
            field={checkbox}
            labelProps={{ sx: { marginRight: 0 } }}
          />
        </Grid>
        <Grid item xs>
          <PuiTextArea
            field={checkboxText}
            minRows={2}
            placeholder={t(
              'Dialogs:ADD_SINGLE_CHECKBOX_DIALOG.TYPE_YOUR_LABEL_HERE',
            )}
          />
        </Grid>
        <Grid item pl={4} xs={12}>
          <FormControl margin="none" sx={{ width: 400 }}>
            <InputLabel sx={{ marginRight: 0 }}>
              {howToDisplay.label}
            </InputLabel>
            <PuiSelect
              field={howToDisplay}
              items={internalDispalyPreferenceSelectItems}
            />
          </FormControl>
          {showSummarizeInOneLine && (
            <PuiTextField
              field={summarizeInOneLine}
              label={summarizeInOneLine.label}
              sx={{ width: 400, marginY: 0, marginLeft: 2 }}
            />
          )}
        </Grid>
        <Grid item pl={4} xs={12}>
          <PuiCheckbox field={mapToRhapsody} label={mapToRhapsody.label} />
        </Grid>
        {showRhapsodyDataField && (
          <Grid item pl={4} xs={12}>
            <FormControl margin="none" sx={{ width: 400 }}>
              <InputLabel>{rhapsodyData.label}</InputLabel>
              <PuiSelect field={rhapsodyData} items={DocumentInputAction} />
            </FormControl>
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}
