export const UPDATE_PATIENTS = 'patients/UPDATE_PATIENTS'
export const CREATE_PATIENT = 'patients/CREATE_PATIENT'
export const CREATE_PATIENT_CALLBACK = 'patients/CREATE_PATIENT_CALLBACK'
export const CREATE_PATIENT_SUCCESS = 'patients/CREATE_PATIENT_SUCCESS'
export const CREATE_PATIENT_FAILURE = 'patients/CREATE_PATIENT_FAILURE'
export const EDIT_PATIENT = 'patients/EDIT_PATIENT'
export const EDIT_PATIENT_SUCCESS = 'patients/EDIT_PATIENT_SUCCESS'
export const EDIT_PATIENT_FAILURE = 'patients/EDIT_PATIENT_FAILURE'
export const UPDATE_PATIENT_PARENT = 'patients/UPDATE_PATIENT_PARENT'
export const UPDATE_PATIENT_PARENT_SUCCESS =
  'patients/UPDATE_PATIENT_PARENT_SUCCESS'
export const UPDATE_PATIENT_PARENT_FAILURE =
  'patients/UPDATE_PATIENT_PARENT_FAILURE'
export const UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT =
  'patients/UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT'
export const UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_SUCCESS =
  'patients/UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_SUCCESS'
export const UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_FAILURE =
  'patients/UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_FAILURE'
export const WS_UPDATED_PATIENT_STATUS = 'patients/WS_UPDATED_PATIENT_STATUS'

export const LINK_PATIENTS_TO_CHEWY_ACCOUNT =
  'patients/LINK_PATIENTS_TO_CHEWY_ACCOUNT'
export const UNLINK_PATIENTS_FROM_CHEWY_ACCOUNT =
  'patients/UNLINK_PATIENTS_FROM_CHEWY_ACCOUNT'
