import React from 'react'
import { useParams } from 'react-router-dom'

import i18n from '~/locales/i18n'
import {
  fetchBoardingSchedules,
  fetchSchedules,
} from '~/store/actions/scheduler'
import { getSchedules } from '~/store/reducers/scheduler'
import { getConstantFromMapOrDefault } from '~/utils'

import { TimetableContextProvider } from '../TimetableContext'
import TimetablePage from '../TimetablePage'
import { SchedulerViewType } from './schedulerViews'
import BoardingCheckInToday from './views/boarding/BoardingCheckInToday'
import BoardingCheckOutToday from './views/boarding/BoardingCheckOutToday'
import SchedulerBoardingView from './views/boarding/SchedulerBoardingView'
import SchedulerScheduleView from './views/schedule/SchedulerScheduleView'

const boardingTabs = [
  {
    label: i18n.t('Common:CHECKING_IN_TODAY'),
    component: BoardingCheckInToday,
  },
  {
    label: i18n.t('Common:CHECKING_OUT_TODAY'),
    component: BoardingCheckOutToday,
  },
]

const ComponentsMap = {
  [SchedulerViewType.BOARDING]: SchedulerBoardingView,
  [SchedulerViewType.SCHEDULE]: SchedulerScheduleView,
}

const ActionsMap = {
  [SchedulerViewType.BOARDING]: fetchBoardingSchedules,
  [SchedulerViewType.SCHEDULE]: fetchSchedules,
}

const TabsMap = {
  [SchedulerViewType.BOARDING]: boardingTabs,
  [SchedulerViewType.SCHEDULE]: undefined,
}

const SchedulerPage = (props: any) => {
  const { view: viewProp = '' } = useParams()

  const view = getConstantFromMapOrDefault(
    viewProp,
    SchedulerViewType,
    SchedulerViewType.SCHEDULE,
  )
  const Component = ComponentsMap[view]

  const fetchTimetablesAction = ActionsMap[view]

  return (
    <TimetableContextProvider>
      <TimetablePage
        fetchTimetablesAction={fetchTimetablesAction}
        schedulesSelector={getSchedules}
        tabs={TabsMap[view]}
        {...props}
      >
        <Component />
      </TimetablePage>
    </TimetableContextProvider>
  )
}

export default SchedulerPage
