import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { TimetableEvent } from '~/types'

import BoardingCheckInCard, {
  BoardingCheckInCardProps,
} from './BoardingCheckInCard'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'BoardingCheckIn' },
)

export interface BoardingCheckInProps {
  appointments: TimetableEvent[]
  buttonLabel: BoardingCheckInCardProps['buttonLabel']
  checkingIn?: boolean
  onClick: BoardingCheckInCardProps['onClick']
}

const BoardingCheckIn = ({
  buttonLabel,
  checkingIn,
  appointments: appointmentsProp,
  onClick,
}: BoardingCheckInProps) => {
  const appointments = appointmentsProp || []
  const classes = useStyles()

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      px={2}
      py={1}
      rowSpacing={1}
    >
      {appointments.map((appointment) => (
        <Grid container item flexDirection="column" key={appointment.id}>
          <BoardingCheckInCard
            appointment={appointment}
            buttonLabel={buttonLabel}
            checkingIn={checkingIn}
            key={appointment.id}
            onClick={onClick}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default BoardingCheckIn
