import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { DocumentFile, IdObject, Nil } from '@pbt/pbt-ui-components'

import AttachmentDialog, {
  AttachmentDialogProps,
} from '~/components/common/inputs/attachment/attachment-dialog/AttachmentDialog'
import { attachDocumentsToSoap } from '~/store/actions/soap'
import { createAttachmentFile, getFilesIsCreating } from '~/store/duck/files'
import { getCurrentUserId } from '~/store/reducers/auth'
import {
  getAppointmentId,
  getIsAttachingDocuments,
  getIsUploadingFile,
} from '~/store/reducers/soap'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

export interface SoapAttachmentDialogProps
  extends Omit<AttachmentDialogProps, 'onListProceed' | 'onPreviewProceed'> {
  clientId: string | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
}

const SoapAttachmentDialog = ({
  onClose,
  soapId,
  soapBusinessId,
  clientId,
  patientId,
  ...rest
}: SoapAttachmentDialogProps) => {
  const dispatch = useDispatch()
  const eventId = useSelector(getAppointmentId)
  const userId = useSelector(getCurrentUserId) as string
  const isUploading = useSelector(getIsUploadingFile)
  const isUploadingV2 = useSelector(getFilesIsCreating)

  const setCloseAfterAttachedOn = useCloseAfterCreation(
    onClose,
    getIsAttachingDocuments,
  )
  const setCloseAfterUploadedOn = useCloseAfterCreation(
    onClose,
    getFilesIsCreating,
  )

  const onListProceed = (orderedItems: IdObject[]) => {
    const ids = R.pluck('id', orderedItems)
    setCloseAfterAttachedOn()
    if (soapId) {
      dispatch(attachDocumentsToSoap(ids, soapId, soapBusinessId, userId))
    }
  }

  const onPreviewProceed = (file: DocumentFile['file']) => {
    setCloseAfterUploadedOn()
    const fileFields = R.omit(['data', 'file', 'raw'], file)
    dispatch(
      createAttachmentFile(file.raw!, fileFields, patientId, clientId, soapId),
    )
  }

  return (
    <AttachmentDialog
      eventId={eventId}
      isLoading={isUploading || isUploadingV2}
      patientId={patientId}
      onClose={onClose}
      onListProceed={onListProceed}
      onPreviewProceed={onPreviewProceed}
      {...rest}
    />
  )
}

export default SoapAttachmentDialog
