import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'

import Typography from '~/components/elements/Typography/Typography'
import { PageBreakState } from '~/types'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface PageBreakProps {
  documentElement: PageBreakState
  isDragging?: boolean
  onDelete?: () => void
}

export const PageBreak = ({
  documentElement,
  onDelete,
  isDragging,
}: PageBreakProps) => {
  const { t } = useTranslation('Common')

  return (
    <DocumentElementAccordion
      isDragging={isDragging}
      title={`Page ${documentElement.page} of ${documentElement.totalPages}`}
      onDelete={onDelete}
    >
      <Divider
        sx={{ my: 1, backgroundColor: (theme) => theme.colors.grayWhite }}
      >
        <Typography.ParagraphLg color="grayGray2">
          {t('Common:PAGE_BREAK')}
        </Typography.ParagraphLg>
      </Divider>
    </DocumentElementAccordion>
  )
}
