import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    root: {},
    text: {
      fontSize: '1.8rem',
    },
    link: {
      color: '#5B99B2',
    },
  }),
  { name: 'RegisterByInviteError' },
)

const RegisterByInviteError = () => {
  const classes = useStyles()
  const { t } = useTranslation('Registration')

  return (
    <Grid container item alignItems="center" direction="column" py={15}>
      <Text strong align="center" variant="h2">
        {t('Registration:REGISTER_BY_INVITE_ERROR.MAIN_TEXT')}
      </Text>
      <Text align="center" className={classes.text} mt={2} variant="body">
        {t('Registration:REGISTER_BY_INVITE_ERROR.TEXT')}&nbsp;
        <a className={classes.link} href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
          {Defaults.SUPPORT_EMAIL}
        </a>
      </Text>
    </Grid>
  )
}

export default RegisterByInviteError
