import React from 'react'
import * as R from 'ramda'
import {
  ConstantMap,
  Constants,
  Field,
  UnitsState,
} from '@pbt/pbt-ui-components'
import {
  TemperatureTypes,
  UnitTypes,
} from '@pbt/pbt-ui-components/src/localization'

import TableSelect from '~/components/common/inputs/TableSelect'
import TableUnitInput from '~/components/common/inputs/TableUnitInput'
import { VitalFormInputTypes } from '~/constants/vitals'
import { VitalConfigItem } from '~/types'

import TemperatureInput from './TemperatureInput'
import VitalsWeightInput from './VitalsWeightInput'

const getSelectOptions = (
  types: ConstantMap,
  formInput: VitalConfigItem['formInput'],
) => {
  const configOptions = formInput.optionsType
    ? types[formInput.optionsType as keyof Constants]
    : formInput.options

  return Array.isArray(configOptions)
    ? configOptions.map((item) =>
        R.has('id', item) ? item : { id: `${item}`, name: item },
      )
    : Object.values(configOptions || {})
}

interface VitalsFormInputProps {
  editDisabled?: boolean
  field: Field
  formInput: VitalConfigItem['formInput']
  onChangeUnits: (
    type: UnitTypes,
    value: UnitTypes | TemperatureTypes | string,
  ) => void
  types: ConstantMap
  unitsState: UnitsState
}

const VitalsFormInput = ({
  formInput,
  field,
  unitsState,
  onChangeUnits,
  types,
  editDisabled,
}: VitalsFormInputProps) => {
  const FormInputComponent = {
    [VitalFormInputTypes.SELECT]: (
      <TableSelect
        disabled={editDisabled}
        field={field}
        items={getSelectOptions(types, formInput)}
      />
    ),
    [VitalFormInputTypes.INPUT]: (
      <TableUnitInput
        disabled={editDisabled}
        field={field}
        placeholder={formInput.placeholder}
        units={formInput.units}
        unitsLabel={formInput.unitsLabel}
      />
    ),
    [VitalFormInputTypes.TEMPERATURE]: (
      <TemperatureInput
        activeUnit={unitsState[UnitTypes.TEMPERATURE]}
        disabled={editDisabled}
        field={field}
        onUnitsChange={onChangeUnits}
      />
    ),
    [VitalFormInputTypes.WEIGHT]: (
      <VitalsWeightInput
        activeUnit={unitsState[UnitTypes.WEIGHT]}
        disabled={editDisabled}
        field={field}
        onUnitsChange={onChangeUnits}
      />
    ),
  }

  return FormInputComponent[formInput?.type] || null
}

export default VitalsFormInput
