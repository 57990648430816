import { useLayoutEffect } from 'react'

const useWindowResize = (onResize: () => void) => {
  useLayoutEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
}

export default useWindowResize
