import React from 'react'
import { HtmlNotesPreview } from '@pbt/pbt-ui-components'

import { ImportedHistoryTimelineItemEntry } from '~/types'

const css = `
  .migrated-history-table {
    border: 1px solid #EAE9E8;
    border-radius: 2px;
    border-collapse: collapse;
  }

  .migrated-history-table-head-cell {
    border: 1px solid #EAE9E8;
    font-weight: 500;
    text-align: left;
    padding: 4px 8px;
    color: #3C3838;
    font-size: 14px;
  }

  .migrated-history-table-body-cell {
    border: 1px solid #EAE9E8;
    padding: 4px 8px;
    color: #3C3838;
    font-size: 14px;
  }
`
interface ImportedHistoryPreviewProps {
  importedHistory: ImportedHistoryTimelineItemEntry
}

const ImportedHistoryPreview = ({
  importedHistory,
}: ImportedHistoryPreviewProps) => (
  <HtmlNotesPreview css={css} notes={importedHistory.details} />
)

export default ImportedHistoryPreview
