import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Constant, IdObject, Nil, Utils } from '@pbt/pbt-ui-components'

// @ts-ignore
import SelectableListWithFilters from '~/components/dashboard/soap/list-with-filters/SelectableListWithFilters'
import { fetchDocumentsByType } from '~/store/actions/documents'
import { getDocumentTypes } from '~/store/reducers/constants'
import { getIsAttachingDocuments as getIsAttachingConversationDocuments } from '~/store/reducers/conversationMessages'
import {
  getDocumentsIsReceiving,
  getDocumentsTypedList,
  getMultipleDocuments,
} from '~/store/reducers/documents'
import { getIsAttachingDocuments } from '~/store/reducers/soap'
import { Document, FetchOrdersOptions } from '~/types'

const useStyles = makeStyles(
  () => ({
    selectableListContainer: {
      padding: 0,
    },
  }),
  { name: 'AttachmentListWithFilters' },
)

export interface AttachmentListWithFiltersProps {
  DetailsComponent?: React.JSXElementConstructor<any>
  ListItemProps?: any
  clientId?: string | Nil
  documentTypes?: Constant[]
  eventId?: string | Nil
  isLoading?: boolean
  label?: string | Nil
  onChange?: (items: IdObject[]) => void
  onProceed: (items: IdObject[]) => void
  patientId?: string | Nil
  skipFileTemplates?: boolean
}

const AttachmentListWithFilters = ({
  documentTypes: documentTypesProp,
  clientId,
  patientId,
  eventId,
  skipFileTemplates,
  label,
  ...rest
}: AttachmentListWithFiltersProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isReceiving = useSelector(getDocumentsIsReceiving)
  const isAttachingDocuments = useSelector(getIsAttachingDocuments)
  const isAttachingConversationDocuments = useSelector(
    getIsAttachingConversationDocuments,
  )
  const DocumentTypes: Constant[] = useSelector(getDocumentTypes)
  const list = useSelector(getDocumentsTypedList)
  const documents: Document[] = useSelector(getMultipleDocuments(list))
  const { t } = useTranslation(['Common', 'Dialogs'])

  const filters = (documentTypesProp || DocumentTypes).map(
    ({ id, ...filter }) => ({ ...filter, type: id }),
  )

  const [activeFilter, setActiveFilter] = useState<string | Constant | Nil>(
    filters[0].type,
  )
  const [activeSearchTerm, setActiveSearchTerm] = useState<string>()

  const loadNewItems = (options?: FetchOrdersOptions | Nil) => {
    const { entityType, searchTerm } = options || {}
    setActiveSearchTerm(searchTerm)
    setActiveFilter(entityType)
    dispatch(
      fetchDocumentsByType(entityType, patientId, eventId, skipFileTemplates),
    )
  }

  const filterAndSortDocuments = useCallback(
    R.pipe(
      R.filter(
        (document: Document) => !activeFilter || document.type === activeFilter,
      ),
      R.sortBy(R.compose(R.toLower, ({ name }) => name)),
    ),
    [activeFilter],
  )

  const prepareSearchDocumentResults = useCallback(
    R.pipe(
      R.filter(({ name }) =>
        activeSearchTerm
          ? Utils.matchSubstring(activeSearchTerm, name || '')
          : true,
      ),
      R.map((item) => ({ item })),
    ),
    [activeSearchTerm],
  )

  const preparedDocuments = filterAndSortDocuments(documents)
  const preparedSearchDocuments = activeSearchTerm
    ? prepareSearchDocumentResults(preparedDocuments)
    : []

  return (
    <SelectableListWithFilters
      classes={{
        container: classes.selectableListContainer,
      }}
      filters={filters}
      isLoading={
        isReceiving || isAttachingDocuments || isAttachingConversationDocuments
      }
      isReceivingListItems={isReceiving}
      items={preparedDocuments}
      label={label}
      loadNewItems={loadNewItems}
      proceedButtonLabel={t('Common:ADD_ACTION')}
      searchItems={loadNewItems}
      searchPlaceholder={t(
        'Dialogs:ATTACHMENT_DIALOG.SEARCH_PRACTICE_DOCUMENTS',
      )}
      searchResults={preparedSearchDocuments}
      {...rest}
    />
  )
}

export default AttachmentListWithFilters
