import React from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BackButton, NumberUtils, Text } from '@pbt/pbt-ui-components'
import { DefaultPrices } from '@pbt/pbt-ui-components/src/localization'

import PosPayTerminalOnTable from '~/components/common/images/PosPayTerminalOnTable.png'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import { RhapsodyPayConfig } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 3, 1, 3),
    },
    text: {
      lineHeight: '2.2rem',
    },
    link: {
      display: 'inline-flex',
      fontSize: '1.6rem',
      textDecoration: 'underline',
      color: theme.colors.link,
      cursor: 'pointer',
    },
    terminalOrderingWrapper: {
      boxShadow: theme.constants.blockShadow,
      borderRadius: 5,
    },
    button: {
      width: 240,
      height: 40,
      margin: theme.spacing(2, 0),
    },
    terminalOnTable: {
      height: 250,
    },
  }),
  { name: 'RhapsodyPayTerminalOrdering' },
)

interface RhapsodyPayTerminalOrderingProps {
  onBack: () => void
  onProceed: () => void
  onTerminalsQuantityUpdate: (value: number) => void
  posPayConfig: RhapsodyPayConfig
}

const RhapsodyPayTerminalOrdering = ({
  onBack,
  onProceed,
  onTerminalsQuantityUpdate,
  posPayConfig,
}: RhapsodyPayTerminalOrderingProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses', 'Prices'])

  return (
    <Grid container className={classes.root} direction="column">
      <Grid container item direction="column">
        <Text align="left" className={classes.text} variant="body">
          {t('Businesses:RHAPSODY_PAY.SMALL_LIGHTWEIGHT_MOBILE_TERMINAL')}
        </Text>
        <Text variant="body2">
          {t('Businesses:RHAPSODY_PAY.GIVE_YOUR_CLIENTS_PRIVACY')}
        </Text>
        <Link
          className={classes.link}
          href="https://www.paxtechnology.com/a60"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('Businesses:RHAPSODY_PAY.LEARN_MORE_ABOUT_PAX_A60')}
        </Link>
      </Grid>
      <Grid
        container
        item
        className={classes.terminalOrderingWrapper}
        mb={7}
        mt={2}
      >
        <Grid container item md={6}>
          <img
            alt="TerminalOnTable"
            className={classes.terminalOnTable}
            src={PosPayTerminalOnTable}
          />
        </Grid>
        <Grid container item direction="column" md={6} p={3}>
          <Text align="left" className={classes.text} variant="body">
            {t(
              'Prices:CURRENCY.YOU_WILL_NEED_AT_LEAST_ONE_TERMINAL_TO_GET_STARTED',
              {
                amount: NumberUtils.formatMoney(DefaultPrices.TERMINAL_COST),
              },
            )}
          </Text>
          <Text strong my={2} variant="subheading3">
            {t('Businesses:RHAPSODY_PAY.HOW_MANY_DEVICES_WOULD_YOU_LIKE')}
          </Text>
          <QuantityInput
            showControls
            min={0}
            value={posPayConfig.deviceCount}
            onChange={onTerminalsQuantityUpdate}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justifyContent="space-between">
        <Grid item>
          <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
        </Grid>
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={onProceed}
        >
          {t('Common:CONTINUE_ACTION')}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default RhapsodyPayTerminalOrdering
