import { ApiError, TableFilter } from '@pbt/pbt-ui-components'

import { QueryBusinessAppointmentTypesArgs } from '~/api/graphql/generated/types'
import { BusinessAppointmentType } from '~/types/entities/businessAppointmentType'

import {
  CLEAR_APPOINTMENT_TYPE_ERROR,
  CREATE_APPOINTMENT_TYPE,
  CREATE_APPOINTMENT_TYPE_FAILURE,
  CREATE_APPOINTMENT_TYPE_SUCCESS,
  FETCH_APPOINTMENT_TYPES_LIST,
  FETCH_APPOINTMENT_TYPES_LIST_FAILURE,
  FETCH_APPOINTMENT_TYPES_LIST_SUCCESS,
  FETCH_MORE_APPOINTMENT_TYPES_LIST,
  FETCH_MORE_APPOINTMENT_TYPES_LIST_FAILURE,
  FETCH_MORE_APPOINTMENT_TYPES_LIST_SUCCESS,
  SET_APPOINTMENT_TYPES_LIST_FILTERS,
  SET_APPOINTMENT_TYPES_LIST_SORTINGS,
  UPDATE_APPOINTMENT_TYPE,
  UPDATE_APPOINTMENT_TYPE_FAILURE,
  UPDATE_APPOINTMENT_TYPE_SUCCESS,
  UPDATE_APPOINTMENT_TYPES,
} from './types/appointmentTypes'

export const updateAppointmentTypes = (
  appointmentTypes: Record<string, Partial<BusinessAppointmentType>>,
) => ({
  type: UPDATE_APPOINTMENT_TYPES,
  appointmentTypes,
})

export const fetchAppointmentTypesList = ({
  businessId,
  searchFilter,
}: QueryBusinessAppointmentTypesArgs) => ({
  type: FETCH_APPOINTMENT_TYPES_LIST,
  businessId,
  searchFilter,
})
export const fetchAppointmentTypesListSuccess = (
  data: string[],
  totalCount: number,
) => ({
  type: FETCH_APPOINTMENT_TYPES_LIST_SUCCESS,
  list: data,
  totalCount,
})
export const fetchAppointmentTypesListFailure = (error: ApiError) => ({
  type: FETCH_APPOINTMENT_TYPES_LIST_FAILURE,
  error,
})

// Fetching more types in scope for RHAP-7 but may be used later
export const fetchMoreAppointmentTypesList = (
  from: number,
  to: number,
  filters: {
    color?: string[]
    enabled?: boolean
    eventTypeIds?: string[]
    onlineSchedulingAllowed?: boolean
    orderOnlyByNameAscending?: boolean
  },
) => ({
  type: FETCH_MORE_APPOINTMENT_TYPES_LIST,
  from,
  to,
  filters,
})
export const fetchMoreAppointmentTypesListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_APPOINTMENT_TYPES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreAppointmentTypesListFailure = (error: ApiError) => ({
  type: FETCH_MORE_APPOINTMENT_TYPES_LIST_FAILURE,
  error,
})

export const createAppointmentType = (
  input: Omit<BusinessAppointmentType, 'id'>,
) => ({
  type: CREATE_APPOINTMENT_TYPE,
  input,
})
export const createAppointmentTypeSuccess = (appointmentTypeId: string) => ({
  type: CREATE_APPOINTMENT_TYPE_SUCCESS,
  appointmentTypeId,
})
export const createAppointmentTypeFailure = (error: ApiError) => ({
  type: CREATE_APPOINTMENT_TYPE_FAILURE,
  error,
})

export const updateAppointmentType = (
  id: string,
  input: Omit<BusinessAppointmentType, 'id' | 'eventTypeId' | 'name'>,
) => ({
  type: UPDATE_APPOINTMENT_TYPE,
  id,
  input,
})
export const updateAppointmentTypeSuccess = (appointmentTypeId: string) => ({
  type: UPDATE_APPOINTMENT_TYPE_SUCCESS,
  appointmentTypeId,
})
export const updateAppointmentTypeFailure = (error: ApiError) => ({
  type: UPDATE_APPOINTMENT_TYPE_FAILURE,
  error,
})

export const setAppointmentTypesListFilters = (
  filters: Record<string, TableFilter>,
) => ({
  type: SET_APPOINTMENT_TYPES_LIST_FILTERS,
  filters,
})
export const setAppointmentTypesListSortings = (
  sortings: Record<string, boolean | undefined>,
) => ({
  type: SET_APPOINTMENT_TYPES_LIST_SORTINGS,
  sortings,
})
export const clearAppointmentTypesError = () => ({
  type: CLEAR_APPOINTMENT_TYPE_ERROR,
})
