import React from 'react'
import { useTranslation } from 'react-i18next'
import { moment } from '@pbt/pbt-ui-components'

import { MessageType, MessageWay } from '~/constants/communications'
import { ChatMessage } from '~/types'

import MessageCard, {
  MessageCardProps,
} from '../conversations-chat/message/MessageCard'

export interface LogPhoneCallPreviewProps
  extends Omit<MessageCardProps, 'message' | 'title'> {
  template: string
}

const LogPhoneCallPreview = ({
  template,
  ...rest
}: LogPhoneCallPreviewProps) => {
  const { t } = useTranslation(['Communications'])

  const title = t('Communications:MESSAGE_CARD.INTERNAL_NOTE_TITLE')

  const message = {
    content: template || '<p></p>',
    type: MessageType.INTERNAL_NOTE,
    way: MessageWay.OUTBOUND,
    sendingDate: moment().toISOString(),
  } as ChatMessage

  return (
    <MessageCard
      hideStatusIcons
      isCurrentUserMessage
      message={message}
      title={title}
      {...rest}
    />
  )
}

export default LogPhoneCallPreview
