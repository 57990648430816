import React, { MouseEvent, useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Menu, MenuItem } from '@mui/material'
import { ButtonWithLoader, ButtonWithLoaderProps } from '@pbt/pbt-ui-components'

export interface ButtonWithMenuProps extends ButtonWithLoaderProps {
  buttonText: string
  horizontalPosition?: 'left' | 'center' | 'right'
  name: string
  onSelectOption: (action: string) => void
  optionsDisplayMap?: { [key: string]: string }
  optionsValueMap: { [key: string]: string }
  verticalPosition?: 'top' | 'bottom'
}

export const ButtonWithMenu = ({
  buttonText,
  name,
  onSelectOption,
  optionsValueMap,
  optionsDisplayMap,
  verticalPosition = 'top',
  horizontalPosition = 'center',
  ...props
}: ButtonWithMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (action: string) => {
    onSelectOption(action)
    handleClose()
  }

  const menuId = `${name}-button-menu`
  const buttonId = `${name}-button`

  return (
    <>
      <ButtonWithLoader
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        id={buttonId}
        onClick={handleClick}
        {...props}
      >
        {buttonText}
      </ButtonWithLoader>
      <Menu
        MenuListProps={{ 'aria-labelledby': buttonId }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition,
        }}
        id={menuId}
        open={open}
        transformOrigin={{
          vertical: verticalPosition === 'top' ? 'bottom' : 'top',
          horizontal: horizontalPosition,
        }}
        onClose={handleClose}
      >
        {Object.values(optionsValueMap).map((key) => (
          <MenuItem key={key} onClick={() => handleSelect(key)}>
            {optionsDisplayMap?.[key] ?? key}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
