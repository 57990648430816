import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Nil, PuiTextField, useFields, Utils } from '@pbt/pbt-ui-components'

import TaskRecurrenceSettings, {
  TaskRecurrenceSettingsHandle,
} from '~/components/dashboard/tasks-dashboard/TaskRecurrenceSettings'
import { useTaskEvent } from '~/store/hooks/tasks'
import {
  getDrugAdministrationFrequencies,
  getTaskTimeUnits,
  getTimeOffsets,
} from '~/store/reducers/constants'
import { getAppointmentId, getClientId, getSoapId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { DataHandle, Task } from '~/types'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const AdminFrequencyToTaskFrequencyMap: Record<
  string,
  { amount: number; unit: string }
> = {
  Hourly: { amount: 1, unit: 'Hour' },
  'Every 2 hours': { amount: 2, unit: 'Hour' },
  'Every 4 hours': { amount: 4, unit: 'Hour' },
  'Every 6 hours': { amount: 6, unit: 'Hour' },
  'Every 8 hours': { amount: 8, unit: 'Hour' },
  'Every 12 hours': { amount: 12, unit: 'Hour' },
  'Every 24 hours': { amount: 24, unit: 'Hour' },
  'Every other day': { amount: 2, unit: 'Day' },
  'Once per week': { amount: 1, unit: 'Week' },
  'Every 30 days': { amount: 30, unit: 'Day' },
  'Once per month': { amount: 1, unit: 'Month' },
  'Every 90 days': { amount: 90, unit: 'Day' },
}

const useConvertAdminFrequencyToTaskFrequency = (adminFrequencyId?: string) => {
  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const TaskTimeUnits = useSelector(getTaskTimeUnits)
  const TimeOffsets = useSelector(getTimeOffsets)

  const adminFrequencyName = Utils.getConstantName(
    adminFrequencyId,
    DrugAdministrationFrequencies,
  )
  const taskFrequency = AdminFrequencyToTaskFrequencyMap[adminFrequencyName]

  if (!taskFrequency) {
    return {
      recurrenceModeId: null,
      recurrencePeriod: null,
    }
  }

  return {
    recurrenceModeId: Utils.findConstantIdByName('Custom', TimeOffsets),
    recurrencePeriod: {
      amount: taskFrequency.amount,
      unit: Utils.findConstantIdByName(taskFrequency.unit, TaskTimeUnits),
    },
  }
}

export interface TaskSectionProps {
  adminFrequencyId?: string
  clientId: string
  name: string | Nil
  onRefChange?: () => void
  orderType: string
  patientId: string
  recurrenceRequired?: boolean
  task: Task | Nil
}

export interface TaskSectionHandle extends DataHandle<Task> {}

const TaskSection = forwardRef<TaskSectionHandle, TaskSectionProps>(
  function TaskSection(
    {
      adminFrequencyId,
      clientId,
      name: nameProp,
      onRefChange,
      orderType,
      patientId,
      recurrenceRequired,
      task,
    },
    ref,
  ) {
    const { t } = useTranslation('Tasks')

    const soapId = useSelector(getSoapId)
    const appointmentId = useSelector(getAppointmentId)
    const soapClientId = useSelector(getClientId)
    const soapClient = useSelector(getUser(soapClientId))

    const isContextItem = useIsCurrentContextItem(task)

    const taskRecurrenceSettingsRef = useRef<TaskRecurrenceSettingsHandle>(null)

    const initialAdminFrequencyId = useMemo(() => adminFrequencyId, [])

    const TaskType = useTaskEvent()
    const TreatmentsType = Utils.findConstantIdByName(
      'Treatments',
      TaskType.subTypes,
    )

    const {
      fields: { name },
      validate,
    } = useFields(
      [
        {
          name: 'name',
          label: t('Tasks:LABEL.TASK_NAME'),
          validators: ['required'],
          initialValue: task?.name || `Administer ${nameProp}`,
        },
      ],
      false,
    )

    useEffectExceptOnMount(() => {
      onRefChange?.()
    }, [name])

    const getTask = () => ({
      ...(task || {}),
      name: name.value,
      ...taskRecurrenceSettingsRef.current?.get(),
    })

    useImperativeHandle(ref, () => ({
      validate: () =>
        validate() && (taskRecurrenceSettingsRef.current?.validate() ?? true),
      get: () =>
        ({
          ...(soapClient?.hasKyriosId
            ? { forChewyClient: true }
            : { forClient: true, forBoopClient: true }),
          typeId: TreatmentsType,
          soapId,
          appointmentId,
          clientId: soapClientId || clientId,
          assigneeId: soapClientId || clientId,
          patientId,
          orderType,
          ...getTask(),
        }) as Task,
    }))

    const initialFrequency = useConvertAdminFrequencyToTaskFrequency(
      initialAdminFrequencyId,
    )

    return (
      <Grid container>
        <Grid item xs={6}>
          <PuiTextField
            disabled={!isContextItem}
            field={name}
            inputProps={{ maxLength: 100 }}
            label={`${name.label}*`}
          />
        </Grid>

        <TaskRecurrenceSettings
          allFieldsRequired={recurrenceRequired}
          appointmentId={task?.appointment}
          initialRecurrence={initialFrequency}
          ref={taskRecurrenceSettingsRef}
          task={task}
          onRefChange={onRefChange}
        />
      </Grid>
    )
  },
)

export default TaskSection
