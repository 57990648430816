import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { TaskContext } from '~/constants/taskConstants'
import { getTaskWithChildren } from '~/store/reducers/tasks'
import { isWithinTimeInterval } from '~/utils/time'

// @ts-ignore
import TaskStateButtonDetailed from '../../soap/to-do/TaskStateButtonDetailed'
import TaskMesh, { TaskMeshProps } from './TaskMesh'

export interface TaskStateButtonMeshProps extends TaskMeshProps {
  context: TaskContext
  taskId: string
}

const TaskStateButtonMesh = forwardRef<
  HTMLDivElement,
  TaskStateButtonMeshProps
>(function TaskStateButtonMesh({ taskId, context, ...rest }, ref) {
  const tasks = useSelector(getTaskWithChildren(taskId))

  return (
    <TaskMesh ref={ref} {...rest}>
      {(column) => {
        const tasksInThisSlot = tasks.filter((task) =>
          isWithinTimeInterval(task?.dueDate, column),
        )

        return tasksInThisSlot.map((task) => (
          <TaskStateButtonDetailed
            context={context}
            key={task.id}
            task={task}
          />
        ))
      }}
    </TaskMesh>
  )
})

export default TaskStateButtonMesh
