import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, FileTemplate } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Shipment, ShipmentImportItem } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const IMPORT_SHIPMENT = 'shipments/IMPORT_SHIPMENT'
export const IMPORT_SHIPMENT_SUCCESS = 'shipments/IMPORT_SHIPMENT_SUCCESS'
export const IMPORT_SHIPMENT_FAILURE = 'shipments/IMPORT_SHIPMENT_FAILURE'

export const FETCH_MORE_SHIPMENT_IMPORT_ITEMS =
  'shipments/FETCH_MORE_SHIPMENT_IMPORT_ITEMS'
export const FETCH_MORE_SHIPMENT_IMPORT_ITEMS_SUCCESS =
  'shipments/FETCH_MORE_SHIPMENT_IMPORT_ITEMS_SUCCESS'
export const FETCH_MORE_SHIPMENT_IMPORT_ITEMS_FAILURE =
  'shipments/FETCH_MORE_SHIPMENT_IMPORT_ITEMS_FAILURE'
export const CLEAR_SHIPMENT_IMPORT_ITEMS =
  'shipments/CLEAR_SHIPMENT_IMPORT_ITEMS'

export const MATCH_SHIPMENT_IMPORT_ITEM = 'shipments/MATCH_SHIPMENT_IMPORT_ITEM'
export const MATCH_SHIPMENT_IMPORT_ITEM_SUCCESS =
  'shipments/MATCH_SHIPMENT_IMPORT_ITEM_SUCCESS'
export const MATCH_SHIPMENT_IMPORT_ITEM_FAILURE =
  'shipments/MATCH_SHIPMENT_IMPORT_ITEM_FAILURE'

export const FETCH_PRELIMINARY_SHIPMENT = 'shipments/FETCH_PRELIMINARY_SHIPMENT'
export const FETCH_PRELIMINARY_SHIPMENT_SUCCESS =
  'shipments/FETCH_PRELIMINARY_SHIPMENT_SUCCESS'
export const FETCH_PRELIMINARY_SHIPMENT_FAILURE =
  'shipments/FETCH_PRELIMINARY_SHIPMENT_FAILURE'

export const importShipment = (document: FileTemplate) => ({
  type: IMPORT_SHIPMENT,
  document,
})
export const importShipmentSuccess = (sessionId: string) => ({
  type: IMPORT_SHIPMENT_SUCCESS,
  sessionId,
})
export const importShipmentFailure = (error: ApiError) => ({
  type: IMPORT_SHIPMENT_FAILURE,
  error,
})

export const fetchMoreShipmentImportItems = (
  sessionId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_SHIPMENT_IMPORT_ITEMS,
  sessionId,
  from,
  to,
})
export const fetchMoreShipmentImportItemsSuccess = (
  list: string[],
  map: Record<string, ShipmentImportItem>,
  totalCount: number,
) => ({ type: FETCH_MORE_SHIPMENT_IMPORT_ITEMS_SUCCESS, list, map, totalCount })
export const fetchMoreShipmentImportItemsFailure = (error: ApiError) => ({
  type: FETCH_MORE_SHIPMENT_IMPORT_ITEMS_FAILURE,
  error,
})
export const clearShipmentImportItems = () => ({
  type: CLEAR_SHIPMENT_IMPORT_ITEMS,
})

export const matchShipmentImportItem = (
  sessionId: string,
  lineId: string,
  variationId: string,
) => ({
  type: MATCH_SHIPMENT_IMPORT_ITEM,
  sessionId,
  lineId,
  variationId,
})
export const matchShipmentImportItemSuccess = (line: ShipmentImportItem) => ({
  type: MATCH_SHIPMENT_IMPORT_ITEM_SUCCESS,
  line,
})
export const matchShipmentImportItemFailure = (error: ApiError) => ({
  type: MATCH_SHIPMENT_IMPORT_ITEM_FAILURE,
  error,
})

export const fetchPreliminaryShipment = (sessionId: string) => ({
  type: FETCH_PRELIMINARY_SHIPMENT,
  sessionId,
})
export const fetchPreliminaryShipmentSuccess = (shipment: Shipment) => ({
  type: FETCH_PRELIMINARY_SHIPMENT_SUCCESS,
  shipment,
})
export const fetchPreliminaryShipmentFailure = (error: ApiError) => ({
  type: FETCH_PRELIMINARY_SHIPMENT_FAILURE,
  error,
})

export type ShipmentImportState = {
  currentSessionId: string | null
  currentlyMatchingLineId: string | null
  error: string | null
  isImportInProgress: boolean
  isLoadingItems: boolean
  isLoadingShipment: boolean
  itemsList: string[]
  itemsMap: Record<string, ShipmentImportItem>
  itemsTotalCount: number
  preliminaryShipment: Shipment | null
}

const INITIAL_STATE: ShipmentImportState = {
  isLoadingItems: false,
  isLoadingShipment: false,
  itemsList: [],
  itemsMap: {},
  itemsTotalCount: 0,
  preliminaryShipment: null,
  currentlyMatchingLineId: null,
  isImportInProgress: false,
  currentSessionId: null,
  error: null,
}

export const shipmentImportReducer = (
  state: ShipmentImportState = INITIAL_STATE,
  action: AnyAction,
): ShipmentImportState => {
  switch (action.type) {
    case IMPORT_SHIPMENT: {
      return {
        ...state,
        isImportInProgress: true,
        currentSessionId: null,
      }
    }
    case IMPORT_SHIPMENT_SUCCESS: {
      return {
        ...state,
        isImportInProgress: false,
        currentSessionId: action.sessionId,
      }
    }
    case IMPORT_SHIPMENT_FAILURE: {
      return {
        ...state,
        isImportInProgress: false,
        error: getErrorMessage(action.error),
      }
    }
    case CLEAR_SHIPMENT_IMPORT_ITEMS: {
      return {
        ...state,
        currentSessionId: null,
        itemsList: [],
        itemsMap: {},
        itemsTotalCount: 0,
        preliminaryShipment: null,
      }
    }
    case FETCH_MORE_SHIPMENT_IMPORT_ITEMS: {
      return {
        ...state,
        isLoadingItems: true,
      }
    }
    case FETCH_MORE_SHIPMENT_IMPORT_ITEMS_SUCCESS: {
      return {
        ...state,
        itemsList: [...state.itemsList, ...action.list],
        itemsMap: { ...state.itemsMap, ...action.map },
        isLoadingItems: false,
        itemsTotalCount: action.totalCount,
      }
    }
    case FETCH_MORE_SHIPMENT_IMPORT_ITEMS_FAILURE: {
      return {
        ...state,
        isLoadingItems: false,
        error: getErrorMessage(action.error),
      }
    }
    case MATCH_SHIPMENT_IMPORT_ITEM: {
      return {
        ...state,
        currentlyMatchingLineId: action.lineId,
      }
    }
    case MATCH_SHIPMENT_IMPORT_ITEM_SUCCESS: {
      return {
        ...state,
        itemsMap: {
          ...state.itemsMap,
          [action.line.id]: {
            ...state.itemsMap[action.line.id],
            ...action.line,
          },
        },
        currentlyMatchingLineId: null,
      }
    }
    case MATCH_SHIPMENT_IMPORT_ITEM_FAILURE: {
      return {
        ...state,
        currentlyMatchingLineId: null,
        error: getErrorMessage(action.error),
      }
    }
    case FETCH_PRELIMINARY_SHIPMENT: {
      return {
        ...state,
        isLoadingShipment: true,
        preliminaryShipment: null,
      }
    }
    case FETCH_PRELIMINARY_SHIPMENT_SUCCESS: {
      return {
        ...state,
        isLoadingShipment: false,
        preliminaryShipment: action.shipment,
      }
    }
    case FETCH_PRELIMINARY_SHIPMENT_FAILURE: {
      return {
        ...state,
        isLoadingShipment: false,
        error: getErrorMessage(action.error),
      }
    }

    default:
      return state
  }
}

const getShipmentImport = (state: RootState): ShipmentImportState =>
  state.shipmentImport
export const getIsImportInProgress = (state: RootState) =>
  getShipmentImport(state).isImportInProgress
export const getCurrentSessionId = (state: RootState) =>
  getShipmentImport(state).currentSessionId
export const getImportItemsList = (state: RootState) =>
  getShipmentImport(state).itemsList
export const getImportItemsMap = (state: RootState) =>
  getShipmentImport(state).itemsMap
export const getMultipleImportItems = (ids: string[]) =>
  createSelector(getImportItemsMap, R.props(ids))
export const getTotalCount = (state: RootState) =>
  getShipmentImport(state).itemsTotalCount
export const getCurrentlyMatchingLineId = (state: RootState) =>
  getShipmentImport(state).currentlyMatchingLineId
export const getIsLoadingShipment = (state: RootState) =>
  getShipmentImport(state).isLoadingShipment
export const getPreliminaryShipment = (state: RootState) =>
  getShipmentImport(state).preliminaryShipment
export const getUnmatchedShipmentItems = createSelector(
  getImportItemsMap,
  (map: Record<string, ShipmentImportItem>) =>
    Object.values(map).filter((item) => !item?.resolvedVariation),
)

export function* importShipmentSaga({
  document,
}: ReturnType<typeof importShipment>) {
  try {
    const documentToSend = {
      csvFile: document.raw,
    }
    const sessionId = yield* requestAPI(API.importShipment, documentToSend)
    yield put(importShipmentSuccess(sessionId))
  } catch (error) {
    yield put(importShipmentFailure(error as ApiError))
  }
}

export function* fetchMoreShipmentImportItemsSaga({
  sessionId,
  from,
  to,
}: ReturnType<typeof fetchMoreShipmentImportItems>) {
  try {
    const {
      result: { data: list, totalCount },
      entities: { shipmentImportItem: map },
    } = yield requestAPI(API.fetchShipmentImportItems, sessionId, from, to)
    yield put(fetchMoreShipmentImportItemsSuccess(list, map, totalCount))
  } catch (error) {
    yield put(fetchMoreShipmentImportItemsFailure(error as ApiError))
  }
}

export function* matchShipmentImportItemSaga({
  sessionId,
  variationId,
  lineId,
}: ReturnType<typeof matchShipmentImportItem>) {
  try {
    const line = yield* requestAPI(
      API.matchShipmentImportItem,
      sessionId,
      variationId,
      lineId,
    )
    yield put(matchShipmentImportItemSuccess(line))
  } catch (error) {
    yield put(matchShipmentImportItemFailure(error as ApiError))
  }
}

export function* fetchPreliminaryShipmentSaga({
  sessionId,
}: ReturnType<typeof fetchPreliminaryShipment>) {
  try {
    const shipment = yield* requestAPI(API.fetchPreliminaryShipment, sessionId)
    yield put(fetchPreliminaryShipmentSuccess(shipment))
  } catch (error) {
    yield put(fetchPreliminaryShipmentFailure(error as ApiError))
  }
}

export function* shipmentImportSaga() {
  yield all([
    takeLatest(IMPORT_SHIPMENT, importShipmentSaga),
    takeLatest(
      FETCH_MORE_SHIPMENT_IMPORT_ITEMS,
      fetchMoreShipmentImportItemsSaga,
    ),
    takeLatest(MATCH_SHIPMENT_IMPORT_ITEM, matchShipmentImportItemSaga),
    takeLatest(FETCH_PRELIMINARY_SHIPMENT, fetchPreliminaryShipmentSaga),
  ])
}
