import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Nil, PermissionArea, Utils } from '@pbt/pbt-ui-components'

import { createReminder, getIsCreating } from '~/store/duck/reminders'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getReminderProtocolTimeUnits } from '~/store/reducers/constants'
import { getSoapId } from '~/store/reducers/soap'
import { Reminder, ReminderProtocol } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ReminderDetails, { ReminderDetailsHandle } from '../ReminderDetails'
import { dateOffsetToDate } from '../reminderUtils'
import AddReminderDialogActions from './AddReminderDialogActions'

export interface StepAddReminderFromProtocolDetailsProps {
  onBack?: () => void
  onClose?: () => void
  patientId: string | Nil
  protocol: ReminderProtocol | Nil
}

const StepAddReminderFromProtocolDetails = ({
  protocol: protocolProp,
  onBack,
  onClose,
  patientId,
}: StepAddReminderFromProtocolDetailsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const closeAfterCreate = useCloseAfterCreation(onClose, getIsCreating)
  const detailsRef = useRef<ReminderDetailsHandle>(null)

  const ReminderProtocolTimeUnits = useSelector(getReminderProtocolTimeUnits)
  const business = useSelector(getCurrentBusiness)
  const isCreating = useSelector(getIsCreating)
  const soapId = useSelector(getSoapId)
  const { create: remindersCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )

  const yearId = Utils.findConstantIdByName('Year', ReminderProtocolTimeUnits)

  const getReminder = (protocol: ReminderProtocol | Nil) => {
    const dueAmount = protocol?.dueDateOffset?.amount || 1
    const dueUnit =
      protocol?.dueDateOffset?.unit ||
      business?.defaultReminderExpirationOffset?.unit ||
      yearId
    const expirationDateOffsetAmount =
      protocol?.expirationDateOffset?.amount ||
      business?.defaultReminderExpirationOffset?.amount
    const expirationDateOffsetUnit =
      protocol?.expirationDateOffset?.unit || yearId

    const dueDatetime = dateOffsetToDate(
      dueAmount,
      Utils.getConstantName(dueUnit, ReminderProtocolTimeUnits),
    )

    const expireDatetime = dateOffsetToDate(
      expirationDateOffsetAmount,
      Utils.getConstantName(
        expirationDateOffsetUnit,
        ReminderProtocolTimeUnits,
      ),
      dueDatetime,
    )

    const reminder = {
      patientId,
      name: protocol?.reminderName,
      dueDatetime: dueDatetime.toISOString(),
      expireDatetime: expireDatetime.toISOString(),
      due: protocol?.due || [],
      resolvesWith: protocol?.resolveSameAsTrigger
        ? protocol?.due
        : protocol?.resolvesWith,
    } as Reminder

    if (protocol?.id) {
      reminder.protocolId = protocol.id
    }

    return reminder
  }

  const reminder = getReminder(protocolProp)

  const handleSave = () => {
    if (detailsRef.current?.validate()) {
      const editedReminder = detailsRef.current?.get()
      dispatch(createReminder(editedReminder, soapId))
      closeAfterCreate()
    }
  }

  return (
    <Grid container direction="column">
      <ReminderDetails
        disabled={!remindersCreatePermissions}
        ref={detailsRef}
        reminder={reminder}
      />
      <Grid container justifyContent="space-between" p={2}>
        <AddReminderDialogActions
          isLoading={isCreating}
          isProceedDisabled={!remindersCreatePermissions}
          proceedLabel={t('Common:SAVE_ACTION')}
          onBack={onBack}
          onProceed={remindersCreatePermissions ? handleSave : () => {}}
        />
      </Grid>
    </Grid>
  )
}

export default StepAddReminderFromProtocolDetails
