import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Nil } from '@pbt/pbt-ui-components'
import {
  Download as DownloadIcon,
  Duplicate as CloneIcon,
  Email as EmailIcon,
  Eye as EyeIcon,
  Money as MoneyIcon,
  Plus as PlusIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import {
  getIsEstimateAttachedToThisSoap,
  getRecordDepositProps,
} from '~/components/dashboard/invoices/invoiceUtils'
import useCloneEstimate from '~/components/dashboard/soapV2/estimates/utils/useCloneEstimate'
import { GeneratingPdfContentKind } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import { addEstimateToSOAPOrEvent } from '~/store/actions/finance'
import {
  useDisplayRecordDeposit,
  useGetEstimateState,
} from '~/store/hooks/estimate'
import {
  useDownloadContentAsPdf,
  useGetInvoiceCompletenessFromSpain,
  useOpenInvoice,
} from '~/store/hooks/finance'
import {
  getExpandedInvoiceGroupIds,
  getFinanceInvoice,
} from '~/store/reducers/finance'
import { getIsFinalized, getSoapInvoicePosted } from '~/store/reducers/soap'
import { Estimate, ExtendPayment } from '~/types'
import useDialog from '~/utils/useDialog'

import useEstimateAlertDialog from './useEstimateAlertDialog'

interface UseEstimateItemActionsProps {
  clientId: string | Nil
  estimateId: string | Nil
  patientId: string | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
}

export const useEstimateItemActions = ({
  estimateId,
  clientId,
  patientId,
  soapId,
  soapBusinessId,
}: UseEstimateItemActionsProps) => {
  const { t } = useTranslation(['Common', 'Soap'])
  const dispatch = useDispatch()
  const estimate = useSelector(getFinanceInvoice(estimateId)) as Estimate | Nil
  const expandedGroups = useSelector(getExpandedInvoiceGroupIds)
  const navigate = useNavigate()

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [
    openPrintInvoiceDialog,
    closePrintInvoiceDialog,
    isPrintInvoiceDialogOpen,
  ] = useDialog(DialogNames.PRINT_INVOICE)
  const [
    openEmailInvoiceDialog,
    closeEmailInvoiceDialog,
    isEmailInvoiceDialogOpen,
  ] = useDialog(DialogNames.EMAIL_INVOICE)
  const [openDepositDialog] = useDialog(DialogNames.PAYMENT_DETAILS)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isSoapInvoicePosted = useSelector(getSoapInvoicePosted)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)
  const { isDraft: isStatusDraft, isApproved: isStatusApproved } =
    useGetEstimateState()(estimate?.stateId)

  const isInvoiceFromSpainCompleted =
    useGetInvoiceCompletenessFromSpain(estimate)
  const [generatePdf] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.INVOICE,
    { invoiceId: estimateId },
  )

  const { setAttachingToSoapEstimateId } = useEstimateAlertDialog({
    fromSoap: true,
    fromTimeline: false,
    soapId,
  })

  const isEstimateAttachedToThisSoap = getIsEstimateAttachedToThisSoap(
    estimate,
    soapId,
  )

  const { onCloneRequested } = useCloneEstimate({
    clientId,
    estimateId,
    patientId,
    soapBusinessId,
    soapId,
  })

  const { displayRecordDeposit, hasPermission } =
    useDisplayRecordDeposit(estimate)

  const getEstimateActions = () => {
    if (!estimateId) {
      return []
    }

    const onPreview = () => {
      openInvoice({
        clientId,
        patientId,
        invoiceId: estimateId,
        isEstimate: true,
        soapBusinessId,
        soapId,
        newEstimateFlow: true,
        setAttachingToSoapEstimateId,
      })
    }

    const onRecordDeposit = () => {
      const recordDepositDialogProps = getRecordDepositProps(estimate, true)
      openAddPaymentDialog({
        clientId,
        ComponentProps: recordDepositDialogProps,
      })
    }

    const onViewDeposit = () => {
      if (estimate?.payments?.length === 1) {
        openDepositDialog({
          clientId,
          payment: estimate.payments[0] as ExtendPayment,
        })
      } else {
        navigate(`/balance/${clientId}`, { replace: true })
      }
    }

    const onPrintInvoiceRequested = () => {
      if (isPrintInvoiceDialogOpen) {
        closePrintInvoiceDialog()
        return
      }

      openPrintInvoiceDialog({
        invoiceIds: [estimateId],
        params: {
          expandedGroups: expandedGroups.join(','),
          includeServiceFee: false,
          complete: isInvoiceFromSpainCompleted,
        },
      })
    }

    const onEmailInvoiceRequested = () => {
      if (isEmailInvoiceDialogOpen) {
        closeEmailInvoiceDialog()
        return
      }

      openEmailInvoiceDialog({
        invoiceIds: [estimateId],
        isEstimate: true,
      })
    }

    const onDownloadPdfRequested = () => {
      generatePdf({
        invoiceIds: [estimateId],
        isEstimate: true,
        params: {
          expandedGroups: expandedGroups.join(','),
          includeServiceFee: false,
          complete: isInvoiceFromSpainCompleted,
        },
      })
    }

    const onAddToSoap = () => {
      setAttachingToSoapEstimateId(estimateId)
      dispatch(
        // No need to pass allowNewSoap
        addEstimateToSOAPOrEvent({
          estimateId,
          soapId,
          eventId: null,
          copyItems: true,
          cloningOptions: {
            allowClone: false,
            checkInactiveLineItems: true,
          },
        }),
      )
    }

    return [
      {
        id: 'estm_add_to_soap_action',
        label: t('Common:ADD_TO_SOAP'),
        Icon: PlusIcon,
        onClick: onAddToSoap,
        disabled:
          isSoapFinalized ||
          isEstimateAttachedToThisSoap ||
          !isStatusApproved ||
          isSoapInvoicePosted,
      },
      {
        id: 'estm_download_action',
        label: t('Common:DOWNLOAD_ACTION'),
        Icon: DownloadIcon,
        onClick: onDownloadPdfRequested,
      },
      {
        id: 'estm_clone_action',
        label: t('Common:CLONE_ACTION'),
        Icon: CloneIcon,
        onClick: onCloneRequested,
      },
      {
        id: 'estm_email_action',
        label: t('Common:EMAIL'),
        Icon: EmailIcon,
        onClick: onEmailInvoiceRequested,
      },
      {
        id: 'estm_print_action',
        label: t('Common:PRINT_ACTION'),
        Icon: PrintIcon,
        onClick: onPrintInvoiceRequested,
      },
      ...(displayRecordDeposit
        ? [
            {
              id: 'estm_record_deposit_action',
              label: t('Common:RECORD_DEPOSIT_ACTION'),
              Icon: MoneyIcon,
              onClick: onRecordDeposit,
              disabled: !hasPermission,
            },
          ]
        : !isStatusDraft && estimate?.payments?.length
          ? [
              {
                id: 'estm_view_deposit_action',
                label: t('Common:VIEW_DEPOSIT'),
                Icon: MoneyIcon,
                onClick: onViewDeposit,
              },
            ]
          : []),
      {
        id: 'estm_view_action',
        label: t('Common:VIEW_ACTION'),
        Icon: EyeIcon,
        onClick: onPreview,
      },
    ] as PopperAction[]
  }

  const estimateActions = getEstimateActions()

  return estimateActions
}
