import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType } from '@pbt/pbt-ui-components'
import { Rhapsody } from '@pbt/pbt-ui-components/src/icons'

import Link from './link/Link'

const DEFAULT_LOGO_WIDTH = 240
const DEFAULT_LOGO_HEIGHT = 80

const useStyles = makeStyles(
  () => ({
    logo: {
      cursor: 'pointer',
      width: DEFAULT_LOGO_WIDTH,
      height: DEFAULT_LOGO_HEIGHT,
    },
    root: {},
  }),
  { name: 'RhapsodyLogo' },
)

interface RhapsodyLogoProps {
  Component?: React.JSXElementConstructor<any>
  className?: string
  classes?: ClassesType<typeof useStyles>
}

const RhapsodyLogo = ({
  Component = Rhapsody,
  className,
  ...rest
}: RhapsodyLogoProps) => {
  const classes = useStyles(rest)
  const { t } = useTranslation('Common')

  return (
    <Grid item className={classNames(classes.root, className)}>
      <Link aria-label={t('Common:RHAPSODY_SYSTEM_HOMEPAGE')} to="/">
        <Component className={classes.logo} />
      </Link>
    </Grid>
  )
}

export default RhapsodyLogo
