import React, { PropsWithChildren } from 'react'
import { Box } from '@mui/material'

import { ChewyPaymentStepper } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentsStepper/ChewyPaymentStepper'
import Typography from '~/components/elements/Typography/Typography'

type ChewyPaymentDialogHeaderProps = PropsWithChildren & {
  activeStep?: number
  showStepper?: boolean
}

export const ChewyPaymentHeader = ({
  activeStep = 0,
  children,
  showStepper = false,
}: ChewyPaymentDialogHeaderProps) => (
  <>
    <Typography.H2 as="div">{children}</Typography.H2>
    {showStepper && (
      <Box ml={-1} pt={1}>
        <ChewyPaymentStepper activeStep={activeStep} />
      </Box>
    )}
  </>
)
