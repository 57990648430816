import { MomentInput } from 'moment'
import * as R from 'ramda'
import { moment } from '@pbt/pbt-ui-components'

export enum When {
  PAST = 'PAST',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  NEXT_WEEK = 'NEXT_WEEK',
  THIS_WEEK = 'THIS_WEEK',
  NEXT_MONTH = 'NEXT_MONTH',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  FUTURE = 'FUTURE',
}

export const getTimeRelationToNow = (selected_: MomentInput) => {
  const selected = moment(selected_)
  const now = moment()

  // moment has mutating methods (e.g. add()), avoid consistent mutability
  return R.cond([
    [(a: MomentInput) => now.isAfter(a, 'day'), R.always(When.PAST)],
    [(a: MomentInput) => now.isSame(a, 'day'), R.always(When.TODAY)],
    [
      (a: MomentInput) => now.clone().add(1, 'day').isSame(a, 'day'),
      R.always(When.TOMORROW),
    ],
    [(a: MomentInput) => now.isSame(a, 'week'), R.always(When.THIS_WEEK)],
    [
      (a: MomentInput) => now.clone().add(1, 'week').isSame(a, 'week'),
      R.always(When.NEXT_WEEK),
    ],
    [(a: MomentInput) => now.isSame(a, 'month'), R.always(When.THIS_MONTH)],
    [
      (a: MomentInput) => now.clone().add(1, 'month').isSame(a, 'month'),
      R.always(When.NEXT_MONTH),
    ],
    [(a: MomentInput) => now.isSame(a, 'year'), R.always(When.THIS_YEAR)],
    [R.T, R.always(When.FUTURE)],
  ])(selected)
}
