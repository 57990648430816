import { PrescriptionTemplateFilters } from '~/types'

import { getAPIPath, request } from './core'

export const fetchPrescriptionTemplates = (
  businessId: string,
  filters: PrescriptionTemplateFilters,
) =>
  request(
    getAPIPath('prescription/defaults'),
    {
      method: 'GET',
      params: {
        businessId,
        ...filters,
      },
    },
    true,
  )
