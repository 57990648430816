import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  BreedConstant,
  LanguageUtils,
  PhoneUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import { AcquiredType, AcquiredTypeNames } from '~/constants/acquiredTypes'
import { MembershipFilters } from '~/constants/wellnessPlansConstants'
import { getClientsMembershipFilters } from '~/store/reducers/clients'
import {
  getBreed,
  getGender,
  getServiceAnimal,
  getSpecies,
  getTag,
} from '~/store/reducers/constants'
import { Filter } from '~/types'

import { boolToYesNoString, getSpeciesFromFieldsQuery } from './index'

const getLocalizedDisplayName = R.curry((list, value) => {
  const id = Utils.findConstantIdByName(value, list)
  return LanguageUtils.getConstantTranslatedName(id, list)
})

const getLocalizedAcquiredType = (value: string) =>
  AcquiredTypeNames[<AcquiredType>value] || value

const useAdvancedFiltersClientValuesMap = (
  fieldsQuery: string,
): Record<string, (value: string) => string> => {
  const membershipFilters: Filter[] = useSelector(getClientsMembershipFilters)
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)
  const BreedsMap: BreedConstant = useSelector(getBreed)
  const ServiceAnimal = useSelector(getServiceAnimal)
  const Tag = useSelector(getTag)

  const getLocalizedBreedsDisplayName = (rawBreedName: string) => {
    const breedsNames = rawBreedName.split(',')
    const { speciesId } = getSpeciesFromFieldsQuery(fieldsQuery, Species)
    const BreedsListBySpecie = BreedsMap[speciesId]
    return BreedsListBySpecie.filter((breed) =>
      breedsNames.includes(breed.name),
    )
      .map((breed) => LanguageUtils.getTranslatedFieldName(breed))
      .join(', ')
  }

  return {
    phone: PhoneUtils.getPhoneNumber,
    [MembershipFilters.WELLNESS_PLAN_LEVEL]: (value: string) =>
      membershipFilters.find((filter) => `${filter.value}` === value)?.name ||
      '',
    [MembershipFilters.MEMBERSHIP_STATUS]: (value: string) =>
      boolToYesNoString(value === 'true'),
    [MembershipFilters.WELLNESS_PLAN_STATUS]: (value: string) =>
      LanguageUtils.capitalize(value),
    [MembershipFilters.WELLNESS_PLAN_CANCELLED]: (value: string) =>
      boolToYesNoString(value === 'true'),
    [MembershipFilters.WELLNESS_PLAN_ID]: (value: string) =>
      membershipFilters.find(
        (filter) =>
          filter.param === MembershipFilters.WELLNESS_PLAN_ID &&
          `${filter.value}` === value,
      )?.name || '',
    'patients.species': getLocalizedDisplayName(Species),
    'patients.gender': getLocalizedDisplayName(Gender),
    'patients.breeds': getLocalizedBreedsDisplayName,
    'patients.serviceAnimalType': getLocalizedDisplayName(ServiceAnimal),
    'patients.acquiredType': getLocalizedAcquiredType,
    tag: getLocalizedDisplayName(Tag),
  }
}

export default useAdvancedFiltersClientValuesMap
