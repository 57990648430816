import React from 'react'
import Step, { StepProps } from '@mui/material/Step'
import StepLabel, { StepLabelProps } from '@mui/material/StepLabel'
import MuiStepper, {
  StepperProps as MuiStepperProps,
} from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'

const StyledStepper = styled(MuiStepper)`
  .MuiStepIcon-root {
    width: 22px;
    height: 22px;
    &:not(.Mui-active) {
      color: ${({ theme }) => theme.colors.inactiveBackground};
    }
    .MuiStepIcon-text {
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
  .MuiStepLabel-label {
    font-size: 1.4rem;
    &.Mui-active,
    &.Mui-completed {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondaryText};
    }
    &:not(.Mui-active) {
      color: ${({ theme }) => theme.colors.inactiveBackground};
    }
  }
  .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.colors.selectedOption};
  }
`

export type StepperProps = MuiStepperProps & {
  activeStep?: number
  alwaysShowNumbers?: boolean
  steps: Array<{
    label: string
    stepLabelProps?: StepLabelProps
    stepProps?: StepProps
  }>
}

export const Stepper = ({
  activeStep = 0,
  alwaysShowNumbers,
  steps,
  ...props
}: StepperProps) => (
  <StyledStepper activeStep={activeStep} {...props}>
    {steps.map(({ label, stepLabelProps, stepProps }) => (
      <Step
        completed={alwaysShowNumbers === true ? false : undefined}
        key={label}
        {...stepProps}
      >
        <StepLabel {...stepLabelProps}>{label}</StepLabel>
      </Step>
    ))}
  </StyledStepper>
)
