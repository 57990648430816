import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { useGetSoapQuestionsCount } from '~/store/hooks/questions'
import useWorkflowTypes from '~/utils/useWorkflowTypes'

export interface QuestionsProps {
  className?: string
}

const Questions = ({ className }: QuestionsProps) => {
  const { t } = useTranslation(['Common', 'Soap'])

  const WorkFlowTypes = useWorkflowTypes()
  const { getAnsweredQuestions, getTotalQuestions } = useGetSoapQuestionsCount()

  return (
    <Grid item className={className}>
      <Text strong pt={0.5} variant="body2">
        {t('Common:QUESTION_OTHER')}
      </Text>
      {WorkFlowTypes.map((workflow) => {
        const showWorkflow = getTotalQuestions(workflow.id) > 0

        if (!showWorkflow) {
          return null
        }

        return (
          <Text key={workflow.id} pl={1} variant="body2">
            {t('Soap:QUESTION_TYPE_NUMBER_OF_TOTAL_ANSWERED', {
              type: LanguageUtils.getTranslatedFieldName(workflow)
                .split(':')[1]
                .trim(),
              number: getAnsweredQuestions(workflow.id),
              total: getTotalQuestions(workflow.id),
            })}
          </Text>
        )
      })}
    </Grid>
  )
}

export default Questions
