import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import {
  AlertIconType,
  BasePuiDialogProps,
  PuiAlert,
  Text,
} from '@pbt/pbt-ui-components'

export interface SoapExamFindingsValidationErrorDialogProps
  extends BasePuiDialogProps {}

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    button: {
      minWidth: 150,
    },
  }),
  { name: 'SoapExamFindingsValidationErrorDialog' },
)

const SoapExamFindingsValidationErrorDialog = ({
  open,
  onClose,
}: SoapExamFindingsValidationErrorDialogProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Common', 'Dialogs'])

  return (
    <PuiAlert
      aria-labelledby="soap-exam-findings-validation-error-dialog"
      cancelButtonText={t('Common:CLOSE_TO_COPY')}
      classes={{
        paper: classes.paper,
      }}
      content={
        <Text align="center" mb={3}>
          {t('Dialogs:SOAP_EXAM_FINDINGS_VALIDATION_ERROR_DIALOG.DESCRIPTION')}
        </Text>
      }
      iconType={AlertIconType.WARN}
      okButtonText={t('Common:REFRESH_ACTION')}
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onOk={() => window.location.reload()}
    />
  )
}

export default SoapExamFindingsValidationErrorDialog
