import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import {
  groupLabTests,
  LabTestGroupKeys,
} from '~/components/dashboard/soap/wrap-up/lab-orders/labTestUtils'
import LabVendorNames from '~/constants/labVendorName'
import { LabOrdersStatus, LabTestTypes } from '~/constants/SOAPStates'
import { Order } from '~/types'

import { getLabOrders } from '../duck/labOrders'
import { getLabOrderStatuses, getLabTestsStates } from '../reducers/constants'
import { getSelectedOrders } from '../reducers/orders'
import { useGetInvoicePosted } from './soap'

export const useShowOutstandingLabOrdersSection = () => {
  const LabOrderStatusesList = useSelector(getLabOrderStatuses)
  const labOrders = useSelector(getLabOrders)
  const LabTestsStatesList = useSelector(getLabTestsStates)
  const orders = useSelector(getSelectedOrders)

  const isInvoicePosted = useGetInvoicePosted()

  const vendorIdsWithIntegration = labOrders
    .filter(({ active }) => active)
    .map(({ vendorId }) => vendorId)
  const labTestsMap = groupLabTests(LabTestsStatesList, orders)

  const createdOrders = R.pipe(
    R.pluck('orders'),
    R.flatten,
    R.filter(
      ({ statusId }) =>
        Utils.getConstantName(statusId, LabOrderStatusesList) ===
        LabOrdersStatus.CREATED,
    ),
  )(labOrders)

  const readyForOrderLabTests = R.pipe(
    R.map(
      (id: string) => labTestsMap[id]?.[LabTestGroupKeys.READY_FOR_ORDER] || [],
    ),
    R.flatten,
    R.filter(
      ({ labTest }: Order) =>
        !(
          labTest?.type === LabTestTypes.REFERENCE &&
          labTest.vendorName === LabVendorNames.IDEXX_ES
        ),
    ),
  )(vendorIdsWithIntegration)

  const hasCreatedOrders = createdOrders.length > 0
  const hasReadyForOrderLabTests = readyForOrderLabTests.length > 0
  const showOutstandingLabOrdersSection =
    !isInvoicePosted && (hasReadyForOrderLabTests || hasCreatedOrders)

  const showLabOrdersListSection = vendorIdsWithIntegration.some(
    (id) => labTestsMap[id],
  )

  return {
    readyForOrderLabTests,
    showOutstandingLabOrdersSection,
    showLabOrdersListSection,
  }
}
