import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BreedConstant,
  Constant,
  LanguageUtils,
  Nil,
  TextInteractive,
} from '@pbt/pbt-ui-components'
import { Delete } from '@pbt/pbt-ui-components/src/icons'

import { PatientPreferencesChip } from '~/components/dashboard/clients/patient/PatientPreferencesChip'
import WellnessPlanMemberButton from '~/components/dashboard/wellness-plans/WellnessPlanMemberButton'
import { AlertColorLevel } from '~/constants/alertColors'
import { useAlertType } from '~/store/hooks/patient'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getBreed, getGender, getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getFullBreedName, getPatientDetails } from '~/utils'

import AlertIcon from '../labels/AlertIcon'
import PatientInfoLabel from '../labels/PatientInfoLabel'
import Link from '../link/Link'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    link: {
      color: theme.colors.primaryText,
    },
    deleteIcon: {
      padding: theme.spacing(0.5),
    },
    planButton: {
      width: 'auto',
      padding: theme.spacing(0.25, 0.75),
    },
    chip: {
      width: 16,
      height: 16,
      marginRight: 4,
      marginLeft: 4,
    },
  }),
  { name: 'PatientCard' },
)

export interface PatientCardProps {
  clientId?: string | Nil
  onRemove?: () => void
  patientId: string
}

const PatientCard = ({ clientId, patientId, onRemove }: PatientCardProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getClientIsLoading)
  const patient = useSelector(getPatient(patientId))
  const Gender: Constant[] = useSelector(getGender)
  const Breed: BreedConstant = useSelector(getBreed)
  const Species: Constant[] = useSelector(getSpecies)

  const patientDetails = [getPatientDetails(patient, Gender)]

  if (patient?.breeds && patient?.species) {
    const fullBreedName = getFullBreedName(
      patient.species,
      patient.breeds,
      Breed,
    )
    const speciesName = LanguageUtils.getConstantTranslatedName(
      patient.species,
      Species,
      '',
    )
    patientDetails.push(`${speciesName} ${fullBreedName}`.trim())
  }

  const { alertsToRender } = useAlertType(patientId)

  return (
    <Grid container item className={classes.root} p={1} wrap="nowrap">
      <Grid container direction="column">
        <Grid container wrap="nowrap">
          <Grid container alignItems="center" pb={1.25}>
            {!R.isEmpty(alertsToRender) && (
              <AlertIcon
                alertColorId={patient?.alertColorId}
                alertColorLevel={AlertColorLevel.PATIENT}
                alertText={alertsToRender.join(', ')}
                size="small"
              />
            )}
            <PatientPreferencesChip
              className={classes.chip}
              patientId={patientId}
            />
            <TextInteractive
              strong
              isLoading={isLoading}
              mr={1}
              variant="subheading2"
            >
              <Link
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                to={`/patient/${patientId}`}
              >
                {patient?.name || ''}
              </Link>
            </TextInteractive>
            <WellnessPlanMemberButton
              className={classes.planButton}
              clientId={clientId}
              patientId={patientId}
            />
          </Grid>
          {onRemove && (
            <Grid item>
              <IconButton
                aria-label={t('Common:DELETE_ACTION').toLowerCase()}
                className={classes.deleteIcon}
                size="large"
                onClick={onRemove}
              >
                <Delete />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <PatientInfoLabel
          fontSize="1.4rem"
          isLoading={isLoading}
          patientId={patientId}
        />
      </Grid>
    </Grid>
  )
}

export default PatientCard
