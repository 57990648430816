import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { DateUtils, Defaults, useInterval, Utils } from '@pbt/pbt-ui-components'
import {
  CheckCircle as CheckCircleIcon,
  ThreeDotsCircle as ThreeDotsCircleIcon,
} from '@pbt/pbt-ui-components/src/icons'

import QuickFilterButton from '~/components/common/buttons/QuickFilterButton'
import DoctorFilter from '~/components/common/filters/DoctorFilter'
import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PatientCell from '~/components/common/lists/primitive-table/cells/patient/PatientCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import DialogNames from '~/constants/DialogNames'
import { ERROR_MESSAGE_BY_TYPE } from '~/constants/errorMessages'
import FeatureToggle from '~/constants/featureToggle'
import { LabTestState } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  fetchLabTestsList,
  fetchMoreLabTests,
  getAllLabTestsIsLoading,
  getLabTestsFilters,
  getLabTestsList,
  getLabTestValidationErrorType,
  getMultipleLabTests,
  getTotalCount,
  refreshLabTestsList,
  setFilters,
} from '~/store/duck/labTestsDashboard'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getDashboardLabTestStates,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { LabTestDashboardItem, TableFilter } from '~/types'
import { formatCalendarDate } from '~/utils/time'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import ClientCell from './ClientCell'
import LabOrderCell from './LabOrderCell'
import LabOrderTypeCell from './LabOrderTypeCell'
import LabStatusLabel from './LabStatusLabel'
import LabTestDetails from './LabTestDetails'
import LabTestsDateFilter from './LabTestsDateFilter'
import LabTestTypeFilter from './LabTestTypeFilter'
import { getLabTestComplexId, getLabTestIdentifier } from './labTestUtils'
import NoLabTestsScreen from './NoLabTestsScreen'
import StatusFilter from './StatusFilter'

const useStyles = makeStyles(
  () => ({
    rowText: {
      fontSize: '1.4rem',
    },
    headerButtonsContainer: {
      width: '100%',
    },
  }),
  { name: 'LabTestsTableComponent' },
)

const columns = [
  {
    label: i18n.t('Common:PATIENT'),
    component: (item: LabTestDashboardItem) => (
      <PatientCell isLoading={R.isEmpty(item)} patientId={item.patient} />
    ),
    width: 1.5,
  },
  {
    label: i18n.t('Common:CLIENT'),
    component: (item: LabTestDashboardItem) => (
      <ClientCell isLoading={R.isEmpty(item)} labTest={item} />
    ),
    width: 1.5,
  },
  {
    label: i18n.t('Common:LAB_ORDER'),
    component: (item: LabTestDashboardItem) => <LabOrderCell labTest={item} />,
    width: 3,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    component: (item: LabTestDashboardItem) => (
      <LabOrderTypeCell order={item.order} tests={item.tests} />
    ),
    filter: 'typeIds',
    FilterComponent: LabTestTypeFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:DATE_TIME'),
    prop: (item: LabTestDashboardItem) =>
      DateUtils.formatDate(item.orderedDate),
    filter: 'date',
    FilterComponent: LabTestsDateFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:ASSIGNED_DOCTOR'),
    prop: (item: LabTestDashboardItem) => item.vet?.name || '',
    filter: 'vetIds',
    FilterComponent: DoctorFilter,
    width: 3,
  },
  {
    label: i18n.t('Common:STATUS'),
    component: (item: LabTestDashboardItem) => (
      <LabStatusLabel order={item.order} tests={item.tests} variant="small" />
    ),
    filter: 'status',
    FilterComponent: StatusFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:RESULTS_RECEIVED'),
    prop: (item: LabTestDashboardItem) =>
      item.order?.resultDate ? formatCalendarDate(item.order?.resultDate) : '-',
    width: 3,
  },
]

const QuickFilters = {
  RESULTS_RECEIVED: [
    LabTestState.PARTIAL_RESULTS,
    LabTestState.COMPLETED,
    LabTestState.RESULTS_RECEIVED,
  ],
  PENDING: [
    LabTestState.ORDERED,
    LabTestState.SAMPLE_COLLECTED,
    LabTestState.SHIPPED,
    LabTestState.SAMPLE_RECEIVED,
    LabTestState.IN_PROGRESS,
    LabTestState.SELECTED,
    LabTestState.WAITING_ON_SAMPLE,
    LabTestState.PARTIAL_RESULTS,
  ],
  REMOVED: [
    LabTestState.CANCELLED,
    LabTestState.DECLINED,
    LabTestState.DELETED,
  ],
  DEFAULT: [
    LabTestState.ORDERED,
    LabTestState.SELECTED,
    LabTestState.WAITING_ON_SAMPLE,
    LabTestState.SAMPLE_COLLECTED,
    LabTestState.COMPLETED,
    LabTestState.RESULTS_RECEIVED,
    LabTestState.UNSUCCESSFUL,
    LabTestState.PARTIAL_RESULTS,
    LabTestState.SHIPPED,
    LabTestState.SAMPLE_RECEIVED,
    LabTestState.IN_PROGRESS,
    LabTestState.SAMPLES_NOT_RECEIVED,
    LabTestState.PENDING,
    LabTestState.UPDATED_RESULTS,
  ],
}

const getUniqueId = ({ identifier }: LabTestDashboardItem) => identifier

export interface LabTestsTableComponentProps {
  labTestIdentifier?: string
}

const LabTestsTableComponent = ({
  labTestIdentifier,
}: LabTestsTableComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common'])

  const labTestIds = useSelector(getLabTestsList)
  const labTests = useSelector(getMultipleLabTests(labTestIds))
  const filters = useSelector(getLabTestsFilters)
  const DashboardLabTestStates = useSelector(getDashboardLabTestStates)
  const isLoading = useSelector(getAllLabTestsIsLoading)
  const totalCount = useSelector(getTotalCount)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const validationErrorType = useSelector(getLabTestValidationErrorType)
  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )

  const loadMoreItems = (from: number, to: number) =>
    dispatch(fetchMoreLabTests(from, to))

  const [openValidationErrorDialog, closeValidationErrorDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const useConditionalEffect = isDeleteFinalizedLabTestsEnabled
    ? useEffectExceptOnMount
    : useEffect

  useConditionalEffect(() => {
    dispatch(fetchLabTestsList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT))
  }, [filters])

  useEffectExceptOnMount(() => {
    if (validationErrorType) {
      openValidationErrorDialog({
        message: ERROR_MESSAGE_BY_TYPE[validationErrorType],
        onCancel: closeValidationErrorDialog,
      })
    }
  }, [validationErrorType])

  useInterval(() => {
    if (!hasOpenDialogs && !isLoading && currentBusinessId) {
      dispatch(refreshLabTestsList(0, Math.max(labTests.length - 1, 0)))
    }
  }, Defaults.LAB_ORDERS_UPDATE_INTERVAL)

  const navigateToLabTest = (id: string, labTest: LabTestDashboardItem) => {
    const isOrphanWithoutPatient = !labTest?.patient

    if (!isOrphanWithoutPatient) {
      navigate(`/lab-tests-dashboard/${getLabTestIdentifier(labTest)}`)
    }
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setFilters({}))
  }

  const onDetailsClose = () => {
    navigate('/lab-tests-dashboard')
  }

  const isItemLoaded = (index: number) => Boolean(labTests[index])

  const getComplexIds = (ids: string[]) =>
    ids.map((name) =>
      getLabTestComplexId(
        Utils.findConstantByName(name, DashboardLabTestStates),
      ),
    )

  const isQuickFilterSelected = (quickFilter: string[]) => {
    const ids = getComplexIds(quickFilter)
    const statusIds = (filters?.status?.value || []) as string[]

    return (
      statusIds?.length > 0 &&
      statusIds.length === ids.length &&
      R.difference(statusIds, ids).length === 0
    )
  }

  const handleQuickFilter = (quickFilter: string[]) => {
    const isSelected = isQuickFilterSelected(quickFilter)
    if (isDeleteFinalizedLabTestsEnabled) {
      if (isSelected) {
        handleQuickFilter(QuickFilters.DEFAULT)
        return
      }
      const complexIds = getComplexIds(quickFilter)
      onApplyFilter('status', { value: complexIds })
      return
    }

    const complexIds = getComplexIds(quickFilter)

    const value = isSelected ? [] : complexIds

    onApplyFilter('status', { value })
  }

  useEffect(() => {
    if (
      isDeleteFinalizedLabTestsEnabled &&
      !isQuickFilterSelected(QuickFilters.DEFAULT)
    ) {
      handleQuickFilter(QuickFilters.DEFAULT)
    }
  }, [])

  const headerButtons = (
    <Grid
      container
      item
      className={classes.headerButtonsContainer}
      pl={2}
      spacing={2}
      wrap="nowrap"
    >
      <Grid item>
        <QuickFilterButton
          IconComponent={ThreeDotsCircleIcon}
          active={isQuickFilterSelected(QuickFilters.PENDING)}
          text={t('Common:PENDING')}
          onClick={() => handleQuickFilter(QuickFilters.PENDING)}
        />
      </Grid>
      <Grid item>
        <QuickFilterButton
          IconComponent={CheckCircleIcon}
          active={isQuickFilterSelected(QuickFilters.RESULTS_RECEIVED)}
          text={t('Common:RESULTS_RECEIVED')}
          onClick={() => handleQuickFilter(QuickFilters.RESULTS_RECEIVED)}
        />
      </Grid>
      {isDeleteFinalizedLabTestsEnabled && (
        <Grid item>
          <QuickFilterButton
            IconComponent={DeleteIcon}
            active={isQuickFilterSelected(QuickFilters.REMOVED)}
            text={t('Common:REMOVED')}
            onClick={() => handleQuickFilter(QuickFilters.REMOVED)}
          />
        </Grid>
      )}
    </Grid>
  )

  return (
    <ExpandableTable
      Expander={LabTestDetails}
      NoItemsScreen={NoLabTestsScreen}
      getUniqueId={getUniqueId}
      hasSelectedFilters={!R.isEmpty(filters)}
      headerButtons={headerButtons}
      isLoading={isLoading}
      itemId={labTestIdentifier}
      list={labTests}
      title={t('Common:LAB_TESTS')}
      onClose={onDetailsClose}
      onSelected={navigateToLabTest}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        getUniqueId={getUniqueId}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        mainColumnCount={3}
        rowClasses={{
          text: classes.rowText,
        }}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default LabTestsTableComponent
