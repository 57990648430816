import React from 'react'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'

const { DISCOUNT } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  () => ({
    subtotalCell: {
      minWidth: DISCOUNT,
      width: DISCOUNT,
    },
  }),
  { name: 'FinanceItemDiscountCellBody' },
)

export interface FinanceItemDiscountCellBodyProps {
  isFullyRefunded?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  tableCellClassName: string
  tableCellWithBorder: string
}

const FinanceItemDiscountCellBody = ({
  item,
  tableCellClassName,
  tableCellWithBorder,
  isFullyRefunded = false,
}: FinanceItemDiscountCellBodyProps) => {
  const classes = useStyles()

  return (
    <TableCell
      align="right"
      className={classNames(
        tableCellClassName,
        tableCellWithBorder,
        classes.subtotalCell,
      )}
    >
      {!R.prop('declined', item) &&
        Boolean(R.prop('discountAmount', item) || R.prop('discount', item)) && (
          <Text variant={isFullyRefunded ? 'lowAccent2' : 'body2'}>
            {NumberUtils.formatMoney(
              R.prop('discountAmount', item) || R.prop('discount', item),
            )}
          </Text>
        )}
    </TableCell>
  )
}

export default FinanceItemDiscountCellBody
