import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import {
  getDomainContext,
  isLocalEnvironment,
} from '@pbt/pbt-ui-components/src/utils'

import { getDevEnvironment } from '~/components/WindowVariableInjector'
import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'
import {
  auth0Enabled,
  isAnalytics,
  isBenchmarking,
  isNVA,
  isPIMS,
} from '~/utils'

const domainContext = getDomainContext()
const env = isLocalEnvironment() ? getDevEnvironment() : domainContext.env
const envVariables = ENVIRONMENT_VARIABLES[env]
const subdomain = isNVA
  ? 'NVA'
  : isPIMS
    ? 'PIMS'
    : isAnalytics
      ? 'ANALYTICS'
      : isBenchmarking
        ? 'BENCHMARKING'
        : 'PORTAL'

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN } = envVariables[subdomain]

type ConditionalAuth0ProviderProps = {
  children?: ReactNode
}

const ConditionalAuth0Provider = ({
  children,
}: ConditionalAuth0ProviderProps) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return auth0Enabled ? (
    <Auth0Provider
      authorizationParams={{
        audience: 'https://petabyte.vet/api/v1/',
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  ) : (
    <>{children}</>
  )
}

export default ConditionalAuth0Provider
