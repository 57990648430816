import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { PermissionArea, PuiTextField, useFields } from '@pbt/pbt-ui-components'

import TeamMemberSelect from '~/components/common/inputs/TeamMemberSelect'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandle, Prescription } from '~/types'
import { getWorkflowType } from '~/utils/prescription'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

const getFields = (obj: Prescription) => [
  {
    name: 'filledById',
    initialValue: obj.filledById,
  },
  {
    name: 'dispenseReason',
    initialValue: obj.dispenseReason,
  },
]

export interface ControlledSubstanceSectionProps {
  activeWorkflow?: PrescriptionWorkflowType
  onRefChange?: () => void
  prescription: Prescription
}

export interface ControlledSubstanceSectionHandle
  extends DataHandle<{
    dispenseReason: string
    filledById: string
  }> {}

const ControlledSubstanceSection = forwardRef<
  ControlledSubstanceSectionHandle,
  ControlledSubstanceSectionProps
>(function ControlledSubstanceSection(
  { activeWorkflow, onRefChange, prescription },
  ref,
) {
  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))

  const RolesList = useMainStaffRoles()
  const { t } = useTranslation('Common')

  const {
    fields: { dispenseReason, filledById },
    reset,
    validate,
  } = useFields(getFields(prescription))

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [dispenseReason, filledById])

  useImperativeHandle(ref, () => ({
    get: () => ({
      dispenseReason: dispenseReason.value,
      filledById: filledById.value,
    }),
    validate,
  }))

  useEffectExceptOnMount(() => {
    reset(getFields(prescription))
  }, [prescription])

  const { isOrder, isInHouseRx } = getWorkflowType(activeWorkflow)

  return (
    <Grid container item alignItems="center" spacing={3}>
      <Grid item xs={8}>
        <PuiTextField
          disabled={!permissions.update}
          field={dispenseReason}
          label={t('Common:REASON_DISPENSED')}
        />
      </Grid>
      {(isOrder || isInHouseRx) && (
        <Grid item xs={4}>
          <TeamMemberSelect
            disabled={!permissions.update}
            field={filledById}
            label={
              isOrder ? t('Common:ADMINISTERED_BY') : t('Common:FILLED_BY')
            }
            roles={RolesList.map((role) => role.name)}
          />
        </Grid>
      )}
    </Grid>
  )
})

export default ControlledSubstanceSection
