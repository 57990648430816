import { Defaults } from '@pbt/pbt-ui-components'

import { getAPIPath, request } from './core'

export const fetchRemindersCatalogFilters = (businessId: string) =>
  request(
    getAPIPath('admin/services/reminder-order-items/filters'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const fetchRemindersCatalogItems = (
  businessId: string,
  categories: string[],
  listType: string,
  entityTypes: string[] = [],
  query: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('admin/services/reminder-order-items/search'),
    {
      method: 'GET',
      params: {
        businessId,
        categories: encodeURI(categories.join(',')),
        listType,
        types: encodeURI(entityTypes.join(',')),
        query,
        from,
        to,
      },
    },
    true,
  )
