import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import ClientDetailsFooter from '~/components/dashboard/clients/ClientDetailsFooter'
import { clearCurrentClientInfo } from '~/store/actions/clients'
import { getCurrentClientId } from '~/store/reducers/clients'
import { getCurrentPatientId, getPatientName } from '~/store/reducers/patients'
import { getUserFirstName } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.soapStatusBar,
    },
    inviteClientRoot: {
      padding: theme.spacing(3),
      backgroundColor: theme.colors.badgeColor,
    },
  }),
  { name: 'AppointmentNewClientConfirmation' },
)

export interface AppointmentNewClientConfirmationProps {
  onOk: () => void
}

const AppointmentNewClientConfirmation = ({
  onOk,
}: AppointmentNewClientConfirmationProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('TimeTable')

  const clientId = useSelector(getCurrentClientId)
  const patientId = useSelector(getCurrentPatientId)
  const clientFirstName = useSelector(getUserFirstName(clientId))
  const patientName = useSelector(getPatientName(patientId))

  const continueToDetails = () => {
    if (clientId) {
      dispatch(clearCurrentClientInfo())
      navigate(`/client/${clientId}/new`)
      onOk()
    }
  }

  return (
    <Grid container item className={classes.root} direction="column" pt={1}>
      <Grid item my={1} pl={3}>
        <Text mb={2} variant="h2">
          {t('TimeTable:ADD_APPOINTMENT_COMPONENT.NEW_APPOINTMENT')}
        </Text>
        <Text variant="body">
          {t(
            'TimeTable:ADD_APPOINTMENT_COMPONENT.ADDED_NEW_CLIENT_AND_PATIENT',
            {
              clientFirstName,
              patientName,
            },
          )}
        </Text>
      </Grid>
      <ClientDetailsFooter
        classes={{
          root: classes.inviteClientRoot,
        }}
        continueToDetails={continueToDetails}
        onClose={() => {
          onOk()
          dispatch(clearCurrentClientInfo())
        }}
      />
    </Grid>
  )
}

export default AppointmentNewClientConfirmation
