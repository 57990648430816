import * as R from 'ramda'
import { all, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError, Coparent, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import { validateClientEmail } from '../actions/clients'
import {
  createCoparent,
  createCoparentFailure,
  createCoparentSuccess,
  deleteCoparent,
  deleteCoparentFailure,
  deleteCoparentSuccess,
  updateCoparent,
  updateCoparentFailure,
  updateCoparentSuccess,
} from '../actions/coparents'
import {
  CREATE_COPARENT,
  DELETE_COPARENT,
  UPDATE_COPARENT,
} from '../actions/types/coparents'
import { updateUser, updateUsers } from '../actions/users'
import { getUser, getUsersMap } from '../reducers/users'
import requestAPI from './utils/requestAPI'

export function* createCoparentSaga({
  clientId,
  coparent,
}: ReturnType<typeof createCoparent>) {
  try {
    const {
      result,
      entities: { users },
    } = yield* requestAPI(API.createCoparent, clientId, coparent)
    const client: User = yield select(getUser(clientId))
    yield put(updateUsers(users))
    yield put(
      updateUser({
        ...client,
        coparents: R.uniq([...(client.coparents || []), result]),
      }),
    )
    if (client.email) {
      yield put(validateClientEmail(clientId))
    }
    yield put(createCoparentSuccess(clientId))
  } catch (error) {
    yield put(createCoparentFailure(error as ApiError))
  }
}

export function* updateCoparentSaga({
  clientId,
  coparent,
}: ReturnType<typeof updateCoparent>) {
  try {
    const oldCoparent: Coparent = yield select(getUser(coparent.id))
    const {
      result,
      entities: { users },
    } = yield* requestAPI(API.updateCoparent, clientId, {
      ...oldCoparent,
      ...coparent,
    })
    const client: User = yield select(getUser(clientId))
    yield put(
      updateUser({
        ...client,
        coparents: R.uniq([...(client.coparents || []), result]),
      }),
    )
    yield put(updateUsers(users))
    if (client.email) {
      yield put(validateClientEmail(clientId))
    }
    yield put(updateCoparentSuccess())
  } catch (error) {
    yield put(updateCoparentFailure(error as ApiError))
  }
}

export function* deleteCoparentSaga({
  clientId,
  coparentId,
}: ReturnType<typeof deleteCoparent>) {
  try {
    yield* requestAPI(API.deleteCoparent, clientId, coparentId)
    const user: User = yield select(getUser(clientId))
    const userCoparents = user?.coparents?.filter((id) => id !== coparentId)
    yield put(updateUser({ ...user, coparents: userCoparents }))
    const { [coparentId]: deleted, ...restUsers } = yield select(getUsersMap)
    yield put(updateUsers(restUsers))
    yield put(deleteCoparentSuccess())
  } catch (error) {
    yield put(deleteCoparentFailure(error as ApiError))
  }
}

function* watchCreateCoparent() {
  yield takeLeading(CREATE_COPARENT, createCoparentSaga)
}

function* watchUpdateCoparent() {
  yield takeLeading(UPDATE_COPARENT, updateCoparentSaga)
}

function* watchDeleteCoparent() {
  yield takeLeading(DELETE_COPARENT, deleteCoparentSaga)
}

export default function* coparentsSaga() {
  yield all([
    watchCreateCoparent(),
    watchUpdateCoparent(),
    watchDeleteCoparent(),
  ])
}
