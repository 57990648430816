import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  FETCH_RHAPSODY_PAY_CONFIG,
  fetchRhapsodyPayConfig,
  fetchRhapsodyPayConfigFailure,
  fetchRhapsodyPayConfigSuccess,
  GET_RHAPSODY_PAA_APP_URL,
  getPayPassAppUrl,
  getRhapsodyPayConfig,
  sendRhapsodyPayConfigFailure,
  sendRhapsodyPayConfigSuccess,
  UPDATE_RHAPSODY_PAY_CONFIG,
  UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY,
  updateRhapsodyPayConfig,
  updateRhapsodyPayConfigFailure,
  updateRhapsodyPayConfigManually,
  updateRhapsodyPayConfigManuallyFailure,
  updateRhapsodyPayConfigManuallySuccess,
  updateRhapsodyPayConfigSuccess,
} from '../duck/rhapsodyPay'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchRhapsodyPayConfigSaga({
  businessId,
  extended,
}: ReturnType<typeof fetchRhapsodyPayConfig>) {
  try {
    const currentRhapsodyPayConfig: string = yield select(
      getRhapsodyPayConfig(businessId),
    )
    if (!currentRhapsodyPayConfig || extended) {
      const { entities } = yield* requestAPI(
        API.fetchRhapsodyPayConfig,
        businessId,
        extended,
      )
      yield call(updateEntities, entities)
    }
    yield put(fetchRhapsodyPayConfigSuccess(businessId))
  } catch (error) {
    yield put(fetchRhapsodyPayConfigFailure(error as ApiError))
  }
}

export function* getPayPassAppUrlSaga({
  goOptionId,
  passOptionId,
}: ReturnType<typeof getPayPassAppUrl>) {
  try {
    const { url } = yield* requestAPI(
      API.getPayPassAppUrl,
      goOptionId,
      passOptionId,
    )
    yield put(sendRhapsodyPayConfigSuccess(url))
  } catch (error) {
    yield put(sendRhapsodyPayConfigFailure(error as ApiError))
  }
}

export function* updateRhapsodyPayConfigSaga({
  config,
}: ReturnType<typeof updateRhapsodyPayConfig>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.updateRhapsodyPayConfig,
      config,
    )
    yield call(updateEntities, entities)
    yield put(updateRhapsodyPayConfigSuccess(result))
  } catch (error) {
    yield put(updateRhapsodyPayConfigFailure(error as ApiError))
  }
}

export function* updateRhapsodyPayConfigManuallySaga({
  config,
}: ReturnType<typeof updateRhapsodyPayConfigManually>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.updateRhapsodyPayConfigManually,
      config,
    )
    yield call(updateEntities, entities)
    yield put(updateRhapsodyPayConfigManuallySuccess(result))
  } catch (error) {
    yield put(updateRhapsodyPayConfigManuallyFailure(error as ApiError))
  }
}

function* watchFetchRhapsodyPayConfig() {
  yield takeLeading(FETCH_RHAPSODY_PAY_CONFIG, fetchRhapsodyPayConfigSaga)
}

function* watchGetPayPassAppUrl() {
  yield takeLeading(GET_RHAPSODY_PAA_APP_URL, getPayPassAppUrlSaga)
}

function* watchUpdateRhapsodyPayConfig() {
  yield takeLeading(UPDATE_RHAPSODY_PAY_CONFIG, updateRhapsodyPayConfigSaga)
}

function* watchUpdateRhapsodyPayConfigManually() {
  yield takeLeading(
    UPDATE_RHAPSODY_PAY_CONFIG_MANUALLY,
    updateRhapsodyPayConfigManuallySaga,
  )
}

export default function* rhapsodyPaySaga() {
  yield all([
    watchGetPayPassAppUrl(),
    watchUpdateRhapsodyPayConfig(),
    watchFetchRhapsodyPayConfig(),
    watchUpdateRhapsodyPayConfigManually(),
  ])
}
