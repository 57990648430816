import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { getEventState } from '~/store/reducers/constants'
import { ReminderStateName } from '~/types'

export const useGetReminderInitialFilters = (): string[] => {
  const EventState = useSelector(getEventState)

  return [
    Utils.findConstantIdByName(ReminderStateName.OPEN, EventState),
    Utils.findConstantIdByName(ReminderStateName.OVERDUE, EventState),
  ]
}
