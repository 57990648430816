import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, InputLabel, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Calendar,
  PuiTextField,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { AttachmentHistoryItem } from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  (theme) => ({
    descriptionLabel: {
      color: theme.colors.primaryText,
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    nameInput: {
      width: '85%',
    },
  }),
  { name: 'AttachmentHistoryDetails' },
)

export interface AttachmentHistoryDetailsProps {
  file?: AttachmentHistoryItem
  isImage?: boolean
  isPreview?: boolean
  onUpdateItem: (item: AttachmentHistoryItem) => void
  order: AttachmentHistoryItem
}

const AttachmentHistoryDetails = forwardRef<
  ValidateHandle,
  AttachmentHistoryDetailsProps
>(function AttachmentHistoryDetails(
  { file, isImage = false, order: item, isPreview = false, onUpdateItem },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'MedicalHistory'])

  const isFileAttachment = Boolean(file)
  const isAttachingFilesToLabOrdersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ATTACH_FILES_TO_LAB_ORDERS),
  )
  const attachmentGroupFromLabel = isAttachingFilesToLabOrdersEnabled
    ? t('MedicalHistory:ATTACHMENT_HISTORY_DETAILS.ATTACHMENT_SOURCE')
    : t('MedicalHistory:ATTACHMENT_HISTORY_DETAILS.PRACTICE_ATTACHMENT_FROM')
  const attachment = file || item

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'date',
        label: t('Common:DATE_TIME'),
        validators: ['required'],
        initialValue: attachment.date,
      },
      {
        name: 'name',
        label: t('Common:ATTACHMENT_NAME'),
        validators: ['required'],
        initialValue: attachment.name || attachment.fileName,
      },
      {
        name: 'diagnosedIn',
        label: attachmentGroupFromLabel,
        initialValue: attachment.diagnosedIn || '',
      },
      {
        name: 'description',
        label: t('Common:DESCRIPTION'),
        initialValue: attachment.description || '',
      },
    ],
    false,
  )

  const { date, name, diagnosedIn, description } = fields

  useEffect(() => {
    reset()
  }, [attachment])

  useFieldsChanged(() => {
    const newAttachment = {
      ...attachment,
      date: date.value,
      description: description.value,
      name: name.value,
      diagnosedIn: diagnosedIn.value,
    }

    if (!isFileAttachment) {
      newAttachment.notes = description.value
    }

    if (!isPreview) {
      newAttachment.documentId = attachment.id || undefined
    }

    onUpdateItem(newAttachment)
  }, fields)

  useImperativeHandle(ref, () => ({
    validate,
  }))

  return (
    <Grid container item direction="column">
      <Stack alignItems="center" direction="row" spacing={2}>
        <Grid item>
          <Calendar fullWidth field={date} label={`${date.label}*`} />
        </Grid>
        <Grid item xs>
          <PuiTextField
            autoFocus
            className={classNames({
              [classes.nameInput]: isImage,
            })}
            field={name}
            inputProps={{ maxLength: 100 }}
            label={`${name.label}*`}
          />
        </Grid>
      </Stack>
      <Grid item>
        <PuiTextField
          field={diagnosedIn}
          inputProps={{ maxLength: 100 }}
          label={diagnosedIn.label}
        />
      </Grid>
      <Grid item mt={1.5}>
        <InputLabel htmlFor="description-input">
          <span className={classes.descriptionLabel}>{description.label}:</span>
        </InputLabel>
        <PuiTextField
          multiline
          field={description}
          id="description-input"
          inputProps={{ maxLength: 1000 }}
          minRows={3}
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
})

export default AttachmentHistoryDetails
