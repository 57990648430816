import { useSelector } from 'react-redux'
import { Business, WellnessPlanBenefit } from '@pbt/pbt-ui-components'

import { getBenefitIsAccessToBoop } from '~/components/dashboard/wellness-plans/wellnessPlanUtils'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessHasOpenBoop } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

const useBoopEnabled = (business?: Business) => {
  const currentBusinessOpenBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const openBoop = business ? business?.openBoop : currentBusinessOpenBoop
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  return !isBoopDisablementEnabled || openBoop
}

const removeBoopBenefitFromList = (benefits: WellnessPlanBenefit[]) =>
  benefits.filter((benefit) => !getBenefitIsAccessToBoop(benefit))

const useBoopFilteredBenefits = (benefits: WellnessPlanBenefit[]) =>
  useBoopEnabled() ? benefits : removeBoopBenefitFromList(benefits)

export { useBoopEnabled, useBoopFilteredBenefits }
