import React from 'react'
import { NumberUtils } from '@pbt/pbt-ui-components'

import { MembershipPaymentStatus } from '~/constants/paymentTypes'

interface PaidCellProps {
  amount: number
  status?: MembershipPaymentStatus
}

const PaidCell = ({ status, amount }: PaidCellProps) => {
  const isDue = status === MembershipPaymentStatus.NEXT

  return <span>{isDue ? '-' : NumberUtils.formatMoney(amount)}</span>
}

export default PaidCell
