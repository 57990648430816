import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import NoItemsScreen from '../NoItemsScreen'

const NoClientsScreen = () => {
  const { t } = useTranslation(['Common', 'Clients'])

  const [openNewClientDialog] = useDialog(DialogNames.CLIENT_NEW)

  return (
    <NoItemsScreen
      action={() => openNewClientDialog()}
      actionMessage={t('Common:ADD_A_CLIENT')}
      title={t('Common:CLIENTS_AND_PATIENTS')}
    >
      <Text>{t('Clients:NO_CLIENTS_SCREEN.BODY_TEXT')}</Text>
    </NoItemsScreen>
  )
}

export default NoClientsScreen
