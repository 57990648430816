import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import { asFlags } from '~/utils'

import {
  fetchImagingVendorConfigs,
  getImagingConfigIsReceiving,
  getImagingConfigsListForBusiness,
  getMultipleImagingConfigs,
} from '../duck/imagingVendorConfig'
import { getCurrentBusinessId } from '../reducers/auth'

export const useGetImagingVendorConfigs = () => {
  const dispatch = useDispatch()
  const currentBusinessId = useSelector(getCurrentBusinessId) as string
  const list = useSelector(getImagingConfigsListForBusiness(currentBusinessId))
  const configs = useSelector(
    getMultipleImagingConfigs(list, currentBusinessId),
  )
  const isReceiving = useSelector(getImagingConfigIsReceiving)

  useEffect(() => {
    if (configs.length === 0 && currentBusinessId && !isReceiving) {
      dispatch(fetchImagingVendorConfigs(currentBusinessId))
    }
  }, [currentBusinessId])

  return configs
}

export const useGetActiveImagingVendorsMap = () => {
  const configs = useGetImagingVendorConfigs()

  const activeConfigs = R.filter(R.propEq('active', true), configs)
  const vendorIds = R.pluck('vendorId', activeConfigs)

  return asFlags(vendorIds)
}
