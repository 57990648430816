import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { ApiError, Nil, Patient } from '@pbt/pbt-ui-components'

import {
  ChewyPet,
  PatientSummary,
  PetProfileMatch,
  UserMatch,
} from '~/api/graphql/generated/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import { getPatientsMap } from '../reducers/patients'

export type ChewyPetsState = {
  emails: UserMatch[]
  emailsError: string | null
  emailsLoading: boolean
  error: string | null
  failedEmails: string[]
  isEmailMatch: boolean | null
  isEmailMatchError: string | null
  isEmailMatchLoading: boolean
  isLinkingPets: boolean
  isLinkingPetsError: string | null
  isLoading: boolean
  linkingUser: boolean
  linkingUserError: string | null
  matchedPets: Record<string, PetProfileMatch>
  matchedPetsList: string[]
  unmatchedPatients: Record<string, PatientSummary>
  unmatchedPatientsList: string[]
  unmatchedPets: Record<string, ChewyPet>
  unmatchedPetsList: string[]
}

export const CHEWY_PETS_INITIAL_STATE: ChewyPetsState = {
  emails: [],
  failedEmails: [],
  emailsLoading: false,
  emailsError: null,
  error: null,
  isEmailMatch: null,
  isEmailMatchError: null,
  isEmailMatchLoading: false,
  isLinkingPets: false,
  isLinkingPetsError: null,
  isLoading: false,
  linkingUser: false,
  linkingUserError: null,
  matchedPets: {},
  unmatchedPatients: {},
  unmatchedPets: {},
  matchedPetsList: [],
  unmatchedPatientsList: [],
  unmatchedPetsList: [],
}

interface PetMatchData {
  matchedPets: Record<string, PetProfileMatch>
  matchedPetsList: string[]
  unmatchedPatients: Record<string, PatientSummary>
  unmatchedPatientsList: string[]
  unmatchedPets: Record<string, ChewyPet>
  unmatchedPetsList: string[]
}

const chewyPetsSlice = createSlice({
  name: 'chewyPets',
  initialState: CHEWY_PETS_INITIAL_STATE,
  reducers: {
    resetChewyPets: () => CHEWY_PETS_INITIAL_STATE,
    fetchEmailData: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ clientId: string }>,
    ) => {
      state.emailsLoading = true
      state.emailsError = null
      state.emails = []
    },
    fetchEmailDataSuccess: (
      state,
      action: PayloadAction<{ emails: UserMatch[] }>,
    ) => {
      state.emailsLoading = false
      state.emails = action.payload.emails
    },
    fetchEmailDataFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.emailsLoading = false
      state.emailsError = getErrorMessage(action.payload.error)
    },

    linkChewyUser: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
        email: string
        patientId?: string | Nil
      }>,
    ) => {
      state.linkingUser = true
      state.linkingUserError = null
    },
    linkChewyUserSuccess: (state) => {
      state.linkingUser = false
      state.linkingUserError = null
    },
    linkChewyUserFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.linkingUser = false
      state.linkingUserError = getErrorMessage(action.payload.error)
    },

    getPetMatchData: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ clientId: string }>,
    ) => {
      state.isLoading = true
      state.matchedPets = {}
      state.unmatchedPets = {}
      state.unmatchedPatients = {}
      state.error = null
    },
    getPetMatchDataSuccess: (state, action: PayloadAction<PetMatchData>) => {
      state.matchedPets = action.payload.matchedPets || {}
      state.unmatchedPatients = action.payload.unmatchedPatients || {}
      state.unmatchedPets = action.payload.unmatchedPets || {}
      state.matchedPetsList = action.payload.matchedPetsList || []
      state.unmatchedPatientsList = action.payload.unmatchedPatientsList || []
      state.unmatchedPetsList = action.payload.unmatchedPetsList || []
      state.isLoading = false
    },
    getPetMatchDataFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    getMatchChewyUserByEmail: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ email: string }>,
    ) => {
      state.isEmailMatchLoading = true
      state.isEmailMatchError = null
      state.isEmailMatch = null
    },
    getMatchChewyUserByEmailSuccess: (
      state,
      action: PayloadAction<{ email: string; isEmailMatch: boolean }>,
    ) => {
      state.isEmailMatchLoading = false
      state.isEmailMatch = action.payload.isEmailMatch
      state.isEmailMatchError = null
    },
    getMatchChewyUserByEmailFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isEmailMatch = false
      state.isEmailMatchError = getErrorMessage(action.payload.error)
      state.isEmailMatchLoading = false
    },
    clearMatchChewyUserByEmail: (state) => {
      state.isEmailMatch = null
      state.isEmailMatchError = null
      state.isEmailMatchLoading = false
    },
    addNewPatient: (
      state: ChewyPetsState,
      action: PayloadAction<{
        patient: Partial<Patient>
      }>,
    ) => {
      state.unmatchedPatients = {
        ...state.unmatchedPatients,
        [action.payload.patient.id!]: {
          rhapsodyId: action.payload.patient.id,
          name: action.payload.patient.name,
          species: action.payload.patient.species,
          gender: action.payload.patient.gender,
          dateOfBirth: action.payload.patient.dateOfBirth,
          isDeceased: action.payload.patient.deceased,
          isActive: action.payload.patient.active,
        } as PatientSummary,
      }
      state.unmatchedPatientsList = [
        ...state.unmatchedPatientsList,
        action.payload.patient.id!,
      ]
    },
    linkPets: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
        petPatientMatches: PetProfileMatch[]
      }>,
    ) => {
      state.isLinkingPets = true
      state.isLinkingPetsError = null
    },
    linkPetsSuccess: (state) => {
      state.isLinkingPets = false
      state.isLinkingPetsError = null
      state.matchedPets = {}
      state.unmatchedPatients = {}
      state.unmatchedPets = {}
      state.matchedPetsList = []
      state.unmatchedPatientsList = []
      state.unmatchedPetsList = []
    },
    linkPetsFailure: (state, action: PayloadAction<{ error: ApiError }>) => {
      state.isLinkingPets = false
      state.isLinkingPetsError = getErrorMessage(action.payload.error)
    },
    addFailedEmails: (state, action: PayloadAction<string[]>) => {
      state.failedEmails = [...state.failedEmails, ...action.payload]
    },
  },
})

const { actions, reducer } = chewyPetsSlice

export const {
  resetChewyPets,
  fetchEmailData,
  fetchEmailDataSuccess,
  fetchEmailDataFailure,
  getPetMatchData,
  getPetMatchDataSuccess,
  getPetMatchDataFailure,
  linkChewyUser,
  linkChewyUserSuccess,
  linkChewyUserFailure,
  getMatchChewyUserByEmail,
  getMatchChewyUserByEmailSuccess,
  getMatchChewyUserByEmailFailure,
  clearMatchChewyUserByEmail,
  addNewPatient,
  linkPets,
  linkPetsFailure,
  linkPetsSuccess,
  addFailedEmails,
} = actions

export default reducer

export const getChewyPetsState = (state: RootState): ChewyPetsState =>
  state.chewyPets
export const getIsPetsLoading = (state: RootState) =>
  getChewyPetsState(state).isLoading
export const getIsLinkingPets = (state: RootState) =>
  getChewyPetsState(state).isLinkingPets
export const getUnmatchedPets = (state: RootState) =>
  getChewyPetsState(state).unmatchedPets
export const getMatchedPets = (state: RootState) =>
  getChewyPetsState(state).matchedPets
export const getUnmatchedPatients = (state: RootState) =>
  getChewyPetsState(state).unmatchedPatients

export const getIsEmailsLoading = (state: RootState) =>
  getChewyPetsState(state).emailsLoading
export const getEmailMatches = (state: RootState) =>
  getChewyPetsState(state).emails
export const getEmailError = (state: RootState) =>
  getChewyPetsState(state).emailsError

export const getUnmatchedPetsIds = (state: RootState) =>
  getChewyPetsState(state).unmatchedPetsList
export const getUnmatchedPatientsIds = (state: RootState) =>
  getChewyPetsState(state).unmatchedPatientsList
export const getMatchedPetsIds = (state: RootState) =>
  getChewyPetsState(state).matchedPetsList

export const getAllPetsIds = createSelector(
  getMatchedPetsIds,
  getUnmatchedPetsIds,
  (matchedPetsIds, unmatchedPetsIds) => [
    ...matchedPetsIds,
    ...unmatchedPetsIds,
  ],
)

export const getPatientToMatchById = (id: string) =>
  createSelector(
    getUnmatchedPatients,
    getMatchedPets,
    (unmatchedPatients, matchedPets) =>
      unmatchedPatients[id] || matchedPets[id].rhapsodyPatient,
  )

export const getPetToMatchById = (id: string | Nil) =>
  createSelector(
    getUnmatchedPets,
    getMatchedPets,
    (unmatchedPets, matchedPets) => {
      if (id) {
        return unmatchedPets[id] || matchedPets[id].chewyPet || {}
      }
      return {} as ChewyPet
    },
  )

export const getMatchChewyUserIsLoading = (state: RootState) =>
  getChewyPetsState(state).isEmailMatchLoading
export const getMatchChewyUserResult = (state: RootState) =>
  getChewyPetsState(state).isEmailMatch

export const getUnassignedAutoMatches = () =>
  createSelector(getPatientsMap, getMatchedPets, (patients, matchedPets) => {
    if (R.isEmpty(matchedPets)) return []

    return Object.values(matchedPets)
      .filter(
        (match) => !patients[match.rhapsodyPatient.rhapsodyId].hasKyriosId,
      )
      .map((match) => match.chewyPet)
  })

export const getFailedEmails = (state: RootState) =>
  getChewyPetsState(state).failedEmails
