import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { getFindingsState } from '~/store/reducers/soap'

import FindingsTable from './FindingsTable'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleColumn: {
      borderRight: theme.constants.tableBorder,
    },
  }),
  { name: 'SoapExaminationLocked' },
)

const SoapExaminationLocked = () => {
  const classes = useStyles()
  const findingsState = useSelector(getFindingsState)
  const { t } = useTranslation(['Common', 'Soap'])

  return (
    <Grid container className={classes.container} wrap="nowrap">
      <Grid item className={classes.titleColumn} p={2} xs={2}>
        <Text variant="h4">{t('Common:EXAM_NOUN')}</Text>
      </Grid>
      <Grid container item xs p={2} wrap="nowrap">
        {Object.keys(findingsState).length > 0 ? (
          <FindingsTable findingsState={findingsState} />
        ) : (
          <Text variant="body">
            {t('Soap:SOAP_EXAMINATION_LOCKED.NO_EXAM_FINDING')}
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

export default SoapExaminationLocked
