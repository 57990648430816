export enum TimelineEntryType {
  APPOINTMENT = 'APPOINTMENT',
  ATTACHMENT = 'ATTACHMENT',
  ATTACHMENT_GROUP = 'ATTACHMENT_GROUP',
  CONVERSATION = 'CONVERSATION',
  DIAGNOSIS = 'DIAGNOSIS,PROBLEM_DIAGNOSIS',
  ESTIMATE = 'ESTIMATE',
  IMAGING = 'IMAGING',
  IMPORTED_HISTORY = 'IMPORTED_HISTORY',
  LAB_TEST = 'LAB_TEST',
  NOTE = 'NOTE',
  OTC_SALE = 'OTC_SALE',
  PRESCRIPTION = 'PRESCRIPTION',
  QUESTION = 'QUESTION',
  REMINDER = 'REMINDER',
  SURGERY = 'SURGERY',
  TASK = 'TASK',
  VACCINATION = 'VACCINATION',
  VITAL = 'VITAL',
}

export const TimelineEntryTypesList = Object.values(TimelineEntryType).filter(
  (type) => type !== TimelineEntryType.ATTACHMENT,
)
