import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DetailsBackButton from '~/components/dashboard/clients/DetailsBackButton'
import EditableTitle from '~/components/dashboard/communications/common/EditableTitle'
import Chat from '~/components/dashboard/communications/conversations-chat/Chat'
import { MessageVariant } from '~/components/dashboard/communications/conversations-chat/message/MessageCard'
import MessageFormattingArea from '~/components/dashboard/communications/conversations-chat/MessageFormattingArea'
import TransportIcon from '~/components/dashboard/communications/TransportIcon'
import { editConversation } from '~/store/actions/conversations'
import { navigateToConversationsList } from '~/store/actions/soap'
import { getConversationById } from '~/store/reducers/conversations'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      height: '100%',
      width: '100%',
    },
    chat: {
      flex: 1,
      overflow: 'auto',
    },
    backPanel: {
      backgroundColor: theme.colors.soapStatusBar,
      paddingLeft: theme.spacing(3),
    },
    chatHeader: {
      height: 73,
      padding: theme.spacing(1, 3),
      borderRadius: 2,
      borderBottom: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: theme.constants.listItemShadow,
    },
    transportIcon: {
      color: theme.colors.signIconBackground,
      fontSize: '3.6rem',
    },
  }),
  { name: 'ChatContainer' },
)

export interface ChatContainerProps {
  conversationId: string
  editDisabled?: boolean
  expanded: boolean
}

const ChatContainer = ({
  conversationId,
  expanded,
  editDisabled,
}: ChatContainerProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const conversation = useSelector(getConversationById(conversationId))

  const chatVariant = expanded ? MessageVariant.PRIMARY : MessageVariant.MINI

  const isContextItem = useIsCurrentContextItem(conversation)

  const setTitle = (title: string) => {
    dispatch(editConversation(conversationId, { title }))
  }

  const backAction = () => {
    dispatch(navigateToConversationsList())
  }

  return (
    <Grid container className={classes.root} direction="column">
      <Grid container item className={classes.backPanel}>
        <DetailsBackButton fullWidth onClick={backAction}>
          {t('Common:BACK_TO_CONVERSATIONS_LIST')}
        </DetailsBackButton>
      </Grid>

      <Grid container item alignItems="center" className={classes.chatHeader}>
        <Grid item>
          <TransportIcon
            classes={{ transportIcon: classes.transportIcon }}
            type={conversation?.transport}
          />
        </Grid>

        <EditableTitle
          readOnly={!isContextItem}
          setTitle={setTitle}
          title={conversation?.title}
        />
      </Grid>

      <Grid container item className={classes.chat} direction="column">
        <Chat conversationId={conversationId} variant={chatVariant} />
      </Grid>

      {isContextItem && (
        <Grid container item>
          <MessageFormattingArea
            hideStatusUpdateButtons
            conversationId={conversationId}
            disabled={editDisabled}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ChatContainer
