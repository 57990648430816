import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  Defaults,
  LanguageUtils,
  PermissionArea,
  PuiTextArea,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import LeaveConfirmationDialog from '~/components/common/dialog/LeaveConfirmationDialog'
import ScrollableColumns, {
  ScrollableColumnsHandle,
} from '~/components/common/lists/scrollable-columns/ScrollableColumns'
import DialogNames from '~/constants/DialogNames'
import { VITALS_LAYOUT_CONSTANTS } from '~/constants/vitals'
import { changeUnits, getUnitsState } from '~/store/duck/settings'
import {
  addVital,
  fetchMoreVitals,
  getVitalsIsLoading,
  getVitalsIsSaving,
} from '~/store/duck/vitals'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getConstantMap } from '~/store/reducers/constants'
import {
  getClientId,
  getPatientId,
  getSoapId,
  getSoapVitals,
  getVitalsCount,
} from '~/store/reducers/soap'

import { vitalsTableConfig } from '../../soapV2/vitals/config/vitalsTableConfig'
import VitalsDataTable from './VitalsDataTable'
import VitalsFormInput from './VitalsFormInput'
import VitalsHeaderColumn from './VitalsHeaderColumn'
import {
  getVitalNotesField,
  getVitalsFields,
  getVitalsState,
  getVitalValueField,
  hasUnsavedVitalsByValue,
  // @ts-ignore
} from './vitalUtils'

const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    table: {},
    headerStickContainer: {
      width: `calc(100% + ${theme.spacing(6)})`,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      position: 'sticky',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      backgroundColor: theme.colors.contentBackground,
      top:
        theme.constants.soapHeaderHeight +
        (theme.mixins.toolbar.minHeight as number),
      [theme.breakpoints.down('md')]: {
        top: theme.constants.soapHeaderHeight,
      },
    },
    headerStickContainerV2: {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      position: 'sticky',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      backgroundColor: theme.colors.contentBackground,
      border: theme.constants.tableBorder,
      top:
        theme.constants.soapHeaderHeight +
        (theme.mixins.toolbar.minHeight as number),
      [theme.breakpoints.down('md')]: {
        top: theme.constants.soapHeaderHeight,
      },
    },
    tableRow: {
      height: theme.spacing(7),
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    expandedRow: {
      height: theme.spacing(13),
    },
    textArea: {
      width: 180,
    },
    textAreaIfVitalsWidget2Enabled: {
      width: 200,
    },
    textAreaInput: {
      height: 40,
      margin: 0,
    },
    textAreaInputExpanded: {
      height: 85,
      margin: 0,
    },
    cell: {
      borderBottom: 'none',
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      padding: theme.spacing(1, 1, 0, 1),
      verticalAlign: 'top',
    },
    rowTitleCell: {
      padding: theme.spacing(2),
      maxWidth: VITALS_LAYOUT_CONSTANTS.TITLE_COLUMN_WIDTH,
    },
    button: {
      minWidth: 136,
      height: theme.spacing(4),
      marginLeft: theme.spacing(0),
    },
    textWrapper: {
      width: VITALS_LAYOUT_CONSTANTS.TITLE_COLUMN_WIDTH,
    },
    scrollableHeader: {
      height: 37,
    },
    scrollableScrollButtonRight: {
      marginLeft: 0,
    },
    scrollableItem: {
      minWidth: VITALS_LAYOUT_CONSTANTS.SCROLLABLE_ITEM_WIDTH,
      maxWidth: VITALS_LAYOUT_CONSTANTS.SCROLLABLE_ITEM_WIDTH,
    },
    notesInput: {
      maxHeight: 40,
    },
    notesInputExpanded: {
      maxHeight: 85,
    },
    headerV2: {
      height: VITALS_LAYOUT_CONSTANTS.HEADER_HEIGHT,
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'VitalsTable' },
)

export interface VitalsTableProps {
  classes?: ClassesType<typeof useStyles>
}

const VitalsTable = ({ classes: classesProp }: VitalsTableProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const permissions = useSelector(getCRUDByArea(PermissionArea.VITAL))
  const soapId = useSelector(getSoapId)
  const vitals = useSelector(getSoapVitals)
  const vitalsCount = useSelector(getVitalsCount)
  const unitsState = useSelector(getUnitsState)
  const constantMap = useSelector(getConstantMap)
  const classes = useStyles({ classes: classesProp })
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const isLoading = useSelector(getVitalsIsLoading)
  const isSaving = useSelector(getVitalsIsSaving)
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))

  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false)

  const contentTableRef = useRef<HTMLDivElement>(null)
  const scrollableStepperTableRef = useRef<ScrollableColumnsHandle>(null)

  const editDisabled = !SOAPPermissions.update || !permissions.update

  const { fields, validate, reset } = useFields(
    getVitalsFields(unitsState, vitalsTableConfig),
    false,
  )

  const hasUnsavedVitals = hasUnsavedVitalsByValue(fields)

  const handleAddVital = () => {
    if (validate()) {
      const vital = getVitalsState(fields, soapId, unitsState)
      dispatch(addVital(vital, soapId!, clientId, patientId!))
      if (scrollableStepperTableRef.current) {
        scrollableStepperTableRef.current.scrollToColumnIndex(0)
      }
      setLoadMoreDisabled(false)
      reset()
    }
  }

  const handleScrollOffsetEnd = () => {
    if (patientId && soapId && vitalsCount > vitals.length) {
      dispatch(
        fetchMoreVitals({
          patientId,
          includeDeleted: false,
          from: vitals.length,
          to: vitals.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT - 1,
        }),
      )
    } else if (!loadMoreDisabled) {
      setLoadMoreDisabled(true)
    }
  }

  const controlTableWidth = VITALS_LAYOUT_CONSTANTS.CONTROL_TABLE_WIDTH

  return (
    <Grid container item>
      <Grid
        container
        className={classes.headerStickContainerV2}
        mb={0}
        mx={0}
        pb={1}
        pl={0}
        pr={1}
        pt={1}
        wrap="nowrap"
      >
        <Grid
          container
          item
          alignItems="center"
          maxWidth={controlTableWidth}
          minWidth={controlTableWidth}
        >
          <Grid className={classes.textWrapper}>
            <Text ml={2} variant="h4">
              {t('Common:VITALS')}
            </Text>
          </Grid>
          {hasUnsavedVitals && !editDisabled && (
            <Fab
              className={classes.button}
              color="inherit"
              type="button"
              variant="extended"
              onClick={handleAddVital}
            >
              {t('Common:ADD_VITALS')}
            </Fab>
          )}
        </Grid>
        <Grid container item alignItems="center">
          <ScrollableColumns
            ColumnHeaderComponent={VitalsHeaderColumn}
            classes={{
              header: classes.scrollableHeader,
              scrollButtonRight: classes.scrollableScrollButtonRight,
              columnHeaderContainer: classes.scrollableItem,
            }}
            columns={vitals}
            contentRef={contentTableRef}
            isDisabledNextButton={loadMoreDisabled}
            isLoadingNextButton={isLoading || isSaving}
            ref={scrollableStepperTableRef}
            onScrollOffsetChange={() =>
              loadMoreDisabled && setLoadMoreDisabled(false)
            }
            onScrollOffsetEnd={handleScrollOffsetEnd}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.tableContainer}
        id="vitals-inputs-container"
        wrap="nowrap"
      >
        <Grid
          container
          item
          maxWidth={controlTableWidth}
          minWidth={controlTableWidth}
        >
          <Table className={classes.table}>
            <TableBody>
              {vitalsTableConfig.map((configItem) => {
                const valueField = getVitalValueField(configItem.id, fields)
                const notesField = getVitalNotesField(configItem.id, fields)
                const areLbsUnits =
                  configItem.id === UnitTypes.WEIGHT &&
                  unitsState.weight === WeightTypes.LBS
                const isExpanded = areLbsUnits
                const configItemDisplayName =
                  LanguageUtils.getTranslatedFieldName(configItem)
                const willOverflow =
                  configItemDisplayName.length >
                  VITALS_LAYOUT_CONSTANTS.MAX_CHARS_TITLE_COLUMN
                const tooltipTitle = willOverflow
                  ? configItem.tooltip
                    ? `${configItemDisplayName} - ${configItem.tooltip}`
                    : configItemDisplayName
                  : configItem.tooltip || ''

                return (
                  <TableRow
                    className={classNames(classes.tableRow, {
                      [classes.expandedRow]: isExpanded,
                    })}
                    id={`vitals-inputs-row-${configItem.id}`}
                    key={configItem.name}
                  >
                    <TableCell
                      className={classNames(
                        classes.cell,
                        classes.rowTitleCell,
                        {},
                      )}
                      component="th"
                      id={`vitals-inputs-row-${configItem.id}-title`}
                      scope="row"
                    >
                      <Tooltip title={tooltipTitle}>
                        <Text noWrap variant="h4">
                          {configItemDisplayName}
                        </Text>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      className={classes.cell}
                      id={`vitals-inputs-row-${configItem.id}-input`}
                    >
                      <VitalsFormInput
                        editDisabled={editDisabled}
                        field={valueField}
                        formInput={configItem.formInput}
                        types={constantMap}
                        unitsState={unitsState}
                        onChangeUnits={(key, value) =>
                          dispatch(changeUnits({ [key]: value }))
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={classes.cell}
                      id={`vitals-inputs-row-${configItem.id}-notes`}
                    >
                      <PuiTextArea
                        InputProps={{
                          classes: {
                            multiline: isExpanded
                              ? classes.textAreaInputExpanded
                              : classes.textAreaInput,
                          },
                        }}
                        className={classes.textAreaIfVitalsWidget2Enabled}
                        classes={{
                          input: isExpanded
                            ? classes.notesInputExpanded
                            : classes.notesInput,
                        }}
                        disabled={editDisabled}
                        field={notesField}
                        margin="none"
                        minRows={isExpanded ? 4 : 2}
                        placeholder={t('Common:NOTES')}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <LeaveConfirmationDialog
              navigateOnCancel
              dialogName={DialogNames.VITALS_CONFIRMATION}
              hasUnsavedData={hasUnsavedVitals && !editDisabled}
              onProceed={handleAddVital}
            />
          </Table>
        </Grid>
        <VitalsDataTable
          classes={{ cell: classes.scrollableItem }}
          ref={contentTableRef}
          vitals={vitals}
        />
      </Grid>
    </Grid>
  )
}

export default VitalsTable
