import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Text, TextWithTooltip, Utils } from '@pbt/pbt-ui-components'
import { TextProps } from '@pbt/pbt-ui-components/src/components/Text'

import ClientMobileAppChip from '~/components/common/ClientMobileAppChip'
import ClientWarningChip from '~/components/common/ClientWarningChip'
import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import Link from '~/components/common/link/Link'
import PatientWarningChip from '~/components/common/PatientWarningChip'
import { fetchClient } from '~/store/actions/clients'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

import useGetPreferredContactMethod from '../clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { PatientPreferencesChip } from '../clients/patient/PatientPreferencesChip'
import { ClientPreferencesChip } from '../clients/preferences/ClientPreferencesChip'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '../link-chewy-account/LinkedChewyAccountContainer'
import WellnessPlanMemberButton from '../wellness-plans/WellnessPlanMemberButton'

const useStyles = makeStyles(
  (theme) => ({
    chip: {
      width: 16,
      height: 16,
      margin: 1,
      alignContent: 'center',
      '&:last-child': {
        marginRight: theme.spacing(0.5),
      },
    },
    wellnessChip: {
      width: 16,
      height: 16,
    },
    button: {
      display: 'flex',
    },
    nameLink: {
      cursor: 'pointer',
    },
  }),
  { name: 'ClientAndPatient' },
)

interface ClientAndPatientProps extends GridProps {
  clientId: string | Nil
  loadClient?: boolean
  patientId: string | Nil
  textVariant?: TextProps['variant']
}

const ClientAndPatient = ({
  clientId,
  patientId,
  loadClient = false,
  textVariant = 'lowAccent2',
  ...props
}: ClientAndPatientProps) => {
  const classes = useStyles()

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const dispatch = useDispatch()

  useEffect(() => {
    if (loadClient && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [loadClient, clientId])

  const preferredPhoneContactMethod = useGetPreferredContactMethod({
    client,
    phoneOnly: true,
  })

  if (!client && !patient) {
    return null
  }

  return (
    <Grid container alignItems="center" flexWrap="nowrap" {...props}>
      <Grid container item flexWrap="nowrap" width="fit-content">
        <ClientWarningChip className={classes.chip} clientId={clientId} />
        <ClientPreferencesChip className={classes.chip} clientId={clientId} />
        <LinkedChewyAccountContainer
          accountType={KyriosAccountType.CLIENT}
          className={classes.chip}
          clientId={clientId}
        />
        <ClientMobileAppChip className={classes.chip} clientId={clientId} />
        <BusinessShareIcon
          businessIds={client?.clientInContextBusinesses}
          className={classes.chip}
        />
      </Grid>
      <Grid item mr={0.5}>
        <Link
          className={classes.nameLink}
          id="soap-header-client-link"
          target="_blank"
          to={`/client/${clientId}`}
        >
          <TextWithTooltip
            noIcon
            underline
            TooltipProps={{ tooltipPlacement: 'bottom-start' }}
            tooltipText={`${preferredPhoneContactMethod?.value ?? ''}\n${
              client?.email ?? ''
            }`}
            variant={textVariant}
          >
            {Utils.getPersonString(client)}
          </TextWithTooltip>
        </Link>
      </Grid>
      {client && patient && (
        <Grid item mr={0.5}>
          <Text variant={textVariant}>{'>'}</Text>
        </Grid>
      )}
      {patient && (
        <>
          <Grid
            container
            item
            alignItems="center"
            flexWrap="nowrap"
            width="fit-content"
          >
            <PatientWarningChip
              className={classes.chip}
              patientId={patientId}
            />
            <PatientPreferencesChip
              className={classes.chip}
              patientId={patientId}
            />
            <LinkedChewyAccountContainer
              accountType={KyriosAccountType.PATIENT}
              className={classes.chip}
              clientId={clientId}
              patientId={patientId}
            />
            <WellnessPlanMemberButton
              chip
              readonly
              buttonClassName={classes.chip}
              className={classes.wellnessChip}
              clientId={clientId}
              patientId={patientId}
            />
          </Grid>
          <Grid item mx={0.5}>
            <Link
              className={classes.nameLink}
              id="soap-header-patient-link"
              target="_blank"
              to={`/patient/${patientId}`}
            >
              <Text underline variant={textVariant}>
                {patient?.name}:
              </Text>
            </Link>
          </Grid>
          <PatientInfoLabel patientId={patientId} variant={textVariant} />
        </>
      )}
    </Grid>
  )
}

export default ClientAndPatient
