import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps, Text } from '@pbt/pbt-ui-components'

import { AppSelector } from '~/store'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    content: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'TypographyDialog' },
)

export interface TypographyDialogProps extends PuiDialogProps {
  contentSelector: AppSelector<string[]>
}

const TypographyDialog = ({
  open,
  onClose,
  contentSelector,
  title,
  placeholder,
}: TypographyDialogProps) => {
  const classes = useStyles()

  const content = useSelector(contentSelector)
  const contentArray = (Array.isArray(content) ? content : [content]).filter(
    Boolean,
  )
  const contentIsAvailable = Boolean(contentArray.length)

  return (
    <PuiDialog
      aria-labelledby="typography-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={title}
      onClose={onClose}
    >
      <Grid container className={classes.content} direction="column">
        {contentIsAvailable
          ? contentArray.map((entry) => (
              <Text key={entry} mb={2} variant="body2">
                {entry}
              </Text>
            ))
          : placeholder}
      </Grid>
    </PuiDialog>
  )
}

export default TypographyDialog
