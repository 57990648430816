import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BusinessRole,
  ControlButtonGroup,
  ControlButtonGroupName,
  LanguageUtils,
  PuiTextField,
  Role,
  Text,
  useFields,
  User,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { updateActiveStatus, updateMember } from '~/store/actions/members'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getMemberIsLoading } from '~/store/reducers/members'
import { getRolesMap } from '~/store/reducers/roles'
import { BusinessRoleItem } from '~/types'
import { isStaffRole } from '~/utils/roleUtils'
import useDialog from '~/utils/useDialog'
import useGetAssignedRoles from '~/utils/useGetAssignedRoles'

const useStyles = makeStyles(
  (theme) => ({
    tableItem: {
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'TeamConfigurationItem' },
)

interface TeamConfigurationItemProps {
  businessId: string
  className?: string
  editable?: boolean
  team: User
}

const TeamConfigurationItem = ({
  className,
  team,
  editable = true,
  businessId: businessIdProp,
}: TeamConfigurationItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [editMode, setEditMode] = useState(false)

  const rolesMap = useSelector(getRolesMap)
  const isLoading = useSelector(getMemberIsLoading)
  const isTeamsPhase2Enabled = useSelector(
    getFeatureToggle(FeatureToggle.TEAMS_PHASE_2),
  )

  const getAssignedRoles = useGetAssignedRoles()

  const [openRolesDialog] = useDialog(DialogNames.ROLES)

  const visibleRolesMap = R.filter(
    R.allPass([(role: Role) => !role.hidden, isStaffRole]),
  )(rolesMap)

  const businessToRoleList = team?.businessToRoleList ?? []

  const assignedRoles = getAssignedRoles(businessToRoleList)

  const initialBusinessRoleList = assignedRoles
    .filter(
      (item) =>
        item?.role && !item.isGroup && item?.business === businessIdProp,
    )
    .map(({ business: businessId, role: roleId, isGroup }) => ({
      businessId,
      roleId,
      isGroup,
    }))

  const rolesComparator = (item1: BusinessRoleItem, item2: BusinessRoleItem) =>
    visibleRolesMap[item1.roleId]?.name?.localeCompare(
      visibleRolesMap[item2.roleId]?.name,
    )

  const roleIdToRole = (roleId: string) => ({
    businessId: businessIdProp,
    roleId,
    isGroup: false,
  })

  const {
    reset,
    fields: { active, name, roles },
  } = useFields(
    [
      {
        name: 'active',
        type: 'toggle',
        validators: ['required'],
        initialValue: team.active,
      },
      { name: 'name', validators: ['required'], initialValue: team.firstName },
      {
        name: 'roles',
        validators: ['required'],
        initialValue: initialBusinessRoleList,
      },
    ],
    false,
  )

  const handleSave = () => {
    dispatch(
      updateMember({
        ...team,
        firstName: name.value,
        businessToRoleList: roles.value.map((role: BusinessRoleItem) => ({
          business: businessIdProp,
          role: role.roleId,
          isGroup: false,
        })) as BusinessRole[],
      }),
    )
  }

  const handleSaveRoles = (businessId: string, newRoleIds: string[]) => {
    dispatch(
      updateMember({
        ...team,
        businessToRoleList: newRoleIds.map((roleId) => ({
          business: businessId,
          role: roleId,
          isGroup: false,
        })) as BusinessRole[],
      }),
    )
  }

  const handleUpdateRoles = (_: string, newRoleIds: string[]) => {
    roles.setValue(newRoleIds.map(roleIdToRole))
  }

  const getRolesString = (rolesList: BusinessRoleItem[]) =>
    R.pipe(
      R.sort(rolesComparator),
      R.map(({ roleId }) =>
        LanguageUtils.getTranslatedFieldName(visibleRolesMap[roleId]),
      ),
      R.join(', '),
    )(rolesList)

  const handleEditRoles = () => {
    openRolesDialog({
      businessId: businessIdProp,
      selectedRoleList: roles.value,
      onSave: isTeamsPhase2Enabled ? handleUpdateRoles : handleSaveRoles,
    })
  }

  const handleSaveChanges = () => {
    handleSave()
    setEditMode(false)
  }

  const handleCancelEdit = () => {
    reset()
    setEditMode(false)
  }

  useEffect(() => {
    dispatch(updateActiveStatus(team.id, active.value))
  }, [active.value])

  return (
    <Grid
      container
      item
      className={classNames(classes.tableItem, className)}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" px={1.5} xs={2}>
        <PuiSwitch disabled={!editable || isLoading} field={active} />
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={3}>
        {editMode ? (
          <PuiTextField
            autoFocus
            disabled={isLoading}
            field={name}
            inputProps={{ maxLength: 100 }}
            margin="none"
          />
        ) : (
          <Dotdotdot clamp={2}>
            <Text disabled={!team.active} variant="body2">
              {team.firstName}
            </Text>
          </Dotdotdot>
        )}
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        flexWrap="nowrap"
        px={1.5}
        xs={5}
      >
        <Grid container item flex="1 1 auto" width="auto">
          <Dotdotdot clamp={2}>
            <Text disabled={!team.active} variant="body2">
              {getRolesString(editMode ? roles.value : initialBusinessRoleList)}
            </Text>
          </Dotdotdot>
        </Grid>
        {editMode && (
          <Grid
            container
            item
            flex="0 0 auto"
            justifyContent="flex-end"
            width="auto"
          >
            <ControlButtonGroup
              buttons={[
                {
                  name: ControlButtonGroupName.EDIT,
                  onClick: handleEditRoles,
                  isLoading,
                },
              ]}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        px={1.5}
        xs={3}
      >
        <ControlButtonGroup
          buttons={
            editMode
              ? [
                  {
                    name: ControlButtonGroupName.CHECK,
                    onClick: handleSaveChanges,
                    isLoading,
                  },
                  {
                    name: ControlButtonGroupName.REMOVE,
                    onClick: handleCancelEdit,
                    isLoading,
                  },
                ]
              : [
                  {
                    name: ControlButtonGroupName.EDIT,
                    onClick: isTeamsPhase2Enabled
                      ? () => setEditMode(true)
                      : handleEditRoles,
                    isLoading,
                  },
                ]
          }
        />
      </Grid>
    </Grid>
  )
}

export default TeamConfigurationItem
