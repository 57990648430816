import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInterval } from '@pbt/pbt-ui-components'

import { MIGRATION_STATUS_POLLING_INTERVAL } from '~/constants/migration'
import { fetchMigrationAnalyzeStatus } from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getProgressEntities,
} from '~/store/reducers/migration'

import CoreMigrationProgressComponent from './CoreMigrationProgressComponent'

const StepAnalysisInProgress = () => {
  const dispatch = useDispatch()
  const sessionId = useSelector(getCurrentSessionId)
  const entities = useSelector(getProgressEntities) || []

  const fetchStatus = useCallback(() => {
    if (sessionId) {
      dispatch(fetchMigrationAnalyzeStatus(sessionId))
    }
  }, [sessionId])

  useEffect(() => {
    fetchStatus()
  }, [])

  useInterval(() => {
    fetchStatus()
  }, MIGRATION_STATUS_POLLING_INTERVAL)

  return (
    <CoreMigrationProgressComponent
      entities={entities}
      finished={false}
      onOk={() => {}}
    />
  )
}

export default StepAnalysisInProgress
