import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Field, Text } from '@pbt/pbt-ui-components'

import WellnessPlanPriceTextField, {
  WellnessPlanPriceTextFieldProps,
} from './WellnessPlanPriceTextField'

export interface WellnessPlanMultiPriceTextFieldProps
  extends Omit<WellnessPlanPriceTextFieldProps, 'field'> {
  fields: Record<string, Field>
}

const WellnessPlanMultiPriceTextField = ({
  fields,
  ...rest
}: WellnessPlanMultiPriceTextFieldProps) => {
  const { t } = useTranslation('Common')

  const priceFields = Object.values(fields).filter((field) => {
    const [priceId, fieldType] = field.name.split('_')
    const isPriceField = fieldType === 'price'

    return isPriceField && fields[`${priceId}_priceEnabled`].value
  })

  return (
    <Grid container item alignItems="center" wrap="nowrap">
      {priceFields.map((field, index) => (
        <React.Fragment key={field.name}>
          {index > 0 && (
            <Text strong mx={2} variant="body">
              {t('Common:OR').toLowerCase()}
            </Text>
          )}
          <WellnessPlanPriceTextField field={field} {...rest} />
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default WellnessPlanMultiPriceTextField
