import { Defaults, TableFilter } from '@pbt/pbt-ui-components'

import { Shipment, ShipmentItem, ShipmentToUpdate } from '~/types'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const createShipment = (businessId: string, shipment: Shipment) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment'),
    { method: 'POST', params: { businessId }, data: shipment },
    true,
    Schema.shipment,
  )

export const fetchShipments = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment/list'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.shipmentsList,
  )

export const fetchShipmentsV2 = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getPreludeAPIPath('inventory-integration/v2/shipment/list'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.shipmentsList,
  )

export const fetchShipmentsWithFilter = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: any,
) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment/list'),
    { method: 'POST', params: { businessId, from, to }, data: filters || {} },
    true,
    Schema.shipmentsList,
  )

export const fetchShipmentsWithFilterV2 = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: Record<string, TableFilter['value']>,
) =>
  request(
    getPreludeAPIPath('inventory-integration/v2/shipment/list'),
    { method: 'GET', params: { businessId, from, to, ...filters } },
    true,
    Schema.shipmentsList,
  )

export const fetchShipment = (businessId: string, shipmentId: string) =>
  request(
    getPreludeAPIPath(`inventory-integration/shipment/${shipmentId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.shipment,
  )

export const editShipment = (
  businessId: string,
  shipment: ShipmentToUpdate,
  withItems: boolean,
) =>
  request(
    getPreludeAPIPath(`inventory-integration/shipment/${shipment.id}`),
    { method: 'PUT', params: { businessId, withItems }, data: shipment },
    true,
    Schema.shipment,
  )

export const deleteShipment = (businessId: string, shipmentId: string) =>
  request(
    getPreludeAPIPath(`inventory-integration/shipment/${shipmentId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const createShipmentItem = (
  businessId: string,
  shipmentItem: ShipmentItem,
) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment/item'),
    { method: 'POST', params: { businessId }, data: shipmentItem },
    true,
  )

export const editShipmentItem = (
  businessId: string,
  shipmentItem: ShipmentItem,
) =>
  request(
    getPreludeAPIPath(`inventory-integration/shipment/item/${shipmentItem.id}`),
    { method: 'PUT', params: { businessId }, data: shipmentItem },
    true,
  )

export const deleteShipmentItem = (
  businessId: string,
  shipmentItemId: string,
) =>
  request(
    getPreludeAPIPath(`inventory-integration/shipment/item/${shipmentItemId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )
