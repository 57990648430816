import { Nil } from '@pbt/pbt-ui-components'

import { MigrationType } from '~/constants/migration'
import {
  ImportSettingsToEdit,
  MigrationException,
  MigrationSession,
  MigrationSessionFile,
} from '~/types/entities/migration'

import { objectToFormData } from '../utils'
import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchSessionsByBusinessId = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/business/${businessId}`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
    Schema.importSessionArray,
  )

export const fetchImportLogsBySessionId = (
  businessId: string,
  sessionId: string | Nil,
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/${sessionId}/logs`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const createImportSession = (
  businessId: string,
  selectedBusinessId: string,
  exporterPimsId: string,
  pimsId: string,
  sourcePimsId: string,
) =>
  request(
    getPreludeAPIPath('import-api/sessions'),
    {
      method: 'POST',
      data: {
        pimsId,
        sourcePimsId,
        exporterPimsId,
        businessId: selectedBusinessId,
        status: 'EXPORTER_DOWNLOADED',
        type: 'RHAPSODY_ENTERPRISE',
      },
      params: { businessId },
    },
    true,
  )

export const updateImportSession = (
  businessId: string,
  session: MigrationSession,
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/${session.id}`),
    { method: 'PUT', data: session, params: { businessId } },
    true,
  )

export const startAnalysis = (
  businessId: string,
  sessionId: string | Nil,
  entityTypes: string[],
) =>
  request(
    getPreludeAPIPath('import-api/import/start'),
    {
      method: 'POST',
      data: {
        entityTypes,
        sessionId,
        mode: 'ANALYSIS',
      },
      params: {
        businessId,
      },
    },
    true,
  )

export const startAnalysisEnterprise = (
  businessId: string,
  sessionId: string | Nil,
  entityTypes: string[],
) =>
  request(
    getPreludeAPIPath('import-api/enterprise/import/full/start'),
    {
      method: 'POST',
      data: {
        entityTypes,
        sessionId,
        mode: 'ANALYSIS',
      },
      params: {
        businessId,
      },
    },
    true,
  )

export const fetchAnalysisStatus = (businessId: string, sessionId: string) =>
  request(
    getPreludeAPIPath('import-api/import/status'),
    {
      method: 'GET',
      params: {
        businessId,
        sessionId,
        mode: 'ANALYSIS',
      },
    },
    true,
  )

export const startImport = (
  businessId: string,
  sessionId: string | Nil,
  entityTypes: string[],
) =>
  request(
    getPreludeAPIPath('import-api/import/start'),
    {
      method: 'POST',
      data: {
        entityTypes,
        sessionId,
        mode: 'IMPORT',
      },
      params: {
        businessId,
      },
    },
    true,
  )

export const startImportEnterprise = (
  businessId: string,
  sessionId: string | Nil,
  entityTypes: string[],
) =>
  request(
    getPreludeAPIPath('import-api/enterprise/import/full/start'),
    {
      method: 'POST',
      data: {
        entityTypes,
        sessionId,
        mode: 'IMPORT',
      },
      params: {
        businessId,
      },
    },
    true,
  )

export const fetchImportStatus = (
  businessId: string,
  sessionId: string | Nil,
) =>
  request(
    getPreludeAPIPath('import-api/import/status'),
    {
      method: 'GET',
      params: {
        businessId,
        sessionId,
        mode: 'IMPORT',
      },
    },
    true,
  )

export const fetchMappings = (
  businessId: string,
  sessionId: string | Nil,
  field: string,
  entity: string,
  from: number,
  to: number,
  includeResolved = false,
) =>
  request(
    getPreludeAPIPath('import-api/mappings'),
    {
      method: 'GET',
      params: {
        businessId,
        sessionId,
        entity,
        field,
        includeResolved,
        from,
        to,
      },
    },
    true,
    Schema.importMappingList,
  )

export const fetchEnterpriseMappings = (
  businessId: string,
  sessionId: string | Nil,
  field: string,
  entity: string,
  from: number,
  to: number,
  includeResolved = false,
) =>
  request(
    getPreludeAPIPath('import-api/enterprise/mappings'),
    {
      method: 'GET',
      params: {
        businessId,
        sessionId,
        entity,
        field,
        includeResolved,
        from,
        to,
      },
    },
    true,
    Schema.importMappingList,
  )

export const updateMapping = (
  businessId: string,
  sessionId: string | Nil,
  mapping: MigrationException,
) =>
  request(
    getPreludeAPIPath(`import-api/mappings/${mapping.id}`),
    { method: 'PUT', data: mapping, params: { businessId } },
    true,
  )

export const updateDeltaMapping = (
  businessId: string,
  sessionId: string | Nil,
  mapping: MigrationException,
) =>
  request(
    getPreludeAPIPath(`import-api/enterprise/mappings/${mapping.id}/apply`),
    { method: 'PUT', data: mapping, params: { businessId } },
    true,
  )

export const fetchFieldsBySessionId = (businessId: string, sessionId: string) =>
  request(
    getPreludeAPIPath('import-api/mappings/fields'),
    { method: 'GET', params: { businessId, sessionId } },
    true,
  )

export const fetchSettingBySessionId = (
  businessId: string,
  sessionId: string,
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/${sessionId}/setting`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const updateSettingBySessionId = (
  businessId: string,
  sessionId: string | Nil,
  settings: ImportSettingsToEdit | { timeZone: string },
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/${sessionId}/setting`),
    { method: 'PUT', data: settings, params: { businessId } },
    true,
  )

export const applyMappings = (
  businessId: string,
  sessionId: string | Nil,
  field: string,
  entity: string,
) =>
  request(
    getPreludeAPIPath('import-api/mappings/apply'),
    { method: 'POST', params: { businessId, sessionId, entity, field } },
    true,
  )

export const applyDeltaMappings = (
  businessId: string,
  sessionId: string | Nil,
  field: string,
  entity: string,
) =>
  request(
    getPreludeAPIPath('import-api/enterprise/mappings/apply'),
    { method: 'PUT', params: { businessId, sessionId, entity, field } },
    true,
  )

export const fetchExporterUrl = (
  businessId: string,
  pimsId: string,
  selectedBusinessId: string,
  exporterType: MigrationType | undefined,
) =>
  request(
    getPreludeAPIPath('import-api/sessions/exporter'),
    {
      method: 'POST',
      data: { pimsId, businessId: selectedBusinessId },
      params: { businessId, type: exporterType },
    },
    true,
  )

export const fetchActivationToken = (
  businessId: string,
  selectedBusinessId: string,
  pimsId: string,
) =>
  request(
    getPreludeAPIPath(
      `import-api/enterprise/exporter/business/${selectedBusinessId}/token`,
    ),
    {
      method: 'GET',
      params: { businessId, pimsId },
    },
    true,
  )

export const fetchSessionFiles = (businessId: string, sessionId: string) =>
  request(
    getPreludeAPIPath(`import-api/files/sessions/${sessionId}`),
    {
      method: 'GET',
      params: { businessId },
    },
    true,
  )

export const uploadSessionFile = (
  businessId: string,
  sessionId: string,
  file: MigrationSessionFile,
) =>
  request(
    getPreludeAPIPath(`import-api/files/sessions/${sessionId}`),
    {
      method: 'POST',
      data: objectToFormData({ ...file, file: file.raw }),
      params: { businessId },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    true,
  )

export const deleteSessionFile = (
  businessId: string,
  sessionId: string | Nil,
  name: string,
  extension: string,
) =>
  request(
    getPreludeAPIPath(`import-api/files/sessions/${sessionId}`),
    {
      method: 'DELETE',
      params: { businessId, name, extension },
    },
    true,
  )

export const fetchSessionFileUrl = (
  businessId: string,
  sessionId: string | Nil,
  name: string,
  extension: string,
) =>
  request(
    getPreludeAPIPath(`import-api/files/sessions/${sessionId}/url`),
    {
      method: 'GET',
      params: { businessId, name, extension },
    },
    true,
  )

export const startPreprocessing = (
  businessId: string,
  sessionId: string | Nil,
) =>
  request(
    getPreludeAPIPath('import-api/enterprise/preprocessing/start'),
    {
      method: 'POST',
      params: { businessId, importSessionId: sessionId },
    },
    true,
  )

export const fetchPreprocessingStatus = (
  businessId: string,
  sessionId: string,
) =>
  request(
    getPreludeAPIPath('import-api/enterprise/preprocessing/status'),
    {
      method: 'GET',
      params: { businessId, importSessionId: sessionId },
    },
    true,
  )

export const generateActivationToken = (
  businessId: string,
  pimsId: string,
  selectedBusinessId: string,
  exporterType: string,
) =>
  request(
    getPreludeAPIPath(
      `import-api/enterprise/exporter/business/${selectedBusinessId}/token`,
    ),
    {
      method: 'POST',
      data: { pimsId, businessId: selectedBusinessId },
      params: { businessId, type: exporterType },
    },
    true,
  )

export const fetchSessionSupportedEntities = (
  businessId: string,
  sessionId: string,
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/${sessionId}/entities`),
    {
      method: 'GET',
      params: { businessId },
    },
    true,
  )

export const fetchBusinessRelationsMappings = (
  businessId: string,
  selectedBusinessId: string,
  relationType: string,
) =>
  request(
    getPreludeAPIPath(`import-api/relations/business/${selectedBusinessId}`),
    {
      method: 'GET',
      params: { businessId, relationType },
    },
    true,
  )
