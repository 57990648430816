import React from 'react'

import { ProblemsContextProvider } from './ProblemsContext'
import SoapProblemsWidget, {
  SoapProblemsWidgetProps,
} from './SoapProblemsWidget'

const SoapProblemsWidgetWrapper = ({
  onHideSoapRail,
}: SoapProblemsWidgetProps) => (
  <ProblemsContextProvider>
    <SoapProblemsWidget onHideSoapRail={onHideSoapRail} />
  </ProblemsContextProvider>
)

export default SoapProblemsWidgetWrapper
