import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  Constant,
  LanguageUtils,
  PuiCheckbox,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  EnabledState,
  NotificationNotifyForNames,
} from '~/constants/notifications'
import {
  fetchNotificationSettings,
  updateNotificationSettings,
} from '~/store/actions/notifications'
import { getCurrentUserId } from '~/store/reducers/auth'
import {
  getNotificationNotifyForItem,
  getNotificationStyle,
  getNotificationType,
} from '~/store/reducers/constants'
import {
  getNotificationIsLoading,
  getNotificationIsSaving,
  getNotificationNotifyForItemSettings,
  getNotificationStyleSettings,
} from '~/store/reducers/notifications'
import { NotificationSetting } from '~/types'
import {
  getSettingsWithChangedEnabledState,
  mapSubListWithSettingsOfNotificationType,
  updateSettingEnabledState,
  updateSettingStateInList,
} from '~/utils/notifications'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import NotificationSettingsCombinationTable from './NotificationSettingsCombinationTable'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      position: 'relative',
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
      overflowX: 'auto',
    },
    borderColumn: {
      borderRight: theme.constants.tabBorder,
    },
    columnHeader: {
      height: 33,
    },
    columnSubHeader: {
      height: 20,
      borderBottom: theme.constants.tabBorder,
    },
    borderRight: {
      borderRight: theme.constants.tabBorder,
    },
    notifyAboutColumnSelectHeader: {
      borderBottom: theme.constants.tabBorder,
      height: 40,
    },
    tableRow: {
      height: 40,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    noRightBorder: {
      borderRight: 'none',
    },
    responsiveColumn: {
      minWidth: 'auto',
    },
    saveButton: {
      width: 175,
    },
    firstColumn: {
      width: 'auto',
    },
    notificationsColumn: {
      width: 104,
    },
    secondColumn: {
      position: 'relative',
    },
    link: {
      color: theme.colors.link,
    },
  }),
  { name: 'NotificationSettings' },
)

export interface NotificationSettingsProps {
  className?: string
  onSaved?: () => void
  personId: string
  readOnly?: boolean
}

const NotificationSettings = ({
  personId,
  readOnly = false,
  className: classNameProp,
  onSaved,
}: NotificationSettingsProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Notifications'])

  const NotifyForItemTypes = useSelector(getNotificationNotifyForItem) || []
  const NotificationStyles = useSelector(getNotificationStyle) || []
  const NotificationTypes: Constant[] = useSelector(getNotificationType) || []
  const currentUserId = useSelector(getCurrentUserId)

  const initialNotifyForItemSettingList = useSelector(
    getNotificationNotifyForItemSettings(personId),
  )
  const initialNotificationStyleSettingList = useSelector(
    getNotificationStyleSettings(personId),
  )
  const areSettingsEmpty =
    !initialNotifyForItemSettingList || !initialNotificationStyleSettingList
  const isLoading = useSelector(getNotificationIsLoading)
  const isSaving = useSelector(getNotificationIsSaving)

  const [notifyForItemSettingList, setNotifyForItemSettingList] = useState(
    initialNotifyForItemSettingList || [],
  )
  const [notificationStyleSettingList, setNotificationStyleSettingList] =
    useState(initialNotificationStyleSettingList || [])

  const [openNotificationStylesPreviewDialog] = useDialog(
    DialogNames.NOTIFICATION_STYLES_PREVIEW_DIALOG,
  )
  const [openProfileDialog] = useDialog(DialogNames.PROFILE)

  const onNotificationStylesPreview = () => {
    openNotificationStylesPreviewDialog()
  }

  const isForCurrentUser = currentUserId === personId

  const allNotificationSettings = [
    ...notifyForItemSettingList,
    ...notificationStyleSettingList,
  ]
  const noNotificationsList = NotificationTypes.map((type) => ({
    notificationTypeId: type.id,
    enabled: R.pipe(
      R.filter(R.propEq('notificationTypeId', type.id)),
      R.none(R.propEq('enabledState', EnabledState.ENABLED)),
    )(allNotificationSettings),
  }))

  const anyEnabledForNoNotifications = R.any(
    R.propEq('enabled', true),
    noNotificationsList,
  )
  const anyDisabledForNoNotifications = R.any(
    R.propEq('enabled', false),
    noNotificationsList,
  )
  const selectAllIndeterminateStateForNoNotifications =
    anyEnabledForNoNotifications && anyDisabledForNoNotifications
  const selectAllForNoNotificationsEnabled = R.all(
    R.propEq('enabled', true),
    noNotificationsList,
  )

  const updateCheckedForRow = (
    notificationTypeId: string,
    enabled: boolean,
    list: NotificationSetting[],
  ) =>
    mapSubListWithSettingsOfNotificationType(notificationTypeId, list, (it) =>
      updateSettingEnabledState(it, enabled),
    )

  const onNoNotificationForTypeSelected = (
    notificationTypeId: string,
    enabled: boolean,
  ) => {
    setNotifyForItemSettingList(
      updateCheckedForRow(
        notificationTypeId,
        enabled,
        notifyForItemSettingList,
      ),
    )
    setNotificationStyleSettingList(
      updateCheckedForRow(
        notificationTypeId,
        enabled,
        notificationStyleSettingList,
      ),
    )
  }

  const onNoNotificationSelectAllSelected = (enabled: boolean) => {
    setNotifyForItemSettingList(
      notifyForItemSettingList.map((it) =>
        updateSettingEnabledState(it, enabled),
      ),
    )
    setNotificationStyleSettingList(
      notificationStyleSettingList.map((it) =>
        updateSettingEnabledState(it, enabled),
      ),
    )
  }

  const handleNotificationsSavedAfterSave = useCloseAfterCreation(
    () => onSaved?.(),
    getNotificationIsSaving,
  )

  const handleSave = () => {
    const notificationSettings = {
      notifyForItemsSettings: notifyForItemSettingList,
      styleSettings: notificationStyleSettingList,
    }
    handleNotificationsSavedAfterSave()
    dispatch(updateNotificationSettings(personId, notificationSettings))
  }

  const handleNotifyForItemSettingsListChange = (
    newNotifyForItemSettingsList: NotificationSetting[] = [],
  ) => {
    const notifyForAllId = Utils.findConstantIdByName(
      NotificationNotifyForNames.ALL,
      NotifyForItemTypes,
    )

    const settingsWithChangedEnabledState = getSettingsWithChangedEnabledState(
      newNotifyForItemSettingsList,
      notifyForItemSettingList,
    )

    const changedToEnabledNotifyForAllTypeSettings =
      settingsWithChangedEnabledState.filter(
        (it) =>
          it.typeId === notifyForAllId &&
          it.enabledState === EnabledState.ENABLED,
      )

    const changedToDisabledNotifyForNotAllTypesSettings =
      settingsWithChangedEnabledState.filter(
        (it) =>
          it.typeId !== notifyForAllId &&
          it.enabledState === EnabledState.DISABLED,
      )

    // Select the whole `Notify for item` row if notify for `All` option is selected
    const updatedWithSelectedAllOptions =
      changedToEnabledNotifyForAllTypeSettings.reduce(
        (settingsAcc, notifyForAllSetting) =>
          updateCheckedForRow(
            notifyForAllSetting.notificationTypeId,
            true,
            settingsAcc,
          ),
        newNotifyForItemSettingsList,
      )

    // Unselect notify for `All` option if any of options in the row were unselected
    const updatedWithUnselectedNotAllOptions =
      changedToDisabledNotifyForNotAllTypesSettings.reduce(
        (settingsAcc, setting) =>
          updateSettingStateInList(
            settingsAcc,
            notifyForAllId,
            setting.notificationTypeId,
            false,
          ),
        updatedWithSelectedAllOptions,
      )

    setNotifyForItemSettingList(updatedWithUnselectedNotAllOptions)
  }

  const handleConfigureNotificationsClick = () => {
    openProfileDialog({ openNotificationSettings: true })
  }

  useEffect(() => {
    if (areSettingsEmpty) {
      dispatch(fetchNotificationSettings(personId))
    }
  }, [areSettingsEmpty])

  useEffect(() => {
    setNotifyForItemSettingList(initialNotifyForItemSettingList || [])
  }, [initialNotifyForItemSettingList])

  useEffect(() => {
    setNotificationStyleSettingList(initialNotificationStyleSettingList || [])
  }, [initialNotificationStyleSettingList])

  return (
    <Grid container className={classNameProp} direction="column">
      <Grid container item wrap="nowrap">
        <Text strong variant="body2">
          {t('Notifications:NOTIFICATION_SETTINGS.NOTIFICATIONS_RECEIVED')}
        </Text>
        {isForCurrentUser && readOnly && (
          <Text
            inline
            link
            className={classes.link}
            ml={1}
            variant="body2"
            onClick={handleConfigureNotificationsClick}
          >
            {t(
              'Notifications:NOTIFICATION_SETTINGS.CONFIGURE_YOUR_NOTIFICATIONS',
            )}
          </Text>
        )}
      </Grid>
      <Grid container item className={classes.table} mt={2} wrap="nowrap">
        <Grid
          container
          item
          className={classNames(classes.firstColumn, classes.borderColumn)}
          direction="column"
          wrap="nowrap"
        >
          <Grid
            container
            item
            className={classes.columnHeader}
            p={1}
            wrap="nowrap"
          >
            <Text strong variant="lowAccent2">
              {t('Notifications:NOTIFICATION_SETTINGS.NOTIFY_ME_ABOUT')}
            </Text>
          </Grid>
          <Grid item className={classes.columnSubHeader} pl={1} />
          {!readOnly && (
            <Grid
              container
              item
              className={classes.notifyAboutColumnSelectHeader}
              p={1}
              wrap="nowrap"
            >
              <Text strong>{t('Common:SELECT_ALL')}</Text>
            </Grid>
          )}
          <Grid container item direction="column" wrap="nowrap">
            {NotificationTypes?.map((notificationType) => (
              <Grid
                container
                item
                className={classes.tableRow}
                key={notificationType.id}
                p={1}
              >
                <Text noWrap>
                  {LanguageUtils.getTranslatedFieldName(notificationType)}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs
          alignItems="flex-start"
          className={classes.secondColumn}
          wrap="nowrap"
        >
          {!readOnly && (
            <Grid
              container
              item
              className={classNames(
                classes.notificationsColumn,
                classes.borderColumn,
              )}
              direction="column"
              wrap="nowrap"
            >
              <Grid
                container
                item
                className={classes.columnHeader}
                p={1}
                wrap="nowrap"
              >
                <Text strong variant="lowAccent2">
                  {t('Notifications:NOTIFICATION_SETTINGS.NO_NOTIFICATIONS')}
                </Text>
              </Grid>
              <Grid item className={classes.columnSubHeader} pl={1} />
              <Grid
                container
                item
                className={classes.notifyAboutColumnSelectHeader}
                p={1}
                wrap="nowrap"
              >
                <PuiCheckbox
                  checked={
                    selectAllForNoNotificationsEnabled ||
                    selectAllIndeterminateStateForNoNotifications
                  }
                  indeterminate={selectAllIndeterminateStateForNoNotifications}
                  onChange={() =>
                    onNoNotificationSelectAllSelected(
                      selectAllForNoNotificationsEnabled,
                    )
                  }
                />
              </Grid>
              <Grid container item direction="column" wrap="nowrap">
                {noNotificationsList?.map((noNotificationItem) => (
                  <Grid
                    container
                    item
                    className={classes.tableRow}
                    key={noNotificationItem.notificationTypeId}
                    p={1}
                    wrap="nowrap"
                  >
                    <PuiCheckbox
                      checked={noNotificationItem.enabled}
                      onChange={() =>
                        onNoNotificationForTypeSelected(
                          noNotificationItem.notificationTypeId,
                          noNotificationItem.enabled,
                        )
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          <NotificationSettingsCombinationTable
            isLoading={isLoading}
            readOnly={readOnly}
            settingList={notifyForItemSettingList}
            settingTypeList={NotifyForItemTypes}
            title={t(
              'Notifications:NOTIFICATION_SETTINGS_COMBINATION_TABLE.NOTIFY_FOR_ITEMS',
            )}
            onSettingListChange={handleNotifyForItemSettingsListChange}
          />

          <NotificationSettingsCombinationTable
            classes={{
              column: classNames(
                classes.noRightBorder,
                classes.responsiveColumn,
              ),
            }}
            isLoading={isLoading}
            readOnly={readOnly}
            settingList={notificationStyleSettingList}
            settingTypeList={NotificationStyles}
            title={t(
              'Notifications:NOTIFICATION_SETTINGS_COMBINATION_TABLE.NOTIFICATION_STYLES',
            )}
            onPreview={onNotificationStylesPreview}
            onSettingListChange={setNotificationStyleSettingList}
          />

          <CircularProgressOverlay
            open={isLoading}
            preloaderText={t(
              'Notifications:NOTIFICATION_SETTINGS.LOADING_SETTINGS',
            )}
          />
        </Grid>
      </Grid>

      {!readOnly && (
        <Grid item mt={2}>
          <ButtonWithLoader
            className={classes.saveButton}
            disabled={isSaving || isLoading}
            loading={isSaving}
            onClick={handleSave}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      )}
    </Grid>
  )
}

export default NotificationSettings
