import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { MigrationStatuses, MigrationType } from '~/constants/migration'
import {
  ImportSettings,
  ImportSettingsToEdit,
  MigrationEntity,
  MigrationException,
  MigrationExceptionMap,
  MigrationPreprocessingStatus,
  MigrationSession,
  MigrationSessionFile,
  MigrationStructure,
  NofixLog,
  SessionsMap,
  StartMigrationSettings,
  SupportedEntity,
} from '~/types/entities/migration'

import * as MigrationTypes from './types/migration'

export const fetchExporterUrl = (
  pimsId: string,
  selectedBusinessId: string,
  exporterType?: MigrationType,
) => ({
  type: MigrationTypes.FETCH_EXPORTER_URL,
  pimsId,
  selectedBusinessId,
  exporterType,
})
export const fetchExporterUrlSuccess = (url: string) => ({
  type: MigrationTypes.FETCH_EXPORTER_URL_SUCCESS,
  url,
})
export const fetchExporterUrlFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_EXPORTER_URL_FAILURE,
  error,
})
export const clearExporterUrl = () => ({
  type: MigrationTypes.CLEAR_EXPORTER_URL,
})

export const fetchNonFixableLogs = (sessionId: string | Nil) => ({
  type: MigrationTypes.FETCH_NOFIX_LOGS,
  sessionId,
})
export const fetchNonFixableLogsSuccess = (logs: NofixLog[]) => ({
  type: MigrationTypes.FETCH_NOFIX_LOGS_SUCCESS,
  logs,
})
export const fetchNonFixableLogsFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_NOFIX_LOGS_FAILURE,
  error,
})

export const fetchMigrationSessions = (businessId: string) => ({
  type: MigrationTypes.FETCH_MIGRATION_SESSIONS,
  businessId,
})
export const fetchMigrationSessionsSuccess = (
  list: string[],
  map: SessionsMap,
) => ({
  type: MigrationTypes.FETCH_MIGRATION_SESSIONS_SUCCESS,
  list,
  map,
})
export const fetchMigrationSessionsFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_MIGRATION_SESSIONS_FAILURE,
  error,
})

export const startMigrationAnalyze = (
  sessionId: string | Nil,
  isEnterprise?: boolean,
  entitiesList?: string[],
) => ({
  type: MigrationTypes.START_MIGRATION_ANALYZE,
  sessionId,
  isEnterprise,
  entitiesList,
})
export const startMigrationAnalyzeSuccess = () => ({
  type: MigrationTypes.START_MIGRATION_ANALYZE_SUCCESS,
})
export const startMigrationAnalyzeFailure = (error: ApiError) => ({
  type: MigrationTypes.START_MIGRATION_ANALYZE_FAILURE,
  error,
})

export const fetchMigrationAnalyzeStatus = (sessionId: string) => ({
  type: MigrationTypes.FETCH_MIGRATION_ANALYZE_STATUS,
  sessionId,
})
export const fetchMigrationAnalyzeStatusSuccess = (
  entities: MigrationEntity[],
) => ({
  type: MigrationTypes.FETCH_MIGRATION_ANALYZE_STATUS_SUCCESS,
  entities,
})
export const fetchMigrationAnalyzeStatusFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_MIGRATION_ANALYZE_STATUS_FAILURE,
  error,
})

export const fetchMigrationImportStatus = (
  sessionId: string | Nil,
  skipSessionUpdate?: boolean,
) => ({
  type: MigrationTypes.FETCH_MIGRATION_IMPORT_STATUS,
  sessionId,
  skipSessionUpdate,
})
export const fetchMigrationImportStatusSuccess = (
  entities: MigrationEntity[],
) => ({
  type: MigrationTypes.FETCH_MIGRATION_IMPORT_STATUS_SUCCESS,
  entities,
})
export const fetchMigrationImportStatusFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_MIGRATION_IMPORT_STATUS_FAILURE,
  error,
})

export const fetchMoreMappings = (
  sessionId: string | Nil,
  field: string,
  entity: string,
  from: number,
  to: number,
  includeResolved: boolean,
  isEnterprise?: boolean,
  // eslint-disable-next-line max-params
) => ({
  type: MigrationTypes.FETCH_MORE_MAPPINGS,
  sessionId,
  field,
  entity,
  from,
  to,
  includeResolved,
  isEnterprise,
})

export const fetchMoreMappingsSuccess = (
  list: string[],
  map: MigrationExceptionMap,
  totalCount: number,
) => ({
  type: MigrationTypes.FETCH_MORE_MAPPINGS_SUCCESS,
  list,
  map,
  totalCount,
})

export const fetchMoreMappingsFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_MORE_MAPPINGS_FAILURE,
  error,
})

export const clearMappings = () => ({ type: MigrationTypes.CLEAR_MAPPINGS })

export const startMigration = (
  sessionId: string | Nil,
  settings: StartMigrationSettings,
  isEnterprise: boolean,
) => ({
  type: MigrationTypes.START_MIGRATION,
  sessionId,
  settings,
  isEnterprise,
})

export const startMigrationSuccess = () => ({
  type: MigrationTypes.START_MIGRATION_SUCCESS,
})
export const startMigrationFailure = (error: ApiError) => ({
  type: MigrationTypes.START_MIGRATION_FAILURE,
  error,
})

export const updateSessionStatus = (
  sessionId: string | Nil,
  status: MigrationStatuses,
) => ({
  type: MigrationTypes.UPDATE_SESSION_STATUS,
  sessionId,
  status,
})

export const updateSessionStatusSuccess = () => ({
  type: MigrationTypes.UPDATE_SESSION_STATUS_SUCCESS,
})
export const updateSessionStatusFailure = (error: ApiError) => ({
  type: MigrationTypes.UPDATE_SESSION_STATUS_FAILURE,
  error,
})

export const startReviewExceptions = (sessionId: string | Nil) =>
  updateSessionStatus(sessionId, MigrationStatuses.REVIEW_EXCEPTIONS)
export const finishReviewExceptions = (sessionId: string | Nil) =>
  updateSessionStatus(sessionId, MigrationStatuses.IMPORT_IS_READY)

export const editImportSettings = (
  sessionId: string | Nil,
  settingsToUpdate: ImportSettingsToEdit | { timeZone: string },
) => ({
  type: MigrationTypes.EDIT_IMPORT_SETTINGS,
  sessionId,
  settingsToUpdate,
})

export const editImportSettingsSuccess = (settings: ImportSettingsToEdit) => ({
  type: MigrationTypes.EDIT_IMPORT_SETTINGS_SUCCESS,
  settings,
})

export const editImportSettingsFailure = (error: ApiError) => ({
  type: MigrationTypes.EDIT_IMPORT_SETTINGS_FAILURE,
  error,
})

export const fetchMappingStructures = (sessionId: string) => ({
  type: MigrationTypes.FETCH_MAPPING_STRUCTURES,
  sessionId,
})
export const fetchMappingStructuresSuccess = (
  structures: MigrationStructure[],
) => ({
  type: MigrationTypes.FETCH_MAPPING_STRUCTURES_SUCCESS,
  structures,
})
export const fetchMappingStructuresFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_MAPPING_STRUCTURES_FAILURE,
  error,
})

export const editMapping = (
  sessionId: string | Nil,
  mapping: MigrationException,
  isEnterprise: boolean,
) => ({
  type: MigrationTypes.EDIT_MAPPING,
  sessionId,
  mapping,
  isEnterprise,
})

export const editMappingSuccess = (mapping: MigrationException) => ({
  type: MigrationTypes.EDIT_MAPPING_SUCCESS,
  mapping,
})
export const editMappingFailure = (error: ApiError) => ({
  type: MigrationTypes.EDIT_MAPPING_FAILURE,
  error,
})

export const applyMappings = (
  sessionId: string | Nil,
  field: string,
  entity: string,
  isEnterprise: boolean,
) => ({
  type: MigrationTypes.APPLY_MAPPINGS,
  sessionId,
  field,
  entity,
  isEnterprise,
})
export const applyMappingsSuccess = () => ({
  type: MigrationTypes.APPLY_MAPPINGS_SUCCESS,
})
export const applyMappingsFailure = (error: ApiError) => ({
  type: MigrationTypes.APPLY_MAPPINGS_FAILURE,
  error,
})

export const requestFillMissingMapping = (rowIndex: number) => ({
  type: MigrationTypes.FOCUS_MAPPING_ROW,
  rowIndex,
})

export const focusMappingStructure = (structureIndex: number) => ({
  type: MigrationTypes.FOCUS_MAPPING_STRUCTURE,
  structureIndex,
})

export const chooseImportSession = (sessionId: string | Nil) => ({
  type: MigrationTypes.CHOOSE_IMPORT_SESSION,
  sessionId,
})
export const acceptUpdatedSession = (session: Partial<MigrationSession>) => ({
  type: MigrationTypes.ACCEPT_UPDATED_SESSION,
  session,
})
export const checkMigrationSettings = (
  sessionId: string | Nil,
  entitiesList: string[],
) => ({
  type: MigrationTypes.ACCEPT_UPDATED_SESSION,
  session: {
    entitiesList,
    id: sessionId,
    status: MigrationStatuses.CONFIRM_IMPORT_SETTINGS,
  },
})
export const dismissTimezoneChanges = (sessionId: string | Nil) => ({
  type: MigrationTypes.ACCEPT_UPDATED_SESSION,
  session: {
    id: sessionId,
    status: MigrationStatuses.IMPORT_IS_READY,
    entitiesList: [],
  },
})

export const fetchActivationToken = (businessId: string, pimsId: string) => ({
  type: MigrationTypes.FETCH_ACTIVATION_TOKEN,
  businessId,
  pimsId,
})
export const fetchActivationTokenSuccess = (token: string) => ({
  type: MigrationTypes.FETCH_ACTIVATION_TOKEN_SUCCESS,
  token,
})
export const fetchActivationTokenFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_ACTIVATION_TOKEN_FAILURE,
  error,
})
export const fetchActivationTokenSilentFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_ACTIVATION_TOKEN_SILENT_FAILURE,
  error,
})

export const fetchSessionFiles = (sessionId: string) => ({
  type: MigrationTypes.FETCH_SESSION_FILES,
  sessionId,
})
export const fetchSessionFilesSuccess = (files: MigrationSessionFile[]) => ({
  type: MigrationTypes.FETCH_SESSION_FILES_SUCCESS,
  files,
})
export const fetchSessionFilesFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_SESSION_FILES_FAILURE,
  error,
})
export const uploadSessionFile = (
  sessionId: string,
  file: MigrationSessionFile,
) => ({
  type: MigrationTypes.UPLOAD_SESSION_FILE,
  sessionId,
  file,
})
export const uploadSessionFileSuccess = (files: MigrationSessionFile[]) => ({
  type: MigrationTypes.UPLOAD_SESSION_FILE_SUCCESS,
  files,
})
export const uploadSessionFileFailure = (error: ApiError) => ({
  type: MigrationTypes.UPLOAD_SESSION_FILE_FAILURE,
  error,
})
export const fetchSessionFileUrl = (
  sessionId: string | Nil,
  name: string,
  extension: string,
) => ({
  type: MigrationTypes.FETCH_SESSION_FILE_URL,
  sessionId,
  name,
  extension,
})
export const fetchSessionFileUrlSuccess = (url: string) => ({
  type: MigrationTypes.FETCH_SESSION_FILE_URL_SUCCESS,
  url,
})
export const fetchSessionFileUrlFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_SESSION_FILE_URL_FAILURE,
  error,
})
export const deleteSessionFile = (
  sessionId: string | Nil,
  name: string,
  extension: string,
) => ({
  type: MigrationTypes.DELETE_SESSION_FILE,
  sessionId,
  name,
  extension,
})
export const deleteSessionFileSuccess = (files: MigrationSessionFile[]) => ({
  type: MigrationTypes.DELETE_SESSION_FILE_SUCCESS,
  files,
})
export const deleteSessionFileFailure = (error: ApiError) => ({
  type: MigrationTypes.DELETE_SESSION_FILE_FAILURE,
  error,
})
export const startPreprocessing = (sessionId: string | Nil) => ({
  type: MigrationTypes.START_PREPROCESSING,
  sessionId,
})
export const startPreprocessingSuccess = () => ({
  type: MigrationTypes.START_PREPROCESSING_SUCCESS,
})
export const startPreprocessingFailure = (error: ApiError) => ({
  type: MigrationTypes.START_PREPROCESSING_FAILURE,
  error,
})

export const preprocessingStarted = (sessionId: string) => ({
  type: MigrationTypes.ACCEPT_UPDATED_SESSION,
  session: {
    id: sessionId,
    status: MigrationStatuses.PREPROCESSING_IN_PROGRESS,
  },
})
export const fetchPreprocessingStatus = (sessionId: string) => ({
  type: MigrationTypes.FETCH_PREPROCESSING_STATUS,
  sessionId,
})
export const fetchPreprocessingStatusSuccess = (
  status: MigrationPreprocessingStatus,
) => ({
  type: MigrationTypes.FETCH_PREPROCESSING_STATUS_SUCCESS,
  status,
})
export const fetchPreprocessingStatusFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_PREPROCESSING_STATUS_FAILURE,
  error,
})

export const createImportSession = (
  businessId: string,
  exporterPimsId: string,
  pimsId: string,
  sourcePimsId: string,
) => ({
  type: MigrationTypes.CREATE_IMPORT_SESSION,
  businessId,
  exporterPimsId,
  pimsId,
  sourcePimsId,
})

export const createImportSessionSuccess = (session: MigrationSession) => ({
  type: MigrationTypes.CREATE_IMPORT_SESSION_SUCCESS,
  session,
})
export const createImportSessionFailure = (error: ApiError) => ({
  type: MigrationTypes.CREATE_IMPORT_SESSION_FAILURE,
  error,
})
export const fetchImportSettings = (sessionId: string) => ({
  type: MigrationTypes.FETCH_IMPORT_SETTINGS,
  sessionId,
})
export const fetchImportSettingsSuccess = (settings: ImportSettings) => ({
  type: MigrationTypes.FETCH_IMPORT_SETTINGS_SUCCESS,
  settings,
})
export const fetchImportSettingsFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_IMPORT_SETTINGS_FAILURE,
  error,
})

export const generateActivationToken = (
  pimsId: string,
  selectedBusinessId: string,
  exporterType: string,
) => ({
  type: MigrationTypes.GENERATE_ACTIVATION_TOKEN,
  pimsId,
  selectedBusinessId,
  exporterType,
})

export const generateActivationTokenSuccess = (token: string) => ({
  type: MigrationTypes.GENERATE_ACTIVATION_TOKEN_SUCCESS,
  token,
})
export const generateActivationTokenFailure = (error: ApiError) => ({
  type: MigrationTypes.GENERATE_ACTIVATION_TOKEN_FAILURE,
  error,
})

export const fetchSessionSupportedEntities = (sessionId: string) => ({
  type: MigrationTypes.FETCH_SESSION_SUPPORTED_ENTITIES,
  sessionId,
})
export const fetchSessionSupportedEntitiesSuccess = (
  entities: SupportedEntity[],
) => ({
  type: MigrationTypes.FETCH_SESSION_SUPPORTED_ENTITIES_SUCCESS,
  entities,
})
export const fetchSessionSupportedEntitiesFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_SESSION_SUPPORTED_ENTITIES_FAILURE,
  error,
})

export const fetchBusinessRelationsMappings = (
  selectedBusinessId: string,
  relationType: string,
) => ({
  type: MigrationTypes.FETCH_BUSINESS_RELATIONS_MAPPINGS,
  selectedBusinessId,
  relationType,
})
export const fetchBusinessRelationsMappingsSuccess = (
  relationsMappings: object[] | null,
) => ({
  type: MigrationTypes.FETCH_BUSINESS_RELATIONS_MAPPINGS_SUCCESS,
  relationsMappings,
})
export const fetchBusinessRelationsMappingsFailure = (error: ApiError) => ({
  type: MigrationTypes.FETCH_BUSINESS_RELATIONS_MAPPINGS_FAILURE,
  error,
})

export const clearBusinessRelationsMappings = () => ({
  type: MigrationTypes.CLEAR_BUSINESS_RELATIONS_MAPPINGS,
})

export const MIGRATION_SENSITIVE_ACTIONS = [
  {
    type: MigrationTypes.FETCH_ACTIVATION_TOKEN_SUCCESS,
    sensitiveData: ['token'],
  },
  {
    type: MigrationTypes.GENERATE_ACTIVATION_TOKEN_SUCCESS,
    sensitiveData: ['token'],
  },
]
