import { EditorDocumentType } from '~/types'

export const isOrderNoteType = ((type) =>
  type === EditorDocumentType.SOAP_CHARGE_INVENTORY ||
  type === EditorDocumentType.SOAP_CHARGE_LAB_TEST ||
  type === EditorDocumentType.SOAP_CHARGE_PROCEDURE ||
  type === EditorDocumentType.SOAP_CHARGE_PRESCRIPTION ||
  type === EditorDocumentType.SOAP_CHARGE_TASK) as (
  type: EditorDocumentType,
) => type is Extract<
  EditorDocumentType,
  | EditorDocumentType.SOAP_CHARGE_INVENTORY
  | EditorDocumentType.SOAP_CHARGE_LAB_TEST
  | EditorDocumentType.SOAP_CHARGE_PROCEDURE
  | EditorDocumentType.SOAP_CHARGE_PRESCRIPTION
  | EditorDocumentType.SOAP_CHARGE_TASK
>

export const isMedicalOrDischargeNoteType = ((type) =>
  type === EditorDocumentType.SOAP_MEDICAL ||
  type === EditorDocumentType.SOAP_DISCHARGE) as (
  type: EditorDocumentType,
) => type is Extract<
  EditorDocumentType,
  EditorDocumentType.SOAP_MEDICAL | EditorDocumentType.SOAP_DISCHARGE
>
