import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Field, PermissionArea, User } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getCRUDByArea } from '~/store/reducers/auth'

import TimeTrackingSection from '../TimeTrackingSection'

interface MemberTimeTrackingSectionProps {
  teamMember: User
  timeTrackingEnabled: Field
}

const MemberTimeTrackingSection = ({
  teamMember,
  timeTrackingEnabled,
}: MemberTimeTrackingSectionProps) => {
  const { t } = useTranslation('Common')

  const switchLabel = timeTrackingEnabled.value
    ? t('Common:ON')
    : t('Common:OFF')

  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))

  return (
    <Grid container item>
      <Grid item pb={1} sm={12}>
        <PuiSwitch
          disabled={!personPermissions.update}
          field={timeTrackingEnabled}
          label={switchLabel}
          name="timeTrackingSwitch"
        />
        {timeTrackingEnabled.value && (
          <TimeTrackingSection
            deleteAllowed={personPermissions.delete}
            updateAllowed={personPermissions.update}
            userId={teamMember.id}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default MemberTimeTrackingSection
