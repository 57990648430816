import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'
import {
  Check as CheckIcon,
  Decline as DeclineIcon,
  Delete as DeleteIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { useIsReminderStateName } from '~/components/dashboard/reminders/reminderUtils'
import DialogNames from '~/constants/DialogNames'
import {
  deleteReminder,
  editReminder,
  toggleReminderState,
} from '~/store/duck/reminders'
import { getEventState } from '~/store/reducers/constants'
import { Reminder, ReminderStateName } from '~/types'
import useDialog from '~/utils/useDialog'

export const useReminderItemActions = (reminder: Reminder | Nil) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const EventState = useSelector(getEventState)

  const isResolved = useIsReminderStateName(
    reminder,
    ReminderStateName.RESOLVED,
  )
  const isDeclined = useIsReminderStateName(
    reminder,
    ReminderStateName.DECLINED,
  )
  const [openReminderDetailsDialog] = useDialog(DialogNames.REMINDER_DETAILS)

  const getReminderActions = () => {
    if (!reminder) {
      return []
    }

    const handleDelete = () => {
      dispatch(deleteReminder(reminder.id))
    }

    const handleCheck = () => {
      dispatch(toggleReminderState(reminder.id))
    }

    const handlePreview = () => {
      openReminderDetailsDialog({ reminderId: reminder.id })
    }

    const handleDecline = () => {
      dispatch(
        editReminder({
          ...reminder,
          state: Utils.findConstantIdByName('Declined', EventState),
        }),
      )
    }

    return [
      {
        id: 'view',
        label: t('Common:VIEW_ACTION'),
        Icon: EyeIcon,
        onClick: handlePreview,
      },
      {
        id: 'markAsResolved',
        label: isResolved
          ? t('Common:OPEN_ACTION')
          : t('Common:RESOLVE_ACTION'),
        Icon: CheckIcon,
        onClick: handleCheck,
      },
      ...(!isDeclined
        ? [
            {
              id: 'decline',
              label: t('Common:DECLINE_ACTION'),
              Icon: DeclineIcon,
              onClick: handleDecline,
            },
          ]
        : []),
      {
        id: 'delete',
        label: t('Common:DELETE_ACTION'),
        Icon: DeleteIcon,
        onClick: handleDelete,
      },
    ]
  }

  const reminderActions = getReminderActions()

  return reminderActions
}
