import React from 'react'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
} from '@pbt/pbt-ui-components'

export interface EditItemButtonGroupProps {
  disabled?: boolean
  item?: any
  onDelete?: (item: any) => void
  onDuplicate?: (item: any) => void
  onEdit?: (item: any) => void
  onPreview?: (item: any) => void
}

const EditItemButtonGroup = ({
  item,
  disabled = false,
  onDelete,
  onDuplicate,
  onEdit,
  onPreview,
}: EditItemButtonGroupProps) => (
  <ControlButtonGroup
    buttons={[
      Boolean(onDelete) && {
        name: ControlButtonGroupName.DELETE,
        onClick: () => onDelete!(item),
        disabled,
      },
      Boolean(onEdit) && {
        name: ControlButtonGroupName.EDIT,
        onClick: () => onEdit!(item),
        disabled,
      },
      Boolean(onDuplicate) && {
        name: ControlButtonGroupName.DUPLICATE,
        onClick: () => onDuplicate!(item),
        disabled,
      },
      Boolean(onPreview) && {
        name: ControlButtonGroupName.PREVIEW,
        onClick: () => onPreview!(item),
        disabled,
      },
    ]}
  />
)

export default EditItemButtonGroup
