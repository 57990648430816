import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Field,
  Nil,
  PuiDialog,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { getUser } from '~/store/reducers/users'
import { EmailEntityConfigRecipient } from '~/types'

import ConversationMessageFormattingArea from '../ConversationMessageFormattingArea'
import ConversationTransportSelect from '../new-conversation-dialog/ConversationTransportSelect'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
    },
    buttonsSection: {
      borderTop: theme.constants.tableBorder,
    },
    sendButton: {
      width: 120,
    },
    toContainer: {
      width: '50%',
    },
  }),
  { name: 'NewConversationDialog' },
)

const mapClientToClientChip = (client: User) => ({
  id: client.id,
  name: Utils.getPersonString(client),
})

export interface ConversationMessageFormattingAreaHandle {
  formRecipients: () => EmailEntityConfigRecipient[]
  resetMessageState: () => void
}

export interface NewConversationDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  handleSend: (recipients: EmailEntityConfigRecipient[]) => void
  handleTransportChange: (transport: ConversationTransport) => void
  isLoading?: boolean
  isMessageLoading?: boolean
  message: Field
  patientId?: string
  subject: Field
  title: string
  to: Field
  transport: ConversationTransport | Nil
}

const NewConversationDialog = forwardRef(function NewConversationDialog(
  {
    clientId,
    to,
    transport,
    subject,
    message,
    isMessageLoading = false,
    isLoading = false,
    title: titleProp,
    handleSend,
    handleTransportChange,
    onClose,
    patientId,
    ...rest
  }: NewConversationDialogProps,
  ref: ForwardedRef<ConversationMessageFormattingAreaHandle>,
) {
  const classes = useStyles()
  const client = useSelector(getUser(clientId))
  const { t } = useTranslation('Common')

  const initialSelectedRecipients = client
    ? [mapClientToClientChip(client)]
    : []
  const title = titleProp || t('Common:NEW_CLIENT_CONVERSATION')

  const messageAreaRef = useRef<ConversationMessageFormattingAreaHandle>(null)

  const formRecipients = () => messageAreaRef.current?.formRecipients() || []
  const resetMessageState = () => messageAreaRef.current?.resetMessageState()

  useImperativeHandle(ref, () => ({
    resetMessageState,
    formRecipients,
  }))

  return (
    <PuiDialog
      aria-labelledby="new-conversation-dialog"
      classes={{ paper: classes.paper }}
      title={title}
      onClose={onClose}
      {...rest}
    >
      <Grid container direction="column">
        <Grid pb={2} pl={3} pr={5} pt={1}>
          <ConversationTransportSelect
            allowedTransports={[
              ConversationTransport.Email,
              ConversationTransport.Sms,
            ]}
            contactSlot={{ clientId }}
            disabled={isMessageLoading}
            transport={transport}
            onChange={handleTransportChange}
          />
          <ConversationMessageFormattingArea
            hidePanel
            hidePlusButtonBlock
            clientId={clientId}
            isLoading={isMessageLoading}
            messageField={message}
            minEditorHeight={150}
            patientId={patientId}
            ref={messageAreaRef}
            subjectField={subject}
            to={to}
            toInputClasses={{ container: classes.toContainer }}
            transport={transport}
            {...rest}
          />
        </Grid>
        <Grid container className={classes.buttonsSection} p={2}>
          <ButtonWithLoader
            className={classes.sendButton}
            disabled={
              R.isEmpty(initialSelectedRecipients) ||
              !transport ||
              isMessageLoading
            }
            loading={isLoading}
            onClick={() => {
              handleSend(formRecipients())
            }}
          >
            {t('Common:SEND_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
})

export default NewConversationDialog
