export enum AppointmentTypeName {
  EXAM_NEW_PATIENT = 'Exam - new patient',
  EXAM_WELLNESS_VISIT = 'Exam - wellness visit',
  EXAM_SICK_VISIT = 'Exam - sick visit',
  EXAM_URGENT = 'Exam - urgent',
  EXAM_PROGRESS = 'Exam - progress',
  DENTAL = 'Dental',
  SURGERY_SPAY_NEUTER = 'Surgery - spay/neuter',
  SURGERY_SOFT_TISSUE = 'Surgery - soft tissue',
  SURGERY_OTHER = 'Surgery - other',
  TECH_VISIT = 'Tech visit',
  BOARDING = 'Boarding',
  GROOMING = 'Grooming',
  OTC_SALE = 'OTC sale',
  TELEMEDICINE = 'Telemedicine',
  EXAM_MIGRATED = 'Exam - migrated',
  EXAM_ALTERNATIVE = 'Exam - Alternative',
  EUTHANASIA = 'Euthanasia',
  DAYCARE = 'Daycare',
  TELEMEDICINE_FIRST_AVAILABLE = 'Telemedicine - first available',
  TELEMEDICINE_SCHEDULED = 'Telemedicine - scheduled',
  TELEMEDICINE_MONITORING_FOLLOW_UP = 'Telemedicine - Monitoring / Follow-up',
  TELEHEALTH_NEW_PATIENT = 'Telehealth - new patient',
  PROCEDURE = 'Procedure',
  EXAM_IN_PATIENT = 'Exam - in-patient',
  SURGERY_SPAY = 'Surgery - spay',
  SURGERY_NEUTER = 'Surgery - neuter',
}
