import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { isEmpty, propEq } from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { getNotificationArea } from '~/store/reducers/constants'
import {
  getMultipleNotifications,
  getNotificationIdsForLinkedItemId,
} from '~/store/reducers/notifications'

const useStyles = makeStyles(
  (theme) => ({
    notificationsBadge: {
      width: 8,
      height: 8,
      backgroundColor: theme.colors.important,
      borderRadius: '50%',
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'LinkedItemNotificationsBadge' },
)

export interface LinkedItemNotificationsBadgeProps extends GridProps {
  areaName: string
  className?: string
  itemId: string
}

const LinkedItemNotificationsBadge = ({
  itemId,
  areaName,
  className,
  ...rest
}: LinkedItemNotificationsBadgeProps) => {
  const classes = useStyles()
  const NotificationAreas = useSelector(getNotificationArea)
  const areaId = Utils.findConstantIdByName(areaName, NotificationAreas)
  const notificationIds = useSelector(
    getNotificationIdsForLinkedItemId(areaId, itemId),
  )
  const notifications = useSelector(getMultipleNotifications(notificationIds))

  const unreadNotifications = notifications.filter(propEq('read', false))
  const shouldRender = !isEmpty(unreadNotifications)

  if (!shouldRender) {
    return null
  }

  return (
    <Grid
      item
      className={classNames(classes.notificationsBadge, className)}
      {...rest}
    />
  )
}

export default LinkedItemNotificationsBadge
