import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Maybe } from 'graphql/jsutils/Maybe'
import { getConstantTranslatedName } from '@pbt/pbt-ui-components/src/utils/languageUtils'

import { InvoiceLineItem as GraphqlInvoiceLineItem } from '~/api/graphql/generated/types'
import InvoiceType from '~/constants/InvoiceType'
import {
  getInventoryPriceUnit,
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import { InvoiceLineItem } from '~/types'
import { getConstantIdStringByNameString } from '~/utils'

export const isRefundInvoice = (invoiceNo: Maybe<string>) =>
  invoiceNo?.startsWith('R')

export const getInvoiceType = (invoiceNo: Maybe<string>) => {
  if (invoiceNo?.startsWith('I')) {
    return InvoiceType.INVOICE
  }
  if (invoiceNo?.startsWith('R')) {
    return InvoiceType.REFUND_INVOICE
  }
  if (invoiceNo?.startsWith('E')) {
    return InvoiceType.ESTIMATE
  }
  return null
}

export const useGetRefundAndRestock = ({
  originalLineItem,
  restockedAsPackage,
}: {
  originalLineItem: InvoiceLineItem | GraphqlInvoiceLineItem
  restockedAsPackage: Maybe<boolean>
}) => {
  const { t } = useTranslation('Common')

  const packageTypeConstants = useSelector(getPackageType)
  const perPackageUnitTypeConstants = useSelector(getInventoryProductSizeUnit)
  const inventoryPriceUnitsConstants = useSelector(getInventoryPriceUnit)

  const perPackageId = getConstantIdStringByNameString(
    'Per X packages',
    inventoryPriceUnitsConstants,
  )
  const purchasedAsPackage = originalLineItem.unitId === perPackageId

  const {
    variationPerPackageAmount = 1,
    variationPackageTypeId,
    variationPerPackageUnitsId,
  } = originalLineItem

  const packageName = getConstantTranslatedName(
    variationPackageTypeId,
    packageTypeConstants,
    t('Common:EACH'),
  )
  const perPackageUnitName = getConstantTranslatedName(
    variationPerPackageUnitsId,
    perPackageUnitTypeConstants,
    t('Common:EACH'),
  )

  const getRefundQuantity = (refundQty: number) => {
    if (restockedAsPackage === false) {
      return refundQty * variationPerPackageAmount
    }

    return purchasedAsPackage
      ? refundQty
      : refundQty * variationPerPackageAmount
  }

  const getRestockQuantity = (restockQty: number) => {
    if (restockedAsPackage === false) {
      return restockQty * variationPerPackageAmount
    }

    return purchasedAsPackage
      ? restockQty
      : restockQty * variationPerPackageAmount
  }

  const getRefundPackageName = () => {
    if (restockedAsPackage || purchasedAsPackage) {
      return packageName
    }

    return perPackageUnitName
  }

  const getRestockPackageName = () => {
    if (restockedAsPackage === false) {
      return perPackageUnitName
    }

    return purchasedAsPackage ? packageName : perPackageUnitName
  }

  return {
    packageName,
    perPackageUnitName,
    purchasedAsPackage,
    getRefundQuantity,
    getRestockQuantity,
    restockPackageName: getRestockPackageName(),
    refundPackageName: getRefundPackageName(),
  }
}
