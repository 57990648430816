import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNil } from 'ramda'

import { setLogOutTime } from '~/store/actions/auth'
import { getCurrentBusinessId, getLogOutTime } from '~/store/reducers/auth'
import { getLockWorkingHoursSettings } from '~/store/reducers/businesses'
import useLogout from '~/utils/useLogout'

const ForceLogOutWatcher = () => {
  const dispatch = useDispatch()

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const lockWorkingHoursSettings = useSelector(getLockWorkingHoursSettings)
  const logOutTime = useSelector(getLogOutTime)

  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const logout = useLogout()

  const lockWorkingHoursCurrentSettings = lockWorkingHoursSettings?.find(
    ({ businessId }) => businessId === currentBusinessId,
  )

  useEffect(() => {
    if (currentBusinessId && lockWorkingHoursCurrentSettings) {
      const { timeToLock, lockOutsideWorkingHours, locked } =
        lockWorkingHoursCurrentSettings

      if (locked || (lockOutsideWorkingHours && timeToLock === 0)) {
        logout()
        dispatch(setLogOutTime(0))
      }

      if (!lockOutsideWorkingHours) {
        clearTimeout(timer)
        dispatch(setLogOutTime(0))
      }

      if (lockOutsideWorkingHours && timeToLock) {
        dispatch(setLogOutTime(timeToLock))
      }
    }
  }, [currentBusinessId])

  useEffect(() => {
    if (isNil(logOutTime) && timer) {
      clearTimeout(timer)
      setTimer(undefined)
    } else if (logOutTime) {
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        logout()
      }, logOutTime * 1000)
      setTimer(newTimer)
    }
  }, [logOutTime])

  return null
}

export default ForceLogOutWatcher
