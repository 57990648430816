import React from 'react'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { CurrencyTextField } from '@pbt/pbt-ui-components'

import PercentTextField from '~/components/common/inputs/PercentTextField'
import { getDiscountSource } from '~/store/reducers/constants'
import {
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceUpdateFieldCallback,
} from '~/types'

import DiscountTooltip from '../../DiscountTooltip'
import {
  discountAmountFieldChanged,
  discountPercFieldChanged,
  getDiscountAmount,
  getDiscountPerc,
  getHighValue,
  getLimitedDiscountAmount,
  getLowValue,
  isRange,
  markManualDiscountSource,
} from '../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    amountCell: {
      borderRight: 'none !important',
    },
    underlineInput: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
    discountPercInputAdornment: {
      marginLeft: 0,
    },
    discountPercInput: {
      maxWidth: 47,
    },
    currencyTextField: {
      maxWidth: 74,
    },
  }),
  { name: 'DiscountCellBody' },
)

export interface DiscountCellBodyProps {
  className?: string
  group: InvoiceLineItemGroup
  isDeclined: boolean
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  updateField: InvoiceUpdateFieldCallback
}

const DiscountCellBody = ({
  isReadOnly,
  updateField,
  group,
  className,
  isDeclined,
  item,
  isGrouped,
}: DiscountCellBodyProps) => {
  const classes = useStyles()
  const DiscountSource = useSelector(getDiscountSource) || []

  const onDiscountAmountChanged = (
    oldItem: InvoiceLineItem,
    newItem: InvoiceLineItem,
  ) => {
    discountAmountFieldChanged(oldItem, newItem)
    markManualDiscountSource(DiscountSource, newItem)
  }

  const onDiscountPercentChanged = (
    oldItem: InvoiceLineItem,
    newItem: InvoiceLineItem,
  ) => {
    discountPercFieldChanged(oldItem, newItem)
    markManualDiscountSource(DiscountSource, newItem)
  }

  const disabledRow = !item.discountAllowed || isGrouped || isReadOnly

  return (
    <>
      <TableCell className={classNames(className, classes.amountCell)}>
        {!isDeclined &&
          (item.discountAllowed || item.discountPerc || isGrouped ? (
            <PercentTextField
              InputProps={{
                disableUnderline: disabledRow,
              }}
              className={classNames(
                classes.underlineInput,
                classes.discountPercInput,
              )}
              classes={{
                adornment: classes.discountPercInputAdornment,
              }}
              disabled={disabledRow}
              placeholder=""
              value={
                isRange(getDiscountPerc(item))
                  ? undefined
                  : getLowValue(getDiscountPerc(item))
              }
              onChange={(event) =>
                updateField(
                  event,
                  'discountPerc',
                  group,
                  item,
                  false,
                  onDiscountPercentChanged,
                )
              }
            />
          ) : (
            <DiscountTooltip />
          ))}
      </TableCell>
      <TableCell align="right" className={className}>
        {!isDeclined &&
          (item.discountAllowed || item.discountAmount || isGrouped ? (
            <CurrencyTextField
              noLonelyAdornment
              selectZeroOnFocus
              InputProps={{
                disableUnderline: disabledRow,
              }}
              className={classNames(
                classes.underlineInput,
                classes.currencyTextField,
              )}
              disabled={disabledRow}
              value={
                isRange(getDiscountAmount(item))
                  ? ''
                  : getHighValue(getLimitedDiscountAmount(item))
              }
              onChange={(event) => {
                const discount = Math.min(
                  Number(event.target.value),
                  getHighValue(item.extendedPrice),
                )
                updateField(
                  discount,
                  'discountAmount',
                  group,
                  item,
                  false,
                  onDiscountAmountChanged,
                )
              }}
            />
          ) : (
            <DiscountTooltip />
          ))}
      </TableCell>
    </>
  )
}

export default DiscountCellBody
