import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import { PuiSelect, PuiTextField, useFields } from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import ManualInputSelect from '~/components/common/ManualInputSelect'
import {
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getDrugStrengthUnit,
  getProductForm,
} from '~/store/reducers/constants'
import { DataHandle, Prescription, PrescriptionDrugInfo } from '~/types'
import { handleNumberInput } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

export interface DrugCompoundInfoProps {
  onRefChange?: () => void
  prescription: Prescription
}

export interface DrugCompoundInfoHandle
  extends DataHandle<PrescriptionDrugInfo> {}

const DrugCompoundInfo = forwardRef<
  DrugCompoundInfoHandle,
  DrugCompoundInfoProps
>(function DrugCompoundInfo({ onRefChange, prescription }, ref) {
  const ProductForm = useSelector(getProductForm)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const { t } = useTranslation('Common')

  const {
    fields: {
      formId,
      customForm,
      deliveryMethodId,
      strength,
      strengthUnitsId,
      strengthColorId,
      description,
    },
    validate,
  } = useFields([
    {
      name: 'formId',
      label: t('Common:FORM_NOUN'),
      type: 'select',
      initialValue: prescription?.drugInfo?.formId,
    },
    {
      name: 'customForm',
      initialValue: prescription?.drugInfo?.customForm,
    },
    {
      name: 'deliveryMethodId',
      label: t('Common:DRUG_DELIVERY_METHOD'),
      type: 'select',
      initialValue: prescription?.drugInfo?.deliveryMethodId,
    },
    {
      name: 'strength',
      label: t('Common:DRUG_STRENGTH'),
      initialValue: prescription?.drugInfo?.strength,
    },
    {
      name: 'strengthUnitsId',
      label: t('Common:DRUG_STRENGTH_UNIT'),
      type: 'select',
      initialValue: prescription?.drugInfo?.strengthUnitsId,
    },
    {
      name: 'strengthColorId',
      label: t('Common:DRUG_STRENGTH_COLOR'),
      type: 'select',
      initialValue: prescription?.drugInfo?.strengthColorId,
    },
    {
      name: 'description',
      label: t('Common:DESCRIPTION'),
      initialValue: prescription?.drugInfo?.description,
    },
  ])

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [
    formId,
    customForm,
    deliveryMethodId,
    strength,
    strengthUnitsId,
    strengthColorId,
    description,
  ])

  useImperativeHandle(ref, () => ({
    get: () => ({
      formId: formId.value,
      customForm: customForm.value,
      deliveryMethodId: deliveryMethodId.value,
      strength: strength.value,
      strengthUnitsId: strengthUnitsId.value,
      strengthColorId: strengthColorId.value,
      description: description.value,
    }),
    validate,
  }))

  return (
    <Grid container direction="column">
      <Grid container item spacing={2}>
        <ManualInputSelect
          fields={{
            unitId: formId,
            customUnit: customForm,
          }}
          label={formId.label!}
          options={ProductForm}
        />
      </Grid>
      <Grid container item alignItems="center" spacing={2}>
        <Grid item xs>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="delivery-method-select">
              {deliveryMethodId.label}
            </InputLabel>
            <PuiSelect
              field={deliveryMethodId}
              input={<Input id="delivery-method-select" />}
              items={DrugDeliveryMethod}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <PuiTextField field={description} label={description.label} />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" spacing={2}>
        <Grid item xs>
          <NumericInput
            allowDecimal
            field={{
              ...strength,
              set: handleNumberInput(strength.setValue, 10, 4),
            }}
            label={strength.label}
            min={0.01}
          />
        </Grid>
        <Grid item xs>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="strength-unit-select">
              {strengthUnitsId.label}
            </InputLabel>
            <PuiSelect
              field={strengthUnitsId}
              input={<Input id="strength-unit-select" />}
              items={DrugStrengthUnit}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="strength-color-select">
              {strengthColorId.label}
            </InputLabel>
            <PuiSelect
              field={strengthColorId}
              input={<Input id="strength-color-select" />}
              items={DrugStrengthColor}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
})

export default DrugCompoundInfo
