import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getDocumentTypes } from '~/store/reducers/constants'

type DocumentTypeCellItem = {
  type: string
}

const DocumentTypeCell = (item: DocumentTypeCellItem) => {
  const DocumentTypes = useSelector(getDocumentTypes)

  return LanguageUtils.getConstantTranslatedName(item.type, DocumentTypes)
}

export default DocumentTypeCell
