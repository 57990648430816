import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ReminderProtocol } from '~/types'

import { updateSearchHighlights } from '../actions/search'
import { finishLoading, startLoading } from '../duck/progress'
import {
  CREATE_REMINDER_PROTOCOL,
  CREATE_REMINDER_PROTOCOL_GROUP,
  createReminderProtocol,
  createReminderProtocolFailure,
  createReminderProtocolGroup,
  createReminderProtocolGroupFailure,
  createReminderProtocolGroupSuccess,
  createReminderProtocolSuccess,
  DELETE_REMINDER_PROTOCOL,
  DELETE_REMINDER_PROTOCOL_GROUP,
  deleteReminderProtocol,
  deleteReminderProtocolFailure,
  deleteReminderProtocolGroup,
  deleteReminderProtocolGroupFailure,
  deleteReminderProtocolGroupSuccess,
  deleteReminderProtocolSuccess,
  FETCH_MORE_REMINDER_PROTOCOL_GROUPS,
  FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST,
  FETCH_REMINDER_PROTOCOL_GROUP,
  FETCH_REMINDER_PROTOCOL_GROUPS,
  FETCH_REMINDER_PROTOCOLS_SEARCH_LIST,
  fetchMoreReminderProtocolGroups,
  fetchMoreReminderProtocolGroupsFailure,
  fetchMoreReminderProtocolGroupsSuccess,
  fetchMoreReminderProtocolsSearchList,
  fetchMoreReminderProtocolsSearchListFailure,
  fetchMoreReminderProtocolsSearchListSuccess,
  fetchReminderProtocolGroup,
  fetchReminderProtocolGroupFailure,
  fetchReminderProtocolGroups,
  fetchReminderProtocolGroupsFailure,
  fetchReminderProtocolGroupsSuccess,
  fetchReminderProtocolGroupSuccess,
  fetchReminderProtocolsSearchList,
  fetchReminderProtocolsSearchListFailure,
  fetchReminderProtocolsSearchListSuccess,
  UPDATE_REMINDER_PROTOCOL,
  UPDATE_REMINDER_PROTOCOL_GROUP,
  updateReminderProtocol,
  updateReminderProtocolFailure,
  updateReminderProtocolGroup,
  updateReminderProtocolGroupFailure,
  updateReminderProtocolGroupSuccess,
  updateReminderProtocolSuccess,
} from '../duck/reminderProtocols'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchReminderProtocolGroupsSaga({
  query,
  from,
  to,
}: ReturnType<typeof fetchReminderProtocolGroups>) {
  try {
    yield put(startLoading('reminder-protocol-groups'))
    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield requestAPI(API.fetchReminderProtocolGroups, query, from, to)
    yield put(updateSearchHighlights(highlights, totalCount))
    yield call(updateEntities, entities)
    yield put(fetchReminderProtocolGroupsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchReminderProtocolGroupsFailure(error as ApiError))
  } finally {
    yield put(finishLoading('reminder-protocol-groups'))
  }
}

export function* fetchMoreReminderProtocolGroupsSaga({
  query,
  from,
  to,
}: ReturnType<typeof fetchMoreReminderProtocolGroups>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(API.fetchReminderProtocolGroups, query, from, to)
    yield call(updateEntities, entities)
    yield put(fetchMoreReminderProtocolGroupsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreReminderProtocolGroupsFailure(error as ApiError))
  }
}

export function* fetchReminderProtocolGroupSaga({
  groupId,
}: ReturnType<typeof fetchReminderProtocolGroup>) {
  try {
    yield put(startLoading('reminder-protocol-groups'))
    const { entities } = yield requestAPI(
      API.fetchReminderProtocolGroup,
      groupId,
    )
    yield call(updateEntities, entities)
    yield put(fetchReminderProtocolGroupSuccess(groupId))
  } catch (error) {
    yield put(fetchReminderProtocolGroupFailure(error as ApiError))
  } finally {
    yield put(finishLoading('reminder-protocol-groups'))
  }
}

export function* createReminderProtocolGroupSaga({
  group,
}: ReturnType<typeof createReminderProtocolGroup>) {
  try {
    const { result, entities } = yield requestAPI(
      API.createReminderProtocolGroup,
      group,
    )
    yield call(updateEntities, entities)
    yield put(createReminderProtocolGroupSuccess(result))
  } catch (error) {
    yield put(createReminderProtocolGroupFailure(error as ApiError))
  }
}

export function* updateReminderProtocolGroupSaga({
  group,
}: ReturnType<typeof updateReminderProtocolGroup>) {
  try {
    const { result, entities } = yield requestAPI(
      API.updateReminderProtocolGroup,
      group,
    )
    yield call(updateEntities, entities)
    yield put(updateReminderProtocolGroupSuccess(result))
  } catch (error) {
    yield put(updateReminderProtocolGroupFailure(error as ApiError))
  }
}

export function* deleteReminderProtocolGroupSaga({
  groupId,
}: ReturnType<typeof deleteReminderProtocolGroup>) {
  try {
    yield requestAPI(API.deleteReminderProtocolGroup, groupId)
    yield put(deleteReminderProtocolGroupSuccess(groupId))
  } catch (error) {
    yield put(deleteReminderProtocolGroupFailure(error as ApiError))
  }
}

export function* createReminderProtocolSaga({
  groupId,
  protocol,
}: ReturnType<typeof createReminderProtocol>) {
  try {
    const newProtocol: ReminderProtocol = yield requestAPI(
      API.createReminderProtocol,
      groupId,
      protocol,
    )
    yield put(createReminderProtocolSuccess(groupId, newProtocol))
  } catch (error) {
    yield put(createReminderProtocolFailure(error as ApiError))
  }
}

export function* updateReminderProtocolSaga({
  groupId,
  protocol,
}: ReturnType<typeof updateReminderProtocol>) {
  try {
    const newProtocol: ReminderProtocol = yield requestAPI(
      API.updateReminderProtocol,
      groupId,
      protocol,
    )
    yield put(updateReminderProtocolSuccess(groupId, newProtocol))
  } catch (error) {
    yield put(updateReminderProtocolFailure(error as ApiError))
  }
}

export function* deleteReminderProtocolSaga({
  groupId,
  protocolId,
}: ReturnType<typeof deleteReminderProtocol>) {
  try {
    yield requestAPI(API.deleteReminderProtocol, groupId, protocolId)
    yield put(deleteReminderProtocolSuccess(groupId, protocolId))
  } catch (error) {
    yield put(deleteReminderProtocolFailure(error as ApiError))
  }
}

export function* fetchReminderProtocolsSearchListSaga({
  query,
  speciesId,
  from,
  to,
}: ReturnType<typeof fetchReminderProtocolsSearchList>) {
  try {
    const { data: items, totalCount } = yield requestAPI(
      API.fetchReminderProtocols,
      query,
      speciesId,
      from,
      to,
    )

    yield put(fetchReminderProtocolsSearchListSuccess(items, totalCount))
  } catch (error) {
    yield put(fetchReminderProtocolsSearchListFailure(error as ApiError))
  }
}

export function* fetchMoreReminderProtocolsSearchListSaga({
  query,
  speciesId,
  from,
  to,
}: ReturnType<typeof fetchMoreReminderProtocolsSearchList>) {
  try {
    const { data: items, totalCount } = yield requestAPI(
      API.fetchReminderProtocols,
      query,
      speciesId,
      from,
      to,
    )

    yield put(fetchMoreReminderProtocolsSearchListSuccess(items, totalCount))
  } catch (error) {
    yield put(fetchMoreReminderProtocolsSearchListFailure(error as ApiError))
  }
}

function* watchFetchReminderProtocolGroups() {
  yield takeLatest(
    FETCH_REMINDER_PROTOCOL_GROUPS,
    fetchReminderProtocolGroupsSaga,
  )
}

function* watchFetchMoreReminderProtocolGroups() {
  yield takeLatest(
    FETCH_MORE_REMINDER_PROTOCOL_GROUPS,
    fetchMoreReminderProtocolGroupsSaga,
  )
}

function* watchFetchReminderProtocolGroup() {
  yield takeLatest(
    FETCH_REMINDER_PROTOCOL_GROUP,
    fetchReminderProtocolGroupSaga,
  )
}

function* watchCreateReminderProtocolGroup() {
  yield takeLatest(
    CREATE_REMINDER_PROTOCOL_GROUP,
    createReminderProtocolGroupSaga,
  )
}

function* watchUpdateReminderProtocolGroup() {
  yield takeLatest(
    UPDATE_REMINDER_PROTOCOL_GROUP,
    updateReminderProtocolGroupSaga,
  )
}

function* watchDeleteReminderProtocolGroup() {
  yield takeLatest(
    DELETE_REMINDER_PROTOCOL_GROUP,
    deleteReminderProtocolGroupSaga,
  )
}

function* watchCreateReminderProtocol() {
  yield takeLatest(CREATE_REMINDER_PROTOCOL, createReminderProtocolSaga)
}

function* watchUpdateReminderProtocol() {
  yield takeLatest(UPDATE_REMINDER_PROTOCOL, updateReminderProtocolSaga)
}

function* watchDeleteReminderProtocol() {
  yield takeLatest(DELETE_REMINDER_PROTOCOL, deleteReminderProtocolSaga)
}

function* watchFetchReminderProtocolsSearchList() {
  yield takeLatest(
    FETCH_REMINDER_PROTOCOLS_SEARCH_LIST,
    fetchReminderProtocolsSearchListSaga,
  )
}

function* watchMoreFetchReminderProtocolsSearchList() {
  yield takeLatest(
    FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST,
    fetchMoreReminderProtocolsSearchListSaga,
  )
}

export default function* reminderProtocolsSaga() {
  yield all([
    watchFetchReminderProtocolGroups(),
    watchFetchMoreReminderProtocolGroups(),
    watchFetchReminderProtocolGroup(),
    watchCreateReminderProtocolGroup(),
    watchUpdateReminderProtocolGroup(),
    watchDeleteReminderProtocolGroup(),
    watchCreateReminderProtocol(),
    watchUpdateReminderProtocol(),
    watchDeleteReminderProtocol(),
    watchFetchReminderProtocolsSearchList(),
    watchMoreFetchReminderProtocolsSearchList(),
  ])
}
