const TABLE_FULL_WIDTH = 1095

export const CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS = 4
export const CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT = 40

export const TableColumnWidth = {
  FIRST: `${(112 / TABLE_FULL_WIDTH) * 100}%`,
  SECOND: `${(160 / TABLE_FULL_WIDTH) * 100}%`,
  THIRD: `${(182 / TABLE_FULL_WIDTH) * 100}%`,
  FOURTH: `${(140 / TABLE_FULL_WIDTH) * 100}%`,
  FIFTH: `${(242 / TABLE_FULL_WIDTH) * 100}%`,
  SIXTH: `${(121 / TABLE_FULL_WIDTH) * 100}%`,
  SEVENTH: `${(138 / TABLE_FULL_WIDTH) * 100}%`,
}

export const InvoiceTableColumnWidthWithCheckbox = {
  FIRST: 129,
  SECOND: 157,
  THIRD: 156,
  FOURTH: 120,
  FIFTH: 217,
  SIXTH: 148,
  SEVENTH: 113,
}

export const PaymentTableColumnWidthWithCheckbox = {
  FIRST: 119,
  SECOND: 133,
  THIRD: 130,
  FOURTH: 110,
  FIFTH: 110,
  SIXTH: 100,
  SEVENTH: 96,
  EIGHTH: 120,
  NINTH: 160,
}

export const PaymentTableColumnWidthWithoutCheckbox = {
  FIRST: 88,
  SECOND: 160,
  THIRD: 133,
  FOURTH: 133,
  FIFTH: 121,
  SIXTH: 101,
  SEVENTH: 99,
  EIGHTH: 118,
  NINTH: 120,
}
