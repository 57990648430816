import { Task } from '@redux-saga/types'
import { normalize } from 'normalizr'
import * as R from 'ramda'
import {
  all,
  call,
  cancel,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects'
import {
  ApiError,
  Defaults,
  IdObject,
  LanguageUtils,
  User,
  UserWithFullPatients,
  Utils,
} from '@pbt/pbt-ui-components'

import * as API from '~/api'
import * as Schema from '~/api/schemas'
import SearchContext from '~/constants/searchContext'
import SecretSearchContext from '~/constants/secretSearchContext'
import {
  BaseSearchRequestParams,
  SearchClientsAndPatientsRequestParams,
  SearchContactsRequestParams,
  SearchPersonsRequestParams,
  SuggestionResult,
  TeamFilter,
} from '~/types'
import { getPatientStatus } from '~/utils'

import {
  fetchMoreSuggestionResults,
  fetchMoreSuggestionResultsFailure,
  fetchMoreSuggestionResultsSuccess,
  fetchSuggestionResults,
  fetchSuggestionResultsFailure,
  fetchSuggestionResultsSuccess,
} from '../actions/search'
import {
  CLEAR_SUGGESTION_RESULTS,
  FETCH_MORE_SUGGESTION_RESULTS,
  FETCH_SUGGESTION_RESULTS,
  FETCH_SUGGESTION_RESULTS_SUCCESS,
} from '../actions/types/search'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const searchResultEntityMap = ({ id, name }: IdObject) => ({ id, item: name })

const SearchResultToListItemMap = {
  [SearchContext.INVENTORY]: searchResultEntityMap,
  [SearchContext.LAB_TESTS]: searchResultEntityMap,
  [SearchContext.PROCEDURES]: (procedure: IdObject) => ({
    id: procedure?.id,
    item: LanguageUtils.getTranslatedFieldName(procedure),
  }),
  [SearchContext.CLIENT_PATIENTS]: (client: UserWithFullPatients) => ({
    id: client.id,
    itemContextIds: client.clientInContextBusinesses,
    item: Utils.getPersonString(client),
    itemActive: client.active,
    subItems: (client.patients || []).map((patient) => {
      const { disabled, reasons } = getPatientStatus(patient)

      return {
        id: patient.id,
        name: patient.name,
        alert: disabled ? R.head(reasons) : undefined,
      }
    }),
    noSubItemsText: 'No patients',
  }),
  [SearchContext.REMINDER_PROTOCOLS]: searchResultEntityMap,
  [SecretSearchContext.PERSONS]: (person: User) => ({
    id: person.id,
    item: Utils.getPersonString(person),
  }),
  [SearchContext.CONTACTS]: searchResultEntityMap,
  [SearchContext.TASK_DASHBOARD]: searchResultEntityMap,
  [SearchContext.MARKETPLACE]: searchResultEntityMap,
  [SearchContext.IMAGING_DASHBOARD]: searchResultEntityMap,
  [SearchContext.PRACTICES]: searchResultEntityMap,
  [SearchContext.ON_HAND_CATALOG]: searchResultEntityMap,
}

const formatSuggestions = (
  suggestions: SuggestionResult[],
): SuggestionResult[] => {
  const withSubItems = suggestions.map((suggestion) => {
    const subItems = suggestion.subItems || []
    const firstSubItem = R.head(subItems)

    const mainSuggestion = firstSubItem
      ? {
          ...suggestion,
          subItem: firstSubItem.name,
          subItemId: firstSubItem.id,
          subItemAlert: firstSubItem.alert,
        }
      : suggestion

    return [
      mainSuggestion,
      ...subItems.slice(1).map((subItem) => ({
        id: suggestion.id,
        subItem: subItem.name,
        subItemId: subItem.id,
        subItemAlert: subItem.alert,
        noSubItemsText: suggestion.noSubItemsText,
      })),
    ]
  })

  return R.flatten(withSubItems)
}

const getSearchContextApiAndParams = (
  searchContext: SearchContext | SecretSearchContext,
  {
    query,
    maxCount,
    roleIds,
    fieldsQuery,
    includeInactive,
    shorten,
    from,
    to,
    normalize: normalizeParam,
    teamFilter,
  }: any,
) => {
  const baseSearchParams: BaseSearchRequestParams = { query, max: maxCount }
  const clientAndPatientsParams: SearchClientsAndPatientsRequestParams = {
    ...baseSearchParams,
    fieldsQuery,
    includeInactive,
  }
  const personsParams: SearchPersonsRequestParams = {
    ...baseSearchParams,
    from,
    includeInactive,
    teamFilter,
    roleIds,
    shorten,
    to,
  }
  const contactsParams: SearchContactsRequestParams = {
    ...baseSearchParams,
    from,
    to,
    normalize: normalizeParam,
  }

  const SearchContextMap = {
    [SearchContext.INVENTORY]: {
      params: baseSearchParams,
      api: API.searchInventory,
    },
    [SearchContext.PROCEDURES]: {
      params: baseSearchParams,
      api: API.searchProcedures,
    },
    [SearchContext.LAB_TESTS]: {
      params: baseSearchParams,
      api: API.searchLabTests,
    },
    [SearchContext.CLIENT_PATIENTS]: {
      params: clientAndPatientsParams,
      api: API.searchClientsAndPatients,
    },
    [SecretSearchContext.PERSONS]: {
      params: personsParams,
      api: API.searchPersons,
    },
    [SearchContext.REMINDER_PROTOCOLS]: {
      params: baseSearchParams,
      api: API.searchReminderProtocols,
    },
    [SearchContext.CONTACTS]: {
      params: contactsParams,
      api: API.searchContacts,
    },
    [SearchContext.TASK_DASHBOARD]: {
      params: baseSearchParams,
      api: API.searchTasks,
    },
    [SearchContext.MARKETPLACE]: {
      params: baseSearchParams,
      api: API.searchMarketplaceItems,
    },
    [SearchContext.IMAGING_DASHBOARD]: {
      params: baseSearchParams,
      api: API.suggestImagingDashboard,
    },
    [SearchContext.PRACTICES]: {
      params: baseSearchParams,
      api: API.suggestBusinesses,
    },
    [SearchContext.ON_HAND_CATALOG]: {
      params: baseSearchParams,
      api: API.suggestOnHandCatalog,
    },
  }
  return SearchContextMap[searchContext]
}

function* updatePersonsEntities(data: any) {
  const { entities } = normalize(data, Schema.userArray)
  yield call(updateEntities, entities)
}

function* updateContactEntities(data: any) {
  const { entities } = normalize(data, Schema.contactsArray)
  yield call(updateEntities, entities)
}

const updateEntitiesHandlersMap: Partial<
  Record<SearchContext | SecretSearchContext, (data: any) => Generator>
> = {
  [SecretSearchContext.PERSONS]: updatePersonsEntities,
  [SearchContext.CLIENT_PATIENTS]: updatePersonsEntities,
  [SearchContext.CONTACTS]: updateContactEntities,
}

export function* fetchSuggestionResultsSaga({
  searchContext,
  searchTerm: query,
  maxCount = Defaults.SEARCH_BATCH_LOAD_COUNT,
  roleIds,
  fieldsQuery,
  includeInactive = true,
  teamFilter = TeamFilter.MEMBER,
  shorten,
}: ReturnType<typeof fetchSuggestionResults>) {
  try {
    const { api: searchApi, params: searchParams } =
      getSearchContextApiAndParams(searchContext, {
        query,
        maxCount,
        roleIds,
        fieldsQuery,
        includeInactive,
        teamFilter,
        shorten,
      })
    if (searchApi) {
      const { data, totalCount } = yield* requestAPI<
        typeof searchParams,
        any[]
      >(searchApi, searchParams)
      // TODO: adjust only map after removing isProceduresI18nEnabled
      const mapper = SearchResultToListItemMap[searchContext]
      const processedData = mapper ? data.map(mapper) : data
      const result = formatSuggestions(processedData)
      const updateEntityHandler = updateEntitiesHandlersMap[searchContext]
      if (updateEntityHandler) {
        yield updateEntityHandler(data)
      }
      yield put(fetchSuggestionResultsSuccess(result, totalCount))
    }
  } catch (error) {
    yield put(fetchSuggestionResultsFailure(error as ApiError))
  }
}

export function* fetchMoreSuggestionResultsCancelable({
  searchContext,
  searchTerm: query,
  maxCount = Defaults.SEARCH_BATCH_LOAD_COUNT,
  roleIds,
  fieldsQuery,
  includeInactive = true,
  teamFilter = TeamFilter.MEMBER,
  shorten,
  from = 0,
  to = 20,
}: ReturnType<typeof fetchMoreSuggestionResults>) {
  try {
    const { api: searchApi, params: searchParams } =
      getSearchContextApiAndParams(searchContext, {
        query,
        maxCount,
        roleIds,
        fieldsQuery,
        includeInactive,
        teamFilter,
        shorten,
        from,
        to,
      })
    if (searchApi) {
      const { data, totalCount } = yield* requestAPI<
        typeof searchParams,
        any[]
      >(searchApi, searchParams)
      const mapper = SearchResultToListItemMap[searchContext]
      const processedData = mapper ? data.map(mapper) : data
      const result = formatSuggestions(processedData)
      const updateEntityHandler = updateEntitiesHandlersMap[searchContext]
      if (updateEntityHandler) {
        yield updateEntityHandler(data)
      }
      yield put(fetchMoreSuggestionResultsSuccess(result, totalCount, from))
    }
  } catch (error) {
    yield put(fetchMoreSuggestionResultsFailure(error as ApiError))
  }
}

export function* fetchMoreSuggestionResultsSaga(
  params: ReturnType<typeof fetchMoreSuggestionResults>,
) {
  const task: Task = yield fork(fetchMoreSuggestionResultsCancelable, params)
  yield take([
    FETCH_SUGGESTION_RESULTS,
    FETCH_SUGGESTION_RESULTS_SUCCESS,
    CLEAR_SUGGESTION_RESULTS,
  ])
  yield cancel(task)
}

function* watchFetchSuggestionResult() {
  yield takeLatest(FETCH_SUGGESTION_RESULTS, fetchSuggestionResultsSaga)
  yield takeLatest(
    FETCH_MORE_SUGGESTION_RESULTS,
    fetchMoreSuggestionResultsSaga,
  )
}

export default function* searchSaga() {
  yield all([watchFetchSuggestionResult()])
}
