import * as R from 'ramda'
import { AnyAction } from 'redux'

import { UiNotification } from '~/types'

import type { RootState } from '../index'

export const ADD_UI_NOTIFICATION = 'uiNotifications/ADD_UI_NOTIFICATION'
export const REMOVE_UI_NOTIFICATION = 'uiNotifications/REMOVE_UI_NOTIFICATION'

export const addUiNotification = (notification: UiNotification) => ({
  type: ADD_UI_NOTIFICATION,
  notification,
})
export const removeUiNotification = (notificationId: string) => ({
  type: REMOVE_UI_NOTIFICATION,
  notificationId,
})

export type UiNotificationsState = {
  list: string[]
  map: Record<string, UiNotification>
}

const INITIAL_STATE: UiNotificationsState = {
  list: [],
  map: {},
}

export const uiNotificationsReducer = (
  state: UiNotificationsState = INITIAL_STATE,
  action: AnyAction,
): UiNotificationsState => {
  switch (action.type) {
    case ADD_UI_NOTIFICATION:
      return {
        ...state,
        list: [action.notification.id, ...state.list],
        map: {
          ...state.map,
          [action.notification.id]: action.notification,
        },
      }
    case REMOVE_UI_NOTIFICATION:
      return {
        ...state,
        list: R.without([action.notificationId], state.list),
      }
    default:
      return state
  }
}

export const getUiNotificationsState = (
  state: RootState,
): UiNotificationsState => state.uiNotifications
export const getUiNotificationsMap = (state: RootState) =>
  getUiNotificationsState(state).map
export const getUiNotificationsIds = (state: RootState) =>
  getUiNotificationsState(state).list
export const getUiNotification = R.curry((id, state) =>
  R.prop(id, getUiNotificationsMap(state)),
)
export const getUiNotifications = (state: RootState) =>
  R.props(getUiNotificationsIds(state), getUiNotificationsMap(state))
