import { useEffect, useLayoutEffect, useState, useTransition } from 'react'

const useTransitioningList = (list: any[], transitionStep: number) => {
  const [isPending, startTransition] = useTransition()

  const [transitioningList, setTransitioningList] = useState<string[]>([])

  useLayoutEffect(() => {
    setTransitioningList(list.slice(0, transitionStep))
  }, [list])

  useEffect(() => {
    if (!isPending && transitioningList.length < list.length) {
      startTransition(() => {
        setTransitioningList((oldList) =>
          oldList.concat(
            list.slice(oldList.length, oldList.length + transitionStep),
          ),
        )
      })
    }
  }, [isPending, list])

  return {
    transitioningList,
    isPending,
  }
}

export default useTransitioningList
