enum RhapsodyGoPreferences {
  MERCHANT_GO_ID = 'merchantGoId',
  // NOTE: not required at this point
  // ACH_PROCESSOR_ID = 'achProcessorId',
  CARD_PROCESSOR_ID = 'cardProcessorId',
  KEY = 'key',
  TRANSCENTER_ID = 'transcenterId',
  ACH_URL = 'achUrl',
  CARD_URL = 'cardUrl',
}

export default RhapsodyGoPreferences
