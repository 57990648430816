import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiPopper, PuiPopperProps } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: theme.spacing(2, 2, 3, 2),
    },
    button: {
      marginTop: theme.spacing(2),
      minWidth: 150,
      height: 40,
    },
    icon: {
      fontSize: '1.4rem',
      top: 2,
      position: 'relative',
      pointerEvents: 'none',
    },
    anchor: {
      userSelect: 'none',
      cursor: 'pointer',
    },
  }),
  { name: 'ApplierPopper' },
)

export interface ApplierPopperProps extends Partial<PuiPopperProps> {
  Component: React.ReactNode
  applyLabel?: string
  children: React.ReactElement
  condition?: boolean
  id: string
  onApply: () => void
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  onClose: (event: React.MouseEvent<HTMLDivElement>) => void
}

const ApplierPopper = ({
  id,
  children,
  applyLabel: applyLabelProp,
  condition = true,
  Component,
  onApply,
  onClick,
  onClose,
  open = false,
  ...rest
}: ApplierPopperProps) => {
  const classes = useStyles()
  const ref = useRef()
  const { t } = useTranslation('Common')
  const applyLabel = applyLabelProp || t('Common:APPLY_TO_ALL')

  if (!condition) {
    return children
  }

  return React.cloneElement(
    children,
    {
      id,
      onClick,
      ref,
      className: classNames(children?.props?.className, classes.anchor),
    },
    <>
      {children.props.children}
      {open ? (
        <UnfoldLessIcon className={classes.icon} />
      ) : (
        <UnfoldMoreIcon className={classes.icon} />
      )}
      <PuiPopper
        hideCloseButton
        anchorEl={ref && ref.current}
        open={open}
        placement="bottom-start"
        onClose={onClose}
        {...rest}
      >
        <Grid container className={classes.container} direction="column">
          <Grid item>{Component}</Grid>
          <Grid item>
            <Fab
              className={classes.button}
              color="inherit"
              variant="extended"
              onClick={onApply}
            >
              {applyLabel}
            </Fab>
          </Grid>
        </Grid>
      </PuiPopper>
    </>,
  )
}

export default ApplierPopper
