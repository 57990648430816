import React, { forwardRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

import { isFirefox } from '~/utils'

const useStyles = makeStyles(
  () => ({
    hidden: {
      position: 'absolute',
      display: 'none',
    },
  }),
  { name: 'Print' },
)

export interface PrintIFrameProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  classes?: ClassesType<typeof useStyles>
  visible?: boolean
}

const PrintIFrame = forwardRef<HTMLIFrameElement, PrintIFrameProps>(
  ({ classes: classesProp, visible = false, ...rest }, ref) => {
    const classes = useStyles({ classes: classesProp })
    return (
      // eslint-disable-next-line react/iframe-missing-sandbox
      <iframe
        className={!visible ? classes.hidden : undefined}
        ref={ref}
        title="print-iframe"
        // Note: hack for the FireFox to use file name from `title` instead `URL`
        {...(isFirefox() ? { src: 'about:blank:' } : {})}
        {...rest}
      />
    )
  },
)

export default PrintIFrame
