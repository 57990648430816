export default {
  APPOINTMENT_HAS_FINALIZED_SOAP: 'APPOINTMENT_HAS_FINALIZED_SOAP',
  APPOINTMENT_HAS_MORE_THAN_ONE_SOAP: 'APPOINTMENT_HAS_MORE_THAN_ONE_SOAP',
  APPOINTMENT_HAS_NO_SOAP: 'APPOINTMENT_HAS_NO_SOAP',
  APPOINTMENT_HAS_SOAP_WITH_POSTED_INVOICE:
    'APPOINTMENT_HAS_SOAP_WITH_POSTED_INVOICE',
  APPOINTMENT_TYPE_NOT_AVAILABLE: 'APPOINTMENT_TYPE_NOT_AVAILABLE',
  BOOP_PAYLOAD_LIMIT_ERROR_TYPE: 'BOOP_PAYLOAD_LIMIT_ERROR_TYPE',
  BUSINESS_APPOINTMENT_TYPE_WITH_SUCH_NAME_ALREADY_EXISTS_ERROR_TYPE:
    'BUSINESS_APPOINTMENT_TYPE_WITH_SUCH_NAME_ALREADY_EXISTS_ERROR_TYPE',
  BUSINESS_ROLES_MISSING: 'BUSINESS_ROLES_MISSING',
  CANNOT_CHANGE_PATIENT: 'CANNOT_CHANGE_PATIENT',
  CANT_DELETE_INVOICE_LOG_ITEM: 'CANT_DELETE_INVOICE_LOG_ITEM',
  CANT_CREATE_SOAP_LOG: 'CANT_CREATE_SOAP_LOG',
  CANT_DELETE_SOAP_LOG: 'CANT_DELETE_SOAP_LOG',
  CONFIRM_MEMBERSHIP_CANCEL: 'CONFIRM_MEMBERSHIP_CANCEL',
  CONVERSATION_ACCESS_FROM_OUTER_BUSINESS_ERROR_TYPE:
    'CONVERSATION_ACCESS_FROM_OUTER_BUSINESS_ERROR_TYPE',
  CREDIT_ADJUSTMENT_EXCEEDS_INVOICES_TOTAL_ERROR:
    'CREDIT_ADJUSTMENT_EXCEEDS_INVOICES_TOTAL_ERROR',
  CREDIT_ADJUSTMENT_FOR_PAID_INVOICE_ERROR:
    'CREDIT_ADJUSTMENT_FOR_PAID_INVOICE_ERROR',
  EMAIL_PAYLOAD_LIMIT_ERROR_TYPE: 'EMAIL_PAYLOAD_LIMIT_ERROR_TYPE',
  ENTITY_IS_READ_ONLY: 'ENTITY_IS_READ_ONLY',
  ESTIMATE_HAS_BEEN_ASSIGNED_TO_ANOTHER_EVENT:
    'ESTIMATE_HAS_BEEN_ASSIGNED_TO_ANOTHER_EVENT',
  ESTIMATE_HAS_NO_ACTIVE_ITEMS: 'ESTIMATE_HAS_NO_ACTIVE_ITEMS',
  ESTIMATE_HAS_SOME_INACTIVE_ITEMS: 'ESTIMATE_HAS_SOME_INACTIVE_ITEMS',
  FINALIZED_SOAP_ITEM_MODIFICATION: 'FINALIZED_SOAP_ITEM_MODIFICATION',
  INVENTORY_LOG_IS_READ_ONLY: 'INVENTORY_LOG_IS_READ_ONLY',
  INVOICE_IS_POSTED: 'INVOICE_IS_POSTED',
  MEMBERSHIP_CANCEL: 'MEMBERSHIP_CANCEL',
  NO_PERMISSION: 'NO_PERMISSION',
  ORDERS_ARE_LOCKED: 'ORDERS_ARE_LOCKED',
  ORDER_CANNOT_BE_MODIFIED: 'ORDER_CANNOT_BE_MODIFIED',
  ORPHAN_IS_ALREADY_ASSIGNED: 'ORPHAN_IS_ALREADY_ASSIGNED',
  PREPAID_IN_USE: 'PREPAID_IN_USE',
  PREPAID_INVOICE_ONLY: 'PREPAID_INVOICE_ONLY',
  RX_HAS_REFILLS: 'RX_HAS_REFILLS',
  SMS_ATTEMPT_TO_SEND_TO_UNSUBSCRIBED_RECIPIENT_ERROR:
    'SMS_ATTEMPT_TO_SEND_TO_UNSUBSCRIBED_RECIPIENT_ERROR',
  SMS_INVALID_PHONE_NUMBER: 'SMS_INVALID_PHONE_NUMBER',
  SMS_MESSAGE_LENGTH_LIMIT_ERROR_TYPE: 'SMS_MESSAGE_LENGTH_LIMIT_ERROR_TYPE',
  SMS_RECIPIENT_REGION_IS_NOT_SUPPORTED_ERROR:
    'SMS_RECIPIENT_REGION_IS_NOT_SUPPORTED_ERROR',
  SOAP_IS_FINALIZED: 'SOAP_IS_FINALIZED',
  SOAP_IS_NOT_FOUND: 'SOAP_IS_NOT_FOUND',
  STALE_INVOICE_PAYMENT_LINKAGE: 'STALE_INVOICE_PAYMENT_LINKAGE',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
}
