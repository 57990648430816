import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  AlertIconType,
  LanguageUtils,
  PuiAlert,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import LabVendorNames from '~/constants/labVendorName'
import {
  editLabVendorConfig,
  fetchLabVendorConfigs,
  getLabConfigs,
  getTestingLabs,
} from '~/store/duck/labVendorConfig'
import { getLabVendors } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps, LabVendorConfig } from '~/types'
import { arrayToMap } from '~/utils'
import useDialog from '~/utils/useDialog'

import AbaxisLab from './AbaxisLab'
import AntechLab from './AntechLab'
import HeskaLab from './HeskaLab'
import IdexxLab from './IdexxLab'
import ZnLabsLab from './ZnLabsLab'

const LabsMap = {
  [LabVendorNames.IDEXX]: IdexxLab,
  [LabVendorNames.IDEXX_ES]: IdexxLab,
  [LabVendorNames.ABAXIS]: AbaxisLab,
  [LabVendorNames.ANTECH]: AntechLab,
  [LabVendorNames.ZN_LABS]: ZnLabsLab,
  [LabVendorNames.HESKA]: HeskaLab,
}

const LabIntegrations = function LabIntegrations({
  business,
}: BasePracticeDetailsSectionProps) {
  const businessId = business.id
  const dispatch = useDispatch()
  const labConfigs = useSelector(getLabConfigs(businessId))
  const testingsLabs = useSelector(getTestingLabs(businessId))
  const LabVendors = useSelector(getLabVendors)

  const { t } = useTranslation(['Common', 'Businesses'])

  const [changedLabMap, setChangedLabMap] = useState({})
  const [
    successfullyIntegratedLabsOpenAlertMap,
    setSuccessfullyIntegratedLabsOpenAlertMap,
  ] = useState<Record<string, boolean>>({})

  const [openConfirmDisconnectAlert, closeConfirmDisconnectAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const getLab = (vendorId: string, labs: LabVendorConfig[]) =>
    labs.find((lab) => lab.vendorId === vendorId)

  useEffect(() => {
    if (businessId) {
      dispatch(fetchLabVendorConfigs(businessId))
    }
  }, [businessId])

  useEffect(() => {
    const ids = Object.entries(testingsLabs)
      .map(([vendorId, { success }]) => {
        const isActive = getLab(vendorId, labConfigs)?.active
        const showSuccessAlert =
          success &&
          successfullyIntegratedLabsOpenAlertMap[vendorId] !== false &&
          isActive
        return showSuccessAlert ? vendorId : undefined
      })
      .filter(Boolean)
    if (ids.length > 0) {
      setSuccessfullyIntegratedLabsOpenAlertMap({
        ...successfullyIntegratedLabsOpenAlertMap,
        ...arrayToMap(ids as string[], R.identity, true),
      })
    }
  }, [testingsLabs])

  const handleLabUpdate = (vendorId: string, config: object) => {
    dispatch(editLabVendorConfig(businessId, vendorId, config))
  }

  const disconnectLab = (vendorId: string) => {
    dispatch(
      editLabVendorConfig(businessId, vendorId, { vendorId, active: false }),
    )
  }

  const changeSuccessAlertIsOpen = (
    vendorId: string,
    open: boolean = false,
  ) => {
    setSuccessfullyIntegratedLabsOpenAlertMap({
      ...successfullyIntegratedLabsOpenAlertMap,
      [vendorId]: open,
    })
  }

  const onDisconnectAlertApprove = (labToDiscard: string) => {
    disconnectLab(labToDiscard)
    setChangedLabMap(R.omit([labToDiscard], changedLabMap))
    changeSuccessAlertIsOpen(labToDiscard, undefined)
    closeConfirmDisconnectAlert()
  }

  const onToggle = ({
    nextState,
    initiallyActive,
    proceed,
    vendorId,
  }: {
    initiallyActive: boolean
    nextState: boolean
    proceed: () => void
    vendorId: string
  }) => {
    if (!nextState && initiallyActive) {
      const disconnectLabName = LanguageUtils.getConstantTranslatedName(
        vendorId,
        LabVendors,
      )
      openConfirmDisconnectAlert({
        cancelButtonText: t('Businesses:INTEGRATION.DISCONNECT_NAME', {
          name: disconnectLabName,
        }),
        iconType: AlertIconType.WARN,
        message: t('Businesses:INTEGRATION.TURN_OFF_INTEGRATION_MESSAGE', {
          name: disconnectLabName,
        }),
        okButtonText: t('Businesses:INTEGRATION.KEEP_CONNECTION'),
        onCancel: () => {
          onDisconnectAlertApprove(vendorId)
        },
        onClose: closeConfirmDisconnectAlert,
        onOk: closeConfirmDisconnectAlert,
      })
    } else {
      proceed()
    }
  }

  return (
    <>
      <Grid container direction="column">
        {labConfigs.map(({ vendorId, active, ...credentials }) => {
          const name = Utils.getConstantName(vendorId, LabVendors)
          const Lab = LabsMap[name as keyof typeof LabsMap]

          if (!Lab) {
            return null
          }

          return (
            <Grid key={vendorId}>
              <Lab
                business={business}
                credentials={credentials}
                initiallyActive={active}
                isTesting={testingsLabs[vendorId]?.testing === true}
                name={name}
                status={testingsLabs[vendorId]?.status}
                vendorId={vendorId}
                onTest={(config: LabVendorConfig) =>
                  handleLabUpdate(vendorId, config)
                }
                onToggle={(nextState: boolean, proceed: () => void) => {
                  onToggle({
                    nextState,
                    initiallyActive: active,
                    proceed,
                    vendorId,
                  })
                }}
              />
            </Grid>
          )
        })}
      </Grid>
      {Object.entries(successfullyIntegratedLabsOpenAlertMap).map(
        ([vendorId, open]) => {
          const name = LanguageUtils.getConstantTranslatedName(
            vendorId,
            LabVendors,
          )

          return (
            <PuiAlert
              iconType={AlertIconType.SUCCESS}
              key={vendorId}
              message={t(
                'Businesses:INTEGRATION.YOU_ARE_NOW_SET_UP_TO_ORDER_LABS',
                { name },
              )}
              okButtonText={t('Common:CLOSE_ACTION')}
              open={Boolean(open)}
              onClose={() => changeSuccessAlertIsOpen(vendorId, false)}
              onOk={() => changeSuccessAlertIsOpen(vendorId, false)}
            />
          )
        },
      )}
    </>
  )
}

export default LabIntegrations
