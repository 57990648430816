import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Reminder, UnsavedReminder } from '~/types'

import { getAPIPath, request } from './core'
import { requestMutation } from './graphql/graphqlCore'
import { UPDATE_REMINDER } from './graphql/mutations/reminders'
import * as Schema from './schemas'

export const fetchReminderV2 = (
  businessId: string,
  reminderId: string,
  soapId?: string | Nil,
) =>
  request(
    getAPIPath(`v2/reminders/${reminderId}`),
    { method: 'GET', params: { businessId, soapId } },
    true,
    Schema.reminder,
  )

export const fetchRemindersV2 = (
  businessId: string,
  patientId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  stateIds: string[] = [],
) =>
  request(
    getAPIPath('v2/reminders'),
    {
      method: 'GET',
      params: { businessId, patientId, from, to, stateIds: stateIds.join(',') },
    },
    true,
    Schema.remindersGroupsList,
  )

export const editReminderV2 = (businessId: string, reminder: Reminder) =>
  request(
    getAPIPath(`v2/reminders/${reminder.id}/base`),
    { method: 'PUT', params: { businessId }, data: reminder },
    true,
    Schema.reminder,
  )

export const deleteReminderV2 = (businessId: string, reminderId: string) =>
  request(
    getAPIPath(`v2/reminders/${reminderId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const createManualReminder = (
  businessId: string,
  reminder: UnsavedReminder,
  soapId: string | Nil,
) =>
  request(
    getAPIPath('v2/reminders'),
    { method: 'POST', params: { businessId }, data: { ...reminder, soapId } },
    true,
    Schema.reminder,
  )

export const createFromProtocol = (
  businessId: string,
  protocolId: string,
  patientId: string,
  soapId: string,
) =>
  request(
    getAPIPath('v2/reminders/fromProtocol'),
    {
      method: 'POST',
      params: { businessId },
      data: { protocolId, patientId, soapId },
    },
    true,
  )

export const partialUpdateReminders = requestMutation({
  mutation: UPDATE_REMINDER,
  variablesHandler: (_, reminderId, input) => ({
    id: reminderId,
    input,
  }),
})
