import * as R from 'ramda'
import { User } from '@pbt/pbt-ui-components'

import { SlotType } from '~/constants/schedulerConstants'
import { Schedule } from '~/types'

export const getSlotsAndWorkingHours = (
  candidate: User,
  schedules: Schedule[],
) => {
  const { workingHours, slots: scheduleSlots } =
    schedules.find((schedule) => schedule.personId === candidate.id) || {}
  const slots = R.is(Object, scheduleSlots)
    ? R.flatten(R.values(scheduleSlots || {}))
    : scheduleSlots

  return { slots, workingHours }
}

export const activeUserFilterMatcher = (
  candidate: User,
  schedules: Schedule[],
) => {
  const { slots = [] } = getSlotsAndWorkingHours(candidate, schedules)
  const appointmentSlots = slots.filter(
    ({ type }) => type === SlotType.APPOINTMENT,
  )
  return (
    (candidate.active && !candidate.deleted) ||
    (appointmentSlots && appointmentSlots.length > 0)
  )
}

export const workingTodayFilterMatcher = (
  candidate: User,
  schedules: Schedule[],
) => {
  const { workingHours, slots } = getSlotsAndWorkingHours(candidate, schedules)
  return (
    Boolean(workingHours && workingHours.from && workingHours.to) ||
    (slots && slots.length > 0)
  )
}
