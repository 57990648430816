import { ApiError } from '@pbt/pbt-ui-components'

import { RegistrationFinalQuestions } from '~/types'

import { BusinessDto } from '../dto/Business'
import { PersonDto } from '../dto/Person'
import {
  CLEAR_INVITATION_ALREADY_ACCEPTED,
  CLEAR_KEY_ALREADY_EXISTS,
  CLEAR_USER_ALREADY_EXISTS,
  CREATE_PERSONS,
  FETCH_INVITATION,
  FETCH_INVITATION_FAILURE,
  FETCH_INVITATION_SUCCESS,
  FETCHING_LOCATION_DATA,
  INVITATION_ALREADY_ACCEPTED,
  REGISTER_BY_INVITE_REQUEST,
  REGISTRATION_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REMOVE_BUSINESS,
  SAVE_PERSON,
  SET_KEY_ALREADY_EXISTS,
  SET_USER_ALREADY_EXISTS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_CANDIDATE,
  UPDATE_FINAL_QUESTIONS,
  UPDATE_MEMBER,
  VALIDATE_ACTIVATION_KEY,
  VALIDATE_ACTIVATION_KEY_FAILURE,
  VALIDATE_ACTIVATION_KEY_SUCCESS,
} from './types/registration'

export const fetchingLocationData = () => ({ type: FETCHING_LOCATION_DATA })
export const updateBusiness = (business: BusinessDto | null = null) => ({
  type: UPDATE_BUSINESS,
  business,
})
export const removeBusiness = (businessId: string) => ({
  type: REMOVE_BUSINESS,
  businessId,
})
export const updateBusinessCandidate = (business: BusinessDto | null) => ({
  type: UPDATE_BUSINESS_CANDIDATE,
  business,
})
export const registrationFailure = (error: ApiError) => ({
  type: REGISTRATION_FAILURE,
  error,
})
export const registrationRequest = () => ({ type: REGISTRATION_REQUEST })
export const registrationSuccess = () => ({ type: REGISTRATION_SUCCESS })
export const savePerson = (person: PersonDto) => ({ type: SAVE_PERSON, person })
export const updateMember = (member: PersonDto) => ({
  type: UPDATE_MEMBER,
  member,
})
export const fetchInvitation = (invitationToken: string) => ({
  type: FETCH_INVITATION,
  invitationToken,
})
export const fetchInvitationSuccess = (
  person: PersonDto,
  businesses: Record<string, BusinessDto>,
  token: string,
) => ({
  type: FETCH_INVITATION_SUCCESS,
  person,
  businesses,
  token,
})
export const fetchInvitationFailure = (error: ApiError) => ({
  type: FETCH_INVITATION_FAILURE,
  error,
})

export const invitationAlreadyAccepted = (email: string) => ({
  type: INVITATION_ALREADY_ACCEPTED,
  email,
})
export const clearInvitationAlreadyAccepted = () => ({
  type: CLEAR_INVITATION_ALREADY_ACCEPTED,
})

export const registerByInvite = (invitationToken?: string) => ({
  type: REGISTER_BY_INVITE_REQUEST,
  invitationToken,
})
export const setUserAlreadyExists = () => ({ type: SET_USER_ALREADY_EXISTS })
export const clearUserAlreadyExists = () => ({
  type: CLEAR_USER_ALREADY_EXISTS,
})

export const validateActivationKey = (key: string) => ({
  type: VALIDATE_ACTIVATION_KEY,
  key,
})
export const validateActivationKeySuccess = (metadata: any, key: string) => ({
  type: VALIDATE_ACTIVATION_KEY_SUCCESS,
  metadata,
  key,
})
export const validateActivationKeyFailure = (error: ApiError) => ({
  type: VALIDATE_ACTIVATION_KEY_FAILURE,
  error,
})

export const setKeyAlreadyExists = () => ({ type: SET_KEY_ALREADY_EXISTS })
export const clearKeyAlreadyExists = () => ({ type: CLEAR_KEY_ALREADY_EXISTS })

export const createPersons = (amountOfPersons: number) => ({
  type: CREATE_PERSONS,
  amountOfPersons,
})
export const updateFinalQuestions = (
  finalQuestions: RegistrationFinalQuestions,
) => ({
  type: UPDATE_FINAL_QUESTIONS,
  finalQuestions,
})

export const REGISTRATION_SENSITIVE_ACTIONS = [
  {
    type: VALIDATE_ACTIVATION_KEY,
    sensitiveData: ['key'],
  },
  {
    type: VALIDATE_ACTIVATION_KEY_SUCCESS,
    sensitiveData: ['key'],
  },
  {
    type: FETCH_INVITATION,
    sensitiveData: ['invitationToken'],
  },
  {
    type: FETCH_INVITATION_SUCCESS,
    sensitiveData: ['token'],
  },
  {
    type: REGISTER_BY_INVITE_REQUEST,
    sensitiveData: ['invitationToken'],
  },
  {
    type: SAVE_PERSON,
    sensitiveData: ['person'],
  },
]
