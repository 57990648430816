import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiSelect,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { getPimsFull } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      margin: theme.spacing(0, 1),
      minWidth: 150,
      whiteSpace: 'nowrap',
    },
    pimsSelect: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'SelectPimsDialog' },
)

interface SelectPimsDialogProps extends BasePuiDialogProps {
  onProceed: (value: string) => void
}

const SelectPimsDialog = ({
  open,
  onClose,
  onProceed,
}: SelectPimsDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const PimsFull = useSelector(getPimsFull)

  const AvimarkId = Utils.findConstantIdByName('Avimark', PimsFull)
  const CovetrusId = Utils.findConstantIdByName('Covetrus', PimsFull)
  const UniversalId = Utils.findConstantIdByName('Other', PimsFull)
  const InventoryId = Utils.findConstantIdByName('Inventory', PimsFull)
  const excludePimsIdsList = [CovetrusId, UniversalId, InventoryId]

  const selectablePimsList = useMemo(
    () =>
      PimsFull.filter(
        ({ id }: { id: string }) => !excludePimsIdsList.includes(id),
      ),
    [PimsFull],
  )

  const {
    fields: { selectedPimsId },
  } = useFields(
    [
      {
        name: 'selectedPimsId',
        initialValue: AvimarkId,
        type: 'select',
      },
    ],
    false,
  )

  const handleProceed = () => {
    onProceed(selectedPimsId.value)
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="select-pims-dialog"
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        pb={3}
        pr={3}
        pt={6}
      >
        <PuiSelect
          className={classes.pimsSelect}
          field={selectedPimsId}
          items={selectablePimsList}
        />
        <Grid container justifyContent="center" wrap="nowrap">
          <ButtonWithLoader
            className={classes.button}
            color="secondary"
            onClick={onClose}
          >
            {t('Common:CANCEL_ACTION')}
          </ButtonWithLoader>
          <ButtonWithLoader className={classes.button} onClick={handleProceed}>
            {t('Common:CREATE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default SelectPimsDialog
