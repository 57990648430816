import React from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils } from '@pbt/pbt-ui-components'

import { getFinanceInvoice } from '~/store/reducers/finance'
import { getPatientName } from '~/store/reducers/patients'
import { Invoice } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    label: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'EmailInvoiceSelectableListItem' },
)

export interface EmailInvoiceSelectableListItemProps {
  invoiceId: string
  isChecked: boolean
  toggleItem: (invoiceId: string) => void
}

const EmailInvoiceSelectableListItem = ({
  isChecked,
  invoiceId,
  toggleItem,
}: EmailInvoiceSelectableListItemProps) => {
  const classes = useStyles()
  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Invoice
  const patientName = useSelector(getPatientName(invoice?.patient))

  const eventStart = invoice?.event
    ? DateUtils.formatDate(invoice?.event.scheduledStartDatetime)
    : ''

  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox checked={isChecked} onChange={() => toggleItem(invoiceId)} />
      }
      key={invoiceId}
      label={`${patientName} ${invoice?.description} ${eventStart}`}
      value={invoiceId}
    />
  )
}

export default EmailInvoiceSelectableListItem
