import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  Constant,
  Defaults,
  LanguageUtils,
  moment,
  Nil,
  Text,
  TextWithTooltip,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { Note } from '@pbt/pbt-ui-components/src/icons'

import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import {
  cleanUpUserEntities,
  deleteTimeEntity,
  fetchMoreUserTimeEntities,
  fetchUserTimeEntities,
  getMultipleTimeTrackerEntities,
  getTimeTrackingEntriesTotalCount,
  getUserEntityIdsList,
} from '~/store/duck/timeTracker'
import { getClockOutReasons } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { TimeEntity } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import { getDateString, getHoursBetween } from '~/utils/time'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: theme.spacing(1, 0),
    },
    text: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'TimeTrackingSection' },
)

const mainColumn = {
  label: i18n.t('Admin:MEMBER.TIME_TRACKING_TABLE.COLUMNS.DATE'),
  value: ({ clockinDatetime, clockoutDatetime }: TimeEntity) =>
    clockoutDatetime
      ? getDateString(moment(clockinDatetime), moment(clockoutDatetime))
      : `${getDateString(moment(clockinDatetime))}`,
}

const buildColumns = (user: User | Nil, ClockoutReasons: Constant[]) => {
  const clockInColumn = {
    label: i18n.t('Admin:MEMBER.TIME_TRACKING_TABLE.COLUMNS.CLOCK_IN'),
    value: (item: TimeEntity) => (
      <TextWithTooltip
        Icon={Note}
        tooltipText={
          item.clockinNotes && (
            <>
              <Text strong variant="body2">
                {i18n.t<string>(
                  'Admin:MEMBER.TIME_TRACKING_TABLE.TEAM_MEMBER_TOOLTIP',
                  {
                    person: Utils.getPersonString(user),
                  },
                )}
              </Text>
              {item.clockinNotes}
            </>
          )
        }
        variant="body"
      >
        {moment(item.clockinDatetime).format(Defaults.HOURS_FORMAT)}
      </TextWithTooltip>
    ),
  }

  const clockOutColumn = {
    label: i18n.t('Admin:MEMBER.TIME_TRACKING_TABLE.COLUMNS.CLOCK_OUT'),
    value: (item: TimeEntity) => (
      <TextWithTooltip
        Icon={Note}
        tooltipText={
          item.clockoutNotes && (
            <>
              <Text strong variant="body2">
                {i18n.t<string>(
                  'Admin:MEMBER.TIME_TRACKING_TABLE.TEAM_MEMBER_TOOLTIP',
                  {
                    person: Utils.getPersonString(user),
                  },
                )}
              </Text>
              {item.clockoutNotes}
            </>
          )
        }
      >
        {item.clockoutDatetime
          ? moment(item.clockoutDatetime).format(Defaults.HOURS_FORMAT)
          : ''}
      </TextWithTooltip>
    ),
  }

  const reasonColumn = {
    label: i18n.t('Admin:MEMBER.TIME_TRACKING_TABLE.COLUMNS.REASON'),
    value: (item: TimeEntity) =>
      item.clockoutDatetime &&
      LanguageUtils.getConstantTranslatedName(
        item.clockoutReason?.id,
        ClockoutReasons,
        LanguageUtils.getTranslatedFieldName(item.clockoutReason),
      ),
  }

  const hoursColumn = {
    label: i18n.t('Admin:MEMBER.TIME_TRACKING_TABLE.COLUMNS.HOURS'),
    value: (item: TimeEntity) => (
      <TextWithTooltip
        Icon={Note}
        tooltipText={
          item.administratorNotes && (
            <>
              {item.creator && (
                <Text strong variant="body2">
                  {Utils.getPersonString(item.creator)}:
                </Text>
              )}
              {item.administratorNotes}
            </>
          )
        }
      >
        {item.clockoutDatetime
          ? getHoursBetween(item.clockinDatetime, item.clockoutDatetime)
          : ''}
      </TextWithTooltip>
    ),
  }

  return [clockInColumn, clockOutColumn, reasonColumn, hoursColumn]
}

export interface TimeTrackingSectionProps {
  deleteAllowed: boolean
  updateAllowed: boolean
  userId: string
}

const TimeTrackingSection = ({
  userId,
  updateAllowed,
  deleteAllowed,
}: TimeTrackingSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])
  const dispatch = useDispatch()
  const list = useSelector(getUserEntityIdsList)
  const items = useSelector(getMultipleTimeTrackerEntities(list))
  const totalCount = useSelector(getTimeTrackingEntriesTotalCount)
  const user = useSelector(getUser(userId))
  const ClockoutReasons = useSelector(getClockOutReasons)

  const [openTimeTrackerEditDialog] = useDialog(DialogNames.TIME_TRACKER_EDIT)
  const [openDeleteTimeEntryAlert, closeDeleteTimeEntryAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserTimeEntities(userId))
    }
    return () => {
      dispatch(cleanUpUserEntities())
    }
  }, [userId])

  const onAddTimeEntityClick = useCallback(() => {
    openTimeTrackerEditDialog({ userId })
  }, [userId])

  const confirmAndDelete = ({ id } = {} as TimeEntity) => {
    openDeleteTimeEntryAlert({
      message: getDeleteConfirmMessage('time entry'),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteTimeEntryAlert(),
      onOk: () => {
        dispatch(deleteTimeEntity(id))
        closeDeleteTimeEntryAlert()
      },
    })
  }

  const handleDelete = deleteAllowed ? confirmAndDelete : R.identity
  const handleEdit = updateAllowed
    ? ({ id } = {} as TimeEntity) => openTimeTrackerEditDialog({ id, userId })
    : R.identity

  const loadMoreItems = (from: number, to: number) => {
    dispatch(fetchMoreUserTimeEntities(userId, from, to))
  }

  const columns = buildColumns(user, ClockoutReasons)

  return (
    <>
      <PuiDataTable
        classes={{ root: classes.root }}
        columns={columns}
        items={items}
        loadMoreItems={loadMoreItems}
        mainColumn={mainColumn}
        totalCount={totalCount}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
      {updateAllowed && (
        <Grid item md={12} xs={12}>
          <AddButton
            addText={t('Admin:MEMBER.TIME_TRACKING_TABLE.ADD_TIME_ENTRY')}
            onAdd={onAddTimeEntityClick}
          />
        </Grid>
      )}
    </>
  )
}

export default TimeTrackingSection
