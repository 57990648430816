import { props } from 'ramda'
import { all, put, takeLatest } from 'redux-saga/effects'

import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import requestAPI from '../../sagas/utils/requestAPI'

export const INITIAL_STATE = {
  isLoading: false,
  map: {},
  list: [],
  activeSessionId: null,
}

export function* createSessionSaga(config, duck, { type, session }) {
  try {
    const {
      entities: { practiceCloningSession },
      result: createdSessionId,
    } = yield requestAPI(config.apiEndpoints[type], session)
    yield put(duck.actions.updateSessionEntities(practiceCloningSession))
    yield put(duck.actions.createSessionSuccess(createdSessionId))
  } catch (error) {
    yield put(duck.actions.createSessionFailure(error))
  }
}

export function* fetchSessionSaga(config, duck, { type, sessionId }) {
  try {
    const {
      entities: { practiceCloningSession },
    } = yield requestAPI(config.apiEndpoints[type], sessionId)
    yield put(duck.actions.updateSessionEntities(practiceCloningSession))
    yield put(duck.actions.fetchSessionSuccess())
  } catch (error) {
    yield put(duck.actions.fetchSessionFailure(error))
  }
}

export function* fetchActiveSessionSaga(
  config,
  duck,
  { type, targetBusinessId },
) {
  try {
    const {
      entities: { practiceCloningSession },
      result: activeSessionId,
    } = yield requestAPI(config.apiEndpoints[type], targetBusinessId)
    if (activeSessionId) {
      yield put(duck.actions.updateSessionEntities(practiceCloningSession))
    }
    yield put(duck.actions.fetchActiveSessionSuccess())
  } catch (error) {
    yield put(duck.actions.fetchActiveSessionFailure(error))
  }
}

export default (config) => ({
  types: {
    CREATE_SESSION: 'CREATE_SESSION',
    CREATE_SESSION_SUCCESS: 'CREATE_SESSION_SUCCESS',
    CREATE_SESSION_FAILURE: 'CREATE_SESSION_FAILURE',

    FETCH_SESSION: 'FETCH_SESSION',
    FETCH_SESSION_SUCCESS: 'FETCH_SESSION_SUCCESS',
    FETCH_SESSION_FAILURE: 'FETCH_SESSION_FAILURE',

    FETCH_ACTIVE_SESSION: 'FETCH_ACTIVE_SESSION',
    FETCH_ACTIVE_SESSION_SUCCESS: 'FETCH_ACTIVE_SESSION_SUCCESS',
    FETCH_ACTIVE_SESSION_FAILURE: 'FETCH_ACTIVE_SESSION_FAILURE',

    CLEAN_ACTIVE_SESSION: 'CLEAN_ACTIVE_SESSION',
    UPDATE_SESSION_ENTITIES: 'UPDATE_SESSION_ENTITIES',
  },
  actions: (duck) => ({
    createSession: (session) => ({ type: duck.types.CREATE_SESSION, session }),
    createSessionSuccess: (sessionId) => ({
      type: duck.types.CREATE_SESSION_SUCCESS,
      sessionId,
    }),
    createSessionFailure: (error) => ({
      type: duck.types.CREATE_SESSION_FAILURE,
      error,
    }),

    fetchSession: (sessionId) => ({
      type: duck.types.FETCH_SESSION,
      sessionId,
    }),
    fetchSessionSuccess: () => ({ type: duck.types.FETCH_SESSION_SUCCESS }),
    fetchSessionFailure: (error) => ({
      type: duck.types.FETCH_SESSION_FAILURE,
      error,
    }),

    fetchActiveSession: (targetBusinessId) => ({
      type: duck.types.FETCH_ACTIVE_SESSION,
      targetBusinessId,
    }),
    fetchActiveSessionSuccess: () => ({
      type: duck.types.FETCH_ACTIVE_SESSION_SUCCESS,
    }),
    fetchActiveSessionFailure: (error) => ({
      type: duck.types.FETCH_ACTIVE_SESSION_FAILURE,
      error,
    }),

    cleanActiveSession: () => ({ type: duck.types.CLEAN_ACTIVE_SESSION }),
    updateSessionEntities: (entities) => ({
      type: duck.types.UPDATE_SESSION_ENTITIES,
      entities,
    }),
  }),
  reducer: (state = INITIAL_STATE, action, duck) => {
    switch (action.type) {
      case duck.types.CREATE_SESSION:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
      case duck.types.CREATE_SESSION_SUCCESS:
        return {
          ...state,
          activeSessionId: action.sessionId,
          isLoading: false,
        }
      case duck.types.CREATE_SESSION_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: getErrorMessage(action.error),
        }
      case duck.types.FETCH_SESSION:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
      case duck.types.FETCH_SESSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }
      case duck.types.FETCH_SESSION_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: getErrorMessage(action.error),
        }
      case duck.types.FETCH_ACTIVE_SESSION:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
      case duck.types.FETCH_ACTIVE_SESSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }
      case duck.types.FETCH_ACTIVE_SESSION_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: getErrorMessage(action.error),
        }
      case duck.types.CLEAN_ACTIVE_SESSION:
        return { ...state, activeSessionId: null }
      case duck.types.UPDATE_SESSION_ENTITIES:
        return { ...state, map: secondLevelMerge(state.map, action.entities) }
      default:
        return state
    }
  },
  selectors: {
    getIsLoading: (state) => config.getReducer(state).isLoading,
    getActiveSessionId: (state) => config.getReducer(state).activeSessionId,
    getSession: (id) => (state) => config.getReducer(state).map[id],
    getSessionsList: (state) => config.getReducer(state).list,
    getMultipleSessions: (ids) => (state) =>
      props(ids || [], config.getReducer(state).map),
  },
  saga: (duck) =>
    function* saga() {
      yield all([
        yield takeLatest(
          duck.types.CREATE_SESSION,
          createSessionSaga,
          config,
          duck,
        ),
        yield takeLatest(
          duck.types.FETCH_SESSION,
          fetchSessionSaga,
          config,
          duck,
        ),
        yield takeLatest(
          duck.types.FETCH_ACTIVE_SESSION,
          fetchActiveSessionSaga,
          config,
          duck,
        ),
      ])
    },
})
