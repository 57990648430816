import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Grid, Skeleton } from '@mui/material'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { AlertIconType, Utils } from '@pbt/pbt-ui-components'
import { EventTypeName } from '@pbt/pbt-ui-components/src/types/entities'

import {
  MutationApproveReactiveRxArgs,
  MutationDeclineReactiveRxArgs,
  MutationSaveReactiveRxArgs,
} from '~/api/graphql/generated/types'
import BasePrescription from '~/components/dashboard/prescription/prescription-editor/BasePrescription'
import { PrescriptionEditorProps } from '~/components/dashboard/prescription/prescription-editor/hooks/usePrescriptionEditor'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { editTaskState, partialEditTask } from '~/store/actions/tasks'
import {
  approveReactiveRx,
  declineReactiveRx,
  fetchPrescription,
  getPrescriptionById,
  getPrescriptionError,
  getPrescriptionIsLoading,
  getPrescriptionIsUpdating,
  saveReactiveRx,
} from '~/store/duck/prescriptions'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Prescription, Task } from '~/types'
import { removeServerErrorPrefix } from '~/utils/errors'
import {
  convertOrderToRxInput,
  convertPrescriptionV2ToPrescription,
} from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useEventType } from '~/utils/useEventType'

export interface RxRequestPrescriptionProps {
  onClose: () => void
  task?: Task
  tasksIsFetching: boolean
}

const RxRequestPrescription = ({
  onClose,
  task,
  tasksIsFetching,
}: RxRequestPrescriptionProps) => {
  const { t } = useTranslation(['Tasks', 'Common'])

  const dispatch = useDispatch()

  const prescriptionId = task?.prescriptionId

  const prescriptionV2 = useSelector(getPrescriptionById(prescriptionId))
  const prescription = useMemo(
    () => convertPrescriptionV2ToPrescription(prescriptionV2),
    [prescriptionV2],
  )

  const prescriptionError = useSelector(getPrescriptionError)
  const prescriptionIsLoading = useSelector(getPrescriptionIsLoading)
  const currentUserId = useSelector(getCurrentUserId)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const [notificationToShowAfterSave, setNotificationToShowAfterSave] =
    useState<string | null>(null)
  const [updateType, setUpdateType] = useState<
    'approve' | 'decline' | 'draft' | null
  >(null)

  const clientId = task?.client || ''
  const patientId = task?.patient || ''
  const taskName = task?.name || t('Common:TASK')
  const forPatient = task?.patient
    ? t('Tasks:TASK_NOTIFICATIONS.FOR_PATIENT', { patient: task.patient })
    : ''

  const taskStates = useEventType(EventTypeName.Task, 'states')
  const doneState = Utils.findConstantIdByName('Done', taskStates)

  const setTaskState = (stateId: string) => {
    if (task?.id) {
      dispatch(editTaskState(task.id, stateId))
    }
  }

  const showActionNotification = (message: string) => {
    dispatch(
      addUiNotification({
        id: uuid(),
        message,
        type: SnackNotificationType.TASK_ACTION,
        params: {
          taskId: task?.id,
        },
      }),
    )
  }

  const afterSaveCallback = (callback?: () => void) => {
    if (prescriptionError) {
      openAlert({
        iconType: AlertIconType.WARN,
        content: (
          <Grid mb={3} mt={1}>
            {removeServerErrorPrefix(prescriptionError)}
          </Grid>
        ),
        onOk: closeAlert,
      })
    } else {
      if (notificationToShowAfterSave !== null) {
        showActionNotification(notificationToShowAfterSave)
      }
      if (updateType && updateType === 'approve') {
        setTaskState(doneState)
      }
      setNotificationToShowAfterSave(null)
      setUpdateType(null)
      onClose()
    }
    if (typeof callback === 'function') {
      callback()
    }
  }

  const afterSave = useCloseAfterCreation(
    afterSaveCallback,
    getPrescriptionIsUpdating,
  )

  useEffect(() => {
    if (prescriptionId) {
      dispatch(fetchPrescription(prescriptionId))
    }
  }, [prescriptionId])

  const updateTaskTeamMember = (teamMemberId?: string) => {
    if (task && !R.isNil(task?.id)) {
      dispatch(partialEditTask({ id: task.id, assigneeId: teamMemberId }))
    }
  }

  const handleSave: PrescriptionEditorProps['onSave'] = (
    prescriptionData,
    options = {},
  ) => {
    updateTaskTeamMember(options?.teamMemberId)

    const mutationArgs:
      | MutationApproveReactiveRxArgs
      | MutationSaveReactiveRxArgs
      | MutationDeclineReactiveRxArgs = {
      prescriptionId: prescriptionData.id,
      input: {
        ...convertOrderToRxInput(
          prescriptionData as unknown as Prescription,
          isFoodCatalogEnabled,
        ),
        signerId: currentUserId,
      },
    }

    if (options.isApprove) {
      dispatch(approveReactiveRx(mutationArgs))
      setUpdateType('approve')
      setNotificationToShowAfterSave(
        t('Tasks:TASK_NOTIFICATIONS.APPROVED', { taskName, forPatient }),
      )
    } else if (options.declineReason) {
      dispatch(declineReactiveRx(mutationArgs))
      setUpdateType('decline')
      setNotificationToShowAfterSave(
        t('Tasks:TASK_NOTIFICATIONS.DECLINED', {
          taskName,
          forPatient,
          declineReason: options.declineReason,
        }),
      )
    } else {
      dispatch(saveReactiveRx(mutationArgs))
      setUpdateType('draft')
      setNotificationToShowAfterSave(t('Tasks:TASK_NOTIFICATIONS.DRAFT_SAVED'))
    }
    afterSave(() => {
      if (typeof options.afterSaveCallback === 'function') {
        options.afterSaveCallback()
      }
    })
  }

  if (tasksIsFetching || (prescriptionIsLoading && !prescription)) {
    return <Skeleton height={24} sx={{ m: 2 }} variant="rounded" width={24} />
  }

  if (prescriptionError && !prescription) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {removeServerErrorPrefix(prescriptionError)}
      </Alert>
    )
  }

  if (task && !tasksIsFetching && !prescriptionId) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {t('Tasks:ERROR_NO_PRESCRIPTION_ID')}
      </Alert>
    )
  }

  if (!prescription) {
    return null
  }

  return (
    <BasePrescription
      outsideSoap
      skipOrderFetching
      assigneeId={task?.assigned}
      clientId={clientId}
      patientId={patientId}
      prescription={prescription}
      taskId={task?.id}
      onSave={handleSave}
    />
  )
}

export default RxRequestPrescription
