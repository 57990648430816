import React from 'react'
import { Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { InvoiceLineItem } from '~/types'

import ChargeDetailsCurrencyField from './ChargeDetailsCurrencyField'

export interface ChargeFieldTaxProps {
  className?: string
  inputClassName?: string
  item: InvoiceLineItem | RetailOrderLineItem
  taxField: Field
}

const ChargeFieldTax = ({
  className: classNameProp,
  inputClassName,
  item,
  taxField,
}: ChargeFieldTaxProps) => (
  <ChargeDetailsCurrencyField
    disabled
    className={classNameProp}
    field={taxField}
    inputClassName={inputClassName}
    item={item}
  />
)

export default ChargeFieldTax
