import { call, put, select } from 'redux-saga/effects'
import { EmailUtils, Nil, User } from '@pbt/pbt-ui-components'
import { EmailValidationType } from '@pbt/pbt-ui-components/src/constants/users'

import { partialUpdateClient, validateClientEmail } from '../../actions/clients'
import { getUser } from '../../reducers/users'

export function* validateClientEmailWithCommonMistakes(clientId: string) {
  const client: User = yield select(getUser(clientId))

  const {
    email: suggestedEmail,
    fixed,
  }: {
    email: string | Nil
    fixed: boolean
    suspicious: boolean
  } = yield call(EmailUtils.validateCommonEmailMistakes, client.email)

  if (fixed) {
    yield put(
      partialUpdateClient({
        id: clientId,
        emailValidationResultType: EmailValidationType.INVALID,
        emailValidationSuggestion: suggestedEmail,
      }),
    )
  } else {
    yield put(validateClientEmail(clientId))
  }
}
