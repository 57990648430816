import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import { Field, Nil, useFields } from '@pbt/pbt-ui-components'

import { RefundInvoice } from '~/api/graphql/generated/types'
import { InvoiceViewStates } from '~/constants/refund'
import { fetchChargeSheetLineItem } from '~/store/duck/clientFinanceData'
import {
  calculateRefundAmount,
  getRefundInvoice,
  postRefundInvoice,
  voidRefundInvoice,
} from '~/store/duck/refunds'
import { useGetInvoiceV3Items } from '~/store/hooks/invoiceV3'
import { getInvoiceV3 } from '~/store/reducers/invoiceV3'
import { InvoiceLineItem, Order, RefundCandidate } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

import { isGroupedInvoiceItem } from '../../invoices/invoiceUtils'

export interface RefundTableRowValue {
  originalLineItemId: string
  refundQty: number
  refundReasonId: string
  restockQty: number
  restockedAsPackage: boolean
}

export interface RefundTableRowHandle {
  getValue: () => RefundTableRowValue
  setReason: (reason: string) => void
  validate: () => boolean
}

type RowRefs = Map<string, RefundTableRowHandle>

interface RefundContextProps {
  closeInfoPanel: () => void
  getRowRefs: () => RowRefs
  groups: Record<string, InvoiceLineItem[]>
  internalNoteField: Field
  invoice: InvoiceV3 | Nil
  isInfoPanelOpen: boolean
  itemsMarkedForRefund: Set<string>
  onMarkForRefund: (items: string[]) => void
  onRemoveFromRefund: (items: string[]) => void
  onResetRefundCandidate: () => void
  onSelectItem: (item: InvoiceLineItem | Order) => void
  onUpdateRefundCandidate: (item: RefundCandidate) => void
  refundCandidate?: RefundCandidate
  refundInvoice: RefundInvoice | Nil
  save: () => void
  selectedItem?: InvoiceLineItem
  tooltipInfo: string[]
  updateRefundAmounts: () => void
  validate: () => boolean
  viewState: InvoiceViewStates
  voidInvoice: () => void
}

interface RefundContextProviderProps {
  children: JSX.Element
  invoiceId: string | Nil
  viewState: InvoiceViewStates
}

const RefundContext = createContext({} as RefundContextProps)

const RefundContextProvider = ({
  invoiceId,
  viewState,
  children,
}: RefundContextProviderProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Invoices')

  const isRefundInvoice = viewState === InvoiceViewStates.REFUND_INVOICE
  const invoice = useSelector(
    getInvoiceV3(isRefundInvoice ? undefined : invoiceId),
  )
  const refundInvoice = useSelector(
    getRefundInvoice(isRefundInvoice ? invoiceId : undefined),
  )
  const groups = useGetInvoiceV3Items(
    isRefundInvoice ? undefined : (invoiceId ?? undefined),
  )

  const [selectedItem, setSelectedItem] = useState<InvoiceLineItem>()
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false)
  const [itemsMarkedForRefund, setItemsMarkedForRefund] = useState<Set<string>>(
    new Set(),
  )
  const [refundCandidate, setRefundCandidate] = useState<
    RefundCandidate | undefined
  >()
  const [tooltipInfo, setTooltipInfo] = useState<string[]>([])

  const rowRefs = useRef<RowRefs | null>(null)
  const getRowRefs = () => {
    if (rowRefs.current === null) {
      rowRefs.current = new Map()
    }

    return rowRefs.current
  }

  const { fields, validate: internalNoteFieldValidate } = useFields(
    [
      {
        name: 'internalNoteField',
        label: t('Invoices:REFUND_INTERNAL_NOTE'),
        type: 'text',
        validators: ['required'],
      },
    ],
    false,
  )

  const { internalNoteField } = fields

  const closeInfoPanel = useCallback(() => {
    setIsInfoPanelOpen(false)
    setSelectedItem(undefined)
    setRefundCandidate(undefined)
  }, [])

  const onSelectItem = useCallback(
    (item: InvoiceLineItem | Order) => {
      setIsInfoPanelOpen(true)
      setSelectedItem(item as InvoiceLineItem)
      if (item !== selectedItem && item?.id && !isGroupedInvoiceItem(item)) {
        dispatch(fetchChargeSheetLineItem({ id: item.id }))
      }
    },
    [selectedItem],
  )

  const onUpdateRefundCandidate = useCallback(
    (newValue: RefundCandidate) => {
      if (refundCandidate) {
        setRefundCandidate({
          ...refundCandidate,
          ...newValue,
        })
      } else {
        setRefundCandidate(newValue)
      }
    },
    [refundCandidate],
  )

  const onResetRefundCandidate = useCallback(() => {
    setRefundCandidate(undefined)
  }, [])

  const updateRefundAmountsInner = useCallback(() => {
    const input = {
      originalInvoiceId: invoiceId!,
      originalInvoiceModificationDate: invoice?.modificationDate,
      internalNote: 'note', // Placeholder as calculation call doesn't care about note field
      items: R.map(
        (refundItem: RefundTableRowValue) => ({
          ...refundItem,
          refundQty: refundItem.refundQty || 0,
          refundReasonId: '5lV0dV0p', // Use 'Other' as a placeholder for calculation
          restockQty: 0, // Placeholder for calculation
        }),
        R.filter(
          R.pipe(R.isEmpty, R.not),
          [...itemsMarkedForRefund.keys()].map((id) => ({
            ...getRowRefs().get(id)!.getValue(),
          })),
        ),
      ),
    }
    dispatch(calculateRefundAmount({ input }))
  }, [invoiceId, invoice?.modificationDate, itemsMarkedForRefund])

  const updateRefundAmounts = useDebouncedCallback(
    updateRefundAmountsInner,
    500,
  )

  const onMarkForRefund = useCallback(
    (items: string[]) => {
      const newItems = new Set([...itemsMarkedForRefund, ...items])
      setItemsMarkedForRefund(newItems)
      updateRefundAmounts()
    },
    [itemsMarkedForRefund],
  )

  const onRemoveFromRefund = useCallback(
    (items: string[]) => {
      const newItems = new Set(R.without(items, [...itemsMarkedForRefund]))
      setItemsMarkedForRefund(newItems)
      updateRefundAmounts()
    },
    [itemsMarkedForRefund],
  )

  const validate = useCallback(() => {
    const validationErrors: string[] = []
    const isAnyLineItemSelected = itemsMarkedForRefund.size > 0

    if (!isAnyLineItemSelected) {
      validationErrors.push(t('Invoices:REFUND_MIN_SELECTION_TOOLTIP'))
    }

    const internalNoteFieldValidated = internalNoteFieldValidate()
    if (!internalNoteFieldValidated) {
      validationErrors.push(t('Invoices:REFUND_INTERNAL_NOTE_TOOLTIP'))
    }

    // Avoid short circuit to ensure all fields are validated
    // eslint-disable-next-line ramda/no-redundant-and
    const isValid = R.and(
      R.all(
        Boolean,
        R.map(
          (item) => getRowRefs().get(item)?.validate(),
          Array.from(itemsMarkedForRefund),
        ),
      ),
      internalNoteFieldValidated,
    )

    if (!isValid && isAnyLineItemSelected) {
      validationErrors.push(t('Invoices:LINE_ITEM_VALIDATION_TOOLTIP'))
    }
    if (validationErrors.length > 0) {
      validationErrors.unshift(t('Invoices:CAN_NOT_PROCEED_TOOLTIP'))
    }
    setTooltipInfo(validationErrors)
    return isValid && isAnyLineItemSelected
  }, [itemsMarkedForRefund, internalNoteFieldValidate])

  const save = useCallback(() => {
    if (validate()) {
      const input = {
        originalInvoiceId: invoiceId!,
        originalInvoiceModificationDate: invoice?.modificationDate,
        internalNote: internalNoteField.value,
        items: R.map(
          (refundItem: RefundTableRowValue) => ({
            ...refundItem,
            restockQty: refundItem.restockQty || 0,
            refundQty: refundItem.refundQty || 0,
          }),
          R.filter(
            R.pipe(R.isEmpty, R.not),
            [...itemsMarkedForRefund.keys()].map((id) => ({
              ...getRowRefs().get(id)!.getValue(),
            })),
          ),
        ),
      }
      dispatch(postRefundInvoice({ input }))
    }
  }, [
    invoiceId,
    invoice?.modificationDate,
    itemsMarkedForRefund,
    internalNoteField,
  ])

  const voidInvoice = useCallback(() => {
    if (invoiceId) {
      dispatch(voidRefundInvoice({ id: invoiceId }))
    }
  }, [invoiceId])

  const contextValue = useMemo(
    () => ({
      closeInfoPanel,
      getRowRefs,
      groups,
      internalNoteField,
      invoice,
      isInfoPanelOpen,
      itemsMarkedForRefund,
      onMarkForRefund,
      onRemoveFromRefund,
      onResetRefundCandidate,
      onSelectItem,
      onUpdateRefundCandidate,
      refundCandidate,
      refundInvoice,
      save,
      selectedItem,
      tooltipInfo,
      updateRefundAmounts,
      validate,
      viewState,
      voidInvoice,
    }),
    [
      closeInfoPanel,
      getRowRefs,
      groups,
      internalNoteField,
      invoice,
      isInfoPanelOpen,
      itemsMarkedForRefund,
      onMarkForRefund,
      onRemoveFromRefund,
      onSelectItem,
      refundCandidate,
      refundInvoice,
      save,
      selectedItem,
      setRefundCandidate,
      tooltipInfo,
      updateRefundAmounts,
      validate,
      viewState,
      voidInvoice,
    ],
  )

  return (
    <RefundContext.Provider value={contextValue}>
      {children}
    </RefundContext.Provider>
  )
}

export { RefundContext, RefundContextProvider }
