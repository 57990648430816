import React from 'react'

import { Order } from '~/types'

import ItemWithBadges from '../ItemWithBadges'
import ProcedureActions from './actions/ProcedureActions'
import ProcedureButtonSection from './button-sections/ProcedureButtonSection'
import ProcedureContent from './content/ProcedureContent'

export interface ProcedureProps {
  data: Order
}

const Procedure = ({ data, ...rest }: ProcedureProps) => (
  <ItemWithBadges
    actions={<ProcedureActions order={data} {...rest} />}
    buttonSection={<ProcedureButtonSection order={data} {...rest} />}
    order={data}
  >
    <ProcedureContent order={data} />
  </ItemWithBadges>
)

export default Procedure
