import React, { useState } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, ClickAwayListener, Grow, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import SearchContext, { SearchLabel } from '~/constants/searchContext'
import { ContextAvailability } from '~/types'

import ToolbarContextSelectItem from './ToolbarContextSelectItem'

const useStyles = makeStyles(
  (theme) => ({
    expandIcon: {
      color: theme.colors.tabLabel,
      width: 20,
      height: 20,
    },
    toolbarSelectRoot: {
      width: '100%',
      position: 'relative',
    },
    toolbarSelectInput: {
      position: 'relative',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      paddingLeft: 14,
      height: 37,
      border: `1px solid ${theme.colors.searchPicker}`,
      borderRadius: '19px 0 0 19px',
      boxShadow: 'inset 1px 1px 5px 0 rgba(237,236,236,0.5)',
      backgroundColor: '#FFFFFF',
      borderRight: 'none',
    },
    toolbarSelectValue: {
      userSelect: 'none',
      color: theme.colors.searchPickerText,
    },
    toolbarSelectIcon: {
      height: 20,
      marginRight: theme.spacing(0.5),
    },
    toolbarSelectMenu: {
      top: 18,
      left: 1,
      right: 0,
      padding: theme.spacing(3, 1, 1, 1),
      paddingTop: 24,
      position: 'absolute',
      width: 166,
      boxShadow: theme.constants.blockShadow,
      borderTopRightRadius: 20,
    },
    active: {
      border: theme.constants.activeFieldBorder,
      borderRight: 'none',
    },
  }),
  { name: 'ToolbarContextSelect' },
)

export interface ToolbarContextSelectProps {
  contexts: Record<SearchContext, ContextAvailability>
  isActive: boolean
  label: React.ReactNode
  onChange: (value: SearchContext) => void
  onOpen: () => void
  value: string
}

const ToolbarContextSelect = ({
  contexts,
  label,
  value,
  isActive,
  onChange,
  onOpen,
  ...rest
}: ToolbarContextSelectProps) => {
  const classes = useStyles(rest)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={classes.toolbarSelectRoot}>
        <Box
          className={classNames(
            classes.toolbarSelectInput,
            isActive && classes.active,
          )}
          role="button"
          onClick={() => {
            if (!isOpen) {
              onOpen()
            }
            setIsOpen(!isOpen)
          }}
        >
          <Text
            strong
            className={classes.toolbarSelectValue}
            pt={0.5}
            variant="body4"
          >
            {label}
          </Text>
          <span className={classes.toolbarSelectIcon}>
            {isOpen ? (
              <ExpandLess className={classes.expandIcon} />
            ) : (
              <ExpandMore className={classes.expandIcon} />
            )}
          </span>
        </Box>
        {isOpen && (
          <Grow in={isOpen}>
            <Paper className={classes.toolbarSelectMenu}>
              {Object.keys(contexts).map((contextKey) => (
                <ToolbarContextSelectItem
                  availabilityRules={contexts[contextKey as SearchContext]}
                  context={contextKey}
                  key={contextKey}
                  label={SearchLabel[contextKey as SearchContext]}
                  value={value}
                  onChange={onChange}
                  onOpen={setIsOpen}
                  {...rest}
                />
              ))}
            </Paper>
          </Grow>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default ToolbarContextSelect
