import { EmailEntityConfig } from '~/types'

import { getAPIPath, request } from './core'

export const loadReportCardConfig = (businessId: string, soapId: string) =>
  request(
    getAPIPath(`soaps/${soapId}/report/config`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const loadReportCardEmailTemplate = (
  businessId: string,
  soapId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`soaps/${soapId}/report/email`),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )

export const loadReportCardPrintTemplate = (
  businessId: string,
  soapId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`soaps/${soapId}/report/print`),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )
