import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import ClientInfoLinkType from './ClientInfoLinkType'
import ClientSection from './ClientSection'
import PatientSection from './PatientSection'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 265,
      background: theme.colors.tableLeftColumnBackground,
      boxShadow: '4px 2px 10px -2px rgb(0, 0, 0, 0.1)',
    },
  }),
  { name: 'ClientAndPatientSection' },
)

interface ClientAndPatientSectionProps {
  clientId: string
  highlight?: ClientInfoLinkType
  patientId?: string
}

const ClientAndPatientSection = ({
  clientId,
  patientId,
  highlight,
}: ClientAndPatientSectionProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      aria-label="client-patient-section"
      className={classes.root}
    >
      <ClientSection
        clientId={clientId}
        highlight={highlight}
        patientId={patientId}
      />
      <PatientSection clientId={clientId} patientId={patientId} />
    </Grid>
  )
}

export default ClientAndPatientSection
