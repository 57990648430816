import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BlobWithName,
  moment,
  Nil,
  Patient,
  Text,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import { ChewyPet } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import { createPatientWithCallback } from '~/store/actions/patients'
import {
  getPetToMatchById,
  getUnmatchedPatientsIds,
} from '~/store/duck/chewyPets'
import useDialog from '~/utils/useDialog'

import { PatientMenuItem } from './PatientMenuItem'

const useStyles = makeStyles(
  () => ({
    select: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      textTransform: 'none',
      justifyContent: 'start',
    },
    divider: {
      margin: '0 !important',
    },
  }),
  { name: 'PatientSelect' },
)

interface PatientSelectProps {
  chewyPetId: string | Nil
  clientId: string
  defaultId: string | Nil
  id: string | Nil
  onSelect: (patientId: string) => void
}

export const PatientSelect = ({
  id,
  chewyPetId,
  defaultId,
  onSelect,
  clientId,
}: PatientSelectProps) => {
  const { t } = useTranslation('Dialogs')
  const classes = useStyles()
  const dispatch = useDispatch()
  const patientsIds = useSelector(getUnmatchedPatientsIds)
  const chewyPet = useSelector(
    getPetToMatchById(chewyPetId),
  ) as Partial<ChewyPet>
  const [openPatientDialog] = useDialog(DialogNames.PATIENT)

  const IconComponent = useCallback(
    () => (
      <InputAdornment position="end">
        <Toggle />
      </InputAdornment>
    ),
    [],
  )

  const createPatientAction = (
    client: string,
    patient: Omit<Patient, 'id'>,
    avatarBlob?: BlobWithName | Nil,
  ) => {
    dispatch(createPatientWithCallback(client, patient, onSelect, avatarBlob))
  }

  return (
    <FormControl fullWidth>
      <Select
        disableUnderline
        displayEmpty
        IconComponent={IconComponent}
        classes={{
          select: classes.select,
        }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Text strong variant="body2">
                {t('Dialogs:MATCH_PET_PATIENT_DIALOG.CHOOSE_A_PATIENT')}
              </Text>
            )
          }
          return <PatientMenuItem patientId={selected} />
        }}
        value={id || ''}
        onChange={(event) => {
          onSelect(event.target.value!)
        }}
      >
        <MenuItem key="no-match" value="">
          <Text py={1.5}>{t('Dialogs:MATCH_PET_PATIENT_DIALOG.NO_MATCH')}</Text>
        </MenuItem>
        <Divider className={classes.divider} />
        <Button
          fullWidth
          className={classes.button}
          onClick={() => {
            openPatientDialog({
              clientId,
              patient: {
                ...chewyPet,
                deceased: chewyPet.isDeceased,
                dateOfBirth: chewyPet.dateOfBirth
                  ? moment(chewyPet.dateOfBirth).toISOString()
                  : undefined,
              } as Partial<Patient>,
              createPatient: createPatientAction,
              saveAndAddAnother: false,
            })
          }}
        >
          <Text px={1} py={1.5}>
            {t(
              'Dialogs:MATCH_PET_PATIENT_DIALOG.ADD_CHEWY_PET_AS_PATIENT_IN_RHAPSODY',
            )}
          </Text>
        </Button>
        <Divider className={classes.divider} />
        {defaultId && (
          <MenuItem key={defaultId} value={defaultId}>
            <Box py={1.5}>
              <PatientMenuItem patientId={defaultId} />
            </Box>
          </MenuItem>
        )}
        {defaultId && <Divider className={classes.divider} />}
        <Text pt={1} px={2} variant="body4">
          {t('Dialogs:MATCH_PET_PATIENT_DIALOG.NO_MATCH_FOUND')}
        </Text>
        {patientsIds.map((patientId) => (
          <MenuItem key={patientId} value={patientId}>
            <PatientMenuItem patientId={patientId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
