import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AnyAction } from 'redux'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateUtils,
  moment,
  PuiDialog,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'

import { updateDischargeNotes, updateMedicalNotes } from '~/store/actions/soap'
import { getIsSaving, getNotesValidationError } from '~/store/reducers/soap'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import { BaseTemplateInputHandle } from '../../template-inputs/BaseTemplateInput/BaseTemplateInput'
import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    dialogContentRoot: {
      padding: theme.spacing(1, 2, 2),
    },
    radioGroup: {
      width: '100%',
    },
    label: {
      fontSize: '1.6rem',
    },
    button: {
      width: 150,
    },
  }),
  { name: 'SoapNotesValidationErrorDialog' },
)

const updateMethodMap: Record<
  string,
  (notes: string, forceUpdate?: boolean) => AnyAction
> = {
  medicalNotes: updateMedicalNotes,
  dischargeNotes: updateDischargeNotes,
}

enum EditorSelection {
  USE_OWN_NOTES = 'USE_OWN_NOTES',
  USE_INCOMING_NOTES = 'USE_INCOMING_NOTES',
}

export interface SoapNotesValidationErrorDialogProps
  extends BasePuiDialogProps {}

const SoapNotesValidationErrorDialog = ({
  open,
  onClose,
}: SoapNotesValidationErrorDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const validationError = useSelector(getNotesValidationError)
  const isSaving = useSelector(getIsSaving)

  const [editorSelection, setEditorSelection] = useState(
    EditorSelection.USE_OWN_NOTES,
  )

  const setCloseAfterCreationOn = useCloseAfterCreation(onClose, getIsSaving)

  const editorRefs = {
    [EditorSelection.USE_OWN_NOTES]: useRef<BaseTemplateInputHandle>(null),
    [EditorSelection.USE_INCOMING_NOTES]: useRef<BaseTemplateInputHandle>(null),
  }

  const selectedEditorRef = editorRefs[editorSelection]

  const proceed = () => {
    if (!validationError) {
      return
    }

    const value = selectedEditorRef.current?.getUnsavedData()

    if (value) {
      setCloseAfterCreationOn()
      dispatch(updateMethodMap[validationError.fieldName](value, true))
    } else if (editorSelection === EditorSelection.USE_OWN_NOTES) {
      setCloseAfterCreationOn()
      dispatch(
        updateMethodMap[validationError.fieldName](
          validationError.newText,
          true,
        ),
      )
    } else if (onClose) {
      onClose()
    }
  }

  if (!validationError) {
    return null
  }

  const editedByPersonDate = DateUtils.formatDateWithHours(
    validationError.lastEditedDate,
  )
  const editedByPersonFromNow = moment(validationError.lastEditedDate).fromNow()

  return (
    <PuiDialog
      hideCloseButton
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isSaving}
          loading={isSaving}
          onClick={proceed}
        >
          {t('Common:PROCEED')}
        </ButtonWithLoader>
      }
      aria-labelledby="soap-notes-validation-error-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        paper: classes.paper,
      }}
      header={
        <TextWithTooltip
          tooltipText={t(
            'Soap:WE_WILL_DETECT_CONFLICTING_EDITS_WHEN_ANOTHER_USER_EDITS_MEDICAL_DISCHARGE_NOTES',
          )}
          variant="h2"
        >
          {t('Soap:WARNING_WE_FOUND_CONFLICTING_EDITS')}
        </TextWithTooltip>
      }
      open={open}
    >
      <RadioGroup
        className={classes.radioGroup}
        name="chooseVersion"
        value={editorSelection}
        onChange={(event, value) =>
          setEditorSelection(value as EditorSelection)
        }
      >
        <Grid container item direction="column">
          <Grid container item direction="column">
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              control={<Radio />}
              label={t('Common:EDITED_BY_PERSON_ON_DATE', {
                person: Utils.getPersonString(validationError.lastAuthor),
                date: `${editedByPersonDate} (${editedByPersonFromNow})`,
              })}
              value={EditorSelection.USE_INCOMING_NOTES}
            />
            <NotesTemplateInput
              isSoap
              resetStateOnValueChange
              isLoading={isSaving}
              ref={editorRefs[EditorSelection.USE_INCOMING_NOTES]}
              value={validationError.lastText}
              onFocus={() =>
                setEditorSelection(EditorSelection.USE_INCOMING_NOTES)
              }
            />
          </Grid>
          <Grid container item direction="column">
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              control={<Radio />}
              label={t('Common:EDITED_BY_YOU')}
              value={EditorSelection.USE_OWN_NOTES}
            />
            <NotesTemplateInput
              isSoap
              resetStateOnValueChange
              isLoading={isSaving}
              ref={editorRefs[EditorSelection.USE_OWN_NOTES]}
              value={validationError.newText}
              onFocus={() => setEditorSelection(EditorSelection.USE_OWN_NOTES)}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </PuiDialog>
  )
}

export default SoapNotesValidationErrorDialog
