import React from 'react'
import { PuiPopperProps, User } from '@pbt/pbt-ui-components'

import {
  UserSelectPopper,
  UserSelectPopperProps,
} from '~/components/common/inputs/UserSelect'

interface UserSelectActionPopperProps
  extends Omit<UserSelectPopperProps, 'open' | 'onChange' | 'onClick'> {
  anchorEl: PuiPopperProps['anchorEl']
  onClick: (value: User) => void
}

const UserSelectActionPopper = ({
  anchorEl,
  onClick,
  ...props
}: UserSelectActionPopperProps) => (
  <UserSelectPopper
    PopperProps={{
      placement: 'auto',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: ['left', 'start'],
          },
        },
      ],
    }}
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onChange={onClick}
    {...props}
  />
)

export default UserSelectActionPopper
