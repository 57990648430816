import React from 'react'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import i18n from '~/locales/i18n'

import ExaminationSelect from './ExaminationSelect'

const getCategoriesString = (categories: string[] = []) => {
  if (categories?.length > 2) {
    const first = categories.slice(0, -2)
    const rest = categories.slice(-2)
    return [
      first.join(', '),
      rest.join(`, ${i18n.t('Common:AND').toLowerCase()} `),
    ].join(', ')
  }
  return categories?.join(`, ${i18n.t('Common:AND').toLowerCase()} `)
}

export interface SoapExaminationProps {
  editDisabled?: boolean
}

const SoapExamination = ({ editDisabled }: SoapExaminationProps) => {
  const [openConfirmCategoryAlert] = useConfirmAlert({
    type: ConfirmAlertType.EXAMINATION_CATEGORY,
  })
  const [openConfirmFindingAlert] = useConfirmAlert({
    type: ConfirmAlertType.EXAMINATION_FINDING,
  })

  const confirmCategoryDiscard = (
    categories: string[],
    discardCallback: (confirmed: boolean) => void,
  ) => {
    openConfirmCategoryAlert({
      onConfirm: discardCallback,
      message: i18n.t<string>(
        'Soap:SOAP_EXAMINATION.CATEGORY_DISCARD_MESSAGE',
        {
          categories: getCategoriesString(categories),
        },
      ),
    })
  }

  const confirmFindingDiscard = (
    findingName: string,
    discardCallback: (confirmed: boolean) => void,
  ) => {
    openConfirmFindingAlert({
      onConfirm: discardCallback,
      message: i18n.t<string>('Common:YOU_WILL_REMOVE_SOMETHING', {
        something: findingName,
      }),
    })
  }

  return (
    <ExaminationSelect
      confirmCategoryDiscard={confirmCategoryDiscard}
      confirmFindingDiscard={confirmFindingDiscard}
      editDisabled={editDisabled}
    />
  )
}

export default SoapExamination
