import { useSelector } from 'react-redux'

import { getSpaceType } from '~/store/reducers/constants'
import { getConstantsStringWithAll } from '~/utils'

export type SpaceTypeCellItem = {
  type: string
}

const SpaceTypeCell = (item: SpaceTypeCellItem) => {
  const SpaceTypes = useSelector(getSpaceType)

  return getConstantsStringWithAll(item.type, SpaceTypes)
}

export default SpaceTypeCell
