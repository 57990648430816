import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

const generateAllPossibleGridLineSteps = () => {
  const templates = [0.1, 0.2, 0.25, 0.5]
  const steps = [...templates]

  let templateIndex = 0
  let tens = 10

  while (true) {
    const nextNumber = templates[templateIndex] * tens
    if (nextNumber < Number.MAX_SAFE_INTEGER) {
      steps.push(nextNumber)
      templateIndex++
      if (templateIndex === templates.length) {
        templateIndex = 0
        tens *= 10
      }
    } else {
      break
    }
  }

  return steps
}

const getBaseGridLine = (min: number, candidate: number) =>
  min < candidate
    ? 0
    : min % candidate === 0
      ? min - candidate
      : Math.floor(min / candidate) * candidate

const getGridLinesStep = (values: number[], gridLinesCount: number) => {
  const min = R.reduce<number, number>(R.min, Number.MAX_SAFE_INTEGER, values)
  const max = R.reduce<number, number>(R.max, -Number.MAX_SAFE_INTEGER, values)
  const arr = generateAllPossibleGridLineSteps()

  const step =
    R.find((candidate) => {
      const base = getBaseGridLine(min, candidate)
      return base + (gridLinesCount - 1) * candidate >= max
    }, arr) || 0

  return [step, getBaseGridLine(min, step)]
}

const getLines = (base: number, step: number, gridLinesCount: number) =>
  new Array(gridLinesCount)
    .fill(undefined)
    .map((_, index) => Utils.round(index * step + base, 2) || 0)

export const getGridLines = (arr: number[], linesCount: number) => {
  const [step, base] = getGridLinesStep(arr, linesCount)
  return getLines(base, step, linesCount)
}
