import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Nil, Text } from '@pbt/pbt-ui-components'

import { getInventoryManufacturers } from '~/store/reducers/constants'
import { getManualInputSelectValue } from '~/utils'

export interface DispensedFromLabelProps {
  lotNumber: string | Nil
  manufacturerId: string | Nil
  manufacturerName: string | Nil
  serialNumber: string | Nil
}

const DispensedFromLabel = ({
  lotNumber,
  serialNumber,
  manufacturerId,
  manufacturerName,
}: DispensedFromLabelProps) => {
  const { t } = useTranslation('Common')

  const InventoryManufacturers = useSelector(getInventoryManufacturers)

  const text = [
    t('Common:DISPENSED_FROM_LOT', { lotNumber }),
    serialNumber && `${t('Common:SERIAL_NUMBER_SIGN')} ${serialNumber}`,
    manufacturerId &&
      getManualInputSelectValue(
        InventoryManufacturers,
        manufacturerId,
        manufacturerName,
      ),
  ]
    .filter(Boolean)
    .join(' | ')

  return <Text variant="lowAccent">{text}</Text>
}

export default DispensedFromLabel
