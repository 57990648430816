import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'

import FullGenderCell from './FullGenderCell'

export type FullGendersCellItem = {
  genderIds: {
    genderId: string
    spayedNeuteredId?: string
    spayedNeuteredStatusId: string
  }[]
}

const FullGendersCell = ({ genderIds: genderIdsProp }: FullGendersCellItem) => {
  const genderIds = genderIdsProp || {}
  const { t } = useTranslation('Common')

  return (
    <>
      {genderIds && !isEmpty(genderIds)
        ? genderIds.map((gender, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              <FullGenderCell {...gender} />
              <br />
            </React.Fragment>
          ))
        : t('Common:ALL')}
    </>
  )
}

export default FullGendersCell
