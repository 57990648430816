import React, { useMemo } from 'react'
import { AxisOptions, Chart } from 'react-charts'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import * as R from 'ramda'
import { DateFormat, DateUtils, Text } from '@pbt/pbt-ui-components'

import { LandingType, LandingWidgetName } from '~/constants/landingConstants'
import { getWidgetDataIsLoading, getWidgetMeta } from '~/store/duck/landing'

import { ScoreWidgetData } from './ScoreWidget'

const CustomerSatisfactionChart = () => {
  const { t } = useTranslation('Common')

  const data: ScoreWidgetData[] = useSelector(
    getWidgetMeta(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.CUSTOMER_SATISFACTION,
    ),
  )
  const loading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.CUSTOMER_SATISFACTION,
    ),
  )

  const getDateString = ({ weekStartDate, weekEndDate }: ScoreWidgetData) => {
    const formattedStartDate = DateUtils.formatDate(
      weekStartDate,
      true,
      DateFormat.SHORT_DATE_WITHOUT_YEAR,
    )
    const formattedEndDate = DateUtils.formatDate(
      weekEndDate,
      true,
      DateFormat.SHORT_DATE_WITHOUT_YEAR,
    )
    return `${formattedStartDate} - ${formattedEndDate}`
  }

  const primaryAxis = useMemo(
    (): AxisOptions<ScoreWidgetData> => ({
      getValue: (dataItem) => getDateString(dataItem),
      showGrid: false,
    }),
    [],
  )

  const secondaryAxes = useMemo(
    (): AxisOptions<ScoreWidgetData>[] => [
      {
        getValue: ({ score }: ScoreWidgetData) => score || 0,
      },
    ],
    [],
  )

  const chartData = useMemo(
    () => (!R.isEmpty(data) ? [{ data: R.reverse(data) }] : undefined),
    [data],
  )

  return (
    <Grid container item alignItems="center" justifyContent="center">
      {loading ? (
        <CircularProgress size={16} />
      ) : chartData ? (
        <Chart
          options={{
            data: chartData,
            primaryAxis,
            secondaryAxes,
            primaryCursor: false,
            secondaryCursor: false,
            tooltip: false,
          }}
        />
      ) : (
        <Text>{t('Common:NO_CHART_DATA')}</Text>
      )}
    </Grid>
  )
}

export default CustomerSatisfactionChart
