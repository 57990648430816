import { Defaults } from '@pbt/pbt-ui-components'

import { TimeEntity, UnsavedTimeEntity } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const checkTimeTrackingStatus = (businessId: string) =>
  request(
    getAPIPath('time-tracking/status'),
    { method: 'GET', params: { businessId } },
    true,
    {
      currentEntry: Schema.timeEntity,
    },
  )

export const clockIn = (
  businessId: string,
  notes: string,
  bypassValidation?: boolean,
) =>
  request(
    getAPIPath('time-tracking/clockin'),
    {
      method: 'POST',
      params: { businessId },
      data: { notes, bypassValidation },
    },
    true,
    Schema.timeEntity,
  )

export const clockOut = (
  businessId: string,
  id: string,
  reason: string,
  notes: string,
) =>
  request(
    getAPIPath('time-tracking/clockout'),
    {
      method: 'POST',
      params: { businessId },
      data: { clockoutReason: reason, notes, timeTrackingEntryId: id },
    },
    true,
    Schema.timeEntity,
  )

export const fetchUserTimeEntities = (
  businessId: string,
  personId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('time-tracking'),
    { method: 'GET', params: { businessId, personId, from, to } },
    true,
    Schema.timeEntitiesList,
  )

export const deleteTimeEntity = (businessId: string, id: string) =>
  request(
    getAPIPath(`time-tracking/${id}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const updateTimeEntity = (businessId: string, entity: TimeEntity) =>
  request(
    getAPIPath(`time-tracking/${entity.id}`),
    { method: 'PUT', params: { businessId }, data: entity },
    true,
    Schema.timeEntity,
  )

export const createTimeEntity = (
  businessId: string,
  personId: string,
  entity: UnsavedTimeEntity,
) =>
  request(
    getAPIPath('time-tracking'),
    {
      method: 'POST',
      params: { businessId },
      data: {
        ...entity,
        person: personId,
        business: businessId,
      },
    },
    true,
    Schema.timeEntity,
  )
