import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import i18n from '~/locales/i18n'

const entityNameMap = {
  [BulkPriceEntityTypes.INVENTORY]: i18n.t('Common:INVENTORY'),
  [BulkPriceEntityTypes.PROCEDURE]: i18n.t('Common:PROCEDURE'),
  [BulkPriceEntityTypes.LAB_TEST]: i18n.t('Common:LAB_TEST'),
}

const useStyles = makeStyles(
  (theme) => ({
    border: {
      borderRight: theme.constants.tableBorder,
    },
    tableHead: {
      backgroundColor: theme.colors.tableBackground,
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'BulkPricesReviewListHeder' },
)

interface BulkPricesReviewListHederProps {
  entityType: BulkPriceEntityTypes
}

const BulkPricesReviewListHeader = ({
  entityType,
}: BulkPricesReviewListHederProps) => {
  const { t } = useTranslation(['Common', 'Prices'])
  const classes = useStyles()
  return (
    <Grid container item className={classes.tableHead} wrap="nowrap">
      <Grid item className={classes.border} px={2} py={1} xs={4}>
        <Text strong variant="lowAccent2">
          {entityNameMap[entityType]}
        </Text>
      </Grid>
      <Grid item px={2} py={1} xs={2}>
        <Text strong variant="lowAccent2">
          {t('Common:PRICE_NAME')}
        </Text>
      </Grid>
      <Grid item px={2} py={1} xs={2}>
        <Text strong variant="lowAccent2">
          {t('Prices:NEW_PRICE')}
        </Text>
      </Grid>
      <Grid item px={2} py={1} xs={2}>
        <Text strong variant="lowAccent2">
          {t('Prices:CURRENT_PRICE')}
        </Text>
      </Grid>
      <Grid item px={2} py={1} xs={2}>
        <Text strong variant="lowAccent2">
          {t('Common:DIFFERENCE')}
        </Text>
      </Grid>
    </Grid>
  )
}

export default BulkPricesReviewListHeader
