import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Nil, useFields, User, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { fetchClient } from '~/store/actions/clients'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getContactMethod, getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { ContactMethodName } from '~/types'
import { isFieldValuesChanged } from '~/utils'

export const useShouldDisplaySmsConsent = () => {
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSmsConsentToggleEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SMS_CONSENT_TOGGLE),
  )

  const shouldDisplaySmsConsent =
    Boolean(isCurrentBusinessOmniChannel) && isSmsConsentToggleEnabled

  return shouldDisplaySmsConsent
}

export const useCanSendSmsToClient = (
  clientId: string | Nil,
  fieldValue?: boolean,
): boolean => {
  const dispatch = useDispatch()

  const client = useSelector(getUser(clientId))

  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  useEffect(() => {
    if (clientId && shouldDisplaySmsConsent) {
      dispatch(fetchClient({ clientId, withGlobalClientPreferences: true }))
    }
  }, [clientId, shouldDisplaySmsConsent])

  if (!shouldDisplaySmsConsent) {
    return true
  }

  return (
    fieldValue ??
    client?.globalClientPreferences?.communicationSmsOptIn ??
    false
  )
}

/**
 * @param clientId
 * @param fieldValue Used when a user is able to switch local state of the SMS consent state, which should enabled/disabled the "Text message" select option
 */
export const useGetContactMethodWithDisabledFlag = (
  clientId: string | Nil,
  fieldValue?: boolean,
) => {
  const { t } = useTranslation('Clients')

  const ContactMethod = useSelector(getContactMethod)

  const canSendSmsToClient = useCanSendSmsToClient(clientId, fieldValue)

  const ContactMethodWithDisabledFlag = ContactMethod.map((method) => {
    const isTextMessageOptionDisabled =
      method.name === ContactMethodName.TEXT_MESSAGE && !canSendSmsToClient

    return {
      ...method,
      nameTranslation: isTextMessageOptionDisabled
        ? `${method.nameTranslation}\n${t('Clients:SMS_CONSENT_DISABLED')}`
        : method.nameTranslation,
      disabled: isTextMessageOptionDisabled,
    }
  })

  return { canSendSmsToClient, ContactMethodWithDisabledFlag }
}

export const useGetIsTextMessage = (methodId: string | Nil) => {
  const ContactMethod = useSelector(getContactMethod)
  const textMessageId = Utils.findByName(
    ContactMethodName.TEXT_MESSAGE,
    ContactMethod,
  )?.id

  return methodId === textMessageId
}

export const useSmsConsentField = (client: User | undefined) => {
  const { t } = useTranslation('Clients')

  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  const { fields, reset } = useFields(
    [
      {
        name: 'communicationSmsOptIn',
        label: t('Clients:NEW_CLIENT_DETAILS_EDIT.SWITCH_TEXT.SMS_CONSENT'),
        type: 'toggle',
        initialValue:
          client?.globalClientPreferences?.communicationSmsOptIn ?? null,
      },
    ],
    false,
  )

  useEffect(() => {
    if (client?.id) {
      reset()
    }
  }, [client?.id, client?.globalClientPreferences?.communicationSmsOptIn])

  const hasChanged = isFieldValuesChanged(fields)
  const { communicationSmsOptIn: communicationSmsOptInField } = fields

  return shouldDisplaySmsConsent
    ? { communicationSmsOptInField, hasChanged }
    : {}
}
