import * as R from 'ramda'
import { DateUtils, LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { parse } from '~/utils'
import { toInteger } from '~/utils/number'

export const getPluralPackageLabel = (packageId, PackageType) => {
  const packageName = Utils.getConstantName(packageId, PackageType)
  const packageDisplayName = LanguageUtils.getConstantTranslatedName(
    packageId,
    PackageType,
  )
  return packageName !== 'Each'
    ? `${packageDisplayName}(s)`
    : packageDisplayName
}

// NOTE: in order to have unique id:
// singular -> id_1
// plural -> id_X
export const getPriceUnitsIdFromValue = (value) =>
  value && value.includes('_') ? value.split('_')[0] : value
export const getPriceUnitsValuedFromId = (id, isPlural) =>
  `${id}_${isPlural ? 'X' : '1'}`
export const isPluralUnit = (value) => value && value.split('_')[1] === 'X'
export const isPackagePluralUnit = (InventoryPriceUnit, value) => {
  const id = Utils.findConstantIdByName('Per X packages', InventoryPriceUnit)
  return value === getPriceUnitsValuedFromId(id, true)
}

export const getVariationKey = (variationId = '', index = 0) =>
  JSON.stringify({ variationId, index })
export const parseVariationKey = (key) => parse(key)

export const getVariation = (variationKey, variations) => {
  const parsedKey = parseVariationKey(variationKey)
  if (!parsedKey) {
    return undefined
  }
  const { variationId, index } = parsedKey
  return R.path([variationId, index])(variations)
}

export const toggleSelectedVariationsState = (
  variations,
  variation,
  variationName,
  isFoodCatalogEnabled,
) => {
  const alreadyExist = Boolean(variations[variation.id])

  return alreadyExist
    ? R.omit([variation.id], variations)
    : {
        ...variations,
        [variation.id]: [
          {
            ...R.omit(['id'], variation),
            [isFoodCatalogEnabled
              ? 'globalInventoryVariationMappingId'
              : 'globalVariationId']: variation.id,
            name: variation.name || variationName,
          },
        ],
      }
}

export const toggleSelectedCustomCompoundState = (variations, variation) => {
  const alreadyExist = Boolean(variations[variation.id])
  return alreadyExist
    ? R.omit([variation.id], variations)
    : {
        ...variations,
        [variation.id]: [{ name: i18n.t('Common:CUSTOM_DRUG_COMPOUND') }],
      }
}

export const getVariationIndex = (variationKey, variations) => {
  const parsedKey = parseVariationKey(variationKey)
  if (!parsedKey) {
    return undefined
  }
  const { variationId, index: variationIndex } = parsedKey
  const { index, found } = Object.keys(variations).reduce(
    ({ index, found }, id) =>
      !found
        ? id === variationId
          ? { index: index + variationIndex, found: true }
          : { index: index + variations[id].length, found: false }
        : { index, found },
    { index: 0, found: false },
  )
  return found ? index : undefined
}

export const getNextVariationKey = (variationKey, variations) => {
  const parsedKey = parseVariationKey(variationKey)
  if (!parsedKey?.variationId) {
    return undefined
  }
  const { variationId, index } = parsedKey
  const selectedVariationIds = Object.keys(variations)
  if (variations[variationId].length > index + 1) {
    return getVariationKey(variationId, index + 1)
  }

  const currentIdIndex = selectedVariationIds.indexOf(variationId)

  return currentIdIndex < selectedVariationIds.length - 1
    ? getVariationKey(selectedVariationIds[currentIdIndex + 1], 0)
    : undefined
}

export const getPrevVariationKey = (variationKey, variations) => {
  const { variationId, index } = parseVariationKey(variationKey)
  const selectedVariationIds = Object.keys(variations)

  if (index > 0) {
    return getVariationKey(variationId, index - 1)
  }

  const curentIdIndex = selectedVariationIds.indexOf(variationId)
  return curentIdIndex > 0
    ? getVariationKey(
        selectedVariationIds[curentIdIndex - 1],
        variations[selectedVariationIds[curentIdIndex - 1]].length - 1,
      )
    : undefined
}

export const insertVariation = (
  variation,
  variations,
  isFoodCatalogEnabled,
) => {
  const id =
    variation.id ||
    variation[
      isFoodCatalogEnabled
        ? 'globalInventoryVariationMappingId'
        : 'globalVariationId'
    ]
  return {
    ...variations,
    [id]: [...(variations[id] || []), variation],
  }
}

export const getVariationsCount = (variations = {}) =>
  Object.values(variations).reduce((acc, arr) => acc + arr.length, 0)

export const addOrUpdateVariation = (variationKey, variation, variations) => {
  const { variationId, index: variationIndex } = parseVariationKey(variationKey)
  const variationsWithSameId = variations[variationId] || []
  return {
    ...variations,
    [variationId]:
      variationIndex >= variationsWithSameId.length
        ? [...variationsWithSameId, variation]
        : variationsWithSameId.map((item, index) =>
            index === variationIndex ? variation : item,
          ),
  }
}

export const getCommonPackagingLabel =
  (InventoryProductSizeUnit, PackageType) => (item) => {
    if (!item) {
      return ''
    }

    const {
      perPackageUnitsId,
      packageTypeId,
      perPackageAmount,
      count,
      saleUnitOfMeasure,
    } = item || {}

    const perPackageUnits = LanguageUtils.getConstantTranslatedName(
      perPackageUnitsId,
      InventoryProductSizeUnit,
    )
    const packageType = LanguageUtils.getConstantTranslatedName(
      packageTypeId,
      PackageType,
    )

    let initialLabel = `${perPackageAmount} ${perPackageUnits}`

    if (saleUnitOfMeasure) {
      initialLabel += ` ${saleUnitOfMeasure}`
    }

    if (count) {
      initialLabel += ` | ${count}`
    }

    if (packageType) {
      initialLabel += `/${packageType}`
    }

    return initialLabel
  }

export const getOnHandAmount =
  (InventoryProductSizeUnit, PackageType) =>
  ({
    perPackageUnitsId,
    packageTypeId,
    perPackageAmount,
    onHandAmount,
  } = {}) => {
    const perPackageUnits = LanguageUtils.getConstantTranslatedName(
      perPackageUnitsId,
      InventoryProductSizeUnit,
    )
    const packageType = LanguageUtils.getConstantTranslatedName(
      packageTypeId,
      PackageType,
    )
    const packageAmount = perPackageAmount
      ? toInteger(((onHandAmount || 0) / perPackageAmount).toFixed(2))
      : 0

    return `${packageAmount} ${packageType} (${
      onHandAmount || 0
    } ${perPackageUnits})`
  }

export const getUnitCoefficient = (
  InventoryInStockUnit,
  unitId,
  perPackageAmount,
) => {
  const packagesUnitId = Utils.findConstantIdByName(
    'Package',
    InventoryInStockUnit,
  )

  return unitId === packagesUnitId ? perPackageAmount : 1
}

export const getMinimalAdjustmentAmountByOnHandAmount = (
  InventoryInStockUnit,
  variation,
  initialAmount,
  initialUnitId,
  directedUnitId,
) => {
  const { perPackageAmount, onHandAmount = 0 } = variation || {}

  const initialCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    initialUnitId,
    perPackageAmount,
  )
  const directedCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    directedUnitId,
    perPackageAmount,
  )

  const initialUnits = initialAmount * initialCoefficient

  const minimalUnits =
    onHandAmount < 0 ? initialUnits : initialUnits - onHandAmount

  const isPackageDirected = directedCoefficient === perPackageAmount

  const preparedUnits = minimalUnits / directedCoefficient

  return isPackageDirected
    ? toInteger(preparedUnits)
    : Utils.round(preparedUnits, 4)
}

export const getDirectAdjustmentDelta = (
  InventoryInStockUnit,
  variation,
  initialAmount,
  initialUnitId,
  directedAmount,
  directedUnitId,
  // eslint-disable-next-line max-params
) => {
  const { perPackageAmount, onHandAmount } = variation || {}

  const initialCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    initialUnitId,
    perPackageAmount,
  )
  const directedCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    directedUnitId,
    perPackageAmount,
  )

  const initialUnits = initialAmount * initialCoefficient
  const directedUnits = directedAmount * directedCoefficient

  return Utils.round(
    (directedUnits - (onHandAmount - initialUnits)) / directedCoefficient,
    4,
  )
}

export const getVariationWithAddedOnHandAmount = (
  InventoryInStockUnit,
  variation,
  initialAmount,
  initialUnitId,
  addAmount,
  addUnitId,
  // eslint-disable-next-line max-params
) => {
  const { perPackageAmount, onHandAmount } = variation || {}

  const initialCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    initialUnitId,
    perPackageAmount,
  )
  const addCoefficient = getUnitCoefficient(
    InventoryInStockUnit,
    addUnitId,
    perPackageAmount,
  )

  const relativeAdjustment =
    addAmount * addCoefficient - initialAmount * initialCoefficient

  return {
    ...variation,
    onHandAmount: Utils.round(onHandAmount + relativeAdjustment, 4),
  }
}

export const getReceivedDate = R.pipe(
  R.prop('receivedDate'),
  DateUtils.formatDate,
)
export const getCreationDate = R.pipe(
  R.prop('creationDate'),
  DateUtils.formatDate,
)

export const pluckStorageLocation = R.pipe(
  R.pluck('storageLocationId'),
  R.filter(Boolean),
  R.uniq,
)
