import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import {
  fetchMoreReminders,
  fetchReminders,
  getMultipleRemindersGroups,
  getRemindersGroupsList,
  getTotalCount,
} from '~/store/duck/reminders'
import { getEventType } from '~/store/reducers/constants'

import PatientRemindersList from './PatientRemindersList'

export interface UpcomingAndOverdueRemindersProps {
  patientId: string
}

const UpcomingAndOverdueReminders = ({
  patientId,
}: UpcomingAndOverdueRemindersProps) => {
  const dispatch = useDispatch()
  const EventType = useSelector(getEventType)
  const groupsList = useSelector(getRemindersGroupsList)
  const totalCount = useSelector(getTotalCount)
  const groups = useSelector(getMultipleRemindersGroups(groupsList))
  const remindersList = R.pipe(R.pluck('reminders'), R.flatten)(groups)

  const ReminderEvent = Utils.findConstantByName('Reminder', EventType) || {}
  const ReminderStates = ReminderEvent.states || []

  const OpenStateId = Utils.findConstantIdByName('Open', ReminderStates)
  const OverdueStateId = Utils.findConstantIdByName('Overdue', ReminderStates)

  useEffect(() => {
    if (patientId) {
      dispatch(
        fetchReminders({
          patientId,
          stateIds: [OpenStateId, OverdueStateId],
        }),
      )
    }
  }, [patientId])

  const loadMoreItems = (from: number, to: number) => {
    dispatch(
      fetchMoreReminders({
        patientId,
        stateIds: [OpenStateId, OverdueStateId],
        from,
        to,
      }),
    )
  }

  return (
    <PatientRemindersList
      list={remindersList}
      loadMoreItems={loadMoreItems}
      patientId={patientId}
      totalCount={totalCount}
    />
  )
}

export default UpcomingAndOverdueReminders
