import { AtLeast, Defaults, Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import {
  CRUDOrderOptions,
  EmailEntityConfig,
  Order,
  OrderForCreate,
  OrderListType,
} from '~/types'

import { getAPIPath, request } from './core'

export const createOrder = (
  businessId: string,
  soapId: string,
  type: string,
  order: Order,
  print: boolean | Nil,
  email?: boolean,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order`),
    { method: 'POST', data: order, params: { businessId, type, print, email } },
    true,
  )

export const createOrderOutsideSoap = (
  businessId: string,
  type: string,
  order: Order,
  print: boolean | Nil,
  email?: boolean,
) =>
  request(
    getAPIPath('prescription'),
    { method: 'POST', data: order, params: { businessId, type, print, email } },
    true,
  )

export const editOrders = (
  businessId: string,
  soapId: string,
  orders: Order[],
) =>
  request(
    getAPIPath(`soaps/${soapId}/orders`),
    { method: 'PATCH', data: orders, params: { businessId } },
    true,
  )

export const editOrdersStatuses = (
  businessId: string,
  soapId: string,
  orders: Partial<Order>[],
) =>
  request(
    getAPIPath(`soaps/${soapId}/order_statuses`),
    { method: 'PATCH', data: orders, params: { businessId } },
    true,
  )

export const editOrder = (
  businessId: string,
  soapId: string,
  type: string,
  order: Order,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order`),
    {
      method: 'PUT',
      data: order,
      params: { businessId, type, logEntryId: order.id },
    },
    true,
  )

export const partialEditOrder = (
  businessId: string,
  soapId: string,
  type: OrderType | Nil,
  order: AtLeast<Order, 'id'>,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order`),
    {
      method: 'PATCH',
      data: order,
      params: { businessId, type, logEntryId: order.id },
    },
    true,
  )

export const editOrderOutsideSoap = (
  businessId: string,
  type: OrderType | Nil,
  order: AtLeast<Order, 'id'>,
) =>
  request(
    getAPIPath(`prescription/${order.id}`),
    { method: 'PATCH', data: order, params: { businessId, type } },
    true,
  )

export const updateOrderStatus = (
  businessId: string,
  logEntryId: string,
  stateId: string,
) =>
  request(
    getAPIPath(`labtest/log/${logEntryId}`),
    { method: 'PUT', params: { businessId, statusId: stateId } },
    true,
  )

export const updateOrderStatusDecoupling = (
  businessId: string,
  logEntryId: string,
  stateId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/orders/LAB_TEST/${logEntryId}`),
    { method: 'PATCH', data: { stateId }, params: { businessId } },
    true,
  )

export const removeOrders = (
  businessId: string,
  soapId: string,
  orders: Order[],
) =>
  request(
    getAPIPath(`soaps/${soapId}/orders`),
    { method: 'DELETE', data: orders, params: { businessId } },
    true,
  )

export const removeOrder = (
  businessId: string,
  soapId: string | Nil,
  type: string,
  id: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order`),
    { method: 'DELETE', params: { businessId, type, logEntryId: id } },
    true,
  )

export const removeOrderDecoupling = (
  businessId: string,
  soapId: string | Nil,
  type: string,
  id: string,
) =>
  request(
    getAPIPath(`soaps/orders/${type}/${id}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const removeOrderUpdatePatient = (
  businessId: string,
  soapId: string | Nil,
  type: string,
  id: string,
) =>
  request(
    getAPIPath('soaps/order_automate'),
    { method: 'DELETE', params: { businessId, type, logEntryId: id } },
    true,
  )

export const removeOrderOutsideSoap = (
  businessId: string,
  type: string,
  id: string,
) =>
  request(
    getAPIPath(`prescription/${id}`),
    { method: 'DELETE', params: { businessId, type } },
    true,
  )

export const loadOrderEmailTemplate = (
  businessId: string,
  soapId: string,
  orderId: string,
  type: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order/${orderId}/email`),
    { method: 'POST', data: config, params: { businessId, type } },
    true,
  )

export const loadOrderEmailTemplateOutsideSoap = (
  businessId: string,
  id: string,
  type: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`prescription/${id}/email`),
    { method: 'POST', data: config, params: { businessId, type } },
    true,
  )

export const signPrescription = (
  businessId: string,
  soapId: string,
  orderId: string,
  signerId: string,
  signature: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order/sign`),
    {
      method: 'PUT',
      data: {
        signerId,
        signature,
      },
      params: { businessId, orderId, type: OrderType.PRESCRIPTION },
    },
    true,
  )

export const signPrescriptionOutsideSoap = (
  businessId: string,
  id: string,
  signerId: string,
  signature: string,
) =>
  request(
    getAPIPath(`prescription/${id}/sign`),
    {
      method: 'PUT',
      data: {
        signerId,
        signature,
      },
      params: { businessId },
    },
    true,
  )

export const cancelRefills = (businessId: string, prescriptionId: string) =>
  request(
    getAPIPath(`prescription/${prescriptionId}/refill`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const searchOrders = (
  businessId: string,
  query: string | Nil,
  categories: string,
  filter: string,
  patientId: string,
  clientId: string,
  eventId: string | Nil,
  withTasks: boolean | Nil,
  labVendorId: string | Nil,
  applyPatientRestriction: boolean | Nil,
  forShipments: boolean | Nil,
  countryCode: string,
  from: number | Nil = 0,
  to: number | Nil = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isGlobalMappingSearch?: boolean,
) =>
  request(
    getAPIPath('orders/search'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        categories,
        filter,
        patientId,
        clientId,
        eventId,
        withTasks,
        labVendorId,
        applyPatientRestriction,
        forShipments,
        countryCode,
        from,
        to,
        isGlobalMappingSearch,
      },
    },
    true,
  )

export const getOrders = (
  businessId: string,
  categories: string[] | Nil,
  subcategories: string[] | Nil,
  type: string | Nil,
  listType: OrderListType,
  patientId: string,
  clientId: string,
  eventId: string | Nil,
  withTasks: boolean | Nil,
  forShipments: boolean | Nil,
  labVendorId: string | Nil,
  applyPatientRestriction: boolean | Nil,
  countryCode: string,
  from: number | Nil = 0,
  to: number | Nil = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('orders/page'),
    {
      method: 'GET',
      params: {
        businessId,
        categories: categories?.join(','),
        subcategories: subcategories?.join(','),
        type,
        filter: listType,
        patientId,
        clientId,
        eventId,
        withTasks,
        forShipments,
        labVendorId,
        applyPatientRestriction,
        countryCode,
        from,
        to,
      },
    },
    true,
  )

export const getOrdersV2 = (
  businessId: string,
  categories: string[] | Nil,
  subcategories: string[] | Nil,
  type: string | Nil,
  listType: OrderListType,
  patientId: string,
  clientId: string,
  eventId: string | Nil,
  withTasks: boolean | Nil,
  forShipments: boolean | Nil,
  labVendorId: string | Nil,
  applyPatientRestriction: boolean | Nil,
  countryCode: string,
  from: number | Nil = 0,
  to: number | Nil = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('v2/orders/page'),
    {
      method: 'GET',
      params: {
        businessId,
        categories: categories?.join(','),
        subcategories: subcategories?.join(','),
        type,
        filter: listType,
        patientId,
        clientId,
        eventId,
        withTasks,
        forShipments,
        labVendorId,
        applyPatientRestriction,
        countryCode,
        from,
        to,
      },
    },
    true,
  )

export const getOrdersV3 = (
  businessId: string,
  categories: string[] | Nil,
  subcategories: string[] | Nil,
  type: string | Nil,
  listType: OrderListType,
  patientId: string,
  clientId: string,
  eventId: string | Nil,
  withTasks: boolean | Nil,
  forShipments: boolean | Nil,
  labVendorId: string | Nil,
  applyPatientRestriction: boolean | Nil,
  countryCode: string,
  from: number | Nil = 0,
  to: number | Nil = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('v3/orders/page'),
    {
      method: 'GET',
      params: {
        businessId,
        categories: categories?.join(','),
        subcategories: subcategories?.join(','),
        type,
        filter: listType,
        patientId,
        clientId,
        eventId,
        withTasks,
        forShipments,
        labVendorId,
        applyPatientRestriction,
        countryCode,
        from,
        to,
      },
    },
    true,
  )

/**
 * Deprecated
 */
export const getOrderDetails = (
  businessId: string,
  businessItemId: string | Nil,
  globalItemId: string | Nil,
) =>
  request(
    getAPIPath('orders/inventory'),
    { method: 'GET', params: { businessId, businessItemId, globalItemId } },
    true,
  )

export const fetchOrderDetailsGrouped = (
  businessId: string,
  businessItemId: string | Nil,
  globalItemId: string | Nil,
  matchChewyItems?: boolean,
) =>
  request(
    getAPIPath('orders/inventory/grouped'),
    {
      method: 'GET',
      params: { businessId, businessItemId, globalItemId, matchChewyItems },
    },
    true,
  )

export const fetchOrderDetailsGroupedV2 = (
  businessId: string,
  businessItemId: string | Nil,
  globalInventoryMappingId: string | Nil,
  matchChewyItems?: boolean,
) =>
  request(
    getAPIPath('v2/orders/inventory/grouped'),
    {
      method: 'GET',
      params: {
        businessId,
        businessItemId,
        globalInventoryMappingId,
        matchChewyItems,
      },
    },
    true,
  )

export const getOrdersFilters = (businessId: string) =>
  request(
    getAPIPath('orders/filter'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const fetchPrintInfo = (businessId: string, id: string) =>
  request(
    getAPIPath('orders/prescription'),
    { method: 'GET', params: { businessId, prescriptionId: id, print: true } },
    true,
  )

export const createUnifiedOrder = (
  businessId: string,
  order: OrderForCreate,
  { type, print, email }: CRUDOrderOptions,
) =>
  request(
    getAPIPath(`unifiedInventory/${order.soapId ? '' : 'otc/lineItemLog'}`),
    { method: 'POST', data: order, params: { businessId, type, print, email } },
    true,
  )

export const updateUnifiedOrder = (
  businessId: string,
  order: Partial<Order>,
  {
    type,
    previousEntityType,
    print,
    email,
    allowEditPostedItem,
  }: CRUDOrderOptions,
) =>
  request(
    getAPIPath(
      `unifiedInventory/${order.soapId ? '' : 'otc/lineItemLog/'}${order.id}`,
    ),
    {
      method: 'PUT',
      data: order,
      params: {
        businessId,
        type,
        previousEntityType,
        print,
        email,
        allowEditPostedItem,
      },
    },
    true,
  )

export const removeUnifiedOrder = (
  businessId: string,
  order: Order,
  type: string,
) =>
  request(
    getAPIPath(
      `unifiedInventory/${order.soapId ? '' : 'otc/lineItemLog/'}${order.id}`,
    ),
    { method: 'DELETE', params: { businessId, type } },
    true,
  )

export const fetchUnifiedOrder = (
  businessId: string,
  id: string,
  type: string | Nil,
) =>
  request(
    getAPIPath(`unifiedInventory/otc/lineItemLog/${id}`),
    { method: 'GET', params: { businessId, type } },
    true,
  )

export const fetchUnifiedOrderByEntityType = (
  businessId: string,
  entityId: string,
  entityType: OrderType,
  withTasks: boolean = false,
) =>
  request(
    getAPIPath('orders/item'),
    { method: 'GET', params: { businessId, entityId, entityType, withTasks } },
    true,
  )

export const orderBundle = (
  businessId: string,
  bundleId: string | Nil,
  soapId: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order/bundle`),
    { method: 'POST', params: { businessId, bundleId } },
    true,
  )

export const unorderBundle = (
  businessId: string,
  bundleId: string,
  soapId: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/order/bundle`),
    { method: 'DELETE', params: { businessId, bundleId } },
    true,
  )

export const fetchOrderCountsByType = (
  businessId: string,
  clientId: string,
  patientId: string,
  eventId: string,
  applyPatientRestriction: boolean,
  types: string,
) =>
  request(
    getAPIPath('orders/counts'),
    {
      method: 'GET',
      params: {
        businessId,
        clientId,
        patientId,
        eventId,
        applyPatientRestriction,
        types,
      },
    },
    true,
  )

export const fetchUnifiedOrderByTypeAndLogId = (
  businessId: string,
  logId: string,
  type: string,
) => request(getAPIPath(`orders/log/${type}/${logId}?businessId=${businessId}`))
