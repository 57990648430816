import { gql } from '@apollo/client/core'

export const FETCH_CLIENT_BULLING_ACTIVITY = gql`
  query ClientBillingActivity($clientId: ID!) {
    client(id: $clientId) {
      id
      billingActivitySummary {
        balance
        deposits {
          amount
          count
        }
        unappliedPayments {
          amount
          count
        }
        pendingRefunds {
          amount
          count
        }
        unpaidInvoices {
          amount
          count
        }
      }
    }
  }
`
