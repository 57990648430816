import React from 'react'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { WhiteboardRowHeaderNames } from '~/constants/whiteboardColumns'
import { WhiteboardSchedule, WhiteboardSlot } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      height: '100%',
      padding: theme.spacing(0.75, 2),
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'WhiteboardTypeViewRowHeader' },
)

export interface WhiteboardTypeViewRowHeaderProps {
  className?: string
  column: Omit<WhiteboardSchedule, 'slots'> & {
    slots: WhiteboardSlot[]
  }
}

const WhiteboardTypeViewRowHeader = ({
  className,
  column: { type, slots = [] },
}: WhiteboardTypeViewRowHeaderProps) => {
  const classes = useStyles()

  const isEmpty = slots.length === 0

  return (
    <Paper className={classNames(className, classes.paper)} elevation={0}>
      <Text strong variant={isEmpty ? 'lowAccent2' : 'h4'}>
        {LanguageUtils.getReadableEnumName(
          WhiteboardRowHeaderNames[type] || type,
        )}
      </Text>
    </Paper>
  )
}

export default WhiteboardTypeViewRowHeader
