import { Task } from '@redux-saga/types'
import * as R from 'ramda'
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { ApiError, Constant, Nil, Utils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getReminderGroupName } from '~/components/dashboard/reminders/reminderUtils'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { WsAction } from '~/constants/websocket'
import { Reminder, ReminderGroup } from '~/types'

import { fetchTimeline } from '../actions/timeline'
import { fetchWidgetsData } from '../duck/landing'
import { finishLoading, startLoading } from '../duck/progress'
import {
  clearRemountWebsocketRemindersWidget,
  CREATE_REMINDER,
  createReminder,
  createReminderFailure,
  createReminderSuccess,
  DELETE_REMINDER,
  deleteReminder,
  deleteReminderFailure,
  deleteReminderSuccess,
  EDIT_REMINDER,
  editReminder,
  editReminderFailure,
  editReminderSuccess,
  FETCH_MORE_REMINDERS,
  FETCH_REMINDER,
  FETCH_REMINDERS,
  fetchMoreReminders,
  fetchMoreRemindersFailure,
  fetchMoreRemindersSuccess,
  fetchReminder,
  fetchReminderFailure,
  fetchReminders,
  fetchRemindersFailure,
  fetchRemindersSuccess,
  fetchReminderSuccess,
  findGroupOfReminder,
  getReminder,
  getReminderFilters,
  getRemountRemindersWidget,
  PARTIAL_UPDATE_REMINDER,
  partialUpdateReminder,
  partialUpdateReminderFailure,
  partialUpdateReminderSuccess,
  REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
  remountWebsocketRemindersWidget,
  TOGGLE_REMINDER_STATE,
  toggleReminderState,
} from '../duck/reminders'
import { getEventType } from '../reducers/constants'
import { getPatientId, getSoapId } from '../reducers/soap'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchReminderSaga({
  reminderId,
  soapId,
}: ReturnType<typeof fetchReminder>) {
  try {
    const { entities } = yield* requestAPI(
      API.fetchReminderV2,
      reminderId,
      soapId,
    )
    yield call(updateEntities, entities)
    yield put(fetchReminderSuccess())
  } catch (e) {
    yield put(fetchReminderFailure(e as ApiError))
  }
}

export function* fetchRemindersSaga({
  patientId,
  from,
  to,
  stateIds,
}: ReturnType<typeof fetchReminders>) {
  try {
    yield put(startLoading('reminders'))
    const {
      result: { data: groupsList, totalCount },
      entities,
    } = yield requestAPI(API.fetchRemindersV2, patientId, from, to, stateIds)
    yield call(updateEntities, entities)
    yield put(fetchRemindersSuccess(groupsList, totalCount))
  } catch (error) {
    yield put(fetchRemindersFailure(error as ApiError))
  } finally {
    yield put(finishLoading('reminders'))
  }
}

export function* fetchMoreRemindersSagaCancelable({
  patientId,
  from,
  to,
  stateIds,
}: ReturnType<typeof fetchMoreReminders>) {
  try {
    yield put(startLoading('reminders'))
    const {
      result: { data: groupsList, totalCount },
      entities,
    } = yield requestAPI(API.fetchRemindersV2, patientId, from, to, stateIds)
    yield call(updateEntities, entities, { remindersGroups: [true] })
    yield put(fetchMoreRemindersSuccess(groupsList, totalCount))
  } catch (error) {
    yield put(fetchMoreRemindersFailure(error as ApiError))
  } finally {
    yield put(finishLoading('reminders'))
  }
}

export function* fetchMoreRemindersSaga(
  params: ReturnType<typeof fetchMoreReminders>,
) {
  const task: Task = yield fork(fetchMoreRemindersSagaCancelable, params)
  yield take([FETCH_REMINDERS])
  yield cancel(task)
}

export function* editReminderSaga({
  reminder,
}: ReturnType<typeof editReminder>) {
  try {
    yield put(startLoading('reminders'))

    const oldReminder: Reminder = yield select(getReminder(reminder.id))
    const filters: Record<string, any> = yield select(getReminderFilters)

    const isDateChanged = oldReminder?.dueDatetime !== reminder.dueDatetime
    const editableFields = R.pick(
      ['id', 'state', 'name', 'notes', 'dueDatetime', 'expireDatetime'],
      reminder,
    )
    const { result, entities } = yield requestAPI(
      API.editReminderV2,
      editableFields,
    )

    yield call(updateEntities, entities)
    yield put(editReminderSuccess(result))

    if (isDateChanged) {
      yield put(fetchTimeline())
    }

    const patientId = reminder.patientId || reminder.patient

    if (filters.stateIds && patientId) {
      yield put(
        fetchReminders({
          patientId,
          stateIds: filters.stateIds,
        }),
      )
    }
  } catch (error) {
    yield put(editReminderFailure(error as ApiError, reminder))
  } finally {
    yield put(finishLoading('reminders'))
  }
}

export function* toggleReminderStateSaga({
  reminderId,
}: ReturnType<typeof toggleReminderState>) {
  try {
    const reminder: Reminder = yield select(getReminder(reminderId))
    const EventType: Constant[] = yield select(getEventType)
    const ReminderEventType = Utils.findByName('Reminder', EventType)
    const ReminderStates = ReminderEventType?.states || []
    const resolvedStateId = Utils.findConstantIdByName(
      'Resolved',
      ReminderStates,
    )
    const openStateId = Utils.findConstantIdByName('Open', ReminderStates)

    const updatedReminder = {
      ...reminder,
      state:
        reminder.state?.id === resolvedStateId ? openStateId : resolvedStateId,
      patientId: reminder.patientId || reminder.patient,
    }
    yield put(editReminder(updatedReminder))
  } catch (error) {
    yield put(editReminderFailure(error as ApiError))
  }
}

export function* deleteReminderSaga({
  reminderId,
}: ReturnType<typeof deleteReminder>) {
  try {
    yield put(startLoading('reminders'))
    yield requestAPI(API.deleteReminderV2, reminderId)
    const reminderGroup: ReminderGroup = yield select(
      findGroupOfReminder(reminderId),
    )
    const reminderGroupKey =
      reminderGroup && getReminderGroupName(reminderGroup)
    yield put(deleteReminderSuccess(reminderId, reminderGroupKey))
  } catch (error) {
    yield put(deleteReminderFailure(error as ApiError, reminderId))
  } finally {
    yield put(finishLoading('reminders'))
  }
}

export function* createReminderSaga({
  reminder,
  soapId,
}: ReturnType<typeof createReminder>) {
  try {
    const { result, entities } = yield requestAPI(
      API.createManualReminder,
      reminder,
      soapId,
    )
    yield call(updateEntities, entities)
    yield put(
      createReminderSuccess(entities.reminders[result].listGroup, result),
    )
    yield put(fetchTimeline())
    yield put(
      fetchWidgetsData([SnapshotsAliasTypes.Reminders], {
        quiet: false,
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        patientId: reminder.patientId,
      }),
    )
  } catch (error) {
    yield put(createReminderFailure(error as ApiError))
  }
}

function* refetchRemindersWithFilters(patientId: string | Nil) {
  const filters: Record<string, any> = yield select(getReminderFilters)

  if (patientId) {
    yield put(
      fetchReminders({
        patientId,
        stateIds: filters?.stateIds,
      }),
    )
  }
}

export function* partialUpdateReminderSaga({
  id,
  input,
}: ReturnType<typeof partialUpdateReminder>) {
  try {
    const reminder = yield* requestAPI(API.partialUpdateReminders, id, input)
    yield put(partialUpdateReminderSuccess(id, reminder))
  } catch (error) {
    yield put(partialUpdateReminderFailure(error as ApiError, id))
  }
}

export function* remountWebsocketRemindersWidgetSaga({
  body,
}: ReturnType<typeof remountWebsocketRemindersWidget>) {
  const remount: boolean = yield select(getRemountRemindersWidget)
  const patientId: string | Nil = yield select(getPatientId)
  const soapId: string | Nil = yield select(getSoapId)
  const { id: reminderId, actionType } = body || {}

  if (remount) {
    if (reminderId && actionType !== WsAction.DELETE) {
      yield put(fetchReminder(reminderId, soapId))
    } else {
      yield call(refetchRemindersWithFilters, patientId)
    }
  }

  yield put(clearRemountWebsocketRemindersWidget())
}

function* watchFetchReminder() {
  yield takeLatest(FETCH_REMINDER, fetchReminderSaga)
}

function* watchFetchReminders() {
  yield takeLatest(FETCH_REMINDERS, fetchRemindersSaga)
}

function* watchFetchMoreReminders() {
  yield takeLatest(FETCH_MORE_REMINDERS, fetchMoreRemindersSaga)
}

function* watchEditReminder() {
  yield takeEvery(EDIT_REMINDER, editReminderSaga)
}

function* watchToggleReminderState() {
  yield takeEvery(TOGGLE_REMINDER_STATE, toggleReminderStateSaga)
}

function* watchDeleteReminder() {
  yield takeEvery(DELETE_REMINDER, deleteReminderSaga)
}

function* watchCreateReminder() {
  yield takeLatest(CREATE_REMINDER, createReminderSaga)
}

function* watchPartialUpdateReminder() {
  yield takeLatest(PARTIAL_UPDATE_REMINDER, partialUpdateReminderSaga)
}

function* watchRemountWebsocketRemindersWidget() {
  yield takeLatest(
    REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
    remountWebsocketRemindersWidgetSaga,
  )
}

export default function* remindersSaga() {
  yield all([
    watchFetchReminder(),
    watchFetchReminders(),
    watchFetchMoreReminders(),
    watchEditReminder(),
    watchDeleteReminder(),
    watchToggleReminderState(),
    watchCreateReminder(),
    watchPartialUpdateReminder(),
    watchRemountWebsocketRemindersWidget(),
  ])
}
