import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { PermissionArea, useFields } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeMarketplaceManagementFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../practices'

const MarketplaceManagementSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const { t } = useTranslation('Businesses')

  const supportPermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))

  const { fields, reset } = useFields(
    [
      {
        name: 'devExtensionsEnabled',
        label: t(
          'Businesses:MARKETPLACE_MANAGEMENT.VIEW_MARKETPLACE_EXTENSIONS_IN_DEVELOPMENT',
        ),
        type: 'toggle',
        initialValue: Boolean(business.devExtensionsEnabled),
      },
    ],
    false,
  )

  const { devExtensionsEnabled } = fields

  usePracticeFieldsSection<PracticeMarketplaceManagementFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.MARKETPLACE_MANAGEMENT,
    reset,
  })

  return (
    <Grid container direction="column">
      <PuiSwitch
        disabled={!supportPermissions.update}
        field={devExtensionsEnabled}
        label={devExtensionsEnabled.label}
      />
    </Grid>
  )
}

export default memo(MarketplaceManagementSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
