import { ApiError } from '@pbt/pbt-ui-components'

import {
  Notification,
  NotificationSettings,
  NotificationsTotalCounts,
  TableFilter,
} from '~/types'

import {
  ADD_NOTIFICATION_FOR_AREAS,
  CLOSE_NOTIFICATIONS_HISTORY_POPUP,
  DECREMENT_NOTIFICATIONS_TOTAL_COUNTS,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_FAILURE,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS,
  FETCH_NOTIFICATION_SETTINGS_FAILURE,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_FAILURE,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  INCREMENT_NOTIFICATIONS_TOTAL_COUNTS,
  NEW_LIST_NOTIFICATION,
  NEW_WS_NOTIFICATION,
  NEW_WS_NOTIFICATION_FAILURE,
  NOTIFICATIONS_CHANGE_IS_READ,
  NOTIFICATIONS_CHANGE_IS_READ_FAILURE,
  NOTIFICATIONS_CHANGE_IS_READ_SUCCESS,
  NOTIFICATIONS_DELETE,
  NOTIFICATIONS_DELETE_ALL,
  NOTIFICATIONS_DELETE_ALL_FAILURE,
  NOTIFICATIONS_DELETE_ALL_SUCCESS,
  NOTIFICATIONS_DELETE_FAILURE,
  NOTIFICATIONS_DELETE_SUCCESS,
  NOTIFICATIONS_MARK_ALL_AS_READ,
  NOTIFICATIONS_MARK_ALL_AS_READ_FAILURE,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_FAILURE,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_SUCCESS,
  NOTIFICATIONS_MARK_ALL_AS_READ_SUCCESS,
  OPEN_NOTIFICATIONS_HISTORY_POPUP,
  SET_NOTIFICATIONS_MAP,
  SET_NOTIFICATIONS_TOTAL_COUNTS_TO_ZERO,
  UPDATE_FILTERS,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM,
  UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM_MAP,
  UPDATE_NOTIFICATIONS_SINGLE_TOTAL_COUNT,
  UPDATE_NOTIFICATIONS_TOTAL_COUNTS,
  UPDATE_NOTIFICATIONS_TOTAL_COUNTS_FAILURE,
} from './types/notifications'

export const fetchNotificationSettings = (personId: string) => ({
  type: FETCH_NOTIFICATION_SETTINGS,
  personId,
})
export const fetchNotificationSettingsSuccess = (
  personId: string,
  notificationSettings: NotificationSettings,
) => ({
  type: FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  personId,
  notificationSettings,
})
export const fetchNotificationSettingsFailure = (error: ApiError) => ({
  type: FETCH_NOTIFICATION_SETTINGS_FAILURE,
  error,
})

export const updateNotificationSettings = (
  personId: string,
  notificationSettings: NotificationSettings,
) => ({
  type: UPDATE_NOTIFICATION_SETTINGS,
  personId,
  notificationSettings,
})
export const updateNotificationSettingsSuccess = (
  personId: string,
  notificationSettings: NotificationSettings,
) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  personId,
  notificationSettings,
})
export const updateNotificationSettingsFailure = (error: ApiError) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  error,
})

export const fetchNotifications = (
  from: number,
  to: number,
  notificationStyleId: string,
) => ({
  type: FETCH_NOTIFICATIONS,
  from,
  to,
  notificationStyleId,
})
export const fetchNotificationsSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  list,
  totalCount,
})
export const fetchNotificationsFailure = (error: ApiError) => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  error,
})

export const fetchMoreItemsForNotifications = (
  from: number,
  to: number,
  notificationStyleId: string,
) => ({
  type: FETCH_MORE_ITEMS_FOR_NOTIFICATIONS,
  from,
  to,
  notificationStyleId,
})
export const fetchMoreItemsForNotificationsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForNotificationsFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_FAILURE,
  error,
})

export const updateNotificationsLinkedToAreaListItemMap = (
  notificationIdsByLinkedItemId: Record<string, string[]>,
  areaId: string,
) => ({
  type: UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM_MAP,
  notificationIdsByLinkedItemId,
  areaId,
})
export const updateNotificationsLinkedToAreaListItem = (
  notificationIdsByLinkedItemId: Record<string, string[]>,
  unreadNotificationsTotalCount: number,
  areaId: string,
) => ({
  type: UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM,
  notificationIdsByLinkedItemId,
  unreadNotificationsTotalCount,
  areaId,
})

export const setNotificationsMap = (
  notificationsMap: Record<string, Notification>,
) => ({
  type: SET_NOTIFICATIONS_MAP,
  notificationsMap,
})
export const updateNotifications = (
  notifications: Record<string, Notification>,
) => ({
  type: UPDATE_NOTIFICATIONS,
  notifications,
})
export const updateNotificationFilters = (
  filters: Record<string, TableFilter>,
) => ({ type: UPDATE_FILTERS, filters })

export const closeNotificationsHistoryPopup = () => ({
  type: CLOSE_NOTIFICATIONS_HISTORY_POPUP,
})
export const openNotificationsHistoryPopup = () => ({
  type: OPEN_NOTIFICATIONS_HISTORY_POPUP,
})

export const updateNotificationsSingleTotalCount = (
  totalCount: number,
  areaId: string,
  styleId: string,
) => ({
  type: UPDATE_NOTIFICATIONS_SINGLE_TOTAL_COUNT,
  totalCount,
  areaId,
  styleId,
})
export const updateNotificationsTotalCounts = (
  totalCounts: NotificationsTotalCounts,
) => ({
  type: UPDATE_NOTIFICATIONS_TOTAL_COUNTS,
  totalCounts,
})
export const updateNotificationsTotalCountsFailure = (error: ApiError) => ({
  type: UPDATE_NOTIFICATIONS_TOTAL_COUNTS_FAILURE,
  error,
})

export const decrementNotificationsTotalCounts = (
  notificationTypeId: string,
) => ({
  type: DECREMENT_NOTIFICATIONS_TOTAL_COUNTS,
  notificationTypeId,
})
export const incrementNotificationsTotalCounts = (
  notificationTypeId: string,
) => ({
  type: INCREMENT_NOTIFICATIONS_TOTAL_COUNTS,
  notificationTypeId,
})
export const setNotificationsTotalCountsToZero = () => ({
  type: SET_NOTIFICATIONS_TOTAL_COUNTS_TO_ZERO,
})

export const fetchNotificationAreasTotalCounts = () => ({
  type: FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS,
})
export const fetchNotificationAreasTotalCountsSuccess = () => ({
  type: FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_SUCCESS,
})
export const fetchNotificationAreasTotalCountsFailure = (error: ApiError) => ({
  type: FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_FAILURE,
  error,
})

export const changeNotificationIsRead = (
  notificationId: string,
  isRead: boolean,
) => ({
  type: NOTIFICATIONS_CHANGE_IS_READ,
  notificationId,
  isRead,
})
export const changeNotificationIsReadSuccess = () => ({
  type: NOTIFICATIONS_CHANGE_IS_READ_SUCCESS,
})
export const changeNotificationIsReadFailure = (
  error: ApiError,
  notificationId: string,
) => ({
  type: NOTIFICATIONS_CHANGE_IS_READ_FAILURE,
  error,
  notificationId,
})

export const markAllNotificationsAsReadForLinkedItem = (
  linkedItemId: string,
  areaId: string,
) => ({
  type: NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM,
  linkedItemId,
  areaId,
})
export const markAllNotificationsAsReadForLinkedItemSuccess = () => ({
  type: NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_SUCCESS,
})
export const markAllNotificationsAsReadForLinkedItemFailure = (
  error: ApiError,
) => ({
  type: NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_FAILURE,
  error,
})

export const markAllNotificationsAsRead = () => ({
  type: NOTIFICATIONS_MARK_ALL_AS_READ,
})
export const markAllNotificationsAsReadSuccess = () => ({
  type: NOTIFICATIONS_MARK_ALL_AS_READ_SUCCESS,
})
export const markAllNotificationsAsReadFailure = (error: ApiError) => ({
  type: NOTIFICATIONS_MARK_ALL_AS_READ_FAILURE,
  error,
})

export const deleteNotification = (notificationId: string) => ({
  type: NOTIFICATIONS_DELETE,
  notificationId,
})
export const deleteNotificationSuccess = (notificationId: string) => ({
  type: NOTIFICATIONS_DELETE_SUCCESS,
  notificationId,
})
export const deleteNotificationFailure = (
  error: ApiError,
  notificationId: string,
) => ({
  type: NOTIFICATIONS_DELETE_FAILURE,
  error,
  notificationId,
})

export const deleteAllNotifications = () => ({ type: NOTIFICATIONS_DELETE_ALL })
export const deleteAllNotificationsSuccess = () => ({
  type: NOTIFICATIONS_DELETE_ALL_SUCCESS,
})
export const deleteAllNotificationsFailure = (error: ApiError) => ({
  type: NOTIFICATIONS_DELETE_ALL_FAILURE,
  error,
})

export const newWsNotification = (body: any) => ({
  type: NEW_WS_NOTIFICATION,
  body,
})
export const newWsNotificationFailure = (error: ApiError) => ({
  type: NEW_WS_NOTIFICATION_FAILURE,
  error,
})

export const addNotificationForAreas = (notification: Notification) => ({
  type: ADD_NOTIFICATION_FOR_AREAS,
  notification,
})
export const newListNotification = (notificationId: string) => ({
  type: NEW_LIST_NOTIFICATION,
  notificationId,
})
