import React from 'react'
import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { OnHandChangeType } from '~/constants/onHandConstants'
import { getInventoryOnHandChangeType } from '~/store/reducers/constants'
import { OnHandCatalogHistoryItem } from '~/types/entities/onHandCatalog'

import AdjustedQuantityCell from './AdjustedQuantityCell'

const HistoryQuantityCell = (item: OnHandCatalogHistoryItem) => {
  const InventoryOnHandChangeType = useSelector(getInventoryOnHandChangeType)

  const OnHandChangeTypeUseId = Utils.findConstantIdByName(
    OnHandChangeType.USE,
    InventoryOnHandChangeType,
  )

  const quantity =
    item.changeTypeId === OnHandChangeTypeUseId
      ? -1 * item.quantity
      : item.quantity

  return <AdjustedQuantityCell {...item} quantity={quantity} />
}

export default HistoryQuantityCell
