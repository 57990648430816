import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateUtils,
  LanguageUtils,
  LinkButton,
  Nil,
  PuiDialog,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import CertificatePreviewGenerateTypes from '~/constants/CertificatePreviewGenerateTypes'
import DialogNames from '~/constants/DialogNames'
import { PDF } from '~/constants/extensions'
import FeatureToggle from '~/constants/featureToggle'
import {
  getFeatureToggle,
  getProcedureStatus,
} from '~/store/reducers/constants'
import { RabiesVaccination, SoapFile } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2, 7, 2, 3),
    },
    paper: {
      maxWidth: 800,
      with: 800,
    },
    button: {
      width: 134,
    },
    radioGroup: {
      flex: 1,
    },
    radioLabel: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'RabiesVaccinationsDialog' },
)

export interface RabiesVaccinationsDialogProps extends BasePuiDialogProps {
  clientId?: string | Nil
  patientId: string | Nil
  showSkipButton?: boolean
  soapBusinessId?: string | Nil
  soapId?: string | Nil
  vaccinations: RabiesVaccination[]
}

const RabiesVaccinationsDialog = ({
  open,
  soapId,
  soapBusinessId,
  clientId,
  patientId,
  vaccinations,
  showSkipButton,
  onClose,
}: RabiesVaccinationsDialogProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [openVaccineCertificatePreviewDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_PREVIEW,
  )

  const ProcedureStatus = useSelector(getProcedureStatus)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const DeclinedStateId = Utils.findConstantIdByName(
    'Declined',
    ProcedureStatus,
  )
  const CompletedStateId = Utils.findConstantIdByName(
    'Declined',
    ProcedureStatus,
  )

  const [vaccineId, setVaccineId] = useState(vaccinations?.[0]?.id)

  const handleGenerate = () => {
    openVaccineCertificatePreviewDialog({
      vaccinationIds: [vaccineId],
      soapId,
      soapBusinessId,
      clientId,
      patientId,
      generateType: CertificatePreviewGenerateTypes.RABIES,
      showSkipButton,
    })

    if (onClose) {
      onClose()
    }
  }

  const navigateToSoap = (otherSoapId: string) => {
    if (onClose) {
      onClose()
    }
    navigate(
      addOriginalBusinessId(
        `/soap/${otherSoapId}`,
        isPatientSharingEnabled ? soapBusinessId : null,
      ),
    )
  }

  const handlePreviewCertificate = (document: SoapFile) => {
    openVaccineCertificatePreviewDialog({
      soapId,
      soapBusinessId,
      patientId,
      document,
    })
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader className={classes.button} onClick={handleGenerate}>
          {t('Common:GENERATE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="rabies-vaccinations-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      scroll="paper"
      title={t('Dialogs:RABIES_VACCINATIONS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <RadioGroup
        className={classes.radioGroup}
        name="vaccineId"
        value={vaccineId}
        onChange={(event, value) => setVaccineId(value)}
      >
        {vaccinations.map(
          ({
            id,
            name,
            certificateFileId,
            certificateFileName,
            certificateGenerated: certificateDate,
            certificateURL,
            stateId,
            soapId: vaccinationSoapId,
            businessId: vaccinationBusinessId,
          }) => {
            const isDeclined = stateId === DeclinedStateId
            const isCompleted = stateId === CompletedStateId
            const stateDisplayName = LanguageUtils.getConstantTranslatedName(
              stateId,
              ProcedureStatus,
            )

            return (
              <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                key={id}
              >
                <Grid container item alignItems="center" wrap="nowrap" xs={5}>
                  <FormControlLabel
                    classes={{ label: classes.radioLabel }}
                    control={<Radio value={id} />}
                    label={
                      <Grid item>
                        {name}
                        <BusinessShareIcon
                          businessIds={
                            vaccinationBusinessId ? [vaccinationBusinessId] : []
                          }
                        />
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  {certificateURL ? (
                    <LinkButton
                      onClick={() => {
                        handlePreviewCertificate({
                          id: certificateFileId!,
                          name: certificateFileName || '',
                          fileUrl: certificateURL,
                          extension: PDF,
                        })
                      }}
                    >
                      {certificateDate
                        ? `${t('Common:CERTIFICATE')} ${DateUtils.formatDate(
                            certificateDate,
                          )}`
                        : t('Common:CERTIFICATE')}
                    </LinkButton>
                  ) : (
                    <Text pl={1}>
                      {t('Dialogs:RABIES_VACCINATIONS_DIALOG.NO_CERTIFICATE')}
                    </Text>
                  )}
                </Grid>
                <Grid container item justifyContent="center" xs={2}>
                  <StateLabel
                    align="center"
                    disabled={isDeclined}
                    success={isCompleted}
                    variant="small"
                  >
                    {stateDisplayName}
                  </StateLabel>
                </Grid>
                <Grid item xs={2}>
                  {vaccinationSoapId && (
                    <LinkButton
                      onClick={() => navigateToSoap(vaccinationSoapId)}
                    >
                      {t('Common:VIEW_SOAP')}
                    </LinkButton>
                  )}
                </Grid>
              </Grid>
            )
          },
        )}
      </RadioGroup>
    </PuiDialog>
  )
}

export default RabiesVaccinationsDialog
