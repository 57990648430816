import React from 'react'
import { Grid } from '@mui/material'

import ClientAndPatientMarketplacePanel from '../../details/new-client-and-patient/ClientAndPatientMarketplacePanel'
import TimelineComponent from '../../timeline/TimelineComponent'

interface RecordsComponentProps {
  clientId: string
  patientId: string
}

const RecordsComponent = ({ clientId, patientId }: RecordsComponentProps) => (
  <Grid container item direction="column" wrap="nowrap">
    <ClientAndPatientMarketplacePanel
      clientId={clientId}
      patientId={patientId}
    />
    <TimelineComponent clientId={clientId} patientId={patientId} />
  </Grid>
)

export default RecordsComponent
