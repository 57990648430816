import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { TimeEntity, UnsavedTimeEntity } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getServerValidationError } from '~/utils/errors'

import type { RootState } from '../index'

export const CHECK_STATUS = 'timeTracker/CHECK_STATUS'
export const CHECK_STATUS_SUCCESS = 'timeTracker/CHECK_STATUS_SUCCESS'
export const CHECK_STATUS_FAILURE = 'timeTracker/CHECK_STATUS_FAILURE'

export const CLOCK_IN = 'timeTracker/CLOCK_IN'
export const CLOCK_IN_SUCCESS = 'timeTracker/CLOCK_IN_SUCCESS'
export const CLOCK_IN_FAILURE = 'timeTracker/CLOCK_IN_FAILURE'

export const CLOCK_OUT = 'timeTracker/CLOCK_OUT'
export const CLOCK_OUT_SUCCESS = 'timeTracker/CLOCK_OUT_SUCCESS'
export const CLOCK_OUT_FAILURE = 'timeTracker/CLOCK_OUT_FAILURE'

export const DELETE_TIME_ENTITY = 'timeTracker/DELETE_TIME_ENTITY'
export const DELETE_TIME_ENTITY_SUCCESS =
  'timeTracker/DELETE_TIME_ENTITY_SUCCESS'
export const DELETE_TIME_ENTITY_FAILURE =
  'timeTracker/DELETE_TIME_ENTITY_FAILURE'

export const FETCH_USER_TIME_ENTITIES = 'timeTracker/FETCH_USER_TIME_ENTITIES'
export const FETCH_USER_TIME_ENTITIES_SUCCESS =
  'timeTracker/FETCH_USER_TIME_ENTITIES_SUCCESS'
export const FETCH_USER_TIME_ENTITIES_FAILURE =
  'timeTracker/FETCH_USER_TIME_ENTITIES_FAILURE'

export const FETCH_MORE_USER_TIME_ENTITIES =
  'timeTracker/FETCH_MORE_USER_TIME_ENTITIES'
export const FETCH_MORE_USER_TIME_ENTITIES_SUCCESS =
  'timeTracker/FETCH_MORE_USER_TIME_ENTITIES_SUCCESS'
export const FETCH_MORE_USER_TIME_ENTITIES_FAILURE =
  'timeTracker/FETCH_MORE_USER_TIME_ENTITIES_FAILURE'

export const UPDATE_TIME_ENTITY = 'timeTracker/UPDATE_TIME_ENTITY'
export const UPDATE_TIME_ENTITY_SUCCESS =
  'timeTracker/UPDATE_TIME_ENTITY_SUCCESS'
export const UPDATE_TIME_ENTITY_FAILURE =
  'timeTracker/UPDATE_TIME_ENTITY_FAILURE'

export const CREATE_TIME_ENTITY = 'timeTracker/CREATE_TIME_ENTITY'
export const CREATE_TIME_ENTITY_SUCCESS =
  'timeTracker/CREATE_TIME_ENTITY_SUCCESS'
export const CREATE_TIME_ENTITY_FAILURE =
  'timeTracker/CREATE_TIME_ENTITY_FAILURE'

export const CLEAN_UP_USER_ENTITIES = 'timeTracker/CLEAN_UP_USER_ENTITIES'
export const CLEAR_ERROR = 'timeTracker/CLEAR_ERROR'

export const UPDATE_TIME_TRACKER_ENTITIES =
  'timeTracker/UPDATE_TIME_TRACKER_ENTITIES'

export const updateTimeTrackerEntities = (
  entities: Record<string, TimeEntity>,
) => ({
  type: UPDATE_TIME_TRACKER_ENTITIES,
  entities,
})

export const checkStatus = () => ({ type: CHECK_STATUS })
export const checkStatusSuccess = (id: string | Nil) => ({
  type: CHECK_STATUS_SUCCESS,
  id,
})
export const checkStatusFailure = (error: ApiError) => ({
  type: CHECK_STATUS_FAILURE,
  error,
})

export const clockIn = (notes: string, bypassValidation?: boolean) => ({
  type: CLOCK_IN,
  notes,
  bypassValidation,
})
export const clockInSuccess = (id: string, personId: string) => ({
  type: CLOCK_IN_SUCCESS,
  id,
  personId,
})
export const clockInFailure = (error: ApiError) => ({
  type: CLOCK_IN_FAILURE,
  error,
})

export const clockOut = (reason: string, notes: string) => ({
  type: CLOCK_OUT,
  reason,
  notes,
})
export const clockOutSuccess = () => ({ type: CLOCK_OUT_SUCCESS })
export const clockOutFailure = (error: ApiError) => ({
  type: CLOCK_OUT_FAILURE,
  error,
})

export const deleteTimeEntity = (id: string) => ({
  type: DELETE_TIME_ENTITY,
  id,
})
export const deleteTimeEntitySuccess = (id: string) => ({
  type: DELETE_TIME_ENTITY_SUCCESS,
  id,
})
export const deleteTimeEntityFailure = (error: ApiError) => ({
  type: DELETE_TIME_ENTITY_FAILURE,
  error,
})

export const fetchUserTimeEntities = (
  personId: string,
  from?: number,
  to?: number,
) => ({
  type: FETCH_USER_TIME_ENTITIES,
  personId,
  from,
  to,
})
export const fetchUserTimeEntitiesSuccess = (
  list: string[],
  totalCount: number,
  personId: string,
) => ({
  type: FETCH_USER_TIME_ENTITIES_SUCCESS,
  list,
  totalCount,
  personId,
})
export const fetchUserTimeEntitiesFailure = (error: ApiError) => ({
  type: FETCH_USER_TIME_ENTITIES_FAILURE,
  error,
})

export const fetchMoreUserTimeEntities = (
  personId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_USER_TIME_ENTITIES,
  personId,
  from,
  to,
})
export const fetchMoreUserTimeEntitiesSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_USER_TIME_ENTITIES_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreUserTimeEntitiesFailure = (error: ApiError) => ({
  type: FETCH_MORE_USER_TIME_ENTITIES_FAILURE,
  error,
})

export const updateTimeEntity = (entity: TimeEntity) => ({
  type: UPDATE_TIME_ENTITY,
  entity,
})
export const updateTimeEntitySuccess = () => ({
  type: UPDATE_TIME_ENTITY_SUCCESS,
})
export const updateTimeEntityFailure = (error: ApiError) => ({
  type: UPDATE_TIME_ENTITY_FAILURE,
  error,
})

export const createTimeEntity = (
  personId: string,
  entity: UnsavedTimeEntity,
) => ({
  type: CREATE_TIME_ENTITY,
  personId,
  entity,
})
export const createTimeEntitySuccess = () => ({
  type: CREATE_TIME_ENTITY_SUCCESS,
})
export const createTimeEntityFailure = (error: ApiError) => ({
  type: CREATE_TIME_ENTITY_FAILURE,
  error,
})

export const cleanUpUserEntities = () => ({ type: CLEAN_UP_USER_ENTITIES })
export const clearError = () => ({ type: CLEAR_ERROR })

export type TimeTrackerState = {
  currentEntityId: string | Nil
  error: string | null
  isLoading: boolean
  list: string[]
  listOwnerId: string | null
  map: Record<string, TimeEntity>
  totalCount: number
}

export const INITIAL_STATE: TimeTrackerState = {
  map: {},
  currentEntityId: null,
  isLoading: false,
  list: [],
  listOwnerId: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  error: null,
}

export const timeTrackerReducer = (
  state: TimeTrackerState = INITIAL_STATE,
  action: AnyAction,
): TimeTrackerState => {
  switch (action.type) {
    case UPDATE_TIME_TRACKER_ENTITIES:
      return { ...state, map: secondLevelMerge(state.map, action.entities) }
    case FETCH_USER_TIME_ENTITIES:
      return {
        ...state,
        isLoading: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case FETCH_MORE_USER_TIME_ENTITIES:
      return { ...state, isLoading: true }
    case FETCH_USER_TIME_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: R.uniq(action.list),
        listOwnerId: action.personId,
        totalCount: action.totalCount,
      }
    case FETCH_MORE_USER_TIME_ENTITIES_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case CLOCK_IN:
    case CLOCK_OUT:
    case DELETE_TIME_ENTITY:
    case UPDATE_TIME_ENTITY:
    case CREATE_TIME_ENTITY:
      return { ...state, isLoading: true, error: null }
    case DELETE_TIME_ENTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: R.omit([action.id], state.map),
        list: R.without([action.id], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case UPDATE_TIME_ENTITY_SUCCESS:
    case CREATE_TIME_ENTITY_SUCCESS:
      return { ...state, isLoading: false }
    case CLOCK_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentEntityId: action.id,
        ...(action.personId === state.listOwnerId
          ? {
              list: [action.id, ...state.list],
              totalCount: state.totalCount + 1,
            }
          : {}),
      }
    case CLOCK_OUT_SUCCESS:
      return { ...state, isLoading: false, currentEntityId: null }
    case CHECK_STATUS_SUCCESS:
      return { ...state, currentEntityId: action.id }
    case CLEAN_UP_USER_ENTITIES:
      return { ...state, list: [] }
    case CHECK_STATUS_FAILURE:
    case CLOCK_IN_FAILURE:
    case CLOCK_OUT_FAILURE:
    case FETCH_USER_TIME_ENTITIES_FAILURE:
    case FETCH_MORE_USER_TIME_ENTITIES_FAILURE:
    case DELETE_TIME_ENTITY_FAILURE:
    case UPDATE_TIME_ENTITY_FAILURE:
    case CREATE_TIME_ENTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getServerValidationError(action.error),
      }
    case CLEAR_ERROR:
      return { ...state, error: null }
    case CHECK_STATUS:
    default:
      return state
  }
}

export const getTimeTracker = (state: RootState): TimeTrackerState =>
  state.timeTracker
export const getTimeTrackerMap = (state: RootState) => getTimeTracker(state).map
export const getTimeTrackerEntity = (id: string | Nil) =>
  createSelector(getTimeTrackerMap, (map) => (id ? map[id] : undefined))
export const getMultipleTimeTrackerEntities = (ids: string[]) =>
  createSelector(getTimeTrackerMap, (map) => R.props(ids, map))
export const getCurrentTimeEntityId = (state: RootState) =>
  getTimeTracker(state).currentEntityId
export const getCurrentTimeEntity = (state: RootState) => {
  const id = getCurrentTimeEntityId(state)
  return id ? getTimeTrackerEntity(id)(state) : undefined
}
export const getTimeTrackerIsLoading = (state: RootState) =>
  getTimeTracker(state).isLoading
export const getTimeTrackerError = (state: RootState) =>
  getTimeTracker(state).error
export const getUserEntityIdsList = (state: RootState) =>
  getTimeTracker(state).list
export const getTimeTrackingEntriesTotalCount = (state: RootState) =>
  getTimeTracker(state).totalCount
