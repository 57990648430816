import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text, TextInteractive, Utils } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import { getUser } from '~/store/reducers/users'
import { LabTestDashboardItem } from '~/types'

import ChoosePatientButton from './ChoosePatientButton'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.colors.secondaryText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'ClientCell' },
)

export interface ClientCellProps {
  isLoading: boolean
  labTest: LabTestDashboardItem
}

const ClientCell = ({ labTest, isLoading }: ClientCellProps) => {
  const classes = useStyles()

  const { client: clientId, patient: patientId } = labTest

  const client = useSelector(getUser(clientId))

  const clientName = Utils.getPersonString(client)

  if (isLoading) {
    return <TextInteractive isLoading={isLoading} variant="body2" />
  }

  return clientId && patientId ? (
    <Grid container alignItems="center" wrap="nowrap">
      <Dotdotdot clamp={1}>
        <Text variant="body2">
          {clientId ? (
            <Link className={classes.link} to={`/client/${clientId}`}>
              {clientName}
            </Link>
          ) : (
            clientName
          )}
        </Text>
      </Dotdotdot>
    </Grid>
  ) : (
    <ChoosePatientButton labTest={labTest} />
  )
}

export default ClientCell
