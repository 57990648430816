import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Defaults,
  PuiAlert,
  PuiDialog,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  deleteMemberFromBusinesses,
  fetchMembersList,
} from '~/store/actions/members'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getMembersIsDeleting } from '~/store/reducers/members'
import { getUser } from '~/store/reducers/users'
import { getDeleteConfirmMessage } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 420,
      maxWidth: 420,
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
    },
    actions: {
      paddingLeft: theme.spacing(2),
    },
    button: {
      width: 120,
    },
    radioLabel: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'RemoveMemberDialog' },
)

const RemoveFromType = {
  CURRENT: 'CURRENT',
  ALL: 'ALL',
}

interface RemoveMemberDialogProps extends BasePuiDialogProps {
  memberId: string
  onDeleted: () => void
}

const RemoveMemberDialog = ({
  memberId,
  open,
  onClose,
  onDeleted,
}: RemoveMemberDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Admin'])

  const teamMember = useSelector(getUser(memberId))
  const currentBusiness = useSelector(getCurrentBusiness)
  const isDeleting = useSelector(getMembersIsDeleting)

  const [removeFromType, setRemoveFromType] = useState(RemoveFromType.CURRENT)

  const handleClose = ({ refresh }: { refresh?: boolean } = {}) => {
    if (refresh) {
      dispatch(
        fetchMembersList({
          from: 0,
          to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          shorten: true,
          includeInactive: true,
          includeInvitationStatus: true,
        }),
      )
    }

    if (onClose) {
      onClose()
    }
    onDeleted()
  }

  const closeAfterDelete = useCloseAfterCreation(
    handleClose,
    getMembersIsDeleting,
  )

  const businessesIds = R.uniq(
    R.pluck('business', teamMember?.businessToRoleList || []),
  )

  if (!businessesIds.length) {
    return null
  }

  const hasRolesInOtherBusinesses = businessesIds.length > 1

  const handleRemove = () => {
    if (removeFromType === RemoveFromType.ALL) {
      dispatch(deleteMemberFromBusinesses(memberId, businessesIds))
    } else if (currentBusiness?.id) {
      dispatch(deleteMemberFromBusinesses(memberId, [currentBusiness.id]))
    }

    closeAfterDelete()
  }

  return hasRolesInOtherBusinesses ? (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isDeleting}
          onClick={handleRemove}
        >
          {t('Common:REMOVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="remove-member-dialog"
      classes={{
        actions: classes.actions,
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Admin:MEMBER.REMOVE_PERSON', {
        person: Utils.getPersonString(teamMember),
      })}
      onClose={onClose}
    >
      <RadioGroup
        aria-label="remove-from-type"
        name="remove-from-type"
        value={removeFromType}
        onChange={(_, value) => setRemoveFromType(value)}
      >
        <FormControlLabel
          classes={{ label: classes.radioLabel }}
          control={<Radio />}
          label={t('Admin:MEMBER.FROM_THIS_PRACTICE', {
            practice: currentBusiness?.name,
          })}
          value={RemoveFromType.CURRENT}
        />
        <FormControlLabel
          classes={{ label: classes.radioLabel }}
          control={<Radio />}
          label={t('Admin:MEMBER.FROM_ALL_PRACTICES')}
          value={RemoveFromType.ALL}
        />
      </RadioGroup>
    </PuiDialog>
  ) : (
    <PuiAlert
      CancelButtonProps={{ loading: isDeleting }}
      OkButtonProps={{ loading: isDeleting }}
      cancelButtonText={t('Common:NO_KEEP')}
      message={getDeleteConfirmMessage(t('Common:TEAM_MEMBER').toLowerCase())}
      okButtonText={t('Common:YES_DELETE')}
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onOk={handleRemove}
    />
  )
}

export default RemoveMemberDialog
