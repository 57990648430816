import * as R from 'ramda'
import { all, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Entities, LabTest, TableFilter } from '~/types'

import {
  createLabTestPrice,
  createLabTestPriceFailure,
  createLabTestPriceSuccess,
  deleteLabTestPrice,
  deleteLabTestPriceFailure,
  deleteLabTestPriceSuccess,
  editLabTestPrice,
  editLabTestPriceFailure,
  editLabTestPriceSuccess,
  editMultipleLabTestPrices,
  fetchLabTest,
  fetchLabTestFailure,
  fetchLabTestsList,
  fetchLabTestsListFailure,
  fetchLabTestsListSuccess,
  fetchLabTestSuccess,
  fetchMoreItemsForLabTestsList,
  fetchMoreItemsForLabTestsListFailure,
  fetchMoreItemsForLabTestsListSuccess,
  updateLabTests,
} from '../actions/labTests'
import { updateSearchHighlights } from '../actions/search'
import {
  CREATE_LAB_TEST_PRICE,
  DELETE_LAB_TEST_PRICE,
  EDIT_LAB_TEST_PRICE,
  EDIT_MULTIPLE_LAB_TEST_PRICES,
  FETCH_LAB_TEST,
  FETCH_LAB_TESTS_LIST,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST,
} from '../actions/types/labTests'
import { finishLoading, startLoading } from '../duck/progress'
import { getLabTestFilters } from '../reducers/labTests'
import requestAPI from './utils/requestAPI'

export function* fetchLabTestsListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchLabTestsList>) {
  try {
    const filters: Record<string, TableFilter> = yield select(getLabTestFilters)
    const labVendorIds = filters.labVendorIds?.value

    yield put(startLoading('labTests'))
    const {
      result: { data: list, totalCount, highlights },
      entities: { labTests = {} },
    } = yield* requestAPI(
      API.fetchLabTests,
      from,
      to,
      query,
      labVendorIds as string[],
    )
    yield put(updateSearchHighlights(highlights, totalCount))
    yield put(updateLabTests(labTests))
    yield put(fetchLabTestsListSuccess(list, totalCount))
    yield put(finishLoading('labTests'))
  } catch (error) {
    yield put(fetchLabTestsListFailure(error as ApiError))
    yield put(finishLoading('labTests'))
  }
}

export function* fetchMoreItemsForLabTestsListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForLabTestsList>) {
  try {
    const filters: Record<string, TableFilter> = yield select(getLabTestFilters)
    const labVendorIds = filters.labVendorIds?.value

    const {
      result: { data: list, totalCount },
      entities: { labTests = {} },
    } = yield* requestAPI(
      API.fetchLabTests,
      from,
      to,
      query,
      labVendorIds as string[],
    )
    yield put(updateLabTests(labTests))
    yield put(fetchMoreItemsForLabTestsListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForLabTestsListFailure(error as ApiError))
  }
}

export function* fetchLabTestSaga({ id }: ReturnType<typeof fetchLabTest>) {
  try {
    const {
      entities: { labTests = {} },
    } = yield* requestAPI(API.fetchLabTest, id)
    yield put(updateLabTests(labTests))
    yield put(fetchLabTestSuccess())
  } catch (error) {
    yield put(fetchLabTestFailure(error as ApiError))
  }
}

export function* createLabTestPriceSaga({
  labTestId,
  price,
}: ReturnType<typeof createLabTestPrice>) {
  try {
    const {
      entities: { labTests = {} },
    } = yield* requestAPI(API.createLabTestPrice, labTestId, price)
    yield put(updateLabTests(labTests))
    yield put(createLabTestPriceSuccess())
  } catch (error) {
    yield put(createLabTestPriceFailure(error as ApiError))
  }
}

export function* editLabTestPriceSaga({
  labTestId,
  price,
}: ReturnType<typeof editLabTestPrice>) {
  try {
    const {
      entities: { labTests = {} },
    } = yield* requestAPI(API.editLabTestPrice, labTestId, price)
    yield put(updateLabTests(labTests))
    yield put(editLabTestPriceSuccess())
  } catch (error) {
    yield put(editLabTestPriceFailure(error as ApiError))
  }
}

const composeLabTestResponses = R.pipe(
  R.map(
    (result: EditLabTestPriceResult) =>
      result?.entities?.labTests as Record<string, LabTest>,
  ),
  R.filter(Boolean),
  R.mergeAll,
)

type EditLabTestPriceResult = {
  entities: Entities
  result: string
}

export function* editMultipleLabTestPricesSaga({
  updates,
}: ReturnType<typeof editMultipleLabTestPrices>) {
  try {
    const requests = updates.map(({ labTestId, price }) =>
      requestAPI(API.editLabTestPrice, labTestId, price),
    )
    const results: EditLabTestPriceResult[] = yield all(requests)
    const labTestChunks: Record<string, LabTest> =
      composeLabTestResponses(results)
    yield put(updateLabTests(labTestChunks))
  } catch (error) {
    yield put(editLabTestPriceFailure(error as ApiError))
  }
}

export function* deleteLabTestPriceSaga({
  labTestId,
  priceId,
}: ReturnType<typeof deleteLabTestPrice>) {
  try {
    const {
      entities: { labTests = {} },
    } = yield* requestAPI(API.deleteLabTestPrice, labTestId, priceId)
    yield put(updateLabTests(labTests))
    yield put(deleteLabTestPriceSuccess())
  } catch (error) {
    yield put(deleteLabTestPriceFailure(error as ApiError))
  }
}

function* watchFetchLabTestsList() {
  yield takeLatest(FETCH_LAB_TESTS_LIST, fetchLabTestsListSaga)
}

function* watchFetchMoreItemsForLabTestsList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST,
    fetchMoreItemsForLabTestsListSaga,
  )
}

function* watchFetchLabTest() {
  yield takeLeading(FETCH_LAB_TEST, fetchLabTestSaga)
}

function* watchCreateLabTestPrice() {
  yield takeLeading(CREATE_LAB_TEST_PRICE, createLabTestPriceSaga)
}

function* watchEditLabTestPrice() {
  yield takeLeading(EDIT_LAB_TEST_PRICE, editLabTestPriceSaga)
}

function* watchEditMultipleLabTestPrices() {
  yield takeLeading(
    EDIT_MULTIPLE_LAB_TEST_PRICES,
    editMultipleLabTestPricesSaga,
  )
}

function* watchDeleteLabTestPrice() {
  yield takeLeading(DELETE_LAB_TEST_PRICE, deleteLabTestPriceSaga)
}

export default function* labTestsSaga() {
  yield all([
    watchFetchLabTestsList(),
    watchFetchMoreItemsForLabTestsList(),
    watchFetchLabTest(),
    watchCreateLabTestPrice(),
    watchEditLabTestPrice(),
    watchDeleteLabTestPrice(),
    watchEditMultipleLabTestPrices(),
  ])
}
