import { gql } from '@apollo/client/core'

import {
  PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS,
  PROBLEM_LOG_CRC_CONTAINER_FIELDS,
} from '../fragments'

export const ADD_NEW_PROBLEM_BODY_SYSTEM_LOG = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  mutation CreateOrGetBodySystemLog(
    $soapId: ID!
    $problemBodySystemLogRequest: CreateProblemBodySystemLogRequestInput!
  ) {
    createOrGetBodySystemLog(
      soapId: $soapId
      problemBodySystemLogRequest: $problemBodySystemLogRequest
    ) {
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const UPDATE_PROBLEM_BODY_SYSTEM_LOG = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  mutation UpdateBodySystemLog(
    $soapId: ID!
    $logId: ID!
    $problemBodySystemLogRequest: UpdateProblemBodySystemLogRequestInput!
  ) {
    updateBodySystemLog(
      soapId: $soapId
      logId: $logId
      problemBodySystemLogRequest: $problemBodySystemLogRequest
    ) {
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const DELETE_PROBLEM_BODY_SYSTEM_LOG = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  mutation DeleteBodySystemLog($soapId: ID!, $logId: ID!) {
    deleteBodySystemLog(soapId: $soapId, logId: $logId) {
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const ADD_NEW_PROBLEM_LOG = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  mutation CreateOrGetProblemLog(
    $soapId: ID!
    $bodySystemLogId: ID!
    $problemLogRequest: CreateProblemLogRequestInput2!
  ) {
    createOrGetProblemLog(
      soapId: $soapId
      bodySystemLogId: $bodySystemLogId
      problemLogRequest: $problemLogRequest
    ) {
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const UPDATE_PROBLEM_LOG = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  mutation UpdateProblemLog(
    $soapId: ID!
    $logId: ID!
    $problemLogRequest: UpdateProblemLogRequestInput2!
  ) {
    updateProblemLog(
      soapId: $soapId
      logId: $logId
      problemLogRequest: $problemLogRequest
    ) {
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const CHANGE_STATE_FOR_ALL_BODY_SYSTEMS = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  mutation BatchUpdateBodySystemLog(
    $soapId: ID!
    $bodySystemLogBatchRequest: UpdateBodySystemLogBatchRequestInput!
  ) {
    batchUpdateBodySystemLog(
      soapId: $soapId
      bodySystemLogBatchRequest: $bodySystemLogBatchRequest
    ) {
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const ADD_PROBLEM_LOG_ENUM_VALUE = gql`
  ${PROBLEM_LOG_CRC_CONTAINER_FIELDS}
  mutation CreateOrGetProblemEnumLog(
    $problemLogId: ID!
    $problemEnumLogRequest: CreateProblemEnumLogRequestInput!
  ) {
    createOrGetProblemEnumLog(
      problemLogId: $problemLogId
      problemEnumLogRequest: $problemEnumLogRequest
    ) {
      problemLog {
        ...ProblemLogCrcContainerFields
      }
    }
  }
`

export const UPDATE_PROBLEM_LOG_ENUM_VALUE = gql`
  ${PROBLEM_LOG_CRC_CONTAINER_FIELDS}
  mutation UpdateProblemEnumLog(
    $problemLogId: ID!
    $problemEnumLogId: ID!
    $problemEnumLogRequest: UpdateProblemEnumLogRequestInput!
  ) {
    updateProblemEnumLog(
      problemLogId: $problemLogId
      problemEnumLogId: $problemEnumLogId
      problemEnumLogRequest: $problemEnumLogRequest
    ) {
      problemLog {
        ...ProblemLogCrcContainerFields
      }
    }
  }
`

export const DELETE_PROBLEM_LOG_ENUM_VALUE = gql`
  ${PROBLEM_LOG_CRC_CONTAINER_FIELDS}
  mutation DeleteProblemEnumLog(
    $problemLogId: ID!
    $problemEnumLogId: ID!
    $crc: Long!
  ) {
    deleteProblemEnumLog(
      problemLogId: $problemLogId
      problemEnumLogId: $problemEnumLogId
      crc: $crc
    ) {
      problemLog {
        ...ProblemLogCrcContainerFields
      }
    }
  }
`
