import React from 'react'
import { EventTypeName, Field } from '@pbt/pbt-ui-components'

import { ColorVariant } from '~/constants/colors'
import { useEventType } from '~/utils/useEventType'

import EnumSelect from '../inputs/EnumSelect'

const EventStateColorMapping = {
  Overdue: ColorVariant.IMPORTANT,
  Expired: ColorVariant.DISABLED,
  Resolved: ColorVariant.SUCCESS,
  Declined: ColorVariant.DISABLED,
}

export interface ReminderStateSelectProps {
  disabled?: boolean
  field: Field
}

const ReminderStateSelect = ({ disabled, field }: ReminderStateSelectProps) => {
  const reminderStates = useEventType(EventTypeName.Reminder, 'states')

  return (
    <EnumSelect
      accent
      Constant={reminderStates || []}
      colorVariantMap={EventStateColorMapping}
      disabled={disabled}
      field={field}
    />
  )
}

export default ReminderStateSelect
