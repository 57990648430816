import * as R from 'ramda'

import { isEmptyMember } from '~/store/dto/Person'

export const serializeRegistrationData = ({ manager, businesses, members }) => {
  const data = {}
  data.person = { ...manager }
  data.person.businesses = []
  delete data.person.id
  data.person.mobilePhone = data.person.phone.replace(/\s/g, '')
  delete data.person.phone
  data.businesses = Object.values(businesses).map((business) => {
    const result = { ...business }
    result.phone = result.phone.replace(/\s/g, '')
    delete result.id
    return result
  })
  const businessesKeys = Object.keys(businesses)
  if (businessesKeys.length === 1) {
    data.businesses[0].isGroup = true
  }
  data.members = Object.values(members)
    .filter((member) => !isEmptyMember(member))
    .map((member) => {
      const result = { ...member }
      delete result.id
      delete result.businessRoles
      result.businesses = member.businessRoles.map(({ businessId, jobId }) => ({
        businessIndex: businessesKeys.indexOf(businessId),
        jobId,
      }))
      return result
    })
  return data
}

export const serializeRegistrationByInviteData = (person) => {
  const result = R.pick(
    [
      'avatar',
      'country',
      'dea',
      'email',
      'firstName',
      'lastName',
      'phone',
      'password',
      'pims',
    ],
    person,
  )
  result.mobilePhone = result.phone
  delete result.phone
  return result
}
