import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Language, LanguageUtils } from '@pbt/pbt-ui-components'

import { getBusinessSupportedLanguageCodes } from '~/store/reducers/businesses'
import { getLanguages } from '~/store/reducers/constants'

export const useBusinessSupportedLanguages = (businessId?: string) => {
  const CurrentSupportedLanguageCodes = useSelector(
    getBusinessSupportedLanguageCodes(businessId),
  )
  const Languages = useSelector(getLanguages)

  const EnabledLanguages = R.pipe(
    R.filter((lng: Language) =>
      R.includes(lng.code, CurrentSupportedLanguageCodes),
    ),
    R.map((lng) => ({
      id: lng.code,
      value: lng.code,
      name: lng.fullName,
    })),
  )(Languages)

  const isMultipleLanguagesSupported =
    LanguageUtils.getIsMultipleLanguagesSupported(CurrentSupportedLanguageCodes)

  const hasCurrentLanguagesSupported = Boolean(
    CurrentSupportedLanguageCodes?.length,
  )

  return {
    hasCurrentLanguagesSupported,
    isMultipleLanguagesSupported,
    EnabledLanguages,
    CurrentSupportedLanguageCodes,
  }
}
