export const FETCH_NOTIFICATION_SETTINGS =
  'notifications/FETCH_NOTIFICATION_SETTINGS'
export const FETCH_NOTIFICATION_SETTINGS_SUCCESS =
  'notifications/FETCH_NOTIFICATION_SETTINGS_SUCCESS'
export const FETCH_NOTIFICATION_SETTINGS_FAILURE =
  'notifications/FETCH_NOTIFICATION_SETTINGS_FAILURE'

export const UPDATE_NOTIFICATION_SETTINGS =
  'notifications/UPDATE_NOTIFICATION_SETTINGS'
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  'notifications/UPDATE_NOTIFICATION_SETTINGS_SUCCESS'
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE =
  'notifications/UPDATE_NOTIFICATION_SETTINGS_FAILURE'

export const FETCH_NOTIFICATIONS = 'notifications/FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_SUCCESS =
  'notifications/FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE =
  'notifications/FETCH_NOTIFICATIONS_FAILURE'

export const FETCH_MORE_ITEMS_FOR_NOTIFICATIONS =
  'notifications/FETCH_MORE_ITEMS_FOR_NOTIFICATIONS'
export const FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_SUCCESS =
  'notifications/FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_FAILURE =
  'notifications/FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_FAILURE'

export const SET_NOTIFICATIONS_MAP = 'notifications/SET_NOTIFICATIONS_MAP'
export const UPDATE_NOTIFICATIONS = 'notifications/UPDATE_NOTIFICATIONS'
export const UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM_MAP =
  'notifications/UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM_MAP'
export const UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM =
  'notifications/UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM'
export const UPDATE_FILTERS = 'notifications/UPDATE_FILTERS'

export const CLOSE_NOTIFICATIONS_HISTORY_POPUP =
  'notifications/CLOSE_NOTIFICATIONS_HISTORY_POPUP'
export const OPEN_NOTIFICATIONS_HISTORY_POPUP =
  'notifications/OPEN_NOTIFICATIONS_HISTORY_POPUP'

export const UPDATE_NOTIFICATIONS_SINGLE_TOTAL_COUNT =
  'notifications/UPDATE_NOTIFICATIONS_SINGLE_TOTAL_COUNT'
export const UPDATE_NOTIFICATIONS_TOTAL_COUNTS =
  'notifications/UPDATE_NOTIFICATIONS_TOTAL_COUNTS'
export const UPDATE_NOTIFICATIONS_TOTAL_COUNTS_FAILURE =
  'notifications/UPDATE_NOTIFICATIONS_TOTAL_COUNTS_FAILURE'
export const DECREMENT_NOTIFICATIONS_TOTAL_COUNTS =
  'notifications/DECREMENT_NOTIFICATIONS_TOTAL_COUNTS'
export const INCREMENT_NOTIFICATIONS_TOTAL_COUNTS =
  'notifications/INCREMENT_NOTIFICATIONS_TOTAL_COUNTS'
export const SET_NOTIFICATIONS_TOTAL_COUNTS_TO_ZERO =
  'notifications/SET_NOTIFICATIONS_TOTAL_COUNTS_TO_ZERO'

export const FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS =
  'notifications/FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS'
export const FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_SUCCESS =
  'notifications/FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_SUCCESS'
export const FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_FAILURE =
  'notifications/FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_FAILURE'

export const NOTIFICATIONS_CHANGE_IS_READ =
  'notifications/NOTIFICATIONS_CHANGE_IS_READ'
export const NOTIFICATIONS_CHANGE_IS_READ_SUCCESS =
  'notifications/NOTIFICATIONS_CHANGE_IS_READ_SUCCESS'
export const NOTIFICATIONS_CHANGE_IS_READ_FAILURE =
  'notifications/NOTIFICATIONS_CHANGE_IS_READ_FAILURE'

export const NOTIFICATIONS_MARK_ALL_AS_READ =
  'notifications/NOTIFICATIONS_MARK_ALL_AS_READ'
export const NOTIFICATIONS_MARK_ALL_AS_READ_SUCCESS =
  'notifications/NOTIFICATIONS_MARK_ALL_AS_READ_SUCCESS'
export const NOTIFICATIONS_MARK_ALL_AS_READ_FAILURE =
  'notifications/NOTIFICATIONS_MARK_ALL_AS_READ_FAILURE'

export const NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM =
  'notifications/NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM'
export const NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_SUCCESS =
  'notifications/NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_SUCCESS'
export const NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_FAILURE =
  'notifications/NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_FAILURE'

export const NOTIFICATIONS_DELETE = 'notifications/NOTIFICATIONS_DELETE'
export const NOTIFICATIONS_DELETE_SUCCESS =
  'notifications/NOTIFICATIONS_DELETE_SUCCESS'
export const NOTIFICATIONS_DELETE_FAILURE =
  'notifications/NOTIFICATIONS_DELETE_FAILURE'

export const NOTIFICATIONS_DELETE_ALL = 'notifications/NOTIFICATIONS_DELETE_ALL'
export const NOTIFICATIONS_DELETE_ALL_SUCCESS =
  'notifications/NOTIFICATIONS_DELETE_ALL_SUCCESS'
export const NOTIFICATIONS_DELETE_ALL_FAILURE =
  'notifications/NOTIFICATIONS_DELETE_ALL_FAILURE'

export const NEW_WS_NOTIFICATION = 'notifications/NEW_WS_NOTIFICATION'
export const NEW_WS_NOTIFICATION_FAILURE =
  'notifications/NEW_WS_NOTIFICATION_FAILURE'

export const ADD_NOTIFICATION_FOR_AREAS =
  'notifications/ADD_NOTIFICATION_FOR_AREAS'
export const NEW_LIST_NOTIFICATION = 'notifications/NEW_LIST_NOTIFICATION'
