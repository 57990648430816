export const FETCH_PROCEDURES_LIST_FAILURE =
  'procedures/FETCH_PROCEDURES_LIST_FAILURE'
export const FETCH_PROCEDURES_LIST_SUCCESS =
  'procedures/FETCH_PROCEDURES_LIST_SUCCESS'
export const FETCH_PROCEDURES_LIST = 'procedures/FETCH_PROCEDURES_LIST'

export const FETCH_PROCEDURE = 'procedures/FETCH_PROCEDURE'
export const FETCH_PROCEDURE_FAILURE = 'procedures/FETCH_PROCEDURE_FAILURE'
export const FETCH_PROCEDURE_SUCCESS = 'procedures/FETCH_PROCEDURE_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_FAILURE =
  'procedures/FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_SUCCESS =
  'procedures/FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST =
  'procedures/FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST'

export const UPDATE_PROCEDURES = 'procedures/UPDATE_PROCEDURES'
export const RESET_PROCEDURES = 'procedures/RESET_PROCEDURES'

export const CREATE_PRICE = 'procedures/CREATE_PRICE'
export const CREATE_PRICE_SUCCESS = 'procedures/CREATE_PRICE_SUCCESS'
export const CREATE_PRICE_FAILURE = 'procedures/CREATE_PRICE_FAILURE'

export const EDIT_PRICE = 'procedures/EDIT_PRICE'
export const EDIT_PRICE_SUCCESS = 'procedures/EDIT_PRICE_SUCCESS'
export const EDIT_PRICE_FAILURE = 'procedures/EDIT_PRICE_FAILURE'

export const DELETE_PRICE = 'procedures/DELETE_PRICE'
export const DELETE_PRICE_SUCCESS = 'procedures/DELETE_PRICE_SUCCESS'
export const DELETE_PRICE_FAILURE = 'procedures/DELETE_PRICE_FAILURE'

export const SET_SELECTED_PROCEDURE_ID = 'procedures/SET_SELECTED_PROCEDURE_ID'
export const CLEAR_SELECTED_PROCEDURE_ID =
  'procedures/CLEAR_SELECTED_PROCEDURE_ID'

export const SET_FILTERS = 'procedures/SET_FILTERS'
