import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  AahaPageMetaMap,
  BenchmarkingPageMetaMap,
  PageMetaMap,
  PageMetaRoute,
} from '~/constants/pageMeta'
import { PathRegexHandlerList } from '~/constants/routes'
import { getCurrentUserId } from '~/store/reducers/auth'
import { isAAHA, isBenchmarking, testAndMatch } from '~/utils'
import { addToRecentList } from '~/utils/recentList'
import useRouteTitleMeta from '~/utils/useRouteTitleMeta'

const hasMatchedRouteRegexHandler = (path: string) =>
  PathRegexHandlerList.some((regex) => {
    const { match } = testAndMatch(path, RegExp(regex))
    return match
  })

const PageTitleWatcher = () => {
  const location = useLocation()
  const path = location.pathname + location.search

  const routeTitleMeta = useRouteTitleMeta()

  const currentUserId = useSelector(getCurrentUserId)

  const isActivationLandingPage = location.pathname === '/auth/activation'
  const isAahaBrandingPage = isAAHA && isActivationLandingPage
  const isBenchmarkingBrandingPage = isBenchmarking && isActivationLandingPage

  const { title: routeTitle, historyTitle } = routeTitleMeta

  useEffect(() => {
    if (historyTitle && currentUserId) {
      addToRecentList(currentUserId, { title: historyTitle, url: path })
    }

    if (
      !historyTitle &&
      process.env.NODE_ENV !== 'production' &&
      !hasMatchedRouteRegexHandler(path)
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `Recent List: No match for "${path}", consider adding new handler!`,
      )
    }
  }, [path, historyTitle, currentUserId])

  const { title, description: pageDescription } =
    (isAahaBrandingPage
      ? AahaPageMetaMap[location.pathname as PageMetaRoute]
      : undefined) ||
    (isBenchmarkingBrandingPage
      ? BenchmarkingPageMetaMap[location.pathname as PageMetaRoute]
      : undefined) ||
    PageMetaMap[location.pathname as PageMetaRoute] ||
    PageMetaMap[PageMetaRoute.ROOT] ||
    {}

  const pageTitle = routeTitle || title

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta content={pageDescription} name="Description" />
    </Helmet>
  )
}
export default PageTitleWatcher
