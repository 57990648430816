import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import deepEqual from 'fast-deep-equal'
import { FieldProp, useFields } from '@pbt/pbt-ui-components'

import i18nPortal from '~/locales/i18n'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { SoapTemplate } from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

const getFields = (item: SoapTemplate | undefined): FieldProp[] => [
  {
    name: 'name',
    initialValue: item?.name,
    validators: ['required'],
    label: i18nPortal.t('Admin:SOAP_CUSTOMIZATION.TEMPLATE_NAME'),
  },
  {
    name: 'tabs',
    initialValue: item?.tabs || [],
    type: 'select',
    validators: ['required'],
  },
]

export const useSoapTemplateFields = (item: SoapTemplate | undefined) => {
  const businessId = useSelector(getCurrentBusinessId)

  const { fields, reset, validate } = useFields(getFields(item), false)

  const { name: fieldName, tabs: fieldTabs } = fields

  const [hasUnsavedData, setHasUnsavedData] = useState(false)

  const handleReset = useCallback(() => {
    if (!item) {
      return
    }
    reset(getFields(item))
  }, [item])

  useEffect(() => {
    if (businessId) {
      handleReset()
    }
  }, [handleReset, businessId])

  useFieldsChanged(() => {
    if (!item) {
      return
    }

    setHasUnsavedData(true)
    const hasTabsChanged = !deepEqual(fieldTabs.initialValue, fieldTabs.value)
    const hasTabNameChanged = fieldName.initialValue !== fieldName.value

    setHasUnsavedData(hasTabsChanged || hasTabNameChanged)
  }, fields)

  return {
    fieldName,
    fieldTabs,
    handleReset,
    hasUnsavedData,
    validate,
  }
}
