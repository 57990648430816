import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  BasePuiDialogProps,
  Defaults,
  LanguageUtils,
  moment,
  Nil,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { fetchUpcomingEvents } from '~/store/actions/scheduler'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import {
  getSchedulerIsLoading,
  getSchedulerUpcomingAppointmentsList,
} from '~/store/reducers/scheduler'
import { getMultipleTimetableEvents } from '~/store/reducers/timetable'
import { TimetableEvent } from '~/types'
import useDialog from '~/utils/useDialog'

import ListWithChildrenDialog from '../dialog/ListWithChildrenDialog'

export interface AddToAppointmentDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  filterByClient?: boolean
  onAdd: (selectedItemId: string) => void
  patientId?: string | Nil
  title?: string
}

const AddToAppointmentDialog = ({
  onClose,
  open,
  patientId,
  clientId,
  onAdd,
  title: titleProp,
  filterByClient,
  ...props
}: AddToAppointmentDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const title = titleProp || t('Common:ADD_TO_APPOINTMENT')

  const eventsList = useSelector(getSchedulerUpcomingAppointmentsList)
  const events = useSelector(getMultipleTimetableEvents(eventsList))
  const isFetching = useSelector(getSchedulerIsLoading)
  const isSaving = useSelector(getFinanceIsLoading)

  const fetchUpcomingEventsIfNeeded = () => {
    if (open && clientId && patientId) {
      dispatch(fetchUpcomingEvents(clientId, patientId, true, filterByClient))
    }
  }

  const [openEventDialog, , eventDialogOpen] = useDialog(
    DialogNames.EVENT,
    fetchUpcomingEventsIfNeeded,
  )

  const openAppointmentDialog = useCallback(() => {
    openEventDialog({ clientId, patientId })
  }, [clientId, patientId])

  useEffect(() => {
    if (open && clientId && patientId) {
      fetchUpcomingEventsIfNeeded()
    }
  }, [open, clientId, patientId])

  return (
    <ListWithChildrenDialog
      addButtonLabel={t('Common:ADD_TO_APPOINTMENT')}
      createButtonLabel={t('Common:CREATE_NEW_APPOINTMENT')}
      getName={(appointment: TimetableEvent) => {
        const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
          appointment?.businessAppointmentType,
        )
        return `${appointmentTypeDisplayName} ${moment(
          appointment.scheduledStartDatetime,
        ).format(Defaults.DATE_FORMAT)}`
      }}
      isFetching={isFetching}
      isSaving={isSaving}
      items={events}
      open={open}
      title={title}
      onAddClick={onAdd}
      onClose={() => {
        if (!eventDialogOpen && onClose) {
          onClose()
        }
      }}
      onCreateClick={openAppointmentDialog}
      {...props}
    />
  )
}

export default AddToAppointmentDialog
