import React from 'react'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { Expand as ExpandIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    control: {
      border: theme.constants.tabBorder,
    },
    label: {
      color: theme.colors.title,
    },
    icon: {
      color: theme.colors.tabLabel,
      padding: 0,
    },
    topElement: {
      borderBottom: theme.constants.tabBorder,
    },
  }),
  { name: 'BillingActivitySection' },
)

interface BillingActivitySectionProps {
  Component: React.ReactElement
  isLoading: boolean
  onExpand?: () => void
  title: string
}

export const BillingActivitySection = ({
  Component,
  title,
  isLoading,
  onExpand,
}: BillingActivitySectionProps) => {
  const classes = useStyles()
  return (
    <Box
      className={classes.control}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Box
        className={classes.topElement}
        display="flex"
        justifyContent="space-between"
        px={2}
        py={1}
      >
        <Text strong className={classes.label}>
          {title}
        </Text>
        <IconButton
          className={classes.icon}
          disabled={isLoading}
          size="small"
          onClick={onExpand}
        >
          <ExpandIcon />
        </IconButton>
      </Box>
      <Box>{Component}</Box>
    </Box>
  )
}
