import { NamedEntity, Nil, User } from '@pbt/pbt-ui-components'

import { WsAction } from '~/constants/websocket'

import { Order, OrderCallbackWithTypeCheck } from './orders'
import { ReminderProtocolTrigger } from './reminderProtocols'
import { TimetableEvent } from './timetable'

export type ReminderByUser = {
  datetime: string
  itemName: string
  person: User
}

export type Reminder = TimetableEvent & {
  due?: ReminderProtocolTrigger[]
  dueDatetime?: string
  expireDatetime?: string
  listGroup?: ReminderListGroup
  new?: boolean
  performedDate?: string
  protocolId?: string
  resolvedBy?: ReminderByUser
  resolvesWith?: ReminderProtocolTrigger[]
  soapChargeOrderItems?: Order[]
  state: NamedEntity
  triggeredBy?: ReminderByUser
}

export type UnsavedReminder = Omit<Reminder, 'id' | 'state'>

export type ReminderGroup = ReminderListGroup & {
  itemType: string
  reminders: string[]
}

export type ReminderListGroup = {
  month: string
  type: string
}

export type PartialUpdateReminderPayload = {
  dueDatetime?: string
  expireDatetime?: string
  name?: string
  notes?: string
  patientId?: string
  stateId?: string
}

export enum ReminderStateName {
  DECLINED = 'Declined',
  EXPIRED = 'Expired',
  OPEN = 'Open',
  OVERDUE = 'Overdue',
  RESOLVED = 'Resolved',
}

export interface ReminderOrderListHandlers {
  isCheckedItem: (item: Order) => boolean
  onCheckItem: OrderCallbackWithTypeCheck
  onUncheckItem: OrderCallbackWithTypeCheck
}

export type CUDReminderWSMessage = {
  actionType: WsAction
  id: string | Nil
}
