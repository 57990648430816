import React from 'react'
import { PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

export interface SoapOrderValidationErrorDialogProps extends PuiAlertProps {}

const SoapOrderValidationErrorDialog = ({
  open,
  onClose,
  message,
}: SoapOrderValidationErrorDialogProps) => (
  <PuiAlert message={message} open={open} onClose={onClose} onOk={onClose} />
)

export default SoapOrderValidationErrorDialog
