import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  ButtonWithLoader,
  PuiTextField,
  StateLabel,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  addFailedEmails,
  clearMatchChewyUserByEmail,
  getFailedEmails,
  getMatchChewyUserByEmail,
  getMatchChewyUserIsLoading,
  getMatchChewyUserResult,
} from '~/store/duck/chewyPets'

const useStyles = makeStyles(
  (theme) => ({
    left: {},
    right: {
      backgroundColor: theme.palette.grey[200],
    },
    failedEmail: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: theme.spacing(0.5),
    },
    otherInput: {
      marginTop: 0,
    },
    errorTooltip: {
      marginBottom: '-10px !important',
    },
  }),
  {
    name: 'EmailLookupWithFallback',
  },
)

interface EmailLookupWithFallbackProps {
  onSubmit: (emailToSave: string) => void
  userFailedEmails: string[]
}

const EmailLookupWithFallback = ({
  onSubmit = () => {},
  userFailedEmails = [],
}: EmailLookupWithFallbackProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')
  const dispatch = useDispatch()
  const matchChewyUserIsLoading = useSelector(getMatchChewyUserIsLoading)
  const matchChewyUserResult = useSelector(getMatchChewyUserResult)
  const failedEmails = useSelector(getFailedEmails)

  const {
    fields: { email },
    validate,
  } = useFields([
    {
      name: 'email',
      validators: [
        'required',
        'email',
        {
          validator: () => matchChewyUserResult !== false,
          validatorName: 'serverError',
        },
        'email',
      ],
      messages: {
        serverError: t(
          'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.NO_CHEWY_ACCOUNT_FOUND_TRY_ANOTHER_EMAIL',
        ),
      },
    },
  ])

  useEffect(() => {
    dispatch(addFailedEmails(userFailedEmails))
  }, [])

  useEffect(() => {
    if (matchChewyUserResult === null) return
    dispatch(clearMatchChewyUserByEmail())
  }, [email.value])

  const checkEmail = () => {
    if (validate()) {
      dispatch(getMatchChewyUserByEmail({ email: email.value }))
    }
  }

  const handleSubmit = () => {
    onSubmit(email.value)
  }

  return (
    <Grid container>
      <Grid item xs className={classes.left}>
        <Box p={3} pb={5}>
          <Text variant="h2">
            {t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.CONNECT_CHEWY_ACCOUNT')}
          </Text>
          <Box pt={1}>
            <Text strong variant="subheading3">
              {t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.NO_CHEWY_ACCOUNT_FOUND')}
            </Text>
            <Text strong variant="subheading3">
              {t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.TRY_ANOTHER_EMAIL_ADDRESS')}
            </Text>
            <Box py={1}>
              <PuiTextField
                TooltipProps={{
                  placement: 'top',
                  classes: {
                    errorTooltip: classes.errorTooltip,
                  },
                }}
                className={classes.otherInput}
                error={!email.valid}
                field={email}
                label={t(
                  'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.OTHER_EMAIL_ADDRESS',
                )}
              />
            </Box>
            {!matchChewyUserResult && (
              <Box py={1}>
                <ButtonWithLoader
                  color="primary"
                  loading={matchChewyUserIsLoading}
                  onClick={checkEmail}
                >
                  {t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.CHECK_EMAIL')}
                </ButtonWithLoader>
              </Box>
            )}
            <Box py={1}>
              <ButtonWithLoader
                color="primary"
                disabled={!matchChewyUserResult}
                onClick={handleSubmit}
              >
                {t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.CONNECT_CHEWY_ACCOUNT')}
              </ButtonWithLoader>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs className={classes.right}>
        <Box pl={4} py={8}>
          <Box pb={1} pr={4}>
            <Text strong variant="subheading3">
              {t(
                'Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.NO_CHEWY_ACCOUNTS_FOUND_FOR_THE_FOLLOWING_EMAILS',
              )}
            </Text>
          </Box>
          {failedEmails.map((failedEmail) => (
            <Grid container alignItems="center" key={failedEmail} py={0.5}>
              <Text noWrap className={classes.failedEmail} maxWidth={200}>
                {failedEmail}
              </Text>
              <StateLabel
                deleted
                display="inline"
                height="fit-content"
                variant="small"
                width="fit-content"
              >
                {t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.NO_ACCOUNT_FOUND')}
              </StateLabel>
            </Grid>
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}

export default EmailLookupWithFallback
