import React from 'react'
import { useTranslation } from 'react-i18next'
import { BasePuiDialogProps, PuiAlert } from '@pbt/pbt-ui-components'

interface NotFinalizedOrderDialogProps extends BasePuiDialogProps {
  message?: string
}

const NotFinalizedOrderDialog = ({
  message,
  onClose,
  open,
}: NotFinalizedOrderDialogProps) => {
  const { t } = useTranslation('Common')

  return (
    <PuiAlert
      message={message || t('Invoices:ITEM_NOT_FINALIZED')}
      open={open}
      onClose={onClose}
      onOk={onClose}
    />
  )
}

export default NotFinalizedOrderDialog
