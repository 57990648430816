import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { SchedulerSettings } from '~/types/entities'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_SCHEDULER_SETTINGS =
  'schedulerSettings/FETCH_SCHEDULER_SETTINGS'
export const FETCH_SCHEDULER_SETTINGS_SUCCESS =
  'schedulerSettings/FETCH_SCHEDULER_SETTINGS_SUCCESS'
export const FETCH_SCHEDULER_SETTINGS_FAILURE =
  'schedulerSettings/FETCH_SCHEDULER_SETTINGS_FAILURE'

export const UPDATE_SCHEDULER_SETTINGS =
  'schedulerSettings/UPDATE_SCHEDULER_SETTINGS'
export const UPDATE_SCHEDULER_SETTINGS_SUCCESS =
  'schedulerSettings/UPDATE_SCHEDULER_SETTINGS_SUCCESS'
export const UPDATE_SCHEDULER_SETTINGS_FAILURE =
  'schedulerSettings/UPDATE_SCHEDULER_SETTINGS_FAILURE'

export const fetchSchedulerSettings = (businessId: string) => ({
  type: FETCH_SCHEDULER_SETTINGS,
  businessId,
})
export const fetchSchedulerSettingsSuccess = (
  businessId: string,
  settings: SchedulerSettings,
) => ({
  type: FETCH_SCHEDULER_SETTINGS_SUCCESS,
  settings,
  businessId,
})
export const fetchSchedulerSettingsFailure = (error: ApiError) => ({
  type: FETCH_SCHEDULER_SETTINGS_FAILURE,
  error,
})

export const updateSchedulerSettings = (
  businessId: string,
  settings: Partial<SchedulerSettings>,
) => ({
  type: UPDATE_SCHEDULER_SETTINGS,
  businessId,
  settings,
})
export const updateSchedulerSettingsSuccess = (
  businessId: string,
  newSettings: Partial<SchedulerSettings>,
) => ({
  type: UPDATE_SCHEDULER_SETTINGS_SUCCESS,
  businessId,
  settings: newSettings,
})
export const updateSchedulerSettingsFailure = (error: ApiError) => ({
  type: UPDATE_SCHEDULER_SETTINGS_FAILURE,
  error,
})

export type SchedulerSettingsState = {
  error: string | null
  isFetching: boolean
  isUpdating: boolean
  settingsMap: Record<string, SchedulerSettings> | null
}

export const INITIAL_STATE: SchedulerSettingsState = {
  error: null,
  isFetching: false,
  isUpdating: false,
  settingsMap: null,
}

export const schedulerSettingsReducer = (
  state: SchedulerSettingsState = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_SCHEDULER_SETTINGS:
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    case FETCH_SCHEDULER_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        settingsMap: secondLevelMerge(state.settingsMap ?? {}, {
          [action.businessId]: action.settings,
        }),
      }
    case FETCH_SCHEDULER_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_SCHEDULER_SETTINGS:
      return {
        ...state,
        isUpdating: true,
        error: null,
      }
    case UPDATE_SCHEDULER_SETTINGS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        settingsMap: secondLevelMerge(state.settingsMap ?? {}, {
          [action.businessId]: action.settings,
        }),
      }
    case UPDATE_SCHEDULER_SETTINGS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

const getSchedulerSettings = (state: RootState): SchedulerSettingsState =>
  state.schedulerSettings
export const getSchedulerSettingsIsFetching = (state: RootState) =>
  getSchedulerSettings(state).isFetching
export const getSchedulerSettingsIsUpdating = (state: RootState) =>
  getSchedulerSettings(state).isUpdating
export const getSchedulerSettingsError = (state: RootState) =>
  getSchedulerSettings(state).error
export const getSchedulerSettingsMap = (state: RootState) =>
  getSchedulerSettings(state).settingsMap
export const getSchedulerSettingsByBusinessId = (businessId: string | Nil) =>
  createSelector(getSchedulerSettingsMap, (map) =>
    businessId ? map?.[businessId] : undefined,
  )

export function* fetchSchedulerSettingsSaga({
  businessId,
}: ReturnType<typeof fetchSchedulerSettings>) {
  try {
    const { occupyAllStaffTimeslots } = yield requestAPI(
      API.fetchSchedulerSettings,
      businessId,
    )
    yield put(
      fetchSchedulerSettingsSuccess(businessId, { occupyAllStaffTimeslots }),
    )
  } catch (error) {
    yield put(fetchSchedulerSettingsFailure(error as ApiError))
  }
}
export function* updateSchedulerSettingsSaga({
  businessId,
  settings,
}: ReturnType<typeof updateSchedulerSettings>) {
  try {
    const { occupyAllStaffTimeslots } = yield requestAPI(
      API.updateSchedulerSettings,
      businessId,
      settings,
    )
    yield put(
      updateSchedulerSettingsSuccess(businessId, { occupyAllStaffTimeslots }),
    )
  } catch (error) {
    yield put(updateSchedulerSettingsFailure(error as ApiError))
  }
}

function* watchFetchSchedulerSettings() {
  yield takeLatest(FETCH_SCHEDULER_SETTINGS, fetchSchedulerSettingsSaga)
}
function* watchUpdateSchedulerSettings() {
  yield takeLatest(UPDATE_SCHEDULER_SETTINGS, updateSchedulerSettingsSaga)
}

export function* schedulerSettingsSaga() {
  yield all([watchFetchSchedulerSettings(), watchUpdateSchedulerSettings()])
}
