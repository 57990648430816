import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Defaults,
  InfiniteLoaderListProps as InfiniteLoaderListPropsType,
} from '@pbt/pbt-ui-components'

import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import {
  fetchInvoicePage,
  fetchMoreItemsForClientInvoiceActivity,
  getClientBillingActivityInvoiceError,
  getInvoiceLoading,
  getInvoicePageData,
  getInvoiceTotalCount,
  getShouldRefreshData,
  getUnpaidInvoiceLoading,
  getUnpaidInvoicePageData,
  getUnpaidInvoiceTotalCount,
} from '~/store/duck/clientBillingActivityData'

import { useGetInvoiceBillingActivityTableColumns } from '../table/invoices/columns'
import {
  ClientBillingActivityInfiniteLoaderList,
  ClientBillingActivityInfiniteLoaderListClassesType,
} from './ClientBillingActivityInfiniteLoaderList'

interface InvoicesInfiniteLoaderListProps {
  InfiniteLoaderListProps?: Partial<InfiniteLoaderListPropsType>
  classes?: ClientBillingActivityInfiniteLoaderListClassesType
  clientId: string | undefined
  hasError?: boolean
  invoiceStateIds?: string[]
  onCheckInvoices?: (id: string) => void
  refundStateIds?: string[]
  selectedInvoiceIds?: string[]
  showDueToPay?: boolean
  sortColumns?: (columns: PuiDataTableColumn[]) => PuiDataTableColumn[]
  unpaid: boolean
}

export const InvoicesInfiniteLoaderList = ({
  InfiniteLoaderListProps,
  classes,
  clientId,
  hasError = false,
  invoiceStateIds = [],
  refundStateIds = [],
  selectedInvoiceIds,
  showDueToPay,
  unpaid,
  sortColumns,
  onCheckInvoices,
}: InvoicesInfiniteLoaderListProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const refresh = useSelector(getShouldRefreshData)
  const invoicesPageData = useSelector(getInvoicePageData)
  const invoicesTotalCount = useSelector(getInvoiceTotalCount)
  const isLoading = useSelector(getInvoiceLoading)
  const unpaidInvoicesPageData = useSelector(getUnpaidInvoicePageData)
  const unpaidInvoicesTotalCount = useSelector(getUnpaidInvoiceTotalCount)
  const isLoadingUnpaidInvoices = useSelector(getUnpaidInvoiceLoading)
  const error = useSelector(getClientBillingActivityInvoiceError)

  const [offsetValue, setOffsetValue] = useState(0)

  const columns = useGetInvoiceBillingActivityTableColumns({
    key: 'value',
    checkbox: onCheckInvoices
      ? {
          hasError,
          onCheck: onCheckInvoices,
          getIsChecked: (id) => (selectedInvoiceIds || []).includes(id),
        }
      : undefined,
    dueToPayPrimary: showDueToPay,
  }) as PuiDataTableColumn[]
  const sortedColumns = sortColumns ? sortColumns(columns) : columns

  const handleFetchMore = ({ from }: { from: number }) => {
    if (clientId) {
      setOffsetValue(from)
      dispatch(
        fetchMoreItemsForClientInvoiceActivity({
          clientId,
          from,
          to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          refundStateIds,
          stateIds: invoiceStateIds,
          unpaid,
        }),
      )
    }
  }

  useEffect(() => {
    if (clientId && refresh) {
      dispatch(
        fetchInvoicePage({
          unpaid,
          clientId,
          offset: offsetValue,
          limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          isRefreshing: true,
        }),
      )
    }
  }, [clientId, refresh, unpaid])

  return (
    <ClientBillingActivityInfiniteLoaderList
      InfiniteLoaderListProps={InfiniteLoaderListProps}
      classes={classes}
      clientId={clientId}
      columns={sortedColumns}
      emptyPlaceholder={t('Common:NO_INVOICES')}
      error={error}
      isLoadingInitialData={unpaid ? isLoadingUnpaidInvoices : isLoading}
      pageData={unpaid ? unpaidInvoicesPageData : invoicesPageData}
      totalCount={unpaid ? unpaidInvoicesTotalCount : invoicesTotalCount}
      onFetchMore={handleFetchMore}
    />
  )
}
