import React from 'react'
import { Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'auto',
      cursor: 'pointer',
      backgroundColor: theme.colors.profileTopBackground,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
      },
    },
    content: {
      color: theme.colors.badgeColor,
    },
  }),
  { name: 'FloatingPanelButton' },
)

export interface FloatingPanelButtonProps {
  Icon?: React.JSXElementConstructor<any>
  label?: React.ReactNode
  onClick?: () => void
}

const FloatingPanelButton = ({
  Icon,
  label,
  onClick,
}: FloatingPanelButtonProps) => {
  const classes = useStyles()

  return (
    <Stack
      alignItems="center"
      className={classes.root}
      direction="row"
      px={2}
      py={1}
      onClick={(event) => {
        event.stopPropagation()
        if (typeof onClick === 'function') {
          onClick()
        }
      }}
    >
      {Icon && <Icon className={classes.content} />}
      {label && (
        <Text underline className={classes.content} variant="body2">
          {label}
        </Text>
      )}
    </Stack>
  )
}

export default FloatingPanelButton
