import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PuiDialog } from '@pbt/pbt-ui-components'

import { SessionsTypes } from '~/constants/migration'
import { chooseImportSession } from '~/store/actions/migration'
import { getCurrentSession } from '~/store/reducers/migration'
import { BaseMigrationDialogProps } from '~/types/entities/migration'

import CovetrusSetup from './CovetrusSetup'
import StepSelectSession from './StepSelectSession'

const CovetrusIntegrationDialog = ({
  open,
  onClose,
  businessId,
}: BaseMigrationDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const sessionsFilterTypes = [SessionsTypes.RHAPSODY_ENTERPRISE]

  const currentSession = useSelector(getCurrentSession)

  const isCovetrusSetupActive = Boolean(currentSession)
  const dialogTitle = isCovetrusSetupActive
    ? t('Businesses:MIGRATION.COVETRUS_CONFIG')
    : t('Businesses:MIGRATION.SELECT_SESSION_FOR_INTEGRATION')
  const StepComponent = isCovetrusSetupActive
    ? CovetrusSetup
    : StepSelectSession

  const handleClose = useCallback(() => {
    dispatch(chooseImportSession(null))
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="upload-additional-files-dialog"
      maxWidth="sm"
      open={open}
      title={dialogTitle}
      onClose={handleClose}
    >
      <StepComponent
        allowDisabledSessions
        createSessionEnabled
        businessId={businessId}
        createSessionButtonText={t(
          'Businesses:MIGRATION.CREATE_NEW_INTEGRATION',
        )}
        sessionsFilterTypes={sessionsFilterTypes}
        onSaved={handleClose}
      />
    </PuiDialog>
  )
}

export default CovetrusIntegrationDialog
