import React from 'react'
import { Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { OrderListType } from '~/types'
import { BaseChargeTabProps } from '~/types/entities/charges'

import ChargeListWithFilters from '../ChargeListWithFilters'

export interface RefillsChargeTabProps extends BaseChargeTabProps {
  appointmentId?: string
  clientId: string | Nil
  patientId?: string
}

const RefillsChargeTab = (props: RefillsChargeTabProps) => (
  <ChargeListWithFilters
    preselectedFilter={OrderType.REFILL}
    preselectedListType={OrderListType.OUR}
    {...props}
  />
)

export default RefillsChargeTab
