import React, { useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PermissionArea,
  PuiDialog,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getGender,
  getPreferenceCautions,
  getPreferenceDrugs,
  getPreferenceLabWorkFrequencies,
  getPreferenceMedicalAlerts,
  getPreferencePatientPerformedTreatmentsLocations,
  getPreferencePremedicationVaccineReactions,
  getPreferenceSedations,
  getPreferenceVenipunctures,
} from '~/store/reducers/constants'
import {
  fetchPatientPreferences,
  getPatientPreferences,
  getPatientPreferencesIsLoading,
  getPatientPreferencesIsSaving,
  savePatientPreferences,
} from '~/store/reducers/patientPreferences'
import { isFieldValuesChanged } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

interface PatientPreferencesDialogProps extends BasePuiDialogProps {
  patientId: string | Nil
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 550,
      maxWidth: 550,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 100,
    },
  }),
  { name: 'PatientPreferencesDialog' },
)

const PatientPreferencesDialog = ({
  open,
  onClose,
  patientId,
}: PatientPreferencesDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])
  const dispatch = useDispatch()

  const cautionConstants = useSelector(getPreferenceCautions)
  const medicalAlertConstants = useSelector(getPreferenceMedicalAlerts)
  const drugPreferenceConstants = useSelector(getPreferenceDrugs)
  const genderOfStaffConstants = useSelector(getGender)
  const venipunctureSiteConstants = useSelector(getPreferenceVenipunctures)
  const vaccineReactionRequiringPremedicationWithConstants = useSelector(
    getPreferencePremedicationVaccineReactions,
  )
  const locationOfPerformedTreatmentsConstants = useSelector(
    getPreferencePatientPerformedTreatmentsLocations,
  )
  const requiresSedationForConstants = useSelector(getPreferenceSedations)
  const labWorkFrequencyConstants = useSelector(getPreferenceLabWorkFrequencies)
  const patientPreferences = useSelector(getPatientPreferences(patientId))
  const preferencesIsLoading = useSelector(getPatientPreferencesIsLoading)
  const preferencesIsSaving = useSelector(getPatientPreferencesIsSaving)
  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )

  const { fields, reset, validate } = useFields(
    [
      {
        name: 'caution',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.CAUTION'),
        type: 'select',
        initialValue: patientPreferences?.caution?.map((c) => c.id) ?? [],
      },
      {
        name: 'medicalAlert',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.MEDICAL_ALERT'),
        type: 'select',
        initialValue: patientPreferences?.medicalAlert?.map((c) => c.id) ?? [],
      },
      {
        name: 'medicalAlertDrugSensitivity',
        label: t(
          'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.DRUG_SENSITIVITY_COMMENT',
        ),
        type: 'text',
        initialValue: patientPreferences?.drugSensitivityComment ?? '',
      },
      {
        name: 'importantComment',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.COMMENT'),
        type: 'text',
        initialValue: patientPreferences?.importantComment ?? '',
      },
      {
        name: 'treats',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.TREATS'),
        type: 'text',
        initialValue: patientPreferences?.treats ?? '',
      },
      {
        name: 'namePronunciation',
        label: t(
          'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.NAME_PRONUNCIATION',
        ),
        type: 'text',
        initialValue: patientPreferences?.namePronunciation ?? '',
      },
      {
        name: 'drugPreference',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.DRUG_PREFERENCE'),
        type: 'select',
        initialValue:
          patientPreferences?.drugPreference?.map((c) => c.id) ?? [],
      },
      {
        name: 'genderOfStaff',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.STAFF_GENDER'),
        type: 'select',
        initialValue: patientPreferences?.staffGender?.id ?? '',
      },
      {
        name: 'generalComment',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.COMMENT'),
        type: 'text',
        initialValue: patientPreferences?.generalComment ?? '',
      },
      {
        name: 'venipunctureSite',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.VENIPUNCTURE_SITE'),
        type: 'select',
        initialValue: patientPreferences?.venipuncture?.id ?? '',
      },
      {
        name: 'vaccineReactionRequiringPremedicationWith',
        label: t(
          'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.PREMEDICATION_VACCINE_REACTIONS',
        ),
        type: 'select',
        initialValue:
          patientPreferences?.premedicationVaccineReaction?.map((c) => c.id) ??
          [],
      },
      {
        name: 'locationOfPerformedTreatments',
        label: t(
          'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.PERFORMED_TREATMENTS_LOCATION',
        ),
        type: 'select',
        initialValue: patientPreferences?.performedTreatmentsLocation?.id ?? '',
      },
      {
        name: 'requiresSedationFor',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.SEDATION'),
        type: 'select',
        initialValue: patientPreferences?.sedation?.map((c) => c.id) ?? [],
      },
      {
        name: 'labWorkFrequency',
        label: t(
          'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.LAB_WORK_FREQUENCY',
        ),
        type: 'select',
        initialValue:
          patientPreferences?.labWorkFrequency?.map((c) => c.id) ?? [],
      },
      {
        name: 'medicalComment',
        label: t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.COMMENT'),
        type: 'text',
        initialValue: patientPreferences?.medicalComment ?? '',
      },
    ],
    false,
  )

  useEffect(() => {
    if (!preferencesIsLoading) {
      reset()
    }
  }, [preferencesIsLoading])

  const closeAfterCreation = useCloseAfterCreation(() => {
    reset()
    if (onClose) {
      onClose()
    }
  }, getPatientPreferencesIsSaving)

  useLayoutEffect(() => {
    if (patientId) {
      dispatch(fetchPatientPreferences({ id: patientId }))
    }
  }, [patientId])

  const {
    caution,
    importantComment,
    medicalAlert,
    medicalAlertDrugSensitivity,
    treats,
    namePronunciation,
    drugPreference,
    genderOfStaff,
    generalComment,
    venipunctureSite,
    vaccineReactionRequiringPremedicationWith,
    locationOfPerformedTreatments,
    requiresSedationFor,
    labWorkFrequency,
    medicalComment,
  } = fields

  const onSave = () => {
    if (patientId && validate()) {
      const hasDrugSensitivity = medicalAlert.value.includes('DRUG_SENSITIVITY')

      dispatch(
        savePatientPreferences({
          patientId,
          input: {
            cautionIds: caution.value.length > 0 ? caution.value : undefined,
            drugPreferenceIds:
              drugPreference.value.length > 0
                ? drugPreference.value
                : undefined,
            drugSensitivityComment:
              hasDrugSensitivity && medicalAlertDrugSensitivity.value.length > 0
                ? medicalAlertDrugSensitivity.value
                : undefined,
            staffGenderId:
              genderOfStaff.value.length > 0 ? genderOfStaff.value : undefined,
            generalComment:
              generalComment.value.length > 0
                ? generalComment.value
                : undefined,
            importantComment:
              importantComment.value.length > 0
                ? importantComment.value
                : undefined,
            labWorkFrequencyIds:
              labWorkFrequency.value.length > 0
                ? labWorkFrequency.value
                : undefined,
            medicalAlertIds:
              medicalAlert.value.length > 0 ? medicalAlert.value : undefined,
            medicalComment:
              medicalComment.value.length > 0
                ? medicalComment.value
                : undefined,
            namePronunciation:
              namePronunciation.value.length > 0
                ? namePronunciation.value
                : undefined,
            performedTreatmentsLocationId:
              locationOfPerformedTreatments.value.length > 0
                ? locationOfPerformedTreatments.value
                : undefined,
            premedicationVaccineReactionIds:
              vaccineReactionRequiringPremedicationWith.value.length > 0
                ? vaccineReactionRequiringPremedicationWith.value
                : undefined,
            sedationIds:
              requiresSedationFor.value.length > 0
                ? requiresSedationFor.value
                : undefined,
            treats: treats.value.length > 0 ? treats.value : undefined,
            venipunctureId:
              venipunctureSite.value.length > 0
                ? venipunctureSite.value
                : undefined,
          },
        }),
      )

      closeAfterCreation()
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: patientUpdatePermissions ? onSave : undefined,
      }}
      actions={
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={
              !patientUpdatePermissions ||
              preferencesIsLoading ||
              preferencesIsSaving
            }
            loading={preferencesIsSaving}
            onClick={patientUpdatePermissions ? onSave : undefined}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      }
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() => isFieldValuesChanged(fields)}
      open={open}
      scroll="paper"
      title={t('Clients:PATIENT_SECTION.PATIENT_PREFERENCES')}
      onClose={onClose}
    >
      {preferencesIsLoading && <CircularProgress />}
      {!preferencesIsLoading && (
        <Grid container>
          <Grid container mb={2}>
            <Grid item xs={12}>
              <Text strong>
                {t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.TITLE')}
              </Text>
              <FormControl fullWidth error={!medicalAlert.valid}>
                <InputLabel htmlFor="patient-medical-alert-select">
                  {t(
                    'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.MEDICAL_ALERT',
                  )}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={medicalAlert}
                  input={<Input id="patient-medical-alert-select" />}
                  items={medicalAlertConstants}
                />
              </FormControl>
              {medicalAlert.value.includes('DRUG_SENSITIVITY') && (
                <Grid item md={12} xs={12}>
                  <PuiTextField
                    field={medicalAlertDrugSensitivity}
                    id="important-drug-sensitivity-input"
                    label={t(
                      'Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.DRUG_SENSITIVITY_COMMENT',
                    )}
                  />
                </Grid>
              )}
              <FormControl fullWidth error={!caution.valid}>
                <InputLabel htmlFor="patient-caution-select">
                  {t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.CAUTION')}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={caution}
                  input={<Input id="patient-caution-select" />}
                  items={cautionConstants}
                />
              </FormControl>
              <Grid item md={12} xs={12}>
                <InputLabel htmlFor="important-comment-input">
                  <Text mb={0} mt={2} variant="subheading3">
                    {t('Clients:PATIENT_PREFERENCE_TITLES.IMPORTANT.COMMENT')}
                  </Text>
                </InputLabel>
                <PuiTextField
                  multiline
                  field={importantComment}
                  id="important-comment-input"
                  inputProps={{ maxLength: 1000 }}
                  minRows={3}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12}>
              <Text strong>
                {t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.TITLE')}
              </Text>
            </Grid>
            <Grid item md={6} pr={1} xs={12}>
              <PuiTextField
                field={treats}
                id="general-treats-input"
                label={t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.TREATS')}
              />
            </Grid>
            <Grid item md={6} pl={1} xs={12}>
              <PuiTextField
                field={namePronunciation}
                id="general-name-pronunciation-input"
                label={t(
                  'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.NAME_PRONUNCIATION',
                )}
              />
            </Grid>
            <Grid item mb={1} md={12} xs={12}>
              <FormControl fullWidth error={!drugPreference.valid}>
                <InputLabel htmlFor="patient-drug-preference-select">
                  {t(
                    'Clients:PATIENT_PREFERENCE_TITLES.GENERAL.DRUG_PREFERENCE',
                  )}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={drugPreference}
                  input={<Input id="patient-drug-preference-select" />}
                  items={drugPreferenceConstants}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth error={!genderOfStaff.valid}>
                <InputLabel htmlFor="patient-gender-of-staff-select">
                  {t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.STAFF_GENDER')}
                </InputLabel>
                <PuiSelect
                  field={genderOfStaff}
                  input={<Input id="patient-gender-of-staff-select" />}
                  items={genderOfStaffConstants}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel htmlFor="important-comment-input">
                <Text mb={0} mt={2} variant="subheading3">
                  {t('Clients:PATIENT_PREFERENCE_TITLES.GENERAL.COMMENT')}
                </Text>
              </InputLabel>
              <PuiTextField
                multiline
                field={generalComment}
                id="general-comment-input"
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Text strong>
                {t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.TITLE')}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!venipunctureSite.valid}>
                <InputLabel htmlFor="patient-venipuncture-site-select">
                  {t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.VENIPUNCTURE_SITE',
                  )}
                </InputLabel>
                <PuiSelect
                  field={venipunctureSite}
                  input={<Input id="patient-venipuncture-site-select" />}
                  items={venipunctureSiteConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!vaccineReactionRequiringPremedicationWith.valid}
              >
                <InputLabel htmlFor="patient-vaccine-reaction-select">
                  {t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.PREMEDICATION_VACCINE_REACTIONS',
                  )}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={vaccineReactionRequiringPremedicationWith}
                  input={<Input id="patient-vaccine-reaction-select" />}
                  items={vaccineReactionRequiringPremedicationWithConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!locationOfPerformedTreatments.valid}
              >
                <InputLabel htmlFor="patient-location-of-performed-treatments-select">
                  {t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.PERFORMED_TREATMENTS_LOCATION',
                  )}
                </InputLabel>
                <PuiSelect
                  field={locationOfPerformedTreatments}
                  input={
                    <Input id="patient-location-of-performed-treatments-select" />
                  }
                  items={locationOfPerformedTreatmentsConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!requiresSedationFor.valid}>
                <InputLabel htmlFor="patient-requires-sedation-select">
                  {t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.SEDATION')}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={requiresSedationFor}
                  input={<Input id="patient-requires-sedation-select" />}
                  items={requiresSedationForConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!labWorkFrequency.valid}>
                <InputLabel htmlFor="patient-lab-work-frequency-select">
                  {t(
                    'Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.LAB_WORK_FREQUENCY',
                  )}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={labWorkFrequency}
                  input={<Input id="patient-lab-work-frequency-select" />}
                  items={labWorkFrequencyConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="important-comment-input">
                <Text mb={0} mt={2} variant="subheading3">
                  {t('Clients:PATIENT_PREFERENCE_TITLES.MEDICAL.COMMENT')}
                </Text>
              </InputLabel>
              <PuiTextField
                multiline
                field={medicalComment}
                id="medical-comment-input"
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </PuiDialog>
  )
}
export default PatientPreferencesDialog
