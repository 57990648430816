import { getAPIPath, request } from './core'

/**
 *
 * @param businessId
 * @param dashboard - Number defined by data team
 * @param signedParams - Additional Metabase params to apply default filter options
 */
export const fetchAnalyticsReportUrl = (
  businessId: string,
  dashboard: number,
  signedParams?: Record<string, string>,
) =>
  request(
    getAPIPath(`analytics/dashboard/${dashboard}`),
    {
      method: 'GET',
      params: {
        businessId,
        ...signedParams,
      },
    },
    true,
  )
