import { useSelector } from 'react-redux'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

export const useIsSoapCustomizationEnabled = () => {
  const isSoapCustomizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOAP_CUSTOMIZATION),
  )
  const isProblemsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PROBLEMS_SOAP_WIDGET),
  )

  return isSoapCustomizationEnabled && isProblemsWidgetEnabled
}
