import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { equals, isEmpty } from 'ramda'
import { ClassesType, TextInteractive } from '@pbt/pbt-ui-components'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

import { getMultipleUsers, getUser } from '~/store/reducers/users'
import { getPetFriendsString } from '~/utils'

const useStyles = makeStyles(
  () => ({
    subtext: {},
  }),
  { name: 'PetFriendsLabel' },
)

interface PetFriendsLabelProps {
  classes?: ClassesType<typeof useStyles>
  clientId: string
  highlights: string[]
  inline?: boolean
}

const PetFriendsLabel = ({
  classes: classesProp,
  clientId,
  highlights,
  inline,
}: PetFriendsLabelProps) => {
  const classes = useStyles({ classes: classesProp })

  const client = useSelector(getUser(clientId))
  const coparents = useSelector(getMultipleUsers(client?.coparents), equals)
  const petFriendsString = getPetFriendsString(client, coparents)

  return (
    <TextInteractive
      className={classes.subtext}
      highlight={getHighlightedText(petFriendsString, highlights)}
      inline={inline}
      isLoading={isEmpty(client)}
      loaderWidth={120}
      variant="body2"
    >
      {petFriendsString}
    </TextInteractive>
  )
}

export default PetFriendsLabel
