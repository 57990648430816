import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ResizeObserver from 'resize-observer-polyfill'
import { getIsSupportedAndUpdatedBrowser } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import { showRecommendedBrowsersDialog } from '~/store/actions/loginSessionSettings'
import { getErrorsList } from '~/store/duck/errors'
import { getHasClosedClientsSplashScreen } from '~/store/reducers/journal'
import { getShouldShowBrowserNotice } from '~/store/reducers/loginSessionSettings'

import SwitchBrowserSplashScreen from '../clients/SwitchBrowserSplashScreen'

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver
}

const BrowserVersionWatcher = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const hasClosedSplashScreen = useSelector(getHasClosedClientsSplashScreen)
  const shouldShowBrowserNotice = useSelector(getShouldShowBrowserNotice)
  const errors = useSelector(getErrorsList)
  const isSupportedBrowser = getIsSupportedAndUpdatedBrowser()

  useEffect(() => {
    if (errors?.length && !isSupportedBrowser) {
      dispatch(showRecommendedBrowsersDialog())
    }
  }, [errors])

  return !isSupportedBrowser &&
    (location.pathname !== '/clients' || hasClosedSplashScreen) &&
    shouldShowBrowserNotice ? (
    <SwitchBrowserSplashScreen />
  ) : null
}

export default BrowserVersionWatcher
