import * as R from 'ramda'
import { AnyAction } from 'redux'
import { ApiError } from '@pbt/pbt-ui-components'

import { ConnectedDevice } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const UPDATE_CONNECTED_DEVICES =
  'connectedDevices/UPDATE_CONNECTED_DEVICES'

export const FETCH_CONNECTED_DEVICES =
  'connectedDevices/FETCH_CONNECTED_DEVICES'
export const FETCH_CONNECTED_DEVICES_SUCCESS =
  'connectedDevices/FETCH_CONNECTED_DEVICES_SUCCESS'
export const FETCH_CONNECTED_DEVICES_FAILURE =
  'connectedDevices/FETCH_CONNECTED_DEVICES_FAILURE'

export const DELETE_CONNECTED_DEVICE =
  'connectedDevices/DELETE_CONNECTED_DEVICE'
export const DELETE_CONNECTED_DEVICE_SUCCESS =
  'connectedDevices/DELETE_CONNECTED_DEVICE_SUCCESS'
export const DELETE_CONNECTED_DEVICE_FAILURE =
  'connectedDevices/DELETE_CONNECTED_DEVICE_FAILURE'

export const updateConnectedDevices = (
  connectedDevices: Record<string, ConnectedDevice>,
) => ({
  type: UPDATE_CONNECTED_DEVICES,
  connectedDevices,
})

export const fetchConnectedDevices = () => ({
  type: FETCH_CONNECTED_DEVICES,
})
export const fetchConnectedDevicesSuccess = (list: string[]) => ({
  type: FETCH_CONNECTED_DEVICES_SUCCESS,
  list,
})
export const fetchConnectedDevicesFailure = (error: ApiError) => ({
  type: FETCH_CONNECTED_DEVICES_FAILURE,
  error,
})

export const deleteConnectedDevice = (deviceId: string) => ({
  type: DELETE_CONNECTED_DEVICE,
  deviceId,
})
export const deleteConnectedDeviceSuccess = (deviceId: string) => ({
  type: DELETE_CONNECTED_DEVICE_SUCCESS,
  deviceId,
})
export const deleteConnectedDeviceFailure = (error: ApiError) => ({
  type: DELETE_CONNECTED_DEVICE_FAILURE,
  error,
})

export type ConnectedDevicesState = {
  error: string | null
  isFetching: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, ConnectedDevice>
}

export const INITIAL_STATE: ConnectedDevicesState = {
  list: [],
  map: {},
  isLoading: false,
  isFetching: false,
  error: null,
}

export const connectedDevicesReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_CONNECTED_DEVICES_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
      }
    case FETCH_CONNECTED_DEVICES_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        isLoading: false,
        isFetching: false,
      }
    case FETCH_CONNECTED_DEVICES:
      return { ...state, list: [], isLoading: true, isFetching: true }
    case UPDATE_CONNECTED_DEVICES:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.connectedDevices),
      }
    case DELETE_CONNECTED_DEVICE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case DELETE_CONNECTED_DEVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: R.without([action.deviceId], state.list),
        map: R.omit([action.deviceId], state.map),
      }
    case DELETE_CONNECTED_DEVICE:
      return { ...state, isLoading: true }
    default:
      return state
  }
}

export const getConnectedDevices = (state: RootState): ConnectedDevicesState =>
  state.connectedDevices
export const getConnectedDevicesList = (state: RootState) =>
  getConnectedDevices(state).list
export const getConnectedDevicesMap = (state: RootState) =>
  getConnectedDevices(state).map
export const getMultipleConnectedDevices = (ids: string[]) =>
  R.pipe(getConnectedDevicesMap, R.props(ids))
export const getConnectedDevice = R.curry(
  (id, state) => getConnectedDevices(state).map[id],
)
export const getConnectedDevicesIsFetching = (state: RootState) =>
  getConnectedDevices(state).isFetching
export const getConnectedDevicesIsLoading = (state: RootState) =>
  getConnectedDevices(state).isLoading
