import { DateUtils } from '@pbt/pbt-ui-components'

export type DueDateCellItem = {
  dueDate: string
}

const DueDateCell = (item: DueDateCellItem) =>
  DateUtils.formatDateWithHours(item.dueDate, true)

export default DueDateCell
