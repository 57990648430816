import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  File as FileIcon,
  UploadImage as UploadImageIcon,
} from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    imageIconContainer: {
      position: 'relative',
      top: -2,
      right: -19,
      backgroundColor: theme.colors.tableBackground,
      borderRadius: '50%',
      padding: 2,
      width: 43,
      height: 43,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    imageIcon: {
      fontSize: '3.5rem',
      color: theme.colors.markerHighlighted,
      transform: 'rotate(-12deg)',
    },
    fileIcon: {
      fontSize: '5.6rem',
      color: theme.colors.markerHighlighted,
      transform: 'rotate(18deg)',
    },
  }),
  { name: 'AttachmentUploadIcon' },
)

const AttachmentUploadIcon = () => {
  const classes = useStyles()

  return (
    <Grid container item alignItems="center" justifyContent="center">
      <Grid item className={classes.imageIconContainer}>
        <UploadImageIcon className={classes.imageIcon} />
      </Grid>
      <Grid item>
        <FileIcon className={classes.fileIcon} />
      </Grid>
    </Grid>
  )
}

export default AttachmentUploadIcon
