import { gql } from '@apollo/client'

export const POST_REFUND_INVOICE = gql`
  mutation PostRefundInvoice($input: RefundInvoiceInput!) {
    postRefundInvoice(input: $input) {
      id
    }
  }
`

export const VOID_REFUND_INVOICE = gql`
  mutation VoidRefundInvoice($id: ID!) {
    voidRefundInvoice(refundInvoiceId: $id) {
      id
    }
  }
`
