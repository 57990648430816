import React, { forwardRef } from 'react'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

/*
  Time range format: 03:37pm - 03:42pm, 21:37 - 23:42
*/

const SINGLE_RANGE_MASK_MERIDIAN = [
  /[0-1]/,
  /\d/,
  ':',
  /[0-5]/,
  /\d/,
  /[ap]/,
  /m/,
]

const SINGLE_RANGE_MASK_WITHOUT_MERIDIAN = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/]

export interface TimeRangeInputProps extends Partial<MaskedInputProps> {
  enableMeridian?: boolean
  range?: boolean
}

const TimeRangeInput = forwardRef<HTMLInputElement, TimeRangeInputProps>(
  function TimeRangeInput(
    { range, onChange, onFocus, enableMeridian = true, ...other },
    ref,
  ) {
    const singleRangeMask = enableMeridian
      ? SINGLE_RANGE_MASK_MERIDIAN
      : SINGLE_RANGE_MASK_WITHOUT_MERIDIAN

    const timeRangeMask = R.flatten([
      singleRangeMask,
      ' ',
      '-',
      ' ',
      singleRangeMask,
    ])

    return (
      <MaskedInput
        keepCharPositions
        showMask
        mask={range ? timeRangeMask : singleRangeMask}
        ref={(inputRef) => {
          if (R.is(Function, ref)) {
            const inputElement = inputRef?.inputElement as HTMLInputElement
            ref(inputElement)
          }
        }}
        onChange={(event) => {
          const { target: { value = '' } = {} } = event
          const digitsCount = value.split('').filter(Utils.isDigit).length
          const newEvent =
            digitsCount > 0
              ? event
              : {
                  ...event,
                  target: {
                    ...event.target,
                    value: '',
                  },
                }
          if (onChange) {
            onChange(newEvent)
          }
        }}
        onFocus={Utils.handleMaskInputFocus(onFocus)}
        {...other}
      />
    )
  },
)

export default TimeRangeInput
