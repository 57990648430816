import React from 'react'

import { FieldNames, SoapValidationField } from './BaseValidationDialog'
import OTCValidationDialog, {
  OTCValidationDialogProps,
} from './OTCValidationDialog'

const fieldsList: SoapValidationField[] = [
  {
    name: FieldNames.DOCTOR,
    required: true,
  },
]

interface SoapValidationDialogProps extends OTCValidationDialogProps {}

const SoapValidationDialog = ({ ...rest }: SoapValidationDialogProps) => (
  <OTCValidationDialog fieldsList={fieldsList} {...rest} />
)

export default SoapValidationDialog
