export const FETCH_VACCINES = 'medicalHistory/FETCH_VACCINES'
export const FETCH_VACCINES_SUCCESS = 'medicalHistory/FETCH_VACCINES_SUCCESS'
export const FETCH_VACCINES_FAILURE = 'medicalHistory/FETCH_VACCINES_FAILURE'

export const SAVE_VACCINE_HISTORY = 'medicalHistory/SAVE_VACCINE_HISTORY'
export const SAVE_VACCINE_HISTORY_SUCCESS =
  'medicalHistory/SAVE_VACCINE_HISTORY_SUCCESS'
export const SAVE_VACCINE_HISTORY_FAILURE =
  'medicalHistory/SAVE_VACCINE_HISTORY_FAILURE'

export const EDIT_VACCINE_HISTORY = 'medicalHistory/EDIT_VACCINE_HISTORY'
export const EDIT_VACCINE_HISTORY_SUCCESS =
  'medicalHistory/EDIT_VACCINE_HISTORY_SUCCESS'
export const EDIT_VACCINE_HISTORY_FAILURE =
  'medicalHistory/EDIT_VACCINE_HISTORY_FAILURE'

export const DELETE_VACCINE_HISTORY = 'medicalHistory/DELETE_VACCINE_HISTORY'
export const DELETE_VACCINE_HISTORY_SUCCESS =
  'medicalHistory/DELETE_VACCINE_HISTORY_SUCCESS'
export const DELETE_VACCINE_HISTORY_FAILURE =
  'medicalHistory/DELETE_VACCINE_HISTORY_FAILURE'

export const FETCH_DIAGNOSES_FILTERS = 'medicalHistory/FETCH_DIAGNOSES_FILTERS'
export const FETCH_DIAGNOSES_FILTERS_SUCCESS =
  'medicalHistory/FETCH_DIAGNOSES_FILTERS_SUCCESS'
export const FETCH_DIAGNOSES_FILTERS_FAILURE =
  'medicalHistory/FETCH_DIAGNOSES_FILTERS_FAILURE'

export const FETCH_DIAGNOSES = 'medicalHistory/FETCH_DIAGNOSES'
export const FETCH_DIAGNOSES_SUCCESS = 'medicalHistory/FETCH_DIAGNOSES_SUCCESS'
export const FETCH_DIAGNOSES_FAILURE = 'medicalHistory/FETCH_DIAGNOSES_FAILURE'

export const SAVE_DIAGNOSIS_HISTORY = 'medicalHistory/SAVE_DIAGNOSIS_HISTORY'
export const SAVE_DIAGNOSIS_HISTORY_SUCCESS =
  'medicalHistory/SAVE_DIAGNOSIS_HISTORY_SUCCESS'
export const SAVE_DIAGNOSIS_HISTORY_FAILURE =
  'medicalHistory/SAVE_DIAGNOSIS_HISTORY_FAILURE'

export const EDIT_DIAGNOSIS_HISTORY = 'medicalHistory/EDIT_DIAGNOSIS_HISTORY'
export const EDIT_DIAGNOSIS_HISTORY_SUCCESS =
  'medicalHistory/EDIT_DIAGNOSIS_HISTORY_SUCCESS'
export const EDIT_DIAGNOSIS_HISTORY_FAILURE =
  'medicalHistory/EDIT_DIAGNOSIS_HISTORY_FAILURE'

export const DELETE_DIAGNOSIS_HISTORY =
  'medicalHistory/DELETE_DIAGNOSIS_HISTORY'
export const DELETE_DIAGNOSIS_HISTORY_SUCCESS =
  'medicalHistory/DELETE_DIAGNOSIS_HISTORY_SUCCESS'
export const DELETE_DIAGNOSIS_HISTORY_FAILURE =
  'medicalHistory/DELETE_DIAGNOSIS_HISTORY_FAILURE'

export const SAVE_NOTE_HISTORY = 'medicalHistory/SAVE_NOTE_HISTORY'
export const SAVE_NOTE_HISTORY_SUCCESS =
  'medicalHistory/SAVE_NOTE_HISTORY_SUCCESS'
export const SAVE_NOTE_HISTORY_FAILURE =
  'medicalHistory/SAVE_NOTE_HISTORY_FAILURE'

export const EDIT_NOTE_HISTORY = 'medicalHistory/EDIT_NOTE_HISTORY'
export const EDIT_NOTE_HISTORY_SUCCESS =
  'medicalHistory/EDIT_NOTE_HISTORY_SUCCESS'
export const EDIT_NOTE_HISTORY_FAILURE =
  'medicalHistory/EDIT_NOTE_HISTORY_FAILURE'

export const DELETE_NOTE_HISTORY = 'medicalHistory/DELETE_NOTE_HISTORY'
export const DELETE_NOTE_HISTORY_SUCCESS =
  'medicalHistory/DELETE_NOTE_HISTORY_SUCCESS'
export const DELETE_NOTE_HISTORY_FAILURE =
  'medicalHistory/DELETE_NOTE_HISTORY_FAILURE'

export const SAVE_ATTACHMENT_HISTORY_V2 =
  'medicalHistory/SAVE_ATTACHMENT_HISTORY_V2'
export const SAVE_ATTACHMENT_HISTORY_SUCCESS =
  'medicalHistory/SAVE_ATTACHMENT_HISTORY_SUCCESS'
export const SAVE_ATTACHMENT_HISTORY_FAILURE =
  'medicalHistory/SAVE_ATTACHMENT_HISTORY_FAILURE'

export const SAVE_MULTI_ATTACHMENT_HISTORY =
  'medicalHistory/SAVE_MULTI_ATTACHMENT_HISTORY'
export const SAVE_MULTI_ATTACHMENT_HISTORY_SUCCESS =
  'medicalHistory/SAVE_MULTI_ATTACHMENT_HISTORY_SUCCESS'
export const SAVE_MULTI_ATTACHMENT_HISTORY_FAILURE =
  'medicalHistory/SAVE_MULTI_ATTACHMENT_HISTORY_FAILURE'

export const SAVE_ATTACHMENT_HISTORY_DOCUMENTS =
  'medicalHistory/SAVE_ATTACHMENT_HISTORY_DOCUMENTS'
export const SAVE_ATTACHMENT_HISTORY_DOCUMENTS_SUCCESS =
  'medicalHistory/SAVE_ATTACHMENT_HISTORY_DOCUMENTS_SUCCESS'
export const SAVE_ATTACHMENT_HISTORY_DOCUMENTS_FAILURE =
  'medicalHistory/SAVE_ATTACHMENT_HISTORY_DOCUMENTS_FAILURE'

export const DELETE_ATTACHMENT_HISTORY =
  'medicalHistory/DELETE_ATTACHMENT_HISTORY'
export const DELETE_ATTACHMENT_HISTORY_SUCCESS =
  'medicalHistory/DELETE_ATTACHMENT_HISTORY_SUCCESS'
export const DELETE_ATTACHMENT_HISTORY_FAILURE =
  'medicalHistory/DELETE_ATTACHMENT_HISTORY_FAILURE'

export const CLEAR_MEDICAL_HISTORY_DIAGNOSES =
  'medicalHistory/CLEAR_MEDICAL_HISTORY_DIAGNOSES'
