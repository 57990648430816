import React from 'react'
import { TableCell } from '@mui/material'

export interface ExtendedPriceCellEstimateRangeProps {
  className?: string
}

const ExtendedPriceCellEstimateRange = ({
  className,
}: ExtendedPriceCellEstimateRangeProps) => <TableCell className={className} />

export default ExtendedPriceCellEstimateRange
