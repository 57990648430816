import React, { cloneElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, Hidden, IconButton, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType, Nil, Text } from '@pbt/pbt-ui-components'

import ChooseColumnsPopper from '../ChooseColumnsPopper'

const defaultGetUniqueId = R.prop('id')

export enum SelectMode {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
  DISABLED = 'disabled',
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      flex: 1,
      width: '100%',
      maxWidth: '100%',
    },
    tableContainer: {
      width: '100%',
      minHeight: 0,
    },
    expanded: {
      maxWidth: '58%',
      minWidth: '58%',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsed: {
      maxWidth: '0%',
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    headerButtonsCollapsed: {
      opacity: 0,
      pointerEvents: 'none',
      overflow: 'hidden',
      width: 0,
    },
    headerButtonsExpanded: {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        delay: theme.transitions.duration.leavingScreen,
      }),
    },
    headerTabs: {
      marginLeft: theme.spacing(3),
    },
    expanderContainer: {
      position: 'sticky',
      top: 0,
    },
    iconButton: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(3),
      '&:hover': {
        backgroundColor: 'inherit',
      },
      padding: 0,
    },
    icon: {
      color: theme.colors.iconColor,
      '&:hover': {
        color: theme.colors.iconHover,
      },
    },
    header: {
      minHeight: theme.spacing(8),
      padding: theme.spacing(0, 3),
    },
    headerText: {
      whiteSpace: 'nowrap',
    },
    headingSecondaryButton: {
      width: 'auto',
      cursor: 'pointer',
      userSelect: 'none',
    },
    headingButtonActive: {
      color: theme.colors.tabSelected,
    },
    arrow: {
      width: 16,
    },
  }),
  { name: 'ExpandableTable' },
)

export interface ExpandableTableProps {
  Expander?: React.JSXElementConstructor<any>
  NoItemsScreen?: React.JSXElementConstructor<any>
  NoItemsScreenProps?: any
  children: React.ReactElement
  classes?: ClassesType<typeof useStyles>
  getUniqueId?: (item: any) => string
  hasSelectedFilters?: boolean
  headerButtons?: React.ReactNode
  headerTabs?: React.ReactNode
  isLoading?: boolean
  item?: any
  itemId?: string
  list?: any[]
  onClose?: () => void
  onPrint?: () => void
  onSelectModeChange?: ((selectMode: SelectMode) => void) | Nil
  onSelected?: (id: string, item: any) => void
  possibleColumns?: string[]
  searchTerm?: string | Nil
  selectMode?: SelectMode
  selectedColumns?: string[]
  setSelectedColumns?: (selectedColumns: string[]) => void
  title?: React.ReactElement | string
}

const ExpandableTable = ({
  classes: classesProp,
  children,
  Expander,
  onSelected = R.F,
  onClose,
  itemId,
  item,
  headerButtons,
  headerTabs,
  title,
  getUniqueId = defaultGetUniqueId,
  list,
  NoItemsScreenProps,
  NoItemsScreen,
  isLoading,
  searchTerm,
  hasSelectedFilters,
  selectedColumns,
  setSelectedColumns,
  possibleColumns,
  selectMode,
  onPrint,
  onSelectModeChange,
}: ExpandableTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const columnsContainerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('Common')

  const [hadSelectedFilters, setHadSelectedFilters] =
    useState(hasSelectedFilters)
  const [columnsPopperOpen, setColumnsPopperOpen] = useState(false)

  useEffect(() => {
    setHadSelectedFilters(hasSelectedFilters)
  }, [hasSelectedFilters])

  const toggleColumnPopperOpen = () => setColumnsPopperOpen(!columnsPopperOpen)

  const onItemClick = (newItem: any) => {
    const id = getUniqueId(newItem)
    if (R.isNil(id)) {
      return
    }
    if (itemId === id) {
      onClose?.()
    } else {
      onSelected(id, newItem)
    }
  }

  const toggleBulkEdit = () => {
    if (onSelectModeChange) {
      const newSelectMode =
        selectMode === SelectMode.DISABLED
          ? SelectMode.INCLUDE
          : SelectMode.DISABLED
      onSelectModeChange(newSelectMode)
    }
  }

  const defaultTableProps = {
    onItemClick,
    selectedItemId: itemId,
    searchTerm,
    hasSelectedFilters,
    isLoading,
  }

  const tableProps = list ? { ...defaultTableProps, list } : defaultTableProps

  const showNoItems =
    list &&
    R.isEmpty(list) &&
    !isLoading &&
    !searchTerm &&
    !hasSelectedFilters &&
    !hadSelectedFilters &&
    NoItemsScreen

  return showNoItems ? (
    <NoItemsScreen {...(NoItemsScreenProps || {})} />
  ) : (
    // For chrome version 116 the nowrap prop is breaking components and slowing down a lot of places - RHAP-1427
    <Box display="flex" flex={1}>
      <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
        <Grid container item alignItems="center" className={classes.header}>
          {title && (
            <Grid item>
              {typeof title === 'string' ? (
                <Text className={classes.headerText} py={1} variant="h1">
                  {title}
                </Text>
              ) : (
                React.cloneElement(title, { className: classes.headerText })
              )}
            </Grid>
          )}
          {headerTabs && (
            <Grid item className={classes.headerTabs}>
              {headerTabs}
            </Grid>
          )}
          <Grid
            container
            item
            className={classNames({
              [classes.headerButtonsCollapsed]: Boolean(itemId),
              [classes.headerButtonsExpanded]: !itemId,
            })}
            flex={1}
            spacing={2}
            wrap="nowrap"
          >
            <Grid item xs>
              {headerButtons}
            </Grid>
            {possibleColumns && possibleColumns.length > 0 && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.headingSecondaryButton}
                ref={columnsContainerRef}
                wrap="nowrap"
                onClick={toggleColumnPopperOpen}
              >
                <Text underline variant="body2">
                  {t('Common:CHOOSE_COLUMNS')}
                </Text>
                {columnsPopperOpen ? (
                  <KeyboardArrowUp className={classes.arrow} />
                ) : (
                  <KeyboardArrowDown className={classes.arrow} />
                )}
                <ChooseColumnsPopper
                  anchorEl={columnsContainerRef.current}
                  open={columnsPopperOpen}
                  possibleColumns={possibleColumns}
                  value={selectedColumns}
                  onChange={({ value }) => {
                    if (setSelectedColumns) {
                      setSelectedColumns(value)
                    }
                  }}
                  onClose={(event) => {
                    if (
                      !event?.target ||
                      !columnsContainerRef.current?.contains(event.target)
                    ) {
                      setColumnsPopperOpen(false)
                    }
                  }}
                />
              </Grid>
            )}
            {onPrint && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.headingSecondaryButton}
                onClick={onPrint}
              >
                <Text underline variant="body2">
                  {t('Common:PRINT_ACTION')}
                </Text>
              </Grid>
            )}
            {onSelectModeChange && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.headingSecondaryButton}
                onClick={toggleBulkEdit}
              >
                <Text
                  underline
                  className={classNames({
                    [classes.headingButtonActive]:
                      selectMode !== SelectMode.DISABLED,
                  })}
                  variant="body2"
                >
                  {t('Common:BULK_EDIT')}
                </Text>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item md className={classes.tableContainer} flex={1} px={3} py={0}>
          {cloneElement(children, tableProps)}
        </Grid>
      </Box>
      <Hidden mdDown>
        <Box
          className={classNames(
            {
              [classes.expanded]: Boolean(itemId),
              [classes.collapsed]: !itemId || !Expander,
            },
            classes.expanderContainer,
          )}
          display="flex"
          flexDirection="column"
        >
          {itemId && (
            <Paper className={classes.paper}>
              {onClose && (
                <IconButton
                  className={classes.iconButton}
                  size="large"
                  onClick={onClose}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              )}
              {Expander && (
                <Expander
                  item={item}
                  itemId={itemId}
                  key={itemId}
                  onClose={onClose || R.F}
                />
              )}
            </Paper>
          )}
        </Box>
      </Hidden>
    </Box>
  )
}

export default ExpandableTable
