import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { AddButton, Nil, PermissionArea, Text } from '@pbt/pbt-ui-components'

import Spacer from '~/components/common/Spacer'
import DialogNames from '~/constants/DialogNames'
import {
  fetchVariationAdjustments,
  getAdjustmentsIsLoading,
  getAdjustmentsList,
  getMultipleAdjustments,
} from '~/store/duck/inventoryAdjustments'
import { getCRUDByArea } from '~/store/reducers/auth'
import { Variation } from '~/types'
import useDialog from '~/utils/useDialog'

import AdjustmentsTable from '../adjustments/AdjustmentsTable'

export interface VariationAdjustmentsSectionProps {
  onHandAmount: number | Nil
  packageTypeId: string
  perPackageAmount: number | Nil
  perPackageUnitsId: string
  variationId: string | Nil
  variationName: string
}

const VariationAdjustmentsSection = ({
  variationId,
  variationName,
  perPackageUnitsId,
  packageTypeId,
  perPackageAmount,
  onHandAmount,
}: VariationAdjustmentsSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])
  const [openAdjustmentDialog] = useDialog(DialogNames.ADJUSTMENT_DIALOG)

  const adjustmentIds = useSelector(getAdjustmentsList)
  const adjustments = useSelector(getMultipleAdjustments(adjustmentIds))
  const isLoading = useSelector(getAdjustmentsIsLoading)
  const permissions = useSelector(getCRUDByArea(PermissionArea.ADJUSTMENTS))

  useEffect(() => {
    if (variationId) {
      dispatch(fetchVariationAdjustments(variationId))
    }
  }, [variationId])

  const handleAdd = () => {
    openAdjustmentDialog({
      adjustment: {
        variation: {
          id: variationId,
          name: variationName,
          perPackageUnitsId,
          packageTypeId,
          perPackageAmount,
          onHandAmount,
        } as Variation,
      },
    })
  }

  return (
    <Grid container direction="column">
      {adjustments.length > 0 || isLoading ? (
        <AdjustmentsTable adjustments={adjustments} isLoading={isLoading} />
      ) : (
        <Text>
          {t('Admin:CATALOG.VARIATION_ADJUSTMENTS_SECTION.NO_ADJUSTMENTS')}
        </Text>
      )}
      <Spacer spacing={2} />
      {!isLoading && permissions.create && (
        <AddButton
          inline
          addText={t('Common:NEW_ADJUSTMENT')}
          onAdd={handleAdd}
        />
      )}
    </Grid>
  )
}

export default VariationAdjustmentsSection
