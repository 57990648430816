import React from 'react'
import { StateLabel, StateLabelProps } from '@pbt/pbt-ui-components'

export interface AccentLabelProps extends StateLabelProps {
  colorVariant?: string
  name?: string
}

// @TODO: consider this component as a replacement of StateLabel
//        now uses StateLabel under the hood and supplies handy colorValue interface
const AccentLabel = ({ name, colorVariant, ...rest }: AccentLabelProps) => (
  <StateLabel {...rest} {...(colorVariant ? { [colorVariant]: true } : {})}>
    {name}
  </StateLabel>
)

export default AccentLabel
