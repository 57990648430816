import React from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    hide: {
      display: 'none',
    },
  }),
  { name: 'RevisionViewer' },
)

export interface RevisionViewerProps {
  hide: boolean
  viewerContainerRef: React.RefObject<HTMLDivElement>
  viewerEditorElementRef: React.RefObject<HTMLDivElement>
  viewerSidebarContainerRef: React.RefObject<HTMLDivElement>
}

const RevisionViewer = ({
  hide,
  viewerContainerRef,
  viewerEditorElementRef,
  viewerSidebarContainerRef,
}: RevisionViewerProps) => {
  const classes = useStyles()

  return (
    <Grid
      className={classNames({ [classes.hide]: hide })}
      id="revision-viewer-container"
      ref={viewerContainerRef}
    >
      <Grid id="revision-viewer-editor" ref={viewerEditorElementRef} />
      <Grid id="revision-viewer-sidebar" ref={viewerSidebarContainerRef} />
    </Grid>
  )
}

export default RevisionViewer
