import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import {
  Defaults,
  LinkButton,
  moment,
  PuiCheckbox,
  Text,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import { Vaccination } from '~/types'

export interface VaccinationsListRowProps {
  checked?: boolean
  onCheck: (id: string) => void
  onNavigateToSoap?: (soapId: string) => void
  vaccination: Vaccination
}

const VaccinationsListRow = ({
  checked,
  vaccination,
  onCheck,
  onNavigateToSoap,
}: VaccinationsListRowProps) => {
  const { t } = useTranslation('Common')

  const { id, name, dateGiven, dueDate, soapId, businessId } = vaccination

  return (
    <Grid container item alignItems="center" pl={1.5}>
      <Grid container item alignItems="center" wrap="nowrap" xs={6}>
        <PuiCheckbox
          checked={checked}
          label={
            <Grid item>
              {name}
              <BusinessShareIcon businessIds={businessId ? [businessId] : []} />
            </Grid>
          }
          onChange={() => onCheck(id)}
        />
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={6}>
          {dateGiven && (
            <Text>{moment(dateGiven).format(Defaults.DATE_FORMAT)}</Text>
          )}
        </Grid>
        <Grid item xs={6}>
          {vaccination.dueDate && (
            <Text>{moment(dueDate).format(Defaults.DATE_FORMAT)}</Text>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={2}>
        {onNavigateToSoap && soapId && (
          <LinkButton onClick={() => onNavigateToSoap(soapId)}>
            {t('Common:VIEW_SOAP')}
          </LinkButton>
        )}
      </Grid>
    </Grid>
  )
}

export default VaccinationsListRow
