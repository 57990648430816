import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Utils } from '@pbt/pbt-ui-components'
import { BulkPrice as BulkPriceIcon } from '@pbt/pbt-ui-components/src/icons'

import { NotificationAreaNames } from '~/constants/notifications'
import {
  getNotificationArea,
  getNotificationType,
} from '~/store/reducers/constants'
import { Notification } from '~/types'

import TransportIcon from '../../communications/TransportIcon'
import TaskIcon from '../../tasks-dashboard/TaskIcon'

const useStyles = makeStyles(
  () => ({
    root: {
      width: 30,
    },
  }),
  { name: 'NotificationTypeIcon' },
)

const IconComponentByNotificationArea = {
  [NotificationAreaNames.COMMUNICATIONS]: TransportIcon,
  [NotificationAreaNames.TASKS]: TaskIcon,
  [NotificationAreaNames.BULK_PRICE_UPDATES]: BulkPriceIcon,
}

export interface NotificationTypeIconProps {
  notification: Notification | Nil
}

const NotificationTypeIcon = ({ notification }: NotificationTypeIconProps) => {
  const classes = useStyles()

  const NotificationAreas = useSelector(getNotificationArea)
  const NotificationTypes = useSelector(getNotificationType)

  const notificationsType = Utils.findById(
    notification?.typeId,
    NotificationTypes,
  )
  const notificationsArea = Utils.findById(
    notificationsType?.notificationAreaId,
    NotificationAreas,
  )

  const IconComponent = IconComponentByNotificationArea[notificationsArea?.name]
  const Icon = IconComponent ? (
    <IconComponent type={notification?.conversationTransport} />
  ) : null

  return (
    <Grid container className={classes.root} justifyContent="center">
      {Icon}
    </Grid>
  )
}
export default NotificationTypeIcon
