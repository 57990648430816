import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, ButtonWithLoaderProps } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  () => ({
    deleteButton: {
      width: 40,
      height: 40,
      minWidth: 40,
      padding: 0,
    },
  }),
  { name: 'DeleteButton' },
)

const DeleteButton = (props: ButtonWithLoaderProps) => {
  const classes = useStyles()

  return (
    <ButtonWithLoader
      className={classes.deleteButton}
      color="secondary"
      {...props}
    >
      <DeleteIcon />
    </ButtonWithLoader>
  )
}

export default DeleteButton
