import React, { createRef, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text, WellnessPlan, WellnessPlanVersion } from '@pbt/pbt-ui-components'

import { DataHandleWithUnsavedChanges } from '~/types'

import { getEnabledPlans } from '../wellnessPlanUtils'
import WellnessPlanLevelsDetailsSectionItem, {
  WellnessPlanLevelsDetailsSectionItemHandle,
} from './WellnessPlanLevelsDetailsSectionItem'

const useStyles = makeStyles(
  () => ({
    root: {},
    title: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'WellnessPlanLevelsDetailsSection' },
)

export interface WellnessPlanLevelsDetailsSectionHandle
  extends DataHandleWithUnsavedChanges<WellnessPlan[]> {}

interface WellnessPlanLevelsDetailsSectionProps {
  className?: string
  onDeletePlan: (plan: WellnessPlan) => void
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanLevelsDetailsSection = forwardRef<
  WellnessPlanLevelsDetailsSectionHandle,
  WellnessPlanLevelsDetailsSectionProps
>(function WellnessPlanLevelsDetailsSection(
  { className, wellnessPlanVersion, onDeletePlan },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const plans = getEnabledPlans(wellnessPlanVersion)

  const detailsSectionItemRefs = Array.from({ length: plans.length }, () =>
    createRef<WellnessPlanLevelsDetailsSectionItemHandle>(),
  )

  useImperativeHandle(ref, () => ({
    validate: () =>
      detailsSectionItemRefs.every((itemRef) => itemRef.current?.validate()),
    get: () =>
      detailsSectionItemRefs
        .map((itemRef) => itemRef.current?.get())
        .filter(Boolean) as WellnessPlan[],
    hasUnsavedChanges: () =>
      detailsSectionItemRefs.some((itemRef) =>
        itemRef.current?.hasUnsavedChanges(),
      ),
  }))

  return (
    <Grid container item className={classNames(className, classes.root)}>
      <Text className={classes.title} variant="h2">
        {t('Common:PLANS')}
      </Text>
      <Stack spacing={1}>
        {plans.map((wellnessPlan, index) => (
          <WellnessPlanLevelsDetailsSectionItem
            key={wellnessPlan.id}
            ref={detailsSectionItemRefs[index]}
            wellnessPlan={wellnessPlan}
            wellnessPlanVersion={wellnessPlanVersion}
            onDeletePlan={onDeletePlan}
          />
        ))}
      </Stack>
    </Grid>
  )
})

export default WellnessPlanLevelsDetailsSection
