import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { path } from 'ramda'
import { LanguageUtils, PuiSelect, Text, Utils } from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import { getDimensions } from '~/store/reducers/constants'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import LocationWithNotes from './LocationWithNotes'

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      marginBottom: theme.spacing(1),
      height: 44,
    },
    select: {
      borderBottom: '1px solid #7B7777',
    },
    innerFormControl: {
      '&:last-child': {
        marginBottom: theme.spacing(3),
      },
    },
    outerFormControl: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    selectInput: {
      fontSize: '1.4rem',
    },
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
  }),
  { name: 'FindingsView' },
)

const FindingsView = ({
  state: stateProp,
  subCategoryName,
  locations,
  dimensions,
  onChange,
  onLocationNotesSave,
  onLocationNotesChange,
  subCategoryId,
}) => {
  const classes = useStyles()
  const state = stateProp || {}
  const dimensionsMap = useSelector(getDimensions)
  const IconComponent = useCallback(
    () => <Toggle className={classes.toggleIcon} />,
    [],
  )
  const { t } = useTranslation('Soap')

  const renderDimensions = (dimensions, location = '') =>
    dimensions.map((dimension) => (
      <FormControl
        className={classNames(
          classes.formControl,
          location ? classes.innerFormControl : classes.outerFormControl,
        )}
        key={dimension}
      >
        <InputLabel htmlFor={`${dimension}-select`}>
          {LanguageUtils.capitalize(
            LanguageUtils.getTranslatedFieldName(dimensionsMap[dimension]),
          )}
        </InputLabel>
        <PuiSelect
          disableUnderline
          fullWidth
          IconComponent={IconComponent}
          className={classes.select}
          inputProps={{
            className: classes.selectInput,
            id: `${dimension}-select`,
          }}
          items={dimensionsMap[dimension].values}
          value={path([location, 'dimensions', dimension, 'value'])(state)}
          onChange={Utils.handleFormSelectInput((value) =>
            onChange({ location, dimension, dimensionValue: value }),
          )}
        />
      </FormControl>
    ))

  const [expandedState, setExpandedState] = useState({})
  useEffect(() => {
    if (!locations || !locations.length) {
      return
    }
    const overrides = locations.reduce(
      (acc, value) =>
        expandedState[value] && !state[value]
          ? {
              ...acc,
              [value]: false,
            }
          : acc,
      {},
    )
    setExpandedState({
      ...expandedState,
      ...overrides,
    })
  }, [state])

  const hasLocations = locations && locations.length > 0
  const defaultLocation = state[''] || {}
  return (
    <>
      <NotesTemplateInput
        hidePanel
        isSoap
        key={subCategoryId}
        maxHeight={332}
        title={t('Soap:FINDINGS_VIEW.NOTES_TEMPLATE_INPUT_TITLE', {
          subCategoryName,
        })}
        value={defaultLocation.notes || ''}
        onChange={onLocationNotesChange}
        onSave={(notes) => onLocationNotesSave(null, notes)}
      />
      {hasLocations ? (
        <Text strong p={1} variant="body2">
          {t('Soap:FINDINGS_VIEW.SELECT_SUBCATEGORY_LOCATION_ONE_OR_OTHER', {
            subCategoryName: subCategoryName.toLowerCase(),
          })}
        </Text>
      ) : null}
      {hasLocations
        ? locations.map((locationId) => (
            <LocationWithNotes
              dimensions={dimensions}
              expandedState={expandedState}
              key={locationId}
              locationId={locationId}
              renderDimensions={renderDimensions}
              setExpandedState={setExpandedState}
              state={state}
              subCategoryName={subCategoryName}
              onChange={onChange}
              onLocationNotesChange={onLocationNotesChange}
              onLocationNotesSave={onLocationNotesSave}
            />
          ))
        : null}
      {!hasLocations && dimensions && renderDimensions(dimensions)}
    </>
  )
}

export default FindingsView
