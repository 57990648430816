import { useEffect, useRef, useState } from 'react'
import * as R from 'ramda'
import {
  CustomClassicEditorType,
  PbtCKEditorUtils,
  PluginName,
} from '@pbt/pbt-ckeditor-ui'

import { convertNumberToPx } from '~/utils'

export interface UseCKEditorOnReadyProps {
  editorHeight: number
  editorId: string
  editorWidth?: string | number
  removePlugins?: PluginName[]
  setWillEditorRestart: (value: boolean) => void
  withRealTimeCollaboration: boolean
}

export const useOnEditorReady = ({
  editorId,
  editorHeight,
  editorWidth,
  removePlugins: removePluginsProp = [],
  setWillEditorRestart,
  withRealTimeCollaboration,
}: UseCKEditorOnReadyProps) => {
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [isLayoutReady, setIsLayoutReady] = useState(false)

  const editorRef = useRef<CustomClassicEditorType>(null!)

  const enabledCKEditorDebug = JSON.parse(
    localStorage?.getItem('enabledCKEditorDebug') || 'false',
  )

  const onEditorReady = (editor: CustomClassicEditorType) => {
    if (R.isNil(editor)) {
      return
    }

    setWillEditorRestart(false)

    editorRef.current = editor

    // Defines CKEditor height
    if (editorHeight) {
      PbtCKEditorUtils.setEditorStyle({
        propName: 'height',
        propValue: convertNumberToPx(editorHeight),
        editor,
      })
    }

    // Defines CKEditor width
    if (editorWidth) {
      PbtCKEditorUtils.setEditorStyle({
        propName: 'width',
        propValue: convertNumberToPx(editorWidth),
        editor,
      })
    }

    // Enable track changes by default
    if (withRealTimeCollaboration) {
      PbtCKEditorUtils.enableTrackChangesForCustomPlugins(
        editor,
        removePluginsProp,
      )
    }

    setIsEditorReady(true)

    // The official CKEditor 5 instance inspector. It helps understand the editor view and model.
    if (enabledCKEditorDebug) {
      import('@ckeditor/ckeditor5-inspector').then((CKEditorInspector) => {
        CKEditorInspector.default.attach(editor)
      })
    }
  }

  // This is important to avoid triggering an error on the Editor, as we're attaching refs to use some features,
  // such as PresenceList and Revision History
  useEffect(() => {
    setIsLayoutReady(true)
  }, [])

  // Reset editor state whenever the editorId changes
  useEffect(() => {
    setIsEditorReady(false)
  }, [editorId])

  useEffect(() => {
    if (editorHeight) {
      document.documentElement.style.setProperty(
        '--ck-editor-custom-height',
        convertNumberToPx(editorHeight),
      )
    }

    if (editorWidth) {
      document.documentElement.style.setProperty(
        '--ck-editor-custom-width',
        convertNumberToPx(editorWidth),
      )
    }
  }, [editorHeight, editorWidth])

  return { editorRef, isLayoutReady, isEditorReady, onEditorReady }
}
