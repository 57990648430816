import React from 'react'
import { LanguageUtils, StateLabelProps } from '@pbt/pbt-ui-components'

import { Status } from '~/components/elements/Status/Status'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'

interface ChewyRetailOrderStatusLabelProps extends StateLabelProps {
  statusId: string | undefined
}

export const ChewyRetailOrderStatusLabel = ({
  statusId,
  ...rest
}: ChewyRetailOrderStatusLabelProps) => {
  const { currentState } = useGetChewyOrderState(statusId)

  if (!statusId || !currentState) {
    return null
  }

  return (
    <Status flexShrink={0} variant={currentState.variant} {...rest}>
      {LanguageUtils.getTranslatedFieldName(currentState)}
    </Status>
  )
}
