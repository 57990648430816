import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import PetFriend, { PetFriendHandle } from './PetFriend'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'PetFriendDialog' },
)

interface PetFriendDialogProps extends BasePuiDialogProps {
  clientId: string
}

const PetFriendDialog = ({ open, clientId, onClose }: PetFriendDialogProps) => {
  const classes = useStyles()
  const petFriendRef = useRef<PetFriendHandle>(null)
  const { t } = useTranslation('Common')

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => petFriendRef.current?.save(),
      }}
      aria-labelledby="pet-friend-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        Boolean(petFriendRef.current?.getHasUnsavedChanges())
      }
      open={open}
      title={t('Common:NEW_PET_FRIEND')}
      onClose={onClose}
    >
      <PetFriend clientId={clientId} ref={petFriendRef} onOk={onClose} />
    </PuiDialog>
  )
}

export default PetFriendDialog
