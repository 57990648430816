import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { Collapse, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text, Utils } from '@pbt/pbt-ui-components'

import TaskMesh from '~/components/dashboard/tasks-dashboard/mesh/TaskMesh'
import { getEventState } from '~/store/reducers/constants'
import { getMultipleTasks } from '~/store/reducers/tasks'
import { WhiteboardTreatmentsSchedule } from '~/types'

import WhiteboardTaskRow from './WhiteboardTaskRow'

const useStyles = makeStyles(
  (theme) => ({
    iconButton: {
      padding: theme.spacing(0.5),
    },
    doneTaskRow: {
      '&:last-of-type': {
        borderBottom: theme.constants.tableBorder,
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    doneTasksContainer: {
      borderTop: theme.constants.tableBorder,
      backgroundColor: theme.colors.newLabel,
    },
    doneTasksContainerCollapsed: {
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'WhiteboardDoneTaskRow' },
)

export interface WhiteboardDoneTaskRowProps {
  headerScrollRef: React.RefObject<HTMLDivElement>
  schedule: WhiteboardTreatmentsSchedule
  scrollOffset?: number
  tasksScrollRefs: React.RefObject<HTMLDivElement>[]
  timeColumns: string[]
}

const WhiteboardDoneTaskRow = ({
  timeColumns,
  schedule,
  headerScrollRef,
  tasksScrollRefs,
  scrollOffset,
}: WhiteboardDoneTaskRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const tasks = useSelector(getMultipleTasks(schedule.tasks))
  const EventState = useSelector(getEventState)

  const [doneTasksExpanded, setDoneTasksExpanded] = useState(false)

  const DoneState = Utils.findConstantIdByName('Done', EventState)

  const doneTasks = tasks.filter(
    (task) => task?.aggregatedStateId === DoneState,
  )

  if (doneTasks.length === 0) {
    return null
  }

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.doneTasksContainer, {
          [classes.doneTasksContainerCollapsed]: !doneTasksExpanded,
        })}
      >
        <Grid item pl={4.5} xs={7}>
          <IconButton
            className={classes.iconButton}
            size="large"
            onClick={() => setDoneTasksExpanded(!doneTasksExpanded)}
          >
            {doneTasksExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          <Text inline strong variant="subheading3">
            {`${t('Common:COMPLETED')} (${doneTasks.length})`}
          </Text>
        </Grid>
        <TaskMesh
          columns={timeColumns}
          ref={headerScrollRef}
          scrollOffset={scrollOffset}
        />
      </Grid>
      <Collapse mountOnEnter unmountOnExit in={doneTasksExpanded}>
        {doneTasks.map((task, taskIndex) => (
          <WhiteboardTaskRow
            className={classes.doneTaskRow}
            key={task.id}
            scrollOffset={scrollOffset}
            scrollRef={tasksScrollRefs[taskIndex]}
            taskId={task.id}
            timeColumns={timeColumns}
          />
        ))}
      </Collapse>
    </>
  )
}

export default WhiteboardDoneTaskRow
