import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, InfiniteLoaderList } from '@pbt/pbt-ui-components'

import {
  fetchMoreShipmentImportItems,
  getCurrentSessionId,
  getImportItemsList,
  getMultipleImportItems,
  getTotalCount,
} from '~/store/duck/shipmentImport'

import type { OpenMatchDialogOptions } from '../ShipmentDialog'
import ShipmentImportTableRow from './ShipmentImportTableRow'

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      border: theme.constants.tableBorder,
      fontWeight: 500,
      fontSize: '1.4rem',
      color: theme.colors.tabLabel,
    },
    headingCell: {
      padding: theme.spacing(1, 2),
    },
    highlightCell: {
      borderRight: theme.constants.tableBorder,
    },
    editCell: {
      textAlign: 'end',
      padding: theme.spacing(1, 2, 1, 0),
    },
    table: {},
  }),
  { name: 'ShipmentImportTable' },
)

interface ShipmentImportTableProps {
  classes?: ClassesType<typeof useStyles>
  openMatchDialog: (options: OpenMatchDialogOptions) => void
}

const ShipmentImportTable = ({
  classes: classesProp,
  openMatchDialog,
}: ShipmentImportTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const importItemsList = useSelector(getImportItemsList)
  const importItems = useSelector(getMultipleImportItems(importItemsList))
  const totalCount = useSelector(getTotalCount)
  const sessionId = useSelector(getCurrentSessionId)

  useEffect(() => {
    if (sessionId) {
      dispatch(fetchMoreShipmentImportItems(sessionId, 0, 25))
    }
  }, [])

  const loadMoreItems = (from: number, to: number) => {
    if (sessionId) {
      dispatch(fetchMoreShipmentImportItems(sessionId, from, to))
    }
  }

  const isItemLoaded = (index: number) => Boolean(importItems[index])

  return (
    <Grid container className={classes.table} direction="column">
      <Grid container className={classes.tableRow}>
        <Grid
          item
          className={classNames(classes.headingCell, classes.highlightCell)}
          xs={5}
        >
          {t('Admin:CATALOG.SHIPMENT_IMPORT_TABLE.VET_COVE_COMPANY_ITEM')}
        </Grid>
        <Grid item className={classes.headingCell} xs={4}>
          {t('Admin:CATALOG.SHIPMENT_IMPORT_TABLE.MATCH_ITEM')}
        </Grid>
        <Grid item className={classes.headingCell} xs={2}>
          {t('Admin:CATALOG.SHIPMENT_IMPORT_TABLE.MATCH_REASON')}
        </Grid>
        <Grid item className={classes.editCell} xs={1}>
          {t('Admin:CATALOG.SHIPMENT_IMPORT_TABLE.EDIT_MATCH')}
        </Grid>
      </Grid>
      <InfiniteLoaderList
        isItemLoaded={isItemLoaded}
        itemCount={totalCount}
        itemData={importItems}
        itemSize={32}
        loadMoreItems={loadMoreItems}
        style={{ height: 288 }}
      >
        {(item) => (
          <ShipmentImportTableRow
            item={item}
            key={item?.lineId}
            openMatchDialog={openMatchDialog}
            sessionId={sessionId}
          />
        )}
      </InfiniteLoaderList>
    </Grid>
  )
}

export default ShipmentImportTable
