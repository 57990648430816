import React from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Question, Text } from '@pbt/pbt-ui-components'

import PuiPopup, { PuiPopupProps } from '~/components/common/PuiPopup'

const useStyles = makeStyles(
  () => ({
    container: {
      width: 386,
    },
    button: {
      width: 150,
      height: 40,
    },
  }),
  { name: 'ConfirmAnswerSwitchPopup' },
)

export interface ConfirmAnswerSwitchPopupProps extends PuiPopupProps {
  onProceed: () => void
  question: Question | Nil
}

const ConfirmAnswerSwitchPopup = ({
  question,
  onProceed,
  ...rest
}: ConfirmAnswerSwitchPopupProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const answers = question?.answers || []

  return (
    <PuiPopup {...rest}>
      <Grid
        container
        alignItems="center"
        className={classes.container}
        direction="column"
        pb={5}
        pt={6}
        px={3}
      >
        <Grid item>
          <Text align="center">
            {t('Admin:CATALOG.CONFIRM_ANSWER_SWITCH_POPUP.ARE_YOU_SURE')}
          </Text>
        </Grid>
        <Grid item mt={3}>
          <Text strong align="center">
            {t(
              'Admin:CATALOG.CONFIRM_ANSWER_SWITCH_POPUP.ALL_MULTIPLE_CHOICE_ANSWER',
              {
                answersLength: answers.length,
              },
            )}
          </Text>
        </Grid>
        <Grid item mt={3}>
          <Fab
            className={classes.button}
            color="inherit"
            type="submit"
            variant="extended"
            onClick={onProceed}
          >
            {t('Common:SWITCH_ACTION')}
          </Fab>
        </Grid>
      </Grid>
    </PuiPopup>
  )
}

export default ConfirmAnswerSwitchPopup
