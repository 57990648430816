import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { DateUtils, LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import i18n from '~/locales/i18n'
import { getLicenseTypes } from '~/store/reducers/constants'
import { TeamMemberLicense } from '~/types'

const useStyles = makeStyles(
  () => ({
    name: {
      whiteSpace: 'normal',
      fontSize: '1.4rem',
      maxWidth: 200,
    },
    nameContainer: {
      height: 40,
      width: 170,
    },
    tableCell: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'LicensesSectionTable' },
)

const headerRow = [
  { label: i18n.t('Common:EXPIRATION_DATE') },
  { label: i18n.t('Common:ISSUE_DATE') },
  { label: i18n.t('Common:RANK') },
  { label: i18n.t('Common:ISSUING_AUTHORITY') },
  { label: i18n.t('Common:ADDITIONAL_INFORMATION') },
]

export interface LicensesSectionTableProps
  extends Omit<
    ScrollableDataTableProps<TeamMemberLicense, false>,
    'data' | 'headerRow' | 'mainColumns'
  > {
  licenses?: TeamMemberLicense[]
}

const LicensesSectionTable = ({
  licenses: licensesProp,
  ...rest
}: LicensesSectionTableProps) => {
  const licenses = licensesProp || []
  const classes = useStyles()
  const LicenseTypes = useSelector(getLicenseTypes)
  const { t } = useTranslation(['Abbreviations', 'Admin', 'Common'])

  const getLicenseLabel = (license: TeamMemberLicense) => {
    const labelType = Utils.findById(license.typeId, LicenseTypes)
    const licenseTypeName = Utils.findByName('Other', LicenseTypes)?.name
    const labelName = labelType?.name
    const otherLabelName = license.type
      ? license.type
      : t('Abbreviations:COMMON.NOT_AVAILABLE')
    return labelName === licenseTypeName
      ? t('Admin:MEMBER.LICENSE.LICENSE_LABEL', { type: otherLabelName })
      : LanguageUtils.getTranslatedFieldName(labelType || {})
  }

  const mainColumns = [
    {
      label: i18n.t('Common:LICENSE_TYPE'),
      prop: getLicenseLabel,
      width: 170,
    },
    {
      label: i18n.t('Common:LICENSE_NUMBER'),
      prop: 'number' as keyof TeamMemberLicense,
      width: 170,
    },
  ]

  const formattedLicenses = !R.isEmpty(LicenseTypes)
    ? licenses.map((license) => ({
        ...license,
        licenseLabel: getLicenseLabel(license),
      }))
    : licenses

  if (licenses.length === 0) {
    return null
  }

  return (
    <ScrollableDataTable
      duplicateDisabled
      classes={classes}
      data={formattedLicenses}
      headerRow={headerRow}
      mainColumns={mainColumns}
      {...rest}
    >
      {({ item: license, tableCellClassName }) => (
        <>
          <TableCell className={tableCellClassName}>
            {DateUtils.formatDate(license.expirationDate)}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {DateUtils.formatDate(license.issueDate)}
          </TableCell>
          <TableCell className={tableCellClassName}>{license.rank}</TableCell>
          <TableCell className={tableCellClassName}>
            {license.issuingAuthority}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {license.additionalInfo}
          </TableCell>
        </>
      )}
    </ScrollableDataTable>
  )
}

export default LicensesSectionTable
