import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import * as R from 'ramda'

import { useIsFullscreenTask } from '~/store/hooks/tasks'
import { addSearch } from '~/utils'

import FullScreenTask from './FullScreenTask'
import TaskDetails from './TaskDetails'
import TasksTableComponent from './TasksTableComponent'

const TasksDashboardPage = () => {
  const navigate = useNavigate()
  const { taskId } = useParams()
  const location = useLocation()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isFullscreenTask = useIsFullscreenTask(taskId)

  const onDetailsClose = () => {
    navigate(addSearch(location, '/tasks-dashboard'))
  }

  const showFullScreenTask = isFullscreenTask && !R.isNil(taskId)
  const showMobileTask = !showFullScreenTask && isMobile && !R.isNil(taskId)
  const isExpandable = !showFullScreenTask && !showMobileTask

  return (
    <>
      {showFullScreenTask && (
        <FullScreenTask taskId={taskId} onDetailsClose={onDetailsClose} />
      )}
      {showMobileTask && (
        <TaskDetails itemId={taskId} onClose={onDetailsClose} />
      )}
      <Grid
        container
        display={showFullScreenTask || showMobileTask ? 'none' : undefined}
        flex={1}
        wrap="nowrap"
      >
        <TasksTableComponent
          isExpandable={isExpandable}
          taskId={taskId}
          onDetailsClose={onDetailsClose}
        />
      </Grid>
    </>
  )
}

export default TasksDashboardPage
