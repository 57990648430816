import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import { ShipmentItem, ShipmentTaxes } from '~/types'

import { getShipmentPriceInfo } from './shipment-item-utils'

interface ShipmentDialogPriceInfoProps {
  shipmentItems: ShipmentItem[] | undefined
  shipmentTaxes?: ShipmentTaxes
}

const ShipmentDialogPriceInfo = ({
  shipmentItems,
  shipmentTaxes,
}: ShipmentDialogPriceInfoProps) => {
  const { t } = useTranslation(['Common', 'Abbreviations'])

  const shippingAndHandling = shipmentTaxes?.shippingAndHandling
  const customTotalTax = shipmentTaxes?.customTotalTax

  const { total, subTotal, taxes } = getShipmentPriceInfo({
    shippingAndHandling,
    customTotalTax,
    items: shipmentItems || [],
  })

  const priceItems = [
    { label: t('Common:SUBTOTAL'), value: subTotal },
    {
      label: t('Abbreviations:COMMON.SHIPPING_AND_HANDLING'),
      value: shippingAndHandling,
    },
    { label: t('Common:TAXES'), value: taxes },
    { label: t('Common:TOTAL'), value: total },
  ].filter(Boolean) as { label: string; value: number }[]

  return (
    <Grid container columnSpacing={3}>
      {priceItems.map(({ label, value }) => (
        <Grid item key={label}>
          <Text variant="body">{`${label}:  ${NumberUtils.formatMoney(
            value,
          )}`}</Text>
        </Grid>
      ))}
    </Grid>
  )
}

export default ShipmentDialogPriceInfo
