import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, TextInteractive } from '@pbt/pbt-ui-components'

import { getSearchResult } from '~/store/reducers/problems'
import { Problem } from '~/types'

import ProblemsFindingOrDiagnosisIcon from '../identified-problems/ProblemsFindingOrDiagnosisIcon'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      cursor: 'pointer',
      '&:hover': {
        background: '#E5E5E5',
        boxShadow: theme.constants.selectItemSelectedShadow,
      },
    },
  }),
  { name: 'SearchFindingsRow' },
)

interface SearchFindingsRowProps {
  findingId: string
  onClick: (finding: Problem) => void
  searchTerm: string
}

const SearchFindingsRow = ({
  findingId,
  searchTerm,
  onClick,
}: SearchFindingsRowProps) => {
  const classes = useStyles()

  const finding = useSelector(getSearchResult(findingId))

  const breadcrumbLabel = LanguageUtils.getTranslatedFieldName(
    finding,
    'breadcrumb',
  )

  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      py={1}
      onClick={() => {
        if (finding) {
          onClick(finding)
        }
      }}
    >
      <Grid container item direction="row" wrap="nowrap" xs={4}>
        <Grid item ml={1.5} xs={1}>
          {finding?.type && (
            <ProblemsFindingOrDiagnosisIcon type={finding.type} />
          )}
        </Grid>
        <TextInteractive highlight={searchTerm} variant="body2">
          {LanguageUtils.getTranslatedFieldName(finding) || ''}
        </TextInteractive>
      </Grid>
      <Grid item xs={8}>
        <TextInteractive highlight={searchTerm} variant="body2">
          {breadcrumbLabel}
        </TextInteractive>
      </Grid>
    </Grid>
  )
}

export default SearchFindingsRow
