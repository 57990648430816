import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, CircularProgress, Grid, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddButton,
  ClassesType,
  Defaults,
  PermissionArea,
  PuiPopper,
  Text,
} from '@pbt/pbt-ui-components'

import ActionsPopper from '~/components/common/ActionsPopper'
import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import DialogNames from '~/constants/DialogNames'
import {
  fetchMorePatientEstimates,
  fetchPatientEstimates,
} from '~/store/actions/finance'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getFinanceIsFetching,
  getFinanceIsLoading,
  getPatientEstimatesTotalCount,
  getSortedPatientEstimates,
} from '~/store/reducers/finance'
import {
  getAppointmentId,
  getClientId,
  getIsCurrentContextSoap,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { Estimate, EstimateEvent } from '~/types'
import useDialog from '~/utils/useDialog'

import SoapWidget from '../SoapWidget'
import EyePopperRow from './table/EyePopperRow'
import { useEstimateItemActions } from './utils/useEstimateItemActions'
import { useEstimatesColumns } from './utils/useEstimatesColumns'

const ROW_HEIGHT = 40

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    cell: {
      borderRight: theme.constants.tableBorder,
      padding: theme.spacing(0, 0.5),
      minHeight: ROW_HEIGHT,
      overflow: 'hidden',
      fontSize: '1.4rem',
    },
    spacingCell: {
      padding: theme.spacing(0, 0.5),
      fontSize: '1.4rem',
    },
    paginationContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    paginationContainerDisabled: {
      opacity: '50%',
      borderBottom: theme.constants.tableBorder,
    },
    cellStatusLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    createdDateHeaderCell: {
      paddingLeft: theme.spacing(1.5),
    },
  }),
  { name: 'EstimatesWidget' },
)

interface EstimatesWidgetProps {
  classes?: ClassesType<typeof useStyles>
}

const EstimatesWidget = ({ classes: classesProp }: EstimatesWidgetProps) => {
  const classes = useStyles({ classes: classesProp })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const isFetching = useSelector(getFinanceIsFetching)
  const patientEstimatesTotalCount = useSelector(getPatientEstimatesTotalCount)
  const isLoading = useSelector(getFinanceIsLoading)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const soapId = useSelector(getSoapId)
  const appointmentId = useSelector(getAppointmentId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const estimates = useSelector(getSortedPatientEstimates(soapId))
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const [expanded, setExpanded] = useState(false)
  const hasMoreEstimatesToLoad = estimates.length <= patientEstimatesTotalCount
  const [actionsAnchorElement, setActionsAnchorElement] =
    useState<HTMLElement | null>(null)
  const [activeActionItem, setActiveActionItem] = useState<string | null>(null)
  const [eventsAnchorElement, setEventsAnchorElement] =
    useState<HTMLElement | null>(null)
  const activeEstimate = estimates.find(
    (estimate: Estimate) => estimate.id === activeActionItem,
  )

  const closePopper = (event: React.MouseEvent) => {
    if (
      !(eventsAnchorElement as HTMLAnchorElement)?.contains(
        event.target as Element,
      )
    ) {
      setActiveActionItem(null)
      setEventsAnchorElement(null)
    }
  }

  const navigateToTimelineRecords = () => {
    if (clientId && patientId) {
      navigate(`/client/${clientId}/patient/${patientId}/records`)
    }
  }

  useEffect(() => {
    if (patientId && soapId) {
      if (expanded && hasMoreEstimatesToLoad) {
        dispatch(
          fetchMorePatientEstimates(
            patientId,
            soapId,
            estimates.length,
            Defaults.MAX_ESTIMATES_TO_LOAD - estimates.length,
          ),
        )
      } else {
        dispatch(
          fetchPatientEstimates(
            patientId,
            soapId,
            0,
            Defaults.MIN_ESTIMATES_TO_LOAD,
          ),
        )
      }
    }
  }, [patientId, soapId, expanded])

  const { columns, mainColumn } = useEstimatesColumns({
    actionsAnchorElement,
    setActionsAnchorElement,
    setActiveActionItem,
    classes,
    activeActionItem,
  })

  const estimateActions = useEstimateItemActions({
    estimateId: activeActionItem,
    clientId,
    patientId,
    soapId,
    soapBusinessId,
  })

  const countLabel = `${estimates.length}/${patientEstimatesTotalCount}`
  const minViewportHeight =
    ROW_HEIGHT * Math.min(Defaults.MIN_ESTIMATES_TO_LOAD, estimates.length)

  if (!isCurrentContextSoap || !invoicePermissions.read) {
    return null
  }

  return (
    <SoapWidget id="estm_estimates-widget" title={t('Common:ESTIMATE_BUDGET')}>
      <PuiDataTable
        densed
        columns={columns}
        emptyPlaceholder={
          <Text align="center" m={2} variant="body2">
            {t('Soap:APPOINTMENT_ESTIMATES_LIST_LOCKED.NO_ESTIMATES')}
          </Text>
        }
        isLoading={isFetching || isLoading}
        items={estimates}
        loadMoreItems={() => {}}
        mainColumn={mainColumn}
        minViewPortHeight={minViewportHeight}
        rowHeight={ROW_HEIGHT}
        totalCount={estimates.length}
        // The maximum amount items shown will be Defaults.MAX_ESTIMATES_TO_LOAD - to check more than that
        // user can click on link to go to timeline
        viewportItems={
          expanded
            ? Math.min(Defaults.MAX_ESTIMATES_TO_LOAD, estimates.length)
            : Math.min(Defaults.MIN_ESTIMATES_TO_LOAD, estimates.length)
        }
      />
      <Box
        className={
          isFetching
            ? classes.paginationContainerDisabled
            : classes.paginationContainer
        }
        display="flex"
        flex={1}
        flexDirection="row"
        id="estm_less_more_button"
        justifyContent="space-between"
        px={2}
        py={0}
      >
        <Box
          alignItems="center"
          display="flex"
          sx={{ cursor: 'pointer' }}
          onClick={() => !isFetching && setExpanded(!expanded)}
        >
          <Text strong variant="body2">
            {expanded ? t('Common:LESS') : t('Common:MORE')}
          </Text>
          {expanded ? <ExpandLess /> : <ExpandMore />}
          {isFetching && <CircularProgress size={12} />}
        </Box>
        <Box alignItems="center" display="flex">
          <Text
            link={expanded}
            variant="body2"
            onClick={navigateToTimelineRecords}
          >
            {expanded
              ? `${countLabel} ${t('Common:VIEW_ALL_IN_TIMELINE')}`
              : `${countLabel}`}
          </Text>
        </Box>
      </Box>
      <Grid container id="estm_add_button" px={2} py={1}>
        <AddButton
          addText={t('Common:ADD_ESTIMATE_BUDGET')}
          onAdd={() =>
            openInvoice({
              clientId,
              create: true,
              patientId,
              isEstimate: true,
              invoiceId: null,
              soapId,
              eventId: appointmentId,
              soapBusinessId,
              newEstimateFlow: true,
            })
          }
        />
      </Grid>
      <ActionsPopper
        actions={estimateActions}
        anchorEl={actionsAnchorElement}
        disablePortal={false}
        id="estm_actions"
        onClose={() => {
          setActionsAnchorElement(null)
          setActiveActionItem(null)
        }}
      />
      {Boolean(eventsAnchorElement) && (
        <PuiPopper
          hideCloseButton
          anchorEl={eventsAnchorElement}
          disablePortal={false}
          id="estm_eye_popper"
          open={Boolean(eventsAnchorElement)}
          placement="bottom-start"
          onClose={closePopper}
        >
          <Paper>
            {activeEstimate?.events?.map((event: EstimateEvent) => (
              <EyePopperRow
                assignedSoapDetails={activeEstimate.assignedSoapDetails}
                event={event}
                key={event.id}
              />
            ))}
          </Paper>
        </PuiPopper>
      )}
    </SoapWidget>
  )
}

export default EstimatesWidget
