import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  Calendar,
  DateUtils,
  Field,
  moment,
  PuiTextField,
  Text,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { handleNumberInput } from '~/utils'

import PuiSwitch from '../PuiSwitch'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 330,
      maxWidth: 380,
    },
    container: {
      borderBottom: theme.constants.tableBorder,
    },
    optionLabel: {
      textTransform: 'capitalize',
    },
    button: {
      minWidth: 150,
    },
  }),
  { name: 'DateOfBirth' },
)

const ShiftTypes = {
  YEARS: 'years',
  MONTHS: 'month',
  WEEKS: 'weeks',
  DAYS: 'days',
}

const ApproximateAgeShiftDefault = {
  [ShiftTypes.YEARS]: '',
  [ShiftTypes.MONTHS]: '',
  [ShiftTypes.WEEKS]: '',
  [ShiftTypes.DAYS]: '',
}

const ShiftTypesLabel = {
  [ShiftTypes.YEARS]: i18n.t('Constants:TIME_UNITS.YEAR_OTHER').toLowerCase(),
  [ShiftTypes.MONTHS]: i18n.t('Constants:TIME_UNITS.MONTH_OTHER').toLowerCase(),
  [ShiftTypes.WEEKS]: i18n.t('Constants:TIME_UNITS.WEEK_OTHER').toLowerCase(),
  [ShiftTypes.DAYS]: i18n.t('Constants:TIME_UNITS.DAY_OTHER').toLowerCase(),
}

export interface DateOfBirthProps {
  approximateAge: Field
  dob: Field
  inline?: boolean
  onDone?: () => void
}

export interface DateOfBirthHandle {
  getValue: () => {
    approximateAge: string
    dob: string
  }
}

const DateOfBirth = forwardRef<DateOfBirthHandle, DateOfBirthProps>(
  function DateOfBirth(
    { approximateAge, dob, inline = false, onDone = R.F },
    ref,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'Constants', 'Clients'])

    const [approximateShift, setApproximateShift] = useState(
      ApproximateAgeShiftDefault,
    )

    const isApproximateShiftEmpty = R.isEmpty(
      R.filter(Boolean, approximateShift),
    )

    const projectedDOB = moment().subtract(approximateShift).toISOString()
    const approximateShiftKeys = Object.values(ShiftTypes)

    useEffect(() => {
      if (!isApproximateShiftEmpty) {
        approximateAge.setValue(true)
      }
    }, [approximateShift])

    useEffect(() => {
      if (!approximateAge.value) {
        setApproximateShift(ApproximateAgeShiftDefault)
      }
    }, [approximateAge.value])

    const handleApproximateShiftChange = (type: string) => (value: string) => {
      setApproximateShift({
        ...approximateShift,
        [type]: value,
      })
    }

    const handleDoneClick = () => {
      if (!isApproximateShiftEmpty) {
        dob.setValue(projectedDOB)
      }
      setApproximateShift(ApproximateAgeShiftDefault)
      onDone()
    }

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        dob:
          approximateAge.value && !isApproximateShiftEmpty
            ? projectedDOB
            : dob.value,
        approximateAge: approximateAge.value,
      }),
    }))

    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.container} px={1.5} py={1}>
          <Grid container>
            <Text strong variant="subheading3">
              {t('Clients:PATIENT_DATE_OF_BIRTH.ENTER_DATE_OF_BIRTH')}
            </Text>
          </Grid>
          <Grid container alignItems="center">
            <Grid item pr={1} xs={6}>
              <Calendar fullWidth utc field={dob} maxDate={moment()} />
            </Grid>
            <Grid item pl={1} xs={6}>
              <PuiSwitch
                field={approximateAge}
                label={t('Common:APPROXIMATE_AGE')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.container} px={1.5} py={1}>
          <Grid container>
            <Text strong variant="subheading3">
              {t('Clients:PATIENT_DATE_OF_BIRTH.OR_ENTER_APPROXIMATE_AGE')}
            </Text>
          </Grid>
          <Grid container>
            {approximateShiftKeys.map((key) => (
              <Grid
                container
                item
                alignItems="center"
                direction="row"
                key={key}
                wrap="nowrap"
                xs={6}
              >
                <Text className={classes.optionLabel}>
                  {ShiftTypesLabel[key]}:
                </Text>
                <Grid item mx={1}>
                  <PuiTextField
                    value={approximateShift[key]}
                    onChange={handleNumberInput(
                      handleApproximateShiftChange(key),
                      3,
                      0,
                      false,
                      false,
                    )}
                  />
                </Grid>
              </Grid>
            ))}
            {!isApproximateShiftEmpty && (
              <Grid container alignItems="center">
                <Grid item xs mt={1.25}>
                  <Text inline>
                    {t(
                      'Clients:PATIENT_DATE_OF_BIRTH.PROJECTED_DATE_OF_BIRTH',
                      {
                        projectedDOB: DateUtils.formatDate(projectedDOB),
                      },
                    )}
                  </Text>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!inline && (
          <Grid container px={1.5} py={1.5}>
            <Grid container alignItems="center">
              <Grid container item>
                <ButtonWithLoader
                  className={classes.button}
                  onClick={handleDoneClick}
                >
                  {t('Common:DONE')}
                </ButtonWithLoader>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  },
)

export default DateOfBirth
