import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FeatureToggle from '~/constants/featureToggle'
import { updateTimelineFilters } from '~/store/actions/timeline'
import { useLockableComponent } from '~/store/hooks/soap'
import { getFeatureToggle } from '~/store/reducers/constants'

import useTimelineItems from '../../clients/timeline/timelineItems'
import QuestionsListWidgetLocked from '../../soapV2/questions/QuestionsListWidgetLocked'
import QuestionsWidgetUnlocked from '../../soapV2/questions/QuestionsWidgetUnlocked'
import QuestionsList from './QuestionsList'

export interface QuestionsPageProps {
  onRailNavigation: (tab: number) => void
}

const QuestionsPage = ({ onRailNavigation }: QuestionsPageProps) => {
  const dispatch = useDispatch()
  const timelineItems = useTimelineItems()

  const isQuestionsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.QUESTIONS_WIDGET),
  )

  const QuestionsListComponent = useLockableComponent(
    isQuestionsWidgetEnabled ? QuestionsWidgetUnlocked : QuestionsList,
    QuestionsListWidgetLocked,
  )

  return (
    <QuestionsListComponent
      onViewTimelineRequested={() => {
        dispatch(updateTimelineFilters([timelineItems.question.type]))
        onRailNavigation(2)
      }}
    />
  )
}

export default QuestionsPage
