import React from 'react'
import { Grid } from '@mui/material'

import { MessageWay } from '~/constants/communications'

import MessageCard, {
  MessageCardProps,
  MessageVariant,
} from './message/MessageCard'

export interface ChatMessageProps extends MessageCardProps {}

const ChatMessage = ({
  message,
  variant = MessageVariant.PRIMARY,
  ...rest
}: ChatMessageProps) => {
  const isCurrentUserMessage = message?.way === MessageWay.OUTBOUND

  const isMini = variant === MessageVariant.MINI

  return (
    <Grid
      container
      justifyContent={isCurrentUserMessage ? 'flex-end' : 'flex-start'}
      px={isMini ? 2 : 8}
      py={1.5}
    >
      <MessageCard
        isCurrentUserMessage={isCurrentUserMessage}
        message={message}
        variant={variant}
        {...rest}
      />
    </Grid>
  )
}

export default ChatMessage
