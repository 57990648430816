import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, TextInteractive } from '@pbt/pbt-ui-components'

import EmailPreview from '~/components/common/emailPreview/EmailPreview'
import { ChatMessage } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
    },
    messageContent: {
      position: 'relative',
    },
    header: {
      borderBottom: theme.constants.tableBorder,
    },
    previewContainerWrapper: {
      overflowX: 'auto',
      justifyContent: 'flex-start',
    },
    shadowDomPreviewContainer: {
      width: 240,
      overflowWrap: 'break-word',
    },
  }),
  { name: 'MessageCardMini' },
)

export interface MessageCardMiniProps {
  isPlainTextMessage?: boolean
  message: ChatMessage
}

const MessageCardMini = ({
  isPlainTextMessage = false,
  message,
}: MessageCardMiniProps) => {
  const classes = useStyles()

  const isLoading = !message
  const { sendingDate, from, content, creator } = message

  const sendingTime = DateUtils.formatTime(sendingDate)
  const fromLabel = creator || from

  return (
    <Grid className={classes.root}>
      <Grid container className={classes.header} px={2} py={1}>
        <Grid container item justifyContent="space-between">
          <Dotdotdot clamp={1}>
            <TextInteractive strong isLoading={isLoading} variant="body2">
              {fromLabel}
            </TextInteractive>
          </Dotdotdot>
          <Dotdotdot clamp={1}>
            <TextInteractive isLoading={isLoading} variant="lowAccent2">
              {sendingTime}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
      </Grid>
      {content && (
        <Grid className={classes.messageContent} px={2} py={1}>
          <EmailPreview
            allowPointerEvents
            classes={{
              previewContainerWrapper: classes.previewContainerWrapper,
            }}
            disableHtmlRendering={isPlainTextMessage}
            emailPreviewShadowDomClasses={{
              previewContainer: classes.shadowDomPreviewContainer,
            }}
            emailTemplate={content}
            scale={1}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default MessageCardMini
