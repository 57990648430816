import React, { forwardRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AddButton, ClassesType } from '@pbt/pbt-ui-components'

import AddRow from './AddRow'

const useStyles = makeStyles(
  () => ({
    list: {
      overflowY: 'auto',
    },
    addRowContainer: {},
  }),
  { name: 'AddList' },
)

export type Item = {
  id: string
  label: string | undefined
  sublabel?: string
}

export interface AddListProps {
  addText: string
  classes?: ClassesType<typeof useStyles>
  getImage?: (id: string) => React.ReactNode
  items: Item[]
  onAdd?: () => void
  onDelete: (id: string) => void
  onEdit?: (id: string) => void
}

const AddList = forwardRef<HTMLDivElement, AddListProps>(function AddList(
  {
    classes: classesProp,
    items,
    onAdd,
    onDelete,
    onEdit,
    addText,
    getImage,
    ...rest
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      item
      className={classes.list}
      direction="column"
      ref={ref}
      rowSpacing={1}
      {...rest}
    >
      {Object.values(items).map(({ id, label, sublabel }) => (
        <Grid item key={id}>
          <AddRow
            ImageComponent={getImage ? () => getImage(id) : undefined}
            classes={{ root: classes.addRowContainer }}
            label={label}
            sublabel={sublabel}
            onDelete={onDelete ? () => onDelete(id) : undefined}
            onEdit={onEdit ? () => onEdit(id) : undefined}
          />
        </Grid>
      ))}
      {onAdd && (
        <Grid item>
          <AddButton addText={addText} variant="dashed" onAdd={onAdd} />
        </Grid>
      )}
    </Grid>
  )
})

export default AddList
