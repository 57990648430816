import React from 'react'
import { StateLabel, StateLabelProps } from '@pbt/pbt-ui-components'

import { LabOrdersStatus } from '~/constants/SOAPStates'

const StatusNameMapping = {
  [LabOrdersStatus.CANCELLED]: { disabled: true },
  [LabOrdersStatus.COMPLETED]: { completed: true },
  [LabOrdersStatus.CREATED]: { warning: true },
  [LabOrdersStatus.PARTIAL_RESULTS]: { normal: true },
  [LabOrdersStatus.PENDING]: { normal: true },
  [LabOrdersStatus.RESULTS_RECEIVED]: { success: true },
  [LabOrdersStatus.SUBMITTED]: { normal: true },
}

export interface OrderStateLabelProps extends StateLabelProps {
  orderStatus: string
}

export default function OrderStateLabel({
  orderStatus,
  children,
  ...rest
}: OrderStateLabelProps) {
  const labelProps = StatusNameMapping[orderStatus] || {}

  return (
    <StateLabel {...labelProps} {...rest}>
      {children}
    </StateLabel>
  )
}
