import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import {
  getFeatureToggle,
  getInventoryCategory,
} from '~/store/reducers/constants'
import { Order } from '~/types'

import {
  getInventoryItemCategoryId,
  isBundle,
  isObsoleteGlobalItem,
  isReminder,
  isWellnessPlanItem,
} from './orderUtils'

const useGetIsCatalogSelectableItem = () => {
  const InventoryCategory = useSelector(getInventoryCategory)
  const isObsoleteEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OBSOLETE_GLOBAL_INVENTORY_ITEM_VARIATION),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const drugId = Utils.findConstantIdByName(
    InventoryCategoryName.DRUG,
    InventoryCategory,
  )
  const vetDietId = Utils.findConstantIdByName(
    InventoryCategoryName.VET_DIET,
    InventoryCategory,
  )

  return (order: Order) =>
    Boolean(order.price) ||
    isBundle(order) ||
    isReminder(order) ||
    isWellnessPlanItem(order) ||
    ([drugId, vetDietId].includes(
      getInventoryItemCategoryId(order, isFoodCatalogEnabled),
    ) &&
      (isObsoleteEnabled
        ? !isObsoleteGlobalItem(order, isFoodCatalogEnabled)
        : true))
}
export default useGetIsCatalogSelectableItem
