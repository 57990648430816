import { Task as SagaTask } from '@redux-saga/types'
import * as R from 'ramda'
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, Constant, Defaults, Utils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ConversationCategory, DraftFilter } from '~/constants/communications'
import { NotificationAreaNames } from '~/constants/notifications'
import { Conversation, TableFilter } from '~/types'
import { cleanFilesToSend } from '~/utils'
import {
  getStatusFilterFromList,
  getStatusFilterList,
  isCommunicationValidationError,
} from '~/utils/communicationsUtils'

import {
  fetchAllConversationFiles,
  updateConversationFiles,
  updateConversationMessages,
} from '../actions/conversationMessages'
import {
  createConversation,
  createConversationFailure,
  createConversationSuccess,
  createConversationValidationFailure,
  deleteConversation,
  deleteConversationFailure,
  deleteConversationSuccess,
  editConversationFailure,
  editConversationSuccess,
  fetchClientConversationsList,
  fetchClientConversationsListFailure,
  fetchClientConversationsListSuccess,
  fetchConversation,
  fetchConversationEmailPreview,
  fetchConversationEmailPreviewFailure,
  fetchConversationEmailPreviewSuccess,
  fetchConversationFailure,
  fetchConversationMessagesList,
  fetchConversationMessagesListFailure,
  fetchConversationMessagesListSuccess,
  fetchConversationsList,
  fetchConversationsListFailure,
  fetchConversationsListSuccess,
  fetchConversationSuccess,
  fetchMoreItemsForClientConversationsList,
  fetchMoreItemsForClientConversationsListFailure,
  fetchMoreItemsForClientConversationsListSuccess,
  fetchMoreItemsForConversationMessagesList,
  fetchMoreItemsForConversationMessagesListFailure,
  fetchMoreItemsForConversationMessagesListSuccess,
  fetchMoreItemsForConversationsList,
  fetchMoreItemsForConversationsListFailure,
  fetchMoreItemsForConversationsListSuccess,
  setIsReadForConversation,
  updateConversationRecipientContext,
} from '../actions/conversations'
import {
  newWsNotification,
  newWsNotificationFailure,
  updateNotificationsLinkedToAreaListItemMap,
} from '../actions/notifications'
import {
  EMAIL_CHARGE_SHEET_FAILURE,
  EMAIL_CHARGE_SHEET_SUCCESS,
  EMAIL_INVOICE_FAILURE,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_LAB_RESULT_FAILURE,
  EMAIL_LAB_RESULT_SUCCESS,
  EMAIL_MED_HISTORY_FAILURE,
  EMAIL_MED_HISTORY_SUCCESS,
  EMAIL_PAYMENT_FAILURE,
  EMAIL_PAYMENT_SUCCESS,
  EMAIL_PRESCRIPTION_FAILURE,
  EMAIL_PRESCRIPTION_SUCCESS,
  EMAIL_REPORT_CARD_FAILURE,
  EMAIL_REPORT_CARD_SUCCESS,
  SEND_MEMBERSHIP_HYBRID_INVITE_FAILURE,
  SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS,
} from '../actions/types/communications'
import {
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION,
  EDIT_CONVERSATION,
  FETCH_CLIENT_CONVERSATIONS_LIST,
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_EMAIL_PREVIEW,
  FETCH_CONVERSATION_MESSAGES_LIST,
  FETCH_CONVERSATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST,
  SET_ASSIGNEE_FOR_CONVERSATION,
  SET_IS_READ_FOR_CONVERSATION,
  TOGGLE_ARCHIVE_FOR_CONVERSATION,
  UPDATE_CONVERSATION_RECIPIENT_CONTEXT,
} from '../actions/types/conversations'
import { NEW_WS_NOTIFICATION } from '../actions/types/notifications'
import {
  SEND_DEFAULT_WELCOME_EMAIL_FAILURE,
  SEND_WELCOME_EMAIL_FAILURE,
} from '../actions/types/wellnessPlans'
import {
  EMAIL_APPOINTMENT_CONFIRMATION_FAILURE,
  EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS,
  EMAIL_APPOINTMENT_FAILURE,
  EMAIL_APPOINTMENT_SUCCESS,
  EMAIL_ZOOM_LINK_FAILURE,
  EMAIL_ZOOM_LINK_SUCCESS,
} from '../duck/emailAppointment'
import { finishLoading, startLoading } from '../duck/progress'
import { getNotificationArea } from '../reducers/constants'
import {
  getConversationById,
  getConversationsClientId,
  getConversationShouldUpdate,
  getConversationsIsUpdating,
  getConversationsListFilters,
} from '../reducers/conversations'
import { getNotificationIdsForLinkedItemId } from '../reducers/notifications'
import {
  updateNotificationsAndTotalCountsForArea,
  updateNotificationsForArea,
  // @ts-ignore
} from './utils/notifications'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const MAIN_CONVERSATION_EDIT_ACTIONS = [
  SET_ASSIGNEE_FOR_CONVERSATION,
  TOGGLE_ARCHIVE_FOR_CONVERSATION,
  EDIT_CONVERSATION,
  UPDATE_CONVERSATION_RECIPIENT_CONTEXT,
]

const CANCELLABLE_CONVERSATION_EDIT_ACTIONS = [
  ...MAIN_CONVERSATION_EDIT_ACTIONS,
  SET_IS_READ_FOR_CONVERSATION,
]

export const CONVERSATION_CREATE_FAILURE_ACTIONS = [
  EMAIL_ZOOM_LINK_FAILURE,
  EMAIL_APPOINTMENT_CONFIRMATION_FAILURE,
  EMAIL_APPOINTMENT_FAILURE,
  SEND_DEFAULT_WELCOME_EMAIL_FAILURE,
  SEND_WELCOME_EMAIL_FAILURE,
  SEND_MEMBERSHIP_HYBRID_INVITE_FAILURE,
  EMAIL_INVOICE_FAILURE,
  EMAIL_CHARGE_SHEET_FAILURE,
  EMAIL_LAB_RESULT_FAILURE,
  EMAIL_MED_HISTORY_FAILURE,
  EMAIL_PAYMENT_FAILURE,
  EMAIL_PRESCRIPTION_FAILURE,
  EMAIL_REPORT_CARD_FAILURE,
]

function* getFilters() {
  const filters: Record<string, TableFilter> = yield select(
    getConversationsListFilters,
  )
  const [dateFrom, dateTo] = (filters.lastMessageDate?.value || []) as string[]
  const assignee = filters.assignee?.value
  const type = filters.type?.value
  const category = filters.category?.value

  const status = filters.status?.value
    ? getStatusFilterFromList(getStatusFilterList(filters.status))
    : undefined

  const draftFilter =
    category === ConversationCategory.DRAFT
      ? DraftFilter.DRAFT
      : DraftFilter.ALL
  const categoryFilter =
    category === ConversationCategory.DRAFT ? null : category

  return [assignee, type, status, dateFrom, dateTo, categoryFilter, draftFilter]
}

export function* conversationCreateFailureActionsSaga({
  error,
}: {
  error: ApiError
  type: string
}) {
  if (isCommunicationValidationError(error)) {
    yield put(createConversationValidationFailure(error))
  } else {
    yield put(createConversationFailure(error))
  }
}

export function* fetchConversationCancellable({
  conversationId,
}: ReturnType<typeof fetchConversation>) {
  try {
    const oldConversationRecord: Conversation = yield select(
      getConversationById(conversationId),
    )

    const { entities } = yield* requestAPI(
      API.fetchConversation,
      conversationId,
    )

    yield call(updateNotificationsForArea, {
      notificatedEntities: entities.conversations,
      notificationAreaName: NotificationAreaNames.COMMUNICATIONS,
    })
    yield call(updateEntities, entities)

    const freshConversation = entities.conversations[conversationId] || {}
    const shouldFetchMessages = R.any(
      (prop) =>
        !R.eqProps(prop, oldConversationRecord || {}, freshConversation),
      ['lastMessageUpdateDate', 'lastMessageDate'],
    )

    if (shouldFetchMessages) {
      yield put(fetchAllConversationFiles(conversationId))
      yield put(fetchConversationMessagesList(0, 10, conversationId))
    }
    yield put(fetchConversationSuccess(conversationId))
  } catch (error) {
    yield put(fetchConversationFailure(error as ApiError))
  }
}

// When we make an optimistic update for the conversation we need to cancel all background sync activities
// with a conversation so that when sync completes it won't rewind all changes
export function* fetchConversationSaga(
  params: ReturnType<typeof fetchConversation>,
) {
  const isConversationUpdating: boolean = yield select(
    getConversationsIsUpdating,
  )
  if (isConversationUpdating) {
    return
  }
  const task: SagaTask = yield fork(fetchConversationCancellable, params)
  yield take(CANCELLABLE_CONVERSATION_EDIT_ACTIONS)
  yield cancel(task)
}

export function* fetchConversationsListSaga({
  from,
  to,
}: ReturnType<typeof fetchConversationsList>) {
  try {
    const filters: any[] = yield call(getFilters)
    yield put(startLoading('conversations'))

    const { result, entities } = yield* requestAPI(
      API.fetchClientConversations,
      null,
      from,
      to,
      ...filters,
    )

    const { data: list, totalCount, unreadNotificationsTotalCount } = result

    yield call(updateNotificationsAndTotalCountsForArea, {
      notificatedEntities: entities.conversations,
      unreadNotificationsTotalCount,
      notificationAreaName: NotificationAreaNames.COMMUNICATIONS,
    })

    yield call(updateEntities, entities)
    yield put(fetchConversationsListSuccess(list, totalCount))
    yield put(finishLoading('conversations'))
  } catch (error) {
    yield put(fetchConversationsListFailure(error as ApiError))
    yield put(finishLoading('conversations'))
  }
}

export function* fetchClientConversationsListSaga({
  clientId,
  from,
  to,
}: ReturnType<typeof fetchClientConversationsList>) {
  try {
    const filters: any[] = yield call(getFilters)

    yield put(startLoading('conversations'))
    const { result, entities } = yield* requestAPI(
      API.fetchClientConversations,
      clientId,
      from,
      to,
      ...filters,
    )

    const { data: list, totalCount, unreadNotificationsTotalCount } = result

    yield call(updateNotificationsAndTotalCountsForArea, {
      notificatedEntities: entities.conversations,
      unreadNotificationsTotalCount,
      notificationAreaName: NotificationAreaNames.COMMUNICATIONS,
    })

    yield call(updateEntities, entities)
    yield put(fetchClientConversationsListSuccess(clientId, list, totalCount))
    yield put(finishLoading('conversations'))
  } catch (error) {
    yield put(fetchClientConversationsListFailure(error as ApiError))
    yield put(finishLoading('conversations'))
  }
}

export function* fetchMoreItemsForConversationsListSaga({
  from,
  to,
}: ReturnType<typeof fetchMoreItemsForConversationsList>) {
  try {
    const filters: any[] = yield call(getFilters)

    const { result, entities } = yield* requestAPI(
      API.fetchClientConversations,
      null,
      from,
      to,
      ...filters,
    )

    const { data: list, totalCount, unreadNotificationsTotalCount } = result

    yield call(updateNotificationsAndTotalCountsForArea, {
      notificatedEntities: entities.conversations,
      unreadNotificationsTotalCount,
      notificationAreaName: NotificationAreaNames.COMMUNICATIONS,
    })

    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForConversationsListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForConversationsListFailure(error as ApiError))
  }
}

export function* fetchMoreItemsForClientConversationsListSaga({
  clientId,
  from,
  to,
}: ReturnType<typeof fetchMoreItemsForClientConversationsList>) {
  try {
    const filters: any[] = yield call(getFilters)

    const { result, entities } = yield* requestAPI(
      API.fetchClientConversations,
      clientId,
      from,
      to,
      ...filters,
    )

    const { data: list, totalCount, unreadNotificationsTotalCount } = result

    yield call(updateNotificationsAndTotalCountsForArea, {
      notificatedEntities: entities.conversations,
      unreadNotificationsTotalCount,
      notificationAreaName: NotificationAreaNames.COMMUNICATIONS,
    })

    yield call(updateEntities, entities)
    yield put(
      fetchMoreItemsForClientConversationsListSuccess(
        clientId,
        list,
        totalCount,
        from,
      ),
    )
  } catch (error) {
    yield put(
      fetchMoreItemsForClientConversationsListFailure(error as ApiError),
    )
  }
}

export function* fetchConversationMessagesListSaga({
  from,
  to,
  conversationId,
}: ReturnType<typeof fetchConversationMessagesList>) {
  try {
    yield put(startLoading('conversationMessages'))
    const {
      result,
      entities: { messages, files },
    } = yield* requestAPI(
      API.fetchMessagesForConversation,
      conversationId,
      from,
      to,
    )

    const { data: list, totalCount } = result

    yield put(updateConversationMessages(messages))
    yield put(updateConversationFiles(files, conversationId))
    yield put(
      fetchConversationMessagesListSuccess(conversationId, list, totalCount),
    )
    yield put(finishLoading('conversationMessages'))
  } catch (error) {
    yield put(fetchConversationMessagesListFailure(error as ApiError))
    yield put(finishLoading('conversationMessages'))
  }
}

export function* fetchMoreItemsForConversationMessagesListSaga({
  from,
  to,
  conversationId,
}: ReturnType<typeof fetchMoreItemsForConversationMessagesList>) {
  try {
    const {
      result,
      entities: { messages, files },
    } = yield* requestAPI(
      API.fetchMessagesForConversation,
      conversationId,
      from,
      to,
    )

    const { data: list, totalCount } = result

    yield put(updateConversationMessages(messages))
    yield put(updateConversationFiles(files, conversationId))
    yield put(
      fetchMoreItemsForConversationMessagesListSuccess(
        conversationId,
        list,
        totalCount,
      ),
    )
  } catch (error) {
    yield put(
      fetchMoreItemsForConversationMessagesListFailure(error as ApiError),
    )
  }
}

export function* createConversationSaga({
  type,
  conversation,
  message,
}: ReturnType<typeof createConversation>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.createConversation,
      {
        ...conversation,
        message: {
          ...message,
          files: cleanFilesToSend(message.files),
        },
      },
    )

    yield call(updateEntities, entities)
    yield put(createConversationSuccess(conversationsList))
  } catch (e) {
    const error = e as ApiError
    yield conversationCreateFailureActionsSaga({ error, type })
  }
}

export function* editConversationSaga({
  conversationId,
}: ReturnType<typeof setIsReadForConversation>) {
  const shouldUpdateConversation: boolean = yield select(
    getConversationShouldUpdate,
  )
  if (!shouldUpdateConversation) {
    return
  }

  yield delay(Defaults.DEBOUNCE_ACTION_TIME)

  try {
    const conversation: Conversation = yield select(
      getConversationById(conversationId),
    )
    if (!conversation) {
      return
    }

    const { assigneeId, title, state, eventId } = conversation

    const { entities } = yield* requestAPI(
      API.updateConversation,
      conversationId,
      {
        assigneeId,
        title,
        status: state,
        state,
        eventId,
      },
    )

    yield call(updateEntities, entities)

    yield put(editConversationSuccess())
  } catch (error) {
    yield put(editConversationFailure(error as ApiError))
  }
}

export function* editConversationStatusSaga({
  conversationId,
}: ReturnType<typeof setIsReadForConversation>) {
  const shouldUpdateConversation: boolean = yield select(
    getConversationShouldUpdate,
  )
  if (!shouldUpdateConversation) {
    return
  }

  try {
    const conversation: Conversation = yield select(
      getConversationById(conversationId),
    )

    const { state } = conversation

    const { entities } = yield* requestAPI(
      API.updateConversation,
      conversationId,
      {
        state,
      },
    )

    yield call(updateEntities, entities)
    yield put(editConversationSuccess())
  } catch (error) {
    yield put(editConversationFailure(error as ApiError))
  }
}

export function* deleteConversationSaga({
  conversationId,
}: ReturnType<typeof deleteConversation>) {
  try {
    yield* requestAPI(API.deleteConversation, conversationId)
    yield put(deleteConversationSuccess(conversationId))
  } catch (error) {
    yield put(deleteConversationFailure(error as ApiError))
  }
}

export function* fetchConversationEmailPreviewSaga({
  config,
}: ReturnType<typeof fetchConversationEmailPreview>) {
  try {
    const emailHtmlTemplate = yield* requestAPI(
      API.fetchConversationEmailPreview,
      config,
    )
    yield put(fetchConversationEmailPreviewSuccess(emailHtmlTemplate))
  } catch (error) {
    yield put(fetchConversationEmailPreviewFailure(error as ApiError))
  }
}

export function* updateConversationRecipientContextSaga({
  conversationId,
  origin,
}: ReturnType<typeof updateConversationRecipientContext>) {
  try {
    const { entities } = yield requestAPI(
      API.updateConversation,
      conversationId,
      {
        clientId: origin.clientId,
        patientId: origin.patientId,
      },
    )

    yield call(updateEntities, entities)

    yield put(editConversationSuccess())
  } catch (error) {
    yield put(editConversationFailure(error as ApiError))
  }
}

export function* fetchConversationListAfterConversationCreationSaga({
  conversationsList = [],
}: {
  conversationsList: string[]
  type: string
}) {
  if (!conversationsList.length) {
    return
  }

  const clientId: string = yield select(getConversationsClientId)
  if (clientId) {
    yield fetchClientConversationsListSaga({
      type: FETCH_CLIENT_CONVERSATIONS_LIST,
      clientId,
      from: 0,
      to: Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
    })
  } else {
    yield fetchConversationsListSaga({
      type: FETCH_CONVERSATIONS_LIST,
      from: 0,
      to: Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
    })
  }
}

export function* wsNewNotificationSaga({
  body,
}: ReturnType<typeof newWsNotification>) {
  try {
    const { entities, result } = body
    const { notification: notificationId } = result

    const notification = entities?.notificationItem?.[notificationId] || {}

    if (notification.conversationId) {
      const NotificationAreas: Constant[] = yield select(getNotificationArea)
      const areaId = Utils.findConstantIdByName(
        NotificationAreaNames.COMMUNICATIONS,
        NotificationAreas,
      )
      const notificationIds: string[] = yield select(
        getNotificationIdsForLinkedItemId(areaId, notification.conversationId),
      )

      const notificationIdsByLinkedItemId = {
        [notification.conversationId]: [...notificationIds, notification.id],
      }
      yield put(
        updateNotificationsLinkedToAreaListItemMap(
          notificationIdsByLinkedItemId,
          areaId,
        ),
      )
    }
  } catch (error) {
    yield put(newWsNotificationFailure(error as ApiError))
  }
}

function* watchFetchConversation() {
  yield takeEvery(FETCH_CONVERSATION, fetchConversationSaga)
}

function* watchFetchConversationsList() {
  yield takeLatest(FETCH_CONVERSATIONS_LIST, fetchConversationsListSaga)
}

function* watchFetchClientConversationsList() {
  yield takeLatest(
    FETCH_CLIENT_CONVERSATIONS_LIST,
    fetchClientConversationsListSaga,
  )
}

function* watchFetchMoreItemsForConversationsList() {
  yield takeEvery(
    FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST,
    fetchMoreItemsForConversationsListSaga,
  )
}

function* watchFetchMoreItemsForClientConversationsList() {
  yield takeEvery(
    FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST,
    fetchMoreItemsForClientConversationsListSaga,
  )
}

function* watchFetchConversationMessagesList() {
  yield takeLeading(
    FETCH_CONVERSATION_MESSAGES_LIST,
    fetchConversationMessagesListSaga,
  )
}

function* watchFetchMoreItemsForConversationMessagesList() {
  yield takeEvery(
    FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST,
    fetchMoreItemsForConversationMessagesListSaga,
  )
}

function* watchCreateConversation() {
  yield takeLeading(CREATE_CONVERSATION, createConversationSaga)
}

function* watchConversationUpdate() {
  yield takeLatest(MAIN_CONVERSATION_EDIT_ACTIONS, editConversationSaga)
}

function* watchConversationStatusUpdate() {
  yield takeLatest(SET_IS_READ_FOR_CONVERSATION, editConversationStatusSaga)
}

function* watchFetchConversationEmailTemplate() {
  yield takeLatest(
    FETCH_CONVERSATION_EMAIL_PREVIEW,
    fetchConversationEmailPreviewSaga,
  )
}

function* watchDeleteConversation() {
  yield takeLatest(DELETE_CONVERSATION, deleteConversationSaga)
}

function* watchUpdateConversationRecipientContext() {
  yield takeLatest(
    UPDATE_CONVERSATION_RECIPIENT_CONTEXT,
    updateConversationRecipientContextSaga,
  )
}

function* watchWsNewNotification() {
  yield takeEvery(NEW_WS_NOTIFICATION, wsNewNotificationSaga)
}

function* watchConversationCreationSuccess() {
  yield takeLatest(
    [
      EMAIL_ZOOM_LINK_SUCCESS,
      EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS,
      EMAIL_APPOINTMENT_SUCCESS,
      SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS,
      CREATE_CONVERSATION_SUCCESS,
      EMAIL_INVOICE_SUCCESS,
      EMAIL_CHARGE_SHEET_SUCCESS,
      EMAIL_REPORT_CARD_SUCCESS,
      EMAIL_PAYMENT_SUCCESS,
      EMAIL_PRESCRIPTION_SUCCESS,
      EMAIL_LAB_RESULT_SUCCESS,
      EMAIL_MED_HISTORY_SUCCESS,
      CREATE_CONVERSATION_SUCCESS,
    ],
    fetchConversationListAfterConversationCreationSaga,
  )
}

function* watchConversationCreateFailureActions() {
  yield takeLatest(
    CONVERSATION_CREATE_FAILURE_ACTIONS,
    conversationCreateFailureActionsSaga,
  )
}

export default function* conversationsSaga() {
  yield all([
    watchFetchConversation(),
    watchFetchConversationsList(),
    watchFetchClientConversationsList(),
    watchFetchMoreItemsForConversationsList(),
    watchFetchMoreItemsForClientConversationsList(),
    watchFetchConversationMessagesList(),
    watchFetchMoreItemsForConversationMessagesList(),
    watchCreateConversation(),
    watchConversationUpdate(),
    watchConversationStatusUpdate(),
    watchFetchConversationEmailTemplate(),
    watchDeleteConversation(),
    watchUpdateConversationRecipientContext(),
    watchConversationCreationSuccess(),
    watchConversationCreateFailureActions(),
    watchWsNewNotification(),
  ])
}
