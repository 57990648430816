/* eslint-disable react/no-multi-comp */
import React, { forwardRef, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  Checkbox,
  Divider,
  Grid,
  IconProps as MuiIconProps,
  MenuItem,
  MenuItemProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Constant,
  PuiPopper,
  PuiPopperProps,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    menuItem: {
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },
    group: {
      '&:not(:first-of-type)': {
        paddingTop: theme.spacing(1),
      },
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
        paddingBottom: theme.spacing(1),
      },
    },
    groupDivider: {
      marginBottom: theme.spacing(1),
    },
    checkbox: {
      padding: 0,
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'ActionsPopper' },
)

const useActionWithSubItemStyles = makeStyles(
  () => ({
    rightIcon: {
      marginLeft: 'auto',
    },
  }),
  { name: 'ActionWithSubItem' },
)

const useActionIcon = makeStyles(
  (theme) => ({
    icon: {
      color: theme.colors.tabLabel,
      marginRight: theme.spacing(1),
    },
    placeholder: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'ActionIcon' },
)

interface ActionWithSubItemProps extends MenuItemProps {
  component?: React.JSXElementConstructor<any>
  onClick: (value: any) => void
  subItem: React.JSXElementConstructor<any>
}

const ActionWithSubItem = forwardRef<HTMLLIElement, ActionWithSubItemProps>(
  function ActionWithSubItem(
    { children, onClick, subItem: SubItemComponent, ...rest },
    ref,
  ) {
    const classes = useActionWithSubItemStyles()
    const [subMenuAnchor, setSubMenuAnchor] = useState<EventTarget | null>(null)

    const expandMenu = (event: React.MouseEvent) => {
      setSubMenuAnchor(event.currentTarget)
    }

    const closeSubMenu = () => {
      setSubMenuAnchor(null)
    }

    const handleSubItemClick = (value: Constant) => {
      onClick(value)
      closeSubMenu()
    }

    return (
      <MenuItem ref={ref} {...rest} onClick={expandMenu}>
        {children}
        <ChevronRightIcon className={classes.rightIcon} color="secondary" />
        {subMenuAnchor && (
          <SubItemComponent
            anchorEl={subMenuAnchor}
            onClick={handleSubItemClick}
            onClose={closeSubMenu}
          />
        )}
      </MenuItem>
    )
  },
)

const ActionIcon = ({
  Icon,
  IconProps = {},
}: {
  Icon?: React.JSXElementConstructor<MuiIconProps>
  IconProps?: MuiIconProps
}) => {
  const classes = useActionIcon()

  return Icon ? (
    <Icon className={classes.icon} {...IconProps} />
  ) : (
    <div className={classes.placeholder} />
  )
}

export type PopperAction = {
  Icon?: React.JSXElementConstructor<any>
  IconProps?: any
  checkbox?: boolean
  checked?: boolean
  closeOnClick?: boolean
  content?: React.ReactNode
  disabled?: boolean
  id: string
  isGroup?: boolean
  items?: PopperAction[]
  label?: string
  onClick?: (value?: any) => void
  subItem?: React.JSXElementConstructor<any>
  title?: string
  withDivider?: boolean
}

interface ActionsPopperProps extends Omit<PuiPopperProps, 'open'> {
  actions?: PopperAction[]
  onClose: (event?: React.MouseEvent) => void
}

const ActionsPopper = ({
  anchorEl,
  actions: actionsProp,
  onClose,
  placement = 'bottom-start',
  ...rest
}: ActionsPopperProps) => {
  const classes = useStyles()
  const actions = actionsProp || []

  const renderMenuItem = ({
    id,
    Icon,
    IconProps,
    disabled,
    label,
    subItem,
    onClick,
    closeOnClick = true,
    checkbox,
    checked,
    content,
  }: PopperAction) => {
    const handleClick = (value: any) => {
      if (closeOnClick) {
        onClose()
      }
      if (onClick) {
        onClick(value)
      }
    }

    const element = (
      <>
        {checkbox && (
          <Checkbox
            checked={checked}
            className={classes.checkbox}
            disabled={disabled}
          />
        )}
        {Icon && <ActionIcon Icon={Icon} IconProps={IconProps} />}
        {content}
        {label && <Text variant="body">{label}</Text>}
      </>
    )

    return subItem ? (
      <ActionWithSubItem
        className={classes.menuItem}
        disabled={disabled}
        key={id}
        subItem={subItem}
        onClick={handleClick}
      >
        {element}
      </ActionWithSubItem>
    ) : (
      <MenuItem
        className={classes.menuItem}
        disabled={disabled}
        key={id}
        onClick={handleClick}
      >
        {element}
      </MenuItem>
    )
  }

  const renderMenuItemGroup = ({
    id,
    title,
    items: itemsProp = [],
    withDivider,
  }: PopperAction) => {
    const items = itemsProp.filter(Boolean)

    if (items.length === 0) {
      return null
    }

    return (
      <Grid className={classes.group} key={id}>
        {withDivider && <Divider className={classes.groupDivider} />}
        {title && (
          <Text px={2} py={0.5} variant="lowAccent3">
            {title}
          </Text>
        )}
        {items.map(renderMenuItem)}
      </Grid>
    )
  }

  const onPopperClose = (event: React.MouseEvent) => {
    if (!(anchorEl as HTMLAnchorElement)?.contains(event.target as Element)) {
      onClose(event)
    }
  }

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      placement={placement}
      onClose={onPopperClose}
      {...rest}
    >
      <Grid className={classes.root} py={1}>
        {actions
          .filter(Boolean)
          .map((item: PopperAction) =>
            item.isGroup ? renderMenuItemGroup(item) : renderMenuItem(item),
          )}
      </Grid>
    </PuiPopper>
  )
}

export default ActionsPopper
