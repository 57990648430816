import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { includes } from 'ramda'
import { Nil, NumberUtils } from '@pbt/pbt-ui-components'
import { Check as CheckIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { WellnessPlanSavingsData } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      padding: theme.spacing(1, 2),
    },
    headerCell: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    bodyCell: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tableBorder,
      },
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    planCell: {
      width: ({ numberOfPlans }: UseStylesProps) => `${45 / numberOfPlans}%`,
    },
    orderedCell: {
      width: '40%',
    },
    amountCell: {
      width: '15%',
    },
  }),
  { name: 'MembershipSavingsTable' },
)

export interface MembershipSavingsTableProps {
  savingsData: WellnessPlanSavingsData | Nil
}

interface UseStylesProps {
  numberOfPlans: number
}

const MembershipSavingsTable = ({
  savingsData,
}: MembershipSavingsTableProps) => {
  const { plans = [], orders = [] } = savingsData || {}

  const classes = useStyles({ numberOfPlans: plans.length })
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)

  const viewFullPlans = () => {
    openMembershipSignUpDialog({
      clientId: savingsData?.clientId,
      showFullDetails: true,
    })
  }

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell
            className={classNames(
              classes.tableCell,
              classes.headerCell,
              classes.orderedCell,
            )}
          >
            {t('WellnessPlans:MEMBERSHIP_SAVINGS_TABLE.ORDERED')}
            <Link
              component="button"
              sx={{ ml: 1.5 }}
              underline="always"
              variant="body1"
              onClick={viewFullPlans}
            >
              {t('WellnessPlans:MEMBERSHIP_SAVINGS_TABLE.VIEW_FULL_PLANS')}
            </Link>
          </TableCell>
          {plans.map((plan) => (
            <TableCell
              className={classNames(
                classes.tableCell,
                classes.headerCell,
                classes.planCell,
              )}
              key={plan.id}
            >
              {plan.name}
            </TableCell>
          ))}
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:SAVINGS')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((order) => (
          <TableRow
            className={classes.tableRow}
            key={`${order.name}-${order.amount}`}
          >
            <TableCell
              className={classNames(classes.tableCell, classes.bodyCell)}
            >
              {order.name}
            </TableCell>
            {plans.map((plan) => {
              const isChecked = includes(plan.id, order.planIds)
              return (
                <TableCell
                  className={classNames(classes.tableCell, classes.bodyCell)}
                  key={plan.id}
                >
                  {isChecked && <CheckIcon />}
                </TableCell>
              )
            })}
            <TableCell
              className={classNames(
                classes.tableCell,
                classes.bodyCell,
                classes.amountCell,
              )}
            >
              {NumberUtils.formatMoney(order.amount)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default MembershipSavingsTable
