import { AnyAction } from 'redux'
import { all, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { MedicalHistoryCardConfig } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  EMAIL_MED_HISTORY,
  EMAIL_MED_HISTORY_FAILURE,
  EMAIL_MED_HISTORY_SUCCESS,
  GENERATE_PDF_FOR_MEDICAL_HISTORY,
  GENERATE_PDF_FOR_MEDICAL_HISTORY_FAILURE,
  GENERATE_PDF_FOR_MEDICAL_HISTORY_SUCCESS,
  WS_MEDICAL_HISTORY_PDF_CREATE,
  WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE,
  WS_MEDICAL_HISTORY_PDF_CREATE_SUCCESS,
} from '../actions/types/communications'
import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_MEDICAL_HISTORY_CARD_CONFIG =
  'medicalHistoryCard/FETCH_MEDICAL_HISTORY_CARD_CONFIG'
export const FETCH_MEDICAL_HISTORY_CARD_CONFIG_SUCCESS =
  'medicalHistoryCard/FETCH_MEDICAL_HISTORY_CARD_CONFIG_SUCCESS'
export const FETCH_MEDICAL_HISTORY_CARD_CONFIG_FAILURE =
  'medicalHistoryCard/FETCH_MEDICAL_HISTORY_CARD_CONFIG_FAILURE'

export const FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE =
  'medicalHistoryCard/FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE'
export const FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_SUCCESS =
  'medicalHistoryCard/FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_SUCCESS'

export const FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_FAILURE =
  'medicalHistoryCard/FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_FAILURE'

export const CLEAR_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE =
  'medicalHistoryCard/CLEAR_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE'
export const CLEAR_MEDICAL_HISTORY_CARD_CONFIG =
  'medicalHistoryCard/CLEAR_MEDICAL_HISTORY_CARD_CONFIG'

export const fetchMedicalHistoryCardConfig = (
  patientId: string,
  from?: string | Nil,
  to?: string | Nil,
) => ({
  type: FETCH_MEDICAL_HISTORY_CARD_CONFIG,
  patientId,
  from,
  to,
})
export const fetchMedicalHistoryCardConfigSuccess = (
  config: MedicalHistoryCardConfig,
) => ({
  type: FETCH_MEDICAL_HISTORY_CARD_CONFIG_SUCCESS,
  config,
})
export const fetchMedicalHistoryCardConfigFailure = (error: ApiError) => ({
  type: FETCH_MEDICAL_HISTORY_CARD_CONFIG_FAILURE,
  error,
})

export const fetchMedicalHistoryCardPreviewTemplate = (
  config: MedicalHistoryCardConfig,
  patientId: string,
) => ({
  type: FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE,
  patientId,
  config,
})
export const fetchMedicalHistoryCardPreviewTemplateSuccess = (
  template: string,
) => ({
  type: FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_SUCCESS,
  template,
})
export const fetchMedicalHistoryCardPreviewTemplateFailure = (
  error: ApiError,
) => ({
  type: FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_FAILURE,
  error,
})

export const clearMedicalHistoryCardPreviewTemplate = () => ({
  type: CLEAR_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE,
})
export const clearMedicalHistoryCardConfig = () => ({
  type: CLEAR_MEDICAL_HISTORY_CARD_CONFIG,
})

export type MedicalHistoryCardState = {
  config: MedicalHistoryCardConfig | undefined
  error: string | null
  isConfigLoading: boolean
  isGeneratingPdf: boolean
  isReceivingTemplate: boolean
  isSendingEmail: boolean
  previewTemplate: string | undefined
}

export const INITIAL_STATE: MedicalHistoryCardState = {
  config: undefined,
  previewTemplate: undefined,
  isConfigLoading: false,
  isReceivingTemplate: false,
  isGeneratingPdf: false,
  isSendingEmail: false,
  error: null,
}

export const medicalHistoryCardReducer = (
  state: MedicalHistoryCardState = INITIAL_STATE,
  action: AnyAction,
): MedicalHistoryCardState => {
  switch (action.type) {
    case FETCH_MEDICAL_HISTORY_CARD_CONFIG:
      return { ...state, error: null, isConfigLoading: true }
    case FETCH_MEDICAL_HISTORY_CARD_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.config,
        isConfigLoading: false,
      }
    case FETCH_MEDICAL_HISTORY_CARD_CONFIG_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isConfigLoading: false,
      }
    case FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE:
      return { ...state, isReceivingTemplate: true }
    case FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        isReceivingTemplate: false,
        previewTemplate: action.template,
      }
    case FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE_FAILURE:
      return {
        ...state,
        isReceivingTemplate: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE:
      return {
        ...state,
        previewTemplate: undefined,
      }
    case EMAIL_MED_HISTORY:
      return {
        ...state,
        isSendingEmail: true,
      }
    case EMAIL_MED_HISTORY_SUCCESS:
      return {
        ...state,
        isSendingEmail: false,
      }
    case EMAIL_MED_HISTORY_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
        error: getErrorMessage(action.error),
      }
    case GENERATE_PDF_FOR_MEDICAL_HISTORY:
    case WS_MEDICAL_HISTORY_PDF_CREATE:
      return { ...state, error: null, isGeneratingPdf: true }
    case GENERATE_PDF_FOR_MEDICAL_HISTORY_SUCCESS:
    case WS_MEDICAL_HISTORY_PDF_CREATE_SUCCESS:
      return { ...state, isGeneratingPdf: false }
    case GENERATE_PDF_FOR_MEDICAL_HISTORY_FAILURE:
    case WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE:
      return {
        ...state,
        isGeneratingPdf: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_MEDICAL_HISTORY_CARD_CONFIG:
      return {
        ...state,
        config: undefined,
      }
    default:
      return state
  }
}

export const getMedicalHistoryCard = (
  state: RootState,
): MedicalHistoryCardState => state.medicalHistoryCard
export const getMedicalHistoryCardIsSendingEmail = (state: RootState) =>
  getMedicalHistoryCard(state).isSendingEmail
export const getMedicalHistoryCardTemplateIsReceiving = (state: RootState) =>
  getMedicalHistoryCard(state).isReceivingTemplate
export const getMedicalHistoryCardConfig = (state: RootState) =>
  getMedicalHistoryCard(state).config
export const getMedicalHistoryCardConfigIsLoading = (state: RootState) =>
  getMedicalHistoryCard(state).isConfigLoading
export const getMedicalHistoryCardPreviewTemplate = (state: RootState) =>
  getMedicalHistoryCard(state).previewTemplate
export const getMedicalHistoryCardIsGeneratingPdf = (state: RootState) =>
  getMedicalHistoryCard(state).isGeneratingPdf

export function* fetchMedicalHistoryCardConfigSaga({
  patientId,
  from,
  to,
}: ReturnType<typeof fetchMedicalHistoryCardConfig>) {
  try {
    const config = yield* requestAPI(
      API.loadMedicalHistoryCardConfig,
      patientId,
      from,
      to,
    )
    yield put(fetchMedicalHistoryCardConfigSuccess(config))
  } catch (error) {
    yield put(fetchMedicalHistoryCardConfigFailure(error as ApiError))
  }
}

export function* fetchMedicalHistoryCardPreviewTemplateSaga({
  patientId,
  config,
}: ReturnType<typeof fetchMedicalHistoryCardPreviewTemplate>) {
  try {
    const { from, to } = config
    const html = yield* requestAPI(
      API.loadMedicalHistoryCardPreviewTemplate,
      patientId,
      config,
      from,
      to,
    )
    yield put(fetchMedicalHistoryCardPreviewTemplateSuccess(html))
  } catch (error) {
    yield put(fetchMedicalHistoryCardPreviewTemplateFailure(error as ApiError))
  }
}

function* watchLoadMedicalHistoryCardConfig() {
  yield takeLeading(
    FETCH_MEDICAL_HISTORY_CARD_CONFIG,
    fetchMedicalHistoryCardConfigSaga,
  )
}

function* watchLoadMedicalHistoryCardPreviewTemplate() {
  yield takeLatest(
    FETCH_MEDICAL_HISTORY_CARD_PREVIEW_TEMPLATE,
    fetchMedicalHistoryCardPreviewTemplateSaga,
  )
}

export function* medicalHistoryCardSaga() {
  yield all([
    watchLoadMedicalHistoryCardConfig(),
    watchLoadMedicalHistoryCardPreviewTemplate(),
  ])
}
