import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Text } from '@pbt/pbt-ui-components'

import FinalizeSection from '~/components/common/buttons/FinalizeSection'
import { useShowOutstandingLabOrdersSection } from '~/store/hooks/labOrders'

import LabOrderRow from './LabOrderRow'
import OrderManagementDialog from './order-management-dialog/OrderManagementDialog'
import { useGetLabOrderData } from './useGetLabOrderData'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    labOrdersContainer: {
      marginTop: theme.spacing(3),
    },
    titleContainer: {
      minWidth: 120,
      borderRight: theme.constants.tabBorder,
    },
    title: {
      width: '100%',
    },
    statusLabel: {
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    controlButtonGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    controlButton: {
      padding: theme.spacing(0.5),
    },
    labRow: {
      padding: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    leftInfoContainer: {
      width: 'auto',
    },
    leftInfoContainerLimited: {
      width: 280,
    },
    buttonsContainer: {
      minWidth: 112,
    },
    statusContainer: {
      minWidth: 96,
      display: 'flex',
      marginRight: 'auto',
    },
    button: {
      margin: theme.spacing(0, 2),
    },
  }),
  { name: 'LabOrders' },
)

export interface LabOrdersProps {
  soapBusinessId: string | Nil
  soapId: string | Nil
}

const LabOrders = ({ soapBusinessId, soapId }: LabOrdersProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const {
    allLabOrders,
    currentOrderLabTestMap,
    keysToPick,
    clientId,
    patientId,
    handleEdit,
    handlePrintLabel,
    setOrderManagementDialogVisible,
    orderManagementDialogVisible,
    setCurrentOrderVendorId,
    vendorsHasIntegrationMap,
    ordersMap,
    handleCancelOrder,
  } = useGetLabOrderData()

  const { showOutstandingLabOrdersSection, showLabOrdersListSection } =
    useShowOutstandingLabOrdersSection()

  return (
    <>
      {(showLabOrdersListSection || showOutstandingLabOrdersSection) && (
        <Grid
          container
          item
          className={classes.labOrdersContainer}
          direction="column"
        >
          {showLabOrdersListSection && (
            <Grid container item className={classes.root} wrap="nowrap">
              <Grid item className={classes.titleContainer}>
                <Text
                  className={classes.title}
                  pl={2}
                  pr={1}
                  pt={2}
                  variant="h4"
                >
                  {t('Common:LAB_ORDERS')}
                </Text>
              </Grid>
              <Grid container item direction="column">
                {allLabOrders.map((order) => (
                  <LabOrderRow
                    clientId={clientId}
                    currentOrderLabTestMap={currentOrderLabTestMap}
                    handleEdit={handleEdit}
                    handlePrintLabel={handlePrintLabel}
                    hasIntegrationMap={vendorsHasIntegrationMap}
                    key={order.id}
                    keysToPick={keysToPick}
                    order={order}
                    patientId={patientId}
                    soapBusinessId={soapBusinessId}
                    soapId={soapId}
                    onCancelOrder={handleCancelOrder}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          {showOutstandingLabOrdersSection && (
            <FinalizeSection
              buttonLabel={t('Common:FINALIZE_ACTION')}
              title={t('Soap:OUTSTANDING_LAB_ORDERS')}
              onClick={() => setOrderManagementDialogVisible(true)}
            />
          )}
          {clientId && patientId && (
            <OrderManagementDialog
              clientId={clientId}
              hasIntegrationMap={vendorsHasIntegrationMap}
              labTestsMap={currentOrderLabTestMap}
              open={orderManagementDialogVisible}
              ordersMap={ordersMap}
              patientId={patientId}
              soapId={soapId}
              onCancelOrder={handleCancelOrder}
              onClose={() => {
                setCurrentOrderVendorId(undefined)
                setOrderManagementDialogVisible(false)
              }}
            />
          )}
        </Grid>
      )}
    </>
  )
}

export default LabOrders
