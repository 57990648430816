import { ColorVariant } from './colors'

enum AppointmentStateCustomColor {
  CANCELLED = 'Cancelled',
  DISCHARGED = 'Discharged',
  NO_SHOW = 'No show',
  PROPOSED = 'Proposed',
  READY_TO_GO = 'Ready-to-go',
  REQUEST_DECLINED = 'Request declined',
  REQUESTED = 'Requested',
  RESCHEDULED = 'Rescheduled',
}

export const AppointmentStateColorMap: Record<string, ColorVariant> = {
  [AppointmentStateCustomColor.CANCELLED]: ColorVariant.DISABLED,
  [AppointmentStateCustomColor.DISCHARGED]: ColorVariant.DISABLED,
  [AppointmentStateCustomColor.NO_SHOW]: ColorVariant.DISABLED,
  [AppointmentStateCustomColor.PROPOSED]: ColorVariant.IMPORTANT,
  [AppointmentStateCustomColor.READY_TO_GO]: ColorVariant.SUCCESS,
  [AppointmentStateCustomColor.REQUEST_DECLINED]: ColorVariant.DISABLED,
  [AppointmentStateCustomColor.REQUESTED]: ColorVariant.IMPORTANT,
  [AppointmentStateCustomColor.RESCHEDULED]: ColorVariant.IMPORTANT,
  DEFAULT: ColorVariant.NORMAL,
}
