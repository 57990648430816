export const CREATE_INVENTORY = 'inventories/CREATE_INVENTORY'
export const CREATE_INVENTORY_FAILURE = 'inventories/CREATE_INVENTORY_FAILURE'
export const CREATE_INVENTORY_SUCCESS = 'inventories/CREATE_INVENTORY_SUCCESS'

export const EDIT_INVENTORY = 'inventories/EDIT_INVENTORY'
export const EDIT_INVENTORY_FAILURE = 'inventories/EDIT_INVENTORY_FAILURE'
export const EDIT_INVENTORY_SUCCESS = 'inventories/EDIT_INVENTORY_SUCCESS'

export const UPDATE_INVENTORIES = 'inventories/UPDATE_INVENTORIES'
export const RESET_INVENTORIES = 'inventories/RESET_INVENTORIES'

export const FETCH_INVENTORY = 'inventories/FETCH_INVENTORY'
export const FETCH_INVENTORY_FAILURE = 'inventories/FETCH_INVENTORY_FAILURE'
export const FETCH_INVENTORY_SUCCESS = 'inventories/FETCH_INVENTORY_SUCCESS'

export const FETCH_INVENTORIES_LIST = 'inventories/FETCH_INVENTORIES_LIST'
export const FETCH_INVENTORIES_LIST_FAILURE =
  'inventories/FETCH_INVENTORIES_LIST_FAILURE'
export const FETCH_INVENTORIES_LIST_SUCCESS =
  'inventories/FETCH_INVENTORIES_LIST_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_FAILURE =
  'inventories/FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_SUCCESS =
  'inventories/FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST =
  'inventories/FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST'

export const DELETE_INVENTORY = 'inventories/DELETE_INVENTORY'
export const DELETE_INVENTORY_FAILURE = 'inventories/DELETE_INVENTORY_FAILURE'
export const DELETE_INVENTORY_SUCCESS = 'inventories/DELETE_INVENTORY_SUCCESS'

export const SET_INVENTORIES_LIST_FILTERS =
  'inventories/SET_INVENTORIES_LIST_FILTERS'
