import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const withRouter =
  (Child: React.JSXElementConstructor<any>) => (props: any) => {
    const location = useLocation()
    const navigate = useNavigate()

    return <Child {...props} location={location} navigate={navigate} />
  }

export default withRouter
