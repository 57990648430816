import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  DateUtils,
  LinkButton,
  PermissionArea,
  PuiSelect,
  UnitUtils,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import NoRecords from '~/components/dashboard/landing/widgets/NoRecords'
import TableWidget from '~/components/dashboard/landing/widgets/TableWidget'
import config from '~/components/dashboard/soap/vitals/vitalsTableConfig'
// @ts-ignore
import { getVitalLabel } from '~/components/dashboard/soap/vitals/vitalUtils'
import { vitalsTableConfig } from '~/components/dashboard/soapV2/vitals/config/vitalsTableConfig'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { VitalsNames } from '~/constants/vitals'
import { fetchWidgetsData, getWidgetData } from '~/store/duck/landing'
import { getUnitsState } from '~/store/duck/settings'
import { getVitalsIsSaving } from '~/store/duck/vitals'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getConstantMap, getFeatureToggle } from '~/store/reducers/constants'
import {
  VitalChartItem,
  VitalSnapshotItem,
  VitalValue,
  WidgetColumn,
} from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import VitalsChart from './VitalsChart/VitalsChart'

const useStyles = makeStyles(
  () => ({
    filterText: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'VitalsWidget' },
)

const chartVitals = ['weight', 'temperature']

interface VitalsWidgetProps {
  clientId: string
  columns: WidgetColumn[]
  isLoading?: boolean
  meta: any
  patientId: string
}

const VitalsWidget = ({
  meta: vitalsMeta,
  isLoading,
  columns,
  clientId,
  patientId,
}: VitalsWidgetProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Clients'])

  const isSaving = useSelector(getVitalsIsSaving)
  const vitalsSnapshotList: VitalSnapshotItem[] = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Vitals,
    ),
  )
  const constantMap = useSelector(getConstantMap)
  const unitsState = useSelector(getUnitsState)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )
  const { create: vitalsCreatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.VITAL),
  )

  const vitals = vitalsSnapshotList.reduce((acc, item) => {
    const vitalName = R.head(R.keys(item))!
    const vitalConfigItem = Utils.findById(
      vitalName,
      isPatientSharingEnabled ? vitalsTableConfig : config,
    )
    const newItem = {
      ...item,
      [vitalName]: item[vitalName]?.length
        ? item[vitalName].map((vital) => ({
            ...vital,
            value: R.cond([
              [
                R.equals('weight'),
                R.always(
                  UnitUtils.convertUnits(
                    UnitTypes.WEIGHT,
                    Number(vital.value),
                    unitsState,
                  ),
                ),
              ],
              [
                R.equals('temperature'),
                R.always(
                  UnitUtils.convertUnits(
                    UnitTypes.TEMPERATURE,
                    Number(vital.value),
                    unitsState,
                  ),
                ),
              ],
              [
                R.T,
                R.always(
                  getVitalLabel(vital.value, vitalConfigItem, constantMap),
                ),
              ],
            ])(vitalName),
          }))
        : [],
    }
    return { ...acc, ...newItem }
  }, {} as VitalSnapshotItem)

  const [activeVital, setActiveVital] = useState<keyof VitalValue>(
    R.head(Object.keys(VitalsNames)) as keyof VitalValue,
  )

  const [openVitalsDialog] = useDialog(DialogNames.VITALS)

  const vitalsList = R.keys(VitalsNames).map((id) => ({
    id,
    name: VitalsNames[id],
  }))

  const refetchVitals = () => {
    dispatch(
      fetchWidgetsData([SnapshotsAliasTypes.Vitals], {
        quiet: false,
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        patientId,
      }),
    )
  }

  const refetchVitalsAfterAdd = useCloseAfterCreation(
    refetchVitals,
    getVitalsIsSaving,
    true,
  )

  const isChartVital = chartVitals.includes(activeVital)

  const data = vitals?.[activeVital] || []
  const chartData =
    data?.map(({ creationDate, value }) => ({
      label: DateUtils.formatDate(creationDate),
      value,
    })) || []

  const handleAddVitals = () => {
    refetchVitalsAfterAdd()
    openVitalsDialog({ clientId, patientId })
  }

  const { latestSoapId, latestSoapBusinessId } = vitalsMeta

  const handleViewMostResentSoap = () => {
    navigate(
      addOriginalBusinessId(
        `/soap/${latestSoapId}`,
        isPatientSharingEnabled ? latestSoapBusinessId : null,
      ),
    )
  }

  return (
    <Grid container>
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mx={1.5}
        my={1}
      >
        <Grid>
          <PuiSelect
            disableUnderline
            classes={{
              select: classes.filterText,
            }}
            items={vitalsList}
            renderEmpty={false}
            value={activeVital}
            onChange={Utils.handleFormSelectInput(setActiveVital)}
          />
        </Grid>

        {latestSoapId && soapReadPermissions && (
          <Grid item>
            <LinkButton onClick={handleViewMostResentSoap}>
              {t('Clients:SNAPSHOTS_AND_RECORDS.VIEW_MOST_RECENT_SOAP')}
            </LinkButton>
          </Grid>
        )}
      </Grid>
      {data?.length || isLoading ? (
        isChartVital ? (
          <VitalsChart data={chartData as VitalChartItem[]} />
        ) : (
          <TableWidget
            showHeaderTitles
            columns={columns}
            data={data}
            isLoading={isLoading || isSaving}
            rowsCount={data.length}
            showActions={false}
          />
        )
      ) : (
        <NoRecords
          action={t('Clients:SNAPSHOTS_AND_RECORDS.ADD_VITALS')}
          pt={0}
          text={t('Clients:SNAPSHOTS_AND_RECORDS.NO_VITALS')}
          onClick={vitalsCreatePermissions ? handleAddVitals : undefined}
        />
      )}
    </Grid>
  )
}

export default VitalsWidget
