import { useCallback, useEffect, useState } from 'react'

export default function useClickAndHold(callback: () => void, ms = 300) {
  const [clickAndHold, setClickAndHold] = useState(false)
  let timerId: number

  useEffect(() => {
    if (clickAndHold) {
      timerId = window.setTimeout(callback, ms)
    } else {
      window.clearTimeout(timerId)
    }

    return () => {
      window.clearTimeout(timerId)
    }
  }, [clickAndHold, callback])

  const start = useCallback(() => {
    setClickAndHold(true)
  }, [])

  const stop = useCallback(() => {
    setClickAndHold(false)
  }, [])

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onClick: callback,
  }
}
