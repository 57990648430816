import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError, Role } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  fetchGroupRoles,
  fetchGroupRolesFailure,
  fetchGroupRolesSuccess,
  fetchUsedRolesFailure,
  fetchUsedRolesSuccess,
  updateGroupRoles,
  updateGroupRolesFailure,
  updateGroupRolesSuccess,
} from '../actions/roles'
import {
  FETCH_GROUP_ROLES,
  FETCH_USED_ROLES,
  UPDATE_GROUP_ROLES,
} from '../actions/types/roles'
import requestAPI from './utils/requestAPI'

export function* fetchUsedRolesSaga() {
  try {
    const roles: Role[] = yield requestAPI(API.fetchUsedRoles)
    yield put(fetchUsedRolesSuccess(roles))
  } catch (error) {
    yield put(fetchUsedRolesFailure(error as ApiError))
  }
}

export function* fetchGroupRolesSaga({
  businessId,
}: ReturnType<typeof fetchGroupRoles>) {
  try {
    const result = yield* requestAPI(API.fetchGroupRoles, businessId)

    yield put(fetchGroupRolesSuccess(result, businessId))
  } catch (error) {
    yield put(fetchGroupRolesFailure(error as ApiError))
  }
}

export function* updateGroupRolesSaga({
  groupId,
  roleIds,
}: ReturnType<typeof updateGroupRoles>) {
  try {
    const result = yield* requestAPI(API.updateGroupRoles, { groupId, roleIds })
    yield put(updateGroupRolesSuccess(result, groupId))
  } catch (error) {
    yield put(updateGroupRolesFailure(error as ApiError))
  }
}

function* watchFetchUsedRoles() {
  yield takeLatest(FETCH_USED_ROLES, fetchUsedRolesSaga)
}

function* watchFetchGroupRoles() {
  yield takeLatest(FETCH_GROUP_ROLES, fetchGroupRolesSaga)
}

function* watchUpdateGroupRoles() {
  yield takeLatest(UPDATE_GROUP_ROLES, updateGroupRolesSaga)
}

export default function* rolesSaga() {
  yield all([
    watchFetchUsedRoles(),
    watchFetchGroupRoles(),
    watchUpdateGroupRoles(),
  ])
}
