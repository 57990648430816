import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel, Stack } from '@mui/material'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import PuiSwitch from '~/components/common/PuiSwitch'
import { ConfirmAlertType } from '~/constants/DialogNames'
import {
  createBusinessSsoIdpSettings,
  deleteBusinessSsoIdpSettings,
  fetchBusinessSsoIdpSettings,
  updateBusiness,
  updateBusinessSsoIdpSettings,
} from '~/store/actions/businesses'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getBusinessIsLoading } from '~/store/reducers/businesses'
import { BasePracticeDetailsSectionProps } from '~/types'
import { isFieldValuesChanged } from '~/utils'

const SsoConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const roles = useMainStaffRoles()
  const isLoading = useSelector(getBusinessIsLoading)

  const {
    ssoIdpDomain: initialSsoIdpDomain,
    ssoIdpAppId: initialSsoIdpAppId,
    ssoDefaultRoleId: initialSsoDefaultRoleId,
    id: ssoIdpSettingsId,
  } = business.ssoIdpSettings || {}
  const initialEnableSsoDefaultPractice = Boolean(ssoIdpSettingsId)

  const [enableSsoDefaultPractice, setEnableSsoDefaultPractice] = useState(
    initialEnableSsoDefaultPractice,
  )

  const [openEnableSsoAlert] = useConfirmAlert({
    type: ConfirmAlertType.ENABLE_SSO_WARNING,
  })

  const { fields, reset, validate } = useFields(
    [
      {
        name: 'ssoConfigured',
        label: t('Businesses:SSO_CONFIGURATION.ENABLE_SSO'),
        type: 'toggle',
        validators: ['required'],
        initialValue: business.ssoConfigured || false,
      },
      {
        name: 'ssoIdpDomain',
        label: t('Businesses:SSO_CONFIGURATION.IDP_DOMAIN'),
        validators: ['required'],
        initialValue: initialSsoIdpDomain || '',
      },
      {
        name: 'ssoIdpAppId',
        label: t('Businesses:SSO_CONFIGURATION.IDP_APP_ID'),
        validators: ['required'],
        initialValue: initialSsoIdpAppId || '',
      },
      {
        name: 'ssoDefaultRoleId',
        label: t('Businesses:SSO_CONFIGURATION.DEFAULT_ROLE'),
        type: 'select',
        validators: ['required'],
        initialValue: initialSsoDefaultRoleId || '',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
    setEnableSsoDefaultPractice(initialEnableSsoDefaultPractice)
  }, [initialEnableSsoDefaultPractice])

  useEffect(() => {
    reset()
  }, [business.id])

  useEffect(() => {
    if (business.ssoConfigured) {
      dispatch(fetchBusinessSsoIdpSettings(business.id))
    }
  }, [business.id, business.ssoConfigured])

  const { ssoConfigured, ssoIdpDomain, ssoIdpAppId, ssoDefaultRoleId } = fields

  const formChanged = enableSsoDefaultPractice && isFieldValuesChanged(fields)
  const formRemoved =
    !enableSsoDefaultPractice &&
    initialEnableSsoDefaultPractice !== enableSsoDefaultPractice
  const saveEnabled = formChanged || formRemoved

  const handleSave = () => {
    if (!enableSsoDefaultPractice && ssoIdpSettingsId) {
      dispatch(deleteBusinessSsoIdpSettings(business.id, ssoIdpSettingsId))
      return
    }

    if (enableSsoDefaultPractice && validate()) {
      if (ssoIdpSettingsId) {
        dispatch(
          updateBusinessSsoIdpSettings(business.id, {
            id: ssoIdpSettingsId,
            ssoIdpDomain: ssoIdpDomain.value,
            ssoIdpAppId: ssoIdpAppId.value,
            ssoDefaultRoleId: ssoDefaultRoleId.value,
          }),
        )
      } else {
        dispatch(
          createBusinessSsoIdpSettings(business.id, {
            ssoIdpDomain: ssoIdpDomain.value,
            ssoIdpAppId: ssoIdpAppId.value,
            ssoDefaultRoleId: ssoDefaultRoleId.value,
          }),
        )
      }
    }
  }

  return (
    <Grid>
      {business.ssoConfigured ? (
        <Text strong>{t('Businesses:SSO_CONFIGURATION.SSO_ENABLED')}</Text>
      ) : (
        <PuiSwitch
          disabled={isLoading}
          field={{
            ...ssoConfigured,
            set: (event) => {
              openEnableSsoAlert({
                applyCustomMessage: true,
                message: t(
                  'Businesses:SSO_CONFIGURATION.CONFIRMATION_WARNING_MESSAGE',
                ),
                okButtonText: t('Common:YES_ENABLE_SSO'),
                cancelButtonText: t('Common:NO_GO_BACK'),
                preventShowAgainCheckBox: false,
                onConfirm: (proceed) => {
                  if (proceed) {
                    ssoConfigured.setValue(!event.target.checked)
                    dispatch(
                      updateBusiness({
                        ...business,
                        ssoConfigured: true,
                      }),
                    )
                  }
                },
              })
            },
          }}
          label={ssoConfigured.label}
        />
      )}
      {business.ssoConfigured && (
        <Stack mt={1} spacing={2}>
          <PuiSwitch
            checked={enableSsoDefaultPractice}
            disabled={isLoading}
            label={t('Businesses:SSO_CONFIGURATION.ENABLE_DEFAULT_PRACTICE')}
            onChange={() => setEnableSsoDefaultPractice((state) => !state)}
          />
          {enableSsoDefaultPractice && (
            <>
              <PuiTextField
                disabled={isLoading}
                field={ssoIdpDomain}
                label={`${ssoIdpDomain.label}*`}
              />
              <PuiTextField
                disabled={isLoading}
                field={ssoIdpAppId}
                label={`${ssoIdpAppId.label}*`}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="sso-default-role-select">
                  {ssoDefaultRoleId.label}*
                </InputLabel>
                <PuiSelect
                  aria-label="sso-default-role-select"
                  disabled={isLoading}
                  field={ssoDefaultRoleId}
                  input={<Input id="sso-default-role-select" />}
                  items={roles}
                />
              </FormControl>
            </>
          )}
          <ButtonWithLoader
            disabled={!saveEnabled}
            loading={isLoading}
            onClick={handleSave}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Stack>
      )}
    </Grid>
  )
}

export default memo(SsoConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
