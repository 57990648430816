import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  HtmlNotesPreview,
  LanguageUtils,
  Text,
} from '@pbt/pbt-ui-components'

import {
  getDimensions,
  getLocationDisplayName,
} from '~/store/reducers/constants'
import { FindingDimension } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    findings: {
      paddingTop: theme.spacing(1),
    },
    finding: {
      overflowWrap: 'break-word',
    },
  }),
  { name: 'FindingsNodeLocation' },
)

export interface FindingsNodeLocationProps {
  classes?: ClassesType<typeof useStyles>
  dimensions: Record<string, FindingDimension>
  id?: string
  name: string
  notes?: string
}

const FindingsNodeLocation = ({
  id,
  notes,
  dimensions,
  name,
  classes: classesProp,
}: FindingsNodeLocationProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const Dimensions = useSelector(getDimensions)
  const locationName = useSelector(getLocationDisplayName(id))

  return (
    <div className={classes.findings}>
      {id && (
        <Text className={classes.finding} variant="body2">
          {t('Common:LOCATION')}: {locationName}
        </Text>
      )}
      {notes && (
        <HtmlNotesPreview
          classes={{ text: classes.finding }}
          fontSize="1.4rem"
          notes={`${locationName || name} ${t(
            'Common:NOTES',
          ).toLowerCase()}: ${notes}`}
        />
      )}
      {Object.values(dimensions).map(({ dimension, value }) => (
        <Text className={classes.finding} key={dimension} variant="body2">
          {`${LanguageUtils.getTranslatedFieldName(Dimensions[dimension])}:
            ${LanguageUtils.getConstantTranslatedName(
              value,
              Dimensions[dimension]?.values,
            )}`}
        </Text>
      ))}
    </div>
  )
}

export default FindingsNodeLocation
