import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { useDebounce } from 'use-debounce'
import { Defaults, Text } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import { InventoryItemState } from '~/constants/SOAPStates'
import { partialEditOrder } from '~/store/actions/orders'
import { useGetInventoryStateId } from '~/store/hooks/orders'
import { Order } from '~/types'
import { calculateAvailableQuantity } from '~/utils/orderUtils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import OrderStatusSelect from '../../../../to-do/OrderStatusSelect'
import InventoryItemDispenseButton from '../buttons/InventoryItemDispenseButton'

const useStyles = makeStyles(
  (theme) => ({
    grid: {
      width: 'calc(100% + 20px)',
      marginLeft: -20,
    },
    fab: {
      height: 33,
      width: '100%',
      fontSize: '1.2rem',
      fontWeight: 500,
      padding: theme.spacing(0, 1),
    },
    fabSelected: {
      '&&&&:hover': {
        backgroundColor: theme.colors.selectedOption,
      },
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.primaryText,
    },
    statusSelect: {
      width: 'fit-content',
      fontSize: '1rem',
    },
  }),
  { name: 'InventoryItemButtonSection' },
)

export interface InventoryItemButtonSectionProps {
  editDisabled?: boolean
  order: Order
  readOnly?: boolean
}

const InventoryItemButtonSection = ({
  order,
  editDisabled,
  readOnly,
}: InventoryItemButtonSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const availableQuantity = calculateAvailableQuantity(order)
  const priceUnits = useGetFormattedPriceUnit(order.price, order.variation)

  const [rawQuantity, setRawQuantity] = useState(order.quantity)

  const [quantity] = useDebounce(rawQuantity, Defaults.DEBOUNCE_ACTION_TIME)

  useEffect(() => {
    setRawQuantity(order.quantity)
  }, [order.quantity])

  useEffect(() => {
    if (quantity !== order.quantity) {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: order.type,
          quantity: quantity || 1,
        }),
      )
    }
  }, [quantity])

  const getInventoryStateId = useGetInventoryStateId()

  const setState = (stateId: string) => {
    dispatch(
      partialEditOrder({
        id: order.id,
        type: order.type,
        stateId:
          stateId === order.stateId
            ? getInventoryStateId(InventoryItemState.ORDERED)
            : stateId,
      }),
    )
  }

  return (
    <Grid container item alignItems="center" className={classes.grid}>
      <Grid item xs={readOnly ? true : 5}>
        {readOnly ? (
          <Grid container item direction="column" pl={2.5}>
            <Text variant="body2">
              {`${t('Abbreviations:COMMON.QUANTITY')}: ${
                order.quantity ? `${order.quantity} ` : ''
              }${priceUnits}`}
            </Text>
            <OrderStatusSelect
              disabled
              classes={{ statusSelect: classes.statusSelect }}
              order={order}
            />
          </Grid>
        ) : (
          <QuantityInput
            allowDecimal
            labelEnabled
            minReachedTooltipEnabled
            availableQuantity={availableQuantity}
            disabled={editDisabled}
            min={0.001}
            value={rawQuantity}
            onChange={setRawQuantity}
          />
        )}
      </Grid>
      {!readOnly && (
        <Grid container item xs spacing={1}>
          <Grid item xs={6}>
            <InventoryItemDispenseButton
              disabled={editDisabled}
              getInventoryStateId={getInventoryStateId}
              order={order}
            />
          </Grid>
          <Grid item xs={6}>
            <Fab
              className={classNames(classes.fab, {
                [classes.fabSelected]:
                  order.stateId ===
                  getInventoryStateId(InventoryItemState.DECLINED),
              })}
              color="primary"
              disabled={editDisabled}
              type="button"
              variant="extended"
              onClick={() =>
                setState(getInventoryStateId(InventoryItemState.DECLINED))
              }
            >
              {order.stateId ===
              getInventoryStateId(InventoryItemState.DECLINED)
                ? t('Common:DECLINED')
                : t('Common:DECLINE_ACTION')}
            </Fab>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default InventoryItemButtonSection
