import { useEffect } from 'react'

export type UseKeydown = {
  callback: Function
  element: HTMLElement | Window | Document | null
  key: string
}

const hasKeyBoardEvent = (event: Event): event is KeyboardEvent =>
  event instanceof KeyboardEvent

export const useKeydown = ({ element, key, callback }: UseKeydown) => {
  useEffect(() => {
    if (!element) {
      return () => {}
    }

    const handleCallback = (event: Event) => {
      if (!hasKeyBoardEvent(event)) {
        throw new Error('Element does not support keyboard event')
      }

      if (event.key === key) {
        callback()
      }
    }

    element.addEventListener('keydown', handleCallback)

    return () => {
      element.removeEventListener('keydown', handleCallback)
    }
  }, [element])
}
