import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { EditorState } from 'draft-js'

import convertToHTML from '~/components/common/inputs/rich-edit/convertToHTML'
import RichEdit from '~/components/common/inputs/rich-edit/RichEdit'
import { DocumentElementStateItem, RichTextWithPlaceholderState } from '~/types'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface RichTextWithPlaceholdersProps {
  documentElement: RichTextWithPlaceholderState
  isDragging?: boolean
  onDelete?: () => void
  onUpdate?: (documentElement: Partial<DocumentElementStateItem>) => void
}

export const RichTextWithPlaceholders = ({
  documentElement,
  isDragging,
  onUpdate,
  onDelete,
}: RichTextWithPlaceholdersProps) => {
  const { t } = useTranslation('Common')
  // eslint-disable-next-line react/hook-use-state
  const [initialHTML] = useState(documentElement.contentWithPlaceholders)
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )

  const handleSetEditorState = (newState: EditorState) => {
    setEditorState(newState)
    onUpdate?.({
      contentWithPlaceholders: convertToHTML(newState),
    })
  }

  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={t('Common:RICH_TEXT')}
        onDelete={onDelete}
      >
        <RichEdit
          showAddLink
          disabled={!onUpdate}
          editorState={editorState}
          initialHTML={initialHTML}
          setEditorState={handleSetEditorState}
          showSignature={false}
        />
      </DocumentElementAccordion>
    </Box>
  )
}
