import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateFormat,
  moment,
  Nil,
  Text,
  useInterval,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  fetchSoapTasks,
  updateSoapTasksSelectedDate,
} from '~/store/actions/tasks'
import { getHasOpenDialogsByName } from '~/store/duck/dialogs'
import { getAppointmentId } from '~/store/reducers/soap'
import { getSoapTasksSelectedDate } from '~/store/reducers/tasks'
import { getTimetableEvent } from '~/store/reducers/timetable'

import { useIsSoapCustomizationEnabled } from '../../soapV2/utils/useIsSoapCustomizationEnabled'
import DatePopper from './DatePopper'

const useStyles = makeStyles(
  () => ({
    arrow: {
      verticalAlign: 'middle',
      fontSize: '1.8rem',
    },
    container: {
      userSelect: 'none',
      position: 'relative',
      cursor: 'pointer',
    },
  }),
  { name: 'ToDoDatePicker' },
)

const REFRESH_TIMEOUT = 60000

const ToDoDatePicker = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const selectedDate = useSelector(getSoapTasksSelectedDate)
  const isTaskDialogOpen = useSelector(
    getHasOpenDialogsByName(DialogNames.TASK),
  )
  const isNotesDialogOpen = useSelector(
    getHasOpenDialogsByName(DialogNames.NOTES),
  )
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  const [popperOpen, setPopperOpen] = useState(false)

  const rootRef = useRef<HTMLDivElement>(null)

  useInterval(() => {
    if (isSoapCustomizationEnabled) {
      return
    }

    if (
      appointment?.scheduledStartDatetime &&
      !isTaskDialogOpen &&
      !isNotesDialogOpen
    ) {
      dispatch(fetchSoapTasks())
    }
  }, REFRESH_TIMEOUT)

  const setSelectedDate = (date: string | Nil) => {
    dispatch(updateSoapTasksSelectedDate(date))
  }

  useEffect(() => {
    if (appointment?.scheduledStartDatetime) {
      setSelectedDate(appointment.scheduledStartDatetime)
    }
  }, [appointment?.scheduledStartDatetime])

  const togglePopper = (event: React.MouseEvent) => {
    event.stopPropagation()
    setPopperOpen(!popperOpen)
  }

  if (!selectedDate) {
    return null
  }

  return (
    <Grid container item alignItems="center" justifyContent="flex-end">
      <Grid
        item
        className={classes.container}
        ref={rootRef}
        onClick={togglePopper}
      >
        <Text inline strong variant="subheading4">
          {moment(selectedDate).format(
            DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_YEAR,
          )}
        </Text>
        {popperOpen ? (
          <KeyboardArrowUp className={classes.arrow} />
        ) : (
          <KeyboardArrowDown className={classes.arrow} />
        )}
        <DatePopper
          anchorEl={rootRef.current}
          open={popperOpen}
          placement="top-end"
          value={selectedDate}
          onChange={setSelectedDate}
          onClose={(event) => {
            if (!rootRef.current?.contains(event.target)) {
              setPopperOpen(false)
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ToDoDatePicker
