import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { Constant, LanguageUtils } from '@pbt/pbt-ui-components'

import PuiCheckboxList from '../PuiCheckboxList'
import BaseFilter, { BaseFilterProps, FilterOption } from './BaseFilter'

export interface CheckboxListFilterProps extends Partial<BaseFilterProps> {
  arrayFormat?: boolean
  clearEmptyFilters?: boolean // For use in cases where empty string may be a valid value
  grouped?: boolean
  includeSearch?: boolean
  isLoading?: boolean
  items?: Constant[]
  onChange?: (filterOption: FilterOption) => void
  onClose: (event?: any) => void
  open: boolean
  resetOnReOpen?: boolean
  singleSelection?: boolean
  value: any
}

const CheckboxListFilter = ({
  includeSearch = true,
  arrayFormat = false,
  grouped = false,
  clearEmptyFilters = false,
  singleSelection = false,
  resetOnReOpen = false,
  onChange,
  onClose,
  value: valueProp,
  items: itemsProp,
  open = false,
  ...rest
}: CheckboxListFilterProps) => {
  const value = !R.isNil(valueProp)
    ? arrayFormat
      ? valueProp
      : valueProp.split(',')
    : []
  const items = itemsProp || []
  const [selectedOption, setSelectedOption] = useState(value)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setSelectedOption(value)
  }, [valueProp])

  useEffect(() => {
    if (resetOnReOpen) {
      setSelectedOption(value)
    }
  }, [open])

  const apply = () => {
    const constants = items.map(R.omit(['component']))
    if (onChange) {
      if (clearEmptyFilters && R.isEmpty(selectedOption)) {
        onChange({} as any)
      } else {
        onChange({
          value: arrayFormat ? selectedOption : selectedOption.join(','),
          humanReadable: selectedOption
            .map((id: string) =>
              LanguageUtils.getConstantTranslatedName(id, constants, ''),
            )
            .join(', '),
        })
      }
    }
    onClose()
  }

  const clear = () => {
    setSelectedOption([])
  }

  const filteredItems = items.filter(
    (item) =>
      LanguageUtils.getTranslatedFieldName(item)
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase()) > -1,
  )

  return (
    <BaseFilter
      hasSelectedOption={Boolean(selectedOption)}
      includeSearch={includeSearch}
      open={open}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onApply={apply}
      onClear={clear}
      onClose={onClose}
      {...rest}
    >
      <PuiCheckboxList
        hideAllButton
        grouped={grouped}
        initialState={selectedOption}
        items={filteredItems}
        singleSelection={singleSelection}
        onChange={setSelectedOption}
      />
    </BaseFilter>
  )
}

export default CheckboxListFilter
