import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { LabTest, TableFilter } from '~/types'
import { getRehydratedState, mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CREATE_LAB_TEST_PRICE,
  CREATE_LAB_TEST_PRICE_FAILURE,
  CREATE_LAB_TEST_PRICE_SUCCESS,
  DELETE_LAB_TEST_PRICE,
  DELETE_LAB_TEST_PRICE_FAILURE,
  DELETE_LAB_TEST_PRICE_SUCCESS,
  EDIT_LAB_TEST_PRICE,
  EDIT_LAB_TEST_PRICE_FAILURE,
  EDIT_LAB_TEST_PRICE_SUCCESS,
  FETCH_LAB_TEST,
  FETCH_LAB_TEST_FAILURE,
  FETCH_LAB_TEST_SUCCESS,
  FETCH_LAB_TESTS_LIST,
  FETCH_LAB_TESTS_LIST_FAILURE,
  FETCH_LAB_TESTS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_SUCCESS,
  RESET_LAB_TESTS,
  UPDATE_LAB_TEST_FILTERS,
  UPDATE_LAB_TESTS,
} from '../actions/types/labTests'
import type { RootState } from '../index'

export type LabTestsState = {
  error: string | null
  filters: Record<string, TableFilter>
  isCreating: boolean
  isDeleting: boolean
  isEditing: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, LabTest>
  totalCount: number
}

export const INITIAL_STATE: LabTestsState = {
  list: [],
  map: {},
  isLoading: false,
  isDeleting: false,
  isFetching: false,
  isFetchingList: false,
  isCreating: false,
  isEditing: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: {},
}

const labTests = (
  state: LabTestsState = INITIAL_STATE,
  action: AnyAction,
): LabTestsState => {
  state = getRehydratedState(INITIAL_STATE, state)
  switch (action.type) {
    case RESET_LAB_TESTS:
      return INITIAL_STATE
    case FETCH_LAB_TESTS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_LAB_TESTS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_LAB_TESTS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_LAB_TESTS:
      return {
        ...state,
        map: R.mergeDeepRight(
          state.map,
          R.map(
            (lt: LabTest) => ({
              ...lt,
              priced: lt.prices?.some((p) => p.active) ?? lt.priced,
            }),
            action.labTests,
          ),
        ),
        error: null,
      }
    case FETCH_LAB_TEST:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_LAB_TEST_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_LAB_TEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_LAB_TEST_PRICE:
      return { ...state, isLoading: true, isCreating: true }
    case EDIT_LAB_TEST_PRICE:
      return { ...state, isLoading: true, isEditing: true }
    case DELETE_LAB_TEST_PRICE:
      return { ...state, isLoading: true, isDeleting: true }
    case CREATE_LAB_TEST_PRICE_SUCCESS:
      return { ...state, isLoading: false, isCreating: false }
    case EDIT_LAB_TEST_PRICE_SUCCESS:
      return { ...state, isLoading: false, isEditing: false }
    case DELETE_LAB_TEST_PRICE_SUCCESS:
      return { ...state, isLoading: false, isDeleting: false }
    case CREATE_LAB_TEST_PRICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_LAB_TEST_PRICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isEditing: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_LAB_TEST_PRICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_LAB_TEST_FILTERS:
      return { ...state, filters: action.filters }
    default:
      return state
  }
}

export default labTests
export const getLabTests = (state: RootState): LabTestsState => state.labTests
export const getLabTestsList = (state: RootState) => getLabTests(state).list
export const getLabTestsMap = (state: RootState) => getLabTests(state).map
export const getLabTestsIsLoading = (state: RootState) =>
  getLabTests(state).isLoading
export const getLabTestsIsFetchingList = (state: RootState) =>
  getLabTests(state).isFetchingList
export const getLabTestsIsCreating = (state: RootState) =>
  getLabTests(state).isCreating
export const getLabTestsIsEditing = (state: RootState) =>
  getLabTests(state).isEditing
export const getLabTestsIsDeleting = (state: RootState) =>
  getLabTests(state).isDeleting
export const getLabTestsTotalCount = (state: RootState) =>
  getLabTests(state).totalCount
export const getLabTest = (id: string | Nil) =>
  createSelector(getLabTestsMap, (map) => (id ? map[id] : undefined))
export const getMultipleLabTests = (ids: string[]) =>
  createSelector(getLabTestsMap, R.props(ids))
export const getLabTestFilters = (state: RootState) =>
  getLabTests(state).filters
