import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateUtils, Defaults, NumberUtils } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import FeatureToggle from '~/constants/featureToggle'
import {
  fetchMoreShipments,
  fetchShipments,
  getMultipleShipments,
  getShipmentsFilters,
  getShipmentsIsFetchingList,
  getShipmentsList,
  getShipmentsTotalCount,
  setFilters,
} from '~/store/duck/shipments'
import { getFeatureToggle } from '~/store/reducers/constants'
import { TableFilter } from '~/types'

import DistributorCell from './DistributorCell'
import ShipmentDetails from './ShipmentDetails'
import ShipmentStatusFilter from './ShipmentStatusFilter'
import ShipmentStatusLabel from './ShipmentStatusLabel'
import SourceCell from './SourceCell'

interface ShipmentTableComponentProps {
  headerButtons?: React.ReactNode
  headerTabs: React.ReactNode
  itemId?: string
  onDetailsClose: () => void
}

const ShipmentTableComponent = ({
  itemId,
  headerButtons,
  headerTabs,
  onDetailsClose,
}: ShipmentTableComponentProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const filters = useSelector(getShipmentsFilters)
  const shipmentsList = useSelector(getShipmentsList)
  const totalCount = useSelector(getShipmentsTotalCount)
  const isFetchingList = useSelector(getShipmentsIsFetchingList)
  const shipments = useSelector(getMultipleShipments(shipmentsList))
  const isSourceInShipmentTableEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOURCE_IN_SHIPMENT_TABLE),
  )

  useEffect(() => {
    dispatch(fetchShipments(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT))
  }, [filters])

  const navigateToDetails = (id: string) => {
    navigate(`/admin/catalog/inventories/shipments/${id}`)
  }

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreShipments(startIndex, endIndex))
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setFilters({}))
  }

  const isItemLoaded = useCallback(
    (index: number) => Boolean(shipmentsList[index]),
    [shipmentsList],
  )

  const columns = [
    {
      label: t('Common:RECEIVED_DATE'),
      prop: ({ receivedDate }: { receivedDate: string }) =>
        DateUtils.formatDateWithHours(receivedDate) || '-',
      width: 3,
    },
    {
      label: t('Common:ORDER_NUMBER'),
      prop: 'orderNumber',
      width: 2,
    },
    {
      label: t('Common:DISTRIBUTOR'),
      prop: DistributorCell,
      width: 4,
    },
    {
      label: t('Common:SOURCE'),
      prop: SourceCell,
      width: 3,
      hidden: !isSourceInShipmentTableEnabled,
    },
    {
      label: t('Common:RECEIVED_BY'),
      prop: 'receivedByName',
      width: 6,
    },
    {
      label: t('Common:TOTAL_COST'),
      prop: ({ costTotal }: { costTotal: number }) =>
        NumberUtils.formatMoney(costTotal),
      width: 2,
    },
    {
      filter: 'statusIds',
      FilterComponent: ShipmentStatusFilter,
      label: t('Common:STATUS'),
      noTypography: true,
      prop: ShipmentStatusLabel,
      width: 2,
    },
  ]

  return (
    <ExpandableTable
      Expander={ShipmentDetails}
      headerButtons={headerButtons}
      headerTabs={headerTabs}
      isLoading={isFetchingList}
      itemId={itemId}
      list={shipments}
      title={t('Common:INVENTORY')}
      onClose={onDetailsClose}
      onSelected={navigateToDetails}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        mainColumnCount={2}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default ShipmentTableComponent
