import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, LinearProgress, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  Nil,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import { getIsScrollSmoothSupported } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import ThreeStepsButton from '~/components/common/inputs/ThreeStepsButton'
import DialogNames from '~/constants/DialogNames'
import { ProblemType } from '~/constants/problems'
import { ActionAnchors } from '~/constants/routes'
import { BodySystemState } from '~/constants/SOAPStates'
import {
  changeStateForAllBodySystems,
  cleanProblemValidationError,
  createProblemBodySystemLog,
  deleteProblemBodySystemLog,
  setProblemToOpenInRail,
  updateProblemBodySystemLog,
} from '~/store/actions/problems'
import { useIsSoapLocked } from '~/store/hooks/soap'
import {
  getDiagnosisProblemStates,
  getFindingProblemStates,
  getProblemBodySystemStates,
} from '~/store/reducers/constants'
import {
  getIsLoadingLogs,
  getIsLogsAndCatalogLoaded,
  getProblemCatalog,
  getProblemsBodySystemLogsMap,
  getProblemsLogsMap,
  getProblemsValidationError,
  getProblemToOpenInRail,
} from '~/store/reducers/problems'
import { getSoapId } from '~/store/reducers/soap'
import { Problem, ProblemLogBodySystemContainer, ProblemStates } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import ProblemBodySystemItem from './catalog/ProblemBodySystemItem'
import IdentifiedProblemDetails from './identified-problems/IdentifiedProblemDetails'
import IdentifiedProblems from './identified-problems/IdentifiedProblems'
import { ProblemsContext } from './ProblemsContext'
import ProblemsSearch from './search/ProblemsSearch'

const isScrollSmoothSupported = getIsScrollSmoothSupported()

const useStyles = makeStyles(
  (theme) => ({
    search: {
      position: 'sticky',
      backgroundColor: theme.colors.tableEvenItem,
      borderBottom: theme.constants.filterBorder,
      borderTop: theme.constants.filterBorder,
      // it should be bigger than widget content (2 is max) and smaller than both rails (9)
      zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'below', 6),
      [theme.breakpoints.down('md')]: {
        top: theme.constants.soapHeaderHeight - 1,
      },
      [theme.breakpoints.up('md')]: {
        top:
          theme.constants.soapHeaderHeight +
          theme.constants.appHeader.heightMdUp,
      },
    },
  }),
  { name: 'ProblemsWidget' },
)

const ProblemsWidget = () => {
  const { t } = useTranslation(['Soap', 'Errors'])
  const classes = useStyles()
  const dispatch = useDispatch()
  const { hash, pathname, search } = useLocation()
  const navigate = useNavigate()

  const { isRightDrawerOpen, closeRightDrawer, openRightDrawer } =
    useContext(ProblemsContext)

  const catalog = useSelector(getProblemCatalog)
  const soapId = useSelector(getSoapId)
  const bodySystemLogs = useSelector(getProblemsBodySystemLogsMap)
  const problemLogs = useSelector(getProblemsLogsMap)
  const isLogsAndCatalogLoaded = useSelector(getIsLogsAndCatalogLoaded)
  const isLogsLoading = useSelector(getIsLoadingLogs)
  const problemsValidationError = useSelector(getProblemsValidationError)
  const problemToOpenInRail = useSelector(getProblemToOpenInRail)

  const findingStates = useSelector(getFindingProblemStates)
  const diagnosisStates = useSelector(getDiagnosisProblemStates)

  const isReadOnly = useIsSoapLocked()
  const addedStatus = Utils.findConstantIdByName(
    ProblemStates.ADDED,
    findingStates,
  )
  const suspectedStatus = Utils.findConstantIdByName(
    ProblemStates.SUSPECTED,
    diagnosisStates,
  )
  const ProblemBodySystemStates = useSelector(getProblemBodySystemStates)

  const stateMap = {
    [BodySystemState.WNL]: Utils.findByName(
      BodySystemState.WNL,
      ProblemBodySystemStates,
    ),
    [BodySystemState.ONL]: Utils.findByName(
      BodySystemState.ONL,
      ProblemBodySystemStates,
    ),
    [BodySystemState.NC]: Utils.findByName(
      BodySystemState.NC,
      ProblemBodySystemStates,
    ),
  }

  const identifiedProblemsRef = useRef<HTMLDivElement>(null)

  const [activeProblemId, setActiveProblemId] = useState<string | null>(null)
  const [openUploadErrorDialog, closeUploadErrorDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  useEffect(() => {
    if (problemsValidationError) {
      openUploadErrorDialog({
        iconType: AlertIconType.WARN,
        message: t('Errors:PROBLEM_WIDGET.VALIDATION_SOURCE_MESSAGE'),
        onOk: () => {
          dispatch(cleanProblemValidationError())
          closeUploadErrorDialog()
        },
      })
    }
  }, [problemsValidationError])

  useEffectExceptOnMount(() => {
    if (
      hash === ActionAnchors.IDENTIFIED_PROBLEMS &&
      identifiedProblemsRef.current
    ) {
      window.scrollTo({
        top: identifiedProblemsRef.current.offsetTop,
        behavior: isScrollSmoothSupported ? 'smooth' : 'auto',
      })
      // it resets action anchor IDENTIFIED_PROBLEMS
      navigate(pathname + search)
    }
  }, [hash, identifiedProblemsRef.current])

  const getNewProblemStateId = (problem: Problem): string =>
    problem.type === ProblemType.FINDING ? addedStatus : suspectedStatus

  const getNewLogStateId = (
    problem: Problem,
    bodySystemState?: BodySystemState,
  ): string =>
    bodySystemState
      ? Utils.findByName(bodySystemState, ProblemBodySystemStates).id
      : problem.onlDisabled
        ? stateMap[BodySystemState.WNL].id
        : stateMap[BodySystemState.ONL].id

  const createBodySystemLog = (
    problem: Problem,
    bodySystemState?: BodySystemState,
  ) => {
    if (soapId) {
      dispatch(
        createProblemBodySystemLog(soapId, {
          bodySystemId: problem.bodySystemId,
          stateId: getNewLogStateId(problem, bodySystemState),
          notes: '',
          problemLogs:
            bodySystemState || !problem.id
              ? []
              : [
                  {
                    notes: '',
                    problemId: problem.id,
                    stateId: getNewProblemStateId(problem),
                  },
                ],
        }),
      )
    }
  }

  const createProblemLog = (
    problem: Problem,
    bodySystemLog: ProblemLogBodySystemContainer,
    bodySystemState?: BodySystemState,
  ) => {
    if (soapId) {
      const { id, notes, bodySystemId } = bodySystemLog.entity

      dispatch(
        updateProblemBodySystemLog(soapId, id, {
          id,
          soapId,
          stateId: getNewLogStateId(problem, bodySystemState),
          notes,
          bodySystemId,
          crc: bodySystemLog.crc,
          problemLogs: !problem.id
            ? []
            : [
                {
                  notes: '',
                  problemId: problem.id,
                  stateId: getNewProblemStateId(problem),
                },
              ],
        }),
      )
    }
  }

  const openRightRail = (problem: Problem) => {
    const problemLog = problem.logKey
      ? problemLogs?.[problem.logKey]
      : bodySystemLogs?.[problem.bodySystemId]
    openRightDrawer(
      <IdentifiedProblemDetails
        key={problem?.key}
        logKey={problemLog?.key || ''}
        problem={problem}
      />,
    )
  }

  useEffect(() => {
    if (problemToOpenInRail) {
      openRightRail(problemToOpenInRail)
      dispatch(setProblemToOpenInRail(null))
    }
  }, [problemToOpenInRail])

  const openRightDrawerAfterCreation = useCloseAfterCreation(
    (problem) => openRightRail(problem),
    getIsLoadingLogs,
  )

  const createLog = (problem: Problem, bodySystemState?: BodySystemState) => {
    const bodySystemLog = bodySystemLogs?.[problem?.bodySystemId]
    if (bodySystemLog) {
      const problemLogKey = problem?.logKey || ''
      const problemLog = problemLogs?.[problemLogKey]
      if (!problemLog) {
        createProblemLog(problem, bodySystemLog, bodySystemState)
        openRightDrawerAfterCreation(problem)
      } else {
        openRightRail(problem)
      }
    } else {
      createBodySystemLog(problem, bodySystemState)
      openRightDrawerAfterCreation(problem)
    }
  }

  const updateLogState = (
    problem: Problem,
    bodySystemState: BodySystemState,
  ) => {
    const bodySystemLog = bodySystemLogs?.[problem?.bodySystemId]
    const { entity, crc } = bodySystemLog || {}

    if (soapId && entity && crc) {
      dispatch(
        updateProblemBodySystemLog(soapId, entity.id, {
          id: entity.id,
          soapId,
          stateId: stateMap[bodySystemState].id,
          notes: entity.notes,
          bodySystemId: entity.bodySystemId,
          removeExistingProblemLogs: bodySystemState === BodySystemState.WNL,
          crc,
        }),
      )
    }
    openRightDrawerAfterCreation(problem)
  }

  const deleteLog = (problem: Problem) => {
    const bodySystemLog = bodySystemLogs?.[problem?.bodySystemId]
    if (soapId && bodySystemLog?.entity.id) {
      dispatch(deleteProblemBodySystemLog(soapId, bodySystemLog?.entity.id))
    }
  }

  const handleStateChange = (
    problemId: string,
    bodySystemState: string | Nil,
    fromEvent?: boolean,
  ) => {
    const problem = catalog?.problems[problemId]

    if (fromEvent && problem) {
      const bodySystemLog = bodySystemLogs?.[problem.bodySystemId]
      const hasBodySystemLog = Boolean(bodySystemLog)
      const notes = (bodySystemLog as ProblemLogBodySystemContainer)?.entity
        ?.notes
      const toDelete = bodySystemState === BodySystemState.NC
      const toDeleteWithNotes = toDelete && notes
      if (
        bodySystemState === BodySystemState.ONL ||
        bodySystemState === BodySystemState.WNL ||
        toDeleteWithNotes
      ) {
        if (!hasBodySystemLog) {
          createLog(problem, bodySystemState)
        } else {
          updateLogState(problem, bodySystemState)
        }
      } else if (toDelete) {
        deleteLog(problem)
        closeRightDrawer()
      }
    }
  }

  const changeAllBodySystemsStates = (state: BodySystemState) => {
    if (soapId && catalog) {
      const removedBodySystemKey = catalog.bodySystemsList.find(
        (key) => catalog.problems[key].name === ProblemStates.REMOVED,
      )
      const removedBodySystemId =
        removedBodySystemKey && catalog.problems[removedBodySystemKey].id

      const bodySystemsIdsList = catalog.bodySystemsList
        .map((key) => catalog.problems[key].id)
        .filter((id) => id !== removedBodySystemId) as string[]

      if (state === BodySystemState.NC) {
        closeRightDrawer()
      }

      dispatch(
        changeStateForAllBodySystems(
          soapId,
          Utils.findByName(state, ProblemBodySystemStates).id,
          bodySystemsIdsList,
        ),
      )
    }
  }

  const handleBrowse = (problemId: string) => {
    if (activeProblemId === problemId) {
      setActiveProblemId(null)
    } else {
      setActiveProblemId(problemId)
    }
  }

  return (
    <Paper elevation={3} sx={{ flex: 1 }}>
      <Grid container direction="column">
        <Grid item px={2} py={0.5}>
          <TextWithTooltip
            TooltipProps={{
              placement: 'bottom-start',
            }}
            tooltipText={t('Soap:PROBLEMS.WIDGET_TITLE_TOOLTIP')}
            variant="h4"
          >
            {t('Soap:PROBLEMS.PROBLEMS')}
          </TextWithTooltip>
        </Grid>
        {!isReadOnly && (
          <Grid container className={classes.search} direction="row" pl={1}>
            <Grid item ml={1} mt={1.5} xs="auto">
              <ThreeStepsButton
                disabled={isLogsLoading}
                noneLabel={t('Abbreviations:COMMON.NOT_CHECKED')}
                offLabel={t('Abbreviations:COMMON.OUTSIDE_NORMAL_LIMITS')}
                onClick={(state) => changeAllBodySystemsStates(state)}
                onLabel={t('Abbreviations:COMMON.WITHIN_NORMAL_LIMITS')}
              />
            </Grid>
            <Grid item xs>
              <ProblemsSearch
                isRightDrawerOpen={isRightDrawerOpen}
                onSearchFindingClick={createLog}
              />
            </Grid>
          </Grid>
        )}
        {isLogsAndCatalogLoaded ? (
          <>
            {!isReadOnly &&
              catalog?.bodySystemsList.map((problemId: string) => (
                <ProblemBodySystemItem
                  isActive={activeProblemId === problemId}
                  key={problemId}
                  problemId={problemId}
                  onAddLog={createLog}
                  onBrowse={handleBrowse}
                  onStateChange={handleStateChange}
                />
              ))}
            <IdentifiedProblems ref={identifiedProblemsRef} />
          </>
        ) : (
          <LinearProgress />
        )}
      </Grid>
    </Paper>
  )
}

export default ProblemsWidget
