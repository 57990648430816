import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import { editTasks } from '~/store/actions/tasks'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { Task } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

// @ts-ignore
import MultipleTasksTable from './MultipleTasksTable'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
      overflowY: 'visible',
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'RepeatTasksDialog' },
)

interface RepeatTasksDialogProps extends BasePuiDialogProps {
  tasks: Task[]
}

const RepeatTasksDialog = ({
  tasks,
  open,
  onClose,
}: RepeatTasksDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const setCloseAfterEditOn = useCloseAfterCreation(onClose, getTasksIsLoading)

  const onProceed = (tasksToEdit: Task[]) => {
    setCloseAfterEditOn()
    dispatch(editTasks(tasksToEdit))
  }

  return (
    <PuiDialog
      aria-labelledby="repeat-tasks-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Common:ADD_REPEAT')}
      onClose={onClose}
    >
      <MultipleTasksTable
        proceedButtonLabel={t('Common:ADD_REPEAT')}
        showAssigned={false}
        showDueDate={false}
        showDueTime={false}
        showType={false}
        tasks={tasks}
        onProceed={onProceed}
      />
    </PuiDialog>
  )
}

export default RepeatTasksDialog
