import { all, put, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  fetchReminderProtocols,
  fetchReminderProtocolsFailure,
  fetchReminderProtocolsSuccess,
} from '../duck/reminderProtocolsV2'
import requestAPI from './utils/requestAPI'

export function* fetchReminderProtocolsSaga({
  payload,
}: ReturnType<typeof fetchReminderProtocols>) {
  try {
    const { data: reminderProtocols } = yield* requestAPI(
      API.fetchReminderProtocolsForProcedure,
      payload.priceId,
      payload.procedureId,
    )

    yield put(
      fetchReminderProtocolsSuccess({
        ...payload,
        protocols: reminderProtocols,
      }),
    )
  } catch (error) {
    yield put(fetchReminderProtocolsFailure({ error: error as ApiError }))
  }
}

function* watchFetchReminderProtocols() {
  yield takeLeading(fetchReminderProtocols.type, fetchReminderProtocolsSaga)
}

export default function* reminderProtocolsSaga() {
  yield all([watchFetchReminderProtocols()])
}
