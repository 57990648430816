import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BackButton, ButtonWithLoader } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 200,
    },
  }),
  { name: 'ManualPaymentActions' },
)

interface ManualPaymentActionsProps {
  isLoading: boolean
  onBack: (() => void) | undefined
  onRecordPaymentButtonClick: () => void
}

export const ManualPaymentActions = ({
  isLoading,
  onBack,
  onRecordPaymentButtonClick,
}: ManualPaymentActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <>
      <Box mr={3}>
        <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
      </Box>
      <Box>
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          type="submit"
          onClick={onRecordPaymentButtonClick}
        >
          {t('Common:RECORD_PAYMENT')}
        </ButtonWithLoader>
      </Box>
    </>
  )
}
