import React, { forwardRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiPopper, PuiPopperProps } from '@pbt/pbt-ui-components'

import Selector, { SelectorProps } from './Selector'

const useStyles = makeStyles(
  (theme) => ({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    popperNormal: {
      width: 288,
    },
    popperDense: {
      width: 232,
    },
    popperDenseWithLabel: {
      width: 272,
    },
  }),
  { name: 'SelectorPopper' },
)

export interface SelectorPopperProps extends SelectorProps {
  BottomControls: React.ReactNode
  anchorEl: PuiPopperProps['anchorEl']
  disablePortal?: boolean
  keepMounted?: boolean
  onClose: PuiPopperProps['onClose']
  open: boolean
  placement?: PuiPopperProps['placement']
}

const SelectorPopper = forwardRef<HTMLDivElement, SelectorPopperProps>(
  function SelectorPopper(
    {
      keepMounted,
      disablePortal = true,
      fromLabel,
      anchorEl,
      open,
      placement = 'bottom-start',
      onClose,
      range,
      BottomControls = null,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()

    return (
      <PuiPopper
        closeOnTooltipsEvents
        hideCloseButton
        anchorEl={anchorEl}
        classes={{
          popper: classNames(classes.popper, {
            [classes.popperNormal]: range,
            [classes.popperDenseWithLabel]: !range && fromLabel,
            [classes.popperDense]: !range && !fromLabel,
          }),
        }}
        disablePortal={disablePortal}
        keepMounted={keepMounted}
        open={open}
        placement={placement}
        ref={ref}
        onClose={onClose}
      >
        <Selector fromLabel={fromLabel} range={range} {...rest} />
        {BottomControls}
      </PuiPopper>
    )
  },
)

export default SelectorPopper
