import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Field } from '@pbt/pbt-ui-components'

import { getConversationById } from '~/store/reducers/conversations'

import CommunicationTemplateInput from '../../template-inputs/CommunicationTemplateInput'

const useStyles = makeStyles(
  (theme) => ({
    addButton: {
      width: 150,
    },
    noteContent: {
      padding: theme.spacing(2, 2, 0),
    },
  }),
  { name: 'InternalNote' },
)

export interface InternalNoteProps {
  conversationId: string
  handleAddNote: () => void
  isLoading: boolean
  messageField: Field
}

const InternalNote = ({
  isLoading,
  messageField,
  handleAddNote,
  conversationId,
}: InternalNoteProps) => {
  const classes = useStyles()
  const conversation = useSelector(getConversationById(conversationId))
  const { t } = useTranslation(['Common'])

  return (
    <Grid container direction="column">
      <CommunicationTemplateInput
        showAttachment
        className={classes.noteContent}
        clientId={conversation?.client}
        eventId={conversation?.eventId}
        field={messageField}
        minHeight={240}
        patientId={conversation?.patient}
      />
      <Grid container item p={2}>
        <ButtonWithLoader
          className={classes.addButton}
          loading={isLoading}
          onClick={handleAddNote}
        >
          {t('Common:ADD_NOTE')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default InternalNote
