import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { clearCurrentInvoiceData } from '~/store/actions/finance'
import { getUser } from '~/store/reducers/users'

import BatchInvoice from './BatchInvoice'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.contentBackground,
    },
    paper: {
      width: 'calc(100% - 16px)',
      maxWidth: '100%',
      margin: theme.spacing(1),
    },
  }),
  { name: 'BatchInvoiceDialog' },
)

interface BatchInvoiceDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  invoiceIds?: string[]
}

const BatchInvoiceDialog = ({
  clientId,
  invoiceIds,
  open,
  onClose,
}: BatchInvoiceDialogProps) => {
  const classes = useStyles()
  const client = useSelector(getUser(clientId)) || {}
  const dispatch = useDispatch()
  const { t } = useTranslation('Dialogs')

  const handleClose = () => {
    dispatch(clearCurrentInvoiceData())
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      aria-labelledby="batch-invoice-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={handleClose}
    >
      <Grid container className={classes.container} direction="column">
        <Grid
          container
          item
          alignItems="center"
          pb={1}
          pt={3}
          px={3}
          wrap="nowrap"
        >
          <Text inline variant="h2">
            {`${t(
              'Dialogs:BATCH_INVOICE_DIALOG.VIEW_INVOICES',
            )}: ${Utils.getPersonString(client)}`}
          </Text>
        </Grid>
        <BatchInvoice clientId={clientId} invoiceIds={invoiceIds || []} />
      </Grid>
    </PuiDialog>
  )
}

export default BatchInvoiceDialog
