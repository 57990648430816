import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Nil, PuiTooltip, Text } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { LandingType, LandingWidgetName } from '~/constants/landingConstants'
import { getWidgetDataIsLoading, getWidgetMeta } from '~/store/duck/landing'

export type ScoreWidgetData = {
  score: number | Nil
  weekEndDate: string | Nil
  weekStartDate: string | Nil
}

const useStyles = makeStyles(
  (theme) => ({
    container: {
      maxHeight: 180,
      maxWidth: 160,
    },
    infoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
    },
  }),
  { name: 'ScoreWidget' },
)

const ScoreWidget = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Landing'])

  const data = useSelector(
    getWidgetMeta(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.CUSTOMER_SATISFACTION,
    ),
  ) as Array<ScoreWidgetData>

  const loading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.CUSTOMER_SATISFACTION,
    ),
  )

  const currentWeekScore = R.prop('score', R.head(data) as ScoreWidgetData) || 0
  const previousWeekScore =
    R.prop('score', R.nth(1, data) as ScoreWidgetData) || 0
  const changedScorePercent =
    previousWeekScore !== 0
      ? Math.ceil(
          Math.abs((currentWeekScore - previousWeekScore) / previousWeekScore) *
            100,
        )
      : 0

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.container}
      direction="column"
      justifyContent="center"
      xs={4}
    >
      <Grid container item alignItems="center" wrap="nowrap">
        <Text noWrap variant="h3">
          {t('Landing:CUSTOMER_SATISFACTION.CUSTOMER_SATISFACTION_TITLE')}
        </Text>
        <PuiTooltip
          tooltipPlacement="bottom"
          tooltipText={
            <Text component="span" whiteSpace="pre-line">
              {t('Landing:CUSTOMER_SATISFACTION.NPS_DESCRIPTION')}
            </Text>
          }
        >
          <InfoIcon className={classes.infoIcon} />
        </PuiTooltip>
      </Grid>
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <Grid container item alignItems="center" justifyContent="center">
          <Text variant="hero">{currentWeekScore}</Text>
          {changedScorePercent ? (
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              {currentWeekScore > previousWeekScore ? (
                <ArrowUpwardIcon color="success" fontSize="small" />
              ) : (
                <ArrowDownwardIcon color="error" fontSize="small" />
              )}
              <Text noWrap sx={{ color: 'black' }} variant="caption">
                {t(
                  'Landing:CUSTOMER_SATISFACTION.CUSTOMER_SATISFACTION_LAST_WEEK',
                  {
                    changedScorePercent: Math.abs(changedScorePercent),
                  },
                )}
              </Text>
            </Grid>
          ) : null}
        </Grid>
      )}
    </Grid>
  )
}

export default ScoreWidget
