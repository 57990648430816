import * as API from '~/api'

import createDuck from './duck-generators/createDuck'
import listDuck from './duck-generators/list'

const loyaltyPointsDuck = createDuck({
  name: 'loyaltyPoints',
  duck: listDuck,
  apiEndpoints: (types) => ({
    [types.FETCH_LIST]: API.fetchLoyaltyPointsList,
    [types.FETCH_MORE_ITEMS_FOR_LIST]: API.fetchLoyaltyPointsList,
    [types.REFRESH_LIST]: API.fetchLoyaltyPointsList,
  }),
})

export const loyaltyPointsReducer = loyaltyPointsDuck.reducer
export const loyaltyPointsSaga = loyaltyPointsDuck.saga
export const loyaltyPointsSelectors = loyaltyPointsDuck.selectors
export const loyaltyPointsActions = loyaltyPointsDuck.actions
