import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { propEq } from 'ramda'
import {
  BackButton,
  Constant,
  Defaults,
  PuiSelect,
  Text,
} from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import { RhapsodyGoConfigurationLabels } from '~/constants/RhapsodyPassStates'
import { RhapsodyPayConfig } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 3, 1, 3),
    },
    text: {
      lineHeight: '2.2rem',
    },
    summaryWrapper: {
      boxShadow: theme.constants.blockShadow,
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
    },
    summaryRow: {
      padding: theme.spacing(1),
    },
    withBorders: {
      borderBottom: theme.constants.filterBorder,
      borderTop: theme.constants.filterBorder,
    },
    button: {
      width: 240,
      height: 40,
      margin: theme.spacing(2, 0),
    },
    quantityInput: {
      width: 'auto',
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    spinner: {
      position: 'absolute',
      left: 'calc(50% - 32px)',
      top: '50%',
    },
    link: {
      display: 'inline-flex',
      fontSize: '1.6rem',
      textDecoration: 'underline',
      color: theme.colors.link,
      cursor: 'pointer',
    },
  }),
  { name: 'RhapsodyPaySummary' },
)

interface RhapsodyPaySummaryProps {
  isLoading: boolean
  onBack: () => void
  onPercentageChange: (event: SelectChangeEvent<unknown>) => void
  onProceed: () => void
  onRhapsodyGoProvidingChange: (value: string) => void
  onTerminalsQuantityUpdate: (value: number) => void
  payGoConfigList: Constant[]
  payPassConfigList: Constant[]
  posPayConfig: RhapsodyPayConfig
}

const RhapsodyPaySummary = ({
  isLoading,
  onProceed,
  onBack,
  posPayConfig,
  payGoConfigList,
  payPassConfigList,
  onTerminalsQuantityUpdate,
  onPercentageChange,
  onRhapsodyGoProvidingChange,
}: RhapsodyPaySummaryProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const includedItem = payGoConfigList.find(
    propEq('name', RhapsodyGoConfigurationLabels.INCLUDED),
  )
  const notIncludedItem = payGoConfigList.find(
    propEq('name', RhapsodyGoConfigurationLabels.NOT_INCLUDED),
  )

  const updatePayGoValue = ({
    target: { checked },
  }: {
    target: { checked: boolean }
  }) => {
    if (checked) {
      onRhapsodyGoProvidingChange(includedItem?.id as string)
    } else {
      onRhapsodyGoProvidingChange(notIncludedItem?.id as string)
    }
  }

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.disabled]: isLoading,
      })}
      direction="column"
    >
      {isLoading && <CircularProgress className={classes.spinner} size={32} />}
      <Grid
        container
        item
        className={classes.summaryWrapper}
        direction="column"
      >
        <Grid
          container
          item
          alignItems="center"
          className={classes.summaryRow}
          justifyContent="space-between"
        >
          <Text align="left" className={classes.text}>
            {t('Businesses:RHAPSODY_PAY.MOBILE_PAYMENT_TERMINALS')}
          </Text>
          <Grid item className={classes.quantityInput}>
            <QuantityInput
              showControls
              min={0}
              value={posPayConfig.deviceCount}
              onChange={onTerminalsQuantityUpdate}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classNames(classes.summaryRow, classes.withBorders)}
          justifyContent="space-between"
        >
          <Text align="left" className={classes.text}>
            {t('Businesses:RHAPSODY_PAY.RHAPSODY_PASS')}
          </Text>
          <PuiSelect
            disableUnderline
            items={payPassConfigList.map((option) => ({
              ...option,
              name: t('Businesses:RHAPSODY_PAY.PERCENT_SERVICE_FEE', {
                percent: Number(option.name) * 100,
              }),
            }))}
            renderEmpty={false}
            value={posPayConfig.passOption}
            onChange={onPercentageChange}
          />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classes.summaryRow}
          justifyContent="space-between"
        >
          <Text align="left" className={classes.text}>
            {t('Businesses:RHAPSODY_PAY.RHAPSODY_GO')}
          </Text>
          <FormControlLabel
            classes={{ label: classes.text }}
            control={
              <Checkbox
                checked={includedItem?.id === posPayConfig.goOption}
                value={posPayConfig.goOption}
                onChange={updatePayGoValue}
              />
            }
            label={
              payGoConfigList.find(propEq('id', posPayConfig.goOption))?.name
            }
          />
        </Grid>
      </Grid>
      <Grid container item direction="column">
        <Text align="left" className={classes.text}>
          {t(
            'Businesses:RHAPSODY_PAY.NEXT_YOU_WILL_COMPLETE_YOUR_MERCHANT_APPLICATION',
          )}
        </Text>
        <Text align="left" className={classes.text} mt={3}>
          <Trans
            components={{
              emailLink: (
                <a
                  className={classes.link}
                  href={`mailto:${Defaults.SUPPORT_EMAIL}`}
                >
                  {Defaults.SUPPORT_EMAIL}
                </a>
              ),
            }}
            i18nKey="Businesses:RHAPSODY_PAY.APPROVALS_WILL_BE_PROCESSED_BY_OUR_PAYMENT_PARTNERS"
            values={{ supportEmail: Defaults.SUPPORT_EMAIL }}
          />
        </Text>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        mt={7}
      >
        <Grid item>
          <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
        </Grid>
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={onProceed}
        >
          {t('Common:APPLY_ACTION')}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default RhapsodyPaySummary
