import { gql } from '@apollo/client'

import { WELLNESS_PLAN_FIELDS } from '../fragments'

export const TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN = gql`
  ${WELLNESS_PLAN_FIELDS}
  mutation AutoRenewalPlan($input: WPlanRenewalLog!) {
    autoRenewalWellnessPlan(input: $input) {
      ...WellnessPlanFields
    }
  }
`
