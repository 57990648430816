import React, { useEffect } from 'react'
import { Field } from '@pbt/pbt-ui-components'

import {
  useGetIsTextMessage,
  useShouldDisplaySmsConsent,
} from '~/store/hooks/clients'

import PuiSwitch from '../PuiSwitch'

export interface SMSConsentFieldProps {
  communicationSmsOptInField: Field
  preferredContactMethodIdField: Field
}

export const SMSConsentField = ({
  communicationSmsOptInField,
  preferredContactMethodIdField,
}: SMSConsentFieldProps) => {
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  // We should reset preferred contact method whenever the current business is omnichannel
  // and client has selected sms consent flag to be OFF
  const isTextMessageSelected = useGetIsTextMessage(
    preferredContactMethodIdField.value,
  )

  useEffect(() => {
    if (
      shouldDisplaySmsConsent &&
      communicationSmsOptInField?.value === false &&
      isTextMessageSelected
    ) {
      preferredContactMethodIdField.set(undefined)
    }
  }, [
    shouldDisplaySmsConsent,
    communicationSmsOptInField?.value,
    isTextMessageSelected,
  ])

  if (!shouldDisplaySmsConsent) {
    return null
  }

  return (
    <PuiSwitch
      field={communicationSmsOptInField}
      label={communicationSmsOptInField.label}
    />
  )
}
