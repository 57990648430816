import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { patchAppointment } from '~/store/actions/timetable'
import {
  fetchAssignedToSpaceMonitors,
  getAssignedToSpaceMonitors,
  getCurrentMonitorToken,
  getInRoomSpaceMonitor,
  getIsGettingToken,
} from '~/store/duck/monitor'
import { registerWarnAlert } from '~/store/duck/uiAlerts'
import {
  getTimetableIsLoading,
  getTimetableValidationError,
} from '~/store/reducers/timetable'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

type OpenAndRegisterProps = {
  appointmentId?: string | Nil
  appointmentTypeId?: string
  clientId?: string
  patientId?: string
  spaceId?: string
}

const useOpenMonitorApp = (monitorUrl: string, spaceId?: string) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const assignedDevices = useSelector(getAssignedToSpaceMonitors)
  const validationError = useSelector(getTimetableValidationError)
  const currentMonitorToken = useSelector(getCurrentMonitorToken)

  const [openSpaceSelectDialog, closeSpaceSelectDialog] = useDialog(
    DialogNames.SPACE_SELECT,
  )

  useEffect(() => {
    if (R.isEmpty(assignedDevices) && spaceId) {
      dispatch(fetchAssignedToSpaceMonitors(spaceId))
    }
  }, [spaceId])

  const openMonitorApp = useCloseAfterCreation(() => {
    if (currentMonitorToken) {
      window.open(`${monitorUrl}&token=${currentMonitorToken}`, '_blank')
    }
  }, getIsGettingToken)

  const configureAndOpen = ({ id }: { id: string }) => {
    openMonitorApp()
    dispatch(
      getInRoomSpaceMonitor({
        spaceId: id,
      }),
    )
  }

  const openMonitorAfterPatchingAppointment = useCloseAfterCreation(
    (id) => R.isNil(validationError) && configureAndOpen({ id }),
    getTimetableIsLoading,
  )

  const registerAndOpen = ({
    appointmentTypeId,
    patientId,
    appointmentId,
  }: OpenAndRegisterProps) => {
    if (!spaceId && !appointmentId) {
      dispatch(
        registerWarnAlert(
          t('Common:CREATE_APPOPINTMENT_BEFORE_OPENING_MONITOR'),
        ),
      )
      return
    }

    if (!spaceId) {
      openSpaceSelectDialog({
        appointmentTypeId,
        patientId,
        handleSelectSpace: (id: string) => {
          openMonitorAfterPatchingAppointment(id)
          dispatch(
            patchAppointment({
              id: appointmentId!,
              spaceId: id,
            }),
          )
          closeSpaceSelectDialog()
        },
      })
      return
    }
    configureAndOpen({ id: spaceId })
  }

  return registerAndOpen
}

export default useOpenMonitorApp
