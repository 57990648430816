import { useState } from 'react'

import useWindowResize from './useWindowResize'

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useWindowResize(() => setWidth(window.innerWidth))
  return width
}

export default useWindowWidth
