import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Constant, LanguageUtils, Nil, Utils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  getInventoryInStockUnit,
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import { Adjustment } from '~/types'

enum InStockAmountType {
  UNIT = 'UNIT',
  PACKAGE = 'PACKAGE',
}

const isNotNil = R.complement(R.isNil)

const resolveEnumIds =
  (
    InventoryProductSizeUnit: Constant[],
    PackageType: Constant[],
    InventoryInStockUnit: Constant[],
  ) =>
  (
    item:
      | {
          amount?: number
          amountUnitsId?: string
          desiredOnHandAmount?: number | Nil
          packageTypeId?: string | Nil
          perPackageUnitsId?: string | Nil
        }
      | Nil,
  ) => {
    const {
      desiredOnHandAmount,
      amount,
      amountUnitsId,
      perPackageUnitsId,
      packageTypeId,
    } = item || {}

    const inventoryInStockUnitId = Utils.findConstantIdByName(
      'Package units',
      InventoryInStockUnit,
    )
    const inventoryInStockPackageUnitId = Utils.findConstantIdByName(
      'Package',
      InventoryInStockUnit,
    )

    const amountType =
      amountUnitsId === inventoryInStockUnitId
        ? InStockAmountType.UNIT
        : amountUnitsId === inventoryInStockPackageUnitId
          ? InStockAmountType.PACKAGE
          : null

    const unitName = LanguageUtils.getConstantTranslatedName(
      perPackageUnitsId,
      InventoryProductSizeUnit,
      i18n.t('Common:PACKAGE_UNITS'),
    )
    const packageName = LanguageUtils.getConstantTranslatedName(
      packageTypeId,
      PackageType,
      i18n.t('Common:PACKAGE'),
    )

    return { desiredOnHandAmount, amount, amountType, unitName, packageName }
  }

const getInStockAmountLabel = (
  amountType: InStockAmountType | Nil,
  unitName: string,
  packageName: string,
) => {
  if (amountType === InStockAmountType.UNIT) {
    return unitName
  }

  if (amountType === InStockAmountType.PACKAGE) {
    return packageName
  }

  return ''
}

const formatInStockAmount =
  (showPlus?: boolean, isAdjustment?: boolean) =>
  ({
    desiredOnHandAmount,
    amount,
    amountType,
    unitName,
    packageName,
  }: {
    amount?: number
    amountType: InStockAmountType | Nil
    desiredOnHandAmount?: number | Nil
    packageName: string
    unitName: string
  }) => {
    const { t } = useTranslation('Common')

    const amountPrefix =
      showPlus && R.isNil(desiredOnHandAmount) && !R.isNil(amount) && amount > 0
        ? '+'
        : ''

    const amountValue = desiredOnHandAmount ?? amount ?? 0
    const packagingLabel = getInStockAmountLabel(
      amountType,
      unitName,
      packageName,
    )

    const prefix = isAdjustment
      ? R.isNil(desiredOnHandAmount)
        ? t('Common:ADJUSTED_BY')
        : t('Common:SET_TOTAL_ON_HAND_TO')
      : null

    return [prefix, `${amountPrefix}${amountValue}`, packagingLabel]
      .filter(isNotNil)
      .join(' ')
  }

export const pickAdjustmentInStockInfo = (adjustment: Partial<Adjustment>) => {
  const {
    quantity: amount,
    quantityUnitId: amountUnitsId,
    desiredOnHandAmount,
    variation: { perPackageUnitsId, packageTypeId } = {},
  } = adjustment

  return {
    desiredOnHandAmount,
    amount,
    amountUnitsId,
    perPackageUnitsId,
    packageTypeId,
  }
}

export interface UseGetInStockAmountProps {
  isAdjustment?: boolean
  relative?: boolean
}

const useGetInStockAmount = ({
  relative,
  isAdjustment,
}: UseGetInStockAmountProps = {}) => {
  const InventoryInStockUnit = useSelector(getInventoryInStockUnit)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PackageType = useSelector(getPackageType)

  return R.pipe(
    resolveEnumIds(InventoryProductSizeUnit, PackageType, InventoryInStockUnit),
    formatInStockAmount(relative, isAdjustment),
  )
}

export default useGetInStockAmount
