import * as R from 'ramda'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  BulkPricesCategoryConstant,
  BulkPricesSubCategoryConstant,
} from '~/components/dashboard/admin/catalog/bulk-prices/BulkPricesEditFilters'
import { globalNavigate } from '~/components/GlobalHistory'
import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import { BulkPricesErrors } from '~/types'
import { detectAPIErrorType } from '~/utils/errors'

import {
  BulkPricesStateType,
  clearBulkPricesSession,
  deleteBulkPricesSession,
  deleteBulkPricesSessionFailure,
  deleteBulkPricesSessionSuccess,
  editPricesBulkAndManual,
  editPricesBulkAndManualFailure,
  editPricesBulkAndManualSuccess,
  fetchBulkPricesActiveSession,
  fetchBulkPricesActiveSessionFailure,
  fetchBulkPricesActiveSessionSuccess,
  fetchInventoryBulkPrices,
  fetchInventoryBulkPricesFailure,
  fetchInventoryBulkPricesPreview,
  fetchInventoryBulkPricesPreviewFailure,
  fetchInventoryBulkPricesPreviewSuccess,
  fetchInventoryBulkPricesSuccess,
  fetchLabTestsBulkPrices,
  fetchLabTestsBulkPricesFailure,
  fetchLabTestsBulkPricesPreview,
  fetchLabTestsBulkPricesPreviewFailure,
  fetchLabTestsBulkPricesPreviewSuccess,
  fetchLabTestsBulkPricesSuccess,
  fetchProceduresBulkPrices,
  fetchProceduresBulkPricesFailure,
  fetchProceduresBulkPricesPreview,
  fetchProceduresBulkPricesPreviewFailure,
  fetchProceduresBulkPricesPreviewSuccess,
  fetchProceduresBulkPricesSuccess,
  getAdjustByPercent,
  getAdjustRoundup,
  getAdjustValue,
  getAllSelected,
  getBulkPricesFilter,
  getConfirmedSessions,
  getHasManualPriceChanges,
  getManualPriceChangesMap,
  getPricesSelectionExceptions,
  startInventoryBulkPricesSession,
  startInventoryBulkPricesSessionFailure,
  startInventoryBulkPricesSessionSuccess,
  startLabTestsBulkPricesSession,
  startLabTestsBulkPricesSessionFailure,
  startLabTestsBulkPricesSessionSuccess,
  startPriceBulkUpdate,
  startPriceBulkUpdateFailure,
  startPriceBulkUpdateSuccess,
  startProceduresBulkPricesSession,
  startProceduresBulkPricesSessionFailure,
  startProceduresBulkPricesSessionSuccess,
} from '../duck/bulkPrices'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchInventoryBulkPricesSaga({
  payload: { from, to, category, subCategory },
}: ReturnType<typeof fetchInventoryBulkPrices>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchInventoryBulkPrices,
      from,
      to,
      category,
      subCategory,
    )
    const { data: list, totalCount } = result
    yield call(updateEntities, entities)
    yield put(fetchInventoryBulkPricesSuccess({ list, totalCount, from }))
  } catch (error) {
    yield put(fetchInventoryBulkPricesFailure({ error }))
  }
}

export function* fetchProceduresBulkPricesSaga({
  payload: { from, to, category },
}: ReturnType<typeof fetchProceduresBulkPrices>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchProceduresBulkPrices,
      from,
      to,
      category,
    )
    const { data: list, totalCount } = result
    yield call(updateEntities, entities)
    yield put(fetchProceduresBulkPricesSuccess({ list, totalCount, from }))
  } catch (error) {
    yield put(fetchProceduresBulkPricesFailure({ error }))
  }
}

export function* fetchLabTestsBulkPricesSaga({
  payload: { from, to, category },
}: ReturnType<typeof fetchLabTestsBulkPrices>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchLabTestsBulkPrices,
      from,
      to,
      category,
    )
    const { data: list, totalCount } = result
    yield call(updateEntities, entities)
    yield put(fetchLabTestsBulkPricesSuccess({ list, totalCount, from }))
  } catch (error) {
    yield put(fetchLabTestsBulkPricesFailure({ error }))
  }
}

export function* fetchInventoryBulkPricesPreviewSaga({
  payload: { from, to, sessionId },
}: ReturnType<typeof fetchInventoryBulkPricesPreview>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchInventoryBulkPricesPreview,
      from,
      to,
      sessionId,
    )
    const { data: list, totalCount } = result
    yield call(updateEntities, entities)
    yield put(
      fetchInventoryBulkPricesPreviewSuccess({ list, totalCount, from }),
    )
  } catch (error) {
    yield put(fetchInventoryBulkPricesPreviewFailure({ error }))
  }
}

export function* fetchProceduresBulkPricesPreviewSaga({
  payload: { from, to, sessionId },
}: ReturnType<typeof fetchProceduresBulkPricesPreview>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchProceduresBulkPricesPreview,
      from,
      to,
      sessionId,
    )
    const { data: list, totalCount } = result
    yield call(updateEntities, entities)
    yield put(
      fetchProceduresBulkPricesPreviewSuccess({ list, totalCount, from }),
    )
  } catch (error) {
    yield put(fetchProceduresBulkPricesPreviewFailure({ error }))
  }
}

export function* fetchLabTestsBulkPricesPreviewSaga({
  payload: { from, to, sessionId },
}: ReturnType<typeof fetchLabTestsBulkPricesPreview>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchLabTestsBulkPricesPreview,
      from,
      to,
      sessionId,
    )
    const { data: list, totalCount } = result
    yield call(updateEntities, entities)
    yield put(fetchLabTestsBulkPricesPreviewSuccess({ list, totalCount, from }))
  } catch (error) {
    yield put(fetchLabTestsBulkPricesPreviewFailure({ error }))
  }
}

export function* fetchBulkPricesActiveSessionSaga({
  payload: { entityType, silent },
}: ReturnType<typeof fetchBulkPricesActiveSession>) {
  try {
    const { result: activeSession, entities } = yield* requestAPI(
      API.fetchBulkPricesActiveSession,
      entityType,
    )
    const session = R.isEmpty(activeSession) ? null : activeSession
    yield call(updateEntities, entities)
    yield put(
      fetchBulkPricesActiveSessionSuccess({ entityType, silent, session }),
    )
  } catch (error) {
    yield put(fetchBulkPricesActiveSessionFailure({ error }))
  }
}

export function* startProceduresBulkPricesSessionSaga({
  payload,
}: ReturnType<typeof startProceduresBulkPricesSession>) {
  try {
    const sessionId = yield* requestAPI(
      API.startProceduresBulkPricesSession,
      payload?.force,
    )
    yield put(startProceduresBulkPricesSessionSuccess({ sessionId }))
    if (payload?.navigateTo) {
      globalNavigate(payload.navigateTo)
    }
  } catch (error) {
    yield put(startProceduresBulkPricesSessionFailure({ error }))
  }
}

export function* startLabTestsBulkPricesSessionSaga({
  payload,
}: ReturnType<typeof startLabTestsBulkPricesSession>) {
  try {
    const sessionId = yield* requestAPI(
      API.startLabTestsBulkPricesSession,
      payload?.force,
    )
    yield put(startLabTestsBulkPricesSessionSuccess({ sessionId }))
    if (payload?.navigateTo) {
      globalNavigate(payload.navigateTo)
    }
  } catch (error) {
    yield put(startLabTestsBulkPricesSessionFailure({ error }))
  }
}

export function* startInventoryBulkPricesSessionSaga({
  payload,
}: ReturnType<typeof startInventoryBulkPricesSession>) {
  try {
    const sessionId = yield* requestAPI(
      API.startInventoryBulkPricesSession,
      payload?.force,
    )
    yield put(startInventoryBulkPricesSessionSuccess({ sessionId }))
    if (payload?.navigateTo) {
      globalNavigate(payload.navigateTo)
    }
  } catch (error) {
    yield put(startInventoryBulkPricesSessionFailure({ error }))
  }
}

export function* deleteBulkPricesSessionSaga({
  payload: { sessionId, entityType },
}: ReturnType<typeof deleteBulkPricesSession>) {
  try {
    const confirmedSessions: string[] = yield select(getConfirmedSessions)
    if (!confirmedSessions.includes(sessionId)) {
      yield* requestAPI(API.deleteBulkPricesSession, sessionId)
    }
    yield put(deleteBulkPricesSessionSuccess({ sessionId, entityType }))
  } catch (error) {
    yield put(deleteBulkPricesSessionFailure({ error }))
  }
}

export function* startPriceBulkUpdateSaga({
  payload: { sessionId },
}: ReturnType<typeof startPriceBulkUpdate>) {
  try {
    yield* requestAPI(API.startPriceBulkUpdate, sessionId)
    yield put(startPriceBulkUpdateSuccess({ sessionId }))
  } catch (error) {
    yield put(startPriceBulkUpdateFailure({ error }))
  }
}

function* getPriceIdsForSave() {
  const allSelected: boolean = yield select(getAllSelected)
  const pricesSelectionExceptions: Record<string, boolean> = yield select(
    getPricesSelectionExceptions,
  )
  const activeSelectionExceptions = Object.keys(
    pricesSelectionExceptions,
  ).filter((id) => pricesSelectionExceptions[id])
  const manualPriceChangesMap: Record<string, number> = yield select(
    getManualPriceChangesMap,
  )

  return allSelected
    ? [...activeSelectionExceptions, ...Object.keys(manualPriceChangesMap)]
    : activeSelectionExceptions.filter((id) => !manualPriceChangesMap[id])
}

export function* savePriceBulkUpdateSaga({
  payload: { sessionId, entityType },
}: ReturnType<typeof editPricesBulkAndManual>) {
  const incrementValue: number = yield select(getAdjustValue)
  const adjustByPercent: boolean = yield select(getAdjustByPercent)
  const incrementType = adjustByPercent ? 'PERCENT' : 'ABSOLUTE'
  const roundUp: boolean = yield select(getAdjustRoundup)
  const allSelected: boolean = yield select(getAllSelected)
  const priceIds: string[] = yield getPriceIdsForSave()
  const includePrices = !allSelected

  switch (entityType) {
    case BulkPriceEntityTypes.INVENTORY:
      const inventoryCategoryId: string = yield select(
        getBulkPricesFilter(BulkPricesCategoryConstant.INVENTORY),
      )
      const subcategoryId: string = yield select(
        getBulkPricesFilter(BulkPricesSubCategoryConstant.INVENTORY),
      )
      yield* requestAPI(
        API.saveInventoryPriceBulkUpdateChange,
        sessionId,
        inventoryCategoryId,
        subcategoryId,
        incrementValue,
        incrementType,
        roundUp,
        includePrices,
        priceIds,
      )
      break
    case BulkPriceEntityTypes.LAB_TEST:
      const labVendorId: string = yield select(
        getBulkPricesFilter(BulkPricesCategoryConstant.LAB_VENDORS),
      )
      yield* requestAPI(
        API.saveLabTestPriceBulkUpdateChange,
        sessionId,
        labVendorId,
        incrementValue,
        incrementType,
        roundUp,
        includePrices,
        priceIds,
      )
      break
    case BulkPriceEntityTypes.PROCEDURE:
      const procedureCategoryId: string = yield select(
        getBulkPricesFilter(BulkPricesCategoryConstant.PROCEDURE),
      )
      yield* requestAPI(
        API.saveProcedurePriceBulkUpdateChange,
        sessionId,
        procedureCategoryId,
        incrementValue,
        incrementType,
        roundUp,
        includePrices,
        priceIds,
      )
      break
    default:
  }
}

export function* bulkEditPricesManualSaga({
  payload: { sessionId, entityType },
}: ReturnType<typeof editPricesBulkAndManual>) {
  const manualPriceChanges: BulkPricesStateType['manualPriceChanges'] =
    yield select(getManualPriceChangesMap)
  const prices = Object.keys(manualPriceChanges).map((key) => ({
    id: key,
    value: manualPriceChanges[key],
  }))

  yield* requestAPI(API.bulkEditPricesManual, sessionId, prices, entityType)
}

export function* editPricesBulkAndManualSaga(
  params: ReturnType<typeof editPricesBulkAndManual>,
) {
  try {
    const adjustValue: number | null = yield select(getAdjustValue)
    if (adjustValue) {
      yield call(savePriceBulkUpdateSaga, params)
    }

    const hasManualPriceChanges: boolean = yield select(
      getHasManualPriceChanges,
    )
    if (hasManualPriceChanges) {
      yield call(bulkEditPricesManualSaga, params)
    }

    yield put(editPricesBulkAndManualSuccess())
  } catch (error) {
    const errorType = detectAPIErrorType((error as ApiError).responseBody)
    if (errorType === BulkPricesErrors.MISMATCHED_SESSION_STATUS_ERROR_TYPE) {
      yield put(clearBulkPricesSession({ sessionId: params.payload.sessionId }))
    }
    yield put(editPricesBulkAndManualFailure({ error }))
  }
}

export default function* bulkPricesSaga() {
  yield all([
    takeLatest(fetchInventoryBulkPrices.type, fetchInventoryBulkPricesSaga),
    takeLatest(fetchProceduresBulkPrices.type, fetchProceduresBulkPricesSaga),
    takeLatest(fetchLabTestsBulkPrices.type, fetchLabTestsBulkPricesSaga),
    takeLatest(
      fetchInventoryBulkPricesPreview.type,
      fetchInventoryBulkPricesPreviewSaga,
    ),
    takeLatest(
      fetchProceduresBulkPricesPreview.type,
      fetchProceduresBulkPricesPreviewSaga,
    ),
    takeLatest(
      fetchLabTestsBulkPricesPreview.type,
      fetchLabTestsBulkPricesPreviewSaga,
    ),
    takeLatest(
      fetchBulkPricesActiveSession.type,
      fetchBulkPricesActiveSessionSaga,
    ),
    takeLatest(
      startProceduresBulkPricesSession.type,
      startProceduresBulkPricesSessionSaga,
    ),
    takeLatest(
      startLabTestsBulkPricesSession.type,
      startLabTestsBulkPricesSessionSaga,
    ),
    takeLatest(
      startInventoryBulkPricesSession.type,
      startInventoryBulkPricesSessionSaga,
    ),
    takeLatest(deleteBulkPricesSession.type, deleteBulkPricesSessionSaga),
    takeLatest(startPriceBulkUpdate.type, startPriceBulkUpdateSaga),
    takeLatest(editPricesBulkAndManual.type, editPricesBulkAndManualSaga),
  ])
}
