import * as R from 'ramda'

import i18n from '~/locales/i18n'

export const getUnblockInvoiceMessageByFlags = (
  isFinalizedSoap: boolean,
  isPostedInvoice: boolean,
  isSoapPage: boolean,
) =>
  R.cond([
    [
      R.equals([true, true, true]),
      R.always(i18n.t('Invoices:UNBLOCK_ALERT_MESSAGE.FINALIZED_POSTED_SOAP')),
    ],
    [
      R.equals([true, true, false]),
      R.always(i18n.t('Invoices:UNBLOCK_ALERT_MESSAGE.FINALIZED_POSTED')),
    ],
    [
      R.equals([true, false, true]),
      R.always(
        i18n.t('Invoices:UNBLOCK_ALERT_MESSAGE.FINALIZED_UNPOSTED_SOAP'),
      ),
    ],
    [
      R.equals([true, false, false]),
      R.always(i18n.t('Invoices:UNBLOCK_ALERT_MESSAGE.FINALIZED_UNPOSTED')),
    ],
    [
      R.equals([false, true, true]),
      R.always(
        i18n.t('Invoices:UNBLOCK_ALERT_MESSAGE.UNFINALIZED_POSTED_SOAP'),
      ),
    ],
    [
      R.equals([false, true, false]),
      R.always(i18n.t('Invoices:UNBLOCK_ALERT_MESSAGE.UNFINALIZED_POSTED')),
    ],
    [R.T, R.always(null)],
  ])([isFinalizedSoap, isPostedInvoice, isSoapPage])
