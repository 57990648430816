import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Field,
  PuiSelect,
  Text,
  Utils,
  WeightUnitsConstant,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import { changeUnits, getUnitsState } from '~/store/duck/settings'
import { getWeightUnits } from '~/store/reducers/constants'
import { useGetBusinessEnabledUnits } from '~/utils/measurementUnitsUtils'

import NumericInput from './NumericInput'

const useStyles = makeStyles(
  () => ({
    weightInput: {
      maxWidth: 40,
    },
  }),
  { name: 'WeightRangeInput' },
)

export interface WeightRangeInputProps {
  disabled?: boolean
  field?: Field
  maxWeightField: Field
  minWeightField: Field
}

const WeightRangeInput = ({
  disabled,
  field,
  maxWeightField,
  minWeightField,
}: WeightRangeInputProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const unitsState = useSelector(getUnitsState)
  const WeightUnits: WeightUnitsConstant[] = useSelector(getWeightUnits)
  const { t } = useTranslation('Common')

  const unit = field
    ? field.value
    : unitsState[UnitTypes.WEIGHT] || WeightTypes.LBS

  const items = useGetBusinessEnabledUnits(WeightUnits)

  const setUnit = (newUnit: WeightTypes) => {
    if (field) {
      field.setValue(newUnit)
    } else {
      dispatch(changeUnits({ [UnitTypes.WEIGHT]: newUnit }))
    }
  }

  return (
    <>
      <Grid item>
        <Text variant="body">
          {t('Common:INPUTS.WEIGHT_RANGE_INPUT.WEIGHT_RANGE')}:
        </Text>
      </Grid>
      <Grid item mx={0.5}>
        <NumericInput
          allowDecimal
          nullable
          className={classes.weightInput}
          disabled={disabled}
          field={minWeightField}
          inputProps={{ maxLength: 100 }}
          min={0}
          placeholder={t('Common:ALL').toLowerCase()}
        />
      </Grid>
      <Grid item>
        <Text variant="body2">
          {t('Common:INPUTS.WEIGHT_RANGE_INPUT.MIN_TO_MAX')}
        </Text>
      </Grid>
      <Grid item mx={0.5}>
        <NumericInput
          allowDecimal
          nullable
          className={classes.weightInput}
          disabled={disabled}
          field={maxWeightField}
          inputProps={{ maxLength: 100 }}
          min={0}
          placeholder={t('Common:ALL').toLowerCase()}
        />
      </Grid>
      <Grid item ml={1}>
        <PuiSelect
          disabled={disabled}
          input={<Input id="weight-units-select" />}
          items={items}
          keyProp={field ? undefined : 'name'}
          renderEmpty={false}
          value={unit}
          onChange={Utils.handleFormSelectInput(setUnit)}
        />
      </Grid>
    </>
  )
}

export default WeightRangeInput
