import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import { AddButton, Text, ValidateHandle } from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import { deleteBundle, editBundle, fetchBundle } from '~/store/actions/bundles'
import {
  getBundle,
  getBundlesIsDeleting,
  getBundlesIsFetching,
  getBundlesIsLoading,
} from '~/store/reducers/bundles'
import { Bundle as BundleType, BundleItem, Task } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import Bundle, { BundleHandle } from './Bundle'
import { Steps } from './BundleDialog'
import BundleItemsTable from './BundleItemsTable'
import BundleTasksTable from './BundleTasksTable'

const useStyles = makeStyles(
  () => ({
    bundleRoot: {
      padding: 0,
    },
  }),
  { name: 'BundleDetails' },
)

interface BundleDetailsProps {
  itemId: string
  onClose: () => void
}

const BundleDetails = ({ itemId, onClose }: BundleDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const bundle = useSelector(getBundle(itemId))
  const isLoading = useSelector(getBundlesIsLoading)
  const isDeleting = useSelector(getBundlesIsDeleting)
  const isFetching = useSelector(getBundlesIsFetching)
  const { t } = useTranslation(['Admin', 'Common'])

  const bundleRef = useRef<BundleHandle>(null)
  const bundleItemsTableRef = useRef<ValidateHandle>(null)

  const [openBundleTaskDialog] = useDialog(DialogNames.BUNDLE_TASK)
  const [openBundleDialog] = useDialog(DialogNames.BUNDLE)

  const setCloseOnDelete = useCloseAfterCreation(onClose, getBundlesIsDeleting)

  const [bundleCandidate, setBundleCandidate] = useState<BundleType>(
    bundle || {},
  )
  const [isBundleChanged, setIsBundleChanged] = useState(false)

  const getNewBundle = () => {
    const newBundle = bundleRef.current?.getBundle()

    if (bundleCandidate.items && newBundle) {
      newBundle.items = bundleCandidate.items
    }

    return newBundle
  }

  const getUnsavedData = () =>
    bundle && !R.isEmpty(bundle) && !deepEqual(bundle, getNewBundle())

  useEffect(() => {
    setBundleCandidate(bundle || {})
    setIsBundleChanged(false)
  }, [bundle])

  useEffect(() => {
    setIsBundleChanged(getUnsavedData())
  }, [bundleCandidate])

  useEffect(() => {
    if (itemId) {
      dispatch(fetchBundle(itemId))
    }
  }, [itemId])

  const onBundleReady = (newBundle: BundleType) => {
    setBundleCandidate({
      ...bundleCandidate,
      additionalDiscount: newBundle.additionalDiscount,
      items: newBundle.items,
    })
  }

  const onAddRequested = () => {
    openBundleDialog({
      bundle: bundleCandidate,
      finalizeProceedButtonLabel: t('Common:ADD_TO_BUNDLE'),
      step: Steps.ITEMS_LIST,
      onBundleReady,
    })
  }

  const deleteItem = (item: BundleItem) => {
    if (bundleRef.current?.getBundle() && bundleCandidate?.items?.length) {
      setBundleCandidate({
        ...bundleRef.current?.getBundle(),
        items: R.without([item], bundleCandidate.items),
      })
    }
  }

  const validate = () =>
    bundleRef.current?.validate() &&
    (!bundleItemsTableRef.current || bundleItemsTableRef.current?.validate())

  const onSaveRequested = () => {
    if (validate()) {
      const newBundle = getNewBundle()

      dispatch(editBundle(newBundle as BundleType))
    }
  }

  const onDeleteRequested = () => {
    setCloseOnDelete()
    dispatch(deleteBundle(itemId))
  }

  const onCloneRequested = () => {
    openBundleDialog({
      bundle: {
        ...bundleCandidate,
        name: `${bundleCandidate.name}_copy`,
        tasks: bundleCandidate.tasks?.map(R.omit(['id'])),
      } as BundleType,
      isClone: true,
      step: Steps.INITIAL,
    })
  }

  const onBundleTaskDialogProceed = (task: Task, index?: number) => {
    const oldTasks = bundleCandidate.tasks || []
    setBundleCandidate({
      ...bundleCandidate,
      tasks: R.isNil(index)
        ? oldTasks.concat(task)
        : R.update(index, task, oldTasks),
    })
  }

  const onEditTask = (index: number) => {
    if (bundleCandidate?.tasks) {
      openBundleTaskDialog({
        task: bundleCandidate.tasks[index],
        onProceed: (task: Task) => onBundleTaskDialogProceed(task, index),
      })
    }
  }

  const onDeleteTask = (index: number) => {
    if (bundleCandidate?.tasks) {
      setBundleCandidate({
        ...bundleCandidate,
        tasks: R.remove(index, 1, bundleCandidate?.tasks),
      })
    }
  }

  const onAddTask = () => {
    openBundleTaskDialog({
      onProceed: onBundleTaskDialogProceed,
    })
  }

  const onChange = () => {
    setIsBundleChanged(getUnsavedData())
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:BUNDLE_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isBundleChanged}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isLoading}
      onBack={onClose}
      onCloneRequested={onCloneRequested}
      onDeleteRequested={onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <Bundle
        view
        bundle={bundleCandidate}
        classes={{
          root: classes.bundleRoot,
        }}
        ref={bundleRef}
        onChange={onChange}
      />
      <Grid item mt={5}>
        <Text strong mb={1} variant="subheading3">
          {t('Admin:CATALOG.BUNDLE_DETAILS.ITEMS_IN_BUNDLE')}
        </Text>
        {bundleCandidate?.items?.length && (
          <BundleItemsTable
            bundlePreview
            bundle={bundleCandidate}
            ref={bundleItemsTableRef}
            onBundleChange={setBundleCandidate}
            onDelete={deleteItem}
          />
        )}
      </Grid>
      <Grid item mt={2}>
        <AddButton addText={t('Common:ADD_ITEM')} onAdd={onAddRequested} />
      </Grid>
      <BundleTasksTable
        bundle={bundleCandidate}
        onAdd={onAddTask}
        onDelete={onDeleteTask}
        onEdit={onEditTask}
      />
    </Expander>
  )
}

export default BundleDetails
