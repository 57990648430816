import React from 'react'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'

import ProblemsFindingOrDiagnosisIcon from '../identified-problems/ProblemsFindingOrDiagnosisIcon'

interface ProblemsCheckboxLabelProps {
  name: string
  type: ProblemType
}

const ProblemsCheckboxLabel = ({ name, type }: ProblemsCheckboxLabelProps) => (
  <Grid container direction="row">
    <ProblemsFindingOrDiagnosisIcon type={type} />
    <Grid item>
      <Text variant="body2">{name}</Text>
    </Grid>
  </Grid>
)

export default ProblemsCheckboxLabel
