import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { Space, UnsavedSpace } from '~/types'

import {
  CLEAR_SPACES_LIST_FOR_APPOINTMENT,
  CREATE_SPACE,
  CREATE_SPACE_FAILURE,
  CREATE_SPACE_SUCCESS,
  DELETE_SPACE,
  DELETE_SPACE_FAILURE,
  DELETE_SPACE_SUCCESS,
  EDIT_SPACE,
  EDIT_SPACE_FAILURE,
  EDIT_SPACE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST_SUCCESS,
  FETCH_SPACE,
  FETCH_SPACE_FAILURE,
  FETCH_SPACE_SUCCESS,
  FETCH_SPACES_LIST,
  FETCH_SPACES_LIST_FAILURE,
  FETCH_SPACES_LIST_FOR_APPOINTMENT,
  FETCH_SPACES_LIST_FOR_APPOINTMENT_FAILURE,
  FETCH_SPACES_LIST_FOR_APPOINTMENT_SUCCESS,
  FETCH_SPACES_LIST_SUCCESS,
  FETCH_SPACES_LIST_WITH_TYPE,
  FETCH_SPACES_LIST_WITH_TYPE_FAILURE,
  FETCH_SPACES_LIST_WITH_TYPE_SUCCESS,
  UPDATE_SPACES,
} from './types/spaces'

export const fetchSpacesList = (
  from?: number,
  to?: number,
  query?: string | Nil,
) => ({
  type: FETCH_SPACES_LIST,
  from,
  to,
  query,
})
export const fetchSpacesListSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_SPACES_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchSpacesListFailure = (error: ApiError) => ({
  type: FETCH_SPACES_LIST_FAILURE,
  error,
})

export const fetchSpacesListWithType = (typeId: string) => ({
  type: FETCH_SPACES_LIST_WITH_TYPE,
  typeId,
})
export const fetchSpacesListWithTypeSuccess = (list: string[]) => ({
  type: FETCH_SPACES_LIST_WITH_TYPE_SUCCESS,
  list,
})
export const fetchSpacesListWithTypeFailure = (error: ApiError) => ({
  type: FETCH_SPACES_LIST_WITH_TYPE_FAILURE,
  error,
})

export const fetchSpace = (id: string) => ({ type: FETCH_SPACE, id })
export const fetchSpaceFailure = (error: ApiError) => ({
  type: FETCH_SPACE_FAILURE,
  error,
})
export const fetchSpaceSuccess = () => ({ type: FETCH_SPACE_SUCCESS })

export const fetchMoreItemsForSpacesList = (
  from: number,
  to: number,
  query?: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_SPACES_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForSpacesListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_SPACES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForSpacesListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_SPACES_LIST_FAILURE,
  error,
})

export const createSpace = (space: UnsavedSpace) => ({
  type: CREATE_SPACE,
  space,
})
export const createSpaceSuccess = (spaceId: string) => ({
  type: CREATE_SPACE_SUCCESS,
  spaceId,
})
export const createSpaceFailure = (error: ApiError) => ({
  type: CREATE_SPACE_FAILURE,
  error,
})

export const editSpace = (space: Space) => ({ type: EDIT_SPACE, space })
export const editSpaceSuccess = () => ({ type: EDIT_SPACE_SUCCESS })
export const editSpaceFailure = (error: ApiError) => ({
  type: EDIT_SPACE_FAILURE,
  error,
})

export const deleteSpace = (spaceId: string) => ({
  type: DELETE_SPACE,
  spaceId,
})
export const deleteSpaceSuccess = (spaceId: string) => ({
  type: DELETE_SPACE_SUCCESS,
  spaceId,
})
export const deleteSpaceFailure = (error: ApiError) => ({
  type: DELETE_SPACE_FAILURE,
  error,
})

export const updateSpaces = (
  spaces: Record<string, Space>,
  updateList?: boolean,
) => ({
  type: UPDATE_SPACES,
  spaces,
  updateList,
})

export const fetchSpacesListForAppointment = (
  appointmentTypeId: string,
  patientId: string | Nil,
) => ({
  type: FETCH_SPACES_LIST_FOR_APPOINTMENT,
  appointmentTypeId,
  patientId,
})
export const fetchSpacesListForAppointmentFailure = (error: ApiError) => ({
  type: FETCH_SPACES_LIST_FOR_APPOINTMENT_FAILURE,
  error,
})
export const fetchSpacesListForAppointmentSuccess = (list: string[]) => ({
  type: FETCH_SPACES_LIST_FOR_APPOINTMENT_SUCCESS,
  list,
})

export const clearSpacesListForAppointment = () => ({
  type: CLEAR_SPACES_LIST_FOR_APPOINTMENT,
})
