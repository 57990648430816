import React, { forwardRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    itemContainer: {
      width: '100%',
      margin: 0,
    },
    itemContainerTo: {
      backgroundColor: '#FAF8F5',
    },
    selectContainer: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'Selector' },
)

export interface SelectorProps {
  FromInput: React.ReactNode
  FromSelect: React.ReactNode
  ToInput: React.ReactNode
  ToSelect: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  fromLabel: React.ReactNode
  range: boolean
  toLabel: React.ReactNode
}

const Selector = forwardRef<HTMLDivElement, SelectorProps>(function Selector(
  {
    classes: classesProp,
    FromInput,
    FromSelect,
    ToInput,
    ToSelect,
    fromLabel,
    toLabel,
    range,
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid container item className={classes.root} ref={ref}>
      <Grid
        container
        item
        alignItems="center"
        className={classes.itemContainer}
        px={range ? 3 : 2}
        wrap="nowrap"
      >
        {fromLabel && (
          <Grid item xs>
            <Text strong variant="subheading3">
              {fromLabel}
            </Text>
          </Grid>
        )}
        {FromInput}
        <Grid item xs className={classes.selectContainer}>
          {FromSelect}
        </Grid>
      </Grid>
      {range && (
        <Grid
          container
          alignItems="center"
          className={classNames(classes.itemContainer, classes.itemContainerTo)}
          px={3}
          wrap="nowrap"
        >
          {toLabel && (
            <Grid item xs>
              <Text strong variant="subheading3">
                {toLabel}
              </Text>
            </Grid>
          )}
          {ToInput}
          <Grid item xs className={classes.selectContainer}>
            {ToSelect}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
})

export default Selector
