import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Grid, GridProps, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { RhapsodyAahaBenchmarkPlus } from '@pbt/pbt-ui-components/src/icons'

import BenchmarkingLoginBackground from '~/components/common/images/BenchmarkingLoginBackground.svg'
import { isAAHA, isBenchmarking } from '~/utils'

import BenchmarkingLogo from '../images/BenchmarkingLogo.png'
import ChewyHealth from '../images/chewyHealth.png'
import CreepingCat from '../images/CreepingCat.png'
import DoctorDoggie from '../images/DoctorDoggie.png'
import LoginBackground from '../images/LoginBackground.svg'
import RhapsodyLogo from '../RhapsodyLogo'

const DEFAULT_WIDGET_MAX_WIDTH = 480

const useStyles = makeStyles(
  (theme) => ({
    form: {
      display: 'block', // Fix IE 11 issue.
      [theme.breakpoints.down('md')]: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    pageContainer: {
      backgroundImage: `url(${LoginBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center',
      backgroundSize: '100%, auto',
      [theme.breakpoints.down('lg')]: {
        backgroundSize: 'auto auto',
      },
      [theme.breakpoints.down('sm')]: {
        overflow: 'hidden',
      },
      minHeight: '100vh',
    },
    benchmarkingBackground: {
      backgroundImage: `url(${BenchmarkingLoginBackground})`,
    },
    wrapper: {
      backgroundColor: theme.colors.loginFormBackground,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      boxShadow:
        '-1px -1px 8px 0 rgba(99,95,95,0.1), 1px 1px 8px 0 rgba(0,0,0,0.1)',
      borderRadius: 4,
      marginBottom: theme.spacing(1),
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    catImage: {
      pointerEvents: 'none',
      position: 'absolute',
      top: -50,
      right: -40,
      [theme.breakpoints.down('sm')]: {
        top: -42,
        right: -30,
        height: 82,
      },
      height: 100,
    },
    dogImage: {
      pointerEvents: 'none',
      position: 'absolute',
      bottom: 0,
      left: -120,
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        height: 121,
        left: 0,
        alignSelf: 'flex-start',
      },
      height: 155,
    },
    rhapsodyLogo: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(15),
      [theme.breakpoints.down('lg')]: {
        paddingBottom: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(6),
      },
    },
    logoIcon: {
      [theme.breakpoints.down('sm')]: {
        height: 50,
      },
    },
    logoAahaIcon: {
      width: 350,
      [theme.breakpoints.down('sm')]: {
        height: 50,
      },
    },
    logoBenchmarking: {
      width: 350,
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(10),
      },
    },
    termsAndConditionsLink: {
      fontSize: '1.4rem',
      textDecoration: 'none',
      color: theme.colors.lowAccentText,
      cursor: 'pointer',
    },
  }),
  { name: 'LoginPage' },
)

interface LoginPageProps {
  WrapperProps?: GridProps
  children: React.ReactElement
  showTermsAndConditions?: boolean
}

const LoginPage = ({
  children,
  WrapperProps,
  showTermsAndConditions,
}: LoginPageProps) => {
  const location = useLocation()
  const classes = useStyles()
  const { t } = useTranslation(['Auth', 'Common', 'PageTitles'])

  const isActivationLandingPage = location.pathname === '/auth/activation'
  const isAahaBrandingPage = isAAHA && isActivationLandingPage

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(
        classes.pageContainer,
        isBenchmarking && classes.benchmarkingBackground,
      )}
      direction="column"
      wrap="nowrap"
    >
      {isBenchmarking ? (
        <img
          alt="benchmarking-plus"
          className={classes.logoBenchmarking}
          src={BenchmarkingLogo}
        />
      ) : (
        <RhapsodyLogo
          Component={isAahaBrandingPage ? RhapsodyAahaBenchmarkPlus : undefined}
          className={classes.rhapsodyLogo}
          classes={{
            logo: isAahaBrandingPage ? classes.logoAahaIcon : classes.logoIcon,
          }}
        />
      )}
      <Grid
        container
        alignItems="center"
        className={classes.wrapper}
        direction="column"
        justifyContent="center"
        maxWidth={DEFAULT_WIDGET_MAX_WIDTH}
        {...WrapperProps}
      >
        {!isAahaBrandingPage && !isBenchmarking && (
          <img
            alt={t('PageTitles:CAT')}
            className={classes.catImage}
            src={CreepingCat}
          />
        )}
        {children}
        {!isAahaBrandingPage && !isBenchmarking && (
          <img
            alt={t('PageTitles:DOG')}
            className={classes.dogImage}
            src={DoctorDoggie}
          />
        )}
      </Grid>
      {isAahaBrandingPage && (
        <img
          alt="chewy-health"
          src={ChewyHealth}
          style={{
            margin: 'auto',
            width: 100,
          }}
        />
      )}
      {showTermsAndConditions && (
        <Grid
          container
          item
          alignItems="flex-end"
          flex={1}
          justifyContent="center"
          p={2}
        >
          <Link
            className={classes.termsAndConditionsLink}
            href="/terms-and-conditions"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('Auth:LOGIN_PAGE.TERMS_AND_CONDITIONS.LINK')}
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

export default LoginPage
