import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DialogNames from '~/constants/DialogNames'
import {
  getClientId,
  getFiles,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import useDialog from '~/utils/useDialog'

import Attachment from './Attachment'

const useStyles = makeStyles(
  () => ({
    root: {
      flexShrink: 0,
    },
    button: {
      minWidth: 162,
      height: 40,
    },
  }),
  { name: 'Attachments' },
)

export interface AttachmentsProps {
  editDisabled?: boolean
  expanded?: boolean
}

const Attachments = ({ expanded, editDisabled }: AttachmentsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const files = useSelector(getFiles)

  const [openSoapAttachmentDialog] = useDialog(DialogNames.SOAP_ATTACHMENT)

  return (
    <Grid
      container
      item
      alignItems="flex-end"
      className={classes.root}
      direction="column"
      px={2}
    >
      {!editDisabled && (
        <Grid item mb={2}>
          <Fab
            className={classes.button}
            color="inherit"
            variant="extended"
            onClick={() =>
              openSoapAttachmentDialog({
                clientId,
                patientId,
                soapId,
                soapBusinessId,
              })
            }
          >
            {t('Common:ADD_ATTACHMENT')}
          </Fab>
        </Grid>
      )}
      <Grid container item direction="column">
        {files.map((attachment) => (
          <Attachment
            attachment={attachment}
            editDisabled={editDisabled}
            expanded={expanded}
            key={attachment.id}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default Attachments
