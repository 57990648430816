import React from 'react'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    text: {
      color: 'inherit !important',
    },
  }),
  { name: 'PaginationDirection' },
)

export interface PaginationDirectionProps {
  direction?: 'prev' | 'next'
}

export const PaginationDirection = ({
  direction,
}: PaginationDirectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Box alignItems="center" display="flex">
      {direction === 'prev' ? (
        <>
          <KeyboardArrowLeft />
          <Text className={classes.text} variant="body3">
            {t('Common:PREVIOUS_SHORT')}
          </Text>
        </>
      ) : (
        <>
          <Text className={classes.text} variant="body3">
            {t('Common:NEXT')}
          </Text>
          <KeyboardArrowRight />
        </>
      )}
    </Box>
  )
}
