import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Business,
  CircularProgressOverlay,
  HtmlNotesPreview,
} from '@pbt/pbt-ui-components'

import MobilePhoneChatPreview from '~/components/common/preview/MobilePhoneChatPreview'
import {
  fetchBoopSLAMessagesDummyTemplate,
  getBoopSLAMessageDummyTemplate,
  getBoopSLAMessagesTemplateIsReceiving,
} from '~/store/duck/boop'
import { AutoReplyMessageConfig } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    previewRoot: {
      margin: theme.spacing(-1, 2, 0, -2),
    },
    content: {
      backgroundColor: '#F9F0E5',
    },
  }),
  { name: 'BoopSlaMessagesPreview' },
)

export interface BoopSlaMessagesPreviewProps
  extends Omit<GridProps, 'wrap' | 'item' | 'container'> {
  business: Business
  className?: string
  config: AutoReplyMessageConfig
}

const BoopSlaMessagesPreview = ({
  business,
  config,
  className,
  ...rest
}: BoopSlaMessagesPreviewProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const textTemplate = useSelector(getBoopSLAMessageDummyTemplate)
  const isReceiving = useSelector(getBoopSLAMessagesTemplateIsReceiving)

  useEffect(() => {
    if (config) {
      dispatch(fetchBoopSLAMessagesDummyTemplate(config))
    }
  }, [config])

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      wrap="nowrap"
      {...rest}
    >
      <CircularProgressOverlay
        open={isReceiving}
        preloaderText={t('Common:LOADING')}
      />
      <Grid item>
        <MobilePhoneChatPreview
          classes={{
            root: classes.previewRoot,
            content: classes.content,
          }}
          sender={business.name}
        >
          {textTemplate && (
            <HtmlNotesPreview fontSize="1.2rem" notes={textTemplate} />
          )}
        </MobilePhoneChatPreview>
      </Grid>
    </Grid>
  )
}

export default BoopSlaMessagesPreview
