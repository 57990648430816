import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { path } from 'ramda'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getLocationDisplayName } from '~/store/reducers/constants'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import TreeViewNode from './TreeViewNode'

const useStyles = makeStyles(
  (theme) => ({
    details: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(1),
    },
    notes: {
      marginBottom: theme.spacing(0.5),
    },
  }),
  { name: 'FindingsView' },
)

const LocationWithNotes = ({
  locationId,
  state,
  renderDimensions,
  dimensions,
  expandedState,
  setExpandedState,
  onChange,
  subCategoryName,
  onLocationNotesChange,
  onLocationNotesSave,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  const checked = Boolean(path([locationId])(state))
  const notes = path([locationId, 'notes'])(state)

  const locationName = useSelector(getLocationDisplayName(locationId))

  return (
    <>
      <TreeViewNode
        checkable
        iconAlignRight
        checked={checked}
        classes={{ details: classes.details }}
        content={renderDimensions(dimensions, locationId)}
        expandable={dimensions && dimensions.length > 0}
        expanded={Boolean(expandedState[locationId])}
        title={LanguageUtils.capitalize(locationName)}
        onChange={() => {
          setExpandedState({
            ...expandedState,
            [locationId]: !expandedState[locationId],
          })
          if (!expandedState[locationId]) {
            onChange({
              location: locationId,
              locationName,
              uncheckLocation: false,
            })
          }
        }}
        onCheckChange={(event, value) => {
          onChange({
            location: locationId,
            locationName,
            uncheckLocation: !value,
          })
        }}
      />
      {checked && (
        <NotesTemplateInput
          hidePanel
          isSoap
          className={classes.notes}
          key={`${subCategoryName}${locationId}`}
          maxHeight={332}
          title={t('Soap:LOCATION_WITH_NOTES.NOTES_TEMPLATE_INPUT_TITLE', {
            locationName,
          })}
          value={notes || ''}
          onChange={onLocationNotesChange}
          onSave={(notes) => onLocationNotesSave(locationId, notes)}
        />
      )}
    </>
  )
}

export default LocationWithNotes
