import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { DataHandle } from '~/types'

import DayTimeRecurrenceGroupItem, {
  DayTimeRecurrenceGroupItemHandle,
} from './DayTimeRecurrenceGroupItem'

const getInitialItems = (group: string[]) => {
  const items = group.length > 0 ? group : [R.__]
  return items.map((item, index) => ({
    id: index,
    ref: React.createRef<DayTimeRecurrenceGroupItemHandle>(),
  }))
}

type ItemRef = {
  id: number
  ref: React.RefObject<DayTimeRecurrenceGroupItemHandle>
}

export interface DayTimeRecurrenceGroupProps {
  className?: string
  disabled?: boolean
  group: string[] | Nil
  onRefChange?: () => void
}

export interface DayTimeRecurrenceGroupHandle extends DataHandle {}

const DayTimeRecurrenceGroup = forwardRef(function DayTimeRecurrenceGroup(
  {
    className,
    disabled,
    group: groupProp,
    onRefChange,
  }: DayTimeRecurrenceGroupProps,
  ref: ForwardedRef<DayTimeRecurrenceGroupHandle>,
) {
  const group = groupProp || []

  const [itemRefs, setItemRefs] = useState<ItemRef[]>(getInitialItems(group))

  const addItem = () => {
    setItemRefs([
      ...itemRefs,
      { id: (R.last(itemRefs)?.id || -1) + 1, ref: React.createRef() },
    ])
    onRefChange?.()
  }

  const removeItem = (id: number) => {
    const index = R.findIndex(R.propEq('id', id))(itemRefs)
    setItemRefs(R.remove(index, 1, itemRefs))
    onRefChange?.()
  }

  useImperativeHandle(ref, () => ({
    validate: () =>
      itemRefs.every((itemRef) => itemRef.ref.current?.validate()),
    get: () => itemRefs.map((itemRef) => itemRef.ref.current?.get()),
  }))

  return (
    <Grid container item className={className} direction="column">
      {itemRefs.map((itemRef, index) => (
        <DayTimeRecurrenceGroupItem
          disabled={disabled}
          initialValue={group[index]}
          key={itemRef.id}
          ref={itemRef.ref}
          showAddButton={index === itemRefs.length - 1}
          showRemoveButton={itemRefs.length > 1}
          onAddItem={addItem}
          onRefChange={onRefChange}
          onRemoveItem={() => removeItem(itemRef.id)}
        />
      ))}
    </Grid>
  )
})

export default DayTimeRecurrenceGroup
