import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'
import { formatDate } from '@pbt/pbt-ui-components/src/utils/dateUtils'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import {
  canRestockInvoiceItem,
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
  isUnrefundable,
} from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import {
  getFeatureToggle,
  getRefundInvoiceState,
} from '~/store/reducers/constants'
import { getInvoiceV3SubItemById } from '~/store/reducers/invoiceV3'
import { InvoiceLineItem, RefundLineItem } from '~/types'

import FinanceItemCreationDateCellBody from '../body/FinanceItemCreationDateCellBody'
import FinanceItemDiscountCellBody from '../body/FinanceItemDiscountCellBody'
import FinanceItemProducerCellBody from '../body/FinanceItemProducerCellBody'
import FinanceItemQuantityCellBody from '../body/FinanceItemQuantityCellBody'
import FinanceItemSubTotalCellBody from '../body/FinanceItemSubTotalCellBody'
import FinanceItemTaxCellBodyBody from '../body/FinanceItemTaxCellBody'
import FinanceItemUnitPriceCellBody from '../body/FinanceItemUnitPriceCellBody'
import RetailOrderItemNameCellBody from '../body/RetailOrderItemNameCellBody'

const { DISCOUNT, PRODUCER, QUANTITY, REFUNDED, SUBTOTAL, TAX, UNIT_PRICE } =
  FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  (theme) => ({
    creationDate: {
      opacity: '50%',
    },
    groupedCell: {
      padding: 0,
    },
    tableCellWithBorder: {
      borderRight: theme.constants.tableBorder,
    },
    refundedCell: {
      minWidth: REFUNDED,
      width: REFUNDED,
    },
    producerCell: {
      minWidth: PRODUCER,
      width: PRODUCER,
    },
    quantityCell: {
      minWidth: QUANTITY,
      width: QUANTITY,
    },
    unitPriceCell: {
      minWidth: UNIT_PRICE,
      width: UNIT_PRICE,
    },
    taxCell: {
      minWidth: TAX,
      width: TAX,
    },
    discountCell: {
      minWidth: DISCOUNT,
      width: DISCOUNT,
    },
    subtotalCell: {
      minWidth: SUBTOTAL,
      width: SUBTOTAL,
    },
    bundleCell: {
      justifyContent: 'center',
    },
    fullyRefunded: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'FinanceTableItemCells' },
)

export interface FinanceTableItemCellsProps {
  columnsLength: number
  currentItemId?: string
  handleSelectItem: (item: InvoiceLineItem | RetailOrderLineItem) => void
  invoiceHasRefunds?: boolean
  invoiceId?: string
  isExpandedSelector?: any
  isInvoice?: boolean
  isVoidedState?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  nested?: boolean
  selectedItem: InvoiceLineItem | RetailOrderLineItem | undefined
  showUsedPrepaid?: boolean
  soapId?: string
  tableCellClassName: string
  toggleExpand?: (item: InvoiceLineItem | RetailOrderLineItem) => void
}

const FinanceTableItemCells = ({
  columnsLength,
  handleSelectItem,
  isInvoice = false,
  invoiceHasRefunds = false,
  item,
  nested = false,
  selectedItem,
  soapId,
  tableCellClassName,
  isExpandedSelector,
  invoiceId,
  toggleExpand,
  showUsedPrepaid,
  isVoidedState = false,
  currentItemId,
}: FinanceTableItemCellsProps) => {
  const classes = useStyles()

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )
  const isExpanded = useSelector(
    isExpandedSelector ? isExpandedSelector(item) : R.F,
  ) as boolean
  const itemSection = useSelector(getInvoiceV3SubItemById(currentItemId))

  const isGrouped = isGroupedInvoiceItem(item)

  const refundInvoiceStates = useSelector(getRefundInvoiceState)

  const voidedStateId = findConstantIdByName('Voided', refundInvoiceStates)

  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)

  const toggleExpandInner = (i: InvoiceLineItem | RetailOrderLineItem) => {
    if (isGrouped && toggleExpand) {
      toggleExpand(i)
    }
  }

  const isFinalized = Boolean(R.path(['soap', 'finalized'], itemSection))

  const isFullyRefunded =
    isIpoM0InvoiceRefundsEnabled && invoiceHasRefunds
      ? isGrouped && !isItemRetailOrderLineItem
        ? item.items?.every(isUnrefundable)
        : isUnrefundable(item) ||
          (item.subTotal === 0 && !canRestockInvoiceItem(item))
      : false

  const CommonProps = {
    isExpanded: isGrouped ? isExpanded : false,
    isGrouped,
    item,
    tableCellClassName,
    nested,
    isReadOnly: isEditPostedChargesEnabled
      ? isInvoice
        ? isGrouped || isVoidedState || isFinalized
        : false
      : isInvoice,
    isFullyRefunded,
  }

  const notVoid = (refundItem: RefundLineItem) =>
    refundItem.refundedInvoice.state.id !== voidedStateId

  const refundLineItems = isItemRetailOrderLineItem
    ? []
    : R.filter(notVoid, item.refundLineItems || [])

  return (
    <>
      {isGrouped && !isItemRetailOrderLineItem && item.items ? (
        <TableCell
          className={classes.groupedCell}
          colSpan={columnsLength}
          id={item.group}
          scope="row"
        >
          <Grid container wrap="nowrap">
            {!isInvoice && <FinanceItemCreationDateCellBody {...CommonProps} />}
            <RetailOrderItemNameCellBody
              showAsDisabled={isFullyRefunded}
              {...CommonProps}
              handleSelectItem={handleSelectItem}
              selectedItem={selectedItem}
              tableCellClassName={classNames(
                CommonProps.tableCellClassName,
                classes.tableCellWithBorder,
              )}
              toggleExpand={toggleExpandInner}
            />
            {isIpoM0InvoiceRefundsEnabled && invoiceHasRefunds && (
              <Grid
                className={classNames(
                  CommonProps.tableCellClassName,
                  classes.tableCellWithBorder,
                  classes.refundedCell,
                )}
              />
            )}
            <Grid
              className={classNames(
                classes.tableCellWithBorder,
                classes.producerCell,
              )}
            />
            <Grid
              className={classNames(
                classes.tableCellWithBorder,
                classes.quantityCell,
              )}
            />
            {isInvoice ? (
              <>
                <FinanceItemTaxCellBodyBody
                  {...CommonProps}
                  tableCellClassName={classes.bundleCell}
                  tableCellWithBorder={classes.tableCellWithBorder}
                />
                {R.prop('discount', item) ? (
                  <FinanceItemDiscountCellBody
                    {...CommonProps}
                    tableCellClassName={classes.bundleCell}
                    tableCellWithBorder={classes.tableCellWithBorder}
                  />
                ) : (
                  <Grid
                    className={classNames(
                      classes.tableCellWithBorder,
                      classes.discountCell,
                    )}
                  />
                )}
              </>
            ) : (
              <Grid
                className={classNames(
                  classes.tableCellWithBorder,
                  classes.unitPriceCell,
                )}
              />
            )}
            <Grid
              container
              item
              alignItems="center"
              className={classes.subtotalCell}
              justifyContent="flex-end"
              pl={1}
              pr={3}
            >
              {!item.declined && (
                <Text
                  className={classNames({
                    [classes.fullyRefunded]: isFullyRefunded,
                  })}
                  variant="body2"
                >
                  {NumberUtils.formatMoney(item.subTotal)}
                </Text>
              )}
            </Grid>
          </Grid>
          {isExpanded &&
            item.items.map((childItem) => (
              <Table key={`${childItem.bundleId}_${childItem.id}`}>
                <TableBody>
                  <TableRow id={childItem.logId}>
                    <FinanceTableItemCells
                      nested
                      columnsLength={columnsLength}
                      currentItemId={currentItemId}
                      handleSelectItem={handleSelectItem}
                      invoiceHasRefunds={invoiceHasRefunds}
                      invoiceId={invoiceId}
                      isInvoice={isInvoice}
                      item={childItem}
                      selectedItem={selectedItem}
                      showUsedPrepaid={showUsedPrepaid}
                      soapId={soapId}
                      tableCellClassName={tableCellClassName}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            ))}
        </TableCell>
      ) : (
        <>
          {!isInvoice && <FinanceItemCreationDateCellBody {...CommonProps} />}

          <RetailOrderItemNameCellBody
            isInvoice={isInvoice}
            showAsDisabled={isFullyRefunded}
            {...CommonProps}
            handleSelectItem={handleSelectItem}
            selectedItem={selectedItem}
            tableCellWithBorder={classes.tableCellWithBorder}
            toggleExpand={toggleExpandInner}
          />
          {isIpoM0InvoiceRefundsEnabled && invoiceHasRefunds && (
            <TableCell
              className={classNames(
                tableCellClassName,
                classes.tableCellWithBorder,
                classes.refundedCell,
              )}
            >
              <Grid container direction="column">
                {refundLineItems?.map((refundItem) => (
                  <Grid item key={refundItem.id}>
                    <Link
                      className={classes.fullyRefunded}
                      to={`/refund/${refundItem.refundedInvoice.id}`}
                    >
                      {formatDate(refundItem.refundedInvoice.creationDate)}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </TableCell>
          )}

          <FinanceItemProducerCellBody
            {...CommonProps}
            allowModifyForInvoiceDoctorOnly={isInvoice}
            invoiceId={invoiceId}
            tableCellWithBorder={classes.tableCellWithBorder}
          />

          <FinanceItemQuantityCellBody
            {...CommonProps}
            showUsedPrepaid={showUsedPrepaid}
            tableCellWithBorder={classes.tableCellWithBorder}
          />

          {isInvoice ? (
            <>
              <FinanceItemTaxCellBodyBody
                {...CommonProps}
                tableCellWithBorder={classes.tableCellWithBorder}
              />
              <FinanceItemDiscountCellBody
                {...CommonProps}
                tableCellWithBorder={classes.tableCellWithBorder}
              />
            </>
          ) : (
            <FinanceItemUnitPriceCellBody
              {...CommonProps}
              tableCellWithBorder={classes.tableCellWithBorder}
            />
          )}

          <FinanceItemSubTotalCellBody {...CommonProps} />
        </>
      )}
    </>
  )
}

export default FinanceTableItemCells
