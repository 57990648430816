import { gql } from '@apollo/client/core'

import { CONVERSATION_DTO_FIELDS } from '../fragments'

export const CREATE_CONVERSATION = gql`
  ${CONVERSATION_DTO_FIELDS}
  mutation CreateConversation($input: ConversationOriginDto!) {
    createConversation(input: $input) {
      ...ConversationDtoFields
    }
  }
`

export const UPDATE_CONVERSATION = gql`
  ${CONVERSATION_DTO_FIELDS}
  mutation UpdateConversation(
    $conversationId: ID!
    $input: ConversationUpdateDto!
  ) {
    updateConversation(conversationId: $conversationId, input: $input) {
      ...ConversationDtoFields
    }
  }
`

export const DELETE_CONVERSATION = gql`
  mutation DeleteConversation($conversationId: ID!) {
    deleteConversation(conversationId: $conversationId)
  }
`

export const EMAIL_PREVIEW = gql`
  mutation EmailConversationPreview($input: EmailPreviewConfig!) {
    emailConversationPreview(input: $input)
  }
`
