import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

import FinalizeSection from '~/components/common/buttons/FinalizeSection'

import ImagingOrdersTable from './ImagingOrdersTable'
import { useGetImagingOrdersData } from './useGetImagingOrdersData'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    finalizeSection: {
      border: 'none',
      marginTop: 0,
    },
  }),
  { name: 'ImagingOrdersSection' },
)

export interface ImagingOrdersSectionProps {
  classes?: ClassesType<typeof useStyles>
}

const ImagingOrdersSection = ({
  classes: classesProp,
}: ImagingOrdersSectionProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const { hasOrders, hasOutstandingOrders, handleFinalizeOrders } =
    useGetImagingOrdersData()

  if (!hasOrders && !hasOutstandingOrders) {
    return null
  }

  return (
    <Grid container item className={classes.root} direction="column" mt={2}>
      {hasOrders && <ImagingOrdersTable />}
      {hasOutstandingOrders && (
        <FinalizeSection
          buttonLabel={t('Common:FINALIZE_ACTION')}
          classes={{
            outstandingContainer: classes.finalizeSection,
          }}
          title={t('Common:OUTSTANDING_IMAGING_ORDERS')}
          onClick={handleFinalizeOrders}
        />
      )}
    </Grid>
  )
}

export default ImagingOrdersSection
