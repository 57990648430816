import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, Nil, StateLabel } from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import MembershipPaymentMethodCell from '~/components/common/lists/primitive-table/cells/MembershipPaymentMethodCell'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
// @ts-ignore
import { patientMembershipsSelectors } from '~/store/duck/patientMemberships'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Membership, MembershipPlan } from '~/types'
import { getBusinessName } from '~/utils/businessUtils'
import {
  formatMembershipPeriod,
  useEditPaymentInfo,
} from '~/utils/membershipPaymentUtils'
import useDialog from '~/utils/useDialog'

import PlanTypeCell from './table/patients/PlanTypeCell'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      border: theme.constants.tableBorder,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    headerCell: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
    },
    iconButton: {
      color: theme.colors.editIconColor,
      padding: theme.spacing(0.75),
    },
    stateLabel: {
      marginRight: theme.spacing(0.5),
      width: 'fit-content',
      fontSize: '1rem',
    },
    paymentInfoCell: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
  { name: 'PatientMembershipTable' },
)

interface PatientMembershipTableProps {
  clientId?: string | Nil
  onClose?: () => void
  patientId: string
}

const PatientMembershipTable = ({
  clientId,
  onClose,
  patientId,
}: PatientMembershipTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const membership: Membership | Nil = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )

  const business = useSelector(getBusiness(membership?.businessId))

  const [openCancelMembershipDetailsDialog] = useDialog(
    DialogNames.CANCEL_MEMBERSHIP_DETAILS,
  )

  const isWellnessplanSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WELLNESSPLAN_SHARING),
  )

  const {
    canUpdate,
    onEditPaymentInfo,
    Icon,
    isLinkToUpdateWplansPaymentMethodEnabled,
  } = useEditPaymentInfo({
    clientId,
    patientId,
    withBillingAddress: true,
    onClose,
  })

  const onViewCancelMembershipDialog = (plan: MembershipPlan) => {
    openCancelMembershipDetailsDialog({ plan })
  }

  // eslint-disable-next-line react/no-unstable-nested-components, react/no-multi-comp
  const MembershipUpdatePaymentMethodCell = ({
    plan,
  }: {
    plan: MembershipPlan
  }) => (
    <>
      <MembershipPaymentMethodCell paymentMethod={plan?.paymentMethod} />
      <IconButton
        className={classes.iconButton}
        disabled={!canUpdate}
        size="large"
        onClick={() => onEditPaymentInfo(plan)}
      >
        {Icon}
      </IconButton>
    </>
  )

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:PLAN')}
          </TableCell>
          {isWellnessplanSharingEnabled && (
            <TableCell
              className={classNames(classes.tableCell, classes.headerCell)}
            >
              {t('Common:PRACTICE')}
            </TableCell>
          )}
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:TYPE_ONE')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:PLAN_PERIOD')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:PAYMENTS.NEXT_PAYMENT_DUE')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:PAYMENTS.PAYMENT_METHOD')}
          </TableCell>
          <TableCell
            className={classNames(classes.tableCell, classes.headerCell)}
          >
            {t('Common:MEMBER_SINCE')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(membership?.plans || []).filter(Boolean).map((plan) => (
          <TableRow key={plan?.planLogId}>
            <TableCell className={classes.tableCell}>{plan?.tier}</TableCell>
            {isWellnessplanSharingEnabled && (
              <TableCell className={classes.tableCell}>
                {getBusinessName(business)}
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <PlanTypeCell plan={plan} />
            </TableCell>
            <TableCell className={classes.tableCell}>
              {formatMembershipPeriod(plan)}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {plan?.alreadyCancelled ? (
                <Grid container item alignItems="center" wrap="nowrap">
                  <StateLabel disabled className={classes.stateLabel}>
                    {`${t('Common:CANCELLED')} ${DateUtils.formatDate(
                      plan?.cancellationDate,
                    )}`}
                  </StateLabel>
                  <IconButton
                    className={classes.iconButton}
                    size="large"
                    onClick={() => onViewCancelMembershipDialog(plan)}
                  >
                    <EyeIcon />
                  </IconButton>
                </Grid>
              ) : plan?.nextPaymentDue ? (
                DateUtils.formatDate(plan?.nextPaymentDue)
              ) : (
                t('Abbreviations:COMMON.NOT_AVAILABLE')
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {isLinkToUpdateWplansPaymentMethodEnabled ? (
                <div className={classes.paymentInfoCell}>
                  <MembershipUpdatePaymentMethodCell plan={plan} />
                </div>
              ) : (
                <MembershipUpdatePaymentMethodCell plan={plan} />
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {DateUtils.formatDate(plan?.creationDate)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default PatientMembershipTable
