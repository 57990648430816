import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateUtils,
  LanguageUtils,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  getIsCurrentContextSoap,
  getSoapFinalizedLogs,
} from '~/store/reducers/soap'

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      backgroundColor: theme.colors.actionNeededBackground,
      padding: theme.spacing(2),
      margin: theme.spacing(-2, -2, 2),
    },
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      padding: theme.spacing(2, 1.5),
      borderRight: theme.constants.tabBorder,
    },
    contentContainer: {
      padding: theme.spacing(1.5, 2, 3),
    },
    logContainer: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
    },
    stateLabel: {
      width: 55,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'SoapRecordSection' },
)

const SoapRecordSection = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const soapFinalizedLogs = useSelector(getSoapFinalizedLogs)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  if (isCurrentContextSoap && soapFinalizedLogs.length === 0) {
    return null
  }

  return (
    <Box className={classes.wrapper}>
      <Grid container item className={classes.root}>
        <Grid item className={classes.titleContainer} xs={2}>
          <Text variant="h4">{t('Soap:SOAP_RECORD_SECTION.SOAP_RECORD')}</Text>
        </Grid>
        <Grid
          container
          item
          xs
          className={classes.contentContainer}
          direction="column"
        >
          {!isCurrentContextSoap && (
            <Grid container item>
              <StateLabel warning mb={1}>
                {t('Soap:SOAP_HAS_NOT_BEEN_FINALIZED_BY_ORIGINATING_CLINIC')}
              </StateLabel>
            </Grid>
          )}
          {soapFinalizedLogs.map((entry) => (
            <Grid
              container
              item
              alignItems="center"
              className={classes.logContainer}
              key={entry.id}
            >
              <StateLabel
                className={classes.stateLabel}
                variant="small"
                warning={entry.actionName === 'Finalized'}
              >
                {LanguageUtils.getTranslatedFieldName(entry, 'actionName')}
              </StateLabel>
              <Text variant="body2">
                {DateUtils.formatDateWithHours(entry.finalizedDate, true)}&nbsp;
                {t('Common:BY').toLowerCase()}&nbsp;
                {Utils.getPersonString(entry.finalizedBy)}
              </Text>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default SoapRecordSection
