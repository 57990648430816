import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  createDiscountReason,
  getDiscountReasonsIsLoading,
} from '~/store/duck/discountReasons'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme) => ({
    tableAdd: {
      borderTop: theme.constants.tableBorder,
    },
    reasonColumn: {
      borderLeft: theme.constants.tableBorder,
    },
  }),
  { name: 'DiscountAdd' },
)

interface DiscountAddProps {
  businessId: string
  className?: string
  onDiscard?: () => void
}

const DiscountAdd = ({
  className,
  businessId,
  onDiscard = R.F,
}: DiscountAddProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const isLoading = useSelector(getDiscountReasonsIsLoading)

  const {
    validate,
    reset,
    fields: { active, name },
  } = useFields(
    [
      {
        name: 'active',
        type: 'toggle',
        validators: ['required'],
        initialValue: true,
      },
      { name: 'name', validators: ['required'], initialValue: '' },
    ],
    false,
  )

  const handleDiscard = () => {
    reset()
    onDiscard()
  }

  const resetFieldsAfterCreationOn = useCloseAfterCreation(
    reset,
    getDiscountReasonsIsLoading,
  )

  const handleAdd = () => {
    if (businessId && validate()) {
      dispatch(createDiscountReason(name.value, businessId))
      resetFieldsAfterCreationOn()
    }
  }

  return (
    <Grid
      container
      item
      className={classNames(classes.tableAdd, className)}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" px={1.5} xs={1}>
        <PuiSwitch disabled field={active} />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.reasonColumn}
        pl={1.5}
      >
        <PuiTextField
          autoFocus
          disabled={isLoading}
          field={name}
          inputProps={{ maxLength: 100 }}
          margin="none"
        />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        px={1.5}
        xs={1.75}
      >
        <ControlButtonGroup
          buttons={[
            {
              name: ControlButtonGroupName.CHECK,
              onClick: handleAdd,
              isLoading,
            },
            {
              name: ControlButtonGroupName.REMOVE,
              onClick: handleDiscard,
              isLoading,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default DiscountAdd
