import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import { PuiSelect, PuiSelectProps } from '@pbt/pbt-ui-components'

import {
  fetchReferredByContacts,
  getContactsIsFetchingList,
  getReferredByContacts,
} from '~/store/duck/contacts'

const CONTACT_ID_SEPARATOR = '_'

export interface ReferredBySelectProps extends PuiSelectProps {}

const ReferredBySelect = ({ field, ...rest }: ReferredBySelectProps) => {
  const dispatch = useDispatch()
  const contacts = useSelector(getReferredByContacts)
  const contactsIsReceiving = useSelector(getContactsIsFetchingList)

  useEffect(() => {
    if (isEmpty(contacts) && !contactsIsReceiving) {
      dispatch(fetchReferredByContacts())
    }
  }, [])

  const contactsWithUniqueId = contacts.map(
    ({ id, type, name, nameTranslation }) => ({
      id: `${id}${CONTACT_ID_SEPARATOR}${type}`,
      name,
      nameTranslation,
    }),
  )

  return (
    <PuiSelect
      fullWidth
      field={field}
      items={contactsWithUniqueId}
      renderEmpty={false}
      {...rest}
    />
  )
}

export default ReferredBySelect

export const parseReferredByIds = (value?: string) =>
  value?.split(CONTACT_ID_SEPARATOR)

export const joinReferredByIds = (
  referredById?: string,
  referredByTypeId?: string,
) =>
  referredById && referredByTypeId
    ? `${referredById}${CONTACT_ID_SEPARATOR}${referredByTypeId}`
    : ''
