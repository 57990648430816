import React from 'react'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 144,
      height: 40,
    },
    goBackText: {
      textTransform: 'none',
    },
  }),
  { name: 'ValidationError' },
)

export interface ValidationErrorProps {
  error: string | Nil
  onBack: () => void
  onProceed: () => void
  proceedButtonActionName?: string
}

const ValidationError = ({
  error,
  proceedButtonActionName,
  onBack,
  onProceed,
}: ValidationErrorProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const validationErrorProceedButtonActionName =
    proceedButtonActionName || t('Common:ADD_ACTION')

  return (
    <Grid container alignItems="center" direction="column" p={5}>
      <Grid item>
        <Text align="center" variant="body">
          {error}
        </Text>
      </Grid>
      <Grid item mt={5}>
        <Button onClick={onBack}>
          <ArrowBackIcon />
          <Text
            strong
            className={classes.goBackText}
            ml={1}
            variant="subheading3"
          >
            {t('Common:GO_BACK')}
          </Text>
        </Button>
      </Grid>
      <Grid item mt={4}>
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={onProceed}
        >
          {t('Common:PROCEED_ACTION_ANYWAY', {
            actionName: validationErrorProceedButtonActionName,
          })}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default ValidationError
