import React from 'react'
import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { getIsLoading } from '~/store/duck/progress'

const useStyles = makeStyles(
  (theme) => ({
    progress: {
      position: 'sticky',
      [theme.breakpoints.down('md')]: {
        top: 0,
      },
      top: theme.mixins.toolbar.minHeight,
      height: theme.constants.progressBarHeight,
      zIndex: theme.zIndex.progress,
    },
    progressHidden: {
      display: 'none',
    },
  }),
  { name: 'Progress' },
)

const Progress = () => {
  const classes = useStyles()
  const isLoading = useSelector(getIsLoading)

  return (
    <LinearProgress
      aria-label="page-progress"
      className={classNames(classes.progress, {
        [classes.progressHidden]: !isLoading,
      })}
    />
  )
}

export default Progress
