import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { AutoReplyMessageConfig } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE =
  'boop/FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE'
export const FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_SUCCESS =
  'boop/FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_SUCCESS'
export const FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_FAILURE =
  'boop/FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_FAILURE'
export const CLEAR_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE =
  'boop/CLEAR_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE'

export const fetchBoopSLAMessagesDummyTemplate = (
  config: AutoReplyMessageConfig,
) => ({
  type: FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE,
  config,
})
export const fetchBoopSLAMessagesDummyTemplateSuccess = (template: string) => ({
  type: FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_SUCCESS,
  template,
})
export const fetchBoopSLAMessagesDummyTemplateFailure = (error: ApiError) => ({
  type: FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_FAILURE,
  error,
})

export const clearBoopSLAMessagesDummyTemplate = () => ({
  type: CLEAR_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE,
})

export type BoopState = {
  boopSlaMessagesTemplate: string | null
  error: string | null
  isReceivingTemplate: boolean
}

const INITIAL_STATE: BoopState = {
  boopSlaMessagesTemplate: null,
  isReceivingTemplate: false,
  error: null,
}

export const boopReducer = (
  state: BoopState = INITIAL_STATE,
  action: AnyAction,
): BoopState => {
  switch (action.type) {
    case FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE:
      return { ...state, isReceivingTemplate: true }
    case FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_SUCCESS:
      return {
        ...state,
        isReceivingTemplate: false,
        boopSlaMessagesTemplate: action.template,
      }
    case FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE_FAILURE:
      return {
        ...state,
        isReceivingTemplate: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}
export const getBoop = (state: RootState): BoopState => state.boop
export const getBoopSLAMessagesTemplateIsReceiving = (state: RootState) =>
  getBoop(state).isReceivingTemplate
export const getBoopSLAMessageDummyTemplate = (state: RootState) =>
  getBoop(state).boopSlaMessagesTemplate

export function* fetchBoopSLAMessagesDummyTemplateSaga({
  config,
}: ReturnType<typeof fetchBoopSLAMessagesDummyTemplate>) {
  try {
    const { preview } = yield* requestAPI(
      API.loadBoopSLAMessagesDummyTemplate,
      config,
    )
    yield put(fetchBoopSLAMessagesDummyTemplateSuccess(preview))
  } catch (error) {
    yield put(fetchBoopSLAMessagesDummyTemplateFailure(error as ApiError))
  }
}

function* watchFetchBoopSLAMessagesDummyConfig() {
  yield takeLatest(
    FETCH_BOOP_SLA_MESSAGES_DUMMY_TEMPLATE,
    fetchBoopSLAMessagesDummyTemplateSaga,
  )
}

export function* boopSaga() {
  yield all([watchFetchBoopSLAMessagesDummyConfig()])
}
