import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, BoxProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import { Defaults, PermissionArea, Text } from '@pbt/pbt-ui-components'

import PuiCKEditorMedicalDischargeNotes from '~/components/common/ckeditor/variants/PuiCKEditorMedicalDischargeNotes'
import { PuiTab } from '~/components/common/PuiTabs'
import PuiTabsPanel from '~/components/common/PuiTabsPanel'
import { setDischargeNotes, setMedicalNotes } from '~/store/actions/soap'
import {
  getCKEditorCurrentEditorTab,
  setCurrentEditorTab,
} from '~/store/duck/ckeditor'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getIsCurrentContextSoap,
  getIsFinalized,
  getSoapId,
  getSOAPisFetching,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { EditorDocumentType, FeatureElements } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    dividerContainer: {
      width: `calc(100% + ${theme.spacing(4)})`,
      marginLeft: theme.spacing(-2),
    },
    notesContainer: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    tabContainer: {
      marginLeft: `-${theme.spacing(1)} !important`,
    },
  }),
  { name: 'MedicalDischargeNotesWidgetUnlocked' },
)

export interface MedicalDischargeNotesWidgetUnlockedProps {
  ContainerProps?: BoxProps
  container?: boolean
  hideElements?: FeatureElements[]
}

type CKEditorNotesTab = {
  id: string
  noteType: EditorDocumentType.SOAP_MEDICAL | EditorDocumentType.SOAP_DISCHARGE
  setAction: typeof setMedicalNotes | typeof setDischargeNotes
  subTitle: string
}

const MedicalDischargeNotesWidgetUnlocked = ({
  ContainerProps,
  container = true,
  hideElements,
}: MedicalDischargeNotesWidgetUnlockedProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const soapId = useSelector(getSoapId)
  const soapIsLoading = useSelector(getSOAPisLoading)
  const soapIsFetching = useSelector(getSOAPisFetching)
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const tabValue = useSelector(getCKEditorCurrentEditorTab) || 0

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap
  const isSoapPending = soapIsLoading || soapIsFetching

  const panels: CKEditorNotesTab[] = [
    {
      id: `${EditorDocumentType.SOAP_MEDICAL}_${soapId}`,
      subTitle: t('Common:NOT_SHARED_WITH_CLIENT'),
      noteType: EditorDocumentType.SOAP_MEDICAL,
      setAction: setMedicalNotes,
    },
    {
      id: `${EditorDocumentType.SOAP_DISCHARGE}_${soapId}`,
      subTitle: t('Common:SHARED_WITH_CLIENT'),
      noteType: EditorDocumentType.SOAP_DISCHARGE,
      setAction: setDischargeNotes,
    },
  ]

  const tabs: PuiTab[] = [
    {
      id: `${EditorDocumentType.SOAP_MEDICAL}_${soapId}`,
      label: t('Common:MEDICAL_NOTES'),
      hide: false,
    },
    {
      id: `${EditorDocumentType.SOAP_DISCHARGE}_${soapId}`,
      label: t('Common:DISCHARGE_NOTES'),
      hide: false,
    },
  ]

  const handleTabChange = useCallback((newValue: number) => {
    dispatch(setCurrentEditorTab(newValue))
  }, [])

  const handleValueChange = useDebouncedCallback(
    (newValue: string, tabIndex: number) => {
      const tabConfig = panels[tabIndex]
      if (tabConfig) {
        dispatch(tabConfig.setAction(newValue))
      }
    },
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  return (
    <Box
      className={classNames({
        [classes.notesContainer]: container,
      })}
      display="flex"
      id="notes-widget"
      minHeight={366}
      padding={2}
      width="100%"
      {...ContainerProps}
    >
      <PuiTabsPanel
        classes={{
          tabContainer: classes.tabContainer,
          dividerContainer: classes.dividerContainer,
        }}
        panels={panels}
        tabValue={tabValue}
        tabs={tabs}
        onChange={handleTabChange}
      >
        {({ tabPanel }) => (
          <>
            <Text pb={0.5} pt={1.5} variant="lowAccent3">
              {tabPanel.subTitle}
            </Text>
            <PuiCKEditorMedicalDischargeNotes
              disabled={Boolean(
                isReadOnly || !SOAPPermissions.update || isSoapPending,
              )}
              hideElements={hideElements}
              id={tabPanel.id}
              noteType={tabPanel.noteType}
              soapId={soapId}
              onChange={(value) => handleValueChange(value, tabValue)}
            />
          </>
        )}
      </PuiTabsPanel>
    </Box>
  )
}

export default MedicalDischargeNotesWidgetUnlocked
