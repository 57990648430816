import React from 'react'
import { Grid } from '@mui/material'
import {
  LanguageUtils,
  Text,
  WellnessPlanBenefit,
  WellnessPlanCoverItem,
} from '@pbt/pbt-ui-components'

export interface PatientMembershipDetailsCoverRowProps {
  benefit: WellnessPlanBenefit
  className?: string
  coverage: WellnessPlanCoverItem
}

const PatientMembershipDetailsCoverRow = ({
  coverage,
  benefit,
  className,
}: PatientMembershipDetailsCoverRowProps) => (
  <Grid
    item
    className={className}
    key={`${coverage.id}-${coverage.name}`}
    px={2}
    py={0.5}
  >
    <Text variant={benefit.limitReached ? 'lowAccent2' : 'body2'}>
      {coverage.displayName || LanguageUtils.getTranslatedFieldName(coverage)}
    </Text>
  </Grid>
)

export default PatientMembershipDetailsCoverRow
