import { ApiError, AtLeast, Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import {
  CRUDOrderOptions,
  EmailEntityConfig,
  FetchOrdersOptions,
  Order,
  OrderDetails,
  OrderFilter,
  OrderSearchResult,
  Prescription,
} from '~/types'

import {
  APPLY_PATIENT_RESTRICTIONS,
  CANCEL_REFILLS,
  CANCEL_REFILLS_FAILURE,
  CANCEL_REFILLS_SUCCESS,
  CLEAR_CURRENT_ORDER_DETAILS,
  CLEAR_CURRENT_UNIFIED_ORDER,
  CLEAR_ITEM_EXIST_ERROR,
  CLEAR_ITEM_STATUS_ERROR,
  CLEAR_ORDER_CAN_NOT_BE_REMOVED_MESSAGE,
  CLEAR_ORDER_VALIDATION_ERROR_MESSAGE,
  CLEAR_ORDERS,
  CLEAR_PRESCRIPTION_EMAIL_TEMPLATE,
  CLEAR_PRESCRIPTION_FOR_EMAIL,
  CLEAR_PRESCRIPTION_FOR_PRINT,
  CLEAR_REMOUNT_WEBSOCKET_CHARGES_WIDGET,
  CLEAR_SEARCH_ORDERS,
  CREATE_ORDER,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  CREATE_UNIFIED_ORDER,
  CREATE_UNIFIED_ORDER_FAILURE,
  CREATE_UNIFIED_ORDER_SUCCESS,
  DECLINE_LAB_ORDER,
  DECLINE_LAB_ORDER_ABOLISH,
  DECLINE_LAB_ORDER_FAILURE,
  DECLINE_LAB_ORDER_SUCCESS,
  EDIT_ORDER,
  EDIT_ORDER_ABOLISH,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDERS,
  EDIT_ORDERS_FAILURE,
  EDIT_ORDERS_STATUSES,
  EDIT_ORDERS_STATUSES_FAILURE,
  EDIT_ORDERS_STATUSES_SUCCESS,
  EDIT_ORDERS_SUCCESS,
  FETCH_MORE_FOR_SEARCH,
  FETCH_MORE_FOR_SEARCH_FAILURE,
  FETCH_MORE_FOR_SEARCH_SUCCESS,
  FETCH_MORE_ORDERS,
  FETCH_MORE_ORDERS_FAILURE,
  FETCH_MORE_ORDERS_SUCCESS,
  FETCH_ORDER_COUNTS_BY_TYPE,
  FETCH_ORDER_COUNTS_BY_TYPE_FAILURE,
  FETCH_ORDER_COUNTS_BY_TYPE_SUCCESS,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_GROUPED,
  FETCH_ORDER_DETAILS_GROUPED_FAILURE,
  FETCH_ORDER_DETAILS_GROUPED_SUCCESS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDERS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_FILTERS,
  FETCH_ORDERS_FILTERS_FAILURE,
  FETCH_ORDERS_FILTERS_SUCCESS,
  FETCH_ORDERS_SUCCESS,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE_FAILURE,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE_SUCCESS,
  FETCH_PRESCRIPTION_PRINT_INFO,
  FETCH_PRESCRIPTION_PRINT_INFO_FAILURE,
  FETCH_UNIFIED_ORDER,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_FAILURE,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_SUCCESS,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_FAILURE,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_SUCCESS,
  FETCH_UNIFIED_ORDER_FAILURE,
  FETCH_UNIFIED_ORDER_SUCCESS,
  ORDER_BUNDLE,
  ORDER_BUNDLE_FAILURE,
  ORDER_BUNDLE_SUCCESS,
  PARTIAL_EDIT_ORDER,
  PARTIAL_EDIT_ORDER_ABOLISH,
  PARTIAL_EDIT_ORDER_FAILURE,
  PARTIAL_EDIT_ORDER_SUCCESS,
  REFRESH_ORDERS,
  REMOUNT_WEBSOCKET_CHARGES_WIDGET,
  REMOVE_LAB_ORDER,
  REMOVE_LAB_ORDER_ABOLISH,
  REMOVE_MULTIPLE_ORDERS,
  REMOVE_ORDER,
  REMOVE_ORDER_FAILURE,
  REMOVE_ORDER_SUCCESS,
  REMOVE_ORDER_UPDATE_PATIENT,
  REMOVE_ORDER_UPDATE_PATIENT_FAILURE,
  REMOVE_ORDER_UPDATE_PATIENT_SUCCESS,
  REMOVE_ORDERS,
  REMOVE_ORDERS_FAILURE,
  REMOVE_ORDERS_SUCCESS,
  REMOVE_UNIFIED_ORDER,
  REMOVE_UNIFIED_ORDER_FAILURE,
  REMOVE_UNIFIED_ORDER_SUCCESS,
  SEARCH_ORDERS,
  SEARCH_ORDERS_FAILURE,
  SEARCH_ORDERS_SUCCESS,
  SET_ORDER_CAN_NOT_BE_REMOVED_MESSAGE,
  SET_ORDER_VALIDATION_ERROR_MESSAGE,
  SET_PRESCRIPTION_FOR_EMAIL,
  SET_PRESCRIPTION_FOR_PRINT,
  SIGN_PRESCRIPTION,
  SIGN_PRESCRIPTION_FAILURE,
  SIGN_PRESCRIPTION_SUCCESS,
  SUBTRACT_REFILL_REMAINS,
  UNORDER_BUNDLE,
  UNORDER_BUNDLE_FAILURE,
  UNORDER_BUNDLE_SUCCESS,
  UNORDER_BUNDLE_WITHOUT_ORDER,
  UNORDER_BUNDLE_WITHOUT_ORDER_FAILURE,
  UNORDER_BUNDLE_WITHOUT_ORDER_SUCCESS,
  UPDATE_CURRENT_ORDER_DETAILS,
  UPDATE_FULL_LOG,
  UPDATE_LOG_FAILURE,
  UPDATE_LOG_SUCCESS,
  UPDATE_MULTIPLE_ORDERS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_UNIFIED_ORDER,
  UPDATE_UNIFIED_ORDER_FAILURE,
  UPDATE_UNIFIED_ORDER_SUCCESS,
} from './types/orders'

export const fetchOrders = ({
  categories,
  subcategories,
  entityType,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  forShipments,
  labVendorId,
  applyPatientRestriction,
  from,
  to,
}: FetchOrdersOptions) => ({
  type: FETCH_ORDERS,
  categories,
  subcategories,
  entityType,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  forShipments,
  labVendorId,
  applyPatientRestriction,
  from,
  to,
})

export const fetchOrdersSuccess = (
  orders: Order[],
  totalCount: number,
  filters: FetchOrdersOptions,
) => ({
  type: FETCH_ORDERS_SUCCESS,
  orders,
  totalCount,
  filters,
})
export const fetchOrdersFailure = (error: ApiError) => ({
  type: FETCH_ORDERS_FAILURE,
  error,
})

export const fetchMoreOrders = ({
  categories,
  subcategories,
  entityType,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  forShipments,
  labVendorId,
  applyPatientRestriction,
  from,
  to,
}: FetchOrdersOptions) => ({
  type: FETCH_MORE_ORDERS,
  categories,
  subcategories,
  entityType,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  forShipments,
  labVendorId,
  applyPatientRestriction,
  from,
  to,
})
export const fetchMoreOrdersSuccess = (
  orders: Order[],
  from: number | Nil,
  filters: FetchOrdersOptions,
) => ({
  type: FETCH_MORE_ORDERS_SUCCESS,
  orders,
  from,
  filters,
})
export const fetchMoreOrdersFailure = (error: ApiError) => ({
  type: FETCH_MORE_ORDERS_FAILURE,
  error,
})

export const fetchOrdersFilters = (options?: { allowedTypes: string[] }) => ({
  type: FETCH_ORDERS_FILTERS,
  options,
})
export const fetchOrdersFiltersSuccess = (ordersFilters: OrderFilter[]) => ({
  type: FETCH_ORDERS_FILTERS_SUCCESS,
  ordersFilters,
})
export const fetchOrdersFiltersFailure = (error: ApiError) => ({
  type: FETCH_ORDERS_FILTERS_FAILURE,
  error,
})

export const updateFullLog = (log: Order[]) => ({ type: UPDATE_FULL_LOG, log })
export const updateLogSuccess = () => ({ type: UPDATE_LOG_SUCCESS })
export const updateLogFailure = (error: ApiError) => ({
  type: UPDATE_LOG_FAILURE,
  error,
})

export const clearOrders = () => ({ type: CLEAR_ORDERS })

export const fetchOrderDetailsGrouped = ({
  businessItemId,
  globalInventoryCommonOrMappingId,
  matchChewyItems,
}: {
  businessItemId?: string
  globalInventoryCommonOrMappingId?: string
  matchChewyItems?: boolean
}) => ({
  type: FETCH_ORDER_DETAILS_GROUPED,
  businessItemId,
  globalInventoryCommonOrMappingId,
  matchChewyItems,
})
export const fetchOrderDetailsGroupedSuccess = (
  orderDetails: OrderDetails[],
) => ({
  type: FETCH_ORDER_DETAILS_GROUPED_SUCCESS,
  orderDetails,
})
export const fetchOrderDetailsGroupedFailure = (error: ApiError) => ({
  type: FETCH_ORDER_DETAILS_GROUPED_FAILURE,
  error,
})

export const fetchOrderDetails = (order: Order) => ({
  type: FETCH_ORDER_DETAILS,
  order,
})
export const fetchOrderDetailsSuccess = (orderDetails: Prescription) => ({
  type: FETCH_ORDER_DETAILS_SUCCESS,
  orderDetails,
})
export const fetchOrderDetailsFailure = (error: ApiError) => ({
  type: FETCH_ORDER_DETAILS_FAILURE,
  error,
})

export const createOrder = (order: Order, options?: CRUDOrderOptions) => ({
  type: CREATE_ORDER,
  order,
  options,
})
export const createOrderSuccess = (createdOrder: Order, order: Order) => ({
  type: CREATE_ORDER_SUCCESS,
  createdOrder,
  order,
})
export const createOrderFailure = (error: ApiError, order: Order) => ({
  type: CREATE_ORDER_FAILURE,
  error,
  order,
})

export const createUnifiedOrder = (
  order: Order,
  options: CRUDOrderOptions,
) => ({
  type: CREATE_UNIFIED_ORDER,
  order,
  options,
})
export const createUnifiedOrderSuccess = (
  createdOrder: Order,
  order: Order,
) => ({
  type: CREATE_UNIFIED_ORDER_SUCCESS,
  createdOrder,
  order,
})
export const createUnifiedOrderFailure = (error: ApiError, order: Order) => ({
  type: CREATE_UNIFIED_ORDER_FAILURE,
  order,
  error,
})

export const declineLabOrder = (vendorId: string, orderId: string) => ({
  type: DECLINE_LAB_ORDER,
  vendorId,
  orderId,
})
export const declineLabOrderSuccess = (order: Order) => ({
  type: DECLINE_LAB_ORDER_SUCCESS,
  order,
})
export const declineLabOrderFailure = (error: ApiError | null) => ({
  type: DECLINE_LAB_ORDER_FAILURE,
  error,
})
export const declineLabOrderAbolish = () => ({
  type: DECLINE_LAB_ORDER_ABOLISH,
})

export const editOrders = (orders: Order[]) => ({ type: EDIT_ORDERS, orders })
export const editOrdersSuccess = (orders: Order[]) => ({
  type: EDIT_ORDERS_SUCCESS,
  orders,
})
export const editOrdersFailure = (error: ApiError) => ({
  type: EDIT_ORDERS_FAILURE,
  error,
})

export const editOrdersStatuses = (orders: Partial<Order>[]) => ({
  type: EDIT_ORDERS_STATUSES,
  orders,
})
export const editOrdersStatusesSuccess = (orders: Order[]) => ({
  type: EDIT_ORDERS_STATUSES_SUCCESS,
  orders,
})
export const editOrdersStatusesFailure = (error: ApiError) => ({
  type: EDIT_ORDERS_STATUSES_FAILURE,
  error,
})

export const partialEditOrder = (
  order: AtLeast<Order, 'id'>,
  options?: CRUDOrderOptions,
) => ({
  type: PARTIAL_EDIT_ORDER,
  order,
  options,
})
export const partialEditOrderSuccess = (order: Order) => ({
  type: PARTIAL_EDIT_ORDER_SUCCESS,
  order,
})
export const partialEditOrderFailure = (error: ApiError) => ({
  type: PARTIAL_EDIT_ORDER_FAILURE,
  error,
})
export const partialEditOrderAbolish = () => ({
  type: PARTIAL_EDIT_ORDER_ABOLISH,
})

export const editOrder = (order: Order, options?: CRUDOrderOptions) => ({
  type: EDIT_ORDER,
  order,
  options,
})
export const editOrderSuccess = (order: Order) => ({
  type: EDIT_ORDER_SUCCESS,
  order,
})
export const editOrderFailure = (error: ApiError) => ({
  type: EDIT_ORDER_FAILURE,
  error,
})
export const editOrderAbolish = () => ({ type: EDIT_ORDER_ABOLISH })

export const updateUnifiedOrder = (
  order: Order,
  options?: CRUDOrderOptions,
) => ({
  type: UPDATE_UNIFIED_ORDER,
  order,
  options,
})
export const updateUnifiedOrderSuccess = (
  order: Order,
  updatedOrder: Order,
) => ({
  type: UPDATE_UNIFIED_ORDER_SUCCESS,
  order,
  updatedOrder,
})
export const updateUnifiedOrderFailure = (error: ApiError) => ({
  type: UPDATE_UNIFIED_ORDER_FAILURE,
  error,
})

export const updateMultipleOrders = (orders: Order[]) => ({
  type: UPDATE_MULTIPLE_ORDERS,
  orders,
})

export const updateOrderStatus = (
  logEntryId: string,
  statusId: string,
  reloadTimeline?: boolean,
) => ({
  type: UPDATE_ORDER_STATUS,
  logEntryId,
  statusId,
  reloadTimeline,
})
export const updateOrderStatusSuccess = () => ({
  type: UPDATE_ORDER_STATUS_SUCCESS,
})
export const updateOrderStatusFailure = (error: ApiError) => ({
  type: UPDATE_ORDER_STATUS_FAILURE,
  error,
})

export const removeOrders = (orders: Order[]) => ({
  type: REMOVE_ORDERS,
  orders,
})
export const removeOrdersSuccess = (orders: Order[]) => ({
  type: REMOVE_ORDERS_SUCCESS,
  orders,
})
export const removeOrdersFailure = (error: ApiError, orders: Order[]) => ({
  type: REMOVE_ORDERS_FAILURE,
  error,
  orders,
})

export const removeOrder = (order: Order, options?: CRUDOrderOptions) => ({
  type: REMOVE_ORDER,
  order,
  options,
})
export const removeOrderSuccess = (orderId: string) => ({
  type: REMOVE_ORDER_SUCCESS,
  orderId,
})
export const removeOrderFailure = (error: ApiError, order: Order) => ({
  type: REMOVE_ORDER_FAILURE,
  error,
  order,
})

export const removeLabOrder = (order: Order) => ({
  type: REMOVE_LAB_ORDER,
  order,
})
export const removeLabOrderAbolish = () => ({ type: REMOVE_LAB_ORDER_ABOLISH })

export const removeOrderUpdatePatient = (order: Order) => ({
  type: REMOVE_ORDER_UPDATE_PATIENT,
  order,
})
export const removeOrderUpdatePatientSuccess = (orderId: string) => ({
  type: REMOVE_ORDER_UPDATE_PATIENT_SUCCESS,
  orderId,
})
export const removeOrderUpdatePatientFailure = (
  error: ApiError,
  order: Order,
) => ({
  type: REMOVE_ORDER_UPDATE_PATIENT_FAILURE,
  error,
  order,
})

export const removeUnifiedOrder = (order: Order) => ({
  type: REMOVE_UNIFIED_ORDER,
  order,
})
export const removeUnifiedOrderSuccess = (orderId: string) => ({
  type: REMOVE_UNIFIED_ORDER_SUCCESS,
  orderId,
})
export const removeUnifiedOrderFailure = (error: ApiError, order: Order) => ({
  type: REMOVE_UNIFIED_ORDER_FAILURE,
  order,
})

export const searchOrders = ({
  searchTerm,
  filters,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  labVendorId,
  applyPatientRestriction,
  forShipments,
  from,
  to,
}: FetchOrdersOptions) => ({
  type: SEARCH_ORDERS,
  searchTerm,
  filters,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  labVendorId,
  applyPatientRestriction,
  forShipments,
  from,
  to,
})
export const searchOrdersSuccess = (
  searchResults: OrderSearchResult[],
  totalCount: number,
) => ({
  type: SEARCH_ORDERS_SUCCESS,
  searchResults,
  totalCount,
})
export const searchOrdersFailure = (error: ApiError) => ({
  type: SEARCH_ORDERS_FAILURE,
  error,
})

export const fetchMoreForSearch = ({
  searchTerm,
  filters,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  labVendorId,
  applyPatientRestriction,
  forShipments,
  from,
  to,
}: FetchOrdersOptions) => ({
  type: FETCH_MORE_FOR_SEARCH,
  searchTerm,
  filters,
  listType,
  patientId,
  clientId,
  eventId,
  withTasks,
  labVendorId,
  applyPatientRestriction,
  forShipments,
  from,
  to,
})
export const fetchMoreForSearchSuccess = (
  searchResults: OrderSearchResult[],
  totalCount: number,
  from: number | Nil,
) => ({ type: FETCH_MORE_FOR_SEARCH_SUCCESS, searchResults, totalCount, from })
export const fetchMoreForSearchFailure = (error: ApiError) => ({
  type: FETCH_MORE_FOR_SEARCH_FAILURE,
  error,
})

export const clearSearchOrders = () => ({ type: CLEAR_SEARCH_ORDERS })

export const removeMultipleOrders = (orders: Order[]) => ({
  type: REMOVE_MULTIPLE_ORDERS,
  orders,
})

export const setPrescriptionForPrint = (prescription: Prescription) => ({
  type: SET_PRESCRIPTION_FOR_PRINT,
  prescription,
})
export const clearPrescriptionForPrint = () => ({
  type: CLEAR_PRESCRIPTION_FOR_PRINT,
})

export const setPrescriptionForEmail = (prescription: Prescription) => ({
  type: SET_PRESCRIPTION_FOR_EMAIL,
  prescription,
})
export const clearPrescriptionForEmail = () => ({
  type: CLEAR_PRESCRIPTION_FOR_EMAIL,
})

export const fetchPrescriptionPrintInfo = (
  prescription: Prescription | Order,
) => ({
  type: FETCH_PRESCRIPTION_PRINT_INFO,
  prescription,
})
export const fetchPrescriptionPrintInfoFailure = (error: ApiError) => ({
  type: FETCH_PRESCRIPTION_PRINT_INFO_FAILURE,
  error,
})

export const fetchPrescriptionEmailTemplate = (
  soapId: string | Nil,
  orderId: string,
  config: EmailEntityConfig,
) => ({
  type: FETCH_PRESCRIPTION_EMAIL_TEMPLATE,
  soapId,
  orderId,
  config,
})
export const fetchPrescriptionEmailTemplateSuccess = (template: string) => ({
  type: FETCH_PRESCRIPTION_EMAIL_TEMPLATE_SUCCESS,
  template,
})
export const fetchPrescriptionEmailTemplateFailure = (error: ApiError) => ({
  type: FETCH_PRESCRIPTION_EMAIL_TEMPLATE_FAILURE,
  error,
})

export const clearPrescriptionEmailTemplate = () => ({
  type: CLEAR_PRESCRIPTION_EMAIL_TEMPLATE,
})

export const signPrescription = (
  soapId: string | Nil,
  orderId: string,
  signerId: string,
  signature: string,
) => ({ type: SIGN_PRESCRIPTION, soapId, orderId, signerId, signature })
export const signPrescriptionSuccess = (order: Order) => ({
  type: SIGN_PRESCRIPTION_SUCCESS,
  order,
})
export const signPrescriptionFailure = (error: ApiError) => ({
  type: SIGN_PRESCRIPTION_FAILURE,
  error,
})

export const orderBundle = (order: Order, soapId: string) => ({
  type: ORDER_BUNDLE,
  order,
  soapId,
})
export const orderBundleSuccess = (order: Order) => ({
  type: ORDER_BUNDLE_SUCCESS,
  order,
})
export const orderBundleFailure = (error: ApiError, order: Order) => ({
  type: ORDER_BUNDLE_FAILURE,
  error,
  order,
})

export const unOrderBundleWithoutOrder = (
  bundleId: string,
  soapId: string,
) => ({
  type: UNORDER_BUNDLE_WITHOUT_ORDER,
  bundleId,
  soapId,
})
export const unOrderBundleWithoutOrderSuccess = (removedLogItems: Order[]) => ({
  type: UNORDER_BUNDLE_WITHOUT_ORDER_SUCCESS,
  removedLogItems,
})
export const unOrderBundleWithoutOrderFailure = (error: ApiError) => ({
  type: UNORDER_BUNDLE_WITHOUT_ORDER_FAILURE,
  error,
})

export const unorderBundle = (order: Order, soapId: string) => ({
  type: UNORDER_BUNDLE,
  order,
  soapId,
})
export const unorderBundleSuccess = (
  order: Order,
  removedLogItems: Order[],
) => ({
  type: UNORDER_BUNDLE_SUCCESS,
  order,
  removedLogItems,
})
export const unorderBundleFailure = (error: ApiError, order: Order) => ({
  type: UNORDER_BUNDLE_FAILURE,
  error,
  order,
})

export const updateCurrentOrderDetails = (currentOrder: Order) => ({
  type: UPDATE_CURRENT_ORDER_DETAILS,
  currentOrder,
})
export const clearCurrentOrderDetails = () => ({
  type: CLEAR_CURRENT_ORDER_DETAILS,
})

export const setOrderValidationErrorMessage = (errorMessage: string) => ({
  type: SET_ORDER_VALIDATION_ERROR_MESSAGE,
  errorMessage,
})
export const clearOrderValidationErrorMessage = () => ({
  type: CLEAR_ORDER_VALIDATION_ERROR_MESSAGE,
})

export const setOrderCanNotBeRemovedMessage = (errorMessage: string) => ({
  type: SET_ORDER_CAN_NOT_BE_REMOVED_MESSAGE,
  errorMessage,
})
export const clearOrderCanNotBeRemovedMessage = () => ({
  type: CLEAR_ORDER_CAN_NOT_BE_REMOVED_MESSAGE,
})

export const subtractRefillRemains = (order: Order) => ({
  type: SUBTRACT_REFILL_REMAINS,
  order,
})

export const cancelRefills = (prescriptionId: string) => ({
  type: CANCEL_REFILLS,
  prescriptionId,
})
export const cancelRefillsSuccess = () => ({ type: CANCEL_REFILLS_SUCCESS })
export const cancelRefillsFailure = (error: ApiError) => ({
  type: CANCEL_REFILLS_FAILURE,
  error,
})

export const refreshOrders = (order?: Order) => ({
  type: REFRESH_ORDERS,
  order,
})

export const fetchUnifiedOrder = (id: string, logType?: string) => ({
  type: FETCH_UNIFIED_ORDER,
  id,
  logType,
})
export const fetchUnifiedOrderSuccess = (order: Order) => ({
  type: FETCH_UNIFIED_ORDER_SUCCESS,
  order,
})
export const fetchUnifiedOrderFailure = (error: ApiError) => ({
  type: FETCH_UNIFIED_ORDER_FAILURE,
  error,
})

export const fetchUnifiedOrderByEntityType = (
  entityId: string,
  entityType: OrderType,
  withTasks?: boolean,
) => ({
  type: FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE,
  entityId,
  entityType,
  withTasks,
})
export const fetchUnifiedOrderByEntityTypeSuccess = (order: Order) => ({
  type: FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_SUCCESS,
  order,
})
export const fetchUnifiedOrderByEntityTypeFailure = (error: ApiError) => ({
  type: FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_FAILURE,
  error,
})

export const clearCurrentUnifiedOrder = () => ({
  type: CLEAR_CURRENT_UNIFIED_ORDER,
})
export const clearItemExistError = () => ({ type: CLEAR_ITEM_EXIST_ERROR })

export const clearItemStatusError = () => ({ type: CLEAR_ITEM_STATUS_ERROR })

export const fetchOrderCountsByType = (
  clientId: string,
  patientId: string,
  eventId: string,
  applyPatientRestriction: boolean,
  types: string[],
) => ({
  type: FETCH_ORDER_COUNTS_BY_TYPE,
  clientId,
  patientId,
  eventId,
  applyPatientRestriction,
  types,
})
export const fetchOrderCountsByTypeSuccess = (
  totalCountByType: Record<Partial<OrderType>, number>,
) => ({
  type: FETCH_ORDER_COUNTS_BY_TYPE_SUCCESS,
  totalCountByType,
})
export const fetchOrderCountsByTypeFailure = (error: ApiError) => ({
  type: FETCH_ORDER_COUNTS_BY_TYPE_FAILURE,
  error,
})

export const remountWebsocketChargesWidget = () => ({
  type: REMOUNT_WEBSOCKET_CHARGES_WIDGET,
})
export const clearRemountWebsocketChargesWidget = () => ({
  type: CLEAR_REMOUNT_WEBSOCKET_CHARGES_WIDGET,
})

export const applyPatientRestrictions = (applied: boolean) => ({
  type: APPLY_PATIENT_RESTRICTIONS,
  applied,
})

export const fetchUnifiedOrderByTypeAndLogId = (
  logId: string,
  logType: OrderType,
) => ({
  type: FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID,
  logId,
  logType,
})
export const fetchUnifiedOrderByTypeAndLogIdSuccess = (order: Order) => ({
  type: FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_SUCCESS,
  order,
})
export const fetchUnifiedOrderByTypeAndLogIdFailure = (error: ApiError) => ({
  type: FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_FAILURE,
  error,
})
