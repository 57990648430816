import React, { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, IconButton, Menu } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import { Problem, ProblemLogProblemContainer } from '~/types'

import IdentifiedProblemDetailsEnumsBranch from './IdentifiedProblemDetailsEnumsBranch'
import IdentifiedProblemDetailsSelectedEnumValues from './IdentifiedProblemDetailsSelectedEnumValues'

interface IdentifiedProblemDetailsEnumsProps {
  problem: Problem
  problemLog: ProblemLogProblemContainer
}

const IdentifiedProblemDetailsEnums = ({
  problem,
  problemLog,
}: IdentifiedProblemDetailsEnumsProps) => {
  const { t } = useTranslation('Soap')
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(menuAnchorEl)
  const problemEnumRefs = problem.enums

  const openMenu: MouseEventHandler<HTMLElement> = (event) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setMenuAnchorEl(null)
  }

  if (!problemEnumRefs || problemEnumRefs.length === 0) {
    return null
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: 'pointer' }}
        onClick={openMenu}
      >
        <Text strong color="primary" variant="subheading3">
          {t('Soap:PROBLEMS.ADD_DESCRIPTION')}
        </Text>
        <IconButton size="small">
          {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Grid>
      <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={closeMenu}>
        <Grid mx={2} my={1}>
          <IdentifiedProblemDetailsEnumsBranch
            problemEnumRefs={problemEnumRefs}
            problemLog={problemLog}
          />
        </Grid>
      </Menu>
      <IdentifiedProblemDetailsSelectedEnumValues
        problemEnumRefs={problemEnumRefs}
        problemLog={problemLog}
      />
    </>
  )
}

export default IdentifiedProblemDetailsEnums
