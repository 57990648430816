import * as R from 'ramda'

export const FINANCE_TABLE_PADDING_X_SPACING_VALUE = 3
export const FINANCE_TABLE_CELL_HEIGHT = 40

export const FINANCE_CELL_WIDTH = {
  CREATION_DATE: 152,
  PRODUCER: 174,
  QUANTITY: 115,
  UNIT_PRICE: 115,
  TAX: 115,
  DISCOUNT: 115,
  SUBTOTAL: 133,
  REFUNDED: 120,
}

export const REFUND_TABLE_MIN_WIDTH = R.sum(R.values(FINANCE_CELL_WIDTH))
