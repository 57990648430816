import { getAPIPath, request } from './core'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { UPDATE_GROUP_ROLES } from './graphql/mutations/roles'
import { FETCH_GROUP_ROLES } from './graphql/queries/roles'

export const fetchUsedRoles = (businessId: string) =>
  request(
    getAPIPath('role'),
    { method: 'GET', params: { businessId, filterUsedRoles: true } },
    true,
  )

export const fetchGroupRoles = requestQuery({
  query: FETCH_GROUP_ROLES,
  variablesHandler: (_, id) => ({
    businessId: id,
  }),
})

export const updateGroupRoles = requestMutation({
  mutation: UPDATE_GROUP_ROLES,
  variablesHandler: (_, { groupId, roleIds }) => ({
    groupId,
    roleIds,
  }),
})
