import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AlertIconType,
  ClassesType,
  Utils,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import {
  editImagingVendorConfig,
  getIntegrationTestingForVendor,
} from '~/store/duck/imagingVendorConfig'
import { getLabVendors as getImagingVendors } from '~/store/reducers/constants'
import { ImagingVendorConfig } from '~/types/entities/imagingVendorConfig'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'ImagingVendorIntegration' },
)

export interface ImagingVendorIntegrationProps {
  FormComponent: React.JSXElementConstructor<any>
  businessId: string
  classes?: ClassesType<typeof useStyles>
  config: ImagingVendorConfig
}

const ImagingVendorIntegration = ({
  FormComponent,
  businessId,
  classes: classesProp,
  config,
}: ImagingVendorIntegrationProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const ImagingVendors = useSelector(getImagingVendors)
  const { t } = useTranslation(['Common', 'Businesses'])

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const formRef = useRef<ValidateHandle>()

  const [shouldNotifySuccess, setShouldNotifySuccess] = useState(false)
  const [integrationConfig, setIntegrationConfig] = useState(config)

  const { vendorId, active, ...credentials } = integrationConfig

  const integrationTesting = useSelector(
    getIntegrationTestingForVendor(businessId, vendorId),
  )

  const isInitiallyActive = useMemo(() => config?.active, [])

  useEffect(() => {
    setIntegrationConfig(config)
  }, [config])

  const vendorName = Utils.getConstantName(vendorId, ImagingVendors)

  const disconnectVendor = () => {
    dispatch(
      editImagingVendorConfig(businessId, vendorId, {
        vendorId,
        active: false,
      }),
    )
    closeAlert()
  }

  const updateIntegrationConfig = (diff: Partial<ImagingVendorConfig>) =>
    setIntegrationConfig({ ...integrationConfig, ...diff })

  const openConfirmDisconnectDialog = () => {
    openAlert({
      cancelButtonText: t('Businesses:INTEGRATION.DISCONNECT_NAME', {
        name: vendorName,
      }),
      iconType: AlertIconType.WARN,
      message: t('Businesses:INTEGRATION.TURN_OFF_INTEGRATION_MESSAGE', {
        name: vendorName,
      }),
      okButtonText: t('Businesses:INTEGRATION.KEEP_CONNECTION'),
      onCancel: disconnectVendor,
      onOk: closeAlert,
    })
  }

  const openIntegrationSuccessDialog = () => {
    openAlert({
      iconType: AlertIconType.SUCCESS,
      okButtonText: t('Common:CLOSE_ACTION'),
      message: t('Businesses:INTEGRATION.YOU_ARE_NOW_SET_UP_TO_ORDER_LABS', {
        name: vendorName,
      }),
      onOk: closeAlert,
    })
  }

  useEffect(() => {
    if (shouldNotifySuccess && integrationTesting.success) {
      openIntegrationSuccessDialog()
      setShouldNotifySuccess(false)
    }
  }, [shouldNotifySuccess, integrationTesting.success])

  const showSuccessAfterUpdate = useCloseAfterCreation(
    () => setShouldNotifySuccess(true),
    R.always(integrationTesting.testing),
  )

  const handleLabUpdate = () => {
    if (formRef?.current && formRef.current.validate()) {
      dispatch(editImagingVendorConfig(businessId, vendorId, integrationConfig))
      showSuccessAfterUpdate()
    }
  }

  return (
    <Grid className={classes.root}>
      <PuiSwitch
        checked={active}
        label={vendorName}
        onChange={() => {
          const isCurrentlyActive = !active
          if (!isCurrentlyActive && isInitiallyActive) {
            openConfirmDisconnectDialog()
          } else {
            updateIntegrationConfig({ active: isCurrentlyActive })
          }
        }}
      />
      {FormComponent && (
        <Grid container pb={3} pl={6}>
          <FormComponent
            active={active}
            businessId={businessId}
            credentials={credentials}
            initiallyActive={isInitiallyActive}
            isTesting={integrationTesting.testing}
            name={vendorName}
            ref={formRef}
            status={integrationTesting.status}
            vendorId={vendorId}
            onTest={handleLabUpdate}
            onUpdate={updateIntegrationConfig}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ImagingVendorIntegration
