import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import { createTasks } from '~/store/actions/tasks'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { Order, Task } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

// @ts-ignore
import MultipleTasksTable from './MultipleTasksTable'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
      overflowY: 'visible',
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'MultipleTasksDialog' },
)

interface MultipleTasksDialogProps extends BasePuiDialogProps {
  appointmentId: string | Nil
  clientId: string | Nil
  dueDate: string | Nil
  items: Order[]
  patientId: string | Nil
  soapId: string | Nil
}

const MultipleTasksDialog = ({
  appointmentId,
  clientId,
  patientId,
  dueDate,
  soapId,
  items,
  open,
  onClose,
}: MultipleTasksDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getTasksIsLoading,
  )

  const onProceed = (tasks: Omit<Task, 'id'>[]) => {
    setCloseAfterCreationOn()
    dispatch(createTasks(tasks))
  }

  return (
    <PuiDialog
      aria-labelledby="multiple-tasks-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:MULTIPLE_TASKS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <MultipleTasksTable
        appointmentId={appointmentId}
        clientId={clientId}
        dueDate={dueDate}
        items={items}
        patientId={patientId}
        soapId={soapId}
        onProceed={onProceed}
      />
    </PuiDialog>
  )
}

export default MultipleTasksDialog
