import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'

import { BodySystemState } from '~/constants/SOAPStates'
import {
  getDiagnosisProblemStates,
  getFindingProblemStates,
  getProblemBodySystemStates,
} from '~/store/reducers/constants'
import { ProblemDiagnosisLog, ProblemDiagnosisLogType } from '~/types'

import ProblemsDiagnosesTable from './problems-diagnoses-table/ProblemsDiagnosesTable'

const StyledBox = styled(Box)`
  margintop: ${({ theme }) => theme.spacing(1.5)};
  marginbottom: ${({ theme }) => theme.spacing(1.5)};
`

interface ProblemFindingsTablesProps {
  problemFindingLogs: ProblemDiagnosisLog[]
}

export default function ProblemFindingsTables({
  problemFindingLogs,
}: ProblemFindingsTablesProps) {
  const findingStates = useSelector(getFindingProblemStates)
  const diagnosisStates = useSelector(getDiagnosisProblemStates)
  const bodySystemConstants = useSelector(getProblemBodySystemStates)

  const isProblemRemoved = (findingLog: ProblemDiagnosisLog) => {
    const problemFindingStateConstant =
      findingLog.logType === ProblemDiagnosisLogType.PROBLEM_DIAGNOSIS_LOG
        ? diagnosisStates
        : findingStates
    const removedStateIds = ['Resolved', 'Removed', 'Ruled Out'].map((name) =>
      findConstantIdByName(name, problemFindingStateConstant),
    )
    return removedStateIds.includes(findingLog.status)
  }

  const activeStatusProblemFindings = problemFindingLogs.filter(
    (log) => !isProblemRemoved(log),
  )
  const problemWNLFindings = activeStatusProblemFindings.filter(
    (finding) =>
      finding.bodySystemLogState ===
      findConstantIdByName(BodySystemState.WNL, bodySystemConstants),
  )
  const problemONLFindings = activeStatusProblemFindings.filter(
    (finding) =>
      finding.bodySystemLogState ===
      findConstantIdByName(BodySystemState.ONL, bodySystemConstants),
  )
  const problemNCFindings = activeStatusProblemFindings.filter(
    (finding) =>
      finding.bodySystemLogState ===
      findConstantIdByName(BodySystemState.NC, bodySystemConstants),
  )

  return (
    <>
      {problemONLFindings?.length ? (
        <StyledBox>
          <ProblemsDiagnosesTable
            problemFindingLogs={problemONLFindings}
            state={BodySystemState.ONL}
          />
        </StyledBox>
      ) : null}
      {problemWNLFindings?.length ? (
        <StyledBox>
          <ProblemsDiagnosesTable
            problemFindingLogs={problemWNLFindings}
            state={BodySystemState.WNL}
          />
        </StyledBox>
      ) : null}
      {problemNCFindings?.length ? (
        <StyledBox>
          <ProblemsDiagnosesTable
            problemFindingLogs={problemNCFindings}
            state={BodySystemState.NC}
          />
        </StyledBox>
      ) : null}
    </>
  )
}
