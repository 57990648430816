import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Constant } from '@pbt/pbt-ui-components'

import { getGender, getSpayedNeuteredStatus } from '~/store/reducers/constants'
import { SpayedNeuteredGenderType } from '~/types'

import PuiSelectAll, { PuiSelectAllProps } from '../PuiSelectAll'
import { generateSpayedNeuteredGender } from './genderUtils'

const getSpayedNeuteredGenderList = (
  spayedNeuteredList = [],
  SpayedNeuteredGender: SpayedNeuteredGenderType[] = [],
) =>
  spayedNeuteredList
    .map(({ genderId, spayedNeuteredStatusId }) =>
      SpayedNeuteredGender.find(
        (item) =>
          item.genderId === genderId &&
          item.spayedNeuteredStatusId === spayedNeuteredStatusId,
      ),
    )
    .filter((item) => item)

export interface GenderRestrictionSelectProps extends PuiSelectAllProps {}

const GenderRestrictionSelect = ({
  field,
  ...rest
}: GenderRestrictionSelectProps) => {
  const Gender: Constant[] = useSelector(getGender)
  const SpayedNeuteredStatus: Constant[] = useSelector(getSpayedNeuteredStatus)

  const [spayedNeuteredGender, setSpayedNeuteredGender] = useState<
    SpayedNeuteredGenderType[]
  >([])

  useEffect(() => {
    if (Gender.length && SpayedNeuteredStatus.length) {
      setSpayedNeuteredGender(
        generateSpayedNeuteredGender(Gender, SpayedNeuteredStatus),
      )
    }
  }, [Gender, SpayedNeuteredStatus])

  return (
    <PuiSelectAll
      field={{
        ...field,
        value: getSpayedNeuteredGenderList(field.value, spayedNeuteredGender),
      }}
      items={spayedNeuteredGender}
      label={field.label}
      {...rest}
    />
  )
}

export default GenderRestrictionSelect
