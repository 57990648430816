import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'

import { fetchModality } from '~/store/duck/imagingOrders'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { addSearch } from '~/utils'

import ImagingDetails from './imaging-table/ImagingDetails'
import ImagingTableComponent from './imaging-table/ImagingTableComponent'

const ImagingDashboardPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { recordId } = useParams()
  const location = useLocation()
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchModality(currentBusinessId))
    }
  }, [currentBusinessId])

  const onDetailsClose = () => {
    navigate(addSearch(location, '/imaging-dashboard'))
  }

  if (isMobile && recordId) {
    return <ImagingDetails item={recordId} onClose={onDetailsClose} />
  }

  return (
    <Grid container item flex={1} wrap="nowrap">
      <ImagingTableComponent
        recordId={recordId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default ImagingDashboardPage
