import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  ClassesType,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import LetUsKnowLink from '~/components/common/LetUsKnowLink'
import FeatureToggle from '~/constants/featureToggle'
import { RootState } from '~/store'
import { createInventory } from '~/store/actions/inventories'
import { createVariations } from '~/store/actions/variations'
import { useIsGlobalInventoryItem } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getInventory,
  getInventoryIsSending,
  getLastCreatedInventory,
} from '~/store/reducers/inventories'
import { getVariationIsSending } from '~/store/reducers/variations'
import {
  GlobalInventoryCatalogItem,
  InventoryItem,
  Variation as VariationType,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import GlobalItemSelector from '../global-selector/GlobalItemSelector'
import {
  addOrUpdateVariation,
  getNextVariationKey,
  getPrevVariationKey,
  getVariation,
  getVariationIndex,
  getVariationKey,
  getVariationsCount,
  insertVariation,
  // @ts-ignore
} from '../inventoryUtils'
import CategorySelector from './CategorySelector'
import Inventory, { InventoryHandle } from './Inventory'
import InventoryRestriction, {
  InventoryRestrictionHandle,
} from './InventoryRestriction'
import Variation from './Variation'

const useStyles = makeStyles(
  () => ({
    saveButton: {
      height: 40,
      width: 150,
    },
  }),
  { name: 'AddInventoryItem' },
)

const loadingSelector = (state: RootState) =>
  getInventoryIsSending(state) || getVariationIsSending(state)

interface VariationHandle {
  getIsTouched: () => boolean
  save: () => void
}

export interface AddInventoryItemHandle {
  confirmSave: () => void
  hasUnsavedChanges: () => boolean
}

interface AddInventoryItemProps {
  classes?: ClassesType<typeof useStyles>
  duplicateInventoryId?: string | Nil
  onClose: (inventoryId: string | Nil, inventory?: InventoryItem | Nil) => void
  onVariationFocus?: (variationKey: string | Nil) => void
  singleItemOnly?: boolean
}

const AddInventoryItem = forwardRef<
  AddInventoryItemHandle,
  AddInventoryItemProps
>(function AddInventoryItem(
  {
    duplicateInventoryId,
    onClose,
    onVariationFocus,
    singleItemOnly,
    classes: classesProp,
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const isLoading = useSelector(loadingSelector)
  const originalInventory = useSelector(getInventory(duplicateInventoryId))
  const lastCreatedInventory = useSelector(getLastCreatedInventory)
  const lastCreatedInventoryId = lastCreatedInventory?.id

  const inventorySection = useRef<InventoryHandle>(null)
  const inventoryRestrictionSection = useRef<InventoryRestrictionHandle>(null)
  const variationRef = useRef<VariationHandle>(null)

  const [inventory, setInventory] = useState({} as InventoryItem)
  const [variationsToCreate, setVariationsToCreate] = useState({})
  const [selectedVariations, setSelectedVariations] = useState({})
  const [inventoryToCreate, setInventoryToCreate] = useState<
    GlobalInventoryCatalogItem | Nil
  >()
  const [currentVariationKey, setCurrentVariationKey] = useState<string | Nil>()
  const [isTouched, setIsTouched] = useState(false)

  const focusOnVariation = useCallback(
    (variationKey: string | Nil) => {
      setCurrentVariationKey(variationKey)
      if (onVariationFocus) {
        onVariationFocus(variationKey)
      }
    },
    [onVariationFocus],
  )

  const isGlobalItem = useIsGlobalInventoryItem(inventory)

  const handleClose = () => {
    if (onClose) {
      onClose(lastCreatedInventoryId, lastCreatedInventory)
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    handleClose,
    loadingSelector,
    true,
  )

  useEffect(() => {
    if (originalInventory && originalInventory.name) {
      setInventory({
        ...R.clone(originalInventory),
        name: `${originalInventory.name}_copy`,
      })
    }
  }, [originalInventory])

  useEffect(
    () => () => {
      setVariationsToCreate({})
      setSelectedVariations({})
      focusOnVariation(undefined)
      setInventoryToCreate(undefined)
    },
    [],
  )

  const runVariationsRefill = () => {
    focusOnVariation(getVariationKey(Object.keys(selectedVariations)[0]))
  }

  const prepareInventoryPayload = () => {
    const newInventory = {
      ...R.omit(['id'], inventory),
      ...(inventorySection.current?.get() || {}),
      ...(inventoryRestrictionSection.current?.getRestriction() || {}),
    }

    const updatedVariations = newInventory.variations
      ? newInventory.variations.map((variation: VariationType) =>
          R.omit(['id'], variation),
        )
      : null

    return { ...newInventory, variations: updatedVariations }
  }

  const runInitialVariationFilling = () => {
    if (inventorySection.current?.validate()) {
      const payload = prepareInventoryPayload()
      setInventoryToCreate(payload)
      focusOnVariation(getVariationKey(''))
    }
  }

  const runInventoryItemCloning = () => {
    if (inventorySection.current?.validate()) {
      const payload = prepareInventoryPayload()
      setCloseAfterCreationOn()
      dispatch(createInventory(payload))
    }
  }

  const onAddClick = () => {
    if (isGlobalItem) {
      runVariationsRefill()
    } else if (duplicateInventoryId) {
      runInventoryItemCloning()
    } else {
      runInitialVariationFilling()
    }
  }

  const onVariationFilled = (
    variation: VariationType,
    isClone?: boolean | Nil,
  ) => {
    const newVariationsToCreate: VariationType[] = addOrUpdateVariation(
      currentVariationKey,
      variation,
      variationsToCreate,
    )
    const nextSelectedVariations = isClone
      ? insertVariation(
          { ...getVariation(currentVariationKey, selectedVariations) },
          selectedVariations,
          isFoodCatalogEnabled,
        )
      : selectedVariations

    if (isClone) {
      setSelectedVariations(nextSelectedVariations)
    }

    const nextVariationKey = getNextVariationKey(
      currentVariationKey,
      nextSelectedVariations,
    )

    if (nextVariationKey) {
      setVariationsToCreate(newVariationsToCreate)
      focusOnVariation(nextVariationKey)
    } else {
      setCloseAfterCreationOn()
      if (inventoryToCreate?.businessInventoryItemId) {
        dispatch(
          createVariations(
            inventoryToCreate.businessInventoryItemId,
            R.flatten(Object.values(newVariationsToCreate)),
          ),
        )
      } else if (inventoryToCreate?.id) {
        dispatch(
          createInventory({
            [isFoodCatalogEnabled
              ? 'globalInventoryItemMappingId'
              : 'globalInventoryItemId']: inventoryToCreate.id,
            variations: R.flatten(Object.values(newVariationsToCreate)),
          }),
        )
      } else {
        dispatch(
          createInventory({
            ...inventoryToCreate,
            variations: R.flatten(Object.values(newVariationsToCreate)),
          }),
        )
      }
    }
  }

  const onGoBack = () => {
    const prevVariationKey = getPrevVariationKey(
      currentVariationKey,
      selectedVariations,
    )
    focusOnVariation(prevVariationKey)
  }

  const currentVariation =
    getVariation(currentVariationKey, variationsToCreate) ||
    getVariation(currentVariationKey, selectedVariations)

  const isGlobalItemWithoutVariation =
    isGlobalItem && (!inventoryToCreate || R.isEmpty(selectedVariations))

  useImperativeHandle(ref, () => ({
    confirmSave: () => {
      if (variationRef.current) {
        variationRef.current?.save()
      } else {
        onAddClick()
      }
    },
    hasUnsavedChanges: () =>
      variationRef.current?.getIsTouched() ||
      (isTouched && !isGlobalItemWithoutVariation),
  }))

  return currentVariationKey ? (
    <Grid container direction="column" onClick={() => setIsTouched(true)}>
      <Variation
        inventory={inventoryToCreate}
        nextVariation={getVariation(
          getNextVariationKey(currentVariationKey, selectedVariations),
          selectedVariations,
        )}
        ref={variationRef}
        singleVariationOnly={singleItemOnly}
        variation={currentVariation}
        variationIndex={getVariationIndex(
          currentVariationKey,
          selectedVariations,
        )}
        variationsCount={getVariationsCount(selectedVariations)}
        onBack={onGoBack}
        onVariationFilled={onVariationFilled}
      />
    </Grid>
  ) : (
    <Grid container item p={3} spacing={2} onClick={() => setIsTouched(true)}>
      <Grid item md={12} xs={12}>
        <Text variant="h2">
          {t('Admin:CATALOG.ADD_INVENTORY_ITEM.NEW_INVENTORY_ITEM')}
        </Text>
      </Grid>
      <CategorySelector
        category={inventory.categoryId}
        onCategorySet={(id: string) => {
          const subcategoryId =
            originalInventory?.categoryId === id
              ? originalInventory.subcategoryId
              : ''
          setInventory({ ...inventory, categoryId: id, subcategoryId })
        }}
      />
      {inventory.categoryId &&
        (isGlobalItem ? (
          <GlobalItemSelector
            itemCategoryId={inventory.categoryId}
            selectedItem={inventoryToCreate}
            selectedVariations={selectedVariations}
            setSelectedItem={setInventoryToCreate}
            setSelectedVariations={setSelectedVariations}
            onEdit={onClose}
          />
        ) : (
          <>
            <Inventory dialog inventory={inventory} ref={inventorySection} />
            <InventoryRestriction
              inventory={inventory}
              ref={inventoryRestrictionSection}
            />
          </>
        ))}
      <Grid container item alignItems="center" mt={3} spacing={3} xs={12}>
        <Grid item>
          <ButtonWithLoader
            className={classes.saveButton}
            disabled={
              isLoading || !inventory.categoryId || isGlobalItemWithoutVariation
            }
            loading={isLoading}
            onClick={onAddClick}
          >
            {isGlobalItem ? t('Common:NEXT') : t('Common:ADD_ACTION')}
          </ButtonWithLoader>
        </Grid>
        {isGlobalItem && (
          <Grid item>
            <LetUsKnowLink />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})

export default AddInventoryItem
