import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 2, 2, 2),
      overflowX: 'auto',
      ...theme.constants.scrollbar,
    },
    text: {
      lineHeight: '2.1rem',
    },
    noPadding: {
      padding: 0,
    },
  }),
  { name: 'TimelineCardContent' },
)

export interface TimelineCardContentProps {
  children: React.ReactNode
  className?: string
  component?: string | React.JSXElementConstructor<any>
  noPadding?: boolean
  noTypography?: boolean
}

const TimelineCardContent = ({
  component: Component = 'div',
  className,
  children,
  noTypography,
  noPadding,
  ...other
}: TimelineCardContentProps) => {
  const classes = useStyles()

  return (
    <Component
      className={classNames(classes.root, className, {
        [classes.noPadding]: noPadding,
      })}
      {...other}
    >
      {noTypography ? (
        children
      ) : (
        <Text className={classes.text}>{children}</Text>
      )}
    </Component>
  )
}

export default TimelineCardContent
