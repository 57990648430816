import React from 'react'
import Dotdotdot, { DotdotdotProps } from 'react-dotdotdot'

export interface WithDotDotDotProps extends DotdotdotProps {
  children: React.ReactNode
  condition: boolean
}

const WithDotDotDot = ({
  condition,
  children,
  clamp,
  ...rest
}: WithDotDotDotProps) =>
  condition ? (
    <Dotdotdot clamp={clamp || 1} {...rest}>
      {children}
    </Dotdotdot>
  ) : (
    <>{children}</>
  )

export default WithDotDotDot
