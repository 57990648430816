import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { PermissionArea } from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Email as EmailIcon,
  Eye as EyeIcon,
  Money as MoneyIcon,
  Print as PrintIcon,
  Sign as SignIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'
import { EstimateSnapshotItem } from '~/types'

import Snapshot from './Snapshot'

const columns = [
  {
    id: 'name',
    label: '',
    prop: 'name',
    width: 5.5,
  },
]

const EstimatesSnapshot = () => {
  const { t } = useTranslation('Common')

  const {
    delete: invoiceDeletePermissions,
    read: invoiceReadPermissions,
    update: invoiceUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const { update: appointmentUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleView = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddToAppointment = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRecordDeposit = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSign = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePrint = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEmail = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDownload = (item: EstimateSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelete = (item: EstimateSnapshotItem) => {}

  // Review permissions when implementing this Feature
  const getActions = (item: EstimateSnapshotItem) => [
    {
      id: 'view',
      label: t('Common:VIEW_ACTION'),
      Icon: EyeIcon,
      onClick: invoiceReadPermissions ? () => handleView(item) : undefined,
      disabled: !invoiceReadPermissions,
    },
    {
      id: 'addToAppointment',
      label: t('Common:ADD_TO_APPOINTMENT'),
      Icon: ArrowForwardIcon,
      onClick: appointmentUpdatePermissions
        ? () => handleAddToAppointment(item)
        : undefined,
      disabled: !appointmentUpdatePermissions,
    },
    // Implement useDisplayRecordDeposit hook if this component will be used someday.
    {
      id: 'recordDeposit',
      label: t('Common:RECORD_DEPOSIT_ACTION'),
      Icon: MoneyIcon,
      onClick: invoiceUpdatePermissions
        ? () => handleRecordDeposit(item)
        : undefined,
      disabled: !invoiceUpdatePermissions,
    },
    {
      id: 'sign',
      label: t('Common:SIGN_ESTIMATE_ACTION'),
      Icon: SignIcon,
      onClick: invoiceUpdatePermissions ? () => handleSign(item) : undefined,
      disabled: !invoiceUpdatePermissions,
    },
    {
      id: 'print',
      label: t('Common:PRINT_ACTION'),
      Icon: PrintIcon,
      onClick: invoiceReadPermissions ? () => handlePrint(item) : undefined,
      disabled: !invoiceReadPermissions,
    },
    {
      id: 'email',
      label: t('Common:EMAIL'),
      Icon: EmailIcon,
      onClick: invoiceReadPermissions ? () => handleEmail(item) : undefined,
      disabled: !invoiceReadPermissions,
    },
    {
      id: 'download',
      label: t('Common:DOWNLOAD'),
      Icon: DownloadIcon,
      onClick: invoiceReadPermissions ? () => handleDownload(item) : undefined,
      disabled: !invoiceReadPermissions,
    },
    {
      id: 'delete',
      label: t('Common:DELETE_ACTION'),
      Icon: DeleteIcon,
      onClick: invoiceDeletePermissions ? () => handleDelete(item) : undefined,
      disabled: !invoiceDeletePermissions,
    },
  ]

  return (
    <Snapshot
      noItems
      addActionName={t('Common:ADD_AN_ESTIMATE')}
      columns={columns}
      data={[]}
      getActions={getActions}
      isLoading={false}
      noRecentItems={false}
      title={t('Common:ESTIMATES_BUDGET')}
    />
  )
}

export default EstimatesSnapshot
