import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import DialogNames from '~/constants/DialogNames'
import { getPatient } from '~/store/reducers/patients'
import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

export const useOpenMissingClientPatientMissingFields = () => {
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const client = useSelector(getUser(appointment?.client))
  const patient = useSelector(getPatient(appointment?.patient))

  const [missingFieldsWarningWasShown, setMissingFieldsWarningWasShown] =
    useState(false)

  const [openMissingFieldDialog] = useDialog(
    DialogNames.PATIENT_MISSING_FIELDS_DIALOG,
  )

  useEffect(() => {
    const hasMissingFields =
      client &&
      patient &&
      (!patient.species ||
        !patient.gender ||
        R.isNil(patient.spayedNeutered) ||
        !patient.dateOfBirth)
    if (hasMissingFields && !missingFieldsWarningWasShown) {
      setMissingFieldsWarningWasShown(true)
      openMissingFieldDialog({ clientId: client.id, patient })
    }
  }, [patient, client])

  useEffect(() => {
    if (appointmentId) {
      setMissingFieldsWarningWasShown(false)
    }
  }, [appointmentId])
}
