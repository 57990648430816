import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import { Conversation } from '~/types'

const ConversationSubjectCell = ({ title, businessId }: Conversation) => (
  <Grid container item alignItems="center" wrap="nowrap">
    <Dotdotdot clamp={1}>
      <Text>{title}</Text>
    </Dotdotdot>
    {businessId && (
      <BusinessShareIcon businessIds={[businessId]} noMargin={!title} />
    )}
  </Grid>
)

export default ConversationSubjectCell
