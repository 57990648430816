import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError, User, ZoomSettings } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import type { RootState } from '~/store'
import { AtLeast } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import { updateEvents } from '../actions/timetable'
import requestAPI from '../sagas/utils/requestAPI'
import { finishLoading, startLoading } from './progress'

export const CREATE_ZOOM_LINK = 'conferencing/CREATE_ZOOM_LINK'
export const CREATE_ZOOM_LINK_SUCCESS = 'conferencing/CREATE_ZOOM_LINK_SUCCESS'
export const CREATE_ZOOM_LINK_FAILURE = 'conferencing/CREATE_ZOOM_LINK_FAILURE'

export const FETCH_ZOOM_USERS = 'conferencing/FETCH_ZOOM_USERS'
export const FETCH_ZOOM_USERS_SUCCESS = 'conferencing/FETCH_ZOOM_USERS_SUCCESS'
export const FETCH_ZOOM_USERS_FAILURE = 'conferencing/FETCH_ZOOM_USERS_FAILURE'

export const DELETE_ZOOM_LINK = 'conferencing/DELETE_ZOOM_LINK'
export const DELETE_ZOOM_LINK_SUCCESS = 'conferencing/DELETE_ZOOM_LINK_SUCCESS'
export const DELETE_ZOOM_LINK_FAILURE = 'conferencing/DELETE_ZOOM_LINK_FAILURE'

export const TEST_ZOOM_CONNECTION = 'ZOOM/TEST_CONNECTION'
export const TEST_ZOOM_CONNECTION_SUCCESS = 'ZOOM/TEST_ZOOM_CONNECTION_SUCCESS'
export const TEST_ZOOM_CONNECTION_FAILURE = 'ZOOM/TEST_ZOOM_CONNECTION_FAILURE'

export const FETCH_ZOOM_SETTINGS = 'ZOOM/FETCH_SETTINGS'
export const FETCH_ZOOM_SETTINGS_SUCCESS = 'ZOOM/FETCH_SETTINGS_SUCCESS'
export const FETCH_ZOOM_SETTINGS_FAILURE = 'ZOOM/FETCH_SETTINGS_FAILURE'

export const FETCH_IS_ZOOM_ENABLED = 'ZOOM/FETCH_IS_ZOOM_ENABLED'
export const FETCH_IS_ZOOM_ENABLED_SUCCESS =
  'ZOOM/FETCH_IS_ZOOM_ENABLED_SUCCESS'
export const FETCH_IS_ZOOM_ENABLED_FAILURE =
  'ZOOM/FETCH_IS_ZOOM_ENABLED_FAILURE'

export const UPDATE_ZOOM_SETTINGS = 'ZOOM/UPDATE_SETTINGS'
export const UPDATE_ZOOM_SETTINGS_SUCCESS = 'ZOOM/UPDATE_SETTINGS_SUCCESS'
export const UPDATE_ZOOM_SETTINGS_FAILURE = 'ZOOM/UPDATE_SETTINGS_FAILURE'

export const createZoomLink = (appointmentId: string, zoomUserId: string) => ({
  type: CREATE_ZOOM_LINK,
  appointmentId,
  zoomUserId,
})
export const createZoomLinkSuccess = () => ({ type: CREATE_ZOOM_LINK_SUCCESS })
export const createZoomLinkFailure = (error: ApiError) => ({
  type: CREATE_ZOOM_LINK_FAILURE,
  error,
})

export const fetchZoomUsers = () => ({ type: FETCH_ZOOM_USERS })
export const fetchZoomUsersSuccess = (zoomUsers: AtLeast<User, 'id'>[]) => ({
  type: FETCH_ZOOM_USERS_SUCCESS,
  zoomUsers,
})
export const fetchZoomUsersFailure = (error: ApiError) => ({
  type: FETCH_ZOOM_USERS_FAILURE,
  error,
})

export const deleteZoomLink = (appointmentId: string) => ({
  type: DELETE_ZOOM_LINK,
  appointmentId,
})
export const deleteZoomLinkSuccess = (appointmentId: string) => ({
  type: DELETE_ZOOM_LINK_SUCCESS,
  appointmentId,
})
export const deleteZoomLinkFailure = (error: ApiError) => ({
  type: DELETE_ZOOM_LINK_FAILURE,
  error,
})

export const testZoomConnection = (
  zoomAccountId: string,
  zoomClientId: string,
  zoomClientSecret: string,
) => ({
  type: TEST_ZOOM_CONNECTION,
  zoomAccountId,
  zoomClientId,
  zoomClientSecret,
})
export const testZoomConnectionSuccess = (credentialsValid: boolean) => ({
  type: TEST_ZOOM_CONNECTION_SUCCESS,
  credentialsValid,
})
export const testZoomConnectionFailure = (error: ApiError) => ({
  type: TEST_ZOOM_CONNECTION_FAILURE,
  error,
})

export const fetchZoomSettings = (businessId: string) => ({
  type: FETCH_ZOOM_SETTINGS,
  businessId,
})
export const fetchZoomSettingsSuccess = (zoomSettings: ZoomSettings) => ({
  type: FETCH_ZOOM_SETTINGS_SUCCESS,
  zoomSettings,
})
export const fetchZoomSettingsFailure = (error: ApiError) => ({
  type: FETCH_ZOOM_SETTINGS_FAILURE,
  error,
})

export const fetchIsZoomEnabled = (businessId: string) => ({
  type: FETCH_IS_ZOOM_ENABLED,
  businessId,
})
export const fetchIsZoomEnabledSuccess = (
  integrationEnabled: ZoomSettings['integrationEnabled'],
) => ({
  type: FETCH_IS_ZOOM_ENABLED_SUCCESS,
  integrationEnabled,
})
export const fetchIsZoomEnabledFailure = (error: ApiError) => ({
  type: FETCH_IS_ZOOM_ENABLED_FAILURE,
  error,
})

export const updateZoomSettings = (zoomSettings: ZoomSettings) => ({
  type: UPDATE_ZOOM_SETTINGS,
  zoomSettings,
})
export const updateZoomSettingsSuccess = (zoomSettingsUpdateResult: {
  zoomSettings: ZoomSettings
}) => ({
  type: UPDATE_ZOOM_SETTINGS_SUCCESS,
  zoomSettingsUpdateResult,
})
export const updateZoomSettingsFailure = (error: ApiError) => ({
  type: UPDATE_ZOOM_SETTINGS_FAILURE,
  error,
})

export const CONFERENCING_SENSITIVE_ACTIONS = [
  {
    type: TEST_ZOOM_CONNECTION,
    sensitiveData: ['zoomAccountId', 'zoomClientId', 'zoomClientSecret'],
  },
  {
    type: FETCH_ZOOM_SETTINGS_SUCCESS,
    sensitiveData: ['zoomSettings'],
  },
  {
    type: UPDATE_ZOOM_SETTINGS,
    sensitiveData: ['zoomSettings'],
  },
  {
    type: UPDATE_ZOOM_SETTINGS_SUCCESS,
    sensitiveData: ['zoomSettingsUpdateResult'],
  },
]

const ZoomSettingsUpdateStatus = {
  OK: 'OK',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
}

export type ConferencingState = {
  error: string | null
  isTestingConnection: boolean
  isZoomEnabled: boolean | null
  isZoomLinkCreating: boolean
  isZoomLinkDeleting: boolean
  isZoomSettingsLoading: boolean
  isZoomSettingsWithoutKeysLoading: boolean
  isZoomUsersLoading: boolean
  lastTestCredentialsValid: boolean
  zoomSettings: ZoomSettings
  zoomUsers: AtLeast<User, 'id'>[]
}

export const INITIAL_STATE: ConferencingState = {
  error: null,
  isTestingConnection: false,
  isZoomEnabled: null,
  isZoomLinkCreating: false,
  isZoomLinkDeleting: false,
  isZoomSettingsLoading: false,
  isZoomSettingsWithoutKeysLoading: false,
  isZoomUsersLoading: false,
  lastTestCredentialsValid: false,
  zoomSettings: {},
  zoomUsers: [],
}

export const conferencingReducer = (
  state: ConferencingState = INITIAL_STATE,
  action: AnyAction,
): ConferencingState => {
  switch (action.type) {
    case CREATE_ZOOM_LINK:
      return { ...state, isZoomLinkCreating: true }
    case CREATE_ZOOM_LINK_SUCCESS:
      return { ...state, isZoomLinkCreating: false }
    case CREATE_ZOOM_LINK_FAILURE:
      return {
        ...state,
        isZoomLinkCreating: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_ZOOM_USERS:
      return { ...state, isZoomUsersLoading: true }
    case FETCH_ZOOM_USERS_SUCCESS:
      return {
        ...state,
        zoomUsers: action.zoomUsers || [],
        isZoomUsersLoading: false,
      }
    case FETCH_ZOOM_USERS_FAILURE:
      return {
        ...state,
        isZoomUsersLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_ZOOM_LINK:
      return { ...state, isZoomLinkDeleting: true }
    case DELETE_ZOOM_LINK_SUCCESS:
      return { ...state, isZoomLinkDeleting: false }
    case DELETE_ZOOM_LINK_FAILURE:
      return {
        ...state,
        isZoomLinkDeleting: false,
        error: getErrorMessage(action.error),
      }
    case TEST_ZOOM_CONNECTION:
      return { ...state, isTestingConnection: true }
    case TEST_ZOOM_CONNECTION_SUCCESS:
      return {
        ...state,
        isTestingConnection: false,
        lastTestCredentialsValid: action.credentialsValid,
      }
    case TEST_ZOOM_CONNECTION_FAILURE:
      return {
        ...state,
        isTestingConnection: false,
        lastTestCredentialsValid: false,
        error: getErrorMessage(action.error),
      }

    case FETCH_ZOOM_SETTINGS:
      return { ...state, isZoomSettingsLoading: true }
    case FETCH_ZOOM_SETTINGS_SUCCESS:
      return {
        ...state,
        isZoomSettingsLoading: false,
        zoomSettings: action.zoomSettings,
      }
    case FETCH_ZOOM_SETTINGS_FAILURE:
      return {
        ...state,
        isZoomSettingsLoading: false,
        error: getErrorMessage(action.error),
      }

    case FETCH_IS_ZOOM_ENABLED:
      return { ...state, isZoomSettingsWithoutKeysLoading: true, error: null }
    case FETCH_IS_ZOOM_ENABLED_SUCCESS:
      return {
        ...state,
        isZoomSettingsWithoutKeysLoading: false,
        isZoomEnabled: action.integrationEnabled,
      }
    case FETCH_IS_ZOOM_ENABLED_FAILURE:
      return {
        ...state,
        isZoomSettingsWithoutKeysLoading: false,
        error: getErrorMessage(action.error),
      }

    case UPDATE_ZOOM_SETTINGS:
      return {
        ...state,
        isZoomSettingsLoading: true,
      }
    case UPDATE_ZOOM_SETTINGS_SUCCESS:
      return {
        ...state,
        isZoomSettingsLoading: false,
        zoomSettings:
          action.zoomSettingsUpdateResult?.zoomSettings || state.zoomSettings,
        lastTestCredentialsValid:
          action.zoomSettingsUpdateResult?.status ===
          ZoomSettingsUpdateStatus.OK,
      }
    case UPDATE_ZOOM_SETTINGS_FAILURE:
      return {
        ...state,
        isZoomSettingsLoading: false,
        lastTestCredentialsValid: false,
      }

    default:
      return state
  }
}

const getConferencing = (state: RootState): ConferencingState =>
  state.conferencing
export const getIsZoomLinkCreating = (state: RootState) =>
  getConferencing(state).isZoomLinkCreating
export const getIsZoomUsersLoading = (state: RootState) =>
  getConferencing(state).isZoomUsersLoading
export const getIsZoomLinkDeleting = (state: RootState) =>
  getConferencing(state).isZoomLinkDeleting
export const getIsZoomSettingsLoading = (state: RootState) =>
  getConferencing(state).isZoomSettingsLoading
export const getZoomUsers = (state: RootState) =>
  getConferencing(state).zoomUsers
export const getZoomSettings = (state: RootState) =>
  getConferencing(state).zoomSettings
export const getIsZoomEnabled = (state: RootState) =>
  getConferencing(state).isZoomEnabled
export const getZoomIsTestingConnection = (state: RootState) =>
  getConferencing(state).isTestingConnection
export const getZoomLastTestCredentialsValid = (state: RootState) =>
  getConferencing(state).lastTestCredentialsValid
export const getConferencingSanitized = (state: RootState) => ({
  ...state.conferencing,
  zoomSettings: {
    ...(state.conferencing.zoomSettings ?? {}),
    zoomAccountId: '*',
    zoomClientId: '*',
    zoomClientSecret: '*',
  },
})

export function* createZoomLinkSaga({
  appointmentId,
  zoomUserId,
}: ReturnType<typeof createZoomLink>) {
  try {
    yield put(startLoading('zoom-link-create'))
    const appointmentZoomDetails = yield* requestAPI(
      API.createZoomLink,
      appointmentId,
      zoomUserId,
    )
    yield put(createZoomLinkSuccess())
    yield put(updateEvents({ [appointmentId]: appointmentZoomDetails }))
  } catch (error) {
    yield put(createZoomLinkFailure(error as ApiError))
  } finally {
    yield put(finishLoading('zoom-link-create'))
  }
}

export function* fetchZoomUsersSaga() {
  try {
    const zoomUsers = yield* requestAPI(API.fetchZoomUsers)
    yield put(fetchZoomUsersSuccess(zoomUsers))
  } catch (error) {
    yield put(fetchZoomUsersFailure(error as ApiError))
  }
}

export function* deleteZoomLinkSaga({
  appointmentId,
}: ReturnType<typeof deleteZoomLink>) {
  try {
    yield put(startLoading('zoom-link-deleting'))
    yield* requestAPI(API.deleteZoomLink, appointmentId)
    yield put(deleteZoomLinkSuccess(appointmentId))
  } catch (error) {
    yield put(deleteZoomLinkFailure(error as ApiError))
  } finally {
    yield put(finishLoading('zoom-link-deleting'))
  }
}

export function* testZoomConnectionSaga({
  zoomAccountId,
  zoomClientId,
  zoomClientSecret,
}: ReturnType<typeof testZoomConnection>) {
  try {
    const credentialsValid = yield* requestAPI(
      API.testZoomConnection,
      zoomAccountId,
      zoomClientId,
      zoomClientSecret,
    )
    yield put(testZoomConnectionSuccess(credentialsValid))
  } catch (error) {
    yield put(testZoomConnectionFailure(error as ApiError))
  }
}

export function* updateZoomSettingsSaga({
  zoomSettings,
}: ReturnType<typeof updateZoomSettings>) {
  try {
    const credentialsValid = yield* requestAPI(
      API.updateZoomSettings,
      zoomSettings,
    )
    yield put(updateZoomSettingsSuccess(credentialsValid))
  } catch (error) {
    yield put(updateZoomSettingsFailure(error as ApiError))
  }
}

export function* fetchZoomSettingsSaga({
  businessId,
}: ReturnType<typeof fetchZoomSettings>) {
  try {
    const zoomSettings = yield* requestAPI(
      API.fetchZoomSettings,
      businessId,
      true,
    )
    yield put(fetchZoomSettingsSuccess(zoomSettings))
  } catch (error) {
    yield put(fetchZoomSettingsFailure(error as ApiError))
  }
}

export function* fetchIsZoomEnabledSaga({
  businessId,
}: ReturnType<typeof fetchIsZoomEnabled>) {
  try {
    const zoomSettings = yield* requestAPI(
      API.fetchZoomSettings,
      businessId,
      false,
    )
    yield put(fetchIsZoomEnabledSuccess(zoomSettings.integrationEnabled))
  } catch (error) {
    yield put(fetchIsZoomEnabledFailure(error as ApiError))
  }
}

function* watchCreateZoomLink() {
  yield takeLatest(CREATE_ZOOM_LINK, createZoomLinkSaga)
}

function* watchFetchZoomUsers() {
  yield takeLatest(FETCH_ZOOM_USERS, fetchZoomUsersSaga)
}

function* watchDeleteZoomLink() {
  yield takeLatest(DELETE_ZOOM_LINK, deleteZoomLinkSaga)
}

function* watchTestZoomConnection() {
  yield takeLatest(TEST_ZOOM_CONNECTION, testZoomConnectionSaga)
}

function* watchUpdateZoomSettings() {
  yield takeLatest(UPDATE_ZOOM_SETTINGS, updateZoomSettingsSaga)
}

function* watchFetchZoomSettings() {
  yield takeLatest(FETCH_ZOOM_SETTINGS, fetchZoomSettingsSaga)
}

function* watchFetchIsZoomEnabled() {
  yield takeLatest(FETCH_IS_ZOOM_ENABLED, fetchIsZoomEnabledSaga)
}

export function* conferencingSaga() {
  yield all([
    watchCreateZoomLink(),
    watchFetchZoomUsers(),
    watchDeleteZoomLink(),
    watchTestZoomConnection(),
    watchUpdateZoomSettings(),
    watchFetchZoomSettings(),
    watchFetchIsZoomEnabled(),
  ])
}
