import React, { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid, ListItemIcon, Menu, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CircularProgressOverlay,
  Nil,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'
import {
  Calendar as CalendarIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Duplicate as CloneIcon,
  Email as EmailIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import PuiIconButton from '~/components/common/PuiIconButton'
import useCloneEstimate from '~/components/dashboard/soapV2/estimates/utils/useCloneEstimate'
import { deleteEstimate } from '~/store/actions/finance'
import { fetchRhapsodyPayConfig } from '~/store/duck/rhapsodyPay'
import { useGetEstimateState } from '~/store/hooks/estimate'
import { usePrintEmailOrDownloadInvoice } from '~/store/hooks/finance'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import {
  getFinanceIsEstimateDeleting,
  getFinanceIsFetching,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import {
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { BatchInvoice, InvoiceOrEstimate, SaveInvoice } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    addToAppointmentButton: {
      minWidth: 174,
    },
    requestPaymentButton: {
      minWidth: 156,
    },
    saveButton: {
      minWidth: 120,
    },
    recordDepositButton: {
      minWidth: 139,
    },
    recordPaymentButton: {
      minWidth: 148,
    },
    menuButtonIcon: {
      borderRadius: '50%',
      backgroundColor: 'unset',
      border: '1px solid',
      borderColor: theme.colors.lowAccentText,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    menuButtonSvg: {
      color: theme.colors.lowAccentText,
    },
    listItemIcon: {
      position: 'relative',
    },
    listItemIconProgress: {
      left: theme.spacing(-1),
    },
  }),
  { name: 'InvoiceActionsMenu' },
)

export interface InvoiceActionsMenuProps {
  addToAppointment: () => void
  clientId: string | Nil
  includeServiceFee: boolean
  invoice: InvoiceOrEstimate | BatchInvoice
  isEstimate: boolean
  onClone?: () => void
  onOk?: (id?: string | Nil) => void
  saveIfNeeded: (
    handler: (invoice: InvoiceOrEstimate | BatchInvoice) => void,
    invoiceParams?: SaveInvoice,
  ) => void
  soapId: string | Nil
}

const InvoiceActionsMenu = ({
  clientId,
  invoice: invoiceProp,
  soapId,
  isEstimate,
  includeServiceFee,
  onClone,
  onOk,
  saveIfNeeded,
  addToAppointment,
}: InvoiceActionsMenuProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices', 'Tooltips'])

  const isLoadingFinance = useSelector(getFinanceIsLoading)
  const isLoadingEstimateDeleting = useSelector(getFinanceIsEstimateDeleting)
  const isLoading = isLoadingFinance || isLoadingEstimateDeleting
  const business = useSelector(getCurrentBusiness)
  const invoicePermissionsUpdate = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  ).update
  const appointmentPermissionsUpdate = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  ).update
  const isSoapLoading = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const isFetching =
    useSelector(getFinanceIsFetching) || isSoapLoading || isLoadingSoapOrders

  useEffect(() => {
    if (business?.id) {
      dispatch(fetchRhapsodyPayConfig(business.id))
    }
  }, [business?.id])

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(menuAnchorEl)
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const { onCloneRequested } = useCloneEstimate({
    clientId,
    estimateId: invoiceProp.id,
    onDialogOpen: () => {
      handleCloseMenu()
      if (onClone) onClone()
    },
    patientId: invoiceProp.patient,
    soapBusinessId: business?.id,
    soapId,
  })

  const {
    onPrintInvoiceRequested,
    onEmailInvoiceRequested,
    onDownloadPdfRequested,
    isGenerating,
  } = usePrintEmailOrDownloadInvoice({
    invoiceProp,
    includeServiceFee,
    onOk,
    clientId,
    isEstimate,
  })

  const onDeleteEstimateRequested = () => {
    dispatch(deleteEstimate(invoiceProp.id))
  }

  const saveAndPrint = () => saveIfNeeded(onPrintInvoiceRequested)

  const saveAndEmail = () => saveIfNeeded(onEmailInvoiceRequested)

  const saveAndDownloadPdf = () => saveIfNeeded(onDownloadPdfRequested)

  const saveAndConCloneRequested = () => saveIfNeeded(onCloneRequested)

  const estimateState = useGetEstimateState()(invoiceProp.stateId)

  const hasNoPayments =
    R.isEmpty(invoiceProp.payments) || R.isNil(invoiceProp.payments)

  return (
    <Grid container>
      <PuiIconButton
        Icon={MoreHorizIcon}
        aria-controls={menuOpen ? 'invoice-actions-menu' : undefined}
        aria-expanded={menuOpen ? 'true' : undefined}
        aria-haspopup="true"
        className={classes.menuButtonIcon}
        classes={{ icon: classes.menuButtonSvg }}
        id="invoice-actions-basic-button"
        onClick={handleOpenMenu}
      />
      <Menu
        MenuListProps={{
          'aria-labelledby': 'invoice-actions-basic-button',
        }}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="invoice-actions-menu"
        open={menuOpen}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleCloseMenu}
      >
        {isEstimate && estimateState.isApproved && (
          <MenuItem
            disabled={
              !appointmentPermissionsUpdate ||
              isFetching ||
              isLoading ||
              !invoiceProp.id
            }
            onClick={addToAppointment}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CircularProgressOverlay
                halfTransparent
                className={classes.listItemIconProgress}
                open={isGenerating || isLoading || isFetching}
              />
              <CalendarIcon fontSize="small" />
            </ListItemIcon>
            <Text variant="body">{t('Common:ADD_TO_APPOINTMENT')}</Text>
          </MenuItem>
        )}
        {isEstimate && invoiceProp.id && (
          <MenuItem
            disabled={isLoading || isFetching}
            onClick={saveAndConCloneRequested}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CircularProgressOverlay
                halfTransparent
                className={classes.listItemIconProgress}
                open={isFetching || isLoading}
              />
              <CloneIcon />
            </ListItemIcon>
            <Text variant="body">{t('Common:CLONE_ACTION')}</Text>
          </MenuItem>
        )}
        {/* Draft estimates with payments attached to it should not allow to delete the estimate */}
        {isEstimate &&
          estimateState.isDraft &&
          invoiceProp.id &&
          hasNoPayments && (
            <MenuItem
              disabled={isLoading || isFetching}
              onClick={onDeleteEstimateRequested}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <CircularProgressOverlay
                  halfTransparent
                  className={classes.listItemIconProgress}
                  open={isFetching || isLoading}
                />
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Text variant="body">{t('Common:DELETE_ACTION')}</Text>
            </MenuItem>
          )}
        <MenuItem
          disabled={isGenerating || isLoading || isFetching}
          onClick={saveAndDownloadPdf}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CircularProgressOverlay
              halfTransparent
              className={classes.listItemIconProgress}
              open={isGenerating || isLoading || isFetching}
            />
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <Text variant="body">{t('Common:DOWNLOAD_ACTION')}</Text>
        </MenuItem>
        <MenuItem
          disabled={!invoicePermissionsUpdate || isFetching || isLoading}
          onClick={saveAndEmail}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CircularProgressOverlay
              halfTransparent
              className={classes.listItemIconProgress}
              open={isFetching || isLoading}
            />
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <Text variant="body">{t('Common:EMAIL')}</Text>
        </MenuItem>
        <MenuItem
          disabled={!invoicePermissionsUpdate || isFetching || isLoading}
          onClick={saveAndPrint}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <CircularProgressOverlay
              halfTransparent
              className={classes.listItemIconProgress}
              open={isFetching || isLoading}
            />
            <PrintIcon fontSize="small" />
          </ListItemIcon>
          <Text variant="body">{t('Common:PRINT_ACTION')}</Text>
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export default InvoiceActionsMenu
