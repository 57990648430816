import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import RecurringEvent, { RecurringEventProps } from './RecurringEvent'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 385,
      maxWidth: 385,
    },
  }),
  { name: 'RecurringEventDialog' },
)

export interface RecurringEventDialogProps
  extends BasePuiDialogProps,
    RecurringEventProps {}

const RecurringEventDialog = ({
  open,
  onClose,
  onProceed: onProceedProp,
  ...rest
}: RecurringEventDialogProps) => {
  const classes = useStyles()

  const onProceed: RecurringEventProps['onProceed'] = (...params) => {
    onProceedProp(...params)

    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="recurring-event-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <RecurringEvent onProceed={onProceed} {...rest} />
    </PuiDialog>
  )
}

export default RecurringEventDialog
