import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { BasePuiDialogProps, IdObject, Nil } from '@pbt/pbt-ui-components'

import AttachmentDialog from '~/components/common/inputs/attachment/attachment-dialog/AttachmentDialog'
import { AttachmentPreviewProps } from '~/components/common/inputs/attachment/attachment-dialog/AttachmentPreview'
import {
  saveAttachmentHistoryDocuments,
  saveAttachmentHistoryV2,
} from '~/store/actions/medicalHistory'
import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import { getAppointmentId } from '~/store/reducers/soap'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import AttachmentHistoryDetails from './AttachmentHistoryDetails'

interface AttachmentHistoryDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
}

const AttachmentHistoryDialog = ({
  open,
  onClose,
  clientId,
  patientId,
}: AttachmentHistoryDialogProps) => {
  const dispatch = useDispatch()
  const isSending = useSelector(getMedicalHistoryIsSending)
  const eventId = useSelector(getAppointmentId)
  const { t } = useTranslation('Common')

  const setCloseAfterSavedOn = useCloseAfterCreation(
    onClose,
    getMedicalHistoryIsSending,
  )

  const onListProceed = (items: IdObject[]) => {
    setCloseAfterSavedOn()
    if (patientId) {
      dispatch(saveAttachmentHistoryDocuments(items, patientId, clientId))
    }
  }

  const onPreviewProceed = (file: AttachmentPreviewProps['file']) => {
    if (file?.raw) {
      setCloseAfterSavedOn()
      const fileFields = R.omit(['file', 'raw', 'data'], file)
      if (patientId) {
        dispatch(
          saveAttachmentHistoryV2(file.raw, fileFields, patientId, clientId),
        )
      }
    }
  }

  return (
    <AttachmentDialog
      ListWithFiltersProps={{
        ListItemProps: {
          showListItemActions: true,
        },
        DetailsComponent: AttachmentHistoryDetails,
        isLoading: isSending,
      }}
      PreviewProps={{
        DetailsComponent: AttachmentHistoryDetails,
      }}
      eventId={eventId}
      isLoading={isSending}
      open={open}
      patientId={patientId}
      title={t('Common:ADD_HISTORICAL_ATTACHMENT')}
      onClose={onClose}
      onListProceed={onListProceed}
      onPreviewProceed={onPreviewProceed}
    />
  )
}

export default AttachmentHistoryDialog
