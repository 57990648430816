import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiTextField, Utils } from '@pbt/pbt-ui-components'

import EditItemButtonGroup from '~/components/common/buttons/EditItemButtonGroup'
import DispensedFromLabel from '~/components/common/labels/DispensedFromLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { useIsDrug } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  DispensedFromData,
  Prescription,
  ShipmentItem,
  Variation,
} from '~/types'
import { getPrescriptionName } from '~/utils/prescription'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { ShipmentItemSearchCriteria } from '../../soap/order/vaccine/ChooseShipmentItemDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'DispensedFromSection' },
)

export interface DispensedFromSectionProps {
  onFieldsChange: (buttonVisible: boolean) => void
  onRefChange?: () => void
  prescription: Prescription
}

export interface DispensedFromSectionHandle {
  chooseFromInventory: () => void
  get: () => DispensedFromData
}

const DispensedFromSection = forwardRef<
  DispensedFromSectionHandle,
  DispensedFromSectionProps
>(function DispensedFromSection(
  { onFieldsChange, onRefChange, prescription },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [openShipmentItemsDialog] = useDialog(
    DialogNames.CHOOSE_SHIPMENT_ITEM_DIALOG,
  )

  const [fields, setFields] = useState<DispensedFromData>({})

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [fields])

  const variationId = prescription?.variation?.id

  const getFields = (item: Prescription | ShipmentItem) => ({
    lotNumber: item?.lotNumber,
    serialNumber: item?.serialNumber,
    businessReferenceNumber: item?.businessReferenceNumber,
    manufacturerId: item?.manufacturerId,
    manufacturerName: item?.manufacturerName,
    expirationDate: item?.expirationDate,
  })

  const onInventoryChange = (props: ShipmentItem) => setFields(getFields(props))

  const initialFields = getFields(prescription)
  const isDrug = useIsDrug(prescription)

  useEffect(() => {
    if (Object.values(initialFields).some(Boolean)) {
      setFields(initialFields)
    }
  }, Object.values(initialFields))

  const onDiscard = () => setFields({})

  const handleChooseFromInventory = () => {
    openShipmentItemsDialog({
      // TODO: consider changing to another prop interface
      order: {
        name: getPrescriptionName(prescription, isFoodCatalogEnabled) || '',
        variation: { id: variationId } as Variation,
      },
      showToggle: !isDrug,
      onSelect: onInventoryChange,
      searchCriteria: ShipmentItemSearchCriteria.INVENTORY,
    })
  }

  const handleBusinessReferenceNumberChange = (name: string) => {
    setFields({ ...fields, businessReferenceNumber: name })
  }

  useImperativeHandle(ref, () => ({
    get: () => fields,
    chooseFromInventory: handleChooseFromInventory,
  }))

  useEffect(() => {
    onFieldsChange(R.isEmpty(fields))
  }, [fields])

  if (R.isEmpty(fields)) {
    return null
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      className={classes.root}
      mt={2}
      p={1}
      wrap="nowrap"
    >
      <Grid container item xs direction="column">
        <DispensedFromLabel
          lotNumber={fields.lotNumber}
          manufacturerId={fields.manufacturerId}
          manufacturerName={fields.manufacturerName}
          serialNumber={fields.serialNumber}
        />
        <PuiTextField
          label={t('Common:PRACTICE_REFERENCE')}
          value={fields.businessReferenceNumber}
          onChange={Utils.handleFormTextInput(
            handleBusinessReferenceNumberChange,
          )}
        />
      </Grid>
      <Grid container item alignItems="center" justifyContent="flex-end" xs={2}>
        <EditItemButtonGroup
          onDelete={onDiscard}
          onEdit={handleChooseFromInventory}
        />
      </Grid>
    </Grid>
  )
})

export default DispensedFromSection
