export const FETCH_BUNDLES_LIST_FAILURE = 'bundles/FETCH_BUNDLES_LIST_FAILURE'
export const FETCH_BUNDLES_LIST_SUCCESS = 'bundles/FETCH_BUNDLES_LIST_SUCCESS'
export const FETCH_BUNDLES_LIST = 'bundles/FETCH_BUNDLES_LIST'

export const FETCH_BUNDLE = 'bundles/FETCH_BUNDLE'
export const FETCH_BUNDLE_FAILURE = 'bundles/FETCH_BUNDLE_FAILURE'
export const FETCH_BUNDLE_SUCCESS = 'bundles/FETCH_BUNDLE_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_FAILURE =
  'bundles/FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_SUCCESS =
  'bundles/FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_BUNDLES_LIST =
  'bundles/FETCH_MORE_ITEMS_FOR_BUNDLES_LIST'

export const CREATE_BUNDLE = 'bundles/CREATE_BUNDLE'
export const CREATE_BUNDLE_FAILURE = 'bundles/CREATE_BUNDLE_FAILURE'
export const CREATE_BUNDLE_SUCCESS = 'bundles/CREATE_BUNDLE_SUCCESS'

export const EDIT_BUNDLE = 'bundles/EDIT_BUNDLE'
export const EDIT_BUNDLE_FAILURE = 'bundles/EDIT_BUNDLE_FAILURE'
export const EDIT_BUNDLE_SUCCESS = 'bundles/EDIT_BUNDLE_SUCCESS'

export const DELETE_BUNDLE = 'bundles/DELETE_BUNDLE'
export const DELETE_BUNDLE_FAILURE = 'bundles/DELETE_BUNDLE_FAILURE'
export const DELETE_BUNDLE_SUCCESS = 'bundles/DELETE_BUNDLE_SUCCESS'

export const UPDATE_BUNDLES = 'bundles/UPDATE_BUNDLES'
