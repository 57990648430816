import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  DateUtils,
  HtmlNotesPreview,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { getAddendum } from '~/store/duck/addendums'
import { getUser } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    title: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'AddendumItem' },
)

export interface AddendumItemProps {
  addendumId: string
  className?: string
}

const AddendumItem = ({ className, addendumId }: AddendumItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const addendum = useSelector(getAddendum(addendumId))
  const person = useSelector(getUser(addendum?.person))

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <Grid item className={classes.titleContainer} p={2} xs={2}>
        <Text className={classes.title} variant="h4">
          {`${t('Common:ADDENDUM')} \n${DateUtils.formatDate(addendum?.date)}`}
        </Text>
        <Text mt={1} variant="lowAccent2">
          {`${DateUtils.formatTime(
            addendum?.date || '',
          )} | ${Utils.getPersonString(person)}`}
        </Text>
      </Grid>
      <Grid item xs p={2}>
        <HtmlNotesPreview notes={addendum?.notes} />
      </Grid>
    </Grid>
  )
}

export default AddendumItem
