import React from 'react'
import { useSelector } from 'react-redux'
import { TextInteractiveProps, Utils } from '@pbt/pbt-ui-components'

import { ChewyPet } from '~/api/graphql/generated/types'
import { getGenderString } from '~/components/common/inputs/gender/genderUtils'
import DetailsSeparatedLabel, {
  DetailsItem,
} from '~/components/common/labels/DetailsSeparatedLabel'
import { getGender, getSpecies } from '~/store/reducers/constants'
import { getAge } from '~/utils'

interface PetInfoLabelProps extends TextInteractiveProps {
  petProfile: ChewyPet
}

const PetInfoLabel = ({ petProfile, ...rest }: PetInfoLabelProps) => {
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)

  const details = [
    petProfile?.dateOfBirth && {
      id: 'age',
      text: getAge(petProfile?.dateOfBirth),
    },
    petProfile?.gender && {
      id: 'gender',
      text: getGenderString(petProfile.gender, Gender),
    },
    petProfile?.species && {
      id: 'species',
      text: Utils.getConstantsValue(petProfile?.species, Species)?.name,
    },
  ].filter(Boolean) as DetailsItem[]

  return <DetailsSeparatedLabel details={details} {...rest} />
}

export default PetInfoLabel
