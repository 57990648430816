import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  AlertIconType,
  BasePuiDialogProps,
  PuiAlert,
} from '@pbt/pbt-ui-components'

import { deleteConversation } from '~/store/actions/conversations'
import {
  getConversationById,
  getConversationDeleting,
} from '~/store/reducers/conversations'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

interface ConversationDeleteDialogProps extends BasePuiDialogProps {
  conversationId: string
}

const ConversationDeleteDialog = ({
  open,
  onClose,
  conversationId,
}: ConversationDeleteDialogProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLoading = useSelector(getConversationDeleting)
  const isConversationDeleted = !useSelector(
    getConversationById(conversationId),
  )
  const { t } = useTranslation(['Common', 'Dialogs'])

  const onConversationDeleted = () => {
    if (isConversationDeleted) {
      if (onClose) {
        onClose()
      }
      navigate('/communications')
    }
  }

  const closeAfterConversationDeleted = useCloseAfterCreation(
    onConversationDeleted,
    getConversationDeleting,
  )

  const handleDeleteConversation = () => {
    closeAfterConversationDeleted()
    dispatch(deleteConversation(conversationId))
  }

  return (
    <PuiAlert
      CancelButtonProps={{ disabled: isLoading }}
      OkButtonProps={{ loading: isLoading }}
      iconType={AlertIconType.WARN}
      message={t('Dialogs:CONVERSATION_DELETE_DIALOG.MESSAGE')}
      okButtonText={t('Common:DELETE_ACTION')}
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onOk={handleDeleteConversation}
    />
  )
}

export default ConversationDeleteDialog
