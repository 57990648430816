import React from 'react'
import { useTranslation } from 'react-i18next'

import ToggleLabel, { ToggleLabelProps } from '../ToggleLabel'

export interface TimeSelectorTextLabelProps {
  onClick: ToggleLabelProps['onClick']
  value: string
}

const TimeSelectorTextLabel = ({
  onClick,
  value,
}: TimeSelectorTextLabelProps) => {
  const { t } = useTranslation('Time')

  return (
    <ToggleLabel text={value || t('Time:LABEL.ALL_DUE')} onClick={onClick} />
  )
}

export default TimeSelectorTextLabel
