import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CustomFieldValidatorState,
  ErrorTooltip,
  LanguageUtils,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiCheckboxList from '~/components/common/PuiCheckboxList'
import { getLanguages } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps } from '~/types'
import { useBusinessSupportedLanguages } from '~/utils/useBusinessSupportedLanguages'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  () => ({
    checkboxListContainer: {
      padding: 0,
    },
  }),
  { name: 'LocalizationsSection' },
)

const validateSelectedLanguages = ({ value }: CustomFieldValidatorState) =>
  value.length >= 1

const LocalizationsSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Validations'])

  const LanguagesConstant = useSelector(getLanguages)
  const { CurrentSupportedLanguageCodes } = useBusinessSupportedLanguages(
    business.id,
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'supportedLanguageCodes',
        initialValue: CurrentSupportedLanguageCodes,
        validators: [
          {
            validator: validateSelectedLanguages,
            validatorName: 'supportedLanguageCodes',
          },
        ],
        messages: {
          supportedLanguageCodes: t('Validations:SELECT_AT_LEAST_ONE_LANGUAGE'),
        },
      },
    ],
    false,
  )

  const { supportedLanguageCodes } = fields

  usePracticeFieldsSection({
    fields,
    business,
    sectionName: PracticeDetailsPanels.LOCALIZATIONS,
    validate,
    reset,
  })

  const Languages = LanguageUtils.getSortedSupportedLanguages(
    LanguagesConstant,
    'fullName',
  ).map((item) => ({
    id: item.code,
    name: item.fullName,
  }))

  return (
    <>
      <Grid container item direction="column">
        <Text strong pb={0.5} variant="subheading3">
          {t('Admin:PRACTICE.DETAILS_LABEL.CHOOSE_SUPPORTED_LANGUAGES')}
        </Text>
      </Grid>
      <ErrorTooltip
        message={supportedLanguageCodes.message}
        open={Boolean(supportedLanguageCodes.message)}
      >
        <PuiCheckboxList
          hideAllButton
          className={classes.checkboxListContainer}
          initialState={CurrentSupportedLanguageCodes}
          items={Languages}
          onChange={(values) => {
            supportedLanguageCodes.setValue(values)
          }}
        />
      </ErrorTooltip>
    </>
  )
}

export default memo(LocalizationsSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
