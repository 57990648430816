import { ImagingVendorConfig } from '~/types/entities/imagingVendorConfig'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchImagingVendorConfigs = (
  businessId: string,
  targetBusinessId: string,
) =>
  request(
    getPreludeAPIPath(`idexx-imaging/v1/settings/${targetBusinessId}/all`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.imagingVendorConfigList,
  )

export const editImagingVendorConfig = (
  businessId: string,
  targetBusinessId: string,
  vendorId: string,
  config: ImagingVendorConfig,
) =>
  request(
    getPreludeAPIPath(`idexx-imaging/v1/settings/${targetBusinessId}`),
    { method: 'PUT', params: { businessId, vendorId }, data: config },
    true,
  )
