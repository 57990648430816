import React, { memo } from 'react'
import { Stack } from '@mui/material'
import * as R from 'ramda'

import { BasePracticeDetailsSectionProps } from '~/types'

import { AutoReplySection } from './AutoReplySection'
import { useAutoReplyCommunicationsFields } from './useAutoReplyCommunicationsFields'

const AutoReplyCommunicationsComponent = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const autoReplyCommunicationsSections =
    useAutoReplyCommunicationsFields(business)

  return (
    <Stack spacing={2}>
      {autoReplyCommunicationsSections.map(({ id, section, subSections }) => (
        <AutoReplySection
          business={business}
          key={id}
          sectionId={id}
          sectionName={section}
          subSections={subSections}
        />
      ))}
    </Stack>
  )
}

export const AutoReplyCommunications = memo(
  AutoReplyCommunicationsComponent,
  (prevProps, nextProps) => R.equals(prevProps.business, nextProps.business),
)
