import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { InlineSearchHandle } from '@pbt/pbt-ui-components'

import {
  useHandleOrderRefill,
  useLogItemStateGetter,
} from '~/store/hooks/orders'
import { getClientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { ChargeQueryParams } from '~/types'

export interface PrescriptionEditPostedModalProps {
  areChargesPostedAndEditable: boolean
}

const PrescriptionEditPostedModal = ({
  areChargesPostedAndEditable,
}: PrescriptionEditPostedModalProps) => {
  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))
  const logItemStateGetter = useLogItemStateGetter()
  const searchRef = useRef<InlineSearchHandle>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const clearSearch = () => {
    if (searchRef.current?.clearSearch) {
      searchRef.current.clearSearch()
      searchParams.delete(ChargeQueryParams.CHARGE_SEARCH_QUERY)
      setSearchParams(searchParams)
    }
  }

  useHandleOrderRefill(
    client,
    logItemStateGetter,
    clearSearch,
    areChargesPostedAndEditable,
  )
  return <></>
}

export default PrescriptionEditPostedModal
