import React, { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import * as R from 'ramda'
import {
  PermissionArea,
  PuiTextArea,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeVaccineCertificateFootersFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const VaccineCertificateFootersSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const { t } = useTranslation('Businesses')

  const isAutogenerateVaccineCertificatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.AUTOMATED_VACCINE_GENERATION),
  )

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'rabiesCertificateFooter',
        label: t(
          'Businesses:VACCINE_CERTIFICATE_FOOTERS.RABIES_CERTIFICATE_FOOTER',
        ),
        initialValue: business.rabiesCertificateFooter || '',
      },
      {
        name: 'proofOfVaccinationFooter',
        label: t(
          'Businesses:VACCINE_CERTIFICATE_FOOTERS.PROOF_OF_VACCINATION_FOOTER',
        ),
        initialValue: business.proofOfVaccinationFooter || '',
      },
      {
        name: 'autoGenerateVaccineCertificates',
        type: 'toggle',
        initialValue: business.autoGenerateVaccineCertificates || false,
      },
    ],
    false,
  )
  const {
    rabiesCertificateFooter,
    proofOfVaccinationFooter,
    autoGenerateVaccineCertificates,
  } = fields

  usePracticeFieldsSection<PracticeVaccineCertificateFootersFields>({
    business,
    fields,
    reset,
    sectionName: PracticeDetailsPanels.VACCINE_CERTIFICATE_FOOTERS,
    validate,
  })

  return (
    <Grid container direction="column">
      {permissions.update && isAutogenerateVaccineCertificatesEnabled && (
        <Grid item marginBottom={1}>
          <PuiSwitch
            field={autoGenerateVaccineCertificates}
            label={
              <TextWithTooltip
                strong
                TooltipProps={{}}
                tooltipText={
                  <Grid item maxWidth={220}>
                    <Trans
                      components={{
                        linkText: (
                          <Link
                            href="https://help.rhapsody.vet/en/articles/9103145-new-feature-auto-generate-rabies-and-other-vaccine-certificate-from-patients-soap"
                            rel="help noopener"
                            target="_blank"
                            underline="always"
                          />
                        ),
                      }}
                      i18nKey={t(
                        'Businesses:VACCINE_CERTIFICATE_FOOTERS.TOOLTIP',
                      )}
                    />
                  </Grid>
                }
                variant="subheading3"
              >
                {t(
                  'Businesses:VACCINE_CERTIFICATE_FOOTERS.TURN_ON_AUTO_GENERATE_VACCINE_CERTIFICATES',
                )}
              </TextWithTooltip>
            }
          />
        </Grid>
      )}
      <Grid container item columnSpacing={3}>
        <Grid item xs>
          <PuiTextArea
            disabled={!permissions.update}
            field={rabiesCertificateFooter}
            label={rabiesCertificateFooter.label}
            labelProps={{
              tooltipText: t(
                'Businesses:VACCINE_CERTIFICATE_FOOTERS.THESE_NOTES_WILL_APPEAR_AT_BOTTOM',
              ),
            }}
          />
        </Grid>
        <Grid item xs>
          <PuiTextArea
            disabled={!permissions.update}
            field={proofOfVaccinationFooter}
            label={proofOfVaccinationFooter.label}
            labelProps={{
              TooltipProps: { tooltipPlacement: 'top-end' },
              tooltipText: t(
                'Businesses:VACCINE_CERTIFICATE_FOOTERS.THESE_NOTES_WILL_APPEAR_AT_BOTTOM',
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(VaccineCertificateFootersSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
