import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Nil, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: theme.spacing(2),
      minWidth: 150,
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'MissingNIFNumber' },
)

export interface MissingNIFNumberProps {
  clientId?: string | Nil
  handleClose: () => void
}

const MissingNIFNumber = ({ clientId, handleClose }: MissingNIFNumberProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Dialogs'])

  const [openClientDetailsEditDialog, closeClientDetailsEditDialog] = useDialog(
    DialogNames.CLIENT_DETAILS_EDIT,
  )

  const handleAddClientNIFNumber = () => {
    handleClose()
    closeClientDetailsEditDialog()
    if (clientId) {
      openClientDetailsEditDialog({ clientId })
    }
  }

  return (
    <>
      <Text align="center" variant="body">
        {t('Dialogs:INVOICE_MISSING_FIELDS_DIALOG.NIF_DESCRIPTION')}
      </Text>
      <ButtonWithLoader
        className={classes.button}
        onClick={handleAddClientNIFNumber}
      >
        {t('Dialogs:INVOICE_MISSING_FIELDS_DIALOG.ADD_NIF_NUMBER')}
      </ButtonWithLoader>
    </>
  )
}

export default MissingNIFNumber
