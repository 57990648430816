import { AtLeast, DocumentFile, Nil } from '@pbt/pbt-ui-components'

import AssetDestination from '~/constants/AssetDestination'
import { UploadAssetPayload } from '~/types'

import { getAPIPath, getPreludeAPIPath, request } from './core'

export const createAttachmentFile = (
  businessId: string,
  file: Omit<DocumentFile['file'], 'id'>,
  soapId: string | Nil,
) =>
  request(
    getAPIPath(`v2/soaps/${soapId}/files`),
    { method: 'POST', params: { businessId }, data: file },
    true,
  )

export const editAttachmentFile = (
  businessId: string,
  file: AtLeast<DocumentFile['file'], 'id'>,
  soapId: string,
) =>
  request(
    getAPIPath(`v2/soaps/${soapId}/files/${file.id}`),
    { method: 'PUT', params: { businessId }, data: file },
    true,
  )

export const deleteAttachmentFile = (businessId: string, fileId: string) =>
  request(
    getAPIPath(`v2/files/${fileId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const reserveAssetUpload = (
  businessId: string,
  destination: AssetDestination,
  payload: UploadAssetPayload,
) =>
  request(
    getPreludeAPIPath('asset/v3/upload'),
    {
      method: 'POST',
      data: { ...payload, businessId, destination },
      params: { businessId },
    },
    true,
  )

export const generateThumbnailForAsset = (
  businessId: string,
  assetUrl: string,
) =>
  request(
    getAPIPath('v1/image/thumbnail'),
    { method: 'POST', params: { businessId, assetUrl } },
    true,
  )
