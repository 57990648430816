import { all, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { LandingType, LandingWidgetName } from '~/constants/landingConstants'

import { getWidgetData, updateWidgetData } from '../duck/landing'
import {
  CHECK_STATUS,
  checkStatus,
  checkStatusFailure,
  checkStatusSuccess,
  CLOCK_IN,
  CLOCK_OUT,
  clockIn,
  clockInFailure,
  clockInSuccess,
  clockOut,
  clockOutFailure,
  clockOutSuccess,
  CREATE_TIME_ENTITY,
  createTimeEntity,
  createTimeEntityFailure,
  createTimeEntitySuccess,
  DELETE_TIME_ENTITY,
  deleteTimeEntity,
  deleteTimeEntityFailure,
  deleteTimeEntitySuccess,
  FETCH_MORE_USER_TIME_ENTITIES,
  FETCH_USER_TIME_ENTITIES,
  fetchMoreUserTimeEntities,
  fetchMoreUserTimeEntitiesFailure,
  fetchMoreUserTimeEntitiesSuccess,
  fetchUserTimeEntities,
  fetchUserTimeEntitiesFailure,
  fetchUserTimeEntitiesSuccess,
  getCurrentTimeEntity,
  UPDATE_TIME_ENTITY,
  updateTimeEntity,
  updateTimeEntityFailure,
  updateTimeEntitySuccess,
  updateTimeTrackerEntities,
} from '../duck/timeTracker'
import { getCurrentBusinessId, getCurrentUserId } from '../reducers/auth'
import requestAPI from './utils/requestAPI'

export function* checkStatusSaga({ type }: ReturnType<typeof checkStatus>) {
  try {
    const currentBusinessId: string = yield select(getCurrentBusinessId)
    if (type !== CHECK_STATUS || !currentBusinessId) {
      return
    }
    const {
      result,
      entities: { timeEntities },
    } = yield* requestAPI(API.checkTimeTrackingStatus)
    yield put(updateTimeTrackerEntities(timeEntities))
    yield put(checkStatusSuccess(result?.currentEntry))
  } catch (error) {
    yield put(checkStatusFailure(error as ApiError))
  }
}

export function* clockInSaga({
  notes,
  bypassValidation,
}: ReturnType<typeof clockIn>) {
  try {
    const {
      result,
      entities: { timeEntities },
    } = yield* requestAPI(API.clockIn, notes, bypassValidation)
    yield put(updateTimeTrackerEntities(timeEntities))
    const currentUserId: string = yield select(getCurrentUserId)
    yield put(clockInSuccess(result, currentUserId))
    const timeTrackingData: unknown[] = yield select(
      getWidgetData(
        LandingType.LANDING_DASHBOARD,
        LandingWidgetName.TIME_TRACKING,
      ),
    )
    yield put(
      updateWidgetData(
        LandingType.LANDING_DASHBOARD,
        LandingWidgetName.TIME_TRACKING,
        [result, ...timeTrackingData],
      ),
    )
  } catch (error) {
    yield put(clockInFailure(error as ApiError))
  }
}

export function* clockOutSaga({ reason, notes }: ReturnType<typeof clockOut>) {
  try {
    const { id } = yield select(getCurrentTimeEntity) || {}
    const {
      entities: { timeEntities },
    } = yield* requestAPI(API.clockOut, id, reason, notes)
    yield put(updateTimeTrackerEntities(timeEntities))
    yield put(clockOutSuccess())
  } catch (error) {
    yield put(clockOutFailure(error as ApiError))
  }
}

export function* fetchUserTimeEntitiesSaga({
  personId,
  from,
  to,
}: ReturnType<typeof fetchUserTimeEntities>) {
  try {
    const {
      result: { data: list, totalCount },
      entities: { timeEntities },
    } = yield* requestAPI(API.fetchUserTimeEntities, personId, from, to)
    yield put(updateTimeTrackerEntities(timeEntities))
    yield put(fetchUserTimeEntitiesSuccess(list, totalCount, personId))
  } catch (error) {
    yield put(fetchUserTimeEntitiesFailure(error as ApiError))
  }
}

export function* fetchMoreUserTimeEntitiesSaga({
  personId,
  from,
  to,
}: ReturnType<typeof fetchMoreUserTimeEntities>) {
  try {
    const {
      result: { data: list, totalCount },
      entities: { timeEntities },
    } = yield* requestAPI(API.fetchUserTimeEntities, personId, from, to)
    yield put(updateTimeTrackerEntities(timeEntities))
    yield put(fetchMoreUserTimeEntitiesSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreUserTimeEntitiesFailure(error as ApiError))
  }
}

export function* deleteTimeEntitySaga({
  id,
}: ReturnType<typeof deleteTimeEntity>) {
  try {
    yield* requestAPI(API.deleteTimeEntity, id)
    yield put(deleteTimeEntitySuccess(id))
  } catch (error) {
    yield put(deleteTimeEntityFailure(error as ApiError))
  }
}

export function* updateTimeEntitySaga({
  entity,
}: ReturnType<typeof updateTimeEntity>) {
  try {
    const {
      entities: { timeEntities },
    } = yield* requestAPI(API.updateTimeEntity, entity)
    yield put(updateTimeTrackerEntities(timeEntities))
    yield put(updateTimeEntitySuccess())
  } catch (error) {
    yield put(updateTimeEntityFailure(error as ApiError))
  }
}

export function* createTimeEntitySaga({
  personId,
  entity,
}: ReturnType<typeof createTimeEntity>) {
  try {
    const {
      entities: { timeEntities },
    } = yield* requestAPI(API.createTimeEntity, personId, entity)
    yield put(updateTimeTrackerEntities(timeEntities))
    yield put(fetchUserTimeEntities(personId))
    yield put(createTimeEntitySuccess())
  } catch (error) {
    yield put(createTimeEntityFailure(error as ApiError))
  }
}

function* watchCheckStatus() {
  yield takeLatest([CHECK_STATUS, CLOCK_IN, CLOCK_OUT], checkStatusSaga)
}

function* watchClockIn() {
  yield takeLatest(CLOCK_IN, clockInSaga)
}

function* watchClockOut() {
  yield takeLatest(CLOCK_OUT, clockOutSaga)
}

function* watchFetchUserTimeEntities() {
  yield takeLatest(FETCH_USER_TIME_ENTITIES, fetchUserTimeEntitiesSaga)
}

function* watchFetchMoreUserTimeEntities() {
  yield takeLatest(FETCH_MORE_USER_TIME_ENTITIES, fetchMoreUserTimeEntitiesSaga)
}

function* watchDeleteTimeEntity() {
  yield takeLatest(DELETE_TIME_ENTITY, deleteTimeEntitySaga)
}

function* watchUpdateTimeEntity() {
  yield takeLatest(UPDATE_TIME_ENTITY, updateTimeEntitySaga)
}

function* watchCreateTimeEntity() {
  yield takeLatest(CREATE_TIME_ENTITY, createTimeEntitySaga)
}

export default function* timeTrackerSaga() {
  yield all([
    watchCheckStatus(),
    watchClockIn(),
    watchClockOut(),
    watchFetchUserTimeEntities(),
    watchFetchMoreUserTimeEntities(),
    watchDeleteTimeEntity(),
    watchUpdateTimeEntity(),
    watchCreateTimeEntity(),
  ])
}
