import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { BasePrice, Procedure, TableFilter, UnsavedPrice } from '~/types'

import {
  CLEAR_SELECTED_PROCEDURE_ID,
  CREATE_PRICE,
  CREATE_PRICE_FAILURE,
  CREATE_PRICE_SUCCESS,
  DELETE_PRICE,
  DELETE_PRICE_FAILURE,
  DELETE_PRICE_SUCCESS,
  EDIT_PRICE,
  EDIT_PRICE_FAILURE,
  EDIT_PRICE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_SUCCESS,
  FETCH_PROCEDURE,
  FETCH_PROCEDURE_FAILURE,
  FETCH_PROCEDURE_SUCCESS,
  FETCH_PROCEDURES_LIST,
  FETCH_PROCEDURES_LIST_FAILURE,
  FETCH_PROCEDURES_LIST_SUCCESS,
  RESET_PROCEDURES,
  SET_FILTERS,
  SET_SELECTED_PROCEDURE_ID,
  UPDATE_PROCEDURES,
} from './types/procedures'

export const fetchProceduresList = (
  from: number,
  to: number,
  query: string | Nil,
) => ({
  type: FETCH_PROCEDURES_LIST,
  from,
  to,
  query,
})
export const fetchProceduresListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_PROCEDURES_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchProceduresListFailure = (error: ApiError) => ({
  type: FETCH_PROCEDURES_LIST_FAILURE,
  error,
})

export const fetchProcedure = (id: string) => ({ type: FETCH_PROCEDURE, id })
export const fetchProcedureFailure = (error: ApiError) => ({
  type: FETCH_PROCEDURE_FAILURE,
  error,
})
export const fetchProcedureSuccess = () => ({ type: FETCH_PROCEDURE_SUCCESS })

export const fetchMoreItemsForProceduresList = (
  from: number,
  to: number,
  query: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForProceduresListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForProceduresListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_FAILURE,
  error,
})

export const updateProcedures = (procedures: Record<string, Procedure>) => ({
  type: UPDATE_PROCEDURES,
  procedures,
})
export const resetProcedures = () => ({ type: RESET_PROCEDURES })

export const createPrice = (procedureId: string, price: UnsavedPrice) => ({
  type: CREATE_PRICE,
  procedureId,
  price,
})
export const createPriceSuccess = (procedure: Procedure) => ({
  type: CREATE_PRICE_SUCCESS,
  procedure,
})
export const createPriceFailure = (error: ApiError) => ({
  type: CREATE_PRICE_FAILURE,
  error,
})

export const editPrice = (procedureId: string, price: BasePrice) => ({
  type: EDIT_PRICE,
  procedureId,
  price,
})
export const editPriceSuccess = (procedure: Procedure) => ({
  type: EDIT_PRICE_SUCCESS,
  procedure,
})
export const editPriceFailure = (error: ApiError) => ({
  type: EDIT_PRICE_FAILURE,
  error,
})

export const deletePrice = (procedureId: string, priceId: string) => ({
  type: DELETE_PRICE,
  procedureId,
  priceId,
})
export const deletePriceSuccess = (procedure: Procedure) => ({
  type: DELETE_PRICE_SUCCESS,
  procedure,
})
export const deletePriceFailure = (error: ApiError) => ({
  type: DELETE_PRICE_FAILURE,
  error,
})

export const setSelectedProcedureId = (procedureId: string) => ({
  type: SET_SELECTED_PROCEDURE_ID,
  procedureId,
})
export const clearSelectedProcedureId = () => ({
  type: CLEAR_SELECTED_PROCEDURE_ID,
})

export const setFilters = (filters: Record<string, TableFilter>) => ({
  type: SET_FILTERS,
  filters,
})
