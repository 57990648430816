import React, { useCallback } from 'react'
import { Skeleton, SkeletonProps } from '@mui/material'
import { TextInteractive } from '@pbt/pbt-ui-components'

import TimelineCard from './TimelineCard'
import TimelineCardActions from './TimelineCardActions'
import TimelineCardContent from './TimelineCardContent'

const LoadingCard = () => {
  const IconComponent = useCallback(
    (props: SkeletonProps) => (
      <Skeleton height={24} variant="circular" width={24} {...props} />
    ),
    [],
  )

  return (
    <TimelineCard
      date={<Skeleton height={20} width={250} />}
      icon={IconComponent}
      title={<Skeleton height={15} width={100} />}
    >
      <TimelineCardContent noTypography>
        <Skeleton height={15} width={200} />
      </TimelineCardContent>
      <TimelineCardActions
        isLoading
        state={<TextInteractive isLoading variant="body2" />}
      >
        <Skeleton height={40} width={70} />
        <Skeleton height={40} width={70} />
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default LoadingCard
