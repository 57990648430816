import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'

import DifferentialDiagnoses from './diff-diagnoses/DifferentialDiagnoses'
import DifferentialDiagnosesLocked from './diff-diagnoses/DifferentialDiagnosesLocked'

const DiffDxPage = ({ editDisabled }) => {
  const DifferentialDiagnosesComponent = useLockableComponent(
    DifferentialDiagnoses,
    DifferentialDiagnosesLocked,
  )

  return <DifferentialDiagnosesComponent editDisabled={editDisabled} />
}

export default DiffDxPage
