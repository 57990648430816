import React, { forwardRef } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    presenceListContainer: {
      fontFamily: 'Rubik',
      fontWeight: 500,
    },
    hide: {
      display: 'none',
    },
  }),
  { name: 'PresenceList' },
)

export interface PresenceListProps {
  hide: boolean
}

const PresenceList = forwardRef<HTMLDivElement, PresenceListProps>(
  function PresenceList({ hide }, ref) {
    const classes = useStyles()

    return (
      <Grid
        container
        className={classNames(classes.presenceListContainer, {
          [classes.hide]: hide,
        })}
        direction="column"
        id="presence-list-container"
        mt={1}
        ref={ref}
      />
    )
  },
)

export default PresenceList
