import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  PuiDialog,
  Utils,
} from '@pbt/pbt-ui-components'

import { ImagingVendorLabel } from '~/constants/imaging'
import { useGetChargeSheetItems } from '~/store/duck/clientFinanceData'
import {
  batchCreateOrder,
  getBusinessScopeModalityList,
  getImagingIsLoading as getIsLoadingImaging,
} from '~/store/duck/imagingOrders'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import { hasImagingOrders } from '~/store/hooks/finance'
import { useGetActiveImagingVendorsMap } from '~/store/hooks/imagingVendorConfig'
import { useGetInvoiceV3Items } from '~/store/hooks/invoiceV3'
import {
  getImagingVendors,
  getProcedureCategory,
} from '~/store/reducers/constants'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { ImagingOrder, InvoiceLineItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ImagingOrderSection, {
  ImagingOrderSectionHandle,
} from './ImagingOrderSection'

const useStyles = makeStyles(
  (theme) => ({
    actionButton: {
      marginLeft: theme.spacing(1),
      minWidth: 120,
    },
    content: {},
    orderItem: {
      flex: 1,
      border: theme.constants.tableBorder,
    },
    checkboxContainer: {
      border: theme.constants.tableBorder,
      borderRightWidth: 0,
    },
    checkbox: {
      margin: 0,
    },
    paper: {
      overflow: 'visible',
    },
  }),
  { name: 'BatchImagingOrderManagementDialogV2' },
)

interface BatchImagingOrderManagementDialogV2Props extends BasePuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  clientId?: string
  hideOrderManagement?: boolean
  invoiceId?: string
}

const BatchImagingOrderManagementDialogV2 = ({
  classes: classesProp,
  hideOrderManagement,
  invoiceId,
  clientId,
  open,
  onClose,
}: BatchImagingOrderManagementDialogV2Props) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs', 'Tooltips'])

  const ImagingVendors = useSelector(getImagingVendors)
  const isLoadingImaging = useSelector(getIsLoadingImaging)
  const isRemovingOrder = useSelector(getOrdersIsSending)
  const ProcedureCategory = useSelector(getProcedureCategory)
  const activeImagingVendors = useGetActiveImagingVendorsMap()
  const idexxImagingVendorId = useGetIdexxImagingId()
  const businessScopeModalities = useSelector(
    getBusinessScopeModalityList(idexxImagingVendorId),
  )

  const itemsInvoice = useGetInvoiceV3Items(invoiceId)
  const itemsCharge = useGetChargeSheetItems()
  const unfilteredItems = invoiceId ? itemsInvoice : itemsCharge

  const filteredItems: Record<string, InvoiceLineItem[]> = Object.keys(
    unfilteredItems,
  )
    .filter((sectionId) =>
      hasImagingOrders(
        unfilteredItems[sectionId],
        ProcedureCategory,
        activeImagingVendors,
        idexxImagingVendorId,
        businessScopeModalities,
      ),
    )
    .reduce(
      (acc, id) => {
        acc[id] = unfilteredItems[id]
        return acc
      },
      {} as Record<string, InvoiceLineItem[]>,
    )

  const items = filteredItems

  const itemsRefs = Array.from({ length: R.keys(items).length }, () =>
    React.createRef<ImagingOrderSectionHandle>(),
  )

  const isLoading = isLoadingImaging || isRemovingOrder
  const vendorId = useGetIdexxImagingId()
  const vendorName = Utils.getConstantName(vendorId, ImagingVendors)
  const vendorLabel = ImagingVendorLabel[vendorName]

  const closeAfterCreate = useCloseAfterCreation(onClose, getIsLoadingImaging)

  const handleSendOrders = () => {
    if (R.all(Boolean)(itemsRefs.map((item) => item.current?.validate()))) {
      const groupedOrders = itemsRefs
        .map((item) => item.current?.get())
        .filter(Boolean) as Partial<ImagingOrder>[][]
      const orders = R.flatten(groupedOrders)
      dispatch(batchCreateOrder({ clientId, orders, vendorLabel, invoiceId }))
      closeAfterCreate()
    }
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.actionButton}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSendOrders}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
      }
      classes={{ paper: classes.paper }}
      maxWidth="xl"
      open={open}
      title={t('Dialogs:BATCH_IMAGING_ORDER_MANAGEMENT_DIALOG.TITLE', {
        vendorLabel,
      })}
      onClose={onClose}
    >
      <Grid container className={classes.content} direction="column" p={2}>
        {Object.keys(items).map((id, index) => (
          <ImagingOrderSection
            hideOrderManagement={hideOrderManagement}
            isChargeSheet={!invoiceId}
            key={id}
            ref={itemsRefs[index]}
            sectionItemId={id}
          />
        ))}
      </Grid>
    </PuiDialog>
  )
}

export default BatchImagingOrderManagementDialogV2
