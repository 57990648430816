export const UPDATE_ROLES = 'roles/UPDATE_ROLES'

export const FETCH_USED_ROLES = 'roles/FETCH_USED_ROLES'
export const FETCH_USED_ROLES_SUCCESS = 'roles/FETCH_USED_ROLES_SUCCESS'
export const FETCH_USED_ROLES_FAILURE = 'roles/FETCH_USED_ROLES_FAILURE'
export const FETCH_GROUP_ROLES = 'roles/FETCH_GROUP_ROLES'
export const FETCH_GROUP_ROLES_SUCCESS = 'roles/FETCH_GROUP_ROLES_SUCCESS'
export const FETCH_GROUP_ROLES_FAILURE = 'roles/FETCH_GROUP_ROLES_FAILURE'
export const UPDATE_GROUP_ROLES = 'roles/UPDATE_GROUP_ROLES'
export const UPDATE_GROUP_ROLES_SUCCESS = 'roles/UPDATE_GROUP_ROLES_SUCCESS'
export const UPDATE_GROUP_ROLES_FAILURE = 'roles/UPDATE_GROUP_ROLES_FAILURE'
