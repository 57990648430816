import React from 'react'

import { WhiteboardSchedule } from '~/types'

import ScheduleColumnHeader from '../../scheduler/views/schedule/ScheduleColumnHeader'
// @ts-ignore
import DragAndDropView from './DragAndDropView'
import WhiteboardHorizontalView, {
  WhiteboardHorizontalViewProps,
} from './WhiteboardHorizontalView'

interface WhiteboardTeamMemberViewProps
  extends Omit<
    WhiteboardHorizontalViewProps,
    'RowHeaderComponent' | 'RowHeaderProps' | 'getUniqueId'
  > {
  HeaderComponent: React.JSXElementConstructor<any>
}

const WhiteboardTeamMemberView = ({
  HeaderComponent,
  columns,
  openCardId,
  selectedView,
  onCardClick,
  onCardClose,
}: WhiteboardTeamMemberViewProps) => (
  <DragAndDropView
    HeaderComponent={HeaderComponent}
    columns={columns}
    openCardId={openCardId}
    onCardClose={onCardClose}
  >
    <WhiteboardHorizontalView
      RowHeaderComponent={ScheduleColumnHeader}
      RowHeaderProps={{ allowWrap: true }}
      columns={columns}
      getUniqueId={(schedule: WhiteboardSchedule) =>
        schedule.personId || 'unassigned'
      }
      openCardId={openCardId}
      selectedView={selectedView}
      onCardClick={onCardClick}
      onCardClose={onCardClose}
    />
  </DragAndDropView>
)

export default WhiteboardTeamMemberView
