import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { getCurrentEnvironmentString, isAAHA, isBenchmarking } from '~/utils'

const Favicon = () => {
  const location = useLocation()

  const env = getCurrentEnvironmentString()

  const isActivationLandingPage = location.pathname === '/auth/activation'
  const isAahaBrandingPage = isAAHA && isActivationLandingPage

  const faviconUrl =
    isAahaBrandingPage || isBenchmarking
      ? `${process.env.PUBLIC_URL}/favicons/chewy-favicon.png`
      : `${process.env.PUBLIC_URL}/favicons/${env}/favicon.png`

  return (
    <Helmet>
      <link href={faviconUrl} rel="icon" />
    </Helmet>
  )
}

export default Favicon
