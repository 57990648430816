import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { CircularProgressOverlay, Text } from '@pbt/pbt-ui-components'

import {
  editMigrationImportExceptions,
  fetchMigrationImportExceptions,
  getCurrentSessionId,
  getExceptionsDrafts,
  getExceptionsIsLoading,
  getExceptionsList,
  getIsUpdatingExceptions,
  getIsWithResolved,
  getMultipleExceptions,
  updateMigrationImportExceptions,
} from '~/store/duck/migrationV2'
import { BaseStepComponentProps } from '~/types/entities/migrationV2'
import {
  MigrationException,
  MigrationExceptionEntityToField,
} from '~/types/entities/migrationV2/migrationExceptions'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'
import { MigrationExceptionsTable } from '../core-migration/MigrationExceptionsTable'
import { MigrationExceptionTableFooter } from '../core-migration/MigrationExceptionTableFooter'
import { MigrationExceptionTableHeader } from '../core-migration/MigrationExceptionTableHeader'
import { getExceptionEntityFields } from '../core-migration/utils'

const DataStandardization = ({ stepController }: BaseStepComponentProps) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [currentEntityFieldIndex, setCurrentEntityFieldIndex] = useState(0)
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const importSessionId = useSelector(getCurrentSessionId)
  const isUpdatingExceptions = useSelector(getIsUpdatingExceptions)
  const isLoading =
    useSelector(getExceptionsIsLoading) ||
    !isInitialized ||
    isUpdatingExceptions
  const exceptionIds = useSelector(getExceptionsList)
  const exceptions = useSelector(getMultipleExceptions(exceptionIds))
  const exceptionDrafts = useSelector(getExceptionsDrafts)
  const withResolved = useSelector(getIsWithResolved)
  const exceptionEntityFields = getExceptionEntityFields(
    exceptions,
  ) as MigrationExceptionEntityToField[]

  useEffect(() => {
    if (!isInitialized && importSessionId) {
      dispatch(fetchMigrationImportExceptions({ importSessionId }))
      setIsInitialized(true)
    }
  }, [importSessionId, isInitialized])

  const onToggleWithResolved = () => {
    if (!importSessionId) {
      return
    }
    dispatch(
      fetchMigrationImportExceptions({
        importSessionId,
        withResolved: !withResolved,
      }),
    )
  }

  const onExceptionChange = (
    importExceptionId: string,
    exception: MigrationException,
  ) => {
    dispatch(editMigrationImportExceptions({ importExceptionId, exception }))
  }

  const onPrevEntityField = () => {
    if (currentEntityFieldIndex > 0) {
      setCurrentEntityFieldIndex(currentEntityFieldIndex - 1)
    }
  }

  const onNextEntityField = () => {
    if (currentEntityFieldIndex < exceptionEntityFields.length - 1) {
      setCurrentEntityFieldIndex(currentEntityFieldIndex + 1)
    }
  }

  const handleProceed = () => {
    stepController.next()
  }

  const closeAfterApplying = useCloseAfterCreation(
    handleProceed,
    getIsUpdatingExceptions,
  )

  const handleApplyExceptionsChanges = () => {
    dispatch(
      updateMigrationImportExceptions({ draftExceptions: exceptionDrafts }),
    )
    closeAfterApplying()
  }

  const currentExceptionEntityField =
    exceptionEntityFields[currentEntityFieldIndex]
  const hasUnsavedDrafts = R.pipe(R.keys, R.any(R.T))(exceptionDrafts)

  return (
    <MigrationStep width={1200}>
      <MigrationExceptionTableHeader
        header={currentExceptionEntityField?.humanReadableName}
        isLoading={isLoading}
        withResolved={withResolved}
        onToggleWithResolved={onToggleWithResolved}
      />
      <MigrationStepContent height={480}>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:MIGRATION.LOADING_EXCEPTIONS')}
          />
        ) : (
          <>
            {currentExceptionEntityField ? (
              <MigrationExceptionsTable
                exceptions={exceptions}
                migrationEntityToField={currentExceptionEntityField}
                onExceptionChange={onExceptionChange}
              />
            ) : (
              <Text m="auto" variant="h4">
                {t('Businesses:MIGRATION.NO_EXCEPTIONS_FOUND')}
              </Text>
            )}
          </>
        )}
      </MigrationStepContent>
      <MigrationExceptionTableFooter
        disableNext={R.gte(
          currentEntityFieldIndex,
          exceptionEntityFields.length - 1,
        )}
        disablePrev={R.lte(currentEntityFieldIndex, 0)}
        onNext={onNextEntityField}
        onPrev={onPrevEntityField}
      />
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:CANCEL_ACTION')}
        </MigrationStepActionButton>
        <MigrationStepActionButton
          disabled={!hasUnsavedDrafts || isLoading}
          loading={isLoading}
          onClick={handleApplyExceptionsChanges}
        >
          {t('Common:APPLY_ACTION')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default DataStandardization
