import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'

import QuestionsListWidgetLocked from './QuestionsListWidgetLocked'
import QuestionsWidgetUnlocked from './QuestionsWidgetUnlocked'

const QuestionsWidget = () => {
  const QuestionsWidgetComponent = useLockableComponent(
    QuestionsWidgetUnlocked,
    QuestionsListWidgetLocked,
  )

  return <QuestionsWidgetComponent />
}

export default QuestionsWidget
