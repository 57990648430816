import React from 'react'
import { useSelector } from 'react-redux'

import { ConversationTransport } from '~/api/graphql/generated/types'
import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { MESSAGE_TRANSPORT_NAMES } from '~/constants/communications'
import i18n from '~/locales/i18n'
import { getSmsCommunicationsEnabled } from '~/store/reducers/auth'
import { filterItemsFromEnum } from '~/utils/communicationsUtils'

const filterNamesConfig: Partial<Record<ConversationTransport, string>> = {
  [ConversationTransport.Sms]: i18n.t('Common:TEXT_MESSAGE'),
}

export interface ConversationTypeFilterProps extends CheckboxListFilterProps {}

const ConversationTypeFilter = (props: ConversationTypeFilterProps) => {
  const isSmsCommunicationsEnabled = useSelector(getSmsCommunicationsEnabled)
  const filteredTransport: ConversationTransport[] = []

  if (!isSmsCommunicationsEnabled) {
    filteredTransport.push(ConversationTransport.Sms)
  }

  const transportFilterItems = filterItemsFromEnum(
    MESSAGE_TRANSPORT_NAMES,
    filterNamesConfig,
  ).filter(({ id }) => !filteredTransport.includes(id))

  return <CheckboxListFilter items={transportFilterItems} {...props} />
}

export default ConversationTypeFilter
