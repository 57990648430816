import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Link,
} from '@mui/icons-material'
import { Divider, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { AtomicBlockUtils, EditorState, Modifier, RichUtils } from 'draft-js'
import { CHECKABLE_LIST_ITEM } from 'draft-js-checkable-list-item'
import * as R from 'ramda'
import {
  Attachment,
  CheckList,
  Delete as DeleteIcon,
  Signature as SignatureIcon,
} from '@pbt/pbt-ui-components/src/icons'

import i18n from '~/locales/i18n'
import { getPlaceholders } from '~/store/reducers/constants'

import PuiIconButton from '../../PuiIconButton'
import MenuDropdown from '../MenuDropdown'
import { PLACEHOLDER } from './decorator/placeholder'
import { BLOCK_TYPE_SIGNATURE } from './decorator/signature'
import { LinkPopper } from './LinkPopper'
import {
  deserializeLocalePlaceholder,
  getPlaceholderHtml,
  getPlaceholderString,
} from './placeholderUtils'

const inlineStyleMethod = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
  style: string,
) => setEditorState(RichUtils.toggleInlineStyle(editorState, style))

const isActiveInline = (editorState: EditorState, style: string) =>
  editorState.getCurrentInlineStyle().has(style)

const blockStyleMethod = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
  style: string,
) => setEditorState(RichUtils.toggleBlockType(editorState, style))

const isActiveBlock = (editorState: EditorState, style: string) =>
  R.equals(
    style,
    editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType(),
  )

type TButton =
  | {
      divider: boolean
    }
  | {
      icon: React.JSXElementConstructor<any>
      isActive: (editorState: EditorState, style: string) => boolean
      isDisabled?: boolean
      method?: (
        editorState: EditorState,
        setEditorState: (editorState: EditorState) => void,
        style: string,
      ) => void
      onClick?: () => void
      style: string
    }

type TFontSizeOption = {
  name: string
  value: string
}

const SIMPLE_TEXT_NAME = i18n.t('Common:INPUTS.RICH_EDIT.OPTIONS.BODY_COPY')
const SIMPLE_TEXT_STYLE = 'simple-text'

const fontSizeOptions: TFontSizeOption[] = [
  {
    name: i18n.t('Common:INPUTS.RICH_EDIT.OPTIONS.HEADER_ONE'),
    value: 'header-one',
  },
  {
    name: i18n.t('Common:INPUTS.RICH_EDIT.OPTIONS.SECTION_HEADER'),
    value: 'header-three',
  },
  {
    name: i18n.t('Common:INPUTS.RICH_EDIT.OPTIONS.SUBHEADER'),
    value: 'header-four',
  },
  {
    name: SIMPLE_TEXT_NAME,
    value: SIMPLE_TEXT_STYLE,
  },
  {
    name: i18n.t('Common:INPUTS.RICH_EDIT.OPTIONS.FOOTNOTE'),
    value: 'header-six',
  },
]

const getButtons = (
  isAttachmentEnabled: boolean,
  onAttachClick?: () => void,
): TButton[] => [
  {
    divider: true,
  },
  {
    icon: FormatBold,
    style: 'BOLD',
    isActive: isActiveInline,
    method: inlineStyleMethod,
  },
  {
    icon: FormatItalic,
    style: 'ITALIC',
    isActive: isActiveInline,
    method: inlineStyleMethod,
  },
  {
    icon: FormatUnderlined,
    style: 'UNDERLINE',
    isActive: isActiveInline,
    method: inlineStyleMethod,
  },
  {
    divider: true,
  },
  {
    icon: FormatListBulleted,
    style: 'unordered-list-item',
    isActive: isActiveBlock,
    method: blockStyleMethod,
  },
  {
    icon: FormatListNumbered,
    style: 'ordered-list-item',
    isActive: isActiveBlock,
    method: blockStyleMethod,
  },
  {
    icon: CheckList,
    style: CHECKABLE_LIST_ITEM,
    isActive: isActiveBlock,
    method: blockStyleMethod,
  },
  {
    divider: true,
  },
  {
    style: '',
    icon: Attachment,
    isActive: isActiveBlock,
    isDisabled: !isAttachmentEnabled,
    onClick: onAttachClick,
  },
  {
    divider: true,
    isDisabled: !isAttachmentEnabled,
  },
]

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    button: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderRadius: 0,
    },
    active: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    placeholdersButton: {
      color: theme.colors.link,
      maxWidth: '100%',
    },
    wrapButtonSpan: {
      display: 'block',
      textAlign: 'left',
    },
    noWrapButtonSpan: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
    divider: {
      height: 22,
    },
    disabled: {
      opacity: 0.5,
    },
    fontSelectorButton: {
      maxWidth: '40%',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
      marginRight: '0 !important',
      overflow: 'hidden',
    },
    signatureIcon: {
      color: theme.colors.secondaryText,
    },
    icon: {
      color: theme.colors.disabledLabelText,
      '&:hover': {
        color: theme.colors.secondaryText,
      },
    },
    activeIcon: {
      color: theme.colors.badgeColor,
    },
    deleteIconButton: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'RichEditPanel' },
)

export interface RichEditPanelProps {
  disabled?: boolean
  editorState: EditorState
  onAttachClick?: () => void
  onFocus: () => void
  onRemoveRequested?: () => void
  setEditorState: (editorState: EditorState) => void
  showAddLink?: boolean
  showAttachment?: boolean
  showButtons?: boolean
  showDynamicText?: boolean
  showSignature?: boolean
  wrapMenuDropdownButton?: boolean
}

const RichEditPanel = ({
  wrapMenuDropdownButton,
  editorState,
  setEditorState,
  onFocus,
  disabled,
  showButtons = true,
  showSignature = true,
  showDynamicText = true,
  showAttachment = false,
  showAddLink = false,
  onAttachClick,
  onRemoveRequested,
}: RichEditPanelProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const Placeholders = useSelector(getPlaceholders)

  const [currentFontSizeName, setCurrentFontSizeName] =
    useState(SIMPLE_TEXT_NAME)
  const [focusTimeout, setFocusTimeout] = useState<number>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const openLinkPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeLinkPopover = () => {
    setAnchorEl(null)
  }

  const isPopoverOpen = Boolean(anchorEl)

  const buttons: TButton[] = R.reject(
    R.propOr(false, 'isDisabled'),
    getButtons(showAttachment, onAttachClick),
  )

  const focusTimeoutHandler = useCallback(() => {
    setFocusTimeout(undefined)
    onFocus()
  }, [onFocus])

  const addMacros = useCallback(
    (placeholder: string, displayString?: string) => {
      const currentContent = editorState.getCurrentContent()
      const selection = editorState.getSelection()
      const contentStateWithEntity = currentContent.createEntity(
        PLACEHOLDER,
        'IMMUTABLE',
        {
          time: Date.now(),
          serializedText: getPlaceholderHtml(placeholder),
          deserializedText: deserializeLocalePlaceholder(
            Placeholders,
            placeholder,
          ),
        },
      )
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      const textWithEntity = Modifier.replaceText(
        currentContent,
        selection,
        getPlaceholderString(displayString || ''),
        undefined,
        entityKey,
      )

      setEditorState(
        EditorState.push(editorState, textWithEntity, 'insert-characters'),
      )
      setFocusTimeout(window.setTimeout(focusTimeoutHandler, 100))
    },
    [editorState, setEditorState, onFocus],
  )

  const addSignature = useCallback(() => {
    const currentContent = editorState.getCurrentContent()
    const contentStateWithEntity = currentContent.createEntity(
      BLOCK_TYPE_SIGNATURE,
      'IMMUTABLE',
      { time: Date.now() },
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    })

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
    )

    setFocusTimeout(window.setTimeout(focusTimeoutHandler, 100))
  }, [editorState, setEditorState, onFocus])

  const toggleStyle = useCallback(
    (style: string) => {
      const updateEditorState = (
        options: TFontSizeOption[],
        initialState: EditorState,
      ) =>
        options.reduce(
          (state, { value }) =>
            isActiveBlock(editorState, value)
              ? RichUtils.toggleBlockType(state, value)
              : state,
          initialState,
        )

      const newState =
        style === SIMPLE_TEXT_STYLE
          ? updateEditorState(fontSizeOptions, editorState)
          : RichUtils.toggleBlockType(editorState, style)

      setEditorState(newState)
      setFocusTimeout(window.setTimeout(focusTimeoutHandler, 100))
    },
    [editorState, setEditorState, onFocus],
  )

  useEffect(() => () => clearTimeout(focusTimeout), [focusTimeout])

  useEffect(() => {
    const selectedStyles = fontSizeOptions.filter(({ value }) =>
      isActiveBlock(editorState, value),
    )

    const styleName =
      selectedStyles.length > 0 ? selectedStyles[0].name : SIMPLE_TEXT_NAME
    setCurrentFontSizeName(styleName)
  }, [editorState])

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(classes.root, {
        [classes.disabled]: disabled,
      })}
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid container item sm alignItems="center" maxWidth="60%" wrap="nowrap">
        {showButtons && (
          <>
            <MenuDropdown
              linkButton
              classes={{
                button: classes.fontSelectorButton,
                buttonSpan: wrapMenuDropdownButton
                  ? classes.wrapButtonSpan
                  : classes.noWrapButtonSpan,
              }}
              disabled={disabled}
              items={fontSizeOptions}
              title={currentFontSizeName}
              onSelected={toggleStyle}
            />
            {buttons.map((button, id) =>
              'divider' in button ? (
                <Divider
                  className={classes.divider}
                  // eslint-disable-next-line react/no-array-index-key
                  key={id}
                  orientation="vertical"
                />
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={id}>
                  <PuiIconButton
                    Icon={button.icon}
                    className={classNames(classes.button, {
                      [classes.active]: button.isActive(
                        editorState,
                        button.style,
                      ),
                    })}
                    classes={{
                      iconButtonSvg: button.isActive(editorState, button.style)
                        ? classes.activeIcon
                        : classes.icon,
                    }}
                    disabled={disabled}
                    onClick={button.onClick}
                    onMouseDown={(event) => {
                      event.preventDefault()
                      if (button.method) {
                        button.method(editorState, setEditorState, button.style)
                      }
                    }}
                  />
                </Grid>
              ),
            )}
            {showSignature && (
              <>
                <Divider className={classes.divider} orientation="vertical" />
                <PuiIconButton
                  Icon={SignatureIcon}
                  className={classes.button}
                  classes={{
                    iconButtonSvg: classes.signatureIcon,
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault()
                    addSignature()
                  }}
                />
              </>
            )}
            {showAddLink && (
              <>
                <Divider className={classes.divider} orientation="vertical" />
                <PuiIconButton
                  Icon={Link}
                  className={classes.button}
                  classes={{
                    iconButtonSvg: classes.icon,
                  }}
                  onMouseDown={openLinkPopover}
                />
              </>
            )}
          </>
        )}
      </Grid>
      {showDynamicText && (
        <Grid item maxWidth="30%">
          <MenuDropdown
            linkButton
            classes={{
              button: classes.placeholdersButton,
              buttonSpan: wrapMenuDropdownButton
                ? classes.wrapButtonSpan
                : classes.noWrapButtonSpan,
            }}
            disabled={disabled}
            items={Placeholders}
            title={t('Common:INPUTS.RICH_EDIT.LABEL.INSERT_DYNAMIC_TEXT')}
            onSelected={addMacros}
          />
        </Grid>
      )}
      {onRemoveRequested && (
        <IconButton
          aria-label={t('Common:DELETE_ACTION')}
          className={classes.deleteIconButton}
          size="large"
          onClick={onRemoveRequested}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <LinkPopper
        anchorEl={anchorEl}
        editorState={editorState}
        open={isPopoverOpen}
        setEditorState={setEditorState}
        onClose={closeLinkPopover}
        onFocus={onFocus}
      />
    </Grid>
  )
}

export default RichEditPanel
