import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PrintHtml from '~/components/common/print/PrintHtml'
import { clearInvoicePrintHtml, printInvoice } from '~/store/actions/finance'
import { useCanPrintOrEmailOrDownloadInvoice } from '~/store/hooks/finance'
import { getFinancePrintHtml } from '~/store/reducers/finance'
import { PrintInvoiceParams } from '~/types'

export interface PrintInvoiceProps {
  invoiceIds: string[]
  onClose?: () => void
  params?: PrintInvoiceParams
}

const PrintInvoice = ({
  onClose,
  invoiceIds: invoiceIdsProp,
  params,
}: PrintInvoiceProps) => {
  const invoiceIds = invoiceIdsProp || []
  const dispatch = useDispatch()
  const printHtml = useSelector(getFinancePrintHtml)
  const {
    getCanPrintOrEmailOrDownloadInvoice: getCanPrintInvoice,
    isCompletedType,
  } = useCanPrintOrEmailOrDownloadInvoice(invoiceIds, true)

  useEffect(() => {
    if (getCanPrintInvoice() && invoiceIds.length > 0) {
      dispatch(
        printInvoice(invoiceIds, {
          ...params,
          ...(getCanPrintInvoice() ? { complete: isCompletedType } : {}),
        }),
      )
    }
  }, [invoiceIds])

  useEffect(() => {
    dispatch(clearInvoicePrintHtml())

    if (!getCanPrintInvoice() && onClose) {
      onClose()
    }
  }, [])

  if (!printHtml) {
    return null
  }

  return <PrintHtml source={printHtml} onOk={onClose} />
}

export default PrintInvoice
