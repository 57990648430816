import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import LabVendorName from '~/constants/labVendorName'
import { LabVendorConfig } from '~/types'
import { asFlags } from '~/utils'

import {
  fetchLabVendorConfigs,
  getLabConfigsListForBusiness,
  getLabVendorConfigIsReceiving,
  getMultipleLabConfigs,
} from '../duck/labVendorConfig'
import { getCurrentBusinessId } from '../reducers/auth'
import { getLabVendors } from '../reducers/constants'

export const useGetLabVendorConfigs = () => {
  const dispatch = useDispatch()
  const currentBusinessId = useSelector(getCurrentBusinessId) as string
  const list = useSelector(getLabConfigsListForBusiness(currentBusinessId))
  const configs = useSelector(getMultipleLabConfigs(list, currentBusinessId))
  const isReceiving = useSelector(getLabVendorConfigIsReceiving)

  useEffect(() => {
    if (configs.length === 0 && currentBusinessId && !isReceiving) {
      dispatch(fetchLabVendorConfigs(currentBusinessId))
    }
  }, [currentBusinessId])

  return configs
}

export const useGetActiveLabVendorsMap = () => {
  const configs = useGetLabVendorConfigs()

  const activeConfigs = R.filter(R.propEq('active', true), configs)
  const vendorIds = R.pluck('vendorId', activeConfigs)

  return asFlags(vendorIds)
}

export const useGetLabVendorConfig = (
  name: LabVendorName,
): LabVendorConfig | undefined => {
  const LabVendors = useSelector(getLabVendors)

  const activeConfigs = useGetLabVendorConfigs()

  const labId = Utils.findConstantIdByName(name, LabVendors)

  return Utils.findByProp(labId, activeConfigs, 'vendorId')
}
