import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as R from 'ramda'

import { ChargeQueryParams, ChargeTab } from '~/types'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

export const useChargeTabParams = (tabs: ChargeTab[]) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const queryParamIndex = tabs.findIndex(
    (item) => item.id === searchParams.get(ChargeQueryParams.CHARGE_TAB),
  )

  const [tabValue, setTabValue] = useState(
    queryParamIndex >= 0 ? queryParamIndex : 0,
  )

  useEffectExceptOnMount(() => {
    const currentTab = tabs[tabValue]

    if (currentTab.hide) {
      const firstNotHiddenIndex = tabs.findIndex((tab) => !tab.hide)
      searchParams.set(
        ChargeQueryParams.CHARGE_TAB,
        tabs[firstNotHiddenIndex].id,
      )
      setTabValue(firstNotHiddenIndex)
      setSearchParams(searchParams)
      return
    }

    if (currentTab.id !== searchParams.get(ChargeQueryParams.CHARGE_TAB)) {
      searchParams.set(ChargeQueryParams.CHARGE_TAB, currentTab.id)
      setSearchParams(searchParams)
    }
  }, [...tabs.map((tab) => tab.hide)])

  const handleSearchParamsChange = (id: string | null) => {
    if (id !== 'search') {
      searchParams.delete(ChargeQueryParams.CHARGE_SEARCH_QUERY)
    }

    if (R.isNil(id)) {
      searchParams.delete(ChargeQueryParams.CHARGE_TAB)
      setSearchParams(searchParams)
      return
    }

    searchParams.set(ChargeQueryParams.CHARGE_TAB, id)
    setSearchParams(searchParams)
  }

  const onTabChange = (value: number) => {
    const currentTabId = tabs[value]?.id
    setTabValue(value)
    handleSearchParamsChange(currentTabId)
  }

  return {
    onTabChange,
    tabValue,
  }
}
