import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    panel: {
      width: '100%',
    },
    panelExpanded: {
      border: theme.constants.activeFieldBorder,
    },
    panelSummary: {
      padding: theme.spacing(0, 2),
      color: theme.colors.primaryText,
      '&.Mui-expanded': {
        minHeight: 44,
      },
    },
    panelSummaryExpanded: {
      color: theme.colors.title,
      '&.Mui-expanded': {
        margin: 'auto 0',
      },
    },
    panelDetails: {
      padding: theme.spacing(0, 2, 2, 2),
      flexDirection: 'column',
    },
    panelDetailsNoPadding: {
      padding: 0,
    },
    heading: {
      fontSize: '1.6rem',
      fontWeight: 500,
      color: theme.colors.primaryText,
    },
    headingExpanded: {
      color: theme.colors.title,
    },
  }),
  { name: 'PanelAccordion' },
)

export interface PanelAccordionProps extends AccordionProps {
  noPadding?: boolean
  titleComponent?: React.ReactElement
}

const PanelAccordion = ({
  expanded,
  title,
  titleComponent,
  children,
  noPadding,
  ...rest
}: PanelAccordionProps) => {
  const classes = useStyles()

  const renderTitleComponent = titleComponent ? (
    React.cloneElement(titleComponent, {
      className: classNames(titleComponent.props.className, classes.heading, {
        [classes.headingExpanded]: expanded,
      }),
    })
  ) : (
    <Text strong variant={expanded ? 'h4' : 'subheading2'}>
      {title}
    </Text>
  )

  return (
    <Accordion
      classes={{
        root: classes.panel,
        expanded: classes.panelExpanded,
      }}
      expanded={expanded}
      {...rest}
    >
      <AccordionSummary
        classes={{
          root: classes.panelSummary,
          expanded: classes.panelSummaryExpanded,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        {renderTitleComponent}
      </AccordionSummary>
      <AccordionDetails
        className={classNames(classes.panelDetails, {
          [classes.panelDetailsNoPadding]: noPadding,
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default PanelAccordion
