import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import {
  checkMigrationSettings,
  startMigrationAnalyze,
} from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getIsStartingImport,
} from '~/store/reducers/migration'

import EntityTypesSelectList from './EntityTypesSelectList'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      padding: theme.spacing(0, 3),
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'StepImportReady' },
)

interface StepImportReadyProps {
  isEnterprise: boolean
}

const StepImportReady = ({ isEnterprise }: StepImportReadyProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const [selectedEntities, setSelectedEntities] = useState<string[]>([])

  const isStartingImport = useSelector(getIsStartingImport)
  const sessionId = useSelector(getCurrentSessionId)

  const isAnyEntitySelected = Boolean(selectedEntities.length)

  const handleRepeat = useCallback(() => {
    dispatch(startMigrationAnalyze(sessionId, isEnterprise, selectedEntities))
  }, [sessionId, selectedEntities])

  const handleProceed = useCallback(() => {
    dispatch(checkMigrationSettings(sessionId, selectedEntities))
  }, [sessionId, selectedEntities])

  return (
    <Grid container>
      <EntityTypesSelectList onSelectedEntitiesChanged={setSelectedEntities} />
      <Grid container justifyContent="flex-start" px={2} py={1}>
        <ButtonWithLoader
          className={classes.button}
          color="secondary"
          disabled={isStartingImport || !isAnyEntitySelected}
          onClick={handleRepeat}
        >
          {t('Businesses:MIGRATION.RE_ANALYZE')}
        </ButtonWithLoader>
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          disabled={isStartingImport || !isAnyEntitySelected}
          onClick={handleProceed}
        >
          {t('Businesses:MIGRATION.PROCEED_WITH_IMPORT')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default StepImportReady
