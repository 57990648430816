import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MarketingStylesButton, Text } from '@pbt/pbt-ui-components'

import SuccessSvg from './success.svg'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    placeholder: {
      height: parseInt(theme.spacing(3), 10) - 1,
    },
    submit: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    successIcon: {
      width: 39,
      height: 44,
      marginTop: parseInt(theme.spacing(2), 10) - 3,
    },
  }),
  { name: 'EmailPasswordSuccess' },
)

const EmailPasswordSuccess = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation(['Auth', 'Common'])

  return (
    <Grid container className={classes.root} direction="column" mt={2}>
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        pb={4}
        pt={3}
        px={3}
      >
        <Text variant="hero2">{t('Common:SUCCESS')}!</Text>
        <img
          alt={t('Common:SUCCESS')}
          className={classes.successIcon}
          src={SuccessSvg}
        />
        <Text align="center" mb={1} mt={2} sx={{ width: '80%' }}>
          {t('Auth:EMAIL_PASSWORD_SUCCESS.INSTRUCTIONS')}
        </Text>
      </Grid>
      <div className={classes.placeholder} />
      <MarketingStylesButton
        className={classes.submit}
        type="button"
        onClick={() => navigate('/auth/login')}
      >
        {t('Auth:EMAIL_PASSWORD_SUCCESS.CONTINUE_TO_LOG_IN')}
      </MarketingStylesButton>
    </Grid>
  )
}

export default EmailPasswordSuccess
