import React from 'react'

import { FeatureElements } from '~/types'

import MedicalDischargeNotesWidgetUnlocked, {
  MedicalDischargeNotesWidgetUnlockedProps,
} from '../../soapV2/medical-discharge-notes/MedicalDischargeNotesWidgetUnlocked'

const NotesTab = (props: MedicalDischargeNotesWidgetUnlockedProps) => (
  <MedicalDischargeNotesWidgetUnlocked
    ContainerProps={{ mt: -2, pt: 0 }}
    container={false}
    hideElements={[FeatureElements.COMMENTS]}
    {...props}
  />
)

export default NotesTab
