import { v4 as uuid } from 'uuid'
import { Field } from '@pbt/pbt-ui-components'

export type PersonBusinessRole = {
  businessId: string
  jobId: Field | string
}

export type PersonDto = {
  avatar: string
  businessRoles: PersonBusinessRole[]
  country: string
  dea: string
  email: string
  firstName: string
  id: string
  jobId?: string
  lastName: string
  mobilePhone?: string
  password: string
  phone?: string
  pims: string
}

/**
 *  Deprecated
 */
export const createPerson = ({
  avatar = '',
  businessRoles = [],
  country = '',
  dea = '',
  email = '',
  firstName = '',
  password = '',
  phone = '',
  pims = '',
  lastName = '',
} = {}): PersonDto => ({
  id: uuid(),
  avatar,
  businessRoles,
  country,
  dea,
  email,
  firstName,
  password,
  phone,
  pims,
  lastName,
})

export function isNoJob(person: PersonDto) {
  return (
    !person.businessRoles.length ||
    person.businessRoles.reduce((acc, { jobId }) => acc && !jobId, true)
  )
}

export function isEmptyMember(person: PersonDto) {
  return !person.email && isNoJob(person)
}
