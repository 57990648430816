import { EasterEggQualifyingEvent } from '~/types'

import {
  CLEAR_EASTER_EGG_EVENT_QUEUE,
  QUEUE_EASTER_EGG_EVENT,
  UPDATE_EASTER_EGG_EVENT_QUEUE,
} from './types/easterEgg'

export const queueEasterEggEvent = (event: EasterEggQualifyingEvent) => ({
  type: QUEUE_EASTER_EGG_EVENT,
  event,
})
export const clearEasterEggEventQueue = () => ({
  type: CLEAR_EASTER_EGG_EVENT_QUEUE,
})
export const updateEasterEggEventQueue = (
  easterEggEventQueue: EasterEggQualifyingEvent[],
) => ({
  type: UPDATE_EASTER_EGG_EVENT_QUEUE,
  easterEggEventQueue,
})
