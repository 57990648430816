import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Nil, Text } from '@pbt/pbt-ui-components'

import InfoList from '~/components/common/InfoList'
import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import Expander from '~/components/common/lists/Expander'
import {
  fetchVariation as fetchOnHandVariation,
  getIsLoading,
  getVariation as getOnHandVariation,
} from '~/store/duck/onHandCatalog'
import {
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

// @ts-ignore
import { getOnHandAmount } from '../inventoryUtils'
import OnHandHistoryTable from './OnHandHistoryTable'
import OnHandStatusLabel from './OnHandStatusLabel'

export interface OnHandDetailsProps {
  itemId: string | Nil
  onClose: () => void
}

const OnHandDetails = ({ itemId, onClose }: OnHandDetailsProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onHandVariation = useSelector(getOnHandVariation(itemId))
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PackageType = useSelector(getPackageType)
  const isFetching = useSelector(getIsLoading)
  const { t } = useTranslation('Common')

  const getInStockAmount = useGetInStockAmount()

  const {
    inventoryItemId,
    name,
    lastDistributor,
    onHandStatusId,
    reorderQuantity,
    reorderQuantityUnitId,
    reorderPoint,
    reorderPointUnitId,
    perPackageUnitsId,
    packageTypeId,
  } = onHandVariation || {}

  useEffect(() => {
    if (itemId) {
      dispatch(fetchOnHandVariation(itemId))
    }
  }, [itemId])

  const backToDashboard = () => onClose()

  const navigateToInventoryCatalog = () => {
    if (inventoryItemId) {
      navigate(`/admin/catalog/inventories/catalog/${inventoryItemId}`)
    }
  }

  return (
    <Expander
      expandedItemClass="on-hand-inventory"
      isFetching={isFetching}
      onBack={backToDashboard}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Text variant="h2">{name}</Text>
          <Text
            link
            strong
            disabled={!inventoryItemId}
            fontSize="1.4rem"
            onClick={navigateToInventoryCatalog}
          >
            {t('Common:GO_TO_CATALOG')}
          </Text>
        </Grid>
        <Grid item>
          <InfoList
            items={[
              {
                name: t('Common:ON-HAND_STATUS'),
                component: <OnHandStatusLabel statusId={onHandStatusId} />,
              },
              {
                name: t('Common:ON-HAND'),
                value: getOnHandAmount(
                  InventoryProductSizeUnit,
                  PackageType,
                )(onHandVariation),
              },
              {
                name: t('Common:INVENTORY_RE-ORDER_POINT'),
                value: getInStockAmount({
                  amount: reorderPoint,
                  amountUnitsId: reorderPointUnitId,
                  perPackageUnitsId,
                  packageTypeId,
                }),
              },
              {
                name: t('Common:INVENTORY_RE-ORDER_QUANTITY'),
                value: getInStockAmount({
                  amount: reorderQuantity,
                  amountUnitsId: reorderQuantityUnitId,
                  perPackageUnitsId,
                  packageTypeId,
                }),
              },
              {
                name: t('Common:LAST_DISTRIBUTOR'),
                value: lastDistributor ?? t('Common:NONE'),
              },
            ]}
          />
        </Grid>
        <Grid item>
          <ItemHistoryLabel item={onHandVariation} />
        </Grid>
        <Grid item>
          <Text strong mb={0.5} variant="subheading3">
            {t('Common:HISTORY_PAST_EVENTS')}
          </Text>
          <OnHandHistoryTable variationId={itemId} />
        </Grid>
      </Grid>
    </Expander>
  )
}

export default OnHandDetails
