import { RhapsodyPayConfig } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchRhapsodyPayConfig = (
  currentBusinessId: string,
  businessId: string,
  extended = false,
) =>
  request(
    getAPIPath(`finance/pay-preferences/${businessId}`),
    { method: 'GET', params: { businessId: currentBusinessId, extended } },
    true,
    Schema.rhapsodyPayConfig,
  )

export const getPayPassAppUrl = (
  businessId: string,
  goOption: string,
  passOption: string,
) =>
  request(
    getAPIPath('finance/pay-preferences/signUpUrl'),
    { method: 'GET', params: { businessId, goOption, passOption } },
    true,
  )

export const updateRhapsodyPayConfig = (
  businessId: string,
  config: RhapsodyPayConfig,
) =>
  request(
    getAPIPath('finance/pay-preferences'),
    { method: 'POST', data: config, params: { businessId } },
    true,
    Schema.rhapsodyPayConfig,
  )

export const updateRhapsodyPayConfigManually = (
  businessId: string,
  config: RhapsodyPayConfig,
) =>
  request(
    getAPIPath(`finance/pay-preferences/${businessId}`),
    { method: 'PUT', data: config, params: { businessId } },
    true,
    Schema.rhapsodyPayConfig,
  )
