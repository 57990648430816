import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchImagingDashboardPage = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters = {},
  query: string | Nil = '',
) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/dashboard/search'),
    {
      method: 'POST',
      params: { businessId },
      data: {
        data: {
          offset: from,
          // note, limit does not include specified value into resulting range
          limit: to - from + 1,
          query,
          ...filters,
        },
      },
    },
    true,
    Schema.imagingDashboardRecordList,
  )

export const fetchImagingDashboardRecords = (
  businessId: string,
  ids: string[],
) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/dashboard/list'),
    { method: 'POST', params: { businessId }, data: ids },
    true,
    Schema.imagingDashboardRecordArray,
  )

export const fetchImagingDashboardDetails = (
  businessId: string,
  itemId: string,
) =>
  request(
    getPreludeAPIPath(`idexx-imaging/v1/dashboard/${itemId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.imagingDashboardRecord,
  )

export const assignOrderResults = (
  businessId: string,
  orderId: string,
  vendorId: string | Nil,
  appointmentId: string | Nil,
  vetId: string,
  clientId?: string,
  patientId?: string,
) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/dashboard/assign'),
    {
      method: 'POST',
      params: { businessId },
      data: {
        orderId,
        vendorId,
        appointmentId,
        vetId,
        clientId,
        patientId,
      },
    },
    true,
    Schema.imagingDashboardRecord,
  )
