export const FETCH_LAB_TESTS_LIST_FAILURE =
  'labTests/FETCH_LAB_TESTS_LIST_FAILURE'
export const FETCH_LAB_TESTS_LIST_SUCCESS =
  'labTests/FETCH_LAB_TESTS_LIST_SUCCESS'
export const FETCH_LAB_TESTS_LIST = 'labTests/FETCH_LAB_TESTS_LIST'

export const FETCH_LAB_TEST = 'labTests/FETCH_LAB_TEST'
export const FETCH_LAB_TEST_FAILURE = 'labTests/FETCH_LAB_TEST_FAILURE'
export const FETCH_LAB_TEST_SUCCESS = 'labTests/FETCH_LAB_TEST_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_FAILURE =
  'labTests/FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_SUCCESS =
  'labTests/FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST =
  'labTests/FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST'

export const UPDATE_LAB_TESTS = 'labTests/UPDATE_LAB_TESTS'
export const RESET_LAB_TESTS = 'labTests/RESET_LAB_TESTS'

export const CREATE_LAB_TEST_PRICE = 'labTests/CREATE_LAB_TEST_PRICE'
export const CREATE_LAB_TEST_PRICE_SUCCESS =
  'labTests/CREATE_LAB_TEST_PRICE_SUCCESS'
export const CREATE_LAB_TEST_PRICE_FAILURE =
  'labTests/CREATE_LAB_TEST_PRICE_FAILURE'

export const EDIT_LAB_TEST_PRICE = 'labTests/EDIT_LAB_TEST_PRICE'
export const EDIT_MULTIPLE_LAB_TEST_PRICES =
  'labTests/EDIT_MULTIPLE_LAB_TEST_PRICES'
export const EDIT_LAB_TEST_PRICE_SUCCESS =
  'labTests/EDIT_LAB_TEST_PRICE_SUCCESS'
export const EDIT_LAB_TEST_PRICE_FAILURE =
  'labTests/EDIT_LAB_TEST_PRICE_FAILURE'

export const DELETE_LAB_TEST_PRICE = 'labTests/DELETE_LAB_TEST_PRICE'
export const DELETE_LAB_TEST_PRICE_SUCCESS =
  'labTests/DELETE_LAB_TEST_PRICE_SUCCESS'
export const DELETE_LAB_TEST_PRICE_FAILURE =
  'labTests/DELETE_LAB_TEST_PRICE_FAILURE'

export const UPDATE_LAB_TEST_FILTERS = 'labTests/UPDATE_LAB_TEST_FILTERS'

export const UPDATE_SEARCH_TERM = 'labTests/UPDATE_SEARCH_TERM'
