import { normalize } from 'normalizr'

import { populateProblemNodesVisibility } from '../utils/problems'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  ADD_NEW_PROBLEM_BODY_SYSTEM_LOG,
  ADD_NEW_PROBLEM_LOG,
  ADD_PROBLEM_LOG_ENUM_VALUE,
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
  DELETE_PROBLEM_BODY_SYSTEM_LOG,
  DELETE_PROBLEM_LOG_ENUM_VALUE,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG,
  UPDATE_PROBLEM_LOG,
  UPDATE_PROBLEM_LOG_ENUM_VALUE,
} from './graphql/mutations'
import {
  DISABLE_TOAST_SHOWING,
  FETCH_LOGS,
  FETCH_PROBLEM_CATALOG_SCALAR,
  FETCH_PROBLEM_LOG_HISTORY,
  SEARCH_PROBLEMS,
} from './graphql/queries'
import * as Schema from './schemas'

export const fetchProblemCatalog = async (...rest: [string, string]) => {
  const { catalog } = await requestQuery({
    query: FETCH_PROBLEM_CATALOG_SCALAR,
    variablesHandler: (businessId: string, patientId: string) => ({
      patientId,
    }),
  })(...rest)

  const preparedCatalog = {
    ...catalog,
    problems: populateProblemNodesVisibility(catalog.problems),
  }

  return normalize(preparedCatalog, Schema.problemsData)
}

export const fetchLogs = requestQuery({
  query: FETCH_LOGS,
  variablesHandler: (_, soapId) => ({
    soapId,
  }),
  schema: Schema.problemsLog,
})

export const createProblemBodySystemLog = requestMutation({
  mutation: ADD_NEW_PROBLEM_BODY_SYSTEM_LOG,
  variablesHandler: (_, soapId, problemBodySystemLogRequest) => ({
    soapId,
    problemBodySystemLogRequest,
  }),
  schema: Schema.problemsLog,
})

export const updateProblemBodySystemLog = requestMutation({
  mutation: UPDATE_PROBLEM_BODY_SYSTEM_LOG,
  variablesHandler: (_, soapId, logId, problemBodySystemLogRequest) => ({
    soapId,
    logId,
    problemBodySystemLogRequest,
  }),
  schema: Schema.problemsLog,
})

export const deleteProblemBodySystemLog = requestMutation({
  mutation: DELETE_PROBLEM_BODY_SYSTEM_LOG,
  variablesHandler: (_, soapId, logId) => ({
    soapId,
    logId,
  }),
  schema: Schema.problemsLog,
})

export const createProblemLog = requestMutation({
  mutation: ADD_NEW_PROBLEM_LOG,
  variablesHandler: (_, soapId, bodySystemLogId, problemLogRequest) => ({
    soapId,
    bodySystemLogId,
    problemLogRequest,
  }),
  schema: Schema.problemsLog,
})

export const updateProblemLog = requestMutation({
  mutation: UPDATE_PROBLEM_LOG,
  variablesHandler: (_, soapId, logId, problemLogRequest) => ({
    soapId,
    logId,
    problemLogRequest,
  }),
  schema: Schema.problemsLog,
})

export const searchProblems = requestQuery({
  query: SEARCH_PROBLEMS,
  variablesHandler: (_, query, locale, patientId, from, to) => ({
    query,
    locale,
    patientId,
    offset: from,
    limit: to,
  }),
  schema: Schema.searchProblemsList,
})

export const fetchProblemLogHistory = requestQuery({
  query: FETCH_PROBLEM_LOG_HISTORY,
  variablesHandler: (_, problemId, patientId, soapId, offset, limit) => ({
    problemId,
    patientId,
    soapId,
    offset,
    limit,
  }),
  schema: Schema.problemLogHistoryList,
})

export const disableToastShowing = requestQuery({
  query: DISABLE_TOAST_SHOWING,
  variablesHandler: (_, soapId) => ({
    soapId,
  }),
})

export const changeStateForAllBodySystems = requestMutation({
  mutation: CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
  variablesHandler: (_, soapId, bodySystemLogBatchRequest) => ({
    soapId,
    bodySystemLogBatchRequest,
  }),
  schema: Schema.problemsLog,
})

export const createProblemLogEnumValue = requestMutation({
  mutation: ADD_PROBLEM_LOG_ENUM_VALUE,
  variablesHandler: (_, problemLogId, problemEnumLogRequest) => ({
    problemLogId,
    problemEnumLogRequest,
  }),
})

export const updateProblemLogEnumValue = requestMutation({
  mutation: UPDATE_PROBLEM_LOG_ENUM_VALUE,
  variablesHandler: (
    _,
    problemLogId,
    problemEnumLogId,
    problemEnumLogRequest,
  ) => ({
    problemLogId,
    problemEnumLogId,
    problemEnumLogRequest,
  }),
})

export const deleteProblemLogEnumValue = requestMutation({
  mutation: DELETE_PROBLEM_LOG_ENUM_VALUE,
  variablesHandler: (_, problemLogId, problemEnumLogId, crc) => ({
    problemLogId,
    problemEnumLogId,
    crc,
  }),
})
