import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

import { Patient, PaymentLink } from '~/api/graphql/generated/types'
import Link from '~/components/common/link/Link'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      textDecorationColor: theme.colors.secondaryText,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'PaymentTablePatientNameCell' },
)

interface PaymentTablePatientNameCellProps {
  links: PaymentLink[]
}

export const PaymentTablePatientNameCell = ({
  links,
}: PaymentTablePatientNameCellProps) => {
  const classes = useStyles()

  if (!links) {
    return null
  }

  const invoices = links.flatMap((link) => link.invoice)

  const patients = invoices
    .flatMap((invoice) => {
      if ('patients' in invoice) {
        return invoice.patients
      }
      if ('patient' in invoice) {
        return invoice.patient as Patient
      }
      return null
    })
    .filter((patient) => patient != null)

  return (
    <>
      {patients.map((patient, index) => (
        <Box
          alignItems="center"
          display="flex"
          height={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
          // eslint-disable-next-line react/no-array-index-key
          key={`${patient?.id}_${index}`}
          width="100%"
        >
          <Link
            className={classes.link}
            id="credit-adjustment-patient-link"
            target="_self"
            to={`/patient/${patient?.id}`}
          >
            <Text variant="body2">{patient?.name}</Text>
          </Link>
        </Box>
      ))}
    </>
  )
}
