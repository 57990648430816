import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import DialogNames from '~/constants/DialogNames'
import { removeOrderUpdatePatient } from '~/store/actions/orders'
import { useIsIntegratedModality } from '~/store/hooks/imagingOrders'
import { useUpdateOrderNotes } from '~/store/hooks/orders'
import { getCurrentBusinessIsIdexxImagingEnabled } from '~/store/reducers/auth'
import { getClientId, getPatientId, getSoapId } from '~/store/reducers/soap'
import { Order } from '~/types'
import useDialog from '~/utils/useDialog'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'
import { useHandleDelete } from '../../utils/useHandleDelete'

const PROCEDURE_CATEGORY = {
  VACCINATION: 'Vaccinations',
  IMAGING: 'Imaging',
}

export interface ProcedureActionsProps extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
}

const ProcedureActions = ({
  onDelete: onDeleteProp,
  order,
  ...rest
}: ProcedureActionsProps) => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)

  const [openVaccineDetailsDialog] = useDialog(DialogNames.VACCINE_DETAILS)
  const [openImagingOrderDialog] = useDialog(DialogNames.IMAGING_ORDER)

  const handleUpdateNotes = useUpdateOrderNotes(order)
  const isIntegratedModality = useIsIntegratedModality(
    order?.procedure?.modalityId,
  )
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )

  const showImagingEditButton = isImagingIntegrated && isIntegratedModality

  const { procedure: { category } = {} } = order

  const editVaccination = () => {
    openVaccineDetailsDialog({
      clientId,
      order,
      patientId,
    })
  }

  const editImaging = () => {
    openImagingOrderDialog({
      soapLog: order,
      orderId: order?.imagingOrderId,
      soapId,
    })
  }

  const handlersMap = {
    [PROCEDURE_CATEGORY.VACCINATION]: editVaccination,
    ...(showImagingEditButton
      ? {
          [PROCEDURE_CATEGORY.IMAGING]: editImaging,
        }
      : {}),
  }

  const handleEdit = category ? handlersMap[category] : undefined

  const handleDelete = useHandleDelete(
    order,
    () => dispatch(removeOrderUpdatePatient(order)),
    onDeleteProp,
  )

  return (
    <ItemWithBadgesActions
      hasNotes={Boolean(order.notes)}
      tooltip={<OrderTooltip order={order} />}
      onDelete={handleDelete}
      onEdit={handleEdit}
      onUpdateNotes={showImagingEditButton ? undefined : handleUpdateNotes}
      {...rest}
    />
  )
}

export default ProcedureActions
