import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import { PointsType } from '~/constants/wellnessPlansConstants'

const useStyles = makeStyles(
  (theme) => ({
    redeemText: {
      color: theme.colors.success,
    },
  }),
  { name: 'PointsCell' },
)

const PointsCell = (item: { amount: number; type: PointsType }) => {
  const classes = useStyles()

  const isRedeem = item.type === PointsType.REDEEM

  return (
    <Text
      className={classNames({
        [classes.redeemText]: isRedeem,
      })}
      variant="body2"
    >
      {isRedeem ? `(${item.amount})` : item.amount}
    </Text>
  )
}

export default PointsCell
