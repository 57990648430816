import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'

import ActionsButton from '~/components/common/buttons/ActionsButton'
import DialogNames from '~/constants/DialogNames'
import { useCreateShipmentFromOnHand } from '~/store/hooks/onHandCatalog'
import { Variation } from '~/types'
import useDialog from '~/utils/useDialog'

const OnHandActionsCell = (item: Variation) => {
  const navigate = useNavigate()
  const { t } = useTranslation('Common')

  const [openAdjustmentDialog] = useDialog(DialogNames.ADJUSTMENT_DIALOG)
  const openShipmentDialog = useCreateShipmentFromOnHand(
    item.id ? [item.id] : [],
  )

  const buildId = (sentence: string) => `${item.id}-${sentence}`

  return (
    <Grid container justifyContent="flex-end">
      <ActionsButton
        actions={[
          {
            id: buildId('add-adjustment'),
            label: t('Common:ADD_ADJUSTMENT'),
            onClick: () => {
              openAdjustmentDialog({
                adjustment: { variation: item },
              })
            },
          },
          {
            id: buildId('add-shipment'),
            label: t('Common:ADD_SHIPMENT'),
            onClick: openShipmentDialog,
          },
          {
            id: buildId('go-to-catalog'),
            label: t('Common:GO_TO_CATALOG'),
            onClick: () => {
              navigate(
                `/admin/catalog/inventories/catalog/${item.inventoryItemId}`,
              )
            },
          },
        ]}
        placement="bottom-end"
      />
    </Grid>
  )
}

export default OnHandActionsCell
