import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  MigrationEntity,
  MigrationImportRequest,
  MigrationImportTask,
  MigrationPreprocessingStatus,
  MigrationSessionDetails,
  MigrationSessionFile,
  MigrationSessionSettings,
  MigrationSessionStage,
  MigrationSupportedEntity,
  MigrationTaskType,
  NofixLog,
  RhapsodyExporterTypes,
  SessionsMap,
} from '~/types/entities/migrationV2'
import {
  MigrationException,
  MigrationExceptionMap,
} from '~/types/entities/migrationV2/migrationExceptions'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

type MigrationState = {
  activationToken: string
  error?: string | null
  exceptions: {
    drafts: MigrationExceptionMap
    isLoading: boolean
    isUpdating: boolean
    list: string[]
    map: MigrationExceptionMap
    withResolved: boolean
  }
  exporterUrl: string
  importRequests: {
    currentImportRequestId: string | null
    isLoading: boolean
    list: string[]
    map: Record<string, MigrationImportRequest>
    totalCount: number
  }
  importTasks: {
    isLoading: boolean
    list: string[]
    map: Record<string, MigrationImportTask>
    totalCount: number
  }
  isCreatingNewImportTask: boolean
  isLoading: boolean
  isLoadingExporterUrl: boolean
  isLoadingImportSessionDetails: boolean
  isLoadingRelationsMappings: boolean
  isLoadingStartImport: boolean
  isLoadingStatus: boolean
  isLoadingSupportedEntities: boolean
  nofixLogs: NofixLog[]
  preprocessingStatus: MigrationPreprocessingStatus
  progressEntities: MigrationEntity[]
  relationsMappings: object[] | null
  sessionFiles: MigrationSessionFile[]
  sessions: {
    currentSessionId: string | null
    isLoading: boolean
    list: string[]
    map: SessionsMap
  }
  settings: MigrationSessionSettings | null
  supportedEntities: MigrationSupportedEntity[]
}

const INITIAL_STATE: MigrationState = {
  sessions: {
    map: {},
    list: [],
    currentSessionId: null,
    isLoading: false,
  },
  importRequests: {
    currentImportRequestId: null,
    isLoading: false,
    list: [],
    map: {},
    totalCount: 0,
  },
  importTasks: {
    isLoading: false,
    list: [],
    map: {},
    totalCount: 0,
  },
  exceptions: {
    drafts: {} as MigrationExceptionMap,
    map: {} as MigrationExceptionMap,
    list: [],
    isUpdating: false,
    isLoading: false,
    withResolved: false,
  },
  isCreatingNewImportTask: false,
  isLoadingImportSessionDetails: false,
  isLoadingStartImport: false,
  isLoadingRelationsMappings: false,
  isLoadingSupportedEntities: false,
  progressEntities: [],
  exporterUrl: '',
  nofixLogs: [],
  activationToken: '',
  sessionFiles: [],
  isLoading: false,
  preprocessingStatus: {},
  isLoadingStatus: false,
  settings: null,
  supportedEntities: [],
  relationsMappings: null,
  isLoadingExporterUrl: false,
}

export const migrationSlice = createSlice({
  name: 'migrationV2',
  initialState: INITIAL_STATE,
  reducers: {
    resestMigrationState: () => INITIAL_STATE,
    fetchMigrationSessions: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        businessId: string
      }>,
    ) => {
      state.sessions.list = []
      state.sessions.map = {}
      state.sessions.isLoading = true
      state.error = null
    },
    fetchMigrationSessionsSuccess: (state, action) => {
      state.sessions.list = action.payload.list
      state.sessions.map = action.payload.map
      state.sessions.isLoading = false
    },
    fetchMigrationSessionsFailure: (state, action) => {
      state.sessions.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    createImportSession: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        businessId: string
        exporterPimsId: string
        sourcePimsId: string
      }>,
    ) => {
      state.sessions.isLoading = true
      state.error = null
    },
    createImportSessionSuccess: (state) => {
      state.sessions.isLoading = false
    },
    createImportSessionFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.sessions.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    chooseImportSession: (
      state,
      action: PayloadAction<{
        sessionId: string
      }>,
    ) => {
      state.sessions.currentSessionId = action.payload.sessionId
      state.progressEntities = []
      state.supportedEntities = []
    },
    fetchMigrationSessionDetails: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionId: string
      }>,
    ) => {
      state.isLoadingImportSessionDetails = true
      state.sessions.currentSessionId = null
      state.settings = null
      state.activationToken = ''
      state.error = null
    },
    fetchMigrationSessionDetailsSuccess: (
      state,
      action: PayloadAction<{
        sessionDetails: MigrationSessionDetails
      }>,
    ) => {
      const { session, settings, token } = action.payload.sessionDetails
      state.sessions.currentSessionId = session.id
      state.sessions.map[session.id] = session
      state.settings = settings
      state.activationToken = token
      state.isLoadingImportSessionDetails = false
    },
    fetchMigrationSessionDetailsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoadingImportSessionDetails = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateMigrationSessionStage: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionId: string
        stageTo: MigrationSessionStage
      }>,
    ) => {
      state.isLoadingImportSessionDetails = true
      state.error = null
    },
    updateMigrationSessionStageFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoadingImportSessionDetails = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateMigrationSessionSettings: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionId: string
        settings: MigrationSessionSettings
      }>,
    ) => {
      state.isLoadingImportSessionDetails = true
      state.error = null
    },
    updateMigrationSessionSettingsSuccess: (state, action) => {
      state.isLoadingImportSessionDetails = false
      state.settings = action.payload.settings
    },
    updateMigrationSessionSettingsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoadingImportSessionDetails = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchMigrationSessionFiles: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionId: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    fetchMigrationSessionFilesSuccess: (
      state,
      action: PayloadAction<{
        sessionFiles: MigrationSessionFile[]
      }>,
    ) => {
      state.isLoading = false
      state.sessionFiles = action.payload.sessionFiles
    },
    fetchMigrationSessionFilesFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    uploadMigrationSessionFile: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionFile: MigrationSessionFile
        sessionId: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    uploadMigrationSessionFileSuccess: (
      state,
      action: PayloadAction<{
        sessionFiles: MigrationSessionFile[]
      }>,
    ) => {
      state.isLoading = false
      state.sessionFiles = action.payload.sessionFiles
    },
    uploadMigrationSessionFileFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    deleteMigrationSessionFile: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        fileExtension: string
        fileName: string
        sessionId: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    deleteMigrationSessionFileSuccess: (
      state,
      action: PayloadAction<{
        sessionFiles: MigrationSessionFile[]
      }>,
    ) => {
      state.isLoading = false
      state.sessionFiles = action.payload.sessionFiles
    },
    deleteMigrationSessionFileFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchMigrationExporterUrl: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        exporterType: RhapsodyExporterTypes
      }>,
    ) => {
      state.isLoadingExporterUrl = true
      state.error = null
    },
    fetchMigrationExporterUrlSuccess: (
      state,
      action: PayloadAction<{
        exporterUrl: string
      }>,
    ) => {
      state.exporterUrl = action.payload.exporterUrl
      state.isLoadingExporterUrl = false
    },
    fetchMigrationExporterUrlFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoadingExporterUrl = false
      state.error = getErrorMessage(action.payload.error)
    },
    clearMigrationExporterUrl: (state) => {
      state.exporterUrl = ''
    },
    fetchMigrationImportRequests: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionId: string
      }>,
    ) => {
      state.importRequests.isLoading = true
      state.importRequests.map = {}
      state.importRequests.list = []
      state.error = null
    },
    fetchMigrationImportRequestsSuccess: (state, action) => {
      state.importRequests.list = action.payload.list || []
      state.importRequests.map = action.payload.map || {}
      state.importRequests.totalCount = action.payload.totalCount
      state.importRequests.isLoading = false
    },
    fetchMigrationImportRequestsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.importRequests.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchMoreMigrationImportRequests: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        from?: number
        sessionId: string
      }>,
    ) => {
      state.error = null
    },
    fetchMoreMigrationImportRequestsSuccess: (state, action) => {
      state.importRequests.list = R.concat(
        state.importRequests.list,
        action.payload.list,
      )
      state.importRequests.map = R.mergeLeft(
        state.importRequests.map,
        action.payload.map,
      )
    },
    fetchMoreMigrationImportRequestsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.error = getErrorMessage(action.payload.error)
    },
    chooseImportRequest: (
      state,
      action: PayloadAction<{
        importRequestId: string
      }>,
    ) => {
      state.importRequests.currentImportRequestId =
        action.payload.importRequestId
    },
    fetchMigrationImportTasks: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        importRequestId: string
      }>,
    ) => {
      state.importTasks.isLoading = true
      state.importTasks.map = {}
      state.importTasks.list = []
      state.error = null
    },
    fetchMigrationImportTasksSuccess: (state, action) => {
      state.importTasks.list = action.payload.list || []
      state.importTasks.map = action.payload.map || {}
      state.importTasks.totalCount = action.payload.totalCount
      state.importTasks.isLoading = false
    },
    fetchMigrationImportTasksFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.importTasks.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchMoreMigrationImportTasks: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        from?: number
        importRequestId: string
      }>,
    ) => {
      state.error = null
    },
    fetchMoreMigrationImportTasksSuccess: (state, action) => {
      state.importTasks.list = R.concat(
        state.importTasks.list,
        action.payload.list,
      )
      state.importTasks.map = R.mergeLeft(
        state.importTasks.map,
        action.payload.map,
      )
    },
    fetchMoreMigrationImportTasksFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.error = getErrorMessage(action.payload.error)
    },
    fetchMigrationSupportedEntities: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        sessionId: string
      }>,
    ) => {
      state.supportedEntities = []
      state.isLoadingSupportedEntities = true
      state.error = null
    },
    fetchMigrationSupportedEntitiesSuccess: (state, action) => {
      state.supportedEntities = action.payload.supportedEntities
      state.isLoadingSupportedEntities = false
    },
    fetchMigrationSupportedEntitiesFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoadingSupportedEntities = false
      state.error = getErrorMessage(action.payload.error)
    },
    createImportTask: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        entities?: string[]
        requestId: string
        type: MigrationTaskType
      }>,
    ) => {
      state.isCreatingNewImportTask = true
      state.error = null
    },
    createImportTaskSuccess: (state) => {
      state.isCreatingNewImportTask = false
    },
    createImportTaskFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isCreatingNewImportTask = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchMigrationImportExceptions: (
      state,
      action: PayloadAction<{
        importSessionId: string
        withResolved?: boolean
      }>,
    ) => {
      state.exceptions.isLoading = true
      state.exceptions.withResolved = Boolean(action.payload.withResolved)
      state.error = null
    },
    fetchMigrationImportExceptionsSuccess: (state, action) => {
      state.exceptions.list = action.payload.list
      state.exceptions.map = action.payload.map
      state.exceptions.isLoading = false
    },
    fetchMigrationImportExceptionsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.exceptions.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    editMigrationImportExceptions: (
      state,
      action: PayloadAction<{
        exception: MigrationException
        importExceptionId: string
      }>,
    ) => {
      const { importExceptionId, exception } = action.payload
      state.exceptions.drafts = R.equals(
        exception,
        state.exceptions.map[importExceptionId],
      )
        ? R.omit([importExceptionId], state.exceptions.drafts)
        : R.assoc(importExceptionId, exception, state.exceptions.drafts)
    },
    updateMigrationImportExceptions: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        draftExceptions: MigrationExceptionMap
      }>,
    ) => {
      state.exceptions.isUpdating = true
      state.error = null
    },
    updateMigrationImportExceptionsSuccess: (state) => {
      state.exceptions.isUpdating = false
      state.exceptions.drafts = INITIAL_STATE.exceptions.drafts
    },
    updateMigrationImportExceptionsFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.exceptions.isUpdating = false
      state.error = getErrorMessage(action.payload.error)
    },
    clearMigrationImportExceptions: (state) => {
      state.exceptions = INITIAL_STATE.exceptions
    },
  },
})

const { actions } = migrationSlice

export const {
  fetchMigrationSessions,
  fetchMigrationSessionsSuccess,
  fetchMigrationSessionsFailure,
  fetchMigrationSessionDetails,
  fetchMigrationSessionDetailsSuccess,
  fetchMigrationSessionDetailsFailure,
  createImportSession,
  createImportSessionSuccess,
  createImportSessionFailure,
  chooseImportSession,
  updateMigrationSessionStage,
  updateMigrationSessionStageFailure,
  updateMigrationSessionSettings,
  updateMigrationSessionSettingsSuccess,
  updateMigrationSessionSettingsFailure,
  fetchMigrationSessionFiles,
  fetchMigrationSessionFilesSuccess,
  fetchMigrationSessionFilesFailure,
  uploadMigrationSessionFile,
  uploadMigrationSessionFileSuccess,
  uploadMigrationSessionFileFailure,
  deleteMigrationSessionFile,
  deleteMigrationSessionFileSuccess,
  deleteMigrationSessionFileFailure,
  fetchMigrationExporterUrl,
  fetchMigrationExporterUrlSuccess,
  fetchMigrationExporterUrlFailure,
  clearMigrationExporterUrl,
  fetchMigrationImportRequests,
  fetchMigrationImportRequestsSuccess,
  fetchMigrationImportRequestsFailure,
  fetchMoreMigrationImportRequests,
  fetchMoreMigrationImportRequestsSuccess,
  fetchMoreMigrationImportRequestsFailure,
  chooseImportRequest,
  fetchMigrationImportTasks,
  fetchMigrationImportTasksSuccess,
  fetchMigrationImportTasksFailure,
  fetchMoreMigrationImportTasks,
  fetchMoreMigrationImportTasksSuccess,
  fetchMoreMigrationImportTasksFailure,
  fetchMigrationSupportedEntities,
  fetchMigrationSupportedEntitiesSuccess,
  fetchMigrationSupportedEntitiesFailure,
  createImportTask,
  createImportTaskSuccess,
  createImportTaskFailure,
  fetchMigrationImportExceptions,
  fetchMigrationImportExceptionsSuccess,
  fetchMigrationImportExceptionsFailure,
  clearMigrationImportExceptions,
  updateMigrationImportExceptions,
  updateMigrationImportExceptionsSuccess,
  updateMigrationImportExceptionsFailure,
  editMigrationImportExceptions,
  resestMigrationState,
} = actions

const formatLogItem = (logItem: NofixLog) => {
  const { lineNumber, exceptionMessage } = logItem
  const lineNumberString = lineNumber && `Line ${lineNumber}`

  return [lineNumberString, exceptionMessage].filter(Boolean).join(' ')
}

export const getMigration = (state: RootState): MigrationState =>
  state.migrationV2
export const getExporterUrl = (state: RootState) =>
  getMigration(state).exporterUrl
export const getProgressEntities = (state: RootState) =>
  getMigration(state).progressEntities
export const getNonFixableLogs = (state: RootState) =>
  getMigration(state).nofixLogs?.map(formatLogItem) || []

export const getIsStartingImport = (state: RootState) =>
  getMigration(state).isLoadingStartImport

export const getSessions = (state: RootState) => getMigration(state).sessions
export const getSessionsMap = (state: RootState) => getSessions(state).map
export const getSessionsList = (state: RootState) => getSessions(state).list
export const getSessionsIsLoading = (state: RootState) =>
  getSessions(state).isLoading
export const getSession = (id: string | Nil) =>
  createSelector(getSessionsMap, (map: SessionsMap) =>
    id ? map[id] : undefined,
  )
export const getMultipleSessions = (ids: string[]) =>
  createSelector(getSessionsMap, (map) => R.props(ids, map))
export const getIsLoadingImportSessions = (state: RootState) =>
  getSessions(state).isLoading
export const getCurrentSessionId = (state: RootState) =>
  getSessions(state).currentSessionId
export const getCurrentSession = createSelector(
  getCurrentSessionId,
  getSessionsMap,
  (currentSessionId: string | null, sessionsMap: SessionsMap) =>
    currentSessionId ? sessionsMap[currentSessionId] : null,
)

export const getImportRequests = (state: RootState) =>
  getMigration(state).importRequests
export const getImportRequestIds = (state: RootState) =>
  getImportRequests(state).list
export const getImportRequestsMap = (state: RootState) =>
  getImportRequests(state).map
export const getIsLoadingImportRequests = (state: RootState) =>
  getImportRequests(state).isLoading
export const getMultipleImportRequests = (ids: string[]) =>
  createSelector(getImportRequestsMap, (map) => R.props(ids, map))
export const getCurrentImportRequestId = (state: RootState) =>
  getImportRequests(state).currentImportRequestId
export const getCurrentImportRequest = createSelector(
  getCurrentImportRequestId,
  getImportRequestsMap,
  (
    currentImportRequestId: string | null,
    importRequestsMap: Record<string, MigrationImportRequest>,
  ) =>
    currentImportRequestId ? importRequestsMap[currentImportRequestId] : null,
)

export const getImportTasks = (state: RootState) =>
  getMigration(state).importTasks
export const getImportTaskIds = (state: RootState) => getImportTasks(state).list
export const getImportTasksMap = (state: RootState) => getImportTasks(state).map
export const getIsLoadingImportTasks = (state: RootState) =>
  getImportTasks(state).isLoading
export const getMultipleImportTasks = (ids: string[]) =>
  createSelector(getImportTasksMap, (map) => R.props(ids, map))
export const getIsCreatingImportTask = (state: RootState) =>
  getMigration(state).isCreatingNewImportTask

export const getIsLoadingStatus = (state: RootState) =>
  getMigration(state).isLoadingStatus

/** Migration Exception Selectors */
export const getExceptions = (state: RootState) =>
  getMigration(state).exceptions
export const getExceptionsMap = (state: RootState) => getExceptions(state).map
export const getExceptionsList = (state: RootState) => getExceptions(state).list
export const getExceptionsDrafts = (state: RootState) =>
  getExceptions(state).drafts
export const getExceptionDraft = (id: string) =>
  createSelector(getExceptionsDrafts, (drafts) => drafts[id] || {})
export const getIsUpdatingExceptions = (state: RootState) =>
  getExceptions(state).isUpdating
export const getExceptionsIsLoading = (state: RootState) =>
  getExceptions(state).isLoading
export const getMultipleExceptions = (ids: string[]) =>
  createSelector(getExceptionsMap, (map) => R.props(ids, map))
export const getIsWithResolved = (state: RootState) =>
  getExceptions(state).withResolved

export const getActivationToken = (state: RootState) =>
  getMigration(state).activationToken

export const getIsLoading = (state: RootState) => getMigration(state).isLoading
export const getSessionFiles = (state: RootState) =>
  getMigration(state).sessionFiles
export const getPreprocessingStatus = (state: RootState) =>
  getMigration(state).preprocessingStatus
export const getIsLoadingStartImport = (state: RootState) =>
  getMigration(state).isLoadingStartImport

export const getError = (state: RootState) => getMigration(state).error

export const getIsLoadingImportSessionDetails = (state: RootState) =>
  getMigration(state).isLoadingImportSessionDetails
export const getImportSettings = (state: RootState) =>
  getMigration(state).settings
export const getMigrationSanitized = (state: RootState) => ({
  ...state.migration,
  activationToken: '*',
})

export const getSessionSupportedEntities = (state: RootState) =>
  getMigration(state).supportedEntities
export const getIsLoadingSupportedEntitites = (state: RootState) =>
  getMigration(state).isLoadingSupportedEntities

export const getRelationsMappings = (state: RootState) =>
  getMigration(state).relationsMappings
export const getIsLoadingRelationsMappings = (state: RootState) =>
  getMigration(state).isLoadingRelationsMappings
export const getIsLoadingExporterUrl = (state: RootState) =>
  getMigration(state).isLoadingExporterUrl
