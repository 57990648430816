import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'

import { ReferenceEntityType } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import { getClientId, getSoapId } from '~/store/reducers/soap'
import { fetchClientSurveyLink } from '~/store/reducers/soapV2'
import useDialog from '~/utils/useDialog'

import SoapWidget from '../SoapWidget'

const ClientSurveyWidget = () => {
  const { t } = useTranslation(['Soap'])
  const dispatch = useDispatch()
  const clientId = useSelector(getClientId)
  const soapId = useSelector(getSoapId)

  const [openQRCodeDialog] = useDialog(DialogNames.CLIENT_SURVEY)

  const generateQRCode = () => {
    if (clientId && soapId) {
      dispatch(
        fetchClientSurveyLink({
          clientId,
          referenceEntityType: ReferenceEntityType.Soap,
          referenceId: soapId,
        }),
      )
    }
    openQRCodeDialog()
  }

  return (
    <SoapWidget
      id="finalize-orders-widget"
      title={t('Soap:CLIENT_SURVEY_WIDGET_TITLE')}
    >
      <Grid container item justifyContent="flex-start" px={3} py={2}>
        <Fab
          disableRipple
          color="inherit"
          variant="extended"
          onClick={generateQRCode}
        >
          {t('Soap:CLIENT_SURVEY_GENERATE')}
        </Fab>
      </Grid>
    </SoapWidget>
  )
}

export default ClientSurveyWidget
