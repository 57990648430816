import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, Business, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import DialogNames from '~/constants/DialogNames'
import { Journal, TokenHolder } from '~/types'
import { auth0Enabled } from '~/utils'

import {
  fetchCurrentUserFailure,
  fetchCurrentUserSuccess,
  loginFailure,
  loginRequest,
  loginSuccess,
  refreshToken,
  refreshTokenFailure,
  refreshTokenSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  sendResetPasswordEmail,
  sendResetPasswordEmailFailure,
  sendResetPasswordEmailSuccess,
  silentLoginFailure,
  silentLoginRequest,
  silentLoginSuccess,
  updatePassword,
  updatePasswordFailure,
  updatePasswordSuccess,
} from '../actions/auth'
import {
  updateBusinesses,
  updateFavoriteBusinessList,
  updateLockWorkingHoursSettings,
} from '../actions/businesses'
import { addBusinessToJournal, addUserToJournal } from '../actions/journal'
import { fetchNotificationSettings } from '../actions/notifications'
import * as AuthActions from '../actions/types/auth'
import { updateUsers } from '../actions/users'
import { openDialog } from '../duck/dialogs'
import { removeAllErrors } from '../duck/errors'
import { urlPreloadSaga } from '../duck/preload'
import {
  checkStatusSuccess,
  updateTimeTrackerEntities,
} from '../duck/timeTracker'
import {
  getCurrentBusiness,
  getCurrentBusinessId,
  getCurrentUser,
  getCurrentUserId,
} from '../reducers/auth'
import { getJournalMap } from '../reducers/journal'
import requestAPI from './utils/requestAPI'

export function* loginRequestSaga({
  email,
  password,
  eulaAccepted,
}: ReturnType<typeof loginRequest>) {
  try {
    const { tokenHolder, personId } = yield call(
      API.logIn,
      email,
      password,
      eulaAccepted,
    )
    const journal: Journal = yield select(getJournalMap)
    yield put(updateUsers(journal))
    yield put(removeAllErrors())
    yield put(loginSuccess(tokenHolder, personId))
  } catch (error) {
    yield put(loginFailure(error as ApiError))
  }
}

export function* refreshTokenSaga({ token }: ReturnType<typeof refreshToken>) {
  try {
    const tokenHolder: TokenHolder = yield call(API.refresh, token)
    yield put(refreshTokenSuccess(tokenHolder))
  } catch (error) {
    yield put(refreshTokenFailure(error as ApiError))
  }
}

export function* silentLoginRequestSaga({
  email,
  password,
}: ReturnType<typeof silentLoginRequest>) {
  try {
    const { tokenHolder, personId } = yield call(API.logIn, email, password)
    const journal: Journal = yield select(getJournalMap)
    yield put(removeAllErrors())
    yield put(silentLoginSuccess(tokenHolder, personId))
    yield put(updateUsers(journal))
  } catch (error) {
    yield put(silentLoginFailure(error as ApiError))
  }
}

export function* fetchMe() {
  try {
    const {
      result: {
        person: userId,
        business: userBusinessId,
        timeTrackingStatus,
        groupBusinessList,
        groupHierarchyBusinessIds,
        businessRootId,
        onboardBusinessInfo,
      },
      entities: { users, businesses, timeEntities },
    } = yield* requestAPI(API.fetchCurrentUser)
    const currentUser: User = users[userId]
    const currentBusinessId = (currentUser.businessToRoleList || []).some(
      ({ business }) => business === userBusinessId,
    )
      ? userBusinessId
      : currentUser.businessToRoleList?.[0].business

    const newUser = {
      ...currentUser,
      currentBusinessRootId: businessRootId,
      currentBusinessId,
      currentHierarchyBusinessIds: groupHierarchyBusinessIds,
      currentGroupBusinessIds: [currentBusinessId, ...groupBusinessList],
    }

    yield put(
      updateBusinesses({
        ...businesses,
        // TODO: PR-1568 consider to place onboarding details directly in business entity
        ...(onboardBusinessInfo?.businessId
          ? {
              [onboardBusinessInfo.businessId]: {
                ...businesses[onboardBusinessInfo.businessId],
                ...onboardBusinessInfo,
              },
            }
          : {}),
      }),
    )
    yield put(updateFavoriteBusinessList(newUser?.favouriteBusinessIds || []))
    yield put(updateLockWorkingHoursSettings(newUser.lockWorkingHoursSettings))
    yield put(
      updateUsers({
        [userId]: newUser,
      }),
    )
    yield put(addUserToJournal(newUser))

    if (!currentUser?.eulaAcceptanceDate) {
      yield put(
        openDialog({
          name: DialogNames.TERMS_AND_CONDITIONS,
          id: uuid(),
          unique: true,
        }),
      )
    }

    const business: Business = yield select(getCurrentBusiness)

    if (business) {
      yield put(addBusinessToJournal(business))
    }

    if (timeEntities) {
      yield put(updateTimeTrackerEntities(timeEntities))
    }

    yield put(checkStatusSuccess(timeTrackingStatus?.currentEntry))
    yield put(fetchCurrentUserSuccess(userId))
  } catch (error) {
    yield put(fetchCurrentUserFailure(error as ApiError))
  }
}

export function* updatePasswordSaga({
  password,
}: ReturnType<typeof updatePassword>) {
  try {
    yield call(API.updatePassword, password)
    yield put(updatePasswordSuccess())
  } catch (error) {
    yield put(updatePasswordFailure(error as ApiError))
  }
}

export function* sendResetPasswordEmailSaga({
  email,
}: ReturnType<typeof sendResetPasswordEmail>) {
  try {
    if (auth0Enabled) {
      yield call(API.requestAuth0ResetPasswordEmail, email)
    } else {
      yield call(API.requestResetPasswordEmail, email)
    }
    yield put(sendResetPasswordEmailSuccess())
  } catch (error) {
    yield put(sendResetPasswordEmailFailure(error as ApiError))
  }
}

export function* resetPasswordSaga({
  token,
  password,
  email,
}: ReturnType<typeof resetPassword>) {
  try {
    yield call(API.resetPassword, token, password)
    yield put(resetPasswordSuccess())
    yield put(loginRequest(email, password))
  } catch (error) {
    yield put(resetPasswordFailure(error as ApiError))
  }
}

function* fetchDataWithEmptyStorage(cachedUserId: string) {
  yield call(fetchMe)
  yield call(urlPreloadSaga, cachedUserId)
}

function* fetchDataWithJournal(cachedBusinessId: string, cachedUserId: string) {
  // NOTE: Preload endpoints should be made up carefully due to possible issues
  // with passing cachedBusinessId simultaneously with /me request which can
  // override business response and context in common by some inner conditions,
  // it can lead to unexpected preload endpoint exceptions
  yield all([call(fetchMe), call(urlPreloadSaga, cachedUserId)])
  const currentBusinessId: string = yield select(getCurrentBusinessId)
  if (currentBusinessId !== cachedBusinessId) {
    yield call(urlPreloadSaga, cachedUserId)
  }
}

export function* fetchCurrentUserSaga() {
  const oldUser: User = yield select(getCurrentUser)
  if (!oldUser || !oldUser.email) {
    const journal: Journal = yield select(getJournalMap)
    yield put(updateUsers(journal))
  }
  const cachedBusinessId: string = yield select(getCurrentBusinessId)
  const cachedUserId: string = yield select(getCurrentUserId)

  if (cachedBusinessId) {
    yield call(fetchDataWithJournal, cachedBusinessId, cachedUserId)
  } else {
    yield call(fetchDataWithEmptyStorage, cachedUserId)
  }

  const userId: string = yield select(getCurrentUserId)

  yield put(fetchNotificationSettings(userId))
}

function* watchLogin() {
  yield takeLatest(AuthActions.LOGIN_REQUEST, loginRequestSaga)
}

function* watchSilentLogin() {
  yield takeLatest(AuthActions.SILENT_LOGIN_REQUEST, silentLoginRequestSaga)
}

function* watchFetchCurrentUser() {
  yield takeLeading(AuthActions.FETCH_CURRENT_USER, fetchCurrentUserSaga)
}

function* watchRefreshToken() {
  yield takeLeading(AuthActions.REFRESH_TOKEN, refreshTokenSaga)
}

function* watchUpdatePassword() {
  yield takeLatest(AuthActions.UPDATE_PASSWORD, updatePasswordSaga)
}

function* watchSendResetPasswordEmail() {
  yield takeLatest(
    AuthActions.SEND_RESET_PASSWORD_EMAIL,
    sendResetPasswordEmailSaga,
  )
}

function* watchResetPassword() {
  yield takeLatest(AuthActions.RESET_PASSWORD, resetPasswordSaga)
}

export default function* authSaga() {
  yield all([
    watchUpdatePassword(),
    watchFetchCurrentUser(),
    watchLogin(),
    watchSilentLogin(),
    watchRefreshToken(),
    watchSendResetPasswordEmail(),
    watchResetPassword(),
  ])
}
