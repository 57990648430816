import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  ButtonWithLoader,
  ClassesType,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      minWidth: 150,
      padding: theme.spacing(0, 2),
    },
  }),
  { name: 'AddReminderDialogActions' },
)

export interface AddReminderDialogActionsProps {
  classes?: ClassesType<typeof useStyles>
  isLoading?: boolean
  isProceedDisabled?: boolean
  onBack?: () => void
  onProceed: () => void
  proceedLabel: string
}

const AddReminderDialogActions = ({
  classes: classesProp,
  isLoading,
  isProceedDisabled = false,
  onBack,
  onProceed,
  proceedLabel,
}: AddReminderDialogActionsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  return (
    <>
      {onBack && (
        <Grid item>
          <BackButton
            disabled={isLoading}
            label={t('Common:BACK_ACTION')}
            onClick={onBack}
          />
        </Grid>
      )}
      {onProceed && (
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          disabled={isLoading || isProceedDisabled}
          loading={isLoading}
          onClick={onProceed}
        >
          {proceedLabel}
        </ButtonWithLoader>
      )}
    </>
  )
}

export default AddReminderDialogActions
