import React, { useCallback, useState } from 'react'
import * as R from 'ramda'
import {
  Field,
  Nil,
  PuiTextField,
  PuiTextFieldProps,
} from '@pbt/pbt-ui-components'

import { handleNumberInput } from '~/utils'

export type NumericInputProps = Omit<
  PuiTextFieldProps,
  'onChange' | 'onBlur'
> & {
  allowDecimal?: boolean
  field?: Field
  max?: number
  min?: number
  nullable?: boolean
  onChange?: (newValue: number | string) => void
  onMinReached?: (newValue: number | string) => void
  value?: number | '' | Nil
}

const NumericInput = ({
  field,
  min = 1,
  max = 999999,
  value: initialValue,
  onChange: initialOnChange,
  allowDecimal,
  nullable,
  onMinReached,
  ...rest
}: NumericInputProps) => {
  const value = field?.value ?? initialValue
  const onChange = field?.setValue ?? initialOnChange

  const [typedNotSaved, setTypedNotSaved] = useState<string>()

  const allowNegative = min < 0

  const getNewDecimalValue = (newValue: string | number) => {
    const numberValue = Number(newValue)
    if (Number.isNaN(numberValue)) {
      return 0
    }

    return numberValue >= min ? (numberValue <= max ? numberValue : max) : min
  }

  const onInputChange = useCallback(
    (inputValue: string, blured: boolean) => {
      const lastDot = allowDecimal && inputValue.slice(-1) === '.'
      const lastZero =
        allowDecimal &&
        inputValue.slice(-1) === '0' &&
        inputValue.indexOf('.') !== -1
      const isNegativeSignInput = inputValue === '-'
      const inputValueMinReached = Number(inputValue) < min

      if (blured && inputValueMinReached && onMinReached) {
        onMinReached(inputValue)
      }

      const hasProcessingSymbols = inputValueMinReached || lastDot || lastZero
      const isStartNegativeFlow = allowNegative && isNegativeSignInput

      if ((isStartNegativeFlow || hasProcessingSymbols) && !blured) {
        setTypedNotSaved(inputValue)
        return
      }
      const newValue = getNewDecimalValue(
        allowDecimal ? inputValue : parseInt(inputValue, 10),
      )
      if (onChange) {
        onChange((nullable || allowNegative) && newValue === 0 ? '' : newValue)
        setTypedNotSaved(undefined)
      }
    },
    [min, max, onChange],
  )

  const onBlur = () => {
    if (!R.isNil(typedNotSaved)) {
      onInputChange(typedNotSaved, true)
      setTypedNotSaved(undefined)
    }
  }

  return (
    <PuiTextField
      field={
        {
          ...field,
          value: typedNotSaved ?? value,
          set: handleNumberInput(onInputChange, 10, 4, true, allowNegative),
        } as Field
      }
      onBlur={onBlur}
      {...rest}
    />
  )
}

export default NumericInput
