import * as R from 'ramda'
import { Business, Nil } from '@pbt/pbt-ui-components'

import { BusinessSearchParameters } from '~/types'

import { getFieldsQueryPairs, getStringifyFieldsQueryPairs } from './index'

export const getBusinessName = (business?: Business) =>
  business?.internalName || business?.name || ''

export const getBusinessIsGroup = (business?: Business) =>
  business?.children ? business?.children?.length > 0 : false

// TODO PR-168: we need another field to detect if business is inside a group
// since currently all businesses have Rhapsody Global as their parent
// need to clarify this with backend devs
// eslint-disable-next-line
export const getBusinessIsInGroup = (business: Business) => false // Boolean(business?.parentBusinessId)

export function businessSortingFunction(
  businessOne: Business,
  businessTwo: Business,
  currentBusinessId?: string,
) {
  const businessOneName = businessOne.name?.toLowerCase?.()
  const businessTwoName = businessTwo.name?.toLowerCase?.()

  if (businessOne.id === currentBusinessId) {
    return -1
  }
  if (businessTwo.id === currentBusinessId) {
    return 1
  }
  if (businessOneName < businessTwoName) {
    return -1
  }
  if (businessOneName > businessTwoName) {
    return 1
  }

  return 0
}

export const isDifferedBusinessList = (
  items: { businessId?: string | Nil }[],
) => R.uniqBy(R.prop('businessId'), items).length > 1

export const ensureBusinessSearchParametersByGroupType = (
  parameters: BusinessSearchParameters,
  isGroup: boolean,
): BusinessSearchParameters => {
  const { query, fieldsQuery, ...rest } = parameters

  const fixedFieldsQuery = isGroup
    ? getStringifyFieldsQueryPairs(
        getFieldsQueryPairs(fieldsQuery).concat([['groupName', query]]),
      )
    : fieldsQuery

  return {
    query: isGroup ? '' : query,
    fieldsQuery: fixedFieldsQuery,
    sharingResult: isGroup ? 'GROUP_BUSINESSES' : 'CHILDREN_BUSINESSES',
    ...rest,
  }
}
