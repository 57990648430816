import React from 'react'

import {
  RX_REQUEST_TASK_TYPE,
  VET_DIET_REQUEST_TASK_TYPE,
} from '~/constants/taskConstants'
import { useGetTaskTypeByTaskId } from '~/store/hooks/tasks'

import RxRequestTaskDetails from './rx-request-task/RxRequestTaskDetails'
import TaskDetails from './TaskDetails'

interface FullScreenTaskProps {
  onDetailsClose: () => void
  taskId: string
}

const FullScreenTask = ({ taskId, onDetailsClose }: FullScreenTaskProps) => {
  const taskType = useGetTaskTypeByTaskId()(taskId)

  const isRxRequestTask = [
    RX_REQUEST_TASK_TYPE,
    VET_DIET_REQUEST_TASK_TYPE,
  ].includes(taskType)

  return (
    <>
      {isRxRequestTask ? (
        <RxRequestTaskDetails itemId={taskId} onClose={onDetailsClose} />
      ) : (
        <TaskDetails itemId={taskId} onClose={onDetailsClose} />
      )}
    </>
  )
}

export default FullScreenTask
