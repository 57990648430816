import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { getClientId, getPatientId } from '~/store/reducers/soap'

const useConfirmLeaveSoapForAssignmentAlert = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_LEAVE_FOR_ASSIGNMENT,
  })

  const openConfirmLeaveSoapForAssignmentAlert = () => {
    openConfirmAlert({
      applyCustomMessage: true,
      message: t(
        'Dialogs:CONFIRM_ALERT_DIALOG.SOAP_LEAVE_FOR_ASSIGNMENT.MESSAGE',
      ),
      onConfirm: (proceed: any) => {
        if (proceed) {
          navigate('/scheduler', {
            state: {
              clientId,
              patientId,
              redirectUrl: location.pathname + location.search,
            },
          })
        }
      },
      okButtonText: t('Common:GO_TO_SCHEDULER'),
      cancelButtonText: t(
        'Dialogs:CONFIRM_ALERT_DIALOG.SOAP_LEAVE_FOR_ASSIGNMENT.CANCEL_BUTTON',
      ),
    })
  }

  return [openConfirmLeaveSoapForAssignmentAlert]
}

export default useConfirmLeaveSoapForAssignmentAlert
