import React from 'react'
import { useParams } from 'react-router-dom'

import ScrollableColumns, {
  ScrollableColumnsProps,
} from '~/components/common/lists/scrollable-columns/ScrollableColumns'
import { TimetableArea } from '~/constants/schedulerConstants'
import { getConstantFromMapOrDefault } from '~/utils'
import useTimetableDate from '~/utils/useTimetableDate'

import TimetableHeader from '../header/TimetableHeader'
import { SchedulerViewHumanReadable, SchedulerViewType } from './schedulerViews'
import BoardingColumnHeader from './views/boarding/BoardingColumnHeader'
import ScheduleColumnHeader from './views/schedule/ScheduleColumnHeader'

const ColumnHeaderComponentsMap = {
  [SchedulerViewType.BOARDING]: BoardingColumnHeader,
  [SchedulerViewType.SCHEDULE]: ScheduleColumnHeader,
}

export interface SchedulerHeaderProps
  extends Omit<ScrollableColumnsProps, 'ColumnHeaderComponent'> {
  openShadow: boolean
}

const SchedulerHeader = ({ openShadow, ...rest }: SchedulerHeaderProps) => {
  const { view: viewProp } = useParams() as { view: string }
  const { selectedDate } = useTimetableDate()
  const view = getConstantFromMapOrDefault(
    viewProp,
    SchedulerViewType,
    SchedulerViewType.SCHEDULE,
  )

  const isBoarding = view === SchedulerViewType.BOARDING

  return (
    <TimetableHeader
      area={TimetableArea.SCHEDULER}
      openShadow={openShadow}
      selectedView={view}
      showSettingsButton={!isBoarding}
      views={SchedulerViewHumanReadable}
    >
      <ScrollableColumns
        ColumnHeaderComponent={ColumnHeaderComponentsMap[view]}
        dependencies={[selectedDate]}
        {...rest}
      />
    </TimetableHeader>
  )
}

export default SchedulerHeader
