/* eslint-disable prefer-regex-literals */
/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import * as R from 'ramda'
import { AlertIconType } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { clearClientsError, fetchClient } from '~/store/actions/clients'
import { useShouldDisplaySmsConsent } from '~/store/hooks/clients'
import { getClientsError } from '~/store/reducers/clients'
import { getPatientsForUser, getUserByPatient } from '~/store/reducers/users'
import { testAndMatch } from '~/utils'
import useDialog from '~/utils/useDialog'

import ResponsiveRoute from '../routes/ResponsiveRoute'
import { renderMultiPathRoute } from '../routes/utils'
import CreateClientPage from './CreateClientPage'
import ClientAndPatientDetailsPage from './details/ClientAndPatientDetailsPage'
import ClientDetailsEditPage from './details/ClientDetailsEditPage'
import ClientDetailsPage from './details/ClientDetailsPage'
import NoPatientPage from './details/NoPatientPage'
import PatientDetailsPage from './details/PatientDetailsPage'
import NewClientDetailsEditPage from './new-client/NewClientDetailsEditPage'
import NewClientPatientPage from './new-client/NewClientPatientPage'
import EditPatientPage from './patient/EditPatientPage'
import CoPetParentPage from './pet-friend/CoPetParentPage'
import EmergencyContactPage from './pet-friend/EmergencyContactPage'
import PetFriendPage from './pet-friend/PetFriendPage'

const ClientIdPage = () => {
  const dispatch = useDispatch()
  const { clientId = '' } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('Common')

  const {
    matches: [patientId],
  } = testAndMatch(location.pathname, RegExp('/patient/([A-Za-z0-9]+)/?(.+)?'))
  const client = useSelector(getUserByPatient(patientId, clientId))
  const error = useSelector(getClientsError)
  const sortedPatients = useSelector(getPatientsForUser(clientId), R.equals)

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  useEffect(() => {
    if (patientId || clientId) {
      dispatch(
        fetchClient({
          clientId,
          patientId,
          withGlobalClientPreferences: shouldDisplaySmsConsent,
        }),
      )
    }
  }, [clientId, patientId, shouldDisplaySmsConsent])

  useEffect(() => {
    if (error) {
      openAlert({
        hideCloseButton: true,
        iconType: AlertIconType.WARN,
        message: error,
        okButtonText: t('Common:GO_BACK'),
        onOk: () => {
          dispatch(clearClientsError())
          navigate('/clients')
          closeAlert()
        },
      })
    }
  }, [error])

  if (!client?.id) {
    return null
  }

  const firstPatientId =
    client && sortedPatients.length ? sortedPatients[0] : undefined

  const patientUrl =
    patientId || firstPatientId
      ? `/client/${clientId}/patient/${patientId || firstPatientId}`
      : `/client/${clientId}/nopet`

  return (
    <Routes>
      {/* root path: /client/:clientId */}
      <Route
        element={
          <ResponsiveRoute
            md
            redirectWithReplace
            redirectTo="/client/:clientId"
          >
            <NoPatientPage />
          </ResponsiveRoute>
        }
        path="nopet"
      />
      <Route element={<NewClientDetailsEditPage />} path="new" />
      <Route element={<ClientDetailsEditPage />} path="edit" />
      <Route
        element={
          <ResponsiveRoute
            redirectWithReplace
            xs
            redirectTo={`/client/${clientId}/patient/:patientId`}
          >
            <EditPatientPage />
          </ResponsiveRoute>
        }
        path="patient/:patientId/edit"
      />
      <Route element={<EditPatientPage />} path="patient/new" />
      <Route
        element={
          <ResponsiveRoute redirectWithReplace sm xs redirectTo={patientUrl}>
            <PatientDetailsPage />
          </ResponsiveRoute>
        }
        path="patient/:patientId/details"
      />
      <Route element={<NewClientPatientPage />} path="patient/:patientId/new" />
      {renderMultiPathRoute({
        path: [
          'patient/:patientId/snapshots',
          'patient/:patientId/records',
          'patient/:patientId',
        ],
        element: <ClientAndPatientDetailsPage />,
      })}
      <Route
        element={
          <ResponsiveRoute
            redirectWithReplace
            xs
            redirectTo="/client/:clientId/edit"
          >
            <CoPetParentPage />
          </ResponsiveRoute>
        }
        path="coparent/new"
      />
      <Route
        element={
          <ResponsiveRoute redirectWithReplace sm xs redirectTo={patientUrl}>
            <CoPetParentPage />
          </ResponsiveRoute>
        }
        path="coparent/:coparentId"
      />
      <Route
        element={
          <ResponsiveRoute
            redirectWithReplace
            xs
            redirectTo="/client/:clientId/edit"
          >
            <EmergencyContactPage />
          </ResponsiveRoute>
        }
        path="emergencycontact"
      />
      <Route
        element={
          <ResponsiveRoute
            redirectWithReplace
            xs
            redirectTo="/client/:clientId/edit"
          >
            <PetFriendPage />
          </ResponsiveRoute>
        }
        path="petfriend"
      />
      <Route
        element={
          <ResponsiveRoute redirectWithReplace sm xs redirectTo={patientUrl}>
            <ClientDetailsPage />
          </ResponsiveRoute>
        }
        path="/"
      />
      <Route element={<Navigate replace to={patientUrl} />} path="*" />
    </Routes>
  )
}

const ClientPage = () => (
  <Routes>
    {/* root path: /client */}
    <Route
      element={
        <ResponsiveRoute xs redirectTo="/clients">
          <CreateClientPage />
        </ResponsiveRoute>
      }
      path="new"
    />
    <Route element={<ClientIdPage />} path=":clientId/*" />
    <Route element={<Navigate replace to="/" />} path="*" />
  </Routes>
)

export default ClientPage
