import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import {
  ControlButtonGroupName,
  LanguageUtils,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  deleteReminder,
  editReminder,
  getDeletingRemindersMap,
  getMultipleReminders,
} from '~/store/duck/reminders'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getEventState } from '~/store/reducers/constants'
import { Reminder, TimelineItem } from '~/types'
import { isDifferedBusinessList } from '~/utils/businessUtils'
import useDialog from '~/utils/useDialog'

import TimelineCard from '../TimelineCard'
import TimelineCardContent from '../TimelineCardContent'
import MultipleReminders from './MultipleReminders'
import OneReminder from './OneReminder'

interface ReminderCardProps {
  item: TimelineItem
}

const ReminderCard = ({ item, ...rest }: ReminderCardProps) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const permissions = useSelector(getCRUDByArea(PermissionArea.REMINDERS))
  const EventState = useSelector(getEventState)
  const reminders = useSelector(
    getMultipleReminders((item.entries ?? []) as string[]),
  ).filter(Boolean)
  const deletingRemindersMap = useSelector(getDeletingRemindersMap)
  const ResolvedStateId = Utils.findConstantIdByName('Resolved', EventState)
  const OpenStateId = Utils.findConstantIdByName('Open', EventState)
  const { t } = useTranslation('Clients')

  const [openReminderDetailsDialog] = useDialog(DialogNames.REMINDER_DETAILS)

  const hasOneReminder = reminders.length === 1
  const reminder = reminders[0]

  if (!reminder) {
    return null
  }

  const hasDifferedBusinessEntries = isDifferedBusinessList(reminders)

  const title = hasOneReminder
    ? LanguageUtils.getTranslatedFieldName(reminder)
    : t('Clients:TIMELINE.REMINDER_CARD.MULTIPLE_REMINDERS')

  const handleDelete = (reminderToDelete: Reminder) =>
    dispatch(deleteReminder(reminderToDelete.id))

  const handleUpdate = (newReminder: Reminder) =>
    dispatch(editReminder(newReminder))

  const handlePreview = (reminderToPreview: Reminder) =>
    openReminderDetailsDialog({ reminderId: reminderToPreview.id })

  return (
    <TimelineCard
      buttons={
        hasOneReminder
          ? [
              reminder.state.id === ResolvedStateId
                ? {
                    name: ControlButtonGroupName.ROUNDED_CHECK,
                    onClick: () =>
                      handleUpdate({ ...reminder, state: OpenStateId }),
                    IconProps: {
                      fillColor: theme.colors.tabLabel,
                    },
                  }
                : {
                    name: ControlButtonGroupName.CHECK,
                    onClick: () =>
                      handleUpdate({ ...reminder, state: ResolvedStateId }),
                  },
              {
                name: ControlButtonGroupName.PREVIEW,
                onClick: () => handlePreview(reminder),
              },
              permissions.delete && {
                name: ControlButtonGroupName.DELETE,
                onClick: () => handleDelete(reminder),
                isLoading: Object.keys(deletingRemindersMap).includes(
                  reminder.id,
                ),
              },
            ]
          : []
      }
      originBusinessId={
        hasOneReminder || !hasDifferedBusinessEntries
          ? reminder.businessId
          : undefined
      }
      title={title}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {hasOneReminder ? (
          <OneReminder reminder={reminder} />
        ) : (
          <MultipleReminders
            enableBusinessShareIcon={hasDifferedBusinessEntries}
            reminders={reminders}
            onDelete={permissions.delete ? handleDelete : undefined}
            onPreview={handlePreview}
            onUpdate={handleUpdate}
          />
        )}
      </TimelineCardContent>
    </TimelineCard>
  )
}

export default ReminderCard
