import { gql } from '@apollo/client'

export const UPDATE_APPOINTMENT_NOTES = gql`
  mutation UpdateAppointment(
    $businessId: ID
    $id: ID!
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(businessId: $businessId, id: $id, input: $input) {
      id
      notes
    }
  }
`
