import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CustomFieldValidatorState,
  LanguageUtils,
  NamedEntity,
  PermissionArea,
  PuiSelect,
  PuiTextArea,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import PercentTextField from '~/components/common/inputs/PercentTextField'
import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  getExpirationTimeUnits,
  getFeatureToggle,
  getFinalizeTimeUnits,
} from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeInvoiceEstimateConfigurationFields,
} from '~/types'
import { isBool } from '~/utils'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import { usePracticeDetailsFields } from '../../usePracticeDetailsFields'

const useStyles = makeStyles(
  () => ({
    numericInput: {
      width: 50,
    },
  }),
  { name: 'InvoiceConfigurationSection' },
)

const getFormattedValue = (field: number | undefined) =>
  typeof field === 'number' && field > 0 ? field * 100 : ''

export interface InvoiceConfigurationSectionProps
  extends BasePracticeDetailsSectionProps {}

const InvoiceConfigurationSection = ({
  business,
}: InvoiceConfigurationSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const isNewStatusFlowEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  const { invoiceConfigurationsFields } = usePracticeDetailsFields(business.id)

  const FinalizeTimeUnitsFromStore: NamedEntity[] =
    useSelector(getFinalizeTimeUnits)
  const ExpirationTimeUnitsFromStore: NamedEntity[] = useSelector(
    getExpirationTimeUnits,
  )

  const DayTimeUnit = Utils.findConstantIdByName(
    'Day',
    FinalizeTimeUnitsFromStore,
  )

  const FinalizeTimeUnits = FinalizeTimeUnitsFromStore.map((unit) => ({
    ...unit,
    name: t('Common:SOMETHING_ONE_OR_OTHER', {
      something: LanguageUtils.getTranslatedFieldName(unit),
    }),
  }))

  const YearTimeUnit = Utils.findConstantIdByName(
    'Year',
    ExpirationTimeUnitsFromStore,
  )

  const ExpirationTimeUnits = ExpirationTimeUnitsFromStore.map((unit) => ({
    ...unit,
    name: t('Common:SOMETHING_ONE_OR_OTHER', {
      something: LanguageUtils.getTranslatedFieldName(unit),
    }),
  }))

  const estimateExpirationOffsetValidator = ({
    state,
    value,
  }: CustomFieldValidatorState) => {
    const isYear = state.estimateExpirationTimeUnitId === YearTimeUnit

    return isYear ? value <= 1 : true
  }

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'showDeclined',
        type: 'toggle',
        initialValue: isBool(business.invoiceShowDeclinedItems)
          ? business.invoiceShowDeclinedItems
          : true,
      },
      {
        name: 'estimateRequiresDeposit',
        type: 'toggle',
        initialValue: isBool(business.estimateRequiresDeposit)
          ? business.estimateRequiresDeposit
          : false,
      },
      {
        name: 'estimateRequiredDepositPerc',
        initialValue:
          getFormattedValue(business.estimateRequiredDepositPerc) || '',
      },
      {
        name: 'invoiceFootnotes',
        label: t('Businesses:INVOICE.INVOICE_FOOTNOTES'),
        initialValue: business.invoiceNotes || '',
      },
      {
        name: 'estimateFootnotes',
        label: t('Businesses:INVOICE.ESTIMATE_FOOTNOTES'),
        initialValue: business.estimateNotes || '',
      },
      {
        name: 'invoiceAutoPostEnabled',
        type: 'toggle',
        initialValue: isBool(business.invoiceAutoPostEnabled)
          ? business.invoiceAutoPostEnabled
          : false,
      },
      {
        name: 'invoiceAutoPostOffset',
        initialValue: business.invoiceAutoPostOffset ?? 30,
      },
      {
        name: 'invoiceAutoPostTimeUnitId',
        initialValue: business.invoiceAutoPostTimeUnitId ?? DayTimeUnit,
      },
      {
        name: 'estimateExpiration',
        type: 'toggle',
        initialValue: business.estimateExpiration ?? false,
      },
      {
        name: 'estimateExpirationOffset',
        initialValue: business.estimateExpirationOffset ?? 1,
        validators: [
          {
            validator: estimateExpirationOffsetValidator,
            validatorName: 'estimateExpirationOffset',
          },
        ],
        messages: {
          estimateExpirationOffset: t(
            'Validations:ESTIMATE_EXPIRATION_OFFSET_DURATION',
          ),
        },
      },
      {
        name: 'estimateExpirationTimeUnitId',
        initialValue: business.estimateExpirationTimeUnitId ?? YearTimeUnit,
      },
      {
        name: 'estimateSignatureOnApproval',
        type: 'toggle',
        initialValue: Boolean(business.estimateSignatureOnApproval),
      },
    ],
    false,
  )

  const {
    showDeclined,
    estimateRequiresDeposit,
    estimateRequiredDepositPerc,
    invoiceFootnotes,
    estimateFootnotes,
    invoiceAutoPostEnabled,
    invoiceAutoPostOffset,
    invoiceAutoPostTimeUnitId,
    estimateExpiration,
    estimateExpirationOffset,
    estimateExpirationTimeUnitId,
    estimateSignatureOnApproval,
  } = fields

  usePracticeFieldsSection<PracticeInvoiceEstimateConfigurationFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION,
    validate,
    reset,
    parsedFields: {
      invoiceShowDeclinedItems: showDeclined.value,
      estimateRequiresDeposit: estimateRequiresDeposit.value,
      estimateRequiredDepositPerc: estimateRequiredDepositPerc.value / 100,
      invoiceNotes: invoiceFootnotes.value,
      estimateNotes: estimateFootnotes.value,
      invoiceAutoPostEnabled: invoiceAutoPostEnabled.value,
      invoiceAutoPostOffset: invoiceAutoPostOffset.value,
      invoiceAutoPostTimeUnitId: invoiceAutoPostTimeUnitId.value,
      estimateExpiration: estimateExpiration.value,
      estimateExpirationOffset: estimateExpirationOffset.value,
      estimateExpirationTimeUnitId: estimateExpirationTimeUnitId.value,
      estimateSignatureOnApproval: estimateSignatureOnApproval.value,
    },
  })

  useEffect(() => {
    if (
      invoiceConfigurationsFields.estimateSignatureOnApproval !==
      estimateSignatureOnApproval.value
    ) {
      estimateSignatureOnApproval.setValue(
        invoiceConfigurationsFields.estimateSignatureOnApproval,
      )
    }
  }, [invoiceConfigurationsFields.estimateSignatureOnApproval])

  useEffect(() => {
    if (estimateExpirationTimeUnitId.value === YearTimeUnit) {
      estimateExpirationOffset.setValue(1)
    }
  }, [estimateExpirationTimeUnitId.value])

  return (
    <>
      <Grid container item direction="column" mb={2}>
        <PuiSwitch
          disabled={!permissions.update}
          field={showDeclined}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Businesses:INVOICE.ANY_ITEMS_DECLINED_WILL_APPEAR_ON_INVOICE',
              )}
              variant="body"
            >
              {t('Businesses:INVOICE.SHOW_DECLINED_ITEMS')}
            </TextWithTooltip>
          }
        />
        <PuiSwitch
          disabled={!permissions.update}
          field={invoiceAutoPostEnabled}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Businesses:INVOICE.SET_TIMEFRAME_TO_LOCK_INVOICES',
              )}
              variant="body"
            >
              {t('Businesses:INVOICE.AUTO_POST_INVOICE_AFTER_SET_TIME')}
            </TextWithTooltip>
          }
        />
        {invoiceAutoPostEnabled.value && (
          <Grid
            container
            item
            alignItems="center"
            pl={6}
            spacing={1}
            wrap="nowrap"
          >
            <Grid item>
              <Text>{t('Common:POST')}</Text>
            </Grid>
            <Grid item>
              <NumericInput
                className={classes.numericInput}
                field={invoiceAutoPostOffset}
                min={0}
              />
            </Grid>
            <Grid item>
              <PuiSelect
                fullWidth
                disabled={!permissions.update}
                field={invoiceAutoPostTimeUnitId}
                items={FinalizeTimeUnits}
              />
            </Grid>
            <Grid item>
              <Text>
                {t(
                  'Businesses:INVOICE.AFTER_APPOINTMENT_END_DATE',
                ).toLowerCase()}
              </Text>
            </Grid>
          </Grid>
        )}
        <PuiSwitch
          disabled={!permissions.update}
          field={estimateRequiresDeposit}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Businesses:INVOICE.TURN_THIS_ON_TO_PUT_DEFAULT_DEPOSIT',
              )}
              variant="body"
            >
              {t('Businesses:INVOICE.ESTIMATE_REQUIRES_DEPOSIT')}
            </TextWithTooltip>
          }
        />
        {estimateRequiresDeposit.value && (
          <Grid item ml={6}>
            <PercentTextField field={estimateRequiredDepositPerc} />
          </Grid>
        )}
        <PuiSwitch
          disabled={!permissions.update}
          field={estimateExpiration}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t('Businesses:INVOICE.ESTIMATE_EXPIRATION_TOOLTIP')}
              variant="body"
            >
              {t('Businesses:INVOICE.ESTIMATE_EXPIRATION')}
            </TextWithTooltip>
          }
        />
        {estimateExpiration.value && (
          <Grid
            container
            item
            alignItems="center"
            pl={6}
            spacing={1}
            wrap="nowrap"
          >
            <Grid item>
              <Text>{t('Businesses:INVOICE.ESTIMATE_EXPIRES')}</Text>
            </Grid>
            <Grid item>
              <NumericInput
                className={classes.numericInput}
                field={estimateExpirationOffset}
                min={0}
              />
            </Grid>
            <Grid item>
              <PuiSelect
                fullWidth
                disabled={!permissions.update}
                field={estimateExpirationTimeUnitId}
                items={ExpirationTimeUnits}
                renderEmpty={false}
              />
            </Grid>
            <Grid item>
              <Text>{t('Businesses:INVOICE.FROM_CREATION').toLowerCase()}</Text>
            </Grid>
          </Grid>
        )}
        {isNewStatusFlowEnabled && (
          <PuiSwitch
            disabled={!permissions.update}
            field={estimateSignatureOnApproval}
            label={
              <TextWithTooltip
                allowWrap
                tooltipText={t(
                  'Businesses:INVOICE.ESTIMATE_REQUIRES_SIGNATURE_ON_APPROVAL_TOOLTIP',
                )}
                variant="body"
              >
                {t(
                  'Businesses:INVOICE.ESTIMATE_REQUIRES_SIGNATURE_ON_APPROVAL',
                )}
              </TextWithTooltip>
            }
          />
        )}
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs>
          <PuiTextArea
            disabled={!permissions.update}
            field={invoiceFootnotes}
            label={invoiceFootnotes.label}
            labelProps={{
              tooltipText: t(
                'Businesses:INVOICE.THESE_NOTES_WILL_APPEAR_EVERY_INVOICE',
              ),
            }}
          />
        </Grid>
        <Grid item xs>
          <PuiTextArea
            disabled={!permissions.update}
            field={estimateFootnotes}
            label={estimateFootnotes.label}
            labelProps={{
              tooltipText: t(
                'Businesses:INVOICE.THESE_NOTES_WILL_APPEAR_EVERY_ESTIMATE',
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default memo(InvoiceConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
