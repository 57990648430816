import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  LanguageUtils,
  NamedEntity,
  Nil,
  PuiCheckbox,
  PuiSelect,
  PuiTextField,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  AlertColorLevel,
  DEF_BUSINESS_COLOR_ID,
  useGetAlertColorsWithBusinessDefault,
} from '~/constants/alertColors'
import { getFullAlertType } from '~/store/reducers/constants'
import { AlertsConfig } from '~/types'

const useStyles = makeStyles(
  () => ({
    otherField: {
      width: 200,
    },
  }),
  { name: 'AddAlerts' },
)

export interface AddAlertsHandle {
  getAlertsConfig: () => AlertsConfig
}
export interface AddAlertProps {
  alertColorId: string | Nil
  alerts: NamedEntity[]
  customAlertType: string
}

const AddAlerts = forwardRef<AddAlertsHandle, AddAlertProps>(function AddAlerts(
  {
    alertColorId: alertColorIdProp,
    alerts: currentAlerts = [],
    customAlertType = '',
  },
  ref,
) {
  const classes = useStyles()
  const FullAlertType: NamedEntity[] = useSelector(getFullAlertType)
  const { t } = useTranslation('Common')

  const [alerts, setAlerts] = useState(currentAlerts)
  const [alertColorId, setAlertColorId] = useState(
    alertColorIdProp || DEF_BUSINESS_COLOR_ID,
  )
  const [otherSelected, setOtherSelected] = useState(Boolean(customAlertType))
  const [otherAlert, setOtherAlert] = useState(customAlertType)

  const getAlertsConfig = () => ({
    alerts,
    alertTypesIds: R.pluck('id', alerts),
    customAlertType: otherSelected ? otherAlert : '',
    alertColorId: alertColorId === DEF_BUSINESS_COLOR_ID ? null : alertColorId,
  })

  useImperativeHandle(ref, () => ({
    getAlertsConfig,
  }))

  const alertColors = useGetAlertColorsWithBusinessDefault(
    AlertColorLevel.PATIENT,
  )

  return (
    <Grid container direction="column" justifyContent="center">
      {FullAlertType.map((entity) => (
        <Grid item key={entity.id} xs={12}>
          <PuiCheckbox
            checked={Boolean(Utils.findById(entity.id, alerts))}
            label={LanguageUtils.getConstantTranslatedName(
              entity.id,
              FullAlertType,
            )}
            onChange={(event, checked) =>
              setAlerts(
                checked ? alerts.concat(entity) : R.without([entity], alerts),
              )
            }
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <PuiCheckbox
          checked={otherSelected}
          label={t('Common:OTHER')}
          onChange={(event, checked) => setOtherSelected(checked)}
        />
        <PuiTextField
          className={classes.otherField}
          disabled={!otherSelected}
          inputProps={{ maxLength: 100 }}
          margin="none"
          value={otherAlert}
          onChange={Utils.handleFormTextInput(setOtherAlert)}
        />
      </Grid>
      <Grid item mt={1} xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="alert-color-select">
            {t('Common:ALERT_COLOR')}
          </InputLabel>
          <PuiSelect
            input={<Input id="alert-color-select" />}
            items={alertColors}
            label={t('Common:COLOR')}
            renderEmpty={false}
            value={alertColorId}
            onChange={Utils.handleFormSelectInput(setAlertColorId)}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
})

export default AddAlerts
