import * as API from '~/api'

// @ts-ignore
import createDuck from './duck-generators/createDuck'
// @ts-ignore
import listDuck, { INITIAL_STATE as listInitialState } from './duck-generators/list'

const patientMembershipsDuck = createDuck({
  name: 'patientMemberships',
  duck: listDuck,
  apiEndpoints: (types: Record<string, string>) => ({
    [types.FETCH_LIST]: API.fetchPatientMembershipsList,
    [types.FETCH_MORE_ITEMS_FOR_LIST]: API.fetchPatientMembershipsList,
    [types.REFRESH_LIST]: API.fetchPatientMembershipsList,
    [types.FETCH_ITEM]: API.fetchPatientMembership,
    [types.FETCH_PAUSED_LIST]: API.fetchPausedPatientMembershipsList,
  }),
})

export const patientMembershipsReducer = patientMembershipsDuck.reducer
export const patientMembershipsSaga = patientMembershipsDuck.saga
export const patientMembershipsSelectors = patientMembershipsDuck.selectors
export const patientMembershipsActions = patientMembershipsDuck.actions
export const PatientMembershipsInitialState = listInitialState
export type PatientMembershipsState = {
  list: string[],
  map: Record<string, any>,
  isLoading: boolean,
  isFetching: boolean,
  error: string | null,
  totalCount: number,
}

