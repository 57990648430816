import { convertFromHTML } from 'draft-convert'
import { CHECKABLE_LIST_ITEM } from 'draft-js-checkable-list-item'
import { PlaceholderConstant } from '@pbt/pbt-ui-components'

import type { PlaceholderData } from './convertToHTML'
import { LINK } from './decorator/link'
import { PLACEHOLDER } from './decorator/placeholder'
import { BLOCK_TYPE_SIGNATURE } from './decorator/signature'
import type { PlaceholderString } from './placeholderUtils'
import {
  deserializeLocalePlaceholder,
  getStringFromPlaceholder,
  serializePlaceholder,
} from './placeholderUtils'

export default (htmlString: string, Placeholders: PlaceholderConstant[]) =>
  convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'span' && node.className === PLACEHOLDER) {
        const shownText = getStringFromPlaceholder(
          node.innerText as PlaceholderString,
        )
        const localizedPlaceholder = deserializeLocalePlaceholder(
          Placeholders,
          shownText,
        )
        const serializedText = serializePlaceholder(Placeholders, shownText)

        return createEntity(PLACEHOLDER, 'IMMUTABLE', {
          time: Date.now(),
          serializedText,
          deserializedText: localizedPlaceholder,
        } as PlaceholderData)
      }
      if (nodeName === 'table' && node.className === BLOCK_TYPE_SIGNATURE) {
        return createEntity(BLOCK_TYPE_SIGNATURE, 'IMMUTABLE', {
          time: Date.now(),
        })
      }
      if (nodeName === 'a' && node.className === LINK) {
        return createEntity(LINK, 'IMMUTABLE', {
          time: Date.now(),
          url: node.href,
        })
      }

      return undefined
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === 'figure') {
        return {
          type: 'atomic',
          data: {},
        }
      }
      if (nodeName === 'div' && node.className === CHECKABLE_LIST_ITEM) {
        const checkbox = node.children[0] as HTMLInputElement
        return {
          type: CHECKABLE_LIST_ITEM,
          data: {
            checked: Boolean(checkbox.checked),
          },
        }
      }
      return undefined
    },
  })(htmlString)
