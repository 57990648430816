/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { BaseUser, Nil, Text, Utils } from '@pbt/pbt-ui-components'

import {
  Appointment,
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { Invoice, InvoiceEvent } from '~/types'
import { useGetInvoiceSectionsWithLimit } from '~/utils/finance'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

interface InvoiceTableDoctorCellProps {
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

const boxProps = {
  alignItems: 'center',
  display: 'flex',
  minHeight: CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT,
  width: '100%',
}

export const InvoiceTableDoctorCell = ({
  invoice,
  multipleRowsLimit,
}: InvoiceTableDoctorCellProps) => {
  const sections =
    useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit) || []

  const getAssignedVet = (event: InvoiceEvent | Appointment | Nil) => {
    if (R.isNil(event)) {
      return ''
    }

    if ('assignedVet' in event) {
      return Utils.getPersonString(event.assignedVet as BaseUser)
    }

    const assignedVetId = event?.assignedVetId
    const veterinarian = event?.resources?.find(
      (resource) => resource.employee.id === assignedVetId,
    )?.employee
    const assignedVet = Utils.getPersonString(veterinarian as BaseUser)
    return assignedVet
  }

  if (sections && !R.isEmpty(sections)) {
    return (
      <>
        {sections.map(({ event, id }, index) => {
          const assignedVet = getAssignedVet(event)

          if (!assignedVet) {
            return <Box {...boxProps} key={`${id}_${index}`} />
          }

          return (
            <Box {...boxProps} key={`${id}_${event?.id}_${index}`}>
              <Text variant="body2">{assignedVet}</Text>
            </Box>
          )
        })}
      </>
    )
  }

  if ('event' in invoice && invoice.event) {
    return <Text variant="body2">{getAssignedVet(invoice.event)}</Text>
  }

  return null
}
