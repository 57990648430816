import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Nil } from '@pbt/pbt-ui-components'

import { getPatientHasNoMembership } from '~/components/dashboard/wellness-plans/wellnessPlanUtils'
import { OrderType } from '~/constants/SOAPStates'
import { getPatient } from '~/store/reducers/patients'
import { getOrderFilters, getPatientId } from '~/store/reducers/soap'
import { BaseChargeTabProps, OrderListType } from '~/types'
import { hasOrderFilter } from '~/utils/orderUtils'

import MembershipSignUpIframe from '../../../../membership/MembershipSignUpIframe'
import ChargeListWithFilters from '../ChargeListWithFilters'

const useStyles = makeStyles(
  () => ({
    frame: {
      display: 'flex',
      width: '100%',
      height: 500,
    },
  }),
  { name: 'WellnessChargeTab' },
)

export interface WellnessChargeTabProps extends BaseChargeTabProps {
  clientId: string | Nil
}

const WellnessChargeTab = ({
  disabled,
  clientId,
  handleEditListItem,
  handleRefill,
  handleSavePrescription,
  logItemStateGetter,
  openPrescriptionDialog,
}: WellnessChargeTabProps) => {
  const classes = useStyles()
  const orderFilters = useSelector(getOrderFilters)
  const patientId = useSelector(getPatientId)
  const patient = useSelector(getPatient(patientId))

  const hasNoMembership = getPatientHasNoMembership(patient)
  const hasWellnessPlan = hasOrderFilter(OrderType.WPLANS, orderFilters)

  if (!hasWellnessPlan && hasNoMembership) {
    return (
      <MembershipSignUpIframe
        className={classes.frame}
        clientId={clientId}
        patientId={patientId}
      />
    )
  }

  return (
    <ChargeListWithFilters
      clientId={clientId}
      disabled={disabled}
      handleEditListItem={handleEditListItem}
      handleRefill={handleRefill}
      handleSavePrescription={handleSavePrescription}
      logItemStateGetter={logItemStateGetter}
      openPrescriptionDialog={openPrescriptionDialog}
      preselectedFilter={OrderType.WPLANS}
      preselectedListType={OrderListType.OUR}
    />
  )
}

export default WellnessChargeTab
