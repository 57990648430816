import React from 'react'
import { useTranslation } from 'react-i18next'
import { Radio } from '@mui/material'
import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import { RadioGroupState } from '~/types'

import { SelectionGroupDetails } from './SelectionGroupDetails'

const InputComponent = () => <Radio disabled sx={{ paddingLeft: 0 }} />

interface DocumentRadioGroupDialogProps extends BasePuiDialogProps {
  documentElement?: RadioGroupState
  onSave: (data: RadioGroupState) => void
}

// eslint-disable-next-line react/no-multi-comp
export const DocumentRadioGroupDialog = ({
  open,
  onClose,
  onSave,
  documentElement,
}: DocumentRadioGroupDialogProps) => {
  const { t } = useTranslation('Dialogs')

  return (
    <SelectionGroupDetails
      InputComponent={InputComponent}
      documentElement={documentElement}
      header={t('Dialogs:DOCUMENT_RADIO_GROUP_DIALOG.ADD_MULTIPLE_CHOICE')}
      open={open}
      subheader={t(
        'Dialogs:DOCUMENT_RADIO_GROUP_DIALOG.USER_CAN_SELECT_ONLY_ONE_OPTION',
      )}
      onClose={onClose}
      onSave={onSave}
    />
  )
}
