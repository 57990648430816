import { gql } from '@apollo/client'

export const FETCH_PATIENT = gql`
  query FetchPatient($patientId: ID!) {
    patient(id: $patientId) {
      id
      clientId
      businessId
      name
      photo
      photoThumbnail
      genderEntity {
        id
        name
        createdAt
        updatedAt
      }
      spayedNeuteredStatus
      spayedNeutered
      dateOfBirth
      acquiredDate
      approximateDateOfBirth
      microchipNumber
      externalId
      active
      displayId
    }
  }
`
