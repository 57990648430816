import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Fab, Grid, LinearProgress, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  CircularProgressOverlay,
  Nil,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { useGetPaymentReversalOptions } from '~/components/dashboard/invoices/payment/hooks/useGetPaymentReversalOptions'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import PaymentType, {
  PAYMENT_TRANSACTION_STATE,
  PaymentTypesLabel,
} from '~/constants/paymentTypes'
import { RhapsodyGoPaymentMethod } from '~/constants/RhapsodyGoPaymentMethod'
import { VOIDED } from '~/constants/rhapsodyGoPaymentsStates'
import {
  editGoPayment,
  editGoStripePayment,
  editPayment,
  settleGoStripeTransaction,
  settleGoTransaction,
} from '~/store/actions/payments'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  getFeatureToggle,
  getPaymentTransactionState,
} from '~/store/reducers/constants'
import {
  getFinanceIsFetching,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import { fetchInvoiceV3, getInvoiceV3Id } from '~/store/reducers/invoiceV3'
import { getPayment, getPaymentsIsLoading } from '~/store/reducers/payments'
import { ExtendPayment, Invoice } from '~/types'
import {
  checkIsCreditAdjustment,
  checkIsDebitAdjustment,
  extractInvoicesByType,
  getDerrivedPaymentType,
} from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import PaymentDetailsChunk from './PaymentDetailsChunk'
import PaymentDetailsRefundButtonMenu from './PaymentDetailsRefundButtonMenu'
import { ReverseButtonWrapper } from './ReverseButtonWrapper'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginRight: theme.spacing(2),
      height: 40,
    },
    footer: {
      borderTop: theme.constants.tabBorder,
    },
    roundedButton: {
      borderRadius: 23.5,
      textTransform: 'none',
      fontWeight: 500,
    },
    progress: {
      height: theme.constants.progressBarHeight,
    },
    hidden: {
      visibility: 'hidden',
    },
    header: {
      borderBottom: theme.constants.tabBorder,
    },
    paymentHeader: {
      borderBottom: theme.constants.tabBorder,
    },
    creditHeader: {
      borderBottom: `1px solid ${theme.colors.important}`,
    },
  }),
  { name: 'PaymentDetails' },
)

interface PaymentDetailsProps {
  clientId: string | Nil
  forceNotNavigateToBalanceAfterRefund?: boolean
  invoiceId?: string | Nil
  isPossibleToViewInvoice?: boolean
  navigateToRefundLanding?: () => void
  onClose: () => void
  onRefresh?: () => void
  payment: ExtendPayment
}

const PaymentDetails = ({
  clientId,
  isPossibleToViewInvoice = true,
  onClose,
  onRefresh,
  payment,
  navigateToRefundLanding,
  forceNotNavigateToBalanceAfterRefund,
  invoiceId: invoiceIdProp,
}: PaymentDetailsProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices', 'Payments'])

  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openUndoPaymentDialog] = useDialog(DialogNames.UNDO_PAYMENT)
  const [openRefundPaymentDialog, onRefundPaymentDialogClose] = useDialog(
    DialogNames.REFUND_PAYMENT,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const financeIsLoading = useSelector(getFinanceIsLoading)
  const paymentsIsLoading = useSelector(getPaymentsIsLoading)
  const isFetching = useSelector(getFinanceIsFetching)
  const PaymentTransactionState = useSelector(getPaymentTransactionState)
  const originPayment = useSelector(getPayment(payment.originPaymentId))
  const currentInvoiceId = useSelector(getInvoiceV3Id)
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isIpoM0VoidAndPaymentReversalFTEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_VOID_AND_PAYMENT_REVERSAL),
  )
  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )

  const {
    canReverse,
    canRefundNonIntegratedPayments,
    canRefundGoOrPos,
    canReverseGoOrPos,
  } = useGetPaymentReversalOptions()(payment)

  const renderExtendedPaymentCompleteModal =
    isIpoM0VoidAndPaymentReversalFTEnabled &&
    (payment?.paymentType === PaymentType.REVERSE_CHARGE ||
      payment?.paymentType === PaymentType.REFUND ||
      payment?.paymentType === PaymentType.UNDO ||
      payment?.paymentType === PaymentType.VOID) &&
    Boolean(originPayment)
  const renderExtendedRefundPayments =
    isIpoM0VoidAndPaymentReversalFTEnabled &&
    !R.isEmpty(payment?.refundPayments)

  const [paymentNotes, setPaymentNotes] = useState('')

  const ErrorType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.ERROR,
    PaymentTransactionState,
  )

  const AuthorizedType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.AUTHORIZED,
    PaymentTransactionState,
  )

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const isGoPayment = payment.type === PaymentType.GO_PAYMENT
  const isStripePayment = payment.type === PaymentType.GO_STRIPE_PAYMENT
  const loading = paymentsIsLoading || financeIsLoading
  const isFailedPayment = payment.transactionState === ErrorType
  const haveNotesChanged = paymentNotes !== payment.notes
  const { invoices: paymentInvoices } = extractInvoicesByType(payment)

  const hasPendingRefundInvoice = paymentInvoices.some(
    (invoice) => invoice.pendingRefundInvoiceId,
  )

  const isPaymentRefundOrReverse =
    payment?.paymentType === PaymentType.REVERSE_CHARGE ||
    payment?.paymentType === PaymentType.REFUND
  const isGoPaymentNoRefundFlag =
    (payment?.paymentType === PaymentType.GO_PAYMENT &&
      !payment?.refundAvailable) ||
    (payment?.paymentType === PaymentType.GO_STRIPE_PAYMENT &&
      !payment?.refundAvailable)
  const paymentRefundableAmount = payment?.refundableAmount ?? 0
  const isRefundButtonDisabled =
    loading ||
    isPaymentRefundOrReverse ||
    !paymentInvoices.length ||
    paymentRefundableAmount <= 0 ||
    isGoPaymentNoRefundFlag ||
    payment?.paymentType === PaymentType.IMPORTED

  const isAuth =
    payment &&
    payment.transactionState === AuthorizedType &&
    payment.paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD

  const callbackOnSaveNotes = useCloseAfterCreation(
    (callback) => callback(),
    getFinanceIsLoading,
  )

  const refreshCurrentInvoice = () => {
    if (
      invoiceIdProp &&
      isChargeSheetEnabled &&
      invoiceIdProp === currentInvoiceId
    ) {
      dispatch(fetchInvoiceV3({ id: invoiceIdProp }))
    }
  }

  const voidPayment = () => {
    onClose()
    if (!isChargeSheetEnabled) {
      navigate(`/balance/${clientId}`, { replace: true })
    }
    openAddPaymentDialog({
      payment: { ...payment, notes: paymentNotes },
      isVoid: true,
      clientId,
      onOk: () => {
        refreshCurrentInvoice()
        onRefresh?.()
      },
    })
  }

  const settle = () => {
    const {
      amount,
      businessId,
      goTxId,
      clientId: paymentClientId,
      person,
      id,
    } = payment
    const transactionToSettle = {
      amount,
      businessId,
      goTxId: goTxId ?? id,
      personId: paymentClientId,
      userId: person?.id,
    }

    if (isStripePayment) {
      dispatch(settleGoStripeTransaction(transactionToSettle))
    } else {
      dispatch(settleGoTransaction(transactionToSettle))
    }
    onRefresh?.()
  }

  const refundGoPayPayment = () => {
    onClose()
    if (
      R.isNil(forceNotNavigateToBalanceAfterRefund) ||
      !forceNotNavigateToBalanceAfterRefund
    ) {
      navigate(`/balance/${clientId}`, { replace: true })
    }
    openAddPaymentDialog({
      payment: { ...payment, notes: paymentNotes },
      isRefund: true,
      clientId,
      ...(onRefresh ? { onOk: onRefresh } : {}),
    })
  }

  const reverseGoPayPayment = () => {
    onClose()
    if (
      R.isNil(forceNotNavigateToBalanceAfterRefund) ||
      !forceNotNavigateToBalanceAfterRefund
    ) {
      navigate(`/balance/${clientId}`, { replace: true })
    }
    openAddPaymentDialog({
      payment: { ...payment, notes: paymentNotes },
      isReverseGo: true,
      clientId,
      ...(onRefresh ? { onOk: onRefresh } : {}),
    })
  }

  const reversePayment = () => {
    onClose()
    openAddPaymentDialog({
      payment: { ...payment, notes: paymentNotes },
      isReversePayment: true,
      clientId,
      ...(onRefresh ? { onOk: onRefresh } : {}),
    })
  }

  const refund = () => {
    onClose()
    openRefundPaymentDialog({
      clientId,
      payment: { ...payment, notes: paymentNotes },
      onClose: () => {
        onRefundPaymentDialogClose()
        onRefresh?.()
      },
    })
  }

  const undo = () => {
    onClose()
    openUndoPaymentDialog({
      clientId,
      payment,
      onOk: () => {
        refreshCurrentInvoice()
        onRefresh?.()
      },
    })
  }

  const savePayment = () => {
    if (isGoPayment) {
      dispatch(
        editGoPayment(payment.goTxId || payment.id, {
          notes: paymentNotes,
        }),
      )
    } else if (isStripePayment) {
      dispatch(
        editGoStripePayment(payment.goStripeTxId || payment.id, {
          notes: paymentNotes,
        }),
      )
    } else {
      dispatch(editPayment(payment.id, { notes: paymentNotes }))
    }
  }

  const saveNotes = (callback: () => void) => () => {
    const areNotesMatching = paymentNotes === payment.notes

    if (areNotesMatching) {
      callback()
    } else {
      callbackOnSaveNotes(callback)
      savePayment()
    }
  }

  const clickRefundButton = (invoice: Invoice) => {
    if (!invoice?.id) return

    const isEstimate = invoice.type === InvoiceType.ESTIMATE

    if (
      isIpoM0InvoiceRefundsEnabled &&
      navigateToRefundLanding &&
      !isEstimate
    ) {
      if (invoice.pendingRefundInvoiceId) {
        navigate(`/refund/${invoice.pendingRefundInvoiceId}`)
      } else {
        navigateToRefundLanding()
      }
    } else {
      openInvoice({
        clientId,
        invoiceId: invoice.id,
        isEstimate,
        chargesEntityType: isEstimate
          ? InvoiceType.ESTIMATE
          : InvoiceType.INVOICE,
        id: invoice.id,
      })
    }

    onClose()
  }

  const getPaymentStatus = R.cond<any, string>([
    [
      R.propEq('transactionState', ErrorType),
      R.always(t('Common:STATUS_FAILED')),
    ],
    [R.propOr(false, 'partial'), R.always(t('Common:STATUS_APPLIED'))],
    [
      (paymentToCheck: ExtendPayment) => R.isNil(paymentToCheck.voidAvailable),
      R.always(t('Common:STATUS_COMPLETED')),
    ],
    [R.propOr(false, 'voidAvailable'), R.always(t('Common:STATUS_APPROVED'))],
    [R.T, R.always(t('Common:STATUS_COMPLETED'))],
  ])

  const isPayment = payment?.paymentType === PaymentType.PAYMENT
  const isDeposit = payment?.paymentType === PaymentType.DEPOSIT
  const isRefund = payment?.paymentType === PaymentType.REVERSE_CHARGE
  const isUndo = payment?.paymentType === PaymentType.UNDO
  const isVoid = payment?.state === VOIDED

  const isCreditAdjustment = checkIsCreditAdjustment(payment)
  const isDebitAdjustment = checkIsDebitAdjustment(payment)

  const paymentStatus = getPaymentStatus(payment).toLowerCase()
  const isPaymentHeader =
    isPayment || isDeposit || isRefund || isUndo || isCreditAdjustment

  const paymentTypeLabel = getDerrivedPaymentType({
    paymentType: payment?.paymentType,
    isRefund: isRefund && !(payment?.reversed ?? false),
    isReversal: isRefund && (payment?.reversed ?? false),
    isVoid,
    isAuth,
    isCreditAdjustment,
    isDebitAdjustment,
    isUndo,
    isIpoM0VoidAndPaymentReversalFTEnabled,
  })

  const getRefundTooltipMessage = (): string | undefined => {
    if (payment?.paymentType === PaymentType.IMPORTED) {
      return t(
        'Invoices:PAYMENTS.PAYMENT_DETAILS.REFUND_BUTTON.MIGRATED_BALANCE',
      )
    }
    if (
      isPaymentRefundOrReverse ||
      isGoPaymentNoRefundFlag ||
      paymentRefundableAmount <= 0
    ) {
      return t(
        'Invoices:PAYMENTS.PAYMENT_DETAILS.REFUND_BUTTON.REVERSAL_OR_REFUND_COMPLETED',
      )
    }
    if (!paymentInvoices.length) {
      return t(
        'Invoices:PAYMENTS.PAYMENT_DETAILS.REFUND_BUTTON.NO_INVOICE_OR_ESTIMATE_ASSOCIATED',
      )
    }
    return undefined
  }

  const refundButtonTooltipMessage = getRefundTooltipMessage()

  return (
    <Grid container direction="column">
      <CircularProgressOverlay
        open={isFetching || loading}
        preloaderText={t('Common:LOADING_PAYMENTS')}
      />
      <Grid
        item
        className={classNames(classes.header, {
          [classes.paymentHeader]: isPaymentHeader,
          [classes.creditHeader]: !isPaymentHeader,
        })}
        pb={1}
        pt={3}
        px={3}
      >
        {renderExtendedPaymentCompleteModal ? (
          <Text strong variant="h2">
            {t('Invoices:PAYMENTS.PAYMENT_DETAILS.PAYMENT_DETAILS')}
          </Text>
        ) : (
          <Text strong variant="h2">
            {`${
              paymentTypeLabel && PaymentTypesLabel[paymentTypeLabel]
            } ${paymentStatus}`}
          </Text>
        )}
      </Grid>
      <LinearProgress
        className={classNames(classes.progress, {
          [classes.hidden]: !loading,
        })}
      />
      {renderExtendedPaymentCompleteModal ? (
        <>
          <PaymentDetailsChunk
            areNotesEditable
            isPaymentReversal
            clientId={clientId}
            isPaymentRefundOrReverse={renderExtendedPaymentCompleteModal}
            isPossibleToViewInvoice={isPossibleToViewInvoice}
            notes={paymentNotes}
            payment={payment as ExtendPayment}
            setNotes={setPaymentNotes}
            onClose={onClose}
          />
          <PaymentDetailsChunk
            clientId={clientId}
            isPaymentRefundOrReverse={renderExtendedPaymentCompleteModal}
            isPaymentReversal={false}
            isPossibleToViewInvoice={isPossibleToViewInvoice}
            payment={originPayment as ExtendPayment}
            onClose={onClose}
          />
        </>
      ) : (
        <>
          <PaymentDetailsChunk
            areNotesEditable
            clientId={clientId}
            isPaymentRefundOrReverse={renderExtendedRefundPayments}
            isPaymentReversal={false}
            isPossibleToViewInvoice={isPossibleToViewInvoice}
            notes={paymentNotes}
            payment={payment as ExtendPayment}
            setNotes={setPaymentNotes}
            onClose={onClose}
          />
          {renderExtendedRefundPayments &&
            payment?.refundPayments?.map((refundPayment) => (
              <PaymentDetailsChunk
                isPaymentReversal
                clientId={clientId}
                isPaymentRefundOrReverse={renderExtendedRefundPayments}
                isPossibleToViewInvoice={isPossibleToViewInvoice}
                key={refundPayment.id}
                payment={refundPayment as ExtendPayment}
                onClose={onClose}
              />
            ))}
        </>
      )}

      <Grid container item className={classes.footer} px={3} py={2}>
        {!renderExtendedPaymentCompleteModal && (
          <>
            {!canReverseGoOrPos && (
              <Fab
                className={classes.button}
                color="inherit"
                disabled={loading}
                variant="extended"
                onClick={onClose}
              >
                {t('Common:CLOSE_ACTION')}
              </Fab>
            )}
            {payment.voidAvailable && !isFailedPayment && (
              <Button
                className={classNames(classes.button, classes.roundedButton)}
                color="primary"
                disabled={loading}
                variant="outlined"
                onClick={saveNotes(voidPayment)}
              >
                {t('Common:VOID_ACTION')}
              </Button>
            )}
            {isAuth && (
              <Button
                className={classNames(classes.button, classes.roundedButton)}
                color="primary"
                disabled={loading}
                variant="outlined"
                onClick={saveNotes(settle)}
              >
                {t('Common:PAYMENTS.SETTLE_ACTION')}
              </Button>
            )}
            {canReverse && (
              <ReverseButtonWrapper
                hasPendingRefundInvoice={hasPendingRefundInvoice}
              >
                <Button
                  className={classNames(classes.button, classes.roundedButton)}
                  color="primary"
                  disabled={loading || hasPendingRefundInvoice}
                  variant="outlined"
                  onClick={saveNotes(
                    isIpoM0VoidAndPaymentReversalFTEnabled
                      ? reversePayment
                      : undo,
                  )}
                >
                  {isIpoM0VoidAndPaymentReversalFTEnabled
                    ? t('Common:PAYMENTS.REVERSE_PAYMENT_ACTION')
                    : t('Common:UNDO_ACTION')}
                </Button>
              </ReverseButtonWrapper>
            )}
          </>
        )}
        {isIpoM0VoidAndPaymentReversalFTEnabled &&
        isIpoM0InvoiceRefundsEnabled ? (
          <>
            {!isRefundButtonDisabled && paymentInvoices.length > 1 ? (
              <PaymentDetailsRefundButtonMenu
                buttonClassNames={classNames(
                  classes.button,
                  classes.roundedButton,
                )}
                clickRefundButton={clickRefundButton}
                invoices={paymentInvoices}
              />
            ) : (
              <Tooltip placement="top" title={refundButtonTooltipMessage}>
                <span>
                  <Button
                    className={classNames(
                      classes.button,
                      classes.roundedButton,
                    )}
                    color="primary"
                    disabled={isRefundButtonDisabled}
                    variant="contained"
                    onClick={() => clickRefundButton(paymentInvoices[0])}
                  >
                    {t('Common:PAYMENTS.REFUND_ACTION')}
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {canRefundNonIntegratedPayments && (
              <Button
                className={classNames(classes.button, classes.roundedButton)}
                color="primary"
                disabled={loading}
                variant="contained"
                onClick={saveNotes(refund)}
              >
                {t('Common:PAYMENTS.REFUND_ACTION')}
              </Button>
            )}
            {canRefundGoOrPos && (
              <Button
                className={classNames(classes.button, classes.roundedButton)}
                color="primary"
                disabled={loading}
                variant="contained"
                onClick={saveNotes(refundGoPayPayment)}
              >
                {t('Common:PAYMENTS.REFUND_ACTION')}
              </Button>
            )}
          </>
        )}
        {isIpoM0VoidAndPaymentReversalFTEnabled && canReverseGoOrPos && (
          <ReverseButtonWrapper
            hasPendingRefundInvoice={hasPendingRefundInvoice}
          >
            <Button
              className={classNames(classes.button, classes.roundedButton)}
              color="primary"
              disabled={loading || hasPendingRefundInvoice}
              variant="outlined"
              onClick={saveNotes(reverseGoPayPayment)}
            >
              {t('Common:PAYMENTS.REVERSE_PAYMENT_ACTION')}
            </Button>
          </ReverseButtonWrapper>
        )}
        <Button
          className={classNames(classes.button, classes.roundedButton)}
          color="primary"
          disabled={!haveNotesChanged || loading}
          variant="outlined"
          onClick={() => {
            savePayment()
            onRefresh?.()
          }}
        >
          {t('Common:SAVE_ACTION')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default PaymentDetails
