import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AlertIconType,
  LanguageUtils,
  PuiAlert,
  PuiAlertProps,
} from '@pbt/pbt-ui-components'

import { getLabVendors } from '~/store/reducers/constants'

export interface DeclineLabOrderDialogProps extends PuiAlertProps {
  declineOrder: () => void
  onClose: () => void
  vendorId: string
}

const DeclineLabOrderDialog = ({
  vendorId,
  declineOrder,
  onClose,
  ...rest
}: DeclineLabOrderDialogProps) => {
  const LabVendors = useSelector(getLabVendors)
  const { t } = useTranslation(['Soap', 'Common'])

  const handleDecline = () => {
    declineOrder()
    onClose()
  }

  return (
    <PuiAlert
      cancelButtonText={t('Common:NO_KEEP_ORDER')}
      iconType={AlertIconType.WARN}
      message={t('Soap:ARE_YOU_SURE_YOU_WANT_TO_DECLINE_LAB_ORDER', {
        orderName: LanguageUtils.getConstantTranslatedName(
          vendorId,
          LabVendors,
        ),
      })}
      okButtonText={t('Common:YES_DECLINE_ORDER')}
      onCancel={onClose}
      onClose={onClose}
      onOk={handleDecline}
      {...rest}
    />
  )
}

export default DeclineLabOrderDialog
