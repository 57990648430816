import { Nil } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchWhiteboard = (businessId: string, date: string | Nil) =>
  request(
    getAPIPath('whiteboard'),
    { method: 'GET', params: { businessId, date, language: i18n.language } },
    true,
    Schema.schedules,
  )

export const fetchWhiteboardTreatments = (
  businessId: string,
  date: string | Nil,
  assignedTo: string,
  appointmentType: string,
) =>
  request(
    getAPIPath('whiteboard/treatments'),
    {
      method: 'GET',
      params: {
        businessId,
        date,
        assignedTo,
        appointmentType,
        language: i18n.language,
      },
    },
    true,
    Schema.treatmentsScheduleArray,
  )
