import * as R from 'ramda'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, BusinessRole, Defaults, User } from '@pbt/pbt-ui-components'
import { getPersonString } from '@pbt/pbt-ui-components/src/utils'

import * as API from '~/api'
import AssetDestination from '~/constants/AssetDestination'
import SnackNotificationType from '~/constants/SnackNotificationType'
import i18n from '~/locales/i18n'
import { BusinessRoleItem, TeamFilter } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  addMemberToCurrentBusiness,
  createTeam,
  createTeamFailure,
  createTeamMemberEmployeeId,
  createTeamMemberEmployeeIdFailure,
  createTeamMemberEmployeeIdSuccess,
  createTeamMemberLicense,
  createTeamMemberLicenseFailure,
  createTeamMemberLicenseSuccess,
  createTeamSuccess,
  deleteMemberFromBusinesses,
  deleteMemberFromBusinessesFailure,
  deleteMemberFromBusinessesSuccess,
  deleteMemberFromCurrentBusiness,
  deleteTeamMemberEmployeeId,
  deleteTeamMemberEmployeeIdFailure,
  deleteTeamMemberEmployeeIdSuccess,
  deleteTeamMemberLicense,
  deleteTeamMemberLicenseFailure,
  deleteTeamMemberLicenseSuccess,
  editTeamMemberEmployeeId,
  editTeamMemberEmployeeIdFailure,
  editTeamMemberEmployeeIdSuccess,
  editTeamMemberLicense,
  editTeamMemberLicenseFailure,
  editTeamMemberLicenseSuccess,
  fetchMember,
  fetchMemberFailure,
  fetchMembersForSelectList,
  fetchMembersForSelectListFailure,
  fetchMembersForSelectListSuccess,
  fetchMembersList,
  fetchMembersList as fetchMembersListAction,
  fetchMembersListFailure,
  fetchMembersListSuccess,
  fetchMemberSuccess,
  fetchMoreItemsForMembersList,
  fetchMoreItemsForMembersListFailure,
  fetchMoreItemsForMembersListSuccess,
  fetchMoreMembersForSelectList,
  fetchMoreMembersForSelectListFailure,
  fetchMoreMembersForSelectListSuccess,
  fetchTeamsList,
  fetchTeamsListFailure,
  fetchTeamsListSuccess,
  inviteMember,
  inviteMemberFailure,
  inviteMemberSuccess,
  updateActiveStatus,
  updateActiveStatusFailure,
  updateActiveStatusSuccess,
  updateMember,
  updateMemberFailure,
  updateMemberRoles,
  updateMemberRolesFailure,
  updateMemberRolesSuccess,
  updateMemberSuccess,
} from '../actions/members'
import {
  CREATE_TEAM,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID,
  CREATE_TEAM_MEMBER_LICENSE,
  DELETE_MEMBER_FROM_BUSINESSES,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID,
  DELETE_TEAM_MEMBER_LICENSE,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID,
  EDIT_TEAM_MEMBER_LICENSE,
  FETCH_MEMBER,
  FETCH_MEMBERS_FOR_SELECT_LIST,
  FETCH_MEMBERS_LIST,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST,
  FETCH_TEAMS_LIST,
  INVITE_MEMBER,
  UPDATE_ACTIVE_STATUS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_ROLES,
} from '../actions/types/members'
import { updateUser } from '../actions/users'
import { uploadImageAssetAndGenerateThumbnail } from '../duck/files'
import { finishLoading, startLoading } from '../duck/progress'
import { registerWarnAlert } from '../duck/uiAlerts'
import { addUiNotification } from '../duck/uiNotifications'
import { getCurrentBusinessId } from '../reducers/auth'
import { getUser } from '../reducers/users'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

function* addOrRemoveMember(
  memberId: string,
  newBusinessToRoleList: User['businessToRoleList'] = [],
  oldBusinessToRolesList: User['businessToRoleList'] = [],
) {
  const currentBusinessId: string = yield select(getCurrentBusinessId)
  const isCurrentBusinessMember = R.any(R.propEq('business', currentBusinessId))

  const wasInBusiness = isCurrentBusinessMember(oldBusinessToRolesList)
  const isInBusiness = isCurrentBusinessMember(newBusinessToRoleList)

  if (wasInBusiness && !isInBusiness) {
    // remove user from current business
    yield put(deleteMemberFromCurrentBusiness(memberId))
  }

  if (!wasInBusiness && isInBusiness) {
    // add user to current business
    yield put(addMemberToCurrentBusiness(memberId))
  }
}

export function* fetchMemberSaga({
  id,
  includeDeleted,
}: ReturnType<typeof fetchMember>) {
  try {
    const { entities } = yield* requestAPI(API.fetchPerson, id, includeDeleted)
    yield call(updateEntities, entities)
    yield put(fetchMemberSuccess())
  } catch (error) {
    yield put(fetchMemberFailure(error as ApiError))
  }
}

export function* inviteMemberSaga({ member }: ReturnType<typeof inviteMember>) {
  try {
    yield* requestAPI(API.inviteMember, member)
    yield put(inviteMemberSuccess())
    yield put(
      fetchMembersListAction({
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        shorten: true,
        includeInactive: true,
        includeInvitationStatus: true,
      }),
    )
  } catch (error) {
    const { status } = error as ApiError
    const errorMessage = getErrorMessage(error as ApiError) || ''
    if (status === 409) {
      yield put(registerWarnAlert(errorMessage))
    }
    yield put(inviteMemberFailure(error as ApiError))
  }
}

export function* createTeamSaga({ team }: ReturnType<typeof createTeam>) {
  try {
    const { result, entities } = yield* requestAPI(API.createPerson, {
      ...team,
      team: true,
    })

    yield call(updateEntities, entities)
    yield put(createTeamSuccess(result))
  } catch (error) {
    yield put(createTeamFailure(error as ApiError))
  }
}

export function* updateMemberSaga({
  member,
  avatarBlob,
}: ReturnType<typeof updateMember>) {
  try {
    if (avatarBlob) {
      const image = yield* uploadImageAssetAndGenerateThumbnail({
        blob: avatarBlob,
        destination: AssetDestination.PERSON,
        payload: {
          personId: member.id,
          isPublic: true,
        },
      })
      member.avatar = image.imageUrl
      member.avatarThumbnail = image.imageThumbnailUrl
    }
    const oldMember: User = yield select(getUser(member.id))
    const { entities } = yield* requestAPI(API.updatePerson, member)
    yield call(updateEntities, entities)
    yield addOrRemoveMember(
      member.id,
      member?.businessToRoleList,
      oldMember?.businessToRoleList,
    )
    yield put(updateMemberSuccess())
  } catch (error) {
    yield put(updateMemberFailure(error as ApiError))
  }
}

function* showUndoNotification(
  memberId: string,
  businessToRoleList: User['businessToRoleList'],
) {
  const member: User = yield select(getUser(memberId))
  yield put(
    addUiNotification({
      id: uuid(),
      title: `${i18n.t(
        'Admin:MEMBER.ROLE.YOU_REMOVED_PERSON_FROM_ALL_PRACTICES',
        {
          person: getPersonString(member),
        },
      )}`,
      type: SnackNotificationType.UNDO,
      params: {
        businessToRoleList,
        memberId,
      },
    }),
  )
}

export function* deleteMemberFromBusinessesSaga({
  memberId,
  businessesIds,
}: ReturnType<typeof deleteMemberFromBusinesses>) {
  try {
    yield put(startLoading('members'))
    const response = yield* requestAPI(
      API.deleteMemberFromBusinesses,
      memberId,
      businessesIds,
    )

    const member: User = yield select(getUser(memberId))
    const newMember = {
      ...member,
      businessToRoleList: (member.businessToRoleList || []).filter(
        ({ business: id }) => !businessesIds.includes(id),
      ),
    }
    yield put(updateUser(newMember))

    const currentBusinessId: string = yield select(getCurrentBusinessId)
    yield put(deleteMemberFromBusinessesSuccess())
    if (businessesIds.includes(currentBusinessId)) {
      yield put(deleteMemberFromCurrentBusiness(memberId))
    }

    if (businessesIds.length > 1) {
      yield showUndoNotification(memberId, response)
    }

    yield put(finishLoading('members'))
  } catch (error) {
    yield put(deleteMemberFromBusinessesFailure(error as ApiError))
    yield put(finishLoading('members'))
  }
}

export function* updateMemberRolesSaga({
  memberId,
  businessToRoleList,
}: ReturnType<typeof updateMemberRoles>) {
  try {
    yield put(startLoading('members'))
    const response: BusinessRoleItem[] = yield* requestAPI(
      API.updateMemberRoles,
      memberId,
      businessToRoleList,
    )
    const member: User = yield select(getUser(memberId))
    const newBusinessToRoleList = response.map(({ businessId, roleId }) => ({
      business: businessId,
      role: roleId,
    })) as BusinessRole[]
    yield addOrRemoveMember(
      memberId,
      newBusinessToRoleList,
      member.businessToRoleList,
    )

    const memberWithRoles = {
      ...member,
      businessToRoleList: newBusinessToRoleList,
    }
    yield put(updateMemberRolesSuccess())
    yield put(updateUser(memberWithRoles))

    yield put(finishLoading('members'))
  } catch (error) {
    yield put(updateMemberRolesFailure(error as ApiError))
    yield put(finishLoading('members'))
  }
}

export function* fetchMembersListSaga(
  options: ReturnType<typeof fetchMembersList>,
) {
  try {
    yield put(startLoading('members'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchPersons, {
      ...options,
      teamFilter: TeamFilter.MEMBER,
    })
    yield call(updateEntities, entities)
    yield put(fetchMembersListSuccess(list, totalCount))
    yield put(finishLoading('members'))
  } catch (error) {
    yield put(fetchMembersListFailure(error as ApiError))
    yield put(finishLoading('members'))
  }
}

export function* fetchMoreItemsForMembersListSaga(
  options: ReturnType<typeof fetchMoreItemsForMembersList>,
) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchPersons, {
      ...options,
      teamFilter: TeamFilter.MEMBER,
    })
    yield call(updateEntities, entities)
    yield put(
      fetchMoreItemsForMembersListSuccess(list, totalCount, options.from || 0),
    )
  } catch (error) {
    yield put(fetchMoreItemsForMembersListFailure(error as ApiError))
  }
}

export function* fetchTeamsListSaga(
  options: ReturnType<typeof fetchTeamsList>,
) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchPersons, {
      ...options,
      teamFilter: TeamFilter.TEAM,
    })
    yield call(updateEntities, entities)
    yield put(fetchTeamsListSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchTeamsListFailure(error as ApiError))
  }
}

export function* fetchMembersForSelectListSaga(
  options: ReturnType<typeof fetchMembersForSelectList>,
) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchPersons, {
      ...options,
      teamFilter: TeamFilter.MEMBER,
    })
    yield call(updateEntities, entities)
    yield put(fetchMembersForSelectListSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchMembersForSelectListFailure(error as ApiError))
  }
}

export function* fetchMoreMembersForSelectListSaga(
  options: ReturnType<typeof fetchMoreMembersForSelectList>,
) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchPersons, {
      ...options,
      teamFilter: TeamFilter.MEMBER,
    })
    yield call(updateEntities, entities)
    yield put(
      fetchMoreMembersForSelectListSuccess(list, totalCount, options.from || 0),
    )
  } catch (error) {
    yield put(fetchMoreMembersForSelectListFailure(error as ApiError))
  }
}

export function* createTeamMemberLicenseSaga({
  teamMemberId,
  license,
}: ReturnType<typeof createTeamMemberLicense>) {
  try {
    const licenses = yield* requestAPI(
      API.createTeamMemberLicense,
      teamMemberId,
      license,
    )
    yield put(createTeamMemberLicenseSuccess(teamMemberId, licenses))
  } catch (error) {
    yield put(createTeamMemberLicenseFailure(error as ApiError))
  }
}

export function* editTeamMemberLicenseSaga({
  teamMemberId,
  license,
}: ReturnType<typeof editTeamMemberLicense>) {
  try {
    const licenses = yield* requestAPI(
      API.createTeamMemberLicense,
      teamMemberId,
      license,
    )
    yield put(editTeamMemberLicenseSuccess(teamMemberId, licenses))
  } catch (error) {
    yield put(editTeamMemberLicenseFailure(error as ApiError))
  }
}

export function* deleteTeamMemberLicenseSaga({
  teamMemberId,
  licenseId,
}: ReturnType<typeof deleteTeamMemberLicense>) {
  try {
    const licenses = yield* requestAPI(
      API.deleteTeamMemberLicense,
      teamMemberId,
      licenseId,
    )
    yield put(deleteTeamMemberLicenseSuccess(teamMemberId, licenses))
  } catch (error) {
    yield put(deleteTeamMemberLicenseFailure(error as ApiError))
  }
}

export function* updateActiveStatusSaga({
  memberId,
  isActive,
}: ReturnType<typeof updateActiveStatus>) {
  try {
    const { entities } = yield* requestAPI(
      API.updateMemberBusinessScope,
      memberId,
      { active: isActive },
    )
    yield call(updateEntities, entities)
    yield put(updateActiveStatusSuccess())
  } catch (error) {
    yield put(updateActiveStatusFailure(error as ApiError))
  }
}

export function* createTeamMemberEmployeeIdSaga({
  teamMemberId,
  newEmployeeIdInfo,
}: ReturnType<typeof createTeamMemberEmployeeId>) {
  try {
    const employeeIdsInfos = yield* requestAPI(
      API.createTeamMemberEmployeeId,
      teamMemberId,
      newEmployeeIdInfo,
    )
    yield put(createTeamMemberEmployeeIdSuccess(teamMemberId, employeeIdsInfos))
  } catch (error) {
    yield put(createTeamMemberEmployeeIdFailure(error as ApiError))
  }
}

export function* editTeamMemberEmployeeIdSaga({
  teamMemberId,
  employeeIdInfo,
}: ReturnType<typeof editTeamMemberEmployeeId>) {
  try {
    const employeeIdsInfos = yield* requestAPI(
      API.createTeamMemberEmployeeId,
      teamMemberId,
      employeeIdInfo,
    )
    yield put(editTeamMemberEmployeeIdSuccess(teamMemberId, employeeIdsInfos))
  } catch (error) {
    yield put(editTeamMemberEmployeeIdFailure(error as ApiError))
  }
}

export function* deleteTeamMemberEmployeeIdSaga({
  teamMemberId,
  employeeIdInfoId,
}: ReturnType<typeof deleteTeamMemberEmployeeId>) {
  try {
    const employeeIdsInfos = yield* requestAPI(
      API.deleteTeamMemberEmployeeId,
      teamMemberId,
      employeeIdInfoId,
    )
    yield put(deleteTeamMemberEmployeeIdSuccess(teamMemberId, employeeIdsInfos))
  } catch (error) {
    yield put(deleteTeamMemberEmployeeIdFailure(error as ApiError))
  }
}

function* watchInviteMember() {
  yield takeLeading(INVITE_MEMBER, inviteMemberSaga)
}

function* watchCreateTeam() {
  yield takeLeading(CREATE_TEAM, createTeamSaga)
}

function* watchUpdateMember() {
  yield takeLeading(UPDATE_MEMBER, updateMemberSaga)
}

function* watchDeleteMemberFromBusinesses() {
  yield takeLeading(
    DELETE_MEMBER_FROM_BUSINESSES,
    deleteMemberFromBusinessesSaga,
  )
}

function* watchFetchMembersList() {
  yield takeLeading(FETCH_MEMBERS_LIST, fetchMembersListSaga)
}

function* watchFetchMoreItemsForMembersList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_MEMBERS_LIST,
    fetchMoreItemsForMembersListSaga,
  )
}

function* watchFetchTeamsList() {
  yield takeLatest(FETCH_TEAMS_LIST, fetchTeamsListSaga)
}

function* watchFetchMembersForSelectList() {
  yield takeLatest(FETCH_MEMBERS_FOR_SELECT_LIST, fetchMembersForSelectListSaga)
}

function* watchFetchMoreMembersForSelectList() {
  yield takeLatest(
    FETCH_MORE_MEMBERS_FOR_SELECT_LIST,
    fetchMoreMembersForSelectListSaga,
  )
}

function* watchCreateTeamMemberLicense() {
  yield takeLeading(CREATE_TEAM_MEMBER_LICENSE, createTeamMemberLicenseSaga)
}

function* watchEditTeamMemberLicense() {
  yield takeLeading(EDIT_TEAM_MEMBER_LICENSE, editTeamMemberLicenseSaga)
}

function* watchDeleteTeamMemberLicense() {
  yield takeLeading(DELETE_TEAM_MEMBER_LICENSE, deleteTeamMemberLicenseSaga)
}

function* watchSetActiveStatus() {
  yield takeLeading(UPDATE_ACTIVE_STATUS, updateActiveStatusSaga)
}

function* watchFetchMember() {
  yield takeLatest(FETCH_MEMBER, fetchMemberSaga)
}

function* watchUpdateMemberRoles() {
  yield takeEvery(UPDATE_MEMBER_ROLES, updateMemberRolesSaga)
}

function* watchCreateTeamMemberEmployeeId() {
  yield takeLeading(
    CREATE_TEAM_MEMBER_EMPLOYEE_ID,
    createTeamMemberEmployeeIdSaga,
  )
}

function* watchEditTeamMemberEmployeeId() {
  yield takeLeading(EDIT_TEAM_MEMBER_EMPLOYEE_ID, editTeamMemberEmployeeIdSaga)
}

function* watchDeleteTeamMemberEmployeeId() {
  yield takeLeading(
    DELETE_TEAM_MEMBER_EMPLOYEE_ID,
    deleteTeamMemberEmployeeIdSaga,
  )
}

export default function* membersSaga() {
  yield all([
    watchCreateTeamMemberEmployeeId(),
    watchEditTeamMemberEmployeeId(),
    watchDeleteTeamMemberEmployeeId(),
    watchInviteMember(),
    watchCreateTeam(),
    watchUpdateMember(),
    watchFetchMembersList(),
    watchFetchMoreItemsForMembersList(),
    watchFetchMembersForSelectList(),
    watchFetchMoreMembersForSelectList(),
    watchFetchTeamsList(),
    watchCreateTeamMemberLicense(),
    watchEditTeamMemberLicense(),
    watchDeleteTeamMemberLicense(),
    watchSetActiveStatus(),
    watchDeleteMemberFromBusinesses(),
    watchFetchMember(),
    watchUpdateMemberRoles(),
  ])
}
