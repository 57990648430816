import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { RootState } from '~/store'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import { BaseTemplateInputHandle } from '../template-inputs/BaseTemplateInput/BaseTemplateInput'
import NotesTemplateInput, {
  NotesTemplateInputProps,
} from '../template-inputs/NotesTemplateInput'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: '100%',
      maxWidth: 936,
    },
    dialogContentRoot: {
      padding: theme.spacing(1.5, 2, 3),
    },
    button: {
      minWidth: 120,
    },
  }),
  { name: 'NotesDialog' },
)

export interface NotesDialogProps
  extends BasePuiDialogProps,
    NotesTemplateInputProps {
  isLoadingSelector: (state: RootState) => boolean
  name: string
  notes: string | Nil
  onUpdateNotes: (notes: string) => void
}

const NotesDialog = ({
  open,
  name,
  notes,
  onUpdateNotes,
  onClose,
  isLoadingSelector,
  ...rest
}: NotesDialogProps) => {
  const classes = useStyles()
  const isLoading = useSelector(isLoadingSelector)
  const { t } = useTranslation('Common')

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    isLoadingSelector,
  )

  const notesRef = useRef<BaseTemplateInputHandle>(null)

  const handleOnSave = () => {
    const notesValue = notesRef.current?.getUnsavedData()

    if (notesValue !== undefined) {
      onUpdateNotes(notesValue)
    }

    setCloseAfterCreationOn()
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          classes={{ button: classes.button }}
          loading={isLoading}
          onClick={handleOnSave}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="notes-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={`${t('Common:ADD_NOTE')}: ${name}`}
      onClose={onClose}
    >
      <NotesTemplateInput
        hidePanel
        isSoap
        maxEditorHeight={400}
        maxHeight={400}
        minEditorHeight={162}
        minHeight={162}
        placeholder={t('Common:NOTE')}
        ref={notesRef}
        value={notes}
        {...rest}
      />
    </PuiDialog>
  )
}

export default NotesDialog
