import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { BasePuiDialogProps, Constant, Nil } from '@pbt/pbt-ui-components'

import { groupLabTests } from '~/components/dashboard/soap/wrap-up/lab-orders/labTestUtils'
import OrderManagementDialog from '~/components/dashboard/soap/wrap-up/lab-orders/order-management-dialog/OrderManagementDialog'
import { OrderType } from '~/constants/SOAPStates'
import {
  cancelLabOrder,
  clearDevices,
  clearLabOrders,
  fetchDevices,
  fetchLabOrders,
  getLabOrders,
} from '~/store/duck/labOrders'
import { useGetInvoiceLogsByLogType } from '~/store/hooks/finance'
import { useGetActiveLabVendorsMap } from '~/store/hooks/labVendorConfig'
import { getLabTestsStates } from '~/store/reducers/constants'
import { getFinanceInvoice } from '~/store/reducers/finance'
import { getSoapId } from '~/store/reducers/soap'
import { LabOrder } from '~/types'
import { arrayToMap } from '~/utils'
import { convertInvoiceLineItemToSoapLog } from '~/utils/orderUtils'

const flattenLabOrders = R.pipe(
  R.map(({ orders }: LabOrder) => orders),
  R.flatten,
)

export interface LabOrderManagementDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  invoiceId: string
  patientId: string | Nil
}

const LabOrderManagementDialog = ({
  open,
  onClose,
  clientId,
  patientId,
  invoiceId,
}: LabOrderManagementDialogProps) => {
  const dispatch = useDispatch()
  const invoice = useSelector(getFinanceInvoice(invoiceId))
  const LabTestsStatesList: Constant[] = useSelector(getLabTestsStates)
  const labOrders = useSelector(getLabOrders)
  const soapId = useSelector(getSoapId)

  const logItems = useGetInvoiceLogsByLogType(OrderType.LAB_TEST, invoice)

  const ordersMap = arrayToMap(flattenLabOrders(labOrders), R.prop('id'))
  const labTestsMap = groupLabTests(
    LabTestsStatesList,
    logItems.map(convertInvoiceLineItemToSoapLog),
  )

  const activeLabVendors = useGetActiveLabVendorsMap()

  useEffect(() => {
    if (invoiceId) {
      dispatch(fetchLabOrders(undefined, invoiceId))
      dispatch(fetchDevices())
    }
    return () => {
      // This component can be opened inside an actual SOAP by opening an invoice dialog, in such case
      // we don't want to clear lab orders status, as this would remove the LabOrdersSection component
      if (invoiceId && !soapId) {
        dispatch(clearLabOrders())
        dispatch(clearDevices())
      }
    }
  }, [invoiceId, soapId])

  const onCancelOrder = (vendorId: string, orderId: string) => {
    dispatch(cancelLabOrder(vendorId, orderId))
  }

  return (
    <OrderManagementDialog
      isOTC
      clientId={clientId}
      hasIntegrationMap={activeLabVendors}
      invoiceId={invoiceId}
      labTestsMap={labTestsMap}
      open={open}
      ordersMap={ordersMap}
      patientId={patientId}
      onCancelOrder={onCancelOrder}
      onClose={onClose}
    />
  )
}

export default LabOrderManagementDialog
