import { useLayoutEffect } from 'react'

export const useElementResize = (
  element: HTMLElement | null,
  onResize: () => void,
) => {
  useLayoutEffect(() => {
    let observer: ResizeObserver
    if (element) {
      observer = new ResizeObserver(onResize)
      observer.observe(element)
    }
    return () => {
      if (observer && element) {
        observer.unobserve(element)
      }
    }
  }, [element])
}
