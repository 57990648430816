import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import {
  clearChewyItems,
  fetchActiveRxWorkflowAllowance,
  fetchChewyItemsByGlobalItemVariationId,
} from '~/store/duck/prescriptions'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

export interface UseChewyActiveWorkflowProps {
  activeWorkflow: PrescriptionWorkflowType | undefined
  clientId: string | undefined
  globalVariationId: string | undefined
  globalVariationMappingId: string | undefined
  isChewyReactiveRx: boolean
  patientId: string | undefined
}

export const useChewyActiveWorkflow = ({
  activeWorkflow,
  clientId,
  isChewyReactiveRx,
  patientId,
  globalVariationId,
  globalVariationMappingId,
}: UseChewyActiveWorkflowProps) => {
  const dispatch = useDispatch()

  const businessId = useSelector(getCurrentBusinessId)
  const isChewyActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const fetchDisabled = R.isNil(activeWorkflow) || isChewyReactiveRx

  const processingGlobalVariationId = isFoodCatalogEnabled
    ? globalVariationMappingId
    : globalVariationId

  // Check active rx workflow allowance for business, client and patient
  useEffect(() => {
    if (fetchDisabled) {
      return
    }

    if (isChewyActiveRxEnabled && businessId && clientId && patientId) {
      dispatch(
        fetchActiveRxWorkflowAllowance({ businessId, clientId, patientId }),
      )
    }
  }, [
    fetchDisabled,
    isChewyActiveRxEnabled,
    businessId,
    clientId,
    patientId,
    client?.hasKyriosId,
    patient?.hasKyriosId,
  ])

  // Fetch chewy items to check chewy active workflow availability
  useEffect(() => {
    if (fetchDisabled || !isChewyActiveRxEnabled) {
      return
    }

    if (!processingGlobalVariationId) {
      dispatch(clearChewyItems())
      return
    }

    dispatch(
      fetchChewyItemsByGlobalItemVariationId(processingGlobalVariationId),
    )
  }, [fetchDisabled, isChewyActiveRxEnabled, processingGlobalVariationId])
}
