import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { AlertColorLevel, useGetAlertColor } from '~/constants/alertColors'
import { getUser } from '~/store/reducers/users'

import TooltipChip from './TooltipChip'

const useStyles = makeStyles(
  (theme) => ({
    warningIcon: {
      fontSize: '1.2rem',
      fill: theme.colors.alertErrorText,
    },
    icon: {},
  }),
  { name: 'ClientWarningChip' },
)

export interface ClientWarningChipProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  clientId: string | Nil
}

const ClientWarningChip = ({
  clientId,
  className,
  classes: classesProp,
}: ClientWarningChipProps) => {
  const client = useSelector(getUser(clientId))

  const alertColor = useGetAlertColor(
    client?.alertColorId,
    AlertColorLevel.PATIENT,
  )
  const classes = useStyles({ alertColor, classes: classesProp })

  return (
    <>
      {client?.alertText && (
        <TooltipChip
          className={className}
          color={alertColor}
          icon={
            <WarningIcon
              className={classNames(classes.warningIcon, classes.icon)}
            />
          }
          tooltipPlacement="top"
          tooltipText={client?.alertText}
        />
      )}
    </>
  )
}

export default ClientWarningChip
