import { Nil } from '@pbt/pbt-ui-components'

import { BusinessRoleItem, FetchSupportUsersOptions } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchSupportUsers = (
  businessId: string,
  { from = 0, to = 100, query = '' }: FetchSupportUsersOptions,
) =>
  request(
    getAPIPath('persons/v2/support'),
    {
      method: 'GET',
      params: {
        from,
        to,
        query,
        businessId,
      },
    },
    true,
    Schema.membersList,
  )

export const updateSupportUserRoles = (
  businessId: string,
  personId: string,
  businessToRoleList: BusinessRoleItem[] | Nil,
) =>
  request(
    getAPIPath(`role/global/person/${personId}/expiring`),
    { method: 'PUT', params: { businessId }, data: businessToRoleList },
    true,
  )
