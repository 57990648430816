import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Nil, PuiBaseTooltip } from '@pbt/pbt-ui-components'
import { Share as ShareIcon } from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getMultipleBusinesses } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      fontSize: '1.6rem',
    },
    bidIcon: {
      fontSize: '2.4rem',
    },
    text: {
      padding: theme.spacing(0, 1),
    },
    tooltip: {
      whiteSpace: 'pre-line',
      marginLeft: theme.spacing(-2),
    },
    tooltipBigFixed: {
      marginLeft: theme.spacing(-1.5),
    },
  }),
  { name: 'BusinessShareIcon' },
)

export interface BusinessShareIconProps {
  businessIds: string[] | Nil
  className?: string
  noMargin?: boolean
  variant?: 'default' | 'big'
}

const BusinessShareIcon = ({
  businessIds,
  className,
  variant,
  noMargin,
}: BusinessShareIconProps) => {
  const classes = useStyles()

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const businesses = useSelector(getMultipleBusinesses(businessIds || []))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const sharedBusinesses = businesses.filter(
    ({ id }) => id !== currentBusinessId,
  )

  if (
    !isPatientSharingEnabled ||
    !currentBusinessId ||
    !sharedBusinesses.length
  ) {
    return null
  }

  const tooltipText = R.pluck('name', sharedBusinesses).join('\n')

  return (
    <PuiBaseTooltip
      aria-label="share-icon"
      classes={{
        tooltip: classNames(classes.tooltip, {
          [classes.tooltipBigFixed]: variant === 'big',
        }),
      }}
      placement="top-start"
      tooltipText={tooltipText}
    >
      <Grid
        item
        className={className}
        component="span"
        display="inline-flex"
        ml={noMargin ? 0 : 1}
      >
        <ShareIcon
          className={classNames(classes.icon, {
            [classes.bidIcon]: variant === 'big',
          })}
        />
      </Grid>
    </PuiBaseTooltip>
  )
}

export default BusinessShareIcon
