import { ApiError } from '@pbt/pbt-ui-components'

import { TokenHolder } from '~/types'

import * as AuthActions from './types/auth'

export const resetPassword = (
  token: string,
  password: string,
  email: string,
) => ({
  type: AuthActions.RESET_PASSWORD,
  token,
  password,
  email,
})

export const resetPasswordSuccess = () => ({
  type: AuthActions.RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailure = (error: ApiError) => ({
  type: AuthActions.RESET_PASSWORD_FAILURE,
  error,
})

export const sendResetPasswordEmail = (email: string) => ({
  type: AuthActions.SEND_RESET_PASSWORD_EMAIL,
  email,
})

export const sendResetPasswordEmailSuccess = () => ({
  type: AuthActions.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
})

export const sendResetPasswordEmailFailure = (error: ApiError) => ({
  type: AuthActions.SEND_RESET_PASSWORD_EMAIL_FAILURE,
  error,
})

export const updatePassword = (password: string) => ({
  type: AuthActions.UPDATE_PASSWORD,
  password,
})

export const updatePasswordSuccess = () => ({
  type: AuthActions.UPDATE_PASSWORD_SUCCESS,
})

export const updatePasswordFailure = (error: ApiError) => ({
  type: AuthActions.UPDATE_PASSWORD_FAILURE,
  error,
})

export const loginRequest = (
  email: string,
  password: string,
  eulaAccepted?: boolean,
) => ({
  type: AuthActions.LOGIN_REQUEST,
  email,
  password,
  eulaAccepted,
})
export const silentLoginRequest = (email: string, password: string) => ({
  type: AuthActions.SILENT_LOGIN_REQUEST,
  email,
  password,
})
export const loginFailure = (error: ApiError) => ({
  type: AuthActions.LOGIN_FAILURE,
  error,
})
export const setAuthorizationError = (error: ApiError | Error) => ({
  type: AuthActions.SET_AUTHORIZATION_ERROR,
  error,
})
export const clearAuthorizationError = () => ({
  type: AuthActions.CLEAR_AUTHORIZATION_ERROR,
})
export const silentLoginFailure = (error: ApiError) => ({
  type: AuthActions.SILENT_LOGIN_FAILURE,
  error,
})
export const silentLoginSuccess = (
  tokenHolder: TokenHolder,
  personId: string,
) => ({
  type: AuthActions.SILENT_LOGIN_SUCCESS,
  tokenHolder,
  personId,
})
export const loginSuccess = (tokenHolder: TokenHolder, personId: string) => ({
  type: AuthActions.LOGIN_SUCCESS,
  tokenHolder,
  personId,
})
export const fetchCurrentUser = () => ({ type: AuthActions.FETCH_CURRENT_USER })
export const fetchCurrentUserFailure = (error: ApiError) => ({
  type: AuthActions.FETCH_CURRENT_USER_FAILURE,
  error,
})
export const fetchCurrentUserSuccess = (userId: string) => ({
  type: AuthActions.FETCH_CURRENT_USER_SUCCESS,
  userId,
})
export const logout = () => ({ type: AuthActions.LOGOUT })
export const invalidateToken = () => ({ type: AuthActions.INVALIDATE_TOKEN })

export const setLogOutTime = (timeout: number) => ({
  type: AuthActions.SET_LOG_OUT_TIME,
  timeout,
})
export const clearLogOutTime = () => ({ type: AuthActions.CLEAR_LOG_OUT_TIME })

export const refreshToken = (token: string) => ({
  type: AuthActions.REFRESH_TOKEN,
  token,
})
export const refreshTokenFailure = (error: ApiError) => ({
  type: AuthActions.REFRESH_TOKEN_FAILURE,
  error,
})
export const refreshTokenSuccess = (tokenHolder: TokenHolder) => ({
  type: AuthActions.REFRESH_TOKEN_SUCCESS,
  tokenHolder,
})

export const AUTH_SENSITIVE_ACTIONS = [
  {
    type: AuthActions.LOGIN_REQUEST,
    sensitiveData: ['password'],
  },
  {
    type: AuthActions.LOGIN_SUCCESS,
    sensitiveData: ['tokenHolder'],
  },
  {
    type: AuthActions.REFRESH_TOKEN,
    sensitiveData: ['token'],
  },
  {
    type: AuthActions.REFRESH_TOKEN_SUCCESS,
    sensitiveData: ['tokenHolder'],
  },
  {
    type: AuthActions.SILENT_LOGIN_REQUEST,
    sensitiveData: ['password'],
  },
  {
    type: AuthActions.SILENT_LOGIN_SUCCESS,
    sensitiveData: ['tokenHolder'],
  },
  {
    type: AuthActions.UPDATE_PASSWORD,
    sensitiveData: ['password'],
  },
  {
    type: AuthActions.RESET_PASSWORD,
    sensitiveData: ['password', 'token'],
  },
]
