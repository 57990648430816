import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

import QuestionDetails from './QuestionDetails'
import QuestionsTableComponent from './QuestionsTableComponent'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 132,
      height: 40,
    },
  }),
  { name: 'QuestionsPage' },
)

const QuestionsPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { questionId } = useParams()
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))
  const { t } = useTranslation('Common')

  const [openQuestionDialog] = useDialog(DialogNames.QUESTION)

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/questions'))
  }

  if (isMobile && typeof questionId !== 'undefined') {
    return <QuestionDetails itemId={questionId} onClose={onDetailsClose} />
  }

  const headerButtons = permissions.create && (
    <Grid container item justifyContent="flex-end" px={3} py={2}>
      <Fab
        className={classes.button}
        color="inherit"
        variant="extended"
        onClick={() => openQuestionDialog()}
      >
        {t('Common:ADD_QUESTION')}
      </Fab>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <QuestionsTableComponent
        headerButtons={headerButtons}
        questionId={questionId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default QuestionsPage
