import React from 'react'
import { useSelector } from 'react-redux'
import { Constants } from '@pbt/pbt-ui-components'

import { getConstantTranslatedName } from '~/store/reducers/constants'

export type ConstantValueCellItem = {
  constant: keyof Constants
  value: any
}

const ConstantValueCell = ({ constant, value }: ConstantValueCellItem) => {
  const cellName =
    useSelector(getConstantTranslatedName(constant, value)) || '-'

  if (!value) {
    return null
  }

  return <>{cellName}</>
}

export default ConstantValueCell
