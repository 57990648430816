import { useSelector } from 'react-redux'

import { getGender, getSpayedNeuteredStatus } from '~/store/reducers/constants'
import { SpayedNeuteredGenderType } from '~/types'

import { getGenderRestrictionString } from '../../../inputs/gender/genderUtils'

export type GenderRestrictionsCellItem = {
  genderRestrictions: SpayedNeuteredGenderType[]
}

const GenderRestrictionsCell = (item: GenderRestrictionsCellItem) => {
  const Gender = useSelector(getGender)
  const SpayedNeuteredStatus = useSelector(getSpayedNeuteredStatus)

  return getGenderRestrictionString(
    item.genderRestrictions,
    Gender,
    SpayedNeuteredStatus,
  )
}

export default GenderRestrictionsCell
