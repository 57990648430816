import React from 'react'
import { TableCell } from '@mui/material'

export interface ProductionCellSubHeadProps {
  className?: string
}

const ProductionCellSubHead = ({ className }: ProductionCellSubHeadProps) => (
  <TableCell className={className} />
)

export default ProductionCellSubHead
