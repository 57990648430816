import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Amount,
  BasePuiDialogProps,
  ButtonWithLoader,
  CurrencyTextField,
  CustomFieldValidatorState,
  PuiDialog,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  getHighValue,
  getLowValue,
} from '~/components/dashboard/invoices/invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: theme.spacing(2),
      minWidth: 150,
    },
    input: {
      width: 200,
    },
  }),
  { name: 'BundleCustomPriceDialog' },
)

interface BundleCustomPriceDialogProps extends BasePuiDialogProps {
  extendedBundlePrice: Amount
  onOk: (bundlePrice: number) => void
}

const BundleCustomPriceDialog = ({
  open,
  onClose,
  onOk,
  extendedBundlePrice,
}: BundleCustomPriceDialogProps) => {
  const classes = useStyles()
  const initialBundleHighPrice = getHighValue(extendedBundlePrice)
  const initialBundleLowPrice = getLowValue(extendedBundlePrice)
  const isRangePrice = initialBundleHighPrice !== initialBundleLowPrice
  const { t } = useTranslation(['Common', 'Dialogs', 'Validations'])

  const bundlePriceValidator = useCallback(
    ({ state: { bundlePrice } }: CustomFieldValidatorState) =>
      bundlePrice < initialBundleHighPrice,
    [initialBundleHighPrice],
  )

  const {
    fields: { bundlePrice },
    validate,
  } = useFields([
    {
      name: 'bundlePrice',
      label: t('Common:TOTAL_PRICE'),
      validators: [
        {
          validator: bundlePriceValidator,
          validatorName: 'bundlePrice',
        },
      ],
      messages: {
        bundlePrice: t(
          'Validations:TOTAL_MUST_BE_LESS_THAN_SUM_INCLUDED_ITEMS',
        ),
      },
      initialValue: initialBundleHighPrice,
    },
  ])

  const handleProceed = () => {
    if (validate()) {
      onOk(parseFloat(bundlePrice.value))
      if (onClose) {
        onClose()
      }
    }
  }

  const promptText = isRangePrice
    ? t('Dialogs:BUNDLE_CUSTOM_PRICE_DIALOG.PROMPT_TEXT_RANGE_PRICE')
    : t('Dialogs:BUNDLE_CUSTOM_PRICE_DIALOG.PROMPT_TEXT_NOT_RANGE_PRICE')

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="bundle-custom-price-dialog"
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <Grid container alignItems="center" direction="column" p={3}>
        <Text align="center" m={2} variant="body">
          {promptText}
        </Text>
        <CurrencyTextField
          TooltipProps={{
            placement: 'top',
          }}
          className={classes.input}
          field={bundlePrice}
          label={bundlePrice.label}
        />
        <ButtonWithLoader className={classes.button} onClick={handleProceed}>
          {t('Common:SET_TOTAL')}
        </ButtonWithLoader>
      </Grid>
    </PuiDialog>
  )
}

export default BundleCustomPriceDialog
