import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import { Text, TextWithTooltip, useFields, Utils } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { BasePracticeDetailsSectionProps } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../practices'

export const SharingSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const { t } = useTranslation(['Admin', 'Tooltips'])

  const { fields } = useFields([
    {
      name: 'sharing',
      initialValue: business.wplanSharingEnabled || false,
    },
  ])

  const { sharing } = fields

  usePracticeFieldsSection({
    business,
    fields,
    parsedFields: {
      wplanSharingEnabled: sharing.value,
    },
    sectionName: PracticeDetailsPanels.SHARING,
  })

  return (
    <PuiSwitch
      checked={sharing.value}
      label={
        <TextWithTooltip
          tooltipText={
            <Text variant="body2">
              <Trans
                components={{
                  linkComponent: (
                    <Link
                      href="https://help.rhapsody.vet"
                      rel="help noopener"
                      target="_blank"
                      underline="always"
                    />
                  ),
                }}
                i18nKey="Tooltips:IF_ON_YOUR_MEMBERSHIP_BENEFITS_WILL_BE_AVAILABLE_AT_ANY_PRACTICE_IN_YOUR_GROUP"
              />
            </Text>
          }
          variant="body"
        >
          {t('Admin:PRACTICE.SHARING.ENABLE_MEMBERSHIP_BENEFIT_SHARING')}
        </TextWithTooltip>
      }
      onChange={Utils.handleFormCheckboxInput(sharing.setValue)}
    />
  )
}
