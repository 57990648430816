import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    list: {
      borderLeft: theme.constants.filterBorder,
      borderTop: theme.constants.filterBorder,
      borderBottom: theme.constants.filterBorder,
      height: 355,
      overflowY: 'scroll',
      ...theme.constants.scrollbar,
    },
    listItem: {
      '&:hover': {
        backgroundColor: theme.colors.newLabel,
      },
      cursor: 'pointer',
      padding: theme.spacing(2.5, 1),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.filterBorder,
      },
    },
    iconHighlighted: {
      color: theme.colors.markerHighlighted,
    },
    iconNormal: {
      color: theme.colors.iconColor,
    },
    icon: {
      fontSize: '5rem',
    },
  }),
  { name: 'MapMarkersList' },
)

const MapMarkersList = ({
  className,
  points,
  setHighlightedId,
  onItemClick,
  highlightedId = null,
}) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.list, className)}>
      {points.map((point) => (
        <Grid
          container
          alignItems="center"
          className={classes.listItem}
          id={`mapMarkersList-listItem-${point.place_id}`}
          key={point.place_id}
          wrap="nowrap"
          onClick={() => onItemClick(point)}
          onMouseEnter={() => setHighlightedId(point.place_id)}
          onMouseLeave={() => setHighlightedId(null)}
        >
          <LocationOnIcon
            className={classNames(
              classes.icon,
              highlightedId === point.place_id
                ? classes.iconHighlighted
                : classes.iconNormal,
            )}
          />
          <Grid item xs>
            <Dotdotdot clamp={1}>
              <Text strong variant="subheading">
                {point.name}
              </Text>
            </Dotdotdot>
            <Dotdotdot clamp={1}>
              <Text>{point.formatted_address || point.vicinity}</Text>
            </Dotdotdot>
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

MapMarkersList.propTypes = {
  highlightedId: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  points: PropTypes.array.isRequired,
  setHighlightedId: PropTypes.func.isRequired,
}

MapMarkersList.defaultProps = {
  highlightedId: null,
}

export default MapMarkersList
