import { useCallback, useRef, useState } from 'react'

export const useRefCallback = (initialValue: any) => {
  const ref = useRef(initialValue)

  const [mounted, setMounted] = useState(false)

  const refCallback = useCallback((value: any) => {
    ref.current = value
    // to rerender component when ref is ready
    setMounted(true)
  }, [])

  return { ref, refCallback, mounted }
}
