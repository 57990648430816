import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ImageScalingUtils,
  PatientAvatar,
  Text,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'

import Link from '../../../../link/Link'
import NotAssignedPatient from './NotAssignedPatient'

const AVATAR_SIZE = 32

const useStyles = makeStyles(
  (theme) => ({
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: 16,
      marginRight: theme.spacing(1.5),
    },
    link: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'PatientCell' },
)

export interface PatientCellProps {
  isLoading?: boolean
  patientId?: string
}

const PatientCell = ({ patientId, isLoading }: PatientCellProps) => {
  const classes = useStyles()
  const patient = useSelector(getPatient(patientId))

  const name = patient?.name
  const photo = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  if (isLoading) {
    return <TextInteractive isLoading={isLoading} variant="body2" />
  }

  return patientId && name ? (
    <Grid container alignItems="center" wrap="nowrap">
      <PatientAvatar tiny className={classes.avatar} src={photo} />
      <Dotdotdot clamp={1}>
        <Text variant="body2">
          {patientId ? (
            <Link className={classes.link} to={`/patient/${patientId}`}>
              {name}
            </Link>
          ) : (
            name
          )}
        </Text>
      </Dotdotdot>
    </Grid>
  ) : (
    <NotAssignedPatient />
  )
}

export default PatientCell
