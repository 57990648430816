import React from 'react'
import { alpha, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    content: {
      backgroundColor: alpha(theme.colors.primaryText, 0.02),
      borderTop: theme.constants.tableBorder,
    },
    title: {},
  }),
  { name: 'SoapWidget' },
)

export interface SoapWidgetProps {
  actions?: React.ReactNode
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  id?: string
  title: string | React.ReactNode
}

const SoapWidget = ({
  classes: classesProp,
  actions,
  id,
  children,
  title,
}: SoapWidgetProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      height="fit-content"
      id={id}
      width="100%"
    >
      <Box
        alignItems="center"
        className={classes.title}
        display="flex"
        pb={1}
        pt={2}
        px={2}
        width="100%"
      >
        {React.isValidElement(title) ? (
          title
        ) : (
          <Text lineHeight={1} variant="h4">
            {title}
          </Text>
        )}
        {actions && actions}
      </Box>
      <Box
        className={classes.content}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  )
}

export default SoapWidget
