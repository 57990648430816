import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Constant, PuiSelect, useFields, Utils } from '@pbt/pbt-ui-components'

import {
  getAppointmentCommunicationInitializationType,
  getAppointmentCommunicationTriggeringStatus,
} from '~/store/reducers/constants'
import { DataHandle } from '~/types'

import { AutomaticCommunicationBaseProps } from './interfaces'

const useStyles = makeStyles(
  () => ({
    root: {},
    select: {
      width: 315,
    },
  }),
  { name: 'AutomaticCommunicationChangeInAppointmentStatusSettings' },
)

export interface AutomaticCommunicationChangeInAppointmentStatusSettingsProps
  extends AutomaticCommunicationBaseProps {
  initializationTypeId?: string
}

const AutomaticCommunicationChangeInAppointmentStatusSettings = forwardRef<
  DataHandle | undefined,
  AutomaticCommunicationChangeInAppointmentStatusSettingsProps
>(({ initializationTypeId, className, automaticCommunication }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const AppointmentCommunicationTriggeringStatus: Constant[] = useSelector(
    getAppointmentCommunicationTriggeringStatus,
  )
  const InitializationType: Constant[] = useSelector(
    getAppointmentCommunicationInitializationType,
  )

  const ChangeInStatusOptionId: string = Utils.findConstantIdByName(
    'Triggered by change in appointment status',
    InitializationType,
  )

  const isChangeInStatus = initializationTypeId === ChangeInStatusOptionId

  const CancelledStateId: string = Utils.findConstantIdByName(
    'Cancelled',
    AppointmentCommunicationTriggeringStatus,
  )

  const {
    fields: { triggeringStatusId },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'triggeringStatusId',
        label: t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.TRIGGERING_APPOINTMENT_STATUS',
        ),
        initialValue:
          automaticCommunication?.initializationByStatus?.triggeringStatusId ||
          CancelledStateId,
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [automaticCommunication])

  useImperativeHandle(ref, () => ({
    validate: () => (isChangeInStatus ? validate() : true),
    get: () => ({
      initializationByStatus: isChangeInStatus
        ? {
            triggeringStatusId: triggeringStatusId.value,
          }
        : null,
    }),
  }))

  if (!isChangeInStatus) {
    return null
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
      wrap="nowrap"
      xs={12}
    >
      <FormControl fullWidth margin="none">
        <InputLabel htmlFor="counties-select">
          {triggeringStatusId.label}
        </InputLabel>
        <PuiSelect
          className={classes.select}
          field={triggeringStatusId}
          input={<Input id="triggering-appointment-status-select" />}
          items={AppointmentCommunicationTriggeringStatus}
          renderEmpty={false}
        />
      </FormControl>
    </Grid>
  )
})

export default AutomaticCommunicationChangeInAppointmentStatusSettings
