import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { DataHandle, Prescription } from '~/types'

import AdministrationSection, {
  AdministrationSectionHandle,
  copyAdministrationInstructions,
} from './AdministrationSection'
import LengthOfCourseSection, {
  copyLengthOfCourse,
  LengthOfCourseSectionHandle,
} from './LengthOfCourseSection'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    expandCollapse: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: theme.spacing(-3),
      right: 0,
      textDecoration: 'none',
      color: theme.colors.link,
    },
    administrationBlock: {
      border: theme.constants.tableBorderSelected,
      borderRadius: 2,
      padding: ({ isSimplified }: { isSimplified: boolean }) =>
        isSimplified ? 0 : theme.spacing(2),
      backgroundColor: theme.palette.common.white,
    },
  }),
  { name: 'PrescriptionDetailsSection' },
)

export interface PrescriptionDetailsSectionProps {
  alwaysExpanded?: boolean
  expandFormByDefault?: boolean
  isChewy?: boolean
  isExpandable?: boolean
  isLengthOfCourseRequired?: boolean
  isOrder?: boolean
  isRxPrescription?: boolean
  isVetDiet?: boolean
  onActiveAdminFrequencyChange?: (value: string) => void
  onRefChange?: () => void
  prescription: Prescription
}

export interface PrescriptionDetailsSectionHandle extends DataHandle {}

const PrescriptionDetailsSection = forwardRef<
  PrescriptionDetailsSectionHandle,
  PrescriptionDetailsSectionProps
>(function PrescriptionDetailsSection(
  {
    alwaysExpanded = false,
    expandFormByDefault = false,
    isChewy = false,
    isExpandable = false,
    isLengthOfCourseRequired = false,
    isOrder = false,
    isRxPrescription = false,
    isVetDiet = false,
    onActiveAdminFrequencyChange,
    onRefChange,
    prescription,
  },
  ref,
) {
  const { t } = useTranslation('Dialogs')

  const administrationSectionRef = useRef<AdministrationSectionHandle>(null)
  const lengthOfCourseSectionRef = useRef<LengthOfCourseSectionHandle>(null)

  const [formExpanded, setFormExpanded] = useState(expandFormByDefault)

  const finalFormExpanded = alwaysExpanded || formExpanded

  const classes = useStyles({ isSimplified: !finalFormExpanded })

  useImperativeHandle(ref, () => ({
    get: () => ({
      ...(administrationSectionRef.current?.get() || {}),
      ...(lengthOfCourseSectionRef.current?.get() || {}),
    }),
    validate: () => {
      const isAdministrationSectionValid =
        administrationSectionRef.current?.validate() || false
      const isLengthOfCourseSectionValid =
        lengthOfCourseSectionRef.current?.validate() || false
      if (!isAdministrationSectionValid && !finalFormExpanded && isExpandable) {
        setFormExpanded(true)
      }
      return isAdministrationSectionValid && isLengthOfCourseSectionValid
    },
  }))

  return (
    <Grid container className={classes.root}>
      {isExpandable && (
        <Text
          link
          strong
          className={classes.expandCollapse}
          variant="subheading3"
          onClick={() =>
            setFormExpanded((prevFormExpanded) => !prevFormExpanded)
          }
        >
          {finalFormExpanded
            ? t('Dialogs:PRESCRIPTION_DIALOG.COLLAPSE_FORM')
            : t('Dialogs:PRESCRIPTION_DIALOG.EXPAND_FORM')}
          {finalFormExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Text>
      )}
      {isChewy && (
        <Text strong mb={0.5} mt={1.5} variant="subheading3">
          {t('Dialogs:PRESCRIPTION_DIALOG.ADMINISTRATION_INSTRUCTIONS')}
          &nbsp;
          <Text component="span" variant="body2">
            ({t('Dialogs:PRESCRIPTION_DIALOG.APPEARS_ON_LABEL')})
          </Text>
        </Text>
      )}
      <Grid
        container
        className={isChewy ? classes.administrationBlock : undefined}
      >
        <AdministrationSection
          isChewy={isChewy}
          isOrder={isOrder}
          isRxPrescription={isRxPrescription}
          isVetDiet={isVetDiet}
          prescription={prescription}
          ref={administrationSectionRef}
          simplified={!finalFormExpanded}
          onActiveAdminFrequencyChange={onActiveAdminFrequencyChange}
          onRefChange={onRefChange}
        />
        <Collapse in={finalFormExpanded && !isOrder} style={{ width: '100%' }}>
          <Grid mt={isChewy ? 2 : undefined}>
            <Text strong my={1} variant="subheading3">
              {t('Dialogs:PRESCRIPTION_DIALOG.PERIOD_OF_TIME')}
              {isLengthOfCourseRequired ? '*' : ''}
            </Text>

            <LengthOfCourseSection
              isRequired={isLengthOfCourseRequired}
              prescription={prescription}
              ref={lengthOfCourseSectionRef}
              onRefChange={onRefChange}
            />
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  )
})

export const copyPrescriptionDetailsSection = (prescription: Prescription) => ({
  ...copyAdministrationInstructions(prescription),
  ...copyLengthOfCourse(prescription),
})

export default PrescriptionDetailsSection
