import React from 'react'
import { useTranslation } from 'react-i18next'

import TimelineCard from './TimelineCard'
import TimelineCardContent from './TimelineCardContent'

interface TimelineErrorCardProps {
  date: string
  icon: React.JSXElementConstructor<any>
}

const TimelineErrorCard = ({ date, icon }: TimelineErrorCardProps) => {
  const { t } = useTranslation('Common')
  return (
    <TimelineCard
      date={date}
      icon={icon}
      title={t('Common:THIS_RECORD_CANNOT_BE_DISPLAYED')}
    >
      <TimelineCardContent>
        {t('Common:RESORATION_IS_IN_PROGRESS')}
      </TimelineCardContent>
    </TimelineCard>
  )
}

export default TimelineErrorCard
