import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import PuiInformationPanel from '~/components/common/PuiInformationPanel'
import DialogNames from '~/constants/DialogNames'
import { clearFindingLogsCrcValidationError } from '~/store/actions/soap'
import { getIsLogsAndCatalogLoaded } from '~/store/reducers/problems'
import { getFindingLogsCrcValidationError } from '~/store/reducers/soap'
import useDialog from '~/utils/useDialog'

import { ProblemsContext } from './ProblemsContext'
import ProblemsWidget from './ProblemsWidget'
import { useProblemsNotification } from './utils/useProblemsNotification'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      padding: theme.spacing(3, 1, 1),
    },
  }),
  { name: 'SoapProblemsWidget' },
)

export interface SoapProblemsWidgetProps {
  onHideSoapRail: () => void
}

const SoapProblemsWidget = ({ onHideSoapRail }: SoapProblemsWidgetProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { isRightDrawerOpen, closeRightDrawer, rightDrawerContent } =
    useContext(ProblemsContext)

  const isLogsAndCatalogLoaded = useSelector(getIsLogsAndCatalogLoaded)
  const findingLogsCrcValidationError = useSelector(
    getFindingLogsCrcValidationError,
  )

  const [openSoapExamFindingsValidationErrorDialog] = useDialog(
    DialogNames.SOAP_EXAM_FINDINGS_VALIDATION_ERROR,
    () => dispatch(clearFindingLogsCrcValidationError()),
  )

  useProblemsNotification()

  useEffect(() => {
    if (isLogsAndCatalogLoaded && onHideSoapRail) {
      onHideSoapRail()
    }
  }, [isLogsAndCatalogLoaded])

  useEffect(() => {
    if (findingLogsCrcValidationError) {
      openSoapExamFindingsValidationErrorDialog()
    }
  }, [findingLogsCrcValidationError])

  return (
    <Box id="problems-widget">
      <PuiInformationPanel
        classes={{ informationPanel: classes.paper }}
        isOpened={isRightDrawerOpen}
        onClose={closeRightDrawer}
      >
        {rightDrawerContent}
      </PuiInformationPanel>
      <ProblemsWidget />
    </Box>
  )
}

export default SoapProblemsWidget
