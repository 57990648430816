import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    menuItem: {
      cursor: 'pointer',
      userSelect: 'none',
      padding: theme.spacing(0.5, 1),
      '&&&&:hover': {
        background: '#E5E5E5',
        boxShadow: theme.constants.selectItemSelectedShadow,
      },
    },
    selectedItem: {
      background: '#E5E5E5',
      boxShadow: theme.constants.selectItemSelectedShadow,
    },
    disabled: {
      cursor: 'default',
      background: 'transparent',
      boxShadow: 'none',
      opacity: 0.7,
      '&&&&:hover': {
        background: 'transparent',
        boxShadow: 'none',
      },
    },
  }),
  { name: 'HeaderSelectItem' },
)

export interface HeaderSelectItemProps {
  active?: boolean
  children?: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  onBlur?: () => void
  onSelect: (value: string) => void
  value: string
}

const HeaderSelectItem = ({
  classes: classesProp,
  active,
  children,
  disabled,
  onBlur,
  onSelect,
  value,
}: HeaderSelectItemProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Box
      className={classNames(classes.menuItem, {
        [classes.disabled]: disabled,
        [classes.selectedItem]: active,
      })}
      key={value}
      role="button"
      onBlur={onBlur}
      onClick={disabled ? undefined : () => onSelect(value)}
    >
      <Text strong variant="body2">
        {children}
      </Text>
    </Box>
  )
}

export default HeaderSelectItem
