import { gql } from '@apollo/client'

export const FETCH_CLIENT_CHEWY_EMAILS = gql`
  query FetchEmailsMatchesForClient($personId: ID!) {
    matchChewyUserByPersonId(personId: $personId) {
      email
      hasKyriosId
    }
  }
`

export const FETCH_PETMATCH_DATA = gql`
  query FetchUnmatchedPetsForClient($clientId: ID!) {
    matchChewyPets(id: $clientId) {
      matchedPets {
        rhapsodyPatient {
          rhapsodyId
          name
          gender
          species
          dateOfBirth
          isDeceased
          isActive
        }
        chewyPet {
          rhapsodyId
          name
          gender
          species
          dateOfBirth
          isDeceased
        }
      }
      unmatchedPets {
        rhapsodyId
        name
        gender
        species
        dateOfBirth
        isDeceased
      }
      unmatchedPatients {
        rhapsodyId
        name
        gender
        species
        dateOfBirth
        isActive
        isDeceased
      }
    }
  }
`

export const MATCH_CHEWY_UESR_BY_EMAIL = gql`
  query MatchChewyUserByEmail($email: String!) {
    matchChewyUserByEmail(email: $email) {
      hasKyriosId
    }
  }
`
