import { gql } from '@apollo/client'

import { BUSINESS_APPOINTMENT_TYPE_FIELDS } from '../fragments/appointmentTypes'

export const CREATE_BUSINESS_APPOINTMENT_TYPE = gql`
  ${BUSINESS_APPOINTMENT_TYPE_FIELDS}
  mutation CreateBusinessAppointmentType(
    $businessId: ID!
    $input: CreateBusinessAppointmentTypeInput!
  ) {
    createBusinessAppointmentType(businessId: $businessId, input: $input) {
      ...BusinessAppointmentTypeFields
    }
  }
`

export const UPDATE_BUSINESS_APPOINTMENT_TYPE = gql`
  ${BUSINESS_APPOINTMENT_TYPE_FIELDS}
  mutation UpdateBusinessAppointmentType(
    $id: ID!
    $businessId: ID!
    $input: UpdateBusinessAppointmentTypeInput!
  ) {
    updateBusinessAppointmentType(
      id: $id
      businessId: $businessId
      input: $input
    ) {
      ...BusinessAppointmentTypeFields
    }
  }
`
