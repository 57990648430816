import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Stack } from '@mui/material'
import {
  Calendar,
  Defaults,
  PuiCheckbox,
  PuiTextArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import AgeRangeSelector from '~/components/common/inputs/AgeRangeSelector'
import { getAgeUnits } from '~/store/reducers/constants'
import { DataHandle, VaccineHistoryItem } from '~/types'
import { getAgeUnitIdByTimeOffsetUnit } from '~/utils/timeRangeUtils'
import useFieldsChanged from '~/utils/useFieldsChanged'

export interface VaccineHistoryExpandedDetailsProps {
  edit?: boolean
  onUpdateItem?: (item: VaccineHistoryItem) => void
  order?: VaccineHistoryItem
  vaccine: VaccineHistoryItem
}

export interface VaccineHistoryExpandedDetailsHandle
  extends DataHandle<VaccineHistoryItem> {
  updateNotes: (notes: string) => void
}

const VaccineHistoryExpandedDetails = forwardRef(
  function VaccineHistoryExpandedDetails(
    {
      edit,
      vaccine: vaccineProp,
      order,
      onUpdateItem,
    }: VaccineHistoryExpandedDetailsProps,
    ref: ForwardedRef<VaccineHistoryExpandedDetailsHandle>,
  ) {
    const AgeUnits = useSelector(getAgeUnits)
    const { t } = useTranslation(['Common', 'MedicalHistory'])

    const vaccine = vaccineProp || order

    const defaultDueDate =
      vaccine?.dueDate?.amount || Defaults.DEFAULT_DUE_DATE_AMOUNT
    const defaultDueDateUnitId =
      vaccine?.dueDate?.unitId ||
      getAgeUnitIdByTimeOffsetUnit(Defaults.DEFAULT_DUE_DATE_UNIT, AgeUnits)

    const { fields, validate } = useFields(
      [
        {
          name: 'dateGiven',
          label: t('Common:DATE_GIVEN_VACCINE'),
          validators: ['required'],
          initialValue: vaccine?.dateGiven || vaccine?.date || '',
        },
        { name: 'dueDateAmount', initialValue: defaultDueDate },
        { name: 'dueDateUnitId', initialValue: defaultDueDateUnitId },
        {
          name: 'addReminder',
          label: t(
            'MedicalHistory:VACCINE_HISTORY_EXPANDED_DETAILS.LABEL.ADD_REMINDER',
          ),
          type: 'toggle',
          initialValue: vaccine?.addReminder || false,
        },
        {
          name: 'administeredIn',
          label: t(
            'MedicalHistory:VACCINE_HISTORY_EXPANDED_DETAILS.LABEL.PRACTICE_VACCINE',
          ),
          initialValue: vaccine?.administeredIn || '',
        },
        {
          name: 'notes',
          label: t('Common:NOTES'),
          initialValue: vaccine?.notes || '',
        },
      ],
      false,
    )

    const {
      dateGiven,
      dueDateAmount,
      dueDateUnitId,
      addReminder,
      administeredIn,
      notes,
    } = fields
    const hideReminderToggle =
      edit &&
      dueDateAmount.value === defaultDueDate &&
      dueDateUnitId.value === defaultDueDateUnitId

    const getVaccine = () => ({
      ...vaccine,
      dateGiven: dateGiven.value,
      administeredIn: administeredIn.value,
      dueDate: {
        amount: dueDateAmount.value,
        unitId: dueDateUnitId.value,
      },
      notes: notes.value,
      procedureId: edit ? vaccine.procedureId : vaccine.id,
      addReminder: addReminder.value,
    })

    useFieldsChanged(() => {
      if (onUpdateItem) {
        onUpdateItem(getVaccine())
      }
    }, fields)

    useImperativeHandle(ref, () => ({
      validate,
      updateNotes: notes.setValue,
      get: getVaccine,
    }))

    return (
      <Grid container item direction="column">
        <Stack alignItems="center" direction="row" spacing={2}>
          <Grid item>
            <Calendar field={dateGiven} label={`${dateGiven.label}*`} />
          </Grid>
          <Grid item>
            <AgeRangeSelector
              fromLabel={t('Common:EXPIRATION_DATE_IN')}
              id="due-date-select"
              label={t('Common:EXPIRATION_DATE')}
              minValue={1}
              range={false}
              startUnit={dueDateUnitId.value}
              startValue={dueDateAmount.value}
              onStartChange={dueDateAmount.setValue}
              onStartUnitChange={dueDateUnitId.setValue}
            />
          </Grid>
          {!hideReminderToggle && (
            <Grid item>
              <PuiCheckbox field={addReminder} label={addReminder.label} />
            </Grid>
          )}
        </Stack>
        <Grid item>
          <PuiTextField
            fullWidth
            field={administeredIn}
            label={administeredIn.label}
          />
        </Grid>
        {edit && (
          <Grid item>
            <PuiTextArea field={notes} placeholder={notes.label} />
          </Grid>
        )}
      </Grid>
    )
  },
)

export default VaccineHistoryExpandedDetails
