import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getProceduresIsLoading,
  getProceduresTotalCount,
} from '~/store/reducers/procedures'
import { addSearch } from '~/utils'

import ProcedureDetails from './ProcedureDetails'
import ProceduresBulkPricingLink from './ProceduresBulkPricingLink'
import ProceduresTableComponent from './ProceduresTableComponent'

const ProceduresList = () => {
  const location = useLocation()
  const { procedureId } = useParams()
  const navigate = useNavigate()
  const totalCount = useSelector(getProceduresTotalCount)
  const isLoading = useSelector(getProceduresIsLoading)
  const bulkPricePermissions = useSelector(
    getCRUDByArea(PermissionArea.PROCEDURE_BULK_PRICE),
  )
  const isBulkPriceAvailable = bulkPricePermissions.create

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/procedures'))
  }

  if (isMobile && typeof procedureId !== 'undefined') {
    return <ProcedureDetails itemId={procedureId} onClose={onDetailsClose} />
  }

  const headerButtons = (
    <Grid container justifyContent="space-between" px={3} py={2}>
      <Grid item>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
      <Grid item>{isBulkPriceAvailable && <ProceduresBulkPricingLink />}</Grid>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <ProceduresTableComponent
        headerButtons={headerButtons}
        procedureId={procedureId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default ProceduresList
