import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import GroupSharingModeNames from '~/constants/groupSharingModeNames'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getGroupSharingModes,
} from '~/store/reducers/constants'

export const useGetGroupSharingEnabled = () => {
  const business = useSelector(getCurrentBusiness)
  const GroupSharingModes = useSelector(getGroupSharingModes)

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  if (isPatientSharingEnabled) {
    return false
  }

  const currentGroupSharingModeName = Utils.getConstantName(
    business?.groupSharingModeId,
    GroupSharingModes,
  )
  return [GroupSharingModeNames.ADHOC, GroupSharingModeNames.AUTO].includes(
    currentGroupSharingModeName,
  )
}
