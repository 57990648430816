import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CircularProgress, Grid, IconButton } from '@mui/material'
import * as R from 'ramda'
import {
  Defaults,
  InfiniteLoaderList,
  LanguageUtils,
  PuiTooltip,
  Text,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import {
  fetchMoreProblemLogHistory,
  fetchProblemLogHistory,
} from '~/store/actions/problems'
import { useIsSoapLocked } from '~/store/hooks/soap'
import {
  getIsProblemLogHistoryLoading,
  getProblemLogHistoryIdsList,
  getProblemLogHistoryMap,
  getProblemLogHistoryTotalCount,
} from '~/store/reducers/problems'
import { getPatientId, getSoapId } from '~/store/reducers/soap'
import { Problem } from '~/types'

import IdentifiedProblemDetailsHistoryItem from './IdentifiedProblemDetailsHistoryItem'

interface IdentifiedProblemDetailsHistoryProps {
  problem: Problem
}

const IdentifiedProblemDetailsHistory = ({
  problem,
}: IdentifiedProblemDetailsHistoryProps) => {
  const { t } = useTranslation('Soap')
  const dispatch = useDispatch()

  const isReadOnly = useIsSoapLocked()

  const soapId = useSelector(getSoapId)
  const patientId = useSelector(getPatientId)
  const isProblemLogHistoryLoading = useSelector(getIsProblemLogHistoryLoading)
  const problemLogHistoryMap = useSelector(getProblemLogHistoryMap)
  const problemLogHistoryIdsList = useSelector(getProblemLogHistoryIdsList)
  const problemId = problem.id
  const totalCount = useSelector(getProblemLogHistoryTotalCount)

  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (patientId && soapId) {
      dispatch(
        fetchProblemLogHistory(
          problemId!,
          patientId,
          soapId,
          0,
          Defaults.PROBLEMS_HISTORY_LOAD_COUNT,
        ),
      )
    }
  }, [patientId, problemId, soapId])

  if (isProblemLogHistoryLoading) {
    return <CircularProgress size={20} />
  }

  if (R.isEmpty(problemLogHistoryIdsList)) {
    return null
  }

  const isItemLoaded = (index: number) =>
    Boolean(problemLogHistoryIdsList[index])

  const loadMoreItems = (startIndex: number) => {
    if (startIndex > 0 && patientId && soapId) {
      dispatch(
        fetchMoreProblemLogHistory(
          problemId!,
          patientId,
          soapId,
          startIndex,
          Defaults.PROBLEMS_HISTORY_LOAD_COUNT,
        ),
      )
    }
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Text
          strong
          alignItems="center"
          color="primary"
          display="flex"
          variant="subheading3"
        >
          {t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.HISTORY_OF_PROBLEM_NAME', {
            problemName: LanguageUtils.getTranslatedFieldName(problem),
          })}
          <PuiTooltip
            tooltipPlacement="top"
            tooltipText={t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.HISTORY_HINT')}
          >
            <InfoIcon color="primary" />
          </PuiTooltip>
        </Text>
        {!isReadOnly && (
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Grid>
      {expanded ? (
        <InfiniteLoaderList
          isItemLoaded={isItemLoaded}
          itemCount={totalCount}
          itemData={problemLogHistoryIdsList}
          itemSpacing={0}
          loadMoreItems={loadMoreItems}
          style={{ height: 250 }}
        >
          {(itemId) => {
            const historyItem = problemLogHistoryMap[itemId]
            return historyItem ? (
              <IdentifiedProblemDetailsHistoryItem
                historyItem={historyItem}
                key={itemId}
                problem={problem}
              />
            ) : null
          }}
        </InfiniteLoaderList>
      ) : null}
    </>
  )
}

export default IdentifiedProblemDetailsHistory
