import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getContactTypes } from '~/store/reducers/constants'

export interface ContactTypeCellProps {
  type: string
}

const ContactTypeCell = (item: ContactTypeCellProps) => {
  const ContactTypes = useSelector(getContactTypes)

  return LanguageUtils.getConstantTranslatedName(item.type, ContactTypes)
}

export default ContactTypeCell
