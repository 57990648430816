import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

import { getRoom, getSoapPrimaryComplaint } from '~/store/reducers/soap'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
    titleCell: {
      width: 150,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'SoapInformationLocked' },
)

export interface SoapInformationLockedProps {
  classes: ClassesType<typeof useStyles>
}

const SoapInformationLocked = ({
  classes: classesProp,
}: SoapInformationLockedProps) => {
  const classes = useStyles({ classes: classesProp })
  const primaryComplaint = useSelector(getSoapPrimaryComplaint)
  const spaceName = useSelector(getRoom)
  const { t } = useTranslation('Common')

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item className={classes.titleContainer} p={2} xs={2}>
        <Text variant="h4">{t('Common:INFORMATION')}</Text>
      </Grid>
      <Grid container item xs pb={3} pl={1} pr={2} pt={1}>
        <Table className={classes.table}>
          <TableBody>
            {Boolean(primaryComplaint) && (
              <TableRow>
                <TableCell
                  className={classNames(classes.tableCell, classes.titleCell)}
                >
                  {t('Common:REASON_FOR_VISIT')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {primaryComplaint}
                </TableCell>
              </TableRow>
            )}
            {spaceName && (
              <TableRow>
                <TableCell
                  className={classNames(classes.tableCell, classes.titleCell)}
                >
                  {t('Common:SPACE_ASSIGNED')}
                </TableCell>
                <TableCell className={classes.tableCell}>{spaceName}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default SoapInformationLocked
