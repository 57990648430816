import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { BaseSearchRequestParams, Task } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchTasks = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
  startDate?: string,
  endDate?: string,
  assignedTo?: string,
  createdBy?: string,
  type?: string,
  status?: string,
  unassigned?: boolean,
  forClient?: boolean,
) =>
  request(
    getAPIPath('tasks'),
    {
      method: 'GET',
      params: {
        businessId,
        from,
        to,
        query,
        unassigned,
        startDate,
        endDate,
        assignedTo,
        createdBy,
        type,
        status,
        forClient,
      },
    },
    true,
    Schema.tasksList,
  )

export const fetchTask = (businessId: string, taskId: string) =>
  request(
    getAPIPath(`tasks/${taskId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.task,
  )

export const createTasks = (businessId: string, tasks: Partial<Task>[]) =>
  request(
    getAPIPath('tasks/bulk'),
    { method: 'POST', data: tasks, params: { businessId } },
    true,
    Schema.taskArray,
  )

export const createTask = (businessId: string, task: Partial<Task>) =>
  request(
    getAPIPath('tasks'),
    { method: 'POST', data: task, params: { businessId } },
    true,
    Schema.task,
  )

export const editTasks = (businessId: string, tasks: Partial<Task>[]) =>
  request(
    getAPIPath('tasks/bulk'),
    { method: 'PUT', data: tasks, params: { businessId } },
    true,
    Schema.taskArray,
  )

export const editTask = (businessId: string, task: Partial<Task>) =>
  request(
    getAPIPath(`tasks/${task.id}`),
    { method: 'PUT', data: task, params: { businessId } },
    true,
    Schema.task,
  )

export const partialEditTask = (
  businessId: string,
  task: Partial<Task>,
  date: string | Nil,
) =>
  request(
    getAPIPath(`tasks/${task.id}`),
    { method: 'PATCH', data: task, params: { businessId, date } },
    true,
    Schema.task,
  )

export const deleteTasks = (businessId: string, taskIds: string[]) =>
  request(
    getAPIPath('tasks/bulk'),
    { method: 'DELETE', data: taskIds, params: { businessId } },
    true,
  )

export const deleteTask = (businessId: string, taskId: string) =>
  request(
    getAPIPath(`tasks/${taskId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const editTaskState = (
  businessId: string,
  taskId: string,
  stateId: string,
) =>
  request(
    getAPIPath(`tasks/${taskId}/state/${stateId}`),
    { method: 'PATCH', params: { businessId } },
    true,
    Schema.task,
  )

export const searchTasks = (
  businessId: string,
  { max, query }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('tasks'),
    {
      method: 'GET',
      params: { businessId, query, from: 0, to: max },
    },
    true,
  )

export const fetchSoapTasks = (
  businessId: string,
  soapId: string,
  date: string | Nil,
) =>
  request(
    getAPIPath(`tasks/soap/${soapId}`),
    {
      method: 'GET',
      params: {
        businessId,
        date,
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT * 4,
      },
    },
    true,
    Schema.tasksList,
  )

export const fetchSoapTasksV2 = (
  businessId: string,
  soapId: string,
  date: string | Nil,
  includeChildren: boolean = true,
) =>
  request(
    getAPIPath(`v2/tasks/soap/${soapId}`),
    {
      method: 'GET',
      params: {
        businessId,
        date,
        soapId,
        offset: 0,
        limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT * 4,
        includeChildren,
      },
    },
    true,
    Schema.tasksList,
  )

export const fetchTaskV2 = (businessId: string, taskId: string) =>
  request(
    getAPIPath(`v2/tasks/${taskId}`),
    { method: 'GET', params: { businessId, taskId } },
    true,
    Schema.task,
  )
