import { Task as TaskSaga } from '@redux-saga/types'
import * as R from 'ramda'
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, DateUtils, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getUrlTimetableDate } from '~/components/dashboard/timetable/timetableUtils'
import FeatureToggle from '~/constants/featureToggle'
import { NotificationAreaNames } from '~/constants/notifications'
import { TaskContext } from '~/constants/taskConstants'
import i18n from '~/locales/i18n'
import { TableFilter, Task } from '~/types'

import { updateSearchHighlights } from '../actions/search'
import {
  addTaskToSoapList,
  createTask,
  createTaskFailure,
  createTasks,
  createTasksFailure,
  createTasksSuccess,
  createTaskSuccess,
  deleteTask,
  deleteTaskFailure,
  deleteTasks,
  deleteTasksFailure,
  deleteTasksSuccess,
  deleteTaskSuccess,
  editTask,
  editTaskFailure,
  editTasks,
  editTasksFailure,
  editTasksSuccess,
  editTaskState,
  editTaskStateFailure,
  editTaskStateSuccess,
  editTaskSuccess,
  fetchMoreItemsForTasksList,
  fetchMoreItemsForTasksListFailure,
  fetchMoreItemsForTasksListSuccess,
  fetchSoapTasks as fetchSoapTasksAction,
  fetchSoapTasksFailure,
  fetchSoapTasksIgnored,
  fetchSoapTasksSuccess,
  fetchTask,
  fetchTaskFailure,
  fetchTasksList,
  fetchTasksListFailure,
  fetchTasksListSuccess,
  fetchTaskSuccess,
  partialEditTask,
  partialEditTaskFailure,
  partialEditTaskSuccess,
  removeTaskFromList,
  wsTaskCreate,
  wsTaskCreateFailure,
  wsTaskCreateSuccess,
  wsTaskDelete,
  wsTaskDeleteFailure,
  wsTaskDeleteSuccess,
  wsTaskUpdate,
  wsTaskUpdateFailure,
  wsTaskUpdateSuccess,
} from '../actions/tasks'
import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import {
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASKS,
  CREATE_TASKS_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASKS,
  DELETE_TASKS_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_STATE,
  EDIT_TASK_SUCCESS,
  EDIT_TASKS,
  EDIT_TASKS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST,
  FETCH_SOAP_TASKS,
  FETCH_SOAP_TASKS_FAILURE,
  FETCH_SOAP_TASKS_IGNORED,
  FETCH_SOAP_TASKS_SUCCESS,
  FETCH_TASK,
  FETCH_TASKS_LIST,
  PARTIAL_EDIT_TASK,
  PARTIAL_EDIT_TASK_SUCCESS,
  UPDATE_SOAP_TASKS_SELECTED_DATE,
  UPDATE_TASKS,
  WS_TASK_CREATE,
  WS_TASK_DELETE,
  WS_TASK_UPDATE,
} from '../actions/types/tasks'
import {
  FETCH_WHITEBOARD_TREATMENTS_FAILURE,
  FETCH_WHITEBOARD_TREATMENTS_IGNORED,
  FETCH_WHITEBOARD_TREATMENTS_SUCCESS,
} from '../actions/types/whiteboard'
import { fetchWhiteboardTreatments as fetchWhiteboardTreatmentsAction } from '../actions/whiteboard'
import { finishLoading, startLoading } from '../duck/progress'
import { notifyNetworkError } from '../duck/uiAlerts'
import { getFeatureToggle } from '../reducers/constants'
import { getSoapId } from '../reducers/soap'
import {
  getSoapTasksList,
  getSoapTasksSelectedDate,
  getTasksListFilters,
} from '../reducers/tasks'
import { waitForSubsequentCall } from './utils'
import {
  updateNotificationsAndTotalCountsForArea,
  updateNotificationsForArea,
  // @ts-ignore
} from './utils/notifications'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const normalizedFields = [
  'assigned',
  'patient',
  'client',
  'appointment',
  'contact',
  'createdBy',
  'modifiedBy',
  'order',
]

const getValueOrUndefined = (value1: string | Nil, value2: string | Nil) =>
  typeof value1 === 'undefined'
    ? typeof value2 === 'undefined'
      ? undefined
      : value2
    : value1

const denormalizeTask = (task: Partial<Task>): Partial<Task> =>
  R.omit(normalizedFields, {
    ...task,
    clientId: getValueOrUndefined(task.clientId, task.client),
    patientId: getValueOrUndefined(task.patientId, task.patient),
    contactId: getValueOrUndefined(task.contactId, task.contact),
    appointmentId: getValueOrUndefined(task.appointmentId, task.appointment),
    assigneeId: getValueOrUndefined(task.assigneeId, task.assigned),
  })

const denormalizeTasks = (tasks: Partial<Task>[]) => tasks.map(denormalizeTask)

function* waitForSoapBackgroundSync() {
  yield waitForSubsequentCall({
    action: fetchSoapTasksAction(),
    successType: FETCH_SOAP_TASKS_SUCCESS,
    failureType: FETCH_SOAP_TASKS_FAILURE,
    ignoredType: FETCH_SOAP_TASKS_IGNORED,
  })
}

function* waitForWhiteboardSync() {
  yield waitForSubsequentCall({
    action: fetchWhiteboardTreatmentsAction(),
    successType: FETCH_WHITEBOARD_TREATMENTS_SUCCESS,
    failureType: FETCH_WHITEBOARD_TREATMENTS_FAILURE,
    ignoredType: FETCH_WHITEBOARD_TREATMENTS_IGNORED,
  })
}

function* waitForSoapAndWhiteboardSync() {
  yield all([waitForSoapBackgroundSync(), waitForWhiteboardSync()])
}

function* getFilters(query?: string | Nil) {
  const filters: Record<string, TableFilter> = yield select(getTasksListFilters)
  const [startDate, endDate] = (filters.dueDate?.value || []) as string[]
  const assignedTo = filters.assignedTo?.value
  const createdBy = filters.createdBy?.value
  const type = filters.type?.value
  const statuses = (filters.statuses?.value || []) as string[]
  const forClient = filters.forClient?.value
  const unassigned = ((filters.assignedTo?.humanReadable || '') as string)
    .split(', ')
    .some((item) => item === i18n.t('Common:UNASSIGNED'))

  return [
    startDate,
    endDate,
    assignedTo,
    createdBy,
    type,
    statuses.join(','),
    unassigned,
    query ? null : forClient,
  ]
}

function* removeTaskWithChangedState(taskId: string, stateId?: string) {
  const filters: Record<string, TableFilter> = yield select(getTasksListFilters)
  const statuses = (filters.statuses?.value || []) as string[]

  const shouldRemoveFromList =
    stateId && !statuses.includes(stateId) && !filters.forClient?.value
  if (shouldRemoveFromList) {
    yield put(removeTaskFromList(taskId))
  }
}

export function* fetchTasksListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchTasksList>) {
  try {
    yield put(startLoading('tasks'))
    const filters: any[] = yield call(getFilters, query)
    const {
      result: {
        data: list,
        totalCount,
        highlights,
        unreadNotificationsTotalCount,
      },
      entities,
    } = yield* requestAPI(API.fetchTasks, from, to, query, ...filters)

    yield call(updateNotificationsAndTotalCountsForArea, {
      notificatedEntities: entities.tasks,
      unreadNotificationsTotalCount,
      notificationAreaName: NotificationAreaNames.TASKS,
    })

    yield put(updateSearchHighlights(highlights, totalCount))
    yield call(updateEntities, entities)
    yield put(fetchTasksListSuccess(list, totalCount))
    yield put(finishLoading('tasks'))
  } catch (error) {
    yield put(fetchTasksListFailure(error as ApiError))
    yield put(finishLoading('tasks'))
  }
}

export function* fetchMoreItemsForTasksListCancelable({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForTasksList>) {
  try {
    const filters: string[] = yield call(getFilters, query)
    const {
      result: { data: list, totalCount, unreadNotificationsTotalCount },
      entities,
    } = yield* requestAPI(API.fetchTasks, from, to, query, ...filters)

    yield call(updateNotificationsAndTotalCountsForArea, {
      notificatedEntities: entities.tasks,
      unreadNotificationsTotalCount,
      notificationAreaName: NotificationAreaNames.TASKS,
    })

    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForTasksListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForTasksListFailure(error as ApiError))
  }
}

export function* fetchMoreItemsForTasksListSaga(
  params: ReturnType<typeof fetchMoreItemsForTasksList>,
) {
  const task: TaskSaga = yield fork(
    fetchMoreItemsForTasksListCancelable,
    params,
  )
  yield take([FETCH_TASKS_LIST])
  yield cancel(task)
}

export function* fetchTaskSaga({ id }: ReturnType<typeof fetchTask>) {
  try {
    const isTaskOptimizationEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.TASK_OPTIMIZATION),
    )
    const { entities } = yield* requestAPI(
      isTaskOptimizationEnabled ? API.fetchTaskV2 : API.fetchTask,
      id,
    )
    yield call(updateEntities, entities)
    yield call(updateNotificationsForArea, {
      notificatedEntities: entities.tasks,
      notificationAreaName: NotificationAreaNames.TASKS,
    })
    yield put(fetchTaskSuccess())
  } catch (error) {
    yield put(fetchTaskFailure(error as ApiError))
  }
}

export function* createTasksSaga({ tasks }: ReturnType<typeof createTasks>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.createTasks,
      denormalizeTasks(tasks),
    )
    yield call(updateEntities, entities)
    yield call(waitForSoapBackgroundSync)
    yield put(createTasksSuccess(result))
  } catch (e) {
    const error = e as ApiError
    if (error?.response?.status === 409) {
      yield notifyNetworkError(error)
    }
    yield put(createTasksFailure(error))
  }
}

export function* createTaskSaga({ task }: ReturnType<typeof createTask>) {
  try {
    const soapId: string = yield select(getSoapId)
    const { result, entities } = yield* requestAPI(
      API.createTask,
      denormalizeTask(task),
    )
    yield call(updateEntities, entities)
    yield call(waitForSoapAndWhiteboardSync)
    if (soapId && task.soapId === soapId) {
      yield put(addTaskToSoapList(result))
    }
    yield put(createTaskSuccess(result))
    yield put(fetchTimelineAction())
  } catch (e) {
    const error = e as ApiError
    if (error?.response?.status === 409) {
      yield notifyNetworkError(error)
    }
    yield put(createTaskFailure(error))
  }
}

export function* editTasksSaga({ tasks }: ReturnType<typeof editTasks>) {
  try {
    const { entities } = yield* requestAPI(
      API.editTasks,
      denormalizeTasks(tasks),
    )
    yield call(updateEntities, entities)
    yield call(waitForSoapBackgroundSync)
    yield put(editTasksSuccess())
  } catch (error) {
    yield put(editTasksFailure(error as ApiError))
  }
}

export function* editTaskSaga({
  task,
  updateTasks = true,
}: ReturnType<typeof editTask>) {
  try {
    const { entities } = yield* requestAPI(API.editTask, denormalizeTask(task))
    yield call(updateEntities, entities)
    if (updateTasks) {
      yield call(waitForSoapAndWhiteboardSync)
    }
    yield removeTaskWithChangedState(task.id, task.stateId)
    yield put(editTaskSuccess())
  } catch (error) {
    yield put(editTaskFailure(error as ApiError))
  }
}

const ContextSelectedDateMap: Record<TaskContext, () => Generator> = {
  [TaskContext.SOAP]: function* soapDateSelect() {
    return yield select(getSoapTasksSelectedDate)
  },
  [TaskContext.WHITEBOARD]: function* whiteboardDateSelect() {
    return yield call(getUrlTimetableDate)
  },
}

export function* partialEditTaskSaga({
  context,
  task,
}: ReturnType<typeof partialEditTask>) {
  try {
    const dateSelect = context && ContextSelectedDateMap[context]
    const selectedDate: string | undefined = dateSelect
      ? yield* dateSelect()
      : undefined

    const { entities } = yield* requestAPI(
      API.partialEditTask,
      denormalizeTask(task),
      DateUtils.serializeDate(selectedDate),
    )
    yield call(updateEntities, entities)
    yield removeTaskWithChangedState(task.id, task.stateId)
    yield put(partialEditTaskSuccess())
  } catch (error) {
    yield put(partialEditTaskFailure(error as ApiError))
  }
}

export function* deleteTasksSaga({ taskIds }: ReturnType<typeof deleteTasks>) {
  try {
    yield* requestAPI(API.deleteTasks, taskIds)
    yield put(deleteTasksSuccess(taskIds))
  } catch (error) {
    yield put(deleteTasksFailure(error as ApiError))
  }
}

export function* deleteTaskSaga({ taskId }: ReturnType<typeof deleteTask>) {
  try {
    yield* requestAPI(API.deleteTask, taskId)
    yield put(fetchTimelineAction())
    yield put(deleteTaskSuccess(taskId))
  } catch (error) {
    yield put(deleteTaskFailure(error as ApiError))
  }
}

export function* editTaskStateSaga({
  taskId,
  stateId,
}: ReturnType<typeof editTaskState>) {
  try {
    const { entities } = yield* requestAPI(API.editTaskState, taskId, stateId)
    yield call(updateEntities, entities)
    yield call(waitForSoapBackgroundSync)
    yield removeTaskWithChangedState(taskId, stateId)
    yield put(editTaskStateSuccess())
  } catch (error) {
    yield put(editTaskStateFailure(error as ApiError))
  }
}

export function* fetchSoapTasks() {
  try {
    const isTaskOptimizationEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.TASK_OPTIMIZATION),
    )
    const soapId: string = yield select(getSoapId)
    const date: string = yield select(getSoapTasksSelectedDate)

    if (!soapId || !date) {
      yield put(fetchSoapTasksIgnored())
      return
    }

    yield put(startLoading('soap-tasks'))
    const {
      result: { data: list },
      entities,
    } = yield* requestAPI(
      isTaskOptimizationEnabled ? API.fetchSoapTasksV2 : API.fetchSoapTasks,
      soapId,
      DateUtils.serializeDate(date),
    )
    yield call(updateEntities, entities)
    yield put(fetchSoapTasksSuccess(list))
    yield put(finishLoading('soap-tasks'))
  } catch (error) {
    yield put(fetchSoapTasksFailure(error as ApiError))
    yield put(finishLoading('soap-tasks'))
  }
}

function* wsTaskCreateSagaCancelable({
  body,
}: ReturnType<typeof wsTaskCreate>) {
  try {
    const { entities, result } = body
    yield put(addTaskToSoapList(result.tasks[0]))
    yield put(wsTaskCreateSuccess(result.tasks[0]))
    yield call(updateEntities, entities)
  } catch (error) {
    yield put(wsTaskCreateFailure(error as ApiError))
  }
}

function* wsTaskCreateSaga(params: ReturnType<typeof wsTaskCreate>) {
  const wsCreateTask: TaskSaga = yield fork(wsTaskCreateSagaCancelable, params)
  yield take([
    CREATE_TASK,
    CREATE_TASK_SUCCESS,
    CREATE_TASKS,
    CREATE_TASKS_SUCCESS,
  ])
  yield cancel(wsCreateTask)
}

function* wsTaskDeleteSagaCancelable({
  body,
}: ReturnType<typeof wsTaskDelete>) {
  try {
    const { tasks } = body
    const taskIds = R.pluck('id', tasks)
    const soapTasksList: string[] = yield select(getSoapTasksList)
    const pendingDeleteTaskIds = R.intersection<string>(taskIds, soapTasksList)
    yield put(wsTaskDeleteSuccess(pendingDeleteTaskIds || []))
  } catch (error) {
    yield put(wsTaskDeleteFailure(error as ApiError))
  }
}

function* wsTaskDeleteSaga(params: ReturnType<typeof wsTaskDelete>) {
  const deleteWsTask: TaskSaga = yield fork(wsTaskDeleteSagaCancelable, params)
  yield take([
    DELETE_TASK,
    DELETE_TASK_SUCCESS,
    DELETE_TASKS,
    DELETE_TASKS_SUCCESS,
  ])
  yield cancel(deleteWsTask)
}

function* wsTaskUpdateSagaCancelable({
  body,
}: ReturnType<typeof wsTaskUpdate>) {
  try {
    const { entities, result } = body
    yield put(wsTaskUpdateSuccess(result.tasks))
    yield call(updateEntities, entities)
  } catch (error) {
    yield put(wsTaskUpdateFailure(error as ApiError))
  }
}

function* wsTaskUpdateSaga(params: ReturnType<typeof wsTaskUpdate>) {
  const updateTask: TaskSaga = yield fork(wsTaskUpdateSagaCancelable, params)
  yield take([
    UPDATE_TASKS,
    EDIT_TASKS,
    EDIT_TASKS_SUCCESS,
    EDIT_TASK,
    EDIT_TASK_SUCCESS,
    PARTIAL_EDIT_TASK,
    PARTIAL_EDIT_TASK_SUCCESS,
  ])
  yield cancel(updateTask)
}

function* watchFetchTasksList() {
  yield takeLatest(FETCH_TASKS_LIST, fetchTasksListSaga)
}

function* watchFetchMoreItemsForTasksList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_TASKS_LIST,
    fetchMoreItemsForTasksListSaga,
  )
}

function* watchFetchTask() {
  yield takeLeading(FETCH_TASK, fetchTaskSaga)
}

function* watchCreateTasks() {
  yield takeLeading(CREATE_TASKS, createTasksSaga)
}

function* watchCreateTask() {
  yield takeLeading(CREATE_TASK, createTaskSaga)
}

function* watchEditTasks() {
  yield takeLeading(EDIT_TASKS, editTasksSaga)
}

function* watchEditTask() {
  yield takeLeading(EDIT_TASK, editTaskSaga)
}

function* watchPartialEditTask() {
  yield takeLeading(PARTIAL_EDIT_TASK, partialEditTaskSaga)
}

function* watchDeleteTasks() {
  yield takeLeading(DELETE_TASKS, deleteTasksSaga)
}

function* watchDeleteTask() {
  yield takeLeading(DELETE_TASK, deleteTaskSaga)
}

function* watchEditTaskState() {
  yield takeLeading(EDIT_TASK_STATE, editTaskStateSaga)
}

function* watchFetchSoapTasks() {
  yield takeLeading(
    [FETCH_SOAP_TASKS, UPDATE_SOAP_TASKS_SELECTED_DATE],
    fetchSoapTasks,
  )
}

function* watchWsTaskCreateSaga() {
  yield takeEvery(WS_TASK_CREATE, wsTaskCreateSaga)
}

function* watchWsTaskDeleteSaga() {
  yield takeEvery(WS_TASK_DELETE, wsTaskDeleteSaga)
}

function* watchWsTaskUpdateSaga() {
  yield takeEvery(WS_TASK_UPDATE, wsTaskUpdateSaga)
}

export default function* tasksSaga() {
  yield all([
    watchFetchTasksList(),
    watchFetchMoreItemsForTasksList(),
    watchFetchTask(),
    watchCreateTasks(),
    watchCreateTask(),
    watchEditTasks(),
    watchEditTask(),
    watchPartialEditTask(),
    watchDeleteTasks(),
    watchDeleteTask(),
    watchEditTaskState(),
    watchFetchSoapTasks(),
    watchWsTaskCreateSaga(),
    watchWsTaskDeleteSaga(),
    watchWsTaskUpdateSaga(),
  ])
}
