import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// see https://reacttraining.com/react-router/web/guides/scroll-restoration
export interface ScrollToTopProps {
  children: JSX.Element
}

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, search])

  return children
}

export default ScrollToTop
