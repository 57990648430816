import React, { JSXElementConstructor } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import {
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import { OrderComponentsActions } from '~/components/dashboard/soap/rail/summary/orders/orderSummaryUtils'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getChargesLoading } from '~/store/duck/charges'
import { useIsDeclinableOrDeletable } from '~/store/hooks/prescription'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSelectedOrder } from '~/store/reducers/orders'
import { InvoiceLineItem, InvoiceOrEstimate, Order } from '~/types'
import {
  convertInvoiceLineItemToSoapLog,
  convertRetailOrderLineItemToSoapLog,
} from '~/utils/orderUtils'

import OrderItemButtonStatus from '../utils/OrderItemButtonStatus'
import { useChargeInformationPanelPermissions } from '../utils/useChargeInformationPanelPermissions'
import {
  useDeclineLineItem,
  useDeclineOrderItem,
  useGetIsDeclinedLineItem,
} from '../utils/useDeclineOrderItem'
import ChargeBundleItemButtonsActions from './ChargeBundleItemButtonsActions'

export interface ChargeItemButtonActionsProps {
  ComponentsActions?: Partial<Record<OrderType, JSXElementConstructor<any>>>
  disabled: boolean
  invoice: InvoiceOrEstimate | Nil
  isInvoice?: boolean
  isLoading: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  onDelete: () => void
  onUpdateItem: (item: InvoiceLineItem | RetailOrderLineItem) => void
}

const ChargeItemButtonActions = ({
  disabled,
  invoice,
  item,
  onDelete,
  onUpdateItem,
  ComponentsActions,
  isInvoice,
  isLoading = false,
}: ChargeItemButtonActionsProps) => {
  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)
  const soapOrder = useSelector(
    getSelectedOrder(item.logType as OrderType, item.logId),
  )
  const isDeclined = useGetIsDeclinedLineItem(item)

  const chargesLoading = useSelector(getChargesLoading)

  const order = soapOrder
  const convertedToOrderItem = !soapOrder
    ? isItemRetailOrderLineItem
      ? convertRetailOrderLineItemToSoapLog(item)
      : convertInvoiceLineItemToSoapLog(item)
    : ({} as Order)

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const type =
    order?.type ||
    (item.logType as OrderType) ||
    (!isItemRetailOrderLineItem && isEditPostedChargesEnabled
      ? item.type
      : undefined)

  const onDeclineOrder = useDeclineOrderItem(order)
  const onDeclineLineItem = useDeclineLineItem(item)
  const isDeclinableOrDeletable = useIsDeclinableOrDeletable({
    orderType: order?.type || convertedToOrderItem?.type,
    stateId: order?.stateId || convertedToOrderItem?.stateId,
    prescriptionType:
      order?.prescriptionType || convertedToOrderItem?.prescriptionType,
    origin: order?.origin || convertedToOrderItem?.origin,
  })

  const IconItemComponent =
    (ComponentsActions && ComponentsActions[type]) ||
    OrderComponentsActions[type]

  const orderData = order || convertedToOrderItem

  const data = isChargeSheetEnabled
    ? item
    : {
        ...orderData,
        group: isItemRetailOrderLineItem ? null : item.group,
      }

  const isPrepaidAndUsedZero = isRetailOrderLineItem(data) // RetailOrderLineItem has no concept of prepaid.
    ? false
    : Boolean(data.prepaid) && data.usedQuantity === 0
  const showOrderButtonStatus = !isChargeSheetEnabled || !isPrepaidAndUsedZero

  const permissions = useChargeInformationPanelPermissions(
    item,
    isInvoice,
    isPrepaidAndUsedZero,
  )

  if (isGroupedInvoiceItem(item) && !isItemRetailOrderLineItem) {
    return (
      <ChargeBundleItemButtonsActions
        disabled={disabled}
        invoice={invoice}
        item={item}
        loading={isLoading ? false : chargesLoading}
        onDelete={onDelete}
        onUpdateItem={onUpdateItem}
      />
    )
  }

  if (
    !order &&
    !item.logType &&
    (isItemRetailOrderLineItem ? true : !item.type)
  ) {
    return null
  }

  return (
    <Grid container>
      {showOrderButtonStatus && (
        <Grid item mb={1}>
          {/* TODO will be removed once crud endpoint for charge sheet line item will be ready */}
          {/* @ts-ignore */}
          <OrderItemButtonStatus
            disabled={
              isEditPostedChargesEnabled
                ? R.path(['Status', 'disabled'], permissions)
                : disabled
            }
            order={data}
          />
        </Grid>
      )}
      {IconItemComponent && (
        <IconItemComponent
          hideDivider
          align="flex-start"
          declined={isDeclined}
          isInvoice={isInvoice}
          isItemRetailOrderLineItem={isItemRetailOrderLineItem}
          isLineItem={Boolean(ComponentsActions)}
          isPrepaidAndUsedZero={isPrepaidAndUsedZero}
          loading={isLoading ? false : chargesLoading}
          order={data}
          permissions={ComponentsActions ? permissions : null}
          readOnly={disabled}
          size="large"
          onDecline={
            disabled || !isDeclinableOrDeletable
              ? undefined
              : !ComponentsActions
                ? onDeclineOrder
                : onDeclineLineItem
          }
          onDelete={isDeclinableOrDeletable ? onDelete : undefined}
          onUpdateNotes={undefined}
        />
      )}
    </Grid>
  )
}

export default ChargeItemButtonActions
