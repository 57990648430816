import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { AlertColorLevel } from '~/constants/alertColors'

const useStyles = makeStyles(
  (theme) => ({
    alertLabelIcon: {
      marginRight: theme.spacing(1),
      fontSize: '1.5rem',
    },
    alertLabelIconRow: {
      marginRight: 0,
      fontSize: '1.2rem',
    },
    alertLabelRoot: {
      height: theme.spacing(3),
      maxHeight: theme.spacing(3),
      borderRadius: 0,
      padding: theme.spacing(0, 0.5),
    },
    alertLabelRootRow: {
      height: '100%',
      maxHeight: '100%',
      maxWidth: theme.spacing(3),
    },
  }),
  { name: 'CardAlert' },
)

export interface CardAlertProps {
  alertColorId: string | Nil
  alertTypes: string[]
  classes?: ClassesType<typeof useStyles>
  isDense?: boolean
}

const CardAlert = ({
  classes: classesProp,
  alertColorId,
  alertTypes,
  isDense,
}: CardAlertProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <AlertLabel
      showIcon
      alertColorId={alertColorId}
      alertColorLevel={AlertColorLevel.PATIENT}
      alertTypes={alertTypes}
      classes={{
        icon: classNames(classes.alertLabelIcon, {
          [classes.alertLabelIconRow]: isDense,
        }),
        root: classNames(classes.alertLabelRoot, {
          [classes.alertLabelRootRow]: isDense,
        }),
      }}
      showText={!isDense}
      showTooltip={isDense}
    />
  )
}

export default CardAlert
