import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import DetailsBackButton from '../DetailsBackButton'
import CoPetParent from './CoPetParent'

const CoPetParentPage = () => {
  const { t } = useTranslation('Common')
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const { clientId = '', coparentId = '' } = useParams()

  const onBackButtonClick = () => {
    goBack()
  }

  return (
    <>
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Common:BACK_TO_CLIENT_SUMMARY')}
      </DetailsBackButton>
      <Grid container item p={3}>
        <CoPetParent showTitle clientId={clientId} coparentId={coparentId} />
      </Grid>
    </>
  )
}

export default CoPetParentPage
