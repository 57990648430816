import { createContext } from 'react'
import { Field, Nil } from '@pbt/pbt-ui-components'

import { CRUDOrderOptions } from '~/types'

export interface PrescriptionActionContextType {
  automaticallyCreateTaskField: Field
  disableDrafts: boolean
  isAutomateChewyRxTasksEnabled: boolean
  isChewyCatalogItemNotLinked: boolean
  isCompounded: boolean
  isDvmLicenseRequired: boolean
  isEdit: boolean
  onApprove: () => void
  onDecline: (declineReason: string) => void
  onEmail: () => void
  onPrint: () => void
  onSave: (options?: CRUDOrderOptions, closeAfterSave?: boolean) => void
  onSaveDraft: () => void
  patientId: string
  prescriptionSignerId: string | Nil
  stateId?: string
}

export interface PrescriptionActionExpandedContextType
  extends PrescriptionActionContextType {
  canApproveAndSubmitChewyRx: boolean
  isLoadingOrUpdating: boolean
  isSending: boolean
  needsDvmLicenseEntry: boolean
  needsDvmLicenseEntryTooltip: string | null
}

export const PrescriptionActionContext =
  createContext<PrescriptionActionContextType>(
    {} as PrescriptionActionContextType,
  )

export const PrescriptionActionExpandedContext =
  createContext<PrescriptionActionExpandedContextType>(
    {} as PrescriptionActionExpandedContextType,
  )
