import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import {
  ButtonWithLoader,
  DateFormat,
  Defaults,
  LanguageUtils,
  StateLabel,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  ReconcilationStatus,
  ReconcilationStatusColorMap,
} from '~/constants/chat'
import {
  fetchBusinessChatOnboardInfo,
  fetchChatReconcilationInfo,
  partialEditBusinessChatOnboardInfo,
  startChatReconcilation,
} from '~/store/actions/chat'
import {
  getChatIsOnboardLoading,
  getChatIsReconcilationFetching,
  getChatIsReconcilationStarting,
  getChatReconcilationProcesses,
} from '~/store/reducers/chat'
import { BasePracticeDetailsSectionProps } from '~/types'
import { isToday } from '~/utils/time'

const useStyles = makeStyles(
  (theme) => ({
    progressContainer: {
      border: theme.constants.tabBorder,
    },
    progressBar: {
      marginLeft: theme.spacing(1.5),
      flexGrow: 1,
      height: 8,
      borderRadius: 5,
    },
    reconcileBtn: {
      padding: theme.spacing(0, 6),
    },
    table: {
      border: theme.constants.tableBorder,
    },
    tableHeading: {
      padding: theme.spacing(1),
      borderBottom: theme.constants.tableBorder,
      '&:first-child': {
        borderRight: theme.constants.tableBorder,
      },
    },
    tableCell: {
      padding: theme.spacing(1.5, 1),
      border: 'none',
      '&:first-child': {
        borderRight: theme.constants.tableBorder,
      },
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
  }),
  { name: 'InternalChatIntegration' },
)

const getFormattedProcessDate = (date: string) => {
  if (!date) {
    return '-'
  }

  return isToday(date)
    ? moment(date).format(DateFormat.TIME_WITH_MERIDIAN)
    : moment(date).format(DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE)
}

const InternalChatIntegration = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const pollingIntervalRef = useRef<number | null>()

  const reconcilationProcesses = useSelector(getChatReconcilationProcesses)
  const isStarting = useSelector(getChatIsReconcilationStarting)
  const isFetching = useSelector(getChatIsReconcilationFetching)
  const isOnboardLoading = useSelector(getChatIsOnboardLoading)

  const { fields, reset } = useFields(
    [
      {
        name: 'chatUiEnabled',
        label: t('Admin:PRACTICE.INTERNAL_CHAT_INTEGRATION.CHAT_UI_ENABLED'),
        type: 'toggle',
        initialValue: business.chatUiEnabled || false,
      },
    ],
    false,
  )

  const { chatUiEnabled } = fields

  const resetPollingInterval = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef?.current)
      pollingIntervalRef.current = null
    }
  }

  const workingProcess = reconcilationProcesses.find((process) =>
    [ReconcilationStatus.WAITING, ReconcilationStatus.RUNNING].includes(
      process.status,
    ),
  )

  const isProcessing = Boolean(workingProcess) || isFetching

  useEffect(() => {
    resetPollingInterval()
    dispatch(fetchBusinessChatOnboardInfo(business.id))
    dispatch(fetchChatReconcilationInfo(business.id, 5))
  }, [business.id])

  useEffect(() => {
    reset()
  }, [business])

  useEffect(() => {
    if (workingProcess) {
      pollingIntervalRef.current = window.setInterval(() => {
        dispatch(fetchChatReconcilationInfo(business.id, 5))
      }, Defaults.DEFAULT_UPDATE_INTERVAL)
    }

    return () => {
      if (workingProcess) {
        dispatch(fetchBusinessChatOnboardInfo(business.id))
      }
      resetPollingInterval()
    }
  }, [workingProcess?.id])

  const handleReconcile = () => {
    dispatch(startChatReconcilation(business.id))
  }

  return (
    <Grid container direction="column">
      <Grid item mb={2}>
        {business.chatIntegrationCompleted && (
          <PuiSwitch
            disabled={isOnboardLoading}
            field={{
              ...chatUiEnabled,
              set: () => {
                dispatch(
                  partialEditBusinessChatOnboardInfo(
                    business.id,
                    !chatUiEnabled.value,
                  ),
                )
                chatUiEnabled.setValue(!chatUiEnabled.value)
              },
            }}
            label={chatUiEnabled.label}
          />
        )}
      </Grid>
      <ButtonWithLoader
        className={classes.reconcileBtn}
        disabled={isProcessing}
        loading={isStarting}
        onClick={handleReconcile}
      >
        {t('Common:RECONCILE_ACTION')}
      </ButtonWithLoader>
      {workingProcess && (
        <>
          <Text strong mb={1} mt={2} variant="subheading3">
            {t('Admin:PRACTICE.INTERNAL_CHAT_INTEGRATION.RUNNING_PROCESS')}
          </Text>
          <Grid
            container
            alignItems="center"
            className={classes.progressContainer}
            px={1}
            py={1.5}
            wrap="nowrap"
          >
            <Text noWrap variant="body2">
              {t('Admin:PRACTICE.INTERNAL_CHAT_INTEGRATION.PROCESS_ID')}:{' '}
              {workingProcess.id}
            </Text>
            <LinearProgress
              className={classes.progressBar}
              value={workingProcess.progress}
              variant="determinate"
            />
          </Grid>
        </>
      )}
      {reconcilationProcesses.length > 0 && (
        <>
          <Text strong mb={1} mt={2} variant="subheading3">
            {t('Admin:PRACTICE.INTERNAL_CHAT_INTEGRATION.ALL_PROCESSES')}
          </Text>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeading}>
                  <Text strong variant="lowAccent2">
                    {t('Admin:PRACTICE.INTERNAL_CHAT_INTEGRATION.PROCESS_ID')}
                  </Text>
                </TableCell>
                <TableCell className={classes.tableHeading}>
                  <Text strong variant="lowAccent2">
                    {t('Common:START_NOUN')}
                  </Text>
                </TableCell>
                <TableCell className={classes.tableHeading}>
                  <Text strong variant="lowAccent2">
                    {t('Common:STOP_NOUN')}
                  </Text>
                </TableCell>
                <TableCell className={classes.tableHeading}>
                  <Text strong variant="lowAccent2">
                    {t('Common:STATUS')}
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reconcilationProcesses.map((process) => (
                <TableRow className={classes.tableRow} key={process.id}>
                  <TableCell className={classes.tableCell}>
                    <Text variant="body2">{process.id}</Text>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Text variant="body2">
                      {getFormattedProcessDate(process.processStart)}
                    </Text>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Text variant="body2">
                      {getFormattedProcessDate(process.processEnd)}
                    </Text>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <StateLabel
                      {...{
                        [ReconcilationStatusColorMap[process.status]]: true,
                      }}
                      display="inline"
                      variant="small"
                    >
                      {LanguageUtils.capitalize(process.status)}
                    </StateLabel>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Grid>
  )
}

export default InternalChatIntegration
