import React from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    root: {
      borderRadius: 4,
    },
  }),
  { name: 'ProfileBottomContainer' },
)

export interface ProfileBottomContainerProps {
  alignItems?: GridProps['alignItems']
  children: React.ReactNode
  className?: string
}

const ProfileBottomContainer = ({
  className,
  children,
  alignItems = 'center',
}: ProfileBottomContainerProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      alignItems={alignItems}
      className={classNames(classes.root, className)}
      direction="column"
      justifyContent="center"
    >
      {children}
    </Grid>
  )
}

export default ProfileBottomContainer
