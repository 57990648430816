import React from 'react'
import { Grid } from '@mui/material'

export interface ToDoProps {
  className?: string
}

const ToDo = ({ className }: ToDoProps) => <Grid item className={className} />

export default ToDo
