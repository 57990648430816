import { useEffect, useState } from 'react'

interface UseInfiniteListDynamicHeight {
  defaultHeight: string | number
  hasSearchedWithoutResults: boolean
  infiniteContainerWrapperRef: React.RefObject<HTMLDivElement>
  isFetchingMore: boolean
  itemsLength: number
  minHeight: string | number
}

export const useInfiniteListDynamicHeight = ({
  defaultHeight,
  hasSearchedWithoutResults,
  infiniteContainerWrapperRef,
  isFetchingMore,
  itemsLength,
  minHeight,
}: UseInfiniteListDynamicHeight) => {
  const [dynamicHeight, setDynamicHeight] = useState<string | number>(
    defaultHeight,
  )

  // Resize height dynamically considering available height bellow the list component
  useEffect(() => {
    if (
      infiniteContainerWrapperRef.current &&
      itemsLength > 0 &&
      !isFetchingMore
    ) {
      const remainingHeight =
        window.innerHeight -
        infiniteContainerWrapperRef.current.getBoundingClientRect().bottom
      setDynamicHeight(
        remainingHeight < minHeight ? minHeight : remainingHeight,
      )
      return
    }

    if (hasSearchedWithoutResults) {
      setDynamicHeight(defaultHeight)
    }
  }, [
    infiniteContainerWrapperRef.current,
    itemsLength,
    hasSearchedWithoutResults,
  ])

  return dynamicHeight
}
