import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PermissionArea, Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

const NoBundlesScreen = () => {
  const { t } = useTranslation(['Admin', 'Common'])
  const [openNewBundleDialog] = useDialog(DialogNames.BUNDLE)

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUNDLE))

  return (
    <NoItemsScreen
      action={
        !isCvcRolesEnabled || permissions.create
          ? () => openNewBundleDialog()
          : undefined
      }
      actionMessage={t('Common:ADD_BUNDLE')}
      title={t('Common:BUNDLES_CATALOG')}
    >
      <Text variant="body">
        {t('Admin:CATALOG.NO_BUNDLES_SCREEN.DESCRIPTION')}
      </Text>
    </NoItemsScreen>
  )
}

export default NoBundlesScreen
