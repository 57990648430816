import * as R from 'ramda'

import {
  EmailInvoiceData,
  EmailInvoiceParams,
  GeneratePdfForInvoiceData,
} from '~/types'

import { getAPIPath, request } from './core'
import {
  InvoiceEmailDto,
  MutationGeneratePdfForInvoiceArgs,
} from './graphql/generated/types'
import { requestMutation } from './graphql/graphqlCore'
import {
  EMAIL_CHARGE_SHEET,
  EMAIL_INVOICE,
  EMAIL_LAB_RESULT,
  EMAIL_MEDICAL_HISTORY,
  EMAIL_REPORT_CARD,
  GENERATE_PDF_CHARGE_SHEET,
  GENERATE_PDF_INVOICE,
  GENERATE_PDF_MEDICAL_HISTORY,
  GENERATE_PDF_PRESCRIPTION,
  GENERATE_PDF_REPORT_CARD,
  SEND_BOOP_SIGN_UP_MESSAGE,
  SEND_EMAIL_PAYMENT,
  SEND_EMAIL_PRESCRIPTION,
  SEND_EMAIL_PRESCRIPTION_OUTSIDE_SOAP,
} from './graphql/mutations'
import * as Schema from './schemas'

export const emailInvoice = (
  businessId: string,
  emailData: EmailInvoiceData,
  params: EmailInvoiceParams,
) =>
  request(
    getAPIPath('unifiedInbox/conversations/invoice/email'),
    {
      method: 'POST',
      data: emailData,
      params: { businessId, ...params },
    },
    true,
    Schema.conversationsArray,
  )

export const emailInvoiceV2 = requestMutation({
  mutation: EMAIL_INVOICE,
  variablesHandler: (businessId, emailData: InvoiceEmailDto) => ({
    input: {
      businessId,
      ...emailData,
    },
  }),
  schema: Schema.conversationsArray,
})

export const emailChargeSheet = requestMutation({
  mutation: EMAIL_CHARGE_SHEET,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(
      [
        'businessId',
        'clientId',
        'recipients',
        'subject',
        'message',
        'expandedGroups',
      ],
      emailData,
    ),
  }),
  schema: Schema.conversationsArray,
})

export const emailReportCard = requestMutation({
  mutation: EMAIL_REPORT_CARD,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(
      ['soapId', 'message', 'recipients', 'subject', 'sections'],
      emailData,
    ),
  }),
})

export const emailMedHistory = requestMutation({
  mutation: EMAIL_MEDICAL_HISTORY,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(
      [
        'patientId',
        'message',
        'recipients',
        'subject',
        'sections',
        'from',
        'to',
      ],
      emailData,
    ),
  }),
})

export const emailPayment = requestMutation({
  mutation: SEND_EMAIL_PAYMENT,
  variablesHandler: (businessId, emailData) => ({
    input: emailData,
  }),
  schema: Schema.conversationsArray,
})

export const sendMembershipHybridInvite = requestMutation({
  mutation: SEND_BOOP_SIGN_UP_MESSAGE,
  variablesHandler: (businessId, message) => ({
    message,
  }),
})

export const emailPrescription = requestMutation({
  mutation: SEND_EMAIL_PRESCRIPTION,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(['orderId', 'message', 'subject', 'recipients'], emailData),
  }),
  schema: Schema.conversationsArray,
})

export const emailPrescriptionOutsideSoap = requestMutation({
  mutation: SEND_EMAIL_PRESCRIPTION_OUTSIDE_SOAP,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(['orderId', 'message', 'subject', 'recipients'], emailData),
  }),
  schema: Schema.conversationsArray,
})

export const emailLabResult = requestMutation({
  mutation: EMAIL_LAB_RESULT,
  variablesHandler: (businessId, emailData) => ({
    input: emailData,
  }),
  schema: Schema.conversationsArray,
})

export const generatePdfForMedicalHistory = requestMutation({
  mutation: GENERATE_PDF_MEDICAL_HISTORY,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(
      ['patientId', 'message', 'sections', 'from', 'to'],
      emailData,
    ),
  }),
})

export const generatePdfForReportCard = requestMutation({
  mutation: GENERATE_PDF_REPORT_CARD,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(['soapId', 'message', 'sections'], emailData),
  }),
})

export const generatePdfForPrescription = requestMutation({
  mutation: GENERATE_PDF_PRESCRIPTION,
  variablesHandler: (businessId, emailData) => ({
    input: R.pick(['orderId', 'message'], emailData),
  }),
})

export const generatePdfForInvoice = (
  businessId: string,
  emailData: Omit<GeneratePdfForInvoiceData, 'params'>,
  params: EmailInvoiceParams,
) =>
  request(
    getAPIPath('unifiedInbox/files/invoice'),
    {
      method: 'POST',
      data: emailData,
      params: { businessId, ...params },
    },
    true,
  )

export const generatePdfForInvoiceV2 = requestMutation({
  mutation: GENERATE_PDF_INVOICE,
  variablesHandler: (
    businessId,
    {
      invoiceId,
      expandedGroups,
      includeServiceFee,
    }: MutationGeneratePdfForInvoiceArgs,
  ) => ({
    businessId,
    invoiceId,
    expandedGroups,
    includeServiceFee,
  }),
})

export const generatePdfForChargeSheet = requestMutation({
  mutation: GENERATE_PDF_CHARGE_SHEET,
  variablesHandler: (businessId, clientId, expandedGroups) => ({
    businessId,
    clientId,
    expandedGroups,
  }),
})
