import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  Slide,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import Avatar from '~/components/common/Avatar'
import RhapsodyLogo from '~/components/common/RhapsodyLogo'
import { getCurrentUser, getTimeTrackerEnabled } from '~/store/reducers/auth'

import NotificationsHistoryButton from '../notifications/historyTable/NotificationsHistoryButton'
import ProfileMenu from '../profile/ProfileMenu'
import RecentListButton from '../recent-list/RecentListButton'
import TimeTrackerButton from '../time-tracking/TimeTrackerButton'
import BusinessLocationSwitch from './BusinessLocationSwitch'
import Search from './Search'

const ICON_WIDTH = 38

const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      zIndex: theme.zIndex.headerTopBar,
      boxShadow: '0 1px 3px 0 rgba(229, 229, 229, 1)',
      [theme.breakpoints.up('md')]: {
        left: 0,
        top: 0,
        position: 'fixed',
      },
    },
    toolbar: theme.mixins.toolbar,
    toolbarGutters: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    searchBarContainer: {
      zIndex: theme.zIndex.base,
    },
    searchBar: {
      width: `calc(100% - ${
        theme.constants.businessSwitchWidth - ICON_WIDTH
      }px)`,

      [theme.breakpoints.down('xl')]: {
        width: `calc(100% - ${theme.constants.businessSwitchWidthLg}px + 65px)`,
      },

      [theme.breakpoints.down('lg')]: {
        width: `calc(100% - ${theme.constants.businessSwitchWidthMd}px + 65px)`,
      },
    },
    rhapsodyLogo: {
      marginTop: 5,
      height: 48,
      width: 145,
      [theme.breakpoints.down('md')]: {
        width: 106,
        height: 34,
      },
    },
    userAvatar: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      cursor: 'pointer',
    },
    '@keyframes fadeHide': {
      '0%': { opacity: 1 },
      '100%': { opacity: 0 },
    },
    profileMenuClosed: {
      animation: '0.25s ease-out 0.1s forwards $fadeHide',
    },
  }),
  { name: 'Header' },
)

export interface HeaderProps {
  onSandwichClicked: () => void
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(function Header(
  { onSandwichClicked },
  ref,
) {
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation('Common')

  const currentUser = useSelector(getCurrentUser) || {}
  const timeTrackingEnabled = useSelector(getTimeTrackerEnabled)

  const [searchExpanded, setSearchExpanded] = useState(false)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)

  const avatarRef = React.createRef<HTMLSpanElement>()

  let content
  if (searchExpanded && isMobile) {
    content = (
      <Grid container alignItems="flex-end" direction="column">
        <Grid item>
          <IconButton
            aria-label={t('Common:CLOSE_SEARCH')}
            size="large"
            onClick={() => setSearchExpanded(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container item pb={2} px={1.5}>
          <Search />
        </Grid>
      </Grid>
    )
  } else {
    content = (
      <Grid container alignItems="center" wrap="nowrap">
        <Hidden mdUp>
          <Grid item xs>
            <IconButton
              aria-label={t('Common:OPEN_MENU')}
              size="large"
              onClick={onSandwichClicked}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Hidden>
        <RhapsodyLogo classes={{ logo: classes.rhapsodyLogo }} />
        <Hidden mdUp>
          <Grid container item md xs justifyContent="flex-end">
            <IconButton
              aria-label={t('Common:OPEN_SEARCH')}
              size="large"
              onClick={() => setSearchExpanded(true)}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid item xs className={classes.searchBarContainer} mx={4}>
            <div className={classes.searchBar}>
              <Search />
            </div>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          {currentUser.businessToRoleList &&
            currentUser.businessToRoleList.length > 1 && (
              <BusinessLocationSwitch />
            )}
          <Grid item ml={2}>
            <NotificationsHistoryButton />
          </Grid>
          {timeTrackingEnabled && (
            <Grid item ml={2}>
              <TimeTrackerButton />
            </Grid>
          )}
          <Grid item ml={2}>
            <RecentListButton />
          </Grid>
          <Grid item ml={2}>
            <Avatar
              plain
              aria-label="user-avatar"
              className={classes.userAvatar}
              person={currentUser}
              ref={avatarRef}
              onClick={() => {
                setProfileMenuOpen(!profileMenuOpen)
              }}
            />
          </Grid>
          <Slide unmountOnExit direction="left" in={profileMenuOpen}>
            <ProfileMenu
              className={classNames(
                !profileMenuOpen && classes.profileMenuClosed,
              )}
              onClose={(e) => {
                if (
                  avatarRef.current &&
                  !avatarRef.current.contains(e.target)
                ) {
                  setProfileMenuOpen(false)
                }
              }}
            />
          </Slide>
        </Hidden>
      </Grid>
    )
  }

  return (
    <AppBar
      className={classes.appBar}
      color="inherit"
      position="relative"
      ref={ref}
    >
      <Toolbar
        classes={{ regular: classes.toolbar, gutters: classes.toolbarGutters }}
      >
        {content}
      </Toolbar>
    </AppBar>
  )
})

export default Header
