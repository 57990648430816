import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { v4 as uuid } from 'uuid'
import { CountryCode, moment, Utils } from '@pbt/pbt-ui-components'

export type GeoPlace = {
  address_components: any
  geometry?: Record<string, any>
  icon?: string
  international_phone_number?: string
  name?: string
  place_id?: string
  website?: string
}

export interface BusinessDto {
  address?: string
  children?: string[] | null
  city?: string
  country?: CountryCode
  countryCatalogCode?: CountryCode
  geo?: any
  id: string
  isGroup?: boolean
  kioskEnabled?: boolean
  logo?: string
  name?: string
  parsedWebsite?: string | undefined
  phone?: string
  state?: string
  suite?: string
  timeZone?: string
  website?: string
  zipcode?: string
}

/**
 *  Deprecated
 */
class Business implements BusinessDto {
  address: string

  children: string[] | null

  city: string

  country: CountryCode

  countryCatalogCode: CountryCode

  geo: any

  id: string

  isGroup: boolean

  kioskEnabled: boolean

  logo: string

  name: string

  phone: string

  state: string

  suite: string

  timeZone: string

  website: string

  zipcode: string

  parsedWebsite: string | undefined

  constructor(options?: BusinessDto) {
    const {
      address = '',
      city = '',
      country = CountryCode.US,
      geo = null,
      id = '',
      isGroup = false,
      logo = '',
      name = '',
      phone = '',
      state = '',
      suite = '',
      website = '',
      zipcode = '',
      children = null,
      timeZone,
      kioskEnabled = false,
      countryCatalogCode = CountryCode.US,
    } = options || {}

    this.id = id || uuid()
    this.timeZone = timeZone || moment.tz.guess()
    this.address = address
    this.city = city
    this.country = country
    this.geo = geo
    this.isGroup = isGroup
    this.logo = logo
    this.name = name
    const phoneNumber =
      phone && parsePhoneNumberFromString(phone, country || 'US')
    this.phone = phoneNumber ? phoneNumber.formatInternational() : ''
    this.state = state
    this.suite = suite
    this.website = website
    this.parsedWebsite = Utils.getUrlHost(website)
    this.zipcode = zipcode
    this.children = children
    this.kioskEnabled = kioskEnabled
    this.countryCatalogCode = countryCatalogCode
  }

  getFormattedAddress() {
    if (!this.address) {
      return ''
    }
    return `${this.address}, ${this.city} ${this.state} ${this.zipcode}`
  }

  isFilled() {
    return Boolean(
      this.address &&
        this.city &&
        this.name &&
        this.phone &&
        this.state &&
        this.zipcode,
    )
  }
}

export default Business
export const fillFromGooglePlace = (
  place: GeoPlace,
  { timeZoneId: timeZone }: { timeZoneId?: string } = {},
) => {
  const {
    address_components = [], // eslint-disable-line
    geometry = {},
    international_phone_number = '', // eslint-disable-line
    name,
    place_id, // eslint-disable-line
    website,
  } = place
  let { icon = '' } = place

  if (icon.indexOf('generic_business-71.png') !== -1) {
    icon = ''
  }
  const addressMap = {
    route: { types: ['route'], key: 'short_name' },
    streetNumber: { types: ['street_number'], key: 'short_name' },
    city: {
      types: ['locality', 'sublocality', 'neighborhood'],
      key: 'short_name',
    },
    state: { types: ['administrative_area_level_1'], key: 'short_name' },
    zipcode: { types: ['postal_code'], key: 'short_name' },
    country: { types: ['country'], key: 'short_name' },
  }

  const { city, country, route, state, streetNumber, zipcode } =
    address_components.reduce((acc: Record<string, any>, item: any) => {
      Object.keys(addressMap).forEach((key) => {
        const matcher = addressMap[key as keyof typeof addressMap]
        if (
          item.types.reduce(
            (accInner: boolean, itemType: string) =>
              matcher.types.includes(itemType) || accInner,
            false,
          )
        ) {
          acc[key] = item[matcher.key]
        }
      })
      return acc
    }, {})

  const phoneNumber = parsePhoneNumberFromString(international_phone_number)
  const { location } = geometry
  return new Business({
    id: place_id!,
    logo: icon,
    name,
    geo: location && { lat: location.lat(), lng: location.lng() },
    phone: phoneNumber ? phoneNumber.formatInternational() : '',
    website,
    address: streetNumber && route ? `${streetNumber} ${route}` : '',
    city,
    country,
    state,
    zipcode,
    timeZone,
  })
}
