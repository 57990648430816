enum SnackNotificationType {
  COMMUNICATION = 'COMMUNICATION',
  CREDIT_ADJUSTMENT = 'CREDIT_ADJUSTMENT',
  ERROR = 'ERROR',
  INFO = 'INFO',
  LINK_CHEWY_ACCOUNT = 'LINK_CHEWY_ACCOUNT',
  NETWORK_ERROR = 'NETWORK_ERROR',
  PATIENT_MATCH_INCOMPLETE = 'PATIENT_MATCH_INCOMPLETE',
  PRICING_UPDATE = 'PRICING_UPDATE',
  PROBLEMS_LOG = 'PROBLEMS_LOG',
  TASK = 'TASK',
  TASK_ACTION = 'TASK_ACTION',
  UNDO = 'UNDO',
  UPDATE = 'UPDATE',
}

export default SnackNotificationType
