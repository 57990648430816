import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import * as R from 'ramda'
import { PermissionArea } from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import LandingWidget, {
  LandingWidgetProps,
} from '~/components/dashboard/landing/widgets/LandingWidget'
import NoRecords from '~/components/dashboard/landing/widgets/NoRecords'
import TableWidget from '~/components/dashboard/landing/widgets/TableWidget'
import { getCRUDByArea } from '~/store/reducers/auth'
import { VaccineSnapshotItem, WidgetColumn } from '~/types'

const columns: WidgetColumn<VaccineSnapshotItem>[] = [
  {
    id: 'name',
    label: '',
    prop: 'name',
    width: 8,
  },
]

interface VaccinesSnapshotProps
  extends Omit<LandingWidgetProps<VaccineSnapshotItem>, 'component' | 'data'> {
  clientId: string
  name: string
  patientId: string
}

const VaccinesSnapshot = ({
  name,
  clientId,
  patientId,
  ...rest
}: VaccinesSnapshotProps) => {
  const { t } = useTranslation(['Common', 'Clients'])

  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )

  const isLoading = false

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleView = (item?: VaccineSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleGoToSoap = (item?: VaccineSnapshotItem) => {}

  const handleAddDiagnose = () => {}

  // Review permissions when implementing this Feature
  const getActions = (item?: VaccineSnapshotItem) => [
    {
      id: 'view',
      label: t('Common:VIEW_ACTION'),
      Icon: EyeIcon,
      onClick: () => handleView(item),
    },
    {
      id: 'goToSoap',
      label: t('Common:GO_TO_SOAP'),
      Icon: ArrowForwardIcon,
      onClick: soapReadPermissions ? () => handleGoToSoap(item) : undefined,
      disabled: !soapReadPermissions,
    },
  ]

  return (
    <LandingWidget
      canNavigateToDetails={false}
      canNavigateToItemDetails={R.F}
      columns={columns}
      component={TableWidget}
      data={[] as VaccineSnapshotItem[]}
      getActions={getActions}
      isLoading={isLoading}
      name={name}
      noRecords={
        <NoRecords
          action={t('Clients:SNAPSHOTS_AND_RECORDS.ADD_VACCINE_HISTORY')}
          text={t('Clients:SNAPSHOTS_AND_RECORDS.NO_VACCINES')}
          onClick={handleAddDiagnose}
        />
      }
      showPaddings={false}
      {...rest}
    />
  )
}

export default VaccinesSnapshot
