import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Business as BusinessType } from '@pbt/pbt-ui-components'

import {
  updateBusiness,
  updateBusinessCandidate,
} from '~/store/actions/registration'
import Business, { BusinessDto } from '~/store/dto/Business'
import {
  getIsActivationKeyFlow,
  getRegistrationBusinessCandidate,
  getRegistrationBusinesses,
} from '~/store/reducers/registration'
import { registrationNavigationDependingOnIsActivationFlow } from '~/utils/registrationNavigationDependingOnIsActivationFlow'

import ClinicEdit from './ClinicEdit'

const ClinicEditComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clinicFromState = useSelector(getRegistrationBusinessCandidate)
  const businesses = useSelector(getRegistrationBusinesses)
  const isActivationFow = useSelector(getIsActivationKeyFlow)
  const { t } = useTranslation(['Common', 'Registration'])

  const isEditPage = Boolean(clinicFromState)
  const clinic = clinicFromState || new Business()

  if (!clinicFromState) {
    dispatch(updateBusinessCandidate(clinic))
  }

  const onSubmit = (business: Omit<BusinessType | BusinessDto, 'id'>) => {
    if (businesses[clinic.id]) {
      dispatch(updateBusiness({ id: clinic.id, ...business }))
    } else {
      dispatch(updateBusinessCandidate(business as BusinessType))
      dispatch(updateBusiness())
    }
    registrationNavigationDependingOnIsActivationFlow(navigate, isActivationFow)
  }

  const onUploadTrigger = (
    business: Omit<BusinessType | BusinessDto, 'id'>,
  ) => {
    dispatch(updateBusinessCandidate({ id: clinic.id, ...business }))
    navigate('/register/clinic/logoUpload')
  }

  const onBackToMapClick = () => {
    dispatch(updateBusinessCandidate(null))
    if (businesses[clinic.id] && !isActivationFow) {
      navigate('/register')
    } else {
      navigate('/register/clinic/select')
    }
  }

  const subheaderText = isEditPage
    ? t('Registration:CLINIC_EDIT_COMPONENT.EDIT_PAGE_SUB_HEADER_TEXT')
    : t('Registration:CLINIC_EDIT_COMPONENT.ADD_PAGE_SUB_HEADER_TEXT')

  return (
    <ClinicEdit
      backLabel={businesses[clinic.id] && t('Common:BACK_TO_LIST')}
      clinic={clinic}
      headerText={t('Common:NO_WORRIES')}
      subheaderText={subheaderText}
      onBackToMapClick={onBackToMapClick}
      onSubmit={onSubmit}
      onUploadTrigger={onUploadTrigger}
    />
  )
}

export default ClinicEditComponent
