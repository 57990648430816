import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { getWorkflowIFrames } from '../duck/marketplace'
import { getMarketplaceWorkflow } from '../reducers/constants'

export const useGetWorkflowIFrames = (workflowName: string) => {
  const MarketplaceWorkflow = useSelector(getMarketplaceWorkflow)
  const workflowId = Utils.findConstantIdByName(
    workflowName,
    MarketplaceWorkflow,
  )

  return useSelector(getWorkflowIFrames(workflowId))
}
