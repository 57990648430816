import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea } from '@pbt/pbt-ui-components'

import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import DialogNames from '~/constants/DialogNames'
import {
  getReminderProtocolsIsLoading,
  getReminderProtocolsTotalCount,
} from '~/store/duck/reminderProtocols'
import { getCRUDByArea } from '~/store/reducers/auth'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

import ReminderProtocolDetails from './ReminderProtocolGroupDetails'
import ReminderProtocolGroupsTableComponent from './ReminderProtocolGroupsTableComponent'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 192,
      height: 40,
    },
  }),
  { name: 'ReminderProtocolGroupsPage' },
)

const ReminderProtocolGroupsPage = () => {
  const classes = useStyles()
  const location = useLocation()
  const { groupId } = useParams()
  const navigate = useNavigate()
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )
  const totalCount = useSelector(getReminderProtocolsTotalCount)
  const isLoading = useSelector(getReminderProtocolsIsLoading)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation('Common')

  const [openNewReminderProtocolGroupDialog] = useDialog(
    DialogNames.NEW_REMINDER_PROTOCOL_GROUP,
  )

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/reminder-protocols'))
  }

  if (isMobile && typeof groupId !== 'undefined') {
    return <ReminderProtocolDetails itemId={groupId} onClose={onDetailsClose} />
  }

  const headerButtons = (
    <Grid container item justifyContent="space-between" px={3} py={2}>
      <Grid item>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
      {permissions.create && (
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={() => openNewReminderProtocolGroupDialog()}
        >
          {t('Common:NEW_PROTOCOL')}
        </Fab>
      )}
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <ReminderProtocolGroupsTableComponent
        groupId={groupId}
        headerButtons={headerButtons}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default ReminderProtocolGroupsPage
