import { AnyAction } from 'redux'

import {
  DiagnoseFilter,
  MedicalHistoryDiagnose,
  MedicalHistoryVaccine,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  CLEAR_MEDICAL_HISTORY_DIAGNOSES,
  DELETE_ATTACHMENT_HISTORY,
  DELETE_ATTACHMENT_HISTORY_FAILURE,
  DELETE_ATTACHMENT_HISTORY_SUCCESS,
  DELETE_DIAGNOSIS_HISTORY,
  DELETE_DIAGNOSIS_HISTORY_FAILURE,
  DELETE_DIAGNOSIS_HISTORY_SUCCESS,
  DELETE_NOTE_HISTORY,
  DELETE_NOTE_HISTORY_FAILURE,
  DELETE_NOTE_HISTORY_SUCCESS,
  DELETE_VACCINE_HISTORY,
  DELETE_VACCINE_HISTORY_FAILURE,
  DELETE_VACCINE_HISTORY_SUCCESS,
  EDIT_DIAGNOSIS_HISTORY,
  EDIT_DIAGNOSIS_HISTORY_FAILURE,
  EDIT_DIAGNOSIS_HISTORY_SUCCESS,
  EDIT_NOTE_HISTORY,
  EDIT_NOTE_HISTORY_FAILURE,
  EDIT_NOTE_HISTORY_SUCCESS,
  EDIT_VACCINE_HISTORY,
  EDIT_VACCINE_HISTORY_FAILURE,
  EDIT_VACCINE_HISTORY_SUCCESS,
  FETCH_DIAGNOSES,
  FETCH_DIAGNOSES_FAILURE,
  FETCH_DIAGNOSES_FILTERS,
  FETCH_DIAGNOSES_FILTERS_FAILURE,
  FETCH_DIAGNOSES_FILTERS_SUCCESS,
  FETCH_DIAGNOSES_SUCCESS,
  FETCH_VACCINES,
  FETCH_VACCINES_FAILURE,
  FETCH_VACCINES_SUCCESS,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS_FAILURE,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS_SUCCESS,
  SAVE_ATTACHMENT_HISTORY_FAILURE,
  SAVE_ATTACHMENT_HISTORY_SUCCESS,
  SAVE_ATTACHMENT_HISTORY_V2,
  SAVE_DIAGNOSIS_HISTORY,
  SAVE_DIAGNOSIS_HISTORY_FAILURE,
  SAVE_DIAGNOSIS_HISTORY_SUCCESS,
  SAVE_MULTI_ATTACHMENT_HISTORY,
  SAVE_MULTI_ATTACHMENT_HISTORY_FAILURE,
  SAVE_MULTI_ATTACHMENT_HISTORY_SUCCESS,
  SAVE_NOTE_HISTORY,
  SAVE_NOTE_HISTORY_FAILURE,
  SAVE_NOTE_HISTORY_SUCCESS,
  SAVE_VACCINE_HISTORY,
  SAVE_VACCINE_HISTORY_FAILURE,
  SAVE_VACCINE_HISTORY_SUCCESS,
} from '../actions/types/medicalHistory'
import type { RootState } from '../index'

export type MedicalHistoryState = {
  diagnoses: MedicalHistoryDiagnose[]
  diagnosesFilters: DiagnoseFilter[]
  error: string | null
  isFetching: boolean
  isSending: boolean
  vaccines: MedicalHistoryVaccine[]
}

export const MEDICAL_HISTORY_INITIAL_STATE: MedicalHistoryState = {
  isSending: false,
  isFetching: false,
  error: null,
  vaccines: [],
  diagnoses: [],
  diagnosesFilters: [],
}

const medicalHistory = (
  state: MedicalHistoryState = MEDICAL_HISTORY_INITIAL_STATE,
  action: AnyAction,
): MedicalHistoryState => {
  switch (action.type) {
    case SAVE_VACCINE_HISTORY:
    case EDIT_VACCINE_HISTORY:
    case DELETE_VACCINE_HISTORY:
    case SAVE_DIAGNOSIS_HISTORY:
    case EDIT_DIAGNOSIS_HISTORY:
    case DELETE_DIAGNOSIS_HISTORY:
    case SAVE_NOTE_HISTORY:
    case EDIT_NOTE_HISTORY:
    case DELETE_NOTE_HISTORY:
    case SAVE_ATTACHMENT_HISTORY_V2:
    case SAVE_MULTI_ATTACHMENT_HISTORY:
    case SAVE_ATTACHMENT_HISTORY_DOCUMENTS:
    case DELETE_ATTACHMENT_HISTORY:
      return { ...state, isSending: true, error: null }
    case SAVE_VACCINE_HISTORY_SUCCESS:
    case EDIT_VACCINE_HISTORY_SUCCESS:
    case DELETE_VACCINE_HISTORY_SUCCESS:
    case SAVE_DIAGNOSIS_HISTORY_SUCCESS:
    case EDIT_DIAGNOSIS_HISTORY_SUCCESS:
    case DELETE_DIAGNOSIS_HISTORY_SUCCESS:
    case SAVE_NOTE_HISTORY_SUCCESS:
    case EDIT_NOTE_HISTORY_SUCCESS:
    case DELETE_NOTE_HISTORY_SUCCESS:
    case SAVE_ATTACHMENT_HISTORY_SUCCESS:
    case SAVE_MULTI_ATTACHMENT_HISTORY_SUCCESS:
    case SAVE_ATTACHMENT_HISTORY_DOCUMENTS_SUCCESS:
    case DELETE_ATTACHMENT_HISTORY_SUCCESS:
      return { ...state, isSending: false }
    case SAVE_VACCINE_HISTORY_FAILURE:
    case EDIT_VACCINE_HISTORY_FAILURE:
    case DELETE_VACCINE_HISTORY_FAILURE:
    case SAVE_DIAGNOSIS_HISTORY_FAILURE:
    case EDIT_DIAGNOSIS_HISTORY_FAILURE:
    case DELETE_DIAGNOSIS_HISTORY_FAILURE:
    case SAVE_NOTE_HISTORY_FAILURE:
    case EDIT_NOTE_HISTORY_FAILURE:
    case DELETE_NOTE_HISTORY_FAILURE:
    case SAVE_ATTACHMENT_HISTORY_FAILURE:
    case SAVE_MULTI_ATTACHMENT_HISTORY_FAILURE:
    case SAVE_ATTACHMENT_HISTORY_DOCUMENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isSending: false,
      }
    case FETCH_VACCINES:
      return { ...state, isFetching: true }
    case FETCH_VACCINES_SUCCESS:
      return { ...state, vaccines: action.vaccines, isFetching: false }
    case FETCH_DIAGNOSES:
      return { ...state, isFetching: true, diagnoses: [] }
    case FETCH_DIAGNOSES_SUCCESS:
      return { ...state, diagnoses: action.diagnoses, isFetching: false }
    case FETCH_DIAGNOSES_FILTERS:
      return { ...state, isFetching: true }
    case FETCH_DIAGNOSES_FILTERS_SUCCESS:
      return { ...state, diagnosesFilters: action.filters, isFetching: false }
    case FETCH_VACCINES_FAILURE:
    case FETCH_DIAGNOSES_FILTERS_FAILURE:
    case FETCH_DIAGNOSES_FAILURE:
    case DELETE_ATTACHMENT_HISTORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_MEDICAL_HISTORY_DIAGNOSES:
      return { ...state, diagnoses: [] }
    default:
      return state
  }
}

export default medicalHistory
export const getMedicalHistory = (state: RootState): MedicalHistoryState =>
  state.medicalHistory
export const getMedicalHistoryIsFetching = (state: RootState) =>
  getMedicalHistory(state).isFetching
export const getMedicalHistoryIsSending = (state: RootState) =>
  getMedicalHistory(state).isSending
export const getMedicalHistoryVaccines = (state: RootState) =>
  getMedicalHistory(state).vaccines
export const getMedicalHistoryDiagnoses = (state: RootState) =>
  getMedicalHistory(state).diagnoses
export const getMedicalHistoryDiagnosesFilters = (state: RootState) =>
  getMedicalHistory(state).diagnosesFilters
