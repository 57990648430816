import { AddressUtils, PhoneUtils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import { joinLineParts } from './labelsUtils'

export const buildPracticeAddressLabel = ({
  name,
  address,
  suite,
  city,
  state,
  zipcode,
  phone,
} = {}) =>
  [
    [name],
    [AddressUtils.formatAddress({ address, suite })],
    [AddressUtils.formatAddress({ city, state, zipcode })],
    [PhoneUtils.formatPhoneNumber(phone)],
  ].map(joinLineParts)

export const buildFolderLabel = ({
  clientName,
  clientAddress,
  clientPhone,
  patientName,
  patientGender,
  patientAge,
  patientSpecies,
  patientBreed,
} = {}) =>
  [
    [clientName, PhoneUtils.formatPhoneNumber(clientPhone)],
    [clientAddress],
    [patientName, patientAge, patientGender],
    [patientSpecies, patientBreed],
  ].map(joinLineParts)

export const buildPrescriptionLabel = ({
  practice = {},
  doctorName,
  doctorDeaNumber,
  prescriptionNumber,
  filledDate,
  clientName,
  patientName,
  patientSpecies,
  drugName,
  drugStrength,
  drugForm,
  drugQuantity,
  drugExpiresDate,
  instructions,
  refillsNumber,
  refillDate,
  bottomWarning,
} = {}) =>
  [
    [practice.name],
    [
      AddressUtils.formatAddress(practice),
      PhoneUtils.formatPhoneNumber(practice.phone),
    ],
    [
      doctorName,
      `${i18n.t(
        'Abbreviations:ACRONYMS.DEA.DEA_NUMBER_SIGN',
      )}: ${doctorDeaNumber}`,
    ],
    [
      `${i18n.t(
        'Abbreviations:COMMON.RECIPE_NUMBER_SIGN',
      )}: ${prescriptionNumber}`,
      i18n.t('Common:FILLED_ON_DATE', { date: filledDate }),
    ],
    [
      `${i18n.t('Common:CLIENT')}: ${clientName}`,
      `${i18n.t('Common:PATIENT')}: ${patientName}, ${patientSpecies}`,
    ],
    [
      `${drugName}, ${drugStrength}, ${drugForm}`,
      `${i18n.t('Abbreviations:COMMON.QUANTITY')}: ${drugQuantity}`,
      `${i18n.t('Common:EXPIRES')}: ${drugExpiresDate}`,
    ],
    [`${i18n.t('Common:INSTRUCTIONS')}: ${instructions}`],
    [
      `${refillsNumber} ${i18n.t('Common:REFILL_OTHER').toLowerCase()}`,
      i18n.t('Common:REFILL_BY_DATE', { date: refillDate }),
    ],
    [bottomWarning],
  ].map(joinLineParts)

export const buildFormattedPrescriptionData = ({
  practice = {},
  doctorName,
  doctorDeaNumber,
  prescriptionNumber,
  filledDate,
  clientName,
  patientName,
  patientSpecies,
  drugName,
  drugStrength,
  drugForm,
  drugQuantity,
  drugExpiresDate,
  instructions,
  refillsNumber,
}) => ({
  patientNameAndSpecies: patientSpecies
    ? `${patientName} (${patientSpecies})`
    : patientName,
  drugName,
  drugStrength,
  drugForm,
  prescriptionNumber,
  expirationDateString: `${i18n.t('Common:EXPIRES')}: ${drugExpiresDate}`,
  instructions,
  quantityRow: [
    `${i18n.t('Abbreviations:COMMON.QUANTITY')}: ${drugQuantity} ${drugForm}`,
    `${i18n.t('Common:REFILL_OTHER')}: ${refillsNumber}`,
    i18n.t('Common:FILLED_ON_DATE', { date: filledDate }),
    clientName,
  ]
    .filter(Boolean)
    .join('  |  '),
  businessName: '',
  businessAddress: AddressUtils.formatAddress(practice),
  businessPhone: PhoneUtils.formatPhoneNumber(practice.phone),
  doctorName: `${doctorName} (${i18n.t(
    'Abbreviations:ACRONYMS.DEA.LABEL_ABBREVIATION',
  )}: ${doctorDeaNumber})`,
})

export const buildCageLabel = ({
  patientAlert,
  clientName,
  clientPhone,
  patientName,
  patientGender,
  patientAge,
  patientSpecies,
  patientBreed,
} = {}) =>
  [
    [patientAlert],
    [patientName, clientName, clientPhone],
    [patientAge, patientGender],
    [patientSpecies, patientBreed],
  ].map(joinLineParts)

export const buildLabSampleLabel = ({
  vendorName,
  orderNumber,
  date,
  clientLastName,
  patientName,
  patientGender,
  patientAge,
  patientSpecies,
  patientBreed,
} = {}) =>
  [
    [vendorName, orderNumber, date],
    [[patientName, clientLastName].join(' ')],
    [patientAge, patientGender],
    [patientSpecies, patientBreed],
  ].map(joinLineParts)
