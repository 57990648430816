import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { EmployeeIdInfo, Nil, User, Utils } from '@pbt/pbt-ui-components'

import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  CREATE_TEAM_MEMBER_LICENSE_SUCCESS,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  DELETE_TEAM_MEMBER_LICENSE_SUCCESS,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  EDIT_TEAM_MEMBER_LICENSE_SUCCESS,
} from '../actions/types/members'
import { CREATE_PATIENT_SUCCESS } from '../actions/types/patients'
import {
  ACCEPT_EULA,
  ACCEPT_EULA_FAILURE,
  ACCEPT_EULA_SUCCESS,
  FETCH_BALANCE_FAILURE,
  FETCH_BALANCE_SUCCESS,
  LINK_USER_TO_CHEWY_ACCOUNT,
  UNLINK_USER_FROM_CHEWY_ACCOUNT,
  UNLINK_USER_FROM_CHEWY_ACCOUNT_FAILURE,
  UNLINK_USER_FROM_CHEWY_ACCOUNT_SUCCESS,
  UPDATE_CURRENT_USER_ON_SERVER,
  UPDATE_CURRENT_USER_ON_SERVER_SUCCESS,
  UPDATE_CURRENT_USERS_BUSINESS_ID,
  UPDATE_USER_SIGNATURE,
  UPDATE_USER_SIGNATURE_FAILURE,
  UPDATE_USER_SIGNATURE_SUCCESS,
  UPDATE_USERS,
  UPDATE_ZN_LAB_PROVIDER_ID,
  UPDATE_ZN_LAB_PROVIDER_ID_FAILURE,
  UPDATE_ZN_LAB_PROVIDER_ID_SUCCESS,
  USERS_UPDATE_FAILURE,
} from '../actions/types/users'
import type { RootState } from '../index'
import { getPatientsMap } from './patients'

export type UsersState = {
  error: string | null
  isFetching: boolean
  isSending: boolean
  map: Record<string, User>
}

export const USERS_INITIAL_STATE: UsersState = {
  map: {},
  error: null,
  isFetching: false,
  isSending: false,
}

const users = (
  state: UsersState = USERS_INITIAL_STATE,
  action: AnyAction,
): UsersState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return USERS_INITIAL_STATE
    case UPDATE_USERS:
      return { ...state, map: secondLevelMerge(state.map, action.users) }
    case UPDATE_CURRENT_USERS_BUSINESS_ID:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            currentBusinessId: action.businessId,
          },
        },
      }
    case USERS_UPDATE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isFetching: false,
      }
    case UPDATE_CURRENT_USER_ON_SERVER:
      return { ...state, isFetching: true, error: null }
    case UPDATE_CURRENT_USER_ON_SERVER_SUCCESS:
      return { ...state, isFetching: false, error: null }
    case UPDATE_USER_SIGNATURE:
      return { ...state, isFetching: true, error: null }
    case UPDATE_USER_SIGNATURE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            signatureUrl: action.signatureUrl,
          },
        },
      }
    case UPDATE_USER_SIGNATURE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_TEAM_MEMBER_LICENSE_SUCCESS:
    case EDIT_TEAM_MEMBER_LICENSE_SUCCESS:
    case DELETE_TEAM_MEMBER_LICENSE_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.teamMemberId]: {
            ...state.map[action.teamMemberId],
            licenses: action.licenses,
          },
        },
      }
    case CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS:
    case DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.teamMemberId]: {
            ...state.map[action.teamMemberId],
            employeeIdInfos: action.employeeIdInfos,
          },
        },
      }
    case EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS:
      const byIssueDate = R.descend((info: EmployeeIdInfo) =>
        new Date(info.issueDate).getTime(),
      )
      return {
        ...state,
        map: {
          ...state.map,
          [action.teamMemberId]: {
            ...state.map[action.teamMemberId],
            employeeIdInfos: R.sort(byIssueDate, action.employeeIdInfos),
          },
        },
      }
    case ACCEPT_EULA_SUCCESS:
      return { ...state, error: null, isSending: false }
    case ACCEPT_EULA:
    case UPDATE_ZN_LAB_PROVIDER_ID:
      return {
        ...state,
        isSending: true,
      }
    case UPDATE_ZN_LAB_PROVIDER_ID_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.memberId]: {
            ...state.map[action.memberId],
            znlabsProviderIdentifier: action.providerId,
          },
        },
        isSending: false,
      }
    case ACCEPT_EULA_FAILURE:
    case UPDATE_ZN_LAB_PROVIDER_ID_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_BALANCE_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.clientId]: {
            ...state.map[action.clientId],
            balance: action.balanceAmount,
          },
        },
      }
    case FETCH_BALANCE_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.clientId]: {
            ...state.map[action.clientId],
            patients: R.uniq([
              ...(state.map[action.clientId].patients || []),
              action.patientId,
            ]),
          },
        },
      }
    case UNLINK_USER_FROM_CHEWY_ACCOUNT:
      return {
        ...state,
        isSending: true,
        error: null,
      }
    case UNLINK_USER_FROM_CHEWY_ACCOUNT_SUCCESS:
      return {
        ...state,
        isSending: false,
        map: {
          ...state.map,
          [action.clientId]: {
            ...state.map[action.clientId],
            hasKyriosId: false,
          },
        },
      }
    case UNLINK_USER_FROM_CHEWY_ACCOUNT_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
        map: {
          ...state.map,
          [action.clientId]: {
            ...state.map[action.clientId],
            hasKyriosId: true,
          },
        },
      }
    case LINK_USER_TO_CHEWY_ACCOUNT:
      return {
        ...state,
        map: {
          ...state.map,
          [action.clientId]: {
            ...state.map[action.clientId],
            hasKyriosId: true,
          },
        },
      }
    default:
      return state
  }
}

export default users
export const getUsers = (state: RootState): UsersState => state.users
export const getUsersMap = (state: RootState) => getUsers(state).map
export const getUser = (id: string | Nil) =>
  createSelector(getUsersMap, (map) => (id ? map[id] : undefined))
export const getUserName = (id: string | Nil) =>
  createSelector(getUser(id), (user) => Utils.getPersonString(user))
export const getUserFirstName = (id: string | Nil) =>
  createSelector(getUser(id), (user) => R.prop('firstName', user))
export const getUserLastName = (id: string | Nil) =>
  createSelector(getUser(id), (user) => R.prop('lastName', user))
export const getUserTaxCertificationNumber = (id: string | Nil) =>
  createSelector(getUser(id), (user) => R.prop('taxIdentificationNumber', user))
export const getMultipleUsers = (ids: string[] | Nil) =>
  createSelector(getUsersMap, (map: Record<string, User>) =>
    ids ? R.props<string, User>(ids, map) : [],
  )
export const getUserIsFetching = (state: RootState) =>
  getUsers(state).isFetching
export const getUserIsSending = (state: RootState) => getUsers(state).isSending
export const getError = (state: RootState) => getUsers(state).error
export const getUserByPatient = (
  patientId: string | Nil,
  userId?: string | Nil,
) =>
  createSelector(getUsersMap, (map: UsersState['map']) =>
    userId
      ? map[userId]
      : Object.values(map).find(
          (user) => user.patients && R.includes(patientId, user.patients),
        ),
  )

export const getPatientsForUser = (id: string) =>
  createSelector([getPatientsMap, getUsersMap], (patientsMap, usersMap) => {
    const user = usersMap[id]
    const patients = user?.patients || []
    const getPatient = (patientId: string) =>
      patientsMap[patientId] || { name: '' }

    return R.sortWith([
      R.ascend(
        R.compose(({ deceased, active }) => deceased || !active, getPatient),
      ),
      R.ascend(R.compose(R.toLower, R.prop('name'), getPatient)),
    ])(patients)
  })
