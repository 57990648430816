import {
  UnitTypes,
  UnitTypesLabelMap,
} from '@pbt/pbt-ui-components/src/localization'

import { MeasurementConstant } from '~/constants/measurementUnits'
import { VitalFormInputTypes } from '~/constants/vitals'
import i18n from '~/locales/i18n'
import { VitalConfigItem } from '~/types'

export const vitalsTableConfig: VitalConfigItem[] = [
  {
    id: 'weight',
    name: i18n.t('Common:WEIGHT'),
    humanReadable: i18n.t('Common:WEIGHT'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.WEIGHT,
      units: UnitTypes.WEIGHT,
      placeholder: '0',
    },
    constant: MeasurementConstant.WeightUnits,
  },
  {
    id: 'temperature',
    name: i18n.t('Common:TEMPERATURE'),
    humanReadable: i18n.t('Common:TEMPERATURE'),
    common: true,
    constant: MeasurementConstant.TemperatureUnits,
    formInput: {
      type: VitalFormInputTypes.TEMPERATURE,
      units: UnitTypes.TEMPERATURE,
    },
  },
  {
    id: 'heartRate',
    name: i18n.t('Soap:VITALS_WIDGET.HEART_RATE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.HEART_RATE'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.BEATS_PER_MINUTE,
      unitsLabel: UnitTypesLabelMap[UnitTypes.BEATS_PER_MINUTE],
    },
  },
  {
    id: 'pulseCharacterId',
    name: i18n.t('Soap:VITALS_WIDGET.PULSE_QUALITY'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.PULSE_QUALITY'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'PulseCharacterTypes',
    },
  },
  {
    id: 'respirationRate',
    name: i18n.t('Soap:VITALS_WIDGET.RESPIRATION'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.RESPIRATION'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.BREATHS_PER_MINUTE,
      unitsLabel: UnitTypesLabelMap[UnitTypes.BREATHS_PER_MINUTE],
    },
  },
  {
    id: 'capillaryRefillTime',
    name: i18n.t('Soap:VITALS_WIDGET.CAPILLARY_REFILL_TIME'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.CAPILLARY_REFILL_TIME'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'CapillaryRefillTime',
    },
  },
  {
    id: 'mucousMembraneColorId',
    name: i18n.t('Soap:VITALS_WIDGET.MUCOUS_MEMBRANE_COLOR'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.MUCOUS_MEMBRANE_COLOR'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'MucousMembraneColors',
    },
  },
  {
    id: 'mucousMembraneMoistureId',
    name: i18n.t('Soap:VITALS_WIDGET.MUCOUS_MEMBRANE_MOISTURE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.MUCOUS_MEMBRANE_MOISTURE'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'MucousMembraneMoistures',
    },
  },
  {
    id: 'skinTurgorId',
    name: i18n.t('Soap:VITALS_WIDGET.TURGOR'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.TURGOR'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'SkinTurgors',
    },
  },
  {
    id: 'mentationId',
    name: i18n.t('Soap:VITALS_WIDGET.MENTATION'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.MENTATION'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'MentationTypes',
    },
  },
  {
    id: 'bodyConditionScore',
    name: i18n.t('Soap:VITALS_WIDGET.BODY_CONDITION_SCORE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.BODY_CONDITION_SCORE'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      options: [
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
        { id: '6', name: '6' },
        { id: '7', name: '7' },
        { id: '8', name: '8' },
        { id: '9', name: '9' },
      ],
    },
  },
  {
    id: 'muscleMassScore',
    name: i18n.t('Soap:VITALS_WIDGET.MUSCLE_MASS_SCORE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.MUSCLE_MASS_SCORE'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.MUSCLE_MASS_SCORE,
    },
  },
  {
    id: 'fearAnxietyScore',
    name: i18n.t('Soap:VITALS_WIDGET.FEAR_ANXIETY_SCORE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.FEAR_ANXIETY_SCORE'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'FearAnxietyScore',
    },
  },
  {
    id: 'dyspnea',
    name: i18n.t('Soap:VITALS_WIDGET.DYSPNEA'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.DYSPNEA'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      options: [
        { id: 'false', name: 'No', nameTranslation: i18n.t('Common:NO') },
        { id: 'true', name: 'Yes', nameTranslation: i18n.t('Common:YES') },
      ],
    },
  },
  {
    id: 'painScore',
    name: i18n.t('Soap:VITALS_WIDGET.PAIN_SCORE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.PAIN_SCORE'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'PainScore',
    },
  },
  {
    id: 'bloodPressure',
    name: i18n.t('Soap:VITALS_WIDGET.BLOOD_PRESSURE'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.BLOOD_PRESSURE'),
    common: true,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.MILLIMETRE_OF_MERCURY,
      unitsLabel: UnitTypesLabelMap[UnitTypes.MILLIMETRE_OF_MERCURY],
    },
  },
  {
    id: 'spo2',
    name: i18n.t('Soap:VITALS_WIDGET.SPO2'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.SPO2'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.PERCENT,
      unitsLabel: UnitTypesLabelMap[UnitTypes.PERCENT],
    },
  },
  {
    id: 'etco2',
    name: i18n.t('Soap:VITALS_WIDGET.ETCO2'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.ETCO2'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.MILLIMETRE_OF_MERCURY,
      unitsLabel: UnitTypesLabelMap[UnitTypes.MILLIMETRE_OF_MERCURY],
    },
  },
  {
    id: 'o2Level',
    name: i18n.t('Soap:VITALS_WIDGET.O2_LEVEL'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.O2_LEVEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.LITRES_PER_MINUTE,
      unitsLabel: UnitTypesLabelMap[UnitTypes.LITRES_PER_MINUTE],
    },
  },
  {
    id: 'isoSevoLevel',
    name: i18n.t('Soap:VITALS_WIDGET.ISO_SEVO_LEVEL'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.ISO_SEVO_LEVEL'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.INPUT,
      units: UnitTypes.PERCENT,
      unitsLabel: UnitTypesLabelMap[UnitTypes.PERCENT],
    },
  },
  {
    id: 'recumbencyId',
    name: i18n.t('Soap:VITALS_WIDGET.RECUMBENCY'),
    humanReadable: i18n.t('Soap:VITALS_WIDGET.RECUMBENCY'),
    common: false,
    formInput: {
      type: VitalFormInputTypes.SELECT,
      optionsType: 'VitalRecumbency',
    },
  },
]
