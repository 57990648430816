import { useSelector } from 'react-redux'

import {
  getAutoSaveInvoice,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import {
  getOrderIsSigning,
  getOrdersIsSending,
  getPrescriptionIsEmail,
  getPrescriptionIsPrint,
} from '~/store/reducers/orders'
import { getIsSaving as getSoapIsSaving } from '~/store/reducers/soap'

export const usePrescriptionLoading = (outsideSoap?: boolean) => {
  const isEmailing = useSelector(getPrescriptionIsEmail)
  const isPrinting = useSelector(getPrescriptionIsPrint)
  const isOrderSigning = useSelector(getOrderIsSigning)
  const isOrdersSending = useSelector(getOrdersIsSending)
  const soapIsSaving = useSelector(getSoapIsSaving)
  const isInvoiceSending = useSelector(getFinanceIsLoading)
  const autoSaveInvoice = useSelector(getAutoSaveInvoice)

  const isSending =
    isOrdersSending || ((isInvoiceSending || autoSaveInvoice) && outsideSoap)
  const isSigning = isOrderSigning || (!outsideSoap && soapIsSaving)

  const isLoading = isSending || isSigning || isEmailing || isPrinting

  return { isLoading, isSending, isSigning, isEmailing, isPrinting }
}
