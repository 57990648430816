import React from 'react'
import { Box, Stack } from '@mui/material'
import { Business, Text } from '@pbt/pbt-ui-components'

import { AutoReplyCommunicationsSubSection } from '~/types'

import { AutoReplySubSection } from './AutoReplySubSection'

export interface AutoReplySectionProps {
  business: Business
  sectionId: string
  sectionName: string
  subSections: AutoReplyCommunicationsSubSection[]
}

export const AutoReplySection = ({
  business,
  sectionId,
  sectionName,
  subSections,
}: AutoReplySectionProps) => (
  <Box>
    <Text strong mb={1} variant="subheading3">
      {sectionName}
    </Text>
    <Stack spacing={1}>
      {subSections.map(
        ({
          id: subSectionId,
          maxLength,
          messageField,
          toggleField,
          tooltipText,
          withTextFormat,
          onPreview,
        }) => (
          <AutoReplySubSection
            business={business}
            key={`${sectionId}_${subSectionId}`}
            maxLength={maxLength}
            messageField={messageField}
            toggleField={toggleField}
            tooltipText={tooltipText}
            withTextFormat={withTextFormat}
            onPreview={onPreview}
          />
        ),
      )}
    </Stack>
  </Box>
)
