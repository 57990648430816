import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import Link from '~/components/common/link/Link'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getPatient } from '~/store/reducers/patients'
import { Invoice } from '~/types'
import { useGetInvoiceSectionsWithLimit } from '~/utils/finance'

import { CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT } from '../common/tableConstants'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      textDecorationColor: theme.colors.secondaryText,
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'InvoiceTablePatientNameCell' },
)

interface InvoiceTablePatientNameCellProps {
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

export const InvoiceTablePatientNameCell = ({
  invoice,
  multipleRowsLimit,
}: InvoiceTablePatientNameCellProps) => {
  const classes = useStyles()

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const sections = useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit)

  const patientIdFromRetailOrder =
    'retailOrder' in invoice
      ? (invoice.retailOrder?.sections?.length || 0) > 1
        ? null
        : invoice.retailOrder?.sections[0]?.patient.id
      : (invoice as Invoice).patientId

  const patientId = sections
    ? null
    : isChewyCheckoutEnabled
      ? patientIdFromRetailOrder
      : (invoice as Invoice).patientId
  const patientFromStore = useSelector(getPatient(patientId))

  if (sections && !R.isEmpty(sections)) {
    return (
      <>
        {sections.map(({ patient }, index) => {
          const id = patient?.id
          const patientName = patient?.name
          return (
            <Box
              alignItems="center"
              display="flex"
              // eslint-disable-next-line react/no-array-index-key
              key={`${id}_${index}`}
              minHeight={CLIENT_BILLING_ACTIVITY_CELL_MIN_HEIGHT}
              width="100%"
            >
              <Link
                className={classes.link}
                id="credit-adjustment-patient-link"
                target="_self"
                to={`/patient/${id}`}
              >
                <Text variant="body2">{patientName}</Text>
              </Link>
            </Box>
          )
        })}
      </>
    )
  }

  if (!patientFromStore) {
    return null
  }

  return (
    <Link
      className={classes.link}
      id="credit-adjustment-patient-link"
      target="_blank"
      to={`/patient/${patientFromStore.id}`}
    >
      <Text variant="body2">{patientFromStore.name}</Text>
    </Link>
  )
}
