import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import FeatureToggle from '~/constants/featureToggle'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import { fetchPrescriptionPrintInfo } from '~/store/actions/orders'
import { fetchClientFinanceCharges } from '~/store/duck/clientFinanceData'
import {
  useIsFood,
  useNavigateOrderToPrescription,
  useOpenPrescriptionDialogAfterFetchUnifiedOrder,
} from '~/store/hooks/orders'
import {
  useDeletePrescription,
  useIsDeclinableOrDeletable,
} from '~/store/hooks/prescription'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import { Order } from '~/types'
import {
  getPrescriptionOrderId,
  getPrescriptionType,
} from '~/utils/prescription'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'

export interface PrescriptionItemActionsProps
  extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
  permissions?: any
}

const PrescriptionItemActions = ({
  onDelete: onDeleteProp,
  order,
  permissions,
  ...rest
}: PrescriptionItemActionsProps) => {
  const dispatch = useDispatch()

  const id = getPrescriptionOrderId(order)

  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const isFood = useIsFood(order)

  const isContextItem = useIsCurrentContextItem(order)

  const { isInHouse, isChewyActiveRx, isChewyReactiveRx } = getPrescriptionType(
    order.prescriptionType,
    order.origin,
  )
  const isDeclinableOrDeletable = useIsDeclinableOrDeletable({
    orderType: order.type,
    stateId: order.stateId,
    prescriptionType: order.prescriptionType,
    origin: order.origin,
  })

  const handleDelete = useDeletePrescription({
    hasRefills: Boolean(order.refills?.length),
    isFromSoap: Boolean(soapId),
    name: order.name,
    order,
    onDelete: onDeleteProp,
  })

  const { fetchUnifiedOrderAndOpenPrescription } =
    useOpenPrescriptionDialogAfterFetchUnifiedOrder({
      onClose: () =>
        order.clientId &&
        dispatch(
          fetchClientFinanceCharges({
            id: order.clientId,
            soapId: order.soapId,
          }),
        ),
    })

  const openPrescription = () => {
    if (id) {
      fetchUnifiedOrderAndOpenPrescription(id)
    }
  }

  const handlePrint = () => {
    if (id) {
      dispatch(fetchPrescriptionPrintInfo({ ...order, id }))
    }
  }

  const navigateToPrescriptionDialog = useNavigateOrderToPrescription({
    businessId: soapBusinessId,
    soapId,
  })

  const openFoodPrescriptionDialog = () =>
    navigateToPrescriptionDialog(InventoryCategoryName.FOOD, order)

  if (isEditPostedChargesEnabled && permissions) {
    return (
      <ItemWithBadgesActions
        tooltip={<OrderTooltip order={order} />}
        onAddRX={openFoodPrescriptionDialog}
        onDelete={handleDelete}
        onEdit={openPrescription}
        onPrint={handlePrint}
        {...rest}
      />
    )
  }

  return (
    <ItemWithBadgesActions
      tooltip={isInHouse ? <OrderTooltip order={order} /> : undefined}
      onAddRX={isFood ? openFoodPrescriptionDialog : undefined}
      onDelete={isDeclinableOrDeletable ? handleDelete : undefined}
      onEdit={!isFood ? openPrescription : undefined}
      onPrint={
        !isChewyActiveRx && !isChewyReactiveRx && isContextItem
          ? handlePrint
          : undefined
      }
      {...rest}
    />
  )
}

export default PrescriptionItemActions
