import React, { ForwardedRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text, WellnessPlanVersion } from '@pbt/pbt-ui-components'
import {
  KioskBackgroundCustom,
  KioskBackgroundDefault,
} from '@pbt/pbt-ui-components/src/icons'

import ClinicLogo from '~/components/common/logos/ClinicLogo'
import { getCurrentBusiness } from '~/store/reducers/auth'

import WellnessPlanReviewTable from './WellnessPlanReviewTable'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      zIndex: theme.zIndex.base,
      position: 'relative',
      padding: theme.spacing(3, 5),
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
      minHeight: 650,
    },
    background: {
      position: 'absolute',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'below'),
      bottom: 0,
      left: 0,
      height: 'fit-content',
      width: '100%',
    },
    titleContainer: {
      position: 'absolute',
      top: theme.spacing(3),
      left: theme.spacing(3),
    },
    clinicLogo: {
      width: 180,
      height: 95,
    },
  }),
  { name: 'WellnessPlanReview' },
)

export interface WellnessPlanReviewProps {
  classes?: ClassesType<typeof useStyles>
  showPrice?: boolean
  title: string
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanReview = forwardRef(function WellnessPlanReview(
  {
    showPrice = false,
    title,
    classes: classesProp,
    wellnessPlanVersion,
  }: WellnessPlanReviewProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const classes = useStyles({ classes: classesProp })
  const business = useSelector(getCurrentBusiness)

  const BackgroundProps = {
    preserveAspectRatio: 'none',
    className: classes.background,
  }

  return (
    <Grid
      container
      item
      alignContent="flex-start"
      alignItems="center"
      className={classes.root}
      direction="column"
      justifyContent="center"
      ref={ref}
    >
      {business?.backgroundColor ? (
        <KioskBackgroundCustom
          fill={business.backgroundColor}
          {...BackgroundProps}
        />
      ) : (
        <KioskBackgroundDefault {...BackgroundProps} />
      )}
      <Grid item className={classes.titleContainer}>
        <Text strong fontSize="1.8rem" variant="h1">
          {title}
        </Text>
      </Grid>
      <Grid item>
        <ClinicLogo className={classes.clinicLogo} clinic={business} />
      </Grid>
      <WellnessPlanReviewTable
        showPrice={showPrice}
        wellnessPlanVersion={wellnessPlanVersion}
      />
    </Grid>
  )
})

export default WellnessPlanReview
