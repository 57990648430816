import { useDispatch, useSelector } from 'react-redux'

import DialogNames from '~/constants/DialogNames'

import { closeDialog, getDialogsListByName } from '../duck/dialogs'

export const useCloseDialogsCallback = (name: DialogNames) => {
  const dispatch = useDispatch()
  const dialogs = useSelector(getDialogsListByName(name))

  return () => {
    dialogs.forEach((dialog) => {
      dispatch(closeDialog(dialog.id))
    })
  }
}
