import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import { savePerson } from '~/store/actions/registration'
import { getRegistrationPerson } from '~/store/reducers/registration'

import UploadPage from './UploadPage'

const AvatarUploadPage = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const dispatch = useDispatch()
  const person = useSelector(getRegistrationPerson)

  if (!person || !person.email) {
    return <Navigate replace to="/" />
  }

  const onUploaded = (url: string) => {
    person.avatar = url // TODO: get rid of DTO objects and use object restructing
    dispatch(savePerson(person))
    goBack()
  }

  return (
    <UploadPage aspectRatio={1} onCancel={goBack} onUploaded={onUploaded} />
  )
}

export default AvatarUploadPage
