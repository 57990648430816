import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DateUtils } from '@pbt/pbt-ui-components'

import LandingWidget, {
  LandingWidgetProps,
} from '~/components/dashboard/landing/widgets/LandingWidget'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { getWidgetDataIsLoading, getWidgetMeta } from '~/store/duck/landing'
import { VitalRecord, WidgetColumn } from '~/types'

import VitalsWidget from './VitalsWidget'

interface VitalsSnapshotProps
  extends Omit<LandingWidgetProps<VitalRecord>, 'component' | 'data'> {
  clientId: string
  expandedSnapshotType?: SnapshotsAliasTypes
  name: string
  patientId: string
}

const VitalsSnapshot = ({
  name,
  clientId,
  patientId,
  expandedSnapshotType,
  ...rest
}: VitalsSnapshotProps) => {
  const { t } = useTranslation(['Common'])

  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Vitals,
    ),
  )
  const vitalsMeta = useSelector(
    getWidgetMeta(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Vitals,
    ),
  )

  const columns: WidgetColumn<VitalRecord>[] = [
    {
      id: 'date',
      prop: ({ creationDate }) => DateUtils.formatDate(creationDate),
      label: t('Common:DATE_TIME'),
      width: 1,
    },
    {
      id: 'value',
      prop: ({ value }) => value,
      label: t('Common:VALUE'),
      width: 1,
    },
    {
      id: 'notes',
      prop: ({ notes }) => notes,
      label: t('Common:NOTES'),
      width: 6,
    },
  ]

  return (
    <LandingWidget
      canNavigateToDetails={false}
      columns={columns}
      component={VitalsWidget}
      componentProps={{
        clientId,
        patientId,
      }}
      isLoading={isLoading}
      meta={vitalsMeta}
      name={name}
      showPaddings={false}
      {...rest}
    />
  )
}

export default VitalsSnapshot
