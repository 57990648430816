import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { moment } from '@pbt/pbt-ui-components'

import PageAccordion from '~/components/elements/PageAccordion/PageAccordion'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getMultipleTimetableEvents } from '~/store/reducers/timetable'
import {
  getWhiteboardIsLoading,
  getWhiteboardTreatmentsSchedules,
} from '~/store/reducers/whiteboard'
import { WhiteboardTreatmentsSchedule } from '~/types'
import { dateToIntervals } from '~/utils/time'
import useTimetableDate from '~/utils/useTimetableDate'

import Timetable from '../../Timetable'
import WhiteboardTreatmentsViewRow, {
  WhiteboardTreatmentsViewRowProps,
} from './WhiteboardTreatmentsViewRow'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      marginTop: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(0, 1),
    },
    dischargedHeader: {
      marginTop: theme.spacing(2),
    },
    dischargedSection: {
      opacity: 0.8,
    },
  }),
  { name: 'WhiteboardTreatmentsView' },
)

export interface WhiteboardTreatmentsViewProps {
  HeaderComponent: React.JSXElementConstructor<any>
}

const WhiteboardTreatmentsView = ({
  HeaderComponent,
}: WhiteboardTreatmentsViewProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isDischargedSectionEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DISCHARGED_TREATMENTS_SECTION),
  )
  const schedules = useSelector(getWhiteboardTreatmentsSchedules)
  const appointments = useSelector(
    getMultipleTimetableEvents(
      schedules.map((schedule) => schedule.appointment),
    ),
  )
  const isLoading = useSelector(getWhiteboardIsLoading)

  const { selectedDate } = useTimetableDate()

  const [scrollOffset, setScrollOffset] = useState(0)

  const startDate = moment(selectedDate).startOf('day')
  const endDate = moment(selectedDate).endOf('day')
  const timeColumns = useMemo(
    () => dateToIntervals(startDate, endDate),
    [selectedDate],
  )

  const deriveRefs = (
    schedule: WhiteboardTreatmentsSchedule,
  ): WhiteboardTreatmentsViewRowProps['scrollRefs'] => [
    React.createRef<HTMLDivElement>(), // mainScrollRef
    Array.from({ length: (schedule.tasks || []).length }, () =>
      React.createRef<HTMLDivElement>(),
    ), // tasksScrollRefs
    React.createRef<HTMLDivElement>(), // doneTaskHeaderScrollRef
    React.createRef<HTMLDivElement>(), // addButtonScrollRef
  ]

  const rowRefs = Array.from(schedules, deriveRefs)

  const [activeTreatments, dischargedTreatments] = R.partition(
    (schedule) =>
      appointments.find((appt) => appt.id === schedule.appointment)?.state
        ?.name !== 'Discharged',
    schedules,
  )
  const activeRefs = Array.from(activeTreatments, deriveRefs)
  const dischargedRefs = Array.from(dischargedTreatments, deriveRefs)

  return (
    <Timetable
      row
      HeaderComponent={HeaderComponent}
      HeaderProps={{
        onScrollOffsetChange: setScrollOffset,
      }}
      additionalRefs={R.flatten([...activeRefs, ...dischargedRefs])}
      classes={{
        content: classes.content,
      }}
      isLoading={isLoading}
    >
      {isDischargedSectionEnabled ? (
        <>
          {activeTreatments.map((schedule, index) => (
            <WhiteboardTreatmentsViewRow
              className={classes.row}
              key={schedule.appointment}
              schedule={schedule}
              scrollOffset={scrollOffset}
              scrollRefs={activeRefs[index]}
              timeColumns={timeColumns}
            />
          ))}
          {dischargedTreatments.length > 0 && (
            <PageAccordion
              className={classes.dischargedHeader}
              title={t('Common:DISCHARGED')}
            >
              <Grid className={classes.dischargedSection}>
                {dischargedTreatments.map((schedule, index) => (
                  <WhiteboardTreatmentsViewRow
                    className={classes.row}
                    key={schedule.appointment}
                    schedule={schedule}
                    scrollOffset={scrollOffset}
                    scrollRefs={dischargedRefs[index]}
                    timeColumns={timeColumns}
                  />
                ))}
              </Grid>
            </PageAccordion>
          )}
        </>
      ) : (
        <>
          {schedules.map((schedule, index) => (
            <WhiteboardTreatmentsViewRow
              className={classes.row}
              key={schedule.appointment}
              schedule={schedule}
              scrollOffset={scrollOffset}
              scrollRefs={rowRefs[index]}
              timeColumns={timeColumns}
            />
          ))}
        </>
      )}
    </Timetable>
  )
}

export default WhiteboardTreatmentsView
