import React from 'react'
import { useTranslation } from 'react-i18next'

import BaseValidationDialog, {
  BaseValidationDialogProps,
  FieldNames,
  SoapValidationField,
} from './BaseValidationDialog'

const otcFieldsList: (string | SoapValidationField)[] = [
  {
    name: FieldNames.PATIENT_DATE_OF_BIRTH,
    required: true,
  },
  {
    name: FieldNames.PATIENT_SPECIES,
    required: true,
  },
  {
    name: FieldNames.PATIENT_GENDER,
    required: true,
  },
  {
    name: FieldNames.PATIENT_SPAYED_NEUTERED_STATUS,
    required: true,
  },
]

export interface OTCValidationDialogProps
  extends Omit<BaseValidationDialogProps, 'title'> {}

const OTCValidationDialog = ({
  fieldsList,
  ...rest
}: OTCValidationDialogProps) => {
  const { t } = useTranslation('Dialogs')

  return (
    <BaseValidationDialog
      fieldsList={otcFieldsList.concat(fieldsList || [])}
      title={t('Dialogs:OTC_VALIDATION_DIALOG.TITLE')}
      {...rest}
    />
  )
}

export default OTCValidationDialog
