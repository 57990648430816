import { QueryBusinessAppointmentTypesArgs } from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  CREATE_BUSINESS_APPOINTMENT_TYPE,
  UPDATE_BUSINESS_APPOINTMENT_TYPE,
} from './graphql/mutations/appointmentTypes'
import { FETCH_APPOINTMENT_TYPES } from './graphql/queries/appointmentTypes'
import * as Schema from './schemas'

export const fetchAppointmentTypes = requestQuery({
  query: FETCH_APPOINTMENT_TYPES,
  variablesHandler: (
    _: string,
    { businessId, searchFilter }: QueryBusinessAppointmentTypesArgs,
  ) => ({
    businessId,
    searchFilter,
  }),
  schema: Schema.appointmentTypeList,
})

export const createAppointmentType = requestMutation({
  mutation: CREATE_BUSINESS_APPOINTMENT_TYPE,
  variablesHandler: (businessId, input) => ({
    businessId,
    input,
  }),
  schema: Schema.appointmentType,
})

export const updateAppointmentType = requestMutation({
  mutation: UPDATE_BUSINESS_APPOINTMENT_TYPE,
  variablesHandler: (businessId, id, input) => ({
    businessId,
    id,
    input,
  }),
  schema: Schema.appointmentType,
})
