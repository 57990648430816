import i18n from '~/locales/i18n'

export const SchedulerViewType = {
  SCHEDULE: 'schedule',
  BOARDING: 'boarding',
}

export const SchedulerViewHumanReadable = [
  {
    name: SchedulerViewType.SCHEDULE,
    label: i18n.t('Common:SCHEDULE_NOUN'),
  },
  {
    name: SchedulerViewType.BOARDING,
    label: i18n.t('Common:BOARDING_APPOINTMENT_TYPE_NOUN'),
  },
]
