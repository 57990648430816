import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  Field,
  PermissionArea,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'

interface AppointmentCancellationReasonProps {
  appointmentCancellationReasonEnabled: Field
  businessId: string
}

export const AppointmentCancellationReason = ({
  appointmentCancellationReasonEnabled,
  businessId,
}: AppointmentCancellationReasonProps) => {
  const { t } = useTranslation('Businesses')

  const business = useSelector(getBusiness(businessId))
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  return (
    <Box>
      <Text strong variant="subheading3">
        {t(
          'Businesses:APPOINTMENT_CONFIGURATION.APPOINTMENT_CANCELLATION_REASON',
        )}
      </Text>
      <PuiSwitch
        disabled={!permissions.update}
        field={appointmentCancellationReasonEnabled}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={
              <Text variant="body2">
                {t(
                  'Businesses:APPOINTMENT_CONFIGURATION.CANCELLATION_REASON_TOOLTIP',
                )}
              </Text>
            }
          >
            {t(
              'Businesses:APPOINTMENT_CONFIGURATION.CANCELLATION_REASON_TOGGLE',
            )}
          </TextWithTooltip>
        }
      />
    </Box>
  )
}
