import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'
import { UploadImage as UploadImageIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    inputArea: {
      height: '100%',
      cursor: 'pointer',
      padding: theme.spacing(4),
    },
    uploadImageIcon: {
      fontSize: '6rem',
      marginBottom: theme.spacing(3),
    },
    iconDragOver: {
      color: theme.colors.badgeColor,
      transition: theme.transitions.create('color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    iconDragOut: {
      color: theme.colors.searchIconBackground,
      transition: theme.transitions.create('color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    areaDragOver: {
      backgroundColor: theme.colors.patientAvatarBackground,
      transition: theme.transitions.create('backgroundColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    areaDragOut: {
      backgroundColor: 'transparent',
      transition: theme.transitions.create('backgroundColor', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    hidden: {
      display: 'none',
    },
    smallText: {
      fontSize: '1.6rem',
    },
    text: {},
  }),
  { name: 'FileInputArea' },
)

export interface FileInputAreaProps {
  Icon?: React.JSXElementConstructor<any>
  classes?: ClassesType<typeof useStyles>
  fileInputMobileText?: string
  fileInputText?: string
  hidden?: boolean
  isDragOver: boolean
  size?: string
}

const FileInputArea = ({
  Icon = UploadImageIcon,
  hidden,
  isDragOver,
  fileInputText,
  fileInputMobileText,
  size = 'normal',
  classes: classesProp,
}: FileInputAreaProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const isBigScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('lg'),
  )

  const text =
    fileInputText || t('Dialogs:ATTACHMENT_DIALOG.CLICK_HERE_TO_UPLOAD_A_FILE')
  const mobileText =
    fileInputMobileText || t('Common:INPUTS.FILE_INPUT.TEXT.MOBILE')

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(classes.inputArea, {
        [classes.hidden]: hidden,
        [classes.areaDragOver]: isDragOver,
        [classes.areaDragOut]: !isDragOver,
      })}
      direction="column"
      justifyContent="center"
      wrap="nowrap"
    >
      <Icon
        className={classNames(classes.uploadImageIcon, {
          [classes.iconDragOver]: isDragOver,
          [classes.iconDragOut]: !isDragOver,
        })}
      />
      <Text
        align="center"
        className={classNames(classes.text, {
          [classes.smallText]: size === 'small',
        })}
        variant="h3"
      >
        {isBigScreen ? text : mobileText}
      </Text>
    </Grid>
  )
}

export default FileInputArea
