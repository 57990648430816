import React from 'react'
import { Grid } from '@mui/material'

import CustomerSatisfactionChart from './CustomerSatisfactionChart'
import ScoreWidget from './ScoreWidget'

const CustomerSatisfactionWidget = () => (
  <Grid container p={2} spacing={2} wrap="nowrap">
    <ScoreWidget />
    <CustomerSatisfactionChart />
  </Grid>
)

export default CustomerSatisfactionWidget
