import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextWithTooltip } from '@pbt/pbt-ui-components'

const ProtocolsLabel = () => {
  const { t } = useTranslation(['Common', 'Tooltips'])

  return (
    <TextWithTooltip
      strong
      tooltipText={t(
        'Tooltips:EACH_PROTOCOL_WILL_DETERMINE_WHAT_TRIGGERS_REMINDER',
      )}
      variant="body2"
    >
      {t('Common:PROTOCOL_ONE_OR_OTHER')}
    </TextWithTooltip>
  )
}

export default ProtocolsLabel
