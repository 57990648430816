import React from 'react'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    cell: {},
    columns: {
      height: 34,
    },
    mainColumn: {},
  }),
  { name: 'SoapTemplateTabsTableColumn' },
)

export interface SoapTemplateTabsTableColumnProps {
  classes: ClassesType<typeof useStyles>
  isMainColumn: boolean
  label: string
  width: string
}

const SoapTemplateTabsTableColumn = ({
  classes: classesProp,
  isMainColumn,
  label,
  width,
}: SoapTemplateTabsTableColumnProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <TableCell
      className={classNames(classes.cell, classes.columns, {
        [classes.mainColumn]: isMainColumn,
      })}
      width={width}
    >
      <Text strong variant="lowAccent2">
        {label}
      </Text>
    </TableCell>
  )
}

export default SoapTemplateTabsTableColumn
