import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  DebouncedInlineSearch,
  Defaults,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import { fetchMember } from '~/store/actions/members'
import {
  fetchMoreItemsForSupportUsersList,
  fetchSupportUsersList,
  updateSupportUserRoles,
} from '~/store/actions/supportUsers'
import {
  getSupportUsersIsFetchingList,
  getSupportUsersIsUpdating,
  getSupportUsersList,
  getSupportUsersTotalCount,
} from '~/store/reducers/supportUsers'
import { getMultipleUsers, getUser } from '~/store/reducers/users'

import MemberRolesSectionContainer, {
  MemberRolesSectionContainerDataHandle,
} from '../general/members/MemberDetailsPanelsSections/MemberRolesSectionContainer'
import MembersTable from '../general/members/MembersTable'

const RolesManagementPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search'])
  const navigate = useNavigate()
  const { memberId } = useParams()

  const isUpdatingRoles = useSelector(getSupportUsersIsUpdating)
  const isFetchingList = useSelector(getSupportUsersIsFetchingList)
  const supportUsersIdsList = useSelector(getSupportUsersList)
  const supportUsers = useSelector(getMultipleUsers(supportUsersIdsList))
  const selectedUser = useSelector(getUser(memberId))
  const totalCount = useSelector(getSupportUsersTotalCount)

  const [query, setQuery] = useState('')
  const [hasRolesChanged, setHasRolesChanged] = useState(false)

  const rolesSectionRef = useRef<MemberRolesSectionContainerDataHandle>(null)

  const navigateToMember = (id: string) => {
    navigate(`/admin/support/roles/${id}`)
  }

  const onDetailsClose = () => {
    navigate('/admin/support/roles')
  }

  useEffect(() => {
    onDetailsClose()
    dispatch(
      fetchSupportUsersList({
        query,
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }),
    )
  }, [query])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (startIndex !== 0) {
      dispatch(
        fetchMoreItemsForSupportUsersList({
          from: startIndex,
          to: endIndex,
          query,
        }),
      )
    }
  }

  const onRolesChanged = useCallback(() => {
    if (selectedUser) {
      const originalRoles = selectedUser.businessToRoleList ?? []
      const newRoles = rolesSectionRef.current?.getBusinessRoleList() || []
      const hasRolesDiff =
        R.symmetricDifference(originalRoles, newRoles).length > 0
      setHasRolesChanged(hasRolesDiff)
    } else {
      setHasRolesChanged(false)
    }
  }, [
    selectedUser,
    rolesSectionRef,
    updateSupportUserRoles,
    setHasRolesChanged,
  ])

  useEffect(() => {
    onRolesChanged()
  }, [isUpdatingRoles])

  const updateRoles = useCallback(() => {
    if (hasRolesChanged && selectedUser) {
      const newRoles = rolesSectionRef.current?.getBusinessRoleList() || []
      dispatch(updateSupportUserRoles(selectedUser.id, newRoles))
    }
  }, [selectedUser, hasRolesChanged, setHasRolesChanged, onRolesChanged])

  const isItemLoaded = (index: number) => Boolean(supportUsers[index])

  useEffect(() => {
    if (memberId) {
      dispatch(fetchMember(memberId))
    }
  }, [memberId])

  return (
    <Grid container item direction="row" flex={1}>
      <Grid container item direction="column" flex={1}>
        <Grid item p={2}>
          <DebouncedInlineSearch
            placeholder={t('Search:BY_NAME_OR_EMAIL')}
            onChange={setQuery}
          />
        </Grid>
        <ExpandableTable
          isLoading={isFetchingList}
          itemId={memberId}
          title={t('Admin:SUPPORT.ROLES_MANAGEMENT.SUPPORT_USERS')}
          onSelected={(id) => navigateToMember(id)}
        >
          <MembersTable
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            members={supportUsers}
            totalCount={totalCount}
          />
        </ExpandableTable>
      </Grid>
      {selectedUser && (
        <Grid container item flex="1" flexDirection="column" p={2}>
          <Grid item flexGrow={1}>
            <MemberRolesSectionContainer
              ignoreRolesInSearch
              includeNotAssignedRoles
              ref={rolesSectionRef}
              teamMember={selectedUser}
              onRoleListChange={onRolesChanged}
            />
          </Grid>
          <Grid item my={1}>
            <ButtonWithLoader
              disabled={!hasRolesChanged}
              loading={isUpdatingRoles}
              onClick={updateRoles}
            >
              {t('Common:SAVE_ACTION')}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default RolesManagementPage
