import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'

import { renderCardFailure } from '~/store/actions/timeline'
import { TimelineItem } from '~/types'

import LoadingCard from './LoadingCard'
import TimelineErrorCard from './TimelineErrorCard'
import useTimelineItems from './timelineItems'

export interface TimelineTableRowProps {
  clientId: string
  expanded?: boolean
  item?: TimelineItem
  patientId: string
}
const TimelineTableRow = ({
  clientId,
  patientId,
  item,
  expanded,
}: TimelineTableRowProps) => {
  const dispatch = useDispatch()
  const timelineItemKey = [item?.type, item?.subType]
    .filter(Boolean)
    .join('.')
    .toLowerCase()
  const timelineItems = useTimelineItems()
  const timelineItem = timelineItems[timelineItemKey] || {}
  const { icon, component: Card, permission } = timelineItem

  const logError = (error: Error, info: { componentStack: string }) => {
    dispatch(renderCardFailure(error, info))
  }
  const isCharheSheet = R.pathEq(['invoice', 'posted'], false, item)

  if (!permission) {
    return null
  }

  return !item || R.isEmpty(item) || !Card ? (
    <LoadingCard />
  ) : (
    <ErrorBoundary
      // eslint-disable-next-line react/no-unstable-nested-components
      FallbackComponent={() => (
        <TimelineErrorCard date={item.date} icon={icon} />
      )}
      onError={logError}
    >
      <Card
        clientId={clientId}
        expanded={expanded}
        icon={icon}
        isInvoice={!isCharheSheet}
        item={item}
        patientId={patientId}
      />
    </ErrorBoundary>
  )
}

export default TimelineTableRow
