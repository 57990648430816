import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_BENCHMARKING_PLUS_SCORES =
  'benchmarkingPlus/FETCH_BENCHMARKING_PLUS_SCORES'
export const FETCH_BENCHMARKING_PLUS_SCORES_SUCCESS =
  'benchmarkingPlus/FETCH_BENCHMARKING_PLUS_SCORES_SUCCESS'
export const FETCH_BENCHMARKING_PLUS_SCORES_FAILURE =
  'benchmarkingPlus/FETCH_BENCHMARKING_PLUS_SCORES_FAILURE'

export const fetchBenchmarkingPlusValues = () => ({
  type: FETCH_BENCHMARKING_PLUS_SCORES,
})
export const fetchBenchmarkingPlusValuesSuccess = (
  values: Array<BenchmarkingScore>,
) => ({
  type: FETCH_BENCHMARKING_PLUS_SCORES_SUCCESS,
  values,
})
export const fetchBenchmarkingPlusValuesFailure = (error: ApiError) => ({
  type: FETCH_BENCHMARKING_PLUS_SCORES_FAILURE,
  error,
})

export interface BenchmarkingScore {
  score: number
  scoreType: string
}

export type BenchmarkingPlusState = {
  benchmarkingScores: Array<BenchmarkingScore>
  error: string | null
  isLoading: boolean
}

export const INITIAL_STATE: BenchmarkingPlusState = {
  benchmarkingScores: [],
  isLoading: false,
  error: null,
}

export const benchmarkingPlusReducer = (
  state: BenchmarkingPlusState = INITIAL_STATE,
  action: AnyAction,
): BenchmarkingPlusState => {
  switch (action.type) {
    case FETCH_BENCHMARKING_PLUS_SCORES:
      return {
        ...state,
        isLoading: true,
        benchmarkingScores: [],
        error: null,
      }
    case FETCH_BENCHMARKING_PLUS_SCORES_SUCCESS:
      return {
        ...state,
        benchmarkingScores: action.values,
        isLoading: false,
        error: null,
      }
    case FETCH_BENCHMARKING_PLUS_SCORES_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    default:
      return state
  }
}

export const getBenchmarkingPlus = (state: RootState): BenchmarkingPlusState =>
  state.benchmarkingPlus
export const getBenchmarkingScores = (state: RootState) =>
  getBenchmarkingPlus(state).benchmarkingScores

export const getIsLoading = (state: RootState) =>
  getBenchmarkingPlus(state).isLoading
export const getError = (state: RootState) => getBenchmarkingPlus(state).error

export function* fetchBenchmarkingPlusValuesSaga() {
  try {
    const scores = yield* requestAPI(API.fetchBenchmarkingPlusValues)
    yield put(
      fetchBenchmarkingPlusValuesSuccess(
        scores.map((s: { score: number; score_type: string }) => ({
          scoreType: s.score_type,
          score: s.score,
        })),
      ),
    )
  } catch (error) {
    yield put(fetchBenchmarkingPlusValuesFailure(error as ApiError))
  }
}

function* watchFetchBenchmarkingPlusValues() {
  yield takeLatest(
    FETCH_BENCHMARKING_PLUS_SCORES,
    fetchBenchmarkingPlusValuesSaga,
  )
}

export function* benchmarkingPlusSaga() {
  yield all([watchFetchBenchmarkingPlusValues()])
}
