import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import FloatingPanelButton from './FloatingPanelButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.profileTopBackground,
    },
  }),
  { name: 'ButtonsPanel' },
)

export type ButtonPanelItem = {
  name: string
  onClick: () => void
}

export interface ButtonsPanelProps {
  buttons: ButtonPanelItem[]
  className?: string
  onClose: () => void
}

const ButtonsPanel = ({ className, buttons, onClose }: ButtonsPanelProps) => {
  const classes = useStyles()
  const validButtons = buttons.filter(Boolean)

  return (
    <Stack
      alignItems="center"
      className={classNames(className, classes.root)}
      direction="row"
      justifyContent="space-between"
      pl={1}
    >
      <Stack alignItems="center" direction="row">
        {validButtons.map((validButton) => (
          <FloatingPanelButton
            key={validButton.name}
            label={validButton.name}
            onClick={validButton.onClick}
          />
        ))}
      </Stack>
      <FloatingPanelButton Icon={CloseIcon} onClick={onClose} />
    </Stack>
  )
}

export default ButtonsPanel
