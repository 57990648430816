import React from 'react'
import { useSelector } from 'react-redux'
import {
  Constant,
  GenderField as GenderFieldComponent,
  GenderFieldProps,
} from '@pbt/pbt-ui-components'

import { getGender } from '~/store/reducers/constants'

const GenderField = (props: Omit<GenderFieldProps, 'Gender'>) => {
  const Gender: Constant[] = useSelector(getGender)

  return <GenderFieldComponent Gender={Gender} {...props} />
}

export default GenderField
