import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Close as CloseIcon } from '@mui/icons-material'
import { Collapse, Grid, IconButton, Snackbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text, Utils } from '@pbt/pbt-ui-components'

import ConfusedDog from '~/components/common/images/ConfusedDog.svg'
import { getErrorsList } from '~/store/duck/errors'
import { ErrorLogRecord } from '~/types'

import ErrorContactForm from '../../error/ErrorContactForm'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      marginTop: theme.spacing(2),
      left: 'unset',
      right: 'unset',
      bottom: 'unset',
      top: 'unset',
      transform: 'unset',
    },
    snackRoot: {
      flexWrap: 'nowrap',
      boxShadow: '0 -2px 10px 0 rgba(0,0,0,0.2), 0 2px 10px 0 rgba(0,0,0,0.2)',
      padding: 0,
      pointerEvents: 'all',
      minWidth: 'unset',
    },
    snackMessage: {
      padding: 0,
    },
    snackAction: {
      padding: 0,
      margin: 0,
    },
    rootCollapsed: {
      width: 52,
      height: 52,
    },
    rootExpanded: {
      width: 380,
    },
    contentRoot: {
      maxHeight: 340,
      border: `2px solid ${theme.colors.title}`,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 -2px 10px 0 rgba(0,0,0,0.2), 0 2px 10px 0 rgba(0,0,0,0.2)',
      overflow: 'hidden',
      marginLeft: 'auto',
    },
    dogImage: {
      cursor: 'pointer',
      position: 'relative',
      left: 0,
      top: 2,
      width: 46,
      height: 69,
      minHeight: 69,
    },
    detailsText: {
      wordBreak: 'break-word',
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
    },
    closeButton: {
      top: theme.spacing(1),
      right: theme.spacing(0.75),
      position: 'absolute',
    },
    collapseEntered: {
      overflowY: 'auto',
      ...theme.constants.scrollbar,
    },
    form: {
      borderBottom: theme.constants.tabBorder,
      padding: theme.spacing(2),
    },
    rootWarning: {
      backgroundColor: theme.colors.alertLabelError,
      border: `2px solid ${theme.colors.badgeColor}`,
      width: 78,
      height: 78,
    },
    largeDogImage: {
      width: 69,
      height: 104,
      minHeight: 104,
    },
  }),
  { name: 'ErrorNotification' },
)

const getErrorMessage = (error: ErrorLogRecord) => {
  if (!error || !error.data) {
    return ''
  }
  const { data } = error

  if (data.message) {
    return typeof data.message === 'string'
      ? data.message
      : data.message.message || data.message.name || ''
  }

  return error.data.error || error.data || ''
}

const getStatus = (error: ErrorLogRecord) => error?.status || ''

const getErrorUrl = (error: ErrorLogRecord) =>
  error?.url || window.location.href
const getErrorDescription = (error: ErrorLogRecord) =>
  error?.data?.description || error?.data?.message?.stack || ''
const getErrorPath = (error: ErrorLogRecord) => error?.data?.path || ''
const getErrorText = (error: ErrorLogRecord) => {
  const status = getStatus(error)
  return `${status ? `${status} |` : ''} ${getErrorMessage(error)}`.trim()
}
const getErrorMetadata = (error: ErrorLogRecord) =>
  `portalVersion: ${process.env.REACT_APP_SENTRY_RELEASE} | errorId ${error.id}`

interface ErrorNotificationProps {
  onClose: () => void
  open: boolean
}

const ErrorNotification = ({
  open: openProp,
  onClose,
}: ErrorNotificationProps) => {
  const classes = useStyles()
  const errors = useSelector(getErrorsList)
  const { t } = useTranslation('Common')

  const [expanded, setExpanded] = useState(false)
  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (openProp && !open) {
      setOpen(true)
      setVisible(true)
    } else if (!openProp && open) {
      setOpen(false)
    }
  }, [openProp])

  const handleClose = () => {
    onClose()
    setDetailsExpanded(false)
    setExpanded(false)
  }

  const handleOnExited = () => {
    setVisible(false)
  }

  if (!visible) {
    return null
  }

  return (
    <Snackbar
      ContentProps={{
        classes: {
          root: classes.snackRoot,
          message: classes.snackMessage,
          action: classes.snackAction,
        },
      }}
      TransitionProps={{
        onExited: handleOnExited,
      }}
      className={classes.root}
      message={
        <Grid
          container
          item
          className={classNames(classes.contentRoot, {
            [classes.rootExpanded]: expanded,
            [classes.rootCollapsed]: !expanded,
            [classes.rootWarning]: !expanded && !Utils.isProduction(),
          })}
          direction="column"
          wrap="nowrap"
        >
          {expanded ? (
            <>
              <IconButton
                className={classes.closeButton}
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <ErrorContactForm
                className={classes.form}
                detailsExpanded={detailsExpanded}
                error={errors
                  .map((error) =>
                    [
                      getErrorPath(error)
                        ? `<strong>${getErrorPath(error)}</strong>`
                        : '',
                      `<strong>URL: ${getErrorUrl(error)}</strong>`,
                      `<strong>${getErrorText(error)}</strong>`,
                      `<strong>${getErrorMetadata(error)}</strong>`,
                      getErrorDescription(error),
                    ]
                      .filter(Boolean)
                      .join('<br/>'),
                  )
                  .join('<br /><br />')}
                setDetailsExpanded={setDetailsExpanded}
                onAlertClose={handleClose}
              />
              <Collapse
                classes={{
                  entered: classes.collapseEntered,
                }}
                in={detailsExpanded}
              >
                <Grid container item direction="column" pb={2} pt={1} px={2}>
                  {errors.map((error) => {
                    const description = getErrorDescription(error)
                    const path = getErrorPath(error)
                    return (
                      <React.Fragment key={error.id}>
                        {path && (
                          <Text
                            strong
                            className={classes.detailsText}
                            variant="body3"
                          >
                            {path}
                          </Text>
                        )}
                        <Text
                          strong
                          className={classes.detailsText}
                          variant="body3"
                        >
                          {getErrorText(error)}
                        </Text>
                        {description && (
                          <Text className={classes.detailsText} variant="body3">
                            {description}
                          </Text>
                        )}
                      </React.Fragment>
                    )
                  })}
                </Grid>
              </Collapse>
            </>
          ) : (
            <Grid item onClick={() => setExpanded(true)}>
              <img
                alt={t('Common:CONFUSED_DOG')}
                className={classNames(classes.dogImage, {
                  [classes.largeDogImage]: !Utils.isProduction(),
                })}
                src={ConfusedDog}
              />
            </Grid>
          )}
        </Grid>
      }
      open={open}
    />
  )
}

export default ErrorNotification
