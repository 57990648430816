import React, {
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import {
  CityTextField,
  CountrySelect,
  FieldProp,
  PhoneUtils,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  RegionUtils,
  StateSelect,
  Text,
  TextWithTooltip,
  useFields,
  ZipInput,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import PuiSwitch from '~/components/common/PuiSwitch'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getContactTypes } from '~/store/reducers/constants'
import { Contact as ContactType } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

export interface ContactProps {
  contact?: ContactType
  onChange?: () => void
}

const Contact = forwardRef(function Contact(
  { contact, onChange = () => {} }: ContactProps,
  ref,
) {
  const { t } = useTranslation(['Admin', 'Common'])
  const ContactTypes = useSelector(getContactTypes)
  const business = useSelector(getCurrentBusiness)

  const isCreate = !contact

  const getFields = (contactFields?: ContactType): FieldProp[] => [
    {
      name: 'name',
      label: t('Admin:CONTACT.CONTACT_NAME'),
      validators: ['required'],
      initialValue: isCreate ? '' : contactFields?.name,
    },
    {
      name: 'inReferralList',
      label: t('Admin:CONTACT.IN_REFERRAL_LIST'),
      type: 'toggle',
      initialValue: isCreate ? false : contactFields?.inReferralList,
    },
    {
      name: 'type',
      label: t('Common:TYPE_ONE'),
      type: 'select',
      initialValue: isCreate ? null : contactFields?.type,
    },
    {
      name: 'phone',
      label: t('Common:PHONE_NUMBER'),
      validators: ['phone'],
      initialValue: isCreate ? '' : contactFields?.phone,
    },
    {
      name: 'email',
      label: t('Common:EMAIL'),
      validators: ['email'],
      initialValue: isCreate ? '' : contactFields?.email,
    },
    {
      name: 'website',
      label: t('Common:WEBSITE'),
      validators: ['website'],
      initialValue: isCreate ? '' : contactFields?.website,
    },
    {
      name: 'address',
      label: t('Common:ADDRESS'),
      initialValue: isCreate ? '' : contactFields?.address,
    },
    {
      name: 'suite',
      label: t('Common:SUITE_NUMBER'),
      initialValue: isCreate ? '' : contactFields?.suite,
    },
    {
      name: 'city',
      label: t('Common:CITY'),
      initialValue: isCreate ? '' : contactFields?.city,
    },
    {
      name: 'country',
      label: t('Common:COUNTRY'),
      initialValue: isCreate
        ? RegionUtils.getAvailableCountry(business?.country)
        : RegionUtils.getAvailableCountry(
            contactFields?.country || business?.country,
          ),
    },
    {
      name: 'state',
      label: t('Common:STATE'),
      initialValue: isCreate ? '' : contactFields?.state,
    },
    {
      name: 'zip',
      label: t('Admin:CONTACT.POSTAL_CODE'),
      validators: ['zip'],
      initialValue: isCreate ? '' : contactFields?.zip,
    },
    {
      name: 'notes',
      label: t('Common:NOTES'),
      initialValue: isCreate ? '' : contactFields?.notes,
    },
  ]

  const { fields, validate, reset } = useFields(getFields(contact), false)

  const {
    name,
    inReferralList,
    type,
    phone,
    email,
    website,
    address,
    suite,
    city,
    country,
    state,
    zip,
    notes,
  } = fields

  useEffect(() => {
    if (contact) {
      reset(getFields(contact))
    }
  }, [contact])

  useFieldsChanged(() => {
    onChange()
  }, fields)

  const get = () => ({
    id: contact?.id,
    name: name.value,
    inReferralList: inReferralList.value,
    type: type.value,
    phone: PhoneUtils.parsePhoneNumber(phone.value),
    email: email.value,
    website: website.value,
    address: address.value,
    suite: suite.value,
    city: city.value,
    country: country.value,
    state: state.value,
    zip: zip.value,
    notes: notes.value,
  })

  useImperativeHandle(ref, () => ({
    validate,
    get,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  const onSetCountry = (event: MouseEvent | KeyboardEvent) => {
    country.set(event)
    zip.setValue('')
    state.setValue('')
  }

  return (
    <Grid container direction="column" pb={3} px={3}>
      <Grid item>
        <PuiTextField
          field={name}
          inputProps={{ maxLength: 100 }}
          label={`${name.label}*`}
        />
      </Grid>

      <Grid container alignItems="flex-end" columnSpacing={4}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="type-select">{type.label}</InputLabel>
            <PuiSelect
              field={type}
              input={<Input id="type-select" />}
              items={ContactTypes}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <PuiSwitch
            field={inReferralList}
            label={
              <TextWithTooltip
                tooltipText={t('Admin:CONTACT.REFERRAL_LIST_TOOLTIP')}
                variant="body"
              >
                {inReferralList.label}
              </TextWithTooltip>
            }
          />
        </Grid>
      </Grid>

      <Text strong mt={4} variant="subheading3">
        {t('Common:CONTACT_ONE')}
      </Text>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PhoneInput field={phone} />
        </Grid>
        <Grid item xs={8}>
          <PuiTextField
            field={email}
            inputProps={{ maxLength: 100 }}
            label={email.label}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          <PuiTextField
            field={website}
            inputProps={{ maxLength: 100 }}
            label={website.label}
          />
        </Grid>
      </Grid>

      <Text strong mt={4} variant="subheading3">
        {t('Common:ADDRESS')}
      </Text>

      <Grid container spacing={3}>
        <Grid item xs={8}>
          <PuiTextField
            field={address}
            inputProps={{ maxLength: 100 }}
            label={address.label}
          />
        </Grid>
        <Grid item xs={4}>
          <PuiTextField
            field={suite}
            inputProps={{ maxLength: 100 }}
            label={suite.label}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <CityTextField country={country.value} field={city} />
        </Grid>
        <Grid item xs={3}>
          <CountrySelect field={{ ...country, set: onSetCountry }} />
        </Grid>
        <Grid item xs={3}>
          <StateSelect country={country.value} field={state} />
        </Grid>
        <Grid item xs={3}>
          <PuiTextField
            InputProps={{
              inputComponent: ZipInput,
              inputProps: { country: country.value },
            }}
            field={zip}
            label={zip.label}
          />
        </Grid>
      </Grid>

      <Grid item mb={1} mt={4}>
        <PuiTextArea
          field={notes}
          label={notes.label}
          margin="none"
          showColon={false}
        />
      </Grid>
      <RequiredFieldsNotice />
    </Grid>
  )
})

export default Contact
