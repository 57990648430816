import { Nil } from '@pbt/pbt-ui-components'

import { VaccinationSignature } from '~/types'

import { getAPIPath, getPreludeAPIPath, request } from './core'

export const fetchVaccinations = (
  businessId: string,
  patientId: string,
  soapId: string | Nil,
) =>
  request(
    getAPIPath(`vaccinations/${patientId}`),
    { method: 'GET', params: { businessId, soapId } },
    true,
  )

export const generateProofOfVaccinations = (
  businessId: string,
  patientId: string,
  vaccinationIds: string[],
  signature: VaccinationSignature,
  soapId: string | Nil,
) =>
  request(
    getPreludeAPIPath('certificate/v2/vaccinations/proof'),
    {
      method: 'POST',
      params: { businessId },
      data: {
        patientId,
        soapId,
        vaccinationIds,
        ...signature,
      },
    },
    true,
  )

export const generateProofOfVaccinationsByScope = (
  businessId: string,
  patientId: string,
  vaccinationIds: string[],
  signature: VaccinationSignature,
  soapId: string | Nil,
) =>
  request(
    getPreludeAPIPath('certificate/v2/vaccinations/proof/scope'),
    {
      method: 'POST',
      params: { businessId },
      data: {
        patientId,
        soapId,
        vaccinationIds,
        ...signature,
      },
    },
    true,
  )

export const fetchRabiesVaccinations = (
  businessId: string,
  patientId: string,
  soapId: string | Nil,
) =>
  request(
    getAPIPath(`vaccinations/rabies/${patientId}`),
    { method: 'GET', params: { businessId, soapId } },
    true,
  )

export const generateRabiesCertificate = (
  businessId: string,
  patientId: string,
  vaccinationIds: string[],
  soapId: string | Nil,
) =>
  request(
    getPreludeAPIPath('certificate/v2/vaccinations/rabies'),
    {
      method: 'POST',
      params: { businessId, soapId },
      data: {
        patientId,
        soapId,
        vaccinationIds,
      },
    },
    true,
  )

export const generateRabiesCertificateByScope = (
  businessId: string,
  patientId: string,
  vaccinationIds: string[],
  soapId: string | Nil,
) =>
  request(
    getPreludeAPIPath('certificate/v2/vaccinations/rabies/scope'),
    {
      method: 'POST',
      params: { businessId, soapId },
      data: {
        patientId,
        soapId,
        vaccinationIds,
      },
    },
    true,
  )

export const getPatientVaccinations = (businessId: string, patientId: string) =>
  request(
    getPreludeAPIPath(`certificate/v2/vaccinations/${patientId}`),
    {
      method: 'GET',
      params: { businessId },
    },
    true,
  )
