import { AnyAction } from 'redux'
import { ApiError } from '@pbt/pbt-ui-components'

import { EmailEntityConfig } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  EMAIL_REPORT_CARD,
  EMAIL_REPORT_CARD_FAILURE,
  EMAIL_REPORT_CARD_SUCCESS,
  GENERATE_PDF_FOR_REPORT_CARD,
  GENERATE_PDF_FOR_REPORT_CARD_FAILURE,
  GENERATE_PDF_FOR_REPORT_CARD_SUCCESS,
  WS_REPORT_CARD_PDF_CREATE,
  WS_REPORT_CARD_PDF_CREATE_FAILURE,
  WS_REPORT_CARD_PDF_CREATE_SUCCESS,
} from '../actions/types/communications'
import type { RootState } from '../index'

export const FETCH_REPORT_CARD_CONFIG = 'reportCard/FETCH_REPORT_CARD_CONFIG'
export const FETCH_REPORT_CARD_CONFIG_SUCCESS =
  'reportCard/FETCH_REPORT_CARD_CONFIG_SUCCESS'
export const FETCH_REPORT_CARD_CONFIG_FAILURE =
  'reportCard/FETCH_REPORT_CARD_CONFIG_FAILURE'

export const FETCH_REPORT_CARD_EMAIL_TEMPLATE =
  'reportCard/FETCH_REPORT_CARD_EMAIL_TEMPLATE'
export const FETCH_REPORT_CARD_EMAIL_TEMPLATE_SUCCESS =
  'reportCard/FETCH_REPORT_CARD_EMAIL_TEMPLATE_SUCCESS'
export const FETCH_REPORT_CARD_EMAIL_TEMPLATE_FAILURE =
  'reportCard/FETCH_REPORT_CARD_EMAIL_TEMPLATE_FAILURE'

export const CLEAR_REPORT_CARD_EMAIL_TEMPLATE =
  'reportCard/CLEAR_REPORT_CARD_EMAIL_TEMPLATE'

export const FETCH_REPORT_CARD_PRINT_TEMPLATE =
  'reportCard/FETCH_REPORT_CARD_PRINT_TEMPLATE'
export const FETCH_REPORT_CARD_PRINT_TEMPLATE_SUCCESS =
  'reportCard/FETCH_REPORT_CARD_PRINT_TEMPLATE_SUCCESS'
export const FETCH_REPORT_CARD_PRINT_TEMPLATE_FAILURE =
  'reportCard/FETCH_REPORT_CARD_PRINT_TEMPLATE_FAILURE'

export const CLEAR_REPORT_CARD_PRINT_TEMPLATE =
  'reportCard/CLEAR_REPORT_CARD_PRINT_TEMPLATE'

export const fetchReportCardConfig = (soapId: string) => ({
  type: FETCH_REPORT_CARD_CONFIG,
  soapId,
})
export const fetchReportCardConfigSuccess = (config: EmailEntityConfig) => ({
  type: FETCH_REPORT_CARD_CONFIG_SUCCESS,
  config,
})
export const fetchReportCardConfigFailure = (error: ApiError) => ({
  type: FETCH_REPORT_CARD_CONFIG_FAILURE,
  error,
})

export const fetchReportCardEmailTemplate = (
  soapId: string,
  config: EmailEntityConfig,
) => ({
  type: FETCH_REPORT_CARD_EMAIL_TEMPLATE,
  soapId,
  config,
})
export const fetchReportCardEmailTemplateSuccess = (template: string) => ({
  type: FETCH_REPORT_CARD_EMAIL_TEMPLATE_SUCCESS,
  template,
})
export const fetchReportCardEmailTemplateFailure = (error: ApiError) => ({
  type: FETCH_REPORT_CARD_EMAIL_TEMPLATE_FAILURE,
  error,
})

export const clearReportCardEmailTemplate = () => ({
  type: CLEAR_REPORT_CARD_EMAIL_TEMPLATE,
})

export const fetchReportCardPrintTemplate = (
  soapId: string,
  config: EmailEntityConfig,
) => ({
  type: FETCH_REPORT_CARD_PRINT_TEMPLATE,
  soapId,
  config,
})
export const fetchReportCardPrintTemplateSuccess = (template: string) => ({
  type: FETCH_REPORT_CARD_PRINT_TEMPLATE_SUCCESS,
  template,
})
export const fetchReportCardPrintTemplateFailure = (error: ApiError) => ({
  type: FETCH_REPORT_CARD_PRINT_TEMPLATE_FAILURE,
  error,
})

export const clearReportCardPrintTemplate = () => ({
  type: CLEAR_REPORT_CARD_PRINT_TEMPLATE,
})

export type ReportCardState = {
  config: EmailEntityConfig | undefined
  emailTemplate: string | undefined
  error: string | null
  isGeneratingPdf: boolean
  isLoading: boolean
  isReceivingTemplate: boolean
  isSendingEmail: boolean
  printTemplate: string | undefined
}

export const INITIAL_STATE: ReportCardState = {
  config: undefined,
  emailTemplate: undefined,
  printTemplate: undefined,
  isLoading: false,
  isReceivingTemplate: false,
  isSendingEmail: false,
  isGeneratingPdf: false,
  error: null,
}

export const reportCardReducer = (
  state: ReportCardState = INITIAL_STATE,
  action: AnyAction,
): ReportCardState => {
  switch (action.type) {
    case FETCH_REPORT_CARD_CONFIG:
      return { ...state, isLoading: true, config: undefined }
    case FETCH_REPORT_CARD_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        config: action.config,
      }
    case FETCH_REPORT_CARD_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_REPORT_CARD_EMAIL_TEMPLATE:
      return { ...state, isReceivingTemplate: true }
    case FETCH_REPORT_CARD_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isReceivingTemplate: false,
        emailTemplate: action.template,
      }
    case FETCH_REPORT_CARD_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        isReceivingTemplate: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_REPORT_CARD_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplate: undefined,
      }
    case FETCH_REPORT_CARD_PRINT_TEMPLATE:
      return {
        ...state,
        printTemplate: undefined,
        isReceivingTemplate: true,
      }
    case FETCH_REPORT_CARD_PRINT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isReceivingTemplate: false,
        printTemplate: action.template,
      }
    case FETCH_REPORT_CARD_PRINT_TEMPLATE_FAILURE:
      return {
        ...state,
        isReceivingTemplate: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_REPORT_CARD_PRINT_TEMPLATE:
      return {
        ...state,
        printTemplate: undefined,
      }
    case EMAIL_REPORT_CARD:
      return {
        ...state,
        isSendingEmail: true,
      }
    case EMAIL_REPORT_CARD_SUCCESS:
      return {
        ...state,
        isSendingEmail: false,
      }
    case EMAIL_REPORT_CARD_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
        error: getErrorMessage(action.error),
      }
    case GENERATE_PDF_FOR_REPORT_CARD:
    case WS_REPORT_CARD_PDF_CREATE:
      return { ...state, error: null, isGeneratingPdf: true }
    case GENERATE_PDF_FOR_REPORT_CARD_SUCCESS:
    case WS_REPORT_CARD_PDF_CREATE_SUCCESS:
      return { ...state, isGeneratingPdf: false }
    case GENERATE_PDF_FOR_REPORT_CARD_FAILURE:
    case WS_REPORT_CARD_PDF_CREATE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isGeneratingPdf: false,
      }
    default:
      return state
  }
}

export const getReportCard = (state: RootState): ReportCardState =>
  state.reportCard
export const getReportCardIsLoading = (state: RootState) =>
  getReportCard(state).isLoading
export const getReportCardIsSendingEmail = (state: RootState) =>
  getReportCard(state).isSendingEmail
export const getReportCardTemplateIsReceiving = (state: RootState) =>
  getReportCard(state).isReceivingTemplate
export const getReportCardConfig = (state: RootState) =>
  getReportCard(state).config
export const getReportCardEmailTemplate = (state: RootState) =>
  getReportCard(state).emailTemplate
export const getReportCardPrintTemplate = (state: RootState) =>
  getReportCard(state).printTemplate
export const getReportCardIsGeneratingPdf = (state: RootState) =>
  getReportCard(state).isGeneratingPdf
