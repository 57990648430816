export default [
  {
    id: 'id1',
    name: 'Mocked discount group 1',
  },
  {
    id: 'id2',
    deleted: true,
    name: 'Mocked discount group 2',
  },
]
