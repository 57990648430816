import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { sanitize } from 'dompurify'
import { DateUtils, HtmlNotesPreview, Text } from '@pbt/pbt-ui-components'

import {
  getVaccineDefaultAmount,
  getVaccineDefaultDeliveryLocation,
} from '~/store/reducers/constants'
import { getUserName } from '~/store/reducers/users'
import { Order } from '~/types'
import { getManualInputSelectValue } from '~/utils'

export interface ProcedureContentProps {
  className?: string
  order: Order
}

const ProcedureContent = ({ className, order }: ProcedureContentProps) => {
  const { t } = useTranslation(['Common', 'Time'])

  const {
    notes,
    vaccinationDetails: {
      amount,
      amountId,
      dueDate,
      location,
      locationId,
      lotNumber,
      serialNumber,
      lotExpiration,
      administeredBy,
      manufacturedBy,
      county,
      dateGiven,
    } = {},
  } = order

  const VaccineDefaultAmount = useSelector(getVaccineDefaultAmount)
  const VaccineDefaultDeliveryLocation = useSelector(
    getVaccineDefaultDeliveryLocation,
  )
  const administeredByName = useSelector(getUserName(administeredBy))

  return (
    <Grid item className={className} mb={1}>
      {manufacturedBy && (
        <Text variant="body2">{`${t(
          'Common:MANUFACTURED_BY',
        )}: ${manufacturedBy}`}</Text>
      )}
      {serialNumber && (
        <Text variant="body2">{`${t(
          'Common:SERIAL_NUMBER',
        )}: ${serialNumber}`}</Text>
      )}
      {lotNumber && (
        <Text variant="body2">{`${t('Common:LOT_NUMBER')}: ${lotNumber}`}</Text>
      )}
      {lotExpiration && (
        <Text variant="body2">{`${t(
          'Common:LOT_EXPIRATION',
        )}: ${DateUtils.formatDate(lotExpiration)}`}</Text>
      )}
      {administeredByName && (
        <Text variant="body2">{`${t(
          'Common:ADMINISTERED_BY',
        )}: ${administeredByName}`}</Text>
      )}
      {(amount || amountId) && (
        <Text variant="body2">
          {`${t('Common:AMOUNT')}: ${getManualInputSelectValue(
            VaccineDefaultAmount,
            amountId,
            amount,
          )}`}
        </Text>
      )}
      {(location || locationId) && (
        <Text variant="body2">
          {`${t('Common:LOCATION')}: ${getManualInputSelectValue(
            VaccineDefaultDeliveryLocation,
            locationId,
            location,
          )}`}
        </Text>
      )}
      {dueDate && (
        <Text variant="body2">{`${t(
          'Time:LABEL.DUE_DATE',
        )}: ${DateUtils.formatDate(dueDate)}`}</Text>
      )}
      {county && (
        <Text variant="body2">{`${t('Common:COUNTY')}: ${county}`}</Text>
      )}
      {dateGiven && (
        <Text variant="body2">{`${t(
          'Time:LABEL.DATE_GIVEN',
        )}: ${DateUtils.formatDate(dateGiven)}`}</Text>
      )}
      {notes && (
        <HtmlNotesPreview
          fontSize="1.4rem"
          notes={sanitize(`${t('Common:NOTES')}: ${notes}`)}
        />
      )}
    </Grid>
  )
}

export default ProcedureContent
