import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType, PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

export interface SoapAlertDialogProps extends PuiAlertProps {}

const SoapAlertDialog = ({ open, message, onClose }: SoapAlertDialogProps) => {
  const { t } = useTranslation('Common')

  return (
    <PuiAlert
      hideCloseButton
      iconType={AlertIconType.WARN}
      message={message}
      okButtonText={`${t('Common:GOT_IT')}!`}
      open={open}
      onClose={onClose}
      onOk={onClose}
    />
  )
}

export default SoapAlertDialog
