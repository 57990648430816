import React from 'react'
import { useTranslation } from 'react-i18next'

import SearchContext from '~/constants/searchContext'

import BaseSearchPopper, { BaseSearchPopperProps } from './BaseSearchPopper'

export interface ClientAndPatientSearchPopperProps
  extends Omit<
    BaseSearchPopperProps,
    'clientAdvancedSearch' | 'placeholder' | 'searchContext' | 'text'
  > {
  text?: BaseSearchPopperProps['text']
}

const ClientAndPatientSearchPopper = (
  props: ClientAndPatientSearchPopperProps,
) => {
  const { t } = useTranslation(['Common', 'Search'])

  return (
    <BaseSearchPopper
      clientAdvancedSearch
      placeholder={t('Search:CLIENTS_AND_PATIENTS')}
      searchContext={SearchContext.CLIENT_PATIENTS}
      text={t('Common:ADD_CLIENT_AND_PATIENT')}
      {...props}
    />
  )
}

export default ClientAndPatientSearchPopper
