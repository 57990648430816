import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils, NamedEntity, Nil, Utils } from '@pbt/pbt-ui-components'

import {
  getPluralPackageLabel,
  getPriceUnitsValuedFromId,
  isPackagePluralUnit,
  // @ts-ignore
} from '~/components/dashboard/admin/catalog/inventory/inventoryUtils'
import { ShipmentItem } from '~/types'

import {
  getInventoryInStockUnit,
  getInventoryPriceUnit,
  getInventoryProductSizeUnit,
  getPackageType,
  getProductSizeUnit,
} from '../reducers/constants'

export const useGetInventoryPriceUnits = (
  unitId: string,
  packageTypeId: string,
  priceUnitsSize: number | Nil,
  saleUnitOfMeasure?: string | Nil,
  count?: number | Nil,
) => {
  const { t } = useTranslation('Common')

  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PackageType = useSelector(getPackageType)
  const InventoryPriceUnit = useSelector(getInventoryPriceUnit)

  const getConstant = (
    singularName: string,
    pluralName: string,
  ): NamedEntity[] => {
    const id = Utils.findConstantIdByName(pluralName, InventoryPriceUnit)
    return [
      { id: getPriceUnitsValuedFromId(id, false), name: singularName },
      { id: getPriceUnitsValuedFromId(id, true), name: pluralName },
    ]
  }

  const rawUnits = [
    ...(unitId ? getConstant('Per packages', 'Per X packages') : []),
    ...(packageTypeId
      ? getConstant('Per package units', 'Per X package units')
      : []),
  ]

  const packageSaleUnitOfMeasure =
    count && saleUnitOfMeasure ? `${count} ${saleUnitOfMeasure}` : ''

  const packageName = Utils.getConstantName(packageTypeId, PackageType)
  const packageDisplayName = LanguageUtils.getConstantTranslatedName(
    packageTypeId,
    PackageType,
  )

  const pluralPackageName = getPluralPackageLabel(packageTypeId, PackageType)

  const unitName = Utils.getConstantName(unitId, ProductSizeUnit)
  const unitDisplayName = LanguageUtils.getConstantTranslatedName(
    unitId,
    ProductSizeUnit,
  )

  const formatXName = (name: string) => {
    const result = name
      // Note: first replace plural values, which will have `X` in names
      .replace(
        'X packages',
        `X ${
          packageSaleUnitOfMeasure
            ? `${packageSaleUnitOfMeasure} ${pluralPackageName}`
            : pluralPackageName
        }`,
      )
      .replace(
        'packages',
        packageSaleUnitOfMeasure
          ? `${packageSaleUnitOfMeasure} ${packageName}`
          : packageName,
      )
      .replace('package units', saleUnitOfMeasure || unitName)

    if (priceUnitsSize) {
      return result.replace('X', `${priceUnitsSize}`)
    }

    return result
  }

  const formatXNameTranslation = (name: string) => {
    const result = name
      .replace(
        'X packages',
        `X ${
          packageSaleUnitOfMeasure
            ? `${packageSaleUnitOfMeasure} ${pluralPackageName}`
            : pluralPackageName
        }`,
      )
      .replace(
        'packages',
        packageSaleUnitOfMeasure
          ? `${packageSaleUnitOfMeasure} ${packageDisplayName}`
          : packageDisplayName,
      )
      .replace('package units', saleUnitOfMeasure || unitDisplayName)
      .replace('Per', t('Common:PER'))

    if (priceUnitsSize) {
      return result.replace('X', `${priceUnitsSize}`)
    }

    return result
  }

  return rawUnits.map((constant) => ({
    id: constant.id,
    name: formatXName(constant.name),
    nameTranslation: formatXNameTranslation(constant.name),
  }))
}

export const useGetInventoryPriceUnitsSizeLabel = (
  packageTypeId: string,
  perPackageUnitsId: string,
  unitId: string,
  saleUnitOfMeasure?: string | Nil,
  count?: number | Nil,
) => {
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PackageType = useSelector(getPackageType)
  const InventoryPriceUnit = useSelector(getInventoryPriceUnit)
  const { t } = useTranslation('Utils')

  const packageSaleUnitOfMeasure =
    count && saleUnitOfMeasure ? `${count} ${saleUnitOfMeasure}` : ''

  const defaultUnitLabel = isPackagePluralUnit(InventoryPriceUnit, unitId)
    ? getPluralPackageLabel(packageTypeId, PackageType)
    : LanguageUtils.getConstantTranslatedName(
        perPackageUnitsId,
        ProductSizeUnit,
      )

  const unitLabel = packageSaleUnitOfMeasure
    ? `${packageSaleUnitOfMeasure} ${defaultUnitLabel}`
    : defaultUnitLabel

  return t('Utils:ADMIN_CATALOG_INVENTORY_UTILS.PRICE_UNITS_SIZE_LABEL', {
    unitLabel,
  })
}

export const useInStockUnits = (unitId?: string, packageTypeId?: string) => {
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const InventoryInStockUnit = useSelector(getInventoryInStockUnit)
  const PackageType = useSelector(getPackageType)
  const { t } = useTranslation('Common')

  return [
    {
      id: Utils.findConstantIdByName('Package units', InventoryInStockUnit),
      name: unitId
        ? LanguageUtils.getConstantTranslatedName(
            unitId,
            InventoryProductSizeUnit,
          )
        : t('Common:PACKAGE_UNITS'),
    },
    {
      id: Utils.findConstantIdByName('Package', InventoryInStockUnit),
      name: packageTypeId
        ? LanguageUtils.getConstantTranslatedName(packageTypeId, PackageType)
        : t('Common:PACKAGE'),
    },
  ]
}

export const usePackagingValue = (shipment: ShipmentItem | Nil) => {
  const PackageType = useSelector(getPackageType)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)

  const unitName = LanguageUtils.getConstantTranslatedName(
    shipment?.perPackageUnitsId,
    InventoryProductSizeUnit,
    '',
  )
  const packageName = LanguageUtils.getConstantTranslatedName(
    shipment?.packageTypeId,
    PackageType,
    '',
  )
  return `${shipment?.perPackageAmount || 0} ${unitName} ${packageName}`.trim()
}
