export const ADD_ESTIMATE_TO_SOAP_OR_EVENT =
  'finance/ADD_ESTIMATE_TO_SOAP_OR_EVENT'
export const ADD_ESTIMATE_TO_SOAP_OR_EVENT_FAILURE =
  'finance/ADD_ESTIMATE_TO_SOAP_OR_EVENT_FAILURE'
export const ADD_ESTIMATE_TO_SOAP_OR_EVENT_VALIDATION_FAILURE =
  'finance/ADD_ESTIMATE_TO_SOAP_OR_EVENT_VALIDATION_FAILURE'
export const ADD_ESTIMATE_TO_SOAP_OR_EVENT_SUCCESS =
  'finance/ADD_ESTIMATE_TO_SOAP_OR_EVENT_SUCCESS'

export const CLEAR_APPOINTMENT_INVOICE_ITEMS =
  'finance/CLEAR_APPOINTMENT_INVOICE_ITEMS'
export const CLEAR_CURRENT_INVOICE_DATA = 'finance/CLEAR_CURRENT_INVOICE_DATA'
export const CLEAR_FINANCE_ERROR = 'finance/CLEAR_FINANCE_ERROR'
export const CLEAR_INVOICE_PRINT_HTML = 'finance/CLEAR_INVOICE_PRINT_HTML'
export const CLEAR_LINE_ITEM_CANDIDATES = 'finance/CLEAR_LINE_ITEM_CANDIDATES'
export const CLEAR_LINE_ITEM_DELETED = 'finance/CLEAR_LINE_ITEM_DELETED'

export const CONVERT_TO_LINE_ITEMS = 'finance/CONVERT_TO_LINE_ITEMS'
export const CONVERT_TO_LINE_ITEMS_FAILURE =
  'finance/CONVERT_TO_LINE_ITEMS_FAILURE'
export const CONVERT_TO_LINE_ITEMS_SUCCESS =
  'finance/CONVERT_TO_LINE_ITEMS_SUCCESS'

export const DELETE_ESTIMATE = 'finance/DELETE_ESTIMATE'
export const DELETE_ESTIMATE_FAILURE = 'finance/DELETE_ESTIMATE_FAILURE'
export const DELETE_ESTIMATE_SUCCESS = 'finance/DELETE_ESTIMATE_SUCCESS'

export const DETACH_ESTIMATE_FROM_EVENT = 'finance/DETACH_ESTIMATE_FROM_EVENT'
export const DETACH_ESTIMATE_FROM_EVENT_FAILURE =
  'finance/DETACH_ESTIMATE_FROM_EVENT_FAILURE'
export const DETACH_ESTIMATE_FROM_EVENT_SUCCESS =
  'finance/DETACH_ESTIMATE_FROM_EVENT_SUCCESS'

export const FETCH_APPOINTMENT_INVOICE_ITEMS =
  'finance/FETCH_APPOINTMENT_INVOICE_ITEMS'
export const FETCH_APPOINTMENT_INVOICE_ITEMS_FAILURE =
  'finance/FETCH_APPOINTMENT_INVOICE_ITEMS_FAILURE'
export const FETCH_APPOINTMENT_INVOICE_ITEMS_SUCCESS =
  'finance/FETCH_APPOINTMENT_INVOICE_ITEMS_SUCCESS'

export const FETCH_BATCH_INVOICE = 'finance/FETCH_BATCH_INVOICE'
export const FETCH_BATCH_INVOICE_FAILURE = 'finance/FETCH_BATCH_INVOICE_FAILURE'
export const FETCH_BATCH_INVOICE_SUCCESS = 'finance/FETCH_BATCH_INVOICE_SUCCESS'

export const FETCH_CHECKOUT_INVOICES = 'finance/FETCH_CHECKOUT_INVOICES'
export const FETCH_CHECKOUT_INVOICES_FAILURE =
  'finance/FETCH_CHECKOUT_INVOICES_FAILURE'
export const FETCH_CHECKOUT_INVOICES_SUCCESS =
  'finance/FETCH_CHECKOUT_INVOICES_SUCCESS'

export const FETCH_ESTIMATES = 'finance/FETCH_ESTIMATES'
export const FETCH_ESTIMATES_FAILURE = 'finance/FETCH_ESTIMATES_FAILURE'
export const FETCH_ESTIMATES_SUCCESS = 'finance/FETCH_ESTIMATES_SUCCESS'

export const FETCH_PATIENT_ESTIMATES = 'finance/FETCH_PATIENT_ESTIMATES'
export const FETCH_PATIENT_ESTIMATES_FAILURE =
  'finance/FETCH_PATIENT_ESTIMATES_FAILURE'
export const FETCH_PATIENT_ESTIMATES_SUCCESS =
  'finance/FETCH_PATIENT_ESTIMATES_SUCCESS'

export const FETCH_MORE_PATIENT_ESTIMATES =
  'finance/FETCH_MORE_PATIENT_ESTIMATES'
export const FETCH_MORE_PATIENT_ESTIMATES_FAILURE =
  'finance/FETCH_MORE_PATIENT_ESTIMATES_FAILURE'
export const FETCH_MORE_PATIENT_ESTIMATES_SUCCESS =
  'finance/FETCH_MORE_PATIENT_ESTIMATES_SUCCESS'

export const CLONE_ESTIMATE = 'finance/CLONE_ESTIMATE'
export const CLONE_ESTIMATE_SUCCESS = 'finance/CLONE_ESTIMATE_SUCCESS'
export const CLONE_ESTIMATE_FAILURE = 'finance/CLONE_ESTIMATE_FAILURE'

export const ADD_PATIENT_ESTIMATE_SUCCESS =
  'finance/ADD_PATIENT_ESTIMATE_SUCCESS'

export const FETCH_ESTIMATE_TEMPLATE = 'finance/FETCH_ESTIMATE_TEMPLATE'
export const FETCH_ESTIMATE_TEMPLATE_FAILURE =
  'finance/FETCH_ESTIMATE_TEMPLATE_FAILURE'
export const FETCH_ESTIMATE_TEMPLATE_SUCCESS =
  'finance/FETCH_ESTIMATE_TEMPLATE_SUCCESS'

export const FETCH_INVOICE = 'finance/FETCH_INVOICE'
export const FETCH_INVOICE_BY_EVENT_ID = 'finance/FETCH_INVOICE_BY_EVENT_ID'
export const FETCH_INVOICE_BY_EVENT_ID_FAILURE =
  'finance/FETCH_INVOICE_BY_EVENT_ID_FAILURE'
export const FETCH_INVOICE_BY_EVENT_ID_SUCCESS =
  'finance/FETCH_INVOICE_BY_EVENT_ID_SUCCESS'
export const FETCH_INVOICE_FAILURE = 'finance/FETCH_INVOICE_FAILURE'
export const FETCH_INVOICE_SUCCESS = 'finance/FETCH_INVOICE_SUCCESS'

export const FETCH_INVOICE_TEMPLATE = 'finance/FETCH_INVOICE_TEMPLATE'
export const FETCH_INVOICE_TEMPLATE_FAILURE =
  'finance/FETCH_INVOICE_TEMPLATE_FAILURE'
export const FETCH_INVOICE_TEMPLATE_SUCCESS =
  'finance/FETCH_INVOICE_TEMPLATE_SUCCESS'

export const FETCH_UNPAID_INVOICES = 'finance/FETCH_UNPAID_INVOICES'
export const FETCH_UNPAID_INVOICES_FAILURE =
  'finance/FETCH_UNPAID_INVOICES_FAILURE'
export const FETCH_UNPAID_INVOICES_SUCCESS =
  'finance/FETCH_UNPAID_INVOICES_SUCCESS'

export const PRINT_INVOICE = 'finance/PRINT_INVOICE'
export const PRINT_INVOICE_V2 = 'finance/PRINT_INVOICE_V2'
export const PRINT_INVOICE_FAILURE = 'finance/PRINT_INVOICE_FAILURE'
export const PRINT_INVOICE_SUCCESS = 'finance/PRINT_INVOICE_SUCCESS'

export const REDEEM_LOYALTY_POINTS = 'finance/REDEEM_LOYALTY_POINTS'
export const REDEEM_LOYALTY_POINTS_FAILURE =
  'finance/REDEEM_LOYALTY_POINTS_FAILURE'
export const REDEEM_LOYALTY_POINTS_SUCCESS =
  'finance/REDEEM_LOYALTY_POINTS_SUCCESS'
export const REMOVE_LINE_ITEMS = 'finance/REMOVE_LINE_ITEMS'

export const SAVE_ESTIMATE_TO_EVENT = 'finance/SAVE_ESTIMATE_TO_EVENT'
export const SAVE_ESTIMATE_TO_EVENT_FAILURE =
  'finance/SAVE_ESTIMATE_TO_EVENT_FAILURE'
export const SAVE_ESTIMATE_TO_EVENT_SUCCESS =
  'finance/SAVE_ESTIMATE_TO_EVENT_SUCCESS'

export const SAVE_ESTIMATE_TO_SOAP = 'finance/SAVE_ESTIMATE_TO_SOAP'
export const SAVE_ESTIMATE_TO_SOAP_FAILURE =
  'finance/SAVE_ESTIMATE_TO_SOAP_FAILURE'
export const SAVE_ESTIMATE_TO_SOAP_SUCCESS =
  'finance/SAVE_ESTIMATE_TO_SOAP_SUCCESS'

export const SAVE_INVOICE = 'finance/SAVE_INVOICE'
export const SAVE_INVOICE_FAILURE = 'finance/SAVE_INVOICE_FAILURE'
export const SAVE_INVOICE_SUCCESS = 'finance/SAVE_INVOICE_SUCCESS'

export const SET_INVOICE_AUTO_SAVE = 'finance/SET_INVOICE_AUTO_SAVE'
export const SIGN_ESTIMATE = 'finance/SIGN_ESTIMATE'
export const SIGN_ESTIMATE_FAILURE = 'finance/SIGN_ESTIMATE_FAILURE'
export const SIGN_ESTIMATE_SUCCESS = 'finance/SIGN_ESTIMATE_SUCCESS'

export const UPDATE_BATCH_INVOICE = 'finance/UPDATE_BATCH_INVOICE'
export const UPDATE_INVOICES = 'finance/UPDATE_INVOICES'
export const UPDATE_INVOICE_TEMPLATE = 'finance/UPDATE_INVOICE_TEMPLATE'

export const ADD_PAYMENT_TO_INVOICES = 'finance/ADD_PAYMENT_TO_INVOICES'

export const FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY =
  'finance/FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY'
export const FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_FAILURE =
  'finance/FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_FAILURE'
export const FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_SUCCESS =
  'finance/FETCH_MORE_ITEMS_FOR_BALANCE_HISTORY_SUCCESS'

export const FETCH_BALANCE_HISTORY = 'finance/FETCH_BALANCE_HISTORY'
export const FETCH_BALANCE_HISTORY_FAILURE =
  'finance/FETCH_BALANCE_HISTORY_FAILURE'
export const FETCH_BALANCE_HISTORY_SUCCESS =
  'finance/FETCH_BALANCE_HISTORY_SUCCESS'

export const REFRESH_BALANCE_HISTORY = 'finance/REFRESH_BALANCE_HISTORY'
export const REFRESH_BALANCE_HISTORY_FAILURE =
  'finance/REFRESH_BALANCE_HISTORY_FAILURE'
export const REFRESH_BALANCE_HISTORY_SUCCESS =
  'finance/REFRESH_BALANCE_HISTORY_SUCCESS'

export const UPDATE_INVOICE_COMPLETENESS_FROM_SPAIN =
  'finance/UPDATE_INVOICE_COMPLETENESS_FROM_SPAIN'

export const EDIT_INVOICE_LINE_ITEM = 'finance/EDIT_INVOICE_LINE_ITEM'
export const EDIT_INVOICE_LINE_ITEM_SUCCESS =
  'finance/EDIT_INVOICE_LINE_ITEM_SUCCESS'
export const EDIT_INVOICE_LINE_ITEM_FAILURE =
  'finance/EDIT_INVOICE_LINE_ITEM_FAILURE'

export const EDIT_RETAIL_ORDER_LINE_ITEM = 'finance/EDIT_RETAIL_ORDER_LINE_ITEM'
export const EDIT_RETAIL_ORDER_LINE_ITEM_SUCCESS =
  'finance/EDIT_RETAIL_ORDER_LINE_ITEM_SUCCESS'
export const EDIT_RETAIL_ORDER_LINE_ITEM_FAILURE =
  'finance/EDIT_RETAIL_ORDER_LINE_ITEM_FAILURE'

export const REARRANGE_INVOICE_LINE_ITEMS =
  'finance/REARRANGE_INVOICE_LINE_ITEMS'
export const REARRANGE_INVOICE_LINE_ITEMS_SUCCESS =
  'finance/REARRANGE_INVOICE_LINE_ITEMS_SUCCESS'
export const REARRANGE_INVOICE_LINE_ITEMS_FAILURE =
  'finance/REARRANGE_INVOICE_LINE_ITEMS_FAILURE'

export const REARRANGE_GROUPED_LINE_ITEMS =
  'finance/REARRANGE_GROUPED_LINE_ITEMS'
export const REARRANGE_GROUPED_LINE_ITEMS_SUCCESS =
  'finance/REARRANGE_GROUPED_LINE_ITEMS_SUCCESS'
export const REARRANGE_GROUPED_LINE_ITEMS_FAILURE =
  'finance/REARRANGE_GROUPED_LINE_ITEMS_FAILURE'

export const TOGGLE_INVOICE_GROUP_EXPANDED =
  'finance/TOGGLE_INVOICE_GROUP_EXPANDED'
export const CLEAR_INVOICE_GROUP_EXPANDED =
  'finance/CLEAR_INVOICE_GROUP_EXPANDED'

export const CLEAR_CLONED_ESTIMATE_ID = 'finance/CLEAR_CLONED_ESTIMATE_ID'
