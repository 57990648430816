import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DialogActions, DialogTitle, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PhoneUtils,
  PuiPopper,
  PuiPopperProps,
  PuiTextField,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import { partialUpdateClient } from '~/store/actions/clients'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getUser } from '~/store/reducers/users'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme) => ({
    input: {
      width: 280,
    },
    paper: {
      width: 480,
    },
    dialogTitle: {
      padding: theme.spacing(1.5, 2),
      borderBottom: theme.constants.tableBorder,
    },
    content: {
      padding: theme.spacing(2),
    },
    actions: {
      borderTop: theme.constants.tableBorder,
      justifyContent: 'flex-start',
      padding: theme.spacing(1.5, 2),
    },
    addButton: {
      height: 40,
      width: 150,
    },
  }),
  { name: 'MissingClientContactsPopper' },
)

export interface MissingClientContactsPopperProps extends PuiPopperProps {
  clientId: string
}

const MissingClientContactsPopper = ({
  clientId,
  onClose,
  ...rest
}: MissingClientContactsPopperProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const client = useSelector(getUser(clientId))
  const isLoading = useSelector(getClientIsLoading)
  const closeAfterUpdate = useCloseAfterCreation(onClose, getClientIsLoading)

  const {
    fields: { email, mobilePhone, otherPhone },
    validate,
    reset,
  } = useFields([
    {
      name: 'email',
      label: t('Common:EMAIL'),
      validators: ['email'],
      initialValue: client?.email || '',
    },
    {
      name: 'mobilePhone',
      label: t('Common:MOBILE_PHONE'),
      validators: ['phone'],
      initialValue: client?.mobilePhone || '',
    },
    {
      name: 'otherPhone',
      label: t('Common:OTHER_PHONE'),
      validators: ['phone'],
      initialValue: client?.otherPhone || '',
    },
  ])

  useEffect(() => {
    reset()
  }, [client])

  const handleAddMissingInfo = () => {
    if (validate()) {
      const update = {
        id: clientId,
        email: email.value,
        mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
        otherPhone: PhoneUtils.parsePhoneNumber(otherPhone.value),
      }
      dispatch(partialUpdateClient(update))
      closeAfterUpdate()
    }
  }

  const clientName = Utils.getPersonString(client)

  return (
    <PuiPopper
      classes={{
        paper: classes.paper,
      }}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Text variant="h2">
          {t('Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.ADD_INFO_FOR_CLIENT', {
            clientName,
          })}
        </Text>
      </DialogTitle>
      <Grid container className={classes.content} direction="column">
        {!client?.email && (
          <PuiTextField
            className={classes.input}
            field={email}
            inputProps={{ maxLength: 100 }}
            label={email.label}
            margin="none"
          />
        )}
        {!client?.mobilePhone && (
          <PhoneInput className={classes.input} field={mobilePhone} />
        )}
        {!client?.otherPhone && (
          <PhoneInput className={classes.input} field={otherPhone} />
        )}
      </Grid>
      <DialogActions className={classes.actions}>
        <ButtonWithLoader
          className={classes.addButton}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleAddMissingInfo}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      </DialogActions>
    </PuiPopper>
  )
}

export default MissingClientContactsPopper
