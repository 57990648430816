import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { DateUtils, moment, Text } from '@pbt/pbt-ui-components'

import { Reminder } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    subTitle: {
      color: theme.colors.title,
    },
    subTitleImportant: {
      color: theme.colors.important,
    },
  }),
  { name: 'ReminderDueDateTimeCell' },
)

export interface ReminderDueDateTimeCellProps {
  reminder: Reminder | undefined
}

const ReminderDueDateTimeCell = ({
  reminder,
}: ReminderDueDateTimeCellProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const isInThePast =
    reminder?.dueDatetime && moment(reminder.dueDatetime).isBefore(moment())
  const dueDateTimeState = moment().isSame(reminder?.dueDatetime, 'month')
    ? t('Time:LABEL.THIS_MONTH').toLowerCase()
    : moment(reminder?.dueDatetime).fromNow()

  return (
    <Grid container pl={1}>
      <Text variant="lowAccent3">
        {DateUtils.formatDate(reminder?.dueDatetime)}
      </Text>
      <Text
        className={classNames(classes.subTitle, {
          [classes.subTitleImportant]: isInThePast,
        })}
        variant="body3"
      >
        &nbsp;{dueDateTimeState}
      </Text>
    </Grid>
  )
}

export default ReminderDueDateTimeCell
