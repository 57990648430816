import { useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'

export const useCanApproveAndSubmitChewyActiveRx = (
  needsDvmLicenseEntry: boolean,
) => {
  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const chewyRxPermissions = useSelector(
    getCRUDByArea(PermissionArea.CHEWY_RX_PRESCRIPTION),
  )
  const personPermission = useSelector(getCRUDByArea(PermissionArea.PERSON))

  const canApproveAndSubmitChewyRx =
    permissions.update &&
    chewyRxPermissions.update &&
    (needsDvmLicenseEntry ? personPermission.update : true)

  return canApproveAndSubmitChewyRx
}
