/* eslint-disable no-unused-vars */
import { normalize } from 'normalizr'
import { Defaults } from '@pbt/pbt-ui-components'

import loyaltyPointsMock from './mocks/loyaltyPointsMock'
import * as Schema from './schemas'

export const fetchLoyaltyPointsList = (
  businessId,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query,
) => Promise.resolve(normalize(loyaltyPointsMock, Schema.loyaltyPointsList))
