import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType, PrimitiveTableUtils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

const { getWidthInPercents } = PrimitiveTableUtils

const useStyles = makeStyles(
  (theme) => ({
    table: {},
    header: {},
    titleCell: {
      padding: theme.spacing(0.5, 1),
      border: theme.constants.tableBorder,
      color: theme.colors.disabledLabelText,
      fontSize: '1.4rem',
      fontWeight: 'bold',
    },
    cell: {
      padding: theme.spacing(0.5, 1),
      border: theme.constants.tableBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
      wordBreak: 'break-word',
    },
    row: {},
  }),
  { name: 'StaticTable' },
)

export type StaticTableRow = {
  className?: string
  id?: string
}

export type StaticTableColumn<TRow extends StaticTableRow> = {
  FallbackComponent?: React.JSXElementConstructor<any>
  className?: string
  component?: React.JSXElementConstructor<any>
  componentProps?: any
  field?: keyof TRow
  getTableCellProps?: (row: TRow) => TableCellProps
  id: string
  label?: React.ReactNode
  prop?: ((row: TRow) => any) | keyof TRow
  skip?: (row: TRow) => boolean
  tooltipDescription?: string
  width?: number
}

export interface StaticTableProps<TRow extends StaticTableRow> {
  clamp?: number
  classes?: ClassesType<typeof useStyles>
  columns?: StaticTableColumn<TRow>[]
  emptyValue?: string
  hideHeader?: boolean
  rows?: TRow[]
  showHeaderTooltip?: boolean
}

const StaticTable = <TRow extends StaticTableRow>({
  showHeaderTooltip = false,
  hideHeader = false,
  classes: classesProp,
  columns: columnsProp,
  rows: rowsProp,
  clamp,
  emptyValue = '',
}: StaticTableProps<TRow>) => {
  const columns = columnsProp || []
  const rows = rowsProp || []
  const classes = useStyles({ classes: classesProp })

  const totalWidth = columns.reduce((sum, { width }) => (width || 0) + sum, 0)

  const isMasterProblemsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.MASTER_PROBLEMS_WIDGET),
  )

  return (
    <Table className={classes.table}>
      {!hideHeader && (
        <TableHead className={classes.header}>
          <TableRow>
            {columns.map(
              ({ label = '', id, tooltipDescription, className, width }) =>
                showHeaderTooltip ? (
                  <Tooltip
                    key={id}
                    placement="top"
                    title={tooltipDescription || label}
                  >
                    <TableCell
                      className={classNames(classes.titleCell, className)}
                      width={
                        isMasterProblemsWidgetEnabled && width
                          ? `${width / totalWidth}%`
                          : undefined
                      }
                    >
                      {label}
                    </TableCell>
                  </Tooltip>
                ) : (
                  <TableCell
                    className={classNames(classes.titleCell, className)}
                    key={id}
                    width={
                      isMasterProblemsWidgetEnabled && width
                        ? `${width / totalWidth}%`
                        : undefined
                    }
                  >
                    {label}
                  </TableCell>
                ),
            )}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {rows.map((row, rIndex) => {
          const rowIndex = row.id || rIndex
          return (
            <TableRow
              className={classNames(classes.row, row.className)}
              key={rowIndex}
            >
              {columns.map((item) => {
                const key = `${rowIndex}-${item.id}`
                const Component = item.component
                const ComponentProps = item.componentProps || {}
                const prop = item.prop || item.field
                const value =
                  typeof prop === 'function'
                    ? prop(row)
                    : prop && row && row[prop]
                const valueOrDefault = R.isNil(value) ? emptyValue : value

                const isPlaceholder = R.isEmpty(row)
                const width = item.width
                  ? getWidthInPercents(item.width)
                  : undefined

                if (item.skip?.(row)) {
                  const { FallbackComponent } = item
                  if (FallbackComponent) {
                    return <FallbackComponent item={row} key={key} />
                  }
                  return null
                }

                return (
                  <TableCell
                    className={classes.cell}
                    key={key}
                    style={width ? { width: `${width}%` } : undefined}
                    {...item.getTableCellProps?.(row)}
                  >
                    {isPlaceholder ? (
                      <Skeleton
                        height={16}
                        variant="rectangular"
                        width="100%"
                      />
                    ) : Component ? (
                      <Component item={row} {...ComponentProps} />
                    ) : clamp ? (
                      <Dotdotdot clamp={clamp}>{valueOrDefault}</Dotdotdot>
                    ) : (
                      valueOrDefault
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default StaticTable
