/* eslint-disable react/no-multi-comp */
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  CKEditorLanguagesMap,
  CustomClassicEditorType,
  EditorType,
  PbtBalloonEditor,
  PbtClassicEditor,
  PbtInlineEditor,
  PluginName,
  RealTimeCollaborationPluginNames,
  ToolbarItem,
} from '@pbt/pbt-ckeditor-ui'
import { PlaceholderConstant } from '@pbt/pbt-ui-components'

import { getCurrentUserLanguage } from '~/store/duck/userSettings'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getPlaceholders } from '~/store/reducers/constants'
import { EditorDocumentType, FeatureElements } from '~/types'

import { ImageUploadAdapter } from './ImageUploadAdapter'
import PresenceList from './PresenceList'
import PuiCKEditorError from './PuiCKEditorError'
import PuiCKEditorSkeleton, {
  EDITOR_TOOLBAR_HEIGHT,
} from './PuiCKEditorSkeleton'
import RevisionViewer from './RevisionViewer'
import SidebarCommentsArea from './SidebarCommentsArea'
import { useAddNotesTemplate } from './utils/useAddNotesTemplate'
import { OnInitializeChannelId, useChannelId } from './utils/useChannelId'
import { useEnabledTrackChanges } from './utils/useEnabledTrackChanges'
import {
  OnRestoreCollaborationSession,
  useOnEditorError,
} from './utils/useOnEditorError'
import { useOnEditorReady } from './utils/useOnEditorReady'

const EditorTypeMap = {
  [EditorType.CLASSIC]: PbtClassicEditor.CustomClassicEditor,
  [EditorType.INLINE]: PbtInlineEditor.CustomInlineEditor,
  [EditorType.BALLOON]: PbtBalloonEditor.CustomBalloonEditor,
}

interface PuiCKEditorBaseProps {
  disabled?: boolean
  editorHeight: number
  editorWidth?: string | number
  hideElements?: FeatureElements[]
  id: string
  innerRef?: React.Ref<PuiCKEditorHandle>
  noteType: EditorDocumentType
  removePlugins?: PluginName[]
  toolbar?: ToolbarItem[]
  type?: EditorType
}

interface PuiCKEditorWithRealTimeCollaborationProps
  extends PuiCKEditorBaseProps {
  onChange?: never
  onDangerousChange?: (value: string) => void
  onInitializeChannelId: OnInitializeChannelId
  onRestoreCollaborationSession: OnRestoreCollaborationSession
  withRealTimeCollaboration: boolean
}

interface PuiCKEditorWithoutRealTimeCollaborationProps
  extends PuiCKEditorBaseProps {
  onChange?: (value: string) => void
  onDangerousChange?: never
  onInitializeChannelId?: never
  onRestoreCollaborationSession?: never
  withRealTimeCollaboration?: never
}

export type PuiCKEditorProps =
  | PuiCKEditorWithRealTimeCollaborationProps
  | PuiCKEditorWithoutRealTimeCollaborationProps

export interface PuiCKEditorHandle {
  editorId: string
  getEditorData: () => string | undefined
  setEditorData: (data: string | undefined) => void
}

const PuiCKEditor = ({
  disabled = false,
  id,
  editorHeight,
  editorWidth = '100%',
  hideElements: hideElementsProp,
  innerRef,
  noteType,
  // Be careful with this prop, if you remove a wrong plugin it could lead to editor crashes
  removePlugins: removePluginsProp = [],
  toolbar: toolbarProp,
  type = EditorType.CLASSIC,
  withRealTimeCollaboration = false,
  onChange: onChangeProp,
  onDangerousChange,
  onInitializeChannelId,
  onRestoreCollaborationSession,
}: PuiCKEditorProps) => {
  const hideElements = hideElementsProp || []
  const Placeholders = useSelector(getPlaceholders) as PlaceholderConstant[]
  const currentUserLanguageCode = useSelector(getCurrentUserLanguage)
  const businessId = useSelector(getCurrentBusinessId)

  const editorContainerRef = useRef<HTMLDivElement>(null)
  const presenceListElementRef = useRef<HTMLDivElement>(null)
  const sidebarContainerRef = useRef<HTMLDivElement>(null)
  const viewerContainerRef = useRef<HTMLDivElement>(null)
  const viewerEditorElementRef = useRef<HTMLDivElement>(null)
  const viewerSidebarContainerRef = useRef<HTMLDivElement>(null)

  const [willEditorRestart, setWillEditorRestart] = useState(false)

  // Handles editor initialization
  const { editorRef, isLayoutReady, isEditorReady, onEditorReady } =
    useOnEditorReady({
      editorId: id,
      editorHeight,
      editorWidth,
      removePlugins: removePluginsProp,
      setWillEditorRestart,
      withRealTimeCollaboration,
    })
  // Handles editor error
  const { onError, isFetchingSoapOnError } = useOnEditorError({
    editorId: id,
    noteType,
    bundleType: type,
    onRestoreCollaborationSession,
    onInitializeChannelId,
  })

  // Persist track changes while toggling between different editors
  useEnabledTrackChanges({
    id,
    editor: editorRef.current,
    removedTrackChanges: removePluginsProp.includes('TrackChanges'),
  })
  // Interacts with customized plugin importNotesTemplate
  useAddNotesTemplate(editorRef.current)
  // Initializes user channel id for collaborative session
  const {
    CloudServicesConfig: { bundleVersion, channelId, tokenUrl, webSocketUrl },
  } =
    useChannelId(
      id,
      noteType,
      type,
      withRealTimeCollaboration,
      onInitializeChannelId,
    ) || {}

  const baseEditorCanRender = Boolean(
    isLayoutReady && Placeholders && businessId,
  )
  const editorCanRender = withRealTimeCollaboration
    ? Boolean(channelId && baseEditorCanRender)
    : baseEditorCanRender

  useImperativeHandle(innerRef, () => ({
    getEditorData: () => editorRef.current?.data.get(),
    // Be careful if ever using this, remember that we work with real-time collaboration
    // This won't work with track changes for example
    setEditorData: (data) =>
      data
        ? editorRef.current?.data?.set(data, {
            suppressErrorInCollaboration: true,
          })
        : undefined,
    editorId: id,
  }))

  // We must use this as a function
  function ImageCustomUploadAdapterPlugin(editor: CustomClassicEditorType) {
    // As we have 'editorCanRender', we can ensure that the Editor will called only when businessId is defined
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      new ImageUploadAdapter(loader, businessId!)
  }

  const hideComments = hideElements.includes(FeatureElements.COMMENTS)
  const hidePresenceList = hideElements.includes(FeatureElements.PRESENCE_LIST)
  const hideRevisionHistory = hideElements.includes(
    FeatureElements.REVISION_HISTORY,
  )

  const editorType = EditorTypeMap[type]
  const minHeight =
    type === EditorType.CLASSIC
      ? editorHeight + EDITOR_TOOLBAR_HEIGHT
      : editorHeight

  const Editor = (
    <Grid
      container
      item
      minHeight={minHeight}
      xs={!withRealTimeCollaboration || hideComments ? 12 : 8}
    >
      {(!isEditorReady || willEditorRestart || isFetchingSoapOnError) && (
        <PuiCKEditorSkeleton height={editorHeight} type={type} />
      )}
      {editorCanRender && (
        <CKEditor
          // @ts-ignore
          config={{
            ...(withRealTimeCollaboration
              ? {
                  cloudServices: {
                    tokenUrl,
                    webSocketUrl,
                    bundleVersion,
                  },
                  collaboration: {
                    channelId,
                  },
                  presenceList: {
                    container: presenceListElementRef.current,
                  },
                  sidebar: {
                    container: sidebarContainerRef.current,
                  },
                  revisionHistory: {
                    editorContainer: editorContainerRef.current,
                    viewerContainer: viewerContainerRef.current,
                    viewerEditorElement: viewerEditorElementRef.current,
                    viewerSidebarContainer: viewerSidebarContainerRef.current,
                  },
                }
              : {}),
            language:
              CKEditorLanguagesMap[
                currentUserLanguageCode as keyof typeof CKEditorLanguagesMap
              ],
            ...(toolbarProp ? { toolbar: toolbarProp } : {}),
            placeholderConfig: {
              types: Placeholders,
            },
            removePlugins: [
              ...(withRealTimeCollaboration
                ? []
                : RealTimeCollaborationPluginNames),
              ...removePluginsProp,
              // Revision history was removed temporary
              'RevisionHistory',
              'RealTimeCollaborativeRevisionHistory',
            ],
            extraPlugins: [ImageCustomUploadAdapterPlugin as any],
          }}
          disabled={disabled || isFetchingSoapOnError}
          editor={editorType}
          id={id}
          onChange={(_, editor) => {
            onChangeProp?.(editor.data.get())
            onDangerousChange?.(editor.data.get())
          }}
          onError={(error) => {
            setWillEditorRestart(true)
            onError(error)
          }}
          onReady={onEditorReady}
        />
      )}
    </Grid>
  )

  return (
    <>
      {withRealTimeCollaboration ? (
        <>
          <RevisionViewer
            hide={hideRevisionHistory}
            viewerContainerRef={viewerContainerRef}
            viewerEditorElementRef={viewerEditorElementRef}
            viewerSidebarContainerRef={viewerSidebarContainerRef}
          />

          <Grid
            container
            id="editor-container"
            minHeight={minHeight}
            position="relative"
            ref={editorContainerRef}
          >
            {Editor}
            <SidebarCommentsArea
              hide={hideComments}
              isEditorReady={isEditorReady}
              ref={sidebarContainerRef}
            />
          </Grid>
          <PresenceList hide={hidePresenceList} ref={presenceListElementRef} />
        </>
      ) : (
        <Grid
          container
          id="editor-container"
          minHeight={minHeight}
          position="relative"
        >
          {Editor}
        </Grid>
      )}
    </>
  )
}

const PuiCKEditorClassicWithErrorBoundary = (props: PuiCKEditorProps) => {
  const [hasError, setHasError] = useState(false)

  return (
    <ErrorBoundary
      // eslint-disable-next-line react/no-unstable-nested-components
      FallbackComponent={(fallbackProps: FallbackProps) => (
        <PuiCKEditorError {...fallbackProps} height={props.editorHeight} />
      )}
      resetKeys={[hasError]}
      onReset={() => {
        setHasError(false)
      }}
    >
      {hasError ? null : <PuiCKEditor {...props} />}
    </ErrorBoundary>
  )
}

const ForwardRefComponent = forwardRef(
  (props: PuiCKEditorProps, ref: React.ForwardedRef<PuiCKEditorHandle>) => (
    <PuiCKEditorClassicWithErrorBoundary {...props} innerRef={ref} />
  ),
)

export default React.memo(ForwardRefComponent, R.equals)
