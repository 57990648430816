import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import TableSelect, { TableSelectProps } from './TableSelect'

const useStyles = makeStyles(
  () => ({
    select: {
      width: 64,
    },
  }),
  { name: 'UnitsToggle' },
)

export interface UnitSelectProps extends TableSelectProps {}

const UnitSelect = (props: UnitSelectProps) => {
  const classes = useStyles()

  return (
    <TableSelect
      classes={{
        select: classes.select,
      }}
      keyProp="name"
      renderEmpty={false}
      {...props}
    />
  )
}

export default UnitSelect
