/* eslint-disable max-lines */
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Path } from 'ramda'
import {
  BasePuiDialogProps,
  BaseSignature,
  ButtonWithLoader,
  Calendar,
  CityTextField,
  CountrySelect,
  ErrorTooltip,
  Field,
  FieldProp,
  moment,
  Nil,
  Patient,
  PuiDialog,
  PuiSelect,
  PuiTextField,
  RegionUtils,
  SignatureOption,
  SignatureView,
  StateSelect,
  Text,
  useFields,
  User,
  ValidationType,
  ZipInput,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import BreedAutocomplete from '~/components/common/inputs/BreedAutocomplete'
import GenderField from '~/components/common/inputs/GenderField'
import PatientSizeSelect from '~/components/common/inputs/PatientSizeSelect'
import SpayedNeuteredField from '~/components/common/inputs/SpayedNeuteredField'
import SpeciesSelect from '~/components/common/inputs/SpeciesSelect'
import TeamMemberSelect from '~/components/common/inputs/TeamMemberSelect'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import RoleName from '~/constants/roleNames'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { updateClient } from '~/store/actions/clients'
import { partialEditOrder } from '~/store/actions/orders'
import { editPatient } from '~/store/actions/patients'
import {
  clearAssignedDoctorValidationError,
  selectDoctor,
} from '~/store/actions/soap'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useIsInventoryCategory } from '~/store/hooks/orders'
import { getCurrentBusiness, getCurrentUser } from '~/store/reducers/auth'
import { getClientIsLoading } from '~/store/reducers/clients'
import {
  getFeatureToggle,
  getInventoryManufacturers,
  getVaccineType,
} from '~/store/reducers/constants'
import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'
import {
  getAssignedDoctorValidationError,
  getDoctorId,
  getIsSaving,
  getSoapId,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { Order, OrderVaccinationDetails, ShipmentItem } from '~/types'
import { getManualInputSelectValue, getRequiredLabel } from '~/utils'
import { getIsRabiesVaccine } from '~/utils/orderUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import { useSignatureOptions } from '../../soapV2/utils/useSignatureOptions'
import { ShipmentItemSearchCriteria } from '../order/vaccine/ChooseShipmentItemDialog'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 820,
      maxWidth: 820,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    vaccineInfoSection: {
      backgroundColor: theme.colors.actionNeededBackground,
      maxWidth: '100%',
      width: '100%',
    },
    vaccineInfoBackground: {
      backgroundColor: theme.colors.actionNeededBackground,
      maxWidth: 'calc(100% + 32px)',
      width: 'calc(100% + 32px)',
      marginLeft: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
    },
    accordionDetails: {
      width: 772, // 820px for dialog - 48px for padding
    },
    accordionRoot: {
      '&:before': {
        content: 'none',
      },
    },
    fillFromInventoryButton: {
      backgroundColor: theme.colors.important,
      textTransform: 'none',
      boxShadow: 'none',
      color: '#FFFFFF',
      '&:hover, &:active': {
        backgroundColor: '#EF7605',
        boxShadow: 'none',
      },
    },
    warningText: {
      color: theme.colors.important,
    },
    errorTooltipText: {
      color: theme.colors.profileText,
      overflowWrap: 'break-word',
    },
    root: {
      width: '100%',
      margin: 0,
    },
    text: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    button: {
      height: 40,
      padding: theme.spacing(0, 6),
      marginRight: theme.spacing(2),
    },
    warningIcon: {
      color: theme.colors.alertWarning,
      fontSize: '2.6rem',
    },
    textCenter: {
      textAlign: 'center',
    },
  }),
  { name: 'BaseValidationDialog' },
)

const PATIENT_FIELDS = {
  PATIENT_DATE_OF_BIRTH: 'patient-date-of-birth',
  PATIENT_MICROCHIP_NUMBER: 'patient-microchip-number',
  PATIENT_SPECIES: 'patient-species',
  PATIENT_BREED: 'patient-breed',
  PATIENT_COLOR: 'patient-color',
  PATIENT_SIZE: 'patient-size',
  PATIENT_MARKINGS_TATTOO: 'patient-markings-tattoo',
  PATIENT_LICENSE: 'patient-license',
  PATIENT_RABIES_TAG: 'patient-rabies-tag',
  PATIENT_RABIES_TAG_EXPIRATION: 'patient-rabies-tag-expiration',
  PATIENT_GENDER: 'patient-gender',
  PATIENT_SPAYED_NEUTERED_STATUS: 'patient-spayed-neutered-status',
}

const CLIENT_FIELDS = {
  CLIENT_FIRST_NAME: 'client-first-name',
  CLIENT_LAST_NAME: 'client-last-name',
  CLIENT_ADDRESS: 'client-address',
  CLIENT_SUITE: 'client-suite',
  CLIENT_CITY: 'client-city',
  CLIENT_COUNTRY: 'client-country',
  CLIENT_STATE: 'client-state',
  CLIENT_ZIPCODE: 'client-zipcode',
}

const VACCINE_FIELDS = {
  VACCINE_TYPE: 'vaccine-type',
  VACCINE_EXPIRATION_DATE: 'vaccine-expiration-date',
  VACCINE_MANUFACTURER: 'vaccine-manufacturer',
  VACCINE_SERIAL_NUMBER: 'vaccine-serial-number',
  VACCINE_LOT_NUMBER: 'vaccine-lot-number',
  VACCINE_LOT_EXPIRATION: 'vaccine-lot-expiration',
  VACCINE_SIGNATURE: 'vaccine-signature',
  VACCINE_SIGNER: 'vaccine-signer',
}

export const FieldNames = {
  DOCTOR: 'doctor',
  ...PATIENT_FIELDS,
  ...CLIENT_FIELDS,
  ...VACCINE_FIELDS,
}

const getVaccineFieldId = (vaccineId: string, fieldName: string) =>
  `${fieldName}-${vaccineId}`

const createRequiredVaccineFields = (
  vaccine: Order,
  required: boolean,
): FieldProp[] => {
  const isRabies = getIsRabiesVaccine(vaccine)
  if (!isRabies) {
    return [
      {
        name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_EXPIRATION_DATE),
        label: i18n.t('Common:EXPIRATION_DATE'),
        initialValue: vaccine.vaccinationDetails?.dueDate,
      },
    ]
  }
  return [
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_TYPE),
      label: i18n.t('Common:VACCINE_TYPE'),
      type: 'select',
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.typeId,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_NUMBER),
      label: i18n.t('Common:LOT_NUMBER'),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.lotNumber,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_EXPIRATION),
      label: i18n.t('Common:LOT_EXPIRATION'),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.lotExpiration,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_EXPIRATION_DATE),
      label: i18n.t('Common:EXPIRATION_DATE'),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.dueDate,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_MANUFACTURER),
      label: i18n.t('Common:MANUFACTURER'),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.manufacturedBy,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SERIAL_NUMBER),
      label: i18n.t('Common:SERIAL_NUMBER'),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.serialNumber,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SIGNATURE),
      label: i18n.t('Common:SIGNATURE_VIEW.LABEL'),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.signature,
    },
    {
      name: getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SIGNER),
      validators: required ? ['required'] : [],
      initialValue: vaccine.vaccinationDetails?.signerId,
    },
  ]
}

const getFieldFromList = (
  fieldName: string | Nil,
  fieldsList: SoapValidationField[],
) => fieldsList.find((field) => field.name === fieldName)

const getRequiredValidator = (
  fieldName: string,
  requiredFields: Record<string, any> | Nil,
): ValidationType[] => (requiredFields?.has(fieldName) ? ['required'] : [])

const isFilled = (value: any) => !R.isNil(value) && !R.isEmpty(value)
const pathDoesNotExist = (path: Path) =>
  R.pathSatisfies(R.complement(isFilled), path)
const isFieldFilled = (field: Field) =>
  field && isFilled(field.value) && field.value !== field.initialValue

const FIELDS_VALUE_MISSING_MAP: Record<
  string,
  (data: ValidationData) => boolean
> = {
  [FieldNames.DOCTOR]: (data) =>
    Boolean(data.soapId) && pathDoesNotExist(['doctorId'])(data),
  [FieldNames.PATIENT_DATE_OF_BIRTH]: pathDoesNotExist([
    'patient',
    'dateOfBirth',
  ]),
  [FieldNames.PATIENT_MICROCHIP_NUMBER]: pathDoesNotExist([
    'patient',
    'microchipNumber',
  ]),
  [FieldNames.PATIENT_SPECIES]: pathDoesNotExist(['patient', 'species']),
  [FieldNames.PATIENT_BREED]: ({ patient }) =>
    (patient?.breeds?.length ?? 0) < 1,
  [FieldNames.PATIENT_COLOR]: pathDoesNotExist(['patient', 'color']),
  [FieldNames.PATIENT_SIZE]: pathDoesNotExist(['patient', 'size']),
  [FieldNames.PATIENT_MARKINGS_TATTOO]: pathDoesNotExist([
    'patient',
    'markingsTattoo',
  ]),
  [FieldNames.PATIENT_LICENSE]: pathDoesNotExist(['patient', 'license']),
  [FieldNames.PATIENT_RABIES_TAG]: pathDoesNotExist(['patient', 'rabiesTag']),
  [FieldNames.PATIENT_RABIES_TAG_EXPIRATION]: pathDoesNotExist([
    'patient',
    'rabiesTagExpiration',
  ]),
  [FieldNames.PATIENT_GENDER]: pathDoesNotExist(['patient', 'gender']),
  [FieldNames.PATIENT_SPAYED_NEUTERED_STATUS]: pathDoesNotExist([
    'patient',
    'spayedNeutered',
  ]),
  [FieldNames.CLIENT_FIRST_NAME]: pathDoesNotExist(['client', 'firstName']),
  [FieldNames.CLIENT_LAST_NAME]: pathDoesNotExist(['client', 'lastName']),
  [FieldNames.CLIENT_ADDRESS]: pathDoesNotExist(['client', 'address']),
  [FieldNames.CLIENT_SUITE]: pathDoesNotExist(['client', 'suite']),
  [FieldNames.CLIENT_CITY]: pathDoesNotExist(['client', 'city']),
  [FieldNames.CLIENT_COUNTRY]: pathDoesNotExist(['client', 'country']),
  [FieldNames.CLIENT_STATE]: pathDoesNotExist(['client', 'state']),
  [FieldNames.CLIENT_ZIPCODE]: pathDoesNotExist(['client', 'zip']),
}

const getFields = (
  requiredFields: Set<string | undefined>,
  client: User | Nil,
  patient: Patient | Nil,
  vaccineFields: FieldProp[],
  defaultValues: Record<string, any>,
): FieldProp[] => [
  {
    name: 'doctor',
    label: i18n.t('Common:ASSIGNED_DOCTOR'),
    type: 'select',
    validators: getRequiredValidator(FieldNames.DOCTOR, requiredFields),
    initialValue: defaultValues[FieldNames.DOCTOR],
  },
  {
    name: 'patientDateOfBirth',
    label: i18n.t('Common:DATE_OF_BIRTH'),
    validators: getRequiredValidator(
      FieldNames.PATIENT_DATE_OF_BIRTH,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.PATIENT_DATE_OF_BIRTH] ?? patient?.dateOfBirth,
  },
  {
    name: 'patientMicrochipNumber',
    label: i18n.t('Common:MICROCHIP_NUMBER_SIGN'),
    validators: getRequiredValidator(
      FieldNames.PATIENT_MICROCHIP_NUMBER,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.PATIENT_MICROCHIP_NUMBER] ??
      patient?.microchipNumber,
  },
  {
    name: 'patientSpecies',
    label: i18n.t('Common:SPECIES'),
    type: 'select',
    validators: getRequiredValidator(
      FieldNames.PATIENT_SPECIES,
      requiredFields,
    ),
    initialValue: defaultValues[FieldNames.PATIENT_SPECIES] ?? patient?.species,
  },
  {
    name: 'patientBreed',
    label: i18n.t('Common:BREED'),
    type: 'select',
    validators: getRequiredValidator(FieldNames.PATIENT_BREED, requiredFields),
    initialValue:
      defaultValues[FieldNames.PATIENT_BREED] || patient?.breeds || [],
  },
  {
    name: 'patientColor',
    label: i18n.t('Common:COLOR'),
    validators: getRequiredValidator(FieldNames.PATIENT_COLOR, requiredFields),
    initialValue: defaultValues[FieldNames.PATIENT_COLOR] ?? patient?.color,
  },
  {
    name: 'patientSize',
    label: i18n.t('Common:SIZE'),
    type: 'select',
    validators: getRequiredValidator(FieldNames.PATIENT_SIZE, requiredFields),
    initialValue: defaultValues[FieldNames.PATIENT_SIZE] ?? patient?.size,
  },
  {
    name: 'patientMarkingsTattoo',
    label: i18n.t('Common:MARKINGS_OR_TATTOO'),
    validators: getRequiredValidator(
      FieldNames.PATIENT_MARKINGS_TATTOO,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.PATIENT_MARKINGS_TATTOO] ??
      patient?.markingsTattoo,
  },
  {
    name: 'patientLicense',
    label: i18n.t('Common:LICENSE_NUMBER'),
    validators: getRequiredValidator(
      FieldNames.PATIENT_LICENSE,
      requiredFields,
    ),
    initialValue: defaultValues[FieldNames.PATIENT_LICENSE] ?? patient?.license,
  },
  {
    name: 'patientRabiesTag',
    label: i18n.t('Common:RABIES_TAG'),
    validators: getRequiredValidator(
      FieldNames.PATIENT_RABIES_TAG,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.PATIENT_RABIES_TAG] ?? patient?.rabiesTag,
  },
  {
    name: 'patientRabiesTagExpiration',
    label: i18n.t('Common:RABIES_TAG_EXPIRATION'),
    validators: [
      ...getRequiredValidator(
        FieldNames.PATIENT_RABIES_TAG_EXPIRATION,
        requiredFields,
      ),
      'timestamp',
    ],
    initialValue:
      defaultValues[FieldNames.PATIENT_RABIES_TAG_EXPIRATION] ??
      patient?.rabiesTagExpiration,
  },
  {
    name: 'patientGender',
    label: i18n.t('Common:GENDER'),
    validators: getRequiredValidator(FieldNames.PATIENT_GENDER, requiredFields),
    initialValue: defaultValues[FieldNames.PATIENT_GENDER] ?? patient?.gender,
  },
  {
    name: 'patientSpayedNeutered',
    label: `${i18n.t('Common:SPAYED')}/${i18n.t('Common:NEUTERED')}`,
    validators: getRequiredValidator(
      FieldNames.PATIENT_SPAYED_NEUTERED_STATUS,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.PATIENT_SPAYED_NEUTERED_STATUS] ??
      patient?.spayedNeutered,
  },
  {
    name: 'clientFirstName',
    label: i18n.t('Common:CLIENT_FIRST_NAME'),
    validators: getRequiredValidator(
      FieldNames.CLIENT_FIRST_NAME,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.CLIENT_FIRST_NAME] ?? client?.firstName,
  },
  {
    name: 'clientLastName',
    label: i18n.t('Common:CLIENT_LAST_NAME'),
    validators: getRequiredValidator(
      FieldNames.CLIENT_LAST_NAME,
      requiredFields,
    ),
    initialValue:
      defaultValues[FieldNames.CLIENT_LAST_NAME] ?? client?.lastName,
  },
  {
    name: 'clientAddress',
    label: i18n.t('Common:STREET_ADDRESS'),
    validators: getRequiredValidator(FieldNames.CLIENT_ADDRESS, requiredFields),
    initialValue: defaultValues[FieldNames.CLIENT_ADDRESS] ?? client?.address,
  },
  {
    name: 'clientSuite',
    label: i18n.t('Common:APARTMENT_NUMBER_SIGN'),
    validators: getRequiredValidator(FieldNames.CLIENT_SUITE, requiredFields),
    initialValue: defaultValues[FieldNames.CLIENT_SUITE] ?? client?.suite,
  },
  {
    name: 'clientCity',
    label: i18n.t('Common:CITY'),
    validators: getRequiredValidator(FieldNames.CLIENT_CITY, requiredFields),
    initialValue: defaultValues[FieldNames.CLIENT_CITY] ?? client?.city,
  },
  {
    name: 'clientCountry',
    label: i18n.t('Common:COUNTRY'),
    validators: getRequiredValidator(FieldNames.CLIENT_COUNTRY, requiredFields),
    initialValue:
      defaultValues[FieldNames.CLIENT_COUNTRY] ??
      RegionUtils.getAvailableCountry(client?.country),
  },
  {
    name: 'clientState',
    label: i18n.t('Common:STATE'),
    type: 'select',
    validators: getRequiredValidator(FieldNames.CLIENT_STATE, requiredFields),
    initialValue: defaultValues[FieldNames.CLIENT_STATE] ?? client?.state,
  },
  {
    name: 'clientZipcode',
    label: i18n.t('Common:ZIP_CODE'),
    type: 'select',
    validators: [
      ...getRequiredValidator(FieldNames.CLIENT_ZIPCODE, requiredFields),
      'zip',
    ],
    initialValue: defaultValues[FieldNames.CLIENT_ZIPCODE] ?? client?.zip,
  },
  ...vaccineFields,
]

export type SoapValidationField = {
  name: string
  required: boolean
}

type ValidationData = {
  client: User | Nil
  doctorId: string
  patient: Patient | Nil
  soapId: string | Nil
}

export interface BaseValidationDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  defaultValues?: Record<string, any>
  disableVaccineUpdate?: boolean
  fieldsList?: (SoapValidationField | string)[]
  onOk?: (vaccinationDetails?: OrderVaccinationDetails) => void
  patientId: string | Nil
  requireRabiesUpdates?: boolean
  showSkipButton?: boolean
  title: string
  vaccines?: Order[]
}

const BaseValidationDialog = ({
  showSkipButton,
  fieldsList: fieldsListProp,
  title,
  disableVaccineUpdate = false,
  defaultValues = {} as Record<string, any>,
  open,
  clientId,
  patientId,
  onClose,
  onOk,
  vaccines: vaccinesProp,
  requireRabiesUpdates = false,
}: BaseValidationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const baseValidationDialogTitle =
    title || t('Dialogs:BASE_VALIDATION_DIALOG.TITLE')

  const isLoadingPatients = useSelector(getPatientsIsLoading)
  const isLoadingClients = useSelector(getClientIsLoading)
  const isSavingSoap = useSelector(getIsSaving)
  const doctorId = useSelector(getDoctorId)
  const assignedDoctorValidationError = useSelector(
    getAssignedDoctorValidationError,
  )
  const soapId = useSelector(getSoapId)

  const currentBusiness = useSelector(getCurrentBusiness)
  const VaccineType = useSelector(getVaccineType)
  const patient = useSelector(getPatient(patientId))
  const client = useSelector(getUser(clientId))
  const InventoryManufacturers = useSelector(getInventoryManufacturers)
  const currentUser = useSelector(getCurrentUser)
  const isVaccine = useIsInventoryCategory(InventoryCategoryName.VACCINES)
  const vaccines = vaccinesProp?.filter(
    (order) => isVaccine(order) || getIsRabiesVaccine(order),
  )

  const {
    signatureOptions,
    canEdit: canEditSignature,
    canSelect: canSelectSignature,
  } = useSignatureOptions()

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const [openSignatureDialog] = useDialog(DialogNames.DOCTOR_SIGNATURE_DIALOG)
  const [openValidationErrorDialog] = useDialog(DialogNames.VALIDATION_ERROR)
  const [openShipmentItemDialog] = useDialog(
    DialogNames.CHOOSE_SHIPMENT_ITEM_DIALOG,
  )
  const isLoadingSomething =
    isSavingSoap || isLoadingPatients || isLoadingClients

  const data: ValidationData = {
    soapId,
    doctorId,
    client,
    patient,
  }

  const fieldsList = (fieldsListProp || []).map((item) =>
    typeof item === 'string'
      ? {
          name: item,
          required: false,
        }
      : item,
  )

  const vaccineMissingFields: FieldProp[] = []

  if (vaccines) {
    vaccines.forEach((vaccine) => {
      vaccineMissingFields.push(
        ...createRequiredVaccineFields(vaccine, requireRabiesUpdates).filter(
          (field) => !field.initialValue,
        ),
      )
    })
  }

  const missingFields = fieldsList
    .map((field) => {
      const isFieldMissingByData = FIELDS_VALUE_MISSING_MAP[field.name](data)
      const isFieldFilledOutside = isFilled(defaultValues[field.name])
      return isFieldMissingByData && !isFieldFilledOutside
        ? field.name
        : undefined
    })
    .filter(Boolean)

  const requiredFields = missingFields.filter(
    (fieldName) => getFieldFromList(fieldName, fieldsList)?.required,
  )

  // capture initial state when dialog opened
  const missingFieldsSet = useMemo(() => new Set(missingFields), [])
  const missingVaccineFieldsSet = useMemo(
    () => new Set(vaccineMissingFields),
    [],
  )
  const requiredFieldsSet = useMemo(() => new Set(requiredFields), [])
  const missingFieldsExist = useMemo(
    () =>
      missingFieldsSet.size > 0 ||
      (Boolean(currentBusiness?.autoGenerateVaccineCertificates) &&
        missingVaccineFieldsSet.size > 0),
    [],
  )

  const isFieldRequired = (fieldName: string) =>
    requiredFieldsSet.has(fieldName)
  const isFieldMissing = (fieldName: string) => missingFieldsSet.has(fieldName)
  const isFieldEmpty = (field: Field) =>
    !field.value || field.value !== field.initialValue

  const { fields, validate, reset } = useFields(
    getFields(
      requiredFieldsSet,
      client,
      patient,
      vaccineMissingFields,
      defaultValues,
    ),
    false,
  )

  const {
    doctor,
    patientDateOfBirth,
    patientMicrochipNumber,
    patientSpecies,
    patientBreed,
    patientColor,
    patientSize,
    patientMarkingsTattoo,
    patientLicense,
    patientRabiesTag,
    patientRabiesTagExpiration,
    patientGender,
    patientSpayedNeutered,
    clientFirstName,
    clientLastName,
    clientAddress,
    clientSuite,
    clientCity,
    clientCountry,
    clientState,
    clientZipcode,
    ...vaccineFields
  } = fields

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    reset()
  }

  const handleValid = (vaccineUpdates?: OrderVaccinationDetails) => {
    if (onOk) {
      onOk(vaccineUpdates)
    }
    handleClose()
  }

  const handleVaccineChosenFromInventory =
    (vaccine: Order) =>
    ({
      inventoryVariation,
      manufacturerId,
      manufacturerName,
      serialNumber,
      lotNumber,
      expirationDate,
      dueDate,
    }: ShipmentItem) => {
      const { vaccineTypeId } = inventoryVariation || {}
      const manufacturedBy = getManualInputSelectValue(
        InventoryManufacturers,
        manufacturerId,
        manufacturerName,
      )

      vaccineFields[
        getVaccineFieldId(vaccine.id, FieldNames.VACCINE_MANUFACTURER)
      ].setValue(manufacturedBy)
      vaccineFields[
        getVaccineFieldId(vaccine.id, FieldNames.VACCINE_TYPE)
      ].setValue(vaccineTypeId)
      vaccineFields[
        getVaccineFieldId(vaccine.id, FieldNames.VACCINE_EXPIRATION_DATE)
      ].setValue(dueDate)
      vaccineFields[
        getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SERIAL_NUMBER)
      ].setValue(serialNumber)
      vaccineFields[
        getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_NUMBER)
      ].setValue(lotNumber)
      vaccineFields[
        getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_EXPIRATION)
      ].setValue(expirationDate)
    }

  const handleFillFromInventory = (vaccine: Order) => () => {
    openShipmentItemDialog({
      order: vaccine,
      searchCriteria: ShipmentItemSearchCriteria.PROCEDURE_WITH_VARIATION,
      onSelect: handleVaccineChosenFromInventory(vaccine),
      showToggle: true,
    })
  }

  const closeOnUpdate = useCloseAfterCreation(
    (vaccineUpdates: OrderVaccinationDetails) => {
      if (assignedDoctorValidationError) {
        openValidationErrorDialog({
          error: assignedDoctorValidationError,
          onBack: () => dispatch(clearAssignedDoctorValidationError()),
          onProceed: () => {
            dispatch(clearAssignedDoctorValidationError())
            dispatch(selectDoctor(fields?.doctor?.value, true))
            closeOnUpdate()
          },
        })
      } else {
        handleValid(vaccineUpdates)
      }
    },
    (state) =>
      getPatientsIsLoading(state) ||
      getClientIsLoading(state) ||
      getIsSaving(state),
  )

  useLayoutEffect(() => {
    if (!missingFieldsExist || !clientId || !patientId) {
      handleValid()
    }
  }, [missingFieldsExist, clientId, patientId])

  const handleSave = () => {
    if (validate()) {
      if (fields?.doctor?.value) {
        dispatch(selectDoctor(fields.doctor.value, false))
      }

      let vaccineUpdates

      vaccines?.forEach((vaccine) => {
        vaccineUpdates = R.pluck<any, string>(
          'value',
          R.pickBy(isFieldFilled, {
            dueDate:
              fields[
                getVaccineFieldId(
                  vaccine.id,
                  FieldNames.VACCINE_EXPIRATION_DATE,
                )
              ],
            lotExpiration:
              fields[
                getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_EXPIRATION)
              ],
            serialNumber:
              fields[
                getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SERIAL_NUMBER)
              ],
            manufacturedBy:
              fields[
                getVaccineFieldId(vaccine.id, FieldNames.VACCINE_MANUFACTURER)
              ],
            lotNumber:
              fields[
                getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_NUMBER)
              ],
            typeId:
              fields[getVaccineFieldId(vaccine.id, FieldNames.VACCINE_TYPE)],
            signature:
              fields[
                getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SIGNATURE)
              ],
            signerId:
              fields[getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SIGNER)],
          }),
        ) as Partial<OrderVaccinationDetails>

        if (Object.keys(vaccineUpdates).length > 0 && !disableVaccineUpdate) {
          const minDifference = {
            id: vaccine.id,
            stateId: vaccine.stateId,
            vaccinationDetails: {
              ...vaccine?.vaccinationDetails,
              ...vaccineUpdates,
            },
          }
          const action =
            isChargeSheetEnabled || !vaccine.soapId
              ? () =>
                  editChargeSheetOrder({
                    id: vaccine.logId || vaccine.id,
                    type: OrderType.PROCEDURE,
                    order: minDifference,
                    soapLogModificationDate: vaccine.modificationDate || '',
                  })
              : () =>
                  partialEditOrder(minDifference, {
                    soapId: vaccine.soapId || undefined,
                  })
          dispatch(action())
        }
      })

      const patientUpdates = R.pluck<any, string>(
        'value',
        R.pickBy(isFieldFilled, {
          dateOfBirth: fields.patientDateOfBirth,
          species: fields.patientSpecies,
          color: fields.patientColor,
          size: fields.patientSize,
          breeds: fields.patientBreed,
          gender: fields.patientGender,
          spayedNeutered: fields.patientSpayedNeutered,
          microchipNumber: fields.patientMicrochipNumber,
          markingsTattoo: fields.patientMarkingsTattoo,
          license: fields.patientLicense,
          rabiesTag: fields.patientRabiesTag,
          rabiesTagExpiration: fields.patientRabiesTagExpiration,
        }),
      ) as Partial<Patient>

      const clientUpdates = R.pluck<any, string>(
        'value',
        R.pickBy(isFieldFilled, {
          firstName: fields.clientFirstName,
          lastName: fields.clientLastName,
          address: fields.clientAddress,
          suite: fields.clientSuite,
          city: fields.clientCity,
          country: fields.clientCountry,
          state: fields.clientState,
          zip: fields.clientZipcode,
        }),
      ) as Partial<User>

      if (Object.keys(patientUpdates).length > 0) {
        if (patient && clientId) {
          dispatch(
            editPatient(clientId, {
              ...patient,
              ...patientUpdates,
            }),
          )
        }
      }

      if (Object.keys(clientUpdates).length > 0 && clientId) {
        dispatch(
          updateClient({
            id: clientId,
            ...clientUpdates,
          }),
        )
      }

      // If there is only one vaccine given to the validation dialog, then
      // we pass the changes back to the original vaccine dialog to
      // consolidate the changes and make the update. Otherwise,
      // we already handled the updates above
      closeOnUpdate(vaccines?.length === 1 ? vaccineUpdates : {})
    }
  }

  const onSetCountry = (event: Field['set']) => {
    clientZipcode.setValue('')
    clientState.setValue('')
    clientCountry.set(event)
  }

  const missingPatientFieldsCount =
    R.values(PATIENT_FIELDS).filter(isFieldMissing).length
  const missingClientFieldsCount =
    R.values(CLIENT_FIELDS).filter(isFieldMissing).length

  const hasMissingPatientFields = missingPatientFieldsCount > 0
  const hasMissingClientFields = missingClientFieldsCount > 0
  const missingVaccineFieldsCount =
    R.values(vaccineFields).filter(isFieldEmpty).length
  const hasMissingVaccineFields =
    currentBusiness?.autoGenerateVaccineCertificates &&
    missingVaccineFieldsCount > 0

  const [patientAccordionExpanded, setPatientAccordionExpanded] = useState(
    !hasMissingVaccineFields,
  )
  const [clientAccordionExpanded, setClientAccordionExpanded] = useState(
    !hasMissingVaccineFields,
  )

  const renderVaccineSection = (
    vaccineSectionFields: Record<string, Field>,
    vaccine: Order,
  ) => {
    const {
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_TYPE)]: vaccineType,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_MANUFACTURER)]:
        vaccineManufacturer,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SERIAL_NUMBER)]:
        vaccineSerialNumber,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_NUMBER)]:
        vaccineLotNumber,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_LOT_EXPIRATION)]:
        vaccineLotExpiration,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_EXPIRATION_DATE)]:
        vaccineExpirationDate,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SIGNATURE)]:
        vaccineSignature,
      [getVaccineFieldId(vaccine.id, FieldNames.VACCINE_SIGNER)]: vaccineSigner,
    } = vaccineSectionFields

    const hasMissingFields = R.any(Boolean)([
      vaccineType,
      vaccineManufacturer,
      vaccineSerialNumber,
      vaccineLotNumber,
      vaccineLotExpiration,
      vaccineExpirationDate,
    ])

    const hasMissingInfo = hasMissingFields || Boolean(vaccineSignature)

    const handleSelectSignature = (signer: SignatureOption) => {
      vaccineSignature.setValue(signer.signature)
      vaccineSigner.setValue(signer.id)
    }

    const handleEditSignature = () => {
      openSignatureDialog({
        SignatureComponent: BaseSignature,
        outputFormat: 'png',
        initialSignerValue: currentUser.id,
        onSign: (signerId: string, signature: string) => {
          vaccineSignature.setValue(signature)
          vaccineSigner.setValue(signerId)
        },
      })
    }

    return hasMissingInfo ? (
      <Grid
        container
        item
        alignItems="center"
        className={classes.vaccineInfoSection}
        mb={2}
        pr={2}
        spacing={2}
      >
        <Grid item xs={12}>
          <Text strong variant="subheading3">
            {vaccine.nameTranslation}
          </Text>
        </Grid>
        {getIsRabiesVaccine(vaccine) && hasMissingFields && (
          <Grid item xs={12}>
            <Fab
              disableRipple
              className={classes.fillFromInventoryButton}
              variant="extended"
              onClick={handleFillFromInventory(vaccine)}
            >
              {t('Common:FILL_FROM_INVENTORY')}
            </Fab>
          </Grid>
        )}
        {vaccineType && (
          <Grid item sm={4} xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="vaccine-type-select">
                {vaccineType.label}
              </InputLabel>
              <PuiSelect
                fullWidth
                field={vaccineType}
                input={<Input id="vaccine-type-select" />}
                items={VaccineType}
              />
            </FormControl>
          </Grid>
        )}
        {vaccineManufacturer && (
          <Grid item sm={4} xs={6}>
            <PuiTextField
              field={vaccineManufacturer}
              inputProps={{ maxLength: 100 }}
              label={vaccineManufacturer.label}
            />
          </Grid>
        )}
        {vaccineSerialNumber && (
          <Grid item sm={4} xs={6}>
            <PuiTextField
              field={vaccineSerialNumber}
              inputProps={{ maxLength: 100 }}
              label={vaccineSerialNumber.label}
            />
          </Grid>
        )}
        {vaccineExpirationDate && (
          <Grid item sm={4} xs={6}>
            <Calendar
              fullWidth
              field={vaccineExpirationDate}
              label={vaccineExpirationDate.label}
            />
          </Grid>
        )}
        {vaccineLotNumber && (
          <Grid item sm={4} xs={6}>
            <PuiTextField
              field={vaccineLotNumber}
              inputProps={{ maxLength: 100 }}
              label={vaccineLotNumber.label}
            />
          </Grid>
        )}
        {vaccineLotExpiration && (
          <Grid item sm={4} xs={6}>
            <Calendar
              fullWidth
              field={vaccineLotExpiration}
              label={vaccineLotExpiration.label}
            />
          </Grid>
        )}
        {vaccineSignature && (canEditSignature || canSelectSignature) && (
          <ErrorTooltip
            disablePortal
            open={
              vaccineSignature.errors.length > 0 &&
              vaccineSignature.open &&
              !vaccineSignature.valid
            }
            title={
              <Text
                align="center"
                className={classes.errorTooltipText}
                mx={1.5}
                variant="body"
              >
                {vaccineSignature.message}
              </Text>
            }
            validateTag={vaccineSignature.validateTag}
          >
            <Grid item sm={5} xs={6}>
              <SignatureView
                signatureOptions={signatureOptions}
                signatureString={vaccineSignature.value}
                onEdit={canEditSignature ? handleEditSignature : undefined}
                onSelect={
                  canSelectSignature ? handleSelectSignature : undefined
                }
              />
            </Grid>
          </ErrorTooltip>
        )}
      </Grid>
    ) : (
      <></>
    )
  }

  return (
    <PuiDialog
      aria-labelledby="base-validation-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open && missingFieldsExist}
      onClose={handleClose}
    >
      <Grid
        container
        className={classes.root}
        direction="column"
        p={2}
        spacing={2}
      >
        <Grid item className={classes.textCenter}>
          <WarningIcon className={classes.warningIcon} />
        </Grid>
        <Grid item ml={-2} pr={2}>
          <Text align="center" variant="body">
            {baseValidationDialogTitle}
          </Text>
        </Grid>
        {hasMissingVaccineFields && (
          <Grid className={classes.vaccineInfoBackground}>
            <Grid item mb={1} mt={2} xs={12}>
              <Text
                strong
                className={classes.warningText}
                variant="subheading3"
              >
                {t('Common:MISSING_VACCINE_INFORMATION')}
              </Text>
            </Grid>
            {vaccines?.map((vaccine) =>
              renderVaccineSection(
                R.pick(
                  Object.keys(vaccineFields).filter((fieldName) =>
                    fieldName.includes(vaccine.id),
                  ),
                  vaccineFields,
                ),
                vaccine,
              ),
            )}
          </Grid>
        )}
        <Grid container item alignItems="center" spacing={2}>
          {isFieldMissing(FieldNames.DOCTOR) && (
            <Grid container item>
              <Grid item sm={6} xs={12}>
                <TeamMemberSelect
                  field={doctor}
                  label={getRequiredLabel(
                    doctor.label,
                    isFieldRequired(FieldNames.DOCTOR),
                  )}
                  renderEmpty={false}
                  roles={[RoleName.Veterinarian]}
                />
              </Grid>
            </Grid>
          )}
          {hasMissingPatientFields && (
            <Accordion
              elevation={0}
              expanded={patientAccordionExpanded}
              onChange={(event, isExpanded) =>
                setPatientAccordionExpanded(isExpanded)
              }
            >
              <AccordionSummary>
                {patientAccordionExpanded ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
                <Text strong lineHeight="24px" variant="subheading3">
                  {t('Common:MISSING_PATIENT_INFORMATION')}
                </Text>
                <Text paddingLeft={1}>
                  {t('Common:MISSING_FIELDS', {
                    missingFieldCount: missingPatientFieldsCount,
                  })}
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.accordionDetails}
                  spacing={2}
                >
                  {isFieldMissing(FieldNames.PATIENT_DATE_OF_BIRTH) && (
                    <Grid item sm={6} xs={12}>
                      <Calendar
                        fullWidth
                        field={patientDateOfBirth}
                        label={getRequiredLabel(
                          patientDateOfBirth.label,
                          isFieldRequired(FieldNames.PATIENT_DATE_OF_BIRTH),
                        )}
                        maxDate={moment()}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_MICROCHIP_NUMBER) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={patientMicrochipNumber}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          patientMicrochipNumber.label,
                          isFieldRequired(FieldNames.PATIENT_MICROCHIP_NUMBER),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_SPECIES) && (
                    <Grid item sm={6} xs={12}>
                      <SpeciesSelect
                        field={patientSpecies}
                        required={isFieldRequired(FieldNames.PATIENT_SPECIES)}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_BREED) && (
                    <Grid item sm={6} xs={12}>
                      <BreedAutocomplete
                        field={patientBreed}
                        required={isFieldRequired(FieldNames.PATIENT_BREED)}
                        species={patientSpecies?.value}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_COLOR) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={patientColor}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          patientColor.label,
                          isFieldRequired(FieldNames.PATIENT_COLOR),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_SIZE) && (
                    <Grid item sm={6} xs={12}>
                      <PatientSizeSelect
                        field={patientSize}
                        required={isFieldRequired(FieldNames.PATIENT_SIZE)}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_MARKINGS_TATTOO) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={patientMarkingsTattoo}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          patientMarkingsTattoo.label,
                          isFieldRequired(FieldNames.PATIENT_MARKINGS_TATTOO),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_LICENSE) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={patientLicense}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          patientLicense.label,
                          isFieldRequired(FieldNames.PATIENT_LICENSE),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_RABIES_TAG) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={patientRabiesTag}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          patientRabiesTag.label,
                          isFieldRequired(FieldNames.PATIENT_RABIES_TAG),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.PATIENT_RABIES_TAG_EXPIRATION) && (
                    <Grid item sm={6} xs={12}>
                      <Calendar
                        fullWidth
                        field={patientRabiesTagExpiration}
                        label={getRequiredLabel(
                          patientRabiesTagExpiration.label,
                          isFieldRequired(
                            FieldNames.PATIENT_RABIES_TAG_EXPIRATION,
                          ),
                        )}
                      />
                    </Grid>
                  )}
                  {(isFieldMissing(FieldNames.PATIENT_GENDER) ||
                    isFieldMissing(
                      FieldNames.PATIENT_SPAYED_NEUTERED_STATUS,
                    )) && (
                    <Grid
                      container
                      item
                      alignItems="center"
                      spacing={2}
                      wrap="nowrap"
                      xs={12}
                    >
                      {isFieldMissing(FieldNames.PATIENT_GENDER) && (
                        <Grid item sm={6} xs={12}>
                          <GenderField
                            field={patientGender}
                            required={isFieldRequired(
                              FieldNames.PATIENT_GENDER,
                            )}
                          />
                        </Grid>
                      )}
                      {isFieldMissing(
                        FieldNames.PATIENT_SPAYED_NEUTERED_STATUS,
                      ) && (
                        <Grid item sm={6} xs={12}>
                          <SpayedNeuteredField
                            field={patientSpayedNeutered}
                            gender={patientGender?.value}
                            required={isFieldRequired(
                              FieldNames.PATIENT_SPAYED_NEUTERED_STATUS,
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          {hasMissingClientFields && (
            <Accordion
              classes={{
                root: classes.accordionRoot,
              }}
              elevation={0}
              expanded={clientAccordionExpanded}
              onChange={(event, isExpanded) =>
                setClientAccordionExpanded(isExpanded)
              }
            >
              <AccordionSummary>
                {clientAccordionExpanded ? (
                  <KeyboardArrowDown />
                ) : (
                  <KeyboardArrowRight />
                )}
                <Text strong lineHeight="24px" variant="subheading3">
                  {t('Common:MISSING_CLIENT_INFORMATION')}
                </Text>
                <Text paddingLeft={1}>
                  {t('Common:MISSING_FIELDS', {
                    missingFieldCount: missingClientFieldsCount,
                  })}
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.accordionDetails}
                  spacing={2}
                >
                  {isFieldMissing(FieldNames.CLIENT_FIRST_NAME) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={clientFirstName}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          clientFirstName.label,
                          isFieldRequired(FieldNames.CLIENT_FIRST_NAME),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.CLIENT_LAST_NAME) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={clientLastName}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          clientLastName.label,
                          isFieldRequired(FieldNames.CLIENT_LAST_NAME),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.CLIENT_ADDRESS) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={clientAddress}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          clientAddress.label,
                          isFieldRequired(FieldNames.CLIENT_ADDRESS),
                        )}
                      />
                    </Grid>
                  )}
                  {isFieldMissing(FieldNames.CLIENT_SUITE) && (
                    <Grid item sm={6} xs={12}>
                      <PuiTextField
                        field={clientSuite}
                        inputProps={{ maxLength: 100 }}
                        label={getRequiredLabel(
                          clientSuite.label,
                          isFieldRequired(FieldNames.CLIENT_SUITE),
                        )}
                      />
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    alignItems="center"
                    spacing={2}
                    wrap="nowrap"
                  >
                    {isFieldMissing(FieldNames.CLIENT_CITY) && (
                      <Grid item sm={3} xs={12}>
                        <CityTextField
                          country={clientCountry?.value}
                          field={clientCity}
                          required={isFieldRequired(FieldNames.CLIENT_CITY)}
                        />
                      </Grid>
                    )}
                    {isFieldMissing(FieldNames.CLIENT_COUNTRY) && (
                      <Grid item sm={3} xs={12}>
                        <CountrySelect
                          field={{ ...clientCountry, set: onSetCountry }}
                          required={isFieldRequired(FieldNames.CLIENT_COUNTRY)}
                        />
                      </Grid>
                    )}
                    {isFieldMissing(FieldNames.CLIENT_STATE) && (
                      <Grid item sm={3} xs={12}>
                        <StateSelect
                          country={clientCountry?.value}
                          field={clientState}
                          required={isFieldRequired(FieldNames.CLIENT_STATE)}
                        />
                      </Grid>
                    )}
                    {isFieldMissing(FieldNames.CLIENT_ZIPCODE) && (
                      <Grid item sm={3} xs={12}>
                        <PuiTextField
                          InputProps={{
                            inputComponent: ZipInput,
                            inputProps: { country: clientCountry?.value },
                          }}
                          field={clientZipcode}
                          label={getRequiredLabel(
                            clientZipcode.label,
                            isFieldRequired(FieldNames.CLIENT_ZIPCODE),
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoadingSomething}
            loading={isLoadingSomething}
            spinnerProps={{
              color: 'secondary',
            }}
            onClick={handleSave}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
          {showSkipButton && (
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              onClick={() => handleValid()}
            >
              {t('Common:SKIP_FOR_NOW')}
            </ButtonWithLoader>
          )}
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default BaseValidationDialog
