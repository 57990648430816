import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults } from '@pbt/pbt-ui-components'

import { Bundle } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CREATE_BUNDLE,
  CREATE_BUNDLE_FAILURE,
  CREATE_BUNDLE_SUCCESS,
  DELETE_BUNDLE,
  DELETE_BUNDLE_FAILURE,
  DELETE_BUNDLE_SUCCESS,
  EDIT_BUNDLE,
  EDIT_BUNDLE_FAILURE,
  EDIT_BUNDLE_SUCCESS,
  FETCH_BUNDLE,
  FETCH_BUNDLE_FAILURE,
  FETCH_BUNDLE_SUCCESS,
  FETCH_BUNDLES_LIST,
  FETCH_BUNDLES_LIST_FAILURE,
  FETCH_BUNDLES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_SUCCESS,
  UPDATE_BUNDLES,
} from '../actions/types/bundles'
import type { RootState } from '../index'

export type BundlesState = {
  error: string | null
  isCreating: boolean
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  lastCreatedBundleId: string | null
  list: string[]
  map: Record<string, Bundle>
  totalCount: number
}

export const INITIAL_STATE: BundlesState = {
  list: [],
  map: {},
  isLoading: false,
  isDeleting: false,
  isFetching: false,
  isFetchingList: false,
  isCreating: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  lastCreatedBundleId: null,
}

const bundles = (
  state: BundlesState = INITIAL_STATE,
  action: AnyAction,
): BundlesState => {
  switch (action.type) {
    case FETCH_BUNDLES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_BUNDLES_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_BUNDLES_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_BUNDLES_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_BUNDLES:
      return { ...state, map: secondLevelMerge(state.map, action.bundles) }
    case FETCH_BUNDLE:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_BUNDLE_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_BUNDLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_BUNDLE:
      return { ...state, isLoading: true }
    case EDIT_BUNDLE_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_BUNDLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_BUNDLE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_BUNDLE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.omit([action.bundleId], state.map),
        list: R.without([action.bundleId], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case DELETE_BUNDLE:
      return { ...state, isDeleting: true }
    case CREATE_BUNDLE:
      return { ...state, isCreating: true }
    case CREATE_BUNDLE_SUCCESS:
      return {
        ...state,
        lastCreatedBundleId: action.bundleId,
        isCreating: false,
      }
    case CREATE_BUNDLE_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export default bundles
export const getBundles = (state: RootState): BundlesState => state.bundles
export const getBundlesList = (state: RootState) => getBundles(state).list
export const getBundlesMap = (state: RootState) => getBundles(state).map
export const getBundle = (bundleId: string) =>
  createSelector(getBundlesMap, (map) => R.prop(bundleId, map))
export const getMultipleBundles = (ids: string[]) =>
  createSelector(getBundlesMap, (map) => R.props(ids, map))
export const getBundlesIsLoading = (state: RootState) =>
  getBundles(state).isLoading
export const getBundlesIsFetching = (state: RootState) =>
  getBundles(state).isFetching
export const getBundlesIsFetchingList = (state: RootState) =>
  getBundles(state).isFetchingList
export const getBundlesIsDeleting = (state: RootState) =>
  getBundles(state).isDeleting
export const getBundlesIsCreating = (state: RootState) =>
  getBundles(state).isCreating
export const getBundlesError = (state: RootState) => getBundles(state).error
export const getBundlesTotalCount = (state: RootState) =>
  getBundles(state).totalCount
export const getLastCreatedBundleId = (state: RootState) =>
  getBundles(state).lastCreatedBundleId
