import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse, Fab, Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDebounce } from 'use-debounce'
import { ClassesType, Defaults, Text } from '@pbt/pbt-ui-components'
import {
  List as ListIcon,
  LocationsMap as LocationsMapIcon,
} from '@pbt/pbt-ui-components/src/icons'

import SelectionField from '~/components/common/inputs/SelectionField'
// @ts-ignore
import Map from '~/components/common/map/Map'
import {
  BusinessDto,
  fillFromGooglePlace,
  GeoPlace,
} from '~/store/dto/Business'
// @ts-ignore
import { SearchTypes } from '~/utils/GoogleMapHelper'

import AddItNowLink from './AddItNowLink'
// @ts-ignore
import MapMarkersList from './MapMarkersList'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
    },
    mapGridItem: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      boxShadow: theme.constants.buttonBlockShadow,
      backgroundColor: theme.colors.selectedOption,
      color: theme.colors.secondaryText,
      marginTop: theme.spacing(1),
      borderRadius: 0,
      height: 44,
      '&&&&:active': {
        backgroundColor: theme.colors.selectedOption,
      },
      '&&&&:hover': {
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.25)',
        backgroundColor: theme.colors.selectedOption,
      },
    },
    header: {
      lineHeight: '52px',
      fontSize: 44,
      [theme.breakpoints.down('md')]: {
        fontSize: '2.6rem',
        lineHeight: '24px',
        textAlign: 'center',
      },
    },
    subHeader: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
      },
      paddingBottom: 5,
    },
    selectionField: {
      paddingLeft: 10,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 13,
        paddingRight: 13,
      },
    },
    listContainer: {
      position: 'relative',
    },
    mobileList: {
      height: 463,
    },
    listAnimator: {
      top: 0,
      width: '100%',
      position: 'absolute',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      backgroundColor: theme.colors.loginFormBackground,
    },
    mapContainer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 10,
      },
      [theme.breakpoints.down('md')]: {
        height: 463,
      },
    },
    itemText: {
      lineHeight: '22px',
    },
  }),
  { name: 'ClinicsSelector' },
)

interface ClinicsSelectorProps {
  classes?: ClassesType<typeof useStyles>
  headerText?: string
  onAddNewClinicRequested: () => void
  onBusinessLoaded: (businessDto: BusinessDto) => void
  onClinicSelected?: () => void
  subheaderText?: string
}

const ClinicsSelector = ({
  onBusinessLoaded,
  headerText,
  subheaderText,
  onClinicSelected,
  onAddNewClinicRequested,
  classes: classesProp,
}: ClinicsSelectorProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Search'])

  const [rawSearchQuery, setRawSearchQuery] = useState('')
  const [debouncedSearchQuery] = useDebounce(
    rawSearchQuery,
    Defaults.DEBOUNCE_ACTION_TIME,
  )
  const [submittedSearchQuery, setSubmittedSearchQuery] = useState('')
  const [searchPoints, setSearchPoints] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [googleMapHelper, setGoogleMapHelper] = useState<any | null>(null)
  const [autoMove, setAutoMove] = useState(false)
  const [bounds, setBounds] = useState(null)
  const [highlightedId, setHighlightedId] = useState(null)
  const [listOpen, setListOpen] = useState(false)
  const [mapCenter, setMapCenter] = useState<GeoPlace['geometry']>()
  const [mapZoom, setMapZoom] = useState<number>()
  const [mapPoints, setMapPoints] = useState([])

  useEffect(() => {
    if (!googleMapHelper) {
      return
    }
    const query =
      rawSearchQuery === submittedSearchQuery
        ? rawSearchQuery
        : debouncedSearchQuery
    setIsLoading(true)
    googleMapHelper
      .searchPlaces({
        query,
        area: bounds,
        type: SearchTypes.VETERINARY_CARE,
        fallback: true,
      })
      .then((points: any) => {
        setSearchPoints(points)
        setIsLoading(false)
        if (rawSearchQuery === submittedSearchQuery) {
          setAutoMove(true)
          setMapPoints(points)
        }
      })
  }, [debouncedSearchQuery, submittedSearchQuery])

  useEffect(() => {
    if (autoMove) {
      setAutoMove(false)
      return
    }
    if (googleMapHelper) {
      googleMapHelper
        .searchPlaces({
          query: submittedSearchQuery,
          area: bounds,
          type: SearchTypes.VETERINARY_CARE,
        })
        .then((points: any) => setMapPoints(points))
    }
  }, [googleMapHelper, bounds])

  const onSelected = (unfilledBusiness: GeoPlace) => {
    if (!googleMapHelper) {
      return
    }
    const onLoaded = (place?: GeoPlace, timezone?: { timeZoneId?: string }) => {
      onBusinessLoaded(fillFromGooglePlace(place || unfilledBusiness, timezone))
    }

    if (onClinicSelected) {
      onClinicSelected()
    }
    setIsLoading(true)
    googleMapHelper
      .getPlaceDetails({
        placeId: unfilledBusiness.place_id,
        type: SearchTypes.VETERINARY_CARE,
        lat: unfilledBusiness.geometry?.location.lat(),
        lng: unfilledBusiness.geometry?.location.lng(),
      })
      .then(([place, timezone]: [GeoPlace, { timeZoneId?: string }]) =>
        onLoaded(place, timezone),
      )
      .catch(() => onLoaded())
  }

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justifyContent="center"
      wrap="nowrap"
    >
      <Grid container item alignItems="flex-end">
        <Grid item md={5} xs={12}>
          <Text className={classes.header} variant="hero">
            {headerText || t('Common:WELCOME')}
          </Text>
          {subheaderText && (
            <Text className={classes.subHeader} variant="h3">
              {subheaderText}
            </Text>
          )}
        </Grid>
        <Grid item className={classes.selectionField} md={7} xs={12}>
          <SelectionField
            isLoading={isLoading}
            label={t('Search:BY_NAME_OR_ZIPCODE')}
            list={searchPoints}
            searchQuery={rawSearchQuery}
            onEnterPressed={() => setSubmittedSearchQuery(rawSearchQuery)}
            onItemClick={onSelected}
            onQueryChange={(query) => {
              setRawSearchQuery(query)
              if (!query) {
                setSubmittedSearchQuery(query)
              }
            }}
          >
            {({ name }) => (
              <Text
                noWrap
                strong
                className={classes.itemText}
                fontSize="1.8rem"
              >
                {name}
              </Text>
            )}
          </SelectionField>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <Grid container item direction="column" xs={12}>
          <Fab
            className={classes.button}
            color="secondary"
            variant="extended"
            onClick={() => setListOpen(!listOpen)}
          >
            {listOpen ? (
              <LocationsMapIcon className={classes.buttonIcon} />
            ) : (
              <ListIcon className={classes.buttonIcon} />
            )}
            {listOpen ? t('Common:SHOW_MAP') : t('Common:SHOW_LIST')}
          </Fab>
        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <AddItNowLink onAddNewClinicRequested={onAddNewClinicRequested} />
          <Grid container item className={classes.listContainer}>
            <Collapse className={classes.listAnimator} in={listOpen}>
              <MapMarkersList
                className={classes.mobileList}
                highlightedId={highlightedId}
                points={mapPoints}
                setHighlightedId={setHighlightedId}
                onItemClick={onSelected}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container item className={classes.mapGridItem}>
        <Hidden mdDown>
          <Grid item md={5}>
            <MapMarkersList
              highlightedId={highlightedId}
              points={mapPoints}
              setHighlightedId={setHighlightedId}
              onItemClick={onSelected}
            />
          </Grid>
        </Hidden>
        <Grid item className={classes.mapContainer} md={7} xs={12}>
          <Map
            autoMove={autoMove}
            center={mapCenter}
            highlightedId={highlightedId}
            points={mapPoints}
            zoom={mapZoom}
            onBoundsChanged={setBounds}
            onCenterChanged={(center: GeoPlace['geometry']) =>
              setMapCenter(center)
            }
            onMarkerClick={onSelected}
            onSearchServiceReady={setGoogleMapHelper}
            onZoomChanged={(zoom: number) => setMapZoom(zoom)}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item pt={2} xs={12}>
            <AddItNowLink onAddNewClinicRequested={onAddNewClinicRequested} />
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  )
}

export default ClinicsSelector
