import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from '@mui/material'
import { Field, PuiSelect, SelectEntity } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import NestedMenuItem from '../inputs/NestedMenuItem'
import { useAppointmentTypes } from './useAppointmentTypes'

export interface AppointmentTypeSelectProps {
  disabled: boolean
  field: Field | undefined
}

const AppointmentTypeSelect = ({
  disabled,
  field,
}: AppointmentTypeSelectProps) => {
  const [appointmentSelectOpen, setAppointmentSelectOpen] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null)

  const appointmentTypeItems = useAppointmentTypes()

  const isDisplayNameUpdateEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RHAP_APPT_TYPE_NAMING_UPDATES),
  )
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)

  const selectItemProps = {
    onMouseEnter: (id: string) => {
      setActiveMenuItem(id)
    },
    onSelected: () => {
      setAppointmentSelectOpen(false)
    },
    shouldClose: (id: string) => activeMenuItem !== id,
  }

  const renderSelected = (id: string) => {
    for (let i = 0; i < appointmentTypeItems.length; i++) {
      const item = appointmentTypeItems[i]

      if (item.id === id) {
        return item.name
      }

      const subitems = (item as SelectEntity).subitems ?? []
      const selected = subitems.find((cur) => cur.id === id)

      if (selected) {
        return `${item.name} ${selected.name}`
      }
    }

    return ''
  }

  return (
    <PuiSelect
      Item={NestedMenuItem}
      disabled={disabled}
      field={field}
      input={<Input id="appointment-type-select" />}
      items={appointmentTypeItems}
      name="appointmentType"
      open={appointmentSelectOpen}
      renderEmpty={false}
      renderSelectedValue={
        isDisplayNameUpdateEnabled && isOmnichannel ? renderSelected : undefined
      }
      selectItemProps={selectItemProps}
      onClose={() => setAppointmentSelectOpen(false)}
      onOpen={() => setAppointmentSelectOpen(true)}
    />
  )
}

export default AppointmentTypeSelect
