import React from 'react'
import { useSelector } from 'react-redux'

import { useIsReminderStateName } from '~/components/dashboard/reminders/reminderUtils'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { Reminder, ReminderOrderListHandlers, ReminderStateName } from '~/types'

import ReminderOrderListItem from './ReminderOrderListItem'

export interface ReminderOrderListProps extends ReminderOrderListHandlers {
  disabled?: boolean
  includePrice?: boolean
  reminder: Reminder
  title?: React.ReactNode
}

const ReminderOrderList = ({
  disabled,
  includePrice = false,
  reminder,
  title,
  isCheckedItem,
  onCheckItem,
  onUncheckItem,
}: ReminderOrderListProps) => {
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)

  const isResolved = useIsReminderStateName(
    reminder,
    ReminderStateName.RESOLVED,
  )
  const isDeclined = useIsReminderStateName(
    reminder,
    ReminderStateName.DECLINED,
  )

  if (
    !reminder.soapChargeOrderItems ||
    isResolved ||
    isDeclined ||
    !patientId
  ) {
    return null
  }

  return (
    <>
      {title}
      {reminder.soapChargeOrderItems.map((orderItem, index) => (
        <ReminderOrderListItem
          clientId={clientId}
          disabled={disabled}
          includePrice={includePrice}
          isCheckedItem={isCheckedItem}
          key={orderItem.id || index}
          orderItem={orderItem}
          patientId={patientId}
          onCheckItem={onCheckItem}
          onUncheckItem={onUncheckItem}
        />
      ))}
    </>
  )
}

export default ReminderOrderList
