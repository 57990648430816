import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import UploadPage from '../../registration/UploadPage'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: 700,
      maxWidth: 700,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'UploadImageDialog' },
)

export interface UploadImageDialogProps extends BasePuiDialogProps {
  Component?: React.JSXElementConstructor<any>
  onCancel?: () => void
  onUploaded?: (logo: string) => void
}

const UploadImageDialog = ({
  Component: ComponentProp,
  open,
  onClose,
  onCancel,
  onUploaded,
  ...rest
}: UploadImageDialogProps) => {
  const classes = useStyles()

  const Component = ComponentProp || UploadPage

  return (
    <PuiDialog
      aria-labelledby="upload-image-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Component
        onCancel={() => {
          if (onCancel) {
            onCancel()
          }
          if (onClose) {
            onClose()
          }
        }}
        onUploaded={(logo: string) => {
          if (onUploaded) {
            onUploaded(logo)
          }
          if (onClose) {
            onClose()
          }
        }}
        {...rest}
      />
    </PuiDialog>
  )
}

export default UploadImageDialog
