import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AddButton, Nil } from '@pbt/pbt-ui-components'

import { getChargeSheetSubItemById } from '~/store/duck/clientFinanceData'
import { useGetOpenAddInvoiceDialogAfterOrderFiltersFetched } from '~/store/hooks/chargeSheet'
import { getPatient } from '~/store/reducers/patients'
import { ChargeSheetItemSection } from '~/types/entities/chargesSheet'

import { formatMoneyRange } from '../invoices/invoiceUtils'
import Discounts from './components/Discounts'
import FinanceDetailedFooterTable from './components/FinanceDetailedFooterTable'

const useStyles = makeStyles((theme) => ({
  buttonCell: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    verticalAlign: 'top',
  },
  totalCell: {
    padding: 0,
  },
}))

export interface ChargeSheetTableFooterProps {
  chargesId: string | Nil
  currentItemId: string
}

const ChargeSheetTableFooter = ({
  chargesId,
  currentItemId,
}: ChargeSheetTableFooterProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const chargeSheetItem =
    useSelector(getChargeSheetSubItemById(currentItemId)) ||
    ({} as ChargeSheetItemSection)

  const notFinalized =
    chargeSheetItem && chargeSheetItem.soap
      ? !chargeSheetItem.soap.finalized
      : true

  const {
    amount,
    subtotal,
    totalTax,
    clientId,
    patientId,
    groupedItems,
    soapId,
    eventId,
    soap,
  } = chargeSheetItem

  const patient = useSelector(getPatient(patientId))

  const openAddDialogAfterFetch =
    useGetOpenAddInvoiceDialogAfterOrderFiltersFetched()

  const rowData = [
    {
      id: 'subtotal',
      name: t('Common:SUBTOTAL'),
      value: formatMoneyRange(subtotal),
    },
    {
      id: 'discount',
      Component: Discounts,
    },
    {
      id: 'totalTax',
      name: t('Common:TAX'),
      value: formatMoneyRange(totalTax),
    },
  ]

  const totalRowData = {
    name: (isExapnded: boolean) =>
      t('Common:PATIENT_TOTAL_WITH_VALUE', {
        patientName: patient?.name,
        amount: !isExapnded && formatMoneyRange(amount),
      }),
    value: amount && formatMoneyRange(amount),
  }

  const onAdd = () => {
    if (chargesId && clientId) {
      openAddDialogAfterFetch({
        id: chargesId,
        clientId,
        patientId,
        groupedItems,
        soapId,
        eventId,
        producerId: R.prop('assignedVetId', soap),
      })
    }
  }

  return (
    <TableRow>
      <TableCell />
      {notFinalized ? (
        <TableCell className={classes.buttonCell} colSpan={2}>
          <Grid container>
            <AddButton addText={t('Common:ADD_ITEM')} onAdd={onAdd} />
          </Grid>
        </TableCell>
      ) : (
        <TableCell colSpan={2} />
      )}
      <TableCell className={classes.totalCell} colSpan={3}>
        <FinanceDetailedFooterTable
          id={currentItemId}
          rowData={rowData}
          totalRowData={totalRowData}
        />
      </TableCell>
    </TableRow>
  )
}

export default ChargeSheetTableFooter
