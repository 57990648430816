import React from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      borderRadius: 0,
      boxShadow: 'none',
      margin: '0px !important',
      '&::before': {
        display: 'none',
      },
    },
    summary: {
      padding: theme.spacing(0.5),
      minHeight: '32px !important',
    },
    summaryContent: {
      margin: '0 !important',
      width: '100%',
      alignItems: 'center',
      '&> :last-child': {
        paddingRight: '0 !important',
      },
    },
    title: {
      paddingTop: 4,
      lineHeight: '16px',
    },
    details: {
      display: 'flex',
      padding: 0,
      paddingLeft: theme.spacing(4),
      flexDirection: 'column',
    },
    icon: {
      color: theme.colors.secondaryText,
    },
    rightIcon: {
      marginLeft: 'auto',
    },
    activated: {
      color: '#E48736',
    },
    checkbox: {
      padding: 0,
      color: theme.colors.secondaryText,
    },
    checkboxChecked: {
      color: `${theme.colors.important} !important`,
    },
  }),
  { name: 'TreeViewNode' },
)

const TreeViewNode = ({
  classes: classesProp,
  activated,
  expandable = true,
  expanded,
  checkable,
  checked,
  title,
  content,
  iconAlignRight = false,
  onChange,
  onCheckChange,
}) => {
  const classes = useStyles({ classes: classesProp })
  return (
    <Accordion
      className={classes.root}
      expanded={expandable && expanded}
      onChange={onChange}
    >
      <AccordionSummary
        classes={{
          root: classes.summary,
          content: classes.summaryContent,
        }}
      >
        {expandable &&
          !iconAlignRight &&
          (expanded ? (
            <ExpandLessIcon
              className={classNames(
                classes.icon,
                activated && classes.activated,
              )}
            />
          ) : (
            <ExpandMoreIcon
              className={classNames(
                classes.icon,
                activated && classes.activated,
              )}
            />
          ))}
        {checkable && (
          <Checkbox
            checked={checked}
            classes={{
              root: classes.checkbox,
              checked: classes.checkboxChecked,
            }}
            onChange={onCheckChange}
          />
        )}
        <Text
          className={classNames(classes.title, activated && classes.activated)}
          color="secondary"
          ml={1}
          variant="body2"
        >
          {title}
        </Text>
        {expandable &&
          iconAlignRight &&
          (expanded ? (
            <ExpandLessIcon
              className={classNames(
                classes.icon,
                classes.rightIcon,
                activated && classes.activated,
              )}
            />
          ) : (
            <ExpandMoreIcon
              className={classNames(
                classes.icon,
                classes.rightIcon,
                activated && classes.activated,
              )}
            />
          ))}
      </AccordionSummary>
      {content && (
        <AccordionDetails classes={{ root: classes.details }}>
          {content}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default TreeViewNode
