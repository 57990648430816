import { ZoomSettings } from '@pbt/pbt-ui-components'

import { getAPIPath, request } from './core'

export const createZoomLink = (
  businessId: string,
  appointmentId: string,
  zoomUserId: string,
) =>
  request(
    getAPIPath('v1/zoom/meetings'),
    { method: 'POST', params: { businessId, appointmentId, zoomUserId } },
    true,
  )

export const fetchZoomUsers = (businessId: string) =>
  request(
    getAPIPath('v1/zoom/users'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const deleteZoomLink = (businessId: string, appointmentId: string) =>
  request(
    getAPIPath('v1/zoom/meetings'),
    { method: 'DELETE', params: { businessId, appointmentId } },
    true,
  )

export const testZoomConnection = (
  businessId: string,
  zoomAccountId: string,
  zoomClientId: string,
  zoomClientSecret: string,
) =>
  request(
    getAPIPath('v1/zoom/keysValid'),
    {
      method: 'GET',
      params: {
        businessId,
        zoomAccountId,
        zoomClientId,
        zoomClientSecret,
      },
    },
    true,
  )

export const updateZoomSettings = (
  businessId: string,
  zoomSettings: ZoomSettings,
) =>
  request(
    getAPIPath('v1/zoom/settings'),
    { method: 'PUT', params: { businessId }, data: zoomSettings },
    true,
  )

export const fetchZoomSettings = (
  currentBusinessId: string,
  businessId: string,
  includeKeys: boolean,
) =>
  request(
    getAPIPath('v1/zoom/settings'),
    { method: 'GET', params: { businessId, includeKeys } },
    true,
  )
