import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { AlertsConfig } from '~/types'

import AddAlerts, { AddAlertsHandle } from './AddAlerts'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 416,
      maxWidth: 416,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 100,
    },
  }),
  { name: 'AddAlertsDialog' },
)

interface AddAlertsDialogProps extends BasePuiDialogProps {
  alertColorId: AlertsConfig['alertColorId'] | Nil
  alerts: AlertsConfig['alerts']
  customAlertType: AlertsConfig['customAlertType']
  onOk?: (alertsConfig?: AlertsConfig) => void
  patientName?: string
}

const AddAlertsDialog = ({
  open,
  alerts,
  alertColorId,
  customAlertType,
  patientName,
  onClose,
  onOk = R.F,
}: AddAlertsDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const addAlertsRef = useRef<AddAlertsHandle>(null)

  const save = () => {
    onOk(addAlertsRef.current?.getAlertsConfig())
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader className={classes.button} onClick={save}>
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="add-alerts-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      scroll="paper"
      title={t('Dialogs:ADD_ALERTS_DIALOG.TITLE', {
        patientName: patientName || t('Common:PATIENT'),
      })}
      onClose={onClose}
    >
      <AddAlerts
        alertColorId={alertColorId}
        alerts={alerts}
        customAlertType={customAlertType}
        ref={addAlertsRef}
      />
    </PuiDialog>
  )
}

export default AddAlertsDialog
