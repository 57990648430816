import { AppointmentCommunicationTemplate } from '@pbt/pbt-ui-components'

import { AutoReplyMessageConfig } from '~/types'

import { getAPIPath, getPreludeAPIPath, request } from './core'
import { ConversationTransport } from './graphql/generated/types'

export const fetchFetchAppointmentConfirmationPreview = (
  businessId: string,
  templateCandidate: AppointmentCommunicationTemplate,
) =>
  request(
    getAPIPath('appointments/message/client-confirmation/preview/dummy'),
    {
      method: 'POST',
      data: templateCandidate,
      params: { businessId },
    },
    true,
  )

export const previewAutoReplyByTransportType = (
  currentBusinessId: string,
  businessId: string,
  transport: ConversationTransport,
  config: AutoReplyMessageConfig,
) =>
  request(
    getPreludeAPIPath(
      `clients-communication/v1/settings/${businessId}/auto-reply/preview`,
    ),
    {
      method: 'POST',
      data: config,
      params: {
        businessId,
        transport,
      },
    },
    true,
  )
