import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import {
  ButtonWithLoader,
  Defaults,
  PrimitiveTable,
  PrimitiveTableColumn,
  Text,
} from '@pbt/pbt-ui-components'
import { getDomainContext } from '@pbt/pbt-ui-components/src/utils'

import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'
import i18n from '~/locales/i18n'
import {
  fetchConnectedDevices,
  getConnectedDevicesIsFetching,
  getConnectedDevicesList,
  getMultipleConnectedDevices,
} from '~/store/duck/connectedDevices'
import { ConnectedDevice } from '~/types'
import { isNVA } from '~/utils'

import ConnectedDevicesConnectionColumn from './ConnectedDevicesConnectionColumn'

const domainContext = getDomainContext()
const envVariables = ENVIRONMENT_VARIABLES[domainContext.env]
const auth0Domain = isNVA
  ? envVariables.NVA.AUTH0_DOMAIN
  : envVariables.PORTAL.AUTH0_DOMAIN
const deviceConnectUrl = `https://${auth0Domain}/activate`

const columns: PrimitiveTableColumn[] = [
  {
    label: i18n.t('Common:DEVICE'),
    prop: (device: ConnectedDevice) => `POS: ${device.serialNumber}`,
    width: 6,
  },
  {
    component: ConnectedDevicesConnectionColumn,
    width: 12,
  },
]

const ConnectedDevicesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const list = useSelector(getConnectedDevicesList)
  const devices = useSelector(getMultipleConnectedDevices(list))
  const isFetching = useSelector(getConnectedDevicesIsFetching)

  const handleFetchConnectedDevices = () => {
    dispatch(fetchConnectedDevices())
  }

  useEffect(() => {
    handleFetchConnectedDevices()
  }, [])

  const isItemLoaded = (index: number) => Boolean(devices[index])

  return (
    <Grid container item direction="column" flex={1} px={3} wrap="nowrap">
      <Grid container item justifyContent="space-between" py={2}>
        <Text variant="h1">
          {t('Admin:CONNECTED_DEVICES.CONNECTED_DEVICES')}
        </Text>
        <Box>
          <ButtonWithLoader
            color="secondary"
            loading={isFetching}
            sx={{ mr: 2 }}
            onClick={handleFetchConnectedDevices}
          >
            {t('Common:REFRESH_LIST')}
          </ButtonWithLoader>
          <ButtonWithLoader
            href={deviceConnectUrl}
            // @ts-ignore
            target="_blank"
          >
            {t('Admin:CONNECTED_DEVICES.CONNECT_NEW_DEVICE')}
          </ButtonWithLoader>
        </Box>
      </Grid>
      <PrimitiveTable
        RowComponentProps={{
          noOverflow: false,
        }}
        columns={columns}
        expandable={false}
        isItemLoaded={isItemLoaded}
        list={devices}
        loadMoreItems={() => {}}
        totalCount={
          isFetching ? Defaults.INFINITE_LIST_BATCH_LOAD_COUNT : devices.length
        }
      />
    </Grid>
  )
}

export default ConnectedDevicesPage
