import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Input, InputLabel } from '@mui/material'
import * as R from 'ramda'
import { Field, PuiSelect } from '@pbt/pbt-ui-components'

import {
  fetchDiscountGroups,
  getDiscountGroupsIsFetching,
  getDiscountGroupsList,
  getMultipleDiscountGroups,
  // @ts-ignore
} from '~/store/duck/discountGroups'
import { getCurrentBusinessId } from '~/store/reducers/auth'

export interface DiscountGroupSelectProps {
  field: Field
}

const DiscountGroupSelect = ({ field }: DiscountGroupSelectProps) => {
  const dispatch = useDispatch()
  const businessId = useSelector(getCurrentBusinessId)
  const isFetching: boolean = useSelector(getDiscountGroupsIsFetching)
  const discountGroupsList: string[] = useSelector(getDiscountGroupsList)
  const { t } = useTranslation('Common')

  const list = field.value
    ? R.uniq(discountGroupsList.concat(field.value))
    : discountGroupsList

  const discountGroups: any[] = useSelector(getMultipleDiscountGroups(list))
  const items = discountGroups.map((item) => ({
    ...item,
    name: item.deleted
      ? `${item.name} (${t('Common:DISCOUNT_GROUP_REMOVED')})`
      : item.name,
  }))

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open && businessId) {
      dispatch(fetchDiscountGroups(businessId))
    }
  }, [businessId, open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="discount-group-select">{field.label}</InputLabel>
      <PuiSelect
        field={field}
        input={<Input id="discount-group-select" />}
        isLoading={isFetching}
        items={items}
        margin="none"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </FormControl>
  )
}

export default DiscountGroupSelect
