import React from 'react'

import { BatchInvoice, Invoice } from '~/types'

import MembershipSavingsBanner, {
  MembershipSavingsBannerProps,
} from '../membership/MembershipSavingsBanner'

export interface InvoiceSavingBannerProps
  extends Partial<MembershipSavingsBannerProps> {
  invoice?: Invoice | BatchInvoice
}

const InvoiceSavingsBanner = ({
  invoice,
  ...rest
}: InvoiceSavingBannerProps) => (
  <MembershipSavingsBanner
    hideIfHasMembership
    clientId={invoice?.clientId}
    patientId={invoice?.patientId}
    savingsDialogProps={{
      invoiceId: invoice?.id,
    }}
    wellnessCoverage={invoice?.wellnessCoverage}
    {...rest}
  />
)

export default InvoiceSavingsBanner
