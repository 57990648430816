import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import {
  getTimetableIsDeleting,
  getTimetableIsSaving,
} from '~/store/reducers/timetable'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 144,
    },
    radioLabel: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'RecurringEvent' },
)

export interface RecurringEventProps {
  onProceed: (param: string | undefined) => void
  proceedButtonActionName?: string
}

const RecurringEvent = ({
  onProceed,
  proceedButtonActionName: proceedButtonActionNameProp,
}: RecurringEventProps) => {
  const classes = useStyles()
  const isDeleting = useSelector(getTimetableIsDeleting)
  const isSaving = useSelector(getTimetableIsSaving)
  const { t } = useTranslation(['Common', 'TimeTable'])

  const proceedButtonActionName =
    proceedButtonActionNameProp || t('Common:PROCEED')

  const options = [
    { label: t('TimeTable:RECURRING_EVENT_POPPER.THIS_APPOINTMENT_ONLY') },
    {
      label: t('TimeTable:RECURRING_EVENT_POPPER.THIS_AND_ALL_FOLLOWING'),
      param: 'following',
    },
    {
      label: t('TimeTable:RECURRING_EVENT_POPPER.ALL_APPOINTMENTS'),
      param: 'all',
    },
  ]

  const [selectedOption, setSelectedOption] = useState(0)

  return (
    <Grid container alignItems="center" direction="column" p={3} rowSpacing={3}>
      <Grid container item>
        <RadioGroup
          aria-label="recurring-options"
          name="recurring-options"
          value={selectedOption}
          onChange={(event, value) => setSelectedOption(Number(value))}
        >
          {options.map(({ label }, index) => (
            <FormControlLabel
              classes={{
                label: classes.radioLabel,
              }}
              control={<Radio />}
              key={label}
              label={label}
              value={index}
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item>
        <ButtonWithLoader
          className={classes.button}
          disabled={isDeleting || isSaving}
          loading={isDeleting || isSaving}
          onClick={() => onProceed(options[selectedOption].param)}
        >
          {proceedButtonActionName}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default RecurringEvent
