import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogNames from '~/constants/DialogNames'
import {
  clearPrescriptionForEmail,
  clearPrescriptionForPrint,
} from '~/store/actions/orders'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getPrescriptionForEmail,
  getPrescriptionForPrint,
} from '~/store/reducers/orders'
import { getPatient } from '~/store/reducers/patients'
import { getSoapId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { getPrescriptionType } from '~/utils/prescription'
import useDialog from '~/utils/useDialog'

import PrintOutsidePharmacyPrescription from '../soap/PrintOutsidePharmacyPrescription'

const PrescriptionWatcher = () => {
  const dispatch = useDispatch()

  const business = useSelector(getCurrentBusiness)
  const soapId = useSelector(getSoapId)
  const prescriptionForPrint = useSelector(getPrescriptionForPrint)
  const client = useSelector(getUser(prescriptionForPrint?.clientId))
  const patient = useSelector(getPatient(prescriptionForPrint?.patientId))
  const prescriptionForEmail = useSelector(getPrescriptionForEmail)

  const [openEmailPrescriptionDialog] = useDialog(
    DialogNames.EMAIL_PRESCRIPTION,
    () => dispatch(clearPrescriptionForEmail()),
  )

  const [openPrintInHouseOrderLabelDialog] = useDialog(
    DialogNames.PRINT_IN_HOUSE_ORDER_LABEL,
    () => dispatch(clearPrescriptionForPrint()),
  )

  const { isInHouse, isScriptOut } = getPrescriptionType(
    prescriptionForPrint?.prescriptionType,
  )

  useEffect(() => {
    if (prescriptionForEmail) {
      openEmailPrescriptionDialog({
        prescription: prescriptionForEmail,
        soapId,
      })
    }
  }, [prescriptionForEmail])

  useEffect(() => {
    if (prescriptionForPrint && isInHouse) {
      openPrintInHouseOrderLabelDialog({
        printInfo: {
          ...prescriptionForPrint,
          client,
          patient,
          business,
        },
      })
    }
  }, [prescriptionForPrint])

  return prescriptionForPrint && isScriptOut ? (
    <PrintOutsidePharmacyPrescription prescription={prescriptionForPrint} />
  ) : (
    <></>
  )
}

export default PrescriptionWatcher
