import React from 'react'
import { useSelector } from 'react-redux'
import { Constant } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getImagingOrderStatuses } from '~/store/reducers/constants'

import ImagingStatusLabel from '../../imaging-procedures/ImagingStatusLabel'

export interface ImagingStatusFilterProps extends CheckboxListFilterProps {}

const ImagingStatusFilter = (props: ImagingStatusFilterProps) => {
  const ImagingOrderStatuses: Constant[] = useSelector(getImagingOrderStatuses)

  const checkboxOptions = ImagingOrderStatuses.map((option) => ({
    ...option,
    component: <ImagingStatusLabel statusId={option.id} />,
  }))

  return <CheckboxListFilter arrayFormat items={checkboxOptions} {...props} />
}

export default ImagingStatusFilter
