import { Department } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchDepartments = (
  currentBusinessId: string,
  businessId: string,
  includeInactive: boolean,
) =>
  request(
    getAPIPath('departments'),
    { method: 'GET', params: { businessId, includeInactive } },
    true,
    Schema.departmentsList,
  )

export const createDepartment = (
  currentBusinessId: string,
  businessId: string,
  newDepartment: Omit<Department, 'id'>,
) =>
  request(
    getAPIPath('departments'),
    { method: 'PUT', params: { businessId }, data: newDepartment },
    true,
    Schema.departmentItem,
  )

export const toggleDepartmentState = (
  currentBusinessId: string,
  businessId: string,
  departmentId: string,
) =>
  request(
    getAPIPath(`departments/${departmentId}/activate`),
    { method: 'POST', params: { businessId } },
    true,
    Schema.departmentItem,
  )
