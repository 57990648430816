import React from 'react'
import { Box, TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { ClassesType, TextInteractive } from '@pbt/pbt-ui-components'

import EditItemButtonGroup from '~/components/common/buttons/EditItemButtonGroup'
import { SoapTemplateTab } from '~/types'

const MIN_ROW_HEIGHT = 40

const useStyles = makeStyles(
  (theme) => ({
    cell: {},
    mainColumn: {},
    filledBackground: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    rowCell: {
      borderBottom: 'none',
    },
    rows: {
      minHeight: MIN_ROW_HEIGHT,
    },
  }),
  { name: 'SoapTemplateTabsTableRow' },
)

export interface SoapTemplateTabsTableRowProps {
  classes: ClassesType<typeof useStyles>
  isEven: boolean
  isFetching: boolean
  name: string
  onDelete: (soapTab: SoapTemplateTab) => void
  onEdit: (soapTab: SoapTemplateTab) => void
  tab: SoapTemplateTab
  widgets: React.JSX.Element[] | null
}

const SoapTemplateTabsTableRow = ({
  classes: classesProp,
  isEven,
  isFetching,
  name,
  onDelete,
  onEdit,
  tab,
  widgets,
}: SoapTemplateTabsTableRowProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <TableRow
      className={classNames(classes.rows, {
        [classes.filledBackground]: !isEven,
      })}
    >
      <TableCell
        className={classNames(classes.cell, classes.mainColumn)}
        classes={{ root: classes.rowCell }}
      >
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <TextInteractive
            isLoading={isFetching}
            loaderHeight={MIN_ROW_HEIGHT}
            variant="body"
          >
            {name}
          </TextInteractive>
          <Box alignItems="center" display="flex">
            {!isFetching && (
              <EditItemButtonGroup
                item={tab}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell className={classes.cell} classes={{ root: classes.rowCell }}>
        <TextInteractive
          isLoading={isFetching}
          loaderHeight={MIN_ROW_HEIGHT}
          variant="body"
        >
          {widgets}
        </TextInteractive>
      </TableCell>
    </TableRow>
  )
}

export default SoapTemplateTabsTableRow
