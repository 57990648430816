import { AnyAction } from 'redux'

import { ReconcilationProcessInfo } from '~/types/entities/chat'
import { getErrorMessage } from '~/utils/errors'

import {
  FETCH_BUSINESS_CHAT_ONBOARD_INFO,
  FETCH_BUSINESS_CHAT_ONBOARD_INFO_FAILURE,
  FETCH_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS,
  FETCH_CHAT_RECONCILATION_INFO,
  FETCH_CHAT_RECONCILATION_INFO_FAILURE,
  FETCH_CHAT_RECONCILATION_INFO_SUCCESS,
  OAUTH_LOGIN_ROCKET_CHAT,
  OAUTH_LOGIN_ROCKET_CHAT_FAILURE,
  OAUTH_LOGIN_ROCKET_CHAT_SUCCESS,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_FAILURE,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS,
  SET_CHAT_UNREAD_COUNT,
  START_CHAT_RECONCILATION,
  START_CHAT_RECONCILATION_FAILURE,
  START_CHAT_RECONCILATION_SUCCESS,
} from '../actions/types/chat'
import type { RootState } from '../index'

export type ChatState = {
  authToken: string | null
  error: string | null
  isLoading: boolean
  isOnboardLoading: boolean
  isReconcilationFetching: boolean
  isReconcilationStarting: boolean
  reconcilationProcesses: ReconcilationProcessInfo[]
  unreadCount: number | null
  userId: string | null
}

export const INITIAL_STATE: ChatState = {
  unreadCount: null,
  authToken: null,
  userId: null,
  isLoading: false,
  isOnboardLoading: false,
  isReconcilationFetching: false,
  isReconcilationStarting: false,
  error: null,
  reconcilationProcesses: [],
}

const chat = (
  state: ChatState = INITIAL_STATE,
  action: AnyAction,
): ChatState => {
  switch (action.type) {
    case OAUTH_LOGIN_ROCKET_CHAT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case OAUTH_LOGIN_ROCKET_CHAT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userId: action.userId,
        authToken: action.authToken,
      }
    case OAUTH_LOGIN_ROCKET_CHAT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case SET_CHAT_UNREAD_COUNT:
      return { ...state, unreadCount: action.unreadCount }
    case FETCH_CHAT_RECONCILATION_INFO:
      return { ...state, isReconcilationFetching: true }
    case FETCH_CHAT_RECONCILATION_INFO_SUCCESS:
      return {
        ...state,
        reconcilationProcesses: action.reconcilationProcesses,
        isReconcilationFetching: false,
      }
    case FETCH_CHAT_RECONCILATION_INFO_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReconcilationFetching: false,
      }
    case START_CHAT_RECONCILATION:
      return { ...state, isReconcilationStarting: true }
    case START_CHAT_RECONCILATION_SUCCESS:
      return {
        ...state,
        reconcilationProcesses: [
          action.reconcilationProcess,
          ...state.reconcilationProcesses,
        ],
        isReconcilationStarting: false,
      }
    case START_CHAT_RECONCILATION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReconcilationStarting: false,
      }
    case FETCH_BUSINESS_CHAT_ONBOARD_INFO:
      return { ...state, isOnboardLoading: true }
    case FETCH_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS:
      return { ...state, isOnboardLoading: false }
    case FETCH_BUSINESS_CHAT_ONBOARD_INFO_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isOnboardLoading: false,
      }
    case PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO:
      return { ...state, isOnboardLoading: true }
    case PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS:
      return { ...state, isOnboardLoading: false }
    case PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isOnboardLoading: false,
      }
    default:
      return state
  }
}

export default chat
export const getChat = (state: RootState): ChatState => state.chat
export const getChatUnreadCount = (state: RootState) =>
  getChat(state).unreadCount
export const getChatReconcilationProcesses = (state: RootState) =>
  getChat(state).reconcilationProcesses
export const getChatAuthToken = (state: RootState) => getChat(state).authToken
export const getChatIsReconcilationFetching = (state: RootState) =>
  getChat(state).isReconcilationFetching
export const getChatIsReconcilationStarting = (state: RootState) =>
  getChat(state).isReconcilationStarting
export const getChatIsLoading = (state: RootState) => getChat(state).isLoading
export const getChatIsOnboardLoading = (state: RootState) =>
  getChat(state).isOnboardLoading
