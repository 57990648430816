import React from 'react'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { Adjustment } from '~/types'
import useGetInStockAmount, {
  pickAdjustmentInStockInfo,
} from '~/utils/useGetInStockAmount'

const AdjustmentAmountCell = (item: Partial<Adjustment> | Nil) => {
  const getInStockAmount = useGetInStockAmount({
    relative: true,
    isAdjustment: true,
  })

  if (!item) {
    return null
  }

  const getAdjustmentAmount = R.pipe(
    pickAdjustmentInStockInfo,
    getInStockAmount,
  )

  return <>{getAdjustmentAmount(item)}</>
}

export default AdjustmentAmountCell
