import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { LanguageUtils, QuestionMode } from '@pbt/pbt-ui-components'

import { PuiTab } from '~/components/common/PuiTabs'
import { fetchQuestionsForSoap } from '~/store/actions/questions'
import { useGetSoapQuestionsCount } from '~/store/hooks/questions'
import { getQuestionsIsFetching } from '~/store/reducers/questions'
import { getSoapId } from '~/store/reducers/soap'
import useWorkflowTypes from '~/utils/useWorkflowTypes'

import QuestionTab from '../QuestionTab'
import QuestionTabTooltip from '../QuestionTabTooltip'

interface UseQuestionsTabs {
  mode: QuestionMode
  showAllPriorAnswers: boolean
}

export const useQuestionsTabs = ({
  mode,
  showAllPriorAnswers,
}: UseQuestionsTabs) => {
  const dispatch = useDispatch()

  const isFetching = useSelector(getQuestionsIsFetching)
  const soapId = useSelector(getSoapId)

  const workflows = useWorkflowTypes()
  const { getAnsweredQuestions, getTotalQuestions } = useGetSoapQuestionsCount()

  useEffect(() => {
    if (soapId) {
      dispatch(fetchQuestionsForSoap(soapId))
    }
  }, [soapId])

  const panels = useMemo(
    () =>
      workflows.map((workflow) => ({
        Component: () => (
          <QuestionTab
            id={workflow.id}
            mode={mode}
            showAllPriorAnswers={showAllPriorAnswers}
          />
        ),
      })),
    [mode, showAllPriorAnswers, workflows.length],
  )

  const tabs: PuiTab[] = useMemo(
    () =>
      workflows.map((workflow) => {
        const { id } = workflow

        const shortNameLabel = LanguageUtils.getTranslatedFieldName(
          workflow,
          'shortName',
        )
        const answeredQuestions = getAnsweredQuestions(workflow.id)
        const totalQuestions = getTotalQuestions(workflow.id)

        const label = isFetching
          ? `${shortNameLabel} (-/-)`
          : `${shortNameLabel} (${answeredQuestions}/${totalQuestions})`

        return {
          id,
          label,
          hide: isFetching ? false : totalQuestions === 0,
          tabElement: <QuestionTabTooltip label={label} />,
        }
      }),
    [isFetching, getAnsweredQuestions, getTotalQuestions, workflows.length],
  )

  const [tabValue, setTabValue] = useState<boolean | number>(false)

  const onTabChange = (value: number) => {
    setTabValue(value)
  }

  // Fix issue related with MUI - https://github.com/mui/material-ui/issues/32749#issuecomment-1262234760
  useEffect(() => {
    if (isFetching) {
      return () => {}
    }

    const firstShownIndex =
      tabs.findIndex((tab) => !tab.hide) > 0
        ? tabs.findIndex((tab) => !tab.hide)
        : 0

    const updateTabValue = setTimeout(() => {
      setTabValue(firstShownIndex)
    }, 100)

    return () => {
      clearTimeout(updateTabValue)
    }
  }, [...R.pluck('hide', tabs), isFetching])

  return {
    tabs,
    tabValue,
    onTabChange,
    panels,
    isFetching,
  }
}
