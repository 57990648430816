export const CREATE_AVAILABILITY_RULE =
  'availabilityRules/CREATE_AVAILABILITY_RULE'
export const CREATE_AVAILABILITY_RULE_FAILURE =
  'availabilityRules/CREATE_AVAILABILITY_RULE_FAILURE'
export const CREATE_AVAILABILITY_RULE_SUCCESS =
  'availabilityRules/CREATE_AVAILABILITY_RULE_SUCCESS'

export const FETCH_AVAILABILITY_RULE =
  'availabilityRules/FETCH_AVAILABILITY_RULE'
export const FETCH_AVAILABILITY_RULE_FAILURE =
  'availabilityRules/FETCH_AVAILABILITY_RULE_FAILURE'
export const FETCH_AVAILABILITY_RULE_SUCCESS =
  'availabilityRules/FETCH_AVAILABILITY_RULE_SUCCESS'

export const FETCH_AVAILABILITY_RULES_LIST =
  'availabilityRules/FETCH_AVAILABILITY_RULES_LIST'
export const FETCH_AVAILABILITY_RULES_LIST_FAILURE =
  'availabilityRules/FETCH_AVAILABILITY_RULES_LIST_FAILURE'
export const FETCH_AVAILABILITY_RULES_LIST_SUCCESS =
  'availabilityRules/FETCH_AVAILABILITY_RULES_LIST_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST =
  'availabilityRules/FETCH_MORE_ITEMS_FOR__AVAILABILITY_RULES_LIST'
export const FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_FAILURE =
  'availabilityRules/FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_SUCCESS =
  'availabilityRules/FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_SUCCESS'

export const UPDATE_AVAILABILITY_RULES =
  'availabilityRules/UPDATE_AVAILABILITY_RULES'

export const EDIT_AVAILABILITY_RULE = 'availabilityRules/EDIT_AVAILABILITY_RULE'
export const EDIT_AVAILABILITY_RULE_FAILURE =
  'availabilityRules/EDIT_AVAILABILITY_RULE_FAILURE'
export const EDIT_AVAILABILITY_RULE_SUCCESS =
  'availabilityRules/EDIT_AVAILABILITY_RULE_SUCCESS'

export const DELETE_AVAILABILITY_RULE =
  'availabilityRules/DELETE_AVAILABILITY_RULE'
export const DELETE_AVAILABILITY_RULE_FAILURE =
  'availabilityRules/DELETE_AVAILABILITY_RULE_FAILURE'
export const DELETE_AVAILABILITY_RULE_SUCCESS =
  'availabilityRules/DELETE_AVAILABILITY_RULE_SUCCESS'

export const CLEAR_AVAILABILITY_RULE_VALIDATION_ERROR =
  'availabilityRules/CLEAR_AVAILABILITY_RULE_VALIDATION_ERROR'
