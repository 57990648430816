import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Business,
  ButtonWithLoader,
  ClassesType,
  PhoneUtils,
  Text,
} from '@pbt/pbt-ui-components'
import {
  WellnessPromotionBackgroundBottom,
  WellnessPromotionBackgroundTop,
} from '@pbt/pbt-ui-components/src/icons'

// @ts-ignore
import ClinicLogo from '~/components/common/logos/ClinicLogo'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
      boxShadow: '2px 2px 14px 0 rgba(168,163,163,0.5)',
    },
    header: {
      padding: theme.spacing(2),
      borderBottom: theme.constants.tabBorder,
    },
    clinicLogo: {
      width: 128,
      height: 64,
    },
    captionContainer: {
      position: 'relative',
    },
    captionBackground: {
      width: '100%',
      height: 'calc(50% + 5px)',
      position: 'absolute',
      fill: (props: UseStylesProps) => props.backgroundColor,
    },
    captionBackgroundTop: {
      top: 0,
    },
    captionBackgroundBottom: {
      bottom: 0,
    },
    captionText: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      position: 'relative',
      color: theme.colors.profileText,
    },
    button: {
      marginTop: theme.spacing(4),
      '&&&&:hover': {
        backgroundColor: (props: UseStylesProps) => props.buttonsColor,
      },
      backgroundColor: (props: UseStylesProps) => props.buttonsColor,
      color: theme.colors.profileText,
      minWidth: 160,
    },
  }),
  { name: 'PreviewWellnessPromotion' },
)

interface UseStylesProps {
  backgroundColor?: string
  buttonsColor?: string
  classes?: ClassesType<typeof useStyles>
}

interface PreviewWellnessPromotionProps {
  business: Business
  className: string
  classes?: ClassesType<typeof useStyles>
  showBoopDescription: boolean
}

const PreviewWellnessPromotion = ({
  classes: classesProp,
  business,
  showBoopDescription,
  className,
}: PreviewWellnessPromotionProps) => {
  const classes = useStyles({
    classes: classesProp,
    buttonsColor: business.buttonsColor,
    backgroundColor: business.backgroundColor,
  })
  const { t } = useTranslation('Businesses')

  return (
    <Grid
      container
      item
      className={classNames(classes.root, className)}
      direction="column"
    >
      <Grid container item className={classes.header} wrap="nowrap">
        <ClinicLogo className={classes.clinicLogo} clinic={business} />
        <Grid container item direction="column" ml={2}>
          <Text strong>{business.name}</Text>
          <Text>{PhoneUtils.formatPhoneNumber(business.phone)}</Text>
          <Text>{business.email}</Text>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" direction="column" py={4}>
        <Text strong>{t('Businesses:WELLNESS_PLANS.MESSAGE_CONTENT')}</Text>

        <Grid item className={classes.captionContainer} py={7}>
          <WellnessPromotionBackgroundTop
            className={classNames(
              classes.captionBackground,
              classes.captionBackgroundTop,
            )}
            preserveAspectRatio="none"
          />
          <Text
            strong
            align="center"
            className={classes.captionText}
            px={3}
            variant="hero2"
          >
            {t('Businesses:WELLNESS_PLANS.TAKE_CONTROL_OF_INDYS_HEALTH')}
          </Text>
          <WellnessPromotionBackgroundBottom
            className={classNames(
              classes.captionBackground,
              classes.captionBackgroundBottom,
            )}
            preserveAspectRatio="none"
          />
        </Grid>

        <Text mt={1}>
          {t('Businesses:WELLNESS_PLANS.CLICK_AWAY_WITH_TELEMEDICINE')}
        </Text>
        <Text mt={1}>{t('Businesses:WELLNESS_PLANS.UNLIMITED_EXAMS')}</Text>
        {showBoopDescription && (
          <Text mt={1}>{t('Businesses:WELLNESS_PLANS.ACCESS_TO_BOOP')}</Text>
        )}
        <ButtonWithLoader className={classes.button}>
          {t('Businesses:WELLNESS_PLANS.ENROLL_NOW')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default PreviewWellnessPromotion
