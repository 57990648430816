import * as R from 'ramda'
import { AnyAction } from 'redux'
import { put, select } from 'redux-saga/effects'
import { AlertIconType, ApiError } from '@pbt/pbt-ui-components'

import { ERROR_MESSAGE_BY_TYPE } from '~/constants/errorMessages'
import { UiAlert } from '~/types'

import type { RootState } from '../index'

export const REGISTER_ALERT = 'uiAlerts/REGISTER_ALERT'
export const SHOW_ALERT = 'uiAlerts/SHOW_ALERT'
export const DISMISS_ALERT = 'uiAlerts/DISMISS_ALERT'

export const registerAlert = (level: string, message: string) => ({
  type: REGISTER_ALERT,
  level,
  message,
})
export const registerTableAlert = ({ columns, rows, message }: UiAlert) => ({
  type: REGISTER_ALERT,
  level: AlertIconType.WARN,
  columns,
  rows,
  message,
})
export const registerWarnAlert = (message: string) =>
  registerAlert(AlertIconType.WARN, message)
export const registerSuccessAlert = (message: string) =>
  registerAlert(AlertIconType.SUCCESS, message)
export const showAlert = () => ({ type: SHOW_ALERT })
export const dismissAlert = () => ({ type: DISMISS_ALERT })

export type UiAlertsState = {
  alerts: UiAlert[]
  isOpen: boolean
}

const INITIAL_STATE: UiAlertsState = {
  alerts: [],
  isOpen: false,
}

export const uiAlertsReducer = (
  state: UiAlertsState = INITIAL_STATE,
  action: AnyAction,
): UiAlertsState => {
  switch (action.type) {
    case REGISTER_ALERT:
      const { level, message, columns, rows } = action
      return {
        ...state,
        alerts: R.append({ level, message, columns, rows }, state.alerts),
      }
    case SHOW_ALERT:
      return { ...state, isOpen: true, alerts: R.tail(state.alerts) }
    case DISMISS_ALERT:
      return { ...state, isOpen: false }
    default:
      return state
  }
}

export const getUiAlertsState = (state: RootState): UiAlertsState =>
  state.uiAlerts
export const getIsOpen = (state: RootState) => getUiAlertsState(state).isOpen
export const getLastAlert = (state: RootState) =>
  R.head(getUiAlertsState(state).alerts)

const parseNetworkError = (error: ApiError) => {
  const { responseBody } = error
  if (responseBody) {
    return responseBody?.error && R.is(Object, responseBody.error)
      ? responseBody.error
      : responseBody
  }
  return {}
}

export function* notifyNetworkError(
  error: ApiError,
  forceMessageByType?: boolean,
) {
  const isOpen: boolean = yield select(getIsOpen)
  const {
    message: errorMessage,
    description: errorDescription,
    type: errorType,
  } = parseNetworkError(error)
  const networkProvidedMessage = [errorMessage, errorDescription]
    .filter(Boolean)
    .join('\n')
  const message =
    (!forceMessageByType && networkProvidedMessage) ||
    ERROR_MESSAGE_BY_TYPE[errorType]
  if (!isOpen && message) {
    yield put(registerWarnAlert(message))
  }
}

export function* notifyNetworkSuccess(message: string) {
  const isOpen: boolean = yield select(getIsOpen)
  if (!isOpen && message) {
    yield put(registerSuccessAlert(message))
  }
}
