import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  IdObject,
  PermissionArea,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'
import { getDomainContext } from '@pbt/pbt-ui-components/src/utils'

import { SpaceMonitor } from '~/api/graphql/generated/types'
import ActiveStateSwitch from '~/components/common/ActiveStateSwitch'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { getMonitorDomain } from '~/components/dashboard/admin/general/spaces/MonitorUtils'
import FeatureToggle from '~/constants/featureToggle'
import {
  fetchAssignedToSpaceMonitors,
  fetchUnassignedMonitors,
  getAssignedToSpaceMonitors,
  getUnassignedMonitors,
} from '~/store/duck/monitor'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getEventType,
  getFeatureToggle,
  getSpaceType,
  getSpecies,
} from '~/store/reducers/constants'
import { getSpacesIsLoading } from '~/store/reducers/spaces'
import { DataHandleWithUnsavedChanges, Space, UnsavedSpace } from '~/types'
import { getConstantsList, isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

import { Device, MonitorType } from './monitorTypes'
import SpaceMonitors from './SpaceMonitors'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 3, 3),
    },
    rootView: {
      paddingBottom: 0,
    },
    button: {
      width: 150,
    },
  }),
  { name: 'SpaceComponent' },
)

export interface SpaceProps {
  onChange?: () => void
  onProceed?: (space: UnsavedSpace) => void
  space?: Space | UnsavedSpace
  view?: boolean
}

export interface SpaceHandle extends DataHandleWithUnsavedChanges {}

const SpaceComponent = forwardRef<SpaceHandle, SpaceProps>(
  function SpaceComponent(
    { space, view, onProceed, onChange = () => {} },
    ref,
  ) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Admin', 'Common'])
    const domainContext = getDomainContext()

    const EventType = useSelector(getEventType)
    const Species = useSelector(getSpecies)
    const SpaceTypes = useSelector(getSpaceType)
    const isLoading = useSelector(getSpacesIsLoading)
    const permissions = useSelector(getCRUDByArea(PermissionArea.SPACE))
    const unassignedMonitors = useSelector(getUnassignedMonitors)
    const assignedMonitors = useSelector(getAssignedToSpaceMonitors)
    const spaceMonitorManagmentEnabled = useSelector(
      getFeatureToggle(FeatureToggle.SPACE_MONITOR_MANAGEMENT),
    )
    const businessId = useSelector(getCurrentBusinessId)

    const [devices, setDevices] = useState<Device[]>([])

    const monitorDomain = getMonitorDomain(domainContext.env as Environment)
    const combinedMonitors = R.concat(assignedMonitors, unassignedMonitors)
    const sortedMonitors = combinedMonitors.sort((a, b) =>
      a.deviceName.localeCompare(b.deviceName, 'en', { numeric: true }),
    )
    const spaceId = R.prop('id', space)

    useEffect(() => {
      if (!R.isEmpty(unassignedMonitors) || !R.isEmpty(assignedMonitors)) {
        setDevices([
          {
            label: t('Common:IN_ROOM'),
            items: R.pipe(
              R.filter(R.propEq('monitorType', MonitorType.IN_ROOM)),
              R.map((monitor: SpaceMonitor) => ({
                ...monitor,
                name: monitor.deviceName,
                id: monitor.token,
                spaceId,
              })),
            )(sortedMonitors),
            id: 1,
          },
          {
            label: t('Common:TABLET'),
            items: R.pipe(
              R.filter(R.propEq('monitorType', MonitorType.OUT_ROOM)),
              R.map((monitor: SpaceMonitor) => ({
                ...monitor,
                name: monitor.deviceName,
                id: monitor.token,
                spaceId,
              })),
            )(sortedMonitors),
            id: 2,
          },
        ])
      }
    }, [unassignedMonitors.length, assignedMonitors.length])

    const isCreate = !space

    useEffect(() => {
      dispatch(fetchUnassignedMonitors())
      if (spaceId) {
        dispatch(fetchAssignedToSpaceMonitors(spaceId))
      }
    }, [])

    const updateDisabled = !permissions.update

    const AppointmentEvent =
      Utils.findConstantByName('Appointment', EventType) || {}

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'name',
          label: t('Admin:SPACE.SPACE_NAME'),
          validators: ['required'],
          initialValue: isCreate ? '' : space.name,
        },
        {
          name: 'type',
          label: t('Common:TYPE_ONE'),
          initialValue: isCreate ? '' : space.type,
        },
        {
          name: 'active',
          type: 'toggle',
          initialValue: isCreate
            ? true
            : R.isNil(space.active)
              ? true
              : space.active,
        },
        {
          name: 'linkedDevices',
          label: t('Common:LINKED_DEVICES'),
          type: 'select',
          initialValue: isCreate ? [] : assignedMonitors,
        },
        {
          name: 'monitorUrl',
          label: t('Common:MONITOR_URL'),
          initialValue: isCreate
            ? ''
            : `${monitorDomain}/in-room/welcome?businessId=${businessId}&spaceId=${spaceId}`,
        },
        {
          name: 'tabletUrl',
          label: t('Common:TABLET_URL'),
          initialValue: isCreate
            ? ''
            : `${monitorDomain}/welcome-monitor?businessId=${businessId}`,
        },
        {
          name: 'appointmentTypeIds',
          label: t('Common:APPOINTMENT_TYPES'),
          type: 'select',
          initialValue: isCreate
            ? []
            : getConstantsList(
                space.appointmentTypeIds,
                AppointmentEvent.subTypes,
              ),
        },
        {
          name: 'species',
          label: t('Common:SPECIES'),
          type: 'select',
          initialValue: isCreate
            ? []
            : getConstantsList(space.speciesIds, Species),
        },
        {
          name: 'capacity',
          label: t('Common:CAPACITY'),
          initialValue: isCreate ? 1 : space.capacity,
        },
        {
          name: 'notes',
          label: t('Common:NOTES'),
          initialValue: isCreate ? '' : space.notes,
        },
      ],
      false,
    )

    const {
      name,
      type,
      active,
      appointmentTypeIds,
      species,
      capacity,
      notes,
      linkedDevices,
      monitorUrl,
      tabletUrl,
    } = fields

    useEffect(() => {
      reset()
    }, [space])

    const createSpace = (): UnsavedSpace => ({
      ...(space || {}),
      name: name.value,
      type: type.value,
      appointmentTypeIds: R.pluck('id', appointmentTypeIds.value as IdObject[]),
      speciesIds: R.pluck('id', species.value as IdObject[]),
      capacity: capacity.value,
      notes: notes.value,
      active: active.value,
    })

    useImperativeHandle(ref, () => ({
      validate,
      get: createSpace,
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    const proceed = () => {
      if (onProceed && validate()) {
        onProceed(createSpace())
      }
    }

    useFieldsChanged(() => {
      onChange()
    }, fields)

    return (
      <Grid
        container
        item
        alignItems="flex-end"
        className={classNames(classes.root, {
          [classes.rootView]: view,
        })}
        columnSpacing={3}
      >
        <Grid item xs={6}>
          <PuiTextField
            disabled={updateDisabled}
            field={name}
            inputProps={{ maxLength: 100 }}
            label={`${name.label}*`}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="space-type-select">{type.label}</InputLabel>
            <PuiSelect
              disabled={updateDisabled}
              field={type}
              input={<Input id="space-type-select" />}
              items={SpaceTypes}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
        {view && (
          <Grid item mt={1} xs={12}>
            <ActiveStateSwitch disabled={updateDisabled} field={active} />
          </Grid>
        )}
        {spaceMonitorManagmentEnabled && spaceId && (
          <>
            <Grid item mb={2} mt={4}>
              <TextWithTooltip
                strong
                tooltipText={t('Common:LINKED_DEVICES_TOOLTIP')}
                variant="body2"
              >
                {t('Common:LINKED_DEVICES')}
              </TextWithTooltip>
            </Grid>
            <SpaceMonitors
              devices={devices}
              field={linkedDevices}
              monitorUrl={monitorUrl}
              reset={reset}
              spaceId={spaceId}
              tabletUrl={tabletUrl}
              updateDisabled={updateDisabled}
            />
          </>
        )}
        <Grid item mb={2} mt={4}>
          <TextWithTooltip
            strong
            tooltipText={t('Admin:SPACE.RESTRICTIONS_TOOLTIP')}
            variant="body2"
          >
            {t('Common:RESTRICTION_OTHER')}
          </TextWithTooltip>
        </Grid>
        <Grid item xs={12}>
          <PuiSelectAll
            disabled={updateDisabled}
            field={appointmentTypeIds}
            items={AppointmentEvent.subTypes}
            label={appointmentTypeIds.label}
          />
        </Grid>
        <Grid container item columnSpacing={3} mt={2}>
          <Grid item xs>
            <PuiSelectAll
              disabled={updateDisabled}
              field={species}
              items={Species}
              label={species.label}
            />
          </Grid>
          <Grid container item xs alignItems="flex-end">
            <Grid item mb={1} mr={2}>
              <Text inline>{capacity.label}</Text>
            </Grid>
            <Grid item mt={1.5}>
              <QuantityInput
                showControls
                disabled={updateDisabled}
                field={capacity}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item mt={2}>
          <PuiTextArea
            disabled={updateDisabled}
            field={notes}
            label={notes.label}
          />
        </Grid>
        {!view && (
          <>
            <Grid item xs={12}>
              <RequiredFieldsNotice />
            </Grid>
            <Grid item mt={2}>
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoading}
                loading={isLoading}
                onClick={proceed}
              >
                {t('Common:ADD_ACTION')}
              </ButtonWithLoader>
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)

export default SpaceComponent
