import { RoleName } from '@pbt/pbt-ui-components'

import useHasRole from '~/utils/useHasRole'

export const useCanUpdateSignature = () => {
  const isChewyAuth = useHasRole(RoleName.ChewyAuthorization)
  const isVeterinarian1 = useHasRole(RoleName.Veterinarian)
  const isVeterinarian2 = useHasRole(RoleName.Veterinarian2)

  return isVeterinarian1 || isVeterinarian2 || isChewyAuth
}
