import React, { useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiSelect, PuiSelectProps } from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    select: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.controlBorder,
      borderRadius: 2,
    },
    selectWidth: {
      width: 136,
    },
    selectInput: {
      fontSize: '1.5rem',
      lineHeight: '20px',
      padding: theme.spacing(1),
    },
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
  }),
  { name: 'TableSelect' },
)

export interface TableSelectProps extends PuiSelectProps {
  classes?: ClassesType<typeof useStyles>
}

const TableSelect = ({ classes: classesProp, ...rest }: TableSelectProps) => {
  const classes = useStyles({ classes: classesProp })
  const IconComponent = useCallback(
    () => <Toggle className={classes.toggleIcon} />,
    [],
  )

  return (
    <PuiSelect
      disableUnderline
      fullWidth
      IconComponent={IconComponent}
      className={classNames(classes.select)}
      inputProps={{
        className: classes.selectInput,
      }}
      {...rest}
    />
  )
}

export default TableSelect
