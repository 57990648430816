export enum CompoundingReasonTypes {
  'ALLERGIES_INTOLERANCES' = 'Allergies/Intolerances',
  'DOSAGE_FORM' = 'Dosage Form',
  'DOSAGE_SIZE' = 'Dosage Size',
  'FLAVOR' = 'Flavor',
  'NO_APPROVED_PRODUCTS' = 'No Approved Products',
  'PRODUCT_SHORTAGE' = 'Product Shortage',
  'STRENGTH' = 'Strength',
  'OTHER' = 'Other',
}
