import React from 'react'
import { useTranslation } from 'react-i18next'

import PuiSwitch, { PuiSwitchProps } from './PuiSwitch'

const ActiveStateSwitch = (props: PuiSwitchProps) => {
  const { t } = useTranslation('Common')

  const STATE_LABEL = {
    ACTIVE: t('Common:ACTIVE_ONE'),
    INACTIVE: t('Common:INACTIVE_ONE'),
  }

  return (
    <PuiSwitch
      label={props.field?.value ? STATE_LABEL.ACTIVE : STATE_LABEL.INACTIVE}
      {...props}
    />
  )
}

export default ActiveStateSwitch
