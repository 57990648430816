import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

import { useBusinessSupportedLanguages } from '~/utils/useBusinessSupportedLanguages'
import { useChangeLanguage } from '~/utils/useChangeLanguage'

import MenuDropdown from './inputs/MenuDropdown'

const useStyles = makeStyles(
  () => ({
    selectorButton: {
      fontSize: '1.4rem',
      fontWeight: 500,
      padding: 0,
      height: 'auto',
      borderRadius: 0,
      textAlign: 'start',
      backgroundColor: 'inherit',
      '&:hover': {
        backgroundColor: 'initial !important',
        boxShadow: 'none !important',
      },
    },
    menu: {
      borderRadius: 0,
      boxShadow: '3px 3px 20px 0 rgba(168,163,163,0.5)',
    },
  }),
  { name: 'PuiLanguagesSelector' },
)

export interface PuiLanguagesSelectorProps {
  businessId: string
  classes?: ClassesType<typeof useStyles>
}

const PuiLanguagesSelector = ({
  businessId,
  classes: classesProp,
}: PuiLanguagesSelectorProps) => {
  const classes = useStyles({ classes: classesProp })
  const { currentLanguageDisplayName, toggleLang } = useChangeLanguage()
  const { EnabledLanguages } = useBusinessSupportedLanguages(businessId)

  return (
    <MenuDropdown
      classes={{
        button: classes.selectorButton,
        menu: classes.menu,
      }}
      items={EnabledLanguages}
      title={currentLanguageDisplayName?.toUpperCase()}
      onSelected={toggleLang}
    />
  )
}

export default PuiLanguagesSelector
