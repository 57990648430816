import { all, call, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError, Business, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ConversationTransport } from '~/api/graphql/generated/types'
import { OrderType } from '~/constants/SOAPStates'
import {
  AttachmentOrigin,
  Contact,
  Conversation,
  EmailEntityConfigRecipient,
} from '~/types'
import { getRecipientInfo } from '~/utils/communicationsUtils'
import { downloadFile } from '~/utils/file'

import {
  emailChargeSheet,
  emailChargeSheetFailure,
  emailChargeSheetSuccess,
  emailInvoice,
  emailInvoiceFailure,
  emailInvoiceSuccess,
  emailInvoiceSuccessV2,
  emailInvoiceV2,
  emailLabResult,
  emailLabResultFailure,
  emailLabResultSuccess,
  emailMedHistory,
  emailMedHistoryFailure,
  emailMedHistorySuccess,
  emailPayment,
  emailPaymentFailure,
  emailPaymentSuccess,
  emailPrescription,
  emailPrescriptionFailure,
  emailPrescriptionSuccess,
  emailReportCard,
  emailReportCardFailure,
  emailReportCardSuccess,
  generatePdfForChargeSheet,
  generatePdfForChargeSheetFailure,
  generatePdfForChargeSheetSuccess,
  generatePdfForInvoice,
  generatePdfForInvoiceFailure,
  generatePdfForInvoiceSuccess,
  generatePdfForInvoiceV2,
  generatePdfForMedicalHistory,
  generatePdfForMedicalHistoryFailure,
  generatePdfForMedicalHistorySuccess,
  generatePdfForPrescription,
  generatePdfForPrescriptionFailure,
  generatePdfForPrescriptionSuccess,
  generatePdfForReportCard,
  generatePdfForReportCardFailure,
  generatePdfForReportCardSuccess,
  sendMembershipHybridInvite,
  sendMembershipHybridInviteFailure,
  sendMembershipHybridInviteSuccess,
  wsMedicalHistoryEmailCreate,
  wsMedicalHistoryEmailCreateFailure,
  wsMedicalHistoryEmailCreateSuccess,
  wsMedicalHistoryPdfCreate,
  wsMedicalHistoryPdfCreateFailure,
  wsMedicalHistoryPdfCreateSuccess,
  wsReportCardEmailCreate,
  wsReportCardEmailCreateFailure,
  wsReportCardEmailCreateSuccess,
  wsReportCardPdfCreate,
  wsReportCardPdfCreateFailure,
  wsReportCardPdfCreateSuccess,
} from '../actions/communications'
import { updateConversations } from '../actions/conversations'
import {
  EMAIL_CHARGE_SHEET,
  EMAIL_INVOICE,
  EMAIL_INVOICE_V2,
  EMAIL_LAB_RESULT,
  EMAIL_MED_HISTORY,
  EMAIL_PAYMENT,
  EMAIL_PRESCRIPTION,
  EMAIL_REPORT_CARD,
  GENERATE_PDF_FOR_CHARGE_SHEET,
  GENERATE_PDF_FOR_INVOICE,
  GENERATE_PDF_FOR_INVOICE_V2,
  GENERATE_PDF_FOR_MEDICAL_HISTORY,
  GENERATE_PDF_FOR_PRESCRIPTION,
  GENERATE_PDF_FOR_REPORT_CARD,
  SEND_MEMBERSHIP_HYBRID_INVITE,
  WS_MEDICAL_HISTORY_EMAIL_CREATE,
  WS_MEDICAL_HISTORY_PDF_CREATE,
  WS_REPORT_CARD_EMAIL_CREATE,
  WS_REPORT_CARD_PDF_CREATE,
} from '../actions/types/communications'
import { getContact } from '../duck/contacts'
import { registerWarnAlert } from '../duck/uiAlerts'
import { getBusiness } from '../reducers/businesses'
import { getUser } from '../reducers/users'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* getConversationFromConversationOrigin(
  conversationId: string,
  recipientOrigin: EmailEntityConfigRecipient,
  transport?: ConversationTransport,
) {
  const contact: Contact = yield select(getContact(recipientOrigin.contactId))
  const client: User = yield select(getUser(recipientOrigin.clientId))
  const business: Business = yield select(
    getBusiness(recipientOrigin.businessId!),
  )

  const recipient = {
    ...recipientOrigin,
    ...getRecipientInfo({ client, contact, business, ...recipientOrigin }),
    type: recipientOrigin.recipientType,
  }
  return { id: conversationId, recipient, transport }
}

export function* updateConversationsWithNewConversationOrigins({
  ids,
  recipients,
  transport,
}: {
  ids: string[]
  recipients: EmailEntityConfigRecipient[]
  transport?: ConversationTransport
}) {
  const conversationsMap: Record<string, Conversation> = {}
  for (let i = 0; i < ids.length; i++) {
    const newConversation: Conversation = yield call(
      getConversationFromConversationOrigin,
      ids[i],
      recipients[i],
      transport,
    )
    conversationsMap[newConversation.id] = newConversation
  }
  yield put(updateConversations(conversationsMap))
}

export function* emailInvoiceSaga({
  emailData,
  params,
}: ReturnType<typeof emailInvoice>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailInvoice,
      emailData,
      params,
    )
    yield call(updateEntities, entities)
    yield put(emailInvoiceSuccess(conversationsList))
  } catch (error) {
    yield put(emailInvoiceFailure(error as ApiError))
  }
}

export function* emailInvoiceV2Saga({
  emailData,
}: ReturnType<typeof emailInvoiceV2>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailInvoiceV2,
      emailData,
    )
    yield call(updateEntities, entities)
    yield put(emailInvoiceSuccessV2(conversationsList))
  } catch (error) {
    yield put(emailInvoiceFailure(error as ApiError))
  }
}

export function* emaiChargeSheetSaga({
  emailData,
}: ReturnType<typeof emailChargeSheet>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailChargeSheet,
      emailData,
    )

    yield call(updateEntities, entities)
    yield put(emailChargeSheetSuccess(conversationsList))
  } catch (error) {
    yield put(emailChargeSheetFailure(error as ApiError))
  }
}

export function* emailPaymentSaga({
  emailData,
}: ReturnType<typeof emailPayment>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailPayment,
      emailData,
    )
    yield call(updateEntities, entities)
    yield put(emailPaymentSuccess(conversationsList))
  } catch (error) {
    yield put(emailPaymentFailure(error as ApiError))
  }
}

export function* sendMembershipHybridInviteSaga({
  messageData,
}: ReturnType<typeof sendMembershipHybridInvite>) {
  try {
    const result = yield* requestAPI(
      API.sendMembershipHybridInvite,
      messageData,
    )
    const { createdConversations: createdConversationIds = [] } = result
    yield call(updateConversationsWithNewConversationOrigins, {
      ids: createdConversationIds,
      ...messageData,
    })
    yield put(sendMembershipHybridInviteSuccess(createdConversationIds))
  } catch (error) {
    yield put(sendMembershipHybridInviteFailure(error as ApiError))
  }
}

export function* emailReportCardSaga({
  emailData,
}: ReturnType<typeof emailReportCard>) {
  try {
    yield* requestAPI(API.emailReportCard, emailData)
    yield put(emailReportCardSuccess([]))
  } catch (error) {
    yield put(emailReportCardFailure(error as ApiError))
  }
}

export function* emailPrescriptionSaga({
  emailData: emailDataProp,
}: ReturnType<typeof emailPrescription>) {
  const emailData = {
    ...emailDataProp,
    type: OrderType.PRESCRIPTION,
  }

  try {
    const { result: conversationsList, entities } = emailData?.soapId
      ? yield* requestAPI(API.emailPrescription, emailData)
      : yield* requestAPI(API.emailPrescriptionOutsideSoap, emailData)

    yield call(updateEntities, entities)
    yield put(emailPrescriptionSuccess(conversationsList))
  } catch (error) {
    yield put(emailPrescriptionFailure(error as ApiError))
  }
}

export function* emailLabResultSaga({
  emailData,
}: ReturnType<typeof emailLabResult>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailLabResult,
      emailData,
    )

    yield call(updateEntities, entities)
    yield put(emailLabResultSuccess(conversationsList))
  } catch (error) {
    yield put(emailLabResultFailure(error as ApiError))
  }
}

export function* emailMedHistorySaga({
  emailData,
}: ReturnType<typeof emailMedHistory>) {
  try {
    yield* requestAPI(API.emailMedHistory, emailData)
    yield put(emailMedHistorySuccess([]))
  } catch (error) {
    yield put(emailMedHistoryFailure(error as ApiError))
  }
}

export function* generatePdfForMedicalHistorySaga({
  emailData,
}: ReturnType<typeof generatePdfForMedicalHistory>) {
  try {
    yield* requestAPI(API.generatePdfForMedicalHistory, emailData)
    yield put(generatePdfForMedicalHistorySuccess())
  } catch (error) {
    yield put(generatePdfForMedicalHistoryFailure(error as ApiError))
  }
}

export function* generatePdfForReportCardSaga({
  emailData,
}: ReturnType<typeof generatePdfForReportCard>) {
  try {
    yield* requestAPI(API.generatePdfForReportCard, emailData)
    yield put(generatePdfForReportCardSuccess())
  } catch (error) {
    yield put(generatePdfForReportCardFailure(error as ApiError))
  }
}

export function* generatePdfForPrescriptionSaga({
  emailData,
}: ReturnType<typeof generatePdfForPrescription>) {
  try {
    const attachment: AttachmentOrigin = yield* requestAPI(
      API.generatePdfForPrescription,
      emailData,
    )

    const { name, extension, fileUrl } = attachment
    downloadFile({
      url: fileUrl,
      name,
      extension,
    })
    yield put(generatePdfForPrescriptionSuccess())
  } catch (error) {
    yield put(generatePdfForPrescriptionFailure(error as ApiError))
  }
}

export function* generatePdfForInvoiceSaga({
  emailData,
}: ReturnType<typeof generatePdfForInvoice>) {
  try {
    const { params, ...data } = emailData
    const result: AttachmentOrigin = yield* requestAPI(
      API.generatePdfForInvoice,
      data,
      params,
    )

    const { name, extension, fileUrl } = result
    downloadFile({
      url: fileUrl,
      name,
      extension,
    })
    yield put(generatePdfForInvoiceSuccess())
  } catch (error) {
    yield put(generatePdfForInvoiceFailure(error as ApiError))
  }
}

export function* generatePdfForInvoiceSagaV2({
  data,
}: ReturnType<typeof generatePdfForInvoiceV2>) {
  try {
    const result: AttachmentOrigin = yield* requestAPI(
      API.generatePdfForInvoiceV2,
      { ...data },
    )

    const { name, extension, fileUrl } = result
    downloadFile({
      url: fileUrl,
      name,
      extension,
    })
    yield put(generatePdfForInvoiceSuccess())
  } catch (error) {
    yield put(generatePdfForInvoiceFailure(error as ApiError))
  }
}

export function* generatePdfForChargeSheetSaga({
  emailData,
}: ReturnType<typeof generatePdfForChargeSheet>) {
  try {
    const result: AttachmentOrigin = yield* requestAPI(
      API.generatePdfForChargeSheet,
      emailData.clientId,
      emailData.expandedGroups,
    )

    const { name, extension, fileUrl } = result

    downloadFile({
      url: fileUrl,
      name,
      extension,
    })
    yield put(generatePdfForChargeSheetSuccess())
  } catch (error) {
    yield put(generatePdfForChargeSheetFailure(error as ApiError))
  }
}

export function* wsReportCardPdfCreateSaga({
  body,
}: ReturnType<typeof wsReportCardPdfCreate>) {
  const { errorMessage } = body
  if (!errorMessage) {
    const { name, extension, fileUrl } = body
    downloadFile({
      url: fileUrl,
      name,
      extension,
    })
    yield put(wsReportCardPdfCreateSuccess())
  } else {
    yield put(registerWarnAlert(errorMessage))
    yield put(wsReportCardPdfCreateFailure(errorMessage))
  }
}

export function* wsReportCardEmailCreateSaga({
  body,
}: ReturnType<typeof wsReportCardEmailCreate>) {
  const { errorMessage } = body
  if (!errorMessage) {
    yield put(wsReportCardEmailCreateSuccess())
  } else {
    yield put(registerWarnAlert(errorMessage))
    yield put(wsReportCardEmailCreateFailure(errorMessage))
  }
}

export function* wsMedicalHistoryPdfCreateSaga({
  body,
}: ReturnType<typeof wsMedicalHistoryPdfCreate>) {
  const { errorMessage } = body
  if (!errorMessage) {
    const { name, extension, fileUrl } = body
    downloadFile({
      url: fileUrl,
      name,
      extension,
    })
    yield put(wsMedicalHistoryPdfCreateSuccess())
  } else {
    yield put(registerWarnAlert(errorMessage))
    yield put(wsMedicalHistoryPdfCreateFailure(errorMessage))
  }
}

export function* wsMedicalHistoryEmailCreateSaga({
  body,
}: ReturnType<typeof wsMedicalHistoryEmailCreate>) {
  const { errorMessage } = body
  if (!errorMessage) {
    yield put(wsMedicalHistoryEmailCreateSuccess())
  } else {
    yield put(registerWarnAlert(errorMessage))
    yield put(wsMedicalHistoryEmailCreateFailure(errorMessage))
  }
}

function* watchEmailInvoice() {
  yield takeLeading(EMAIL_INVOICE, emailInvoiceSaga)
}

function* watchEmailInvoiceV2() {
  yield takeLeading(EMAIL_INVOICE_V2, emailInvoiceV2Saga)
}

function* watchEmailChargeSheet() {
  yield takeLeading(EMAIL_CHARGE_SHEET, emaiChargeSheetSaga)
}

function* watchEmailReportCard() {
  yield takeLeading(EMAIL_REPORT_CARD, emailReportCardSaga)
}

function* watchEmailPayment() {
  yield takeLeading(EMAIL_PAYMENT, emailPaymentSaga)
}

function* watchEmailPrescription() {
  yield takeLeading(EMAIL_PRESCRIPTION, emailPrescriptionSaga)
}

function* watchEmailLabResult() {
  yield takeLeading(EMAIL_LAB_RESULT, emailLabResultSaga)
}

function* watchEmailMedHistory() {
  yield takeLeading(EMAIL_MED_HISTORY, emailMedHistorySaga)
}

function* watchSendMembershipHybridInvite() {
  yield takeLeading(
    SEND_MEMBERSHIP_HYBRID_INVITE,
    sendMembershipHybridInviteSaga,
  )
}

function* watchGeneratePdfForMedicalhistory() {
  yield takeLeading(
    GENERATE_PDF_FOR_MEDICAL_HISTORY,
    generatePdfForMedicalHistorySaga,
  )
}

function* watchGeneratePdfForReportCard() {
  yield takeLeading(GENERATE_PDF_FOR_REPORT_CARD, generatePdfForReportCardSaga)
}

function* watchGeneratePdfForPrescription() {
  yield takeLeading(
    GENERATE_PDF_FOR_PRESCRIPTION,
    generatePdfForPrescriptionSaga,
  )
}

function* watchGeneratePdfForInvoice() {
  yield takeLeading(GENERATE_PDF_FOR_INVOICE, generatePdfForInvoiceSaga)
}

function* watchGeneratePdfForInvoiceV2() {
  yield takeLeading(GENERATE_PDF_FOR_INVOICE_V2, generatePdfForInvoiceSagaV2)
}

function* watchGeneratePdfForChargeSheet() {
  yield takeLeading(
    GENERATE_PDF_FOR_CHARGE_SHEET,
    generatePdfForChargeSheetSaga,
  )
}

function* watchWsReportCardPdfCreate() {
  yield takeLeading(WS_REPORT_CARD_PDF_CREATE, wsReportCardPdfCreateSaga)
}

function* watchWsReportCardEmailCreate() {
  yield takeLeading(WS_REPORT_CARD_EMAIL_CREATE, wsReportCardEmailCreateSaga)
}

function* watchWsMedicalHistoryPdfCreate() {
  yield takeLeading(
    WS_MEDICAL_HISTORY_PDF_CREATE,
    wsMedicalHistoryPdfCreateSaga,
  )
}

function* watchWsMedicalHistoryEmailCreate() {
  yield takeLeading(
    WS_MEDICAL_HISTORY_EMAIL_CREATE,
    wsMedicalHistoryEmailCreateSaga,
  )
}

export default function* communicationsSaga() {
  yield all([
    watchEmailInvoice(),
    watchEmailInvoiceV2(),
    watchEmailChargeSheet(),
    watchEmailReportCard(),
    watchEmailPayment(),
    watchEmailPrescription(),
    watchEmailLabResult(),
    watchEmailMedHistory(),
    watchSendMembershipHybridInvite(),
    watchGeneratePdfForMedicalhistory(),
    watchGeneratePdfForReportCard(),
    watchGeneratePdfForInvoice(),
    watchGeneratePdfForInvoiceV2(),
    watchGeneratePdfForChargeSheet(),
    watchGeneratePdfForPrescription(),
    watchWsReportCardPdfCreate(),
    watchWsReportCardEmailCreate(),
    watchWsMedicalHistoryPdfCreate(),
    watchWsMedicalHistoryEmailCreate(),
  ])
}
