import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Shadow, Text } from '@pbt/pbt-ui-components'
import { getIsScrollSmoothSupported } from '@pbt/pbt-ui-components/src/utils/browserUtils'

const isScrollSmoothSupported = getIsScrollSmoothSupported()

const useStyles = makeStyles(
  () => ({
    content: {
      minHeight: 'calc(100% - 108px)',
      overflow: 'visible',
      position: 'relative',
      scrollBehavior: isScrollSmoothSupported ? 'smooth' : undefined,
    },
    rowContent: {
      flexDirection: 'column',
    },
    noPointerEvents: {
      pointerEvents: 'none',
    },
  }),
  { name: 'Timetable' },
)

export interface TimetableProps {
  HeaderComponent: React.JSXElementConstructor<any>
  HeaderProps?: any
  additionalRefs?: React.RefObject<HTMLDivElement>[]
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  columns?: any[]
  contentRef?: React.RefObject<HTMLDivElement>
  isLoading?: boolean
  openShadow?: boolean
  row?: boolean
}

const Timetable = ({
  classes: classesProp,
  row,
  HeaderComponent,
  HeaderProps,
  openShadow = false,
  columns,
  children,
  additionalRefs,
  contentRef,
  isLoading,
}: TimetableProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  return (
    <>
      <HeaderComponent
        additionalRefs={additionalRefs}
        columns={columns || []}
        contentRef={contentRef}
        openShadow={openShadow}
        {...(HeaderProps || {})}
      />
      <Grid
        container
        item
        className={classNames(classes.content, {
          [classes.rowContent]: row,
        })}
        ref={contentRef}
        wrap="nowrap"
      >
        {isLoading ? (
          <Grid
            container
            item
            xs
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <CircularProgress />
            <Text mt={1} variant="body2">
              {t('Common:LOADING')}
            </Text>
          </Grid>
        ) : (
          children
        )}
      </Grid>
      <Shadow className={classes.noPointerEvents} open={openShadow} />
    </>
  )
}

export default Timetable
