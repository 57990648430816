import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { BasePuiDialogProps, Defaults, Utils } from '@pbt/pbt-ui-components'

import EmailDialogWithSections, {
  SuccessDialogKind,
} from '~/components/common/dialog/email/EmailDialogWithSections'
import { GeneratingPdfContentKind } from '~/constants/communications'
import { emailMedHistory } from '~/store/actions/communications'
import {
  clearMedicalHistoryCardConfig,
  clearMedicalHistoryCardPreviewTemplate,
  fetchMedicalHistoryCardConfig,
  fetchMedicalHistoryCardPreviewTemplate,
  getMedicalHistoryCardConfig,
  getMedicalHistoryCardConfigIsLoading,
  getMedicalHistoryCardIsSendingEmail,
  getMedicalHistoryCardPreviewTemplate,
  getMedicalHistoryCardTemplateIsReceiving,
} from '~/store/duck/medicalHistoryCard'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { EmailEntityConfig, MedicalHistoryCardConfig } from '~/types'

interface MedicalHistoryCardDialogProps extends BasePuiDialogProps {
  clientId: string
  patientId: string
}

const MedicalHistoryCardDialog = ({
  open,
  onClose,
  patientId,
  clientId,
}: MedicalHistoryCardDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const config = useSelector(getMedicalHistoryCardConfig)
  const configIsLoading = useSelector(getMedicalHistoryCardConfigIsLoading)
  const previewTemplate = useSelector(getMedicalHistoryCardPreviewTemplate)
  const templateIsReceiving = useSelector(
    getMedicalHistoryCardTemplateIsReceiving,
  )
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  useEffect(() => {
    if (open) {
      dispatch(fetchMedicalHistoryCardConfig(patientId))
    }
  }, [open])

  const handleClose = () => {
    dispatch(clearMedicalHistoryCardPreviewTemplate())
    dispatch(clearMedicalHistoryCardConfig())
    if (onClose) {
      onClose()
    }
  }

  const fetchPreviewTemplate = (
    historyCardConfig: MedicalHistoryCardConfig,
  ) => {
    if (open && historyCardConfig?.sections) {
      dispatch(
        fetchMedicalHistoryCardPreviewTemplate(historyCardConfig, patientId),
      )
    }
  }

  const handleDateRangeChange = (
    historyCardConfig: MedicalHistoryCardConfig,
  ) => {
    const { from, to } = historyCardConfig
    dispatch(fetchMedicalHistoryCardConfig(patientId, from, to))
  }

  const fetchPreviewTemplateDebounced = useDebouncedCallback(
    fetchPreviewTemplate,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const handleSendEmail = (entityConfig: EmailEntityConfig) => {
    dispatch(emailMedHistory({ patientId, ...entityConfig }))
  }

  const handlePrint = (historyCardConfig: MedicalHistoryCardConfig) => {
    dispatch(
      fetchMedicalHistoryCardPreviewTemplate(historyCardConfig, patientId),
    )
  }

  const pdfFileConfig = { patientId }

  return (
    <EmailDialogWithSections
      cardEntityName={t(
        'Dialogs:MEDICAL_HISTORY_CARD_DIALOG.TITLE',
      ).toLowerCase()}
      clientId={clientId}
      config={config}
      configIsLoading={configIsLoading}
      defaultDateRangeLabel={t('Common:ENTIRE_HISTORY')}
      defaultSubject={t('Dialogs:MEDICAL_HISTORY_CARD_DIALOG.DEFAULT_SUBJECT', {
        patientName: patient?.name || '',
      })}
      dialogHeader={t('Dialogs:MEDICAL_HISTORY_CARD_DIALOG.HEADER')}
      emailTemplate={previewTemplate}
      isSendingEmailSelector={getMedicalHistoryCardIsSendingEmail}
      open={open}
      patient={patient}
      pdfContentKind={GeneratingPdfContentKind.MEDICAL_HISTORY}
      pdfFileConfig={pdfFileConfig}
      popupEmailSentMessageHandler={({ mailTo }) =>
        t('Dialogs:MEDICAL_HISTORY_CARD_DIALOG.EMAIL_SENT_MESSAGE', {
          patientName: patient?.name || t('Common:PATIENT'),
          clientName: Utils.getPersonString(client),
          mailTo: mailTo || client?.email,
        })
      }
      printTemplate={previewTemplate}
      successDialogKind={SuccessDialogKind.POPUP}
      templateIsReceiving={templateIsReceiving}
      onChange={fetchPreviewTemplateDebounced}
      onClearPrint={clearMedicalHistoryCardPreviewTemplate}
      onClose={handleClose}
      onMedicalHistoryDateRangeChange={handleDateRangeChange}
      onPrint={handlePrint}
      onSendEmail={handleSendEmail}
    />
  )
}

export default MedicalHistoryCardDialog
