import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { PermissionArea, Text } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { BasePracticeDetailsSectionProps } from '~/types'

import ContinuousMigration from './continuousMigration/ContinuousMigration'
import Mappings from './migration/Mappings'
import RhapsodyMigration from './migration/RhapsodyMigration'
import CatalogAndSettings from './practiceCloning/CatalogAndSettings'

const MigrationSection = ({
  business,
  isRhapsodyAnalytics = false,
}: BasePracticeDetailsSectionProps & { isRhapsodyAnalytics: boolean }) => {
  const { t } = useTranslation('Businesses')
  const businessId = business.id
  const permissions = useSelector(getCRUDByArea(PermissionArea.IMPORT))

  return (
    <Grid>
      {!isRhapsodyAnalytics && (
        <Grid container direction="column" pl={6}>
          <Text mb={1} ml={-3} variant="h4">
            {t('Businesses:MIGRATION.RHAPSODY_MIGRATION')}
          </Text>
          <RhapsodyMigration businessId={businessId} />
        </Grid>
      )}
      {permissions.create && (
        <Grid container direction="column" mt={2} pl={6}>
          <Text mb={1} ml={-3} variant="h4">
            {t('Businesses:MIGRATION.CONTINUOUS_MIGRATION')}
          </Text>
          <ContinuousMigration businessId={businessId} />
        </Grid>
      )}
      {!isRhapsodyAnalytics && (
        <Grid container direction="column" mt={2} pl={6}>
          <Text mb={1} ml={-3} variant="h4">
            {t('Businesses:MIGRATION.PRACTICE_CATALOG_AND_SETTINGS')}
          </Text>
          <CatalogAndSettings businessId={businessId} />
        </Grid>
      )}
      {permissions.read && !isRhapsodyAnalytics && (
        <Grid container direction="column" mt={2} pl={6}>
          <Text mb={1} ml={-3} variant="h4">
            {t('Businesses:MIGRATION.RELATIONS_MAPPINGS')}
          </Text>
          <Mappings businessId={businessId} />
        </Grid>
      )}
    </Grid>
  )
}

export default memo(MigrationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
