import Chewy from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/chewy/Chewy'

import AlertConfiguration from './PracticeDetailsSections/alert-configuration/AlertConfiguration'
import AppointmentCommunicationsConfig from './PracticeDetailsSections/appointment-communications/AppointmentCommunicationsConfig'
import AppointmentConfiguration from './PracticeDetailsSections/appointment-configuration/AppointmentConfiguration'
import { AutoReplyCommunications } from './PracticeDetailsSections/auto-reply-communications/AutoReplyCommunications'
import BoopCommunicationsConfig from './PracticeDetailsSections/boop-communications/BoopCommunicationsConfig'
import BrandingConfiguration from './PracticeDetailsSections/branding-configuration/BrandingConfiguration'
import CountrySection from './PracticeDetailsSections/CountrySection'
import DiscountConfiguration from './PracticeDetailsSections/discount-configuration/DiscountConfiguration'
import DiscountReasonConfiguration from './PracticeDetailsSections/discount-reason-configuration/DiscountReasonConfiguration'
import PracticeGeneralInformation from './PracticeDetailsSections/general-information/PracticeGeneralInformation'
import GroupDetailsConfigurationSection from './PracticeDetailsSections/group-details/GroupDetailsConfigurationSection'
import GroupRolesAutoAssignSection from './PracticeDetailsSections/GroupRolesAutoAssignSection'
import ImagingIntegrations from './PracticeDetailsSections/integration/imaging/ImagingIntegrations'
import LabIntegrations from './PracticeDetailsSections/integration/labs/LabIntegrations'
import InternalChatIntegration from './PracticeDetailsSections/internal-chat-integration/InternalChatIntegration'
import InvoiceConfigurationSection from './PracticeDetailsSections/invoice/InvoiceConfigurationSection'
import KioskConfiguration from './PracticeDetailsSections/kiosk-configuration/KioskConfiguration'
import LabelsConfiguration from './PracticeDetailsSections/labelPrinting/LabelsConfiguration'
import LicensesSection from './PracticeDetailsSections/licenses/LicensesSection'
import LocalizationsSection from './PracticeDetailsSections/localizations/LocalizationSection'
import MarketplaceManagementSection from './PracticeDetailsSections/MarketplaceManagementSection'
import MigrationSection from './PracticeDetailsSections/migrations/MigrationSection'
import MigrationSectionV2 from './PracticeDetailsSections/migrations/MigrationSectionV2'
import MobileSection from './PracticeDetailsSections/mobile/MobileSection'
import NPSSystem from './PracticeDetailsSections/nps-system/NpsSystem'
import RecordSharingConfigurationSection from './PracticeDetailsSections/record-sharing/RecordSharingConfigurationSection'
import ReminderSetUpSection from './PracticeDetailsSections/reminderCommunication/ReminderSetUpSection'
import RhapsodyPaySection from './PracticeDetailsSections/rhapsodyPay/RhapsodyPaySection'
import SavedSignatureConfigurationSection from './PracticeDetailsSections/saved-signature-configuration/SavedSignatureConfigurationSection'
import { SharingSection } from './PracticeDetailsSections/SharingSection'
import SoapConfigurationSection from './PracticeDetailsSections/soap/SoapConfigurationSection'
import SocialMediaSection from './PracticeDetailsSections/socialMedia/SocialMediaSection'
import SsoConfigurationSection from './PracticeDetailsSections/SsoConfigurationSection'
import TaxConfigurationSection from './PracticeDetailsSections/tax-configuration/TaxConfigurationSection'
import TeamConfigurationSection from './PracticeDetailsSections/team-configuration/TeamConfigurationSection'
import TelehealthIntegration from './PracticeDetailsSections/TelehealthIntegration'
import UnitsOfMeasurementSection from './PracticeDetailsSections/units-of-measurement/UnitsOfMeasurementSection'
import DepartmentSection from './PracticeDetailsSections/user-department/DepartmentSection'
import VaccineCertificateFootersSection from './PracticeDetailsSections/vaccine-certificate-footers/VaccineCertificateFootersSection'
import VetcoveIntegration from './PracticeDetailsSections/vetcove/VetcoveIntegration'
import WellnessPlanActivation from './PracticeDetailsSections/wellness-plans/WellnessPlanActivation'
import { PracticeDetailsPanels } from './practices'

export const PracticeDetailsPanelsComponents: Record<
  Exclude<PracticeDetailsPanels, PracticeDetailsPanels.MAIN>,
  React.JSXElementConstructor<any>
> = {
  [PracticeDetailsPanels.GENERAL_INFORMATION]: PracticeGeneralInformation,
  [PracticeDetailsPanels.ALERT_CONFIGURATION]: AlertConfiguration,
  [PracticeDetailsPanels.APPOINTMENT_COMMUNICATIONS]:
    AppointmentCommunicationsConfig,
  [PracticeDetailsPanels.APPOINTMENT_CONFIGURATION]: AppointmentConfiguration,
  [PracticeDetailsPanels.AUTO_REPLY_COMMUNICATIONS]: AutoReplyCommunications,
  [PracticeDetailsPanels.BOOP_COMMUNICATIONS]: BoopCommunicationsConfig,
  [PracticeDetailsPanels.BRANDING_CONFIGURATION]: BrandingConfiguration,
  [PracticeDetailsPanels.CHEWY]: Chewy,
  [PracticeDetailsPanels.COUNTRY_SECTION]: CountrySection,
  [PracticeDetailsPanels.DEPARTMENT]: DepartmentSection,
  [PracticeDetailsPanels.DISCOUNT_CONFIGURATION]: DiscountConfiguration,
  [PracticeDetailsPanels.DISCOUNT_REASON_CONFIGURATION]:
    DiscountReasonConfiguration,
  [PracticeDetailsPanels.GROUP_ACCESS_ROLES]: GroupRolesAutoAssignSection,
  [PracticeDetailsPanels.GROUP_DETAILS]: GroupDetailsConfigurationSection,
  [PracticeDetailsPanels.IMAGING_INTEGRATIONS]: ImagingIntegrations,
  [PracticeDetailsPanels.INTERNAL_CHAT_AND_LOGS]: InternalChatIntegration,
  [PracticeDetailsPanels.INVOICE_ESTIMATE_CONFIGURATION]:
    InvoiceConfigurationSection,
  [PracticeDetailsPanels.KIOSK]: KioskConfiguration,
  [PracticeDetailsPanels.LAB_INTEGRATIONS]: LabIntegrations,
  [PracticeDetailsPanels.LABEL_CONFIGURATION]: LabelsConfiguration,
  [PracticeDetailsPanels.LICENSES]: LicensesSection,
  [PracticeDetailsPanels.LOCALIZATIONS]: LocalizationsSection,
  [PracticeDetailsPanels.MARKETPLACE_MANAGEMENT]: MarketplaceManagementSection,
  [PracticeDetailsPanels.MIGRATION]: MigrationSection,
  [PracticeDetailsPanels.MIGRATION_V2]: MigrationSectionV2,
  [PracticeDetailsPanels.MOBILE]: MobileSection,
  [PracticeDetailsPanels.NPS_SYSTEM]: NPSSystem,
  [PracticeDetailsPanels.RECORD_SHARING]: RecordSharingConfigurationSection,
  [PracticeDetailsPanels.REMINDER_SET_UP]: ReminderSetUpSection,
  [PracticeDetailsPanels.RHAPSODY_PAY]: RhapsodyPaySection,
  [PracticeDetailsPanels.SAVED_SIGNATURE_CONFIGURATION]:
    SavedSignatureConfigurationSection,
  [PracticeDetailsPanels.SHARING]: SharingSection,
  [PracticeDetailsPanels.SOAP_CONFIGURATION]: SoapConfigurationSection,
  [PracticeDetailsPanels.SOCIAL_MEDIA]: SocialMediaSection,
  [PracticeDetailsPanels.SSO_CONFIGURATION]: SsoConfigurationSection,
  [PracticeDetailsPanels.TAX_CONFIGURATION]: TaxConfigurationSection,
  [PracticeDetailsPanels.TEAM_CONFIGURATION]: TeamConfigurationSection,
  [PracticeDetailsPanels.TELEHEALTH_INTEGRATION]: TelehealthIntegration,
  [PracticeDetailsPanels.UNITS_OF_MEASUREMENT]: UnitsOfMeasurementSection,
  [PracticeDetailsPanels.VACCINE_CERTIFICATE_FOOTERS]:
    VaccineCertificateFootersSection,
  [PracticeDetailsPanels.VETCOVE_INTEGRATION]: VetcoveIntegration,
  [PracticeDetailsPanels.WELLNESS_PLANS]: WellnessPlanActivation,
}
