import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DateUtils } from '@pbt/pbt-ui-components'

import { RetailPaymentDetail } from '~/api/graphql/generated/types'
import Typography from '~/components/elements/Typography/Typography'

import { formatMoneyRange } from './invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.totalBorder,
      },
    },
    leftColumn: {
      borderRight: theme.constants.totalBorder,
    },
  }),
  { name: 'ChewyPaymentTile' },
)

interface ChewyPaymentTileProps {
  clientFullName: string
  orderDate: string
  payment: RetailPaymentDetail
}

export const ChewyPaymentTile = ({
  clientFullName,
  orderDate,
  payment,
}: ChewyPaymentTileProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const { amountPaid, paymentMethod } = payment
  const paymentTypeName = paymentMethod.__typename
  const paymentType =
    paymentTypeName === 'CardPaymentMethod'
      ? `${paymentMethod.paymentCardType.name} ${paymentMethod.lastFour}`
      : paymentTypeName === 'ChewyAccountBalancePaymentMethod'
        ? t('Common:CHEWY_ACCOUNT')
        : paymentTypeName === 'PayPalPaymentMethod'
          ? t('Common:PAY_PAL')
          : ''

  return (
    <Grid container item alignItems="center" className={classes.row}>
      <Grid
        item
        alignItems="flex-start"
        className={classes.leftColumn}
        flexDirection="column"
        pl={3}
        xs={8}
      >
        <Typography.H3>
          <span>
            <Trans
              i18nKey="Invoices:CHEWY_PAYMENT.PAID_BY"
              values={{ clientFullName }}
            />
          </span>
        </Typography.H3>
        <Typography.Paragraph>
          {`${DateUtils.formatDate(orderDate)} | ${paymentType}`}
        </Typography.Paragraph>
      </Grid>
      <Grid item pr={3} xs={4}>
        <Typography.H3 sx={{ textAlign: 'right' }}>
          {formatMoneyRange(amountPaid, true)}
        </Typography.H3>
      </Grid>
    </Grid>
  )
}
