import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { ForwardArrow } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    control: {
      border: theme.constants.tabBorder,
      cursor: 'pointer',
    },
  }),
  { name: 'SummaryPartWidget' },
)

export interface SummaryPartWidgetProps {
  amount: string
  onClick?: () => void
  title: string
}

export const SummaryPartWidget = ({
  amount,
  title,
  onClick,
}: SummaryPartWidgetProps) => {
  const classes = useStyles()
  return (
    <Box
      className={classes.control}
      display="flex"
      flex={1}
      flexDirection="column"
      p={2}
      onClick={onClick}
    >
      <Box>
        <Text strong variant="body2">
          {title}
        </Text>
      </Box>
      <Box display="flex">
        <Text strong variant="h3">
          {amount}
        </Text>
        <ForwardArrow style={{ marginTop: '0.25rem' }} />
      </Box>
    </Box>
  )
}
