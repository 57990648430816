import {
  all,
  call,
  delay,
  put,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  createQuestion,
  createQuestionFailure,
  createQuestionSuccess,
  deleteQuestion,
  deleteQuestionFailure,
  deleteQuestionSuccess,
  editQuestion,
  editQuestionFailure,
  editQuestionSuccess,
  fetchMoreItemsForQuestionsList,
  fetchMoreItemsForQuestionsListFailure,
  fetchMoreItemsForQuestionsListSuccess,
  fetchQuestion,
  fetchQuestionFailure,
  fetchQuestionsForSoap,
  fetchQuestionsForSoapFailure,
  fetchQuestionsForSoapSuccess,
  fetchQuestionsList,
  fetchQuestionsListFailure,
  fetchQuestionsListSuccess,
  fetchQuestionSuccess,
  questionValidationError,
  saveQuestionsForSoap,
  saveQuestionsForSoapFailure,
  saveQuestionsForSoapSuccess,
} from '../actions/questions'
import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  EDIT_QUESTION,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST,
  FETCH_QUESTION,
  FETCH_QUESTIONS_FOR_SOAP,
  FETCH_QUESTIONS_LIST,
  SAVE_QUESTIONS_FOR_SOAP,
} from '../actions/types/questions'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchQuestionsListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchQuestionsList>) {
  try {
    yield put(startLoading('questionsList'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchQuestions, from, to, query)
    yield call(updateEntities, entities)
    yield put(fetchQuestionsListSuccess(list, totalCount))
    yield put(finishLoading('questionsList'))
  } catch (error) {
    yield put(fetchQuestionsListFailure(error as ApiError))
    yield put(finishLoading('questionsList'))
  }
}

export function* fetchMoreItemsForQuestionsListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForQuestionsList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchQuestions, from, to, query)
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForQuestionsListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForQuestionsListFailure(error as ApiError))
  }
}

export function* fetchQuestionSaga({ id }: ReturnType<typeof fetchQuestion>) {
  try {
    const { entities } = yield* requestAPI(API.fetchQuestion, id)
    yield call(updateEntities, entities)
    yield put(fetchQuestionSuccess())
  } catch (error) {
    yield put(fetchQuestionFailure(error as ApiError))
  }
}

export function* fetchQuestionsForSoapSaga({
  soapId,
}: ReturnType<typeof fetchQuestionsForSoap>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchQuestionsForSoap,
      soapId,
    )
    yield call(updateEntities, entities)
    yield put(fetchQuestionsForSoapSuccess(soapId, result))
  } catch (error) {
    yield put(fetchQuestionsForSoapFailure(error as ApiError))
  }
}

export function* saveQuestionsForSoapSaga({
  soapId,
  log,
}: ReturnType<typeof saveQuestionsForSoap>) {
  yield delay(Defaults.DEBOUNCE_ACTION_TIME)
  try {
    const { result, entities } = yield* requestAPI(
      API.saveQuestionsForSoap,
      soapId,
      log,
    )
    yield call(updateEntities, entities)
    yield put(fetchTimelineAction())
    yield put(saveQuestionsForSoapSuccess(soapId, result))
  } catch (error) {
    yield put(saveQuestionsForSoapFailure(error as ApiError))
  }
}

export function* createQuestionSaga({
  question,
}: ReturnType<typeof createQuestion>) {
  try {
    const { result, entities } = yield* requestAPI(API.createQuestion, question)
    yield call(updateEntities, entities)
    yield put(fetchQuestionsList())
    yield put(createQuestionSuccess(result))
  } catch (error) {
    yield put(createQuestionFailure(error as ApiError))
  }
}

export function* editQuestionSaga({
  question,
}: ReturnType<typeof editQuestion>) {
  try {
    const { entities } = yield* requestAPI(API.editQuestion, question)
    yield call(updateEntities, entities)
    yield put(editQuestionSuccess())
  } catch (error) {
    if ((error as ApiError).status === 400) {
      yield put(questionValidationError(error as ApiError))
    } else {
      yield put(editQuestionFailure(error as ApiError))
    }
  }
}

export function* deleteQuestionSaga({
  questionId,
}: ReturnType<typeof deleteQuestion>) {
  try {
    yield* requestAPI(API.deleteQuestion, questionId)
    yield put(deleteQuestionSuccess(questionId))
  } catch (error) {
    yield put(deleteQuestionFailure(error as ApiError))
  }
}

function* watchFetchQuestionsList() {
  yield takeLeading(FETCH_QUESTIONS_LIST, fetchQuestionsListSaga)
}

function* watchFetchMoreItemsForQuestionsList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST,
    fetchMoreItemsForQuestionsListSaga,
  )
}

function* watchFetchQuestion() {
  yield takeLeading(FETCH_QUESTION, fetchQuestionSaga)
}

function* watchFetchQuestionsForSoap() {
  yield takeLeading(FETCH_QUESTIONS_FOR_SOAP, fetchQuestionsForSoapSaga)
}

function* watchSaveQuestionsForSoap() {
  yield takeLatest(SAVE_QUESTIONS_FOR_SOAP, saveQuestionsForSoapSaga)
}

function* watchCreateQuestion() {
  yield takeLeading(CREATE_QUESTION, createQuestionSaga)
}

function* watchEditQuestion() {
  yield takeLeading(EDIT_QUESTION, editQuestionSaga)
}

function* watchDeleteQuestion() {
  yield takeLeading(DELETE_QUESTION, deleteQuestionSaga)
}

export default function* questionsSaga() {
  yield all([
    watchFetchQuestionsList(),
    watchFetchMoreItemsForQuestionsList(),
    watchFetchQuestion(),
    watchFetchQuestionsForSoap(),
    watchSaveQuestionsForSoap(),
    watchCreateQuestion(),
    watchEditQuestion(),
    watchDeleteQuestion(),
  ])
}
