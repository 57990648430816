import { Patient } from '@pbt/pbt-ui-components'

import { requestQuery } from '~/api/graphql/graphqlCore'
import { FETCH_PATIENT } from '~/api/graphql/queries/patients'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchPatient = requestQuery({
  query: FETCH_PATIENT,
  variablesHandler: (businessId, patientId: string) => ({
    businessId,
    patientId,
  }),
})

export const updatePatientParent = (
  businessId: string,
  newClientId: string,
  patientId: string,
) =>
  request(
    getAPIPath(`clients/${newClientId}/patient/${patientId}/assign`),
    {
      method: 'PUT',
      params: { businessId, newClientId, patientId },
    },
    true,
    Schema.user,
  )

export const createPatient = (
  businessId: string,
  clientId: string,
  patient: Partial<Patient>,
) =>
  request(
    getAPIPath(`clients/${clientId}/patients`),
    { method: 'POST', data: patient, params: { businessId } },
    true,
    Schema.patient,
  )

export const editPatient = (
  businessId: string,
  clientId: string,
  patient: Patient,
) =>
  request(
    getAPIPath(`clients/${clientId}/patients/${patient.id}`),
    {
      method: 'PUT',
      data: patient,
      params: { businessId, modificationDateCheck: patient?.modificationDate },
    },
    true,
    Schema.patient,
  )
