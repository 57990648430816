import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import { PermissionArea } from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import { deleteSpace, editSpace, fetchSpace } from '~/store/actions/spaces'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getSpace,
  getSpacesIsDeleting,
  getSpacesIsFetching,
  getSpacesIsLoading,
} from '~/store/reducers/spaces'
import { Space as SpaceType } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import Space, { SpaceHandle } from './Space'

export interface SpaceDetailsProps {
  itemId: string
  onClose: () => void
}

const SpaceDetails = ({ itemId, onClose }: SpaceDetailsProps) => {
  const dispatch = useDispatch()
  const space = useSelector(getSpace(itemId))
  const isLoading = useSelector(getSpacesIsLoading)
  const isFetching = useSelector(getSpacesIsFetching)
  const isDeleting = useSelector(getSpacesIsDeleting)
  const permissions = useSelector(getCRUDByArea(PermissionArea.SPACE))
  const { t } = useTranslation(['Common'])

  const [isSpaceChanged, setIsSpaceChanged] = useState(false)

  const updateDisabled = !permissions.update

  const [openSpaceDialog] = useDialog(DialogNames.SPACE)

  const setCloseOnDelete = useCloseAfterCreation(onClose, getSpacesIsDeleting)

  const spaceRef = useRef<SpaceHandle>(null)

  useEffect(() => {
    dispatch(fetchSpace(itemId))
  }, [itemId])

  const getNewSpace = () => spaceRef.current && spaceRef.current.get()

  const getUnsavedData = () => {
    const newSpace = getNewSpace()
    return Boolean(space) && !R.isEmpty(space) && !deepEqual(space, newSpace)
  }

  const onSaveRequested = () => {
    if (spaceRef.current && spaceRef.current.validate() && isSpaceChanged) {
      const newSpace = getNewSpace()

      dispatch(editSpace(newSpace))
    }
  }

  const onDeleteRequested = () => {
    setCloseOnDelete()
    dispatch(deleteSpace(itemId))
  }

  const onCloneRequested = () => {
    openSpaceDialog({
      space: { ...space, name: `${space?.name}_copy` } as SpaceType,
    })
  }

  const onChange = () => {
    setIsSpaceChanged(getUnsavedData())
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:SPACE_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isSpaceChanged}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isLoading}
      showButtons={!updateDisabled}
      onBack={onClose}
      onCloneRequested={onCloneRequested}
      onDeleteRequested={onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <Space view ref={spaceRef} space={space} onChange={onChange} />
    </Expander>
  )
}

export default SpaceDetails
