import { Reminder } from '~/types'

const CHARGE_ITEM_HEIGHT = 36.5
const VIEWPORT_ITEMS = 10

const getMinViewportHeight = (reminders: Reminder[]) => {
  if (reminders.length < VIEWPORT_ITEMS) {
    if (
      reminders.some(
        (reminder) => (reminder.soapChargeOrderItems ?? []).length >= 2,
      )
    ) {
      const chargeItemsCount = reminders
        .map((orderItem) => orderItem.soapChargeOrderItems?.length)
        .filter(Boolean)
        .reduce((acc: number, c) => {
          if (c) {
            acc += c
          }
          return acc
        }, 0)
      return Math.min(300, chargeItemsCount * CHARGE_ITEM_HEIGHT)
    }
  }

  return undefined
}

export const getMinReminderWidgetViewportHeight = (reminders: Reminder[]) => {
  const viewportHeight = getMinViewportHeight(reminders)
  const viewportItems = VIEWPORT_ITEMS

  return [viewportHeight, viewportItems]
}
