import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil, Text, TextProps, Utils } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { LabTestDashboardItem } from '~/types'
import useDialog from '~/utils/useDialog'

export interface ChoosePatientButtonProps {
  labTest: LabTestDashboardItem | Nil
  textVariant?: TextProps['variant']
}

const ChoosePatientButton = ({
  labTest,
  textVariant,
}: ChoosePatientButtonProps) => {
  const { t } = useTranslation(['Common'])

  const [openLabResultAssignmentDialog] = useDialog(
    DialogNames.LAB_RESULT_ASSIGNMENT,
  )

  const clientId = labTest?.client
  const patientId = labTest?.patient
  const vendorId = labTest?.vendorId
  const rawClient = labTest?.rawClient
  const rawPatient = labTest?.rawPatient

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const patientName = patient?.name || rawPatient?.name
  const clientData = client || rawClient

  const clientStr =
    clientData &&
    `${i18n.t('Common:CLIENT')}: ${Utils.getPersonString(clientData)}`
  const patientStr =
    patientName && `${i18n.t('Common:PATIENT')}: ${patientName}`
  const vetStr =
    labTest?.vet?.name &&
    `${i18n.t('Common:VETERINARIAN')}: ${labTest?.vet.name}`
  const testsStr = R.pluck('name', labTest?.tests || [])

  const orderInfo = [clientStr, patientStr, ...testsStr, vetStr]
    .filter(Boolean)
    .join('\n')

  const handleChoosePatient = () => {
    openLabResultAssignmentDialog({
      labOrder: labTest?.order,
      labOrderInfo: orderInfo,
      labTestId: labTest?.identifier,
      vendorId,
    })
  }

  return (
    <Text link variant={textVariant} onClick={handleChoosePatient}>
      {t('Common:CHOOSE_A_PATIENT')}
    </Text>
  )
}

export default ChoosePatientButton
