import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { UnsavedVital, Vital } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_VITALS = 'vitals/FETCH_VITALS'
export const FETCH_VITALS_SUCCESS = 'vitals/FETCH_VITALS_SUCCESS'
export const FETCH_VITALS_FAILURE = 'vitals/FETCH_VITALS_FAILURE'

export const UPDATE_VITALS = 'vitals/UPDATE_VITALS'

export const ADD_VITAL = 'vitals/ADD_VITAL'
export const ADD_VITAL_SUCCESS = 'vitals/ADD_VITAL_SUCCESS'
export const ADD_VITAL_FAILURE = 'vitals/ADD_VITAL_FAILURE'

export const EDIT_VITAL = 'vitals/EDIT_VITAL'
export const EDIT_VITAL_SUCCESS = 'vitals/EDIT_VITAL_SUCCESS'
export const EDIT_VITAL_FAILURE = 'vitals/EDIT_VITAL_FAILURE'

export const DELETE_VITAL = 'vitals/DELETE_VITAL'
export const DELETE_VITAL_SUCCESS = 'vitals/DELETE_VITAL_SUCCESS'
export const DELETE_VITAL_FAILURE = 'vitals/DELETE_VITAL_FAILURE'

export const fetchMoreVitalsSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_VITALS_SUCCESS,
  list,
  totalCount,
})
export const fetchMoreVitalsFailure = (error: ApiError) => ({
  type: FETCH_VITALS_FAILURE,
  error,
})

export type FetchVitals = {
  from: number
  includeDeleted: boolean
  patientId: string
  to: number
}

export const fetchMoreVitals = ({
  patientId,
  includeDeleted,
  from,
  to,
}: FetchVitals) => ({
  type: FETCH_VITALS,
  patientId,
  includeDeleted,
  from,
  to,
})

export const updateVitals = (vitals: Record<string, Vital>) => ({
  type: UPDATE_VITALS,
  vitals,
})

export const addVitalSuccess = (vitalId: string) => ({
  type: ADD_VITAL_SUCCESS,
  vitalId,
})
export const addVitalFailure = (error: ApiError, vital: UnsavedVital) => ({
  type: ADD_VITAL_FAILURE,
  error,
  vital,
})
export const addVital = (
  vital: UnsavedVital,
  soapId: string,
  clientId: string | Nil,
  patientId: string,
) => ({
  type: ADD_VITAL,
  vital,
  soapId,
  clientId,
  patientId,
})

export const editVital = (vital: Vital) => ({ type: EDIT_VITAL, vital })
export const editVitalSuccess = (vitalId: string) => ({
  type: EDIT_VITAL_SUCCESS,
  vitalId,
})
export const editVitalFailure = (error: ApiError) => ({
  type: EDIT_VITAL_FAILURE,
  error,
})

export const deleteVital = (vitalId: string) => ({
  type: DELETE_VITAL,
  vitalId,
})
export const deleteVitalSuccess = (vitalId: string) => ({
  type: DELETE_VITAL_SUCCESS,
  vitalId,
})
export const deleteVitalFailure = (error: ApiError) => ({
  type: DELETE_VITAL_FAILURE,
  error,
})

export type VitalsState = {
  error: string | null
  isDeleting: boolean
  isLoading: boolean
  isSaving: boolean
  map: Record<string, Vital>
  pendingVital: Vital | null
  totalCount: number | null
}

const INITIAL_STATE: VitalsState = {
  map: {},
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  pendingVital: null,
  totalCount: null,
  error: null,
}

export const vitalsReducer = (
  state: VitalsState = INITIAL_STATE,
  action: AnyAction,
): VitalsState => {
  switch (action.type) {
    case FETCH_VITALS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_VITALS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_VITALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_VITALS:
      return { ...state, map: secondLevelMerge(state.map, action.vitals) }
    case ADD_VITAL:
      return { ...state, pendingVital: action.vital, isSaving: true }
    case ADD_VITAL_SUCCESS:
      return {
        ...state,
        pendingVital: null,
        isSaving: false,
      }
    case ADD_VITAL_FAILURE:
      return {
        ...state,
        pendingVital: null,
        error: getErrorMessage(action.error),
        isSaving: false,
      }

    case EDIT_VITAL:
      return { ...state, isSaving: true }
    case EDIT_VITAL_SUCCESS:
      return { ...state, isSaving: false }
    case EDIT_VITAL_FAILURE:
      return { ...state, error: getErrorMessage(action.error), isSaving: false }
    case DELETE_VITAL:
      return { ...state, isDeleting: true }
    case DELETE_VITAL_SUCCESS:
      return {
        ...state,
        map: R.omit([action.vitalId], state.map),
        isDeleting: false,
      }
    case DELETE_VITAL_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    default:
      return state
  }
}

const getVitals = (state: RootState): VitalsState => state.vitals
export const getVitalsIsLoading = (state: RootState) =>
  getVitals(state).isLoading
export const getVitalsIsSaving = (state: RootState) => getVitals(state).isSaving
export const getVitalsIsDeleting = (state: RootState) =>
  getVitals(state).isDeleting
export const getVitalsMap = (state: RootState) => getVitals(state).map
export const getVital = (vitalId: string | Nil) =>
  createSelector(getVitalsMap, (map) => (vitalId ? map[vitalId] : undefined))
export const getMultipleVitals = (ids: string[]) =>
  createSelector(getVitalsMap, R.pipe(R.props(ids), R.filter(Boolean)))
export const getPendingVital = (state: RootState) =>
  getVitals(state).pendingVital
