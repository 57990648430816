import { DateUtils } from '@pbt/pbt-ui-components'

export type CreationDateCellItem = {
  creationDate: string
}

const CreationDateCell = (item: CreationDateCellItem) =>
  DateUtils.formatDate(item.creationDate, true)

export default CreationDateCell
