import React from 'react'
import { useSelector } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import { getInvoiceV3SubItemById } from '~/store/reducers/invoiceV3'

import FinanceCompactTotalFooterCell from '../components/FinanceCompactTotalFooterCell'

const useStyles = makeStyles((theme) => ({
  totalCell: {
    padding: ({ useInvoiceCVCLayout }: { useInvoiceCVCLayout: boolean }) =>
      theme.spacing(
        0,
        useInvoiceCVCLayout ? 2 : FINANCE_TABLE_PADDING_X_SPACING_VALUE,
      ),
    textAlign: 'right',
    height: FINANCE_TABLE_CELL_HEIGHT,
  },
}))

export interface InvoiceTableFooterProps {
  columnsLength: number
  currentItemId: string
  useInvoiceCVCLayout: boolean
}

const InvoiceTableFooter = ({
  currentItemId,
  columnsLength,
  useInvoiceCVCLayout,
}: InvoiceTableFooterProps) => {
  const classes = useStyles({ useInvoiceCVCLayout })

  const currentItem = useSelector(getInvoiceV3SubItemById(currentItemId))
  return (
    <TableRow>
      <TableCell className={classes.totalCell} colSpan={columnsLength}>
        <FinanceCompactTotalFooterCell
          patientId={currentItem?.patientId}
          subtotal={currentItem?.amount}
        />
      </TableCell>
    </TableRow>
  )
}

export default InvoiceTableFooter
