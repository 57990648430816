import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import useEstimateAlertDialog from '~/components/dashboard/soapV2/estimates/utils/useEstimateAlertDialog'
import DialogNames from '~/constants/DialogNames'
import { clearClonedEstimateId, cloneEstimate } from '~/store/actions/finance'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getClonedEstimateId } from '~/store/reducers/finance'
import useDialog from '~/utils/useDialog'

type UseCloneEstimateProps = {
  clientId: string | Nil
  estimateId: string | Nil
  onDialogOpen?: () => void
  patientId: string | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
}

const useCloneEstimate = ({
  clientId,
  estimateId,
  onDialogOpen,
  patientId,
  soapBusinessId,
  soapId,
}: UseCloneEstimateProps) => {
  const dispatch = useDispatch()

  const clonedEstimateId = useSelector(getClonedEstimateId)

  const [openInvoiceDialog, closeInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [needToOpenDialog, setNeedToOpenDialog] = useState(false)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const { setCloningEstimateId, setAttachingToSoapEstimateId } =
    useEstimateAlertDialog({
      fromSoap: true,
      fromTimeline: false,
      soapId,
    })

  useEffect(() => {
    if (clonedEstimateId && needToOpenDialog) {
      setNeedToOpenDialog(false)
      dispatch(clearClonedEstimateId())
      openInvoice({
        clientId,
        patientId,
        invoiceId: clonedEstimateId,
        isEstimate: true,
        soapBusinessId,
        soapId,
        newEstimateFlow: true,
        setAttachingToSoapEstimateId,
        onClose: () => {
          closeInvoiceDialog()
        },
      })
      if (onDialogOpen) {
        onDialogOpen()
      }
    }
    setCloningEstimateId(null)
  }, [clonedEstimateId])

  const onCloneRequested = () => {
    if (estimateId) {
      setNeedToOpenDialog(true)
      setCloningEstimateId(estimateId)
      dispatch(cloneEstimate(estimateId, true))
    }
  }

  return { onCloneRequested }
}

export default useCloneEstimate
