import axios from 'axios'
import { AnyAction } from 'redux'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  ApiError,
  BlobWithName,
  DocumentFile,
  Nil,
} from '@pbt/pbt-ui-components'

import * as API from '~/api'
import AssetDestination from '~/constants/AssetDestination'
import {
  CreateSoapFileGroupInput,
  CreateSoapFileInput,
  FilesWithDetails,
  UploadAssetPayload,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'
import { mapFilesToObjects } from '~/utils/file'

import { updateSoapFiles } from '../actions/soap'
import {
  fetchTimeline as fetchTimelineAction,
  optimisticAttachmentDeleteFromTimeline,
} from '../actions/timeline'
import type { RootState } from '../index'
import { getCurrentUserId } from '../reducers/auth'
import requestAPI from '../sagas/utils/requestAPI'

export const CREATE_ATTACHMENT_FILE = 'files/CREATE_ATTACHMENT_FILE'
export const CREATE_ATTACHMENT_FILE_SUCCESS =
  'files/CREATE_ATTACHMENT_FILE_SUCCESS'
export const CREATE_ATTACHMENT_FILE_FAILURE =
  'files/CREATE_ATTACHMENT_FILE_FAILURE'

export const CREATE_ATTACHMENT_FILE_LIST = 'files/CREATE_ATTACHMENT_FILE_LIST'
export const CREATE_ATTACHMENT_FILE_LIST_SUCCESS =
  'files/CREATE_ATTACHMENT_FILE_LIST_SUCCESS'
export const CREATE_ATTACHMENT_FILE_LIST_FAILURE =
  'files/CREATE_ATTACHMENT_FILE_LIST_FAILURE'

export const EDIT_ATTACHMENT_FILE = 'files/EDIT_ATTACHMENT_FILE'
export const EDIT_ATTACHMENT_FILE_SUCCESS = 'files/EDIT_ATTACHMENT_FILE_SUCCESS'
export const EDIT_ATTACHMENT_FILE_FAILURE = 'files/EDIT_ATTACHMENT_FILE_FAILURE'

export const DELETE_ATTACHMENT_FILE = 'files/DELETE_ATTACHMENT_FILE'
export const DELETE_ATTACHMENT_FILE_SUCCESS =
  'files/DELETE_ATTACHMENT_FILE_SUCCESS'
export const DELETE_ATTACHMENT_FILE_FAILURE =
  'files/DELETE_ATTACHMENT_FILE_FAILURE'

export const createAttachmentFile = (
  blob: BlobWithName,
  attachment: Partial<DocumentFile['file']>,
  clientId: string | Nil,
  patientId: string | Nil,
  soapId: string | Nil,
) => ({
  type: CREATE_ATTACHMENT_FILE,
  blob,
  attachment,
  clientId,
  patientId,
  soapId,
})
export const createAttachmentFileSuccess = () => ({
  type: CREATE_ATTACHMENT_FILE_SUCCESS,
})
export const createAttachmentFileFailure = (error: ApiError) => ({
  type: CREATE_ATTACHMENT_FILE_FAILURE,
  error,
})

export const createAttachmentFileList = (
  attachmentGroup: FilesWithDetails,
  clientId: string,
  patientId: string,
  soapId: string,
  soapBusinessId: string,
) => ({
  type: CREATE_ATTACHMENT_FILE_LIST,
  attachmentGroup,
  clientId,
  patientId,
  soapId,
  soapBusinessId,
})
export const createAttachmentFileListSuccess = () => ({
  type: CREATE_ATTACHMENT_FILE_LIST_SUCCESS,
})
export const createAttachmentFileListFailure = (error: ApiError) => ({
  type: CREATE_ATTACHMENT_FILE_LIST_FAILURE,
  error,
})

export const editAttachmentFile = (
  blob: BlobWithName,
  attachment: DocumentFile['file'],
  clientId: string,
  patientId: string,
  soapId: string,
) => ({
  type: EDIT_ATTACHMENT_FILE,
  blob,
  attachment,
  clientId,
  patientId,
  soapId,
})
export const editAttachmentFileSuccess = () => ({
  type: EDIT_ATTACHMENT_FILE_SUCCESS,
})
export const editAttachmentFileFailure = (error: ApiError) => ({
  type: EDIT_ATTACHMENT_FILE_FAILURE,
  error,
})

export const deleteAttachmentFile = (id: string) => ({
  type: DELETE_ATTACHMENT_FILE,
  id,
})
export const deleteAttachmentFileSuccess = () => ({
  type: DELETE_ATTACHMENT_FILE_SUCCESS,
})
export const deleteAttachmentFileFailure = (error: ApiError) => ({
  type: DELETE_ATTACHMENT_FILE_FAILURE,
  error,
})

export type FilesState = {
  error: string | null
  isCreating: boolean
  isDeleting: boolean
  isEditing: boolean
}

export const INITIAL_STATE: FilesState = {
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  error: null,
}

export const filesReducer = (
  state: FilesState = INITIAL_STATE,
  action: AnyAction,
): FilesState => {
  switch (action.type) {
    case CREATE_ATTACHMENT_FILE:
      return {
        ...state,
        error: null,
        isCreating: true,
      }
    case CREATE_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        isCreating: false,
      }
    case CREATE_ATTACHMENT_FILE_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_ATTACHMENT_FILE:
      return {
        ...state,
        error: null,
        isEditing: true,
      }
    case EDIT_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        isEditing: false,
      }
    case EDIT_ATTACHMENT_FILE_FAILURE:
      return {
        ...state,
        isEditing: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_ATTACHMENT_FILE:
      return {
        ...state,
        error: null,
        isDeleting: true,
      }
    case DELETE_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      }
    case DELETE_ATTACHMENT_FILE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getFiles = (state: RootState): FilesState => state.files
export const getFilesIsCreating = (state: RootState) =>
  getFiles(state).isCreating

export const fileContentTypeHeaders = (blob: BlobWithName) => ({
  'Content-Type': blob.type,
  'Content-Disposition': `attachment; filename="${encodeURIComponent(
    blob.name,
  )}"`,
})

export function* uploadAsset({
  blob,
  destination,
  payload,
}: {
  blob: BlobWithName
  destination: AssetDestination
  payload: UploadAssetPayload
}) {
  const response = yield* requestAPI(
    API.reserveAssetUpload,
    destination,
    payload,
  )
  const { fileUrl, uploadUrl } = response || {}
  yield axios.put(uploadUrl, blob, {
    headers: fileContentTypeHeaders(blob),
  })

  return fileUrl
}

export function* uploadAssetList({
  files,
  destination,
  payload,
}: {
  destination: AssetDestination
  files: File[]
  payload: UploadAssetPayload
}) {
  const responseList: Record<'fileUrl' | 'uploadUrl', string>[] = yield all(
    files.map(
      // @ts-ignore
      () => call(requestAPI, API.reserveAssetUpload, destination, payload),
    ),
  )
  yield all(
    responseList.map((item, i) =>
      call(axios.put, item.uploadUrl, files[i], {
        headers: fileContentTypeHeaders(files[i]),
      }),
    ),
  )

  return responseList.map((item: { fileUrl: string }) => item.fileUrl)
}

export function* uploadImageAssetAndGenerateThumbnail({
  blob,
  destination,
  payload,
}: {
  blob: BlobWithName
  destination: AssetDestination
  payload: UploadAssetPayload
}) {
  const assetUrl = yield* uploadAsset({ blob, destination, payload })
  const thumbnailUrl = yield* requestAPI(
    API.generateThumbnailForAsset,
    assetUrl,
  )

  return { imageUrl: assetUrl, imageThumbnailUrl: thumbnailUrl }
}

export function* createAttachmentFileSaga({
  blob,
  attachment,
  clientId,
  patientId,
  soapId,
}: ReturnType<typeof createAttachmentFile>) {
  try {
    const fileUrl: string = yield uploadAsset({
      blob,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })

    const authorId: string = yield select(getCurrentUserId)
    const payload = { ...attachment, fileUrl, authorId }
    const soapAttachment = yield* requestAPI(
      API.createAttachmentFile,
      payload,
      soapId,
    )
    yield put(updateSoapFiles([soapAttachment]))
    yield put(fetchTimelineAction())
    yield put(createAttachmentFileSuccess())
  } catch (error) {
    yield put(createAttachmentFileFailure(error as ApiError))
  }
}

function* getSoapFileGroupInputSaga({
  attachmentGroup,
  fileUrls,
  soapId,
  patientId,
  soapBusinessId,
}: {
  attachmentGroup: FilesWithDetails
  fileUrls: string[]
  patientId: string
  soapBusinessId: string
  soapId: string
}) {
  const soapFiles: CreateSoapFileInput[] = yield call(
    mapFilesToObjects,
    attachmentGroup.files,
    fileUrls,
  )
  const authorId: string = yield select(getCurrentUserId)

  return {
    date: attachmentGroup.date,
    diagnosedIn: attachmentGroup.diagnosedIn,
    description: attachmentGroup.description,
    title: attachmentGroup.title,
    authorId,
    soapFiles,
    soapId,
    businessId: soapBusinessId,
    patientId,
  }
}

export function* createAttachmentFileListSaga({
  attachmentGroup,
  clientId,
  patientId,
  soapId,
  soapBusinessId,
}: ReturnType<typeof createAttachmentFileList>) {
  try {
    const fileUrls: string[] = yield uploadAssetList({
      files: attachmentGroup.files,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })

    const soapFileGroupInput: CreateSoapFileGroupInput =
      yield getSoapFileGroupInputSaga({
        attachmentGroup,
        fileUrls,
        soapId,
        patientId,
        soapBusinessId,
      })
    const { soapFiles } = yield* requestAPI(
      API.saveSoapFileGroup,
      soapFileGroupInput,
    )
    yield put(updateSoapFiles(soapFiles))
    yield put(fetchTimelineAction())
    yield put(createAttachmentFileSuccess())
  } catch (error) {
    yield put(createAttachmentFileFailure(error as ApiError))
  }
}

export function* editAttachmentFileSaga({
  blob,
  attachment,
  clientId,
  patientId,
  soapId,
}: ReturnType<typeof editAttachmentFile>) {
  try {
    const fileUrl: string = yield uploadAsset({
      blob,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId,
        isPublic: false,
      },
    })

    const authorId: string = yield select(getCurrentUserId)
    const payload = { ...attachment, fileUrl, authorId }
    const soapAttachment = yield* requestAPI(
      API.editAttachmentFile,
      payload,
      soapId,
    )
    yield put(updateSoapFiles([soapAttachment]))
    yield put(fetchTimelineAction())
    yield put(editAttachmentFileSuccess())
  } catch (error) {
    yield put(editAttachmentFileFailure(error as ApiError))
  }
}

export function* deleteAttachmentFileSaga({
  id: attachmentId,
}: ReturnType<typeof deleteAttachmentFile>) {
  try {
    yield put(optimisticAttachmentDeleteFromTimeline(attachmentId))
    yield* requestAPI(API.deleteAttachmentFile, attachmentId)
    yield put(fetchTimelineAction())
    yield put(deleteAttachmentFileSuccess())
  } catch (error) {
    yield put(deleteAttachmentFileFailure(error as ApiError))
  }
}

function* watchCreateAttachmentFile() {
  yield takeLatest(CREATE_ATTACHMENT_FILE, createAttachmentFileSaga)
}

function* watchCreateAttachmentFileList() {
  yield takeLatest(CREATE_ATTACHMENT_FILE_LIST, createAttachmentFileListSaga)
}

function* watchEditAttachmentFile() {
  yield takeLatest(EDIT_ATTACHMENT_FILE, editAttachmentFileSaga)
}

function* watchDeleteAttachmentFile() {
  yield takeLatest(DELETE_ATTACHMENT_FILE, deleteAttachmentFileSaga)
}

export function* filesSaga() {
  yield all([
    watchCreateAttachmentFile(),
    watchCreateAttachmentFileList(),
    watchEditAttachmentFile(),
    watchDeleteAttachmentFile(),
  ])
}
