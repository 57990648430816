import { gql } from '@apollo/client/core'

import { CONVERSATION_DTO_FIELDS, MESSAGE_FIELDS } from '../fragments'

export const SEND_CONVERSATION_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation SendConversationMessage(
    $conversationId: ID!
    $input: OutboundMessageDto!
  ) {
    sendConversationMessage(conversationId: $conversationId, input: $input) {
      ...MessageFields
    }
  }
`

export const SEND_CONVERSATION_DRAFT_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation SendConversationDraftMessage(
    $conversationId: ID!
    $input: OutboundMessageDto!
  ) {
    sendConversationMessage: sendConversationDraftMessage(
      conversationId: $conversationId
      input: $input
    ) {
      ...MessageFields
    }
  }
`

export const UPDATE_MESSAGE = gql`
  ${MESSAGE_FIELDS}
  mutation UpdateConversationMessage($messageId: ID!, $input: MessageUpdate!) {
    updateConversationMessage(messageId: $messageId, input: $input) {
      ...MessageFields
    }
  }
`

export const DELETE_CONVERSATION_MESSAGE = gql`
  mutation DeleteConversationMessage($messageId: ID!) {
    deleteConversationMessage(messageId: $messageId)
  }
`

export const CREATE_CONVERSATION_ATTACHMENT = gql`
  mutation CreateConversationAttachment(
    $file: Upload!
    $patientId: ID
    $clientId: ID
    $name: String
    $description: String
    $extension: String
  ) {
    createAttachment(
      file: $file
      patientId: $patientId
      clientId: $clientId
      name: $name
      description: $description
      extension: $extension
    ) {
      name
      description
      extension
      fileUrl
    }
  }
`

export const DELETE_CONVERSATION_ATTACHMENT = gql`
  mutation DeleteConversationAttachment($fileId: ID!) {
    deleteAttachment(fileId: $fileId)
  }
`

export const SEND_GENERATED_MESSAGE = gql`
  ${CONVERSATION_DTO_FIELDS}
  mutation SendGeneratedMessage(
    $transport: ConversationTransport!
    $recipients: [RecipientDto!]!
    $input: GeneratedMessageInput!
  ) {
    sendGeneratedMessage(
      transport: $transport
      recipients: $recipients
      input: $input
    ) {
      ...ConversationDtoFields
    }
  }
`
