import {
  ReminderCommunicationTemplatePreviewInput,
  ReminderProtocolSettings,
} from '~/types'

import { getAPIPath, request } from './core'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { UPDATE_REMINDER_SETTINGS } from './graphql/mutations/reminderSettings'
import {
  FETCH_REMINDER_SETTINGS,
  FETCH_TEMPLATE_PREVIEW,
} from './graphql/queries/reminderSettings'

export const updateRemindersSettings = (
  currentBusinessId: string,
  businessId: string,
  settings: ReminderProtocolSettings,
) =>
  request(
    getAPIPath(`admin/services/reminder-settings/${businessId}`),
    {
      method: 'PUT',
      data: settings,
      params: { businessId: currentBusinessId },
    },
    true,
  )

export const updateRemindersSettingsV2 = requestMutation({
  mutation: UPDATE_REMINDER_SETTINGS,
  variablesHandler: (
    _: string,
    businessId: string,
    settings: ReminderProtocolSettings,
  ) => ({ settings }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const fetchReminderSettingsTemplate = (
  currentBusinessId: string,
  businessId: string,
  templateId: string,
) =>
  request(
    getAPIPath(
      `admin/services/reminder-settings/${businessId}/preview/${templateId}`,
    ),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
  )

export const fetchReminderTemplatePreviewV2 = requestQuery({
  query: FETCH_TEMPLATE_PREVIEW,
  variablesHandler: (
    _: string,
    businessId: string,
    template: ReminderCommunicationTemplatePreviewInput,
  ) => ({
    template,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const fetchCurrentReminderSettingsTemplate = (
  currentBusinessId: string,
  businessId: string,
  text: string,
) =>
  request(
    getAPIPath(`admin/services/reminder-settings/${businessId}/preview`),
    {
      method: 'POST',
      data: text,
      params: { businessId: currentBusinessId },
      headers: { 'Content-Type': 'text/html' },
    },
    true,
  )

export const fetchRemindersSettings = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getAPIPath(`admin/services/reminder-settings/${businessId}`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
  )

export const fetchRemindersSettingsV2 = requestQuery({
  query: FETCH_REMINDER_SETTINGS,
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})
