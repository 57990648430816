import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DateUtils, moment } from '@pbt/pbt-ui-components'

import PrintHtml from '~/components/common/print/PrintHtml'
import { getMultipleVariations } from '~/store/duck/onHandCatalog'
import {
  getInventoryCategory,
  getInventoryOnHandStatus,
  getInventoryProductSizeUnit,
  getInventorySubCategory,
  getPackageType,
} from '~/store/reducers/constants'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

import OnHandCatalogPrintPage, {
  OnHandCatalogPrintPageProps,
} from './OnHandCatalogPrintPage'

export interface OnHandCatalogPrintProviderProps
  extends Omit<OnHandCatalogPrintPageProps, 'constants' | 'getInStockAmount'> {
  itemsToPrint: string[]
  onPrintFinished: () => void
}

const OnHandCatalogPrintProvider = ({
  itemsToPrint,
  onPrintFinished,
  ...rest
}: OnHandCatalogPrintProviderProps) => {
  const getInStockAmount = useGetInStockAmount()
  const { t } = useTranslation('Admin')

  const getPrintPageName = () => {
    const currentDate = DateUtils.formatDate(moment().toISOString())
    return t('Admin:CATALOG.ON-HAND_CATALOG_PRINT_PROVIDER.TITLE', {
      currentDate,
    })
  }

  const title = getPrintPageName()

  const resolvedItems = useSelector(getMultipleVariations(itemsToPrint))
  const constants = {
    InventoryCategory: useSelector(getInventoryCategory),
    InventoryOnHandStatus: useSelector(getInventoryOnHandStatus),
    InventoryProductSizeUnit: useSelector(getInventoryProductSizeUnit),
    InventorySubCategory: useSelector(getInventorySubCategory),
    PackageType: useSelector(getPackageType),
  }

  if (!itemsToPrint?.length) {
    return null
  }

  return (
    <PrintHtml
      name={title}
      source={
        <OnHandCatalogPrintPage
          constants={constants}
          getInStockAmount={getInStockAmount}
          heading={title}
          items={resolvedItems}
          {...rest}
        />
      }
      onOk={onPrintFinished}
    />
  )
}

export default OnHandCatalogPrintProvider
