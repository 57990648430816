import { gql } from '@apollo/client/core'

import {
  PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS,
  PROBLEM_FIELDS,
} from '../fragments'

/**
 *  Deprecated after providing optimized BE option FETCH_PROBLEM_CATALOG_SCALAR
 */
export const FETCH_PROBLEM_CATALOG = gql`
  ${PROBLEM_FIELDS}
  query ProblemCatalog($patientId: ID!) {
    problemCatalog(patientId: $patientId) {
      problems {
        ...ProblemFields
        children {
          ...ProblemFields
          children {
            ...ProblemFields
            children {
              ...ProblemFields
              children {
                ...ProblemFields
              }
            }
          }
        }
      }
      enums {
        id
        name
        nameTranslation
        values {
          id
          name
          nameTranslation
          order
        }
      }
    }
  }
`

export const FETCH_PROBLEM_CATALOG_SCALAR = gql`
  query ProblemCatalogScalar($patientId: ID!) {
    problemCatalogScalar(patientId: $patientId) {
      catalog
    }
  }
`

export const FETCH_LOGS = gql`
  ${PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS}
  query ProblemLogContainer($soapId: ID!) {
    problemLogContainer(soapId: $soapId) {
      showAddedProblemLogsToast
      bodySystemLogs {
        ...ProblemBodySystemLogCrcContainerFields
      }
    }
  }
`

export const SEARCH_PROBLEMS = gql`
  query SearchProblems(
    $query: String!
    $locale: String!
    $patientId: ID!
    $offset: Int!
    $limit: Int!
  ) {
    searchProblems(
      query: $query
      locale: $locale
      patientId: $patientId
      offset: $offset
      limit: $limit
    ) {
      data {
        id
        name
        nameTranslation
        synonym
        synonymTranslation
        breadcrumb
        breadcrumbTranslation
        bodySystemId
        onlDisabled
        primaryId
        secondaryId
        tertiaryId
        type
      }
      totalCount
    }
  }
`

export const FETCH_PROBLEM_LOG_HISTORY = gql`
  query HistoryProblemLogs(
    $problemId: ID!
    $patientId: ID!
    $soapId: ID!
    $offset: Int!
    $limit: Int!
  ) {
    historyProblemLogs(
      problemId: $problemId
      patientId: $patientId
      soapId: $soapId
      offset: $offset
      limit: $limit
    ) {
      totalCount
      data {
        stateId
        notes
        date
        doctor {
          id
          firstName
          lastName
        }
        enumLogs {
          id
          problemLogId
          enumId
          enumValueId
          parentEnumValueId
          notes
        }
      }
    }
  }
`

export const DISABLE_TOAST_SHOWING = gql`
  query DisableSoapToastShowing($soapId: ID!) {
    disableSoapToastShowing(soapId: $soapId)
  }
`
