import React from 'react'
import InfiniteScroll, {
  Props as IScrollProps,
} from 'react-infinite-scroll-component'
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps as RGProps,
  RadioProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: '100%',
    },
    loader: {
      display: 'block',
      margin: `${theme.spacing(2)} auto`,
    },
    label: {},
    radioGroup: {},
    radioItem: {},
  }),
  { name: 'InfiniteScrollRadio' },
)

export type InfiniteScrollRadioProps = {
  InfiniteScrollProps?: Partial<IScrollProps>
  RadioGroupProps?: Partial<RGProps>
  classes?: ClassesType<typeof useStyles>
  items: RadioProps[]
  loadMore: () => void
  totalCount: number
}

const InfiniteScrollRadio = ({
  classes: classOverrides,
  items: itemsProp,
  totalCount,
  loadMore,
  RadioGroupProps = {},
  InfiniteScrollProps = {},
}: InfiniteScrollRadioProps) => {
  const items = itemsProp || []
  const classes = useStyles({ classes: classOverrides })

  return (
    <div className={classes.container}>
      <InfiniteScroll
        dataLength={items.length}
        hasMore={totalCount > items.length}
        loader={
          <CircularProgress
            className={classes.loader}
            color="primary"
            size={24}
          />
        }
        next={loadMore}
        {...InfiniteScrollProps}
      >
        <RadioGroup className={classes.radioGroup} {...RadioGroupProps}>
          {items.map(({ id, name, disabled }) => (
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              control={
                <Radio
                  className={classes.radioItem}
                  disabled={disabled}
                  value={id}
                />
              }
              key={id}
              label={name}
            />
          ))}
        </RadioGroup>
      </InfiniteScroll>
    </div>
  )
}

export default InfiniteScrollRadio
