import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ClickAwayListener, Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PermissionArea, Text, Utils } from '@pbt/pbt-ui-components'
import {
  ChevronDown,
  ChevronUp,
  Hospital as HospitalIcon,
} from '@pbt/pbt-ui-components/src/icons'

import BusinessSelect from '~/components/common/BusinessSelect'
import PuiIconButton from '~/components/common/PuiIconButton'
import { useUpdateCurrentUsersBusinessId } from '~/store/hooks/user'
import {
  getAssignedLocations,
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentUserId,
} from '~/store/reducers/auth'
import { getLockWorkingHoursSettings } from '~/store/reducers/businesses'
import { getBusinessStatus } from '~/store/reducers/constants'
import { arrayToMap } from '~/utils'
import { getBusinessName } from '~/utils/businessUtils'
import useStatusClasses from '~/utils/useStatusClasses'
import useWindowWidth from '~/utils/useWindowWidth'

import PracticeStatusLabel from '../admin/general/practices/PracticeStatusLabel'

const ICON_WIDTH = 38
const COLLAPSE_WIDTH = 550

const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: 'relative',
    },
    svgIconButtonActive: {
      color: 'white',
    },
    currentBusinessLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    currentBusiness: {
      transition: 'width 0.2s, height 0.1s, left 0.2s',
      width: ICON_WIDTH,
      position: 'absolute',
      left: 0,
      borderRadius: 20,
      backgroundColor: '#F7F4EE',
      height: 38,
      padding: theme.spacing(1),
      cursor: 'pointer',
      '& > *': {
        transition: 'opacity 0s',
        opacity: 0,
      },
    },
    currentBusinessVisible: {
      padding: theme.spacing(1, 6, 1, 3),
      '& > *': {
        transition: 'opacity 0.5s',
        opacity: 1,
      },
      width: theme.constants.businessSwitchWidth,
      left: ICON_WIDTH - theme.constants.businessSwitchWidth,

      [theme.breakpoints.down('xl')]: {
        width: theme.constants.businessSwitchWidthLg,
        left: ICON_WIDTH - theme.constants.businessSwitchWidthLg,
      },

      [theme.breakpoints.down('lg')]: {
        width: theme.constants.businessSwitchWidthMd,
        left: ICON_WIDTH - theme.constants.businessSwitchWidthMd,
      },
    },
    chevronIcon: {
      cursor: 'pointer',
      width: 20,
      height: 20,
      marginLeft: theme.spacing(1),
      visibility: 'hidden',
    },
    chevronIconVisible: {
      visibility: 'visible',
    },
    collapse: {
      boxShadow: '3px 3px 20px rgba(168,163,163,0.5)',
      position: 'fixed',
      width: COLLAPSE_WIDTH,
    },
    stateLabel: {
      width: 'fit-content',
      height: 'fit-content',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'BusinessLocationSwitch' },
)

const BusinessLocationSwitch = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Tooltips'])

  const currentBusinessRef = useRef<HTMLDivElement>(null)

  const lockWorkingHoursSettings = useSelector(getLockWorkingHoursSettings)
  const assignedBusinesses = useSelector(getAssignedLocations)
  const currentBusiness = useSelector(getCurrentBusiness)
  const BusinessStatus = useSelector(getBusinessStatus)
  const currentUserId = useSelector(getCurrentUserId) as string
  const supportPermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))

  const [visible, setVisible] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [locationPickerPinned, setLocationPickerPinned] = useState(
    assignedBusinesses.length > 1,
  )

  const { getClassesByStatus } = useStatusClasses()
  const windowWidth = useWindowWidth()
  const updateCurrentUsersBusinessId = useUpdateCurrentUsersBusinessId()

  const currentBusinessRefOffset =
    currentBusinessRef?.current?.getBoundingClientRect()
  const isRelatedCollapsePosition =
    currentBusinessRefOffset &&
    currentBusinessRefOffset.left + COLLAPSE_WIDTH < windowWidth
  const collapseLeft = isRelatedCollapsePosition
    ? currentBusinessRefOffset.left
    : windowWidth - COLLAPSE_WIDTH

  const businessStatus = Utils.findById(
    currentBusiness?.statusId,
    BusinessStatus,
  )
  const statusClasses = getClassesByStatus(businessStatus?.color)

  const hasMoreThanOneBusiness = Object.keys(assignedBusinesses).length > 1

  const lockedBusinessesMap = arrayToMap(
    lockWorkingHoursSettings,
    R.prop('businessId'),
    R.prop('locked'),
  )

  useEffect(() => {
    if (locationPickerPinned) {
      setVisible(true)
    }
  }, [locationPickerPinned])

  if (!hasMoreThanOneBusiness) {
    return null
  }

  const toggleVisible = () => {
    // if we are hiding, also need to collapse
    if (visible) {
      setExpanded(false)
    }
    setLocationPickerPinned(!visible)
    setVisible(!visible)
  }

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const handleMouseLeave = () => {
    if (expanded) {
      // setExpanded(false)
    }
    if (!locationPickerPinned && visible) {
      setVisible(false)
    }
  }

  const handleBusinessSelect = (id: string) => {
    updateCurrentUsersBusinessId({ businessId: id, userId: currentUserId })
  }

  const IconChevronComponent = expanded ? ChevronUp : ChevronDown

  return (
    <ClickAwayListener
      mouseEvent="onMouseUp"
      onClickAway={() => setExpanded(false)}
    >
      <Grid item className={classes.container} onMouseLeave={handleMouseLeave}>
        <Grid
          container
          alignItems="center"
          className={classNames(
            classes.currentBusiness,
            statusClasses.backgroundColor,
            {
              [classes.currentBusinessVisible]: visible,
            },
          )}
          ref={currentBusinessRef}
          wrap="nowrap"
          onClick={toggleExpanded}
        >
          <Grid container item alignItems="center" wrap="nowrap">
            <Text
              className={classNames(
                classes.currentBusinessLabel,
                statusClasses.color,
              )}
              color="secondary"
            >
              {getBusinessName(currentBusiness)}
            </Text>
            <PracticeStatusLabel
              short
              className={classes.stateLabel}
              statusId={currentBusiness?.statusId}
              variant="big"
            />
          </Grid>
          <IconChevronComponent
            className={classNames(classes.chevronIcon, {
              [classes.chevronIconVisible]: visible,
            })}
            onClick={toggleExpanded}
          />
        </Grid>
        <PuiIconButton
          Icon={HospitalIcon}
          aria-label={t('Common:OPEN_LOCATION_SWITCHER')}
          classes={{
            iconButton: statusClasses.highBackgroundColor,
            iconButtonSvg: classNames({
              [classes.svgIconButtonActive]: statusClasses.active,
            }),
          }}
          onClick={toggleVisible}
        />
        <Collapse
          mountOnEnter
          className={classes.collapse}
          in={expanded}
          style={{ left: collapseLeft }}
        >
          <BusinessSelect
            enableFavoriteButtons
            isLocationType
            showCurrentBusiness
            lockedBusinesses={lockedBusinessesMap}
            lockedMessage={t('Tooltips:LOCKED_OUTSIDE_BUSINESS_HOURS')}
            showBusinessNumber={supportPermissions.read}
            onBusinessSelect={handleBusinessSelect}
          />
        </Collapse>
      </Grid>
    </ClickAwayListener>
  )
}

export default BusinessLocationSwitch
