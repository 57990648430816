import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { deleteVital, getMultipleVitals } from '~/store/duck/vitals'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { VitalTimelineItem } from '~/types'
import { addOriginalBusinessId, getDeleteConfirmMessage } from '~/utils'
import { isDifferedBusinessList } from '~/utils/businessUtils'
import useDialog from '~/utils/useDialog'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'
import MultipleVitals from './MultipleVitals'
import OneVital from './OneVital'

export interface VitalCardProps extends TimelineCardProps {
  item: VitalTimelineItem
}

const VitalCard = ({ item, ...rest }: VitalCardProps) => {
  const dispatch = useDispatch()
  const permissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const vitals = useSelector(getMultipleVitals(item.entries))
  const { t } = useTranslation('Common')

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const [openVitalsDialog] = useDialog(DialogNames.VITALS)
  const [openDeleteVitalAlert, closeDeleteVitalAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const hasOneVital = vitals.length === 1
  const firstVital = vitals[0]

  if (vitals.length === 0 || !firstVital) {
    return null
  }

  const hasSoap = Boolean(firstVital.soapId)

  const hasDifferedBusinessEntries = isDifferedBusinessList(vitals)

  const onEdit = (id: string) => {
    openVitalsDialog({ vitalsId: id })
  }

  const onDelete = (id: string) => {
    openDeleteVitalAlert({
      message: getDeleteConfirmMessage('vital'),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteVitalAlert(),
      onOk: () => {
        dispatch(deleteVital(id))
        closeDeleteVitalAlert()
      },
    })
  }

  return (
    <TimelineCard
      doubleMargin
      originBusinessId={
        hasOneVital || !hasDifferedBusinessEntries
          ? firstVital.businessId
          : undefined
      }
      title={t('Common:VITALS')}
      onDelete={
        hasOneVital && !hasSoap ? () => onDelete(firstVital.id) : undefined
      }
      onEdit={hasOneVital && !hasSoap ? () => onEdit(firstVital.id) : undefined}
      {...item}
      {...rest}
    >
      <TimelineCardContent noPadding noTypography>
        {hasOneVital ? (
          <OneVital vital={firstVital} />
        ) : (
          <MultipleVitals
            enableBusinessShareIcon={hasDifferedBusinessEntries}
            item={item}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )}
      </TimelineCardContent>
      {hasOneVital && (
        <TimelineCardActions item={hasOneVital && vitals[0]}>
          {firstVital.soapId && permissions.read && (
            <Link
              to={addOriginalBusinessId(
                `/soap/${firstVital.soapId}`,
                isPatientSharingEnabled ? firstVital.businessId : null,
              )}
            >
              {t('Common:VIEW_SOAP')}
            </Link>
          )}
        </TimelineCardActions>
      )}
    </TimelineCard>
  )
}

export default VitalCard
