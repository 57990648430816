export const FETCH_CONVERSATION = 'conversations/FETCH_CONVERSATION'
export const FETCH_CONVERSATION_SUCCESS =
  'conversations/FETCH_CONVERSATION_SUCCESS'
export const FETCH_CONVERSATION_FAILURE =
  'conversations/FETCH_CONVERSATION_FAILURE'

export const FETCH_CONVERSATIONS_LIST = 'conversations/FETCH_CONVERSATIONS_LIST'
export const FETCH_CONVERSATIONS_LIST_SUCCESS =
  'conversations/FETCH_CONVERSATIONS_LIST_SUCCESS'
export const FETCH_CONVERSATIONS_LIST_FAILURE =
  'conversations/FETCH_CONVERSATIONS_LIST_FAILURE'

export const FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_FAILURE =
  'conversations/FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_SUCCESS =
  'conversations/FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST =
  'conversations/FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST'

export const UPDATE_CONVERSATIONS = 'conversations/UPDATE_CONVERSATIONS'

export const FETCH_CONVERSATION_MESSAGES_LIST =
  'conversations/FETCH_CONVERSATION_MESSAGES_LIST'
export const FETCH_CONVERSATION_MESSAGES_LIST_SUCCESS =
  'conversations/FETCH_CONVERSATION_MESSAGES_LIST_SUCCESS'
export const FETCH_CONVERSATION_MESSAGES_LIST_FAILURE =
  'conversations/FETCH_CONVERSATION_MESSAGES_LIST_FAILURE'

export const FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_FAILURE =
  'conversations/FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_SUCCESS =
  'conversations/FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST =
  'conversations/FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST'

export const CREATE_CONVERSATION = 'conversations/CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCESS =
  'conversations/CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAILURE =
  'conversations/CREATE_CONVERSATION_FAILURE'
export const CREATE_CONVERSATION_VALIDATION_FAILURE =
  'conversations/CREATE_CONVERSATION_VALIDATION_FAILURE'

export const DELETE_CONVERSATION = 'conversations/DELETE_CONVERSATION'
export const DELETE_CONVERSATION_SUCCESS =
  'conversations/DELETE_CONVERSATION_SUCCESS'
export const DELETE_CONVERSATION_FAILURE =
  'conversations/DELETE_CONVERSATION_FAILURE'

export const UPDATE_CONVERSATION_RECIPIENT_CONTEXT =
  'conversationsMessages/UPDATE_CONVERSATION_RECIPIENT_CONTEXT'
export const SET_ASSIGNEE_FOR_CONVERSATION =
  'conversations/SET_ASSIGNEE_FOR_CONVERSATION'
export const TOGGLE_ARCHIVE_FOR_CONVERSATION =
  'conversations/TOGGLE_ARCHIVE_FOR_CONVERSATION'
export const SET_IS_READ_FOR_CONVERSATION =
  'conversations/SET_IS_READ_FOR_CONVERSATION'
export const EDIT_CONVERSATION = 'conversations/EDIT_CONVERSATION'

export const EDIT_CONVERSATION_SUCCESS =
  'conversations/EDIT_CONVERSATION_SUCCESS'
export const EDIT_CONVERSATION_FAILURE =
  'conversations/EDIT_CONVERSATION_FAILURE'
export const FETCH_CLIENT_CONVERSATIONS_LIST =
  'conversations/FETCH_CLIENT_CONVERSATIONS_LIST'
export const FETCH_CLIENT_CONVERSATIONS_LIST_SUCCESS =
  'conversations/FETCH_CLIENT_CONVERSATIONS_LIST_SUCCESS'
export const FETCH_CLIENT_CONVERSATIONS_LIST_FAILURE =
  'conversations/FETCH_CLIENT_CONVERSATIONS_LIST_FAILURE'

export const FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_FAILURE =
  'conversations/FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_SUCCESS =
  'conversations/FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST =
  'conversations/FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST'

export const FETCH_CONVERSATION_EMAIL_PREVIEW =
  'conversations/FETCH_CONVERSATION_EMAIL_PREVIEW'
export const FETCH_CONVERSATION_EMAIL_PREVIEW_SUCCESS =
  'conversations/FETCH_CONVERSATION_EMAIL_PREVIEW_SUCCESS'
export const FETCH_CONVERSATION_EMAIL_PREVIEW_FAILURE =
  'conversations/FETCH_CONVERSATION_EMAIL_PREVIEW_FAILURE'
export const CLEAR_CONVERSATION_EMAIL_PREVIEW =
  'conversations/CLEAR_CONVERSATION_EMAIL_PREVIEW'

export const SET_CONVERSATIONS_LIST_FILTERS =
  'conversations/SET_CONVERSATIONS_LIST_FILTERS'
