import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { ColorSwatch } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { appointmentColorsList } from '~/store/reducers/appointmentTypes'

export interface AppointmentTypeColorFilterProps
  extends CheckboxListFilterProps {}

const AppointmentTypeColorFilter = (props: AppointmentTypeColorFilterProps) => {
  const colors = useSelector(appointmentColorsList)

  const colorOptions = R.uniq(colors).map((color) => ({
    id: color,
    name: color || '',
    component: <ColorSwatch noColorValueEnabled color={color || ''} />,
  }))

  return (
    <CheckboxListFilter
      clearEmptyFilters
      includeSearch={false}
      items={colorOptions || []}
      {...props}
    />
  )
}

export default AppointmentTypeColorFilter
