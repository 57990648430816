import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  CircularProgressOverlay,
  DateFormat,
  moment,
} from '@pbt/pbt-ui-components'

import InfiniteScrollRadio from '~/components/common/inputs/InfiniteScrollRadio'
import {
  chooseImportRequest,
  fetchMigrationImportRequests,
  fetchMoreMigrationImportRequests,
  getCurrentSession,
  getImportRequestIds,
  getImportRequests,
  getIsLoadingImportRequests,
  getMultipleImportRequests,
} from '~/store/duck/migrationV2'
import {
  BaseStepComponentProps,
  MigrationImportRequest,
} from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const useStyles = makeStyles(
  (theme) => ({
    radioGroup: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'ImportRequestPicker' },
)

const ImportRequestPicker = ({ stepController }: BaseStepComponentProps) => {
  const classes = useStyles()
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedImportRequestId, setSelectedImportRequestId] = useState<
    string | null
  >(null)
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const currentSession = useSelector(getCurrentSession)
  const isLoading = useSelector(getIsLoadingImportRequests) || !isInitialized
  const importRequestIds = useSelector(getImportRequestIds)
  const importRequests = useSelector(
    getMultipleImportRequests(importRequestIds),
  )
  const importRequestTotalCount = useSelector(getImportRequests).totalCount

  useEffect(() => {
    if (!isInitialized && currentSession) {
      dispatch(fetchMigrationImportRequests({ sessionId: currentSession.id }))
      setIsInitialized(true)
    }
  }, [currentSession, isInitialized])

  const buildImportRequestItems = ({
    id,
    creationDate,
    status,
    sourceDataLocation,
  }: MigrationImportRequest) => {
    const creationDateString = moment(creationDate).format(
      DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE,
    )
    const name = [sourceDataLocation, creationDateString, status].join(' | ')

    return { id, name }
  }

  const items = importRequests.map(buildImportRequestItems)

  const loadMore = () => {
    if (!currentSession) {
      return
    }
    dispatch(
      fetchMoreMigrationImportRequests({
        sessionId: currentSession?.id,
        from: items.length,
      }),
    )
  }

  const handleProceed = () => {
    if (!selectedImportRequestId) {
      return
    }
    dispatch(chooseImportRequest({ importRequestId: selectedImportRequestId }))
    stepController.next()
  }

  return (
    <MigrationStep>
      <MigrationStepContent height={320} id="import-request-picker-container">
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:LOOKING_FOR_IMPORT_REQUESTS')}
          />
        ) : (
          <InfiniteScrollRadio
            InfiniteScrollProps={{
              scrollableTarget: 'import-request-picker-container',
            }}
            RadioGroupProps={{
              onChange: (_, value) => setSelectedImportRequestId(value),
            }}
            classes={{
              label: classes.label,
              radioGroup: classes.radioGroup,
            }}
            items={items}
            loadMore={loadMore}
            totalCount={importRequestTotalCount}
          />
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton
          disabled={Boolean(!selectedImportRequestId)}
          onClick={handleProceed}
        >
          {t('Common:PROCEED')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default ImportRequestPicker
