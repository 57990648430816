import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, IconButton, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Nil,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { WarningRound as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { ConversationTransport } from '~/api/graphql/generated/types'
import {
  ConversationStatus,
  MessageStatus,
  MessageType,
} from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  closeMessageFormattingArea,
  messageFormattingAreaFocused,
  sendConversationDraftMessage,
  sendConversationMessage,
  updateMessage,
} from '~/store/actions/conversationMessages'
import {
  setIsReadForConversation,
  toggleArchiveForConversation,
} from '~/store/actions/conversations'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { useCanSendSmsToClient } from '~/store/hooks/clients'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getAttachmentsToSend,
  getEditMessage,
  getMessageFormattingAreaExpanded,
} from '~/store/reducers/conversationMessages'
import { getConversationById } from '~/store/reducers/conversations'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { EasterEggEvents } from '~/types'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import ConversationMessageFormattingArea, {
  ConversationMessageFormattingAreaHandle,
} from '../ConversationMessageFormattingArea'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      borderRadius: 2,
      borderTop: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    button: {
      height: 40,
    },
    warningIcon: {
      color: theme.colors.important,
      width: 24,
      height: 24,
    },
    secondaryButton: {
      color: theme.colors.title,
    },
    closeIcon: {
      color: theme.colors.secondaryText,
      width: 20,
      height: 20,
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
    },
  }),
  { name: 'MessageFormattingArea' },
)

export interface MessageFormattingAreaProps {
  conversationId: string
  disabled?: boolean
  hideStatusUpdateButtons?: boolean
}

const MessageFormattingArea = ({
  conversationId,
  disabled = false,
  hideStatusUpdateButtons = false,
}: MessageFormattingAreaProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Communications', 'Clients'])

  const editMessage = useSelector(getEditMessage)
  const conversation = useSelector(getConversationById(conversationId))
  const files = useSelector(getAttachmentsToSend)
  const messageFormattingAreaExpanded = useSelector(
    getMessageFormattingAreaExpanded,
  )
  const appointment = useSelector(getTimetableEvent(conversation?.eventId))
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )

  const isContextItem = useIsCurrentContextItem(conversation)

  const conversationFormattingAreaRef =
    useRef<ConversationMessageFormattingAreaHandle>(null)

  const [openDialog] = useDialog(DialogNames.CONVERSATION_INTERNAL_NOTE)

  const isLoading = !conversation

  const {
    state: status,
    transport,
    client: clientId,
    patient: patientId,
  } = conversation || {}

  const canSendSmsToClient = useCanSendSmsToClient(clientId)

  const {
    fields: { messageField },
    validate,
    reset,
  } = useFields([
    {
      name: 'messageField',
      label: t('Common:MESSAGE'),
      validators: ['required'],
      initialValue: editMessage?.body ?? '',
    },
  ])

  const isSmsTransport = transport === ConversationTransport.Sms
  const internalNoteOnly = transport === ConversationTransport.LogPhoneCall
  const archiveActionTitle =
    status === ConversationStatus.ARCHIVED
      ? t('Common:OPEN_CONVERSATION')
      : t('Communications:MESSAGE_FORMATTING_AREA.ARCHIVE_CONVERSATION')

  const goBack = () => navigate(-1)

  const onInternalNoteAdd = () => openDialog({ conversationId })

  const onArchiveAction = () => {
    if (isEasterEggEnabled && status !== ConversationStatus.ARCHIVED) {
      dispatch(
        queueEasterEggEvent({
          actionType: EasterEggEvents.ARCHIVE_CONVERSATION,
        }),
      )
    }
    dispatch(toggleArchiveForConversation(conversationId))
  }

  const handleMessageSend = () => {
    if (validate()) {
      if (editMessage) {
        dispatch(
          updateMessage({
            messageId: editMessage.id,
            status: MessageStatus.SENT,
            text: messageField.value,
            files,
          }),
        )
      } else {
        dispatch(
          sendConversationMessage(
            conversationId,
            MessageType.PLAIN,
            messageField.value,
            files,
          ),
        )
      }
      reset()
    }
  }

  const handleMessageDraftSend = () => {
    if (validate()) {
      if (editMessage) {
        dispatch(
          updateMessage({
            messageId: editMessage.id,
            status: MessageStatus.DRAFT,
            text: messageField.value,
            files,
          }),
        )
      } else {
        dispatch(
          sendConversationDraftMessage(
            conversationId,
            MessageType.PLAIN,
            messageField.value,
          ),
        )
      }
      reset()
    }
  }

  const onMarkUnread = () => {
    dispatch(setIsReadForConversation(conversationId, false))
    goBack()
  }

  const handleCloseMessageFormattingArea = () => {
    dispatch(closeMessageFormattingArea())
  }

  const setMessageText = (text: string | Nil) => {
    messageField.setValue(text)
    conversationFormattingAreaRef.current?.resetMessageState()
  }

  useEffect(() => {
    if (!isLoading && conversationId && isContextItem) {
      dispatch(setIsReadForConversation(conversationId, true))
    }
  }, [conversationId, isContextItem])

  useEffect(() => {
    setMessageText(editMessage?.body)
  }, [editMessage?.body])

  useEffect(() => {
    handleCloseMessageFormattingArea()
  }, [])

  return (
    <Grid
      container
      className={classes.root}
      pb={2}
      pt={messageFormattingAreaExpanded ? 5 : 2}
      px={3}
    >
      {messageFormattingAreaExpanded && (
        <IconButton
          disableRipple
          className={classes.closeButton}
          size="large"
          onClick={handleCloseMessageFormattingArea}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      )}
      <Grid container gap={2}>
        {(isSmsTransport ? canSendSmsToClient : !internalNoteOnly) && (
          <Grid container>
            {messageFormattingAreaExpanded ? (
              <ConversationMessageFormattingArea
                hideTo
                showAttachment
                clientId={clientId}
                eventId={conversation?.eventId}
                isShowSubject={false}
                maxEditorHeight={200}
                messageField={messageField}
                minEditorHeight={150}
                patientId={patientId || appointment?.patient}
                ref={conversationFormattingAreaRef}
                transport={transport}
              />
            ) : (
              <PuiTextField
                margin="none"
                minRows={1}
                placeholder={t(
                  'Communications:MESSAGE_FORMATTING_AREA.TYPE_REPLY',
                )}
                variant="outlined"
                onFocus={() => {
                  if (!disabled) {
                    dispatch(messageFormattingAreaFocused())
                  }
                }}
              />
            )}
          </Grid>
        )}
        <Grid container>
          {!internalNoteOnly && (
            <Grid container item xs columnSpacing={1} flex={1} rowSpacing={1.5}>
              {(isSmsTransport ? canSendSmsToClient : true) ? (
                <Grid item>
                  <ButtonWithLoader
                    disabled={isLoading || !messageFormattingAreaExpanded}
                    onClick={handleMessageSend}
                  >
                    {t('Common:SEND_ACTION')}
                  </ButtonWithLoader>
                </Grid>
              ) : (
                <Grid container item alignItems="center">
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <WarningIcon className={classes.warningIcon} />
                    <Text variant="body2">
                      {t('Clients:SMS_CONSENT_DISABLED')}
                    </Text>
                  </Stack>
                </Grid>
              )}
              {messageFormattingAreaExpanded && (
                <Grid item>
                  <ButtonWithLoader
                    className={classes.secondaryButton}
                    color="secondary"
                    disabled={isLoading}
                    onClick={handleMessageDraftSend}
                  >
                    {t('Common:SAVE_AS_DRAFT')}
                  </ButtonWithLoader>
                </Grid>
              )}
            </Grid>
          )}

          <Grid
            container
            item
            columnSpacing={1}
            flex={2}
            justifyContent="flex-end"
            rowSpacing={1.5}
            sm={8}
          >
            {!messageFormattingAreaExpanded && (
              <>
                <Grid item>
                  <ButtonWithLoader
                    className={classes.secondaryButton}
                    color="secondary"
                    disabled={isLoading}
                    onClick={onInternalNoteAdd}
                  >
                    {t('Common:ADD_INTERNAL_NOTE')}
                  </ButtonWithLoader>
                </Grid>

                {!hideStatusUpdateButtons &&
                  status !== ConversationStatus.DELIVERY_FAILED && (
                    <>
                      <Grid item>
                        <ButtonWithLoader
                          className={classes.secondaryButton}
                          color="secondary"
                          disabled={isLoading}
                          onClick={onMarkUnread}
                        >
                          {t('Common:MARK_AS_UNREAD')}
                        </ButtonWithLoader>
                      </Grid>

                      <Grid item>
                        <ButtonWithLoader
                          className={classes.secondaryButton}
                          color="secondary"
                          disabled={isLoading}
                          onClick={onArchiveAction}
                        >
                          {archiveActionTitle}
                        </ButtonWithLoader>
                      </Grid>
                    </>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MessageFormattingArea
