import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  EmployeeIdInfo,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { getMembersError, getMembersIsUpdating } from '~/store/reducers/members'
import { EmployeeIdInfoToCreate } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import EmployeeId, { EmployeeIdHandle } from './EmployeeId'

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
    },
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    button: {
      width: 144,
      height: 40,
    },
  }),
  { name: 'EmployeeIdDialog' },
)

export interface LicenseDialogProps extends BasePuiDialogProps {
  employeeIdInfo?: EmployeeIdInfo
  onCreateEmployeeIdInfo: (newEmployeeIdInfo: EmployeeIdInfoToCreate) => void
  onEditEmployeeIdInfo: (newEmployeeIdInfo: EmployeeIdInfoToCreate) => void
}

const EmployeeIdDialog = ({
  open,
  onClose,
  employeeIdInfo,
  onCreateEmployeeIdInfo,
  onEditEmployeeIdInfo,
}: LicenseDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')
  const errors = useSelector(getMembersError)
  const isLoading = useSelector(getMembersIsUpdating)

  const isCreate = !employeeIdInfo || R.isEmpty(employeeIdInfo)

  const employeeIdRef = useRef<EmployeeIdHandle>(null)

  const setCloseAfterCreationOn = useCloseAfterCreation(() => {
    if (!errors && onClose) {
      onClose()
    }
  }, getMembersIsUpdating)

  const save = () => {
    if (employeeIdRef.current?.validate()) {
      const newEmployeeIdInfo = employeeIdRef.current.get()

      setCloseAfterCreationOn()

      if (isCreate) {
        onCreateEmployeeIdInfo(newEmployeeIdInfo)
      } else {
        onEditEmployeeIdInfo(newEmployeeIdInfo)
      }
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: save,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          name="btnSaveEmployeeId"
          type="submit"
          onClick={save}
        >
          {isCreate ? t('Common:ADD_ACTION') : t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="employee-id-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        employeeIdRef.current?.hasUnsavedChanges() || false
      }
      open={open}
      title={
        !employeeIdInfo
          ? t('Admin:MEMBER.EMPLOYEE_ID.ADD_EMPLOYEE_ID_ACTION')
          : t('Admin:MEMBER.EMPLOYEE_ID.EDIT_EMPLOYEE_ID_ACTION')
      }
      onClose={onClose}
    >
      <EmployeeId employeeIdInfo={employeeIdInfo} ref={employeeIdRef} />
    </PuiDialog>
  )
}

export default EmployeeIdDialog
