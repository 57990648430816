import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  TextWithTooltip,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  getEnabledPlans,
  useGroupedWellnessPlans,
} from '../../wellnessPlanUtils'
import WellnessPlanReviewTablePlanRow from './WellnessPlanReviewTablePlanRow'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    plansContainer: {
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      border: theme.constants.tableBorder,
    },
    plansHeaderContainer: {
      padding: theme.spacing(2),
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'WellnessPlanReviewTable' },
)

export interface WellnessPlanReviewTableProps {
  classes?: ClassesType<typeof useStyles>
  showPrice?: boolean
  wellnessPlanVersion: WellnessPlanVersion
}

const WellnessPlanReviewTable = ({
  classes: classesProp,
  showPrice,
  wellnessPlanVersion,
}: WellnessPlanReviewTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const plans = getEnabledPlans(wellnessPlanVersion)
  const {
    packages = [],
    extras = [],
    tiers = [],
  } = useGroupedWellnessPlans(plans)

  if (plans.length === 0) {
    return null
  }

  const hasTiers = tiers.length > 0
  const hasExtras = extras.length > 0
  const hasPackages = packages.length > 0

  const hasManyColumns = hasTiers && hasExtras && hasPackages

  return (
    <Grid
      container
      item
      alignItems="flex-start"
      className={classes.root}
      mt={3}
      spacing={2}
      wrap="nowrap"
    >
      {hasTiers && (
        <Grid item xs>
          <Grid
            container
            item
            className={classes.plansContainer}
            direction="column"
          >
            <Grid
              container
              item
              alignItems="center"
              className={classes.plansHeaderContainer}
            >
              <TextWithTooltip
                tooltipText={t(
                  'WellnessPlans:PATIENTS_CAN_BE_ENROLLED_IN_ONLY_ONE_PLAN',
                )}
                variant="h4"
              >
                {t('Common:WELLNESS_PLANS')}
              </TextWithTooltip>
            </Grid>
            {tiers.map((tier) => (
              <WellnessPlanReviewTablePlanRow
                dense={hasManyColumns}
                key={tier.level}
                plan={tier}
                showPrice={showPrice}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {hasExtras && (
        <Grid item xs>
          <Grid
            container
            item
            className={classes.plansContainer}
            direction="column"
          >
            <Grid
              container
              item
              alignItems="center"
              className={classes.plansHeaderContainer}
            >
              <TextWithTooltip
                tooltipText={t(
                  'WellnessPlans:PATIENT_MUST_BE_ENROLLED_IN_PLAN_TO_INCLUDE_ANY_EXTRA',
                )}
                variant="h4"
              >
                {t('Common:WELLNESS_EXTRAS')}
              </TextWithTooltip>
            </Grid>
            {extras.map((extra, index) => (
              <WellnessPlanReviewTablePlanRow
                dense={hasManyColumns}
                key={extra.id || index}
                plan={extra}
                showPrice={showPrice}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {hasPackages && (
        <Grid item xs>
          <Grid
            container
            item
            className={classes.plansContainer}
            direction="column"
          >
            <Grid
              container
              item
              alignItems="center"
              className={classes.plansHeaderContainer}
            >
              <TextWithTooltip
                tooltipText={t(
                  'WellnessPlans:SUBSCRIPTIONS_ARE_AVAILABLE_INDEPENDENTLY_OF_WELLNESS_PLANS',
                )}
                variant="h4"
              >
                {t('Common:SUBSCRIBE_AND_SAVE')}
              </TextWithTooltip>
            </Grid>
            {packages.map((packageItem, index) => (
              <WellnessPlanReviewTablePlanRow
                dense={hasManyColumns}
                key={packageItem.id || index}
                plan={packageItem}
                showPrice={showPrice}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default WellnessPlanReviewTable
