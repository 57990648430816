import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import PaymentType from '~/constants/paymentTypes'
import { clearTransactionInfo, fetchPayment } from '~/store/actions/payments'
import {
  getGoPayment,
  getGoStripePayment,
  getPayment,
} from '~/store/reducers/payments'
import { ExtendPayment, Payment } from '~/types'

import PaymentDetails from './PaymentDetails'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 600,
      maxWidth: 600,
    },
  }),
  { name: 'PaymentDetailsDialog' },
)

interface PaymentDetailsDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  forceNotNavigateToBalanceAfterRefund?: boolean
  invoiceId?: string | Nil
  isPossibleToViewInvoice?: boolean
  navigateToRefundLanding?: () => void
  onRefresh?: () => void
  payment?: ExtendPayment | Payment | Nil
  paymentId?: string | Nil
  type?: PaymentType
}

const PaymentDetailsDialog = ({
  payment: paymentProp,
  paymentId: paymentIdProp,
  type,
  clientId: clientIdProp,
  isPossibleToViewInvoice,
  onClose,
  navigateToRefundLanding,
  open,
  invoiceId,
  forceNotNavigateToBalanceAfterRefund,
  onRefresh,
}: PaymentDetailsDialogProps) => {
  const providedPayment = paymentProp || {
    id: paymentIdProp,
    type,
    goStripeTxId: undefined,
    goTxId: undefined,
  }

  const rootObjectIsGoPayment = providedPayment.type === PaymentType.GO_PAYMENT
  const rootObjectIsGoStripePayment =
    providedPayment.type === PaymentType.GO_STRIPE_PAYMENT

  const classes = useStyles()
  const dispatch = useDispatch()
  const paymentFromStore = useSelector(getPayment(providedPayment.id))
  const payment =
    rootObjectIsGoPayment || rootObjectIsGoStripePayment
      ? undefined
      : paymentFromStore
  const goPayment = useSelector(
    getGoPayment(
      rootObjectIsGoPayment
        ? providedPayment.id
        : R.prop('goTxId', providedPayment),
    ),
  )
  const goStripePayment = useSelector(
    getGoStripePayment(
      rootObjectIsGoStripePayment
        ? providedPayment.id
        : R.prop('goStripeTxId', providedPayment),
    ),
  )

  useEffect(() => {
    if (
      !rootObjectIsGoPayment &&
      !rootObjectIsGoStripePayment &&
      providedPayment.id
    ) {
      dispatch(fetchPayment(providedPayment.id))
    }
  }, [])

  const handleClose = () => {
    dispatch(clearTransactionInfo())

    if (onClose) {
      onClose()
    }
  }

  const preparedPayment = rootObjectIsGoPayment
    ? goPayment
    : rootObjectIsGoStripePayment
      ? goStripePayment
      : ({
          goTxId: payment?.id,
          goStripeTxId: payment?.id,
          initialPaymentSource: payment,
          ...payment,
        } as ExtendPayment)
  const clientId = clientIdProp ?? preparedPayment?.clientId

  useEffect(() => {
    if (preparedPayment?.originPaymentId) {
      dispatch(fetchPayment(preparedPayment?.originPaymentId))
    }
  }, [preparedPayment?.originPaymentId])

  return (
    <PuiDialog
      aria-labelledby="payment-details-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={handleClose}
    >
      {!preparedPayment ? null : (
        <PaymentDetails
          clientId={clientId}
          forceNotNavigateToBalanceAfterRefund={
            forceNotNavigateToBalanceAfterRefund
          }
          invoiceId={invoiceId}
          isPossibleToViewInvoice={isPossibleToViewInvoice}
          navigateToRefundLanding={navigateToRefundLanding}
          payment={preparedPayment}
          onClose={handleClose}
          onRefresh={onRefresh}
        />
      )}
    </PuiDialog>
  )
}

export default PaymentDetailsDialog
