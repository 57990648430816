import React, { createContext, useCallback, useMemo, useState } from 'react'
import { Nil } from '@pbt/pbt-ui-components'

interface ProblemsContextProps {
  closeRightDrawer: () => void
  isRightDrawerOpen: boolean
  onProblemSelect: (content: React.ReactNode, problemId: string) => void
  openRightDrawer: (content?: React.ReactNode) => void
  rightDrawerContent: React.ReactNode
  selectedProblemId: string | Nil
}

const ProblemsContext = createContext({} as ProblemsContextProps)

const ProblemsContextProvider = ({ children }: { children: JSX.Element }) => {
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false)
  const [rightDrawerContent, setRightDrawerContent] =
    useState<React.ReactNode>(null)
  const [selectedProblemId, setSelectedProblemId] = useState<string | Nil>()

  const closeRightDrawer = useCallback(() => {
    setRightDrawerContent(null)
    setIsRightDrawerOpen(false)
    setSelectedProblemId(null)
  }, [setRightDrawerContent, setIsRightDrawerOpen, setSelectedProblemId])

  const openRightDrawer = useCallback(
    (content: React.ReactNode = null) => {
      setRightDrawerContent(content)
      setIsRightDrawerOpen(true)
    },
    [setRightDrawerContent, setIsRightDrawerOpen],
  )

  const onProblemSelect = useCallback(
    (content: React.ReactNode, problemId: string) => {
      setSelectedProblemId(problemId)
      openRightDrawer(content)
    },
    [setSelectedProblemId, openRightDrawer],
  )

  const contextValue = useMemo(
    () => ({
      selectedProblemId,
      onProblemSelect,
      openRightDrawer,
      closeRightDrawer,
      isRightDrawerOpen,
      rightDrawerContent,
    }),
    [
      selectedProblemId,
      onProblemSelect,
      openRightDrawer,
      closeRightDrawer,
      isRightDrawerOpen,
      rightDrawerContent,
    ],
  )

  return (
    <ProblemsContext.Provider value={contextValue}>
      {children}
    </ProblemsContext.Provider>
  )
}

export { ProblemsContext, ProblemsContextProvider }
