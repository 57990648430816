import React from 'react'

import { CommonDueDates } from '~/components/common/filters/CommonDueDates'
import DateRangeFilter, {
  DateRangeFilterProps,
} from '~/components/common/filters/DateRangeFilter'

const DueDates = [
  CommonDueDates.TODAY,
  CommonDueDates.LAST_3_DAYS,
  CommonDueDates.LAST_5_DAYS,
  CommonDueDates.LAST_7_DAYS,
  CommonDueDates.LAST_30_DAYS,
]

export interface LabTestsDateFilterProps
  extends Omit<DateRangeFilterProps, 'DueDates'> {}

const LabTestsDateFilter = (props: LabTestsDateFilterProps) => (
  <DateRangeFilter DueDates={DueDates} {...props} />
)

export default LabTestsDateFilter
