import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import {
  BasePuiDialogProps,
  DateUtils,
  Defaults,
  LanguageUtils,
  Nil,
} from '@pbt/pbt-ui-components'

import EmailDialogWithSections from '~/components/common/dialog/email/EmailDialogWithSections'
import { GeneratingPdfContentKind } from '~/constants/communications'
import { emailPrescription } from '~/store/actions/communications'
import {
  clearPrescriptionEmailTemplate,
  clearPrescriptionForPrint,
  fetchPrescriptionEmailTemplate,
  setPrescriptionForPrint,
} from '~/store/actions/orders'
import {
  getIsPrescriptionEmailSending,
  getIsPrescriptionEmailTemplateReceiving,
  getPrescriptionEmailTemplate,
} from '~/store/reducers/orders'
import { getPatient } from '~/store/reducers/patients'
import {
  getAppointment,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'
import { EmailEntityConfig, Prescription } from '~/types'

interface EmailPrescriptionDialogProps extends BasePuiDialogProps {
  prescription: Prescription
  soapId: string | Nil
}

const EmailPrescriptionDialog = ({
  open,
  onClose,
  soapId,
  prescription,
}: EmailPrescriptionDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Soap')
  const emailTemplate = useSelector(getPrescriptionEmailTemplate)
  const templateIsReceiving = useSelector(
    getIsPrescriptionEmailTemplateReceiving,
  )
  const appointment = useSelector(getAppointment)
  const patientId = useSelector(getPatientId)
  const patient = useSelector(getPatient(patientId)) || prescription?.patient
  const clientId = useSelector(getClientId) || prescription?.client?.id

  const petName = LanguageUtils.capitalize(patient?.name || '')
  const appointmentDate = DateUtils.formatDate(appointment?.startDate)
  const defaultSubject = `${t('Soap:PETS_PRESCRIPTIONS', {
    petName,
  })} ${appointmentDate}`
  const drugName = prescription?.inventory?.name
  const defaultMessage = t(
    'Soap:HERE_IS_PETS_PRESCRIPTION_FOR_DRUG_FROM_DATE',
    {
      petName,
      drugName,
      date: appointmentDate,
    },
  )

  const handleClose = () => {
    dispatch(clearPrescriptionEmailTemplate())
    if (onClose) {
      onClose()
    }
  }

  const fetchEmailTemplate = (config: EmailEntityConfig) => {
    if (open && prescription) {
      dispatch(fetchPrescriptionEmailTemplate(soapId, prescription?.id, config))
    }
  }

  const fetchEmailTemplateDebounced = useDebouncedCallback(
    fetchEmailTemplate,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const handleSendEmail = (config: EmailEntityConfig) => {
    dispatch(
      emailPrescription({ soapId, orderId: prescription?.id, ...config }),
    )
  }

  const pdfFileConfig = { soapId, orderId: prescription?.id }

  const handlePrint = () => {
    dispatch(setPrescriptionForPrint(prescription))
  }

  return (
    <EmailDialogWithSections
      cardEntityName="prescription"
      clientId={clientId}
      defaultMessage={defaultMessage}
      defaultSubject={defaultSubject}
      emailTemplate={emailTemplate}
      isSendingEmailSelector={getIsPrescriptionEmailSending}
      open={open}
      patient={patient}
      pdfContentKind={GeneratingPdfContentKind.PRESCRIPTION}
      pdfFileConfig={pdfFileConfig}
      soapId={soapId}
      templateIsReceiving={templateIsReceiving}
      onChange={fetchEmailTemplateDebounced}
      onClearPrint={clearPrescriptionForPrint}
      onClose={handleClose}
      onPrint={handlePrint}
      onSendEmail={handleSendEmail}
    />
  )
}

export default EmailPrescriptionDialog
