import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CustomFieldValidatorState,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  editVetcoveConfig,
  fetchVetcoveConfig,
  getVetcoveConfig,
} from '~/store/duck/vetcoveConfig'
import { BasePracticeDetailsSectionProps } from '~/types'
import { VetcoveConfig } from '~/types/entities/vetcoveConfig'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
    },
  }),
  { name: 'VetcoveIntegration' },
)

const validateClientId = ({ state, value }: CustomFieldValidatorState) =>
  (Boolean(value) && state.active) || !state.active
const validateClientSecret = ({ state, value }: CustomFieldValidatorState) =>
  (Boolean(value) && state.active) || !state.active

const VetcoveIntegration = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Businesses'])

  const config = useSelector(getVetcoveConfig(business.id))

  const { fields, reset, validate } = useFields([
    {
      name: 'active',
      label: t('Businesses:VETCOVE_INTEGRATION.VETCOVE_INTEGRATION'),
      type: 'toggle',
      initialValue: config?.active || false,
    },
    {
      name: 'clientId',
      label: `${t('Businesses:VETCOVE_INTEGRATION.CLIENT_ID')}*`,
      validators: [
        { validator: validateClientId, validatorName: 'validateClientId' },
      ],
      messages: { validateClientId: t('Validations:REQUIRED_FIELD') },
      initialValue: config?.clientId || '',
    },
    {
      name: 'clientSecret',
      label: `${t('Businesses:VETCOVE_INTEGRATION.CLIENT_SECRET')}*`,
      validators: [
        {
          validator: validateClientSecret,
          validatorName: 'validateClientSecret',
        },
      ],
      messages: { validateClientSecret: t('Validations:REQUIRED_FIELD') },
      initialValue: config?.clientSecret || '',
    },
    {
      name: 'webhookSecret',
      label: t('Businesses:VETCOVE_INTEGRATION.REDIRECT_URLS'),
      initialValue: config?.webhookSecret || '',
    },
  ])

  const { active, clientId, clientSecret, webhookSecret } = fields

  const parsedConfig = {
    businessId: business.id,
    active: active.value,
    clientId: clientId.value,
    clientSecret: clientSecret.value,
    webhookSecret: webhookSecret.value,
  }

  const onSave = () => {
    dispatch(editVetcoveConfig(parsedConfig))
  }

  usePracticeFieldsSection<VetcoveConfig>({
    business,
    fields,
    onSave,
    parsedFields: parsedConfig,
    sectionName: PracticeDetailsPanels.VETCOVE_INTEGRATION,
    validate,
    reset,
  })

  useEffect(() => {
    if (business.id) {
      dispatch(fetchVetcoveConfig(business.id))
    }
  }, [business.id])

  useEffect(() => {
    if (!active.value) {
      clientId.setValue('')
      clientSecret.setValue('')
      webhookSecret.setValue('')
    }
  }, [active.value])

  if (!config) {
    return null
  }

  return (
    <Grid className={classes.root}>
      <PuiSwitch field={active} label={active.label} />
      {active?.value && (
        <>
          <Grid item md={8} px={1} py={1.5}>
            <PuiTextField
              autoComplete="off"
              field={clientId}
              label={clientId.label}
            />
          </Grid>
          <Grid item md={12} px={1} py={1.5}>
            <PuiTextField
              autoComplete="off"
              field={clientSecret}
              label={clientSecret.label}
            />
          </Grid>
          <Grid item md={8} px={1} py={1.5}>
            <PuiTextField
              autoComplete="off"
              field={webhookSecret}
              label={webhookSecret.label}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default VetcoveIntegration
