import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import { LanguageUtils, Nil } from '@pbt/pbt-ui-components'

import {
  fetchReferredByContacts,
  getContactsIsFetchingList,
  getReferredByContacts,
} from '../duck/contacts'

export const useGetReferredByName = (
  referredById: string | Nil,
  referredByType: string | Nil,
) => {
  const dispatch = useDispatch()
  const contacts = useSelector(getReferredByContacts)
  const contactsIsReceiving = useSelector(getContactsIsFetchingList)

  useEffect(() => {
    if (isEmpty(contacts) && !contactsIsReceiving) {
      dispatch(fetchReferredByContacts())
    }
  }, [])

  return LanguageUtils.getTranslatedFieldName(
    contacts.find(
      ({ id, type }) => id === referredById && type === referredByType,
    ),
  )
}
