import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  fetchClientsList,
  fetchMoreItemsForClientsList,
} from '~/store/actions/clients'
import {
  getCurrentBusinessHasActiveWellnessPlans,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import {
  getClientIsLoading,
  getClientIsSearching,
  getClientsList,
  getTotalClientCount,
} from '~/store/reducers/clients'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSearchHighlights } from '~/store/reducers/search'
import { getFieldsQueryMergeObject, getUrlSearchParam } from '~/utils'
import { useGetGroupSharingEnabled } from '~/utils/groupSharingUtils'

import { INCLUDE_INACTIVE_QUERY_KEY } from '../../header/Search'
import ClientsSplashScreen from '../ClientsSplashScreen'
import ClientsTable from './ClientsTable'

interface ClientsTableComponentProps {
  groupSearchRequire: boolean
  includeInactive: boolean
  sharedOnly: boolean
}

const ClientsTableComponent = ({
  includeInactive,
  sharedOnly,
  groupSearchRequire,
}: ClientsTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const list = useSelector(getClientsList)
  const totalCount = useSelector(getTotalClientCount)
  const searchHighlights = useSelector(getSearchHighlights)
  const isLoading = useSelector(getClientIsLoading)
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const hasActiveWellnessPlans = useSelector(
    getCurrentBusinessHasActiveWellnessPlans,
  )
  const isSearching = useSelector(getClientIsSearching)

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isGroupClientSharingFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_CLIENT_SHARING),
  )
  const groupSharingEnabled = useGetGroupSharingEnabled()

  const query = getUrlSearchParam('query', location.search)
  const fieldsQuery = getUrlSearchParam('fieldsQuery', location.search)

  useEffect(() => {
    dispatch(
      fetchClientsList({
        from: 0,
        to: Defaults.CLIENTS_BATCH_LOAD_COUNT,
        query,
        fieldsQuery,
        showLastAppointment: false,
        includeInactive,
        sharedOnly,
        groupSearchRequire,
      }),
    )
  }, [query, fieldsQuery, includeInactive, sharedOnly, groupSearchRequire])

  useEffect(() => {
    if (
      !isLoading &&
      totalCount === 0 &&
      isSearching &&
      (query || fieldsQuery) &&
      !includeInactive
    ) {
      const queryParams = new URLSearchParams(location.search)
      queryParams.append(INCLUDE_INACTIVE_QUERY_KEY, 'true')
      navigate(`${location.pathname}?${queryParams}`)
    }
  }, [totalCount, isLoading])

  const loadMoreItems = (startIndex: number, stopIndex: number) => {
    dispatch(
      fetchMoreItemsForClientsList({
        from: startIndex,
        to: stopIndex,
        query,
        fieldsQuery,
        showLastAppointment: false,
        includeInactive,
        groupSearchRequire,
      }),
    )
  }

  const isItemLoaded = (index: number) => Boolean(list[index])

  const onItemClick = (clientId: string, patientId: string | Nil) => {
    if (typeof clientId !== 'undefined') {
      if (patientId) {
        navigate(`/client/${clientId}/patient/${patientId}`, {
          state: { searchQuery: query },
        })
      } else {
        navigate(`/client/${clientId}`)
      }
    }
  }

  const onApplyFilter = (filter?: string, value?: any) => {
    const filterFieldsQuery = value.value.join(';')
    const queryParams = new URLSearchParams()

    if (query) {
      queryParams.append('query', query)
    }

    if (filterFieldsQuery) {
      queryParams.append('fieldsQuery', decodeURIComponent(filterFieldsQuery))
    }

    navigate(`${location.pathname}?${queryParams}`)
  }

  const onClearFilters = () => {
    navigate(location.pathname)
  }

  const searchFieldsObject = fieldsQuery
    ? getFieldsQueryMergeObject(fieldsQuery)
    : {}
  const highlightProps = {
    firstName: [...searchHighlights, searchFieldsObject.firstName || ''],
    lastName: [...searchHighlights, searchFieldsObject.lastName || ''],
    petFriendString: [...searchHighlights],
    name: [...searchHighlights, searchFieldsObject['patients.name'] || ''],
    gender: [searchFieldsObject['patients.gender']],
    breeds: searchFieldsObject['patients.breeds']?.split(',') || [],
  }

  return (
    <>
      <ClientsSplashScreen search={query} />
      <ClientsTable
        hideActionsColumn
        hideLastAppointmentColumn
        hideMembershipColumn={!wellnessPlansEnabled || !hasActiveWellnessPlans}
        hideSharedColumn={
          !(isGroupClientSharingFeatureEnabled && groupSharingEnabled) ||
          isPatientSharingEnabled
        }
        highlightProps={highlightProps}
        isItemLoaded={isItemLoaded}
        isLoading={isLoading}
        list={list}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
        onItemClick={onItemClick}
      />
    </>
  )
}

export default ClientsTableComponent
