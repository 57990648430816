import React from 'react'
import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    menuButton: {
      justifyContent: 'space-between',
      color: theme.colors.lowAccentText,
      textTransform: 'none',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
  { name: 'FinalizeOrdersActionButton' },
)

interface FinalizeOrdersActionButtonProps {
  disabled?: boolean
  handler: () => void
  icon: React.ReactNode
  text: string
}

export const FinalizeOrdersActionButton = ({
  disabled,
  handler,
  icon,
  text,
}: FinalizeOrdersActionButtonProps) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.menuButton}
      disabled={disabled}
      onClick={handler}
    >
      <Grid container>
        {icon}
        <Grid item ml={0.5}>
          <Text variant="body">{text}</Text>
        </Grid>
      </Grid>
    </Button>
  )
}
