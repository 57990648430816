import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import LoginForm from '~/components/common/auth/LoginForm'
import LoginPage from '~/components/common/auth/LoginPage'
import LoginWithActivationCodeForm from '~/components/common/auth/LoginWithActivationCodeForm'
import useIsAuthenticated from '~/utils/useIsAuthenticated'
import useReloadRedirect from '~/utils/useReloadRedirect'

import ForgotPasswordComponent from './ForgotPasswordComponent'
import LockScreenComponent from './LockScreenComponent'
import ResetPasswordComponent from './ResetPasswordComponent'

const Auth = () => {
  const location = useLocation()

  const { isAuthenticated } = useIsAuthenticated()
  const reloadRedirect = useReloadRedirect()

  const search = new URLSearchParams(location.search)
  const redirectTo = search.get('redirectTo') || '/'

  useEffect(() => {
    if (isAuthenticated) {
      reloadRedirect(redirectTo)
    }
  }, [isAuthenticated])

  return (
    <LoginPage>
      <Routes>
        {/* root path: /auth */}
        <Route element={<LoginForm showRegisterButton />} path="/login" />
        <Route element={<LoginWithActivationCodeForm />} path="/activation" />
        <Route
          element={<LockScreenComponent showRegisterButton />}
          path="/lock"
        />
        <Route
          element={<ForgotPasswordComponent showRegisterButton />}
          path="/password-reset"
        />
        <Route
          element={<ResetPasswordComponent showRegisterButton />}
          path="/password/reset/:token"
        />
      </Routes>
    </LoginPage>
  )
}

export default Auth
