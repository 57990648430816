import { all, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api/charges'
import { Charges } from '~/api/graphql/generated/types'

import {
  fetchChargesByLog,
  fetchChargesByLogFailure,
  fetchChargesByLogSuccess,
  fetchSoapCharges,
  fetchSoapChargesFailure,
  fetchSoapChargesSuccess,
} from '../duck/charges'
import { finishLoading, startLoading } from '../duck/progress'
import { getClientId, getSoapId } from '../reducers/soap'
import requestAPI from './utils/requestAPI'

export function* fetchSoapChargesSaga({
  payload,
}: ReturnType<typeof fetchSoapCharges>) {
  try {
    yield put(startLoading('soapCharges'))
    const clientId: string = yield select(getClientId)
    const soapId: string = yield select(getSoapId)
    const { charges }: { charges: Charges } = yield requestAPI(
      API.fetchSoapCharges,
      payload?.id || clientId,
      payload?.soapId || soapId,
      payload?.includeDeleted,
    )

    yield put(fetchSoapChargesSuccess(charges))
    yield put(finishLoading('soapCharges'))
  } catch (error) {
    yield put(fetchSoapChargesFailure({ error: error as ApiError }))
    yield put(finishLoading('soapCharges'))
  }
}

export function* fetchChargesByLogSaga({
  payload,
}: ReturnType<typeof fetchChargesByLog>) {
  try {
    const { logType, logId } = payload
    const chargesByLog: Charges = yield requestAPI(
      API.fetchChargesByLog,
      logId,
      logType,
    )
    yield put(fetchChargesByLogSuccess(chargesByLog))
  } catch (error) {
    yield put(fetchChargesByLogFailure({ error: error as ApiError }))
  }
}

function* watchSoapCharges() {
  yield takeLeading(fetchSoapCharges.type, fetchSoapChargesSaga)
}

function* watchChargesByLog() {
  yield takeLeading(fetchChargesByLog.type, fetchChargesByLogSaga)
}

export default function* invoiceV3Saga() {
  yield all([watchSoapCharges(), watchChargesByLog()])
}
