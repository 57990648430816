import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import {
  fetchBusinessesList,
  fetchMoreItemsForBusinessesList,
} from '~/store/actions/businesses'
import {
  getBusinessesList,
  getBusinessesTotalCount,
  getBusinessIsFetchingList,
  getMultipleBusinesses,
} from '~/store/reducers/businesses'
import { getBusinessStatus, getPracticeTypes } from '~/store/reducers/constants'
import { getSearchHighlights } from '~/store/reducers/search'
import { TableFilter } from '~/types'
import {
  addSearch,
  getConstantIdStringByNameString,
  getFieldsQueryMergeObject,
  getUrlSearchParam,
  setStringifyFieldsQueryParam,
} from '~/utils'

import PracticeDetails from './PracticeDetails'
import PracticesTable from './PracticesTable'

export interface PracticesTableComponentProps {
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
  practiceId?: string
}

const PracticesTableComponent = ({
  practiceId,
  headerButtons,
  onDetailsClose,
}: PracticesTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const isFetchingList = useSelector(getBusinessIsFetchingList)
  const list = useSelector(getBusinessesList)
  const businesses = useSelector(getMultipleBusinesses(list))
  const totalCount = useSelector(getBusinessesTotalCount)
  const BusinessStatus = useSelector(getBusinessStatus)
  const PracticeTypes = useSelector(getPracticeTypes)
  const searchHighlights = useSelector(getSearchHighlights)

  const query = getUrlSearchParam('query', location.search)
  const fieldsQuery = getUrlSearchParam('fieldsQuery', location.search)
  const searchFieldsObject = getFieldsQueryMergeObject(fieldsQuery)

  const columnFilters = useMemo(() => {
    const statusFilter = searchFieldsObject?.status
      ? {
          status: {
            value: getConstantIdStringByNameString(
              searchFieldsObject.status,
              BusinessStatus,
            ),
            humanReadable: searchFieldsObject.status,
          },
        }
      : {}

    const typeFilter = searchFieldsObject?.type
      ? {
          type: {
            value: getConstantIdStringByNameString(
              searchFieldsObject.type,
              PracticeTypes,
            ),
            humanReadable: searchFieldsObject.type,
          },
        }
      : {}

    return { ...statusFilter, ...typeFilter }
  }, [fieldsQuery, BusinessStatus])

  useEffect(() => {
    dispatch(
      fetchBusinessesList(
        0,
        Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        query,
        fieldsQuery,
      ),
    )
  }, [query, fieldsQuery])

  const navigateToPractice = (id: string) => {
    navigate(addSearch(location, `/admin/general/practices/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(businesses[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (startIndex === 0) {
      return
    }
    dispatch(
      fetchMoreItemsForBusinessesList(startIndex, endIndex, query, fieldsQuery),
    )
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    const queryParams = new URLSearchParams(location.search)
    if (filter && !R.isNil(value?.humanReadable)) {
      const newFieldsQuery = setStringifyFieldsQueryParam(
        fieldsQuery,
        filter,
        value.humanReadable,
      )
      if (newFieldsQuery) {
        queryParams.set('fieldsQuery', decodeURIComponent(newFieldsQuery))
      } else {
        queryParams.delete('fieldsQuery')
      }
    }
    navigate(`${location.pathname}?${queryParams}`)
  }

  const onClearFilters = () => {
    navigate(location.pathname)
  }

  const highlightProps = {
    practiceName: [...searchHighlights, searchFieldsObject?.practiceName || ''],
    internalName: [...searchHighlights, searchFieldsObject?.internalName || ''],
  }

  return (
    <ExpandableTable
      Expander={PracticeDetails}
      hasSelectedFilters={!R.isEmpty(columnFilters)}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={practiceId}
      title={t('Common:PRACTICE_OTHER')}
      onClose={onDetailsClose}
      onSelected={navigateToPractice}
    >
      <PracticesTable
        filters={columnFilters as Record<string, TableFilter>}
        highlightProps={highlightProps}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        practices={businesses}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default PracticesTableComponent
