import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TextInteractive } from '@pbt/pbt-ui-components'

import { fetchBusiness } from '~/store/actions/businesses'
import { getBusiness, getBusinessIsLoading } from '~/store/reducers/businesses'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
  }),
  { name: 'BusinessCard' },
)

export interface BusinessCardProps {
  businessId?: string
}

const BusinessCard = ({ businessId }: BusinessCardProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation(['Common'])
  const business = useSelector(getBusiness(businessId))
  const isLoading = useSelector(getBusinessIsLoading)

  const hasRequiredFields = business?.communicationsPhone && business?.email

  useEffect(() => {
    if (businessId && !hasRequiredFields) {
      dispatch(fetchBusiness(businessId))
    }
  }, [businessId])

  if (!businessId) {
    return null
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      p={1}
      px={2}
    >
      {business?.communicationsPhone && (
        <TextInteractive isLoading={isLoading} loaderWidth={90} variant="body2">
          {`${t('Common:PHONE')}: ${business?.communicationsPhone}`}
        </TextInteractive>
      )}
      {business?.email && (
        <TextInteractive isLoading={isLoading} variant="body2">
          {business?.email}
        </TextInteractive>
      )}
    </Grid>
  )
}

export default BusinessCard
