export const FETCH_DOCUMENTS_LIST_FAILURE =
  'documents/FETCH_DOCUMENTS_LIST_FAILURE'
export const FETCH_DOCUMENTS_LIST_SUCCESS =
  'documents/FETCH_DOCUMENTS_LIST_SUCCESS'
export const FETCH_DOCUMENTS_LIST = 'documents/FETCH_DOCUMENTS_LIST'

export const FETCH_DOCUMENT = 'documents/FETCH_DOCUMENT'
export const FETCH_DOCUMENT_FAILURE = 'documents/FETCH_DOCUMENT_FAILURE'
export const FETCH_DOCUMENT_SUCCESS = 'documents/FETCH_DOCUMENT_SUCCESS'

export const FETCH_DOCUMENTS = 'documents/FETCH_DOCUMENTS'
export const FETCH_DOCUMENTS_FAILURE = 'documents/FETCH_DOCUMENTS_FAILURE'
export const FETCH_DOCUMENTS_SUCCESS = 'documents/FETCH_DOCUMENTS_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_FAILURE =
  'documents/FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_SUCCESS =
  'documents/FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST =
  'documents/FETCH_MORE_ITEMS_FOR_DOCUMENTS_LIST'

export const FETCH_RESOLVED_DOCUMENT_BODY =
  'documents/FETCH_RESOLVED_DOCUMENT_BODY'
export const FETCH_RESOLVED_DOCUMENT_BODY_FAILURE =
  'documents/FETCH_RESOLVED_DOCUMENT_BODY_FAILURE'
export const FETCH_RESOLVED_DOCUMENT_BODY_SUCCESS =
  'documents/FETCH_RESOLVED_DOCUMENT_BODY_SUCCESS'

export const FETCH_RESOLVED_DOCUMENTS = 'documents/FETCH_RESOLVED_DOCUMENTS'
export const FETCH_RESOLVED_DOCUMENTS_FAILURE =
  'documents/FETCH_RESOLVED_DOCUMENTS_FAILURE'
export const FETCH_RESOLVED_DOCUMENTS_SUCCESS =
  'documents/FETCH_RESOLVED_DOCUMENTS_SUCCESS'

export const CREATE_DOCUMENT = 'documents/CREATE_DOCUMENT'
export const CREATE_DOCUMENT_WITH_TEMPLATE =
  'documents/CREATE_DOCUMENT_WITH_TEMPLATE'
export const CREATE_DOCUMENT_FAILURE = 'documents/CREATE_DOCUMENT_FAILURE'
export const CREATE_DOCUMENT_SUCCESS = 'documents/CREATE_DOCUMENT_SUCCESS'

export const EDIT_DOCUMENT = 'documents/EDIT_DOCUMENT'
export const EDIT_DOCUMENT_WITH_TEMPLATE =
  'documents/EDIT_DOCUMENT_WITH_TEMPLATE'
export const EDIT_DOCUMENT_FAILURE = 'documents/EDIT_DOCUMENT_FAILURE'
export const EDIT_DOCUMENT_SUCCESS = 'documents/EDIT_DOCUMENT_SUCCESS'

export const DELETE_DOCUMENT = 'documents/DELETE_DOCUMENT'
export const DELETE_DOCUMENT_FAILURE = 'documents/DELETE_DOCUMENT_FAILURE'
export const DELETE_DOCUMENT_SUCCESS = 'documents/DELETE_DOCUMENT_SUCCESS'

export const UPDATE_DOCUMENTS = 'documents/UPDATE_DOCUMENTS'

export const RESET_DOCUMENTS = 'documents/RESET_DOCUMENTS'

export const FETCH_DOCUMENTS_BY_TYPE = 'documents/FETCH_DOCUMENTS_BY_TYPE'
export const FETCH_DOCUMENTS_BY_TYPE_FAILURE =
  'documents/FETCH_DOCUMENTS_BY_TYPE_FAILURE'
export const FETCH_DOCUMENTS_BY_TYPE_SUCCESS =
  'documents/FETCH_DOCUMENTS_BY_TYPE_SUCCESS'

export const GENERATE_FILE_FOR_DOCUMENT_TEMPLATE =
  'documents/GENERATE_FILE_FOR_DOCUMENT_TEMPLATE'
export const GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_FAILURE =
  'documents/GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_FAILURE'
export const GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_SUCCESS =
  'documents/GENERATE_FILE_FOR_DOCUMENT_TEMPLATE_SUCCESS'

export const GENERATE_DOCUMENT_INSTANCES =
  'conversationsMessages/GENERATE_DOCUMENT_INSTANCES'
export const GENERATE_DOCUMENT_INSTANCES_SUCCESS =
  'conversationsMessages/GENERATE_DOCUMENT_INSTANCES_SUCCESS'
export const GENERATE_DOCUMENT_INSTANCES_FAILURE =
  'conversationsMessages/GENERATE_DOCUMENT_INSTANCES_FAILURE'
