import { NamedEntity } from '@pbt/pbt-ui-components'

export type AlertsConfig = {
  alertColorId: string | null
  alertTypesIds: string[]
  alerts: NamedEntity[]
  customAlertType: string
}

export enum PatientUpdateReason {
  SPAY = 'SPAY',
  NEUTER = 'NEUTER',
  DECEASE = 'DECEASE',
}
