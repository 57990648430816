import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import { updateSearchHighlights } from '../actions/search'
import {
  CREATE_CONTACT,
  createContact,
  createContactFailure,
  createContactSuccess,
  DELETE_CONTACT,
  deleteContact,
  deleteContactFailure,
  deleteContactSuccess,
  EDIT_CONTACT,
  editContact,
  editContactFailure,
  editContactSuccess,
  FETCH_CONTACT,
  FETCH_CONTACTS,
  FETCH_MORE_ITEMS_FOR_CONTACTS,
  FETCH_REFERRED_BY_CONTACTS,
  fetchContact,
  fetchContactFailure,
  fetchContacts,
  fetchContactsFailure,
  fetchContactsSuccess,
  fetchContactSuccess,
  fetchMoreItemsForContacts,
  fetchMoreItemsForContactsFailure,
  fetchMoreItemsForContactsSuccess,
  fetchReferredByContacts,
  fetchReferredByContactsFailure,
  fetchReferredByContactsSuccess,
} from '../duck/contacts'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchContactsSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchContacts>) {
  try {
    yield put(startLoading('contacts'))
    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = query
      ? yield* requestAPI(API.searchContacts, {
          from,
          query,
          to,
          normalize: true,
        })
      : yield* requestAPI(
          API.fetchContacts,
          query,
          null,
          null,
          null,
          null,
          from,
          to,
          true,
        )
    yield put(updateSearchHighlights(highlights, totalCount))
    yield call(updateEntities, entities)
    yield put(fetchContactsSuccess(list, totalCount))
    yield put(finishLoading('contacts'))
  } catch (error) {
    yield put(fetchContactsFailure(error as ApiError))
    yield put(finishLoading('contacts'))
  }
}

export function* fetchMoreItemsForContactsSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForContacts>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = query
      ? yield* requestAPI(API.searchContacts, {
          query,
          from,
          to,
          normalize: true,
        })
      : yield* requestAPI(
          API.fetchContacts,
          query,
          null,
          null,
          null,
          null,
          from,
          to,
          true,
        )
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForContactsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForContactsFailure(error as ApiError))
  }
}

export function* fetchContactSaga({
  contactId,
}: ReturnType<typeof fetchContact>) {
  try {
    const { result, entities } = yield* requestAPI(API.fetchContact, contactId)
    yield call(updateEntities, entities)
    yield put(fetchContactSuccess(result))
  } catch (error) {
    yield put(fetchContactFailure(error as ApiError))
  }
}

export function* createContactSaga({
  contact,
}: ReturnType<typeof createContact>) {
  try {
    const { result, entities } = yield* requestAPI(API.createContact, contact)
    yield call(updateEntities, entities)
    yield put(fetchContacts())
    yield put(createContactSuccess(result))
  } catch (error) {
    yield put(createContactFailure(error as ApiError))
  }
}

export function* editContactSaga({ contact }: ReturnType<typeof editContact>) {
  try {
    const { result, entities } = yield* requestAPI(API.editContact, contact)
    yield call(updateEntities, entities)
    yield put(editContactSuccess(result))
  } catch (error) {
    yield put(editContactFailure(error as ApiError))
  }
}

export function* deleteContactSaga({
  contactId,
}: ReturnType<typeof deleteContact>) {
  try {
    yield* requestAPI(API.deleteContact, contactId)
    yield put(deleteContactSuccess(contactId))
  } catch (error) {
    yield put(deleteContactFailure(error as ApiError))
  }
}

export function* fetchReferredByContactsSaga({
  from,
  to,
}: ReturnType<typeof fetchReferredByContacts>) {
  try {
    const contacts = yield* requestAPI(API.fetchReferredByContacts, from, to)
    yield put(fetchReferredByContactsSuccess(contacts))
  } catch (error) {
    yield put(fetchReferredByContactsFailure(error as ApiError))
  }
}

function* watchFetchContacts() {
  yield takeLatest(FETCH_CONTACTS, fetchContactsSaga)
}

function* watchFetchMoreItemsForContacts() {
  yield takeLatest(FETCH_MORE_ITEMS_FOR_CONTACTS, fetchMoreItemsForContactsSaga)
}

function* watchFetchContact() {
  yield takeLatest(FETCH_CONTACT, fetchContactSaga)
}

function* watchCreateContact() {
  yield takeLatest(CREATE_CONTACT, createContactSaga)
}

function* watchEditContact() {
  yield takeLatest(EDIT_CONTACT, editContactSaga)
}

function* watchDeleteContact() {
  yield takeLatest(DELETE_CONTACT, deleteContactSaga)
}

function* watchReferredByContacts() {
  yield takeLatest(FETCH_REFERRED_BY_CONTACTS, fetchReferredByContactsSaga)
}

export default function* contactsSaga() {
  yield all([
    watchFetchContacts(),
    watchFetchMoreItemsForContacts(),
    watchFetchContact(),
    watchCreateContact(),
    watchEditContact(),
    watchDeleteContact(),
    watchReferredByContacts(),
  ])
}
