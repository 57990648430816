import React from 'react'
import { MenuItem } from '@mui/material'

import AccentLabel, { AccentLabelProps } from '../labels/AccentLabel'

export interface AccentSelectItemProps extends AccentLabelProps {
  classes?: any
  onClick?: (event: React.MouseEvent<any>) => void
}

const AccentSelectItem = ({
  // prevents classes prop bypass
  classes,
  onClick,
  ...rest
}: AccentSelectItemProps) => (
  <MenuItem onClick={onClick}>
    <AccentLabel {...rest} />
  </MenuItem>
)

export default AccentSelectItem
