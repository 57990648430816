import { Business as BusinessType } from '@pbt/pbt-ui-components'

import Business, { BusinessDto } from '~/store/dto/Business'
import { createPerson, PersonDto } from '~/store/dto/Person'

import { getAPIPath, request } from './core'
import {
  serializeRegistrationByInviteData,
  serializeRegistrationData,
  // @ts-ignore
} from './utils/serializeRegistrationData'

export const fetchInvitationData = (invitationToken: string) =>
  request(getAPIPath(`auth/invitation_details/${invitationToken}`)).then(
    (response) => {
      const businesses = {} as Record<string, BusinessDto>
      if (response.businesses) {
        response.businesses.forEach((_: BusinessType) => {
          businesses[_.id] = new Business(_)
        })
      }
      return {
        person: createPerson({
          email: response.person.email,
          firstName: response.person.firstName,
          lastName: response.person.lastName,
          phone: response.person.mobilePhone,
          dea: response.person.dea,
        }),
        businesses,
      }
    },
  )

export const registerByInvite = (person: PersonDto, invitationToken: string) =>
  request(
    getAPIPath('auth/register/invitation'),
    {
      method: 'POST',
      data: {
        invitationToken,
        person: serializeRegistrationByInviteData(person),
      },
    },
    false,
  )

export const register = (
  manager: PersonDto,
  businesses: Record<string, BusinessType>,
  members: Record<string, PersonDto>,
) =>
  request(getAPIPath('auth/register'), {
    method: 'POST',
    data: serializeRegistrationData({ manager, businesses, members }),
  })

export const registerV2 = (
  manager: PersonDto,
  businesses: Record<string, BusinessType>,
  members: Record<string, PersonDto>,
  key: string,
  isAaha: boolean,
  fearFree: boolean,
  doctorCount: number,
  practiceType: string,
) => {
  const serializedData: {
    businesses: BusinessType[]
    members: PersonDto[]
    person: PersonDto
  } = serializeRegistrationData({
    manager,
    businesses,
    members,
  })
  const changedMembers = serializedData.members.map((member: PersonDto) => ({
    email: member.email,
    roleId: member.jobId,
  }))
  return request(
    getAPIPath('auth/v2/register'),
    {
      method: 'POST',
      data: {
        key,
        business: {
          ...Object.values(serializedData.businesses)[0],
          aaha: isAaha,
          fearFree,
          doctorCount,
          onboardingPracticeTypeId: practiceType,
        },
        person: serializedData.person,
        members: changedMembers,
      },
    },
    false,
  )
}

export const validateActivationKey = (key: string) =>
  request(
    getAPIPath('keychain/onboarding/key/metadata'),
    { method: 'POST', data: { key } },
    false,
  )
