import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { ControlButtonGroupName } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { OrderType } from '~/constants/SOAPStates'
import { useIsGlobalInventoryItem } from '~/store/hooks/orders'
import {
  getUnifiedOrderIsLoading,
  getUnifiedOrderLoadingId,
} from '~/store/reducers/orders'
import {
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceUpdateFieldCallback,
} from '~/types'

import { isGroupedInvoiceItem } from '../../invoiceUtils'

const useInvoiceItemButtons = ({
  item,
  group,
  isReadOnly,
  isEstimate,
  onUpdatePrescriptionLineItem,
  deleteItem,
  updateField,
}: {
  deleteItem: (item: InvoiceLineItem) => void
  group: InvoiceLineItemGroup
  isEstimate: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  onUpdatePrescriptionLineItem: (item: InvoiceLineItem) => void
  updateField: InvoiceUpdateFieldCallback
}) => {
  const { t } = useTranslation(['Common'])

  const unifiedOrderIsLoading = useSelector(getUnifiedOrderIsLoading)
  const unifiedOrderLoadingId = useSelector(getUnifiedOrderLoadingId)

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.INVOICE_TABLE_ITEM,
  })

  const isGlobalItem = useIsGlobalInventoryItem(item)

  if (isReadOnly) {
    return []
  }
  const isGrouped = isGroupedInvoiceItem(item)
  const isPrescription = item.logType === OrderType.PRESCRIPTION
  const isOrder = item.logType === OrderType.INVENTORY

  const hasLabTestOrder = isGrouped
    ? item.items?.some(R.prop('labTestOrderId'))
    : Boolean(item.labTestOrderId)

  const editButton = {
    name: ControlButtonGroupName.EDIT,
    isLoading: unifiedOrderIsLoading && unifiedOrderLoadingId === item.logId,
    onClick: () => onUpdatePrescriptionLineItem(item),
  }

  const deleteButton = {
    name: ControlButtonGroupName.DELETE,
    onClick: () =>
      openConfirmAlert({
        message: t('Common:YOU_WILL_REMOVE_SOMETHING', {
          something: isGrouped ? item.groupName : item.name,
        }),
        onConfirm: (proceed: boolean) => proceed && deleteItem(item),
      }),
  }

  const declineButton = {
    name: ControlButtonGroupName.DECLINE,
    tooltip: t('Common:CLIENT_DECLINED'),
    onClick: () => updateField(!item.declined, 'declined', group, item),
  }

  const defaultButtons = [deleteButton, declineButton]
  const prescriptionButtons = [editButton, ...defaultButtons]

  const invoiceButtons = hasLabTestOrder ? [] : defaultButtons

  const buttons = isEstimate
    ? defaultButtons
    : isPrescription || (isOrder && isGlobalItem)
      ? prescriptionButtons
      : invoiceButtons

  return buttons
}

export default useInvoiceItemButtons
