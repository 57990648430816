/* eslint sort-keys: "error" */
import { SupportedDashboardConfig } from '~/utils/analytics'

export enum SupportedAnalyticsDashboard {
  ACCOUNTING = 'ACCOUNTING',
  ACQUISITION_AND_LIFETIME_VALUE = 'ACQUISITION_AND_LIFETIME_VALUE',
  APPOINTMENT_MIX_BENCHMARK = 'APPOINTMENT_MIX_BENCHMARK',
  BANK_RECONCILIATION = 'BANK_RECONCILIATION',
  BILLING_AGING = 'BILLING_AGING',
  BUNDLES_AND_WELLNESS_PLANS = 'BUNDLES_AND_WELLNESS_PLANS',
  CANCELLATIONS_AND_NO_SHOWS = 'CANCELLATIONS_AND_NO_SHOWS',
  CLIENT_AND_PATIENT_STATUS = 'CLIENT_AND_PATIENT_STATUS',
  CLIENT_INITIATED_REQUESTS = 'CLIENT_INITIATED_REQUESTS',
  CLIENT_LIST = 'CLIENT_LIST',
  CONTROLLED_SUBSTANCE_LOG = 'CONTROLLED_SUBSTANCE_LOG',
  DAILY_BILLING_ACTIVITY = 'DAILY_BILLING_ACTIVITY',
  DAILY_RECONCILIATION = 'DAILY_RECONCILIATION',
  DENTAL_AND_HEARTWORM_OVERVIEW = 'DENTAL_AND_HEARTWORM_OVERVIEW',
  DISCOUNT_USAGE = 'DISCOUNT_USAGE',
  END_OF_DAY_AUDIT = 'END_OF_DAY_AUDIT',
  FORWARD_BOOKINGS = 'FORWARD_BOOKINGS',
  GROUP_PERFORMANCE = 'GROUP_PERFORMANCE',
  GROUP_PRICE_LISTS = 'GROUP_PRICE_LISTS',
  INSURED_PATIENTS_VISITS = 'INSURED_PATIENTS_VISITS',
  INVENTORY_AND_CATALOG_AUDIT = 'INVENTORY_AND_CATALOG_AUDIT',
  INVENTORY_USAGE = 'INVENTORY_USAGE',
  INVENTORY_USAGE_AND_AVERAGE_INVOICE = 'INVENTORY_USAGE_AND_AVERAGE_INVOICE',
  INVOICES_AND_PAYMENTS = 'INVOICES_AND_PAYMENTS',
  INVOICE_ACTIVITY = 'INVOICE_ACTIVITY',
  MAGIC_QUADRANT = 'MAGIC_QUADRANT',
  MARKETING_DATA_QUALITY = 'MARKETING_DATA_QUALITY',
  MEDICAL_INSIGHTS = 'MEDICAL_INSIGHTS',
  MONTHLY_SALES_SUMMARY = 'MONTHLY_SALES_SUMMARY',
  NEW_CLIENT_AND_PATIENT_ACTIVITY = 'NEW_CLIENT_AND_PATIENT_ACTIVITY',
  ONLINE_SICK_APPOINTMENTS = 'ONLINE_SICK_APPOINTMENTS',
  OPEN_AND_UNAPPLIED_BILLING = 'OPEN_AND_UNAPPLIED_BILLING',
  OPEN_SOAPS_AND_INVOICES = 'OPEN_SOAPS_AND_INVOICES',
  OVERDUE_TASKS = 'OVERDUE_TASKS',
  PRACTICE_PERFORMANCE = 'PRACTICE_PERFORMANCE',
  PRACTICE_SALES = 'PRACTICE_SALES',
  PRODUCER_SALES = 'PRODUCER_SALES',
  PRODUCTION = 'PRODUCTION',
  RABIES_VACCINE_HISTORY = 'RABIES_VACCINE_HISTORY',
  REFERRAL_BUSINESS = 'REFERRAL_BUSINESS',
  REMINDERS_COMPLIANCE = 'REMINDERS_COMPLIANCE',
  REPEAT_AND_RECHECK_PATIENTS = 'REPEAT_AND_RECHECK_PATIENTS',
  RESOLUTION_RATE = 'RESOLUTION_RATE',
  SALES_SUMMARY_EXCLUDING_UNPAID_OTC = 'SALES_SUMMARY_EXCLUDING_UNPAID_OTC',
  SCHEDULE_MANAGEMENT = 'SCHEDULE_MANAGEMENT',
  SMS_ACTIVITY = 'SMS_ACTIVITY',
  TIME_TRACKING = 'TIME_TRACKING',
  WELLNESS_PLANS = 'WELLNESS_PLANS',
}

export const supportedAnalyticsDashboards = {
  ACCOUNTING: new SupportedDashboardConfig(
    [{ id: 249 }, { id: 473 }],
    '/analytics/accounting',
  ),
  ACQUISITION_AND_LIFETIME_VALUE: new SupportedDashboardConfig(
    [{ id: 405 }],
    '/analytics/customer-acquisition-and-lifetime-value',
  ),
  APPOINTMENT_MIX_BENCHMARK: new SupportedDashboardConfig(
    [{ id: 456 }],
    '/analytics/appointment-mix-benchmark',
    true,
  ),
  BANK_RECONCILIATION: new SupportedDashboardConfig(
    [{ id: 371 }],
    '/analytics/bank-reconciliation',
  ),
  BILLING_AGING: new SupportedDashboardConfig(
    [{ id: 479 }],
    '/analytics/billing-aging',
  ),
  BUNDLES_AND_WELLNESS_PLANS: new SupportedDashboardConfig(
    [{ id: 419 }, { id: 474 }],
    '/analytics/bundles-and-wellness-plans',
  ),
  CANCELLATIONS_AND_NO_SHOWS: new SupportedDashboardConfig(
    [{ id: 421 }, { id: 508, urlSuffix: 'v2' }],
    '/analytics/cancellations-and-no-shows',
  ),
  CLIENT_AND_PATIENT_STATUS: new SupportedDashboardConfig(
    [{ id: 59 }],
    '/analytics/client-and-patient-status',
  ),
  CLIENT_INITIATED_REQUESTS: new SupportedDashboardConfig(
    [{ id: 422 }],
    '/analytics/client-initiated-requests',
  ),
  CLIENT_LIST: new SupportedDashboardConfig(
    [{ id: 408 }, { id: 470 }],
    '/analytics/client-list',
  ),
  CONTROLLED_SUBSTANCE_LOG: new SupportedDashboardConfig(
    [{ id: 407 }],
    '/analytics/controlled-substance-log',
  ),
  DAILY_BILLING_ACTIVITY: new SupportedDashboardConfig(
    [{ id: 484 }],
    '/analytics/daily-billing-activity',
  ),
  DAILY_RECONCILIATION: new SupportedDashboardConfig(
    [{ id: 423, urlSuffix: 'old' }, { id: 368, urlSuffix: 'new' }, { id: 478 }],
    '/analytics/daily-reconciliation',
  ),
  DENTAL_AND_HEARTWORM_OVERVIEW: new SupportedDashboardConfig(
    [{ id: 453 }],
    '/analytics/dental-and-heartworm-overview',
    true,
  ),
  DISCOUNT_USAGE: new SupportedDashboardConfig(
    [{ id: 428 }, { id: 465 }],
    '/analytics/discount-usage',
  ),
  END_OF_DAY_AUDIT: new SupportedDashboardConfig(
    [{ id: 500 }],
    '/analytics/end-of-day-audit',
  ),
  FORWARD_BOOKINGS: new SupportedDashboardConfig(
    [{ id: 402 }],
    '/analytics/forward-bookings',
  ),
  GROUP_PERFORMANCE: new SupportedDashboardConfig(
    [{ id: 410 }],
    '/analytics/group-performance',
  ),
  GROUP_PRICE_LISTS: new SupportedDashboardConfig(
    [{ id: 34 }],
    '/analytics/group-price-lists',
  ),
  INSURED_PATIENTS_VISITS: new SupportedDashboardConfig(
    [{ id: 457 }],
    '/analytics/insured-patients-visits',
    true,
  ),
  INVENTORY_AND_CATALOG_AUDIT: new SupportedDashboardConfig(
    [{ id: 466 }],
    '/analytics/inventory-and-catalog-audit',
  ),
  INVENTORY_USAGE: new SupportedDashboardConfig(
    [{ id: 401 }],
    '/analytics/inventory-usage',
  ),
  INVENTORY_USAGE_AND_AVERAGE_INVOICE: new SupportedDashboardConfig(
    [{ id: 490 }],
    '/analytics/inventory-usage-and-average-invoice',
  ),
  INVOICES_AND_PAYMENTS: new SupportedDashboardConfig(
    [{ id: 372 }],
    '/analytics/invoices-and-payments',
  ),
  INVOICE_ACTIVITY: new SupportedDashboardConfig(
    [{ id: 418 }, { id: 471 }, { id: 485 }],
    '/analytics/invoice-activity',
  ),
  MAGIC_QUADRANT: new SupportedDashboardConfig(
    [{ id: 398 }, { id: 475 }],
    '/analytics/magic-quadrant',
  ),
  MARKETING_DATA_QUALITY: new SupportedDashboardConfig(
    [{ id: 60 }],
    '/analytics/marketing-data-quality',
  ),
  MEDICAL_INSIGHTS: new SupportedDashboardConfig(
    [{ id: 395 }],
    '/analytics/medical-insights',
  ),
  MONTHLY_SALES_SUMMARY: new SupportedDashboardConfig(
    [{ id: 376 }],
    '/analytics/monthly-sales-summary',
  ),
  NEW_CLIENT_AND_PATIENT_ACTIVITY: new SupportedDashboardConfig(
    [{ id: 393 }],
    '/analytics/new-client-and-patient-activity',
  ),
  ONLINE_SICK_APPOINTMENTS: new SupportedDashboardConfig(
    [{ id: 494 }],
    '/analytics/online-sick-appointments',
    true,
  ),
  OPEN_AND_UNAPPLIED_BILLING: new SupportedDashboardConfig(
    [{ id: 476 }],
    '/analytics/open-and-unapplied-billing',
  ),
  OPEN_SOAPS_AND_INVOICES: new SupportedDashboardConfig(
    [{ id: 392 }, { id: 472 }],
    '/analytics/open-soaps-and-invoices',
  ),
  OVERDUE_TASKS: new SupportedDashboardConfig(
    [{ id: 495 }],
    '/analytics/overdue-tasks',
  ),
  PRACTICE_PERFORMANCE: new SupportedDashboardConfig(
    [{ id: 390 }],
    '/analytics/practice-performance',
  ),
  PRACTICE_SALES: new SupportedDashboardConfig(
    [{ id: 416 }],
    '/analytics/practice-sales',
  ),
  PRODUCER_SALES: new SupportedDashboardConfig(
    [{ id: 417 }, { id: 486 }],
    '/analytics/producer-sales',
  ),
  PRODUCTION: new SupportedDashboardConfig(
    [{ id: 374 }],
    '/analytics/production',
  ),
  RABIES_VACCINE_HISTORY: new SupportedDashboardConfig(
    [{ id: 389 }],
    '/analytics/rabies-vaccine-history',
  ),
  REFERRAL_BUSINESS: new SupportedDashboardConfig(
    [{ id: 387 }],
    '/analytics/referral-business',
  ),
  REMINDERS_COMPLIANCE: new SupportedDashboardConfig(
    [{ id: 386 }],
    '/analytics/reminders-compliance',
  ),
  REPEAT_AND_RECHECK_PATIENTS: new SupportedDashboardConfig(
    [{ id: 491 }],
    '/analytics/repeat-and-recheck-patients',
    true,
  ),
  RESOLUTION_RATE: new SupportedDashboardConfig(
    [{ id: 458 }],
    '/analytics/resolution-rate',
  ),
  SALES_SUMMARY_EXCLUDING_UNPAID_OTC: new SupportedDashboardConfig(
    [{ id: 415 }],
    '/analytics/sales-summary-excluding-unpaid-otc',
  ),
  SCHEDULE_MANAGEMENT: new SupportedDashboardConfig(
    [
      { id: 379, urlSuffix: 'old' },
      { id: 420, urlSuffix: 'new' },
    ],
    '/analytics/schedule-management',
  ),
  SMS_ACTIVITY: new SupportedDashboardConfig(
    [{ id: 481 }],
    '/analytics/sms-activity',
  ),
  TIME_TRACKING: new SupportedDashboardConfig(
    [{ id: 27 }],
    '/analytics/time-tracking',
  ),
  WELLNESS_PLANS: new SupportedDashboardConfig(
    [{ id: 377 }],
    '/analytics/wellness-plans',
  ),
}
