import React from 'react'

import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import { OrderType } from '~/constants/SOAPStates'
import { getClientFinanceLoading } from '~/store/duck/clientFinanceData'
import { useGetDeleteChargeSheetItemAction } from '~/store/hooks/chargeSheet'
import {
  useIsFood,
  useIsGlobalInventoryItem,
  useOpenPrescriptionDialogAfterFetchUnifiedOrder,
  useUpdateOrderNotes,
} from '~/store/hooks/orders'
import { Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'
import { useHandleDelete } from '../../utils/useHandleDelete'

export interface InventoryItemActionsLineItemProps
  extends ItemWithBadgesActionsProps {
  isInvoice: boolean
  onDelete?: () => void
  order: Order
}

// TODO need to move it to new OpenApi model once it will be ready, delete doesn't need new model to work
const InventoryItemActionsLineItem = ({
  order,
  onDelete: onDeleteProp,
  isInvoice,
  ...rest
}: InventoryItemActionsLineItemProps) => {
  const handleUpdateNotes = useUpdateOrderNotes(order)
  const isGlobalItem = useIsGlobalInventoryItem(order)
  const isFood = useIsFood(order)

  const deleteAction = useGetDeleteChargeSheetItemAction(order)
  const onDeleteInventoryItem = useCloseAfterCreation(
    onDeleteProp,
    getClientFinanceLoading,
  )

  const handleDelete = useHandleDelete(
    order,
    deleteAction,
    onDeleteProp && onDeleteInventoryItem,
  )

  const { fetchUnifiedOrderAndOpenPrescription } =
    useOpenPrescriptionDialogAfterFetchUnifiedOrder({
      overrideParams: {
        patientId: order.patientId,
        clientId: order.clientId,
        outsideSoap: !order.soapId,
        soapId: order.soapId,
      },
      isInvoice,
    })

  const openPrescription = () => {
    if (order.logId) {
      fetchUnifiedOrderAndOpenPrescription(order.logId, OrderType.INVENTORY)
    }
  }

  return (
    <ItemWithBadgesActions
      hasNotes={Boolean(order.notes)}
      tooltip={<OrderTooltip forceShowPrices order={order} />}
      onAddRX={isFood ? openPrescription : undefined}
      onDelete={handleDelete}
      onEdit={isGlobalItem ? openPrescription : undefined}
      onUpdateNotes={isGlobalItem ? undefined : handleUpdateNotes}
      {...rest}
    />
  )
}

export default InventoryItemActionsLineItem
