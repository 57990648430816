import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { DateUtils, Nil } from '@pbt/pbt-ui-components'

import { Payment as GraphQLPayment } from '~/api/graphql/generated/types'
import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import PaymentTypes from '~/constants/paymentTypes'
import { ExtendPayment, Payment } from '~/types'
import { getPaymentPaidPersonName } from '~/utils/finance'
import { getPaymentMethodString, getPaymentType } from '~/utils/paymentUtils'
import useDialog from '~/utils/useDialog'

import { formatMoneyRange, formatPositiveMoney } from './invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.totalBorder,
      },
    },
    leftColumn: {
      borderRight: theme.constants.totalBorder,
    },
  }),
  { name: 'InvoicePaymentTile' },
)

interface InvoicePaymentTileProps {
  clientId: string | Nil
  invoiceId: string
  isEstimate: boolean
  navigateToRefundLanding?: () => void
  payment: GraphQLPayment | Payment
}

export const InvoicePaymentTile = ({
  clientId,
  invoiceId,
  isEstimate,
  navigateToRefundLanding,
  payment,
}: InvoicePaymentTileProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const [openPayment] = useDialog(DialogNames.PAYMENT_DETAILS)

  const paidPersonName = getPaymentPaidPersonName(payment)
  const paymentType = getPaymentType(payment)

  const signedSplitAmount = R.prop('signedSplitAmount', payment) || 0
  const isReverseCharge = !isEstimate
    ? signedSplitAmount > 0
    : paymentType === PaymentTypes.REVERSE_CHARGE

  const isCreditAdjustment = paymentType === PaymentTypes.ADJUSTMENT

  const money = payment.amount

  const actionText = isCreditAdjustment
    ? t('Common:CREDIT_ADJUSTMENT')
    : isReverseCharge
      ? paidPersonName
        ? `${t('Common:PAYMENTS.REFUNDED_TO')} ${paidPersonName}`
        : t('Common:PAYMENTS.REFUNDED')
      : paidPersonName
        ? `${t('Common:PAYMENTS.PAID_BY')} ${paidPersonName}`
        : null

  return (
    <Grid container item alignItems="center" className={classes.row}>
      <Grid
        item
        alignItems="flex-start"
        className={classes.leftColumn}
        flexDirection="column"
        pl={3}
        xs={8}
      >
        <Typography.H3>
          {actionText || t('Invoices:PAID_INFO_IS_MISSING')}
        </Typography.H3>
        <Typography.Link
          onClick={() => {
            openPayment({
              payment: payment as ExtendPayment,
              clientId,
              isPossibleToViewInvoice: true,
              forceNotNavigateToBalanceAfterRefund: true,
              invoiceId,
              navigateToRefundLanding,
            })
          }}
        >
          {`${DateUtils.formatDate(
            payment.creationDate,
          )} | ${getPaymentMethodString(payment)}`}
        </Typography.Link>
      </Grid>
      <Grid item pr={3} xs={4}>
        <Typography.H3 sx={{ textAlign: 'right' }}>
          {isReverseCharge
            ? formatPositiveMoney(money)
            : formatMoneyRange(money, true)}
        </Typography.H3>
        {Boolean(payment.serviceFeeIncAmount) && (
          <Typography.H3 sx={{ textAlign: 'right' }}>
            {t('Invoices:INCLUDING_SERVICE_FEE', {
              amount: formatMoneyRange(payment.serviceFeeIncAmount),
            })}
          </Typography.H3>
        )}
      </Grid>
    </Grid>
  )
}
