import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { clearSchedulingAssignment } from '~/store/actions/timetable'
import {
  getSchedulingClientId,
  getSchedulingPatientId,
} from '~/store/reducers/timetable'

import AppointmentClientAndPatientDetails from '../scheduler/appointment/AppointmentClientAndPatientDetails'

const useStyles = makeStyles(
  () => ({
    closeButton: {
      position: 'absolute',
      right: 0,
    },
  }),
  { name: 'TimetableSchedulingSection' },
)

const TimetableSchedulingSection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const schedulingClientId = useSelector(getSchedulingClientId)
  const schedulingPatientId = useSelector(getSchedulingPatientId)

  if (!schedulingClientId || !schedulingPatientId) {
    return null
  }

  return (
    <Grid container position="relative">
      <IconButton
        className={classes.closeButton}
        size="small"
        onClick={() => {
          dispatch(clearSchedulingAssignment())
        }}
      >
        <Close />
      </IconButton>
      <AppointmentClientAndPatientDetails
        clientId={schedulingClientId}
        patientId={schedulingPatientId}
      />
    </Grid>
  )
}

export default TimetableSchedulingSection
