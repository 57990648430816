import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  MutationGenerateSurveyLinkArgs,
  MutationResetSoapTemplateToDefaultArgs,
  MutationUpdateSoapTemplateArgs,
  QuerySoapActiveTemplateArgs,
  QueryUncoveredSoapTemplateWidgetsArgs,
  SoapTemplate as SoapApiTemplate,
} from '~/api/graphql/generated/types'
import { SoapTemplate } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '..'
import { convertSoapTemplateData } from '../hooks/soap'

export type SoapCustomizationState = {
  activeTemplate: SoapTemplate | undefined
  clientSurveyLink: string | undefined
  error: string | Nil
  errorCode: number | undefined
  isFetching: boolean
  isFetchingClientSurveyLink: boolean
  isResetting: boolean
  isUpdating: boolean
  templates: SoapTemplate[] | undefined
  uncoveredWidgetIds: string[]
}

export const INITIAL_STATE: SoapCustomizationState = {
  activeTemplate: undefined,
  error: null,
  isFetching: false,
  isResetting: false,
  isUpdating: false,
  isFetchingClientSurveyLink: false,
  templates: undefined,
  uncoveredWidgetIds: [],
  clientSurveyLink: undefined,
  errorCode: undefined,
}

const soapCustomizationSlice = createSlice({
  name: 'soapV2',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSoapActiveTemplate: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QuerySoapActiveTemplateArgs>,
    ) => {
      state.isFetching = true
      state.error = null
    },
    fetchSoapActiveTemplateSuccess: (
      state,
      { payload: template }: PayloadAction<SoapApiTemplate>,
    ) => {
      state.isFetching = false
      state.activeTemplate = convertSoapTemplateData(template)
    },
    fetchSoapActiveTemplateFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isFetching = false
      state.error = getErrorMessage(payload.error)
    },
    fetchUncoveredSoapTemplateWidgets: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryUncoveredSoapTemplateWidgetsArgs>,
    ) => {
      state.isFetching = true
      state.error = null
    },
    fetchUncoveredSoapTemplateWidgetsSuccess: (
      state,
      { payload: uncoveredWidgetIds }: PayloadAction<string[]>,
    ) => {
      state.isFetching = false
      state.uncoveredWidgetIds = uncoveredWidgetIds
    },
    fetchUncoveredSoapTemplateWidgetsFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isFetching = false
      state.error = getErrorMessage(payload.error)
    },
    fetchClientSurveyLink: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationGenerateSurveyLinkArgs>,
    ) => {
      state.isFetchingClientSurveyLink = true
      state.error = null
    },
    fetchClientSurveyLinkSuccess: (
      state,
      { payload: link }: PayloadAction<string>,
    ) => {
      state.isFetchingClientSurveyLink = false
      state.clientSurveyLink = link
    },
    fetchClientSurveyLinkFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isFetchingClientSurveyLink = false
      state.error = getErrorMessage(payload.error)
      state.errorCode = payload.error.status
    },
    resetSoapTemplateToDefault: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationResetSoapTemplateToDefaultArgs>,
    ) => {
      state.isResetting = true
      state.error = null
    },
    resetSoapTemplateToDefaultSuccess: (
      state,
      { payload: template }: PayloadAction<SoapApiTemplate>,
    ) => {
      state.isResetting = false
      state.activeTemplate = convertSoapTemplateData(template)
    },
    resetSoapTemplateToDefaultFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isResetting = false
      state.error = getErrorMessage(payload.error)
    },
    updateSoapTemplate: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUpdateSoapTemplateArgs>,
    ) => {
      state.isUpdating = true
      state.error = null
    },
    updateSoapTemplateSuccess: (
      state,
      { payload: template }: PayloadAction<SoapApiTemplate>,
    ) => {
      state.isUpdating = false
      state.activeTemplate = convertSoapTemplateData(template)
    },
    updateSoapTemplateFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isUpdating = false
      state.error = getErrorMessage(payload.error)
    },
  },
})

const { actions, reducer } = soapCustomizationSlice

export const {
  fetchSoapActiveTemplate,
  fetchSoapActiveTemplateFailure,
  fetchSoapActiveTemplateSuccess,
  fetchUncoveredSoapTemplateWidgets,
  fetchUncoveredSoapTemplateWidgetsFailure,
  fetchUncoveredSoapTemplateWidgetsSuccess,
  fetchClientSurveyLink,
  fetchClientSurveyLinkFailure,
  fetchClientSurveyLinkSuccess,
  resetSoapTemplateToDefault,
  resetSoapTemplateToDefaultFailure,
  resetSoapTemplateToDefaultSuccess,
  updateSoapTemplate,
  updateSoapTemplateFailure,
  updateSoapTemplateSuccess,
} = actions

export default reducer

const getSoapV2 = (state: RootState): SoapCustomizationState => state.soapV2

export const getIsFetchingSoapTemplate = (state: RootState) =>
  getSoapV2(state).isFetching
export const getIsResettingSoapTemplate = (state: RootState) =>
  getSoapV2(state).isResetting
export const getIsUpdatingSoapTemplate = (state: RootState) =>
  getSoapV2(state).isUpdating
export const getIsFetchingClientSurveyLink = (state: RootState) =>
  getSoapV2(state).isFetchingClientSurveyLink

export const getSoapActiveTemplate = (state: RootState) =>
  getSoapV2(state).activeTemplate
export const getSoapUncoveredWidgetIds = (state: RootState) =>
  getSoapV2(state).uncoveredWidgetIds
export const getSoapActiveTemplateTabs = createSelector(
  getSoapActiveTemplate,
  (template) => template?.tabs,
)
export const getClientSurveyLink = (state: RootState) =>
  getSoapV2(state).clientSurveyLink
export const getError = (state: RootState) => getSoapV2(state).error
export const getErrorCode = (state: RootState) => getSoapV2(state).errorCode
