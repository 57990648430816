import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAppointment } from '~/store/actions/timetable'
import { useLockableComponent } from '~/store/hooks/soap'
import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'

import { useIsSoapCustomizationEnabled } from '../utils/useIsSoapCustomizationEnabled'
import ReasonForVisitWidgetLocked from './ReasonForVisitWidgetLocked'
import ReasonForVisitWidgetUnlocked from './ReasonForVisitWidgetUnlocked'

const ReasonForVisitWidget = () => {
  const dispatch = useDispatch()

  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  const ReasonForVisitWidgetComponent = useLockableComponent(
    ReasonForVisitWidgetUnlocked,
    ReasonForVisitWidgetLocked,
  )

  useEffect(() => {
    if (isSoapCustomizationEnabled && appointmentId) {
      dispatch(fetchAppointment(appointmentId))
    }
  }, [appointmentId])

  if (!appointment) {
    return null
  }

  return (
    <ReasonForVisitWidgetComponent
      appointmentId={appointment.id}
      appointmentNotes={appointment.notes}
    />
  )
}

export default ReasonForVisitWidget
