import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Nil, Text } from '@pbt/pbt-ui-components'
import { Boop } from '@pbt/pbt-ui-components/src/icons'

import ClientWarningChip from '~/components/common/ClientWarningChip'
import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import Link from '~/components/common/link/Link'
import PatientWarningChip from '~/components/common/PatientWarningChip'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '~/components/dashboard/link-chewy-account/LinkedChewyAccountContainer'
import FeatureToggle from '~/constants/featureToggle'
import { FINANCE_TABLE_PADDING_X_SPACING_VALUE } from '~/constants/financeTable'
import { useGetClientFinancePatientData } from '~/store/hooks/chargeSheet'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientName } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { CustomNavigationPrevState } from '~/types'

import DetailsBackButton from '../../clients/DetailsBackButton'
import { PatientPreferencesChip } from '../../clients/patient/PatientPreferencesChip'
import { ClientPreferencesChip } from '../../clients/preferences/ClientPreferencesChip'
import WellnessPlanMemberButton from '../../wellness-plans/WellnessPlanMemberButton'
import ChargesHeaderClientInfo from './ChargesHeaderClientInfo'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderBottom: 'none',
    padding: theme.spacing(0.5, 0, 0.5, FINANCE_TABLE_PADDING_X_SPACING_VALUE),
  },
  title: {
    padding: theme.spacing(1, 0, 0.5, FINANCE_TABLE_PADDING_X_SPACING_VALUE),
  },
  name: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontWeight: 500,
    textDecoration: 'underline',
    color: theme.colors.secondaryText,
    marginRight: theme.spacing(1),
  },
  data: {
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  chip: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(0.5),
  },
  clientWithAlert: {
    display: 'inline-flex',
  },
  wellnessChip: {
    width: 16,
    height: 16,
  },
  withBoxShadow: {
    boxShadow: '0px 2px 4px 0px #0000001A',
    marginBottom: theme.spacing(1.5),
  },
  backButtonContainer: {
    backgroundColor: theme.colors.tableEvenItem,
  },
}))

interface ChargesHeaderProps {
  clientId: string | Nil
  headerAlert?: ReactNode
  isError: boolean
  isInvoice?: boolean
  isLoading?: boolean
  isRefundInvoice?: boolean
  soapClientId: string | Nil
  soapPatientId: string | Nil
  title: string
}

const ChargesHeader = ({
  title,
  clientId,
  soapPatientId,
  soapClientId,
  isInvoice,
  isRefundInvoice,
  isLoading,
  isError,
  headerAlert,
}: ChargesHeaderProps) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('Common')

  const { from } = (location.state as CustomNavigationPrevState) || {}
  const client = useSelector(getUser(soapClientId))
  const patientName = useSelector(getPatientName(soapPatientId))
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const clientPatients = useGetClientFinancePatientData(
    clientId,
    isInvoice,
    isRefundInvoice,
  ).map((data, idx) => ({ id: idx, ...data }))

  const PrevUrlNameMap = {
    'admin/catalog/inventories/on_hand': t('Common:INVENTORY_ON-HAND'),
    'lab-tests-dashboard': t('Common:LAB_TEST_DASHBOARD'),
    'imaging-dashboard': t('Common:IMAGING_REQUESTS_DASHBOARD'),
    client: t('Common:CLIENT_AND_PATIENT_DETAIL'),
    patient: t('Common:CLIENT_AND_PATIENT_DETAIL'),
    soap:
      patientName && client?.lastName
        ? `${patientName} ${client.lastName} ${t('Common:SOAP')}`
        : null,
    balance: t('Common:CLIENT_BALANCE'),
  }

  const matchKey = R.keys(PrevUrlNameMap).find((pathPart) =>
    from?.includes(pathPart),
  ) as keyof typeof PrevUrlNameMap

  const backButtonName = from
    ? PrevUrlNameMap[matchKey]
    : PrevUrlNameMap.balance

  const onBackButtonClick = () => {
    if (from) {
      navigate(`/${from}`)
    } else {
      navigate(`/balance/${clientId}`)
    }
  }

  return (
    <>
      {!isLoading && !isError && (
        <Grid
          container
          alignItems="center"
          className={classes.backButtonContainer}
          height={40}
          px={1}
        >
          <DetailsBackButton fullWidth thin onClick={onBackButtonClick}>
            {backButtonName}
          </DetailsBackButton>
        </Grid>
      )}
      {headerAlert && (
        <Grid container pl={3} pt={3}>
          {headerAlert}
        </Grid>
      )}
      <Table className={classes.withBoxShadow}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classNames(classes.tableCell, classes.title)}
              colSpan={2}
            >
              <Text variant="h1">{title}</Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientPatients.map(
            ({
              id,
              name,
              data,
              isBoopUser,
              isClient,
              patientId,
              primaryShippingAddress,
            }) => (
              <TableRow key={id}>
                <TableCell className={classes.tableCell}>
                  <Grid container item direction="row" wrap="nowrap">
                    <Link
                      className={classes.name}
                      target="_blank"
                      to={
                        isClient
                          ? `/client/${clientId}`
                          : `/client/${clientId}/patient/${patientId}`
                      }
                    >
                      {name}
                    </Link>
                    {isClient ? (
                      <ClientWarningChip
                        className={classes.chip}
                        clientId={clientId}
                      />
                    ) : (
                      <PatientWarningChip
                        className={classes.chip}
                        patientId={patientId}
                      />
                    )}
                    {isClient ? (
                      <ClientPreferencesChip
                        className={classes.chip}
                        clientId={clientId}
                      />
                    ) : (
                      <PatientPreferencesChip
                        className={classes.chip}
                        patientId={patientId}
                      />
                    )}
                    <LinkedChewyAccountContainer
                      accountType={
                        isClient
                          ? KyriosAccountType.CLIENT
                          : KyriosAccountType.PATIENT
                      }
                      className={classes.chip}
                      clientId={clientId}
                      patientId={patientId}
                    />
                    {!isClient && (
                      <WellnessPlanMemberButton
                        chip
                        readonly
                        buttonClassName={classes.chip}
                        className={classes.wellnessChip}
                        clientId={clientId}
                        patientId={patientId}
                      />
                    )}
                    {!isBoopDisablementEnabled && isBoopUser && (
                      <Boop className={classes.chip} />
                    )}
                    {isClient && (
                      <BusinessShareIcon
                        noMargin
                        businessIds={client?.clientInContextBusinesses}
                        className={classes.chip}
                      />
                    )}
                  </Grid>
                </TableCell>
                <TableCell className={classes.tableCell} width="100%">
                  <ChargesHeaderClientInfo
                    data={data}
                    isClient={isClient}
                    isLoading={isLoading}
                    isRefundInvoice={isRefundInvoice ?? false}
                    name={name}
                    primaryShippingAddress={primaryShippingAddress}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default ChargesHeader
