import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import makeStyles from '@mui/styles/makeStyles'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import { BusinessIdParamSync } from '~/components/BusinessIdParamSync'
import PrintFile from '~/components/common/print/PrintFile'
import DialogNames from '~/constants/DialogNames'
import { USER_DEACTIVATED_MESSAGE } from '~/constants/errorMessages'
import { FETCH_CURRENT_USER_FAILURE } from '~/store/actions/types/auth'
import { getAuthErrorType, getCurrentBusinessId } from '~/store/reducers/auth'
import { auth0Enabled } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsAuthenticated from '~/utils/useIsAuthenticated'
import useLogout from '~/utils/useLogout'

import ErrorsBoundary from '../error/ErrorsBoundary'
import { SyncBrowserTabs } from '../SyncBrowserTabs'
import CurrentUserFetcher from './CurrentUserFetcher'
import IntercomWidget from './IntercomWidget'
import RCIFrameProvider from './internal-chat/RCIFrameProvider'
import NavigationWrapper from './NavigationWrapper'
import Progress from './Progress'
import { useGetAnalyticsRoutes } from './routes/analyticsRoutes'
import { useGetMainRoutes } from './routes/mainRoutes'
import ProtectedRoute from './routes/ProtectedRoute'
import { renderMultiPathRoute } from './routes/utils'
import Timezone from './Timezone'
import BrowserVersionWatcher from './watchers/BrowserVersionWatcher'
import BusinessWatcher from './watchers/BusinessWatcher'
import ForceLogOutWatcher from './watchers/ForceLogOutWatcher'
import PrescriptionWatcher from './watchers/PrescriptionWatcher'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      minHeight: '100vh',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: theme.colors.contentBackground,
      [theme.breakpoints.down('md')]: {
        height: 1,
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.constants.progressBarHeight}px)`,
      },
    },
    toolbar: {
      display: 'flex',
      padding: theme.spacing(0, 1),
      [theme.breakpoints.up('md')]: {
        ...theme.mixins.toolbar,
      },
    },
  }),
  { name: 'RootPage' },
)

const RootPage = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const errorType = useSelector(getAuthErrorType)
  const currentBusinessId = useSelector(getCurrentBusinessId)

  const { isAuthenticated } = useIsAuthenticated()

  const [openAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const logout = useLogout()
  const mainRoutes = useGetMainRoutes()
  const analyticsRoutes = useGetAnalyticsRoutes()

  useEffect(() => {
    if (!auth0Enabled && errorType === FETCH_CURRENT_USER_FAILURE) {
      openAlert({
        cancelButtonText: t('Common:LOG_OUT'),
        message: USER_DEACTIVATED_MESSAGE,
        onCancel: () => logout('/auth/login'),
        onClose: () => logout('/auth/login'),
      })
    }
  }, [errorType])

  if (!auth0Enabled && !isAuthenticated) {
    return <Navigate replace to="/auth/login" />
  }

  return (
    <>
      <Timezone />
      <CurrentUserFetcher>
        <RCIFrameProvider>
          <NavigationWrapper />
          <BusinessWatcher />
          <SyncBrowserTabs />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <IntercomWidget />
            <PrintFile />
            <ErrorsBoundary>
              <BusinessIdParamSync>
                <Progress />
                {currentBusinessId && (
                  <Routes>
                    {/* root path: / */}
                    <Route
                      element={<Navigate replace to="/landing" />}
                      path="/"
                    />
                    {[...mainRoutes, ...analyticsRoutes].map(
                      ({ path, ...rest }, pathIndex: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={pathIndex}>
                          {renderMultiPathRoute({
                            path,
                            element: <ProtectedRoute {...rest} />,
                          })}
                        </React.Fragment>
                      ),
                    )}
                  </Routes>
                )}
              </BusinessIdParamSync>
            </ErrorsBoundary>
          </main>
          <PrescriptionWatcher />
          {/* This needs to be at the bottom since the watcher may instantly produce logout action when mounted */}
          {/* thus clearing the redux store */}
          {/* which will cause the component in main section to fire actions without proper data (businessId etc) */}
          {/* putting it here is safe since logout cancels all sagas anyway */}
          <ForceLogOutWatcher />
          <BrowserVersionWatcher />
        </RCIFrameProvider>
      </CurrentUserFetcher>
    </>
  )
}

export default auth0Enabled
  ? withAuthenticationRequired(RootPage, {
      // eslint-disable-next-line react/no-multi-comp
      onRedirecting: () => <CircularProgressOverlay open />,
    })
  : RootPage
