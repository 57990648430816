import React, { forwardRef } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, Text, VerticalDivider } from '@pbt/pbt-ui-components'

import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import { Order } from '~/types'

import OrderListItem, { OrderListItemProps } from './OrderListItem'

const STATUS_COLUMN_WIDTH = 64

const useStyles = makeStyles(
  (theme) => ({
    divider: {
      position: 'absolute',
      left: 72,
      top: 0,
      bottom: 0,
      height: '100%',
      borderRight: theme.constants.tabBorder,
    },
    stateLabel: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'ReminderListItem' },
)

export interface ReminderListItemProps extends OrderListItemProps {
  disabledWeakMap: WeakMap<Order, boolean>
  editDisabled: boolean
  selectedSubItem: Order
  updateSelectedSubItem: (order: Order) => void
  useChargesWidgetView?: boolean
}

const ReminderListItem = forwardRef<HTMLDivElement, ReminderListItemProps>(
  function ReminderListItem(
    {
      order,
      selectedSubItem,
      updateSelectedSubItem,
      disabledWeakMap,
      editDisabled,
      useChargesWidgetView,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()

    return useChargesWidgetView ? (
      <Grid container item flexWrap="nowrap" pl={1} py={0.5} ref={ref}>
        <Grid
          container
          alignItems="flex-start"
          minWidth={STATUS_COLUMN_WIDTH}
          px={0.25}
          width={STATUS_COLUMN_WIDTH}
        >
          {order.isFirstItemInCategory && (
            <>
              <ReminderStateLabel
                p={0}
                stateName={order.categoryName}
                variant="small"
              />
              <VerticalDivider classes={{ divider: classes.divider }} />
            </>
          )}
        </Grid>
        <Grid
          container
          item
          pl={0.5}
          width={`calc(100% - ${STATUS_COLUMN_WIDTH}px)`}
        >
          <Grid container pl={2}>
            <Text variant="lowAccent2">
              {DateUtils.formatDate(order.dueDatetime)}
            </Text>
            &nbsp;&nbsp;
            <Text variant="body2">{order.name}</Text>
          </Grid>
          {order.items?.map((item) => (
            <OrderListItem
              {...rest}
              useZebraItemsStyling
              isDisabled={
                editDisabled || (disabledWeakMap && disabledWeakMap.get(item))
              }
              key={item.id}
              order={item}
            />
          ))}
        </Grid>
      </Grid>
    ) : (
      <Grid
        container
        item
        direction="column"
        mb={order.isLastItemInCategory ? 1 : 0}
        pl={1}
        py={0.5}
        ref={ref}
      >
        {order.isFirstItemInCategory && (
          <Grid item mb={1}>
            <ReminderStateLabel
              className={classes.stateLabel}
              py={0.5}
              stateName={order.categoryName}
            />
          </Grid>
        )}
        <Grid item pl={1}>
          <Text variant="body2">{`${order.name} | ${DateUtils.formatDate(
            order.dueDatetime,
          )}`}</Text>
        </Grid>
        {order.items?.map((item) => (
          <OrderListItem
            {...rest}
            isDisabled={
              editDisabled || (disabledWeakMap && disabledWeakMap.get(item))
            }
            key={item.id}
            order={item}
          />
        ))}
      </Grid>
    )
  },
)

export default ReminderListItem
