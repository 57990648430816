import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { HtmlNotesPreview } from '@pbt/pbt-ui-components'

import { Order } from '~/types'

export interface LabTestContentProps {
  className?: string
  order: Order
}

const LabTestContent = ({ className, order }: LabTestContentProps) => {
  const { t } = useTranslation('Common')

  return (
    <Grid item className={className}>
      {order.notes && (
        <HtmlNotesPreview
          fontSize="1.4rem"
          notes={`${t('Common:NOTES')}: ${order.notes}`}
        />
      )}
    </Grid>
  )
}

export default LabTestContent
