import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType, Field } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

export type UseSoapTemplateAlert = {
  fieldTabs: Field
  onReset: () => void
}

export const useSoapTemplateAlert = ({
  fieldTabs,
  onReset,
}: UseSoapTemplateAlert) => {
  const { t } = useTranslation(['Admin', 'Common'])

  const [openWarningAlert, closeWarningAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  useEffect(() => {
    if (fieldTabs.errors && !fieldTabs.valid) {
      openWarningAlert({
        iconType: AlertIconType.WARN,
        message: t('Admin:SOAP_CUSTOMIZATION.TAB_WIDGETS_ERROR'),
        okButtonText: t('Common:GOT_IT'),
        onOk: () => {
          closeWarningAlert()
          onReset()
        },
      })
    }
  }, [fieldTabs.errors])
}
