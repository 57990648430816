import React from 'react'
import {
  Calendar,
  Nil,
  PuiPopper,
  PuiPopperProps,
} from '@pbt/pbt-ui-components'

export interface DatePopperProps extends Omit<PuiPopperProps, 'onChange'> {
  onChange: (value: string | Nil) => void
  value: string
}

const DatePopper = ({ value, onChange, ...rest }: DatePopperProps) => (
  <PuiPopper hideCloseButton {...rest}>
    <Calendar value={value} variant="static" onChange={onChange} />
  </PuiPopper>
)

export default DatePopper
