import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { pluck } from 'ramda'
import { Constant, Text } from '@pbt/pbt-ui-components'

import {
  fetchModality,
  getImagingModalityIsLoading,
  getImagingVendorModality,
} from '~/store/duck/imagingOrders'
import { getImagingModalities } from '~/store/reducers/constants'
import { ImagingVendorModality } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      border: theme.constants.tableBorder,
      marginTop: theme.spacing(2),
    },
    tableCellHead: {
      padding: theme.spacing(0.5, 1),
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    tableCell: {
      padding: theme.spacing(1.5, 1),
    },
    button: {
      minWidth: 150,
      height: 40,
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'ModalitiesTable' },
)

export interface ModalitiesTableProps {
  active: boolean
  businessId: string
  vendorId: string
  vendorName: string
}

const ModalitiesTable = ({
  active,
  businessId,
  vendorId,
  vendorName,
}: ModalitiesTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const businessScopeModalities: ImagingVendorModality[] =
    useSelector(getImagingVendorModality(vendorId)) || []
  const isLoading: boolean = useSelector(getImagingModalityIsLoading)
  const ImagingModalities: Constant[] = useSelector(getImagingModalities)
  const businessScopeModalityIds = pluck('modalityId', businessScopeModalities)
  const modalityOptions = ImagingModalities.filter(({ id }) =>
    businessScopeModalityIds.includes(id),
  )
  const isEmpty = !modalityOptions.length

  const onRefreshList = () => {
    dispatch(fetchModality(businessId, true))
  }

  return (
    <Grid container>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHead}>
              <Text variant="lowAccent2">
                {t('Businesses:INTEGRATION.NAME_MODALITIES', {
                  name: vendorName,
                })}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty ? (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Text variant="body2">
                  {t('Businesses:INTEGRATION.NO_MODALITIES_FOUND')}
                </Text>
              </TableCell>
            </TableRow>
          ) : (
            modalityOptions.map(({ name }) => (
              <TableRow className={classes.tableRow} key={name}>
                <TableCell className={classes.tableCell}>
                  <Text variant="body2">{name}</Text>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {active && (
        <Fab
          className={classes.button}
          color="inherit"
          disabled={isLoading}
          variant="extended"
          onClick={onRefreshList}
        >
          {isLoading
            ? `${t('Common:REFRESHING')}...`
            : t('Common:REFRESH_LIST')}
        </Fab>
      )}
    </Grid>
  )
}

export default ModalitiesTable
