import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { ForwardArrow } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(2),
    },
    text: {
      textTransform: 'none',
    },
  }),
  { name: 'ClientsNoSearchResults' },
)

export interface ClientsNoSearchResultsProps {
  clientOnly?: boolean
  onSuggestionClick: (fieldsString: string) => void
  query?: string
}

const ClientsNoSearchResults = ({
  query = '',
  onSuggestionClick,
  clientOnly,
}: ClientsNoSearchResultsProps) => {
  const classes = useStyles()
  const queryItems = query.split(' ')
  const { t } = useTranslation('Search')

  if (queryItems.length <= 1) {
    return null
  }

  const lastName = queryItems.pop()
  const patientName = queryItems.join(' ')

  const handleOnSuggestionClick = () => {
    const fieldsString = clientOnly
      ? `firstName=${patientName};lastName=${lastName}`
      : `patients.name=${patientName};lastName=${lastName}`

    onSuggestionClick(fieldsString)
  }

  return (
    <Button
      fullWidth
      classes={{ root: classes.button }}
      onClick={handleOnSuggestionClick}
    >
      <Text className={classes.text} mr={1}>
        {t('Search:USE_ADVANCED_SEARCH')}
      </Text>
      <ForwardArrow />
    </Button>
  )
}

export default ClientsNoSearchResults
