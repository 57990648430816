import React from 'react'
import { TableCell } from '@mui/material'

export interface ExtendedPriceCellSubHeadProps {
  className?: string
}

const ExtendedPriceCellSubHead = ({
  className,
}: ExtendedPriceCellSubHeadProps) => <TableCell className={className} />

export default ExtendedPriceCellSubHead
