import React, { useEffect, useState } from 'react'
import GoogleMapReact, { fitBounds } from 'google-map-react'

import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'
import { getEnvironment } from '~/utils'
import GoogleMapHelper from '~/utils/GoogleMapHelper'

import aulaStyles from './aula.json'
import Marker from './Marker'

// Default to Washington DC
const defaultCenter = {
  lat: 38.88,
  lng: -77.01,
}

const createMapOptions = (map) => ({
  styles: aulaStyles,
  fullscreenControl: false,
  mapTypeControl: false,
  myLocation: true,
  myLocationButton: true,
  zoomControlOptions: {
    position: map.ControlPosition.RIGHT_BOTTOM,
    style: map.ZoomControlStyle.SMALL,
  },
})

const isCenterDefined = (center) =>
  center &&
  typeof center.lat !== 'undefined' &&
  typeof center.lng !== 'undefined'
const reformatPoint = (point) => ({ lat: point.lat(), lng: point.lng() })

const Map = ({
  autoMove = false,
  center,
  highlightedId = null,
  onBoundsChanged = null,
  onMarkerClick = null,
  points = [],
  zoom = 12,
  onCenterChanged = null,
  onSearchServiceReady = null,
  onZoomChanged = null,
}) => {
  const [api, setApi] = useState(null)
  const [mapSize, setMapSize] = useState(null)
  const [googleMapHelper, setGoogleMapHelper] = useState(null)

  const isDefined = isCenterDefined(center)

  const setDefaultPosition = () => onCenterChanged(defaultCenter)

  useEffect(() => {
    if (isDefined) {
      return
    }
    if (navigator.geolocation) {
      let timer = setTimeout(setDefaultPosition, 800)
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (timer) {
            onCenterChanged({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          }
          clearTimeout(timer)
          timer = null
        },
        () => {
          clearTimeout(timer)
          timer = null
          setDefaultPosition()
        },
      )
    } else {
      setDefaultPosition()
    }
  }, [center])

  useEffect(() => {
    if (!autoMove || !points || !points.length || !api || !mapSize) {
      return
    }
    if (points.length === 1) {
      const { location } = points[0].geometry
      onCenterChanged({
        lat: location.lat(),
        lng: location.lng(),
      })
      onZoomChanged(10)
      return
    }
    const bounds = new api.LatLngBounds()
    points.forEach((point) => {
      bounds.extend(point.geometry.location)
    })
    const { center, zoom } = fitBounds(
      {
        ne: reformatPoint(bounds.getNorthEast()),
        sw: reformatPoint(bounds.getSouthWest()),
      },
      mapSize,
    )
    onCenterChanged(center)
    onZoomChanged(zoom)
  }, [points, api, autoMove, mapSize])

  useEffect(() => {
    onSearchServiceReady(googleMapHelper)
    return () => {
      if (googleMapHelper) {
        googleMapHelper.destroy()
      }
    }
  }, [googleMapHelper])

  const onGoogleApiLoaded = ({ map, maps }) => {
    setApi(maps)
    if (googleMapHelper) {
      googleMapHelper.destroy()
    }
    setGoogleMapHelper(new GoogleMapHelper(maps, map))
    setMapSize({ width: map.offsetWidth, height: map.offsetHeight }) // TODO: need to call from onChange
  }

  const onChange = ({ bounds, size }) => {
    if (
      bounds &&
      bounds.ne &&
      typeof bounds.ne.lat !== 'undefined' &&
      onBoundsChanged
    ) {
      onBoundsChanged(bounds)
      setMapSize(size)
    }
  }

  if (!isDefined) {
    return null
  }

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: ENVIRONMENT_VARIABLES[getEnvironment()].GOOGLE_API_KEY,
        libraries: 'places',
      }}
      center={center || defaultCenter}
      options={createMapOptions}
      zoom={zoom}
      onChange={onChange}
      onGoogleApiLoaded={onGoogleApiLoaded}
    >
      {points.map((point) => (
        <Marker
          highlighted={highlightedId === point.place_id}
          key={`${
            point.name
          }${point.geometry.location.lat()}${point.geometry.location.lng()}`}
          lat={point.geometry.location.lat()}
          lng={point.geometry.location.lng()}
          name={point.name}
          onClick={() => onMarkerClick && onMarkerClick(point)}
        />
      ))}
    </GoogleMapReact>
  )
}

export default Map
