import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid, Hidden, Link, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCloseButton, Shadow, Text } from '@pbt/pbt-ui-components'
import {
  getBrowserName,
  getIsOutdatedBrowser,
} from '@pbt/pbt-ui-components/src/utils/browserUtils'

import ChromeLogo from '~/components/common/images/ChromeLogo.svg'
import SafariLogo from '~/components/common/images/SafariLogo.svg'
import recommendedBrowsers from '~/constants/recommendedBrowsers'
import { closeRecommendedBrowsersDialog } from '~/store/actions/loginSessionSettings'

import HelpButton from '../HelpButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above'),
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      maxWidth: 729,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: theme.constants.blockShadow,
      borderRadius: '0 2px 2px 2px',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
        paddingTop: theme.spacing(2),
      },
    },
    link: {
      cursor: 'pointer',
      color: theme.colors.link,
      fontSize: '1.6rem',
      textDecoration: 'underline',
      display: 'inline-flex',
      marginLeft: theme.spacing(2),
    },
    catImg: {
      position: 'absolute',
      top: -39,
      left: -24,
      [theme.breakpoints.down('sm')]: {
        left: -8,
      },
    },
    dogImg: {
      position: 'absolute',
      bottom: -10,
      right: -52,
    },
    button: {
      marginTop: theme.spacing(1),
      minWidth: 150,
      height: 40,
    },
    bottomPanel: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(4),
      background: theme.colors.splashScreenBackground,
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    browserContainer: {
      border: theme.constants.tableBorder,
      margin: theme.spacing(0, 2),
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
    content: {
      padding: theme.spacing(0, 5),
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'SwitchBrowser' },
)

const SwitchBrowserSplashScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Clients', 'PageTitles'])

  const isBigScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('lg'),
  )
  const shouldUpdate = getIsOutdatedBrowser()
  const browserName = getBrowserName()
  const showChromeButton =
    !shouldUpdate || (shouldUpdate && browserName === 'Chrome')
  const showSafariButton =
    !isBigScreen || (shouldUpdate && browserName === 'Safari')

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={classes.root}
        direction="column"
      >
        <PuiCloseButton
          onClick={() => dispatch(closeRecommendedBrowsersDialog())}
        />
        <img
          alt={t('PageTitles:CAT')}
          className={classes.catImg}
          src={`${process.env.PUBLIC_URL}/assets/splash/cat@3x.svg`}
        />
        <Hidden smDown>
          <img
            alt={t('PageTitles:DOG')}
            className={classes.dogImg}
            src={`${process.env.PUBLIC_URL}/assets/splash/dog@3x.svg`}
          />
        </Hidden>
        <Grid container item justifyContent="center" px={5} py={2}>
          <Text variant="subheading">
            {shouldUpdate
              ? t(
                  'Clients:SWITCH_BROWSER_SPLASH_SCREEN.UPDATE_BROWSER_VERSION',
                  { browserName },
                )
              : isBigScreen
                ? t('Clients:SWITCH_BROWSER_SPLASH_SCREEN.SWITCH_TO_CHROME')
                : t('Clients:SWITCH_BROWSER_SPLASH_SCREEN.SWITCH_TO_BROWSER')}
          </Text>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classes.content}
          justifyContent="center"
        >
          {showChromeButton && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.browserContainer}
              md={5}
              xs={12}
            >
              <img alt="Chrome logo" src={ChromeLogo} />
              <Link
                className={classes.link}
                href={
                  shouldUpdate
                    ? recommendedBrowsers.chrome.update
                    : recommendedBrowsers.chrome.install
                }
                target="_blank"
              >
                {`${
                  shouldUpdate ? `${t('Common:UPDATE_ACTION')} ` : ''
                }Chrome >`}
              </Link>
            </Grid>
          )}
          {showSafariButton && (
            <Grid
              container
              item
              alignItems="center"
              className={classes.browserContainer}
              md={5}
              xs={12}
            >
              <img alt="Safari logo" src={SafariLogo} />
              <Link
                className={classes.link}
                href={
                  shouldUpdate
                    ? recommendedBrowsers.safari.update
                    : recommendedBrowsers.safari.install
                }
                target="_blank"
              >
                {`${
                  shouldUpdate ? `${t('Common:UPDATE_ACTION')} ` : ''
                }Safari >`}
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className={classes.bottomPanel}
          direction="column"
        >
          <Text variant="body2">
            {t('Clients:SWITCH_BROWSER_SPLASH_SCREEN.ANY_QUESTIONS')}
          </Text>
          <HelpButton
            ComponentProps={{
              color: 'inherit',
              variant: 'extended',
            }}
            className={classes.button}
          >
            {t('Clients:CLIENTS_SPLASH_SCREEN.CHAT_WITH_US')}
          </HelpButton>
        </Grid>
      </Grid>
      <Shadow open />
    </>
  )
}

export default SwitchBrowserSplashScreen
