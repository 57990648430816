import { Defaults } from '@pbt/pbt-ui-components'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchMembershipPaymentsList = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  clientId: string,
) =>
  request(
    getPreludeAPIPath('wplans/payments'),
    { method: 'GET', params: { businessId, from, to, clientId } },
    true,
    Schema.membershipPaymentsList,
  )

export const fetchRefundMembershipPaymentInfo = (
  businessId: string,
  paymentId: string,
) =>
  request(
    getPreludeAPIPath('wplans/payments/refund'),
    {
      method: 'GET',
      params: {
        businessId,
        paymentId,
      },
    },
    true,
  )

export const refundMembershipPayment = (
  businessId: string,
  paymentId: string,
  refundAmount: number,
  notes: string,
) =>
  request(
    getPreludeAPIPath('wplans/payment/refund'),
    {
      method: 'POST',
      params: {
        businessId,
        paymentId,
        refundAmount,
        notes,
      },
    },
    true,
    Schema.membershipPayment,
  )
