import { select } from 'redux-saga/effects'
import { Constant } from '@pbt/pbt-ui-components'

import { getFieldsQueryWithFieldsFixes } from '~/utils'

import { getBusinessStatus, getPracticeTypes } from '../../reducers/constants'

/**
 * Group sort parameter for location list api request
 * favorite - sorting by favorite practices
 * internalOrPracticeName - sorting by internal name if it exists or practice name
 */
export const sortBusinessQueryParameter = 'favorite;internalOrPracticeName'

export function* fixBusinessSearchQuery(fieldsQuery?: string | null) {
  if (!fieldsQuery) {
    return ''
  }
  const BusinessStatus: Constant[] = yield select(getBusinessStatus)
  const PracticeTypes: Constant[] = yield select(getPracticeTypes)

  const fieldsToFix: Record<string, [string, Constant[]]> = {
    status: ['statusIds', BusinessStatus],
    type: ['practiceTypeIds', PracticeTypes],
  }

  return getFieldsQueryWithFieldsFixes(fieldsQuery, fieldsToFix)
}
