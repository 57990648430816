import { gql } from '@apollo/client'

export const FETCH_LAB_TEST_LOG_FILE_GROUPS = gql`
  query LabTestLogFileGroups($labTestLogId: ID!) {
    labTestLogFileGroups(labTestLogId: $labTestLogId) {
      id
      title
      description
      uploadDate
      creationDate
      diagnosedIn
      files {
        id
        name
        extension
        originalFileName
        fileUrl
      }
    }
  }
`
