import React from 'react'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    noBorderCell: {
      borderRight: 'none !important',
    },
  }),
  { name: 'UnitPriceCellSubHead' },
)

export interface UnitPriceCellSubHeadProps {
  className?: string
  isEstimate: boolean
}

const UnitPriceCellSubHead = ({
  className,
  isEstimate,
}: UnitPriceCellSubHeadProps) => {
  const classes = useStyles()

  if (isEstimate) {
    return null
  }

  return (
    <>
      <TableCell className={classNames(className, classes.noBorderCell)} />
      <TableCell className={className} />
    </>
  )
}

export default UnitPriceCellSubHead
