import * as R from 'ramda'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  Estimate,
  InvoiceLineItem,
  PrintInvoiceParams,
  UnsavedInvoice,
} from '~/types'

import { getAPIPath, request } from './core'
import {
  InvoiceSearchInput,
  MutationGenerateHtmlForInvoiceArgs,
  MutationRearrangeGroupedLineItemsArgs,
  UpdateRetailOrderLineItemInput,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  GENERATE_HTML_INVOICE,
  REARRANGE_GROUPED_LINE_ITEMS,
  REARRANGE_INVOICE_LINE_ITEMS,
  UPDATE_INVOICE_LINE_ITEM,
  UPDATE_RETAIL_ORDER_LINE_ITEM,
} from './graphql/mutations'
import {
  FETCH_CLIENT_ESTIMATES_PAGE,
  FETCH_ESTIMATES,
  FETCH_INVOICES_BY_CLIENT_AND_STATES,
  FETCH_UNPAID_INVOICES_BY_CLIENT_AND_STATES,
} from './graphql/queries/finance'
import * as Schema from './schemas'

export const signEstimate = (
  businessId: string,
  estimateId: string,
  signature: string,
  signerId: string,
  signerName: string,
) =>
  request(
    getAPIPath(`finance/invoice/estimate/${estimateId}/sign`),
    {
      method: 'PUT',
      data: { signature, signerId, signerName },
      params: { businessId, splitEstimateAdditionalDiscount: true },
    },
    true,
    Schema.invoice,
  )

export const fetchBalance = (businessId: string, clientId: string) =>
  request(
    getAPIPath('finance/balance'),
    { method: 'GET', params: { businessId, clientId } },
    true,
  )

export const fetchBalanceHistory = (
  businessId: string,
  clientId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('finance/balance/history'),
    { method: 'GET', params: { businessId, clientId, from, to } },
    true,
    Schema.paymentsList,
  )

export const fetchInvoice = (businessId: string, invoiceId: string) =>
  request(
    getAPIPath(`finance/invoice/${invoiceId}`),
    {
      method: 'GET',
      params: { businessId, splitEstimateAdditionalDiscount: true },
    },
    true,
    Schema.invoice,
  )

export const fetchInvoiceByEventId = (
  businessId: string,
  eventId: string,
  clientId: string | Nil,
) =>
  request(
    getAPIPath('finance/invoice/event'),
    { method: 'PUT', params: { businessId, eventId, clientId } },
    true,
    Schema.invoice,
  )

export const fetchInvoiceTemplate = (
  businessId: string,
  clientId: string | Nil,
  patientId: string,
  eventId: string | Nil,
) =>
  request(
    getAPIPath('finance/invoice/event'),
    { method: 'GET', params: { businessId, clientId, patientId, eventId } },
    true,
    Schema.invoice,
  )

export const fetchInvoiceTemplateV2 = (
  businessId: string,
  clientId: string | Nil,
  patientId: string,
  eventId: string | Nil,
) =>
  request(
    getAPIPath('finance/v2/invoice/next_otc'),
    { method: 'GET', params: { businessId, clientId, patientId, eventId } },
    true,
    Schema.invoice,
  )

export const fetchEstimateTemplate = (
  businessId: string,
  clientId: string | Nil,
  patientId: string,
) =>
  request(
    getAPIPath('finance/invoice/next'),
    { method: 'GET', params: { businessId, clientId, patientId } },
    true,
    Schema.invoice,
  )

export const deleteEstimate = (businessId: string, estimateId: string) =>
  request(
    getAPIPath(`finance/invoice/${estimateId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const saveInvoiceV2 = (businessId: string, invoice: UnsavedInvoice) =>
  request(
    getAPIPath('finance/v2/invoice'),
    {
      method: 'POST',
      data: invoice,
      params: {
        businessId,
        clientId: invoice.clientId,
        splitEstimateAdditionalDiscount: true,
      },
    },
    true,
    Schema.invoice,
  )

export const printInvoice = (
  businessId: string,
  invoiceIds: string[],
  params: PrintInvoiceParams | Nil,
) =>
  request(
    getAPIPath('finance/invoice/print'),
    {
      method: 'GET',
      params: {
        businessId,
        invoiceIds: invoiceIds.join(','),
        invoiceId: R.head(invoiceIds),
        ...params,
      },
    },
    true,
  )

export const printInvoiceV2 = requestMutation({
  mutation: GENERATE_HTML_INVOICE,
  variablesHandler: (
    businessId,
    {
      invoiceId,
      expandedGroups,
      includeServiceFee,
    }: MutationGenerateHtmlForInvoiceArgs,
  ) => ({
    businessId,
    invoiceId,
    expandedGroups,
    includeServiceFee,
  }),
})

export const convertToLineItems = (
  businessId: string,
  invoiceId: string,
  clientId: string | Nil,
  patientId: string | Nil,
  invoiceType: string,
  otcItems: InvoiceLineItem[],
) =>
  request(
    getAPIPath('finance/invoice/items'),
    {
      method: 'PUT',
      data: otcItems,
      params: { businessId, invoiceId, clientId, patientId, invoiceType },
    },
    true,
  )

export const fetchEstimates = (businessId: string, patientId: string) =>
  request(
    getAPIPath('finance/invoice/estimates'),
    { method: 'GET', params: { businessId, patientId } },
    true,
    Schema.invoicesArray,
  )

export const fetchPatientEstimates = requestQuery({
  query: FETCH_ESTIMATES,
  variablesHandler: (
    currentBusinessId,
    patientId,
    soapBusinessId,
    from,
    to,
  ) => ({
    businessId: currentBusinessId,
    patientId,
    soapId: soapBusinessId,
    offset: from,
    limit: to,
  }),
  schema: Schema.invoicesList,
})

export const fetchClientEstimates = requestQuery({
  query: FETCH_CLIENT_ESTIMATES_PAGE,
  variablesHandler: (currentBusinessId, clientId, from, to) => ({
    businessId: currentBusinessId,
    clientId,
    offset: from,
    limit: to,
  }),
})

export const fetchInvoicesByClientAndStates = (includeRetailOrder: boolean) =>
  requestQuery({
    query: FETCH_INVOICES_BY_CLIENT_AND_STATES(includeRetailOrder),
    variablesHandler: (
      currentBusinessId: string,
      input: InvoiceSearchInput,
    ) => ({
      input: {
        ...input,
        businessId: currentBusinessId || input.businessId,
      },
    }),
  })

export const fetchUnpaidInvoicesByClientAndStates = (
  includeRetailOrder: boolean,
) =>
  requestQuery({
    query: FETCH_UNPAID_INVOICES_BY_CLIENT_AND_STATES(includeRetailOrder),
    variablesHandler: (
      currentBusinessId: string,
      input: InvoiceSearchInput,
    ) => ({
      businessId: currentBusinessId,
      clientId: input.clientId,
      offset: input.offset,
      limit: input.limit,
    }),
  })

export const addEstimateToSOAPOrEvent = (
  businessId: string,
  {
    estimateId,
    soapId,
    eventId,
    copyItems,
    allowClone,
    checkInactiveLineItems,
    allowNewSoap,
  }: {
    allowClone?: boolean
    allowNewSoap?: boolean
    checkInactiveLineItems?: boolean
    copyItems: boolean
    estimateId: string
    eventId: string | Nil
    soapId: string | Nil
  },
) =>
  request(
    getAPIPath(`finance/invoice/estimate/${estimateId}`),
    {
      method: 'PUT',
      params: {
        allowNewSoap,
        businessId,
        soapId,
        eventId,
        copyItems,
        allowClone,
        checkInactiveLineItems,
      },
    },
    true,
    {
      soap: Schema.SOAP,
      estimate: Schema.invoice,
      event: Schema.event,
    },
  )

export const saveEstimateToSOAP = (
  businessId: string,
  estimate: Estimate,
  soapId: string,
) =>
  request(
    getAPIPath('finance/invoice/estimate/saveAndCopyToSoap'),
    {
      method: 'POST',
      data: estimate,
      params: { businessId, soapId, splitEstimateAdditionalDiscount: true },
    },
    true,
    {
      soap: Schema.SOAP,
      estimate: Schema.invoice,
    },
  )

export const saveEstimateToEvent = (
  businessId: string,
  {
    allowNewSoap,
    estimate,
    eventId,
  }: {
    allowNewSoap?: boolean
    estimate: Estimate
    eventId: string
  },
) =>
  request(
    getAPIPath('finance/invoice/estimate/saveAndCopyToEvent'),
    {
      method: 'POST',
      data: estimate,
      params: {
        businessId,
        eventId,
        splitEstimateAdditionalDiscount: true,
        allowNewSoap,
      },
    },
    true,
    {
      event: Schema.event,
      estimate: Schema.invoice,
    },
  )

export const detachEstimateFromEvent = (
  businessId: string,
  estimateId: string,
  eventId: string,
) =>
  request(
    getAPIPath(`finance/invoice/estimate/${estimateId}/detach`),
    { method: 'PUT', params: { businessId, eventId } },
    true,
    {
      event: Schema.event,
      estimate: Schema.invoice,
    },
  )

export const fetchCheckoutInvoices = (businessId: string, eventId: string) =>
  request(
    getAPIPath('finance/invoice/checkout'),
    { method: 'GET', params: { businessId, eventId } },
    true,
    Schema.checkoutInvoiceData,
  )

export const fetchUnpaidInvoices = (businessId: string, clientId: string) =>
  request(
    getAPIPath('finance/invoice/unpaid'),
    { method: 'GET', params: { businessId, clientId } },
    true,
    Schema.invoicesArray,
  )

export const fetchBatchInvoice = (businessId: string, invoiceIds: string[]) =>
  request(
    getAPIPath(`finance/invoice/${invoiceIds.join(',')}`),
    {
      method: 'GET',
      params: { businessId, splitEstimateAdditionalDiscount: true },
    },
    true,
    invoiceIds.length > 1 ? Schema.batchInvoice : Schema.invoice,
  )

export const fetchAppointmentInvoiceItems = (
  businessId: string,
  eventId: string,
  prepaid = true,
) =>
  request(
    getAPIPath(`finance/invoice/event/${eventId}/items`),
    { method: 'GET', params: { businessId, prepaid } },
    true,
  )

export const redeemLoyaltyPoints = (
  // eslint-disable-next-line
  businessId: string,
  // eslint-disable-next-line
  amount: number,
  // eslint-disable-next-line
  invoiceId: string,
) => Promise.resolve()

export const updateInvoiceLineItem = requestMutation({
  mutation: UPDATE_INVOICE_LINE_ITEM,
  variablesHandler: (_, id, input) => ({
    id,
    input,
  }),
})

export const updateRetailOrderLineItem = requestMutation({
  mutation: UPDATE_RETAIL_ORDER_LINE_ITEM,
  variablesHandler: (_, input: UpdateRetailOrderLineItemInput) => ({
    input,
  }),
})

export const rearrangeInvoiceLineItems = requestMutation({
  mutation: REARRANGE_INVOICE_LINE_ITEMS,
  variablesHandler: (_, invoiceId, input) => ({
    invoiceId,
    input,
  }),
})

export const rearrangeGroupedLineItems = requestMutation({
  mutation: REARRANGE_GROUPED_LINE_ITEMS,
  variablesHandler: (_, args: MutationRearrangeGroupedLineItemsArgs) => ({
    ...args,
  }),
})

export const cloneEstimate = (
  businessId: string,
  estimateId: string,
  checkInactiveLineItems: boolean,
) =>
  request(
    getAPIPath(`finance/invoice/estimate/${estimateId}/clone`),
    { method: 'POST', params: { businessId, checkInactiveLineItems } },
    true,
    Schema.invoice,
  )
