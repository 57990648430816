import { Defaults } from '@pbt/pbt-ui-components'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchGlobalItem = (businessId: string, itemId: string) =>
  request(
    getAPIPath(`inventory/global/items/full/${itemId}`),
    {
      method: 'GET',
      params: { businessId },
    },
    true,
    Schema.globalInventoryItem,
  )

export const fetchGlobalItemV2 = (businessId: string, itemId: string) =>
  request(
    getAPIPath(`v2/inventory/global/items/full/${itemId}`),
    {
      method: 'GET',
      params: { businessId },
    },
    true,
    Schema.globalInventoryItem,
  )

export const fetchGlobalInventoryCatalog = (
  businessId: string,
  categories: string[],
  countryCode: string,
  query: string = '',
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isGlobalMappingSearch?: boolean,
) =>
  request(
    getAPIPath('inventory/global/items/full/search'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        from,
        to,
        categories: categories.join(','),
        countryCode,
        isGlobalMappingSearch,
      },
    },
    true,
    Schema.globalInventoryItemsList,
  )
