import React from 'react'
import { useSelector } from 'react-redux'
import { Constant, LanguageUtils } from '@pbt/pbt-ui-components'

import BusinessTypeLabel from '~/components/common/BusinessTypeLabel'
import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getPracticeTypes } from '~/store/reducers/constants'

export interface PracticesTypeFilterProps extends CheckboxListFilterProps {}

const PracticesTypeFilter = (props: PracticesTypeFilterProps) => {
  const PracticeTypes: Constant[] = useSelector(getPracticeTypes)

  const filterTypeItems = PracticeTypes.map((item) => ({
    ...item,
    component: (
      <BusinessTypeLabel icon={item.name}>
        {LanguageUtils.getTranslatedFieldName(item)}
      </BusinessTypeLabel>
    ),
  }))

  return <CheckboxListFilter items={filterTypeItems} {...props} />
}

export default PracticesTypeFilter
