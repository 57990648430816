import React, { Fragment } from 'react'
import { Grid } from '@mui/material'
import { FieldProp, Text } from '@pbt/pbt-ui-components'

export interface ChargeDetailsFieldWrapperProps {
  children: React.ReactNode
  className?: string
  disabled: boolean
  label: FieldProp['label']
}

const ChargeDetailsFieldWrapper = ({
  children,
  className: classNameProp,
  disabled,
  label,
}: ChargeDetailsFieldWrapperProps) => {
  if (!label) {
    return null
  }

  return (
    <Fragment key={label}>
      <Grid
        container
        item
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between"
      >
        <Text
          className={classNameProp}
          disabled={disabled}
          lineHeight={2}
          p={1}
          variant="body"
        >
          {label}
        </Text>
        {children}
      </Grid>
    </Fragment>
  )
}

export default ChargeDetailsFieldWrapper
