import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { DiagnosisStatusName } from '~/constants/diagnosis'
import DialogNames from '~/constants/DialogNames'
import { editDifferentialDiagnosesState } from '~/store/actions/soap'
import { getDiagnosisStatus } from '~/store/reducers/constants'
import { getOrdersIsSendingOrReceiving } from '~/store/reducers/orders'
import { getDiagnosesState } from '~/store/reducers/soap'
import { DiagnoseLocation, DiagnosesStateWithSimpleLocations } from '~/types'
import useDialog from '~/utils/useDialog'

import DiffDxLocationItem from './DiffDxLocationItem'

const useStyles = makeStyles(
  () => ({
    root: {},
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
  { name: 'DiffDxExpandedDetails' },
)

export interface DiffDxExpandedDetailsProps {
  diagnosis?: DiagnosesStateWithSimpleLocations
  order: DiagnosesStateWithSimpleLocations
}

export interface DiffDxExpandedDetailsHandle {
  updateNotes: (notes: string) => void
}

const DiffDxExpandedDetails = forwardRef(function DiffDxExpandedDetails(
  { diagnosis: diagnosisProp, order }: DiffDxExpandedDetailsProps,
  ref: ForwardedRef<DiffDxExpandedDetailsHandle>,
) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const diagnosesState = useSelector(getDiagnosesState)
  const DiagnosisStatus = useSelector(getDiagnosisStatus)

  const [openNotesDialog] = useDialog(DialogNames.NOTES)

  const diagnosis = diagnosisProp || order
  const diagnoseState = diagnosesState[diagnosis.id]
  const locations = diagnoseState ? diagnoseState.locations : []

  const suspectedStatus = Utils.findConstantIdByName(
    DiagnosisStatusName.SUSPECTED,
    DiagnosisStatus,
  )

  useImperativeHandle(ref, () => ({
    updateNotes: (notes) => {
      const statusId =
        diagnosis?.statusId || diagnoseState?.statusId || suspectedStatus
      dispatch(
        editDifferentialDiagnosesState(diagnosis.id, {
          ...diagnosis,
          notes,
          locations,
          ...(R.isEmpty(locations) ? { statusId } : {}),
        }),
      )
    },
  }))

  const onLocationColumnItemClick = (id: string) => {
    const locationState = Utils.findById(id, locations)
    const updatedDiagnosis = {
      ...diagnoseState,
      locations: locationState
        ? locations.filter((location) => location.id !== id)
        : [...locations, { id, statusId: suspectedStatus }],
    }

    dispatch(editDifferentialDiagnosesState(diagnosis.id, updatedDiagnosis))
  }

  const onLocationNotesChange = (id: string, notesValue: string) => {
    dispatch(
      editDifferentialDiagnosesState(diagnosis.id, {
        ...diagnoseState,
        locations: locations.map((location) =>
          location.id === id ? { ...location, notes: notesValue } : location,
        ),
      }),
    )
  }

  const handleAddNotes =
    ({ id, notes: locationNotes }: DiagnoseLocation) =>
    () => {
      openNotesDialog({
        name: order.name,
        notes: locationNotes,
        onUpdateNotes: (notes: string) => onLocationNotesChange(id, notes),
        isLoadingSelector: getOrdersIsSendingOrReceiving,
      })
    }

  return (
    <Grid container item className={classes.root} direction="column" pr={2}>
      {diagnosis.locations?.map((id) => (
        <DiffDxLocationItem
          handleAddNotes={handleAddNotes}
          key={id}
          locationId={id}
          locations={locations}
          onLocationColumnItemClick={onLocationColumnItemClick}
        />
      ))}
    </Grid>
  )
})

export default DiffDxExpandedDetails
