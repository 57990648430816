import { IntRange } from '@pbt/pbt-ui-components'

export function isAutoshipDataValid(
  unit: string,
  frequency: number | null | undefined,
): frequency is IntRange<1, 17> | IntRange<5, 9> {
  if (unit === 'Week') {
    return (
      frequency !== null &&
      frequency !== undefined &&
      frequency >= 1 &&
      frequency <= 16
    )
  }
  if (unit === 'Month') {
    return (
      frequency !== null &&
      frequency !== undefined &&
      frequency >= 5 &&
      frequency <= 8
    )
  }
  return false
}
