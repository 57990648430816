import { useSelector } from 'react-redux'

import { getIsDymoSupported } from '~/components/dashboard/labels/labelsUtils'
import { getCurrentBusinessIsDymoConnectEnabled } from '~/store/reducers/auth'

const useIsDymoConnectEnabled = () => {
  const isDymoConnectEnabled = useSelector(
    getCurrentBusinessIsDymoConnectEnabled,
  )
  const isDymoSupported = getIsDymoSupported()

  return isDymoSupported && isDymoConnectEnabled
}

export default useIsDymoConnectEnabled
