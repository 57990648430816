import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Stack } from '@mui/system'
import { ButtonWithLoader, Text } from '@pbt/pbt-ui-components'

import ConfusedDog from '../images/ConfusedDog.svg'

const useStyles = makeStyles(
  () => ({
    errorMessage: {
      wordBreak: 'break-word',
    },
  }),
  { name: 'PuiCKEditorError' },
)

export interface PuiCKEditorErrorProps extends FallbackProps {
  height: number
}

const PuiCKEditorError = ({
  error,
  resetErrorBoundary,
  height,
}: PuiCKEditorErrorProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Common', 'Errors'])

  return (
    <Grid container alignItems="center" minHeight={height} my={2}>
      <Stack spacing={2}>
        <Text strong variant="body">
          {t('Errors:ERROR_CONTACT_FORM.MESSAGE')}
        </Text>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid container item direction="column" xs={8}>
            <Text className={classes.errorMessage} pb={2} variant="lowAccent3">
              {t('Common:DETAILS')}: {error.message}
            </Text>
            <ButtonWithLoader onClick={resetErrorBoundary}>
              {t('Common:RELOAD_EDITOR')}
            </ButtonWithLoader>
          </Grid>
          <Grid item mb={-2.5} xs={4}>
            <img alt={t('Common:CONFUSED_DOG')} src={ConfusedDog} />
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  )
}

export default PuiCKEditorError
