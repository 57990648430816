import i18n from '~/locales/i18n'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchConstants = () =>
  request(
    getAPIPath('constants'),
    { method: 'GET', params: { language: i18n.language } },
    false,
    Schema.constants,
  )

export const fetchSecureConstants = () =>
  request(
    getAPIPath('constants'),
    { method: 'GET', params: { language: i18n.language } },
    true,
    Schema.constants,
  )
