import React from 'react'
import { Grid } from '@mui/material'
import { User } from '@pbt/pbt-ui-components'

import NotificationSettings from '../../../../notifications/NotificationSettings'

interface MemberNotificationSettingsSectionProps {
  teamMember: User
}

const MemberNotificationSettingsSection = ({
  teamMember,
}: MemberNotificationSettingsSectionProps) => (
  <Grid container item>
    {teamMember?.id && (
      <NotificationSettings readOnly personId={teamMember.id} />
    )}
  </Grid>
)

export default MemberNotificationSettingsSection
