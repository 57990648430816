import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, Nil, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    base: {
      width: 96,
      height: 32,
      borderRadius: 16,
      backgroundColor: 'rgba(198,192,192,0.3)',
      transition: theme.transitions.create('background-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      position: 'relative',
    },
    baseOn: {
      backgroundColor: 'rgba(125,172,30,0.15)',
    },
    baseOff: {
      backgroundColor: 'rgba(228,135,54,0.15)',
    },
    bullet: {
      position: 'absolute',
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 32,
      height: 32,
      borderRadius: 16,
      backgroundColor: theme.colors.disabledLabelText,
      boxShadow:
        '-1px 2px 4px 0 rgba(0,0,0,0.10), 1px 0 2px 0 rgba(60,56,56,0.20)',
      cursor: 'pointer',
      userSelect: 'none',
      '&&&&:hover': {
        opacity: '0.5',
      },
      transition: theme.transitions.create(['background-color', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    bulletOn: {
      left: 32,
      backgroundColor: theme.colors.success,
    },
    bulletOff: {
      left: 64,
      backgroundColor: theme.colors.important,
      boxShadow: 'none',
    },
    bulletPlaceholder: {
      width: 32,
      height: 32,
      borderRadius: 16,
      backgroundColor: theme.colors.disabledLabelText,
      opacity: 0,
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      '&&&&:hover': {
        opacity: '0.1',
      },
    },
    disabled: {
      cursor: 'default',
      '&&&&:hover': {
        opacity: 0,
      },
    },
    bulletOnPlaceholder: {
      backgroundColor: theme.colors.success,
      left: 32,
    },
    bulletOffPlaceholder: {
      backgroundColor: theme.colors.important,
      left: 64,
    },
    label: {
      lineHeight: '12px',
      color: '#FFFFFF',
      position: 'absolute',

      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    showLabel: {
      opacity: 1,
    },
    hideLabel: {
      opacity: 0,
    },
  }),
  { name: 'ThreeStepsToggle' },
)

const ThreeStepsToggleState = {
  NONE: 'NONE',
  ON: 'ON',
  OFF: 'OFF',
}

const transitionMap = {
  [ThreeStepsToggleState.NONE]: ThreeStepsToggleState.ON,
  [ThreeStepsToggleState.ON]: ThreeStepsToggleState.OFF,
  [ThreeStepsToggleState.OFF]: ThreeStepsToggleState.NONE,
}

export interface ThreeStepsToggleProps {
  confirmStateChange?: (callback: (confirmed: boolean) => false | void) => void
  disabled?: boolean
  noneLabel: string
  noneValue?: string
  offLabel: string
  offValue?: string
  onLabel: string
  onStateChange: (newValue: string | Nil, fromEvent?: boolean) => void
  onValue?: string
  value: string
}

const ThreeStepsToggle = ({
  disabled,
  value,
  noneLabel,
  onLabel,
  offLabel,
  noneValue = ThreeStepsToggleState.NONE,
  onValue = ThreeStepsToggleState.ON,
  offValue = ThreeStepsToggleState.OFF,
  onStateChange,
  confirmStateChange,
}: ThreeStepsToggleProps) => {
  const classes = useStyles()
  const [localState, setLocalState] = useState(ThreeStepsToggleState.NONE)

  useEffect(() => {
    if (value === onValue) {
      setLocalState(ThreeStepsToggleState.ON)
    } else if (value === offValue) {
      setLocalState(ThreeStepsToggleState.OFF)
    } else if (value === noneValue) {
      setLocalState(ThreeStepsToggleState.NONE)
    }
  }, [value])

  const handleSetThreeToggleState = (
    newState: string,
    event: React.MouseEvent,
  ) => {
    if (disabled) {
      return
    }

    if (event) {
      event.stopPropagation()
    }

    const changeState = () => {
      const newValue =
        newState === ThreeStepsToggleState.NONE
          ? noneValue
          : newState === ThreeStepsToggleState.ON
            ? onValue
            : newState === ThreeStepsToggleState.OFF
              ? offValue
              : null

      setLocalState(newState)
      onStateChange(newValue, Boolean(event))
    }

    if (
      confirmStateChange &&
      event &&
      localState === ThreeStepsToggleState.OFF &&
      newState !== ThreeStepsToggleState.OFF
    ) {
      confirmStateChange((confirmed: boolean) => confirmed && changeState())
    } else {
      changeState()
    }
  }

  const handleBulletClick = (event: React.MouseEvent) => {
    if (!disabled) {
      handleSetThreeToggleState(transitionMap[localState], event)
    }
    event.stopPropagation()
  }

  return (
    <div
      className={classNames(
        classes.base,
        localState === ThreeStepsToggleState.ON && classes.baseOn,
        localState === ThreeStepsToggleState.OFF && classes.baseOff,
      )}
      data-state={localState}
    >
      <Box
        className={classNames(
          classes.bullet,
          localState === ThreeStepsToggleState.ON && classes.bulletOn,
          localState === ThreeStepsToggleState.OFF && classes.bulletOff,
        )}
        role="button"
        onClick={handleBulletClick}
      >
        <Text
          strong
          className={classNames(
            classes.label,
            localState === ThreeStepsToggleState.NONE
              ? classes.showLabel
              : classes.hideLabel,
          )}
          variant="body5"
        >
          {LanguageUtils.formatInitials(noneLabel)}
        </Text>
        <Text
          strong
          className={classNames(
            classes.label,
            localState === ThreeStepsToggleState.ON
              ? classes.showLabel
              : classes.hideLabel,
          )}
          variant="body5"
        >
          {LanguageUtils.formatInitials(onLabel)}
        </Text>
        <Text
          strong
          className={classNames(
            classes.label,
            localState === ThreeStepsToggleState.OFF
              ? classes.showLabel
              : classes.hideLabel,
          )}
          variant="body5"
        >
          {LanguageUtils.formatInitials(offLabel)}
        </Text>
      </Box>
      {localState !== ThreeStepsToggleState.NONE && (
        <Box
          className={classNames(classes.bulletPlaceholder, {
            [classes.disabled]: disabled,
          })}
          id={`ThreeStepsToggle-${ThreeStepsToggleState.NONE}`}
          role="button"
          onClick={(event) =>
            handleSetThreeToggleState(ThreeStepsToggleState.NONE, event)
          }
        />
      )}
      {localState !== ThreeStepsToggleState.ON && (
        <Box
          className={classNames(
            classes.bulletPlaceholder,
            classes.bulletOnPlaceholder,
            {
              [classes.disabled]: disabled,
            },
          )}
          id={`ThreeStepsToggle-${ThreeStepsToggleState.ON}`}
          role="button"
          onClick={(event) =>
            handleSetThreeToggleState(ThreeStepsToggleState.ON, event)
          }
        />
      )}
      {localState !== ThreeStepsToggleState.OFF && (
        <Box
          className={classNames(
            classes.bulletPlaceholder,
            classes.bulletOffPlaceholder,
            {
              [classes.disabled]: disabled,
            },
          )}
          id={`ThreeStepsToggle-${ThreeStepsToggleState.OFF}`}
          role="button"
          onClick={(event) =>
            handleSetThreeToggleState(ThreeStepsToggleState.OFF, event)
          }
        />
      )}
    </div>
  )
}

export default ThreeStepsToggle
