import { gql } from '@apollo/client'

import { TEMPLATE_ITEM_FIELDS } from '../fragments/reminderSettings'

export const FETCH_REMINDER_SETTINGS = gql`
  ${TEMPLATE_ITEM_FIELDS}
  query ReminderSettings {
    reminderSettings {
      active
      templates {
        ...TemplateItemFields
      }
      defaultCommunicationMessage
      defaultReminderExpirationOffset {
        amount
        unit
      }
    }
  }
`

export const FETCH_TEMPLATE_PREVIEW = gql`
  query TemplatePreview($template: ReminderCommunicationTemplatePreviewInput) {
    templatePreview(template: $template) {
      email
      textMessage
    }
  }
`
