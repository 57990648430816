import i18n from '~/locales/i18n'

export const ColumnNames = {
  ITEM: i18n.t('Common:ITEM'),
  CATEGORY: i18n.t('Common:CATEGORY'),
  SUB_CATEGORY: i18n.t('Common:SUB-CATEGORY'),
  ON_HAND_AMOUNT: i18n.t('Common:ON-HAND'),
  LAST_DISTRIBUTOR: i18n.t('Common:LAST_DISTRIBUTOR'),
  RE_ORDER_POINT: i18n.t('Common:INVENTORY_RE-ORDER_POINT'),
  RE_ORDER_QUANTITY: i18n.t('Common:INVENTORY_RE-ORDER_QUANTITY'),
  RE_ORDER_MAX_QUANTITY: i18n.t('Abbreviations:COMMON.MAXIMUM_QUANTITY'),
  ON_HAND_STATUS: i18n.t('Common:ON-HAND_STATUS'),
  ACTIONS: '',
}

export const PreselectedColumns = [
  ColumnNames.ITEM,
  ColumnNames.CATEGORY,
  ColumnNames.ON_HAND_AMOUNT,
  ColumnNames.RE_ORDER_QUANTITY,
  ColumnNames.ON_HAND_STATUS,
  ColumnNames.ACTIONS,
]

export const MandatoryColumns = [ColumnNames.ITEM, ColumnNames.ACTIONS]

export const OptionalColumns = [
  ColumnNames.CATEGORY,
  ColumnNames.SUB_CATEGORY,
  ColumnNames.ON_HAND_AMOUNT,
  ColumnNames.LAST_DISTRIBUTOR,
  ColumnNames.RE_ORDER_POINT,
  ColumnNames.RE_ORDER_QUANTITY,
  ColumnNames.RE_ORDER_MAX_QUANTITY,
  ColumnNames.ON_HAND_STATUS,
]

export const OrderStatus = {
  NONE: 'None',
  ORDERED: 'Ordered',
  BACKORDERED: 'Backordered',
  TIME_TO_REORDER: 'Time to re-order',
}

export const OnHandStatus = {
  STOCKED: 'Stocked',
  BELOW_REORDER: 'Below re-order point',
  OUT_OF_STOCK: 'Out of stock',
  NO_REORDER_POINT: 'No re-order point set',
}

export const OnHandStatusColor = {
  [OnHandStatus.STOCKED]: 'normal',
  [OnHandStatus.BELOW_REORDER]: 'warning',
  [OnHandStatus.OUT_OF_STOCK]: 'warning',
  [OnHandStatus.NO_REORDER_POINT]: 'disabled',
}

export const OnHandChangeType = {
  ADJUSTMENT: 'Adjustment',
  SHIPMENT: 'Shipment',
  USE: 'Use',
}
