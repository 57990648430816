import React, { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import * as R from 'ramda'
import { InlineSearchHandle } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import {
  useHandleOrderRefill,
  useLogItemStateGetter,
  useOrderEditListItem,
} from '~/store/hooks/orders'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getOrdersIsFetchingOrdersCountByType } from '~/store/reducers/orders'
import { getClientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { ChargeQueryParams, ChargeTab, ChargeTabId } from '~/types'

import { useChargeTabCountByType } from '../utils/useChargeTabCountByType'
import BundlesChargeTab from './bundles/BundlesChargeTab'
import ChargesDropdownView from './ChargesDropdownView'
import { ChargesPanelTabs } from './chargesTabs'
import ChargesTabsView from './ChargeTabsView'
import PrepaidChargeTab from './pre-paid/PrepaidChargeTab'
import RefillsChargeTab from './refills/RefillsChargeTab'
import RemindersChargeTab from './reminders/RemindersChargeTab'
import SearchChargeTab from './search/SearchChargeTab'
import WellnessChargeTab from './wellness/WellnessChargeTab'

interface ChargesItemsBaseProps {
  appointmentId?: string
  editDisabled: boolean
  patientId?: string
}

interface IChargesTabsProps extends ChargesItemsBaseProps {
  expandedTab?: never
  onExpandTab?: never
  view?: 'tab'
}

interface IChargesTabsDropdownProps extends ChargesItemsBaseProps {
  expandedTab: string | null
  onExpandTab: (id: string | null) => void
  view?: 'drop-down'
}

export type ChargesItemsProps = IChargesTabsDropdownProps | IChargesTabsProps

const ChargesItems = ({
  appointmentId,
  editDisabled,
  expandedTab = null,
  onExpandTab = R.identity,
  patientId,
  view = 'tab',
}: ChargesItemsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { t } = useTranslation(['Common', 'Menu'])

  const business = useSelector(getCurrentBusiness)
  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))
  const isFetchingOrdersCountByType = useSelector(
    getOrdersIsFetchingOrdersCountByType,
  )

  const searchRef = useRef<InlineSearchHandle>(null)

  const clearSearch = () => {
    if (searchRef.current?.clearSearch) {
      searchRef.current.clearSearch()
      searchParams.delete(ChargeQueryParams.CHARGE_SEARCH_QUERY)
      setSearchParams(searchParams)
    }
  }

  const { hasNoItems, getTotalCountByTypeString } = useChargeTabCountByType({
    appointmentId,
    patientId,
    clientId,
  })

  const logItemStateGetter = useLogItemStateGetter()
  const { handleRefill, handleSavePrescription, openPrescriptionDialog } =
    useHandleOrderRefill(client, logItemStateGetter)
  const handleEditListItem = useOrderEditListItem()

  const panels: ChargesPanelTabs = [
    {
      id: ChargeTabId.SEARCH,
      Component: SearchChargeTab,
      props: { clearSearch, searchRef, patientId },
    },
    {
      id: ChargeTabId.REMINDERS,
      Component: RemindersChargeTab,
      props: { patientId, clientId, appointmentId },
    },
    {
      id: ChargeTabId.REFILLS,
      Component: RefillsChargeTab,
      props: { patientId, clientId, appointmentId },
    },
    {
      id: ChargeTabId.PREPAID,
      Component: PrepaidChargeTab,
      props: { patientId, clientId, appointmentId },
    },
    {
      id: ChargeTabId.BUNDLES,
      Component: BundlesChargeTab,
      props: { patientId, clientId, appointmentId },
    },
    {
      id: ChargeTabId.WELLNESS,
      Component: WellnessChargeTab,
      props: { clientId },
    },
  ]

  const tabs: ChargeTab[] = [
    {
      id: ChargeTabId.SEARCH,
      label: t('Common:SEARCH_ACTION'),
      hide: view === 'drop-down',
    },
    {
      id: ChargeTabId.REMINDERS,
      label: `${t('Common:REMINDERS')} ${getTotalCountByTypeString(
        OrderType.REMINDER,
      )}`,
      hide: isFetchingOrdersCountByType
        ? false
        : hasNoItems(OrderType.REMINDER),
    },
    {
      id: ChargeTabId.REFILLS,
      label: `${t('Common:REFILL_OTHER')} ${getTotalCountByTypeString(
        OrderType.REFILL,
      )}`,
      hide: isFetchingOrdersCountByType ? false : hasNoItems(OrderType.REFILL),
    },
    {
      id: ChargeTabId.PREPAID,
      label: `${t('Common:PRE-PAID')} ${getTotalCountByTypeString(
        OrderType.PREPAID,
      )}`,
      hide: isFetchingOrdersCountByType ? false : hasNoItems(OrderType.PREPAID),
    },
    {
      id: ChargeTabId.BUNDLES,
      label: t('Menu:ADMIN_CATALOG.BUNDLES'),
      hide: isFetchingOrdersCountByType ? false : hasNoItems(OrderType.BUNDLE),
    },
    {
      id: ChargeTabId.WELLNESS,
      label: t('Common:WELLNESS'),
      hide: !business?.wellnessPlansEnabled,
    },
  ]

  if (view === 'drop-down') {
    return (
      <ChargesDropdownView
        SearchChargeTabProps={{
          clearSearch,
          disabled: editDisabled,
          handleEditListItem,
          handleRefill,
          handleSavePrescription,
          logItemStateGetter,
          openPrescriptionDialog,
          patientId,
          searchRef,
        }}
        expandedTab={expandedTab}
        panels={panels}
        tabs={tabs}
        onExpandTab={onExpandTab}
      />
    )
  }

  return (
    <ChargesTabsView
      editDisabled={editDisabled}
      handleEditListItem={handleEditListItem}
      handleRefill={handleRefill}
      handleSavePrescription={handleSavePrescription}
      logItemStateGetter={logItemStateGetter}
      openPrescriptionDialog={openPrescriptionDialog}
      panels={panels}
      tabs={tabs}
    />
  )
}

export default memo(ChargesItems)
