import React from 'react'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

import { formatMoneyRange } from '~/components/dashboard/invoices/invoiceUtils'

const useStyles = makeStyles(
  () => ({
    container: {},
  }),
  { name: 'TableAmountCellProps' },
)

interface TableAmountCellProps {
  amount: number
  classes?: ClassesType<typeof useStyles>
}

export const TableAmountCell = ({
  amount,
  classes: classesProp,
}: TableAmountCellProps) => {
  const classes = useStyles({ classes: classesProp })

  if (R.isNil(amount)) {
    return null
  }

  return (
    <Text
      align="right"
      className={classes.container}
      justifyContent="flex-end"
      variant="body2"
    >
      {formatMoneyRange(amount)}
    </Text>
  )
}
