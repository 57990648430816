import React from 'react'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, StateLabel } from '@pbt/pbt-ui-components'

import ItemHistoryLabel, {
  HistoryLabelItem,
} from '~/components/common/labels/ItemHistoryLabel'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: theme.spacing(8),
      borderTop: theme.constants.tableBorder,
      padding: theme.spacing(1.5, 2),
    },
    buttonContainer: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 1),
      },
      '&:not(:first-child)': {
        [theme.breakpoints.only('md')]: {
          padding: theme.spacing(0, 0, 0, 2),
        },
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(0, 0, 0, 3),
        },
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      padding: '6px 0',
      fontWeight: 'normal',
      textTransform: 'none',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    buttonLoaded: {
      textDecoration: 'underline',
      '&&&&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    actionsContainer: {
      paddingLeft: theme.spacing(0.5),
    },
    stateContainer: {
      width: 'auto',
    },
  }),
  { name: 'TimelineCardActions' },
)

export interface TimelineCardActionsProps {
  actionNeeded?: boolean
  children?: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  customStateControl?: React.ReactNode
  disabledState?: boolean
  errorState?: boolean
  isLoading?: boolean
  item?: HistoryLabelItem
  state?: React.ReactNode
  successState?: boolean
  warningState?: boolean
}

const TimelineCardActions = ({
  classes: classesProp,
  item,
  state,
  errorState,
  warningState,
  successState,
  disabledState,
  actionNeeded,
  customStateControl,
  children,
  isLoading,
}: TimelineCardActionsProps) => {
  const classes = useStyles({ classes: classesProp })
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const childrenArray = React.Children.toArray(children) as React.ReactElement[]

  const actions = childrenArray.map((child, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Grid item className={classes.buttonContainer} key={index}>
      {React.cloneElement(child, {
        className: classNames(classes.button, {
          [classes.buttonLoaded]: !isLoading,
        }),
      })}
    </Grid>
  ))

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="center"
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems="flex-start"
        className={classes.stateContainer}
        direction="column"
      >
        <Grid item>
          {customStateControl || (
            <StateLabel
              disabled={disabledState}
              error={errorState}
              success={successState}
              warning={actionNeeded || warningState}
            >
              {state || ''}
            </StateLabel>
          )}
        </Grid>
        <Grid item>
          <ItemHistoryLabel item={item} />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs
        alignItems="center"
        className={classes.actionsContainer}
        justifyContent={isMobile ? 'center' : 'flex-end'}
        wrap={isMobile ? 'wrap' : 'nowrap'}
      >
        {actions}
      </Grid>
    </Grid>
  )
}

export default TimelineCardActions
