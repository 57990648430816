import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ErrorTooltip,
  Nil,
  PuiTextField,
  Text,
  User,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'
import { ForwardArrow } from '@pbt/pbt-ui-components/src/icons'

import Avatar from '../Avatar'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    main: {
      backgroundColor: '#FFFFFF',
      borderRadius: 30,
      border: `1px solid ${theme.colors.userPickerBorder}`,
      height: 60,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
      cursor: 'pointer',
    },
    userAvatar: {
      width: 60,
      height: 60,
    },
    selectedUserAvatar: {
      width: 52,
      height: 52,
    },
    userAvatarContainer: {
      marginLeft: 3,
    },
    searchPickerText: {
      userSelect: 'none',
      color: theme.colors.userPickerText,
    },
    expandIconContainer: {
      marginRight: 17,
    },
    expandIcon: {
      color: theme.colors.expandMoreIcon,
    },
    search: {
      height: 101,
      backgroundColor: '#F7F4EE',
      borderRadius: '2px 2px 0 0',
      paddingBottom: 18,
      paddingLeft: 16,
      paddingRight: 23,
    },
    slider: {
      top: '50%',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      position: 'absolute',
      boxShadow: theme.constants.blockShadow,
      backgroundColor: theme.colors.tableBackground,
      borderRadius: 2,
    },
    itemContainer: {
      height: 70,
    },
    item: {
      width: '100%',
      height: 65,
      marginLeft: 8,
      marginRight: 8,
      padding: 0,
      paddingLeft: 8,
      '&&:hover, &&:focus': {
        backgroundColor: `${theme.colors.listItemHover} !important`,
        color: `${theme.colors.listItemHoverText} !important`,
        boxShadow: theme.constants.selectItemSelectedShadow,
      },
    },
    menuList: {
      maxHeight: 347,
      width: '100%',
    },
    userSelect: {
      overflowY: 'scroll',
    },
    collapse: {
      width: '100%',
    },
    iconButton: {
      marginBottom: theme.spacing(1),
      padding: 2,
      '&:hover': {
        backgroundColor: 'rgba(74, 14, 4, 0.2)',
      },
    },
    forwardArrowIcon: {
      color: theme.colors.loginPageSecondaryText,
    },
  }),
  { name: 'UserPicker' },
)

interface UserPickerProps {
  className?: string
  initialValue: Partial<User>
  journal: {
    [userId: string]: Partial<User>
  }
  onChange: (user: Partial<User>) => void
  onExpandedChange: (pickerExpanded: boolean) => void
  onSubmit: (email: string) => void
}

const UserPicker = ({
  journal,
  onChange,
  onSubmit,
  onExpandedChange,
  initialValue,
  className,
}: UserPickerProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Validations'])

  const [selectedUser, setSelectedUser] = useState(initialValue)
  const [pickerExpanded, setPickerExpanded] = useState(false)
  const [email, setEmail] = useState('')
  const [emailValidationError, setEmailValidationError] = useState('')
  const [touched, setTouched] = useState(false)

  const someContains = (items: Array<string | Nil>, str: string) =>
    items.some((item) => item && item.indexOf(str) > -1)

  const filteredUsers = Object.values(journal).filter(
    ({ firstName, lastName, email: entryEmail, id }) =>
      id !== selectedUser.id &&
      someContains([firstName, lastName, entryEmail], email) &&
      entryEmail,
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!pickerExpanded) {
      return
    }
    event.preventDefault()
    setEmailValidationError('')
    setTouched(false)
    if (!email) {
      setEmailValidationError(t('Validations:NO_EMAIL'))
    } else if (!Validators.isEmailValid(email)) {
      setEmailValidationError(t('Validations:EMAIL_INVALID'))
    } else {
      onSubmit(email)
    }
  }

  const getHasPreviousLogins = () => Object.keys(journal).length > 1

  return (
    <ClickAwayListener onClickAway={() => setPickerExpanded(false)}>
      <Grid
        container
        className={classNames(classes.root, className)}
        direction="column"
        justifyContent="center"
      >
        <Grid
          container
          item
          alignItems="center"
          className={classes.main}
          onClick={() => {
            onExpandedChange(!pickerExpanded)
            setPickerExpanded(!pickerExpanded)
          }}
        >
          <Grid item className={classes.userAvatarContainer}>
            <Avatar
              className={classes.selectedUserAvatar}
              person={selectedUser}
            />
          </Grid>
          <Grid item xs pl={5} pr={2}>
            <Text strong className={classes.searchPickerText} variant="body2">
              {Utils.getPersonString(selectedUser)}
            </Text>
          </Grid>
          <Grid item className={classes.expandIconContainer}>
            {pickerExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
        <Grid container item className={classes.slider}>
          <Collapse className={classes.collapse} in={pickerExpanded}>
            <Grid
              container
              item
              alignItems="flex-end"
              className={classes.search}
              justifyContent="center"
            >
              <ErrorTooltip
                message={emailValidationError}
                open={Boolean(!touched && emailValidationError)}
              >
                <PuiTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className={classes.iconButton}
                          size="large"
                          type="submit"
                          onClick={handleClick}
                        >
                          <ForwardArrow className={classes.forwardArrowIcon} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="email"
                  id="email-search"
                  label={t('Common:ENTER_EMAIL')}
                  margin="none"
                  type="email"
                  value={email}
                  onChange={Utils.handleFormTextInput(setEmail)}
                />
              </ErrorTooltip>
            </Grid>
            {getHasPreviousLogins() && (
              <Grid container item className={classes.userSelect}>
                <MenuList disableListWrap className={classes.menuList}>
                  {filteredUsers.map((user) => (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.itemContainer}
                      key={user.id}
                      onClick={() => {
                        setSelectedUser(user)
                        setPickerExpanded(false)
                        onChange(user)
                      }}
                    >
                      <MenuItem className={classes.item}>
                        <Avatar className={classes.userAvatar} person={user} />
                        <Text noWrap strong ml={1.25} variant="body2">
                          {Utils.getPersonString(user)}
                        </Text>
                      </MenuItem>
                    </Grid>
                  ))}
                </MenuList>
              </Grid>
            )}
          </Collapse>
        </Grid>
      </Grid>
    </ClickAwayListener>
  )
}

export default UserPicker
