import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Input, InputLabel } from '@mui/material'
import { Constant, Field, PuiSelect } from '@pbt/pbt-ui-components'

import { getRequiredLabel } from '~/utils'

export interface SpeciesSelectProps {
  field: Field
  hideLabel?: boolean
  required?: boolean
  species: Constant[]
}

const PropSpeciesSelect = ({
  field,
  required,
  species,
  hideLabel = false,
}: SpeciesSelectProps) => {
  const { t } = useTranslation('Common')

  return (
    <FormControl fullWidth error={!field.valid}>
      {!hideLabel && (
        <InputLabel htmlFor="species-select">
          {getRequiredLabel(t('Common:SPECIES'), required)}
        </InputLabel>
      )}
      <PuiSelect
        field={field}
        input={<Input id="species-select" />}
        items={species}
      />
    </FormControl>
  )
}

export default PropSpeciesSelect
