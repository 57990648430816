import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty, pluck } from 'ramda'
import { PermissionArea, Utils } from '@pbt/pbt-ui-components'

import { DiagnosisStatusName } from '~/constants/diagnosis'
import { editDifferentialDiagnosesState } from '~/store/actions/soap'
import {
  clearSoapDiagnoses,
  clearSoapDiagnosesFilters,
  fetchDiagnosesFilters,
  fetchSoapDiagnoses,
  getDiagnosesData,
  getDiagnosesFilters,
  getDiagnosesIsLoading,
  getDiffDxSearchResults,
  searchDiagnoses,
} from '~/store/duck/soapDiagnoses'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getDiagnosisStatus } from '~/store/reducers/constants'
import {
  getDiagnosesState,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import { getSoapContainerHeight } from '~/utils'

import DiffDxExpandedDetails from '../../list-with-filters/DiffDxExpandedDetails'
import ListWithFilters from '../../list-with-filters/ListWithFilters'

const useStyles = makeStyles(
  (theme) => ({
    listWithFiltersContainer: {
      height: getSoapContainerHeight(theme),
    },
  }),
  { name: 'DifferentialDiagnoses' },
)

const defaultIsCheckedItem = (item, selectedItems) =>
  selectedItems.some(({ id }) => id === item.id)

const DifferentialDiagnoses = ({ editDisabled }) => {
  const classes = useStyles()
  const listRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation('Search')

  const patientId = useSelector(getPatientId)
  const filters = useSelector(getDiagnosesFilters)
  const diagnosesData = useSelector(getDiagnosesData)
  const isLoading = useSelector(getDiagnosesIsLoading)
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const searchResults = useSelector(getDiffDxSearchResults)
  const soapId = useSelector(getSoapId)
  const permissions = useSelector(getCRUDByArea(PermissionArea.DIAGNOSE))
  const diagnosesState = useSelector(getDiagnosesState)

  useEffect(() => {
    if (patientId) {
      dispatch(fetchDiagnosesFilters(patientId))
    }
  }, [patientId, soapId])

  useEffect(
    () => () => {
      dispatch(clearSoapDiagnosesFilters())
      dispatch(clearSoapDiagnoses())
    },
    [],
  )

  const [selectedItems, setSelectedItems] = useState([])
  const [diagnoses, setDiagnoses] = useState(diagnosesData)

  useEffect(() => {
    if (diagnosesData) {
      setDiagnoses(diagnosesData)
    }
  }, [diagnosesData])

  useEffect(() => {
    if (!isEmpty(searchResults)) {
      setDiagnoses(pluck('item', searchResults))
    }
  }, [searchResults])

  useEffect(() => {
    setSelectedItems(diagnoses.filter(({ id }) => diagnosesState[id]))
  }, [diagnoses, diagnosesState])

  if (!patientId) {
    return null
  }

  const loadNewItems = ({ categories, entityType }) => {
    const categoriesToLoad =
      categories.length > 0
        ? categories
        : filters
            .find((filter) => filter.type === entityType)
            .children.map(({ categoryId }) => categoryId)
    dispatch(fetchSoapDiagnoses(patientId, categoriesToLoad, entityType))
  }

  const suspectedStatus = Utils.findConstantIdByName(
    DiagnosisStatusName.SUSPECTED,
    DiagnosisStatus,
  )

  const createDiagnoseState = ({ id, name, nameTranslation }) => ({
    id,
    name,
    nameTranslation,
    locations: [],
    statusId: suspectedStatus,
  })

  const updateDiagnoses = (item) => {
    dispatch(
      editDifferentialDiagnosesState(
        item.id,
        diagnosesState[item.id] ? null : createDiagnoseState(item),
      ),
    )
  }

  const onCheckItem = (item) => {
    setSelectedItems((prevOrderedItems) => [...prevOrderedItems, item])
    updateDiagnoses(item)
  }

  const onUncheckItem = (item) => {
    setSelectedItems((prevOrderedItems) =>
      prevOrderedItems.filter(({ id }) => item.id !== id),
    )
    updateDiagnoses(item)
  }

  const isChecked = (item) =>
    item.items
      ? item.items.length > 0 && item.items.every(isChecked)
      : defaultIsCheckedItem(item, selectedItems)

  const onResetFilter = () => {
    setDiagnoses([])
  }

  const getDiagnoseWithField = (diagnose) =>
    diagnosesState[diagnose.id]
      ? { ...diagnosesState[diagnose.id], locations: diagnose.locations }
      : diagnose

  const diagnosesWithFields = diagnosesData.map(getDiagnoseWithField)
  const searchResultsWithFields = searchResults.map((result) => ({
    ...result,
    item: getDiagnoseWithField(result.item),
  }))

  return (
    <ListWithFilters
      showResetButton
      DetailsComponent={DiffDxExpandedDetails}
      ListItemProps={{
        showListItemActions: true,
      }}
      classes={{
        container: classes.listWithFiltersContainer,
      }}
      clearItems={() => dispatch(clearSoapDiagnoses())}
      editDisabled={editDisabled || !permissions.update}
      filters={filters}
      isCheckedItem={isChecked}
      isLoading={isLoading}
      items={diagnosesWithFields}
      loadNewItems={loadNewItems}
      ref={listRef}
      searchItems={({ searchTerm }) =>
        dispatch(searchDiagnoses(searchTerm, patientId))
      }
      searchPlaceholder={t('Search:DIFFERENTIAL_DIAGNOSES')}
      searchResults={searchResultsWithFields}
      onCheckItem={onCheckItem}
      onResetFilter={onResetFilter}
      onUncheckItem={onUncheckItem}
    />
  )
}

export default DifferentialDiagnoses
