import { AnyAction } from 'redux'
import { Coparent } from '@pbt/pbt-ui-components'

import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  CREATE_COPARENT,
  CREATE_COPARENT_FAILURE,
  CREATE_COPARENT_SUCCESS,
  DELETE_COPARENT,
  DELETE_COPARENT_FAILURE,
  DELETE_COPARENT_SUCCESS,
  UPDATE_COPARENT,
  UPDATE_COPARENT_FAILURE,
  UPDATE_COPARENT_SUCCESS,
  UPDATE_COPARENTS,
} from '../actions/types/coparents'
import type { RootState } from '../index'

export type CoparentsState = {
  error: string | null
  isLoading: boolean
  map: Record<string, Coparent>
}

export const INITIAL_STATE = {
  isLoading: false,
  error: null,
  map: {},
}

const coparents = (
  state: CoparentsState = INITIAL_STATE,
  action: AnyAction,
): CoparentsState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return INITIAL_STATE
    case UPDATE_COPARENTS:
      return { ...state, map: secondLevelMerge(state.map, action.coparents) }
    case CREATE_COPARENT:
      return { ...state, isLoading: true }
    case CREATE_COPARENT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CREATE_COPARENT_SUCCESS:
      return { ...state, isLoading: false }
    case UPDATE_COPARENT:
      return { ...state, isLoading: true }
    case UPDATE_COPARENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_COPARENT_SUCCESS:
      return { ...state, isLoading: false }
    case DELETE_COPARENT:
      return { ...state, isLoading: true }
    case DELETE_COPARENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_COPARENT_SUCCESS:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default coparents
export const getCoparents = (state: RootState): CoparentsState =>
  state.coparents
export const getCoparentsIsLoading = (state: RootState) =>
  getCoparents(state).isLoading
