import { keys, union } from 'ramda'

import { isNilOrEmpty } from '~/utils'

export const IMPORT_INVENTORY_STATUS = {
  FAILED: 'FAILED',
  SUCCEED: 'SUCCEED',
  IN_PROGRESS: 'IN_PROGRESS',
  IS_NOT_STARTED: 'IS_NOT_STARTED',
}

export function getPropertiesMapFromFields(fields) {
  return Object.entries(fields).reduce((acc, [name, { value }]) => {
    acc[name] = value
    return acc
  }, {})
}

export function getFieldsValuesList(fields) {
  return Object.values(fields).map(({ value }) => value)
}

export function hasCredentialsChanged(oldCredentials, newCredentials) {
  const allKeys = union(keys(oldCredentials), keys(newCredentials))
  return allKeys.reduce((hasChanges, key) => {
    const oldValue = oldCredentials[key]
    const newValue = newCredentials[key]
    return (
      hasChanges ||
      (oldValue !== newValue &&
        !(isNilOrEmpty(oldValue) && isNilOrEmpty(newValue)))
    )
  }, false)
}
