import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import deepEqual from 'fast-deep-equal'
import { isEmpty } from 'ramda'
import { Contact as ContactType } from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import {
  deleteContact,
  editContact,
  fetchContact,
  getContact,
  getContactsIsDeleting,
  getContactsIsFetching,
  getContactsIsUpdating,
} from '~/store/duck/contacts'
import { DataHandleWithUnsavedChanges } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import Contact from './Contact'

export interface ContactDetailsProps {
  itemId: string
  onClose: () => void
}

const ContactDetails = ({ itemId, onClose }: ContactDetailsProps) => {
  const dispatch = useDispatch()
  const isDeleting = useSelector(getContactsIsDeleting)
  const isUpdating = useSelector(getContactsIsUpdating)
  const isFetching = useSelector(getContactsIsFetching)
  const contact = useSelector(getContact(itemId))
  const { t } = useTranslation(['Common'])

  const [isContactChanged, setIsContactChanged] = useState(false)

  const contactRef = useRef<DataHandleWithUnsavedChanges>(null)

  const [openContactDialog] = useDialog(DialogNames.CONTACT)

  const setCloseOnDelete = useCloseAfterCreation(onClose, getContactsIsDeleting)

  useEffect(() => {
    if (itemId) {
      dispatch(fetchContact(itemId))
    }
  }, [itemId])

  const handleClone = () => {
    openContactDialog({
      contact: {
        ...contact,
        name: `${contact?.name ?? ''}_copy`,
      } as ContactType,
    })
  }

  const handleDelete = () => {
    setCloseOnDelete()
    dispatch(deleteContact(itemId))
  }

  const getNewContact = () => contactRef?.current?.get()

  const handleSave = () => {
    if (contactRef?.current?.validate()) {
      const newContact = getNewContact()
      dispatch(editContact(newContact))
    }
  }

  const getUnsavedData = () => {
    const newContact = getNewContact()
    const isUnsavedData =
      contact && !isEmpty(contact) && !deepEqual(contact, newContact)
    return isUnsavedData || false
  }

  const onChange = () => {
    setIsContactChanged(getUnsavedData())
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:CONTACT_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isContactChanged}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isUpdating}
      onBack={onClose}
      onCloneRequested={handleClone}
      onDeleteRequested={handleDelete}
      onSaveRequested={handleSave}
    >
      <Contact contact={contact} ref={contactRef} onChange={onChange} />
    </Expander>
  )
}

export default ContactDetails
