import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

interface UseStylesProps {
  columnWidth: string | number | undefined
}

const useStyles = makeStyles(
  () => ({
    headerColumn: (props: UseStylesProps) => ({
      width: props.columnWidth,
      minWidth: props.columnWidth,
      maxWidth: props.columnWidth,
      height: 0,
      maxHeight: 0,
      lineHeight: 0,
      visibility: 'collapse',
      padding: 0,
      borderWidth: 0,
    }),
  }),
  { name: 'ColGroupListItem' },
)

const useStylesRow = makeStyles(
  () => ({
    headerRow: {
      height: 0,
      maxHeight: 0,
      lineHeight: 0,
      visibility: 'collapse',
    },
  }),
  { name: 'ColGroupList' },
)

interface ColGroupListItemProps {
  columnWidth: string | number | undefined
}

const ColGroupListItem = ({ columnWidth }: ColGroupListItemProps) => {
  const classes = useStyles({ columnWidth })

  return <TableCell className={classes.headerColumn} />
}

interface ColGroupProps {
  columnWidths: (string | number | undefined)[]
}

// eslint-disable-next-line react/no-multi-comp
const ColGroupList = ({ columnWidths }: ColGroupProps) => {
  const classes = useStylesRow()

  return (
    <TableRow className={classes.headerRow}>
      {columnWidths.map((width, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ColGroupListItem columnWidth={width} key={index} />
      ))}
    </TableRow>
  )
}

export default ColGroupList
