import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateUtils,
  HtmlNotesPreview,
  LinkButton,
  moment,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { deleteVaccineHistory } from '~/store/actions/medicalHistory'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getConstantName,
  getConstantTranslatedName,
  getFeatureToggle,
  getVaccineDefaultAmount,
} from '~/store/reducers/constants'
import { getUserName } from '~/store/reducers/users'
import { SoapFile, VaccineTimelineItem } from '~/types'
import { addOriginalBusinessId, getManualInputSelectValue } from '~/utils'
import { isSoapOutsidePharmacyOrder } from '~/utils/orderUtils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

const useStyles = makeStyles(
  (theme) => ({
    certificateButton: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'VaccinationCard' },
)

export interface VaccinationCardProps extends Partial<TimelineCardProps> {
  clientId: string
  item: VaccineTimelineItem
  patientId: string
}

const VaccinationCard = ({
  item,
  clientId,
  patientId,
  ...rest
}: VaccinationCardProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Clients'])

  const VaccineDefaultAmount = useSelector(getVaccineDefaultAmount)
  const permissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const statusName = useSelector(
    getConstantName('ProcedureStatus', item.status),
  )
  const statusDisplayName = useSelector(
    getConstantTranslatedName('ProcedureStatus', item.status),
  )
  const dueDateUnitDuration = useSelector(
    getConstantName('AgeUnits', item.dueDate?.unitId),
  )
  const administeredByName = useSelector(getUserName(item.administeredBy))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const recordFromName = item.administeredIn || t('Common:PREVIOUS_CLINIC')

  const isContextItem = useIsCurrentContextItem(item)

  const [openVaccineHistoryDialog] = useDialog(DialogNames.VACCINE_HISTORY)
  const [openVaccineCertificatePreviewDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_PREVIEW,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const isFutureVaccine = statusName === 'Ordered'
  const isHistoryRecord = !item.soapId && !item.invoiceId
  const state = isHistoryRecord
    ? t('Clients:TIMELINE.VACCINATION_CARD.ACTION_STATE.RECORD_FROM', {
        recordFromName,
      })
    : statusDisplayName

  const getTitle = () => (isFutureVaccine ? '' : t('Common:ADMINISTERED'))

  const getText = () =>
    isFutureVaccine
      ? t('Clients:TIMELINE.VACCINATION_CARD.LAST_SHOT_ADMINISTERED')
      : t('Clients:TIMELINE.VACCINATION_CARD.NEXT_BOOSTER_DUE')

  const onDelete = () => {
    dispatch(deleteVaccineHistory(item.id, patientId))
  }

  const onEdit = () => {
    openVaccineHistoryDialog({ clientId, patientId, vaccine: item })
  }

  const handleGenerateProofOfVaccination = () => {
    openVaccineCertificatePreviewDialog({
      soapBusinessId: item.businessId,
      soapId: item.soapId,
      patientId,
      document: item.file as SoapFile,
    })
  }

  const handleOpenInvoice = () => {
    openInvoice({
      clientId,
      patientId,
      invoiceId: item.invoiceId,
      soapId: item.soapId,
      logId: item.id,
      logType: OrderType.PROCEDURE,
    })
  }

  return (
    <TimelineCard
      originBusinessId={item.businessId}
      title={`${item.description} ${getTitle()}`}
      onDelete={isHistoryRecord && item.id ? onDelete : undefined}
      onEdit={isHistoryRecord && item.id ? onEdit : undefined}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {item.lastShotDate && (
          <Text variant="body">{`${getText()} ${moment(
            item.lastShotDate,
          ).format('L')}`}</Text>
        )}
        {item.dueDateTime && (
          <Text variant="body">
            {t('Clients:TIMELINE.VACCINATION_CARD.NEXT_EXPIRATION_DATE')}:&nbsp;
            {DateUtils.formatDate(item.dueDateTime)}
          </Text>
        )}
        {!item.dueDateTime &&
          item.dueDate &&
          item.dueDate.amount &&
          item.dueDate.unitId && (
            // NOTE: we have a different expiration date formats
            // -- for rhapsody records (dueDate) is a time offset object
            // -- for history records (dueDateTime) is a pure date
            <Text variant="body">
              {t('Clients:TIMELINE.VACCINATION_CARD.NEXT_EXPIRATION_DATE')}
              :&nbsp;
              {DateUtils.formatDate(
                moment(item.date)
                  .add(
                    item.dueDate.amount,
                    dueDateUnitDuration as moment.unitOfTime.DurationConstructor,
                  )
                  .toISOString(),
              )}
            </Text>
          )}
        {administeredByName && (
          <Text variant="body">
            {t('Common:ADMINISTERED_BY')}: {administeredByName}
          </Text>
        )}
        {item.lotNumber && (
          <Text variant="body">
            {t('Common:LOT_NUMBER')}: {item.lotNumber}
          </Text>
        )}
        {item.serialNumber && (
          <Text variant="body">
            {t('Common:SERIAL_NUMBER')}: {item.serialNumber}
          </Text>
        )}
        {item.lotExpiration && (
          <Text variant="body">
            {t('Clients:TIMELINE.VACCINATION_CARD.LOT_EXPIRES')}:{' '}
            {DateUtils.formatDate(item.lotExpiration)}
          </Text>
        )}
        {item.amountId && (
          <Text variant="body">
            {t('Common:AMOUNT')}:{' '}
            {getManualInputSelectValue(
              VaccineDefaultAmount,
              item.amountId,
              item.amount,
            )}
          </Text>
        )}
        {item.notes && (
          <HtmlNotesPreview includeLabel notes={item.notes} variant="body" />
        )}
      </TimelineCardContent>
      <TimelineCardActions state={state}>
        {item.soapId && item?.file && (
          <LinkButton
            classes={{ button: classes.certificateButton }}
            onClick={handleGenerateProofOfVaccination}
          >
            {t('Common:RABIES_CERTIFICATE')}
          </LinkButton>
        )}
        {permissions.read && isSoapOutsidePharmacyOrder(item) && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${item.soapId}`,
              isPatientSharingEnabled ? item.businessId : null,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
        {item.invoiceId && isContextItem && (
          <LinkButton onClick={handleOpenInvoice}>
            {t('Common:VIEW_INVOICE')}
          </LinkButton>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default VaccinationCard
