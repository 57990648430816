import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { DateFormat, LanguageUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { useIsSoapLocked } from '~/store/hooks/soap'
import {
  getProblemBodySystemLog,
  getProblemLog,
} from '~/store/reducers/problems'
import { Problem, ProblemLogProblemContainer } from '~/types'

import IdentifiedProblemDetailsBreadcrumbs from './IdentifiedProblemDetailsBreadcrumbs'
import IdentifiedProblemDetailsEnums from './IdentifiedProblemDetailsEnums'
import IdentifiedProblemDetailsHistory from './IdentifiedProblemDetailsHistory'
import IdentifiedProblemDetailsNotes from './IdentifiedProblemDetailsNotes'
import IdentifiedProblemDetailsStatus from './IdentifiedProblemDetailsStatus'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
    },
    contentContainer: {
      overflowY: 'auto',
    },
    notesBox: {
      border: `1px solid ${theme.colors.divider}`,
      borderRadius: 4,
      padding: 8,
    },
  }),
  { name: 'IdentifiedProblemDetails' },
)

interface IdentifiedProblemDetailsProps {
  logKey: string
  problem: Problem
}

const IdentifiedProblemDetails = ({
  problem,
  logKey,
}: IdentifiedProblemDetailsProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const bodySystemLog = useSelector(getProblemBodySystemLog(logKey))
  const problemLog = useSelector(getProblemLog(logKey))

  const isReadOnly = useIsSoapLocked()

  const log = problem.type ? problemLog : bodySystemLog

  if (!log) {
    return null
  }

  return (
    <Box
      className={classes.container}
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Box className={classes.contentContainer} maxHeight="100%" pt={1.5}>
        {problem.type && (
          <IdentifiedProblemDetailsBreadcrumbs problem={problem} />
        )}
        {!isReadOnly && (
          <>
            <Text strong mb={1} variant="subheading3">
              {LanguageUtils.getTranslatedFieldName(problem)}
            </Text>
            {problem.type && (
              <IdentifiedProblemDetailsStatus
                log={log as ProblemLogProblemContainer}
                problem={problem}
              />
            )}
            <IdentifiedProblemDetailsNotes log={log} problem={problem} />
            {problem.type && (
              <IdentifiedProblemDetailsEnums
                problem={problem}
                problemLog={problemLog!}
              />
            )}
          </>
        )}
        {isReadOnly && log.entity.notes && (
          <>
            <Text strong mb={1} variant="subheading3">
              {LanguageUtils.getTranslatedFieldName(problem)}
            </Text>
            <Box className={classes.notesBox} mb={1}>
              <Text>{log.entity.notes}</Text>
            </Box>
          </>
        )}
        {problem.type && (
          <IdentifiedProblemDetailsHistory key={log.crc} problem={problem} />
        )}
      </Box>
      <Box mt="auto">
        <Text variant="caption">
          {`${t('Common:CREATED_BY')}: `}
          {Utils.getPersonString(log.entity.createdBy)}{' '}
          {moment(log.entity.creationDate).format(DateFormat.FULL_DATE_TIME)}
        </Text>
        <Text variant="caption">
          {`${t('Common:MODIFIED_BY')}: `}
          {Utils.getPersonString(log.entity.modifiedBy)}{' '}
          {moment(log.entity.modificationDate).format(
            DateFormat.FULL_DATE_TIME,
          )}
        </Text>
      </Box>
    </Box>
  )
}

export default IdentifiedProblemDetails
