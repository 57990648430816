import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { AvailabilityRule, AvailabilityRuleGroup } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage, getServerValidationError } from '~/utils/errors'

import {
  CLEAR_AVAILABILITY_RULE_VALIDATION_ERROR,
  CREATE_AVAILABILITY_RULE,
  CREATE_AVAILABILITY_RULE_FAILURE,
  CREATE_AVAILABILITY_RULE_SUCCESS,
  DELETE_AVAILABILITY_RULE,
  DELETE_AVAILABILITY_RULE_FAILURE,
  DELETE_AVAILABILITY_RULE_SUCCESS,
  EDIT_AVAILABILITY_RULE,
  EDIT_AVAILABILITY_RULE_FAILURE,
  EDIT_AVAILABILITY_RULE_SUCCESS,
  FETCH_AVAILABILITY_RULE,
  FETCH_AVAILABILITY_RULE_FAILURE,
  FETCH_AVAILABILITY_RULE_SUCCESS,
  FETCH_AVAILABILITY_RULES_LIST,
  FETCH_AVAILABILITY_RULES_LIST_FAILURE,
  FETCH_AVAILABILITY_RULES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_SUCCESS,
  UPDATE_AVAILABILITY_RULES,
} from '../actions/types/availabilityRules'
import type { RootState } from '../index'

export type AvailabilityRulesState = {
  currentRuleId: string | null
  error: string | null
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  list: AvailabilityRuleGroup[]
  map: Record<string, AvailabilityRule>
  totalCount: number
  validationError: string | null
}

export const INITIAL_STATE: AvailabilityRulesState = {
  map: {},
  list: [],
  isLoading: false,
  isFetching: false,
  isFetchingList: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  validationError: null,
  currentRuleId: null,
}

const availabilityRules = (
  state: AvailabilityRulesState = INITIAL_STATE,
  action: AnyAction,
): AvailabilityRulesState => {
  switch (action.type) {
    case FETCH_AVAILABILITY_RULES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_AVAILABILITY_RULES_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_AVAILABILITY_RULES_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case CREATE_AVAILABILITY_RULE:
      return { ...state, isLoading: true, error: null }
    case CREATE_AVAILABILITY_RULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentRuleId: action.ruleId,
        error: null,
      }
    case CREATE_AVAILABILITY_RULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_AVAILABILITY_RULE:
      return { ...state, isLoading: true, error: null, validationError: null }
    case UPDATE_AVAILABILITY_RULES:
      return { ...state, map: secondLevelMerge(state.map, action.rules) }
    case EDIT_AVAILABILITY_RULE_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_AVAILABILITY_RULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError: getServerValidationError(action.error),
      }
    case DELETE_AVAILABILITY_RULE:
      return { ...state, isLoading: true, error: null, validationError: null }
    case DELETE_AVAILABILITY_RULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: R.omit([action.ruleId], state.map),
        list: state.list.map((group) => ({
          ...group,
          rules: R.without([action.ruleId], group.rules),
        })),
      }
    case DELETE_AVAILABILITY_RULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError: getServerValidationError(action.error),
      }
    case FETCH_AVAILABILITY_RULE:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_AVAILABILITY_RULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_AVAILABILITY_RULE_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case CLEAR_AVAILABILITY_RULE_VALIDATION_ERROR:
      return { ...state, validationError: null }
    default:
      return state
  }
}

export default availabilityRules
export const getAvailabilityRules = (
  state: RootState,
): AvailabilityRulesState => state.availabilityRules
export const getAvailabilityRulesError = (state: RootState) =>
  getAvailabilityRules(state).error
export const getAvailabilityRuleIsLoading = (state: RootState) =>
  getAvailabilityRules(state).isLoading
export const getAvailabilityRuleIsFetching = (state: RootState) =>
  getAvailabilityRules(state).isFetching
export const getAvailabilityRuleIsFetchingList = (state: RootState) =>
  getAvailabilityRules(state).isFetchingList
export const getAvailabilityRulesList = (state: RootState) =>
  getAvailabilityRules(state).list
export const getAvailabilityRulesMap = (state: RootState) =>
  getAvailabilityRules(state).map
export const getAvailabilityRulesTotalCount = (state: RootState) =>
  getAvailabilityRules(state).totalCount
export const getAvailabilityRule = (ruleId: string | Nil) =>
  createSelector(getAvailabilityRulesMap, (map) =>
    ruleId ? map[ruleId] : undefined,
  )
export const getCurrentAvailabilityRuleId = (state: RootState) =>
  getAvailabilityRules(state).currentRuleId
export const getAvailabilityRuleValidationError = (state: RootState) =>
  getAvailabilityRules(state).validationError
