export const UPDATE_AVATAR = 'users/UPDATE_AVATAR'
export const UPDATE_USERS = 'users/UPDATE_USERS'
export const UPDATE_CURRENT_USERS_BUSINESS_ID =
  'users/UPDATE_CURRENT_USERS_BUSINESS_ID'
export const UPDATE_CURRENT_USER_ON_SERVER =
  'users/UPDATE_CURRENT_USER_ON_SERVER'
export const UPDATE_CURRENT_USER_ON_SERVER_SUCCESS =
  'users/UPDATE_CURRENT_USER_ON_SERVER_SUCCESS'
export const USERS_UPDATE_FAILURE = 'users/USERS_UPDATE_FAILURE'

export const ACCEPT_EULA = 'users/ACCEPT_EULA'
export const ACCEPT_EULA_FAILURE = 'users/ACCEPT_EULA_FAILURE'
export const ACCEPT_EULA_SUCCESS = 'users/ACCEPT_EULA_SUCCESS'

export const UPDATE_ZN_LAB_PROVIDER_ID = 'users/UPDATE_ZN_LAB_PROVIDER_ID'
export const UPDATE_ZN_LAB_PROVIDER_ID_SUCCESS =
  'users/UPDATE_ZN_LAB_PROVIDER_ID_SUCCESS'
export const UPDATE_ZN_LAB_PROVIDER_ID_FAILURE =
  'users/UPDATE_ZN_LAB_PROVIDER_ID_FAILURE'

export const FETCH_BALANCE = 'users/FETCH_BALANCE'
export const FETCH_BALANCE_SUCCESS = 'users/FETCH_BALANCE_SUCCESS'
export const FETCH_BALANCE_FAILURE = 'users/FETCH_BALANCE_FAILURE'

export const UPDATE_USER_SIGNATURE = 'users/UPDATE_USER_SIGNATURE'
export const UPDATE_USER_SIGNATURE_SUCCESS =
  'users/UPDATE_USER_SIGNATURE_SUCCESS'
export const UPDATE_USER_SIGNATURE_FAILURE =
  'users/UPDATE_USER_SIGNATURE_FAILURE'

export const UNLINK_USER_FROM_CHEWY_ACCOUNT =
  'users/UNLINK_USER_FROM_CHEWY_ACCOUNT'
export const UNLINK_USER_FROM_CHEWY_ACCOUNT_SUCCESS =
  'users/UNLINK_USER_FROM_CHEWY_ACCOUNT_SUCCESS'
export const UNLINK_USER_FROM_CHEWY_ACCOUNT_FAILURE =
  'users/UNLINK_USER_FROM_CHEWY_ACCOUNT_FAILURE'

export const LINK_USER_TO_CHEWY_ACCOUNT = 'users/LINK_USER_TO_CHEWY_ACCOUNT'
