import { MAX_RECORDS_COUNT } from '~/constants/configs/landing'
import i18n from '~/locales/i18n'
import { LandingLayout, LandingSettings } from '~/types'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchLandingLayout = (businessId: string) =>
  request(
    getPreludeAPIPath('landing-dashboard/layout/person'),
    { method: 'GET', params: { businessId, language: i18n.language } },
    true,
  )

export const editLandingLayout = (
  businessId: string,
  layout: LandingLayout,
  settings: LandingSettings,
) =>
  request(
    getPreludeAPIPath('landing-dashboard/layout/person'),
    { method: 'POST', params: { businessId }, data: { layout, settings } },
    true,
  )

export const deleteLandingLayout = (businessId: string) =>
  request(
    getPreludeAPIPath('landing-dashboard/layout/person'),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchLandingWidgetsData = (
  businessId: string,
  widgetNames: string[],
) =>
  request(
    getPreludeAPIPath('landing-dashboard/widget/list/data'),
    {
      method: 'GET',
      params: {
        businessId,
        widgets: widgetNames.join(','),
        from: 0,
        to: MAX_RECORDS_COUNT - 1,
        language: i18n.language,
      },
    },
    true,
    Schema.widgetsData,
  )

export const fetchClientAndPatientWidgetsData = (
  businessId: string,
  widgetNames: string[],
  patientId: string,
  pageSize?: number,
) =>
  request(
    getPreludeAPIPath('patient-snapshot/widget/list/data'),
    {
      method: 'GET',
      params: {
        businessId,
        widgets: widgetNames.join(','),
        patientId,
        from: 0,
        to: pageSize ? pageSize - 1 : 19,
        language: i18n.language,
      },
    },
    true,
    Schema.widgetsData,
  )
