import {
  Defaults,
  Nil,
  Question,
  UnsavedQuestion,
} from '@pbt/pbt-ui-components'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchQuestions = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
) =>
  request(
    getAPIPath('admin/services/customQuestions'),
    { method: 'GET', params: { businessId, from, to, query } },
    true,
    Schema.questionsList,
  )

export const fetchQuestion = (businessId: string, questionId: string) =>
  request(
    getAPIPath(`admin/services/customQuestions/${questionId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.question,
  )

export const createQuestion = (
  businessId: string,
  question: Partial<UnsavedQuestion>,
) =>
  request(
    getAPIPath('admin/services/customQuestions'),
    { method: 'POST', data: question, params: { businessId } },
    true,
    Schema.question,
  )

export const editQuestion = (businessId: string, question: Question) =>
  request(
    getAPIPath(`admin/services/customQuestions/${question.id}`),
    { method: 'PUT', data: question, params: { businessId } },
    true,
    Schema.question,
  )

export const deleteQuestion = (businessId: string, questionId: string) =>
  request(
    getAPIPath(`admin/services/customQuestions/${questionId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchQuestionsForSoap = (businessId: string, soapId: string) =>
  request(
    getAPIPath(`soaps/${soapId}/customQuestions/log`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.questionsArray,
  )

export const saveQuestionsForSoap = (
  businessId: string,
  soapId: string,
  log: any,
) =>
  request(
    getAPIPath(`soaps/${soapId}/customQuestions/log`),
    { method: 'POST', data: log, params: { businessId } },
    true,
    Schema.questionsArray,
  )
