import { Defaults } from '@pbt/pbt-ui-components'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchShipmentItemsForVaccineWithVariation = (
  businessId: string,
  procedureId: string,
  from: number,
  to: number,
  includeExpiredItems: boolean,
) =>
  request(
    getPreludeAPIPath(
      'inventory-integration/shipment/item/forVaccination/withVariation',
    ),
    {
      method: 'GET',
      params: {
        businessId,
        procedureId,
        from,
        to,
        showExpired: includeExpiredItems,
      },
    },
    true,
    Schema.shipmentItemList,
  )

export const fetchShipmentItemsForVaccine = (
  businessId: string,
  procedureId: string,
  from: number,
  to: number,
) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment/item/forVaccination'),
    { method: 'GET', params: { businessId, procedureId, from, to } },
    true,
    Schema.shipmentItemList,
  )

export const fetchVariationShipmentItems = (
  businessId: string,
  variationId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment/item/list'),
    { method: 'GET', params: { businessId, variationId, from, to } },
    true,
    Schema.shipmentItemList,
  )
