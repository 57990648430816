import React from 'react'

import { DiagnosesState } from '~/types'

import DiagnoseStatusSelect from '../../DiagnoseStatusSelect'
import DifferentialDiagnoseLocation from '../../DifferentialDiagnoseLocation'

export interface DifferentialDiagnoseButtonSectionProps {
  diagnose: DiagnosesState
  editDisabled?: boolean
  onChange: (id: string, payload: DiagnosesState) => void
}

const DifferentialDiagnoseButtonSection = ({
  diagnose,
  editDisabled,
  onChange,
}: DifferentialDiagnoseButtonSectionProps) => (
  <>
    {diagnose.statusId && (
      <DiagnoseStatusSelect
        currentItem={diagnose}
        diagnoseData={diagnose}
        disabled={editDisabled}
        onChange={onChange}
      />
    )}
    {diagnose.locations?.map((locationItem) => (
      <DifferentialDiagnoseLocation
        currentItem={locationItem}
        diagnoseData={diagnose}
        editDisabled={editDisabled}
        key={locationItem.id}
        onChange={onChange}
      />
    ))}
  </>
)

export default DifferentialDiagnoseButtonSection
