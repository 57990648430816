import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchConnectedDevices = (businessId: string) =>
  request(
    getAPIPath('finance/pay/pos/device'),
    { method: 'GET', params: { businessId } },
    true,
    Schema.connectedDevicesArray,
  )

export const deleteConnectedDevice = (
  businessId: string,
  deviceAuth0Id: string,
) =>
  request(
    getAPIPath(`finance/pay/pos/device/${deviceAuth0Id}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )
