import React from 'react'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

interface FinalizeOrdersActionHeaderProps {
  text: string
}

export const FinalizeOrdersActionHeader = ({
  text,
}: FinalizeOrdersActionHeaderProps) => (
  <Grid container>
    <Grid item pl={1} pt={0.5}>
      <Text variant="subheading4">{text}</Text>
    </Grid>
  </Grid>
)
