import { all, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  fetchPatientPreferences,
  fetchPatientPreferencesFailure,
  fetchPatientPreferencesSuccess,
  savePatientPreferences,
  savePatientPreferencesFailure,
  savePatientPreferencesSuccess,
} from '../reducers/patientPreferences'
import requestAPI from './utils/requestAPI'

export function* fetchPatientPreferencesSaga({
  payload,
}: ReturnType<typeof fetchPatientPreferences>) {
  try {
    const { preference: patientPreferences } = yield* requestAPI(
      API.fetchPatientPreferences,
      payload.id,
    )

    yield put(
      fetchPatientPreferencesSuccess({
        patientId: payload.id,
        preferences: patientPreferences,
      }),
    )
  } catch (error) {
    yield put(fetchPatientPreferencesFailure({ error: error as ApiError }))
  }
}

export function* savePatientPreferencesSaga({
  payload,
}: ReturnType<typeof savePatientPreferences>) {
  try {
    const result = yield* requestAPI(API.savePatientPreferences, {
      ...payload,
    })
    yield put(
      savePatientPreferencesSuccess({
        patientId: payload.patientId,
        preferences: result,
      }),
    )
  } catch (error) {
    yield put(savePatientPreferencesFailure({ error: error as ApiError }))
  }
}

function* watchFetchPatientPreferences() {
  yield takeLeading(fetchPatientPreferences.type, fetchPatientPreferencesSaga)
}

function* watchSavePatientPreferences() {
  yield takeLatest(savePatientPreferences.type, savePatientPreferencesSaga)
}

export default function* patientPreferencesSaga() {
  yield all([watchFetchPatientPreferences(), watchSavePatientPreferences()])
}
