import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ApiError,
  ErrorTooltip,
  MarketingStylesButton,
  Nil,
  PasswordInput,
  User,
} from '@pbt/pbt-ui-components'

import { clearAuthorizationError } from '~/store/actions/auth'
import Business from '~/store/dto/Business'
import { getAuthorizationError } from '~/store/reducers/auth'
import { getErrorMessage, removeServerErrorPrefix } from '~/utils/errors'

import ForgotPasswordLink from '../link/ForgotPasswordLink'
import ClinicLogo from '../logos/ClinicLogo'
import AuthorizationErrorTooltip from './AuthorizationErrorTooltip'
import UserPicker from './UserPicker'

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      width: '100%',
    },
    clinicLogo: {
      width: 156,
      height: 80,
    },
    userPicker: {
      marginTop: 28,
    },
    passwordContainer: {
      marginTop: theme.spacing(4),
    },
    forgotPasswordLink: {
      width: '100%',
    },
  }),
  { name: 'LockForm' },
)

interface LockFormProps {
  error: string | ApiError | Nil
  isFetching: boolean
  journal: {
    [userId: string]: Partial<User>
  }
  lastBusiness: any
  lastUserId: string
  onNewEmailSubmit: (email: string) => void
  onSubmit: (email: string, password: string) => void
}

const LockForm = ({
  onSubmit,
  onNewEmailSubmit,
  error = null,
  isFetching = false,
  journal,
  lastUserId,
  lastBusiness,
}: LockFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const authorizationError = useSelector(getAuthorizationError)
  const hasAuthorizationError = Boolean(authorizationError)
  const { t } = useTranslation(['Common', 'Validations'])

  const [password, setPassword] = useState('')
  const [touched, setTouched] = useState(false)
  const [passwordValidationError, setPasswordValidationError] = useState('')
  const [pickedUser, setPickedUser] = useState(journal[lastUserId] || {})
  const [email, setEmail] = useState(pickedUser.email || '')
  const [interacted, setInteracted] = useState(false)

  useEffect(() => {
    if (
      error &&
      !touched &&
      !passwordValidationError &&
      !hasAuthorizationError
    ) {
      const passwordValidationErrorUpdater: SetStateAction<string> = (
        prevError,
      ) =>
        prevError ||
        removeServerErrorPrefix(getErrorMessage(error) as string) ||
        ''
      setPasswordValidationError(passwordValidationErrorUpdater)
    }
  }, [error, touched])

  useEffect(() => {
    if (hasAuthorizationError && (password || touched) && interacted) {
      dispatch(clearAuthorizationError())
    }
  }, [password, touched])

  if (!journal || !journal[lastUserId] || !email) {
    return <Navigate replace to="/auth/login" />
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    setPasswordValidationError('')
    setInteracted(false)
    if (!isFetching) {
      if (!password) {
        setPasswordValidationError(t('Validations:ENTER_PASSWORD'))
      } else {
        onSubmit(email, password)
      }
    }
    return false
  }

  const onUserPicked = (user: Partial<User> = {}) => {
    setPickedUser(user)
    setEmail(user?.email || '')
    setInteracted(true)
  }

  const getButtonTitle = () => {
    if (isFetching) {
      return t('Common:LOGGING_IN')
    }
    if (!password) {
      return t('Common:ENTER_PASSWORD')
    }
    return t('Common:LOGIN')
  }

  const clinic = new Business(lastBusiness)

  return (
    <form noValidate className={classes.form} onSubmit={handleSubmit}>
      <Grid container justifyContent="center">
        <ClinicLogo className={classes.clinicLogo} clinic={clinic} />
        <UserPicker
          className={classes.userPicker}
          initialValue={pickedUser}
          journal={journal}
          onChange={onUserPicked}
          onExpandedChange={() => {
            if (passwordValidationError) {
              setPasswordValidationError('')
            }
          }}
          onSubmit={onNewEmailSubmit}
        />
        <ErrorTooltip
          message={passwordValidationError}
          open={Boolean(!touched && email && passwordValidationError)}
        >
          <Grid
            container
            alignItems="center"
            className={classes.passwordContainer}
            wrap="nowrap"
          >
            <PasswordInput
              disabled={isFetching}
              error={Boolean(!touched && passwordValidationError)}
              password={password}
              onBlur={() => {
                if (passwordValidationError) {
                  setPasswordValidationError('')
                }
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTouched(true)
                setPassword(event.target.value)
              }}
              onMouseDown={() => setInteracted(true)}
            />
          </Grid>
        </ErrorTooltip>
        <Grid container justifyContent="flex-end">
          <ForgotPasswordLink />
        </Grid>
      </Grid>
      <AuthorizationErrorTooltip error={authorizationError}>
        <MarketingStylesButton className={classes.submit} type="submit">
          {getButtonTitle()}
        </MarketingStylesButton>
      </AuthorizationErrorTooltip>
    </form>
  )
}

export default LockForm
