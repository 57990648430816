import { ImagingOrder } from '~/types'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const placeImagingOrder = (businessId: string, order: ImagingOrder) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/orders'),
    { method: 'POST', params: { businessId }, data: order },
    true,
    Schema.imagingOrder,
  )

export const batchPlaceImagingOrder = (
  businessId: string,
  orders: Partial<ImagingOrder>[],
) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/orders/batch'),
    { method: 'POST', params: { businessId }, data: orders },
    true,
    Schema.imagingOrdersArray,
  )

export const editImagingOrder = (businessId: string, order: ImagingOrder) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/orders'),
    { method: 'PUT', params: { businessId }, data: order },
    true,
    Schema.imagingOrder,
  )

/**
 * @param businessId
 * @param targetBusinessId
 * @param {Boolean} force -- when set, fetch list of modalities directly from 3rd party service instead of rhapsody database
 */
export const fetchImagingModality = (
  businessId: string,
  targetBusinessId: string,
  force?: boolean,
) =>
  request(
    getPreludeAPIPath(`idexx-imaging/v1/modality/get/${targetBusinessId}`),
    { method: 'GET', params: { businessId, force } },
    true,
  )

export const fetchImagingOrders = (businessId: string, soapId: string) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/orders'),
    { method: 'GET', params: { businessId, soapId } },
    true,
    Schema.imagingVendorOrdersList,
  )

export const fetchImagingOrder = (businessId: string, orderId: string) =>
  request(
    getPreludeAPIPath(`idexx-imaging/v1/orders/${orderId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.imagingOrder,
  )
