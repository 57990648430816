import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Moment } from 'moment'
import { DateFormat, moment, Text } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { getReminderGroup } from '~/store/duck/reminders'
import { ReminderGroup } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
    },
    subTitle: {
      color: theme.colors.title,
    },
    subTitleImportant: {
      color: theme.colors.important,
    },
  }),
  { name: 'RemindersListGroup' },
)

const GROUP_TYPE = {
  MONTH: 'MONTH',
  EXPIRED: 'EXPIRED',
}

const getDateLabel = (date?: Moment) => {
  if (!date) {
    return undefined
  }

  return moment().isSame(date, 'month')
    ? i18n.t('Time:LABEL.THIS_MONTH').toLowerCase()
    : date.fromNow()
}

export interface RemindersListGroupProps {
  className?: string
  groupId: string
}

const RemindersListGroup = ({
  className,
  groupId,
}: RemindersListGroupProps) => {
  const classes = useStyles()
  const group: ReminderGroup = useSelector(getReminderGroup(groupId))
  const { t } = useTranslation(['Common', 'Time'])

  const date = group?.month ? moment(group.month, 'YYYY-MM') : undefined
  const title =
    group?.itemType === GROUP_TYPE.EXPIRED
      ? t('Common:EXPIRED')
      : date?.format(DateFormat.MONTH_AND_YEAR)

  const subTitle = getDateLabel(date)

  const isInThePast = date && date.isBefore(moment())

  return (
    <Grid
      container
      className={classNames(className, classes.root)}
      px={2}
      py={1}
      wrap="nowrap"
    >
      <Text strong mr={1} variant="body2">
        {title}
      </Text>
      {subTitle && (
        <Text
          className={classNames(classes.subTitle, {
            [classes.subTitleImportant]: isInThePast,
          })}
          variant="body2"
        >
          {subTitle}
        </Text>
      )}
    </Grid>
  )
}

export default RemindersListGroup
