import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil, Text } from '@pbt/pbt-ui-components'

import { getPatient } from '../../../../store/reducers/patients'
import { formatMoneyRange } from '../../invoices/invoiceUtils'

export interface FinanceCompactTotalFooterCellProps {
  patientId: string | Nil
  subtotal: number | Nil
}

const FinanceCompactTotalFooterCell = ({
  subtotal,
  patientId,
}: FinanceCompactTotalFooterCellProps) => {
  const { t } = useTranslation('Common')

  const patient = useSelector(getPatient(patientId))

  return R.isNil(subtotal) ? null : (
    <Text inline strong variant="body2">
      {patient?.name
        ? t('Common:PATIENT_TOTAL_WITH_VALUE', {
            patientName: patient.name,
            amount: formatMoneyRange(subtotal),
          })
        : t('Common:TOTAL_WITH_VALUE', { amount: formatMoneyRange(subtotal) })}
    </Text>
  )
}

export default FinanceCompactTotalFooterCell
