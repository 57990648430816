import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import * as R from 'ramda'
import {
  Email as EmailIcon,
  Estimates as EstimatesIcon,
  Eye as EyeIcon,
  Print as PrintIcon,
  TasksDashboard as TasksDashboardIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import i18n from '~/locales/i18n'
import { getMultipleLabTests } from '~/store/duck/labTestsDashboard'
import {
  getWidgetData,
  getWidgetDataIsLoading,
  getWidgetDataTotalCount,
} from '~/store/duck/landing'
import { useOpenInvoice } from '~/store/hooks/finance'
import {
  useGetLabResultPreviewActions,
  useGetOrderPreviewActions,
} from '~/store/hooks/labTestsDashboard'
import {
  getFeatureToggle,
  getLabsWidgetAssignedMode,
} from '~/store/reducers/constants'
import { LabTestDashboardItem, WidgetColumn } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import DateGroupingCell from '../DateGroupingCell'
import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import NoRecords from '../NoRecords'
import TableWidget from '../TableWidget'
// @ts-ignore
import useEnrichGroupedItemsByDate from '../useEnrichGroupedItemsByDate'
import { getVisibleWidgetColumns } from '../widgetUtils'
import ClientAndPatientLabsCell from './ClientAndPatientLabsCell'
import OrderedDateCell from './OrderedDateCell'

const columns: WidgetColumn<LabTestDashboardItem>[] = [
  {
    id: 'dateGrouping',
    prop: (item) => <DateGroupingCell title={item?.dateTitle} />,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1.5,
      [WidgetWidthType.HALF_WIDTH]: 1.5,
      [WidgetWidthType.THIRD_WIDTH]: 2,
    },
    label: i18n.t('Common:DATE_TIME'),
  },
  {
    id: 'orderedDate',
    component: OrderedDateCell,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 1,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 1,
      [WidgetWidthType.HALF_WIDTH]: 1,
      [WidgetWidthType.THIRD_WIDTH]: 1.5,
    },
    label: i18n.t('Common:ORDERED'),
  },
  {
    id: 'tests',
    prop: (item) => (item?.tests || []).map(R.prop('name')).join(', '),
    width: 3.5,
    widthMap: {
      [WidgetWidthType.FULL_WIDTH]: 3.5,
      [WidgetWidthType.TWO_THIRDS_WIDTH]: 3.5,
      [WidgetWidthType.HALF_WIDTH]: 3.5,
      [WidgetWidthType.THIRD_WIDTH]: 2.5,
    },
    label: i18n.t('Common:LAB_ORDER'),
  },
  {
    id: 'clientAndPatientLabs',
    component: ClientAndPatientLabsCell,
    width: 3,
    widthTypes: [WidgetWidthType.FULL_WIDTH],
    label: i18n.t('Common:PATIENT'),
  },
  {
    id: 'vetName',
    prop: (item) => item?.vet?.name,
    width: 2,
    widthTypes: [
      WidgetWidthType.FULL_WIDTH,
      WidgetWidthType.TWO_THIRDS_WIDTH,
      WidgetWidthType.HALF_WIDTH,
    ],
    label: i18n.t('Common:DOCTOR'),
  },
]

interface LabsWidgetProps extends LandingWidgetProps<LabTestDashboardItem> {
  onExpand: () => void
  rowsCount?: number
  widthType?: WidgetWidthType
}

const LabsWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  rowsCount = 10,
  onExpand,
  ...rest
}: LabsWidgetProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Landing'])

  const LabsWidgetAssignedMode = useSelector(getLabsWidgetAssignedMode)
  const labIdentifiers = useSelector(
    getWidgetData(LandingType.LANDING_DASHBOARD, LandingWidgetName.LABS),
  )
  const totalCount = useSelector(
    getWidgetDataTotalCount(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.LABS,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.LABS,
    ),
  )
  const labs = useSelector(getMultipleLabTests(labIdentifiers))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const labsList = useEnrichGroupedItemsByDate({
    dateProp: 'orderedDate',
    titleProp: 'dateTitle',
    items: labs,
  })

  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(null, openInvoiceDialog)

  const getLabResultPreviewActions = useGetLabResultPreviewActions()
  const getOrderPreviewActions = useGetOrderPreviewActions()

  const navigateToLabTestDashboard = (item?: LabTestDashboardItem) => {
    if (item) {
      navigate(`/lab-tests-dashboard/${item.identifier}`)
    }
  }
  const handleNavigateToItemDetails = navigateToLabTestDashboard
  const handleViewInLabDashboard = navigateToLabTestDashboard

  const handleOpenInvoice = (item: LabTestDashboardItem) => {
    openInvoice({
      clientId: item.client,
      patientId: item.patient,
      invoiceId: item.invoiceId,
      soapId: item.soapId,
    })
  }

  const handleGoToSoap = (item: LabTestDashboardItem) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${item.soapId}`,
        isPatientSharingEnabled ? item.businessId : null,
      ),
    )
  }

  const handlePrintLabel = (item?: LabTestDashboardItem) => {
    if (!item) {
      return
    }
    const { order, vendorId, client, patient } = item

    openPrintLabOrderLabelDialog({
      labOrderId: order?.vendorOrderIdentifier,
      orderDate: order?.orderDate,
      vendorId,
      clientId: client,
      patientId: patient,
    })
  }

  const handleCreateTask = (item?: LabTestDashboardItem) => {
    if (item) {
      openTaskDialog({ clientId: item.client, patientId: item.patient })
    }
  }

  const handleGoToLabsDashboard = () => {
    navigate('/lab-tests-dashboard/')
  }

  const getActions = (item?: LabTestDashboardItem) => {
    const {
      order,
      vendorId,
      client: clientId,
      patient: patientId,
      soapId,
      businessId,
    } = item || {}

    const hasClientAndPatient = Boolean(clientId && patientId)

    const labResultPreviewActions = getLabResultPreviewActions({
      order,
      vendorId,
      clientId,
      patientId,
      soapId,
    })

    const hasLabResultPreviewActions =
      labResultPreviewActions?.preview ||
      labResultPreviewActions?.print ||
      labResultPreviewActions?.email

    const orderPreviewActions = getOrderPreviewActions({
      order,
      soapId,
      soapBusinessId: businessId,
      vendorId,
    })

    const hasOrderPreviewActions =
      orderPreviewActions?.preview || orderPreviewActions?.print

    return [
      {
        id: 'labDashboard',
        isGroup: true,
        items: [
          {
            id: 'viewInLabDashboard',
            label: t('Common:VIEW_IN_LAB_DASHBOARD'),
            Icon: EyeIcon,
            onClick: () => handleViewInLabDashboard(item),
          },
          item?.soapId && {
            id: 'goToSoap',
            label: t('Common:GO_TO_SOAP'),
            Icon: ArrowForwardIcon,
            onClick: () => handleGoToSoap(item),
          },
          item?.invoiceId && {
            id: 'openInvoice',
            label: t('Common:OPEN_INVOICE'),
            Icon: EstimatesIcon,
            onClick: () => handleOpenInvoice(item),
          },
          hasClientAndPatient && {
            id: 'printLabel',
            label: t('Common:PRINT_LABEL'),
            Icon: PrintIcon,
            onClick: () => handlePrintLabel(item),
          },
          {
            id: 'createTask',
            label: t('Landing:LABS_WIDGET.CREATE_TASK'),
            Icon: TasksDashboardIcon,
            onClick: () => handleCreateTask(item),
          },
        ],
      },
      hasLabResultPreviewActions && {
        id: 'results',
        title: t('Common:RESULTS'),
        isGroup: true,
        items: [
          labResultPreviewActions?.preview && {
            id: 'viewResults',
            label: t('Common:VIEW_RESULTS'),
            Icon: EyeIcon,
            onClick: () => {
              if (
                labResultPreviewActions.preview &&
                labResultPreviewActions.preview.handle
              ) {
                labResultPreviewActions.preview.handle()
              }
            },
          },
          labResultPreviewActions?.print && {
            id: 'printResults',
            label: t('Common:PRINT_RESULTS'),
            Icon: PrintIcon,
            onClick: () => {
              if (
                labResultPreviewActions.print &&
                labResultPreviewActions.print.handle
              ) {
                labResultPreviewActions.print.handle()
              }
            },
          },
          labResultPreviewActions?.email && {
            id: 'emailResults',
            label: t('Common:EMAIL_RESULTS_ACTION'),
            Icon: EmailIcon,
            onClick: () => {
              if (
                labResultPreviewActions.email &&
                labResultPreviewActions.email.handle
              ) {
                labResultPreviewActions.email.handle()
              }
            },
          },
        ],
      },
      hasOrderPreviewActions && {
        id: 'order',
        title: t('Common:ORDER_ACTION'),
        isGroup: true,
        items: [
          orderPreviewActions?.preview && {
            id: 'viewOrder',
            label: t('Common:VIEW_ORDER'),
            Icon: EyeIcon,
            onClick: () => {
              if (
                orderPreviewActions.preview &&
                orderPreviewActions.preview.handle
              ) {
                orderPreviewActions.preview.handle()
              }
            },
          },
          orderPreviewActions?.print && {
            id: 'printOrder',
            label: t('Common:PRINT_ORDER'),
            Icon: PrintIcon,
            onClick: () => {
              if (
                orderPreviewActions.print &&
                orderPreviewActions.print.handle
              ) {
                orderPreviewActions.print.handle()
              }
            },
          },
        ],
      },
    ].filter(Boolean) as PopperAction[]
  }

  const visibleColumns = getVisibleWidgetColumns(columns, widthType)

  return (
    <LandingWidget
      showHeaderTitles
      assignedModes={LabsWidgetAssignedMode}
      columns={visibleColumns}
      component={TableWidget}
      data={labsList}
      getActions={getActions}
      isLoading={isLoading}
      navigateToDetailsTooltip={t('Landing:LABS_WIDGET.GO_TO_LABS')}
      navigateToItemDetailsTooltip={t(
        'Landing:LABS_WIDGET.VIEW_LAB_IN_DASHBOARD',
      )}
      noRecords={
        <NoRecords
          action={t('Landing:LABS_WIDGET.GO_TO_LABS_DASHBOARD')}
          text={t('Landing:LABS_WIDGET.NO_LABS')}
          onClick={handleGoToLabsDashboard}
        />
      }
      rowsCount={rowsCount}
      totalCount={totalCount}
      onExpand={onExpand}
      onNavigateToDetails={handleGoToLabsDashboard}
      onNavigateToItemDetails={handleNavigateToItemDetails}
      {...rest}
    />
  )
}

export default LabsWidget
