import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { SortingOrder } from '~/constants/sortingOrders'
import { BenchmarkingKey } from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_BENCHMARKING_ITEMS = 'benchmarking/FETCH_BENCHMARKING_ITEMS'
export const FETCH_BENCHMARKING_ITEMS_SUCCESS =
  'benchmarking/FETCH_BENCHMARKING_ITEMS_SUCCESS'
export const FETCH_BENCHMARKING_ITEMS_FAILURE =
  'benchmarking/FETCH_BENCHMARKING_ITEMS_FAILURE'

export const FETCH_MORE_BENCHMARKING_ITEMS =
  'benchmarking/FETCH_MORE_BENCHMARKING_ITEMS'
export const FETCH_MORE_BENCHMARKING_ITEMS_SUCCESS =
  'benchmarking/FETCH_MORE_BENCHMARKING_ITEMS_SUCCESS'
export const FETCH_MORE_BENCHMARKING_ITEMS_FAILURE =
  'benchmarking/FETCH_MORE_BENCHMARKING_ITEMS_FAILURE'

export const CREATE_BENCHMARKING_KEY = 'benchmarking/CREATE_BENCHMARKING_KEY'
export const CREATE_BENCHMARKING_KEY_SUCCESS =
  'benchmarking/CREATE_BENCHMARKING_KEY_SUCCESS'
export const CREATE_BENCHMARKING_KEY_FAILURE =
  'benchmarking/CREATE_BENCHMARKING_KEY_FAILURE'

export const EDIT_BENCHMARKING_KEY = 'benchmarking/EDIT_BENCHMARKING_KEY'
export const EDIT_BENCHMARKING_KEY_SUCCESS =
  'benchmarking/EDIT_BENCHMARKING_KEY_SUCCESS'
export const EDIT_BENCHMARKING_KEY_FAILURE =
  'benchmarking/EDIT_BENCHMARKING_KEY_FAILURE'

export const UPDATE_BENCHMARKING_KEYS = 'benchmarking/UPDATE_BENCHMARKING_KEYS'
export const DEACTIVATE_BENCHMARKING_KEY =
  'benchmarking/DEACTIVATE_BENCHMARKING_KEY'

export type FetchBenchmarkingItemsProps = {
  from: number
  includeDeactivated?: boolean
  sortBy: string
  sortingOrder: SortingOrder
  state?: string
  to: number
}

export const fetchBenchmarkingItems = ({
  from,
  to,
  sortBy,
  sortingOrder,
  state,
  includeDeactivated = true,
}: FetchBenchmarkingItemsProps) => ({
  type: FETCH_BENCHMARKING_ITEMS,
  from,
  to,
  sortBy,
  sortingOrder,
  state,
  includeDeactivated,
})
export const fetchBenchmarkingItemsSuccess = (
  list: BenchmarkingKey[],
  totalCount: number,
) => ({
  type: FETCH_BENCHMARKING_ITEMS_SUCCESS,
  list,
  totalCount,
})
export const fetchBenchmarkingItemsFailure = (error: ApiError) => ({
  type: FETCH_BENCHMARKING_ITEMS_FAILURE,
  error,
})

export const fetchMoreBenchmarkingItems = ({
  from,
  to,
  sortBy,
  sortingOrder,
  state,
  includeDeactivated = true,
}: FetchBenchmarkingItemsProps) => ({
  type: FETCH_MORE_BENCHMARKING_ITEMS,
  from,
  to,
  sortBy,
  sortingOrder,
  state,
  includeDeactivated,
})
export const fetchMoreBenchmarkingItemsSuccess = (
  list: BenchmarkingKey[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_BENCHMARKING_ITEMS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreBenchmarkingItemsFailure = (error: ApiError) => ({
  type: FETCH_MORE_BENCHMARKING_ITEMS_FAILURE,
  error,
})

export const createBenchmarkingKey = (item: Partial<BenchmarkingKey>) => ({
  type: CREATE_BENCHMARKING_KEY,
  item,
})
export const createBenchmarkingKeySuccess = (newKeyId: BenchmarkingKey) => ({
  type: CREATE_BENCHMARKING_KEY_SUCCESS,
  newKeyId,
})
export const createBenchmarkingKeyFailure = (error: ApiError) => ({
  type: CREATE_BENCHMARKING_KEY_FAILURE,
  error,
})

export const editBenchmarkingKey = (newKey: BenchmarkingKey) => ({
  type: EDIT_BENCHMARKING_KEY,
  newKey,
})
export const editBenchmarkingKeySuccess = () => ({
  type: EDIT_BENCHMARKING_KEY_SUCCESS,
})
export const editBenchmarkingKeyFailure = (error: ApiError) => ({
  type: EDIT_BENCHMARKING_KEY_FAILURE,
  error,
})

export const updateBenchmarkingKeys = (
  keys: Record<string, BenchmarkingKey>,
) => ({
  type: UPDATE_BENCHMARKING_KEYS,
  keys,
})

export type BenchmarkingState = {
  error: string | null
  isCreating: boolean
  isEditing: boolean
  isLoading: boolean
  lastCreatedKeyId: string | null
  list: string[]
  map: Record<string, BenchmarkingKey>
  totalCount: number
}

export const INITIAL_STATE: BenchmarkingState = {
  isCreating: false,
  isEditing: false,
  isLoading: false,
  list: [],
  totalCount: 0,
  error: null,
  map: {},
  lastCreatedKeyId: null,
}

export const benchmarkingReducer = (
  state: BenchmarkingState = INITIAL_STATE,
  action: AnyAction,
): BenchmarkingState => {
  switch (action.type) {
    case FETCH_BENCHMARKING_ITEMS:
      return {
        ...state,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
        isLoading: true,
      }
    case FETCH_BENCHMARKING_ITEMS_SUCCESS:
      return {
        ...state,
        list: action.list,
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_BENCHMARKING_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_BENCHMARKING_ITEMS:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MORE_BENCHMARKING_ITEMS_SUCCESS:
      return {
        ...state,
        totalCount: action.totalCount,
        isLoading: false,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
      }
    case FETCH_MORE_BENCHMARKING_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_BENCHMARKING_KEY:
      return {
        ...state,
        isCreating: true,
        lastCreatedKeyId: null,
      }
    case CREATE_BENCHMARKING_KEY_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.newKeyId],
        lastCreatedKeyId: action.newKeyId,
      }
    case CREATE_BENCHMARKING_KEY_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_BENCHMARKING_KEY:
      return {
        ...state,
        isEditing: true,
      }
    case EDIT_BENCHMARKING_KEY_SUCCESS:
      return {
        ...state,
        isEditing: false,
      }
    case EDIT_BENCHMARKING_KEY_FAILURE:
      return {
        ...state,
        isEditing: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_BENCHMARKING_KEYS:
      return { ...state, map: R.mergeDeepRight(state.map, action.keys) }
    default:
      return state
  }
}

export const getBenchmarking = (state: RootState): BenchmarkingState =>
  state.benchmarking
export const getBenchmarkingItemsLoading = (state: RootState) =>
  getBenchmarking(state).isLoading
export const getIsBenchmarkingItemCreating = (state: RootState) =>
  getBenchmarking(state).isCreating
export const getBenchmarkingLastCreatedKeyId = (state: RootState) =>
  getBenchmarking(state).lastCreatedKeyId
export const getIsBenchmarkingItemEditing = (state: RootState) =>
  getBenchmarking(state).isEditing
export const getBenchmarkingItemsList = (state: RootState) =>
  getBenchmarking(state).list
export const getBenchmarkingItemsTotalCount = (state: RootState) =>
  getBenchmarking(state).totalCount
export const getBenchmarkingKeysMap = (state: RootState) =>
  getBenchmarking(state).map
export const getBenchmarkingKey = (id: string | Nil) =>
  createSelector(
    getBenchmarkingKeysMap,
    (map: Record<string, BenchmarkingKey>) => (id ? map[id] : undefined),
  )
export const getMultipleBenchmarkingKeys = (ids: string[]) =>
  createSelector(getBenchmarkingKeysMap, R.props(ids))

export function* fetchBenchmarkingItemsSaga({
  from,
  to,
  sortBy,
  sortingOrder,
  state,
  includeDeactivated,
}: ReturnType<typeof fetchBenchmarkingItems>) {
  try {
    const isDesc = sortingOrder === SortingOrder.DESC
    const {
      result: { data, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchBenchmarkingItems,
      from,
      to,
      sortBy,
      isDesc,
      state,
      includeDeactivated,
    )
    yield put(updateBenchmarkingKeys(entities.benchmarkingKeys))
    yield put(fetchBenchmarkingItemsSuccess(data, totalCount))
  } catch (error) {
    yield put(fetchBenchmarkingItemsFailure(error as ApiError))
  }
}

export function* fetchMoreBenchmarkingItemsSaga({
  from,
  to,
  sortBy,
  sortingOrder,
  state,
  includeDeactivated,
}: ReturnType<typeof fetchMoreBenchmarkingItems>) {
  try {
    const isDesc = sortingOrder === SortingOrder.DESC
    const {
      result: { data, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchBenchmarkingItems,
      from,
      to,
      sortBy,
      isDesc,
      state,
      includeDeactivated,
    )
    yield put(updateBenchmarkingKeys(entities.benchmarkingKeys))
    yield put(fetchMoreBenchmarkingItemsSuccess(data, totalCount, from))
  } catch (error) {
    yield put(fetchMoreBenchmarkingItemsFailure(error as ApiError))
  }
}

export function* createBenchmarkingKeySaga({
  item,
}: ReturnType<typeof createBenchmarkingKey>) {
  try {
    const { entities, result } = yield* requestAPI(
      API.createBenchmarkingKey,
      item,
    )
    yield put(updateBenchmarkingKeys(entities.benchmarkingKeys))
    yield put(createBenchmarkingKeySuccess(result))
  } catch (error) {
    yield put(createBenchmarkingKeyFailure(error as ApiError))
  }
}

export function* editBenchmarkingKeySaga({
  newKey,
}: ReturnType<typeof editBenchmarkingKey>) {
  try {
    const { entities } = yield* requestAPI(API.editBenchmarkingKey, {
      active: newKey.active,
      contactEmail: newKey.contactEmail,
      contactFirstName: newKey.contactFirstName,
      contactLastName: newKey.contactLastName,
      key: newKey.id,
      practiceName: newKey.practiceName,
      status: newKey.status,
    })
    yield put(updateBenchmarkingKeys(entities.benchmarkingKeys))
    yield put(editBenchmarkingKeySuccess())
  } catch (error) {
    yield put(editBenchmarkingKeyFailure(error as ApiError))
  }
}

function* watchFetchBenchmarkingItems() {
  yield takeLeading(FETCH_BENCHMARKING_ITEMS, fetchBenchmarkingItemsSaga)
}

function* watchFetchMoreBenchmarkingItems() {
  yield takeLeading(
    FETCH_MORE_BENCHMARKING_ITEMS,
    fetchMoreBenchmarkingItemsSaga,
  )
}

function* watchCreateBenchmarkingKey() {
  yield takeLeading(CREATE_BENCHMARKING_KEY, createBenchmarkingKeySaga)
}

function* watchEditBenchmarkingKey() {
  yield takeLeading(EDIT_BENCHMARKING_KEY, editBenchmarkingKeySaga)
}

export function* benchmarkingSaga() {
  yield all([
    watchFetchBenchmarkingItems(),
    watchFetchMoreBenchmarkingItems(),
    watchCreateBenchmarkingKey(),
    watchEditBenchmarkingKey(),
  ])
}
