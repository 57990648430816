import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { ImagingDashboardItem, ImagingOrder, TableFilter } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const MESSAGE_ASSIGNMENT_SUCCEED =
  'Orphan imaging order was successfully assigned'

export const FETCH_DASHBOARD_PAGE = 'imagingDashboard/FETCH_DASHBOARD_PAGE'
export const REFRESH_DASHBOARD_PAGE = 'imagingDashboard/REFRESH_DASHBOARD_PAGE'
export const FETCH_DASHBOARD_PAGE_SUCCESS =
  'imagingDashboard/FETCH_DASHBOARD_PAGE_SUCCESS'
export const FETCH_DASHBOARD_PAGE_FAILURE =
  'imagingDashboard/FETCH_DASHBOARD_PAGE_FAILURE'

export const FETCH_DASHBOARD_DETAILS =
  'imagingDashboard/FETCH_DASHBOARD_DETAILS'
export const FETCH_DASHBOARD_DETAILS_SUCCESS =
  'imagingDashboard/FETCH_DASHBOARD_DETAILS_SUCCESS'
export const FETCH_DASHBOARD_DETAILS_FAILURE =
  'imagingDashboard/FETCH_DASHBOARD_DETAILS_FAILURE'

export const ACTUALIZE_DASHBOARD_DETAILS =
  'imagingDashboard/ACTUALIZE_DASHBOARD_DETAILS'

export const FETCH_DASHBOARD_RECORDS =
  'imagingDashboard/FETCH_DASHBOARD_RECORDS'
export const FETCH_DASHBOARD_RECORDS_SUCCESS =
  'imagingDashboard/FETCH_DASHBOARD_RECORDS_SUCCESS'
export const FETCH_DASHBOARD_RECORDS_FAILURE =
  'imagingDashboard/FETCH_DASHBOARD_RECORDS_FAILURE'

export const ASSIGN_ORDER = 'imagingDashboard/ASSIGN_ORDER'
export const ASSIGN_ORDER_SUCCESS = 'imagingDashboard/ASSIGN_ORDER_SUCCESS'
export const ASSIGN_ORDER_FAILURE = 'imagingDashboard/ASSIGN_ORDER_FAILURE'

export const CLEAR_RECORDS = 'imagingDashboard/CLEAR_RECORDS'
export const UPDATE_IMAGING_DASHBOARD_RECORDS =
  'imagingDashboard/UPDATE_RECORDS'
export const SET_DASHBOARD_FILTERS = 'imagingDashboard/SET_DASHBOARD_FILTERS'
export const EDIT_ORDER_WITHIN_DASHBOARD =
  'imagingDashboard/EDIT_ORDER_WITHIN_DASHBOARD'
export const COMPLETE_ORDER_WITHIN_DASHBOARD =
  'imagingDashboard/COMPLETE_ORDER_WITHIN_DASHBOARD'

export const fetchDashboardPage = (
  search: string | Nil,
  from: number,
  to: number,
  filters: Record<string, TableFilter>,
  reset?: boolean,
) => ({ type: FETCH_DASHBOARD_PAGE, search, from, to, filters, reset })
export const refreshDashboardItems = (
  search: string | Nil,
  from: number,
  to: number,
  filters: Record<string, TableFilter>,
) => ({ type: REFRESH_DASHBOARD_PAGE, search, from, to, filters, reset: true })
export const fetchDashboardPageSuccess = (
  list: string[],
  totalCount: number,
  reset?: boolean,
) => ({
  type: FETCH_DASHBOARD_PAGE_SUCCESS,
  list,
  totalCount,
  reset,
})
export const fetchDashboardPageFailure = (error: ApiError) => ({
  type: FETCH_DASHBOARD_PAGE_FAILURE,
  error,
})

export const fetchDashboardDetails = (itemId: string) => ({
  type: FETCH_DASHBOARD_DETAILS,
  itemId,
})
export const fetchDashboardDetailsSuccess = (itemId: string) => ({
  type: FETCH_DASHBOARD_DETAILS_SUCCESS,
  itemId,
})
export const fetchDashboardDetailsFailure = (error: ApiError) => ({
  type: FETCH_DASHBOARD_DETAILS_FAILURE,
  error,
})

export const actualizeDashboardDetails = (updatedOrder: ImagingOrder) => ({
  type: ACTUALIZE_DASHBOARD_DETAILS,
  updatedOrder,
})

export const fetchDashboardRecords = (recordIds: string[]) => ({
  type: FETCH_DASHBOARD_RECORDS,
  recordIds,
})
export const fetchDashboardRecordsSuccess = () => ({
  type: FETCH_DASHBOARD_RECORDS_SUCCESS,
})
export const fetchDashboardRecordsFailure = (error: ApiError) => ({
  type: FETCH_DASHBOARD_RECORDS_FAILURE,
  error,
})

type AssignOrderProps = {
  eventId: string | Nil
  orderId: string
  selectedClientId?: string
  selectedPatientId?: string
  vendorId: string
  vetId: string
}

export const assignOrder = ({
  eventId,
  orderId,
  vendorId,
  vetId,
  selectedClientId,
  selectedPatientId,
}: AssignOrderProps) => ({
  type: ASSIGN_ORDER,
  eventId,
  orderId,
  vendorId,
  vetId,
  selectedClientId,
  selectedPatientId,
})
export const assignOrderSuccess = () => ({ type: ASSIGN_ORDER_SUCCESS })
export const assignOrderFailure = (error: ApiError) => ({
  type: ASSIGN_ORDER_FAILURE,
  error,
})

export const clearRecords = () => ({ type: CLEAR_RECORDS })
export const updateImagingDashboardRecords = (
  orders: Record<string, ImagingDashboardItem>,
) => ({
  type: UPDATE_IMAGING_DASHBOARD_RECORDS,
  orders,
})
export const setFilters = (filters: Record<string, TableFilter>) => ({
  type: SET_DASHBOARD_FILTERS,
  filters,
})
export const editOrderWithinDashboard = (
  order: ImagingOrder,
  dashboardRecordId: string,
) => ({
  type: EDIT_ORDER_WITHIN_DASHBOARD,
  order,
  dashboardRecordId,
})
export const completeOrderWithinDashboard = (
  orderId: string,
  dashboardRecordId: string,
) => ({
  type: COMPLETE_ORDER_WITHIN_DASHBOARD,
  orderId,
  dashboardRecordId,
})

export type ImagingDashboardState = {
  error: string | null
  filters: Record<string, TableFilter>
  isAssigningOrder: boolean
  isFetchingDetails: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, ImagingDashboardItem>
  totalCount: number
}

export const INITIAL_STATE: ImagingDashboardState = {
  map: {},
  list: [],
  isLoading: false,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: {},
  isFetchingDetails: false,
  isAssigningOrder: false,
  error: null,
}

export const imagingDashboardReducer = (
  state: ImagingDashboardState = INITIAL_STATE,
  action: AnyAction,
): ImagingDashboardState => {
  switch (action.type) {
    case ASSIGN_ORDER:
      return {
        ...state,
        isAssigningOrder: true,
      }
    case ASSIGN_ORDER_SUCCESS:
      return {
        ...state,
        isAssigningOrder: false,
      }
    case ASSIGN_ORDER_FAILURE:
      return {
        ...state,
        isAssigningOrder: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_DASHBOARD_PAGE:
    case REFRESH_DASHBOARD_PAGE:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_DASHBOARD_PAGE_SUCCESS:
      return {
        ...state,
        list: action.reset
          ? action.list
          : R.uniq([...state.list, ...action.list]),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_DASHBOARD_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_DASHBOARD_DETAILS:
      return {
        ...state,
        isFetchingDetails: true,
      }
    case FETCH_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
      }
    case FETCH_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        error: getErrorMessage(action.error),
      }
    case ACTUALIZE_DASHBOARD_DETAILS:
      return {
        ...state,
        map: R.map(
          (item: ImagingDashboardItem) => ({
            ...item,
            order:
              item.order && item.order?.id === action.updatedOrder?.id
                ? action.updatedOrder
                : item.order,
          }),
          state.map,
        ),
      }
    case FETCH_DASHBOARD_RECORDS:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_DASHBOARD_RECORDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case FETCH_DASHBOARD_RECORDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_IMAGING_DASHBOARD_RECORDS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.orders),
      }
    case SET_DASHBOARD_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      }
    }
    case CLEAR_RECORDS: {
      return {
        ...state,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    }
    default:
      return state
  }
}

export const getImagingDashboard = (state: RootState): ImagingDashboardState =>
  state.imagingDashboard
export const getImagingDashboardList = (state: RootState) =>
  getImagingDashboard(state).list
export const getImagingDashboardMap = (state: RootState) =>
  getImagingDashboard(state).map
export const getImagingDashboardRecord = (id: string) =>
  createSelector(getImagingDashboardMap, (map) => R.prop(id, map))
export const getTotalCount = (state: RootState) =>
  getImagingDashboard(state).totalCount
export const getImagingDashboardIsLoading = (state: RootState) =>
  getImagingDashboard(state).isLoading
export const getImagingDashboardFilters = (state: RootState) =>
  getImagingDashboard(state).filters
export const getIsFetchingDetails = (state: RootState) =>
  getImagingDashboard(state).isFetchingDetails
export const getIsAssigningOrder = (state: RootState) =>
  getImagingDashboard(state).isAssigningOrder
