import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Constant, Nil, PhoneUtils, User, Utils } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import i18n from '~/locales/i18n'
import { getContactMethod } from '~/store/reducers/constants'
import { ContactMethodName } from '~/types'

type Method = {
  field: string | Nil
  fieldName: keyof User
  label: string
  order: number
  transport: ConversationTransport
  type: string
}

const getMethodsMap = (
  ContactMethod: Constant[],
  client: User,
): Record<string, Method> => {
  const getId = (name: string) =>
    Utils.findConstantIdByName(name, ContactMethod)

  return {
    [getId(ContactMethodName.CALL_MOBILE_PHONE)]: {
      field: client.mobilePhone,
      label: i18n.t('Common:MOBILE'),
      type: 'phone',
      order: 1,
      fieldName: 'mobilePhone',
      transport: ConversationTransport.LogPhoneCall,
    },
    [getId(ContactMethodName.TEXT_MESSAGE)]: {
      field: client.mobilePhone,
      label: i18n.t('Common:MOBILE'),
      type: 'phone',
      order: 1,
      fieldName: 'mobilePhone',
      transport: ConversationTransport.Sms,
    },
    [getId(ContactMethodName.EMAIL)]: {
      field: client.email,
      label: i18n.t('Common:EMAIL'),
      type: 'email',
      order: 2,
      fieldName: 'email',
      transport: ConversationTransport.Email,
    },
    [getId(ContactMethodName.CALL_HOME_PHONE)]: {
      field: client.homePhone,
      label: i18n.t('Common:HOME'),
      type: 'phone',
      order: 3,
      fieldName: 'homePhone',
      transport: ConversationTransport.LogPhoneCall,
    },
    [getId(ContactMethodName.CALL_WORK_PHONE)]: {
      field: client.workPhone,
      label: i18n.t('Common:WORK'),
      type: 'phone',
      order: 4,
      fieldName: 'workPhone',
      transport: ConversationTransport.LogPhoneCall,
    },
    [getId(ContactMethodName.CALL_OTHER_PHONE)]: {
      field: client.otherPhone,
      label: i18n.t('Common:OTHER'),
      type: 'phone',
      order: 5,
      fieldName: 'otherPhone',
      transport: ConversationTransport.LogPhoneCall,
    },
  }
}

const isPhoneMethod = (item: Method) => item.type === 'phone'

const getMethod = (
  ContactMethod: Constant[],
  client: User,
  phoneOnly = false,
) => {
  const methods = getMethodsMap(ContactMethod, client)

  if (
    client.preferredContactMethodId &&
    methods[client.preferredContactMethodId]?.field &&
    (!phoneOnly || isPhoneMethod(methods[client.preferredContactMethodId]))
  ) {
    return methods[client.preferredContactMethodId]
  }

  const methodList = Object.values(methods)
  const sortedList = R.sortBy(R.prop('order'), methodList)
  const filteredList = phoneOnly ? sortedList.filter(isPhoneMethod) : sortedList

  return filteredList.find(R.prop('field'))
}

const useGetPreferredContactMethod = ({
  client,
  phoneOnly = false,
}: {
  client: User | Nil
  phoneOnly?: boolean
}) => {
  const ContactMethod: Constant[] = useSelector(getContactMethod)

  if (!client) {
    return {}
  }

  const method = getMethod(ContactMethod, client, phoneOnly)

  if (!method) {
    return {}
  }

  return {
    label: method.label,
    value:
      method.field && isPhoneMethod(method)
        ? PhoneUtils.formatPhoneNumber(method?.field)
        : method.field,
    fieldName: method.fieldName,
    transport: method.transport,
  }
}

export default useGetPreferredContactMethod
