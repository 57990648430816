import { User } from '@pbt/pbt-ui-components'
import { Business, Nil } from '@pbt/pbt-ui-components/src/types'

export const setGainsightUser = (
  business: Business | Nil,
  user: User | Nil,
) => {
  if (!window.aptrinsic || !business || !user) {
    return
  }

  window.aptrinsic(
    'identify',
    {
      // User Fields
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    },
    {
      // Account Fields
      id: business.id,
      name: business.name,
    },
  )
}

export const resetGainsightUser = () => {
  if (window.aptrinsic) {
    window.aptrinsic('reset')
  }
}
