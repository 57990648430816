import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { LanguagesConstant, LanguageUtils } from '@pbt/pbt-ui-components'

import i18nPortal from '~/locales/i18n'
import {
  getCurrentUserLanguage,
  updateCurrentUserLanguage,
} from '~/store/duck/userSettings'
import { getCurrentBusinessId, getCurrentUserId } from '~/store/reducers/auth'
import { getLanguages } from '~/store/reducers/constants'

import { useBusinessSupportedLanguages } from './useBusinessSupportedLanguages'
import useEffectExceptOnMount from './useEffectExceptOnMount'

export function useChangeLanguage() {
  const currentUserLanguageCode: string = useSelector(getCurrentUserLanguage)
  const Languages: LanguagesConstant[] = useSelector(getLanguages)
  const businessId = useSelector(getCurrentBusinessId)
  const userId = useSelector(getCurrentUserId) as string
  const dispatch = useDispatch()

  const { CurrentSupportedLanguageCodes, hasCurrentLanguagesSupported } =
    useBusinessSupportedLanguages(businessId)

  const toggleLang = (value?: string) => {
    if (!value) {
      return
    }
    dispatch(updateCurrentUserLanguage(userId, value))
    LanguageUtils.updateLanguage({
      languageValue: value,
      i18n: i18nPortal,
      SupportedLngsValuesList: CurrentSupportedLanguageCodes,
    })
  }

  const currentLanguageDisplayName =
    R.find(R.propEq('code', currentUserLanguageCode), Languages)?.name ||
    currentUserLanguageCode

  useEffectExceptOnMount(() => {
    const isDiffLanguageCode = currentUserLanguageCode !== i18nPortal.language
    const isSupportedLanguageCode = R.includes(
      currentUserLanguageCode,
      CurrentSupportedLanguageCodes,
    )

    if (!hasCurrentLanguagesSupported) {
      return
    }

    if (!isSupportedLanguageCode) {
      toggleLang(R.head(CurrentSupportedLanguageCodes))
      return
    }

    if (isSupportedLanguageCode && isDiffLanguageCode) {
      toggleLang(currentUserLanguageCode)
    }
  }, [CurrentSupportedLanguageCodes, currentUserLanguageCode])

  return {
    i18n: i18nPortal,
    toggleLang,
    currentLanguageDisplayName,
  }
}
