import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import {
  Calendar,
  DateUtils,
  Field,
  Nil,
  PuiTextField,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'

export interface RabiesVaccineDetailsProps {
  fields: {
    rabiesTag: Field
    rabiesTagExpires: Field
  }
  patientId: string | Nil
}

const RabiesVaccineDetails = ({
  fields,
  patientId,
}: RabiesVaccineDetailsProps) => {
  const patient = useSelector(getPatient(patientId))
  const { t } = useTranslation(['Common', 'Soap'])

  const { rabiesTag, rabiesTagExpires } = fields

  const isExpirationDateCalculatedByReminderProtocol = useSelector(
    getFeatureToggle(
      FeatureToggle.VACCINATION_DURATION_OF_IMMUNITY_BASED_ON_REMINDER_PROTOCOL,
    ),
  )
  const dueDateLabel = (fieldHasValue: boolean) =>
    isExpirationDateCalculatedByReminderProtocol && fieldHasValue ? (
      <TextWithTooltip
        tooltipText={t('Tooltips:VACCINE_EXPIRATION_DATE')}
        variant="body"
      >
        {t('Common:RABIES_TAG_EXPIRATION')}
      </TextWithTooltip>
    ) : (
      t('Common:RABIES_TAG_EXPIRATION')
    )

  return (
    <>
      <Grid item xs={6}>
        <PuiTextField
          fullWidth
          field={rabiesTag}
          label={t('Common:RABIES_TAG')}
          margin="none"
        />
        {patient?.rabiesTag && (
          <Text variant="lowAccent3">
            {t('Soap:RABIES_VACCINE_DETAILS.CURRENT_RABIES_TAG', {
              currentTag: patient.rabiesTag,
            })}
          </Text>
        )}
      </Grid>
      <Grid item xs={6}>
        <Calendar
          fullWidth
          label={dueDateLabel(Boolean(rabiesTagExpires.value))}
          margin="none"
          value={rabiesTagExpires.value || null}
          onChange={(newDate: string | Nil) => {
            rabiesTagExpires.setValue(newDate || null)
          }}
        />
        {patient?.rabiesTagExpiration && (
          <Text variant="lowAccent3">
            {t('Soap:RABIES_VACCINE_DETAILS.CURRENT_RABIES_TAG_EXPIRATION', {
              currentTagExpiration: DateUtils.formatDate(
                patient.rabiesTagExpiration,
              ),
            })}
          </Text>
        )}
      </Grid>
    </>
  )
}

export default RabiesVaccineDetails
