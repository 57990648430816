import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getInventorySubCategory } from '~/store/reducers/constants'

export interface InventorySubCategoryCellProps {
  categoryId: string
  subcategoryId: string
}

const InventorySubCategoryCell = (item: InventorySubCategoryCellProps) => {
  const InventorySubCategory = useSelector(getInventorySubCategory)

  return LanguageUtils.getConstantTranslatedName(
    item.subcategoryId,
    InventorySubCategory[item.categoryId],
  )
}

export default InventorySubCategoryCell
