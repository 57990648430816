import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  AppointmentEventType,
  Calendar,
  EventTypeAppointmentResponsibility,
  EventTypeConstant,
  moment,
  PuiCheckbox,
  Responsibility,
  Role,
  RoleName,
  Text,
  useFields,
  useInterval,
  Utils,
} from '@pbt/pbt-ui-components'

import AppointmentRoleItem from '~/components/common/appointments/AppointmentRoleItem'
import AppointmentTypeSelect from '~/components/common/appointments/AppointmentTypeSelect'
import SpaceSelect from '~/components/common/inputs/SpaceSelect'
import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import multiDayAppointmentTypes from '~/constants/multiDayAppointmentTypes'
import secretSearchContext from '~/constants/secretSearchContext'
import { fetchSuggestionResults } from '~/store/actions/search'
import {
  clearAppointmentValidationError,
  fetchAppointment,
  patchAppointment,
} from '~/store/actions/timetable'
import { getHasOpenDialogsByName } from '~/store/duck/dialogs'
import {
  useGetAppointmentResponsibilities,
  useGetAppointmentStaffRoles,
} from '~/store/hooks/timetable'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getAppointmentTypesMap } from '~/store/reducers/appointmentTypes'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getEventType,
  getFeatureToggle,
  getResponsibilities,
} from '~/store/reducers/constants'
import {
  getHasMainStaffRoles,
  getHasStaffRoles,
  getMultipleRoles,
} from '~/store/reducers/roles'
import { getSoapId } from '~/store/reducers/soap'
import {
  getTimetableIsLoading,
  getTimetableValidationError,
} from '~/store/reducers/timetable'
import {
  TimetableEvent,
  WritableTimetableEvent,
  WritableTimetableEventPersonResponsibility,
  WritableTimetableEventPersonRole,
} from '~/types'
import { filterCVCRoles, filterVeterinarian2Role } from '~/utils/roleUtils'
import {
  aggregateStartAndEndDates,
  getAppointmentEndTime,
  getIsMultidayEvent,
} from '~/utils/time'
import useFieldsChanged from '~/utils/useFieldsChanged'

import { BaseTemplateInputHandle } from '../../template-inputs/BaseTemplateInput/BaseTemplateInput'
import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import {
  getOccupyTimeSlotFieldName,
  getOccupyTimeSlotFieldValue,
  getRoleFieldName,
  useGetOccupyTimeSlotFields,
  useGetSchedulerSettingsByBusiness,
} from '../../timetable/scheduler/appointment/appointmentUtils'
import AppointmentStatusSelect from './AppointmentStatusSelect'
import Conferencing from './Conferencing'

const REFRESH_TIMEOUT = 30000

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tableBorder,
      minWidth: 145,
    },
    scheduledDate: {
      [theme.breakpoints.down('xl')]: {
        maxWidth: 150,
      },
    },
    progressContainer: {
      height: 243,
    },
    textArea: {
      paddingLeft: theme.spacing(0.5),
      scrollbarWidth: 'auto',
      '&::-webkit-scrollbar': {
        display: 'auto',
      },
    },
  }),
  { name: 'Appointment' },
)

export interface AppointmentHandle {
  discardChanges: () => void
}

export interface AppointmentProps {
  appointment: TimetableEvent
  editDisabled?: boolean
}

const Appointment = forwardRef<AppointmentHandle, AppointmentProps>(
  function Appointment({ appointment, editDisabled }, ref) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation(['Common', 'Soap', 'Time'])

    const soapId = useSelector(getSoapId)
    const EventType: EventTypeConstant = useSelector(getEventType)
    const timetableIsLoading = useSelector(getTimetableIsLoading)
    const isRemoteUsersEnabled = useSelector(
      getFeatureToggle(FeatureToggle.REMOTE_MEMBERS),
    )
    const isCvcRolesEnabled = useSelector(
      getFeatureToggle(FeatureToggle.CVC_ROLES),
    )
    // For backwards-compatibility, we should only use responsibilities
    // for newly created appointments or ones that already have personResponsibilities
    const shouldUseResponsibilities =
      isCvcRolesEnabled &&
      (appointment?.personResponsibilities || !appointment?.personRoles)
    const mainStaffRolesList = useMainStaffRoles()
    const responsibilitiesList: Responsibility[] =
      useSelector(getResponsibilities)
    const doctorRoles: string[] = Utils.findConstantIdsByName(
      RoleName.Veterinarian,
      mainStaffRolesList,
    )
    const techRoles: string[] = Utils.findConstantIdsByName(
      RoleName.VetTech,
      mainStaffRolesList,
    )
    const hasRoles = useSelector(
      isRemoteUsersEnabled ? getHasMainStaffRoles : getHasStaffRoles,
    )
    const validationError = useSelector(getTimetableValidationError)
    const currentBusiness = useSelector(getCurrentBusiness)
    const isInvoiceDialogOpen = useSelector(
      getHasOpenDialogsByName(DialogNames.INVOICE),
    )
    const appointmentTypes = useSelector(getAppointmentTypesMap)

    const [multiDay, setMultiDay] = useState(getIsMultidayEvent(appointment))
    const [appointmentTypeIsTouched, setAppointmentTypeIsTouched] =
      useState(false)
    const [hasFocusedField, setHasFocusedField] = useState(false)

    const rootRef = useRef<HTMLDivElement>(null)
    const notesRef = useRef<BaseTemplateInputHandle>(null)

    const AppointmentEvent: AppointmentEventType = Utils.findConstantByName(
      'Appointment',
      EventType,
    )
    const AppointmentEventSubTypes = AppointmentEvent?.subTypes || []
    const occupyTimeSlotFields = useGetOccupyTimeSlotFields({
      roles: shouldUseResponsibilities
        ? responsibilitiesList
        : mainStaffRolesList,
      personRoles: appointment?.personRoles,
      personResponsibilities: appointment?.personResponsibilities,
      isEdit: Boolean(appointment.id),
    })

    const {
      fields,
      reset: resetFields,
      validate,
    } = useFields(
      [
        {
          name: 'state',
          label: t('Common:STATE'),
          validators: ['required'],
          initialValue: appointment?.state?.id || '',
        },
        {
          name: 'startDate',
          label: multiDay ? t('Common:SCHEDULED_START') : t('Common:SCHEDULED'),
          validators: ['required'],
          initialValue: appointment?.scheduledStartDatetime || '',
        },
        {
          name: 'endDate',
          label: t('Common:SCHEDULED_END'),
          validators: ['required'],
          initialValue: appointment?.scheduledEndDatetime || '',
        },
        {
          name: 'startTime',
          type: 'none',
          initialValue: appointment?.scheduledStartDatetime || '',
        },
        {
          name: 'endTime',
          label: t('Time:TIME_SELECTOR.END_TIME'),
          type: 'none',
          initialValue: appointment?.scheduledEndDatetime || '',
        },
        {
          name: 'type',
          label: t('Common:APPOINTMENT_TYPE'),
          validators: ['required'],
          initialValue: appointment?.businessAppointmentType?.id,
        },
        {
          name: 'spaceId',
          initialValue: appointment?.assignedSpace,
        },
        {
          name: 'clientInstructions',
          label: t('Soap:APPOINTMENT.NOTES_TEMPLATE_INPUT_TITLE'),
          initialValue: appointment?.clientInstructions || '',
        },
      ],
      false,
    )

    const findInitialPersonId = (
      roleOrResponsibility: Role | EventTypeAppointmentResponsibility,
    ) =>
      shouldUseResponsibilities
        ? appointment?.personResponsibilities?.find(
            (personResponsibility) =>
              personResponsibility.responsibilityId === roleOrResponsibility.id,
          )?.personId
        : appointment?.personRoles?.find(
            (personRole) => personRole.roleId === roleOrResponsibility.id,
          )?.personId

    const { fields: rolesFields, reset: resetRoleFields } = useFields(
      [
        ...(shouldUseResponsibilities
          ? responsibilitiesList.map(
              (responsibility: EventTypeAppointmentResponsibility) => ({
                name: getRoleFieldName(responsibility.id),
                initialValue: findInitialPersonId(responsibility),
              }),
            )
          : mainStaffRolesList.map((role) => ({
              name: getRoleFieldName(role.id),
              initialValue: findInitialPersonId(role),
            }))),
        ...occupyTimeSlotFields,
      ],
      false,
    )

    const discardChanges = () => {
      resetFields()
      resetRoleFields()
    }

    useImperativeHandle(ref, () => ({
      discardChanges,
    }))

    const {
      state,
      endDate,
      endTime,
      startDate,
      startTime,
      type,
      spaceId,
      clientInstructions,
    } = fields

    const currentRoles = useGetAppointmentStaffRoles(type.value)
    const apptResponsibilities = useGetAppointmentResponsibilities(type.value)
    const hasResponsibilities = apptResponsibilities.length > 0
    const allRoleIds = apptResponsibilities
      .flatMap(({ roles: responsibilityRoles }) => responsibilityRoles)
      .map(({ id }) => id)
    const allAcceptableRoles = useSelector(getMultipleRoles(allRoleIds))
    const schedulerSettings = useGetSchedulerSettingsByBusiness(
      currentBusiness?.id,
    )

    useEffect(
      () => () => {
        if (validationError) {
          dispatch(clearAppointmentValidationError())
        }
      },
      [],
    )

    useDeepCompareEffect(() => {
      if (!hasFocusedField) {
        resetFields()
        notesRef.current?.resetState()
      }
    }, [appointment])

    useInterval(() => {
      if (
        !timetableIsLoading &&
        !validationError &&
        !hasFocusedField &&
        // prevent refreshing appointment if invoice/estimate dialog is open
        !isInvoiceDialogOpen
      ) {
        dispatch(fetchAppointment(appointment.id))
      }
    }, REFRESH_TIMEOUT)

    const doctorsForms = currentRoles
      .filter(filterCVCRoles)
      .filter(filterVeterinarian2Role)
      .map((role, index) => {
        const roleField = rolesFields[getRoleFieldName(role.id)]
        const occupyTimeSlotField =
          rolesFields[getOccupyTimeSlotFieldName(role.id)]

        return roleField ? (
          <Grid item key={`${role.id}_${role.name}`} md={4}>
            <AppointmentRoleItem
              isEdit
              appointmentTypeId={type.value}
              hasAppointmentTypeChanged={type.initialValue !== type.value}
              isFirstRole={index === 0}
              key={role.id}
              occupyTimeSlotField={occupyTimeSlotField}
              role={role}
              roleField={roleField}
              schedulerSettings={schedulerSettings}
            />
          </Grid>
        ) : null
      })
      .filter(Boolean)

    const doctorsFormsByResponsibility = apptResponsibilities
      .map((responsibility, index) => {
        const responsibilityField =
          rolesFields[getRoleFieldName(responsibility.id)]
        const occupyTimeSlotField =
          rolesFields[getOccupyTimeSlotFieldName(responsibility.id)]

        return responsibilityField ? (
          <Grid item key={`${responsibility.id}_${responsibility.name}`} md={4}>
            <AppointmentRoleItem
              isEdit
              appointmentTypeId={type.value}
              hasAppointmentTypeChanged={type.initialValue !== type.value}
              isFirstRole={index === 0}
              key={responsibility.id}
              occupyTimeSlotField={occupyTimeSlotField}
              role={responsibility}
              roleField={responsibilityField}
              schedulerSettings={schedulerSettings}
            />
          </Grid>
        ) : null
      })
      .filter(Boolean)

    useFieldsChanged((changedFields) => {
      if (!validate()) {
        return
      }
      const data = changedFields.reduce((diff, { name, value }) => {
        if (['startDate', 'startTime', 'endDate', 'endTime'].includes(name)) {
          const [scheduledStartDatetime, scheduledEndDatetime] =
            aggregateStartAndEndDates({
              startDate: moment(startDate.value),
              endDate: moment(endDate.value),
              startTime: moment(startTime.value),
              endTime: moment(endTime.value),
              ignoreSeconds: true,
              isMultiday: multiDay,
            })
          diff.scheduledStartDatetime = scheduledStartDatetime
          diff.scheduledEndDatetime = scheduledEndDatetime
        } else if (name === 'state') {
          if (value !== appointment?.state?.id) {
            diff[name] = value
          }
        } else if (name === 'type') {
          diff.type = appointmentTypes[value]?.eventTypeId

          diff.businessAppointmentType = { id: value }
        } else {
          // @ts-ignore
          diff[name] = value
        }
        return diff
      }, {} as WritableTimetableEvent)
      if (Object.entries(data).length > 0) {
        dispatch(
          patchAppointment(
            {
              // @ts-ignore
              id: appointment.id,
              ...data,
            },
            undefined,
            true,
          ),
        )
      }
    }, fields)

    useFieldsChanged(() => {
      const isUsingResponsibilities = Boolean(
        appointment.personResponsibilities,
      )
      const personRoles = !isUsingResponsibilities
        ? (currentRoles
            .filter(filterCVCRoles)
            .filter(filterVeterinarian2Role)
            .map(({ id }) => {
              const { value: personValue } = rolesFields[getRoleFieldName(id)]
              return personValue
                ? {
                    role: id,
                    person: personValue as string,
                    occupyTimeslot: getOccupyTimeSlotFieldValue(
                      rolesFields,
                      id,
                    ),
                  }
                : undefined
            })
            .filter(Boolean) as WritableTimetableEventPersonRole[])
        : undefined

      const personResponsibilities = isUsingResponsibilities
        ? (apptResponsibilities
            .map(({ id }) => {
              const { value: personValue } = rolesFields[getRoleFieldName(id)]
              return personValue
                ? {
                    responsibility: id,
                    person: personValue as string,
                    occupyTimeslot: getOccupyTimeSlotFieldValue(
                      rolesFields,
                      id,
                    ),
                  }
                : undefined
            })
            .filter(Boolean) as WritableTimetableEventPersonResponsibility[])
        : undefined

      dispatch(
        patchAppointment(
          {
            id: appointment.id,
            personRoles,
            personResponsibilities,
          },
          undefined,
          true,
        ),
      )
    }, rolesFields)

    useEffect(() => {
      if (soapId && hasRoles && !shouldUseResponsibilities) {
        if (
          appointment?.personRoles &&
          !validationError &&
          !timetableIsLoading
        ) {
          appointment.personRoles.forEach(({ roleId, personId }) => {
            rolesFields[getRoleFieldName(roleId)]?.setValue(personId)
          })
        }
      }
    }, [soapId, appointment, hasRoles, validationError, timetableIsLoading])

    useEffect(() => {
      if (soapId && hasResponsibilities && shouldUseResponsibilities) {
        if (
          appointment?.personResponsibilities &&
          !validationError &&
          !timetableIsLoading
        ) {
          appointment.personResponsibilities.forEach(
            ({ responsibilityId, personId }) => {
              rolesFields[getRoleFieldName(responsibilityId)]?.setValue(
                personId,
              )
            },
          )
        }
      }
    }, [
      soapId,
      appointment,
      hasResponsibilities,
      validationError,
      timetableIsLoading,
    ])

    const resetAppointmentEndTime = (typeId: string) => {
      const configuration =
        currentBusiness?.appointmentConfigurationByTypeId?.[typeId]

      if (configuration) {
        const duration = moment
          .duration(configuration.defaultDuration)
          .asMinutes()
        endTime.setValue(
          getAppointmentEndTime(moment(startTime.value), duration),
        )
      }
    }

    const currentAppointmentType = Utils.findById(
      type.value,
      AppointmentEventSubTypes,
    )

    useEffect(() => {
      const rawRoles = shouldUseResponsibilities
        ? allAcceptableRoles
        : currentRoles.filter(filterCVCRoles)
      const newRoles = rawRoles
        .map(({ id }) => id)
        .filter(
          (roleId: string) =>
            [...doctorRoles, ...techRoles].indexOf(roleId) === -1,
        )
        .join(',')

      if (newRoles) {
        dispatch(
          fetchSuggestionResults({
            searchContext: secretSearchContext.PERSONS,
            searchTerm: '',
            roleIds: newRoles,
          }),
        )
      }
      if (
        type.value &&
        multiDayAppointmentTypes.includes(currentAppointmentType?.name) &&
        appointmentTypeIsTouched
      ) {
        setMultiDay(true)
      }
    }, [type.value])

    const onChangeAppointmentType = (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      if (!appointmentTypeIsTouched) {
        setAppointmentTypeIsTouched(true)
      }

      const newAppointmentType = event.target.value
      type.setValue(newAppointmentType)
      endDate.setValue(startDate.value)
      resetAppointmentEndTime(newAppointmentType)
    }

    const onChangeMultiDay = () => {
      if (multiDay) {
        endDate.setValue(startDate.value)
        resetAppointmentEndTime(type.value)
      }
      setMultiDay(!multiDay)
    }

    return (
      <Grid
        container
        item
        className={classes.root}
        md={12}
        ref={rootRef}
        wrap="nowrap"
      >
        <Grid item className={classes.titleContainer} p={2} xs={2}>
          <Text mb={2} variant="h4">
            {t('Common:APPOINTMENT_ONE')}
          </Text>
          <AppointmentStatusSelect
            disabled={editDisabled}
            field={state}
            label={t('Common:APPOINTMENT_STATUS')}
          />
          <ItemHistoryLabel vertical item={appointment} />
        </Grid>
        <Grid container item xs direction="column" p={2} wrap="nowrap">
          <Grid container item alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth error={!type.valid} margin="normal">
                <InputLabel htmlFor="appointment-type-select">
                  {type.label}*
                </InputLabel>
                <AppointmentTypeSelect
                  disabled={editDisabled || false}
                  field={{ ...type, set: onChangeAppointmentType }}
                />
              </FormControl>
            </Grid>
            <Grid container item className={classes.scheduledDate} xs={3}>
              <Calendar
                fullWidth
                disabled={editDisabled}
                field={startDate}
                label={`${startDate.label}*`}
              />
            </Grid>
            <Grid item>
              <TimeSelector
                disabled={editDisabled}
                endValue={!multiDay && endTime.value}
                range={!multiDay}
                startValue={startTime.value}
                onEndChange={multiDay ? undefined : endTime.set}
                onStartChange={startTime.set}
              />
            </Grid>
            <Grid item>
              <PuiCheckbox
                checked={multiDay}
                disabled={editDisabled}
                label={t('Time:LABEL.MULTI-DAY')}
                onChange={onChangeMultiDay}
              />
            </Grid>
            {multiDay && (
              <Grid container item spacing={2}>
                <Grid item xs={4} />
                <Grid item className={classes.scheduledDate} xs={3}>
                  <Calendar
                    fullWidth
                    disabled={editDisabled}
                    field={endDate}
                    label={`${endDate.label}*`}
                    minDate={startDate.value}
                  />
                </Grid>
                <Grid item>
                  <TimeSelector
                    disabled={editDisabled}
                    label={`${endTime.label}*`}
                    startValue={endTime.value}
                    onStartChange={endTime.set}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column" py={1}>
              <Grid container>
                <Grid container item alignItems="flex-end" columnSpacing={2}>
                  {shouldUseResponsibilities
                    ? doctorsFormsByResponsibility
                    : doctorsForms}
                  <Grid item xs>
                    <SpaceSelect
                      appointmentTypeId={appointment.type?.id}
                      disabled={editDisabled}
                      field={spaceId}
                      margin="normal"
                      patientId={appointment?.patient}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {appointment?.client && appointment?.patient && (
                <Grid container item mt={2} xs={12}>
                  <NotesTemplateInput
                    singleLine
                    clientId={appointment.client}
                    disabled={editDisabled}
                    field={clientInstructions}
                    minHeight={30}
                    patientId={appointment.patient}
                    ref={notesRef}
                    title={clientInstructions.label}
                    onBlur={() => setHasFocusedField(false)}
                    onFocus={() => setHasFocusedField(true)}
                  />
                </Grid>
              )}
              <Conferencing
                appointment={appointment}
                editDisabled={editDisabled}
                showUserSelect={!appointment?.id}
                onFocusedFieldChange={setHasFocusedField}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default Appointment
