import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Constant, Defaults, Nil, Question } from '@pbt/pbt-ui-components'

import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CLEAR_QUESTION_VALIDATION_ERROR,
  CREATE_QUESTION,
  CREATE_QUESTION_FAILURE,
  CREATE_QUESTION_SUCCESS,
  DELETE_QUESTION,
  DELETE_QUESTION_FAILURE,
  DELETE_QUESTION_SUCCESS,
  EDIT_QUESTION,
  EDIT_QUESTION_FAILURE,
  EDIT_QUESTION_SUCCESS,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_SUCCESS,
  FETCH_QUESTION,
  FETCH_QUESTION_FAILURE,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTIONS_FOR_SOAP,
  FETCH_QUESTIONS_FOR_SOAP_FAILURE,
  FETCH_QUESTIONS_FOR_SOAP_SUCCESS,
  FETCH_QUESTIONS_LIST,
  FETCH_QUESTIONS_LIST_FAILURE,
  FETCH_QUESTIONS_LIST_SUCCESS,
  QUESTION_VALIDATION_ERROR,
  SAVE_QUESTIONS_FOR_SOAP,
  SAVE_QUESTIONS_FOR_SOAP_FAILURE,
  SAVE_QUESTIONS_FOR_SOAP_SUCCESS,
  UPDATE_QUESTIONS,
} from '../actions/types/questions'
import type { RootState } from '../index'
import { getWorkFlowTypes } from './constants'
import { getSoapId } from './soap'

export type QuestionsState = {
  error: string | null
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  lastCreatedQuestionId: string | null
  list: string[]
  map: Record<string, Question>
  soapQuestions: Record<string, string[]>
  totalCount: number
  validationError: string | null
}

export const INITIAL_STATE: QuestionsState = {
  list: [],
  map: {},
  isLoading: false,
  isFetching: false,
  isFetchingList: false,
  isDeleting: false,
  error: null,
  validationError: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  soapQuestions: {},
  lastCreatedQuestionId: null,
}

const questions = (
  state: QuestionsState = INITIAL_STATE,
  action: AnyAction,
): QuestionsState => {
  switch (action.type) {
    case FETCH_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_QUESTIONS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_QUESTIONS:
      return {
        ...state,
        map: R.mergeDeepRight(state.map, action.questions),
        error: null,
      }
    case FETCH_QUESTION:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_QUESTION_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_QUESTION:
    case EDIT_QUESTION:
    case SAVE_QUESTIONS_FOR_SOAP:
      return { ...state, isLoading: true }
    case EDIT_QUESTION_SUCCESS:
      return { ...state, isLoading: false }
    case CREATE_QUESTION_FAILURE:
    case EDIT_QUESTION_FAILURE:
    case SAVE_QUESTIONS_FOR_SOAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_QUESTIONS_FOR_SOAP:
      return { ...state, isFetching: true }
    case FETCH_QUESTIONS_FOR_SOAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.omit([action.questionId], state.map),
        list: R.without([action.questionId], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
        soapQuestions: Object.keys(state.soapQuestions).reduce(
          (acc, key) => ({
            ...acc,
            [key]: R.without([action.questionId], state.soapQuestions[key]),
          }),
          {},
        ),
      }
    case FETCH_QUESTIONS_FOR_SOAP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        soapQuestions: {
          ...state.soapQuestions,
          [action.soapId]: action.list,
        },
      }
    case SAVE_QUESTIONS_FOR_SOAP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        soapQuestions: {
          ...state.soapQuestions,
          [action.soapId]: action.list,
        },
      }
    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lastCreatedQuestionId: action.questionId,
      }
    case DELETE_QUESTION:
      return { ...state, isDeleting: true }
    case DELETE_QUESTION_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case QUESTION_VALIDATION_ERROR:
      return {
        ...state,
        isLoading: false,
        validationError: getErrorMessage(action.error),
      }
    case CLEAR_QUESTION_VALIDATION_ERROR:
      return { ...state, validationError: null }
    default:
      return state
  }
}

export default questions
export const getQuestions = (state: RootState): QuestionsState =>
  state.questions
export const getQuestionsList = (state: RootState) => getQuestions(state).list
export const getQuestionsMap = (state: RootState) => getQuestions(state).map
export const getQuestion = (id: string | Nil) =>
  createSelector(getQuestionsMap, (map: Record<string, Question>) =>
    id ? map[id] : undefined,
  )
export const getMultipleQuestions = (ids: string[]) =>
  createSelector(getQuestionsMap, R.props(ids))
export const getQuestionsIsLoading = (state: RootState) =>
  getQuestions(state).isLoading
export const getQuestionsIsDeleting = (state: RootState) =>
  getQuestions(state).isDeleting
export const getQuestionsIsFetching = (state: RootState) =>
  getQuestions(state).isFetching
export const getQuestionsIsFetchingList = (state: RootState) =>
  getQuestions(state).isFetchingList
export const getQuestionsTotalCount = (state: RootState) =>
  getQuestions(state).totalCount
export const getLastCreatedQuestionId = (state: RootState) =>
  getQuestions(state).lastCreatedQuestionId
export const getSoapQuestions = (state: RootState) =>
  getQuestions(state).soapQuestions
export const getQuestionsValidationError = (state: RootState) =>
  getQuestions(state).validationError
export const getSoapQuestionsBySoapId = createSelector(
  [getSoapQuestions, getQuestionsMap, getSoapId],
  (soapQuestions, questionsMap, soapId) =>
    (soapId ? soapQuestions[soapId] || [] : [])
      .map((questionId) => questionsMap[questionId])
      .filter(Boolean),
)

export const getSoapQuestionsWithWorkFlow = createSelector(
  [getWorkFlowTypes, getSoapQuestionsBySoapId],
  (WorkFlowTypes: Constant[], soapQuestionsBySoapId: Question[]) =>
    WorkFlowTypes.reduce(
      (acc, value) => ({
        ...acc,
        [value.id]: soapQuestionsBySoapId.filter(
          (question) => question.workflowIds.indexOf(value.id) > -1,
        ),
      }),
      {} as Record<string, Question[]>,
    ),
)
