import React from 'react'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import YesNoSelect from '~/components/common/inputs/YesNoSelect'
import {
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceUpdateFieldCallback,
} from '~/types'

import { formatMoneyRange, getTaxAmount } from '../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    taxSelectCell: {
      borderRight: 'none !important',
      width: 84,
    },
    amountCell: {
      minWidth: 85,
    },
  }),
  { name: 'TaxCellBody' },
)

export interface TaxCellBodyProps {
  className?: string
  group: InvoiceLineItemGroup
  invoice: Invoice
  isDeclined: boolean
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  updateField: InvoiceUpdateFieldCallback
}

const TaxCellBody = ({
  className,
  invoice,
  item,
  isDeclined,
  isGrouped,
  isReadOnly,
  updateField,
  group,
}: TaxCellBodyProps) => {
  const classes = useStyles()

  const taxAmount = getTaxAmount(item, invoice)

  return (
    <>
      <TableCell className={classNames(className, classes.taxSelectCell)}>
        {!isDeclined && !isGrouped && (
          <YesNoSelect
            disableUnderline
            disabled={isReadOnly || !item.priceTaxable}
            value={item.taxed}
            onChange={(event) => updateField(event, 'taxed', group, item, true)}
          />
        )}
      </TableCell>
      <TableCell
        align="right"
        className={classNames(className, classes.amountCell)}
      >
        {!isDeclined && formatMoneyRange(taxAmount)}
      </TableCell>
    </>
  )
}

export default TaxCellBody
