import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import LetUsKnowLink from '~/components/common/LetUsKnowLink'
import FeatureToggle from '~/constants/featureToggle'
import { fetchGlobalItem } from '~/store/actions/globalInventoryCatalog'
import { createVariations } from '~/store/actions/variations'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getGlobalInventoryItem,
  getIsItemLoading,
} from '~/store/reducers/globalInventoryCatalog'
import { getVariationIsSending } from '~/store/reducers/variations'
import { InventoryItem, Variation as VariationType } from '~/types'

import Variation from '../catalog/Variation'
import {
  addOrUpdateVariation,
  getNextVariationKey,
  getPrevVariationKey,
  getVariation,
  getVariationIndex,
  getVariationKey,
  getVariationsCount,
  insertVariation,
  toggleSelectedVariationsState,
  // @ts-ignore
} from '../inventoryUtils'
import VariationListItem from './VariationListItem'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      minHeight: 200,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 32px) !important',
        margin: '16px auto !important',
      },
    },
    nextButton: {
      height: 40,
      width: 150,
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'GlobalVariationSelectDialog' },
)

interface GlobalVariationSelectDialogProps extends BasePuiDialogProps {
  item: InventoryItem | Nil
}

const GlobalVariationSelectDialog = ({
  item,
  onClose,
  ...rest
}: GlobalVariationSelectDialogProps) => {
  const classes = useStyles(rest)
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getIsItemLoading)
  const isVariationsSending = useSelector(getVariationIsSending)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const globalItemId =
    item?.[
      isFoodCatalogEnabled
        ? 'globalInventoryItemMappingId'
        : 'globalInventoryItemId'
    ]
  const globalItem = useSelector(getGlobalInventoryItem(globalItemId))

  const [selectedVariations, setSelectedVariations] = useState<
    Record<string, VariationType>
  >({})
  const [variationsSelectConfirmed, setVariationsSelectConfirmed] =
    useState(false)
  const [variationsToCreate, setVariationsToCreate] = useState({})
  const [currentVariationKey, setCurrentVariationKey] = useState()
  const [closeAfterCreation, setCloseAfterCreation] = useState(false)

  const onAddClick = useCallback(() => {
    setCurrentVariationKey(getVariationKey(Object.keys(selectedVariations)[0]))
    setVariationsSelectConfirmed(true)
  }, [selectedVariations])

  const onVariationFilled = (
    variation: VariationType,
    isClone?: boolean | Nil,
  ) => {
    const newVariationsToCreate: Record<string, VariationType> =
      addOrUpdateVariation(currentVariationKey, variation, variationsToCreate)
    const nextSelectedVariations: Record<string, VariationType> = isClone
      ? insertVariation(
          { ...getVariation(currentVariationKey, selectedVariations) },
          selectedVariations,
          isFoodCatalogEnabled,
        )
      : selectedVariations

    if (isClone) {
      setSelectedVariations(nextSelectedVariations)
    }

    const nextVariationKey = getNextVariationKey(
      currentVariationKey,
      nextSelectedVariations,
    )

    if (nextVariationKey) {
      setVariationsToCreate(newVariationsToCreate)
      setCurrentVariationKey(nextVariationKey)
    } else {
      setCloseAfterCreation(true)
      if (item?.id) {
        dispatch(
          createVariations(
            item.id,
            R.flatten(Object.values(newVariationsToCreate)),
          ),
        )
      }
    }
  }

  const onGoBack = () => {
    const prevVariationKey = getPrevVariationKey(
      currentVariationKey,
      selectedVariations,
    )
    setCurrentVariationKey(prevVariationKey)
  }

  useEffect(() => {
    if (closeAfterCreation && !isVariationsSending) {
      if (onClose) {
        onClose()
      }
    }
  }, [closeAfterCreation, isVariationsSending])

  useEffect(() => {
    if (globalItemId) {
      dispatch(fetchGlobalItem(globalItemId))
    }
  }, [globalItemId])

  const showToolTip =
    globalItem &&
    (globalItem.variations || []).some(
      ({ businessVariationIds }) =>
        businessVariationIds && businessVariationIds.length,
    )

  const currentVariation =
    getVariation(currentVariationKey, variationsToCreate) ||
    getVariation(currentVariationKey, selectedVariations)

  return (
    <PuiDialog
      actions={
        !variationsSelectConfirmed && (
          <>
            <Fab
              className={classes.nextButton}
              color="inherit"
              disabled={isLoading || R.isEmpty(selectedVariations)}
              type="button"
              variant="extended"
              onClick={onAddClick}
            >
              {t('Common:NEXT')}
            </Fab>
            <LetUsKnowLink />
          </>
        )
      }
      aria-labelledby="item-variations-select-dialog"
      classes={{
        paper: classes.paper,
      }}
      title={
        !variationsSelectConfirmed &&
        t('Common:ADD_VARIATION_FOR_NAME', { name: item?.name })
      }
      onClose={onClose}
      {...rest}
    >
      {variationsSelectConfirmed ? (
        <Variation
          inventory={item}
          nextVariation={getVariation(
            getNextVariationKey(currentVariationKey, selectedVariations),
            selectedVariations,
          )}
          variation={currentVariation}
          variationIndex={getVariationIndex(
            currentVariationKey,
            selectedVariations,
          )}
          variationsCount={getVariationsCount(selectedVariations)}
          onBack={onGoBack}
          onVariationFilled={onVariationFilled}
        />
      ) : (
        <Grid container direction="column" p={2}>
          {isLoading ? (
            <Grid container justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <Grid container>
              <Grid item pl={1.25}>
                <TextWithTooltip
                  strong
                  tooltipText={
                    showToolTip
                      ? t('Tooltips:ITEM_ALREADY_HAVE_VARIATION', {
                          itemName: item?.name,
                        })
                      : undefined
                  }
                  variant="body2"
                >
                  {t('Common:SELECT_VARIATIONS')}
                </TextWithTooltip>
              </Grid>
              {((globalItem && globalItem.variations) || []).map(
                (variation) => (
                  <VariationListItem
                    isSelected={Boolean(selectedVariations[variation.id])}
                    key={variation.id}
                    variation={variation}
                    onClick={(variationName) => {
                      setSelectedVariations(
                        toggleSelectedVariationsState(
                          selectedVariations,
                          variation,
                          variationName,
                          isFoodCatalogEnabled,
                        ),
                      )
                    }}
                  />
                ),
              )}
            </Grid>
          )}
        </Grid>
      )}
    </PuiDialog>
  )
}

export default GlobalVariationSelectDialog
