import React from 'react'
import { Grid, GridProps } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

export interface NoRecordsProps extends GridProps {
  action: string
  onClick: (() => void) | undefined
  text: string
}

const NoRecords = ({ text, action, onClick, ...rest }: NoRecordsProps) => (
  <Grid container p={1} {...rest}>
    <Text mr={1} variant="lowAccent">
      {text}
    </Text>
    {action && onClick && (
      <Text link mr={1} onClick={onClick}>
        {action}
      </Text>
    )}
  </Grid>
)

export default NoRecords
