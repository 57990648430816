import { Defaults } from '@pbt/pbt-ui-components'

import { BulkPriceEntityTypes } from '~/constants/bulkPrices'

import { requestMutation, requestQuery } from './graphql/graphqlCore'
import * as Mutation from './graphql/mutations/bulkPrices'
import * as Query from './graphql/queries/bulkPrices'
import * as Schema from './schemas'

export const fetchInventoryBulkPrices = requestQuery({
  query: Query.PRICED_INVENTORY_VARIATIONS,
  variablesHandler: (
    businessId: string,
    from: number = 0,
    to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    categoryId: string | number,
    subcategoryId?: string | number,
  ) => ({
    categoryId,
    subcategoryId,
    from,
    to,
  }),
  schema: Schema.bulkPriceVariationsList,
})

export const fetchProceduresBulkPrices = requestQuery({
  query: Query.PRICED_PROCEDURES,
  variablesHandler: (
    businessId,
    from: number = 0,
    to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    categoryId: string | number,
  ) => ({
    categoryId,
    from,
    to,
  }),
  schema: Schema.bulkPriceVariationsList,
})

export const fetchLabTestsBulkPrices = requestQuery({
  query: Query.PRICED_LAB_TESTS,
  variablesHandler: (
    businessId,
    from: number = 0,
    to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    labVendorId: string | number,
  ) => ({
    labVendorId,
    from,
    to,
  }),
  schema: Schema.bulkPriceVariationsList,
})

export const fetchInventoryBulkPricesPreview = requestQuery({
  query: Query.INVENTORY_PREVIEW,
  variablesHandler: (
    businessId,
    from: number = 0,
    to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    sessionId: string,
  ) => ({
    sessionId,
    from,
    to,
  }),
  schema: Schema.bulkPriceVariationsPreviewList,
})

export const fetchProceduresBulkPricesPreview = requestQuery({
  query: Query.PROCEDURE_PREVIEW,
  variablesHandler: (
    businessId,
    from: number = 0,
    to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    sessionId: string,
  ) => ({
    sessionId,
    from,
    to,
  }),
  schema: Schema.bulkPriceVariationsPreviewList,
})

export const fetchLabTestsBulkPricesPreview = requestQuery({
  query: Query.LAB_TEST_PREVIEW,
  variablesHandler: (
    businessId,
    from: number = 0,
    to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    sessionId: string,
  ) => ({
    sessionId,
    from,
    to,
  }),
  schema: Schema.bulkPriceVariationsPreviewList,
})

export const bulkEditPricesManual = requestMutation({
  mutation: Mutation.SAVE_PRICE_BULK_UPDATE_MANUAL_CHANGES,
  variablesHandler: (businessId, sessionId, prices, entityType) => ({
    entityType,
    sessionId,
    prices,
  }),
})

export const saveInventoryPriceBulkUpdateChange = requestMutation({
  mutation: Mutation.SAVE_INVENTORY_PRICE_BULK_UPDATE_CHANGE,
  variablesHandler: (
    businessId,
    sessionId,
    categoryId,
    subcategoryId,
    incrementValue,
    incrementType,
    roundUp,
    includePrices,
    priceIds,
  ) => ({
    sessionId,
    categoryId,
    subcategoryId,
    incrementValue,
    incrementType,
    roundUp,
    includePrices,
    priceIds,
  }),
})

export const saveProcedurePriceBulkUpdateChange = requestMutation({
  mutation: Mutation.SAVE_PROCEDURE_PRICE_BULK_UPDATE_CHANGE,
  variablesHandler: (
    businessId,
    sessionId,
    categoryId,
    incrementValue,
    incrementType,
    roundUp,
    includePrices,
    priceIds,
  ) => ({
    sessionId,
    categoryId,
    incrementValue,
    incrementType,
    roundUp,
    includePrices,
    priceIds,
  }),
})

export const saveLabTestPriceBulkUpdateChange = requestMutation({
  mutation: Mutation.SAVE_LAB_TEST_PRICE_BULK_UPDATE_CHANGE,
  variablesHandler: (
    businessId,
    sessionId,
    labVendorId,
    incrementValue,
    incrementType,
    roundUp,
    includePrices,
    priceIds,
  ) => ({
    sessionId,
    labVendorId,
    incrementValue,
    incrementType,
    roundUp,
    includePrices,
    priceIds,
  }),
})

export const fetchBulkPricesActiveSession = requestQuery({
  query: Query.ACTIVE_PRICE_BULK_UPDATE_SESSION,
  variablesHandler: (businessId, entityType) => ({
    entityType,
  }),
  schema: Schema.bulkPriceSession,
})

export const startProceduresBulkPricesSession = requestMutation({
  mutation: Mutation.START_NEW_PRICE_BULK_UPDATE_SESSION,
  variablesHandler: (businessId, force = false) => ({
    entityType: BulkPriceEntityTypes.PROCEDURE,
    force,
  }),
})

export const startLabTestsBulkPricesSession = requestMutation({
  mutation: Mutation.START_NEW_PRICE_BULK_UPDATE_SESSION,
  variablesHandler: (businessId, force = false) => ({
    entityType: BulkPriceEntityTypes.LAB_TEST,
    force,
  }),
})

export const startInventoryBulkPricesSession = requestMutation({
  mutation: Mutation.START_NEW_PRICE_BULK_UPDATE_SESSION,
  variablesHandler: (businessId, force = false) => ({
    entityType: BulkPriceEntityTypes.INVENTORY,
    force,
  }),
})

export const deleteBulkPricesSession = requestMutation({
  mutation: Mutation.DELETE_PRICE_BULK_UPDATE_SESSION,
  variablesHandler: (businessId, sessionId) => ({
    id: sessionId,
  }),
})

export const startPriceBulkUpdate = requestMutation({
  mutation: Mutation.START_PRICE_BULK_UPDATE,
  variablesHandler: (businessId, sessionId) => ({
    sessionId,
  }),
})
