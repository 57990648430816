import { AnyAction } from 'redux'

import { EasterEggQualifyingEvent } from '~/types'

import {
  CLEAR_EASTER_EGG_EVENT_QUEUE,
  QUEUE_EASTER_EGG_EVENT,
  UPDATE_EASTER_EGG_EVENT_QUEUE,
} from '../actions/types/easterEgg'
import type { RootState } from '../index'

export type EasterEggState = {
  easterEggEventQueue: EasterEggQualifyingEvent[]
}

export const INITIAL_STATE: EasterEggState = {
  easterEggEventQueue: [],
}

const easterEgg = (
  state: EasterEggState = INITIAL_STATE,
  action: AnyAction,
): EasterEggState => {
  switch (action.type) {
    case QUEUE_EASTER_EGG_EVENT:
      return {
        ...state,
        easterEggEventQueue: [...state.easterEggEventQueue, action.event],
      }
    case CLEAR_EASTER_EGG_EVENT_QUEUE:
      return { ...state, easterEggEventQueue: [] }
    case UPDATE_EASTER_EGG_EVENT_QUEUE:
      return {
        ...state,
        easterEggEventQueue: action.easterEggEventQueue,
      }
    default:
      return state
  }
}

export const getEasterEgg = (state: RootState): EasterEggState =>
  state.easterEgg
export const getEasterEggEventQueue = (state: RootState) =>
  getEasterEgg(state).easterEggEventQueue

export default easterEgg
