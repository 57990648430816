import React from 'react'
import * as R from 'ramda'

import {
  GroupedRefundItems,
  RefundInvoiceLineItem,
} from '~/api/graphql/generated/types'
import { InvoiceLineItem } from '~/types'

import { RefundInvoiceGroupRow } from './RefundInvoiceGroupRow'
import { RefundInvoiceSingleItemRow } from './RefundInvoiceSingleItemRow'

export interface RefundInvoiceTableRowProps {
  isExpandedSelector?: any
  item: GroupedRefundItems
  tableCellClassName: string
  toggleExpand?: (item: InvoiceLineItem) => void
}

export const RefundInvoiceTableRow = ({
  isExpandedSelector,
  item,
  tableCellClassName,
  toggleExpand,
}: RefundInvoiceTableRowProps) => {
  const isGrouped = R.has('groupSnapshot', item)

  return (
    <>
      {isGrouped && item.items ? (
        <RefundInvoiceGroupRow
          isExpandedSelector={isExpandedSelector}
          item={item}
          tableCellClassName={tableCellClassName}
          toggleExpand={toggleExpand}
        />
      ) : (
        <RefundInvoiceSingleItemRow
          isNested={false}
          item={item as RefundInvoiceLineItem}
          tableCellClassName={tableCellClassName}
        />
      )}
    </>
  )
}
