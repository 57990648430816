import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Defaults,
  InfiniteLoaderListProps as InfiniteLoaderListPropsType,
} from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import {
  fetchMoreItemsForClientPaymentActivity,
  fetchPaymentPage,
  getClientBillingActivityPaymentError,
  getPaymentLoading,
  getPaymentPageData,
  getPaymentTotalCount,
  getShouldRefreshData,
  getUnappliedPaymentLoading,
  getUnappliedPaymentPageData,
  getUnappliedPaymentTotalCount,
} from '~/store/duck/clientBillingActivityData'

import { useGetPaymentBillingActivityColumns } from '../table/payments/columns'
import {
  ClientBillingActivityInfiniteLoaderList,
  ClientBillingActivityInfiniteLoaderListClassesType,
} from './ClientBillingActivityInfiniteLoaderList'

interface PaymentsInfiniteLoaderListProps {
  InfiniteLoaderListProps?: Partial<InfiniteLoaderListPropsType>
  classes?: ClientBillingActivityInfiniteLoaderListClassesType
  clientId: string | undefined
  hasError?: boolean
  onCheckPayments?: (id: string) => void
  onClickEstimate: (data: GraphqlEstimate) => void
  paymentLabelAndAmount?: boolean
  selectedPaymentIds?: string[]
  sortColumns?: (columns: PuiDataTableColumn[]) => PuiDataTableColumn[]
  unapplied: boolean
}

export const PaymentsInfiniteLoaderList = ({
  InfiniteLoaderListProps,
  classes,
  clientId,
  hasError = false,
  onCheckPayments,
  onClickEstimate,
  selectedPaymentIds,
  sortColumns,
  unapplied,
  paymentLabelAndAmount,
}: PaymentsInfiniteLoaderListProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const refresh = useSelector(getShouldRefreshData)
  const paymentPageData = useSelector(getPaymentPageData)
  const paymentTotalCount = useSelector(getPaymentTotalCount)
  const isLoading = useSelector(getPaymentLoading)
  const unappliedPaymentPageData = useSelector(getUnappliedPaymentPageData)
  const unappliedPaymentTotalCount = useSelector(getUnappliedPaymentTotalCount)
  const unappliedIsLoading = useSelector(getUnappliedPaymentLoading)
  const error = useSelector(getClientBillingActivityPaymentError)

  const [offsetValue, setOffsetValue] = useState(0)

  const handleFetchMore = ({ from }: { from: number }) => {
    if (clientId) {
      setOffsetValue(from)
      dispatch(
        fetchMoreItemsForClientPaymentActivity({
          unapplied,
          clientId,
          from,
          to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        }),
      )
    }
  }

  useEffect(() => {
    if (clientId && refresh) {
      dispatch(
        fetchPaymentPage({
          unapplied,
          clientId,
          offset: offsetValue,
          limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          isRefreshing: true,
        }),
      )
    }
  }, [clientId, refresh, unapplied])

  const columns = useGetPaymentBillingActivityColumns({
    key: 'value',
    onClickEstimate,
    checkbox: onCheckPayments
      ? {
          hasError,
          onCheck: onCheckPayments,
          getIsChecked: (id) => (selectedPaymentIds || []).includes(id),
        }
      : undefined,
    showUnappliedAmount: unapplied,
    showPaymentLabelAndAmount: paymentLabelAndAmount,
  }) as PuiDataTableColumn[]
  const sortedColumns = sortColumns ? sortColumns(columns) : columns

  return (
    <ClientBillingActivityInfiniteLoaderList
      InfiniteLoaderListProps={InfiniteLoaderListProps}
      classes={classes}
      clientId={clientId}
      columns={sortedColumns}
      emptyPlaceholder={t('Common:NO_PAYMENTS')}
      error={error}
      isLoadingInitialData={unapplied ? unappliedIsLoading : isLoading}
      pageData={unapplied ? unappliedPaymentPageData : paymentPageData}
      totalCount={unapplied ? unappliedPaymentTotalCount : paymentTotalCount}
      onFetchMore={handleFetchMore}
    />
  )
}
