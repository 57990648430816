import { DateUtils } from '@pbt/pbt-ui-components'

import { BalanceListEntryItem, Invoice } from '~/types'

import { getInvoiceDate } from './invoiceUtils'

const PaymentDateCell = (item: BalanceListEntryItem) => {
  const invoiceItem = item as Invoice

  return DateUtils.formatDate(getInvoiceDate(invoiceItem) || item.creationDate)
}

export default PaymentDateCell
