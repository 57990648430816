import React, { ReactNode } from 'react'
import { Box, BoxProps, css } from '@mui/material'
import { styled } from '@mui/material/styles'
import { PaletteColor } from '@pbt/pbt-ui-components'

import { AlertIcon } from '~/components/elements/Icons/AlertIcon'
import { DropdownToggleIcon } from '~/components/elements/Icons/DropdownToggleIcon'
import Typography from '~/components/elements/Typography/Typography'

export const statuses = [
  'normal',
  'warning',
  'danger',
  'good',
  'complete',
  'canceled',
  'inactive',
  'deceased',
  'chronic',
  'error',
] as const

type StatusVariant = (typeof statuses)[number]

const statusColors: Record<
  StatusVariant,
  { bg: PaletteColor; color: PaletteColor; toggle: PaletteColor }
> = {
  normal: {
    bg: 'shadesNormal',
    color: 'brandPrimary',
    toggle: 'grayGray2',
  },
  warning: {
    bg: 'shadesNeedsAttention',
    color: 'uiNeedsAttention',
    toggle: 'grayGray2',
  },
  danger: {
    bg: 'shadesError',
    color: 'uiError',
    toggle: 'grayGray2',
  },
  good: {
    bg: 'shadesSuccess',
    color: 'uiSuccess',
    toggle: 'grayGray2',
  },
  complete: {
    bg: 'uiSuccess',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  canceled: {
    bg: 'grayGray4',
    color: 'grayGray2',
    toggle: 'grayGray2',
  },
  inactive: {
    bg: 'grayGray2',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  deceased: {
    bg: 'grayGray2',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  chronic: {
    bg: 'brandPrimary',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
  error: {
    bg: 'uiError',
    color: 'grayWhite',
    toggle: 'grayWhite',
  },
}

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => props !== 'withToggle',
})<{ variant: StatusVariant; withToggle?: boolean }>`
  ${({ withToggle }) =>
    withToggle
      ? css`
          cursor: pointer;
        `
      : ''}
  background-color: ${({ theme, variant }) =>
    theme.colors[statusColors[variant].bg]};
  color: ${({ theme, variant }) => theme.colors[statusColors[variant].color]};
  display: flex;
  width: fit-content;
  max-width: 100%;
  border-radius: 2px;
  gap: 2px;
`

const StyledTypographyLabel = styled(Typography.Label, {
  shouldForwardProp: (props) =>
    props !== 'hasStartIcon' && props !== 'hasEndIcon',
})<{
  hasEndIcon?: boolean
  hasStartIcon?: boolean
}>`
  flex-grow: 1;
  padding: ${({ theme, hasStartIcon, hasEndIcon }) =>
    theme.spacing(0.125, hasEndIcon ? 0 : 1, 0.125, hasStartIcon ? 0 : 1)};
`

type StatusProps = BoxProps & {
  ellipsis?: boolean
  endIcon?: ReactNode
  startIcon?: ReactNode
  variant?: StatusVariant
  withAlertIcon?: boolean
  withToggle?: boolean
}

export const Status = ({
  variant = 'normal',
  startIcon,
  endIcon,
  children,
  withAlertIcon = false,
  withToggle = false,
  ellipsis = false,
  ...props
}: StatusProps) => (
  <StyledBox variant={variant} withToggle={withToggle} {...props}>
    <>
      {withAlertIcon ? <AlertIcon /> : startIcon}
      <StyledTypographyLabel
        color={statusColors[variant].color}
        ellipsis={ellipsis}
        hasEndIcon={Boolean(withToggle || endIcon)}
        hasStartIcon={Boolean(withAlertIcon || startIcon)}
      >
        {children}
      </StyledTypographyLabel>
      {withToggle ? (
        <DropdownToggleIcon
          sx={{
            color: (theme) => theme.colors[statusColors[variant].toggle],
          }}
        />
      ) : (
        endIcon
      )}
    </>
  </StyledBox>
)

// eslint-disable-next-line react/no-multi-comp
export const StatusError = ({
  variant = 'error',
  withAlertIcon = true,
  ellipsis = true,
  ...props
}: StatusProps) => (
  <Status
    ellipsis={ellipsis}
    variant={variant}
    withAlertIcon={withAlertIcon}
    {...props}
  />
)

// eslint-disable-next-line react/no-multi-comp
export const StatusWarning = ({
  variant = 'warning',
  ...props
}: StatusProps) => <StatusError variant={variant} {...props} />
