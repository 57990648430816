import { gql } from '@apollo/client/core'

export const FETCH_CONVERSATION_ATTACHMENTS = gql`
  query ConversationAttachments($conversationId: ID!) {
    conversationAttachments(conversationId: $conversationId) {
      id
      messageId
      name
      extension
      fileUrl
      creationDate
      description
    }
  }
`
