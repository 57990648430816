import { useSelector } from 'react-redux'
import {
  Nil,
  PermissionArea,
  UserPermissions,
  Utils,
} from '@pbt/pbt-ui-components'

import { EstimateState } from '~/constants/invoice'
import { Estimate, EstimateTimelineItem } from '~/types'

import { getCRUDByArea } from '../reducers/auth'
import { getEstimateStates } from '../reducers/constants'

export const useGetEstimateState = () => {
  const estimateStates = useSelector(getEstimateStates)

  return (stateId: string | Nil) => {
    const stateName = Utils.getConstantName(stateId, estimateStates)
    return {
      isDraft: stateName === EstimateState.DRAFT,
      isDeclined: stateName === EstimateState.DECLINED,
      isApproved: stateName === EstimateState.APPROVED,
      isExpiredDraft: stateName === EstimateState.EXPIRED_DRAFT,
      isExpiredApproved: stateName === EstimateState.EXPIRED_APPROVED,
      isExpiredDeclined: stateName === EstimateState.EXPIRED_DECLINED,
      isExpired:
        stateName === EstimateState.EXPIRED_DRAFT ||
        stateName === EstimateState.EXPIRED_APPROVED ||
        stateName === EstimateState.EXPIRED_DECLINED,
    }
  }
}

// Rule to display "Record deposit" button: https://chewyinc.atlassian.net/browse/RHAP-12545?focusedCommentId=1518198
export const useDisplayRecordDeposit = (
  estimate: Estimate | EstimateTimelineItem | Nil,
) => {
  const paymentPermissions: UserPermissions = useSelector(
    getCRUDByArea(PermissionArea.PAYMENT),
  )

  const { isApproved: isStatusApproved } = useGetEstimateState()(
    estimate?.stateId,
  )

  if (!estimate) {
    return {
      displayRecordDeposit: false,
      hasPermission: false,
    }
  }

  const { amountNoFee, dueToPayNoFee, id, requiredDepositAmountNoFee } =
    estimate as Estimate | EstimateTimelineItem

  const paidDepositAmountNoFeeValue =
    'paidDepositAmountNoFee' in estimate
      ? estimate.paidDepositAmountNoFee
      : estimate.paidAmountNoFee

  const amountNoFeeValue =
    amountNoFee && typeof amountNoFee === 'object' && 'high' in amountNoFee
      ? amountNoFee.high
      : amountNoFee
  const isUnpaid =
    requiredDepositAmountNoFee > 0
      ? dueToPayNoFee > 0
      : amountNoFeeValue - paidDepositAmountNoFeeValue > 0

  const displayRecordDeposit = isUnpaid && isStatusApproved

  return {
    displayRecordDeposit,
    hasPermission: id ? paymentPermissions.update : paymentPermissions.create,
  }
}
