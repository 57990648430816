import { useContext } from 'react'

import { TimetableContext } from '~/components/dashboard/timetable/TimetableContext'

const useTimetableDate = () => {
  const { selectedDate, setSelectedDate } = useContext(TimetableContext)

  return {
    selectedDate,
    setSelectedDate,
  }
}

export default useTimetableDate
