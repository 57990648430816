import React, { forwardRef, useImperativeHandle, useRef } from 'react'

const scrollAnchorStyle = { opacity: 0, height: 0 }

export interface ScrollAnchorHandle {
  show: () => void
}

const ScrollAnchor = forwardRef<ScrollAnchorHandle, any>((_, ref) => {
  const scrollerRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(ref, () => ({
    show: () => scrollerRef.current?.scrollIntoView(),
  }))

  return <div ref={scrollerRef} style={scrollAnchorStyle} />
})

export default ScrollAnchor
