import { ApiError } from '@pbt/pbt-ui-components'

import { ReconcilationProcessInfo } from '~/types/entities/chat'

import {
  FETCH_BUSINESS_CHAT_ONBOARD_INFO,
  FETCH_BUSINESS_CHAT_ONBOARD_INFO_FAILURE,
  FETCH_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS,
  FETCH_CHAT_RECONCILATION_INFO,
  FETCH_CHAT_RECONCILATION_INFO_FAILURE,
  FETCH_CHAT_RECONCILATION_INFO_SUCCESS,
  OAUTH_LOGIN_ROCKET_CHAT,
  OAUTH_LOGIN_ROCKET_CHAT_FAILURE,
  OAUTH_LOGIN_ROCKET_CHAT_SUCCESS,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_FAILURE,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS,
  SET_CHAT_UNREAD_COUNT,
  START_CHAT_RECONCILATION,
  START_CHAT_RECONCILATION_FAILURE,
  START_CHAT_RECONCILATION_SUCCESS,
} from './types/chat'

export const setChatUnreadCount = (unreadCount: number) => ({
  type: SET_CHAT_UNREAD_COUNT,
  unreadCount,
})

export const oauthLoginRocketChat = () => ({ type: OAUTH_LOGIN_ROCKET_CHAT })
export const oauthLoginRocketChatSuccess = (
  userId: string,
  authToken: string,
) => ({
  type: OAUTH_LOGIN_ROCKET_CHAT_SUCCESS,
  userId,
  authToken,
})
export const oauthLoginRocketChatFailure = (error: ApiError) => ({
  type: OAUTH_LOGIN_ROCKET_CHAT_FAILURE,
  error,
})

export const fetchChatReconcilationInfo = (
  businessId: string,
  count: number,
) => ({
  type: FETCH_CHAT_RECONCILATION_INFO,
  businessId,
  count,
})
export const fetchChatReconcilationInfoSuccess = (
  reconcilationProcesses: string[],
) => ({
  type: FETCH_CHAT_RECONCILATION_INFO_SUCCESS,
  reconcilationProcesses,
})
export const fetchChatReconcilationInfoFailure = (error: ApiError) => ({
  type: FETCH_CHAT_RECONCILATION_INFO_FAILURE,
  error,
})

export const startChatReconcilation = (businessId: string) => ({
  type: START_CHAT_RECONCILATION,
  businessId,
})
export const startChatReconcilationSuccess = (
  reconcilationProcess: ReconcilationProcessInfo,
) => ({
  type: START_CHAT_RECONCILATION_SUCCESS,
  reconcilationProcess,
})
export const startChatReconcilationFailure = (error: ApiError) => ({
  type: START_CHAT_RECONCILATION_FAILURE,
  error,
})

export const fetchBusinessChatOnboardInfo = (businessId: string) => ({
  type: FETCH_BUSINESS_CHAT_ONBOARD_INFO,
  businessId,
})
export const fetchBusinessChatOnboardInfoSuccess = (businessId: string) => ({
  type: FETCH_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS,
  businessId,
})
export const fetchBusinessChatOnboardInfoFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_CHAT_ONBOARD_INFO_FAILURE,
  error,
})

export const partialEditBusinessChatOnboardInfo = (
  businessId: string,
  chatUiEnabled: boolean,
) => ({
  type: PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO,
  businessId,
  chatUiEnabled,
})
export const partialEditBusinessChatOnboardInfoSuccess = (
  businessId: string,
) => ({
  type: PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS,
  businessId,
})
export const partialEditBusinessChatOnboardInfoFailure = (error: ApiError) => ({
  type: PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_FAILURE,
  error,
})
