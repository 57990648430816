import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AddButton, ClassesType, Nil } from '@pbt/pbt-ui-components'

import { DataHandleWithReset, Price, Variation } from '~/types'

import VariationPriceBlock, {
  VariationPriceBlockHandle,
} from './VariationPriceBlock'

const useStyles = makeStyles(
  (theme) => ({
    addButtonContainer: {
      border: theme.constants.tabBorder,
      borderTop: 'none',
    },
  }),
  { name: 'VariationPriceSection' },
)

export interface VariationPriceSectionHandle extends DataHandleWithReset {}

interface VariationPriceSectionProps {
  classes?: ClassesType<typeof useStyles>
  packageTypeId: string
  perPackageUnitsId: string
  variation: Variation | Nil
}

const VariationPriceSection = forwardRef<
  VariationPriceSectionHandle,
  VariationPriceSectionProps
>(function VariationPriceSection(
  { variation, packageTypeId, perPackageUnitsId, classes: classesProp },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation('Common')

  const getNewPrice = () => ({
    price: 0,
    unitId: null,
    priceUnitsSize: 0,
    cost: null,
    costUnitsId: null,
    costUnitsSize: null,
    markupPercent: null,
    minCharge: null,
    dispensingFee: 0,
    discountAllowed: true,
    taxable: false,
    name: '',
    originalCode: null,
    syncWithExternalPrice: false,
  })

  const newPrice = getNewPrice()
  const initialPrices = variation?.prices?.length
    ? variation?.prices
    : [newPrice]

  const [currentPrices, setCurrentPrices] =
    useState<(Omit<Price, 'id'> & { id?: string })[]>(initialPrices)

  useEffect(() => {
    const { id: variationId, prices } = variation || {}

    // we need this scenario only for variation creation flow
    if (variationId) {
      return
    }

    if (!prices || !prices.length || (prices && prices !== currentPrices)) {
      const newPrices = prices && prices.length ? prices : [newPrice]
      setCurrentPrices(newPrices)
    }
  }, [variation])

  const priceBlockRefs = Array.from({ length: currentPrices.length }, () =>
    React.createRef<VariationPriceBlockHandle>(),
  )
  const getFieldsCurrentValues = () =>
    priceBlockRefs.map(
      (priceBlockRef) =>
        priceBlockRef.current?.get() as Omit<Price, 'id'> & { id?: string },
    )

  const addNewPrice = () => {
    const newCurrentPrices = [...getFieldsCurrentValues(), newPrice]
    setCurrentPrices(newCurrentPrices)
  }

  const deletePrice = (index: number) => {
    const newCurrentPrices = R.remove(index, 1, getFieldsCurrentValues())
    setCurrentPrices(newCurrentPrices)
  }

  useImperativeHandle(ref, () => ({
    get: getFieldsCurrentValues,
    validate: () =>
      priceBlockRefs.every((priceBlockRef) =>
        priceBlockRef.current?.validate(),
      ),
    reset: () => {
      setCurrentPrices([])
      priceBlockRefs.forEach((priceBlockRef) => priceBlockRef.current?.reset())
    },
  }))

  const sectionWithMultiplePrices = currentPrices.length > 1

  return (
    <>
      {currentPrices.map((price, index) => {
        const key = `${price?.id}_${index}`
        return (
          <VariationPriceBlock
            canBeRemoved={sectionWithMultiplePrices}
            count={variation?.count}
            key={key}
            packageTypeId={packageTypeId}
            perPackageUnitsId={perPackageUnitsId}
            price={price}
            ref={priceBlockRefs[index]}
            saleUnitOfMeasure={variation?.saleUnitOfMeasure}
            wrap={isMobile ? 'wrap' : 'nowrap'}
            onPriceRemoved={() => deletePrice(index)}
          />
        )
      })}
      <Grid container item className={classes.addButtonContainer} p={1}>
        <AddButton addText={t('Common:ADD_PRICE')} onAdd={addNewPrice} />
      </Grid>
    </>
  )
})

export default VariationPriceSection
