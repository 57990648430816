import { gql } from '@apollo/client'

export const PATIENT_DTO_FIELDS = gql`
  fragment PatientDtoFields on PatientDto {
    id
    name
    photo
    photoThumbnail
    microchipped
    microchipNumber
    species
    breeds
    size
    color
    dateOfBirth
    approximateAge
    acquiredType
    acquiredDate
    gender
    spayedNeutered
    alertTypes
    serviceAnimalType
    environment
    allergies
    diet
    insuranceProvider
    insurancePolicyNumber
    activityLevel
    notes
    deceased
    deceasedDate
    active
    externalPatientId
    externalSource
    rabiesTag
    rabiesTagExpiration
    referralSourceId
    referralSourceTypeId
    sendReminders
  }
`

export const PATIENT_FIELDS = gql`
  fragment PatientFields on Patient {
    approximateDateOfBirth
    id
    name
    dateOfBirth
    gender: genderEntity {
      id
    }
    spayedNeutered
    active
    businessId
    clientId
    createdAt
    deceased
    deceasedDate
    deleted
    displayId
    externalId
    insurancePolicyNumber
    insuranceProvider
    license
    markingsTattoo
    microchipNumber
    spayedNeuteredStatus
    updatedAt
    breeds {
      id
      name
    }
    species {
      id
    }
    color
    currentWeight {
      value
      date
    }
    alertColorId
    alertTypeIds
    customAlertType
    # eslint-disable-next-line @graphql-eslint/no-deprecated
    plans {
      wplanId
      wplanName
      wplanLogId
      wplanBusinessId
      wplanCancellationDate
      wplanClientId
      wplanLevel
      wplanSinceDate
      wplanStatus
      wplanTypeId
      wplanVersion
      wplanVersionId
    }
  }
`
