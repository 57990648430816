import { SchedulerSettings } from '~/types/entities'

import { getAPIPath, request } from './core'

export const fetchSchedulerSettings = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getAPIPath(`admin/services/scheduler-settings/${businessId}`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
  )

export const updateSchedulerSettings = (
  currentBusinessId: string,
  businessId: string,
  settings: Partial<SchedulerSettings>,
) =>
  request(
    getAPIPath(`admin/services/scheduler-settings/${businessId}`),
    {
      method: 'PUT',
      data: settings,
      params: { businessId: currentBusinessId },
    },
    true,
  )
