import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getPaymentCardType } from '~/store/reducers/constants'
import { MembershipPaymentMethod } from '~/types'

interface MembershipPaymentMethodCellProps {
  paymentMethod?: MembershipPaymentMethod
}

const MembershipPaymentMethodCell = ({
  paymentMethod,
}: MembershipPaymentMethodCellProps) => {
  const PaymentCardType = useSelector(getPaymentCardType)
  const { t } = useTranslation('Abbreviations')

  const cardTypeName = LanguageUtils.getConstantTranslatedName(
    paymentMethod?.cardTypeId,
    PaymentCardType,
    '',
  )

  return (
    <>
      {paymentMethod
        ? `${cardTypeName} ${paymentMethod?.last4 || ''}`
        : t('Abbreviations:COMMON.NOT_AVAILABLE')}
    </>
  )
}

export default MembershipPaymentMethodCell
