import React, { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { fetchBatchInvoice, fetchInvoice } from '~/store/actions/finance'
import {
  getFinanceBatchInvoice,
  getFinanceInvoice,
  getMultipleInvoices,
} from '~/store/reducers/finance'
import { Invoice as InvoiceType } from '~/types'

import Invoice, { InvoiceHandle, InvoiceProps } from '../Invoice'

export interface BatchInvoiceProps
  extends Partial<Omit<InvoiceProps, 'invoice' | 'clientId'>> {
  clientId: string | Nil
  invoice?: InvoiceType
  invoiceIds: string[]
}

const BatchInvoice = forwardRef<InvoiceHandle, BatchInvoiceProps>(
  function BatchInvoice(
    { clientId, invoiceIds, invoice: invoiceProp, ...rest },
    ref,
  ) {
    const isSingleInvoice = invoiceIds.length === 1

    const dispatch = useDispatch()
    const invoiceFromState = useSelector(
      isSingleInvoice
        ? getFinanceInvoice(invoiceIds[0])
        : getFinanceBatchInvoice,
    )
    const invoice = invoiceProp || (invoiceFromState as InvoiceType)
    const childInvoices = useSelector(
      getMultipleInvoices((invoice?.invoices as string[]) || []),
    ) as InvoiceType[]

    const fetch = () => {
      if (isSingleInvoice) {
        dispatch(fetchInvoice(invoiceIds[0]))
      } else if (invoiceIds) {
        dispatch(fetchBatchInvoice(invoiceIds))
      }
    }

    useEffect(() => {
      fetch()
    }, [invoiceIds])

    const batchInvoice = {
      ...((invoice as InvoiceType) || {}),
      invoices: childInvoices.map((childInvoice) => ({
        ...childInvoice,
        business: invoice?.business,
      })),
    }

    return (
      <Invoice
        {...rest}
        clientId={clientId}
        invoice={batchInvoice}
        ref={ref}
        refetchInvoice={fetch}
      />
    )
  },
)

export default BatchInvoice
