import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { getIsAuthenticated } from '~/store/reducers/auth'

import { auth0Enabled } from '.'

const useIsAuthenticated = () => {
  const isNativelyAuthenticated = useSelector(getIsAuthenticated)

  const { isAuthenticated: isAuth0Authenticated, isLoading: isAuth0Loading } =
    useAuth0()

  return {
    isAuthenticated: auth0Enabled
      ? isAuth0Authenticated
      : isNativelyAuthenticated,
    isAuthenticating: auth0Enabled ? isAuth0Loading : false,
  }
}

export default useIsAuthenticated
