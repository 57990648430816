import { MutationCreateOrUpdatePatientPreferenceArgs } from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { SAVE_PATIENT_PREFERENCES } from './graphql/mutations'
import { FETCH_PATIENT_PREFERENCES } from './graphql/queries'

export const fetchPatientPreferences = requestQuery({
  query: FETCH_PATIENT_PREFERENCES,
  variablesHandler: (_, patientId: string) => ({
    patientId,
  }),
})

export const savePatientPreferences = requestMutation({
  mutation: SAVE_PATIENT_PREFERENCES,
  variablesHandler: (_, args: MutationCreateOrUpdatePatientPreferenceArgs) =>
    args,
})
