import React from 'react'
import { Icon } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'
import { Boop, Email, PhoneCall, Sms } from '@pbt/pbt-ui-components/src/icons'

import { ConversationTransport } from '~/api/graphql/generated/types'

const TransportIcons = {
  [ConversationTransport.Email]: Email,
  [ConversationTransport.Sms]: Sms,
  [ConversationTransport.LogPhoneCall]: PhoneCall,
  [ConversationTransport.Boop]: Boop,
}

const useStyles = makeStyles(
  (theme) => ({
    transportIcon: {
      fontSize: '3rem',
      color: theme.colors.signIconBackground,
    },
  }),
  { name: 'TransportIcon' },
)

export interface TransportIconProps {
  classes?: ClassesType<typeof useStyles>
  type?: ConversationTransport | Nil
}

const TransportIcon = ({ classes: classesProp, type }: TransportIconProps) => {
  const classes = useStyles({ classes: classesProp })

  if (!type) {
    return null
  }

  return (
    <Icon className={classes.transportIcon} component={TransportIcons[type]} />
  )
}

export default TransportIcon
