import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Defaults } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import {
  fetchEstimatePage,
  fetchMoreItemsForClientEstimateActivity,
  getClientBillingActivityEstimateError,
  getEstimateLoading,
  getEstimatePageData,
  getEstimateTotalCount,
  getShouldRefreshData,
} from '~/store/duck/clientBillingActivityData'

import { useGetEstimateBillingActivityColumns } from '../table/estimates/EstimateBillingActivityTable'
import {
  ClientBillingActivityInfiniteLoaderList,
  ClientBillingActivityInfiniteLoaderListClassesType,
} from './ClientBillingActivityInfiniteLoaderList'

interface EstimatesInfiniteLoaderListProps {
  classes?: ClientBillingActivityInfiniteLoaderListClassesType
  clientId: string | undefined
  onClickEstimate: (data: GraphqlEstimate) => void
}

export const EstimatesInfiniteLoaderList = ({
  classes,
  clientId,
  onClickEstimate,
}: EstimatesInfiniteLoaderListProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const refresh = useSelector(getShouldRefreshData)
  const estimatePageData = useSelector(getEstimatePageData)
  const estimateTotalCount = useSelector(getEstimateTotalCount)
  const isLoading = useSelector(getEstimateLoading)
  const error = useSelector(getClientBillingActivityEstimateError)

  const [offsetValue, setOffsetValue] = useState(0)

  const handleFetchMore = ({ from }: { from: number }) => {
    if (clientId) {
      setOffsetValue(from)
      dispatch(
        fetchMoreItemsForClientEstimateActivity({
          clientId,
          from,
          to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        }),
      )
    }
  }

  useEffect(() => {
    if (clientId && refresh) {
      dispatch(
        fetchEstimatePage({
          clientId,
          offset: offsetValue,
          limit: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          isRefreshing: true,
        }),
      )
    }
  }, [clientId, refresh])

  return (
    <ClientBillingActivityInfiniteLoaderList
      classes={classes}
      clientId={clientId}
      columns={
        useGetEstimateBillingActivityColumns(
          'value',
          onClickEstimate,
        ) as PuiDataTableColumn[]
      }
      emptyPlaceholder={t('Common:NO_ESTIMATES')}
      error={error}
      isLoadingInitialData={isLoading}
      pageData={estimatePageData}
      totalCount={estimateTotalCount}
      onFetchMore={handleFetchMore}
    />
  )
}
