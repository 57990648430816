import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { EditorType } from '@pbt/pbt-ckeditor-ui'

import { fetchCKEditorTokenURL } from '~/api'
import {
  getCKEditorBundleVersion,
  getCKEditorChannelId,
  getCKEditorWebSocketUrl,
} from '~/store/duck/ckeditor'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { EditorDocumentType } from '~/types'

export type OnInitializeChannelId = (
  editorId: string,
  noteType: EditorDocumentType,
  bundleType: EditorType,
  restore: boolean,
) => void

export const useChannelId = (
  editorId: string,
  noteType: EditorDocumentType,
  bundleType: EditorType,
  withRealTimeCollaboration: boolean,
  onInitializeChannelId?: OnInitializeChannelId,
) => {
  const businessId = useSelector(getCurrentBusinessId)
  const bundleVersion = useSelector(getCKEditorBundleVersion(editorId))
  const channelId = useSelector(getCKEditorChannelId(editorId))
  const webSocketUrl = useSelector(getCKEditorWebSocketUrl(editorId))

  const CloudServicesConfig = withRealTimeCollaboration
    ? {
        tokenUrl: async () => {
          try {
            if (!businessId) {
              throw new Error('Missing business id')
            }

            const newToken = await fetchCKEditorTokenURL(businessId)

            if (!newToken) {
              throw new Error('Cannot download a new token!')
            }

            return newToken
          } catch {
            return null
          }
        },
        webSocketUrl,
        channelId,
        bundleVersion,
      }
    : {}

  useEffect(() => {
    if (!withRealTimeCollaboration) {
      return
    }

    onInitializeChannelId?.(editorId, noteType, bundleType, false)
  }, [
    withRealTimeCollaboration,
    editorId,
    bundleType,
    noteType,
    onInitializeChannelId,
  ])

  return useMemo(
    () => ({ CloudServicesConfig }),
    Object.values(CloudServicesConfig),
  )
}
