import { DocumentToSend } from '~/types'
import { PracticeCloningSession } from '~/types/entities/practiceCloning'

import { objectToFormData } from '../utils'
import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const createExportSession = (
  businessId: string,
  session: PracticeCloningSession,
) =>
  request(
    getAPIPath('practice/clone/export'),
    { method: 'POST', params: { businessId }, data: session },
    true,
    Schema.practiceExportSession,
  )

export const fetchExportSession = (businessId: string, sessionId: string) =>
  request(
    getAPIPath(`practice/clone/export/${sessionId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.practiceExportSession,
  )

export const fetchExportSessionForBusiness = (
  businessId: string,
  targetBusinessId: string,
  from: number,
  to: number,
) =>
  request(
    getAPIPath(`practice/clone/export/business/${targetBusinessId}`),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.practiceExportSessionList,
  )

export const fetchActiveExportSession = (
  businessId: string,
  targetBusinessId: string,
) =>
  request(
    getAPIPath(`practice/clone/export/business/${targetBusinessId}/active`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.practiceExportSession,
  )

export const createImportSession = (
  businessId: string,
  session: PracticeCloningSession,
) =>
  request(
    getAPIPath('practice/clone/import'),
    { method: 'POST', params: { businessId }, data: session },
    true,
    Schema.practiceImportSession,
  )

export const createImportSessionFromFile = (
  businessId: string,
  targetBusinessId: string,
  document: DocumentToSend,
) =>
  request(
    getAPIPath(`practice/clone/import/file/business/${targetBusinessId}`),
    {
      method: 'POST',
      params: { businessId },
      data: objectToFormData(document),
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    true,
    Schema.practiceImportSession,
  )

export const fetchImportSession = (businessId: string, sessionId: string) =>
  request(
    getAPIPath(`practice/clone/import/${sessionId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.practiceImportSession,
  )

export const fetchActiveImportSession = (
  businessId: string,
  targetBusinessId: string,
) =>
  request(
    getAPIPath(`practice/clone/import/business/${targetBusinessId}/active`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.practiceImportSession,
  )
