import React from 'react'
import { Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { BaseChargeTabProps, OrderListType } from '~/types'

import ChargeListWithFilters from '../ChargeListWithFilters'

export interface BundlesChargeTabProps extends BaseChargeTabProps {
  appointmentId?: string
  clientId: string | Nil
  patientId?: string
}

const BundlesChargeTab = (props: BundlesChargeTabProps) => (
  <ChargeListWithFilters
    preselectedFilter={OrderType.BUNDLE}
    preselectedListType={OrderListType.OUR}
    {...props}
  />
)

export default BundlesChargeTab
