import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  CREATE_CONVERSATION,
  DELETE_CONVERSATION,
  EMAIL_PREVIEW,
  UPDATE_CONVERSATION,
} from './graphql/mutations'
import {
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_MESSAGES,
  FETCH_CONVERSATIONS,
} from './graphql/queries'
import * as Schema from './schemas'

export const fetchConversation = requestQuery({
  query: FETCH_CONVERSATION,
  variablesHandler: (businessId, conversationId) => ({
    conversationId,
  }),
  schema: Schema.conversation,
})

export const fetchClientConversations = requestQuery({
  query: FETCH_CONVERSATIONS,
  variablesHandler: (
    businessId: string,
    clientId: string | Nil,
    from: number = 0,
    to: number = Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
    teamMembersIds?: string,
    transports?: string,
    statuses?: string,
    lastMessageDateFrom?: string,
    lastMessageDateTo?: string,
    category?: string,
    draftFilter?: string,
  ) => ({
    clientId,
    searchCriteria: {
      offset: from,
      limit: to,
      // TODO: PR-537 rework filters after removing old endpoint
      teamMembersIds: teamMembersIds?.split(','),
      transports: transports?.split(','),
      states: statuses?.split(','),
      lastMessageDateFrom,
      lastMessageDateTo,
      category,
      draftFilter,
    },
  }),
  schema: Schema.conversationsList,
})

export const fetchMessagesForConversation = requestQuery({
  query: FETCH_CONVERSATION_MESSAGES,
  variablesHandler: (
    businessId,
    conversationId,
    from = 0,
    to = Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
  ) => ({
    conversationId,
    from,
    to,
  }),
  schema: Schema.messagesList,
})

export const createConversation = requestMutation({
  mutation: CREATE_CONVERSATION,
  variablesHandler: (businessId, conversation) => ({
    input: conversation,
  }),
  schema: Schema.conversationsArray,
})

export const updateConversation = requestMutation({
  mutation: UPDATE_CONVERSATION,
  variablesHandler: (businessId, conversationId, conversation) => ({
    conversationId,
    input: conversation,
  }),
  schema: Schema.conversation,
})

export const deleteConversation = requestMutation({
  mutation: DELETE_CONVERSATION,
  variablesHandler: (businessId, conversationId) => ({
    conversationId,
  }),
})

export const fetchConversationEmailPreview = requestMutation({
  mutation: EMAIL_PREVIEW,
  variablesHandler: (businessId, emailPreviewConfig) => ({
    input: emailPreviewConfig,
  }),
})
