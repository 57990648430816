export const FETCH_GLOBAL_LIST = 'globalInventory/FETCH_GLOBAL_LIST'
export const UPDATE_GLOBAL_ITEMS = 'globalInventory/UPDATE_GLOBAL_ITEMS'
export const FETCH_GLOBAL_LIST_FAILURE =
  'globalInventory/FETCH_GLOBAL_LIST_FAILURE'
export const FETCH_GLOBAL_LIST_SUCCESS =
  'globalInventory/FETCH_GLOBAL_LIST_SUCCESS'
export const FETCH_MORE_GLOBAL_LIST = 'globalInventory/FETCH_MORE_GLOBAL_LIST'
export const FETCH_MORE_GLOBAL_LIST_FAILURE =
  'globalInventory/FETCH_MORE_GLOBAL_LIST_FAILURE'
export const FETCH_MORE_GLOBAL_LIST_SUCCESS =
  'globalInventory/FETCH_MORE_GLOBAL_LIST_SUCCESS'
export const FETCH_GLOBAL_ITEM = 'globalInventory/FETCH_GLOBAL_ITEM'
export const FETCH_GLOBAL_ITEM_SUCCESS =
  'globalInventory/FETCH_GLOBAL_ITEM_SUCCESS'
export const FETCH_GLOBAL_ITEM_FAILURE =
  'globalInventory/FETCH_GLOBAL_ITEM_FAILURE'
