import React, { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseTemplateInputHandle } from './BaseTemplateInput/BaseTemplateInput'
import WrappedBaseTemplateInput, {
  WrappedBaseTemplateInputProps,
} from './WrappedBaseTemplateInput'

export interface NotesTemplateInputProps
  extends Omit<WrappedBaseTemplateInputProps, 'filter'> {}

const NotesTemplateInput = forwardRef(function NotesTemplateInput(
  props: NotesTemplateInputProps,
  ref: ForwardedRef<BaseTemplateInputHandle>,
) {
  const { t } = useTranslation('Common')

  return (
    <WrappedBaseTemplateInput
      filter="Notes template"
      label={t('Common:NOTES_TEMPLATE')}
      ref={ref}
      {...props}
    />
  )
})

export default NotesTemplateInput
