import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { InvoiceViewStates } from '~/constants/refund'
import {
  fetchRefundInvoice,
  getRefundInvoice,
  getRefundsError,
  getRefundsLoading,
} from '~/store/duck/refunds'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import ChargesHeader from '../components/ChargesHeader'
import { InvoiceDetailsForRefund } from './InvoiceDetailsForRefund'
import { InvoiceFooterForRefunds } from './InvoiceFooterForRefund'
import { RefundContextProvider } from './RefundContext'

const ViewRefundInvoice = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const isLoading = useSelector(getRefundsLoading)
  const isError = useSelector(getRefundsError)

  const { refundInvoiceId } = useParams()
  const refundInvoice = useSelector(getRefundInvoice(refundInvoiceId))

  useEffect(() => {
    if (refundInvoiceId) {
      dispatch(fetchRefundInvoice({ id: refundInvoiceId }))
    }
  }, [refundInvoiceId])

  useEffect(() => {
    if (refundInvoice?.originalInvoiceId) {
      dispatch(fetchInvoiceV3({ id: refundInvoice.originalInvoiceId }))
    }
  }, [refundInvoice?.originalInvoiceId])

  const soapPatientId =
    refundInvoice?.patients?.[0].id ?? refundInvoice?.sections[0].patient?.id

  useWSTopic({
    wsTopic: WSTopics.REFUND_INVOICE,
    context: {
      retrySubscription: false,
      topicParams: {
        invoiceId: refundInvoiceId,
      },
      actionParams: {
        sessionId: null,
      },
    },
    unsubscribe: true,
  })

  return (
    <>
      <ChargesHeader
        isRefundInvoice
        clientId={refundInvoice?.client.id}
        isError={Boolean(isError)}
        isLoading={isLoading}
        soapClientId={refundInvoice?.client.id}
        soapPatientId={soapPatientId}
        title={`${t('Common:REFUND')} ${refundInvoice?.invoiceNo || ''}`}
      />
      <RefundContextProvider
        invoiceId={refundInvoiceId}
        viewState={InvoiceViewStates.REFUND_INVOICE}
      >
        <>
          <InvoiceDetailsForRefund />
          <InvoiceFooterForRefunds onSwitchInvoiceViewState={() => {}} />
        </>
      </RefundContextProvider>
    </>
  )
}

export default ViewRefundInvoice
