import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IframeResizer from 'iframe-resizer-react'

import DialogNames from '~/constants/DialogNames'
import {
  cleanUp,
  fetchAnalyticsUrl,
  getError,
  getIsLoading,
  getUrl,
} from '~/store/duck/analytics'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { VideoItem } from '~/types/entities/analytics'
import useDialog from '~/utils/useDialog'

import EducationalSection from './EducationalSection'

export interface AnalyticsPageProps {
  dashboard: number
  educationalContent?: VideoItem[]
  signedParams?: Record<string, string>
}

const AnalyticsPage = ({
  dashboard,
  educationalContent,
  signedParams,
}: AnalyticsPageProps) => {
  const dispatch = useDispatch()
  const url = useSelector(getUrl(dashboard))
  const isLoadingAnalytics = useSelector(getIsLoading)
  const error = useSelector(getError)
  const currentBusiness = useSelector(getCurrentBusiness)

  const [openReportUnavailableAlert] = useDialog(DialogNames.REPORT_UNAVAILABLE)

  const isLoading = isLoadingAnalytics || !currentBusiness

  // on unmount
  useEffect(
    () => () => {
      dispatch(cleanUp())
    },
    [dashboard],
  )

  useEffect(() => {
    if (!url && !isLoading && !error) {
      dispatch(fetchAnalyticsUrl(dashboard, signedParams))
    }
  }, [url, isLoading, error, dashboard, signedParams])

  useEffect(() => {
    if (error) {
      openReportUnavailableAlert()
    }
  }, [error])

  return (
    <>
      {educationalContent && (
        <EducationalSection content={educationalContent} />
      )}
      {url ? (
        <IframeResizer
          frameBorder="0"
          log={false}
          src={url}
          style={{ minWidth: '100%' }}
          title="analytics-iframe"
        />
      ) : null}
    </>
  )
}

export default AnalyticsPage
