import {
  AmericanExpress as AmericanExpressIcon,
  Discover as DiscoverIcon,
  MasterCard as MasterCardIcon,
  Visa as VisaIcon,
  WellsFargo as WellsFargoIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { IconMap } from '~/types'

const PaymentProviderIconsMap: IconMap = {
  Visa: VisaIcon,
  Mastercard: MasterCardIcon,
  Amex: AmericanExpressIcon,
  Discover: DiscoverIcon,
  'Wells Fargo': WellsFargoIcon,
}

export default PaymentProviderIconsMap
