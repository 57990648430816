import { useSelector } from 'react-redux'
import {
  AppointmentCommunicationTemplate,
  LanguageUtils,
  SentenceFormatter,
  Utils,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  getAppointmentCommunicationInitializationType,
  getAppointmentCommunicationOffsetPoint,
  getAppointmentCommunicationTimeUnits,
  getAppointmentCommunicationTriggeringStatus,
} from '~/store/reducers/constants'

const useSpecificTimeStringFormatter = () => {
  const AppointmentCommunicationTimeUnits = useSelector(
    getAppointmentCommunicationTimeUnits,
  )
  const AppointmentCommunicationOffsetPoint = useSelector(
    getAppointmentCommunicationOffsetPoint,
  )

  return (item: AppointmentCommunicationTemplate) =>
    item.initializationByTime
      ? LanguageUtils.getSentence(
          [
            Math.abs(item.initializationByTime.timeOffset.amount),
            `${LanguageUtils.getConstantTranslatedName(
              item.initializationByTime.timeOffset.unit,
              AppointmentCommunicationTimeUnits,
            )}(s)`,
            item.initializationByTime.timeOffset.amount >= 0
              ? i18n.t<string>('Common:AFTER_APPOINTMENT')
              : i18n.t<string>('Common:BEFORE_APPOINTMENT'),
            LanguageUtils.getConstantTranslatedName(
              item.initializationByTime.offsetPointId,
              AppointmentCommunicationOffsetPoint,
            ),
          ],
          SentenceFormatter.LOWERCASE,
        )
      : ''
}

const useChangeInStatusStringFormatter = () => {
  const AppointmentCommunicationTriggeringStatus = useSelector(
    getAppointmentCommunicationTriggeringStatus,
  )

  return (item: AppointmentCommunicationTemplate) => {
    const statusId = item.initializationByStatus?.triggeringStatusId
    const statusName = LanguageUtils.getConstantTranslatedName(
      statusId,
      AppointmentCommunicationTriggeringStatus,
      '',
    )

    return i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.IF_APPOINTMENT_IS', {
      statusName: statusName.toLowerCase(),
    })
  }
}

export const useAutomaticCommunicationWhenStringFormatter = () => {
  const InitializationType = useSelector(
    getAppointmentCommunicationInitializationType,
  )

  const SpecificTimeOptionId: string = Utils.findConstantIdByName(
    'At a specific time',
    InitializationType,
  )
  const ChangeInStatusOptionId: string = Utils.findConstantIdByName(
    'Triggered by change in appointment status',
    InitializationType,
  )

  const specificTimeStringFormatter = useSpecificTimeStringFormatter()
  const changeInStatusStringFormatter = useChangeInStatusStringFormatter()

  const formattersMap = {
    [SpecificTimeOptionId]: specificTimeStringFormatter,
    [ChangeInStatusOptionId]: changeInStatusStringFormatter,
  }

  return (item: AppointmentCommunicationTemplate) =>
    formattersMap[item.initializationTypeId]?.(item) || ''
}
