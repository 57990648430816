import { includes, without } from 'ramda'

import i18n from '~/locales/i18n'

import DiscountCellCollapsible from './body/collapsible/DiscountCellCollapsible'
import ItemTotalCellCollapsible from './body/collapsible/ItemTotalCellCollapsible'
import ProductionCellCollapsible from './body/collapsible/ProductionCellCollapsible'
import TaxCellCollapsible from './body/collapsible/TaxCellCollapsible'
import UnitPriceCellCollapsible from './body/collapsible/UnitPriceCellCollapsible'
import DiscountCellBody from './body/DiscountCellBody'
import ExtendedPriceCellBody from './body/ExtendedPriceCellBody'
import ItemTotalCellBody from './body/ItemTotalCellBody'
import ProductionCellBody from './body/ProductionCellBody'
import QuantityCellBody from './body/QuantityCellBody'
import TaxCellBody from './body/TaxCellBody'
import UnitPriceCellBody from './body/UnitPriceCellBody'
import DiscountCellEstimateRange from './head/rows/estimate-range/DiscountCellEstimateRange'
import ExtendedPriceCellEstimateRange from './head/rows/estimate-range/ExtendedPriceCellEstimateRange'
import ItemTotalCellEstimateRange from './head/rows/estimate-range/ItemTotalCellEstimateRange'
import QuantityCellEstimateRange from './head/rows/estimate-range/QuantityCellEstimateRange'
import TaxCellEstimateRange from './head/rows/estimate-range/TaxCellEstimateRange'
import DiscountCellHead from './head/rows/head/DiscountCellHead'
import ExtendedPriceCellHead from './head/rows/head/ExtendedPriceCellHead'
import ItemTotalCellHead from './head/rows/head/ItemTotalCellHead'
import ProductionCellHead from './head/rows/head/ProductionCellHead'
import QuantityCellHead from './head/rows/head/QuantityCellHead'
import TaxCellHead from './head/rows/head/TaxCellHead'
import UnitPriceCellHead from './head/rows/head/UnitPriceCellHead'
import DiscountCellSubHead from './head/rows/sub-head/DiscountCellSubHead'
import ExtendedPriceCellSubHead from './head/rows/sub-head/ExtendedPriceCellSubHead'
import ItemTotalCellSubHead from './head/rows/sub-head/ItemTotalCellSubHead'
import ProductionCellSubHead from './head/rows/sub-head/ProductionCellSubHead'
import QuantityCellSubHead from './head/rows/sub-head/QuantityCellSubHead'
import TaxCellSubHead from './head/rows/sub-head/TaxCellSubHead'
import UnitPriceCellSubHead from './head/rows/sub-head/UnitPriceCellSubHead'

export const ColumnNames = {
  UNIT_PRICE: i18n.t('Common:UNIT_PRICE'),
  PRODUCTION: i18n.t('Common:PRODUCTION'),
  QUANTITY: i18n.t('Common:QUANTITY'),
  EXTENDED_PRICE: i18n.t('Common:EXTENDED_PRICE'),
  DISCOUNT: i18n.t('Common:DISCOUNT'),
  TAX: i18n.t('Common:TAX'),
  ITEM_TOTAL: i18n.t('Common:ITEM_TOTAL'),
}

export const PreselectedColumns = [
  ColumnNames.UNIT_PRICE,
  ColumnNames.PRODUCTION,
  ColumnNames.QUANTITY,
  ColumnNames.EXTENDED_PRICE,
  ColumnNames.DISCOUNT,
  ColumnNames.TAX,
  ColumnNames.ITEM_TOTAL,
]

const InvoiceColumns = [
  ColumnNames.UNIT_PRICE,
  ColumnNames.PRODUCTION,
  ColumnNames.QUANTITY,
  ColumnNames.EXTENDED_PRICE,
  ColumnNames.DISCOUNT,
  ColumnNames.TAX,
  ColumnNames.ITEM_TOTAL,
]

const EstimateColumns = [
  ColumnNames.QUANTITY,
  ColumnNames.EXTENDED_PRICE,
  ColumnNames.DISCOUNT,
  ColumnNames.TAX,
  ColumnNames.ITEM_TOTAL,
]

export const InvoiceSelectableColumns = [
  ColumnNames.UNIT_PRICE,
  ColumnNames.PRODUCTION,
  ColumnNames.QUANTITY,
  ColumnNames.EXTENDED_PRICE,
  ColumnNames.DISCOUNT,
  ColumnNames.TAX,
]

export const EstimateSelectableColumns = [
  ColumnNames.QUANTITY,
  ColumnNames.EXTENDED_PRICE,
  ColumnNames.DISCOUNT,
  ColumnNames.TAX,
]

export const InvoiceNonSelectableColumns = without(
  InvoiceSelectableColumns,
  InvoiceColumns,
)
export const EstimateNonSelectableColumns = without(
  EstimateSelectableColumns,
  EstimateColumns,
)

export const HeadColumnComponents = {
  [ColumnNames.UNIT_PRICE]: UnitPriceCellHead,
  [ColumnNames.PRODUCTION]: ProductionCellHead,
  [ColumnNames.QUANTITY]: QuantityCellHead,
  [ColumnNames.EXTENDED_PRICE]: ExtendedPriceCellHead,
  [ColumnNames.DISCOUNT]: DiscountCellHead,
  [ColumnNames.TAX]: TaxCellHead,
  [ColumnNames.ITEM_TOTAL]: ItemTotalCellHead,
}

export const SubHeadColumnComponents = {
  [ColumnNames.UNIT_PRICE]: UnitPriceCellSubHead,
  [ColumnNames.PRODUCTION]: ProductionCellSubHead,
  [ColumnNames.QUANTITY]: QuantityCellSubHead,
  [ColumnNames.EXTENDED_PRICE]: ExtendedPriceCellSubHead,
  [ColumnNames.DISCOUNT]: DiscountCellSubHead,
  [ColumnNames.TAX]: TaxCellSubHead,
  [ColumnNames.ITEM_TOTAL]: ItemTotalCellSubHead,
}

export const EstimateRangeColumnComponents = {
  [ColumnNames.QUANTITY]: QuantityCellEstimateRange,
  [ColumnNames.EXTENDED_PRICE]: ExtendedPriceCellEstimateRange,
  [ColumnNames.DISCOUNT]: DiscountCellEstimateRange,
  [ColumnNames.TAX]: TaxCellEstimateRange,
  [ColumnNames.ITEM_TOTAL]: ItemTotalCellEstimateRange,
}

export const BodyColumnComponents = {
  [ColumnNames.UNIT_PRICE]: UnitPriceCellBody,
  [ColumnNames.PRODUCTION]: ProductionCellBody,
  [ColumnNames.QUANTITY]: QuantityCellBody,
  [ColumnNames.EXTENDED_PRICE]: ExtendedPriceCellBody,
  [ColumnNames.DISCOUNT]: DiscountCellBody,
  [ColumnNames.TAX]: TaxCellBody,
  [ColumnNames.ITEM_TOTAL]: ItemTotalCellBody,
}

export const CollapsibleColumnComponents = {
  [ColumnNames.UNIT_PRICE]: UnitPriceCellCollapsible,
  [ColumnNames.PRODUCTION]: ProductionCellCollapsible,
  [ColumnNames.DISCOUNT]: DiscountCellCollapsible,
  [ColumnNames.TAX]: TaxCellCollapsible,
  [ColumnNames.ITEM_TOTAL]: ItemTotalCellCollapsible,
}

export const getInvoiceSelectableColumns = (isEstimate: boolean) =>
  isEstimate ? EstimateSelectableColumns : InvoiceSelectableColumns

export const getInvoiceNonSelectableColumns = (isEstimate: boolean) =>
  isEstimate ? EstimateNonSelectableColumns : InvoiceNonSelectableColumns

export const getInvoicePossibleColumns = (isEstimate: boolean) =>
  isEstimate ? EstimateColumns : InvoiceColumns

export const getInvoiceColumns = (columns: string[], isEstimate: boolean) => {
  const possibleColumns = getInvoicePossibleColumns(isEstimate)
  const nonSelectableColumns = getInvoiceNonSelectableColumns(isEstimate)
  const list = nonSelectableColumns.concat(columns)
  return possibleColumns.filter((name) => includes(name, list)) // maintain order
}
