import React, { useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PermissionArea,
  PuiDialog,
  PuiSelect,
  PuiTextField,
  RoleName,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'

import PuiSwitch from '~/components/common/PuiSwitch'
import SecretSearchContext from '~/constants/secretSearchContext'
import { fetchSuggestionResults } from '~/store/actions/search'
import { fetchSpacesList } from '~/store/actions/spaces'
import {
  fetchClientPreferences,
  getClientPreferences,
  getClientPreferencesIsLoading,
  getClientPreferencesIsSaving,
  saveClientPreferences,
} from '~/store/duck/clientPreferences'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getPreferenceAttitudeTowardsStaff,
  getPreferenceCadenceOfVisits,
  getPreferenceClientPerformedTreatmentsLocations,
  getPreferenceDayOfWeek,
  getPreferenceExtentOfCare,
  getPreferenceFinancialComfort,
  getPreferenceGenderIdentity,
  getPreferenceNeedsExtendedAppointmentTime,
  getPreferencePaymentMethod,
  getPreferenceServicePreferences,
  getPreferenceTimeOfDay,
} from '~/store/reducers/constants'
import {
  getSuggestionIsReceiving,
  getSuggestionResults,
} from '~/store/reducers/search'
import {
  getMultipleSpaces,
  getSpacesIsLoading,
  getSpacesList,
} from '~/store/reducers/spaces'
import { SuggestionResult } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

interface ClientPreferencesDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 550,
      maxWidth: 550,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 100,
    },
  }),
  { name: 'ClientPreferencesDialog' },
)

const ClientPreferencesDialog = ({
  open,
  onClose,
  clientId,
}: ClientPreferencesDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])
  const dispatch = useDispatch()

  const spacesList = useSelector(getSpacesList)
  const spaces = useSelector(getMultipleSpaces(spacesList))
  const spacesIsLoading = useSelector(getSpacesIsLoading)

  const mainStaffRoles = useMainStaffRoles()
  const doctorRoleId = findConstantIdByName(
    RoleName.Veterinarian,
    mainStaffRoles,
  )
  const doctorRoleId2 = findConstantIdByName(
    RoleName.Veterinarian2,
    mainStaffRoles,
  )
  const roleIdsList = [doctorRoleId, doctorRoleId2]
  const searchIsLoading = useSelector(getSuggestionIsReceiving)
  const searchResults: SuggestionResult[] = useSelector(getSuggestionResults)

  const genderIdentityConstants = useSelector(getPreferenceGenderIdentity)
  const attitudeTowardsStaffConstants = useSelector(
    getPreferenceAttitudeTowardsStaff,
  )
  const paymentMethodConstants = useSelector(getPreferencePaymentMethod)
  const financialComfortConstants = useSelector(getPreferenceFinancialComfort)
  const extentOfCareConstants = useSelector(getPreferenceExtentOfCare)
  const servicePreferenceConstants = useSelector(
    getPreferenceServicePreferences,
  )
  const locationOfPerformedTreatmentConstants = useSelector(
    getPreferenceClientPerformedTreatmentsLocations,
  )
  const timeOfDayConstants = useSelector(getPreferenceTimeOfDay)
  const needsExtendedAppointmentTimeConstants = useSelector(
    getPreferenceNeedsExtendedAppointmentTime,
  )
  const cadenceOfVisitConstants = useSelector(getPreferenceCadenceOfVisits)
  const dayOfWeekConstants = useSelector(getPreferenceDayOfWeek)

  const clientPreferences = useSelector(getClientPreferences(clientId))
  const preferencesIsLoading = useSelector(getClientPreferencesIsLoading)
  const preferencesIsSaving = useSelector(getClientPreferencesIsSaving)
  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )

  const isLoading = preferencesIsLoading || spacesIsLoading || searchIsLoading

  const { fields, reset, validate } = useFields(
    [
      {
        name: 'genderIdentity',
        label: t('Clients:CLIENT_PREFERENCES.PERSONAL.GENDER_IDENTITY'),
        type: 'select',
        initialValue: clientPreferences?.genderIdentity?.id ?? '',
      },
      {
        name: 'attitudeTowardsStaff',
        label: t('Clients:CLIENT_PREFERENCES.PERSONAL.ATTITUDE_TOWARDS_STAFF'),
        type: 'select',
        initialValue: clientPreferences?.attitudeTowardsStaff?.id ?? '',
      },
      {
        name: 'topicsToAvoid',
        label: t('Clients:CLIENT_PREFERENCES.PERSONAL.TOPICS_TO_AVOID'),
        type: 'text',
        initialValue: clientPreferences?.topicsToAvoid ?? '',
      },
      {
        name: 'paymentMethod',
        label: t('Clients:CLIENT_PREFERENCES.FINANCIAL.PAYMENT_METHOD'),
        type: 'select',
        initialValue: clientPreferences?.paymentMethod?.id ?? '',
      },
      {
        name: 'financialComfort',
        label: t('Clients:CLIENT_PREFERENCES.FINANCIAL.FINANCIAL_COMFORT'),
        type: 'select',
        initialValue: clientPreferences?.financialComfort?.id ?? '',
      },
      {
        name: 'concernForNonpayment',
        label: t('Clients:CLIENT_PREFERENCES.FINANCIAL.CONCERN_FOR_NONPAYMENT'),
        type: 'toggle',
        initialValue: clientPreferences?.concernForNonpayment ?? false,
      },
      {
        name: 'financialComment',
        label: t('Clients:CLIENT_PREFERENCES.FINANCIAL.COMMENT'),
        type: 'text',
        initialValue: clientPreferences?.financialComment ?? '',
      },
      {
        name: 'extentOfCare',
        label: t('Clients:CLIENT_PREFERENCES.CARE.EXTENT_OF_CARE'),
        type: 'select',
        initialValue: clientPreferences?.extentOfCare?.id ?? '',
      },
      {
        name: 'servicePreference',
        label: t('Clients:CLIENT_PREFERENCES.CARE.SERVICE_PREFERENCES'),
        type: 'select',
        initialValue: clientPreferences?.servicePreference?.id ?? '',
      },
      {
        name: 'doctorIds',
        label: t('Clients:CLIENT_PREFERENCES.CARE.DOCTOR'),
        type: 'select',
        initialValue: clientPreferences?.doctors?.map((c) => c.id) ?? [],
      },
      {
        name: 'spaceIds',
        label: t('Clients:CLIENT_PREFERENCES.CARE.SPACES'),
        type: 'select',
        initialValue: clientPreferences?.spaces?.map((c) => c?.id) ?? [],
      },
      {
        name: 'locationOfPerformedTreatment',
        label: t(
          'Clients:CLIENT_PREFERENCES.CARE.LOCATION_OF_PERFORMED_TREATMENTS',
        ),
        type: 'select',
        initialValue: clientPreferences?.locationOfPerformedTreatment?.id ?? '',
      },
      {
        name: 'careComment',
        label: t('Clients:CLIENT_PREFERENCES.CARE.COMMENT'),
        type: 'text',
        initialValue: clientPreferences?.careComment ?? '',
      },
      {
        name: 'dayOfWeek',
        label: t('Clients:CLIENT_PREFERENCES.SCHEDULING.DAY_OF_WEEK'),
        type: 'select',
        initialValue: clientPreferences?.dayOfWeek?.map((c) => c.id) ?? [],
      },
      {
        name: 'timeOfDay',
        label: t('Clients:CLIENT_PREFERENCES.SCHEDULING.TIME_OF_DAY'),
        type: 'select',
        initialValue: clientPreferences?.timeOfDay?.map((c) => c.id) ?? [],
      },
      {
        name: 'cadenceOfVisit',
        label: t('Clients:CLIENT_PREFERENCES.SCHEDULING.CADENCE_OF_VISITS'),
        type: 'select',
        initialValue: clientPreferences?.cadenceOfVisit?.map((c) => c.id) ?? [],
      },
      {
        name: 'clientChronicallyLate',
        label: t('Clients:CLIENT_PREFERENCES.SCHEDULING.CHRONICALLY_LATE'),
        type: 'toggle',
        initialValue: clientPreferences?.clientChronicallyLate ?? false,
      },
      {
        name: 'clientChronicallyNoShow',
        label: t('Clients:CLIENT_PREFERENCES.SCHEDULING.CHRONICALLY_NO_SHOW'),
        type: 'toggle',
        initialValue: clientPreferences?.clientChronicallyNoShow ?? false,
      },
      {
        name: 'needsExtendedAppointmentTime',
        label: t(
          'Clients:CLIENT_PREFERENCES.SCHEDULING.NEED_EXTENDED_APPT_TIME',
        ),
        type: 'select',
        initialValue: clientPreferences?.needsExtendedAppointmentTime?.id ?? '',
      },
      {
        name: 'schedulingComment',
        label: t('Clients:CLIENT_PREFERENCES.SCHEDULING.COMMENT'),
        type: 'text',
        initialValue: clientPreferences?.schedulingComment ?? '',
      },
    ],
    false,
  )

  useEffect(() => {
    if (!isLoading) {
      reset()
    }
  }, [isLoading])

  const closeAfterCreation = useCloseAfterCreation(() => {
    reset()
    if (onClose) {
      onClose()
    }
  }, getClientPreferencesIsSaving)

  useLayoutEffect(() => {
    if (clientId) {
      dispatch(fetchClientPreferences({ id: clientId }))
    }
    dispatch(fetchSpacesList())
    dispatch(
      fetchSuggestionResults({
        searchContext: SecretSearchContext.PERSONS,
        searchTerm: '',
        roleIds: roleIdsList.join(','),
        includeInactive: false,
        shorten: false,
      }),
    )
  }, [clientId])

  const {
    genderIdentity,
    attitudeTowardsStaff,
    topicsToAvoid,
    paymentMethod,
    financialComfort,
    concernForNonpayment,
    financialComment,
    extentOfCare,
    servicePreference,
    doctorIds,
    spaceIds,
    locationOfPerformedTreatment,
    careComment,
    dayOfWeek,
    timeOfDay,
    cadenceOfVisit,
    clientChronicallyLate,
    clientChronicallyNoShow,
    needsExtendedAppointmentTime,
    schedulingComment,
  } = fields

  const onSave = () => {
    if (clientId && validate()) {
      dispatch(
        saveClientPreferences({
          clientId,
          input: {
            genderIdentityId:
              genderIdentity.value.length > 0
                ? genderIdentity.value
                : undefined,
            attitudeTowardsStaffId:
              attitudeTowardsStaff.value.length > 0
                ? attitudeTowardsStaff.value
                : undefined,
            topicsToAvoid: topicsToAvoid.value,
            paymentMethodId:
              paymentMethod.value.length > 0 ? paymentMethod.value : undefined,
            financialComfortId:
              financialComfort.value.length > 0
                ? financialComfort.value
                : undefined,
            concernForNonpayment: concernForNonpayment.value,
            financialComment: financialComment.value,
            extentOfCareId:
              extentOfCare.value.length > 0 ? extentOfCare.value : undefined,
            servicePreferenceId:
              servicePreference.value.length > 0
                ? servicePreference.value
                : undefined,
            doctors:
              doctorIds.value.length > 0
                ? doctorIds.value.map((id: string) => ({ id }))
                : undefined,
            spaces:
              spaceIds.value.length > 0
                ? spaceIds.value.map((id: string) => ({ id }))
                : undefined,
            locationOfPerformedTreatmentId:
              locationOfPerformedTreatment.value.length > 0
                ? locationOfPerformedTreatment.value
                : undefined,
            careComment: careComment.value,
            dayOfWeekIds:
              dayOfWeek.value.length > 0 ? dayOfWeek.value : undefined,
            timeOfDayIds:
              timeOfDay.value.length > 0 ? timeOfDay.value : undefined,
            cadenceOfVisitIds:
              cadenceOfVisit.value.length > 0
                ? cadenceOfVisit.value
                : undefined,
            clientChronicallyLate: clientChronicallyLate.value,
            clientChronicallyNoShow: clientChronicallyNoShow.value,
            needsExtendedAppointmentTimeId:
              needsExtendedAppointmentTime.value.length > 0
                ? needsExtendedAppointmentTime.value
                : undefined,
            schedulingComment: schedulingComment.value,
          },
        }),
      )

      closeAfterCreation()
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: patientUpdatePermissions ? onSave : undefined,
      }}
      actions={
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={
              !patientUpdatePermissions || isLoading || preferencesIsSaving
            }
            loading={preferencesIsSaving}
            onClick={patientUpdatePermissions ? onSave : undefined}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      }
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() => isFieldValuesChanged(fields)}
      open={open}
      scroll="paper"
      title={t('Clients:CLIENT_PREFERENCES.DISPLAY.HEADER')}
      onClose={onClose}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Grid container>
          <Grid container mb={2}>
            <Grid item xs={12}>
              <Text strong>
                {t('Clients:CLIENT_PREFERENCES.PERSONAL.TITLE')}
              </Text>
            </Grid>
            <Grid item md={6} pr={1} xs={12}>
              <FormControl fullWidth error={!genderIdentity.valid}>
                <InputLabel htmlFor="client-gender-identity-select">
                  {genderIdentity.label}
                </InputLabel>
                <PuiSelect
                  field={genderIdentity}
                  input={<Input id="client-gender-identity-select" />}
                  items={genderIdentityConstants}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} pl={1} xs={12}>
              <FormControl fullWidth error={!attitudeTowardsStaff.valid}>
                <InputLabel htmlFor="client-attitude-select">
                  {attitudeTowardsStaff.label}
                </InputLabel>
                <PuiSelect
                  field={attitudeTowardsStaff}
                  input={<Input id="client-attitude-select" />}
                  items={attitudeTowardsStaffConstants}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel htmlFor="personal-topics-to-avoid-input">
                <Text mb={0} mt={2} variant="subheading3">
                  {topicsToAvoid.label}
                </Text>
              </InputLabel>
              <PuiTextField
                multiline
                field={topicsToAvoid}
                id="personal-topics-to-avoid-input"
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12}>
              <Text strong>
                {t('Clients:CLIENT_PREFERENCES.FINANCIAL.TITLE')}
              </Text>
            </Grid>
            <Grid item md={6} pr={1} xs={12}>
              <FormControl fullWidth error={!paymentMethod.valid}>
                <InputLabel htmlFor="client-payment-method-select">
                  {paymentMethod.label}
                </InputLabel>
                <PuiSelect
                  field={paymentMethod}
                  input={<Input id="client-payment-method-select" />}
                  items={paymentMethodConstants}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} pl={1} xs={12}>
              <FormControl fullWidth error={!financialComfort.valid}>
                <InputLabel htmlFor="client-financial-comfort-select">
                  {financialComfort.label}
                </InputLabel>
                <PuiSelect
                  field={financialComfort}
                  input={<Input id="client-financial-comfort-select" />}
                  items={financialComfortConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <PuiSwitch
                field={concernForNonpayment}
                id="client-concern-for-nonpayment-switch"
                label={concernForNonpayment.label}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel htmlFor="financial-comment-input">
                <Text mb={0} mt={2} variant="subheading3">
                  {financialComment.label}
                </Text>
              </InputLabel>
              <PuiTextField
                multiline
                field={financialComment}
                id="financial-comment-input"
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12}>
              <Text strong>{t('Clients:CLIENT_PREFERENCES.CARE.TITLE')}</Text>
            </Grid>
            <Grid item md={6} pr={1} xs={12}>
              <FormControl fullWidth error={!extentOfCare.valid}>
                <InputLabel htmlFor="client-extent-of-care-select">
                  {extentOfCare.label}
                </InputLabel>
                <PuiSelect
                  field={extentOfCare}
                  input={<Input id="client-extent-of-care-select" />}
                  items={extentOfCareConstants}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} pl={1} xs={12}>
              <FormControl fullWidth error={!servicePreference.valid}>
                <InputLabel htmlFor="client-service-preference-select">
                  {servicePreference.label}
                </InputLabel>
                <PuiSelect
                  field={servicePreference}
                  input={<Input id="client-service-preference-select" />}
                  items={servicePreferenceConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!doctorIds.valid}>
                <InputLabel htmlFor="client-doctor-select">
                  {doctorIds.label}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={doctorIds}
                  input={<Input id="client-doctor-select" />}
                  items={searchResults
                    .filter((sr) => sr.item)
                    .map((searchResult) => ({
                      id: searchResult.id,
                      name: searchResult.item!,
                    }))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!spaceIds.valid}>
                <InputLabel htmlFor="client-spaces-select">
                  {spaceIds.label}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={spaceIds}
                  input={<Input id="client-spaces-select" />}
                  items={spaces}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!locationOfPerformedTreatment.valid}
              >
                <InputLabel htmlFor="client-location-of-performed-treatment-select">
                  {locationOfPerformedTreatment.label}
                </InputLabel>
                <PuiSelect
                  field={locationOfPerformedTreatment}
                  input={
                    <Input id="client-location-of-performed-treatment-select" />
                  }
                  items={locationOfPerformedTreatmentConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="care-comment-input">
                <Text mb={0} mt={2} variant="subheading3">
                  {careComment.label}
                </Text>
              </InputLabel>
              <PuiTextField
                multiline
                field={careComment}
                id="care-comment-input"
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Text strong>
                {t('Clients:CLIENT_PREFERENCES.SCHEDULING.TITLE')}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!dayOfWeek.valid}>
                <InputLabel htmlFor="client-day-of-week-select">
                  {dayOfWeek.label}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={dayOfWeek}
                  input={<Input id="client-day-of-week-select" />}
                  items={dayOfWeekConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!timeOfDay.valid}>
                <InputLabel htmlFor="client-time-of-day-select">
                  {timeOfDay.label}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={timeOfDay}
                  input={<Input id="client-time-of-day-select" />}
                  items={timeOfDayConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth error={!cadenceOfVisit.valid}>
                <InputLabel htmlFor="client-cadence-of-visit-select">
                  {cadenceOfVisit.label}
                </InputLabel>
                <PuiSelect
                  multiple
                  field={cadenceOfVisit}
                  input={<Input id="client-cadence-of-visit-select" />}
                  items={cadenceOfVisitConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!needsExtendedAppointmentTime.valid}
              >
                <InputLabel htmlFor="client-needs-extended-time-select">
                  {needsExtendedAppointmentTime.label}
                </InputLabel>
                <PuiSelect
                  field={needsExtendedAppointmentTime}
                  input={<Input id="client-needs-extended-time-select" />}
                  items={needsExtendedAppointmentTimeConstants}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <PuiSwitch
                field={clientChronicallyLate}
                id="client-chronically-late-switch"
                label={clientChronicallyLate.label}
              />
            </Grid>
            <Grid item xs={12}>
              <PuiSwitch
                field={clientChronicallyNoShow}
                id="client-chronically-no-show-switch"
                label={clientChronicallyNoShow.label}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor="scheduling-comment-input">
                <Text mb={0} mt={2} variant="subheading3">
                  {schedulingComment.label}
                </Text>
              </InputLabel>
              <PuiTextField
                multiline
                field={schedulingComment}
                id="care-comment-input"
                inputProps={{ maxLength: 1000 }}
                minRows={3}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </PuiDialog>
  )
}
export default ClientPreferencesDialog
