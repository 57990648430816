import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateFormat,
  LanguageUtils,
  moment,
  Nil,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { SoapFile } from '~/types'

import AttachmentFileControlButtons from '../inputs/attachment/AttachmentFileControlButtons'

const useStyles = makeStyles(
  (theme) => ({
    addedByText: {
      color: theme.colors.sideText,
    },
  }),
  { name: 'AttachmentHeader' },
)

export interface AttachmentHeaderProps {
  attachment: SoapFile
  author: User | Nil
  editDisabled?: boolean
  expanded?: boolean
  hideAddedBy?: boolean
  hideDate?: boolean
  hideDelete?: boolean
  onDeleteRequested?: () => void
  onEmailRequested?: () => void
  onPreviewRequested?: () => void
  onPrintRequested?: () => void
}

const AttachmentHeader = ({
  editDisabled,
  hideDate,
  hideAddedBy,
  hideDelete,
  author,
  attachment,
  expanded,
  onPreviewRequested,
  onDeleteRequested,
  onEmailRequested,
  onPrintRequested,
}: AttachmentHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const formattedDate = moment(attachment.creationDate).format(
    DateFormat.FULL_DATE_COMMA_PIPE_TIME,
  )

  return (
    <Grid container item alignItems="center">
      <Grid item xs>
        {!hideDate && (
          <Text inline variant="body2">
            {formattedDate}
          </Text>
        )}
        {expanded && !hideAddedBy && author && (
          <Text inline className={classes.addedByText} ml={1} variant="body2">
            {LanguageUtils.lowercaseFirst(
              t('Common:ADDED_BY_PERSON_NAME', {
                personName: Utils.getPersonString(author),
              }),
            )}
          </Text>
        )}
      </Grid>
      <Grid item>
        <AttachmentFileControlButtons
          deleteDisabled={editDisabled}
          file={attachment}
          hideDelete={hideDelete}
          onDeleteRequested={onDeleteRequested}
          onEmailRequested={onEmailRequested}
          onPreviewRequested={onPreviewRequested}
          onPrintRequested={onPrintRequested}
        />
      </Grid>
    </Grid>
  )
}

export default AttachmentHeader
