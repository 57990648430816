import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EditorState } from 'draft-js'
import { PuiTextArea, Text, useFields, Utils } from '@pbt/pbt-ui-components'

import RichEdit from '~/components/common/inputs/rich-edit/RichEdit'
import { getDocumentTypes } from '~/store/reducers/constants'
import {
  BaseDataHandleWithUnsavedChanges,
  Document as DocumentType,
} from '~/types'

import { compareDocument, createTemplateFromHtml } from './documentUtils'

const useStyles = makeStyles(
  (theme) => ({
    content: {},
    richEditRoot: {
      height: 792,
      overflowY: 'auto',
    },
    richEditEditor: {
      border: 'none !important',
    },
    richEditContainer: {
      boxShadow: theme.constants.blockShadow,
    },
    textArea: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'DocumentEditor' },
)

export interface DocumentEditorHandle
  extends BaseDataHandleWithUnsavedChanges {}

interface DocumentEditorProps {
  document: DocumentType
}

const DocumentEditor = forwardRef<DocumentEditorHandle, DocumentEditorProps>(
  function DocumentEditor({ document }, ref) {
    const classes = useStyles()
    const { t } = useTranslation('Common')
    const [editorState, setEditorState] = useState<EditorState>(
      EditorState.createEmpty(),
    )

    const DocumentTypes = useSelector(getDocumentTypes)

    const CommunicationTemplateType = Utils.findConstantIdByName(
      'Communication template',
      DocumentTypes,
    )

    const {
      fields: { subject },
    } = useFields([
      {
        name: 'subject',
        label: t('Common:EMAIL_SUBJECT'),
        initialValue: document.subject,
      },
    ])

    const getDocument = () => ({
      ...document,
      subject: subject.value,
      template: createTemplateFromHtml(editorState),
    })

    useImperativeHandle(ref, () => ({
      get: getDocument,
      hasUnsavedChanges: () =>
        Boolean(!compareDocument(getDocument(), document)),
    }))

    const isCommunicationTemplate = document.type === CommunicationTemplateType

    return (
      <Grid
        container
        item
        className={classes.content}
        direction="column"
        pt={2}
        px={3}
      >
        {isCommunicationTemplate && (
          <Grid item>
            <PuiTextArea
              classes={{
                input: classes.textArea,
              }}
              label={subject.label}
              minRows={1}
              value={subject.value}
              onChange={subject.set}
            />
            <Text strong mb={1} mt={2} variant="subheading3">
              {t('Common:MESSAGE')}:
            </Text>
          </Grid>
        )}
        <Grid item className={classes.richEditContainer}>
          <RichEdit
            stickyControlPanel
            classes={{
              root: classes.richEditRoot,
              editor: classes.richEditEditor,
            }}
            editorState={editorState}
            initialHTML={document.template?.body}
            setEditorState={setEditorState}
            wrapHeaderText={false}
          />
        </Grid>
      </Grid>
    )
  },
)

export default DocumentEditor
