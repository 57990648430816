import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  fetchingLocationData,
  updateBusinessCandidate,
} from '~/store/actions/registration'

import ClinicsSelector from './ClinicsSelector'

const ClinicsSelectorComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('Registration')

  const onClinicSelected = () => {
    dispatch(fetchingLocationData())
    navigate('/register/clinic/confirm')
  }

  const onAddNewClinicRequested = () => {
    navigate('/register/clinic/edit')
  }

  return (
    <ClinicsSelector
      subheaderText={t(
        'Registration:CLINICS_SELECTOR_COMPONENT.SUB_HEADER_TEXT',
      )}
      onAddNewClinicRequested={onAddNewClinicRequested}
      onBusinessLoaded={(business) =>
        dispatch(updateBusinessCandidate(business))
      }
      onClinicSelected={onClinicSelected}
    />
  )
}

export default ClinicsSelectorComponent
