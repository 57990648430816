import React from 'react'
import { TableCell } from '@mui/material'

export interface DiscountCellEstimateRangeProps {
  className?: string
}

const DiscountCellEstimateRange = ({
  className,
}: DiscountCellEstimateRangeProps) => (
  <TableCell className={className} colSpan={2} />
)

export default DiscountCellEstimateRange
