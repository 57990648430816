import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { StateLabel, Utils } from '@pbt/pbt-ui-components'

import { PAYMENT_TRANSACTION_STATE } from '~/constants/paymentTypes'
import { getPaymentTransactionState } from '~/store/reducers/constants'
import { BalanceListEntryItem, ExtendPayment, InvoiceOrEstimate } from '~/types'

import { INVOICE_CHARGE_SHEET_TYPE } from '../charge-sheet/constants'
import InvoiceStatusLabel from './InvoiceStatusLabel'

const PaymentStatusCell = (item: BalanceListEntryItem) => {
  const PaymentTransactionState = useSelector(getPaymentTransactionState)
  const { t } = useTranslation(['Common', 'Invoices'])

  const ErrorType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.ERROR,
    PaymentTransactionState,
  )

  return (item as ExtendPayment).transactionState === ErrorType ? (
    <StateLabel warning variant="big">
      {t('Common:STATUS_FAILED')}
    </StateLabel>
  ) : item.type === INVOICE_CHARGE_SHEET_TYPE || R.has('invoiceNo', item) ? (
    <InvoiceStatusLabel invoice={item as InvoiceOrEstimate} />
  ) : (
    ''
  )
}

export default PaymentStatusCell
