import React, { ChangeEvent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid } from '@mui/material'
import * as R from 'ramda'
import {
  PuiTextField,
  useFields,
  ValidateOptions,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { LabProps, ZnLabCredentials } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../../practices'
import VendorFormContainer from '../VendorFormContainer'

const ZnLabsLab = function ZnLabsLab({
  credentials,
  business,
  name,
  initiallyActive,
  status,
  isTesting,
  vendorId,
  onToggle,
  onTest,
}: LabProps<ZnLabCredentials>) {
  const { t } = useTranslation('Common')

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'active',
        label: name,
        type: 'toggle',
        initialValue: initiallyActive || false,
      },
      {
        name: 'clinicIdentifier',
        label: t('Common:CLINIC_ID'),
        validators: ['required'],
        initialValue: credentials.clinicIdentifier || '',
      },
    ],
    false,
  )

  const { active, clinicIdentifier } = fields

  usePracticeFieldsSection({
    business,
    fields,
    sectionName:
      `${PracticeDetailsPanels.LAB_INTEGRATIONS}_ZNLABS` as PracticeDetailsPanels,
    parentSectionName: PracticeDetailsPanels.LAB_INTEGRATIONS,
    validate: (options?: ValidateOptions | undefined): boolean => {
      if (active.value) {
        return validate(options)
      }
      return true
    },
    reset,
    resetDependencies: [initiallyActive, business],
  })

  return (
    <>
      <PuiSwitch
        field={{
          ...active,
          set: (event: ChangeEvent<HTMLInputElement>) =>
            onToggle(event.target?.checked, () => active.set(event)),
        }}
        label={active.label}
      />
      {active?.value && (
        <>
          <Grid container direction="column" pb={3} pl={6}>
            <VendorFormContainer
              isTesting={isTesting}
              status={status}
              onTest={() =>
                onTest({
                  active: active.value,
                  clinicIdentifier: clinicIdentifier.value,
                  vendorId,
                })
              }
            >
              <Grid item md={6} px={1.5} py={1}>
                <PuiTextField
                  field={clinicIdentifier}
                  inputProps={{ maxLength: 100 }}
                  label={clinicIdentifier.label}
                />
              </Grid>
            </VendorFormContainer>
          </Grid>
          <Divider />
        </>
      )}
    </>
  )
}

export default memo(ZnLabsLab, (prevProps, nextProps) =>
  R.equals(prevProps, nextProps),
)
