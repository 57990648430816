import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { v4 as uuid } from 'uuid'
import { Nil } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { Document as DocumentType } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    button: {
      color: 'inherit',
      textTransform: 'initial',
      textDecoration: 'underline',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      padding: 0,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'ChewyPrescriptionPdfButton' },
)

interface ChewyPrescriptionPdfButtonProps {
  pdfUrl?: string | Nil
}

const ChewyPrescriptionPdfButton = ({
  pdfUrl,
}: ChewyPrescriptionPdfButtonProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  if (!pdfUrl) {
    return null
  }

  const openPdfPreview = () => {
    const document: DocumentType = {
      id: uuid(),
      name: 'Chewy Prescription',
      extension: 'pdf',
      fileUrl: pdfUrl,
    }

    openDocumentDialog({
      previewOnly: true,
      document,
      PreviewProps: {
        hideTopButtons: false,
        view: true,
        showChangeFile: false,
        isSoap: false,
      },
      step: DocumentDialogStates.PREVIEW,
    })
  }

  return (
    <Button className={classes.button} size="small" onClick={openPdfPreview}>
      {t('Common:VIEW_CHEWY_PDF')}
    </Button>
  )
}

export default ChewyPrescriptionPdfButton
