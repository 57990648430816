import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import { MigrationStepController } from '~/components/dashboard/admin/general/practices/PracticeDetailsSections/migrations/migrationV2/core-migration/migration-step/useMigrationStepController'
import { MigrationStatuses } from '~/constants/migrationV2'

import { MigrationConstant } from './constants'

export enum SupportedPims {
  Avimark = 'Avimark',
  Cornerstone = 'Cornerstone',
  ImproMed = 'ImproMed',
  IntraVet = 'IntraVet',
  Covetrus = 'Covetrus',
}

export enum RhapsodyExporterTypes {
  RE_AVIMARK = 'RE_AVIMARK',
  RE_CORNERSTONE = 'RE_CORNERSTONE',
  RE_IMPROMED_INFINITY = 'RE_IMPROMED_INFINITY',
  RE_INTRAVET_CLASSIC = 'RE_INTRAVET_CLASSIC',
}

export enum MigrationSessionType {
  DEFAULT = 'DEFAULT',
  RHAPSODY_ENTERPRISE = 'RHAPSODY_ENTERPRISE',
}

export enum MigrationSessionStage {
  NEW = 'NEW',
  EXPORT = 'EXPORT',
  CONVERSION = 'CONVERSION',
  REVIEW = 'REVIEW',
  ANALYSIS = 'ANALYSIS',
  IMPORT = 'IMPORT',
  DELTA = 'DELTA',
  DONE = 'DONE',
}

export type MigrationSession = {
  businessId: string
  creationDate: string
  exporterPimsId: string
  id: string
  importSessionGroupId: string
  modificationDate: string
  sourcePimsId: string
  stage: MigrationSessionStage
  type: MigrationSessionType
}

export type SessionsMap = Record<string, MigrationSession>

export type MigrationSessionSettings = {
  analyticsType: 'HYBRID' | 'BIGQUERY'
  covetrusEnabled: boolean
  covetrusInstallationId?: string
  covetrusPassword?: string
  covetrusUser?: string
  creationDate: string
  exporterDownloadUrl?: string
  exporterOptions: Record<string, string>
  exporterScheduleCron: string
  exporterTimeZone: string
  id: string
  importSessionId: string
  modificationDate: string
  preprocessorMultiClinicSite?: string
  preprocessorOptions: Record<string, string>
  timeZone: string
  vetterClinicId?: string
  vetterEnabled: boolean
}

export type MigrationSessionDetails = {
  business: {
    id: string
    name: string
  }
  session: MigrationSession
  settings: MigrationSessionSettings
  token: string
}

export enum MigrationImportStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  INTERRUPTED = 'INTERRUPTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum MigrationImportRequestSource {
  INVENTORY_MATCHER = 'INVENTORY_MATCHER',
  MANUAL = 'MANUAL',
  EXPORTER = 'EXPORTER',
}

export enum MigrationImportRequestType {
  FULL = 'FULL',
  DETLA = 'DETLA',
}

export type MigrationImportRequest = {
  creationDate: string
  id: string
  importSessionId: string
  modificationDate: string
  source: MigrationImportRequestSource
  sourceDataLocation: string
  status: MigrationImportStatus
  type: MigrationImportRequestType
}

export enum MigrationTaskType {
  CONVERSION = 'CONVERSION',
  ANALYSIS = 'ANALYSIS',
  IMPORT = 'IMPORT',
}

export type MigrationImportTask = {
  creationDate: string
  id: string
  importRequestId: string
  modificationDate: string
  status: MigrationImportStatus
  type: MigrationTaskType
}

export type MigrationEntity = {
  count?: number
  importedCount?: number
  name?: string
  status: MigrationStatuses
}

export type MigrationSupportedEntity = {
  enabledByDefault: boolean
  entity: string
  label: string
}

export type ProposedColumn = {
  groupColumn?: string
  id: string
  isGroupColumnSubTypes?: boolean
  name: string
  options?: MigrationConstant[] | string[]
  type: string
}

export type MigrationSessionFile = {
  exists?: boolean
  extension: string
  name: string
  raw?: string
}

export type StartMigrationSettings = {
  businessId: string
  importTimezone: string
  practiceTimezone?: string
}

export type MigrationPreprocessingStatus = {
  entities?: MigrationEntity[]
  status?: MigrationStatuses
}

export type ImportSettingsToEdit = ImportSettings & {
  covetrusEnabled: boolean
}

export type ImportSettings = {
  covetrusInstallationId: string
  covetrusPassword: string
  covetrusUser: string
}

export type NofixLog = {
  exceptionMessage: string
  lineNumber: number
}

export interface BaseMigrationDialogProps extends BasePuiDialogProps {
  businessId: string
}
export interface BaseMigrationProps {
  businessId: string
}

export interface BaseStepComponentProps {
  businessId: string
  onNext?: () => void
  stepController: MigrationStepController
}
