import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import { getStaticSoapWidget } from '~/store/reducers/constants'
import { SoapWidgetName } from '~/types'

import AttachmentsWidget from './attachments/AttachmentsWidget'
import ChargesWidget from './charges/ChargesWidget'
import EstimatesWidget from './estimates/EstimatesWidget'
import ClientSurveyWidget from './finalize-orders/ClientSurveyWidget'
import FinalizeOrdersWidget from './finalize-orders/FinalizeOrdersWidget'
import MarketplaceWidget from './marketplace/MarketplaceWidget'
import MedicalDischargeNotesWidget from './medical-discharge-notes/MedicalDischargeNotesWidget'
import { SoapProblemsWidgetProps } from './problems/SoapProblemsWidget'
import SoapProblemsWidgetWrapper from './problems/SoapProblemsWidgetWrapper'
import QuestionsWidget from './questions/QuestionsWidget'
import ReasonForVisitWidget from './reason-for-visit/ReasonForVisitWidget'
import RemindersWidget from './reminders/RemindersWidget'
import ToDoWidget from './to-do/ToDoWidget'
import SoapVitalsWidget from './vitals/SoapVitalsWidget'

const SoapWidgetsMap = {
  [SoapWidgetName.ATTACHMENTS_WIDGET]: AttachmentsWidget,
  [SoapWidgetName.CHARGES_WIDGET]: ChargesWidget,
  [SoapWidgetName.CLIENT_SURVEY]: ClientSurveyWidget,
  [SoapWidgetName.ESTIMATE_WIDGET]: EstimatesWidget,
  [SoapWidgetName.FINALIZE_ORDERS]: FinalizeOrdersWidget,
  [SoapWidgetName.MARKETPLACE_WIDGET]: MarketplaceWidget,
  [SoapWidgetName.NOTES_WIDGET]: MedicalDischargeNotesWidget,
  [SoapWidgetName.PROBLEMS_WIDGET]: SoapProblemsWidgetWrapper,
  [SoapWidgetName.QUESTIONS_WIDGET]: QuestionsWidget,
  [SoapWidgetName.REASON_FOR_VISIT_WIDGET]: ReasonForVisitWidget,
  [SoapWidgetName.REMINDERS_WIDGET]: RemindersWidget,
  [SoapWidgetName.TODO_WIDGET]: ToDoWidget,
  [SoapWidgetName.VITALS_WIDGET]: SoapVitalsWidget,
}

export interface SoapWidgetItemProps extends SoapProblemsWidgetProps {
  active: boolean
  marketplaceId: string | Nil
  soapWidgetId: string
}

const SoapWidgetItem = ({
  active,
  marketplaceId,
  soapWidgetId,
  onHideSoapRail,
}: SoapWidgetItemProps) => {
  const StaticSoapWidget = useSelector(getStaticSoapWidget)

  const currentWidget = Utils.findById(soapWidgetId, StaticSoapWidget)
  const Component = SoapWidgetsMap[currentWidget.name as SoapWidgetName]

  // MarketplaceWidget can be placed under Marketplace tab or in any other tab that has marketplaceId
  if (
    currentWidget.name === SoapWidgetName.MARKETPLACE_WIDGET ||
    marketplaceId
  ) {
    return <MarketplaceWidget active={active} id={marketplaceId} />
  }

  if (!Component) {
    return null
  }

  return <Component onHideSoapRail={onHideSoapRail} />
}

export default memo(SoapWidgetItem)
