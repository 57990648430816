import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PermissionArea } from '@pbt/pbt-ui-components'

import RemindersList from '~/components/dashboard/reminders/RemindersList'
import { getCRUDByArea } from '~/store/reducers/auth'

import RemindersSectionHeader from './RemindersSectionHeader'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'RemindersSection' },
)

export interface RemindersSectionProps {
  patientId: string | Nil
}

const RemindersSection = ({ patientId }: RemindersSectionProps) => {
  const classes = useStyles()
  const permissions = useSelector(getCRUDByArea(PermissionArea.REMINDERS))

  if (!permissions.read) {
    return null
  }

  return (
    <Grid container className={classes.root} direction="column">
      <RemindersSectionHeader patientId={patientId} />
      <Grid item>
        <RemindersList
          disableInitialAutoScroll
          useWindowScroll
          patientId={patientId}
        />
      </Grid>
    </Grid>
  )
}

export default RemindersSection
