import React from 'react'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'

import { GoPayment, Payment } from '~/api/graphql/generated/types'
import AccentLabel from '~/components/common/labels/AccentLabel'
import { PaymentLabel } from '~/constants/paymentTypes'
import {
  getPaymentStatusLabelName,
  getPaymentWidgetStatusLabel,
  isCredit,
  labelStyles,
} from '~/utils/paymentUtils'

const useStyles = makeStyles(
  (theme) => ({
    status: {
      width: 'fit-content',
      fontSize: '1.2rem',
    },
    refund: {
      backgroundColor: theme.colors.inactiveBackground,
      color: theme.colors.badgeColor,
    },
  }),
  { name: 'PaymentTableLabelCell' },
)

interface PaymentTableLabelCellProps {
  value: Payment | GoPayment
}

export const PaymentTableLabelCell = ({
  value: payment,
}: PaymentTableLabelCellProps) => {
  const classes = useStyles()
  const isCreditPayment = isCredit(payment.amount, payment.type?.name || '')
  const labels = getPaymentWidgetStatusLabel(isCreditPayment, payment)

  return (
    <Stack
      useFlexGap
      direction="row"
      flexWrap="wrap"
      py={0.5}
      spacing={{ xs: 1, sm: 1 }}
    >
      {labels.map((label) => {
        const name = getPaymentStatusLabelName[label]
        if (R.isNil(name)) {
          return null
        }
        const isRefund =
          label === PaymentLabel.Refund || label === PaymentLabel.Refunded
        const colorVariant = labelStyles[label]
        return (
          <AccentLabel
            className={classNames(
              classes.status,
              isRefund ? classes.refund : undefined,
            )}
            colorVariant={colorVariant}
            key={label}
            name={name}
          />
        )
      })}
    </Stack>
  )
}
