import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text, WellnessPlan } from '@pbt/pbt-ui-components'

import { getBenefitIsAccessToBoop } from '../../wellnessPlanUtils'
import WellnessPlanBenefitRow from './WellnessPlanBenefitRow'
import WellnessPlanPriceLabel from './WellnessPlanPriceLabel'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    planNameContainer: {
      borderRight: theme.constants.tableBorder,
    },
    planNameContainerDense: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    priceTextDense: {
      color: theme.colors.tabLabel,
    },
    planNameText: {
      fontSize: '1.4rem',
    },
    benefitRow: {
      flex: 1,
    },
    benefitRowDense: {
      padding: theme.spacing(0.5, 2),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableBackground,
      },
    },
  }),
  { name: 'WellnessPlanReviewTablePlanRow' },
)

export interface WellnessPlanReviewTablePlanRowProps {
  dense?: boolean
  plan: WellnessPlan
  showPrice?: boolean
}

const WellnessPlanReviewTablePlanRow = ({
  dense,
  plan,
  showPrice,
}: WellnessPlanReviewTablePlanRowProps) => {
  const classes = useStyles()

  const benefits = plan.benefits.filter(
    (benefit) => !getBenefitIsAccessToBoop(benefit),
  )

  return (
    <Grid
      container
      item
      className={classes.root}
      direction={dense ? 'column' : 'row'}
      key={plan.level}
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems={dense ? 'center' : 'stretch'}
        className={classNames(classes.planNameContainer, {
          [classes.planNameContainerDense]: dense,
        })}
        direction={dense ? 'row' : 'column'}
        justifyContent={dense ? 'space-between' : 'flex-start'}
        px={2}
        py={dense ? 0.75 : 2}
        xs={dense ? true : 3}
      >
        <Text strong className={classes.planNameText} variant="subheading2">
          {plan.name}
        </Text>
        {showPrice && (
          <WellnessPlanPriceLabel
            className={classNames({
              [classes.priceTextDense]: dense,
            })}
            mt={dense ? 0 : 1}
            plan={plan}
            variant="body2"
          />
        )}
      </Grid>
      <Grid container item direction="column">
        {benefits.map((benefit) => (
          <WellnessPlanBenefitRow
            benefit={benefit}
            className={classNames(classes.benefitRow, {
              [classes.benefitRowDense]: dense,
            })}
            key={`${benefit.id}-${benefit.globalBenefitId}`}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default WellnessPlanReviewTablePlanRow
