import React from 'react'
import { useSelector } from 'react-redux'

import { getSpecies } from '~/store/reducers/constants'
import { getConstantsStringWithAll } from '~/utils'

export type SpeciesCellItem = {
  speciesId?: string
  speciesIds?: string[]
}

const SpeciesCell = (item: SpeciesCellItem) => {
  const Species = useSelector(getSpecies)

  return (
    <>{getConstantsStringWithAll(item.speciesIds || item.speciesId, Species)}</>
  )
}

export default SpeciesCell
