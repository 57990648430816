import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Nil, Text } from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import { getUnmatchedPetsIds } from '~/store/duck/chewyPets'

import { ChewyPetMenuItem } from './ChewyPetMenuItem'

const useStyles = makeStyles(
  () => ({
    select: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      margin: '0 !important',
    },
  }),
  { name: 'ChewyPetSelect' },
)

interface ChewyPetSelectProps {
  defaultId: string | Nil
  id: string | Nil
  onSelect: (patientId: string) => void
}

export const ChewyPetSelect = ({
  id,
  defaultId,
  onSelect,
}: ChewyPetSelectProps) => {
  const { t } = useTranslation('Dialogs')
  const classes = useStyles()
  const patientsIds = useSelector(getUnmatchedPetsIds)
  const IconComponent = useCallback(
    () => (
      <InputAdornment position="end">
        <Toggle />
      </InputAdornment>
    ),
    [],
  )

  return (
    <FormControl fullWidth>
      <Select
        disableUnderline
        displayEmpty
        IconComponent={IconComponent}
        classes={{
          select: classes.select,
        }}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <Text strong variant="body2">
                {t('Dialogs:MATCH_PET_PATIENT_DIALOG.CHOOSE_A_PET')}
              </Text>
            )
          }
          return <ChewyPetMenuItem patientId={selected} />
        }}
        value={id || ''}
        onChange={(event) => {
          onSelect(event.target.value!)
        }}
      >
        <MenuItem key="no-match" value="">
          <Text py={1.5}>{t('Dialogs:MATCH_PET_PATIENT_DIALOG.NO_MATCH')}</Text>
        </MenuItem>
        <Divider className={classes.divider} />
        {defaultId && (
          <MenuItem key={defaultId} value={defaultId}>
            <Box py={1.5}>
              <ChewyPetMenuItem patientId={defaultId} />
            </Box>
          </MenuItem>
        )}
        {defaultId && <Divider className={classes.divider} />}

        <Text pt={1} px={2} variant="body4">
          {t('Dialogs:MATCH_PET_PATIENT_DIALOG.NO_MATCH_FOUND')}
        </Text>
        {patientsIds.map((patientId) => (
          <MenuItem key={patientId} value={patientId}>
            <ChewyPetMenuItem patientId={patientId} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
