import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import { TimetableEvent } from '~/types'

import { getAppointmentWarningLabel } from '../../../timetable/timetableUtils'

const useStyles = makeStyles(
  (theme) => ({
    warningMessage: {
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(0, 0.5),
      backgroundColor: theme.colors.important,
      color: theme.colors.profileText,
      borderRadius: 2,
      fontSize: '1rem',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'AppointmentFullStatusCell' },
)

interface AppointmentFullStatusCellProps {
  item: TimetableEvent
}

const AppointmentFullStatusCell = ({
  item: appointment,
}: AppointmentFullStatusCellProps) => {
  const classes = useStyles()

  const warningMessage = getAppointmentWarningLabel({
    from: appointment?.scheduledStartDatetime,
    to: appointment?.scheduledEndDatetime,
    stateName: appointment?.state?.name,
  })

  const showWarning = Boolean(warningMessage)

  return (
    <Grid container alignItems="center">
      <AppointmentStateLabel item={appointment} variant="small" />
      {showWarning && (
        <Text className={classes.warningMessage}>{warningMessage}</Text>
      )}
    </Grid>
  )
}

export default AppointmentFullStatusCell
