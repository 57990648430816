import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

const NoPermissionPage = () => {
  const { t } = useTranslation('Common')

  return (
    <Grid item p={1}>
      {t('Common:NO_PERMISSION_PAGE_DESCRIPTION')}
    </Grid>
  )
}

export default NoPermissionPage
