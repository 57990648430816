import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { matchImageExtension } from '~/constants/extensions'
import { SoapFile } from '~/types'

import FilePlaceholder from './FilePlaceholder'

const useStyles = makeStyles(
  () => ({
    smallImage: {
      width: 79,
      height: 53,
    },
    largeImage: {
      width: 160,
      height: 104,
    },
    longImage: {
      width: 72,
      height: 48,
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
  { name: 'AttachmentPreview' },
)

export interface AttachmentPreviewProps {
  attachment: SoapFile
  variant?: 'small' | 'large' | 'long'
}

const AttachmentPreview = ({
  variant = 'small',
  attachment,
}: AttachmentPreviewProps) => {
  const classes = useStyles()

  const { extension = '', fileUrl } = attachment
  const isImage = matchImageExtension(extension)

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames({
        [classes.smallImage]: variant === 'small',
        [classes.largeImage]: variant === 'large',
        [classes.longImage]: variant === 'long',
      })}
      justifyContent="center"
    >
      {isImage ? (
        <img alt="" className={classes.image} src={fileUrl} />
      ) : (
        <FilePlaceholder variant={variant} />
      )}
    </Grid>
  )
}

export default AttachmentPreview
