import React from 'react'
import {
  CircularProgress,
  Divider,
  IconButton,
  IconButtonProps,
  Stack,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { StackProps } from '@mui/system'
import classNames from 'classnames'
import * as R from 'ramda'
import { TextWithTooltip } from '@pbt/pbt-ui-components'
import {
  AddNote,
  Decline as DeclineIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Eye as EyeIcon,
  Print as PrintIcon,
  RX as RXIcon,
} from '@pbt/pbt-ui-components/src/icons'

import DisableIconButtonWithTooltip from './DisableIconButtonWithTooltip'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    badgeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    editIcon: {
      fontSize: '2rem',
    },
    declineInactiveIcon: {
      opacity: theme.constants.inactiveOpacity,
    },
    icon: {
      padding: 0,
    },
    noteIcon: {
      color: theme.colors.searchButton,
    },
    noteFilledIcon: {
      color: theme.colors.editIconColor,
    },
  }),
  { name: 'ItemWithBadgesActions' },
)

export interface ItemWithBadgesActionsProps {
  align?: StackProps['justifyContent']
  declineDisabled?: boolean
  declined?: boolean
  editDisabled?: boolean
  hasNotes?: boolean
  hideDivider?: boolean
  isPrepaidAndUsedZero?: boolean
  loading?: boolean
  onAddRX?: () => void
  onDecline?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onPrint?: () => void
  onShare?: () => void
  onUpdateNotes?: () => void
  onView?: () => void
  permissions?: any
  readOnly?: boolean
  size?: IconButtonProps['size']
  tooltip?: React.ReactNode
}

const ItemWithBadgesActions = ({
  align = 'flex-end',
  declined,
  declineDisabled,
  editDisabled,
  hasNotes,
  hideDivider = false,
  loading = false,
  onAddRX,
  onDecline,
  onDelete,
  onEdit,
  onPrint,
  onShare,
  onUpdateNotes,
  onView,
  readOnly,
  size = 'medium',
  tooltip,
  permissions,
  isPrepaidAndUsedZero,
}: ItemWithBadgesActionsProps) => {
  const classes = useStyles()

  const permissionsObject =
    R.isNil(permissions) || R.isEmpty(permissions)
      ? {
          Edit: {
            shouldRender: Boolean(onEdit) && !readOnly && !isPrepaidAndUsedZero,
            disabled: editDisabled,
          },
          View: {
            shouldRender: Boolean(onView),
            disabled: editDisabled,
          },
          EditRX: {
            shouldRender:
              Boolean(onAddRX) && !readOnly && !isPrepaidAndUsedZero,
            disabled: editDisabled,
          },
          Print: {
            shouldRender: Boolean(onPrint),
            disabled: false,
          },
          Delete: {
            shouldRender: Boolean(onDelete) && !readOnly,
            disabled: editDisabled,
          },
          Decline: {
            shouldRender: Boolean(onDecline),
            disabled: editDisabled || declineDisabled,
          },
          Info: {
            shouldRender: Boolean(tooltip),
            disable: false,
          },
        }
      : permissions

  const { Edit, EditRX, Print, Delete, View, Decline, Info } = permissionsObject

  return (
    <Stack
      direction="row"
      divider={
        !hideDivider && (
          <Divider
            flexItem
            orientation="vertical"
            sx={{ height: 16, alignSelf: 'center' }}
          />
        )
      }
      justifyContent={align}
      spacing={0.5}
    >
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <>
          {View?.shouldRender && (
            <div className={classes.badgeWrapper}>
              <IconButton
                className={classes.icon}
                disabled={View.disabled}
                id="item-with-badges-actions-view"
                size={size}
                onClick={onView}
              >
                <EyeIcon
                  className={classNames({
                    [classes.editIcon]: size !== 'large',
                  })}
                  fontSize={size}
                />
              </IconButton>
            </div>
          )}
          {EditRX?.shouldRender && (
            <div className={classes.badgeWrapper}>
              <IconButton
                className={classes.icon}
                disabled={EditRX.disabled}
                id="item-with-badges-actions-rx"
                size={size}
                onClick={onAddRX}
              >
                <RXIcon
                  className={classNames({
                    [classes.editIcon]: size !== 'large',
                  })}
                  fontSize={size}
                />
              </IconButton>
            </div>
          )}
          {Edit?.shouldRender && (
            <div className={classes.badgeWrapper}>
              <IconButton
                className={classes.icon}
                disabled={Edit.disabled}
                id="item-with-badges-actions-edit"
                size={size}
                onClick={onEdit}
              >
                <EditIcon
                  className={classNames({
                    [classes.editIcon]: size !== 'large',
                  })}
                  fontSize={size}
                />
              </IconButton>
            </div>
          )}
          {onShare && (
            <div className={classes.badgeWrapper}>
              <IconButton
                className={classes.icon}
                id="item-with-badges-actions-eye"
                size={size}
                onClick={onShare}
              >
                <EyeIcon fontSize={size} />
              </IconButton>
            </div>
          )}
          {Print?.shouldRender && (
            <div className={classes.badgeWrapper}>
              <IconButton
                className={classes.icon}
                disabled={Print.disabled}
                id="item-with-badges-actions-print"
                size={size}
                onClick={onPrint}
              >
                <PrintIcon fontSize={size} />
              </IconButton>
            </div>
          )}
          {Info?.shouldRender && (
            <div className={classes.badgeWrapper}>
              <TextWithTooltip
                IconProps={{
                  sx: { margin: 'none' },
                  fontSize: size,
                }}
                TooltipProps={{
                  tooltipPlacement: 'top-end',
                }}
                tooltipText={tooltip}
              />
            </div>
          )}
          {Delete?.shouldRender && (
            <div className={classes.badgeWrapper}>
              {Delete.disabled ? (
                <DisableIconButtonWithTooltip
                  Icon={DeleteIcon}
                  size={size}
                  title={Delete.tooltip}
                />
              ) : (
                <IconButton
                  className={classes.icon}
                  data-testid="item-with-badges-actions-delete"
                  disabled={Delete.disabled}
                  size={size}
                  onClick={onDelete}
                >
                  <DeleteIcon fontSize={size} />
                </IconButton>
              )}
            </div>
          )}
          {onUpdateNotes && !readOnly && (
            <div className={classes.badgeWrapper}>
              <IconButton
                className={classes.icon}
                disabled={editDisabled}
                id="item-with-badges-actions-add-note"
                size={size}
                onClick={onUpdateNotes}
              >
                <AddNote filled={hasNotes} fontSize={size} />
              </IconButton>
            </div>
          )}
          {Decline?.shouldRender && (
            <div className={classes.badgeWrapper}>
              {Decline.disabled ? (
                <DisableIconButtonWithTooltip
                  Icon={DeclineIcon}
                  size={size}
                  title={Decline.tooltip}
                />
              ) : (
                <IconButton
                  className={classNames(classes.icon, {
                    [classes.declineInactiveIcon]: !declined,
                  })}
                  disabled={Decline.disabled}
                  id="item-with-badges-actions-decline"
                  size={size}
                  onClick={onDecline}
                >
                  <DeclineIcon fontSize={size} />
                </IconButton>
              )}
            </div>
          )}
        </>
      )}
    </Stack>
  )
}

export default ItemWithBadgesActions
