import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import * as R from 'ramda'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessChatEnabled } from '~/store/reducers/auth'
import { getFeatureToggle, getFeatureToggles } from '~/store/reducers/constants'

import RCIFrameContainer from './RCIFrameContainer'

const RCPage = () => {
  const currentBusinessChatEnabled = useSelector(getCurrentBusinessChatEnabled)
  const featureToggles = useSelector(getFeatureToggles)
  const isRCIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_INTEGRATION),
  )
  const isRCPhase2IntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_PHASE_2_INTEGRATION),
  )

  if (
    (isRCPhase2IntegrationEnabled && currentBusinessChatEnabled) ||
    isRCIntegrationEnabled
  ) {
    return <RCIFrameContainer />
  }

  return !R.isEmpty(featureToggles) ? <Navigate replace to="/" /> : null
}

export default RCPage
