import { ContentBlock, ContentState } from 'draft-js'
import { SignatureView } from '@pbt/pbt-ui-components'

export const BLOCK_TYPE_SIGNATURE = 'SIGNATURE'

const findSignatureEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === BLOCK_TYPE_SIGNATURE
    )
  }, callback)
}

export default {
  strategy: findSignatureEntities,
  component: SignatureView,
}
