import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import {
  Nil,
  PermissionArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandle, Prescription } from '~/types'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

export interface AdministrationSectionSimpleProps {
  onRefChange?: () => void
  prescription: Prescription
}

export interface AdministrationSectionSimpleHandle
  extends DataHandle<{ notes?: string | Nil }> {}

const AdministrationSectionSimple = forwardRef<
  AdministrationSectionSimpleHandle,
  AdministrationSectionSimpleProps
>(function AdministrationSectionSimple({ prescription, onRefChange }, ref) {
  const { t } = useTranslation('Common')

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))

  const getFields = (obj: Prescription) => [
    {
      name: 'administrationInstructions',
      label: t('Common:NOTES'),
      initialValue: obj.notes,
    },
  ]

  const {
    fields: { administrationInstructions },
    reset,
    validate,
  } = useFields(getFields(prescription), false)

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [administrationInstructions])

  useEffectExceptOnMount(() => {
    reset(getFields(prescription))
  }, [prescription])

  useImperativeHandle(ref, () => ({
    get: () => ({ notes: administrationInstructions.value }),
    validate,
  }))

  return (
    <Grid container>
      <PuiTextField
        multiline
        disabled={!permissions.update}
        field={administrationInstructions}
        inputProps={{ maxLength: 1000 }}
        margin="none"
        minRows={2}
        placeholder={administrationInstructions.label}
        variant="outlined"
      />
    </Grid>
  )
})

export const copyAdministrationSimpleInstruction = ({
  notes,
}: Prescription) => ({ notes })

export default AdministrationSectionSimple
