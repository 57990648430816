import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, Text, Utils } from '@pbt/pbt-ui-components'
import {
  DefaultAvatar,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

import Avatar from '~/components/common/Avatar'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getUser } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme) => ({
    defaultAvatar: {
      fontSize: '5rem',
    },
    defaultAvatarBackground: {
      backgroundColor: '#EDECEC',
    },
    avatar: {
      width: 72,
      height: 72,
    },
    nameContainer: {
      overflow: 'hidden',
      paddingLeft: theme.spacing(3),
    },
    clientNameText: {
      wordBreak: 'break-word',
      fontSize: '2.6rem',
      lineHeight: '30px',
      [theme.breakpoints.down('md')]: {
        color: theme.colors.title,
      },
      color: theme.colors.profileText,
    },
    editIcon: {
      marginLeft: 10,
      color: theme.colors.profileText,
      [theme.breakpoints.down('md')]: {
        color: theme.colors.title,
      },
      padding: theme.spacing(1),
    },
  }),
  { name: 'ClientMainDetails' },
)

interface ClientMainDetailsProps {
  clientId: string
  onEdit: (event: React.MouseEvent) => void
}

const ClientMainDetails = ({ clientId, onEdit }: ClientMainDetailsProps) => {
  const classes = useStyles()
  const client = useSelector(getUser(clientId)) || {}
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))

  return (
    <>
      <Grid item>
        <Avatar
          alt="userpic"
          className={classes.avatar}
          classes={{ childrenBackgroundColor: classes.defaultAvatarBackground }}
          person={client}
          size="normal"
        >
          <DefaultAvatar className={classes.defaultAvatar} />
        </Avatar>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-start"
        className={classes.nameContainer}
        wrap="nowrap"
      >
        <Dotdotdot clamp={2}>
          <Text strong align="left" className={classes.clientNameText} pt={0.5}>
            {Utils.getPersonString(client)}
          </Text>
        </Dotdotdot>
        {permissions.update && (
          <IconButton
            className={classes.editIcon}
            size="large"
            onClick={onEdit}
          >
            <EditIcon />
          </IconButton>
        )}
      </Grid>
    </>
  )
}

export default ClientMainDetails
