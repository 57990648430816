import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BaseRole, LanguageUtils, Text } from '@pbt/pbt-ui-components'
import IconLabelButton from '@pbt/pbt-ui-components/src/components/buttons/IconLabelButton'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { fetchGroupRoles, updateGroupRoles } from '~/store/actions/roles'
import {
  getGroupRoles,
  getMultipleRoles,
  getRolesIsLoading,
} from '~/store/reducers/roles'
import { BasePracticeDetailsSectionProps } from '~/types'
import { isPracticeAdminRole } from '~/utils/roleUtils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    buttonContainer: {
      height: '100%',
      marginTop: theme.spacing(1),
    },
    buttonText: {
      fontWeight: 500,
    },
    buttonIcon: {
      margin: theme.spacing(0, 1, 0, 0),
    },
    heading: {
      margin: theme.spacing(1, 0, 0, 1),
    },
    listItem: {
      margin: theme.spacing(0.5, 0, 0, 1),
    },
  }),
  { name: 'GroupRolesAutoAssignSection' },
)

const GroupRolesAutoAssignSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const selectedRoleIds = useSelector(getGroupRoles(business.id))
  const selectedRoles = useSelector(getMultipleRoles(selectedRoleIds || []))
  const isLoading = useSelector(getRolesIsLoading)

  const [openRolesDialog] = useDialog(DialogNames.ROLES)

  const handleUpdateRoles = (
    businessId: string,
    roles: string[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isGroup?: boolean,
  ) => {
    dispatch(updateGroupRoles(businessId, roles))
  }

  const handleAddRoles = () => {
    openRolesDialog({
      isGroup: true,
      businessId: business?.id,
      selectedRoleList: selectedRoleIds.map((roleId: string) => ({
        businessId: business.id,
        roleId,
        isGroup: true,
      })),
      onSave: handleUpdateRoles,
      saveLabel: t('Common:UPDATE_ACTION'),
      title: t('Admin:PRACTICE.GROUP_ROLES_AUTO_ASSIGN.UPDATE_ROLES'),
    })
  }

  useEffect(() => {
    dispatch(fetchGroupRoles(business.id))
  }, [business.id])

  const [analyticsRoles, pimsRoles] = R.partition(
    R.propEq('rhapsodyAnalytics', true),
    selectedRoles,
  )

  const renderRole = (role: BaseRole) => (
    <Grid item className={classes.listItem} key={role.id}>
      <Text>
        {isPracticeAdminRole(role)
          ? t('Common:GROUP_ADMINISTATOR')
          : LanguageUtils.getTranslatedFieldName(role)}
      </Text>
    </Grid>
  )

  const renderSkeletons = () => (
    <Grid item className={classes.listItem}>
      <Skeleton variant="text" width={100} />
    </Grid>
  )

  return (
    <Grid>
      <Text>{t('Admin:PRACTICE.GROUP_ROLES_AUTO_ASSIGN.ROLES_LIST')}</Text>
      <Grid container direction="row">
        {(pimsRoles.length > 0 || isLoading) && (
          <Grid container item direction="column" xs={6}>
            <Text strong className={classes.heading} variant="subheading3">
              {t('Admin:PRACTICE.GROUP_ROLES_AUTO_ASSIGN.PIMS_ROLES')}
            </Text>
            {isLoading ? renderSkeletons() : pimsRoles?.map(renderRole)}
          </Grid>
        )}
        {analyticsRoles.length > 0 && (
          <Grid container item direction="column" xs={6}>
            <Text strong className={classes.heading} variant="subheading3">
              {t('Admin:PRACTICE.GROUP_ROLES_AUTO_ASSIGN.ANALYTICS_ROLES')}
            </Text>
            {analyticsRoles?.map(renderRole)}
          </Grid>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        className={classes.buttonContainer}
        justifyContent="flex-end"
      >
        {!isLoading && (
          <IconLabelButton
            Icon={EditIcon}
            classes={{
              label: classes.buttonText,
              iconContainer: classes.buttonIcon,
            }}
            label={
              selectedRoles.length > 0
                ? t('Admin:PRACTICE.GROUP_ROLES_AUTO_ASSIGN.UPDATE_ROLES')
                : t('Common:ADD_ROLES')
            }
            onClick={handleAddRoles}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default memo(GroupRolesAutoAssignSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
