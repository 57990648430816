import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import { getLastUser } from '~/store/reducers/journal'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

const IdpIssApiSessionMap: Record<string, string> = {
  'https://chewy-test.oktapreview.com': '/api/v1/sessions/me',
  'https://chewy.okta.com': '/api/v1/sessions/me',
}

const SignInComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const lastUser = useSelector(getLastUser)

  const { loginWithRedirect, logout } = useAuth0()
  const { isAuthenticated, isAuthenticating } = useIsAuthenticated()

  const iss = new URLSearchParams(location.search).get('iss')

  const loginWithRedirectByLoginHint = (login_hint?: string) => {
    loginWithRedirect({
      authorizationParams: {
        login_hint,
      },
    })
  }

  useEffect(() => {
    if (isAuthenticating) {
      return
    }

    if (iss && IdpIssApiSessionMap[iss]) {
      if (isAuthenticated) {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}${location.pathname}${location.search}`,
          },
        })
        return
      }

      fetch(`${iss}${IdpIssApiSessionMap[iss]}`, {
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('IDP API Exception')
          }
          return response.json()
        })
        .then((data) => {
          const sessionLoginHint = data.login
          loginWithRedirectByLoginHint(sessionLoginHint)
        })
        .catch(() => {
          loginWithRedirectByLoginHint()
        })
    } else {
      if (isAuthenticated) {
        navigate('/')
        return
      }
      loginWithRedirectByLoginHint(lastUser?.email)
    }
  }, [isAuthenticating, isAuthenticated, loginWithRedirect, lastUser, iss])

  return <CircularProgressOverlay open />
}

export default SignInComponent
