import React from 'react'
import { DateUtils, Patient } from '@pbt/pbt-ui-components'

import { getPatientAge } from '~/utils'

export const getDOBLabelString = (patient: Patient) => {
  const dateOfBirth = DateUtils.formatDate(patient.dateOfBirth, true)
  const age = getPatientAge(patient)

  const deceasedDate = patient.deceasedDate
    ? ` - ${DateUtils.formatDate(patient.deceasedDate, true)}`
    : ''

  return dateOfBirth ? `${age} (${dateOfBirth}${deceasedDate})` : ''
}

export interface PatientDOBLabelProps {
  patient?: Patient
}

const PatientDOBLabel = ({ patient }: PatientDOBLabelProps) => {
  if (!patient) {
    return null
  }

  return (
    <>
      {DateUtils.formatDate(patient.dateOfBirth, true)}
      {patient.deceasedDate &&
        ` - ${DateUtils.formatDate(patient.deceasedDate, true)}`}
    </>
  )
}

export default PatientDOBLabel
