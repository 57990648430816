import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, RoleName, Text, Utils } from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import RoleMemberLabel from '~/components/common/labels/RoleMemberLabel'
import {
  useGetAppointmentResponsibilities,
  useGetAppointmentStaffRoles,
} from '~/store/hooks/timetable'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getResponsibilities } from '~/store/reducers/constants'
import { getRolesMap } from '~/store/reducers/roles'
import {
  getAppointmentId,
  getDoctorId,
  getRoom,
  getSoapStaffInfo,
  getTechId,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import {
  TimetableEventPersonResponsibility,
  TimetableEventPersonRole,
} from '~/types'

import { getRolePropertyOfSoap } from '../../appointment/appointmentMemberUtils'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'Appointment' },
)

export interface AppointmentProps {
  className?: string
}

const Appointment = ({ className }: AppointmentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const soapStaffInfo = useSelector(getSoapStaffInfo)
  const room = useSelector(getRoom)
  const doctorId = useSelector(getDoctorId)
  const techId = useSelector(getTechId)
  const doctor = useSelector(getUser(doctorId))
  const tech = useSelector(getUser(techId))

  const mainStaffRolesList = useMainStaffRoles()
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const rolesMap = useSelector(getRolesMap)
  const responsibilities = useSelector(getResponsibilities)

  const doctorRoleId = Utils.findConstantIdByName(
    RoleName.Veterinarian,
    mainStaffRolesList,
  )
  const techRoleId = Utils.findConstantIdByName(
    RoleName.VetTech,
    mainStaffRolesList,
  )

  const doctorResponsibilityId = Utils.findConstantIdByName(
    ResponsibilityName.Veterinarian,
    responsibilities,
  )

  const techResponsibilityId = Utils.findConstantIdByName(
    ResponsibilityName.VetTech,
    responsibilities,
  )

  const personRoles = appointmentId
    ? appointment?.personRoles || []
    : ([
        doctor && {
          person: doctor,
          roleId: doctorRoleId,
        },
        tech && {
          person: tech,
          roleId: techRoleId,
        },
      ].filter(Boolean) as TimetableEventPersonRole[])

  const personResponsibilities = appointmentId
    ? appointment?.personResponsibilities || []
    : ([
        doctor && {
          person: doctor,
          responsibilityId: doctorResponsibilityId,
        },
        tech && {
          person: tech,
          responsibilityId: techResponsibilityId,
        },
      ].filter(Boolean) as TimetableEventPersonResponsibility[])

  const getPersonIdFromPersonRoles = (roleId: string) =>
    personRoles.find((personRole) => personRole.roleId === roleId)?.personId

  const getPersonIdFromPersonResponsibilities = (responsibilityId: string) =>
    personResponsibilities.find(
      (personResponsibility) =>
        personResponsibility.responsibilityId === responsibilityId,
    )?.personId

  const soapsAmount = appointment?.soaps?.length || 0
  const appointmentTypeId = appointment?.type?.id
  const multipleSoaps = soapsAmount > 1

  const staffRoles = useGetAppointmentStaffRoles(appointmentTypeId)
  const apptResponsibilities =
    useGetAppointmentResponsibilities(appointmentTypeId)

  const shouldUseResponsibilities =
    appointment?.personResponsibilities &&
    appointment.personResponsibilities.length > 0

  return (
    <Grid item className={classNames(classes.root, className)}>
      {(personRoles.length > 0 || personResponsibilities.length > 0) &&
        appointmentId && (
          <Text variant="body">{t('Common:ASSIGNED_TO_APPOINTMENT')}:</Text>
        )}
      {shouldUseResponsibilities
        ? personResponsibilities.map(({ person, responsibilityId }) => {
            const displayRoleName = LanguageUtils.getTranslatedFieldName(
              responsibilities.find(
                (responsibility) => responsibility.id === responsibilityId,
              ),
            )

            return (
              <Text key={responsibilityId} pl={appointmentId ? 2 : 0}>
                {displayRoleName}: {Utils.getPersonString(person)}
              </Text>
            )
          })
        : personRoles.map(({ person, roleId }) => {
            const displayRoleName = LanguageUtils.getTranslatedFieldName(
              rolesMap[roleId],
            )

            return (
              <Text key={roleId} pl={appointmentId ? 2 : 0}>
                {displayRoleName}: {Utils.getPersonString(person)}
              </Text>
            )
          })}
      {multipleSoaps && (
        <>
          <Text variant="body2">{t('Common:ASSIGNED_TO_SOAP')}:</Text>
          {shouldUseResponsibilities
            ? apptResponsibilities.map((responsibility) => (
                <RoleMemberLabel
                  key={responsibility.id}
                  personId={getPersonIdFromPersonResponsibilities(
                    responsibility.id,
                  )}
                  roleName={LanguageUtils.getTranslatedFieldName(
                    responsibility,
                  )}
                />
              ))
            : staffRoles.map((role) => (
                <RoleMemberLabel
                  key={role.id}
                  personId={
                    getRolePropertyOfSoap(soapStaffInfo, role.name) ||
                    getPersonIdFromPersonRoles(role.id)
                  }
                  roleName={LanguageUtils.getTranslatedFieldName(role)}
                />
              ))}
        </>
      )}
      {room && (
        <Text variant="body2">
          {t('Common:ROOM')}: {room}
        </Text>
      )}
    </Grid>
  )
}

export default Appointment
