import { gql } from '@apollo/client/core'

import { PRESCRIPTION_FIELDS } from '../fragments'

export const SAVE_REACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation SaveReactiveRx($prescriptionId: ID!, $input: RxInput) {
    saveReactiveRx(prescriptionId: $prescriptionId, input: $input) {
      ...PrescriptionFields
    }
  }
`

export const APPROVE_REACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation ApproveReactiveRx($prescriptionId: ID!, $input: RxInput) {
    approveReactiveRx(prescriptionId: $prescriptionId, input: $input) {
      ...PrescriptionFields
    }
  }
`

export const DECLINE_REACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation DeclineReactiveRx($prescriptionId: ID!, $input: RxInput) {
    declineReactiveRx(prescriptionId: $prescriptionId, input: $input) {
      ...PrescriptionFields
    }
  }
`

export const LINK_REACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation LinkReactiveRx(
    $prescriptionId: ID!
    $clientId: ID!
    $patientId: ID!
  ) {
    linkReactiveRx(
      prescriptionId: $prescriptionId
      clientId: $clientId
      patientId: $patientId
    ) {
      ...PrescriptionFields
    }
  }
`

export const SAVE_ACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation SaveActiveRx($prescriptionId: ID, $input: RxInput!) {
    saveActiveRx(prescriptionId: $prescriptionId, input: $input) {
      ...PrescriptionFields
    }
  }
`

export const APPROVE_ACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation ApproveActiveRx($prescriptionId: ID, $input: RxInput!) {
    approveActiveRx(prescriptionId: $prescriptionId, input: $input) {
      ...PrescriptionFields
    }
  }
`

export const SUBMIT_ACTIVE_RX_MUTATION = gql`
  ${PRESCRIPTION_FIELDS}
  mutation SubmitActiveRx($prescriptionId: ID, $input: RxInput!) {
    submitActiveRx(prescriptionId: $prescriptionId, input: $input) {
      ...PrescriptionFields
    }
  }
`

export const DELETE_ACTIVE_RX_MUTATION = gql`
  mutation DeleteActiveRx($prescriptionId: ID!) {
    deleteActiveRx(prescriptionId: $prescriptionId)
  }
`

export const DECLINE_ACTIVE_RX = gql`
  ${PRESCRIPTION_FIELDS}
  mutation DeclineActiveRx($prescriptionId: ID!) {
    declineActiveRx(prescriptionId: $prescriptionId) {
      ...PrescriptionFields
    }
  }
`

export const UNDECLINE_ACTIVE_RX = gql`
  ${PRESCRIPTION_FIELDS}
  mutation UndeclineActiveRx($prescriptionId: ID!) {
    undeclineActiveRx(prescriptionId: $prescriptionId) {
      ...PrescriptionFields
    }
  }
`

export const SUBMIT_ALL_ACTIVE_RX_FOR_SOAP = gql`
  mutation SubmitAllActiveRxForSoap($soapId: ID!) {
    submitAllActiveRxForSoap(soapId: $soapId)
  }
`

export const SUBMIT_ALL_ACTIVE_RX_FOR_CHARGE_SHEET = gql`
  mutation SubmitAllActiveRxForChargeSheet($businessId: ID!, $clientId: ID!) {
    submitAllActiveRxForChargeSheet(
      businessId: $businessId
      clientId: $clientId
    )
  }
`
