import { ColorVariant } from './colors'

export enum ShipmentStatus {
  ORDERED = 'Ordered',
  DRAFT = 'Draft',
  PARTIAL = 'Partially received',
  RECEIVED = 'Received',
  CANCELLED = 'Cancelled',
}

export enum ShipmentItemStatus {
  BACKORDERED = 'Back-ordered',
  ORDERED = 'Ordered',
  PARTIAL = 'Partially received',
  RECEIVED = 'Received',
  CANCELLED = 'Cancelled',
  DRAFT = 'Draft',
}

export const ShipmentStatusToShipmentItemStatusMatch: Record<
  ShipmentStatus,
  ShipmentItemStatus
> = {
  [ShipmentStatus.ORDERED]: ShipmentItemStatus.ORDERED,
  [ShipmentStatus.DRAFT]: ShipmentItemStatus.DRAFT,
  [ShipmentStatus.PARTIAL]: ShipmentItemStatus.PARTIAL,
  [ShipmentStatus.RECEIVED]: ShipmentItemStatus.RECEIVED,
  [ShipmentStatus.CANCELLED]: ShipmentItemStatus.CANCELLED,
}

export const ShipmentStatusColor = {
  [ShipmentStatus.CANCELLED]: ColorVariant.DISABLED,
  [ShipmentStatus.RECEIVED]: ColorVariant.SUCCESS,
  [ShipmentStatus.PARTIAL]: ColorVariant.IMPORTANT,
  [ShipmentStatus.DRAFT]: ColorVariant.IMPORTANT,
}

export const ShipmentItemStatusColor = {
  [ShipmentItemStatus.CANCELLED]: ColorVariant.DISABLED,
  [ShipmentItemStatus.RECEIVED]: ColorVariant.SUCCESS,
  [ShipmentItemStatus.BACKORDERED]: ColorVariant.IMPORTANT,
  [ShipmentItemStatus.PARTIAL]: ColorVariant.IMPORTANT,
  [ShipmentItemStatus.DRAFT]: ColorVariant.IMPORTANT,
}
