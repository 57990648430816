import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import {
  DateFormat,
  DateUtils,
  LanguageUtils,
  Text,
} from '@pbt/pbt-ui-components'

import ColorPinIcon from '~/components/common/icons/ColorPinIcon'
import { TimetableEvent } from '~/types'

interface AppointmentDateCellProps {
  item?: TimetableEvent
}

const AppointmentDateCell = ({ item }: AppointmentDateCellProps) => {
  const color = item?.businessAppointmentType?.color

  const name = LanguageUtils.getTranslatedFieldName(
    item?.businessAppointmentType,
  )

  const date = DateUtils.formatDate(
    item?.scheduledStartDatetime,
    undefined,
    DateFormat.FULL_DATE_TIME_WITH_PIPE,
  ).replace('|', '-')

  return (
    <Grid container item alignItems="center" wrap="nowrap">
      <ColorPinIcon color={color} mr={1} tooltipText={name} />
      <Dotdotdot clamp={1}>
        <Text strong variant="body2">
          {date}
        </Text>
      </Dotdotdot>
    </Grid>
  )
}

export default AppointmentDateCell
