import { useSelector } from 'react-redux'

import { getNewVersionAvailable } from '~/store/duck/serviceWorker'

import { useNavigatePrevState } from './useNavigatePrevState'

const useReloadRedirect = () => {
  const navigate = useNavigatePrevState()
  const newVersionAvailable = useSelector(getNewVersionAvailable)

  return (url: string) => {
    if (newVersionAvailable) {
      document.location.assign(url)
    } else {
      navigate(url)
    }
  }
}

export default useReloadRedirect
