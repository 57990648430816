import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { Defaults, LanguageUtils } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import GenderCell from '~/components/common/lists/primitive-table/cells/GenderCell'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import {
  fetchMoreItemsForProceduresList,
  fetchProceduresList,
  setFilters,
} from '~/store/actions/procedures'
import {
  getMultipleProcedures,
  getProceduresFilters,
  getProceduresIsLoading,
  getProceduresList,
  getProceduresTotalCount,
} from '~/store/reducers/procedures'
import { Procedure, TableFilter } from '~/types'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

import ProcedureDetails from './ProcedureDetails'
import ProceduresCategoryFilter from './ProceduresCategoryFilter'

export interface ProceduresTableComponentProps {
  headerButtons: React.ReactNode
  onDetailsClose: () => void
  procedureId?: string
}

const ProceduresTableComponent = ({
  procedureId,
  headerButtons,
  onDetailsClose,
}: ProceduresTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const isLoading = useSelector(getProceduresIsLoading)
  const list = useSelector(getProceduresList)
  const procedures = useSelector(getMultipleProcedures(list))
  const totalCount = useSelector(getProceduresTotalCount)
  const filters = useSelector(getProceduresFilters)

  const { t } = useTranslation(['Admin', 'Common'])

  const columns = [
    {
      label: t('Common:DESCRIPTION'),
      prop: LanguageUtils.getTranslatedFieldName,
      width: 6,
      highlight: true,
    },
    {
      label: t('Common:CATEGORY'),
      filter: 'category',
      prop: (column: Procedure) =>
        LanguageUtils.getTranslatedFieldName(column, 'category'),
      width: 4,
      FilterComponent: ProceduresCategoryFilter,
    },
    {
      label: t('Common:SPECIES'),
      prop: SpeciesCell,
      width: 4,
    },
    {
      label: t('Common:GENDER'),
      prop: GenderCell,
      width: 2,
    },
    {
      label: t('Common:ACTIVE_ONE'),
      prop: (item: Procedure) => boolToYesNoString(item.priced),
      width: 2,
    },
  ]

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchProceduresList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search, filters])

  const navigateToProcedure = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/procedures/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(procedures[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForProceduresList(startIndex, endIndex, search))
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setFilters({ ...filters, [filter]: value }))
  }

  const getDisabled = (item: Procedure) => !item.priced

  return (
    <ExpandableTable
      Expander={ProcedureDetails}
      hasSelectedFilters={!R.isEmpty(filters)}
      headerButtons={headerButtons}
      isLoading={isLoading}
      itemId={procedureId}
      list={procedures}
      searchTerm={search}
      title={t('Admin:CATALOG.PROCEDURES_TABLE_COMPONENT.PROCEDURE_CATALOG')}
      onClose={onDetailsClose}
      onSelected={navigateToProcedure}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
      />
    </ExpandableTable>
  )
}

export default ProceduresTableComponent
