import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { DymoLabelType } from '~/constants/dymo'
import { DymoLabelProps, HtmlLabelProps } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_DYMO_LABEL_XML = 'labelPrinting/FETCH_DYMO_LABEL_XML'
export const FETCH_DYMO_LABEL_XML_SUCCESS =
  'labelPrinting/FETCH_DYMO_LABEL_XML_SUCCESS'
export const FETCH_DYMO_LABEL_XML_FAILURE =
  'labelPrinting/FETCH_DYMO_LABEL_XML_FAILURE'

export const FETCH_HTML_LABEL = 'labelPrinting/FETCH_HTML_LABEL'
export const FETCH_HTML_LABEL_SUCCESS = 'labelPrinting/FETCH_HTML_LABEL_SUCCESS'
export const FETCH_HTML_LABEL_FAILURE = 'labelPrinting/FETCH_HTML_LABEL_FAILURE'

export const fetchDymoLabelXML = (id: string) => ({
  type: FETCH_DYMO_LABEL_XML,
  id,
})
export const fetchDymoLabelXMLSuccess = (
  id: string,
  props: DymoLabelProps,
) => ({
  type: FETCH_DYMO_LABEL_XML_SUCCESS,
  id,
  props,
})
export const fetchDymoLabelXMLFailure = (error: ApiError) => ({
  type: FETCH_DYMO_LABEL_XML_FAILURE,
  error,
})

export const fetchHtmlLabel = (
  id: string,
  deviceType: string,
  labelType: DymoLabelType,
) => ({
  type: FETCH_HTML_LABEL,
  id,
  deviceType,
  labelType,
})
export const fetchHtmlLabelSuccess = (
  id: string,
  deviceType: string,
  labelType: DymoLabelType,
  props: HtmlLabelProps,
) => ({ type: FETCH_HTML_LABEL_SUCCESS, id, deviceType, labelType, props })
export const fetchHtmlLabelFailure = (error: ApiError) => ({
  type: FETCH_HTML_LABEL_FAILURE,
  error,
})

export type LabelPrintingState = {
  dymoLabelIsLoading: boolean
  dymoLabelsMap: Record<string, DymoLabelProps>
  error: string | null
  htmlLabelsIsLoading: boolean
  htmlLabelsMap: Record<string, Record<string, HtmlLabelProps>>
}

export const INITIAL_STATE: LabelPrintingState = {
  dymoLabelsMap: {},
  dymoLabelIsLoading: false,
  htmlLabelsMap: {},
  htmlLabelsIsLoading: false,
  error: null,
}

export const labelPrintingReducer = (
  state: LabelPrintingState = INITIAL_STATE,
  action: AnyAction,
): LabelPrintingState => {
  switch (action.type) {
    case FETCH_DYMO_LABEL_XML:
      return { ...state, dymoLabelIsLoading: true, error: null }
    case FETCH_DYMO_LABEL_XML_SUCCESS:
      return {
        ...state,
        dymoLabelsMap: { ...state.dymoLabelsMap, [action.id]: action.props },
        dymoLabelIsLoading: false,
        error: null,
      }
    case FETCH_DYMO_LABEL_XML_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        dymoLabelIsLoading: false,
      }
    case FETCH_HTML_LABEL:
      return { ...state, htmlLabelsIsLoading: true, error: null }
    case FETCH_HTML_LABEL_SUCCESS:
      return {
        ...state,
        htmlLabelsMap: {
          ...state.htmlLabelsMap,
          [action.id]: {
            ...(state.htmlLabelsMap[action.id] || {}),
            [action.labelType]: action.props,
          },
        },
        htmlLabelsIsLoading: false,
        error: null,
      }
    case FETCH_HTML_LABEL_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        htmlLabelsIsLoading: false,
      }
    default:
      return state
  }
}

export const getLabelPrinting = (state: RootState): LabelPrintingState =>
  state.labelPrinting
export const getDymoLabelIsLoading = (state: RootState) =>
  getLabelPrinting(state).dymoLabelIsLoading
export const getDymoLabelsMap = (state: RootState) =>
  getLabelPrinting(state).dymoLabelsMap
export const getDymoLabelProps = (id: string | Nil) =>
  createSelector(getDymoLabelsMap, (map) => (id ? map[id] : undefined))
export const getHtmlLabelIsLoading = (state: RootState) =>
  getLabelPrinting(state).htmlLabelsIsLoading
export const getHtmlLabelMap = (state: RootState) =>
  getLabelPrinting(state).htmlLabelsMap
export const getHtmlLabelProps = R.curry(
  (id, labelType, state) => getHtmlLabelMap(state)[id]?.[labelType],
)
export const getLabelPrintError = (state: RootState) =>
  getLabelPrinting(state).error

export function* fetchDymoLabelXMLSaga({
  id,
}: ReturnType<typeof fetchDymoLabelXML>) {
  try {
    const props = yield* requestAPI(API.fetchDymoLabelXML, id)
    yield put(fetchDymoLabelXMLSuccess(id, props))
  } catch (error) {
    yield put(fetchDymoLabelXMLFailure(error as ApiError))
  }
}

export function* fetchHtmlLabelSaga({
  id,
  deviceType,
  labelType,
}: ReturnType<typeof fetchHtmlLabel>) {
  try {
    const props = yield* requestAPI(
      API.fetchHtmlLabel,
      id,
      deviceType,
      labelType,
    )
    yield put(fetchHtmlLabelSuccess(id, deviceType, labelType, props))
  } catch (error) {
    yield put(fetchHtmlLabelFailure(error as ApiError))
  }
}

function* watchFetchDymoLabelXML() {
  yield takeLatest(FETCH_DYMO_LABEL_XML, fetchDymoLabelXMLSaga)
}

function* watchFetchHtmlLabelSaga() {
  yield takeLatest(FETCH_HTML_LABEL, fetchHtmlLabelSaga)
}

export function* labelPrintingSaga() {
  yield all([watchFetchDymoLabelXML(), watchFetchHtmlLabelSaga()])
}
