import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, SelectChangeEvent } from '@mui/material'
import { PuiSelect, SelectEntity, Text } from '@pbt/pbt-ui-components'

import { NumberStepper } from '~/components/elements/NumberStepper/NumberStepper'
import { ExpirationPeriod, ExpirationPeriodSpecifier } from '~/types'

export function getPeriodQuantity(period: ExpirationPeriod): number {
  return Number(period.match(/\d+/))
}

export function getPeriodSpecifier(
  period: ExpirationPeriod,
): ExpirationPeriodSpecifier {
  return period.charAt(period.length - 1) as ExpirationPeriodSpecifier
}

export function updatePeriodQuantity(
  period: ExpirationPeriod,
  quantity: number,
): ExpirationPeriod {
  return period.replace(/\d+/, `${quantity}`) as ExpirationPeriod
}

export function updatePeriodSpecifier(
  period: ExpirationPeriod,
  specifier: ExpirationPeriodSpecifier,
): ExpirationPeriod {
  return period.replace(/Y|M|D/, specifier) as ExpirationPeriod
}

const PeriodSpecifierOptions: SelectEntity[] = [
  {
    id: 'Y',
    name: 'Year(s)',
  },
  {
    id: 'M',
    name: 'Month(s)',
  },
  {
    id: 'D',
    name: 'Day(s)',
  },
]

export interface ExpirationPeriodInputProps {
  minimum?: number
  onChange: (value: ExpirationPeriod) => void
  value: ExpirationPeriod
}

export function ExpirationPeriodInput({
  value,
  onChange,
  minimum = 1,
}: ExpirationPeriodInputProps) {
  const { t } = useTranslation(['Admin'])
  const handleNumberChange = (newValue: number) => {
    const newPeriod = updatePeriodQuantity(
      value,
      newValue < minimum ? minimum : newValue,
    )
    onChange(newPeriod)
  }

  const handleSpecifierChange = (e: SelectChangeEvent<unknown>) => {
    const newPeriod = updatePeriodSpecifier(
      value,
      e.target.value as ExpirationPeriodSpecifier,
    )
    onChange(newPeriod)
  }

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <NumberStepper
          value={getPeriodQuantity(value)}
          onChange={(newValue) => handleNumberChange(Number(newValue))}
        />
      </Grid>
      <Grid item>
        <PuiSelect
          items={PeriodSpecifierOptions}
          value={getPeriodSpecifier(value)}
          onChange={handleSpecifierChange}
        />
      </Grid>
      <Grid item>
        <Text>{t('Admin:CATALOG.DOCUMENT.AFTER_COMPLETION')}</Text>
      </Grid>
    </Grid>
  )
}
