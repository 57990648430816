import { gql } from '@apollo/client'

export const PAYMENT_FIELDS = gql`
  fragment PaymentFields on Payment {
    __typename
    id
    clientId
    createdAt
    creationDate
    cardType {
      id
      name
    }
    lastFour
    amount
    posTxId
    goTxId
    refundableAmount
    reversed
    undone
    split
    splitAmount
    signedAmount
    signedSplitAmount
    paidByPersonName
    paidByPersonId
    paidByPerson {
      id
      firstName
      lastName
    }
    employee {
      id
      firstName
      lastName
    }
    serviceFeeIncAmount
    splitServiceFeeIncAmount
    method {
      id
      name
    }
    type {
      id
      name
    }
    unappliedAmount
    links {
      paidAmountNoFee
      invoice {
        __typename
        ... on Invoice {
          id
          patients {
            id
            name
          }
        }
        ... on RefundInvoice {
          id
          patients {
            id
            name
          }
        }
        ... on Estimate {
          id
          name
          patient {
            id
            name
          }
        }
      }
      invoiceNo
    }
  }
`

export const ALL_PAYMENT_ENTITIES_PAGE_FIELDS = gql`
  ${PAYMENT_FIELDS}
  fragment AllPaymentEntitiesPageFields on PaymentEntityPage {
    data {
      ...PaymentFields
      ... on GoPayment {
        __typename
        id
        creationDate
        cardType {
          id
          name
        }
        amount
        reversed
        transactionStateName
        employee {
          id
          firstName
          lastName
        }
        method {
          id
          name
        }
        type {
          id
          name
        }
      }
    }
    totalCount
  }
`

export const PAYMENT_PAGE_BILLING_ACTIVITY = gql`
  ${PAYMENT_FIELDS}
  fragment BalancePaymentPageFields on PaymentPage {
    data {
      ...PaymentFields
    }
    totalCount
  }
`
