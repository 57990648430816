import { RoleName } from '@pbt/pbt-ui-components'

export const StaffRoleWhitelist = new Set([
  RoleName.Veterinarian,
  RoleName.VetTech,
  RoleName.VetAssistant,
])

export const VCRRoles = new Set([RoleName.VCR])

// TODO: this is temporally solution - eventually should be removed in favour of discount.edit permission
export const discountEditRoleBlacklist = [
  RoleName.VCR,
  RoleName.CWAV,
  RoleName.ClientConcierge,
]

export default RoleName
