import React from 'react'
import { useSelector } from 'react-redux'

import { getDialogIds } from '~/store/duck/dialogs'

import DialogWrapper from './DialogWrapper'

const DialogsManager = () => {
  const dialogIds = useSelector(getDialogIds)

  return (
    <>
      {dialogIds.map((id) => (
        <DialogWrapper id={id} key={id} />
      ))}
    </>
  )
}

export default DialogsManager
