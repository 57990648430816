import {
  Defaults,
  Nil,
  UnsavedWellnessPlanVersion,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  WellnessPlanBoopSignUpMessageContext,
  WellnessPlanCancellationData,
  WellnessPlanSettings,
  WellnessPlanWelcomeEmailContext,
} from '~/types'

import { getPreludeAPIPath, request } from './core'
import { MutationAutoRenewalWellnessPlanArgs } from './graphql/generated/types'
import { requestMutation } from './graphql/graphqlCore'
import { TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN } from './graphql/mutations/wellnessPlans'
import * as Schema from './schemas'

export const fetchWellnessPlansSettings = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getPreludeAPIPath(`wplans/settings/${businessId}`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
    Schema.wellnessPlanBusinessSettings,
  )

export const editWellnessPlansSettings = (
  currentBusinessId: string,
  businessId: string,
  settings: WellnessPlanSettings,
) =>
  request(
    getPreludeAPIPath(`wplans/settings/${businessId}`),
    {
      method: 'PUT',
      data: settings,
      params: { businessId: currentBusinessId },
    },
    true,
  )

export const fetchWellnessPlans = (
  currentBusinessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
  businessId: string | Nil,
) =>
  request(
    getPreludeAPIPath('wplans/version'),
    {
      method: 'GET',
      params: { businessId: businessId || currentBusinessId, from, to, query },
    },
    true,
    Schema.wellnessPlansVersionsList,
  )

export const fetchWellnessPlanVersion = (
  businessId: string,
  wellnessPlanVersionId: string,
  withGlobalBenefits?: boolean,
) =>
  request(
    getPreludeAPIPath(`wplans/version/${wellnessPlanVersionId}`),
    { method: 'GET', params: { businessId, withGlobalBenefits } },
    true,
    Schema.wellnessPlanVersion,
  )

export const fetchActiveWellnessPlanVersion = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getPreludeAPIPath('wplans/version/active'),
    { method: 'GET', params: { businessId: businessId || currentBusinessId } },
    true,
    Schema.wellnessPlanVersion,
  )

export const createWellnessPlanVersion = (
  businessId: string,
  wellnessPlanVersion: UnsavedWellnessPlanVersion,
) =>
  request(
    getPreludeAPIPath('wplans/version'),
    { method: 'POST', data: wellnessPlanVersion, params: { businessId } },
    true,
    Schema.wellnessPlanVersion,
  )

export const editWellnessPlanVersion = (
  businessId: string,
  wellnessPlanVersion: WellnessPlanVersion,
) =>
  request(
    getPreludeAPIPath('wplans/version'),
    { method: 'POST', data: wellnessPlanVersion, params: { businessId } },
    true,
    Schema.wellnessPlanVersion,
  )

export const deleteWellnessPlanVersion = (
  businessId: string,
  wellnessPlanVersionId: string,
) =>
  request(
    getPreludeAPIPath(`wplans/version/${wellnessPlanVersionId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchWellnessPlanVersionTemplate = (
  businessId: string,
  cloneFromId?: string,
) =>
  request(
    getPreludeAPIPath('wplans/version/new'),
    { method: 'GET', params: { businessId, cloneFromId } },
    true,
  )

// eslint-disable-next-line
export const fetchWellnessPlansCatalogFilters = (businessId: string) =>
  Promise.resolve([])

export const fetchWellnessPlansCatalogItems = (
  businessId: string,
  categories: string[],
  listType: string,
  // eslint-disable-next-line
  entityTypes: string[] = [],
  query: string,
  // eslint-disable-next-line
  from: number = 0,
  // eslint-disable-next-line
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  Promise.resolve({
    data: [],
    totalCount: 0,
  })

// eslint-disable-next-line
export const fetchWellnessPlan = (businessId: string, wellnessPlanId: string) =>
  Promise.resolve()

export const fetchWellnessPlanGlobalBenefitGroups = (businessId: string) =>
  request(
    getPreludeAPIPath('wplans/globalBenefit/groups'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const fetchWellnessPlanSignUpToken = (
  businessId: string,
  clientId: string,
) =>
  request(
    getPreludeAPIPath('wplans/signUpToken'),
    { method: 'GET', params: { businessId, clientId } },
    true,
  )

export const cancelWellnessPlansOld = (
  businessId: string,
  clientId: string,
  patientId: string,
  planLogIds: string[],
  reason?: string,
  notes?: string,
  confirmToken?: string,
) =>
  request(
    getPreludeAPIPath('wplans/membership/v2/cancel'),
    {
      method: 'POST',
      data: {
        reason: { reason, notes },
        membershipIds: planLogIds,
      },
      params: {
        businessId,
        clientId,
        patientId,
        confirmToken,
      },
    },
    true,
    Schema.patientMembership,
  )

export const fetchWpCancellationInfo = (
  businessId: string,
  clientId: string | Nil,
  patientId: string | Nil,
  cancelId: string,
) =>
  request(
    getPreludeAPIPath('wplans/membership/v3/cancel'),
    { method: 'GET', params: { businessId, clientId, patientId, cancelId } },
    true,
  )

export const cancelWellnessPlan = (
  businessId: string,
  clientId: string,
  patientId: string,
  cancelData: WellnessPlanCancellationData,
  confirmToken?: string,
) => {
  const {
    cancellationAmount,
    cancellationType,
    creditOrWriteOffAmount,
    reason,
    cancelId,
  } = cancelData
  return request(
    getPreludeAPIPath('wplans/membership/v3/cancel'),
    {
      method: 'POST',
      params: { businessId, clientId, patientId, confirmToken },
      data: {
        cancellationAmount,
        cancellationType,
        creditOrWriteOffAmount,
        reason,
        cancelId,
      },
    },
    true,
    Schema.patientMembership,
  )
}

export const createWellnessPlanPaymentMethod = (
  businessId: string,
  clientId: string,
  currencyId: string,
) =>
  request(
    getPreludeAPIPath('wplans/payment-methods'),
    { method: 'POST', data: { clientId, currencyId }, params: { businessId } },
    true,
  )

export const getWellnessPlanPaymentMethod = (businessId: string, id: string) =>
  request(
    getPreludeAPIPath(`wplans/payment-methods/${id}`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const replaceWellnessPlanPaymentMethod = (
  businessId: string,
  membershipId: string,
  paymentMethodId: string,
) =>
  request(
    getPreludeAPIPath(
      `wplans/membership/replace-payment-method/${membershipId}`,
    ),
    { method: 'PUT', params: { paymentMethodId, businessId } },
    true,
    Schema.patientMembership,
  )

export const fetchWellnessPlanSavings = (
  businessId: string,
  clientId: string,
) =>
  request(
    getPreludeAPIPath(`wplans/cover-options/client/${clientId}/saved`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const fetchWellnessPlanWelcomeTemplate = (
  businessId: string,
  templateText: string,
) =>
  request(
    getPreludeAPIPath('wplans/communications/v1/previewTemplate'),
    { method: 'POST', params: { businessId }, data: { templateText } },
    true,
  )

export const fetchWellnessPlanSignUpInBoopTemplate = (
  businessId: string,
  templateText: string,
) =>
  request(
    getPreludeAPIPath('wplans/communications/v1/boopSignUp/previewTemplate'),
    { method: 'POST', params: { businessId }, data: { templateText } },
    true,
  )

export const fetchWellnessPlanSignUpInBoopDefaultMessage = (
  businessId: string,
  data: WellnessPlanBoopSignUpMessageContext,
) =>
  request(
    getPreludeAPIPath('wplans/communications/v1/boopSignUp/previewMessage'),
    { method: 'POST', params: { businessId }, data },
    true,
  )

export const fetchCurrentWellnessPlanWelcomeTemplate = (businessId: string) =>
  request(
    getPreludeAPIPath('wplans/communications/v1/templateSample'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const sendWellnessPlanWelcomeMessage = (
  businessId: string,
  data: WellnessPlanWelcomeEmailContext,
) =>
  request(
    getPreludeAPIPath('wplans/communications/v1/boop/welcomeMessage'),
    { method: 'POST', params: { businessId }, data },
    true,
  )

export const sendWellnessPlanDefaultWelcomeMessage = (
  businessId: string,
  clientId: string,
  data: any,
) =>
  request(getPreludeAPIPath('wplans/communications/v1/welcomeMessage'), {
    method: 'POST',
    params: { businessId, clientId },
    data,
  })

export const sendWellnessPlanDefaultWelcomeMessageV2 = (
  businessId: string,
  data: {
    clientId: string
    email: string
    patientWPlanIdPairs: { patientId: string; planId: string }[]
  },
) =>
  request(getPreludeAPIPath('wplans/communications/v2/welcomeMessage'), {
    method: 'POST',
    params: { businessId },
    data,
  })

export const fetchWellnessPlanSavingsDataForSoap = (
  businessId: string,
  appointmentId: string,
) =>
  request(
    getPreludeAPIPath('wplans/cover-options/today/coverage/details'),
    { method: 'GET', params: { businessId, appointmentId } },
    true,
  )

export const fetchWellnessPlanSavingsDataForInvoice = (
  businessId: string,
  invoiceId: string,
) =>
  request(
    getPreludeAPIPath(
      `wplans/cover-options/invoice/${invoiceId}/coverage/details`,
    ),
    { method: 'GET', params: { businessId } },
    true,
  )

export const toggleAutoRenewalPlan = requestMutation({
  mutation: TOGGLE_AUTO_RENEWAL_WELLNESS_PLAN,
  variablesHandler: (_: string, args: MutationAutoRenewalWellnessPlanArgs) =>
    args,
})
