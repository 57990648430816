import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

import EmergencyContact, { EmergencyContactHandle } from './EmergencyContact'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'EmergencyContactDialog' },
)

export interface EmergencyContactDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
}

const EmergencyContactDialog = ({
  open,
  clientId,
  onClose,
}: EmergencyContactDialogProps) => {
  const classes = useStyles()
  const { emergencyContact } = useSelector(getUser(clientId)) || {}
  const { t } = useTranslation('Clients')

  const emergencyContactRef = useRef<EmergencyContactHandle>(null)

  const isEdit = emergencyContact && Object.keys(emergencyContact).length > 0

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => emergencyContactRef.current?.save(),
      }}
      aria-labelledby="emergency-contact-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        Boolean(emergencyContactRef.current?.getHasUnsavedChanges())
      }
      open={open}
      title={
        isEdit
          ? t('Clients:EMERGENCY_CONTACT.TITLE_EDIT')
          : t('Clients:EMERGENCY_CONTACT.TITLE_NEW')
      }
      onClose={onClose}
    >
      <EmergencyContact
        clientId={clientId}
        ref={emergencyContactRef}
        onOk={onClose}
      />
    </PuiDialog>
  )
}

export default EmergencyContactDialog
