import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import i18n from '~/locales/i18n'
import {
  fetchMoreReminderProtocolGroups,
  fetchReminderProtocolGroups,
  getMultipleReminderProtocolGroups,
  getReminderProtocolGroupsList,
  getReminderProtocolsIsFetchingList,
  getReminderProtocolsTotalCount,
  getReminderProtocolValidationError,
} from '~/store/duck/reminderProtocols'
import { ReminderProtocolGroup } from '~/types'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'
import useErrorAlert from '~/utils/useErrorAlert'

import NoReminderProtocolGroupsScreen from './NoReminderProtocolGroupsScreen'
import ReminderProtocolGroupDetails from './ReminderProtocolGroupDetails'

const columns = [
  {
    label: i18n.t('Common:NAME'),
    highlight: true,
    prop: 'name',
    width: 6,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    prop: (item: ReminderProtocolGroup) => boolToYesNoString(item.active),
    width: 12,
  },
]

const getDisabled = (item: ReminderProtocolGroup) => !item.active

export interface ReminderProtocolGroupsTableComponentProps {
  groupId?: string
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
}

const ReminderProtocolGroupsTableComponent = ({
  headerButtons,
  groupId,
  onDetailsClose,
}: ReminderProtocolGroupsTableComponentProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isFetchingList = useSelector(getReminderProtocolsIsFetchingList)
  const list = useSelector(getReminderProtocolGroupsList)
  const totalCount = useSelector(getReminderProtocolsTotalCount)
  const reminderProtocols = useSelector(getMultipleReminderProtocolGroups(list))
  const { t } = useTranslation('Common')

  const search = getUrlSearchParam('query', location.search)

  useErrorAlert({
    errorSelector: getReminderProtocolValidationError,
    onOpenHandler: () => {
      navigate(addSearch(location, '/admin/catalog/reminder-protocols'))
    },
    onCloseHandler: () => {
      dispatch(
        fetchReminderProtocolGroups(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          search,
        ),
      )
    },
  })

  useEffect(() => {
    dispatch(
      fetchReminderProtocolGroups(
        0,
        Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        search,
      ),
    )
  }, [search])

  const navigateToReminderProtocol = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/reminder-protocols/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(reminderProtocols[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreReminderProtocolGroups(startIndex, endIndex, search))
  }

  return (
    <ExpandableTable
      Expander={ReminderProtocolGroupDetails}
      NoItemsScreen={NoReminderProtocolGroupsScreen}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={groupId}
      searchTerm={search}
      title={t('Common:REMINDER_PROTOCOLS')}
      onClose={onDetailsClose}
      onSelected={navigateToReminderProtocol}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        list={reminderProtocols}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default ReminderProtocolGroupsTableComponent
