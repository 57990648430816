import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton, PermissionArea } from '@pbt/pbt-ui-components'

import { ChewyPet, PetProfileMatch } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import { getPetMatchData, linkPets } from '~/store/duck/chewyPets'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getPatient } from '~/store/reducers/patients'
import useDialog from '~/utils/useDialog'

interface ConnectChewyPetProps {
  className?: string
  clientId: string
  onOpenDialog?: () => void
  patientId: string
}

const ConnectChewyPet = ({
  clientId,
  patientId,
  className,
  onOpenDialog,
}: ConnectChewyPetProps) => {
  const { t } = useTranslation('Tooltips')
  const dispatch = useDispatch()
  const [openDialog, closeDialog] = useDialog(
    DialogNames.LINK_INDIVIDUAL_PATIENT_DIALOG,
  )
  const patient = useSelector(getPatient(patientId))
  const { update: connectChewyAccountUpdatePermission } = useSelector(
    getCRUDByArea(PermissionArea.CONNECT_CHEWY_ACCOUNT),
  )

  const submitHandler = (selectedPet: ChewyPet) => {
    const petProfileMatch: PetProfileMatch = {
      chewyPet: {
        name: selectedPet.name,
      },
      rhapsodyPatient: {
        rhapsodyId: patientId,
        name: patient!.name,
      },
    }

    dispatch(linkPets({ clientId, petPatientMatches: [petProfileMatch] }))
    closeDialog()
  }

  const handleOpenDialog = () => {
    onOpenDialog?.()
    dispatch(getPetMatchData({ clientId }))
    // future call to get petProfiles that are currently unmated for the client
    openDialog({ clientId, patientId, submitHandler })
  }

  return (
    <LinkButton
      className={className}
      disabled={!connectChewyAccountUpdatePermission}
      onClick={
        connectChewyAccountUpdatePermission ? handleOpenDialog : undefined
      }
    >
      {t('Tooltips:CONNECT_CHEWY_ACCOUNT')}
    </LinkButton>
  )
}

export default ConnectChewyPet
