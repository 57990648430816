import { Defaults } from '@pbt/pbt-ui-components'

import { BenchmarkingKey } from '~/types'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchBenchmarkingItems = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  sortBy: string,
  desc: boolean,
  state?: string,
  includeDeactivated?: boolean,
) =>
  request(
    getPreludeAPIPath('keychain/onboarding/key/all'),
    {
      method: 'GET',
      params: {
        businessId,
        from,
        to,
        sortBy,
        desc,
        state,
        includeDeactivated,
      },
    },
    true,
    Schema.benchmarkingKeyList,
  )

export const createBenchmarkingKey = (
  businessId: string,
  item: Partial<BenchmarkingKey>,
) =>
  request(
    getPreludeAPIPath('keychain/onboarding/key/generate'),
    { method: 'POST', data: item, params: { businessId } },
    true,
    Schema.benchmarkingKey,
  )

export const editBenchmarkingKey = (
  businessId: string,
  item: Partial<BenchmarkingKey>,
) =>
  request(
    getPreludeAPIPath('keychain/onboarding/key/edit'),
    { method: 'PUT', data: item, params: { businessId } },
    true,
    Schema.benchmarkingKey,
  )
