import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

export type AssignedCellItem = {
  assigned: string
}

const AssignedCell = (item: AssignedCellItem) => {
  const user = useSelector(getUser(item.assigned))
  const { t } = useTranslation('Common')

  return user ? Utils.getPersonString(user) : t('Common:UNASSIGNED')
}

export default AssignedCell
