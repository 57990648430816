import { AnyAction } from 'redux'

import { PartNumber } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  CLEAR_VARIATION_CALCULATED_COST,
  CLEAR_VARIATION_PART_NUMBER_DETAILS,
  CREATE_VARIATION,
  CREATE_VARIATION_FAILURE,
  CREATE_VARIATION_SUCCESS,
  CREATE_VARIATIONS,
  CREATE_VARIATIONS_FAILURE,
  CREATE_VARIATIONS_SUCCESS,
  DELETE_VARIATION,
  DELETE_VARIATION_FAILURE,
  DELETE_VARIATION_SUCCESS,
  FETCH_VARIATION_CALCULATED_COST,
  FETCH_VARIATION_CALCULATED_COST_FAILURE,
  FETCH_VARIATION_CALCULATED_COST_SUCCESS,
  FETCH_VARIATION_PART_NUMBER_DETAILS,
  FETCH_VARIATION_PART_NUMBER_DETAILS_FAILURE,
  FETCH_VARIATION_PART_NUMBER_DETAILS_SUCCESS,
  PARTIAL_UPDATE_VARIATION,
  UPDATE_VARIATION,
  UPDATE_VARIATION_FAILURE,
  UPDATE_VARIATION_SUCCESS,
} from '../actions/types/variations'
import type { RootState } from '../index'

export type VariationsState = {
  calculatedCostsMap: Record<string, Record<string, number | null>>
  error: null | string
  isLoadingCosts: boolean
  isLoadingPartNumberDetails: boolean
  isSending: boolean
  partNumberDetails: PartNumber[]
  validationError: null | String
}
export const VARIATIONS_INITIAL_STATE: VariationsState = {
  isSending: false,
  isLoadingCosts: false,
  isLoadingPartNumberDetails: false,
  calculatedCostsMap: {},
  partNumberDetails: [],
  error: null,
  validationError: null,
}

const variations = (
  state: VariationsState = VARIATIONS_INITIAL_STATE,
  action: AnyAction,
): VariationsState => {
  switch (action.type) {
    case CREATE_VARIATION:
      return { ...state, isSending: true, error: null }
    case CREATE_VARIATION_SUCCESS:
      return { ...state, isSending: false, error: null }
    case CREATE_VARIATION_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_VARIATIONS:
      return { ...state, isSending: true, error: null }
    case CREATE_VARIATIONS_SUCCESS:
      return { ...state, isSending: false, error: null }
    case CREATE_VARIATIONS_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_VARIATION:
      return { ...state, isSending: true, error: null, validationError: null }
    case UPDATE_VARIATION_SUCCESS:
      return { ...state, isSending: false }
    case UPDATE_VARIATION_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case PARTIAL_UPDATE_VARIATION:
      return { ...state, isSending: true, error: null, validationError: null }
    case DELETE_VARIATION:
      return { ...state, isSending: true }
    case DELETE_VARIATION_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_VARIATION_SUCCESS:
      return { ...state, isSending: false }
    case FETCH_VARIATION_CALCULATED_COST:
      return { ...state, calculatedCostsMap: {}, isLoadingCosts: true }
    case FETCH_VARIATION_CALCULATED_COST_SUCCESS:
      return {
        ...state,
        calculatedCostsMap: action.calculatedCostsMap,
        isLoadingCosts: false,
      }
    case FETCH_VARIATION_CALCULATED_COST_FAILURE:
      return {
        ...state,
        isLoadingCosts: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_VARIATION_CALCULATED_COST:
      return { ...state, calculatedCostsMap: {}, isLoadingCosts: false }
    case FETCH_VARIATION_PART_NUMBER_DETAILS:
      return {
        ...state,
        partNumberDetails: [],
        isLoadingPartNumberDetails: true,
        error: null,
      }
    case FETCH_VARIATION_PART_NUMBER_DETAILS_SUCCESS:
      return {
        ...state,
        partNumberDetails: action.partNumberDetails,
        isLoadingPartNumberDetails: false,
      }
    case FETCH_VARIATION_PART_NUMBER_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingPartNumberDetails: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_VARIATION_PART_NUMBER_DETAILS:
      return {
        ...state,
        partNumberDetails: [],
        isLoadingPartNumberDetails: false,
      }
    default:
      return state
  }
}

export default variations

const getVariations = (state: RootState): VariationsState => state.variations
export const getVariationsError = (state: RootState) =>
  getVariations(state).error
export const getVariationIsSending = (state: RootState) =>
  getVariations(state).isSending
export const getVariationCalculatedCostsMap = (state: RootState) =>
  getVariations(state).calculatedCostsMap
export const getVariationPartNumberDetails = (state: RootState) =>
  getVariations(state).partNumberDetails
export const getVariationIsLoadingPartNumberDetails = (state: RootState) =>
  getVariations(state).isLoadingPartNumberDetails
