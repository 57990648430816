import React from 'react'
import { useSelector } from 'react-redux'
import { Nil, Text, TextProps, Utils } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

export interface PersonLabelProps extends TextProps {
  personId: string | Nil
}

const PersonLabel = ({ personId, ...rest }: PersonLabelProps) => {
  const person = useSelector(getUser(personId))

  if (!person) {
    return null
  }

  const personLabel = Utils.getPersonString(person)

  return <Text {...rest}>{personLabel}</Text>
}

export default PersonLabel
