import React from 'react'
import { useSelector } from 'react-redux'
import { FormControl, Input, InputLabel } from '@mui/material'
import {
  Field,
  NumberUtils,
  PuiSelect,
  PuiSelectProps,
} from '@pbt/pbt-ui-components'

import { getCurrentBusinessTaxes } from '~/store/reducers/auth'

export interface TaxRateSelectProps extends PuiSelectProps {
  field: Field
  taxableField: Field
}

const TaxRateSelect = ({
  taxableField,
  field,
  ...rest
}: TaxRateSelectProps) => {
  const taxes = useSelector(getCurrentBusinessTaxes)

  const list = taxes.map((tax) => ({
    ...tax,
    name: `${tax.name} | ${NumberUtils.toPercentFormat(tax.rateValue, 2)}`,
  }))

  return (
    <FormControl fullWidth margin="none">
      <InputLabel htmlFor="tax-rate-select">{field.label}</InputLabel>
      <PuiSelect
        multiple
        field={field}
        input={<Input id="tax-rate-select" />}
        items={list}
        {...rest}
      />
    </FormControl>
  )
}
export default TaxRateSelect
