import React from 'react'
import { useSelector } from 'react-redux'
import { Field, Nil } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import {
  calculatePrepaidColumns,
  getUsedQuantityBlocked,
  isGroupedInvoiceItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice, InvoiceLineItem, InvoiceLineItemPayload } from '~/types'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeFieldUsedQuantityProps {
  className?: string
  disabled: boolean
  inputClassName?: string
  invoice: Invoice | InvoiceV3 | Nil
  item: InvoiceLineItem
  onEditInvoiceLineItem: (payload: InvoiceLineItemPayload) => void
  quantityField: Field
  remainingQuantityField: Field
  usedQuantityField: Field
}

const ChargeFieldUsedQuantity = ({
  className: classNameProp,
  disabled: disabledProp,
  inputClassName,
  invoice,
  item,
  onEditInvoiceLineItem,
  quantityField,
  remainingQuantityField,
  usedQuantityField,
}: ChargeFieldUsedQuantityProps) => {
  const blockedUsedQuantity =
    typeof invoice?.groups?.[0] === 'string'
      ? item.prepaid && item.soapId && item.usedQuantity === 0
      : getUsedQuantityBlocked(invoice, item)
  const disabled = disabledProp || isGroupedInvoiceItem(item)

  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  if (!item.prepaid && !isEditPostedChargesEnabled) {
    return null
  }

  return (
    <ChargeDetailsFieldWrapper
      disabled={disabled}
      label={usedQuantityField.label}
    >
      <QuantityInput
        disableUnderline
        showControls
        InputProps={{
          disableUnderline: blockedUsedQuantity,
        }}
        allowDecimal={item.logType !== OrderType.LAB_TEST}
        className={classNameProp}
        disabled={disabled}
        field={{
          ...usedQuantityField,
          setValue: (value: number) => {
            const newItem = { ...item, usedQuantity: value }

            usedQuantityField.setValue(value)
            calculatePrepaidColumns(item, newItem, false)
            quantityField.setValue(newItem.quantity)
            remainingQuantityField.setValue(newItem.prepaidRemaining)

            onEditInvoiceLineItem({
              usedQuantity: value,
              quantity: newItem.quantity,
              prepaid: true,
            })
          },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          'aria-labelledby': 'charge-field-used-quantity',
          className: inputClassName,
        }}
        max={999}
        min={0}
        minReachedTooltipEnabled={!blockedUsedQuantity}
      />
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeFieldUsedQuantity
