import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Space, UnsavedSpace } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchSpaces = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
) =>
  request(
    getAPIPath('spaces'),
    { method: 'GET', params: { businessId, from, to, query } },
    true,
    Schema.spacesList,
  )

export const fetchAllSpacesWithType = (businessId: string, typeId: string) =>
  request(
    getAPIPath('spaces'),
    { method: 'GET', params: { businessId, typeId } },
    true,
    Schema.spacesArray,
  )

export const fetchSpace = (businessId: string, spaceId: string) =>
  request(
    getAPIPath(`spaces/${spaceId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.space,
  )

export const createSpace = (businessId: string, space: UnsavedSpace) =>
  request(
    getAPIPath('spaces'),
    { method: 'POST', data: space, params: { businessId } },
    true,
    Schema.space,
  )

export const editSpace = (businessId: string, space: Space) =>
  request(
    getAPIPath(`spaces/${space.id}`),
    { method: 'PUT', data: space, params: { businessId } },
    true,
    Schema.space,
  )

export const deleteSpace = (businessId: string, spaceId: string) =>
  request(
    getAPIPath(`spaces/${spaceId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchSpacesListForAppointment = (
  businessId: string,
  appointmentTypeId: string,
  patientId: string | Nil,
) =>
  request(
    getAPIPath('spaces'),
    { method: 'GET', params: { businessId, appointmentTypeId, patientId } },
    true,
    Schema.spacesArray,
  )
