import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  HtmlPreviewUtils,
  IFrameEvent,
  Nil,
  Text,
  useHtmlPreview,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    previewContainerWrapper: {
      overflow: 'hidden',
      flexShrink: 0,
    },
    progress: {
      margin: `${theme.spacing(3)} auto ${theme.spacing(1)}`,
    },
    plainTextPreview: {
      width: '100%',
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
  }),
  { name: 'EmailPreview' },
)

export interface EmailPreviewProps {
  allowPointerEvents?: boolean
  classes?: ClassesType<typeof useStyles>
  disableHtmlRendering?: boolean
  emailPreviewShadowDomClasses?: object
  emailTemplate?: string | Nil
  hideLoading?: boolean
  scale?: number
}

const EmailPreview = ({
  emailTemplate,
  scale = HtmlPreviewUtils.IFRAME_SCALE,
  classes: classesProp,
  emailPreviewShadowDomClasses,
  allowPointerEvents = false,
  disableHtmlRendering = false,
  hideLoading = false,
}: EmailPreviewProps) => {
  const classes = useStyles({ classes: classesProp })
  const [iFrameHeight, setIFrameHeight] = useState<number>()
  const [isTemplateReady, setIsTemplateReady] = useState(false)

  const zoom = scale * 100

  useEffect(() => {
    if (emailTemplate) {
      setIsTemplateReady(true)
    }
  }, [emailTemplate])

  const iFrameLoaded = (event: IFrameEvent) => {
    const { target } = event
    if (target?.contentDocument) {
      setIFrameHeight(target.contentDocument.documentElement.scrollHeight || 0)
    }
  }

  const isLoading = !isTemplateReady || !emailTemplate

  const htmlPreview = useHtmlPreview({
    iFrameLoaded,
    zoom,
    html: emailTemplate,
    allowPointerEvents,
    shadowDomClasses: emailPreviewShadowDomClasses || {},
  })

  return (
    <Grid
      container
      className={classes.previewContainerWrapper}
      justifyContent="center"
      style={
        iFrameHeight
          ? { height: `${scale * Number(iFrameHeight)}px` }
          : undefined
      }
    >
      {isLoading && !hideLoading ? (
        <CircularProgress
          className={classes.progress}
          color="secondary"
          size={32}
        />
      ) : disableHtmlRendering ? (
        <Text className={classes.plainTextPreview}>{emailTemplate}</Text>
      ) : (
        htmlPreview
      )}
    </Grid>
  )
}

export default EmailPreview
