import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  DateFormat,
  moment,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  disabledActionStatusesSet,
  MigrationStatuses,
  MigrationStatusPostfix,
  SessionsTypes,
} from '~/constants/migration'
import i18n from '~/locales/i18n'
import {
  chooseImportSession,
  createImportSession,
  fetchMigrationSessions,
} from '~/store/actions/migration'
import { getPimsFull } from '~/store/reducers/constants'
import {
  getIsLoadingImportSessions,
  getMultipleSessions,
  getSessionsList,
} from '~/store/reducers/migration'
import useDialog from '~/utils/useDialog'

const buildSessionConfig =
  (allowDisabledSessions?: boolean) =>
  ({
    active,
    id,
    date,
    status,
  }: {
    active: boolean
    date?: string
    id: string
    status: MigrationStatuses
  }) => {
    const dateString = moment(date).format(
      DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE,
    )
    const postfix =
      MigrationStatusPostfix[status as keyof typeof MigrationStatusPostfix]
    const name = [dateString, postfix].join(' | ')
    const disabled = allowDisabledSessions
      ? false
      : disabledActionStatusesSet.has(status) || !active

    return { id, name, disabled }
  }

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      padding: theme.spacing(3),
    },
    container: {
      border: theme.constants.tableBorder,
      height: 320,
      overflow: 'auto',
      position: 'relative',
    },
    radioGroup: {
      padding: theme.spacing(2),
    },
    spinner: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    label: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    footer: {
      paddingTop: theme.spacing(2),
    },
    button: {
      flexShrink: 0,
      height: 40,
      width: 150,
    },
    newSessionButton: {
      height: 40,
      padding: theme.spacing(0, 3),
      margin: theme.spacing(0, 2),
    },
  }),
  { name: 'StepSelectSession' },
)

const filterSessionsByStatus =
  (filterTypes: SessionsTypes[]) =>
  ({ type }: { type: SessionsTypes }) =>
    filterTypes?.length ? filterTypes.includes(type) : true
const excludeSessionsByPimsIds =
  (pimsIds: string[]) =>
  ({ pimsId }: { pimsId: string }) =>
    pimsIds?.length ? !pimsIds.includes(pimsId) : true

interface StepSelectSessionProps {
  allowDisabledSessions?: boolean
  businessId: string
  createSessionButtonText?: string
  createSessionEnabled?: boolean
  sessionsExcludePimsIds?: string[]
  sessionsFilterTypes: SessionsTypes[]
}

const StepSelectSession = ({
  allowDisabledSessions,
  businessId,
  createSessionButtonText = i18n.t('Businesses:MIGRATION.CREATE_NEW_SESSION'),
  createSessionEnabled = false,
  sessionsExcludePimsIds,
  sessionsFilterTypes,
}: StepSelectSessionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const isLoading = useSelector(getIsLoadingImportSessions)
  const sessionsIds = useSelector(getSessionsList)
  const sessions = useSelector(getMultipleSessions(sessionsIds))
  const PimsFull = useSelector(getPimsFull)

  const CovetrusId = Utils.findConstantIdByName('Covetrus', PimsFull)
  const UniversalId = Utils.findConstantIdByName('Other', PimsFull)

  const [openSelectPimsDialog] = useDialog(DialogNames.SELECT_PIMS)

  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [selectedSession, setSelectedSession] = useState<string | null>(null)

  const items = sessions
    .filter(filterSessionsByStatus(sessionsFilterTypes))
    .filter(excludeSessionsByPimsIds(sessionsExcludePimsIds ?? []))
    .map(buildSessionConfig(allowDisabledSessions))

  const isRESessionCreated = Boolean(items.length)

  useEffect(() => {
    if (!isInitialized && businessId) {
      dispatch(fetchMigrationSessions(businessId))
      setIsInitialized(true)
    }
  }, [isInitialized, businessId])

  const handleProceed = () => {
    dispatch(chooseImportSession(selectedSession))
  }

  const handleNewSession = (pimsId: string) => {
    dispatch(createImportSession(businessId, CovetrusId, UniversalId, pimsId))
  }

  const handlePimsSelect = () => {
    openSelectPimsDialog({
      onProceed: handleNewSession,
    })
  }

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.container}>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:LOOKING_FOR_SESSIONS')}
          />
        ) : (
          <RadioGroup
            className={classes.radioGroup}
            name="items"
            value={selectedSession || ''}
            onChange={(_, value) => setSelectedSession(value)}
          >
            {items.map(({ id, name, disabled }) => (
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={<Radio disabled={disabled} value={id} />}
                key={id}
                label={name}
              />
            ))}
          </RadioGroup>
        )}
      </Grid>
      <Grid container className={classes.footer} wrap="nowrap">
        <Fab
          className={classes.button}
          color="inherit"
          disabled={Boolean(!selectedSession)}
          variant="extended"
          onClick={handleProceed}
        >
          {t('Common:PROCEED')}
        </Fab>
        {createSessionEnabled && (
          <Grid item>
            <ButtonWithLoader
              className={classes.newSessionButton}
              color="primary"
              disabled={isLoading || isRESessionCreated}
              onClick={handlePimsSelect}
            >
              {createSessionButtonText}
            </ButtonWithLoader>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default StepSelectSession
