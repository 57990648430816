import { VetcoveConfig } from '~/types/entities/vetcoveConfig'

import { getPreludeAPIPath, request } from './core'

export const fetchVetcoveConfig = (
  currentBusinessId: string,
  businessId: string,
  currentUserId: string,
) =>
  request(
    getPreludeAPIPath('inventory-integration/vetcove/settings'),
    {
      method: 'GET',
      params: { businessId },
      headers: {
        'X-Prelude-Business-Id': currentBusinessId,
        'X-Prelude-Person-Id': currentUserId,
      },
    },
    true,
  )

export const editVetcoveConfig = (
  currentBusinessId: string,
  currentUserId: string,
  config: VetcoveConfig,
) =>
  request(
    getPreludeAPIPath('inventory-integration/vetcove/settings'),
    {
      method: 'POST',
      data: config,
      headers: {
        'X-Prelude-Business-Id': currentBusinessId,
        'X-Prelude-Person-Id': currentUserId,
      },
    },
    true,
  )
