import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Calendar,
  ClassesType,
  moment,
  PuiCheckbox,
  PuiDialog,
  PuiDialogProps,
  PuiSelect,
  PuiTextArea,
  Text,
  useFields,
  Utils,
  ValidationType,
} from '@pbt/pbt-ui-components'

import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import {
  createTimeEntity,
  getTimeTrackerEntity,
  getTimeTrackerIsLoading,
  updateTimeEntity,
} from '~/store/duck/timeTracker'
import { getClockOutReasons } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import {
  aggregateStartAndEndDates,
  getDateString,
  getHoursBetween,
} from '~/utils/time'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import TimezoneWarningLabel from '../alerts/TimezoneWarningLabel'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
    button: {
      width: 150,
    },
    reason: {
      width: 212,
    },
  }),
  { name: 'TimeTrackerEditDialog' },
)

export interface TimeTrackerEditDialogProps extends PuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  id?: string
  userId: string
}

const TimeTrackerEditDialog = ({
  id,
  open,
  onClose,
  userId,
  classes: classesProp,
  ...props
}: TimeTrackerEditDialogProps) => {
  const classes = useStyles(classesProp)
  const { t } = useTranslation(['Common', 'Constants', 'Dialogs', 'Time'])

  const isEdit = Boolean(id)

  const dispatch = useDispatch()
  const entity = useSelector(getTimeTrackerEntity(id))
  const isLoading = useSelector(getTimeTrackerIsLoading)
  const ClockOutReasons = useSelector(getClockOutReasons)
  const user = useSelector(getUser(userId))

  const setCloseAfterUpdate = useCloseAfterCreation(
    onClose,
    getTimeTrackerIsLoading,
  )
  const [isMultiday, setIsMultiday] = useState(
    isEdit &&
      Boolean(entity?.clockoutDatetime) &&
      !moment(entity?.clockinDatetime).isSame(entity?.clockoutDatetime, 'day'),
  )

  const timeString = getDateString(
    moment(entity?.clockinDatetime),
    entity?.clockoutDatetime ? moment(entity?.clockoutDatetime) : undefined,
  )

  const {
    fields: {
      administratorNotes,
      clockInDate,
      clockInTime,
      clockOutDate,
      clockOutTime,
      clockOutReason,
    },
    validate,
  } = useFields([
    {
      name: 'administratorNotes',
      label: t('Common:NOTES'),
      initialValue: entity?.administratorNotes || '',
    },
    {
      name: 'clockInDate',
      label: isMultiday
        ? t('Dialogs:TIME_TRACKER_EDIT_DIALOG.CLOCK_IN_START_DATE')
        : t('Common:DATE_TIME'),
      validators: ['required', 'timestamp'],
      initialValue: isEdit ? entity?.clockinDatetime : moment(),
    },
    {
      name: 'clockInTime',
      label: isMultiday
        ? t('Time:LABEL.CLOCK_IN')
        : `${t('Time:LABEL.CLOCK_IN')}              ${t(
            'Time:LABEL.CLOCK_OUT',
          )}`,
      type: 'none',
      validators: ['required', 'timestamp'],
      initialValue: isEdit ? entity?.clockinDatetime : moment(),
    },
    ...(isMultiday // conditional validators does not update properly, need to use conditional fields.
      ? [
          {
            name: 'clockOutDate',
            label: t('Dialogs:TIME_TRACKER_EDIT_DIALOG.CLOCK_OUT_END_DATE'),
            validators: ['required', 'timestamp'] as ValidationType[],
            initialValue: isEdit
              ? entity?.clockoutDatetime
              : moment().add(10, 'minutes'),
          },
        ]
      : [
          {
            name: 'clockOutDate',
            initialValue: isEdit
              ? entity?.clockoutDatetime
              : moment().add(10, 'minutes'),
          },
        ]),
    {
      name: 'clockOutTime',
      label: t('Time:LABEL.CLOCK_OUT'),
      type: 'none',
      validators: entity?.clockoutDatetime
        ? ['required', 'timestamp']
        : ['timestamp'],
      initialValue: isEdit
        ? entity?.clockoutDatetime
        : moment().add(10, 'minutes'),
    },
    {
      name: 'clockOutReason',
      type: 'select',
      initialValue: Utils.findConstantIdByName('End of shift', ClockOutReasons),
    },
  ])

  const onClockInValueChange = (newValue: string) => {
    clockInDate.setValue(newValue)
    if (moment(newValue).isAfter(clockOutDate.value, 'd')) {
      clockOutDate.setValue(newValue)
    }
  }

  const getHours = useCallback(() => {
    const [start, end] = aggregateStartAndEndDates({
      startDate: moment(clockInDate.value),
      endDate: moment(clockOutDate.value),
      startTime: moment(clockInTime.value),
      endTime: moment(clockOutTime.value),
      isMultiday,
    })
    const hours = getHoursBetween(start, end)
    return Number.isNaN(hours) ? '—' : hours
  }, [
    clockInDate.value,
    clockOutDate.value,
    clockInTime.value,
    clockOutTime.value,
  ])

  const onSave = () => {
    if (validate()) {
      setCloseAfterUpdate()
      const [start, end] = aggregateStartAndEndDates({
        startDate: moment(clockInDate.value),
        endDate: moment(clockOutDate.value),
        startTime: moment(clockInTime.value),
        endTime: moment(clockOutTime.value),
        isMultiday,
      })
      if (entity) {
        dispatch(
          updateTimeEntity({
            ...entity,
            clockoutReason: entity?.clockoutReason?.id || clockOutReason.value,
            administratorNotes: administratorNotes.value,
            clockinDatetime: start,
            clockoutDatetime: clockOutTime.value ? end : null,
          }),
        )
      } else {
        dispatch(
          createTimeEntity(userId, {
            clockoutReason: clockOutReason.value,
            administratorNotes: administratorNotes.value,
            clockinDatetime: start,
            clockoutDatetime: end,
          }),
        )
      }
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={onSave}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="time-tracker-edit-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      header={
        <TimezoneWarningLabel variant="h3">
          {isEdit
            ? t('Dialogs:TIME_TRACKER_EDIT_DIALOG.TIME_ENTRY_TIME', {
                timeString,
              })
            : t('Dialogs:TIME_TRACKER_EDIT_DIALOG.NEW_TIME_ENTRY')}
        </TimezoneWarningLabel>
      }
      open={open}
      onClose={onClose}
      {...props}
    >
      <Grid container item alignItems="center" columnSpacing={2}>
        <Grid item>
          <Calendar
            fullWidth
            field={{ ...clockInDate, setValue: onClockInValueChange }}
            label={clockInDate.label}
          />
        </Grid>
        <Grid item>
          <TimeSelector
            endValue={!isMultiday && clockOutTime.value}
            keepRange={false}
            label={clockInTime.label}
            range={!isMultiday}
            startValue={clockInTime.value}
            onEndChange={!isMultiday ? clockOutTime.set : undefined}
            onStartChange={clockInTime.set}
          />
        </Grid>
        <Grid item>
          <PuiCheckbox
            checked={isMultiday}
            label={t('Time:LABEL.MULTI-DAY')}
            onChange={() => setIsMultiday(!isMultiday)}
          />
        </Grid>
      </Grid>
      {isMultiday && (
        <Grid container item columnSpacing={2}>
          <Grid item>
            <Calendar
              fullWidth
              field={clockOutDate}
              label={clockOutDate.label}
              minDate={clockInDate.value}
            />
          </Grid>
          <Grid item>
            <TimeSelector
              label={t('Time:LABEL.CLOCK_OUT')}
              startValue={clockOutTime.value}
              onStartChange={clockOutTime.set}
            />
          </Grid>
        </Grid>
      )}
      <Box m={1} />
      {!isEdit && (
        <>
          <PuiSelect
            aria-labelledby="timeTrackingReasonSelect"
            className={classes.reason}
            field={clockOutReason}
            items={ClockOutReasons}
            renderEmpty={false}
          />
          <Box m={1} />
        </>
      )}
      {clockOutDate.value && (
        <Text mb={1}>
          {t('Constants:TIME_UNITS.HOUR_OTHER')}: {getHours()}
        </Text>
      )}
      {entity?.clockoutReason && (
        <Text mb={1}>
          {t('Dialogs:TIME_TRACKER_EDIT_DIALOG.CLOCK_OUT_REASON', {
            clockoutReason: entity?.clockoutReason.name,
          })}
        </Text>
      )}
      {entity?.clockinNotes && (
        <Grid container item>
          <Text strong mb={1}>
            {t('Dialogs:TIME_TRACKER_EDIT_DIALOG.USER_CLOCK_IN_NOTES', {
              userName: Utils.getPersonString(user),
            })}
          </Text>
          <Text>: {entity?.clockinNotes}</Text>
        </Grid>
      )}
      {entity?.clockoutNotes && (
        <Grid container item>
          <Text strong mb={1}>
            {t('Dialogs:TIME_TRACKER_EDIT_DIALOG.USER_CLOCK_OUT_NOTES', {
              userName: Utils.getPersonString(user),
            })}
          </Text>
          <Text>: {entity?.clockoutNotes}</Text>
        </Grid>
      )}
      <PuiTextArea
        field={administratorNotes}
        placeholder={administratorNotes.label}
      />
    </PuiDialog>
  )
}

export default TimeTrackerEditDialog
