import React from 'react'
import { useDispatch } from 'react-redux'
import { pluck } from 'ramda'
import { IdObject, Nil } from '@pbt/pbt-ui-components'

import { AttachmentListWithFiltersProps } from '~/components/common/inputs/attachment/attachment-dialog/AttachmentListWithFilters'
import MultiAttachmentDialog, {
  MultiAttachmentDialogProps,
} from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentDialog'
import MultiAttachmentFilters from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentFilters'
import {
  attachDocumentsToMessage,
  uploadFileList,
} from '~/store/actions/conversationMessages'
import {
  getIsAttachingDocuments,
  getIsUploadingFile,
} from '~/store/reducers/conversationMessages'
import { AttachmentGroup } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

interface ConversationMultiAttachmentDialogProps
  extends Omit<MultiAttachmentDialogProps, 'renderMultiAttachment'> {
  ListWithFiltersProps?: Partial<AttachmentListWithFiltersProps>
  clientId: string | Nil
  date?: string
  eventId?: string | Nil
  patientId: string | Nil
  showDetails?: boolean
  title?: string
}

const ConversationMultiAttachmentDialog = ({
  open,
  onClose,
  clientId,
  patientId,
  eventId,
  date,
  title,
  ListWithFiltersProps,
  showDetails,
}: ConversationMultiAttachmentDialogProps) => {
  const dispatch = useDispatch()

  const setCloseAfterUploadedOn = useCloseAfterCreation(
    onClose,
    getIsUploadingFile,
  )
  const setCloseAfterAttachedOn = useCloseAfterCreation(
    onClose,
    getIsAttachingDocuments,
  )

  const onDocumnentsUpload = (orderedItems: IdObject[]) => {
    const ids = pluck('id', orderedItems)
    setCloseAfterAttachedOn()
    dispatch(
      attachDocumentsToMessage(ids, {
        clientId,
        patientId,
        appointmentId: eventId,
      }),
    )
  }

  const onFilesUpload = (group: AttachmentGroup) => {
    setCloseAfterUploadedOn()
    dispatch(uploadFileList(group, clientId, patientId))
  }

  return (
    <MultiAttachmentDialog
      open={open}
      renderMultiAttachment={({
        closeEdit,
        fileIndexForImageEdit,
        setFileIndexForImageEdit,
      }) => (
        <MultiAttachmentFilters
          ListWithFiltersProps={ListWithFiltersProps}
          closeEdit={closeEdit}
          date={date}
          fileIndexForImageEdit={fileIndexForImageEdit}
          patientId={patientId}
          setFileIndexForImageEdit={setFileIndexForImageEdit}
          showDetails={showDetails}
          onDocumnentsUpload={onDocumnentsUpload}
          onFilesUpload={onFilesUpload}
        />
      )}
      title={title}
      onClose={onClose}
    />
  )
}

export default ConversationMultiAttachmentDialog
