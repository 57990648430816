import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { map } from 'ramda'
import { moment, Text } from '@pbt/pbt-ui-components'

import SelectionField from '~/components/common/inputs/SelectionField'
import i18n from '~/locales/i18n'
import { updateTimelineDateRange } from '~/store/actions/timeline'
import { TimelineTimeframeItem } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    timeframeText: {
      marginLeft: 5,
      textTransform: 'uppercase',
    },
    searchInput: {
      margin: 0,
    },
    anchorRoot: {
      userSelect: 'none',
      cursor: 'pointer',
      display: 'inline-block',
    },
    paper: {
      width: 312,
    },
    arrowIcon: {
      color: theme.colors.title,
    },
  }),
  { name: 'TimelineTimeframe' },
)

const TimelineNames: Record<string, string> = {
  SHOW_ALL: i18n.t('Common:SHOW_ALL'),
  SHOW_ALL_FUTURE: i18n.t('Time:LABEL.SHOW_ALL_FUTURE'),
  NEXT_03_MONTHS: i18n.t('Time:LABEL.NEXT_MONTH_OTHER', { monthsQty: 3 }),
  NEXT_06_MONTHS: i18n.t('Time:LABEL.NEXT_MONTH_OTHER', { monthsQty: 6 }),
  NEXT_12_MONTHS: i18n.t('Time:LABEL.NEXT_MONTH_OTHER', { monthsQty: 12 }),
  SHOW_ALL_PAST: i18n.t('Time:LABEL.SHOW_ALL_PAST'),
  LAST_03_MONTHS: i18n.t('Time:LABEL.LAST_MONTH_OTHER', { monthsQty: 3 }),
  LAST_06_MONTHS: i18n.t('Time:LABEL.LAST_MONTH_OTHER', { monthsQty: 6 }),
  LAST_12_MONTHS: i18n.t('Time:LABEL.LAST_MONTH_OTHER', { monthsQty: 12 }),
}

const TimelineTimeframe = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Time'])

  const items = [
    {
      bold: true,
      id: 0,
      name: TimelineNames.SHOW_ALL,
      getDateRange: () => [],
    },
    {
      bold: true,
      id: 1,
      name: TimelineNames.SHOW_ALL_FUTURE,
      getDateRange: () => [moment()],
    },
    {
      id: 2,
      name: TimelineNames.NEXT_03_MONTHS,
      getDateRange: () => [moment(), moment().add(3, 'month')],
    },
    {
      id: 3,
      name: TimelineNames.NEXT_06_MONTHS,
      getDateRange: () => [moment(), moment().add(6, 'month')],
    },
    {
      id: 4,
      name: TimelineNames.NEXT_12_MONTHS,
      getDateRange: () => [moment(), moment().add(12, 'month')],
    },
    {
      bold: true,
      id: 5,
      name: TimelineNames.SHOW_ALL_PAST,
      getDateRange: () => [null, moment()],
    },
    {
      id: 6,
      name: TimelineNames.LAST_03_MONTHS,
      getDateRange: () => [moment().subtract(3, 'month'), moment()],
    },
    {
      id: 7,
      name: TimelineNames.LAST_06_MONTHS,
      getDateRange: () => [moment().subtract(6, 'month'), moment()],
    },
    {
      id: 8,
      name: TimelineNames.LAST_12_MONTHS,
      getDateRange: () => [moment().subtract(12, 'month'), moment()],
    },
  ]

  const [selectedItem, setSelectedItem] = useState(items[0])

  const classes = useStyles()

  const anchorElement = (
    <div className={classes.anchorRoot}>
      <Text inline strong mr={1} variant="lowAccent2">
        {t('Time:TIMEFRAME')}:
      </Text>
      <Text inline strong className={classes.timeframeText} variant="h5">
        {selectedItem.name}
      </Text>
    </div>
  )

  const onItemClick = (newItem: TimelineTimeframeItem) => {
    const dateRange = map(
      (date) => date && date.toISOString(),
      newItem.getDateRange(),
    )

    dispatch(updateTimelineDateRange(dateRange))
    setSelectedItem(newItem)
  }

  return (
    <SelectionField
      closeOnClickWhenOpen
      closeOnItemClick
      showArrow
      anchorElement={anchorElement}
      classes={{
        paper: classes.paper,
        searchInput: classes.searchInput,
        arrowIcon: classes.arrowIcon,
      }}
      list={items}
      onItemClick={onItemClick}
    >
      {({ name, bold }: TimelineTimeframeItem) => (
        <Text noWrap strong={bold}>
          {name}
        </Text>
      )}
    </SelectionField>
  )
}

export default TimelineTimeframe
