import { ContactSlot } from '~/types'

import ContactTargetSlot from './ContactTargetSlot'

export const getSlotId = ({
  clientId = null,
  patientId = null,
  contactId = null,
}: ContactSlot) => [clientId, patientId, contactId].join('-')

export default ContactTargetSlot
