import { useCallback, useEffect, useState } from 'react'

import { useElementResize } from './useElementResize'

export const useIsTextTruncated = (element: HTMLElement | null) => {
  const [isTruncated, setIsTruncated] = useState(false)
  useEffect(() => {
    if (!element) return
    setIsTruncated(element.scrollWidth > element.clientWidth)
  }, [element])

  const handleResize = useCallback(() => {
    if (!element) return
    setIsTruncated(element.scrollWidth > element.clientWidth)
  }, [element])

  useElementResize(element, handleResize)
  return isTruncated
}
