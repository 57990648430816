import React from 'react'
import { Patient, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { useOpenInvoice } from '~/store/hooks/finance'
import useDialog from '~/utils/useDialog'

type DescriptionCellItem = {
  clientId: string
  description: string
  invoiceId: string
  patient: Patient
}

const DescriptionCell = (item: DescriptionCellItem) => {
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(item.clientId, openInvoiceDialog)

  const openInvoiceSafe = () => {
    openInvoice({
      patientId: item.patient.id,
      clientId: item.clientId,
      invoiceId: item.invoiceId,
    })
  }

  return (
    <Text link variant="body2" onClick={openInvoiceSafe}>
      {item.description}
    </Text>
  )
}

export default DescriptionCell
