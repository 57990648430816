import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChevronLeft,
  ChevronLeftRounded,
  ChevronRight,
  Close,
} from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Slide,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
      zIndex: theme.zIndex.rightRail,
      boxShadow: '-2px 0 4px 0 rgba(0, 0, 0, 0.1)',
      overflowY: 'auto',
      overflowX: 'hidden',
      top: theme.mixins.toolbar.minHeight,
      paddingTop: theme.spacing(1),
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      [theme.breakpoints.down('md')]: {
        top: 0,
        paddingTop:
          (theme.mixins.toolbar as any)[theme.breakpoints.down('md')]
            .minHeight + parseInt(theme.spacing(1), 10),
        height: '100%',
      },
      backgroundColor: theme.colors.tableBackground,
      position: 'fixed',
      right: 0,
    },
    rootExpanded: {
      width: theme.constants.rightRailExpandedWidth,
      maxWidth: '100%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    rootCollapsed: {
      width: theme.constants.rightRailCollapsedWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandToggle: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    chevron: {
      color: theme.colors.primaryText,
    },
    expandButton: {
      backgroundColor: theme.palette.primary.main,
      borderTopLeftRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
      height: theme.spacing(6),
      width: theme.spacing(6),
      cursor: 'pointer',
      boxShadow: '1px 1px 2px 0 rgba(123,119,119,0.15)',
      position: 'fixed',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'below', 2),
      right: 0,
      bottom: theme.spacing(3),
    },
    icon: {
      color: theme.colors.tableBackground,
      width: '1.5em',
      height: '1.5em',
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'inherit',
      },
      padding: theme.spacing(0, 2),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    wrapper: {
      flexShrink: 0,
    },
  }),
  { name: 'RightRail' },
)

export interface RightRailProps {
  children?: React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  expandable?: boolean
  expanded?: boolean
  hideForAnyScreen?: boolean
  onToggleExpandStateButtonClick?: () => void
  onVisibilityChange?: (visible: boolean) => void
  visible?: boolean
}

const RightRail = ({
  classes: classesProp,
  children,
  className,
  expandable,
  expanded,
  onToggleExpandStateButtonClick,
  hideForAnyScreen = false,
  visible,
  onVisibilityChange,
}: RightRailProps) => {
  const classes = useStyles({ classes: classesProp })
  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const { t } = useTranslation('Common')

  useEffect(() => {
    if (isMedium && onVisibilityChange) {
      onVisibilityChange(false)
    }
  }, [isMedium])

  return (
    <>
      <Slide
        direction="left"
        id="right-rail-slide"
        in={visible}
        timeout={isMedium ? 250 : 0}
      >
        <Grid
          container
          className={classNames(className, classes.root, {
            [classes.rootExpanded]: expanded,
            [classes.rootCollapsed]: !expanded,
          })}
          direction="column"
          wrap="nowrap"
        >
          <Grid
            container
            item
            alignItems="center"
            className={classes.wrapper}
            justifyContent="space-between"
          >
            <Grid item>
              {expandable && (
                <Box
                  className={classes.expandToggle}
                  role="button"
                  onClick={onToggleExpandStateButtonClick}
                >
                  {expanded ? (
                    <ChevronRight className={classes.chevron} />
                  ) : (
                    <ChevronLeft className={classes.chevron} />
                  )}
                  <Text strong display="inline" variant="body2">
                    {expanded
                      ? t('Common:COLLAPSE_ACTION')
                      : t('Common:EXPAND_ACTION')}
                  </Text>
                </Box>
              )}
            </Grid>
            {onVisibilityChange && (hideForAnyScreen || isMedium) && (
              <Grid item>
                <IconButton
                  className={classes.iconButton}
                  size="large"
                  onClick={() => onVisibilityChange(false)}
                >
                  <Close />
                </IconButton>
              </Grid>
            )}
          </Grid>
          {children}
        </Grid>
      </Slide>
      {Boolean(onVisibilityChange) && (
        <Slide direction="left" in={!visible} timeout={250}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.expandButton}
            justifyContent="center"
            onClick={() => {
              if (onVisibilityChange) {
                onVisibilityChange(true)
              }
            }}
          >
            <ChevronLeftRounded className={classes.icon} />
          </Grid>
        </Slide>
      )}
    </>
  )
}

export default RightRail
