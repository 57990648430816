import { gql } from '@apollo/client'

import { CONSTANT_ENTITY_FIELDS } from './constantEntities'

export const PRESCRIPTION_FIELDS = gql`
  ${CONSTANT_ENTITY_FIELDS}
  fragment PrescriptionFields on Prescription {
    id
    businessId
    name
    categoryId
    client {
      id
    }
    patient {
      id
    }
    # eslint-disable-next-line @graphql-eslint/no-deprecated
    doctorId
    rxNo
    type
    administrationQuantity
    administrationQuantityUnit {
      ...ConstantEntityFields
    }
    administrationQuantityCustomUnit
    administrationFrequency {
      ...ConstantEntityFields
    }
    administrationFrequencyNotes
    administrationCourseType
    administrationCourseLength
    administrationRoute {
      ...ConstantEntityFields
    }
    drugExpirationDate
    prescribedDate
    globalInventoryId
    globalVariationId
    globalInventoryMapping {
      id
    }
    globalVariationMapping {
      id
    }
    globalFoodCatalogId
    globalFoodCatalogVariationId
    inventoryId
    numberRefills
    quantityPerRefill
    quantityPerRefillUnit {
      ...ConstantEntityFields
    }
    quantityPerRefillCustomUnit
    refillExpirationDate
    totalQuantity
    totalQuantityUnit {
      ...ConstantEntityFields
    }
    totalQuantityCustomUnit
    deleted
    createdAt
    updatedAt
    rejectionReason
    state {
      ...ConstantEntityFields
    }
    compoundingReasonState {
      ...ConstantEntityFields
    }
    compoundingReason
    notes
    orderNumber
    drugInfo {
      customCompound
      customForm
      deliveryMethod {
        id
        name
      }
      description
      flavor
      form {
        ...ConstantEntityFields
      }
      masterName
      strength
      strengthColor {
        ...ConstantEntityFields
      }
      strengthUnits {
        ...ConstantEntityFields
      }
      variationName
      perPackageAmount
      perPackageUnits {
        ...ConstantEntityFields
      }
      packageType {
        ...ConstantEntityFields
      }
      count
      compounded
    }
    mismatchWarning
    pdfUrl
    rxType
    origin
    signer {
      id
    }
    signatureUrl
  }
`
