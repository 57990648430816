import React from 'react'

import { CommonDueDates } from '~/components/common/filters/CommonDueDates'
import DateRangeFilter, {
  DateRangeFilterProps,
} from '~/components/common/filters/DateRangeFilter'

const DueDates = [
  CommonDueDates.TODAY,
  CommonDueDates.LAST_3_DAYS,
  CommonDueDates.LAST_5_DAYS,
  CommonDueDates.LAST_7_DAYS,
  CommonDueDates.LAST_30_DAYS,
]

export interface ConversationDateRangeFilterProps
  extends DateRangeFilterProps {}

const ConversationDateRangeFilter = (
  props: ConversationDateRangeFilterProps,
) => <DateRangeFilter DueDates={DueDates} {...props} />

export default ConversationDateRangeFilter
