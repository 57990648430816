import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  CircularProgressOverlay,
  PuiCheckbox,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { SupportedEntity } from '~/types/entities/migration'

const getGroupedEntities = R.groupBy(({ enabledByDefault }: SupportedEntity) =>
  enabledByDefault ? 'entities' : 'onPremiseEntities',
)

const useStyles = makeStyles((theme) => ({
  tableViewport: {
    tableLayout: 'fixed',
  },
  tableHeading: {
    verticalAlign: 'middle',
    padding: theme.spacing(1, 0, 1, 2),
    borderRight: theme.constants.tableBorder,
    borderBottom: theme.constants.stripedTableHeaderBorder,
  },
  tableHeadingText: {
    color: theme.colors.tabLabel,
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(1),
  },
  tableCell: {
    height: 40,
    padding: theme.spacing(0, 2),
    border: 'none',
    borderRight: theme.constants.tableBorder,
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.colors.tableEvenItem,
    },
  },
}))

interface EntityTypesSelectListProps {
  isLoading: boolean
  onSelectedEntitiesChanged: (arg: string[]) => void
  sessionSupportedEntities: SupportedEntity[]
}

const EntityTypesSelectList = ({
  isLoading,
  sessionSupportedEntities,
  onSelectedEntitiesChanged,
}: EntityTypesSelectListProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const { entities, onPremiseEntities } = useMemo(() => {
    const groupedEntities = getGroupedEntities(sessionSupportedEntities)

    return {
      entities: groupedEntities.entities || [],
      onPremiseEntities: groupedEntities.onPremiseEntities || [],
    }
  }, [sessionSupportedEntities])

  const [selectedEntities, setSelectedEntities] = useState<string[]>([])

  const isAllSelected = Boolean(
    selectedEntities.length === sessionSupportedEntities.length,
  )

  useEffect(() => {
    const selectedItems = R.pluck('entity', entities)

    setSelectedEntities(selectedItems)
  }, [entities])

  useEffect(() => {
    onSelectedEntitiesChanged(selectedEntities)
  }, [selectedEntities])

  const handleEntitySelect = (entity: string) => () => {
    setSelectedEntities(Utils.toggleListItem(entity, selectedEntities))
  }

  const handleAllSelect = () => {
    const updateSelectedEntities = isAllSelected
      ? []
      : R.pluck('entity', sessionSupportedEntities)

    setSelectedEntities(updateSelectedEntities)
  }

  return (
    <>
      <CircularProgressOverlay
        open={isLoading}
        preloaderText={t('Businesses:MIGRATION.LOADING_SUPPORTED_ENTITIES')}
      />
      <Table className={classes.tableViewport}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classNames(
                classes.tableHeadingText,
                classes.tableHeading,
              )}
            >
              <Grid container item>
                <PuiCheckbox
                  checkboxClasses={{
                    root: classes.checkbox,
                  }}
                  checked={isAllSelected}
                  className={classes.checkboxLabel}
                  label={t('Common:SELECT_ALL')}
                  onChange={handleAllSelect}
                />
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={classNames(
                classes.tableHeadingText,
                classes.tableHeading,
              )}
            >
              <Text variant="subheading2">
                {t('Businesses:MIGRATION.ENTITIES')}
              </Text>
            </TableCell>
          </TableRow>
          {!entities.length && (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Text strong align="center">
                  {t('Businesses:MIGRATION.ENTITIES_NOT_FOUND')}
                </Text>
              </TableCell>
            </TableRow>
          )}
          {entities.map(({ entity, label }) => (
            <TableRow className={classes.tableRow} key={entity}>
              <TableCell className={classes.tableCell}>
                <PuiCheckbox
                  checkboxClasses={{
                    root: classes.checkbox,
                  }}
                  checked={R.includes(entity, selectedEntities)}
                  className={classes.checkboxLabel}
                  label={label}
                  onChange={handleEntitySelect(entity)}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              className={classNames(
                classes.tableHeadingText,
                classes.tableHeading,
              )}
            >
              <Grid container item>
                <Text variant="subheading2">
                  {t('Businesses:MIGRATION.ON_PREMISE_ENTITIES')}
                </Text>
              </Grid>
            </TableCell>
          </TableRow>
          {!onPremiseEntities.length && (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Text strong align="center">
                  {t('Businesses:MIGRATION.ON_PREMISE_ENTITIES_NOT_FOUND')}
                </Text>
              </TableCell>
            </TableRow>
          )}
          {onPremiseEntities.map(({ entity, label }) => (
            <TableRow className={classes.tableRow} key={entity}>
              <TableCell className={classes.tableCell}>
                <PuiCheckbox
                  checkboxClasses={{
                    root: classes.checkbox,
                  }}
                  checked={R.includes(entity, selectedEntities)}
                  className={classes.checkboxLabel}
                  label={label}
                  onChange={handleEntitySelect(entity)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default EntityTypesSelectList
