import React, { forwardRef } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ChewySubmark = forwardRef<SVGSVGElement, SvgIconProps>(
  function ChewySubmark(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}>
        <path
          d="M17.8803 12.6998C15.8071 13.1959 15.2765 15.6569 13.0744 16.1098C10.575 16.6238 8.99404 14.6752 8.51194 12.3311C8.11613 10.4067 8.45581 7.57065 11.2325 7.15354C13.671 6.78721 14.2411 9.32813 16.9551 8.79222C18.5631 8.47478 19.3382 7.21066 18.962 5.59529C18.3705 3.14702 14.4844 1.34833 10.219 2.22556C4.7317 3.3541 1.02108 8.24656 2.22851 14.3832C3.47653 20.7263 9.47125 22.7813 14.213 21.7447C20.3147 20.4107 21.3816 16.0883 21.0497 14.664C20.762 13.4308 19.5032 12.2936 17.8803 12.6998Z"
          fill="#1C49C2"
        />
      </SvgIcon>
    )
  },
)

export default ChewySubmark
