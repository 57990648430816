enum LabVendorName {
  IDEXX = 'Idexx',
  IDEXX_ES = 'Idexx (España)',
  ABAXIS = 'Zoetis VETSCAN Analyzers (Abaxis)',
  ANTECH = 'Antech',
  ZN_LABS = 'Zoetis Reference Labs (ZN Labs)',
  HESKA = 'Heska',
  INTERNAL = 'Internal',
}

export default LabVendorName
