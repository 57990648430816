import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, moment, Text, Utils } from '@pbt/pbt-ui-components'

import { BatchInvoice, Invoice } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    label: {
      background: theme.colors.newLabel,
      color: theme.colors.link,
      padding: theme.spacing(0.25, 0.5),
      marginLeft: theme.spacing(2),
      borderRadius: 4,
    },
  }),
  { name: 'InvoicePostedLabel' },
)

export interface InvoicePostedLabelProps {
  invoice: Invoice | BatchInvoice
}

const InvoicePostedLabel = ({ invoice }: InvoicePostedLabelProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Invoices')

  if (!invoice.posted) {
    return null
  }

  const dateTimeFormat = `${Defaults.DATE_FORMAT} ${Defaults.HOURS_FORMAT}`
  const postedBy = invoice.postedBy
    ? t('Invoices:POSTED_INVOICE_BY', {
        personName: Utils.getPersonString(invoice.postedBy),
      })
    : ''
  const postedDate = invoice.postedAt
    ? ` ${moment(invoice.postedAt).format(dateTimeFormat)}`
    : ''
  const postedLabel = t('Invoices:POSTED_INVOICE_LABEL', {
    postedBy,
    postedDate,
  })

  return (
    <Text strong className={classes.label} variant="body">
      {postedLabel}
    </Text>
  )
}

export default InvoicePostedLabel
