import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults, PrimitiveTableColumn } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import AppointmentTypesCell from '~/components/common/lists/primitive-table/cells/AppointmentTypesCell'
import BusinessNameCell from '~/components/common/lists/primitive-table/cells/BusinessNameCell'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  fetchDocumentsList,
  fetchMoreItemsForDocumentsList,
} from '~/store/actions/documents'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getDocumentsIsListFetching,
  getDocumentsIsReceiving,
  getDocumentsIsSending,
  getDocumentsList,
  getDocumentsTotalCount,
  getMultipleDocuments,
} from '~/store/reducers/documents'
import { Document } from '~/types'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

// @ts-ignore
import DocumentDetails from './DocumentDetails'
import DocumentTypeCell from './DocumentTypeCell'
import NoDocumentsScreen from './NoDocumentsScreen'

const getColumns = (isGroupDocumentsSharingEnabled: boolean) =>
  [
    {
      label: i18n.t('Common:NAME'),
      prop: 'name',
      highlight: true,
      width: 4,
    },
    {
      label: i18n.t('Common:TYPE_ONE'),
      prop: DocumentTypeCell,
      width: 2,
    },
    {
      label: i18n.t('Common:APPOINTMENT_TYPE'),
      prop: AppointmentTypesCell,
      width: 5,
    },
    {
      label: i18n.t('Common:SPECIES'),
      prop: SpeciesCell,
      width: 4,
    },
    isGroupDocumentsSharingEnabled && {
      label: i18n.t('Common:SOURCE'),
      prop: BusinessNameCell,
      width: 4,
    },
    {
      label: i18n.t('Common:ACTIVE_ONE'),
      prop: (item: Document) => boolToYesNoString(item.active),
      width: 1,
    },
  ].filter(Boolean) as PrimitiveTableColumn[]

export interface DocumentsTableComponentProps {
  documentId?: string
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
}

const DocumentsTableComponent = ({
  headerButtons,
  documentId,
  onDetailsClose,
}: DocumentsTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const list = useSelector(getDocumentsList)
  const documents = useSelector(getMultipleDocuments(list))
  const totalCount = useSelector(getDocumentsTotalCount)
  const documentsIsSending = useSelector(getDocumentsIsSending)
  const documentsIsReceiving = useSelector(getDocumentsIsReceiving)
  const isListFetching = useSelector(getDocumentsIsListFetching)
  const isLoading = documentsIsSending || documentsIsReceiving || isListFetching
  const { t } = useTranslation('Common')

  const columns = getColumns(
    useSelector(getFeatureToggle(FeatureToggle.GROUP_DOCUMENTS_SHARING)),
  )

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchDocumentsList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search])

  const navigateToDocument = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/documents/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(documents[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForDocumentsList(startIndex, endIndex, search))
  }

  const getDisabled = (item: Document) => !item.active

  return (
    <ExpandableTable
      Expander={DocumentDetails}
      NoItemsScreen={NoDocumentsScreen}
      headerButtons={headerButtons}
      isLoading={isLoading}
      itemId={documentId}
      list={documents}
      searchTerm={search}
      title={t('Common:DOCUMENTS_AND_TEMPLATES')}
      onClose={onDetailsClose}
      onSelected={navigateToDocument}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default DocumentsTableComponent
