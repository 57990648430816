import { gql } from '@apollo/client'

export const PROBLEM_FIELDS = gql`
  fragment ProblemFields on ProblemNode {
    id
    bodySystemId
    breadcrumb
    onlDisabled
    primaryId
    secondaryId
    tertiaryId
    nameTranslation
    chipOrder
    type
    removed
    enums {
      id
      parentEnumId
      order
    }
  }
`
export const PROBLEM_LOG_PERSON_FIELDS = gql`
  fragment ProblemLogPersonFields on PersonShortDto {
    id
    firstName
    lastName
  }
`

export const PROBLEM_LOG_CRC_CONTAINER_FIELDS = gql`
  ${PROBLEM_LOG_PERSON_FIELDS}
  fragment ProblemLogCrcContainerFields on ProblemLogCrcContainer {
    crc
    entity {
      id
      bodySystemLogId
      problemId
      stateId
      notes
      creationDate
      modificationDate
      isCopied
      createdBy {
        ...ProblemLogPersonFields
      }
      modifiedBy {
        ...ProblemLogPersonFields
      }
      enumLogs {
        crc
        entity {
          id
          enumId
          enumValueId
          parentEnumValueId
          problemLogId
          notes
          creationDate
          modificationDate
          creatorId
          modifierId
        }
      }
    }
  }
`

export const PROBLEM_BODY_SYSTEM_LOG_CRC_CONTAINER_FIELDS = gql`
  ${PROBLEM_LOG_CRC_CONTAINER_FIELDS}
  fragment ProblemBodySystemLogCrcContainerFields on ProblemBodySystemLogCrcContainer {
    crc
    entity {
      id
      bodySystemId
      soapId
      stateId
      notes
      creationDate
      modificationDate
      isCopied
      createdBy {
        ...ProblemLogPersonFields
      }
      modifiedBy {
        ...ProblemLogPersonFields
      }
      problemLogs {
        ...ProblemLogCrcContainerFields
      }
    }
  }
`
