import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTooltip } from '@pbt/pbt-ui-components'
import { PuiTooltipProps } from '@pbt/pbt-ui-components/src/components/PuiTooltip'

const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      color: ({ color }: UseStylesProps) => color || theme.colors.title,
      borderColor: ({ color }: UseStylesProps) => color || theme.colors.title,
      marginLeft: 0,
      '&::before': {
        borderTopColor: ({ color }: UseStylesProps) =>
          color || theme.colors.title,
      },
    },
    tooltipRaw: {
      maxWidth: 500,
      border: 'none',
      margin: '0 !important',
      padding: 0,
      '&::before, &::after': {
        display: 'none',
      },
    },
    chip: {
      backgroundColor: ({ color }: UseStylesProps) =>
        color || theme.colors.title,
    },
    chipRaw: {},
  }),
  { name: 'TooltipChip' },
)

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
  color?: string
}

export interface TooltipChipProps extends Omit<PuiTooltipProps, 'children'> {
  className?: string
  classes?: ClassesType<typeof useStyles>
  color?: string
  disableIconBranding?: boolean
  disableTooltipBranding?: boolean
  icon: React.ReactNode
}

const TooltipChip = ({
  icon,
  classes: classesProp,
  color,
  className,
  disableTooltipBranding,
  disableIconBranding,
  ...rest
}: TooltipChipProps) => {
  const classes = useStyles({ color, classes: classesProp })

  return (
    <PuiTooltip
      enterTouchDelay={0}
      {...rest}
      classes={{
        tooltip: disableTooltipBranding ? classes.tooltipRaw : classes.tooltip,
      }}
    >
      <Grid
        container
        alignItems="center"
        borderRadius="50%"
        className={classNames(
          disableIconBranding ? classes.chipRaw : classes.chip,
          className,
        )}
        justifyContent="center"
      >
        {icon}
      </Grid>
    </PuiTooltip>
  )
}

export default TooltipChip
