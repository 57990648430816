import React from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      border: theme.constants.tableBorder,
      overflow: 'auto',
      position: 'relative',
    },
  }),
  { name: 'MigrationStepContent' },
)

const MigrationStepContent = ({
  children,
  ...props
}: React.PropsWithChildren<GridProps>) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.container} {...props}>
      {children}
    </Grid>
  )
}

export default MigrationStepContent
