import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, InputProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  FieldValidator,
  PuiPopper,
  PuiTextField,
  Text,
  useFields,
  ValidationType,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      maxWidth: '280px',
    },
    textFieldInput: {
      maxWidth: 64,
      bottomBOrder: 'none',
    },
    icon: {
      padding: 0,
    },
    editIcon: {
      fontSize: '2rem',
      color: theme.colors.editIconColor,
    },
  }),
  { name: 'EditItemDiscountPoper' },
)

type EditItemDiscountPoperProps = {
  InputProps: InputProps
  confirmDialogText: string
  customValidators?: FieldValidator[]
  description?: string
  id: ConfirmAlertType
  save: (value: number) => void
}

const EditItemDiscountPoper = ({
  description,
  confirmDialogText,
  customValidators,
  InputProps: InputPropsProp,
  id,
  save,
}: EditItemDiscountPoperProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const [isOpen, setIsOpen] = useState(false)

  const [openConfirmAlert, closeConfirmation] = useConfirmAlert({
    type: ConfirmAlertType[id as ConfirmAlertType],
  })

  const fieldsMap = {
    [ConfirmAlertType.ITEM_DISCOUNT]: {
      name: ConfirmAlertType.ITEM_DISCOUNT,
      validators: ['required', 'nonNegative'] as ValidationType[],
      label: '',
      initialValue: '',
    },
    [ConfirmAlertType.ADDITIONAL_DISCOUNT]: {
      name: ConfirmAlertType.ADDITIONAL_DISCOUNT,
      validators: customValidators
        ? (['required', ...customValidators] as
            | ValidationType[]
            | FieldValidator[])
        : (['required'] as ValidationType[]),
      label: '',
      initialValue: '',
    },
  }

  const { fields, validate } = useFields([
    fieldsMap[id as keyof typeof fieldsMap],
  ])

  const itemDiscount = fields[id]

  const onClose = () => setIsOpen(false)

  const onActionButtonClick = () => {
    if (!validate()) {
      return
    }
    if (itemDiscount.name === ConfirmAlertType.ITEM_DISCOUNT) {
      openConfirmAlert({
        applyCustomMessage: true,
        onConfirm: () => save(itemDiscount.value),
        onCancel: closeConfirmation,
        onClose: closeConfirmation,
        message: confirmDialogText,
        cancelButtonText: t('Common:NO_CANCEL'),
        okButtonText: t('Common:YES_UPDATE'),
      })
    } else {
      save(itemDiscount.value)
    }
    onClose()
  }

  return (
    <>
      <IconButton
        className={classes.icon}
        id="item-with-badges-actions-edit"
        ref={buttonRef}
        size="medium"
        onClick={() => setIsOpen(true)}
      >
        <EditIcon className={classes.editIcon} fontSize="medium" />
      </IconButton>
      <PuiPopper
        hideCloseButton
        anchorEl={buttonRef.current}
        open={isOpen}
        placement="top-start"
        onClose={onClose}
      >
        <Grid
          container
          className={classes.container}
          flexDirection="column"
          px={3}
          py={2}
        >
          <Text variant="body2">{description}</Text>
          <PuiTextField
            autoFocus
            InputProps={InputPropsProp}
            className={classes.textFieldInput}
            error={Boolean(itemDiscount.message)}
            id="itemDiscount"
            value={itemDiscount.value}
            onChange={(e) => itemDiscount.setValue(e.target.value)}
          />
          <ButtonWithLoader
            disabled={!itemDiscount.value}
            onClick={onActionButtonClick}
          >
            {t('Common:APPLY_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </PuiPopper>
    </>
  )
}

export default EditItemDiscountPoper
