import React from 'react'
import { ChevronRight } from '@mui/icons-material'
import { Button, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  AppointmentEventType,
  EventTypeName,
  Field,
  LanguageUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import MenuDropdown, {
  MenuDropdownProps,
} from '~/components/common/inputs/MenuDropdown'
import { useEventType } from '~/utils/useEventType'

import { getAppointmentColorState } from '../../timetable/timetableUtils'
import AppointmentStatusLabel, {
  useColorClassname,
} from './AppointmentStatusLabel'

export interface AppointmentStatusSelectDropdownProps
  extends Omit<MenuDropdownProps, 'field' | 'items' | 'onSelected'> {
  field: Field
}

const useStyles = makeStyles(
  (theme) => ({
    menuButton: {
      justifyContent: 'space-between',
      color: theme.colors.lowAccentText,
      textTransform: 'none',
      padding: theme.spacing(0.5, 0),
      width: '100%',
    },
    chevronIcon: {
      color: theme.colors.lowAccentText,
      fontSize: '2.6rem !important',
    },
    endAdornment: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
  }),
  { name: 'EnumSelect' },
)

const AppointmentStatusSelectDropdown = ({
  field,
  ...rest
}: AppointmentStatusSelectDropdownProps) => {
  const classes = useStyles()
  const AppointmentStatuses: AppointmentEventType['states'] = useEventType(
    EventTypeName.Appointment,
    'states',
  )

  const statuses = AppointmentStatuses

  const currentItem = Utils.findById(field.value, statuses) || {}
  const currentColorVariant = getAppointmentColorState(currentItem.name)

  return (
    <MenuDropdown
      Button={
        <Button
          className={classNames(
            classes.menuButton,
            useColorClassname(currentColorVariant),
          )}
          endIcon={
            <InputAdornment position="end">
              <ChevronRight
                className={classNames(
                  classes.chevronIcon,
                  classes.endAdornment,
                )}
              />
            </InputAdornment>
          }
        >
          <AppointmentStatusLabel
            colorVariant={currentColorVariant}
            name={LanguageUtils.getTranslatedFieldName(currentItem)}
            variant="body"
          />
        </Button>
      }
      ItemComponent={AppointmentStatusLabel}
      {...rest}
      items={statuses.map((status) => ({
        value: status.id,
        name: LanguageUtils.getTranslatedFieldName(status),
        colorVariant: getAppointmentColorState(status.name),
      }))}
      onSelected={field.setValue}
    />
  )
}

export default AppointmentStatusSelectDropdown
