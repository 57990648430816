import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IdObject, Nil } from '@pbt/pbt-ui-components'

import MultiAttachmentDialog, {
  MultiAttachmentDialogProps,
} from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentDialog'
import MultiAttachmentFilters from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentFilters'
import {
  saveAttachmentHistoryDocuments,
  saveMultiAttachmentHistory,
} from '~/store/actions/medicalHistory'
import { getMedicalHistoryIsSending } from '~/store/reducers/medicalHistory'
import { getAppointment } from '~/store/reducers/soap'
import { AttachmentGroup, FilesWithDetails } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import AttachmentHistoryDetails from './AttachmentHistoryDetails'

export interface MultiAttachmentHistoryDialogProps
  extends Omit<MultiAttachmentDialogProps, 'renderMultiAttachment'> {
  clientId: string | Nil
  patientId: string | Nil
}

const MultiAttachmentHistoryDialog = ({
  onClose,
  open,
  clientId,
  patientId,
}: MultiAttachmentHistoryDialogProps) => {
  const dispatch = useDispatch()
  const isSending = useSelector(getMedicalHistoryIsSending)
  const { appointmentId, startDate } = useSelector(getAppointment) || {}

  const setCloseAfterSavedOn = useCloseAfterCreation(
    onClose,
    getMedicalHistoryIsSending,
  )

  const onDocumnentsUpload = (orderedItems: IdObject[]) => {
    setCloseAfterSavedOn()
    if (patientId) {
      dispatch(
        saveAttachmentHistoryDocuments(orderedItems, patientId, clientId),
      )
    }
  }

  const onFilesUpload = (group: AttachmentGroup) => {
    setCloseAfterSavedOn()
    if (patientId) {
      dispatch(
        saveMultiAttachmentHistory(
          group as FilesWithDetails,
          patientId,
          clientId,
        ),
      )
    }
  }

  return (
    <MultiAttachmentDialog
      open={open}
      renderMultiAttachment={({
        closeEdit,
        fileIndexForImageEdit,
        setFileIndexForImageEdit,
      }) => (
        <MultiAttachmentFilters
          showDetails
          ListWithFiltersProps={{
            ListItemProps: {
              showListItemActions: true,
            },
            DetailsComponent: AttachmentHistoryDetails,
            isLoading: isSending,
          }}
          closeEdit={closeEdit}
          date={startDate}
          eventId={appointmentId}
          fileIndexForImageEdit={fileIndexForImageEdit}
          patientId={patientId}
          setFileIndexForImageEdit={setFileIndexForImageEdit}
          onDocumnentsUpload={onDocumnentsUpload}
          onFilesUpload={onFilesUpload}
        />
      )}
      onClose={onClose}
    />
  )
}

export default MultiAttachmentHistoryDialog
