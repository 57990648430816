import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { BulkAdjustmentDialogProps } from '~/components/dashboard/admin/catalog/inventory/adjustments/bulk-create-adjustments/BulkAdjustmentDialog'
import { ShipmentDialogProps } from '~/components/dashboard/admin/catalog/inventory/shipments/ShipmentDialog'
import DialogNames from '~/constants/DialogNames'
import { Shipment, ShipmentItem, UnsavedAdjustment, Variation } from '~/types'
import useDialog from '~/utils/useDialog'

import { getMultipleVariations } from '../duck/onHandCatalog'
import { getInventoryAdjustmentMethod } from '../reducers/constants'

const enrichWithAdjustmentMethod =
  (methodId: string) => (adjustment: UnsavedAdjustment) => ({
    ...adjustment,
    methodId,
  })

const catalogItemToAdjustment = (item: Variation): UnsavedAdjustment => ({
  variation: item || ({} as Variation),
})

export const useBulkAdjustmentFromOnHand = (
  list: string[] = [],
  onSave?: BulkAdjustmentDialogProps['onSave'],
) => {
  const [openBulkAdjustmentDialog] = useDialog(
    DialogNames.BULK_ADJUSTMENT_DIALOG,
  )

  const onHandVariations = useSelector(getMultipleVariations(list))
  const InventoryAdjustmentMethod = useSelector(getInventoryAdjustmentMethod)

  const setDirectlyMethod = Utils.findConstantIdByName(
    'Set on-hand directly',
    InventoryAdjustmentMethod,
  )

  const adjustments = onHandVariations
    .map(catalogItemToAdjustment)
    .map(enrichWithAdjustmentMethod(setDirectlyMethod))

  return () => {
    openBulkAdjustmentDialog({
      adjustments,
      onSave,
    })
  }
}

const catalogItemToShipmentItem = (item: Variation) =>
  ({
    name: item.name,
    inventoryVariationId: item.id,
    inventoryItemId: item.inventoryItemId,
    perPackageAmount: item.perPackageAmount,
    perPackageUnitsId: item.perPackageUnitsId,
    packageTypeId: item.packageTypeId,
  }) as ShipmentItem

export const useCreateShipmentFromOnHand = (
  list: string[] = [],
  onSave?: ShipmentDialogProps['onSave'],
) => {
  const onHandVariations = useSelector(getMultipleVariations(list))

  const [openShipmentDialog] = useDialog(DialogNames.SHIPMENT)

  const items = onHandVariations.filter(Boolean).map(catalogItemToShipmentItem)

  return (dialogProps?: Partial<ShipmentDialogProps>) => {
    openShipmentDialog({
      shipment: { items } as Shipment,
      onSave,
      ...dialogProps,
    })
  }
}
