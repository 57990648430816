import { AnyAction } from 'redux'
import { RoleName } from '@pbt/pbt-ui-components'

import { selectDoctor, selectTech } from '~/store/actions/soap'
import { SoapStaffInfo, SoapStaffInfoList, SoapStaffInfoSingle } from '~/types'

const MemberRoleToSoapProperty: Partial<
  Record<RoleName, keyof SoapStaffInfoSingle>
> = {
  [RoleName.Veterinarian]: 'doctorId',
  [RoleName.VetTech]: 'techId',
}

const MemberRoleToSoapMembersProperty: Partial<
  Record<RoleName, keyof SoapStaffInfoList>
> = {
  [RoleName.Veterinarian]: 'doctors',
  [RoleName.VetTech]: 'techs',
}

const MemberRoleToSoapAction: Partial<
  Record<RoleName, (id: string) => AnyAction>
> = {
  [RoleName.Veterinarian]: selectDoctor,
  [RoleName.VetTech]: selectTech,
}

export const getRolePropertyOfSoap = (
  soapStaffInfo: SoapStaffInfo,
  roleName: RoleName,
) =>
  soapStaffInfo?.[
    MemberRoleToSoapProperty[roleName] as keyof SoapStaffInfoSingle
  ]

export const getRoleMembersOfSoap = (
  soapStaffInfo: SoapStaffInfo,
  roleName: RoleName,
) =>
  soapStaffInfo?.[
    MemberRoleToSoapMembersProperty[roleName] as keyof SoapStaffInfoList
  ] || []

export const getRoleUpdateAction = (roleName: RoleName) =>
  MemberRoleToSoapAction[roleName]
