import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import * as R from 'ramda'
import {
  LanguageUtils,
  LinkButton,
  Nil,
  PermissionArea,
  RoleName,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import DialogNames from '~/constants/DialogNames'
import { useOpenInvoice } from '~/store/hooks/finance'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getResponsibilities } from '~/store/reducers/constants'
import type { OTCSaleTimelineItem } from '~/types'
import {
  getLogIdFromTimelineAppoitmentEntry,
  getLogTypeFromTimelineAppoitmentEntry,
} from '~/utils/timeline'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import EventProductsTable from '../appointment/EventProductsTable'
import TimelineCard from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

export interface OTCSaleCardProps {
  clientId: string
  icon: React.JSXElementConstructor<any>
  item: OTCSaleTimelineItem
  patientId: string
}

const OTCSaleCard = ({ clientId, icon, patientId, item }: OTCSaleCardProps) => {
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))

  const mainStaffRoles = useMainStaffRoles()
  const responsibilities = useSelector(getResponsibilities)

  const {
    businessId,
    createdBy,
    creationDate,
    date,
    id,
    invoiceId,
    modificationDate,
    modifiedBy,
    personRoles,
    personResponsibilities,
    products,
  } = item

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const isContextItem = useIsCurrentContextItem({ businessId })
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)
  const handleViewInvoice = () => {
    openInvoice({
      clientId,
      patientId,
      invoiceId,
      chargesEntityType: item.chargesEntityType,
      id: item.id,
      logId: getLogIdFromTimelineAppoitmentEntry(item) as unknown as
        | string
        | Nil,
      logType: getLogTypeFromTimelineAppoitmentEntry(item) as unknown as
        | string
        | Nil,
    })
  }

  const [doctorPersonResponsibility] = R.filter(
    R.propEq(
      'responsibilityId',
      Utils.findConstantIdByName(
        ResponsibilityName.Veterinarian,
        responsibilities,
      ),
    ),
    personResponsibilities || [],
  )

  const [doctorPersonRole] = R.filter(
    R.propEq(
      'roleId',
      Utils.findConstantIdByName(RoleName.Veterinarian, mainStaffRoles),
    ),
    personRoles || [],
  )

  return (
    <TimelineCard
      date={date}
      icon={icon}
      id={id}
      originBusinessId={businessId}
      title={t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.OTC_SALE')}
    >
      <TimelineCardContent noTypography>
        {doctorPersonResponsibility ? (
          <Box mb={2}>
            <Text strong>
              {LanguageUtils.getTranslatedFieldName(
                doctorPersonResponsibility.responsibility,
              )}
            </Text>
            <Text>
              {Utils.getPersonString(doctorPersonResponsibility.person)}
            </Text>
          </Box>
        ) : doctorPersonRole ? (
          <Box mb={2}>
            <Text strong>
              {LanguageUtils.getTranslatedFieldName(doctorPersonRole.role)}
            </Text>
            <Text>{Utils.getPersonString(doctorPersonRole.person)}</Text>
          </Box>
        ) : null}

        {!R.isEmpty(products) && (
          <>
            <Text strong>
              {t(
                'Clients:TIMELINE.SINGLE_SOAP_APPOINTMENT.PRODUCT_AND_SERVICES',
              )}
            </Text>
            <EventProductsTable products={products} />
          </>
        )}
      </TimelineCardContent>

      <TimelineCardActions>
        {invoicePermissions.read && isContextItem && (
          <LinkButton onClick={handleViewInvoice}>
            {t('Common:VIEW_INVOICE')}
          </LinkButton>
        )}
      </TimelineCardActions>

      <Box pb={1.5} px={2}>
        <ItemHistoryLabel
          item={{
            createdBy,
            creationDate,
            modificationDate,
            modifiedBy,
          }}
        />
      </Box>
    </TimelineCard>
  )
}

export default OTCSaleCard
