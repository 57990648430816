import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import FileInput from '~/components/common/inputs/file-input/FileInput'
import { updateAvatar } from '~/store/actions/users'
import { getCurrentUser } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 412,
      paddingTop: theme.spacing(7),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
  }),
  { name: 'ProfileAvatarEdit' },
)

export interface ProfileAvatarEditProps {
  onBack: () => void
}

const ProfileAvatarEdit = ({ onBack }: ProfileAvatarEditProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)

  const [isLoading, setIsLoading] = useState(false)
  const [uploadTriggered, setUploadTriggered] = useState(false)

  const originalAvatar = useMemo(() => currentUser.avatar, [])

  useEffect(() => {
    if (uploadTriggered && currentUser.avatar !== originalAvatar) {
      onBack()
    }
  }, [currentUser])

  const onFileReady = ({ blob }: { blob: Blob }) => {
    setIsLoading(true)
    setUploadTriggered(true)
    dispatch(updateAvatar(currentUser.id, blob))
  }

  return (
    <FileInput
      onlyImages
      aspectRatio={1 / 1}
      className={classes.root}
      isLoading={isLoading}
      size="small"
      onCancel={onBack}
      onFileReady={onFileReady}
    />
  )
}

export default ProfileAvatarEdit
