export const FETCH_SPACES_LIST_FAILURE = 'spaces/FETCH_SPACES_LIST_FAILURE'
export const FETCH_SPACES_LIST_SUCCESS = 'spaces/FETCH_SPACES_LIST_SUCCESS'
export const FETCH_SPACES_LIST = 'spaces/FETCH_SPACES_LIST'

export const FETCH_SPACES_LIST_WITH_TYPE_FAILURE =
  'spaces/FETCH_SPACES_LIST_WITH_TYPE_FAILURE'
export const FETCH_SPACES_LIST_WITH_TYPE_SUCCESS =
  'spaces/FETCH_SPACES_LIST_WITH_TYPE_SUCCESS'
export const FETCH_SPACES_LIST_WITH_TYPE = 'spaces/FETCH_SPACES_LIST_WITH_TYPE'

export const FETCH_SPACE = 'spaces/FETCH_SPACE'
export const FETCH_SPACE_FAILURE = 'spaces/FETCH_SPACE_FAILURE'
export const FETCH_SPACE_SUCCESS = 'spaces/FETCH_SPACE_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_SPACES_LIST_FAILURE =
  'spaces/FETCH_MORE_ITEMS_FOR_SPACES_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_SPACES_LIST_SUCCESS =
  'spaces/FETCH_MORE_ITEMS_FOR_SPACES_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_SPACES_LIST =
  'spaces/FETCH_MORE_ITEMS_FOR_SPACES_LIST'

export const CREATE_SPACE = 'spaces/CREATE_SPACE'
export const CREATE_SPACE_FAILURE = 'spaces/CREATE_SPACE_FAILURE'
export const CREATE_SPACE_SUCCESS = 'spaces/CREATE_SPACE_SUCCESS'

export const EDIT_SPACE = 'spaces/EDIT_SPACE'
export const EDIT_SPACE_FAILURE = 'spaces/EDIT_SPACE_FAILURE'
export const EDIT_SPACE_SUCCESS = 'spaces/EDIT_SPACE_SUCCESS'

export const DELETE_SPACE = 'spaces/DELETE_SPACE'
export const DELETE_SPACE_FAILURE = 'spaces/DELETE_SPACE_FAILURE'
export const DELETE_SPACE_SUCCESS = 'spaces/DELETE_SPACE_SUCCESS'

export const UPDATE_SPACES = 'spaces/UPDATE_SPACES'

export const FETCH_SPACES_LIST_FOR_APPOINTMENT =
  'spaces/FETCH_SPACES_LIST_FOR_APPOINTMENT'
export const FETCH_SPACES_LIST_FOR_APPOINTMENT_FAILURE =
  'spaces/FETCH_SPACES_LIST_FOR_APPOINTMENT_FAILURE'
export const FETCH_SPACES_LIST_FOR_APPOINTMENT_SUCCESS =
  'spaces/FETCH_SPACES_LIST_FOR_APPOINTMENT_SUCCESS'

export const CLEAR_SPACES_LIST_FOR_APPOINTMENT =
  'spaces/CLEAR_SPACES_LIST_FOR_APPOINTMENT'
