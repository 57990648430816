import { useLocation, useNavigate } from 'react-router-dom'

import { LogoutComponentLocationState } from '~/components/auth/LogoutComponent'
import isURLBlacklistedForRedirect from '~/components/error/isURLBlacklistedForRedirect'

import { broadcastChannel } from './broadcastChannel'
import { auth0Enabled } from './index'
import usePreLogoutCleanup from './usePreLogoutCleanup'

const useLogout = () => {
  const navigate = useNavigate()
  const { pathname = '' } = useLocation()
  const handlePreLogoutCleanup = usePreLogoutCleanup()

  const encodedRedirectPath = encodeURIComponent(pathname)

  return (path = '/', postMessage = true, lock?: string) => {
    handlePreLogoutCleanup()

    const lockedParam = lock ? `&lock=${lock}` : ''

    if (auth0Enabled) {
      navigate('/auth/logout', {
        state: {
          doLogout: `${window.location.origin}/auth/logout?redirectTo=${encodedRedirectPath}${lockedParam}`,
        } as LogoutComponentLocationState,
      })
    } else if (!isURLBlacklistedForRedirect(pathname)) {
      navigate(`${path}?redirectTo=${encodedRedirectPath}`)
    }

    if (postMessage) {
      broadcastChannel.postMessage({
        action: 'logout',
      })
    }
  }
}

export default useLogout
