import { gql } from '@apollo/client'

export const BIG_DECIMAL_VITAL_FIELDS = gql`
  fragment BigDecimalVitalValueFields on BigDecimalVitalValue {
    value
    notes
  }
`

export const INTEGER_VITAL_FIELDS = gql`
  fragment IntegerVitalValueFields on IntegerVitalValue {
    value
    notes
  }
`

export const CONSTANT_ENTITY_VITAL_FIELDS = gql`
  fragment ConstantEntityVitalValueFields on ConstantEntityVitalValue {
    value {
      id
      name
      createdAt
      updatedAt
    }
    notes
  }
`
