import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import * as R from 'ramda'
import { PermissionArea } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { updateCurrentUsersBusinessId } from '~/store/actions/users'
import { getFeatureToggle } from '~/store/reducers/constants'

import { createCRUDSelectorForArea } from '../reducers/auth'
import { getBusinessesMap } from '../reducers/businesses'

const useBusinessSupportUtils = () => {
  const businessesMap = useSelector(getBusinessesMap)
  const navigate = useNavigate()
  const matchRolesManagementRoute = useMatch('/admin/support/roles')
  const matchMemberRolesManagementRoute = useMatch(
    '/admin/support/roles/:memberId',
  )
  const getCRUDForSupportArea = useSelector(
    createCRUDSelectorForArea(PermissionArea.SUPPORT),
    R.equals,
  )
  const isRolesManagementRoute =
    matchRolesManagementRoute || matchMemberRolesManagementRoute
  const navigateToDashboard = (businessId: string) => {
    navigate(`/landing?businessId=${businessId}`)
  }
  const getIsSupportUserInBusiness = (businessId: string) => {
    const business = businessesMap[businessId]
    return getCRUDForSupportArea(business).read
  }

  return {
    isRolesManagementRoute,
    navigateToDashboard,
    getIsSupportUserInBusiness,
  }
}

export const useUpdateBusinessIdUrlParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    isRolesManagementRoute,
    navigateToDashboard,
    getIsSupportUserInBusiness,
  } = useBusinessSupportUtils()

  return (businessId: string) => {
    const isSupportUserInBusiness = getIsSupportUserInBusiness(businessId)
    if (isRolesManagementRoute && !isSupportUserInBusiness) {
      navigateToDashboard(businessId)
    } else {
      searchParams.set('businessId', businessId)
      setSearchParams(searchParams, { replace: true })
    }
  }
}

export const useUpdateCurrentUsersBusinessId = () => {
  const dispatch = useDispatch()

  const isBusinessIdUrlParamEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BUSINESS_ID_URL_PARAM),
  )

  const updateBusinessIdUrlParam = useUpdateBusinessIdUrlParam()

  return ({ businessId, userId }: { businessId: string; userId: string }) => {
    if (isBusinessIdUrlParamEnabled) {
      updateBusinessIdUrlParam(businessId)
    }
    dispatch(updateCurrentUsersBusinessId(userId, businessId))
  }
}
