import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import FeatureToggle from '~/constants/featureToggle'
import { getErrorMessage } from '~/utils/errors'

import { removeOrderSuccess } from '../actions/orders'
import { fetchSOAPOrders } from '../actions/soap'
import { fetchClientFinanceCharges } from '../duck/clientFinanceData'
import {
  approveReactiveRx,
  approveReactiveRxFailure,
  approveReactiveRxSuccess,
  declineActiveRx,
  declineActiveRxFailure,
  declineActiveRxSuccess,
  declineReactiveRx,
  declineReactiveRxFailure,
  declineReactiveRxSuccess,
  deleteActiveRx,
  deleteActiveRxFailure,
  deleteActiveRxSuccess,
  fetchActiveRxWorkflowAllowance,
  fetchActiveRxWorkflowAllowanceFailure,
  fetchActiveRxWorkflowAllowanceSuccess,
  fetchAllActiveRxForSoap,
  fetchAllActiveRxForSoapFailure,
  fetchAllActiveRxForSoapSuccess,
  fetchAllPendingActiveRxForChargeSheet,
  fetchAllPendingActiveRxForChargeSheetFailure,
  fetchAllPendingActiveRxForChargeSheetSuccess,
  fetchAllPendingActiveRxForSoap,
  fetchAllPendingActiveRxForSoapFailure,
  fetchAllPendingActiveRxForSoapSuccess,
  fetchChewyItemsByGlobalItemVariationId,
  fetchChewyItemsByGlobalItemVariationIdFailure,
  fetchChewyItemsByGlobalItemVariationIdSuccess,
  fetchPrescription,
  fetchPrescriptionFailure,
  fetchPrescriptionSuccess,
  linkReactiveRx,
  linkReactiveRxFailure,
  linkReactiveRxSuccess,
  reactiveRxUnlinkedClientDetails,
  reactiveRxUnlinkedClientDetailsFailure,
  reactiveRxUnlinkedClientDetailsSuccess,
  refetchAllActiveRxForSoap,
  removeActiveRxListItem,
  saveReactiveRx,
  saveReactiveRxFailure,
  saveReactiveRxSuccess,
  submitAllActiveRxForChargeSheet,
  submitAllActiveRxForChargeSheetFailure,
  submitAllActiveRxForChargeSheetSuccess,
  submitAllActiveRxForSoap,
  submitAllActiveRxForSoapFailure,
  submitAllActiveRxForSoapSuccess,
} from '../duck/prescriptions'
import { finishLoading, startLoading } from '../duck/progress'
import { registerWarnAlert } from '../duck/uiAlerts'
import { wsOnMessage } from '../duck/websocket'
import { getFeatureToggle } from '../reducers/constants'
import { getSoapBusinessId, getSoapId } from '../reducers/soap'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

function* handleSoapOrdersRefetch() {
  // After submitting all pending active rx, we need to refetch soap orders to update the right rail state properly
  const soapId: string | undefined = yield select(getSoapId)
  const soapBusinessId: string | undefined = yield select(getSoapBusinessId)
  if (soapId && soapBusinessId) {
    yield put(fetchSOAPOrders(soapId, soapBusinessId))
  }
}

function* fetchPrescriptionSaga({
  payload,
}: ReturnType<typeof fetchPrescription>) {
  try {
    yield put(startLoading('prescription'))
    const { entities } = yield* requestAPI(API.fetchPrescription, payload)
    yield call(updateEntities, entities)
    yield put(fetchPrescriptionSuccess())
  } catch (error) {
    yield put(fetchPrescriptionFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('prescription'))
  }
}

function* saveReactiveRxSaga({ payload }: ReturnType<typeof saveReactiveRx>) {
  try {
    yield put(startLoading('updatePrescription'))
    const { entities } = yield* requestAPI(API.saveReactiveRx, payload)
    yield call(updateEntities, entities)
    yield put(saveReactiveRxSuccess())
  } catch (error) {
    yield put(saveReactiveRxFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('updatePrescription'))
  }
}

function* approveReactiveRxSaga({
  payload,
}: ReturnType<typeof approveReactiveRx>) {
  try {
    yield put(startLoading('updatePrescription'))
    const { entities } = yield* requestAPI(API.approveReactiveRx, payload)
    yield call(updateEntities, entities)
    yield put(approveReactiveRxSuccess())
  } catch (error) {
    yield put(approveReactiveRxFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('updatePrescription'))
  }
}

function* declineActiveRxSaga({ payload }: ReturnType<typeof declineActiveRx>) {
  try {
    yield put(startLoading('updatePrescription'))
    const { entities, result } = yield* requestAPI(API.declineActiveRx, payload)
    yield call(updateEntities, entities)
    yield put(declineActiveRxSuccess())
    yield put(removeActiveRxListItem({ id: result }))
  } catch (error) {
    yield put(declineActiveRxFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('updatePrescription'))
  }
}

function* declineReactiveRxSaga({
  payload,
}: ReturnType<typeof declineReactiveRx>) {
  try {
    yield put(startLoading('updatePrescription'))
    const { entities } = yield* requestAPI(API.declineReactiveRx, payload)
    yield call(updateEntities, entities)
    yield put(declineReactiveRxSuccess())
  } catch (error) {
    yield put(declineReactiveRxFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('updatePrescription'))
  }
}

function* deleteActiveRxSaga({ payload }: ReturnType<typeof deleteActiveRx>) {
  try {
    yield put(startLoading('updatePrescription'))
    const id = yield* requestAPI(API.deleteActiveRx, payload)
    yield put(deleteActiveRxSuccess({ id }))
    yield put(removeActiveRxListItem({ id }))

    const soapId: string | undefined = yield select(getSoapId)
    if (soapId) {
      yield put(removeOrderSuccess(id))
    }
  } catch (error) {
    yield put(deleteActiveRxFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('updatePrescription'))
  }
}

function* linkReactiveRxSaga({ payload }: ReturnType<typeof linkReactiveRx>) {
  try {
    const { entities } = yield* requestAPI(API.linkReactiveRx, payload)
    yield call(updateEntities, entities)
    yield put(linkReactiveRxSuccess())
  } catch (error) {
    yield put(linkReactiveRxFailure({ error: error as ApiError }))
  }
}

function* reactiveRxUnlinkedClientDetailsSaga({
  payload,
}: ReturnType<typeof reactiveRxUnlinkedClientDetails>) {
  try {
    const unlinkedDetails = yield* requestAPI(
      API.reactiveRxUnlinkedClientDetails,
      payload,
    )
    yield put(
      reactiveRxUnlinkedClientDetailsSuccess({
        prescriptionId: payload.prescriptionId,
        unlinkedDetails,
      }),
    )
  } catch (error) {
    yield put(
      reactiveRxUnlinkedClientDetailsFailure({ error: error as ApiError }),
    )
  }
}

function* fetchActiveRxAllowanceSaga({
  payload,
}: ReturnType<typeof fetchActiveRxWorkflowAllowance>) {
  try {
    const { business, client, patient } = yield* requestAPI(
      API.fetchActiveRxAllowance,
      payload,
    )
    yield put(
      fetchActiveRxWorkflowAllowanceSuccess({
        business,
        client,
        patient,
      }),
    )
  } catch (e) {
    const error = e as ApiError
    const errorMessage = getErrorMessage(error)
    if (errorMessage) {
      yield put(registerWarnAlert(errorMessage))
    }
    yield put(fetchActiveRxWorkflowAllowanceFailure({ error }))
  }
}

function* fetchChewyItemsByGlobalItemVariationIdSaga({
  payload,
}: ReturnType<typeof fetchChewyItemsByGlobalItemVariationId>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    if (isFoodCatalogEnabled) {
      const [{ chewyItems, globalInventoryMapping }] = yield* requestAPI(
        API.fetchGlobalItemVariations,
        { ids: [payload] },
      )
      yield put(
        fetchChewyItemsByGlobalItemVariationIdSuccess({
          chewyItems,
          globalInventoryMapping,
        }),
      )
    } else {
      const { chewyItems, globalInventoryMapping } = yield* requestAPI(
        API.fetchGlobalItemVariation,
        {
          id: payload,
        },
      )
      yield put(
        fetchChewyItemsByGlobalItemVariationIdSuccess({
          chewyItems,
          globalInventoryMapping,
        }),
      )
    }
  } catch (e) {
    const error = e as ApiError
    const errorMessage = getErrorMessage(error)
    if (errorMessage) {
      yield put(registerWarnAlert(errorMessage))
    }
    yield put(fetchChewyItemsByGlobalItemVariationIdFailure({ error }))
  }
}

function* refetchAllActiveRxForSoapSaga({
  body,
}: ReturnType<typeof wsOnMessage>) {
  yield put(fetchAllActiveRxForSoap({ soapId: body.soapId }))
}

function* fetchAllActiveRxForSoapSaga({
  payload,
}: ReturnType<typeof fetchAllActiveRxForSoap>) {
  try {
    const activeRxForSoapList = yield* requestAPI(
      API.getAllActiveRxForSoap,
      payload,
    )
    yield put(
      fetchAllActiveRxForSoapSuccess({
        list: activeRxForSoapList,
      }),
    )
  } catch (e) {
    yield put(fetchAllActiveRxForSoapFailure({ error: e as ApiError }))
  }
}

function* submitAllActiveRxForSoapSaga({
  payload,
}: ReturnType<typeof submitAllActiveRxForSoap>) {
  try {
    const isSubmitted = yield* requestAPI(API.submitAllActiveRxForSoap, payload)
    if (isSubmitted) {
      yield* fetchAllActiveRxForSoapSaga({
        payload,
        type: fetchAllActiveRxForSoap.type,
      })
    }
    yield put(submitAllActiveRxForSoapSuccess())
    yield handleSoapOrdersRefetch()
  } catch (e) {
    yield put(submitAllActiveRxForSoapFailure({ error: e as ApiError }))
  }
}

function* submitAllActiveRxForChargeSheetSaga({
  payload,
}: ReturnType<typeof submitAllActiveRxForChargeSheet>) {
  try {
    const isSubmitted = yield* requestAPI(
      API.submitAllActiveRxForChargeSheet,
      payload,
    )
    if (isSubmitted) {
      yield put(fetchClientFinanceCharges({ id: payload.clientId }))
    }
    yield put(submitAllActiveRxForChargeSheetSuccess())
  } catch (e) {
    yield put(submitAllActiveRxForChargeSheetFailure({ error: e as ApiError }))
  }
}

function* fetchAllPendingActiveRxForChargeSheetSaga({
  payload,
}: ReturnType<typeof fetchAllPendingActiveRxForChargeSheet>) {
  try {
    const pendingOrders = yield* requestAPI(
      API.getAllPendingActiveRxForChargeSheet,
      payload,
    )
    yield put(fetchAllPendingActiveRxForChargeSheetSuccess({ pendingOrders }))
  } catch (e) {
    yield put(
      fetchAllPendingActiveRxForChargeSheetFailure({ error: e as ApiError }),
    )
  }
}

function* fetchAllPendingActiveRxForSoapSaga({
  payload,
}: ReturnType<typeof fetchAllPendingActiveRxForSoap>) {
  try {
    const pendingActiveRx = yield* requestAPI(
      API.getAllPendingActiveRxForSoap,
      payload,
    )
    yield put(
      fetchAllPendingActiveRxForSoapSuccess({
        pendingActiveRx,
      }),
    )
  } catch (e) {
    yield put(fetchAllPendingActiveRxForSoapFailure({ error: e as ApiError }))
  }
}

function* watchFetchPrescription() {
  yield takeEvery(fetchPrescription.type, fetchPrescriptionSaga)
}

function* watchSaveReactiveRx() {
  yield takeLatest(saveReactiveRx.type, saveReactiveRxSaga)
}

function* watchApproveReactiveRx() {
  yield takeLatest(approveReactiveRx.type, approveReactiveRxSaga)
}

function* watchDeclineActiveRx() {
  yield takeLatest(declineActiveRx.type, declineActiveRxSaga)
}

function* watchDeclineReactiveRx() {
  yield takeLatest(declineReactiveRx.type, declineReactiveRxSaga)
}

function* watchDeleteActiveRx() {
  yield takeLatest(deleteActiveRx.type, deleteActiveRxSaga)
}

function* watchLinkReactiveRx() {
  yield takeLatest(linkReactiveRx.type, linkReactiveRxSaga)
}

function* watchReactiveRxUnlinkedClientDetails() {
  yield takeLatest(
    reactiveRxUnlinkedClientDetails.type,
    reactiveRxUnlinkedClientDetailsSaga,
  )
}

function* watchFetchActiveRxAllowanceSaga() {
  yield takeLatest(
    fetchActiveRxWorkflowAllowance.type,
    fetchActiveRxAllowanceSaga,
  )
}

function* watchFetchChewyItemsByGlobalItemVariationIdSaga() {
  yield takeLeading(
    fetchChewyItemsByGlobalItemVariationId.type,
    fetchChewyItemsByGlobalItemVariationIdSaga,
  )
}

function* watchFetchAllActiveRxForSoapSaga() {
  yield takeLeading(fetchAllActiveRxForSoap.type, fetchAllActiveRxForSoapSaga)
}

function* watchRefetchAllActiveRxForSoapSaga() {
  yield takeLeading(
    refetchAllActiveRxForSoap.type,
    refetchAllActiveRxForSoapSaga,
  )
}

function* watchSubmitAllActiveRxForSoapSaga() {
  yield takeLeading(submitAllActiveRxForSoap.type, submitAllActiveRxForSoapSaga)
}

function* watchSubmitAllActiveRxForChargeSheetSaga() {
  yield takeLeading(
    submitAllActiveRxForChargeSheet.type,
    submitAllActiveRxForChargeSheetSaga,
  )
}

function* watchFetchAllPendingActiveRxForChargeSheet() {
  yield takeLeading(
    fetchAllPendingActiveRxForChargeSheet.type,
    fetchAllPendingActiveRxForChargeSheetSaga,
  )
}

function* watchFetchAllPendingActiveRxForSoap() {
  yield takeLeading(
    fetchAllPendingActiveRxForSoap.type,
    fetchAllPendingActiveRxForSoapSaga,
  )
}

export function* prescriptionsSaga() {
  yield all([
    watchFetchPrescription(),
    watchSaveReactiveRx(),
    watchApproveReactiveRx(),
    watchDeclineActiveRx(),
    watchDeclineReactiveRx(),
    watchDeleteActiveRx(),
    watchLinkReactiveRx(),
    watchReactiveRxUnlinkedClientDetails(),
    watchFetchActiveRxAllowanceSaga(),
    watchFetchChewyItemsByGlobalItemVariationIdSaga(),
    watchFetchAllActiveRxForSoapSaga(),
    watchSubmitAllActiveRxForSoapSaga(),
    watchSubmitAllActiveRxForChargeSheetSaga(),
    watchFetchAllPendingActiveRxForChargeSheet(),
    watchFetchAllPendingActiveRxForSoap(),
    watchRefetchAllActiveRxForSoapSaga(),
  ])
}
