import React from 'react'

import { DiagnosesState } from '~/types'

import DifferentialDiagnoseActions from './diff-dx/actions/DifferentialDiagnoseActions'
import DifferentialDiagnoseButtonSection from './diff-dx/button-sections/DifferentialDiagnoseButtonSection'
import DifferentialDiagnoseContent from './diff-dx/content/DifferentialDiagnoseContent'
import ItemWithBadges from './ItemWithBadges'

export interface DifferentialDiagnoseProps {
  data: DiagnosesState
  editDisabled?: boolean
  onChange: (id: string, payload?: DiagnosesState) => void
  onNotesChange: (notes: string) => void
}

const DifferentialDiagnose = ({
  data,
  onChange,
  onNotesChange,
  editDisabled,
}: DifferentialDiagnoseProps) => (
  <ItemWithBadges
    actions={
      <DifferentialDiagnoseActions
        diagnose={data}
        editDisabled={editDisabled}
        onChange={onChange}
        onNotesChange={onNotesChange}
      />
    }
    alignItems="flex-start"
    buttonSection={
      <DifferentialDiagnoseButtonSection
        diagnose={data}
        editDisabled={editDisabled}
        onChange={onChange}
      />
    }
    direction="column"
    order={data}
  >
    <DifferentialDiagnoseContent diagnose={data} />
  </ItemWithBadges>
)

export default DifferentialDiagnose
