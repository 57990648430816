import React, { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Moment } from 'moment'
import {
  DateFormat,
  moment,
  Nil,
  PuiPopperProps,
  PuiSelect,
  PuiTextField,
  PuiTextFieldProps,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import TimeRangeInput from '../../form-inputs/TimeRangeInput'
import TimeInput, { TimeInputTypes } from '../TimeInput'
import Selector from './Selector'
import SelectorPopper from './SelectorPopper'

const useStyles = makeStyles(
  (theme) => ({
    label: {
      width: 'auto !important',
      whiteSpace: 'pre',
    },
    inputDense: {
      minWidth: 104,
      maxWidth: 132,
    },
    inputNormal: {
      width: 155,
    },
    endPeriodSelect: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    periodSelect: {
      paddingRight: theme.spacing(4),
    },
    selectContainerNoOffset: {
      marginBottom: theme.spacing(0.5),
    },
  }),
  { name: 'TimeSelector' },
)

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
const { useMeridian } = moment.localeData()._custom || {}

const TimePeriods = [
  {
    id: 'am',
    name: 'am',
  },
  {
    id: 'pm',
    name: 'pm',
  },
]

const RANGE_LABEL_SPACE_WITH_MERIDIAN = new Array(7).fill(' ').join('')

const TIME_FORMAT = useMeridian
  ? DateFormat.FULL_TIME_WITH_MERIDIAN
  : DateFormat.FULL_TIME_WITHOUT_MERIDIAN

const MAX_DEFAULT_DURATION = 240

const MIN_RANGE = 10

const PERIOD_SELECT_KEY_PROP = 'name'

const formatRequiredLabel = (label: string) => `${label}*`

const DefaultStartTimeLabel = formatRequiredLabel(
  i18n.t('Time:TIME_SELECTOR.START_TIME'),
)
const DefaultEndTimeLabel = formatRequiredLabel(
  i18n.t('Time:TIME_SELECTOR.END_TIME'),
)

export type TimeSelectorProps = PuiTextFieldProps & {
  SelectorProps?: any
  TextFieldComponent?: React.JSXElementConstructor<any>
  disablePortal?: boolean
  disabled?: boolean
  endValue?: string
  fromLabel?: string | Nil | boolean
  fullWidth?: boolean
  keepRange?: boolean
  label?: string
  offsetArrows?: boolean
  onEndChange?: (value: string) => void
  onStartChange: (value: string) => void
  placement?: PuiPopperProps['placement']
  range?: boolean
  startValue?: string
  withPopper?: boolean
}

const TimeSelector = forwardRef<HTMLDivElement, TimeSelectorProps>(
  function TimeSelector(
    {
      offsetArrows,
      withPopper = true,
      disablePortal,
      range,
      label,
      disabled,
      endValue: endValueProp,
      startValue,
      keepRange = true,
      onStartChange,
      onEndChange,
      placement = 'bottom',
      fullWidth,
      TextFieldComponent,
      fromLabel = i18n.t('Time:TIME_SELECTOR.FROM'),
      SelectorProps = {},
      ...rest
    },
    ref,
  ) {
    const classes = useStyles(rest)
    const { t } = useTranslation('Time')

    const endValue = endValueProp || startValue

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>()
    const [popperOpen, setPopperOpen] = useState(false)
    const [inputFocused, setInputFocused] = useState(false)
    const [inputValue, setInputValue] = useState('')

    const selectorRef = useRef<HTMLDivElement>(null)

    const momentStart = (startValue ? moment(startValue) : moment()).startOf(
      'minute',
    )
    const momentEnd = (endValue ? moment(endValue) : moment()).startOf('minute')

    const startPeriod = momentStart.format('a')
    const endPeriod = momentEnd.format('a')

    const formattedStart = momentStart.format(TIME_FORMAT)
    const formattedEnd = momentEnd.format(TIME_FORMAT)
    const formattedTime = `${formattedStart} ${
      range ? `- ${formattedEnd}` : ''
    }`

    const handleClose = (event?: React.KeyboardEvent<HTMLDivElement>) => {
      const target = event?.target as Node
      if (
        event &&
        selectorRef.current &&
        selectorRef.current?.contains(target)
      ) {
        return
      }
      setPopperOpen(false)
    }

    const setEndFromStart = (currentDuration: number) => {
      const midnight = momentStart.clone()
      midnight
        .set({
          hour: 0,
          minutes: 0,
        })
        .add(1, 'day')
      const minutesToMidnight = midnight.diff(momentStart, 'minutes') - 1
      const newEnd = momentStart.clone()
      newEnd.add(Math.min(currentDuration, minutesToMidnight), 'minutes')

      if (onEndChange) {
        onEndChange(newEnd.toISOString())
      }
    }

    const getMinuteDiff = () =>
      Math.min(momentEnd.diff(momentStart, 'minutes'), MAX_DEFAULT_DURATION)

    const updateMoment = (
      momentDate: Moment,
      newTime: number,
      prop: string,
    ) => {
      if (prop === 'h') {
        momentDate.set({ h: newTime, m: moment(startValue).minutes() })
      } else {
        momentDate.set({ h: moment(startValue).hours(), m: newTime })
      }
    }

    const onStartTimeChange = (newTime: number, prop: string) => {
      const noon = moment()
      noon.set({
        hour: 12,
        minutes: 0,
      })
      const currentDuration = getMinuteDiff()
      const start = momentStart.isValid() ? momentStart : noon
      updateMoment(start, newTime, prop)
      onStartChange(
        start.isValid() ? start.toISOString() : moment().toISOString(),
      )
      if (range) {
        if (keepRange) {
          setEndFromStart(currentDuration)
        } else if (start.isSameOrAfter(momentEnd, 'm')) {
          setEndFromStart(MIN_RANGE)
        }
      }
    }

    const onEndTimeChange = (newTime: number, prop: string) => {
      const noon = moment()
      noon.set({
        hour: 12,
        minutes: 0,
      })
      const end = momentEnd.isValid() ? momentEnd : noon
      const newMomentEnd = moment(end).set({ [prop]: newTime })

      if (momentStart.isAfter(newMomentEnd, 'm')) {
        return
      }

      end.set({ h: newMomentEnd.hour(), m: newMomentEnd.minute() })

      if (onEndChange) {
        onEndChange(end.toISOString())
      }
    }

    const onStartHourChange = (newHour: number, periodFromHour?: boolean) => {
      if (useMeridian && startPeriod === 'pm' && !periodFromHour) {
        newHour = 12 + (newHour % 12)
      }
      onStartTimeChange(newHour, 'h')
    }

    const onStartMinuteChange = (newMinute: number) => {
      onStartTimeChange(newMinute, 'm')
    }

    const onEndHourChange = (newHour: number, periodFromHour?: boolean) => {
      if (useMeridian && endPeriod === 'pm' && !periodFromHour) {
        newHour = 12 + (newHour % 12)
      }
      onEndTimeChange(newHour, 'h')
    }
    const onEndMinuteChange = (newMinute: number) =>
      onEndTimeChange(newMinute, 'm')

    const setMeridiem = (newMeridiem: string, momentDate: Moment) => {
      if (newMeridiem === 'am' && momentDate.hours() > 12) {
        return momentDate.hours() - 12
      }
      return momentDate.hours() + 12
    }

    const setStartPeriod = (newMeridiem: string) => {
      if (!useMeridian) {
        return
      }
      const hour = setMeridiem(newMeridiem, momentStart)
      onStartTimeChange(hour, 'h')
    }

    const setEndPeriod = (newMeridiem: string) => {
      if (!useMeridian) {
        return
      }
      const hour = setMeridiem(newMeridiem, momentEnd)
      onEndTimeChange(hour, 'h')
    }

    const updateMoments = (newValue: string) => {
      const [newStartTime, newEndTime] = newValue.split(' - ')
      const newStartTimeMoment = moment(newStartTime, TIME_FORMAT)
      const newEndTimeMoment = moment(newEndTime, TIME_FORMAT)

      if (newStartTimeMoment.hour() !== momentStart.hour()) {
        onStartTimeChange(newStartTimeMoment.hour(), 'h')
      } else if (newStartTimeMoment.minute() !== momentStart.minute()) {
        onStartTimeChange(newStartTimeMoment.minute(), 'm')
      }

      if (range) {
        if (newEndTimeMoment.hour() !== momentEnd.hour()) {
          onEndTimeChange(newEndTimeMoment.hour(), 'h')
        } else if (newEndTimeMoment.minute() !== momentEnd.minute()) {
          onEndTimeChange(newEndTimeMoment.minute(), 'm')
        }
      }
    }

    const onTextFieldChange = (newValue: string) => {
      setInputValue(newValue)
      updateMoments(newValue)
    }

    const onTextFieldFocused = (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select()
      setInputValue(formattedTime)
      setInputFocused(true)
    }

    const SelectorComponent = withPopper ? SelectorPopper : Selector

    const defaultRangeLabelSpace = useMeridian
      ? RANGE_LABEL_SPACE_WITH_MERIDIAN
      : ' '
    const defaultLabel = range
      ? `${DefaultStartTimeLabel}${defaultRangeLabelSpace}${DefaultEndTimeLabel}`
      : DefaultStartTimeLabel

    const openPopper = ({
      currentTarget,
    }: React.MouseEvent<HTMLDivElement>) => {
      if (!disabled) {
        setAnchorEl(currentTarget)
        setPopperOpen(true)
      }
    }

    return (
      <>
        {withPopper &&
          (TextFieldComponent ? (
            <TextFieldComponent
              ref={ref}
              value={startValue ? momentStart.format(TIME_FORMAT) : undefined}
              onClick={openPopper}
            />
          ) : (
            <PuiTextField
              InputLabelProps={{
                className: classes.label,
                shrink: true,
              }}
              InputProps={{
                inputComponent: TimeRangeInput,
                inputProps: {
                  range,
                  enableMeridian: useMeridian,
                },
              }}
              aria-owns={anchorEl ? 'menu-list-grow' : undefined}
              className={classNames({
                [classes.inputNormal]: range && !fullWidth,
                [classes.inputDense]: !range && !fullWidth,
              })}
              disabled={disabled}
              label={label || defaultLabel}
              ref={ref}
              value={inputFocused ? inputValue : formattedTime}
              onBlur={() => setInputFocused(false)}
              onChange={Utils.handleFormTextInput(onTextFieldChange)}
              onClick={openPopper}
              onFocus={onTextFieldFocused}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleClose()
                }
              }}
              {...rest}
            />
          ))}
        <SelectorComponent
          keepMounted
          FromInput={
            <>
              <Grid item xs>
                <TimeInput
                  enableMeridian={useMeridian}
                  offsetArrows={offsetArrows}
                  type={TimeInputTypes.HOUR}
                  value={momentStart.hour()}
                  onChange={onStartHourChange}
                />
              </Grid>
              <Grid item mx={1}>
                <Text variant="body">:</Text>
              </Grid>
              <Grid item xs>
                <TimeInput
                  enableMeridian={useMeridian}
                  offsetArrows={offsetArrows}
                  type={TimeInputTypes.MINUTE}
                  value={momentStart.minute() || 0}
                  onChange={onStartMinuteChange}
                />
              </Grid>
            </>
          }
          FromSelect={
            useMeridian ? (
              <PuiSelect
                classes={{
                  select: classes.periodSelect,
                }}
                input={<Input id="time-period-start-select" />}
                items={TimePeriods}
                keyProp={PERIOD_SELECT_KEY_PROP}
                renderEmpty={false}
                value={startPeriod}
                onChange={Utils.handleFormSelectInput(setStartPeriod)}
              />
            ) : null
          }
          ToInput={
            <>
              <Grid item xs>
                <TimeInput
                  enableMeridian={useMeridian}
                  offsetArrows={offsetArrows}
                  type={TimeInputTypes.HOUR}
                  value={momentEnd.hour()}
                  onChange={onEndHourChange}
                />
              </Grid>
              <Grid item mx={1}>
                <Text variant="body">:</Text>
              </Grid>
              <Grid item xs>
                <TimeInput
                  enableMeridian={useMeridian}
                  offsetArrows={offsetArrows}
                  type={TimeInputTypes.MINUTE}
                  value={momentEnd.minute() || 0}
                  onChange={onEndMinuteChange}
                />
              </Grid>
            </>
          }
          ToSelect={
            useMeridian ? (
              <PuiSelect
                classes={{
                  select: classNames(
                    classes.periodSelect,
                    classes.endPeriodSelect,
                  ),
                }}
                input={<Input id="time-period-end-select" />}
                items={TimePeriods}
                keyProp={PERIOD_SELECT_KEY_PROP}
                renderEmpty={false}
                value={endPeriod}
                onChange={Utils.handleFormSelectInput(setEndPeriod)}
              />
            ) : null
          }
          anchorEl={anchorEl}
          classes={{
            selectContainer: classNames({
              [classes.selectContainerNoOffset]: !offsetArrows,
            }),
            ...SelectorProps?.classes,
          }}
          disablePortal={disablePortal}
          fromLabel={fromLabel}
          open={popperOpen}
          placement={placement}
          range={range}
          ref={selectorRef}
          toLabel={t('Time:TIME_SELECTOR.TO')}
          onClose={handleClose}
          {...SelectorProps}
        />
      </>
    )
  },
)

export default TimeSelector
