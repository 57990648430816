import { Variation } from '~/types'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

const ReOrderPointCell = (item: Variation) => {
  const getInStockAmount = useGetInStockAmount()

  return getInStockAmount({
    amount: item.reorderPoint,
    amountUnitsId: item.reorderPointUnitId,
    perPackageUnitsId: item.perPackageUnitsId,
    packageTypeId: item.packageTypeId,
  })
}

export default ReOrderPointCell
