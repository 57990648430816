import { all, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { TableFilter } from '~/types'

import {
  createPrice,
  createPriceFailure,
  createPriceSuccess,
  deletePrice,
  deletePriceFailure,
  deletePriceSuccess,
  editPrice,
  editPriceFailure,
  editPriceSuccess,
  fetchMoreItemsForProceduresList,
  fetchMoreItemsForProceduresListFailure,
  fetchMoreItemsForProceduresListSuccess,
  fetchProcedure,
  fetchProcedureFailure,
  fetchProceduresList,
  fetchProceduresListFailure,
  fetchProceduresListSuccess,
  fetchProcedureSuccess,
  updateProcedures,
} from '../actions/procedures'
import { updateSearchHighlights } from '../actions/search'
import {
  CREATE_PRICE,
  DELETE_PRICE,
  EDIT_PRICE,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST,
  FETCH_PROCEDURE,
  FETCH_PROCEDURES_LIST,
} from '../actions/types/procedures'
import { finishLoading, startLoading } from '../duck/progress'
import { getProceduresFilters } from '../reducers/procedures'
import requestAPI from './utils/requestAPI'

export function* fetchProceduresListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchProceduresList>) {
  try {
    const filters: Record<string, TableFilter> =
      yield select(getProceduresFilters)
    yield put(startLoading('proceduresList'))
    const categoryIds = (filters?.category?.value || []) as string[]
    const {
      result: { data: list, totalCount, highlights },
      entities: { procedures },
    } = yield* requestAPI(
      API.fetchProcedures,
      from,
      to,
      query,
      categoryIds?.join(','),
    )
    yield put(updateSearchHighlights(highlights, totalCount))
    yield put(updateProcedures(procedures))
    yield put(fetchProceduresListSuccess(list, totalCount))
    yield put(finishLoading('proceduresList'))
  } catch (error) {
    yield put(fetchProceduresListFailure(error as ApiError))
    yield put(finishLoading('proceduresList'))
  }
}

export function* fetchMoreItemsForProceduresListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForProceduresList>) {
  try {
    const filters: Record<string, TableFilter> =
      yield select(getProceduresFilters)
    const categoryIds = (filters?.category?.value || []) as string[]
    const {
      result: { data: list, totalCount },
      entities: { procedures },
    } = yield* requestAPI(
      API.fetchProcedures,
      from,
      to,
      query,
      categoryIds?.join(','),
    )
    yield put(updateProcedures(procedures))
    yield put(fetchMoreItemsForProceduresListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForProceduresListFailure(error as ApiError))
  }
}

export function* fetchProcedureSaga({ id }: ReturnType<typeof fetchProcedure>) {
  try {
    const {
      entities: { procedures },
    } = yield* requestAPI(API.fetchProcedure, id)
    yield put(updateProcedures(procedures))
    yield put(fetchProcedureSuccess())
  } catch (error) {
    yield put(fetchProcedureFailure(error as ApiError))
  }
}

export function* createPriceSaga({
  procedureId,
  price,
}: ReturnType<typeof createPrice>) {
  try {
    const procedure = yield* requestAPI(API.createPrice, procedureId, price)
    yield put(createPriceSuccess(procedure))
  } catch (error) {
    yield put(createPriceFailure(error as ApiError))
  }
}

export function* editPriceSaga({
  procedureId,
  price,
}: ReturnType<typeof editPrice>) {
  try {
    const procedure = yield* requestAPI(API.editPrice, procedureId, price)
    yield put(editPriceSuccess(procedure))
  } catch (error) {
    yield put(editPriceFailure(error as ApiError))
  }
}

export function* deletePriceSaga({
  procedureId,
  priceId,
}: ReturnType<typeof deletePrice>) {
  try {
    const procedure = yield* requestAPI(API.deletePrice, procedureId, priceId)
    yield put(deletePriceSuccess(procedure))
  } catch (error) {
    yield put(deletePriceFailure(error as ApiError))
  }
}

function* watchFetchProceduresList() {
  yield takeLeading(FETCH_PROCEDURES_LIST, fetchProceduresListSaga)
}

function* watchFetchMoreItemsForProceduresList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST,
    fetchMoreItemsForProceduresListSaga,
  )
}

function* watchFetchProcedure() {
  yield takeLeading(FETCH_PROCEDURE, fetchProcedureSaga)
}

function* watchCreatePrice() {
  yield takeLeading(CREATE_PRICE, createPriceSaga)
}

function* watchEditPrice() {
  yield takeLeading(EDIT_PRICE, editPriceSaga)
}

function* watchDeletePrice() {
  yield takeLeading(DELETE_PRICE, deletePriceSaga)
}

export default function* proceduresSaga() {
  yield all([
    watchFetchProceduresList(),
    watchFetchMoreItemsForProceduresList(),
    watchFetchProcedure(),
    watchCreatePrice(),
    watchEditPrice(),
    watchDeletePrice(),
  ])
}
