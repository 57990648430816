import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Business } from '@pbt/pbt-ui-components'

import { Journal } from '~/types'
import { auth0Enabled, secondLevelMerge } from '~/utils'

import {
  ADD_BUSINESS_TO_JOURNAL,
  ADD_USER_TO_JOURNAL,
  CLOSE_CLIENTS_SPLASH_SCREEN,
  CLOSE_TIMEZONE_ALERT,
} from '../actions/types/journal'
import type { RootState } from '../index'

export type JournalState = {
  hasClosedClientsSplashScreen: boolean
  hasClosedTimezoneAlert: boolean
  lastBusiness: Business | null
  lastUserId: string | null
  map: Journal
}

export const JOURNAL_INITIAL_STATE: JournalState = {
  map: {},
  lastUserId: null,
  lastBusiness: null,
  hasClosedClientsSplashScreen: false,
  hasClosedTimezoneAlert: false,
}

const ENTRY_FIELDS = [
  'id',
  'email',
  'avatar',
  'avatarThumbnail',
  'firstName',
  'lastName',
  'currentBusinessId',
  'locationPickerPinned',
  'ssoUser',
]

const journal = (
  state: JournalState = JOURNAL_INITIAL_STATE,
  action: AnyAction,
): JournalState => {
  switch (action.type) {
    case ADD_USER_TO_JOURNAL:
      return {
        ...state,
        map: secondLevelMerge(state.map, {
          [action.user.id]: R.pick(ENTRY_FIELDS, action.user),
        }),
        lastUserId: action.user.id,
      }
    case ADD_BUSINESS_TO_JOURNAL:
      // removing extra and sensitive business configuration from LS journal handler
      return {
        ...state,
        lastBusiness: auth0Enabled
          ? R.pick(['id', 'logo', 'name', 'timeZone'], action.business)
          : action.business,
      }
    case CLOSE_CLIENTS_SPLASH_SCREEN:
      return { ...state, hasClosedClientsSplashScreen: true }
    case CLOSE_TIMEZONE_ALERT:
      return { ...state, hasClosedTimezoneAlert: true }
    default:
      return state
  }
}

export const getJournal = (state: RootState): JournalState => state.journal
export const getJournalMap = (state: RootState) => getJournal(state).map
export const getLastUserId = (state: RootState) => getJournal(state).lastUserId
export const getLastUser = createSelector(
  getJournalMap,
  getLastUserId,
  (map, lastUserId) => (lastUserId ? map[lastUserId] : undefined),
)
export const getLastBusiness = (state: RootState) =>
  getJournal(state).lastBusiness
export const getHasClosedClientsSplashScreen = (state: RootState) =>
  getJournal(state).hasClosedClientsSplashScreen
export const getHasClosedTimezoneAlert = (state: RootState) =>
  getJournal(state).hasClosedTimezoneAlert

export default journal
