import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import {
  Business,
  BusinessAutoReplySettings,
  FieldProp,
  useFields,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import i18nPortal from '~/locales/i18n'
import {
  AutoReplyCommunicationsSubSection,
  BusinessHours,
  PracticeAutoReplyCommunicationsFields,
} from '~/types'
import { useBoopEnabled } from '~/utils/boop'
import { hasHtmlFieldChanged } from '~/utils/htmlUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const getAfterBusinessHoursFields = ({
  emailAutoReplySettings,
  smsAutoReplySettings,
  boopAutoReplySettings,
}: BusinessAutoReplySettings): FieldProp[] => [
  {
    name: 'emailAfterBusinessHoursEnabled',
    initialValue: emailAutoReplySettings?.afterHoursMessage.enabled ?? false,
    type: 'toggle',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.SEND_AUTO_REPLY_TRANSPORT',
      {
        transport: i18nPortal.t('Common:EMAIL'),
      },
    ),
  },
  {
    name: 'emailAfterBusinessHoursMessage',
    initialValue: emailAutoReplySettings?.afterHoursMessage.message ?? '',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.AFTER_BUSINESS_HOURS_AUTOMATIC_TRANSPORT',
      {
        transport: i18nPortal.t('Common:EMAIL'),
      },
    ),
  },
  {
    name: 'smsAfterBusinessHoursEnabled',
    initialValue: smsAutoReplySettings?.afterHoursMessage.enabled ?? false,
    type: 'toggle',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.SEND_AUTO_REPLY_TRANSPORT',
      {
        transport: i18nPortal.t('Common:SMS'),
      },
    ),
  },
  {
    name: 'smsAfterBusinessHoursMessage',
    initialValue: smsAutoReplySettings?.afterHoursMessage.message ?? '',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.AFTER_BUSINESS_HOURS_AUTOMATIC_TRANSPORT',
      {
        transport: i18nPortal.t('Common:SMS'),
      },
    ),
  },
  {
    name: 'boopAfterBusinessHoursEnabled',
    initialValue: boopAutoReplySettings?.afterHoursMessage.enabled ?? false,
    type: 'toggle',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.SEND_AUTO_REPLY_TRANSPORT',
      {
        transport: i18nPortal.t('Common:BOOP_SYSTEM_NAME_MESSAGE'),
      },
    ),
  },
  {
    name: 'boopAfterBusinessHoursMessage',
    initialValue: boopAutoReplySettings?.afterHoursMessage.message ?? '',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.AFTER_BUSINESS_HOURS_AUTOMATIC_TRANSPORT',
      {
        transport: i18nPortal.t('Common:BOOP_SYSTEM_NAME_MESSAGE'),
      },
    ),
  },
]

const getDuringBusinessHoursFields = ({
  emailAutoReplySettings,
  smsAutoReplySettings,
  boopAutoReplySettings,
}: BusinessAutoReplySettings): FieldProp[] => [
  {
    name: 'emailDuringBusinessHoursEnabled',
    initialValue: emailAutoReplySettings?.openHoursMessage.enabled ?? false,
    type: 'toggle',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.SEND_AUTO_REPLY_TRANSPORT',
      {
        transport: i18nPortal.t('Common:EMAIL'),
      },
    ),
  },
  {
    name: 'emailDuringBusinessHoursMessage',
    initialValue: emailAutoReplySettings?.openHoursMessage.message ?? '',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.BUSINESS_HOURS_AUTOMATIC_TRANSPORT',
      {
        transport: i18nPortal.t('Common:EMAIL'),
      },
    ),
  },
  {
    name: 'smsDuringBusinessHoursEnabled',
    initialValue: smsAutoReplySettings?.openHoursMessage.enabled ?? false,
    type: 'toggle',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.SEND_AUTO_REPLY_TRANSPORT',
      {
        transport: i18nPortal.t('Common:SMS'),
      },
    ),
  },
  {
    name: 'smsDuringBusinessHoursMessage',
    initialValue: smsAutoReplySettings?.openHoursMessage.message ?? '',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.BUSINESS_HOURS_AUTOMATIC_TRANSPORT',
      {
        transport: i18nPortal.t('Common:SMS'),
      },
    ),
  },
  {
    name: 'boopDuringBusinessHoursEnabled',
    initialValue: boopAutoReplySettings?.openHoursMessage.enabled ?? false,
    type: 'toggle',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.SEND_AUTO_REPLY_TRANSPORT',
      {
        transport: i18nPortal.t('Common:BOOP_SYSTEM_NAME_MESSAGE'),
      },
    ),
  },
  {
    name: 'boopDuringBusinessHoursMessage',
    initialValue: boopAutoReplySettings?.openHoursMessage.message ?? '',
    label: i18nPortal.t(
      'Businesses:AUTO_REPLY_COMMUNICATIONS.BUSINESS_HOURS_AUTOMATIC_TRANSPORT',
      {
        transport: i18nPortal.t('Common:BOOP_SYSTEM_NAME_MESSAGE'),
      },
    ),
  },
]

const MAX_LENGTH_LARGE = 1000
const MAX_LENGTH_SMALL = 355

export const useAutoReplyCommunicationsFields = (business: Business) => {
  const { t } = useTranslation(['Businesses', 'Common'])

  const showBoopAutoReplySettings = useBoopEnabled(business)

  const {
    smsAutoReplySettings,
    boopAutoReplySettings,
    emailAutoReplySettings,
    name: businessName,
    id: businessId,
  } = business

  const [openAutoReplyMessagePreviewDialog] = useDialog(
    DialogNames.AUTO_REPLY_MESSAGE_PREVIEW_DIALOG,
  )

  const { fields, reset, validate } = useFields([
    ...getAfterBusinessHoursFields({
      smsAutoReplySettings,
      boopAutoReplySettings,
      emailAutoReplySettings,
    }),
    ...getDuringBusinessHoursFields({
      smsAutoReplySettings,
      boopAutoReplySettings,
      emailAutoReplySettings,
    }),
  ])

  const {
    emailDuringBusinessHoursEnabled,
    emailDuringBusinessHoursMessage,
    smsDuringBusinessHoursEnabled,
    smsDuringBusinessHoursMessage,
    boopDuringBusinessHoursEnabled,
    boopDuringBusinessHoursMessage,
  } = fields

  const {
    emailAfterBusinessHoursEnabled,
    emailAfterBusinessHoursMessage,
    smsAfterBusinessHoursEnabled,
    smsAfterBusinessHoursMessage,
    boopAfterBusinessHoursEnabled,
    boopAfterBusinessHoursMessage,
  } = fields

  usePracticeFieldsSection<PracticeAutoReplyCommunicationsFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.AUTO_REPLY_COMMUNICATIONS,
    parsedFields: {
      smsAutoReplySettings: {
        openHoursMessage: {
          enabled: smsDuringBusinessHoursEnabled.value,
          message: smsDuringBusinessHoursMessage.value,
        },
        afterHoursMessage: {
          enabled: smsAfterBusinessHoursEnabled.value,
          message: smsAfterBusinessHoursMessage.value,
        },
      },
      boopAutoReplySettings: {
        openHoursMessage: {
          enabled: boopDuringBusinessHoursEnabled.value,
          message: boopDuringBusinessHoursMessage.value,
        },
        afterHoursMessage: {
          enabled: boopAfterBusinessHoursEnabled.value,
          message: boopAfterBusinessHoursMessage.value,
        },
      },
      emailAutoReplySettings: {
        openHoursMessage: {
          enabled: emailDuringBusinessHoursEnabled.value,
          message: emailDuringBusinessHoursMessage.value,
        },
        afterHoursMessage: {
          enabled: emailAfterBusinessHoursEnabled.value,
          message: emailAfterBusinessHoursMessage.value,
        },
      },
    },
    validate,
    reset,
    softUpdate: R.any(Boolean, [
      hasHtmlFieldChanged(smsAfterBusinessHoursMessage),
      hasHtmlFieldChanged(smsDuringBusinessHoursMessage),
      hasHtmlFieldChanged(emailAfterBusinessHoursMessage),
      hasHtmlFieldChanged(emailDuringBusinessHoursMessage),
      hasHtmlFieldChanged(boopAfterBusinessHoursMessage),
      hasHtmlFieldChanged(boopDuringBusinessHoursMessage),
      smsAfterBusinessHoursEnabled.value !==
        smsAfterBusinessHoursEnabled.initialValue,
      smsDuringBusinessHoursEnabled.value !==
        smsDuringBusinessHoursEnabled.initialValue,
      emailAfterBusinessHoursEnabled.value !==
        emailAfterBusinessHoursEnabled.initialValue,
      emailDuringBusinessHoursEnabled.value !==
        emailDuringBusinessHoursEnabled.initialValue,
      boopAfterBusinessHoursEnabled.value !==
        boopAfterBusinessHoursEnabled.initialValue,
      boopDuringBusinessHoursEnabled.value !==
        boopDuringBusinessHoursEnabled.initialValue,
    ]),
  })

  const boopMessagesLabel = t('Common:BOOP_MESSAGES')
  const emailMessagesLabel = t('Common:EMAIL_MESSAGES')
  const smsMessagesLabel = t('Common:SMS_MESSAGES')

  const duringBusinessHoursSubSections: AutoReplyCommunicationsSubSection[] = [
    {
      id: 'EMAIL',
      toggleField: emailDuringBusinessHoursEnabled,
      maxLength: MAX_LENGTH_LARGE,
      messageField: emailDuringBusinessHoursMessage,
      tooltipText: t(
        'Businesses:AUTO_REPLY_COMMUNICATIONS.TOOLTIP_DURING_BUSINESS_HOURS',
        {
          transportMessages: emailMessagesLabel,
        },
      ),
      withTextFormat: true,
      onPreview: () => {
        openAutoReplyMessagePreviewDialog({
          businessId,
          message: emailDuringBusinessHoursMessage.value,
          transport: ConversationTransport.Email,
          type: BusinessHours.DURING_HOURS,
        })
      },
    },
    {
      id: 'SMS',
      toggleField: smsDuringBusinessHoursEnabled,
      maxLength: MAX_LENGTH_SMALL,
      messageField: smsDuringBusinessHoursMessage,
      tooltipText: t(
        'Businesses:AUTO_REPLY_COMMUNICATIONS.TOOLTIP_DURING_BUSINESS_HOURS',
        {
          transportMessages: smsMessagesLabel,
        },
      ),
      withTextFormat: false,
      onPreview: () => {
        openAutoReplyMessagePreviewDialog({
          businessId,
          message: smsDuringBusinessHoursMessage.value,
          transport: ConversationTransport.Sms,
          type: BusinessHours.DURING_HOURS,
        })
      },
    },
    ...(showBoopAutoReplySettings
      ? [
          {
            id: 'BOOP',
            toggleField: boopDuringBusinessHoursEnabled,
            maxLength: MAX_LENGTH_LARGE,
            messageField: boopDuringBusinessHoursMessage,
            tooltipText: t(
              'Businesses:AUTO_REPLY_COMMUNICATIONS.TOOLTIP_DURING_BUSINESS_HOURS',
              {
                transportMessages: boopMessagesLabel,
              },
            ),
            withTextFormat: false,
            onPreview: () => {
              openAutoReplyMessagePreviewDialog({
                businessId,
                businessName,
                message: boopDuringBusinessHoursMessage.value,
                transport: ConversationTransport.Boop,
                type: BusinessHours.DURING_HOURS,
              })
            },
          },
        ]
      : []),
  ]

  const afterBusinessHoursSubSections: AutoReplyCommunicationsSubSection[] = [
    {
      id: 'EMAIL',
      toggleField: emailAfterBusinessHoursEnabled,
      maxLength: MAX_LENGTH_LARGE,
      messageField: emailAfterBusinessHoursMessage,
      tooltipText: t(
        'Businesses:AUTO_REPLY_COMMUNICATIONS.TOOLTIP_AFTER_BUSINESS_HOURS',
        {
          transportMessages: emailMessagesLabel,
        },
      ),
      withTextFormat: true,
      onPreview: () => {
        openAutoReplyMessagePreviewDialog({
          businessId,
          message: emailAfterBusinessHoursMessage.value,
          transport: ConversationTransport.Email,
          type: BusinessHours.AFTER_HOURS,
        })
      },
    },
    {
      id: 'SMS',
      toggleField: smsAfterBusinessHoursEnabled,
      maxLength: MAX_LENGTH_SMALL,
      messageField: smsAfterBusinessHoursMessage,
      tooltipText: t(
        'Businesses:AUTO_REPLY_COMMUNICATIONS.TOOLTIP_AFTER_BUSINESS_HOURS',
        {
          transportMessages: smsMessagesLabel,
        },
      ),
      withTextFormat: false,
      onPreview: () => {
        openAutoReplyMessagePreviewDialog({
          businessId,
          message: smsAfterBusinessHoursMessage.value,
          transport: ConversationTransport.Sms,
          type: BusinessHours.AFTER_HOURS,
        })
      },
    },
    ...(showBoopAutoReplySettings
      ? [
          {
            id: 'BOOP',
            toggleField: boopAfterBusinessHoursEnabled,
            maxLength: MAX_LENGTH_LARGE,
            messageField: boopAfterBusinessHoursMessage,
            tooltipText: t(
              'Businesses:AUTO_REPLY_COMMUNICATIONS.TOOLTIP_AFTER_BUSINESS_HOURS',
              {
                transportMessages: boopMessagesLabel,
              },
            ),
            withTextFormat: false,
            onPreview: () => {
              openAutoReplyMessagePreviewDialog({
                businessId,
                businessName,
                message: boopAfterBusinessHoursMessage.value,
                transport: ConversationTransport.Boop,
                type: BusinessHours.AFTER_HOURS,
              })
            },
          },
        ]
      : []),
  ]

  const autoReplyCommunicationsSections = [
    {
      id: BusinessHours.DURING_HOURS,
      section: t('Businesses:AUTO_REPLY_COMMUNICATIONS.DURING_BUSINESS_HOURS'),
      subSections: duringBusinessHoursSubSections,
    },
    {
      id: BusinessHours.AFTER_HOURS,
      section: t('Businesses:AUTO_REPLY_COMMUNICATIONS.AFTER_BUSINESS_HOURS'),
      subSections: afterBusinessHoursSubSections,
    },
  ]

  return autoReplyCommunicationsSections
}
