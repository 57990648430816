import React from 'react'
import { Table, TableProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  () => ({
    table: {
      borderCollapse: 'separate',
    },
  }),
  { name: 'SoapVitalsLockedTable' },
)

export interface SoapVitalsLockedTableProps extends TableProps {}

const SoapVitalsLockedTable = (props: SoapVitalsLockedTableProps) => {
  const classes = useStyles()

  return <Table className={classes.table} {...props} />
}

export default SoapVitalsLockedTable
