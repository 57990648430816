const serializeLocations = (locationsObject) => {
  const locations = Object.values(locationsObject)
  if (!locations.length) {
    return [{ id: null, dimensions: [] }]
  }
  return locations.map(({ id, dimensions = {}, notes }) => ({
    id: id || null,
    dimensions: Object.values(dimensions).map((dimension) => ({
      id: dimension.dimension,
      value: dimension.value,
    })),
    notes,
  }))
}

export const serializeFindingsState = (findings) =>
  Object.values(findings).map((bodySystem) => ({
    id: bodySystem.id,
    state: bodySystem.state,
    notes: bodySystem.notes || null,
    findings: Object.values(bodySystem.findings).map(({ id, locations }) => ({
      id,
      locations: serializeLocations(locations),
    })),
  }))

export const serializeFindingsStateWithConcurrency = (findings, crc) => ({
  crc,
  findingLogs: serializeFindingsState(findings),
})

const convertToObject = (list, modifier) =>
  list.reduce((acc, value) => {
    acc[value.id === null ? '' : value.id] = modifier(value)
    return acc
  }, {})

const deserializeDimensions = (dimensions) =>
  convertToObject(dimensions, ({ id, value }) => ({ value, dimension: id }))

const deserializeLocations = (locations) =>
  convertToObject(locations, ({ id, name, notes, dimensions }) => ({
    id: id || '',
    name,
    notes,
    dimensions: deserializeDimensions(dimensions),
  }))

const deserializeFindings = (findings) =>
  convertToObject(findings, ({ locations, ...rest }) => ({
    locations: deserializeLocations(locations),
    ...rest,
  }))

export const deserializeFindingsState = (bodySystems) =>
  convertToObject(bodySystems, (bodySystem) => ({
    ...bodySystem,
    findings: deserializeFindings(bodySystem.findings),
  }))
