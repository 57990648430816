import React, { ForwardedRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import {
  getAppointmentId,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'

import BaseTemplateInput from './BaseTemplateInput'
import {
  BaseTemplateInputHandle,
  BaseTemplateInputProps,
} from './BaseTemplateInput/BaseTemplateInput'

export interface SoapBaseTemplateInputProps extends BaseTemplateInputProps {}

const SoapBaseTemplateInput = forwardRef(function SoapBaseTemplateInput(
  props: SoapBaseTemplateInputProps,
  ref: ForwardedRef<BaseTemplateInputHandle>,
) {
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const appointmentId = useSelector(getAppointmentId)

  return (
    <BaseTemplateInput
      clientId={clientId}
      eventId={appointmentId}
      patientId={patientId}
      ref={ref}
      {...props}
    />
  )
})

export default SoapBaseTemplateInput
