import React from 'react'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import EmailPreview, {
  EmailPreviewProps,
} from '~/components/common/emailPreview/EmailPreview'
import { isPlainTextTransport } from '~/utils/communicationsUtils'

import LogPhoneCallPreview from './LogPhoneCallPreview'

const useStyles = makeStyles(
  (theme) => ({
    preview: {
      boxShadow: theme.constants.blockShadow,
      width: 384,
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
    loadingPreview: {
      minHeight: 500,
    },
    plainTextPreview: {
      padding: theme.spacing(2),
    },
    progress: {
      margin: `${theme.spacing(3)} auto ${theme.spacing(1)}`,
    },
  }),
  { name: 'CommunicationsPreview' },
)

export interface CommunicationsPreviewProps {
  classes?: ClassesType<typeof useStyles>
  emailPreviewProps?: Partial<EmailPreviewProps>
  template?: string | Nil
  transport?: ConversationTransport
}

const CommunicationsPreview = ({
  template,
  transport,
  classes: classesProp,
  emailPreviewProps,
}: CommunicationsPreviewProps) => {
  const classes = useStyles({ classes: classesProp })

  const isLoading = R.isNil(template)

  return (
    <Grid
      container
      className={classNames(classes.preview, {
        [classes.loadingPreview]: isLoading,
      })}
    >
      {isLoading ? (
        <CircularProgress
          className={classes.progress}
          color="secondary"
          size={32}
        />
      ) : (
        <>
          {transport === ConversationTransport.LogPhoneCall && (
            <LogPhoneCallPreview template={template} />
          )}
          {transport !== ConversationTransport.LogPhoneCall && (
            <EmailPreview
              classes={{ plainTextPreview: classes.plainTextPreview }}
              disableHtmlRendering={isPlainTextTransport(transport)}
              emailTemplate={template}
              {...(emailPreviewProps || {})}
            />
          )}
        </>
      )}
    </Grid>
  )
}

export default CommunicationsPreview
