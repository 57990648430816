import React from 'react'
import { Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { InvoiceLineItem } from '~/types'

import ChargeDetailsCurrencyField from './ChargeDetailsCurrencyField'

export interface ChargeFieldSellPriceProps {
  className?: string
  inputClassName?: string
  item: InvoiceLineItem | RetailOrderLineItem
  sellPriceField: Field
}

const ChargeFieldSellPrice = ({
  className: classNameProp,
  inputClassName,
  item,
  sellPriceField,
}: ChargeFieldSellPriceProps) => (
  <ChargeDetailsCurrencyField
    disabled
    className={classNameProp}
    field={sellPriceField}
    inputClassName={inputClassName}
    item={item}
  />
)

export default ChargeFieldSellPrice
