import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { moment } from '@pbt/pbt-ui-components'

import {
  getTimetableEvent,
  getTimetableFilteredAppointmentTypes,
} from '~/store/reducers/timetable'
import useTimetableDate from '~/utils/useTimetableDate'

import TimetableEventCard, {
  TimetableEventCardProps,
} from '../../TimetableEventCard'

const MIN_CARD_HEIGHT = 20

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
    },
  }),
  { name: 'ScheduleAppointmentCard' },
)

export interface ScheduleAppointmentCardProps extends TimetableEventCardProps {
  offsetPosition: number
  stepInterval: number
  steps: string[]
}

const ScheduleAppointmentCard = ({
  steps,
  stepInterval,
  offsetPosition,
  appointmentId,
  slot: slotProp,
  ...rest
}: ScheduleAppointmentCardProps) => {
  const classes = useStyles()

  const appointment = useSelector(getTimetableEvent(appointmentId))
  const filteredAppointmentTypes = useSelector(
    getTimetableFilteredAppointmentTypes,
  )
  const {
    constants: {
      schedulerRowHeight,
      schedulerNotLastChildPadding,
      schedulerColumnWidth,
    },
  } = useTheme()

  const { selectedDate } = useTimetableDate()

  const cardVisible =
    !appointment ||
    filteredAppointmentTypes.indexOf(appointment?.type?.id || '') === -1

  if (!cardVisible) {
    return null
  }

  const startDate = moment(selectedDate).startOf('day')
  const endDate = moment(selectedDate).endOf('day')

  let momentStart = moment(slotProp?.interval?.from)
  let momentEnd = moment(slotProp?.interval?.to)

  // appointment is before or after clinic working hours, ignore it
  if (
    momentEnd.isSameOrBefore(startDate, 'minute') ||
    momentStart.isSameOrAfter(endDate, 'minute')
  ) {
    return null
  }

  // appointment starts before clinic opens, start it at clinic opening hour
  if (momentStart.isBefore(startDate, 'minute')) {
    momentStart = moment(startDate)
  }

  // appointment starts after clinic closes, end it at clinic closing hour
  if (momentEnd.isAfter(endDate, 'minute')) {
    momentEnd = moment(endDate)
  }

  const closestStep = steps.reduce((prev, curr) => {
    const currDiff = momentStart.diff(curr)
    const prevDiff = momentStart.diff(prev)
    return currDiff < prevDiff && currDiff >= 0 ? curr : prev
  }, steps[0])

  const duration = momentEnd.diff(momentStart, 'minutes')
  const durationToClosestStep = momentStart.diff(closestStep, 'minutes')

  const height =
    (duration * schedulerRowHeight) / stepInterval -
    schedulerNotLastChildPadding
  const preparedHeight = Math.max(height, MIN_CARD_HEIGHT)
  const diffHeight = (durationToClosestStep * schedulerRowHeight) / stepInterval

  const top = steps.indexOf(closestStep) * schedulerRowHeight + diffHeight
  const hasOffsetPosition = offsetPosition >= 0
  const left = hasOffsetPosition
    ? (offsetPosition * schedulerColumnWidth) / 2
    : undefined

  const isCondensed = duration < 35 && duration >= 30
  const isMedium = duration < 30 && duration >= 20
  const isShort = duration < 20

  const slot = {
    ...slotProp,
    interval: {
      from: momentStart.toISOString(),
      to: momentEnd.toISOString(),
    },
  }

  return (
    <TimetableEventCard
      {...rest}
      appointmentId={appointmentId}
      classes={classes}
      isCondensed={isCondensed}
      isHalfWidth={hasOffsetPosition}
      isMedium={isMedium}
      isShort={isShort}
      rootStyle={{ top, left, height: preparedHeight }}
      slot={slot}
    />
  )
}

export default ScheduleAppointmentCard
