import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonWithLoaderProps } from '@pbt/pbt-ui-components'

import { ButtonWithMenu } from '~/components/common/buttons/ButtonWithMenu'
import DialogNames from '~/constants/DialogNames'
import {
  PrescriptionDeclineReasonName,
  PrescriptionDeclineReasonType,
} from '~/constants/PrescriptionWorkflow'
import useDialog from '~/utils/useDialog'

import { PrescriptionActionContextType } from '../../PrescriptionContext'

export interface PrescriptionDeclineActionProps extends ButtonWithLoaderProps {
  onDecline: PrescriptionActionContextType['onDecline']
}

const PrescriptionDeclineAction = ({
  onDecline,
  ...props
}: PrescriptionDeclineActionProps) => {
  const { t } = useTranslation(['Common'])

  const [openDialog] = useDialog(DialogNames.PRESCRIPTION_DECLINE_REASON)

  const handleDecline = (declineReason: PrescriptionDeclineReasonType) => {
    if (declineReason === PrescriptionDeclineReasonType.OTHER) {
      openDialog({ onDecline })
    } else {
      onDecline(PrescriptionDeclineReasonName[declineReason])
    }
  }

  return (
    <ButtonWithMenu
      buttonText={t('Common:DECLINE_ACTION')}
      name="prescription-decline"
      optionsDisplayMap={PrescriptionDeclineReasonName}
      optionsValueMap={PrescriptionDeclineReasonType}
      onSelectOption={handleDecline as (declineReason: string) => void}
      {...props}
    />
  )
}

export default PrescriptionDeclineAction
