import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertIconType, PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

import { removeLabOrder } from '~/store/actions/orders'
import { cancelLabOrder } from '~/store/duck/labOrders'
import { Order } from '~/types'

export interface RemoveFinalizedLabTestDialogProps extends PuiAlertProps {
  onClose: () => void
  order: Order
}

const RemoveFinalizedLabTestDialog = ({
  order,
  onClose,
  ...rest
}: RemoveFinalizedLabTestDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const handleRemoveTest = () => {
    dispatch(removeLabOrder(order))
    onClose()
  }

  const handleRemoveRequisition = () => {
    dispatch(
      cancelLabOrder(order.labTest!.vendorId!, order.labTest!.labOrderId!),
    )
    onClose()
  }

  return (
    <PuiAlert
      cancelButtonText={t('Common:REQUISITION')}
      iconType={AlertIconType.WARN}
      message={t('Dialogs:REMOVE_FINALIZED_LAB_TEST_DIALOG.MESSAGE')}
      okButtonText={t('Common:TEST')}
      onCancel={handleRemoveRequisition}
      onClose={onClose}
      onOk={handleRemoveTest}
      {...rest}
    />
  )
}

export default RemoveFinalizedLabTestDialog
