import { gql } from '@apollo/client/core'

import {
  ALL_PAYMENT_ENTITIES_PAGE_FIELDS,
  PAYMENT_PAGE_BILLING_ACTIVITY,
} from '~/api/graphql/fragments/payments'

export const FETCH_UNAPPLIED_PAYMENTS_BY_CLIENT = gql`
  ${PAYMENT_PAGE_BILLING_ACTIVITY}
  query ClientUnappliedPaymentsPage(
    $businessId: ID!
    $clientId: ID!
    $offset: PageOffset!
    $limit: PageLimit!
  ) {
    unappliedPaymentsByClient(
      clientId: $clientId
      businessId: $businessId
      limit: $limit
      offset: $offset
    ) {
      ...BalancePaymentPageFields
    }
  }
`

export const FETCH_ALL_PAYMENT_ENTITIES = gql`
  ${ALL_PAYMENT_ENTITIES_PAGE_FIELDS}
  query AllPaymentEntities(
    $businessId: ID!
    $clientId: ID!
    $offset: PageOffset!
    $limit: PageLimit!
  ) {
    allPaymentEntities(
      businessId: $businessId
      clientId: $clientId
      offset: $offset
      limit: $limit
    ) {
      ...AllPaymentEntitiesPageFields
    }
  }
`
