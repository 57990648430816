import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  createAppointmentType,
  createAppointmentTypeFailure,
  createAppointmentTypeSuccess,
  fetchAppointmentTypesList,
  fetchAppointmentTypesListFailure,
  fetchAppointmentTypesListSuccess,
  updateAppointmentType,
  updateAppointmentTypeFailure,
  updateAppointmentTypeSuccess,
} from '../actions/appointmentTypes'
import {
  CREATE_APPOINTMENT_TYPE,
  FETCH_APPOINTMENT_TYPES_LIST,
  UPDATE_APPOINTMENT_TYPE,
} from '../actions/types/appointmentTypes'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchAppointmentTypesListSaga({
  businessId,
  searchFilter,
}: ReturnType<typeof fetchAppointmentTypesList>) {
  try {
    const {
      result: { data, totalCount },
      entities,
    } = yield* requestAPI(API.fetchAppointmentTypes, {
      businessId,
      searchFilter,
    })
    yield call(updateEntities, entities)
    yield put(fetchAppointmentTypesListSuccess(data, totalCount))
  } catch (error) {
    yield put(fetchAppointmentTypesListFailure(error as ApiError))
  }
}

export function* createAppointmentTypeSaga({
  input,
}: ReturnType<typeof createAppointmentType>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.createAppointmentType,
      input,
    )

    yield call(updateEntities, entities)
    yield put(createAppointmentTypeSuccess(result))
  } catch (error) {
    yield put(createAppointmentTypeFailure(error as ApiError))
  }
}

export function* updateAppointmentTypeSaga({
  id,
  input,
}: ReturnType<typeof updateAppointmentType>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.updateAppointmentType,
      id,
      input,
    )

    yield call(updateEntities, entities)
    yield put(updateAppointmentTypeSuccess(result))
  } catch (error) {
    yield put(updateAppointmentTypeFailure(error as ApiError))
  }
}

function* watchFetchAppointmentTypesList() {
  yield takeLatest(FETCH_APPOINTMENT_TYPES_LIST, fetchAppointmentTypesListSaga)
}

function* watchCreateAppointmentType() {
  yield takeLatest(CREATE_APPOINTMENT_TYPE, createAppointmentTypeSaga)
}

function* watchUpdateAppointmentType() {
  yield takeEvery(UPDATE_APPOINTMENT_TYPE, updateAppointmentTypeSaga)
}

export default function* appointmentTypesSaga() {
  yield all([
    watchFetchAppointmentTypesList(),
    watchCreateAppointmentType(),
    watchUpdateAppointmentType(),
  ])
}
