import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CityTextField,
  CountrySelect,
  Nil,
  PuiTextField,
  RegionUtils,
  StateSelect,
  useFields,
  ValidationType,
  ZipInput,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'
import { getUser } from '~/store/reducers/users'
import { DataHandleWithUnsavedChanges } from '~/types'
import {
  getPluckedFieldsByFieldKey,
  getRequiredLabel,
  isFieldValuesChanged,
} from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

const useStyles = makeStyles(
  () => ({
    zipInput: {
      textTransform: 'capitalize',
    },
    fullWidthLabel: {
      width: '100%',
    },
  }),
  { name: 'AddressForm' },
)

export interface AddressFormHandle
  extends DataHandleWithUnsavedChanges<string[]> {}

export interface AddressFormProps {
  required?: boolean
  userId: string | Nil
}

const AddressForm = forwardRef<AddressFormHandle, AddressFormProps>(
  function AddressForm({ userId, required }, ref) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const person = useSelector(getUser(userId))
    const business = useSelector(getCurrentBusiness)
    const reqValidators: ValidationType[] = required ? ['required'] : []

    const { fields, validate } = useFields(
      [
        {
          name: 'address',
          label: t('Common:STREET_ADDRESS'),
          validators: reqValidators,
          initialValue: person?.address || '',
        },
        {
          name: 'suite',
          label: t('Common:APARTMENT_NUMBER_SIGN'),
          initialValue: person?.suite || '',
        },
        {
          name: 'county',
          label: t('Common:COUNTY'),
          initialValue: person?.county || '',
        },
        {
          name: 'country',
          label: t('Common:COUNTRY'),
          validators: reqValidators,
          initialValue: RegionUtils.getAvailableCountry(
            person?.country || business?.country,
          ),
        },
        {
          name: 'city',
          label: t('Common:CITY'),
          validators: reqValidators,
          initialValue: person?.city || '',
        },
        {
          name: 'state',
          label: t('Common:STATE'),
          validators: reqValidators,
          type: 'select',
          initialValue: person?.state || '',
        },
        {
          name: 'zip',
          label: t('Common:ZIP_CODE'),
          validators: [...reqValidators, 'zip'],
          initialValue: person?.zip || '',
        },
      ],
      false,
    )

    const { address, suite, county, country, city, state, zip } = fields

    useEffectExceptOnMount(() => {
      zip.setValue('')
      state.setValue('')
    }, [country.value])

    useImperativeHandle(ref, () => ({
      validate,
      get: () => getPluckedFieldsByFieldKey(fields, 'value'),
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    return (
      <>
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={address}
            inputProps={{ maxLength: 100 }}
            label={getRequiredLabel(address.label!, required)}
            margin="none"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <PuiTextField
            InputLabelProps={{ className: classes.fullWidthLabel }}
            field={suite}
            inputProps={{ maxLength: 100 }}
            label={suite.label}
            margin="none"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <PuiTextField
            InputLabelProps={{ className: classes.fullWidthLabel }}
            field={county}
            inputProps={{ maxLength: 100 }}
            label={county.label}
            margin="none"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CityTextField
            country={country.value}
            field={city}
            margin="none"
            required={required}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <CountrySelect field={country} margin="none" required={required} />
        </Grid>
        <Grid item md={3} xs={12}>
          <StateSelect
            country={country.value}
            field={state}
            margin="none"
            required={required}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <PuiTextField
            InputProps={{
              inputComponent: ZipInput,
              inputProps: { country: country.value },
            }}
            className={classes.zipInput}
            field={zip}
            label={getRequiredLabel(zip.label!, required)}
            margin="none"
          />
        </Grid>
      </>
    )
  },
)

export default AddressForm
