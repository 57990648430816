import React from 'react'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'

import { Reminder, ReminderOrderListHandlers } from '~/types'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import ReminderOrderList from '../../list/ReminderOrderList'

export interface ReminderAddToChargesCellProps
  extends ReminderOrderListHandlers {
  actionsAnchorElement: HTMLElement | null
  activeActionItem: Reminder | null
  reminder: Reminder
  setActionsAnchorElement: (element: HTMLElement | null) => void
  setActiveActionItem: (item: Reminder | null) => void
}

const ReminderAddToChargesCell = ({
  actionsAnchorElement,
  activeActionItem,
  reminder,
  setActionsAnchorElement,
  setActiveActionItem,
  isCheckedItem,
  onCheckItem,
  onUncheckItem,
}: ReminderAddToChargesCellProps) => {
  const isContextItem = useIsCurrentContextItem(reminder)

  const handleToggleActions = (event: React.MouseEvent, item: Reminder) => {
    event.stopPropagation()

    const targetButton = (event.target as HTMLElement).closest('button')
    setActionsAnchorElement(actionsAnchorElement ? null : targetButton)
    setActiveActionItem(activeActionItem ? null : item)
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignSelf="center"
        direction="column"
        py={0.5}
        wrap="nowrap"
        xs={11}
      >
        <ReminderOrderList
          disabled={!isContextItem}
          includePrice={isContextItem}
          isCheckedItem={isCheckedItem}
          reminder={reminder}
          onCheckItem={onCheckItem}
          onUncheckItem={onUncheckItem}
        />
      </Grid>
      <Grid container item justifyContent="flex-end" xs={1}>
        <IconButton onClick={(event) => handleToggleActions(event, reminder)}>
          <MoreHorizIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default ReminderAddToChargesCell
