import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  BlobWithName,
  ClassesType,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import FileInput from '../inputs/file-input/FileInput'

const useStyles = makeStyles(
  (theme) => ({
    imageInputLogoArea: {
      height: 340,
      margin: theme.spacing(2, 3),
    },
    imageInputButtonsContainer: {
      margin: 0,
      padding: theme.spacing(2),
    },
    imageInputButtons: {
      width: 150,
      height: 40,
      maxWidth: '45%',
    },
  }),
  { name: 'ChooseAvatarDialog' },
)

export interface ChooseAvatarDialogProps extends BasePuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  onOk: (blob: BlobWithName) => void
  title: string
}

const ChooseAvatarDialog = ({
  classes: classesProp,
  open,
  onClose,
  onOk,
  title: titleProp,
}: ChooseAvatarDialogProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Dialogs')

  const [isFileSelected, setIsFileSelected] = useState(false)

  const title = titleProp || t('Dialogs:CHOOSE_AVATAR_DIALOG.TITLE')

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="choose-avatar-dialog"
      maxWidth="md"
      open={open}
      title={title}
      onClose={onClose}
    >
      <FileInput
        clearOnCancel
        onlyImages
        aspectRatio={1 / 1}
        buttonsHidden={!isFileSelected}
        classes={{
          logoAreaClassName: classes.imageInputLogoArea,
          buttonsContainerClassName: classes.imageInputButtonsContainer,
          buttonsClassName: classes.imageInputButtons,
        }}
        size="normal"
        uploadButtonColor="primary"
        onCancel={() => setIsFileSelected(false)}
        onFileReady={({ blob }) => {
          onOk(blob)
          if (onClose) {
            onClose()
          }
        }}
        onFileSelected={() => setIsFileSelected(true)}
      />
    </PuiDialog>
  )
}

export default ChooseAvatarDialog
