import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  ButtonWithLoader,
  CircularProgressOverlay,
  Text,
  TimezoneSelect,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  dismissTimezoneChanges,
  fetchMigrationImportStatus,
  startMigration,
} from '~/store/actions/migration'
import { getBusiness } from '~/store/reducers/businesses'
import {
  getFeatureToggle,
  getTimezoneGroups,
  getTimezones,
} from '~/store/reducers/constants'
import {
  getCurrentSessionId,
  getIsLoadingStartImport,
  getIsLoadingStatus,
  getProgressEntities,
} from '~/store/reducers/migration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    optionsSelect: {
      width: 256,
      maxWidth: 256,
      margin: theme.spacing(1, 4),
    },
    actions: {
      marginTop: theme.spacing(3),
    },
    button: {
      width: 150,
      height: 40,
      marginLeft: theme.spacing(2),
    },
    group: {
      marginBottom: theme.spacing(3),
    },
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'StepConfirmTimezones' },
)

const TimezoneOption = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
}

interface StepConfirmTimezonesProps {
  businessId: string
  isEnterprise: boolean
}

const StepConfirmTimezones = ({
  businessId,
  isEnterprise,
}: StepConfirmTimezonesProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const [openImportAlreadyDone] = useDialog(DialogNames.IMPORT_ALREADY_DONE)

  const Timezones = useSelector(getTimezones)
  const TimezoneGroups = useSelector(getTimezoneGroups)
  const timezoneListReorderingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TIMEZONE_LIST_REORDERING),
  )
  const business = useSelector(getBusiness(businessId))
  const sessionId = useSelector(getCurrentSessionId)
  const isLoadingStartImport = useSelector(getIsLoadingStartImport)
  const isLoadingStatus = useSelector(getIsLoadingStatus)
  const entities = useSelector(getProgressEntities) || []
  const timezoneOptions = Timezones.map(
    ({ timezone }: { timezone: string }) => ({ id: timezone, name: timezone }),
  )
  const utcTimezoneId = Utils.findByName('UTC', timezoneOptions)?.id

  const [isImportedEntitiesFetched, setIsImportedEntitiesFetched] =
    useState(false)
  const [importSourceTimezoneOption, setImportSourceTimezoneOption] = useState(
    TimezoneOption.CUSTOM,
  )
  const [importTimezone, setImportTimezone] = useState(utcTimezoneId)
  const [practiceTimezoneOption, setPracticeTimezoneOption] = useState(
    TimezoneOption.DEFAULT,
  )
  const [practiceTimezone, setPracticeTimezone] = useState<string>()

  const setFetchedFlag = useCloseAfterCreation(
    () => !isImportedEntitiesFetched && setIsImportedEntitiesFetched(true),
    getIsLoadingStatus,
  )

  const businessTimezone = business?.timeZone

  const startImport = () => {
    const settings = {
      businessId,
      importTimezone:
        importSourceTimezoneOption === TimezoneOption.DEFAULT
          ? businessTimezone
          : importTimezone,
      practiceTimezone:
        practiceTimezoneOption === TimezoneOption.DEFAULT
          ? businessTimezone
          : practiceTimezone,
    }

    dispatch(startMigration(sessionId, settings, isEnterprise))
  }

  const handleProceed = () => {
    setFetchedFlag()
    dispatch(fetchMigrationImportStatus(sessionId, true))
  }

  const handleBack = () => dispatch(dismissTimezoneChanges(sessionId))

  useEffect(() => {
    setPracticeTimezone(businessTimezone)
  }, [businessTimezone])

  useEffect(() => {
    if (isImportedEntitiesFetched && !isLoadingStartImport) {
      if (entities.length) {
        openImportAlreadyDone({ onStart: startImport })
      } else {
        startImport()
      }
    }
  }, [isImportedEntitiesFetched, isLoadingStartImport, entities])

  const formControlClasses = { label: classes.radioLabel }

  return (
    <>
      <CircularProgressOverlay
        open={isLoadingStartImport}
        preloaderText={t('Businesses:MIGRATION.SAVING_SETTINGS')}
      />
      <Grid container direction="column" p={3}>
        <Grid container className={classes.group} direction="column">
          <Text strong variant="body2">
            {t('Businesses:MIGRATION.SOURCE_DATA_TIME_ZONE')}
          </Text>
          <RadioGroup
            name="import-timezone-options"
            value={importSourceTimezoneOption}
            onChange={(_, value) => setImportSourceTimezoneOption(value)}
          >
            <FormControlLabel
              classes={formControlClasses}
              control={<Radio value={TimezoneOption.DEFAULT} />}
              label={`${businessTimezone} (${t(
                'Businesses:MIGRATION.BASED_ON_PRACTICE_SETTINGS',
              ).toLowerCase()})`}
            />
            <FormControlLabel
              classes={formControlClasses}
              control={<Radio value={TimezoneOption.CUSTOM} />}
              label={t(
                'Businesses:MIGRATION.SOURCE_DATA_IS_IN_DIFFERENT_TIME_ZONE',
              )}
            />
          </RadioGroup>
          {importSourceTimezoneOption === TimezoneOption.CUSTOM && (
            <FormControl className={classes.optionsSelect} margin="normal">
              <TimezoneSelect
                timezoneGroups={TimezoneGroups}
                timezones={Timezones}
                useTimezoneGroupVariant={timezoneListReorderingEnabled}
                value={importTimezone}
                onChange={(event) => {
                  setImportTimezone(event.target.value)
                }}
              />
            </FormControl>
          )}
        </Grid>
        <Grid container className={classes.group} direction="column">
          <Text strong variant="body2">
            {t('Businesses:MIGRATION.PRACTICE_TIME_ZONE')}
          </Text>
          <RadioGroup
            name="practice-timezone-options"
            value={practiceTimezoneOption}
            onChange={(_, value) => setPracticeTimezoneOption(value)}
          >
            <FormControlLabel
              classes={formControlClasses}
              control={<Radio value={TimezoneOption.DEFAULT} />}
              label={`${businessTimezone} (${t(
                'Businesses:MIGRATION.BASED_ON_PRACTICE_SETTINGS',
              ).toLowerCase()})`}
            />
            <FormControlLabel
              classes={formControlClasses}
              control={<Radio value={TimezoneOption.CUSTOM} />}
              label={t(
                'Businesses:MIGRATION.PRACTICE_IS_IN_DIFFERENT_TIME_ZONE',
              )}
            />
          </RadioGroup>
          {practiceTimezoneOption === TimezoneOption.CUSTOM && (
            <FormControl className={classes.optionsSelect} margin="normal">
              <TimezoneSelect
                timezoneGroups={TimezoneGroups}
                timezones={Timezones}
                useTimezoneGroupVariant={timezoneListReorderingEnabled}
                value={practiceTimezone}
                onChange={(event) => {
                  setPracticeTimezone(event.target.value)
                }}
              />
            </FormControl>
          )}
        </Grid>
        <Grid container className="actions" justifyContent="flex-start">
          <Grid item>
            <BackButton label={t('Common:BACK_ACTION')} onClick={handleBack} />
          </Grid>
          <ButtonWithLoader
            className={classes.button}
            color="primary"
            loading={isLoadingStatus}
            onClick={handleProceed}
          >
            {t('Common:NEXT')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  )
}

export default StepConfirmTimezones
