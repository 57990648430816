import { call, put } from 'redux-saga/effects'
import { moment } from '@pbt/pbt-ui-components'

import { SchedulerViewType } from '~/components/dashboard/timetable/scheduler/schedulerViews'
import { getUrlSearchParam } from '~/utils'

import { fetchBoardingSchedules, fetchSchedules } from '../actions/scheduler'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* fetchSchedulerConfig(personId: string) {
  const urlDate = moment(
    getUrlSearchParam('date', window.location.search) || undefined,
  )
  const selectedDate = urlDate.startOf('day').toISOString()

  const isBoarding =
    window.location.pathname.indexOf(SchedulerViewType.BOARDING) > -1

  if (isBoarding) {
    yield put(fetchBoardingSchedules(false, selectedDate))
  } else {
    yield put(fetchSchedules(false, selectedDate))
  }
}

const preloadSagaByPageUrl: Record<string, (personId: string) => Generator> = {
  // TODO: enable back with removal of USE_V2_SCHEDULER_ENDPOINT feature toggle
  // and implement correct conditions that will work including navigation cases
  // preloading calls extra requests if it is turned on with current implementation!
  // scheduler: fetchSchedulerConfig,
  //
  // It was call of /time-tracking/status endpoint (checkStatusSaga) in fetchRegularConfig
  // before adding this status to /me endpoint.
}

export function* urlPreloadSaga(personId: string) {
  const [, url] = window.location.pathname.split('/')
  if (preloadSagaByPageUrl[url]) {
    yield call(preloadSagaByPageUrl[url], personId)
  }
}
