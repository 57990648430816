import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import SnackNotificationType from '~/constants/SnackNotificationType'
import {
  disableToastShowing,
  fetchProblemCatalog,
  fetchProblemLogs,
} from '~/store/actions/problems'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { getPatient } from '~/store/reducers/patients'
import {
  getIdentifiedProblemsCount,
  getIsLogsAndCatalogLoaded,
  getShouldShowedProblemLogsToast,
} from '~/store/reducers/problems'
import {
  getPatientId,
  getSoapId,
  getSOAPisLoading,
} from '~/store/reducers/soap'

export const useProblemsNotification = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation('Soap')

  const isLoading = useSelector(getSOAPisLoading)
  const soapId = useSelector(getSoapId)
  const patientId = useSelector(getPatientId)
  const patient = useSelector(getPatient(patientId))
  const shouldShowedProblemLogsToast = useSelector(
    getShouldShowedProblemLogsToast,
  )
  const identifiedProblemsCount = useSelector(getIdentifiedProblemsCount)
  const isLogsAndCatalogLoaded = useSelector(getIsLogsAndCatalogLoaded)

  const showAddedProblemsNotification = (numberDiagnoses: number) => {
    dispatch(
      addUiNotification({
        id: uuid(),
        message: t('Soap:PROBLEMS.WE_ADDED_NUMBER_DIAGNOSES', {
          number: numberDiagnoses,
        }),
        type: SnackNotificationType.PROBLEMS_LOG,
        params: {
          soapId,
        },
      }),
    )
  }

  // We must fetch problems, so we can trigger the next useEffect to show toast alert
  useEffect(() => {
    if (patient?.id && soapId) {
      dispatch(fetchProblemCatalog(patient.id))
      dispatch(fetchProblemLogs(soapId))
    }
  }, [patient?.id, soapId])

  useEffect(() => {
    if (
      soapId &&
      identifiedProblemsCount > 0 &&
      shouldShowedProblemLogsToast &&
      isLogsAndCatalogLoaded &&
      patient &&
      !isLoading
    ) {
      showAddedProblemsNotification(identifiedProblemsCount)
      dispatch(disableToastShowing(soapId))
    }
  }, [
    identifiedProblemsCount,
    shouldShowedProblemLogsToast,
    isLoading,
    isLogsAndCatalogLoaded,
    patient,
    soapId,
  ])
}
