import { MutationUpdateMonitorsAssignmentsArgs } from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { UPDATE_MONITORS_ASSIGNMENTS } from './graphql/mutations/monitors'
import {
  FETCH_BUSINESS_UNASSIGNED_MONITOR_DEVICES,
  FETCH_SPACE_ASSIGNED_DEVICES,
  GET_IN_ROOM_SPACE_MONITOR,
} from './graphql/queries/monitors'

export const fetchUnassignedMonitors = requestQuery({
  query: FETCH_BUSINESS_UNASSIGNED_MONITOR_DEVICES,
  variablesHandler: (businessId) => ({
    businessId,
    id: businessId,
  }),
})

export const fetchAssignedToSpaceMonitors = requestQuery({
  query: FETCH_SPACE_ASSIGNED_DEVICES,
  variablesHandler: (businessId, id) => ({
    businessId,
    id,
  }),
})

export const updateMonitorsAssignments = requestMutation({
  mutation: UPDATE_MONITORS_ASSIGNMENTS,
  variablesHandler: (
    businessId: string,
    { input }: MutationUpdateMonitorsAssignmentsArgs,
  ) => ({
    businessId,
    input,
  }),
})

export const getInRoomSpaceMonitor = requestMutation({
  mutation: GET_IN_ROOM_SPACE_MONITOR,
  variablesHandler: (businessId: string, spaceId: string) => ({
    businessId,
    spaceId,
  }),
})
