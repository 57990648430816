import React from 'react'
import { WellnessPlanCoverItem } from '@pbt/pbt-ui-components'

import {
  wellnessPlansCatalogActions,
  wellnessPlansCatalogSelectors,
  // @ts-ignore
} from '~/store/duck/wellnessPlansCatalog'

import AddCatalogItemsDialog, {
  AddCatalogItemsDialogProps,
} from './AddCatalogItemsDialog'

interface AddWellnessPlanCoverageItemsDialogProps
  extends Omit<AddCatalogItemsDialogProps, 'actions' | 'selectors' | 'onSave'> {
  onSave: (cover: WellnessPlanCoverItem[]) => void
}

const AddWellnessPlanCoverageItemsDialog = (
  props: AddWellnessPlanCoverageItemsDialogProps,
) => (
  <AddCatalogItemsDialog
    actions={wellnessPlansCatalogActions}
    selectors={wellnessPlansCatalogSelectors}
    {...props}
    onSave={(items) =>
      props.onSave(
        items.map((item) => ({
          name: item.name,
          itemId: item.id,
          itemType: item.type,
        })),
      )
    }
  />
)

export default AddWellnessPlanCoverageItemsDialog
