import React from 'react'

import { OnHandCatalogHistoryItem } from '~/types/entities/onHandCatalog'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

const AdjustedQuantityCell = (item: OnHandCatalogHistoryItem) => {
  const getInStockAmount = useGetInStockAmount({
    relative: true,
  })

  return (
    <>
      {getInStockAmount({
        amount: item.quantity,
        amountUnitsId: item.quantityUnitId,
        perPackageUnitsId: item.perPackageUnitsId,
        packageTypeId: item.packageTypeId,
      })}
    </>
  )
}

export default AdjustedQuantityCell
