import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'

import ChargesWidgetLocked from './ChargesWidgetLocked'
import ChargesWidgetUnlocked from './ChargesWidgetUnlocked'

const ChargesWidget = () => {
  const ChargesWidgetComponent = useLockableComponent(
    ChargesWidgetUnlocked,
    ChargesWidgetLocked,
    true,
  )

  return <ChargesWidgetComponent />
}

export default ChargesWidget
