import React, { forwardRef } from 'react'

import HelpButton, { HelpButtonProps } from '../HelpButton'

export interface LeftMenuHelpButtonProps extends HelpButtonProps {}

const LeftMenuHelpButton = forwardRef(function LeftMenuHelpButton(
  { children, className, ...rest }: LeftMenuHelpButtonProps,
  ref,
) {
  return (
    <HelpButton
      Component="div"
      ComponentProps={rest}
      className={className}
      ref={ref}
    >
      {children}
    </HelpButton>
  )
})

export default LeftMenuHelpButton
