import { gql } from '@apollo/client'

export const FETCH_PATIENT_PREFERENCES = gql`
  query FetchPatientPreferences($patientId: ID!) {
    patient(id: $patientId) {
      id
      preference {
        patientId
        caution {
          id
        }
        medicalAlert {
          id
        }
        treats
        namePronunciation
        drugPreference {
          id
        }
        staffGender {
          id
        }
        venipuncture {
          id
        }
        premedicationVaccineReaction {
          id
        }
        performedTreatmentsLocation {
          id
        }
        sedation {
          id
        }
        labWorkFrequency {
          id
        }
        generalComment
        importantComment
        medicalComment
      }
    }
  }
`
