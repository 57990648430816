import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Field,
  Nil,
  PuiDialog,
  PuiSelect,
  PuiTooltip,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import FeatureToggle from '~/constants/featureToggle'
import { fetchSpacesListWithType } from '~/store/actions/spaces'
import {
  bulkCreateAdjustments,
  getAdjustmentsIsSaving,
} from '~/store/duck/inventoryAdjustments'
import {
  getFeatureToggle,
  getInventoryAdjustmentReasons,
  getSelectableInventoryAdjustmentReasons,
  getSpaceType,
} from '~/store/reducers/constants'
import {
  getMultipleSpaces,
  getSpacesIsFetching,
  getSpacesList,
} from '~/store/reducers/spaces'
import { Adjustment, UnsavedAdjustment } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import BulkAdjustmentRow, { BulkAdjustmentRowHandle } from './BulkAdjustmentRow'

type DraftAdjustment = {
  adjustment: Adjustment | UnsavedAdjustment
  id: string | Nil
  ref: React.RefObject<BulkAdjustmentRowHandle>
}

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      padding: theme.spacing(1, 2),
    },
    table: {
      border: theme.constants.tableBorder,
    },
    heading: {
      borderBottom: theme.constants.tableBorder,
    },
    mainCell: {
      borderRight: theme.constants.tableBorder,
    },
    headingCell: {
      padding: theme.spacing(1),
    },
    select: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
      fontWeight: 400,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    hint: {
      cursor: 'pointer',
    },
    icon: {
      color: theme.colors.link,
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'BulkAdjustmentDialog' },
)

const buildAdjustmentDrafts = (adjustment: Adjustment | UnsavedAdjustment) => ({
  id: adjustment?.variation?.id,
  ref: createRef<BulkAdjustmentRowHandle>(),
  adjustment,
})

const isValidDraft = (draft: DraftAdjustment) =>
  Boolean(draft.ref.current?.validate())
const getAdjustment = (draft: DraftAdjustment) => ({
  ...draft.adjustment,
  ...draft.ref.current?.get(),
})

export interface BulkAdjustmentDialogProps extends BasePuiDialogProps {
  adjustments: (Adjustment | UnsavedAdjustment)[]
  onSave?: () => void
}

const BulkAdjustmentDialog = ({
  open,
  onClose,
  adjustments,
  onSave,
}: BulkAdjustmentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isSaving = useSelector(getAdjustmentsIsSaving(false))
  const isFetchingSpaces = useSelector(getSpacesIsFetching)
  const spacesList = useSelector(getSpacesList)
  const spaces = useSelector(getMultipleSpaces(spacesList)) || []
  const isIpoM0InvoiceRefundsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.INVOICE_BASED_REFUNDS),
  )
  const InventoryAdjustmentReasons = useSelector(getInventoryAdjustmentReasons)
  const SelectableInventoryAdjustmentReasons = useSelector(
    getSelectableInventoryAdjustmentReasons,
  )
  const SpaceType = useSelector(getSpaceType)
  const locationTypeSpaceId = Utils.findConstantIdByName(
    'Storage location',
    SpaceType,
  )

  const [lineDrafts, setLineDrafts] = useState<DraftAdjustment[]>(
    adjustments.map(buildAdjustmentDrafts),
  )
  const closeAfterSave = useCloseAfterCreation(() => {
    if (onClose) {
      onClose()
    }
    if (onSave) {
      onSave()
    }
  }, getAdjustmentsIsSaving(false))

  useEffect(() => {
    dispatch(fetchSpacesListWithType(locationTypeSpaceId))
  }, [locationTypeSpaceId])

  const handleSave = () => {
    const allValid = R.all(isValidDraft)(lineDrafts)
    if (allValid) {
      const newAdjustments = R.map(getAdjustment)(lineDrafts)
      dispatch(bulkCreateAdjustments(newAdjustments))
      closeAfterSave()
    }
  }

  const updateAllDrafts = (field: string, value: any) => {
    const updatedDrafts = lineDrafts.map((draft) => ({
      ...draft,
      adjustment: {
        ...draft.adjustment,
        [field]: value,
      },
    }))
    setLineDrafts(updatedDrafts)
  }

  const propagateAdjustmentReason = (reasonId: string) => {
    updateAllDrafts('reasonId', reasonId)
  }

  const propagateStorageLocation = (storageLocationId: string) => {
    updateAllDrafts('storageLocationId', storageLocationId)
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isFetchingSpaces || isSaving}
          loading={isSaving}
          type="submit"
          onClick={handleSave}
        >
          {t('Common:RECORD_ADJUSTMENT')}
        </ButtonWithLoader>
      }
      aria-labelledby="bulk-adjustment-dialog"
      maxWidth="lg"
      open={open}
      title={t('Dialogs:BULK_ADJUSTMENT_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Grid p={2}>
        <Table className={classes.table}>
          <TableHead className={classes.heading}>
            <TableRow>
              <TableCell
                className={classNames(classes.headingCell, classes.mainCell)}
              >
                <Text strong variant="lowAccent2">
                  {t('Common:ITEM')}
                </Text>
              </TableCell>
              <TableCell className={classes.headingCell}>
                <Text strong variant="lowAccent2">
                  {t('Dialogs:BULK_ADJUSTMENT_DIALOG.RECORDED_ON-HAND')}
                </Text>
              </TableCell>
              <TableCell className={classes.headingCell}>
                <PuiTooltip
                  tooltipPlacement="top"
                  tooltipText={t(
                    'Tooltips:BULK_ADJUSTMENT_DIALOG.TOTAL_ON-HAND_HINT',
                  )}
                >
                  <Grid container alignItems="center" className={classes.hint}>
                    <Text strong variant="lowAccent2">
                      {t('Dialogs:BULK_ADJUSTMENT_DIALOG.TOTAL_ON-HAND')}
                    </Text>
                    <InfoIcon className={classes.icon} />
                  </Grid>
                </PuiTooltip>
              </TableCell>
              <TableCell className={classes.headingCell}>
                <Text strong variant="lowAccent2">
                  {t('Common:ADJUSTMENT_REASON')}
                </Text>
                <EnumSelect
                  renderEmpty
                  Constant={
                    isIpoM0InvoiceRefundsEnabled
                      ? SelectableInventoryAdjustmentReasons
                      : InventoryAdjustmentReasons
                  }
                  classes={{
                    statusSelect: classes.select,
                  }}
                  field={
                    {
                      set: (event) =>
                        propagateAdjustmentReason(event?.target?.value),
                    } as Field
                  }
                  placeholder={t('Common:ADJUSTMENT_REASON')}
                />
              </TableCell>
              <TableCell className={classes.headingCell}>
                <Text strong variant="lowAccent2">
                  {t('Common:STORAGE_LOCATION')}
                </Text>
                <PuiSelect
                  disableUnderline
                  renderEmpty
                  classes={{
                    select: classes.select,
                  }}
                  field={
                    {
                      set: (event) =>
                        propagateStorageLocation(event?.target?.value),
                    } as Field
                  }
                  items={spaces}
                  placeholder={t('Common:STORAGE_LOCATION')}
                />
              </TableCell>
              <TableCell className={classes.headingCell}>
                <Text strong variant="lowAccent2">
                  {t('Common:NOTES')}
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineDrafts.map(({ id, ref, adjustment }) => (
              <BulkAdjustmentRow
                adjustment={adjustment}
                key={id}
                ref={ref}
                spaces={spaces}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </PuiDialog>
  )
}

export default BulkAdjustmentDialog
