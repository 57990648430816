import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Calendar,
  CustomFieldValidatorState,
  EmployeeIdInfo,
  Nil,
  PuiCheckbox,
  PuiTextArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { DataHandleWithUnsavedChanges, EmployeeIdInfoToCreate } from '~/types'
import { isFieldValuesChanged } from '~/utils'

import EmployeeIdDuplicateWarning from './EmployeeIdDuplicateWarning'

const useStyles = makeStyles(
  (theme) => ({
    checkbox: {
      position: 'absolute',
      marginTop: theme.spacing(6),
    },
    checkboxLabel: {
      fontSize: '1.4rem',
      marginLeft: theme.spacing(-0.5),
    },
  }),
  { name: 'EmployeeId' },
)

export interface EmployeeIdProps {
  employeeIdInfo: EmployeeIdInfo | Nil
}

export type EmployeeIdHandle =
  DataHandleWithUnsavedChanges<EmployeeIdInfoToCreate>

const EmployeeId = forwardRef<EmployeeIdHandle, EmployeeIdProps>(
  function EmployeeId({ employeeIdInfo }, ref) {
    const classes = useStyles()
    const { t } = useTranslation(['Admin', 'Common'])
    const isEmployeeIdAutogenerationEnabled = useSelector(
      getFeatureToggle(FeatureToggle.EMPLOYEE_ID_AUTOGENERATION),
    )
    const isCreate = !employeeIdInfo || R.isEmpty(employeeIdInfo)

    const validateIdNumber = ({
      state: { employeeIdNumber, autoGenerateEmployeeNumber },
    }: CustomFieldValidatorState) => {
      if (isEmployeeIdAutogenerationEnabled && autoGenerateEmployeeNumber) {
        return true
      }

      const isLengthValid =
        employeeIdNumber.length > 0 && employeeIdNumber.length <= 32

      const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(employeeIdNumber)

      return isLengthValid && isAlphanumeric
    }

    const { fields, validate } = useFields(
      [
        {
          name: 'employeeIdType',
          label: t('Admin:MEMBER.EMPLOYEE_ID.EMPLOYEE_ID_TYPE'),
          initialValue: isCreate ? '' : employeeIdInfo.employeeIdType,
        },
        {
          name: 'employeeIdNumber',
          label: t('Admin:MEMBER.EMPLOYEE_ID.EMPLOYEE_ID_NUMBER'),
          validators: [
            { validator: validateIdNumber, validatorName: 'validIdNumber' },
          ],
          messages: {
            validIdNumber: t('Validations:PLEASE_ENTER_VALID_NUMBER'),
          },
          initialValue: isCreate ? '' : employeeIdInfo.employeeIdNumber,
        },
        {
          name: 'autoGenerateEmployeeNumber',
          initialValue: isCreate
            ? false
            : employeeIdInfo.generatedEmployeeIdNumber,
          type: 'toggle',
        },
        {
          name: 'issuingAuthority',
          label: t('Admin:MEMBER.EMPLOYEE_ID.ISSUING_AUTHORITY'),
          initialValue: isCreate ? '' : employeeIdInfo.issuingAuthority,
        },
        {
          name: 'issueDate',
          label: t('Admin:MEMBER.EMPLOYEE_ID.ISSUE_DATE'),
          validators: ['required'],
          initialValue: isCreate ? '' : employeeIdInfo.issueDate,
        },
        {
          name: 'additionalInfo',
          label: t('Common:ADDITIONAL_INFORMATION'),
          initialValue: isCreate ? '' : employeeIdInfo.notes,
        },
      ],
      false,
    )

    const {
      employeeIdType,
      employeeIdNumber,
      autoGenerateEmployeeNumber,
      issuingAuthority,
      issueDate,
      additionalInfo,
    } = fields

    useImperativeHandle(ref, () => ({
      get: () => ({
        ...employeeIdInfo,
        employeeIdType: employeeIdType.value,
        employeeIdNumber:
          isEmployeeIdAutogenerationEnabled && autoGenerateEmployeeNumber.value
            ? null
            : employeeIdNumber.value,
        generatedEmployeeIdNumber: isEmployeeIdAutogenerationEnabled
          ? autoGenerateEmployeeNumber.value
          : null,
        issuingAuthority: issuingAuthority.value,
        issueDate: issueDate.value,
        notes: additionalInfo.value,
        id: isCreate ? '' : employeeIdInfo.id,
      }),
      validate,
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    return (
      <Grid container item alignItems="center" columnSpacing={2}>
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={employeeIdType}
            inputProps={{ maxLength: 100 }}
            label={`${employeeIdType.label}`}
            name={employeeIdType.name}
          />
        </Grid>
        <Grid container item md={6} xs={12}>
          <Grid container wrap="nowrap">
            <PuiTextField
              disabled={Boolean(
                isEmployeeIdAutogenerationEnabled &&
                  autoGenerateEmployeeNumber.value,
              )}
              field={employeeIdNumber}
              inputProps={{ maxLength: 100 }}
              label={`${employeeIdNumber.label}*`}
              name={employeeIdNumber.name}
              value={
                isEmployeeIdAutogenerationEnabled &&
                autoGenerateEmployeeNumber.value
                  ? '######'
                  : undefined
              }
            />
            {employeeIdInfo?.duplicate && (
              <Grid mt={4}>
                <EmployeeIdDuplicateWarning />
              </Grid>
            )}
          </Grid>
          {isEmployeeIdAutogenerationEnabled && (
            <PuiCheckbox
              classes={{
                labelRoot: classes.checkbox,
                switchLabel: classes.checkboxLabel,
              }}
              field={autoGenerateEmployeeNumber}
              label={t(
                'Admin:MEMBER.EMPLOYEE_ID.FILL_EMPLOYEE_ID_WITH_A_RANDOM_NUMBER',
              )}
            />
          )}
        </Grid>
        <Grid
          item
          md={6}
          mt={isEmployeeIdAutogenerationEnabled ? 1 : 0}
          xs={12}
        >
          <PuiTextField
            field={issuingAuthority}
            inputProps={{ maxLength: 100 }}
            label={`${issuingAuthority.label}`}
            name={issuingAuthority.name}
          />
        </Grid>
        <Grid
          item
          md={6}
          mt={isEmployeeIdAutogenerationEnabled ? 1 : 0}
          xs={12}
        >
          <Calendar fullWidth field={issueDate} label={`${issueDate.label}*`} />
        </Grid>
        <Grid item mt={2} xs={12}>
          <PuiTextArea
            label={additionalInfo.label}
            name={additionalInfo.name}
            value={additionalInfo.value}
            onChange={additionalInfo.set}
          />
        </Grid>
        <Grid item mt={1} xs={12}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
    )
  },
)

export default EmployeeId
