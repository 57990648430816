import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AppointmentEventType,
  ControlButtonGroup,
  ControlButtonGroupName,
  DateUtils,
  EventTypeName,
  NumberUtils,
  PuiCheckbox,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getFinanceInvoice } from '~/store/reducers/finance'
import { getPatient } from '~/store/reducers/patients'
import { Invoice } from '~/types'
import { isToday } from '~/utils/time'
import useDialog from '~/utils/useDialog'
import {
  getDisplayNameFromAppointmentType,
  useEventType,
} from '~/utils/useEventType'

import InvoiceStatusLabel from '../../invoices/InvoiceStatusLabel'
import {
  getInvoiceAmountNoFee,
  getInvoiceDate,
  getInvoiceDueToPayNoFee,
} from '../../invoices/invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    checkboxLabel: {
      margin: 0,
    },
    stateLabel: {
      width: 'fit-content',
    },
    buttonsColumn: {
      minWidth: 120,
      maxWidth: 120,
    },
    labelColumn: {
      minWidth: 61,
      maxWidth: 61,
    },
    dateColumn: {
      minWidth: 155,
      maxWidth: 155,
    },
    eventTypeColumn: {
      minWidth: 230,
      maxWidth: 230,
    },
  }),
  { name: 'SoapClientInvoiceListRow' },
)

export interface SoapClientInvoiceListRowProps {
  checked: boolean
  invoiceId: string
  onCheck: (id: string) => void
}

const SoapClientInvoiceListRow = ({
  invoiceId,
  checked,
  onCheck,
}: SoapClientInvoiceListRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Time')

  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Invoice
  const patient = useSelector(getPatient(invoice?.patient))

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)
  const [openEmailInvoiceDialog] = useDialog(DialogNames.EMAIL_INVOICE)
  const [openPrintInvoiceDialog] = useDialog(DialogNames.PRINT_INVOICE)

  const openInvoice = useOpenInvoice(invoice?.clientId, openInvoiceDialog)

  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )
  const invoiceTypeDisplayName = getDisplayNameFromAppointmentType(
    invoice?.event?.type?.name,
    AppointmentSubTypes,
  )

  const onOpenInvoiceDialog = () => {
    openInvoice({
      clientId: invoice?.clientId,
      patientId: invoice?.patientId,
      invoiceId,
      id: invoiceId,
    })
  }

  const onAppointmentNameClick = () => {
    openAppointmentDialog({
      appointmentId: invoice?.eventId,
      isAppointmentType: true,
    })
  }

  const invoiceDate = getInvoiceDate(invoice)
  const isTodayDate = isToday(invoiceDate)

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems="center"
        className={classes.dateColumn}
        wrap="nowrap"
      >
        <PuiCheckbox
          checked={checked}
          labelProps={{
            className: classes.checkboxLabel,
          }}
          onChange={() => onCheck(invoiceId)}
        />
        <Text variant="body">
          {isTodayDate
            ? t('Time:LABEL.TODAY')
            : DateUtils.formatDate(invoiceDate)}
        </Text>
      </Grid>
      <Grid item xs={2}>
        <Text variant="body">
          {NumberUtils.formatMoney(getInvoiceDueToPayNoFee(invoice))}
        </Text>
      </Grid>
      <Grid item className={classes.eventTypeColumn}>
        <Text link variant="body" onClick={onAppointmentNameClick}>
          {invoiceTypeDisplayName}
        </Text>
      </Grid>
      <Grid item xs>
        <Text variant="body">{patient && patient.name}</Text>
      </Grid>
      {invoice && getInvoiceAmountNoFee(invoice) > 0 && (
        <Grid item className={classes.labelColumn}>
          <InvoiceStatusLabel
            className={classes.stateLabel}
            invoice={invoice}
          />
        </Grid>
      )}
      <Grid
        container
        item
        alignItems="center"
        className={classes.buttonsColumn}
        justifyContent="flex-end"
        wrap="nowrap"
      >
        <ControlButtonGroup
          buttons={[
            {
              name: ControlButtonGroupName.PRINT,
              onClick: () => {
                openPrintInvoiceDialog({ invoiceIds: [invoiceId] })
              },
            },
            {
              name: ControlButtonGroupName.EMAIL,
              onClick: () => {
                openEmailInvoiceDialog({ invoiceIds: [invoiceId] })
              },
            },
            {
              name: ControlButtonGroupName.PREVIEW,
              onClick: onOpenInvoiceDialog,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default SoapClientInvoiceListRow
