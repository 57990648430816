import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BasePuiDialogProps, Nil } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import {
  BOOP_APP_STORE_DOWNLOAD_LINK,
  BOOP_GOOGLE_PLAY_DOWNLOAD_LINK,
} from '~/constants/communications'
import { sendWelcomeEmail } from '~/store/actions/wellnessPlans'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getConversationsIsCreating } from '~/store/reducers/conversations'
import { getUser } from '~/store/reducers/users'
import { EmailEntityConfigRecipient } from '~/types'
import { normalizeTextForHtml } from '~/utils/requestPaymentUtils'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'

import { ConversationMessageFormattingAreaHandle } from '../ConversationMessageFormattingArea'
import NewConversationDialog from './NewConversationDialog'

export interface SendBoopLinkDialogProps extends BasePuiDialogProps {
  businessId: string | Nil
  clientId: string
  onSent?: () => void
  patientId: string
}

const SendBoopLinkDialog = ({
  clientId,
  businessId,
  onSent,
  onClose,
  ...rest
}: SendBoopLinkDialogProps) => {
  const dispatch = useDispatch()
  const conversationDialogRef =
    useRef<ConversationMessageFormattingAreaHandle>(null)
  const { t } = useTranslation(['Dialogs'])

  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const isLoading = useSelector(getConversationsIsCreating)

  const [transport, setTransport] = useState<ConversationTransport>()

  const initialSubject = t(
    'Dialogs:SEND_BOOP_LINK_DIALOG.BOOP_SYSTEM_NAME_DOWNLOAD_LINK',
  )
  const messageBody = t('Dialogs:SEND_BOOP_LINK_DIALOG.BODY_MESSAGE', {
    clientFirstName: client?.firstName,
    googlePlayLink: BOOP_GOOGLE_PLAY_DOWNLOAD_LINK,
    appStoreLink: BOOP_APP_STORE_DOWNLOAD_LINK,
    currentBusinessName: currentBusiness?.name,
  })

  const initialMessage =
    transport === ConversationTransport.Email
      ? normalizeTextForHtml(messageBody)
      : messageBody

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport, client },
    { initialSubject, initialMessage },
  )

  const onConversationCreationSuccess = () => {
    onSent?.()
    onClose?.()
  }

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getConversationsIsCreating,
    onConversationCreationSuccess,
    createdInfoDialogProps: { titleMessageName: initialSubject },
  })

  const handleSend = (recipients: EmailEntityConfigRecipient[]) => {
    if (validate()) {
      displayConversationCreationResult()
      dispatch(
        sendWelcomeEmail({
          clientId,
          transport,
          subject: subject.value,
          body: message.value,
          recipients,
        }),
      )
    }
  }

  const updateMessage = (text: string) => {
    message.setValue(text)
    conversationDialogRef.current?.resetMessageState()
  }

  useEffect(() => {
    updateMessage(initialMessage)
  }, [transport])

  return (
    <NewConversationDialog
      clientId={clientId}
      handleSend={handleSend}
      handleTransportChange={setTransport}
      isLoading={isLoading}
      message={message}
      ref={conversationDialogRef}
      subject={subject}
      title={t('Dialogs:SEND_BOOP_LINK_DIALOG.SEND_BOOP_SYSTEM_NAME_LINK')}
      to={to}
      transport={transport}
      onClose={onClose}
      {...rest}
    />
  )
}

export default SendBoopLinkDialog
