import React from 'react'

import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import { Reminder } from '~/types'

export interface ReminderStatusCellProps {
  reminder: Reminder
}

const ReminderStatusCell = ({ reminder }: ReminderStatusCellProps) => (
  <ReminderStateLabel reminder={reminder} variant="small" />
)

export default ReminderStatusCell
