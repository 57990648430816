import React from 'react'
import { useSelector } from 'react-redux'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import AccentLabel from '~/components/common/labels/AccentLabel'
import { getInventoryShipmentStatus } from '~/store/reducers/constants'

export interface ShipmentStatusLabelProps {
  statusId: string
}

const ShipmentStatusLabel = ({ statusId }: ShipmentStatusLabelProps) => {
  const InventoryShipmentStatus = useSelector(getInventoryShipmentStatus)
  const status = Utils.findById(statusId, InventoryShipmentStatus) || {}

  return (
    <AccentLabel
      color={status.color}
      colorVariant={status.colorVariant}
      id={status.id}
      name={LanguageUtils.getTranslatedFieldName(status)}
    />
  )
}

export default ShipmentStatusLabel
