const pTagRegex = /^<p>(.*?)<\/p>$/
const tagsCountRegex = /<(.*?)>/g

export const tryFlattenSimplePTag = (text = '') => {
  if (text.match(tagsCountRegex)?.length === 2) {
    const tryMatch = text.match(pTagRegex)
    return tryMatch ? tryMatch[1] : text
  }

  return text
}
