import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { Defaults } from '@pbt/pbt-ui-components'
import { isIPad } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import {
  getFileUrl,
  getPrintIsLoading,
  loadingComplete,
  printingComplete,
} from '~/store/duck/print'

import PrintIFrame from './PrintIFrame'

const PDF_PRINT_DURATION = 7000

const PrintFile = () => {
  const dispatch = useDispatch()
  const fileUrl = useSelector(getFileUrl)
  const printIsLoading = useSelector(getPrintIsLoading)

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const [currentUrl, setCurrentUrl] = useState<string | undefined>(undefined)
  const [mouseMoved, setMouseMoved] = useState(false)
  const [printedOnMouseMove, setPrintedOnMouseMove] = useState(false)

  const onMouseMove = () => {
    setMouseMoved(true)
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    return () => document.removeEventListener('mousemove', onMouseMove)
  }, [])

  const printFinished = () => {
    dispatch(printingComplete())
    setPrintedOnMouseMove(false)
  }

  const debouncedOnAfterPrint = useDebouncedCallback(
    printFinished,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  useEffect(() => {
    if (printedOnMouseMove && mouseMoved) {
      printFinished()
    }
  }, [mouseMoved, printedOnMouseMove])

  const timedOutCloseOnMouseMove = useDebouncedCallback(() => {
    setMouseMoved(false)
    setPrintedOnMouseMove(true)
  }, PDF_PRINT_DURATION)

  const debouncedOnBeforePrint = useDebouncedCallback(() => {
    timedOutCloseOnMouseMove.cancel()
  }, Defaults.DEBOUNCE_ACTION_TIME)

  const print = () => {
    const contentWindow = iframeRef.current?.contentWindow
    const contentDocument = iframeRef.current?.contentDocument

    // print events only for HTML-documents, not for pdf
    timedOutCloseOnMouseMove()

    if (isIPad()) {
      const newWindow = window.open()

      if (newWindow) {
        try {
          const width = contentDocument?.body?.children?.[0]?.clientWidth
          const widthStr = width ? `${width}px` : 'auto'

          newWindow.document.write('<div id="loader"></div>')
          newWindow.document.write(
            `<style>
            body {
              margin: 0;
              display: flex;
              justify-content: center;
              width: 100%;
            }
  
            #loader {
              border: 12px solid #f3f3f3;
              border-radius: 50%;
              border-top: 12px solid #5A98B1;
              width: 80px;
              height: 80px;
              animation: spin 1s linear infinite;
              position: absolute;
              margin-top: calc(50vh - 80px);
              margin-left: auto;
              margin-right: auto;
            }
  
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          </style>`,
          )

          newWindow.document.write(
            `<script>
            setTimeout(() => {
              var loader = document.getElementById('loader');
              var iframe = document.createElement('iframe');
              iframe.style = "width: ${widthStr}; height: auto; border: none; margin: 0 auto; display: block;";
              iframe.title = "print-iframe";
              iframe.onload = function() { document.body.removeChild(loader); window.focus(); window.print(); };
              iframe.src = "${fileUrl}";
              document.body.appendChild(iframe);
            }, 1000)
          </script>`,
          )

          newWindow.document.close()

          newWindow.onbeforeprint = debouncedOnBeforePrint
          newWindow.onafterprint = () => {
            newWindow.close()
            debouncedOnAfterPrint()
          }
        } catch (error) {
          // ignore errors
        }
      }
    } else {
      if (contentWindow) {
        try {
          contentWindow.onbeforeprint = debouncedOnBeforePrint
          contentWindow.onafterprint = debouncedOnAfterPrint
        } catch (error) {
          // will be handled by timeout
        }
      }

      if (contentDocument && contentDocument.images?.[0]) {
        contentDocument.body.style.objectFit = 'contain'
        contentDocument.images[0].style.maxHeight = '100%'
        contentDocument.images[0].style.maxWidth = '100%'
      }

      if (contentWindow) {
        contentWindow.focus()
        contentWindow.print()
      }
    }
  }

  const handlePdfForPrintLoaded = () => {
    if (fileUrl) {
      print()
      dispatch(loadingComplete())
    }
  }

  useEffect(() => {
    if (printIsLoading && fileUrl && currentUrl === fileUrl) {
      handlePdfForPrintLoaded()
    }
  }, [printIsLoading])

  useEffect(() => {
    setCurrentUrl(fileUrl)
  }, [fileUrl])

  return (
    <PrintIFrame
      key={fileUrl}
      ref={iframeRef}
      src={fileUrl}
      onLoad={handlePdfForPrintLoaded}
    />
  )
}

export default PrintFile
