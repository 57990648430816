import React from 'react'
import { useSelector } from 'react-redux'

import { getWhiteboardSchedules } from '~/store/reducers/whiteboard'

import WhiteboardColumns, { WhiteboardColumnsProps } from '../WhiteboardColumns'
import WhiteboardEmptyColumn from '../WhiteboardEmptyColumn'
import DragAndDropView from './DragAndDropView'

interface WhiteboardDefaultViewProps
  extends Omit<WhiteboardColumnsProps, 'slots' | 'EmptyColumnComponent'> {
  HeaderComponent: React.JSXElementConstructor<any>
  selectedView: string
}

const WhiteboardDefaultView = ({
  selectedView,
  ...rest
}: WhiteboardDefaultViewProps) => {
  const schedules = useSelector(getWhiteboardSchedules)
  const currentSchedules = schedules[selectedView] || []

  const { slots = {} } = currentSchedules[0] || {}

  return (
    <DragAndDropView {...rest}>
      <WhiteboardColumns
        EmptyColumnComponent={WhiteboardEmptyColumn}
        slots={slots}
        {...rest}
      />
    </DragAndDropView>
  )
}

export default WhiteboardDefaultView
