import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  ClassesType,
  Constant,
  CustomFieldValidatorState,
  ErrorTooltip,
  LanguageUtils,
  Nil,
  PermissionArea,
  PuiDialog,
  PuiPopper,
  PuiTextArea,
  Question as QuestionType,
  QuestionAnswer,
  Shadow,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getAnswerInputTypes } from '~/store/reducers/constants'
import { DataHandleWithUnsavedChanges } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

import AnswerPopup from './AnswerPopup'
import AnswersTable from './AnswersTable'
import ConfirmAnswerSwitchPopup from './ConfirmAnswerSwitchPopup'
import PreviewQuestionPopup from './PreviewQuestionPopup'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    button: {
      width: 150,
      height: 40,
    },
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: theme.spacing(0.5),
    },
    labelRoot: {
      margin: 0,
    },
  }),
  { name: 'Question' },
)

export interface QuestionHandle extends DataHandleWithUnsavedChanges {
  proceed: () => void
}

interface QuestionProps {
  classes?: ClassesType<typeof useStyles>
  onChange?: () => void
  onOk?: (newQuestion: QuestionType) => void
  question?: QuestionType
  view?: boolean
}

const Question = forwardRef<QuestionHandle, QuestionProps>(function Question(
  { classes: classesProp, question: questionProp, view, onOk, onChange = R.F },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const AnswerInputTypes: Constant[] = useSelector(getAnswerInputTypes)
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const isCreate = !questionProp

  const [addAnswerPopupOpen, setAddAnswerPopupOpen] = useState(false)
  const [previewPopupOpen, setPreviewPopupOpen] = useState(false)
  const [confirmAnswerSwitchPopupOpen, setConfirmAnswerSwitchPopupOpen] =
    useState(false)
  const [questionCandidate, setQuestionCandidate] = useState<
    Partial<QuestionType> | Nil
  >(questionProp)
  const [answerToEdit, setAnswerToEdit] = useState<QuestionAnswer | Nil>(null)
  const [pendingChange, setPendingChange] = useState<string | Nil>(null)

  const rootRef = useRef<HTMLDivElement>(null)
  const answersLength = questionCandidate?.answers?.length || 0

  const getAnswerValue = () => {
    if (!questionCandidate?.answers || answersLength === 0) {
      return ''
    }

    return answersLength === 1 &&
      questionCandidate?.answers[0].inputs.length === 1
      ? R.path(['answers', 0, 'inputs', 0, 'inputTypeId'])(questionCandidate)
      : 'multichoice'
  }

  const validateAnswerType = ({
    state: { answer },
  }: CustomFieldValidatorState) => answer !== 'multichoice' || answersLength > 1

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'questionText',
        label: t('Admin:CATALOG.QUESTION.QUESTION_TEXT_FIELD'),
        validators: ['required'],
        initialValue: isCreate ? '' : questionCandidate?.questionText,
      },
      {
        name: 'answer',
        label: t('Common:ANSWER_ONE'),
        validators: [
          'required',
          { validator: validateAnswerType, validatorName: 'answersRequired' },
        ],
        initialValue: isCreate ? '' : getAnswerValue(),
      },
    ],
    false,
  )

  const { questionText, answer } = fields

  useFieldsChanged(() => {
    onChange()
  }, fields)

  useEffect(() => {
    setQuestionCandidate(questionProp)
    if (questionProp) {
      reset()
    }
  }, [questionProp])

  const isMultiChoice = answer.value === 'multichoice'

  useEffect(() => {
    if (answer.value) {
      const newAnswers = isMultiChoice
        ? []
        : [
            {
              inputs: [
                {
                  inputTypeId: answer.value,
                },
              ],
            },
          ]

      setQuestionCandidate({
        ...(questionCandidate || {}),
        answers: newAnswers as QuestionAnswer[],
      })
    }
  }, [answer.value])

  useEffect(() => {
    setQuestionCandidate({
      ...(questionCandidate || {}),
      questionText: questionText.value,
      active: true,
    })
  }, [questionText.value])

  const createQuestion = () => ({
    ...(questionCandidate || {}),
    questionText: questionText.value,
  })

  const next = () => {
    if (onOk && validate()) {
      onOk(createQuestion() as QuestionType)
    }
  }

  useImperativeHandle(ref, () => ({
    validate,
    get: createQuestion,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
    proceed: next,
  }))

  const openPreview = () => {
    if (validate()) {
      setPreviewPopupOpen(true)
    }
  }

  const onAddAnswerRequested = () => {
    setAddAnswerPopupOpen(true)
  }

  const onAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMultiChoice && questionCandidate?.answers && answersLength > 0) {
      setPendingChange(event.target.value)
      setConfirmAnswerSwitchPopupOpen(true)
    } else {
      answer.set(event)
    }
  }

  const onConfirmAnswerSwitchPopupProceed = () => {
    answer.setValue(pendingChange)
    setPendingChange(null)
    setConfirmAnswerSwitchPopupOpen(false)
  }

  const onAnswerAdded = (newAnswer: QuestionAnswer) => {
    setQuestionCandidate({
      ...(questionCandidate || {}),
      answers: (questionCandidate?.answers || []).concat(newAnswer),
    })
    setAddAnswerPopupOpen(false)
  }

  const onAnswerEdited = (newAnswer: QuestionAnswer) => {
    const index =
      answerToEdit && questionCandidate?.answers?.indexOf(answerToEdit)
    const answersToEdit = questionCandidate?.answers || []
    setQuestionCandidate({
      ...(questionCandidate || {}),
      answers: index
        ? R.update(index, newAnswer, answersToEdit)
        : answersToEdit,
    })
    setAnswerToEdit(null)
    setAddAnswerPopupOpen(false)
  }

  const handleDeleteAnswer = (answerToDelete: QuestionAnswer) => {
    setQuestionCandidate({
      ...(questionCandidate || {}),
      answers: (questionCandidate?.answers || []).filter(
        (item) => !R.equals(item, answerToDelete),
      ),
    })
  }

  const handleEditAnswer = (newAnswerToEdit: QuestionAnswer) => {
    setAnswerToEdit(newAnswerToEdit)
    setAddAnswerPopupOpen(true)
  }

  const handleAnswerOrderChange = (oldIndex: number, newIndex: number) => {
    const result = Array.from(questionCandidate?.answers || [])
    const [removed] = result.splice(oldIndex, 1)
    result.splice(newIndex, 0, removed)

    setQuestionCandidate({
      ...(questionCandidate || {}),
      answers: result,
    })
  }

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      pb={3}
      px={3}
      ref={rootRef}
    >
      <PuiTextArea
        disabled={!permissions.update}
        field={questionText}
        placeholder={`${t(
          'Admin:CATALOG.QUESTION.QUESTION_TEXT_PLACEHOLDER',
        )}*`}
      />
      <Grid item mt={2}>
        <FormControl component="fieldset">
          <ErrorTooltip
            message={
              isMultiChoice && answersLength < 2
                ? t('Tooltips:EMPTY_ANSWERS_ERROR_MESSAGE')
                : t('Tooltips:CHOOSE_AN_ANSWER_TYPE')
            }
            open={questionText.valid && !answer.valid && !addAnswerPopupOpen}
            placement="top"
          >
            <FormLabel component="legend">
              <TextWithTooltip
                strong
                tooltipText={t('Tooltips:ANSWERS_TYPES')}
                variant="subheading3"
              >
                {t('Common:ANSWER_ONE_OR_OTHER')}*
              </TextWithTooltip>
            </FormLabel>
          </ErrorTooltip>
          <RadioGroup
            aria-label={t('Common:ANSWER_OTHER')}
            name="answers1"
            value={answer.value}
            onChange={onAnswerChange}
          >
            {AnswerInputTypes.map((type) => (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classes.radioLabel,
                }}
                control={
                  <Radio
                    className={classes.radio}
                    disabled={!permissions.update}
                  />
                }
                key={type.id}
                label={LanguageUtils.getTranslatedFieldName(type)}
                value={type.id}
              />
            ))}
            <FormControlLabel
              classes={{
                root: classes.labelRoot,
                label: classes.radioLabel,
              }}
              control={
                <Radio
                  className={classes.radio}
                  disabled={!permissions.update}
                />
              }
              label={t('Admin:CATALOG.QUESTION.MULTIPLE_CHOICE')}
              value="multichoice"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        {isMultiChoice && questionCandidate?.answers && answersLength > 0 && (
          <AnswersTable
            answers={questionCandidate?.answers}
            onDelete={handleDeleteAnswer}
            onEdit={handleEditAnswer}
            onOrderChange={handleAnswerOrderChange}
          />
        )}
        {isMultiChoice && (
          <Grid item pl={4.5} pt={2}>
            <AddButton
              addText={t('Common:ADD_ANSWER')}
              onAdd={onAddAnswerRequested}
            />
          </Grid>
        )}
      </Grid>
      {!view && (
        <Grid container item alignItems="center" mt={3}>
          <Grid item>
            <Fab
              className={classes.button}
              color="inherit"
              type="submit"
              variant="extended"
              onClick={next}
            >
              {t('Common:NEXT')}
            </Fab>
          </Grid>
          {questionCandidate?.answers && answersLength > 0 && (
            <Grid item ml={4}>
              <PreviewButton onClick={openPreview} />
            </Grid>
          )}
        </Grid>
      )}
      <AnswerPopup
        Component={view ? PuiDialog : PuiPopper}
        PopperProps={{
          anchorEl: rootRef.current,
        }}
        answer={answerToEdit}
        open={addAnswerPopupOpen}
        onAnswerAdded={onAnswerAdded}
        onAnswerEdited={onAnswerEdited}
        onClose={() => {
          setAddAnswerPopupOpen(false)
          setAnswerToEdit(null)
        }}
      />
      <PreviewQuestionPopup
        Component={view ? PuiDialog : PuiPopper}
        PopperProps={{
          disableClickAwayListener: true,
          anchorEl: rootRef.current,
        }}
        open={previewPopupOpen}
        question={questionCandidate as QuestionType}
        onClose={() => setPreviewPopupOpen(false)}
      />
      <ConfirmAnswerSwitchPopup
        Component={view ? PuiDialog : PuiPopper}
        PopperProps={{
          anchorEl: rootRef.current,
        }}
        open={confirmAnswerSwitchPopupOpen}
        question={questionCandidate as QuestionType}
        onClose={() => {
          setPendingChange(null)
          setConfirmAnswerSwitchPopupOpen(false)
        }}
        onProceed={onConfirmAnswerSwitchPopupProceed}
      />
      <Shadow
        open={
          addAnswerPopupOpen || previewPopupOpen || confirmAnswerSwitchPopupOpen
        }
      />
    </Grid>
  )
})

export default Question
