import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Nil, TextInteractive } from '@pbt/pbt-ui-components'

import { Notification } from '~/types'

import NotificationTypeIcon from './NotificationTypeIcon'

const useStyles = makeStyles(
  () => ({
    message: {
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
    },
  }),
  { name: 'NotificationHistoryTableMessageColumn' },
)

export interface NotificationHistoryTableMessageColumnProps {
  notification: Notification | Nil
  prop: keyof Notification
  showTypeIcon?: boolean
}

const NotificationHistoryTableMessageColumn = ({
  notification,
  showTypeIcon = false,
  prop,
}: NotificationHistoryTableMessageColumnProps) => {
  const classes = useStyles()
  const isLoading = R.isEmpty(notification)

  return (
    <Grid container alignItems="center" columnSpacing={2} wrap="nowrap">
      {showTypeIcon && (
        <Grid item>
          {isLoading ? (
            <Skeleton height={30} variant="circular" width={30} />
          ) : (
            <NotificationTypeIcon notification={notification} />
          )}
        </Grid>
      )}
      <Grid item>
        <Dotdotdot clamp={1}>
          <TextInteractive
            className={classes.message}
            isLoading={isLoading}
            strong={!notification?.read}
            variant="body"
          >
            {notification?.[prop]}
          </TextInteractive>
        </Dotdotdot>
      </Grid>
    </Grid>
  )
}
export default NotificationHistoryTableMessageColumn
