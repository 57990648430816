import React from 'react'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'initial',
      cursor: 'pointer',
    },
    icon: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'EducationalVideoLink' },
)

interface EducationalVideoLinkProps {
  classes?: ClassesType<typeof useStyles>
  onClick: (value: string) => void
  value: string
}

const EducationalVideoLink = ({
  onClick,
  value,
  classes: classesProp,
}: EducationalVideoLinkProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      wrap="nowrap"
      onClick={() => onClick(value)}
    >
      <SmartDisplayIcon className={classes.icon} />
      <Text link ml={1} variant="body2">
        {value}
      </Text>
    </Grid>
  )
}

export default EducationalVideoLink
