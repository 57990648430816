import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

export enum MeasurementConstant {
  WeightUnits = 'WeightUnits',
  TemperatureUnits = 'TemperatureUnits',
}

export const ConstantToUnitType: Record<MeasurementConstant, UnitTypes> = {
  [MeasurementConstant.WeightUnits]: UnitTypes.WEIGHT,
  [MeasurementConstant.TemperatureUnits]: UnitTypes.TEMPERATURE,
}
