import { To, useNavigate, useParams } from 'react-router-dom'
import * as R from 'ramda'

import { CustomNavigationPrevState } from '~/types'

export const useNavigatePrevState = () => {
  const params = useParams()
  const navigate = useNavigate()

  const state: CustomNavigationPrevState = {
    from: params['*'],
    fromParams: R.omit(['*'], params),
  }

  return (url: To) => {
    navigate(url, { state })
  }
}
