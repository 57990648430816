import React, { forwardRef } from 'react'
import { Grid, GridProps, Tooltip } from '@mui/material'
import { Nil } from '@pbt/pbt-ui-components'

interface ColorPinIconProps
  extends Omit<
    GridProps,
    'bgcolor' | 'borderRadius' | 'borderRadius' | 'height' | 'width' | 'color'
  > {
  color: string | Nil
  tooltipText?: string | Nil
  variant?: 'big' | 'default'
}

enum PinSize {
  'big' = 16,
  'default' = 12,
}

const ColorPinIcon = forwardRef<HTMLDivElement, ColorPinIconProps>(
  function ColorPinIcon(
    { color, variant = 'default', tooltipText, ...rest },
    ref,
  ) {
    const size = PinSize[variant]

    if (!color) {
      return null
    }

    const elem = (
      <Grid
        bgcolor={color}
        borderRadius="50%"
        height={size}
        minWidth={size}
        ref={ref}
        width={size}
        {...rest}
      />
    )

    if (tooltipText) {
      return (
        <Tooltip placement="top" sx={{ cursor: 'pointer' }} title={tooltipText}>
          {elem}
        </Tooltip>
      )
    }

    return elem
  },
)

export default ColorPinIcon
