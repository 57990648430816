import React from 'react'
import { Route } from 'react-router-dom'

export const renderMultiPathRoute = ({
  path,
  element,
}: {
  element: React.ReactNode
  path: string | string[]
}) =>
  Array.isArray(path) ? (
    path.map((pathItem, pathIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <Route element={element} key={pathIndex} path={pathItem} />
    ))
  ) : (
    <Route element={element} path={path} />
  )
