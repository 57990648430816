import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import * as R from 'ramda'
import { PuiSelect, useFields } from '@pbt/pbt-ui-components'

import { getBoopVariations } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps, PracticeMobileFields } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const MobileSection = ({ business }: BasePracticeDetailsSectionProps) => {
  const BoopVariations = useSelector(getBoopVariations)

  const { t } = useTranslation(['Admin'])

  const { fields, reset } = useFields(
    [
      {
        name: 'boopVariationId',
        type: 'select',
        label: t('Admin:PRACTICE.BOOP_VARIATION'),
        initialValue: business.boopVariationId,
      },
    ],
    false,
  )

  usePracticeFieldsSection<PracticeMobileFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.MOBILE,
    reset,
  })

  return (
    <Grid container item>
      <FormControl fullWidth margin="normal" style={{ maxWidth: 160 }}>
        <InputLabel htmlFor="boop-variation-select">
          {fields.boopVariationId.label}
        </InputLabel>
        <PuiSelect
          field={fields.boopVariationId}
          input={<Input id="boop-variation-select" />}
          items={BoopVariations}
        />
      </FormControl>
    </Grid>
  )
}

export default memo(MobileSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
