import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { getUser } from '~/store/reducers/users'

import DetailsBackButton from '../DetailsBackButton'
import NewClientDetailsEdit from '../new-client/NewClientDetailsEdit'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    clientsDetailsContainer: {
      [theme.breakpoints.up('md')]: {
        boxShadow: '0 0 0 1px #E5E5E5',
        width: 650,
      },
    },
  }),
  { name: 'ClientDetailsEditPage' },
)

const ClientDetailsEditPage = () => {
  const navigate = useNavigate()
  const { clientId = '' } = useParams()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const client = useSelector(getUser(clientId))
  const onBackButtonClick = () => {
    navigate(`/client/${clientId}`)
  }

  const onOk = () => {
    navigate(`/client/${clientId}/patient/${client?.patients?.[0]}/new`)
  }

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      px={isMobile ? 0 : 3}
      wrap="nowrap"
    >
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Clients:EDIT_CLIENT_DETAILS.DETAILS_BACK')}
      </DetailsBackButton>
      <Grid item className={classes.clientsDetailsContainer} py={2}>
        {clientId && (
          <NewClientDetailsEdit
            clientId={clientId}
            renderCoparents={false}
            title={t('Common:CLIENT_DETAILS')}
            onOk={onOk}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ClientDetailsEditPage
