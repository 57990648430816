import React, { useState } from 'react'
import { InputAdornment } from '@mui/material'
import {
  DateUtils,
  Field,
  PuiPopper,
  PuiTextField,
  PuiTextFieldProps,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import ApproximateAgeLabel from '../labels/ApproximateAgeLabel'
import DateOfBirth from './DateOfBirth'

export interface DateOfBirthSelectorProps {
  approximateAge: Field
  disablePortal?: boolean
  dob: Field
  label?: PuiTextFieldProps['label']
}

const DateOfBirthSelector = ({
  approximateAge,
  dob,
  label,
  disablePortal = false,
}: DateOfBirthSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleDone = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ApproximateAgeLabel isApproximate={dob.value && approximateAge.value}>
        <PuiTextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Toggle />
              </InputAdornment>
            ),
          }}
          label={label}
          value={DateUtils.formatDate(dob.value, true)}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        />
      </ApproximateAgeLabel>
      <PuiPopper
        hideCloseButton
        anchorEl={anchorEl}
        disablePortal={disablePortal}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        onClose={() => setAnchorEl(null)}
      >
        <DateOfBirth
          approximateAge={approximateAge}
          dob={dob}
          onDone={handleDone}
        />
      </PuiPopper>
    </>
  )
}

export default DateOfBirthSelector
