import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Defaults,
  PrimitiveTable,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import CreationDateCell from '~/components/common/lists/primitive-table/cells/CreationDateCell'
import i18n from '~/locales/i18n'
import {
  fetchMoreItemsForWellnessPlansList,
  fetchWellnessPlansList,
} from '~/store/actions/wellnessPlans'
import {
  getMultipleWellnessPlansVersions,
  getWellnessPlansIsFetchingList,
  getWellnessPlansVersionsList,
  getWellnessPlansVersionsTotalCount,
} from '~/store/reducers/wellnessPlans'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

import WellnessPlanDetails from './details/WellnessPlanDetails'
import NoWellnessPlansScreen from './NoWellnessPlansScreen'

const columns = [
  {
    label: i18n.t('Common:VERSION'),
    prop: 'versionNumber',
    width: 5,
  },
  {
    label: i18n.t('Common:CREATED'),
    prop: CreationDateCell,
    width: 4,
  },
  {
    label: i18n.t('Common:ENROLLED_PATIENTS'),
    prop: (item: WellnessPlanVersion) => item.activePatients?.toString(),
    width: 4,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    prop: (item: WellnessPlanVersion) => boolToYesNoString(item.active),
    width: 1,
  },
]

export interface WellnessPlansTableComponentProps {
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
  wellnessPlanVersionId?: string
}

const WellnessPlansTableComponent = ({
  headerButtons,
  wellnessPlanVersionId,
  onDetailsClose,
}: WellnessPlansTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const list = useSelector(getWellnessPlansVersionsList)
  const wellnessPlansVersions = useSelector(
    getMultipleWellnessPlansVersions(list),
  )
  const totalCount = useSelector(getWellnessPlansVersionsTotalCount)
  const isFetchingList = useSelector(getWellnessPlansIsFetchingList)

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchWellnessPlansList({
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        query: search,
      }),
    )
  }, [search])

  const navigateToWellnessPlan = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/wellness-plans/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(wellnessPlansVersions[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(
      fetchMoreItemsForWellnessPlansList({
        from: startIndex,
        to: endIndex,
        query: search,
      }),
    )
  }

  const getDisabled = (item: WellnessPlanVersion) => !item.active

  return (
    <ExpandableTable
      Expander={WellnessPlanDetails}
      NoItemsScreen={NoWellnessPlansScreen}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={wellnessPlanVersionId}
      list={wellnessPlansVersions}
      searchTerm={search}
      title={t('Common:WELLNESS_PLANS')}
      onClose={onDetailsClose}
      onSelected={navigateToWellnessPlan}
    >
      <PrimitiveTable
        columns={columns}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default WellnessPlansTableComponent
