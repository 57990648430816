import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { EditorState } from 'draft-js'
import { last, partition } from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  ButtonWithLoader,
  ClassesType,
  Defaults,
  DocumentFile,
  ErrorTooltip,
  Field,
  Nil,
  PlusButton,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import convertToHTML from '~/components/common/inputs/rich-edit/convertToHTML'
import RichEdit, {
  RichEditHandle,
  RichEditProps,
} from '~/components/common/inputs/rich-edit/RichEdit'
import TextFieldWithOptions, {
  TextFieldWithOptionsProps,
} from '~/components/common/inputs/TextFieldWithOptions'
import DialogNames from '~/constants/DialogNames'
import { getDocumentTypes } from '~/store/reducers/constants'
import { Document } from '~/types'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { tryFlattenSimplePTag } from './utils'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: 'relative',
      minHeight: (props: UseStylesProps) => props.minHeight,
      maxHeight: (props: UseStylesProps) => props.maxHeight,
      border: theme.constants.outlinedInputBorder,
    },
    containerFocused: {
      border: theme.constants.focusedInputBorder,
    },
    containerError: {
      border: theme.constants.errorBorder,
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    title: {
      fontSize: '1.4rem',
      marginRight: theme.spacing(1),
    },
    subTitle: {
      fontWeight: 400,
    },
    titleContainer: {
      marginBottom: theme.spacing(0.5),
    },
    richEditEditor: {
      border: 'none !important',
    },
    richEditorSingleLine: {
      '&& .public-DraftEditor-content': {
        padding: 5,
      },
    },
    richEditorDisabled: {
      cursor: 'default !important',
    },
    plusButton: {
      margin: theme.spacing(0.5, 1),
    },
    addText: {
      fontWeight: 400,
    },
    plusButtonFloating: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      position: 'absolute',
      right: 3,
      bottom: 3,
    },
    plusButtonRoot: {
      width: 'fit-content',
    },
    richEditRoot: {
      wordBreak: 'break-word',
      borderWidth: '0 0 1px 0',
      borderRadius: 0,
    },
    richEditRootNoPlusButtonBlock: {
      borderWidth: 0,
    },
  }),
  { name: 'BaseTemplateInput' },
)

export interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
  maxEditorHeight?: number
  maxHeight?: number
  minEditorHeight?: number
  minHeight?: number
}

export interface BaseTemplateInputProps
  extends UseStylesProps,
    Omit<RichEditProps, 'classes' | 'editorState' | 'setEditorState'> {
  PlainTextFieldProps?: Partial<TextFieldWithOptionsProps>
  applyGetterOverride?: boolean
  className?: string
  classes?: ClassesType<typeof useStyles>
  clientId?: string | Nil
  disabled?: boolean
  enrichDocuments?: boolean
  error?: boolean
  eventId?: string | Nil
  field?: Field
  files?: DocumentFile['file'][]
  filter: string
  hidePanel?: boolean
  hidePlusButtonBlock?: boolean
  isLoading?: boolean
  label?: string
  maxLength?: number
  onAttachClick?: () => void
  onAttachDocuments?: (documents: Document[]) => void
  onBlur?: () => void
  onChange?: (data: string) => void
  onDeleteFile?: RichEditProps['onDeleteFile']
  onFocus?: () => void
  onPreviewClick?: () => void
  onSave?: (value: string) => void
  onSubjectChangeRequested?: (subject: string) => void
  patientId?: string | Nil
  plainTextInput?: boolean
  resetStateOnValueChange?: boolean
  showDynamicText?: boolean
  singleLine?: boolean
  soapId?: string | Nil
  subTitle?: string
  title?: string
  value?: string | Nil
}

export interface BaseTemplateInputHandle {
  getData: () => string
  getHasUnsavedData: () => boolean
  getPlainText?: () => string | undefined
  getUnsavedData: () => string | undefined
  resetState: () => void
  setInitialValue: (value: string | Nil) => void
}

const BaseTemplateInput = forwardRef(function BaseTemplateInput(
  {
    singleLine,
    minHeight,
    maxHeight,
    maxLength,
    className,
    filter,
    label,
    field,
    files,
    title,
    enrichDocuments = true,
    value: valueProp,
    error: errorProp = false,
    onChange: onChangeProp,
    clientId,
    patientId,
    eventId,
    soapId,
    subTitle,
    classes: classesProp,
    onSubjectChangeRequested,
    hidePanel,
    hidePlusButtonBlock = false,
    onPreviewClick,
    disabled,
    plainTextInput = false,
    PlainTextFieldProps = {},
    maxEditorHeight,
    minEditorHeight,
    onSave,
    isLoading,
    onFocus,
    onBlur,
    onAttachClick,
    onAttachDocuments,
    onDeleteFile,
    applyGetterOverride,
    resetStateOnValueChange,
    showDynamicText = false,
    showAttachment,
    ...rest
  }: BaseTemplateInputProps,
  ref: ForwardedRef<BaseTemplateInputHandle>,
) {
  const classes = useStyles({
    classes: classesProp,
    minHeight,
    maxHeight,
    maxEditorHeight,
    minEditorHeight,
  })
  const DocumentTypes = useSelector(getDocumentTypes)
  const { t } = useTranslation('Common')

  const richEditRef = useRef<RichEditHandle>(null)

  const error = field ? field.valid === false : errorProp
  const value = field?.value ?? valueProp
  const onChange = field?.setValue ?? onChangeProp
  const initialExternalValue = field ? field?.initialValue : value

  const templateType = Utils.findConstantByName(filter, DocumentTypes)

  const [isEditorFocused, setIsEditorFocused] = useState(false)
  const [initialValue, setInitialValue] = useState(value)
  const [editorState, setEditorState] = useState<EditorState>()
  const [plainTextValue, setPlainTextValue] = useState(value || '')

  const [openSelectAttachmentDialog] = useDialog(DialogNames.SELECT_ATTACHMENT)

  const resetState = () => {
    if (initialExternalValue !== initialValue) {
      setEditorState(richEditRef.current?.regenerateEditorState())
      setInitialValue(initialExternalValue)
    }
  }

  useEffectExceptOnMount(() => {
    if (resetStateOnValueChange) {
      resetState()
    }
  }, [initialExternalValue])

  useEffect(() => {
    if (plainTextInput && value !== plainTextValue) {
      setPlainTextValue(value)
    }
  }, [value])

  const getValues = (newEditorState?: EditorState): [string, string] => {
    const newValue = convertToHTML(newEditorState)
    const flatValue = tryFlattenSimplePTag(newValue)

    return [flatValue, newValue]
  }

  const getUnsavedData = (newEditorState?: EditorState): string | undefined => {
    if (plainTextInput) {
      return plainTextValue
    }

    const [flatValue, newValue] = getValues(newEditorState)

    return newValue !== value && flatValue !== value ? flatValue : undefined
  }

  const fireOnChange = (newEditorState: EditorState) => {
    const data = getUnsavedData(newEditorState)
    if (onChange && typeof data !== 'undefined') {
      onChange(data)
    }
  }

  const debouncedOnChange = useDebouncedCallback(
    fireOnChange,
    Defaults.NOTES_TEMPLATE_DEBOUNCE_UPDATE,
  )

  if (field && applyGetterOverride) {
    Object.defineProperty(field, 'value', {
      get() {
        const data = getUnsavedData(editorState)

        return typeof data === 'undefined' ? value : data
      },
    })
  }

  const onDocumentsFetched = (documents: Document[]) => {
    if (documents.length > 0) {
      const [embeddableDocuments, otherDocuments] = partition<Document>(
        (document) => Boolean(document.template?.body),
        documents,
      )

      if (onAttachDocuments && otherDocuments.length > 0) {
        onAttachDocuments(otherDocuments)
      }

      const bodies = embeddableDocuments.map(
        (document) => document.template?.body,
      )

      if (!bodies.length) {
        return
      }

      if (plainTextInput) {
        if (onChange) {
          onChange((value ?? '') + bodies.join('\n'))
        }
      } else {
        const newValue = convertToHTML(editorState) + bodies.join('\n')
        setEditorState(undefined)
        setInitialValue(newValue)
      }

      if (onSubjectChangeRequested) {
        const subjects = documents
          .map((document) => document.subject)
          .filter(Boolean)

        const newSubject = last(subjects)
        if (newSubject) {
          onSubjectChangeRequested(newSubject)
        }
      }
    }
  }

  const onRichEditFocus = () => {
    setIsEditorFocused(true)
    if (onFocus) {
      onFocus()
    }
  }

  const onRichEditBlur = () => {
    setIsEditorFocused(false)
    if (onBlur) {
      onBlur()
    }
  }

  const onPlusButtonClick = () => {
    openSelectAttachmentDialog({
      documentTypes: [templateType],
      eventId,
      soapId,
      clientId,
      enrich: enrichDocuments,
      patientId,
      label: label?.toLowerCase(),
      onDocumentsFetched,
      convertToTextDocument: plainTextInput,
      skipFileTemplates: !showAttachment,
    })
  }

  useImperativeHandle(ref, () => ({
    getHasUnsavedData: () => Boolean(getUnsavedData(editorState)),
    getUnsavedData: () => getUnsavedData(editorState),
    getData: () => getValues(editorState)[0],
    resetState: () => resetState(),
    getPlainText: () => richEditRef.current?.getPlainText(),
    setInitialValue,
  }))

  return (
    <ErrorTooltip
      message={field?.message}
      open={field?.open && !field?.valid}
      validateTag={field?.validateTag}
    >
      <Grid
        container
        item
        className={className}
        direction="column"
        wrap="nowrap"
      >
        <Grid
          container
          item
          alignItems="center"
          className={classNames({
            [classes.titleContainer]: title || subTitle,
          })}
        >
          {title && (
            <Grid item>
              <Text className={classes.title} variant="h2">
                {title}
              </Text>
            </Grid>
          )}
          {subTitle && (
            <Grid item>
              <Text className={classes.subTitle} py={0.5} variant="body2">
                {subTitle}
              </Text>
            </Grid>
          )}
          {onPreviewClick && (
            <Grid item>
              <PreviewButton onClick={onPreviewClick} />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          className={classNames(classes.container, {
            [classes.containerFocused]: isEditorFocused,
            [classes.containerError]: error,
          })}
          direction="column"
          wrap="nowrap"
          onBlur={onRichEditBlur}
          onFocus={onRichEditFocus}
        >
          {isLoading && <LinearProgress />}
          {plainTextInput ? (
            <TextFieldWithOptions
              files={files}
              hidePanel={hidePanel}
              maxEditorHeight={maxEditorHeight}
              maxLength={maxLength}
              minEditorHeight={minEditorHeight}
              plainTextValue={plainTextValue}
              onAttachClick={onAttachClick}
              onChange={(newValue) => {
                setPlainTextValue(newValue)
                if (onChange) {
                  onChange(newValue)
                }
              }}
              onDeleteFile={onDeleteFile}
              {...PlainTextFieldProps}
            />
          ) : (
            <RichEdit
              classes={{
                root: classNames(classes.richEditRoot, {
                  [classes.richEditRootNoPlusButtonBlock]: hidePlusButtonBlock,
                }),
                editor: classNames(classes.richEditEditor, {
                  [classes.richEditorSingleLine]: singleLine,
                  [classes.richEditorDisabled]: disabled,
                }),
              }}
              disabled={disabled}
              editorState={editorState as EditorState}
              files={files}
              hidePanel={hidePanel || singleLine}
              initialHTML={initialValue}
              maxEditorHeight={maxEditorHeight}
              maxLength={maxLength}
              minEditorHeight={minEditorHeight}
              ref={richEditRef}
              setEditorState={(newState) => {
                // prevent editor from overriding config that may be loading
                if (!isLoading) {
                  setEditorState(newState)
                  if (onChange) {
                    debouncedOnChange(newState)
                  }
                }
              }}
              showAttachment={showAttachment}
              showDynamicText={showDynamicText}
              showSignature={false}
              onAttachClick={onAttachClick}
              onDeleteFile={onDeleteFile}
              {...rest}
            />
          )}
          {!disabled &&
            !hidePlusButtonBlock &&
            (singleLine ? (
              <PlusButton
                classes={{
                  root: classNames(
                    classes.plusButtonRoot,
                    classes.plusButtonFloating,
                  ),
                  label: classes.addText,
                  iconContainer: classes.plusButton,
                }}
                onClick={onPlusButtonClick}
              />
            ) : (
              <Grid
                container
                item
                alignItems="center"
                justifyContent={onSave ? 'space-between' : 'flex-end'}
                p={0.5}
              >
                {onSave && (
                  <ButtonWithLoader
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={(event) => {
                      event.preventDefault()
                      event.stopPropagation()

                      const [flatValue] = getValues(editorState)
                      onSave(flatValue)
                    }}
                  >
                    {t('Common:SAVE_ACTION')}
                  </ButtonWithLoader>
                )}
                <PlusButton
                  alignItems="center"
                  classes={{
                    root: classes.plusButtonRoot,
                    label: classes.addText,
                    iconContainer: classes.plusButton,
                  }}
                  label={isEditorFocused ? t('Common:ADD_TEMPLATE') : undefined}
                  /* Using mousedown event so it is fired before blur on rich edit */
                  onMouseDown={onPlusButtonClick}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </ErrorTooltip>
  )
})

export default BaseTemplateInput
