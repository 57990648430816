import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Calendar,
  moment,
  PermissionArea,
} from '@pbt/pbt-ui-components'

import PuiTabs, { PuiTab } from '~/components/common/PuiTabs'
import RightRail, { RightRailProps } from '~/components/common/RightRail'
import DialogNames from '~/constants/DialogNames'
import { MarketplaceWorkflowNames } from '~/constants/marketplaceConstants'
import { RootState } from '~/store'
import { fetchMarketplaceIFrames } from '~/store/duck/marketplace'
import { useGetWorkflowIFrames } from '~/store/hooks/marketplace'
import {
  getCRUDByArea,
  getCurrentBusiness,
  getCurrentBusinessId,
} from '~/store/reducers/auth'
import {
  getSchedulingClientId,
  getSchedulingPatientId,
} from '~/store/reducers/timetable'
import { Schedule, WhiteboardSchedule } from '~/types'
import useDialog from '~/utils/useDialog'
import useTimetableDate from '~/utils/useTimetableDate'

import TimetableCalendarDayPicker from '../TimetableCalendarDayPicker'
import AppointmentTypes from './AppointmentTypes'
import TeamMembers from './TeamMembers'
import TimetableMarketplaceSection from './TimetableMarketplaceSection'
import TimetableSchedulingSection from './TimetableSchedulingSection'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        top: (theme.mixins.toolbar as any)[theme.breakpoints.down('md')]
          .minHeight,
        height: '100%',
      },
    },
    kioskButton: {
      width: 125,
    },
    appointmentButton: {
      width: 165,
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
      },
    },
    buttonContainer: {
      padding: theme.spacing(1, 4, 0, 2),
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    calendarContainer: {
      borderBottom: theme.constants.tabBorder,
      position: 'relative',
      width: '100%',
      padding: theme.spacing(1, 1.5, 0),
    },
    tabRoot: {
      padding: theme.spacing(0.75, 0.33),
    },
    content: {
      flex: 1,
    },
    wrapper: {
      position: 'absolute',
    },
  }),
  { name: 'TimetableRail' },
)

interface TimetableRailTab extends PuiTab {
  component?: React.JSXElementConstructor<any>
}

export interface TimetableRailProps extends RightRailProps {
  onAddAppointmentRequested: () => void
  schedulesSelector: (state: RootState) => Schedule[] | WhiteboardSchedule[]
  tabs?: TimetableRailTab[]
}

const TimetableRail = ({
  schedulesSelector,
  onAddAppointmentRequested,
  tabs: tabsProp,
  ...rest
}: TimetableRailProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'TimeTable'])
  const dispatch = useDispatch()

  const { kioskEnabled } = useSelector(getCurrentBusiness) || {}
  const businessId = useSelector(getCurrentBusinessId)
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const schedulingClientId = useSelector(getSchedulingClientId)
  const schedulingPatientId = useSelector(getSchedulingPatientId)

  const hasSchedulingAssignment = Boolean(
    schedulingClientId && schedulingPatientId,
  )

  const { selectedDate, setSelectedDate } = useTimetableDate()

  const [openKioskDialog] = useDialog(DialogNames.KIOSK)

  const [selectedTab, setSelectedTab] = useState(0)

  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))

  const defaultTabs: TimetableRailTab[] = tabsProp || [
    {
      label: hasSchedulingAssignment
        ? t('Common:TEAM')
        : t('TimeTable:TEAM_FILTERS'),
      component: TeamMembers,
    },
    {
      label: hasSchedulingAssignment
        ? t('Common:APPOINTMENT_ONE')
        : t('TimeTable:APPOINTMENT_FILTERS'),
      component: AppointmentTypes,
    },
  ]

  useEffect(() => {
    if (businessId) {
      dispatch(fetchMarketplaceIFrames())
    }
  }, [businessId])

  const iFrames = useGetWorkflowIFrames(MarketplaceWorkflowNames.SCHEDULER)
  const showMarketplace = iFrames?.length > 0

  const tabs = [
    hasSchedulingAssignment && {
      label: t('Common:SCHEDULING_FOR'),
      component: TimetableSchedulingSection,
    },
    ...defaultTabs,
    showMarketplace && {
      label: t('Common:MARKETPLACE'),
      component: TimetableMarketplaceSection,
    },
  ].filter(Boolean) as TimetableRailTab[]

  const Component = tabs[selectedTab]?.component

  return (
    <RightRail
      hideForAnyScreen
      classes={{ root: classes.root, wrapper: classes.wrapper }}
      {...rest}
    >
      <Grid
        container
        item
        className={classes.buttonContainer}
        justifyContent={isMedium || kioskEnabled ? 'space-between' : 'flex-end'}
        wrap="nowrap"
      >
        {permissions.create && (
          <Grid item>
            <ButtonWithLoader
              className={classes.appointmentButton}
              color="primary"
              onClick={onAddAppointmentRequested}
            >
              {t('Common:ADD_APPOINTMENT')}
            </ButtonWithLoader>
          </Grid>
        )}
        {kioskEnabled && (
          <Grid item>
            <ButtonWithLoader
              className={classes.kioskButton}
              color="secondary"
              onClick={() => openKioskDialog()}
            >
              {t('Common:LAUNCH_KIOSK_SYSTEM')}
            </ButtonWithLoader>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        className={classes.calendarContainer}
        justifyContent="center"
      >
        <Calendar
          renderDay={TimetableCalendarDayPicker}
          value={moment(selectedDate)}
          variant="static"
          onChange={(date) => {
            setSelectedDate(moment(date).format('YYYY-MM-DD'))
          }}
        />
      </Grid>
      <Grid item pt={0.5}>
        <PuiTabs
          classes={{ tabRoot: classes.tabRoot }}
          selectedTab={selectedTab}
          tabs={tabs}
          onSelectedTabChange={setSelectedTab}
        />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.content}
        direction="column"
        justifyContent="center"
        wrap="nowrap"
      >
        {Component && (
          <Component
            schedulesSelector={schedulesSelector}
            selectedDate={selectedDate}
          />
        )}
      </Grid>
    </RightRail>
  )
}

export default TimetableRail
