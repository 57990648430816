import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Text, TextProps } from '@pbt/pbt-ui-components'

import { getCurrentUser } from '~/store/reducers/auth'
import useLogout from '~/utils/useLogout'

interface LockLinkProps extends TextProps {
  className?: string
}

const LockLink = ({ className, ...rest }: LockLinkProps) => {
  const { t } = useTranslation('Common')

  const logout = useLogout()
  const user = useSelector(getCurrentUser)

  return (
    <Text
      inline
      link
      className={className}
      variant="body2"
      onClick={() => {
        logout('/auth/lock', undefined, user?.email)
      }}
      {...rest}
    >
      {t('Common:LOCK_ACTION')}
    </Text>
  )
}

export default LockLink
