import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  moment,
  Nil,
  Text,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import { getLabVendors } from '~/store/reducers/constants'
import { LabTestDashboardOrder, LabTestDashboardTest } from '~/types'
import { BusinessAppointmentType } from '~/types/entities/businessAppointmentType'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: theme.spacing(1, 0),
    },
    title: {
      lineHeight: '16px',
    },
    titleDot: {
      marginBottom: theme.spacing(1),
    },
    testContainer: {
      marginTop: theme.spacing(1),
    },
    test: {
      lineHeight: '16px',
    },
  }),
  { name: 'LabOrderCell' },
)

const getTitle = (
  vendorName: string | Nil,
  labOrderId: string | Nil,
  appointmentType: string | Nil,
  appointmentDateStr: string | Nil,
) => {
  if (vendorName && labOrderId) {
    return `${vendorName}: ${labOrderId}`
  }
  if (vendorName && appointmentType && appointmentDateStr) {
    return `${vendorName}: ${appointmentType} ${appointmentDateStr}`
  }
  if (appointmentType && appointmentDateStr) {
    return `${appointmentType} ${appointmentDateStr}`
  }
  return vendorName || ''
}

export interface LabOrderCellProps {
  classes?: ClassesType<typeof useStyles>
  labTest: {
    appointmentDate: string
    businessAppointmentType?: Pick<BusinessAppointmentType, 'id' | 'name'>
    order: LabTestDashboardOrder | null
    tests: LabTestDashboardTest[]
    vendorId: string
  }
}

const LabOrderCell = ({ classes: classesProp, labTest }: LabOrderCellProps) => {
  const classes = useStyles({ classes: classesProp })
  const LabVendors = useSelector(getLabVendors)

  const {
    appointmentDate,
    tests = [],
    vendorId,
    businessAppointmentType,
  } = labTest

  const labOrderId = labTest.order?.vendorOrderIdentifier
  const isLoading = R.isEmpty(labTest)

  const vendorName = LanguageUtils.getConstantTranslatedName(
    vendorId,
    LabVendors,
  )
  const appointmentType = businessAppointmentType?.name

  const appointmentDateStr = moment(appointmentDate).format('L')

  const title = getTitle(
    vendorName,
    labOrderId,
    appointmentType,
    appointmentDateStr,
  )

  if (isLoading) {
    return <TextInteractive isLoading={isLoading} variant="body2" />
  }

  return (
    <Grid container item className={classes.container} direction="column">
      {title && (
        <Dotdotdot clamp={1} className={classes.titleDot}>
          <Text strong className={classes.title} variant="body2">
            {title}
          </Text>
        </Dotdotdot>
      )}
      {tests.map(({ name }, index) => (
        <Dotdotdot
          clamp={1}
          className={index > 0 ? classes.testContainer : ''}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Text className={classes.test} variant="body2">
            {name}
          </Text>
        </Dotdotdot>
      ))}
    </Grid>
  )
}

export default LabOrderCell
