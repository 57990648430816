import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { InventoryCategoryName } from '~/constants/inventoryCategory'
import {
  useIsDrug,
  useIsFood,
  useIsVetDiet,
  useNavigateOrderToPrescription,
} from '~/store/hooks/orders'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import { Order } from '~/types'

import ItemWithBadges from '../ItemWithBadges'
import PrescriptionItemActions from './actions/PrescriptionItemActions'
import PrescriptionItemButtonSection from './button-sections/PrescriptionItemButtonSection'
import PrescriptionItemContent from './content/PrescriptionItemContent'

export interface PrescriptionItemProps {
  data: Order
  readOnly?: boolean
}

const PrescriptionItem = ({
  data: order,
  readOnly,
  ...rest
}: PrescriptionItemProps) => {
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const navigateToPrescription = useNavigateOrderToPrescription({
    businessId: soapBusinessId,
    soapId,
  })
  const isDrug = useIsDrug(order)
  const isVetDiet = useIsVetDiet(order)
  const isFood = useIsFood(order)

  const openPrescriptionDialog = R.cond([
    [
      R.always(isDrug),
      () => navigateToPrescription(InventoryCategoryName.DRUG, order),
    ],
    [
      R.always(isVetDiet),
      () => navigateToPrescription(InventoryCategoryName.VET_DIET, order),
    ],
    [
      R.always(isFood),
      () => navigateToPrescription(InventoryCategoryName.FOOD, order),
    ],
    [R.T, () => {}],
  ])

  return (
    <ItemWithBadges
      actions={
        <PrescriptionItemActions order={order} readOnly={readOnly} {...rest} />
      }
      buttonSection={
        <PrescriptionItemButtonSection
          order={order}
          readOnly={readOnly}
          {...rest}
        />
      }
      order={order}
      onNameClick={readOnly ? undefined : openPrescriptionDialog}
    >
      <PrescriptionItemContent order={order} />
    </ItemWithBadges>
  )
}

export default PrescriptionItem
