import React, { useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, DateUtils, Text, Utils } from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import Link from '~/components/common/link/Link'
import { getAnswerInputTypes } from '~/store/reducers/constants'
import { QuestionTimelineItemEntry } from '~/types'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  () => ({
    root: {},
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      whiteSpace: 'pre-wrap',
    },
    container: {
      cursor: 'pointer',
    },
    dotdotdot: {
      width: '100%',
    },
  }),
  { name: 'OneQuestion' },
)

interface OneQuestionProps {
  classes?: ClassesType<typeof useStyles>
  enableBusinessShareIcon?: boolean
  question: QuestionTimelineItemEntry
  showAppointmentButton?: boolean
}

const OneQuestion = ({
  enableBusinessShareIcon,
  question,
  showAppointmentButton,
  classes: classesProp,
}: OneQuestionProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Clients'])

  const AnswerInputTypes = useSelector(getAnswerInputTypes)

  const isContextItem = useIsCurrentContextItem(question)

  const DateSelector = Utils.findConstantIdByName(
    'Date selector',
    AnswerInputTypes,
  )

  const [expanded, setExpanded] = useState(false)
  const [expandable, setExpandable] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const fieldRef = (text: HTMLSpanElement) => {
    if (text && text.offsetHeight < text.scrollHeight) {
      setExpandable(true)
    }
  }

  const isSingleAnswer = question.answerInputTexts.length === 1
  const answerText = question.answerText ? `${question.answerText}\n` : ''

  const answerInputTexts = question.answerInputTexts.reduce(
    (acc, obj, index) => {
      const newLine = index !== 0 ? '\n' : ''
      const isDateSelector = obj.inputTypeId === DateSelector
      const newValue = isDateSelector
        ? isSingleAnswer
          ? // when the answer is a single date, add `Date provided` in front of the answer
            t('Clients:TIMELINE.QUESTION_CARD.DATE_PROVIDED', {
              date: DateUtils.formatDate(obj.value),
            })
          : DateUtils.formatDate(obj.value)
        : obj.value

      return `${acc}${newLine}${newValue}`
    },
    '',
  )

  const text = `${answerText}${answerInputTexts}`

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.container]: expandable,
      })}
      direction="column"
      pb={1}
      onClick={expandable ? toggleExpanded : undefined}
    >
      <Grid container item>
        <Grid container item xs alignItems="center" wrap="nowrap">
          {expanded ? (
            <Text className={classes.text}>{text}</Text>
          ) : (
            <Dotdotdot clamp={2}>
              <Text className={classes.text} ref={fieldRef}>
                {text}
              </Text>
            </Dotdotdot>
          )}
          {enableBusinessShareIcon && (
            <BusinessShareIcon businessIds={[question.businessId]} />
          )}
        </Grid>
        {expandable && (
          <Grid item>{expanded ? <ExpandLess /> : <ExpandMore />}</Grid>
        )}
      </Grid>
      {isContextItem && showAppointmentButton && question.appointmentId && (
        <Grid container item justifyContent="flex-end" mt={2}>
          <Grid item>
            <Link to={`/scheduler/schedule/${question.appointmentId}`}>
              {t('Common:VIEW_APPOINTMENT')}
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default OneQuestion
