import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { StateLabel } from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'
import {
  getProblemCatalogProblemsMap,
  getRecursivelyChildrenProblemIds,
} from '~/store/reducers/problems'
import { Problem } from '~/types'

import ChipFindingsAndDiagnoses from './Chip'

const useStyles = makeStyles(
  (theme) => ({
    findingsLabel: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.colors.disabledLabelText,
      color: 'white',
      minWidth: 80,
    },
    diagnosesLabel: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.colors.important,
      color: theme.colors.actionNeededBackground,
      minWidth: 80,
    },
  }),
  { name: 'CommonFindingsAndDiagnosesComponent' },
)

interface CommonFindingsAndDiagnosesComponentProps {
  addLog: (problem: Problem) => void
  bodySystemId: string
}

const CommonFindingsAndDiagnosesComponent = ({
  bodySystemId,
  addLog,
}: CommonFindingsAndDiagnosesComponentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  const problemsMap = useSelector(getProblemCatalogProblemsMap)
  const allProblemIds = useSelector(
    getRecursivelyChildrenProblemIds(bodySystemId),
  )
  const bodySystem = problemsMap?.[bodySystemId]
  const commonProblems = allProblemIds.filter((id: string) => {
    const problem = problemsMap?.[id]
    if (
      problem?.bodySystemId === bodySystem?.bodySystemId &&
      problem?.chipOrder
    ) {
      return problem
    }
    return false
  })
  const sortedCommonDiagnoses = commonProblems
    .filter((id) => problemsMap?.[id].type === ProblemType.DIAGNOSIS)
    .map((id) => problemsMap?.[id])
    .sort((problem1, problem2) => problem1!.chipOrder! - problem2!.chipOrder!)
  const sortedCommonFindings = commonProblems
    .filter((id) => problemsMap?.[id].type === ProblemType.FINDING)
    .map((id) => problemsMap?.[id])
    .sort((problem1, problem2) => problem1!.chipOrder! - problem2!.chipOrder!)

  return (
    <Grid container direction="column" height="100%" px={2}>
      {sortedCommonFindings.length ? (
        <Grid
          container
          alignItems="start"
          direction="row"
          height="auto"
          pt={0.5}
        >
          <Grid item xs="auto">
            <StateLabel className={classes.findingsLabel}>
              {t('Common:FINDINGS_NOUN')}
            </StateLabel>
          </Grid>
          <Grid container item xs direction="row">
            {sortedCommonFindings.map(
              (chip) =>
                chip && (
                  <ChipFindingsAndDiagnoses
                    addLog={addLog}
                    key={chip.key}
                    problem={chip}
                  />
                ),
            )}
          </Grid>
        </Grid>
      ) : null}
      {sortedCommonDiagnoses.length ? (
        <Grid
          container
          alignItems="start"
          direction="row"
          height="auto"
          pb={0.5}
        >
          <Grid item xs="auto">
            <StateLabel className={classes.diagnosesLabel}>
              {t('Common:DIAGNOSES')}
            </StateLabel>
          </Grid>
          <Grid container item xs direction="row">
            {sortedCommonDiagnoses.map(
              (chip) =>
                chip && (
                  <ChipFindingsAndDiagnoses
                    addLog={addLog}
                    key={chip.id}
                    problem={chip}
                  />
                ),
            )}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default CommonFindingsAndDiagnosesComponent
