import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import { EventTypeName, Utils } from '@pbt/pbt-ui-components'

import SimplePatientCell from '~/components/common/lists/primitive-table/cells/patient/SimplePatientCell'
import { useGetTaskIsUnlinkedRxRequest } from '~/store/hooks/tasks'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { Task } from '~/types'
import { useEventType } from '~/utils/useEventType'

import RxRequestUnlinkedLabel from './rx-request-task/RxRequestUnlinkedLabel'

export interface TaskPatientCellProps {
  task: Task
}

const TaskPatientCell = ({ task }: TaskPatientCellProps) => {
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getTasksIsLoading)

  const TaskStates = useEventType(EventTypeName.Task, 'states')
  const isDone =
    task?.stateId === Utils.findConstantIdByName('Done', TaskStates)

  const isUnlinkedRxRequest = useGetTaskIsUnlinkedRxRequest()(task)

  if (isLoading) {
    return <Skeleton height={20} variant="text" width={70} />
  }

  if (isUnlinkedRxRequest) {
    if (isDone) {
      return <Dotdotdot clamp={1}>{t('Common:UNASSIGNED')}</Dotdotdot>
    }
    return <RxRequestUnlinkedLabel taskId={task.id} textVariant="body2" />
  }

  return <SimplePatientCell {...task} />
}

export default TaskPatientCell
