import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import {
  getProblemEnumCatalog,
  getProblemEnums,
} from '~/store/reducers/problems'
import { ProblemEnumReference, ProblemLogProblemContainer } from '~/types'
import { findEnumLog, getEnumValuePathLabel } from '~/utils/problems'

import IdentifiedProblemDetailsEnumValueNotes from './IdentifiedProblemDetailsEnumValueNotes'

interface IdentifiedProblemDetailsSelectedEnumValuesProps {
  parentEnumId?: string
  parentEnumValueId?: string
  problemEnumRefs: ProblemEnumReference[]
  problemLog: ProblemLogProblemContainer
}

const IdentifiedProblemDetailsSelectedEnumValues = ({
  problemEnumRefs,
  parentEnumId,
  parentEnumValueId,
  problemLog,
}: IdentifiedProblemDetailsSelectedEnumValuesProps) => {
  const enumCatalog = useSelector(getProblemEnumCatalog)
  const problemEnums = useSelector(
    getProblemEnums(problemEnumRefs, parentEnumId),
  )

  if (!problemEnums || problemEnums.length === 0) {
    return null
  }

  return (
    <>
      {problemEnums.map((problemEnum) => (
        <Fragment key={problemEnum.id}>
          {problemEnum.values.map((problemEnumValue) => {
            const enumLog = findEnumLog({
              problemLog,
              problemEnum,
              problemEnumValue,
              parentEnumValueId,
            })

            if (!enumLog) {
              return null
            }

            const parentEnum = parentEnumId
              ? enumCatalog?.[parentEnumId]
              : undefined
            const parentEnumValue = parentEnum?.values?.find(
              ({ id }) => id === parentEnumValueId,
            )

            const enumValuePathLabel = getEnumValuePathLabel(
              problemEnum,
              problemEnumValue,
              parentEnum,
              parentEnumValue,
            )

            return (
              <Fragment key={enumLog.entity.id}>
                <Grid mb={1}>
                  <Text variant="body2">{enumValuePathLabel}</Text>
                  <IdentifiedProblemDetailsEnumValueNotes enumLog={enumLog} />
                </Grid>
                <IdentifiedProblemDetailsSelectedEnumValues
                  parentEnumId={problemEnum.id}
                  parentEnumValueId={problemEnumValue.id}
                  problemEnumRefs={problemEnumRefs}
                  problemLog={problemLog}
                />
              </Fragment>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

export default IdentifiedProblemDetailsSelectedEnumValues
