import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BackButton,
  ButtonWithLoader,
  CircularProgressOverlay,
  Text,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import Stepper from '~/components/common/stepper/Stepper'
import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'
import i18n from '~/locales/i18n'
import {
  clearWellnessPlanVersionTemplate,
  fetchWellnessPlanVersion,
  fetchWellnessPlanVersionTemplate,
} from '~/store/actions/wellnessPlans'
import {
  useSaveWellnessPlanVersion,
  useWellnessPlanVersionActivationAlert,
} from '~/store/hooks/wellnessPlans'
import {
  getWellnessPlanGlobalBenefitGroups,
  getWellnessPlansIsSaving,
  getWellnessPlanVersion,
  getWellnessPlanVersionTemplate,
} from '~/store/reducers/wellnessPlans'

import WellnessPlanBasics from './steps/plan-basics/WellnessPlanBasics'
import WellnessPlanChargeSetUp from './steps/plan-customization/WellnessPlanChargeSetUp'
import WellnessPlanLevelBase from './steps/plan-customization/WellnessPlanLevelBase'
import WellnessPlanFinalReview from './steps/plan-review/WellnessPlanFinalReview'
import WellnessPlanIntermediateReview from './steps/plan-review/WellnessPlanIntermediateReview'
import WellnessPlanBranding from './steps/WellnessPlanBranding'
import { getPlanByLevel } from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: '100vw',
      [theme.breakpoints.up('md')]: {
        maxWidth: `calc(100vw - ${theme.constants.leftMenuWidth}px)`,
      },
      flex: 1,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.constants.progressBarHeight}px)`,
    },
    header: {
      padding: theme.spacing(2, 3, 1),
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    title: {
      marginBottom: theme.spacing(2),
      lineHeight: '2.4rem',
    },
    stepper: {
      padding: 0,
      backgroundColor: 'transparent',
    },
    content: {
      position: 'relative',
      overflowY: 'auto',
      flex: 1,
      padding: theme.spacing(2, 3),
    },
    footer: {
      padding: theme.spacing(2, 3),
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    button: {
      marginRight: theme.spacing(2),
      minWidth: 150,
    },
    overlay: {
      width: `calc(100% - ${theme.spacing(3)})`,
      height: `calc(100% - ${theme.spacing(2)})`,
    },
  }),
  { name: 'CreateWellnessPlanVersionPage' },
)

enum Steps {
  BRANDING = 'BRANDING',
  PLAN_BASICS = 'PLAN_BASICS',
  PLAN_REVIEW = 'PLAN_REVIEW',
  BASE = 'BASE',
  CHARGE_SET_UP = 'CHARGE_SET_UP',
  FINAL_REVIEW = 'FINAL_REVIEW',
}

// The steps order must leave the same
const StepsList = [
  Steps.BRANDING,
  Steps.PLAN_BASICS,
  Steps.PLAN_REVIEW,
  Steps.BASE,
  Steps.CHARGE_SET_UP,
  Steps.FINAL_REVIEW,
]

const LevelSteps = [Steps.BASE, Steps.CHARGE_SET_UP, Steps.FINAL_REVIEW]

const StepLabelsMap = {
  [Steps.BRANDING]: i18n.t('WellnessPlans:STEPS.BRANDING'),
  [Steps.PLAN_BASICS]: i18n.t('WellnessPlans:STEPS.PLAN_BASICS'),
  [Steps.PLAN_REVIEW]: i18n.t('WellnessPlans:STEPS.PLAN_REVIEW'),
  [Steps.BASE]: i18n.t('WellnessPlans:STEPS.BASE'),
  [Steps.CHARGE_SET_UP]: i18n.t('WellnessPlans:STEPS.CHARGE_SET_UP'),
  [Steps.FINAL_REVIEW]: i18n.t('WellnessPlans:STEPS.FINAL_REVIEW'),
}

interface CreateWellnessPlanVersionPageHandle extends HTMLDivElement {
  get: () => WellnessPlanVersion
  onBack: () => void
  onProceed: () => boolean
  validate: () => boolean
}

const CreateWellnessPlanVersionPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const { wellnessPlanVersionId } = useParams()
  const { t } = useTranslation('Common')

  const planToEdit = useSelector(getWellnessPlanVersion(wellnessPlanVersionId))
  const versionTemplate = useSelector(getWellnessPlanVersionTemplate)
  const isSaving = useSelector(getWellnessPlansIsSaving)
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)

  const isClone = /\/clone\//.test(location.pathname)
  const isEdit = Boolean(wellnessPlanVersionId) && !isClone

  const wellnessPlanVersionTemplate = isEdit ? planToEdit : versionTemplate

  const [activeStep, setActiveStep] = useState(Steps.BRANDING)
  const [wellnessPlanVersionCandidate, setWellnessPlanVersionCandidate] =
    useState(wellnessPlanVersionTemplate)
  const [validSteps, setValidSteps] = useState(
    wellnessPlanVersionId ? StepsList : [],
  )

  const saveWellnessPlanVersion = useSaveWellnessPlanVersion()

  const LevelStepsDisabledMap = {
    [Steps.BASE]: wellnessPlanVersionCandidate?.basePlanHidden,
    [Steps.BRANDING]: undefined,
    [Steps.CHARGE_SET_UP]: !wellnessPlanVersionCandidate?.plans?.some(
      (plan) => plan.level !== WellnessPlanLevels.BASE,
    ),
    [Steps.FINAL_REVIEW]: undefined,
    [Steps.PLAN_BASICS]: undefined,
    [Steps.PLAN_REVIEW]: undefined,
  }

  const LevelStepsInvalidMap = {
    [Steps.BASE]: !R.includes(Steps.BASE, validSteps),
    [Steps.BRANDING]: undefined,
    [Steps.CHARGE_SET_UP]: !R.includes(Steps.CHARGE_SET_UP, validSteps),
    [Steps.FINAL_REVIEW]: undefined,
    [Steps.PLAN_BASICS]: undefined,
    [Steps.PLAN_REVIEW]: undefined,
  }

  const getFirstInvalidChildStep = (step: Steps) => {
    const stepIndex = LevelSteps.indexOf(step)
    const previousSteps = LevelSteps.slice(0, stepIndex)
    return previousSteps.find(
      (item) => LevelStepsInvalidMap[item] && !LevelStepsDisabledMap[item],
    )
  }

  const getStepDisabledByValidation = (step: Steps) => {
    const stepIndex = LevelSteps.indexOf(step)
    const previousSteps = LevelSteps.slice(0, stepIndex)
    const hasPreviousDisabledByValidationSteps = previousSteps.some(
      getStepDisabledByValidation,
    )
    const hasPreviousInvalidSteps = Boolean(getFirstInvalidChildStep(step))

    return (
      !LevelStepsDisabledMap[step] &&
      (hasPreviousDisabledByValidationSteps || hasPreviousInvalidSteps)
    )
  }

  const getTooltipText = (step: Steps) => {
    if (LevelStepsDisabledMap[step]) {
      return t('Common:SOMETHING_IS_DISABLED', {
        something: StepLabelsMap[step],
      })
    }

    const firstInvalidStep = getFirstInvalidChildStep(step)

    return firstInvalidStep
      ? t('Common:PLEASE_COMPLETE_SOMETHING_SETUP', {
          something: StepLabelsMap[firstInvalidStep],
        })
      : undefined
  }

  const StepComponents = {
    [Steps.BRANDING]: {
      label: StepLabelsMap[Steps.BRANDING],
      disabled: false,
      component: WellnessPlanBranding,
    },
    [Steps.PLAN_BASICS]: {
      label: StepLabelsMap[Steps.PLAN_BASICS],
      disabled: false,
      component: WellnessPlanBasics,
    },
    [Steps.PLAN_REVIEW]: {
      label: StepLabelsMap[Steps.PLAN_REVIEW],
      disabled: false,
      component: WellnessPlanIntermediateReview,
    },
    [Steps.BASE]: {
      label: StepLabelsMap[Steps.BASE],
      disabled: LevelStepsDisabledMap[Steps.BASE],
      tooltipText: getTooltipText(Steps.BASE),
      component: WellnessPlanLevelBase,
    },
    [Steps.CHARGE_SET_UP]: {
      label: StepLabelsMap[Steps.CHARGE_SET_UP],
      disabled: LevelStepsDisabledMap[Steps.CHARGE_SET_UP],
      disabledByValidation: getStepDisabledByValidation(Steps.CHARGE_SET_UP),
      tooltipText: getTooltipText(Steps.CHARGE_SET_UP),
      component: WellnessPlanChargeSetUp,
    },
    [Steps.FINAL_REVIEW]: {
      label: StepLabelsMap[Steps.FINAL_REVIEW],
      disabled: false,
      disabledByValidation: getStepDisabledByValidation(Steps.FINAL_REVIEW),
      tooltipText: getTooltipText(Steps.FINAL_REVIEW),
      component: WellnessPlanFinalReview,
    },
  }

  const ActiveStepsList = StepsList.filter(
    (step) => !StepComponents[step].disabled,
  )

  const stepComponentRef = useRef<CreateWellnessPlanVersionPageHandle>(null)
  const StepComponent = StepComponents[activeStep].component

  const currentActiveStepIndex = ActiveStepsList.indexOf(activeStep)
  const currentStepIndex = StepsList.indexOf(activeStep)
  const hasPrevStep = currentStepIndex > 0
  const hasNextStep = currentStepIndex < StepsList.length - 1

  const { plans = [] } = wellnessPlanVersionCandidate || {}

  useEffect(() => {
    if (wellnessPlanVersionId && isClone && !versionTemplate) {
      dispatch(fetchWellnessPlanVersionTemplate(wellnessPlanVersionId))
    } else if (wellnessPlanVersionId && !planToEdit) {
      dispatch(fetchWellnessPlanVersion(wellnessPlanVersionId, true))
    } else {
      dispatch(fetchWellnessPlanVersionTemplate())
    }
  }, [wellnessPlanVersionId])

  useEffect(() => {
    if (wellnessPlanVersionTemplate) {
      setWellnessPlanVersionCandidate(wellnessPlanVersionTemplate)
    }
  }, [wellnessPlanVersionTemplate])

  useEffect(
    () => () => {
      dispatch(clearWellnessPlanVersionTemplate())
    },
    [],
  )

  const togglePlan = (wellnessPlan: WellnessPlan) => {
    const isBaseLevel = wellnessPlan.level === WellnessPlanLevels.BASE
    const wellnessPlanIndex = plans.indexOf(wellnessPlan)
    const planTemplate = getPlanByLevel(
      wellnessPlan.level,
      versionTemplate?.plans,
    )
    const isEnabled = isBaseLevel
      ? !wellnessPlanVersionCandidate?.basePlanHidden
      : Boolean(wellnessPlan)

    const updatedCandidate = {
      ...wellnessPlanVersionCandidate,
    }

    if (isBaseLevel) {
      // for base plan just set basePlanHidden prop on version
      updatedCandidate.basePlanHidden = isEnabled
      if (isEnabled) {
        // for base plan also reset name to default
        const updatedPlan = {
          ...wellnessPlan,
          name: planTemplate?.name || '',
        }
        updatedCandidate.plans = R.update(
          wellnessPlanIndex,
          updatedPlan,
          updatedCandidate.plans || [],
        )
      }
    } else if (isEnabled) {
      updatedCandidate.plans = R.without(
        [wellnessPlan],
        updatedCandidate.plans || [],
      )
    } else if (planTemplate) {
      const index = R.findLastIndex(
        (plan) => plan.level < wellnessPlan.level,
        updatedCandidate.plans || [],
      )
      updatedCandidate.plans = R.insert(
        index + 1,
        planTemplate,
        updatedCandidate.plans || [],
      )
    }

    if (wellnessPlanVersionCandidate?.id) {
      if (isEnabled) {
        updatedCandidate.deletedPlanIds = R.uniq(
          (wellnessPlanVersionCandidate.deletedPlanIds || []).concat(
            wellnessPlan.id,
          ),
        )
      } else if (wellnessPlan) {
        updatedCandidate.deletedPlanIds = R.without(
          [wellnessPlan.id],
          updatedCandidate.deletedPlanIds || [],
        )
      }
    }

    setWellnessPlanVersionCandidate(updatedCandidate as WellnessPlanVersion)
  }

  const onStepValidStateChange = (isValid: boolean) => {
    if (isValid) {
      setValidSteps(R.uniq(validSteps.concat(activeStep)))
    } else {
      setValidSteps(R.without([activeStep], validSteps))
    }
  }

  const validate = () => stepComponentRef.current?.validate?.() ?? true

  const tryUpdateVersion = () => {
    const updatedVersion = stepComponentRef.current?.get?.()
    if (updatedVersion) {
      setWellnessPlanVersionCandidate(updatedVersion)
    }
  }

  const goToPrevStep = () => {
    tryUpdateVersion()

    const canGoBack = stepComponentRef.current?.onBack?.() ?? true

    if (canGoBack) {
      setActiveStep(ActiveStepsList[currentActiveStepIndex - 1])
    }
  }

  const goToNextStep = () => {
    if (validate()) {
      const canProceed = stepComponentRef.current?.onProceed?.() ?? true

      tryUpdateVersion()

      if (canProceed) {
        setActiveStep(ActiveStepsList[currentActiveStepIndex + 1])
      }
    }
  }

  const onStepChange = (newStep: string) => {
    const newStepIndex = ActiveStepsList.indexOf(newStep as Steps)
    const shouldValidate = newStepIndex > currentActiveStepIndex

    if (!shouldValidate) {
      tryUpdateVersion()

      setActiveStep(newStep as Steps)
    } else if (validate()) {
      const canProceed = stepComponentRef.current?.onProceed?.() ?? true

      tryUpdateVersion()

      if (canProceed) {
        setActiveStep(newStep as Steps)
      }
    }
  }

  const onStepperLeftArrowClick = () => {
    const newStepIndex = Math.max(currentActiveStepIndex - 1, 0)
    const newStep = ActiveStepsList[newStepIndex]

    const canGoBack = stepComponentRef.current?.onBack?.() ?? true

    tryUpdateVersion()

    if (canGoBack) {
      setActiveStep(newStep)
    }
  }

  const onStepperRightArrowClick = () => {
    const newStepIndex = Math.min(
      currentActiveStepIndex + 1,
      ActiveStepsList.length - 1,
    )
    const newStep = ActiveStepsList[newStepIndex]

    if (validate()) {
      const canProceed = stepComponentRef.current?.onProceed?.() ?? true

      tryUpdateVersion()

      if (canProceed) {
        setActiveStep(newStep)
      }
    }
  }

  const savePlanVersion = (wellnessPlanVersion: WellnessPlanVersion) => {
    if (validate()) {
      saveWellnessPlanVersion(wellnessPlanVersion)
    }
  }

  const activatePlan = () => {
    savePlanVersion({
      ...wellnessPlanVersionCandidate,
      active: true,
    } as WellnessPlanVersion)
  }

  const savePlanAsDraft = () => {
    savePlanVersion({
      ...wellnessPlanVersionCandidate,
      active: false,
    } as WellnessPlanVersion)
  }

  const onForceSaveRequested = (currentActiveId: string) => {
    savePlanVersion({
      ...wellnessPlanVersionCandidate,
      active: true,
      currentActiveId,
    } as WellnessPlanVersion)
  }

  useWellnessPlanVersionActivationAlert(
    wellnessPlanVersionCandidate,
    onForceSaveRequested,
  )

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      <Grid container item className={classes.header} direction="column">
        <Text className={classes.title} variant="h1">
          {t('Common:WELLNESS_PLANS')}
        </Text>
        <Stepper
          activeStep={activeStep}
          classes={{
            stepper: classes.stepper,
          }}
          components={StepComponents}
          id="wellness-plan-wizard-stepper"
          paddingMultiplier={0}
          steps={StepsList}
          onLeftArrowClick={onStepperLeftArrowClick}
          onRightArrowClick={onStepperRightArrowClick}
          onStepChange={onStepChange}
        />
      </Grid>
      <Grid
        container
        item
        alignContent="flex-start"
        alignItems="flex-start"
        className={classes.content}
      >
        {wellnessPlanVersionCandidate && globalBenefitGroups.length > 0 ? (
          <StepComponent
            isClone={isClone}
            isEdit={isEdit}
            ref={stepComponentRef}
            setWellnessPlanVersion={setWellnessPlanVersionCandidate}
            togglePlan={togglePlan}
            wellnessPlanVersion={wellnessPlanVersionCandidate}
            wellnessPlanVersionTemplate={wellnessPlanVersionTemplate}
            onValidStateChange={onStepValidStateChange}
          />
        ) : (
          <CircularProgressOverlay
            open
            transparent
            className={classes.overlay}
            preloaderText={t('Common:LOADING')}
          />
        )}
      </Grid>
      <Grid container item alignItems="center" className={classes.footer}>
        {hasPrevStep && (
          <Grid item>
            <BackButton
              label={t('Common:BACK_ACTION')}
              onClick={goToPrevStep}
            />
          </Grid>
        )}
        {hasNextStep ? (
          <ButtonWithLoader className={classes.button} onClick={goToNextStep}>
            {t('Common:NEXT_ACTION')}
          </ButtonWithLoader>
        ) : (
          <>
            <ButtonWithLoader
              className={classes.button}
              loading={isSaving}
              onClick={activatePlan}
            >
              {t('Common:ACTIVATE_ACTION')}
            </ButtonWithLoader>
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              loading={isSaving}
              onClick={savePlanAsDraft}
            >
              {t('Common:SAVE_AS_A_DRAFT')}
            </ButtonWithLoader>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default CreateWellnessPlanVersionPage
