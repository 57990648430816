import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { AlertLabelColorVariant } from '~/constants/alertColors'
import i18n from '~/locales/i18n'
import useTimetableDate from '~/utils/useTimetableDate'

import { getTimeRelationToNow, When } from './TimetableUtilsNew'

interface TimetableTagProps {
  selectedDate?: string
}

const useStyles = makeStyles(() => ({
  tag: {
    flex: 'none',
  },
}))

export const TIMES = {
  [When.PAST]: i18n.t('Time:LABEL.PAST'),
  [When.TODAY]: i18n.t('Time:LABEL.TODAY'),
  [When.TOMORROW]: i18n.t('Time:LABEL.TOMORROW'),
  [When.NEXT_WEEK]: i18n.t('Time:LABEL.NEXT_WEEK'),
  [When.THIS_WEEK]: i18n.t('Time:LABEL.THIS_WEEK'),
  [When.NEXT_MONTH]: i18n.t('Time:LABEL.NEXT_MONTH'),
  [When.THIS_MONTH]: i18n.t('Time:LABEL.THIS_MONTH'),
  [When.THIS_YEAR]: i18n.t('Time:LABEL.THIS_YEAR'),
  [When.FUTURE]: i18n.t('Time:LABEL.FUTURE'),
}

const TimetableTag = ({
  selectedDate: selectedDateProp,
}: TimetableTagProps) => {
  const classes = useStyles()

  const [variant, setVariant] = useState(AlertLabelColorVariant.RELAXED)

  const { selectedDate: selectedTimetableDate } = useTimetableDate()
  const selectedDate = selectedDateProp || selectedTimetableDate

  const time: When = getTimeRelationToNow(selectedDate)

  useEffect(() => {
    const variantByTime = R.cond([
      [R.equals(When.PAST), R.always(AlertLabelColorVariant.ATTENTION_RED)],
      [R.equals(When.TODAY), R.always(AlertLabelColorVariant.ACTIVE)],
      [R.T, R.always(AlertLabelColorVariant.RELAXED)],
    ])(time)

    setVariant(variantByTime)
  }, [time])

  if (!selectedDate) {
    return <></>
  }

  return (
    <AlertLabel
      classes={{ root: classes.tag }}
      message={TIMES[time]}
      variant={variant}
    />
  )
}

export default TimetableTag
