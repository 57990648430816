import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'
import { Note, WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { MessageStatus, MessageType } from '~/constants/communications'
import {
  deleteMessage,
  editMessage,
  updateMessage,
} from '~/store/actions/conversationMessages'
import { openIntercom } from '~/store/duck/intercom'
import { getFilesForMessageIds } from '~/store/reducers/conversationMessages'
import { ChatMessage } from '~/types'

import MessageAttachments from '../MessageAttachments'
import MessageCardMini, { MessageCardMiniProps } from './MessageCardMini'
import MessageCardPrimary, {
  MessageCardPrimaryProps,
} from './MessageCardPrimary'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 635,
    },
    rootMini: {
      maxWidth: 270,
    },
    card: {
      borderRadius: 2,
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    internalNoteCard: {
      border: theme.constants.tableBorderSelected,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    importantCard: {
      border: theme.constants.importantBorder,
    },
    inProgress: {
      border: theme.constants.tableBorderSelected,
      opacity: 0.5,
    },
    listShadow: {
      boxShadow: theme.constants.listItemShadow,
    },
    noteIcon: {
      color: theme.colors.title,
    },
    alertIcon: {
      color: theme.colors.important,
      paddingRight: theme.spacing(1),
    },
    actionLink: {
      color: theme.colors.important,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'MessageCard' },
)

export enum MessageVariant {
  PRIMARY = 'PRIMARY',
  MINI = 'MINI',
}

export interface MessageCardProps
  extends MessageCardPrimaryProps,
    MessageCardMiniProps {
  hideStatusIcons?: boolean
  isCurrentUserMessage?: boolean
  isPlainTextTransport?: boolean
  message: ChatMessage
  readOnly?: boolean
  variant?: MessageVariant
}

const MessageCard = ({
  message,
  variant = MessageVariant.PRIMARY,
  isCurrentUserMessage,
  isPlainTextTransport = false,
  hideStatusIcons = false,
  readOnly,
  ...rest
}: MessageCardProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Communications'])

  const { id: messageId, type, status, attachments } = message || {}
  const files = useSelector(getFilesForMessageIds([messageId]))
  const isInternalNoteType = type === MessageType.INTERNAL_NOTE
  const isPlainTextMessage = isPlainTextTransport && !isInternalNoteType

  const isMiniMode = variant === MessageVariant.MINI
  const isNotDelivered = status === MessageStatus.FAILED
  const hasFormingFailed = status === MessageStatus.FORMING_FAILED
  const hasFailed = isNotDelivered || hasFormingFailed
  const isDraft = status === MessageStatus.DRAFT
  const isInProgress = !isInternalNoteType && status === MessageStatus.SENT
  const isImportant = hasFailed || isDraft
  const isContainsAttachments = Boolean(attachments?.length)
  const showStatusIcons = !isMiniMode && !hideStatusIcons

  const onSend = () =>
    dispatch(
      updateMessage({
        messageId,
        status: MessageStatus.SENT,
        files,
      }),
    )
  const onEdit = () => dispatch(editMessage(messageId))
  const onDelete = () => dispatch(deleteMessage(messageId))

  const onContactSupport = () => dispatch(openIntercom())

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.rootMini]: isMiniMode,
      })}
    >
      {showStatusIcons && hasFailed && (
        <Grid item>
          <WarnAlert className={classes.alertIcon} />
        </Grid>
      )}
      {showStatusIcons && isInternalNoteType && (
        <Grid item>
          <Note className={classes.noteIcon} />
        </Grid>
      )}
      <Grid container item xs direction="column">
        <Grid
          container
          item
          className={classNames(classes.card, classes.listShadow, {
            [classes.importantCard]: isImportant,
            [classes.inProgress]: isInProgress,
            [classes.internalNoteCard]: isInternalNoteType,
          })}
        >
          {isMiniMode ? (
            <MessageCardMini
              isPlainTextMessage={isPlainTextMessage}
              message={message}
              {...rest}
            />
          ) : (
            <MessageCardPrimary
              isPlainTextMessage={isPlainTextMessage}
              message={message}
              {...rest}
            />
          )}
        </Grid>
        {isNotDelivered && !readOnly && (
          <Grid item pt={0.5}>
            <Text align="right" variant="body2">
              {t('Communications:MESSAGE_CARD.UNABLE_TO_DELIVER_MESSAGE')}
              <Link className={classes.actionLink} to="#" onClick={onSend}>
                {t('Common:TRY_AGAIN')}
              </Link>
            </Text>
          </Grid>
        )}
        {hasFormingFailed && (
          <Grid item pt={0.5}>
            <Text align="right" variant="body2">
              {t('Communications:MESSAGE_CARD.UNABLE_TO_FORM_MESSAGE')}
              <Link
                className={classes.actionLink}
                to="#"
                onClick={onContactSupport}
              >
                {t('Common:CONTACT_SUPPORT')}
              </Link>
            </Text>
          </Grid>
        )}
        {isDraft && !readOnly && (
          <Grid item pt={0.5}>
            <Text align="right" variant="body2">
              {t('Communications:MESSAGE_CARD.DRAFT_MESSAGE')}
              <Link className={classes.actionLink} to="#" onClick={onSend}>
                {t('Common:SEND_ACTION')}
              </Link>
              <Link className={classes.actionLink} to="#" onClick={onEdit}>
                {t('Common:EDIT_ACTION')}
              </Link>
              <Link className={classes.actionLink} to="#" onClick={onDelete}>
                {t('Common:DELETE_ACTION')}
              </Link>
            </Text>
          </Grid>
        )}
        {isInProgress && (
          <Grid item pt={0.5}>
            <Text align="right" variant="h5">
              {t('Common:SENDING')}
            </Text>
          </Grid>
        )}
        {isContainsAttachments && (
          <MessageAttachments
            attachments={attachments}
            isCurrentUserMessage={isCurrentUserMessage}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default MessageCard
