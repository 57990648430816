import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  ClassesType,
  PermissionArea,
} from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getMemberIsLoading } from '~/store/reducers/members'

import { PrescriptionActionExpandedContext } from '../../PrescriptionContext'
import PrescriptionDeclineAction from './PrescriptionDeclineAction'

const useStyles = makeStyles(
  () => ({
    button: {},
    saveButton: {},
    declineButton: {},
  }),
  { name: 'PrescriptionChewyReactiveRxActions' },
)

export interface PrescriptionChewyReactiveRxActionsProps {
  classes: ClassesType<typeof useStyles>
}

export const PrescriptionChewyReactiveRxActions = ({
  classes: classesProp,
}: PrescriptionChewyReactiveRxActionsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const memberIsLoading = useSelector(getMemberIsLoading)
  const {
    disableDrafts,
    automaticallyCreateTaskField,
    canApproveAndSubmitChewyRx,
    isChewyCatalogItemNotLinked,
    isDvmLicenseRequired,
    isLoadingOrUpdating,
    isSending,
    needsDvmLicenseEntry,
    needsDvmLicenseEntryTooltip,
    onApprove,
    onDecline,
    onSaveDraft,
  } = useContext(PrescriptionActionExpandedContext)

  const isLoading = isSending || (memberIsLoading && isDvmLicenseRequired)

  return (
    <Grid display="flex" gap={2}>
      <Tooltip
        placement="top"
        title={
          (isChewyCatalogItemNotLinked
            ? t('Tooltips:PRESCRIPTION_MATCHING_ACTION_TOOLTIP')
            : null) || needsDvmLicenseEntryTooltip
        }
      >
        <span>
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={
              !canApproveAndSubmitChewyRx ||
              isLoadingOrUpdating ||
              needsDvmLicenseEntry ||
              isChewyCatalogItemNotLinked
            }
            key="reactiveRxApprove"
            loading={isLoading}
            onClick={onApprove}
          >
            {t('Common:APPROVE_ACTION')}
          </ButtonWithLoader>
        </span>
      </Tooltip>

      {onDecline && (
        <PrescriptionDeclineAction
          className={classNames(classes.button, classes.declineButton)}
          disabled={
            !permissions.update || isLoadingOrUpdating || needsDvmLicenseEntry
          }
          key="reactiveRxDecline"
          loading={isLoading}
          onDecline={onDecline}
        >
          {t('Common:DECLINE_ACTION')}
        </PrescriptionDeclineAction>
      )}
      <Tooltip
        title={
          disableDrafts && automaticallyCreateTaskField.value
            ? t('Tooltips:PRESCRIPTION_DISABLED_DRAFTS_TOOLTIP')
            : ''
        }
      >
        <span>
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={
              !permissions.update ||
              (disableDrafts && automaticallyCreateTaskField.value) ||
              isLoadingOrUpdating
            }
            key="reactiveRxSaveDraft"
            loading={isSending}
            onClick={onSaveDraft}
          >
            {t('Common:SAVE_DRAFT_ACTION')}
          </ButtonWithLoader>
        </span>
      </Tooltip>
    </Grid>
  )
}
