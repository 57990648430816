import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Link, Skeleton, Stack } from '@mui/material'
import {
  AlertIconType,
  PermissionArea,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import {
  BusinessSettingsSections,
  getSectionFields,
} from '~/store/duck/businessSettings'
import {
  getSchedulerSettingsIsFetching,
  getSchedulerSettingsIsUpdating,
  updateSchedulerSettings,
} from '~/store/duck/schedulerSettings'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { PracticeAppointmentConfigurationFields } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { useGetSchedulerSettingsByBusiness } from '../../../../../timetable/scheduler/appointment/appointmentUtils'
import { PracticeDetailsPanels } from '../../practices'

export interface OccupyAllStaffTimeSlotSwitchProps {
  businessId: string
}

const OccupyAllStaffTimeSlotSwitch = ({
  businessId,
}: OccupyAllStaffTimeSlotSwitchProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const business = useSelector(getBusiness(businessId))
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const isFetching = useSelector(getSchedulerSettingsIsFetching)
  const isUpdating = useSelector(getSchedulerSettingsIsUpdating)
  const appointmentConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['APPOINTMENT_CONFIGURATION']['fields']
    >(businessId, PracticeDetailsPanels.APPOINTMENT_CONFIGURATION),
  )

  const schedulerSettings = useGetSchedulerSettingsByBusiness(businessId)
  const [openWarningAlert, closeWarningAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )
  const setCloseAfterUpdating = useCloseAfterCreation(
    closeWarningAlert,
    getSchedulerSettingsIsUpdating,
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'occupyAllStaffTimeslots',
        type: 'toggle',
        initialValue: schedulerSettings?.occupyAllStaffTimeslots ?? true,
      },
    ],
    false,
  )

  const { occupyAllStaffTimeslots } = fields
  const isLoading = isFetching || isUpdating

  const handleChange = () => {
    openWarningAlert({
      iconType: AlertIconType.WARN,
      message: t('Businesses:APPOINTMENT_CONFIGURATION.ALERT_MESSAGE'),
      okButtonText: t('Common:YES_CONTINUE'),
      cancelButtonText: t('Common:CANCEL_ACTION'),
      onCancel: closeWarningAlert,
      onClose: closeWarningAlert,
      onOk: () => {
        const newValue = !occupyAllStaffTimeslots.value

        occupyAllStaffTimeslots.setValue(newValue)
        setCloseAfterUpdating()
      },
    })
  }

  const onSave = () => {
    dispatch(
      updateSchedulerSettings(businessId, {
        occupyAllStaffTimeslots: occupyAllStaffTimeslots.value,
      }),
    )
  }

  usePracticeFieldsSection<PracticeAppointmentConfigurationFields>({
    onSave,
    validate,
    parsedFields: {
      ...appointmentConfigurationFields,
      occupyAllStaffTimeslots: occupyAllStaffTimeslots.value,
    },
    business,
    fields,
    sectionName: PracticeDetailsPanels.APPOINTMENT_CONFIGURATION,
    reset,
    resetDependencies: [schedulerSettings?.occupyAllStaffTimeslots],
  })

  return (
    <Box>
      <Text strong variant="subheading3">
        {t('Businesses:APPOINTMENT_CONFIGURATION.DISPLAY_ON_SCHEDULER')}
      </Text>
      {isLoading ? (
        <Stack direction="row" mt={1} spacing={1}>
          <Skeleton height={24} variant="rectangular" width={38} />
          <Skeleton height={24} variant="rectangular" width={200} />
        </Stack>
      ) : (
        <PuiSwitch
          checked={occupyAllStaffTimeslots.value}
          disabled={!permissions.update}
          label={
            <TextWithTooltip
              allowWrap
              IconProps={{
                'data-testId': 'appointment-configuration-tooltip-icon',
              }}
              tooltipText={
                <Text variant="body2">
                  <Trans
                    components={{
                      boldText: (
                        <Text inline strong component="span" variant="body2" />
                      ),
                    }}
                    i18nKey={t(
                      'Businesses:APPOINTMENT_CONFIGURATION.TOGGLE_TOOLTIP',
                    )}
                  />
                  <br />
                  <Link
                    aria-label={t('PageTitles:FAQ_SCHEDULER_SETTINGS')}
                    href="https://help.rhapsody.vet/en/articles/6990311-how-do-i-associate-an-appointment-with-a-single-provider-on-the-scheduler"
                    rel="help noopener"
                    target="_blank"
                    underline="always"
                  >
                    {t('Common:FAQ_LINK_LEARN_MORE')}
                  </Link>
                </Text>
              }
            >
              {t('Businesses:APPOINTMENT_CONFIGURATION.TOGGLE_TEXT')}
            </TextWithTooltip>
          }
          onChange={handleChange}
        />
      )}
    </Box>
  )
}

export default OccupyAllStaffTimeSlotSwitch
