import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, Text } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import PaymentProcessors from '~/constants/PaymentProcessors'
import {
  getRhapsodyPayConfig,
  updateRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import { getFeatureToggle } from '~/store/reducers/constants'
import { RhapsodyPayConfig } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    iframe: {
      border: 'none',
      height: '100%',
      width: '100%',
    },
    button: {
      width: 240,
      height: 40,
      margin: theme.spacing(1, 0),
    },
    text: {
      lineHeight: '2.2rem',
    },
  }),
  { name: 'RhapsodyPayPayment' },
)

interface RhapsodyPayPaymentProps {
  businessId: string
  firstAmericanUrl: string
  posPayConfig: RhapsodyPayConfig
}

const RhapsodyPayPayment = ({
  firstAmericanUrl,
  posPayConfig,
  businessId,
}: RhapsodyPayPaymentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const isStripeFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RHAPSODY_GO_STRIPE_INTEGRATION),
  )
  const rhapsodyPayConfig = useSelector(getRhapsodyPayConfig(businessId))

  const createProcessorSelectedHandler =
    (processor: string, url: string) => () => {
      dispatch(
        updateRhapsodyPayConfig({
          ...posPayConfig,
          goProcessor: processor,
        }),
      )
      window.open(url, '_blank')
    }

  const onStripeSelected = () => {
    dispatch(
      updateRhapsodyPayConfig({
        ...posPayConfig,
        goProcessor: PaymentProcessors.STRIPE,
      }),
    )
  }

  useEffect(() => {
    const url = rhapsodyPayConfig?.stripePreferences?.onboardURL
    if (url) {
      window.open(url, '_blank')
    }
  }, [rhapsodyPayConfig?.stripePreferences?.onboardURL])

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      direction="column"
      pb={1}
      px={3}
    >
      <Grid container item alignItems="center" direction="column">
        <Text align="center" className={classes.text}>
          {t(
            'Businesses:RHAPSODY_PAY.WE_WILL_OPEN_NEW_TAB_WITH_YOUR_APPLICATION',
          )}
          &nbsp;
        </Text>
        <Link href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
          {Defaults.SUPPORT_EMAIL}
        </Link>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        justifyContent="center"
        mb={2}
        mt={3}
      >
        <Grid item>
          <Fab
            className={classes.button}
            color="inherit"
            variant="extended"
            onClick={createProcessorSelectedHandler(
              PaymentProcessors.FIRST_AMERICAN,
              firstAmericanUrl,
            )}
          >
            {isStripeFeatureEnabled
              ? t('Businesses:RHAPSODY_PAY.GO_TO_APPLICATION')
              : t('Businesses:RHAPSODY_PAY.FIRST_AMERICAN')}
          </Fab>
        </Grid>
        {isStripeFeatureEnabled && (
          <Grid item>
            <Fab
              className={classes.button}
              color="inherit"
              variant="extended"
              onClick={onStripeSelected}
            >
              {t('Businesses:RHAPSODY_PAY.STRIPE_APPLICATION')}
            </Fab>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default RhapsodyPayPayment
