import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { prop, uniqBy } from 'ramda'
import { BasePuiDialogProps, PuiDialog, Utils } from '@pbt/pbt-ui-components'

import LabVendorNames from '~/constants/labVendorName'
import {
  fetchLabVendorProviders,
  getLabVendorProviders,
  getLabVendorProvidersIsLoading,
} from '~/store/duck/labOrders'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    root: {
      backgroundColor: theme.colors.contentBackground,
    },
    providersContainer: {
      border: theme.constants.tableBorder,
      height: 320,
      overflow: 'auto',
    },
    spinner: {
      margin: 'auto',
    },
    button: {
      height: 40,
      width: 150,
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'LabVendorProvidersDialog' },
)

interface LabVendorProvidersDialogProps extends BasePuiDialogProps {
  onOk: (providerId?: string) => void
}

const LabVendorProvidersDialog = ({
  open,
  onClose,
  onOk,
}: LabVendorProvidersDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const providers = useSelector(getLabVendorProviders)
  const uniqProviders = uniqBy(prop('providerIdentifier'), providers).filter(
    ({ firstName, lastName }) => firstName || lastName,
  )

  const providersIsLoading = useSelector(getLabVendorProvidersIsLoading)

  useEffect(() => {
    dispatch(fetchLabVendorProviders())
  }, [])

  const [selectedProviderIdentifier, setSelectedProviderIdentifier] =
    useState<string>()

  const handleSelect = () => {
    onOk(selectedProviderIdentifier)
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="lab-vendor-providers-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={LabVendorNames.ZN_LABS}
      onClose={onClose}
    >
      <Grid container className={classes.root} p={3}>
        <Grid container className={classes.providersContainer} p={2}>
          {providersIsLoading ? (
            <CircularProgress className={classes.spinner} size={32} />
          ) : uniqProviders?.length ? (
            <RadioGroup
              name="providers"
              value={selectedProviderIdentifier || ''}
              onChange={(_, value) => setSelectedProviderIdentifier(value)}
            >
              {uniqProviders.map(
                ({ providerIdentifier: id, firstName, lastName }) => (
                  <FormControlLabel
                    control={<Radio value={id} />}
                    key={id}
                    label={Utils.getPersonString({ firstName, lastName })}
                  />
                ),
              )}
            </RadioGroup>
          ) : (
            t('Soap:IN_CLINIC_THERE_ARE_NO_VETERINARIANS_REGISTERED_IN_ZN_LABS')
          )}
        </Grid>
        <Fab
          className={classes.button}
          color="inherit"
          disabled={Boolean(!selectedProviderIdentifier)}
          variant="extended"
          onClick={handleSelect}
        >
          {t('Common:SELECT_ACTION')}
        </Fab>
      </Grid>
    </PuiDialog>
  )
}

export default LabVendorProvidersDialog
