import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import ClientsNoSearchResults from './ClientsNoSearchResults'

export interface NoSearchResultsProps {
  clientOnly?: boolean
  onSuggestionClick?: (fieldsString: string) => void
  query: string
  suggestionsEnabled?: boolean
}

const NoSearchResults = ({
  query,
  suggestionsEnabled,
  onSuggestionClick,
  clientOnly,
}: NoSearchResultsProps) => {
  const { t } = useTranslation('Search')

  return (
    <>
      <Text px={2} variant="body">
        {t('Search:NO_SEARCH_RESULTS')} ‘
        <Text strong component="span">
          {query}
        </Text>
        ‘
      </Text>
      {suggestionsEnabled && onSuggestionClick && (
        <ClientsNoSearchResults
          clientOnly={clientOnly}
          query={query}
          onSuggestionClick={onSuggestionClick}
        />
      )}
    </>
  )
}

export default NoSearchResults
