import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import {
  SupportedAnalyticsDashboard,
  supportedAnalyticsDashboards,
} from '~/constants/analytics'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getAnalyticsMenuItem } from '~/utils/analytics'

import { MenuItem } from '../LeftMenu'

export const generalAnalyticsDashboardMapping = {
  [SupportedAnalyticsDashboard.BILLING_AGING]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.BILLING_AGING.V1,
    i18n.t('Menu:ANALYTICS.ACCOUNTING'),
  ),
  [SupportedAnalyticsDashboard.ACQUISITION_AND_LIFETIME_VALUE]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.ACQUISITION_AND_LIFETIME_VALUE.V1,
      i18n.t('Menu:ANALYTICS.ACQUISITION_AND_LIFETIME_VALUE'),
    ),
  [SupportedAnalyticsDashboard.APPOINTMENT_MIX_BENCHMARK]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.APPOINTMENT_MIX_BENCHMARK.V1,
    i18n.t('Menu:ANALYTICS.APPOINTMENT_MIX_BENCHMARK'),
  ),
  [SupportedAnalyticsDashboard.BANK_RECONCILIATION]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.BANK_RECONCILIATION.V1,
    i18n.t('Menu:ANALYTICS.BANK_RECONCILIATION'),
  ),
  [SupportedAnalyticsDashboard.BUNDLES_AND_WELLNESS_PLANS]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.BUNDLES_AND_WELLNESS_PLANS.V2,
      i18n.t('Menu:ANALYTICS.BUNDLES_AND_WELLNESS_PLANS'),
    ),
  [SupportedAnalyticsDashboard.CANCELLATIONS_AND_NO_SHOWS]: {
    [supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V1.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V1,
        i18n.t('Menu:ANALYTICS.CANCELLATIONS_AND_NO_SHOWS'),
      ),
    [supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V2.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V2,
        i18n.t('Menu:ANALYTICS.CANCELLATIONS_AND_NO_SHOWS'),
      ),
  },
  [SupportedAnalyticsDashboard.CLIENT_AND_PATIENT_STATUS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CLIENT_AND_PATIENT_STATUS.V1,
    i18n.t('Menu:ANALYTICS.CLIENT_AND_PATIENT_STATUS'),
  ),
  [SupportedAnalyticsDashboard.CLIENT_INITIATED_REQUESTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CLIENT_INITIATED_REQUESTS.V1,
    i18n.t('Menu:ANALYTICS.CLIENT_INITIATED_REQUESTS'),
  ),
  [SupportedAnalyticsDashboard.CLIENT_LIST]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CLIENT_LIST.V2,
    i18n.t('Menu:ANALYTICS.CLIENT_LIST'),
  ),
  [SupportedAnalyticsDashboard.CONTROLLED_SUBSTANCE_LOG]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.CONTROLLED_SUBSTANCE_LOG.V1,
    i18n.t('Menu:ANALYTICS.CONTROLLED_SUBSTANCE_LOG'),
  ),
  [SupportedAnalyticsDashboard.DAILY_RECONCILIATION]: {
    [supportedAnalyticsDashboards.DAILY_RECONCILIATION.V1.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.DAILY_RECONCILIATION.V1,
        i18n.t('Menu:ANALYTICS.DAILY_RECONCILIATION_OLD'),
      ),
    [supportedAnalyticsDashboards.DAILY_RECONCILIATION.V2.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.DAILY_RECONCILIATION.V2,
        i18n.t('Menu:ANALYTICS.DAILY_RECONCILIATION'),
      ),
    [supportedAnalyticsDashboards.DAILY_RECONCILIATION.V3.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.DAILY_RECONCILIATION.V3,
        i18n.t('Menu:ANALYTICS.INVOICES AND PAYMENTS'),
      ),
  },
  [SupportedAnalyticsDashboard.DENTAL_AND_HEARTWORM_OVERVIEW]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.DENTAL_AND_HEARTWORM_OVERVIEW.V1,
      i18n.t('Menu:ANALYTICS.DENTAL_AND_HEARTWORM_OVERVIEW'),
    ),
  [SupportedAnalyticsDashboard.DISCOUNT_USAGE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.DISCOUNT_USAGE.V1,
    i18n.t('Menu:ANALYTICS.DISCOUNT_USAGE'),
  ),
  [SupportedAnalyticsDashboard.FORWARD_BOOKINGS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.FORWARD_BOOKINGS.V1,
    i18n.t('Menu:ANALYTICS.FORWARD_BOOKINGS'),
  ),
  [SupportedAnalyticsDashboard.GROUP_PERFORMANCE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.GROUP_PERFORMANCE.V1,
    i18n.t('Menu:ANALYTICS.GROUP_PERFORMANCE'),
  ),
  [SupportedAnalyticsDashboard.GROUP_PRICE_LISTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.GROUP_PRICE_LISTS.V1,
    i18n.t('Menu:ANALYTICS.GROUP_PRICE_LISTS'),
  ),
  [SupportedAnalyticsDashboard.INSURED_PATIENTS_VISITS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.INSURED_PATIENTS_VISITS.V1,
    i18n.t('Menu:ANALYTICS.INSURED_PATIENTS_VISITS'),
  ),
  [SupportedAnalyticsDashboard.INVENTORY_USAGE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.INVENTORY_USAGE.V1,
    i18n.t('Menu:ANALYTICS.INVENTORY_USAGE'),
  ),
  [SupportedAnalyticsDashboard.INVENTORY_USAGE_AND_AVERAGE_INVOICE]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.INVENTORY_USAGE_AND_AVERAGE_INVOICE.V1,
      i18n.t('Menu:ANALYTICS.INVENTORY_USAGE_AND_AVERAGE_INVOICE'),
    ),
  [SupportedAnalyticsDashboard.INVOICE_ACTIVITY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.INVOICE_ACTIVITY.V3,
    i18n.t('Menu:ANALYTICS.INVOICE_ACTIVITY'),
  ),
  [SupportedAnalyticsDashboard.MARKETING_DATA_QUALITY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.MARKETING_DATA_QUALITY.V1,
    i18n.t('Menu:ANALYTICS.MARKETING_DATA_QUALITY'),
  ),
  [SupportedAnalyticsDashboard.MEDICAL_INSIGHTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.MEDICAL_INSIGHTS.V1,
    i18n.t('Menu:ANALYTICS.MEDICAL_INSIGHTS'),
  ),
  [SupportedAnalyticsDashboard.MONTHLY_SALES_SUMMARY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.MONTHLY_SALES_SUMMARY.V1,
    i18n.t('Menu:ANALYTICS.MONTHLY_SALES_SUMMARY'),
  ),
  [SupportedAnalyticsDashboard.NEW_CLIENT_AND_PATIENT_ACTIVITY]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.NEW_CLIENT_AND_PATIENT_ACTIVITY.V1,
      i18n.t('Menu:ANALYTICS.NEW_CLIENT_AND_PATIENT_ACTIVITY'),
    ),
  [SupportedAnalyticsDashboard.ONLINE_SICK_APPOINTMENTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.ONLINE_SICK_APPOINTMENTS.V1,
    i18n.t('Menu:ANALYTICS.ONLINE_SICK_APPOINTMENTS'),
  ),
  [SupportedAnalyticsDashboard.OPEN_SOAPS_AND_INVOICES]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.OPEN_SOAPS_AND_INVOICES.V2,
    i18n.t('Menu:ANALYTICS.OPEN_SOAPS_AND_INVOICES'),
  ),
  [SupportedAnalyticsDashboard.PRACTICE_PERFORMANCE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRACTICE_PERFORMANCE.V1,
    i18n.t('Menu:ANALYTICS.PRACTICE_PERFORMANCE'),
  ),
  [SupportedAnalyticsDashboard.PRACTICE_SALES]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRACTICE_SALES.V1,
    i18n.t('Menu:ANALYTICS.PRACTICE_SALES'),
  ),
  [SupportedAnalyticsDashboard.PRODUCER_SALES]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRODUCER_SALES.V2,
    i18n.t('Menu:ANALYTICS.PRODUCER_SALES'),
  ),
  [SupportedAnalyticsDashboard.PRODUCTION]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.PRODUCTION.V1,
    i18n.t('Menu:ANALYTICS.PRODUCTION'),
  ),
  [SupportedAnalyticsDashboard.RABIES_VACCINE_HISTORY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.RABIES_VACCINE_HISTORY.V1,
    i18n.t('Menu:ANALYTICS.RABIES_VACCINE_HISTORY'),
  ),
  [SupportedAnalyticsDashboard.REFERRAL_BUSINESS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.REFERRAL_BUSINESS.V1,
    i18n.t('Menu:ANALYTICS.REFERRAL_BUSINESS'),
  ),
  [SupportedAnalyticsDashboard.REMINDERS_COMPLIANCE]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.REMINDERS_COMPLIANCE.V1,
    i18n.t('Menu:ANALYTICS.REMINDERS_COMPLIANCE'),
  ),
  [SupportedAnalyticsDashboard.REPEAT_AND_RECHECK_PATIENTS]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.REPEAT_AND_RECHECK_PATIENTS.V1,
      i18n.t('Menu:ANALYTICS.REPEAT_AND_RECHECK_PATIENTS'),
    ),
  [SupportedAnalyticsDashboard.SALES_SUMMARY_EXCLUDING_UNPAID_OTC]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.SALES_SUMMARY_EXCLUDING_UNPAID_OTC.V1,
      i18n.t('Menu:ANALYTICS.SALES_SUMMARY_EXCLUDING_UNPAID_OTC'),
    ),
  [SupportedAnalyticsDashboard.SCHEDULE_MANAGEMENT]: {
    [supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V1.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V1,
        i18n.t('Menu:ANALYTICS.SCHEDULE_MANAGEMENT_OLD'),
      ),
    [supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V2.id]:
      getAnalyticsMenuItem(
        supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V2,
        i18n.t('Menu:ANALYTICS.SCHEDULE_MANAGEMENT'),
      ),
  },
  [SupportedAnalyticsDashboard.TIME_TRACKING]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.TIME_TRACKING.V1,
    i18n.t('Menu:ANALYTICS.STAFF_TIME_TRACKING'),
  ),
  [SupportedAnalyticsDashboard.WELLNESS_PLANS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.WELLNESS_PLANS.V1,
    i18n.t('Common:WELLNESS_PLANS'),
  ),
  [SupportedAnalyticsDashboard.OPEN_AND_UNAPPLIED_BILLING]:
    getAnalyticsMenuItem(
      supportedAnalyticsDashboards.OPEN_AND_UNAPPLIED_BILLING.V1,
      i18n.t('Menu:OMNICHANNEL_ANALYTICS.OPEN_AND_UNAPPLIED_BILLING'),
    ),
  [SupportedAnalyticsDashboard.DAILY_BILLING_ACTIVITY]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.DAILY_BILLING_ACTIVITY.V1,
    i18n.t('Menu:OMNICHANNEL_ANALYTICS.DAILY_BILLING_ACTIVITY'),
  ),
  [SupportedAnalyticsDashboard.INVOICES_AND_PAYMENTS]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.INVOICES_AND_PAYMENTS.V1,
    i18n.t('Menu:ANALYTICS.INVOICES_AND_PAYMENTS_OLD'),
  ),
  [SupportedAnalyticsDashboard.MAGIC_QUADRANT]: getAnalyticsMenuItem(
    supportedAnalyticsDashboards.MAGIC_QUADRANT.V2,
    i18n.t('Menu:ANALYTICS.MAGIC_QUADRANT'),
  ),
}

export const useGeneralAnalyticsMenu = () => {
  const chewyReportsSectionEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CLIENT_INITIATED_REQUEST_DASHBOARD),
  )
  const chewyVetCareReportsSectionEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_VET_CARE_REPORTS),
  )
  const isBankReconReportEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BANK_RECON_REPORT),
  )
  const isCancellationsAndNoShowsReportV2Enabled = useSelector(
    getFeatureToggle(FeatureToggle.CANCELLATIONS_AND_NOSHOWS_DASHBOARD_V2),
  )

  const currentBusiness = useSelector(getCurrentBusiness)

  const { t } = useTranslation(['Common', 'Menu'])

  const AnalyticsMenuFinancials: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DAILY_RECONCILIATION
    ][supportedAnalyticsDashboards.DAILY_RECONCILIATION.V2.id],
    ...(isBankReconReportEnabled
      ? [
          generalAnalyticsDashboardMapping[
            SupportedAnalyticsDashboard.BANK_RECONCILIATION
          ],
        ]
      : []),
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DAILY_RECONCILIATION
    ][supportedAnalyticsDashboards.DAILY_RECONCILIATION.V3.id],
    generalAnalyticsDashboardMapping[SupportedAnalyticsDashboard.BILLING_AGING],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.SALES_SUMMARY_EXCLUDING_UNPAID_OTC
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DISCOUNT_USAGE
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.OPEN_AND_UNAPPLIED_BILLING
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DAILY_BILLING_ACTIVITY
    ],
  ]

  const AnalyticsMenuPerformance: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.PRACTICE_SALES
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.PRODUCER_SALES
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVOICE_ACTIVITY
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.BUNDLES_AND_WELLNESS_PLANS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.NEW_CLIENT_AND_PATIENT_ACTIVITY
    ],
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.PERFORMANCE_TRENDS_PA'),
      tooltipText: t('Menu:ANALYTICS.PERFORMANCE_TRENDS_PA_TOOLTIP'),
      disabled: true,
      showDisabled: true,
    },
  ]

  const AnalyticsMenuArchives: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DAILY_RECONCILIATION
    ][supportedAnalyticsDashboards.DAILY_RECONCILIATION.V1.id],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.MONTHLY_SALES_SUMMARY
    ],
    generalAnalyticsDashboardMapping[SupportedAnalyticsDashboard.PRODUCTION],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.SCHEDULE_MANAGEMENT
    ][supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V1.id],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVOICES_AND_PAYMENTS
    ],
  ]

  const AnalyticsMenuChewyReports: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CLIENT_INITIATED_REQUESTS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVENTORY_USAGE_AND_AVERAGE_INVOICE
    ],
  ]

  const AnalyticsMenuChewyVetCareReports: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.APPOINTMENT_MIX_BENCHMARK
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.DENTAL_AND_HEARTWORM_OVERVIEW
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INSURED_PATIENTS_VISITS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.REPEAT_AND_RECHECK_PATIENTS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.ONLINE_SICK_APPOINTMENTS
    ],
  ]

  const AnalyticsMenuSchedule: MenuItem[] = [
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.SCHEDULE_MANAGEMENT
    ][supportedAnalyticsDashboards.SCHEDULE_MANAGEMENT.V2.id],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CANCELLATIONS_AND_NO_SHOWS
    ][
      isCancellationsAndNoShowsReportV2Enabled
        ? supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V2.id
        : supportedAnalyticsDashboards.CANCELLATIONS_AND_NO_SHOWS.V1.id
    ],
    generalAnalyticsDashboardMapping[SupportedAnalyticsDashboard.TIME_TRACKING],
  ]

  return [
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.FINANCIALS_NEW'),
      expanded: true,
      children: AnalyticsMenuFinancials,
    },
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.PERFORMANCE_NEW'),
      expanded: true,
      children: AnalyticsMenuPerformance,
    },
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.SCHEDULE'),
      expanded: true,
      children: AnalyticsMenuSchedule,
    },
    ...(chewyReportsSectionEnabled
      ? [
          {
            id: uuid(),
            text: t('Menu:ANALYTICS.CHEWY_REPORTS'),
            expanded: true,
            children: AnalyticsMenuChewyReports,
          },
        ]
      : []),
    ...(chewyVetCareReportsSectionEnabled && currentBusiness?.omniChannel
      ? [
          {
            id: uuid(),
            text: t('Menu:ANALYTICS.CHEWY_VET_CARE_REPORTS'),
            expanded: true,
            children: AnalyticsMenuChewyVetCareReports,
          },
        ]
      : []),
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.ACQUISITION_AND_LIFETIME_VALUE
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CLIENT_AND_PATIENT_STATUS
    ],
    generalAnalyticsDashboardMapping[SupportedAnalyticsDashboard.CLIENT_LIST],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.CONTROLLED_SUBSTANCE_LOG
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.FORWARD_BOOKINGS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.GROUP_PERFORMANCE
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.GROUP_PRICE_LISTS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.INVENTORY_USAGE
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.MAGIC_QUADRANT
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.MARKETING_DATA_QUALITY
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.MEDICAL_INSIGHTS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.OPEN_SOAPS_AND_INVOICES
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.PRACTICE_PERFORMANCE
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.RABIES_VACCINE_HISTORY
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.REMINDERS_COMPLIANCE
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.REFERRAL_BUSINESS
    ],
    generalAnalyticsDashboardMapping[
      SupportedAnalyticsDashboard.WELLNESS_PLANS
    ],
    {
      id: uuid(),
      text: t('Menu:ANALYTICS.ARCHIVES'),
      expanded: true,
      children: AnalyticsMenuArchives,
    },
  ]
}
