import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  ErrorTooltip,
  PasswordInput,
  Text,
  User,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import ForgotPasswordLink from '~/components/common/link/ForgotPasswordLink'
import LogOutLink from '~/components/common/link/LogOutLink'
import { silentLoginRequest } from '~/store/actions/auth'
import {
  getAuthIsSilentlyFetching,
  getAuthSilentLoginError,
} from '~/store/reducers/auth'
import { broadcastChannel } from '~/utils/broadcastChannel'
import { getErrorMessage, removeServerErrorPrefix } from '~/utils/errors'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useLogout from '~/utils/useLogout'

import ProfileBottomContainer from './ProfileBottomContainer'
import ProfileTopContainer from './ProfileTopContainer'

const useStyles = makeStyles(
  (theme) => ({
    profileTopContainer: {
      height: 103,
      position: 'relative',
    },
    profileBottomContainer: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(4.5),
    },
    logInButton: {
      width: 240,
    },
    logInButtonContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    profileUserNameText: {
      color: theme.colors.tertiaryText,
    },
    profilePicText: {
      color: theme.colors.profileText,
      fontSize: '4.4rem',
    },
    profilePicContainer: {
      position: 'absolute',
      bottom: -40,
    },
    profilePic: {
      height: 108,
      width: 108,
    },
    button: {},
  }),
  { name: 'SwitchUser' },
)

export interface SwitchUserProps {
  onOk?: () => void
  showLogOutLink?: boolean
  user: Partial<User>
}

const SwitchUser = ({
  user: userProp,
  onOk,
  showLogOutLink,
}: SwitchUserProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Validations'])

  const silentLoginError = useSelector(getAuthSilentLoginError)
  const isSilentlyFetching = useSelector(getAuthIsSilentlyFetching)

  const logout = useLogout()

  const user = userProp || {}

  const onLoginResponse = () => {
    if (!silentLoginError && onOk) {
      onOk()
    }
  }
  const setCloseAfterSuccessOn = useCloseAfterCreation(
    onLoginResponse,
    getAuthIsSilentlyFetching,
  )

  const [touched, setTouched] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordValidationError, setPasswordValidationError] = useState('')

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setTouched(false)
    setPasswordValidationError('')
    if (!isSilentlyFetching) {
      if (!password) {
        setPasswordValidationError(t('Validations:ENTER_PASSWORD'))
      } else {
        const email = user.email || ''

        setCloseAfterSuccessOn()
        dispatch(silentLoginRequest(email, password))
        broadcastChannel.postMessage({
          action: 'silent-login',
          data: {
            email,
            password,
          },
        })
      }
    }
  }

  useEffect(() => {
    if (silentLoginError) {
      setPasswordValidationError(
        removeServerErrorPrefix(getErrorMessage(silentLoginError) || '') || '',
      )
    }
  }, [silentLoginError])

  return (
    <Grid container direction="column">
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        justifyContent="center"
      >
        <ProfileTopContainer className={classes.profileTopContainer}>
          <Grid item className={classes.profilePicContainer}>
            <Avatar
              className={classes.profilePic}
              person={user}
              size="normal"
            />
          </Grid>
        </ProfileTopContainer>
        <ProfileBottomContainer className={classes.profileBottomContainer}>
          <Text
            align="center"
            className={classes.profileUserNameText}
            variant="h3"
          >
            {Utils.getPersonString(user)}
          </Text>
          {showLogOutLink && <LogOutLink />}
          <ErrorTooltip
            message={passwordValidationError}
            open={Boolean(!touched && passwordValidationError)}
          >
            <PasswordInput
              autoFocus
              error={Boolean(!touched && passwordValidationError)}
              password={password}
              onChange={(event) => {
                setTouched(true)
                setPassword(event.target.value)
              }}
            />
          </ErrorTooltip>
          <ForgotPasswordLink onClick={() => logout()} />
          <Grid
            container
            item
            className={classes.logInButtonContainer}
            justifyContent="center"
          >
            <ButtonWithLoader
              className={classNames(classes.button, classes.logInButton)}
              disabled={
                isSilentlyFetching || !Validators.isPasswordValid(password)
              }
              loading={isSilentlyFetching}
              type="submit"
              onClick={handleSubmit}
            >
              {t('Common:LOG_IN_ACTION')}
            </ButtonWithLoader>
          </Grid>
        </ProfileBottomContainer>
      </Grid>
    </Grid>
  )
}

export default SwitchUser
