import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { BaseSearchRequestParams } from '~/types'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchMarketplaceItems = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  active: boolean | Nil,
  query: string,
) =>
  request(
    getPreludeAPIPath('extension/v1/marketplace/page'),
    {
      method: 'GET',
      params: { businessId, offset: from, limit: to, active, query },
    },
    true,
    Schema.marketplaceItemsList,
  )

export const editMarketplaceItem = (
  businessId: string,
  id: string,
  active: boolean,
  rhapsodyApiKey: string,
  jsonParameters: any,
) =>
  request(
    getPreludeAPIPath('extension/v1/marketplace'),
    {
      method: 'POST',
      params: { businessId, id },
      data: {
        active,
        rhapsodyApiKey,
        jsonParameters,
      },
    },
    true,
  )

export const fetchMarketplaceIFrames = (businessId: string) =>
  request(
    getPreludeAPIPath('extension/v1/widgets'),
    { method: 'GET', params: { businessId } },
    true,
    Schema.marketplaceIFrameItemsList,
  )

export const searchMarketplaceItems = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getPreludeAPIPath('extension/v1/marketplace/search'),
    { method: 'GET', params: { businessId, query, max } },
    true,
  )
