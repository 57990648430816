export const FETCH_MEMBERS_LIST = 'members/FETCH_MEMBERS_LIST'
export const FETCH_MEMBERS_LIST_SUCCESS = 'members/FETCH_MEMBERS_LIST_SUCCESS'
export const FETCH_MEMBERS_LIST_FAILURE = 'members/FETCH_MEMBERS_LIST_FAILURE'

export const FETCH_MORE_ITEMS_FOR_MEMBERS_LIST =
  'members/FETCH_MORE_ITEMS_FOR_MEMBERS_LIST'
export const FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_SUCCESS =
  'members/FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_FAILURE =
  'members/FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_FAILURE'

export const FETCH_MEMBERS_FOR_SELECT_LIST =
  'members/FETCH_MEMBERS_FOR_SELECT_LIST'
export const FETCH_MEMBERS_FOR_SELECT_LIST_SUCCESS =
  'members/FETCH_MEMBERS_FOR_SELECT_LIST_SUCCESS'
export const FETCH_MEMBERS_FOR_SELECT_LIST_FAILURE =
  'members/FETCH_MEMBERS_FOR_SELECT_LIST_FAILURE'

export const FETCH_MORE_MEMBERS_FOR_SELECT_LIST =
  'members/FETCH_MORE_MEMBERS_FOR_SELECT_LIST'
export const FETCH_MORE_MEMBERS_FOR_SELECT_LIST_SUCCESS =
  'members/FETCH_MORE_MEMBERS_FOR_SELECT_LIST_SUCCESS'
export const FETCH_MORE_MEMBERS_FOR_SELECT_LIST_FAILURE =
  'members/FETCH_MORE_MEMBERS_FOR_SELECT_LIST_FAILURE'

export const FETCH_TEAMS_LIST = 'members/FETCH_TEAMS_LIST'
export const FETCH_TEAMS_LIST_SUCCESS = 'members/FETCH_TEAMS_LIST_SUCCESS'
export const FETCH_TEAMS_LIST_FAILURE = 'members/FETCH_TEAMS_LIST_FAILURE'

export const FETCH_MEMBER = 'members/FETCH_MEMBER'
export const FETCH_MEMBER_SUCCESS = 'members/FETCH_MEMBER_SUCCESS'
export const FETCH_MEMBER_FAILURE = 'members/FETCH_MEMBER_FAILURE'

export const INVITE_MEMBER = 'members/INVITE_MEMBER'
export const INVITE_MEMBER_SUCCESS = 'members/INVITE_MEMBER_SUCCESS'
export const INVITE_MEMBER_FAILURE = 'members/INVITE_MEMBER_FAILURE'

export const UPDATE_MEMBER = 'members/UPDATE_MEMBER'
export const UPDATE_MEMBER_SUCCESS = 'members/UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAILURE = 'members/UPDATE_MEMBER_FAILURE'

export const UPDATE_MEMBER_ROLES = 'members/UPDATE_MEMBER_ROLES'
export const UPDATE_MEMBER_ROLES_SUCCESS = 'members/UPDATE_MEMBER_ROLES_SUCCESS'
export const UPDATE_MEMBER_ROLES_FAILURE = 'members/UPDATE_MEMBER_ROLES_FAILURE'

export const CREATE_TEAM = 'members/CREATE_TEAM'
export const CREATE_TEAM_SUCCESS = 'members/CREATE_TEAM_SUCCESS'
export const CREATE_TEAM_FAILURE = 'members/CREATE_TEAM_FAILURE'

// TODO: remove
export const DELETE_MEMBER = 'members/DELETE_MEMBER'
export const DELETE_MEMBER_SUCCESS = 'members/DELETE_MEMBER_SUCCESS'
export const DELETE_MEMBER_FAILURE = 'members/DELETE_MEMBER_FAILURE'

export const DELETE_MEMBER_FROM_BUSINESSES =
  'members/DELETE_MEMBER_FROM_BUSINESSES'
export const DELETE_MEMBER_FROM_BUSINESSES_SUCCESS =
  'members/DELETE_MEMBER_FROM_BUSINESSES_SUCCESS'
export const DELETE_MEMBER_FROM_BUSINESSES_FAILURE =
  'members/DELETE_MEMBER_FROM_BUSINESSES_FAILURE'

export const ADD_MEMBER_TO_CURRENT_BUSINESS =
  'members/ADD_MEMBER_TO_CURRENT_BUSINESS'
export const DELETE_MEMBER_FROM_CURRENT_BUSINESS =
  'members/DELETE_MEMBER_FROM_CURRENT_BUSINESS'

export const CREATE_TEAM_MEMBER_EMPLOYEE_ID =
  'members/CREATE_TEAM_MEMBER_EMPLOYEE_ID'
export const CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS =
  'members/CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS'
export const CREATE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE =
  'members/CREATE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE'

export const EDIT_TEAM_MEMBER_EMPLOYEE_ID =
  'members/EDIT_TEAM_MEMBER_EMPLOYEE_ID'
export const EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS =
  'members/EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS'
export const EDIT_TEAM_MEMBER_EMPLOYEE_ID_FAILURE =
  'members/EDIT_TEAM_MEMBER_EMPLOYEE_ID_FAILURE'

export const DELETE_TEAM_MEMBER_EMPLOYEE_ID =
  'members/DELETE_TEAM_MEMBER_EMPLOYEE_ID'
export const DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS =
  'members/DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS'
export const DELETE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE =
  'members/DELETE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE'

export const CREATE_TEAM_MEMBER_LICENSE = 'members/CREATE_TEAM_MEMBER_LICENSE'
export const CREATE_TEAM_MEMBER_LICENSE_SUCCESS =
  'members/CREATE_TEAM_MEMBER_LICENSE_SUCCESS'
export const CREATE_TEAM_MEMBER_LICENSE_FAILURE =
  'members/CREATE_TEAM_MEMBER_LICENSE_FAILURE'

export const EDIT_TEAM_MEMBER_LICENSE = 'members/EDIT_TEAM_MEMBER_LICENSE'
export const EDIT_TEAM_MEMBER_LICENSE_SUCCESS =
  'members/EDIT_TEAM_MEMBER_LICENSE_SUCCESS'
export const EDIT_TEAM_MEMBER_LICENSE_FAILURE =
  'members/EDIT_TEAM_MEMBER_LICENSE_FAILURE'

export const DELETE_TEAM_MEMBER_LICENSE = 'members/DELETE_TEAM_MEMBER_LICENSE'
export const DELETE_TEAM_MEMBER_LICENSE_SUCCESS =
  'members/DELETE_TEAM_MEMBER_LICENSE_SUCCESS'
export const DELETE_TEAM_MEMBER_LICENSE_FAILURE =
  'members/DELETE_TEAM_MEMBER_LICENSE_FAILURE'

export const UPDATE_ACTIVE_STATUS = 'members/UPDATE_ACTIVE_STATUS'
export const UPDATE_ACTIVE_STATUS_SUCCESS =
  'members/UPDATE_ACTIVE_STATUS_SUCCESS'
export const UPDATE_ACTIVE_STATUS_FAILURE =
  'members/UPDATE_ACTIVE_STATUS_FAILURE'

export const CLEAR_ERROR = 'members/CLEAR_ERROR'
