import { EditorType } from '@pbt/pbt-ckeditor-ui'

type SoapMedicalOrDischargeNoteRequestData = { soapId: string }
type SoapChargeNoteRequestData = { id: string }
type SoapDiagnosisHistoryRequestData = { historyId: string; patientId: string }

type Options = {
  bundleType: EditorType
  editorId: string
  restore?: boolean
}

export type HandleChannelIdFn<R> = {
  (
    documentType: EditorDocumentType.DIAGNOSIS_HISTORY,
    payload: SoapDiagnosisHistoryRequestData,
    options: Options,
  ): R
  (
    documentType:
      | EditorDocumentType.SOAP_MEDICAL
      | EditorDocumentType.SOAP_DISCHARGE,
    payload: SoapMedicalOrDischargeNoteRequestData,
    options: Options,
  ): R
  (
    documentType:
      | EditorDocumentType.SOAP_CHARGE_INVENTORY
      | EditorDocumentType.SOAP_CHARGE_LAB_TEST
      | EditorDocumentType.SOAP_CHARGE_PRESCRIPTION
      | EditorDocumentType.SOAP_CHARGE_PROCEDURE
      | EditorDocumentType.SOAP_CHARGE_TASK,
    payload: SoapChargeNoteRequestData,
    options: Options,
  ): R
}

export enum FeatureElements {
  COMMENTS = 'comments',
  PRESENCE_LIST = 'presence-list',
  REVISION_HISTORY = 'revision-history',
}

export enum EditorDocumentType {
  DIAGNOSIS_HISTORY = 'diagnosis-history',
  SOAP_CHARGE_INVENTORY = 'soap-charge-inventory',
  SOAP_CHARGE_LAB_TEST = 'soap-charge-labtest',
  SOAP_CHARGE_PRESCRIPTION = 'soap-charge-prescription',
  SOAP_CHARGE_PROCEDURE = 'soap-charge-procedure',
  SOAP_CHARGE_TASK = 'soap-charge-task',
  SOAP_DISCHARGE = 'soap-discharge',
  SOAP_MEDICAL = 'soap-medical',
  SOAP_V2_PROBLEMS = 'soap-v2-problems',
}

export enum PuiCKEditorVariant {
  MEDICAL_OR_DISCHARGE = 'medical-or-discharge',
  ORDER = 'order',
}
