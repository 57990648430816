import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant,
  LanguageUtils,
  Nil,
  PuiCheckbox,
  PuiTextArea,
  QuestionAnswer,
  QuestionAnswerInput,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import PuiPopup, { PuiPopupProps } from '~/components/common/PuiPopup'
import { getAnswerInputTypes } from '~/store/reducers/constants'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 152,
      height: 40,
    },
  }),
  { name: 'AnswerPopup' },
)

export interface AnswerPopupProps extends PuiPopupProps {
  answer: QuestionAnswer | Nil
  onAnswerAdded: (answer: QuestionAnswer) => void
  onAnswerEdited: (answer: QuestionAnswer) => void
  open: boolean
}

const AnswerPopup = ({
  answer,
  open,
  onAnswerAdded,
  onAnswerEdited,
  ...rest
}: AnswerPopupProps) => {
  const classes = useStyles()
  const AnswerInputTypes: Constant[] = useSelector(getAnswerInputTypes)
  const { t } = useTranslation(['Admin', 'Common'])

  const [inputs, setInputs] = useState(answer?.inputs || [])

  const isCreate = !answer

  const {
    fields: { text },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'text',
        label: t('Common:TEXT_MESSAGE'),
        validators: ['required'],
        initialValue: answer?.text || '',
      },
    ],
    false,
  )

  useEffect(() => {
    if (!isCreate) {
      reset()
    }
  }, [answer])

  useEffect(() => {
    if (!open) {
      setInputs([])
      reset()
    }
  }, [open])

  useEffect(() => {
    setInputs(answer?.inputs || [])
  }, [answer?.inputs])

  const updateInputs = (checked: boolean, id: string) => {
    const oldInput = inputs.find(
      ({ inputTypeId }) => inputTypeId === id,
    ) as QuestionAnswerInput
    const oldInputIndex = inputs.indexOf(oldInput)
    const newInput = { inputTypeId: id } as QuestionAnswerInput

    if (!checked) {
      setInputs(R.without([oldInput], inputs))
    } else if (oldInputIndex > -1) {
      setInputs(R.update(oldInputIndex, newInput, inputs))
    } else {
      setInputs(inputs.concat(newInput))
    }
  }

  const add = () => {
    if (validate()) {
      const newAnswer = {
        ...(answer || {}),
        inputs,
        text: text.value,
      } as QuestionAnswer

      if (isCreate) {
        onAnswerAdded(newAnswer)
      } else {
        onAnswerEdited(newAnswer)
      }
    }
  }

  return (
    <PuiPopup open={open} {...rest}>
      <Grid container direction="column" p={3} width={600}>
        <Grid item>
          <Text variant="h2">
            {isCreate ? t('Common:ADD_ANSWER') : t('Common:EDIT_ANSWER')}
          </Text>
        </Grid>
        <Grid item mb={2} mt={1}>
          <PuiTextArea
            field={text}
            minRows={1}
            placeholder={t('Admin:CATALOG.ANSWER_POPUP.TEXT_FIELD_PLACEHOLDER')}
          />
        </Grid>
        {AnswerInputTypes.map((type) => (
          <Grid item key={type.id}>
            <PuiCheckbox
              checked={inputs.some(
                ({ inputTypeId }) => inputTypeId === type.id,
              )}
              label={t('Admin:CATALOG.ANSWER_POPUP.INCLUDE_A_NAME', {
                name: LanguageUtils.getTranslatedFieldName(type).toLowerCase(),
              })}
              onChange={Utils.handleFormCheckboxInput((checked) =>
                updateInputs(checked, type.id),
              )}
            />
          </Grid>
        ))}
        <Grid item mt={1}>
          <Fab
            className={classes.button}
            color="inherit"
            type="submit"
            variant="extended"
            onClick={add}
          >
            {isCreate ? t('Common:ADD_ACTION') : t('Common:SAVE_ACTION')}
          </Fab>
        </Grid>
      </Grid>
    </PuiPopup>
  )
}

export default AnswerPopup
