import React, { useContext, useEffect, useRef } from 'react'

import { RCContext, RCContextValue } from './RCIFrameProvider'

const RCIFrameContainer = () => {
  const { setIframeContainerAnchor } = useContext<RCContextValue>(RCContext)

  const iframeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIframeContainerAnchor(iframeRef.current)
    return () => {
      setIframeContainerAnchor(null)
    }
  }, [])

  return (
    <div
      ref={iframeRef}
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
    />
  )
}

export default RCIFrameContainer
