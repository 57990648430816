import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Field, PermissionArea } from '@pbt/pbt-ui-components'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getBusinessStatus } from '~/store/reducers/constants'

import PracticeStatusLabel from './PracticeStatusLabel'

const useStyles = makeStyles((theme) => ({
  statusLabel: {
    width: 'fit-content',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}))

export interface PracticeStatusSelectProps {
  status: Field
}

const PracticeStatusSelect = ({ status }: PracticeStatusSelectProps) => {
  const classes = useStyles()

  const supportPermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))
  const BusinessStatus = useSelector(getBusinessStatus) || []

  return supportPermissions.update ? (
    <EnumSelect
      accent
      Constant={BusinessStatus}
      classes={{
        statusSelect: classes.statusLabel,
      }}
      field={status}
    />
  ) : (
    <PracticeStatusLabel
      className={classes.statusLabel}
      statusId={status.value}
      variant="big"
    />
  )
}

export default PracticeStatusSelect
