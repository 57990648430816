import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  CircularProgressOverlay,
  DateFormat,
  moment,
  Text,
} from '@pbt/pbt-ui-components'

import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import { MigrationSteps } from '~/constants/migrationV2'
import i18n from '~/locales/i18n'
import {
  fetchMigrationImportTasks,
  fetchMoreMigrationImportTasks,
  getCurrentImportRequest,
  getImportTaskIds,
  getImportTasks,
  getIsLoadingImportTasks,
  getMultipleImportTasks,
} from '~/store/duck/migrationV2'
import {
  BaseStepComponentProps,
  MigrationImportTask,
} from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const useStyles = makeStyles(
  () => ({
    listContainer: {
      height: '100%',
    },
  }),
  { name: 'ImportRequestOverview' },
)

const buildColumns = () => {
  const mainColumn = {
    label: i18n.t('Common:TYPE_ONE'),
    value: (item: MigrationImportTask) => <Text>{item.type}</Text>,
  }
  const columns = [
    {
      label: i18n.t('Common:STATUS'),
      value: (item: MigrationImportTask) => <Text>{item.status}</Text>,
    },
    {
      label: i18n.t('Common:CREATED'),
      value: (item: MigrationImportTask) => (
        <Text>
          {moment(item.creationDate).format(
            DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE,
          )}
        </Text>
      ),
    },
  ]

  return { mainColumn, columns }
}

const ImportRequestOverview = ({ stepController }: BaseStepComponentProps) => {
  const classes = useStyles()
  const [isInitialized, setIsInitialized] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const currentImportRequest = useSelector(getCurrentImportRequest)
  const isLoading = useSelector(getIsLoadingImportTasks) || !isInitialized
  const importTaskIds = useSelector(getImportTaskIds)
  const importTasks = useSelector(getMultipleImportTasks(importTaskIds))
  const importTaskTotalCount = useSelector(getImportTasks).totalCount

  useEffect(() => {
    if (!isInitialized && currentImportRequest) {
      dispatch(
        fetchMigrationImportTasks({ importRequestId: currentImportRequest.id }),
      )
      setIsInitialized(true)
    }
  }, [currentImportRequest, isInitialized])

  const loadMore = () => {
    if (!currentImportRequest) {
      return
    }
    dispatch(
      fetchMoreMigrationImportTasks({
        importRequestId: currentImportRequest?.id,
        from: importTasks.length,
      }),
    )
  }

  const handleProceed = () => {
    stepController.next()
  }

  const handleCreateImportTask = () => {
    stepController.setCurrentStepOverride({
      migrationStep: MigrationSteps.IMPORT_TASK_CREATION,
      props: {
        // Direct back to this component
        onNext: () => stepController.setCurrentStepOverride(undefined),
      },
    })
  }

  const { mainColumn, columns } = buildColumns()

  return (
    <MigrationStep>
      <MigrationStepContent height={320}>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:LOOKING_FOR_IMPORT_TASKS')}
          />
        ) : (
          <PuiDataTable
            densed
            classes={{
              table: classes.listContainer,
            }}
            columns={columns}
            emptyPlaceholder={
              <Text align="center" m={2} variant="body2">
                {t('Businesses:MIGRATION.NO_TASKS_FOUND')}
              </Text>
            }
            items={importTasks}
            loadMoreItems={loadMore}
            mainColumn={mainColumn}
            totalCount={importTaskTotalCount}
          />
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:DONE')}
        </MigrationStepActionButton>
        <MigrationStepActionButton
          disabled={isLoading}
          onClick={handleCreateImportTask}
        >
          {t('Businesses:MIGRATION.CREATE_IMPORT_TASK')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default ImportRequestOverview
