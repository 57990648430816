import { Nil } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { EmailEntityConfig } from '~/types'

import { getAPIPath, request } from './core'
import { ConversationTransport } from './graphql/generated/types'
import * as Schema from './schemas'

export const loadEmailAppointmentConfig = (
  businessId: string,
  appointmentId: string,
  transport: ConversationTransport | Nil,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/config`),
    { method: 'GET', params: { businessId, transport } },
    true,
  )

export const loadEmailAppointmentDummyConfig = (businessId: string) =>
  request(
    getAPIPath('appointments/email/config/default'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const loadEmailAppointmentConfirmationConfig = (
  businessId: string,
  appointmentId: string,
  transport: ConversationTransport | Nil,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/confirmation/config`),
    { method: 'GET', params: { businessId, transport } },
    true,
  )

export const loadDefaultEmailAppointmentConfirmationConfig = (
  businessId: string,
) =>
  request(
    getAPIPath('appointments/email/confirmation/config'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const loadEmailAppointmentTemplate = (
  businessId: string,
  appointmentId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/preview`),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )

export const loadEmailAppointmentDummyTemplate = (
  businessId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath('appointments/email/preview/dummy'),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )

export const loadEmailAppointmentConfirmationTemplate = (
  businessId: string,
  appointmentId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/confirmation/preview`),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )

export const loadEmailAppointmentDummyConfirmationTemplate = (
  businessId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath('appointments/email/confirmation/preview/dummy'),
    {
      method: 'POST',
      data: config,
      params: { businessId, language: i18n.language },
    },
    true,
  )

export const emailAppointment = (
  businessId: string,
  appointmentId: string,
  config: EmailEntityConfig,
  includeAttachment?: boolean,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/send`),
    { method: 'POST', data: config, params: { businessId, includeAttachment } },
    true,
    Schema.conversationsArray,
  )

export const emailAppointmentConfirmation = (
  businessId: string,
  appointmentId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/confirmation/send`),
    { method: 'POST', data: config, params: { businessId } },
    true,
    Schema.conversationsArray,
  )

export const loadEmailZoomLinkConfig = (
  businessId: string,
  appointmentId: string,
  transport: ConversationTransport | Nil,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/zoomLink/config`),
    { method: 'GET', params: { businessId, transport } },
    true,
  )

export const loadDefaultEmailZoomLinkConfig = (businessId: string) =>
  request(
    getAPIPath('appointments/email/zoomLink/config'),
    { method: 'GET', params: { businessId } },
    true,
  )

export const loadEmailZoomLinkTemplate = (
  businessId: string,
  appointmentId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/zoomLink/preview`),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )

export const loadEmailZoomLinkDummyTemplate = (
  businessId: string,
  config: Partial<EmailEntityConfig>,
) =>
  request(
    getAPIPath('appointments/email/zoomLink/preview/dummy'),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )

export const emailZoomLink = (
  businessId: string,
  appointmentId: string,
  config: EmailEntityConfig,
) =>
  request(
    getAPIPath(`appointments/${appointmentId}/email/zoomLink/send`),
    { method: 'POST', data: config, params: { businessId } },
    true,
    Schema.conversationsArray,
  )
