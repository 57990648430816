import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  Business,
  ButtonWithLoader,
  NamedEntity,
  Nil,
  Patient,
  PuiDialog,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { MessageType } from '~/constants/communications'
import i18n from '~/locales/i18n'
import { createConversation } from '~/store/actions/conversations'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getConversationsIsCreating } from '~/store/reducers/conversations'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { UnsavedConversation, UnsavedConversationMessage } from '~/types'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'
import { useSaveEmailAndSendMessage as useAskToSaveEmailAddressAndSend } from '~/utils/useSaveEmailAndSendMessage'

import EmailForm, { EmailFormHandle } from '../email/EmailForm'
import RequiredFieldsNotice from '../inputs/RequiredFieldsNotice'
import EmailDialogSection from './email/EmailDialogSection'

const SELECT_ALL_ATTACHMENTS_ID = 'SELECT_ALL_ATTACHMENTS_ID'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 167,
    },
  }),
  { name: 'EmailAttachmentDialog' },
)

const getPatientName = R.propOr(`[${i18n.t('Common:PATIENT_NAME')}]`, 'name')
const getClientName = R.propOr(`[${i18n.t('Common:CLIENT_NAME')}]`, 'firstName')
const getBusinessName = R.propOr(
  `[${i18n.t('Businesses:PRACTICE_NAME')}]`,
  'name',
)

const buildMessage = (
  business: Business | Nil,
  client: User | Nil,
  patient: Patient | Nil,
) =>
  [
    i18n.t('Dialogs:EMAIL_ATTACHMENT_DIALOG.INITIAL_MESSAGE.LINE_01', {
      clientName: getClientName(client),
    }),
    '',
    i18n.t('Dialogs:EMAIL_ATTACHMENT_DIALOG.INITIAL_MESSAGE.LINE_02', {
      patient: getPatientName(patient),
    }),
    '',
    i18n.t('Dialogs:EMAIL_ATTACHMENT_DIALOG.INITIAL_MESSAGE.LINE_03'),
    '',
    i18n.t('Dialogs:EMAIL_ATTACHMENT_DIALOG.INITIAL_MESSAGE.LINE_04', {
      business: getBusinessName(business),
    }),
  ].join('<br />')

export interface EmailAttachmentDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
  scopedAttachments: NamedEntity[]
  selectedDocuments: string[]
  soapId?: string | Nil
}

const EmailAttachmentDialog = ({
  selectedDocuments: selectedDocumentsProp,
  open,
  onClose,
  patientId,
  clientId,
  scopedAttachments,
  soapId,
}: EmailAttachmentDialogProps) => {
  const emailFormRef = useRef<EmailFormHandle>(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const patient = useSelector(getPatient(patientId))
  const client = useSelector(getUser(clientId))
  const business = useSelector(getCurrentBusiness)
  const isLoading = useSelector(getConversationsIsCreating)
  const [selectedDocuments, setSelectedDocuments] = useState(
    selectedDocumentsProp || [],
  )

  const transport = ConversationTransport.Email
  const firstDocumentName = Utils.getConstantName(
    selectedDocuments[0],
    scopedAttachments,
  )
  const initialMessage = buildMessage(business, client, patient)
  const initialSubject =
    selectedDocuments.length === 1
      ? firstDocumentName
      : t('Dialogs:EMAIL_ATTACHMENT_DIALOG.INITIAL_SUBJECT', {
          patientName: patient?.name,
        })

  const attachmentOptions = scopedAttachments.map(
    ({ id, name, nameTranslation }) => ({
      id,
      name,
      nameTranslation,
      checked: R.includes(id, selectedDocuments),
    }),
  )

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getConversationsIsCreating,
    onConversationCreationSuccess: onClose,
  })

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport: ConversationTransport.Email, client },
    { initialSubject, initialMessage },
  )

  const getSelectedAttachmentFiles = () =>
    R.includes(SELECT_ALL_ATTACHMENTS_ID, selectedDocuments)
      ? scopedAttachments
      : scopedAttachments.filter(({ id }) => R.includes(id, selectedDocuments))

  const formRecipients = () => emailFormRef.current?.formRecipients() || []

  const handleAttachmentSend = () => {
    if (validate()) {
      const attachmentFiles = getSelectedAttachmentFiles()
      const conversationMessage: UnsavedConversationMessage = {
        body: message.value,
        type: MessageType.PLAIN,
        files: attachmentFiles,
      }
      const conversation: UnsavedConversation = {
        transport,
        title: subject.value,
        recipients: formRecipients(),
        isDraft: false,
        soapId,
      }

      dispatch(createConversation(conversation, conversationMessage))
      displayConversationCreationResult()
    }
  }

  const askToSaveEmailAddressAndSend = useAskToSaveEmailAddressAndSend(
    handleAttachmentSend,
    client,
    formRecipients,
    to.value,
  )
  const handleSendEmailClick = () =>
    validate() && askToSaveEmailAddressAndSend()

  const prepareSelectedStateByAll = (isChecked: boolean) =>
    isChecked ? R.pluck('id', attachmentOptions) : []

  const prepareSelectedStateById = (
    isChecked: boolean,
    attachmentId: string,
  ) =>
    isChecked
      ? R.uniq([...selectedDocuments, attachmentId])
      : R.without([attachmentId], selectedDocuments)

  const handleAttachmentToggle = (attachmentId: string, isChecked: boolean) => {
    const newState =
      attachmentId === SELECT_ALL_ATTACHMENTS_ID
        ? prepareSelectedStateByAll(isChecked)
        : prepareSelectedStateById(isChecked, attachmentId)
    setSelectedDocuments(newState)
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="email-attachment-dialog"
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column" p={3}>
        <EmailForm
          clientId={clientId}
          message={message}
          patientId={patientId}
          ref={emailFormRef}
          soapId={soapId}
          subject={subject}
          to={to}
        />
        {Boolean(attachmentOptions.length) && (
          <Grid item mt={2}>
            <EmailDialogSection
              defaultExpanded
              checked={attachmentOptions.length === selectedDocuments.length}
              children={attachmentOptions}
              id={SELECT_ALL_ATTACHMENTS_ID}
              name={t('Common:ATTACHMENTS')}
              onCheckedChange={handleAttachmentToggle}
            />
          </Grid>
        )}
        <Grid item mt={2}>
          <RequiredFieldsNotice />
        </Grid>
        <Grid container item mt={2}>
          <ButtonWithLoader
            className={classes.button}
            loading={isLoading}
            onClick={handleSendEmailClick}
          >
            {t('Common:SEND_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default EmailAttachmentDialog
