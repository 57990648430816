import React from 'react'
import { useSelector } from 'react-redux'
import {
  PrimitiveTableRow,
  PrimitiveTableRowProps,
} from '@pbt/pbt-ui-components'

import { getTask } from '~/store/reducers/tasks'

export interface TasksTableRowProps extends PrimitiveTableRowProps {
  item: string
}

const TasksTableRow = ({ item: taskId, ...rest }: TasksTableRowProps) => {
  const item = useSelector(getTask(taskId))

  return <PrimitiveTableRow item={item} {...rest} />
}

export default TasksTableRow
