import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { BlobWithName } from '@pbt/pbt-ui-components'

import { uploadAsset } from '~/api'
import FileInput from '~/components/common/inputs/file-input/FileInput'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
  }),
  { name: 'UploadPage' },
)

export interface UploadPageProps {
  aspectRatio?: number
  className?: string
  mobileText?: string
  onCancel: () => void
  onUploaded: (url: string) => void
  text?: string
}

const UploadPage = ({
  className,
  onUploaded,
  onCancel,
  text,
  mobileText,
  aspectRatio,
}: UploadPageProps) => {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)

  const onFileReady = ({ blob }: { blob: BlobWithName }) => {
    setIsLoading(true)
    uploadAsset(blob)
      .then((url) => onUploaded(url))
      .finally(() => setIsLoading(false))
  }

  return (
    <FileInput
      onlyImages
      aspectRatio={aspectRatio}
      className={classNames(className, classes.root)}
      isLoading={isLoading}
      mobileText={mobileText}
      text={text}
      onCancel={onCancel}
      onFileReady={onFileReady}
    />
  )
}

export default UploadPage
