import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AlertIconType, Nil, PuiDialog, Utils } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import LabVendorNames from '~/constants/labVendorName'
import { LabOrdersStatus, OrderType } from '~/constants/SOAPStates'
import { saveInvoice } from '~/store/actions/finance'
import {
  getLabOrderIsPlacing,
  getLabOrdersError,
  getLabOrdersIsLoading,
  getLogIdsForInvoiceWithNoProducer,
  getSoapsWithNoDoctor,
  placeAllLabOrders,
  placeLabOrder,
  setLogIdsForInvoiceWithNoProducer,
  setSoapsWithNoDoctor,
} from '~/store/duck/labOrders'
import { getLabOrderStatuses, getLabVendors } from '~/store/reducers/constants'
import { getFinanceInvoice } from '~/store/reducers/finance'
import { getDoctorId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import {
  Invoice,
  InvoiceLineItem,
  LabOrder,
  LabOrderLabTest,
  LabOrderOptions,
  Order,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import { LabTestGroupKeys } from '../labTestUtils'
import LabSection from './LabSection'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'OrderManagementDialog' },
)

type LabOrderProps = {
  labTests: LabOrderLabTest[]
  options: LabOrderOptions
  vendorId: string
}

interface OrderManagementDialogConstProps {
  clientId: string | Nil
  hasIntegrationMap: Record<string, boolean>
  labTestsMap: Record<string, Record<LabTestGroupKeys, Order[]>>
  onCancelOrder: (vendorId: string, orderId: string) => void
  onClose?: () => void
  open: boolean
  ordersMap: Record<string, LabOrder>
  patientId?: string | Nil
}

interface OTCManagementDialogProps extends OrderManagementDialogConstProps {
  invoiceId: string
  isOTC: true
  soapId?: undefined
}

interface ManagementDialogProps extends OrderManagementDialogConstProps {
  invoiceId?: undefined
  isOTC?: undefined
  soapId?: string | Nil
}

const OrderManagementDialog = ({
  isOTC,
  soapId,
  invoiceId,
  labTestsMap,
  ordersMap,
  hasIntegrationMap,
  open,
  clientId,
  patientId,
  onClose,
  onCancelOrder,
}: OTCManagementDialogProps | ManagementDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Dialogs', 'Errors'])

  const doctorId = useSelector(getDoctorId)
  const doctor = useSelector(getUser(doctorId))
  const LabVendors = useSelector(getLabVendors)
  const soapsWithNoDoctor = useSelector(getSoapsWithNoDoctor)
  const logIdsForInvoiceWithNoProducer = useSelector(
    getLogIdsForInvoiceWithNoProducer,
  )
  const LabOrdersStatusesList = useSelector(getLabOrderStatuses)
  const error = useSelector(getLabOrdersError)
  const isLoading = useSelector(getLabOrdersIsLoading)
  const invoice = useSelector(getFinanceInvoice(invoiceId)) as Invoice

  const [vendorIdAfterPlacingToFinalize, setVendorIdAfterPlacingToFinalize] =
    useState<string>()
  const [createdOrderId, setCreatedOrderId] = useState<string | null>(null)
  const [ordersHash, setOrdersHash] = useState('')
  const [shouldFinalizeOrder, setShouldFinalizeOrder] = useState(false)
  const [labOrderProps, setLabOrderProps] = useState<LabOrderProps>()

  const [openSoapValidationDialog] = useDialog(DialogNames.SOAP_VALIDATION)
  const [openOTCValidationDialog] = useDialog(DialogNames.OTC_VALIDATION)
  const [openLabVendorProvidersDialog] = useDialog(
    DialogNames.LAB_VENDOR_PROVIDERS_DIALOG,
  )
  const [openAddNewLabTestDialog] = useDialog(DialogNames.ADD_NEW_LAB_TEST)
  const [openOrderResultsPreviewDialog] = useDialog(
    DialogNames.VIEW_ORDER_RESULTS_FRAME,
  )
  const [openOrderResultsEmailDialog] = useDialog(
    DialogNames.EMAIL_LAB_ORDER_RESULT,
  )
  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )
  const [openLabOrderAssignmentDialog] = useDialog(
    DialogNames.ASSIGNED_VET_VALIDATION,
  )
  const [openOrderResultsDialog] = useDialog(DialogNames.ORDER_RESULTS)
  const [openFinalizeLabOrderDialog] = useDialog(
    DialogNames.FINALIZE_LAB_ORDER,
    onClose,
  )
  const [openErrorAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const actualOrderIds = Object.keys(ordersMap || {})
  const actualOrdersHash = actualOrderIds.sort().join('|')

  useEffect(() => {
    if (actualOrdersHash && ordersHash !== actualOrdersHash) {
      const oldOrderIds = ordersHash.split('|')
      const idsDiff = R.symmetricDifference(actualOrderIds, oldOrderIds)
      setCreatedOrderId(idsDiff[0])
      setOrdersHash(actualOrdersHash)
    }
  }, [actualOrdersHash])

  const finalizeAfterPlacing = useCloseAfterCreation(() => {
    const shouldFinalize = isOTC
      ? logIdsForInvoiceWithNoProducer.length === 0
      : true
    if (error) {
      openErrorAlert({
        iconType: AlertIconType.WARN,
        message: t('Errors:THE_ORDER_CANNOT_BE_PLACED'),
      })
    } else if (shouldFinalize) {
      setShouldFinalizeOrder(true)
    }
  }, getLabOrderIsPlacing)

  const setProducerIdToInvoiceLineItemCallback = useCallback(
    (producerId?: string) => {
      const { groups } = invoice || {}

      const setProducerIdToInvoiceLineItem: (
        lineItems: InvoiceLineItem[],
      ) => InvoiceLineItem[] = R.map((lineItem: InvoiceLineItem) =>
        lineItem.items
          ? {
              ...lineItem,
              items: setProducerIdToInvoiceLineItem(lineItem.items),
            }
          : lineItem.logType === OrderType.LAB_TEST &&
              logIdsForInvoiceWithNoProducer?.includes(lineItem.logId)
            ? { ...lineItem, producerId }
            : lineItem,
      )

      const updatedGroups = R.map(
        (item) => ({
          ...item,
          groupedItems: setProducerIdToInvoiceLineItem(item.groupedItems),
        }),
        groups,
      )

      dispatch(
        saveInvoice({
          ...invoice,
          groups: updatedGroups,
          client: { id: invoice.client },
          patient: { id: invoice.patient },
        }),
      )
    },
    [invoice?.groups?.length, logIdsForInvoiceWithNoProducer.length],
  )

  const handlePlaceOrder = (props: any) => {
    const { vendorId, labTests, options } = props || {}
    if (vendorId && labTests && options) {
      const action = isOTC
        ? placeAllLabOrders(vendorId, labTests, options)
        : placeLabOrder(soapId, vendorId, labTests, options)
      dispatch(action)
      finalizeAfterPlacing()
    }
  }

  useEffect(() => {
    if (soapsWithNoDoctor.length > 0 && isOTC) {
      openLabOrderAssignmentDialog({
        soapsWithNoDoctor,
        onRetry: () => {
          handlePlaceOrder(labOrderProps)
        },
      })
    }

    return () => {
      dispatch(setSoapsWithNoDoctor([]))
    }
  }, [soapsWithNoDoctor.length])

  useEffect(() => {
    if (logIdsForInvoiceWithNoProducer.length > 0 && isOTC) {
      openLabOrderAssignmentDialog({
        logIdsForInvoiceWithNoProducer,
        onRetry: (producerId?: string) =>
          setProducerIdToInvoiceLineItemCallback(producerId),
      })
    }
    return () => {
      dispatch(setLogIdsForInvoiceWithNoProducer([]))
    }
  }, [logIdsForInvoiceWithNoProducer.length])

  const onPlaceLabOrder = (
    vendorId: string,
    labTests: LabOrderLabTest[],
    options: LabOrderOptions,
  ) => {
    dispatch(setSoapsWithNoDoctor([]))
    const props = { vendorId, labTests, options }
    setLabOrderProps(props)
    handlePlaceOrder(props)
  }

  const handleAddLabTest = (vendorId: string, state: string) => {
    openAddNewLabTestDialog({ vendorId, state })
  }

  const onFinalizeOrder = ({
    soapId: orderSoapId,
    vendorId,
    id,
    statusId,
    iframeUrl,
    businessId,
  }: LabOrder) => {
    openFinalizeLabOrderDialog({
      soapId: orderSoapId,
      soapBusinessId: businessId,
      vendorId,
      orderId: id,
      statusId,
      url: iframeUrl,
      invoiceId,
    })
  }

  useEffect(() => {
    if (
      !vendorIdAfterPlacingToFinalize ||
      !shouldFinalizeOrder ||
      !createdOrderId
    ) {
      return
    }

    const createdOrder = ordersMap?.[createdOrderId] || {}
    const { iframeUrl, statusId } = createdOrder

    setVendorIdAfterPlacingToFinalize(undefined)
    setShouldFinalizeOrder(false)

    if (iframeUrl) {
      onFinalizeOrder(createdOrder)
    } else if (statusId && !iframeUrl && onClose) {
      // NOTE: for some Lab vendors there is no iframe finalizing flow
      onClose()
    }
  }, [shouldFinalizeOrder, createdOrderId])

  useEffect(() => {
    if (
      isOTC &&
      logIdsForInvoiceWithNoProducer.length === 0 &&
      shouldFinalizeOrder
    ) {
      dispatch(
        saveInvoice({
          ...invoice,
          client: { id: invoice.client },
          patient: { id: invoice.patient },
        }),
      )
      if (onClose) {
        onClose()
      }
    }
  }, [logIdsForInvoiceWithNoProducer.length, shouldFinalizeOrder, isOTC])

  const handlePlaceLabOrder = (
    vendorId: string,
    labTests: LabOrderLabTest[],
    deviceSerialNumber: string | undefined,
  ) => {
    const vendorName = Utils.getConstantName(vendorId, LabVendors)
    const isZnLabVendor = vendorName === LabVendorNames.ZN_LABS

    const setZnLabVendorIfNeeded = (
      onOk: (znlabsProviderIdentifier?: string) => void,
    ) => {
      if (isZnLabVendor) {
        if (doctor?.znlabsProviderIdentifier) {
          onOk(doctor.znlabsProviderIdentifier)
        } else {
          openLabVendorProvidersDialog({ onOk })
        }
      } else {
        onOk()
      }
    }

    const proceedToPlace = (znLabsProviderIdentifier?: string) => {
      const options: LabOrderOptions = { deviceSerialNumber }

      if (znLabsProviderIdentifier) {
        options.znLabsProviderIdentifier = znLabsProviderIdentifier
      }

      onPlaceLabOrder(vendorId, labTests, options)
      setCreatedOrderId(null)
      setVendorIdAfterPlacingToFinalize(vendorId)
    }

    if (isOTC) {
      openOTCValidationDialog({
        clientId,
        patientId,
        onOk: () => setZnLabVendorIfNeeded(proceedToPlace),
      })
    } else {
      openSoapValidationDialog({
        clientId,
        patientId,
        onOk: () => setZnLabVendorIfNeeded(proceedToPlace),
      })
    }
  }

  const onPrintLabel = (order: LabOrder | undefined, vendorId: string) => {
    openPrintLabOrderLabelDialog({
      clientId,
      patientId,
      labOrderId: order?.labOrderId,
      orderDate: order?.date,
      vendorId,
    })
  }

  const onArtifactsPreview = (
    isResult: boolean,
    order: LabOrder,
    vendorId: string,
  ) => {
    if (!isResult) {
      return onFinalizeOrder(order || {})
    }

    const orderStatus = Utils.getConstantName(
      order.statusId,
      LabOrdersStatusesList,
    )

    return openOrderResultsPreviewDialog({
      pdfUrl: order.resultPdfUrl,
      vendorId,
      orderId: order.id,
      canComplete: orderStatus === LabOrdersStatus.RESULTS_RECEIVED,
      resultIdentifier: order.resultIdentifier,
      resultFrameUrl: order.resultIframeUrl,
    })
  }

  const onArtifactsPrint = (
    isResult: boolean,
    order: LabOrder,
    vendorId: string,
  ) => {
    const artifactUrl = isResult ? order.resultPdfUrl : order.pdfUrl

    openOrderResultsDialog({
      pdfUrl: artifactUrl,
      vendorId,
      orderId: order.id,
      canComplete: false,
      hasResults: isResult,
    })
  }

  const onPrintOrPreview = (
    isResult: boolean,
    shouldPrint: boolean,
    order: LabOrder,
    vendorId: string,
  ) =>
    shouldPrint
      ? onArtifactsPrint(isResult, order, vendorId)
      : onArtifactsPreview(isResult, order, vendorId)

  const onEmailResult = (order: LabOrder, vendorId: string) => {
    openOrderResultsEmailDialog({
      clientId,
      patientId,
      orderId: order?.id,
      vendorId,
      soapId,
    })
  }

  return (
    <PuiDialog
      aria-labelledby="order-management-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={t('Dialogs:ORDER_MANAGEMENT_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Grid container alignItems="center" direction="column" p={2}>
        {isLoading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : (
          Object.keys(labTestsMap).map((vendorId) => {
            const {
              [LabTestGroupKeys.READY_FOR_ORDER]: readyForOrder,
              [LabTestGroupKeys.NOT_READY_FOR_ORDER]: notReadyForOrder,
              ...ordersLabTests
            } = labTestsMap[vendorId]
            const labVendorName = Utils.getConstantName(vendorId, LabVendors)
            const onAddLab = isOTC
              ? undefined
              : (stateId: string) => handleAddLabTest(vendorId, stateId)

            const isIdexx =
              labVendorName === LabVendorNames.IDEXX ||
              labVendorName === LabVendorNames.IDEXX_ES
            const hasIntegration = hasIntegrationMap[vendorId]
            return (
              <LabSection
                hasIntegration={hasIntegration}
                invoiceId={invoiceId}
                key={vendorId}
                labTestsMap={ordersLabTests as Record<string, Order[]>}
                name={labVendorName}
                notReadyForOrder={notReadyForOrder}
                ordersMap={ordersMap}
                readyForOrder={readyForOrder}
                showDevices={isIdexx && hasIntegration}
                vendorId={vendorId}
                onAddLab={onAddLab}
                onCancelOrder={(orderId: string) =>
                  onCancelOrder(vendorId, orderId)
                }
                onEmailResult={onEmailResult}
                onFinalizeOrder={onFinalizeOrder}
                onPlaceLabOrder={(
                  labTests: LabOrderLabTest[],
                  deviceSerialNumber: string | undefined,
                ) =>
                  handlePlaceLabOrder(vendorId, labTests, deviceSerialNumber)
                }
                onPrintLabel={(order?: LabOrder) =>
                  onPrintLabel(order, vendorId)
                }
                onPrintOrPreview={onPrintOrPreview}
              />
            )
          })
        )}
      </Grid>
    </PuiDialog>
  )
}

export default OrderManagementDialog
