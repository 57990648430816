import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogNames from '~/constants/DialogNames'
import { dismissSoapAlert } from '~/store/actions/soap'
import { getSoapAlert } from '~/store/reducers/soap'
import useDialog from '~/utils/useDialog'

export const useSoapAlert = () => {
  const dispatch = useDispatch()

  const soapAlert = useSelector(getSoapAlert)

  const handleClose = () => {
    dispatch(dismissSoapAlert)
  }

  const [openSoapAlert] = useDialog(DialogNames.SOAP_ALERT, handleClose)

  useEffect(() => {
    if (soapAlert) {
      openSoapAlert({ message: soapAlert })
    }
  }, [soapAlert])
}
