import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import { BenchmarkingColumnsForSorting } from '~/constants/benchmarkingsTypes'
import { SortingOrder } from '~/constants/sortingOrders'
import i18n from '~/locales/i18n'
import {
  fetchBenchmarkingItems,
  fetchMoreBenchmarkingItems,
  getBenchmarkingItemsList,
  getBenchmarkingItemsLoading,
  getBenchmarkingItemsTotalCount,
  getMultipleBenchmarkingKeys,
} from '~/store/duck/benchmarking'
import { BenchmarkingKey } from '~/types'
import { getUrlSearchParam } from '~/utils'

import BenchmarkingActivationKeyDetails from './BenchmarkingActivationKeyDetails'

const columns = [
  {
    label: i18n.t('Common:PRACTICE'),
    highlight: true,
    prop: 'practiceName',
    width: 3,
    sort: BenchmarkingColumnsForSorting.PRACTICE_NAME,
  },
  {
    label: i18n.t('Common:NAME'),
    highlight: true,
    prop: (item: BenchmarkingKey) =>
      `${item.contactFirstName} ${item.contactLastName}`,
    width: 4,
    sort: BenchmarkingColumnsForSorting.CONTACT_NAME,
  },
  {
    label: i18n.t('Common:CONTACT_EMAIL'),
    highlight: true,
    prop: 'contactEmail',
    width: 3.5,
  },
  {
    label: i18n.t('Common:API_KEY'),
    highlight: true,
    prop: 'id',
    width: 3,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    highlight: true,
    prop: (item: BenchmarkingKey) =>
      item.active ? i18n.t('Common:YES') : i18n.t('Common:NO'),
    width: 1.5,
  },
]

export interface BenchmarkingTableComponentProps {
  activationKeyId?: string
  headerButtons: React.ReactNode
  onDetailsClose: () => void
}

const BenchmarkingTableComponent = ({
  headerButtons,
  activationKeyId,
  onDetailsClose,
}: BenchmarkingTableComponentProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const location = useLocation()
  const navigate = useNavigate()

  const [sortings, setSortings] = useState<Record<string, boolean>>({
    [BenchmarkingColumnsForSorting.PRACTICE_NAME]: false,
  })

  const benchmarkinKeysIdsList = useSelector(getBenchmarkingItemsList)
  const benchmarkingItems = useSelector(
    getMultipleBenchmarkingKeys(benchmarkinKeysIdsList),
  )
  const benchmarkingItemsTotalCount = useSelector(
    getBenchmarkingItemsTotalCount,
  )
  const isBenchmarkingItemsLoading = useSelector(getBenchmarkingItemsLoading)

  const search = getUrlSearchParam('query', location.search)
  const navigateToItem = (id: string) => {
    navigate(`/admin/general/benchmarking/${id}`)
  }

  const sortBy = Object.keys(sortings)[0]
  const sortingOrder = Object.values(sortings)[0]
    ? SortingOrder.DESC
    : SortingOrder.ASC

  useEffect(() => {
    dispatch(
      fetchBenchmarkingItems({
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        sortBy,
        sortingOrder,
      }),
    )
  }, [sortBy, sortingOrder])

  const isItemLoaded = (index: number) => Boolean(benchmarkingItems[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(
      fetchMoreBenchmarkingItems({
        from: startIndex,
        to: endIndex,
        sortBy,
        sortingOrder,
      }),
    )
  }

  const onApplySorting = (sort: string, value: boolean) => {
    setSortings({ [sort]: value })
  }

  return (
    <ExpandableTable
      Expander={BenchmarkingActivationKeyDetails}
      headerButtons={headerButtons}
      isLoading={isBenchmarkingItemsLoading}
      itemId={activationKeyId}
      list={benchmarkingItems}
      searchTerm={search}
      title={t('Common:BENCHMARKING_API_ACCESS')}
      onClose={onDetailsClose}
      onSelected={navigateToItem}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        sortings={sortings}
        totalCount={benchmarkingItemsTotalCount}
        onApplySorting={onApplySorting}
      />
    </ExpandableTable>
  )
}

export default BenchmarkingTableComponent
