import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  PuiDialog,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import SpaceSelect from '../inputs/SpaceSelect'

interface SpaceSelectDialogProps extends BasePuiDialogProps {
  appointmentTypeId?: string
  classes?: ClassesType<typeof useStyles>
  handleSelectSpace: (prop: any) => void
  patientId?: string
}

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 150,
    },
    actions: {
      padding: theme.spacing(2),
    },
    warningIcon: {
      color: theme.colors.alertWarning,
      margin: theme.spacing(2),
      width: 32,
      height: 32,
    },
  }),
  { name: 'SpaceSelectDialog' },
)

const SpaceSelectDialog = ({
  classes: classesProp,
  open,
  appointmentTypeId,
  patientId,
  onClose,
  handleSelectSpace,
}: SpaceSelectDialogProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Dialogs'])

  const {
    fields: { spaceId },
  } = useFields([{ name: 'spaceId', initialValue: '' }])

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={!spaceId.value}
          loading={false}
          onClick={() => handleSelectSpace(spaceId.value)}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="space-select-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        actions: classes.actions,
      }}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <Grid container direction="column">
        <Grid container item justifyContent="center">
          <WarningIcon className={classes.warningIcon} />
        </Grid>
        <Text>{t('Dialogs:SPACE_SELECT_DIALOG_DESCRIPTION')}</Text>
        <SpaceSelect
          appointmentTypeId={appointmentTypeId}
          disabled={false}
          field={spaceId}
          margin="normal"
          patientId={patientId}
        />
      </Grid>
    </PuiDialog>
  )
}

export default SpaceSelectDialog
