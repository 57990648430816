import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'

import { isToday, isYesterday } from '~/utils/time'

import { formatWidgetDate } from './widgetUtils'

const useStyles = makeStyles(
  (theme) => ({
    today: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F5F9FA',
      },
    },
    todayGroup: {
      borderTop: `1px solid ${theme.colors.title}`,
    },
    yesterday: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FEF9F5',
      },
    },
    yesterdayGroup: {
      borderTop: `1px solid ${theme.colors.important}`,
    },
    dateGroup: {
      borderTop: `1px solid ${theme.colors.divider}`,
    },
  }),
  { name: 'useEnrichGroupedItemsByDate' },
)

export const addDateTitle = ({ dateProp, items, titleProp }) => {
  const addTitleToFirstItemInGroup = (title, groupItems) =>
    groupItems.map((item, index) => ({
      ...item,
      [titleProp]: index === 0 ? title : '',
    }))

  return R.pipe(
    R.groupBy((item) => formatWidgetDate(R.prop(dateProp, item))),
    R.toPairs,
    R.map(([key, groupItems]) => addTitleToFirstItemInGroup(key, groupItems)),
    R.flatten,
  )(items)
}

const useEnrichGroupedItemsByDate = ({ dateProp, items, titleProp }) => {
  const classes = useStyles()

  const groupedItems = addDateTitle({ dateProp, items, titleProp })

  const getRowClassName = R.cond([
    [
      (item) => isToday(item.date) && item.title,
      R.always(classNames(classes.today, classes.todayGroup)),
    ],
    [(item) => isToday(item.date), R.always(classes.today)],
    [
      (item) => isYesterday(item.date) && item.title,
      R.always(classNames(classes.yesterday, classes.yesterdayGroup)),
    ],
    [(item) => isYesterday(item.date), R.always(classes.yesterday)],
    [(item) => item.title, R.always(classes.dateGroup)],
    [R.T, R.empty],
  ])

  return groupedItems.map((item) => ({
    ...item,
    className: getRowClassName({
      date: item[dateProp],
      title: item[titleProp],
    }),
  }))
}

export default useEnrichGroupedItemsByDate
