export const FETCH_INVITATION = 'registration/FETCH_INVITATION'
export const FETCH_INVITATION_FAILURE = 'registration/FETCH_INVITATION_FAILURE'
export const FETCH_INVITATION_SUCCESS = 'registration/FETCH_INVITATION_SUCCESS'

export const INVITATION_ALREADY_ACCEPTED =
  'registration/INVITATION_ALREADY_ACCEPTED'
export const CLEAR_INVITATION_ALREADY_ACCEPTED =
  'registration/CLEAR_INVITATION_ALREADY_ACCEPTED'

export const FETCHING_LOCATION_DATA = 'registration/FETCHING_LOCATION_DATA'
export const REGISTER_BY_INVITE_REQUEST =
  'registration/REGISTER_BY_INVITE_REQUEST'
export const SET_USER_ALREADY_EXISTS = 'registration/SET_USER_ALREADY_EXISTS'
export const CLEAR_USER_ALREADY_EXISTS =
  'registration/CLEAR_USER_ALREADY_EXISTS'
export const SET_KEY_ALREADY_EXISTS = 'registration/SET_KEY_ALREADY_EXISTS'
export const CLEAR_KEY_ALREADY_EXISTS = 'registration/CLEAR_KEY_ALREADY_EXISTS'
export const REGISTRATION_FAILURE = 'registration/REGISTRATION_FAILURE'
export const REGISTRATION_REQUEST = 'registration/REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'registration/REGISTRATION_SUCCESS'
export const REMOVE_BUSINESS = 'registration/REMOVE_BUSINESS'
export const SAVE_PERSON = 'registration/SAVE_PERSON'
export const UPDATE_BUSINESS = 'registration/UPDATE_BUSINESS'
export const UPDATE_FINAL_QUESTIONS = 'registration/UPDATE_FINAL_QUESTION'
export const UPDATE_BUSINESS_CANDIDATE =
  'registration/UPDATE_BUSINESS_CANDIDATE'
export const UPDATE_MEMBER = 'registration/UPDATE_MEMBER'

export const VALIDATE_ACTIVATION_KEY = 'registration/VALIDATE_ACTIVATION_KEY'
export const VALIDATE_ACTIVATION_KEY_SUCCESS =
  'registration/VALIDATE_ACTIVATION_KEY_SUCCESS'
export const VALIDATE_ACTIVATION_KEY_FAILURE =
  'registration/VALIDATE_ACTIVATION_KEY_FAILURE'

export const CREATE_PERSONS = 'registration/CREATE_PERSONS'
