import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  ClassesType,
  Field,
  Nil,
  PuiBaseTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import { Minus, Plus } from '@pbt/pbt-ui-components/src/icons'

import NumericInput, { NumericInputProps } from './NumericInput'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    quantityInput3digits: {
      width: 32,
    },
    quantityInput6digits: {
      width: 60,
    },
    quantityInput6digitsWithAdornment: {
      width: 70,
    },
    quantityInputNegative3digits: {
      width: 37,
    },
    quantityInputNegative6digits: {
      width: 65,
    },
    quantityInputNegative6digitsWithAdornment: {
      width: 75,
    },
    noUnderline: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
    input: {
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    icon: {
      color: theme.colors.tabLabel,
    },
    hiddenButton: {
      opacity: 0,
    },
    iconButton: {
      width: 24,
      height: 24,
      padding: 0,
    },
    tooltip: {
      fontSize: '1.4rem',
    },
    label: {
      fontSize: '1.4rem',
    },
    quantityInputWithLabel: {
      marginTop: theme.spacing(-1),
    },
  }),
  { name: 'QuantityInput' },
)

export interface QuantityInputProps
  extends Omit<NumericInputProps, 'onChange'> {
  InputProps?: NumericInputProps['InputProps']
  allowDecimal?: boolean
  availableQuantity?: number | Nil
  className?: string
  classes?: ClassesType<typeof useStyles>
  'data-testid'?: string
  debounceMs?: number
  disableUnderline?: boolean
  disabled?: boolean
  field?: Field
  label?: string
  labelEnabled?: boolean
  margin?: NumericInputProps['margin']
  max?: number
  min?: number
  minReachedTooltipEnabled?: boolean
  minReachedTooltipText?: string
  onChange?: (value: number) => void
  showControls?: boolean
  value?: number
}

const QuantityInput = ({
  classes: classesProp,
  InputProps,
  className,
  showControls: showControlsProp = false,
  debounceMs = 0,
  disabled,
  disableUnderline,
  field,
  value: initialValue,
  onChange: initialOnChange,
  minReachedTooltipEnabled,
  minReachedTooltipText: minReachedTooltipTextProp,
  labelEnabled: labelEnabledProp,
  label,
  min = 1,
  max = 999999,
  margin,
  availableQuantity = 0,
  allowDecimal = false,
  'data-testid': dataTestId,
  ...rest
}: QuantityInputProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  const [showControls, setShowControls] = useState(showControlsProp)
  const [localValue, setLocalValue] = useState<number | ''>('')
  const [openTooltip, setOpenTooltip] = useState(false)

  const minReachedTooltipText =
    minReachedTooltipTextProp ||
    t('Tooltips:MIN_REACHED_TOOLTIP_OR_YOU_CAN_DELETE_ITEM', { min })

  const directedValue = field?.value ?? initialValue

  useEffect(() => {
    if (localValue !== directedValue) {
      setLocalValue(directedValue)
    }
  }, [directedValue])

  const propagateChanges = useDebouncedCallback(
    field?.setValue ?? initialOnChange ?? R.identity,
    debounceMs,
  )

  useEffect(() => {
    let timer: number | null = null
    if (openTooltip) {
      timer = window.setTimeout(() => {
        setOpenTooltip(false)
      }, 10000)
    }
    return () => {
      if (openTooltip && timer) {
        clearTimeout(timer)
      }
    }
  }, [openTooltip])

  const onChange = (newValue: number | '') => {
    const canIncrease = newValue <= max
    const canDecrease = newValue >= min

    if (canIncrease && canDecrease) {
      setLocalValue(newValue)
      propagateChanges(Number(newValue))
    } else if (!canIncrease) {
      setLocalValue(max)
      propagateChanges(max)
    } else if (!canDecrease) {
      setLocalValue(min)
      propagateChanges(min)
    }

    if (newValue < min) {
      setOpenTooltip(true)
    }
  }

  useEffect(() => {
    setShowControls(showControlsProp)
  }, [showControlsProp])

  const onPlusClick = () => {
    const fractionLength = allowDecimal ? 2 : 0
    onChange(Utils.round(Number(localValue) + 1, fractionLength) as number)
  }

  const onMinusClick = () => {
    const fractionLength = allowDecimal ? 2 : 0
    onChange(Utils.round(Number(localValue) - 1, fractionLength) as number)
  }

  const labelEnabled = labelEnabledProp || label

  const show3Digits = max <= 999
  const show6Digits = max > 999

  const isAllowedNegative = min < 0

  const buildTestId = (modifier: string) =>
    dataTestId ? `QuantityInput-${dataTestId}-${modifier}` : undefined

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(className, classes.root)}
      wrap="nowrap"
      onMouseEnter={
        showControlsProp || disabled ? undefined : () => setShowControls(true)
      }
      onMouseLeave={
        showControlsProp || disabled ? undefined : () => setShowControls(false)
      }
    >
      <PuiBaseTooltip
        open={minReachedTooltipEnabled && openTooltip}
        tooltipText={minReachedTooltipText}
        onMouseLeave={() => {
          setOpenTooltip(false)
        }}
      >
        <IconButton
          className={classNames(classes.iconButton, {
            [classes.hiddenButton]: !showControls,
          })}
          component={disabled ? 'div' : 'button'}
          data-testid={buildTestId('minus')}
          disabled={disabled}
          size="large"
          onClick={onMinusClick}
        >
          <Minus className={classes.icon} />
        </IconButton>
      </PuiBaseTooltip>
      <NumericInput
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
          shrink: true,
        }}
        InputProps={{
          ...InputProps,
          classes: {
            input: classes.input,
          },
          endAdornment: availableQuantity ? (
            <InputAdornment position="end">/{availableQuantity}</InputAdornment>
          ) : undefined,
          // @ts-ignore
          'data-testid': buildTestId('input'),
        }}
        allowDecimal={allowDecimal}
        className={classNames({
          [classes.noUnderline]: disableUnderline,
          [classes.quantityInput3digits]: show3Digits,
          [classes.quantityInput6digits]: show6Digits,
          [classes.quantityInput6digitsWithAdornment]:
            show6Digits && availableQuantity,
          [classes.quantityInputWithLabel]: labelEnabled,
          [classes.quantityInputNegative3digits]:
            show3Digits && isAllowedNegative,
          [classes.quantityInputNegative6digits]:
            show6Digits && isAllowedNegative,
          [classes.quantityInputNegative6digitsWithAdornment]:
            show6Digits && availableQuantity && isAllowedNegative,
        })}
        disabled={disabled}
        field={{ ...field, setValue: onChange, value: localValue } as Field}
        label={labelEnabled && (label || t('Common:QUANTITY'))}
        margin={margin}
        max={max}
        min={min}
        onFocus={() => {
          setOpenTooltip(false)
        }}
        onMinReached={() => {
          setOpenTooltip(true)
        }}
        {...rest}
      />
      <IconButton
        className={classNames(classes.iconButton, {
          [classes.hiddenButton]: !showControls,
        })}
        data-testid={buildTestId('plus')}
        disabled={disabled}
        size="large"
        onClick={onPlusClick}
      >
        <Plus className={classes.icon} />
      </IconButton>
    </Grid>
  )
}

export default QuantityInput
