import * as R from 'ramda'

export const getLogIdFromTimelineAppoitment = R.pipe(
  R.prop('entries'),
  R.head,
  R.propOr([], 'products'),
  R.find(R.has('logId')),
  R.prop('logId'),
)

export const getLogTypeFromTimelineAppoitment = R.pipe(
  R.prop('entries'),
  R.head,
  R.propOr([], 'products'),
  R.find(R.has('logType')),
  R.prop('logType'),
)

export const getLogIdFromTimelineAppoitmentEntry = R.pipe(
  R.prop('products'),
  R.find(R.has('logId')),
  R.prop('logId'),
)

export const getLogTypeFromTimelineAppoitmentEntry = R.pipe(
  R.prop('products'),
  R.find(R.has('logType')),
  R.prop('logType'),
)
