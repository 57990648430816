import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { fetchClient } from '~/store/actions/clients'
import { useShouldDisplaySmsConsent } from '~/store/hooks/clients'
import { getClientIsLoading } from '~/store/reducers/clients'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import NewClientDetailsEdit, {
  NewClientDetailsEditHandle,
} from '../new-client/NewClientDetailsEdit'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    button: {
      minWidth: 150,
    },
  }),
  { name: 'ClientDetailsEditDialog' },
)

interface ClientDetailsEditDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
}

const ClientDetailsEditDialog = ({
  open,
  clientId,
  onClose,
}: ClientDetailsEditDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clientIsLoading = useSelector(getClientIsLoading)
  const { t } = useTranslation('Common')

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onClose,
    getClientIsLoading,
  )
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  const clientRef = useRef<NewClientDetailsEditHandle>(null)

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchClient({
          clientId,
          withGlobalClientPreferences: shouldDisplaySmsConsent,
        }),
      )
    }
  }, [clientId, shouldDisplaySmsConsent])

  const handleSave = () => clientRef.current?.update()

  const getHasUnsavedChanges = () =>
    Boolean(clientRef.current?.hasUnsavedChanges())

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: handleSave,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={clientIsLoading}
          loading={clientIsLoading}
          onClick={handleSave}
        >
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="client-details-edit-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={getHasUnsavedChanges}
      open={open}
      scroll="paper"
      title={t('Common:CLIENT_DETAILS')}
      onClose={onClose}
    >
      <NewClientDetailsEdit
        withDialogWrapper
        clientId={clientId}
        ref={clientRef}
        renderCoparents={false}
        onOk={setCloseAfterCreationOn}
      />
    </PuiDialog>
  )
}

export default ClientDetailsEditDialog
