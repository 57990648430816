/* eslint-disable simple-import-sort/imports */
// english namespaces imports
import AbbreviationsEnUS from './en-US/Abbreviations.json'
import AdminEnUS from './en-US/Admin.json'
import AuthEnUS from './en-US/Auth.json'
import BusinessesEnUS from './en-US/Businesses.json'
import ClientsEnUS from './en-US/Clients.json'
import CommonEnUS from './en-US/Common.json'
import CommunicationsEnUS from './en-US/Communications.json'
import ConstantsEnUS from './en-US/Constants.json'
import DialogsEnUS from './en-US/Dialogs.json'
import ErrorsEnUS from './en-US/Errors.json'
import InvoicesEnUS from './en-US/Invoices.json'
import FinanceEnUS from './en-US/Finance.json'
import LabTestsEnUS from './en-US/LabTests.json'
import LandingEnUS from './en-US/Landing.json'
import MedicalHistoryEnUS from './en-US/MedicalHistory.json'
import MembershipsEnUS from './en-US/Memberships.json'
import MenuEnUS from './en-US/Menu.json'
import MockEnUS from './en-US/Mock.json'
import NotificationsEnUS from './en-US/Notifications.json'
import PageTitlesEnUs from './en-US/PageTitles.json'
import PaymentsEnUS from './en-US/Payments.json'
import PluralsEnUS from './en-US/Plurals.json'
import PricesEnUS from './en-US/Prices.json'
import ProfileEnUS from './en-US/Profile.json'
import RegistrationEnUS from './en-US/Registration.json'
import RemindersEnUS from './en-US/Reminders.json'
import SearchEnUS from './en-US/Search.json'
import SoapEnUS from './en-US/Soap.json'
import TasksEnUS from './en-US/Tasks.json'
import TermsAndConditionsUSAEnUS from './en-US/TermsAndConditionsUSA.json'
import TermsAndConditionsUSAEulaV2EnUS from './en-US/TermsAndConditionsUSAEulaV2.json'
import TermsAndConditionsUSAEulaV3EnUS from './en-US/TermsAndConditionsUSAEulaV3.json'
import TermsAndConditionsSpainEnUS from './en-US/TermsAndConditionsSpain.json'
import PrivacyNoticeUSAEnUS from './en-US/PrivacyNoticeUSA.json'
import PrivacyNoticeSpainEnUS from './en-US/PrivacyNoticeSpain.json'
import TimeEnUS from './en-US/Time.json'
import TimeTableEnUS from './en-US/TimeTable.json'
import TooltipsEnUS from './en-US/Tooltips.json'
import UtilsEnUS from './en-US/Utils.json'
import ValidationsEnUS from './en-US/Validations.json'
import WatcherEnUS from './en-US/Watcher.json'
import WellnessPlansEnUS from './en-US/WellnessPlans.json'

// spanish namespaces imports
import AbbreviationsEsES from './es-ES/Abbreviations.json'
import AdminEsES from './es-ES/Admin.json'
import AuthEsES from './es-ES/Auth.json'
import BusinessesEsES from './es-ES/Businesses.json'
import ClientsEsES from './es-ES/Clients.json'
import CommonEsES from './es-ES/Common.json'
import CommunicationsEsES from './es-ES/Communications.json'
import ConstantsEsES from './es-ES/Constants.json'
import DialogsEsES from './es-ES/Dialogs.json'
import ErrorsEsES from './es-ES/Errors.json'
import InvoicesEsES from './es-ES/Invoices.json'
import FinanceEsES from './es-ES/Finance.json'
import LabTestsEsES from './es-ES/LabTests.json'
import LandingEsES from './es-ES/Landing.json'
import MedicalHistoryEsES from './es-ES/MedicalHistory.json'
import MembershipsEsES from './es-ES/Memberships.json'
import MenuEsES from './es-ES/Menu.json'
import MockEsES from './es-ES/Mock.json'
import NotificationsEsES from './es-ES/Notifications.json'
import PageTitlesEsES from './es-ES/PageTitles.json'
import PaymentsEsES from './es-ES/Payments.json'
import PluralsEsES from './es-ES/Plurals.json'
import PricesEsES from './es-ES/Prices.json'
import ProfileEsES from './es-ES/Profile.json'
import RegistrationEsES from './es-ES/Registration.json'
import RemindersEsES from './es-ES/Reminders.json'
import SearchEsES from './es-ES/Search.json'
import SoapEsES from './es-ES/Soap.json'
import TasksEsES from './es-ES/Tasks.json'
import TermsAndConditionsUSAEsES from './es-ES/TermsAndConditionsUSA.json'
import TermsAndConditionsUSAEulaV2EsES from './es-ES/TermsAndConditionsUSAEulaV2.json'
import TermsAndConditionsSpainEsES from './es-ES/TermsAndConditionsSpain.json'
import PrivacyNoticeUSAEsES from './es-ES/PrivacyNoticeUSA.json'
import PrivacyNoticeSpainEsES from './es-ES/PrivacyNoticeSpain.json'
import TimeEsES from './es-ES/Time.json'
import TimeTableEsES from './es-ES/TimeTable.json'
import TooltipsEsES from './es-ES/Tooltips.json'
import UtilsEsES from './es-ES/Utils.json'
import ValidationsEsES from './es-ES/Validations.json'
import WatcherEsES from './es-ES/Watcher.json'
import WellnessPlansEsES from './es-ES/WellnessPlans.json'

// spanish (castelian) namespaces imports
import AbbreviationsCaES from './ca-ES/Abbreviations.json'
import AdminCaES from './ca-ES/Admin.json'
import AuthCaES from './ca-ES/Auth.json'
import BusinessesCaES from './ca-ES/Businesses.json'
import ClientsCaES from './ca-ES/Clients.json'
import CommonCaES from './ca-ES/Common.json'
import CommunicationsCaES from './ca-ES/Communications.json'
import ConstantsCaES from './ca-ES/Constants.json'
import DialogsCaES from './ca-ES/Dialogs.json'
import ErrorsCaES from './ca-ES/Errors.json'
import InvoicesCaES from './ca-ES/Invoices.json'
import FinanceCaES from './ca-ES/Finance.json'
import LabTestsCaES from './ca-ES/LabTests.json'
import LandingCaES from './ca-ES/Landing.json'
import MedicalHistoryCaES from './ca-ES/MedicalHistory.json'
import MembershipsCaES from './ca-ES/Memberships.json'
import MenuCaES from './ca-ES/Menu.json'
import MockCaES from './ca-ES/Mock.json'
import NotificationsCaES from './ca-ES/Notifications.json'
import PageTitlesCaES from './ca-ES/PageTitles.json'
import PaymentsCaES from './ca-ES/Payments.json'
import PluralsCaES from './ca-ES/Plurals.json'
import PricesCaES from './ca-ES/Prices.json'
import ProfileCaES from './ca-ES/Profile.json'
import RegistrationCaES from './ca-ES/Registration.json'
import RemindersCaES from './ca-ES/Reminders.json'
import SearchCaES from './ca-ES/Search.json'
import SoapCaES from './ca-ES/Soap.json'
import TasksCaES from './ca-ES/Tasks.json'
import TermsAndConditionsUSACaES from './ca-ES/TermsAndConditionsUSA.json'
import TermsAndConditionsUSAEulaV2CaES from './ca-ES/TermsAndConditionsUSAEulaV2.json'
import TermsAndConditionsSpainCaES from './ca-ES/TermsAndConditionsSpain.json'
import PrivacyNoticeUSACaES from './ca-ES/PrivacyNoticeUSA.json'
import PrivacyNoticeSpainCaES from './ca-ES/PrivacyNoticeSpain.json'
import TimeCaES from './ca-ES/Time.json'
import TimeTableCaES from './ca-ES/TimeTable.json'
import TooltipsCaES from './ca-ES/Tooltips.json'
import UtilsCaES from './ca-ES/Utils.json'
import ValidationsCaES from './ca-ES/Validations.json'
import WatcherCaES from './ca-ES/Watcher.json'
import WellnessPlansCaES from './ca-ES/WellnessPlans.json'

// portuguese namespaces imports
import AbbreviationsPtPT from './pt-PT/Abbreviations.json'
import AdminPtPT from './pt-PT/Admin.json'
import AuthPtPT from './pt-PT/Auth.json'
import BusinessesPtPT from './pt-PT/Businesses.json'
import ClientsPtPT from './pt-PT/Clients.json'
import CommonPtPT from './pt-PT/Common.json'
import CommunicationsPtPT from './pt-PT/Communications.json'
import ConstantsPtPT from './pt-PT/Constants.json'
import DialogsPtPT from './pt-PT/Dialogs.json'
import ErrorsPtPT from './pt-PT/Errors.json'
import InvoicesPtPT from './pt-PT/Invoices.json'
import FinancePtPT from './pt-PT/Finance.json'
import LabTestsPtPT from './pt-PT/LabTests.json'
import LandingPtPT from './pt-PT/Landing.json'
import MedicalHistoryPtPT from './pt-PT/MedicalHistory.json'
import MembershipsPtPT from './pt-PT/Memberships.json'
import MenuPtPT from './pt-PT/Menu.json'
import MockPtPT from './pt-PT/Mock.json'
import NotificationsPtPT from './pt-PT/Notifications.json'
import PageTitlesPtPT from './pt-PT/PageTitles.json'
import PaymentsPtPT from './pt-PT/Payments.json'
import PluralsPtPT from './pt-PT/Plurals.json'
import PricesPtPT from './pt-PT/Prices.json'
import ProfilePtPT from './pt-PT/Profile.json'
import RegistrationPtPT from './pt-PT/Registration.json'
import RemindersPtPT from './pt-PT/Reminders.json'
import SearchPtPT from './pt-PT/Search.json'
import SoapPtPT from './pt-PT/Soap.json'
import TasksPtPT from './pt-PT/Tasks.json'
import TermsAndConditionsUSAPtPT from './pt-PT/TermsAndConditionsUSA.json'
import TermsAndConditionsUSAEulaV2PtPT from './pt-PT/TermsAndConditionsUSAEulaV2.json'
import TermsAndConditionsSpainPtPT from './pt-PT/TermsAndConditionsSpain.json'
import PrivacyNoticeUSAPtPT from './pt-PT/PrivacyNoticeUSA.json'
import PrivacyNoticeSpainPtPT from './pt-PT/PrivacyNoticeSpain.json'
import TimePtPT from './pt-PT/Time.json'
import TimeTablePtPT from './pt-PT/TimeTable.json'
import TooltipsPtPT from './pt-PT/Tooltips.json'
import UtilsPtPT from './pt-PT/Utils.json'
import ValidationsPtPT from './pt-PT/Validations.json'
import WatcherPtPT from './pt-PT/Watcher.json'
import WellnessPlansPtPT from './pt-PT/WellnessPlans.json'

// portuguese (brazil) namespaces imports
import AbbreviationsPtBR from './pt-BR/Abbreviations.json'
import AdminPtBR from './pt-BR/Admin.json'
import AuthPtBR from './pt-BR/Auth.json'
import BusinessesPtBR from './pt-BR/Businesses.json'
import ClientsPtBR from './pt-BR/Clients.json'
import CommonPtBR from './pt-BR/Common.json'
import CommunicationsPtBR from './pt-BR/Communications.json'
import ConstantsPtBR from './pt-BR/Constants.json'
import DialogsPtBR from './pt-BR/Dialogs.json'
import ErrorsPtBR from './pt-BR/Errors.json'
import InvoicesPtBR from './pt-BR/Invoices.json'
import FinancePtBR from './pt-BR/Finance.json'
import LabTestsPtBR from './pt-BR/LabTests.json'
import LandingPtBR from './pt-BR/Landing.json'
import MedicalHistoryPtBR from './pt-BR/MedicalHistory.json'
import MembershipsPtBR from './pt-BR/Memberships.json'
import MenuPtBR from './pt-BR/Menu.json'
import MockPtBR from './pt-BR/Mock.json'
import NotificationsPtBR from './pt-BR/Notifications.json'
import PaymentsPtBR from './pt-BR/Payments.json'
import PageTitlesPtBR from './pt-BR/PageTitles.json'
import PluralsPtBR from './pt-BR/Plurals.json'
import PricesPtBR from './pt-BR/Prices.json'
import ProfilePtBR from './pt-BR/Profile.json'
import RegistrationPtBR from './pt-BR/Registration.json'
import RemindersPtBR from './pt-BR/Reminders.json'
import SearchPtBR from './pt-BR/Search.json'
import SoapPtBR from './pt-BR/Soap.json'
import TasksPtBR from './pt-BR/Tasks.json'
import TermsAndConditionsUSAPtBR from './pt-BR/TermsAndConditionsUSA.json'
import TermsAndConditionsUSAEulaV2PtBR from './pt-BR/TermsAndConditionsUSAEulaV2.json'
import TermsAndConditionsSpainPtBR from './pt-BR/TermsAndConditionsSpain.json'
import PrivacyNoticeUSAPtBR from './pt-BR/PrivacyNoticeUSA.json'
import PrivacyNoticeSpainPtBR from './pt-BR/PrivacyNoticeSpain.json'
import TimePtBR from './pt-BR/Time.json'
import TimeTablePtBR from './pt-BR/TimeTable.json'
import TooltipsPtBR from './pt-BR/Tooltips.json'
import UtilsPtBR from './pt-BR/Utils.json'
import ValidationsPtBR from './pt-BR/Validations.json'
import WatcherPtBR from './pt-BR/Watcher.json'
import WellnessPlansPtBR from './pt-BR/WellnessPlans.json'

export const TRANSLATIONS_EN_US = {
  Abbreviations: AbbreviationsEnUS,
  Admin: AdminEnUS,
  Auth: AuthEnUS,
  Businesses: BusinessesEnUS,
  Clients: ClientsEnUS,
  Common: CommonEnUS,
  Communications: CommunicationsEnUS,
  Constants: ConstantsEnUS,
  Dialogs: DialogsEnUS,
  Errors: ErrorsEnUS,
  Invoices: InvoicesEnUS,
  Finance: FinanceEnUS,
  LabTests: LabTestsEnUS,
  Landing: LandingEnUS,
  MedicalHistory: MedicalHistoryEnUS,
  Memberships: MembershipsEnUS,
  Menu: MenuEnUS,
  Mock: MockEnUS,
  Notifications: NotificationsEnUS,
  PageTitles: PageTitlesEnUs,
  Payments: PaymentsEnUS,
  Plurals: PluralsEnUS,
  Prices: PricesEnUS,
  Profile: ProfileEnUS,
  Registration: RegistrationEnUS,
  Reminders: RemindersEnUS,
  Search: SearchEnUS,
  Soap: SoapEnUS,
  Tasks: TasksEnUS,
  TermsAndConditionsUSA: TermsAndConditionsUSAEnUS,
  TermsAndConditionsUSAEulaV2: TermsAndConditionsUSAEulaV2EnUS,
  TermsAndConditionsUSAEulaV3: TermsAndConditionsUSAEulaV3EnUS,
  TermsAndConditionsSpain: TermsAndConditionsSpainEnUS,
  PrivacyNoticeUSA: PrivacyNoticeUSAEnUS,
  PrivacyNoticeSpain: PrivacyNoticeSpainEnUS,
  Time: TimeEnUS,
  TimeTable: TimeTableEnUS,
  Tooltips: TooltipsEnUS,
  Utils: UtilsEnUS,
  Validations: ValidationsEnUS,
  Watcher: WatcherEnUS,
  WellnessPlans: WellnessPlansEnUS,
}

export const TRANSLATIONS_ES_ES = {
  Abbreviations: AbbreviationsEsES,
  Admin: AdminEsES,
  Auth: AuthEsES,
  Businesses: BusinessesEsES,
  Clients: ClientsEsES,
  Common: CommonEsES,
  Communications: CommunicationsEsES,
  Constants: ConstantsEsES,
  Dialogs: DialogsEsES,
  Errors: ErrorsEsES,
  Invoices: InvoicesEsES,
  Finance: FinanceEsES,
  LabTests: LabTestsEsES,
  Landing: LandingEsES,
  MedicalHistory: MedicalHistoryEsES,
  Memberships: MembershipsEsES,
  Menu: MenuEsES,
  Mock: MockEsES,
  Notifications: NotificationsEsES,
  PageTitles: PageTitlesEsES,
  Payments: PaymentsEsES,
  Plurals: PluralsEsES,
  Prices: PricesEsES,
  Profile: ProfileEsES,
  Registration: RegistrationEsES,
  Reminders: RemindersEsES,
  Search: SearchEsES,
  Soap: SoapEsES,
  Tasks: TasksEsES,
  TermsAndConditionsUSA: TermsAndConditionsUSAEsES,
  TermsAndConditionsUSAEulaV2: TermsAndConditionsUSAEulaV2EsES,
  TermsAndConditionsSpain: TermsAndConditionsSpainEsES,
  PrivacyNoticeUSA: PrivacyNoticeUSAEsES,
  PrivacyNoticeSpain: PrivacyNoticeSpainEsES,
  Time: TimeEsES,
  TimeTable: TimeTableEsES,
  Tooltips: TooltipsEsES,
  Utils: UtilsEsES,
  Validations: ValidationsEsES,
  Watcher: WatcherEsES,
  WellnessPlans: WellnessPlansEsES,
}

export const TRANSLATIONS_CA_ES = {
  Abbreviations: AbbreviationsCaES,
  Admin: AdminCaES,
  Auth: AuthCaES,
  Businesses: BusinessesCaES,
  Clients: ClientsCaES,
  Common: CommonCaES,
  Communications: CommunicationsCaES,
  Constants: ConstantsCaES,
  Dialogs: DialogsCaES,
  Errors: ErrorsCaES,
  Invoices: InvoicesCaES,
  Finance: FinanceCaES,
  LabTests: LabTestsCaES,
  Landing: LandingCaES,
  MedicalHistory: MedicalHistoryCaES,
  Memberships: MembershipsCaES,
  Menu: MenuCaES,
  Mock: MockCaES,
  Notifications: NotificationsCaES,
  PageTitles: PageTitlesCaES,
  Payments: PaymentsCaES,
  Plurals: PluralsCaES,
  Prices: PricesCaES,
  Profile: ProfileCaES,
  Registration: RegistrationCaES,
  Reminders: RemindersCaES,
  Search: SearchCaES,
  Soap: SoapCaES,
  Tasks: TasksCaES,
  TermsAndConditionsUSA: TermsAndConditionsUSACaES,
  TermsAndConditionsUSAEulaV2: TermsAndConditionsUSAEulaV2CaES,
  TermsAndConditionsSpain: TermsAndConditionsSpainCaES,
  PrivacyNoticeUSA: PrivacyNoticeUSACaES,
  PrivacyNoticeSpain: PrivacyNoticeSpainCaES,
  Time: TimeCaES,
  TimeTable: TimeTableCaES,
  Tooltips: TooltipsCaES,
  Utils: UtilsCaES,
  Validations: ValidationsCaES,
  Watcher: WatcherCaES,
  WellnessPlans: WellnessPlansCaES,
}

export const TRANSLATIONS_PT_PT = {
  Abbreviations: AbbreviationsPtPT,
  Admin: AdminPtPT,
  Auth: AuthPtPT,
  Businesses: BusinessesPtPT,
  Clients: ClientsPtPT,
  Common: CommonPtPT,
  Communications: CommunicationsPtPT,
  Constants: ConstantsPtPT,
  Dialogs: DialogsPtPT,
  Errors: ErrorsPtPT,
  Invoices: InvoicesPtPT,
  Finance: FinancePtPT,
  LabTests: LabTestsPtPT,
  Landing: LandingPtPT,
  MedicalHistory: MedicalHistoryPtPT,
  Memberships: MembershipsPtPT,
  Menu: MenuPtPT,
  Mock: MockPtPT,
  Notifications: NotificationsPtPT,
  PageTitles: PageTitlesPtPT,
  Payments: PaymentsPtPT,
  Plurals: PluralsPtPT,
  Prices: PricesPtPT,
  Profile: ProfilePtPT,
  Registration: RegistrationPtPT,
  Reminders: RemindersPtPT,
  Search: SearchPtPT,
  Soap: SoapPtPT,
  Tasks: TasksPtPT,
  TermsAndConditionsUSA: TermsAndConditionsUSAPtPT,
  TermsAndConditionsUSAEulaV2: TermsAndConditionsUSAEulaV2PtPT,
  TermsAndConditionsSpain: TermsAndConditionsSpainPtPT,
  PrivacyNoticeUSA: PrivacyNoticeUSAPtPT,
  PrivacyNoticeSpain: PrivacyNoticeSpainPtPT,
  Time: TimePtPT,
  TimeTable: TimeTablePtPT,
  Tooltips: TooltipsPtPT,
  Utils: UtilsPtPT,
  Validations: ValidationsPtPT,
  Watcher: WatcherPtPT,
  WellnessPlans: WellnessPlansPtPT,
}

export const TRANSLATIONS_PT_BR = {
  Abbreviations: AbbreviationsPtBR,
  Admin: AdminPtBR,
  Auth: AuthPtBR,
  Businesses: BusinessesPtBR,
  Clients: ClientsPtBR,
  Common: CommonPtBR,
  Communications: CommunicationsPtBR,
  Constants: ConstantsPtBR,
  Dialogs: DialogsPtBR,
  Errors: ErrorsPtBR,
  Invoices: InvoicesPtBR,
  Finance: FinancePtBR,
  LabTests: LabTestsPtBR,
  Landing: LandingPtBR,
  MedicalHistory: MedicalHistoryPtBR,
  Memberships: MembershipsPtBR,
  Menu: MenuPtBR,
  Mock: MockPtBR,
  Notifications: NotificationsPtBR,
  PageTitles: PageTitlesPtBR,
  Payments: PaymentsPtBR,
  Plurals: PluralsPtBR,
  Prices: PricesPtBR,
  Profile: ProfilePtBR,
  Registration: RegistrationPtBR,
  Reminders: RemindersPtBR,
  Search: SearchPtBR,
  Soap: SoapPtBR,
  Tasks: TasksPtBR,
  TermsAndConditionsUSA: TermsAndConditionsUSAPtBR,
  TermsAndConditionsUSAEulaV2: TermsAndConditionsUSAEulaV2PtBR,
  TermsAndConditionsSpain: TermsAndConditionsSpainPtBR,
  PrivacyNoticeUSA: PrivacyNoticeUSAPtBR,
  PrivacyNoticeSpain: PrivacyNoticeSpainPtBR,
  Time: TimePtBR,
  TimeTable: TimeTablePtBR,
  Tooltips: TooltipsPtBR,
  Utils: UtilsPtBR,
  Validations: ValidationsPtBR,
  Watcher: WatcherPtBR,
  WellnessPlans: WellnessPlansPtBR,
}
