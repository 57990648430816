import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClientPatientSnapshotWidgetConstant,
  Defaults,
  LanguageUtils,
  Nil,
} from '@pbt/pbt-ui-components'

import ExpandedWidget from '~/components/dashboard/landing/widgets/ExpandedWidget'
import FeatureToggle from '~/constants/featureToggle'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { fetchProblemCatalog } from '~/store/actions/problems'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import { clearWidgetsData, fetchWidgetsData } from '~/store/duck/landing'
import {
  getClientPatientSnapshotWidgets,
  getFeatureToggle,
} from '~/store/reducers/constants'

import AppointmentsSnapshot from './AppointmentsSnapshot/AppointmentsSnapshot'
import CommunicationsSnapshot from './CommunicationsSnapshot'
import DiagnosesSnapshot from './DiagnosesSnapshot/DiagnosesSnapshot'
import EstimatesSnapshot from './EstimatesSnapshot'
import LabsSnapshot from './LabsSnapshot'
import PrepaidItemsSnapshot from './PrepaidItemsSnapshot'
import PrescriptionsSnapshot from './PrescriptionsSnapshot/PrescriptionsSnapshot'
import RemindersSnapshot from './RemindersSnapshot/RemindersSnapshot'
import VaccinesSnapshot from './VaccinesSnapshot'
import VitalsSnapshot from './VitalsSnapshot'

const useStyles = makeStyles(
  (theme) => ({
    snapshot: {
      padding: theme.spacing(1),
    },
    expandedWidget: {
      width: '100%',
      position: 'relative',
      margin: theme.spacing(1),
    },
    expandedWidgetHeader: {
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'SnapshotsComponent' },
)

type SnapshotListItem = {
  Component: React.JSXElementConstructor<any>
  type: SnapshotsAliasTypes
}

const INITIAL_ROWS_NUMBER = 5

const SnapshotsToLoad = [
  SnapshotsAliasTypes.Reminders,
  SnapshotsAliasTypes.Diagnoses,
  SnapshotsAliasTypes.Vitals,
  SnapshotsAliasTypes.Prescriptions,
  SnapshotsAliasTypes.Appointments,
]

const Snapshots: SnapshotListItem[] = [
  { Component: RemindersSnapshot, type: SnapshotsAliasTypes.Reminders },
  { Component: DiagnosesSnapshot, type: SnapshotsAliasTypes.Diagnoses },
  { Component: DiagnosesSnapshot, type: SnapshotsAliasTypes.Problems },
  { Component: PrescriptionsSnapshot, type: SnapshotsAliasTypes.Prescriptions },
  { Component: AppointmentsSnapshot, type: SnapshotsAliasTypes.Appointments },
  { Component: LabsSnapshot, type: SnapshotsAliasTypes.Labs },
  { Component: VitalsSnapshot, type: SnapshotsAliasTypes.Vitals },
  { Component: EstimatesSnapshot, type: SnapshotsAliasTypes.Estimates },
  { Component: PrepaidItemsSnapshot, type: SnapshotsAliasTypes.PrepaidItems },
  {
    Component: CommunicationsSnapshot,
    type: SnapshotsAliasTypes.Communications,
  },
  { Component: VaccinesSnapshot, type: SnapshotsAliasTypes.Vaccines },
]

interface SnapshotsComponentProps {
  clientId: string
  patientId: string
}

const SnapshotsComponent = ({
  clientId,
  patientId,
}: SnapshotsComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Clients')

  const [expandedSnapshotType, setExpandedSnapshotType] =
    useState<SnapshotsAliasTypes>()
  const [rowsCount, setRowsCount] = useState<number | undefined>(
    INITIAL_ROWS_NUMBER,
  )

  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const ClientPatientSnapshotWidgets: ClientPatientSnapshotWidgetConstant[] =
    useSelector(getClientPatientSnapshotWidgets)

  const isMasterProblemsWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.MASTER_PROBLEMS_WIDGET),
  )

  const getSnapshot = (type: SnapshotsAliasTypes | Nil) =>
    R.find(R.propEq('alias', type), ClientPatientSnapshotWidgets)
  const getSnapshotName = (type: SnapshotsAliasTypes | Nil) =>
    type === SnapshotsAliasTypes.Diagnoses && isMasterProblemsWidgetEnabled
      ? t('Clients:PROBLEMS_WIDGET.TITLE')
      : LanguageUtils.getTranslatedFieldName(getSnapshot(type) || {})

  const pollingIntervalRef = useRef<number>()

  const loadWidgetsData = (
    widgets: string[],
    quiet: boolean | undefined = false,
  ) => {
    const [diagnosisWidget, otherWidgets] = R.partition(
      R.equals(SnapshotsAliasTypes.Diagnoses as string),
    )(widgets)
    if (isMasterProblemsWidgetEnabled && diagnosisWidget) {
      dispatch(
        fetchWidgetsData([SnapshotsAliasTypes.Problems], {
          quiet,
          landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
          patientId,
          pageSize: 150,
        }),
      )
      dispatch(fetchProblemCatalog(patientId))
    }
    const keysChunks = R.splitEvery(
      3,
      isMasterProblemsWidgetEnabled ? otherWidgets : widgets,
    )
    keysChunks.forEach((chunk) =>
      dispatch(
        fetchWidgetsData(chunk, {
          quiet,
          landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
          patientId,
        }),
      ),
    )
  }

  const stopPollingInterval = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = undefined
    }
  }

  const startPollingWidgetsData = () => {
    stopPollingInterval()
    pollingIntervalRef.current = window.setInterval(
      () => loadWidgetsData(SnapshotsToLoad, true),
      Defaults.REFRESH_INTERVAL,
    )
  }

  const loadWidgetsDataAndStartPolling = () => {
    loadWidgetsData(SnapshotsToLoad)
    startPollingWidgetsData()
  }

  useEffect(() => {
    if (!hasOpenDialogs) {
      loadWidgetsDataAndStartPolling()
    }

    return () => {
      stopPollingInterval()
    }
  }, [patientId, hasOpenDialogs])

  useEffect(
    () => () => {
      dispatch(clearWidgetsData(LandingType.CLIENT_AND_PATIENT_SNAPSHOTS))
    },
    [patientId],
  )

  const isLargeScreen = useMediaQuery('(min-width:1920px)')
  const isSm = useMediaQuery<Theme>((theme) => theme.breakpoints.only('sm'))
  const columnsCount = isSm ? 1 : isLargeScreen ? 3 : 2

  const isSnapshotVisible = ({ type }: SnapshotListItem) =>
    R.any((snapshot) => snapshot.alias === type, ClientPatientSnapshotWidgets)

  const indexedSnapshots = Snapshots.map((item, index) => ({
    index,
    ...item,
  })).filter(isSnapshotVisible)

  const snapshotColumns = Object.values(
    R.groupBy((item) => `${item.index % columnsCount}`, indexedSnapshots),
  )

  const snapshotComponentProps = {
    clientId,
    patientId,
    expandedSnapshotType,
    rowsCount,
  }

  const expandedSnapshot = Snapshots.find(
    R.propEq('type', expandedSnapshotType),
  )

  const expandedWidgetComponent = expandedSnapshotType
    ? expandedSnapshot?.Component
    : undefined

  const onExpandWidget = (type: SnapshotsAliasTypes) => {
    setExpandedSnapshotType(type)
    setRowsCount(undefined)
  }

  const onCompressWidget = () => {
    setExpandedSnapshotType(undefined)
    setRowsCount(INITIAL_ROWS_NUMBER)
  }

  return (
    <Grid container px={1} wrap="nowrap">
      {expandedWidgetComponent ? (
        <ExpandedWidget
          classes={{
            root: classes.expandedWidget,
            header: classes.expandedWidgetHeader,
          }}
          name={getSnapshotName(expandedSnapshotType)}
          widgetComponent={expandedWidgetComponent}
          widgetComponentProps={snapshotComponentProps}
          onClose={() => onCompressWidget()}
        />
      ) : (
        <>
          {snapshotColumns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid container direction="column" key={index}>
              {column.map(({ Component, type }) => (
                <Grid item className={classes.snapshot} key={type}>
                  <Component
                    name={getSnapshotName(type)}
                    onExpand={() => onExpandWidget(type)}
                    {...snapshotComponentProps}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </>
      )}
    </Grid>
  )
}

export default SnapshotsComponent
