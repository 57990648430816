import * as R from 'ramda'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  createImportSession,
  createImportSessionFailure,
  createImportTask,
  createImportTaskFailure,
  createImportTaskSuccess,
  deleteMigrationSessionFile,
  deleteMigrationSessionFileFailure,
  deleteMigrationSessionFileSuccess,
  fetchMigrationExporterUrl,
  fetchMigrationExporterUrlFailure,
  fetchMigrationExporterUrlSuccess,
  fetchMigrationImportExceptions,
  fetchMigrationImportExceptionsFailure,
  fetchMigrationImportExceptionsSuccess,
  fetchMigrationImportRequests,
  fetchMigrationImportRequestsFailure,
  fetchMigrationImportRequestsSuccess,
  fetchMigrationImportTasks,
  fetchMigrationImportTasksFailure,
  fetchMigrationImportTasksSuccess,
  fetchMigrationSessionDetails,
  fetchMigrationSessionDetailsSuccess,
  fetchMigrationSessionFiles,
  fetchMigrationSessionFilesFailure,
  fetchMigrationSessionFilesSuccess,
  fetchMigrationSessions,
  fetchMigrationSessionsFailure,
  fetchMigrationSessionsSuccess,
  fetchMigrationSupportedEntities,
  fetchMigrationSupportedEntitiesFailure,
  fetchMigrationSupportedEntitiesSuccess,
  fetchMoreMigrationImportRequests,
  fetchMoreMigrationImportRequestsFailure,
  fetchMoreMigrationImportRequestsSuccess,
  fetchMoreMigrationImportTasks,
  fetchMoreMigrationImportTasksFailure,
  fetchMoreMigrationImportTasksSuccess,
  updateMigrationImportExceptions,
  updateMigrationImportExceptionsFailure,
  updateMigrationImportExceptionsSuccess,
  updateMigrationSessionSettings,
  updateMigrationSessionSettingsFailure,
  updateMigrationSessionSettingsSuccess,
  updateMigrationSessionStage,
  updateMigrationSessionStageFailure,
  uploadMigrationSessionFile,
  uploadMigrationSessionFileFailure,
  uploadMigrationSessionFileSuccess,
} from '../duck/migrationV2'
import { getCurrentUser } from '../reducers/auth'
import requestAPI from './utils/requestAPI'

export function* fetchMigrationImportSessionsSaga({
  payload: { businessId },
}: ReturnType<typeof fetchMigrationSessions>) {
  try {
    const {
      result: list,
      entities: { importSession: map },
    } = yield* requestAPI(API.fetchV3ImportSessionsByBusinessId, businessId)
    yield put(fetchMigrationSessionsSuccess({ list, map }))
  } catch (error) {
    yield put(fetchMigrationSessionsFailure(error as ApiError))
  }
}

export function* fetchMigrationImportSessionDetailsSaga({
  payload: { sessionId },
}: ReturnType<typeof fetchMigrationSessionDetails>) {
  try {
    const sessionDetails = yield* requestAPI(
      API.fetchV3ImportSessionDetails,
      sessionId,
    )
    yield put(fetchMigrationSessionDetailsSuccess({ sessionDetails }))
  } catch (error) {
    yield put(fetchMigrationSessionsFailure(error as ApiError))
  }
}

export function* createImportSessionSaga({
  payload: { businessId, exporterPimsId, sourcePimsId },
}: ReturnType<typeof createImportSession>) {
  try {
    const { id: sessionId } = yield* requestAPI(
      API.createV3ImportSession,
      businessId,
      exporterPimsId,
      sourcePimsId,
    )
    yield put(fetchMigrationSessions({ businessId }))
    yield put(fetchMigrationSessionDetails({ sessionId }))
  } catch (error) {
    yield put(createImportSessionFailure({ error: error as ApiError }))
  }
}

export function* updateMigrationImportSessionStageSaga({
  payload: { sessionId, stageTo },
}: ReturnType<typeof updateMigrationSessionStage>) {
  try {
    const user: User = yield select(getCurrentUser)

    if (!user.email) {
      throw new Error('User must have email to make session updates')
    }
    yield* requestAPI(
      API.updateV3ImportSessionStage,
      user.email,
      sessionId,
      stageTo,
    )
    yield put(fetchMigrationSessionDetails({ sessionId }))
  } catch (error) {
    yield put(updateMigrationSessionStageFailure({ error: error as ApiError }))
  }
}

export function* updateMigrationImportSessionSettingsSaga({
  payload: { sessionId, settings },
}: ReturnType<typeof updateMigrationSessionSettings>) {
  try {
    const user: User = yield select(getCurrentUser)

    if (!user.email) {
      throw new Error('User must have email to make session settings updates')
    }
    const newSettings = yield* requestAPI(
      API.updateV3ImportSessionSettings,
      user.email,
      sessionId,
      settings,
    )
    yield put(updateMigrationSessionSettingsSuccess({ settings: newSettings }))
  } catch (error) {
    yield put(
      updateMigrationSessionSettingsFailure({ error: error as ApiError }),
    )
  }
}

export function* fetchMigrationImportSessionFilesSaga({
  payload: { sessionId },
}: ReturnType<typeof fetchMigrationSessionFiles>) {
  try {
    const sessionFiles = yield* requestAPI(
      API.fetchV3ImportSessionFiles,
      sessionId,
    )
    yield put(fetchMigrationSessionFilesSuccess({ sessionFiles }))
  } catch (error) {
    yield put(fetchMigrationSessionFilesFailure({ error: error as ApiError }))
  }
}

export function* uploadMigrationImportSessionFileSaga({
  payload: { sessionId, sessionFile },
}: ReturnType<typeof uploadMigrationSessionFile>) {
  try {
    const sessionFiles = yield* requestAPI(
      API.uploadV3ImportSessionFile,
      sessionId,
      sessionFile,
    )
    yield put(uploadMigrationSessionFileSuccess({ sessionFiles }))
  } catch (error) {
    yield put(uploadMigrationSessionFileFailure({ error: error as ApiError }))
  }
}

export function* deleteMigrationImportSessionFileSaga({
  payload: { sessionId, fileName, fileExtension },
}: ReturnType<typeof deleteMigrationSessionFile>) {
  try {
    const sessionFiles = yield* requestAPI(
      API.deleteV3ImportSessionFile,
      sessionId,
      fileName,
      fileExtension,
    )
    yield put(deleteMigrationSessionFileSuccess({ sessionFiles }))
  } catch (error) {
    yield put(deleteMigrationSessionFileFailure({ error: error as ApiError }))
  }
}
export function* fetchRhapsodyExporterUrlSaga({
  payload: { exporterType },
}: ReturnType<typeof fetchMigrationExporterUrl>) {
  try {
    const exporterUrl = yield* requestAPI(
      API.fetchV3RhapsodyExporterUrl,
      exporterType,
    )
    yield put(fetchMigrationExporterUrlSuccess({ exporterUrl }))
  } catch (error) {
    yield put(fetchMigrationExporterUrlFailure({ error: error as ApiError }))
  }
}

export function* fetchMigrationImportRequestsSaga({
  payload: { sessionId },
}: ReturnType<typeof fetchMigrationImportRequests>) {
  try {
    const response = yield* requestAPI(API.fetchV3ImportRequests, sessionId)
    const {
      entities: { importRequest: map },
      result: { data: list, totalCount },
    } = response
    yield put(fetchMigrationImportRequestsSuccess({ list, map, totalCount }))
  } catch (error) {
    yield put(fetchMigrationImportRequestsFailure({ error: error as ApiError }))
  }
}

export function* fetchMoreMigrationImportRequestsSaga({
  payload: { sessionId, from },
}: ReturnType<typeof fetchMoreMigrationImportRequests>) {
  try {
    const response = yield* requestAPI(
      API.fetchV3ImportRequests,
      sessionId,
      from,
    )
    const {
      entities: { importRequest: map },
      result: { data: list },
    } = response
    yield put(fetchMoreMigrationImportRequestsSuccess({ list, map }))
  } catch (error) {
    yield put(
      fetchMoreMigrationImportRequestsFailure({ error: error as ApiError }),
    )
  }
}

export function* fetchMigrationImportTasksSaga({
  payload: { importRequestId },
}: ReturnType<typeof fetchMigrationImportTasks>) {
  try {
    const response = yield* requestAPI(API.fetchV3ImportTasks, importRequestId)
    const {
      entities: { importTask: map },
      result: { data: list, totalCount },
    } = response
    yield put(fetchMigrationImportTasksSuccess({ list, map, totalCount }))
  } catch (error) {
    yield put(fetchMigrationImportTasksFailure({ error: error as ApiError }))
  }
}

export function* fetchMoreMigrationImportTasksSaga({
  payload: { importRequestId, from },
}: ReturnType<typeof fetchMoreMigrationImportTasks>) {
  try {
    const response = yield* requestAPI(
      API.fetchV3ImportTasks,
      importRequestId,
      from,
    )
    const {
      entities: { importTask: map },
      result: { data: list },
    } = response
    yield put(fetchMoreMigrationImportTasksSuccess({ list, map }))
  } catch (error) {
    yield put(
      fetchMoreMigrationImportTasksFailure({ error: error as ApiError }),
    )
  }
}

export function* fetchMigrationImportSupportedEntitiesSaga({
  payload: { sessionId },
}: ReturnType<typeof fetchMigrationSupportedEntities>) {
  try {
    const supportedEntities = yield* requestAPI(
      API.fetchV3ImportSupportedEntities,
      sessionId,
    )
    yield put(fetchMigrationSupportedEntitiesSuccess({ supportedEntities }))
  } catch (error) {
    yield put(
      fetchMigrationSupportedEntitiesFailure({ error: error as ApiError }),
    )
  }
}

export function* createMigrationImportTaskSaga({
  payload: { requestId, type, entities },
}: ReturnType<typeof createImportTask>) {
  try {
    yield* requestAPI(API.createV3ImportTask, requestId, type, entities)
    yield put(createImportTaskSuccess())
  } catch (error) {
    yield put(createImportTaskFailure({ error: error as ApiError }))
  }
}

export function* fetchMigrationImportExceptionsSaga({
  payload: { importSessionId, withResolved },
}: ReturnType<typeof fetchMigrationImportExceptions>) {
  try {
    const importExceptions = yield* requestAPI(
      API.fetchV3ImportExceptions,
      importSessionId,
      withResolved,
    )
    yield put(
      fetchMigrationImportExceptionsSuccess({
        map: importExceptions.entities.importMapping,
        list: importExceptions.result,
      }),
    )
  } catch (error) {
    yield put(
      fetchMigrationImportExceptionsFailure({ error: error as ApiError }),
    )
  }
}

export function* updateMigrationImportExceptionsSaga({
  payload: { draftExceptions },
}: ReturnType<typeof updateMigrationImportExceptions>) {
  try {
    yield all(
      R.map(
        (exception) =>
          call(() =>
            requestAPI(API.updateV3ImportExceptions, exception.id, exception),
          ),
        R.values(draftExceptions),
      ),
    )
    yield put(updateMigrationImportExceptionsSuccess())
  } catch (error) {
    yield put(
      updateMigrationImportExceptionsFailure({ error: error as ApiError }),
    )
  }
}

export default function* migrationSaga() {
  yield all([
    takeLatest(fetchMigrationSessions.type, fetchMigrationImportSessionsSaga),
    takeLatest(
      fetchMigrationSessionDetails.type,
      fetchMigrationImportSessionDetailsSaga,
    ),
    takeLatest(createImportSession.type, createImportSessionSaga),
    takeLatest(
      updateMigrationSessionStage.type,
      updateMigrationImportSessionStageSaga,
    ),
    takeLatest(
      updateMigrationSessionSettings.type,
      updateMigrationImportSessionSettingsSaga,
    ),
    takeLatest(
      fetchMigrationSessionFiles.type,
      fetchMigrationImportSessionFilesSaga,
    ),
    takeLatest(
      uploadMigrationSessionFile.type,
      uploadMigrationImportSessionFileSaga,
    ),
    takeLatest(
      deleteMigrationSessionFile.type,
      deleteMigrationImportSessionFileSaga,
    ),
    takeLatest(fetchMigrationExporterUrl.type, fetchRhapsodyExporterUrlSaga),
    takeLatest(
      fetchMigrationImportRequests.type,
      fetchMigrationImportRequestsSaga,
    ),
    takeLatest(
      fetchMoreMigrationImportRequests.type,
      fetchMoreMigrationImportRequestsSaga,
    ),
    takeLatest(fetchMigrationImportTasks.type, fetchMigrationImportTasksSaga),
    takeLatest(
      fetchMoreMigrationImportTasks.type,
      fetchMoreMigrationImportTasksSaga,
    ),
    takeLatest(
      fetchMigrationSupportedEntities.type,
      fetchMigrationImportSupportedEntitiesSaga,
    ),
    takeLatest(createImportTask.type, createMigrationImportTaskSaga),
    takeLatest(
      fetchMigrationImportExceptions.type,
      fetchMigrationImportExceptionsSaga,
    ),
    takeLatest(
      updateMigrationImportExceptions.type,
      updateMigrationImportExceptionsSaga,
    ),
  ])
}
