import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Nil } from '@pbt/pbt-ui-components'

import { getUrlSearchParam } from '~/utils'

import SearchFilterValueButton, {
  SearchFilterValueButtonProps,
} from '../buttons/SearchFilterValueButton'
import AdvancedFilters from './AdvancedFilters'

export interface ListSearchFilterPanelProps
  extends Partial<SearchFilterValueButtonProps> {
  searchCount: number
  searchFieldsQuery?: string | Nil
}

const ListSearchFilterPanel = ({
  searchCount,
  searchFieldsQuery,
  hideClearButton,
  ...rest
}: ListSearchFilterPanelProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const query = getUrlSearchParam('query', location.search) || ''
  const fieldsQuery = getUrlSearchParam('fieldsQuery', location.search) || ''

  return (
    <Grid container item alignItems="center" columnSpacing={2}>
      <Grid item>
        <SearchFilterValueButton
          hasFieldsQuery={Boolean(fieldsQuery) || Boolean(searchFieldsQuery)}
          hideClearButton={hideClearButton}
          searchCount={searchCount}
          searchTerm={query}
          onClearFilter={() => navigate(location.pathname)}
          {...rest}
        />
      </Grid>
      {searchFieldsQuery && (
        <AdvancedFilters searchFieldsQuery={searchFieldsQuery} />
      )}
    </Grid>
  )
}

export default ListSearchFilterPanel
