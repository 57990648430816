import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateUtils,
  Nil,
  NumberUtils,
  PuiCheckbox,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getFinanceInvoice } from '~/store/reducers/finance'
import { getPatient } from '~/store/reducers/patients'
import { isToday } from '~/utils/time'
import useDialog from '~/utils/useDialog'

import { getInvoiceDate, getInvoiceDueToPayNoFee } from '../invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    checkboxLabel: {
      margin: 0,
    },
    amountColumn: {},
  }),
  { name: 'PaymentInvoiceRow' },
)

interface PaymentInvoiceRowProps {
  invoiceId: string
  isChecked: boolean
  onCheck: (invoiceId: string) => void
}

const PaymentInvoiceRow = ({
  invoiceId,
  isChecked,
  onCheck,
}: PaymentInvoiceRowProps) => {
  const classes = useStyles()
  const invoice = useSelector(getFinanceInvoice(invoiceId))
  const patient = useSelector(getPatient(invoice?.patient))
  const { t } = useTranslation(['Common', 'Time'])

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const invoiceEvent = invoice?.event
  const invoiceDate = getInvoiceDate(invoice)
  const isTodayDate = isToday(invoiceDate)
  const dueToPayNoFee = getInvoiceDueToPayNoFee(invoice)

  const openAppointment = (eventId: string | Nil) => {
    openAppointmentDialog({
      appointmentId: eventId,
    })
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      px={1.5}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" wrap="nowrap" xs={3}>
        <PuiCheckbox
          checked={isChecked}
          labelProps={{
            className: classes.checkboxLabel,
          }}
          onChange={() => onCheck(invoiceId)}
        />
        <Text variant="body">
          {isTodayDate
            ? t('Time:LABEL.TODAY')
            : DateUtils.formatDate(invoiceDate)}
        </Text>
      </Grid>
      <Grid item className={classes.amountColumn} pr={4} xs={3}>
        <Text align="right" variant="body">
          {NumberUtils.formatMoney(dueToPayNoFee)}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <Text
          link
          variant="body"
          onClick={() => openAppointment(invoiceEvent?.id)}
        >
          {invoiceEvent?.type?.name || ''}
        </Text>
      </Grid>
      <Grid item xs={2}>
        <Text variant="body">{patient && patient.name}</Text>
      </Grid>
    </Grid>
  )
}

export default PaymentInvoiceRow
