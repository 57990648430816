import { ApiError } from '@pbt/pbt-ui-components'

import {
  WhiteboardScheduleData,
  WhiteboardTreatmentsFilters,
  WhiteboardTreatmentsScheduleData,
} from '~/types'

import {
  FETCH_WHITEBOARD,
  FETCH_WHITEBOARD_FAILURE,
  FETCH_WHITEBOARD_SUCCESS,
  FETCH_WHITEBOARD_TREATMENTS,
  FETCH_WHITEBOARD_TREATMENTS_FAILURE,
  FETCH_WHITEBOARD_TREATMENTS_IGNORED,
  FETCH_WHITEBOARD_TREATMENTS_SUCCESS,
  SET_WHITEBOARD_TREATMENTS_FILTERS,
} from './types/whiteboard'

export const fetchWhiteboard = (silent: boolean) => ({
  type: FETCH_WHITEBOARD,
  silent,
})
export const fetchWhiteboardSuccess = (schedules: WhiteboardScheduleData) => ({
  type: FETCH_WHITEBOARD_SUCCESS,
  ...schedules,
})
export const fetchWhiteboardFailure = (error: ApiError) => ({
  type: FETCH_WHITEBOARD_FAILURE,
  error,
})

export const fetchWhiteboardTreatments = (silent?: boolean) => ({
  type: FETCH_WHITEBOARD_TREATMENTS,
  silent,
})
export const fetchWhiteboardTreatmentsSuccess = (
  schedules: WhiteboardTreatmentsScheduleData,
) => ({
  type: FETCH_WHITEBOARD_TREATMENTS_SUCCESS,
  schedules,
})
export const fetchWhiteboardTreatmentsFailure = (error: ApiError) => ({
  type: FETCH_WHITEBOARD_TREATMENTS_FAILURE,
  error,
})
export const fetchWhiteboardTreatmentsIgnored = () => ({
  type: FETCH_WHITEBOARD_TREATMENTS_IGNORED,
})

export const setWhiteboardTreatmentsFilters = (
  filters: WhiteboardTreatmentsFilters,
) => ({
  type: SET_WHITEBOARD_TREATMENTS_FILTERS,
  filters,
})
