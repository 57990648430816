export const FETCH_SUPPORT_USERS_LIST = 'supportUsers/FETCH_SUPPORT_USERS_LIST'
export const FETCH_SUPPORT_USERS_LIST_SUCCESS =
  'supportUsers/FETCH_SUPPORT_USERS_LIST_SUCCESS'
export const FETCH_SUPPORT_USERS_LIST_FAILURE =
  'supportUsers/FETCH_SUPPORT_USERS_LIST_FAILURE'

export const FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST =
  'supportUsers/FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST'
export const FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_SUCCESS =
  'supportUsers/FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_FAILURE =
  'supportUsers/FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_FAILURE'

export const UPDATE_SUPPORT_USER_ROLES =
  'supportUsers/UPDATE_SUPPORT_USER_ROLES'
export const UPDATE_SUPPORT_USER_ROLES_SUCCESS =
  'supportUsers/UPDATE_SUPPORT_USER_ROLES_SUCCESS'
export const UPDATE_SUPPORT_USER_ROLES_FAILURE =
  'supportUsers/UPDATE_SUPPORT_USER_ROLES_FAILURE'
