import { Problem } from '~/types'

export const getProblems = (
  problemsMap: Record<string, Problem>,
  problemsList?: string[],
): string[] => {
  let res: string[] = []
  problemsList?.forEach((itemId: string) => {
    const problem = problemsMap[itemId]
    if (problem?.children?.length && !problem?.type) {
      res = [...res, ...getProblems(problemsMap, problem.children)]
    } else {
      res = [...res, problem.key]
    }
  })
  const setResults = new Set<string>(res)
  return [...setResults]
}
