import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton, Skeleton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import {
  AddButton,
  PermissionArea,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  LoveList as LoveListIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getClientPreferencesIsLoading } from '~/store/duck/clientPreferences'
import { getCRUDByArea } from '~/store/reducers/auth'
import { PatientPreferencesDisplayData } from '~/types'
import { ClientPreferencesDisplayData } from '~/types/entities/clientPreferences'

import { PreferencesDisplayRow } from './PreferencesDisplayRow'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
    },
    rootBorder: {
      border: theme.constants.tableBorder,
    },
    header: {
      width: '100%',
    },
    headerText: {
      color: theme.colors.title,
    },
    headerBorder: {
      borderBottom: theme.constants.tableBorder,
    },
    editIcon: {
      backgroundColor: 'inherit',
      boxShadow: 'none',
      color: theme.colors.editIconColor,
      cursor: 'pointer',
    },
    loveListIcon: {
      width: 16,
      height: 16,
      marginLeft: 4,
      fontSize: '1.2rem',
      border: `2px solid ${theme.colors.title}`,
      borderRadius: '50%',
      backgroundColor: theme.colors.title,
      fill: theme.colors.alertErrorText,
    },
  }),
  { name: 'PreferencesDisplayWidget' },
)

export interface PreferencesDisplayWidgetProps {
  headerText: string
  onEditPreferences?: () => void
  preferences?: ClientPreferencesDisplayData | PatientPreferencesDisplayData
  showInfoIcon?: boolean
}

function PreferencesDisplayWidget({
  headerText,
  preferences = {},
  showInfoIcon = false,
  onEditPreferences,
}: PreferencesDisplayWidgetProps) {
  const classes = useStyles()
  const { t } = useTranslation(['Clients'])

  const clientPreferencesIsLoading = useSelector(getClientPreferencesIsLoading)
  const { read: patientReadPermissions, update: patientUpdatePermissions } =
    useSelector(getCRUDByArea(PermissionArea.PATIENT))

  const categories = Object.keys(preferences).filter(
    (category) => preferences[category as keyof typeof preferences],
  )

  const hasPreferences = categories.length > 0

  return (
    <Grid
      container
      className={classNames(classes.root, {
        [classes.rootBorder]: preferences,
      })}
      p={0}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.header, {
          [classes.headerBorder]: preferences,
        })}
        flexWrap="nowrap"
        p={1}
      >
        <Grid container item>
          <Text strong className={classes.headerText}>
            {headerText}
          </Text>
          {showInfoIcon && (
            <TextWithTooltip
              tooltipText={
                <Text>
                  {t('Clients:CLIENT_PREFERENCES.DISPLAY.TOOLTIP')}
                  <LoveListIcon className={classes.loveListIcon} />
                </Text>
              }
            />
          )}
        </Grid>
        {hasPreferences && patientUpdatePermissions && onEditPreferences && (
          <IconButton size="small" onClick={onEditPreferences}>
            <EditIcon className={classes.editIcon} />
          </IconButton>
        )}
      </Grid>
      {!hasPreferences && onEditPreferences && patientUpdatePermissions && (
        <Grid container item p={1}>
          <AddButton
            addText={t('Clients:PATIENT_SECTION.BUTTON.ADD_PREFERENCES')}
            onAdd={onEditPreferences}
          />
        </Grid>
      )}
      {clientPreferencesIsLoading ? (
        <Skeleton
          data-testid="preferences-display-widget-loader"
          sx={{ m: 1 }}
          width="100%"
        />
      ) : (
        <>
          {hasPreferences && patientReadPermissions && (
            <Grid container item flexDirection="column">
              {categories.map((category) => {
                const { categoryTitle, subCategory } =
                  preferences[category as keyof typeof preferences]
                return (
                  <PreferencesDisplayRow
                    categoryTitle={categoryTitle}
                    key={categoryTitle}
                    subCategory={subCategory}
                  />
                )
              })}
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export { PreferencesDisplayWidget }
