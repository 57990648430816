import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getPatientId, getSoapId } from '~/store/reducers/soap'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import SoapWidget from '../SoapWidget'
import RemindersTable from './table/RemindersTable'

const RemindersWidgetUnlocked = () => {
  const { t } = useTranslation('Common')

  const soapId = useSelector(getSoapId)
  const patientId = useSelector(getPatientId)

  useWSTopic({
    wsTopic: WSTopics.REMINDERS,
    context: {
      retrySubscription: true,
      topicParams: {
        patientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_WIDGET_GROUPED_ITEM,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
      },
    },
    unsubscribe: true,
  })

  return (
    <SoapWidget id="reminders-widget" title={t('Common:REMINDERS')}>
      <RemindersTable />
    </SoapWidget>
  )
}

export default RemindersWidgetUnlocked
