import React from 'react'
import { useSelector } from 'react-redux'
import { all } from 'ramda'

import HeaderSelectItem, {
  HeaderSelectItemProps,
} from '~/components/common/HeaderSelectItem'
import SearchContext from '~/constants/searchContext'
import { useIsAreaIntegrated } from '~/store/hooks/externalIntegration'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { ContextAvailability } from '~/types'

const allTrue = all(Boolean)

export interface ToolbarContextSelectItemProps
  extends Omit<HeaderSelectItemProps, 'onSelect'> {
  availabilityRules: ContextAvailability
  context: string
  label: React.ReactNode
  onChange: (value: SearchContext) => void
  onOpen: (open: boolean) => void
}

const ToolbarContextSelectItem = ({
  context,
  availabilityRules,
  label,
  value,
  onChange,
  onOpen,
  ...rest
}: ToolbarContextSelectItemProps) => {
  const {
    permission: permissionsArea = null,
    integration: integrationArea = null,
    feature: featureName = null,
  } = availabilityRules || {}
  const contextReadPermissions = useSelector(
    getCRUDByArea(permissionsArea),
  ).read
  const isFeatureEnabled = useSelector(getFeatureToggle(featureName))
  const contextIntegrationEnabled = useIsAreaIntegrated(integrationArea)
  const contextEnabled = allTrue([
    integrationArea ? contextIntegrationEnabled : true,
    permissionsArea ? contextReadPermissions : true,
    featureName ? isFeatureEnabled : true,
  ])

  if (!contextEnabled) {
    return null
  }

  return (
    <HeaderSelectItem
      active={context === value}
      value={context}
      onBlur={() => onOpen(false)}
      onSelect={(selectedValue) => {
        onChange(selectedValue as SearchContext)
        onOpen(false)
      }}
      {...rest}
    >
      {label}
    </HeaderSelectItem>
  )
}

export default ToolbarContextSelectItem
