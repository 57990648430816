import { normalize } from 'normalizr'

import discountGroupsMock from './mocks/discountGroupsMock'
import * as Schema from './schemas'

export const fetchDiscountGroups = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentBusinessId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  businessId: string,
) => Promise.resolve(normalize(discountGroupsMock, Schema.discountGroupsArray))
