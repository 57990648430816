import { useSelector } from 'react-redux'
import { RoleBusinessCategory } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'

import { getCurrentBusiness } from '../reducers/auth'
import { getFeatureToggle } from '../reducers/constants'
import { getMainStaffRolesList, getStaffRolesList } from '../reducers/roles'

export const useMainStaffRoles = () => {
  const currentBusiness = useSelector(getCurrentBusiness)

  const isRemoteUsersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REMOTE_MEMBERS),
  )

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const mainStaffRoles = useSelector(
    isRemoteUsersEnabled ? getMainStaffRolesList : getStaffRolesList,
  )

  const businessCategory =
    isCvcRolesEnabled && currentBusiness?.omniChannel
      ? RoleBusinessCategory.CVC
      : RoleBusinessCategory.THIRD_PARTY

  const rolesFilteredByBusinessCategory = mainStaffRoles.filter(
    (role) =>
      role.businessCategory === businessCategory ||
      role.businessCategory === RoleBusinessCategory.ALL,
  )

  return rolesFilteredByBusinessCategory
}
