import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Input, InputLabel } from '@mui/material'
import * as R from 'ramda'
import { Defaults, Field, WellnessPlanVersion } from '@pbt/pbt-ui-components'

import {
  fetchMoreItemsForWellnessPlansList,
  fetchWellnessPlansList,
} from '~/store/actions/wellnessPlans'
import {
  getMultipleWellnessPlansVersions,
  getWellnessPlansIsFetchingList,
  getWellnessPlansVersionsList,
  getWellnessPlansVersionsTotalCount,
} from '~/store/reducers/wellnessPlans'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import InfiniteScrollSelect, {
  InfiniteScrollSelectProps,
} from './InfiniteScrollSelect'

export interface PlanVersionSelectProps
  extends Partial<InfiniteScrollSelectProps> {
  businessId: string
  field: Field
}

const PlanVersionSelect = ({
  businessId,
  field,
  ...rest
}: PlanVersionSelectProps) => {
  const dispatch = useDispatch()
  const list = useSelector(getWellnessPlansVersionsList)
  const listWithFieldValue = field.value ? R.uniq([...list, field.value]) : list
  const wellnessPlansVersions = useSelector(
    getMultipleWellnessPlansVersions(listWithFieldValue),
  )
  const totalCount: number = useSelector(getWellnessPlansVersionsTotalCount)
  const { t } = useTranslation('Common')

  const activeVersion = wellnessPlansVersions.find((item) => item.active)

  const makeSelectOptions = (items: WellnessPlanVersion[]) =>
    items.filter(Boolean).map((item) => ({
      id: item.id,
      name: t('Common:INPUTS.PLAN_VERSION_SELECT.VERSION_NUMBER', {
        versionNumber: item.versionNumber,
      }),
    }))

  const items = makeSelectOptions(wellnessPlansVersions)

  const onListFetched = () => {
    if (activeVersion?.id) {
      field.setValue(activeVersion.id)
    }
  }

  const setCallbackOnListFetched = useCloseAfterCreation(
    onListFetched,
    getWellnessPlansIsFetchingList,
  )

  const handleLoadItems = () => {
    dispatch(
      fetchWellnessPlansList({
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        businessId,
      }),
    )
  }

  useEffect(() => {
    if (!field.value) {
      setCallbackOnListFetched()
      handleLoadItems()
    }
  }, [])

  const handleLoadMore = () => {
    dispatch(
      fetchMoreItemsForWellnessPlansList({
        from: items.length,
        to: items.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        businessId,
      }),
    )
  }

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="plan-version-select">
        {t('Common:INPUTS.PLAN_VERSION_SELECT.PLAN_VERSION')}
      </InputLabel>
      <InfiniteScrollSelect
        field={field}
        input={<Input id="plan-version-select" />}
        items={items}
        loadMore={handleLoadMore}
        totalCount={totalCount}
        {...rest}
      />
    </FormControl>
  )
}

export default PlanVersionSelect
