import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmail from 'validator/lib/isEmail'
import {
  ErrorTooltip,
  MarketingStylesButton,
  PuiTextField,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    formContainer: {
      height: 207,
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3, 3, 4),
    },
    placeholder: {
      height: parseInt(theme.spacing(3), 10) - 1,
    },
    submit: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      marginTop: theme.spacing(3),
      width: '100%',
    },
    message: {
      lineHeight: '22px',
      marginTop: parseInt(theme.spacing(3), 10) - 4,
      marginLeft: '10%',
      marginRight: '10%',
      width: 'auto',
    },
  }),
  { name: 'ForgotPasswordForm' },
)

interface ForgotPasswordFormProps {
  errorMessage?: string
  isFetching?: boolean
  onSubmit: (email: string) => void
}

const ForgotPasswordForm = ({
  onSubmit,
  errorMessage = '',
  isFetching = false,
}: ForgotPasswordFormProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Auth', 'Common', 'Validations'])

  const [emailValidationError, setEmailValidationError] = useState('')
  const [email, setEmail] = useState('')
  const [touched, setTouched] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    setEmailValidationError('')
    if (!isFetching) {
      if (!email) {
        setEmailValidationError(t('Validations:NO_EMAIL'))
      } else if (!isEmail(email)) {
        setEmailValidationError(t('Validations:EMAIL_INVALID'))
      } else {
        onSubmit(email)
      }
    }
    return false
  }
  const isError = Boolean(!touched && (errorMessage || emailValidationError))
  return (
    <form noValidate className={classes.form} onSubmit={handleSubmit}>
      <Grid className={classes.formContainer}>
        <Text align="center" variant="hero2">
          {t('Common:NO_WORRIES')}
        </Text>
        <ErrorTooltip
          message={errorMessage || emailValidationError}
          open={isError}
        >
          <PuiTextField
            autoFocus
            autoComplete="email"
            disabled={isFetching}
            error={isError}
            id="email"
            label={t('Common:EMAIL')}
            type="email"
            value={email}
            onChange={(event) => {
              setTouched(true)
              setEmail(event.target.value.trim())
            }}
          />
        </ErrorTooltip>
        <Text align="center" className={classes.message}>
          {t('Auth:FORGOT_PASSWORD_FORM.DESCRIPTION')}
        </Text>
      </Grid>
      <div className={classes.placeholder} />

      <MarketingStylesButton className={classes.submit} type="submit">
        {isFetching
          ? `${t('Common:LOADING')}...`
          : t('Auth:FORGOT_PASSWORD_FORM.SEND_PASSWORD_RESET_LINK')}
      </MarketingStylesButton>
    </form>
  )
}

export default ForgotPasswordForm
