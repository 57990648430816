import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps } from '@pbt/pbt-ui-components'

import AppointmentNewClientConfirmation, {
  AppointmentNewClientConfirmationProps,
} from './AppointmentNewClientConfirmation'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 750,
      maxWidth: 750,
    },
  }),
  { name: 'AppointmentNewClientConfirmationDialog' },
)

export interface AppointmentNewClientConfirmationDialogProps
  extends PuiDialogProps,
    Omit<AppointmentNewClientConfirmationProps, 'onOk'> {
  onClose: () => void
}

const AppointmentNewClientConfirmationDialog = ({
  open,
  onClose,
  ...rest
}: AppointmentNewClientConfirmationDialogProps) => {
  const classes = useStyles()

  return (
    <PuiDialog
      aria-labelledby="appointment-new-client-confirmation-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <AppointmentNewClientConfirmation onOk={onClose} {...rest} />
    </PuiDialog>
  )
}

export default AppointmentNewClientConfirmationDialog
