import { useDispatch, useSelector } from 'react-redux'

import { fetchReminders, getReminderFilters } from '~/store/duck/reminders'
import {
  useGetIsCheckedItem,
  useHandleOrderRefill,
  useLogItemStateGetter,
  useOrderCheckItem,
  useOrderUncheckItem,
} from '~/store/hooks/orders'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

export const useReminderOrderItems = () => {
  const dispatch = useDispatch()
  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))
  const patientId = useSelector(getPatientId)
  const filters = useSelector(getReminderFilters)

  const refetchReminders = () => {
    if (patientId) {
      dispatch(
        fetchReminders({
          patientId,
          stateIds: filters.stateIds,
        }),
      )
    }
  }
  const refetchRemindersAfterToggleItem = useCloseAfterCreation(
    refetchReminders,
    getOrdersIsSending,
  )

  const logItemStateGetter = useLogItemStateGetter()

  const { handleSavePrescription, openPrescriptionDialog } =
    useHandleOrderRefill(
      client,
      logItemStateGetter,
      refetchRemindersAfterToggleItem,
    )

  const onCheckItem = useOrderCheckItem({
    handleSavePrescription,
    logItemStateGetter,
    openPrescriptionDialog,
  })

  const onUncheckItem = useOrderUncheckItem(refetchRemindersAfterToggleItem)

  const isCheckedItem = useGetIsCheckedItem()

  return {
    isCheckedItem,
    onCheckItem,
    onUncheckItem,
  }
}
