import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  MutationUpdateMonitorsAssignmentsArgs,
  SpaceMonitor,
} from '~/api/graphql/generated/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

type MonitorState = {
  assignedMonitors: SpaceMonitor[]
  error: string | Nil
  isGettingToken: boolean
  loading: boolean
  token: string | Nil
  unassignedMonitors: SpaceMonitor[]
}

const INITIAL_STATE: MonitorState = {
  assignedMonitors: [],
  unassignedMonitors: [],
  loading: false,
  error: null,
  token: null,
  isGettingToken: false,
}

const monitorsSlice = createSlice({
  name: 'unassignedMonitors',
  initialState: INITIAL_STATE,
  reducers: {
    fetchUnassignedMonitors: (state) => {
      state.loading = true
      state.error = null
    },
    fetchUnassignedMonitorsSuccess: (
      state,
      action: PayloadAction<SpaceMonitor[]>,
    ) => {
      state.loading = false
      state.unassignedMonitors = action.payload
    },
    fetchUnassignedMonitorsFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.loading = false
      state.error = getErrorMessage(action.payload.error)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchAssignedToSpaceMonitors: (state, action: PayloadAction<string>) => {
      state.loading = true
      state.error = null
    },
    fetchAssignedToSpaceMonitorsSuccess: (
      state,
      action: PayloadAction<SpaceMonitor[]>,
    ) => {
      state.loading = false
      state.assignedMonitors = action.payload
    },
    fetchAssignedToSpaceMonitorsFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.loading = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateMonitorsAssignments: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUpdateMonitorsAssignmentsArgs>,
    ) => {
      state.loading = true
      state.error = null
    },
    updateMonitorsAssignmentsSuccess: (
      state,
      action: PayloadAction<SpaceMonitor[]>,
    ) => {
      const { payload } = action
      const combinedMonitors = R.concat(
        state.assignedMonitors,
        state.unassignedMonitors,
      )
      const udpatedMonitors = R.pipe(
        R.concat(payload),
        R.uniqBy(R.prop('token')),
        R.map((monitor) => {
          const currentPayloadItem = R.find(
            R.propEq('token', R.prop('token', monitor)),
            payload,
          )
          return currentPayloadItem
            ? { ...monitor, assigned: R.prop('assigned', currentPayloadItem) }
            : monitor
        }),
      )(combinedMonitors)

      state.loading = false
      state.assignedMonitors = R.filter(
        R.propEq('assigned', true),
        udpatedMonitors,
      )
      state.unassignedMonitors = R.filter(
        R.propEq('assigned', false),
        udpatedMonitors,
      )
    },
    updateMonitorsAssignmentsFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.loading = false
      state.error = getErrorMessage(action.payload.error)
    },
    getInRoomSpaceMonitor: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ spaceId: string }>,
    ) => {
      state.isGettingToken = true
      state.error = null
      state.token = null
    },
    getInRoomSpaceMonitorSuccess: (
      state,
      action: PayloadAction<SpaceMonitor>,
    ) => {
      const { payload } = action
      state.isGettingToken = false
      state.token = R.prop('token', payload)

      if (!R.find(R.propEq('token', payload.token), state.assignedMonitors)) {
        state.assignedMonitors = R.concat([payload], state.assignedMonitors)
      }
    },
    getInRoomSpaceMonitorFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isGettingToken = false
      state.error = getErrorMessage(action.payload.error)
    },
  },
})

const { actions, reducer } = monitorsSlice

export const {
  fetchUnassignedMonitors,
  fetchUnassignedMonitorsSuccess,
  fetchUnassignedMonitorsFailure,
  fetchAssignedToSpaceMonitors,
  fetchAssignedToSpaceMonitorsSuccess,
  fetchAssignedToSpaceMonitorsFailure,
  updateMonitorsAssignments,
  updateMonitorsAssignmentsSuccess,
  updateMonitorsAssignmentsFailure,
  getInRoomSpaceMonitor,
  getInRoomSpaceMonitorSuccess,
  getInRoomSpaceMonitorFailure,
} = actions

export default reducer

export const getMonitorsState = (state: RootState): MonitorState =>
  state.monitor
export const getUnassignedMonitors = (state: RootState) =>
  getMonitorsState(state).unassignedMonitors
export const getAssignedToSpaceMonitors = (state: RootState) =>
  getMonitorsState(state).assignedMonitors
export const getMonitorsLoading = (state: RootState) =>
  getMonitorsState(state).loading
export const getCurrentMonitorToken = (state: RootState) =>
  getMonitorsState(state).token
export const getIsGettingToken = (state: RootState) =>
  getMonitorsState(state).isGettingToken
