import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { moment, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getPatient } from '~/store/reducers/patients'
import { getTimelineIsLoading } from '~/store/reducers/timeline'
import { TimelineItem } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      height: 40,
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  }),
  { name: 'TimelinePrettyEmptyPanel' },
)

const hasRecentEntries = (itemData: TimelineItem[] = []) => {
  if (!itemData[0]) {
    return false
  }

  const currentDate = moment()
  const firstEntryDate = moment(itemData[0].date)

  // recent === has entries withing last 1.5 years
  return currentDate.diff(firstEntryDate, 'month') < 18
}

export interface TimelinePrettyEmptyPanelProps {
  clientId: string
  itemData: TimelineItem[]
  patientId: string
}

const TimelinePrettyEmptyPanel = ({
  itemData,
  clientId,
  patientId,
}: TimelinePrettyEmptyPanelProps) => {
  const classes = useStyles()
  const patient = useSelector(getPatient(patientId))
  const isLoading = useSelector(getTimelineIsLoading)
  const { t } = useTranslation(['Common', 'Clients'])
  const patientName =
    patient?.name ||
    t('Clients:TIMELINE.TIMELINE_PRETTY_EMPTY_PANEL.PATIENT_NAME_FALLBACK')

  const [openVaccineHistoryDialog] = useDialog(DialogNames.VACCINE_HISTORY)
  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)
  const [openNoteHistoryDialog] = useDialog(DialogNames.NOTE_HISTORY)
  const [openAttachmentHistoryDialog] = useDialog(
    DialogNames.ATTACHMENT_HISTORY,
  )

  if (isLoading || hasRecentEntries(itemData)) {
    return null
  }

  return (
    <Grid container item className={classes.root} direction="column">
      <Grid item>
        <Text>
          {itemData.length
            ? t(
                'Clients:TIMELINE.TIMELINE_PRETTY_EMPTY_PANEL.DESCRIPTION_WITH_RECORDS',
                { patientName },
              )
            : t('Clients:TIMELINE.TIMELINE_PRETTY_EMPTY_PANEL.DESCRIPTION', {
                patientName,
              })}
        </Text>
      </Grid>
      <Grid item>
        <Fab
          className={classes.button}
          color="inherit"
          type="submit"
          variant="extended"
          onClick={() => openVaccineHistoryDialog({ clientId, patientId })}
        >
          {t('Common:VACCINE_HISTORY')}
        </Fab>
        <Fab
          className={classes.button}
          color="inherit"
          type="submit"
          variant="extended"
          onClick={() => openDiagnosisHistoryDialog({ clientId, patientId })}
        >
          {t('Common:DIAGNOSIS_HISTORY')}
        </Fab>
        <Fab
          className={classes.button}
          color="inherit"
          type="submit"
          variant="extended"
          onClick={() => openNoteHistoryDialog({ clientId, patientId })}
        >
          {t('Common:ADD_NOTE')}
        </Fab>
        <Fab
          className={classes.button}
          color="inherit"
          type="submit"
          variant="extended"
          onClick={() => openAttachmentHistoryDialog({ clientId, patientId })}
        >
          {t('Common:ADD_ATTACHMENT')}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default TimelinePrettyEmptyPanel
