import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { StateLabel, Text } from '@pbt/pbt-ui-components'

import { getSoapId } from '~/store/reducers/soap'
import { Estimate } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.success,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'EstimateName' },
)

interface EstimateNameProps {
  estimate: Estimate
}

const EstimateName = ({ estimate }: EstimateNameProps) => {
  const { t } = useTranslation(['Soap'])
  const classes = useStyles()
  const soapId = useSelector(getSoapId)

  if (!estimate) {
    return null
  }

  const isAddedCharges = estimate.assignedSoapDetails?.find(
    (item) => item.soapId === soapId && item.itemsCopied,
  )

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Grid item xs={isAddedCharges ? 7 : 12}>
        <Text noWrap variant="body2">
          {estimate.name}
        </Text>
      </Grid>
      <Grid item xs={isAddedCharges ? 5 : 0}>
        {isAddedCharges && (
          <StateLabel success className={classes.root} ml={1} variant="small">
            {t('Soap:ADDED_TO_CHARGES')}
          </StateLabel>
        )}
      </Grid>
    </Grid>
  )
}

export default EstimateName
