export const FETCH_ORDERS = 'orders/FETCH_ORDERS'
export const FETCH_ORDERS_SUCCESS = 'orders/FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAILURE = 'orders/FETCH_ORDERS_FAILURE'

export const FETCH_MORE_ORDERS = 'orders/FETCH_MORE_ORDERS'
export const FETCH_MORE_ORDERS_SUCCESS = 'orders/FETCH_MORE_ORDERS_SUCCESS'
export const FETCH_MORE_ORDERS_FAILURE = 'orders/FETCH_MORE_ORDERS_FAILURE'

export const FETCH_ORDERS_FILTERS = 'orders/FETCH_ORDERS_FILTERS'
export const FETCH_ORDERS_FILTERS_SUCCESS =
  'orders/FETCH_ORDERS_FILTERS_SUCCESS'
export const FETCH_ORDERS_FILTERS_FAILURE =
  'orders/FETCH_ORDERS_FILTERS_FAILURE'

export const UPDATE_FULL_LOG = 'orders/UPDATE_FULL_LOG'
export const UPDATE_LOG_SUCCESS = 'orders/UPDATE_LOG_SUCCESS'
export const UPDATE_LOG_FAILURE = 'orders/UPDATE_LOG_FAILURE'
export const CLEAR_ORDERS = 'orders/CLEAR_ORDERS'

export const FETCH_ORDER_DETAILS = 'orders/FETCH_ORDER_DETAILS'
export const FETCH_ORDER_DETAILS_SUCCESS = 'orders/FETCH_ORDER_DETAILS_SUCCESS'
export const FETCH_ORDER_DETAILS_FAILURE = 'orders/FETCH_ORDER_DETAILS_FAILURE'

export const FETCH_ORDER_DETAILS_GROUPED = 'orders/FETCH_ORDER_DETAILS_GROUPED'
export const FETCH_ORDER_DETAILS_GROUPED_SUCCESS =
  'orders/FETCH_ORDER_DETAILS_GROUPED_SUCCESS'
export const FETCH_ORDER_DETAILS_GROUPED_FAILURE =
  'orders/FETCH_ORDER_DETAILS_GROUPED_FAILURE'

export const CREATE_ORDER = 'orders/CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'orders/CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILURE = 'orders/CREATE_ORDER_FAILURE'

export const DECLINE_LAB_ORDER = 'orders/DECLINE_LAB_ORDER'
export const DECLINE_LAB_ORDER_SUCCESS = 'orders/DECLINE_LAB_ORDER_SUCCESS'
export const DECLINE_LAB_ORDER_FAILURE = 'orders/DECLINE_LAB_ORDER_FAILURE'
export const DECLINE_LAB_ORDER_ABOLISH = 'orders/DECLINE_LAB_ORDER_ABOLISH'

export const EDIT_ORDERS = 'orders/EDIT_ORDERS'
export const EDIT_ORDERS_SUCCESS = 'orders/EDIT_ORDERS_SUCCESS'
export const EDIT_ORDERS_FAILURE = 'orders/EDIT_ORDERS_FAILURE'

export const EDIT_ORDERS_STATUSES = 'orders/EDIT_ORDERS_STATUSES'
export const EDIT_ORDERS_STATUSES_SUCCESS =
  'orders/EDIT_ORDERS_STATUSES_SUCCESS'
export const EDIT_ORDERS_STATUSES_FAILURE =
  'orders/EDIT_ORDERS_STATUSES_FAILURE'

export const PARTIAL_EDIT_ORDER = 'orders/PARTIAL_EDIT_ORDER'
export const PARTIAL_EDIT_ORDER_SUCCESS = 'orders/PARTIAL_EDIT_ORDER_SUCCESS'
export const PARTIAL_EDIT_ORDER_FAILURE = 'orders/PARTIAL_EDIT_ORDER_FAILURE'
export const PARTIAL_EDIT_ORDER_ABOLISH = 'orders/PARTIAL_EDIT_ORDER_ABOLISH'

export const EDIT_ORDER = 'orders/EDIT_ORDER'
export const EDIT_ORDER_SUCCESS = 'orders/EDIT_ORDER_SUCCESS'
export const EDIT_ORDER_FAILURE = 'orders/EDIT_ORDER_FAILURE'
export const EDIT_ORDER_ABOLISH = 'orders/EDIT_ORDER_ABOLISH'

export const UPDATE_MULTIPLE_ORDERS = 'orders/UPDATE_MULTIPLE_ORDERS'

export const UPDATE_ORDER_STATUS = 'orders/UPDATE_ORDER_STATUS'
export const UPDATE_ORDER_STATUS_SUCCESS = 'orders/UPDATE_ORDER_STATUS_SUCCESS'
export const UPDATE_ORDER_STATUS_FAILURE = 'orders/UPDATE_ORDER_STATUS_FAILURE'

export const REMOVE_ORDERS = 'orders/REMOVE_ORDERS'
export const REMOVE_ORDERS_SUCCESS = 'orders/REMOVE_ORDERS_SUCCESS'
export const REMOVE_ORDERS_FAILURE = 'orders/REMOVE_ORDERS_FAILURE'

export const REMOVE_ORDER = 'orders/REMOVE_ORDER'
export const REMOVE_ORDER_SUCCESS = 'orders/REMOVE_ORDER_SUCCESS'
export const REMOVE_ORDER_FAILURE = 'orders/REMOVE_ORDER_FAILURE'

export const REMOVE_LAB_ORDER = 'orders/REMOVE_LAB_ORDER'
export const REMOVE_LAB_ORDER_ABOLISH = 'orders/REMOVE_LAB_ORDER_ABOLISH'

export const REMOVE_ORDER_UPDATE_PATIENT = 'orders/REMOVE_ORDER_UPDATE_PATIENT'
export const REMOVE_ORDER_UPDATE_PATIENT_SUCCESS =
  'orders/REMOVE_ORDER_UPDATE_PATIENT_SUCCESS'
export const REMOVE_ORDER_UPDATE_PATIENT_FAILURE =
  'orders/REMOVE_ORDER_UPDATE_PATIENT_FAILURE'

export const SEARCH_ORDERS = 'orders/SEARCH_ORDERS'
export const SEARCH_ORDERS_SUCCESS = 'orders/SEARCH_ORDERS_SUCCESS'
export const SEARCH_ORDERS_FAILURE = 'orders/SEARCH_ORDERS_FAILURE'

export const FETCH_MORE_FOR_SEARCH = 'orders/FETCH_MORE_FOR_SEARCH'
export const FETCH_MORE_FOR_SEARCH_SUCCESS =
  'orders/FETCH_MORE_FOR_SEARCH_SUCCESS'
export const FETCH_MORE_FOR_SEARCH_FAILURE =
  'orders/FETCH_MORE_FOR_SEARCH_FAILURE'

export const CLEAR_SEARCH_ORDERS = 'orders/CLEAR_SEARCH_ORDERS'

export const REMOVE_MULTIPLE_ORDERS = 'orders/REMOVE_MULTIPLE_ORDERS'

export const SET_PRESCRIPTION_FOR_PRINT = 'orders/SET_PRESCRIPTION_FOR_PRINT'
export const CLEAR_PRESCRIPTION_FOR_PRINT =
  'orders/CLEAR_PRESCRIPTION_FOR_PRINT'

export const SET_PRESCRIPTION_FOR_EMAIL = 'orders/SET_PRESCRIPTION_FOR_EMAIL'
export const CLEAR_PRESCRIPTION_FOR_EMAIL =
  'orders/CLEAR_PRESCRIPTION_FOR_EMAIL'

export const FETCH_PRESCRIPTION_PRINT_INFO =
  'orders/FETCH_PRESCRIPTION_PRINT_INFO'
export const FETCH_PRESCRIPTION_PRINT_INFO_FAILURE =
  'orders/FETCH_PRESCRIPTION_PRINT_INFO_FAILURE'

export const FETCH_PRESCRIPTION_EMAIL_TEMPLATE =
  'orders/FETCH_PRESCRIPTION_EMAIL_TEMPLATE'
export const FETCH_PRESCRIPTION_EMAIL_TEMPLATE_SUCCESS =
  'orders/FETCH_PRESCRIPTION_EMAIL_TEMPLATE_SUCCESS'
export const FETCH_PRESCRIPTION_EMAIL_TEMPLATE_FAILURE =
  'orders/FETCH_PRESCRIPTION_EMAIL_TEMPLATE_FAILURE'

export const CLEAR_PRESCRIPTION_EMAIL_TEMPLATE =
  'orders/CLEAR_PRESCRIPTION_EMAIL_TEMPLATE'

export const SIGN_PRESCRIPTION = 'orders/SIGN_PRESCRIPTION'
export const SIGN_PRESCRIPTION_SUCCESS = 'orders/SIGN_PRESCRIPTION_SUCCESS'
export const SIGN_PRESCRIPTION_FAILURE = 'orders/SIGN_PRESCRIPTION_FAILURE'

export const ORDER_BUNDLE = 'orders/ORDER_BUNDLE'
export const ORDER_BUNDLE_SUCCESS = 'orders/ORDER_BUNDLE_SUCCESS'
export const ORDER_BUNDLE_FAILURE = 'orders/ORDER_BUNDLE_FAILURE'

export const UNORDER_BUNDLE = 'orders/UNORDER_BUNDLE'
export const UNORDER_BUNDLE_SUCCESS = 'orders/UNORDER_BUNDLE_SUCCESS'
export const UNORDER_BUNDLE_FAILURE = 'orders/UNORDER_BUNDLE_FAILURE'

export const UNORDER_BUNDLE_WITHOUT_ORDER =
  'orders/UNORDER_BUNDLE_WITHOUT_ORDER'
export const UNORDER_BUNDLE_WITHOUT_ORDER_SUCCESS =
  'orders/UNORDER_BUNDLE_WITHOUT_ORDER_SUCCESS'
export const UNORDER_BUNDLE_WITHOUT_ORDER_FAILURE =
  'orders/UNORDER_BUNDLE_WITHOUT_ORDER_FAILURE'

export const UPDATE_CURRENT_ORDER_DETAILS =
  'orders/UPDATE_CURRENT_ORDER_DETAILS'
export const CLEAR_CURRENT_ORDER_DETAILS = 'orders/CLEAR_CURRENT_ORDER_DETAILS'

export const SET_ORDER_VALIDATION_ERROR_MESSAGE =
  'orders/SET_ORDER_VALIDATION_ERROR_MESSAGE'
export const CLEAR_ORDER_VALIDATION_ERROR_MESSAGE =
  'orders/CLEAR_ORDER_VALIDATION_ERROR_MESSAGE'
export const SET_ORDER_CAN_NOT_BE_REMOVED_MESSAGE =
  'orders/SET_ORDER_CAN_NOT_BE_REMOVED_MESSAGE'
export const CLEAR_ORDER_CAN_NOT_BE_REMOVED_MESSAGE =
  'orders/CLEAR_ORDER_CAN_NOT_BE_REMOVED_MESSAGE'

export const SUBTRACT_REFILL_REMAINS = 'orders/SUBTRACT_REFILL_REMAINS'

export const CANCEL_REFILLS = 'orders/CANCEL_REFILLS'
export const CANCEL_REFILLS_SUCCESS = 'orders/CANCEL_REFILLS_SUCCESS'
export const CANCEL_REFILLS_FAILURE = 'orders/CANCEL_REFILLS_FAILURE'

export const REFRESH_ORDERS = 'orders/REFRESH_ORDERS'

export const CREATE_UNIFIED_ORDER = 'orders/CREATE_UNIFIED_ORDER'
export const CREATE_UNIFIED_ORDER_SUCCESS =
  'orders/CREATE_UNIFIED_ORDER_SUCCESS'
export const CREATE_UNIFIED_ORDER_FAILURE =
  'orders/CREATE_UNIFIED_ORDER_FAILURE'

export const UPDATE_UNIFIED_ORDER = 'orders/UPDATE_UNIFIED_ORDER'
export const UPDATE_UNIFIED_ORDER_SUCCESS =
  'orders/UPDATE_UNIFIED_ORDER_SUCCESS'
export const UPDATE_UNIFIED_ORDER_FAILURE =
  'orders/UPDATE_UNIFIED_ORDER_FAILURE'

export const REMOVE_UNIFIED_ORDER = 'orders/REMOVE_UNIFIED_ORDER'
export const REMOVE_UNIFIED_ORDER_SUCCESS =
  'orders/REMOVE_UNIFIED_ORDER_SUCCESS'
export const REMOVE_UNIFIED_ORDER_FAILURE =
  'orders/REMOVE_UNIFIED_ORDER_FAILURE'

export const FETCH_UNIFIED_ORDER = 'orders/FETCH_UNIFIED_ORDER'
export const FETCH_UNIFIED_ORDER_SUCCESS = 'orders/FETCH_UNIFIED_ORDER_SUCCESS'
export const FETCH_UNIFIED_ORDER_FAILURE = 'orders/FETCH_UNIFIED_ORDER_FAILURE'

export const FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE =
  'orders/FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE'
export const FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_SUCCESS =
  'orders/FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_SUCCESS'
export const FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_FAILURE =
  'orders/FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_FAILURE'

export const CLEAR_CURRENT_UNIFIED_ORDER = 'orders/CLEAR_CURRENT_UNIFIED_ORDER'
export const CLEAR_ITEM_EXIST_ERROR = 'orders/CLEAR_ITEM_EXIST_ERROR'
export const CLEAR_ITEM_STATUS_ERROR = 'orders/CLEAR_ITEM_STATUS_ERROR'

export const FETCH_ORDER_COUNTS_BY_TYPE = 'orders/FETCH_ORDER_COUNTS_BY_TYPE'
export const FETCH_ORDER_COUNTS_BY_TYPE_SUCCESS =
  'orders/FETCH_ORDER_COUNTS_BY_TYPE_SUCCESS'
export const FETCH_ORDER_COUNTS_BY_TYPE_FAILURE =
  'orders/FETCH_ORDER_COUNTS_BY_TYPE_FAILURE'

export const REMOUNT_WEBSOCKET_CHARGES_WIDGET =
  'orders/REMOUNT_WEBSOCKET_CHARGES_WIDGET'
export const CLEAR_REMOUNT_WEBSOCKET_CHARGES_WIDGET =
  'orders/CLEAR_REMOUNT_WEBSOCKET_CHARGES_WIDGET'

export const APPLY_PATIENT_RESTRICTIONS = 'orders/APPLY_PATIENT_RESTRICTIONS'

export const FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID =
  'orders/FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID'
export const FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_SUCCESS =
  'orders/FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_SUCCESS'
export const FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_FAILURE =
  'orders/FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_FAILURE'
