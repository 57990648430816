import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  MutationCreateOrUpdateClientPreferenceArgs,
  QueryClientArgs,
} from '~/api/graphql/generated/types'
import { ClientPreferences } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export type ClientPreferencesState = {
  error: string | null
  isLoading: boolean
  isSaving: boolean
  map: Record<string, ClientPreferences>
}

export const INITIAL_STATE: ClientPreferencesState = {
  map: {},
  isLoading: false,
  isSaving: false,
  error: null,
}

const clientPreferencesSlice = createSlice({
  name: 'clientPreferences',
  initialState: INITIAL_STATE,
  reducers: {
    fetchClientPreferences: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryClientArgs>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    fetchClientPreferencesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ clientId: string; preferences: ClientPreferences }>,
    ) => {
      state.isLoading = false
      state.map[payload.clientId] = payload.preferences
    },
    fetchClientPreferencesFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(payload.error)
    },
    saveClientPreferences: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationCreateOrUpdateClientPreferenceArgs>,
    ) => {
      state.isSaving = true
      state.error = null
    },
    saveClientPreferencesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ clientId: string; preferences: ClientPreferences }>,
    ) => {
      state.isSaving = false
      state.map[payload.clientId] = payload.preferences
    },
    saveClientPreferencesFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isSaving = false
      state.error = getErrorMessage(payload.error)
    },
  },
})

const { actions, reducer } = clientPreferencesSlice

export const {
  fetchClientPreferences,
  fetchClientPreferencesFailure,
  fetchClientPreferencesSuccess,
  saveClientPreferences,
  saveClientPreferencesFailure,
  saveClientPreferencesSuccess,
} = actions

export default reducer
export const getClientPreferencesState = (
  state: RootState,
): ClientPreferencesState => state.clientPreferences
export const getClientPreferencesIsLoading = (state: RootState) =>
  getClientPreferencesState(state).isLoading
export const getClientPreferencesIsSaving = (state: RootState) =>
  getClientPreferencesState(state).isSaving
export const getClientPreferencesMap = (state: RootState) =>
  getClientPreferencesState(state).map
export const getClientPreferences = (id: string | Nil) =>
  createSelector(getClientPreferencesMap, (map) => (id ? map[id] : undefined))
