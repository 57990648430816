import React from 'react'
import { InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  NumberUtils,
  PuiTextField,
  PuiTextFieldProps,
} from '@pbt/pbt-ui-components'

import TaxRateInput from '../form-inputs/TaxRateInput'

const useStyles = makeStyles(
  () => ({
    root: {
      width: 100,
    },
  }),
  { name: 'TaxRateTextField' },
)

export type TaxRateTextFieldProps = PuiTextFieldProps & {
  classes?: ClassesType<typeof useStyles>
}

const TaxRateTextField = (props: TaxRateTextFieldProps) => {
  const classes = useStyles(props)

  const placeholder = `##${NumberUtils.getDecimalSeparator()}##`

  return (
    <PuiTextField
      InputProps={{
        inputComponent: TaxRateInput,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      classes={classes}
      placeholder={placeholder}
      {...props}
    />
  )
}
export default TaxRateTextField
