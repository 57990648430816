import { Defaults } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import ApiErrorTypes from './apiErrorTypes'

export const USER_DEACTIVATED_MESSAGE = i18n.t(
  'Errors:USER_DEACTIVATED_MESSAGE',
  {
    supportEmail: Defaults.SUPPORT_EMAIL,
  },
)

export const USER_NO_ACCESS_TO_BUSINESS = i18n.t(
  'Errors:USER_NO_ACCESS_TO_BUSINESS',
  {
    supportEmail: Defaults.SUPPORT_EMAIL,
  },
)

export const BUNDLE_INCLUDES_ZERO_USED = i18n.t(
  'Errors:BUNDLE_INCLUDES_ZERO_USED',
)

export const USER_CANNOT_CREATE_A_NEW_PATIENT = i18n.t(
  'Errors:USER_CANNOT_CREATE_A_NEW_PATIENT',
)

export const ERROR_MESSAGE_BY_TYPE = {
  [ApiErrorTypes.VALIDATION_ERROR]: i18n.t('Errors:API_ERROR.VALIDATION_ERROR'),
  [ApiErrorTypes.APPOINTMENT_TYPE_NOT_AVAILABLE]: i18n.t(
    'Errors:API_ERROR.APPOINTMENT_TYPE_NOT_AVAILABLE',
  ),
  [ApiErrorTypes.EMAIL_PAYLOAD_LIMIT_ERROR_TYPE]: i18n.t(
    'Errors:API_ERROR.EMAIL_PAYLOAD_LIMIT_ERROR_TYPE',
  ),
  [ApiErrorTypes.BOOP_PAYLOAD_LIMIT_ERROR_TYPE]: i18n.t(
    'Errors:API_ERROR.BOOP_PAYLOAD_LIMIT_ERROR_TYPE',
  ),
  [ApiErrorTypes.SMS_MESSAGE_LENGTH_LIMIT_ERROR_TYPE]: i18n.t(
    'Errors:API_ERROR.SMS_MESSAGE_LENGTH_LIMIT_ERROR_TYPE',
  ),
  [ApiErrorTypes.SMS_INVALID_PHONE_NUMBER]: i18n.t(
    'Errors:API_ERROR.SMS_INVALID_PHONE_NUMBER',
  ),
  [ApiErrorTypes.SMS_RECIPIENT_REGION_IS_NOT_SUPPORTED_ERROR]: i18n.t(
    'Errors:API_ERROR.SMS_RECIPIENT_REGION_IS_NOT_SUPPORTED_ERROR',
  ),
  [ApiErrorTypes.SMS_ATTEMPT_TO_SEND_TO_UNSUBSCRIBED_RECIPIENT_ERROR]: i18n.t(
    'Errors:API_ERROR.SMS_ATTEMPT_TO_SEND_TO_UNSUBSCRIBED_RECIPIENT_ERROR',
  ),
  [ApiErrorTypes.CONVERSATION_ACCESS_FROM_OUTER_BUSINESS_ERROR_TYPE]: i18n.t(
    'Errors:API_ERROR.CONVERSATION_ACCESS_FROM_OUTER_BUSINESS_ERROR_TYPE',
  ),
  [ApiErrorTypes.ORPHAN_IS_ALREADY_ASSIGNED]: i18n.t(
    'Errors:API_ERROR.ORPHAN_IS_ALREADY_ASSIGNED',
  ),
}
