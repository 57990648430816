import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import PuiButtonGroup, {
  PuiButtonGroupProps,
} from '~/components/common/buttons/PuiButtonGroup'
import { CONVERSATION_CATEGORY_NAME } from '~/constants/communications'
import { TableFilter } from '~/types'
import { filterItemsFromEnum } from '~/utils/communicationsUtils'

const statusFilterItems = filterItemsFromEnum(CONVERSATION_CATEGORY_NAME)

const useStyles = makeStyles(
  () => ({
    smallButtons: {
      minWidth: '99px !important',
    },
  }),
  { name: 'CategoryButtonGroup' },
)

export enum CategoryButtonGroupVariant {
  PRIMARY = 'PRIMARY',
  SMALL = 'SMALL',
}

interface CategoryButtonGroupProps
  extends Omit<PuiButtonGroupProps, 'items' | 'onItemSelected'> {
  applyFilter: (category: string, filter: TableFilter) => void
  filter: TableFilter
  variant?: CategoryButtonGroupVariant
}

const CategoryButtonGroup = ({
  applyFilter,
  filter,
  variant = CategoryButtonGroupVariant.PRIMARY,
  ...rest
}: CategoryButtonGroupProps) => {
  const classes = useStyles()
  return (
    <PuiButtonGroup
      classes={{
        button: classNames({
          [classes.smallButtons]: variant === CategoryButtonGroupVariant.SMALL,
        }),
      }}
      items={statusFilterItems}
      selectedItem={statusFilterItems.find(
        (category) => category?.id === filter?.value,
      )}
      onItemSelected={(item) => applyFilter('category', { value: item.id })}
      {...rest}
    />
  )
}

export default CategoryButtonGroup
