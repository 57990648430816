import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { useFields } from '@pbt/pbt-ui-components'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import { ColorVariant } from '~/constants/colors'
import { ProblemType } from '~/constants/problems'
import { BodySystemState } from '~/constants/SOAPStates'
import { useProblemLogUpdate } from '~/store/hooks/problems'
import {
  getDiagnosisProblemStates,
  getFindingProblemStates,
} from '~/store/reducers/constants'
import { getIsLoadingLogs } from '~/store/reducers/problems'
import { Problem, ProblemLogProblemContainer, ProblemStates } from '~/types'

interface IdentifiedProblemDetailsStatusProps {
  log: ProblemLogProblemContainer
  problem: Problem
}

export const problemStateColorVariantMap: Record<string, ColorVariant> = {
  [BodySystemState.NC]: ColorVariant.DISABLED,
  [BodySystemState.WNL]: ColorVariant.SUCCESS,
  [BodySystemState.ONL]: ColorVariant.IMPORTANT,
  [ProblemStates.SUSPECTED]: ColorVariant.IMPORTANT,
  [ProblemStates.RULED_OUT]: ColorVariant.DISABLED,
  [ProblemStates.RESOLVED]: ColorVariant.DISABLED,
  [ProblemStates.REMOVED]: ColorVariant.DISABLED,
}

const IdentifiedProblemDetailsStatus = ({
  problem,
  log,
}: IdentifiedProblemDetailsStatusProps) => {
  const DiagnosisStates = useSelector(getDiagnosisProblemStates)
  const FindingsStates = useSelector(getFindingProblemStates)
  const isLogsLoading = useSelector(getIsLoadingLogs)

  const isCopiedProblem = log.entity.isCopied

  const statusConstants =
    problem.type === ProblemType.DIAGNOSIS ? DiagnosisStates : FindingsStates

  const isCopiedProblemStatusConstants = statusConstants.filter(
    (status) => !(isCopiedProblem && status.name === 'Removed'),
  )

  const {
    fields: { stateId },
  } = useFields([
    {
      name: 'stateId',
      initialValue: log.entity.stateId,
    },
  ])

  useProblemLogUpdate({ problem, log, newStateId: stateId.value })

  return (
    <Box mb={2}>
      <EnumSelect
        accent
        Constant={
          isCopiedProblem ? isCopiedProblemStatusConstants : statusConstants
        }
        colorVariantMap={problemStateColorVariantMap}
        disabled={isLogsLoading}
        field={stateId}
      />
    </Box>
  )
}

export default IdentifiedProblemDetailsStatus
