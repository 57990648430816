import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  moment,
  Nil,
  PuiSelect,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import TaskCustomRecurrencePeriodSettings, {
  TaskCustomRecurrencePeriodSettingsHandle,
} from '~/components/dashboard/tasks-dashboard/TaskCustomRecurrencePeriodSettings'
import {
  RecurrenceOptions,
  RepeatMode,
  RepeatModeList,
} from '~/constants/taskConstants'
import {
  getTaskTemplateOptions,
  getTimeOffsets,
} from '~/store/reducers/constants'
import {
  BundleTaskRecurrence,
  DataHandleWithUnsavedChanges,
  Task,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import { serializeTimeTo24hStr } from '~/utils/time'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import BundleTaskCustomDueDateSettings, {
  BundleTaskCustomDueDateSettingsHandle,
} from './BundleTaskCustomDueDateSettings'
import BundleTaskCustomEndDateSettings, {
  BundleTaskCustomEndDateSettingsHandle,
} from './BundleTaskCustomEndDateSettings'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    quantityInput: {
      width: 'auto',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    labelRoot: {
      margin: 0,
      '&:not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
    },
    radioLabel: {
      fontSize: '1.6rem',
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'BundleTaskRecurrenceSettings' },
)

export interface BundleTaskRecurrenceSettingsProps {
  className?: string
  task?: Task | Nil
}

export interface BundleTaskRecurrenceSettingsHandle
  extends DataHandleWithUnsavedChanges<BundleTaskRecurrence> {}

const BundleTaskRecurrenceSettings = forwardRef(
  function BundleTaskRecurrenceSettings(
    { className, task }: BundleTaskRecurrenceSettingsProps,
    ref: ForwardedRef<BundleTaskRecurrenceSettingsHandle>,
  ) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'Tasks', 'Time'])

    const TaskTemplateOptions = useSelector(getTaskTemplateOptions)
    const TimeOffsets = useSelector(getTimeOffsets)

    const isContextItem = useIsCurrentContextItem(task)

    const taskCustomRecurrencePeriodSettingsRef =
      useRef<TaskCustomRecurrencePeriodSettingsHandle>(null)
    const taskCustomDueDateSettingsRef =
      useRef<BundleTaskCustomDueDateSettingsHandle>(null)
    const taskCustomEndDateSettingsRef =
      useRef<BundleTaskCustomEndDateSettingsHandle>(null)

    const AppointmentDateRecurrence = Utils.findConstantIdByName(
      'Appointment date',
      TaskTemplateOptions.endDate || [],
    )
    const EndOfAppointmentRecurrence = Utils.findConstantIdByName(
      'End of appointment',
      TaskTemplateOptions.endTime || [],
    )
    const CustomTimeOffset = Utils.findConstantIdByName('Custom', TimeOffsets)

    const getRecurrenceOption = () =>
      task?.occurrencesCount
        ? RecurrenceOptions.OCCURRENCES
        : RecurrenceOptions.DATE_TIME
    const getRepeatMode = () =>
      task?.recurrenceModeId === CustomTimeOffset
        ? RepeatMode.REPEAT
        : RepeatMode.DOES_NOT_REPEAT

    const [recurrenceOption, setRecurrenceOption] = useState(
      getRecurrenceOption(),
    )
    const [repeatMode, setRepeatMode] = useState(getRepeatMode())

    const AppointmentDateDueDate = Utils.findConstantIdByName(
      'Appointment date',
      TaskTemplateOptions.dueDate || [],
    )
    const StartOfAppointmentDueTime = Utils.findConstantIdByName(
      'Start of appointment',
      TaskTemplateOptions.dueTime || [],
    )
    const SpecificDueTime = Utils.findConstantIdByName(
      'Set a specific time',
      TaskTemplateOptions.dueTime || [],
    )
    const SpecificEndTime = Utils.findConstantIdByName(
      'Set a specific time',
      TaskTemplateOptions.endTime || [],
    )

    const isRepeatMode = repeatMode === RepeatMode.REPEAT

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'endDateOptionId',
          label: t('Tasks:LABEL.END_DATE'),
          initialValue: task?.endDateOptionId || AppointmentDateRecurrence,
        },
        {
          name: 'endTimeOptionId',
          label: t('Tasks:LABEL.END_TIME'),
          initialValue: task?.endTimeOptionId || EndOfAppointmentRecurrence,
        },
        { name: 'occurrencesCount', initialValue: task?.occurrencesCount || 1 },
        {
          name: 'dueDateOptionId',
          label: isRepeatMode
            ? t('Tasks:LABEL.START_DATE')
            : t('Tasks:LABEL.DUE_DATE'),
          initialValue: task?.dueDateOptionId || AppointmentDateDueDate,
        },
        {
          name: 'dueTimeOptionId',
          label: isRepeatMode
            ? t('Time:TIME_SELECTOR.START_TIME')
            : t('Tasks:LABEL.DUE_TIME'),
          initialValue: task?.dueTimeOptionId || StartOfAppointmentDueTime,
        },
        {
          name: 'specificDueTime',
          initialValue: task?.specificDueTime
            ? moment(task.specificDueTime, 'HH:mm').toISOString()
            : moment().set({ h: 12, m: 0, s: 0, ms: 0 }),
        },
        {
          name: 'specificEndTime',
          initialValue: task?.specificEndTime
            ? moment(task.specificEndTime, 'HH:mm').toISOString()
            : moment().set({ h: 12, m: 0, s: 0, ms: 0 }),
        },
      ],
      false,
    )

    const {
      endDateOptionId,
      endTimeOptionId,
      occurrencesCount,
      dueDateOptionId,
      dueTimeOptionId,
      specificDueTime,
      specificEndTime,
    } = fields

    useEffect(() => {
      reset()
    }, [task])

    useEffect(() => {
      setRecurrenceOption(getRecurrenceOption())
    }, [task?.occurrencesCount])

    useEffect(() => {
      setRepeatMode(getRepeatMode())
    }, [task?.recurrencePeriod])

    const isDateTimeRecurrence =
      isRepeatMode && recurrenceOption === RecurrenceOptions.DATE_TIME
    const isOccurrencesRecurrence =
      isRepeatMode && recurrenceOption === RecurrenceOptions.OCCURRENCES
    const isSpecificDueTime = dueTimeOptionId.value === SpecificDueTime
    const isSpecificEndTime = endTimeOptionId.value === SpecificEndTime

    useImperativeHandle(ref, () => ({
      validate: () => (isRepeatMode ? validate() : true),
      get: () => ({
        recurrenceModeId: isRepeatMode ? CustomTimeOffset : null,
        dueDateOptionId: dueDateOptionId.value,
        dueTimeOptionId: dueTimeOptionId.value,
        occurrencesCount: isOccurrencesRecurrence
          ? occurrencesCount.value
          : null,
        endDateOptionId: isDateTimeRecurrence ? endDateOptionId.value : null,
        endTimeOptionId: isDateTimeRecurrence ? endTimeOptionId.value : null,
        specificDueTime: isSpecificDueTime
          ? serializeTimeTo24hStr(specificDueTime.value)
          : null,
        specificEndTime: isSpecificEndTime
          ? serializeTimeTo24hStr(specificEndTime.value)
          : null,
        ...taskCustomDueDateSettingsRef.current?.get(),
        ...taskCustomRecurrencePeriodSettingsRef.current?.get(),
        ...(taskCustomEndDateSettingsRef.current?.get() || {}),
      }),
      hasUnsavedChanges: () => {
        const fieldsChanged = isFieldValuesChanged(fields)
        const dueDateSettingsChanged =
          taskCustomDueDateSettingsRef.current?.hasUnsavedChanges() ?? false
        const recurrencePeriodSettingsChanged =
          taskCustomRecurrencePeriodSettingsRef.current?.hasUnsavedChanges() ??
          false
        const endDateSettingsChanged =
          taskCustomEndDateSettingsRef.current?.hasUnsavedChanges() ?? false

        return (
          fieldsChanged ||
          dueDateSettingsChanged ||
          recurrencePeriodSettingsChanged ||
          endDateSettingsChanged
        )
      },
    }))

    return (
      <Grid
        container
        item
        className={classNames(className, classes.root)}
        columnSpacing={3}
      >
        <Grid container item alignItems="flex-end" columnSpacing={2}>
          <Grid item>
            <FormControl margin="normal">
              <InputLabel htmlFor="task-repeat-select">
                {t('Common:REPEAT_ACTION')}
              </InputLabel>
              <PuiSelect
                disabled={!isContextItem}
                input={<Input id="task-repeat-select" />}
                items={RepeatModeList}
                renderEmpty={false}
                value={repeatMode}
                onChange={Utils.handleFormSelectInput(setRepeatMode)}
              />
            </FormControl>
          </Grid>
          <TaskCustomRecurrencePeriodSettings
            ref={taskCustomRecurrencePeriodSettingsRef}
            repeatMode={repeatMode}
            task={task}
          />
        </Grid>
        {isRepeatMode && (
          <Grid item xs={12}>
            <Text strong mt={2} variant="body2">
              {t('Tasks:LABEL.START_NOUN')}
            </Text>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="bundle-task-due-date-select">{`${dueDateOptionId.label}*`}</InputLabel>
            <PuiSelect
              disabled={!isContextItem}
              field={dueDateOptionId}
              input={<Input id="bundle-task-due-date-select" />}
              items={TaskTemplateOptions.dueDate}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="bundle-task-due-time-select">{`${dueTimeOptionId.label}*`}</InputLabel>
            <PuiSelect
              disabled={!isContextItem}
              field={dueTimeOptionId}
              input={<Input id="bundle-task-due-time-select" />}
              items={TaskTemplateOptions.dueTime}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
        <BundleTaskCustomDueDateSettings
          dueDateOptionId={dueDateOptionId.value}
          ref={taskCustomDueDateSettingsRef}
          task={task}
        />
        {isSpecificDueTime && (
          <Grid container item columnSpacing={3} justifyContent="flex-end">
            <Grid item xs={6}>
              <TimeSelector
                disabled={!isContextItem}
                fromLabel={false}
                startValue={specificDueTime.value}
                withPopper={false}
                onStartChange={specificDueTime.setValue}
              />
            </Grid>
          </Grid>
        )}
        {isRepeatMode && (
          <>
            <Grid item xs={12}>
              <Text strong mt={2} variant="body2">
                {t('Tasks:LABEL.END_NOUN')}
              </Text>
              <RadioGroup
                row
                aria-label="endAfter"
                name="endAfter1"
                value={recurrenceOption}
                onChange={(_, value) => setRecurrenceOption(value)}
              >
                {Object.values(RecurrenceOptions).map((name) => (
                  <FormControlLabel
                    classes={{
                      root: classes.labelRoot,
                      label: classes.radioLabel,
                    }}
                    control={<Radio className={classes.radio} />}
                    disabled={!isContextItem}
                    key={name}
                    label={name}
                    value={name}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid container item alignItems="center" columnSpacing={3}>
              {isDateTimeRecurrence && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="bundle-task-end-date-select">
                        {endDateOptionId.label}
                      </InputLabel>
                      <PuiSelect
                        disabled={!isContextItem}
                        field={endDateOptionId}
                        input={<Input id="bundle-task-end-date-select" />}
                        items={TaskTemplateOptions.endDate}
                        renderEmpty={false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="bundle-task-end-time-select">
                        {endTimeOptionId.label}
                      </InputLabel>
                      <PuiSelect
                        disabled={!isContextItem}
                        field={endTimeOptionId}
                        input={<Input id="bundle-task-end-time-select" />}
                        items={TaskTemplateOptions.endTime}
                        renderEmpty={false}
                      />
                    </FormControl>
                  </Grid>
                  <BundleTaskCustomEndDateSettings
                    endDateOptionId={endDateOptionId.value}
                    ref={taskCustomEndDateSettingsRef}
                    task={task}
                  />
                  {isSpecificEndTime && (
                    <Grid
                      container
                      item
                      columnSpacing={3}
                      justifyContent="flex-end"
                    >
                      <Grid item xs={6}>
                        <TimeSelector
                          disabled={!isContextItem}
                          fromLabel={false}
                          startValue={specificEndTime.value}
                          withPopper={false}
                          onStartChange={specificEndTime.setValue}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {isOccurrencesRecurrence && (
                <Grid container item alignItems="center" wrap="nowrap">
                  <Text variant="body">{t('Tasks:LABEL.END_AFTER')}</Text>
                  <QuantityInput
                    showControls
                    className={classes.quantityInput}
                    disabled={!isContextItem}
                    field={occurrencesCount}
                    max={999}
                    min={1}
                  />
                  <Text variant="body">
                    {occurrencesCount.value === 1
                      ? t('Tasks:LABEL.OCCURRENCE_ONE').toLowerCase()
                      : t('Tasks:LABEL.OCCURRENCE_OTHER').toLowerCase()}
                  </Text>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)

export default BundleTaskRecurrenceSettings
