import { gql } from '@apollo/client/core'

import {
  CONVERSATION_DTO_FIELDS,
  MESSAGE_FIELDS,
  SHORT_CONVERSATION_DTO_FIELDS,
} from '../fragments'

export const FETCH_CONVERSATIONS = gql`
  ${SHORT_CONVERSATION_DTO_FIELDS}
  query Conversations($searchCriteria: SearchCriteria, $clientId: ID) {
    conversations(searchCriteria: $searchCriteria, clientId: $clientId) {
      data {
        ...ShortConversationDtoFields
      }
      totalCount
      unreadNotificationsTotalCount
    }
  }
`

export const FETCH_CONVERSATION = gql`
  ${CONVERSATION_DTO_FIELDS}
  query Conversation($conversationId: ID!) {
    conversation(conversationId: $conversationId) {
      ...ConversationDtoFields
      event {
        id
        scheduledStartDatetime
        businessAppointmentType {
          id
          name
        }
        soaps {
          id
        }
      }
    }
  }
`

export const FETCH_CONVERSATION_MESSAGES = gql`
  ${MESSAGE_FIELDS}
  query MessagesForConversation($conversationId: ID!, $from: Int, $to: Int) {
    messagesForConversation(
      conversationId: $conversationId
      from: $from
      to: $to
    ) {
      data {
        ...MessageFields
      }
      totalCount
    }
  }
`
