/* eslint-disable prefer-regex-literals */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import {
  AlertIconType,
  ApiError,
  BasePuiDialogProps,
  Nil,
  PuiAlert,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

const testAgainst = (regexp: RegExp) => (string: string) => regexp.test(string)
const testResource = (path: string) => (resource: Resource) =>
  resource.test(path)
const findResource = (path: string) =>
  R.pipe(R.values, R.find(testResource(path)))

type Resource = {
  name: string
  test: (string: string) => boolean
  transferLabel: string
  transferUrl: string
}

type ResourceMapType = {
  [x: string]: Resource
}

const ResourceMap: ResourceMapType = {
  INVENTORY: {
    name: 'Inventory',
    transferLabel: i18n.t('Dialogs:MISSING_RESOURCE_DIALOG.BACK_TO_INVENTORY'),
    transferUrl: '/admin/catalog/inventories',
    test: testAgainst(new RegExp('/inventory/items/')),
  },
  ADJUSTMENTS: {
    name: 'Adjustments',
    transferLabel: i18n.t(
      'Dialogs:MISSING_RESOURCE_DIALOG.BACK_TO_ADJUSTMENTS',
    ),
    transferUrl: '/admin/catalog/inventories/adjustments',
    test: testAgainst(new RegExp('/inventory/adjustment/')),
  },
  SHIPMENTS: {
    name: 'Shipments',
    transferLabel: i18n.t('Dialogs:MISSING_RESOURCE_DIALOG.BACK_TO_SHIPMENTS'),
    transferUrl: '/admin/catalog/inventories/shipments',
    test: testAgainst(new RegExp('/inventory/shipment/')),
  },
  SOAP: {
    name: 'Soap',
    transferLabel: i18n.t('Dialogs:MISSING_RESOURCE_DIALOG.BACK_TO_SCHEDULER'),
    transferUrl: '/scheduler',
    test: testAgainst(new RegExp('/soaps/')),
  },
  IMAGING_DASHBOARD: {
    name: 'Imaging Dashboard',
    transferLabel: i18n.t(
      'Dialogs:MISSING_RESOURCE_DIALOG.BACK_TO_IMAGING_DASHBOARD',
    ),
    transferUrl: '/imaging-dashboard',
    test: testAgainst(new RegExp('/idexx-imaging/v1/dashboard')),
  },
  DEFAULT: {
    name: 'Home',
    transferLabel: i18n.t('Dialogs:MISSING_RESOURCE_DIALOG.BACK_TO_HOME_PAGE'),
    transferUrl: '/',
    test: R.T,
  },
}

const detectResource = (error: ApiError) => {
  const path = error?.response?.config?.url
  return findResource(path)(ResourceMap)
}

export interface MissingResourceDialogProps extends BasePuiDialogProps {
  customMessage?: string | Nil
  error: ApiError
}

const MissingResourceDialog = ({
  open,
  onClose,
  error,
  customMessage,
}: MissingResourceDialogProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation('Dialogs')

  const message =
    customMessage || t('Dialogs:MISSING_RESOURCE_DIALOG.ALERT_MESSAGE')
  const resource = detectResource(error) as Resource

  const handleClose = () => {
    navigate(resource.transferUrl)
    onClose?.()
  }

  return (
    <PuiAlert
      hideCloseButton
      iconType={AlertIconType.WARN}
      message={message}
      okButtonText={resource.transferLabel}
      open={open}
      onOk={handleClose}
    />
  )
}

export default MissingResourceDialog
