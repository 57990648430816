import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  EasterEggEvents,
  Problem,
  ProblemLogBodySystemContainer,
  ProblemLogBodySystemEntity,
  ProblemLogEnumValueContainer,
  ProblemLogProblemContainer,
  ProblemLogProblemEntity,
  ProblemStates,
} from '~/types'
import useIsMounted from '~/utils/useIsMounted'

import { queueEasterEggEvent } from '../actions/easterEgg'
import {
  updateProblemBodySystemLog,
  updateProblemLog,
  updateProblemLogEnumValue,
} from '../actions/problems'
import {
  getDiagnosisProblemStates,
  getFeatureToggle,
} from '../reducers/constants'
import { getSoapId } from '../reducers/soap'

type ProblemLogUpdateHookProps = {
  log: ProblemLogProblemContainer | ProblemLogBodySystemContainer
  newNotes?: string
  newStateId?: string
  onUnmountOnly?: boolean
  problem: Problem
}

export const useProblemLogUpdate = ({
  problem,
  log,
  newNotes,
  newStateId,
  onUnmountOnly = false,
}: ProblemLogUpdateHookProps) => {
  const dispatch = useDispatch()
  const soapId = useSelector(getSoapId)
  const isMounted = useIsMounted()
  const DiagnosisProblemStates = useSelector(getDiagnosisProblemStates)
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )

  const updateLog = () => {
    const hasNewValue = (oldValue: string | Nil, newValue: string | Nil) => {
      /* Has New Value Truth Table
       * Old | New | hasNew?
       * ----|-----|--------
       * Nil | Nil | false
       * Nil | ''  | false
       * Nil | 'a' | true
       * ''  | Nil | false
       * ''  | ''  | false
       * ''  | 'a' | true
       * 'a' | Nil | false
       * 'a' | ''  | true
       * 'a' | 'a' | false
       * 'a' | 'x' | true
       */
      if (!oldValue) {
        // Covers first 6 cases
        return Boolean(newValue)
      }
      // Handles last 4 cases
      return (
        !(newValue === undefined || newValue === null) && newValue !== oldValue
      )
    }

    if (
      !hasNewValue(log.entity.notes, newNotes) &&
      !hasNewValue(log.entity.stateId, newStateId)
    ) {
      return
    }

    if (soapId) {
      if (problem.type) {
        const entity = log.entity as ProblemLogProblemEntity
        const ResolvedStateId =
          newStateId &&
          Utils.findConstantIdByName(
            ProblemStates.RESOLVED,
            DiagnosisProblemStates,
          )
        if (
          isEasterEggEnabled &&
          newStateId &&
          newStateId === ResolvedStateId
        ) {
          dispatch(
            queueEasterEggEvent({
              actionType: EasterEggEvents.RESOLVE_CHRONIC_PROBLEM,
            }),
          )
        }
        dispatch(
          updateProblemLog(soapId, entity.id, {
            id: entity.id,
            bodySystemLogId: entity.bodySystemLogId,
            problemId: entity.problemId,
            stateId: newStateId ?? entity.stateId,
            notes: newNotes ?? entity.notes,
            crc: log.crc,
          }),
        )
      } else {
        const entity = log.entity as ProblemLogBodySystemEntity
        dispatch(
          updateProblemBodySystemLog(soapId, entity.id, {
            id: entity.id,
            soapId,
            stateId: newStateId ?? entity.stateId,
            notes: newNotes ?? entity.notes,
            bodySystemId: entity.bodySystemId,
            crc: log.crc,
          }),
        )
      }
    }
  }

  useEffect(() => {
    if (onUnmountOnly) {
      return () => {
        if (!isMounted()) {
          updateLog()
        }
      }
    }

    updateLog()

    return undefined
  }, [problem, log, newNotes, newStateId])
  return updateLog
}

type ProblemLogUpdateEnumValueHookProps = {
  enumLog: ProblemLogEnumValueContainer
  newNotes?: string
}

export const useProblemLogEnumValueUpdate = ({
  enumLog,
  newNotes,
}: ProblemLogUpdateEnumValueHookProps): boolean => {
  const dispatch = useDispatch()

  const isNotesChanged = Boolean(newNotes) && newNotes !== enumLog.entity.notes

  useEffect(() => {
    if (isNotesChanged) {
      dispatch(
        updateProblemLogEnumValue(
          enumLog.entity.problemLogId,
          enumLog.entity.id,
          {
            id: enumLog.entity.id,
            problemLogId: enumLog.entity.problemLogId,
            enumId: enumLog.entity.enumId,
            enumValueId: enumLog.entity.enumValueId,
            parentEnumValueId: enumLog.entity.parentEnumValueId,
            notes: newNotes || '',
            crc: enumLog.crc,
          },
        ),
      )
    }
  }, [enumLog, newNotes])

  return isNotesChanged
}
