import React, { useEffect, useState } from 'react'
import { IconButton, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import { Defaults, PuiCheckbox } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import {
  formatMoneyRange,
  getExtendedPrice,
  getHighValue,
  getLowValue,
} from '../../invoices/invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    cell: {
      border: 'none',
      padding: theme.spacing(0, 1),
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    row: {
      height: 54,
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: theme.colors.panelRow,
    },
    deleteCell: {
      textAlign: 'right',
    },
  }),
  { name: 'SelectableListItem' },
)

const SelectableListItem = ({
  item,
  name,
  showPrice,
  showQuantity,
  showPriceUnits,
  isRangeEnabled,
  isPrePaidEnabled,
  onTogglePrePaid,
  onUpdateUsedQuantity,
  onUpdateQuantity,
  onUpdateQuantityLow,
  onUpdateQuantityHigh,
  allowDecimalQuantity,
  onDelete,
  showUsedPaidLabels,
}) => {
  const classes = useStyles()

  const priceUnits = useGetFormattedPriceUnit(item.price)

  const [quantity, setQuantity] = useState(item.quantity)

  const updateQuantityLow = useDebouncedCallback(
    onUpdateQuantityLow,
    Defaults.DEBOUNCE_ACTION_TIME,
  )
  const updateQuantityHigh = useDebouncedCallback(
    onUpdateQuantityHigh,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const maxUsedQuantity =
    (item.prepaidBox?.remaining || 0) + getLowValue(item.quantity)

  useEffect(() => {
    if (item.quantity) {
      setQuantity({
        low: item.quantity?.low || item.quantity,
        high: item.quantity?.high || item.quantity,
      })
    }
  }, [JSON.stringify(item)])

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.cell}>{name}</TableCell>
      {showQuantity && !isRangeEnabled && !isPrePaidEnabled && (
        <TableCell className={classes.cell}>
          <QuantityInput
            disableUnderline
            minReachedTooltipEnabled
            allowDecimal={allowDecimalQuantity}
            max={999}
            min={allowDecimalQuantity ? 0.001 : 1}
            showControls={false}
            value={getLowValue(item.quantity)}
            onChange={(value) => onUpdateQuantity(value, item)}
          />
        </TableCell>
      )}
      {showQuantity && isRangeEnabled && (
        <>
          <TableCell className={classes.cell}>
            <QuantityInput
              disableUnderline
              allowDecimal={allowDecimalQuantity}
              max={999}
              min={0}
              minReachedTooltipEnabled={false}
              value={getLowValue(quantity?.low)}
              onChange={(value) => {
                setQuantity({ ...quantity, low: value })
                updateQuantityLow(value, item)
              }}
            />
          </TableCell>
          <TableCell className={classes.cell}>
            <QuantityInput
              disableUnderline
              minReachedTooltipEnabled
              allowDecimal={allowDecimalQuantity}
              max={999}
              min={allowDecimalQuantity ? 0.001 : 1}
              value={getHighValue(quantity?.high)}
              onChange={(value) => {
                setQuantity({ ...quantity, high: value })
                updateQuantityHigh(value, item)
              }}
            />
          </TableCell>
        </>
      )}
      {showQuantity && isPrePaidEnabled && (
        <>
          <TableCell className={classes.cell}>
            <PuiCheckbox
              checked={item.prepaid || item.prepaidBox?.remaining > 0}
              disabled={item.prepaidBox?.remaining > 0}
              onChange={() => onTogglePrePaid(!item.prepaid, item)}
            />
          </TableCell>
          {!showUsedPaidLabels && (
            <TableCell className={classes.cell}>
              {item.prepaid && (
                <QuantityInput
                  disableUnderline
                  minReachedTooltipEnabled
                  allowDecimal={allowDecimalQuantity}
                  max={getLowValue(item.quantity)}
                  min={0}
                  value={item.usedQuantity ?? 1}
                  onChange={(value) => onUpdateUsedQuantity(value, item)}
                />
              )}
            </TableCell>
          )}
          <TableCell className={classes.cell}>
            <QuantityInput
              disableUnderline
              allowDecimal={allowDecimalQuantity}
              max={999}
              min={0}
              minReachedTooltipEnabled={false}
              value={getLowValue(item.quantity)}
              onChange={(value) => onUpdateQuantity(value, item)}
            />
          </TableCell>
          {showUsedPaidLabels && (
            <TableCell className={classes.cell}>
              {item.prepaid && (
                <QuantityInput
                  disableUnderline
                  minReachedTooltipEnabled
                  allowDecimal={allowDecimalQuantity}
                  max={maxUsedQuantity}
                  min={0}
                  value={item.usedQuantity ?? 1}
                  onChange={(value) => onUpdateUsedQuantity(value, item)}
                />
              )}
            </TableCell>
          )}
        </>
      )}
      {showPrice && (
        <TableCell className={classes.cell}>
          {formatMoneyRange(getExtendedPrice(item))}
        </TableCell>
      )}
      {showPriceUnits && (
        <TableCell className={classes.cell}>{priceUnits}</TableCell>
      )}
      <TableCell className={classNames(classes.cell, classes.deleteCell)}>
        <IconButton size="small" onClick={() => onDelete(item)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default SelectableListItem
