import { gql } from '@apollo/client'

export const CHARGES_FIELDS = gql`
  fragment ChargesFields on Charges {
    ... on Invoice {
      id
      type
      invoiceNo
      payments {
        id
      }
    }
    ... on ChargeSheet {
      clientId
      type
    }
  }
`
