import React, { useState } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import UpcomingAppointmentsContent from './UpcomingAppointmentsContent'
import UpcomingAppointmentsHeader from './UpcomingAppointmentsHeader'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
  }),
  { name: 'UpcomingAppointments' },
)

const UpcomingAppointments = () => {
  const classes = useStyles()
  const [hasAppointments, setHasAppointments] = useState(false)

  return (
    <Grid container className={classes.root} py={2}>
      <UpcomingAppointmentsHeader hasAppointments={hasAppointments} />
      <UpcomingAppointmentsContent setHasAppointments={setHasAppointments} />
    </Grid>
  )
}

export default UpcomingAppointments
