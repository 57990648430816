import { useEffect, useState } from 'react'
import * as R from 'ramda'
import { Field, FieldObject, Nil } from '@pbt/pbt-ui-components'

export type FieldCache = Pick<Field, 'name' | 'value' | 'initialValue'>[]

export const getChangedFields = (
  fields: FieldObject = {},
  cache?: FieldCache,
): FieldCache => {
  const values = Object.values(fields)
  if (!cache) {
    cache = Object.values(fields).map(({ name, initialValue }) => ({
      name,
      value: initialValue,
    }))
  }

  return R.differenceWith(
    (a, b) => R.equals(a?.name, b?.name) && R.equals(a?.value, b?.value),
    values,
    cache,
  )
}

export const haveFieldsChanged = (
  fields: FieldObject,
  cache?: FieldCache,
): boolean => !R.isEmpty(getChangedFields(fields, cache))

const generateCache = (fields: FieldObject): FieldCache =>
  Object.values(fields).map(({ name, value }) => ({ name, value }))

const isInitialValuesEqualValues = R.pipe(
  R.values,
  R.map(({ value, initialValue }: FieldObject) =>
    R.equals(value, initialValue),
  ),
  R.all(R.equals(true)),
)

const useFieldsChanged = (
  onChange: ((changedFields: FieldCache) => void) | Nil,
  fields: FieldObject,
  strict?: boolean,
): void => {
  const values = Object.values(fields)
  const fieldValues = values.map((field) => field?.value)
  const [cache, setCache] = useState(generateCache(fields))

  useEffect(() => {
    const changedFields = getChangedFields(fields, cache)
    setCache(generateCache(fields))
    if (
      onChange &&
      !R.isEmpty(changedFields) &&
      (strict ? !isInitialValuesEqualValues(fields) : true)
    ) {
      onChange(changedFields)
    }
  }, fieldValues)
}

export default useFieldsChanged
