import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import {
  fetchMoreItemsForTimeline,
  updateTimelineClientData,
} from '~/store/actions/timeline'
import { getFinanceIsSaving } from '~/store/reducers/finance'
import { getOrdersIsSending } from '~/store/reducers/orders'
import {
  getTimelineList,
  getTimelineTotalCount,
} from '~/store/reducers/timeline'

import Timeline, { TimelineProps } from './Timeline'

export interface TimelineComponentProps
  extends Omit<
    TimelineProps,
    'data' | 'isItemLoaded' | 'loadMoreItems' | 'totalCount'
  > {}

const TimelineComponent = ({
  clientId,
  patientId,
  ...rest
}: TimelineComponentProps) => {
  const dispatch = useDispatch()
  const list = useSelector(getTimelineList)
  const totalCount = useSelector(getTimelineTotalCount)
  const isFinanceSaving = useSelector(getFinanceIsSaving)
  const isOrdersSending = useSelector(getOrdersIsSending)

  useEffect(() => {
    if (!isFinanceSaving && !isOrdersSending) {
      dispatch(updateTimelineClientData({ clientId, patientId }))
    }
  }, [clientId, patientId, isFinanceSaving, isOrdersSending])

  const isItemLoaded = (index: number) => Boolean(list[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (!R.isEmpty(list)) {
      dispatch(fetchMoreItemsForTimeline(startIndex, endIndex))
    }
  }

  return (
    <Timeline
      clientId={clientId}
      data={list}
      isItemLoaded={isItemLoaded}
      loadMoreItems={loadMoreItems}
      patientId={patientId}
      totalCount={totalCount}
      {...rest}
    />
  )
}

export default TimelineComponent
