import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, PopperProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Calendar,
  ControlButtonGroup,
  ControlButtonGroupName,
  CountrySelect,
  FieldProp,
  moment,
  PuiAutocomplete,
  PuiPopper,
  PuiSelect,
  PuiTextArea,
  PuiTextField,
  StateSelect,
  useFields,
  ZipInput,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import BreedAutocomplete from '~/components/common/inputs/BreedAutocomplete'
import ClientEmailTextField from '~/components/common/inputs/ClientEmailTextField'
import GenderSelect, {
  GenderSelectProps,
} from '~/components/common/inputs/gender/GenderSelect'
import SpeciesSelect from '~/components/common/inputs/SpeciesSelect'
import DateOfBirth, {
  DateOfBirthHandle,
  DateOfBirthProps,
} from '~/components/common/selects/DateOfBirth'
import { ConfirmAlertType } from '~/constants/DialogNames'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import ReferredBySelect from '../../ReferredBySelect'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      overflow: 'hidden',
    },
    popper: {
      minWidth: 340,
      height: 48,
    },
    buttonsMultiline: {
      justifyContent: 'flex-end',
      marginTop: theme.spacing(0.5),
    },
    iconButton: {
      margin: 0,
    },
    successIconButton: {
      color: theme.colors.success,
    },
    removeIconButton: {
      color: theme.colors.secondaryText,
    },
    autocomplete: {
      margin: 0,
    },
  }),
  { name: 'InlineEditPopper' },
)

export enum InlineEditInputType {
  AGE = 'AGE',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  BREED = 'BREED',
  CALENDAR = 'CALENDAR',
  CLIENT_EMAIL = 'CLIENT_EMAIL',
  COUNTRY = 'COUNTRY',
  GENDER = 'GENDER',
  MULTILINE = 'MULTILINE',
  PHONE = 'PHONE',
  REFERRED_BY = 'REFERRED_BY',
  SELECT = 'SELECT',
  SPECIES = 'SPECIES',
  STATE = 'STATE',
  TEXT = 'TEXT',
  ZIP = 'ZIP',
}

export interface InlineEditPopperProps {
  anchorEl: HTMLDivElement | null
  fieldProps?: FieldProp | FieldProp[]
  inputProps?: any
  inputType?: InlineEditInputType
  isLoading: boolean
  offset: Record<string, number>
  onClose: () => void
  onSave: (...args: any[]) => void
  placement: PopperProps['placement'] | 'center'
}

const InlineEditPopper = ({
  anchorEl,
  placement,
  inputType = InlineEditInputType.TEXT,
  inputProps: inputPropsProp,
  fieldProps = {} as FieldProp,
  isLoading,
  onSave,
  onClose,
  offset: offsetProp,
}: InlineEditPopperProps) => {
  const { left: offsetLeft = 0, top: offsetTop = 0 } = offsetProp || {}
  const classes = useStyles()
  const patientDateOfBirthRef = useRef<DateOfBirthHandle>(null)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const offset = anchorEl
    ? [4 + offsetTop, -(anchorEl.offsetWidth + 8 + offsetLeft)]
    : undefined

  const multiline = inputPropsProp?.rows > 1

  const [openConfirmAlert, , isOpenConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.INLINE_CLIENT_AND_PATIENT_EDIT,
  })

  const { fields, validate, reset } = useFields(
    Array.isArray(fieldProps) ? fieldProps : [fieldProps],
  )

  const resetDependencies = Array.isArray(fieldProps)
    ? []
    : [fieldProps?.initialValue]

  useEffectExceptOnMount(() => {
    reset()
  }, resetDependencies)

  const field = Array.isArray(fieldProps) ? undefined : fields[fieldProps.name]

  const inputProps = {
    field,
    fullWidth: true,
    disabled: isLoading,
    margin: 'none',
    ...(inputPropsProp || {}),
  }

  const hasChanges = () => {
    if (inputType === InlineEditInputType.AGE) {
      const { dob, approximateAge } =
        patientDateOfBirthRef.current?.getValue() || {}
      const ageChanged = !moment(fields.dob.value).isSame(dob, 'day')
      return ageChanged || fields.approximateAge.initialValue !== approximateAge
    }
    if (inputType === InlineEditInputType.GENDER) {
      return (
        fields.gender.initialValue !== fields.gender.value ||
        fields.spayedNeutered.initialValue !== fields.spayedNeutered.value
      )
    }

    return !R.equals(field?.value, field?.initialValue)
  }

  const handleSave = () => {
    if (validate()) {
      const fieldValues: Record<string, any> = R.pluck<any, string>(
        'value',
        fields as any,
      )

      if (inputType === InlineEditInputType.AGE) {
        if (!patientDateOfBirthRef.current) {
          return
        }
        onSave(patientDateOfBirthRef.current?.getValue())
      } else if (inputType === InlineEditInputType.GENDER) {
        onSave(fieldValues)
      } else {
        onSave(field ? field.value : fieldValues)
      }
    }
  }

  const close = () => {
    reset()
    onClose()
  }

  const handleClose = () => {
    const valueChanged = hasChanges()

    if (!isOpenConfirmAlert && !isLoading && valueChanged) {
      openConfirmAlert({
        applyCustomMessage: true,
        message: t('Dialogs:CONFIRM_ALERT_DIALOG.INLINE_EDIT_POPPER.MESSAGE'),
        onConfirm: (proceed: any) => (proceed ? handleSave() : close()),
        okButtonText: t('Common:SAVE_ACTION'),
        cancelButtonText: t(
          'Dialogs:CONFIRM_ALERT_DIALOG.INLINE_EDIT_POPPER.CANCEL_BUTTON',
        ),
      })
    } else if (!valueChanged) {
      close()
    }
  }

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      classes={{
        popper: classes.popper,
        paper: classes.paper,
      }}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'offset',
          enabled: Boolean(offset),
          options: {
            offset,
          },
        },
      ]}
      open={Boolean(anchorEl)}
      placement={placement}
      onClose={handleClose}
    >
      <Grid
        container
        alignItems="flex-end"
        direction={multiline ? 'column' : 'row'}
        pb={1}
        pl={multiline ? 1.5 : 1}
        pr={multiline ? 1.5 : 0.5}
        pt={multiline ? 1.5 : 1}
        wrap="nowrap"
      >
        {inputType === InlineEditInputType.TEXT && (
          <PuiTextField {...inputProps} />
        )}
        {inputType === InlineEditInputType.MULTILINE && (
          <PuiTextArea {...inputProps} />
        )}
        {inputType === InlineEditInputType.CLIENT_EMAIL && (
          <ClientEmailTextField {...inputProps} />
        )}
        {inputType === InlineEditInputType.PHONE && (
          <PhoneInput {...inputProps} />
        )}
        {inputType === InlineEditInputType.STATE && (
          <StateSelect hideLabel {...inputProps} />
        )}
        {inputType === InlineEditInputType.COUNTRY && (
          <CountrySelect hideLabel {...inputProps} />
        )}
        {inputType === InlineEditInputType.SELECT && (
          <PuiSelect {...inputProps} />
        )}
        {inputType === InlineEditInputType.AUTOCOMPLETE && (
          <PuiAutocomplete
            freeSolo
            options={inputProps.items}
            {...inputProps}
          />
        )}
        {inputType === InlineEditInputType.ZIP && (
          <PuiTextField
            InputProps={{
              inputComponent: ZipInput,
              inputProps: { country: inputProps.country },
            }}
            {...inputProps}
          />
        )}
        {inputType === InlineEditInputType.REFERRED_BY && (
          <ReferredBySelect {...inputProps} />
        )}
        {inputType === InlineEditInputType.SPECIES && (
          <SpeciesSelect hideLabel required {...inputProps} />
        )}
        {inputType === InlineEditInputType.BREED && (
          <BreedAutocomplete
            disableClearable
            hideLabel
            className={classes.autocomplete}
            {...inputProps}
          />
        )}
        {inputType === InlineEditInputType.AGE && (
          <DateOfBirth
            inline
            ref={patientDateOfBirthRef}
            {...(fields as Pick<DateOfBirthProps, 'dob' | 'approximateAge'>)}
          />
        )}
        {inputType === InlineEditInputType.GENDER && (
          <GenderSelect
            fullWidth
            {...(fields as Pick<
              GenderSelectProps,
              'gender' | 'spayedNeutered'
            >)}
          />
        )}
        {inputType === InlineEditInputType.CALENDAR && (
          <Calendar fullWidth field={field} />
        )}
        <Grid
          container
          alignItems="center"
          className={classNames({ [classes.buttonsMultiline]: multiline })}
          width="auto"
          wrap="nowrap"
        >
          <ControlButtonGroup
            buttons={[
              {
                name: ControlButtonGroupName.CHECK,
                onClick: handleSave,
                isLoading,
              },
              {
                name: ControlButtonGroupName.REMOVE,
                onClick: handleClose,
                disabled: isLoading,
              },
            ]}
            classes={{
              iconButton: classes.iconButton,
              checkIcon: classes.successIconButton,
              removeIcon: classes.removeIconButton,
            }}
          />
        </Grid>
      </Grid>
    </PuiPopper>
  )
}

export default InlineEditPopper
