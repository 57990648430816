import React from 'react'
import { Grid } from '@mui/material'
import { TextWithTooltip } from '@pbt/pbt-ui-components'

export type AssignedDevicesCellItem = {
  monitors: string[] | null
}

const AssignedDevices = (item: AssignedDevicesCellItem) => {
  const { monitors } = item

  const text = monitors ? monitors.join(', ') : ''

  return (
    <Grid container item sx={{ maxWidth: `100%` }} wrap="nowrap">
      <TextWithTooltip
        noIcon
        noWrap
        component="div"
        tooltipText={text}
        variant="body"
      >
        {text}
      </TextWithTooltip>
    </Grid>
  )
}

export default AssignedDevices
