import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PermissionArea } from '@pbt/pbt-ui-components'

import MenuDropdown, {
  MenuDropdownProps,
} from '~/components/common/inputs/MenuDropdown'
import { getCRUDByArea } from '~/store/reducers/auth'
import useIsDymoConnectEnabled from '~/utils/useIsDymoEnabled'

export const LabelMenuOptions = {
  CAGE: 'CAGE',
  FOLDER: 'FOLDER',
  PRACTICE_ADDRESS: 'PRACTICE_ADDRESS',
}

const useStyles = makeStyles(
  () => ({
    menu: {
      minWidth: 200,
    },
    button: {},
  }),
  { name: 'ClientAndPatientLabelMenuDropDown' },
)

export interface ClientAndPatientLabelMenuDropDownProps
  extends Partial<MenuDropdownProps> {
  classes?: ClassesType<typeof useStyles>
  onChange: MenuDropdownProps['onSelected']
}

const ClientAndPatientLabelMenuDropDown = ({
  classes: classesProp,
  onChange,
  ...rest
}: ClientAndPatientLabelMenuDropDownProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const { read: patientReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )
  const { read: businessReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.BUSINESS),
  )

  const isDymoConnectEnabled = useIsDymoConnectEnabled()

  const dymoSpecificLabels = [
    {
      name: t('Common:FOLDER_LABEL'),
      value: LabelMenuOptions.FOLDER,
      disabled: !patientReadPermissions,
    },
    {
      name: t('Common:PRACTICE_ADDRESS_LABEL'),
      value: LabelMenuOptions.PRACTICE_ADDRESS,
      disabled: !businessReadPermissions,
    },
  ]

  return (
    <MenuDropdown
      classes={classes}
      items={[
        {
          name: t('Common:CAGE_CARD_LABEL'),
          value: LabelMenuOptions.CAGE,
          disabled: !patientReadPermissions,
        },
        ...(isDymoConnectEnabled ? dymoSpecificLabels : []),
      ]}
      title={t('Common:PRINT_LABEL')}
      onSelected={onChange}
      {...rest}
    />
  )
}

export default ClientAndPatientLabelMenuDropDown
