import { ApiError, BackendRole, Role } from '@pbt/pbt-ui-components'

import {
  FETCH_GROUP_ROLES,
  FETCH_GROUP_ROLES_FAILURE,
  FETCH_GROUP_ROLES_SUCCESS,
  FETCH_USED_ROLES,
  FETCH_USED_ROLES_FAILURE,
  FETCH_USED_ROLES_SUCCESS,
  UPDATE_GROUP_ROLES,
  UPDATE_GROUP_ROLES_FAILURE,
  UPDATE_GROUP_ROLES_SUCCESS,
  UPDATE_ROLES,
} from './types/roles'

export const updateRoles = (roles: Record<string, BackendRole>) => ({
  type: UPDATE_ROLES,
  roles,
})

export const fetchUsedRoles = () => ({
  type: FETCH_USED_ROLES,
})

export const fetchUsedRolesSuccess = (roles: Role[]) => ({
  type: FETCH_USED_ROLES_SUCCESS,
  roles,
})
export const fetchUsedRolesFailure = (error: ApiError) => ({
  type: FETCH_USED_ROLES_FAILURE,
  error,
})

export const fetchGroupRoles = (businessId: string) => ({
  type: FETCH_GROUP_ROLES,
  businessId,
})

export const fetchGroupRolesSuccess = (roles: string[], groupId: string) => ({
  type: FETCH_GROUP_ROLES_SUCCESS,
  roles,
  groupId,
})
export const fetchGroupRolesFailure = (error: ApiError) => ({
  type: FETCH_GROUP_ROLES_FAILURE,
  error,
})

export const updateGroupRoles = (groupId: string, roleIds: string[]) => ({
  type: UPDATE_GROUP_ROLES,
  groupId,
  roleIds,
})

export const updateGroupRolesSuccess = (roles: string[], groupId: string) => ({
  type: UPDATE_GROUP_ROLES_SUCCESS,
  roles,
  groupId,
})

export const updateGroupRolesFailure = (error: ApiError) => ({
  type: UPDATE_GROUP_ROLES_FAILURE,
  error,
})
