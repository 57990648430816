import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

export type CreatedByCellItem = {
  createdBy: string
}

const CreatedByCell = (item: CreatedByCellItem) => {
  const user = useSelector(getUser(item.createdBy))

  return user ? Utils.getPersonString(user) : ''
}

export default CreatedByCell
