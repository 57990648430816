import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BasePuiDialogProps, Nil } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { MEMBERSHIP_INVITE_LINK_UI_PLACEHOLDER } from '~/constants/communications'
import { sendMembershipHybridInvite } from '~/store/actions/communications'
import { fetchWellnessPlanBoopSignUpMessage } from '~/store/actions/wellnessPlans'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getConversationsIsCreating } from '~/store/reducers/conversations'
import { getUser } from '~/store/reducers/users'
import {
  getWellnessPlanBoopSignUpMessage,
  getWellnessPlansIsLoading,
} from '~/store/reducers/wellnessPlans'
import { EmailEntityConfigRecipient } from '~/types'
import { replaceWithTargetPlaceholder } from '~/utils/requestPaymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'

import NewConversationDialog, {
  ConversationMessageFormattingAreaHandle,
} from './NewConversationDialog'

interface SendHybridMembershipInviteDialogProps extends BasePuiDialogProps {
  businessId: string | Nil
  clientId: string | Nil
  onSent?: () => void
}

const SendHybridMembershipInviteDialog = ({
  clientId,
  businessId,
  onSent,
  onClose,
  open,
}: SendHybridMembershipInviteDialogProps) => {
  const dispatch = useDispatch()
  const conversationDialogRef =
    useRef<ConversationMessageFormattingAreaHandle>(null)
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const isLoading = useSelector(getConversationsIsCreating)
  const signUpMessageIsLoading = useSelector(getWellnessPlansIsLoading)
  const defaultSignUpMessage = useSelector(getWellnessPlanBoopSignUpMessage)
  const { t } = useTranslation('Dialogs')

  const [transport, setTransport] = useState<ConversationTransport>()

  const initialSubject = t(
    'Dialogs:SEND_HYBRID_MEMBERSHIP_INVITE_DIALOG.SUBJECT',
    {
      currentBusinessName: currentBusiness?.name,
    },
  )

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport, client },
    { initialSubject, initialMessage: defaultSignUpMessage },
  )

  const onConversationCreationSuccess = () => {
    if (onSent) {
      onSent()
    }
    if (onClose) {
      onClose()
    }
  }

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getConversationsIsCreating,
    onConversationCreationSuccess,
    createdInfoDialogProps: {
      titleMessageName: t(
        'Dialogs:SEND_HYBRID_MEMBERSHIP_INVITE_DIALOG.CREATED_CONVERSATIONS_INFO_DIALOG_TITLE',
      ),
    },
  })

  const handleSend = (recipients: EmailEntityConfigRecipient[]) => {
    if (validate() && clientId) {
      const uiPlaceholders = {
        [MEMBERSHIP_INVITE_LINK_UI_PLACEHOLDER]:
          MEMBERSHIP_INVITE_LINK_UI_PLACEHOLDER,
      }
      dispatch(
        sendMembershipHybridInvite({
          clientId,
          transport,
          subject: subject.value,
          body: replaceWithTargetPlaceholder(
            message.value,
            uiPlaceholders,
            `<<${MEMBERSHIP_INVITE_LINK_UI_PLACEHOLDER}>>`,
          ),
          recipients,
        }),
      )
      displayConversationCreationResult()
    }
  }

  const updateMessage = (text: string | Nil) => {
    message.setValue(text)
    conversationDialogRef.current?.resetMessageState()
  }

  const updateBodyWhenSignUpMessageIsLoaded = useCloseAfterCreation(
    () => updateMessage(defaultSignUpMessage),
    getWellnessPlansIsLoading,
  )

  useEffect(() => {
    updateBodyWhenSignUpMessageIsLoaded()
    if (businessId && clientId && transport) {
      dispatch(
        fetchWellnessPlanBoopSignUpMessage(businessId, { clientId, transport }),
      )
    }
    updateMessage('')
  }, [transport])

  return (
    <NewConversationDialog
      clientId={clientId}
      handleSend={handleSend}
      handleTransportChange={setTransport}
      isLoading={isLoading}
      isMessageLoading={signUpMessageIsLoading}
      message={message}
      open={open}
      ref={conversationDialogRef}
      subject={subject}
      title={t(
        'Dialogs:SEND_HYBRID_MEMBERSHIP_INVITE_DIALOG.NEW_CONVERSATION_DIALOG_TITLE',
      )}
      to={to}
      transport={transport}
      onClose={onClose}
    />
  )
}

export default SendHybridMembershipInviteDialog
