import React from 'react'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import PercentTextField from '~/components/common/inputs/PercentTextField'

export interface DiscountPercentTextFieldProps {
  active: boolean
  disabled: boolean
  onChange: (value: number) => void
  value: number | undefined
}

const DiscountPercentTextField = ({
  disabled,
  active,
  value,
  onChange,
}: DiscountPercentTextFieldProps) => {
  if (!active) {
    return null
  }

  if (disabled) {
    return (
      <Text variant="body">
        {value ? NumberUtils.toPercentFormat(value) : '—'}
      </Text>
    )
  }

  return <PercentTextField placeholder=" " value={value} onChange={onChange} />
}

export default DiscountPercentTextField
