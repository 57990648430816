import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import PaymentType, {
  PAYMENT_TRANSACTION_STATE,
} from '~/constants/paymentTypes'
import { RhapsodyGoPaymentMethod } from '~/constants/RhapsodyGoPaymentMethod'
import {
  getFeatureToggle,
  getPaymentTransactionState,
} from '~/store/reducers/constants'
import {
  getGoPaymentsMap,
  getGoStripePaymentsMap,
} from '~/store/reducers/payments'
import { ExtendPayment } from '~/types'

export const useGetPaymentReversalOptions = () => {
  const PaymentTransactionState = useSelector(getPaymentTransactionState)
  const goPaymentsMap = useSelector(getGoPaymentsMap)
  const goStripePaymentsMap = useSelector(getGoStripePaymentsMap)

  const isIpoM0EstimatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  const ErrorType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.ERROR,
    PaymentTransactionState,
  )
  const AuthorizedType = Utils.findConstantIdByName(
    PAYMENT_TRANSACTION_STATE.AUTHORIZED,
    PaymentTransactionState,
  )

  return (paymentProp: ExtendPayment) => {
    const rootObjectIsGoPayment = paymentProp.type === PaymentType.GO_PAYMENT
    const rootObjectIsGoStripePayment =
      paymentProp.type === PaymentType.GO_STRIPE_PAYMENT

    const goPaymentId = rootObjectIsGoPayment
      ? paymentProp.id
      : R.prop('goTxId', paymentProp)
    const goStripePaymentId = rootObjectIsGoStripePayment
      ? paymentProp.id
      : R.prop('goStripeTxId', paymentProp)

    const goPayment = goPaymentId ? goPaymentsMap[goPaymentId] : undefined
    const goStripePayment = goStripePaymentId
      ? goStripePaymentsMap[goStripePaymentId]
      : undefined

    const currentGoPayment = goPayment || goStripePayment

    const isPayment = paymentProp.paymentType === PaymentType.PAYMENT
    const isDeposit = paymentProp.paymentType === PaymentType.DEPOSIT

    const isFailedPayment = paymentProp.transactionState === ErrorType
    const isAuth =
      currentGoPayment &&
      currentGoPayment.transactionState === AuthorizedType &&
      currentGoPayment.paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD

    const isPOSRefundAvailable =
      paymentProp.posTxId && paymentProp.refundAvailable
    const isGORefundAvailable =
      currentGoPayment && currentGoPayment?.refundAvailable

    const hasRefundableAmount =
      paymentProp.refundableAmount !== undefined &&
      paymentProp.refundableAmount > 0

    const hasUnappliedAmount =
      paymentProp?.unappliedAmount !== undefined &&
      paymentProp?.unappliedAmount > 0

    const hasRefundOrUnappliedAmount =
      isIpoM0EstimatesEnabled && isDeposit
        ? hasRefundableAmount || hasUnappliedAmount
        : hasRefundableAmount

    const canRefundGoOrPos =
      (isPayment || isDeposit) &&
      !isAuth &&
      (isPOSRefundAvailable || isGORefundAvailable) &&
      (isIpoM0EstimatesEnabled && isDeposit ? hasUnappliedAmount : true) &&
      !isFailedPayment

    return {
      canReverse:
        (isPayment || isDeposit) &&
        !paymentProp.posTxId &&
        !paymentProp.goTxId &&
        !paymentProp.goStripeTxId &&
        !paymentProp.undone &&
        paymentProp.refundableAmount === paymentProp.amount &&
        !isFailedPayment,
      canRefundNonIntegratedPayments:
        // use openRefundPaymentDialog
        (isPayment || isDeposit) &&
        !paymentProp.posTxId &&
        !paymentProp.goTxId &&
        !paymentProp.goStripeTxId &&
        !paymentProp.undone &&
        hasRefundOrUnappliedAmount &&
        !isFailedPayment,
      canRefundGoOrPos, // use openAddPaymentDialog with isRefund: true
      canReverseGoOrPos:
        // use openAddPaymentDialog with isReverse: true
        canRefundGoOrPos && paymentProp.refundableAmount === paymentProp.amount,
    }
  }
}
