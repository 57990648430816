/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CheckOutlined as CheckIcon,
  Clock as ClockIcon,
  MarkerOutlined as MarkerIcon,
  PlusOutlined as PlusIcon,
} from '@pbt/pbt-ui-components/src/icons'

import {
  AppointmentBlockSize,
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import { getWidgetDataIsLoading, getWidgetMeta } from '~/store/duck/landing'

import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import AppointmentBlock from './AppointmentBlock'

const useTodayAppointmentsStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'TodayAppointmentsWidget' },
)

const WidgetWidthTypeBlockSizeMap = {
  [WidgetWidthType.THIRD_WIDTH]: AppointmentBlockSize.SMALL,
  [WidgetWidthType.HALF_WIDTH]: AppointmentBlockSize.MEDIUM,
  [WidgetWidthType.TWO_THIRDS_WIDTH]: AppointmentBlockSize.MEDIUM,
  [WidgetWidthType.FULL_WIDTH]: AppointmentBlockSize.BIG,
}

interface TodayAppointmentsProps {
  data: any
  widthType: WidgetWidthType
}

const TodayAppointments = ({ widthType, data }: TodayAppointmentsProps) => {
  const classes = useTodayAppointmentsStyles()
  const { t } = useTranslation(['Landing'])

  const { scheduled = 0, arrived = 0, admitted = 0, discharged = 0 } = data

  const xs = widthType === WidgetWidthType.THIRD_WIDTH ? 6 : 3
  const centered = [
    WidgetWidthType.HALF_WIDTH,
    WidgetWidthType.TWO_THIRDS_WIDTH,
  ].includes(widthType)

  return (
    <Grid container className={classes.root} p={2} spacing={2}>
      <Grid item xs={xs}>
        <AppointmentBlock
          Icon={ClockIcon}
          centered={centered}
          count={scheduled}
          label={t(
            'Landing:TODAYS_APPOINTMENTS_WIDGET.PATIENT_SCHEDULED_ONE_OR_OTHER',
          )}
          size={WidgetWidthTypeBlockSizeMap[widthType]}
        />
      </Grid>
      <Grid item xs={xs}>
        <AppointmentBlock
          Icon={MarkerIcon}
          centered={centered}
          count={arrived}
          label={t(
            'Landing:TODAYS_APPOINTMENTS_WIDGET.PATIENT_ARRIVED_ONE_OR_OTHER',
          )}
          size={WidgetWidthTypeBlockSizeMap[widthType]}
        />
      </Grid>
      <Grid item xs={xs}>
        <AppointmentBlock
          Icon={PlusIcon}
          centered={centered}
          count={admitted}
          label={t(
            'Landing:TODAYS_APPOINTMENTS_WIDGET.PATIENT_ADMITTED_ONE_OR_OTHER',
          )}
          size={WidgetWidthTypeBlockSizeMap[widthType]}
        />
      </Grid>
      <Grid item xs={xs}>
        <AppointmentBlock
          Icon={CheckIcon}
          centered={centered}
          count={discharged}
          label={t(
            'Landing:TODAYS_APPOINTMENTS_WIDGET.PATIENT_DISCHARGED_ONE_OR_OTHER',
          )}
          size={WidgetWidthTypeBlockSizeMap[widthType]}
        />
      </Grid>
    </Grid>
  )
}

interface TodayAppointmentsWidgetProps extends LandingWidgetProps<any> {
  widthType?: WidgetWidthType
}

const TodayAppointmentsWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  ...rest
}: TodayAppointmentsWidgetProps) => {
  const navigate = useNavigate()
  const data = useSelector(
    getWidgetMeta(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.TODAY_APPOINTMENTS,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.TODAY_APPOINTMENTS,
    ),
  )
  const { t } = useTranslation('Common')

  const handleNavigateToScheduler = () => navigate('/scheduler')

  return (
    <LandingWidget
      canExpand={false}
      component={TodayAppointments}
      data={data}
      isLoading={isLoading}
      navigateToDetailsTooltip={t('Common:GO_TO_SCHEDULER')}
      showSettings={false}
      widthType={widthType}
      onNavigateToDetails={handleNavigateToScheduler}
      {...rest}
    />
  )
}

export default TodayAppointmentsWidget
