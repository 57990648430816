import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { TeamFilter } from '~/types'

import {
  UserSelectAutocomplete,
  UserSelectFilterScope,
} from '../inputs/UserSelect'
import BaseFilter, { FilterOption } from './BaseFilter'

export interface PersonFilterProps {
  anchorEl: any
  displayEmpty?: boolean
  filterScope: UserSelectFilterScope
  hasSelectedOption?: boolean
  humanReadable: string
  includeSearch: boolean
  isLoading: boolean
  label: string
  onChange: (filterOption: FilterOption) => void
  onClose: () => void
  open: boolean
  searchTerm?: string
  serialize: boolean
  setSearchTerm: (term: string) => void
  value: any
}

const PersonFilter = ({
  serialize = true,
  displayEmpty = false,
  onChange,
  onClose,
  value: valueProp,
  humanReadable: humanReadableProp,
  filterScope,
  label,
  ...rest
}: PersonFilterProps) => {
  const value =
    valueProp || valueProp === ''
      ? serialize
        ? valueProp.split(',')
        : valueProp
      : []

  const humanReadable = humanReadableProp ? humanReadableProp.split(', ') : []

  const [selectedOption, setSelectedOption] = useState(value)

  // map '' to null for Anassigned
  const selectedOptionsIds = R.pluck('id', selectedOption as Array<any>).map(
    (item) => (item === '' ? null : item),
  )

  useEffect(() => {
    const options = value.map((id: string, index: number) => {
      const [firstName, lastName] = (humanReadable[index] || '').split(' ')
      return {
        id,
        firstName,
        lastName,
      }
    })

    setSelectedOption(options)
  }, [valueProp])

  const apply = () => {
    const selectedValueProp = serialize
      ? selectedOptionsIds.join(',')
      : selectedOptionsIds
    const selectedHumanReadableProp = selectedOption
      .map(Utils.getPersonString)
      .join(', ')
    if (selectedValueProp === '' && selectedHumanReadableProp === '') {
      onChange({} as FilterOption)
    } else {
      onChange({
        value: selectedValueProp,
        humanReadable: selectedHumanReadableProp,
      })
    }
    onClose()
  }

  const clear = () => {
    setSelectedOption([])
  }

  return (
    <BaseFilter
      hasSelectedOption={Boolean(selectedOption)}
      onApply={apply}
      onClear={clear}
      onClose={onClose}
      {...rest}
    >
      <UserSelectAutocomplete
        multiple
        displayEmpty={displayEmpty}
        filterScope={filterScope}
        label={label}
        teamFilter={TeamFilter.ALL}
        value={selectedOptionsIds}
        onChange={setSelectedOption}
      />
    </BaseFilter>
  )
}

export default PersonFilter
