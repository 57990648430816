import React from 'react'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { CONVERSATION_STATUS_NAME } from '~/constants/communications'
import { filterItemsFromEnum } from '~/utils/communicationsUtils'

import ConversationStatusLabel from '../common/ConversationStatusLabel'

const statusFilterItems = filterItemsFromEnum(CONVERSATION_STATUS_NAME).map(
  (filterItem) => ({
    ...filterItem,
    component: (
      <ConversationStatusLabel status={filterItem.id} variant="default" />
    ),
  }),
)

export interface ConversationStatusFilterProps
  extends CheckboxListFilterProps {}

const ConversationStatusFilter = (props: ConversationStatusFilterProps) => (
  <CheckboxListFilter items={statusFilterItems} {...props} />
)

export default ConversationStatusFilter
