import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import moment from 'moment'
import * as R from 'ramda'
import { DateFormat, LanguageUtils, Text } from '@pbt/pbt-ui-components'

import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import { getSoapId } from '~/store/reducers/soap'
import { AssignedSoapDetail, Estimate, EstimateEvent } from '~/types'

interface EstimateUsedCellProps {
  estimate: Estimate
}

const EstimateUsedCell = ({ estimate }: EstimateUsedCellProps) => {
  const { t } = useTranslation(['Common'])
  const navigate = useNavigate()

  const soapId = useSelector(getSoapId)

  const { assignedSoapDetails, events } = estimate
  const assignedSoaps = R.pluck(
    'soapId',
    assignedSoapDetails || ([] as AssignedSoapDetail[]),
  )

  const getStartDate = (assignedEvent?: EstimateEvent): string | undefined =>
    R.prop('scheduledStartDatetime', assignedEvent)
  const getSoapTypeName = (assignedEvent?: EstimateEvent) =>
    LanguageUtils.getTranslatedFieldName(R.prop('type', assignedEvent))

  const soapScheduledDate = (assignedEvent?: EstimateEvent) => {
    const startDate = getStartDate(assignedEvent)
    return startDate
      ? moment(startDate).format(DateFormat.FULL_DATE_YEAR_ABBREV)
      : ''
  }

  const navigateToSoap = (assignedEventId: string) => {
    const assignedSoapId = assignedSoapDetails?.find(
      (soap: AssignedSoapDetail) => soap?.eventId === assignedEventId,
    )?.soapId

    if (assignedSoapId) {
      navigate(`/soap/${assignedSoapId}`)
    }
  }

  const isAssignedSoapSameAsCurrent = (assignedEventId: string): boolean =>
    assignedSoapDetails && soapId
      ? R.pipe(
          R.find((assignedSoapDetail: AssignedSoapDetail) =>
            R.propEq('eventId', assignedEventId, assignedSoapDetail),
          ),
          R.defaultTo({
            eventId: null,
            soapId: null,
            itemsCopied: null,
          } as AssignedSoapDetail),
          R.complement(R.propEq('soapId', soapId)),
        )(assignedSoapDetails)
      : false

  return (
    <Grid container item>
      {R.propSatisfies((length) => length > 0, 'length', events) ? (
        events?.map((assignedEvent) => (
          <Grid
            container
            item
            justifyContent="space-between"
            key={assignedEvent.id}
          >
            <Text
              inline
              link={
                isAssignedSoapSameAsCurrent(assignedEvent.id) &&
                assignedSoaps.length > 0
              }
              variant="body2"
              onClick={() =>
                isAssignedSoapSameAsCurrent(assignedEvent.id) &&
                navigateToSoap(assignedEvent.id)
              }
            >
              {getSoapTypeName(assignedEvent)}{' '}
              {soapScheduledDate(assignedEvent)}
            </Text>
            <AppointmentStateLabel item={assignedEvent} />
          </Grid>
        ))
      ) : (
        <Text variant="body2">{t('Common:NO')}</Text>
      )}
    </Grid>
  )
}

export default EstimateUsedCell
