/* eslint-disable max-lines */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  AlertIconType,
  ButtonWithLoader,
  ConstantWithColors,
  DateUtils,
  NamedEntity,
  Nil,
  Patient,
  PuiTextField,
  SignatureView,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import EnumSelect from '~/components/common/inputs/EnumSelect'
import { useGetPaymentReversalOptions } from '~/components/dashboard/invoices/payment/hooks/useGetPaymentReversalOptions'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { EstimateState } from '~/constants/invoice'
import {
  InventoryItemState,
  LabTestState,
  OrderType,
  PrescriptionItemState,
  ProcedureState,
} from '~/constants/SOAPStates'
import {
  clearLineItemCandidates,
  fetchInvoice,
  saveEstimateToEvent,
  saveEstimateToSOAP,
  saveInvoice,
  setInvoiceAutoSave,
} from '~/store/actions/finance'
import {
  clearCurrentUnifiedOrder,
  clearOrderValidationErrorMessage,
  fetchUnifiedOrder,
  fetchUnifiedOrderByEntityType,
} from '~/store/actions/orders'
import { reOpenSoap } from '~/store/actions/soap'
import { getHasOpenDialogsByName } from '~/store/duck/dialogs'
import { useBusinessLocalization } from '~/store/hooks/business'
import { useGetEstimateState } from '~/store/hooks/estimate'
import {
  useLogItemStateGetter,
  usePrescriptionDialog,
  useRefillDialog,
} from '~/store/hooks/orders'
import { useCreateOrUpdatePrescription } from '~/store/hooks/prescription'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getEstimateStates,
  getFeatureToggle,
  getInventoryStatuses,
  getInvoiceStates,
  getLabTestsStates,
  getPrescriptionStates,
  getProcedureStatus,
} from '~/store/reducers/constants'
import {
  getAutoSaveInvoice,
  getDeletedLineItemsMap,
  getFinanceIsEstimateDeleting,
  getFinanceIsFetching,
  getFinanceIsLoading,
  getFinanceIsSaving,
  getFinanceLineItemsBundleDiscount,
  getFinanceLineItemsCandidates,
} from '~/store/reducers/finance'
import {
  getOrdersIsSending,
  getOrderValidationErrorMessage,
  getUnifiedOrder,
} from '~/store/reducers/orders'
import {
  getSoapId,
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import {
  BatchInvoice,
  CRUDOrderOptions,
  Estimate,
  ExtendPayment,
  Invoice as InvoiceType,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceOrEstimate,
  Order,
  Payment,
  Prescription,
  Price,
  SaveInvoice,
} from '~/types'
import {
  addOriginalBusinessId,
  arrayToMap,
  createDeepEqualityComparator,
} from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import { haveFieldsChanged } from '~/utils/useFieldsChanged'

import InvoiceLoyaltyPointsBanner from '../membership/InvoiceLoyaltyPointsBanner'
import { EstimatesActions } from './EstimatesActions'
import EstimateStateLabel from './EstimateStateLabel'
import EstimateStateSelect from './EstimateStateSelect'
import InvoiceActions from './InvoiceActions'
import { getUnblockInvoiceMessageByFlags } from './invoiceMessages'
import InvoicePostedLabel from './InvoicePostedLabel'
import InvoiceSavingsBanner from './InvoiceSavingsBanner'
import InvoiceTotalsSection from './InvoiceTotalsSection'
import {
  getHighValue,
  getInvoiceTotals,
  getIsBatchInvoice,
  insertCandidatesIntoGroup,
} from './invoiceUtils'
import InvoiceTypesFromSpain from './locale-invoice-options/spain/InvoiceTypesFromSpain'
import InvoiceTable from './table/InvoiceTable'

const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      overflowY: 'auto',
      height: 514,
    },
    buttonContainer: {
      backgroundColor: theme.colors.tableBackground,
    },
    controlContainer: {
      borderTop: theme.constants.tabBorder,
    },
    nameInput: {
      maxWidth: 540,
    },
    banner: {
      marginBottom: theme.spacing(1),
    },
    stateLabel: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'Invoice' },
)

const redundantInvoicePropsToSave = [
  'orderFilters',
  'subtotal',
  'totalDiscount',
  'totalTax',
  'modificationDate',
  'event',
]

/**
 * additional required invoice props to provide
 * correct processing for status change on back side
 */
const requiredInvoicePropsToUpdateStatus = [
  'id',
  'clientId',
  'type',
  'paidAmount',
  'paidFeeAmount',
  'paidAmountNoFee',
]

const compareInvoice = createDeepEqualityComparator({
  ignoreProperties: ['state', 'stateId'],
})

const hasAllItemsInGroups = (items: any, groups: InvoiceLineItemGroup[]) => {
  const itemsInGroups: { name: string; priceId: string }[] = R.pipe<
    InvoiceLineItemGroup[][],
    InvoiceLineItem[][],
    any[],
    any[]
  >(
    R.pluck('groupedItems'),
    R.flatten,
    R.map(R.pick(['priceId', 'name'])),
  )(groups)

  const groupItemsPriceMap = arrayToMap(
    itemsInGroups,
    R.prop('priceId'),
    R.prop('name'),
  )

  return items.every(
    ({ name, price }: { name: string; price: Price }) =>
      price?.id && groupItemsPriceMap[price.id] === name,
  )
}

type UnsavedInvoiceBeforeMap =
  | BatchInvoice
  | InvoiceOrEstimate
  | Omit<InvoiceOrEstimate | BatchInvoice, 'posted'>

type RefillPrescriptionDataToCreate = {
  options: object
  prescription: Order
}

export interface InvoiceProps {
  clientId: string | Nil
  create?: boolean
  eventId?: string
  fromTimeline?: boolean
  hideControls?: boolean
  invoice: BatchInvoice | InvoiceOrEstimate
  isEstimate?: boolean
  isNew?: boolean
  isOtcInvoice?: boolean
  newEstimateFlow?: boolean
  onClose?: () => void
  onOk?: (id: string | Nil) => void
  patient?: Patient
  prescriptionLineItemId?: string
  prescriptionLineItemType?: OrderType.PRESCRIPTION
  refetchInvoice: () => void
  refillItem?: Prescription
  setAttachingToSoapEstimateId?: (attachingToSoapEstimateId: string) => void
  setSoapToAttachEstimateId?: (soapToAttachEstimateId: string) => void
  soapBusinessId?: string | Nil
  soapId?: string | Nil
}

export interface InvoiceHandle {
  hasUnsavedChanges: () => boolean
  save: ({
    preventShowEstimateApprovalDialog,
    includePosted,
    posted,
  }?: SaveInvoice) => void
}

const Invoice = forwardRef<InvoiceHandle, InvoiceProps>(function Invoice(
  {
    isEstimate = false,
    isOtcInvoice,
    clientId,
    patient,
    create,
    invoice: invoiceProp,
    soapBusinessId,
    soapId,
    eventId,
    onClose,
    onOk,
    isNew,
    hideControls,
    prescriptionLineItemId,
    // we can only open Prescriptions from the timeline
    prescriptionLineItemType = OrderType.PRESCRIPTION,
    refillItem,
    refetchInvoice,
    fromTimeline,
    newEstimateFlow,
    setAttachingToSoapEstimateId,
    setSoapToAttachEstimateId,
  },
  ref,
) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentSoapId = useSelector(getSoapId)
  const isSoapLoading = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const isSaving = useSelector(getFinanceIsSaving)
  const isFetching =
    useSelector(getFinanceIsFetching) || isSoapLoading || isLoadingSoapOrders
  const EstimateStates = useSelector(getEstimateStates)
  const InvoiceStates = useSelector(getInvoiceStates)
  const lineItemsCandidates = useSelector(getFinanceLineItemsCandidates) || []
  const bundleDiscount = useSelector(getFinanceLineItemsBundleDiscount) || 0
  const deletedLineItemsMap = useSelector(getDeletedLineItemsMap)
  const autoSaveInvoice = useSelector(getAutoSaveInvoice)
  const unifiedOrder = useSelector(getUnifiedOrder)
  const business = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId)) || {}
  const validationError = useSelector(getOrderValidationErrorMessage)
  const isAddInvoiceItemDialogOpen = useSelector(
    getHasOpenDialogsByName(DialogNames.ADD_INVOICE_ITEM),
  )
  const ProcedureStatus = useSelector(getProcedureStatus)
  const LabTestStates = useSelector(getLabTestsStates)
  const InventoryStatuses = useSelector(getInventoryStatuses)
  const PrescriptionStates = useSelector(getPrescriptionStates)
  const isEstimateDeleting = useSelector(getFinanceIsEstimateDeleting)

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isNewEstimateStatusFlowEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  type InvoiceItemStateItem = {
    stateIds: NamedEntity[] | ConstantWithColors[]
    states:
      | typeof ProcedureState
      | typeof LabTestState
      | typeof InventoryItemState
      | typeof PrescriptionItemState
  }

  const InvoiceItemState: Record<string, InvoiceItemStateItem> = {
    [OrderType.PROCEDURE]: {
      states: ProcedureState,
      stateIds: ProcedureStatus,
    },
    [OrderType.LAB_TEST]: {
      states: LabTestState,
      stateIds: LabTestStates,
    },
    [OrderType.INVENTORY]: {
      states: InventoryItemState,
      stateIds: InventoryStatuses,
    },
    [OrderType.PRESCRIPTION]: {
      states: PrescriptionItemState,
      stateIds: PrescriptionStates,
    },
  }

  const { t } = useTranslation(['Common', 'Dialogs', 'Invoices', 'Constants'])

  const { isSpanishBusiness } = useBusinessLocalization()
  const logItemStateGetter = useLogItemStateGetter()

  const INVOICE_POST_MESSAGE = t('Invoices:POST_MESSAGE')
  const INVOICE_PAID_STATUS_CONFIRM_MESSAGE = t(
    'Invoices:PAID_STATUS_CONFIRM_MESSAGE',
  )

  const [openOrderValidationErrorDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    () => {
      dispatch(clearOrderValidationErrorMessage())
    },
  )

  useEffect(() => {
    if (validationError) {
      openOrderValidationErrorDialog({
        iconType: AlertIconType.WARN,
        message: validationError,
      })
    }
  }, [validationError])

  const [invoice, setInvoice] = useState(invoiceProp)
  const [currentUnifiedOrderId, setCurrentUnifiedOrderId] = useState(
    prescriptionLineItemId,
  )
  const [currentUnifiedOrderLogType, setCurrentUnifiedOrderLogType] = useState<
    OrderType | undefined
  >(prescriptionLineItemType)
  const [refillPrescriptionDataToCreate, setRefillPrescriptionDataToCreate] =
    useState<RefillPrescriptionDataToCreate>()
  const [includeServiceFee, setIncludeServiceFee] = useState(true)
  const [currentUnifiedOrderItem, setCurrentUnifiedOrderItem] =
    useState<InvoiceLineItem>()

  const isPosted = invoiceProp?.posted || false
  const showLoader = isFetching && !invoice

  const handlePrescriptionDialogClose = () => {
    dispatch(clearCurrentUnifiedOrder())
    setCurrentUnifiedOrderId(undefined)
    setCurrentUnifiedOrderLogType(undefined)
  }

  const onRefillDialogClose = () => {
    setCurrentUnifiedOrderId(undefined)
    setCurrentUnifiedOrderLogType(undefined)
  }

  const [openEstimateApprovalDialog] = useDialog(
    DialogNames.ESTIMATE_APPROVAL_DIALOG,
  )
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const [openRefundPaymentDialog] = useDialog(DialogNames.REFUND_PAYMENT)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openRefillDialog, closeRefillDialog] =
    useRefillDialog(onRefillDialogClose)
  const [openPrescriptionDialog, closePrescriptionDialog] =
    usePrescriptionDialog(handlePrescriptionDialogClose)
  const createOrUpdatePrescription = useCreateOrUpdatePrescription()
  const getPaymentReversalOptions = useGetPaymentReversalOptions()

  const [openPostInvoiceConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.POST_INVOICE,
  })
  const [openInvoicePaidStatusConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.INVOICE_PAID_STATUS_CONFIRM,
  })
  const [openSuggestUnblockInvoiceConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SUGGEST_UNBLOCK_INVOICE,
  })

  useEffect(() => {
    if (currentUnifiedOrderId) {
      dispatch(
        fetchUnifiedOrder(currentUnifiedOrderId, currentUnifiedOrderLogType),
      )
    }
  }, [currentUnifiedOrderId])

  const closeRefillDialogAfterSave = useCloseAfterCreation(
    closeRefillDialog,
    getOrdersIsSending,
  )

  const setCloseAfterConvertPrescriptionUpdate = useCloseAfterCreation(() => {
    dispatch(clearCurrentUnifiedOrder())
    setCurrentUnifiedOrderId(undefined)
    setCurrentUnifiedOrderLogType(undefined)
    closePrescriptionDialog()
  }, getOrdersIsSending)

  const draftStateId = Utils.findConstantIdByName(
    EstimateState.DRAFT,
    EstimateStates,
  )
  const approvedStateId = Utils.findConstantIdByName(
    EstimateState.APPROVED,
    EstimateStates,
  )
  const expiredApprovedStateId = Utils.findConstantIdByName(
    EstimateState.EXPIRED_APPROVED,
    EstimateStates,
  )

  const openStateId = Utils.findConstantIdByName('Open', InvoiceStates)
  const paidStateId = Utils.findConstantIdByName('Paid', InvoiceStates)
  const voidedStateId = Utils.findConstantIdByName('Voided', InvoiceStates)

  const isVoidedState = invoiceProp.stateId === voidedStateId

  const PreparedInvoiceStates = isVoidedState
    ? InvoiceStates
    : InvoiceStates.filter((item) => item.id !== voidedStateId)

  const StateConstants = isEstimate ? EstimateStates : InvoiceStates

  const isApprovedState = (stateId: string) =>
    stateId === approvedStateId || stateId === expiredApprovedStateId

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        validators: ['required'],
        initialValue: invoiceProp.name
          ? invoiceProp.name
          : `${t('Constants:INVOICE_TYPE.ESTIMATE')}: ${Utils.getPersonString(
              client,
            )} ${patient ? `| ${patient.name}` : ''}`,
      },
      {
        name: 'state',
        initialValue:
          invoiceProp.stateId || (isEstimate ? draftStateId : openStateId),
      },
    ],
    false,
  )

  const { name, state } = fields

  const isPaid = !isEstimate && state.value === paidStateId

  const resetInvoice = () => {
    setInvoice({ ...invoiceProp })
  }

  useDeepCompareEffect(() => {
    if (!isSaving) {
      resetInvoice()
      reset()
    }
  }, [invoiceProp, isSaving])

  useEffect(() => {
    if (lineItemsCandidates.length === 0) {
      return
    }
    const { invoiceId } = R.head(lineItemsCandidates) || ({} as InvoiceLineItem)
    const childInvoice =
      invoiceId &&
      invoice.invoices &&
      R.find(R.propEq('id', invoiceId), invoice.invoices as InvoiceType[])
    const updateChildInvoice = Boolean(childInvoice)
    const childInvoiceIndex =
      updateChildInvoice &&
      (invoice.invoices as InvoiceType[]).indexOf(childInvoice as InvoiceType)
    const invoiceToUpdate = childInvoice || invoice
    const groupsToUpdate = invoiceToUpdate.groups || []

    const invoiceOtcGroupIndex = groupsToUpdate.findIndex(
      (group) => !group.soap,
    )

    let newGroups

    if (invoiceOtcGroupIndex > -1) {
      const newGroup = insertCandidatesIntoGroup(
        lineItemsCandidates,
        groupsToUpdate[invoiceOtcGroupIndex],
      )
      newGroups = R.update(invoiceOtcGroupIndex, newGroup, groupsToUpdate)
    } else {
      const newGroup = insertCandidatesIntoGroup(lineItemsCandidates, {
        groupedItems: [],
      } as InvoiceLineItemGroup)
      newGroups = groupsToUpdate.concat(newGroup)
    }

    const updatedInvoice: BatchInvoice | InvoiceOrEstimate = {
      ...invoiceToUpdate,
      groups: newGroups,
      bundleAdditionalDiscount:
        (invoice.bundleAdditionalDiscount || 0) + bundleDiscount,
    }

    if (updateChildInvoice && childInvoiceIndex) {
      setInvoice({
        ...invoice,
        invoices: R.update(
          childInvoiceIndex,
          updatedInvoice,
          invoice.invoices as InvoiceType[],
        ),
      } as BatchInvoice)
    } else {
      setInvoice(updatedInvoice)
    }

    dispatch(clearLineItemCandidates())
  }, [lineItemsCandidates])

  const getItemToSave = (item: InvoiceLineItem): InvoiceLineItem => {
    if (item.items) {
      return {
        ...item,
        items: item.items.map(getItemToSave),
      }
    }

    const { states, stateIds } = InvoiceItemState[item.logType]

    const checkIsUnDeclined = R.allPass([
      R.complement(R.prop('declined')),
      R.propEq('state', states.DECLINED.toUpperCase()),
    ])

    if (checkIsUnDeclined(item)) {
      const stateName =
        OrderType.LAB_TEST && item.id ? LabTestState.SELECTED : states.ORDERED

      const getStateId = R.pipe(
        R.find(
          (stateId: NamedEntity | ConstantWithColors) =>
            stateId.name === stateName,
        ),
        R.prop('id'),
      )

      return {
        ...item,
        state: stateName,
        stateId: getStateId(stateIds) || '',
      }
    }
    return item.producerIdChanged ? R.omit(['producerIdChanged'], item) : item
  }

  const getInvoiceToSave = (
    baseInvoice: UnsavedInvoiceBeforeMap,
    stateIdProp?: number | string,
  ): any => {
    const stateId = stateIdProp || state.value
    const isStateChanged = stateId !== state.initialValue
    return {
      ...R.omit(redundantInvoicePropsToSave, baseInvoice),
      ...(isEstimate ? { name: name.value } : {}),
      stateId: isStateChanged ? stateId : baseInvoice.stateId,
      state: isStateChanged
        ? Utils.getConstantName(stateId, StateConstants)
        : baseInvoice.state,
      invoices: (baseInvoice.invoices as InvoiceType[])?.map(getInvoiceToSave),
      client: {
        id: baseInvoice.client,
      },
      patient: {
        id: baseInvoice.patient,
      },
      groups: (baseInvoice.groups || [])
        .filter((group: InvoiceLineItemGroup) => !group?.soap?.finalized)
        .map((group: InvoiceLineItemGroup) => ({
          ...group,
          groupedItems: group.groupedItems.map(getItemToSave),
        })),
      deletedItems:
        (baseInvoice?.id && deletedLineItemsMap[baseInvoice.id]) || [],
      ...// clear signature if state is set to draft
      (isEstimate && !isApprovedState(stateId)
        ? {
            signatureUrl: null,
            signedDate: null,
            signer: null,
            signerId: null,
            signerName: null,
          }
        : {}),
    }
  }

  const handleUpdateAppointments = (
    eventsIds: string[] = [],
    soapIds: string[] = [],
    invoiceUpd: InvoiceOrEstimate,
  ) => {
    if (eventsIds.length > 0 || soapIds.length > 0) {
      dispatch(
        saveInvoice({
          ...getInvoiceToSave(invoiceUpd, invoiceUpd.stateId),
          soapIdsToCopy: soapIds,
          eventIdsToCopy: eventsIds,
        }),
      )
    }
  }

  const showEstimateApprovalDialog = () => {
    if (isEstimate && (invoiceProp as Estimate).events?.length) {
      const isApproved =
        invoiceProp?.stateId && isApprovedState(invoiceProp.stateId)

      openEstimateApprovalDialog({
        title: isApproved
          ? t('Dialogs:ESTIMATE_APPROVAL_DIALOG.TITLE_ESTIMATE_APPROVED')
          : t('Dialogs:ESTIMATE_APPROVAL_DIALOG.TITLE_ESTIMATE_NOT_APPROVED'),
        cancelButtonLabel: t('Common:CANCEL_ACTION'),
        okButtonLabel: t('Common:CONTINUE_ACTION'),
        labelWithoutSoap: isApproved
          ? t('Dialogs:ESTIMATE_APPROVAL_DIALOG.SUB_ITEM_LABEL_WITHOUT_SOAP')
          : t(
              'Dialogs:ESTIMATE_APPROVAL_DIALOG.SUB_ITEM_LABEL_WITHOUT_SOAP_NOT_APPROVED',
            ),

        invoiceId: invoiceProp.id,
        onConfirm: handleUpdateAppointments,
      })
    }
  }

  const showEstimateDeclineDialog = () => {
    const refundablePaymentsWithUnappliedAmount = (
      (invoice?.payments || []) as ExtendPayment[]
    ).filter((payment) => {
      const { canRefundNonIntegratedPayments, canRefundGoOrPos } =
        getPaymentReversalOptions(payment)
      return (
        payment.unappliedAmount !== undefined &&
        payment.unappliedAmount > 0 &&
        (canRefundNonIntegratedPayments || canRefundGoOrPos)
      )
    })

    if (isEstimate && refundablePaymentsWithUnappliedAmount.length > 0) {
      openAlert({
        cancelButtonText: t('Common:NO'),
        iconType: AlertIconType.WARN,
        message: t(
          'Dialogs:ESTIMATE_APPROVAL_DIALOG.DECLINE_PAID_DEPOSIT_REFUND',
        ),
        okButtonText: t('Dialogs:ESTIMATE_APPROVAL_DIALOG.YES_REFUND_DEPOSIT'),
        onCancel: () => {
          closeAlert()
        },
        onOk: () => {
          const payment = refundablePaymentsWithUnappliedAmount[0]
          const { canRefundNonIntegratedPayments, canRefundGoOrPos } =
            getPaymentReversalOptions(payment)
          if (canRefundNonIntegratedPayments) {
            openAddPaymentDialog({
              payment: refundablePaymentsWithUnappliedAmount[0],
              isRefund: true,
              clientId,
            })
          } else if (canRefundGoOrPos) {
            openRefundPaymentDialog({
              payment: refundablePaymentsWithUnappliedAmount[0],
              clientId,
            })
          }

          closeAlert()
        },
      })
    }
  }

  const showEstimateApprovalAfterCreationOn = useCloseAfterCreation(
    showEstimateApprovalDialog,
    getFinanceIsLoading,
  )

  const showEstimateDeclineAfterCreationOn = useCloseAfterCreation(
    showEstimateDeclineDialog,
    getFinanceIsLoading,
  )

  const getEstimateState = useGetEstimateState()

  const save = ({
    preventShowEstimateApprovalDialog,
    includePosted = false,
    posted,
  }: SaveInvoice = {}) => {
    if (isEstimate && !validate()) {
      return
    }
    const onlyInvoiceStateChanged =
      state.value !== state.initialValue &&
      compareInvoice(invoice, invoiceProp) &&
      !isEstimate

    const invoiceWithoutPosted = R.omit(['posted'], invoice)
    const invoiceHasAlreadyBeenPosted = getIsBatchInvoice(invoice)
      ? (invoice.invoices as InvoiceType[]).every((i) => i.posted)
      : invoice.posted
    let newInvoice = getInvoiceToSave(invoiceWithoutPosted)

    // For posted batch and single invoices we should not pass the whole invoice object as we should only able to change the state
    if (
      onlyInvoiceStateChanged ||
      (invoiceHasAlreadyBeenPosted && R.isNil(posted))
    ) {
      newInvoice = {
        ...R.pick(requiredInvoicePropsToUpdateStatus, newInvoice),
        stateId: state.value,
        state: Utils.getConstantName(state.value, StateConstants),
        ...(invoice?.invoices
          ? {
              invoices: (invoice.invoices as InvoiceType[])?.map((child) => ({
                ...R.pick(requiredInvoicePropsToUpdateStatus, child),
                stateId: state.value,
                state: Utils.getConstantName(state.value, StateConstants),
              })),
            }
          : {}),
      }
    }

    if (includePosted) {
      newInvoice.posted = posted
      newInvoice.invoices = newInvoice.invoices?.map(
        (invoiceItem: InvoiceOrEstimate) => ({ ...invoiceItem, posted }),
      )
    }

    if (isEstimate && !preventShowEstimateApprovalDialog) {
      const wasApproved =
        invoiceProp?.stateId && isApprovedState(invoiceProp.stateId)
      const isApproved = isApprovedState(newInvoice.stateId)
      if (
        // estimate is Not Approved, we may need to remove charges
        (!isApproved && wasApproved) ||
        // estimate is Approved and has changes, we may need to add charges
        (isApproved &&
          (!wasApproved || !deepEqual(invoiceProp.groups, newInvoice.groups)))
      ) {
        showEstimateApprovalAfterCreationOn()
      }
    }

    if (
      isEstimate &&
      getEstimateState(newInvoice.stateId).isDeclined &&
      !getEstimateState(invoiceProp.stateId).isDeclined
    ) {
      showEstimateDeclineAfterCreationOn()
    }

    if (isEstimate && soapId && !create && !newEstimateFlow) {
      dispatch(saveEstimateToSOAP(newInvoice, soapId))
    } else if (isEstimate && eventId && !create && !newEstimateFlow) {
      dispatch(
        saveEstimateToEvent({
          allowNewSoap: false,
          estimate: newInvoice,
          eventId,
        }),
      )
    } else {
      dispatch(saveInvoice(newInvoice, isNew))
    }
  }

  const post = () => {
    openPostInvoiceConfirmAlert({
      applyCustomMessage: true,
      message: INVOICE_POST_MESSAGE,
      okButtonText: t('Common:YES_POST_INVOICE'),
      cancelButtonText: t(
        'Dialogs:CONFIRM_ALERT_DIALOG.POST_INVOICE.CANCEL_BUTTON',
      ),
      onConfirm: (proceed: boolean) => {
        if (proceed) {
          save({ posted: true, includePosted: true })
        }
      },
    })
  }

  const reopen = () => {
    save({ posted: false, includePosted: true })
  }

  const handleUnblockEditInvoice = useCallback(
    (invoiceEdit: InvoiceOrEstimate, soap: InvoiceLineItemGroup['soap']) => {
      const unblockInvoiceMessage = getUnblockInvoiceMessageByFlags(
        Boolean(soap?.finalized),
        invoiceEdit?.posted,
        Boolean(currentSoapId),
      )
      if (unblockInvoiceMessage) {
        openSuggestUnblockInvoiceConfirmAlert({
          preventShowAgainCheckBox: false,
          applyCustomMessage: true,
          message: unblockInvoiceMessage,
          okButtonText: t('Common:YES'),
          cancelButtonText: t('Common:CANCEL_ACTION'),
          onConfirm: (proceed: boolean) => {
            if (proceed) {
              dispatch(reOpenSoap(soap?.id))
              save({
                posted: false,
                includePosted: true,
              })
              dispatch(fetchInvoice(invoiceEdit?.id))
            }
          },
        })
      }
    },
    [currentSoapId],
  )

  useEffect(() => {
    if (state.value === state.initialValue) {
      return
    }
    if (isPaid) {
      openInvoicePaidStatusConfirmAlert({
        applyCustomMessage: true,
        message: INVOICE_PAID_STATUS_CONFIRM_MESSAGE,
        okButtonText: t('Common:YES_POST_INVOICE'),
        additionalButtons: [
          <ButtonWithLoader key="save-without-post">
            {t(
              'Dialogs:CONFIRM_ALERT_DIALOG.INVOICE_PAID_STATUS.SAVE_WITHOUT_POSTING',
            )}
          </ButtonWithLoader>,
        ],
        onConfirm: (proceed: boolean, key?: React.Key | Nil) => {
          if (proceed) {
            if (key === 'save-without-post') {
              save()
            } else {
              save({
                posted: true,
                includePosted: true,
              })
            }
          } else {
            state.setValue(state.initialValue)
          }
        },
      })
    } else {
      setInvoice({
        ...invoice,
        stateId: state.value,
        state: Utils.getConstantName(state.value, StateConstants),
        ...(invoice?.invoices
          ? {
              invoices: (invoice.invoices as InvoiceType[])?.map((child) => ({
                ...child,
                stateId: state.value,
                state: Utils.getConstantName(state.value, StateConstants),
              })),
            }
          : {}),
      } as BatchInvoice)
    }
  }, [state.value])

  const hasNoPayments = !invoice.payments?.length
  const invoiceHasNoPaymentsWithServiceFee = () =>
    invoice.payments &&
    R.none((p: Payment) => Boolean(p.serviceFeeIncAmount), invoice.payments)

  useEffect(() => {
    if (isPaid && (hasNoPayments || invoiceHasNoPaymentsWithServiceFee())) {
      setIncludeServiceFee(false)
    }
  }, [isPaid])

  useEffect(() => {
    if (autoSaveInvoice && autoSaveInvoice.no === invoice.invoiceNo) {
      const lineItemsCreated =
        !autoSaveInvoice.items?.length ||
        hasAllItemsInGroups(autoSaveInvoice.items, invoice.groups!)
      if (lineItemsCreated) {
        save()
        dispatch(setInvoiceAutoSave(undefined))
      }
    }
  }, [autoSaveInvoice, invoice.groups])

  const totals = getInvoiceTotals(invoice)

  useEffect(() => {
    if (lineItemsCandidates.length > 0) {
      return
    }
    if (totals.totalWithoutTax) {
      const discountedTotal = getHighValue(totals.subTotal)
      if (
        invoice?.additionalDiscount &&
        invoice.additionalDiscount > discountedTotal
      ) {
        setInvoice({ ...invoice, additionalDiscount: discountedTotal })
      }
    } else if (invoice.additionalDiscount) {
      setInvoice({ ...invoice, additionalDiscount: 0 })
    }
  }, [invoice])

  const handleSavePrescription = (
    prescription: Order,
    options: CRUDOrderOptions = {},
    closeAfterSaving: boolean,
  ) => {
    if (closeAfterSaving) {
      setCloseAfterConvertPrescriptionUpdate()
    }

    const prescriptionWithState = !prescription.stateId
      ? logItemStateGetter(prescription)
      : prescription

    createOrUpdatePrescription(prescriptionWithState!, {
      ...options,
      outsideSoap: true,
      invoiceId: invoice.id,
    })
  }

  const handleCreateRefill = (prescription: Order, options = {}) => {
    dispatch(setInvoiceAutoSave(invoice.invoiceNo, []))
    setRefillPrescriptionDataToCreate({ prescription, options })
  }

  useEffect(() => {
    if (invoice.id && refillPrescriptionDataToCreate) {
      createOrUpdatePrescription(
        logItemStateGetter(refillPrescriptionDataToCreate.prescription)!,
        {
          ...refillPrescriptionDataToCreate.options,
          outsideSoap: true,
          invoiceId: invoice.id,
        },
      )
      setRefillPrescriptionDataToCreate(undefined)
      closeRefillDialogAfterSave()
    }
  }, [invoice.id, refillPrescriptionDataToCreate])

  const handleUpdatePrescriptionLineItem = (item: InvoiceLineItem) => {
    if (item.logId) {
      setCurrentUnifiedOrderId(item.logId)
      setCurrentUnifiedOrderLogType(item.logType)
      setCurrentUnifiedOrderItem(item)
      // trying to get order item by price entity
    } else if (item.priceId) {
      dispatch(fetchUnifiedOrderByEntityType(item.priceId, OrderType.PRICE))
      setCurrentUnifiedOrderItem(item)
    }
  }

  const getPrescriptionWithQuantities = () => ({
    ...unifiedOrder,
    lineItem: currentUnifiedOrderItem
      ? {
          ...unifiedOrder?.lineItem,
          prepaidRemaining: currentUnifiedOrderItem.prepaidRemaining,
          usedQuantity: currentUnifiedOrderItem.usedQuantity,
          quantity: currentUnifiedOrderItem.quantity,
        }
      : unifiedOrder?.lineItem,
    quantity: currentUnifiedOrderItem
      ? R.isNil(currentUnifiedOrderItem.usedQuantity) ||
        !currentUnifiedOrderItem.prepaid
        ? currentUnifiedOrderItem.quantity
        : currentUnifiedOrderItem.usedQuantity
      : unifiedOrder?.quantity,
  })

  useEffect(() => {
    if (refillItem && invoice.invoiceNo) {
      openRefillDialog({
        outsideSoap: true,
        prescription: {
          ...R.omit(['id'], refillItem),
          parent: refillItem,
        },
        autoClose: false,
        onSave: handleCreateRefill,
      })
    }
  }, [refillItem, invoice.invoiceNo])

  useEffect(() => {
    if (unifiedOrder && unifiedOrder.parentId) {
      openRefillDialog({
        outsideSoap: true,
        prescription: getPrescriptionWithQuantities(),
        onSave: (prescription: Order, options: CRUDOrderOptions) =>
          handleSavePrescription(prescription, options, true),
      })
    }

    if (unifiedOrder && !unifiedOrder.parentId) {
      openPrescriptionDialog({
        outsideSoap: true,
        clientId,
        patientId: patient?.id || invoiceProp?.patientId,
        prescription: getPrescriptionWithQuantities(),
        onSave: handleSavePrescription,
      })
    }
  }, [unifiedOrder])

  const footnote =
    (isEstimate && business?.estimateNotes) ||
    (!isEstimate && business?.invoiceNotes)

  const hasUnsavedChanges = () => {
    const hasInvoiceChanged = !deepEqual(invoice, invoiceProp)
    const hasChangesInFields = haveFieldsChanged(fields)
    return hasInvoiceChanged || hasChangesInFields
  }

  useImperativeHandle(ref, () => ({
    save,
    hasUnsavedChanges,
  }))

  useEffectExceptOnMount(() => {
    if (!isEstimateDeleting) {
      onOk?.(
        soapId &&
          addOriginalBusinessId(
            `/soap/${soapId}/order`,
            isPatientSharingEnabled ? soapBusinessId : null,
          ),
      )
    }
  }, [isEstimateDeleting])

  return (
    <Grid container direction="column">
      {!isEstimate && (
        <>
          <InvoiceLoyaltyPointsBanner
            className={classes.banner}
            invoice={invoice as InvoiceType | BatchInvoice}
          />
          <InvoiceSavingsBanner
            className={classes.banner}
            invoice={invoice as InvoiceType | BatchInvoice}
          />
        </>
      )}
      {showLoader && !isAddInvoiceItemDialogOpen ? (
        <Grid
          container
          item
          alignItems="center"
          className={classes.tableContainer}
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item className={classes.tableContainer} px={1}>
          <>
            {isEstimate && (
              <Grid container item direction="column" pl={3}>
                <PuiTextField
                  className={classes.nameInput}
                  field={name}
                  inputProps={{ maxLength: 100 }}
                  label={t('Invoices:ESTIMATE_NAME')}
                />
              </Grid>
            )}
            <InvoiceTable
              create={create}
              invoice={invoice}
              isEstimate={isEstimate}
              isOtcInvoice={isOtcInvoice}
              isReadOnly={isPaid || showLoader || isPosted || isFetching}
              originalInvoice={invoiceProp}
              updateInvoice={setInvoice}
              onUnblockEditInvoice={handleUnblockEditInvoice}
              onUpdatePrescriptionLineItem={handleUpdatePrescriptionLineItem}
            />
          </>
        </Grid>
      )}
      {!hideControls && (
        <Grid container item className={classes.controlContainer}>
          <Grid
            container
            item
            xs
            className={classes.buttonContainer}
            direction="column"
            pb={2}
            pl={2}
            pr={1}
            pt={1}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                {footnote && (
                  <Grid container item wrap="nowrap">
                    <TextWithTooltip
                      link
                      noIcon
                      display="inline"
                      tooltipText={footnote}
                      variant="lowAccent3"
                    >
                      {t('Common:INPUTS.RICH_EDIT.OPTIONS.FOOTNOTE')}
                    </TextWithTooltip>
                  </Grid>
                )}
                {!isEstimate && (
                  <Grid container alignItems="center">
                    <EnumSelect
                      accent
                      Constant={PreparedInvoiceStates}
                      disabled={isFetching || isVoidedState}
                      field={state}
                    />
                    <InvoicePostedLabel
                      invoice={invoice as BatchInvoice | InvoiceType}
                    />
                  </Grid>
                )}
                {invoice.signatureUrl && (
                  <Grid>
                    <SignatureView
                      signatureDate={DateUtils.formatDateWithHours(
                        invoice.signedDate,
                      )}
                      signatureString={invoice.signatureUrl}
                      signerName={invoice.signerName}
                    />
                  </Grid>
                )}
                <Grid container item xs alignItems="flex-end">
                  {isEstimate &&
                    !isFetching &&
                    (isNewEstimateStatusFlowEnabled ? (
                      <Grid container alignItems="flex-end">
                        <Text strong variant="body2">
                          {`${t('Common:STATUS')}: `}
                        </Text>
                        <EstimateStateLabel
                          className={classes.stateLabel}
                          estimate={{ stateId: state.value }}
                        />
                      </Grid>
                    ) : (
                      <EstimateStateSelect field={state} />
                    ))}
                </Grid>
              </Grid>
              {isSpanishBusiness && <InvoiceTypesFromSpain invoice={invoice} />}
            </Grid>
            {isEstimate && isNewEstimateStatusFlowEnabled ? (
              <EstimatesActions
                clientId={clientId}
                estimateStateField={state}
                eventId={eventId}
                fromTimeline={fromTimeline}
                hasUnsavedChanges={hasUnsavedChanges}
                includeServiceFee={includeServiceFee}
                invoice={invoice as Estimate}
                isNew={isNew}
                isPosted={isPosted}
                refetchInvoice={refetchInvoice}
                resetInvoice={resetInvoice}
                save={save}
                setAttachingToSoapEstimateId={setAttachingToSoapEstimateId}
                setSoapToAttachEstimateId={setSoapToAttachEstimateId}
                showEstimateApprovalAfterCreationOn={
                  showEstimateApprovalAfterCreationOn
                }
                soapId={soapId}
                onClose={onClose}
                onOk={onOk}
              />
            ) : (
              <InvoiceActions
                clientId={clientId}
                eventId={eventId}
                fromTimeline={fromTimeline}
                hasUnsavedChanges={hasUnsavedChanges}
                includeServiceFee={includeServiceFee}
                invoice={invoice}
                isEstimate={isEstimate}
                isNew={isNew}
                isPaid={isPaid}
                isPosted={isPosted}
                newEstimateFlow={newEstimateFlow}
                post={post}
                refetchInvoice={refetchInvoice}
                reopen={reopen}
                resetInvoice={resetInvoice}
                save={save}
                setAttachingToSoapEstimateId={setAttachingToSoapEstimateId}
                setSoapToAttachEstimateId={setSoapToAttachEstimateId}
                showEstimateApprovalAfterCreationOn={
                  showEstimateApprovalAfterCreationOn
                }
                soapId={soapId}
                onOk={onOk}
              />
            )}
          </Grid>
          <InvoiceTotalsSection
            clientId={clientId}
            includeServiceFee={includeServiceFee}
            invoice={invoice}
            isEstimate={isEstimate}
            setIncludeServiceFee={setIncludeServiceFee}
            setInvoice={setInvoice}
            totals={totals}
          />
        </Grid>
      )}
    </Grid>
  )
})

export default Invoice
