export const UPDATE_APPOINTMENT_TYPES =
  'appointmentTypes/UPDATE_APPOINTMENT_TYPES'

export const FETCH_APPOINTMENT_TYPES_LIST =
  'appointmentTypes/FETCH_APPOINTMENT_TYPES_LIST'
export const FETCH_APPOINTMENT_TYPES_LIST_SUCCESS =
  'appointmentTypes/FETCH_APPOINTMENT_TYPES_LIST_SUCCESS'
export const FETCH_APPOINTMENT_TYPES_LIST_FAILURE =
  'appointmentTypes/FETCH_APPOINTMENT_TYPES_LIST_FAILURE'

export const FETCH_MORE_APPOINTMENT_TYPES_LIST =
  'appointmentTypes/FETCH_MORE_APPOINTMENT_TYPES_LIST'
export const FETCH_MORE_APPOINTMENT_TYPES_LIST_SUCCESS =
  'appointmentTypes/FETCH_MORE_APPOINTMENT_TYPES_LIST_SUCCESS'
export const FETCH_MORE_APPOINTMENT_TYPES_LIST_FAILURE =
  'appointmentTypes/FETCH_MORE_APPOINTMENT_TYPES_LIST_FAILURE'

export const CREATE_APPOINTMENT_TYPE =
  'appointmentTypes/CREATE_APPOINTMENT_TYPE'
export const CREATE_APPOINTMENT_TYPE_SUCCESS =
  'appointmentTypes/CREATE_APPOINTMENT_TYPE_SUCCESS'
export const CREATE_APPOINTMENT_TYPE_FAILURE =
  'appointmentTypes/CREATE_APPOINTMENT_TYPE_FAILURE'

export const UPDATE_APPOINTMENT_TYPE =
  'appointmentTypes/UPDATE_APPOINTMENT_TYPE'
export const UPDATE_APPOINTMENT_TYPE_SUCCESS =
  'appointmentTypes/UPDATE_APPOINTMENT_TYPE_SUCCESS'
export const UPDATE_APPOINTMENT_TYPE_FAILURE =
  'appointmentTypes/UPDATE_APPOINTMENT_TYPE_FAILURE'

export const SET_APPOINTMENT_TYPES_LIST_FILTERS =
  'appointmentTypes/SET_APPOINTMENT_TYPES_LIST_FILTERS'
export const SET_APPOINTMENT_TYPES_LIST_SORTINGS =
  'appointmentTypes/SET_APPOINTMENT_TYPES_LIST_SORTINGS'

export const CLEAR_APPOINTMENT_TYPE_ERROR =
  'appointmentTypes/CLEAR_APPOINTMENT_TYPE_ERROR'
