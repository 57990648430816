import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { moment, PermissionArea, Text } from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import {
  deleteAdjustment,
  editAdjustment,
  fetchAdjustment,
  getAdjustment,
  getAdjustmentsIsDeleting,
  getAdjustmentsIsFetching,
  getAdjustmentsIsUpdating,
} from '~/store/duck/inventoryAdjustments'
import { getCRUDByArea } from '~/store/reducers/auth'
import { Adjustment } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import AdjustmentComponent, { AdjustmentHandle } from './Adjustment'

export interface AdjustmentDetailsProps {
  itemId: string
}

const AdjustmentDetails = ({
  itemId: adjustmentId,
}: AdjustmentDetailsProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const adjustmentRef = useRef<AdjustmentHandle>(null)

  const isUpdating = useSelector(getAdjustmentsIsUpdating)
  const isDeleting = useSelector(getAdjustmentsIsDeleting)
  const isFetching = useSelector(getAdjustmentsIsFetching)
  const adjustment = useSelector(getAdjustment(adjustmentId))

  const [openAdjustmentDialog] = useDialog(DialogNames.ADJUSTMENT_DIALOG)

  const permissions = useSelector(getCRUDByArea(PermissionArea.ADJUSTMENTS))

  const handleBackButtonClick = () =>
    navigate('/admin/catalog/inventories/adjustments')

  const readOnly = Boolean(adjustment?.id)

  const handleFetchAdjustment = () => {
    if (adjustmentId) {
      dispatch(fetchAdjustment(adjustmentId))
    }
  }

  useEffect(() => {
    handleFetchAdjustment()
  }, [adjustmentId])

  const setCloseAfterDelete = useCloseAfterCreation(
    handleBackButtonClick,
    getAdjustmentsIsDeleting,
  )

  const onSaveRequested = () => {
    if (adjustmentRef.current?.validate()) {
      const newAdjustment = adjustmentRef.current?.get() as Adjustment
      dispatch(editAdjustment(newAdjustment))
    }
  }

  const onCloneRequested = () => {
    const adjustmentClone = {
      ...R.omit(['id'], adjustmentRef.current?.get()),
      creationDate: moment().toISOString(),
    } as Adjustment
    openAdjustmentDialog({
      onSave: () => {
        handleFetchAdjustment()
      },
      adjustment: adjustmentClone,
    })
  }

  const onDeleteRequested = () => {
    setCloseAfterDelete()
    dispatch(deleteAdjustment(adjustmentId))
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:ADJUSTMENT').toLowerCase()}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isUpdating}
      onBack={handleBackButtonClick}
      onCloneRequested={permissions.create ? onCloneRequested : undefined}
      onDeleteRequested={
        permissions.delete && !readOnly ? onDeleteRequested : undefined
      }
      onSaveRequested={
        permissions.update && !readOnly ? onSaveRequested : undefined
      }
    >
      <Text mb={2} variant="h3">
        {adjustment?.name
          ? t('Admin:CATALOG.ADJUSTMENT_DETAILS.ADJUSTMENT_FOR', {
              adjustmentName: adjustment?.name,
            })
          : t('Common:ADJUSTMENT')}
      </Text>
      <AdjustmentComponent adjustment={adjustment} ref={adjustmentRef} />
    </Expander>
  )
}

export default AdjustmentDetails
