import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'

import Link from '~/components/common/link/Link'
import { QuestionTimelineItem } from '~/types'
import { isDifferedBusinessList } from '~/utils/businessUtils'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'
import MultipleQuestions from './MultipleQuestions'
import OneQuestion from './OneQuestion'

const useStyles = makeStyles(
  (theme) => ({
    questionRoot: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  { name: 'QuestionCard' },
)

interface QuestionCardProps extends TimelineCardProps {
  item: QuestionTimelineItem
}

const QuestionCard = ({ item, ...rest }: QuestionCardProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const hasMultipleQuestions = item.entries.length > 1
  const firstQuestion = item.entries[0]

  const isContextFirstItem = useIsCurrentContextItem(firstQuestion)

  const hasDifferedBusinessEntries = isDifferedBusinessList(item.entries)

  return (
    <TimelineCard
      originBusinessId={firstQuestion.businessId}
      title={item.questionText}
      {...item}
      {...rest}
    >
      <TimelineCardContent noPadding noTypography>
        <OneQuestion
          classes={{ root: classes.questionRoot }}
          question={firstQuestion}
          showAppointmentButton={hasMultipleQuestions}
        />
        {hasMultipleQuestions && (
          <MultipleQuestions
            enableBusinessShareIcon={hasDifferedBusinessEntries}
            questions={item.entries.slice(1)}
          />
        )}
      </TimelineCardContent>
      {!hasMultipleQuestions &&
        isContextFirstItem &&
        firstQuestion.appointmentId && (
          <TimelineCardActions>
            <Link to={`/scheduler/schedule/${firstQuestion.appointmentId}`}>
              {t('VIEW_APPOINTMENT')}
            </Link>
          </TimelineCardActions>
        )}
    </TimelineCard>
  )
}

export default QuestionCard
