import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

import { getCurrentBusiness } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      textTransform: 'none',
      fontSize: '1.4rem',
      color: theme.colors.link,
      textDecoration: 'underline',
      marginLeft: theme.spacing(2),
      fontWeight: 400,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    disabledLink: {
      pointerEvents: 'none',
      color: '#bdbdbd',
    },
  }),
  { name: 'OtherPaymentMethodButton' },
)

interface OtherPaymentMethodButtonProps {
  isGoAvailableForPractice: boolean
  isLoading: boolean
  recordManually: () => void
}

export const OtherPaymentMethodButton = ({
  isGoAvailableForPractice,
  isLoading,
  recordManually,
}: OtherPaymentMethodButtonProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Invoices')

  const currentBusiness = useSelector(getCurrentBusiness)

  const { posPayEnabled } = currentBusiness || {}

  if (posPayEnabled && !isGoAvailableForPractice) {
    return (
      <Button
        className={classNames(classes.link, {
          [classes.disabledLink]: isLoading,
        })}
        onClick={recordManually}
      >
        {t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.OTHER_PAYMENT_METHOD')}
      </Button>
    )
  }

  return null
}
