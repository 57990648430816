import { setTokenCookie } from './cookie'

class TokenHolder {
  token: string | null = ''

  tokenGetter: (() => Promise<string> | string) | null = null

  setToken = (accessToken: string | null) => {
    this.token = accessToken
  }

  setTokenGetter = (handler: typeof this.tokenGetter) => {
    this.tokenGetter = handler
  }

  getToken = async () => {
    let { token } = this
    if (this.tokenGetter) {
      token = await this.tokenGetter()
    }
    setTokenCookie(token)
    return token
  }
}

export const tokenHolder = new TokenHolder()
