import React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlNotesPreview } from '@pbt/pbt-ui-components'

import { DiagnosesState } from '~/types'

export interface DifferentialDiagnoseContentProps {
  diagnose: DiagnosesState
}

const DifferentialDiagnoseContent = ({
  diagnose,
}: DifferentialDiagnoseContentProps) => {
  const { t } = useTranslation('Common')

  if (!diagnose.notes) {
    return null
  }

  return (
    <HtmlNotesPreview
      fontSize="1.4rem"
      notes={`${t('Common:NOTES')}: ${diagnose.notes}`}
    />
  )
}

export default DifferentialDiagnoseContent
