import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClassesType } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import RefundInvoiceStatusLabel from '~/components/dashboard/invoices/RefundInvoiceStatusLabel'
import { Invoice } from '~/types'
import { isRefundInvoice } from '~/utils/refundUtils'

import InvoiceStatusLabel from '../../../../invoices/InvoiceStatusLabel'

const useStyles = makeStyles(
  () => ({
    container: {},
    status: {
      width: 'fit-content',
      fontSize: '1.2rem',
    },
  }),
  { name: 'InvoiceTableStatusCell' },
)

interface InvoiceTableStatusCellProps {
  classes?: ClassesType<typeof useStyles>
  invoice: Invoice | GraphqlInvoice | RefundInvoice
}

export const InvoiceTableStatusCell = ({
  classes: classesProp,
  invoice,
}: InvoiceTableStatusCellProps) => {
  const classes = useStyles({ classes: classesProp })

  if (isRefundInvoice(invoice.invoiceNo)) {
    return (
      <Box className={classes.container}>
        <RefundInvoiceStatusLabel
          stateId={(invoice as RefundInvoice).state.id}
        />
      </Box>
    )
  }

  return (
    <Box className={classes.container}>
      <InvoiceStatusLabel
        className={classes.status}
        invoice={invoice as Invoice | GraphqlInvoice}
      />
    </Box>
  )
}
