import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType, Nil } from '@pbt/pbt-ui-components'

import { useMoveItemsToChewyCart } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/useMoveItemsToChewyCart'
import { StatusError } from '~/components/elements/Status/Status'
import DialogNames from '~/constants/DialogNames'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import { getApolloErrorMessage } from '~/utils/errors'
import useDialog from '~/utils/useDialog'

export const useMoveToCartDialog = (invoice: InvoiceV3 | Nil) => {
  const { t } = useTranslation(['Payments', 'Dialogs'])

  const { moveToCartLoading, moveToCartError, moveToCart, resetMoveToCart } =
    useMoveItemsToChewyCart(invoice)
  const message = useMemo(
    () => (
      <>
        {t(
          'Dialogs:CHEWY_PAYMENT_DIALOG.ALERTS.MESSAGE_BEFORE_PAYMENT_REQUEST1',
        )}
        <br />
        <br />
        {moveToCartError !== undefined && (
          <StatusError>{getApolloErrorMessage(moveToCartError)}</StatusError>
        )}
        {t(
          'Dialogs:CHEWY_PAYMENT_DIALOG.ALERTS.MESSAGE_BEFORE_PAYMENT_REQUEST2',
        )}
      </>
    ),
    [moveToCartError],
  )

  const [openAlert, closeAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    () => {},
    {
      iconType: AlertIconType.WARN,
      sx: { '& .MuiPaper-root': { maxWidth: 700, width: 'auto' } },
      message,
      cancelButtonText: t(
        'Dialogs:CHEWY_PAYMENT_DIALOG.MOVE_ITEMS_TO_CHEWY_CART',
      ),
      okButtonText: t(
        'Dialogs:CHEWY_PAYMENT_DIALOG.CHECK_OUT_CHEWY_ITEMS_BEFORE_SENDING',
      ),
      OkButtonProps: {
        disabled: moveToCartLoading,
      },
      CancelButtonProps: {
        loading: moveToCartLoading,
      },
    },
    true,
  )

  const onCloseDialog = () => {
    closeAlert()
    resetMoveToCart()
  }

  return {
    openMoveToCartDialog: ({
      onMoved,
      onCancel,
      onClose,
    }: {
      onCancel: () => void
      onClose: () => void
      onMoved: () => void
    }) => {
      openAlert({
        onOk: () => {
          onCloseDialog()
          onCancel()
        },
        onCancel: () => {
          moveToCart(() => {
            onCloseDialog()
            onMoved()
          })
        },
        onClose: () => {
          onCloseDialog()
          onClose()
        },
      })
    },
  }
}
