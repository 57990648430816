import { Selector } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import LogRocket from 'logrocket'
import { mergeDeepRight } from 'ramda'
import createSagaMiddleware from 'redux-saga'

import { tokenHolder } from '~/api/utils/token'
import { auth0Enabled } from '~/utils'
import LogRocketSanitizer from '~/utils/LogRocketSanitizer'

import { CREATE_PATIENT_CALLBACK } from './actions/types/patients'
import { OPEN_DIALOG } from './duck/dialogs'
import { COMPLETE_LAB_ORDER_WITH_CALLBACK } from './duck/labOrders'
import { CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP } from './duck/labTestsDashboard'
import * as LS from './localStorage'
// @ts-ignore
import reducer, { appReducer } from './reducers'
import { getAccessToken } from './reducers/auth'
import rootSaga from './sagas'

const CHECK_THRESHOLD = 100

const preloadedState = mergeDeepRight(
  appReducer({}, { type: 'EMPTY' }),
  LS.loadState() || {},
)

const { stateSanitizer, actionSanitizer } = LogRocketSanitizer

if (!auth0Enabled && preloadedState) {
  tokenHolder.setToken(getAccessToken(preloadedState))
}

const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer,
  preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: {
        warnAfter: CHECK_THRESHOLD,
      },
      serializableCheck: {
        ignoredActions: [
          OPEN_DIALOG,
          COMPLETE_LAB_ORDER_WITH_CALLBACK,
          CREATE_PATIENT_CALLBACK,
          CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP,
        ],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['error', 'payload.error', 'avatarBlob'],
        ignoredPaths: [
          'auth.error',
          'auth.authorizationError',
          'errors.list',
          'dialogs.list',
        ],
        warnAfter: CHECK_THRESHOLD,
      },
    }),
    sagaMiddleware,
    LogRocket.reduxMiddleware({
      stateSanitizer,
      actionSanitizer,
    }),
  ],
})
store.subscribe(() => {
  const state = store.getState()
  const { idToken, accessToken, expiresIn, userId, refreshToken } = state.auth
  const { filters } = state.labTests
  const { shouldShowBrowserNotice } = state.loginSessionSettings
  const { updateNotificationLastShownDate } = state.serviceWorker
  const {
    filteredPersons,
    filteredPersonGroups,
    filteredAppointmentTypes,
    filteredAppointmentTypeGroups,
  } = state.timetable
  LS.saveState({
    auth: auth0Enabled
      ? { userId }
      : { idToken, accessToken, expiresIn, userId, refreshToken },
    journal: state.journal,
    labTests: { filters },
    loginSessionSettings: { shouldShowBrowserNotice },
    serviceWorker: { updateNotificationLastShownDate },
    userSettings: state.userSettings,
    settings: state.settings,
    businessSettings: state.businessSettings,
    timetable: {
      filteredPersons,
      filteredPersonGroups,
      filteredAppointmentTypes,
      filteredAppointmentTypeGroups,
    },
  })
})
sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppSelector<T> = Selector<RootState, T>
