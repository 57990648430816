import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  DefaultReminderExpirationOffset,
  LanguageUtils,
  Text,
} from '@pbt/pbt-ui-components'

import { getReminderTemplateTimeUnits } from '~/store/reducers/constants'
import { getTimeUnitListPluralized } from '~/utils/time'

const ReminderSetUpDueDateOffsetCell = ({
  dueDateOffset,
}: {
  dueDateOffset: DefaultReminderExpirationOffset
}) => {
  const { t } = useTranslation('Time')
  const ReminderTemplateTimeUnits = useSelector(getReminderTemplateTimeUnits)

  const { amount, unit } = dueDateOffset || {}
  const LocalizedTimeUnits = getTimeUnitListPluralized(
    ReminderTemplateTimeUnits,
    Math.abs(amount),
  )
  const unitString = `${LanguageUtils.getConstantTranslatedName(
    unit,
    LocalizedTimeUnits,
    '',
  ).toLowerCase()}`

  const dueDateOffsetTime = `${Math.abs(amount)} ${unitString}`

  return (
    <Text component="span" variant="body2">
      {amount >= 0
        ? t('Time:LABEL.AFTER_TIME', { time: dueDateOffsetTime })
        : t('Time:LABEL.BEFORE_TIME', { time: dueDateOffsetTime })}
    </Text>
  )
}

export default ReminderSetUpDueDateOffsetCell
