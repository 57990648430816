import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { User, Utils } from '@pbt/pbt-ui-components'

import {
  SoapTemplate as SoapApiTemplate,
  SoapTemplateTab,
} from '~/api/graphql/generated/types'
import FeatureToggle from '~/constants/featureToggle'
import { SoapTemplate } from '~/types'
import { convertToKebabCase } from '~/utils'
import { isChargesInvoiceType } from '~/utils/finance'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { fetchSoapCharges, getCharges } from '../duck/charges'
import { getFeatureToggle } from '../reducers/constants'
import { getFinanceInvoiceIsPosted } from '../reducers/finance'
import {
  getClientId,
  getInvoiceId,
  getIsCurrentContextSoap,
  getIsFinalized,
  getSoapId,
  getUsersOnCollaborationSession,
} from '../reducers/soap'

export const useGetInvoicePosted = () => {
  const dispatch = useDispatch()
  const clientCharges = useSelector(getCharges)
  const soapId = useSelector(getSoapId)
  const id = useSelector(getClientId)
  const invoiceId = useSelector(getInvoiceId)
  const isInvoicePosted = useSelector(
    getFinanceInvoiceIsPosted(invoiceId),
  ) as boolean
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const isInvoice = isChargesInvoiceType(clientCharges)

  useEffectExceptOnMount(() => {
    if (id && soapId && isChargeSheetEnabled && isCurrentContextSoap) {
      dispatch(fetchSoapCharges({ id, soapId }))
    }
  }, [soapId, id, isCurrentContextSoap])

  return isInvoicePosted || (isInvoice && isChargeSheetEnabled)
}

export const useIsSoapLocked = (lockWhenInvoiceLocked = false) => {
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const isInvoicePosted = useGetInvoicePosted()

  return (
    !isCurrentContextSoap ||
    isSoapFinalized ||
    (lockWhenInvoiceLocked && isInvoicePosted)
  )
}

export const useLockableComponent = (
  Component: React.JSXElementConstructor<any>,
  ComponentLocked: React.JSXElementConstructor<any>,
  lockWhenInvoiceLocked = false,
) => {
  const isLockable = useIsSoapLocked(lockWhenInvoiceLocked)

  return isLockable ? ComponentLocked : Component
}

export const useUsersOnSoapCollaborationSession = () => {
  const usersOnCollaborationSession = useSelector(
    getUsersOnCollaborationSession,
  )

  const usersName =
    usersOnCollaborationSession &&
    R.pipe<any, any, string[], string, string>(
      R.map((user: Partial<User>) => Utils.getPersonString(user)),
      R.filter(Boolean),
      R.join(', '),
      R.replace(/, ([^,]*)$/, ' and $1'),
    )(usersOnCollaborationSession)

  return useMemo(
    () => ({
      usersOnCollaborationSession,
      usersName,
    }),
    [usersOnCollaborationSession, usersName],
  )
}

export const getOrderedSoapTemplateTabs = (tabs: SoapTemplateTab[]) =>
  R.sort(R.ascend(R.prop<number>('order')), tabs)

export const convertSoapTemplateData = (
  template: SoapApiTemplate,
): SoapTemplate => ({
  ...template,
  tabs: template.tabs
    ? getOrderedSoapTemplateTabs(template.tabs).map(
        ({ id, name, order, widgets }) => ({
          id,
          label: name,
          name,
          order,
          url: convertToKebabCase(name),
          widgets: R.sort(R.ascend(R.prop<number>('order')), widgets),
        }),
      )
    : [],
})
