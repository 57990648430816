import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Field, PuiTextField, Utils } from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import UnitSelect from '~/components/common/inputs/UnitSelect'
import { getWeightUnits } from '~/store/reducers/constants'
import { useGetBusinessEnabledUnits } from '~/utils/measurementUnitsUtils'

// @ts-ignore
import { getPoundsAndOunces, handleUnitValueChange } from './vitalUtils'

const useStyles = makeStyles(
  (theme) => ({
    input: {
      padding: theme.spacing(1),
    },
    textInput: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.controlBorder,
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
    },
    textInputWidth: {
      width: 72,
    },
    unitsLabel: {
      backgroundColor: theme.colors.tableBackground,
      color: theme.colors.secondaryText,
      width: 32,
      border: theme.constants.controlBorder,
      borderLeft: 'none',
      fontSize: '1.5rem',
      fontWeight: 500,
      textAlign: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }),
  { name: 'VitalsWeightInput' },
)

const weightFractions = (
  baseValue: string | number,
  fractionValue: string | number,
) => ({ baseValue, fractionValue })

const parseInitialFieldValue = (field: Field, activeUnit?: string) => {
  const { value } = field
  if (!value) {
    return weightFractions(0, 0)
  }

  if (R.is(Object, value)) {
    return weightFractions(value?.base ?? '', value?.fraction ?? '')
  }

  return activeUnit === WeightTypes.KG
    ? weightFractions(value, 0)
    : weightFractions(...(getPoundsAndOunces(value) as [number, number]))
}

const setWeightValue = (
  field: Field,
  activeUnit: string | undefined,
  baseValue: string | number | null,
  fractionValue: string | number | null,
) => {
  const fractionalValue = parseInitialFieldValue(field, activeUnit)

  const base = baseValue ?? fractionalValue.baseValue
  const fraction = fractionValue ?? fractionalValue.fractionValue

  const newValue = base || fraction ? { base, fraction } : ''
  field.setValue(newValue)
}

interface VitalsWeightInputProps {
  activeUnit?: string
  disabled?: boolean
  field: Field
  onUnitsChange: (type: UnitTypes, value: WeightTypes) => void
}

const VitalsWeightInput = ({
  field,
  activeUnit,
  onUnitsChange,
  disabled,
}: VitalsWeightInputProps) => {
  const classes = useStyles()
  const WeightUnits = useSelector(getWeightUnits)
  const areLbsUnits = activeUnit === WeightTypes.LBS

  const items = useGetBusinessEnabledUnits(WeightUnits)

  const setLocalBase = (value: any) =>
    setWeightValue(field, activeUnit, value, null)
  const setLocalFraction = (value: any) =>
    setWeightValue(field, activeUnit, null, value)

  const onBaseValueChange = Utils.handleFormTextInput((str) =>
    handleUnitValueChange(setLocalBase, str),
  )
  const onFractionValueChange = Utils.handleFormTextInput((str) =>
    handleUnitValueChange(setLocalFraction, str),
  )

  const { baseValue, fractionValue } = parseInitialFieldValue(field, activeUnit)

  return (
    <Grid container id="vitals-inputs-container-weight-input">
      <Grid container item wrap="nowrap" xs={12}>
        <PuiTextField
          InputProps={{
            disableUnderline: true,
            inputProps: {
              className: classes.input,
            },
          }}
          className={classNames(classes.textInput)}
          disabled={disabled}
          id="weight-units"
          margin="none"
          value={baseValue || ''}
          onChange={onBaseValueChange}
        />
        <UnitSelect
          items={items}
          value={activeUnit}
          onChange={Utils.handleFormSelectInput(
            R.curry(onUnitsChange)(UnitTypes.WEIGHT),
          )}
        />
      </Grid>
      {areLbsUnits && (
        <Grid
          container
          item
          direction="row"
          flexWrap="nowrap"
          ml={0}
          mt={0.5}
          xs={12}
        >
          <PuiTextField
            InputProps={{
              disableUnderline: true,
              inputProps: {
                className: classes.input,
              },
            }}
            className={classNames(classes.textInput)}
            disabled={disabled}
            id="weight-oz"
            margin="none"
            value={fractionValue || ''}
            onChange={onFractionValueChange}
          />
          <div className={classes.unitsLabel}>oz</div>
        </Grid>
      )}
    </Grid>
  )
}

export default VitalsWeightInput
