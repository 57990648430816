import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  PermissionArea,
  TextInteractive,
  WellnessPlans,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getPatientStatus } from '~/utils'
import useDialog from '~/utils/useDialog'

import {
  getAllPatientPlansCancelled,
  getPatientHasNoMembership,
  getPlanNames,
  useSortedWellnessPlans,
  // @ts-ignore
} from '../../wellness-plans/wellnessPlanUtils'
import PatientMembershipIcon from './PatientMembershipIcon'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    membershipIcon: {
      marginRight: theme.spacing(1),
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'PatientMembershipLink' },
)

interface PatientMembershipLinkProps {
  className?: string
  clientId: string
  isLoading: boolean
  patientId: string
  removeUnderline?: boolean
}

const PatientMembershipLink = ({
  className,
  removeUnderline = false,
  clientId,
  patientId,
  isLoading,
}: PatientMembershipLinkProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const patient = useSelector(getPatient(patientId))
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )
  const {
    create: wellnessPlanCreatePermissions,
    update: wellnessPlanUpdatePermissions,
  } = useSelector(getCRUDByArea(PermissionArea.WELLNESS_PLAN))

  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)
  const [openPatientMembershipDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP,
  )

  const isCancelled = getAllPatientPlansCancelled(patient)
  const hasNoMembership = getPatientHasNoMembership(patient)
  const shouldHideSignUp = hasNoMembership && getPatientStatus(patient).disabled

  const sortedPlans = useSortedWellnessPlans(patient?.plans || [])

  const notCancelledPlans = R.filter(
    (plan) => plan.wplanStatus !== WellnessPlans.MembershipStatus.CANCELLED,
    sortedPlans,
  )

  const plansNamesList = getPlanNames(notCancelledPlans)

  const onClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()

    if (hasNoMembership) {
      openMembershipSignUpDialog({
        clientId,
        patientId,
      })
    } else {
      openPatientMembershipDialog({
        clientId,
        patientId,
      })
    }
  }

  if (shouldHideSignUp) {
    return null
  }

  const newMembershipLabel = isBoopDisablementEnabled
    ? t('Common:ADD_PLAN')
    : t('Common:SIGN_UP_ACTION')

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
      wrap="nowrap"
    >
      <PatientMembershipIcon
        className={classes.membershipIcon}
        isLoading={isLoading}
        patientId={patientId}
      />
      <TextInteractive
        align="left"
        className={classNames({
          [classes.link]: !removeUnderline,
        })}
        disabled={
          hasNoMembership
            ? !wellnessPlanCreatePermissions
            : !wellnessPlanUpdatePermissions
        }
        isLoading={isLoading}
        onClick={
          wellnessPlanCreatePermissions || wellnessPlanUpdatePermissions
            ? onClick
            : undefined
        }
      >
        {hasNoMembership
          ? newMembershipLabel
          : isCancelled
            ? `${t('Common:CANCELLED')} ${DateUtils.formatDate(
                patient?.wplanCancellationDate,
              )}`
            : plansNamesList.join(', ')}
      </TextInteractive>
    </Grid>
  )
}

export default PatientMembershipLink
