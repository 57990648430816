import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  LanguageUtils,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'

import RemindersList from './RemindersList'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      maxWidth: 680,
      width: 680,
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    remindersList: {
      borderRadius: 2,
      border: theme.constants.tableBorder,
      minHeight: 500,
    },
  }),
  { name: 'RemindersListDialog' },
)

export interface RemindersListDialogProps extends BasePuiDialogProps {
  patientId: string | Nil
}

const RemindersListDialog = ({
  open,
  patientId,
  onClose,
}: RemindersListDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const patient = useSelector(getPatient(patientId))
  const title = patient?.name
    ? t('Dialogs:REMINDER_LIST_DIALOG.REMINDERS_OF_PATIENT', {
        patientName: LanguageUtils.capitalize(patient.name),
      })
    : t('Common:REMINDERS')

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="reminders-list-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      scroll="paper"
      title={title}
      onClose={onClose}
    >
      <RemindersList className={classes.remindersList} patientId={patientId} />
    </PuiDialog>
  )
}

export default RemindersListDialog
