import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    discountCell: {
      minWidth: 128,
    },
  }),
  { name: 'DiscountCellHead' },
)

export interface DiscountCellHeadProps {
  className?: string
}

const DiscountCellHead = ({ className }: DiscountCellHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <TableCell
      className={classNames(className, classes.discountCell)}
      colSpan={2}
    >
      {t('Common:DISCOUNT')}
    </TableCell>
  )
}

export default DiscountCellHead
