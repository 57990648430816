import { useSelector } from 'react-redux'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { getInventoryDistributors } from '~/store/reducers/constants'

type DistributorCellItem = {
  distributorId: string
  distributorName: string
}

const DistributorCell = ({
  distributorId,
  distributorName,
}: DistributorCellItem) => {
  const InventoryDistributors = useSelector(getInventoryDistributors)
  const distributor = Utils.getConstantName(
    distributorId,
    InventoryDistributors,
  )
  const distributorDisplayName = LanguageUtils.getConstantTranslatedName(
    distributorId,
    InventoryDistributors,
  )

  return distributor === 'Other' ? distributorName : distributorDisplayName
}

export default DistributorCell
