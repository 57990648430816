import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import Attachment from '~/components/common/inputs/attachment/Attachment'
import { MigrationSteps } from '~/constants/migrationV2'
import {
  deleteMigrationSessionFile,
  fetchMigrationSessionFiles,
  getCurrentSession,
  getIsLoading,
  getSessionFiles,
} from '~/store/duck/migrationV2'
import { BaseStepComponentProps } from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const SessionFilesView = ({ stepController }: BaseStepComponentProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const currentSession = useSelector(getCurrentSession)
  const isLoading = useSelector(getIsLoading)
  const sessionFiles = useSelector(getSessionFiles)

  useEffect(() => {
    if (!currentSession?.id) {
      return
    }
    dispatch(fetchMigrationSessionFiles({ sessionId: currentSession.id }))
  }, [currentSession?.id, dispatch])

  const handleProceed = () => {
    stepController.next()
  }

  const handleUploadAdditionalFiles = () => {
    stepController.setCurrentStepOverride({
      migrationStep: MigrationSteps.SESSION_FILE_UPLOAD,
      props: {
        onNext: () =>
          stepController.setCurrentStepOverride({
            migrationStep: MigrationSteps.SESSION_FILE_VIEW,
          }),
      },
    })
  }

  const handleDelete = (fileName: string, fileExtension: string) => {
    if (!currentSession) {
      return
    }

    dispatch(
      deleteMigrationSessionFile({
        sessionId: currentSession.id,
        fileName,
        fileExtension,
      }),
    )
  }

  return (
    <MigrationStep>
      <MigrationStepContent direction="column" height={320}>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t(
              'Businesses:MIGRATION.LOADING_AVAILABLE_SESSION_FILES',
            )}
          />
        ) : (
          sessionFiles.map(({ name, extension, exists }) => (
            <Grid item key={name}>
              <Attachment
                hidePreview
                disabled={!exists}
                file={{
                  name: `${name}.${extension}`,
                  extension,
                }}
                onDeleteRequested={() => handleDelete(name, extension)}
              />
            </Grid>
          ))
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:DONE')}
        </MigrationStepActionButton>
        <MigrationStepActionButton onClick={handleUploadAdditionalFiles}>
          {t('Businesses:MIGRATION.UPLOAD_ADDITIONAL_FILES')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default SessionFilesView
