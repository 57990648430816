import { gql } from '@apollo/client'

import { SOAP_TEMPLATE_TAB_FIELDS } from '../fragments/soap'

export const FETCH_SOAP_ACTIVE_TEMPLATE = gql`
  ${SOAP_TEMPLATE_TAB_FIELDS}
  query SoapActiveTemplate($businessId: ID!) {
    soapActiveTemplate(businessId: $businessId) {
      id
      name
      tabs {
        ...SoapTemplateTabFields
      }
    }
  }
`

export const FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS = gql`
  query UncoveredSoapTemplateWidgets(
    $searchFilter: SoapWidgetUncoveredContentFilterInput
  ) {
    uncoveredSoapTemplateWidgets(searchFilter: $searchFilter) {
      id
    }
  }
`
