import React from 'react'
import { PhoneUtils } from '@pbt/pbt-ui-components'

import { ShippingAddress } from '~/api/graphql/generated/types'

export const ChewyFullShippingAddress = ({
  fullName,
  address,
  phone1,
}: ShippingAddress) => (
  <div style={{ whiteSpace: 'normal' }}>
    <b>{fullName}</b>
    <div>{address.street1}</div>
    {address.street2 && <div>{address.street2}</div>}
    <div>
      {address.city}, {address.state} {address.postcode}
    </div>
    <div>
      {PhoneUtils.formatPhoneNumber(PhoneUtils.parsePhoneNumber(`${phone1}`))}
    </div>
  </div>
)
