import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import AppointmentTypesCell from '~/components/common/lists/primitive-table/cells/AppointmentTypesCell'
import GenderRestrictionsCell from '~/components/common/lists/primitive-table/cells/GenderRestrictionsCell'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import i18n from '~/locales/i18n'
import {
  fetchBundlesList,
  fetchMoreItemsForBundlesList,
} from '~/store/actions/bundles'
import {
  getBundlesIsFetchingList,
  getBundlesList,
  getBundlesTotalCount,
  getMultipleBundles,
} from '~/store/reducers/bundles'
import { Bundle } from '~/types'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

// @ts-ignore
import BundleDetails from './BundleDetails'
import NoBundlesScreen from './NoBundlesScreen'

const columns = [
  {
    label: i18n.t('Common:NAME'),
    prop: 'name',
    highlight: true,
    width: 4,
  },
  {
    label: i18n.t('Common:APPOINTMENT_TYPE'),
    prop: AppointmentTypesCell,
    width: 5,
  },
  {
    label: i18n.t('Common:SPECIES'),
    prop: SpeciesCell,
    width: 4,
  },
  {
    label: i18n.t('Common:GENDER'),
    prop: GenderRestrictionsCell,
    width: 2,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    prop: (item: Bundle) => boolToYesNoString(item.active),
    width: 1,
  },
]

export interface BundlesTableComponentProps {
  bundleId?: string
  headerButtons?: React.ReactNode
  onDetailsClose: () => void
}

const BundlesTableComponent = ({
  headerButtons,
  bundleId,
  onDetailsClose,
}: BundlesTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const list = useSelector(getBundlesList)
  const bundles = useSelector(getMultipleBundles(list))
  const totalCount = useSelector(getBundlesTotalCount)
  const bundlesIsFetchingList = useSelector(getBundlesIsFetchingList)
  const { t } = useTranslation('Common')

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchBundlesList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search])

  const navigateToBundle = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/bundles/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(bundles[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForBundlesList(startIndex, endIndex, search))
  }

  const getDisabled = (item: Bundle) => !item.active

  return (
    <ExpandableTable
      Expander={BundleDetails}
      NoItemsScreen={NoBundlesScreen}
      headerButtons={headerButtons}
      isLoading={bundlesIsFetchingList}
      itemId={bundleId}
      list={bundles}
      searchTerm={search}
      title={t('Common:BUNDLES_CATALOG')}
      onClose={onDetailsClose}
      onSelected={navigateToBundle}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default BundlesTableComponent
