import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 161,
    },
  }),
  { name: 'SoapTemplateTabDialogActions' },
)

export interface SoapTemplateTabDialogActionsProps {
  hasUnsavedData: boolean
  onSave: () => void
}

const SoapTemplateTabDialogActions = ({
  hasUnsavedData,
  onSave,
}: SoapTemplateTabDialogActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <ButtonWithLoader
      className={classes.button}
      disabled={!hasUnsavedData}
      id="soap-template-tab-dialog-save-button"
      onClick={onSave}
    >
      {t('Common:SAVE_ACTION')}
    </ButtonWithLoader>
  )
}

export default SoapTemplateTabDialogActions
