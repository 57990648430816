import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { TempPriceDto } from '~/api/graphql/generated/types'
import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import type { RootState } from '~/store'
import {
  BulkPriceEdit,
  BulkPriceSelectionExceptions,
  BulkPriceSession,
  BulkVariationEdit,
  BulkVariationPreview,
  FetchListPayload,
} from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { detectAPIErrorType, getErrorMessage } from '~/utils/errors'

export type BulkPricesStateType = {
  activeSessionsByEntityType: Record<string, BulkPriceSession>
  adjustPercent: boolean
  adjustRoundup: boolean
  adjustValue: number | null
  allSelected: boolean
  confirmedSessions: string[]
  error: string | null
  errorType: string | Nil
  filters: Record<string, string | number>
  isEditing: boolean
  isLoading: boolean
  isLoadingSession: boolean
  isStartingSession: boolean
  list: string[]
  manualPriceChanges: Record<string, number>
  map: Record<string, BulkVariationEdit>
  previewList: string[]
  previewTotalCount: number
  pricesMap: Record<string, BulkPriceEdit>
  pricesPreviewMap: Record<string, TempPriceDto>
  selectionExceptions: BulkPriceSelectionExceptions
  totalCount: number
  variationsPreviewMap: Record<string, BulkVariationPreview>
}

type DeleteBulkPricesSessionPayload = {
  entityType: BulkPriceEntityTypes
  sessionId: string
}

type BulkEditPricesManualPayload = {
  entityType: BulkPriceEntityTypes
  sessionId: string
}

type FetchListReducerPayload = FetchListPayload & {
  category: string | number
  subCategory?: string | number
}

type StartPriceBulkUpdatePayload = {
  sessionId: string
}

type StartPriceBulkUpdateSuccessPayload = {
  sessionId: string
}

type EditPricesBulkAndManualPayload = {
  entityType: BulkPriceEntityTypes
  sessionId: string
}

type StartBulkPricesSessionPayload = {
  force?: boolean
  navigateTo?: string
}

type FetchBulkPricesPreviewPayload = FetchListPayload &
  BulkEditPricesManualPayload

const INITIAL_STATE: BulkPricesStateType = {
  previewList: [],
  variationsPreviewMap: {},
  list: [],
  map: {},
  pricesMap: {},
  pricesPreviewMap: {},
  filters: {},
  adjustRoundup: false,
  adjustPercent: true,
  adjustValue: null,
  allSelected: true,
  selectionExceptions: {},
  manualPriceChanges: {},
  isLoading: false,
  isLoadingSession: false,
  isStartingSession: false,
  isEditing: false,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  previewTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  error: null,
  errorType: null,
  activeSessionsByEntityType: {},
  confirmedSessions: [],
}

const calculateSelectExceptions = (
  priceIds: string[],
  state: boolean,
  allSelected: boolean,
) =>
  (priceIds || []).reduce((acc, priceId) => {
    acc[priceId] = allSelected ? !state : state
    return acc
  }, {} as BulkPriceSelectionExceptions)

const fetchListReducer = (
  state: BulkPricesStateType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: { payload: FetchListReducerPayload },
) => {
  state.isLoading = true
}

const fetchBulkPricesPreviewReducer = (
  state: BulkPricesStateType,
  action: { payload: FetchBulkPricesPreviewPayload },
) => {
  state.isLoading = true
  if (action.payload.from === 0) {
    state.previewList = []
    state.previewTotalCount = 0
  }
}

const fetchListSuccessReducer = (
  state: BulkPricesStateType,
  action: PayloadAction<any>,
) => {
  state.isLoading = false
  state.list = mergeArraysAtIndex(
    state.list,
    action.payload.list,
    action.payload.from,
  )
  state.totalCount = action.payload.totalCount
}

const fetchListFailureReducer = (
  state: BulkPricesStateType,
  action: PayloadAction<any>,
) => {
  state.isLoading = false
  state.error = getErrorMessage(action.payload.error)
}

const fetchPreviewListSuccessReducer = (
  state: BulkPricesStateType,
  action: PayloadAction<any>,
) => {
  state.isLoading = false
  state.previewList = mergeArraysAtIndex(
    state.previewList,
    action.payload.list,
    action.payload.from,
  )
  state.previewTotalCount = action.payload.totalCount
}

const startBulkPricesSessionReducer = (
  state: BulkPricesStateType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: PayloadAction<StartBulkPricesSessionPayload>,
) => {
  state.isLoading = true
  state.isStartingSession = true
}

const startBulkPricesSessionFailureReducer = (
  state: BulkPricesStateType,
  action: PayloadAction<any>,
) => {
  state.isLoading = false
  state.isStartingSession = false
  state.error = getErrorMessage(action.payload.error)
  state.errorType = detectAPIErrorType(action.payload.error.responseBody)
}

export const bulkPricesSlice = createSlice({
  name: 'bulkPrices',
  initialState: INITIAL_STATE,
  reducers: {
    resetBulkPrices: () => INITIAL_STATE,
    clearBulkPrices: (state) => {
      state.list = []
      state.map = {}
      state.pricesMap = {}
      state.selectionExceptions = {}
      state.manualPriceChanges = {}
      state.totalCount = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT
      state.allSelected = true
    },
    setManualPriceException: (
      state,
      action: PayloadAction<{
        priceId: string
        value: number
      }>,
    ) => {
      state.manualPriceChanges[action.payload.priceId] = action.payload.value
    },
    clearManualPriceException: (state, action) => {
      state.manualPriceChanges = R.omit(
        [action.payload.priceId],
        state.manualPriceChanges,
      )
    },
    setBulkPricesFilter: (state, action) => {
      state.filters[action.payload.filter] = action.payload.value
    },
    setAdjustRoundup: (state, action) => {
      state.adjustRoundup = action.payload.value
    },
    setAdjustByPercent: (state, action) => {
      state.adjustPercent = action.payload.value
      state.adjustValue = null
      state.manualPriceChanges = {}
    },
    setAdjustValue: (state, action) => {
      state.adjustValue = action.payload.value
      state.manualPriceChanges = {}
    },
    setAllSelected: (state, action) => {
      state.allSelected = Boolean(action.payload.value)
      state.manualPriceChanges = action.payload.value
        ? state.manualPriceChanges
        : {}
      state.selectionExceptions = {}
    },
    fetchInventoryBulkPrices: fetchListReducer,
    fetchProceduresBulkPrices: fetchListReducer,
    fetchLabTestsBulkPrices: fetchListReducer,

    fetchInventoryBulkPricesPreview: fetchBulkPricesPreviewReducer,
    fetchProceduresBulkPricesPreview: fetchBulkPricesPreviewReducer,
    fetchLabTestsBulkPricesPreview: fetchBulkPricesPreviewReducer,

    fetchInventoryBulkPricesSuccess: fetchListSuccessReducer,
    fetchProceduresBulkPricesSuccess: fetchListSuccessReducer,
    fetchLabTestsBulkPricesSuccess: fetchListSuccessReducer,

    fetchInventoryBulkPricesPreviewSuccess: fetchPreviewListSuccessReducer,
    fetchProceduresBulkPricesPreviewSuccess: fetchPreviewListSuccessReducer,
    fetchLabTestsBulkPricesPreviewSuccess: fetchPreviewListSuccessReducer,

    fetchInventoryBulkPricesFailure: fetchListFailureReducer,
    fetchProceduresBulkPricesFailure: fetchListFailureReducer,
    fetchLabTestsBulkPricesFailure: fetchListFailureReducer,

    fetchInventoryBulkPricesPreviewFailure: fetchListFailureReducer,
    fetchProceduresBulkPricesPreviewFailure: fetchListFailureReducer,
    fetchLabTestsBulkPricesPreviewFailure: fetchListFailureReducer,

    updateBulkVariations: (state, action) => {
      state.map = secondLevelMerge(state.map, action.payload)
    },
    updateBulkVariationsPreview: (state, action) => {
      state.variationsPreviewMap = secondLevelMerge(
        state.variationsPreviewMap,
        action.payload,
      )
    },
    updateBulkPrices: (state, action) => {
      state.pricesMap = secondLevelMerge(state.pricesMap, action.payload)
    },
    updateBulkPricesPreview: (state, action) => {
      state.pricesPreviewMap = secondLevelMerge(
        state.pricesPreviewMap,
        action.payload,
      )
    },

    setManualSelection: (state, action) => {
      state.selectionExceptions = {
        ...state.selectionExceptions,
        ...calculateSelectExceptions(
          action.payload.priceIds,
          action.payload.state,
          state.allSelected,
        ),
      }
      state.manualPriceChanges = R.omit(
        action.payload.priceIds,
        state.manualPriceChanges,
      )
    },
    fetchBulkPricesActiveSession: (state, action) => {
      state.isLoading = true
      state.error = null
      state.isLoadingSession = action.payload.silent
        ? state.isLoadingSession
        : true
    },
    fetchBulkPricesActiveSessionSuccess: (state, action) => {
      state.isLoading = false
      state.isLoadingSession = action.payload.silent
        ? state.isLoadingSession
        : false
      state.activeSessionsByEntityType[action.payload.entityType] =
        action.payload.session
      state.error = null
      state.errorType = null
    },
    fetchBulkPricesActiveSessionFailure: (state, action) => {
      state.isLoading = false
      state.isLoadingSession = action.payload.silent
        ? state.isLoadingSession
        : false
      state.error = getErrorMessage(action.payload.error)
      state.errorType = detectAPIErrorType(action.payload.error.responseBody)
    },
    clearBulkPricesSession: (state, action) => {
      const filterableArr = (id: string) => id !== action.payload.sessionId
      const filterableObj = ({ id }: { id: string }) =>
        id !== action.payload.sessionId
      state.confirmedSessions = R.filter(filterableArr, state.confirmedSessions)
      state.activeSessionsByEntityType = R.filter(
        filterableObj,
        state.activeSessionsByEntityType,
      )
    },

    startProceduresBulkPricesSession: startBulkPricesSessionReducer,
    startLabTestsBulkPricesSession: startBulkPricesSessionReducer,
    startInventoryBulkPricesSession: startBulkPricesSessionReducer,

    startProceduresBulkPricesSessionSuccess: (state, action) => {
      state.isLoading = false
      state.isStartingSession = false
      state.error = null
      state.errorType = null
      state.activeSessionsByEntityType[BulkPriceEntityTypes.PROCEDURE] = {
        id: action.payload.sessionId,
      }
    },
    startLabTestsBulkPricesSessionSuccess: (state, action) => {
      state.isLoading = false
      state.isStartingSession = false
      state.error = null
      state.errorType = null
      state.activeSessionsByEntityType[BulkPriceEntityTypes.LAB_TEST] = {
        id: action.payload.sessionId,
      }
    },
    startInventoryBulkPricesSessionSuccess: (state, action) => {
      state.isLoading = false
      state.isStartingSession = false
      state.error = null
      state.errorType = null
      state.activeSessionsByEntityType[BulkPriceEntityTypes.INVENTORY] = {
        id: action.payload.sessionId,
      }
    },

    startProceduresBulkPricesSessionFailure:
      startBulkPricesSessionFailureReducer,
    startLabTestsBulkPricesSessionFailure: startBulkPricesSessionFailureReducer,
    startInventoryBulkPricesSessionFailure:
      startBulkPricesSessionFailureReducer,

    deleteBulkPricesSession: (
      state: BulkPricesStateType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: {
        payload: DeleteBulkPricesSessionPayload
      },
    ) => {
      state.isLoading = true
      state.error = null
    },
    deleteBulkPricesSessionSuccess: (state, action) => {
      state.isLoading = false
      state.activeSessionsByEntityType = R.omit(
        [action.payload.entityType],
        state.activeSessionsByEntityType,
      )
    },
    deleteBulkPricesSessionFailure: (state, action) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },

    startPriceBulkUpdate: (
      state: BulkPricesStateType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: {
        payload: StartPriceBulkUpdatePayload
      },
    ) => {
      state.isStartingSession = true
      state.error = null
    },
    startPriceBulkUpdateSuccess: (
      state,
      action: {
        payload: StartPriceBulkUpdateSuccessPayload
      },
    ) => {
      state.isStartingSession = false
      state.confirmedSessions.push(action.payload.sessionId)
    },
    startPriceBulkUpdateFailure: (state, action) => {
      state.isStartingSession = false
      state.error = getErrorMessage(action.payload.error)
    },
    editPricesBulkAndManual: (
      state: BulkPricesStateType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: {
        payload: EditPricesBulkAndManualPayload
      },
    ) => {
      state.isEditing = true
      state.isLoading = true
      state.error = null
    },
    editPricesBulkAndManualSuccess: (state) => {
      state.isEditing = false
      state.isLoading = false
    },
    editPricesBulkAndManualFailure: (state, action) => {
      state.isEditing = false
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
      state.errorType = detectAPIErrorType(action.payload.error.responseBody)
    },
  },
})

const { actions } = bulkPricesSlice

export const {
  resetBulkPrices,
  clearBulkPrices,
  setManualPriceException,
  clearBulkPricesSession,
  clearManualPriceException,
  setBulkPricesFilter,
  setAdjustRoundup,
  setAdjustByPercent,
  setAdjustValue,
  setAllSelected,
  fetchInventoryBulkPrices,
  fetchProceduresBulkPrices,
  fetchLabTestsBulkPrices,
  fetchInventoryBulkPricesPreview,
  fetchProceduresBulkPricesPreview,
  fetchLabTestsBulkPricesPreview,
  fetchInventoryBulkPricesSuccess,
  fetchProceduresBulkPricesSuccess,
  fetchLabTestsBulkPricesSuccess,
  fetchInventoryBulkPricesPreviewSuccess,
  fetchProceduresBulkPricesPreviewSuccess,
  fetchLabTestsBulkPricesPreviewSuccess,
  fetchInventoryBulkPricesFailure,
  fetchProceduresBulkPricesFailure,
  fetchLabTestsBulkPricesFailure,
  fetchInventoryBulkPricesPreviewFailure,
  fetchProceduresBulkPricesPreviewFailure,
  fetchLabTestsBulkPricesPreviewFailure,
  updateBulkVariations,
  updateBulkVariationsPreview,
  updateBulkPrices,
  updateBulkPricesPreview,
  setManualSelection,
  fetchBulkPricesActiveSession,
  fetchBulkPricesActiveSessionSuccess,
  fetchBulkPricesActiveSessionFailure,
  startProceduresBulkPricesSession,
  startLabTestsBulkPricesSession,
  startInventoryBulkPricesSession,
  startProceduresBulkPricesSessionSuccess,
  startLabTestsBulkPricesSessionSuccess,
  startInventoryBulkPricesSessionSuccess,
  startProceduresBulkPricesSessionFailure,
  startLabTestsBulkPricesSessionFailure,
  startInventoryBulkPricesSessionFailure,
  deleteBulkPricesSession,
  deleteBulkPricesSessionSuccess,
  deleteBulkPricesSessionFailure,
  startPriceBulkUpdate,
  startPriceBulkUpdateSuccess,
  startPriceBulkUpdateFailure,
  editPricesBulkAndManual,
  editPricesBulkAndManualSuccess,
  editPricesBulkAndManualFailure,
} = actions

export const getBulkPrices = (state: RootState): BulkPricesStateType =>
  state.bulkPrices
export const getBulkPricesIsLoading = (state: RootState) =>
  getBulkPrices(state).isLoading
export const getBulkPricesIsStarting = (state: RootState) =>
  getBulkPrices(state).isStartingSession
export const getBulkPricesIsLoadingSession = (state: RootState) =>
  getBulkPrices(state).isLoadingSession
export const getBulkPricesIsEditing = (state: RootState) =>
  getBulkPrices(state).isEditing
export const getBulkPricesError = (state: RootState) =>
  getBulkPrices(state).error
export const getBulkPricesErrorType = (state: RootState) =>
  getBulkPrices(state).errorType
export const getBulkPricesMap = (state: RootState) => getBulkPrices(state).map
export const getVariationsPreviewMap = (state: RootState) =>
  getBulkPrices(state).variationsPreviewMap
export const getBulkPricesList = (state: RootState) => getBulkPrices(state).list
export const getBulkPricesPreviewList = (state: RootState) =>
  getBulkPrices(state).previewList
export const getBulkPricesTotalCount = (state: RootState) =>
  getBulkPrices(state).totalCount
export const getBulkPricesPreviewTotalCount = (state: RootState) =>
  getBulkPrices(state).previewTotalCount
export const getVariation = (id: string) =>
  createSelector(getBulkPricesMap, (map) => R.prop(id, map))
export const getPreviewVariation = (id: string) =>
  createSelector(getVariationsPreviewMap, (map) => R.prop(id, map))
export const getPricesMap = (state: RootState) => getBulkPrices(state).pricesMap
export const getPricesPreviewMap = (state: RootState) =>
  getBulkPrices(state).pricesPreviewMap
export const getMultiplePrices = (ids: string[]) =>
  createSelector(getPricesMap, (map) => R.props(ids, map))
export const getPreviewPrice = (id: string) =>
  createSelector(getPricesPreviewMap, (map) => R.prop(id, map))
export const getAllSelected = (state: RootState) =>
  getBulkPrices(state).allSelected
export const getPricesSelectionExceptions = (state: RootState) =>
  getBulkPrices(state).selectionExceptions
export const getPricesSelection = (ids: string[]) =>
  createSelector(
    getPricesSelectionExceptions,
    getAllSelected,
    (exception, allSelected) =>
      ids.reduce((acc: Record<string, boolean>, id: string) => {
        const selectedByAll = allSelected && !exception[id]
        const selectedManually = !allSelected && exception[id]
        acc[id] = Boolean(selectedByAll || selectedManually)
        return acc
      }, {}),
  )
export const getBulkPricesFilters = (state: RootState) =>
  getBulkPrices(state).filters
export const getBulkPricesFilter = (filter: string | Nil) =>
  createSelector(getBulkPricesFilters, (filters) =>
    filter ? filters[filter] : undefined,
  )
export const getManualPriceChangesMap = (state: RootState) =>
  getBulkPrices(state).manualPriceChanges
export const getManualPriceChange = (priceId: string | Nil) =>
  createSelector(getManualPriceChangesMap, (map: Record<string, number>) =>
    priceId ? map[priceId] : undefined,
  )
export const getHasManualPriceChanges = createSelector(
  getManualPriceChangesMap,
  R.compose(R.not, R.isEmpty),
)
export const getManualPriceChangesCount = createSelector(
  getManualPriceChangesMap,
  (map: Record<string, number>) => Object.keys(map).length,
)

export const getAdjustRoundup = (state: RootState) =>
  getBulkPrices(state).adjustRoundup
export const getAdjustByPercent = (state: RootState) =>
  getBulkPrices(state).adjustPercent
export const getAdjustValue = (state: RootState) =>
  getBulkPrices(state).adjustValue
export const getActiveSessionsByEntityType = (state: RootState) =>
  getBulkPrices(state).activeSessionsByEntityType
export const getActiveSessionByEntityType = (entityType: string) =>
  createSelector(getActiveSessionsByEntityType, (map) =>
    R.prop(entityType, map),
  )
export const getActiveSessionByEntityTypeId = (entityType: string) =>
  createSelector(getActiveSessionByEntityType(entityType), (session) =>
    R.prop('id', session),
  )

export const getPreloadedPricesCount = createSelector(
  getBulkPricesList,
  getBulkPricesMap,
  (list: string[], map: Record<string, BulkVariationEdit>) =>
    list.reduce(
      (acc, variationId) => acc + (map[variationId]?.prices.length || 0),
      0,
    ),
)

export const getPricesSelectionExceptionsCount = createSelector(
  getPricesSelectionExceptions,
  (selectionExceptions: Record<string, boolean>) =>
    Object.keys(selectionExceptions).reduce(
      (acc: number, key: string) => (selectionExceptions[key] ? acc + 1 : acc),
      0,
    ),
)

export const getConfirmedPricesSelectionCount = createSelector(
  getPreloadedPricesCount,
  getAllSelected,
  getPricesSelectionExceptionsCount,
  (
    pricesCount: number,
    allSelected: boolean,
    selectedExceptionsCount: number,
  ) =>
    allSelected
      ? pricesCount - selectedExceptionsCount
      : selectedExceptionsCount,
)

export const getHasSelectedPrices = createSelector(
  getPreloadedPricesCount,
  getBulkPricesTotalCount,
  getAllSelected,
  getPricesSelectionExceptionsCount,
  (
    pricesCount: number,
    totalCount: number,
    allSelected: boolean,
    selectedExceptionsCount: number,
  ) =>
    totalCount > 0 &&
    (allSelected
      ? selectedExceptionsCount < pricesCount
      : selectedExceptionsCount > 0),
)

export const isAllPricesSelected = (ids: string[]) => (state: RootState) => {
  const selection = getPricesSelection(ids)(state)
  return R.all(Boolean, R.values(selection))
}

export const getConfirmedSessions = (state: RootState) =>
  getBulkPrices(state).confirmedSessions
