import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BasePuiDialogProps, Patient, PuiDialog } from '@pbt/pbt-ui-components'
import { ForwardArrow } from '@pbt/pbt-ui-components/src/icons'

import SearchInput from '~/components/common/inputs/SearchInput'
import { getClientFinanceClientId } from '~/store/duck/clientFinanceData'
import { getPatientsList } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

import Item from './PatientItem'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 300,
      maxWidth: 300,
    },
    searchContainer: {
      padding: theme.spacing(0, 1.5),
    },
    searchInput: {
      marginBottom: theme.spacing(1),
    },
    patientItem: {
      padding: theme.spacing(0.5, 1.5, 0.5, 3),
      '&:not(:first-of-type)': {
        borderTop: theme.constants.tableBorder,
      },
    },
    scrollContainer: {
      paddingBottom: theme.spacing(1),
      width: '100%',
      overflow: 'scroll',
      height: 512,
      borderTop: theme.constants.tableBorder,
    },
  }),
  { name: 'PatientsListDialog' },
)

interface PatientsListDialogProps extends BasePuiDialogProps {
  hideSearch?: boolean
  onPatientClick: (patientId?: string) => void
  patientsIds: string[]
}

const PatientsListDialog = ({
  open,
  patientsIds,
  onPatientClick,
  onClose,
  hideSearch,
}: PatientsListDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const soapPatients = useSelector(getPatientsList(patientsIds))
  const chargeSheetClientId = useSelector(getClientFinanceClientId)
  const chargeSheetClient = useSelector(getUser(chargeSheetClientId))
  const chargeSheetPatients = useSelector(
    getPatientsList(chargeSheetClient?.patients),
  )

  const patients =
    R.isEmpty(soapPatients) || R.isNil(soapPatients)
      ? !R.isNil(chargeSheetPatients)
        ? chargeSheetPatients
        : []
      : soapPatients

  const [search, setSearch] = useState('')
  const filteredPatients =
    search && !R.isEmpty(patients)
      ? patients.filter(({ name }: Patient) =>
          name.toLowerCase().includes(search.toLowerCase()),
        )
      : patients

  return (
    <PuiDialog
      aria-labelledby="patients-list-dialog"
      classes={{ paper: classes.paper }}
      open={open}
      title={t('Common:PATIENTS')}
      onClose={onClose}
    >
      <Grid container>
        {!hideSearch && (
          <Grid container className={classes.searchContainer}>
            <SearchInput
              className={classes.searchInput}
              label={t('Common:SEARCH_ACTION')}
              value={search}
              onChange={setSearch}
            />
          </Grid>
        )}
        <Grid className={classes.scrollContainer}>
          {filteredPatients.map((patient) => (
            <Item
              Icon={ForwardArrow}
              className={classes.patientItem}
              key={patient.id}
              patientId={patient?.id}
              onClick={(id) => {
                onPatientClick(id)
                if (onClose) {
                  onClose()
                }
              }}
            />
          ))}
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default PatientsListDialog
