import React from 'react'
import { useTranslation } from 'react-i18next'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { getClientFinanceLoading } from '~/store/duck/clientFinanceData'
import { useGetDeleteChargeSheetItemAction } from '~/store/hooks/chargeSheet'
import {
  useGetIsOrderDeletable,
  useLabTestItemDecline,
  useUpdateOrderNotes,
} from '~/store/hooks/orders'
import { Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'

export interface LabTestActionsLineItemProps
  extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
}
// TODO need to move it to new OpenApi model once it will be ready, delete doesn't need new model to work
const LabTestActionsLineItem = ({
  editDisabled,
  onDelete: onDeleteProp,
  order,
  ...rest
}: LabTestActionsLineItemProps) => {
  const { t } = useTranslation('Common')

  const handleUpdateNotes = useUpdateOrderNotes(order)
  const { declineDisabled } = useLabTestItemDecline(order, editDisabled)
  const getIsDeletable = useGetIsOrderDeletable()
  const deleteAction = useGetDeleteChargeSheetItemAction(order)

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_RAIL_ORDER,
  })
  const [openRemoveFinalizedLabTestDialog, closeRemoveFinalizedLabTestDialog] =
    useDialog(DialogNames.REMOVE_FINALIZED_LAB_TEST)

  const onDeleteLabTest = useCloseAfterCreation(
    onDeleteProp,
    getClientFinanceLoading,
  )

  const isDeletable = getIsDeletable(order)
  const isUnderOrder = Boolean(order.labTest?.labOrderId)

  const onOk = () => {
    if (deleteAction) {
      deleteAction()
    }
    closeRemoveFinalizedLabTestDialog()
  }

  const handleDelete = () => {
    if (isUnderOrder) {
      // TODO when openApi model is ready add custom onCancel method
      openRemoveFinalizedLabTestDialog({
        order,
        onClose: closeRemoveFinalizedLabTestDialog,
        onOk,
      })
    } else {
      openConfirmAlert({
        message: t('Common:YOU_WILL_REMOVE_SOMETHING', {
          something: order.name,
        }),
        onConfirm: (proceed) => {
          if (proceed) {
            deleteAction?.()
            onDeleteLabTest()
          }
        },
      })
    }
  }

  return (
    <ItemWithBadgesActions
      declineDisabled={declineDisabled}
      hasNotes={Boolean(order.notes)}
      tooltip={<OrderTooltip forceShowPrices order={order} />}
      onDelete={isDeletable ? handleDelete : undefined}
      onUpdateNotes={handleUpdateNotes}
      {...rest}
    />
  )
}

export default LabTestActionsLineItem
