import { Nil } from '@pbt/pbt-ui-components'

import { Variation } from './inventories'

export type BulkVariationPreview = {
  id: string
  name: string
  prices: string[]
}

export type BulkVariationEdit = BulkVariationPreview & {
  genderId?: string
  speciesIds?: string
}

export type BulkPriceEdit = {
  genderId: string | Nil
  id: string
  maxTimeUnitSize?: number | null
  minTimeUnitSize?: number | null
  name: string
  packageTypeId?: Variation['packageTypeId']
  perPackageUnitsId?: Variation['perPackageUnitsId']
  priceUnitsSize: number | null
  speciesId: string | Nil
  timeUnitId?: string
  timeUnitSize?: number | null
  unit: number | Nil
  unitId: string | Nil
  updatedAt: string
  value: number
  variationId: string
}

export type BulkPriceSession = {
  creator?: string
  id: string
}

export type BulkPriceSelectionExceptions = Record<string, boolean>

export enum BulkPricesErrors {
  ANOTHER_SESSION_HAS_CREATED_STATUS_ERROR_TYPE = 'ANOTHER_SESSION_HAS_CREATED_STATUS_ERROR_TYPE',
  ANOTHER_SESSION_HAS_IN_PROGRESS_STATUS_ERROR_TYPE = 'ANOTHER_SESSION_HAS_IN_PROGRESS_STATUS_ERROR_TYPE',
  MISMATCHED_SESSION_STATUS_ERROR_TYPE = 'MISMATCHED_SESSION_STATUS_ERROR_TYPE',
}
