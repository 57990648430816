import React, { forwardRef } from 'react'
import { PatternFormat, PatternFormatProps } from 'react-number-format'

export interface DurationInputProps
  extends Omit<PatternFormatProps, 'format'> {}

/*
  Duration format: 00:30
*/
const DurationInput = forwardRef(function DurationInput(
  props: DurationInputProps,
  ref,
) {
  return <PatternFormat format="##:##" getInputRef={ref} {...props} />
})

export default DurationInput
