import React from 'react'
import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import { Prescription } from '~/types'

import BaseRefillDialog, { BaseRefillDialogProps } from './BaseRefillDialog'

export interface FoodRefillDialogProps extends BasePuiDialogProps {
  autoClose?: boolean
  handlePrint?: boolean
  onSave: BaseRefillDialogProps['onSave']
  outsideSoap?: boolean
  prescription: Prescription
  readonly?: boolean
}

const FoodRefillDialog = ({
  prescription,
  outsideSoap,
  open,
  handlePrint = false,
  autoClose = true,
  readonly = false,
  onClose,
  onSave,
}: FoodRefillDialogProps) => (
  <BaseRefillDialog
    simpleAdministrationInstructions
    simpleInstructions
    autoClose={autoClose}
    handlePrint={handlePrint}
    open={open}
    outsideSoap={outsideSoap}
    prescription={prescription}
    readonly={readonly}
    onClose={onClose}
    onSave={onSave}
  />
)

export default FoodRefillDialog
