import {
  ApiError,
  BlobWithName,
  EmployeeIdInfo,
  User,
} from '@pbt/pbt-ui-components'

import {
  EmployeeIdInfoToCreate,
  FetchMembersOptions,
  TeamMemberLicense,
} from '~/types'

import {
  ADD_MEMBER_TO_CURRENT_BUSINESS,
  CLEAR_ERROR,
  CREATE_TEAM,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  CREATE_TEAM_MEMBER_LICENSE,
  CREATE_TEAM_MEMBER_LICENSE_FAILURE,
  CREATE_TEAM_MEMBER_LICENSE_SUCCESS,
  CREATE_TEAM_SUCCESS,
  DELETE_MEMBER_FROM_BUSINESSES,
  DELETE_MEMBER_FROM_BUSINESSES_FAILURE,
  DELETE_MEMBER_FROM_BUSINESSES_SUCCESS,
  DELETE_MEMBER_FROM_CURRENT_BUSINESS,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  DELETE_TEAM_MEMBER_LICENSE,
  DELETE_TEAM_MEMBER_LICENSE_FAILURE,
  DELETE_TEAM_MEMBER_LICENSE_SUCCESS,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  EDIT_TEAM_MEMBER_LICENSE,
  EDIT_TEAM_MEMBER_LICENSE_FAILURE,
  EDIT_TEAM_MEMBER_LICENSE_SUCCESS,
  FETCH_MEMBER,
  FETCH_MEMBER_FAILURE,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBERS_FOR_SELECT_LIST,
  FETCH_MEMBERS_FOR_SELECT_LIST_FAILURE,
  FETCH_MEMBERS_FOR_SELECT_LIST_SUCCESS,
  FETCH_MEMBERS_LIST,
  FETCH_MEMBERS_LIST_FAILURE,
  FETCH_MEMBERS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_SUCCESS,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST_FAILURE,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST_SUCCESS,
  FETCH_TEAMS_LIST,
  FETCH_TEAMS_LIST_FAILURE,
  FETCH_TEAMS_LIST_SUCCESS,
  INVITE_MEMBER,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_SUCCESS,
  UPDATE_ACTIVE_STATUS,
  UPDATE_ACTIVE_STATUS_FAILURE,
  UPDATE_ACTIVE_STATUS_SUCCESS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_ROLES,
  UPDATE_MEMBER_ROLES_FAILURE,
  UPDATE_MEMBER_ROLES_SUCCESS,
  UPDATE_MEMBER_SUCCESS,
} from './types/members'

export const fetchMembersList = (options: FetchMembersOptions) => ({
  type: FETCH_MEMBERS_LIST,
  ...options,
})
export const fetchMembersListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_MEMBERS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchMembersListFailure = (error: ApiError) => ({
  type: FETCH_MEMBERS_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForMembersList = (options: FetchMembersOptions) => ({
  type: FETCH_MORE_ITEMS_FOR_MEMBERS_LIST,
  ...options,
})
export const fetchMoreItemsForMembersListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForMembersListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_FAILURE,
  error,
})

export const fetchMembersForSelectList = (options: FetchMembersOptions) => ({
  type: FETCH_MEMBERS_FOR_SELECT_LIST,
  ...options,
})
export const fetchMembersForSelectListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_MEMBERS_FOR_SELECT_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchMembersForSelectListFailure = (error: ApiError) => ({
  type: FETCH_MEMBERS_FOR_SELECT_LIST_FAILURE,
  error,
})

export const fetchMoreMembersForSelectList = (
  options: FetchMembersOptions,
) => ({
  type: FETCH_MORE_MEMBERS_FOR_SELECT_LIST,
  ...options,
})
export const fetchMoreMembersForSelectListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_MEMBERS_FOR_SELECT_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreMembersForSelectListFailure = (error: ApiError) => ({
  type: FETCH_MORE_MEMBERS_FOR_SELECT_LIST_FAILURE,
  error,
})

export const fetchTeamsList = (options: FetchMembersOptions) => ({
  type: FETCH_TEAMS_LIST,
  ...options,
})
export const fetchTeamsListSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_TEAMS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchTeamsListFailure = (error: ApiError) => ({
  type: FETCH_TEAMS_LIST_FAILURE,
  error,
})

export const fetchMember = (id: string, includeDeleted = false) => ({
  type: FETCH_MEMBER,
  id,
  includeDeleted,
})
export const fetchMemberSuccess = () => ({ type: FETCH_MEMBER_SUCCESS })
export const fetchMemberFailure = (error: ApiError) => ({
  type: FETCH_MEMBER_FAILURE,
  error,
})

export const inviteMember = (member: User) => ({ type: INVITE_MEMBER, member })
export const inviteMemberSuccess = () => ({ type: INVITE_MEMBER_SUCCESS })
export const inviteMemberFailure = (error: ApiError) => ({
  type: INVITE_MEMBER_FAILURE,
  error,
})

export const updateMember = (member: User, avatarBlob?: BlobWithName) => ({
  type: UPDATE_MEMBER,
  member,
  avatarBlob,
})
export const updateMemberSuccess = () => ({ type: UPDATE_MEMBER_SUCCESS })
export const updateMemberFailure = (error: ApiError) => ({
  type: UPDATE_MEMBER_FAILURE,
  error,
})

export const createTeam = (team: Omit<User, 'id'>) => ({
  type: CREATE_TEAM,
  team,
})
export const createTeamSuccess = (teamId: string) => ({
  type: CREATE_TEAM_SUCCESS,
  teamId,
})
export const createTeamFailure = (error: ApiError) => ({
  type: CREATE_TEAM_FAILURE,
  error,
})

export const updateMemberRoles = (
  memberId: string,
  businessToRoleList: User['businessToRoleList'],
) => ({
  type: UPDATE_MEMBER_ROLES,
  memberId,
  businessToRoleList,
})
export const updateMemberRolesSuccess = () => ({
  type: UPDATE_MEMBER_ROLES_SUCCESS,
})
export const updateMemberRolesFailure = (error: ApiError) => ({
  type: UPDATE_MEMBER_ROLES_FAILURE,
  error,
})

export const deleteMemberFromBusinesses = (
  memberId: string,
  businessesIds: string[],
) => ({
  type: DELETE_MEMBER_FROM_BUSINESSES,
  memberId,
  businessesIds,
})

export const deleteMemberFromBusinessesSuccess = () => ({
  type: DELETE_MEMBER_FROM_BUSINESSES_SUCCESS,
})

export const deleteMemberFromBusinessesFailure = (error: ApiError) => ({
  type: DELETE_MEMBER_FROM_BUSINESSES_FAILURE,
  error,
})

export const addMemberToCurrentBusiness = (memberId: string) => ({
  type: ADD_MEMBER_TO_CURRENT_BUSINESS,
  memberId,
})

export const deleteMemberFromCurrentBusiness = (memberId: string) => ({
  type: DELETE_MEMBER_FROM_CURRENT_BUSINESS,
  memberId,
})

export const createTeamMemberEmployeeId = (
  teamMemberId: string,
  newEmployeeIdInfo: EmployeeIdInfoToCreate,
) => ({
  type: CREATE_TEAM_MEMBER_EMPLOYEE_ID,
  newEmployeeIdInfo,
  teamMemberId,
})
export const createTeamMemberEmployeeIdSuccess = (
  teamMemberId: string,
  employeeIdInfos: EmployeeIdInfo[],
) => ({
  type: CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  teamMemberId,
  employeeIdInfos,
})
export const createTeamMemberEmployeeIdFailure = (error: ApiError) => ({
  type: CREATE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  error,
})

export const editTeamMemberEmployeeId = (
  teamMemberId: string,
  employeeIdInfo: EmployeeIdInfoToCreate,
) => ({
  type: EDIT_TEAM_MEMBER_EMPLOYEE_ID,
  teamMemberId,
  employeeIdInfo,
})
export const editTeamMemberEmployeeIdSuccess = (
  teamMemberId: string,
  employeeIdInfos: EmployeeIdInfo[],
) => ({
  type: EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  teamMemberId,
  employeeIdInfos,
})
export const editTeamMemberEmployeeIdFailure = (error: ApiError) => ({
  type: EDIT_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  error,
})

export const deleteTeamMemberEmployeeId = (
  teamMemberId: string,
  employeeIdInfoId: string,
) => ({
  type: DELETE_TEAM_MEMBER_EMPLOYEE_ID,
  employeeIdInfoId,
  teamMemberId,
})
export const deleteTeamMemberEmployeeIdSuccess = (
  teamMemberId: string,
  employeeIdInfos: EmployeeIdInfo[],
) => ({
  type: DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  teamMemberId,
  employeeIdInfos,
})
export const deleteTeamMemberEmployeeIdFailure = (error: ApiError) => ({
  type: DELETE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  error,
})

export const createTeamMemberLicense = (
  teamMemberId: string,
  license: TeamMemberLicense,
) => ({
  type: CREATE_TEAM_MEMBER_LICENSE,
  teamMemberId,
  license,
})
export const createTeamMemberLicenseSuccess = (
  teamMemberId: string,
  licenses: TeamMemberLicense[],
) => ({
  type: CREATE_TEAM_MEMBER_LICENSE_SUCCESS,
  teamMemberId,
  licenses,
})
export const createTeamMemberLicenseFailure = (error: ApiError) => ({
  type: CREATE_TEAM_MEMBER_LICENSE_FAILURE,
  error,
})

export const editTeamMemberLicense = (
  teamMemberId: string,
  license: TeamMemberLicense,
) => ({
  type: EDIT_TEAM_MEMBER_LICENSE,
  teamMemberId,
  license,
})
export const editTeamMemberLicenseSuccess = (
  teamMemberId: string,
  licenses: TeamMemberLicense[],
) => ({
  type: EDIT_TEAM_MEMBER_LICENSE_SUCCESS,
  teamMemberId,
  licenses,
})
export const editTeamMemberLicenseFailure = (error: ApiError) => ({
  type: EDIT_TEAM_MEMBER_LICENSE_FAILURE,
  error,
})

export const deleteTeamMemberLicense = (
  teamMemberId: string,
  licenseId: string,
) => ({
  type: DELETE_TEAM_MEMBER_LICENSE,
  teamMemberId,
  licenseId,
})
export const deleteTeamMemberLicenseSuccess = (
  teamMemberId: string,
  licenses: TeamMemberLicense[],
) => ({
  type: DELETE_TEAM_MEMBER_LICENSE_SUCCESS,
  teamMemberId,
  licenses,
})
export const deleteTeamMemberLicenseFailure = (error: ApiError) => ({
  type: DELETE_TEAM_MEMBER_LICENSE_FAILURE,
  error,
})

export const updateActiveStatus = (memberId: string, isActive: boolean) => ({
  type: UPDATE_ACTIVE_STATUS,
  memberId,
  isActive,
})
export const updateActiveStatusSuccess = () => ({
  type: UPDATE_ACTIVE_STATUS_SUCCESS,
})
export const updateActiveStatusFailure = (error: ApiError) => ({
  type: UPDATE_ACTIVE_STATUS_FAILURE,
  error,
})

export const clearMembersError = () => ({ type: CLEAR_ERROR })
