import React, { forwardRef } from 'react'
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from 'react-number-format'
import { Nil, NumberUtils, Utils } from '@pbt/pbt-ui-components'

export interface TaxRateInputProps
  extends Omit<NumericFormatProps, 'defaultValue'> {}

/*
  Tax rate from BE is implemented as coefficient, for UI it is processed to percent
*/
const getFormattedTaxValue = (value: string | number | Nil) =>
  Number(value) * 100
const getFormattedChangedTaxValue = (value: number | undefined) =>
  String(value ? Utils.round(value / 100, 4) : 0)

/*
  Tax rate input with precision of 2 digits
  5 -> 5.00 || 5,00
  5.05 -> 5.05 || 5,05
  5.45 -> 5.45 || 5,45
  5.452 -> 5.45 || 5,45
*/

const TaxRateInput = forwardRef(function TaxRateInput(
  { onChange, value, thousandSeparator = '', ...rest }: TaxRateInputProps,
  ref,
) {
  return (
    <NumericFormat
      fixedDecimalScale
      allowNegative={false}
      decimalScale={2}
      decimalSeparator={NumberUtils.getDecimalSeparator()}
      getInputRef={ref}
      isAllowed={({ floatValue }: NumberFormatValues) =>
        Number(floatValue) >= 0 && Number(floatValue) <= 100
      }
      thousandSeparator={thousandSeparator}
      value={getFormattedTaxValue(value)}
      onValueChange={(values, sourceInfo) => {
        const { event } = sourceInfo
        if (onChange && event) {
          const newEvent = {
            ...event,
            target: {
              ...event.target,
              value: getFormattedChangedTaxValue(values.floatValue),
            },
          } as React.ChangeEvent<HTMLInputElement>
          onChange(newEvent)
        }
      }}
      {...rest}
    />
  )
})

export default TaxRateInput
