export const FETCH_QUESTIONS_LIST_FAILURE =
  'questions/FETCH_QUESTIONS_LIST_FAILURE'
export const FETCH_QUESTIONS_LIST_SUCCESS =
  'questions/FETCH_QUESTIONS_LIST_SUCCESS'
export const FETCH_QUESTIONS_LIST = 'questions/FETCH_QUESTIONS_LIST'

export const FETCH_QUESTION = 'questions/FETCH_QUESTION'
export const FETCH_QUESTION_FAILURE = 'questions/FETCH_QUESTION_FAILURE'
export const FETCH_QUESTION_SUCCESS = 'questions/FETCH_QUESTION_SUCCESS'

export const FETCH_QUESTIONS_FOR_SOAP = 'questions/FETCH_QUESTIONS_FOR_SOAP'
export const FETCH_QUESTIONS_FOR_SOAP_FAILURE =
  'questions/FETCH_QUESTIONS_FOR_SOAP_FAILURE'
export const FETCH_QUESTIONS_FOR_SOAP_SUCCESS =
  'questions/FETCH_QUESTIONS_FOR_SOAP_SUCCESS'

export const SAVE_QUESTIONS_FOR_SOAP = 'questions/SAVE_QUESTIONS_FOR_SOAP'
export const SAVE_QUESTIONS_FOR_SOAP_FAILURE =
  'questions/SAVE_QUESTIONS_FOR_SOAP_FAILURE'
export const SAVE_QUESTIONS_FOR_SOAP_SUCCESS =
  'questions/SAVE_QUESTIONS_FOR_SOAP_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_FAILURE =
  'questions/FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_SUCCESS =
  'questions/FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST =
  'questions/FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST'

export const CREATE_QUESTION = 'questions/CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = 'questions/CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAILURE = 'questions/CREATE_QUESTION_FAILURE'

export const EDIT_QUESTION = 'questions/EDIT_QUESTION'
export const EDIT_QUESTION_SUCCESS = 'questions/EDIT_QUESTION_SUCCESS'
export const EDIT_QUESTION_FAILURE = 'questions/EDIT_QUESTION_FAILURE'

export const DELETE_QUESTION = 'questions/DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'questions/DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILURE = 'questions/DELETE_QUESTION_FAILURE'

export const UPDATE_QUESTIONS = 'questions/UPDATE_QUESTIONS'

export const QUESTION_VALIDATION_ERROR = 'questions/QUESTION_VALIDATION_ERROR'
export const CLEAR_QUESTION_VALIDATION_ERROR =
  'questions/CLEAR_QUESTION_VALIDATION_ERROR'
