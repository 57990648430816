import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConstantWithMeasurementsSystem,
  UnitsState,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  ConstantToUnitType,
  MeasurementConstant,
} from '~/constants/measurementUnits'
import { changeUnits } from '~/store/duck/settings'
import { getCurrentBusinessMeasurementTypeId } from '~/store/reducers/auth'
import { getConstantMap, getMeasurementTypes } from '~/store/reducers/constants'
import { findMatchingUnit } from '~/utils/measurementUnitsUtils'

const BusinessWatcher = () => {
  const dispatch = useDispatch()
  const currentBusinessMeasurementTypeId = useSelector(
    getCurrentBusinessMeasurementTypeId,
  )
  const constantsMap = useSelector(getConstantMap)
  const MeasurementTypes = useSelector(getMeasurementTypes)

  const MeasurementType = Utils.findById(
    currentBusinessMeasurementTypeId,
    MeasurementTypes,
  )
  const measurementSystemsIds =
    MeasurementType?.includedMeasurementSystemsIds || []

  useEffect(() => {
    if (currentBusinessMeasurementTypeId && measurementSystemsIds.length > 0) {
      const unitsState = Object.values(MeasurementConstant).reduce(
        (acc, value) => {
          const MeasurementSystemConstant = constantsMap[
            value
          ] as ConstantWithMeasurementsSystem[]
          return {
            ...acc,
            [ConstantToUnitType[value]]: findMatchingUnit(
              measurementSystemsIds,
              MeasurementSystemConstant,
            ),
          }
        },
        {} as UnitsState,
      )

      dispatch(changeUnits(unitsState))
    }
  }, [currentBusinessMeasurementTypeId, measurementSystemsIds])

  return null
}

export default BusinessWatcher
