import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LinkButton, NumberUtils, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCurrentBusinessWellnessPlansEnabled } from '~/store/reducers/auth'
import { getUserName } from '~/store/reducers/users'
import { BatchInvoice, Invoice } from '~/types'
import useDialog from '~/utils/useDialog'

import { getInvoiceDueToPayNoFee } from '../invoices/invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.75, 3),
      backgroundColor: theme.colors.tableEvenItem,
      marginBottom: theme.spacing(1),
    },
    text: {
      color: theme.colors.planBranding,
    },
    button: {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.colors.planBranding,
    },
    actionContainer: {
      width: 'fit-content',
    },
  }),
  { name: 'InvoiceLoyaltyPointsBanner' },
)

export interface InvoiceLoyaltyPointsBannerProps {
  className?: string
  invoice: Invoice | BatchInvoice
}

const InvoiceLoyaltyPointsBanner = ({
  invoice,
  className,
}: InvoiceLoyaltyPointsBannerProps) => {
  const classes = useStyles()
  const clientName = useSelector(getUserName(invoice.clientId))
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const { t } = useTranslation(['Common', 'Memberships', 'Time'])

  const [openRedeemLoyaltyPointsDialog] = useDialog(
    DialogNames.REDEEM_LOYALTY_POINTS,
  )

  const { savedAmount, earnedPoints, availablePoints, availableAmount } =
    invoice

  const hasSomethingToShow = Boolean(
    savedAmount || earnedPoints || availablePoints || availableAmount,
  )

  if (!wellnessPlansEnabled || !hasSomethingToShow) {
    return null
  }

  const handleRedeem = () => {
    openRedeemLoyaltyPointsDialog({
      totalPoints: earnedPoints,
      invoiceAmount: getInvoiceDueToPayNoFee(invoice),
      invoiceId: invoice.id,
    })
  }

  const savedPointsAmount = NumberUtils.formatMoney(savedAmount)
  const earnedLoyaltyPoints = earnedPoints && String(earnedPoints)
  const todayString =
    savedAmount || earnedPoints ? t('Time:LABEL.TODAY').toLowerCase() : ''

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item>
        <Text className={classes.text} variant="body2">
          {clientName}
          &nbsp;
          {savedAmount && (
            <Trans
              defaults="saved <strong>{{savedPointsAmount}}</strong>"
              i18nKey="Memberships:INVOICE_LOYALTY_POINTS_BANNER.CLIENT_SAVED_POINTS"
              values={{ savedPointsAmount }}
            />
          )}
          {savedAmount && earnedPoints && <>,&nbsp;</>}
          {earnedPoints && (
            <Trans
              defaults="earned <strong>{{earnedLoyaltyPoints}}</strong> loyalty points"
              i18nKey="Memberships:INVOICE_LOYALTY_POINTS_BANNER.EARNED_POINTS"
              values={{ earnedLoyaltyPoints }}
            />
          )}
          &nbsp;
          {todayString}
        </Text>
      </Grid>
      {availablePoints && (
        <Grid
          container
          item
          alignItems="center"
          className={classes.actionContainer}
          wrap="nowrap"
        >
          <Text className={classes.text} variant="body2">
            {t('Memberships:INVOICE_LOYALTY_POINTS_BANNER.LOYALTY_REWARDS', {
              availablePoints,
              availableAmount: NumberUtils.formatMoney(availableAmount),
            })}
          </Text>
          <LinkButton className={classes.button} onClick={handleRedeem}>
            {t('Common:REDEEM_AWARD_ACTION')}
          </LinkButton>
        </Grid>
      )}
    </Grid>
  )
}

export default InvoiceLoyaltyPointsBanner
