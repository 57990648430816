import { Moment } from 'moment'
import * as R from 'ramda'
import { DateFormat, moment, Nil } from '@pbt/pbt-ui-components'
import {
  OrderedWeekConstants,
  WeekDaysShort,
} from '@pbt/pbt-ui-components/src/localization'

import i18n from '~/locales/i18n'

export const getShortLocaleDaysString = (daysData: string[]) =>
  R.pipe(
    R.reduce(
      (acc: string[], day: string) =>
        daysData.includes(day) ? [...acc, WeekDaysShort[day]] : acc,
      [],
    ),
    R.join(', '),
  )(R.keys(OrderedWeekConstants))

export const getTimeRangeString = (
  startTime: Moment,
  endTime: Moment,
): string => {
  const start = moment(startTime).format(DateFormat.TIME_WITH_MERIDIAN)
  const end = moment(endTime).format(DateFormat.TIME_WITH_MERIDIAN)
  return `${start}-${end}`
}

export const getDateProximity = (
  dateString: string | Nil,
  timeString: Moment | Nil,
): string => {
  if (!dateString) {
    return i18n.t('Time:LABEL.NEVER')
  }
  const time =
    timeString?.format(DateFormat['24_HOURS_FORMAT_WITH_SECONDS']) || '23:59:59'
  return moment(`${dateString} ${time}`).fromNow()
}
