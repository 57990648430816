import { createSelector } from '@reduxjs/toolkit'
import { includes } from 'ramda'
import { AnyAction } from 'redux'
import { MembershipStatus } from '@pbt/pbt-ui-components/src/constants/wellnessPlans'

import SecretSearchContext from '~/constants/secretSearchContext'
import { SuggestionResult } from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import * as SearchTypes from '../actions/types/search'
import type { RootState } from '../index'
import { getPatientsMap } from './patients'

export type SearchState = {
  error: string | null
  isSecretlyReceiving: boolean
  isSuggestionReceiving: boolean
  searchHighlights: string[]
  searchTotalCount: number
  suggestedTotalCount: number
  suggestionResults: SuggestionResult[]
}

export const SEARCH_INITIAL_STATE: SearchState = {
  suggestionResults: [],
  isSuggestionReceiving: false,
  isSecretlyReceiving: false,
  suggestedTotalCount: 0,
  searchHighlights: [],
  searchTotalCount: 0,
  error: null,
}

const search = (state = SEARCH_INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SearchTypes.FETCH_SUGGESTION_RESULTS:
      return includes(action.searchContext, Object.values(SecretSearchContext))
        ? {
            ...state,
            isSecretlyReceiving: true,
            suggestionResults: [],
            suggestedTotalCount: 0,
          }
        : {
            ...state,
            isSuggestionReceiving: true,
            suggestionResults: [],
            suggestedTotalCount: 0,
          }

    case SearchTypes.FETCH_SUGGESTION_RESULTS_SUCCESS:
      return {
        ...state,
        suggestionResults: action.suggestionResults,
        isSecretlyReceiving: false,
        isSuggestionReceiving: false,
        error: null,
        suggestedTotalCount: action.totalCount,
      }

    case SearchTypes.FETCH_MORE_SUGGESTION_RESULTS:
      return includes(action.searchContext, Object.values(SecretSearchContext))
        ? { ...state, isSecretlyReceiving: true }
        : { ...state, isSuggestionReceiving: true }

    case SearchTypes.FETCH_MORE_SUGGESTION_RESULTS_SUCCESS:
      return {
        ...state,
        suggestionResults: mergeArraysAtIndex(
          state.suggestionResults,
          action.suggestionResults,
          action.from,
        ),
        isSecretlyReceiving: false,
        isSuggestionReceiving: false,
        error: null,
        suggestedTotalCount: action.totalCount,
      }
    case SearchTypes.FETCH_MORE_SUGGESTION_RESULTS_FAILURE:
    case SearchTypes.FETCH_SUGGESTION_RESULTS_FAILURE:
      return {
        ...state,
        suggestionResults: [],
        isSuggestionReceiving: false,
        isSecretlyReceiving: false,
        error: getErrorMessage(action.error),
        suggestedTotalCount: 0,
      }
    case SearchTypes.CLEAR_SUGGESTION_RESULTS:
      return {
        ...state,
        suggestionResults: [],
        suggestedTotalCount: 0,
      }
    case SearchTypes.CLEAR_SEARCH:
      return { ...SEARCH_INITIAL_STATE }
    case SearchTypes.UPDATE_SEARCH_HIGHLIGHTS:
      return {
        ...state,
        searchHighlights: action.highlights || [],
        searchTotalCount: action.totalCount || 0,
      }
    default:
      return state
  }
}

export const getSearch = (state: RootState): SearchState => state.search
export const getSuggestionResults = (state: RootState) =>
  getSearch(state).suggestionResults
export const getSuggestionResultsWithStatuses = createSelector(
  [getPatientsMap, getSuggestionResults],
  (patientsMap, suggestionResults) =>
    suggestionResults.map((result) => {
      const subItemWpActive = Boolean(
        patientsMap[result.subItemId || '']?.plans?.some(
          (plan) =>
            plan.wplanLogId &&
            !plan.wplanCancellationDate &&
            plan.wplanStatus === MembershipStatus.ACTIVE,
        ),
      )
      let foundActivePlan = false

      const newSubitems = result.subItems?.map((subitem) => {
        const patient = patientsMap[subitem.id]
        const wpActive = Boolean(
          patient.plans?.some(
            (plan) =>
              plan.wplanLogId &&
              !plan.wplanCancellationDate &&
              plan.wplanStatus === MembershipStatus.ACTIVE,
          ),
        )

        if (wpActive) {
          foundActivePlan = true
        }

        return {
          ...subitem,
          subItemWpActive: wpActive,
        }
      })

      return {
        ...result,
        subItems: newSubitems,
        wpActive: foundActivePlan,
        subItemWpActive,
      }
    }),
)
export const getSuggestionIsReceiving = (state: RootState) =>
  getSearch(state).isSuggestionReceiving
export const getSearchHighlights = (state: RootState) =>
  getSearch(state).searchHighlights
export const getSearchTotalCount = (state: RootState) =>
  getSearch(state).searchTotalCount
export const getSecretSearchIsReceiving = (state: RootState) =>
  getSearch(state).isSecretlyReceiving
export const getSuggestedTotalCount = (state: RootState) =>
  getSearch(state).suggestedTotalCount

export default search
