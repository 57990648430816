import { useEffect, useRef } from 'react'

/**
 * Identical to useEffect, except that it never runs on mount. This is the equivalent of
 * the componentDidUpdate lifecycle function.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} dependencies - useEffect dependency list.
 * @param ignoreEmptyDependencies - if passed won't mount unless dependencies exist
 */
const useEffectExceptOnMount = (
  effect: () => void,
  dependencies: any,
  ignoreEmptyDependencies = false,
) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (
      ignoreEmptyDependencies &&
      dependencies?.filter(Boolean)?.length === 0
    ) {
      return
    }

    if (mounted.current) {
      effect()
    } else {
      mounted.current = true
    }
  }, dependencies)
}

export default useEffectExceptOnMount
