import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddressUtils,
  Business,
  PhoneUtils,
  PuiHighlight,
  Shadow,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

import BusinessTypeIconGroup from '~/components/common/BusinessTypeIconGroup'
import ClinicLogo from '~/components/common/logos/ClinicLogo'
import { getBusinessStatus } from '~/store/reducers/constants'

import PracticeStatusLabel from './PracticeStatusLabel'
import PracticeTableName from './PracticeTableName'

const INACTIVE_STATUS = 'Inactive'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        height: 87,
        padding: theme.spacing(1, 2),
      },
      overflow: 'hidden',
      borderRadius: 2,
      border: theme.constants.tableBorder,
    },
    selectedRow: {
      border: theme.constants.tableBorderSelected,
    },
    practiceNameTextSelected: {
      color: theme.colors.tabSelected,
    },
    practiceNameTextContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
      },
    },
    mainInfoContainer: {
      [theme.breakpoints.up('md')]: {
        height: 70,
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        borderBottom: theme.constants.tableBorder,
      },
    },
    sideInfoContainer: {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
      },
    },
    expandedColumn: {
      maxWidth: '75%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsedColumn: {
      maxWidth: '0%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandedMainColumn: {
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2,
        delay: 100,
      }),
    },
    practiceLogo: {
      padding: 0,
      width: 100,
      height: 56,
      boxShadow:
        '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
    },
    link: {
      color: theme.colors.link,
    },
    sideInfoColumn: {
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 2, 2, 2),
      },
    },
    listShadow: {
      '&:hover': {
        boxShadow: theme.constants.listItemShadow,
      },
    },
    statusLabel: {
      width: 'fit-content',
    },
  }),
  { name: 'PracticeTableRow' },
)

export interface PracticeTableRowProps {
  className?: string
  highlightProps: {
    internalName?: string[]
    practiceName?: string[]
  }
  isLoading?: boolean
  isMobile?: boolean
  onClick?: (business: Business) => void
  practice: Business
  rowFocused?: string
}

const PracticeTableRow = ({
  isMobile,
  rowFocused,
  practice,
  isLoading,
  onClick,
  className,
  highlightProps,
}: PracticeTableRowProps) => {
  const classes = useStyles()

  const BusinessStatus = useSelector(getBusinessStatus)

  const isFocusedRow = rowFocused && rowFocused === practice.id
  const isExpanded = !R.isNil(rowFocused)

  const isInactive =
    INACTIVE_STATUS ===
    Utils.getConstantName(practice.statusId, BusinessStatus, '')

  return (
    <Grid
      container
      item
      alignContent="flex-start"
      alignItems={isMobile ? 'flex-start' : 'center'}
      className={classNames(classes.root, className, {
        [classes.listShadow]: !isFocusedRow,
        [classes.selectedRow]: isFocusedRow,
      })}
      position="relative"
      onClick={() => onClick && onClick(practice)}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.mainInfoContainer, {
          [classes.expandedMainColumn]: !isExpanded,
        })}
        md={isExpanded ? 12 : 3}
        wrap="nowrap"
      >
        <Grid item>
          <ClinicLogo
            className={classes.practiceLogo}
            clinic={practice}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs className={classes.practiceNameTextContainer}>
          <Dotdotdot clamp={isMobile ? 2 : 1}>
            <PracticeTableName
              className={isFocusedRow ? classes.practiceNameTextSelected : ''}
              highlights={highlightProps?.practiceName}
              isLoading={isLoading}
              name={practice?.name}
              sx={{ wordBreak: 'break-all' }}
            />
          </Dotdotdot>
          {practice?.internalName && (
            <Dotdotdot clamp={1}>
              <TextInteractive
                className={isFocusedRow ? classes.practiceNameTextSelected : ''}
                isLoading={isLoading}
                variant="body2"
              >
                <PuiHighlight
                  page
                  searchTerm={getHighlightedText(
                    practice.internalName,
                    highlightProps?.internalName,
                  )}
                >
                  {practice.internalName}
                </PuiHighlight>
              </TextInteractive>
            </Dotdotdot>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems={isMobile ? 'flex-start' : 'center'}
        className={classNames(classes.sideInfoColumn, {
          [classes.collapsedColumn]: !isMobile && isExpanded,
          [classes.expandedColumn]: !isMobile && !isExpanded,
        })}
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 0 : 1}
        wrap="nowrap"
      >
        <Grid item className={classes.sideInfoContainer} md={3}>
          <Dotdotdot clamp={1}>
            <TextInteractive isLoading={isLoading} variant="body">
              {practice.website && (
                <Link
                  className={classes.link}
                  color="secondary"
                  href={Utils.toExternalUrl(practice.website)}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="always"
                  onClick={(e) => e.stopPropagation()}
                >
                  {practice.website}
                </Link>
              )}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={2}>
          <Dotdotdot clamp={1}>
            <TextInteractive isLoading={isLoading} variant="body">
              {PhoneUtils.getPhoneNumber(practice.phone)}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={3}>
          <Dotdotdot clamp={2}>
            <TextInteractive isLoading={isLoading} variant="body">
              {AddressUtils.formatAddress(practice, true)}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={2}>
          <BusinessTypeIconGroup ids={practice?.practiceTypeIds} />
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={2}>
          {isLoading ? (
            <TextInteractive isLoading={isLoading} variant="body" />
          ) : (
            <PracticeStatusLabel
              className={classes.statusLabel}
              statusId={practice.statusId}
            />
          )}
        </Grid>
      </Grid>
      <Shadow open={isInactive} />
    </Grid>
  )
}

export default PracticeTableRow
