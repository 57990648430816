import React from 'react'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import i18n from '~/locales/i18n'

const Options = [
  {
    id: 'true',
    name: i18n.t('Admin:APPOINTMENT_CONFIGURATION.APPOINTMENT_TYPE_ENABLED'),
  },
  {
    id: 'false',
    name: i18n.t('Admin:APPOINTMENT_CONFIGURATION.APPOINTMENT_TYPE_DISABLED'),
  },
]

export interface AppointmentTypeEnabledFilterProps
  extends CheckboxListFilterProps {}

const AppointmentTypeEnabledFilter = (
  props: AppointmentTypeEnabledFilterProps,
) => (
  <CheckboxListFilter
    clearEmptyFilters
    singleSelection
    includeSearch={false}
    items={Options}
    {...props}
  />
)

export default AppointmentTypeEnabledFilter
