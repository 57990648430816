import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTooltip, Text, TextProps } from '@pbt/pbt-ui-components'

import { useIsTextTruncated } from '~/utils/useIsTextTruncated'
import { useRefCallback } from '~/utils/useRefCallback'

const useStyles = makeStyles(() => ({
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

interface ShipmentItemsTableNameProps extends TextProps {
  name: string
}

export const ShipmentItemsTableName = ({
  name,
  ...textProps
}: ShipmentItemsTableNameProps) => {
  const classes = useStyles()
  const { ref: nameElementRef, refCallback } = useRefCallback(null)

  const isNameTruncated = useIsTextTruncated(nameElementRef.current)

  return (
    <PuiTooltip
      disabled={!isNameTruncated}
      tooltipPlacement="top"
      tooltipText={name}
    >
      <Text className={classes.name} ref={refCallback} {...textProps}>
        {name}
      </Text>
    </PuiTooltip>
  )
}
