import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Field,
  PermissionArea,
  Text,
  User,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import LabVendorName from '~/constants/labVendorName'
import { updateZnLabProviderId } from '~/store/actions/users'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getUserIsSending } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    znLabProviderLabel: {
      borderBottom: theme.constants.tableBorder,
      lineHeight: '32px',
      marginBottom: theme.spacing(1),
    },
    znLabProviderLabelBold: {
      fontWeight: 500,
      marginRight: theme.spacing(3),
    },
    selectZnLabProviderButton: {
      height: 40,
      minWidth: 164,
    },
  }),
  { name: 'MemberZoetisReferenceSection' },
)

interface MemberZoetisReferenceSectionProps {
  teamMember: User
  znlabsProviderIdentifier: Field
}

const MemberZoetisReferenceSection = ({
  teamMember,
  znlabsProviderIdentifier,
}: MemberZoetisReferenceSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const isSavingZnLabProviderId = useSelector(getUserIsSending)
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))

  const [openLabVendorProvidersDialog] = useDialog(
    DialogNames.LAB_VENDOR_PROVIDERS_DIALOG,
  )

  const handleSelectZnLabProvider = () => {
    openLabVendorProvidersDialog({
      onOk: (providerId?: string) => {
        if (providerId) {
          dispatch(updateZnLabProviderId(teamMember.id, providerId))
        }
      },
    })
  }

  return (
    <Grid container item>
      <Grid item sm={12}>
        {znlabsProviderIdentifier.value && (
          <Text className={classes.znLabProviderLabel} color="secondary">
            <span className={classes.znLabProviderLabelBold}>
              {t('Admin:MEMBER.ZOETIS.PROVIDER', {
                provider: LabVendorName.ZN_LABS,
              })}
            </span>
            {znlabsProviderIdentifier.value}
          </Text>
        )}
        {personPermissions.update && (
          <ButtonWithLoader
            className={classes.selectZnLabProviderButton}
            color={znlabsProviderIdentifier.value ? 'secondary' : 'primary'}
            disabled={isSavingZnLabProviderId}
            loading={isSavingZnLabProviderId}
            onClick={handleSelectZnLabProvider}
          >
            {t('Admin:MEMBER.ZOETIS.SELECT_PROVIDER_ID')}
          </ButtonWithLoader>
        )}
      </Grid>
    </Grid>
  )
}

export default MemberZoetisReferenceSection
