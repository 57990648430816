import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

export const useNoCardOrAddressDialog = ({
  hasPaymentMethods,
  hasShippingAddress,
  loading,
  onMoveToCart,
}: {
  hasPaymentMethods: boolean
  hasShippingAddress: boolean
  loading: boolean
  onMoveToCart: () => void
}) => {
  const { t } = useTranslation(['Dialogs'])
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const [alertShown, setAlertShown] = useState(false)

  const message =
    !hasPaymentMethods && !hasShippingAddress
      ? t('Dialogs:CHEWY_PAYMENT_DIALOG.NO_SAVED_ADDRESSES_AND_PAYMENT_METHODS')
      : !hasPaymentMethods
        ? t('Dialogs:CHEWY_PAYMENT_DIALOG.NO_SAVED_PAYMENT_METHODS')
        : !hasShippingAddress
          ? t('Dialogs:CHEWY_PAYMENT_DIALOG.NO_SAVED_ADDRESSES')
          : null

  useEffectExceptOnMount(() => {
    if (!loading && !alertShown && message) {
      setAlertShown(true)
      openAlert({
        iconType: AlertIconType.WARN,
        sx: { '& .MuiPaper-root': { maxWidth: 632, width: 'auto' } },
        message,
        okButtonText: t(
          'Dialogs:CHEWY_PAYMENT_DIALOG.MOVE_ITEMS_TO_CHEWY_CART',
        ),
        onOk: () => {
          onMoveToCart()
          closeAlert()
        },
      })
    }
  }, [loading])
}
