import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import {
  fetchInventoryBulkPrices,
  startInventoryBulkPricesSession,
} from '~/store/duck/bulkPrices'

import {
  BulkPricesCategoryConstant,
  BulkPricesSubCategoryConstant,
} from '../bulk-prices/BulkPricesEditFilters'
import BulkPricesPage from '../bulk-prices/BulkPricesPage'

const InventoryBulkPricesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const loadItems = (
    from: number,
    to: number,
    category: string | number,
    subCategory: string | number,
  ) => {
    dispatch(fetchInventoryBulkPrices({ from, to, category, subCategory }))
  }

  const startNewSession = () => {
    dispatch(startInventoryBulkPricesSession({}))
  }

  return (
    <BulkPricesPage
      categoryName={BulkPricesCategoryConstant.INVENTORY}
      entityType={BulkPriceEntityTypes.INVENTORY}
      loadItems={loadItems}
      startNewSession={startNewSession}
      subCategoryName={BulkPricesSubCategoryConstant.INVENTORY}
      title={t('Admin:CATALOG.INVENTORY_CATALOG')}
    />
  )
}

export default InventoryBulkPricesPage
