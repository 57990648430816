import React, { useRef, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { MenuItem, MenuList } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiPopper } from '@pbt/pbt-ui-components'
import { RecentList } from '@pbt/pbt-ui-components/src/icons'

import PuiIconButton from '~/components/common/PuiIconButton'
import { getCurrentUser } from '~/store/reducers/auth'
import { HistoryItem, readFromStorage } from '~/utils/recentList'
import useReloadRedirect from '~/utils/useReloadRedirect'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 300,
      maxHeight: 380,
      overflow: 'auto',
      boxShadow: theme.constants.blockShadow,
    },
    item: {
      fontSize: '1.6rem',
      lineHeight: '22px',
      padding: theme.spacing(1, 2),
    },
    menu: {
      padding: 0,
    },
    overflow: {
      display: 'inline-block !important',
    },
  }),
  { name: 'RecentListButton' },
)

const RecentListButton = () => {
  const classes = useStyles()
  const currentUser = useSelector(getCurrentUser)

  const reloadRedirect = useReloadRedirect()

  const ref = useRef<HTMLButtonElement>(null)
  const anchorEl = ref.current

  const [openRecentList, setOpenRecentList] = useState(false)

  const recentListItems: HistoryItem[] = currentUser
    ? readFromStorage(currentUser.id)
    : []

  const handleNavigate = (url: string) => {
    reloadRedirect(url)
    setOpenRecentList(false)
  }

  return (
    <>
      <PuiIconButton
        Icon={RecentList}
        ref={ref}
        onClick={() => setOpenRecentList(!openRecentList)}
      />
      <PuiPopper
        hideCloseButton
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [8, 8],
            },
          },
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: true,
          },
        ]}
        open={openRecentList}
        placement="bottom"
        onClose={() => setOpenRecentList(false)}
      >
        <MenuList disableListWrap className={classes.menu}>
          {recentListItems?.map(({ title, url }, index) => (
            <MenuItem
              className={classes.item}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => handleNavigate(url)}
            >
              <Dotdotdot clamp={1} className={classes.overflow}>
                {title}
              </Dotdotdot>
            </MenuItem>
          ))}
        </MenuList>
      </PuiPopper>
    </>
  )
}

export default RecentListButton
