import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AppointmentEventType,
  ClassesType,
  DateUtils,
  EventTypeName,
  LanguageUtils,
  Text,
} from '@pbt/pbt-ui-components'

import { useIsSoapCustomizationEnabled } from '~/components/dashboard/soapV2/utils/useIsSoapCustomizationEnabled'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { TimetableEvent } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import { useEventType } from '~/utils/useEventType'

import Link from '../link/Link'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'auto',
      padding: theme.spacing(1),
      border: theme.constants.tableBorder,
    },
    link: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'AddedToAppointmentLabel' },
)

export interface AddedToAppointmentLabelProps {
  appointment: TimetableEvent
  classes?: ClassesType<typeof useStyles>
  soapId: string
}

const AddedToAppointmentLabel = ({
  classes: classesProp,
  appointment,
  soapId,
}: AddedToAppointmentLabelProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )

  const { subTypeId, startDate } = appointment
  const subTypeName = LanguageUtils.getConstantTranslatedName(
    subTypeId,
    AppointmentSubTypes,
  )

  const appointmentName = [
    subTypeName,
    startDate && DateUtils.formatDate(startDate),
  ]
    .filter(Boolean)
    .join(' ')

  const url = isSoapCustomizationEnabled
    ? `/soap/${soapId}`
    : `/soap/${soapId}/appointment`

  return (
    <Grid className={classes.root}>
      <Text inline>{t('Common:ADDED_TO')}: </Text>
      <Link
        className={classes.link}
        to={addOriginalBusinessId(
          url,
          isPatientSharingEnabled ? appointment.businessId : null,
        )}
      >
        {appointmentName}
      </Link>
    </Grid>
  )
}

export default AddedToAppointmentLabel
