import React from 'react'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  (theme) => ({
    borderCell: {
      borderRight: theme.constants.tabBorder,
    },
  }),
  { name: 'TaxCellSubHead' },
)

export interface TaxCellSubHeadProps {
  className?: string
}

const TaxCellSubHead = ({ className }: TaxCellSubHeadProps) => {
  const classes = useStyles()

  return (
    <TableCell
      className={classNames(className, classes.borderCell)}
      colSpan={2}
    />
  )
}

export default TaxCellSubHead
