import React from 'react'
import { useDispatch } from 'react-redux'

import { startProceduresBulkPricesSession } from '~/store/duck/bulkPrices'

import BulkPricingLink from '../bulk-prices/BulkPricingLink'

const ProceduresBulkPricingLink = () => {
  const dispatch = useDispatch()

  const startNewSession = (force?: boolean) => {
    dispatch(
      startProceduresBulkPricesSession({
        force,
        navigateTo: '/admin/catalog/procedures/prices',
      }),
    )
  }
  return <BulkPricingLink startNewSession={startNewSession} />
}

export default ProceduresBulkPricingLink
