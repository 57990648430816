import * as R from 'ramda'
import {
  Constant,
  LanguageUtils,
  Nil,
  NumberUtils,
  PluralUtils,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  AmountWithUnitId,
  ReminderProtocol,
  ReminderProtocolTrigger,
} from '~/types'

export const getRangeUnits = (
  Units: Constant[],
  min: AmountWithUnitId | Nil,
  max: AmountWithUnitId | Nil,
) => {
  const getUnit = (unitId: string, amount = 0) => {
    const unitDisplayName = LanguageUtils.getConstantTranslatedName(
      unitId,
      Units,
    ).toLowerCase()

    return PluralUtils.getPluralForm(amount || 0, {
      singular: unitDisplayName,
      plural: PluralUtils.pluralizeOneOrOther(unitDisplayName),
      singularOrPlural: PluralUtils.pluralizeOneOrOther(unitDisplayName),
      fallback: unitDisplayName,
    })
  }

  if (!min && !max) {
    return i18n.t('Common:ALL')
  }

  if (!min && max) {
    return `0 - ${NumberUtils.formatNumberWithDecimal(max.amount)}
    ${getUnit(max.unitId, max.amount)}`
  }

  if (!max && min) {
    return `${NumberUtils.formatNumberWithDecimal(min.amount)} +
    ${getUnit(min.unitId, min.amount)}`
  }

  if (min && max) {
    if (min.unitId === max.unitId) {
      return `${NumberUtils.formatNumberWithDecimal(min.amount)} -
      ${NumberUtils.formatNumberWithDecimal(max.amount)}
      ${getUnit(min.unitId, max.amount)}`
    }

    return `${NumberUtils.formatNumberWithDecimal(min.amount)}
    ${getUnit(min.unitId, min.amount)} - ${max.amount}
    ${getUnit(max.unitId, max.amount)}`
  }
  return undefined
}

export const addAnyPrefix = (name: string) => {
  const [localizedAny] = i18n
    .t('Utils:REMINDER_PROTOCOL_GROUPS_UTILS.ANY_NAME', { name: 'test' })
    .split(' ')
  if ((!name && typeof name !== 'string') || name.indexOf(localizedAny) === 0) {
    return name
  }

  return i18n.t('Utils:REMINDER_PROTOCOL_GROUPS_UTILS.ANY_NAME', { name })
}

const REMINDER_PROTOCOLS_CATALOG_ROOT_TYPES = [
  'PROCEDURE',
  'INVENTORY_ITEM',
  'GLOBAL_INVENTORY_ITEM',
]

export const addAnyPrefixIfNeeded = (item: ReminderProtocolTrigger) => {
  const type = item?.type || item?.itemType

  if (!item || !type || !item.name) {
    return ''
  }

  return REMINDER_PROTOCOLS_CATALOG_ROOT_TYPES.includes(type)
    ? addAnyPrefix(item.name)
    : item.name
}

export const hasEmptyTrigger = (protocol: ReminderProtocol) =>
  R.isNil(protocol.due) ||
  R.isEmpty(protocol.due) ||
  R.isNil(protocol.triggersWith) ||
  R.isEmpty(protocol.triggersWith) ||
  R.isNil(protocol.resolvesWith) ||
  R.isEmpty(protocol.resolvesWith)
