import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 56,
      width: 104,
      minWidth: 104,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
      boxShadow:
        '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
    },
    image: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      padding: theme.spacing(0.5),
    },
    nameContainer: {
      margin: theme.spacing(1),
      textAlign: 'center',
    },
  }),
  { name: 'ImageLogo' },
)

export interface ImageLogoProps {
  className?: string
  isLoading?: boolean
  logo: string
  name: string
}

const ImageLogo = ({ logo, name, isLoading, className }: ImageLogoProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(classes.root, className)}
      justifyContent="center"
    >
      {isLoading ? (
        <Skeleton height={56} variant="rectangular" width={104} />
      ) : logo ? (
        <img alt="logo" className={classes.image} src={logo} />
      ) : (
        <Dotdotdot clamp={2} className={classes.nameContainer}>
          <Text strong variant="body2">
            {name}
          </Text>
        </Dotdotdot>
      )}
    </Grid>
  )
}

export default ImageLogo
