import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddButton,
  ImageScalingUtils,
  Nil,
  Patient,
  PatientAvatar,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { useCreatePatient } from '~/store/hooks/patient'
import { getSpecies } from '~/store/reducers/constants'
import { getPatientsList } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme) => ({
    row: {
      padding: theme.spacing(2, 3),
      '&:not(:first-child)': {
        borderTop: theme.constants.tabBorder,
      },
      cursor: 'pointer',
    },
    patientAvatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    textContainer: {
      marginLeft: theme.spacing(2),
    },
    addButtonContainer: {
      padding: theme.spacing(1, 3, 4, 3),
    },
  }),
  { name: 'PatientSelector' },
)

export interface PatientSelectorProps {
  clientId: string | Nil
  onSelected: (patient: Patient) => void
}

const PatientSelector = ({ clientId, onSelected }: PatientSelectorProps) => {
  const classes = useStyles()
  const client = useSelector(getUser(clientId))
  const Species = useSelector(getSpecies)
  const patients = useSelector(getPatientsList(client?.patients || []))
  const { t } = useTranslation('Common')
  const { createPatient } = useCreatePatient()

  const [openPatientDialog] = useDialog(DialogNames.PATIENT)

  const onAddRequested = () => {
    openPatientDialog({
      clientId,
      createPatient,
    })
  }

  return (
    <Grid container direction="column">
      {patients.map((patient) => (
        <Grid
          container
          item
          alignItems="center"
          className={classes.row}
          key={patient.id}
          onClick={() => onSelected(patient)}
        >
          <Grid item>
            <PatientAvatar
              small
              animal={Utils.getConstantName(patient.species, Species)}
              classes={{ root: classes.patientAvatar }}
              src={ImageScalingUtils.getScaledImageOrOriginal(
                patient.photo,
                patient.photoThumbnail,
                AVATAR_SIZE,
              )}
            />
          </Grid>
          <Grid item xs className={classes.textContainer}>
            <Text strong variant="subheading2">
              {patient.name}
            </Text>
          </Grid>
        </Grid>
      ))}
      <Grid item className={classes.addButtonContainer}>
        <AddButton
          addText={t('Common:ADD_NEW_PATIENT')}
          variant="dashed"
          onAdd={onAddRequested}
        />
      </Grid>
    </Grid>
  )
}

export default PatientSelector
