import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ClassesType,
  Nil,
  PuiDialog,
  Utils,
} from '@pbt/pbt-ui-components'

import { ImagingVendorLabel } from '~/constants/imaging'
import {
  cancelOrder as cancelImagingOrder,
  createOrder as createImagingOrder,
  editOrder as editImagingOrder,
  fetchOrder as fetchImagingOrder,
  getImagingIsLoading,
  getImagingOrder,
} from '~/store/duck/imagingOrders'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import { getConstantName, getImagingVendors } from '~/store/reducers/constants'
import { getDoctorId } from '~/store/reducers/soap'
import { ImagingOrder as ImagingOrderType, Order } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ImagingOrder, { ImagingOrderHandle } from './ImagingOrder'
import { isCompletedImagingStatus } from './imagingProceduresUtils'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflow: 'visible',
    },
    actionButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
      minWidth: 120,
    },
    content: {},
    linkButton: {
      textDecoration: 'underline',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
  }),
  { name: 'ImagingOrderDialog' },
)

interface ImagingOrderDialogProps extends BasePuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  isInvoiceLineItem?: boolean
  isSoapFinalized?: boolean
  orderId: string | Nil
  soapId?: string | Nil
  soapLog?: Order | Nil
}

const ImagingOrderDialog = ({
  classes: classesProp,
  open,
  onClose,
  soapId,
  isSoapFinalized,
  soapLog,
  orderId,
  isInvoiceLineItem,
}: ImagingOrderDialogProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const imagingRef = useRef<ImagingOrderHandle>(null)
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getImagingIsLoading)
  const ImagingVendors = useSelector(getImagingVendors)
  const soapDoctorId = useSelector(getDoctorId)
  const order = useSelector(getImagingOrder(orderId))
  const orderStatusName = useSelector(
    getConstantName('ImagingOrderStatuses', order?.statusId),
  )

  useEffect(() => {
    if (!order?.id && orderId) {
      dispatch(fetchImagingOrder(orderId))
    }
  }, [order?.id])

  const orderDraft = soapLog
    ? {
        name: soapLog.name,
        notes: soapLog.notes,
        assignedVetId: R.propOr(soapDoctorId, 'producerId', soapLog) as string,
        procedureId: soapLog.procedure?.id,
        procedureCode: soapLog.procedure?.procedureCode,
        modalityId: soapLog.procedure?.modalityId,
        soapId: soapLog.soapId,
      }
    : ({} as ImagingOrderType)

  const orderCancellable = !(
    isSoapFinalized ||
    (orderStatusName && isCompletedImagingStatus(orderStatusName))
  )
  const isExistingOrder = Boolean(orderId)
  const vendorId = useGetIdexxImagingId()
  const vendorName = Utils.getConstantName(vendorId, ImagingVendors)
  const vendorLabel = ImagingVendorLabel[vendorName]
  const procedureName = soapLog?.procedure?.description
  const procedureNameStr = procedureName ? ` | ${procedureName}` : ''
  const dialogTitle = isExistingOrder
    ? `${t('Common:IMAGE_REQUEST')} | ${order?.name}`
    : t('Common:SEND_REQUEST_TO', {
        receiver: `${vendorLabel}${procedureNameStr}`,
      })

  const closeAfterCreate = useCloseAfterCreation(onClose, getImagingIsLoading)

  const handleSendOrder = () => {
    if (imagingRef.current?.validate()) {
      const updatedOrder = imagingRef.current?.get()
      const action = isExistingOrder ? editImagingOrder : createImagingOrder
      dispatch(
        action(
          {
            ...(isExistingOrder ? order : orderDraft),
            ...updatedOrder,
            // in case we edit an orphan order it won't have logId
            logId:
              order?.logId || isInvoiceLineItem ? soapLog?.logId : soapLog?.id,
            soapId: order?.soapId || soapId,
          } as ImagingOrderType,
          vendorLabel,
        ),
      )
      closeAfterCreate()
    }
  }

  const handleCancelOrder = () => {
    if (isExistingOrder && order?.id) {
      dispatch(cancelImagingOrder(order.id))
      closeAfterCreate()
    }
  }

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.actionButton}
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSendOrder}
          >
            {isExistingOrder
              ? t('Common:SAVE_ACTION')
              : t('Common:SEND_ACTION')}
          </ButtonWithLoader>
          {isExistingOrder && orderCancellable && (
            <Link
              className={classes.linkButton}
              component="button"
              onClick={handleCancelOrder}
            >
              {t('Common:CANCEL_ACTION')}
            </Link>
          )}
        </>
      }
      classes={{ paper: classes.paper }}
      open={open}
      title={dialogTitle}
      onClose={onClose}
    >
      <Grid className={classes.content} p={2}>
        <ImagingOrder
          isSoapFinalized={isSoapFinalized}
          order={isExistingOrder ? order : orderDraft}
          ref={imagingRef}
        />
      </Grid>
    </PuiDialog>
  )
}

export default ImagingOrderDialog
