import { ApiError } from '@pbt/pbt-ui-components'

import { SearchParameters, SuggestionResult } from '~/types'

import {
  CLEAR_SEARCH,
  CLEAR_SUGGESTION_RESULTS,
  FETCH_MORE_SUGGESTION_RESULTS,
  FETCH_MORE_SUGGESTION_RESULTS_FAILURE,
  FETCH_MORE_SUGGESTION_RESULTS_SUCCESS,
  FETCH_SUGGESTION_RESULTS,
  FETCH_SUGGESTION_RESULTS_FAILURE,
  FETCH_SUGGESTION_RESULTS_SUCCESS,
  UPDATE_SEARCH_HIGHLIGHTS,
} from './types/search'

export const fetchSuggestionResults = (searchParameters: SearchParameters) => ({
  type: FETCH_SUGGESTION_RESULTS,
  ...searchParameters,
})

export const fetchMoreSuggestionResults = (
  searchParameters: SearchParameters,
) => ({
  type: FETCH_MORE_SUGGESTION_RESULTS,
  ...searchParameters,
})

export const fetchSuggestionResultsSuccess = (
  suggestionResults: SuggestionResult[],
  totalCount: number,
) => ({
  type: FETCH_SUGGESTION_RESULTS_SUCCESS,
  suggestionResults,
  totalCount,
})
export const fetchSuggestionResultsFailure = (error: ApiError) => ({
  type: FETCH_SUGGESTION_RESULTS_FAILURE,
  error,
})
export const clearSuggestionResults = () => ({ type: CLEAR_SUGGESTION_RESULTS })
export const clearSearch = () => ({ type: CLEAR_SEARCH })

export const updateSearchHighlights = (
  highlights: string[],
  totalCount: number,
) => ({
  type: UPDATE_SEARCH_HIGHLIGHTS,
  highlights,
  totalCount,
})
export const fetchMoreSuggestionResultsSuccess = (
  suggestionResults: SuggestionResult[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_SUGGESTION_RESULTS_SUCCESS,
  suggestionResults,
  totalCount,
  from,
})
export const fetchMoreSuggestionResultsFailure = (error: ApiError) => ({
  type: FETCH_MORE_SUGGESTION_RESULTS_FAILURE,
  error,
})
