import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Icons, StateLabel, TextInteractive } from '@pbt/pbt-ui-components'
import { Boop as BoopIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Order } from '~/types'
import useDialog from '~/utils/useDialog'

import OrderListItem, { OrderListItemProps } from './OrderListItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: `0 ${theme.spacing(0.5)}`,
    },
    benefitNameText: {
      flexWrap: 'nowrap',
      cursor: 'pointer',
    },
    caret: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    collapse: {
      width: '100%',
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
      paddingLeft: theme.spacing(3),
    },
    listItemUsed: {
      color: theme.colors.tabLabel,
    },
    stateLabel: {
      paddingTop: 2,
      paddingBottom: 2,
      width: 'fit-content',
      verticalAlign: 'middle',
    },
    icon: {
      marginBottom: 1,
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
      fontSize: '1.5rem',
    },
  }),
  { name: 'BenefitListItem' },
)

export interface BenefitListItemProps extends OrderListItemProps {
  disabledWeakMap: WeakMap<Order, boolean>
  editDisabled: boolean
  isSelected: boolean
  selectedSubItem: Order
  updateSelectedItem: (order: Order) => void
  updateSelectedSubItem: (order: Order) => void
}

const BenefitListItem = forwardRef<HTMLDivElement, BenefitListItemProps>(
  function BenefitListItem(
    {
      clientId,
      patientId,
      searchTerm,
      order,
      isSelected,
      selectedSubItem,
      updateSelectedItem,
      isCheckedItem,
      updateSelectedSubItem,
      editDisabled,
      disabledWeakMap,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()

    const { t } = useTranslation(['Common', 'Soap'])
    const isBoopDisablementEnabled = useSelector(
      getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
    )

    const [openPatientMembershipDialog] = useDialog(
      DialogNames.PATIENT_MEMBERSHIP,
    )

    const expand = () => {
      updateSelectedItem(order)
    }

    const viewUsage = () => {
      openPatientMembershipDialog({
        clientId,
        patientId,
      })
    }

    return (
      <Grid
        container
        item
        className={classes.root}
        direction="column"
        ref={ref}
      >
        <Grid
          container
          item
          alignItems="center"
          className={classes.benefitNameText}
          onClick={expand}
        >
          {isSelected ? (
            <ExpandLess className={classes.caret} />
          ) : (
            <ExpandMore className={classes.caret} />
          )}
          <TextInteractive highlight={searchTerm} variant="body2">
            {`${order.name} | `}
            {`${order.planName ? `${order.planName} | ` : ''}`}
            {`${order.usageCount || 0}/${
              order.limit || t('Common:UNLIMITED').toLowerCase()
            } ${t('Common:USED').toLowerCase()} `}
            {order.limitReached && (
              <StateLabel
                warning
                className={classes.stateLabel}
                // @ts-ignore
                component="span"
                variant="small"
              >
                {!isBoopDisablementEnabled && (
                  <BoopIcon
                    className={classes.icon}
                    variant={Icons.IconVariant.WARNING}
                  />
                )}
                {t('Common:LIMIT_REACHED')}
              </StateLabel>
            )}
          </TextInteractive>
          {!R.isNil(order.usageCount) && order.usageCount > 0 && (
            <TextInteractive variant="body2">
              <Link
                color="inherit"
                component="button"
                sx={{ ml: 0.5 }}
                underline="always"
                variant="body1"
                onClick={viewUsage}
              >
                ({t('Soap:BENEFIT_LIST_ITEM.VIEW_USAGE')})
              </Link>
            </TextInteractive>
          )}
        </Grid>
        <Collapse
          mountOnEnter
          unmountOnExit
          className={classes.collapse}
          in={isSelected}
        >
          {order.items?.map((item) => (
            <OrderListItem
              {...rest}
              classes={{
                listItemName: classNames({
                  [classes.listItemUsed]: item.charge,
                }),
              }}
              clientId={clientId}
              isCheckedItem={isCheckedItem}
              isDisabled={
                editDisabled || (disabledWeakMap && disabledWeakMap.get(item))
              }
              key={`${order.id}-${item.price?.id}-${item.id}`}
              order={{
                ...item,
                name:
                  item.charge && item.name
                    ? t('Soap:BENEFIT_LIST_ITEM.ORDER_ITEM_NAME_USED', {
                        itemName: item.name,
                      })
                    : item.name,
              }}
              patientId={patientId}
            />
          ))}
        </Collapse>
      </Grid>
    )
  },
)

export default BenefitListItem
