export const UPDATE_BUSINESSES = 'businesses/UPDATE_BUSINESSES'

export const FETCH_BUSINESSES_LIST = 'businesses/FETCH_BUSINESSES_LIST'
export const FETCH_BUSINESSES_LIST_SUCCESS =
  'businesses/FETCH_BUSINESSES_LIST_SUCCESS'
export const FETCH_BUSINESSES_LIST_FAILURE =
  'businesses/FETCH_BUSINESSES_LIST_FAILURE'

export const FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST =
  'businesses/FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST'
export const FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_SUCCESS =
  'businesses/FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_FAILURE =
  'businesses/FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_FAILURE'

export const CREATE_BUSINESS = 'businesses/CREATE_BUSINESS'
export const CREATE_BUSINESS_SUCCESS = 'businesses/CREATE_BUSINESS_SUCCESS'
export const CREATE_BUSINESS_FAILURE = 'businesses/CREATE_BUSINESS_FAILURE'

export const UPDATE_BUSINESS = 'businesses/UPDATE_BUSINESS'
export const UPDATE_BUSINESS_SUCCESS = 'businesses/UPDATE_BUSINESS_SUCCESS'
export const UPDATE_BUSINESS_FAILURE = 'businesses/UPDATE_BUSINESS_FAILURE'

export const FETCH_BUSINESS = 'businesses/FETCH_BUSINESS'
export const FETCH_BUSINESS_SUCCESS = 'businesses/FETCH_BUSINESS_SUCCESS'
export const FETCH_BUSINESS_FAILURE = 'businesses/FETCH_BUSINESS_FAILURE'

export const DELETE_BUSINESS_GROUP = 'businesses/DELETE_BUSINESS_GROUP'
export const DELETE_BUSINESS_GROUP_SUCCESS =
  'businesses/DELETE_BUSINESS_GROUP_SUCCESS'
export const DELETE_BUSINESS_GROUP_FAILURE =
  'businesses/DELETE_BUSINESS_GROUP_FAILURE'

export const FETCH_BUSINESS_GROUP_TAGS = 'businesses/FETCH_BUSINESS_GROUP_TAGS'
export const FETCH_BUSINESS_GROUP_TAGS_SUCCESS =
  'businesses/FETCH_BUSINESS_GROUP_TAGS_SUCCESS'
export const FETCH_BUSINESS_GROUP_TAGS_FAILURE =
  'businesses/FETCH_BUSINESS_GROUP_TAGS_FAILURE'

export const FETCH_ASSIGNED_PRACTICES = 'businesses/FETCH_ASSIGNED_PRACTICES'
export const FETCH_ASSIGNED_PRACTICES_SUCCESS =
  'businesses/FETCH_ASSIGNED_PRACTICES_SUCCESS'
export const FETCH_ASSIGNED_PRACTICES_FAILURE =
  'businesses/FETCH_ASSIGNED_PRACTICES_FAILURE'

export const CREATE_BUSINESS_LICENSE = 'businesses/CREATE_BUSINESS_LICENSE'
export const CREATE_BUSINESS_LICENSE_SUCCESS =
  'businesses/CREATE_BUSINESS_LICENSE_SUCCESS'
export const CREATE_BUSINESS_LICENSE_FAILURE =
  'businesses/CREATE_BUSINESS_LICENSE_FAILURE'

export const EDIT_BUSINESS_LICENSE = 'businesses/EDIT_BUSINESS_LICENSE'
export const EDIT_BUSINESS_LICENSE_SUCCESS =
  'businesses/EDIT_BUSINESS_LICENSE_SUCCESS'
export const EDIT_BUSINESS_LICENSE_FAILURE =
  'businesses/EDIT_BUSINESS_LICENSE_FAILURE'

export const DELETE_BUSINESS_LICENSE = 'businesses/DELETE_BUSINESS_LICENSE'
export const DELETE_BUSINESS_LICENSE_SUCCESS =
  'businesses/DELETE_BUSINESS_LICENSE_SUCCESS'
export const DELETE_BUSINESS_LICENSE_FAILURE =
  'businesses/DELETE_BUSINESS_LICENSE_FAILURE'

export const CLEAR_REMINDER_TEMPLATE = 'businesses/CLEAR_REMINDER_TEMPLATE'
export const CLEAR_REMINDER_TEMPLATE_ERROR =
  'businesses/CLEAR_REMINDER_TEMPLATE_ERROR'

export const UPDATE_LOCK_WORKING_HOURS_SETTINGS =
  'business/UPDATE_LOCK_WORKING_HOURS_SETTINGS'

export const FETCH_BUSINESS_BRANDING_CONFIGURATION =
  'businesses/FETCH_BUSINESS_BRANDING_CONFIGURATION'
export const FETCH_BUSINESS_BRANDING_CONFIGURATION_SUCCESS =
  'businesses/FETCH_BUSINESS_BRANDING_CONFIGURATION_SUCCESS'
export const FETCH_BUSINESS_BRANDING_CONFIGURATION_FAILURE =
  'businesses/FETCH_BUSINESS_BRANDING_CONFIGURATION_FAILURE'

export const EDIT_BUSINESS_BRANDING_CONFIGURATION =
  'businesses/EDIT_BUSINESS_BRANDING_CONFIGURATION'
export const EDIT_BUSINESS_BRANDING_CONFIGURATION_SUCCESS =
  'businesses/EDIT_BUSINESS_BRANDING_CONFIGURATION_SUCCESS'
export const EDIT_BUSINESS_BRANDING_CONFIGURATION_FAILURE =
  'businesses/EDIT_BUSINESS_BRANDING_CONFIGURATION_FAILURE'

export const UPDATE_FAVORITE_BUSINESS_LIST =
  'businesses/UPDATE_FAVORITE_BUSINESS_LIST'
export const REMOVE_FAVORITE_BUSINESS = 'businesses/REMOVE_FAVORITE_BUSINESS'
export const REMOVE_FAVORITE_BUSINESS_SUCCESS =
  'businesses/REMOVE_FAVORITE_BUSINESS_SUCCESS'
export const REMOVE_FAVORITE_BUSINESS_FAILURE =
  'businesses/REMOVE_FAVORITE_BUSINESS_FAILURE'
export const ADD_FAVORITE_BUSINESS = 'businesses/ADD_FAVORITE_BUSINESS'
export const ADD_FAVORITE_BUSINESS_SUCCESS =
  'businesses/ADD_FAVORITE_BUSINESS_SUCCESS'
export const ADD_FAVORITE_BUSINESS_FAILURE =
  'businesses/ADD_FAVORITE_BUSINESS_FAILURE'

export const FETCH_LOCATIONS_LIST = 'businesses/FETCH_LOCATIONS_LIST'
export const FETCH_LOCATIONS_LIST_SUCCESS =
  'businesses/FETCH_LOCATIONS_LIST_SUCCESS'
export const FETCH_LOCATIONS_LIST_FAILURE =
  'businesses/FETCH_LOCATIONS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST =
  'businesses/FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST'
export const FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_SUCCESS =
  'businesses/FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_FAILURE =
  'businesses/FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_FAILURE'

export const FETCH_SUGGESTIONS_LIST = 'businesses/FETCH_SUGGESTIONS_LIST'
export const FETCH_SUGGESTIONS_LIST_SUCCESS =
  'businesses/FETCH_SUGGESTIONS_LIST_SUCCESS'
export const FETCH_SUGGESTIONS_LIST_FAILURE =
  'businesses/FETCH_SUGGESTIONS_LIST_FAILURE'
export const FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST =
  'businesses/FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST'
export const FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_SUCCESS =
  'businesses/FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_FAILURE =
  'businesses/FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_FAILURE'

export const UPDATE_COUNTRY_CATALOG_CODE =
  'business/UPDATE_COUNTRY_CATALOG_CODE'
export const UPDATE_COUNTRY_CATALOG_CODE_SUCCESS =
  'business/UPDATE_COUNTRY_CATALOG_CODE_SUCCESS'
export const UPDATE_COUNTRY_CATALOG_CODE_FAILURE =
  'business/UPDATE_COUNTRY_CATALOG_CODE_FAILURE'

export const FETCH_BUSINESS_SOAP_WIDGETS =
  'business/FETCH_BUSINESS_SOAP_WIDGETS'
export const FETCH_BUSINESS_SOAP_WIDGETS_SUCCESS =
  'business/FETCH_BUSINESS_SOAP_WIDGETS_SUCCESS'
export const FETCH_BUSINESS_SOAP_WIDGETS_FAILURE =
  'business/FETCH_BUSINESS_WIDGETS_FAILURE'

export const FETCH_BUSINESS_SSO_IDP_SETTINGS =
  'business/FETCH_BUSINESS_SSO_IDP_SETTINGS'
export const FETCH_BUSINESS_SSO_IDP_SETTINGS_SUCCESS =
  'business/FETCH_BUSINESS_SSO_IDP_SETTINGS_SUCCESS'
export const FETCH_BUSINESS_SSO_IDP_SETTINGS_FAILURE =
  'business/FETCH_BUSINESS_SSO_IDP_SETTINGS_FAILURE'

export const CREATE_BUSINESS_SSO_IDP_SETTINGS =
  'business/CREATE_BUSINESS_SSO_IDP_SETTINGS'
export const CREATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS =
  'business/CREATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS'
export const CREATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE =
  'business/CREATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE'

export const UPDATE_BUSINESS_SSO_IDP_SETTINGS =
  'business/UPDATE_BUSINESS_SSO_IDP_SETTINGS'
export const UPDATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS =
  'business/UPDATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS'
export const UPDATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE =
  'business/UPDATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE'

export const DELETE_BUSINESS_SSO_IDP_SETTINGS =
  'business/DELETE_BUSINESS_SSO_IDP_SETTINGS'
export const DELETE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS =
  'business/DELETE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS'
export const DELETE_BUSINESS_SSO_IDP_SETTINGS_FAILURE =
  'business/DELETE_BUSINESS_SSO_IDP_SETTINGS_FAILURE'
