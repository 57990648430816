import * as R from 'ramda'
import {
  LanguageUtils,
  NumberUtils,
  UnitUtils,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  TemperatureTypes,
  WeightTypes,
} from '@pbt/pbt-ui-components/src/localization'

import { UnitsWithLabels } from '~/constants/units'

const FIELD_DIVIDER = '_id_'
const FIELD_PREFIX_VALUE = 'value_id_'
const FIELD_PREFIX_NOTES = 'notes_id_'

export const getVitalValueField = (id, fields) =>
  fields[`${FIELD_PREFIX_VALUE}${id}`]
export const getVitalNotesField = (id, fields) =>
  fields[`${FIELD_PREFIX_NOTES}${id}`]

export const getPoundsAndOunces = (decimalPounds = '') => {
  const [integer = 0, fraction = 0] = decimalPounds.toString().split('.')
  const ounces = Utils.round(Number(`0.${fraction}`) * 16, 2)

  return [Number(integer), ounces]
}

export const getDecimalPounds = (weight) => {
  const pounds = weight.base
  const ounces = weight.fraction
  return R.isNil(pounds)
    ? parseFloat(weight, 10)
    : parseFloat(pounds, 10) + (1 / 16) * parseFloat(ounces || 0, 10)
}

const formatUnits = (value) =>
  R.isNil(value)
    ? '-'
    : `${
        typeof value === 'number'
          ? NumberUtils.formatNumberWithDecimal(value)
          : value
      }`

const getFormattedConstants = (constants, optionsType) => {
  const currentConstant = constants[optionsType]

  return Array.isArray(currentConstant)
    ? currentConstant.map((item) =>
        item?.id ? item : { id: `${item}`, name: item },
      )
    : Object.values(currentConstant)
}

const getUnits = (optionsType, constants, options, value) => {
  const currentUnits = optionsType
    ? getFormattedConstants(constants, optionsType)
    : options
  const rawName = LanguageUtils.getConstantTranslatedName(
    value?.toString(),
    currentUnits,
    value?.toString(),
  )
  return LanguageUtils.getTranslatedNameByRawName(rawName, currentUnits)
}

export const getVitalDisplayValue = (
  value,
  configItem,
  constants,
  unitsState = {},
  format = true,
) => {
  const { options, optionsType } = configItem.formInput

  const needsConverting = !options && !optionsType
  const convertedUnits = needsConverting
    ? UnitUtils.convertUnits(configItem.id, value?.id ?? value, unitsState)
    : getUnits(optionsType, constants, options, value?.id ?? value)

  return format ? formatUnits(convertedUnits) : convertedUnits
}

export const getVitalPostfix = (unit, config) => {
  if (!unit) {
    return UnitsWithLabels.includes(config?.formInput?.units)
      ? config?.formInput?.units
      : ''
  }

  return unit.id
}

export const getVitalLabel = (value, config, constants, unit, noPostfix) => {
  const vitalValue = unit
    ? getVitalDisplayValue(value?.id || value, config, constants, {
        [config.id]: unit.id,
      })
    : getVitalDisplayValue(value?.id || value, config, constants)
  const unitsPostfix =
    !R.isNil(value) && !noPostfix && getVitalPostfix(unit, config)

  return [vitalValue, unitsPostfix].filter(Boolean).join('\u00A0')
}

export const serializeVitals = (vital, unitsState) => {
  if (vital.weight?.value) {
    const serializedWeight =
      unitsState.weight === WeightTypes.KG
        ? UnitUtils.kgToLbs(vital.weight.value.base || vital.weight.value)
        : getDecimalPounds(vital.weight.value)
    vital.weight.value = Utils.round(serializedWeight, 4)
  }

  if (vital.temperature && unitsState.temperature === TemperatureTypes.C) {
    vital.temperature.value = UnitUtils.celsiusToFahrenheit(
      vital.temperature.value,
    )
  }

  return vital
}

const isValidLength = (str, intLength = 4, fractionLength = 4) => {
  const [integerPart, fractionPart] = str.split('.')
  return (
    (!integerPart || integerPart.length <= intLength) &&
    (!fractionPart || fractionPart.length <= fractionLength)
  )
}

const numericValueTester = /^\d+?\.?\d*$/
export const handleUnitValueChange = (onChange, str) => {
  const isAcceptable = numericValueTester.test(str)

  onChange(isAcceptable && isValidLength(str) ? str : '')
}

export const getTableData = ({
  config,
  vitals,
  unitsState,
  constantMap,
  withUnits = true,
}) =>
  config.reduce(
    (acc, configItem) => ({
      ...acc,
      [configItem.id]: vitals.map((item) => {
        const vital = item[configItem.id]
        const unitId = unitsState[configItem.id]

        if (withUnits) {
          const vitalValue = getVitalLabel(
            vital?.value?.id || vital?.value,
            configItem,
            constantMap,
            { id: unitId },
          )

          return {
            value: vitalValue,
            notes: vital?.notes,
            finalized: item?.finalized,
          }
        }

        return {
          value: getVitalDisplayValue(
            vital?.value?.id || vital?.value,
            configItem,
            constantMap,
            unitsState,
          ),
          notes: vital?.notes,
          finalized: item?.finalized,
        }
      }),
    }),
    {},
  )

export const fieldNameToVitalId = (name) => name.split(FIELD_DIVIDER)[1]

export const getVitalsState = (fields, soapId, unitsState) => {
  const valueFields = Object.keys(fields)
    .map((key) =>
      fields[key].value || fields[key].value !== fields[key].initialValue
        ? fields[key]
        : undefined,
    )
    .filter(Boolean)

  const initialVital = soapId ? { soapId } : {}

  const vital = valueFields.reduce((acc, field) => {
    const vitalName = fieldNameToVitalId(field.name)
    return {
      ...acc,
      [vitalName]: {
        value: getVitalValueField(vitalName, fields).value,
        notes: getVitalNotesField(vitalName, fields).value,
      },
    }
  }, initialVital)

  return serializeVitals(vital, unitsState)
}

export const getVitalsFields = (unitsState, vitalsConfig, vitals) =>
  R.pipe(
    R.map(({ id }) => [
      {
        name: `${FIELD_PREFIX_VALUE}${id}`,
        initialValue: vitals
          ? UnitUtils.convertUnits(id, vitals[id]?.value, unitsState) || ''
          : '',
      },
      {
        name: `${FIELD_PREFIX_NOTES}${id}`,
        initialValue: vitals ? vitals[id]?.notes || '' : '',
      },
    ]),
    R.flatten,
  )(vitalsConfig)

export const hasUnsavedVitalsByValue = R.pipe(R.values, R.any(R.prop('value')))
