import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import * as R from 'ramda'
import {
  Constant,
  Field,
  PuiSelect,
  PuiTextField,
} from '@pbt/pbt-ui-components'

import { getTimeUnits } from '~/store/reducers/constants'
import { isOtherUnitSelected } from '~/utils'
import { getSingularOrPluralTimeUnits } from '~/utils/time'

const UnitsItems = ['Day', 'Week', 'Month', 'Year']

export interface ManualInputTimeDurationSelectProps {
  disabled?: boolean
  fields: {
    customUnit: Field
    customUnitId: Field
    unitId: Field
  }
  label: string
  options?: Constant[]
  otherGridWidth?: number
  otherLabel?: string
  unitGridWidth?: number
}

const ManualInputTimeDurationSelect = ({
  disabled,
  fields: { customUnit, customUnitId, unitId },
  label,
  options: optionsProp,
  otherGridWidth = 3,
  otherLabel: otherLabelProp,
  unitGridWidth = 3,
}: ManualInputTimeDurationSelectProps) => {
  const options = optionsProp || []
  const isOtherUnit = isOtherUnitSelected(unitId, options)
  const { t } = useTranslation(['Admin', 'Common'])

  const TimeUnits: Constant[] = useSelector(getTimeUnits)

  const filteredTimeUnits = TimeUnits.filter(({ name }) =>
    UnitsItems.includes(name),
  )
  const TimeUnitsSubset = getSingularOrPluralTimeUnits(filteredTimeUnits)

  useEffect(() => {
    if (TimeUnitsSubset.length && !customUnitId.value) {
      customUnitId.setValue(R.head(TimeUnitsSubset)?.id)
    }
  }, [TimeUnitsSubset, customUnitId])

  const otherLabel =
    otherLabelProp || `${t('Common:OTHER')} ${label.toLowerCase()}`

  return (
    <>
      <Grid item xs={unitGridWidth}>
        <FormControl fullWidth margin="none">
          <InputLabel>{label}</InputLabel>
          <PuiSelect disabled={disabled} field={unitId} items={options} />
        </FormControl>
      </Grid>
      {isOtherUnit && (
        <Grid
          container
          item
          alignItems="flex-end"
          spacing={3}
          xs={otherGridWidth}
        >
          <Grid item xs={7}>
            <PuiTextField
              disabled={disabled}
              field={customUnit}
              inputProps={{ maxLength: 100 }}
              label={otherLabel}
              margin="none"
            />
          </Grid>
          <Grid item xs={5}>
            <PuiSelect
              fullWidth
              disabled={disabled}
              field={customUnitId}
              items={TimeUnitsSubset}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ManualInputTimeDurationSelect
