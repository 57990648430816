import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearAllActiveRx,
  fetchAllActiveRxForSoap,
  getIsFetchingAllActiveRxForSoap,
  getSoapPendingChewyActiveRxList,
} from '~/store/duck/prescriptions'
import { getSoapId } from '~/store/reducers/soap'

import { FinalizeChewyActiveRxSection } from './FinalizeChewyActiveRxSection'

export const FinalizeSoapChewyActiveRx = () => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const soapActiveRxList = useSelector(getSoapPendingChewyActiveRxList)
  const isFetchingAllActiveRxForSoap = useSelector(
    getIsFetchingAllActiveRxForSoap,
  )

  useEffect(() => {
    if (soapId) {
      dispatch(fetchAllActiveRxForSoap({ soapId }))
    }

    return () => {
      dispatch(clearAllActiveRx())
    }
  }, [soapId])

  return (
    <FinalizeChewyActiveRxSection
      context="SOAP"
      data={soapActiveRxList}
      isLoading={isFetchingAllActiveRxForSoap}
      soapId={soapId}
    />
  )
}
