import * as R from 'ramda'
import { all, put, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Business, Space, SpaceMonitor } from '~/api/graphql/generated/types'

import {
  fetchAssignedToSpaceMonitors,
  fetchAssignedToSpaceMonitorsFailure,
  fetchAssignedToSpaceMonitorsSuccess,
  fetchUnassignedMonitors,
  fetchUnassignedMonitorsFailure,
  fetchUnassignedMonitorsSuccess,
  getInRoomSpaceMonitor,
  getInRoomSpaceMonitorFailure,
  getInRoomSpaceMonitorSuccess,
  updateMonitorsAssignments,
  updateMonitorsAssignmentsFailure,
  updateMonitorsAssignmentsSuccess,
} from '../duck/monitor'
import requestAPI from './utils/requestAPI'

export function* fetchUnassignedMonitorsSaga() {
  try {
    const business: Business = yield requestAPI(API.fetchUnassignedMonitors)
    yield put(
      fetchUnassignedMonitorsSuccess(
        R.propOr([], 'unassignedDevices', business),
      ),
    )
  } catch (error) {
    yield put(fetchUnassignedMonitorsFailure({ error: error as ApiError }))
  }
}

export function* fetchAssignedToSpaceMonitorsSaga({
  payload,
}: ReturnType<typeof fetchAssignedToSpaceMonitors>) {
  try {
    const space: Space = yield requestAPI(
      API.fetchAssignedToSpaceMonitors,
      payload,
    )
    yield put(
      fetchAssignedToSpaceMonitorsSuccess(
        R.propOr([], 'assignedDevices', space),
      ),
    )
  } catch (error) {
    yield put(fetchAssignedToSpaceMonitorsFailure({ error: error as ApiError }))
  }
}

export function* updateMonitorsAssignmentsSaga({
  payload,
}: ReturnType<typeof updateMonitorsAssignments>) {
  try {
    const monitors: SpaceMonitor[] = yield requestAPI(
      API.updateMonitorsAssignments,
      payload,
    )
    yield put(updateMonitorsAssignmentsSuccess(monitors))
  } catch (error) {
    yield put(updateMonitorsAssignmentsFailure({ error: error as ApiError }))
  }
}

export function* configureInRoomMonitorSaga({
  payload,
}: ReturnType<typeof getInRoomSpaceMonitor>) {
  try {
    const monitor: SpaceMonitor = yield requestAPI(
      API.getInRoomSpaceMonitor,
      payload.spaceId,
    )
    yield put(getInRoomSpaceMonitorSuccess(monitor))
  } catch (error) {
    yield put(getInRoomSpaceMonitorFailure({ error: error as ApiError }))
  }
}

function* watchUnassignedMonitors() {
  yield takeLeading(fetchUnassignedMonitors.type, fetchUnassignedMonitorsSaga)
}

function* watchAssignedToSpaceMonitors() {
  yield takeLeading(
    fetchAssignedToSpaceMonitors.type,
    fetchAssignedToSpaceMonitorsSaga,
  )
}

function* watchUpdateMonitorsAssignment() {
  yield takeLeading(
    updateMonitorsAssignments.type,
    updateMonitorsAssignmentsSaga,
  )
}

function* watchConfigureInRoomMonitor() {
  yield takeLeading(getInRoomSpaceMonitor.type, configureInRoomMonitorSaga)
}

export default function* monitorsSaga() {
  yield all([
    watchUnassignedMonitors(),
    watchAssignedToSpaceMonitors(),
    watchUpdateMonitorsAssignment(),
    watchConfigureInRoomMonitor(),
  ])
}
