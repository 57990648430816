export const UPDATE_CONVERSATION_MESSAGES =
  'conversationMessages/UPDATE_CONVERSATION_MESSAGES'
export const UPDATE_CONVERSATION_FILES =
  'conversationMessages/UPDATE_CONVERSATION_FILES'

export const SEND_CONVERSATION_MESSAGE =
  'conversationMessages/SEND_CONVERSATION_MESSAGE'
export const SEND_CONVERSATION_DRAFT_MESSAGE =
  'conversationMessages/SEND_CONVERSATION_DRAFT_MESSAGE'
export const SEND_CONVERSATION_MESSAGE_SUCCESS =
  'conversationMessages/SEND_CONVERSATION_MESSAGE_SUCCESS'
export const SEND_CONVERSATION_MESSAGE_FAILURE =
  'conversationMessages/SEND_CONVERSATION_MESSAGE_FAILURE'
export const SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE =
  'conversationMessages/SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE'

export const EDIT_MESSAGE = 'conversationMessages/EDIT_MESSAGE'

export const CLOSE_MESSAGE_FORMATTING_AREA =
  'conversationMessages/CLOSE_MESSAGE_FORMATTING_AREA'
export const MESSAGE_FORMATTING_AREA_FOCUSED =
  'conversationMessages/MESSAGE_FORMATTING_AREA_FOCUSED'

export const UPDATE_MESSAGE = 'conversationMessages/UPDATE_MESSAGE'
export const UPDATE_MESSAGE_SUCCESS =
  'conversationMessages/UPDATE_MESSAGE_SUCCESS'
export const UPDATE_MESSAGE_FAILURE =
  'conversationMessages/UPDATE_MESSAGE_FAILURE'

export const DELETE_MESSAGE = 'conversationMessages/DELETE_MESSAGE'
export const DELETE_MESSAGE_SUCCESS =
  'conversationMessages/DELETE_MESSAGE_SUCCESS'
export const DELETE_MESSAGE_FAILURE =
  'conversationMessages/DELETE_MESSAGE_FAILURE'

export const FETCH_ALL_CONVERSATION_FILES =
  'conversationMessages/FETCH_ALL_CONVERSATION_FILES'
export const FETCH_ALL_CONVERSATION_FILES_SUCCESS =
  'conversationMessages/FETCH_ALL_CONVERSATION_FILES_SUCCESS'
export const FETCH_ALL_CONVERSATION_FILES_FAILURE =
  'conversationMessages/FETCH_ALL_FILES_FAILURE'

export const UPLOAD_FILE = 'conversationMessages/UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'conversationMessages/UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'conversationMessages/UPLOAD_FILE_FAILURE'

export const UPLOAD_FILE_LIST = 'conversationMessages/UPLOAD_FILE_LIST'
export const UPLOAD_FILE_LIST_SUCCESS =
  'conversationMessages/UPLOAD_FILE_LIST_SUCCESS'
export const UPLOAD_FILE_LIST_FAILURE =
  'conversationMessages/UPLOAD_FILE_LIST_FAILURE'

export const ATTACH_DOCUMENTS_TO_MESSAGE =
  'conversationMessages/ATTACH_DOCUMENTS_TO_MESSAGE'
export const ATTACH_DOCUMENTS_TO_MESSAGE_FAILURE =
  'conversationMessages/ATTACH_DOCUMENTS_TO_MESSAGE_FAILURE'

export const WAIT_FOR_ATTACHED_DOCUMENT =
  'conversationMessages/WAIT_FOR_ATTACHED_DOCUMENT'

export const CLEAR_FILES_TO_SEND = 'conversationMessages/CLEAR_FILES_TO_SEND'
export const CLEAR_FILE_TO_SEND = 'conversationMessages/CLEAR_FILE_TO_SEND'
export const CLEAR_MESSAGE_FILES = 'conversationMessages/CLEAR_MESSAGE_FILES'

export const DELETE_FILE = 'conversationMessages/DELETE_FILE'
export const DELETE_FILE_SUCCESS = 'conversationMessages/DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'conversationMessages/DELETE_FILE_FAILURE'

export const SEND_GENERATED_MESSAGE =
  'conversationMessages/SEND_GENERATED_MESSAGE'
export const SEND_GENERATED_MESSAGE_SUCCESS =
  'conversationMessages/SEND_GENERATED_MESSAGE_SUCCESS'
export const SEND_GENERATED_MESSAGE_FAILURE =
  'conversationMessages/SEND_GENERATED_MESSAGE_FAILURE'
