import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AlertIconType,
  Nil,
  Patient,
  PuiDialog,
  PuiDialogProps,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import ClientMobileAppChip from '~/components/common/ClientMobileAppChip'
import ClientWarningChip from '~/components/common/ClientWarningChip'
import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ClientInfoLabel from '~/components/common/labels/ClientInfoLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import PatientWarningChip from '~/components/common/PatientWarningChip'
import DialogNames from '~/constants/DialogNames'
import InvoiceType, { InvoiceTypeDisplayName } from '~/constants/InvoiceType'
import { OrderType } from '~/constants/SOAPStates'
import {
  clearCurrentInvoiceData,
  fetchEstimateTemplate,
  fetchInvoice,
  fetchInvoiceByEventId,
  fetchInvoiceTemplate,
} from '~/store/actions/finance'
import { refreshOrders } from '~/store/actions/orders'
import { fetchModality } from '~/store/duck/imagingOrders'
import {
  useGetInvoiceLogsByLogType,
  useHasUnfinishedLabs,
} from '~/store/hooks/finance'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getFinanceInvoice,
  getFinanceInvoiceByEventId,
  getFinanceIsFetching,
  getFinanceIsSaving,
  getFinanceTemplate,
} from '~/store/reducers/finance'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { InvoiceOrEstimate, Prescription } from '~/types'
import useDialog from '~/utils/useDialog'

import { PatientPreferencesChip } from '../clients/patient/PatientPreferencesChip'
import { ClientPreferencesChip } from '../clients/preferences/ClientPreferencesChip'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '../link-chewy-account/LinkedChewyAccountContainer'
import WellnessPlanMemberButton from '../wellness-plans/WellnessPlanMemberButton'
import BatchInvoice, { BatchInvoiceProps } from './batch/BatchInvoice'
import Invoice, { InvoiceProps } from './Invoice'
import { getIsBatchInvoice } from './invoiceUtils'
import PatientSelector from './PatientSelector'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.contentBackground,
    },
    paper: {
      width: 'calc(100% - 16px)',
      maxWidth: '100%',
      margin: theme.spacing(1),
    },
    selectorPaper: {
      width: 374,
      maxWidth: 374,
    },
    header: {
      padding: theme.spacing(3, 3, 1),
    },
    headerText: {
      fontSize: '2rem',
    },
    confirmationMessage: {
      whiteSpace: 'pre-wrap',
    },
    chip: {
      width: 16,
      height: 16,
      margin: 1,
    },
    wellnessChip: {
      width: 16,
      height: 16,
    },
  }),
  { name: 'InvoiceDialog' },
)

export interface InvoiceHandle {
  hasUnsavedChanges: () => boolean
  save: () => void
}

export interface InvoiceDialogProps extends PuiDialogProps {
  clientId?: string | Nil
  create?: boolean
  eventId?: string | Nil
  fromTimeline?: boolean
  invoiceId?: string | Nil
  isEstimate?: boolean
  isOtcInvoice?: boolean
  newEstimateFlow?: boolean
  patientId?: string | Nil
  prescriptionId?: string
  refillItem?: Prescription
  setAttachingToSoapEstimateId?: (attachingToSoapEstimateId: string) => void
  setSoapToAttachEstimateId?: (soapToAttachEstimateId: string) => void
  shouldCloseEstimateAfterSaving?: boolean
  soapBusinessId?: string | Nil
  soapId?: string | Nil
  soapToRedirect?: string | Nil
}

const InvoiceDialog = ({
  isEstimate: isEstimateProp,
  isOtcInvoice,
  eventId,
  invoiceId,
  open,
  onClose,
  clientId,
  patientId,
  soapBusinessId,
  soapId,
  create,
  prescriptionId,
  refillItem,
  fromTimeline,
  newEstimateFlow,
  setAttachingToSoapEstimateId,
  setSoapToAttachEstimateId,
  ...rest
}: InvoiceDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const template = useSelector(getFinanceTemplate)
  const isFetching = useSelector(getFinanceIsFetching)
  const isSaving = useSelector(getFinanceIsSaving)
  const clientFromStore = useSelector(getUser(clientId))
  const patientFromStore = useSelector(getPatient(patientId))
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const UNFINISHED_LABS_MESSAGE = [
    t('Dialogs:INVOICE_DIALOG.UNFINISHED_LABS_MESSAGE_01'),
    t('Dialogs:INVOICE_DIALOG.UNFINISHED_LABS_MESSAGE_02'),
  ].join('\n\n')

  const fetchByEventId = !create && eventId && open && !invoiceId && !isFetching
  const invoiceFromState = useSelector(
    fetchByEventId
      ? getFinanceInvoiceByEventId(eventId, clientId)
      : getFinanceInvoice(invoiceId || template?.id),
  )
  const invoicePatient = useSelector(getPatient(invoiceFromState?.patientId))
  const invoiceClient = useSelector(getUser(invoiceFromState?.clientId))

  const [selectedPatient, setSelectedPatient] = useState(patientFromStore)
  const [isEstimate, setIsEstimate] = useState(Boolean(isEstimateProp))
  const [openAddItem, setOpenAddItem] = useState(!isEstimate && create)

  const isFirstRun = useRef(true)

  const invoiceRef = useRef<InvoiceHandle>(null)

  const invoice = invoiceFromState || template || ({} as InvoiceOrEstimate)
  const showPatientSelector = create && !selectedPatient
  const invoiceNo = invoice.invoiceNo ? ` ${invoice.invoiceNo}` : ''
  const invoiceType = isEstimate
    ? InvoiceTypeDisplayName[InvoiceType.ESTIMATE]
    : invoice.type
      ? InvoiceTypeDisplayName[invoice.type as InvoiceType] || invoice.type
      : InvoiceTypeDisplayName[InvoiceType.INVOICE]

  const patient = invoicePatient || patientFromStore
  const client = invoiceClient || clientFromStore
  const patientName = patient?.name
  const clientName = Utils.getPersonString(client)

  const labLogs = useGetInvoiceLogsByLogType(OrderType.LAB_TEST, invoice)
  const hasUnfinishedLabs = useHasUnfinishedLabs(labLogs)
  const isPosted = invoice.posted

  const [openAddInvoiceItemDialog] = useDialog(
    DialogNames.ADD_INVOICE_ITEM,
    null,
    {
      invoice,
      isEstimate,
      isOtcInvoice,
    },
  )
  const [openConfirmActionDialog, closeConfirmActionDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  useEffect(() => {
    if (invoiceFromState) {
      setIsEstimate(
        // TODO this should be returned back to invoiceFromState.type === InvoiceType.ESTIMATE once graphQL invoice model will be mapped to portal one
        invoiceFromState.type === InvoiceType.ESTIMATE ||
          R.prop('invoiceType', invoiceFromState) ===
            InvoiceType.ESTIMATE.toUpperCase(),
      )
    }
  }, [invoiceFromState?.type])

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchModality(currentBusinessId))
    }
  }, [currentBusinessId])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return undefined
    }
    if (!open) {
      const timeout = setTimeout(() => {
        setSelectedPatient(undefined)
      }, 200)

      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }

    return undefined
  }, [open])

  const hasOrderFilters = Boolean(invoice.orderFilters)

  useEffect(() => {
    if (!isEstimate && !refillItem && create && hasOrderFilters) {
      openAddInvoiceItemDialog({
        invoice,
        isEstimate,
        isOtcInvoice,
      })
    }
  }, [create, isEstimate, hasOrderFilters])

  useEffect(() => {
    if (open && create && selectedPatient) {
      if (isEstimate) {
        dispatch(fetchEstimateTemplate(clientId, selectedPatient.id))
        return
      }
      dispatch(fetchInvoiceTemplate(clientId, selectedPatient.id, eventId))
    }
  }, [open, selectedPatient, isEstimate])

  const refetchInvoice = () => {
    if (fetchByEventId) {
      dispatch(fetchInvoiceByEventId(clientId, eventId))
    } else if (!create && invoiceId && open && !isFetching) {
      dispatch(fetchInvoice(invoiceId))
    }
  }

  useEffect(() => {
    refetchInvoice()
  }, [open, eventId, invoiceId])

  const handleClose = (redirectTo: string) => {
    setOpenAddItem(false)
    dispatch(clearCurrentInvoiceData())
    dispatch(refreshOrders())
    onClose?.(redirectTo)
  }

  const attemptClose = (redirectTo: string) => {
    if (!isEstimate && hasUnfinishedLabs && !isPosted) {
      openConfirmActionDialog({
        cancelButtonText: t('Common:LEAVE_ANYWAY_ACTION'),
        classes: {
          message: classes.confirmationMessage,
        },
        iconType: AlertIconType.WARN,
        message: UNFINISHED_LABS_MESSAGE,
        okButtonText: t('Dialogs:INVOICE_DIALOG.OK_BUTTON_TEXT'),
        onCancel: () => {
          closeConfirmActionDialog()
          handleClose(redirectTo)
        },
        onClose: closeConfirmActionDialog,
        onOk: closeConfirmActionDialog,
      })
    } else {
      handleClose(redirectTo)
    }
  }

  const onPatientSelected = (newPatient: Patient) => {
    setSelectedPatient(newPatient)
    setOpenAddItem(true)
  }

  const isBatchInvoice = getIsBatchInvoice(invoice)
  const invoiceProps = {
    clientId,
    create,
    eventId,
    fromTimeline,
    hideControls: invoiceId && !invoiceFromState,
    invoice,
    isEstimate,
    isNew:
      !invoiceId && (!isOtcInvoice || !invoice.id) && Boolean(selectedPatient),
    isOtcInvoice,
    onClose,
    openAddItem,
    patient: patientFromStore,
    prescriptionLineItemId: prescriptionId,
    ref: invoiceRef,
    refetchInvoice,
    refillItem,
    soapBusinessId,
    soapId,
    onOk: attemptClose,
    invoiceIds: isBatchInvoice ? invoice.invoices : [invoice.id],
    id: invoiceFromState?.id,
    newEstimateFlow,
    setAttachingToSoapEstimateId,
    setSoapToAttachEstimateId,
  } as unknown as BatchInvoiceProps | InvoiceProps

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => invoiceRef.current?.save(),
      }}
      aria-labelledby="invoice-dialog"
      classes={{
        paper: classNames(classes.paper, {
          [classes.selectorPaper]: showPatientSelector,
        }),
      }}
      disableClose={isSaving}
      hasUnsavedChanges={() => Boolean(invoiceRef.current?.hasUnsavedChanges())}
      open={open}
      onClose={attemptClose}
      {...rest}
    >
      <Grid container className={classes.container} direction="column">
        <Grid container item className={classes.header} direction="column">
          {(!invoiceId || invoiceFromState) && (
            <Text inline variant="h2">
              {showPatientSelector
                ? t('Dialogs:INVOICE_DIALOG.TITLE')
                : `${invoiceType}${invoiceNo}: ${[clientName, patientName].join(
                    ' | ',
                  )}`}
            </Text>
          )}
          <Grid container item columnSpacing={3} ml={-3} mt={1} wrap="nowrap">
            <Grid
              container
              item
              alignItems="center"
              width="max-content"
              wrap="nowrap"
            >
              <Grid container item alignItems="center" width="max-content">
                <ClientWarningChip
                  className={classes.chip}
                  clientId={clientId}
                />
                <ClientPreferencesChip
                  className={classes.chip}
                  clientId={clientId}
                />
                <LinkedChewyAccountContainer
                  accountType={KyriosAccountType.CLIENT}
                  className={classes.chip}
                  clientId={clientId}
                />
                <ClientMobileAppChip
                  className={classes.chip}
                  clientId={clientId}
                />
                <BusinessShareIcon
                  noMargin
                  businessIds={client?.clientInContextBusinesses}
                  className={classes.chip}
                />
                <Text strong mx={1} variant="subheading3">
                  {clientName}
                </Text>
              </Grid>
              <Grid item width="max-content">
                <ClientInfoLabel clientId={client?.id} variant="body2" />
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              columnSpacing={1}
              wrap="nowrap"
            >
              <Grid container item alignItems="center">
                <PatientWarningChip
                  className={classes.chip}
                  patientId={patientId}
                />
                <PatientPreferencesChip
                  className={classes.chip}
                  patientId={patientId}
                />
                <LinkedChewyAccountContainer
                  accountType={KyriosAccountType.PATIENT}
                  className={classes.chip}
                  clientId={clientId}
                  patientId={patientId}
                />
                <WellnessPlanMemberButton
                  chip
                  readonly
                  buttonClassName={classes.chip}
                  className={classes.wellnessChip}
                  clientId={clientId}
                  patientId={patientId}
                />
                <Text strong mx={1} variant="subheading3">
                  {patientName}
                </Text>
                <PatientInfoLabel patientId={patient?.id} variant="body2" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showPatientSelector ? (
          <PatientSelector clientId={clientId} onSelected={onPatientSelected} />
        ) : isBatchInvoice ? (
          <BatchInvoice {...(invoiceProps as BatchInvoiceProps)} />
        ) : (
          <Invoice {...(invoiceProps as InvoiceProps)} />
        )}
      </Grid>
    </PuiDialog>
  )
}

export default InvoiceDialog
