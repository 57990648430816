import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton, PermissionArea } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { fetchEmailData, linkChewyUser } from '~/store/duck/chewyPets'
import { getCRUDByArea } from '~/store/reducers/auth'
import useDialog from '~/utils/useDialog'

interface ConnectChewyAccountProps {
  className?: string
  clientId: string
  onOpenDialog?: () => void
  tooltipText: string
}

const ConnectChewyAccount = ({
  clientId,
  tooltipText,
  className,
  onOpenDialog,
}: ConnectChewyAccountProps) => {
  const [openDialog, closeDialog] = useDialog(DialogNames.LINK_CHEWY_ACCOUNT)

  const dispatch = useDispatch()

  const { update: connectChewyAccountUpdatePermission } = useSelector(
    getCRUDByArea(PermissionArea.CONNECT_CHEWY_ACCOUNT),
  )

  const handleSubmit = (emailToSave: string) => {
    dispatch(linkChewyUser({ clientId, email: emailToSave }))

    closeDialog()
  }

  const handleOpenDialog = () => {
    onOpenDialog?.()
    dispatch(fetchEmailData({ clientId }))
    openDialog({ onSubmit: handleSubmit })
  }

  return (
    <LinkButton
      className={className}
      disabled={!connectChewyAccountUpdatePermission}
      onClick={
        connectChewyAccountUpdatePermission ? handleOpenDialog : undefined
      }
    >
      {tooltipText}
    </LinkButton>
  )
}

export default ConnectChewyAccount
