import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { Defaults, Text, useInterval } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { PuiPagination } from '~/components/common/lists/table/PuiPagination'
import { PaymentBillingActivityTable } from '~/components/dashboard/clients/balance/table/payments/PaymentBillingActivityTable'
import {
  fetchPaymentPage,
  getClientBillingActivityPaymentError,
  getIsPollingPayments,
  getPaymentLoading,
  getPaymentPageData,
  getPaymentTotalCount,
  getShouldRefreshData,
} from '~/store/duck/clientBillingActivityData'
import { getPaymentsIsSaving } from '~/store/reducers/payments'

interface PaymentsWidgetProps {
  isAddClientPaymentDialogOpened: boolean
  isExpanded: boolean
  onClickEstimate: (data: GraphqlEstimate) => void
  onExpand: () => void
}

const itemsPerPage = 4

export const PaymentsWidget = ({
  isAddClientPaymentDialogOpened,
  isExpanded,
  onClickEstimate,
  onExpand,
}: PaymentsWidgetProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const { clientId } = useParams()

  const refresh = useSelector(getShouldRefreshData)
  const paymentPageData = useSelector(getPaymentPageData)
  const paymentTotalCount = useSelector(getPaymentTotalCount)
  const isFetchingPayment = useSelector(getPaymentLoading)
  const isSaving = useSelector(getPaymentsIsSaving)
  const isPolling = useSelector(getIsPollingPayments)
  const error = useSelector(getClientBillingActivityPaymentError)

  const [offsetValue, setOffsetValue] = useState(0)

  const isLoading = isPolling ? false : isFetchingPayment || isSaving
  const shouldFetchInitialData = Boolean(
    clientId && !isAddClientPaymentDialogOpened,
  )

  useEffect(() => {
    if (shouldFetchInitialData) {
      dispatch(
        fetchPaymentPage({
          clientId: clientId!,
          offset: 0,
          limit: itemsPerPage,
        }),
      )
    }
  }, [shouldFetchInitialData])

  useEffect(() => {
    if (clientId && refresh) {
      dispatch(
        fetchPaymentPage({
          clientId,
          offset: offsetValue,
          limit: itemsPerPage,
          isRefreshing: true,
        }),
      )
    }
  }, [clientId, refresh])

  const handleFetchMore = (offset: number) => {
    if (clientId) {
      setOffsetValue(offset)
      dispatch(fetchPaymentPage({ clientId, offset, limit: itemsPerPage }))
    }
  }

  useInterval(() => {
    if (clientId) {
      dispatch(
        fetchPaymentPage({
          clientId: clientId!,
          offset: offsetValue,
          limit: itemsPerPage,
          isPolling: true,
        }),
      )
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  if (error) {
    return (
      <Text px={2} py={1} variant="body2">
        {error}
      </Text>
    )
  }

  if (!isLoading && R.isEmpty(paymentPageData)) {
    return (
      <Text px={2} py={1} variant="body2">
        {t('Common:NO_PAYMENTS')}
      </Text>
    )
  }

  return (
    <>
      <PaymentBillingActivityTable
        isLoading={isLoading}
        payments={paymentPageData}
        paymentsPerPage={itemsPerPage}
        onEstimateNameClick={onClickEstimate}
        onExpand={onExpand}
      />
      {!isExpanded && (
        <Box display="flex" justifyContent="flex-end" p={1}>
          <PuiPagination
            itemsPerPage={itemsPerPage}
            reset={shouldFetchInitialData}
            totalItems={paymentTotalCount}
            onFetchMore={handleFetchMore}
          />
        </Box>
      )}
    </>
  )
}
