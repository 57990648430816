import React from 'react'
import { Text } from '@pbt/pbt-ui-components'

interface TableTextCellProps {
  value: string
}

export const TableTextCell = ({ value }: TableTextCellProps) => {
  if (!value) {
    return null
  }

  return <Text variant="body2">{value}</Text>
}
