import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

import { ReminderProtocol } from '~/types'

import ReminderProtocolSection from './ReminderProtocolSection'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    header: {
      border: theme.constants.tableBorder,
      borderBottomWidth: 0,
    },
    nameHeader: {
      borderRight: theme.constants.tableBorder,
      minWidth: 212,
      maxWidth: 212,
    },
  }),
  { name: 'ReminderProtocolTable' },
)

export interface ReminderProtocolTableProps {
  classes?: ClassesType<typeof useStyles>
  onClone: (protocol: ReminderProtocol, index: number) => void
  onDelete: (protocol: ReminderProtocol, index: number) => void
  onEdit: (protocol: ReminderProtocol, index: number) => void
  protocols: ReminderProtocol[]
}

const ReminderProtocolTable = ({
  classes: classesProp,
  protocols,
  onEdit,
  onClone,
  onDelete,
}: ReminderProtocolTableProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Reminders'])

  return (
    <Grid container className={classes.root} direction="column">
      <Grid container className={classes.header} wrap="nowrap">
        <Grid item xs className={classes.nameHeader} p={1}>
          <Text strong variant="lowAccent2">
            {t('Common:NAME')}
          </Text>
        </Grid>
        <Grid container item xs>
          <Grid item p={1} xs={3}>
            <Text strong variant="lowAccent2">
              {t('Reminders:LABEL.DUE_DATE')}
            </Text>
          </Grid>
          <Grid item p={1} xs={2}>
            <Text strong variant="lowAccent2">
              {t('Common:SPECIES')}
            </Text>
          </Grid>
          <Grid item p={1} xs={2}>
            <Text strong variant="lowAccent2">
              {t('Common:GENDER')}
            </Text>
          </Grid>
          <Grid item p={1} xs={3}>
            <Text strong variant="lowAccent2">
              {t('Common:AGE')}
            </Text>
          </Grid>
          <Grid item p={1} xs={2}>
            <Text strong variant="lowAccent2">
              {t('Common:INPUTS.WEIGHT_RANGE_INPUT.WEIGHT_RANGE')}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {protocols.map((protocol, index) => (
          <ReminderProtocolSection
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            protocol={protocol}
            onClone={() => onClone(protocol, index)}
            onDelete={() => onDelete(protocol, index)}
            onEdit={() => onEdit(protocol, index)}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default ReminderProtocolTable
