import { useSelector } from 'react-redux'
import { Constant, moment, Nil, Utils } from '@pbt/pbt-ui-components'

import { getTimeUnits } from '~/store/reducers/constants'

import { isOtherUnitSelected } from './index'

const useManualTimeDurationValue = (
  optionId: string | Nil,
  unitId: string | Nil,
  unit: string | Nil,
  Constants: Constant[],
  unitPrefix: string,
) => {
  const TimeUnits = useSelector(getTimeUnits)

  const isOtherSelected = isOtherUnitSelected({ value: optionId }, Constants)
  // Get unit and unitId from Constants to use for calculations. We're using unitPrefix bcs of different
  // name of Constants keys in different Constants
  const {
    [unitPrefix]: constantUnit,
    [`${unitPrefix}UnitId`]: constantUnitId,
  } = Utils.findById(optionId, Constants) || {}
  // Get time unit name for use it with moment.js as time period for adding
  // if other was selected - we're using unitId that was selected by user and saved in ${unitPrefix}UnitId field
  // in other case we're getting time unit name from Constants
  const timeUnitName = Utils.getConstantName(
    isOtherSelected ? unitId : constantUnitId,
    TimeUnits,
  )

  // Set amount from unit field, that was set by user, in case if other selected
  // in other case setting amount from Constants
  const amount = isOtherSelected ? unit : constantUnit

  return (
    amount && timeUnitName && moment().add(amount, timeUnitName).toISOString()
  )
}

export default useManualTimeDurationValue
