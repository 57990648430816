import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { TableFilter, Variation } from '~/types'

import { updateSearchHighlights } from '../actions/search'
import {
  clearHistory,
  EDIT_VARIATION,
  editVariation,
  editVariationFailure,
  editVariationSuccess,
  FETCH_MORE_VARIATIONS,
  FETCH_ON_HAND_HISTORY,
  FETCH_VARIATION,
  FETCH_VARIATIONS,
  fetchMoreVariationsFailure,
  fetchMoreVariationsSuccess,
  fetchOnHandHistory,
  fetchOnHandHistoryFailure,
  fetchOnHandHistorySuccess,
  fetchVariation,
  fetchVariationFailure,
  fetchVariations,
  fetchVariationsFailure,
  fetchVariationsSuccess,
  fetchVariationSuccess,
  getFilters,
  getLastSearchQuery,
  getVariation,
  SEARCH_MORE_VARIATIONS,
  SEARCH_VARIATIONS,
  searchMoreVariations,
  searchMoreVariationsFailure,
  searchMoreVariationsSuccess,
  searchVariations,
  searchVariationsFailure,
  searchVariationsSuccess,
} from '../duck/onHandCatalog'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* refreshOnHandCatalog() {
  yield put(clearHistory())
  const query: string = yield select(getLastSearchQuery)
  if (query) {
    yield put(
      searchVariations(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, query),
    )
  } else {
    yield put(fetchVariations(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT))
  }
}

export function* refreshVariationInfo(variationId: string) {
  const variation: Variation = yield select(getVariation(variationId))
  if (variation) {
    yield put(fetchVariation(variationId))
  }
}

const serializeFilters = (filters: Record<string, TableFilter>) =>
  Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      [key]: filters[key]?.value,
    }),
    {} as Record<string, TableFilter['value']>,
  )

export function* fetchVariationsSaga({
  from,
  to,
}: ReturnType<typeof fetchVariations>) {
  try {
    const filters: Record<string, TableFilter> = yield select(getFilters)

    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchOnHandCatalog,
      from,
      to,
      serializeFilters(filters),
    )
    yield call(updateEntities, entities)
    yield put(fetchVariationsSuccess(from, list, totalCount))
  } catch (error) {
    yield put(fetchVariationsFailure(error as ApiError))
  }
}

export function* fetchMoreVariationsSaga({
  from,
  to,
}: ReturnType<typeof fetchVariations>) {
  try {
    const filters: Record<string, TableFilter> = yield select(getFilters)

    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchOnHandCatalog,
      from,
      to,
      serializeFilters(filters),
    )
    yield call(updateEntities, entities)
    yield put(fetchMoreVariationsSuccess(from, list, totalCount))
  } catch (error) {
    yield put(fetchMoreVariationsFailure(error as ApiError))
  }
}

export function* searchVariationsSaga({
  from,
  to,
  query,
}: ReturnType<typeof searchVariations>) {
  try {
    const filters: Record<string, TableFilter> = yield select(getFilters)

    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield* requestAPI(
      API.searchOnHandCatalog,
      from,
      to,
      serializeFilters(filters),
      query,
    )
    yield call(updateEntities, entities)
    yield put(updateSearchHighlights(highlights, totalCount))
    yield put(searchVariationsSuccess(from, list, totalCount))
  } catch (error) {
    yield put(searchVariationsFailure(error as ApiError))
  }
}

export function* searchMoreVariationsSaga({
  from,
  to,
  query,
}: ReturnType<typeof searchMoreVariations>) {
  try {
    const filters: Record<string, TableFilter> = yield select(getFilters)

    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield* requestAPI(
      API.searchOnHandCatalog,
      from,
      to,
      serializeFilters(filters),
      query,
    )
    yield call(updateEntities, entities)
    yield put(updateSearchHighlights(highlights, totalCount))
    yield put(searchMoreVariationsSuccess(from, list, totalCount))
  } catch (error) {
    yield put(searchMoreVariationsFailure(error as ApiError))
  }
}

export function* fetchVariationSaga({
  variationId,
}: ReturnType<typeof fetchVariation>) {
  try {
    const { entities } = yield* requestAPI(
      API.fetchOnHandCatalogItem,
      variationId,
    )
    yield call(updateEntities, entities)
    yield put(fetchVariationSuccess())
  } catch (error) {
    yield put(fetchVariationFailure(error as ApiError))
  }
}

export function* editVariationSaga({
  variation,
}: ReturnType<typeof editVariation>) {
  try {
    const { entities } = yield* requestAPI(API.editOnHandCatalogItem, variation)
    yield call(updateEntities, entities)
    yield put(editVariationSuccess(variation.id))
  } catch (error) {
    yield put(editVariationFailure(error as ApiError, variation.id))
  }
}

export function* fetchOnHandHistorySaga({
  variationId,
  from,
  to,
}: ReturnType<typeof fetchOnHandHistory>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchOnHandHistory, variationId, from, to)
    yield call(updateEntities, entities)
    yield put(fetchOnHandHistorySuccess(from, list, totalCount))
  } catch (error) {
    yield put(fetchOnHandHistoryFailure(error as ApiError))
  }
}

function* watchFetchVariations() {
  yield takeLatest(FETCH_VARIATIONS, fetchVariationsSaga)
}

function* watchFetchMoreVariations() {
  yield takeLatest(FETCH_MORE_VARIATIONS, fetchMoreVariationsSaga)
}

function* watchSearchVariations() {
  yield takeLatest(SEARCH_VARIATIONS, searchVariationsSaga)
}

function* watchSearchMoreVariations() {
  yield takeLatest(SEARCH_MORE_VARIATIONS, searchMoreVariationsSaga)
}

function* watchFetchVariation() {
  yield takeLatest(FETCH_VARIATION, fetchVariationSaga)
}

function* watchEditVariation() {
  yield takeLatest(EDIT_VARIATION, editVariationSaga)
}

function* watchFetchOnHandHistory() {
  yield takeLatest(FETCH_ON_HAND_HISTORY, fetchOnHandHistorySaga)
}

export default function* onHandCatalogSaga() {
  yield all([
    watchFetchVariations(),
    watchFetchMoreVariations(),
    watchSearchVariations(),
    watchSearchMoreVariations(),
    watchFetchVariation(),
    watchEditVariation(),
    watchFetchOnHandHistory(),
  ])
}
