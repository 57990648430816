import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ButtonWithLoader, Text } from '@pbt/pbt-ui-components'

import WellnessPlan from '~/components/common/images/WellnessPlan.svg'
import Link from '~/components/common/link/Link'

import HelpButton from '../HelpButton'
import NoItemsScreen from '../NoItemsScreen'

const LARGE_ICON_HEIGHT = 192

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      fontSize: '1.8rem',
    },
    contentContainer: {
      background: 'transparent',
      border: 'none',
      maxWidth: 'none',
      padding: 0,
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(4),
      height: 56,
      width: 210,
      fontSize: '1.9rem',
      borderRadius: 28,
    },
    iconGroup: {
      marginTop: theme.spacing(4),
      width: 271,
      position: 'relative',
      minHeight: LARGE_ICON_HEIGHT,
    },
    planIcon: {
      position: 'absolute',
    },
    planIconSmall: {
      left: 164,
      top: 26,
      width: 122,
      height: 139,
    },
    planIconMedium: {
      left: 88,
      top: 14,
      width: 144,
      height: 166,
    },
    planIconLarge: {
      left: 0,
      top: 0,
      width: 168,
      height: LARGE_ICON_HEIGHT,
    },
  }),
  { name: 'NoWellnessPlansScreen' },
)

const NoWellnessPlansScreen = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const goToCreatePlan = () => {
    navigate('/admin/catalog/wellness-plans/create')
  }

  return (
    <NoItemsScreen
      classes={{
        contentContainer: classes.contentContainer,
      }}
      title={t('Common:WELLNESS_PLANS')}
    >
      <Text className={classes.title} variant="hero">
        {t(
          'WellnessPlans:SET_UP_YOUR_WELLNESS_PLANS_AND_DRIVE_LOYALTY_TO_YOUR_PRACTICE',
        )}
      </Text>
      <Text className={classes.subTitle} variant="body">
        {`${t('Common:IF_YOU_HAVE_QUESTIONS_WE_ARE_HERE_TO_HELP')}. `}
        <HelpButton Component={Link} ComponentProps={{ to: '/' }}>
          {t('Common:OPEN_CHAT')}
        </HelpButton>
      </Text>
      <ButtonWithLoader className={classes.button} onClick={goToCreatePlan}>
        {t('Common:GET_STARTED')}
      </ButtonWithLoader>
      <Grid item className={classes.iconGroup}>
        <img
          alt="wellness-plan-1"
          className={classNames(classes.planIcon, classes.planIconSmall)}
          src={WellnessPlan}
        />
        <img
          alt="wellness-plan-2"
          className={classNames(classes.planIcon, classes.planIconMedium)}
          src={WellnessPlan}
        />
        <img
          alt="wellness-plan-3"
          className={classNames(classes.planIcon, classes.planIconLarge)}
          src={WellnessPlan}
        />
      </Grid>
    </NoItemsScreen>
  )
}

export default NoWellnessPlansScreen
