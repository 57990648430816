import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Nil, Text } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import CommunicationsPreview from '~/components/dashboard/communications/preview/CommunicationsPreview'

export interface AutomaticCommunicationEmailPreviewProps {
  template?: string | Nil
}

const AutomaticCommunicationEmailPreview = ({
  template,
}: AutomaticCommunicationEmailPreviewProps) => {
  const { t } = useTranslation(['Common', 'Mock'])

  return (
    <Grid container item direction="column">
      <Text strong mb={1} variant="subheading3">
        {t('Common:EMAIL')}
      </Text>
      <CommunicationsPreview
        emailPreviewProps={{ hideLoading: true }}
        template={template}
        transport={ConversationTransport.Email}
      />
    </Grid>
  )
}

export default AutomaticCommunicationEmailPreview
