export enum InventoryCategoryName {
  DRUG = 'Drugs',
  VET_DIET = 'Vet Diet',
  FOOD = 'Food & supplements',
  SUPPLIES = 'Supplies',
  VACCINES = 'Vaccines',
}

export const InventoryCategoryDisplayOrder = [
  InventoryCategoryName.DRUG,
  InventoryCategoryName.VET_DIET,
  InventoryCategoryName.FOOD,
  InventoryCategoryName.SUPPLIES,
  InventoryCategoryName.VACCINES,
]

export const InventoryGlobalCategoryNames = [
  InventoryCategoryName.DRUG,
  InventoryCategoryName.VET_DIET,
]
