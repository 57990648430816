import React from 'react'
import { TextInteractive, TextInteractiveProps } from '@pbt/pbt-ui-components'

export type DetailsItem = {
  id: string
  text?: string
}

export interface DetailsSeparatedLabelProps extends TextInteractiveProps {
  details: DetailsItem[]
}

const DetailsSeparatedLabel = ({
  details,
  ...rest
}: DetailsSeparatedLabelProps) => (
  <TextInteractive variant="body" {...rest}>
    {details.map(({ id, text }, index) => (
      <React.Fragment key={id}>{`${text}${
        index < details.length - 1 ? ' | ' : ''
      }`}</React.Fragment>
    ))}
  </TextInteractive>
)

export default DetailsSeparatedLabel
