import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { AddButton, PermissionArea, Text } from '@pbt/pbt-ui-components'

import ActionsPopper from '~/components/common/ActionsPopper'
import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  getIsFetchingItem,
  getIsFetchingList,
  getIsUpdatingReminder,
  getReminder,
  getReminderFilters,
} from '~/store/duck/reminders'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getOrdersIsSendingOrReceiving } from '~/store/reducers/orders'
import { getPatientId } from '~/store/reducers/soap'
import { Reminder } from '~/types'
import useDialog from '~/utils/useDialog'

import RemindersInformationPanel from '../information-panel/RemindersInformationPanel'
import { getMinReminderWidgetViewportHeight } from '../utils/getMinReminderWidgetViewportHeight'
import { useGetReminderInitialFilters } from '../utils/useGetReminderInitialFilters'
import { useInitializeReminders } from '../utils/useInitializeReminders'
import { useReminderErrorAlert } from '../utils/useReminderErrorAlert'
import { useReminderItemActions } from '../utils/useReminderItemActions'
import { useReminderOrderItems } from '../utils/useReminderOrderItems'
import { useRemindersColumns } from '../utils/useRemindersColumns'

const useStyles = makeStyles(
  (theme) => ({
    cellWrapper: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 24,
    },
    chargesCell: {
      display: 'inline-block',
      padding: theme.spacing(0, 1),
    },
    filter: {
      marginLeft: theme.spacing(-1),
      maxHeight: 35,
    },
    reminderCell: {
      borderRight: theme.constants.tableBorder,
    },
    spacingCell: {
      paddingLeft: theme.spacing(1),
    },
    stretchCell: {
      height: 'auto !important',
      alignSelf: 'stretch',
      alignItems: 'flex-start',
      padding: theme.spacing(1),
    },
    withoutBorder: {
      borderRight: 'none !important',
    },
  }),
  { name: 'RemindersTable' },
)

export interface RemindersTableProps {}

const RemindersTable = () => {
  const classes = useStyles()

  const { t } = useTranslation(['Common', 'Reminders'])

  const [actionsAnchorElement, setActionsAnchorElement] =
    useState<HTMLElement | null>(null)
  const [activeActionItem, setActiveActionItem] = useState<Reminder | null>(
    null,
  )
  const [isRemindersPanelOpened, setIsRemindersPanelOpened] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState<string>()

  const isFetchingItem = useSelector(getIsFetchingItem)
  const isFetchingList = useSelector(getIsFetchingList)
  const isSendingOrReceivingOrder = useSelector(getOrdersIsSendingOrReceiving)
  const isUpdatingReminder = useSelector(getIsUpdatingReminder(selectedItemId))
  const patientId = useSelector(getPatientId)
  const reminderPermissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )
  const selectedReminder = useSelector(getReminder(selectedItemId))
  const filters = useSelector(getReminderFilters)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const initialFilter = useGetReminderInitialFilters()

  const [openAddReminderDialog] = useDialog(DialogNames.ADD_REMINDER)
  const {
    loadMoreItems,
    onApplyFilter,
    onRemoveFilter,
    reminders,
    totalCount,
  } = useInitializeReminders(patientId, initialFilter)
  const reminderActions = useReminderItemActions(activeActionItem)
  const { isCheckedItem, onUncheckItem, onCheckItem } = useReminderOrderItems()
  const { mainColumn, columns } = useRemindersColumns({
    actionsAnchorElement,
    activeActionItem,
    classes,
    initialFilter,
    onApplyFilter,
    onRemoveFilter,
    setActionsAnchorElement,
    setActiveActionItem,
    isCheckedItem,
    onCheckItem,
    onUncheckItem,
  })

  useReminderErrorAlert(patientId)

  const handleAddReminder = () => {
    openAddReminderDialog({ patientId })
  }

  const handleSelectRow = (item: Reminder) => {
    const isCurrentContextItem =
      !isPatientSharingEnabled || item?.businessId === currentBusinessId
    if (isCurrentContextItem) {
      setSelectedItemId(item.id)
      setIsRemindersPanelOpened(true)
    }
  }

  const closeRemindersPanel = () => {
    setSelectedItemId(undefined)
    setIsRemindersPanelOpened(false)
  }

  const [minViewportHeight, viewportItems] =
    getMinReminderWidgetViewportHeight(reminders)

  const isLoadingItem = R.any(Boolean)([
    isFetchingItem,
    isUpdatingReminder,
    isSendingOrReceivingOrder,
  ])
  const isLoadingItems = isFetchingList || isSendingOrReceivingOrder

  const filteredReminders = useMemo(
    () =>
      reminders.filter((reminder) =>
        (filters.stateIds ?? initialFilter).includes(
          reminder.state?.id || reminder.stateId,
        ),
      ),
    [reminders, filters],
  )

  return (
    <>
      {selectedReminder && (
        <RemindersInformationPanel
          isCheckedItem={isCheckedItem}
          isLoading={isLoadingItem}
          isOpened={isRemindersPanelOpened}
          item={selectedReminder}
          onCheckItem={onCheckItem}
          onClose={closeRemindersPanel}
          onUncheckItem={onUncheckItem}
        />
      )}
      <PuiDataTable
        densed
        dynamicSize
        classesHeader={{ mainColumn: classes.withoutBorder }}
        columns={columns}
        emptyPlaceholder={
          <Text align="center" m={2} variant="body2">
            {t('Reminders:REMINDERS_LIST.NO_REMINDERS')}
          </Text>
        }
        filledBackground="odd"
        isLoading={isLoadingItems}
        items={filteredReminders}
        loadMoreItems={loadMoreItems}
        mainColumn={mainColumn}
        minViewPortHeight={minViewportHeight}
        selectedRowId={selectedItemId}
        totalCount={totalCount}
        viewportItems={viewportItems}
        onSelectRow={handleSelectRow}
      />

      <Grid container px={2} py={1}>
        <AddButton
          addText={t('Common:ADD_REMINDER')}
          disabled={!reminderPermissions.create}
          onAdd={handleAddReminder}
        />
      </Grid>

      <ActionsPopper
        actions={reminderActions}
        anchorEl={actionsAnchorElement}
        disablePortal={false}
        onClose={() => {
          setActionsAnchorElement(null)
          setActiveActionItem(null)
        }}
      />
    </>
  )
}

export default RemindersTable
