import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Moment } from 'moment'
import * as R from 'ramda'
import { DateFormat, moment, Nil, Text } from '@pbt/pbt-ui-components'
import { getIsScrollSmoothSupported } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import {
  SchedulerColumnType,
  SchedulerColumnTypesToProp,
  SlotType,
  SpecialTimetableColumn,
  TimetableArea,
} from '~/constants/schedulerConstants'
import { getTimetableConfiguredColumnsForArea } from '~/store/duck/userSettings'
import { useGetScheduleColumnId } from '~/store/hooks/whiteboard'
import {
  getCurrentBusinessId,
  getCurrentBusinessIsAutoAssignEventEnabled,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import {
  getSchedulerBusinessWorkingHours,
  getSchedules,
} from '~/store/reducers/scheduler'
import {
  getTimetableFilteredAppointmentTypes,
  getTimetableFilteredPersons,
} from '~/store/reducers/timetable'
import { Schedule } from '~/types'
import { dateToIntervals } from '~/utils/time'
import useTimetableDate from '~/utils/useTimetableDate'

import Timetable from '../../../Timetable'
import {
  getSlotId,
  getSlotOffsetPosition,
  getSlotsPositions,
} from '../../../timetableUtils'
import ScheduleAppointmentCard from '../../schedule/ScheduleAppointmentCard'
import SchedulerHeader from '../../SchedulerHeader'
import ScheduleMouseHandler from './ScheduleMouseHandler'
import TimeNeedle from './TimeNeedle'
import WorkingHourNeedle from './WorkingHourNeedle'

const isScrollSmoothSupported = getIsScrollSmoothSupported()

const useStyles = makeStyles(
  (theme) => ({
    timeSlotGridContainer: {
      position: 'relative',
      padding: theme.spacing(2, 0.75, 3, 12),
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(-3),
      },
    },
    timeSlotGrid: {
      position: 'relative',
      height: theme.constants.schedulerRowHeight,
      '&:not(:first-child)': {
        borderTop: 'none',
      },
      border: theme.constants.filterBorder,
    },
    timeSlotText: {
      left: theme.spacing(-7),
      position: 'absolute',
      fontSize: '1.2rem',
    },
    timeSlotTextTop: {
      top: theme.spacing(-1),
    },
    timeSlotTextBottom: {
      bottom: theme.spacing(-1),
    },
    slotsContainer: {
      marginLeft: theme.spacing(2),
      height: `calc(100% - ${theme.spacing(9)})`,
      position: 'relative',
      top: theme.spacing(2),
      '&:last-child': {
        marginRight: '100vw',
      },
    },
    unassignedSlotsContainer: {
      marginLeft: theme.spacing(1),
    },
    content: {
      [theme.breakpoints.down('md')]: {
        maxWidth: 'calc(100% - 120px)',
      },
      height: 'calc(100% - 150px)',
      maxWidth: 'calc(100% - 96px)',
      left: theme.spacing(12),
      overflowX: 'hidden',
      position: 'absolute',
    },
    mouseHandlerContainer: {
      position: 'relative',
    },
    header: {
      padding: theme.spacing(0, 3, 1, 8.75),
    },
  }),
  { name: 'SchedulerScheduleView' },
)

const GRID_STEP_MINUTES = 30

const SchedulerScheduleView = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const businessWorkingHours = useSelector(getSchedulerBusinessWorkingHours)
  const schedules = useSelector(getSchedules)
  const filteredPersons = useSelector(getTimetableFilteredPersons)
  const filteredAppointmentTypes = useSelector(
    getTimetableFilteredAppointmentTypes,
  )
  const isAutoAssignEventEnabled = useSelector(
    getCurrentBusinessIsAutoAssignEventEnabled,
  )
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const currentBusinessId = useSelector(getCurrentBusinessId) as string
  const columnsFromStore =
    useSelector(
      getTimetableConfiguredColumnsForArea(
        currentBusinessId,
        TimetableArea.SCHEDULER,
      ),
    ) || []

  const { selectedDate } = useTimetableDate()

  const getColumnName = useGetScheduleColumnId()

  const [openCardId, setOpenCardId] = useState<string | Nil>()
  const [avoidNextScroll, setAvoidNextScroll] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  const {
    constants: { schedulerColumnWidth, schedulerHeadingContainerHeight },
    mixins,
  } = useTheme()
  const toolbarHeight = mixins.toolbar.minHeight as number

  const scheduleFilter = (schedule: Schedule) => {
    const hasNoPerson = !schedule.personId
    const matchesPerson =
      hasNoPerson || !R.includes(schedule.personId, filteredPersons)

    const isNotEventTypeColumn =
      schedule.columnType !== SchedulerColumnType.EVENT_TYPE
    const matchesAppointmentType =
      isNotEventTypeColumn ||
      !R.includes(schedule.eventTypeId, filteredAppointmentTypes)

    return matchesPerson && matchesAppointmentType
  }

  const scheduleSorter = (a: Schedule, b: Schedule) => {
    const aName = getColumnName(a)
    const bName = getColumnName(b)

    return columnsFromStore.indexOf(aName) - columnsFromStore.indexOf(bName)
  }

  const unassignedSchedule = schedules.find(
    (schedule) => getColumnName(schedule) === SpecialTimetableColumn.UNASSIGNED,
  )
  const schedulesReorderUnassigned =
    isAutoAssignEventEnabled && isOmnichannel && unassignedSchedule
      ? [
          ...schedules.filter((schedule) => schedule !== unassignedSchedule),
          unassignedSchedule,
        ]
      : schedules
  const filteredSchedules = schedulesReorderUnassigned
    .filter(scheduleFilter)
    .sort(scheduleSorter)

  const columnsWithSchedule = filteredSchedules.map((column) => {
    const slots = column.slots || []
    // Calculate maximum number of overlaps in order to get column width
    const filteredSlots = slots.filter(
      (slot) => slot.type !== SlotType.NOT_AVAILABLE,
    )
    const positions = getSlotsPositions(filteredSlots)
    const maxNumberOfOverlaps = positions.length
    const columnWidth =
      maxNumberOfOverlaps > 1
        ? (schedulerColumnWidth * maxNumberOfOverlaps) / 2
        : schedulerColumnWidth
    const prop = SchedulerColumnTypesToProp[column.columnType]
    const id = prop ? column[prop] : 'unassigned'
    return {
      ...column,
      positions,
      columnWidth,
      id: `${column.columnType}-${id}`,
    }
  })

  const contentRef = useRef<HTMLDivElement>(null)
  const needleRef = useRef<HTMLDivElement>(null)
  const clinicStartHourRef = useRef<HTMLDivElement>(null)
  const clinicEndHourRef = useRef<HTMLDivElement>(null)
  const nineAmRef = useRef<HTMLDivElement>(null)

  const onSchedulesFetched = () => {
    const anchor = (needleRef.current ||
      clinicStartHourRef.current ||
      nineAmRef.current) as HTMLDivElement

    if (schedules.length && anchor && !avoidNextScroll) {
      setTimer(
        setTimeout(() => {
          window.scrollTo({
            top:
              anchor.offsetTop -
              (window.innerHeight -
                toolbarHeight -
                schedulerHeadingContainerHeight) /
                2,
            behavior: isScrollSmoothSupported ? 'smooth' : 'auto',
          })
        }, 1000),
      )
    }
    setAvoidNextScroll(false)
  }

  useEffect(() => {
    onSchedulesFetched()

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [schedules])

  useEffect(() => {
    if (!selectedDate) {
      onSchedulesFetched()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [selectedDate])

  const startDate = moment(selectedDate).startOf('day')
  const endDate = moment(selectedDate).endOf('day')
  const dates = useMemo(
    () => dateToIntervals(startDate, endDate, GRID_STEP_MINUTES),
    [selectedDate],
  )

  const isBusinessWorkingHour = (momentTime: Moment) =>
    momentTime.isSame(businessWorkingHours.from) ||
    momentTime.isSame(businessWorkingHours.to)

  const onCardClose = useCallback(() => {
    setOpenCardId(undefined)
  }, [])

  return (
    <>
      <Timetable
        HeaderComponent={SchedulerHeader}
        HeaderProps={{
          classes: {
            header: classes.header,
          },
        }}
        classes={{
          content: classes.content,
        }}
        columns={columnsWithSchedule}
        contentRef={contentRef}
        openShadow={Boolean(openCardId)}
      >
        {columnsWithSchedule.map(
          ({ id, slots = [], positions, columnWidth, columnType }) => {
            const isUnassigned = columnType === SchedulerColumnType.UNASSIGNED
            return (
              <div
                className={classNames(classes.slotsContainer, {
                  [classes.unassignedSlotsContainer]: isUnassigned,
                })}
                id={`column-${id}`}
                key={id}
                style={{ width: columnWidth, minWidth: columnWidth }}
              >
                {slots.map((slot, index) => {
                  const appointmentId = getSlotId(slot)
                  const offsetPosition = getSlotOffsetPosition(slot, positions)

                  return (
                    <ScheduleAppointmentCard
                      appointmentId={appointmentId}
                      canOpenPopup={!openCardId || appointmentId === openCardId}
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      offsetPosition={offsetPosition}
                      slot={slot}
                      stepInterval={GRID_STEP_MINUTES}
                      steps={dates}
                      onClick={setOpenCardId}
                      onClose={onCardClose}
                    />
                  )
                })}
              </div>
            )
          },
        )}
      </Timetable>
      <Grid
        container
        item
        className={classes.timeSlotGridContainer}
        id="time-slot-grid-container"
      >
        <Grid
          container
          item
          className={classes.mouseHandlerContainer}
          direction="column"
        >
          {dates.map((dateSlot, index) => {
            const momentDateSlot = moment(dateSlot)
            // As meridian format is not used in all places we need to pass the format value hardcoded
            // here and then create a variable to store the correct date format for each localization
            const formatted = momentDateSlot.format('hh:mma')
            const isNineAmSlot = formatted === '09:00am'

            const displayFormatted = momentDateSlot.format(
              DateFormat.FULL_TIME_WITH_MERIDIAN,
            )
            return (
              <Grid
                item
                className={classes.timeSlotGrid}
                key={formatted}
                ref={isNineAmSlot ? nineAmRef : undefined}
              >
                <Text
                  className={classNames(
                    classes.timeSlotText,
                    classes.timeSlotTextTop,
                  )}
                  variant={
                    isBusinessWorkingHour(momentDateSlot) ? 'h1' : 'body3'
                  }
                >
                  {displayFormatted}
                </Text>
                {index === dates.length - 1 && (
                  <Text
                    className={classNames(
                      classes.timeSlotText,
                      classes.timeSlotTextBottom,
                    )}
                    variant="body3"
                  >
                    {endDate.format(DateFormat.FULL_TIME_WITH_MERIDIAN)}
                  </Text>
                )}
              </Grid>
            )
          })}
          <ScheduleMouseHandler
            columns={filteredSchedules}
            contentRef={contentRef}
            schedules={schedules}
            stepInterval={GRID_STEP_MINUTES}
            steps={dates}
            onAppointmentOk={() => setAvoidNextScroll(true)}
          />
        </Grid>
        {moment().isSame(selectedDate, 'd') && (
          <TimeNeedle
            endDate={endDate}
            ref={needleRef}
            startDate={startDate}
            stepInterval={GRID_STEP_MINUTES}
          />
        )}
        <WorkingHourNeedle
          endDate={endDate}
          label={t('Common:OPEN_CLINIC_NOUN')}
          ref={clinicStartHourRef}
          startDate={startDate}
          stepInterval={GRID_STEP_MINUTES}
          time={businessWorkingHours.from}
        />
        <WorkingHourNeedle
          endDate={endDate}
          label={t('Common:CLOSE_CLINIC_NOUN')}
          ref={clinicEndHourRef}
          startDate={startDate}
          stepInterval={GRID_STEP_MINUTES}
          time={businessWorkingHours.to}
        />
      </Grid>
    </>
  )
}

export default SchedulerScheduleView
