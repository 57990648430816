import { all, call, put, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  fetchConstantsFailure,
  fetchConstantsSuccess,
  fetchSecureConstantsFailure,
  fetchSecureConstantsSuccess,
} from '../actions/constants'
import { FETCH_CURRENT_USER } from '../actions/types/auth'
import { FETCH_CONSTANTS } from '../actions/types/constants'
import updateEntities from './utils/updateEntities'

export function* fetchConstantsSaga() {
  try {
    const { result, entities } = yield call(API.fetchConstants)
    yield put(fetchConstantsSuccess(result))
    yield call(updateEntities, entities)
  } catch (error) {
    yield put(fetchConstantsFailure(error as ApiError))
  }
}

export function* fetchSecureConstantsSaga() {
  try {
    const { result, entities } = yield call(API.fetchSecureConstants)
    yield put(fetchSecureConstantsSuccess(result))
    yield call(updateEntities, entities)
  } catch (error) {
    yield put(fetchSecureConstantsFailure(error as ApiError))
  }
}

function* watchFetchConstants() {
  yield takeLeading(FETCH_CONSTANTS, fetchConstantsSaga)
}

function* watchFetchSecureConstants() {
  yield takeLeading(FETCH_CURRENT_USER, fetchSecureConstantsSaga)
}

export default function* constantsSaga() {
  yield all([watchFetchConstants(), watchFetchSecureConstants()])
}
