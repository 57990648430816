import { Nil } from '@pbt/pbt-ui-components'

export const downloadFile = ({
  url,
  name,
  extension,
}: {
  extension?: string | Nil
  name?: string | Nil
  url: string
}) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name && extension ? `${name}.${extension}` : '')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getFileExtensionFromType = (fileType: string) =>
  fileType.split('/').pop()
export const getFileName = (originalFileName: string) =>
  originalFileName.split('.').shift()

export const mapFilesToObjects = (files: File[], urls: string[]) =>
  files.map((file, i) => ({
    extension: getFileExtensionFromType(file.type),
    fileUrl: urls[i],
    originalFileName: file.name,
    name: getFileName(file.name),
  }))
