import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Facebook, Instagram, Twitter } from '@mui/icons-material'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PermissionArea, PuiTextField, useFields } from '@pbt/pbt-ui-components'

import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeSocialMediaConfigurationFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  (theme) => ({
    socialContainer: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    instagramContainer: {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(2),
      },
    },
    twitterContainer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
      },
    },
    socialInputContainer: {
      paddingLeft: theme.spacing(1),
    },
    secondarySocialContainer: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(3),
      },
    },
  }),
  { name: 'SocialMediaSection' },
)

const SocialMediaSection = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const { fields, validate, reset } = useFields([
    {
      name: 'facebook',
      validators: [],
      initialValue: business?.socialMedia?.facebook || '',
    },
    {
      name: 'instagram',
      validators: [],
      initialValue: business?.socialMedia?.instagram || '',
    },
    {
      name: 'twitter',
      validators: [],
      initialValue: business?.socialMedia?.twitter || '',
    },
  ])

  const { facebook, instagram, twitter } = fields

  usePracticeFieldsSection<PracticeSocialMediaConfigurationFields>({
    fields,
    business,
    parsedFields: {
      socialMedia: {
        facebook: facebook.value,
        twitter: twitter.value,
        instagram: instagram.value,
      },
    },
    sectionName: PracticeDetailsPanels.SOCIAL_MEDIA,
    validate,
    reset,
  })

  return (
    <>
      <Grid container item alignItems="flex-end">
        <Facebook />
        <Grid item xs className={classes.socialInputContainer}>
          <PuiTextField
            disabled={!permissions.update}
            field={facebook}
            inputProps={{ maxLength: 100 }}
            label="Facebook"
            margin="none"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.secondarySocialContainer}
        direction={isMobile ? 'column' : 'row'}
      >
        <Grid
          container
          item
          xs
          alignItems="flex-end"
          className={classNames(
            classes.socialContainer,
            classes.instagramContainer,
          )}
        >
          <Instagram />
          <Grid item xs className={classes.socialInputContainer}>
            <PuiTextField
              disabled={!permissions.update}
              field={instagram}
              inputProps={{ maxLength: 100 }}
              label="Instagram"
              margin="none"
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          alignItems="flex-end"
          className={classNames(
            classes.socialContainer,
            classes.twitterContainer,
          )}
        >
          <Twitter />
          <Grid item xs className={classes.socialInputContainer}>
            <PuiTextField
              disabled={!permissions.update}
              field={twitter}
              inputProps={{ maxLength: 100 }}
              label="Twitter"
              margin="none"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default memo(SocialMediaSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
