export const containsFinding = (child, findingId) => {
  const subChild = child?.children.find((child) =>
    Boolean(containsFinding(child, findingId)),
  )
  if (child?.id === findingId && !subChild) {
    return { path: [findingId], finding: child }
  }
  if (subChild) {
    const { path, finding } = containsFinding(subChild, findingId)
    return { path: [child.id].concat(path), finding }
  }
  return null
}
