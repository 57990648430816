import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      overflowY: 'visible',
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 64px)',
      },
    },
  }),
  { name: 'MultiAttachmentDialog' },
)

interface RenderMultiAttachmentProps {
  closeEdit: () => void
  fileIndexForImageEdit: number | null
  setFileIndexForImageEdit: (value: number | null) => void
}

export interface MultiAttachmentDialogProps extends BasePuiDialogProps {
  renderMultiAttachment: ({
    closeEdit,
    fileIndexForImageEdit,
    setFileIndexForImageEdit,
  }: RenderMultiAttachmentProps) => React.JSX.Element
  title?: string
}

const MultiAttachmentDialog = ({
  open,
  onClose,
  renderMultiAttachment,
  title,
}: MultiAttachmentDialogProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()

  const [fileIndexForImageEdit, setFileIndexForImageEdit] = useState<
    number | null
  >(null)

  const isEditing = fileIndexForImageEdit !== null

  const attachmentDialogTitle = isEditing
    ? t('Common:EDIT_ATTACHMENT')
    : title || t('Common:ADD_ATTACHMENT')

  const closeEdit = () => {
    setFileIndexForImageEdit(null)
  }

  return (
    <PuiDialog
      aria-labelledby="attachment-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={attachmentDialogTitle}
      onClose={isEditing ? closeEdit : onClose}
    >
      <Grid container item direction="column" p={2}>
        {renderMultiAttachment({
          closeEdit,
          fileIndexForImageEdit,
          setFileIndexForImageEdit,
        })}
      </Grid>
    </PuiDialog>
  )
}

export default MultiAttachmentDialog
