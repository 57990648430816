import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { LabTestLogFileMapped } from '~/components/dashboard/lab-tests-dashboard/lab-tests-table/LabTestAttachmentList'
import {
  FilesWithDetails,
  LabTestDashboardItem,
  LabTestToAssign,
  TableFilter,
} from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage, getServerValidationErrorType } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_LAB_TESTS_LIST = 'labTestsDashboard/FETCH_LAB_TESTS_LIST'
export const FETCH_LAB_TESTS_LIST_SUCCESS =
  'labTestsDashboard/FETCH_LAB_TESTS_LIST_SUCCESS'
export const FETCH_LAB_TESTS_LIST_FAILURE =
  'labTestsDashboard/FETCH_LAB_TESTS_LIST_FAILURE'

export const FETCH_MORE_LAB_TESTS = 'labTestsDashboard/FETCH_MORE_LAB_TESTS'
export const FETCH_MORE_LAB_TESTS_SUCCESS =
  'labTestsDashboard/FETCH_MORE_LAB_TESTS_SUCCESS'
export const FETCH_MORE_LAB_TESTS_FAILURE =
  'labTestsDashboard/FETCH_MORE_LAB_TESTS_FAILURE'

export const REFRESH_LAB_TESTS_LIST = 'labTestsDashboard/REFRESH_LAB_TESTS_LIST'
export const REFRESH_LAB_TESTS_LIST_SUCCESS =
  'labTestsDashboard/REFRESH_LAB_TESTS_LIST_SUCCESS'
export const REFRESH_LAB_TESTS_LIST_FAILURE =
  'labTestsDashboard/REFRESH_LAB_TESTS_LIST_FAILURE'

export const ASSIGN_LAB_TEST = 'labTestsDashboard/ASSIGN_LAB_TEST'
export const ASSIGN_LAB_TEST_SUCCESS =
  'labTestsDashboard/ASSIGN_LAB_TEST_SUCCESS'
export const ASSIGN_LAB_TEST_FAILURE =
  'labTestsDashboard/ASSIGN_LAB_TEST_FAILURE'

export const SET_FILTERS = 'labTestsDashboard/SET_FILTERS'

export const SAVE_LAB_ORDER = 'labTestsDashboard/SAVE_LAB_ORDER'
export const SAVE_LAB_ORDER_SUCCESS = 'labTestsDashboard/SAVE_LAB_ORDER_SUCCESS'
export const SAVE_LAB_ORDER_FAILURE = 'labTestsDashboard/SAVE_LAB_ORDER_FAILURE'

export const UPDATE_LAB_TESTS = 'labTestsDashboard/UPDATE_LAB_TESTS'
export const UPDATE_LAB_TEST_LOG_FILE_GROUP =
  'labTestsDashboard/UPDATE_LAB_TEST_LOG_FILE_GROUP'

export const FETCH_LAB_TEST_DETAILS = 'labTestsDashboard/FETCH_LAB_TEST_DETAILS'
export const FETCH_LAB_TEST_DETAILS_SUCCESS =
  'labTestsDashboard/FETCH_LAB_TEST_DETAILS_SUCCESS'
export const FETCH_LAB_TEST_DETAILS_FAILURE =
  'labTestsDashboard/FETCH_LAB_TEST_DETAILS_FAILURE'

export const CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP =
  'labTestsDashboard/CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP'

export const CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_SUCCESS =
  'labTestsDashboard/CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_SUCCESS'

export const CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_FAILURE =
  'labTestsDashboard/CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_FAILURE'

export const DELETE_LAB_TEST_ATTACHMENT_FILE =
  'labTestsDashboard/DELETE_LAB_TEST_ATTACHMENT_FILE'

export const DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS =
  'labTestsDashboard/DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS'

export const DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE =
  'labTestsDashboard/DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE'

export const OPTIMISTIC_DELETE_LAB_TEST_ATTACHMENT_FILE =
  'labTestsDashboard/OPTIMISTIC_DELETE_LAB_TEST_ATTACHMENT_FILE'

export const fetchLabTestsList = (from: number, to: number) => ({
  type: FETCH_LAB_TESTS_LIST,
  from,
  to,
})
export const fetchLabTestsListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_LAB_TESTS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchLabTestsListFailure = (error: ApiError) => ({
  type: FETCH_LAB_TESTS_LIST_FAILURE,
  error,
})

export const fetchMoreLabTests = (from: number, to: number) => ({
  type: FETCH_MORE_LAB_TESTS,
  from,
  to,
})
export const fetchMoreLabTestsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_LAB_TESTS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreLabTestsFailure = (error: ApiError) => ({
  type: FETCH_MORE_LAB_TESTS_FAILURE,
  error,
})

export const refreshLabTestsList = (from: number, to: number) => ({
  type: REFRESH_LAB_TESTS_LIST,
  from,
  to,
})
export const refreshLabTestsListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: REFRESH_LAB_TESTS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const refreshLabTestsListFailure = (error: ApiError) => ({
  type: REFRESH_LAB_TESTS_LIST_FAILURE,
  error,
})

export const updateLabTestsDashboard = (
  labTests: Record<string, LabTestDashboardItem>,
) => ({ type: UPDATE_LAB_TESTS, labTests })

export const assignLabTest = (labTestToAssign: LabTestToAssign) => ({
  type: ASSIGN_LAB_TEST,
  labTestToAssign,
})
export const assignLabTestSuccess = () => ({ type: ASSIGN_LAB_TEST_SUCCESS })
export const assignLabTestFailure = (error: ApiError) => ({
  type: ASSIGN_LAB_TEST_FAILURE,
  error,
})

export const setFilters = (filters: Record<string, TableFilter>) => ({
  type: SET_FILTERS,
  filters,
})

export const saveLabOrder = (labOrder: LabTestDashboardItem) => ({
  type: SAVE_LAB_ORDER,
  labOrder,
})

export const saveLabOrderSuccess = (labOrderIdentifier: string) => ({
  type: SAVE_LAB_ORDER_SUCCESS,
  labOrderIdentifier,
})

export const saveLabOrderFailure = (error: ApiError) => ({
  type: SAVE_LAB_ORDER_FAILURE,
  error,
})

export const fetchLabTestDetails = (
  vendorId: string | Nil,
  orderId: string | Nil,
  soapId?: string | Nil,
  invoiceId?: string | Nil,
  patientId?: string | Nil,
  vetId?: string | Nil,
  // eslint-disable-next-line max-params
) => ({
  type: FETCH_LAB_TEST_DETAILS,
  vendorId,
  orderId,
  soapId,
  invoiceId,
  patientId,
  vetId,
})

export const fetchLabTestDetailsSuccess = (labOrderIdentifier: string) => ({
  type: FETCH_LAB_TEST_DETAILS_SUCCESS,
  labOrderIdentifier,
})

export const fetchLabTestDetailsFailure = (error: ApiError) => ({
  type: FETCH_LAB_TEST_DETAILS_FAILURE,
  error,
})

export const createLabTestAttachmentFileGroup = ({
  labTestLogId,
  attachmentGroup,
  clientId,
  patientId,
  labTestIdentifier,
}: {
  attachmentGroup: FilesWithDetails
  clientId: string
  labTestIdentifier: string
  labTestLogId: string
  patientId: string
}) => ({
  type: CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP,
  labTestLogId,
  attachmentGroup,
  clientId,
  patientId,
  labTestIdentifier,
})

export const createLabTestAttachmentFileGroupSuccess = (
  labTestIdentifier: string,
  labTestLogId: string,
  files: LabTestLogFileMapped[],
) => ({
  type: CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_SUCCESS,
  labTestIdentifier,
  labTestLogId,
  files,
})

export const createLabTestAttachmentFileGroupFailure = (error: ApiError) => ({
  type: CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_FAILURE,
  error,
})

export const deleteLabTestAttachmentFile = ({
  labTestIdentifier,
  labTestLogId,
  groupId,
  fileId,
}: {
  fileId: string
  groupId: string
  labTestIdentifier: string
  labTestLogId: string
}) => ({
  type: DELETE_LAB_TEST_ATTACHMENT_FILE,
  labTestIdentifier,
  labTestLogId,
  groupId,
  fileId,
})

export const deleteLabTestAttachmentFileSuccess = () => ({
  type: DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS,
})

export const deleteLabTestAttachmentFileFailure = (error: ApiError) => ({
  type: DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE,
  error,
})

export const optimisticDeleteLabTestAttachmentFile = ({
  labTestIdentifier,
  labTestLogId,
  fileId,
}: {
  fileId: string
  labTestIdentifier: string
  labTestLogId: string
}) => ({
  type: OPTIMISTIC_DELETE_LAB_TEST_ATTACHMENT_FILE,
  labTestIdentifier,
  labTestLogId,
  fileId,
})

export type LabTestsDashboardState = {
  error: string | null
  filters: Record<string, TableFilter>
  isLabTestsLoading: boolean
  list: string[]
  map: Record<string, LabTestDashboardItem>
  totalCount: number
  validationErrorType: string | null
}

export const INITIAL_STATE: LabTestsDashboardState = {
  list: [],
  map: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: {},
  isLabTestsLoading: false,
  error: null,
  validationErrorType: null,
}

export const labTestsDashboardReducer = (
  state: LabTestsDashboardState = INITIAL_STATE,
  action: AnyAction,
): LabTestsDashboardState => {
  switch (action.type) {
    case FETCH_MORE_LAB_TESTS:
    case ASSIGN_LAB_TEST:
    case CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP:
    case DELETE_LAB_TEST_ATTACHMENT_FILE:
      return {
        ...state,
        isLabTestsLoading: true,
      }
    case FETCH_LAB_TESTS_LIST:
      return {
        ...state,
        isLabTestsLoading: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_LAB_TESTS_LIST_FAILURE:
    case CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_FAILURE:
    case DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLabTestsLoading: false,
      }
    case FETCH_LAB_TESTS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLabTestsLoading: false,
      }
    case FETCH_MORE_LAB_TESTS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
        isLabTestsLoading: false,
      }
    case REFRESH_LAB_TESTS_LIST_SUCCESS:
      return { ...state, list: action.list, totalCount: action.totalCount }
    case UPDATE_LAB_TESTS:
      return { ...state, map: secondLevelMerge(state.map, action.labTests) }
    case ASSIGN_LAB_TEST_SUCCESS:
      return {
        ...state,
        isLabTestsLoading: false,
      }
    case FETCH_MORE_LAB_TESTS_FAILURE:
    case ASSIGN_LAB_TEST_FAILURE:
      return {
        ...state,
        isLabTestsLoading: false,
        error: getErrorMessage(action.error),
        validationErrorType: getServerValidationErrorType(action.error),
      }
    case REFRESH_LAB_TESTS_LIST_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case SET_FILTERS:
      return { ...state, filters: action.filters }
    case CREATE_LAB_TEST_ATTACHMENT_FILE_GROUP_SUCCESS: {
      const { labTestIdentifier, labTestLogId, files } = action
      const updatedMap: Record<string, LabTestDashboardItem> = {
        ...state.map,
        [labTestIdentifier]: {
          ...state.map[labTestIdentifier],
          labTestLogFileGroupMap: {
            ...state.map[labTestIdentifier]?.labTestLogFileGroupMap,
            [labTestLogId]: [
              ...(state.map[labTestIdentifier]?.labTestLogFileGroupMap?.[
                labTestLogId
              ] || []),
              ...files,
            ],
          },
        },
      }
      return {
        ...state,
        map: updatedMap,
        isLabTestsLoading: false,
      }
    }
    case OPTIMISTIC_DELETE_LAB_TEST_ATTACHMENT_FILE: {
      const { labTestIdentifier, labTestLogId, fileId } = action
      const deletedMap: Record<string, LabTestDashboardItem> = {
        ...state.map,
        [labTestIdentifier]: {
          ...state.map[labTestIdentifier],
          labTestLogFileGroupMap: {
            ...state.map[labTestIdentifier]?.labTestLogFileGroupMap,
            [action.labTestLogId]: (
              state.map[labTestIdentifier]?.labTestLogFileGroupMap?.[
                labTestLogId
              ] || []
            ).filter((file) => file.id !== fileId),
          },
        },
      }

      return {
        ...state,
        map: deletedMap,
        isLabTestsLoading: false,
      }
    }
    case SAVE_LAB_ORDER:
      return { ...state, isLabTestsLoading: true }
    case SAVE_LAB_ORDER_SUCCESS:
    case DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        isLabTestsLoading: false,
      }
    case SAVE_LAB_ORDER_FAILURE:
      return {
        ...state,
        isLabTestsLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_LAB_TEST_DETAILS:
      return { ...state, isLabTestsLoading: true }
    case FETCH_LAB_TEST_DETAILS_SUCCESS:
      return {
        ...state,
        isLabTestsLoading: false,
      }
    case FETCH_LAB_TEST_DETAILS_FAILURE:
      return {
        ...state,
        isLabTestsLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

const getLabTestsDashboardState = (state: RootState): LabTestsDashboardState =>
  state.labTestsDashboard
export const getLabTestsList = (state: RootState) =>
  getLabTestsDashboardState(state).list
export const getLabTestsMap = (state: RootState) =>
  getLabTestsDashboardState(state).map
export const getLabTest = (labTestIdentifier: string) =>
  createSelector(getLabTestsMap, (map) => R.prop(labTestIdentifier, map))
export const getMultipleLabTests = (ids: string[]) =>
  createSelector(getLabTestsMap, (map) =>
    R.props(ids || [], map).filter(Boolean),
  )
export const getTotalCount = (state: RootState) =>
  getLabTestsDashboardState(state).totalCount
export const getAllLabTestsIsLoading = (state: RootState) =>
  getLabTestsDashboardState(state).isLabTestsLoading
export const getLabTestsFilters = (state: RootState) =>
  getLabTestsDashboardState(state).filters
export const getLabTestValidationErrorType = (state: RootState) =>
  getLabTestsDashboardState(state).validationErrorType

export const getLabTestLogFileGroups = (
  labTestIdentifier: string,
  logId: string,
) =>
  createSelector(
    getLabTest(labTestIdentifier),
    (labTest) => labTest.labTestLogFileGroupMap?.[logId],
  )
