import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError } from '@pbt/pbt-ui-components'
import { GraphQLError } from '@pbt/pbt-ui-components/src/utils/errorTypes'

import * as API from '~/api'
import SnackNotificationType from '~/constants/SnackNotificationType'
import i18nPortal from '~/locales/i18n'
import { Conversation, ConversationFile, UploadedAttachment } from '~/types'
import { cleanFilesToSend } from '~/utils'
import { isCommunicationValidationError } from '~/utils/communicationsUtils'
import { getFileExtensionFromType, getFileName } from '~/utils/file'

import {
  attachDocumentsToMessage,
  attachDocumentsToMessageFailure,
  clearMessageFiles,
  deleteFile,
  deleteFileFailure,
  deleteFileSuccess,
  deleteMessage,
  deleteMessageFailure,
  deleteMessageSuccess,
  fetchAllConversationFiles,
  fetchAllConversationFilesFailure,
  fetchAllConversationFilesSuccess,
  sendConversationDraftMessage,
  sendConversationMessage,
  sendConversationMessageFailure,
  sendConversationMessageSuccess,
  sendConversationMessageValidationFailure,
  sendGeneratedMessage,
  sendGeneratedMessageFailure,
  sendGeneratedMessageSuccess,
  updateConversationFiles,
  updateConversationMessages,
  updateMessage,
  updateMessageFailure,
  updateMessageSuccess,
  uploadFile,
  uploadFileFailure,
  uploadFileList,
  uploadFileListFailure,
  uploadFileListSuccess,
  uploadFileSuccess,
  waitForAttachedDocuments,
} from '../actions/conversationMessages'
import { generateDocumentInstances } from '../actions/documents'
import {
  ATTACH_DOCUMENTS_TO_MESSAGE,
  DELETE_FILE,
  DELETE_MESSAGE,
  FETCH_ALL_CONVERSATION_FILES,
  SEND_CONVERSATION_DRAFT_MESSAGE,
  SEND_CONVERSATION_MESSAGE,
  SEND_GENERATED_MESSAGE,
  UPDATE_MESSAGE,
  UPLOAD_FILE,
  UPLOAD_FILE_LIST,
} from '../actions/types/conversationMessages'
import { getStatus } from '../duck/errors'
import { registerWarnAlert } from '../duck/uiAlerts'
import { addUiNotification } from '../duck/uiNotifications'
import { getAttachmentsToSend } from '../reducers/conversationMessages'
import { getConversationForMessage } from '../reducers/conversations'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* sendConversationMessageSaga({
  conversationId,
  messageType,
  body,
  files,
}: ReturnType<typeof sendConversationMessage>) {
  try {
    const {
      entities: { messages, files: uploadedFiles },
    } = yield* requestAPI(API.sendConversationMessage, conversationId, {
      body,
      type: messageType,
      files: cleanFilesToSend(files),
    })

    yield put(updateConversationMessages(messages))
    yield put(updateConversationFiles(uploadedFiles, conversationId))
    yield put(sendConversationMessageSuccess())
  } catch (e) {
    const error = e as ApiError
    if (isCommunicationValidationError(error)) {
      yield put(sendConversationMessageValidationFailure(error))
    } else {
      yield put(sendConversationMessageFailure(error))
    }
  }
}

export function* sendConversationDraftMessageSaga({
  conversationId,
  messageType,
  body,
}: ReturnType<typeof sendConversationDraftMessage>) {
  try {
    const files: ConversationFile[] = yield select(getAttachmentsToSend)
    const {
      entities: { messages, files: uploadedFiles },
    } = yield* requestAPI(API.sendConversationDraftMessage, conversationId, {
      body,
      type: messageType,
      files: cleanFilesToSend(files),
    })
    yield put(updateConversationMessages(messages))
    yield put(updateConversationFiles(uploadedFiles, conversationId))
    yield put(sendConversationMessageSuccess())
  } catch (e) {
    const error = e as ApiError
    if (isCommunicationValidationError(error)) {
      yield put(sendConversationMessageValidationFailure(error))
    } else {
      yield put(sendConversationMessageFailure(error))
    }
  }
}

export function* updateMessageSaga({
  messageId,
  status,
  text,
  files,
}: ReturnType<typeof updateMessage>) {
  try {
    const {
      entities: { messages, files: uploadedFiles },
    } = yield* requestAPI(API.updateMessage, messageId, {
      status,
      text,
      files: cleanFilesToSend(files || []),
    })

    const conversation: Conversation = yield select(
      getConversationForMessage(messageId),
    )
    if (conversation?.id) {
      yield put(updateConversationFiles(uploadedFiles, conversation?.id))
    }
    yield put(clearMessageFiles(messageId))
    yield put(updateConversationMessages(messages))
    yield put(updateMessageSuccess())
  } catch (error) {
    yield put(updateMessageFailure(error as ApiError))
  }
}

export function* deleteMessageSaga({
  messageId,
}: ReturnType<typeof deleteMessage>) {
  try {
    yield* requestAPI(API.deleteMessage, messageId)
    yield put(deleteMessageSuccess(messageId))
  } catch (error) {
    yield put(deleteMessageFailure(error as ApiError))
  }
}

export function* fetchAllConversationFilesSaga({
  conversationId,
}: ReturnType<typeof fetchAllConversationFiles>) {
  try {
    const {
      entities: { files },
    } = yield* requestAPI(API.fetchAllConversationFiles, conversationId)
    yield put(fetchAllConversationFilesSuccess(conversationId, files))
  } catch (error) {
    yield put(fetchAllConversationFilesFailure(error as ApiError))
  }
}

export function* uploadFileSaga({
  file,
  clientId,
  patientId,
}: ReturnType<typeof uploadFile>) {
  try {
    const uploadedFile = yield* requestAPI(
      API.uploadConversationFile,
      clientId,
      patientId,
      file,
    )

    yield put(uploadFileSuccess(uploadedFile))
  } catch (error) {
    yield put(uploadFileFailure(error as ApiError))
  }
}

const getFileTemplate = (file: File) => ({
  raw: file,
  name: getFileName(file.name),
  extension: getFileExtensionFromType(file.type),
})

export function* uploadFileListSaga({
  attachmentGroup,
  clientId,
  patientId,
}: ReturnType<typeof uploadFileList>) {
  try {
    const fileList: UploadedAttachment[] = yield all(
      attachmentGroup.files.map((file) => {
        const fileTemplate = getFileTemplate(file)
        return call(
          // @ts-ignore
          requestAPI,
          API.uploadConversationFile,
          clientId,
          patientId,
          fileTemplate,
        )
      }),
    )

    yield put(uploadFileListSuccess(fileList))
  } catch (error) {
    yield put(uploadFileListFailure(error as ApiError))
  }
}

export function* deleteFileSaga({ fileId }: ReturnType<typeof deleteFile>) {
  try {
    yield* requestAPI(API.deleteConversationFile, fileId)
    yield put(deleteFileSuccess(fileId))
  } catch (error) {
    yield put(deleteFileFailure(error as ApiError))
  }
}

export function* attachDocumentsToMessageSaga({
  documentIds,
  context,
}: ReturnType<typeof attachDocumentsToMessage>) {
  try {
    yield put(waitForAttachedDocuments())
    yield put(generateDocumentInstances(documentIds, context))
  } catch (error) {
    yield put(attachDocumentsToMessageFailure(error as ApiError))
  }
}

export function* sendGeneratedMessageSaga({
  input,
  recipients,
  transport,
}: ReturnType<typeof sendGeneratedMessage>) {
  try {
    const { entities, result } = yield* requestAPI(API.sendUpdatePaymentLink, {
      transport,
      recipients,
      input,
    })
    yield call(updateEntities, entities)
    const conversationId = result[0]
    yield put(sendGeneratedMessageSuccess(conversationId))
    yield put(
      addUiNotification({
        id: uuid(),
        type: SnackNotificationType.COMMUNICATION,
        message: i18nPortal.t(
          'Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.NOTIFICATION',
        ),
        conversationId,
      }),
    )
  } catch (err) {
    const error = err as GraphQLError
    if (getStatus({ error, type: SEND_GENERATED_MESSAGE }) === 400) {
      const { message } = error
      yield put(registerWarnAlert(message))
    }
    yield put(sendGeneratedMessageFailure(error))
  }
}

function* watchSendConversationMessage() {
  yield takeLeading(SEND_CONVERSATION_MESSAGE, sendConversationMessageSaga)
}

function* watchSendConversationDraftMessage() {
  yield takeLeading(
    SEND_CONVERSATION_DRAFT_MESSAGE,
    sendConversationDraftMessageSaga,
  )
}

function* watchMessageUpdate() {
  yield takeLatest(UPDATE_MESSAGE, updateMessageSaga)
}

function* watchMessageDelete() {
  yield takeLatest(DELETE_MESSAGE, deleteMessageSaga)
}

function* watchFetchConversationFiles() {
  yield takeLatest(FETCH_ALL_CONVERSATION_FILES, fetchAllConversationFilesSaga)
}

function* watchUploadFile() {
  yield takeLeading(UPLOAD_FILE, uploadFileSaga)
}

function* watchUploadFileList() {
  yield takeLeading(UPLOAD_FILE_LIST, uploadFileListSaga)
}

function* watchDeleteFile() {
  yield takeLeading(DELETE_FILE, deleteFileSaga)
}

function* watchAttachDocument() {
  yield takeLeading(ATTACH_DOCUMENTS_TO_MESSAGE, attachDocumentsToMessageSaga)
}

function* watchSendGeneratedMessaged() {
  yield takeLeading(SEND_GENERATED_MESSAGE, sendGeneratedMessageSaga)
}

export default function* conversationMessagesSaga() {
  yield all([
    watchSendConversationMessage(),
    watchSendConversationDraftMessage(),
    watchMessageUpdate(),
    watchMessageDelete(),
    watchFetchConversationFiles(),
    watchUploadFile(),
    watchUploadFileList(),
    watchDeleteFile(),
    watchAttachDocument(),
    watchSendGeneratedMessaged(),
  ])
}
