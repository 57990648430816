import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiCheckbox, Text, Utils } from '@pbt/pbt-ui-components'

import { Vaccination } from '~/types'

import VaccinationsListRow from './VaccinationsListRow'

const useStyles = makeStyles(
  () => ({
    scrollContainer: {
      maxHeight: 320,
      overflowY: 'auto',
    },
    container: {
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  }),
  { name: 'VaccinationsList' },
)

export interface VaccinationsListProps {
  appointmentVaccinations: Vaccination[]
  onNavigateToSoap?: (soapId: string) => void
  selectAll?: boolean
  vaccinations: Vaccination[]
}

export interface VaccinationsListHandle {
  getSelectedVaccinationsIds: () => string[]
}

const VaccinationsList = forwardRef<
  VaccinationsListHandle,
  VaccinationsListProps
>(function VaccinationsList(
  {
    appointmentVaccinations = [],
    vaccinations: otherVaccinations = [],
    selectAll = false,
    onNavigateToSoap = R.F,
  },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const otherVaccinationsIdsList = R.pluck('id', otherVaccinations)
  const vaccinationsIdsList = R.pluck('id', otherVaccinations)
  const allVaccinationsIds = R.uniq([
    ...otherVaccinationsIdsList,
    ...vaccinationsIdsList,
  ])

  const isAppointmentVaccinations = Boolean(appointmentVaccinations.length)
  const isOtherVaccinations = Boolean(otherVaccinations.length)

  const [checkedVaccinations, setCheckedVaccinations] = useState(
    selectAll ? allVaccinationsIds : [],
  )

  const isAllOtherVaccinationsSelected = !R.difference(
    otherVaccinationsIdsList,
    checkedVaccinations,
  ).length

  useImperativeHandle(ref, () => ({
    getSelectedVaccinationsIds: () => checkedVaccinations,
  }))

  const handleVaccinationCheck = (vaccinationId: string) => {
    setCheckedVaccinations(
      Utils.toggleListItem(vaccinationId, checkedVaccinations),
    )
  }

  const handleSelectAll = () => {
    const updateCheckedVaccinations = isAllOtherVaccinationsSelected
      ? R.difference(checkedVaccinations, otherVaccinationsIdsList)
      : R.uniq([...checkedVaccinations, ...otherVaccinationsIdsList])

    setCheckedVaccinations(updateCheckedVaccinations)
  }

  return (
    <Grid container pl={0.5} pr={2}>
      <Grid container item pb={1} pl={1.5} pt={2}>
        <Grid item xs={6}>
          <Text strong variant="lowAccent2">
            {t('Common:VACCINE')}
          </Text>
        </Grid>
        <Grid container item xs={4}>
          <Grid item xs={6}>
            <Text strong variant="lowAccent2">
              {t('Soap:VACCINATIONS.VACCINATIONS_LIST.DATE_GIVEN')}
            </Text>
          </Grid>
          <Grid item xs={6}>
            <Text strong variant="lowAccent2">
              {t('Common:EXPIRES')}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.scrollContainer}>
        {isAppointmentVaccinations && (
          <Grid container item className={classes.container} mb={2}>
            <Grid item xs>
              <Text strong ml={1.5} variant="subheading3">
                {t(
                  'Soap:VACCINATIONS.VACCINATIONS_LIST.ADMINISTERED_AT_THIS_APPOINTMENT',
                )}
              </Text>
            </Grid>
            {appointmentVaccinations.map((vaccination) => (
              <VaccinationsListRow
                checked={R.includes(vaccination.id, checkedVaccinations)}
                key={vaccination.id}
                vaccination={vaccination}
                onCheck={handleVaccinationCheck}
              />
            ))}
          </Grid>
        )}
        {isOtherVaccinations && (
          <Grid container item className={classes.container} mb={2}>
            {isAppointmentVaccinations && (
              <Grid item xs>
                <Text strong ml={1.5} variant="subheading3">
                  {t(
                    'Soap:VACCINATIONS.VACCINATIONS_LIST.INCLUDE_OTHER_VACCINATIONS',
                  )}
                </Text>
              </Grid>
            )}
            <Grid container item alignItems="center" pl={1.5}>
              <Grid container item alignItems="center" wrap="nowrap" xs={6}>
                <PuiCheckbox
                  checked={isAllOtherVaccinationsSelected}
                  label={t('Common:SELECT_ALL')}
                  onChange={handleSelectAll}
                />
              </Grid>
            </Grid>
            {otherVaccinations.map((vaccination) => (
              <VaccinationsListRow
                checked={R.includes(vaccination.id, checkedVaccinations)}
                key={vaccination.id}
                vaccination={vaccination}
                onCheck={handleVaccinationCheck}
                onNavigateToSoap={onNavigateToSoap}
              />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})

export default VaccinationsList
