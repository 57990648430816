import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'

type ReverseButtonWrapperProps = PropsWithChildren & {
  hasPendingRefundInvoice: boolean
}

export const ReverseButtonWrapper = ({
  children,
  hasPendingRefundInvoice,
}: ReverseButtonWrapperProps) => {
  const { t } = useTranslation(['Common', 'Invoices', 'Payments'])

  if (!hasPendingRefundInvoice) {
    return <>{children}</>
  }

  return (
    <Tooltip placement="top" title={t('Payments:REFUND_INVOICE_PENDING')}>
      <span>{children}</span>
    </Tooltip>
  )
}
