import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, NumberUtils } from '@pbt/pbt-ui-components'

import { WellnessPlanSavingsData } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      color: theme.colors.secondaryText,
      fontWeight: 500,
      fontSize: '1.6rem',
      borderBottom: 'none',
      padding: theme.spacing(1.75, 2),
    },
    planCell: {
      width: ({ numberOfPlans }: UseStylesProps) => `${45 / numberOfPlans}%`,
    },
    savingsCell: {
      width: '40%',
    },
    emptyCell: {
      width: '15%',
    },
  }),
  { name: 'MembershipSavingsSummaryTable' },
)

export interface MembershipSavingsSummaryTableProps {
  savingsData: WellnessPlanSavingsData | Nil
}

interface UseStylesProps {
  numberOfPlans: number
}

const MembershipSavingsSummaryTable = ({
  savingsData,
}: MembershipSavingsSummaryTableProps) => {
  const { plans = [], totals = [] } = savingsData || {}

  const classes = useStyles({ numberOfPlans: plans.length })
  const { t } = useTranslation('Common')

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            className={classNames(classes.tableCell, classes.savingsCell)}
          >
            {t('Common:SAVINGS')}
          </TableCell>
          {plans?.map((plan) => {
            const total = totals.find(({ level }) => level === plan.level)
            return (
              <TableCell
                className={classNames(classes.tableCell, classes.planCell)}
                key={plan.level}
              >
                {total ? NumberUtils.formatMoney(total.amount) : ''}
              </TableCell>
            )
          })}
          <TableCell
            className={classNames(classes.tableCell, classes.emptyCell)}
          />
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default MembershipSavingsSummaryTable
