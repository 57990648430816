import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { StateLabelProps, Utils } from '@pbt/pbt-ui-components'

import { getPrescriptionStates } from '~/store/reducers/constants'

import AccentLabel from './AccentLabel'

const useStyles = makeStyles(
  () => ({
    stateLabel: {
      width: 'fit-content',
    },
  }),
  { name: 'PrescriptionStateLabel' },
)
export interface PrescriptionStateLabelProps
  extends Pick<StateLabelProps, 'variant'> {
  className?: string
  stateId: string
  stateName?: string
}

export const PrescriptionStateLabel = ({
  className,
  stateId,
  stateName: stateNameProp,
  variant,
}: PrescriptionStateLabelProps) => {
  const classes = useStyles()

  const PrescriptionStatesConstant = useSelector(getPrescriptionStates)

  const stateName =
    stateNameProp || Utils.getConstantName(stateId, PrescriptionStatesConstant)
  const StateConstant = Utils.findById(stateId, PrescriptionStatesConstant)

  return (
    <AccentLabel
      className={classNames(className, classes.stateLabel)}
      colorVariant={StateConstant?.colorVariant}
      name={stateName}
      variant={variant}
    />
  )
}
