import { Moment } from 'moment'
import { moment } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

export type DueDate = {
  getDateRange: () => Array<Moment | null>
  id: string
  name: string
}

export type CommonDueDatesMap = {
  [key: string]: DueDate
}

export const CommonDueDates: CommonDueDatesMap = {
  TODAY: {
    id: 'today',
    name: i18n.t('Time:LABEL.TODAY'),
    getDateRange: () => [moment().startOf('day'), moment().endOf('day')],
  },
  NEXT_3_DAYS: {
    id: 'next3days',
    name: i18n.t('Time:LABEL.NEXT_DAY_OTHER', { daysQty: 3 }),
    getDateRange: () => [moment(), moment().add(3, 'days').endOf('day')],
  },
  NEXT_7_DAYS: {
    id: 'next7days',
    name: i18n.t('Time:LABEL.NEXT_DAY_OTHER', { daysQty: 7 }),
    getDateRange: () => [moment(), moment().add(7, 'days').endOf('day')],
  },
  LAST_3_DAYS: {
    id: 'last3days',
    name: i18n.t('Time:LABEL.LAST_DAY_OTHER', { daysQty: 3 }),
    getDateRange: () => [moment().subtract(3, 'days').startOf('day'), moment()],
  },
  LAST_5_DAYS: {
    id: 'last5days',
    name: i18n.t('Time:LABEL.LAST_DAY_OTHER', { daysQty: 5 }),
    getDateRange: () => [moment().subtract(5, 'days').startOf('day'), moment()],
  },
  LAST_7_DAYS: {
    id: 'last7days',
    name: i18n.t('Time:LABEL.LAST_DAY_OTHER', { daysQty: 7 }),
    getDateRange: () => [moment().subtract(7, 'days').startOf('day'), moment()],
  },
  LAST_30_DAYS: {
    id: 'last30days',
    name: i18n.t('Time:LABEL.LAST_DAY_OTHER', { daysQty: 30 }),
    getDateRange: () => [
      moment().subtract(30, 'days').startOf('day'),
      moment(),
    ],
  },
  THIS_MONTH: {
    id: 'thisMonth',
    name: i18n.t('Time:LABEL.THIS_MONTH'),
    getDateRange: () => [moment().startOf('month'), moment().endOf('month')],
  },
  ALL_PAST_DUE_DATE: {
    id: 'allPastDue',
    name: i18n.t('Time:LABEL.ALL_PAST_DUE'),
    getDateRange: () => [null, moment()],
  },
}
