import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { silentLoginRequest } from '~/store/actions/auth'
import { broadcastChannel } from '~/utils/broadcastChannel'
import useLogout from '~/utils/useLogout'

export const SyncBrowserTabs = () => {
  const dispatch = useDispatch()

  const logout = useLogout()

  useEffect(() => {
    broadcastChannel.onmessage = (e) => {
      const { action, data } = e

      // Whenever user logout we should update other browser tabs\
      if (action === 'logout') {
        logout('/auth/login', false)
      }

      // Whenever user switches to different user we should update other browser tabs
      if (action === 'silent-login' && data) {
        dispatch(silentLoginRequest(data.email, data.password))
      }
    }
  }, [broadcastChannel])

  return null
}
