import React, { forwardRef, useState } from 'react'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MomentInput } from 'moment'
import { moment, useInterval } from '@pbt/pbt-ui-components'

import Needle from '~/components/common/Needle'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      pointerEvents: 'none',
      left: 92,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above'),
    },
    needleHorizontal: {
      width: `calc(100% - ${theme.spacing(1)})`,
      borderColor: theme.colors.markerHighlighted,
    },
    needleHead: {
      backgroundColor: theme.colors.markerHighlighted,
      width: theme.spacing(1),
      height: theme.spacing(1),
    },
  }),
  { name: 'TimeNeedle' },
)

const NEEDLE_HALF_HEIGHT = 10

export interface TimeNeedleProps {
  endDate: MomentInput
  startDate: MomentInput
  stepInterval: number
}

const TimeNeedle = forwardRef<HTMLDivElement, TimeNeedleProps>(
  function TimeNeedle({ startDate, endDate, stepInterval }, ref) {
    const classes = useStyles()

    const [now, setNow] = useState(moment())
    const theme = useTheme()

    useInterval(() => {
      setNow(moment())
    }, 30000)

    let top
    let bottom

    if (now.isBefore(startDate, 'minutes')) {
      // day has not yet started
      top = NEEDLE_HALF_HEIGHT / 2
    } else if (now.isAfter(endDate, 'minutes')) {
      // day has already ended
      bottom = NEEDLE_HALF_HEIGHT * 1.5
    } else {
      // clinic is working, yay
      top =
        (now.diff(startDate, 'minutes') * theme.constants.schedulerRowHeight) /
          stepInterval +
        NEEDLE_HALF_HEIGHT
    }

    return (
      <Needle
        classes={classes}
        direction="horizontal"
        ref={ref}
        style={{ top, bottom }}
      />
    )
  },
)

export default TimeNeedle
