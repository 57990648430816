import { useSelector } from 'react-redux'
import {
  CustomFieldValidatorState,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import { getDocumentTypes } from '~/store/reducers/constants'
import { getDocument } from '~/store/reducers/documents'
import { InternalDispalyPreference } from '~/types'

export const validateSummarizeInOneLineField = ({
  state: { howToDisplay, summarizeInOneLine },
}: CustomFieldValidatorState) => {
  if (howToDisplay === InternalDispalyPreference.SUMMARIZED) {
    return Validators.notEmptyFormField(summarizeInOneLine)
  }
  return true
}

export const useIsFormTypeDocument = (documentId: string | undefined) => {
  const document = useSelector(getDocument(documentId))
  const DocumentTypes = useSelector(getDocumentTypes)
  const FormType = Utils.findConstantIdByName('Form', DocumentTypes) || {}
  return document?.type === FormType
}
