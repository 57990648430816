import { AppointmentEventType, EventTypeName } from '@pbt/pbt-ui-components'

import { getConstantsStringWithAll } from '~/utils'
import { useEventType } from '~/utils/useEventType'

export type AppointmentTypesCellItem = {
  appointmentTypeIds: string[]
}

const AppointmentTypesCell = (item: AppointmentTypesCellItem) => {
  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )

  return getConstantsStringWithAll(item.appointmentTypeIds, AppointmentSubTypes)
}

export default AppointmentTypesCell
