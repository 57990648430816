import { gql } from '@apollo/client/core'

export const CREATE_ATTACHMENT_HISTORY_GROUP = gql`
  mutation CreateAttachmentHistoryGroup(
    $patientId: ID!
    $attachmentHistoryGroup: CreateAttachmentHistoryGroupInput!
  ) {
    createAttachmentHistoryGroup(
      patientId: $patientId
      attachmentHistoryGroup: $attachmentHistoryGroup
    ) {
      id
    }
  }
`
