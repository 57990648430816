import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { Business, Field, PermissionArea } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'

import MessageWithPreviewConfiguration from '../wellness-plans/MessageWithPreviewConfiguration'

export interface AutoReplySubSectionProps {
  business: Business
  maxLength: number
  messageField: Field
  onPreview: () => void
  toggleField: Field
  tooltipText: string
  withTextFormat: boolean
}

export const AutoReplySubSection = ({
  business,
  maxLength,
  messageField,
  onPreview,
  toggleField,
  tooltipText,
  withTextFormat,
}: AutoReplySubSectionProps) => {
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  return (
    <Box>
      <PuiSwitch
        disabled={!permissions.update}
        field={toggleField}
        label={toggleField.label}
      />
      {toggleField.value && (
        <MessageWithPreviewConfiguration
          resetStateOnValueChange
          showDynamicText
          disabled={!permissions.update}
          field={messageField}
          maxEditorHeight={300}
          maxLength={maxLength}
          minEditorHeight={100}
          showButtons={withTextFormat}
          tooltipText={tooltipText}
          tooltipTitle={messageField.label}
          onPreview={onPreview}
        />
      )}
    </Box>
  )
}
