import {
  AtLeast,
  Business,
  BusinessBrandingConfiguration,
  BusinessLicense,
  Defaults,
  Nil,
} from '@pbt/pbt-ui-components'

import { BusinessDto } from '~/store/dto/Business'
import { BaseSearchRequestParams, BusinessSearchParameters } from '~/types'

import { getAPIPath, request } from './core'
import {
  QueryBusinessArgs,
  SsoIdpSettingsInput,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  CREATE_BUSINESS_SSO_IDP_SETTINGS,
  DELETE_BUSINESS_SSO_IDP_SETTINGS,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS,
} from './graphql/mutations/businesses'
import {
  FETCH_BUSINESS_SOAP_WIDGETS,
  FETCH_BUSINESS_SSO_IDP_SETTINGS,
} from './graphql/queries/business'
import * as Schema from './schemas'

export const searchBusinesses = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
  fieldsQuery: string,
  grouping: boolean,
  sortFields?: string,
  resolvePermissionsInSearch?: boolean,
  sharingResult?: BusinessSearchParameters['sharingResult'],
) =>
  request(
    getAPIPath('businesses/searchFull'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        fieldsQuery,
        grouping,
        from,
        to,
        sortFields,
        resolvePermissionsInSearch,
        sharingResult,
      },
    },
    true,
    Schema.businessesList,
  )

export const fetchBusinesses = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('v2/businesses'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.businessesList,
  )

export const suggestBusinesses = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('businesses/suggest'),
    { method: 'GET', params: { businessId, query, max } },
    true,
  )

export const editFavoriteBusinessList = (
  businessId: string,
  favouriteBusinessIds: string,
) =>
  request(
    getAPIPath('persons/favouriteBusinesses'),
    {
      method: 'PUT',
      params: { businessId, businessIds: favouriteBusinessIds },
    },
    true,
  )

export const fetchAssignedPractices = (
  businessId: string,
  from = 0,
  to = 9999,
) =>
  request(
    getAPIPath('v2/businesses/all_assigned'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.businessesList,
  )

export const fetchBusiness = (
  currentBusinessId: string,
  businessId: string,
  includeChildren?: boolean,
  includeSignatureCheck?: boolean,
) =>
  request(
    getAPIPath(`businesses/${businessId}`),
    {
      method: 'GET',
      params: {
        businessId: currentBusinessId,
        includeChildren,
        includeSignatureCheck,
      },
    },
    true,
    Schema.business,
  )

export const deleteBusinessGroup = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getAPIPath(`businesses/${businessId}/group`),
    { method: 'DELETE', params: { businessId: currentBusinessId } },
    true,
  )

export const fetchBusinessGroupTags = (
  currentBusinessId: string,
  groupId: string,
) =>
  request(
    getAPIPath(`v2/businesses/tags/group/${groupId}`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
  )

export const createBusiness = (
  businessId: string,
  business: Business | BusinessDto,
  creatorRoleId: string | Nil,
) =>
  request(
    getAPIPath('businesses'),
    { method: 'POST', data: business, params: { businessId, creatorRoleId } },
    true,
    Schema.business,
  )

export const updateBusiness = (
  businessId: string,
  business: AtLeast<Business, 'id'>,
) =>
  request(
    getAPIPath(`businesses/${business.id}`),
    { method: 'PUT', data: business, params: { businessId } },
    true,
    Schema.business,
  )

export const updateBusinessStatus = (
  currentBusinessId: string,
  targetBusinessId: string | Nil,
  statusId: string,
) =>
  request(
    getAPIPath(`businesses/${targetBusinessId}/status/${statusId}`),
    { method: 'PUT', params: { businessId: currentBusinessId } },
    true,
  )

export const createBusinessLicense = (
  currentBusinessId: string,
  businessId: string,
  license: BusinessLicense,
) =>
  request(
    getAPIPath(`businesses/${businessId}/license`),
    { method: 'PUT', data: license, params: { businessId: currentBusinessId } },
    true,
  )

export const deleteBusinessLicense = (
  currentBusinessId: string,
  businessId: string,
  licenseId: string,
) =>
  request(
    getAPIPath(`businesses/${businessId}/license/${licenseId}`),
    { method: 'DELETE', params: { businessId: currentBusinessId } },
    true,
  )

export const fetchBusinessBrandingConfiguration = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getAPIPath(`branding/${businessId}`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
  )

export const editBusinessBrandingConfiguration = (
  currentBusinessId: string,
  businessId: string,
  brandingConfiguration: BusinessBrandingConfiguration,
) =>
  request(
    getAPIPath(`branding/${businessId}`),
    {
      method: 'PUT',
      data: brandingConfiguration,
      params: { businessId: currentBusinessId },
    },
    true,
  )

export const updateCountryCatalogCode = (
  currentBusinessId: string,
  businessId: string,
  code: string,
) =>
  request(
    getAPIPath(`businesses/${businessId}/countryCatalogCode`),
    {
      method: 'PUT',
      data: code,
      params: { businessId: currentBusinessId },
      headers: { 'Content-Type': 'text/plain' },
    },
    true,
  )

export const fetchBusinessSoapWidgets = requestQuery({
  query: FETCH_BUSINESS_SOAP_WIDGETS,
  variablesHandler: (_, { id }: QueryBusinessArgs) => ({
    id,
  }),
})

export const fetchBusinessSsoIdpSettings = requestQuery({
  query: FETCH_BUSINESS_SSO_IDP_SETTINGS,
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const createBusinessSsoIdpSettings = requestMutation({
  mutation: CREATE_BUSINESS_SSO_IDP_SETTINGS,
  variablesHandler: (
    _: string,
    businessId: string,
    input: SsoIdpSettingsInput,
  ) => ({
    input,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const updateBusinessSsoIdpSettings = requestMutation({
  mutation: UPDATE_BUSINESS_SSO_IDP_SETTINGS,
  variablesHandler: (
    _: string,
    businessId: string,
    input: SsoIdpSettingsInput,
  ) => ({
    input,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const deleteBusinessSsoIdpSettings = requestMutation({
  mutation: DELETE_BUSINESS_SSO_IDP_SETTINGS,
  variablesHandler: (
    _: string,
    businessId: string,
    ssoIdpSettingsId: string,
  ) => ({
    id: ssoIdpSettingsId,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})
