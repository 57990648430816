import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorTooltip, Text, TextProps } from '@pbt/pbt-ui-components'

export interface RequiredFilesNoticeProps extends TextProps {
  hasRequiredErrors?: boolean
}
const RequiredFieldsNotice = ({
  hasRequiredErrors,
  ...rest
}: RequiredFilesNoticeProps) => {
  const { t } = useTranslation(['Common', 'Validations'])

  return (
    <ErrorTooltip
      message={t('Validations:REQUIRED_FIELDS')}
      open={hasRequiredErrors}
    >
      <Text variant="lowAccent4" {...rest}>
        * {t('Common:REQUIRED_FIELDS')}
      </Text>
    </ErrorTooltip>
  )
}
export default RequiredFieldsNotice
