import { gql } from '@apollo/client'

import {
  INVOICE_LINE_ITEM_FIELDS,
  RETAIL_ORDER_LINE_ITEM_FIELDS,
} from '../fragments'
import { INVOICE_FIELDS } from '../fragments/invoice'

export const POST_INVOICE = (includeRetailOrderLineItems: boolean) => gql`
  ${INVOICE_FIELDS(includeRetailOrderLineItems)}
  mutation PostInvoice($input: PostInvoiceInput!) {
    postInvoice(input: $input) {
      ...InvoiceFields
    }
  }
`
export const VOID_INVOICE = (includeRetailOrderLineItems: boolean) => gql`
  ${INVOICE_FIELDS(includeRetailOrderLineItems)}
  mutation VoidInvoice($invoiceId: ID!, $voidedById: ID!) {
    voidInvoice(invoiceId: $invoiceId, voidedById: $voidedById) {
      ...InvoiceFields
    }
  }
`
export const UPDATE_INVOICE_LINE_ITEM_PRODUCER_BATCH = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  mutation UpdateInvoiceLineItemProducer(
    $input: [InvoiceLineItemProducerInput!]!
  ) {
    updateInvoiceLineItemProducerBatch(input: $input) {
      ...InvoiceLineItemFields
    }
  }
`

export const UPDATE_RETAIL_ORDER_LINE_ITEM_PRODUCER_BATCH = gql`
  ${RETAIL_ORDER_LINE_ITEM_FIELDS}
  mutation UpdateRetailOrderLineItemProducerBatch(
    $input: [RetailOrderLineItemProducerInput!]!
  ) {
    updateRetailOrderLineProducerBatch(input: $input) {
      ...RetailOrderLineItemFields
    }
  }
`

export const GENERATE_HTML_INVOICE = gql`
  mutation GenerateHtmlForInvoice(
    $businessId: ID!
    $invoiceId: ID!
    $expandedGroups: [GroupIdentifier!]
    $includeServiceFee: Boolean
  ) {
    generateHtmlForInvoice(
      businessId: $businessId
      invoiceId: $invoiceId
      expandedGroups: $expandedGroups
      includeServiceFee: $includeServiceFee
    ) {
      title
      content
    }
  }
`
export const UPDATE_INVOICE_STATE_AND_INTERNAL_NOTE = (
  includeRetailOrderLineItems: boolean,
) => gql`
  ${INVOICE_FIELDS(includeRetailOrderLineItems)}
  mutation UpdateInvoiceStateAndInternalNote(
    $businessId: ID
    $invoiceId: ID!
    $expectedModification: DateTime!
    $input: InvoiceStateAndInternalNoteInput!
  ) {
    updateInvoiceStateAndInternalNote(
      businessId: $businessId
      invoiceId: $invoiceId
      expectedModification: $expectedModification
      input: $input
    ) {
      ...InvoiceFields
    }
  }
`
