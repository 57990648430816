import { AtLeast, User } from '@pbt/pbt-ui-components'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const createCoparent = (
  businessId: string,
  clientId: string,
  coparent: Omit<User, 'id'>,
) =>
  request(
    getAPIPath(`clients/${clientId}/coparents`),
    { method: 'POST', data: coparent, params: { businessId } },
    true,
    Schema.user,
  )

export const updateCoparent = (
  businessId: string,
  clientId: string,
  coparent: AtLeast<User, 'id'>,
) =>
  request(
    getAPIPath(`clients/${clientId}/coparents/${coparent.id}`),
    { method: 'PUT', data: coparent, params: { businessId } },
    true,
    Schema.user,
  )

export const deleteCoparent = (
  businessId: string,
  clientId: string,
  coparentId: string,
) =>
  request(
    getAPIPath(`clients/${clientId}/coparents/${coparentId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )
