import { Nil } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { LabelRestriction } from '~/types'
import { PrintFormattedData } from '~/types/entities/print'
import { stringDivider } from '~/utils'

const WORD_LENGTH = 6

export const getFormattedLabelClipboardContent = ({
  patientName,
  patientSpecies,
  drugName,
  drugStrength,
  drugForm,
  prescriptionNumber,
  expirationDateString,
  instructions,
  quantityRow,
  businessName,
  doctorName,
  businessAddress,
  businessPhone,
  bottomWarning,
}: PrintFormattedData): string =>
  [
    `${i18n.t('Common:PATIENT')}: ${patientName} (${patientSpecies})`,
    `${drugName} ${drugStrength}`,
    `${drugForm} ${prescriptionNumber} ${expirationDateString}`,
    instructions,
    quantityRow,
    businessName,
    doctorName,
    businessAddress,
    `${i18n.t('Common:PHONE')}: ${businessPhone}`,
    bottomWarning,
  ].join('\n')

export const getScale = (
  str: string = '',
  restriction: LabelRestriction | Nil,
): number => {
  const { rows, length } = restriction || {}
  return rows && length && str.length > rows * (length - WORD_LENGTH)
    ? Math.max(Math.sqrt(str.length / (rows * (length - WORD_LENGTH))), 1)
    : 1
}

const getRowsScale = (
  str: string = '',
  restriction: LabelRestriction,
): number => {
  const { rows } = restriction || {}
  const scale = getScale(str, restriction)
  return scale > 1 ? (Math.ceil(rows * scale) * 1.05) / rows : 1
}

const applyRestriction = (str: string = '', restriction: LabelRestriction) => {
  const { rows, length } = restriction || {}
  if (!rows || rows === 1) {
    return str
  }
  const scale = getRowsScale(str, restriction)
  const newLength = scale * length
  const strRows = stringDivider(str, newLength).split('\n')
  return strRows.join('\n')
}

export const applyRestrictions = (
  labelData: PrintFormattedData = {},
  restrictions: Record<string, LabelRestriction | any> = {},
) => ({
  ...labelData,
  instructions: applyRestriction(
    labelData.instructions?.trim(),
    restrictions.instructions,
  ),
  businessAddress: applyRestriction(
    labelData.businessAddress?.trim(),
    restrictions.address,
  ),
  bottomWarning: applyRestriction(
    labelData.bottomWarning?.trim(),
    restrictions.bottomWarning,
  ),
})

export const enableFitMode = (xml: string, name: string): string => {
  const textObjectRegex = /<TextObject>(.*?)<\/TextObject>/gms
  const textObjects = xml?.match(textObjectRegex) || []
  const instructionsTextObject = textObjects.find(
    (textObject) => textObject.indexOf(`<Name>${name}</Name>`) >= 0,
  )
  const newInstructionsTextObject = instructionsTextObject?.replace(
    '<TextFitMode>None</TextFitMode>',
    '<TextFitMode>AlwaysFit</TextFitMode>',
  )
  return instructionsTextObject && newInstructionsTextObject
    ? xml?.replace(instructionsTextObject, newInstructionsTextObject)
    : xml
}
