import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { MarketplaceItemType } from '~/constants/marketplaceConstants'
import { getMarketplaceType } from '~/store/reducers/constants'
import { boolToYesNoString } from '~/utils'

export interface MarketplaceActiveCellProps {
  active: boolean
  typeId: string
}

const MarketplaceActiveCell = ({
  active,
  typeId,
}: MarketplaceActiveCellProps) => {
  const MarketplaceType = useSelector(getMarketplaceType)
  const isFullIntegration =
    Utils.getConstantName(typeId, MarketplaceType) ===
    MarketplaceItemType.EXTERNAL

  return isFullIntegration || R.isNil(active) ? '' : boolToYesNoString(active)
}

export default MarketplaceActiveCell
