import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  Nil,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { OnHandStatusColor } from '~/constants/onHandConstants'
import { getInventoryOnHandStatus } from '~/store/reducers/constants'

const useStyles = makeStyles(
  () => ({
    stateLabel: {
      width: 'fit-content',
      textAlign: 'center',
      borderRadius: 6,
    },
  }),
  { name: 'OnHandStatusLabel' },
)

export interface OnHandStatusLabelProps extends StateLabelProps {
  statusId: string | Nil
}

const OnHandStatusLabel = ({ statusId, ...rest }: OnHandStatusLabelProps) => {
  const classes = useStyles()

  const InventoryOnHandStatus = useSelector(getInventoryOnHandStatus)
  const statusName = Utils.getConstantName(statusId, InventoryOnHandStatus, '')
  const statusDisplayName = LanguageUtils.getConstantTranslatedName(
    statusId,
    InventoryOnHandStatus,
    '',
  )
  const color = OnHandStatusColor[statusName]

  return (
    <StateLabel
      className={classes.stateLabel}
      {...(color ? { [color]: true } : {})}
      {...rest}
    >
      {statusDisplayName}
    </StateLabel>
  )
}

export default OnHandStatusLabel
