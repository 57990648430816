import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

const DiscountTooltip = () => {
  const { t } = useTranslation('Tooltips')

  return (
    <Tooltip placement="top" title={t<string>('Tooltips:DISCOUNT_NOT_ALLOWED')}>
      <Text variant="body2">&mdash;</Text>
    </Tooltip>
  )
}

export default DiscountTooltip
