import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WarningIcon from '@mui/icons-material/Warning'
import { Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LinkButton, Nil, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  patientMembershipsSelectors,
  // @ts-ignore
} from '~/store/duck/patientMemberships'
import { Membership } from '~/types'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles({
  root: {
    border: 'solid',
    borderColor: '#E02020',
  },
  alertText: {
    color: '#E02020',
    fontSize: '14px',
  },
})

interface CancelMembershipFailureAlertProps {
  clientId?: string | Nil
  isRefund?: boolean
  patientId?: string | Nil
  setCloseOnCancelledOn: () => void
}

const CancelMembershipFailureAlert = ({
  patientId,
  clientId,
  isRefund,
  setCloseOnCancelledOn,
}: CancelMembershipFailureAlertProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Payments')

  const membership: Membership | undefined = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )

  const [openPatientMembershipPaymentLinkDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP_PAYMENT_LINK,
  )

  if (!clientId || !patientId || !membership) {
    return <></>
  }

  const handleSendUpdateLink = () => {
    setCloseOnCancelledOn()
    openPatientMembershipPaymentLinkDialog({
      clientId,
      plan: membership?.plans?.[0],
      patientId,
    })
  }

  return (
    <Alert className={classes.root} icon={<WarningIcon />} severity="error">
      <Text strong className={classes.alertText} color="warning" mb={0.5}>
        {isRefund
          ? t('Payments:UNABLE_TO_PROCESS_REFUND')
          : t('Payments:UNABLE_TO_PROCESS_PAYMENT')}
      </Text>
      <Text className={classes.alertText}>
        {t('Payments:PLEASE_TRY_AGAIN_SEND_UPDATE_LINK')}
      </Text>
      <LinkButton onClick={() => handleSendUpdateLink()}>
        <Text underline className={classes.alertText} pl={1}>
          {t('Payments:SEND_UPDATE_PAYMENT_METHOD_LINK')}
        </Text>
      </LinkButton>
    </Alert>
  )
}

export default CancelMembershipFailureAlert
