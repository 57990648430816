import React from 'react'
import { useTranslation } from 'react-i18next'
import { BasePuiDialogProps, PuiCheckbox } from '@pbt/pbt-ui-components'

import { CheckboxGroupState } from '~/types'

import { SelectionGroupDetails } from './SelectionGroupDetails'

const InputComponent = () => (
  <PuiCheckbox disabled labelProps={{ sx: { marginRight: 0 } }} />
)

interface MultipleSelectDialogProps extends BasePuiDialogProps {
  documentElement?: CheckboxGroupState
  onSave: (data: CheckboxGroupState) => void
}

// eslint-disable-next-line react/no-multi-comp
export const MultipleSelectDialog = ({
  open,
  onClose,
  onSave,
  documentElement,
}: MultipleSelectDialogProps) => {
  const { t } = useTranslation('Dialogs')

  return (
    <SelectionGroupDetails
      InputComponent={InputComponent}
      documentElement={documentElement}
      header={t('Dialogs:MULTIPLE_SELECT_DIALOG.ADD_CHECKBOXES')}
      open={open}
      subheader={t(
        'Dialogs:MULTIPLE_SELECT_DIALOG.USER_CAN_SELECT_MULTIPLE_OPTIONS',
      )}
      onClose={onClose}
      onSave={onSave}
    />
  )
}
