import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BaseUser, DateUtils, Nil, Text, Utils } from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { AlertColorMap, AlertColors } from '~/constants/alertColors'
import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import {
  getInvoiceSectionTitle,
  useGetInvoiceV3ItemHeader,
} from '~/store/hooks/invoiceV3'
import {
  getInvoiceV3Current,
  getInvoiceV3SubItemById,
} from '~/store/reducers/invoiceV3'
import { getUser } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme) => ({
    tableTitle: {
      color: theme.colors.tabLabel,
      padding: theme.spacing(1, FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      paddingLeft: ({ useInvoiceCVCLayout }: UseStylesProps) =>
        theme.spacing(
          useInvoiceCVCLayout ? 2 : FINANCE_TABLE_PADDING_X_SPACING_VALUE,
        ),
      height: FINANCE_TABLE_CELL_HEIGHT,
    },
    alertLabelText: {
      lineHeight: '1.7rem',
      padding: theme.spacing(0.5, 0),
    },
    alertLabelBadgeRoot: {
      justifyContent: 'flex-start',
      backgroundColor: AlertColorMap[AlertColors.GREEN],
    },
    alertLabelToolTip: {
      backgroundColor: theme.colors.selectedItem,
      color: theme.colors.badgeColor,
      '&::before, &::after': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  }),
  { name: 'InvoiceTableSubHeader' },
)

interface UseStylesProps {
  useInvoiceCVCLayout: boolean
}

export type InvoiceTableSubHeaderProps = {
  colSpan?: number
  currentItemId?: string
  useInvoiceCVCLayout?: boolean
}

const InvoiceTableSubHeaderRow = ({
  colSpan,
  finalized,
  soapId,
  title,
  useInvoiceCVCLayout,
}: {
  colSpan: number
  finalized: boolean
  soapId: string | Nil
  title: string
  useInvoiceCVCLayout: boolean
}) => {
  const navigate = useNavigate()
  const classes = useStyles({ useInvoiceCVCLayout })
  const { t } = useTranslation('Common')

  const onClickSoapName = () => {
    if (soapId) {
      navigate(`/soap/${soapId}`)
    }
  }

  if (R.isEmpty(title)) {
    return null
  }

  return (
    <TableRow>
      <TableCell className={classes.tableTitle} colSpan={colSpan}>
        <Grid container alignItems="center">
          <Text
            component="span"
            link={Boolean(soapId)}
            variant="h4"
            onClick={onClickSoapName}
          >
            {title}
          </Text>
          {finalized ? (
            <Grid item padding={{ paddingLeft: '16px' }}>
              <AlertLabel
                showTooltip
                classes={{
                  root: classes.alertLabelBadgeRoot,
                  text: classes.alertLabelText,
                  tooltip: classes.alertLabelToolTip,
                }}
                message={t('Common:FINALIZED')}
                sx={{ mr: 0.5, mb: 0.5 }}
                tooltipMessage={t('Common:CHARGES_NOT_EDITABLE')}
                variant="normal"
              />
            </Grid>
          ) : null}
        </Grid>
      </TableCell>
    </TableRow>
  )
}

// eslint-disable-next-line react/no-multi-comp
const InvoiceTableSubHeader = ({
  colSpan = 6,
  currentItemId,
  useInvoiceCVCLayout = false,
}: InvoiceTableSubHeaderProps) => {
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const invoiceLineItemSection = useSelector(
    getInvoiceV3SubItemById(currentItemId),
  )
  const invoice = useSelector(getInvoiceV3Current)
  const currentInvoiceLineItem = useSelector(
    getInvoiceV3SubItemById(currentItemId),
  )

  const title = useGetInvoiceV3ItemHeader(currentItemId)
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()

  const soapId = invoiceLineItemSection?.soapId
  const finalized = R.path(
    ['soap', 'finalized'],
    invoiceLineItemSection,
  ) as boolean
  const currentROLISection = invoice?.retailOrder?.sections.find(
    (section) => section.id === currentItemId,
  )
  const vet = useSelector(getUser(currentROLISection?.soap?.assignedVetId))

  if (R.isNil(currentItemId)) {
    return null
  }

  if (isChewyCheckoutEnabled && currentROLISection && !currentInvoiceLineItem) {
    const ROLISoapId = currentROLISection.soapId
    const isFinalized = currentROLISection.soap?.finalized ?? false
    const eventName: string =
      currentROLISection.event?.type.name ||
      t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION')
    const date = DateUtils.formatDate(
      currentROLISection.event?.scheduledStartDatetime,
    )
    const doctorUser =
      vet ||
      currentROLISection.event?.resources.find(
        (r) => r.responsibility?.name === ResponsibilityName.Veterinarian,
      )?.employee

    const ROLITitle = getInvoiceSectionTitle({
      date,
      eventName,
      patientName: currentROLISection.patient?.name,
      vetName: Utils.getPersonString(doctorUser as BaseUser),
    })

    return (
      <InvoiceTableSubHeaderRow
        colSpan={colSpan}
        finalized={isFinalized}
        soapId={ROLISoapId}
        title={ROLITitle}
        useInvoiceCVCLayout={useInvoiceCVCLayout}
      />
    )
  }

  return (
    <InvoiceTableSubHeaderRow
      colSpan={colSpan}
      finalized={finalized}
      soapId={soapId}
      title={title}
      useInvoiceCVCLayout={useInvoiceCVCLayout}
    />
  )
}

export default InvoiceTableSubHeader
