import { AnyAction } from 'redux'

import type { RootState } from '../index'

export const PRINT = 'print/PRINT'
export const LOADING_COMPLETE = 'print/LOADING_COMPLETE'
export const PRINTING_COMPLETE = 'print/PRINTING_COMPLETE'

export const print = (fileUrl: string) => ({ type: PRINT, fileUrl })
export const loadingComplete = () => ({ type: LOADING_COMPLETE })
export const printingComplete = () => ({ type: PRINTING_COMPLETE })

export type PrintState = {
  fileUrl: string | undefined
  isLoading: boolean
  isPrinting: boolean
}

const INITIAL_STATE: PrintState = {
  fileUrl: undefined,
  isLoading: false,
  isPrinting: false,
}

export const printReducer = (
  state: PrintState = INITIAL_STATE,
  action: AnyAction,
): PrintState => {
  switch (action.type) {
    case PRINT:
      return {
        ...state,
        fileUrl: action.fileUrl,
        isLoading: true,
        isPrinting: true,
      }
    case LOADING_COMPLETE:
      return {
        ...state,
        isLoading: false,
      }
    case PRINTING_COMPLETE:
      return {
        ...state,
        isPrinting: false,
      }
    default:
      return state
  }
}

export const getPrint = (state: RootState): PrintState => state.print
export const getFileUrl = (state: RootState) => getPrint(state).fileUrl
export const getPrintIsLoading = (state: RootState) => getPrint(state).isLoading
export const getPrintIsPrinting = (state: RootState) =>
  getPrint(state).isPrinting
