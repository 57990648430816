import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { TaskContext } from '~/constants/taskConstants'
import {
  AtLeast,
  TableFilter,
  Task,
  WSTaskCUDBody,
  WSTaskNormalizedBody,
} from '~/types'

import {
  ADD_TASK_TO_SOAP_LIST,
  CLEAR_SOAP_TASKS,
  CLEAR_TASKS_VALIDATION_ERROR,
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  CREATE_TASKS,
  CREATE_TASKS_FAILURE,
  CREATE_TASKS_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  DELETE_TASKS,
  DELETE_TASKS_FAILURE,
  DELETE_TASKS_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_FAILURE,
  EDIT_TASK_STATE,
  EDIT_TASK_STATE_FAILURE,
  EDIT_TASK_STATE_SUCCESS,
  EDIT_TASK_SUCCESS,
  EDIT_TASKS,
  EDIT_TASKS_FAILURE,
  EDIT_TASKS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST_SUCCESS,
  FETCH_SOAP_TASKS,
  FETCH_SOAP_TASKS_FAILURE,
  FETCH_SOAP_TASKS_IGNORED,
  FETCH_SOAP_TASKS_SUCCESS,
  FETCH_TASK,
  FETCH_TASK_FAILURE,
  FETCH_TASK_SUCCESS,
  FETCH_TASKS_LIST,
  FETCH_TASKS_LIST_FAILURE,
  FETCH_TASKS_LIST_SUCCESS,
  PARTIAL_EDIT_TASK,
  PARTIAL_EDIT_TASK_FAILURE,
  PARTIAL_EDIT_TASK_SUCCESS,
  REMOVE_TASK_FROM_LIST,
  SET_TASKS_LIST_FILTERS,
  UPDATE_SOAP_TASKS_SELECTED_DATE,
  UPDATE_TASKS,
  WS_TASK_CREATE,
  WS_TASK_CREATE_FAILURE,
  WS_TASK_CREATE_SUCCESS,
  WS_TASK_DELETE,
  WS_TASK_DELETE_FAILURE,
  WS_TASK_DELETE_SUCCESS,
  WS_TASK_UPDATE,
  WS_TASK_UPDATE_FAILURE,
  WS_TASK_UPDATE_SUCCESS,
} from './types/tasks'

export const fetchTasksList = (
  from: number,
  to: number,
  query?: string | Nil,
) => ({
  type: FETCH_TASKS_LIST,
  from,
  to,
  query,
})
export const fetchTasksListSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_TASKS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchTasksListFailure = (error: ApiError) => ({
  type: FETCH_TASKS_LIST_FAILURE,
  error,
})

export const fetchTask = (id: string) => ({ type: FETCH_TASK, id })
export const fetchTaskFailure = (error: ApiError) => ({
  type: FETCH_TASK_FAILURE,
  error,
})
export const fetchTaskSuccess = () => ({ type: FETCH_TASK_SUCCESS })

export const fetchMoreItemsForTasksList = (
  from: number,
  to: number,
  query?: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_TASKS_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForTasksListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_TASKS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForTasksListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_TASKS_LIST_FAILURE,
  error,
})

export const createTasks = (tasks: Omit<Task, 'id'>[]) => ({
  type: CREATE_TASKS,
  tasks,
})
export const createTasksSuccess = (taskIds: string[]) => ({
  type: CREATE_TASKS_SUCCESS,
  taskIds,
})
export const createTasksFailure = (error: ApiError) => ({
  type: CREATE_TASKS_FAILURE,
  error,
})

export const createTask = (task: Omit<Task, 'id'>) => ({
  type: CREATE_TASK,
  task,
})
export const createTaskSuccess = (taskId: string) => ({
  type: CREATE_TASK_SUCCESS,
  taskId,
})
export const createTaskFailure = (error: ApiError) => ({
  type: CREATE_TASK_FAILURE,
  error,
})

export const editTasks = (tasks: Task[]) => ({ type: EDIT_TASKS, tasks })
export const editTasksSuccess = () => ({ type: EDIT_TASKS_SUCCESS })
export const editTasksFailure = (error: ApiError) => ({
  type: EDIT_TASKS_FAILURE,
  error,
})

export const editTask = (task: Task, updateTasks?: boolean) => ({
  type: EDIT_TASK,
  task,
  updateTasks,
})
export const editTaskSuccess = () => ({ type: EDIT_TASK_SUCCESS })
export const editTaskFailure = (error: ApiError) => ({
  type: EDIT_TASK_FAILURE,
  error,
})

export const partialEditTask = (
  task: AtLeast<Task, 'id'>,
  context?: TaskContext,
) => ({
  type: PARTIAL_EDIT_TASK,
  task,
  context,
})
export const partialEditTaskSuccess = () => ({
  type: PARTIAL_EDIT_TASK_SUCCESS,
})
export const partialEditTaskFailure = (error: ApiError) => ({
  type: PARTIAL_EDIT_TASK_FAILURE,
  error,
})

export const deleteTasks = (taskIds: string[]) => ({
  type: DELETE_TASKS,
  taskIds,
})
export const deleteTasksSuccess = (taskIds: string[]) => ({
  type: DELETE_TASKS_SUCCESS,
  taskIds,
})
export const deleteTasksFailure = (error: ApiError) => ({
  type: DELETE_TASKS_FAILURE,
  error,
})

export const deleteTask = (taskId: string) => ({ type: DELETE_TASK, taskId })
export const deleteTaskSuccess = (taskId: string) => ({
  type: DELETE_TASK_SUCCESS,
  taskId,
})
export const deleteTaskFailure = (error: ApiError) => ({
  type: DELETE_TASK_FAILURE,
  error,
})

export const editTaskState = (taskId: string, stateId: string) => ({
  type: EDIT_TASK_STATE,
  taskId,
  stateId,
})
export const editTaskStateSuccess = () => ({ type: EDIT_TASK_STATE_SUCCESS })
export const editTaskStateFailure = (error: ApiError) => ({
  type: EDIT_TASK_STATE_FAILURE,
  error,
})

export const updateTasks = (tasks: Record<string, Task | Partial<Task>>) => ({
  type: UPDATE_TASKS,
  tasks,
})

export const setTasksListFilters = (filters: Record<string, TableFilter>) => ({
  type: SET_TASKS_LIST_FILTERS,
  filters,
})

export const fetchSoapTasks = (date?: string) => ({
  type: FETCH_SOAP_TASKS,
  date,
})
export const fetchSoapTasksSuccess = (list: string[]) => ({
  type: FETCH_SOAP_TASKS_SUCCESS,
  list,
})
export const fetchSoapTasksFailure = (error: ApiError) => ({
  type: FETCH_SOAP_TASKS_FAILURE,
  error,
})
export const fetchSoapTasksIgnored = () => ({ type: FETCH_SOAP_TASKS_IGNORED })

export const addTaskToSoapList = (taskId: string) => ({
  type: ADD_TASK_TO_SOAP_LIST,
  taskId,
})

export const updateSoapTasksSelectedDate = (date: string | Nil) => ({
  type: UPDATE_SOAP_TASKS_SELECTED_DATE,
  date,
})

export const removeTaskFromList = (taskId: string) => ({
  type: REMOVE_TASK_FROM_LIST,
  taskId,
})

export const clearTasksValidationError = () => ({
  type: CLEAR_TASKS_VALIDATION_ERROR,
})

export const clearSoapTasks = () => ({ type: CLEAR_SOAP_TASKS })

export const wsTaskCreate = (body: WSTaskNormalizedBody) => ({
  type: WS_TASK_CREATE,
  body,
})
export const wsTaskCreateSuccess = (taskId: string) => ({
  type: WS_TASK_CREATE_SUCCESS,
  taskId,
})
export const wsTaskCreateFailure = (error: ApiError) => ({
  type: WS_TASK_CREATE_FAILURE,
  error,
})

export const wsTaskDelete = (body: WSTaskCUDBody) => ({
  type: WS_TASK_DELETE,
  body,
})
export const wsTaskDeleteSuccess = (taskIds: string[]) => ({
  type: WS_TASK_DELETE_SUCCESS,
  taskIds,
})
export const wsTaskDeleteFailure = (error: ApiError) => ({
  type: WS_TASK_DELETE_FAILURE,
  error,
})

export const wsTaskUpdate = (body: WSTaskNormalizedBody) => ({
  type: WS_TASK_UPDATE,
  body,
})
export const wsTaskUpdateSuccess = (tasks: string[]) => ({
  type: WS_TASK_UPDATE_SUCCESS,
  tasks,
})
export const wsTaskUpdateFailure = (error: ApiError) => ({
  type: WS_TASK_UPDATE_FAILURE,
  error,
})
