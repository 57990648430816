import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PuiSelect, useFields } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  createImportTask,
  fetchMigrationSupportedEntities,
  getCurrentImportRequestId,
  getCurrentSession,
  getIsCreatingImportTask,
  getIsLoadingSupportedEntitites,
  getSessionSupportedEntities,
} from '~/store/duck/migrationV2'
import {
  BaseStepComponentProps,
  MigrationTaskType,
} from '~/types/entities/migrationV2'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import EntityTypesSelectList from '../core-migration/EntityTypesSelectList'
import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const importTaskTypeToLabel: Record<MigrationTaskType, string> = {
  [MigrationTaskType.ANALYSIS]: i18n.t(
    'Businesses:MIGRATION.IMPORT_TASK_TYPE.ANALYSIS',
  ),
  [MigrationTaskType.CONVERSION]: i18n.t(
    'Businesses:MIGRATION.IMPORT_TASK_TYPE.CONVERSION',
  ),
  [MigrationTaskType.IMPORT]: i18n.t(
    'Businesses:MIGRATION.IMPORT_TASK_TYPE.IMPORT',
  ),
}

const ImportTaskCreation = ({
  stepController,
  onNext,
}: BaseStepComponentProps) => {
  const dispatch = useDispatch()
  const [isInitialized, setIsInitialized] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState<string[]>([])
  const { t } = useTranslation(['Common', 'Businesses'])

  const isLoading = useSelector(getIsLoadingSupportedEntitites)
  const currentSession = useSelector(getCurrentSession)
  const currentImportRequestId = useSelector(getCurrentImportRequestId)
  const supportedEntities = useSelector(getSessionSupportedEntities)

  // Fetch availble session entities on mount
  useEffect(() => {
    if (!isInitialized && currentSession) {
      setIsInitialized(true)
      dispatch(
        fetchMigrationSupportedEntities({ sessionId: currentSession.id }),
      )
    }
  }, [isInitialized, currentSession])

  const selectableImportTaskTypes = Object.values(MigrationTaskType).map(
    (key) => ({
      id: key,
      name: key,
      nameTranslation: importTaskTypeToLabel[key],
    }),
  )

  const {
    fields: { selectedImportTaskType },
  } = useFields(
    [
      {
        name: 'selectedImportTaskType',
        initialValue: undefined,
        type: 'select',
      },
    ],
    false,
  )

  const hasEntities =
    selectedImportTaskType.value !== MigrationTaskType.CONVERSION

  const handleProceed = () => {
    if (onNext) {
      onNext()
      return
    }
    stepController.next()
  }

  const proceedAfterCreation = useCloseAfterCreation(
    handleProceed,
    getIsCreatingImportTask,
  )

  const handleCreateImportTask = () => {
    if (!currentImportRequestId) {
      return
    }

    dispatch(
      createImportTask({
        requestId: currentImportRequestId,
        type: selectedImportTaskType.value,
        entities: hasEntities ? selectedEntities : undefined,
      }),
    )
    proceedAfterCreation()
  }

  return (
    <MigrationStep>
      <MigrationStepContent maxHeight={320}>
        {selectedImportTaskType.value &&
        selectedImportTaskType.value !== MigrationTaskType.CONVERSION ? (
          <EntityTypesSelectList
            isLoading={isLoading}
            sessionSupportedEntities={supportedEntities}
            onSelectedEntitiesChanged={setSelectedEntities}
          />
        ) : (
          <PuiSelect
            fullWidth
            field={selectedImportTaskType}
            items={selectableImportTaskTypes}
            placeholder={t('Businesses:MIGRATION.SELECT_IMPORT_TASK_TYPE')}
            variant="filled"
          />
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:CANCEL_ACTION')}
        </MigrationStepActionButton>
        <MigrationStepActionButton
          disabled={!selectedImportTaskType.value}
          onClick={handleCreateImportTask}
        >
          {t('Common:PROCEED')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default ImportTaskCreation
