import { gql } from '@apollo/client'

import { REMINDER_FIELDS } from '../fragments'

export const UPDATE_REMINDER = gql`
  ${REMINDER_FIELDS}
  mutation UpdateReminder($id: ID!, $input: UpdateReminderInput!) {
    updateReminder(id: $id, input: $input) {
      ...ReminderFields
    }
  }
`
