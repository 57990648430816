import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { InfiniteLoaderList, Text } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { MIGRATION_EXCEPTIONS_BATCH_SIZE } from '~/constants/migration'
import { clearMappings, fetchMoreMappings } from '~/store/actions/migration'
import { getConstantMap } from '~/store/reducers/constants'
import {
  getCurrentSessionId,
  getFocusedMappingRowIndex,
  getTotalMappings,
} from '~/store/reducers/migration'
import {
  MigrationException,
  MigrationStructure,
  ProposedColumn,
} from '~/types/entities/migration'

import MigrationExceptionTableRow from './MigrationExceptionTableRow'
import { getActualColumns, getProposedColumns } from './utils'

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    checkboxCell: {
      padding: 0,
      width: 52,
    },
    headingCell: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
      fontWeight: 500,
      padding: theme.spacing(1, 0),
    },
    scopeCell: {
      width: 136,
    },
  }),
  { name: 'MigrationExceptionsTable' },
)

interface MigrationExceptionsTableProps {
  config: MigrationStructure
  exceptions: MigrationException[]
  isEnterprise: boolean
}

const MigrationExceptionsTable = ({
  config,
  exceptions,
  isEnterprise,
}: MigrationExceptionsTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const [isInitialized, setIsInitialized] = useState(false)
  const [includeResolved, setIncludeResolved] = useState(false)

  const Constants = useSelector(getConstantMap)
  const sessionId = useSelector(getCurrentSessionId)
  const totalCount = useSelector(getTotalMappings)
  const focusedListItem = useSelector(getFocusedMappingRowIndex)

  const { field, entity, humanReadableName } = config

  useEffect(() => {
    if (!isInitialized) {
      dispatch(
        fetchMoreMappings(
          sessionId,
          field,
          entity,
          0,
          30,
          includeResolved,
          isEnterprise,
        ),
      )
      setIsInitialized(true)
    }
  }, [isInitialized])
  // @ts-ignore
  useEffect(() => () => dispatch(clearMappings()), [])

  const toggleIncludeResolved = () => {
    dispatch(clearMappings())
    setIsInitialized(false)
    setIncludeResolved(!includeResolved)
  }

  const actualColumns = getActualColumns(config)
  const proposedColumns = getProposedColumns(config, Constants)

  const columns = [...actualColumns, ...proposedColumns] as ProposedColumn[]

  const isExceptionLoaded = (index: number) => Boolean(exceptions[index])
  const loadMoreItems = (from: number, to: number) => {
    dispatch(
      fetchMoreMappings(
        sessionId,
        field,
        entity,
        from,
        to,
        includeResolved,
        isEnterprise,
      ),
    )
  }

  return (
    <Grid container direction="column">
      <Grid container item justifyContent="space-between" mb={1}>
        <Text variant="h1">{humanReadableName}</Text>
        <PuiSwitch
          checked={includeResolved}
          label={t('Businesses:MIGRATION.INCLUDE_RESOLVED')}
          onChange={toggleIncludeResolved}
        />
      </Grid>
      <Grid container direction="column">
        <Grid container className={classes.tableRow}>
          {columns.map((column) => (
            <Grid
              item
              xs
              className={classes.headingCell}
              key={`${column.id}-header`}
            >
              {column.name}
            </Grid>
          ))}
          <Grid
            item
            className={classNames(classes.headingCell, classes.scopeCell)}
          >
            {t('Businesses:MIGRATION.SCOPE')}
          </Grid>
          <Grid item className={classes.checkboxCell} />
        </Grid>
        <InfiniteLoaderList
          focusedListItem={focusedListItem}
          isItemLoaded={isExceptionLoaded}
          itemCount={totalCount}
          itemData={exceptions}
          itemSize={42}
          loadMoreItems={loadMoreItems}
          minimumBatchSize={MIGRATION_EXCEPTIONS_BATCH_SIZE}
          style={{ height: 400 }}
        >
          {(exception, rowIndex) => (
            <MigrationExceptionTableRow
              columns={columns}
              exception={exception}
              isEnterprise={isEnterprise}
              key={`${entity}-row-${rowIndex}`}
              proposedColumns={proposedColumns}
            />
          )}
        </InfiniteLoaderList>
      </Grid>
    </Grid>
  )
}

export default MigrationExceptionsTable
