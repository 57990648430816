import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'
import { FeatureElements } from '~/types'

import AddendumsSection from '../../soap/wrap-up/addendum/AddendumsSection'
import MedicalDischargeNotesWidgetLocked from './MedicalDischargeNotesWidgetLocked'
import MedicalDischargeNotesUnlockedWidget from './MedicalDischargeNotesWidgetUnlocked'

export interface MedicalDischargeNotesWidgetProps {
  hideElements?: FeatureElements[]
}

const MedicalDischargeNotesWidget = ({
  hideElements,
}: MedicalDischargeNotesWidgetProps) => {
  const MedicalDischargeNotesWidgetComponent = useLockableComponent(
    MedicalDischargeNotesUnlockedWidget,
    MedicalDischargeNotesWidgetLocked,
  )

  return (
    <>
      <MedicalDischargeNotesWidgetComponent hideElements={hideElements} />
      <AddendumsSection />
    </>
  )
}

export default MedicalDischargeNotesWidget
