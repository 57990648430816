import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  PrescriptionWorkflowName,
  PrescriptionWorkflowType,
} from '~/constants/PrescriptionWorkflow'

import WorkflowSectionItem from './WorkflowSectionItem'

export interface ChewyReactiveRxWorkflowProps {
  disabled: boolean
  isChewyReactiveRx: boolean | undefined
  showDisabledTooltip?: boolean
}

const workflow = PrescriptionWorkflowType.CHEWY_REACTIVE_RX
const label = PrescriptionWorkflowName[workflow]

const ChewyReactiveRxWorkflow = ({
  disabled: disabledProp,
  isChewyReactiveRx,
  showDisabledTooltip = true,
}: ChewyReactiveRxWorkflowProps) => {
  const { t } = useTranslation('Tooltips')

  const disabled = disabledProp

  if (!isChewyReactiveRx) {
    return null
  }

  return (
    <WorkflowSectionItem
      disabled={disabled}
      disabledTooltip={
        disabled && showDisabledTooltip
          ? t('Tooltips:CHEWY_WORKFLOW_TOOLTIP')
          : ''
      }
      label={label}
      workflow={workflow}
    />
  )
}

export default ChewyReactiveRxWorkflow
