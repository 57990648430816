import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EditorState } from 'draft-js'
import * as R from 'ramda'
import { FileTemplate, Nil } from '@pbt/pbt-ui-components'

import DownloadButton from '~/components/common/buttons/DownloadButton'
import PreviewButton from '~/components/common/buttons/PreviewButton'
import UploadButton from '~/components/common/buttons/UploadButton'
import SingleFileSelector from '~/components/common/inputs/SingleFileSelector'
import FilePreview from '~/components/common/preview/FilePreview'
import { HTML } from '~/constants/extensions'
import { fetchResolvedDocumentBody } from '~/store/actions/documents'
import { getResolvedDocumentBody } from '~/store/reducers/documents'
import { Document } from '~/types'

import { createTemplateFromHtml } from './documentUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    uploadButtonIcon: {
      color: theme.colors.title,
    },
    uploadButtonText: {
      color: theme.colors.title,
    },
  }),
  { name: 'DocumentPreview' },
)

export interface DocumentPreviewHandle {
  getTemplate: () => FileTemplate
  getTemplateReady: () => boolean
}

interface DocumentPreviewProps {
  document: Document | Nil
  editable?: boolean
  fullScreen?: boolean
  hideDownloadButton?: boolean
  hideTopButtons?: boolean
  onFileSelected?: (file: FileTemplate) => void
  onIframeLoad?: () => void
  onPreview?: (document: Document | Nil) => void
  onPreviewChange?: () => void
  previewDisabled?: boolean
  resolvePlaceholders?: boolean
  setIsLoading?: (isLoading: boolean) => void
  showChangeFile?: boolean
  view?: boolean
}

const DocumentPreview = forwardRef<DocumentPreviewHandle, DocumentPreviewProps>(
  function DocumentPreview(
    {
      editable = false,
      view,
      hideTopButtons = false,
      hideDownloadButton = false,
      document: documentProp,
      onPreview,
      onFileSelected,
      showChangeFile = true,
      resolvePlaceholders = false,
      onIframeLoad,
      setIsLoading,
      previewDisabled = false,
      onPreviewChange = R.F,
      fullScreen,
    },
    ref,
  ) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const resolvedDocumentBody = useSelector(getResolvedDocumentBody)
    const { t } = useTranslation('Admin')

    const [document, setDocument] = useState(documentProp)
    const [editorState, setEditorState] = useState<EditorState>()

    const onFileChange = (file: Blob) => {
      setDocument({
        ...document,
        template: {
          ...document?.template,
          file,
        },
      } as Document)
    }

    const preview = () => {
      if (onPreview) {
        onPreview(document)
      }
    }

    const extension = document?.template?.extension || document?.file?.extension
    const isHTML = extension === HTML
    const documentTemplate =
      document?.template || document?.file || (document as FileTemplate)
    const body = documentTemplate?.body
    const useBackendResolvedBody = body && resolvePlaceholders
    const template = useBackendResolvedBody
      ? ({ ...documentTemplate, body: resolvedDocumentBody } as FileTemplate)
      : documentTemplate

    useEffect(() => {
      if (useBackendResolvedBody) {
        dispatch(fetchResolvedDocumentBody(body))
      }
    }, [body])

    useEffect(() => {
      setDocument(documentProp)
    }, [documentProp])

    useImperativeHandle(ref, () => ({
      getTemplate: () =>
        isHTML ? createTemplateFromHtml(editorState) : documentTemplate,
      getTemplateReady: () =>
        isHTML && editable ? Boolean(editorState) : true,
    }))

    useEffect(() => {
      onPreviewChange(editorState)
    }, [editorState])

    return (
      <Grid
        container
        item
        alignItems="flex-start"
        className={classes.root}
        direction="column"
        pl={1}
      >
        {view && !hideTopButtons && (
          <Grid container item columnSpacing={3} pt={1} px={2}>
            {editable && (
              <Grid item>
                <PreviewButton
                  disabled={previewDisabled}
                  fontSize="1.4rem"
                  onClick={preview}
                />
              </Grid>
            )}
            {showChangeFile && (
              <Grid item>
                <SingleFileSelector onSelected={onFileSelected}>
                  <UploadButton
                    label={
                      isHTML
                        ? t('Admin:CATALOG.DOCUMENT_PREVIEW.REPLACE_WITH_FILE')
                        : t('Common:CHANGE_FILE')
                    }
                  />
                </SingleFileSelector>
              </Grid>
            )}
            {!isHTML && !hideDownloadButton && (
              <Grid item>
                <DownloadButton
                  classes={{
                    icon: classes.uploadButtonIcon,
                    text: classes.uploadButtonText,
                  }}
                  href={template?.fileUrl || ''}
                  label={t('Admin:CATALOG.DOCUMENT_PREVIEW.DOWNLOAD_FILE')}
                />
              </Grid>
            )}
          </Grid>
        )}
        <FilePreview
          editable={editable}
          editorState={editorState}
          file={template}
          fullScreen={fullScreen}
          setIsLoading={setIsLoading}
          onEditorStateChange={setEditorState}
          onFileChange={onFileChange}
          onIFrameLoad={onIframeLoad}
        />
      </Grid>
    )
  },
)

export default DocumentPreview
