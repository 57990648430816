import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { ClassesType, DateUtils, Text } from '@pbt/pbt-ui-components'

import EstimateStateLabel from '~/components/dashboard/invoices/EstimateStateLabel'
import {
  getHighValue,
  getLowValue,
} from '~/components/dashboard/invoices/invoiceUtils'
import { Estimate } from '~/types'

import EstimateName from '../table/EstimateName'
import EstimateUsedCell from '../table/EstimateUsedCell'
import MoreEstimateInfoDropdown from '../table/MoreEstimateInfoDropdown'

interface UseEstimatesColumnsProps {
  actionsAnchorElement: HTMLElement | null
  activeActionItem: string | null
  classes: ClassesType<any>
  setActionsAnchorElement: (element: HTMLElement | null) => void
  setActiveActionItem: (item: string | null) => void
}

export const useEstimatesColumns = ({
  actionsAnchorElement,
  activeActionItem,
  setActionsAnchorElement,
  setActiveActionItem,
  classes,
}: UseEstimatesColumnsProps) => {
  const { t } = useTranslation(['Common'])

  const mainColumn = {
    label: t('Common:CREATED'),
    value: (estimate: Estimate) => (
      <Text pl={1.5} variant="body2">
        {DateUtils.formatDate(estimate?.creationDate)}
      </Text>
    ),
    width: '8%',
    classNameRow: classes.cell,
    classNameHeader: classNames(
      classes.createdDateHeaderCell,
      classes.spacingCell,
    ),
    id: 'estm_created_column',
  }

  const columns = [
    {
      id: 'estm_expires_column',
      label: t('Common:EXPIRES'),
      value: (estimate: Estimate) =>
        estimate?.expirationDate
          ? DateUtils.formatDate(estimate?.expirationDate)
          : '',
      width: '8%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
    {
      id: 'estm_name_column',
      label: t('Common:ESTIMATE_BUDGET'),
      value: (estimate: Estimate) => <EstimateName estimate={estimate} />,
      width: '28%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
    {
      id: 'estm_status_column',
      label: t('Common:STATUS'),
      value: (estimate: Estimate) => (
        <EstimateStateLabel
          className={classes.cellStatusLabel}
          estimate={estimate}
        />
      ),
      width: '10%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
    {
      id: 'estm_low_total_column',
      label: t('Common:LOW_TOTAL'),
      value: (estimate: Estimate) => getLowValue(estimate.amountNoFee),
      width: '8%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
    {
      id: 'estm_high_total_column',
      label: t('Common:HIGH_TOTAL'),
      value: (estimate: Estimate) => getHighValue(estimate.amountNoFee),
      width: '8%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
    {
      id: 'estm_used_column',
      label: t('Common:USED'),
      value: (estimate: Estimate) => <EstimateUsedCell estimate={estimate} />,
      width: '25%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
    {
      label: '',
      id: 'estm_more_column',
      value: (estimate: Estimate) => (
        <MoreEstimateInfoDropdown
          actionsAnchorElement={actionsAnchorElement}
          activeActionItem={activeActionItem}
          estimate={estimate}
          setActionsAnchorElement={setActionsAnchorElement}
          setActiveActionItem={setActiveActionItem}
        />
      ),
      width: '5%',
      classNameRow: classes.cell,
      classNameHeader: classes.spacingCell,
    },
  ]

  return { columns, mainColumn }
}
