export const FETCH_SUGGESTION_RESULTS = 'search/FETCH_SUGGESTION_RESULTS'
export const FETCH_SUGGESTION_RESULTS_SUCCESS =
  'search/FETCH_SUGGESTION_RESULTS_SUCCESS'
export const FETCH_SUGGESTION_RESULTS_FAILURE =
  'search/FETCH_SUGGESTION_RESULTS_FAILURE'
export const CLEAR_SUGGESTION_RESULTS = 'search/CLEAR_SUGGESTION_RESULTS'
export const CLEAR_SEARCH = 'search/CLEAR_SEARCH'
export const UPDATE_SEARCH_HIGHLIGHTS = 'search/UPDATE_SEARCH_HIGHLIGHTS'
export const FETCH_MORE_SUGGESTION_RESULTS =
  'search/FETCH_MORE_SUGGESTION_RESULTS'
export const FETCH_MORE_SUGGESTION_RESULTS_SUCCESS =
  'search/FETCH_MORE_SUGGESTION_RESULTS_SUCCESS'
export const FETCH_MORE_SUGGESTION_RESULTS_FAILURE =
  'search/FETCH_MORE_SUGGESTION_RESULTS_FAILURE'
