import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { HtmlNotesPreview, Text } from '@pbt/pbt-ui-components'

import { getLocationDisplayName } from '~/store/reducers/constants'
import { DiagnoseLocation, DiagnosesState } from '~/types'

import DiagnoseStatusSelect from './DiagnoseStatusSelect'

const useStyles = makeStyles(
  () => ({
    location: {
      textTransform: 'capitalize',
    },
  }),
  { name: 'DifferentialDiagnoseLocation' },
)

export interface DifferentialDiagnoseLocationProps {
  currentItem: DiagnoseLocation
  diagnoseData: DiagnosesState
  editDisabled?: boolean
  onChange?: (id: string, payload: DiagnosesState) => void
  showNotes?: boolean
  showStatusSelect?: boolean
}

const DifferentialDiagnoseLocation = ({
  showNotes = true,
  showStatusSelect = true,
  diagnoseData,
  currentItem,
  editDisabled,
  onChange,
}: DifferentialDiagnoseLocationProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const locationName = useSelector(getLocationDisplayName(currentItem.id))

  return (
    <>
      <Grid item xs={12}>
        <Text className={classes.location} pt={0.5} variant="body2">
          {t('Common:LOCATION')}: {locationName}
        </Text>
      </Grid>
      {currentItem.notes && showNotes && (
        <HtmlNotesPreview
          fontSize="1.4rem"
          notes={`${t('Common:NOTES')}: ${currentItem.notes}`}
        />
      )}
      {currentItem.statusId && showStatusSelect && (
        <DiagnoseStatusSelect
          currentItem={currentItem}
          diagnoseData={diagnoseData}
          disabled={editDisabled}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default DifferentialDiagnoseLocation
