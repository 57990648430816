import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import {
  getHasUnappliedPayments,
  getHasUnpaidInvoices,
} from '~/store/duck/clientBillingActivityData'

interface PaymentSummaryProps {
  totalDueToPay: number
  totalSelectedUnappliedPayments: number
  totalSelectedUnpaidInvoices: number
}

export const PaymentSummary = ({
  totalDueToPay,
  totalSelectedUnappliedPayments,
  totalSelectedUnpaidInvoices,
}: PaymentSummaryProps) => {
  const { t } = useTranslation(['Common', 'Soap'])

  const hasUnpaidInvoices = useSelector(getHasUnpaidInvoices)
  const hasUnappliedPayments = useSelector(getHasUnappliedPayments)

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={3}
      ml="auto"
      mt={1}
      width="fit-content"
    >
      {hasUnpaidInvoices && (
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <Text variant="body2">
            {t('Soap:SOAP_CLIENT_INVOICE_LIST.UNPAID_INVOICES')}
          </Text>
          <Text variant="body2">
            {NumberUtils.formatMoney(totalSelectedUnpaidInvoices)}
          </Text>
        </Stack>
      )}
      {hasUnappliedPayments && (
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <Text variant="body2">{t('Common:UNAPPLIED_PAYMENTS')}</Text>
          <Text variant="body2">
            {NumberUtils.formatMoney(totalSelectedUnappliedPayments, true)}
          </Text>
        </Stack>
      )}
      {(hasUnpaidInvoices || hasUnappliedPayments) && (
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <Text strong variant="body">
            {t('Common:TOTAL_DUE_TODAY')}
          </Text>
          <Text strong variant="body">
            {NumberUtils.formatMoney(totalDueToPay)}
          </Text>
        </Stack>
      )}
    </Box>
  )
}
