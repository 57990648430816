import React from 'react'
import { useSelector } from 'react-redux'
import {
  CountryCode,
  PhoneInputI18n,
  PhoneInputI18nProps,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'

const PhoneInput = ({
  field,
  country,
  className,
  ...other
}: PhoneInputI18nProps) => {
  const currentBusiness = useSelector(getCurrentBusiness)
  const currentCountry =
    country || currentBusiness?.countryCatalogCode || CountryCode.US
  return (
    <PhoneInputI18n
      className={className}
      country={currentCountry}
      field={field}
      {...other}
    />
  )
}

export default PhoneInput
