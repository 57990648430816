import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import DetailsBackButton from '../DetailsBackButton'
import PatientDetails from './PatientDetails'

const PatientDetailsPage = () => {
  const navigate = useNavigate()
  const { clientId = '', patientId = '' } = useParams()
  const { t } = useTranslation('Common')

  const onBackButtonClick = () => {
    navigate(`/client/${clientId}/patient/${patientId}`)
  }

  return (
    <>
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Common:BACK_TO_CLIENT_AND_PATIENT_SUMMARY')}
      </DetailsBackButton>
      <PatientDetails clientId={clientId} patientId={patientId} />
    </>
  )
}

export default PatientDetailsPage
