import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { AlertIconType } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getFirstForbidden, removeErrors } from '~/store/duck/errors'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { auth0Enabled } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsAuthenticated from '~/utils/useIsAuthenticated'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import isURLBlacklistedForRedirect from './isURLBlacklistedForRedirect'

type BusinessRedirect = {
  from: string
  to: string
}

const SWITCH_BUSINESS_REDIRECTS: BusinessRedirect[] = [
  { from: '/soap', to: '/scheduler' },
  { from: '/membership', to: '/clients' },
  { from: '/communications', to: '/communications' },
  { from: '/admin/catalog/questions', to: '/admin/catalog/questions' },
  { from: '/admin/general/contacts', to: '/admin/general/contacts' },
  { from: '/admin/general/practices', to: '/admin/general/practices' },
  { from: '/admin/general/members', to: '/admin/general/members' },
  {
    from: '/admin/scheduling/availability',
    to: '/admin/scheduling/availability',
  },
  { from: '/admin/general/spaces', to: '/admin/general/spaces' },
  { from: '/admin/catalog/bundles', to: '/admin/catalog/bundles' },
  { from: '/admin/catalog/documents', to: '/admin/catalog/documents' },
  { from: '/admin/catalog/inventories', to: '/admin/catalog/inventories' },
  { from: '/admin/catalog/lab-tests', to: '/admin/catalog/lab-tests' },
  {
    from: '/admin/catalog/pdmp-file-export',
    to: '/admin/catalog/pdmp-file-export',
  },
  { from: '/admin/catalog/procedures', to: '/admin/catalog/procedures' },
  { from: '/admin/catalog/questions', to: '/admin/catalog/questions' },
  {
    from: '/admin/catalog/reminder-protocols',
    to: '/admin/catalog/reminder-protocols',
  },
  {
    from: '/admin/catalog/wellness-plans',
    to: '/admin/catalog/wellness-plans',
  },
]

const ErrorWatcher = () => {
  const navigate = useNavigate()
  const { pathname = '' } = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation('Errors')

  const forbiddenError = useSelector(getFirstForbidden)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const { isAuthenticated } = useIsAuthenticated()

  useWSTopic({ wsTopic: WSTopics.ERROR })

  const [openWarningDialog] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const [oldCurrentBusinessId, setOldCurrentBusinessId] = useState<string>()

  useEffect(() => {
    if (oldCurrentBusinessId && oldCurrentBusinessId !== currentBusinessId) {
      const match = R.find<BusinessRedirect>(({ from }) => {
        if (isPatientSharingEnabled && from === '/soap') {
          return false
        }
        return R.includes(from, pathname)
      })(SWITCH_BUSINESS_REDIRECTS)

      if (match) {
        navigate(match.to)
      }
    }
    setOldCurrentBusinessId(currentBusinessId)
  }, [currentBusinessId])

  useEffect(() => {
    if (forbiddenError) {
      navigate('/', { replace: true })
      dispatch(removeErrors([forbiddenError.id]))
      openWarningDialog({
        iconType: AlertIconType.WARN,
        message: <>{t('Errors:ERROR_WATCHER.WARNING_DIALOG_MESSAGE')}</>,
      })
    }
  }, [forbiddenError])

  if (
    !auth0Enabled &&
    !isAuthenticated &&
    !isURLBlacklistedForRedirect(pathname)
  ) {
    return <Navigate replace to="/auth/login" />
  }

  return null
}

export default ErrorWatcher
