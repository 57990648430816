import i18n from '~/locales/i18n'

export const InventoryCatalog = {
  Catalog: 'catalog',
  OnHand: 'on_hand',
  Shipments: 'shipments',
  Adjustments: 'adjustments',
}

export const InventoryCatalogNames = {
  [InventoryCatalog.Catalog]: i18n.t('Common:CATALOG'),
  [InventoryCatalog.OnHand]: i18n.t('Common:ON-HAND'),
  [InventoryCatalog.Shipments]: i18n.t('Common:SHIPMENTS'),
  [InventoryCatalog.Adjustments]: i18n.t('Common:ADJUSTMENTS'),
}
