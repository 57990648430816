import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { Defaults, PuiTextField, useFields } from '@pbt/pbt-ui-components'

import { useProblemLogEnumValueUpdate } from '~/store/hooks/problems'
import { ProblemLogEnumValueContainer } from '~/types'

interface IdentifiedProblemDetailsEnumValueNotesProps {
  enumLog: ProblemLogEnumValueContainer
}

const IdentifiedProblemDetailsEnumValueNotes = ({
  enumLog,
}: IdentifiedProblemDetailsEnumValueNotesProps) => {
  const { t } = useTranslation(['Common'])
  const {
    fields: { notes },
  } = useFields([
    {
      name: 'notes',
      initialValue: enumLog.entity.notes,
    },
  ])

  const [debouncedNotes] = useDebounce(
    notes.value,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const isNotesChanged = useProblemLogEnumValueUpdate({
    enumLog,
    newNotes: debouncedNotes,
  })

  return (
    <PuiTextField
      multiline
      field={notes}
      isLoading={isNotesChanged}
      placeholder={t('Common:NOTE')}
      sx={{ marginTop: 0 }}
      variant="outlined"
    />
  )
}

export default IdentifiedProblemDetailsEnumValueNotes
