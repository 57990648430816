import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  ConstantWithMeasurementsSystem,
  Text,
  TextProps,
  UnitsState,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import { getVitalLabel } from '~/components/dashboard/soap/vitals/vitalUtils'
import { getUnitsState } from '~/store/duck/settings'
import { getConstant, getConstantMap } from '~/store/reducers/constants'
import { VitalConfigItem, VitalValue } from '~/types'
import { useGetBusinessEnabledUnits } from '~/utils/measurementUnitsUtils'

export interface VitalValueLabelProps extends TextProps {
  singleUnit?: boolean
  vital: VitalValue
  vitalConfigItem: VitalConfigItem
}

const VitalValueLabel = ({
  singleUnit = false,
  vital,
  vitalConfigItem,
  ...rest
}: VitalValueLabelProps) => {
  const constantSelector = vitalConfigItem.constant
    ? getConstant(vitalConfigItem.constant)
    : R.always(undefined)

  const constantMap = useSelector(getConstantMap)
  const Units = useSelector(constantSelector)
  const unitsState = useSelector(getUnitsState)

  const value = vital[vitalConfigItem.id]?.value

  const businessEnabledUnits = useGetBusinessEnabledUnits(Units)

  if (R.isNil(value)) {
    return null
  }

  if (!Units) {
    return (
      <Text {...rest}>
        {getVitalLabel(value, vitalConfigItem, constantMap)}
      </Text>
    )
  }

  const configItemId = vitalConfigItem.id as keyof UnitsState

  const units = singleUnit
    ? ([
        businessEnabledUnits.find(
          (unit) => unitsState[configItemId] === unit.name,
        ) || R.head(businessEnabledUnits),
      ] as ConstantWithMeasurementsSystem[])
    : businessEnabledUnits

  return (
    <>
      {units.map((unit) => (
        <Text key={unit.id} {...rest}>
          {getVitalLabel(value, vitalConfigItem, constantMap, {
            id: unit.name,
          })}
        </Text>
      ))}
    </>
  )
}

export default VitalValueLabel
