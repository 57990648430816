import { useSelector } from 'react-redux'
import { propEq, reject } from 'ramda'
import { WorkFlowTypesConstant } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'
import { getWorkFlowTypes } from '~/store/reducers/constants'

const useWorkflowTypes = (): WorkFlowTypesConstant[] => {
  const types = useSelector(getWorkFlowTypes)
  const business = useSelector(getCurrentBusiness)

  return business?.kioskEnabled
    ? types
    : reject(propEq('shortName', 'Kiosk'), types)
}

export default useWorkflowTypes
