import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ClickAwayListener, Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  ClassesType,
  DebouncedInlineSearch,
  Defaults,
  Nil,
  PermissionArea,
  UserPermissions,
} from '@pbt/pbt-ui-components'

import SearchResults from '~/components/common/lists/SearchResults'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import SearchContext from '~/constants/searchContext'
import SecretSearchContext from '~/constants/secretSearchContext'
import {
  clearSuggestionResults,
  fetchSuggestionResults,
} from '~/store/actions/search'
import {
  getCurrentBusinessIsOmniChannel,
  getGroupCRUDByArea,
} from '~/store/reducers/auth'
import { getClientRoleId, getFeatureToggle } from '~/store/reducers/constants'
import {
  getSuggestionIsReceiving,
  getSuggestionResultsWithStatuses,
} from '~/store/reducers/search'
import useDialog from '~/utils/useDialog'

import AdvancedClientSearch from '../../../header/AdvancedClientSearch'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    topContainer: {
      position: 'relative',
      zIndex: theme.zIndex.rightRail,
    },
    clientsSearch: {
      position: 'relative',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
    advancedSearchDropDown: {
      padding: theme.spacing(3.5, 1, 1.5),
    },
    searchResultsContainer: {
      position: 'relative',
    },
  }),
  { name: 'ClientSelector' },
)

const ITEM_SIZE = 32

export interface ClientSelectorProps {
  actionBtnTitle?: string
  allowNoSubItemsSelection?: boolean
  className?: string
  classes?: ClassesType<typeof useStyles>
  clientOnly?: boolean
  direction?: GridProps['direction']
  hideAddClients?: boolean
  isSearchOnlyClients?: boolean
  maxSearchValuesInList?: number
  onAddNewClientRequested?: () => void
  onExpand?: (
    searchTerm: string,
    fieldsQuery?: string,
    additionalParams?: [string, string][],
  ) => void
  onSelected: (clientId: string, patientId: string | Nil) => void
  permissions?: UserPermissions
  resultsTitle?: string
}

const ClientSelector = forwardRef(function ClientSelector(
  {
    allowNoSubItemsSelection,
    actionBtnTitle,
    className,
    classes: classesProp = {},
    hideAddClients = false,
    isSearchOnlyClients = false,
    maxSearchValuesInList = 6,
    onAddNewClientRequested,
    onExpand,
    onSelected,
    permissions,
    resultsTitle,
    clientOnly,
    direction = 'column',
    ...rest
  }: ClientSelectorProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search', 'TimeTable'])

  const searchResults = useSelector(getSuggestionResultsWithStatuses)
  const isLoading = useSelector(getSuggestionIsReceiving)
  const ClientRoleId = useSelector(getClientRoleId)

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )
  const showAddPatient = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [searchResultOpen, setSearchResultOpen] = useState(false)
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false)
  const [searchFieldsQuery, setSearchFieldsQuery] = useState('')
  const { read: patientGroupReadPermissions } = useSelector(
    getGroupCRUDByArea(PermissionArea.PATIENT),
  )

  const [openAdvancedClientsSearchDialog] = useDialog(
    DialogNames.ADVANCED_CLIENTS_SEARCH,
  )

  const isVisibleSearchResults =
    searchResultOpen &&
    !isLoading &&
    searchTerm.length >= Defaults.CLIENTS_SEARCH_LENGTH
  const searchContext = isSearchOnlyClients
    ? SecretSearchContext.PERSONS
    : SearchContext.CLIENT_PATIENTS

  const filteredSearchResults = isSearchOnlyClients
    ? searchResults.filter(({ item }) => item)
    : searchResults
  const hasSubItems = (filteredSearchResults || []).some(
    ({ subItem }) => subItem,
  )

  const handleAdvancedSearchDialog = (
    term: string,
    fieldsQuery?: string,
    additionalParams?: [string, string][],
  ) => {
    openAdvancedClientsSearchDialog({
      actionBtnTitle: t('Common:ADD_ACTION'),
      searchTerm: term,
      fieldsQuery,
      additionalParams,
      resultsTitle,
      onClick: onSelected,
    })
  }

  const onExpandHandle = onExpand ?? handleAdvancedSearchDialog

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && searchTerm) {
      onExpandHandle(searchTerm)
    }
  }

  const handleAdvancedSearch = (
    fieldsQuery: string,
    additionalParams?: [string, string][],
  ) => {
    setSearchFieldsQuery(fieldsQuery)
    if (fieldsQuery || (additionalParams && !R.isEmpty(additionalParams))) {
      onExpandHandle('', fieldsQuery, additionalParams)
      setAdvancedSearchOpen(false)
    }
  }

  useEffect(() => {
    dispatch(clearSuggestionResults())
  }, [])

  useEffect(() => {
    if (
      !advancedSearchOpen &&
      searchTerm.length >= Defaults.CLIENTS_SEARCH_LENGTH
    ) {
      dispatch(
        fetchSuggestionResults({
          searchContext,
          searchTerm,
          roleIds: ClientRoleId,
        }),
      )
    }
  }, [dispatch, searchTerm])

  return (
    <Grid
      container
      alignItems={direction === 'row' ? 'center' : 'stretch'}
      className={classNames(className, classes.root)}
      direction={direction}
      justifyContent="center"
      wrap="nowrap"
    >
      <ClickAwayListener
        onClickAway={() => {
          setSearchResultOpen(false)
        }}
      >
        <Grid item xs className={classes.topContainer} mb={1} mt={1.5}>
          {!advancedSearchOpen && (
            <SearchResults
              suggestionsEnabled
              allowNoSubItemsSelection={allowNoSubItemsSelection}
              clientOnly={clientOnly}
              hasSubItems={hasSubItems}
              itemSize={ITEM_SIZE}
              listItemHeight={
                Math.min(maxSearchValuesInList, filteredSearchResults.length) *
                ITEM_SIZE
              }
              open={isVisibleSearchResults}
              openSearchResults={() => onExpandHandle(searchTerm)}
              results={searchResults}
              searchTerm={searchTerm}
              showContextIds={patientGroupReadPermissions}
              onItemClick={onSelected}
              onSuggestionClick={handleAdvancedSearch}
            />
          )}
          <Grid item className={classes.clientsSearch} ref={ref} {...rest}>
            <DebouncedInlineSearch
              advancedSearch
              advancedSearchOpen={advancedSearchOpen}
              isLoading={isLoading}
              name="clientsAndPatientsSearch"
              placeholder={
                isSearchOnlyClients
                  ? t('Search:CLIENTS')
                  : t('TimeTable:CLIENT_SELECTOR.CLIENT_AND_PATIENTS')
              }
              onAdvancedSearchOpen={() =>
                setAdvancedSearchOpen(!advancedSearchOpen)
              }
              onChange={setSearchTerm}
              onFocus={() => {
                setSearchResultOpen(true)
              }}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          {advancedSearchOpen && (
            <AdvancedClientSearch
              classes={{
                dropDown: classes.advancedSearchDropDown,
              }}
              initialFieldsQuery={searchFieldsQuery}
              open={advancedSearchOpen}
              onSearch={handleAdvancedSearch}
            />
          )}
        </Grid>
      </ClickAwayListener>
      {onAddNewClientRequested && !hideAddClients && permissions?.create && (
        <Grid item pl={2}>
          {showAddPatient && (
            <AddButton
              addText={t('Common:ADD_NEW_CLIENT')}
              onAdd={onAddNewClientRequested}
            />
          )}
        </Grid>
      )}
    </Grid>
  )
})

export default ClientSelector
