import { useState } from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import { useBlocker } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useBeforeUnload from '~/utils/useBeforeUnload'
import useDialog from '~/utils/useDialog'

export interface LeaveConfirmationDialogProps {
  dialogName:
    | DialogNames.VITALS_CONFIRMATION
    | DialogNames.EXPANDER_CONFIRM_CLOSE
    | DialogNames.SOAP_WRAP_UP_LEAVE
    | DialogNames.BULK_PRICES_CLOSE
    | DialogNames.APPOINTMENT_CONFIGURATION_CONFIRMATION
  enableBeforeUnloadValue?: boolean
  getUnsavedData?: () => any
  hasUnsavedData?: boolean
  navigateOnCancel?: boolean
  navigateOnProceed?: boolean
  onCancel?: () => void
  onClose?: () => void
  onProceed?: () => void
}

const BLOCKER_DISABLEMENT_TIME_AFTER_DIALOG_CLOSE = 1000

const LeaveConfirmationDialog = ({
  dialogName,
  hasUnsavedData,
  getUnsavedData,
  navigateOnProceed = true,
  navigateOnCancel = false,
  enableBeforeUnloadValue = true,
  onProceed,
  onCancel,
  onClose,
}: LeaveConfirmationDialogProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [blockerActive, setBlockerActive] = useState(true)

  const onDialogClose = () => {
    if (onClose) {
      onClose()
    }

    setTimeout(() => {
      setBlockerActive(true)
    }, BLOCKER_DISABLEMENT_TIME_AFTER_DIALOG_CLOSE)
  }

  const [openDialog, closeDialog] = useDialog(dialogName, onDialogClose)

  const handleNavigate = (nextLocation: Location) => {
    const nextLocationRoute = nextLocation.pathname + nextLocation.search
    if (nextLocationRoute) {
      navigate(nextLocationRoute, { state: nextLocation?.state })
    }
  }

  const isSameLocation = (newNextLocation: Location) =>
    `${newNextLocation.pathname}${newNextLocation.search}` ===
    `${location.pathname}${location.search}`

  const getData = () => (getUnsavedData ? getUnsavedData() : hasUnsavedData)

  const hasData = () => {
    const data = getData()
    const isArray = Array.isArray(data)
    return isArray ? data.length > 0 : Boolean(data)
  }

  const handleCancel = (nextLocation: Location) => {
    if (onCancel) {
      onCancel()
    }

    if (navigateOnCancel) {
      handleNavigate(nextLocation)
    }

    closeDialog()
  }

  const handleProceed = (nextLocation: Location) => {
    if (onProceed) {
      onProceed()
    }

    if (navigateOnProceed) {
      handleNavigate(nextLocation)
    }

    closeDialog()
  }

  const handleBlockedNavigation = ({
    location: newNextLocation,
    retry,
  }: {
    location: Location
    retry: () => void
  }) => {
    if (blockerActive && hasData() && !isSameLocation(newNextLocation)) {
      setBlockerActive(false)

      const dialogEvents = {
        onCancel: () => handleCancel(newNextLocation),
        onProceed: () => handleProceed(newNextLocation),
      }

      openDialog(Object.assign(dialogEvents, { unsavedData: getData() }))
    } else {
      retry()
    }
  }

  useBeforeUnload(enableBeforeUnloadValue ? hasData : undefined)

  useBlocker(
    handleBlockedNavigation,
    blockerActive && (getUnsavedData ? true : hasUnsavedData),
  )

  return null
}

export default LeaveConfirmationDialog
