import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  AppointmentCommunicationTemplate,
  AppointmentEventType,
  Constant,
  EventTypeName,
  LanguageUtils,
  PermissionArea,
  TextWithTooltip,
  UserPermissions,
} from '@pbt/pbt-ui-components'

import { AutomaticCommunicationType } from '~/components/common/automaticCommunication/AutomaticCommunicationPreviewDialog'
import ScrollableDataTable from '~/components/common/lists/ScrollableDataTable'
import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  getCommunicationTransportBackupOption,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps } from '~/types'
import { getConstantsStringWithAll } from '~/utils'
import useDialog from '~/utils/useDialog'
import { useEventType } from '~/utils/useEventType'

import { useAutomaticCommunicationWhenStringFormatter } from './automaticCommunicationUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    leftColumn: {
      padding: 0,
    },
    name: {
      paddingLeft: theme.spacing(2),
      maxWidth: 250,
    },
    table: {
      width: 335,
    },
    tableCell: {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: '1.4rem',
      height: 48,
    },
    tableTitle: {
      height: 32,
    },
  }),
  { name: 'AdditionalAutomaticCommunicationsTable' },
)

const headerRow = [
  { label: i18n.t('Common:WHEN') },
  { label: i18n.t('Common:SEND_AS') },
  { label: i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.PRIMARY_BACK_UP') },
  { label: i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.SECONDARY_BACK_UP') },
  { label: i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.TERTIARY_BACK_UP') },
  {
    label: i18n.t(
      'Businesses:APPOINTMENT_COMMUNICATIONS.SEND_FOR_APPOINTMENT_TYPES',
    ),
  },
]

type ChildData = {
  item: AppointmentCommunicationTemplate
  tableCellClassName?: string
}

export interface AdditionalAutomaticCommunicationsTableProps
  extends BasePracticeDetailsSectionProps {
  className: string
  templates: AppointmentCommunicationTemplate[]
  updateTemplates: React.Dispatch<
    React.SetStateAction<AppointmentCommunicationTemplate[]>
  >
}

const AdditionalAutomaticCommunicationsTable = ({
  className,
  business,
  templates: templatesCandidate,
  updateTemplates,
  ...rest
}: AdditionalAutomaticCommunicationsTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const permissions: UserPermissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const CommunicationTransportBackupOption: Constant[] = useSelector(
    getCommunicationTransportBackupOption,
  )

  const isHideCommunicationSettingsForOmniChannelEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.HIDE_COMMUNICATION_SETTINGS_FOR_OMNI_CHANNEL,
    ),
  )

  const [openAutomaticCommunicationDialog] = useDialog(
    DialogNames.AUTOMATIC_COMMUNICATION,
  )
  const [openAutomaticCommunicationPreviewDialog] = useDialog(
    DialogNames.AUTOMATIC_COMMUNICATION_PREVIEW,
  )

  const whenStringFormatter = useAutomaticCommunicationWhenStringFormatter()

  useEffect(() => {
    updateTemplates(
      business.appointmentCommunicationsConfiguration?.templates || [],
    )
  }, [business.appointmentCommunicationsConfiguration?.templates])

  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )

  const mainColumns = [
    {
      label: t('Common:ON'),
      // eslint-disable-next-line react/no-unstable-nested-components
      prop: (item: AppointmentCommunicationTemplate) => (
        <PuiSwitch
          checked={item.active}
          disabled={!permissions.update}
          onChange={() => {
            const index = templatesCandidate.indexOf(item)
            const newItem = {
              ...item,
              active: !item.active,
            }

            updateTemplates(R.update(index, newItem, templatesCandidate))
          }}
        />
      ),
    },
    {
      label: t('Common:NAME'),
      prop: 'name' as keyof AppointmentCommunicationTemplate,
    },
  ]

  const onAddRequested = () => {
    openAutomaticCommunicationDialog({
      business,
      onProceed: (automaticCommunication: AppointmentCommunicationTemplate) => {
        updateTemplates([...templatesCandidate, automaticCommunication])
      },
    })
  }

  const onEditRequested = (index: number) => {
    openAutomaticCommunicationDialog({
      business,
      automaticCommunication: templatesCandidate[index],
      onProceed: (automaticCommunication: AppointmentCommunicationTemplate) => {
        updateTemplates(
          R.update(index, automaticCommunication, templatesCandidate),
        )
      },
    })
  }

  const onDeleteRequested = (index: number) => {
    updateTemplates(R.remove(index, 1, templatesCandidate))
  }

  const onPreviewRequested = (index: number) => {
    openAutomaticCommunicationPreviewDialog({
      business,
      automaticCommunication: templatesCandidate[index],
      type: AutomaticCommunicationType.APPOINTMENT,
    })
  }

  const onDuplicateRequested = (index: number) => {
    const item = templatesCandidate[index]
    const duplicate = {
      ...R.omit(['id'], item),
      name: `${item.name}_copy`,
    }

    updateTemplates(
      R.insert(
        index + 1,
        duplicate,
        templatesCandidate,
      ) as AppointmentCommunicationTemplate[],
    )
  }

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      direction="column"
    >
      <TextWithTooltip
        allowWrap
        strong
        tooltipText={t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.ADDITIONAL_AUTOMATIC_COMMUNICATIONS_TOOLTIP',
        )}
        variant="subheading3"
      >
        {t(
          'Businesses:APPOINTMENT_COMMUNICATIONS.ADDITIONAL_AUTOMATIC_COMMUNICATIONS',
        )}
      </TextWithTooltip>
      <ScrollableDataTable
        useIndexAsId
        classes={{
          leftColumn: classes.leftColumn,
          table: classes.table,
          tableCell: classes.tableCell,
          tableTitle: classes.tableTitle,
          name: classes.name,
        }}
        data={templatesCandidate}
        headerRow={headerRow}
        mainColumns={mainColumns}
        onDelete={onDeleteRequested}
        onDuplicate={onDuplicateRequested}
        onEdit={onEditRequested}
        onPreview={
          isHideCommunicationSettingsForOmniChannelEnabled
            ? undefined
            : onPreviewRequested
        }
        {...rest}
      >
        {({ item, tableCellClassName }: ChildData) => (
          <>
            <TableCell className={tableCellClassName}>
              {whenStringFormatter(item)}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {LanguageUtils.getConstantTranslatedName(
                item.communicationMethodId,
                CommunicationTransportBackupOption,
              )}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {LanguageUtils.getConstantTranslatedName(
                item.communicationMethodPrimaryBackupId,
                CommunicationTransportBackupOption,
                t('Common:NONE'),
              )}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {LanguageUtils.getConstantTranslatedName(
                item.communicationMethodSecondaryBackupId,
                CommunicationTransportBackupOption,
                t('Common:NONE'),
              )}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {LanguageUtils.getConstantTranslatedName(
                item.communicationMethodTertiaryBackupId,
                CommunicationTransportBackupOption,
                t('Common:NONE'),
              )}
            </TableCell>
            <TableCell className={tableCellClassName}>
              {getConstantsStringWithAll(
                item.appointmentTypeIds,
                AppointmentSubTypes,
              )}
            </TableCell>
          </>
        )}
      </ScrollableDataTable>
      <Grid item mt={1}>
        <AddButton
          addText={t('Businesses:APPOINTMENT_COMMUNICATIONS.ADD_COMMUNICATION')}
          onAdd={onAddRequested}
        />
      </Grid>
    </Grid>
  )
}

export default AdditionalAutomaticCommunicationsTable
