import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'
import { Defaults, useFields } from '@pbt/pbt-ui-components'

import {
  RetailOrderLineItem,
  UpdateRetailOrderLineItemInput,
} from '~/api/graphql/generated/types'
import {
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import { BaseTemplateInputHandle } from '~/components/dashboard/template-inputs/BaseTemplateInput/BaseTemplateInput'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { editRetailOrderLineItem } from '~/store/actions/finance'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSelectedOrder } from '~/store/reducers/orders'
import { InvoiceLineItem } from '~/types'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'

export interface ChargeItemNoteProps {
  disabled?: boolean
  isSoap?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
}

const ChargeItemNote = ({ disabled, item, isSoap }: ChargeItemNoteProps) => {
  const dispatch = useDispatch()

  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)
  const relatedOrderItem = useSelector(
    getSelectedOrder(item.logType as OrderType, item.logId),
  )

  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const notesValueProperty = isItemRetailOrderLineItem
    ? item.notes || relatedOrderItem?.notes
    : item?.orderNotes || relatedOrderItem?.notes

  const { fields, reset } = useFields([
    {
      name: 'notes',
      initialValue: notesValueProperty,
    },
  ])

  const orderItemId = item.logId
  const notesRef = useRef<BaseTemplateInputHandle>(null)

  const debouncedNotesEdit = useDebouncedCallback(
    (changedFields: FieldCache, it: InvoiceLineItem | RetailOrderLineItem) => {
      const isItRetailOrderLineItem = isRetailOrderLineItem(it)
      const notesChanged = changedFields.filter(
        (f) => f.name === 'notes' && f.initialValue !== f.value,
      )
      if (
        notesChanged.length > 0 &&
        notesValueProperty !== notesChanged[0].value
      ) {
        if (isItRetailOrderLineItem) {
          const data: UpdateRetailOrderLineItemInput = {
            id: it.id,
            quantity: it.quantity,
            declined: it?.declined || false,
            expectedModificationDate: it.modificationDate,
            partNumber: it.partNumber,
            notes: notesChanged[0].value,
            autoshipFrequency: it.autoshipFrequency,
            autoshipUnitId: it.autoshipUnit?.id,
          }
          dispatch(editRetailOrderLineItem(data))
        } else if (isChargeSheetEnabled) {
          dispatch(
            editChargeSheetOrder({
              id: it.logId!,
              type: it.logType,
              order: { notes: notesChanged[0].value },
              soapLogModificationDate:
                it.soapLogModificationDate || it.modificationDate,
            }),
          )
        } else {
          dispatch(
            partialEditOrder({
              id: it.logId,
              soapId: it.soapId,
              type: it.logType,
              notes: notesChanged[0].value,
            }),
          )
        }
      }
    },
    Defaults.DEBOUNCE_ACTION_TIME * 10,
  )

  // when user goes directly to another page, flush changes immediately, otherwise debounce will not fire
  useEffect(
    () => () => {
      debouncedNotesEdit.flush()
    },
    [debouncedNotesEdit],
  )

  useFieldsChanged((f) => debouncedNotesEdit(f, item), fields)

  useEffect(() => {
    if (notesRef.current) {
      debouncedNotesEdit.flush()
      notesRef.current?.resetState()
      reset()
    }
  }, [orderItemId, isItemRetailOrderLineItem ? item.notes : item.orderNotes])

  if (isGroupedInvoiceItem(item)) {
    return null
  }

  return (
    <Grid container mt={2}>
      <NotesTemplateInput
        hidePanel
        disabled={isEditPostedChargesEnabled ? false : disabled}
        eventId={
          isItemRetailOrderLineItem ? item.appointment?.id : item.eventId
        }
        field={fields.notes}
        isSoap={isSoap}
        maxEditorHeight={160}
        maxHeight={160}
        minEditorHeight={80}
        minHeight={80}
        patientId={
          isItemRetailOrderLineItem ? item?.patient.id : item.patientId
        }
        ref={notesRef}
      />
    </Grid>
  )
}

export default ChargeItemNote
