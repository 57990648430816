import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { fetchOrderCountsByType } from '~/store/actions/orders'
import {
  getAppliedPatientRestrictions,
  getOrdersIsFetchingOrdersCountByType,
  getOrdersIsSending,
  getTotalCountByType as getTotalCountByTypeAction,
} from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'

type UseChargeTabCountByTypeProps = {
  appointmentId: string | Nil
  clientId: string | Nil
  patientId: string | Nil
}

export const useChargeTabCountByType = ({
  appointmentId: appointmentIdProp,
  patientId: patientIdProp,
  clientId: clientIdProp,
}: UseChargeTabCountByTypeProps) => {
  const dispatch = useDispatch()

  const soapClientId = useSelector(getClientId)
  const soapPatientId = useSelector(getPatientId)
  const totalCountByType = useSelector(getTotalCountByTypeAction)
  const soapId = useSelector(getAppointmentId)
  const isSending = useSelector(getOrdersIsSending)
  const appliedPatientRestrictions = useSelector(getAppliedPatientRestrictions)
  const isFetchingOrdersCountByType = useSelector(
    getOrdersIsFetchingOrdersCountByType,
  )

  const appointmentId = soapId || appointmentIdProp
  const clientId = soapClientId || clientIdProp
  const patientId = soapPatientId || patientIdProp

  useEffect(() => {
    if (clientId && patientId && appointmentId) {
      dispatch(
        fetchOrderCountsByType(
          clientId,
          patientId,
          appointmentId,
          appliedPatientRestrictions,
          [
            OrderType.PREPAID,
            OrderType.REFILL,
            OrderType.REMINDER,
            OrderType.BUNDLE,
          ],
        ),
      )
    }
  }, [
    clientId,
    patientId,
    appointmentId,
    appliedPatientRestrictions,
    isSending,
  ])

  const getTotalCountByType = (type: OrderType) => totalCountByType?.[type] || 0
  const hasNoItems = (type: OrderType) => getTotalCountByType(type) === 0
  const getTotalCountByTypeString = (type: OrderType) =>
    isFetchingOrdersCountByType ? '(-)' : `(${getTotalCountByType(type)})`

  return { hasNoItems, getTotalCountByTypeString }
}
