import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { getMultipleTasksWithChildren } from '~/store/reducers/tasks'
import { isWithinTimeInterval } from '~/utils/time'

import TaskGroupedStateLabel from '../../timetable/whiteboard/TaskGroupedStateLabel'
import TaskMesh, { TaskMeshProps } from './TaskMesh'

export interface TaskGroupedStateButtonMeshProps extends TaskMeshProps {
  schedule: {
    tasks: string[]
  }
}

const TaskGroupedStateButtonMesh = forwardRef<
  HTMLDivElement,
  TaskGroupedStateButtonMeshProps
>(function TaskGroupedStateButtonMesh({ schedule, ...rest }, ref) {
  const tasks = useSelector(getMultipleTasksWithChildren(schedule.tasks))

  return (
    <TaskMesh ref={ref} {...rest}>
      {(column) => {
        const filteredTasks = tasks.filter((task) =>
          isWithinTimeInterval(task?.dueDate, column),
        )

        return <TaskGroupedStateLabel tasks={filteredTasks} />
      }}
    </TaskMesh>
  )
})

export default TaskGroupedStateButtonMesh
