import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty } from 'ramda'
import { AnyAction } from 'redux'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'
import {
  List as ListIcon,
  LoveList as LoveListIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { AppSelector } from '~/store'
import { FetchOrdersOptions, Order } from '~/types'

import ProtocolListItem from '../admin/catalog/reminder-protocol-groups/ProtocolListItem'
import { ListFilterSubItem } from '../soap/list-with-filters/ListFilter'
// @ts-ignore
import SelectableListWithFilters from '../soap/list-with-filters/SelectableListWithFilters'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
    },
  }),
  { name: 'AddCatalogItemsDialog' },
)

const INFINITE_LIST_BATCH_LOAD_COUNT = 250

type FetchCatalogItemsActionHandler = (
  categories: string[] | Nil,
  listType: string,
  entityTypes: string[] | Nil,
  query: string | Nil,
  from: number | Nil,
  to: number | Nil,
) => AnyAction

export interface AddCatalogItemsDialogProps extends BasePuiDialogProps {
  actions: {
    fetchCatalogFilters: () => AnyAction
    fetchCatalogItems: FetchCatalogItemsActionHandler
    fetchMoreCatalogItems: FetchCatalogItemsActionHandler
  }
  onSave: (items: Partial<Order>[]) => void
  selectors: {
    getCatalogFilters: AppSelector<ListFilterSubItem[] | Nil>
    getCatalogItems: AppSelector<Order[] | Nil>
    getCatalogItemsTotalCount: AppSelector<number | Nil>
    getIsCatalogFilterLoading: AppSelector<boolean | Nil>
    getIsCatalogItemsLoading: AppSelector<boolean | Nil>
  }
  title: string
}

const AddCatalogItemsDialog = ({
  title,
  open,
  onClose,
  onSave,
  selectors,
  actions,
}: AddCatalogItemsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search'])

  const OrderListTypes = [
    {
      id: 'OUR',
      name: t('Common:OUR_LIST'),
      isDefault: true,
      Icon: LoveListIcon,
    },
    {
      id: 'FULL',
      name: t('Common:FULL_LIST'),
      Icon: ListIcon,
    },
  ]

  const [isSearch, setIsSearch] = useState(false)

  const catalogFilters = useSelector(selectors.getCatalogFilters)
  const catalogItems = useSelector(selectors.getCatalogItems)
  const catalogItemsTotalCount = useSelector(
    selectors.getCatalogItemsTotalCount,
  )
  const isCatalogFilterLoading = useSelector(
    selectors.getIsCatalogFilterLoading,
  )
  const isCatalogItemsLoading = useSelector(selectors.getIsCatalogItemsLoading)

  const catalogChildrenCount =
    catalogItems?.reduce((sum, { items }) => sum + (items?.length || 0), 0) || 0

  useEffect(() => {
    if (open && isEmpty(catalogFilters)) {
      dispatch(actions.fetchCatalogFilters())
    }
  }, [open])

  const handleLoadMore = ({
    categories,
    entityType,
    listType,
    searchTerm,
  }: FetchOrdersOptions) => {
    setIsSearch(Boolean(searchTerm))
    dispatch(
      actions.fetchMoreCatalogItems(
        categories,
        listType,
        [entityType as string],
        searchTerm || null,
        catalogChildrenCount,
        catalogChildrenCount + INFINITE_LIST_BATCH_LOAD_COUNT,
      ),
    )
  }

  const loadNewItems = ({
    categories,
    listType,
    entityType,
  }: FetchOrdersOptions) => {
    setIsSearch(false)
    dispatch(
      actions.fetchCatalogItems(
        categories,
        listType,
        [entityType as string],
        null,
        0,
        INFINITE_LIST_BATCH_LOAD_COUNT,
      ),
    )
  }

  const search = ({
    listType,
    filters: entityTypes,
    searchTerm,
  }: FetchOrdersOptions) => {
    setIsSearch(true)
    dispatch(
      actions.fetchCatalogItems(
        [],
        listType,
        entityTypes,
        searchTerm,
        0,
        INFINITE_LIST_BATCH_LOAD_COUNT,
      ),
    )
  }

  const handleProceed = (items: Order[]) => {
    if (onClose) {
      onClose()
    }
    onSave(items)
  }

  const hasMore =
    catalogItemsTotalCount === undefined ||
    catalogChildrenCount < (catalogItemsTotalCount || 0)

  return (
    <PuiDialog
      aria-labelledby="add-catalog-items-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={title}
      onClose={onClose}
    >
      <Grid container direction="column" flex={1} p={1}>
        <SelectableListWithFilters
          ItemComponent={ProtocolListItem}
          filters={catalogFilters}
          hasMore={hasMore}
          isLoading={isCatalogFilterLoading}
          isReceivingListItems={isCatalogItemsLoading}
          items={isSearch ? undefined : catalogItems}
          listTypes={OrderListTypes}
          loadMore={handleLoadMore}
          loadNewItems={loadNewItems}
          proceedButtonLabel={t('Common:ADD_ACTION')}
          searchItems={search}
          searchPlaceholder={t('Search:CATALOG')}
          searchResults={
            isSearch ? catalogItems?.map((item) => ({ item })) : undefined
          }
          onProceed={handleProceed}
        />
      </Grid>
    </PuiDialog>
  )
}

export default AddCatalogItemsDialog
