import { gql } from '@apollo/client'

import { BUSINESS_APPOINTMENT_TYPE_FIELDS } from '../fragments/appointmentTypes'

export const FETCH_APPOINTMENT_TYPES = gql`
  ${BUSINESS_APPOINTMENT_TYPE_FIELDS}
  query BusinessAppointmentTypes(
    $businessId: ID!
    $searchFilter: BusinessAppointmentTypeSearchInput
  ) {
    businessAppointmentTypes(
      businessId: $businessId
      searchFilter: $searchFilter
    ) {
      data {
        ...BusinessAppointmentTypeFields
      }
      totalCount
    }
  }
`
