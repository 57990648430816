import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, List, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PuiTooltip, Text } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    infoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
    },
    tooltip: {
      width: '225px',
    },
    tooltipFontSize: {
      fontSize: '14px',
    },
    boldedTooltip: {
      fontWeight: 700,
    },
    tooltipList: {
      marginLeft: '10px',
      fontSize: '12px',
    },
  }),
  { name: 'AutoshipRefillTooltip' },
)

export const AutoshipRefillTooltip = () => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  return (
    <PuiTooltip
      tooltipPlacement="top-start"
      tooltipText={
        <Grid className={classes.tooltip}>
          <Text
            className={`${classes.tooltipFontSize} ${classes.boldedTooltip}`}
          >
            {t('Soap:AUTOSHIP.TOOLTIP.SET_UP')}
          </Text>
          <List className={classes.tooltipList}>
            <ListItem disablePadding>
              {t('Soap:AUTOSHIP.TOOLTIP.SAVE_FIVE')}
            </ListItem>
            <ListItem disablePadding>
              {t('Soap:AUTOSHIP.TOOLTIP.GET_DELIVERIES')}
            </ListItem>
            <ListItem disablePadding>
              {t('Soap:AUTOSHIP.TOOLTIP.SKIP_CHANGE')}
            </ListItem>
          </List>
          <Text className={classes.tooltipFontSize}>
            {t('Soap:AUTOSHIP.TOOLTIP.NO_FEES')}
          </Text>
        </Grid>
      }
    >
      <InfoIcon className={classes.infoIcon} />
    </PuiTooltip>
  )
}
