import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, moment, PuiTooltip } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

const DEFAULT_WARNING_THRESHOLD_DAYS = 90

const useStyles = makeStyles(
  (theme) => ({
    icon: {},
    expired: {
      color: theme.colors.alertWarning,
    },
    expiresSoon: {
      color: theme.colors.alertWarning,
    },
  }),
  { name: 'ExpirationNotice' },
)

export interface ExpirationNoticeProps {
  classes?: ClassesType<typeof useStyles>
  expirationDate?: string
  warningThresholdDays: number
}

const ExpirationNotice = ({
  classes: classesProp,
  expirationDate: expirationDateProp,
  warningThresholdDays = DEFAULT_WARNING_THRESHOLD_DAYS,
}: ExpirationNoticeProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Tooltips'])

  const now = moment()
  const expirationDate = moment(expirationDateProp)

  if (expirationDate.isBefore(now)) {
    return (
      <PuiTooltip tooltipPlacement="top" tooltipText={t('Common:EXPIRED')}>
        <WarningIcon className={classNames(classes.expired, classes.icon)} />
      </PuiTooltip>
    )
  }

  if (expirationDate.isBefore(now.clone().add(warningThresholdDays, 'days'))) {
    return (
      <PuiTooltip
        tooltipPlacement="top"
        tooltipText={t('Tooltips:EXPIRES_SOON')}
      >
        <WarningIcon
          className={classNames(classes.expiresSoon, classes.icon)}
        />
      </PuiTooltip>
    )
  }

  return null
}

export default ExpirationNotice
