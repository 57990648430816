import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  CREATE_ADDENDUM,
  createAddendum,
  createAddendumFailure,
  createAddendumSuccess,
  DELETE_ADDENDUM,
  deleteAddendum,
  deleteAddendumFailure,
  deleteAddendumSuccess,
  EDIT_ADDENDUM,
  editAddendum,
  editAddendumFailure,
  editAddendumSuccess,
} from '../duck/addendums'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* createAddendumSaga({
  soapId,
  patientId,
  addendum,
}: ReturnType<typeof createAddendum>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.createAddendum,
      soapId,
      patientId,
      addendum,
    )
    yield call(updateEntities, entities)
    yield put(createAddendumSuccess(soapId, patientId, result))
  } catch (error) {
    yield put(createAddendumFailure(error as ApiError))
  }
}

export function* editAddendumSaga({
  soapId,
  patientId,
  addendum,
}: ReturnType<typeof editAddendum>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.editAddendum,
      soapId,
      patientId,
      addendum,
    )
    yield call(updateEntities, entities)
    yield put(editAddendumSuccess(result))
  } catch (error) {
    yield put(editAddendumFailure(error as ApiError))
  }
}

export function* deleteAddendumSaga({
  soapId,
  patientId,
  addendumId,
}: ReturnType<typeof deleteAddendum>) {
  try {
    yield* requestAPI(API.deleteAddendum, soapId, patientId, addendumId)
    yield put(deleteAddendumSuccess(addendumId))
  } catch (error) {
    yield put(deleteAddendumFailure(error as ApiError))
  }
}

function* watchCreateAddendum() {
  yield takeLatest(CREATE_ADDENDUM, createAddendumSaga)
}

function* watchEditAddendum() {
  yield takeLatest(EDIT_ADDENDUM, editAddendumSaga)
}

function* watchDeleteAddendum() {
  yield takeLatest(DELETE_ADDENDUM, deleteAddendumSaga)
}

export default function* addendumSaga() {
  yield all([watchCreateAddendum(), watchEditAddendum(), watchDeleteAddendum()])
}
