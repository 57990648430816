import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  CircularProgressOverlay,
  DateUtils,
  Nil,
  StateLabel,
  Text,
  Utils,
  WellnessPlanBenefit,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
// @ts-ignore
import { patientMembershipsSelectors } from '~/store/duck/patientMemberships'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCurrentBusinessHasOpenBoop } from '~/store/reducers/auth'
import { getAppointmentTypes } from '~/store/reducers/constants'
import { getWellnessPlansIsFetching } from '~/store/reducers/wellnessPlans'
import { Membership } from '~/types'
import useDialog from '~/utils/useDialog'

import { getBenefitIsAccessToBoop } from '../wellness-plans/wellnessPlanUtils'
import PatientMembershipDetailsCoverRow from './PatientMembershipDetailsCoverRow'

const useStyles = makeStyles(
  (theme) => ({
    textLimitReached: {
      color: theme.colors.tabLabel,
    },
    column: {
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tableBorder,
      },
    },
    benefitRow: {
      borderBottom: theme.constants.tableBorder,
    },
    benefitRowLimitReached: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    link: {
      marginTop: theme.spacing(0.5),
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    stateLabel: {
      marginTop: theme.spacing(1),
      width: 'fit-content',
      fontSize: '1rem',
    },
  }),
  { name: 'PatientMembershipDetails' },
)

const getGroupedBenefits = (
  membership: Membership | Nil,
  openBoop: boolean,
) => {
  const benefits = R.flatten(
    R.pluck('benefits', membership?.plans || []).filter(Boolean),
  )

  const filteredBenefits = openBoop
    ? R.reject(getBenefitIsAccessToBoop, benefits)
    : benefits

  const groupedBenefits = R.groupBy(
    R.propOr(undefined, 'globalBenefitId'),
    filteredBenefits,
  )

  const sortedBenefits = R.sortBy<WellnessPlanBenefit[]>(
    (array) => array?.[0]?.name || '',
    Object.values(groupedBenefits),
  )

  return sortedBenefits
}

interface PatientMembershipDetailsProps {
  patientId: string
}

const PatientMembershipDetails = ({
  patientId,
}: PatientMembershipDetailsProps) => {
  const classes = useStyles()
  const membership: Membership | Nil = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )
  const isFetching = useSelector(getWellnessPlansIsFetching)
  const AppointmentTypes = useSelector(getAppointmentTypes)
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const { t } = useTranslation(['Common', 'Plurals', 'Memberships'])

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(membership?.clientId, openInvoiceDialog)

  const openInvoiceSafe = (invoiceId: string) => {
    openInvoice({
      clientId: membership?.clientId,
      patientId,
      invoiceId,
      id: invoiceId,
    })
  }

  const groupedBenefits = getGroupedBenefits(membership, openBoop)

  return (
    <Grid container item direction="column">
      <CircularProgressOverlay
        open={isFetching}
        preloaderText={t(
          'Memberships:PATIENT_MEMBERSHIP_DETAILS.FETCHING_WELLNESS_PLAN',
        )}
      />
      <Grid container item direction="column">
        {groupedBenefits?.map((benefitsGroup) =>
          benefitsGroup.map((benefit: WellnessPlanBenefit, index: number) => {
            const showTitle = index === 0

            const appointmentTypes =
              benefit.appointmentTypeIds?.map((id) =>
                Utils.findById(id, AppointmentTypes),
              ) || []
            const covers = benefit.cover || []
            const plan = (membership?.plans || []).find(
              (membershipPlan) => membershipPlan.planId === benefit.planId,
            )

            const shouldGroupCoverages =
              appointmentTypes.length > 0 && covers.length > 0

            return (
              <Grid
                container
                item
                className={classNames(classes.benefitRow, {
                  [classes.benefitRowLimitReached]: benefit.limitReached,
                })}
                key={benefit.id}
              >
                <Grid
                  container
                  item
                  className={classes.column}
                  direction="column"
                  px={3}
                  py={1}
                  xs={3}
                >
                  <Text
                    strong
                    className={classNames({
                      [classes.textLimitReached]: benefit.limitReached,
                    })}
                    variant="subheading3"
                  >
                    {showTitle ? benefit.name : ''}
                  </Text>
                </Grid>
                <Grid
                  container
                  item
                  className={classes.column}
                  direction="column"
                  px={2}
                  py={1}
                  xs={2}
                >
                  <Text variant="body2">
                    {plan?.name || plan?.planName || ''}
                  </Text>
                  {benefit.limitReached && (
                    <StateLabel warning className={classes.stateLabel}>
                      {t('Common:LIMIT_REACHED')}
                    </StateLabel>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  className={classes.column}
                  direction="column"
                  px={2}
                  py={1}
                  xs={4}
                >
                  <Text
                    strong
                    className={classNames({
                      [classes.textLimitReached]: benefit.limitReached,
                    })}
                    variant="subheading3"
                  >
                    {benefit.usages?.length || 0}/
                    {benefit.limit
                      ? t('Plurals:Z_ICU_WORKAROUND.USAGES_X_NUMBER_USED', {
                          usagesNumber: benefit.limit,
                        })
                      : t(
                          'Memberships:PATIENT_MEMBERSHIP_DETAILS.USAGES_UNLIMITED_USED',
                        )}
                  </Text>
                  {benefit.usages?.map((usage) => (
                    <Text
                      className={classNames({
                        [classes.link]: Boolean(usage.invoiceId),
                        [classes.textLimitReached]: benefit.limitReached,
                      })}
                      key={usage.id}
                      variant="body2"
                      onClick={() => {
                        if (usage.invoiceId) {
                          openInvoiceSafe(usage.invoiceId)
                        }
                      }}
                    >
                      {usage.name}
                      {usage.creationDate &&
                        ` | ${DateUtils.formatDate(usage.creationDate)}`}
                    </Text>
                  ))}
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={classes.column}
                  direction="column"
                >
                  {shouldGroupCoverages ? (
                    <>
                      <Grid container item direction="column">
                        <Text strong pb={0} pr={2} pt={1} variant="subheading3">
                          {t('Common:APPOINTMENT_TYPES')}
                        </Text>
                        {appointmentTypes.map((coverage) => (
                          <PatientMembershipDetailsCoverRow
                            benefit={benefit}
                            coverage={coverage}
                            key={coverage.id}
                          />
                        ))}
                      </Grid>
                      <Grid container item direction="column">
                        <Text strong pb={0} pr={2} pt={1} variant="subheading3">
                          {t(
                            'Memberships:PATIENT_MEMBERSHIP_DETAILS.CHARGES_COVERED',
                          )}
                        </Text>
                        {covers.map((coverage) => (
                          <PatientMembershipDetailsCoverRow
                            benefit={benefit}
                            coverage={coverage}
                            key={coverage.id}
                          />
                        ))}
                      </Grid>
                    </>
                  ) : (
                    [...appointmentTypes, ...covers].map((coverage) => (
                      <PatientMembershipDetailsCoverRow
                        benefit={benefit}
                        coverage={coverage}
                        key={`${coverage.id}-${coverage.name}`}
                      />
                    ))
                  )}
                </Grid>
              </Grid>
            )
          }),
        )}
      </Grid>
    </Grid>
  )
}

export default PatientMembershipDetails
