import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  FieldProp,
  PermissionArea,
  PuiTextArea,
  PuiTooltip,
  useFields,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps, PracticeMobileFields } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      maxWidth: 180,
      flexDirection: 'row',
      alignItems: 'center',
    },
    infoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
    },
    npsTooltip: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'NspSystem' },
)

const NPSSystem = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Businesses'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))
  const isNpsClinicLevelEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NPS_CLINIC_LEVEL),
  )

  const { fields, reset } = useFields(
    [
      {
        name: 'npsEnabled',
        type: 'toggle',
        label: t('Admin:PRACTICE.NPS_SYSTEM_TOGGLER'),
        initialValue: business.npsEnabled,
      },
      ...(isNpsClinicLevelEnabled
        ? ([
            {
              name: 'googleReviewLink',
              label: t('Businesses:GENERAL_INFORMATION.GOOGLE_REVIEW_LINK'),
              validators: ['website'],
              initialValue: business.googleReviewLink || '',
            },
          ] as FieldProp[])
        : []),
    ],
    false,
  )

  usePracticeFieldsSection<PracticeMobileFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.NPS_SYSTEM,
    reset,
  })

  const { npsEnabled, googleReviewLink } = fields

  const getGoogleReviewLinkDescription = () => (
    <>
      {t('Businesses:GENERAL_INFORMATION.GOOGLE_REVIEW_LINK_DESCRIPTION', {
        Google_Business_Account: (
          <a
            href="https://support.google.com/business/answer/3474122?hl=en&ref_topic=6109351"
            key="google_business_account"
          >
            Google Business Profile.
          </a>
        ),
        Google_Reviews: (
          <a
            href="https://support.google.com/business/answer/3474122?hl=en&ref_topic=6109351"
            key="google-reviews"
          >
            Google reviews.
          </a>
        ),
      })}
    </>
  )

  return (
    <Grid container item>
      <FormControl className={classes.formControl} margin="normal">
        <PuiSwitch
          field={npsEnabled}
          label={npsEnabled.label}
          readOnly={isNpsClinicLevelEnabled ? !permissions.update : false}
        />
        <PuiTooltip
          tooltipPlacement="top"
          tooltipText={
            isNpsClinicLevelEnabled
              ? t('Admin:PRACTICE.NPS_TOOLTIP_NEW')
              : t('Admin:PRACTICE.NPS_TOOLTIP')
          }
        >
          <InfoIcon className={classes.infoIcon} />
        </PuiTooltip>
      </FormControl>
      {isNpsClinicLevelEnabled && npsEnabled.value && (
        <Grid container item>
          <Grid item md>
            <PuiTextArea
              field={googleReviewLink}
              label={googleReviewLink.label}
              labelProps={{
                allowWrap: true,
                tooltipText: getGoogleReviewLinkDescription(),
                className: classes.npsTooltip,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default NPSSystem
