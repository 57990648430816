import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  CircularProgressOverlay,
  PuiSelect,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  createImportSession,
  getIsLoadingImportSessions,
} from '~/store/duck/migrationV2'
import { getPimsFull } from '~/store/reducers/constants'
import {
  BaseStepComponentProps,
  SupportedPims,
} from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const useStyles = makeStyles(
  () => ({
    pimsSelect: {
      width: '100%',
    },
  }),
  { name: 'SessionCreation' },
)

const SessionCreation = ({
  businessId,
  stepController,
}: BaseStepComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const isLoading = useSelector(getIsLoadingImportSessions)
  const PimsFull = useSelector(getPimsFull)
  const UniversalId = Utils.findConstantIdByName('Other', PimsFull)

  const selectablePimsList = PimsFull.filter(
    ({ name }) => name in SupportedPims,
  ).sort((a, b) => (a.name > b.name ? 1 : -1)) // Sort alphabetically

  const {
    fields: { selectedPimsId },
  } = useFields(
    [
      {
        name: 'selectedPimsId',
        initialValue: selectablePimsList[0]?.id,
        type: 'select',
      },
    ],
    false,
  )

  const handleProceed = () => {
    dispatch(
      createImportSession({
        businessId,
        exporterPimsId: selectedPimsId.value,
        sourcePimsId: UniversalId,
      }),
    )
    stepController.next()
  }

  return (
    <MigrationStep>
      <MigrationStepContent>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:LOOKING_FOR_SESSIONS')}
          />
        ) : (
          <PuiSelect
            className={classes.pimsSelect}
            field={selectedPimsId}
            items={selectablePimsList}
            variant="filled"
          />
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:PROCEED')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default SessionCreation
