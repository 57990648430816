import React from 'react'
import { useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { useLockableComponent } from '~/store/hooks/soap'
import { getCRUDByArea } from '~/store/reducers/auth'

import RemindersWidgetLocked from './RemindersWidgetLocked'
import RemindersWidgetUnlocked from './RemindersWidgetUnlocked'

const RemindersWidget = () => {
  const RemindersWidgetComponent = useLockableComponent(
    RemindersWidgetUnlocked,
    RemindersWidgetLocked,
  )

  const reminderPermissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDERS),
  )

  if (!reminderPermissions.read) {
    return null
  }

  return <RemindersWidgetComponent />
}

export default RemindersWidget
