import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import {
  MembershipPayment,
  RefundMembershipData,
  SubscriptionInfo,
} from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const UPDATE_MEMBERSHIP_PAYMENTS =
  'membershipPayments/UPDATE_MEMBERSHIP_PAYMENTS'

export const FETCH_MEMBERSHIP_PAYMENTS =
  'membershipPayments/FETCH_MEMBERSHIP_PAYMENTS'
export const FETCH_MEMBERSHIP_PAYMENTS_SUCCESS =
  'membershipPayments/FETCH_MEMBERSHIP_PAYMENTS_SUCCESS'
export const FETCH_MEMBERSHIP_PAYMENTS_FAILURE =
  'membershipPayments/FETCH_MEMBERSHIP_PAYMENTS_FAILURE'

export const FETCH_MORE_MEMBERSHIP_PAYMENTS =
  'membershipPayments/FETCH_MORE_MEMBERSHIP_PAYMENTS'
export const FETCH_MORE_MEMBERSHIP_PAYMENTS_SUCCESS =
  'membershipPayments/FETCH_MORE_MEMBERSHIP_PAYMENTS_SUCCESS'
export const FETCH_MORE_MEMBERSHIP_PAYMENTS_FAILURE =
  'membershipPayments/FETCH_MORE_MEMBERSHIP_PAYMENTS_FAILURE'

export const REFRESH_MEMBERSHIP_PAYMENTS =
  'membershipPayments/REFRESH_MEMBERSHIP_PAYMENTS'
export const REFRESH_MEMBERSHIP_PAYMENTS_SUCCESS =
  'membershipPayments/REFRESH_MEMBERSHIP_PAYMENTS_SUCCESS'
export const REFRESH_MEMBERSHIP_PAYMENTS_FAILURE =
  'membershipPayments/REFRESH_MEMBERSHIP_PAYMENTS_FAILURE'

export const REFUND_MEMBERSHIP_PAYMENT =
  'membershipPayments/REFUND_MEMBERSHIP_PAYMENT'
export const REFUND_MEMBERSHIP_PAYMENT_SUCCESS =
  'membershipPayments/REFUND_MEMBERSHIP_PAYMENT_SUCCESS'
export const REFUND_MEMBERSHIP_PAYMENT_FAILURE =
  'membershipPayments/REFUND_MEMBERSHIP_PAYMENT_FAILURE'

export const FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO =
  'membershipPayments/FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO'
export const FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_SUCCESS =
  'membershipPayments/FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_SUCCESS'
export const FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_FAILURE =
  'membershipPayments/FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_FAILURE'

export const updateMembershipPayments = (
  membershipPayments: Record<string, MembershipPayment>,
) => ({
  type: UPDATE_MEMBERSHIP_PAYMENTS,
  membershipPayments,
})

export const fetchMembershipPayments = (
  from: number,
  to: number,
  clientId: string,
) => ({
  type: FETCH_MEMBERSHIP_PAYMENTS,
  from,
  to,
  clientId,
})
export const fetchMembershipPaymentsSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_MEMBERSHIP_PAYMENTS_SUCCESS,
  list,
  totalCount,
})
export const fetchMembershipPaymentsFailure = (error: ApiError) => ({
  type: FETCH_MEMBERSHIP_PAYMENTS_FAILURE,
  error,
})

export const fetchMoreMembershipPayments = (
  from: number,
  to: number,
  clientId: string,
) => ({
  type: FETCH_MORE_MEMBERSHIP_PAYMENTS,
  from,
  to,
  clientId,
})
export const fetchMoreMembershipPaymentsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_MEMBERSHIP_PAYMENTS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreMembershipPaymentsFailure = (error: ApiError) => ({
  type: FETCH_MORE_MEMBERSHIP_PAYMENTS_FAILURE,
  error,
})

export const refreshMembershipPayments = (
  from: number,
  to: number,
  clientId: string,
) => ({
  type: REFRESH_MEMBERSHIP_PAYMENTS,
  from,
  to,
  clientId,
})
export const refreshMembershipPaymentsSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: REFRESH_MEMBERSHIP_PAYMENTS_SUCCESS,
  list,
  totalCount,
})
export const refreshMembershipPaymentsFailure = (error: ApiError) => ({
  type: REFRESH_MEMBERSHIP_PAYMENTS_FAILURE,
  error,
})

export const refundMembershipPayment = (refundData: RefundMembershipData) => ({
  type: REFUND_MEMBERSHIP_PAYMENT,
  ...refundData,
})
export const refundMembershipPaymentSuccess = (paymentId: string) => ({
  type: REFUND_MEMBERSHIP_PAYMENT_SUCCESS,
  paymentId,
})
export const refundMembershipPaymentFailure = (error: ApiError) => ({
  type: REFUND_MEMBERSHIP_PAYMENT_FAILURE,
  error,
})

export const fetchRefundMembershipPaymentInfo = (
  paymentId: string,
  subscriptionInfo: SubscriptionInfo,
) => ({
  type: FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO,
  paymentId,
  subscriptionInfo,
})
export const fetchRefundMembershipPaymentInfoSuccess = (paymentId: string) => ({
  type: FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_SUCCESS,
  paymentId,
})
export const fetchRefundMembershipPaymentInfoFailure = (error: ApiError) => ({
  type: FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_FAILURE,
  error,
})

export type MembershipPaymentsState = {
  error: string | null
  isFetching: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, MembershipPayment>
  totalCount: number
}

export const INITIAL_STATE: MembershipPaymentsState = {
  list: [],
  map: {},
  isLoading: false,
  isFetching: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
}

export const membershipPaymentsReducer = (
  state: MembershipPaymentsState = INITIAL_STATE,
  action: AnyAction,
): MembershipPaymentsState => {
  switch (action.type) {
    case FETCH_MEMBERSHIP_PAYMENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
      }
    case FETCH_MEMBERSHIP_PAYMENTS_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
      }
    case FETCH_MEMBERSHIP_PAYMENTS:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_MEMBERSHIP_PAYMENTS:
      return { ...state, isLoading: true }
    case FETCH_MORE_MEMBERSHIP_PAYMENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_MEMBERSHIP_PAYMENTS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case REFRESH_MEMBERSHIP_PAYMENTS_SUCCESS:
      return { ...state, list: action.list, totalCount: action.totalCount }
    case REFRESH_MEMBERSHIP_PAYMENTS_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }
    case UPDATE_MEMBERSHIP_PAYMENTS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.membershipPayments),
      }
    case REFUND_MEMBERSHIP_PAYMENT_FAILURE:
    case FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case REFUND_MEMBERSHIP_PAYMENT_SUCCESS:
    case FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO_SUCCESS:
      return { ...state, isLoading: false }
    case REFUND_MEMBERSHIP_PAYMENT:
    case FETCH_REFUND_MEMBERSHIP_PAYMENT_INFO:
      return { ...state, isLoading: true }
    default:
      return state
  }
}

export const getMembershipPayments = (
  state: RootState,
): MembershipPaymentsState => state.membershipPayments
export const getMembershipPaymentsList = (state: RootState) =>
  getMembershipPayments(state).list
export const getMembershipPaymentsMap = (state: RootState) =>
  getMembershipPayments(state).map
export const getMembershipPayment = (id: string) =>
  createSelector(getMembershipPaymentsMap, (map) => R.prop(id, map))
export const getMultipleMembershipPayments = (ids: string[]) =>
  createSelector(getMembershipPaymentsMap, (map) => R.props(ids, map))
export const getMembershipPaymentsIsLoading = (state: RootState) =>
  getMembershipPayments(state).isLoading
export const getMembershipPaymentsIsFetching = (state: RootState) =>
  getMembershipPayments(state).isFetching
export const getMembershipPaymentsError = (state: RootState) =>
  getMembershipPayments(state).error
export const getMembershipPaymentsTotalCount = (state: RootState) =>
  getMembershipPayments(state).totalCount
