import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'

import PuiButtonGroup from '~/components/common/buttons/PuiButtonGroup'
import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import {
  MarketplaceActivityFilterLabel,
  MarketplaceActivityFilterType,
} from '~/constants/marketplaceConstants'
import {
  getMarketplaceItemsListIsLoading,
  getMarketplaceItemsTotalCount,
} from '~/store/duck/marketplace'
import { addSearch } from '~/utils'

import MarketplaceItemDetails from './MarketplaceItemDetails'
import MarketPlaceTableComponent from './MarketPlaceItemsTableComponent'

const marketplaceActivityFilterList = Object.values(
  MarketplaceActivityFilterLabel,
).map((value, index) => ({
  label: value,
  value: Object.keys(MarketplaceActivityFilterType)[index],
}))

const MarketplacePage = () => {
  const { marketplaceItemId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const totalCount = useSelector(getMarketplaceItemsTotalCount)
  const isLoading = useSelector(getMarketplaceItemsListIsLoading)

  const [marketplaceActivity, setMarketplaceActivity] = useState<string>(
    MarketplaceActivityFilterType.ALL,
  )

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/general/marketplace'))
  }

  if (isMobile && typeof marketplaceItemId !== 'undefined') {
    return (
      <MarketplaceItemDetails
        itemId={marketplaceItemId}
        onClose={onDetailsClose}
      />
    )
  }

  const headerButtons = (
    <Grid container ml={2}>
      <Grid item>
        <PuiButtonGroup
          items={marketplaceActivityFilterList}
          selectedItem={marketplaceActivityFilterList.find(
            (item) => item.value === marketplaceActivity,
          )}
          onItemSelected={({ value }) =>
            value ? setMarketplaceActivity(value) : null
          }
        />
      </Grid>
      {totalCount >= 0 && (
        <Grid item>
          <ListSearchFilterPanel
            isLoading={isLoading}
            searchCount={totalCount}
            searchFieldsQuery=""
          />
        </Grid>
      )}
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      <MarketPlaceTableComponent
        headerButtons={headerButtons}
        marketplaceActivity={marketplaceActivity}
        marketplaceItemId={marketplaceItemId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default MarketplacePage
