import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  createBundle,
  createBundleFailure,
  createBundleSuccess,
  deleteBundle,
  deleteBundleFailure,
  deleteBundleSuccess,
  editBundle,
  editBundleFailure,
  editBundleSuccess,
  fetchBundle,
  fetchBundleFailure,
  fetchBundlesList,
  fetchBundlesList as fetchBundlesListAction,
  fetchBundlesListFailure,
  fetchBundlesListSuccess,
  fetchBundleSuccess,
  fetchMoreItemsForBundlesList,
  fetchMoreItemsForBundlesListFailure,
  fetchMoreItemsForBundlesListSuccess,
} from '../actions/bundles'
import {
  CREATE_BUNDLE,
  DELETE_BUNDLE,
  EDIT_BUNDLE,
  FETCH_BUNDLE,
  FETCH_BUNDLES_LIST,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST,
} from '../actions/types/bundles'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchBundlesListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchBundlesList>) {
  try {
    yield put(startLoading('bundles'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchBundles, from, to, query)
    yield call(updateEntities, entities)
    yield put(fetchBundlesListSuccess(list, totalCount))
    yield put(finishLoading('bundles'))
  } catch (error) {
    yield put(fetchBundlesListFailure(error as ApiError))
    yield put(finishLoading('bundles'))
  }
}

export function* fetchMoreItemsForBundlesListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForBundlesList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchBundles, from, to, query)
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForBundlesListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForBundlesListFailure(error as ApiError))
  }
}

export function* fetchBundleSaga({ id }: ReturnType<typeof fetchBundle>) {
  try {
    const { entities } = yield* requestAPI(API.fetchBundle, id)
    yield call(updateEntities, entities)
    yield put(fetchBundleSuccess())
  } catch (error) {
    yield put(fetchBundleFailure(error as ApiError))
  }
}

export function* createBundleSaga({ bundle }: ReturnType<typeof createBundle>) {
  try {
    const { result, entities } = yield* requestAPI(API.createBundle, bundle)
    yield call(updateEntities, entities)
    yield put(fetchBundlesListAction())
    yield put(createBundleSuccess(result))
  } catch (error) {
    yield put(createBundleFailure(error as ApiError))
  }
}

export function* editBundleSaga({ bundle }: ReturnType<typeof editBundle>) {
  try {
    const { entities } = yield* requestAPI(API.editBundle, bundle)
    yield call(updateEntities, entities)
    yield put(editBundleSuccess())
  } catch (error) {
    yield put(editBundleFailure(error as ApiError))
  }
}

export function* deleteBundleSaga({
  bundleId,
}: ReturnType<typeof deleteBundle>) {
  try {
    yield* requestAPI(API.deleteBundle, bundleId)
    yield put(deleteBundleSuccess(bundleId))
  } catch (error) {
    yield put(deleteBundleFailure(error as ApiError))
  }
}

function* watchFetchBundlesList() {
  yield takeLeading(FETCH_BUNDLES_LIST, fetchBundlesListSaga)
}

function* watchFetchMoreItemsForBundlesList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_BUNDLES_LIST,
    fetchMoreItemsForBundlesListSaga,
  )
}

function* watchFetchBundle() {
  yield takeLeading(FETCH_BUNDLE, fetchBundleSaga)
}

function* watchCreateBundle() {
  yield takeLeading(CREATE_BUNDLE, createBundleSaga)
}

function* watchEditBundle() {
  yield takeLeading(EDIT_BUNDLE, editBundleSaga)
}

function* watchDeleteBundle() {
  yield takeLeading(DELETE_BUNDLE, deleteBundleSaga)
}

export default function* bundlesSaga() {
  yield all([
    watchFetchBundlesList(),
    watchFetchMoreItemsForBundlesList(),
    watchFetchBundle(),
    watchCreateBundle(),
    watchEditBundle(),
    watchDeleteBundle(),
  ])
}
