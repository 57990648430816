import React from 'react'
import { Trans } from 'react-i18next'

import Link from '~/components/common/link/Link'

const LabTests = () => (
  <Trans
    components={{ linkToLab: <Link to="/admin/catalog/lab-tests" /> }}
    i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.LAB_TESTS.TEXT"
  />
)
export default LabTests
