import { Defaults } from '@pbt/pbt-ui-components'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchTimeline = (
  businessId: string,
  clientId: string,
  patientId: string,
  type: string,
  startDate: string,
  endDate: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath(`clients/${clientId}/patients/${patientId}/timeline`),
    {
      method: 'GET',
      params: {
        businessId,
        type,
        from,
        to,
        startDate,
        endDate,
      },
    },
    true,
    Schema.timeline,
  )
