import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { createContact, getContactsIsCreating } from '~/store/duck/contacts'
import { Contact as ContactType, DataHandleWithUnsavedChanges } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import Contact from './Contact'

const useStyles = makeStyles(
  () => ({
    paper: {
      maxWidth: 650,
      width: 650,
    },
    addButton: {
      minWidth: 150,
    },
  }),
  { name: 'ContactDialog' },
)

export interface ContactDialogProps extends BasePuiDialogProps {
  contact?: ContactType
}

const ContactDialog = ({ contact, open, onClose }: ContactDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const contactRef = useRef<DataHandleWithUnsavedChanges>(null)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const contactsIsCreating = useSelector(getContactsIsCreating)

  const closeDialogAfterCreation = useCloseAfterCreation(
    onClose,
    getContactsIsCreating,
  )

  const handleAdd = () => {
    if (contactRef?.current?.validate()) {
      const newContact = contactRef?.current?.get()
      closeDialogAfterCreation()
      dispatch(createContact(newContact))
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: handleAdd,
      }}
      actions={
        <ButtonWithLoader
          className={classes.addButton}
          disabled={contactsIsCreating}
          loading={contactsIsCreating}
          onClick={() => handleAdd()}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="contact-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={() => contactRef.current?.hasUnsavedChanges() || false}
      maxWidth={isMobile ? 'sm' : 'md'}
      open={open}
      title={t('Dialogs:CONTACT_DIALOG.NEW_CONTACT')}
      onClose={onClose}
    >
      <Contact contact={contact} ref={contactRef} />
    </PuiDialog>
  )
}

export default ContactDialog
