import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { pluck } from 'ramda'
import {
  AlertIconType,
  FileTemplate,
  IdObject,
  Nil,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import AttachmentDialog, {
  AttachmentDialogProps,
} from '~/components/common/inputs/attachment/attachment-dialog/AttachmentDialog'
import DialogNames from '~/constants/DialogNames'
import {
  attachDocumentsToMessage,
  uploadFile,
} from '~/store/actions/conversationMessages'
import {
  getConversationMessagesError,
  getIsAttachingDocuments,
  getIsUploadingFile,
} from '~/store/reducers/conversationMessages'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

interface ConversationAttachmentDialogProps
  extends Omit<AttachmentDialogProps, 'onListProceed' | 'onPreviewProceed'> {
  clientId: string | Nil
  isLoading?: boolean
  transport?: ConversationTransport
}

const ConversationAttachmentDialog = ({
  onClose,
  clientId,
  patientId,
  eventId,
  transport,
  isLoading,
  ...rest
}: ConversationAttachmentDialogProps) => {
  const dispatch = useDispatch()
  const isUploading = useSelector(getIsUploadingFile)
  const conversationMessagesError = useSelector(getConversationMessagesError)
  const { t } = useTranslation(['Dialog'])

  const [openUploadErrorDialog] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const checkErrorsAndClose = () => {
    if (onClose) {
      onClose()
    }
    if (conversationMessagesError) {
      openUploadErrorDialog({
        iconType: AlertIconType.WARN,
        message: t('Dialogs:CONVERSATION_ATTACHMENT_DIALOG.MESSAGE'),
      })
    }
  }

  const setCloseAfterUploadedOn = useCloseAfterCreation(
    checkErrorsAndClose,
    getIsUploadingFile,
  )
  const setCloseAfterAttachedOn = useCloseAfterCreation(
    onClose,
    getIsAttachingDocuments,
  )

  const onListProceed = (orderedItems: IdObject[]) => {
    const ids = pluck('id', orderedItems)
    setCloseAfterAttachedOn()
    dispatch(
      attachDocumentsToMessage(ids, {
        clientId,
        patientId,
        appointmentId: eventId,
      }),
    )
  }

  const onPreviewProceed = (file: FileTemplate) => {
    setCloseAfterUploadedOn()
    dispatch(uploadFile(file, clientId, patientId))
  }

  return (
    <AttachmentDialog
      eventId={eventId}
      isLoading={isLoading || isUploading}
      patientId={patientId}
      onClose={onClose}
      onListProceed={onListProceed}
      onPreviewProceed={onPreviewProceed}
      {...rest}
    />
  )
}

export default ConversationAttachmentDialog
