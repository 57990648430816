import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import {
  fetchLabTestsBulkPrices,
  startLabTestsBulkPricesSession,
} from '~/store/duck/bulkPrices'

import { BulkPricesCategoryConstant } from '../bulk-prices/BulkPricesEditFilters'
import BulkPricesPage from '../bulk-prices/BulkPricesPage'

const LabTestsBulkPricesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const loadItems = (from: number, to: number, category: string | number) => {
    dispatch(fetchLabTestsBulkPrices({ from, to, category }))
  }

  const startNewSession = () => {
    dispatch(startLabTestsBulkPricesSession({}))
  }

  return (
    <BulkPricesPage
      categoryLabel={t('Prices:SELECT_VENDOR')}
      categoryName={BulkPricesCategoryConstant.LAB_VENDORS}
      entityType={BulkPriceEntityTypes.LAB_TEST}
      loadItems={loadItems}
      startNewSession={startNewSession}
      title={t('Common:LAB_TEST_CATALOG')}
    />
  )
}

export default LabTestsBulkPricesPage
