import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    extendedPriceCell: {
      minWidth: 120,
    },
  }),
  { name: 'ExtendedPriceCellHead' },
)

export interface ExtendedPriceCellHeadProps {
  className?: string
}

const ExtendedPriceCellHead = ({ className }: ExtendedPriceCellHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <TableCell
      align="right"
      className={classNames(className, classes.extendedPriceCell)}
    >
      {t('Common:EXTENDED_PRICE')}
    </TableCell>
  )
}

export default ExtendedPriceCellHead
