import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  Patient,
  PuiDialog,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import UserSelect from '~/components/common/inputs/UserSelect'
import AssignPatientSearch from '~/components/common/orphans-assignment/AssignPatientSearch'
import PatientSearchResult from '~/components/common/orphans-assignment/PatientSearchResult'
import {
  assignLabTest,
  fetchLabTestDetails,
  getAllLabTestsIsLoading,
} from '~/store/duck/labTestsDashboard'
import {
  cleanUpLastAppointments,
  fetchLastMonthAppointments,
  getAppointmentsList,
  getLastAppointmentsIsLoading,
} from '~/store/duck/lastAppointments'
import { LabTestDashboardOrder } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import DetailsBackButton from '../clients/DetailsBackButton'
import ClientView from '../timetable/scheduler/appointment/ClientView'
import { parseLabTestIdentifier } from './lab-tests-table/labTestUtils'
import LabResultPatientAppointments from './LabResultPatientAppointments'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      overflow: 'visible',
    },
    paperSm: {
      width: 660,
      maxWidth: 660,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    paperLg: {
      width: 'calc(100% - 64px)',
      maxWidth: 'calc(100% - 64px)',
    },
    dialogContentRoot: {
      overflow: 'visible',
    },
  }),
  { name: 'LabResultAssignmentDialog' },
)

const AssignmentDialogDisplay = {
  SEARCH_BOX: 'search-box',
  SEARCH_RESULT: 'search-result',
  SINGLE_PATIENT: 'single-patient',
}

export interface LabResultAssignmentDialogProps extends BasePuiDialogProps {
  labOrder: LabTestDashboardOrder | Nil
  labOrderInfo?: string
  labTestId: string | Nil
  vendorId: string | Nil
}

const LabResultAssignmentDialog = ({
  labOrder,
  labOrderInfo = '',
  vendorId: vendorIdProp,
  labTestId,
  open,
  onClose,
}: LabResultAssignmentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs', 'Invoices'])

  const [actualDisplay, setActualDisplay] = useState(
    AssignmentDialogDisplay.SEARCH_BOX,
  )
  const [selectedClientId, setSelectedClientId] = useState<string>()
  const [selectedPatientId, setSelectedPatientId] = useState<string>()
  const [searchTerm, setSearchTerm] = useState('')
  const [fieldsQuery, setFieldsQuery] = useState('')
  const [additionalParams, setAdditionalParams] = useState<[string, string][]>(
    [],
  )
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string>()
  const [appointmentsReceived, setAppointmentsReceived] = useState(false)

  const patientAppointments = useSelector(getAppointmentsList)
  const labTestsIsLoading = useSelector(getAllLabTestsIsLoading)

  const setCloseOnAppointmentsReceived = useCloseAfterCreation(
    () => setAppointmentsReceived(true),
    getLastAppointmentsIsLoading,
  )

  const {
    fields: { assignedVetId },
    validate,
  } = useFields([
    {
      name: 'assignedVetId',
      label: t('Common:ASSIGN_A_DOCTOR'),
      validators: [],
    },
  ])

  const handleDialogClosed = () => {
    dispatch(cleanUpLastAppointments())
    if (onClose) {
      onClose()
    }
  }

  const setCloseOnLabTestReceived = useCloseAfterCreation(
    handleDialogClosed,
    getAllLabTestsIsLoading,
  )

  const setCloseOnLabTestSaved = useCloseAfterCreation(() => {
    const { vendorId, orderId, soapId, invoiceId, patientId, vetId } = labTestId
      ? parseLabTestIdentifier(labTestId)
      : ({} as ReturnType<typeof parseLabTestIdentifier>)
    setCloseOnLabTestReceived()
    dispatch(
      fetchLabTestDetails(
        vendorId,
        orderId,
        soapId,
        invoiceId,
        patientId,
        vetId,
      ),
    )
  }, getAllLabTestsIsLoading)

  useEffect(() => {
    if (selectedPatientId) {
      setAppointmentsReceived(false)
      setCloseOnAppointmentsReceived()
      dispatch(fetchLastMonthAppointments(selectedPatientId, true))
    }
  }, [selectedPatientId])

  const handleBackToSearchResults = () => {
    setSelectedClientId(undefined)
    setSelectedPatientId(undefined)
    setActualDisplay(AssignmentDialogDisplay.SEARCH_BOX)
  }

  const handlePatientSelection = (
    clientId: string,
    patientId: string | Nil,
  ) => {
    // NOTE: do not handle clicks on client-only box
    if (patientId) {
      dispatch(cleanUpLastAppointments())
      setSelectedClientId(clientId)
      setSelectedPatientId(patientId)
      setActualDisplay(AssignmentDialogDisplay.SINGLE_PATIENT)
    }
  }

  const handleAddLabResults = (eventId?: string) => {
    if (validate() && selectedPatientId && selectedClientId) {
      const { id: orderId, resultId } = labOrder || {}
      dispatch(
        assignLabTest({
          eventId,
          orderId,
          resultId,
          vendorId: vendorIdProp,
          vetId: assignedVetId.value,
          patientId: selectedPatientId,
          clientId: selectedClientId,
        }),
      )
      setCloseOnLabTestSaved()
    }
  }

  const searchResultActionButtons = [
    {
      title: t('Common:ADD_LAB_RESULTS'),
      disabled: (patient: Patient) => !patient?.lastAppointment,
      onClick: (patient: Patient) =>
        handleAddLabResults(patient?.lastAppointment?.appointmentId),
    },
  ]

  const isAddLabResultsDisabled = !assignedVetId.value

  return (
    <PuiDialog
      actions={
        actualDisplay === AssignmentDialogDisplay.SINGLE_PATIENT ? (
          <ButtonWithLoader
            disabled={isAddLabResultsDisabled}
            loading={labTestsIsLoading}
            onClick={() => handleAddLabResults(selectedAppointmentId)}
          >
            {t('Common:ADD_LAB_RESULTS')}
          </ButtonWithLoader>
        ) : null
      }
      aria-labelledby="lab-result-assignment-dialog"
      classes={{
        paper: classNames(classes.paper, {
          [classes.paperSm]:
            actualDisplay !== AssignmentDialogDisplay.SEARCH_RESULT,
          [classes.paperLg]:
            actualDisplay === AssignmentDialogDisplay.SEARCH_RESULT,
        }),
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:LAB_RESULT_ASSIGNMENT_DIALOG.ASSIGN_LAB_TO_PATIENT')}
      onClose={handleDialogClosed}
    >
      {actualDisplay === AssignmentDialogDisplay.SEARCH_BOX && (
        <AssignPatientSearch
          actionBtnTitle={t(
            'Dialogs:LAB_RESULT_ASSIGNMENT_DIALOG.ASSIGN_LAB_RESULT',
          )}
          info={labOrderInfo}
          onExpand={(newSearchTerm, newFieldsQuery, newAdditionalParams) => {
            setSearchTerm(newSearchTerm)
            setFieldsQuery(newFieldsQuery || '')
            setAdditionalParams(newAdditionalParams || [])
            setActualDisplay(AssignmentDialogDisplay.SEARCH_RESULT)
          }}
          onItemClick={handlePatientSelection}
        />
      )}
      {actualDisplay === AssignmentDialogDisplay.SINGLE_PATIENT && (
        <Grid container item direction="column" wrap="nowrap">
          <Grid item my={1} px={1.5}>
            <DetailsBackButton onClick={handleBackToSearchResults}>
              {t('Common:BACK_TO_SEARCH')}
            </DetailsBackButton>
          </Grid>
          <ClientView
            clientId={selectedClientId}
            patientId={selectedPatientId}
          />
          <Grid container p={2}>
            {!patientAppointments.length && appointmentsReceived ? (
              <Text width="100%">
                {t('Invoices:APPOINTMENTS_LIST.EMPTY_PLACEHOLDER')}
              </Text>
            ) : (
              <LabResultPatientAppointments
                selectedAppointmentId={selectedAppointmentId}
                setSelectedAppointmentId={setSelectedAppointmentId}
              />
            )}
            <Grid item mt={1} pl={1} xs={5}>
              <UserSelect field={assignedVetId} label={assignedVetId.label} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {actualDisplay === AssignmentDialogDisplay.SEARCH_RESULT && (
        <PatientSearchResult
          showLastAppointment
          actionButtons={searchResultActionButtons}
          additionalParams={additionalParams}
          fieldsQuery={fieldsQuery}
          searchTerm={searchTerm}
          onItemClick={handlePatientSelection}
          onSearchCancelled={handleBackToSearchResults}
        />
      )}
    </PuiDialog>
  )
}

export default LabResultAssignmentDialog
