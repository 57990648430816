import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTooltip } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import {
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceUpdateFieldCallback,
} from '~/types'

import {
  getHighValue,
  getLowValue,
  quantityFieldChanged,
} from '../../invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    scatteredQuantityCell: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    noBorder: {
      borderRight: 'none !important',
    },
    tooltip: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'QuantityCellBody' },
)

export interface QuantityCellBodyProps {
  allowDecimalQuantity: boolean
  blockedUsedQuantity?: boolean
  className?: string
  group: InvoiceLineItemGroup
  isDeclined: boolean
  isEstimate: boolean
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  prePaidEnabled: boolean
  rangeEnabled: boolean
  updateField: InvoiceUpdateFieldCallback
}

const QuantityCellBody = ({
  className,
  prePaidEnabled,
  isGrouped,
  isDeclined,
  allowDecimalQuantity,
  isReadOnly,
  rangeEnabled,
  item,
  updateField,
  group,
  isEstimate,
  blockedUsedQuantity,
}: QuantityCellBodyProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Tooltips')

  return (
    <>
      {!prePaidEnabled && (
        <TableCell align="right" className={className}>
          {!isDeclined && !isGrouped && (
            <Grid container item alignItems="center" wrap="nowrap">
              <QuantityInput
                disableUnderline
                InputProps={{
                  disableUnderline: isReadOnly,
                }}
                allowDecimal={allowDecimalQuantity}
                disabled={isReadOnly}
                min={rangeEnabled ? 0 : allowDecimalQuantity ? 0.001 : 1}
                minReachedTooltipEnabled={!rangeEnabled}
                value={getLowValue(item.quantity)}
                onChange={(event) => {
                  updateField(
                    event,
                    rangeEnabled ? 'quantity.low' : 'quantity',
                    group,
                    item,
                    false,
                    quantityFieldChanged,
                  )
                }}
              />
            </Grid>
          )}
        </TableCell>
      )}
      {/* @NOTE: we can not get both rangeEnabled and prePaidEnabled */}
      {rangeEnabled && !prePaidEnabled && (
        <TableCell align="right" className={className}>
          {!isDeclined && !isGrouped && (
            <Grid container item alignItems="center" wrap="nowrap">
              <QuantityInput
                disableUnderline
                minReachedTooltipEnabled
                InputProps={{
                  disableUnderline: isReadOnly,
                }}
                allowDecimal={allowDecimalQuantity}
                disabled={isReadOnly}
                min={allowDecimalQuantity ? 0.001 : 1}
                value={getHighValue(item.quantity)}
                onChange={(event) => {
                  updateField(
                    event,
                    'quantity.high',
                    group,
                    item,
                    false,
                    quantityFieldChanged,
                  )
                }}
              />
            </Grid>
          )}
        </TableCell>
      )}
      {!isEstimate && prePaidEnabled && !isGrouped && (
        <>
          <TableCell
            align="center"
            className={classNames(
              className,
              classes.scatteredQuantityCell,
              classes.noBorder,
            )}
          >
            <QuantityInput
              disableUnderline
              minReachedTooltipEnabled
              InputProps={{
                disableUnderline: isReadOnly,
              }}
              allowDecimal={allowDecimalQuantity}
              disabled={isReadOnly}
              max={999}
              min={0}
              value={getHighValue(item.quantity)}
              onChange={(event) => {
                updateField(
                  event,
                  'quantity',
                  group,
                  item,
                  false,
                  quantityFieldChanged,
                )
              }}
            />
          </TableCell>
          <TableCell
            align="center"
            className={classNames(
              className,
              classes.scatteredQuantityCell,
              classes.noBorder,
            )}
          >
            <PuiTooltip
              openOnClick
              classes={{
                tooltip: classes.tooltip,
              }}
              disabled={!blockedUsedQuantity}
              tooltipText={t('Tooltips:QUANTITY_CELL_BODY_INSTRUCTION')}
            >
              <Grid item>
                <QuantityInput
                  disableUnderline
                  InputProps={{
                    disableUnderline: blockedUsedQuantity || isReadOnly,
                  }}
                  allowDecimal={allowDecimalQuantity}
                  disabled={blockedUsedQuantity || isReadOnly}
                  max={999}
                  min={0}
                  minReachedTooltipEnabled={!blockedUsedQuantity}
                  value={
                    item.prepaid
                      ? Number(item.usedQuantity)
                      : getHighValue(item.quantity)
                  }
                  onChange={(event) => {
                    updateField(
                      event,
                      item.prepaid ? 'usedQuantity' : 'quantity',
                      group,
                      item,
                      false,
                      quantityFieldChanged,
                    )
                  }}
                />
              </Grid>
            </PuiTooltip>
          </TableCell>
          <TableCell
            align="center"
            className={classNames(className, classes.scatteredQuantityCell)}
          >
            {item.prepaidRemaining}
          </TableCell>
        </>
      )}
      {prePaidEnabled && isGrouped && (
        <TableCell
          align="right"
          className={className}
          colSpan={isEstimate ? 2 : 3}
        />
      )}
      {isEstimate && prePaidEnabled && !isGrouped && (
        <>
          <TableCell
            align="right"
            className={classNames(className, classes.scatteredQuantityCell)}
          >
            {item.prepaid && (
              <QuantityInput
                disableUnderline
                minReachedTooltipEnabled
                InputProps={{
                  disableUnderline: isReadOnly,
                }}
                allowDecimal={allowDecimalQuantity}
                disabled={isReadOnly}
                max={getHighValue(item.quantity)}
                min={0}
                value={Number(item.usedQuantity)}
                onChange={(event) => {
                  updateField(
                    event,
                    'usedQuantity',
                    group,
                    item,
                    false,
                    quantityFieldChanged,
                  )
                }}
              />
            )}
          </TableCell>
          <TableCell
            align="right"
            className={classNames(className, classes.scatteredQuantityCell)}
          >
            <QuantityInput
              disableUnderline
              minReachedTooltipEnabled
              InputProps={{
                disableUnderline: isReadOnly,
              }}
              allowDecimal={allowDecimalQuantity}
              disabled={isReadOnly}
              max={999}
              min={0}
              value={getHighValue(item.quantity)}
              onChange={(event) => {
                updateField(
                  event,
                  'quantity',
                  group,
                  item,
                  false,
                  quantityFieldChanged,
                )
              }}
            />
          </TableCell>
        </>
      )}
    </>
  )
}

export default QuantityCellBody
