import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { IconButton, Tooltip, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  (theme) => ({
    htmlTooltip: {
      textAlign: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.3)',
      border: '2px solid #5A98B1',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 291,
      height: 69,
      backgroundColor: '#FFFFFF',
      color: theme.colors.secondaryText,
      fontWeight: 500,
      lineHeight: '17px',
      fontSize: theme.typography.pxToRem(14),
      '&::before, &::after': {
        top: '100%',
        left: '50%',
        border: 'solid transparent',
        content: '" "',
        height: 0,
        width: 0,
        position: 'absolute',
      },
      '&::before': {
        borderTopColor: '#5A98B1',
        borderWidth: 13,
        marginLeft: -13,
      },
      '&::after': {
        borderTopColor: '#FFFFFF',
        borderWidth: 10,
        marginLeft: -10,
        marginTop: -0.1,
      },
    },
    root: {
      cursor: 'pointer',
      position: 'absolute',
      transform: 'translate(-50%, -100%)',
    },
    iconHighlighted: {
      color: theme.colors.markerHighlighted,
    },
    iconNormal: {
      color: theme.colors.iconColor,
    },
    icon: {
      fontSize: '5.5rem',
    },
  }),
  { name: 'Marker' },
)

const Marker = ({ onClick, name, highlighted }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Tooltip
      classes={{
        tooltip: classes.htmlTooltip,
      }}
      placement="top"
      title={name}
    >
      <IconButton
        className={classes.root}
        style={{
          zIndex: highlighted
            ? theme.utils.modifyZIndex(theme.zIndex.base, 'above')
            : theme.zIndex.base,
        }}
        onClick={onClick}
      >
        <LocationOnIcon
          className={classNames(
            classes.icon,
            highlighted ? classes.iconHighlighted : classes.iconNormal,
          )}
        />
      </IconButton>
    </Tooltip>
  )
}

export default Marker
