import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Business,
  ButtonWithLoader,
  ClassesType,
  HtmlNotesPreview,
  Text,
} from '@pbt/pbt-ui-components'
import {
  KioskBackgroundCustom,
  KioskBackgroundDefault,
} from '@pbt/pbt-ui-components/src/icons'

// @ts-ignore
import ClinicLogo from '~/components/common/logos/ClinicLogo'

const useStyles = makeStyles<Theme, UseStylesProps>(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.base,
      position: 'relative',
      borderRadius: 1,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: theme.constants.blockShadow,
    },
    button: {
      alignSelf: 'flex-end',
      '&&&&:hover': {
        backgroundColor: ({ buttonsColor }) => buttonsColor,
      },
      backgroundColor: ({ buttonsColor }) => buttonsColor,
      color: theme.colors.profileText,
      minWidth: 172,
    },
    kioskPreviewCallOutText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.profileText,
    },
    practiceLogo: {
      boxShadow: 'none',
      border: 'none',
      marginTop: theme.spacing(2),
      width: 182,
      height: 61,
      marginBottom: theme.spacing(5),
    },
    content: {
      flex: 1,
      padding: theme.spacing(2, 3, 4),
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
    },
    background: {
      position: 'absolute',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'below'),
      bottom: 0,
      left: 0,
      height: 'auto',
      width: '100%',
    },
  }),
  { name: 'KioskPreview' },
)

interface UseStylesProps {
  buttonsColor?: string
}

export interface KioskPreviewProps extends UseStylesProps {
  backgroundColor?: string
  business: Business
  buttonText: string
  className?: string
  classes?: ClassesType<typeof useStyles>
  showCallOut?: boolean
  text?: string
  title?: string
}

const KioskPreview = ({
  buttonText,
  buttonsColor,
  title,
  text,
  className,
  business,
  backgroundColor,
  showCallOut = true,
  classes: classesProp,
}: KioskPreviewProps) => {
  const classes = useStyles({ buttonsColor, ...classesProp })
  const { t } = useTranslation('Admin')

  return (
    <Grid
      container
      item
      alignContent="center"
      alignItems="center"
      className={classNames(className, classes.root)}
      direction="column"
      justifyContent="flex-start"
      px={8}
    >
      {backgroundColor ? (
        <KioskBackgroundCustom
          className={classes.background}
          fill={backgroundColor}
        />
      ) : (
        <KioskBackgroundDefault className={classes.background} />
      )}
      <ClinicLogo className={classes.practiceLogo} clinic={business} />
      <Grid
        container
        item
        alignItems="center"
        className={classes.content}
        direction="column"
        mb={showCallOut ? 0 : 2}
      >
        {title && (
          <>
            <Text mb={5} variant="hero3">
              {title}
            </Text>
            <Grid item xs>
              <HtmlNotesPreview notes={text} />
            </Grid>
          </>
        )}
        <ButtonWithLoader className={classes.button}>
          {buttonText}
        </ButtonWithLoader>
      </Grid>
      {showCallOut && (
        <Text
          className={classes.kioskPreviewCallOutText}
          my={2}
          variant="body2"
        >
          {t('Admin:PRACTICE.PHONE_NUMBER_CALL_OUT_TEXT')}
        </Text>
      )}
    </Grid>
  )
}

export default KioskPreview
