import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  CREATE_DISCOUNT_REASON,
  createDiscountReason,
  createDiscountReasonFailure,
  createDiscountReasonSuccess,
  DELETE_DISCOUNT_REASON,
  deleteDiscountReason,
  deleteDiscountReasonFailure,
  deleteDiscountReasonSuccess,
  FETCH_DISCOUNT_REASONS,
  fetchDiscountReasons,
  fetchDiscountReasonsFailure,
  fetchDiscountReasonsSuccess,
  UPDATE_DISCOUNT_REASON_STATUS,
  updateDiscountReasonStatus,
  updateDiscountReasonStatusFailure,
  updateDiscountReasonStatusSuccess,
} from '../duck/discountReasons'
import requestAPI from './utils/requestAPI'

export function* updateDiscountReasonStatusSaga({
  businessId,
  discountReasonId,
  active,
}: ReturnType<typeof updateDiscountReasonStatus>) {
  try {
    const result = yield* requestAPI(
      API.updateDiscountReasonStatus,
      businessId,
      {
        discountReasonId,
        active,
        businessId,
      },
    )

    yield put(
      updateDiscountReasonStatusSuccess(result.id, result.discountReason),
    )
  } catch (error) {
    yield put(updateDiscountReasonStatusFailure(error as ApiError))
  }
}

export function* createDiscountReasonSaga({
  name,
  businessId,
}: ReturnType<typeof createDiscountReason>) {
  try {
    const result = yield* requestAPI(API.createDiscountReason, businessId, {
      input: { name, businessId },
    })

    yield put(createDiscountReasonSuccess(result))
  } catch (error) {
    yield put(createDiscountReasonFailure(error as ApiError))
  }
}

export function* deleteDiscountReasonSaga({
  businessId,
  id,
}: ReturnType<typeof deleteDiscountReason>) {
  try {
    const result = yield* requestAPI(API.deleteDiscountReason, businessId, {
      discountReasonId: id,
    })

    yield put(deleteDiscountReasonSuccess(result)) // todo check arg
  } catch (error) {
    yield put(deleteDiscountReasonFailure(error as ApiError))
  }
}

export function* fetchDiscountReasonsSaga({
  businessId,
}: ReturnType<typeof fetchDiscountReasons>) {
  try {
    const result = yield* requestAPI(API.fetchDiscountReasons, businessId)

    yield put(fetchDiscountReasonsSuccess(result, businessId))
  } catch (error) {
    yield put(fetchDiscountReasonsFailure(error as ApiError))
  }
}

function* watchCreateDiscountReasonStatus() {
  yield takeLatest(CREATE_DISCOUNT_REASON, createDiscountReasonSaga)
}

function* watchDeleteDiscountReasonStatus() {
  yield takeLatest(DELETE_DISCOUNT_REASON, deleteDiscountReasonSaga)
}

function* watchUpdateDiscountReasonStatus() {
  yield takeLatest(
    UPDATE_DISCOUNT_REASON_STATUS,
    updateDiscountReasonStatusSaga,
  )
}

function* watchFetchDiscountReasons() {
  yield takeLatest(FETCH_DISCOUNT_REASONS, fetchDiscountReasonsSaga)
}

export default function* discountReasonsSaga() {
  yield all([
    watchDeleteDiscountReasonStatus(),
    watchUpdateDiscountReasonStatus(),
    watchFetchDiscountReasons(),
    watchCreateDiscountReasonStatus(),
  ])
}
