import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { TextInteractive } from '@pbt/pbt-ui-components'

import { getUserName } from '~/store/reducers/users'

export type AssignedVetCellItem = {
  assignedVet: string
}

const AssignedVetCell = (item: AssignedVetCellItem) => {
  const vetName = useSelector(getUserName(item?.assignedVet))

  const isLoading = R.isEmpty(item)

  return <TextInteractive isLoading={isLoading}>{vetName}</TextInteractive>
}

export default AssignedVetCell
