import { LabVendorConfig } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchLabVendorConfigs = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getAPIPath(`integration/lab/${businessId}/all`),
    { method: 'GET', params: { businessId: currentBusinessId } },
    true,
    Schema.labVendorConfigList,
  )

export const fetchLabVendorConfig = (
  currentBusinessId: string,
  businessId: string,
  vendorId: string,
) =>
  request(
    getAPIPath(`integration/lab/${businessId}/configs`),
    {
      method: 'GET',
      params: { businessId: currentBusinessId, vendorIds: vendorId },
    },
    true,
  )

export const editLabVendorConfig = (
  currentBusinessId: string,
  businessId: string,
  vendorId: string,
  config: Partial<LabVendorConfig>,
) =>
  request(
    getAPIPath(`integration/lab/${businessId}`),
    {
      method: 'PUT',
      params: { businessId: currentBusinessId, vendorId },
      data: config,
    },
    true,
  )
