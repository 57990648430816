import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  LanguageUtils,
  PermissionArea,
  Text,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  useGetSoapQuestionsCount,
  useSoapQuestions,
} from '~/store/hooks/questions'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getQuestionsIsFetching } from '~/store/reducers/questions'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { getSoapContainerHeight } from '~/utils'
import useDialog from '~/utils/useDialog'
import useWorkflowTypes from '~/utils/useWorkflowTypes'

import QuestionListView from '../../admin/catalog/questions/QuestionListView'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: getSoapContainerHeight(theme, true),
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    menu: {
      width: 225,
      minWidth: 225,
      borderRight: theme.constants.tableBorder,
      overflowY: 'auto',
      '& > *': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: theme.constants.tabBorder,
      },
    },
    previewContainer: {
      overflowY: 'auto',
    },
    radioLabel: {
      fontWeight: theme.typography.fontWeightBold,
    },
    medicalHistoryContainer: {
      cursor: 'pointer',
    },
    button: {
      padding: 0,
    },
  }),
  { name: 'QuestionsList' },
)

export interface QuestionsListProps {
  onViewTimelineRequested: () => void
}

const QuestionsList = ({ onViewTimelineRequested }: QuestionsListProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const isFetching = useSelector(getQuestionsIsFetching)
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))

  const WorkFlowTypes = useWorkflowTypes()

  const editDisabled = !SOAPPermissions.update
  const HistoryWorkflowType =
    Utils.findByProp('History', WorkFlowTypes, 'shortName') || {}
  const CheckInWorkflowType =
    Utils.findByProp('Check-in', WorkFlowTypes, 'shortName') || {}

  const [selectedTypeId, setSelectedTypeId] = useState(HistoryWorkflowType.id)
  const [wasFetching, setWasFetching] = useState(false)
  const [medicalHistoryOpen, setMedicalHistoryOpen] = useState(false)

  const [openVaccineHistoryDialog] = useDialog(DialogNames.VACCINE_HISTORY)
  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)
  const [openNoteHistoryDialog] = useDialog(DialogNames.NOTE_HISTORY)
  const [openAttachmentHistoryDialog] = useDialog(
    DialogNames.MULTI_ATTACHMENT_HISTORY,
  )
  const { onSaveQuestionLog, soapQuestions } = useSoapQuestions()
  const { getAnsweredQuestions, getTotalQuestions, soapQuestionsWithWorkflow } =
    useGetSoapQuestionsCount()

  useEffect(() => {
    if (wasFetching && !isFetching && !R.isEmpty(WorkFlowTypes)) {
      if (soapQuestionsWithWorkflow[HistoryWorkflowType.id].length === 0) {
        setSelectedTypeId(CheckInWorkflowType.id)
      } else {
        setSelectedTypeId(HistoryWorkflowType.id)
      }
    }
    setWasFetching(isFetching)
  }, [isFetching, soapQuestionsWithWorkflow])

  return (
    <Grid container className={classes.root} wrap="nowrap">
      <Grid
        container
        item
        className={classes.menu}
        direction="column"
        wrap="nowrap"
      >
        <Grid item py={1.5}>
          <Text variant="h4">{t('Common:QUESTION_OTHER')}</Text>
        </Grid>
        {WorkFlowTypes.map((workflow) => {
          const answeredQuestions = getAnsweredQuestions(workflow.id)
          const totalQuestions = getTotalQuestions(workflow.id)

          return (
            <Grid container item alignItems="center" key={workflow.id}>
              <Grid item xs>
                <FormControlLabel
                  classes={{
                    label: classes.radioLabel,
                  }}
                  control={
                    <Radio
                      checked={selectedTypeId === workflow.id}
                      color="primary"
                      onClick={() => setSelectedTypeId(workflow.id)}
                    />
                  }
                  label={LanguageUtils.getTranslatedFieldName(
                    workflow,
                    'shortName',
                  )}
                  value={workflow.id}
                />
              </Grid>
              <Grid item>
                <TextInteractive
                  isLoading={isFetching}
                  loaderWidth={30}
                  variant="lowAccent2"
                >
                  {`${answeredQuestions}/${totalQuestions}`}
                </TextInteractive>
              </Grid>
            </Grid>
          )
        })}
        <Grid item py={1}>
          <Grid
            container
            item
            alignItems="center"
            className={classes.medicalHistoryContainer}
            justifyContent="space-between"
            wrap="nowrap"
            onClick={() => setMedicalHistoryOpen(!medicalHistoryOpen)}
          >
            <Text strong variant="body2">
              {t('Common:ADD_MEDICAL_HISTORY')}
            </Text>
            <IconButton className={classes.button} size="large">
              {medicalHistoryOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Grid>
          <Collapse in={medicalHistoryOpen}>
            <Text
              link
              fontSize="1.4rem"
              ml={2}
              mt={1}
              onClick={() => openVaccineHistoryDialog({ clientId, patientId })}
            >
              {t('Common:VACCINE_HISTORY')}
            </Text>
            <Text
              link
              fontSize="1.4rem"
              ml={2}
              mt={1}
              onClick={() =>
                openDiagnosisHistoryDialog({ clientId, patientId })
              }
            >
              {t('Common:DIAGNOSIS_HISTORY')}
            </Text>
            {!editDisabled && (
              <>
                <Text
                  link
                  fontSize="1.4rem"
                  ml={2}
                  mt={1}
                  onClick={() => openNoteHistoryDialog({ patientId, clientId })}
                >
                  {t('Common:ADD_HISTORICAL_NOTE')}
                </Text>
                <Text
                  link
                  fontSize="1.4rem"
                  ml={2}
                  mt={1}
                  onClick={() =>
                    openAttachmentHistoryDialog({ clientId, patientId })
                  }
                >
                  {t('Common:ADD_HISTORICAL_ATTACHMENT')}
                </Text>
              </>
            )}
          </Collapse>
        </Grid>
        <Grid item py={1} onClick={onViewTimelineRequested}>
          <Text link fontSize="1.4rem">
            {t('Common:VIEW_TIMELINE')}
          </Text>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.previewContainer}
        direction="column"
        justifyContent={isFetching ? 'center' : 'flex-start'}
        px={2}
        py={3}
        wrap="nowrap"
      >
        {isFetching ? (
          <CircularProgress size={48} />
        ) : (
          <QuestionListView
            showNoQuestionsMessage
            editDisabled={editDisabled}
            questionsLog={soapQuestions}
            type={selectedTypeId}
            onSaveQuestionLog={onSaveQuestionLog}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default QuestionsList
