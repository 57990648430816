import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType } from '@pbt/pbt-ui-components'

import BenchmarkingLogo from './images/BenchmarkingLogo.png'
import Link from './link/Link'

const DEFAULT_LOGO_WIDTH = 240
const DEFAULT_LOGO_HEIGHT = 80

const useStyles = makeStyles(
  () => ({
    logo: {
      cursor: 'pointer',
      width: DEFAULT_LOGO_WIDTH,
      height: DEFAULT_LOGO_HEIGHT,
    },
    root: {},
  }),
  { name: 'RhapsodyLogo' },
)

interface BenchmarkingPlusLogoProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
}

const BenchmarkingPlusLogo = ({
  className,
  ...rest
}: BenchmarkingPlusLogoProps) => {
  const classes = useStyles(rest)
  const { t } = useTranslation('Common')

  return (
    <Grid container item className={classNames(classes.root, className)}>
      <Link aria-label={t('Common:BENCHMARKING_HOMEPAGE')} to="/">
        <img
          alt="BenchmarkingPlus"
          className={classes.logo}
          src={BenchmarkingLogo}
        />
      </Link>
    </Grid>
  )
}

export default BenchmarkingPlusLogo
