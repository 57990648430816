import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { LanguageUtils, Nil } from '@pbt/pbt-ui-components'

import {
  getLogKeyFromLogs,
  getProblemKey,
} from '~/components/dashboard/soapV2/problems/utils'
import { ProblemType } from '~/constants/problems'
import {
  Problem,
  ProblemEnum,
  ProblemEnumLogInfo,
  ProblemEnumReference,
  ProblemLogBodySystemContainer,
  ProblemLogHistory,
  ProblemLogProblemContainer,
} from '~/types'
import { arrayToMap, mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'
import { getProblems } from '~/utils/soapProblemsWidget'

import {
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_FAILURE,
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_SUCCESS,
  CLEAN_PROBLEMS_VALIDATION_ERROR,
  CREATE_PROBLEM_BODY_SYSTEM_LOG,
  CREATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  CREATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  CREATE_PROBLEM_LOG,
  CREATE_PROBLEM_LOG_ENUM_VALUE,
  CREATE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  CREATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  CREATE_PROBLEM_LOG_FAILURE,
  CREATE_PROBLEM_LOG_SUCCESS,
  DELETE_PROBLEM_BODY_SYSTEM_LOG,
  DELETE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  DELETE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  DELETE_PROBLEM_LOG_ENUM_VALUE,
  DELETE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  DELETE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  DISABLE_TOAST_SHOWING_SUCCESS,
  FETCH_LOGS,
  FETCH_LOGS_FAILURE,
  FETCH_LOGS_SUCCESS,
  FETCH_MORE_PROBLEM_LOG_HISTORY,
  FETCH_MORE_PROBLEM_LOG_HISTORY_FAILURE,
  FETCH_MORE_PROBLEM_LOG_HISTORY_SUCCESS,
  FETCH_PROBLEM_CATALOG,
  FETCH_PROBLEM_CATALOG_FAILURE,
  FETCH_PROBLEM_CATALOG_SUCCESS,
  FETCH_PROBLEM_LOG_HISTORY,
  FETCH_PROBLEM_LOG_HISTORY_FAILURE,
  FETCH_PROBLEM_LOG_HISTORY_SUCCESS,
  SEARCH_MORE_PROBLEMS,
  SEARCH_MORE_PROBLEMS_FAILURE,
  SEARCH_MORE_PROBLEMS_SUCCESS,
  SEARCH_PROBLEMS,
  SEARCH_PROBLEMS_FAILURE,
  SEARCH_PROBLEMS_SUCCESS,
  SET_PROBLEM_TO_OPEN_IN_RAIL,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG_VALIDATION_FAILURE,
  UPDATE_PROBLEM_LOG,
  UPDATE_PROBLEM_LOG_ENUM_VALUE,
  UPDATE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  UPDATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  UPDATE_PROBLEM_LOG_FAILURE,
  UPDATE_PROBLEM_LOG_SUCCESS,
  UPDATE_PROBLEM_LOG_VALIDATION_FAILURE,
} from '../actions/types/problems'
import type { RootState } from '../index'

type ProblemBodySystemLogWithProblem = {
  bodySystemLog: ProblemLogBodySystemContainer
  problem: Problem
}

type ProblemLogWithProblem = {
  problem: Problem
  problemLog: ProblemLogProblemContainer
}

type ProblemBodySystemOrProblemLogWithProblem =
  | ProblemBodySystemLogWithProblem
  | ProblemLogWithProblem

export type ProblemCatalog = {
  bodySystemsList: string[]
  enums: Record<string, ProblemEnum>
  problems: Record<string, Problem>
}

export type ProblemLogs = {
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer> | null
  problemLogsMap: Record<string, ProblemLogProblemContainer> | null
}

export type ProblemState = {
  catalog: ProblemCatalog | null
  error: string | null
  isEnumLogsLoading: boolean
  isLogHistoryLoading: boolean
  isLogsLoading: boolean
  isReceivingCatalog: boolean
  isSearchLoading: boolean
  logHistoryList: string[]
  logHistoryMap: Record<string, ProblemLogHistory>
  logHistoryTotalCount: number
  logs: ProblemLogs
  problemToOpenInRail: Problem | null
  searchResultList: string[]
  searchResultMap: Record<string, Problem>
  searchTotalCount: number
  showAddedProblemLogsToast: boolean
  validationError: string | null
}

export const INITIAL_STATE: ProblemState = {
  catalog: null,
  error: null,
  isEnumLogsLoading: false,
  isLogHistoryLoading: false,
  isLogsLoading: false,
  isReceivingCatalog: false,
  isSearchLoading: false,
  logHistoryList: [],
  logHistoryMap: {},
  logHistoryTotalCount: 0,
  logs: {
    bodySystemLogsMap: {},
    problemLogsMap: {},
  },
  problemToOpenInRail: null,
  searchResultList: [],
  searchResultMap: {},
  searchTotalCount: 0,
  showAddedProblemLogsToast: false,
  validationError: null,
}

export const problemReducer = (
  state: ProblemState = INITIAL_STATE,
  action: AnyAction,
): ProblemState => {
  switch (action.type) {
    case FETCH_LOGS:
    case CREATE_PROBLEM_BODY_SYSTEM_LOG:
    case CREATE_PROBLEM_LOG:
    case UPDATE_PROBLEM_LOG:
    case UPDATE_PROBLEM_BODY_SYSTEM_LOG:
    case DELETE_PROBLEM_BODY_SYSTEM_LOG:
    case CHANGE_STATE_FOR_ALL_BODY_SYSTEMS:
      return {
        ...state,
        isLogsLoading: true,
      }
    case FETCH_LOGS_SUCCESS:
    case CREATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS:
    case CREATE_PROBLEM_LOG_SUCCESS:
    case UPDATE_PROBLEM_LOG_SUCCESS:
    case UPDATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS:
    case DELETE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS:
    case CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_SUCCESS:
      return {
        ...state,
        logs: {
          bodySystemLogsMap: action.bodySystemLogsMap, //  || {} refactor to initial value with changing selector logic
          problemLogsMap: action.problemLogsMap, // || {}
        },
        isLogsLoading: false,
        showAddedProblemLogsToast: action.showAddedProblemLogsToast,
      }
    case FETCH_LOGS_FAILURE:
    case CREATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE:
    case CREATE_PROBLEM_LOG_FAILURE:
    case UPDATE_PROBLEM_LOG_FAILURE:
    case UPDATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE:
    case DELETE_PROBLEM_BODY_SYSTEM_LOG_FAILURE:
    case CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLogsLoading: false,
      }
    case UPDATE_PROBLEM_BODY_SYSTEM_LOG_VALIDATION_FAILURE:
    case UPDATE_PROBLEM_LOG_VALIDATION_FAILURE:
      return {
        ...state,
        validationError: action.error,
        isLogsLoading: false,
      }
    case CLEAN_PROBLEMS_VALIDATION_ERROR:
      return {
        ...state,
        validationError: null,
      }
    case FETCH_PROBLEM_CATALOG:
      return { ...state, isReceivingCatalog: true }
    case FETCH_PROBLEM_CATALOG_SUCCESS:
      return { ...state, isReceivingCatalog: false, catalog: action.catalog }
    case FETCH_PROBLEM_CATALOG_FAILURE:
      return {
        ...state,
        isReceivingCatalog: false,
        error: getErrorMessage(action.error),
      }
    case SEARCH_PROBLEMS:
      return {
        ...state,
        isSearchLoading: true,
        searchResultList: [],
        searchResultMap: {},
        searchTotalCount: 0,
      }
    case SEARCH_MORE_PROBLEMS:
      return { ...state, isSearchLoading: true }
    case SEARCH_PROBLEMS_FAILURE:
    case SEARCH_MORE_PROBLEMS_FAILURE:
      return { ...state, error: action.error, isSearchLoading: false }
    case SEARCH_PROBLEMS_SUCCESS:
      return {
        ...state,
        isSearchLoading: false,
        searchResultList: action.searchResultList,
        searchResultMap: action.searchResultMap,
        searchTotalCount: action.totalCount,
      }
    case SEARCH_MORE_PROBLEMS_SUCCESS:
      return {
        ...state,
        isSearchLoading: false,
        searchResultList: mergeArraysAtIndex(
          state.searchResultList,
          action.searchResultList,
          action.from,
        ),
        searchResultMap: secondLevelMerge(
          state.searchResultMap,
          action.searchResultMap,
        ),
        searchTotalCount: action.totalCount,
      }
    case FETCH_PROBLEM_LOG_HISTORY:
    case FETCH_MORE_PROBLEM_LOG_HISTORY:
      return {
        ...state,
        isLogHistoryLoading: true,
      }
    case FETCH_PROBLEM_LOG_HISTORY_SUCCESS:
      return {
        ...state,
        isLogHistoryLoading: false,
        logHistoryList: action.logHistoryList,
        logHistoryMap: action.logHistoryMap,
        logHistoryTotalCount: action.totalCount,
      }
    case FETCH_MORE_PROBLEM_LOG_HISTORY_SUCCESS:
      return {
        ...state,
        isLogHistoryLoading: false,
        logHistoryList: mergeArraysAtIndex(
          state.logHistoryList,
          action.logHistoryList,
          action.from,
        ),
        logHistoryMap: secondLevelMerge(
          state.logHistoryMap,
          action.logHistoryMap,
        ),
        logHistoryTotalCount: action.totalCount,
      }
    case FETCH_MORE_PROBLEM_LOG_HISTORY_FAILURE:
    case FETCH_PROBLEM_LOG_HISTORY_FAILURE:
      return {
        ...state,
        isLogHistoryLoading: false,
        error: action.error,
      }
    case CREATE_PROBLEM_LOG_ENUM_VALUE:
    case UPDATE_PROBLEM_LOG_ENUM_VALUE:
    case DELETE_PROBLEM_LOG_ENUM_VALUE:
      return {
        ...state,
        isEnumLogsLoading: true,
      }
    case CREATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS:
    case UPDATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS:
    case DELETE_PROBLEM_LOG_ENUM_VALUE_SUCCESS:
      const { problemLog } = action

      const bodySystemLog = Object.values(
        state.logs.bodySystemLogsMap || {},
      ).find(({ entity }) => entity.id === problemLog.entity.bodySystemLogId)

      const logKey = getLogKeyFromLogs(
        problemLog.entity.problemId,
        bodySystemLog!.entity.bodySystemId,
      )

      return {
        ...state,
        isEnumLogsLoading: false,
        logs: {
          ...state.logs,
          problemLogsMap: {
            ...state.logs.problemLogsMap,
            [logKey]: {
              ...problemLog,
              key: logKey,
            },
          },
        },
      }
    case CREATE_PROBLEM_LOG_ENUM_VALUE_FAILURE:
    case UPDATE_PROBLEM_LOG_ENUM_VALUE_FAILURE:
    case DELETE_PROBLEM_LOG_ENUM_VALUE_FAILURE:
      return {
        ...state,
        isEnumLogsLoading: false,
        error: action.error,
      }
    case DISABLE_TOAST_SHOWING_SUCCESS:
      return {
        ...state,
        showAddedProblemLogsToast: false,
      }
    case SET_PROBLEM_TO_OPEN_IN_RAIL:
      return {
        ...state,
        problemToOpenInRail: action.problem,
      }
    default:
      return state
  }
}

export const getProblemState = (state: RootState): ProblemState =>
  state.problems
export const getProblemsValidationError = (state: RootState) =>
  getProblemState(state).validationError
export const getIsLoadingCatalog = (state: RootState) =>
  getProblemState(state).isReceivingCatalog
export const getShouldShowedProblemLogsToast = (state: RootState) =>
  getProblemState(state).showAddedProblemLogsToast
export const getIsLoadingLogs = (state: RootState) =>
  getProblemState(state).isLogsLoading
export const getProblemsLogs = (state: RootState) => getProblemState(state).logs
export const getProblemsBodySystemLogsMap = (state: RootState) =>
  getProblemsLogs(state).bodySystemLogsMap
export const getProblemsLogsMap = (state: RootState) =>
  getProblemsLogs(state).problemLogsMap
export const getProblemCatalog = (state: RootState) =>
  getProblemState(state).catalog
export const getProblemCatalogProblemsMap = (state: RootState) =>
  getProblemCatalog(state)?.problems
export const getProblemBodySystemsList = (state: RootState) =>
  getProblemCatalog(state)?.bodySystemsList
export const getProblemById = (problemId: string | Nil) =>
  createSelector(getProblemCatalogProblemsMap, (map) =>
    problemId ? map?.[problemId] : undefined,
  )
export const getAllProblemIds = createSelector(
  getProblemCatalogProblemsMap,
  (map) => Object.keys(map || {}),
)
export const getAllProblemsList = createSelector(
  getProblemCatalogProblemsMap,
  (map) => (map ? Object.values(map) : []),
)

export const getRemovedProblemsList = createSelector(
  getProblemCatalogProblemsMap,
  (map) => (map ? Object.values(map).filter((problem) => problem.removed) : []),
)

interface GetFirstLevelProblemsIdsWithoutCategoriesArgs {
  excludeRemoved?: boolean
}

export const getFirstLevelProblemsIdsWithoutCategories = (
  problemId: string,
  {
    excludeRemoved = false,
  }: GetFirstLevelProblemsIdsWithoutCategoriesArgs = {},
) =>
  createSelector(getProblemCatalogProblemsMap, (map) =>
    map?.[problemId].children.filter((itemId: string) => {
      const problem = map?.[itemId]

      if (excludeRemoved && problem.removed) {
        return false
      }

      return problem.type
    }),
  )

export const getRecursivelyChildrenProblemIds = (problemId: string) =>
  createSelector(getProblemCatalogProblemsMap, (map) =>
    getProblems(map || {}, map?.[problemId]?.children),
  )

export const getSearchResultIdsList = (state: RootState) =>
  getProblemState(state).searchResultList
export const getSearchResultMap = (state: RootState) =>
  getProblemState(state).searchResultMap
export const getSearchResultTotalCount = (state: RootState) =>
  getProblemState(state).searchTotalCount
export const getIsSearchResultLoading = (state: RootState) =>
  getProblemState(state).isSearchLoading
export const getSearchResult = (findingId: string | Nil) =>
  createSelector(
    getSearchResultMap,
    getProblemCatalogProblemsMap,
    (searchResultMap, problemCatalogMap) => {
      const searchResult = findingId ? searchResultMap[findingId] : undefined
      if (!searchResult) {
        return undefined
      }

      const level = searchResult.breadcrumbTranslation?.split('>').length ?? 1
      const problemKey = getProblemKey({ ...searchResult, level })
      const problem = problemCatalogMap?.[problemKey]

      return {
        ...searchResult,
        enums: problem?.enums || [],
      }
    },
  )

export const getProblemLogHistoryIdsList = (state: RootState) =>
  getProblemState(state).logHistoryList
export const getProblemLogHistoryMap = (state: RootState) =>
  getProblemState(state).logHistoryMap
export const getProblemLogHistoryTotalCount = (state: RootState) =>
  getProblemState(state).logHistoryTotalCount
export const getIsProblemLogHistoryLoading = (state: RootState) =>
  getProblemState(state).isLogHistoryLoading

// come up with better loaded conditions
export const getIsLogsAndCatalogLoaded = createSelector(
  getProblemCatalog,
  getProblemsBodySystemLogsMap,
  (problemCatalog, bodySystemLogMap) =>
    Boolean(
      !R.isEmpty(problemCatalog?.bodySystemsList) &&
        !R.isEmpty(bodySystemLogMap),
    ),
)

export const getHasSoapProblemLogs = createSelector(
  getProblemsBodySystemLogsMap,
  getProblemsLogsMap,
  (problemLogMap, bodySystemLogMap) => {
    const hasProblemLogs = Boolean(problemLogMap) && !R.isEmpty(problemLogMap)
    const hasBodySystemLogs =
      Boolean(bodySystemLogMap) && !R.isEmpty(bodySystemLogMap)
    return hasProblemLogs || hasBodySystemLogs
  },
)

const problemNameComparator = (
  log1: ProblemBodySystemLogWithProblem | ProblemLogWithProblem,
  log2: ProblemBodySystemLogWithProblem | ProblemLogWithProblem,
): number =>
  LanguageUtils.getTranslatedFieldName(log1.problem).localeCompare(
    LanguageUtils.getTranslatedFieldName(log2.problem),
  )

const getProblemOrderByType = (log1: ProblemLogWithProblem): number =>
  Number(log1.problem?.type !== ProblemType.FINDING)

const problemTypeComparator = (
  log1: ProblemLogWithProblem,
  log2: ProblemLogWithProblem,
): number => getProblemOrderByType(log1) - getProblemOrderByType(log2)

export const getProblemLogsWithProblems = createSelector(
  getProblemCatalog,
  getProblemsBodySystemLogsMap,
  getProblemsLogsMap,
  (
    problemCatalog,
    bodySystemLogMap,
    problemLogMap,
  ): ProblemLogWithProblem[] | null => {
    if (!problemCatalog || !bodySystemLogMap || !problemLogMap) {
      return null
    }

    const problems = Object.values(problemCatalog.problems)
    const bodySystemLogs = Object.values(bodySystemLogMap)
    const problemLogs = Object.values(problemLogMap)

    return problemLogs
      .map((problemLog) => {
        const { bodySystemId } = bodySystemLogs.find(
          ({ entity }) => entity.id === problemLog.entity.bodySystemLogId,
        )!.entity

        const targetLogKey = getLogKeyFromLogs(
          problemLog.entity.problemId,
          bodySystemId,
        )
        const problem = problems.find(({ logKey }) => logKey === targetLogKey)!

        return { problem, problemLog }
      })
      .sort(
        (log1, log2) =>
          problemTypeComparator(log1, log2) ||
          problemNameComparator(log1, log2),
      )
  },
)

export const getBodySystemLogsWithProblems = createSelector(
  getProblemCatalog,
  getProblemsBodySystemLogsMap,
  (
    problemCatalog,
    bodySystemLogMap,
  ): ProblemBodySystemLogWithProblem[] | null => {
    if (!problemCatalog || !bodySystemLogMap) {
      return null
    }

    const bodySystemLogs = Object.values(bodySystemLogMap)

    return bodySystemLogs
      .filter(
        ({ entity: { notes, problemLogs } }) =>
          (notes && notes?.length > 0) || problemLogs.length === 0,
      )
      .map((bodySystemLog) => {
        const { bodySystemId } = bodySystemLog.entity

        const problemKey = getProblemKey({
          bodySystemId,
          primaryId: null,
          secondaryId: null,
          tertiaryId: null,
          id: bodySystemId,
          level: 1,
        })

        const problem = problemCatalog.problems[problemKey]

        return {
          bodySystemLog,
          problem,
        }
      })
      .sort(problemNameComparator)
  },
)

export const getBodySystemAndProblemLogsWithProblems = createSelector(
  getProblemLogsWithProblems,
  getBodySystemLogsWithProblems,
  (
    problemLogsWithProblems,
    bodySystemLogsWithProblems,
  ): ProblemBodySystemOrProblemLogWithProblem[] => [
    ...(problemLogsWithProblems ?? []),
    ...(bodySystemLogsWithProblems ?? []),
  ],
)

export const getIdentifiedProblemsCount = createSelector(
  getProblemLogsWithProblems,
  (logs) => logs?.length || 0,
)

export const getProblemBodySystemLog = (logKey: string) =>
  createSelector(getProblemsBodySystemLogsMap, (map) => map?.[logKey])

export const getProblemLog = (logKey: string) =>
  createSelector(getProblemsLogsMap, (map) => map?.[logKey])

export const getProblemEnumCatalog = (state: RootState) =>
  getProblemCatalog(state)?.enums

export const getProblemEnums = (
  problemEnumRefs: ProblemEnumReference[] | Nil,
  parentEnumId?: string,
) =>
  createSelector(getProblemEnumCatalog, (problemEnumCatalog) => {
    if (!problemEnumRefs || !problemEnumCatalog) {
      return null
    }

    return problemEnumRefs
      .filter((problemEnumRef) =>
        parentEnumId
          ? problemEnumRef.parentEnumId === parentEnumId
          : !problemEnumRef.parentEnumId,
      )
      .sort(
        (problemEnumRef1, problemEnumRef2) =>
          problemEnumRef1.order - problemEnumRef2.order,
      )
      .map(({ id }) => problemEnumCatalog[id])
  })

export const getIsEnumLogsLoading = (state: RootState) =>
  getProblemState(state).isEnumLogsLoading

export const getInfoAboutEnums = (logsIds: string[]) =>
  createSelector(getProblemEnumCatalog, (problemEnumCatalog) => {
    if (!problemEnumCatalog) {
      return {}
    }
    const logsInfo = logsIds.map((id: string) => {
      const enumProblem = problemEnumCatalog[id]
      return {
        enumId: id,
        enumProblem,
        enumProblemValuesMap: arrayToMap(
          enumProblem.values,
          (value) => value.id,
        ),
      } as ProblemEnumLogInfo
    })
    return arrayToMap(logsInfo, (info) => info.enumId)
  })

export const getProblemToOpenInRail = (state: RootState) =>
  getProblemState(state).problemToOpenInRail
