import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  AppointmentEventType,
  NamedEntity,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  updateTimetableFilteredAppointmentTypeGroups,
  updateTimetableFilteredAppointmentTypes,
} from '~/store/actions/timetable'
import { getEventType } from '~/store/reducers/constants'
import {
  getTimetableFilteredAppointmentTypeGroups,
  getTimetableFilteredAppointmentTypes,
} from '~/store/reducers/timetable'
import { TimetableFilter } from '~/types'

import TimetableFilterAppointmentTypeItem from './TimetableFilterAppointmentTypeItem'
import { getAppointmentTypesFilters } from './TimetableFilters'
import TimetableFilterTab from './TimetableFilterTab'

const filters: TimetableFilter<NamedEntity>[] = getAppointmentTypesFilters()

const AppointmentTypes = () => {
  const dispatch = useDispatch()

  const EventType = useSelector(getEventType)
  const selectedFilterAppointmentTypes = useSelector(
    getTimetableFilteredAppointmentTypes,
  )
  const selectedFilterAppointmentTypeGroups = useSelector(
    getTimetableFilteredAppointmentTypeGroups,
  )

  const AppointmentType: AppointmentEventType =
    Utils.findConstantByName('Appointment', EventType) || {}
  const data = AppointmentType.subTypes || []

  const dataIds = R.pluck('id', data)
  const selectedAppointmentTypes = R.without(
    selectedFilterAppointmentTypes,
    dataIds,
  )

  const handleFilteredDataChange = (filteredAppointmentTypes: string[]) => {
    dispatch(updateTimetableFilteredAppointmentTypes(filteredAppointmentTypes))
  }

  const handleFilteredGroupChange = (filteredPersonGroups: string[]) => {
    dispatch(updateTimetableFilteredAppointmentTypeGroups(filteredPersonGroups))
  }

  return (
    <TimetableFilterTab
      data={data}
      filters={filters}
      selectedFilters={selectedFilterAppointmentTypeGroups}
      selectedItems={selectedAppointmentTypes}
      onFilteredDataChange={handleFilteredDataChange}
      onFilteredGroupChange={handleFilteredGroupChange}
    >
      {(item) => (
        <TimetableFilterAppointmentTypeItem disableColor item={item} />
      )}
    </TimetableFilterTab>
  )
}

export default AppointmentTypes
