import React from 'react'
import { useTranslation } from 'react-i18next'

import { ImportedHistoryTimelineItem } from '~/types'
import { isDifferedBusinessList } from '~/utils/businessUtils'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardContent from '../TimelineCardContent'
import MultipleImportedHistories from './MultipleImportedHistories'
import OneImportedHistory from './OneImportedHistory'

interface ImportedHistoryCardProps extends TimelineCardProps {
  item: ImportedHistoryTimelineItem
}

const ImportedHistoryCard = ({ item, ...rest }: ImportedHistoryCardProps) => {
  const { t } = useTranslation('Common')

  const hasOneImportedHistory = item.entries.length === 1
  const firstImportedHistory = item.entries[0]

  const hasDifferedBusinessEntries = isDifferedBusinessList(item.entries)

  return (
    <TimelineCard
      originBusinessId={
        hasDifferedBusinessEntries ? undefined : firstImportedHistory.businessId
      }
      title={t('Common:MEDICAL_HISTORY')}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography noPadding={!hasOneImportedHistory}>
        {hasOneImportedHistory ? (
          <OneImportedHistory importedHistory={firstImportedHistory} />
        ) : (
          <MultipleImportedHistories
            enableBusinessShareIcon={hasDifferedBusinessEntries}
            item={item}
          />
        )}
      </TimelineCardContent>
    </TimelineCard>
  )
}

export default ImportedHistoryCard
