import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { LanguageUtils, SelectEntity, Utils } from '@pbt/pbt-ui-components'

import EnumSelect, {
  EnumSelectProps,
} from '~/components/common/inputs/EnumSelect'
import { PrescriptionStateLabel } from '~/components/common/labels/PrescriptionStateLabel'
import { ColorVariant } from '~/constants/colors'
import FeatureToggle from '~/constants/featureToggle'
import {
  InventoryItemState,
  LabTestState,
  LabTestStateEasterEgg,
  LabTestStateIsDone,
  OrderType,
  PrescriptionItemState,
  ProcedureState,
} from '~/constants/SOAPStates'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { partialEditOrder } from '~/store/actions/orders'
import { editChargeSheetOrder } from '~/store/duck/clientFinanceData'
import { useGetInvoicePosted } from '~/store/hooks/soap'
import { useTaskEvent } from '~/store/hooks/tasks'
import {
  getFeatureToggle,
  getInventoryStatuses,
  getPrescriptionStates,
  getProcedureStatus,
  getSelectableLabTestsStates,
} from '~/store/reducers/constants'
import { EasterEggEvents, Order } from '~/types'
import { getOrderStateKey, isLabTest } from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'

import { useDeclineOrderLabTestItem } from '../../soapV2/charges/utils/useDeclineOrderItem'

const TaskDoneState = 'Done'

const useStyles = makeStyles(
  () => ({
    chewyActiveItem: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'OrderStatusSelect' },
)

export interface OrderStatusSelectProps extends Omit<EnumSelectProps, 'field'> {
  order: Order
}

const OrderStatusSelect = (props: OrderStatusSelectProps) => {
  const classes = useStyles()
  const { order, disabled, ...rest } = props

  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const LabTestStates = useSelector(getSelectableLabTestsStates)
  const ProcedureStatus = useSelector(getProcedureStatus)
  const PrescriptionStates = useSelector(getPrescriptionStates)
  const InventoryStatuses = useSelector(getInventoryStatuses)
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const isInvoicePosted = useGetInvoicePosted()

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states
  const declinedId = Utils.findConstantIdByName(
    LabTestState.DECLINED,
    LabTestStates,
  )

  const onDeclineLabTest = useDeclineOrderLabTestItem(order, () =>
    partialEditOrder({
      id: order.id,
      type: order.type,
      stateId: declinedId,
    }),
  )

  const getEditChargeSheetOrder = (stateId: string) =>
    editChargeSheetOrder({
      id: order.id,
      type: order.type,
      order: {
        stateId,
      },
      soapLogModificationDate: order.modificationDate || '',
    })

  const defaultSetState = (stateId: string) => {
    if (isEditPostedChargesEnabled && isInvoicePosted) {
      dispatch(getEditChargeSheetOrder(stateId))
    } else {
      dispatch(
        partialEditOrder({
          id: order.id,
          type: order.type,
          stateId,
        }),
      )
    }
  }

  const { isInHouse, isChewyActiveRx } = getPrescriptionType(
    order.prescriptionType,
    order.origin,
  )
  const stateKey: keyof Order = getOrderStateKey(order.prescriptionType)

  const configMap: Record<
    string,
    { items: SelectEntity[]; setState: (stateId: string) => void }
  > = {
    [OrderType.INVENTORY]: {
      items: InventoryStatuses,
      setState: defaultSetState,
    },
    [OrderType.PROCEDURE]: {
      items: ProcedureStatus,
      setState: defaultSetState,
    },
    [OrderType.LAB_TEST]: {
      items: LabTestStates,
      setState: (stateId: string) => {
        if (stateId === declinedId) {
          onDeclineLabTest()
          return
        }
        const statusName = Utils.getConstantName(stateId, LabTestStates)
        if (isEasterEggEnabled && LabTestStateEasterEgg.includes(statusName)) {
          dispatch(
            queueEasterEggEvent({
              actionType: EasterEggEvents.LAB_WORK_STATUS_UPDATE,
              actionSubtype: Utils.getConstantName(statusName, LabTestStates),
            }),
          )
        }
        defaultSetState(stateId)
      },
    },
    [OrderType.PRESCRIPTION]: {
      items: isInHouse ? InventoryStatuses : PrescriptionStates,
      setState: (stateId: string) => {
        if (isEditPostedChargesEnabled && isInvoicePosted) {
          dispatch(getEditChargeSheetOrder(stateId))
        } else {
          dispatch(
            partialEditOrder({
              id: order.id,
              type: order.type,
              [stateKey]: stateId,
            }),
          )
        }
      },
    },
    [OrderType.TASK]: {
      items: TaskStates,
      setState: defaultSetState,
    },
  }

  const labTestSuccessStates = useMemo(
    () =>
      LabTestStateIsDone.reduce(
        (acc, item) => ({
          ...acc,
          [item]: ColorVariant.SUCCESS,
        }),
        {},
      ),
    [],
  )

  const colorMap = {
    ...labTestSuccessStates,
    [InventoryItemState.DECLINED]: ColorVariant.DISABLED,
    [LabTestState.DECLINED]: ColorVariant.DISABLED,
    [ProcedureState.DECLINED]: ColorVariant.DISABLED,
    [PrescriptionItemState.DECLINED]: ColorVariant.DISABLED,
    [InventoryItemState.DISPENSED]: ColorVariant.SUCCESS,
    [ProcedureState.COMPLETED]: ColorVariant.SUCCESS,
    [TaskDoneState]: ColorVariant.SUCCESS,
    [PrescriptionItemState.PRESCRIBED]: ColorVariant.SUCCESS,
  }

  const { items: configItems, setState } = configMap[order.type]

  const items = configItems.map((item) => ({
    ...R.omit(['color', 'colorVariant'], item),
    disabled:
      isInvoicePosted && item.name === 'Declined' ? true : item.disabled,
    name:
      isInvoicePosted && item.name === 'Declined'
        ? `${item.name} ('invoice posted')`
        : item.name,
    nameTranslation:
      isInvoicePosted && item.name === 'Declined'
        ? `${LanguageUtils.getTranslatedFieldName(item)} (${t(
            'Common:INVOICE_POSTED',
          ).toLowerCase()})`
        : LanguageUtils.getTranslatedFieldName(item),
  }))

  const isLabTestDeclined = isLabTest(order) && order?.stateId === declinedId

  if (isChewyActiveRx && order?.stateId) {
    return (
      <PrescriptionStateLabel
        className={classes.chewyActiveItem}
        stateId={order.stateId}
      />
    )
  }

  return (
    <EnumSelect
      accent
      Constant={items}
      colorVariantMap={colorMap}
      disabled={disabled || isLabTestDeclined}
      // @ts-ignore
      field={{
        value: order[stateKey],
        set: Utils.handleFormSelectInput(setState),
      }}
      {...rest}
    />
  )
}

export default OrderStatusSelect
