import { useSelector } from 'react-redux'

import { getMultipleUsers } from '~/store/reducers/users'
import { isActivePerson } from '~/utils'

const useTeamMembers = (members: string[]) => {
  const usersList = useSelector(getMultipleUsers(members))
  const activeUsersList = usersList.filter(isActivePerson)
  return activeUsersList
}

export default useTeamMembers
