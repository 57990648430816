import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getInventoryCategory } from '~/store/reducers/constants'

export interface InventoryCategoryCellProps {
  categoryId: string
}

const InventoryCategoryCell = (item: InventoryCategoryCellProps) => {
  const InventoryCategory = useSelector(getInventoryCategory)

  return LanguageUtils.getConstantTranslatedName(
    item.categoryId,
    InventoryCategory,
  )
}

export default InventoryCategoryCell
