import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  BillingAddress,
  BillingAddressForm,
  BillingAddressFormHandle,
  PuiDialog,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { BillingAddressesActions } from '~/components/dashboard/invoices/BillingAddressesActions'
import ManualPayment from '~/components/dashboard/invoices/payment/ManualPayment'
import PaymentCardDetails from '~/components/dashboard/invoices/payment/PaymentCardDetails'
// @ts-ignore
import RhapsodyPayment from '~/components/dashboard/invoices/payment/RhapsodyPayment'
import { POSPaymentFailureAlertDialog } from '~/components/dashboard/invoices/POSPaymentFailureAlertDialog'
import { PaymentTypes } from '~/constants/financeConstants'
import PaymentType from '~/constants/paymentTypes'
import { FAILED } from '~/constants/transactionStatus'
import i18nPortal from '~/locales/i18n'
import {
  clearClientBillingAddresses,
  clearProcessedBillingAddress,
  clearTransactionInfo,
  createManualPayment,
  createPaymentTransaction,
  fetchBillingAddresses,
  linkUnappliedPayments,
  rhapsodyGoApplyPayment,
} from '~/store/actions/payments'
import {
  getHasUnpaidInvoices,
  getUnappliedPaymentLoading,
  getUnappliedPaymentPageData,
  getUnpaidInvoiceLoading,
  getUnpaidInvoicePageData,
  refreshClientBillingData,
} from '~/store/duck/clientBillingActivityData'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import { useProcessBillingAddress } from '~/store/hooks/finance'
import { getCurrentBusiness, getCurrentUserId } from '~/store/reducers/auth'
import { getPaymentType } from '~/store/reducers/constants'
import {
  getBillingAddresses,
  getIsPosPayInTransaction,
  getPaymentsIsLinkingUnappliedPayments,
  getPaymentsIsLoading,
  getPaymentsIsSaving,
  getPosPayTransaction,
  getProcessedBillingAddress,
} from '~/store/reducers/payments'
import { getMultipleUsers, getUser } from '~/store/reducers/users'
import { PaymentPayload } from '~/types'
import { filterUsers } from '~/utils'
import { isRhapsodyGoAvailableForPractice } from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import { AddClientPaymentDialogActions } from './AddClientPaymentDialogActions'
import { AddClientPaymentInitialization } from './AddClientPaymentInitialization'

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      padding: theme.spacing(2),
      margin: theme.spacing(0, -2),
    },
    billingAddressFormAccordion: {
      margin: theme.spacing(1, 0),
    },
    billingAddressFormContent: {
      padding: theme.spacing(0, 0, 1, 0),
    },
    contentContainer: {
      margin: theme.spacing(-2, -2, 0, -2),
      padding: theme.spacing(2),
      overflow: 'hidden',
    },
    hidden: {
      display: 'none',
    },
    otherActions: {
      padding: theme.spacing(2, 0, 0, 2),
      width: `calc(100% + ${theme.spacing(4)})`,
      margin: theme.spacing(0, -2),
    },
    manualPaymentContainer: {
      padding: theme.spacing(1, 0),
    },
    paper: {
      maxWidth: 1840,
      padding: theme.spacing(2, 2, 0, 2),
      transition: theme.transitions.create(['min-width', 'max-width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    paperBillingAddresses: {
      minWidth: 1024,
      maxWidth: 1024,
    },
    paperCardDetails: {
      minWidth: 480,
      maxWidth: 480,
    },
    paymentCardDetailsContainer: {
      padding: theme.spacing(0),
    },
    smallPaper: {
      width: 650,
      minHeight: 270,
      maxWidth: 650,
    },
    title: {
      padding: theme.spacing(0, 0, 1, 2),
      margin: theme.spacing(0, -2),
      lineHeight: 1.625,
    },
  }),
  { name: 'AddClientPaymentDialog' },
)

enum Steps {
  INITIALIZATION = 'INITIALIZATION',
  MANUAL_PAYMENT_DETAILS = 'MANUAL_PAYMENT_DETAILS',
  BILLING_ADDRESSES = 'BILLING_ADDRESSES',
  CARD_DETAILS = 'CARD_DETAILS',
}

const DialogTitleMap = {
  [Steps.INITIALIZATION]: i18nPortal.t('Common:ADD_PAYMENT'),
  [Steps.MANUAL_PAYMENT_DETAILS]: i18nPortal.t(
    'Common:PAYMENTS.PAYMENT_INFORMATION',
  ),
  [Steps.BILLING_ADDRESSES]: i18nPortal.t('Common:PAYMENTS.BILLING_ADDRESS'),
  [Steps.CARD_DETAILS]: i18nPortal.t('Common:PAYMENTS.PAYMENT_INFORMATION'),
}

interface AddClientPaymentDialogProps extends BasePuiDialogProps {
  clientId: string
  onClickEstimate: (estimate: GraphqlEstimate) => void
}

export const AddClientPaymentDialog = ({
  clientId,
  open,
  onClickEstimate,
  onClose,
}: AddClientPaymentDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Validations')

  const isFetchingUnpaidInvoices = useSelector(getUnpaidInvoiceLoading)
  const isFetchingUnappliedPayments = useSelector(getUnappliedPaymentLoading)
  const currentBusiness = useSelector(getCurrentBusiness)
  const businessId = currentBusiness?.id
  const rhapsodyPayInitialConfig =
    useSelector(getRhapsodyPayConfig(businessId)) || {}
  const billingAddresses = useSelector(getBillingAddresses)
  const client = useSelector(getUser(clientId))
  const isFinanceLoadingOrSaving = useSelector(getPaymentsIsLoading)
  const coparents = useSelector(getMultipleUsers(client?.coparents))
  const PaymentTypeConstant = useSelector(getPaymentType)
  const posPayTransaction = useSelector(getPosPayTransaction)
  const isRhapsodyPaymentInProgress = useSelector(getIsPosPayInTransaction)
  const processedBillingAddress = useSelector(getProcessedBillingAddress)
  const userId = useSelector(getCurrentUserId)
  const unpaidInvoices = useSelector(getUnpaidInvoicePageData)
  const hasUnpaidInvoices = useSelector(getHasUnpaidInvoices)
  const unappliedPayments = useSelector(getUnappliedPaymentPageData)

  const [transaction, setTransaction] = useState<Record<string, any>>({})
  const [procedureType, setProcedureType] = useState('')
  const [step, setStep] = useState(Steps.INITIALIZATION)
  const [prevStep, setPrevStep] = useState(Steps.INITIALIZATION)
  const [fakeIsLoading, setFakeIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const billingAddressRef = useRef<BillingAddressFormHandle>(null)

  useEffect(() => {
    if (clientId) {
      dispatch(fetchBillingAddresses(clientId))
    }
  }, [clientId])

  const searchList = filterUsers([client || {}].concat(coparents), searchQuery)
  const isGoAvailableForPractice = isRhapsodyGoAvailableForPractice(
    currentBusiness,
    rhapsodyPayInitialConfig,
  )

  const isLoading = isFetchingUnpaidInvoices || isFetchingUnappliedPayments
  const dialogTitle = DialogTitleMap[step]
  const getHasApplyPaymentsEnabled = (
    selectedInvoiceIds: string[],
    selectedPaymentIds: string[],
  ) => !R.isEmpty(selectedInvoiceIds) && !R.isEmpty(selectedPaymentIds)

  const { fields, validate } = useFields(
    [
      {
        name: 'paymentAmount',
        initialValue: 0,
      },
      {
        name: 'paymentType',
        initialValue: PaymentTypes.CREDIT_CARD,
        validators: [
          {
            validator: ({ state, value }) => {
              const { paymentAmount, selectedInvoiceIds, selectedPaymentIds } =
                state
              if (
                paymentAmount <= 0 &&
                getHasApplyPaymentsEnabled(
                  selectedInvoiceIds,
                  selectedPaymentIds,
                )
              ) {
                return true
              }
              return Boolean(value)
            },
            validatorName: 'paymentTypeValidator',
          },
        ],
        messages: {
          paymentTypeValidator: t('Validations:REQUIRED_FIELD'),
        },
      },
      {
        name: 'selectedInvoiceIds',
        initialValue: [],
        type: 'select',
        validators: [
          {
            validator: ({ state, value }) => {
              const hasPaymentIds = state.selectedPaymentIds.length > 0
              return hasPaymentIds && hasUnpaidInvoices
                ? value.length > 0
                : true
            },
            validatorName: 'selectAtLeastOneUnpaidInvoice',
          },
        ],
        messages: {
          selectAtLeastOneUnpaidInvoice: t(
            'Validations:SELECT_AT_LEAST_ONE_UNPAID_INVOICE',
          ),
        },
      },
      {
        name: 'selectedPaymentIds',
        initialValue: [],
        type: 'select',
        validators: [
          {
            validator: ({ state, value }) => {
              const isApplyPaymentsFlow = !state.paymentType
              return isApplyPaymentsFlow ? value.length > 0 : true
            },
            validatorName: 'selectAtLeastOneUnappliedPayment',
          },
        ],
        messages: {
          selectAtLeastOneUnappliedPayment: t(
            'Validations:SELECT_AT_LEAST_ONE_UNAPPLIED_PAYMENT',
          ),
        },
      },
    ],
    false,
  )

  const { paymentAmount, paymentType, selectedInvoiceIds, selectedPaymentIds } =
    fields

  const serviceFeePercentage = rhapsodyPayInitialConfig?.serviceFee || 0
  const serviceFeeAmount = serviceFeePercentage
    ? Utils.round(paymentAmount.value * serviceFeePercentage, 2)!
    : 0
  const totalSelectedUnappliedPayments = unappliedPayments
    .filter((p) => selectedPaymentIds.value.includes(p.id))
    .reduce((total, payment) => {
      total +=
        payment.__typename === 'Payment'
          ? payment.unappliedAmount
          : payment.amount
      return total
    }, 0)
  const totalSelectedUnpaidInvoices = unpaidInvoices
    .filter((i) => selectedInvoiceIds.value.includes(i?.id))
    .reduce((total, invoice) => {
      total += invoice.dueToPayNoFee
      return total
    }, 0)
  const totalDueToPay =
    totalSelectedUnpaidInvoices - totalSelectedUnappliedPayments
  const isOtherPaymentType = paymentType.value === PaymentTypes.OTHER
  const hasApplyPaymentsEnabled = getHasApplyPaymentsEnabled(
    selectedInvoiceIds.value,
    selectedPaymentIds.value,
  )

  const goToStep = (newStep: Steps) => {
    setPrevStep(step)
    setStep(newStep)
  }

  const onContinueToBillingAddresses = (type?: PaymentType) => {
    if (type) {
      setProcedureType(type)
    }

    goToStep(Steps.BILLING_ADDRESSES)
  }

  const onRecordManually = () => {
    dispatch(clearTransactionInfo())
    setTransaction({})
    goToStep(Steps.MANUAL_PAYMENT_DETAILS)
  }

  const onPaymentTypeSelected = (value?: PaymentTypes) => {
    if (!validate()) {
      return
    }

    const paymentTypeAction = {
      [PaymentTypes.CREDIT_CARD]: onContinueToBillingAddresses,
      [PaymentTypes.PRE_AUTHORIZATION]: () =>
        onContinueToBillingAddresses(PaymentType.AUTH),
      [PaymentTypes.OTHER]: onRecordManually,
    }

    const currentAction = value ? paymentTypeAction[value] : undefined

    if (currentAction) {
      currentAction()
    }
  }

  const getPaymentAmount = () =>
    serviceFeeAmount && currentBusiness?.posPayEnabled
      ? Utils.round(paymentAmount.value + serviceFeeAmount, 2)
      : Number(paymentAmount.value)

  const onSubmitPosPayment = () => {
    if (validate()) {
      const payload = {
        paymentIds: selectedPaymentIds.value || [],
        aggregatedPaymentTotal: totalSelectedUnappliedPayments,
        aggregatedInvoiceTotal: totalSelectedUnpaidInvoices,
        transaction: {
          paymentTypeId: Utils.findConstantIdByName(
            PaymentType.PAYMENT,
            PaymentTypeConstant,
          ),
          amount: getPaymentAmount(),
          invoiceIds: selectedInvoiceIds.value || [],
        },
      } as any

      dispatch(createPaymentTransaction(clientId, payload, true))
    }
  }

  const handleClose = () => {
    dispatch(clearTransactionInfo())
    dispatch(clearClientBillingAddresses())
    onClose?.()
  }

  const onOkAndClose = () => {
    setSearchQuery('')
    dispatch(clearProcessedBillingAddress())
    handleClose()
  }

  const setCloseAfterCreation = useCloseAfterCreation(
    onOkAndClose,
    getPaymentsIsSaving,
  )

  const setCloseAfterLinkingUnappliedPayments = useCloseAfterCreation(() => {
    onClose?.()
    dispatch(refreshClientBillingData())
  }, getPaymentsIsLinkingUnappliedPayments)

  const onApplyPayments = () => {
    if (!validate()) {
      return
    }

    setCloseAfterLinkingUnappliedPayments()
    dispatch(
      linkUnappliedPayments(selectedInvoiceIds.value, selectedPaymentIds.value),
    )
  }

  const onCreateManualPayment = (payment: any) => {
    const payload: PaymentPayload = {
      aggregatedPaymentTotal: totalSelectedUnappliedPayments,
      aggregatedInvoiceTotal: totalSelectedUnpaidInvoices,
      invoiceIds: selectedInvoiceIds.value || [],
      paymentIds: selectedPaymentIds.value || [],
      requestedPayment: payment,
    }
    setCloseAfterCreation()
    dispatch(createManualPayment(clientId, payload))
  }

  const onChargePayment = (
    cryptogram: string,
    transactionType: PaymentType,
    billingAddress: BillingAddress,
  ) => {
    if (R.isEmpty(processedBillingAddress) && R.isEmpty(billingAddress)) {
      return
    }

    const payload: PaymentPayload = {
      aggregatedInvoiceTotal: totalSelectedUnpaidInvoices,
      aggregatedPaymentTotal: totalSelectedUnappliedPayments,
      invoiceIds: selectedInvoiceIds.value,
      paymentIds: selectedPaymentIds.value,
      requestedPayment: {
        amount: getPaymentAmount(),
        billingAddressId: processedBillingAddress?.id || billingAddress.id,
        businessId,
        cryptogram,
        notes: '',
        personId: clientId,
        userId,
      },
    }

    const isAuthTransaction = transactionType === PaymentType.AUTH

    dispatch(
      rhapsodyGoApplyPayment({
        auth: isAuthTransaction,
        paymentData: payload,
      }),
    )
  }

  const onCheckPayments = (paymentId: string) => {
    const newPaymentIds = Utils.toggleListItem(
      paymentId,
      selectedPaymentIds.value,
    )
    selectedPaymentIds.setValue(newPaymentIds)
  }

  const onCheckInvoices = (invoiceId: string) => {
    const newInvoiceIds = Utils.toggleListItem(
      invoiceId,
      selectedInvoiceIds.value,
    )
    selectedInvoiceIds.setValue(newInvoiceIds)
  }

  const onBack = () => {
    setStep(prevStep)
  }

  const onBackBillingAddress = () => {
    paymentAmount.setValue(0)
    setProcedureType('')
    setStep(Steps.INITIALIZATION)
  }

  const onBackCardDetails = () => {
    onBack()
    dispatch(clearProcessedBillingAddress())
  }

  const onCancelRequested = () => {
    setTransaction({})
    onRecordManually()
  }

  const onResendTransaction = () => {
    onSubmitPosPayment()
    setTransaction({})
  }

  const onSearchQueryChange = (newQuery: string, userChanged?: boolean) => {
    setSearchQuery(newQuery)
    if (userChanged) {
      setFakeIsLoading(true)
      setTimeout(() => {
        setFakeIsLoading(false)
      })
    }
  }

  const onProcessBillingAddresses = useProcessBillingAddress({
    billingAddressRef,
    clientId,
    onProceed: () => goToStep(Steps.CARD_DETAILS),
  })

  useEffect(() => {
    if (businessId) {
      dispatch(fetchRhapsodyPayConfig(businessId))
    }
  }, [businessId])

  return (
    <PuiDialog
      actions={
        !isRhapsodyPaymentInProgress && (
          <>
            {step === Steps.INITIALIZATION && (
              <AddClientPaymentDialogActions
                hasApplyPaymentsEnabled={hasApplyPaymentsEnabled}
                isGoAvailableForPractice={isGoAvailableForPractice}
                isLoading={isLoading}
                isOtherPaymentType={isOtherPaymentType}
                isSaving={isFinanceLoadingOrSaving}
                paymentAmount={paymentAmount.value}
                totalDueToPay={totalDueToPay}
                onApplyPayments={onApplyPayments}
                onPaymentTypeSelected={onPaymentTypeSelected}
                onRecordManually={onRecordManually}
                onSendToTerminal={onSubmitPosPayment}
              />
            )}
            {step === Steps.BILLING_ADDRESSES && (
              <BillingAddressesActions
                onBack={onBackBillingAddress}
                onProcessBillingAddresses={onProcessBillingAddresses}
              />
            )}
          </>
        )
      }
      aria-labelledby="add-client-payment-dialog"
      classes={
        isRhapsodyPaymentInProgress
          ? {
              paper: classes.smallPaper,
            }
          : {
              actions: classes.actions,
              paper: classNames(classes.paper, {
                [classes.paperBillingAddresses]:
                  step === Steps.BILLING_ADDRESSES ||
                  step === Steps.MANUAL_PAYMENT_DETAILS,
                [classes.paperCardDetails]: step === Steps.CARD_DETAILS,
              }),
              dialogTitle: classes.title,
              dialogContentRoot: classes.contentContainer,
            }
      }
      open={open}
      title={!isRhapsodyPaymentInProgress && dialogTitle}
      onClose={handleClose}
    >
      {isRhapsodyPaymentInProgress && (
        <RhapsodyPayment
          clientId={clientId}
          transaction={posPayTransaction}
          onOk={handleClose}
        />
      )}
      <Box
        className={classNames({
          [classes.hidden]: isRhapsodyPaymentInProgress,
        })}
      >
        {step === Steps.INITIALIZATION && (
          <AddClientPaymentInitialization
            clientId={clientId}
            hasApplyPaymentsEnabled={hasApplyPaymentsEnabled}
            isOtherPaymentType={isOtherPaymentType}
            isSaving={isFinanceLoadingOrSaving}
            paymentAmountField={paymentAmount}
            paymentTypeField={paymentType}
            selectedInvoiceIds={selectedInvoiceIds}
            selectedPaymentIds={selectedPaymentIds}
            serviceFeeAmount={serviceFeeAmount}
            totals={{
              totalSelectedUnappliedPayments,
              totalSelectedUnpaidInvoices,
              totalDueToPay,
            }}
            onCheckInvoices={onCheckInvoices}
            onCheckPayments={onCheckPayments}
            onClickEstimate={onClickEstimate}
          />
        )}
        {step === Steps.BILLING_ADDRESSES && (
          <BillingAddressForm
            billingAddresses={billingAddresses}
            business={currentBusiness}
            classes={{
              accordionRoot: classes.billingAddressFormAccordion,
              content: classes.billingAddressFormContent,
            }}
            client={client}
            currentBillingAddress={undefined}
            ref={billingAddressRef}
          />
        )}
        {step === Steps.CARD_DETAILS && (
          <PaymentCardDetails
            classes={{
              content: classes.paymentCardDetailsContainer,
              footer: classes.otherActions,
            }}
            clientId={clientId}
            isAchRefund={false}
            isAuth={procedureType === PaymentType.AUTH}
            paymentAmount={paymentAmount.value}
            onBack={onBackCardDetails}
            onOk={onOkAndClose}
            onProceed={onChargePayment}
          />
        )}
        {step === Steps.MANUAL_PAYMENT_DETAILS && (
          <ManualPayment
            classes={{
              footer: classes.otherActions,
              root: classes.manualPaymentContainer,
            }}
            clientId={clientId}
            invoiceIds={selectedInvoiceIds.value}
            isLoading={isFinanceLoadingOrSaving}
            isLoadingSearchResults={fakeIsLoading}
            paymentAmount={paymentAmount.value}
            searchList={searchList}
            searchQuery={searchQuery}
            updatePaymentAmount={paymentAmount.setValue}
            onBack={onBack}
            onCreateManualPayment={onCreateManualPayment}
            onSearchQueryChange={onSearchQueryChange}
          />
        )}
      </Box>
      <POSPaymentFailureAlertDialog
        open={transaction.state === FAILED}
        setTransaction={setTransaction}
        transaction={transaction}
        onCancelRequested={onCancelRequested}
        onResendTransaction={onResendTransaction}
      />
    </PuiDialog>
  )
}
