import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  CREATE_DEPARTMENT,
  createDepartment,
  createDepartmentFailure,
  createDepartmentSuccess,
  FETCH_DEPARTMENTS,
  fetchDepartments,
  fetchDepartmentsFailure,
  fetchDepartmentsSuccess,
  TOGGLE_DEPARTMENT_STATE,
  toggleDepartmentState,
  toggleDepartmentStateFailure,
  toggleDepartmentStateSuccess,
} from '../duck/departments'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchDepartmentsSaga({
  businessId,
  includeInactive,
}: ReturnType<typeof fetchDepartments>) {
  try {
    const {
      result: { data: list },
      entities,
    } = yield* requestAPI(API.fetchDepartments, businessId, includeInactive)
    yield call(updateEntities, entities)
    yield put(fetchDepartmentsSuccess(list))
  } catch (error) {
    yield put(fetchDepartmentsFailure(error as ApiError))
  }
}

export function* createDepartmentSaga({
  businessId,
  newDepartment,
}: ReturnType<typeof createDepartment>) {
  try {
    const {
      result: { data: departmentId },
    } = yield* requestAPI(API.createDepartment, businessId, newDepartment)
    yield put(fetchDepartments(businessId, true))
    yield put(createDepartmentSuccess(departmentId))
  } catch (error) {
    yield put(createDepartmentFailure(error as ApiError))
  }
}

export function* toggleDepartmentStateSaga({
  businessId,
  departmentId,
}: ReturnType<typeof toggleDepartmentState>) {
  try {
    const { entities } = yield* requestAPI(
      API.toggleDepartmentState,
      businessId,
      departmentId,
    )
    yield call(updateEntities, entities)
    yield put(toggleDepartmentStateSuccess(departmentId))
  } catch (error) {
    yield put(toggleDepartmentStateFailure(error as ApiError))
  }
}

function* watchFetchDepartments() {
  yield takeLatest(FETCH_DEPARTMENTS, fetchDepartmentsSaga)
}

function* watchCreateDepartment() {
  yield takeLatest(CREATE_DEPARTMENT, createDepartmentSaga)
}

function* watchToggleDepartmentState() {
  yield takeLatest(TOGGLE_DEPARTMENT_STATE, toggleDepartmentStateSaga)
}

export default function* departmentsSaga() {
  yield all([
    watchFetchDepartments(),
    watchCreateDepartment(),
    watchToggleDepartmentState(),
  ])
}
