import React from 'react'
import * as R from 'ramda'
import {
  Constant,
  InventorySubCategoryConstant,
  LanguageUtils,
  Nil,
} from '@pbt/pbt-ui-components'

import { ColumnNames } from '~/constants/onHandConstants'
import { Variation } from '~/types'
import { normalizeInputAsString } from '~/utils'

// @ts-ignore
import { getOnHandAmount } from '../inventoryUtils'

const css = `
  .content {
    font-size: 12px;
    padding: 2.4em;
    fontFamily: 'Rubik, sans-serif';
  }
  .heading {
    font-weight: 500;
    font-size: 1.4em;
    width: 12em;
    marginBottom: 1.6em;
  }
  table, thead, tbody {
    font-size: 1em;
  }
  th, td {
    font-size: 1em;
    padding: 0.8rem;
  }
  th {
    font-weigth: 500;
    text-align: left;
    border-bottom: 1px solid #4C4949;
    vertical-align: bottom;
  }
  td {
    vertical-align: top;
    border-bottom: 1px solid #D8D8D8;
    border-left: 1px solid #D8D8D8;
  }
  td:first-child, th:first-child {
    padding-left: 2px;
    border-left: none;
  }
`

const nonBreakingHyphens = (str: string) => str.replace('-', '‑')

const getConstantName = (id: string, constant: Constant[]) => {
  const name = LanguageUtils.getConstantTranslatedName(id, constant)

  return R.pipe(normalizeInputAsString, nonBreakingHyphens)(name)
}

export interface OnHandCatalogPrintPageProps {
  activeColumns: string[]
  constants: {
    InventoryCategory: Constant[]
    InventoryOnHandStatus: Constant[]
    InventoryProductSizeUnit: Constant[]
    InventorySubCategory: InventorySubCategoryConstant
    PackageType: Constant[]
  }
  getInStockAmount: (data: {
    amount?: number
    amountUnitsId?: string
    desiredOnHandAmount?: number | Nil
    packageTypeId?: string
    perPackageUnitsId?: string
  }) => string
  heading?: string
  items?: Variation[]
}

const OnHandCatalogPrintPage = ({
  activeColumns,
  constants,
  getInStockAmount,
  items,
  heading,
}: OnHandCatalogPrintPageProps) => {
  const activeColumnsSet = new Set(activeColumns || [])
  const {
    InventoryCategory,
    InventoryOnHandStatus,
    InventoryProductSizeUnit,
    InventorySubCategory,
    PackageType,
  } = constants

  return (
    <div className="content">
      <style>{css}</style>
      <h1 className="heading">{heading}</h1>
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            {activeColumnsSet.has(ColumnNames.ITEM) && (
              <th>{ColumnNames.ITEM}</th>
            )}
            {activeColumnsSet.has(ColumnNames.CATEGORY) && (
              <th>{ColumnNames.CATEGORY}</th>
            )}
            {activeColumnsSet.has(ColumnNames.SUB_CATEGORY) && (
              <th>{ColumnNames.SUB_CATEGORY}</th>
            )}
            {activeColumnsSet.has(ColumnNames.ON_HAND_AMOUNT) && (
              <th>{nonBreakingHyphens(ColumnNames.ON_HAND_AMOUNT)}</th>
            )}
            {activeColumnsSet.has(ColumnNames.LAST_DISTRIBUTOR) && (
              <th>{ColumnNames.LAST_DISTRIBUTOR}</th>
            )}
            {activeColumnsSet.has(ColumnNames.RE_ORDER_POINT) && (
              <th>{nonBreakingHyphens(ColumnNames.RE_ORDER_POINT)}</th>
            )}
            {activeColumnsSet.has(ColumnNames.RE_ORDER_QUANTITY) && (
              <th>{nonBreakingHyphens(ColumnNames.RE_ORDER_QUANTITY)}</th>
            )}
            {activeColumnsSet.has(ColumnNames.RE_ORDER_MAX_QUANTITY) && (
              <th>{nonBreakingHyphens(ColumnNames.RE_ORDER_MAX_QUANTITY)}</th>
            )}
            {activeColumnsSet.has(ColumnNames.ON_HAND_STATUS) && (
              <th>{nonBreakingHyphens(ColumnNames.ON_HAND_STATUS)}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {(items || []).map((item) => (
            <tr key={item.id}>
              {activeColumnsSet.has(ColumnNames.ITEM) && <td>{item.name}</td>}
              {activeColumnsSet.has(ColumnNames.CATEGORY) && (
                <td>{getConstantName(item.categoryId, InventoryCategory)}</td>
              )}
              {activeColumnsSet.has(ColumnNames.SUB_CATEGORY) && (
                <td>
                  {getConstantName(
                    item.subcategoryId,
                    InventorySubCategory[item.categoryId],
                  )}
                </td>
              )}
              {activeColumnsSet.has(ColumnNames.ON_HAND_AMOUNT) && (
                <td>
                  {getOnHandAmount(InventoryProductSizeUnit, PackageType)(item)}
                </td>
              )}
              {activeColumnsSet.has(ColumnNames.LAST_DISTRIBUTOR) && (
                <td>{item.lastDistributor}</td>
              )}
              {activeColumnsSet.has(ColumnNames.RE_ORDER_POINT) && (
                <td>
                  {getInStockAmount({
                    amount: item.reorderPoint,
                    amountUnitsId: item.reorderPointUnitId,
                    perPackageUnitsId: item.perPackageUnitsId,
                    packageTypeId: item.packageTypeId,
                  })}
                </td>
              )}
              {activeColumnsSet.has(ColumnNames.RE_ORDER_QUANTITY) && (
                <td>
                  {getInStockAmount({
                    amount: item.reorderQuantity,
                    amountUnitsId: item.reorderQuantityUnitId,
                    perPackageUnitsId: item.perPackageUnitsId,
                    packageTypeId: item.packageTypeId,
                  })}
                </td>
              )}
              {activeColumnsSet.has(ColumnNames.RE_ORDER_MAX_QUANTITY) && (
                <td>
                  {getInStockAmount({
                    amount: item.maxQuantity,
                    amountUnitsId: item.maxQuantityUnitId,
                    perPackageUnitsId: item.perPackageUnitsId,
                    packageTypeId: item.packageTypeId,
                  })}
                </td>
              )}
              {activeColumnsSet.has(ColumnNames.ON_HAND_STATUS) && (
                <td>
                  {getConstantName(item.onHandStatusId, InventoryOnHandStatus)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default OnHandCatalogPrintPage
