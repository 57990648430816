import React from 'react'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { useTaskTypes } from '~/store/hooks/tasks'
import { Task } from '~/types'

interface TaskTypeCellProps {
  item: Task
}

const TaskTypeCell = ({ item }: TaskTypeCellProps) => {
  const TaskTypes = useTaskTypes()

  return (
    <>{LanguageUtils.getConstantTranslatedName(item.typeId, TaskTypes, '')}</>
  )
}

export default TaskTypeCell
