import { requestQuery } from './graphql/graphqlCore'
import {
  FECTH_CHARGES_BY_LOG,
  FETCH_SOAP_CHARGES,
} from './graphql/queries/chargeSheet'

export const fetchSoapCharges = requestQuery({
  query: FETCH_SOAP_CHARGES,
  variablesHandler: (businessId, clientId, soapId, includeDeleted) => ({
    businessId,
    id: clientId,
    includeDeleted,
    soapId,
  }),
})

export const fetchChargesByLog = requestQuery({
  query: FECTH_CHARGES_BY_LOG,
  variablesHandler: (businessId, logId, logType) => ({
    businessId,
    logId,
    logType,
  }),
})
