import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchLastMonthAppointments = (
  businessId: string,
  patientId: string,
  includeUpcoming: boolean,
) =>
  request(
    getAPIPath('appointments/lastMonth'),
    { method: 'GET', params: { businessId, patientId, includeUpcoming } },
    true,
  )

export const fetchLastAppointment = (businessId: string, patientId: string) =>
  request(
    getAPIPath('appointments/last'),
    { method: 'GET', params: { businessId, patientId } },
    true,
    Schema.event,
  )
