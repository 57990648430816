import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { AddButton, RoleName, User, Utils } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  createTeamMemberEmployeeId,
  deleteTeamMemberEmployeeId,
  editTeamMemberEmployeeId,
} from '~/store/actions/members'
import { getMembersIsUpdating } from '~/store/reducers/members'
import { EmployeeIdInfoToCreate } from '~/types'
import useDialog from '~/utils/useDialog'
import useHasRole from '~/utils/useHasRole'

import EmployeeIdSectionTable from '../EmployeeIdSectionTable'

interface MemberLicensesSectionProps {
  onEmployeeIdChange: () => void
  teamMember: User
}

const MemberEmployeeIdSection = ({
  teamMember,
  onEmployeeIdChange = R.F,
}: MemberLicensesSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const [openEmployeeIdDialog] = useDialog(DialogNames.EMPLOYEE_ID)

  const isUpdating = useSelector(getMembersIsUpdating)
  const isSuperAdmin = useHasRole(RoleName.SuperAdmin)
  const isPetabyteAdmin = useHasRole(RoleName.PetabyteAdministrator)
  const isPracticeAdmin = useHasRole(RoleName.PracticeAdministrator)
  const hasPermissionToModify =
    isSuperAdmin || isPetabyteAdmin || isPracticeAdmin

  const onCreateEmployeeIdInfo = (
    newEmployeeIdInfo: EmployeeIdInfoToCreate,
  ) => {
    dispatch(createTeamMemberEmployeeId(teamMember.id, newEmployeeIdInfo))
    onEmployeeIdChange()
  }

  const onEditEmployeeIdInfo = (newEmployeeIdInfo: EmployeeIdInfoToCreate) => {
    dispatch(editTeamMemberEmployeeId(teamMember.id, newEmployeeIdInfo))
    onEmployeeIdChange()
  }

  const onEmployeeIdInfoDelete = (employeeIdInfoId: string) => {
    dispatch(deleteTeamMemberEmployeeId(teamMember.id, employeeIdInfoId))
    onEmployeeIdChange()
  }

  const onAddEmployeeIdInfoRequested = () => {
    openEmployeeIdDialog({
      onCreateEmployeeIdInfo,
      onEditEmployeeIdInfo,
    })
  }

  const onEmployeeIdInfoEditRequested = (employeeIdInfoId: string) => {
    openEmployeeIdDialog({
      employeeIdInfo: Utils.findById(
        employeeIdInfoId,
        teamMember.employeeIdInfos || [],
      ),
      onCreateEmployeeIdInfo,
      onEditEmployeeIdInfo,
    })
  }

  return (
    <Grid container item>
      <EmployeeIdSectionTable
        deleteDisabled={!hasPermissionToModify}
        editDisabled={!hasPermissionToModify}
        employeeIdInfos={teamMember.employeeIdInfos || []}
        isDeleting={isUpdating}
        useIndexAsId={false}
        onDelete={onEmployeeIdInfoDelete}
        onEdit={onEmployeeIdInfoEditRequested}
      />
      {hasPermissionToModify && (
        <Grid item mt={2}>
          <AddButton
            addText={t('Admin:MEMBER.EMPLOYEE_ID.ADD_EMPLOYEE_ID_ACTION')}
            onAdd={onAddEmployeeIdInfoRequested}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default MemberEmployeeIdSection
