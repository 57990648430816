import React from 'react'
import { TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ClassesType } from '@pbt/pbt-ui-components'

import i18nPortal from '~/locales/i18n'

import SoapTemplateTabsTableColumn from './SoapTemplateTabsTableColumn'

const useStyles = makeStyles(
  () => ({
    cell: {},
    mainColumn: {},
  }),
  { name: 'SoapTemplateTabsTableHeader' },
)

const columns = [
  {
    label: i18nPortal.t('Admin:SOAP_CUSTOMIZATION.TAB_NAME'),
    id: 'tab_name',
    width: '30%',
  },
  {
    label: i18nPortal.t('Admin:SOAP_CUSTOMIZATION.WIDGETS'),
    id: 'widgets',
    width: '70%',
  },
]

export interface SoapTemplateTabsTableHeaderProps {
  classes: ClassesType<typeof useStyles>
}

const SoapTemplateTabsTableHeader = ({
  classes: classesProp,
}: SoapTemplateTabsTableHeaderProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ id, label, width }, index) => (
          <SoapTemplateTabsTableColumn
            classes={{
              cell: classes.cell,
              mainColumn: classes.mainColumn,
            }}
            isMainColumn={index === 0}
            key={id}
            label={label}
            width={width}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

export default SoapTemplateTabsTableHeader
