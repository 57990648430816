export default {
  chrome: {
    install: 'https://www.google.com/chrome',
    update: 'https://www.google.com/chrome/update/',
  },
  safari: {
    install: 'https://www.apple.com/safari',
    update: 'https://support.apple.com/en-en/HT204416',
  },
}
