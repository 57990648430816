import React from 'react'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateFormat, moment } from '@pbt/pbt-ui-components'

import { isNow } from '~/utils/time'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 96,
      padding: theme.spacing(0, 1),
      border: theme.constants.tableBorder,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: theme.spacing(1),
      borderRadius: 2,
      color: theme.colors.primaryText,
      fontSize: '1.4rem',
      fontWeight: 500,
    },
    rootNow: {
      backgroundColor: theme.colors.tabSelected,
      color: theme.colors.profileText,
    },
  }),
  { name: 'DayTimeColumn' },
)

export interface DayTimeColumnProps {
  column: string
}

const DayTimeColumn = ({ column }: DayTimeColumnProps) => {
  const classes = useStyles()

  const date = moment(column)
  const isNowDate = isNow({ date })

  return (
    <Paper
      className={classNames(classes.root, {
        [classes.rootNow]: isNowDate,
      })}
      elevation={0}
    >
      {date.format(DateFormat.FULL_TIME_WITH_MERIDIAN)}
    </Paper>
  )
}

export default DayTimeColumn
