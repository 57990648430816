import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { DateUtils, Text } from '@pbt/pbt-ui-components'

import { LabTestDashboardItem } from '~/types'

const formatOrderedDate = (order: LabTestDashboardItem) =>
  order?.orderedDate ? DateUtils.formatTime(order.orderedDate) : undefined

interface OrderedDateCellProps {
  item: LabTestDashboardItem
}

const OrderedDateCell = ({ item }: OrderedDateCellProps) => (
  <Dotdotdot clamp={1}>
    <Text strong variant="body2">
      {formatOrderedDate(item)}
    </Text>
  </Dotdotdot>
)

export default OrderedDateCell
