import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType, Defaults, FileTemplate } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import FileSelector, { FileSelectorProps } from './FileSelector'

const MaxFileSize = 5e7

interface SingleFileSelectorProps
  extends Omit<FileSelectorProps, 'processFiles' | 'renderInput'> {
  onSelected?: (fileTemplate: FileTemplate) => void
}

const SingleFileSelector = forwardRef(function SingleFileSelector(
  { onSelected, ...rest }: SingleFileSelectorProps,
  ref,
) {
  const { t } = useTranslation('Common')

  const [openFileAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const processFiles = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLInputElement>,
  ) => {
    // @ts-ignore
    const { files } = event.dataTransfer || event.target || {}
    if (!files || !files.length) {
      return
    }
    const file = files[0]

    if (file.size > MaxFileSize) {
      openFileAlert({
        iconType: AlertIconType.WARN,
        message: (
          <>
            {t('Common:INPUTS.FILE_SELECTOR.ALERT_MESSAGE', {
              maxFileSize: MaxFileSize / 1e6,
            })}
            &nbsp;
            <a href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
              {Defaults.SUPPORT_EMAIL}
            </a>
            .
          </>
        ),
      })
    } else {
      const reader = new FileReader()
      reader.onload = () => {
        const [, name, extension] = file.name.match(/(.*)\.(.*)/) || []
        if (onSelected) {
          onSelected({
            raw: file,
            data: reader.result as string,
            name,
            extension,
          })
        }
      }
      reader.readAsDataURL(file)
    }
  }

  return <FileSelector processFiles={processFiles} ref={ref} {...rest} />
})

export default SingleFileSelector
