import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  EventTypeName,
  Nil,
  TaskEventType,
  Utils,
} from '@pbt/pbt-ui-components'

import {
  FullScreenTasks,
  RX_REQUEST_TASK_TYPE,
  UncreatableTasks,
  VET_DIET_REQUEST_TASK_TYPE,
} from '~/constants/taskConstants'
import { Task, TaskTypesType } from '~/types'
import { useEvent, useEventType } from '~/utils/useEventType'

import { getTasksMap } from '../reducers/tasks'

export const useGetTaskTypeByTaskId = () => {
  const tasksMap = useSelector(getTasksMap)

  const taskTypes = useEventType(EventTypeName.Task, 'subTypes')

  return useCallback(
    (taskId: string | Nil) => {
      if (R.isNil(taskId)) {
        return undefined
      }
      const task = tasksMap[taskId]
      return Utils.getConstantName(task?.typeId, taskTypes)
    },
    [tasksMap, taskTypes],
  )
}

export const useIsFullscreenTask = (taskId: string | Nil): boolean => {
  const taskType = useGetTaskTypeByTaskId()(taskId)

  return FullScreenTasks.includes(taskType)
}

export const useGetTaskIsUnlinkedRxRequest = () => {
  const getTaskType = useGetTaskTypeByTaskId()

  return useCallback(
    (task: Task | Nil) => {
      const taskType = getTaskType(task?.id)

      return Boolean(
        task &&
          !R.isEmpty(task) &&
          R.isNil(task.patient) &&
          (taskType === RX_REQUEST_TASK_TYPE ||
            taskType === VET_DIET_REQUEST_TASK_TYPE),
      )
    },
    [getTaskType],
  )
}

export const useTaskEvent = () => {
  const TaskEvent = useEvent(EventTypeName.Task) as TaskEventType
  return TaskEvent
}

export const useTaskTypes = () => {
  const taskEvent = useTaskEvent()
  return taskEvent.subTypes
}

export const useCreatableTaskTypes = () => {
  const taskTypes = useTaskTypes()
  return taskTypes.filter(
    (taskType) => !UncreatableTasks.includes(taskType.name as TaskTypesType),
  )
}
