import React from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType, PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

export interface DeclineFinalizedLabTestDialogProps extends PuiAlertProps {
  declineOrder: () => void
  declineTest: () => void
  onClose: () => void
}

const DeclineFinalizedLabTestDialog = ({
  onClose,
  declineOrder,
  declineTest,
  ...rest
}: DeclineFinalizedLabTestDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])

  const handleDeclineTest = () => {
    declineTest()
    onClose()
  }

  const handleDeclineRequisition = () => {
    declineOrder()
    onClose()
  }

  return (
    <PuiAlert
      cancelButtonText={t('Common:REQUISITION')}
      iconType={AlertIconType.WARN}
      message={t('Dialogs:DECLINE_FINALIZED_LAB_TEST_DIALOG.MESSAGE')}
      okButtonText={t('Common:TEST')}
      onCancel={handleDeclineRequisition}
      onClose={onClose}
      onOk={handleDeclineTest}
      {...rest}
    />
  )
}

export default DeclineFinalizedLabTestDialog
