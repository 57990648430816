import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { PermissionArea } from '@pbt/pbt-ui-components'
import {
  Email as EmailIcon,
  Eye as EyeIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'
import { LabSnapshotItem, WidgetColumn } from '~/types'

import Snapshot from './Snapshot'

const columns: WidgetColumn<LabSnapshotItem>[] = [
  {
    id: 'name',
    label: '',
    prop: 'name',
    width: 5.5,
  },
]

const LabsSnapshot = () => {
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )
  const { read: labTestReadPermissions, update: labTestUpdatePermissions } =
    useSelector(getCRUDByArea(PermissionArea.LAB_TEST))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewInLabDashboard = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleGoToSoap = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePrintLabel = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewResults = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const printResults = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const emailResults = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleViewOrder = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePrintOrder = (item: LabSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handelCancelOrder = (item: LabSnapshotItem) => {}

  // Review permissions when implementing this Feature
  const getActions = (item: LabSnapshotItem) => [
    {
      id: 'labs',
      isGroup: true,
      items: [
        {
          id: 'viewInLabDashboard',
          label: t('Common:VIEW_IN_LAB_DASHBOARD'),
          Icon: EyeIcon,
          onClick: labTestReadPermissions
            ? () => handleViewInLabDashboard(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
        {
          id: 'goToSoap',
          label: t('Common:GO_TO_SOAP'),
          Icon: ArrowForwardIcon,
          onClick: soapReadPermissions ? () => handleGoToSoap(item) : undefined,
          disabled: !soapReadPermissions,
        },
        {
          id: 'printLabel',
          label: t('Common:PRINT_LABEL'),
          Icon: PrintIcon,
          onClick: labTestReadPermissions
            ? () => handlePrintLabel(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
      ],
    },
    {
      id: 'results',
      title: t('Common:RESULTS'),
      isGroup: true,
      items: [
        {
          id: 'viewResults',
          label: t('Common:VIEW_RESULTS'),
          Icon: EyeIcon,
          onClick: labTestReadPermissions
            ? () => handleViewResults(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
        {
          id: 'printResults',
          label: t('Common:PRINT_RESULTS'),
          Icon: PrintIcon,
          onClick: labTestReadPermissions
            ? () => printResults(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
        {
          id: 'emailResults',
          label: t('Common:EMAIL_RESULTS_ACTION'),
          Icon: EmailIcon,
          onClick: labTestReadPermissions
            ? () => emailResults(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
      ],
    },
    {
      id: 'order',
      title: t('Common:ORDER'),
      isGroup: true,
      items: [
        {
          id: 'viewOrder',
          label: t('Common:VIEW_ORDER'),
          Icon: EyeIcon,
          onClick: labTestReadPermissions
            ? () => handleViewOrder(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
        {
          id: 'printOrder',
          label: t('Common:PRINT_ORDER'),
          Icon: PrintIcon,
          onClick: labTestReadPermissions
            ? () => handlePrintOrder(item)
            : undefined,
          disabled: !labTestReadPermissions,
        },
        {
          id: 'cancelOrder',
          label: t('Common:CANCEL_ORDER'),
          Icon: CloseIcon,
          onClick: labTestUpdatePermissions
            ? () => handelCancelOrder(item)
            : undefined,
          disabled: !labTestUpdatePermissions,
        },
      ],
    },
  ]

  return (
    <Snapshot
      noItems
      addActionName={t(
        'Abbreviations:ACRONYMS.OVER_THE_COUNTER.ORDER_LAB_OTC_ACTION',
      )}
      columns={columns}
      data={[]}
      getActions={getActions}
      isLoading={false}
      noRecentItems={false}
      title={t('Abbreviations:COMMON.LABORATORIES')}
    />
  )
}

export default LabsSnapshot
