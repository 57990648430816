import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, moment, Nil } from '@pbt/pbt-ui-components'

import { TableFilter, Task } from '~/types'
import { arrayToMap, mergeArraysAtIndex } from '~/utils'
import { getErrorMessage, getServerValidationError } from '~/utils/errors'

import {
  ADD_TASK_TO_SOAP_LIST,
  CLEAR_SOAP_TASKS,
  CLEAR_TASKS_VALIDATION_ERROR,
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  CREATE_TASK_SUCCESS,
  CREATE_TASKS,
  CREATE_TASKS_FAILURE,
  CREATE_TASKS_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_FAILURE,
  DELETE_TASK_SUCCESS,
  DELETE_TASKS,
  DELETE_TASKS_FAILURE,
  DELETE_TASKS_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_FAILURE,
  EDIT_TASK_STATE,
  EDIT_TASK_STATE_FAILURE,
  EDIT_TASK_STATE_SUCCESS,
  EDIT_TASK_SUCCESS,
  EDIT_TASKS,
  EDIT_TASKS_FAILURE,
  EDIT_TASKS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_TASKS_LIST_SUCCESS,
  FETCH_SOAP_TASKS,
  FETCH_SOAP_TASKS_FAILURE,
  FETCH_SOAP_TASKS_IGNORED,
  FETCH_SOAP_TASKS_SUCCESS,
  FETCH_TASK,
  FETCH_TASK_FAILURE,
  FETCH_TASK_SUCCESS,
  FETCH_TASKS_LIST,
  FETCH_TASKS_LIST_FAILURE,
  FETCH_TASKS_LIST_SUCCESS,
  PARTIAL_EDIT_TASK,
  PARTIAL_EDIT_TASK_FAILURE,
  PARTIAL_EDIT_TASK_SUCCESS,
  REMOVE_TASK_FROM_LIST,
  SET_TASKS_LIST_FILTERS,
  UPDATE_SOAP_TASKS_SELECTED_DATE,
  UPDATE_TASKS,
  WS_TASK_CREATE,
  WS_TASK_CREATE_FAILURE,
  WS_TASK_CREATE_SUCCESS,
  WS_TASK_DELETE,
  WS_TASK_DELETE_FAILURE,
  WS_TASK_DELETE_SUCCESS,
  WS_TASK_UPDATE,
  WS_TASK_UPDATE_FAILURE,
  WS_TASK_UPDATE_SUCCESS,
} from '../actions/types/tasks'
import type { RootState } from '../index'

export type TasksState = {
  error: string | null
  filters: Record<string, TableFilter>
  isCreating: boolean
  isDeleting: boolean
  isFetching: boolean
  isLoading: boolean
  isLoadingList: boolean
  lastCreatedTaskId: string | null
  list: string[]
  map: Record<string, Task>
  pendingStateId: string | undefined
  pendingTasks: string[]
  soapTasksList: string[]
  soapTasksSelectedDate: string | null
  totalCount: number
  validationError: string | null
}

export const INITIAL_STATE: TasksState = {
  list: [],
  map: {},
  isLoading: false,
  isLoadingList: false,
  isDeleting: false,
  isFetching: false,
  isCreating: false,
  error: null,
  validationError: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  lastCreatedTaskId: null,
  filters: {},
  soapTasksList: [],
  pendingTasks: [],
  pendingStateId: undefined,
  soapTasksSelectedDate: null,
}

const tasks = (
  state: TasksState = INITIAL_STATE,
  action: AnyAction,
): TasksState => {
  function getTaskCreateSuccessState(taskId: string) {
    const newTaskDate = moment(state.map[taskId]?.dueDate)
    const dateIndex = state.list.findIndex(
      (id) => state.map[id] && newTaskDate.isAfter(state.map[id].dueDate),
    )
    const index = dateIndex === -1 ? state.list.length : dateIndex
    const list =
      dateIndex === -1 && state.totalCount > state.list.length
        ? state.list
        : [...state.list.slice(0, index), taskId, ...state.list.slice(index)]

    return {
      ...state,
      isLoading: false,
      isCreating: false,
      lastCreatedTaskId: taskId,
      totalCount: state.totalCount + 1,
      list,
    }
  }

  switch (action.type) {
    case FETCH_TASKS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isLoadingList: false,
      }
    case FETCH_TASKS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isLoadingList: false,
      }
    case FETCH_TASKS_LIST:
      return {
        ...state,
        isLoading: true,
        isLoadingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
        validationError: null,
      }
    case FETCH_MORE_ITEMS_FOR_TASKS_LIST:
      return { ...state, isLoading: true, isLoadingList: true }
    case FETCH_MORE_ITEMS_FOR_TASKS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isLoadingList: false,
      }
    case FETCH_MORE_ITEMS_FOR_TASKS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        isLoadingList: false,
        totalCount: action.totalCount,
      }
    case UPDATE_TASKS:
      return { ...state, map: R.mergeDeepRight(state.map, action.tasks) }
    case FETCH_TASK:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        error: null,
        validationError: null,
      }
    case FETCH_TASK_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    case CREATE_TASKS:
    case CREATE_TASK:
    case WS_TASK_CREATE:
      return { ...state, isLoading: true, isCreating: true }
    case EDIT_TASK:
    case PARTIAL_EDIT_TASK:
      return { ...state, isLoading: true }
    case EDIT_TASK_SUCCESS:
    case PARTIAL_EDIT_TASK_SUCCESS:
      return { ...state, isLoading: false }
    case CREATE_TASKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    case EDIT_TASK_FAILURE:
    case PARTIAL_EDIT_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    case EDIT_TASKS:
      return {
        ...state,
        isLoading: true,
        pendingTasks: action.tasks.map(({ id }: Task) => state.map[id]),
        map: {
          ...state.map,
          ...arrayToMap(action.tasks as Task[], R.prop('id')),
        },
      }
    case EDIT_TASKS_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_TASKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
        map: {
          ...state.map,
          ...arrayToMap(state.pendingTasks as any[], R.prop('id')),
        },
        pendingTasks: [],
      }
    case EDIT_TASK_STATE:
      return {
        ...state,
        isLoading: true,
        pendingStateId: state.map[action.taskId]?.stateId,
        map: {
          ...state.map,
          [action.taskId]: {
            ...state.map[action.taskId],
            stateId: action.stateId,
          },
        },
      }
    case EDIT_TASK_STATE_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_TASK_STATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
        map: {
          ...state.map,
          [action.taskId]: {
            ...state.map[action.taskId],
            stateId: state.pendingStateId,
          },
        },
        pendingStateId: undefined,
      }
    case DELETE_TASKS_SUCCESS:
    case WS_TASK_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.map(
          (task: Task) => ({
            ...task,
            children: R.without(action.taskIds, task.children || []),
          }),
          R.omit(action.taskIds, state.map),
        ),
        list: R.without(action.taskIds, state.list),
        soapTasksList: R.without(action.taskIds, state.soapTasksList),
        totalCount: Math.max(state.totalCount - action.taskIds.length, 0),
      }
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.map(
          (task: Task) => ({
            ...task,
            children: R.without([action.taskId], task.children || []),
          }),
          R.omit([action.taskId], state.map),
        ),
        list: R.without([action.taskId], state.list),
        soapTasksList: R.without([action.taskId], state.soapTasksList),
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case DELETE_TASKS:
    case DELETE_TASK:
    case WS_TASK_DELETE:
      return { ...state, isDeleting: true }
    case CREATE_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        soapTasksList: R.uniq(action.taskIds.concat(state.soapTasksList)),
      }
    case CREATE_TASK_SUCCESS:
    case WS_TASK_CREATE_SUCCESS:
      return getTaskCreateSuccessState(action.taskId)
    case CREATE_TASK_FAILURE:
    case WS_TASK_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        error: getErrorMessage(action.error),
        lastCreatedTaskId: null,
      }
    case SET_TASKS_LIST_FILTERS:
      return {
        ...state,
        filters: R.isEmpty(action.filters)
          ? INITIAL_STATE.filters
          : action.filters,
      }
    case FETCH_SOAP_TASKS:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        validationError: null,
      }
    case FETCH_SOAP_TASKS_IGNORED:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_SOAP_TASKS_SUCCESS:
      return {
        ...state,
        soapTasksList: action.list,
        isFetching: false,
        isLoading: false,
      }
    case FETCH_SOAP_TASKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_TASKS_FAILURE:
    case DELETE_TASK_FAILURE:
    case WS_TASK_DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    case ADD_TASK_TO_SOAP_LIST:
      return {
        ...state,
        soapTasksList: state.soapTasksList.includes(action.taskId)
          ? state.soapTasksList
          : [...state.soapTasksList, action.taskId],
      }
    case UPDATE_SOAP_TASKS_SELECTED_DATE:
      return { ...state, soapTasksSelectedDate: action.date, isFetching: true }
    case REMOVE_TASK_FROM_LIST:
      return {
        ...state,
        list: R.without([action.taskId], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
      }
    case CLEAR_TASKS_VALIDATION_ERROR:
      return { ...state, validationError: null }
    case CLEAR_SOAP_TASKS: {
      return { ...state, soapTasksList: [] }
    }
    case WS_TASK_UPDATE:
      return {
        ...state,
        error: null,
        isLoading: true,
        isFetching: true,
        isLoadingList: true,
      }
    case WS_TASK_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isLoadingList: false,
        soapTasksList: R.uniq(action.tasks.concat(state.soapTasksList)),
      }
    case WS_TASK_UPDATE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isLoadingList: false,
      }
    default:
      return state
  }
}

export default tasks
export const getTasks = (state: RootState): TasksState => state.tasks
export const getTasksList = (state: RootState) => getTasks(state).list
export const getTasksMap = (state: RootState) => getTasks(state).map
export const getTask = (id: string | Nil) =>
  createSelector(getTasksMap, (map) => (id ? map[id] : undefined))
export const getTaskWithChildren = (id: string) =>
  createSelector(getTasksMap, (map) => {
    const task = map[id]
    return task ? [task, ...R.props(task?.children || [], map)] : []
  })
export const getMultipleTasks = (ids: string[]) =>
  createSelector(getTasksMap, (map) => R.props(ids, map))
export const getMultipleTasksWithChildren = (ids: string[]) =>
  createSelector(getTasksMap, (map) => {
    const parentTasks = R.props(ids, map)
    const childrenIds = R.flatten(R.pluck('children', parentTasks))
    const childrenTasks = R.props(childrenIds as string[], map)
    return parentTasks.concat(childrenTasks)
  })
export const getTasksIsLoading = (state: RootState) => getTasks(state).isLoading
export const getTasksIsLoadingList = (state: RootState) =>
  getTasks(state).isLoadingList
export const getTasksIsDeleting = (state: RootState) =>
  getTasks(state).isDeleting
export const getTasksIsFetching = (state: RootState) =>
  getTasks(state).isFetching
export const getTasksIsCreating = (state: RootState) =>
  getTasks(state).isCreating
export const getTasksError = (state: RootState) => getTasks(state).error
export const getTasksValidationError = (state: RootState) =>
  getTasks(state).validationError
export const getTasksTotalCount = (state: RootState) =>
  getTasks(state).totalCount
export const getLastCreatedTaskId = (state: RootState) =>
  getTasks(state).lastCreatedTaskId
export const getTasksListFilters = (state: RootState) => getTasks(state).filters
export const getSoapTasksList = (state: RootState) =>
  getTasks(state).soapTasksList
export const getSoapTasksSelectedDate = (state: RootState) =>
  getTasks(state).soapTasksSelectedDate
