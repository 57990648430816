import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, Text, Utils } from '@pbt/pbt-ui-components'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

import UploadButton from '~/components/common/buttons/UploadButton'
import DialogNames from '~/constants/DialogNames'
import { MigrationType, SessionsTypes } from '~/constants/migration'
import { clearExporterUrl, fetchExporterUrl } from '~/store/actions/migration'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getPimsFull } from '~/store/reducers/constants'
import { getExporterUrl } from '~/store/reducers/migration'
import { BaseMigrationProps } from '~/types/entities/migration'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontSize: '1.4rem',
      color: theme.colors.link,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    icon: {
      color: theme.colors.link,
      cursor: 'pointer',
    },
    migrationTextContainer: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  }),
  { name: 'RhapsodyMigration' },
)

const RhapsodyMigration = ({ businessId }: BaseMigrationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const PimsFull = useSelector(getPimsFull)
  const currentMigrationUrl: string = useSelector(getExporterUrl)
  const permissions = useSelector(getCRUDByArea(PermissionArea.IMPORT))

  const [openCoreMigrationDialog] = useDialog(DialogNames.CORE_MIGRATION_DIALOG)

  const AvimarkId = Utils.findConstantIdByName('Avimark', PimsFull)
  const CornerstoneId = Utils.findConstantIdByName('Cornerstone', PimsFull)
  const InventoryId = Utils.findConstantIdByName('Inventory', PimsFull)

  useEffect(() => {
    if (currentMigrationUrl) {
      window.location.href = currentMigrationUrl
      dispatch(clearExporterUrl())
    }
  }, [currentMigrationUrl])

  const handleCoreImport = () =>
    openCoreMigrationDialog({
      businessId,
      sessionsFilterTypes: [SessionsTypes.DEFAULT],
      sessionsExcludePimsIds: [InventoryId],
    })

  return (
    <Grid>
      <Text strong variant="body2">
        {t('Businesses:MIGRATION.DOWNLOAD_APPROPRIATE_MIGRATION_WIZARD')}
      </Text>
      <Grid
        container
        item
        alignItems="center"
        className={classes.migrationTextContainer}
      >
        <DownloadIcon className={classes.icon} />
        <Link
          className={classes.text}
          onClick={() => dispatch(fetchExporterUrl(CornerstoneId, businessId))}
        >
          {t('Businesses:MIGRATION.DOWNLOAD_WIZARD', { name: 'Cornerstone' })}
        </Link>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.migrationTextContainer}
      >
        <DownloadIcon className={classes.icon} />
        <Link
          className={classes.text}
          onClick={() => dispatch(fetchExporterUrl(AvimarkId, businessId))}
        >
          {t('Businesses:MIGRATION.DOWNLOAD_WIZARD', { name: 'Avimark' })}
        </Link>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.migrationTextContainer}
      >
        <DownloadIcon className={classes.icon} />
        <Link
          className={classes.text}
          onClick={() =>
            dispatch(
              fetchExporterUrl(AvimarkId, businessId, MigrationType.FULL_COPY),
            )
          }
        >
          {t('Businesses:MIGRATION.DOWNLOAD_FILES_COPY_WIZARD', {
            name: 'Avimark',
          })}
        </Link>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.migrationTextContainer}
      >
        <DownloadIcon className={classes.icon} />
        <Link
          className={classes.text}
          onClick={() =>
            dispatch(
              fetchExporterUrl(
                CornerstoneId,
                businessId,
                MigrationType.FULL_COPY_CORNERSTONE,
              ),
            )
          }
        >
          {t('Businesses:MIGRATION.DOWNLOAD_FULL_COPY_WIZARD', {
            name: 'Cornerstone',
          })}
        </Link>
      </Grid>
      {permissions.create && (
        <Grid mt={4}>
          <Text strong variant="body2">
            {t('Businesses:MIGRATION.CORE_DATA')}
          </Text>
          <UploadButton
            label={t('Businesses:MIGRATION.ANALYZE_AND_MIGRATE_CORE_DATA')}
            onClick={handleCoreImport}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default RhapsodyMigration
