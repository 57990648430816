import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

export interface WrapUpProps {
  className?: string
  setSelectedTab: (tab: number) => void
}

const WrapUp = ({ className, setSelectedTab }: WrapUpProps) => {
  const { t } = useTranslation('Common')

  return (
    <Grid item className={className}>
      <Text link fontSize="1.4rem" onClick={() => setSelectedTab(1)}>
        {t('Common:VIEW_ADDED_NOTES')}
      </Text>
    </Grid>
  )
}

export default WrapUp
