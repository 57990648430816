import React from 'react'
import { useSelector } from 'react-redux'
import { Constant } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getLabVendors } from '~/store/reducers/constants'

const LabTestsVendorFilter = (props: CheckboxListFilterProps) => {
  const LabVendors: Constant[] = useSelector(getLabVendors)

  return <CheckboxListFilter items={LabVendors} {...props} />
}

export default LabTestsVendorFilter
