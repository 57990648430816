import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import ErrorTypes from '~/constants/apiErrorTypes'
import {
  ConversationCategory,
  ConversationStatus,
} from '~/constants/communications'
import { Conversation, TableFilter } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import {
  detectAPIErrorType,
  getErrorMessage,
  getServerValidationError,
} from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  EMAIL_CHARGE_SHEET,
  EMAIL_CHARGE_SHEET_SUCCESS,
  EMAIL_INVOICE,
  EMAIL_INVOICE_FAILURE,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_INVOICE_V2,
  EMAIL_INVOICE_V2_SUCCESS,
  EMAIL_LAB_RESULT,
  EMAIL_LAB_RESULT_SUCCESS,
  EMAIL_MED_HISTORY,
  EMAIL_MED_HISTORY_SUCCESS,
  EMAIL_PAYMENT,
  EMAIL_PAYMENT_SUCCESS,
  EMAIL_PRESCRIPTION,
  EMAIL_PRESCRIPTION_SUCCESS,
  EMAIL_REPORT_CARD,
  EMAIL_REPORT_CARD_SUCCESS,
  SEND_MEMBERSHIP_HYBRID_INVITE,
  SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS,
} from '../actions/types/communications'
import { DELETE_MESSAGE_SUCCESS } from '../actions/types/conversationMessages'
import {
  CLEAR_CONVERSATION_EMAIL_PREVIEW,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_VALIDATION_FAILURE,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_FAILURE,
  DELETE_CONVERSATION_SUCCESS,
  EDIT_CONVERSATION,
  EDIT_CONVERSATION_FAILURE,
  EDIT_CONVERSATION_SUCCESS,
  FETCH_CLIENT_CONVERSATIONS_LIST,
  FETCH_CLIENT_CONVERSATIONS_LIST_FAILURE,
  FETCH_CLIENT_CONVERSATIONS_LIST_SUCCESS,
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_EMAIL_PREVIEW,
  FETCH_CONVERSATION_EMAIL_PREVIEW_FAILURE,
  FETCH_CONVERSATION_EMAIL_PREVIEW_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  FETCH_CONVERSATION_MESSAGES_LIST,
  FETCH_CONVERSATION_MESSAGES_LIST_FAILURE,
  FETCH_CONVERSATION_MESSAGES_LIST_SUCCESS,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATIONS_LIST,
  FETCH_CONVERSATIONS_LIST_FAILURE,
  FETCH_CONVERSATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_SUCCESS,
  SET_ASSIGNEE_FOR_CONVERSATION,
  SET_CONVERSATIONS_LIST_FILTERS,
  SET_IS_READ_FOR_CONVERSATION,
  TOGGLE_ARCHIVE_FOR_CONVERSATION,
  UPDATE_CONVERSATION_RECIPIENT_CONTEXT,
  UPDATE_CONVERSATIONS,
} from '../actions/types/conversations'
import {
  SEND_DEFAULT_WELCOME_EMAIL,
  SEND_DEFAULT_WELCOME_EMAIL_SUCCESS,
  SEND_WELCOME_EMAIL,
  SEND_WELCOME_EMAIL_SUCCESS,
} from '../actions/types/wellnessPlans'
import {
  EMAIL_APPOINTMENT,
  EMAIL_APPOINTMENT_CONFIRMATION,
  EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS,
  EMAIL_APPOINTMENT_SUCCESS,
  EMAIL_ZOOM_LINK,
  EMAIL_ZOOM_LINK_SUCCESS,
} from '../duck/emailAppointment'
import type { RootState } from '../index'

export type ConversationsState = {
  areMessagesLoading: boolean
  clientId: string | undefined
  createdConversationsIds: string[]
  emailPreview: string | undefined
  error: string | null
  errorType: string | Nil
  filters: Record<string, TableFilter>
  isConversationCreating: boolean
  isConversationDeleting: boolean
  isConversationFetching: boolean
  isConversationListFetching: boolean
  isConversationUpdating: boolean
  isEmailPreviewReceiving: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, Conversation>
  shouldUpdateConversation: boolean
  totalCount: number
  validationError: string | null
}

export const INITIAL_STATE: ConversationsState = {
  list: [],
  map: {},
  isLoading: false,
  isConversationFetching: false,
  isConversationListFetching: false,
  isConversationCreating: false,
  areMessagesLoading: false,
  error: null,
  errorType: null,
  validationError: null,
  createdConversationsIds: [],
  totalCount: Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
  isEmailPreviewReceiving: false,
  isConversationUpdating: false,
  emailPreview: undefined,
  clientId: undefined,
  shouldUpdateConversation: false,
  isConversationDeleting: false,
  filters: {
    category: { value: ConversationCategory.INBOX },
  },
}

const conversations = (
  state: ConversationsState = INITIAL_STATE,
  action: AnyAction,
): ConversationsState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return INITIAL_STATE

    case UPDATE_CONVERSATIONS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.conversations),
      }

    case FETCH_CLIENT_CONVERSATIONS_LIST:
    case FETCH_CONVERSATIONS_LIST:
      return {
        ...state,
        isLoading: true,
        isConversationListFetching: true,
        list: [],
        totalCount: INITIAL_STATE.totalCount,
        clientId: action?.clientId,
        validationError: null,
      }

    case FETCH_CLIENT_CONVERSATIONS_LIST_SUCCESS:
    case FETCH_CONVERSATIONS_LIST_SUCCESS:
      return {
        ...state,
        list: action.list,
        isLoading: false,
        isConversationListFetching: false,
        totalCount: action.totalCount,
      }

    case FETCH_CONVERSATION:
      return {
        ...state,
        isLoading: true,
        isConversationFetching: true,
        validationError: null,
        errorType: null,
      }
    case FETCH_CONVERSATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isConversationFetching: false,
        error:
          action.error?.status === 410 ? null : getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
        errorType: detectAPIErrorType(action.error.responseBody),
      }
    case FETCH_CONVERSATION_SUCCESS:
      return { ...state, isLoading: false, isConversationFetching: false }

    case FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_FAILURE:
    case FETCH_CLIENT_CONVERSATIONS_LIST_FAILURE:
    case FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_FAILURE:
    case FETCH_CONVERSATIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isConversationListFetching: false,
      }

    case FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST:
    case FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST:
      return {
        ...state,
        isLoading: true,
        isConversationListFetching: true,
        clientId: action?.clientId,
      }

    case FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_SUCCESS:
    case FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isConversationListFetching: false,
        isLoading: false,
        totalCount: action.totalCount,
      }

    case EMAIL_ZOOM_LINK:
    case EMAIL_APPOINTMENT_CONFIRMATION:
    case EMAIL_APPOINTMENT:
    case SEND_DEFAULT_WELCOME_EMAIL:
    case SEND_WELCOME_EMAIL:
    case SEND_MEMBERSHIP_HYBRID_INVITE:
    case EMAIL_INVOICE:
    case EMAIL_INVOICE_V2:
    case EMAIL_CHARGE_SHEET:
    case EMAIL_REPORT_CARD:
    case EMAIL_PAYMENT:
    case EMAIL_PRESCRIPTION:
    case EMAIL_LAB_RESULT:
    case EMAIL_MED_HISTORY:
    case CREATE_CONVERSATION:
      return {
        ...state,
        isConversationCreating: true,
        isLoading: true,
        createdConversationsIds: [],
        error: null,
      }

    case EMAIL_ZOOM_LINK_SUCCESS:
    case EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS:
    case EMAIL_APPOINTMENT_SUCCESS:
    case SEND_DEFAULT_WELCOME_EMAIL_SUCCESS:
    case SEND_WELCOME_EMAIL_SUCCESS:
    case SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS:
    case EMAIL_INVOICE_SUCCESS:
    case EMAIL_INVOICE_V2_SUCCESS:
    case EMAIL_CHARGE_SHEET_SUCCESS:
    case EMAIL_REPORT_CARD_SUCCESS:
    case EMAIL_PAYMENT_SUCCESS:
    case EMAIL_PRESCRIPTION_SUCCESS:
    case EMAIL_LAB_RESULT_SUCCESS:
    case EMAIL_MED_HISTORY_SUCCESS:
    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        isConversationCreating: false,
        createdConversationsIds: action.conversationsList,
      }

    case EMAIL_INVOICE_FAILURE:
    case CREATE_CONVERSATION_FAILURE:
    case CREATE_CONVERSATION_VALIDATION_FAILURE:
      return {
        ...state,
        isConversationCreating: false,
        createdConversationsIds: [],
        error: getErrorMessage(action.error),
      }

    case FETCH_CONVERSATION_MESSAGES_LIST:
      return { ...state, areMessagesLoading: true }
    case FETCH_CONVERSATION_MESSAGES_LIST_FAILURE:
      return { ...state, areMessagesLoading: false }
    case FETCH_CONVERSATION_MESSAGES_LIST_SUCCESS: {
      const { conversationId, messages, totalCount } = action
      const conversation = state.map[conversationId]

      return {
        ...state,
        areMessagesLoading: false,
        map: secondLevelMerge(state.map, {
          [conversationId]: {
            ...conversation,
            messages,
            totalCount,
          },
        }),
      }
    }

    case FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_SUCCESS: {
      const { conversationId, messages, totalCount } = action

      return {
        ...state,
        map: {
          ...state.map,
          [conversationId]: {
            ...state.map[conversationId],
            messages: R.uniq([
              ...(state.map[conversationId]?.messages || []),
              ...messages,
            ]),
            totalCount,
          },
        },
      }
    }

    case SET_ASSIGNEE_FOR_CONVERSATION: {
      return {
        ...state,
        map: {
          ...state.map,
          [action.conversationId]: {
            ...state.map[action.conversationId],
            assigneeId: action.assigneeId,
          },
        },
        shouldUpdateConversation: true,
        isConversationUpdating: true,
      }
    }
    case TOGGLE_ARCHIVE_FOR_CONVERSATION: {
      const conversation = state.map[action.conversationId]
      if (conversation.state === ConversationStatus.DELIVERY_FAILED) {
        return { ...state, shouldUpdateConversation: false }
      }

      const status =
        conversation.state === ConversationStatus.ARCHIVED
          ? ConversationStatus.OPEN
          : ConversationStatus.ARCHIVED
      return {
        ...state,
        map: {
          ...state.map,
          [action.conversationId]: {
            ...conversation,
            state: status,
          },
        },
        shouldUpdateConversation: true,
        isConversationUpdating: true,
      }
    }
    case SET_IS_READ_FOR_CONVERSATION: {
      const conversation = state.map[action.conversationId]
      if (
        conversation.state === ConversationStatus.DELIVERY_FAILED ||
        (conversation.state === ConversationStatus.ARCHIVED &&
          action.isRead === true)
      ) {
        return { ...state, shouldUpdateConversation: false }
      }

      const status = action.isRead
        ? ConversationStatus.OPEN
        : ConversationStatus.UNREAD
      return {
        ...state,
        map: {
          ...state.map,
          [action.conversationId]: {
            ...conversation,
            state: status,
          },
        },
        shouldUpdateConversation: true,
        isConversationUpdating: true,
      }
    }
    case EDIT_CONVERSATION: {
      const conversation = state.map[action.conversationId]
      const updatedConversation: Conversation = {
        ...conversation,
        title: action.conversation.title || conversation.title,
        eventId: action.conversation.eventId || conversation.eventId,
      }
      return {
        ...state,
        shouldUpdateConversation: !R.equals(updatedConversation, conversation),
        map: {
          ...state.map,
          [action.conversationId]: updatedConversation,
        },
        isConversationUpdating: true,
      }
    }

    case FETCH_CONVERSATION_EMAIL_PREVIEW:
      return { ...state, isEmailPreviewReceiving: true }
    case FETCH_CONVERSATION_EMAIL_PREVIEW_SUCCESS:
      return {
        ...state,
        isEmailPreviewReceiving: false,
        emailPreview: action.template,
      }
    case FETCH_CONVERSATION_EMAIL_PREVIEW_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isEmailPreviewReceiving: false,
      }
    case CLEAR_CONVERSATION_EMAIL_PREVIEW:
      return { ...state, emailPreview: undefined }

    case SET_CONVERSATIONS_LIST_FILTERS:
      return {
        ...state,
        filters: R.isEmpty(action.filters)
          ? INITIAL_STATE.filters
          : action.filters,
      }

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        map: R.map(
          (conv) => ({
            ...conv,
            messages: R.without([action.messageId], conv.messages || []),
          }),
          state.map,
        ),
      }

    case DELETE_CONVERSATION:
      return { ...state, isConversationDeleting: true }
    case DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        isConversationDeleting: false,
        map: R.omit([action.conversationId], state.map),
      }
    case DELETE_CONVERSATION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isConversationDeleting: false,
      }

    case UPDATE_CONVERSATION_RECIPIENT_CONTEXT: {
      const conversation = state.map[action.conversationId]
      const client =
        action.origin.clientId === undefined
          ? conversation.client
          : action.origin.clientId
      const patient =
        action.origin.patientId === undefined
          ? conversation.patient
          : action.origin.patientId
      return {
        ...state,
        map: {
          ...state.map,
          [action.conversationId]: {
            ...conversation,
            client,
            patient,
          },
        },
        isConversationUpdating: true,
      }
    }
    case EDIT_CONVERSATION_SUCCESS:
      return { ...state, isConversationUpdating: false }
    case EDIT_CONVERSATION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isConversationUpdating: false,
      }

    default:
      return state
  }
}

export default conversations
export const getConversations = (state: RootState): ConversationsState =>
  state.conversations
export const getConversationsMap = (state: RootState) =>
  getConversations(state).map
export const getConversationsError = (state: RootState) =>
  getConversations(state).error
export const getConversationsErrorType = (state: RootState) =>
  getConversations(state).errorType
export const getConversationsIsNotAccessibleFromBusinessErrorType = (
  state: RootState,
) =>
  getConversationsErrorType(state) ===
  ErrorTypes.CONVERSATION_ACCESS_FROM_OUTER_BUSINESS_ERROR_TYPE
export const getConversationsValidationError = (state: RootState) =>
  getConversations(state).validationError
export const getConversationsList = (state: RootState) =>
  getConversations(state).list
export const getConversationsTotalCount = (state: RootState) =>
  getConversations(state).totalCount
export const getConversationsIsLoading = (state: RootState) =>
  getConversations(state).isLoading
export const getConversationsIsCreating = (state: RootState) =>
  getConversations(state).isConversationCreating
export const getConversationsIsUpdating = (state: RootState) =>
  getConversations(state).isConversationUpdating
export const getConversationIsFetching = (state: RootState) =>
  getConversations(state).isConversationFetching
export const getConversationListFetching = (state: RootState) =>
  getConversations(state).isConversationListFetching
export const getConversationDeleting = (state: RootState) =>
  getConversations(state).isConversationDeleting
export const getConversationMessagesLoading = (state: RootState) =>
  getConversations(state).areMessagesLoading
export const getConversationById = (conversationId: string | Nil) =>
  createSelector(getConversationsMap, (map) =>
    conversationId ? map[conversationId] : undefined,
  )
export const getMultipleConversations = (ids: string[]) =>
  createSelector(getConversationsMap, R.props(ids))
export const getCreatedConversationsIds = (state: RootState) =>
  getConversations(state).createdConversationsIds
export const getAreConversationsCreated = (state: RootState) =>
  !R.isEmpty(getCreatedConversationsIds(state))
export const getConversationEmailPreview = (state: RootState) =>
  getConversations(state).emailPreview
export const getConversationEmailPreviewIsReceiving = (state: RootState) =>
  getConversations(state).isEmailPreviewReceiving
export const getConversationsListFilters = (state: RootState) =>
  getConversations(state).filters
export const getConversationsClientId = (state: RootState) =>
  getConversations(state).clientId
export const getConversationShouldUpdate = (state: RootState) =>
  getConversations(state).shouldUpdateConversation
export const getConversationForMessage = (messageId: string) =>
  createSelector(getConversationsMap, (map) =>
    Object.values(map).find(({ messages }) =>
      R.includes(messageId, messages || []),
    ),
  )

export const getConversationsListObjects = createSelector(
  [getConversationsMap, getConversationsList],
  (conversationMap, conversationsList) =>
    conversationsList.map((id) => conversationMap[id]),
)

export const getCreatedConversations = (state: RootState) =>
  getCreatedConversationsIds(state).map(
    (id) => getConversationById(id)(state) as Conversation,
  )
