import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { getReminderGroupName } from '~/components/dashboard/reminders/reminderUtils'
import {
  CUDReminderWSMessage,
  PartialUpdateReminderPayload,
  Reminder,
  ReminderGroup,
  UnsavedReminder,
} from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage, getServerValidationError } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_REMINDER = 'reminders/FETCH_REMINDER'
export const FETCH_REMINDER_SUCCESS = 'reminders/FETCH_REMINDER_SUCCESS'
export const FETCH_REMINDER_FAILURE = 'reminders/FETCH_REMINDERS_FAILURE'

export const FETCH_REMINDERS = 'reminders/FETCH_REMINDERS'
export const FETCH_REMINDERS_SUCCESS = 'reminders/FETCH_REMINDERS_SUCCESS'
export const FETCH_REMINDERS_FAILURE = 'reminders/FETCH_REMINDERS_FAILURE'

export const FETCH_MORE_REMINDERS = 'reminders/FETCH_MORE_REMINDERS'
export const FETCH_MORE_REMINDERS_SUCCESS =
  'reminders/FETCH_MORE_REMINDERS_SUCCESS'
export const FETCH_MORE_REMINDERS_FAILURE =
  'reminders/FETCH_MORE_REMINDERS_FAILURE'

export const CREATE_REMINDER = 'reminders/CREATE_REMINDER'
export const CREATE_REMINDER_SUCCESS = 'reminders/CREATE_REMINDER_SUCCESS'
export const CREATE_REMINDER_FAILURE = 'reminders/CREATE_REMINDER_FAILURE'

export const EDIT_REMINDER = 'reminders/EDIT_REMINDER'
export const EDIT_REMINDER_SUCCESS = 'reminders/EDIT_REMINDER_SUCCESS'
export const EDIT_REMINDER_FAILURE = 'reminders/EDIT_REMINDER_FAILURE'

export const PARTIAL_UPDATE_REMINDER = 'reminders/PARTIAL_UPDATE_REMINDER'
export const PARTIAL_UPDATE_REMINDER_SUCCESS =
  'reminders/PARTIAL_UPDATE_REMINDER_SUCCESS'
export const PARTIAL_UPDATE_REMINDER_FAILURE =
  'reminders/PARTIAL_UPDATE_REMINDER_FAILURE'

export const TOGGLE_REMINDER_STATE = 'reminders/TOGGLE_REMINDER_STATE'

export const DELETE_REMINDER = 'reminders/DELETE_REMINDER'
export const DELETE_REMINDER_SUCCESS = 'reminders/DELETE_REMINDER_SUCCESS'
export const DELETE_REMINDER_FAILURE = 'reminders/DELETE_REMINDER_FAILURE'

export const UPDATE_REMINDERS = 'reminders/UPDATE_REMINDERS'
export const UPDATE_REMINDERS_GROUPS = 'reminders/UPDATE_REMINDERS_GROUPS'

export const CLEAR_REMINDERS_VALIDATION_ERROR =
  'reminders/CLEAR_REMINDERS_VALIDATION_ERROR'

export const APPLY_REMINDER_FILTERS = 'reminders/APPLY_REMINDER_FILTERS'
export const CLEAR_REMINDER_FILTERS = 'reminders/CLEAR_REMINDER_FILTERS'

export const REMOUNT_WEBSOCKET_REMINDERS_WIDGET =
  'reminders/REMOUNT_WEBSOCKET_REMINDERS_WIDGET'
export const CLEAR_REMOUNT_WEBSOCKET_REMINDERS_WIDGET =
  'reminders/CLEAR_REMOUNT_WEBSOCKET_REMINDERS_WIDGET'

export const fetchReminder = (reminderId: string, soapId?: string | Nil) => ({
  type: FETCH_REMINDER,
  reminderId,
  soapId,
})
export const fetchReminderSuccess = () => ({ type: FETCH_REMINDER_SUCCESS })
export const fetchReminderFailure = (error: ApiError) => ({
  type: FETCH_REMINDER_FAILURE,
  error,
})

export const fetchReminders = ({
  patientId,
  from,
  to,
  stateIds,
}: {
  from?: number
  patientId: string
  stateIds?: string[]
  to?: number
}) => ({
  type: FETCH_REMINDERS,
  patientId,
  from,
  to,
  stateIds,
})
export const fetchRemindersSuccess = (
  groupsList: string[],
  totalCount: number,
) => ({
  type: FETCH_REMINDERS_SUCCESS,
  groupsList,
  totalCount,
})
export const fetchRemindersFailure = (error: ApiError) => ({
  type: FETCH_REMINDERS_FAILURE,
  error,
})

export const fetchMoreReminders = ({
  patientId,
  from,
  to,
  stateIds,
}: {
  from?: number
  patientId: string
  stateIds?: string[]
  to?: number
}) => ({
  type: FETCH_MORE_REMINDERS,
  patientId,
  from,
  to,
  stateIds,
})
export const fetchMoreRemindersSuccess = (
  groupsList: string[],
  totalCount: number,
) => ({
  type: FETCH_MORE_REMINDERS_SUCCESS,
  groupsList,
  totalCount,
})
export const fetchMoreRemindersFailure = (error: ApiError) => ({
  type: FETCH_MORE_REMINDERS_FAILURE,
  error,
})

export const createReminder = (reminder: UnsavedReminder, soapId?: string) => ({
  type: CREATE_REMINDER,
  reminder,
  soapId,
})
export const createReminderSuccess = (
  group: ReminderGroup,
  reminderId: string,
) => ({
  type: CREATE_REMINDER_SUCCESS,
  group,
  reminderId,
})
export const createReminderFailure = (error: ApiError) => ({
  type: CREATE_REMINDER_FAILURE,
  error,
})

export const editReminder = (reminder: Reminder) => ({
  type: EDIT_REMINDER,
  reminder,
})
export const editReminderSuccess = (reminderId: string) => ({
  type: EDIT_REMINDER_SUCCESS,
  reminderId,
})
export const editReminderFailure = (error: ApiError, reminder?: Reminder) => ({
  type: EDIT_REMINDER_FAILURE,
  error,
  reminder,
})

export const partialUpdateReminder = (
  id: string,
  input: PartialUpdateReminderPayload,
) => ({
  type: PARTIAL_UPDATE_REMINDER,
  id,
  input,
})
export const partialUpdateReminderSuccess = (
  reminderId: string,
  newData: Reminder,
) => ({
  type: PARTIAL_UPDATE_REMINDER_SUCCESS,
  reminderId,
  newData,
})
export const partialUpdateReminderFailure = (
  error: ApiError,
  reminderId: string,
) => ({
  type: PARTIAL_UPDATE_REMINDER_FAILURE,
  error,
  reminderId,
})

export const toggleReminderState = (reminderId: string) => ({
  type: TOGGLE_REMINDER_STATE,
  reminderId,
})

export const deleteReminder = (reminderId: string) => ({
  type: DELETE_REMINDER,
  reminderId,
})
export const deleteReminderSuccess = (
  reminderId: string,
  reminderGroupKey: string,
) => ({
  type: DELETE_REMINDER_SUCCESS,
  reminderId,
  reminderGroupKey,
})
export const deleteReminderFailure = (error: ApiError, reminderId: string) => ({
  type: DELETE_REMINDER_FAILURE,
  error,
  reminderId,
})

export const updateReminders = (reminders: Record<string, Reminder>) => ({
  type: UPDATE_REMINDERS,
  reminders,
})
export const updateRemindersGroups = (
  remindersGroups: Record<string, ReminderGroup>,
  concatReminders?: boolean,
) => ({
  type: UPDATE_REMINDERS_GROUPS,
  remindersGroups,
  concatReminders,
})

export const clearRemindersValidationError = () => ({
  type: CLEAR_REMINDERS_VALIDATION_ERROR,
})

export const applyReminderFilters = (filters: Record<string, any>) => ({
  type: APPLY_REMINDER_FILTERS,
  filters,
})
export const clearReminderFilters = () => ({ type: CLEAR_REMINDER_FILTERS })

export const remountWebsocketRemindersWidget = (
  body: CUDReminderWSMessage,
) => ({
  type: REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
  body,
})
export const clearRemountWebsocketRemindersWidget = () => ({
  type: CLEAR_REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
})

export type RemindersState = {
  deletingRemindersMap: Record<string, boolean>
  editingRemindersStateMap: Record<string, boolean>
  error: string | null
  filters: Record<string, any>
  groupsList: string[]
  groupsMap: Record<string, ReminderGroup>
  isCreating: boolean
  isDeleting: boolean
  isFetchingItem: boolean
  isFetchingList: boolean
  isLoading: boolean
  remindersMap: Record<string, Reminder>
  remount: boolean
  totalCount: number
  validationError: string | null
}

export const INITIAL_STATE: RemindersState = {
  deletingRemindersMap: {},
  editingRemindersStateMap: {},
  error: null,
  filters: {},
  groupsList: [],
  groupsMap: {},
  isCreating: false,
  isDeleting: false,
  isFetchingItem: false,
  isFetchingList: false,
  isLoading: false,
  remindersMap: {},
  remount: false,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  validationError: null,
}

const mergeGroupsList = (list1: string[], list2: string[]) => {
  const lastItemInFirstList = R.last(list1)
  const firstItemInSecondList = R.head(list2)

  return lastItemInFirstList === firstItemInSecondList
    ? [...list1, ...list2.slice(1)]
    : [...list1, ...list2]
}

const mergeGroupsMap = (
  map1: Record<string, ReminderGroup>,
  map2: Record<string, ReminderGroup>,
  concatReminders = false,
) =>
  R.mergeDeepWithKey(
    (k, l, r) => (k === 'reminders' && concatReminders ? R.concat(l, r) : r),
    map1,
    map2,
  )

export const remindersReducer = (
  state: RemindersState = INITIAL_STATE,
  action: AnyAction,
): RemindersState => {
  switch (action.type) {
    case FETCH_REMINDER:
      return {
        ...state,
        isFetchingItem: true,
        error: null,
      }
    case FETCH_REMINDER_SUCCESS:
      return {
        ...state,
        isFetchingItem: false,
      }
    case FETCH_REMINDER_FAILURE:
      return {
        ...state,
        isFetchingItem: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_REMINDERS:
      return {
        ...state,
        isLoading: true,
        isFetchingList: true,
        error: null,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        groupsList: [],
        groupsMap: {},
      }
    case FETCH_REMINDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetchingList: false,
        groupsList: action.groupsList || [],
        totalCount: action.totalCount,
      }
    case FETCH_REMINDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetchingList: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_REMINDERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_REMINDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupsList: mergeGroupsList(state.groupsList, action.groupsList),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_REMINDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_REMINDER:
      return {
        ...state,
        isLoading: true,
        editingRemindersStateMap: {
          ...state.editingRemindersStateMap,
          [action.reminder.id]: true,
        },
        error: null,
        validationError: null,
      }
    case EDIT_REMINDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editingRemindersStateMap: R.omit(
          [action.reminderId],
          state.editingRemindersStateMap,
        ),
      }
    case EDIT_REMINDER_FAILURE:
      return {
        ...state,
        editingRemindersStateMap: R.omit(
          [action.reminder.id],
          state.editingRemindersStateMap,
        ),
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    case CLEAR_REMINDERS_VALIDATION_ERROR:
      return {
        ...state,
        validationError: null,
      }
    case DELETE_REMINDER:
      return {
        ...state,
        isDeleting: true,
        error: null,
        deletingRemindersMap: {
          ...state.deletingRemindersMap,
          [action.reminderId]: true,
        },
      }
    case DELETE_REMINDER_SUCCESS:
      const { [action.reminderGroupKey]: currentGroup, ...otherGroups } =
        state.groupsMap || {}
      const updatedList = R.without(
        [action.reminderId],
        currentGroup?.reminders || [],
      )
      const isEmptyGroup = updatedList.length === 0
      const updatedCurrentGroup = isEmptyGroup
        ? {}
        : {
            [action.reminderGroupKey]: {
              ...currentGroup,
              reminders: updatedList,
            },
          }
      return {
        ...state,
        isDeleting: false,
        deletingRemindersMap: R.omit(
          [action.reminderId],
          state.deletingRemindersMap,
        ),
        editingRemindersStateMap: R.omit(
          [action.reminderId],
          state.editingRemindersStateMap,
        ),
        groupsMap: {
          ...otherGroups,
          ...updatedCurrentGroup,
        },
        remindersMap: R.omit([action.reminderId], state.remindersMap),
        totalCount: Math.max(state.totalCount - 1, 0),
        groupsList: isEmptyGroup
          ? R.without([action.reminderGroupKey], state.groupsList)
          : state.groupsList,
      }
    case DELETE_REMINDER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deletingRemindersMap: R.omit(
          [action.reminderId],
          state.deletingRemindersMap,
        ),
        error: getErrorMessage(action.error),
      }
    case UPDATE_REMINDERS:
      return {
        ...state,
        remindersMap: secondLevelMerge(state.remindersMap, action.reminders),
      }
    case UPDATE_REMINDERS_GROUPS:
      return {
        ...state,
        groupsMap: mergeGroupsMap(
          state.groupsMap,
          action.remindersGroups,
          action.concatReminders,
        ),
      }
    case CREATE_REMINDER:
      return {
        ...state,
        isCreating: true,
        error: null,
      }
    case CREATE_REMINDER_SUCCESS: {
      const groupName = getReminderGroupName(action.group)
      const includes = state.groupsList.includes(groupName)
      const newGroupName = includes
        ? state.groupsList
        : [groupName, ...state.groupsList].sort()

      return {
        ...state,
        isCreating: false,
        groupsList: newGroupName,
        groupsMap: {
          ...state.groupsMap,
          [groupName]: {
            ...action.group,
            reminders: includes
              ? [...state.groupsMap[groupName].reminders, action.reminderId]
              : [action.reminderId],
          },
        },
        totalCount: state.totalCount + 1,
      }
    }
    case CREATE_REMINDER_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: getErrorMessage(action.error),
      }
    case TOGGLE_REMINDER_STATE:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case PARTIAL_UPDATE_REMINDER:
      return {
        ...state,
        isLoading: true,
        editingRemindersStateMap: {
          ...state.editingRemindersStateMap,
          [action.id]: true,
        },
        error: null,
      }
    case PARTIAL_UPDATE_REMINDER_SUCCESS:
      const filters = state.filters?.stateIds || []
      const hasChangedStateId =
        filters.length > 0 && !filters.includes(action.newData.state?.id)
      return {
        ...state,
        isLoading: false,
        editingRemindersStateMap: R.omit(
          [action.reminderId],
          state.editingRemindersStateMap,
        ),
        remindersMap: secondLevelMerge(state.remindersMap || {}, {
          [action.reminderId]: action.newData,
        }),
        totalCount: hasChangedStateId ? state.totalCount - 1 : state.totalCount,
      }
    case PARTIAL_UPDATE_REMINDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        editingRemindersStateMap: R.omit(
          [action.reminderId],
          state.editingRemindersStateMap,
        ),
        error: getErrorMessage(action.error),
      }
    case APPLY_REMINDER_FILTERS:
      return {
        ...state,
        filters: action.filters,
      }
    case CLEAR_REMINDER_FILTERS:
      return {
        ...state,
        filters: {},
      }
    case REMOUNT_WEBSOCKET_REMINDERS_WIDGET:
      return {
        ...state,
        remount: true,
      }
    case CLEAR_REMOUNT_WEBSOCKET_REMINDERS_WIDGET:
      return {
        ...state,
        remount: false,
      }
    default:
      return state
  }
}

const groupIncludesReminder = R.curry((reminderId, group) =>
  R.includes(reminderId, group?.reminders),
)
export const getReminders = (state: RootState): RemindersState =>
  state.reminders
export const getIsLoading = (state: RootState) => getReminders(state).isLoading
export const getIsFetchingList = (state: RootState) =>
  getReminders(state).isFetchingList
export const getIsFetchingItem = (state: RootState) =>
  getReminders(state).isFetchingItem
export const getIsCreating = (state: RootState) =>
  getReminders(state).isCreating
export const getIsDeleting = (state: RootState) =>
  getReminders(state).isDeleting
export const getReminderFilters = (state: RootState) =>
  getReminders(state).filters
export const getRemountRemindersWidget = (state: RootState) =>
  getReminders(state).remount
export const getDeletingRemindersMap = (state: RootState) =>
  getReminders(state).deletingRemindersMap
export const getIsDeletingReminder = (id: string | Nil) =>
  createSelector(
    getDeletingRemindersMap,
    id ? R.propOr(false, id) : R.always(false),
  )
export const getUpdatingRemindersMap = (state: RootState) =>
  getReminders(state).editingRemindersStateMap
export const getIsUpdatingReminder = (id: string | Nil) =>
  createSelector(
    getUpdatingRemindersMap,
    id ? R.propOr(false, id) : R.always(false),
  )
export const getTotalCount = (state: RootState) =>
  getReminders(state).totalCount
export const getRemindersGroupsList = (state: RootState) =>
  getReminders(state).groupsList
export const getRemindersGroupsMap = (state: RootState) =>
  getReminders(state).groupsMap
export const getRemindersMap = (state: RootState) =>
  getReminders(state).remindersMap
export const getRemindersValidationError = (state: RootState) =>
  getReminders(state).validationError
export const getReminder = (id: string | Nil) =>
  createSelector(getRemindersMap, (map) => (id ? map[id] : undefined))
export const getReminderGroup = (id: string) =>
  createSelector(getRemindersGroupsMap, (map) => R.prop(id, map))
export const getMultipleReminders = (ids: string[]) =>
  createSelector(getRemindersMap, (map) => R.props(ids, map))
export const getMultipleRemindersGroups = (ids: string[]) =>
  createSelector(getRemindersGroupsMap, (map) => R.props(ids, map))
export const findGroupOfReminder = (reminderId: string) =>
  R.pipe(
    getRemindersGroupsMap,
    R.values,
    R.find(groupIncludesReminder(reminderId)),
  )
