import React from 'react'
import { Moment } from 'moment'
import {
  AmountObj,
  BaseUser,
  DocumentFile,
  NamedEntity,
  Nil,
  User,
} from '@pbt/pbt-ui-components'

import {
  ConversationTransport,
  LabTestLogFileGroup,
  RxPrescriptionOrigin,
} from '~/api/graphql/generated/types'
import { TimelineEntryType } from '~/constants/timelineConstants'

import { BusinessAppointmentType } from './businessAppointmentType'
import { AttachmentOrigin, IdentifiedAttachmentOrigin } from './files'
import { Invoice } from './finance'
import { LabTestDashboardOrder } from './labTestsDashboard'
import { DiagnoseHistoryItem, VaccineHistoryItem } from './medicalHistory'
import { ExtendPayment } from './payments'
import { Prescription } from './prescriptions'
import { FindingState, ProblemLogEnumReference, Soap } from './soap'
import {
  EventProduct,
  TimetableEventPersonResponsibility,
  TimetableEventPersonRole,
} from './timetable'

export type VitalTimelineItem = {
  date: string
  entries: string[]
}

export type SurgeryTimelineItem = {
  businessId: string
  description: string
  id: string
  invoiceId: string | null
  soapId: string | null
  vet: BaseUser
}

export type EstimateTimelineItem = {
  amountNoFee: number | AmountObj
  deposits: ExtendPayment[]
  dueToPay: number
  dueToPayNoFee: number
  id: string
  invoiceNo: string
  name: string
  paidDeposit: number
  paidDepositAmount: number
  paidDepositAmountNoFee: number
  requiredDeposit: number
  requiredDepositAmount: number
  requiredDepositAmountNoFee: number
  state: string
  stateId: string
  total: number
  type: TimelineEntryType
}

export type DrugTimelineItem = {
  administrationFrequencyId: string | null
  administrationFrequencyResolved: string | null
  administrationQuantity: string | null
  administrationQuantityCustomUnit: string | null
  administrationQuantityUnitId: string | null
  administrationQuantityUnitResolved: string | null
  administrationRouteId: string | null
  administrationRouteResolved: string | null
  businessId: string
  controlledSubstance: boolean
  date: string
  id: string
  invoiceId: string
  name: string
  notes: string | null
  quantity: number
  quantityStockUnitId: string
  quantityUnitId: string
  quantityUnitResolved: string
  soapId: string
  statusId: string
  subType: string
  type: string
  variationPackageTypeId: string
  variationPerPackageUnitId: string
  vet: {
    active: boolean
    deleted: boolean
    email: string
    firstName: string
    id: string
    invitationStatus: string | null
    lastName: string
    mobilePhone: string
    photo: string
  }
}

export enum DiagnosisType {
  DIAGNOSIS = 'DIAGNOSIS',
  PROBLEM_DIAGNOSIS = 'PROBLEM_DIAGNOSIS',
}

export type DiagnosisTimelineItem = DiagnoseHistoryItem & {
  bodySystemId?: string
  businessId: string
  description: string
  descriptionTranslation?: string
  diagnosisNotes?: string
  enums?: ProblemLogEnumReference[]
  locationId: string
  soapId: string
  status?: string
  type: DiagnosisType
  vet: BaseUser | null
}

export type VaccineTimelineItem = VaccineHistoryItem & {
  administeredBy: string
  amountId: string | null
  businessId: string
  date: string
  description: string
  dueDate: {
    amount: number
    unitId: string
  } | null
  dueDateTime: string | null
  file: DocumentFile | null
  invoiceId: string | null
  lastShotDate: string | null
  lotExpiration: string | null
  lotNumber: string | null
  serialNumber: string | null
  soapId: string | null
  status: string
}

export type ImagingTimelineItemEntry = {
  assignedVet?: string | null
  businessId: string
  imagingOrder: string | null
  modalityId: string | null
  name: string
  notes?: string | null
  orderId: string | null
  procedureId: string
  statusId: string
  vendorId: string
}

export type ImagingTimelineItem = {
  businessId: string | Nil
  entries: ImagingTimelineItemEntry[]
  invoiceId: string
  soapFinalized: boolean
  soapId: string
}

export type NoteTimelineItem = {
  businessId: string
  id: string
  noteTypeId: string
  notes: string
  person: string
  practiceFrom: string
  soapId: string
}

export type TaskTimelineItem = {
  entries: string[]
}

export type QuestionTimelineItemEntry = {
  answerInputTexts: {
    inputTypeId: string
    value: string | Nil
  }[]
  answerText: string
  appointmentId: string
  businessId: string
  date: string
  id: string
}

export type QuestionTimelineItem = {
  date: string
  entries: QuestionTimelineItemEntry[]
  questionText: string
  type: TimelineEntryType
}

export type ProblemDiagnosisLog = {
  bodySystemId: string
  bodySystemLogState: string
  businessId: string
  date: string
  description: string
  descriptionTranslation?: string | Nil
  diagnosisNotes: string | Nil
  enums: ProblemLogEnumReference[]
  id: string
  logType: ProblemDiagnosisLogType
  notes: string | Nil
  problemId: string
  soapId: string
  status: string
}

export enum ProblemDiagnosisLogType {
  PROBLEM_BODY_SYSTEM_LOG = 'PROBLEM_BODY_SYSTEM_LOG',
  PROBLEM_DIAGNOSIS_LOG = 'PROBLEM_DIAGNOSIS_LOG',
  PROBLEM_FINDING_LOG = 'PROBLEM_FINDING_LOG',
}

export type AppointmentTimelineItemEntity = {
  addendums?: string[]
  assignedVet?: User | Nil
  assignedVetAssistant?: User | Nil
  assignedVetTech?: User | Nil
  date?: string
  dischargeNotes?: string | Nil
  findingLogs?: FindingState[] | Nil
  medicalNotes?: string | Nil
  problemFindingLogs: ProblemDiagnosisLog[] | Nil
  products?: EventProduct[]
  soapId?: string | Nil
}

export type AppointmentTimelineItem = {
  appointmentCancellationReason?: string | Nil
  appointmentCancellationReasonId?: string | Nil
  appointmentType: string
  appointmentTypeName: string
  businessAppointmentType?: BusinessAppointmentType | Nil
  businessId: string
  creationDate: string
  date: string
  dialIn?: string | Nil
  entries: AppointmentTimelineItemEntity[]
  id: string
  internalNotes?: string | Nil
  invoiceId: string
  meetingLink?: string | Nil
  meetingNotes?: string | Nil
  modificationDate?: string
  notes?: string | Nil
  personResponsibilities?: TimetableEventPersonResponsibility[]
  personRoles: TimetableEventPersonRole[]
  state: string
  telehealthProviderId?: string | Nil
  type: TimelineEntryType
}

export type AttachmentTimelineItemEntity = AttachmentOrigin & {
  author: User
  businessId: string
  date: string
  id: string
  invoice?: Invoice | Nil
  recordFrom: string | Nil
  soap?: Soap | Nil
  soapFile?: boolean | Nil
  soapId?: string | Nil
}

export type AttachmentTimelineItem = {
  date: string
  entries: AttachmentTimelineItemEntity[]
  type: TimelineEntryType
}

export type GroupedAttachmentTimelineItemEntity = {
  author: User
  businessId: string
  date: string
  description: string | Nil
  files: IdentifiedAttachmentOrigin[] | Nil
  id: string
  invoice?: Invoice | Nil
  labTestLogFile?: boolean | Nil
  labTestLogId?: string | Nil
  recordFrom: string | Nil
  soap?: Soap | Nil
  soapFile?: boolean | Nil
  soapId?: string | Nil
  title: string | Nil
}

export type GroupedAttachmentTimelineItem = {
  date: string
  entries: GroupedAttachmentTimelineItemEntity[]
  type: TimelineEntryType
}

export type ConversationTimelineItem = {
  businessId: string
  date: string
  id: string
  isDraft: boolean
  isRead: boolean
  recentActivity: string
  state?: string
  title: string
  transport: ConversationTransport
  type: TimelineEntryType
}

export type PrescriptionTimelineItem = {
  administer: string
  autoshipFrequency?: number | Nil
  autoshipUnitId?: string | Nil
  businessId: string | Nil
  cancellationReasonDetail?: string | Nil
  controlled: boolean
  date: string
  invoice: Invoice
  invoiceId: string | Nil
  invoiceNotes: string | Nil
  masterName: string
  name: string
  origin?: RxPrescriptionOrigin | Nil
  prescription: Prescription
  quantity: string
  refills?: string | Nil
  signerId?: string
  soap: Soap
  soapId: string
  state: string
  subType: string
  type: TimelineEntryType
  vet: User
}

export type LabTestTimelineItemEntity = NamedEntity & {
  businessId: string
  date: string
  fileGroups: LabTestLogFileGroup[]
  notes?: string | Nil
  stateId: string
  uniqueIdentifier: string
}

export type LabTestTimelineItem = {
  businessId: string | Nil
  date: string
  entries: LabTestTimelineItemEntity[]
  invoiceId?: string | Nil
  order: LabTestDashboardOrder
  soapId: string
  type: TimelineEntryType
  vendorId: string
}

export type ImportedHistoryTimelineItemEntry = {
  businessId: string
  date: string
  details: string
  name: string
}

export type ImportedHistoryTimelineItem = {
  entries: ImportedHistoryTimelineItemEntry[]
}

export type OTCSaleTimelineItem = {
  businessId: string
  chargesEntityType?: string
  createdBy: string | Nil
  creationDate: string
  date: string
  id: string
  invoiceId: string
  modificationDate: string | Nil
  modifiedBy: string | Nil
  personResponsibilities?: TimetableEventPersonResponsibility[]
  personRoles: TimetableEventPersonRole[] | Nil
  products?: EventProduct[]
  type: TimelineEntryType
}

export type TimelineItem = {
  date: string
  entries?:
    | string[]
    | AttachmentTimelineItemEntity[]
    | GroupedAttachmentTimelineItemEntity[]
    | LabTestTimelineItemEntity[]
  id?: string
  subType?: string
  type: TimelineEntryType
}

export type TimelineConfigItem = {
  component: React.JSXElementConstructor<any>
  icon: React.JSXElementConstructor<any>
  important?: boolean
  label: string
  permission?: boolean
  skipInFilters?: boolean
  type: TimelineEntryType
}

export type TimelineTimeframeItem = {
  bold: boolean
  getDateRange: () => (Moment | null)[]
  id: number
  name: string
}
