import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { BaseSearchRequestParams, Price } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchLabTests = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
  labVendorIds: string[],
) =>
  request(
    getAPIPath('admin/services/labTests'),
    { method: 'GET', params: { businessId, from, to, query, labVendorIds } },
    true,
    Schema.labTestsList,
  )

export const fetchLabTest = (businessId: string, labTestId: string) =>
  request(
    getAPIPath(`admin/services/labTests/${labTestId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.labTest,
  )

export const createLabTestPrice = (
  businessId: string,
  labTestId: string,
  price: Price,
) =>
  request(
    getAPIPath(`admin/services/labTests/${labTestId}/prices`),
    { method: 'POST', data: price, params: { businessId } },
    true,
    Schema.labTest,
  )

export const editLabTestPrice = (
  businessId: string,
  labTestId: string | Nil,
  price: Price,
) =>
  request(
    getAPIPath(`admin/services/labTests/${labTestId}/prices/${price.id}`),
    { method: 'PUT', data: price, params: { businessId } },
    true,
    Schema.labTest,
  )

export const deleteLabTestPrice = (
  businessId: string,
  labTestId: string,
  priceId: string,
) =>
  request(
    getAPIPath(`admin/services/labTests/${labTestId}/prices/${priceId}`),
    { method: 'DELETE', params: { businessId } },
    true,
    Schema.labTest,
  )

export const searchLabTests = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('admin/services/labTests/search'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        max,
      },
    },
    true,
  )
