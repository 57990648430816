import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  DateFormat,
  LanguageUtils,
  moment,
  Nil,
  PuiDialog,
  RoleName,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import { ConversationTransport } from '~/api/graphql/generated/types'
import ToInput, {
  ToInputHandle,
} from '~/components/dashboard/communications/common/ToInput'
import ConversationTransportSelect from '~/components/dashboard/communications/new-conversation-dialog/ConversationTransportSelect'
import DialogNames from '~/constants/DialogNames'
import EmailAppointmentFlow from '~/constants/EmailAppointmentFlow'
import {
  clearEmailAppointmentConfig,
  emailAppointmentConfirmation,
  fetchEmailAppointmentConfirmationConfig,
  getEmailAppointmentConfig,
  getEmailAppointmentIsLoading,
  getEmailAppointmentIsSendingEmail,
  preFillEmailAppointmentConfigWithData,
} from '~/store/duck/emailAppointment'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getResponsibilities } from '~/store/reducers/constants'
import { getPatientName } from '~/store/reducers/patients'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser, getUserName } from '~/store/reducers/users'
import { EmailEntityConfig } from '~/types'
import useDialog from '~/utils/useDialog'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflowY: 'visible',
      padding: theme.spacing(3, 12),
    },
    successIcon: {
      color: theme.colors.alertSuccess,
    },
  }),
  { name: 'EmailAppointmentConfirmationDialog' },
)

const ALLOWED_TRANSPORTS = [
  ConversationTransport.Email,
  ConversationTransport.Sms,
  ConversationTransport.Boop,
]

export interface EmailAppointmentConfirmationDialogProps
  extends BasePuiDialogProps {
  appointmentId: string | Nil
}

const EmailAppointmentConfirmationDialog = ({
  appointmentId,
  open,
  onClose,
}: EmailAppointmentConfirmationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'TimeTable'])

  const appointment = useSelector(getTimetableEvent(appointmentId))
  const clientId = appointment?.client
  const client = useSelector(getUser(clientId))
  const patientName = useSelector(getPatientName(appointment?.patient)) || ''
  const roles = useMainStaffRoles()
  const responsibilities = useSelector(getResponsibilities)
  const emailSending = useSelector(getEmailAppointmentIsSendingEmail)
  const isConfigLoading = useSelector(getEmailAppointmentIsLoading)
  const emailConfig = useSelector(getEmailAppointmentConfig)
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )

  const toInputRef = useRef<ToInputHandle>(null)

  const [transport, setTransport] = useState<ConversationTransport>()

  const {
    scheduledStartDatetime,
    personRoles = [],
    personResponsibilities = [],
    businessAppointmentType,
  } = appointment || {}

  const shouldUseResponsibilities =
    personResponsibilities && personResponsibilities.length > 0

  const [doctor, tech] = shouldUseResponsibilities
    ? [ResponsibilityName.Veterinarian, ResponsibilityName.VetTech]
        .map(
          (responsibilityName) =>
            Utils.findConstantByName(responsibilityName, responsibilities) ||
            {},
        )
        .map(
          (responsibility) =>
            personResponsibilities?.find(
              (personResponsibility) =>
                personResponsibility.responsibilityId === responsibility.id,
            )?.personId,
        )
    : [RoleName.Veterinarian, RoleName.VetTech]
        .map((roleName) => Utils.findConstantByName(roleName, roles) || {})
        .map(
          (role) =>
            personRoles?.find((personRole) => personRole.roleId === role.id)
              ?.personId,
        )

  const withPerson = doctor || tech
  const appointmentStart = moment(scheduledStartDatetime)

  const personName = useSelector(getUserName(withPerson))

  const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    businessAppointmentType,
  )

  const newAppointmentInfoLines = [
    `${patientName} ${client?.lastName || ''}`,
    appointmentTypeDisplayName,
    appointmentStart.format(
      DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_AT_FULL_TIME_WITH_MERIDIAN,
    ),
    withPerson ? `${t('Common:WITH').toLowerCase()} ${personName}` : undefined,
  ].filter(Boolean)

  const {
    fields: { to },
    validate,
    reset,
  } = useNewConversationValidationForm(
    { transport, client },
    {},
    { fieldsToValidate: ['to'] },
  )

  const [openEmailAppointmentDialog] = useDialog(DialogNames.EMAIL_APPOINTMENT)

  const isSendDisabled = isConfigLoading || emailSending || !to.value
  const showSkipConfirmation = !to.value && !isConfigLoading && emailConfig

  const handleClose = (clearConfig = true) => {
    if (clearConfig) {
      dispatch(clearEmailAppointmentConfig())
    }
    reset()
    if (onClose) {
      onClose()
    }
  }

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getEmailAppointmentIsSendingEmail,
    onConversationCreationSuccess: handleClose,
    createdInfoDialogProps: {
      titleMessageName: t('TimeTable:APPOINTMENT_CONFIRMATION_MESSAGE'),
    },
  })

  const sendAutoConfirmationEmail = () => {
    if (validate()) {
      const config = {
        ...emailConfig,
        recipients: toInputRef.current?.formRecipients(),
        transport,
      } as EmailEntityConfig
      displayConversationCreationResult()
      if (appointmentId) {
        dispatch(emailAppointmentConfirmation(appointmentId, config))
      }
    }
  }

  const sendCustomConfirmationEmail = () => {
    dispatch(
      preFillEmailAppointmentConfigWithData({ mailTo: to.value, transport }),
    )
    openEmailAppointmentDialog({
      appointmentId,
      flow: EmailAppointmentFlow.CONFIRMATION,
    })
    handleClose(false)
  }

  useEffect(() => {
    if (appointmentId) {
      dispatch(
        fetchEmailAppointmentConfirmationConfig(appointmentId, transport),
      )
    }
    reset()
  }, [appointmentId])

  useEffect(() => {
    if (appointmentId) {
      dispatch(
        fetchEmailAppointmentConfirmationConfig(appointmentId, transport),
      )
    }
  }, [transport])

  const sendCustomConfirmationAllowed =
    !isCurrentBusinessOmniChannel ||
    (transport !== ConversationTransport.Email &&
      transport !== ConversationTransport.Sms)

  return (
    <PuiDialog
      disableEnforceFocus
      aria-labelledby="email-appointment-confirmation-dialog"
      classes={{
        paper: classes.paper,
      }}
      disableClose={emailSending}
      open={open}
      onClose={handleClose}
    >
      <Grid container item>
        <Grid container alignItems="center" direction="column" mb={2}>
          <SuccessAlert className={classes.successIcon} />
          <Text mt={2} variant="body2">
            {t('Common:APPOINTMENT_IS_BOOKED')}:
          </Text>
          {newAppointmentInfoLines.map((infoRow) => (
            <Text key={infoRow} variant="body2">
              {infoRow}
            </Text>
          ))}
          <ConversationTransportSelect
            allowedTransports={ALLOWED_TRANSPORTS}
            configTransport={emailConfig?.transport}
            contactSlot={{ clientId }}
            disabled={isConfigLoading}
            transport={transport}
            onChange={setTransport}
          />
          <ToInput
            clientId={clientId}
            patientId={appointment?.patient}
            ref={toInputRef}
            to={to}
            transport={transport}
          />
        </Grid>

        <Grid
          container
          columnSpacing={2}
          justifyContent="center"
          rowSpacing={2}
        >
          <Grid item>
            <ButtonWithLoader
              disabled={isSendDisabled}
              loading={emailSending}
              onClick={sendAutoConfirmationEmail}
            >
              {t('Common:SEND_AUTO_CONFIRMATION')}
            </ButtonWithLoader>
          </Grid>
          {sendCustomConfirmationAllowed && (
            <Grid item>
              <ButtonWithLoader
                disabled={isSendDisabled}
                onClick={sendCustomConfirmationEmail}
              >
                {t('Common:SEND_CUSTOM_CONFIRMATION')}
              </ButtonWithLoader>
            </Grid>
          )}
          {showSkipConfirmation && (
            <Grid item>
              <ButtonWithLoader
                color="secondary"
                onClick={() => handleClose(true)}
              >
                {t('Common:SKIP_CONFIRMATION')}
              </ButtonWithLoader>
            </Grid>
          )}
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default EmailAppointmentConfirmationDialog
