import React, { ChangeEvent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, FormControl, Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  PuiSelect,
  PuiTextField,
  useFields,
  ValidateOptions,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getAntechLabLocations } from '~/store/reducers/constants'
import { AntechLabCredentials, LabProps } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../../practices'
import VendorFormContainer from '../VendorFormContainer'

const useStyles = makeStyles(
  (theme) => ({
    field: {
      ...theme.constants.resetPasswordStyle,
    },
  }),
  { name: 'AntechLab' },
)

const AntechLab = ({
  business,
  name,
  vendorId,
  initiallyActive,
  credentials,
  status,
  isTesting,
  onTest,
  onToggle,
}: LabProps<AntechLabCredentials>) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const locations = useSelector(getAntechLabLocations)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'active',
        label: name,
        type: 'toggle',
        initialValue: initiallyActive || false,
      },
      {
        name: 'username',
        label: t('Common:USERNAME'),
        validators: ['required'],
        initialValue: credentials.username || '',
      },
      {
        name: 'password',
        label: t('Common:PASSWORD'),
        initialValue: credentials.password || '',
      },
      {
        name: 'clinicId',
        label: t('Common:CLINIC_ID'),
        validators: ['required'],
        initialValue: credentials.clinicId || '',
      },
      {
        name: 'labLocationId',
        validators: ['required'],
        type: 'select',
        initialValue: credentials.labLocationId || '',
      },
    ],
    false,
  )

  const { active, username, password, clinicId, labLocationId } = fields

  usePracticeFieldsSection({
    business,
    fields,
    sectionName:
      `${PracticeDetailsPanels.LAB_INTEGRATIONS}_ANTECH` as PracticeDetailsPanels,
    parentSectionName: PracticeDetailsPanels.LAB_INTEGRATIONS,
    validate: (options?: ValidateOptions | undefined): boolean => {
      if (active.value) {
        return validate(options)
      }
      return true
    },
    reset,
    resetDependencies: [initiallyActive, business],
  })

  return (
    <>
      <PuiSwitch
        field={{
          ...active,
          set: (event: ChangeEvent<HTMLInputElement>) =>
            onToggle(event.target?.checked, () => active.set(event)),
        }}
        label={active.label}
      />
      {active?.value && (
        <>
          <Grid container direction="column" pb={3} pl={6}>
            <VendorFormContainer
              isTesting={isTesting}
              status={status}
              onTest={() =>
                onTest({
                  active: active.value,
                  password: password.value,
                  username: username.value,
                  clinicId: clinicId.value,
                  labLocationId: labLocationId.value,
                  vendorId,
                })
              }
            >
              <Grid item md={6} px={1.5} py={1}>
                <PuiTextField
                  autoComplete="off"
                  field={username}
                  inputProps={{ maxLength: 100 }}
                  label={username.label}
                />
              </Grid>
              <Grid item md={6} px={1.5} py={1}>
                <PuiTextField
                  shrink
                  autoComplete="off"
                  field={password}
                  inputProps={{ className: classes.field, maxLength: 100 }}
                  label={password.label}
                  placeholder={
                    active
                      ? `<${t('Common:USE_STORED_PASSWORD').toLowerCase()}>`
                      : '∗∗∗∗∗∗∗∗∗∗∗∗∗'
                  }
                  type="password"
                />
              </Grid>
              <Grid item md={6} px={1.5} py={1}>
                <PuiTextField
                  field={clinicId}
                  inputProps={{ maxLength: 100 }}
                  label={clinicId.label}
                />
              </Grid>
              <Grid container item alignItems="flex-end" md={6} px={1.5} py={1}>
                <FormControl fullWidth margin="normal">
                  <PuiSelect
                    renderEmpty
                    field={labLocationId}
                    input={<Input id="locations-select" />}
                    items={locations}
                    placeholder={t('Common:CHOOSE_LAB_LOCATION')}
                  />
                </FormControl>
              </Grid>
            </VendorFormContainer>
          </Grid>
          <Divider />
        </>
      )}
    </>
  )
}

export default memo(AntechLab, (prevProps, nextProps) =>
  R.equals(prevProps, nextProps),
)
