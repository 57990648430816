import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { ApiError, Nil, Utils } from '@pbt/pbt-ui-components'

import {
  ChewyCatalogItem,
  DrugCatalogMappingItem,
  MutationApproveReactiveRxArgs,
  MutationDeclineReactiveRxArgs,
  MutationDeleteActiveRxArgs,
  MutationLinkReactiveRxArgs,
  MutationSaveReactiveRxArgs,
  MutationSubmitAllActiveRxForChargeSheetArgs,
  MutationSubmitAllActiveRxForSoapArgs,
  MutationUndeclineActiveRxArgs,
  QueryActiveRxWorkflowAllowanceArgs,
  QueryAllPendingActiveRxForSoapArgs,
  QueryGetAllActiveRxForSoapArgs,
  QueryGetAllPendingActiveRxForChargeSheetArgs,
  QueryReactiveRxUnlinkedClientDetailsArgs,
  RxUnlinkedClientDetails,
} from '~/api/graphql/generated/types'
import { PrescriptionType } from '~/constants/prescription'
import type { RootState } from '~/store'
import { PrescriptionV2 } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'
import { getIsCreatedPrescriptionChewyActiveRx } from '~/utils/prescription'

export interface PrescriptionState {
  chargeSheetPendingChewyActiveRxList: PrescriptionV2[]
  chewyItems?: ChewyCatalogItem[]
  error: string | null
  globalInventoryMapping?: Partial<DrugCatalogMappingItem>
  isActiveRxAllowanceLoading: boolean
  isAddingPatientToRx: boolean
  isFetchingAllActiveRxForSoap: boolean
  isFetchingAllPendingActiveRxForChargeSheet: boolean
  isFetchingAllPendingActiveRxForSoap: boolean
  isFetchingChewyItems: boolean
  isLoading: boolean
  isReactiveRxUnlinkedDetailsLoading: boolean
  isSubmittingAllActiveRx: boolean
  isUpdating: boolean
  linkedToChewy?: {
    business: boolean
    client: boolean
    patient: boolean
  }
  map: Record<string, PrescriptionV2>
  reactiveRxUnlinkedDetailsMap: Record<string, RxUnlinkedClientDetails>
  soapPendingActiveRx: PrescriptionV2[]
  soapPendingChewyActiveRxList: PrescriptionV2[]
  submittingAllActiveRxError: string | null
  updatingError: string | null
}

export const initialState: PrescriptionState = {
  chewyItems: undefined,
  globalInventoryMapping: undefined,
  error: null,
  isActiveRxAllowanceLoading: false,
  isAddingPatientToRx: false,
  isFetchingAllActiveRxForSoap: false,
  isFetchingAllPendingActiveRxForChargeSheet: false,
  isFetchingAllPendingActiveRxForSoap: false,
  isFetchingChewyItems: false,
  isLoading: false,
  isReactiveRxUnlinkedDetailsLoading: false,
  isSubmittingAllActiveRx: false,
  isUpdating: false,
  linkedToChewy: undefined,
  map: {},
  chargeSheetPendingChewyActiveRxList: [],
  reactiveRxUnlinkedDetailsMap: {},
  soapPendingActiveRx: [],
  soapPendingChewyActiveRxList: [],
  submittingAllActiveRxError: null,
  updatingError: null,
}

const prescriptionSlice = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {
    updatePrescriptions(
      state,
      action: PayloadAction<Record<string, PrescriptionV2>>,
    ) {
      const chewyActiveRxPrescriptions = R.values(
        R.filter(
          (item) =>
            getIsCreatedPrescriptionChewyActiveRx(
              item.type as unknown as PrescriptionType,
              item.origin,
            ),
          action.payload,
        ),
      )

      state.map = secondLevelMerge(state.map, action.payload)
      state.soapPendingChewyActiveRxList = Utils.updateByIds<any>(
        chewyActiveRxPrescriptions,
        state.soapPendingChewyActiveRxList,
      )
      state.chargeSheetPendingChewyActiveRxList = Utils.updateByIds<any>(
        chewyActiveRxPrescriptions,
        state.chargeSheetPendingChewyActiveRxList,
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchPrescription(state, action: PayloadAction<string>) {
      state.isLoading = true
      state.error = null
    },
    fetchPrescriptionSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    fetchPrescriptionFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveReactiveRx(state, action: PayloadAction<MutationSaveReactiveRxArgs>) {
      state.isUpdating = true
      state.error = null
    },
    saveReactiveRxSuccess(state) {
      state.isUpdating = false
      state.error = null
    },
    saveReactiveRxFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isUpdating = false
      state.error = getErrorMessage(action.payload.error)
    },

    approveReactiveRx(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationApproveReactiveRxArgs>,
    ) {
      state.isUpdating = true
      state.error = null
    },
    approveReactiveRxSuccess(state) {
      state.isUpdating = false
      state.error = null
    },
    approveReactiveRxFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isUpdating = false
      state.error = getErrorMessage(action.payload.error)
    },

    declineActiveRx(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUndeclineActiveRxArgs>,
    ) {
      state.isUpdating = true
      state.error = null
    },
    declineActiveRxSuccess(state) {
      state.isUpdating = false
    },
    declineActiveRxFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isUpdating = false
      state.error = getErrorMessage(action.payload.error)
    },

    declineReactiveRx(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationDeclineReactiveRxArgs>,
    ) {
      state.isUpdating = true
      state.error = null
    },
    declineReactiveRxSuccess(state) {
      state.isUpdating = false
      state.error = null
    },
    declineReactiveRxFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isUpdating = false
      state.error = getErrorMessage(action.payload.error)
    },

    deleteActiveRx(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationDeleteActiveRxArgs>,
    ) {
      state.isUpdating = true
      state.error = null
    },
    deleteActiveRxSuccess(
      state,
      action: PayloadAction<{
        id: string
      }>,
    ) {
      state.isUpdating = false
      state.map = R.omit([action.payload.id], state.map)
    },
    deleteActiveRxFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isUpdating = false
      state.error = getErrorMessage(action.payload.error)
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkReactiveRx(state, action: PayloadAction<MutationLinkReactiveRxArgs>) {
      state.isAddingPatientToRx = true
      state.error = null
    },
    linkReactiveRxSuccess(state) {
      state.isAddingPatientToRx = false
      state.error = null
    },
    linkReactiveRxFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isAddingPatientToRx = false
      state.error = getErrorMessage(action.payload.error)
    },

    reactiveRxUnlinkedClientDetails(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryReactiveRxUnlinkedClientDetailsArgs>,
    ) {
      state.isReactiveRxUnlinkedDetailsLoading = true
      state.error = null
    },
    reactiveRxUnlinkedClientDetailsSuccess(
      state,
      action: PayloadAction<{
        prescriptionId: string
        unlinkedDetails: RxUnlinkedClientDetails
      }>,
    ) {
      state.isReactiveRxUnlinkedDetailsLoading = false
      state.error = null
      state.reactiveRxUnlinkedDetailsMap[action.payload.prescriptionId] =
        action.payload.unlinkedDetails
    },
    reactiveRxUnlinkedClientDetailsFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isReactiveRxUnlinkedDetailsLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchActiveRxWorkflowAllowance(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryActiveRxWorkflowAllowanceArgs>,
    ) {
      state.isActiveRxAllowanceLoading = true
      state.error = null
    },
    fetchActiveRxWorkflowAllowanceSuccess(
      state,
      action: PayloadAction<{
        business: boolean
        client: boolean
        patient: boolean
      }>,
    ) {
      state.isActiveRxAllowanceLoading = false
      state.linkedToChewy = {
        business: action.payload.business,
        client: action.payload.client,
        patient: action.payload.patient,
      }
    },
    fetchActiveRxWorkflowAllowanceFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isActiveRxAllowanceLoading = false
      state.error = getErrorMessage(action.payload.error)
    },

    fetchChewyItemsByGlobalItemVariationId(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<string>,
    ) {
      state.error = null
      state.isFetchingChewyItems = true
      state.chewyItems = undefined
      state.globalInventoryMapping = undefined
    },
    fetchChewyItemsByGlobalItemVariationIdSuccess(
      state,
      action: PayloadAction<{
        chewyItems: ChewyCatalogItem[]
        globalInventoryMapping: Partial<DrugCatalogMappingItem>
      }>,
    ) {
      state.isFetchingChewyItems = false
      state.chewyItems = action.payload.chewyItems
      state.globalInventoryMapping = action.payload.globalInventoryMapping
    },
    fetchChewyItemsByGlobalItemVariationIdFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isFetchingChewyItems = false
      state.error = getErrorMessage(action.payload.error)
    },

    clearChewyItems(state) {
      state.chewyItems = undefined
      state.globalInventoryMapping = undefined
    },
    refetchAllActiveRxForSoap() {},
    fetchAllActiveRxForSoap(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryGetAllActiveRxForSoapArgs>,
    ) {
      state.soapPendingChewyActiveRxList = []
      state.isFetchingAllActiveRxForSoap = true
      state.error = null
    },
    fetchAllActiveRxForSoapSuccess(
      state,
      action: PayloadAction<{
        list: PrescriptionV2[]
      }>,
    ) {
      state.isFetchingAllActiveRxForSoap = false
      state.soapPendingChewyActiveRxList = action.payload.list
    },
    fetchAllActiveRxForSoapFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isFetchingAllActiveRxForSoap = false
      state.error = getErrorMessage(action.payload.error)
    },

    addActiveRxListItem(
      state,
      action: PayloadAction<{
        prescription: PrescriptionV2
      }>,
    ) {
      state.soapPendingChewyActiveRxList = [
        ...state.soapPendingChewyActiveRxList,
        action.payload.prescription,
      ]

      state.chargeSheetPendingChewyActiveRxList = [
        ...state.chargeSheetPendingChewyActiveRxList,
        action.payload.prescription,
      ]
    },

    clearAllActiveRx(state) {
      state.soapPendingChewyActiveRxList = []
      state.chargeSheetPendingChewyActiveRxList = []
    },

    removeActiveRxListItem(
      state,
      action: PayloadAction<{
        id: string
      }>,
    ) {
      state.soapPendingChewyActiveRxList = R.reject(
        R.propEq('id', action.payload.id),
        state.soapPendingChewyActiveRxList,
      )
      state.chargeSheetPendingChewyActiveRxList = R.reject(
        R.propEq('id', action.payload.id),
        state.chargeSheetPendingChewyActiveRxList,
      )
    },

    submitAllActiveRxForSoap(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationSubmitAllActiveRxForSoapArgs>,
    ) {
      state.isSubmittingAllActiveRx = true
      state.submittingAllActiveRxError = null
    },
    submitAllActiveRxForSoapSuccess(
      state,
      // action: PayloadAction,
    ) {
      state.isSubmittingAllActiveRx = false
    },
    submitAllActiveRxForSoapFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isSubmittingAllActiveRx = false
      state.submittingAllActiveRxError = getErrorMessage(action.payload.error)
    },

    submitAllActiveRxForChargeSheet(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationSubmitAllActiveRxForChargeSheetArgs>,
    ) {
      state.isSubmittingAllActiveRx = true
      state.submittingAllActiveRxError = null
    },
    submitAllActiveRxForChargeSheetSuccess(
      state,
      // action: PayloadAction,
    ) {
      state.isSubmittingAllActiveRx = false
    },
    submitAllActiveRxForChargeSheetFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isSubmittingAllActiveRx = false
      state.submittingAllActiveRxError = getErrorMessage(action.payload.error)
    },

    fetchAllPendingActiveRxForChargeSheet(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryGetAllPendingActiveRxForChargeSheetArgs>,
    ) {
      state.isFetchingAllPendingActiveRxForChargeSheet = true
      state.error = null
      state.chargeSheetPendingChewyActiveRxList = []
    },
    fetchAllPendingActiveRxForChargeSheetFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isFetchingAllPendingActiveRxForChargeSheet = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchAllPendingActiveRxForChargeSheetSuccess(
      state,
      action: PayloadAction<{
        pendingOrders: PrescriptionV2[]
      }>,
    ) {
      state.isFetchingAllPendingActiveRxForChargeSheet = false
      state.chargeSheetPendingChewyActiveRxList = action.payload.pendingOrders
    },

    fetchAllPendingActiveRxForSoap(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryAllPendingActiveRxForSoapArgs>,
    ) {
      state.isFetchingAllPendingActiveRxForSoap = true
      state.error = null
      state.soapPendingActiveRx = []
    },
    fetchAllPendingActiveRxForSoapFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isFetchingAllPendingActiveRxForSoap = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchAllPendingActiveRxForSoapSuccess(
      state,
      action: PayloadAction<{
        pendingActiveRx: PrescriptionV2[]
      }>,
    ) {
      state.isFetchingAllPendingActiveRxForSoap = false
      state.soapPendingActiveRx = action.payload.pendingActiveRx
    },
  },
})

export const {
  addActiveRxListItem,
  approveReactiveRx,
  approveReactiveRxFailure,
  approveReactiveRxSuccess,
  clearAllActiveRx,
  clearChewyItems,
  declineActiveRx,
  declineActiveRxFailure,
  declineActiveRxSuccess,
  declineReactiveRx,
  declineReactiveRxFailure,
  declineReactiveRxSuccess,
  deleteActiveRx,
  deleteActiveRxFailure,
  deleteActiveRxSuccess,
  fetchActiveRxWorkflowAllowance,
  fetchActiveRxWorkflowAllowanceFailure,
  fetchActiveRxWorkflowAllowanceSuccess,
  fetchAllActiveRxForSoap,
  fetchAllActiveRxForSoapFailure,
  fetchAllActiveRxForSoapSuccess,
  fetchAllPendingActiveRxForChargeSheet,
  fetchAllPendingActiveRxForChargeSheetFailure,
  fetchAllPendingActiveRxForChargeSheetSuccess,
  fetchChewyItemsByGlobalItemVariationId,
  fetchChewyItemsByGlobalItemVariationIdFailure,
  fetchChewyItemsByGlobalItemVariationIdSuccess,
  fetchPrescription,
  fetchPrescriptionFailure,
  fetchPrescriptionSuccess,
  linkReactiveRx,
  linkReactiveRxFailure,
  linkReactiveRxSuccess,
  reactiveRxUnlinkedClientDetails,
  reactiveRxUnlinkedClientDetailsFailure,
  reactiveRxUnlinkedClientDetailsSuccess,
  refetchAllActiveRxForSoap,
  removeActiveRxListItem,
  saveReactiveRx,
  saveReactiveRxFailure,
  saveReactiveRxSuccess,
  submitAllActiveRxForChargeSheet,
  submitAllActiveRxForChargeSheetFailure,
  submitAllActiveRxForChargeSheetSuccess,
  submitAllActiveRxForSoap,
  submitAllActiveRxForSoapFailure,
  submitAllActiveRxForSoapSuccess,
  updatePrescriptions,
  fetchAllPendingActiveRxForSoap,
  fetchAllPendingActiveRxForSoapSuccess,
  fetchAllPendingActiveRxForSoapFailure,
} = prescriptionSlice.actions

export default prescriptionSlice.reducer

export const getPrescriptionsState = (state: RootState): PrescriptionState =>
  state.prescriptions
export const getPrescriptionIsLoading = (state: RootState) =>
  getPrescriptionsState(state).isLoading
export const getPrescriptionIsUpdating = (state: RootState) =>
  getPrescriptionsState(state).isUpdating
export const getPrescriptionIsAddingPatientToRx = (state: RootState) =>
  getPrescriptionsState(state).isAddingPatientToRx
export const getPrescriptionMap = (state: RootState) =>
  getPrescriptionsState(state).map
export const getSoapPendingChewyActiveRxList = (state: RootState) =>
  getPrescriptionsState(state).soapPendingChewyActiveRxList
export const getPrescriptionError = (state: RootState) =>
  getPrescriptionsState(state).error
export const getPrescriptionById = (id: string | Nil) =>
  createSelector(getPrescriptionMap, (map) => (id ? map[id] : undefined))
export const getReactiveRxUnlinkedDetailsMap = (state: RootState) =>
  getPrescriptionsState(state).reactiveRxUnlinkedDetailsMap
export const getReactiveRxUnlinkedDetailsByPrescriptionId = (
  prescriptionId: string | Nil,
) =>
  createSelector(getReactiveRxUnlinkedDetailsMap, (map) =>
    prescriptionId ? map[prescriptionId] : undefined,
  )
export const getIsReactiveRxUnlinkedDetailsLoading = (state: RootState) =>
  getPrescriptionsState(state).isReactiveRxUnlinkedDetailsLoading
export const getLinkedToChewy = (state: RootState) =>
  getPrescriptionsState(state).linkedToChewy
export const getIsActiveRxWorkflowAllowed = createSelector(
  getPrescriptionsState,
  (state) =>
    state.linkedToChewy && R.all(R.equals(true))(R.values(state.linkedToChewy)),
)
export const getIsActiveRxWorkflowAllowanceLoading = (state: RootState) =>
  getPrescriptionsState(state).isActiveRxAllowanceLoading
export const getPrescriptionChewyItems = (state: RootState) =>
  getPrescriptionsState(state).chewyItems
export const getGlobalInventoryMapping = (state: RootState) =>
  getPrescriptionsState(state).globalInventoryMapping
export const getIsFetchingChewyItems = (state: RootState) =>
  getPrescriptionsState(state).isFetchingChewyItems
export const getIsFetchingAllActiveRxForSoap = (state: RootState) =>
  getPrescriptionsState(state).isFetchingAllActiveRxForSoap
export const getIsFetchingAllPendingActiveRxForChargeSheet = (
  state: RootState,
) => getPrescriptionsState(state).isFetchingAllPendingActiveRxForChargeSheet
export const getChargeSheetPendingChewyActiveRxList = (state: RootState) =>
  getPrescriptionsState(state).chargeSheetPendingChewyActiveRxList

export const getSubmittingAllActiveRxError = (state: RootState) =>
  getPrescriptionsState(state).submittingAllActiveRxError
export const getIsSubmittingAllActiveRx = (state: RootState) =>
  getPrescriptionsState(state).isSubmittingAllActiveRx

export const getIsFetchingAllPendingActiveRxForSoap = (state: RootState) =>
  getPrescriptionsState(state).isFetchingAllPendingActiveRxForSoap
export const getSoapPendingActiveRx = (state: RootState) =>
  getPrescriptionsState(state).soapPendingActiveRx
export const getShowSoapPendingActiveRx = createSelector(
  getSoapPendingActiveRx,
  (soapPendingActiveRx) => soapPendingActiveRx.length > 0,
)
