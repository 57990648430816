import * as R from 'ramda'
import { all, put, select, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import FeatureToggle from '~/constants/featureToggle'
import { VariationCalculatedCost } from '~/types'
import { arrayToMap } from '~/utils'

import { fetchInventory } from '../actions/inventories'
import {
  CREATE_VARIATION,
  CREATE_VARIATIONS,
  DELETE_VARIATION,
  FETCH_VARIATION_CALCULATED_COST,
  FETCH_VARIATION_PART_NUMBER_DETAILS,
  PARTIAL_UPDATE_VARIATION,
  UPDATE_VARIATION,
} from '../actions/types/variations'
import {
  createVariation,
  createVariationFailure,
  createVariations,
  createVariationsFailure,
  createVariationsSuccess,
  createVariationSuccess,
  deleteVariation,
  deleteVariationFailure,
  deleteVariationSuccess,
  fetchVariationCalculatedCost,
  fetchVariationCalculatedCostFailure,
  fetchVariationCalculatedCostSuccess,
  fetchVariationPartNumberDetails,
  fetchVariationPartNumberDetailsFailure,
  fetchVariationPartNumberDetailsSuccess,
  partialUpdateVariation,
  updateVariation,
  updateVariationFailure,
  updateVariationSuccess,
} from '../actions/variations'
import { getFeatureToggle } from '../reducers/constants'
import requestAPI from './utils/requestAPI'

export function* createVariationSaga({
  inventoryId,
  variation,
}: ReturnType<typeof createVariation>) {
  try {
    const {
      result: variationId,
      entities: { variations },
    } = yield* requestAPI(API.createVariation, inventoryId, variation)
    yield put(createVariationSuccess(inventoryId, variations[variationId]))
  } catch (error) {
    yield put(createVariationFailure(error as ApiError))
  }
}

export function* createVariationsSaga({
  inventoryId,
  variations: variationsToCreate,
}: ReturnType<typeof createVariations>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const {
      entities: { variations },
    } = yield* requestAPI(
      isFoodCatalogEnabled ? API.createVariationsV2 : API.createVariations,
      inventoryId,
      variationsToCreate,
    )
    yield put(createVariationsSuccess(inventoryId, variations))
    yield put(fetchInventory(inventoryId))
  } catch (error) {
    yield put(createVariationsFailure(error as ApiError))
  }
}

export function* updateVariationSaga({
  inventoryId,
  variation,
}: ReturnType<typeof updateVariation>) {
  try {
    const {
      result: variationId,
      entities: { variations },
    } = yield* requestAPI(API.updateVariation, inventoryId, variation)
    yield put(updateVariationSuccess(inventoryId, variations[variationId]))
    yield put(fetchInventory(inventoryId))
  } catch (error) {
    yield put(updateVariationFailure(error as ApiError))
  }
}

export function* partialUpdateVariationSaga({
  inventoryId,
  variation,
}: ReturnType<typeof partialUpdateVariation>) {
  try {
    const {
      result: variationId,
      entities: { variations },
    } = yield* requestAPI(API.partialUpdateVariation, variation)
    yield put(updateVariationSuccess(inventoryId, variations[variationId]))
    yield put(fetchInventory(inventoryId))
  } catch (error) {
    yield put(updateVariationFailure(error as ApiError))
  }
}

export function* deleteVariationSaga({
  inventoryId,
  variationId,
}: ReturnType<typeof deleteVariation>) {
  try {
    yield* requestAPI(API.deleteVariation, inventoryId, variationId)
    yield put(deleteVariationSuccess(inventoryId, variationId))
    yield put(fetchInventory(inventoryId))
  } catch (error) {
    yield put(deleteVariationFailure(error as ApiError))
  }
}

const optimizeAggregatedCost = (
  inStockUnitCosts: VariationCalculatedCost[] = [],
) => arrayToMap(inStockUnitCosts, R.prop('priceUnitId'), R.prop('cost'))

export function* fetchVariationCalculatedCostSaga({
  inventoryId,
  variationId,
}: ReturnType<typeof fetchVariationCalculatedCost>) {
  try {
    const { aggregatedCosts = {} } = yield* requestAPI(
      API.fetchVariationCalculatedCost,
      inventoryId,
      variationId,
    )
    const quickAccessAggregatedCosts: Record<string, any> = R.map(
      optimizeAggregatedCost,
      aggregatedCosts,
    )
    yield put(fetchVariationCalculatedCostSuccess(quickAccessAggregatedCosts))
  } catch (error) {
    yield put(fetchVariationCalculatedCostFailure(error as ApiError))
  }
}

export function* fetchVariationPartNumberDetailsSaga({
  globalVariationId,
}: ReturnType<typeof fetchVariationPartNumberDetails>) {
  try {
    const { partNumberDetails } = yield* requestAPI(
      API.fetchVariationPartNumberDetails,
      globalVariationId,
    )

    yield put(fetchVariationPartNumberDetailsSuccess(partNumberDetails))
  } catch (error) {
    yield put(fetchVariationPartNumberDetailsFailure(error as ApiError))
  }
}

function* watchCreateVariation() {
  yield takeLeading(CREATE_VARIATION, createVariationSaga)
}

function* watchCreateVariations() {
  yield takeLeading(CREATE_VARIATIONS, createVariationsSaga)
}

function* watchUpdateVariation() {
  yield takeLeading(UPDATE_VARIATION, updateVariationSaga)
}

function* watchPartialUpdateVariation() {
  yield takeLeading(PARTIAL_UPDATE_VARIATION, partialUpdateVariationSaga)
}

function* watchDeleteVariation() {
  yield takeLeading(DELETE_VARIATION, deleteVariationSaga)
}

function* watchFetchVariationCalculatedCost() {
  yield takeLeading(
    FETCH_VARIATION_CALCULATED_COST,
    fetchVariationCalculatedCostSaga,
  )
}

function* watchFetchVariationPartNumberDetails() {
  yield takeLeading(
    FETCH_VARIATION_PART_NUMBER_DETAILS,
    fetchVariationPartNumberDetailsSaga,
  )
}

export default function* variationsSaga() {
  yield all([
    watchCreateVariation(),
    watchCreateVariations(),
    watchUpdateVariation(),
    watchPartialUpdateVariation(),
    watchDeleteVariation(),
    watchFetchVariationCalculatedCost(),
    watchFetchVariationPartNumberDetails(),
  ])
}
