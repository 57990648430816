import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const DropdownToggleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="m12.5 4 4.5 7H8l4.5-7ZM12.5 20l4.5-7H8l4.5 7Z"
      fillRule="evenodd"
    />
  </SvgIcon>
)
