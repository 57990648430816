import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  Calendar,
  PuiSelect,
  PuiTooltip,
  Shadow,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Boop } from '@pbt/pbt-ui-components/src/icons'

import CalendarTextLabel from '~/components/common/inputs/calendar/CalendarTextLabel'
import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import TimeSelectorTextLabel from '~/components/common/inputs/time-selector/TimeSelectorTextLabel'
import UserSelect, {
  PET_PARENT_OPTION_ID,
  UserSelectFilterScope,
} from '~/components/common/inputs/UserSelect'
import FeatureToggle from '~/constants/featureToggle'
import { RepeatMode, RepeatModeList } from '~/constants/taskConstants'
import { useTaskEvent } from '~/store/hooks/tasks'
import { getFeatureToggle, getTimeOffsets } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { getUser } from '~/store/reducers/users'
import { TeamFilter } from '~/types'
import { getNewTimeAfterDayChange } from '~/utils/time'

import { getPatientHasMembership } from '../wellness-plans/wellnessPlanUtils'
import RepeatTaskPopper from './RepeatTaskPopper'
import TaskRepeatLabel from './TaskRepeatLabel'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
      marginBottom: theme.spacing(2),
    },
    tableHeading: {
      padding: theme.spacing(0.5, 0, 0.5, 2),
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      fontWeight: 500,
      borderBottom: 'none',
      '&:first-of-type': {
        borderRight: theme.constants.tableBorder,
      },
    },
    tableSubHeading: {
      padding: theme.spacing(0.5, 0, 0.5, 2),
      borderBottom: theme.constants.tableBorder,
      '&:first-of-type, &:last-of-type': {
        borderRight: theme.constants.tableBorder,
      },
    },
    tableCell: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
      padding: theme.spacing(1, 2),
      border: 'none',
      '&:first-of-type, &:last-of-type': {
        borderRight: theme.constants.tableBorder,
      },
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    button: {
      minWidth: 152,
    },
    text: {
      fontSize: '1.4rem',
    },
    boopIcon: {
      width: '0.75em',
      height: '0.75em',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'MultipleTasksTable' },
)

const MultipleTasksTable = ({
  items,
  tasks: tasksProp,
  appointmentId,
  clientId,
  dueDate,
  patientId,
  soapId,
  onProceed,
  proceedButtonLabel,
  showAssigned = true,
  showType = true,
  showDueDate = true,
  showDueTime = true,
  showRepeat = true,
}) => {
  const classes = useStyles()
  const isLoading = useSelector(getTasksIsLoading)
  const TimeOffsets = useSelector(getTimeOffsets)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const { t } = useTranslation(['Common', 'Tasks', 'Time', 'Tooltips'])
  const multipleTasksTableProceedButtonLabel =
    proceedButtonLabel || t('Common:CREATE_ACTION')

  const TaskType = useTaskEvent()
  const TaskTypes = TaskType.subTypes
  const TreatmentsType = Utils.findConstantIdByName(
    'Treatments',
    TaskType.subTypes,
  )
  const CustomTimeOffset = Utils.findConstantIdByName('Custom', TimeOffsets)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const itemsToTasks = (items = []) =>
    items.map((item) => ({
      name: item.name,
      orderId: item.id,
      orderType: item.type,
      notes: item.notes,
      appointmentId,
      clientId,
      patientId,
      soapId,
      dueDate,
      dueTime: dueDate,
      typeId: TreatmentsType,
    }))

  const getTasks = () => (items ? itemsToTasks(items) : tasksProp)

  const [tasks, setTasks] = useState(getTasks())
  const [repeatPopperOpen, setRepeatPopperOpen] = useState(false)
  const [taskToEdit, setTaskToEdit] = useState()
  const [pendingRecurrenceModeId, setPendingRecurrenceModeId] = useState()

  const rootRef = useRef()

  useEffect(() => {
    setTasks(getTasks())
  }, [items, tasksProp])

  const applyToAllTasks = (field, value) => {
    const newTasks = tasks.map((task) => ({ ...task, [field]: value }))
    setTasks(newTasks)
  }

  const applyToTask = (task, field, value) => {
    const oldTaskIndex = tasks.indexOf(task)
    const newTask = { ...task, [field]: value }
    setTasks(R.update(oldTaskIndex, newTask, tasks))
    return newTask
  }

  const applySettingsToAllTasks = (settings) => {
    const newTasks = tasks.map((task) => ({ ...task, ...settings }))
    setTasks(newTasks)
  }

  const applySettingsToTask = (task, settings) => {
    const oldTaskIndex = tasks.indexOf(task)
    const newTask = { ...task, ...settings }
    setTasks(R.update(oldTaskIndex, newTask, tasks))
  }

  const proceed = () => {
    const modifiedTasks = tasks.map((task) => {
      const dueDate = task.dueTime || task.dueDate
      const isForClient = task?.assigneeId === PET_PARENT_OPTION_ID
      const newTask = R.omit(['dueTime'], task)

      if (isForClient) {
        newTask.assigneeId = clientId
      }

      newTask.dueDate = dueDate
      newTask.forClient = isForClient

      return newTask
    })

    onProceed(modifiedTasks)
  }

  const getRepeatMode = (task) =>
    task?.recurrencePeriod ? RepeatMode.REPEAT : RepeatMode.DOES_NOT_REPEAT

  const showApplyToAllRow = tasks.length > 1
  const firstTask = R.head(tasks) || {}
  const singleValues = Object.keys(firstTask).reduce((acc, val) => {
    acc[val] = tasks.every((task) => task[val] === firstTask[val])
      ? firstTask[val]
      : undefined

    return acc
  }, {})

  const petParentTaskTooltip = t(
    'Tooltips:PET_PARENT_TASKS_AUTOMATICALLY_ASSIGNED',
  )

  const hideAssignedAll = R.all(R.propEq('forClient', true), tasks)

  const displayPetParentOption =
    client?.isBoopUser && getPatientHasMembership(patient)

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      ref={rootRef}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeading}>
              {t('Common:NAME')}
            </TableCell>
            {showAssigned && (
              <TableCell className={classes.tableHeading}>
                {t('Common:ASSIGNED')}
              </TableCell>
            )}
            {showType && (
              <TableCell className={classes.tableHeading}>
                {t('Common:TYPE_ONE')}
              </TableCell>
            )}
            {showDueDate && (
              <TableCell className={classes.tableHeading}>
                {t('Time:LABEL.DUE_DATE')}
              </TableCell>
            )}
            {showDueTime && (
              <TableCell className={classes.tableHeading}>
                {t('Tasks:LABEL.DUE_TIME')}
              </TableCell>
            )}
            {showRepeat && (
              <TableCell className={classes.tableHeading}>
                {t('Common:REPEAT_ACTION')}
              </TableCell>
            )}
          </TableRow>
          {showApplyToAllRow && (
            <TableRow>
              <TableCell className={classes.tableSubHeading} />
              {showAssigned && (
                <TableCell className={classes.tableSubHeading}>
                  {!hideAssignedAll ? (
                    <UserSelect
                      displayEmpty
                      plain
                      displayPetParentOption={displayPetParentOption}
                      emptyLabel={t('Tasks:LABEL.ASSIGN_ALL')}
                      field={{
                        value: singleValues.assigneeId || singleValues.assigned,
                        setValue: (value) =>
                          applyToAllTasks('assigneeId', value),
                      }}
                      filterScope={UserSelectFilterScope.Staff}
                      petParent={client}
                      teamFilter={TeamFilter.ALL}
                    />
                  ) : null}
                </TableCell>
              )}
              {showType && (
                <TableCell className={classes.tableSubHeading}>
                  <PuiSelect
                    disableUnderline
                    classes={{ select: classes.text }}
                    items={TaskTypes}
                    placeholder={t('Tasks:LABEL.ALL_TYPES')}
                    value={singleValues.typeId}
                    onChange={Utils.handleFormSelectInput((value) => {
                      if (value) {
                        applyToAllTasks('typeId', value)
                      }
                    })}
                  />
                </TableCell>
              )}
              {showDueDate && (
                <TableCell className={classes.tableSubHeading}>
                  <Calendar
                    fullWidth
                    label={`${t('Tasks:LABEL.DUE_DATE')}*`}
                    renderInput={CalendarTextLabel}
                    value={singleValues.dueDate}
                    onChange={(value) => {
                      const newEndTime = getNewTimeAfterDayChange(
                        value,
                        singleValues.dueTime,
                      )

                      applySettingsToAllTasks({
                        dueDate: value,
                        dueTime: newEndTime,
                      })
                    }}
                  />
                </TableCell>
              )}
              {showDueTime && (
                <TableCell className={classes.tableSubHeading}>
                  <TimeSelector
                    fullWidth
                    TextFieldComponent={TimeSelectorTextLabel}
                    label={`${t('Tasks:LABEL.DUE_TIME')}*`}
                    startValue={singleValues.dueTime}
                    onStartChange={(value) => applyToAllTasks('dueTime', value)}
                  />
                </TableCell>
              )}
              {showRepeat && (
                <TableCell className={classes.tableSubHeading}>
                  <PuiSelect
                    disableUnderline
                    classes={{ select: classes.text }}
                    items={RepeatModeList}
                    placeholder={t('Tasks:LABEL.ALL_REPEATS')}
                    renderEmpty={false}
                    value={getRepeatMode(singleValues)}
                    onChange={Utils.handleFormSelectInput((value) => {
                      if (value === RepeatMode.REPEAT) {
                        setPendingRecurrenceModeId(CustomTimeOffset)
                        setRepeatPopperOpen(true)
                      } else {
                        applySettingsToAllTasks({
                          recurrenceModeId: null,
                          recurrenceEndDatetime: null,
                          occurrencesCount: null,
                          recurrencePeriod: null,
                        })
                      }
                    })}
                  />
                </TableCell>
              )}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TableRow
              className={classes.tableRow}
              key={`${task.id}-${task.orderId}-${task.orderType}`}
            >
              <TableCell className={classes.tableCell}>{task.name}</TableCell>
              {showAssigned && (
                <TableCell className={classes.tableCell}>
                  {task.forClient ? (
                    <PuiTooltip tooltipText={petParentTaskTooltip}>
                      <Grid container alignItems="center">
                        {!isBoopDisablementEnabled && (
                          <Boop className={classes.boopIcon} />
                        )}
                        <Text variant="body2">{t('Common:PET_PARENT')}</Text>
                      </Grid>
                    </PuiTooltip>
                  ) : (
                    <UserSelect
                      displayEmpty
                      plain
                      displayPetParentOption={displayPetParentOption}
                      field={{
                        value: task.assigneeId || task.assigned,
                        setValue: (value) =>
                          applyToTask(task, 'assigneeId', value),
                      }}
                      filterScope={UserSelectFilterScope.Staff}
                      petParent={client}
                      teamFilter={TeamFilter.ALL}
                    />
                  )}
                </TableCell>
              )}
              {showType && (
                <TableCell className={classes.tableCell}>
                  <PuiSelect
                    disableUnderline
                    classes={{ select: classes.text }}
                    items={TaskTypes}
                    renderEmpty={false}
                    value={task.typeId}
                    onChange={Utils.handleFormSelectInput((value) =>
                      applyToTask(task, 'typeId', value),
                    )}
                  />
                </TableCell>
              )}
              {showDueDate && (
                <TableCell className={classes.tableCell}>
                  <Calendar
                    fullWidth
                    label={`${t('Tasks:LABEL.DUE_DATE')}*`}
                    renderInput={CalendarTextLabel}
                    value={task.dueDate}
                    onChange={(value) => {
                      const newEndTime = getNewTimeAfterDayChange(
                        value,
                        task.dueTime,
                      )
                      applySettingsToTask(task, {
                        dueDate: value,
                        dueTime: newEndTime,
                      })
                    }}
                  />
                </TableCell>
              )}
              {showDueTime && (
                <TableCell className={classes.tableCell}>
                  <TimeSelector
                    fullWidth
                    TextFieldComponent={TimeSelectorTextLabel}
                    label={`${t('Tasks:LABEL.DUE_TIME')}*`}
                    startValue={task.dueTime}
                    onStartChange={(value) =>
                      applyToTask(task, 'dueTime', value)
                    }
                  />
                </TableCell>
              )}
              {showRepeat && (
                <TableCell className={classes.tableCell}>
                  <PuiSelect
                    disableUnderline
                    classes={{ select: classes.text }}
                    items={RepeatModeList}
                    renderEmpty={false}
                    value={getRepeatMode(task)}
                    onChange={Utils.handleFormSelectInput((value) => {
                      if (value === RepeatMode.REPEAT) {
                        setPendingRecurrenceModeId(CustomTimeOffset)
                        setTaskToEdit(task)
                        setRepeatPopperOpen(true)
                      } else {
                        applySettingsToTask(task, {
                          recurrenceModeId: null,
                          recurrenceEndDatetime: null,
                          occurrencesCount: null,
                          recurrencePeriod: null,
                        })
                      }
                    })}
                  />
                  <TaskRepeatLabel task={task} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ButtonWithLoader
        className={classes.button}
        disabled={isLoading}
        loading={isLoading}
        onClick={proceed}
      >
        {multipleTasksTableProceedButtonLabel}
      </ButtonWithLoader>
      {rootRef.current && repeatPopperOpen && (
        <RepeatTaskPopper
          anchorEl={rootRef.current}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
          ]}
          open={repeatPopperOpen}
          task={{
            ...(taskToEdit || singleValues),
            recurrenceModeId: pendingRecurrenceModeId,
          }}
          onApply={(repeatSettings) => {
            if (taskToEdit) {
              applySettingsToTask(taskToEdit, repeatSettings)
            } else {
              applySettingsToAllTasks(repeatSettings)
            }

            setTaskToEdit()
            setPendingRecurrenceModeId()
            setRepeatPopperOpen(false)
          }}
          onClose={() => {
            setTaskToEdit()
            setPendingRecurrenceModeId()
            setRepeatPopperOpen(false)
          }}
        />
      )}
      <Shadow open={repeatPopperOpen} />
    </Grid>
  )
}

export default MultipleTasksTable
