import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { AlertIconType, PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

const useStyles = makeStyles(
  () => ({
    message: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'SoapWrapUp' },
)

const GENERAL_MESSAGE = `${i18n.t(
  'Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.GENERAL_MESSAGE',
)}:`

export interface SoapWrapUpLeaveDialogProps
  extends Omit<PuiAlertProps, 'iconType'> {
  onProceed?: PuiAlertProps['onOk']
  unsavedData: string[]
}

const SoapWrapUpLeaveDialog = ({
  onProceed,
  unsavedData,
  ...rest
}: SoapWrapUpLeaveDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <PuiAlert
      cancelButtonText={t('Common:LEAVE_ANYWAY_ACTION')}
      classes={{
        message: classes.message,
      }}
      iconType={AlertIconType.WARN}
      message={`${GENERAL_MESSAGE}\n\n${(unsavedData || []).join('\n')}`}
      okButtonText={t('Common:FIX_ITEMS_ACTION')}
      onOk={onProceed}
      {...rest}
    />
  )
}

export default SoapWrapUpLeaveDialog
