import React from 'react'
import { Grid, GridProps } from '@mui/material'
import moment from 'moment'
import { DateFormat, Nil, Text } from '@pbt/pbt-ui-components'

import { useCurrentBusinessTimezone } from '~/utils/useCurrentBusinessTimezone'

interface PrescriptionDateProps extends GridProps {
  date?: string | Nil
  prefix?: string
}

const PrescriptionDate = ({
  date,
  prefix,
  ...props
}: PrescriptionDateProps) => {
  const timezone = useCurrentBusinessTimezone()

  const momentDate = moment.tz(date, timezone)

  if (!momentDate.isValid()) {
    return null
  }

  const formattedDate = momentDate.format(DateFormat.FULL_DATE_COMMA_PIPE_TIME)

  const dateFromNow = momentDate.fromNow()

  return (
    <Grid {...props}>
      <Text inline strong variant="subheading3">
        {prefix ? `${prefix} ${formattedDate}` : formattedDate}
      </Text>
      <Text inline ml={1} variant="h5">
        {dateFromNow}
      </Text>
    </Grid>
  )
}

export default PrescriptionDate
