import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { SlotType } from '~/constants/schedulerConstants'

import TimetableEventCard, {
  TimetableEventCardProps,
} from '../TimetableEventCard'
import { realIdToDragDrop } from '../timetableUtils'
import WhiteboardDroppablePlaceholder from './WhiteboardDroppablePlaceholder'

const useStyles = makeStyles(
  (theme) => ({
    dragHandle: {
      width: '100%',
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    dragHandleDragging: {
      boxShadow: '3px 3px 15px 0 rgba(59,58,58,0.4)',
    },
    dragHandleNoTransform: {
      transform: 'none !important',
    },
    busyRoot: {
      minHeight: 120,
    },
  }),
  { name: 'WhiteboardEventCard' },
)

export interface WhiteboardEventCardProps extends TimetableEventCardProps {
  appointmentId: string
  index: number
  isDragDisabled?: boolean
  isDraggingOver: boolean
  noTransform: boolean
  shouldShowEndPlaceholder: boolean
  shouldShowStartPlaceholder: boolean
  type: string
}

const WhiteboardEventCard = ({
  isDragDisabled,
  type,
  appointmentId,
  shouldShowStartPlaceholder,
  shouldShowEndPlaceholder,
  isDraggingOver,
  noTransform,
  index,
  slot,
  ...rest
}: WhiteboardEventCardProps) => {
  const classes = useStyles()

  const isNotAvailable = slot.type === SlotType.NOT_AVAILABLE
  const isBusy = isNotAvailable || slot.type === SlotType.BUSY

  const id = realIdToDragDrop(appointmentId, type)

  return (
    <>
      {shouldShowStartPlaceholder && <WhiteboardDroppablePlaceholder />}
      <Draggable
        draggableId={id}
        index={index}
        isDragDisabled={isDragDisabled || isBusy}
      >
        {(provided, draggableSnapshot) => (
          <div
            className={classNames(classes.dragHandle, {
              [classes.dragHandleDragging]: draggableSnapshot.isDragging,
              [classes.dragHandleNoTransform]: noTransform,
            })}
            id={id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TimetableEventCard
              showHourRange
              appointmentId={appointmentId}
              classes={{ busyRoot: classes.busyRoot }}
              slot={slot}
              {...rest}
            />
          </div>
        )}
      </Draggable>
      {shouldShowEndPlaceholder && <WhiteboardDroppablePlaceholder />}
    </>
  )
}

export default WhiteboardEventCard
