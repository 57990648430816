import i18n from '~/locales/i18n'

export enum VitalMeasurement {
  WEIGHT = 'weight',
}

export const VitalsNames = {
  weight: i18n.t('Common:WEIGHT'),
  temperature: i18n.t('Common:TEMPERATURE'),
  heartRate: i18n.t('Soap:VITALS.HEART_RATE.LABEL'),
  pulseCharacterId: i18n.t('Soap:VITALS.PULSE_CHARACTER.LABEL'),
  respirationRate: i18n.t('Soap:VITALS.RESPIRATION.LABEL'),
  capillaryRefillTime: i18n.t('Soap:VITALS.CAPILLARY_REFILL_TIME.LABEL'),
  mucousMembraneColorId: i18n.t('Soap:VITALS.MUCOUS_MEMBRANE_COLOR.LABEL'),
  mucousMembraneMoistureId: i18n.t(
    'Soap:VITALS.MUCOUS_MEMBRANE_MOISTURE.LABEL',
  ),
  skinTurgorId: i18n.t('Soap:VITALS.TURGOR.LABEL'),
  mentationId: i18n.t('Soap:VITALS.MENTATION.LABEL'),
  bodyConditionScore: i18n.t('Soap:VITALS.BODY_CONDITION_SCORE.LABEL'),
  muscleMassScore: i18n.t('Soap:VITALS.MUSCLE_MASS_SCORE.LABEL'),
  fearAnxietyScore: i18n.t('Soap:VITALS.FEAR_ANXIETY_SCORE.LABEL'),
  dyspnea: i18n.t('Soap:VITALS.DYSPNEA.LABEL'),
  painScore: i18n.t('Soap:VITALS.PAIN_SCORE.LABEL'),
  bloodPressure: i18n.t('Soap:VITALS.BLOOD_PRESSURE.LABEL'),
  spo2: i18n.t('Abbreviations:VITALS.SPO2'),
  etco2: i18n.t('Abbreviations:VITALS.ETCO2'),
  o2Level: i18n.t('Soap:VITALS.O2_LEVEL.LABEL'),
  isoSevoLevel: i18n.t('Abbreviations:VITALS.ISO_SEVO_LEVEL'),
  recumbencyId: i18n.t('Soap:VITALS.RECUMBENCY.LABEL'),
}

export enum VitalFormInputTypes {
  WEIGHT = 'weight',
  TEMPERATURE = 'temperature',
  SELECT = 'select',
  INPUT = 'input',
}

const TITLE_COLUMN_WIDTH = 262
export const VITALS_LAYOUT_CONSTANTS = {
  SCROLLABLE_ITEM_WIDTH: 160,
  CONTROL_TABLE_WIDTH: 670,
  TITLE_COLUMN_WIDTH,
  MAX_CHARS_TITLE_COLUMN: Math.floor(TITLE_COLUMN_WIDTH / 12),
  HEADER_HEIGHT: 40,
}
