import i18n from '~/locales/i18n'

export const WhiteboardViewType = {
  DEFAULT: 'default',
  TEAM_MEMBER: 'team',
  TYPE: 'type',
  TREATMENTS: 'treatments',
}

export const WhiteboardViewHumanReadable = [
  {
    name: WhiteboardViewType.DEFAULT,
    label: i18n.t('Common:DEFAULT_VIEW'),
  },
  {
    name: WhiteboardViewType.TEAM_MEMBER,
    label: i18n.t('Common:BY_TEAM_MEMBER'),
  },
  {
    name: WhiteboardViewType.TYPE,
    label: i18n.t('Common:BY_TYPE'),
  },
  {
    name: WhiteboardViewType.TREATMENTS,
    label: i18n.t('Common:TREATMENTS'),
  },
]

export const WhiteboardHorizontalViews = [
  WhiteboardViewType.TEAM_MEMBER,
  WhiteboardViewType.TYPE,
  WhiteboardViewType.TREATMENTS,
]

export const WhiteboardRailOnTopViews = [WhiteboardViewType.TREATMENTS]
