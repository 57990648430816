import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, PhoneUtils, Text, Utils } from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import FeatureToggle from '~/constants/featureToggle'
import {
  getCurrentGroupRoles,
  getCurrentRoles,
  getCurrentUser,
} from '~/store/reducers/auth'
import { getBusinessesMap } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getUserIsFetching } from '~/store/reducers/users'
import { isPracticeAdminRole } from '~/utils/roleUtils'

import ProfileBottomContainer from './ProfileBottomContainer'
import ProfileTopContainer from './ProfileTopContainer'

const useStyles = makeStyles(
  (theme) => ({
    profileTopContainer: {
      paddingTop: theme.spacing(7),
    },
    profilePic: {
      height: 108,
      width: 108,
    },
    tertiaryText: {
      color: theme.colors.tertiaryText,
    },
    boldText: {
      fontWeight: 500,
    },
    button: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.iconColor,
      fontSize: '1.6rem',
      textTransform: 'none',
    },
  }),
  { name: 'ProfileInfo' },
)

export interface ProfileInfoProps {
  onAvatarEditClick: () => void
  onChangePasswordClick: () => void
  onClick: () => void
  onNotificationSettingsClick: () => void
  onSavedSignatureClick: () => void
}

const ProfileInfo = ({
  onClick,
  onAvatarEditClick,
  onChangePasswordClick,
  onNotificationSettingsClick,
  onSavedSignatureClick,
}: ProfileInfoProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Profile'])

  const user = useSelector(getCurrentUser)
  const currentRoles = useSelector(getCurrentRoles)
  const currentGroupRoles = useSelector(getCurrentGroupRoles)
  const businessesMap = useSelector(getBusinessesMap)
  const isFetching = useSelector(getUserIsFetching)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const rolesString = currentRoles
    .map((role) => LanguageUtils.getTranslatedFieldName(role))
    .join(', ')
  const groupRolesString = currentGroupRoles
    .map((groupRole) => {
      const roleName = isPracticeAdminRole(groupRole)
        ? t('Common:GROUP_ADMINISTATOR')
        : LanguageUtils.getTranslatedFieldName(groupRole)
      const businessName = LanguageUtils.getTranslatedFieldName(
        businessesMap[groupRole.businessId],
      )
      return `${roleName}/${businessName}`
    })
    .join(', ')

  return (
    <Grid container direction="column" width={412}>
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        justifyContent="center"
      >
        <ProfileTopContainer
          showEdit
          className={classes.profileTopContainer}
          onClick={onClick}
        >
          <Grid item>
            <Avatar
              className={classes.profilePic}
              person={user}
              size="normal"
              onEditClick={onAvatarEditClick}
            />
          </Grid>
          <Grid item mb={3} mt={1.5}>
            <Text align="center" className={classes.tertiaryText} variant="h3">
              {Utils.getPersonString(user)}
            </Text>
          </Grid>
        </ProfileTopContainer>
        <ProfileBottomContainer>
          <Grid item mt={4}>
            <Text strong align="center" mb={0.5} variant="lowAccent2">
              {t('Common:CONTACT_ONE').toUpperCase()}
            </Text>
            <Text
              align="center"
              className={classes.tertiaryText}
              variant="body"
            >
              {PhoneUtils.getPhoneNumber(user.mobilePhone)}
            </Text>
            <Text align="center" className={classes.tertiaryText} mt={0.5}>
              {user.email}
            </Text>
          </Grid>
          <Grid item mb={4} mt={5}>
            <Text strong align="center" mb={0.5} variant="lowAccent2">
              {t('Common:JOB_INFO').toUpperCase()}
            </Text>
            <Text align="center" className={classes.tertiaryText}>
              <b className={classes.boldText}>{t('Common:JOB_TYPE')}:</b>
              &nbsp;
              {rolesString}
              {isPatientSharingEnabled &&
                groupRolesString &&
                `, ${groupRolesString}`}
            </Text>
            {user.dea && (
              <Text align="center" className={classes.tertiaryText}>
                <b className={classes.boldText}>
                  {t('Abbreviations:ACRONYMS.DEA.DEA_NUMBER_SIGN')}:
                </b>{' '}
                {user.dea}
              </Text>
            )}
          </Grid>
          {!user?.ssoUser && (
            <Grid container item justifyContent="center">
              <Button
                className={classes.button}
                disabled={isFetching}
                onClick={onChangePasswordClick}
              >
                {t('Profile:PROFILE_INFO.CHANGE_PASSWORD')}
                <ChevronRightIcon />
              </Button>
            </Grid>
          )}
          <Grid container item justifyContent="center" mt={1}>
            <Button
              className={classes.button}
              disabled={isFetching}
              onClick={onNotificationSettingsClick}
            >
              {t('Common:NOTIFICATION_SETTINGS')}
              <ChevronRightIcon />
            </Button>
          </Grid>
          <Grid container item justifyContent="center" mb={5} mt={1}>
            <Button
              className={classes.button}
              disabled={isFetching}
              onClick={onSavedSignatureClick}
            >
              {t('Common:SAVED_SIGNATURE')}
              <ChevronRightIcon />
            </Button>
          </Grid>
        </ProfileBottomContainer>
      </Grid>
    </Grid>
  )
}

export default ProfileInfo
