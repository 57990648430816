import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Fab, Grid, Link, Paper, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BackButton,
  ButtonWithLoader,
  ButtonWithLoaderProps,
  ClassesType,
  MarketingStylesButton,
  PhoneUtils,
  Text,
} from '@pbt/pbt-ui-components'
import { Chain, Phone, Timezone } from '@pbt/pbt-ui-components/src/icons'

import ClinicLogo from '~/components/common/logos/ClinicLogo'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 5,
        paddingRight: 5,
        height: 'auto',
      },
      height: '100%',
    },
    mainBlock: {
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(6),
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        paddingTop: theme.spacing(2),
      },
    },
    button: {
      height: 50,
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
      },
      width: '100%',
    },
    rightButton: {
      boxShadow: theme.constants.buttonBlockShadow,
    },
    editButton: {
      boxShadow: theme.constants.buttonBlockShadow,
    },
    details: {
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        paddingTop: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    label: {
      [theme.breakpoints.down('md')]: {
        maxWidth: 260,
        fontSize: '1.6rem',
      },
      paddingLeft: 10,
      fontSize: '1.8rem',
      lineHeight: '22px',
    },
    header: {
      lineHeight: '52px',
      fontSize: 44,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        padding: theme.spacing(1, 2, 0),
      },
    },
    name: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },

    locationOnIcon: {
      marginLeft: -5,
    },
    icon: {
      color: theme.colors.iconColor,
    },
    headingTextBlock: {
      padding: '10px 10px 0 10px',
    },
    clinicLogoContainer: {
      maxWidth: 263,
      height: 152,
    },
    detailRow: {
      paddingTop: theme.spacing(2),
    },
    buttonGridOuter: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(5),
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    leftButtonContainer: {
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(2),
      },
    },
    rightButtonContainer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
    },
    subHeader: {},
  }),
  { name: 'ClinicView' },
)

interface ClinicViewProps {
  buttonColor?: ButtonWithLoaderProps['color']
  classes?: ClassesType<typeof useStyles>
  clinic: any
  confirmButtonLoading?: boolean
  confirmButtonText?: string
  headerText?: string
  onBackToMapClick: () => void
  onEditClick: () => void
  onSubmit: () => void
  subheaderText?: string
}

const ClinicView = ({
  clinic,
  onEditClick,
  onBackToMapClick,
  headerText,
  subheaderText,
  confirmButtonText,
  confirmButtonLoading,
  buttonColor,
  onSubmit,
  classes: classesProp,
}: ClinicViewProps) => {
  const classes = useStyles({ classes: classesProp })
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation(['Common', 'Registration'])

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item className={classes.headingTextBlock}>
        <Text className={classes.header} variant="hero">
          {headerText || t('Registration:CLINIC_VIEW.HEADER_TEXT')}
        </Text>
        <Text className={classes.subHeader} variant="h3">
          {subheaderText || ''}
        </Text>
      </Grid>
      <Grid
        container
        item
        xs
        className={classes.mainBlock}
        justifyContent="center"
      >
        <Grid item className={classes.clinicLogoContainer} md={3} xs={12}>
          <ClinicLogo clinic={clinic} component={Paper} />
        </Grid>
        <Grid
          container
          item
          className={classes.details}
          direction="column"
          justifyContent={isMobile ? 'center' : 'flex-start'}
          md={9}
          xs={12}
        >
          <Text className={classes.name} variant="h3">
            {clinic.name}
          </Text>
          <Grid container item alignItems="flex-start" direction="column">
            <Grid
              container
              item
              alignItems="center"
              className={classes.detailRow}
              wrap="nowrap"
            >
              <LocationOnIcon
                className={classNames(classes.locationOnIcon, classes.icon)}
              />
              <Grid item xs>
                <Dotdotdot clamp={2}>
                  <Text className={classes.label}>
                    {clinic.getFormattedAddress()}
                  </Text>
                </Dotdotdot>
              </Grid>
            </Grid>
            {clinic.phone && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.detailRow}
                wrap="nowrap"
              >
                <Phone className={classes.icon} />
                <Grid item xs>
                  <Dotdotdot clamp={2}>
                    <Text className={classes.label}>
                      {PhoneUtils.getPhoneNumber(clinic.phone)}
                    </Text>
                  </Dotdotdot>
                </Grid>
              </Grid>
            )}
            {clinic.parsedWebsite && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.detailRow}
                wrap="nowrap"
              >
                <Chain className={classes.icon} />
                <Grid item xs>
                  <Dotdotdot clamp={2}>
                    <Text className={classes.label}>
                      <Link
                        color="secondary"
                        href={clinic.website}
                        rel="noopener noreferrer"
                        target="_blank"
                        underline="always"
                      >
                        {clinic.parsedWebsite}
                      </Link>
                    </Text>
                  </Dotdotdot>
                </Grid>
              </Grid>
            )}
            {clinic.timeZone && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.detailRow}
                wrap="nowrap"
              >
                <Timezone className={classes.icon} />
                <Grid item xs>
                  <Dotdotdot clamp={2}>
                    <Text className={classes.label}>{clinic.timeZone}</Text>
                  </Dotdotdot>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item alignItems="flex-end">
        <Grid item md={3} xs={12}>
          <BackButton
            label={t('Common:BACK_TO_MAP')}
            onClick={onBackToMapClick}
          />
        </Grid>
        <Grid container item className={classes.buttonGridOuter} md={9} xs={12}>
          <Grid item className={classes.leftButtonContainer} md={6} xs={12}>
            <Fab
              className={classNames(classes.editButton, classes.button)}
              color="primary"
              variant="extended"
              onClick={onEditClick}
            >
              {t('Common:NO_I_NEED_TO_EDIT')}
            </Fab>
          </Grid>
          <Grid item className={classes.rightButtonContainer} md={6} xs={12}>
            {buttonColor ? (
              <ButtonWithLoader
                className={classNames(classes.button, classes.rightButton)}
                color={buttonColor}
                loading={confirmButtonLoading}
                onClick={onSubmit}
              >
                {confirmButtonText || t('Common:YES')}
              </ButtonWithLoader>
            ) : (
              <MarketingStylesButton
                className={classNames(classes.button, classes.rightButton)}
                onClick={onSubmit}
              >
                {confirmButtonText || t('Common:YES')}
              </MarketingStylesButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClinicView
