import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { DateUtils, Defaults, useInterval } from '@pbt/pbt-ui-components'

import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  loyaltyPointsActions,
  loyaltyPointsSelectors,
} from '~/store/duck/loyaltyPoints'

import DescriptionCell from './DescriptionCell'
import PointsCell from './PointsCell'

const useStyles = makeStyles(
  (theme) => ({
    rowWrapperContainer: {
      backgroundColor: theme.colors.tableBackground,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderBottom: theme.constants.tableBorder,
      },
    },
    rowContainer: {
      borderTop: 'none',
      borderBottom: 'none',
      backgroundColor: 'unset',
      marginBottom: 0,
    },
    tableHeader: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    text: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'MembershipTableLoyaltyPointsView' },
)

const MembershipTableLoyaltyPointsView = ({ clientId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const list = useSelector(loyaltyPointsSelectors.getList)
  const loyaltyPoints = useSelector(
    loyaltyPointsSelectors.getMultipleItems(list),
  )
  const totalCount = useSelector(loyaltyPointsSelectors.getTotalCount)
  const hasOpenDialogs = useSelector(getHasOpenDialogs)
  const { t } = useTranslation('Common')

  const columns = [
    {
      label: t('Common:DATE_TIME'),
      prop: (item) => DateUtils.formatDate(item.date),
      width: 2,
    },
    {
      label: t('Common:DESCRIPTION'),
      prop: DescriptionCell,
      noTypography: true,
      width: 5,
    },
    {
      label: t('Common:PATIENT'),
      prop: (item) => item?.patient?.name,
      width: 3,
    },
    {
      label: t('Common:POINTS'),
      prop: PointsCell,
      noTypography: true,
      width: 2,
    },
  ]

  useEffect(() => {
    if (clientId) {
      dispatch(
        loyaltyPointsActions.fetchList(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          clientId,
        ),
      )
    }
  }, [clientId])

  useInterval(() => {
    if (!hasOpenDialogs && clientId) {
      dispatch(
        loyaltyPointsActions.refreshList(
          0,
          Math.max(loyaltyPoints.length - 1, 0),
          clientId,
        ),
      )
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  const isItemLoaded = (index) => Boolean(loyaltyPoints[index])

  const loadMoreItems = (startIndex, endIndex) => {
    dispatch(loyaltyPointsActions.fetchMoreItemsForList(startIndex, endIndex))
  }

  return (
    <PrimitiveTableWithSearchHighlights
      HeaderProps={{
        classes: {
          tableHeader: classes.tableHeader,
        },
      }}
      columns={columns}
      defaultSizeDesktop={48}
      expandable={false}
      isItemLoaded={isItemLoaded}
      list={loyaltyPoints}
      loadMoreItems={loadMoreItems}
      rowClasses={{
        container: classes.rowContainer,
        text: classes.text,
        listShadow: classes.listShadow,
      }}
      totalCount={totalCount}
    />
  )
}

export default MembershipTableLoyaltyPointsView
