import React from 'react'
import { useSelector } from 'react-redux'

import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import { getWidgetDataIsLoading } from '~/store/duck/landing'

import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import CustomerSatisfactionWidget from './CustomerSatisfactionWidget'

type CustomerSatisfactionProps = {}

const CustomerSatisfaction = ({
  ...rest
}: LandingWidgetProps<CustomerSatisfactionProps>) => {
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.CUSTOMER_SATISFACTION,
    ),
  )

  return (
    <LandingWidget
      canNavigateToDetails={false}
      component={CustomerSatisfactionWidget}
      isLoading={isLoading}
      showSettings={false}
      widthType={WidgetWidthType.FULL_WIDTH}
      {...rest}
    />
  )
}

export default CustomerSatisfaction
