import { ApiError } from '@pbt/pbt-ui-components'

import { TimelineEntryType } from '~/constants/timelineConstants'
import { TimelineItem } from '~/types'

import {
  DELETE_LAB_TEST_ATTACHMENT_FILE,
  DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE,
  DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_TIMELINE,
  FETCH_MORE_ITEMS_FOR_TIMELINE_FAILURE,
  FETCH_MORE_ITEMS_FOR_TIMELINE_SUCCESS,
  FETCH_TIMELINE,
  FETCH_TIMELINE_FAILURE,
  FETCH_TIMELINE_SUCCESS,
  OPTIMISTIC_ATTACHMENT_DELETE,
  OPTIMISTIC_LAB_TEST_FILE_DELETE_FROM_LAB_TEST_CARD,
  RENDER_TIMELINE_CARD_FAILURE,
  RESET_TIMELINE,
  UPDATE_TIMELINE_CLIENT_DATA,
  UPDATE_TIMELINE_DATE_RANGE,
  UPDATE_TIMELINE_FILTERS,
} from './types/timeline'

export const fetchTimeline = (from?: number, to?: number) => ({
  type: FETCH_TIMELINE,
  from,
  to,
})
export const fetchTimelineSuccess = (
  list: TimelineItem[],
  totalCount: number,
) => ({
  type: FETCH_TIMELINE_SUCCESS,
  list,
  totalCount,
})
export const fetchTimelineFailure = (error: ApiError) => ({
  type: FETCH_TIMELINE_FAILURE,
  error,
})

export const fetchMoreItemsForTimeline = (from: number, to: number) => ({
  type: FETCH_MORE_ITEMS_FOR_TIMELINE,
  from,
  to,
})
export const fetchMoreItemsForTimelineSuccess = (
  list: TimelineItem[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_TIMELINE_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForTimelineFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_TIMELINE_FAILURE,
  error,
})

export const updateTimelineFilters = (filters: TimelineEntryType[]) => ({
  type: UPDATE_TIMELINE_FILTERS,
  filters,
})
export const updateTimelineDateRange = (dateRange: (string | null)[]) => ({
  type: UPDATE_TIMELINE_DATE_RANGE,
  dateRange,
})
export const updateTimelineClientData = (clientData: {
  clientId: string
  patientId: string
}) => ({
  type: UPDATE_TIMELINE_CLIENT_DATA,
  clientData,
})
export const resetTimeline = () => ({ type: RESET_TIMELINE })
export const optimisticAttachmentDeleteFromTimeline = (
  attachmentId: string,
) => ({
  type: OPTIMISTIC_ATTACHMENT_DELETE,
  attachmentId,
})
export const optimisticLabTestFileDeleteFromLabTestCard = (fileId: string) => ({
  type: OPTIMISTIC_LAB_TEST_FILE_DELETE_FROM_LAB_TEST_CARD,
  fileId,
})

export const deleteLabTestAttachmentFile = (
  labTestLogId: string,
  groupId: string,
  fileId: string,
) => ({
  type: DELETE_LAB_TEST_ATTACHMENT_FILE,
  labTestLogId,
  groupId,
  fileId,
})

export const deleteLabTestAttachmentFileSuccess = () => ({
  type: DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS,
})
export const deleteLabTestAttachmentFileFailure = (error: ApiError) => ({
  type: DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE,
  error,
})

export const renderCardFailure = (
  error: Error,
  info: { componentStack: string },
) => ({
  type: RENDER_TIMELINE_CARD_FAILURE,
  error,
  info,
})
