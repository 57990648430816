import React, { ErrorInfo } from 'react'
import { connect } from 'react-redux'
import { Location } from 'react-router-dom'
import { compose } from 'ramda'

import { reportUncaughtFailure } from '~/store/duck/errors'

import UnexpectedErrorSplashScreen from './UnexpectedErrorSplashScreen'
import withRouter from './withRouter'

export interface ErrorsBoundaryProps {
  children: React.ReactNode
  location?: Location
  reportUncaughtFailure?: (error: Error, info: ErrorInfo) => void
}

export interface ErrorsBoundaryState {
  error: null | Error
  info: null | ErrorInfo
}

// eslint-disable-next-line react/require-optimization
class ErrorsBoundary extends React.Component<
  ErrorsBoundaryProps,
  ErrorsBoundaryState
> {
  constructor(props: ErrorsBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      info: null,
    }
  }

  componentDidUpdate(prevProps: ErrorsBoundaryProps) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.onClearErrorRequested()
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.reportUncaughtFailure?.(error, info)
    // eslint-disable-next-line react/no-set-state
    this.setState({ error, info })
  }

  onClearErrorRequested() {
    // eslint-disable-next-line react/no-set-state
    this.setState({ error: null, info: null })
  }

  render() {
    return this.state.error ? (
      <UnexpectedErrorSplashScreen
        componentStack={this.state.info!.componentStack}
        message={this.state.error.message}
        stack={this.state.error.stack!}
      />
    ) : (
      this.props.children
    )
  }
}

export default compose(
  connect(null, { reportUncaughtFailure }),
  withRouter,
)(ErrorsBoundary)
