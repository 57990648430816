import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  ImageScalingUtils,
  Nil,
  PatientAvatar,
  PermissionArea,
  PuiTextArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Edit as EditIcon,
  Wings as WingsIcon,
} from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import ApproximateAgeLabel from '~/components/common/labels/ApproximateAgeLabel'
import BreedLabel from '~/components/common/labels/BreedLabel'
import NeuteringLabel from '~/components/common/labels/NeuteringLabel'
import PatientDOBLabel from '~/components/common/labels/PatientDOBLabel'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient, getPatientsIsLoading } from '~/store/reducers/patients'
import { getPatientAge, getPatientStatus } from '~/utils'

const AVATAR_SIZE = 72

const useStyles = makeStyles(
  (theme) => ({
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    nameContainer: {
      lineHeight: '3.9rem',
      overflow: 'hidden',
      paddingLeft: theme.spacing(3),
    },
    patientNameText: {
      wordBreak: 'break-all',
      color: theme.colors.tertiaryText,
    },
    editIcon: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
    },
    column: {
      '&:nth-of-type(odd)': {
        paddingRight: theme.spacing(1.5),
      },
      '&:nth-of-type(even)': {
        paddingLeft: theme.spacing(1.5),
      },
    },
    alertLabelContainer: {
      '&:not(:last-of-type)': {
        marginRight: theme.spacing(1),
      },
    },
    alertLabelRoot: {
      height: 24,
    },
    alertLabelText: {
      fontSize: '1.5rem',
    },
    alertLabelIcon: {
      fontSize: '4rem',
    },
    disabled: {
      opacity: 0.5,
    },
  }),
  { name: 'PatientMainDetails' },
)

interface PatientMainDetailsProps {
  classes?: ClassesType<typeof useStyles>
  editedField?: string | Nil
  noEdit?: boolean
  onEdit: (event: React.MouseEvent) => void
  onUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    isBool?: boolean,
  ) => void
  patientId: string
}

const PatientMainDetails = ({
  patientId,
  noEdit = false,
  onEdit,
  editedField,
  onUpdate,
  classes: classesProp,
}: PatientMainDetailsProps) => {
  const classes = useStyles({ classes: classesProp })
  const patient = useSelector(getPatient(patientId))
  const Species = useSelector(getSpecies)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const isLoading = useSelector(getPatientsIsLoading)
  const { t } = useTranslation('Common')

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  const patientStatus = getPatientStatus(patient)

  return (
    <Grid container item direction="column" wrap="nowrap">
      <Grid container item wrap="nowrap">
        <Grid item>
          <PatientAvatar
            animal={Utils.getConstantName(patient?.species, Species)}
            className={classNames(classes.avatar, {
              [classes.disabled]: patientStatus.disabled,
            })}
            showBoopBadge={patient?.isBoop}
            src={avatarSrc}
          />
        </Grid>
        <Grid
          container
          item
          xs
          className={classes.nameContainer}
          direction="column"
        >
          <Grid container item alignItems="center" wrap="nowrap">
            <Dotdotdot clamp={1}>
              <Text
                align="left"
                className={classNames(classes.patientNameText, {
                  [classes.disabled]: patientStatus.disabled,
                })}
                variant="hero"
              >
                {patient?.name}
              </Text>
            </Dotdotdot>
            {!noEdit && permissions.update && (
              <IconButton
                className={classNames(classes.editIcon, {
                  [classes.disabled]: patientStatus.disabled,
                })}
                size="large"
                onClick={onEdit}
              >
                <EditIcon />
              </IconButton>
            )}
            {patient?.deceased && (
              <Grid item className={classes.alertLabelContainer}>
                <AlertLabel
                  Icon={WingsIcon}
                  classes={{
                    root: classes.alertLabelRoot,
                    icon: classes.alertLabelIcon,
                    text: classes.alertLabelText,
                  }}
                  message={t('Common:DECEASED')}
                  variant="normal"
                />
              </Grid>
            )}
            {!patient?.active && (
              <Grid item className={classes.alertLabelContainer}>
                <AlertLabel
                  classes={{
                    root: classes.alertLabelRoot,
                    text: classes.alertLabelText,
                  }}
                  message={t('Common:INACTIVE_ONE')}
                  variant="normal"
                />
              </Grid>
            )}
          </Grid>
          <Grid container item alignItems="center" spacing={1}>
            {patient?.dateOfBirth && (
              <>
                <Grid item>
                  <ApproximateAgeLabel
                    isApproximate={Boolean(
                      patient.dateOfBirth && patient?.approximateAge,
                    )}
                  >
                    <Text noWrap align="left">
                      {getPatientAge(patient)} (
                      <PatientDOBLabel patient={patient} />)
                    </Text>
                  </ApproximateAgeLabel>
                </Grid>
                <Grid item>
                  <Text variant="body2">{' | '}</Text>
                </Grid>
              </>
            )}
            <Grid item>
              <NeuteringLabel patient={patient} variant="body" />
            </Grid>
            {patient?.breeds?.length && (
              <>
                <Grid item>
                  <Text variant="body2">{' | '}</Text>
                </Grid>
                <Grid item>
                  <BreedLabel patient={patient} variant="body" />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!noEdit && (
        <Grid item my={1}>
          <PuiTextArea
            disabled={!permissions.update}
            isLoading={isLoading && editedField === 'notes'}
            label={t('Common:NOTES')}
            margin="none"
            value={patient?.notes || ''}
            onChange={(event) =>
              onUpdate(event as React.ChangeEvent<HTMLInputElement>, 'notes')
            }
          />
        </Grid>
      )}
    </Grid>
  )
}

export default PatientMainDetails
