import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { Defaults, Text, useInterval } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { PuiPagination } from '~/components/common/lists/table/PuiPagination'
import { EstimateBillingActivityTable } from '~/components/dashboard/clients/balance/table/estimates/EstimateBillingActivityTable'
import {
  fetchEstimatePage,
  getClientBillingActivityEstimateError,
  getEstimateLoading,
  getEstimatePageData,
  getEstimateTotalCount,
  getIsPollingEstimates,
  getShouldRefreshData,
} from '~/store/duck/clientBillingActivityData'
import { getFinanceIsSaving } from '~/store/reducers/finance'

import { CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS } from '../../table/common/tableConstants'

interface EstimatesWidgetProps {
  isExpanded: boolean
  onClickEstimate: (data: GraphqlEstimate) => void
}

export const EstimatesWidget = ({
  isExpanded,
  onClickEstimate,
}: EstimatesWidgetProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const { clientId } = useParams()
  const [offsetValue, setOffsetValue] = useState(0)

  const refresh = useSelector(getShouldRefreshData)
  const estimatePageData = useSelector(getEstimatePageData)
  const estimateTotalCount = useSelector(getEstimateTotalCount)
  const isFetchingEstimate = useSelector(getEstimateLoading)
  const isSavingInvoice = useSelector(getFinanceIsSaving)
  const isPolling = useSelector(getIsPollingEstimates)
  const error = useSelector(getClientBillingActivityEstimateError)

  const isLoading = isPolling ? false : isFetchingEstimate || isSavingInvoice

  useEffect(() => {
    if (clientId && refresh) {
      dispatch(
        fetchEstimatePage({
          clientId,
          offset: offsetValue,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
          isRefreshing: true,
        }),
      )
    }
  }, [clientId, refresh])

  useInterval(() => {
    if (clientId) {
      dispatch(
        fetchEstimatePage({
          clientId,
          offset: offsetValue,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
          isPolling: true,
        }),
      )
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  useEffect(() => {
    if (clientId) {
      dispatch(
        fetchEstimatePage({
          clientId,
          offset: 0,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
        }),
      )
    }
  }, [clientId])

  const handleFetchMore = (offset: number) => {
    if (clientId) {
      setOffsetValue(offset)
      dispatch(
        fetchEstimatePage({
          clientId,
          offset,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
        }),
      )
    }
  }

  if (error) {
    return (
      <Text px={2} py={1} variant="body2">
        {error}
      </Text>
    )
  }

  if (!isLoading && R.isEmpty(estimatePageData)) {
    return (
      <Text px={2} py={1} variant="body2">
        {t('Common:NO_ESTIMATES')}
      </Text>
    )
  }

  return (
    <>
      <EstimateBillingActivityTable
        estimates={estimatePageData}
        estimatesPerPage={CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS}
        isLoading={isLoading}
        onEstimateNameClick={onClickEstimate}
      />
      {!isExpanded && (
        <Box display="flex" justifyContent="flex-end" p={1}>
          <PuiPagination
            itemsPerPage={CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS}
            totalItems={estimateTotalCount}
            onFetchMore={handleFetchMore}
          />
        </Box>
      )}
    </>
  )
}
