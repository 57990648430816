import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PermissionArea, Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import useDialog from '~/utils/useDialog'

const NoContactsScreen = () => {
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const permissions = useSelector(
    getCRUDByArea(
      isCvcRolesEnabled ? PermissionArea.CONTACT : PermissionArea.PERSON,
    ),
  )
  const { t } = useTranslation(['Admin', 'Common'])

  const [openNewContactDialog] = useDialog(DialogNames.CONTACT)

  return (
    <NoItemsScreen
      action={permissions.create ? () => openNewContactDialog() : undefined}
      actionMessage={t('Common:ADD_CONTACT')}
      title={t('Admin:CONTACT.CONTACT_LIST')}
    >
      <Text>{t('Admin:CONTACT.SET_UP_CONTACT_LIST')}</Text>
    </NoItemsScreen>
  )
}

export default NoContactsScreen
