import { Nil } from '@pbt/pbt-ui-components'

import {
  clearRemindersValidationError,
  fetchReminders,
  getRemindersValidationError,
} from '~/store/duck/reminders'
import useErrorAlert from '~/utils/useErrorAlert'

export const useReminderErrorAlert = (patientId: string | Nil) => {
  useErrorAlert({
    errorSelector: getRemindersValidationError,
    onOpenAction: patientId ? fetchReminders({ patientId }) : undefined,
    onCloseAction: clearRemindersValidationError(),
  })
}
