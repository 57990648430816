import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, PuiTextField, useFields } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { BasePracticeDetailsSectionProps, PracticeChewyFields } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
    },
  }),
  { name: 'Chewy' },
)

const Chewy = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()

  const { t } = useTranslation(['Businesses'])

  const kyriosClinicIdPermissions = useSelector(
    getCRUDByArea(PermissionArea.KYRIOS_CLINIC_ID),
  )

  const { fields, reset, validate } = useFields([
    {
      name: 'kyriosId',
      label: t('Businesses:CHEWY.KYRIOS_ID'),
      type: 'text',
      initialValue: business?.kyriosId || '',
    },
  ])

  usePracticeFieldsSection<PracticeChewyFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.CHEWY,
    validate,
    reset,
  })

  const { kyriosId } = fields

  return (
    <Grid className={classes.root}>
      <PuiTextField
        disabled={!kyriosClinicIdPermissions.update}
        field={kyriosId}
        inputProps={{ maxLength: 100 }}
        label={kyriosId.label}
      />
    </Grid>
  )
}

export default Chewy
