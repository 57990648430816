import React from 'react'
import { useTranslation } from 'react-i18next'

import { ShippingAddress } from '~/api/graphql/generated/types'
import { ChewyFullShippingAddress } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyFullShippingAddress/ChewyFullShippingAddress'
import {
  OutlinedSelect,
  OutlinedSelectProps,
} from '~/components/elements/OutlinedSelect/OutlinedSelect'
import { formatShippingAddress } from '~/utils/cvcClient'

export type ChewyShippingAddressSelectProps = Omit<
  OutlinedSelectProps<ShippingAddress>,
  'label' | 'id'
>

export const ChewyShippingAddressSelect = (
  props: ChewyShippingAddressSelectProps,
) => {
  const { t } = useTranslation(['Common'])

  return (
    <OutlinedSelect
      getOptionLabel={formatShippingAddress}
      id="chewy-shipping-address-select"
      label={t('Common:PAYMENTS.SHIPPING_ADDRESS')}
      renderOption={ChewyFullShippingAddress}
      {...props}
    />
  )
}
