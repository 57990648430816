import {
  MutationCreateInvoiceDiscountReasonArgs,
  MutationDeleteInvoiceDiscountReasonArgs,
  MutationUpdateStatusInvoiceDiscountReasonArgs,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  CREATE_INVOICE_DISCOUNT_REASON,
  DELETE_INVOICE_DISCOUNT_REASON,
  UPDATE_INVOICE_DISCOUNT_REASON_STATUS,
} from './graphql/mutations/discountReasons'
import { FETCH_INVOICE_DISCOUNT_REASONS } from './graphql/queries/discountReasons'

export const fetchDiscountReasons = requestQuery({
  query: FETCH_INVOICE_DISCOUNT_REASONS,
  variablesHandler: (_, id) => ({
    businessId: id,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const updateDiscountReasonStatus = requestMutation({
  mutation: UPDATE_INVOICE_DISCOUNT_REASON_STATUS,
  variablesHandler: (
    _: string,
    businessId: string,
    { discountReasonId, active }: MutationUpdateStatusInvoiceDiscountReasonArgs,
  ) => ({
    businessId,
    discountReasonId,
    active,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const createDiscountReason = requestMutation({
  mutation: CREATE_INVOICE_DISCOUNT_REASON,
  variablesHandler: (
    _: string,
    businessId: string,
    { input }: MutationCreateInvoiceDiscountReasonArgs,
  ) => ({
    input,
    businessId,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})

export const deleteDiscountReason = requestMutation({
  mutation: DELETE_INVOICE_DISCOUNT_REASON,
  variablesHandler: (
    _: string,
    businessId: string,
    { discountReasonId }: MutationDeleteInvoiceDiscountReasonArgs,
  ) => ({
    id: discountReasonId,
    businessId,
  }),
  businessIdHeaderOverrider: (_, businessId: string) => businessId,
})
