export const UPDATE_COPARENTS = 'coparents/UPDATE_COPARENTS'
export const CREATE_COPARENT = 'coparents/CREATE_COPARENT'
export const CREATE_COPARENT_FAILURE = 'coparents/CREATE_COPARENT_FAILURE'
export const CREATE_COPARENT_SUCCESS = 'coparents/CREATE_COPARENT_SUCCESS'
export const UPDATE_COPARENT = 'coparents/UPDATE_COPARENT'
export const UPDATE_COPARENT_SUCCESS = 'coparents/UPDATE_COPARENT_SUCCESS'
export const UPDATE_COPARENT_FAILURE = 'coparents/UPDATE_COPARENT_FAILURE'
export const DELETE_COPARENT = 'coparents/DELETE_COPARENT'
export const DELETE_COPARENT_SUCCESS = 'coparents/DELETE_COPARENT_SUCCESS'
export const DELETE_COPARENT_FAILURE = 'coparents/DELETE_COPARENT_FAILURE'
