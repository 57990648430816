import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddButton,
  LanguageUtils,
  NumberUtils,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableColumn,
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import DialogNames from '~/constants/DialogNames'
import { deleteLabTestPrice } from '~/store/actions/labTests'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getPriceUnits, getSpecies } from '~/store/reducers/constants'
import { getLabTestsIsDeleting } from '~/store/reducers/labTests'
import { LabTest, Price } from '~/types'
import { boolToYesNoString } from '~/utils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      height: 50,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      whiteSpace: 'nowrap',
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    leftColumn: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    tableTitle: {
      height: 38,
    },
    root: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'LabTestPricesTable' },
)

export interface LabTestPricesTableProps
  extends ScrollableDataTableProps<Price, false> {
  labTest: LabTest
}

const LabTestPricesTable = ({ labTest, ...rest }: LabTestPricesTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const Species = useSelector(getSpecies)
  const PriceUnits = useSelector(getPriceUnits)
  const permissions = useSelector(getCRUDByArea(PermissionArea.LAB_TEST_PRICE))
  const isDeleting = useSelector(getLabTestsIsDeleting)
  const { t } = useTranslation('Common')

  const mainColumns: ScrollableDataTableColumn<Price>[] = [
    {
      label: t('Common:PRICE_NAME'),
      prop: 'name',
    },
  ]

  const headerRow = [
    { label: t('Common:PRICE') },
    { label: t('Common:UNIT') },
    { label: t('Common:COST') },
    { label: t('Common:SPECIES') },
    { label: t('Common:ALLOW_DISCOUNT') },
    { label: t('Common:TAXABLE') },
    { label: t('Common:ACTIVE_ONE') },
  ]

  const [openLabTestPriceDialog] = useDialog(DialogNames.LAB_TEST_PRICE)

  const { id: labTestId, prices = [] } = labTest

  const handleDelete = (labTestPriceId: string) => {
    dispatch(deleteLabTestPrice(labTestId, labTestPriceId))
  }

  const handleEdit = (labTestPriceId: string) => {
    openLabTestPriceDialog({
      labTest,
      price: Utils.findById(labTestPriceId, prices),
    })
  }

  const handleCreateRequested = () => {
    openLabTestPriceDialog({
      labTest,
    })
  }

  return (
    <>
      <ScrollableDataTable
        duplicateDisabled
        classes={classes}
        data={prices}
        deleteDisabled={!permissions.delete}
        editDisabled={!permissions.update}
        headerRow={headerRow}
        isDeleting={isDeleting}
        mainColumns={mainColumns}
        onDelete={handleDelete}
        onEdit={handleEdit}
        {...rest}
      >
        {({ item }) => {
          const cells = [
            NumberUtils.formatMoney(item.price),
            LanguageUtils.getConstantTranslatedName(item.unitId, PriceUnits),
            NumberUtils.formatMoney(item.cost),
            LanguageUtils.getConstantTranslatedName(item.speciesId, Species),
            boolToYesNoString(item.discountAllowed),
            boolToYesNoString(item.taxable),
            boolToYesNoString(item.active),
          ]

          return cells.map((cell, index) => (
            <TableCell
              className={classes.tableCell}
              key={['price', item.id, headerRow[index]?.label].join('-')}
            >
              {cell}
            </TableCell>
          ))
        }}
      </ScrollableDataTable>
      {permissions.create && (
        <AddButton
          inline
          addText={t('Common:ADD_PRICE')}
          onAdd={handleCreateRequested}
        />
      )}
    </>
  )
}

export default LabTestPricesTable
