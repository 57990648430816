import i18n from '~/locales/i18n'

export enum MigrationSteps {
  SESSION_PICKER = 'SESSION_PICKER',
  SESSION_CREATION = 'SESSION_CREATION',
  SESSION_OVERVIEW = 'SESSION_OVERVIEW',
  SESSION_FILE_VIEW = 'SESSION_FILE_VIEW',
  SESSION_FILE_UPLOAD = 'SESSION_FILE_UPLOAD',
  COVETRUS_CONFIG = 'COVETRUS_CONFIG',
  IMPORT_REQUEST_PICKER = 'IMPORT_REQUEST_PIKCER',
  IMPORT_REQUEST_OVERVIEW = 'IMPORT_REQUEST_OVERVIEW',
  IMPORT_TASK_CREATION = 'IMPORT_TASK_CREATION',
  DATA_STANDARDIZATION = 'DATA_STANDARDIZATION',
}

export enum MigrationStatuses {
  EXPORTER_DOWNLOADED = 'EXPORTER_DOWNLOADED',
  DATA_IS_READY = 'DATA_IS_READY',
  ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
  ANALYSIS_DONE = 'ANALYSIS_DONE',
  REVIEW_EXCEPTIONS = 'REVIEW_EXCEPTIONS',
  IMPORT_IS_READY = 'IMPORT_IS_READY',
  CONFIRM_IMPORT_SETTINGS = 'CONFIRM_IMPORT_SETTINGS',
  IMPORT_IN_PROGRESS = 'IMPORT_IN_PROGRESS',
  IMPORT_DONE = 'IMPORT_DONE',
  STOPPED = 'STOPPED',
  EXPORT_DONE = 'EXPORT_DONE',
  PREPROCESSING_IN_PROGRESS = 'PREPROCESSING_IN_PROGRESS',
  PREPROCESSING_DONE = 'PREPROCESSING_DONE',
  PREPROCESSING_FAILED = 'PREPROCESSING_FAILED',
  UPLOADER = 'UPLOADER',
}

export enum SessionsTypes {
  DEFAULT = 'DEFAULT',
  RHAPSODY_ENTERPRISE = 'RHAPSODY_ENTERPRISE',
}

export const MigrationStatusPostfix = {
  [MigrationStatuses.EXPORTER_DOWNLOADED]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.EXPORTER_DOWNLOADED',
  ),
  [MigrationStatuses.DATA_IS_READY]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.DATA_IS_READY',
  ),
  [MigrationStatuses.ANALYSIS_IN_PROGRESS]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.ANALYSIS_IN_PROGRESS',
  ),
  [MigrationStatuses.ANALYSIS_DONE]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.ANALYSIS_DONE',
  ),
  [MigrationStatuses.REVIEW_EXCEPTIONS]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.REVIEW_EXCEPTIONS',
  ),
  [MigrationStatuses.IMPORT_IS_READY]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.IMPORT_IS_READY',
  ),
  [MigrationStatuses.IMPORT_IN_PROGRESS]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.IMPORT_IN_PROGRESS',
  ),
  [MigrationStatuses.IMPORT_DONE]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.IMPORT_DONE',
  ),
  [MigrationStatuses.STOPPED]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.STOPPED',
  ),
  [MigrationStatuses.EXPORT_DONE]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.EXPORT_DONE',
  ),
  [MigrationStatuses.PREPROCESSING_IN_PROGRESS]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.PREPROCESSING_IN_PROGRESS',
  ),
  [MigrationStatuses.PREPROCESSING_DONE]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.PREPROCESSING_DONE',
  ),
  [MigrationStatuses.PREPROCESSING_FAILED]: i18n.t(
    'Constants:MIGRATION.STATUS_POSTFIX.PREPROCESSING_FAILED',
  ),
}

export const disabledActionStatusesSet = new Set([
  MigrationStatuses.EXPORTER_DOWNLOADED,
  MigrationStatuses.IMPORT_DONE,
  MigrationStatuses.STOPPED,
])

export const MIGRATION_STATUS_POLLING_INTERVAL = 10000
export const MIGRATION_EXCEPTIONS_BATCH_SIZE = 50

export const ExceptionColumnType = {
  STRING: 'STRING',
  SELECT: 'SELECT',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
}

export const MESSAGE_WARN_MISSING_MAPPING = i18n.t(
  'Constants:MIGRATION.MESSAGE_WARN_MISSING_MAPPING',
)
