import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import { LinkButton, PermissionArea } from '@pbt/pbt-ui-components'

import {
  deleteConnectedDevice,
  getConnectedDevicesIsFetching,
  getConnectedDevicesIsLoading,
} from '~/store/duck/connectedDevices'
import { getCRUDByArea } from '~/store/reducers/auth'
import { ConnectedDevice } from '~/types'

interface ConnectedDevicesConnectionColumnItem extends ConnectedDevice {}

const ConnectedDevicesConnectionColumn = (
  item: ConnectedDevicesConnectionColumnItem,
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isFetching = useSelector(getConnectedDevicesIsFetching)
  const isLoading = useSelector(getConnectedDevicesIsLoading)
  const supportPermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))

  const handleConnectionChange = () => {
    dispatch(deleteConnectedDevice(item.id))
  }

  if (!supportPermissions.delete) {
    return <></>
  }

  return isFetching ? (
    <Skeleton height={20} sx={{ marginLeft: 'auto' }} width={100} />
  ) : (
    <LinkButton
      disabled={isLoading}
      loading={isLoading}
      sx={{ marginLeft: 'auto' }}
      onClick={handleConnectionChange}
    >
      {t('Common:DISCONNECT_ACTION')}
    </LinkButton>
  )
}

export default ConnectedDevicesConnectionColumn
