import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, IconButton } from '@mui/material'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import {
  DateAndTimePickerState,
  DatePickerState,
  DocumentElementStateItem,
  DocumentElementType,
} from '~/types'
import useDialog from '~/utils/useDialog'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface DocumentDateTimePickerProps {
  documentElement: DatePickerState | DateAndTimePickerState
  isDragging?: boolean
  onDelete?: () => void
  onUpdate?: (documentElement: Partial<DocumentElementStateItem>) => void
}

export const DocumentDateTimePicker = ({
  documentElement,
  isDragging,
  onUpdate,
  onDelete,
}: DocumentDateTimePickerProps) => {
  const { t } = useTranslation('Common')

  const [openAddDateTimePickerDialog] = useDialog(
    DialogNames.ADD_DATE_TIME_PICKER,
  )

  const isDateOnlyPicker =
    documentElement.type === DocumentElementType.DATE_PICKER

  const title = isDateOnlyPicker
    ? t('Common:DATE_TIME')
    : t('Common:DATE_AND_TIME')

  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={title}
        onDelete={onDelete}
        onEdit={
          onUpdate
            ? () =>
                openAddDateTimePickerDialog({
                  isDateOnlyDialog: isDateOnlyPicker,
                  onSave: onUpdate,
                  documentElement,
                })
            : undefined
        }
      >
        <Grid container p={2}>
          <Grid item pb={1} xs={12}>
            <Typography.H2>{documentElement.label || ''}</Typography.H2>
          </Grid>
          <Grid
            container
            alignItems="center"
            borderBottom={(theme) => theme.constants.outlinedInputBorder}
            height={40}
            justifyContent="space-between"
            maxWidth={150}
          >
            <Grid item>
              <Typography.ParagraphLg color="grayGray2">
                {t('Common:DATE_TIME')}
              </Typography.ParagraphLg>
            </Grid>
            <Grid item>
              <IconButton disabled sx={{ paddingRight: 0 }}>
                <Toggle />
              </IconButton>
            </Grid>
          </Grid>
          {Boolean(!isDateOnlyPicker) && (
            <Grid
              container
              alignItems="center"
              borderBottom={(theme) => theme.constants.outlinedInputBorder}
              height={40}
              justifyContent="space-between"
              marginLeft={2}
              maxWidth={150}
            >
              <Grid item>
                <Typography.ParagraphLg color="grayGray2">
                  {t('Common:TIME')}
                </Typography.ParagraphLg>
              </Grid>
              <Grid item>
                <IconButton disabled sx={{ paddingRight: 0 }}>
                  <Toggle />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DocumentElementAccordion>
    </Box>
  )
}
