import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  CircularProgressOverlay,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import {
  fetchWellnessPlanSavingsDataForInvoice,
  fetchWellnessPlanSavingsDataForSoap,
} from '~/store/actions/wellnessPlans'
import {
  getWellnessPlanSavingsData,
  getWellnessPlansIsFetching,
} from '~/store/reducers/wellnessPlans'

import InvoiceSavingsBanner from '../invoices/InvoiceSavingsBanner'
import SoapSavingsBanner from '../soap/check-out/SoapSavingsBanner'
import MembershipSavingsSummaryTable from './MembershipSavingsSummaryTable'
import MembershipSavingsTable from './MembershipSavingsTable'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 'calc(100% - 64px)',
    },
    title: {
      padding: theme.spacing(3, 3, 1, 3),
    },
    dialogTitle: {
      borderBottom: 'none',
      padding: 0,
    },
    actions: {
      padding: 0,
    },
    banner: {
      marginBottom: theme.spacing(2),
    },
    overlay: {
      height: `calc(100% - ${theme.spacing(8)})`,
    },
  }),
  { name: 'WellnessPlanSavingsDialog' },
)

export interface WellnessPlanSavingsDialogProps extends BasePuiDialogProps {
  appointmentId: string
  invoiceId: string
}

const WellnessPlanSavingsDialog = ({
  appointmentId,
  invoiceId,
  open,
  onClose,
}: WellnessPlanSavingsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const savingsData = useSelector(getWellnessPlanSavingsData)
  const isFetching = useSelector(getWellnessPlansIsFetching)
  const { t } = useTranslation('Dialogs')

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchWellnessPlanSavingsDataForSoap(appointmentId))
    }
  }, [appointmentId])

  useEffect(() => {
    if (invoiceId) {
      dispatch(fetchWellnessPlanSavingsDataForInvoice(invoiceId))
    }
  }, [invoiceId])

  return (
    <PuiDialog
      actions={<MembershipSavingsSummaryTable savingsData={savingsData} />}
      aria-labelledby="wellness-plan-savings-dialog"
      classes={{
        title: classes.title,
        dialogTitle: classes.dialogTitle,
        paper: classes.paper,
        actions: classes.actions,
      }}
      header={
        !isFetching && (
          <>
            {appointmentId && (
              <SoapSavingsBanner hideLink className={classes.banner} />
            )}
            {invoiceId && (
              <InvoiceSavingsBanner hideLink className={classes.banner} />
            )}
          </>
        )
      }
      open={open}
      scroll="paper"
      title={t('Dialogs:WELLNESS_PLAN_SAVINGS_DIALOG.TITLE')}
      onClose={onClose}
    >
      <CircularProgressOverlay className={classes.overlay} open={isFetching} />
      <MembershipSavingsTable savingsData={savingsData} />
    </PuiDialog>
  )
}

export default WellnessPlanSavingsDialog
