import { DependencyList, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  Business,
  Defaults,
  FieldObject,
  FieldProp,
  ValidateOptions,
} from '@pbt/pbt-ui-components'

import { PracticeDetailsPanels } from '~/components/dashboard/admin/general/practices/practices'
import {
  getIsValidating,
  initializeFieldsFromSection,
  updateBusinessFieldsFromSection,
  validateBusinessSectionFailure,
  validateBusinessSectionSuccess,
} from '~/store/duck/businessSettings'
import {
  getBusinessIsLoading,
  getBusinessIsSaving,
} from '~/store/reducers/businesses'

import { getPluckedFieldsByFieldKey } from '.'
import { getParsedFields, ParsedField } from './businessSettings'
import useFieldsChanged from './useFieldsChanged'

export type UsePracticeFieldsSectionType = {
  business: Business | undefined
  fields: FieldObject
  onSave?: () => void
  parentSectionName?: PracticeDetailsPanels
  parsedFields?: ParsedField
  reset?: (newFields?: FieldProp[] | undefined) => void
  resetDependencies?: DependencyList
  sectionName: PracticeDetailsPanels
  softUpdate?: boolean
  validate?: (options?: ValidateOptions | undefined) => boolean
}

export const usePracticeFieldsSection = <SectionFieldsType extends object>({
  sectionName,
  fields,
  parsedFields,
  business,
  reset,
  validate,
  onSave,
  resetDependencies,
  parentSectionName,
  softUpdate,
}: UsePracticeFieldsSectionType) => {
  const dispatch = useDispatch()

  const isValidating = useSelector(getIsValidating(business?.id))
  const isSaving = useSelector(getBusinessIsSaving)
  const isLoading = useSelector(getBusinessIsLoading)

  const initialValueFields = getPluckedFieldsByFieldKey<SectionFieldsType>(
    fields,
    'initialValue',
  )
  const valueFields = getPluckedFieldsByFieldKey<SectionFieldsType>(
    fields,
    'value',
  )

  const allParsedFields = getParsedFields<SectionFieldsType>(
    fields,
    parsedFields,
  )
  const initialParsedFields = getParsedFields<SectionFieldsType>(
    fields,
    parsedFields,
    'initialValue',
  )

  const onValidate = () => {
    if (!validate || !business) {
      return
    }

    // This is important for sections that do not match the real section name (e.g. LAB_INTEGRATIONS_IDEXX),
    // so the invalidPanels will work properly opening just the invalid ones
    const sectionRealName = parentSectionName || sectionName

    if (validate({ silent: !isValidating })) {
      dispatch(validateBusinessSectionSuccess(business.id, sectionRealName))
    } else {
      dispatch(validateBusinessSectionFailure(business.id, sectionRealName))
    }
  }

  const debounceUpdateFieldsFromSection = useDebouncedCallback(
    (
      fieldsToUpdate: SectionFieldsType | ParsedField,
      hasChangedProp: boolean,
    ) => {
      if (!business) {
        return
      }

      const hasChanged = softUpdate ?? hasChangedProp

      if (fieldsToUpdate) {
        dispatch(
          updateBusinessFieldsFromSection<SectionFieldsType | ParsedField>(
            business.id,
            fieldsToUpdate,
            sectionName,
            hasChanged,
          ),
        )
      }
    },
    Defaults.DEBOUNCE_ACTION_TIME,
    { leading: true },
  )
  const debounceValidateSection = useDebouncedCallback(
    onValidate,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const fieldsHasChanged = useMemo(
    () => !R.equals(initialValueFields, valueFields),
    [...Object.values(initialValueFields), ...Object.values(valueFields)],
  )

  // Every change user enters we need to update fields values for that specific section
  // with the updated data. Then it will validate *silently*, only when there's actually a change
  // to ensure that we don't send any wrong data to BE accidentally.
  useFieldsChanged(() => {
    if (fields) {
      debounceUpdateFieldsFromSection(allParsedFields, fieldsHasChanged)
      debounceValidateSection()
    }
  }, fields)

  useEffect(() => {
    if (onSave && isSaving && validate?.() && fieldsHasChanged) {
      onSave()
    }
  }, [isSaving])

  // When initializing form data, we need to update STORE states
  useEffect(() => {
    if (!business || isLoading) {
      return
    }

    if (!isSaving) {
      dispatch(
        initializeFieldsFromSection<SectionFieldsType>(
          business.id,
          initialParsedFields,
          sectionName,
        ),
      )
    }
  }, [business?.id, isSaving, isLoading])

  // When someone has triggered a validation (e.g.: onSubmit) we need to call validate functions NOT silently
  // to update REDUX store states (*important to populate INVALID_PANELS and check whether the form is valid)
  useEffect(() => {
    if (isValidating) {
      onValidate()
    }
  }, [isValidating])

  // When business has changed or saved we need to trigger reset fn
  useEffect(
    () => {
      if (reset && !isSaving) {
        reset()
      }
    },
    resetDependencies ? [...resetDependencies, isSaving] : [business, isSaving],
  )
}
