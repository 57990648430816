import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, Fab, Grid, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiPopper, PuiTextField, Utils } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    popper: {
      maxWidth: 310,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.headerTopBar, 'above'),
    },
    content: {
      minWidth: 250,
      minHeight: 100,
      maxHeight: 365,
      overflowY: 'auto',
    },
    buttonsContainer: {
      borderTop: theme.constants.tabBorder,
    },
    button: {
      boxShadow: 'none',
      width: '100%',
      height: 40,
      borderRadius: 0,
      textTransform: 'none',
    },
    clearButton: {
      textDecoration: 'underline',
      fontSize: '1.4rem',
      backgroundColor: theme.colors.badgeColor,
      color: theme.colors.tabLabel,
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: theme.colors.badgeColor,
      },
    },
    applyButton: {
      fontWeight: 500,
      color: theme.colors.profileText,
      backgroundColor: theme.colors.title,
      '&:hover': {
        backgroundColor: theme.colors.title,
      },
    },
    applyButtonNotActive: {
      backgroundColor: theme.colors.selectedOption,
    },
    searchContainer: {
      padding: theme.spacing(1, 2),
      borderBottom: theme.constants.tabBorder,
    },
    spinner: {
      margin: `${theme.spacing(8)} auto`,
    },
  }),
  { name: 'BaseFilter' },
)

export type FilterOption = {
  humanReadable: string
  value: any
}

export interface BaseFilterProps {
  anchorEl?: HTMLElement | null
  children: any
  hasSelectedOption?: boolean
  includeSearch: boolean
  isLoading?: boolean
  onApply: () => void
  onClear: () => void
  onClose: (event: any) => void
  open: boolean
  searchTerm?: string
  setSearchTerm: (term: string) => void
}

const BaseFilter = ({
  includeSearch = false,
  searchTerm,
  setSearchTerm,
  hasSelectedOption = false,
  open = false,
  anchorEl,
  onClose,
  onClear,
  onApply,
  children,
  isLoading = false,
}: BaseFilterProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [applyButtonActive, setApplyButtonActive] = useState(false)

  useEffect(() => {
    if (hasSelectedOption) {
      setApplyButtonActive(true)
    }
  }, [hasSelectedOption])

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      className={classes.popper}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      open={open}
      placement="bottom-start"
      onClose={onClose}
    >
      {includeSearch && (
        <Grid item xs className={classes.searchContainer}>
          <PuiTextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={t('Common:SEARCH_ACTION')}
            value={searchTerm}
            onChange={Utils.handleFormTextInput(setSearchTerm)}
          />
        </Grid>
      )}
      <Grid container item className={classes.content}>
        {isLoading ? (
          <CircularProgress className={classes.spinner} />
        ) : (
          children
        )}
      </Grid>
      <Grid container item className={classes.buttonsContainer}>
        <Grid item xs>
          <Fab
            className={classNames(classes.button, classes.clearButton)}
            disabled={isLoading}
            onClick={onClear}
          >
            {t('Common:CLEAR_ACTION')}
          </Fab>
        </Grid>
        <Grid item xs>
          <Fab
            className={classNames(classes.button, classes.applyButton, {
              [classes.applyButtonNotActive]: !applyButtonActive,
            })}
            disabled={isLoading}
            onClick={onApply}
          >
            {t('Common:APPLY_ACTION')}
          </Fab>
        </Grid>
      </Grid>
    </PuiPopper>
  )
}

export default BaseFilter
