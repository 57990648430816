import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

import TermsAndConditionsItems from './TermsAndConditionsItems'

const useStyles = makeStyles(
  (theme) => ({
    firstSection: {},
    firstSubtitle: {},
    firstText: {},
    title: {
      textAlign: 'center',
    },
    subtitle: {},
    subtitleSecondary: {},
    text: {},
    sections: {},
    table: {
      borderCollapse: 'collapse',
      marginTop: theme.spacing(3),
    },
    cell: {
      border: '1px solid black',
      padding: theme.spacing(0, 1),
    },
    iconCell: {
      textAlign: 'center',
    },
    checkIcon: {
      color: '#49b150',
    },
    closeIcon: {
      color: '#ec2b00',
    },
    wideCell: {
      width: '60%',
    },
    halfCell: {
      width: '30%',
    },
  }),
  { name: 'TermsAndConditionsContentUSAEulaV3' },
)

const getArrayTextJoined = (arrayText: Array<string> | string) =>
  (Array.isArray(arrayText) && arrayText.join(' ')) || ''

export interface TermsAndConditionsContentUSAEulaV3Props {
  classes: ClassesType<typeof useStyles>
  showTitle?: boolean
}

interface CollectedInformationTableRow {
  CATEGORY: string
  COLLECTED: string
  TEXT: string
}

interface SellInformationTableRows {
  CATEGORY: string
  DISCLOSURES: string
}

export const TermsAndConditionsContentUSAEulaV3 = ({
  classes: classesProp,
  showTitle,
}: TermsAndConditionsContentUSAEulaV3Props) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation([
    'TermsAndConditionsUSAEulaV3',
    'PrivacyNoticeUSA',
  ])

  const firstMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.DEFINITIONS.TEXT',
  )
  const secondMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.HOSTED_SERVICES.TEXT',
  )
  const thirdMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.DATA.TEXT',
  )
  const fourthMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.INTELLECTUAL_PROPERTY.TEXT',
  )
  const fifthMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.CONFIDENTIALITY.TEXT',
  )
  const sixthMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.REPRESENTATIONS_AND_WARRANTIES.TEXT',
  )
  const seventhMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.WARRANTY_DISCLAIMER.TEXT',
  )
  const eighthMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.LIMITATION_OF_LIABILITY.TEXT',
  )
  const ninthMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.TERM_AND_TERMINATION.TEXT',
  )
  const tenthMainTextArr = t(
    'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.MISCELLANEOUS_PROVISIONS.TEXT',
  )

  const TermsAndConditionsList = [
    {
      id: 1,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.DEFINITIONS.SUBTITLE',
      ),
      text: getArrayTextJoined(firstMainTextArr),
    },
    {
      id: 2,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.HOSTED_SERVICES.SUBTITLE',
      ),
      text: getArrayTextJoined(secondMainTextArr),
    },
    {
      id: 3,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.DATA.SUBTITLE',
      ),
      text: getArrayTextJoined(thirdMainTextArr),
    },
    {
      id: 4,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.INTELLECTUAL_PROPERTY.SUBTITLE',
      ),
      text: getArrayTextJoined(fourthMainTextArr),
    },
    {
      id: 5,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.CONFIDENTIALITY.SUBTITLE',
      ),
      text: getArrayTextJoined(fifthMainTextArr),
    },
    {
      id: 6,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.REPRESENTATIONS_AND_WARRANTIES.SUBTITLE',
      ),
      text: getArrayTextJoined(sixthMainTextArr),
    },
    {
      id: 7,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.WARRANTY_DISCLAIMER.SUBTITLE',
      ),
      text: getArrayTextJoined(seventhMainTextArr),
    },
    {
      id: 8,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.LIMITATION_OF_LIABILITY.SUBTITLE',
      ),
      text: getArrayTextJoined(eighthMainTextArr),
    },
    {
      id: 9,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.TERM_AND_TERMINATION.SUBTITLE',
      ),
      text: getArrayTextJoined(ninthMainTextArr),
    },
    {
      id: 10,
      subtitle: t(
        'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SECTIONS.MISCELLANEOUS_PROVISIONS.SUBTITLE',
      ),
      text: getArrayTextJoined(tenthMainTextArr),
    },
  ]

  const PrivacyNoticeList = [
    {
      id: 1,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.HELLO.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.HELLO.TEXT'),
      ),
    },
    {
      id: 2,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.INFORMATION_WE_COLLECT.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.INFORMATION_WE_COLLECT.TEXT',
        ),
      ),
    },
    {
      id: 3,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.THIRD_PARTY_USE_OF_COOKIES.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.THIRD_PARTY_USE_OF_COOKIES.TEXT',
        ),
      ),
    },
    {
      id: 4,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.HOW_WE_USE.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.HOW_WE_USE.TEXT'),
      ),
    },
    {
      id: 5,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.DISCLOSURE.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.DISCLOSURE.TEXT'),
      ),
    },
    {
      id: 6,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.YOUR_INFORMATION.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.YOUR_INFORMATION.TEXT',
        ),
      ),
    },
    {
      id: 7,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.ACCESSING.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.ACCESSING.TEXT'),
      ),
    },
    {
      id: 8,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CALIFORNIA_PRIVACY.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CALIFORNIA_PRIVACY.TEXT',
        ),
      ),
    },
    {
      id: 9,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.DATA_SECURITY.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.DATA_SECURITY.TEXT',
        ),
      ),
    },
    {
      id: 10,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CHANGES.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CHANGES.TEXT'),
      ),
    },
    {
      id: 11,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CHILDREN.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CHILDREN.TEXT'),
      ),
    },
    {
      id: 12,
      subtitle: t(
        'PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CONTACT.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.SECTIONS.CONTACT.TEXT'),
      ),
    },
  ]

  const CaliforniaPrivacyNoticeList = [
    {
      id: 1,
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.RESIDENTS.TEXT',
        ),
      ),
    },
    {
      id: 2,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION.TEXT',
        ),
      ),
    },
  ]

  const CaliforniaPrivacyNoticeList2 = [
    {
      id: 1,
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_TABLE_CONCLUSION.TEXT',
        ),
      ),
    },
    {
      id: 2,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.USE_OF_INFORMATION.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.USE_OF_INFORMATION.TEXT',
        ),
      ),
    },
    {
      id: 3,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.SHARING.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.SHARING.TEXT',
        ),
      ),
    },
  ]

  const CaliforniaPrivacyNoticeList3 = [
    {
      id: 1,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.USE_OF_INFORMATION.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.USE_OF_INFORMATION.TEXT',
        ),
      ),
    },
    {
      id: 2,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.RIGHTS.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.RIGHTS.TEXT',
        ),
      ),
    },
    {
      id: 3,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.NONE_DISCRIMINATION.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.NONE_DISCRIMINATION.TEXT',
        ),
      ),
    },
    {
      id: 4,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.OTHER_RIGHTS.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.OTHER_RIGHTS.TEXT',
        ),
      ),
    },
    {
      id: 5,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.CHANGES.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.CHANGES.TEXT',
        ),
      ),
    },
    {
      id: 6,
      subtitle: t(
        'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.CONTACT.SUBTITLE',
      ),
      text: getArrayTextJoined(
        t(
          'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.CONTACT.TEXT',
        ),
      ),
    },
  ]

  const collectedInformationTableHeaders: string[] = t(
    'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_TABLE.HEADERS',
  )
  const collectedInformationTableRows: CollectedInformationTableRow[] = t(
    'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_TABLE.ROWS',
  )

  const sellInformationTableHeaders: string[] = t(
    'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_SELL_TABLE.HEADERS',
  )
  const sellInformationTableSubheaders: string[] = t(
    'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_SELL_TABLE.SUBHEADERS',
  )
  const sellInformationTableRows: SellInformationTableRows[] = t(
    'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_SELL_TABLE.ROWS',
  )
  const sellInformationNoneString: string[] = t(
    'PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.SECTIONS.INFORMATION_SELL_TABLE.SALES_NONE',
  )

  return (
    <Grid container direction="column">
      <Grid container className={classes.firstSection} direction="column">
        {showTitle && (
          <Text align="left" className={classes.title} variant="hero">
            {t(
              'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.TITLE',
            )}
          </Text>
        )}

        <Text
          strong
          align="left"
          className={classes.subtitle}
          variant="subheading"
        >
          {t(
            'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SUBTITLE',
          )}
        </Text>
        <Text
          strong
          align="left"
          className={classes.subtitleSecondary}
          variant="subheading"
        >
          {t(
            'TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.SUBTITLE_2',
          )}
        </Text>
        <Text
          className={classNames(classes.text, {
            [classes.firstText]: showTitle,
          })}
        >
          <Trans i18nKey="TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_CONTENT.TEXT">
            End User understands, acknowledges, and agrees that these{' '}
            <strong>Terms and Conditions</strong>(<strong>“T&Cs”</strong>) shall
            govern End User’s use of the Platform (as defined below) and any
            related Services (as defined below) provided by Petabyte Technology
            Inc. (<strong>“Petabyte”</strong>), a Delaware corporation with a
            principal place of business located at 1110 112th Street NE, Suite
            500, Bellevue, WA 98004. By opening an Account (as defined below),
            End User represents and warrants that it has read and understood the
            following T&Cs and End User agrees to be bound by them and to comply
            with all applicable laws and regulations regarding its use of the
            Platform and Hosted Services, and End User acknowledges that these
            T&Cs constitute a binding and enforceable legal contract between End
            User and Petabyte (each a <strong>“Party”</strong> and collectively
            referred to as the <strong>“Parties”</strong>).
          </Trans>
        </Text>
      </Grid>

      <Grid container className={classes.sections} direction="column">
        <TermsAndConditionsItems
          classes={classes}
          list={TermsAndConditionsList}
        />
      </Grid>

      <Grid container className={classes.sections} direction="column" pt={6}>
        <Text strong align="left" className={classes.title} variant="hero">
          {t('PrivacyNoticeUSA:PRIVACY_NOTICE_CONTENT.TITLE')}
        </Text>
        <TermsAndConditionsItems classes={classes} list={PrivacyNoticeList} />
      </Grid>

      <Grid container className={classes.sections} direction="column" pt={6}>
        <Text strong align="left" className={classes.title} variant="hero">
          {t('PrivacyNoticeUSA:CALIFORNIA_PRIVACY_RIGHTS_CONTENT.TITLE')}
        </Text>
        <TermsAndConditionsItems
          classes={classes}
          list={CaliforniaPrivacyNoticeList}
        />
        <table className={classes.table}>
          <tbody>
            <tr>
              {collectedInformationTableHeaders.map((header, index) => (
                <th
                  className={classNames(
                    classes.cell,
                    index === 1 && classes.wideCell,
                  )}
                  key={header}
                >
                  <Text strong variant="body2">
                    {header}
                  </Text>
                </th>
              ))}
            </tr>
            {collectedInformationTableRows.map((row) => (
              <tr key={row.CATEGORY}>
                <td className={classes.cell}>
                  <Text variant="body2">{row.CATEGORY}</Text>
                </td>
                <td className={classes.cell}>
                  <Text variant="body2">{getArrayTextJoined(row.TEXT)}</Text>
                </td>
                <td className={classNames(classes.cell, classes.iconCell)}>
                  {row.COLLECTED ? (
                    <CheckIcon className={classes.checkIcon} />
                  ) : (
                    <CloseIcon className={classes.closeIcon} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <TermsAndConditionsItems
          classes={classes}
          list={CaliforniaPrivacyNoticeList2}
        />

        <table className={classes.table}>
          <tbody>
            <tr>
              {sellInformationTableHeaders.map((header, index) => (
                <th
                  className={classes.cell}
                  colSpan={index === 1 ? 2 : undefined}
                  key={header}
                  rowSpan={index === 0 ? 2 : undefined}
                >
                  <Text strong variant="body2">
                    {header}
                  </Text>
                </th>
              ))}
            </tr>
            <tr>
              {sellInformationTableSubheaders.map((subheader) => (
                <th
                  className={classNames(classes.cell, classes.halfCell)}
                  key={subheader}
                >
                  <Text strong variant="body2">
                    {subheader}
                  </Text>
                </th>
              ))}
            </tr>
            {sellInformationTableRows.map((row) => (
              <tr key={row.CATEGORY}>
                <td className={classes.cell}>
                  <Text variant="body2">{row.CATEGORY}</Text>
                </td>
                <td className={classes.cell}>
                  <Text variant="body2">
                    {row.DISCLOSURES || sellInformationNoneString}
                  </Text>
                </td>
                <td className={classes.cell}>
                  <Text variant="body2">{sellInformationNoneString}</Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <TermsAndConditionsItems
          classes={classes}
          list={CaliforniaPrivacyNoticeList3}
        />
      </Grid>
    </Grid>
  )
}
