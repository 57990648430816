import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { DateUtils, moment, Nil, TextInteractive } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { getAvailabilityRule } from '~/store/reducers/availabilityRules'
import { AvailabilityRule } from '~/types'

import {
  getDateProximity,
  getShortLocaleDaysString,
  getTimeRangeString,
} from './availabilityRulesUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        height: 87,
        padding: theme.spacing(1, 2),
      },
      overflow: 'hidden',
      border: theme.constants.tableBorder,
    },
    selectedRow: {
      border: theme.constants.tableBorderSelected,
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      lineHeight: '2.1rem',
    },
    ruleNameTextSelected: {
      color: theme.colors.tabSelected,
    },
    dateProximityText: {
      color: theme.colors.link,
    },
    ruleNameTextContainer: {
      lineHeight: '2.1rem',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
    mainInfoContainer: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        borderBottom: theme.constants.tableBorder,
      },
    },
    sideInfoContainer: {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
      },
    },
    expandedColumn: {
      maxWidth: '66.666%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    collapsedColumn: {
      maxWidth: '0%',
      maxHeight: '100%',
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    expandedMainColumn: {
      transition: theme.transitions.create(['max-width', 'flex-basis'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen / 2,
        delay: 100,
      }),
    },
    listShadow: {
      '&:hover': {
        boxShadow: theme.constants.listItemShadow,
      },
    },
    sideInfoWrapper: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  }),
  { name: 'AvailabilityRulesTableRow' },
)

const getWeekDays = (params?: AvailabilityRule['recurrenceParams']) => {
  if (
    params !== undefined &&
    params.days !== undefined &&
    Array.isArray(params.days)
  ) {
    return getShortLocaleDaysString(params.days)
  }
  return i18n.t('Common:UNKNOWN')
}

const getRuleRecurrenceString = (
  type?: AvailabilityRule['recurrenceType'],
  params?: AvailabilityRule['recurrenceParams'],
) => {
  if (!type?.name) {
    return ''
  }
  switch (type.name) {
    case 'Weekly':
    case 'Days of week':
      const days = getWeekDays(params)
      return `${i18n.t('Time:LABEL.WEEKLY')}: ${days}`
    default:
      return type.name
  }
}

export interface AvailabilityRulesTableRowProps {
  availabilityRuleId?: string | Nil
  isLoading?: boolean
  onClick: (availabilityRule: AvailabilityRule) => void
  rowFocused?: string
}

const AvailabilityRulesTableRow = ({
  rowFocused,
  availabilityRuleId,
  isLoading,
  onClick,
}: AvailabilityRulesTableRowProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const availabilityRule = useSelector(getAvailabilityRule(availabilityRuleId))

  const isFocusedRow =
    availabilityRule?.id && rowFocused === availabilityRule.id
  const isExpanded = !R.isNil(rowFocused)

  const startTime = moment(
    availabilityRule?.scheduledStartTime,
    'HH:mm',
    availabilityRule?.timezone,
  )
  const endTime = moment(
    availabilityRule?.scheduledEndTime,
    'HH:mm',
    availabilityRule?.timezone,
  )

  return (
    <Grid
      container
      item
      alignContent="flex-start"
      alignItems={isMobile ? 'flex-start' : 'center'}
      className={classNames(classes.root, {
        [classes.listShadow]: !isFocusedRow,
        [classes.selectedRow]: isFocusedRow,
      })}
      onClick={() => {
        if (availabilityRule) {
          onClick(availabilityRule)
        }
      }}
    >
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.mainInfoContainer, {
          [classes.expandedMainColumn]: !isExpanded,
        })}
        md={isExpanded ? 12 : 4}
        wrap="nowrap"
      >
        <Grid item xs className={classes.ruleNameTextContainer}>
          <Dotdotdot clamp={isMobile ? 2 : 1}>
            <TextInteractive
              strong
              className={classNames(classes.text, {
                [classes.ruleNameTextSelected]: isFocusedRow,
              })}
              isLoading={isLoading}
            >
              {availabilityRule?.name}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classNames(classes.sideInfoWrapper, {
          [classes.collapsedColumn]: !isMobile && isExpanded,
          [classes.expandedColumn]: !isMobile && !isExpanded,
        })}
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 0 : 8}
        wrap="nowrap"
      >
        <Grid item className={classes.sideInfoContainer} md={3}>
          <Dotdotdot clamp={1}>
            <TextInteractive
              strong
              className={classes.text}
              isLoading={isLoading}
            >
              {getTimeRangeString(startTime, endTime)}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={3}>
          <Dotdotdot clamp={1}>
            <TextInteractive
              strong
              className={classes.text}
              isLoading={isLoading}
            >
              {getRuleRecurrenceString(
                availabilityRule?.recurrenceType,
                availabilityRule?.recurrenceParams,
              )}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={3}>
          <Dotdotdot clamp={1}>
            <TextInteractive
              strong
              className={classes.text}
              isLoading={isLoading}
            >
              {DateUtils.formatDate(availabilityRule?.startDate)}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
        <Grid item className={classes.sideInfoContainer} md={3}>
          <Dotdotdot clamp={1}>
            <TextInteractive
              strong
              className={classes.text}
              isLoading={isLoading}
            >
              {DateUtils.formatDate(availabilityRule?.endDate)}
            </TextInteractive>
            <TextInteractive
              strong
              className={classNames(classes.text, classes.dateProximityText)}
              isLoading={isLoading}
            >
              {getDateProximity(availabilityRule?.endDate, endTime)}
            </TextInteractive>
          </Dotdotdot>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AvailabilityRulesTableRow
