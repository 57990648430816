import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import Link from '../link/Link'

const useStyles = makeStyles(
  (theme) => ({
    legalContainer: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(4),
      },
    },
    link: {
      color: 'rgba(74,14,4,0.3)',
    },
  }),
  { name: 'TermsAndCondition' },
)

const TermsAndCondition = () => {
  const classes = useStyles()
  const { t } = useTranslation('Auth')

  return (
    <Grid item className={classes.legalContainer}>
      <Text align="center" mt={2} variant="caption">
        {t('Auth:LOGIN_PAGE.TERMS_AND_CONDITIONS.PREFIX')}&nbsp;
        <Link className={classes.link} to="/terms-and-conditions">
          {t('Auth:LOGIN_PAGE.TERMS_AND_CONDITIONS.LINK')}
        </Link>
      </Text>
    </Grid>
  )
}

export default TermsAndCondition
