import { Field } from '@pbt/pbt-ui-components'

export const htmlDecode = (html: string) => {
  const element = document.createElement('div')
  element.innerHTML = html

  return element.innerText ?? element.textContent
}

export const removeWhitespaceBetweenTags = (html: string) =>
  html.replace(/>[\t ]+</g, '><')

export const equalsHtmlDecode = (a: string, b: string) =>
  htmlDecode(removeWhitespaceBetweenTags(a)) ===
  htmlDecode(removeWhitespaceBetweenTags(b))

export const hasHtmlFieldChanged = (field: Field) =>
  !equalsHtmlDecode(field.initialValue, field.value)
