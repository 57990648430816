import React from 'react'
import { useTranslation } from 'react-i18next'
import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import { Prescription } from '~/types'

import { PrescriptionEditorProps } from '../prescription-editor/hooks/usePrescriptionEditor'
import BasePrescriptionDialog from './BasePrescriptionDialog'

export interface FoodPrescriptionDialogProps extends BasePuiDialogProps {
  areChargesPostedAndEditable?: boolean
  clientId: string
  onSave: PrescriptionEditorProps['onSave']
  outsideSoap?: boolean
  patientId: string
  prescription: Prescription
  skipOrderFetching?: boolean
  soapId?: string
}

const FoodPrescriptionDialog = ({
  prescription,
  outsideSoap,
  clientId,
  patientId,
  soapId,
  open,
  skipOrderFetching,
  onClose,
  onSave,
  areChargesPostedAndEditable,
}: FoodPrescriptionDialogProps) => {
  const { t } = useTranslation(['Dialogs'])
  const automaticallyCreateTaskLabel = t(
    'Dialogs:PRESCRIPTION_DIALOG.AUTOMATICALLY_CREATE_TASK',
  )

  return (
    <BasePrescriptionDialog
      disabledCustomCompound
      isFood
      showOnlyDrugVariation
      simpleAdministrationInstructions
      areChargesPostedAndEditable={areChargesPostedAndEditable}
      automaticallyCreateTaskLabel={automaticallyCreateTaskLabel}
      clientId={clientId}
      open={open}
      outsideSoap={outsideSoap}
      patientId={patientId}
      prescription={prescription}
      skipOrderFetching={skipOrderFetching}
      soapId={soapId}
      onClose={onClose}
      onSave={onSave}
    />
  )
}

export default FoodPrescriptionDialog
