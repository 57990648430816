import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text, Utils } from '@pbt/pbt-ui-components'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

import UploadButton from '~/components/common/buttons/UploadButton'
import DialogNames from '~/constants/DialogNames'
import {
  MigrationStatuses,
  MigrationType,
  SessionsTypes,
} from '~/constants/migration'
import { fetchExporterUrl } from '~/store/actions/migration'
import { getPimsFull } from '~/store/reducers/constants'
import { BaseMigrationProps } from '~/types/entities/migration'
import useDialog from '~/utils/useDialog'

const PimsNamesMap = {
  AVIMARK: 'Avimark',
  CORNERSTONE: 'Cornerstone',
  IMPROMED: 'ImproMed',
  INTRAVET: 'IntraVet',
}

const useStyles = makeStyles(
  () => ({
    icon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'ContinuousMigration' },
)

const ContinuousMigration = ({ businessId }: BaseMigrationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const PimsFull = useSelector(getPimsFull)

  const [openCoreMigrationDialog] = useDialog(DialogNames.CORE_MIGRATION_DIALOG)
  const [openActivationTokenDialog] = useDialog(
    DialogNames.MIGRATION_ACTIVATION_TOKEN,
  )
  const [openOnGoingStandardizationDialog] = useDialog(
    DialogNames.ON_GOING_STANDARDIZATION_DIALOG,
  )
  const [openUploadAdditionalFilesDialog] = useDialog(
    DialogNames.MIGRATION_UPLOAD_ADDITIONAL_FILES,
  )
  const [openCovetrusIntegrationDialog] = useDialog(
    DialogNames.COVETRUS_INTEGRATION_DIALOG,
  )

  const AvimarkId = Utils.findConstantIdByName(PimsNamesMap.AVIMARK, PimsFull)
  const CornerstoneId = Utils.findConstantIdByName(
    PimsNamesMap.CORNERSTONE,
    PimsFull,
  )
  const ImproMedId = Utils.findConstantIdByName(PimsNamesMap.IMPROMED, PimsFull)
  const IntraVetId = Utils.findConstantIdByName(PimsNamesMap.INTRAVET, PimsFull)

  const handleCoreImport = () => {
    openCoreMigrationDialog({
      businessId,
      isEnterprise: true,
      enterpriseStatuses: [
        MigrationStatuses.IMPORT_IS_READY,
        MigrationStatuses.CONFIRM_IMPORT_SETTINGS,
        MigrationStatuses.PREPROCESSING_IN_PROGRESS,
        MigrationStatuses.PREPROCESSING_DONE,
      ],
      sessionsFilterTypes: [SessionsTypes.RHAPSODY_ENTERPRISE],
    })
  }

  const handleViewActivationToken =
    (pimsName: string, exportedType: string) => () => {
      openActivationTokenDialog({ businessId, pimsName, exportedType })
    }

  const handleReviewExceptions = () => {
    openOnGoingStandardizationDialog({ businessId })
  }

  const handleUploadAdditionalFiles = () => {
    openUploadAdditionalFilesDialog({ businessId })
  }

  const handleCovetrusIntegration = () => {
    openCovetrusIntegrationDialog({ businessId })
  }

  return (
    <Grid>
      <Text strong variant="body2">
        {t('Businesses:MIGRATION.DOWNLOAD_MIGRATION_WIZARD', {
          name: 'Avimark',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={() =>
          dispatch(
            fetchExporterUrl(AvimarkId, businessId, MigrationType.RE_AVIMARK),
          )
        }
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.DOWNLOAD_CONTINUOUS_MIGRATION_WIZARD', {
          name: 'Avimark',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={handleViewActivationToken(
          PimsNamesMap.AVIMARK,
          MigrationType.RE_AVIMARK,
        )}
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.VIEW_ACTIVATION_TOKEN', { name: 'Avimark' })}
      </Text>
      <Text strong variant="body2">
        {t('Businesses:MIGRATION.DOWNLOAD_MIGRATION_WIZARD', {
          name: 'Cornerstone',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={() =>
          dispatch(
            fetchExporterUrl(
              CornerstoneId,
              businessId,
              MigrationType.RE_CORNERSTONE,
            ),
          )
        }
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.DOWNLOAD_CONTINUOUS_MIGRATION_WIZARD', {
          name: 'Cornerstone',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={handleViewActivationToken(
          PimsNamesMap.CORNERSTONE,
          MigrationType.RE_CORNERSTONE,
        )}
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.VIEW_ACTIVATION_TOKEN', {
          name: 'Cornerstone',
        })}
      </Text>
      <Text strong variant="body2">
        {t('Businesses:MIGRATION.DOWNLOAD_MIGRATION_WIZARD', {
          name: 'ImproMed',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={() =>
          dispatch(
            fetchExporterUrl(
              ImproMedId,
              businessId,
              MigrationType.RE_IMPROMED_INFINITY,
            ),
          )
        }
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.DOWNLOAD_CONTINUOUS_MIGRATION_WIZARD', {
          name: 'ImproMed',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={handleViewActivationToken(
          PimsNamesMap.IMPROMED,
          MigrationType.RE_IMPROMED_INFINITY,
        )}
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.VIEW_ACTIVATION_TOKEN', { name: 'ImproMed' })}
      </Text>
      <Text strong variant="body2">
        {t('Businesses:MIGRATION.DOWNLOAD_MIGRATION_WIZARD', {
          name: 'IntraVet',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={() =>
          dispatch(
            fetchExporterUrl(
              IntraVetId,
              businessId,
              MigrationType.RE_INTRAVET_CLASSIC,
            ),
          )
        }
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.DOWNLOAD_CONTINUOUS_MIGRATION_WIZARD', {
          name: 'IntraVet',
        })}
      </Text>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={handleViewActivationToken(
          PimsNamesMap.INTRAVET,
          MigrationType.RE_INTRAVET_CLASSIC,
        )}
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.VIEW_ACTIVATION_TOKEN', { name: 'IntraVet' })}
      </Text>
      <Grid mt={3}>
        <Text strong variant="body2">
          {t('Businesses:MIGRATION.COVETRUS_INTEGRATION')}
        </Text>
        <UploadButton
          label={t('Businesses:MIGRATION.SETUP_COVETRUS_INTEGRATION')}
          onClick={handleCovetrusIntegration}
        />
      </Grid>
      <Grid mt={3}>
        <Text strong variant="body2">
          {t('Businesses:MIGRATION.CORE_DATA')}
        </Text>
        <UploadButton
          label={t('Businesses:MIGRATION.INITIAL_DATA_STANDARDIZATION')}
          onClick={handleCoreImport}
        />
        <UploadButton
          label={t('Businesses:MIGRATION.REVIEW_ONGOING_STANDARDIZATION')}
          onClick={handleReviewExceptions}
        />
        <UploadButton
          label={t('Businesses:MIGRATION.UPLOAD_ADDITIONAL_FILES')}
          onClick={handleUploadAdditionalFiles}
        />
      </Grid>
    </Grid>
  )
}

export default ContinuousMigration
