import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Input, SelectProps, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { SoapTemplateWidget } from '~/api/graphql/generated/types'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getBusinessSoapWidgets } from '~/store/reducers/businesses'
import {
  getFeatureToggle,
  getStaticSoapWidget,
} from '~/store/reducers/constants'
import { SoapTemplateTab, SoapWidgetName } from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

import SoapTemplateTabDialogActions from './SoapTemplateTabDialogActions'
import SoapWidgetsCandidate from './SoapWidgetsCandidate'

const useStyles = makeStyles(
  (theme) => ({
    actions: {
      padding: theme.spacing(0, 3, 3),
      borderTop: 'none',
    },
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    select: {
      marginTop: theme.spacing(2),
    },
    selectWithError: {
      marginBottom: theme.spacing(9),
    },
    title: {
      paddingLeft: theme.spacing(3, 3, 0),
      borderBottom: 'none',
    },
  }),
  { name: 'SoapTemplateTabDialog' },
)

interface BaseSoapTemplateTabDialogProps extends BasePuiDialogProps {
  onSave: ({
    name,
    templateId,
    widgets,
  }: {
    name: string
    templateId?: string
    widgets: SoapTemplateWidget[]
  }) => void
}

interface AddSoapTemplateTabDialogProps extends BaseSoapTemplateTabDialogProps {
  tab?: never
}

interface EditSoapTemplateTabDialogProps
  extends BaseSoapTemplateTabDialogProps {
  tab: SoapTemplateTab
}

export type SoapTemplateTabDialogProps =
  | AddSoapTemplateTabDialogProps
  | EditSoapTemplateTabDialogProps

const SoapTemplateTabDialog = ({
  onClose,
  onSave,
  open,
  tab,
}: SoapTemplateTabDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')

  const isQualtricsIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.QUALTRICS_INTEGRATION),
  )
  const isOmniChannel = useSelector(getCurrentBusinessIsOmniChannel)
  const StaticSoapWidget = useSelector(getStaticSoapWidget)
  const AllBusinessSoapWidgets = useSelector(getBusinessSoapWidgets)!
  const clientSurveyWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.CLIENT_SURVEY,
    StaticSoapWidget,
  )

  const BusinessSoapWidgets = AllBusinessSoapWidgets.filter(
    (widget) =>
      (isQualtricsIntegrationEnabled && isOmniChannel) ||
      widget.soapWidgetId !== clientSurveyWidgetId,
  )

  const [hasUnsavedData, setHasUnsavedData] = useState(false)

  const findSoapWidget = (soapWidgetId: string, marketplaceId: string | Nil) =>
    Utils.findById(`${soapWidgetId}_${marketplaceId}`, BusinessSoapWidgets)

  const { fields, validate } = useFields(
    [
      {
        name: 'tabName',
        initialValue: tab ? tab.name : '',
        label: t('Admin:SOAP_CUSTOMIZATION.TAB_NAME'),
        validators: ['required'],
      },
      {
        name: 'tabWidgets',
        type: 'select',
        initialValue: tab
          ? tab.widgets.map((widget) => {
              const { soapWidgetId, marketplaceId } = widget
              const { name, nameTranslation } =
                findSoapWidget(soapWidgetId, marketplaceId) || {}

              return {
                ...widget,
                name,
                nameTranslation,
              }
            })
          : [],
        validators: ['required'],
        messages: {
          required: t('Admin:SOAP_CUSTOMIZATION.TAB_WIDGETS_ERROR'),
        },
      },
    ],
    false,
  )

  const { tabName: fieldTabName, tabWidgets: fieldWidgets } = fields
  const dialogId = `soap-template-tab-${tab ? 'edit' : 'add'}-dialog`

  useFieldsChanged(() => {
    setHasUnsavedData(true)
  }, fields)

  const handleChange: SelectProps<unknown>['onChange'] = (event) => {
    const { value } = event.target
    const current = BusinessSoapWidgets?.find((widget) => widget.id === value)

    if (!current) {
      return
    }

    const { active, id, marketplaceId, soapWidgetId } = current
    const { name, nameTranslation } =
      findSoapWidget(soapWidgetId, marketplaceId) || {}

    const newWidget = {
      active,
      id,
      marketplaceId,
      soapWidgetId,
      order: fieldWidgets.value.length + 1,
      name,
      nameTranslation,
    }

    fieldWidgets.setValue([...fieldWidgets.value, newWidget])
  }

  const ascendOrder = (item: SoapTemplateWidget, index: number) => ({
    ...item,
    order: index + 1,
  })

  const handleDelete = (item: SoapTemplateWidget) => {
    const filteredWidgets = R.pipe(
      R.reject(R.propEq('order', item.order)),
      (filtered: SoapTemplateWidget[]) => filtered.map(ascendOrder),
    )(fieldWidgets.value)

    fieldWidgets.setValue(filteredWidgets)
  }

  const handleOrderChange = (sourceIndex: number, targetIndex: number) => {
    const orderedItems = R.pipe<
      SoapTemplateWidget[][],
      SoapTemplateWidget[],
      SoapTemplateWidget[]
    >(
      R.move(sourceIndex, targetIndex),
      (normalizedWidgets: SoapTemplateWidget[]) =>
        normalizedWidgets.map(ascendOrder),
    )(fieldWidgets.value)

    fieldWidgets.setValue(orderedItems)
  }

  const handleSave = () => {
    if (!validate()) {
      return
    }

    onSave({
      ...(tab?.id ? { templateId: tab.id } : {}),
      name: fieldTabName.value,
      widgets: fieldWidgets.value,
    })
    onClose?.()
  }

  return (
    <PuiDialog
      actions={
        <SoapTemplateTabDialogActions
          hasUnsavedData={hasUnsavedData}
          onSave={handleSave}
        />
      }
      aria-labelledby={dialogId}
      classes={{
        actions: classes.actions,
        dialogTitle: classes.title,
        paper: classes.paper,
      }}
      id={dialogId}
      open={open}
      title={
        tab
          ? t('Admin:SOAP_CUSTOMIZATION.EDIT_SOAP_TAB')
          : t('Admin:SOAP_CUSTOMIZATION.ADD_SOAP_TAB')
      }
      onClose={onClose}
    >
      <Stack pb={3} px={3} spacing={2}>
        <PuiTextField
          fullWidth
          field={fieldTabName}
          id="soap-template-tab-name-input"
          inputProps={{ maxLength: 100 }}
          label={fieldTabName.label}
        />
        <FormControl>
          <Text strong mb={1} variant="subheading3">
            {t('Admin:SOAP_CUSTOMIZATION.INCLUDED_WIDGETS')}
          </Text>
          {fieldWidgets.value && (
            <SoapWidgetsCandidate
              widgets={fieldWidgets.value}
              onDelete={handleDelete}
              onOrderChange={handleOrderChange}
            />
          )}
          <PuiSelect
            MenuProps={{ id: 'soap-template-tab-widget-select' }}
            className={classNames(classes.select, {
              [classes.selectWithError]:
                fieldWidgets.errors && !fieldWidgets.valid && fieldWidgets.open,
            })}
            field={fieldWidgets}
            input={<Input id="soap-template-tab-widget-input" />}
            items={BusinessSoapWidgets.map((widget) => ({
              ...widget,
              disabled: fieldWidgets.value.find(
                (currentWidget: SoapTemplateWidget) =>
                  widget.id ===
                  `${currentWidget.soapWidgetId}_${currentWidget.marketplaceId}`,
              ),
            }))}
            placeholder={t('Common:ADD_A_WIDGET')}
            onChange={handleChange}
          />
        </FormControl>
      </Stack>
    </PuiDialog>
  )
}

export default SoapTemplateTabDialog
