import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PermissionArea, Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import useDialog from '~/utils/useDialog'

const NoReminderProtocolGroupsScreen = () => {
  const [openNewReminderProtocolGroupDialog] = useDialog(
    DialogNames.NEW_REMINDER_PROTOCOL_GROUP,
  )
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )
  const { t } = useTranslation(['Admin', 'Common'])

  const onCreateReminderProtocol = () => {
    openNewReminderProtocolGroupDialog()
  }

  return (
    <NoItemsScreen
      action={permissions.create ? onCreateReminderProtocol : undefined}
      actionMessage={t('Common:NEW_PROTOCOL')}
      title={t('Common:REMINDER_PROTOCOLS')}
    >
      <Text>
        {t('Admin:CATALOG.NO_REMINDER_PROTOCOL_GROUPS_SCREEN.DESCRIPTION')}
      </Text>
    </NoItemsScreen>
  )
}

export default NoReminderProtocolGroupsScreen
