export enum LandingType {
  LANDING_DASHBOARD = 'LANDING_DASHBOARD',
  CLIENT_AND_PATIENT_SNAPSHOTS = 'CLIENT_AND_PATIENT_SNAPSHOTS',
}

export enum LandingWidgetName {
  ADMITTED = 'ADMITTED',
  TODAY_APPOINTMENTS = 'TODAY_APPOINTMENTS',
  UPCOMING = 'UPCOMING',
  OPEN_SOAPS = 'MY_OPEN_SOAPS',
  TIME_TRACKING = 'TIME_TRACKING',
  COMMUNICATIONS = 'COMMUNICATIONS',
  MY_TASKS = 'MY_TASKS',
  LABS = 'LABS',
  CUSTOMER_SATISFACTION = 'CUSTOMER_SATISFACTION',
}

export enum WidgetWidthType {
  FULL_WIDTH = 'FULL_WIDTH',
  TWO_THIRDS_WIDTH = 'TWO_THIRDS_WIDTH',
  HALF_WIDTH = 'HALF_WIDTH',
  THIRD_WIDTH = 'THIRD_WIDTH',
}

export enum LandingLayoutBreakpoints {
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
}

export enum AppointmentBlockSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export enum WidgetRecordsAssignedMode {
  OWN = 'OWN',
  UNASSIGNED = 'UNASSIGNED',
  UNASSIGNED_AND_OWN = 'UNASSIGNED_AND_OWN',
  ALL = 'ALL',
}
