import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DateFormat, DateUtils, PuiCheckbox } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    children: {
      marginLeft: theme.spacing(3),
    },
    checkbox: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    checkboxLabel: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'EmailDialogSectionItem' },
)

export interface EmailDialogSectionItemProps {
  addedDate?: string | null
  checked: boolean
  children: any[]
  id: string
  name: string
  onCheckedChange: (id: string, checked: boolean) => void
}

const EmailDialogSectionItem = ({
  id,
  name,
  checked,
  children,
  onCheckedChange,
  addedDate,
}: EmailDialogSectionItemProps) => {
  const classes = useStyles()

  const formattedDate =
    addedDate &&
    DateUtils.formatDate(addedDate, true, DateFormat.FULL_DATE_COMMA)
  const displayFullName = addedDate ? `${name} ${formattedDate}` : name

  return (
    <Grid container>
      <PuiCheckbox
        checkboxClasses={{
          root: classes.checkbox,
        }}
        checked={checked}
        className={classes.checkboxLabel}
        label={displayFullName}
        onChange={() => onCheckedChange(id, !checked)}
      />
      {children && (
        <Grid container className={classes.children}>
          {children.map((item) => (
            <EmailDialogSectionItem
              key={item.id}
              onCheckedChange={onCheckedChange}
              {...item}
            />
          ))}
        </Grid>
      )}
    </Grid>
  )
}

export default EmailDialogSectionItem
