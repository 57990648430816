import React, { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import WellnessPlanReview, {
  WellnessPlanReviewProps,
} from './WellnessPlanReview'

export interface WellnessPlanIntermediateReviewProps
  extends Omit<WellnessPlanReviewProps, 'title'> {}

const WellnessPlanIntermediateReview = forwardRef(
  function WellnessPlanIntermediateReview(
    props: WellnessPlanIntermediateReviewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const { t } = useTranslation('Common')

    return (
      <WellnessPlanReview
        ref={ref}
        title={`${t('Common:CONGRATULATIONS_YOU_ARE_ON_YOUR_WAY')}!`}
        {...props}
      />
    )
  },
)

export default WellnessPlanIntermediateReview
