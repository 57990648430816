import React from 'react'
import { Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { InvoiceLineItem } from '~/types'

import ChargeDetailsCurrencyField from './ChargeDetailsCurrencyField'

export interface ChargeFieldCatalogUnitPriceProps {
  catalogUnitPriceField: Field
  className?: string
  inputClassName?: string
  item: InvoiceLineItem | RetailOrderLineItem
}

const ChargeFieldCatalogUnitPrice = ({
  catalogUnitPriceField,
  className: classNameProp,
  inputClassName,
  item,
}: ChargeFieldCatalogUnitPriceProps) => (
  <ChargeDetailsCurrencyField
    disabled
    className={classNameProp}
    field={catalogUnitPriceField}
    inputClassName={inputClassName}
    item={item}
  />
)

export default ChargeFieldCatalogUnitPrice
