export const FETCH_TIMELINE = 'timeline/FETCH_TIMELINE'
export const FETCH_TIMELINE_FAILURE = 'timeline/FETCH_TIMELINE_FAILURE'
export const FETCH_TIMELINE_SUCCESS = 'timeline/FETCH_TIMELINE_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_TIMELINE =
  'timeline/FETCH_MORE_ITEMS_FOR_TIMELINE'
export const FETCH_MORE_ITEMS_FOR_TIMELINE_FAILURE =
  'timeline/FETCH_MORE_ITEMS_FOR_TIMELINE_FAILURE'
export const FETCH_MORE_ITEMS_FOR_TIMELINE_SUCCESS =
  'timeline/FETCH_MORE_ITEMS_FOR_TIMELINE_SUCCESS'

export const UPDATE_TIMELINE_FILTERS = 'timeline/UPDATE_TIMELINE_FILTERS'
export const UPDATE_TIMELINE_DATE_RANGE = 'timeline/UPDATE_TIMELINE_DATE_RANGE'
export const UPDATE_TIMELINE_CLIENT_DATA =
  'timeline/UPDATE_TIMELINE_CLIENT_DATA'
export const RESET_TIMELINE = 'timeline/RESET_TIMELINE'
export const OPTIMISTIC_ATTACHMENT_DELETE =
  'timeline/OPTIMISTIC_ATTACHMENT_DELETE'

export const OPTIMISTIC_LAB_TEST_FILE_DELETE_FROM_LAB_TEST_CARD =
  'timeline/OPTIMISTIC_LAB_TEST_FILE_DELETE_FROM_LAB_TEST_CARD'

export const RENDER_TIMELINE_CARD_FAILURE =
  'timeline/RENDER_TIMELINE_CARD_FAILURE'

export const DELETE_LAB_TEST_ATTACHMENT_FILE =
  'timeline/DELETE_LAB_TEST_ATTACHMENT_FILE'
export const DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS =
  'timeline/DELETE_LAB_TEST_ATTACHMENT_FILE_SUCCESS'
export const DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE =
  'timeline/DELETE_LAB_TEST_ATTACHMENT_FILE_FAILURE'
