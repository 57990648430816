import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  Grid,
  GridWrap,
  Hidden,
  Input,
  InputLabel,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CurrencyTextField,
  Field,
  Nil,
  PuiSelect,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { useGetAutoCalculatedPrice } from '~/store/hooks/variations'
import { getInventoryCostTypes } from '~/store/reducers/constants'

import VariationPriceUnit from './VariationPriceUnit'

const useStyles = makeStyles(
  (theme) => ({
    divider: {
      alignSelf: 'center',
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(0, 0.5),
      fontSize: '1.6rem',
    },
    priceTopLeftItem: {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(1),
      },
      paddingLeft: theme.spacing(1),
    },
    warnIcon: {
      color: theme.colors.alertWarning,
      marginRight: theme.spacing(1),
      height: '1.5rem',
      width: '1.5rem',
    },
  }),
  { name: 'VariationCost' },
)

export interface VariationCostProps {
  count?: number | Nil
  fields: {
    cost: Field
    costTypeId: Field
    priceUnitsSize: Field
    unitId: Field
  }
  packageTypeId: string
  perPackageUnitsId: string
  saleUnitOfMeasure?: string | Nil
  showCalculationOptions?: boolean
  wrap?: GridWrap
}

const VariationCost = ({
  fields: { cost, unitId, priceUnitsSize, costTypeId },
  packageTypeId,
  perPackageUnitsId,
  showCalculationOptions = true,
  wrap,
  count,
  saleUnitOfMeasure,
}: VariationCostProps) => {
  const classes = useStyles()
  const InventoryCostTypes = useSelector(getInventoryCostTypes)
  const { t } = useTranslation(['Admin', 'Common'])

  const FixedCostTypeId = Utils.findConstantIdByName(
    'Fixed',
    InventoryCostTypes,
  )
  const isAutoCalculatedCost =
    costTypeId.value && costTypeId.value !== FixedCostTypeId
  const costHistoryExists = Boolean(cost.value)
  const manualInputDisabled = Boolean(isAutoCalculatedCost && costHistoryExists)

  const autoCalculatedCost = useGetAutoCalculatedPrice({
    costTypeId: costTypeId.value,
    unitId: unitId.value,
    priceUnitsSize: priceUnitsSize.value,
  })

  useEffect(() => {
    if (!unitId?.value) {
      return
    }
    if (autoCalculatedCost > 0) {
      cost.setValue(autoCalculatedCost)
    }
  }, [autoCalculatedCost])

  useEffect(() => {
    if (FixedCostTypeId && showCalculationOptions && !costTypeId.value) {
      costTypeId.setValue(FixedCostTypeId)
    }
  }, [FixedCostTypeId])

  return (
    <>
      <Grid container item alignItems="center" wrap={wrap}>
        {showCalculationOptions && (
          <Grid item className={classes.priceTopLeftItem} md={4} xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="cost-type-select">
                {costTypeId.label ||
                  t('Admin:CATALOG.VARIATION_COST.COST_CALCULATION')}
                *
              </InputLabel>
              <PuiSelect
                field={costTypeId}
                input={<Input id="cost-type-select" />}
                items={InventoryCostTypes}
                renderEmpty={false}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item className={classes.priceTopLeftItem} md={3} xs={6}>
          <CurrencyTextField
            disabled={manualInputDisabled}
            field={cost}
            label={cost.label || t('Common:COST')}
          />
        </Grid>
        <Hidden mdDown>
          <div className={classes.divider}>/</div>
        </Hidden>
        <VariationPriceUnit
          count={count}
          fields={{
            unitId,
            priceUnitsSize,
          }}
          packageTypeId={packageTypeId}
          perPackageUnitsId={perPackageUnitsId}
          saleUnitOfMeasure={saleUnitOfMeasure}
        />
      </Grid>
      {showCalculationOptions && isAutoCalculatedCost && !costHistoryExists && (
        <Grid container item alignItems="center" p={1} wrap="nowrap">
          <WarningIcon className={classes.warnIcon} />
          <Text>{t('Admin:CATALOG.VARIATION_COST.NO_ORDERS')}</Text>
        </Grid>
      )}
    </>
  )
}

export default VariationCost
