import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'ReminderSettingsItem' },
)

export interface ReminderSettingsItemProps {
  name?: string
  onDelete: () => void
}

const ReminderSettingsItem = ({
  name,
  onDelete,
}: ReminderSettingsItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Grid
      container
      alignItems="center"
      className={classes.container}
      justifyContent="space-between"
      px={1.5}
      py={1}
    >
      <Text variant="body2">{name}</Text>
      <IconButton
        aria-label={t('Common:DELETE_ACTION').toLowerCase()}
        className={classes.iconButton}
        size="large"
        onClick={onDelete}
      >
        <DeleteIcon />
      </IconButton>
    </Grid>
  )
}

export default ReminderSettingsItem
