import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  BackButton,
  ButtonWithLoader,
  Text,
} from '@pbt/pbt-ui-components'

import LeaveConfirmationDialog from '~/components/common/dialog/LeaveConfirmationDialog'
import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import DialogNames from '~/constants/DialogNames'
import { resetInventories } from '~/store/actions/inventories'
import { resetLabTests } from '~/store/actions/labTests'
import { resetProcedures } from '~/store/actions/procedures'
import {
  deleteBulkPricesSession,
  editPricesBulkAndManual,
  fetchBulkPricesActiveSession,
  getActiveSessionByEntityType,
  getAdjustValue,
  getBulkPricesError,
  getBulkPricesErrorType,
  getBulkPricesFilter,
  getBulkPricesIsEditing,
  getBulkPricesIsLoading,
  getBulkPricesIsLoadingSession,
  getBulkPricesIsStarting,
  getConfirmedSessions,
  getHasManualPriceChanges,
  getHasSelectedPrices,
  resetBulkPrices,
  startPriceBulkUpdate,
} from '~/store/duck/bulkPrices'
import { BulkPricesErrors } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import BulkPageHeadline from './BulkPageHeadline'
import {
  BulkPricesCategoryConstant,
  BulkPricesSubCategoryConstant,
} from './BulkPricesEditFilters'
import BulkPricesListStep from './BulkPricesListStep'
import BulkPricesReviewStep from './BulkPricesReviewStep'

enum Steps {
  INITIAL = 'INITIAL',
  REVIEW = 'REVIEW',
}

const useStyles = makeStyles(
  (theme) => ({
    footer: {
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    button: {
      minWidth: 200,
    },
  }),
  { name: 'BulkPricesPage' },
)

interface BulkPricesPageProps {
  categoryLabel?: string
  categoryName: BulkPricesCategoryConstant
  entityType: BulkPriceEntityTypes
  loadItems: (
    from: number,
    to: number,
    category: string | number,
    subCategory: string | number,
  ) => void
  startNewSession: () => void
  subCategoryName?: BulkPricesSubCategoryConstant
  title: string
}

const onCloseNavigation = {
  [BulkPriceEntityTypes.PROCEDURE]: '/admin/catalog/procedures',
  [BulkPriceEntityTypes.LAB_TEST]: '/admin/catalog/lab-tests',
  [BulkPriceEntityTypes.INVENTORY]: '/admin/catalog/inventories/catalog',
}

const resetAfterUpdateMap = {
  [BulkPriceEntityTypes.PROCEDURE]: resetProcedures,
  [BulkPriceEntityTypes.LAB_TEST]: resetLabTests,
  [BulkPriceEntityTypes.INVENTORY]: resetInventories,
}

const BulkPricesPage = ({
  entityType,
  startNewSession,
  loadItems,
  categoryLabel,
  categoryName,
  subCategoryName,
  title,
}: BulkPricesPageProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['Admin', 'Common'])
  const filterValue = useSelector(getBulkPricesFilter(categoryName))
  const activeSession = useSelector(getActiveSessionByEntityType(entityType))
  const hasManualPriceChanges = useSelector(getHasManualPriceChanges)
  const adjustValue = useSelector(getAdjustValue)
  const isEditing = useSelector(getBulkPricesIsEditing)
  const isStarting = useSelector(getBulkPricesIsStarting)
  const isLoading = useSelector(getBulkPricesIsLoading)

  const error = useSelector(getBulkPricesError)
  const errorType = useSelector(getBulkPricesErrorType)
  const confirmedSessions = useSelector(getConfirmedSessions)
  const sessionId = activeSession?.id

  const hasSelectedPrices = useSelector(getHasSelectedPrices)
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const [step, setStep] = useState(Steps.INITIAL)

  const handleClosePage = () => {
    navigate(onCloseNavigation[entityType])
  }

  const onActiveSessionFetched = () => {
    if (!activeSession) {
      startNewSession()
    }
  }

  const onPricesEdited = () => {
    if (!error) {
      setStep(Steps.REVIEW)
    } else if (
      errorType === BulkPricesErrors.MISMATCHED_SESSION_STATUS_ERROR_TYPE
    ) {
      openAlert({
        iconType: AlertIconType.WARN,
        message: t('Prices:PRICING_UPDATE_SESSION_HAS_EXPIRED'),
        okButtonText: t('Common:GO_BACK'),
        onOk: () => {
          closeAlert()
          handleClosePage()
        },
      })
    }
  }

  const onPricesUpdateStarted = () => {
    if (!error) {
      openAlert({
        iconType: AlertIconType.SUCCESS,
        message: t('Prices:PRICING_UPDATE_IN_PROGRESS'),
        okButtonText: t('Common:GOT_IT'),
        onOk: () => {
          closeAlert()
          handleClosePage()
        },
      })
    }
  }

  const setCallbackOnActiveSessionFetched = useCloseAfterCreation(
    onActiveSessionFetched,
    getBulkPricesIsLoadingSession,
  )
  const setCallbackOnPricesEdited = useCloseAfterCreation(
    onPricesEdited,
    getBulkPricesIsEditing,
  )
  const setCallbackOnPricesUpdateStart = useCloseAfterCreation(
    onPricesUpdateStarted,
    getBulkPricesIsStarting,
  )

  useEffect(() => {
    if (!sessionId) {
      setCallbackOnActiveSessionFetched()
      dispatch(fetchBulkPricesActiveSession({ entityType }))
    }

    return () => {
      if (sessionId) {
        dispatch(deleteBulkPricesSession({ sessionId, entityType }))
      }
    }
  }, [sessionId])

  useEffect(
    () => () => {
      dispatch(resetBulkPrices())
    },
    [],
  )

  const goToPrevStep = () => {
    setStep(Steps.INITIAL)
  }

  const proceed = () => {
    if (step === Steps.INITIAL) {
      dispatch(editPricesBulkAndManual({ sessionId, entityType }))
      setCallbackOnPricesEdited()
    }

    if (step === Steps.REVIEW) {
      dispatch(startPriceBulkUpdate({ sessionId }))
      dispatch(resetAfterUpdateMap[entityType]())
      setCallbackOnPricesUpdateStart()
    }
  }

  const noCategorySelected = step === Steps.INITIAL && !filterValue
  const hasAnyChanges = Boolean(adjustValue) || hasManualPriceChanges
  const noRequiredChanges = step === Steps.INITIAL && !hasAnyChanges

  return (
    <Grid container direction="column" flex={1}>
      <BulkPageHeadline title={title} onClose={handleClosePage} />
      <Grid container item flex={1}>
        {step === Steps.INITIAL && (
          <BulkPricesListStep
            categoryLabel={categoryLabel}
            categoryName={categoryName}
            loadItems={loadItems}
            subCategoryName={subCategoryName}
          />
        )}
        {step === Steps.REVIEW && (
          <BulkPricesReviewStep entityType={entityType} />
        )}
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.footer}
        columnSpacing={3}
        px={3}
        py={2}
      >
        {step !== Steps.INITIAL && (
          <Grid item>
            <BackButton
              label={t('Common:BACK_ACTION')}
              onClick={goToPrevStep}
            />
          </Grid>
        )}
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={
              noCategorySelected || noRequiredChanges || !hasSelectedPrices
            }
            loading={isEditing || isStarting || isLoading}
            onClick={proceed}
          >
            {step === Steps.INITIAL && t('Common:NEXT')}
            {step === Steps.REVIEW && t('Common:CONFIRM_ACTION')}
          </ButtonWithLoader>
        </Grid>
        <Grid item>
          <Text variant="lowAccent2">
            {noCategorySelected
              ? t('Prices:CHOOSE_CATEGORY_TO_CONTINUE')
              : noRequiredChanges
                ? t('Prices:ADJUST_PRICES_TO_CONTINUE')
                : null}
          </Text>
        </Grid>
      </Grid>
      <LeaveConfirmationDialog
        dialogName={DialogNames.BULK_PRICES_CLOSE}
        hasUnsavedData={
          hasAnyChanges &&
          !confirmedSessions.includes(sessionId) &&
          Boolean(sessionId)
        }
      />
    </Grid>
  )
}

export default BulkPricesPage
