import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  PrescriptionWorkflowName,
  PrescriptionWorkflowType,
} from '~/constants/PrescriptionWorkflow'

import WorkflowSectionItem from './WorkflowSectionItem'

export interface OrderWorkflowProps {
  disabled: boolean
  showDisabledTooltip?: boolean
}

const workflow = PrescriptionWorkflowType.ORDER
const label = PrescriptionWorkflowName[workflow]

const OrderWorkflow = ({
  disabled,
  showDisabledTooltip = true,
}: OrderWorkflowProps) => {
  const { t } = useTranslation('Tooltips')

  const disabledTooltip = t('Tooltips:WORKFLOW_TOOLTIP')

  return (
    <WorkflowSectionItem
      disabled={disabled}
      disabledTooltip={disabled && showDisabledTooltip ? disabledTooltip : ''}
      label={label}
      workflow={workflow}
    />
  )
}

export default OrderWorkflow
