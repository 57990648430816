import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import i18n from '~/locales/i18n'
import {
  fetchInventoriesList,
  fetchMoreItemsForInventoriesList,
  setInventoriesListFilters,
} from '~/store/actions/inventories'
import {
  getInventoriesList,
  getInventoriesListFilters,
  getInventoryIsReceiving,
  getInventoryIsSending,
  getMultipleInventories,
  getTotalInventoryCount,
} from '~/store/reducers/inventories'
import { TableFilter } from '~/types'
import { addSearch, getUrlSearchParam } from '~/utils'

import InventoryCategoryCell from './InventoryCategoryCell'
import InventoryDetails from './InventoryDetails'
import InventorySubCategoryCell from './InventorySubCategoryCell'
import InventoryTableCategoryFilter from './InventoryTableCategoryFilter'
import InventoryTableFlagIcon from './InventoryTableFlagIcon'
import NoInventoriesScreen from './NoInventoriesScreen'

const columns = [
  {
    label: i18n.t('Common:NAME'),
    prop: 'name',
    highlight: true,
    width: 4,
  },
  {
    label: i18n.t('Common:CATEGORY'),
    prop: InventoryCategoryCell,
    filter: 'categoryIds',
    FilterComponent: InventoryTableCategoryFilter,
    width: 5,
  },
  {
    label: i18n.t('Common:SUB-CATEGORY'),
    prop: InventorySubCategoryCell,
    width: 4,
  },
  {
    label: i18n.t('Common:SPECIES'),
    prop: SpeciesCell,
    width: 2,
  },
  {
    label: i18n.t('Common:CONTROLLED'),
    prop: InventoryTableFlagIcon,
    width: 1,
  },
]

export interface InventoryTableComponentProps {
  headerButtons?: React.ReactNode
  headerTabs?: React.ReactNode
  itemId?: string
  onDetailsClose: () => void
}

const InventoryTableComponent = ({
  itemId,
  headerButtons,
  headerTabs,
  onDetailsClose,
}: InventoryTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const inventoriesList = useSelector(getInventoriesList)
  const inventories = useSelector(getMultipleInventories(inventoriesList))
  const totalCount = useSelector(getTotalInventoryCount)
  const inventoryIsReceiving = useSelector(getInventoryIsReceiving)
  const inventoryIsSending = useSelector(getInventoryIsSending)
  const filters = useSelector(getInventoriesListFilters)
  const isLoading = inventoryIsSending || inventoryIsReceiving
  const { t } = useTranslation('Common')

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setInventoriesListFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setInventoriesListFilters({}))
  }

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchInventoriesList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search, filters])

  const navigateToInventory = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/inventories/catalog/${id}`))
  }

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForInventoriesList(startIndex, endIndex, search))
  }

  const isItemLoaded = (index: number) => Boolean(inventories[index])

  return (
    <ExpandableTable
      Expander={InventoryDetails}
      NoItemsScreen={NoInventoriesScreen}
      hasSelectedFilters={!isEmpty(filters)}
      headerButtons={headerButtons}
      headerTabs={headerTabs}
      isLoading={isLoading}
      itemId={itemId}
      list={inventories}
      searchTerm={search}
      title={t('Common:INVENTORY')}
      onClose={onDetailsClose}
      onSelected={navigateToInventory}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default InventoryTableComponent
