import React from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, IconButton, TextFieldProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { PuiTextField, Utils } from '@pbt/pbt-ui-components'

import useClickAndHold from '~/utils/useClickAndHold'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 40,
      position: 'relative',
    },
    offsetArrowContainer: {
      position: 'absolute',
    },
    offsetArrowContainerTop: {
      top: -28,
    },
    offsetArrowContainerBottom: {
      bottom: -38,
    },
    iconButton: {
      color: theme.colors.selectedOption,
      padding: 5,
      '&:hover': {
        color: theme.colors.toggleIcon,
      },
    },
    input: {
      textAlign: 'center',
    },
  }),
  { name: 'InputWithArrows' },
)

export interface InputWithArrowsProps {
  decrement: () => void
  increment: () => void
  maxLength?: number
  offsetArrows?: boolean
  onBlur?: () => void
  onEnter?: () => void
  onFocus?: () => void
  onTextFieldChange: (value: string) => void
  value: TextFieldProps['value']
}

const InputWithArrows = ({
  offsetArrows = false,
  value,
  increment,
  decrement,
  onTextFieldChange,
  onBlur,
  onFocus,
  onEnter = R.F,
  maxLength = 3,
}: InputWithArrowsProps) => {
  const classes = useStyles()
  const clickAndHoldIncrementEvents = useClickAndHold(increment, 300)
  const clickAndHoldDecrementEvents = useClickAndHold(decrement, 300)

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      direction="column"
    >
      <Grid
        item
        className={classNames({
          [classes.offsetArrowContainer]: offsetArrows,
          [classes.offsetArrowContainerTop]: offsetArrows,
        })}
      >
        <IconButton
          className={classes.iconButton}
          tabIndex={-1}
          {...clickAndHoldIncrementEvents}
          size="large"
        >
          <ExpandLess />
        </IconButton>
      </Grid>
      <Grid item>
        <PuiTextField
          inputProps={{ maxLength, className: classes.input }}
          margin="none"
          value={value}
          onBlur={onBlur}
          onChange={Utils.handleFormTextInput(onTextFieldChange)}
          onFocus={onFocus}
          onKeyDown={(event) => {
            if (event.key === 'ArrowUp') {
              increment()
            } else if (event.key === 'ArrowDown') {
              decrement()
            } else if (event.key === 'Enter') {
              onEnter()
            }
          }}
        />
      </Grid>
      <Grid
        item
        className={classNames({
          [classes.offsetArrowContainer]: offsetArrows,
          [classes.offsetArrowContainerBottom]: offsetArrows,
        })}
        mt={0.5}
      >
        <IconButton
          className={classes.iconButton}
          tabIndex={-1}
          {...clickAndHoldDecrementEvents}
          size="large"
        >
          <ExpandMore />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default InputWithArrows
