import { useSelector } from 'react-redux'
import {
  AppointmentEventType,
  BaseEventType,
  EventTypeConstant,
  EventTypeName,
  LanguageUtils,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import { AppointmentState } from '~/constants/appointmentStates'
import { getEventType } from '~/store/reducers/constants'

export function useEvent(eventTypeName: EventTypeName) {
  const EventType: EventTypeConstant = useSelector(getEventType)
  const CurrentEvent = Utils.findConstantByName(eventTypeName, EventType) || {}

  return CurrentEvent
}

export function useEventType(
  eventTypeName: EventTypeName,
  from: keyof Pick<BaseEventType<EventTypeName>, 'states' | 'subTypes'>,
) {
  const CurrentEvent = useEvent(eventTypeName)

  return CurrentEvent[from] || []
}

export const getDisplayNameFromAppointmentType = (
  appointmentTypeName: string | Nil,
  AppointmentSubTypes: AppointmentEventType['subTypes'],
) =>
  LanguageUtils.getTranslatedNameByRawName(
    appointmentTypeName || '',
    AppointmentSubTypes,
  )

export const useAppointmentStateId = (stateName: AppointmentState) => {
  const EventType = useSelector(getEventType)

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const eventStateId = Utils.findConstantIdByName(
    stateName,
    AppointmentEvent.states,
  )

  return eventStateId
}
