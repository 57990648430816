import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Text, TextProps } from '@pbt/pbt-ui-components'

import { openIntercom } from '~/store/duck/intercom'

import Link from './link/Link'

const LetUsKnowLink = (props: TextProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    dispatch(openIntercom())
  }

  return (
    <Text {...props}>
      {t('Common:LET_US_KNOW_LINK.DESCRIPTION_PREFIX')}&nbsp;
      <Link to="#" onClick={handleClick}>
        {t('Common:LET_US_KNOW_LINK.DESCRIPTION_SUFFIX')}
      </Link>
    </Text>
  )
}

export default LetUsKnowLink
