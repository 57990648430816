import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  Business,
  ButtonWithLoader,
  Nil,
  Patient,
  PuiDialog,
  User,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import EmailForm, { EmailFormHandle } from '~/components/common/email/EmailForm'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import i18n from '~/locales/i18n'
import { emailLabResult } from '~/store/actions/communications'
import { getIsSendingResultEmail } from '~/store/duck/labOrders'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { EmailEntityConfig } from '~/types'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'
import { useSaveEmailAndSendMessage } from '~/utils/useSaveEmailAndSendMessage'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(2),
    },
    button: {
      width: 167,
    },
  }),
  { name: 'EmailOrderResultDialog' },
)

const getPatientName = R.propOr(`[${i18n.t('Common:PATIENT_NAME')}]`, 'name')
const getClientName = R.propOr(`[${i18n.t('Common:CLIENT_NAME')}]`, 'firstName')
const getBusinessName = R.propOr(
  `[${i18n.t('Businesses:PRACTICE_NAME')}]`,
  'name',
)

const buildMessage = (
  business: Business | Nil,
  client: User | Nil,
  patient: Patient | Nil,
) =>
  [
    i18n.t('Mock:EMAIL.LAB_BODY_MESSAGE.LINE_01', {
      clientName: getClientName(client),
    }),
    '',
    i18n.t('Mock:EMAIL.LAB_BODY_MESSAGE.LINE_02', {
      patientName: getPatientName(patient),
    }),
    '',
    i18n.t('Mock:EMAIL.LAB_BODY_MESSAGE.LINE_03'),
    '',
    i18n.t('Mock:EMAIL.LAB_BODY_MESSAGE.LINE_04', {
      businessName: getBusinessName(business),
    }),
  ].join('<br />')

const buildSubject = (patient: Patient | Nil) =>
  i18n.t('Mock:EMAIL.LAB_SUBJECT', { patientName: getPatientName(patient) })

export interface EmailOrderResultDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  orderId: string | Nil
  patientId: string | Nil
  soapId: string | Nil
  vendorId: string | Nil
}

const EmailOrderResultDialog = ({
  clientId,
  patientId,
  orderId,
  vendorId,
  soapId,
  open,
  onClose,
}: EmailOrderResultDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsSendingResultEmail)
  const business = useSelector(getCurrentBusiness)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const { t } = useTranslation(['Common', 'Dialogs'])

  const emailFormRef = useRef<EmailFormHandle>(null)

  const displayConversationCreationResult = useCreatedConversationsInfo({
    getIsConversationCreating: getIsSendingResultEmail,
    onConversationCreationSuccess: onClose,
  })

  const initialSubject = buildSubject(patient)
  const initialMessage = buildMessage(business, client, patient)

  const {
    fields: { subject, message, to },
    validate,
  } = useNewConversationValidationForm(
    { transport: ConversationTransport.Email, client },
    { initialSubject, initialMessage },
  )

  const formRecipients = () => emailFormRef.current?.formRecipients() || []

  const send = () => {
    if (validate()) {
      const emailData: EmailEntityConfig = {
        vendorId,
        orderId,
        recipients: formRecipients(),
        subject: subject.value,
        message: message.value,
      }
      displayConversationCreationResult()
      dispatch(emailLabResult(emailData))
    }
  }

  const handleAskForSaveEmailAndSend = useSaveEmailAndSendMessage(
    send,
    client,
    formRecipients,
    to.value,
  )
  const handleSendClick = () => validate() && handleAskForSaveEmailAndSend()

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSendClick}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="email-order-results-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={t('Dialogs:EMAIL_ORDER_RESULT_DIALOG.EMAIL_LAB_RESULT')}
      onClose={onClose}
    >
      <EmailForm
        clientId={clientId}
        message={message}
        patientId={patientId}
        ref={emailFormRef}
        soapId={soapId}
        subject={subject}
        to={to}
      />
      <Grid item mt={2}>
        <RequiredFieldsNotice />
      </Grid>
    </PuiDialog>
  )
}

export default EmailOrderResultDialog
