import React, { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import WellnessPlanReview, {
  WellnessPlanReviewProps,
} from './WellnessPlanReview'

export interface WellnessPlanFinalReviewProps
  extends Omit<WellnessPlanReviewProps, 'title'> {}

const WellnessPlanFinalReview = forwardRef(function WellnessPlanFinalReview(
  props: WellnessPlanFinalReviewProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { t } = useTranslation('Common')

  return (
    <WellnessPlanReview
      showPrice
      ref={ref}
      title={`${t('Common:CONGRATULATIONS_YOU_ARE_ALL_DONE')}.`}
      {...props}
    />
  )
})

export default WellnessPlanFinalReview
