import React from 'react'
import { makeStyles } from '@mui/styles'
import { ClassesType, TextInteractive } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    text: {
      cursor: 'pointer',
    },
  }),
  { name: 'TableTextWithClickCell' },
)

interface TableTextWithClickCellProps {
  classes?: ClassesType<typeof useStyles>
  isLoading?: boolean
  onClick: () => void
  value: string
}

export const TableTextWithClickCell = ({
  classes: classesProp,
  isLoading = false,
  value,
  onClick,
}: TableTextWithClickCellProps) => {
  const classes = useStyles({ classes: classesProp })

  if (!value) {
    return null
  }

  return (
    <TextInteractive
      underline
      className={classes.text}
      isLoading={isLoading}
      variant="body2"
      onClick={onClick}
    >
      {value}
    </TextInteractive>
  )
}
