import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Nil } from '@pbt/pbt-ui-components'
import { Boop, MobilePhone } from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getBoopVariations, getFeatureToggle } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'

import TooltipChip from './TooltipChip'

const useStyles = makeStyles(() => ({
  phoneIcon: {
    color: 'white',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}))

export interface ClientMobileAppChipProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  clientId: string | Nil
}

const ClientMobileAppChip = ({
  clientId,
  className,
  classes: classesProp,
}: ClientMobileAppChipProps) => {
  const classes = useStyles({ classes: classesProp })
  const client = useSelector(getUser(clientId))
  const business = useSelector(getCurrentBusiness)
  const boopVariations = useSelector(getBoopVariations)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const boopVariation = business
    ? boopVariations?.filter((a) => a.id === business?.boopVariationId)?.[0]
    : undefined
  const isBoopVariation = boopVariation?.name
    ? boopVariation.name.toLowerCase().includes('boop')
    : true

  if (!client?.isBoopUser || isBoopDisablementEnabled) {
    return null
  }

  return (
    <TooltipChip
      className={className}
      icon={
        isBoopVariation ? (
          <Boop className={classNames(classes.icon)} />
        ) : (
          <MobilePhone
            className={classNames(classes.phoneIcon, classes.icon)}
          />
        )
      }
      tooltipPlacement="top"
      tooltipText={boopVariation?.name}
    />
  )
}

export default ClientMobileAppChip
