import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Business } from '@pbt/pbt-ui-components'

import { updateBusinessCandidate } from '~/store/actions/registration'
import { getRegistrationBusinessCandidate } from '~/store/reducers/registration'

import ClinicLogoUpload from './ClinicLogoUpload'

const ClinicLogoUploadComponent = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const dispatch = useDispatch()
  const clinic = useSelector(getRegistrationBusinessCandidate)

  if (!clinic) {
    return <Navigate replace to="/register/clinic/edit" />
  }

  const onUploaded = (logo: Business['logo']) => {
    dispatch(updateBusinessCandidate({ ...clinic, logo }))
    goBack()
  }

  const onCancel = () => {
    goBack()
  }

  return <ClinicLogoUpload onCancel={onCancel} onUploaded={onUploaded} />
}

export default ClinicLogoUploadComponent
