import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  ReminderProtocol,
  ReminderProtocolGroup,
  UnsavedReminderProtocolGroup,
} from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage, getServerValidationError } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_REMINDER_PROTOCOL_GROUPS =
  'reminderProtocols/FETCH_REMINDER_PROTOCOL_GROUPS'
export const FETCH_REMINDER_PROTOCOL_GROUPS_SUCCESS =
  'reminderProtocols/FETCH_REMINDER_PROTOCOL_GROUPS_SUCCESS'
export const FETCH_REMINDER_PROTOCOL_GROUPS_FAILURE =
  'reminderProtocols/FETCH_REMINDER_PROTOCOL_GROUPS_FAILURE'

export const FETCH_MORE_REMINDER_PROTOCOL_GROUPS =
  'reminderProtocols/FETCH_MORE_REMINDER_PROTOCOL_GROUPS'
export const FETCH_MORE_REMINDER_PROTOCOL_GROUPS_SUCCESS =
  'reminderProtocols/FETCH_MORE_REMINDER_PROTOCOL_GROUPS_SUCCESS'
export const FETCH_MORE_REMINDER_PROTOCOL_GROUPS_FAILURE =
  'reminderProtocols/FETCH_MORE_REMINDER_PROTOCOL_GROUPS_FAILURE'

export const UPDATE_REMINDER_PROTOCOL_GROUPS =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL_GROUPS'

export const FETCH_REMINDER_PROTOCOL_GROUP =
  'reminderProtocols/FETCH_REMINDER_PROTOCOL_GROUP'
export const FETCH_REMINDER_PROTOCOL_GROUP_SUCCESS =
  'reminderProtocols/FETCH_REMINDER_PROTOCOL_GROUP_SUCCESS'
export const FETCH_REMINDER_PROTOCOL_GROUP_FAILURE =
  'reminderProtocols/FETCH_REMINDER_PROTOCOL_GROUP_FAILURE'

export const UPDATE_REMINDER_PROTOCOL_GROUP =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL_GROUP'
export const UPDATE_REMINDER_PROTOCOL_GROUP_SUCCESS =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL_GROUP_SUCCESS'
export const UPDATE_REMINDER_PROTOCOL_GROUP_FAILURE =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL_GROUP_FAILURE'

export const DELETE_REMINDER_PROTOCOL_GROUP =
  'reminderProtocols/DELETE_REMINDER_PROTOCOL_GROUP'
export const DELETE_REMINDER_PROTOCOL_GROUP_SUCCESS =
  'reminderProtocols/DELETE_REMINDER_PROTOCOL_GROUP_SUCCESS'
export const DELETE_REMINDER_PROTOCOL_GROUP_FAILURE =
  'reminderProtocols/DELETE_REMINDER_PROTOCOL_GROUP_FAILURE'

export const CREATE_REMINDER_PROTOCOL_GROUP =
  'reminderProtocols/CREATE_REMINDER_PROTOCOL_GROUP'
export const CREATE_REMINDER_PROTOCOL_GROUP_SUCCESS =
  'reminderProtocols/CREATE_REMINDER_PROTOCOL_GROUP_SUCCESS'
export const CREATE_REMINDER_PROTOCOL_GROUP_FAILURE =
  'reminderProtocols/CREATE_REMINDER_PROTOCOL_GROUP_FAILURE'

export const CREATE_REMINDER_PROTOCOL =
  'reminderProtocols/CREATE_REMINDER_PROTOCOL'
export const CREATE_REMINDER_PROTOCOL_SUCCESS =
  'reminderProtocols/CREATE_REMINDER_PROTOCOL_SUCCESS'
export const CREATE_REMINDER_PROTOCOL_FAILURE =
  'reminderProtocols/CREATE_REMINDER_PROTOCOL_FAILURE'

export const UPDATE_REMINDER_PROTOCOL =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL'
export const UPDATE_REMINDER_PROTOCOL_SUCCESS =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL_SUCCESS'
export const UPDATE_REMINDER_PROTOCOL_FAILURE =
  'reminderProtocols/UPDATE_REMINDER_PROTOCOL_FAILURE'

export const DELETE_REMINDER_PROTOCOL =
  'reminderProtocols/DELETE_REMINDER_PROTOCOL'
export const DELETE_REMINDER_PROTOCOL_SUCCESS =
  'reminderProtocols/DELETE_REMINDER_PROTOCOL_SUCCESS'
export const DELETE_REMINDER_PROTOCOL_FAILURE =
  'reminderProtocols/DELETE_REMINDER_PROTOCOL_FAILURE'

export const FETCH_REMINDER_PROTOCOLS_SEARCH_LIST =
  'reminderProtocols/FETCH_REMINDER_PROTOCOLS_SEARCH_LIST'
export const FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS =
  'reminderProtocols/FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS'
export const FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE =
  'reminderProtocols/FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE'

export const CLEAR_REMINDER_PROTOCOL_VALIDATION_ERROR =
  'reminderProtocols/CLEAR_REMINDER_PROTOCOL_VALIDATION_ERROR'

export const FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST =
  'reminderProtocols/FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST'
export const FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS =
  'reminderProtocols/FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS'
export const FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE =
  'reminderProtocols/FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE'

export const fetchReminderProtocolGroups = (
  from: number,
  to: number,
  query: string | Nil,
) => ({
  type: FETCH_REMINDER_PROTOCOL_GROUPS,
  from,
  to,
  query,
})
export const fetchReminderProtocolGroupsSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_REMINDER_PROTOCOL_GROUPS_SUCCESS,
  list,
  totalCount,
})
export const fetchReminderProtocolGroupsFailure = (error: ApiError) => ({
  type: FETCH_REMINDER_PROTOCOL_GROUPS_FAILURE,
  error,
})

export const fetchMoreReminderProtocolGroups = (
  from: number,
  to: number,
  query: string | Nil,
) => ({
  type: FETCH_MORE_REMINDER_PROTOCOL_GROUPS,
  from,
  to,
  query,
})
export const fetchMoreReminderProtocolGroupsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_REMINDER_PROTOCOL_GROUPS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreReminderProtocolGroupsFailure = (error: ApiError) => ({
  type: FETCH_MORE_REMINDER_PROTOCOL_GROUPS_FAILURE,
  error,
})

export const updateReminderProtocolGroups = (
  reminderProtocolGroups: Record<string, ReminderProtocolGroup>,
) => ({
  type: UPDATE_REMINDER_PROTOCOL_GROUPS,
  reminderProtocolGroups,
})

export const fetchReminderProtocolGroup = (groupId: string) => ({
  type: FETCH_REMINDER_PROTOCOL_GROUP,
  groupId,
})
export const fetchReminderProtocolGroupSuccess = (groupId: string) => ({
  type: FETCH_REMINDER_PROTOCOL_GROUP_SUCCESS,
  groupId,
})
export const fetchReminderProtocolGroupFailure = (error: ApiError) => ({
  type: FETCH_REMINDER_PROTOCOL_GROUP_FAILURE,
  error,
})

export const createReminderProtocolGroup = (
  group: UnsavedReminderProtocolGroup,
) => ({
  type: CREATE_REMINDER_PROTOCOL_GROUP,
  group,
})
export const createReminderProtocolGroupSuccess = (groupId: string) => ({
  type: CREATE_REMINDER_PROTOCOL_GROUP_SUCCESS,
  groupId,
})
export const createReminderProtocolGroupFailure = (error: ApiError) => ({
  type: CREATE_REMINDER_PROTOCOL_GROUP_FAILURE,
  error,
})

export const updateReminderProtocolGroup = (group: ReminderProtocolGroup) => ({
  type: UPDATE_REMINDER_PROTOCOL_GROUP,
  group,
})
export const updateReminderProtocolGroupSuccess = (groupId: string) => ({
  type: UPDATE_REMINDER_PROTOCOL_GROUP_SUCCESS,
  groupId,
})
export const updateReminderProtocolGroupFailure = (error: ApiError) => ({
  type: UPDATE_REMINDER_PROTOCOL_GROUP_FAILURE,
  error,
})

export const deleteReminderProtocolGroup = (groupId: string) => ({
  type: DELETE_REMINDER_PROTOCOL_GROUP,
  groupId,
})
export const deleteReminderProtocolGroupSuccess = (groupId: string) => ({
  type: DELETE_REMINDER_PROTOCOL_GROUP_SUCCESS,
  groupId,
})
export const deleteReminderProtocolGroupFailure = (error: ApiError) => ({
  type: DELETE_REMINDER_PROTOCOL_GROUP_FAILURE,
  error,
})

export const createReminderProtocol = (
  groupId: string,
  protocol: ReminderProtocol,
) => ({
  type: CREATE_REMINDER_PROTOCOL,
  groupId,
  protocol,
})
export const createReminderProtocolSuccess = (
  groupId: string,
  protocol: ReminderProtocol,
) => ({
  type: CREATE_REMINDER_PROTOCOL_SUCCESS,
  groupId,
  protocol,
})
export const createReminderProtocolFailure = (error: ApiError) => ({
  type: CREATE_REMINDER_PROTOCOL_FAILURE,
  error,
})

export const updateReminderProtocol = (
  groupId: string,
  protocol: ReminderProtocol,
) => ({
  type: UPDATE_REMINDER_PROTOCOL,
  groupId,
  protocol,
})
export const updateReminderProtocolSuccess = (
  groupId: string,
  protocol: ReminderProtocol,
) => ({
  type: UPDATE_REMINDER_PROTOCOL_SUCCESS,
  groupId,
  protocol,
})
export const updateReminderProtocolFailure = (error: ApiError) => ({
  type: UPDATE_REMINDER_PROTOCOL_FAILURE,
  error,
})

export const deleteReminderProtocol = (
  groupId: string,
  protocolId: string,
) => ({
  type: DELETE_REMINDER_PROTOCOL,
  groupId,
  protocolId,
})
export const deleteReminderProtocolSuccess = (
  groupId: string,
  protocolId: string,
) => ({
  type: DELETE_REMINDER_PROTOCOL_SUCCESS,
  groupId,
  protocolId,
})
export const deleteReminderProtocolFailure = (error: ApiError) => ({
  type: DELETE_REMINDER_PROTOCOL_FAILURE,
  error,
})

export const fetchReminderProtocolsSearchList = (
  query: string | Nil,
  speciesId: string | Nil,
  from?: number,
  to?: number,
) => ({
  type: FETCH_REMINDER_PROTOCOLS_SEARCH_LIST,
  query,
  speciesId,
  from,
  to,
})
export const fetchReminderProtocolsSearchListSuccess = (
  items: ReminderProtocol[],
  totalCount: number,
) => ({
  type: FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS,
  items,
  totalCount,
})
export const fetchReminderProtocolsSearchListFailure = (error: ApiError) => ({
  type: FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE,
  error,
})

export const fetchMoreReminderProtocolsSearchList = (
  query: string | Nil,
  speciesId: string | Nil,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST,
  query,
  speciesId,
  from,
  to,
})
export const fetchMoreReminderProtocolsSearchListSuccess = (
  items: ReminderProtocol[],
  totalCount: number,
) => ({
  type: FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS,
  items,
  totalCount,
})
export const fetchMoreReminderProtocolsSearchListFailure = (
  error: ApiError,
) => ({
  type: FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE,
  error,
})

export const clearReminderProtocolValidationError = () => ({
  type: CLEAR_REMINDER_PROTOCOL_VALIDATION_ERROR,
})

export type ReminderProtocolsState = {
  error: string | null
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  isSearchItemsLoading: boolean
  lastCreatedReminderProtocolGroupId: string | null
  list: string[]
  map: Record<string, ReminderProtocolGroup>
  searchItems: ReminderProtocol[]
  searchTotalCount: undefined | number
  totalCount: number
  validationError: string | null
}

const INITIAL_STATE: ReminderProtocolsState = {
  list: [],
  map: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  searchItems: [],
  searchTotalCount: undefined,
  isLoading: false,
  isFetching: false,
  isFetchingList: false,
  isSearchItemsLoading: false,
  error: null,
  validationError: null,
  lastCreatedReminderProtocolGroupId: null,
}

export const reminderProtocolReducer = (
  state: ReminderProtocolsState = INITIAL_STATE,
  action: AnyAction,
): ReminderProtocolsState => {
  switch (action.type) {
    case FETCH_REMINDER_PROTOCOL_GROUPS:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        error: null,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case FETCH_REMINDER_PROTOCOL_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        list: action.list,
        totalCount: action.totalCount,
      }
    case FETCH_REMINDER_PROTOCOL_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_REMINDER_PROTOCOL_GROUPS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.reminderProtocolGroups),
      }
    case FETCH_MORE_REMINDER_PROTOCOL_GROUPS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_REMINDER_PROTOCOL_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_REMINDER_PROTOCOL_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_REMINDER_PROTOCOL_GROUP: {
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        error: null,
        validationError: null,
      }
    }
    case FETCH_REMINDER_PROTOCOL_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
      }
    }
    case FETCH_REMINDER_PROTOCOL_GROUP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    }
    case CREATE_REMINDER_PROTOCOL_GROUP:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case CREATE_REMINDER_PROTOCOL_GROUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: [action.groupId, ...state.list],
        totalCount: state.totalCount + 1,
        lastCreatedReminderProtocolGroupId: action.groupId,
      }
    }
    case CREATE_REMINDER_PROTOCOL_GROUP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    }
    case UPDATE_REMINDER_PROTOCOL_GROUP:
      return {
        ...state,
        isLoading: true,
        error: null,
        validationError: null,
      }
    case UPDATE_REMINDER_PROTOCOL_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_REMINDER_PROTOCOL_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        validationError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
      }
    case CLEAR_REMINDER_PROTOCOL_VALIDATION_ERROR:
      return {
        ...state,
        validationError: null,
      }
    case DELETE_REMINDER_PROTOCOL_GROUP:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case DELETE_REMINDER_PROTOCOL_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: R.without([action.groupId], state.list),
        map: R.omit([action.groupId], state.map),
        totalCount: state.totalCount - 1,
      }
    case DELETE_REMINDER_PROTOCOL_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_REMINDER_PROTOCOL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case CREATE_REMINDER_PROTOCOL_SUCCESS: {
      const newGroup = {
        ...state.map[action.groupId],
        protocols: [...state.map[action.groupId].protocols, action.protocol],
      }
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.groupId]: newGroup,
        },
      }
    }
    case CREATE_REMINDER_PROTOCOL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_REMINDER_PROTOCOL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case UPDATE_REMINDER_PROTOCOL_SUCCESS: {
      const newGroup = {
        ...state.map[action.groupId],
        protocols: state.map[action.groupId].protocols.map((protocol) =>
          protocol.id === action.protocol.id ? action.protocol : protocol,
        ),
      }
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.groupId]: newGroup,
        },
      }
    }
    case UPDATE_REMINDER_PROTOCOL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_REMINDER_PROTOCOL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case DELETE_REMINDER_PROTOCOL_SUCCESS: {
      const newGroup = {
        ...state.map[action.groupId],
        protocols: state.map[action.groupId].protocols.filter(
          (protocol) => protocol.id !== action.protocolId,
        ),
      }

      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.groupId]: newGroup,
        },
      }
    }
    case DELETE_REMINDER_PROTOCOL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_REMINDER_PROTOCOLS_SEARCH_LIST: {
      return {
        ...state,
        isSearchItemsLoading: true,
        error: null,
      }
    }
    case FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS: {
      return {
        ...state,
        isSearchItemsLoading: false,
        searchItems: action.items,
        searchTotalCount: action.totalCount,
      }
    }
    case FETCH_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE: {
      return {
        ...state,
        isSearchItemsLoading: false,
        error: getErrorMessage(action.error),
      }
    }
    case FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST: {
      return {
        ...state,
        isSearchItemsLoading: true,
        error: null,
      }
    }
    case FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_SUCCESS: {
      return {
        ...state,
        isSearchItemsLoading: false,
        searchItems: [...state.searchItems, ...action.items],
        searchTotalCount: action.totalCount,
      }
    }
    case FETCH_MORE_REMINDER_PROTOCOLS_SEARCH_LIST_FAILURE: {
      return {
        ...state,
        isSearchItemsLoading: false,
        error: getErrorMessage(action.error),
      }
    }
    default:
      return state
  }
}

const getReminderProtocols = (state: RootState): ReminderProtocolsState =>
  state.reminderProtocols
export const getReminderProtocolsIsLoading = (state: RootState) =>
  getReminderProtocols(state).isLoading
export const getReminderProtocolsIsFetching = (state: RootState) =>
  getReminderProtocols(state).isFetching
export const getReminderProtocolsIsFetchingList = (state: RootState) =>
  getReminderProtocols(state).isFetchingList
export const getReminderProtocolsTotalCount = (state: RootState) =>
  getReminderProtocols(state).totalCount
export const getReminderProtocolGroupsList = (state: RootState) =>
  getReminderProtocols(state).list
export const getReminderProtocolGroupsMap = (state: RootState) =>
  getReminderProtocols(state).map
export const getReminderProtocolGroup = (id: string) =>
  createSelector(getReminderProtocolGroupsMap, (map) =>
    id ? map[id] : undefined,
  )
export const getMultipleReminderProtocolGroups = (ids: string[]) =>
  createSelector(getReminderProtocolGroupsMap, R.props(ids))
export const getSearchItems = (state: RootState) =>
  getReminderProtocols(state).searchItems
export const getSearchTotalCount = (state: RootState) =>
  getReminderProtocols(state).searchTotalCount
export const geIstSearchItemsLoading = (state: RootState) =>
  getReminderProtocols(state).isSearchItemsLoading
export const getReminderProtocolValidationError = (state: RootState) =>
  getReminderProtocols(state).validationError
export const getLastCreatedReminderProtocolGroupId = (state: RootState) =>
  getReminderProtocols(state).lastCreatedReminderProtocolGroupId
