import { DymoLabelType } from '~/constants/dymo'

import { getAPIPath, request } from './core'

export const fetchDymoLabelXML = (businessId: string, labelId: string) =>
  request(
    getAPIPath(`labelTemplate/${labelId}`),
    {
      method: 'GET',
      params: { businessId, id: labelId },
    },
    true,
  )

export const fetchHtmlLabel = (
  businessId: string,
  labelId: string,
  deviceType: string,
  labelType: DymoLabelType,
) =>
  request(
    getAPIPath(`labelTemplate/html/${labelId}`),
    {
      method: 'GET',
      params: { businessId, deviceType, labelType },
    },
    true,
  )
