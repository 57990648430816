import React from 'react'
import { useSelector } from 'react-redux'
import { Field, Text } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { InvoiceLineItem } from '~/types'

import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeFieldRemainingQuantityProps {
  item: InvoiceLineItem
  remainingQuantityField: Field
}

const ChargeFieldRemainingQuantity = ({
  item,
  remainingQuantityField,
}: ChargeFieldRemainingQuantityProps) => {
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  if (!item.prepaid && !isEditPostedChargesEnabled) {
    return null
  }

  return (
    <ChargeDetailsFieldWrapper disabled label={remainingQuantityField.label}>
      <Text disabled>{remainingQuantityField.value}</Text>
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeFieldRemainingQuantity
