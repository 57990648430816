import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, Nil, NumberUtils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Payment as GraphQlPayment } from '~/api/graphql/generated/types'
import ErrorTypes from '~/constants/apiErrorTypes'
import apiErrorTypes from '~/constants/apiErrorTypes'
import FeatureToggle from '~/constants/featureToggle'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { COMPLETED } from '~/constants/transactionStatus'
import i18nPortal from '~/locales/i18n'
import { refreshClientBillingData } from '~/store/duck/clientBillingActivityData'
import { getFeatureToggle } from '~/store/reducers/constants'
import { ExtendPayment, UnsavedExtendPayment } from '~/types'
import { detectAPIErrorType, getErrorMessage } from '~/utils/errors'

import {
  addPaymentToInvoices,
  refreshBalanceHistory as refreshBalanceHistoryAction,
} from '../actions/finance'
import {
  applyPaymentToInvoice,
  applyPaymentToInvoiceFailure,
  applyPaymentToInvoiceSuccess,
  chargePayment,
  chargePaymentFailure,
  chargePaymentSuccess,
  clearProcessedBillingAddress,
  createBillingAddress,
  createBillingAddressFailure,
  createBillingAddressSuccess,
  createCreditAdjustment,
  createCreditAdjustmentFailure,
  createCreditAdjustmentSuccess,
  createManualPayment,
  createManualPaymentFailure,
  createManualPaymentSuccess,
  createPayment,
  createPaymentFailure,
  createPaymentSuccess,
  createPaymentTransaction,
  createPaymentTransactionFailure,
  createPaymentTransactionSuccess,
  createStripePayment,
  createStripePaymentFailure,
  createStripePaymentSuccess,
  editGoPayment,
  editGoStripePayment,
  editGoStripePaymentFailure,
  editGoStripePaymentSuccess,
  editPayment,
  editPaymentFailure,
  editPaymentSuccess,
  fetchBillingAddresses,
  fetchBillingAddressesFailure,
  fetchBillingAddressesSuccess,
  fetchGoPayment,
  fetchGoPaymentFailure,
  fetchGoPaymentSuccess,
  fetchPayment,
  fetchPaymentFailure,
  fetchPaymentSuccess,
  getTransactionStatus,
  getTransactionStatusFailure,
  getTransactionStatusSuccess,
  linkUnappliedPayment,
  linkUnappliedPaymentFailure,
  linkUnappliedPayments,
  linkUnappliedPaymentsFailure,
  linkUnappliedPaymentsSuccess,
  linkUnappliedPaymentSuccess,
  refundRhapsodyGoStripeTransaction,
  refundRhapsodyGoTransaction,
  refundRhapsodyGoTransactionFailure,
  refundRhapsodyGoTransactionSuccess,
  rhapsodyGoApplyPayment,
  rhapsodyGoApplyPaymentFailure,
  rhapsodyGoApplyPaymentSuccess,
  rhapsodyGoAuthorization,
  rhapsodyGoAuthorizationFailure,
  rhapsodyGoAuthorizationSuccess,
  settleGoStripeTransaction,
  settleGoStripeTransactionFailure,
  settleGoStripeTransactionSuccess,
  settleGoTransaction,
  settleGoTransactionFailure,
  settleGoTransactionSuccess,
  unlinkPaymentToInvoice,
  unlinkPaymentToInvoiceFailure,
  unlinkPaymentToInvoiceSuccess,
  updateBillingAddress,
  updateBillingAddressFailure,
  updateBillingAddressSuccess,
  updateGoPayments,
  updatePayments,
  updatePendingPaymentState,
  voidRhapsodyGoStripeTransaction,
  voidRhapsodyGoTransaction,
  voidRhapsodyGoTransactionFailure,
  voidRhapsodyGoTransactionSuccess,
} from '../actions/payments'
import { FETCH_BATCH_INVOICE, FETCH_INVOICE } from '../actions/types/finance'
import {
  APPLY_PAYMENT_TO_INVOICE,
  CHARGE_PAYMENT,
  CREATE_BILLING_ADDRESS,
  CREATE_CREDIT_ADJUSTMENT,
  CREATE_CREDIT_ADJUSTMENT_FAILURE,
  CREATE_MANUAL_PAYMENT,
  CREATE_PAYMENT,
  CREATE_PAYMENT_TRANSACTION,
  CREATE_STRIPE_PAYMENT,
  EDIT_GO_PAYMENT,
  EDIT_GO_STRIPE_PAYMENT,
  EDIT_PAYMENT,
  FETCH_BILLING_ADDRESSES,
  FETCH_GO_PAYMENT,
  FETCH_PAYMENT,
  GET_TRANSACTION_STATUS,
  LINK_UNAPPLIED_PAYMENT,
  LINK_UNAPPLIED_PAYMENTS,
  REFUND_RHAPSODY_GO_STRIPE_TRANSACTION,
  REFUND_RHAPSODY_GO_TRANSACTION,
  RHAPSODY_GO_APPLY_PAYMENT,
  RHAPSODY_GO_APPLY_PAYMENT_FAILURE,
  RHAPSODY_GO_AUTHORIZATION,
  SETTLE_GO_STRIPE_TRANSACTION,
  SETTLE_GO_TRANSACTION,
  UNLINK_PAYMENT_TO_INVOICE,
  UPDATE_BILLING_ADDRESS,
  VOID_RHAPSODY_GO_STRIPE_TRANSACTION,
  VOID_RHAPSODY_GO_TRANSACTION,
} from '../actions/types/payments'
import { updateUsers } from '../actions/users'
import { getStatus } from '../duck/errors'
import { registerWarnAlert } from '../duck/uiAlerts'
import { addUiNotification } from '../duck/uiNotifications'
import { fetchBatchInvoiceSaga, fetchInvoiceSaga } from './finance'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

function* fetchPaymentInvoices(payment: UnsavedExtendPayment) {
  if (payment.invoiceIds?.length && payment.invoiceIds.length > 0) {
    if (payment.invoiceIds?.length === 1) {
      yield fetchInvoiceSaga({
        type: FETCH_INVOICE,
        invoiceId: payment.invoiceIds[0],
      })
    } else {
      yield fetchBatchInvoiceSaga({
        type: FETCH_BATCH_INVOICE,
        invoiceIds: payment.invoiceIds || [],
      })
    }
  } else if (payment.invoiceId) {
    yield fetchInvoiceSaga({
      type: FETCH_INVOICE,
      invoiceId: payment.invoiceId,
    })
  }
}

export function* fetchPaymentSaga({
  paymentId,
}: ReturnType<typeof fetchPayment>) {
  try {
    const { entities } = yield* requestAPI(API.fetchPayment, paymentId)
    yield call(updateEntities, entities)
    yield put(fetchPaymentSuccess())
  } catch (error) {
    yield put(fetchPaymentFailure(error as ApiError))
  }
}

export function* fetchGoPaymentSaga({
  goPaymentId,
}: ReturnType<typeof fetchGoPayment>) {
  try {
    const { entities } = yield* requestAPI(API.fetchGoPayment, goPaymentId)
    yield call(updateEntities, entities)
    yield put(fetchGoPaymentSuccess())
  } catch (error) {
    yield put(fetchGoPaymentFailure(error as ApiError))
  }
}

function* onCreatePaymentSuccess({
  payment,
  clientId,
}: {
  clientId: string | Nil
  payment: UnsavedExtendPayment
}): Generator<any, string | null, any> {
  try {
    const { result, entities } = yield* requestAPI(
      API.createPayment,
      clientId,
      payment,
    )

    yield call(updateEntities, entities)
    yield put(createPaymentSuccess(result))
    yield put(addPaymentToInvoices(entities.payments[result]))
    yield fetchPaymentInvoices(entities.payments[result])

    return result
  } catch (err) {
    const error = err as ApiError
    const { message } = error.response?.data || error.responseBody || {}
    const status = getStatus({
      type: CREATE_PAYMENT,
      error,
    })

    if (status === 409 && message) {
      yield put(registerWarnAlert(message))
    }

    yield put(createPaymentFailure(error))
    return null
  }
}

export function* createPaymentSaga({
  clientId,
  payment,
}: ReturnType<typeof createPayment>) {
  yield* onCreatePaymentSuccess({ clientId, payment })
}

export function* createManualPaymentSaga({
  clientId,
  payload,
}: ReturnType<typeof createManualPayment>) {
  try {
    const isIpoM0RevampClientBillingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
    )
    const { entities, result } = yield* requestAPI(
      API.createManualPayment,
      clientId,
      payload,
    )
    yield call(updateEntities, entities)
    yield put(createManualPaymentSuccess(result))
    if (isIpoM0RevampClientBillingEnabled) {
      yield put(refreshClientBillingData())
    }
  } catch (err) {
    const error = err as ApiError
    const errorStatus = getStatus({
      type: CREATE_MANUAL_PAYMENT,
      error,
    })
    const errorMessage = getErrorMessage(error)

    if (
      errorStatus === 409 &&
      error.responseBody?.type === ErrorTypes.STALE_INVOICE_PAYMENT_LINKAGE
    ) {
      yield put(registerWarnAlert(error.responseBody.message))
    } else if (errorMessage) {
      yield put(registerWarnAlert(errorMessage))
    }

    yield put(createManualPaymentFailure(error))
  }
}

export function* getTransactionStatusSaga({
  payment,
}: ReturnType<typeof getTransactionStatus>) {
  try {
    const {
      result,
      entities: { posTransactions, users },
    } = yield* requestAPI(API.getTransactionStatus, payment.id)
    const updatedTransaction = posTransactions[result]
    const transactionPayment = updatedTransaction?.payment

    if (updatedTransaction?.state === COMPLETED && transactionPayment) {
      yield put(updateUsers(users))
      yield put(
        updatePayments({ [updatedTransaction.payment.id]: transactionPayment }),
      )
      yield fetchPaymentInvoices(transactionPayment)
      yield put(refreshBalanceHistoryAction(payment.clientId))
    } else {
      yield put(updatePendingPaymentState(payment))
    }
    yield put(getTransactionStatusSuccess(updatedTransaction))
  } catch (error) {
    yield put(getTransactionStatusFailure(error as ApiError))
  }
}

export function* createPaymentTransactionSaga({
  clientId,
  payment,
  withUnapplied = false,
}: ReturnType<typeof createPaymentTransaction>) {
  try {
    const isIpoM0RevampClientBillingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
    )
    const {
      result,
      entities: { payments },
    } = yield* requestAPI(
      API.createPaymentTransaction,
      clientId,
      payment,
      withUnapplied,
    )
    const pendingPayment = payments[result]
    yield put(createPaymentTransactionSuccess())

    yield getTransactionStatusSaga({
      type: GET_TRANSACTION_STATUS,
      payment: pendingPayment,
    })
    if (isIpoM0RevampClientBillingEnabled) {
      yield put(refreshClientBillingData())
    }
  } catch (err) {
    const error = err as ApiError
    const { message } = error.response?.data || error.responseBody || {}
    const status = getStatus({
      type: CREATE_PAYMENT_TRANSACTION,
      error,
    })

    if (
      status === 409 &&
      error.responseBody?.type === ErrorTypes.STALE_INVOICE_PAYMENT_LINKAGE
    ) {
      yield put(registerWarnAlert(error.responseBody.message))
    } else if (message) {
      yield put(registerWarnAlert(message))
    }

    yield put(createPaymentTransactionFailure(error))
  }
}

export function* fetchBillingAddressesSaga({
  clientId,
}: ReturnType<typeof fetchBillingAddresses>) {
  try {
    const billingAddresses = yield* requestAPI(
      API.fetchBillingAddresses,
      clientId,
    )
    yield put(fetchBillingAddressesSuccess(billingAddresses))
  } catch (error) {
    yield put(fetchBillingAddressesFailure(error as ApiError))
  }
}

export function* createBillingAddressSaga({
  address,
  clientId,
}: ReturnType<typeof createBillingAddress>) {
  try {
    const newAddress = yield* requestAPI(
      API.createBillingAddress,
      address,
      clientId,
    )
    yield put(createBillingAddressSuccess(newAddress))
  } catch (error) {
    yield put(createBillingAddressFailure(error as ApiError))
  }
}

export function* updateBillingAddressSaga({
  address,
  clientId,
}: ReturnType<typeof updateBillingAddress>) {
  try {
    const updatedAddress = yield* requestAPI(
      API.updateBillingAddress,
      address,
      clientId,
    )
    yield put(updateBillingAddressSuccess(updatedAddress))
  } catch (error) {
    yield put(updateBillingAddressFailure(error as ApiError))
  }
}

export function* chargePaymentSaga({
  paymentData,
  transactionType,
}: ReturnType<typeof chargePayment>) {
  try {
    const {
      result,
      entities: { goPayments, ...entities },
    } = yield* requestAPI(API.chargePayment, paymentData, transactionType)
    const currentGoPayment = goPayments[result]
    yield call(updateEntities, entities)
    yield put(clearProcessedBillingAddress())
    yield put(chargePaymentSuccess(currentGoPayment.payment))
    yield put(addPaymentToInvoices(entities.payments[currentGoPayment.payment]))
    yield fetchPaymentInvoices(paymentData)
  } catch (err) {
    const error = err as ApiError
    const errorMessage = getErrorMessage(error)
    yield put(registerWarnAlert(errorMessage))
    yield put(chargePaymentFailure(error))
  }
}

export function* rhapsodyGoApplyPaymentSaga({
  payload,
}: ReturnType<typeof rhapsodyGoApplyPayment>) {
  try {
    const isIpoM0RevampClientBillingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
    )
    const { result, entities } = yield* requestAPI(
      API.rhapsodyGoApplyPayment,
      payload,
    )
    yield call(updateEntities, entities)
    yield put(clearProcessedBillingAddress())
    yield put(rhapsodyGoApplyPaymentSuccess(result))
    if (isIpoM0RevampClientBillingEnabled) {
      yield put(refreshClientBillingData())
    }
  } catch (err) {
    const error = err as ApiError
    const errorStatus = getStatus({
      type: RHAPSODY_GO_APPLY_PAYMENT_FAILURE,
      error,
    })
    const errorMessage = getErrorMessage(error)

    if (
      errorStatus === 409 &&
      error.responseBody?.type === ErrorTypes.STALE_INVOICE_PAYMENT_LINKAGE
    ) {
      yield put(registerWarnAlert(error.responseBody.message))
    } else if (errorMessage) {
      yield put(registerWarnAlert(errorMessage))
    }
    yield put(rhapsodyGoApplyPaymentFailure(error))
  }
}

export function* rhapsodyGoAuthorizationSaga({
  paymentData,
}: ReturnType<typeof rhapsodyGoAuthorization>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.rhapsodyGoAuthorization,
      paymentData,
    )
    yield call(updateEntities, entities)
    yield put(clearProcessedBillingAddress())
    yield put(rhapsodyGoAuthorizationSuccess(result))
  } catch (error) {
    yield put(rhapsodyGoAuthorizationFailure(error as ApiError))
  }
}

export function* refundRhapsodyGoTransactionSaga({
  paymentToRefund,
  transactionType,
}: ReturnType<typeof refundRhapsodyGoTransaction>) {
  try {
    const {
      result,
      entities: { goPayments, users },
    } = yield* requestAPI(
      API.refundRhapsodyGoTransaction,
      paymentToRefund,
      transactionType,
    )
    const refundedPayment = goPayments[result]
    yield put(updateGoPayments(goPayments))
    yield put(updateUsers(users))
    yield put(refreshBalanceHistoryAction(paymentToRefund.personId))
    yield put(refundRhapsodyGoTransactionSuccess(refundedPayment))
  } catch (err) {
    const error = err as ApiError
    const errorMessage = getErrorMessage(error)
    yield put(registerWarnAlert(errorMessage))
    yield put(refundRhapsodyGoTransactionFailure(error as ApiError))
  }
}

export function* refundRhapsodyGoStripeTransactionSaga({
  paymentToRefund,
  transactionType,
}: ReturnType<typeof refundRhapsodyGoStripeTransaction>) {
  try {
    const {
      result,
      entities: { goPayments, users },
    } = yield* requestAPI(
      API.refundRhapsodyGoStripeTransaction,
      paymentToRefund,
      transactionType,
    )
    const refundedPayment = goPayments[result]
    yield put(updateGoPayments(goPayments))
    yield put(updateUsers(users))
    yield put(refreshBalanceHistoryAction(paymentToRefund.personId))
    yield put(refundRhapsodyGoTransactionSuccess(refundedPayment))
  } catch (error) {
    yield put(refundRhapsodyGoTransactionFailure(error as ApiError))
  }
}

export function* voidRhapsodyGoTransactionSaga({
  paymentToVoid,
  transactionType,
}: ReturnType<typeof voidRhapsodyGoTransaction>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.voidRhapsodyGoTransaction,
      paymentToVoid,
      transactionType,
    )
    const { goPayments = {} } = entities
    const voidedPayment = goPayments[result]
    yield call(updateEntities, entities)
    yield fetchPaymentInvoices(voidedPayment)
    yield put(refreshBalanceHistoryAction(paymentToVoid.personId))
    yield put(voidRhapsodyGoTransactionSuccess(voidedPayment))
  } catch (error) {
    yield put(voidRhapsodyGoTransactionFailure(error as ApiError))
  }
}

export function* voidRhapsodyGoStripeTransactionSaga({
  paymentToVoid,
  transactionType,
}: ReturnType<typeof voidRhapsodyGoStripeTransaction>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.voidRhapsodyGoStripeTransaction,
      paymentToVoid,
      transactionType,
    )
    const { goPayments = {} } = entities
    const voidedPayment = goPayments[result]
    yield call(updateEntities, entities)
    yield fetchPaymentInvoices(voidedPayment)
    yield put(refreshBalanceHistoryAction(paymentToVoid.personId))
    yield put(voidRhapsodyGoTransactionSuccess(voidedPayment))
  } catch (error) {
    yield put(voidRhapsodyGoTransactionFailure(error as ApiError))
  }
}

export function* settleGoTransactionSaga({
  transactionToSettle,
}: ReturnType<typeof settleGoTransaction>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.settleGoTransaction,
      transactionToSettle,
    )
    const settledPayment = entities.goPayments[result]
    yield call(updateEntities, entities)
    yield fetchPaymentInvoices(settledPayment)
    yield put(refreshBalanceHistoryAction(transactionToSettle.personId))
    yield put(settleGoTransactionSuccess(settledPayment))
  } catch (error) {
    yield put(settleGoTransactionFailure(error as ApiError))
  }
}

export function* settleGoStripeTransactionSaga({
  transactionToSettle,
}: ReturnType<typeof settleGoStripeTransaction>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.settleGoStripeTransaction,
      transactionToSettle,
    )
    const settledPayment = entities.goStripePayments[result]
    yield call(updateEntities, entities)
    yield fetchPaymentInvoices(settledPayment)
    yield put(refreshBalanceHistoryAction(transactionToSettle.personId))
    yield put(settleGoStripeTransactionSuccess(settledPayment))
  } catch (error) {
    yield put(settleGoStripeTransactionFailure(error as ApiError))
  }
}

export function* applyPaymentToInvoiceSaga({
  paymentId,
  invoiceIds,
}: ReturnType<typeof applyPaymentToInvoice>) {
  try {
    const { entities } = yield* requestAPI(
      API.applyPaymentToInvoice,
      paymentId,
      invoiceIds,
    )
    yield call(updateEntities, entities)
    yield put(applyPaymentToInvoiceSuccess())
  } catch (error) {
    yield put(applyPaymentToInvoiceFailure(error as ApiError))
  }
}

export function* linkUnappliedPaymentSaga({
  invoiceIds,
  paymentId,
}: ReturnType<typeof linkUnappliedPayment>) {
  try {
    yield* requestAPI(API.linkUnappliedPayment, {
      invoiceIds,
      paymentId,
    })
    yield put(linkUnappliedPaymentSuccess())
    yield put(fetchPayment(paymentId))
  } catch (error) {
    yield put(linkUnappliedPaymentFailure(error as ApiError))
  }
}

export function* linkUnappliedPaymentsSaga({
  invoiceIds,
  paymentIds,
}: ReturnType<typeof linkUnappliedPayments>) {
  try {
    yield* requestAPI(API.linkUnappliedPayments, {
      invoiceIds,
      paymentIds,
    })
    yield put(linkUnappliedPaymentsSuccess())
  } catch (error) {
    yield put(linkUnappliedPaymentsFailure(error as ApiError))
  }
}

export function* unlinkPaymentToInvoiceSaga({
  paymentId,
  invoiceId,
}: ReturnType<typeof unlinkPaymentToInvoice>) {
  try {
    yield* requestAPI(API.unlinkPaymentToInvoice, {
      paymentId,
      invoiceId,
    })
    yield put(unlinkPaymentToInvoiceSuccess())
    yield put(fetchPayment(paymentId))
  } catch (error) {
    yield put(unlinkPaymentToInvoiceFailure(error as ApiError))
  }
}

export function* editPaymentSaga({
  paymentId,
  data,
}: ReturnType<typeof editPayment>) {
  try {
    const { entities } = yield* requestAPI(API.editPayment, paymentId, data)
    yield call(updateEntities, entities)
    yield put(editPaymentSuccess())
  } catch (error) {
    yield put(editPaymentFailure(error as ApiError))
  }
}

export function* editGoPaymentSaga({
  paymentId,
  data,
}: ReturnType<typeof editGoPayment>) {
  try {
    const { entities } = yield* requestAPI(API.editGoPayment, paymentId, data)
    yield call(updateEntities, entities)
    yield put(editPaymentSuccess())
  } catch (error) {
    yield put(editPaymentFailure(error as ApiError))
  }
}

export function* editGoStripePaymentSaga({
  paymentId,
  data,
}: ReturnType<typeof editGoStripePayment>) {
  try {
    const { entities } = yield* requestAPI(
      API.editGoStripePayment,
      paymentId,
      data,
    )
    yield call(updateEntities, entities)
    yield put(editGoStripePaymentSuccess())
  } catch (error) {
    yield put(editGoStripePaymentFailure(error as ApiError))
  }
}

export function* createStripePaymentSaga({
  paymentData,
  transactionType,
}: ReturnType<typeof createStripePayment>) {
  try {
    const { clientSecret } = yield* requestAPI(
      API.createStripePayment,
      paymentData,
      transactionType,
    )
    yield put(createStripePaymentSuccess(clientSecret))
  } catch (error) {
    yield put(createStripePaymentFailure(error as ApiError))
  }
}

export function* createCreditAdjustmentSaga({
  adjustmentToRecord,
  clientId,
}: ReturnType<typeof createCreditAdjustment>) {
  try {
    const isIpoM0RevampClientBillingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.IPO_M0_REVAMP_CLIENT_BILLING_PAGE),
    )
    const response: GraphQlPayment = yield* requestAPI(
      API.createCreditAdjustment,
      clientId,
      adjustmentToRecord,
    )
    const paymentId: string = response.id
    const newPayment = {
      amount: response.amount,
      invoiceIds: response.links?.map((link) => link.invoice.id) ?? [],
      id: response.id,
    } as ExtendPayment

    yield put(createPaymentSuccess(paymentId))
    yield put(addPaymentToInvoices(newPayment))
    yield fetchPaymentInvoices(newPayment)
    yield put(createCreditAdjustmentSuccess(newPayment))

    yield put(
      addUiNotification({
        id: uuid(),
        type: SnackNotificationType.CREDIT_ADJUSTMENT,
        message: i18nPortal.t(
          'Dialogs:CREDIT_ADJUSTMENT_DIALOG.TOAST.MESSAGE',
          {
            amount: NumberUtils.formatMoney(adjustmentToRecord.amount!),
          },
        ),
        params: {
          paymentId,
          clientId,
        },
      }),
    )
    if (isIpoM0RevampClientBillingEnabled) {
      yield put(refreshClientBillingData())
    }
  } catch (err) {
    const error = err as ApiError
    const errorStatus = getStatus({
      type: CREATE_CREDIT_ADJUSTMENT_FAILURE,
      error,
    })
    const errorType = detectAPIErrorType(error.responseBody)
    const hasConflictError =
      errorStatus === 409 &&
      errorType === apiErrorTypes.CREDIT_ADJUSTMENT_FOR_PAID_INVOICE_ERROR
    const hasBadRequestError =
      errorStatus === 400 &&
      errorType === apiErrorTypes.CREDIT_ADJUSTMENT_EXCEEDS_INVOICES_TOTAL_ERROR
    const hasConcurrentError = hasConflictError || hasBadRequestError
    yield put(createCreditAdjustmentFailure(error, hasConcurrentError))
  }
}

function* watchFetchPayment() {
  yield takeEvery(FETCH_PAYMENT, fetchPaymentSaga)
}

function* watchFetchGoPayment() {
  yield takeLeading(FETCH_GO_PAYMENT, fetchGoPaymentSaga)
}

function* watchCreatePayment() {
  yield takeLeading(CREATE_PAYMENT, createPaymentSaga)
}

function* watchCreateManualPaymentSaga() {
  yield takeLeading(CREATE_MANUAL_PAYMENT, createManualPaymentSaga)
}

function* watchCreatePaymentTransaction() {
  yield takeLeading(CREATE_PAYMENT_TRANSACTION, createPaymentTransactionSaga)
}

function* watchGetTransactionStatus() {
  yield takeLeading(GET_TRANSACTION_STATUS, getTransactionStatusSaga)
}

function* watchFetchBillingAddresses() {
  yield takeLatest(FETCH_BILLING_ADDRESSES, fetchBillingAddressesSaga)
}

function* watchCreateBillingAddress() {
  yield takeLatest(CREATE_BILLING_ADDRESS, createBillingAddressSaga)
}

function* watchUpdateBillingAddress() {
  yield takeLatest(UPDATE_BILLING_ADDRESS, updateBillingAddressSaga)
}

function* watchChargeSale() {
  yield takeLatest(CHARGE_PAYMENT, chargePaymentSaga)
}

function* watchRhapsodyGoAuthorization() {
  yield takeLatest(RHAPSODY_GO_AUTHORIZATION, rhapsodyGoAuthorizationSaga)
}

function* watchRhapsodyGoApplyPayment() {
  yield takeLatest(RHAPSODY_GO_APPLY_PAYMENT, rhapsodyGoApplyPaymentSaga)
}

function* watchRefundRhapsodyGoTransaction() {
  yield takeLatest(
    REFUND_RHAPSODY_GO_TRANSACTION,
    refundRhapsodyGoTransactionSaga,
  )
}

function* watchRefundRhapsodyGoStripeTransaction() {
  yield takeLatest(
    REFUND_RHAPSODY_GO_STRIPE_TRANSACTION,
    refundRhapsodyGoStripeTransactionSaga,
  )
}

function* watchVoidRhapsodyGoTransaction() {
  yield takeLatest(VOID_RHAPSODY_GO_TRANSACTION, voidRhapsodyGoTransactionSaga)
}

function* watchVoidRhapsodyGoStripeTransaction() {
  yield takeLatest(
    VOID_RHAPSODY_GO_STRIPE_TRANSACTION,
    voidRhapsodyGoStripeTransactionSaga,
  )
}

function* watchSettleGoTransaction() {
  yield takeLatest(SETTLE_GO_TRANSACTION, settleGoTransactionSaga)
}

function* watchSettleGoStripeTransaction() {
  yield takeLatest(SETTLE_GO_STRIPE_TRANSACTION, settleGoStripeTransactionSaga)
}

function* watchApplyPaymentToInvoice() {
  yield takeLatest(APPLY_PAYMENT_TO_INVOICE, applyPaymentToInvoiceSaga)
}

function* watchLinkUnappliedPayment() {
  yield takeLatest(LINK_UNAPPLIED_PAYMENT, linkUnappliedPaymentSaga)
}

function* watchLinkUnappliedPayments() {
  yield takeLatest(LINK_UNAPPLIED_PAYMENTS, linkUnappliedPaymentsSaga)
}

function* watchUnlinkPaymentToInvoice() {
  yield takeLatest(UNLINK_PAYMENT_TO_INVOICE, unlinkPaymentToInvoiceSaga)
}

function* watchEditPayment() {
  yield takeLatest(EDIT_PAYMENT, editPaymentSaga)
}

function* watchEditGoPayment() {
  yield takeLatest(EDIT_GO_PAYMENT, editGoPaymentSaga)
}

function* watchEditGoStripePayment() {
  yield takeLatest(EDIT_GO_STRIPE_PAYMENT, editGoStripePaymentSaga)
}

function* watchCreateStripePayment() {
  yield takeLatest(CREATE_STRIPE_PAYMENT, createStripePaymentSaga)
}

function* watchCreateCreditAdjustment() {
  yield takeLatest(CREATE_CREDIT_ADJUSTMENT, createCreditAdjustmentSaga)
}

export default function* paymentsSaga() {
  yield all([
    watchFetchPayment(),
    watchFetchGoPayment(),
    watchCreatePayment(),
    watchCreateManualPaymentSaga(),
    watchCreatePaymentTransaction(),
    watchGetTransactionStatus(),
    watchFetchBillingAddresses(),
    watchCreateBillingAddress(),
    watchUpdateBillingAddress(),
    watchChargeSale(),
    watchRefundRhapsodyGoTransaction(),
    watchRefundRhapsodyGoStripeTransaction(),
    watchVoidRhapsodyGoTransaction(),
    watchVoidRhapsodyGoStripeTransaction(),
    watchSettleGoTransaction(),
    watchSettleGoStripeTransaction(),
    watchRhapsodyGoAuthorization(),
    watchRhapsodyGoApplyPayment(),
    watchApplyPaymentToInvoice(),
    watchLinkUnappliedPayment(),
    watchLinkUnappliedPayments(),
    watchUnlinkPaymentToInvoice(),
    watchEditPayment(),
    watchEditGoPayment(),
    watchEditGoStripePayment(),
    watchCreateStripePayment(),
    watchCreateCreditAdjustment(),
  ])
}
