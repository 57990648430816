import React from 'react'
import { useSelector } from 'react-redux'
import { Field } from '@pbt/pbt-ui-components'

import { getSpecies } from '~/store/reducers/constants'

import PropSpeciesSelect from './PropSpeciesSelect'

export interface SpeciesSelectProps {
  field: Field
  hideLabel?: boolean
  required?: boolean
}

const SpeciesSelect = ({
  field,
  required,
  hideLabel = false,
}: SpeciesSelectProps) => {
  const Species = useSelector(getSpecies)

  return (
    <PropSpeciesSelect
      field={field}
      hideLabel={hideLabel}
      required={required}
      species={Species}
    />
  )
}

export default SpeciesSelect
