import { gql } from '@apollo/client'

import { REFUND_CALCULATION_FIELDS, REFUND_INVOICE_FIELDS } from '../fragments'

export const CALCULATE_REFUND = gql`
  ${REFUND_CALCULATION_FIELDS}
  query CalculateRefund($input: RefundInvoiceInput!) {
    calculateRefundAmount(input: $input) {
      originalInvoice {
        id
      }
      additionalDiscount
      totalTax
      totalDiscount
      amount
      subtotal
      serviceFeeAmount
      sections {
        id
        groupedItems {
          ... on GroupedRefundItemCalculation {
            groupSnapshot {
              id
            }
            taxAmount
            additionalDiscount
            discount
            subTotal
            items {
              ...RefundLineItemCalculationFields
            }
          }
          ...RefundLineItemCalculationFields
        }
      }
    }
  }
`
export const FETCH_REFUND_INVOICE = gql`
  ${REFUND_INVOICE_FIELDS}
  query FetchRefundInvoice($businessId: ID, $id: ID!) {
    refundInvoice(businessId: $businessId, id: $id) {
      ...RefundInvoiceFields
    }
  }
`
