import i18n from '~/locales/i18n'

enum InvoiceType {
  ESTIMATE = 'Estimate',
  INVOICE = 'Invoice',
  CHARGE_SHEET = 'Charge Sheet',
  DEFAULT = 'Invoice',
  REFUND_INVOICE = 'Refund Invoice',
}

export const InvoiceTypeDisplayName = {
  [InvoiceType.ESTIMATE]: i18n.t('Constants:INVOICE_TYPE.ESTIMATE'),
  [InvoiceType.INVOICE]: i18n.t('Constants:INVOICE_TYPE.INVOICE'),
  [InvoiceType.CHARGE_SHEET]: i18n.t('Common:CHARGE_SHEET'),
  [InvoiceType.REFUND_INVOICE]: i18n.t('Common:REFUND'),
}

export default InvoiceType
