import React from 'react'
import { useSelector } from 'react-redux'
import {
  BasePuiDialogProps,
  DateUtils,
  Nil,
  PhoneUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import PrintLabelDialog from '~/components/common/dialog/PrintLabelDialog'
import {
  getGenderString,
  getSpayedNeuteredIntactWithGenderString,
} from '~/components/common/inputs/gender/genderUtils'
import { DymoLabelType } from '~/constants/dymo'
import i18n from '~/locales/i18n'
import {
  getBreed,
  getGender,
  getLabVendors,
  getSpecies,
} from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getFullBreedName, getPatientAge } from '~/utils'

import { joinLineParts } from './labelsUtils'

export interface PrintLabOderLabelDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  labOrderId: string | Nil
  orderDate: string | Nil
  patientId: string | Nil
  vendorId: string | Nil
}

const PrintLabOderLabelDialog = ({
  open,
  onClose,
  vendorId,
  labOrderId,
  orderDate,
  patientId,
  clientId,
}: PrintLabOderLabelDialogProps) => {
  const Breed = useSelector(getBreed)
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)
  const LabVendors = useSelector(getLabVendors)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const labVendorName = Utils.getConstantName(vendorId, LabVendors)

  const labOrderLine = joinLineParts([
    labVendorName,
    labOrderId,
    orderDate ? DateUtils.formatDate(orderDate) : undefined,
  ])

  const clientName = Utils.getPersonString(client)
  const clientPhone = PhoneUtils.formatPhoneNumber(client?.mobilePhone)
  const clientAndPatientNameLine = joinLineParts([patient?.name, clientName])

  const fullGenderName = patient?.gender
    ? getSpayedNeuteredIntactWithGenderString(
        patient?.gender,
        patient?.spayedNeutered,
        Gender,
      )
    : getGenderString(patient?.gender, Gender)

  const dateOfBirthString = patient?.dateOfBirth && getPatientAge(patient)
  const patientAgeAndGenderLine = joinLineParts([
    dateOfBirthString,
    fullGenderName,
  ])

  const speciesName = Utils.getConstantName(patient?.species, Species, '')
  const breedFullName = getFullBreedName(
    patient?.species,
    patient?.breeds,
    Breed,
  )
  const patientSpeciesAndBreedLine = joinLineParts([speciesName, breedFullName])

  const labelLines = [
    labOrderLine,
    clientAndPatientNameLine,
    patientAgeAndGenderLine,
    patientSpeciesAndBreedLine,
  ].filter(Boolean)

  const formattedLabelData = {
    labOrderId,
    labVendorName,
    patientName: patient?.name,
    genderName: fullGenderName,
    breedName: breedFullName,
    age: dateOfBirthString,
    clientName,
    clientPhone,
  }

  return (
    <PrintLabelDialog
      showHtmlLabels
      formattedLabelData={formattedLabelData}
      labelLines={labelLines}
      labelType={DymoLabelType.LAB_ORDER}
      open={open}
      title={`${labVendorName} ${i18n.t('Common:LAB_LABEL').toLowerCase()}`}
      onClose={onClose}
    />
  )
}

export default PrintLabOderLabelDialog
