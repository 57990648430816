import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { LanguageUtils, Nil, UnitUtils, Utils } from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import {
  getShowSoapPendingActiveRx,
  getSoapPendingActiveRx,
} from '~/store/duck/prescriptions'
import { Variation } from '~/types'
import { getManualInputSelectValue } from '~/utils'

import { getUnitsState } from '../duck/settings'
import {
  getDrugDeliveryMethod,
  getDrugStrengthColor,
  getDrugStrengthUnit,
  getInventoryProductSizeUnit,
  getProductForm,
  getWeightUnits,
} from '../reducers/constants'

export const useVariationWeightString = (variation: Variation | Nil) => {
  const unitsState = useSelector(getUnitsState)
  const WeightUnits = useSelector(getWeightUnits)

  if (
    !variation ||
    R.isNil(variation.weightMinimum) ||
    R.isNil(variation.weightMaximum)
  ) {
    return ''
  }

  const weightMinimum = UnitUtils.convertUnits(
    UnitTypes.WEIGHT,
    variation.weightMinimum,
    unitsState,
  )
  const weightMaximum = UnitUtils.convertUnits(
    UnitTypes.WEIGHT,
    variation.weightMaximum,
    unitsState,
  )
  const UnitConstant = Utils.findConstantIdByName(
    unitsState[UnitTypes.WEIGHT],
    WeightUnits,
  )
  const unit = LanguageUtils.getConstantTranslatedName(
    UnitConstant,
    WeightUnits,
    '',
  )
  return `${weightMinimum} - ${weightMaximum}${unit}`
}

/**
 *  Deprecated
 *  Assumption that all global variation names are built in API now and doesn't require generic building
 */
export const useVariationName = (variation: Variation) => {
  const ProductForm = useSelector(getProductForm)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugStrengthColor = useSelector(getDrugStrengthColor)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)

  const weightStr = useVariationWeightString(variation)

  if (variation.name) {
    return variation.name
  }

  const {
    strength,
    strengthUnitsId,
    formId,
    customForm,
    deliveryMethodId,
    strengthColorId,
    perPackageAmount,
    perPackageUnitsId,
    saleUnitOfMeasure,
  } = variation

  const formStr = getManualInputSelectValue(ProductForm, formId, customForm)
  const routeStr = LanguageUtils.getConstantTranslatedName(
    deliveryMethodId,
    DrugDeliveryMethod,
    '',
  )
  const perPackageUnits = LanguageUtils.getConstantTranslatedName(
    perPackageUnitsId,
    InventoryProductSizeUnit,
    '',
  )
  const strengthColorStr = LanguageUtils.getConstantTranslatedName(
    strengthColorId,
    DrugStrengthColor,
    '',
  )

  const result = []
  if (strength) {
    result.push(
      `${strength} ${LanguageUtils.getConstantTranslatedName(
        strengthUnitsId,
        DrugStrengthUnit,
        '',
      )}`,
    )
  }
  if (formStr) {
    result.push(formStr)
  }
  result.push(routeStr)
  result.push(strengthColorStr)
  if (weightStr) {
    result.push(weightStr)
  }
  if (perPackageAmount && perPackageUnits) {
    result.push(perPackageAmount)
    result.push(perPackageUnits)
    if (saleUnitOfMeasure) {
      result.push(saleUnitOfMeasure)
    }
  }
  return result.join(' ')
}

export const useShowSoapPendingActiveRxSection = () => {
  const soapPendingActiveRx = useSelector(getSoapPendingActiveRx)
  const showSoapPendingActiveRx = useSelector(getShowSoapPendingActiveRx)
  return { showSoapPendingActiveRx, soapPendingActiveRx }
}
