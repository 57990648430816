import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ChatContainer from './conversations-chat/ChatContainer'
import ConversationsPage from './ConversationsPage'

const CommunicationsPage = () => (
  <Routes>
    {/* root path: /communications */}
    <Route element={<ConversationsPage />} path="client/:clientId" />
    <Route element={<ChatContainer />} path=":conversationId" />
    <Route element={<ConversationsPage />} path="/" />
    <Route element={<Navigate replace to="/communications" />} path="*" />
  </Routes>
)

export default CommunicationsPage
