import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { CircularProgressOverlay, PuiDialog } from '@pbt/pbt-ui-components'

import {
  chooseImportSession,
  fetchMigrationSessions,
} from '~/store/actions/migration'
import {
  getIsApplyingMappings,
  getIsLoadingImportSessions,
  getSessionsList,
} from '~/store/reducers/migration'
import { BaseMigrationDialogProps } from '~/types/entities/migration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import StepReviewExceptions from '../migration/core-migration/StepReviewExceptions'

const useStyles = makeStyles(() => ({
  loaderContainer: {
    position: 'relative',
    height: 100,
  },
}))

interface ReviewExceptionsDialogProps extends BaseMigrationDialogProps {
  sessionId: string
}

const ReviewExceptionsDialog = ({
  open,
  onClose,
  businessId,
  sessionId,
}: ReviewExceptionsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const isLoading = useSelector(getIsLoadingImportSessions)
  const sessionsIds = useSelector(getSessionsList)

  useEffect(() => {
    if (businessId) {
      dispatch(fetchMigrationSessions(businessId))
    }
  }, [businessId])

  useEffect(() => {
    const hasCurrentSession = R.includes(sessionId, sessionsIds)

    if (hasCurrentSession) {
      dispatch(chooseImportSession(sessionId))
    }
  }, [sessionsIds])

  const handleClose = useCallback(() => {
    dispatch(chooseImportSession(null))
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const setCloseOnFinish = useCloseAfterCreation(
    handleClose,
    getIsApplyingMappings,
  )

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="review-exceptions-dialog"
      maxWidth="lg"
      open={open}
      title={t('Businesses:REVIEW_EXCEPTIONS')}
      onClose={handleClose}
    >
      <CircularProgressOverlay
        classes={{ loaderContainer: classes.loaderContainer }}
        open={isLoading}
        preloaderText={t('Businesses:LOOKING_FOR_SESSIONS')}
      />
      {!isLoading && (
        <StepReviewExceptions isEnterprise onFinishClose={setCloseOnFinish} />
      )}
    </PuiDialog>
  )
}

export default ReviewExceptionsDialog
