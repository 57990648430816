import { SpaceMonitor } from '~/api/graphql/generated/types'

export enum MonitorType {
  IN_ROOM = 'IN_ROOM',
  OUT_ROOM = 'OUT_ROOM',
}

export type DeviceItems = SpaceMonitor & {
  id: string
  name: string
  spaceId?: string
}

export type Device = {
  id: number
  items: DeviceItems[]
  label: string
}
