import React, { useState } from 'react'

import AlertsPool from './AlertsPool'
import Header from './header/Header'
import LeftMenu from './menu/LeftMenu'
import useLeftMenuConfig from './menu/useLeftMenuConfig'

const NavigationWrapper = () => {
  const [leftPanelOpen, setLeftPanelOpen] = useState(false)

  const leftMenuConfig = useLeftMenuConfig()

  return (
    <>
      <AlertsPool />
      <Header onSandwichClicked={() => setLeftPanelOpen(true)} />
      <LeftMenu
        menuConfig={leftMenuConfig}
        open={leftPanelOpen}
        setOpen={setLeftPanelOpen}
      />
    </>
  )
}

export default NavigationWrapper
