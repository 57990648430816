import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import AccentLabel from '~/components/common/labels/AccentLabel'
import { getEventState } from '~/store/reducers/constants'
import { Task } from '~/types'

const useStyles = makeStyles(
  () => ({
    stateLabel: {
      fontSize: '1.5rem',
    },
  }),
  { name: 'TaskGroupedStateLabel' },
)

export interface TaskGroupedStateLabelProps {
  className?: string
  tasks: Task[]
}

const TaskGroupedStateLabel = ({
  className,
  tasks,
}: TaskGroupedStateLabelProps) => {
  const classes = useStyles()
  const EventState = useSelector(getEventState)

  if (tasks.length === 0) {
    return null
  }

  const groupedTasks = R.groupBy(R.propOr(undefined, 'stateId'), tasks)

  return (
    <>
      {Object.keys(groupedTasks).map((stateId) => {
        const taskState = Utils.findById(stateId, EventState) || {}

        return (
          <AccentLabel
            className={classNames(className, classes.stateLabel)}
            colorVariant={taskState.colorVariant}
            key={stateId}
            name={`${
              groupedTasks[stateId].length
            } ${LanguageUtils.getTranslatedFieldName(taskState)}`}
          />
        )
      })}
    </>
  )
}

export default TaskGroupedStateLabel
