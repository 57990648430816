export const FETCH_WHITEBOARD = 'whiteboard/FETCH_WHITEBOARD'
export const FETCH_WHITEBOARD_FAILURE = 'whiteboard/FETCH_WHITEBOARD_FAILURE'
export const FETCH_WHITEBOARD_SUCCESS = 'whiteboard/FETCH_WHITEBOARD_SUCCESS'

export const FETCH_WHITEBOARD_TREATMENTS =
  'whiteboard/FETCH_WHITEBOARD_TREATMENTS'
export const FETCH_WHITEBOARD_TREATMENTS_FAILURE =
  'whiteboard/FETCH_WHITEBOARD_TREATMENTS_FAILURE'
export const FETCH_WHITEBOARD_TREATMENTS_SUCCESS =
  'whiteboard/FETCH_WHITEBOARD_TREATMENTS_SUCCESS'
export const FETCH_WHITEBOARD_TREATMENTS_IGNORED =
  'whiteboard/FETCH_WHITEBOARD_TREATMENTS_IGNORED'

export const SET_WHITEBOARD_TREATMENTS_FILTERS =
  'whiteboard/SET_WHITEBOARD_TREATMENTS_FILTERS'
