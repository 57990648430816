import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogNames from '~/constants/DialogNames'
import {
  cancelOrder as cancelImagingOrder,
  fetchOrders as fetchImagingOrders,
  getHasOrders,
  getImagingOrdersList,
  getMultipleImagingOrders,
  getOutstandingOrders,
} from '~/store/duck/imagingOrders'
import { getSoapId } from '~/store/reducers/soap'
import { ImagingOrder } from '~/types'
import useDialog from '~/utils/useDialog'

export function useGetImagingOrdersData() {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const imagingOrdersList = useSelector(getImagingOrdersList) || []
  const imagingOrders = useSelector(getMultipleImagingOrders(imagingOrdersList))
  const hasOrders = useSelector(getHasOrders)
  const outstandingImagingOrders = useSelector(getOutstandingOrders) || []

  const [openImagingOrdersManagementDialog] = useDialog(
    DialogNames.BATCH_IMAGING_ORDERS_MANAGEMENT,
  )
  const [openImagingOrderDialog] = useDialog(DialogNames.IMAGING_ORDER)

  const hasOutstandingOrders = Boolean(outstandingImagingOrders.length)

  useEffect(() => {
    if (soapId) {
      dispatch(fetchImagingOrders(soapId))
    }
  }, [soapId])

  const handleFinalizeOrders = () => {
    openImagingOrdersManagementDialog()
  }

  const handleOrderCancel = (order: ImagingOrder) => {
    if (order?.id) {
      dispatch(cancelImagingOrder(order?.id))
    }
  }

  const handleEdit = (order: ImagingOrder) => {
    openImagingOrderDialog({ orderId: order?.id })
  }

  const handlePreview = (order: ImagingOrder) => {
    const resultsUrl = order?.resultsUrl
    if (resultsUrl) {
      window.open(resultsUrl)
    }
  }

  return {
    imagingOrders,
    hasOrders,
    outstandingImagingOrders,
    hasOutstandingOrders,
    handleOrderCancel,
    handleEdit,
    handlePreview,
    handleFinalizeOrders,
  }
}
