import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Fab, Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea } from '@pbt/pbt-ui-components'

import PuiButtonGroup, {
  PuiButtonGroupItem,
} from '~/components/common/buttons/PuiButtonGroup'
import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import DialogNames from '~/constants/DialogNames'
import {
  InventoryCatalog,
  InventoryCatalogNames,
} from '~/constants/inventoryCatalogs'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getInventoryIsReceiving } from '~/store/reducers/inventories'
import { getSearchTotalCount } from '~/store/reducers/search'
import { addSearch } from '~/utils'
import useDialog from '~/utils/useDialog'

import AdjustmentDetails from './adjustments/AdjustmentDetails'
import AdjustmentTableComponent from './adjustments/AdjustmentTableComponent'
import InventoryDetails from './catalog/InventoryDetails'
import InventoryTableComponent from './catalog/InventoryTableComponent'
import InventoryBulkPricingLink from './InventoryBulkPricingLink'
import OnHandCatalogComponent from './on-hand/OnHandCatalogComponent'
import OnHandDetails from './on-hand/OnHandDetails'
// @ts-ignore
import ShipmentDetails from './shipments/ShipmentDetails'
import ShipmentTableComponent from './shipments/ShipmentTableComponent'

const useStyles = makeStyles(
  () => ({
    addButton: {
      minWidth: 192,
      height: 40,
    },
    headerTabButton: {
      minWidth: '128px !important',
    },
  }),
  { name: 'InventoryPage' },
)

const CatalogPermissionMap = {
  [InventoryCatalog.Catalog]: PermissionArea.INVENTORY,
  [InventoryCatalog.OnHand]: PermissionArea.INVENTORY,
  [InventoryCatalog.Shipments]: PermissionArea.SHIPMENTS,
  [InventoryCatalog.Adjustments]: PermissionArea.ADJUSTMENTS,
}

const TableComponentMap = {
  [InventoryCatalog.Catalog]: InventoryTableComponent,
  [InventoryCatalog.OnHand]: OnHandCatalogComponent,
  [InventoryCatalog.Shipments]: ShipmentTableComponent,
  [InventoryCatalog.Adjustments]: AdjustmentTableComponent,
}

const DetailsComponentMap = {
  [InventoryCatalog.Catalog]: InventoryDetails,
  [InventoryCatalog.OnHand]: OnHandDetails,
  [InventoryCatalog.Shipments]: ShipmentDetails,
  [InventoryCatalog.Adjustments]: AdjustmentDetails,
}

const InventoryPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const { currentCatalog = InventoryCatalog.Catalog, catalogItemId } =
    useParams()
  const location = useLocation()

  const catalogPermissions = useSelector(
    getCRUDByArea(CatalogPermissionMap[currentCatalog]),
  )
  const totalCount = useSelector(getSearchTotalCount)
  const isLoading = useSelector(getInventoryIsReceiving)

  const bulkPricePermissions = useSelector(
    getCRUDByArea(PermissionArea.INVENTORY_BULK_PRICE),
  )

  const availableCatalogList = Object.entries(InventoryCatalog)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(([_, value]) => ({ label: InventoryCatalogNames[value], value }))

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const [openInventoryDialog] = useDialog(DialogNames.INVENTORY)
  const [openShipmentDialog] = useDialog(DialogNames.SHIPMENT)

  const handleCatalogChange = ({ value }: PuiButtonGroupItem) => {
    navigate(`/admin/catalog/inventories/${value}`)
  }

  const AddButtonMap = {
    [InventoryCatalog.Catalog]: {
      name: t('Common:ADD_INVENTORY_ITEM'),
      action: () => openInventoryDialog(),
    },
    [InventoryCatalog.Shipments]: {
      name: t('Common:NEW_SHIPMENT'),
      action: () => openShipmentDialog(),
    },
    [InventoryCatalog.Adjustments]: null,
    [InventoryCatalog.OnHand]: null,
  }

  const isCatalogPage = currentCatalog === InventoryCatalog.Catalog
  const addButton = AddButtonMap[currentCatalog]
  const isBulkPriceAvailable = isCatalogPage && bulkPricePermissions.create

  const TableComponent = TableComponentMap[currentCatalog]
  const DetailsComponent = DetailsComponentMap[currentCatalog]

  const onDetailsClose = () => {
    navigate(
      addSearch(location, `/admin/catalog/inventories/${currentCatalog}`),
    )
  }

  if (isMobile && catalogItemId !== undefined && DetailsComponent) {
    return <DetailsComponent itemId={catalogItemId} onClose={onDetailsClose} />
  }

  const headerTabs = (
    <Grid item>
      <PuiButtonGroup
        classes={{ button: classes.headerTabButton }}
        items={availableCatalogList}
        selectedItem={availableCatalogList.find(
          ({ value }) => value === currentCatalog,
        )}
        onItemSelected={handleCatalogChange}
      />
    </Grid>
  )

  const headerButtons = (
    <Grid
      container
      item
      justifyContent="space-between"
      px={3}
      py={2}
      wrap="nowrap"
    >
      <Grid item>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        columnSpacing={2}
        ml={0}
        width="auto"
        wrap="nowrap"
      >
        {isBulkPriceAvailable && <InventoryBulkPricingLink />}
        {addButton && (
          <Grid item>
            <Fab
              className={classes.addButton}
              color="inherit"
              variant="extended"
              onClick={addButton.action}
            >
              {addButton.name}
            </Fab>
          </Grid>
        )}
      </Grid>
    </Grid>
  )

  return (
    <Grid container item flex={1} wrap="nowrap">
      {TableComponent ? (
        <TableComponent
          headerButtons={catalogPermissions.create && headerButtons}
          headerTabs={headerTabs}
          itemId={catalogItemId}
          onDetailsClose={onDetailsClose}
        />
      ) : (
        <Navigate replace to="/admin/catalog/inventories" />
      )}
    </Grid>
  )
}

export default InventoryPage
