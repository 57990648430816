import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import Avatar from '~/components/common/Avatar'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { RootState } from '~/store'
import { fetchUsersOnSoapCollaborationSession } from '~/store/actions/soap'
import { fetchLabOrders, getLabOrdersState } from '~/store/duck/labOrders'
import {
  fetchAllPendingActiveRxForSoap,
  getIsFetchingAllPendingActiveRxForSoap,
} from '~/store/duck/prescriptions'
import { useShowOutstandingLabOrdersSection } from '~/store/hooks/labOrders'
import { useShowSoapPendingActiveRxSection } from '~/store/hooks/prescriptions'
import { useUsersOnSoapCollaborationSession } from '~/store/hooks/soap'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSOAP, getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import {
  convertPrescriptionV2ToPrescription,
  getPrescriptionName,
} from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const customLoadSelector = (state: RootState): boolean =>
  getSOAP(state).isFetchingUsersOnCollaboration ||
  getLabOrdersState(state).isOrdersLoading ||
  getIsFetchingAllPendingActiveRxForSoap(state)

const useConfirmLockSoapAlert = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_LOCK,
  })

  const { usersOnCollaborationSession, usersName } =
    useUsersOnSoapCollaborationSession()
  const { showOutstandingLabOrdersSection, readyForOrderLabTests } =
    useShowOutstandingLabOrdersSection()

  const { showSoapPendingActiveRx, soapPendingActiveRx } =
    useShowSoapPendingActiveRxSection()

  const onLabOrdersAndUsersOnCollaborationSessionFetched = (
    onConfirm?: () => void,
  ) => {
    openConfirmAlert({
      applyCustomMessage: true,
      message: t('Soap:ARE_YOU_SURE_YOU_CANNOT_EDIT_SOAP'),
      okButtonText: t('Common:YES_FINALIZE'),
      cancelButtonText: t('Common:NO_I_DO_NOT_DONE'),
      content: (
        // TODO: add outstanding image requests here
        <>
          {usersOnCollaborationSession &&
            usersOnCollaborationSession.length > 1 && (
              <Grid container item justifyContent="space-evenly" mb={2}>
                {usersOnCollaborationSession.map((user) => (
                  <Avatar key={user.id} person={user} />
                ))}
                <Text strong align="center" mt={1} variant="subheading2">
                  {t('Dialogs:SOAP_FINALIZE_ALERT_DIALOG.ERROR_MESSAGE', {
                    users: usersName,
                  })}
                </Text>
              </Grid>
            )}
          {showOutstandingLabOrdersSection &&
            readyForOrderLabTests.length > 0 && (
              <Grid container item justifyContent="center" mb={2}>
                <Text strong align="center" variant="subheading2">
                  {t('Soap:OUTSTANDING_LAB_ORDERS')}:
                </Text>
                {readyForOrderLabTests.map((labTest) => (
                  <Text align="center" key={labTest.id}>
                    {labTest.name}
                  </Text>
                ))}
              </Grid>
            )}
          {showSoapPendingActiveRx && (
            <Grid
              container
              item
              flexDirection="column"
              justifyContent="center"
              mb={2}
            >
              <Text strong align="center" variant="subheading2">
                {t('Soap:OUTSTANDING_CHEWY_PRESCRIPTIONS')}:
              </Text>
              {soapPendingActiveRx.map((prescription) => (
                <Text align="center" key={prescription.id}>
                  {getPrescriptionName(
                    convertPrescriptionV2ToPrescription(prescription),
                    isFoodCatalogEnabled,
                  )}
                </Text>
              ))}
            </Grid>
          )}
        </>
      ),
      onConfirm: (proceed) => proceed && onConfirm && onConfirm(),
      ...(usersOnCollaborationSession
        ? { preventShowAgainCheckBox: false }
        : {}),
    })
  }

  const setOpenOnOrdersAndUsersFetched = useCloseAfterCreation(
    onLabOrdersAndUsersOnCollaborationSessionFetched,
    customLoadSelector,
  )

  const openConfirmLockSoapAlert = (onConfirm?: () => void) => {
    setOpenOnOrdersAndUsersFetched(onConfirm)
    if (soapId) {
      dispatch(fetchUsersOnSoapCollaborationSession(soapId, soapBusinessId))
      dispatch(fetchLabOrders(soapId))
      dispatch(fetchAllPendingActiveRxForSoap({ soapId }))
    }
  }

  return [openConfirmLockSoapAlert]
}

export default useConfirmLockSoapAlert
