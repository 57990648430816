import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  ControlButtonGroup,
  ControlButtonGroupName,
  FileTemplate,
  Nil,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import AttachmentUploadIcon from '~/components/common/inputs/attachment/attachment-dialog/AttachmentUploadIcon'
import FileInput from '~/components/common/inputs/file-input/FileInput'
import { uploadSessionFile } from '~/store/actions/migration'
import { getIsLoading } from '~/store/reducers/migration'
import { MigrationSessionFile } from '~/types/entities/migration'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  imageInputInputArea: {
    padding: theme.spacing(1),
  },
  imageInputLogoArea: {
    height: 118,
  },
  imageInputText: {
    fontSize: '1.6rem',
  },
  nameSelect: {
    flex: 1,
  },
  button: {
    height: 40,
    width: 150,
    margin: theme.spacing(0, 0, 2, 2),
  },
}))

const filterFilesByExtension =
  (file: FileTemplate | null) =>
  ({ extension }: MigrationSessionFile) =>
    file ? extension === file.extension : true

interface UploadSessionFilesDialogProps extends BasePuiDialogProps {
  sessionFiles: MigrationSessionFile[]
  sessionId: string | Nil
}

const UploadSessionFilesDialog = ({
  open,
  sessionId,
  sessionFiles,
  onClose,
}: UploadSessionFilesDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const isSessionFilesLoading = useSelector(getIsLoading)

  const [file, setFile] = useState<FileTemplate | null>(null)

  const setCloseAfterCreation = useCloseAfterCreation(onClose, getIsLoading)

  const acceptableExtensions = R.pipe(
    R.pluck('extension'),
    R.uniq,
  )(sessionFiles)

  const acceptableNames = R.pipe<any, any, string[]>(
    R.filter(filterFilesByExtension(file)),
    R.pluck('name'),
  )(sessionFiles)

  const [fileName, setFileName] = useState(R.head(acceptableNames))

  const fileInputAccept = R.pipe(
    R.map((extension) => `.${extension}`),
    R.join(','),
  )(acceptableExtensions)
  const onlyOneAcceptableName =
    acceptableNames.length === 1 && R.head(acceptableNames)
  const acceptableFileName = acceptableNames.find((name) => name === file?.name)
  const acceptableFileExtension = acceptableExtensions.find(
    (extension) => extension === file?.extension,
  )
  const isFileNameAcceptable = acceptableFileName && acceptableFileExtension

  const handleNameChange = (event: SelectChangeEvent) => {
    setFileName(event.target.value)
  }

  const handleUpload = () => {
    if (sessionId) {
      dispatch(
        uploadSessionFile(sessionId, {
          ...(file as MigrationSessionFile),
          name: fileName as string,
        }),
      )
    }

    setCloseAfterCreation()
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="upload-session-files-dialog"
      open={open}
      title={t('Businesses:MIGRATION.UPLOAD_SESSION_FILES')}
      onClose={onClose}
    >
      <Grid container className={classes.content}>
        {file ? (
          <Grid container>
            <Grid container item alignItems="center" xs={8}>
              {isFileNameAcceptable ? (
                <Text variant="h5">{`${file.name}.${file.extension}`}</Text>
              ) : (
                <>
                  <Text>{`${file.name}.${file.extension}`}</Text>
                  <Text mx={1}>&gt;</Text>
                  {onlyOneAcceptableName ? (
                    <Text variant="h5">{`${onlyOneAcceptableName}.${file.extension}`}</Text>
                  ) : (
                    <Select
                      className={classes.nameSelect}
                      value={fileName}
                      onChange={handleNameChange}
                    >
                      {acceptableNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}.{file.extension}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </>
              )}
            </Grid>
            <Grid container item justifyContent="flex-end" xs={4}>
              <ControlButtonGroup
                buttons={[
                  {
                    name: ControlButtonGroupName.DELETE,
                    onClick: () => setFile(null),
                  },
                ]}
              />
            </Grid>
          </Grid>
        ) : (
          <FileInput
            forceButtonsHidden
            Icon={AttachmentUploadIcon}
            accept={fileInputAccept}
            classes={{
              text: classes.imageInputText,
              inputArea: classes.imageInputInputArea,
              logoAreaClassName: classes.imageInputLogoArea,
            }}
            text={t('Businesses:MIGRATION.CLICK_HERE_TO_UPLOAD_FILE')}
            uploadButtonColor="primary"
            onFileSelected={setFile}
          />
        )}
      </Grid>
      {file && (
        <ButtonWithLoader
          className={classes.button}
          disabled={isSessionFilesLoading}
          loading={isSessionFilesLoading}
          onClick={handleUpload}
        >
          {t('Common:UPLOAD_ACTION')}
        </ButtonWithLoader>
      )}
    </PuiDialog>
  )
}

export default UploadSessionFilesDialog
