import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import ChargesItems from '../soapV2/charges/tabs/ChargesItems'

interface AddChargeSheetDialogProps extends BasePuiDialogProps {
  onAdd: (data: any) => void
  patientId: string
  soapId?: string
  title: string
}

const AddChargeSheetDialog = ({
  open,
  title,
  patientId,
  soapId,
  onAdd,
  onClose,
}: AddChargeSheetDialogProps) => {
  const { t } = useTranslation('Common')

  return (
    <PuiDialog
      fullWidth
      actions={
        <ButtonWithLoader onClick={onAdd}>
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="add-charge-sheet-item-dialog"
      maxWidth="md"
      open={open}
      title={title}
      onClose={onClose}
    >
      <ChargesItems
        appointmentId={soapId}
        editDisabled={false}
        patientId={patientId}
      />
    </PuiDialog>
  )
}

export default AddChargeSheetDialog
