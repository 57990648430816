import React from 'react'
import { Divider, Grid, Skeleton } from '@mui/material'
import { EditorType } from '@pbt/pbt-ckeditor-ui'

export const EDITOR_TOOLBAR_HEIGHT = 36.7

export interface PuiCKEditorSkeletonProps {
  height: number
  type: EditorType
}

const PuiCKEditorSkeleton = ({ height, type }: PuiCKEditorSkeletonProps) => {
  const showDivider = type === EditorType.CLASSIC

  return (
    <Grid left={0} position="absolute" width="100%" zIndex={2}>
      {showDivider && (
        <>
          <Skeleton
            height={EDITOR_TOOLBAR_HEIGHT}
            variant="rectangular"
            width="100%"
          />
          <Divider />
        </>
      )}
      <Skeleton height={height} variant="rectangular" width="100%" />
    </Grid>
  )
}

export default PuiCKEditorSkeleton
