import React from 'react'
import { useSelector } from 'react-redux'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { rawTheme } from '@pbt/pbt-ui-components'

import { getClientId, getPatientId } from '~/store/reducers/soap'

import TimelineComponent from '../../clients/timeline/TimelineComponent'

const useStyles = makeStyles(
  () => ({
    timeline: {
      paddingTop: 0,
      borderTop: 'none',
    },
    cardsRoot: {
      width: 616,
    },
    filterRoot: {
      justifyContent: 'flex-start',
    },
  }),
  { name: 'Timeline' },
)

export interface TimelineProps {
  expanded: boolean
}

const Timeline = ({ expanded }: TimelineProps) => {
  const classes = useStyles()
  const clientId = useSelector(getClientId) as string
  const patientId = useSelector(getPatientId) as string

  const newTheme = createTheme(
    R.mergeDeepRight(rawTheme, {
      breakpoints: {
        values: {
          xs: 0,
          sm: expanded ? 1 : 9999,
          md: expanded ? 2 : 9999,
          // see here https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createBreakpoints.js#L2-L3
          // and here https://github.com/mui-org/material-ui/issues/11649
          // currently there is no way to remove breakpoints if you don't need them
          lg: 9999,
          xl: 9999,
        },
      },
    }),
  )

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={newTheme}>
        <TimelineComponent
          classes={{
            root: classes.timeline,
            cardsRoot: classNames({
              [classes.cardsRoot]: expanded,
            }),
          }}
          clientId={clientId}
          expanded={expanded}
          filterProps={{
            classes: {
              root: classes.filterRoot,
            },
          }}
          patientId={patientId}
          useWindowScroll={false}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Timeline
