import React, { ReactNode } from 'react'
import { PuiTooltip } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { Status } from '~/components/elements/Status/Status'

type ChewyMissingEntityProps = {
  message: ReactNode
  tooltip: string
}

export const ChewyMissingEntity = ({
  message,
  tooltip,
}: ChewyMissingEntityProps) => (
  <Status
    endIcon={
      <PuiTooltip tooltipText={tooltip}>
        <InfoIcon />
      </PuiTooltip>
    }
    variant="warning"
  >
    {message}
  </Status>
)
