import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ControlButtonGroup } from '@pbt/pbt-ui-components'

import MembershipIcon from '~/components/dashboard/soap/order/MembershipIcon'
import { OrderType } from '~/constants/SOAPStates'
import { toggleInvoiceGroupExpanded } from '~/store/actions/finance'
import {
  getCurrentBusiness,
  getIsPracticeAdministrator,
} from '~/store/reducers/auth'
import { getInvoiceGroupIdIsExpanded } from '~/store/reducers/finance'
import {
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceUpdateFieldCallback,
} from '~/types'

import { getItemCoveredByWplan, isGroupedInvoiceItem } from '../../invoiceUtils'
import { BodyColumnComponents, ColumnNames } from '../invoiceTableConstants'
import useInvoiceItemButtons from './useInvoiceItemButtons'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      height: 48,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    groupedCell: {
      borderBottom: 'none',
    },
    lastNestedCell: {
      borderBottom: 'none',
    },
    borderCell: {
      borderRight: theme.constants.tabBorder,
    },
    bodyCell: {
      '& > *': {
        verticalAlign: 'middle',
      },
      paddingTop: 2,
      paddingBottom: 2,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    declinedCell: {
      color: theme.colors.tabLabel,
    },
    nameCell: {
      maxWidth: 235,
    },
    nestedCell: {
      paddingLeft: theme.spacing(5),
    },
    iconButton: {
      padding: 0,
    },
    bodyRow: {
      borderTop: theme.constants.tabBorder,
    },
    evenRow: {
      backgroundColor: theme.colors.tableEvenItem,
    },
    oddRow: {
      backgroundColor: theme.colors.tableBackground,
    },
    nestedRow: {
      borderTop: 'none',
    },
    lastNestedRow: {
      borderBottom: `1px solid ${theme.colors.userPickerBorder}`,
    },
    groupedBodyRow: {
      borderColor: theme.colors.userPickerBorder,
      borderStyle: 'double',
      borderWidth: '1px 0',
    },
    groupedBodyRowExpanded: {
      borderBottom: 'none',
    },
    select: {
      paddingRight: theme.spacing(2),
    },
    controlGroupContainer: {
      flex: 0,
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameContainer: {
      overflow: 'hidden',
      flex: 1,
    },
    nameContainerGrouped: {
      cursor: 'pointer',
    },
    caret: {
      marginRight: theme.spacing(1),
    },
    declineIcon: {
      opacity: theme.constants.inactiveOpacity,
    },
    membershipIcon: {
      fontSize: '1.5rem',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'InvoiceTableRow' },
)

export interface InvoiceTableRowProps {
  blockedUsedQuantity?: boolean
  deleteItem: (item: InvoiceLineItem) => void
  group: InvoiceLineItemGroup
  index: number
  invoice: Invoice
  isEstimate: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  lastNested?: boolean
  nested?: boolean
  onUnblockEditInvoice?: (
    invoice: Invoice,
    soap: InvoiceLineItemGroup['soap'],
  ) => void
  onUpdatePrescriptionLineItem: (item: InvoiceLineItem) => void
  prePaidEnabled: boolean
  rangeEnabled: boolean
  selectedColumns: string[]
  soap?: InvoiceLineItemGroup['soap']
  toggleExpand?: (item: InvoiceLineItem) => void
  updateField: InvoiceUpdateFieldCallback
}

const InvoiceTableRow = ({
  selectedColumns,
  soap,
  lastNested,
  nested,
  isEstimate,
  invoice,
  item,
  group,
  rangeEnabled,
  prePaidEnabled,
  updateField,
  deleteItem,
  onUpdatePrescriptionLineItem,
  blockedUsedQuantity,
  isReadOnly,
  onUnblockEditInvoice,
  toggleExpand,
  index,
}: InvoiceTableRowProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const business = useSelector(getCurrentBusiness)
  const isPracticeAdmin = useSelector(getIsPracticeAdministrator(business))
  const isExpanded = useSelector(
    getInvoiceGroupIdIsExpanded(item.group, soap?.id),
  )

  const buttons = useInvoiceItemButtons({
    item,
    group,
    isEstimate,
    isReadOnly,
    onUpdatePrescriptionLineItem,
    deleteItem,
    updateField,
  })

  const { t } = useTranslation(['Common', 'Invoices'])

  const isLabTest = item.logType === OrderType.LAB_TEST
  const isEvenRow = index % 2 === 0
  const isDeclined = item.declined
  const isGrouped = isGroupedInvoiceItem(item)

  const toggleExpandInner = (i: InvoiceLineItem) => {
    if (isGrouped && toggleExpand) {
      toggleExpand(i)
    }
  }

  const onExpandChange = () => {
    dispatch(toggleInvoiceGroupExpanded(item.group, soap?.id))
    toggleExpandInner(item)
  }

  const isWplanItem = Boolean(item.wplanLogId)
  const coveredByWplan = getItemCoveredByWplan(item)
  const showPriceColumn = R.includes(ColumnNames.UNIT_PRICE, selectedColumns)

  const isLockedEdit = invoice?.posted || group?.soap?.finalized
  const handleClickInvoiceItem = () =>
    isLockedEdit &&
    isPracticeAdmin &&
    onUnblockEditInvoice &&
    onUnblockEditInvoice(invoice, group?.soap)

  return (
    <>
      <TableRow
        className={classNames(classes.bodyRow, {
          [classes.evenRow]: isEvenRow,
          [classes.oddRow]: !isEvenRow,
          [classes.groupedBodyRow]: isGrouped,
          [classes.groupedBodyRowExpanded]: isExpanded,
          [classes.nestedRow]: nested,
          [classes.lastNestedRow]: lastNested,
        })}
        onClick={handleClickInvoiceItem}
      >
        <TableCell
          className={classNames(
            classes.tableCell,
            classes.bodyCell,
            classes.nameCell,
            {
              [classes.nestedCell]: nested,
              [classes.declinedCell]: isDeclined,
              [classes.borderCell]: isEstimate || !showPriceColumn,
              [classes.groupedCell]: isGrouped,
              [classes.lastNestedCell]: lastNested,
            },
          )}
        >
          <Grid container item alignItems="center" wrap="nowrap">
            <Grid
              container
              item
              alignItems="center"
              className={classNames(classes.nameContainer, {
                [classes.nameContainerGrouped]: isGrouped,
              })}
              wrap="nowrap"
              onClick={() => {
                if (isGrouped && item.group && onExpandChange) {
                  onExpandChange()
                }
              }}
            >
              {isGrouped &&
                (isExpanded ? (
                  <ExpandLess className={classes.caret} />
                ) : (
                  <ExpandMore className={classes.caret} />
                ))}
              {isWplanItem && !isDeclined && (
                <MembershipIcon
                  className={classes.membershipIcon}
                  limitReached={!coveredByWplan}
                />
              )}
              <Grid className={classes.name}>
                {isGrouped ? item.groupName : item.name}
              </Grid>
              {isEstimate && isDeclined
                ? ` (${t('Common:DECLINED')})`
                : coveredByWplan
                  ? ` ${t('Invoices:TABLE.BODY.COVERED_BY_WELLNESS_PLAN')}`
                  : ''}
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              className={classes.controlGroupContainer}
              wrap="nowrap"
            >
              <ControlButtonGroup
                buttons={buttons}
                classes={{
                  declineIcon: classNames({
                    [classes.declineIcon]: !isDeclined,
                  }),
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
        {selectedColumns.map((column) => {
          const CellComponent = BodyColumnComponents[column]

          if (!CellComponent) {
            return null
          }

          return (
            <CellComponent
              allowDecimalQuantity={!isLabTest}
              blockedUsedQuantity={blockedUsedQuantity}
              className={classNames(
                classes.tableCell,
                classes.borderCell,
                classes.bodyCell,
                {
                  [classes.groupedCell]: isGrouped,
                  [classes.lastNestedCell]: lastNested,
                },
              )}
              group={group}
              invoice={invoice}
              isDeclined={isDeclined}
              isEstimate={isEstimate}
              isGrouped={isGrouped}
              isReadOnly={isReadOnly}
              item={item}
              key={column}
              prePaidEnabled={prePaidEnabled}
              rangeEnabled={rangeEnabled}
              updateField={updateField}
            />
          )
        })}
      </TableRow>
      {isExpanded &&
        item.items &&
        item.items.map((childItem, childIndex) => (
          <InvoiceTableRow
            nested
            deleteItem={deleteItem}
            group={group}
            index={index}
            invoice={invoice}
            isEstimate={isEstimate}
            isReadOnly={isReadOnly}
            item={childItem}
            key={childItem.id || childItem.priceId}
            lastNested={childIndex === (item.items?.length || 0) - 1}
            prePaidEnabled={prePaidEnabled}
            rangeEnabled={rangeEnabled}
            selectedColumns={selectedColumns}
            toggleExpand={toggleExpand}
            updateField={updateField}
            onUpdatePrescriptionLineItem={onUpdatePrescriptionLineItem}
          />
        ))}
    </>
  )
}

export default InvoiceTableRow
