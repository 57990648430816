import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BasePuiDialogProps, PuiDialog, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import InvoiceType, { InvoiceTypeDisplayName } from '~/constants/InvoiceType'
import { OrderType } from '~/constants/SOAPStates'
import { convertToLineItems, setInvoiceAutoSave } from '~/store/actions/finance'
import { removeUnifiedOrder } from '~/store/actions/orders'
import {
  fetchClientFinanceCharges,
  getClientFinanceLoading,
} from '~/store/duck/clientFinanceData'
import { useChargeSheetSectionMap } from '~/store/hooks/chargeSheet'
import {
  useLogItemStateGetter,
  usePrescriptionDialog,
  useRefillDialog,
} from '~/store/hooks/orders'
import { useCreateOrUpdatePrescription } from '~/store/hooks/prescription'
import { useFetchPrescriptionTemplate } from '~/store/hooks/prescriptionTemplates'
import { getFinanceIsConvertingToLineItems } from '~/store/reducers/finance'
import {
  getOrdersIsReceiving,
  getOrdersIsSending,
  getOrdersList,
  getOrdersTotalCount,
} from '~/store/reducers/orders'
import { getUser } from '~/store/reducers/users'
import {
  Bundle,
  ChargeSheetItemSection,
  CRUDOrderOptions,
  InvoiceFromLineItem,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceOrEstimate,
  Order,
  Prescription,
} from '~/types'
import { getMissingPdmpReportingFields } from '~/utils/orderUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

// @ts-ignore
import OrderSelectableListWithFilters from '../soap/list-with-filters/OrderSelectableListWithFilters'
import OrderTooltip from '../soap/OrderTooltip'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 976,
      maxWidth: 976,
    },
  }),
  { name: 'AddInvoiceItemDialog' },
)

const lineItemsAreStored = (
  pendingLineItems: InvoiceLineItem[],
  groups: InvoiceLineItemGroup[],
) => {
  const { groupedItems = [] } = groups.find((group) => !group.soap) || {}

  return pendingLineItems.every(({ price }) =>
    groupedItems.find(
      ({ priceId }) => priceId === (typeof price === 'object' && price.id),
    ),
  )
}

type PrescriptionOptionsToCreate = {
  closeAfterSaving: boolean
  options: CRUDOrderOptions
  prescription: Order
}

interface SelectableListHandle {
  clearItems: () => void
  getItems: () => InvoiceLineItem[]
  onUpdateItem: (order: Prescription | Order) => void
}

interface AddInvoiceItemDialogProps extends BasePuiDialogProps {
  invoice: InvoiceOrEstimate | InvoiceFromLineItem
  isChargeSheet?: boolean
  isEstimate?: boolean
  isOtcInvoice?: boolean
  onSave?: (data: InvoiceLineItem[]) => void
  onSavePrescription?: () => void
  soapId?: string
}

const AddInvoiceItemDialog = ({
  open,
  isEstimate,
  isOtcInvoice,
  invoice,
  soapId,
  onClose,
  onSave,
  onSavePrescription,
  isChargeSheet = false,
}: AddInvoiceItemDialogProps) => {
  const {
    id: invoiceId,
    groups: groupsProp,
    orderFilters,
    client: clientId,
    patient: patientId,
    type,
  } = invoice

  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Dialogs'])

  const isConvertingToLineItems = useSelector(getFinanceIsConvertingToLineItems)
  const areOrdersSending = useSelector(getOrdersIsSending)
  const isClientFinanceLoading = useSelector(getClientFinanceLoading)
  const orders = useSelector(getOrdersList)
  const ordersIsReceiving = useSelector(getOrdersIsReceiving)
  const ordersTotalCount = useSelector(getOrdersTotalCount)
  const client = useSelector(getUser(clientId))

  const isLoading =
    isConvertingToLineItems || areOrdersSending || isClientFinanceLoading

  const logItemStateGetter = useLogItemStateGetter()

  const sections = useChargeSheetSectionMap(clientId)

  const invoiceType = isEstimate
    ? InvoiceType.ESTIMATE.toUpperCase()
    : InvoiceType.INVOICE.toUpperCase()

  const [openPrescriptionDialog, closePrescriptionDialog] =
    usePrescriptionDialog()
  const createOrUpdatePrescription =
    useCreateOrUpdatePrescription(isChargeSheet)
  const [openRefillDialog, closeRefillDialog, isRefillDialogOpen] =
    useRefillDialog()
  const [openPrescriptionMissingFieldDialog] = useDialog(
    DialogNames.PRESCRIPTION_MISSING_FIELDS_DIALOG,
  )

  const setCloseAfterConvertOn = useCloseAfterCreation(
    onClose,
    getFinanceIsConvertingToLineItems,
  )
  const setCloseAfterPrescriptionCreate = useCloseAfterCreation(() => {
    closePrescriptionDialog()
    closeRefillDialog()
    if (onSavePrescription) {
      onSavePrescription()
    }
    if (onClose) {
      onClose()
    }
  }, getOrdersIsSending)

  const selectableListRef = useRef<SelectableListHandle>(null)

  const [pendingLineItems, setPendingLineItems] = useState<InvoiceLineItem[]>(
    [],
  )
  const [prescriptionOptionsToCreate, setPrescriptionOptionsToCreate] =
    useState<PrescriptionOptionsToCreate>()
  const [bundleDiscount, setBundleDiscount] = useState(0)
  const [pendingTemplateOrders, setPendingTemplateOrders] = useState<Order[]>(
    [],
  )
  const [groups, setGroups] = useState(groupsProp)

  const [loadPrescriptionTemplates] = useFetchPrescriptionTemplate({
    patientId,
    onTemplateFetched: (template, order) => {
      setPendingTemplateOrders(
        pendingTemplateOrders.filter(
          (pendingOrder) => pendingOrder.price?.id !== order.price?.id,
        ),
      )

      selectableListRef.current?.onUpdateItem({
        ...order,
        notes: template ? template.notes : order.notes,
      })
    },
  })

  const disabledWeakMap = pendingTemplateOrders.reduce((weakMap, order) => {
    weakMap.set(order, true)
    return weakMap
  }, new WeakMap())

  const onProceed = (
    items: InvoiceLineItem[],
    closeAfterConvert: boolean = true,
  ) => {
    const mappedItems =
      isChargeSheet && soapId
        ? items.map((item) => ({ ...item, soapId }))
        : items
    if (closeAfterConvert) {
      setCloseAfterConvertOn()
    }
    dispatch(
      convertToLineItems({
        invoiceId,
        clientId,
        patientId,
        invoiceType,
        otcItems: mappedItems,
        bundleDiscount,
      }),
    )
    onSave?.(mappedItems)
  }

  const handleSavePrescription = (
    prescription: Order,
    options = {},
    closeAfterSaving: boolean,
  ) => {
    if (closeAfterSaving) {
      setCloseAfterPrescriptionCreate()
    }

    const prescriptionWithState = !prescription.stateId
      ? logItemStateGetter(prescription)
      : prescription
    createOrUpdatePrescription(prescriptionWithState as Order, {
      ...options,
      outsideSoap: !soapId,
      soapId,
      invoiceId,
    })
  }

  const proceedToSavePrescription = (
    prescription: Order,
    options: CRUDOrderOptions,
    closeAfterSaving: boolean,
  ) => {
    const items = selectableListRef.current?.getItems() || []
    if (R.has('invoiceNo', invoice) && invoice.invoiceNo) {
      dispatch(setInvoiceAutoSave(invoice.invoiceNo, items))
    }
    if (items.length > 0) {
      onProceed(items, false)
      setPendingLineItems(items)
    }
    setPrescriptionOptionsToCreate({ prescription, options, closeAfterSaving })
    if (selectableListRef.current) {
      selectableListRef.current?.clearItems()
    }
  }

  useEffect(() => {
    const chargeSheetSection = R.pipe(
      R.values,
      R.find(
        R.allPass([
          R.propEq('patientId', patientId),
          R.complement(R.propIs(String, 'soapId')),
        ]),
      ),
    )(sections) as ChargeSheetItemSection

    const { groupedItems } = chargeSheetSection || {}

    const flattenGroupedItems: InvoiceLineItem[] = R.chain(
      (group: InvoiceLineItem) => R.prop('items', group) || [group],
      groupedItems || [],
    )
    const newGroups = [{ groupedItems: flattenGroupedItems }]
    setGroups(newGroups)
  }, [sections])

  useEffect(() => {
    if (
      invoiceId &&
      prescriptionOptionsToCreate &&
      lineItemsAreStored(pendingLineItems, groups)
    ) {
      const { prescription, options, closeAfterSaving } =
        prescriptionOptionsToCreate
      handleSavePrescription(prescription, options, closeAfterSaving)
      setPendingLineItems([])
      setPrescriptionOptionsToCreate(undefined)
    }
  }, [invoiceId, groups, prescriptionOptionsToCreate])

  useEffect(() => {
    if (pendingLineItems?.length > 0 && clientId && !isLoading) {
      dispatch(fetchClientFinanceCharges({ id: clientId, soapId }))
    }
  }, [pendingLineItems, isLoading])

  const handleTrySavePrescription = (
    prescription: Order,
    options: CRUDOrderOptions = {},
    closeAfterSaving: boolean,
  ) => {
    const isPrescription = prescription.type === OrderType.PRESCRIPTION
    const signatureDoctorId = options?.signatureInfo?.signerId
    const missingAssignedDoctor =
      isPrescription && !options.doctorId && !signatureDoctorId
    const missingPdmpReportingFields = getMissingPdmpReportingFields(
      prescription,
      client,
    )

    if (missingAssignedDoctor || !R.isEmpty(missingPdmpReportingFields)) {
      openPrescriptionMissingFieldDialog({
        missingAssignedDoctor,
        ...missingPdmpReportingFields,
        inventoryId: prescription.inventory?.id,
        variation: prescription.variation,
        soapId,
        client,
        onOk: () =>
          proceedToSavePrescription(prescription, options, closeAfterSaving),
      })
    } else {
      proceedToSavePrescription(prescription, options, closeAfterSaving)
    }
  }

  const handleRefill = (prescription: Order) => {
    if (!isRefillDialogOpen) {
      openRefillDialog({
        outsideSoap: !soapId,
        prescription,
        onSave: (savedPrescription: Order, options: CRUDOrderOptions) =>
          proceedToSavePrescription(savedPrescription, options, true),
      })
    }
  }

  const addBundle = useCallback((item: Bundle) => {
    if (item.additionalDiscount) {
      setBundleDiscount((oldValue) => oldValue + (item.additionalDiscount || 0))
    }
  }, [])

  const deleteBundle = useCallback((item: Bundle) => {
    if (item.additionalDiscount) {
      setBundleDiscount((oldValue) => oldValue - (item.additionalDiscount || 0))
    }
  }, [])

  const handleOpenPrescriptionDialog = (orderItem: Prescription) => {
    openPrescriptionDialog({
      outsideSoap: !soapId,
      clientId,
      patientId,
      prescription: orderItem,
      soapId,
      onSave: handleTrySavePrescription,
    })
  }

  const handleCheckPrescribable = (
    orderItem: Prescription,
    originalOrderItem: Order,
  ) => {
    if (isEstimate) {
      setPendingTemplateOrders([...pendingTemplateOrders, originalOrderItem])
      loadPrescriptionTemplates(orderItem)
    } else {
      handleOpenPrescriptionDialog(orderItem)
    }
  }

  const handleUncheckPrescribable = (orderItem: Order) => {
    dispatch(removeUnifiedOrder(orderItem))
  }

  const handleEditPrescribable = useCallback(handleOpenPrescriptionDialog, [
    clientId,
    patientId,
  ])

  const entityTypeDisplayName =
    InvoiceTypeDisplayName[
      (type as InvoiceType) || InvoiceType.DEFAULT
    ]?.toLowerCase()
  return (
    <PuiDialog
      aria-labelledby="add-invoice-item-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      {isOtcInvoice && (
        <Text pb={2} pl={3} pt={3} variant="h2">
          {t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.OTC_ADD_ITEM')}
        </Text>
      )}
      <Grid container pt={isOtcInvoice ? 0 : 7}>
        <OrderSelectableListWithFilters
          showPrice
          showQuantity
          showUsedPaidLabels
          ItemTooltipComponent={OrderTooltip}
          ListItemProps={{
            showFoodListItemActions: !isEstimate,
            showListItemActions: false,
            onRefillSelect: handleRefill,
          }}
          addBundle={addBundle}
          clientId={clientId}
          deleteBundle={deleteBundle}
          disabledWeakMap={disabledWeakMap}
          enableFullFilter={isOtcInvoice}
          filters={orderFilters}
          hasMore={orders.length < ordersTotalCount}
          isLoading={isLoading}
          isReceivingListItems={ordersIsReceiving}
          listItems={orders}
          patientId={patientId}
          proceedButtonLabel={t('Common:ADD_TO_ENTITY', {
            entityName: entityTypeDisplayName,
          })}
          ref={selectableListRef}
          showPrePaid={!isEstimate}
          showRange={isEstimate}
          onCheckPrescribable={handleCheckPrescribable}
          onEditPrescribable={handleEditPrescribable}
          onProceed={onProceed}
          onUncheckPrescribable={
            isEstimate ? undefined : handleUncheckPrescribable
          }
        />
      </Grid>
    </PuiDialog>
  )
}

export default AddInvoiceItemDialog
