import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, Tooltip } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import { ReminderSnapshotItem } from '~/types'

interface ReminderNameCellProps {
  item: ReminderSnapshotItem
}

const ReminderNameCell = ({ item }: ReminderNameCellProps) => (
  <Grid container item justifyContent="space-between" wrap="nowrap">
    <Tooltip placement="top" title={item.name || ''}>
      <Grid item>
        <Dotdotdot clamp={1}>
          <Text strong variant="body2">
            {item.name || ''}
          </Text>
        </Dotdotdot>
      </Grid>
    </Tooltip>
    <BusinessShareIcon businessIds={item.businessId ? [item.businessId] : []} />
  </Grid>
)

export default ReminderNameCell
