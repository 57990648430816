import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { all, put, takeLatest } from 'redux-saga/effects'
import {
  ApiError,
  AppointmentCommunicationTemplate,
  Nil,
} from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ConversationTransport } from '~/api/graphql/generated/types'
import { AutoReplyMessageConfig, EmailEntityConfig } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export type AutomaticCommunicationsState = {
  error: string | null
  isFetchingPreview: boolean
  isLoading: boolean
  messagePreview: string | Nil
  textMessagePreview: string | Nil
}

const INITIAL_STATE: AutomaticCommunicationsState = {
  messagePreview: null,
  textMessagePreview: null,
  isLoading: false,
  isFetchingPreview: false,
  error: null,
}

export const automaticCommunicationsReducer = createSlice({
  name: 'automaticCommunications',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAppointmentConfirmationPreview: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        template: AppointmentCommunicationTemplate
      }>,
    ) => {
      state.isLoading = true
      state.isFetchingPreview = true
      state.error = null
    },
    fetchAppointmentConfirmationPreviewSuccess: (
      state,
      action: PayloadAction<{
        confirmationPreview?: Record<string, EmailEntityConfig>
      }>,
    ) => {
      state.isLoading = false
      state.isFetchingPreview = false
      state.messagePreview =
        action.payload.confirmationPreview?.messageConfig?.message
      state.textMessagePreview =
        action.payload.confirmationPreview?.textMessageConfig?.message
    },
    fetchAppointmentConfirmationPreviewFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isLoading = false
      state.isFetchingPreview = false
      state.error = getErrorMessage(action.payload.error)
    },

    previewAutoReplyByTransportType: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        businessId: string
        config: AutoReplyMessageConfig
        transport: ConversationTransport
      }>,
    ) => {
      state.isLoading = true
      state.isFetchingPreview = true
      state.error = null
    },
    previewAutoReplyByTransportTypeSuccess: (
      state,
      action: PayloadAction<{ preview: string }>,
    ) => {
      state.isLoading = false
      state.isFetchingPreview = false
      state.messagePreview = action.payload.preview
    },
    previewAutoReplyByTransportTypeFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isLoading = false
      state.isFetchingPreview = false
      state.error = getErrorMessage(action.payload.error)
    },

    resetAutomaticCommunications: () => INITIAL_STATE,
  },
})

const { actions, reducer } = automaticCommunicationsReducer

export const {
  fetchAppointmentConfirmationPreview,
  fetchAppointmentConfirmationPreviewFailure,
  fetchAppointmentConfirmationPreviewSuccess,
  previewAutoReplyByTransportType,
  previewAutoReplyByTransportTypeFailure,
  previewAutoReplyByTransportTypeSuccess,
  resetAutomaticCommunications,
} = actions

export default reducer

const getAutomaticCommunications = (
  state: RootState,
): AutomaticCommunicationsState => state.automaticCommunications
export const getAutomaticCommunicationsIsLoading = (state: RootState) =>
  getAutomaticCommunications(state).isLoading
export const getAutomaticCommunicationsIsFetchingPreview = (state: RootState) =>
  getAutomaticCommunications(state).isFetchingPreview
export const getAutomaticCommunicationsMessagePreview = (state: RootState) =>
  getAutomaticCommunications(state).messagePreview
export const getAutomaticCommunicationsTextMessagePreview = (
  state: RootState,
) => getAutomaticCommunications(state).textMessagePreview

export function* fetchFetchAppointmentConfirmationPreviewSaga({
  payload,
}: ReturnType<typeof fetchAppointmentConfirmationPreview>) {
  try {
    const confirmationPreview = yield* requestAPI(
      API.fetchFetchAppointmentConfirmationPreview,
      payload.template,
    )
    yield put(
      fetchAppointmentConfirmationPreviewSuccess({ confirmationPreview }),
    )
  } catch (error) {
    yield put(
      fetchAppointmentConfirmationPreviewFailure({ error: error as ApiError }),
    )
  }
}

export function* previewAutoReplyByTransportTypeSaga({
  payload,
}: ReturnType<typeof previewAutoReplyByTransportType>) {
  try {
    const { preview } = yield* requestAPI(
      API.previewAutoReplyByTransportType,
      payload.businessId,
      payload.transport,
      payload.config,
    )

    yield put(previewAutoReplyByTransportTypeSuccess({ preview }))
  } catch (error) {
    yield put(
      previewAutoReplyByTransportTypeFailure({ error: error as ApiError }),
    )
  }
}

function* watchFetchAppointmentConfirmationPreview() {
  yield takeLatest(
    fetchAppointmentConfirmationPreview.type,
    fetchFetchAppointmentConfirmationPreviewSaga,
  )
}

function* watchPreviewAutoReplyByTransportType() {
  yield takeLatest(
    previewAutoReplyByTransportType.type,
    previewAutoReplyByTransportTypeSaga,
  )
}

export function* automaticCommunicationsSaga() {
  yield all([
    watchFetchAppointmentConfirmationPreview(),
    watchPreviewAutoReplyByTransportType(),
  ])
}
