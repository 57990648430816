import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import SnackNotification, { SnackNotificationProps } from './SnackNotification'

const useStyles = makeStyles(
  (theme) => ({
    snackRoot: {
      width: 380,
    },
    iconContainer: {
      width: 40,
      height: 40,
      backgroundColor: theme.colors.tabSelected,
    },
  }),
  { name: 'WebsocketSnackNotification' },
)

export interface WebsocketSnackNotificationProps
  extends Omit<SnackNotificationProps, 'classes'> {}

const WebsocketSnackNotification = (props: WebsocketSnackNotificationProps) => {
  const classes = useStyles()

  return (
    <SnackNotification
      classes={{
        snackRoot: classes.snackRoot,
        iconContainer: classes.iconContainer,
      }}
      {...props}
    />
  )
}

export default WebsocketSnackNotification
