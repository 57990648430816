import React from 'react'

import { CommonDueDates } from '~/components/common/filters/CommonDueDates'
import DateRangeFilter, {
  DateRangeFilterProps,
} from '~/components/common/filters/DateRangeFilter'

const DueDates = [
  CommonDueDates.TODAY,
  CommonDueDates.NEXT_3_DAYS,
  CommonDueDates.NEXT_7_DAYS,
  CommonDueDates.ALL_PAST_DUE_DATE,
]

export interface TasksDateRangeFilterProps extends DateRangeFilterProps {}

const TasksDateRangeFilter = (props: TasksDateRangeFilterProps) => (
  <DateRangeFilter DueDates={DueDates} {...props} />
)

export default TasksDateRangeFilter
