import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsIdexxImagingEnabled } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import SoapWidget from '../SoapWidget'
import { FinalizeSoapChewyActiveRx } from './chewy-active-rx/FinalizeSoapChewyActiveRx'
import FinalizeImagingOrdersSection from './imaging/FinalizeImagingOrdersSection'
import FinalizeLabOrdersSection from './lab-tests/FinalizeLabOrdersSection'

const FinalizeOrdersWidget = () => {
  const { t } = useTranslation(['Common', 'Soap'])

  const isChewyFinalizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_RX_FINALIZATION),
  )
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )

  return (
    <SoapWidget
      id="finalize-orders-widget"
      title={t('Soap:FINALIZE_ORDERS_WIDGET_TITLE')}
    >
      <FinalizeLabOrdersSection />
      {isImagingIntegrated && <FinalizeImagingOrdersSection />}
      {isChewyFinalizationEnabled && <FinalizeSoapChewyActiveRx />}
    </SoapWidget>
  )
}

export default FinalizeOrdersWidget
