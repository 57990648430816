import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  Defaults,
  PrimitiveTableColumn,
  TableFilter,
} from '@pbt/pbt-ui-components'

import ButtonsPanel, {
  ButtonPanelItem,
} from '~/components/common/buttons/ButtonsPanel'
import ExpandableTable, {
  SelectMode,
} from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import DialogNames from '~/constants/DialogNames'
import {
  ColumnNames,
  MandatoryColumns,
  OptionalColumns,
} from '~/constants/onHandConstants'
import {
  clearFilters,
  clearHistory,
  fetchMoreVariations,
  fetchVariations,
  getFilters,
  getIsLoading,
  getTotalCount,
  getVariationsList,
  searchMoreVariations,
  searchVariations,
  setFilters,
} from '~/store/duck/onHandCatalog'
import {
  getOnHandCatalogSelectedColumns,
  updateOnHandCatalogSelectedColumns,
} from '~/store/duck/userSettings'
import {
  useBulkAdjustmentFromOnHand,
  useCreateShipmentFromOnHand,
} from '~/store/hooks/onHandCatalog'
import {
  getCurrentUserId,
  hasBulkEditPermissionInCurrentBusiness,
} from '~/store/reducers/auth'
import { Variation } from '~/types'
import { addSearch, getUrlSearchParam } from '~/utils'
import useDialog from '~/utils/useDialog'

import InventoryCategoryCell from '../catalog/InventoryCategoryCell'
import InventorySubCategoryCell from '../catalog/InventorySubCategoryCell'
import InventoryTableCategoryFilter from '../catalog/InventoryTableCategoryFilter'
import MaxQuantityCell from './MaxQuantityCell'
import OnHandActionsCell from './OnHandActionsCell'
import OnHandAmountCell from './OnHandAmountCell'
import OnHandCatalogTableRow from './OnHandCatalogTableRow'
import OnHandDetails from './OnHandDetails'
import OnHandStatusCell from './OnHandStatusCell'
import OnHandStatusFilter from './OnHandStatusFilter'
import ReOrderPointCell from './ReOrderPointCell'
import ReOrderQuantityCell from './ReOrderQuantityCell'

const columns = [
  {
    label: ColumnNames.ITEM,
    highlight: true,
    prop: 'name',
    width: 3,
  },
  {
    label: ColumnNames.CATEGORY,
    prop: InventoryCategoryCell,
    filter: 'categoryIds',
    FilterComponent: InventoryTableCategoryFilter,
    width: 3,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.SUB_CATEGORY,
    prop: InventorySubCategoryCell,
    width: 4,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.ON_HAND_AMOUNT,
    prop: OnHandAmountCell,
    width: 3,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.LAST_DISTRIBUTOR,
    prop: 'lastDistributor',
    width: 3,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.RE_ORDER_POINT,
    prop: ReOrderPointCell,
    width: 2,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.RE_ORDER_QUANTITY,
    prop: ReOrderQuantityCell,
    width: 2,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.RE_ORDER_MAX_QUANTITY,
    prop: MaxQuantityCell,
    width: 2,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.ON_HAND_STATUS,
    prop: OnHandStatusCell,
    filter: 'onHandStatusIds',
    FilterComponent: OnHandStatusFilter,
    width: 3,
    wrap: 'wrap',
  },
  {
    label: ColumnNames.ACTIONS,
    component: OnHandActionsCell,
    width: 1,
    noTypography: true,
  },
]

const getColumnWithHidden = (
  column: PrimitiveTableColumn,
  activeColumnNames: string[],
): PrimitiveTableColumn => ({
  ...column,
  hidden: !R.includes(column.label, activeColumnNames),
})

export interface OnHandCatalogComponentProps {
  headerButtons: React.ReactNode
  headerTabs: React.ReactNode
  itemId?: string
  onDetailsClose: () => void
}

const OnHandCatalogComponent = ({
  itemId,
  headerButtons,
  headerTabs,
  onDetailsClose,
}: OnHandCatalogComponentProps) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('Common')

  const variationsList = useSelector(getVariationsList)
  const totalCount = useSelector(getTotalCount)
  const filters = useSelector(getFilters)
  const isLoading = useSelector(getIsLoading)
  const currentUserId = useSelector(getCurrentUserId) as string
  const selectedColumns = useSelector(getOnHandCatalogSelectedColumns)
  const hasBulkEditPermission = useSelector(
    hasBulkEditPermissionInCurrentBusiness,
  )

  const [openPrintDialog] = useDialog(DialogNames.ON_HAND_CATALOG_PRINT)
  const [selectedItemsMap, setSelectedItemsMap] = useState<
    Record<string, boolean>
  >({})
  const [selectMode, setSelectMode] = useState(SelectMode.DISABLED)

  const selectedIds =
    selectMode === SelectMode.INCLUDE
      ? Object.keys(selectedItemsMap).filter((id) => selectedItemsMap[id])
      : selectMode === SelectMode.EXCLUDE
        ? variationsList.filter((id) => !selectedItemsMap[id])
        : []

  const search = getUrlSearchParam('query', location.search)

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (search) {
      dispatch(searchMoreVariations(startIndex, endIndex, search))
    } else {
      dispatch(fetchMoreVariations(startIndex, endIndex))
    }
  }

  const changeSelectMode = (newSelectMode: SelectMode) => {
    setSelectedItemsMap({})
    setSelectMode(newSelectMode)
  }

  const disableSelectionMode = () => {
    changeSelectMode(SelectMode.DISABLED)
  }

  const openCreateShipmentDialog = useCreateShipmentFromOnHand(
    selectedIds,
    disableSelectionMode,
  )
  const openBulkAdjustmentDialog = useBulkAdjustmentFromOnHand(
    selectedIds,
    disableSelectionMode,
  )

  useEffect(() => {
    if (search) {
      dispatch(
        searchVariations(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
      )
    } else {
      dispatch(fetchVariations(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT))
    }
    disableSelectionMode()
  }, [search, filters])

  useEffect(
    () => () => {
      dispatch(clearFilters())
      dispatch(clearHistory())
    },
    [],
  )

  const isItemLoaded = (index: number) => Boolean(variationsList[index])

  const isItemSelected =
    selectMode === SelectMode.EXCLUDE
      ? (item: Variation) => !selectedItemsMap[item?.id]
      : (item: Variation) => Boolean(selectedItemsMap[item?.id])

  const onRowSelectionChange = (item: Variation, state: boolean) => {
    setSelectedItemsMap({
      ...selectedItemsMap,
      [item.id]: selectMode === SelectMode.INCLUDE ? state : !state,
    })
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setFilters({}))
  }

  const navigateToItem = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/inventories/on_hand/${id}`))
  }

  const setSelectedColumns = (updatedColumns: string[]) =>
    dispatch(updateOnHandCatalogSelectedColumns(currentUserId, updatedColumns))

  const activeColumns = useMemo(() => {
    const activeColumnNames = [...selectedColumns, ...MandatoryColumns]
    return columns.map((column) =>
      getColumnWithHidden(column, activeColumnNames),
    )
  }, [selectedColumns])

  const isAnySelected =
    selectMode !== SelectMode.DISABLED && selectedIds.length > 0

  const bulkActions = [
    {
      name: t('Common:SELECT_ALL'),
      onClick: () => changeSelectMode(SelectMode.EXCLUDE),
    },
    isAnySelected && {
      name: t('Common:DESELECT_ALL'),
      onClick: () => changeSelectMode(SelectMode.INCLUDE),
    },
    selectMode !== SelectMode.DISABLED &&
      isAnySelected && {
        name: t('Common:ADD_ADJUSTMENTS'),
        onClick: openBulkAdjustmentDialog,
      },
    selectMode !== SelectMode.DISABLED &&
      isAnySelected && {
        name: t('Common:ADD_SHIPMENT'),
        onClick: openCreateShipmentDialog,
      },
  ].filter(Boolean) as ButtonPanelItem[]

  return (
    <Grid container direction="column" flex={1}>
      <ExpandableTable
        Expander={OnHandDetails}
        hasSelectedFilters={!R.isEmpty(filters)}
        headerButtons={headerButtons}
        headerTabs={headerTabs}
        isLoading={isLoading}
        itemId={itemId}
        list={variationsList}
        possibleColumns={OptionalColumns}
        searchTerm={search}
        selectMode={selectMode}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        title={t('Common:INVENTORY')}
        onClose={onDetailsClose}
        onPrint={() => {
          openPrintDialog({
            activeColumns: R.pluck(
              'label',
              activeColumns.filter(({ hidden }) => !hidden),
            ) as string[],
            search,
            showPrompt: totalCount > 100 || totalCount > variationsList?.length,
          })
        }}
        onSelectModeChange={hasBulkEditPermission ? changeSelectMode : null}
        onSelected={navigateToItem}
      >
        <PrimitiveTableWithSearchHighlights
          RowComponent={OnHandCatalogTableRow}
          RowComponentProps={{
            noOverflow: false,
          }}
          columns={activeColumns}
          filters={filters}
          getItemSize={70}
          isItemLoaded={isItemLoaded}
          isItemSelected={isItemSelected}
          isSelectMode={selectMode !== SelectMode.DISABLED}
          loadMoreItems={loadMoreItems}
          totalCount={totalCount}
          onApplyFilter={onApplyFilter}
          onClearFilters={onClearFilters}
          onSelectionChange={onRowSelectionChange}
        />
      </ExpandableTable>
      {selectMode !== SelectMode.DISABLED && !itemId && (
        <ButtonsPanel buttons={bulkActions} onClose={disableSelectionMode} />
      )}
    </Grid>
  )
}

export default OnHandCatalogComponent
