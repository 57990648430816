import { ApiError, FileTemplate, Nil } from '@pbt/pbt-ui-components'
import { GraphQLError } from '@pbt/pbt-ui-components/src/utils/errorTypes'

import { MutationSendGeneratedMessageArgs } from '~/api/graphql/generated/types'
import { MessageStatus, MessageType } from '~/constants/communications'
import {
  ConversationAttachDocumentsContext,
  ConversationFile,
  ConversationMessage,
  Files,
  UploadedAttachment,
} from '~/types'

import {
  ATTACH_DOCUMENTS_TO_MESSAGE,
  ATTACH_DOCUMENTS_TO_MESSAGE_FAILURE,
  CLEAR_FILE_TO_SEND,
  CLEAR_FILES_TO_SEND,
  CLEAR_MESSAGE_FILES,
  CLOSE_MESSAGE_FORMATTING_AREA,
  DELETE_FILE,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  DELETE_MESSAGE,
  DELETE_MESSAGE_FAILURE,
  DELETE_MESSAGE_SUCCESS,
  EDIT_MESSAGE,
  FETCH_ALL_CONVERSATION_FILES,
  FETCH_ALL_CONVERSATION_FILES_FAILURE,
  FETCH_ALL_CONVERSATION_FILES_SUCCESS,
  MESSAGE_FORMATTING_AREA_FOCUSED,
  SEND_CONVERSATION_DRAFT_MESSAGE,
  SEND_CONVERSATION_MESSAGE,
  SEND_CONVERSATION_MESSAGE_FAILURE,
  SEND_CONVERSATION_MESSAGE_SUCCESS,
  SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE,
  SEND_GENERATED_MESSAGE,
  SEND_GENERATED_MESSAGE_FAILURE,
  SEND_GENERATED_MESSAGE_SUCCESS,
  UPDATE_CONVERSATION_FILES,
  UPDATE_CONVERSATION_MESSAGES,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_FAILURE,
  UPDATE_MESSAGE_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_LIST,
  UPLOAD_FILE_LIST_FAILURE,
  UPLOAD_FILE_LIST_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  WAIT_FOR_ATTACHED_DOCUMENT,
} from './types/conversationMessages'

export const updateConversationMessages = (
  messages: Record<string, ConversationMessage>,
) => ({
  type: UPDATE_CONVERSATION_MESSAGES,
  messages,
})
export const updateConversationFiles = (
  files: Record<string, ConversationMessage>,
  conversationId: string,
) => ({
  type: UPDATE_CONVERSATION_FILES,
  files,
  conversationId,
})

export const sendConversationMessage = (
  conversationId: string,
  messageType: MessageType,
  body: string,
  files: ConversationFile[],
) => ({
  type: SEND_CONVERSATION_MESSAGE,
  conversationId,
  messageType,
  body,
  files,
})

export const sendConversationDraftMessage = (
  conversationId: string,
  messageType: MessageType,
  body: string,
) => ({
  type: SEND_CONVERSATION_DRAFT_MESSAGE,
  conversationId,
  messageType,
  body,
})

export const sendConversationMessageSuccess = () => ({
  type: SEND_CONVERSATION_MESSAGE_SUCCESS,
})
export const sendConversationMessageFailure = (error: ApiError) => ({
  type: SEND_CONVERSATION_MESSAGE_FAILURE,
  error,
})
export const sendConversationMessageValidationFailure = (error: ApiError) => ({
  type: SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE,
  error,
})

export const updateMessage = ({
  messageId,
  status,
  text,
  files,
}: {
  files?: ConversationFile[]
  messageId: string
  status: MessageStatus
  text?: string
}) => ({ type: UPDATE_MESSAGE, messageId, status, text, files })
export const updateMessageSuccess = () => ({ type: UPDATE_MESSAGE_SUCCESS })
export const updateMessageFailure = (error: ApiError) => ({
  type: UPDATE_MESSAGE_FAILURE,
  error,
})

export const editMessage = (messageId: string) => ({
  type: EDIT_MESSAGE,
  messageId,
})

export const closeMessageFormattingArea = () => ({
  type: CLOSE_MESSAGE_FORMATTING_AREA,
})
export const messageFormattingAreaFocused = () => ({
  type: MESSAGE_FORMATTING_AREA_FOCUSED,
})

export const deleteMessage = (messageId: string) => ({
  type: DELETE_MESSAGE,
  messageId,
})
export const deleteMessageSuccess = (messageId: string) => ({
  type: DELETE_MESSAGE_SUCCESS,
  messageId,
})
export const deleteMessageFailure = (error: ApiError) => ({
  type: DELETE_MESSAGE_FAILURE,
  error,
})

export const uploadFile = (
  file: FileTemplate,
  clientId: string | Nil,
  patientId: string | Nil,
) => ({
  type: UPLOAD_FILE,
  file,
  clientId,
  patientId,
})
export const uploadFileSuccess = (file: ConversationFile) => ({
  type: UPLOAD_FILE_SUCCESS,
  file,
})
export const uploadFileFailure = (error: ApiError) => ({
  type: UPLOAD_FILE_FAILURE,
  error,
})

export const uploadFileList = (
  attachmentGroup: Files,
  clientId: string | Nil,
  patientId: string | Nil,
) => ({
  type: UPLOAD_FILE_LIST,
  attachmentGroup,
  clientId,
  patientId,
})
export const uploadFileListSuccess = (files: UploadedAttachment[]) => ({
  type: UPLOAD_FILE_LIST_SUCCESS,
  files,
})
export const uploadFileListFailure = (error: ApiError) => ({
  type: UPLOAD_FILE_LIST_FAILURE,
  error,
})

export const attachDocumentsToMessage = (
  documentIds: string[],
  context: ConversationAttachDocumentsContext,
) => ({
  type: ATTACH_DOCUMENTS_TO_MESSAGE,
  documentIds,
  context,
})
export const attachDocumentsToMessageFailure = (error: ApiError) => ({
  type: ATTACH_DOCUMENTS_TO_MESSAGE_FAILURE,
  error,
})

export const waitForAttachedDocuments = () => ({
  type: WAIT_FOR_ATTACHED_DOCUMENT,
})

export const clearFilesToSend = () => ({ type: CLEAR_FILES_TO_SEND })
export const clearFileToSend = (fileId: string) => ({
  type: CLEAR_FILE_TO_SEND,
  fileId,
})
export const clearMessageFiles = (messageId: string) => ({
  type: CLEAR_MESSAGE_FILES,
  messageId,
})

export const deleteFile = (fileId: string) => ({ type: DELETE_FILE, fileId })
export const deleteFileSuccess = (fileId: string) => ({
  type: DELETE_FILE_SUCCESS,
  fileId,
})
export const deleteFileFailure = (error: ApiError) => ({
  type: DELETE_FILE_FAILURE,
  error,
})

export const fetchAllConversationFiles = (conversationId: string) => ({
  type: FETCH_ALL_CONVERSATION_FILES,
  conversationId,
})
export const fetchAllConversationFilesSuccess = (
  conversationId: string,
  files: Record<string, ConversationFile>,
) => ({
  type: FETCH_ALL_CONVERSATION_FILES_SUCCESS,
  conversationId,
  files,
})
export const fetchAllConversationFilesFailure = (error: ApiError) => ({
  type: FETCH_ALL_CONVERSATION_FILES_FAILURE,
  error,
})

export const sendGeneratedMessage = ({
  transport,
  recipients,
  input,
}: MutationSendGeneratedMessageArgs) => ({
  type: SEND_GENERATED_MESSAGE,
  transport,
  recipients,
  input,
})
export const sendGeneratedMessageSuccess = (conversationId: string) => ({
  type: SEND_GENERATED_MESSAGE_SUCCESS,
  conversationId,
})
export const sendGeneratedMessageFailure = (error: GraphQLError) => ({
  type: SEND_GENERATED_MESSAGE_FAILURE,
  error,
})
