import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import {
  TopicContext,
  WsAction,
  WsReportAggregationAction,
} from '~/constants/websocket'
import {
  WS_MEDICAL_HISTORY_EMAIL_CREATE,
  WS_MEDICAL_HISTORY_PDF_CREATE,
  WS_REPORT_CARD_EMAIL_CREATE,
  WS_REPORT_CARD_PDF_CREATE,
} from '~/store/actions/types/communications'
import { NEW_WS_NOTIFICATION } from '~/store/actions/types/notifications'
import { REMOUNT_WEBSOCKET_CHARGES_WIDGET } from '~/store/actions/types/orders'
import { WS_UPDATED_PATIENT_STATUS } from '~/store/actions/types/patients'
import {
  WS_TASK_CREATE,
  WS_TASK_DELETE,
  WS_TASK_UPDATE,
} from '~/store/actions/types/tasks'
import { fetchSoapCharges } from '~/store/duck/charges'
import { updateClientFinanceCharges } from '~/store/duck/clientFinanceData'
import { refetchAllActiveRxForSoap } from '~/store/duck/prescriptions'
import { fetchRefundInvoice } from '~/store/duck/refunds'
import { REMOUNT_WEBSOCKET_REMINDERS_WIDGET } from '~/store/duck/reminders'
import {
  getWebsocketIsConnected,
  WS_MESSAGE_FAILURE,
  wsSubscribe,
  wsUnsubscribe,
} from '~/store/duck/websocket'
import { getCurrentBusinessId, getCurrentUserId } from '~/store/reducers/auth'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import { ContextType, WSTopic, WSTopicContext, WSTopicName } from '~/types'

import { getFullTopic } from './websocketUtils'

export const WSTopics: Record<WSTopicName, WSTopic> = {
  // See src/api/websocket/README.md file for details
  ERROR: {
    topics: ['errors'],
    topicContext: TopicContext.GENERAL,
    useUserPrefix: true,
    actionType: WS_MESSAGE_FAILURE,
    wsMessageHandleDataByWsAction: {
      [WsAction.CREATE]: {
        actionType: WS_MESSAGE_FAILURE,
      },
    },
  },
  NOTIFICATIONS: {
    topics: ['notifications'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_PERSON,
    wsMessageHandleDataByWsAction: {
      [WsAction.CREATE]: {
        actionType: NEW_WS_NOTIFICATION,
      },
    },
  },
  CHARGES_WIDGET_GROUPED_ITEM: {
    topics: ['invoiceLineItem', 'retailOrderLineItem'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_SOAP,
    wsMessageHandleDataByWsAction: {
      [WsAction.CREATE]: {
        actionType: REMOUNT_WEBSOCKET_CHARGES_WIDGET,
      },
      [WsAction.DELETE]: {
        actionType: REMOUNT_WEBSOCKET_CHARGES_WIDGET,
      },
      [WsAction.UPDATE]: {
        actionType: REMOUNT_WEBSOCKET_CHARGES_WIDGET,
      },
    },
  },
  FINALIZE_OUTSTANDING_CHEWY_ACTIVE_RX: {
    topics: ['invoiceLineItem', 'retailOrderLineItem'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_SOAP,
    wsMessageHandleDataByWsAction: {
      [WsAction.CREATE]: {
        actionType: refetchAllActiveRxForSoap.type,
      },
      [WsAction.UPDATE]: {
        actionType: refetchAllActiveRxForSoap.type,
      },
      [WsAction.DELETE]: {
        actionType: refetchAllActiveRxForSoap.type,
      },
    },
  },
  REMINDERS: {
    topics: ['reminder'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_PATIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.CREATE]: {
        actionType: REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
      },
      [WsAction.UPDATE]: {
        actionType: REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
      },
      [WsAction.DELETE]: {
        actionType: REMOUNT_WEBSOCKET_REMINDERS_WIDGET,
      },
    },
  },
  REPORT_AGGREGATION: {
    topics: ['report.aggregation'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_PERSON,
    wsMessageHandleDataByWsAction: {
      [WsReportAggregationAction.REPORT_CARD_PDF_CREATE]: {
        actionType: WS_REPORT_CARD_PDF_CREATE,
      },
      [WsReportAggregationAction.REPORT_CARD_EMAIL_CREATE]: {
        actionType: WS_REPORT_CARD_EMAIL_CREATE,
      },
      [WsReportAggregationAction.MEDICAL_HISTORY_PDF_CREATE]: {
        actionType: WS_MEDICAL_HISTORY_PDF_CREATE,
      },
      [WsReportAggregationAction.MEDICAL_HISTORY_EMAIL_CREATE]: {
        actionType: WS_MEDICAL_HISTORY_EMAIL_CREATE,
      },
    },
  },
  CHARGE_SHEET_GROUPED_ITEM: {
    topics: ['invoiceLineItem', 'retailOrderLineItem'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: updateClientFinanceCharges.type,
      },
      [WsAction.CREATE]: {
        actionType: updateClientFinanceCharges.type,
      },
      [WsAction.DELETE]: {
        actionType: updateClientFinanceCharges.type,
      },
    },
  },
  CHARGE_SHEET_SPECIAL_PROCEDURE: {
    topics: ['patientAutoUpdated'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: WS_UPDATED_PATIENT_STATUS,
      },
    },
  },
  CHARGES_SPECIAL_PROCEDURE: {
    topics: ['patientAutoUpdated'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_PATIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: WS_UPDATED_PATIENT_STATUS,
      },
    },
  },
  TO_DO: {
    topics: ['task'],
    useUserPrefix: false,
    topicContext: TopicContext.BUSINESS_SOAP,
    wsMessageHandleDataByWsAction: {
      [WsAction.CREATE]: {
        actionType: WS_TASK_CREATE,
      },
      [WsAction.UPDATE]: {
        actionType: WS_TASK_UPDATE,
      },
      [WsAction.DELETE]: {
        actionType: WS_TASK_DELETE,
      },
    },
  },
  CLIENT_BALANCE: {
    topics: ['clientBalance'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: updateClientFinanceCharges.type,
      },
    },
  },
  CHARGES_POSTED_ON_CHARGE_SHEET: {
    topics: ['chargesPosted'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: updateClientFinanceCharges.type,
      },
    },
  },
  CHARGES_FINALIZED_ON_SOAP: {
    topics: ['soapFinalized'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: updateClientFinanceCharges.type,
      },
    },
  },
  CHARGES_REOPEN_ON_SOAP: {
    topics: ['soapReopened'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: updateClientFinanceCharges.type,
      },
    },
  },
  CHARGES_POSTED_ON_SOAP: {
    topics: ['chargesPosted'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT_SOAP,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: fetchSoapCharges.type,
      },
    },
  },
  CHARGES_UNPOSTED_ON_SOAP: {
    topics: ['chargesUnposted'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT_SOAP,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: fetchSoapCharges.type,
      },
    },
  },
  CHARGES_SECTION: {
    topics: ['section'],
    useUserPrefix: false,
    topicContext: TopicContext.CLIENT,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: updateClientFinanceCharges.type,
      },
    },
  },
  REFUND_INVOICE: {
    topics: ['invoice'],
    useUserPrefix: false,
    topicContext: TopicContext.INVOICE,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: fetchRefundInvoice.type,
      },
    },
  },
  INVOICE: {
    topics: ['invoice'],
    useUserPrefix: false,
    topicContext: TopicContext.INVOICE,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: fetchInvoiceV3.type,
      },
    },
  },
  INVOICE_UNPOSTED: {
    topics: ['chargesUnposted'],
    useUserPrefix: false,
    topicContext: TopicContext.INVOICE,
    wsMessageHandleDataByWsAction: {
      [WsAction.UPDATE]: {
        actionType: fetchInvoiceV3.type,
      },
    },
  },
}

export interface UseWSTopic {
  context?: WSTopicContext<ContextType.PARTIAL>
  forceBreak?: boolean
  unsubscribe?: boolean
  wsTopic: WSTopic
}

const useWSTopic = ({
  wsTopic,
  context = {
    retrySubscription: false,
    topicParams: {},
    actionParams: {},
  },
  unsubscribe = false,
  forceBreak = false,
}: UseWSTopic) => {
  const dispatch = useDispatch()

  const isConnected = useSelector(getWebsocketIsConnected)
  const businessId = useSelector(getCurrentBusinessId)
  const personId = useSelector(getCurrentUserId)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (forceBreak) {
      return () => {}
    }

    if (
      context.retrySubscription &&
      Object.values(context.topicParams).some(R.isNil)
    ) {
      return () => {}
    }

    const fullContext: WSTopicContext<ContextType.FULL> = {
      ...context,
      topicParams: {
        ...context.topicParams,
        businessId,
        personId,
      },
    }

    const fullTopics = wsTopic.topics.map((topic) => {
      if (businessId && personId) {
        return getFullTopic(topic, wsTopic, fullContext)
      }
      return null
    })

    if (isConnected && fullTopics && fullTopics.length > 0) {
      fullTopics.forEach((topic) => {
        if (topic) {
          dispatch(
            wsSubscribe(
              topic,
              wsTopic.wsMessageHandleDataByWsAction,
              context.actionParams,
            ),
          )
        }
      })
    }

    return () => {
      if (unsubscribe && fullTopics && fullTopics.length > 0) {
        fullTopics.forEach((topic) => {
          if (topic) {
            dispatch(wsUnsubscribe(topic))
          }
        })
      }
    }
  }, [
    isConnected,
    ...(context.retrySubscription ? Object.values(context.topicParams) : []),
  ])
}

export default useWSTopic
