import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  AlertIconType,
  BusinessDiscountConfigurationItem,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { PopperAction } from '~/components/common/ActionsPopper'
import ActionsButton from '~/components/common/buttons/ActionsButton'
import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import { getIsPracticeAdministrator } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { BasePracticeDetailsSectionProps } from '~/types'
import { getBusinessIsInGroup } from '~/utils/businessUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import DiscountPercentTextField from './DiscountPercentTextField'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      borderRadius: 2,
      border: theme.constants.tableBorder,
    },
    headerCell: {
      padding: theme.spacing(1, 2, 0),
      borderRight: theme.constants.tableBorder,
      borderBottom: 'none',
    },
    subHeaderCell: {
      padding: theme.spacing(0, 2),
    },
    subHeaderEmptyCell: {
      borderRight: theme.constants.tableBorder,
    },
    nameCell: {
      height: 50,
      width: 350,
      padding: theme.spacing(0, 1),
      borderRight: theme.constants.tableBorder,
    },
    discountCell: {
      padding: theme.spacing(0, 2),
    },
    bodyRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
  }),
  { name: 'DiscountConfiguration' },
)

const DiscountConfiguration = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Admin', 'Common', 'Tooltips'])

  const parentBusiness = useSelector(getBusiness(business.parentBusinessId!))
  const isPracticeAdminInParentBusiness = useSelector(
    getIsPracticeAdministrator(parentBusiness),
  )

  const getRestrictApplication = () =>
    business.discountConfiguration?.restrictApplication ?? true
  const getItems = () => business.discountConfiguration?.items || []

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const [itemIdToEdit, setItemIdToEdit] = useState<string>()

  const { fields } = useFields([
    {
      name: 'restrictApplication',
      initialValue: getRestrictApplication(),
    },
    {
      name: 'items',
      initialValue: getItems(),
    },
  ])

  const { restrictApplication, items } = fields

  usePracticeFieldsSection({
    business,
    fields,
    // When integrating we need to check whether this will be the correct format
    parsedFields: {
      discountConfiguration: {
        restrictApplication: restrictApplication.value,
        items: items.value,
      },
    },
    sectionName: PracticeDetailsPanels.DISCOUNT_CONFIGURATION,
  })

  const isInGroup = getBusinessIsInGroup(business)

  const removeGroup = (item: BusinessDiscountConfigurationItem) => {
    const index = items.value.indexOf(item)
    items.setValue(R.remove(index, 1, items.value))
  }

  const onRemoveGroupRequested = (item: BusinessDiscountConfigurationItem) => {
    if (item.id) {
      openAlert({
        cancelButtonText: t('Common:NO_KEEP'),
        iconType: AlertIconType.WARN,
        message: t(
          'Admin:PRACTICE.DISCOUNT_CONFIGURATION.DELETE_DISCOUNT_GROUP_WARNING',
          { name: item.name },
        ),
        okButtonText: t('Common:CONTINUE_ACTION'),
        onCancel: closeAlert,
        onOk: () => {
          removeGroup(item)
          closeAlert()
        },
      })
    } else {
      removeGroup(item)
    }
  }

  const getActions = (item: BusinessDiscountConfigurationItem) =>
    [
      item.id && {
        id: 'edit-group-name',
        Icon: EditIcon,
        label: t('Admin:PRACTICE.DISCOUNT_CONFIGURATION.EDIT_GROUP_NAME'),
        onClick: () => setItemIdToEdit(item.id),
      },
      {
        id: 'delete-group',
        Icon: DeleteIcon,
        label: t('Admin:PRACTICE.DISCOUNT_CONFIGURATION.DELETE_DISCOUNT_GROUP'),
        onClick: () => onRemoveGroupRequested(item),
      },
    ] as PopperAction[]

  const createNewItem = () => ({
    active: true,
    name: '',
  })

  const onAdd = () => {
    items.setValue([...items.value, createNewItem()])
  }

  const createFieldChangeHandler =
    (
      fieldName: keyof BusinessDiscountConfigurationItem,
      item: BusinessDiscountConfigurationItem,
    ) =>
    (value: number) => {
      const index = items.value.indexOf(item)
      const newItem = {
        ...item,
        [fieldName]: value,
      }

      items.setValue(R.update(index, newItem, items.value))
    }

  const createBlurHandler = (item: BusinessDiscountConfigurationItem) => () => {
    if (item.id === itemIdToEdit) {
      setItemIdToEdit(undefined)
    }
  }

  return (
    <>
      {!isInGroup && (
        <Grid item mb={1}>
          <PuiSwitch
            checked={restrictApplication.value}
            label={
              <TextWithTooltip
                allowWrap
                tooltipText={t(
                  'Tooltips:ONLY_PRACTICE_ADMIN_CAN_ADD_DISCOUNT_TAGS',
                )}
                variant="body"
              >
                {t(
                  'Admin:PRACTICE.DISCOUNT_CONFIGURATION.RESTRICT_APPLICATION',
                )}
              </TextWithTooltip>
            }
            onChange={Utils.handleFormCheckboxInput(
              restrictApplication.setValue,
            )}
          />
        </Grid>
      )}
      {items.value && items.value.length > 0 && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>
                <TextWithTooltip
                  allowWrap
                  strong
                  tooltipText={t('Tooltips:CLIENT_WITH_TAG_DISCOUNTS')}
                  variant="lowAccent2"
                >
                  {t('Admin:PRACTICE.DISCOUNT_CONFIGURATION.DISCOUNT_GROUP')}
                </TextWithTooltip>
              </TableCell>
              <TableCell className={classes.headerCell} colSpan={3}>
                <TextWithTooltip
                  allowWrap
                  strong
                  tooltipText={t('Tooltips:INVOICE_DISCOUNTS_WHEN_ORDERING')}
                  variant="lowAccent2"
                >
                  {t('Admin:PRACTICE.DISCOUNT_CONFIGURATION.DISCOUNT')}
                </TextWithTooltip>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.subHeaderEmptyCell} />
              <TableCell className={classes.subHeaderCell}>
                <Text variant="body2">{t('Common:INVENTORY')}</Text>
              </TableCell>
              <TableCell className={classes.subHeaderCell}>
                <Text variant="body2">
                  {t('Abbreviations:COMMON.LABORATORIES')}
                </Text>
              </TableCell>
              <TableCell className={classes.subHeaderCell}>
                <Text variant="body2">{t('Common:PROCEDURE_OTHER')}</Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.value.map(
              (item: BusinessDiscountConfigurationItem, index: number) => {
                const blurHandler = createBlurHandler(item)

                return (
                  <TableRow className={classes.bodyRow} key={item.id || index}>
                    <TableCell className={classes.nameCell}>
                      <Grid container item alignItems="center" wrap="nowrap">
                        <Checkbox
                          checked={item.active}
                          onChange={Utils.handleFormCheckboxInput(
                            createFieldChangeHandler('active', item),
                          )}
                        />
                        <Grid item xs>
                          {item.id && item.id !== itemIdToEdit ? (
                            <Text variant="body">{item.name}</Text>
                          ) : (
                            <PuiTextField
                              autoFocus
                              value={item.name || ''}
                              onBlur={blurHandler}
                              onChange={Utils.handleFormTextInput(
                                createFieldChangeHandler('name', item),
                              )}
                              onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                  blurHandler()
                                }
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item ml={1}>
                          <ActionsButton actions={getActions(item)} />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.discountCell}>
                      <DiscountPercentTextField
                        active={item.active}
                        disabled={isInGroup && !isPracticeAdminInParentBusiness}
                        value={item.inventoryDiscount}
                        onChange={createFieldChangeHandler(
                          'inventoryDiscount',
                          item,
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.discountCell}>
                      <DiscountPercentTextField
                        active={item.active}
                        disabled={isInGroup && !isPracticeAdminInParentBusiness}
                        value={item.labsDiscount}
                        onChange={createFieldChangeHandler(
                          'labsDiscount',
                          item,
                        )}
                      />
                    </TableCell>
                    <TableCell className={classes.discountCell}>
                      <DiscountPercentTextField
                        active={item.active}
                        disabled={isInGroup && !isPracticeAdminInParentBusiness}
                        value={item.proceduresDiscount}
                        onChange={createFieldChangeHandler(
                          'proceduresDiscount',
                          item,
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              },
            )}
          </TableBody>
        </Table>
      )}
      {!isInGroup && (
        <Grid item mt={1}>
          <AddButton
            addText={t(
              'Admin:PRACTICE.DISCOUNT_CONFIGURATION.ADD_DISCOUNT_GROUP',
            )}
            onAdd={onAdd}
          />
        </Grid>
      )}
    </>
  )
}

export default memo(DiscountConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
