import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableCell,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import ApplierPopper from '~/components/common/inputs/ApplierPopper'
import { Invoice } from '~/types'

import { getIsBatchInvoice } from '../../../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    taxableCell: {
      minWidth: 84,
      borderRight: 'none !important',
    },
  }),
  { name: 'TaxCellHead' },
)

export interface TaxCellHeadProps {
  className?: string
  invoice: Invoice
  onPopperApplyToAll: (fieldName: string, value: boolean | '') => void
  onPopperClose: (event: React.MouseEvent<HTMLDivElement>) => void
  openPopperId: string | null
  toggleApplierPopperOpen: (event: React.MouseEvent<HTMLDivElement>) => void
}

const TaxCellHead = ({
  className,
  invoice,
  openPopperId,
  onPopperClose,
  toggleApplierPopperOpen,
  onPopperApplyToAll,
}: TaxCellHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [taxable, setTaxable] = useState<boolean | ''>('')

  const isBatchInvoice = getIsBatchInvoice(invoice)

  return (
    <>
      <ApplierPopper
        Component={
          <FormControl component="fieldset">
            <RadioGroup
              aria-label={t('Common:TAXABLE')}
              value={taxable.toString()}
              onChange={(event) => setTaxable(event.target.value === 'true')}
            >
              <FormControlLabel
                control={<Radio />}
                label={t('Common:YES')}
                value="true"
              />
              <FormControlLabel
                control={<Radio />}
                label={t('Common:NO')}
                value="false"
              />
            </RadioGroup>
          </FormControl>
        }
        condition={!isBatchInvoice}
        id="taxable-cell"
        open={openPopperId === 'taxable-cell'}
        onApply={() => onPopperApplyToAll('taxed', taxable)}
        onClick={toggleApplierPopperOpen}
        onClose={onPopperClose}
      >
        <TableCell className={classNames(className, classes.taxableCell)}>
          {t('Common:TAXABLE')}
        </TableCell>
      </ApplierPopper>
      <TableCell align="right" className={className}>
        {t('Common:TAX')}
      </TableCell>
    </>
  )
}

export default TaxCellHead
