import { PathRegexNameMap, RouteNameMap } from '~/constants/routes'

export function getRouteTitleByPath(path: string) {
  const RegexList = Object.keys(PathRegexNameMap)
  const matchedRegExp = RegexList.find((regExp) => RegExp(regExp).test(path))

  if (!matchedRegExp) {
    return undefined
  }

  const handlerOrTitle =
    PathRegexNameMap[matchedRegExp as keyof typeof PathRegexNameMap]
  if (typeof handlerOrTitle !== 'function') {
    return handlerOrTitle
  }
  const matches = path.match(matchedRegExp) || []
  const innerMatches = matches.slice(1)
  const preparedMatches = innerMatches.map(
    (item) => RouteNameMap[item as keyof typeof RouteNameMap] || item,
  )
  return handlerOrTitle(preparedMatches)
}
