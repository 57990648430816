import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiCloseButton, Text } from '@pbt/pbt-ui-components'

import { MembershipFilters } from '~/constants/wellnessPlansConstants'
import i18n from '~/locales/i18n'
import { getFieldsQueryPairs, getUrlSearchParam } from '~/utils'
import useAdvancedFiltersClientValuesMap from '~/utils/useAdvancedFiltersClientValuesMap'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 40,
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      display: 'flex',
    },
    iconButton: {
      opacity: 0.5,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      padding: 0,
      right: 'unset',
      top: 'unset',
      position: 'unset',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'AdvancedFilters' },
)

const FilterPracticeLabels: Record<string, string> = {
  groupName: i18n.t('Businesses:GROUP_NAME'),
  practiceName: i18n.t('Businesses:PRACTICE_NAME'),
  internalName: i18n.t('Businesses:INTERNAL_NAME'),
  accountNumber: i18n.t('Businesses:ACCOUNT_NUMBER'),
  region: i18n.t('Businesses:REGION'),
  status: i18n.t('Businesses:STATUS'),
  tagName: i18n.t('Businesses:TAGS'),
  type: i18n.t('Businesses:TYPES'),
}

const FilterClientLabels: Record<string, string> = {
  firstName: i18n.t('Common:FIRST_NAME'),
  lastName: i18n.t('Common:LAST_NAME'),
  phone: i18n.t('Common:PHONE_NUMBER'),
  email: i18n.t('Common:EMAIL'),
  city: i18n.t('Common:CITY'),
  zip: i18n.t('Common:ZIP_CODE'),
  tag: i18n.t('Search:CLIENT_FILTERS.TAG'),
  externalPersonId: i18n.t('Search:CLIENT_FILTERS.EXTERNAL_PERSON_ID'),
  'patients.name': i18n.t('Search:CLIENT_FILTERS.PATIENTS.NAME'),
  'patients.species': i18n.t('Search:CLIENT_FILTERS.PATIENTS.SPECIES'),
  'patients.gender': i18n.t('Search:CLIENT_FILTERS.PATIENTS.GENDER'),
  'patients.breeds': i18n.t('Search:CLIENT_FILTERS.PATIENTS.BREEDS'),
  'patients.microchipNumber': i18n.t('Common:MICROCHIP_NUMBER'),
  'patients.acquiredType': i18n.t(
    'Search:CLIENT_FILTERS.PATIENTS.ACQUIRED_TYPE',
  ),
  'patients.serviceAnimalType': i18n.t('Common:SERVICE_DESIGNATION'),
  'patients.rabiesTag': i18n.t('Common:RABIES_TAG'),
  'patients.license': i18n.t('Common:LICENSE'),
  'patients.externalPatientId': i18n.t(
    'Search:CLIENT_FILTERS.PATIENTS.EXTERNAL_PATIENT_ID',
  ),
  [MembershipFilters.WELLNESS_PLAN_LEVEL]: i18n.t('Common:MEMBERSHIP'),
  [MembershipFilters.WELLNESS_PLAN_STATUS]: i18n.t(
    'Search:CLIENT_FILTERS.PATIENTS.WELLNESS_PLAN_STATUS',
  ),
  [MembershipFilters.MEMBERSHIP_STATUS]: i18n.t('Common:MEMBERSHIP'),
  [MembershipFilters.WELLNESS_PLAN_VERSION]: i18n.t(
    'Search:CLIENT_FILTERS.PATIENTS.WELLNESS_PLAN_VERSION',
  ),
  [MembershipFilters.WELLNESS_PLAN_CANCELLED]: i18n.t(
    'Search:CLIENT_FILTERS.PATIENTS.WELLNESS_PLAN_CANCELLED',
  ),
  [MembershipFilters.WELLNESS_PLAN_ID]: i18n.t('Common:MEMBERSHIP'),
}

const FilterLabels = { ...FilterPracticeLabels, ...FilterClientLabels }

const AdvancedFilters = ({ searchFieldsQuery = '' }) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const query = getUrlSearchParam('query', location.search) || ''
  const fieldsQuery =
    getUrlSearchParam('fieldsQuery', location.search) || searchFieldsQuery

  const clientValuesMap = useAdvancedFiltersClientValuesMap(fieldsQuery)

  const filterFields = getFieldsQueryPairs(fieldsQuery)

  const removeField = (paramsPair: string[]) => {
    const newFields = R.without([paramsPair], filterFields)
      .map((param) => `${param[0]}=${param[1]}`)
      .join(';')

    const newSearchParams = [
      ['query', query],
      ['fieldsQuery', newFields],
    ]
      .filter((param) => param[1] !== '')
      .map((param) => `${param[0]}=${param[1]}`)
      .join('&')

    navigate(location.pathname + (newSearchParams ? `?${newSearchParams}` : ''))
  }

  const getFilterText = (key: string, value: string) => {
    const displayValue = clientValuesMap[key]
      ? clientValuesMap[key](value)
      : value
    return `${FilterLabels[key]}: ${displayValue}`
  }

  return (
    <>
      {filterFields.map((paramsPair: string[]) => (
        <Grid item key={`${paramsPair[0]}-${paramsPair[1]}`}>
          <Grid item alignItems="center" className={classes.root} pl={2} pr={1}>
            <Text strong variant="lowAccent2">
              {getFilterText(paramsPair[0], paramsPair[1])}
            </Text>
            {!searchFieldsQuery && (
              <PuiCloseButton
                classes={{ iconButton: classes.iconButton }}
                onClick={() => removeField(paramsPair)}
              />
            )}
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default AdvancedFilters
