import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import ConstantValueCell from '~/components/common/lists/primitive-table/cells/ConstantValueCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import { MarketplaceActivityFilterType } from '~/constants/marketplaceConstants'
import i18n from '~/locales/i18n'
import {
  fetchMarketplaceItems,
  fetchMoreMarketplaceItems,
  getMarketplaceItemsList,
  getMarketplaceItemsListIsLoading,
  getMarketplaceItemsTotalCount,
  getMultipleMarketplaceItems,
} from '~/store/duck/marketplace'
import { addSearch, getUrlSearchParam } from '~/utils'

import MarketplaceActiveCell from './MarketplaceActiveCell'
import MarketplaceItemDetails from './MarketplaceItemDetails'
import MarketplaceItemLogoCell from './MarketplaceItemLogoCell'

const useStyles = makeStyles(
  () => ({
    expanderContainer: {
      flex: 2,
    },
  }),
  { name: 'MarketPlaceItemsTableComponent' },
)

const ActivityFilterMap: Record<string, boolean | undefined> = {
  [MarketplaceActivityFilterType.ALL]: undefined,
  [MarketplaceActivityFilterType.ACTIVE]: true,
  [MarketplaceActivityFilterType.INACTIVE]: false,
}

const columns = [
  {
    label: i18n.t('Common:INTEGRATION'),
    component: MarketplaceItemLogoCell,
    width: 4,
  },
  {
    label: i18n.t('Common:DESCRIPTION'),
    prop: 'description',
    width: 5,
  },
  {
    label: i18n.t('Common:CATEGORY'),
    prop: ({ categoryId }: { categoryId: string }) => (
      <ConstantValueCell constant="MarketplaceCategory" value={categoryId} />
    ),
    width: 2,
  },
  {
    label: i18n.t('Common:SUPPORT_CONTACT'),
    prop: 'supportEmail',
    width: 4,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    component: MarketplaceActiveCell,
    width: 1,
  },
]

interface MarketPlaceItemsTableComponentProps {
  headerButtons: any
  marketplaceActivity: string
  marketplaceItemId?: string
  onDetailsClose: () => void
}

const MarketPlaceItemsTableComponent = ({
  marketplaceItemId,
  marketplaceActivity,
  headerButtons,
  onDetailsClose,
}: MarketPlaceItemsTableComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation('Admin')

  const list = useSelector(getMarketplaceItemsList)
  const marketplaceItems = useSelector(getMultipleMarketplaceItems(list))
  const totalCount = useSelector(getMarketplaceItemsTotalCount)
  const marketplaceItemsListIsLoading = useSelector(
    getMarketplaceItemsListIsLoading,
  )

  const navigateToMarketplace = (id: string) => {
    navigate(addSearch(location, `/admin/general/marketplace/${id}`, []))
  }

  const isItemLoaded = (index: number) => Boolean(marketplaceItems[index])

  const search = getUrlSearchParam('query', location.search) || ''

  useEffect(() => {
    if (marketplaceActivity) {
      dispatch(
        fetchMarketplaceItems(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          search,
          ActivityFilterMap[marketplaceActivity],
        ),
      )
    }
  }, [marketplaceActivity, search])

  const loadMoreItems = useCallback((startIndex: number, endIndex: number) => {
    dispatch(
      fetchMoreMarketplaceItems(
        startIndex,
        endIndex,
        search,
        ActivityFilterMap[marketplaceActivity],
      ),
    )
  }, [])

  return (
    <ExpandableTable
      Expander={MarketplaceItemDetails}
      classes={{ expanderContainer: classes.expanderContainer }}
      headerButtons={headerButtons}
      isLoading={marketplaceItemsListIsLoading}
      itemId={marketplaceItemId}
      list={marketplaceItems}
      title={t(
        'Admin:MARKETPLACE.MARKETPLACE_ITEMS_TABLE_COMPONENT.MARKETPLACE_AND_INTEGRATIONS',
      )}
      onClose={onDetailsClose}
      onSelected={navigateToMarketplace}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        isItemLoaded={isItemLoaded}
        list={marketplaceItems}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default MarketPlaceItemsTableComponent
