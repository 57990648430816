import React, { lazy, Suspense } from 'react'
import { LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { PdfPreviewComponentProps } from './PdfPreviewComponent'

const PdfPreviewComponent = lazy(() => import('./PdfPreviewComponent'))

const useStyles = makeStyles(
  () => ({
    spinner: {
      width: '100%',
      height: 3,
    },
  }),
  { name: 'PdfPreview' },
)

const PdfPreview = (props: PdfPreviewComponentProps) => {
  const classes = useStyles()

  return (
    <Suspense fallback={<LinearProgress className={classes.spinner} />}>
      <PdfPreviewComponent {...props} />
    </Suspense>
  )
}

export default PdfPreview
