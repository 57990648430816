import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  PuiAlert,
  PuiAlertProps,
  Text,
} from '@pbt/pbt-ui-components'

import { Order } from '~/types'

const useStyles = makeStyles(
  () => ({
    paper: {
      minWidth: 650,
    },
  }),
  { name: 'RemoveOrdersFromPostedInvoiceDialog' },
)

export interface RemoveOrdersFromPostedInvoiceDialogProps
  extends PuiAlertProps {
  onOk?: () => void
  orders: Order[]
}

const RemoveOrdersFromPostedInvoiceDialog = ({
  orders,
  open,
  onClose,
  onOk,
  ...rest
}: RemoveOrdersFromPostedInvoiceDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Errors'])

  return (
    <PuiAlert
      cancelButtonText={t('Common:GO_BACK')}
      classes={{
        paper: classes.paper,
      }}
      content={
        <Grid container item direction="column" mb={1.5} wrap="nowrap">
          {orders.map((order) => (
            <Text underline key={order.id} mb={1} variant="body2">
              {order.name}
            </Text>
          ))}
        </Grid>
      }
      iconType={AlertIconType.WARN}
      message={t(
        'Errors:ORDERED_ITEMS_WILL_NOT_BE_DELETED_SINCE_INVOICE_IS_POSTED',
      )}
      okButtonText={t('Common:CONTINUE_ACTION')}
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onOk={() => {
        if (onOk) {
          onOk()
        }

        if (onClose) {
          onClose()
        }
      }}
      {...rest}
    />
  )
}

export default RemoveOrdersFromPostedInvoiceDialog
