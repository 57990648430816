import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import {
  Patient,
  TextInteractive,
  TextInteractiveProps,
} from '@pbt/pbt-ui-components'

import { getBreed } from '~/store/reducers/constants'
import { getFullBreedName } from '~/utils'

export interface BreedLabelProps extends TextInteractiveProps {
  patient?: Patient
}

const BreedLabel = ({ patient, ...rest }: BreedLabelProps) => {
  const Breed = useSelector(getBreed)

  if (!patient) {
    return null
  }

  const fullBreedName = getFullBreedName(patient.species, patient.breeds, Breed)

  return (
    <Dotdotdot clamp={2}>
      <TextInteractive align="left" {...rest}>
        {fullBreedName}
      </TextInteractive>
    </Dotdotdot>
  )
}

export default BreedLabel
