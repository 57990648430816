import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  LanguageUtils,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import { getCreatedConversations } from '~/store/reducers/conversations'
import { getRecipientNameWithCommunicationAddress } from '~/utils/communicationsUtils'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflowY: 'visible',
    },
    closeButton: {
      width: 120,
    },
    tableCell: {
      minWidth: 250,
      borderBottom: 'none',
    },
  }),
  { name: 'CreatedConversationsInfoDialog' },
)

export enum CreatedConversationFlow {
  PLAIN_MESSAGE_SENT = 'PLAIN_MESSAGE_SENT',
  DRAFT_MESSAGE_SENT = 'DRAFT_MESSAGE_SENT',
  LOG_PHONE_CALL = 'LOG_PHONE_CALL',
}

export interface CreatedConversationsInfoDialogProps
  extends BasePuiDialogProps {
  flow: CreatedConversationFlow
  handleViewConversation: (conversationId: string) => void
  titleMessageName?: string
}

const CreatedConversationsInfoDialog = ({
  open,
  onClose,
  flow,
  handleViewConversation: onViewConversation,
  titleMessageName,
}: CreatedConversationsInfoDialogProps) => {
  const classes = useStyles()
  const createdConversations = useSelector(getCreatedConversations)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const message = titleMessageName || t('Common:MESSAGE').toLowerCase()

  const isDraftMessageSentFlow =
    flow === CreatedConversationFlow.DRAFT_MESSAGE_SENT
  const isLogPhoneCallFlow = flow === CreatedConversationFlow.LOG_PHONE_CALL

  const draftMessageFlowTitle = t(
    'Dialogs:CREATED_CONVERSATION_INFO_DIALOG.DRAFT_MESSAGE_SENT_TITLE',
    {
      titleMessageName: LanguageUtils.capitalize(message),
    },
  )
  const logPhoneCallFlowTitle = t(
    'Dialogs:CREATED_CONVERSATION_INFO_DIALOG.LOG_PHONE_CALL_TITLE',
  )
  const defaultTitle = t(
    'Dialogs:CREATED_CONVERSATION_INFO_DIALOG.DEFAULT_TITLE',
    {
      titleMessageName: message,
    },
  )

  const title = R.cond([
    [R.always(isDraftMessageSentFlow), R.always(draftMessageFlowTitle)],
    [R.always(isLogPhoneCallFlow), R.always(logPhoneCallFlowTitle)],
    [R.T, R.always(defaultTitle)],
  ])(flow)

  const conversationLinkRows = createdConversations.map(
    ({ id, recipient, transport }) => {
      const to = getRecipientNameWithCommunicationAddress(transport, recipient)
      return { id, to }
    },
  )

  const handleViewConversation = (conversationId: string) => {
    onViewConversation(conversationId)
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader className={classes.closeButton} onClick={onClose}>
          {t('Common:CLOSE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="created-conversations-info-dialog"
      classes={{ paper: classes.paper }}
      open={open}
      title={title}
      onClose={onClose}
    >
      <Grid my={2}>
        <Table>
          <TableBody>
            {conversationLinkRows.map(({ id, to }) => (
              <TableRow key={id}>
                <TableCell classes={{ root: classes.tableCell }}>
                  <Text variant="subheading2">{to}</Text>
                </TableCell>
                <TableCell classes={{ root: classes.tableCell }}>
                  <Text
                    link
                    ml={2.5}
                    variant="subheading2"
                    onClick={() => handleViewConversation(id)}
                  >
                    {t('Common:VIEW_CONVERSATION')}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </PuiDialog>
  )
}

export default CreatedConversationsInfoDialog
