import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
} from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    file: {
      display: 'none',
    },
  }),
  { name: 'FileSelector' },
)

export interface FileSelectorProps {
  accept?: string
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  multiple?: boolean
  onDragOverChange?: (value: boolean) => void
  onlyImages?: boolean
  processFiles: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLInputElement>,
  ) => void
}
const FileSelector = forwardRef(function FileSelector(
  {
    accept,
    classes: classesProp,
    children,
    onDragOverChange,
    onlyImages,
    processFiles,
    multiple = false,
  }: FileSelectorProps,
  ref,
) {
  const classes = useStyles({ classes: classesProp })

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    processFiles(event)
    event.target.value = ''
  }

  const onDragIn = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (onDragOverChange) {
      onDragOverChange(true)
    }
  }

  const onDragOut = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (onDragOverChange) {
      onDragOverChange(false)
    }
  }

  const onDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault()
    onDragOut(event)
    processFiles(event)
  }

  const childrenArray = React.Children.toArray(children) as ReactElement[]

  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => inputRef?.current)

  const content = childrenArray.map((child, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      {React.cloneElement(child, {
        onClick: () => inputRef.current && inputRef.current.click(),
        onDragEnter: onDragIn,
        onDragLeave: onDragOut,
        onDragOver: onDragIn,
        onDrop,
      })}
    </React.Fragment>
  ))

  const acceptFilter = accept ?? (onlyImages ? 'image/*' : undefined)

  return (
    <>
      <input
        accept={acceptFilter}
        className={classes.file}
        multiple={multiple}
        ref={inputRef}
        type="file"
        onChange={onChange}
      />
      {content}
    </>
  )
})

export default FileSelector
