import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { IFrame, MarketplaceItem } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_MARKETPLACE_ITEMS = 'marketplace/FETCH_MARKETPLACE_ITEMS'
export const FETCH_MARKETPLACE_ITEMS_SUCCESS =
  'marketplace/FETCH_MARKETPLACE_ITEMS_SUCCESS'
export const FETCH_MARKETPLACE_ITEMS_FAILURE =
  'marketplace/FETCH_MARKETPLACE_ITEMS_FAILURE'

export const FETCH_MORE_MARKETPLACE_ITEMS =
  'marketplace/FETCH_MORE_MARKETPLACE_ITEMS'
export const FETCH_MORE_MARKETPLACE_ITEMS_SUCCESS =
  'marketplace/FETCH_MORE_MARKETPLACE_ITEMS_SUCCESS'
export const FETCH_MORE_MARKETPLACE_ITEMS_FAILURE =
  'marketplace/FETCH_MORE_MARKETPLACE_ITEMS_FAILURE'

export const UPDATE_MARKETPLACE_ITEMS = 'marketplace/UPDATE_MARKETPLACE_ITEMS'

export const EXPAND_IFRAME = 'marketplace/EXPAND_IFRAME'
export const COLLAPSE_IFRAME = 'marketplace/COLLAPSE_IFRAME'

export const EDIT_MARKETPLACE_ITEM = 'marketplace/EDIT_MARKETPLACE_ITEM'
export const EDIT_MARKETPLACE_ITEM_SUCCESS =
  'marketplace/EDIT_MARKETPLACE_ITEM_SUCCESS'
export const EDIT_MARKETPLACE_ITEM_FAILURE =
  'marketplace/EDIT_MARKETPLACE_ITEM_FAILURE'

export const FETCH_MARKETPLACE_IFRAMES = 'marketplace/FETCH_MARKETPLACE_IFRAMES'
export const FETCH_MARKETPLACE_IFRAMES_SUCCESS =
  'marketplace/FETCH_MARKETPLACE_IFRAMES_SUCCESS'
export const FETCH_MARKETPLACE_IFRAMES_FAILURE =
  'marketplace/FETCH_MARKETPLACE_IFRAMES_FAILURE'

export const fetchMarketplaceItems = (
  from: number,
  to: number,
  query: string,
  active?: boolean,
) => ({
  type: FETCH_MARKETPLACE_ITEMS,
  from,
  to,
  active,
  query,
})
export const fetchMarketplaceItemsSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_MARKETPLACE_ITEMS_SUCCESS,
  list,
  totalCount,
})
export const fetchMarketplaceItemsFailure = (error: ApiError) => ({
  type: FETCH_MARKETPLACE_ITEMS_FAILURE,
  error,
})

export const fetchMoreMarketplaceItems = (
  from: number,
  to: number,
  query: string,
  active?: boolean,
) => ({
  type: FETCH_MARKETPLACE_ITEMS,
  from,
  to,
  active,
  query,
})
export const fetchMoreMarketplaceItemsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MARKETPLACE_ITEMS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreMarketplaceItemsFailure = (error: ApiError) => ({
  type: FETCH_MARKETPLACE_ITEMS_FAILURE,
  error,
})

export const updateMarketplaceItems = (
  marketplaceItems: Record<string, MarketplaceItem>,
) => ({
  type: UPDATE_MARKETPLACE_ITEMS,
  marketplaceItems,
})

export const expandIframe = (iframeId: string) => ({
  type: EXPAND_IFRAME,
  iframeId,
})

export const collapseIframe = () => ({ type: COLLAPSE_IFRAME })

export const editMarketplaceItem = (
  id: string,
  active: boolean,
  rhapsodyApiKey: string,
  jsonParameters: MarketplaceItem['jsonParameters'],
) => ({
  type: EDIT_MARKETPLACE_ITEM,
  id,
  active,
  rhapsodyApiKey,
  jsonParameters,
})

export const editMarketplaceItemSuccess = (
  id: string,
  active: boolean,
  rhapsodyApiKey: string,
  jsonParameters: MarketplaceItem['jsonParameters'],
) => ({
  type: EDIT_MARKETPLACE_ITEM_SUCCESS,
  id,
  active,
  rhapsodyApiKey,
  jsonParameters,
})

export const editMarketplaceItemFailure = (error: ApiError) => ({
  type: EDIT_MARKETPLACE_ITEM_FAILURE,
  error,
})

export const fetchMarketplaceIFrames = () => ({
  type: FETCH_MARKETPLACE_IFRAMES,
})

export const fetchMarketplaceIFramesSuccess = (
  iFramesMap: Record<string, IFrame>,
  workflowIFrameMap: Record<string, string[]>,
) => ({
  type: FETCH_MARKETPLACE_IFRAMES_SUCCESS,
  iFramesMap,
  workflowIFrameMap,
})

export const fetchMarketplaceIFramesFailure = (error: ApiError) => ({
  type: FETCH_MARKETPLACE_IFRAMES_FAILURE,
  error,
})

export type MarketplaceState = {
  error: ApiError | undefined
  expandedIframeId: string | undefined
  iFramesLoading: boolean
  iFramesMap: Record<string, IFrame>
  isEditing: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, MarketplaceItem>
  totalCount: number
  workflowIFrameMap: Record<string, string[]>
}

export const INITIAL_STATE: MarketplaceState = {
  list: [],
  map: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  workflowIFrameMap: {},
  iFramesLoading: false,
  iFramesMap: {},
  isLoading: false,
  isEditing: false,
  expandedIframeId: undefined,
  error: undefined,
}

export const marketplaceReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_MARKETPLACE_ITEMS:
      return {
        ...state,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
        isLoading: true,
      }
    case FETCH_MARKETPLACE_ITEMS_SUCCESS:
      return {
        ...state,
        list: action.list,
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_MARKETPLACE_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_MARKETPLACE_ITEMS:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MORE_MARKETPLACE_ITEMS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_MORE_MARKETPLACE_ITEMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_MARKETPLACE_ITEMS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.marketplaceItems),
      }
    case EXPAND_IFRAME:
      return {
        ...state,
        expandedIframeId: action.iframeId,
      }
    case COLLAPSE_IFRAME:
      return {
        ...state,
        expandedIframeId: undefined,
      }
    case EDIT_MARKETPLACE_ITEM:
      return {
        ...state,
        isEditing: true,
      }
    case EDIT_MARKETPLACE_ITEM_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.id]: {
            ...state.map[action.id],
            active: action.active,
            rhapsodyApiKey: action.rhapsodyApiKey,
            jsonParameters: action.jsonParameters,
          },
        },
        isEditing: false,
      }
    case EDIT_MARKETPLACE_ITEM_FAILURE:
      return {
        ...state,
        isEditing: false,
        error: getErrorMessage(action.error),
      }

    case FETCH_MARKETPLACE_IFRAMES:
      return {
        ...state,
        iFramesLoading: true,
      }
    case FETCH_MARKETPLACE_IFRAMES_SUCCESS:
      return {
        ...state,
        iFramesMap: action.iFramesMap,
        workflowIFrameMap: action.workflowIFrameMap,
        iFramesLoading: false,
      }
    case FETCH_MARKETPLACE_IFRAMES_FAILURE:
      return {
        ...state,
        iFramesLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getMarketplace = (state: RootState): MarketplaceState =>
  state.marketplace
export const getMarketplaceItemsMap = (state: RootState) =>
  getMarketplace(state).map
export const getMarketplaceItemsList = (state: RootState) =>
  getMarketplace(state).list
export const getMarketplaceItemsTotalCount = (state: RootState) =>
  getMarketplace(state).totalCount
export const getMarketplaceItem = (id: string) =>
  createSelector(getMarketplaceItemsMap, (map) => R.prop(id, map))
export const getMultipleMarketplaceItems = (ids: string[]) =>
  createSelector(getMarketplaceItemsMap, (map) => R.props(ids, map))
export const getMarketplaceIFramesLoading = (state: RootState) =>
  getMarketplace(state).iFramesLoading
export const getMarketplaceItemsListIsLoading = (state: RootState) =>
  getMarketplace(state).isLoading
export const getMarketplaceItemIsEditing = (state: RootState) =>
  getMarketplace(state).isEditing
export const getExpandedIframeId = (state: RootState) =>
  getMarketplace(state).expandedIframeId
export const getMarketplaceWorkflowIFrameMap = (state: RootState) =>
  getMarketplace(state).workflowIFrameMap
export const getMarketplaceWorkflowIFrameIds = (id: string | Nil) =>
  createSelector(getMarketplaceWorkflowIFrameMap, (map) =>
    id ? map[id] || [] : [],
  )
export const getMarketplaceIFramesMap = (state: RootState) =>
  getMarketplace(state).iFramesMap
export const getIFrame = (id: string | Nil) =>
  createSelector(getMarketplaceIFramesMap, (map) => (id ? map[id] : undefined))
export const getMultipleIFrames = (ids: string[]) =>
  createSelector(getMarketplaceIFramesMap, (map) => R.props(ids, map))
export const getExpandedIFrame = (state: RootState) =>
  getIFrame(getExpandedIframeId(state))(state)
export const getWorkflowIFrames = (workflowId: string) =>
  createSelector(
    [getMarketplaceWorkflowIFrameIds(workflowId), (state: RootState) => state],
    (ids, state) => getMultipleIFrames(ids)(state),
  )
