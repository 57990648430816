import { put, select } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { BlobWithName, Nil, Patient } from '@pbt/pbt-ui-components'

import AssetDestination from '~/constants/AssetDestination'
import DialogNames from '~/constants/DialogNames'
import { updatePatients } from '~/store/actions/patients'
import { getClientId } from '~/store/reducers/soap'
import { Order, UploadAssetPayload } from '~/types'

import { openDialog } from '../../duck/dialogs'
import { uploadImageAssetAndGenerateThumbnail } from '../../duck/files'

export interface UploadPatientAvatarProps {
  blob: BlobWithName | Nil
  destination: AssetDestination
  patient: Partial<Patient>
  payload: UploadAssetPayload
}

export function* uploadPatientAvatar({
  patient,
  blob,
  destination,
  payload,
}: UploadPatientAvatarProps) {
  let newPatient = patient
  if (blob) {
    const image = yield* uploadImageAssetAndGenerateThumbnail({
      blob,
      destination,
      payload,
    })
    newPatient = {
      ...patient,
      photo: image.imageUrl,
      photoThumbnail: image.imageThumbnailUrl,
    }
  }
  return newPatient
}

export function* handlePatientUpdatedByOrder({
  patientUpdateReason,
  patientUpdateReverted,
  name,
  updatedPatient,
}: Order) {
  const clientId: string | Nil = yield select(getClientId)
  if (!patientUpdateReason) {
    return
  }
  if (updatedPatient?.id) {
    yield put(updatePatients({ [updatedPatient.id]: updatedPatient }))
  }

  yield put(
    openDialog({
      name: DialogNames.AUTOMATED_PATIENT_UPDATE_ALERT,
      id: uuid(),
      unique: true,
      props: {
        clientId,
        patientId: updatedPatient?.id,
        patientName: updatedPatient?.name,
        patientUpdateReason,
        patientUpdateReverted,
        name,
      },
    }),
  )
}
