import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BlobWithName,
  ButtonWithLoader,
  ClassesType,
  ImageScalingUtils,
  Nil,
  PhoneUtils,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import AddList, { Item } from '~/components/common/AddList'
import Avatar from '~/components/common/Avatar'
import { ContactMethodSelect } from '~/components/common/ContactMethodSelect'
import PhoneInput from '~/components/common/form-inputs/PhoneInput'
import ClientEmailTextField from '~/components/common/inputs/ClientEmailTextField'
import DiscountGroupSelect from '~/components/common/inputs/DiscountGroupSelect'
import FileInput from '~/components/common/inputs/file-input/FileInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { SMSConsentField } from '~/components/common/inputs/SMSConsentField'
import PuiSwitch from '~/components/common/PuiSwitch'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '~/components/dashboard/link-chewy-account/LinkedChewyAccountContainer'
import {
  AlertColorLevel,
  DEF_BUSINESS_COLOR_ID,
  useGetAlertColorsWithBusinessDefault,
} from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { updateClient } from '~/store/actions/clients'
import { deleteCoparent } from '~/store/actions/coparents'
import { getClientPreferencesIsLoading } from '~/store/duck/clientPreferences'
import { useBusinessLocalization } from '~/store/hooks/business'
import { useSmsConsentField } from '~/store/hooks/clients'
import { getCurrentBusinessSendRemindersEnabled } from '~/store/reducers/auth'
import { getClientIsLoading } from '~/store/reducers/clients'
import { getFeatureToggle, getTag } from '~/store/reducers/constants'
import { getMultipleUsers, getUser } from '~/store/reducers/users'
import { AtLeast, DataHandleWithUnsavedChanges } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useDialog from '~/utils/useDialog'

import { PreferencesDisplayWidget } from '../../preferences/PreferencesDisplayWidget'
import AddressForm, { AddressFormHandle } from '../AddressForm'
import CoPetParentDialog from '../pet-friend/CoPetParentDialog'
import EmergencyContactDialog from '../pet-friend/EmergencyContactDialog'
import { useGetClientPreferencesViewData } from '../preferences/useGetClientPreferencesViewData'
import ReferredBySelect, {
  joinReferredByIds,
  parseReferredByIds,
} from '../ReferredBySelect'

const AVATAR_SIZE = 88

const useStyles = makeStyles(
  (theme) => ({
    container: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.colors.tableBackground,
      },
    },
    button: {
      width: 100,
    },
    imageInputLogoArea: {
      height: 180,
    },
    imageInputButtonsContainer: {
      marginTop: theme.spacing(2),
    },
    imageInputButtons: {
      width: 200,
      maxWidth: '45%',
      [theme.breakpoints.down('md')]: {
        width: 300,
        maxWidth: '100%',
      },
    },
    profilePic: {
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
      boxShadow:
        '0 14px 26px -12px rgba(0, 0, 0, 0.22), 0 4px 23px 0px rgba(0, 0, 0, 0.07), 0 8px 10px -5px rgba(0, 0, 0, 0.1)',
    },
    fabContainer: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        bottom: 0,
      },
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
    chewyIcon: {
      width: 24,
      height: 24,
    },
  }),
  { name: 'NewClientDetailsEdit' },
)

export interface NewClientDetailsEditHandle
  extends DataHandleWithUnsavedChanges {
  update: () => void
}

interface NewClientDetailsEditProps {
  classes?: ClassesType<typeof useStyles>
  clientId: string | Nil
  onOk?: () => void
  renderCoparents?: boolean
  title?: string
  withDialogWrapper?: boolean
}

const NewClientDetailsEdit = forwardRef<
  NewClientDetailsEditHandle,
  NewClientDetailsEditProps
>(function NewClientDetailsEdit(
  {
    title,
    clientId,
    onOk,
    renderCoparents = true,
    withDialogWrapper = false,
    classes: classesProp,
  },
  ref,
) {
  const navigate = useNavigate()
  const classes = useStyles({ classes: classesProp })
  const isXS = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const { t } = useTranslation([
    'Common',
    'Clients',
    'Validations',
    'Businesses',
  ])

  const dispatch = useDispatch()

  const client = useSelector(getUser(clientId))
  const coparents = useSelector(getMultipleUsers(client?.coparents || []))
  const clientIsLoading = useSelector(getClientIsLoading)
  const Tag = useSelector(getTag)
  const showSendRemindersFlag = useSelector(
    getCurrentBusinessSendRemindersEnabled,
  )
  const clientPreferencesIsLoading = useSelector(getClientPreferencesIsLoading)
  const isDiscountGroupsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DISCOUNT_GROUPS),
  )

  const [coPetParentDialogOpen, setCoPetParentDialogOpen] = useState(false)
  const [emergencyContactDialogOpen, setEmergencyContactDialogOpen] =
    useState(false)
  const [isLogoEditing, setIsLogoEditing] = useState(false)
  const [currentCoparentId, setCurrentCoparentId] = useState<string | null>(
    null,
  )
  const [avatarBlob, setAvatarBlob] = useState<BlobWithName | null>(null)
  const [openClientPreferencesDialog] = useDialog(
    DialogNames.CLIENT_PREFERENCES,
  )

  const addressFormRef = useRef<AddressFormHandle>(null)

  const alertColors = useGetAlertColorsWithBusinessDefault(
    AlertColorLevel.CLIENT,
  )

  const { communicationSmsOptInField, hasChanged } = useSmsConsentField(client)

  const { fields, validate } = useFields(
    [
      {
        name: 'active',
        label: t('Clients:NEW_CLIENT_DETAILS_EDIT.SWITCH_TEXT.ACTIVE_CLIENT'),
        initialValue: client?.active,
        type: 'toggle',
      },
      {
        name: 'shouldNotPromoteWellnessPlans',
        label: t('Common:SHOULD_NOT_PROMOTE_WELLNESS_PLANS'),
        type: 'toggle',
        initialValue: !(client?.promoteWellnessPlans ?? true),
      },
      {
        name: 'sendReminders',
        label: t('Clients:NEW_CLIENT_DETAILS_EDIT.SWITCH_TEXT.SEND_REMINDERS'),
        type: 'toggle',
        initialValue: client?.sendReminders,
      },
      {
        name: 'firstName',
        label: t('Common:CLIENT_FIRST_NAME'),
        validators: ['required'],
        initialValue: client?.firstName,
      },
      {
        name: 'lastName',
        label: t('Common:CLIENT_LAST_NAME'),
        validators: ['required'],
        initialValue: client?.lastName,
      },
      {
        name: 'mobilePhone',
        label: t('Common:MOBILE_PHONE_NUMBER'),
        validators:
          communicationSmsOptInField?.value === true
            ? ['phone', 'required']
            : ['phone'],
        initialValue: client?.mobilePhone || '',
      },
      {
        name: 'homePhone',
        label: t('Common:HOME_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: client?.homePhone || '',
      },
      {
        name: 'workPhone',
        label: t('Common:WORK_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: client?.workPhone || '',
      },
      {
        name: 'otherPhone',
        label: t('Common:OTHER_PHONE_NUMBER'),
        validators: ['phone'],
        initialValue: client?.otherPhone || '',
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['email'],
        initialValue: client?.email || '',
      },
      {
        name: 'additionalEmail',
        label: t('Common:ADDITIONAL_EMAIL'),
        validators: ['email'],
        initialValue: client?.additionalEmail || '',
      },
      {
        name: 'preferredContactMethodId',
        label: t('Common:PREFERRED_CONTACT_METHOD'),
        type: 'select',
        initialValue: client?.preferredContactMethodId || '',
      },
      {
        name: 'discountGroup',
        label: t('Common:DISCOUNT_GROUP'),
        type: 'select',
        initialValue: client?.discountGroup || '',
      },
      {
        name: 'tag',
        label: t('Common:TAG'),
        type: 'select',
        initialValue: client?.tag || '',
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: client?.notes || '',
      },
      {
        name: 'referredBy',
        label: t('Common:REFERRED_BY'),
        type: 'select',
        initialValue:
          joinReferredByIds(
            client?.referralSourceId,
            client?.referralSourceTypeId,
          ) || '',
      },
      {
        name: 'alertText',
        label: t('Common:CLIENT_ALERT'),
        initialValue: client?.alertText,
      },
      {
        name: 'alertColorId',
        label: t('Common:COLOR'),
        initialValue: client?.alertColorId || DEF_BUSINESS_COLOR_ID,
      },
      {
        name: 'nif',
        label: t('Businesses:GENERAL_INFORMATION.NIF'),
        initialValue: client?.taxIdentificationNumber,
      },
    ],
    false,
  )

  const { isSpanishBusiness } = useBusinessLocalization()

  const {
    active,
    shouldNotPromoteWellnessPlans,
    sendReminders,
    firstName,
    lastName,
    mobilePhone,
    homePhone,
    workPhone,
    otherPhone,
    email,
    additionalEmail,
    preferredContactMethodId,
    discountGroup,
    tag,
    notes,
    referredBy,
    alertText,
    alertColorId,
    nif,
  } = fields

  useEffect(() => {
    if (active?.value === false) {
      sendReminders.setValue(false)
    }
  }, [active?.value])

  // When changing the customer’s phone number, even if the customer was opted-in, the toggle
  // should switch off when a new number is added because they have not consented for that phone number.
  useEffect(() => {
    if (
      communicationSmsOptInField?.value &&
      mobilePhone.initialValue !== mobilePhone.value
    ) {
      communicationSmsOptInField.setValue(false)
    }
  }, [mobilePhone.initialValue, mobilePhone.value])

  const getNewDetails = (): AtLeast<User, 'id'> => {
    const [referredById, referredByType] =
      parseReferredByIds(referredBy.value) || []

    return {
      id: clientId!,
      active: active.value,
      promoteWellnessPlans: !shouldNotPromoteWellnessPlans.value,
      sendReminders: sendReminders.value,
      ...(communicationSmsOptInField
        ? {
            globalClientPreferences: {
              communicationSmsOptIn: communicationSmsOptInField.value,
            },
          }
        : {}),
      firstName: firstName.value,
      lastName: lastName.value,
      mobilePhone: PhoneUtils.parsePhoneNumber(mobilePhone.value),
      homePhone: PhoneUtils.parsePhoneNumber(homePhone.value),
      workPhone: PhoneUtils.parsePhoneNumber(workPhone.value),
      otherPhone: PhoneUtils.parsePhoneNumber(otherPhone.value),
      email: email.value,
      additionalEmail: additionalEmail.value,
      preferredContactMethodId: preferredContactMethodId.value,
      ...addressFormRef.current?.get(),
      discountGroup: discountGroup.value,
      tag: tag.value,
      notes: notes.value,
      emergencyContact: client?.emergencyContact,
      referralSourceId: referredById,
      referralSourceTypeId: referredByType,
      alertText: alertText.value,
      alertColorId:
        alertColorId.value === DEF_BUSINESS_COLOR_ID
          ? null
          : alertColorId.value,
      taxIdentificationNumber: nif.value,
    }
  }

  const validateAll = () => {
    const validateAddress = addressFormRef.current?.validate || R.F

    return validate() && validateAddress()
  }

  const updateDetails = () => {
    if (validateAll()) {
      const details = getNewDetails()
      dispatch(updateClient(details, avatarBlob))
      if (onOk) {
        onOk()
      }
    }
  }

  useImperativeHandle(ref, () => ({
    validate: validateAll,
    update: updateDetails,
    get: getNewDetails,
    hasUnsavedChanges: () =>
      Boolean(
        isFieldValuesChanged(fields) ||
          hasChanged ||
          addressFormRef.current?.hasUnsavedChanges(),
      ),
  }))

  const getEmergencyContactsList = (): Item[] => {
    if (client?.emergencyContact && client?.emergencyContact.firstName) {
      return [
        {
          label: Utils.getPersonString(client?.emergencyContact),
          sublabel: `(${t('Common:EMERGENCY_CONTACT')})`,
          id: '0',
        },
      ]
    }
    return []
  }

  const getCopetParentsList = () =>
    coparents.map((user) => ({
      id: user.id,
      label: Utils.getPersonString(user),
      sublabel: `(${t('Common:CO-PET_PARENT')})`,
    }))

  const editEmergencyContact = () => {
    if (isXS) {
      navigate(`/client/${clientId}/emergencycontact`)
    } else {
      setEmergencyContactDialogOpen(true)
    }
  }

  const editCopetParent = (id: string) => {
    if (isXS) {
      navigate(`/client/${clientId}/coparent/${id}`)
    } else {
      setCurrentCoparentId(id)
      setCoPetParentDialogOpen(true)
    }
  }

  const addCopetParent = () => {
    if (isXS) {
      navigate(`/client/${clientId}/coparent/new`)
    } else {
      setCoPetParentDialogOpen(true)
    }
  }

  const clientAvatar = client?.avatar || client?.photo
  const clientAvatarThumbnail =
    client?.avatarThumbnail || client?.photoThumbnail

  const avatarSrc = avatarBlob
    ? URL.createObjectURL(avatarBlob)
    : ImageScalingUtils.getScaledImageOrOriginal(
        clientAvatar,
        clientAvatarThumbnail,
        AVATAR_SIZE,
      )

  const onAvatarReadyV2 = ({ blob }: { blob: BlobWithName }) => {
    setAvatarBlob(blob)
    setIsLogoEditing(false)
  }
  const preferencesDisplayData = useGetClientPreferencesViewData(clientId)

  const onEditPreferences = () => {
    if (!clientPreferencesIsLoading) {
      openClientPreferencesDialog({ clientId })
    }
  }

  return (
    <Grid container className={classes.container} direction="column">
      <Grid container alignItems="center" px={3} spacing={2}>
        <Grid item xs={12}>
          {title && <Text variant="h2">{title}</Text>}
        </Grid>
        <Grid item xs={12}>
          <LinkedChewyAccountContainer
            accountType={KyriosAccountType.CLIENT}
            className={classes.chewyIcon}
            clientId={clientId}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <PuiSwitch field={active} label={active.label} />
        </Grid>
        <Grid item md={8} xs={12}>
          <PuiSwitch
            field={shouldNotPromoteWellnessPlans}
            label={shouldNotPromoteWellnessPlans.label}
          />
        </Grid>
        {showSendRemindersFlag && (
          <Grid item md={communicationSmsOptInField ? 4 : 12} xs={12}>
            <PuiSwitch field={sendReminders} label={sendReminders.label} />
          </Grid>
        )}
        {communicationSmsOptInField && (
          <Grid item md={8} xs={12}>
            <SMSConsentField
              communicationSmsOptInField={communicationSmsOptInField}
              preferredContactMethodIdField={preferredContactMethodId}
            />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={firstName}
            inputProps={{ maxLength: 100 }}
            label={`${firstName.label}*`}
            margin="none"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={lastName}
            inputProps={{ maxLength: 100 }}
            label={`${lastName.label}*`}
            margin="none"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={mobilePhone} />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={homePhone} />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={workPhone} />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhoneInput field={otherPhone} />
        </Grid>
        <Grid item md={6} xs={12}>
          <ClientEmailTextField client={client} field={email} margin="none" />
        </Grid>
        <Grid item md={6} xs={12}>
          <PuiTextField
            field={additionalEmail}
            inputProps={{ maxLength: 100 }}
            label={additionalEmail.label}
            margin="none"
          />
        </Grid>
        <Grid item md={6} mr={{ md: '50%' }} xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="contact-method-select">
              {preferredContactMethodId.label}
            </InputLabel>
            <ContactMethodSelect
              clientId={clientId}
              communicationSmsOptInValue={communicationSmsOptInField?.value}
              field={preferredContactMethodId}
              inputId="contact-method-select"
            />
          </FormControl>
        </Grid>
        <AddressForm ref={addressFormRef} userId={clientId} />
        <Grid item md={6} xs={6}>
          {isDiscountGroupsEnabled ? (
            <DiscountGroupSelect field={discountGroup} />
          ) : (
            <FormControl fullWidth>
              <InputLabel htmlFor="tags-select">{tag.label}</InputLabel>
              <PuiSelect
                field={tag}
                input={<Input id="tags-select" />}
                items={Tag}
                margin="none"
              />
            </FormControl>
          )}
        </Grid>
        <Grid item md={6} xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="referred-by-select">
              {referredBy.label}
            </InputLabel>
            <ReferredBySelect
              field={referredBy}
              input={<Input id="referred-by-select" />}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={6}>
          <PuiTextField
            field={alertText}
            label={alertText.label}
            margin="none"
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="alert-color-select">
              {t('Common:ALERT_COLOR')}
            </InputLabel>
            <PuiSelect
              field={alertColorId}
              input={<Input id="alert-color-select" />}
              items={alertColors}
              label={alertColorId.label}
              renderEmpty={false}
            />
          </FormControl>
        </Grid>
        {isSpanishBusiness && (
          <Grid item md={6} xs={12}>
            <PuiTextField field={nif} label={nif.label} />
          </Grid>
        )}
        <Grid item width="100%">
          <PreferencesDisplayWidget
            showInfoIcon
            headerText={t('Clients:CLIENT_PREFERENCES.DISPLAY.HEADER')}
            preferences={preferencesDisplayData}
            onEditPreferences={onEditPreferences}
          />
        </Grid>
        <Grid item my={2} xs={12}>
          {isLogoEditing || !avatarSrc ? (
            <FileInput
              clearOnCancel
              onlyImages
              aspectRatio={1}
              buttonsHidden={!avatarSrc}
              classes={{
                logoAreaClassName: classes.imageInputLogoArea,
                buttonsContainerClassName: classes.imageInputButtonsContainer,
                buttonsClassName: classes.imageInputButtons,
              }}
              size="normal"
              uploadButtonColor="primary"
              onCancel={() => setIsLogoEditing(false)}
              onFileReady={onAvatarReadyV2}
            />
          ) : (
            <Avatar
              className={classes.profilePic}
              person={client}
              size="normal"
              src={avatarSrc}
              onEditClick={() => setIsLogoEditing(true)}
            />
          )}
        </Grid>
        {renderCoparents && (
          <>
            <Grid item md={12} xs={12}>
              <AddList
                addText={t('Clients:NEW_CLIENT_DETAILS_EDIT.ADD_COPET_PARENT')}
                items={getCopetParentsList()}
                onAdd={addCopetParent}
                onDelete={(id) => {
                  if (clientId) {
                    dispatch(deleteCoparent(clientId, id))
                  }
                }}
                onEdit={editCopetParent}
              />
              <CoPetParentDialog
                clientId={clientId}
                coparentId={currentCoparentId}
                open={coPetParentDialogOpen}
                onClose={() => {
                  setCoPetParentDialogOpen(false)
                  setCurrentCoparentId(null)
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <AddList
                addText={t(
                  'Clients:NEW_CLIENT_DETAILS_EDIT.ADD_EMERGENCY_CONTACT',
                )}
                items={getEmergencyContactsList()}
                onAdd={
                  !client?.emergencyContact ? editEmergencyContact : () => {}
                }
                onDelete={() => {
                  if (clientId) {
                    dispatch(
                      updateClient({ id: clientId, emergencyContact: null }),
                    )
                  }
                }}
                onEdit={editEmergencyContact}
              />
              <EmergencyContactDialog
                clientId={clientId}
                open={emergencyContactDialogOpen}
                onClose={() => setEmergencyContactDialogOpen(false)}
              />
            </Grid>
          </>
        )}
        <Grid item md={12} xs={12}>
          <InputLabel htmlFor="notes-input">
            <Text inline strong variant="body">
              {notes.label}:
            </Text>
            {t('Common:NOTE_NOT_SHARED_WITH_CLIENT')}
          </InputLabel>
          <PuiTextField
            multiline
            field={notes}
            id="notes-input"
            inputProps={{ maxLength: 1000 }}
            minRows={3}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
      {!withDialogWrapper && (
        <Grid className={classes.fabContainer} p={2}>
          <ButtonWithLoader
            className={classes.button}
            disabled={clientIsLoading}
            loading={clientIsLoading}
            onClick={updateDetails}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      )}
    </Grid>
  )
})

export default NewClientDetailsEdit
