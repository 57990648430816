import React from 'react'

import { Order } from '~/types'

import MembershipIcon from './MembershipIcon'

interface OrderMembershipIconProps {
  className?: string
  order: Order
}

const OrderMembershipIcon = ({
  className,
  order,
}: OrderMembershipIconProps) => {
  if (!order.cover) {
    return null
  }

  return <MembershipIcon className={className} limitReached={order.charge} />
}

export default OrderMembershipIcon
