import React from 'react'
import { Trans } from 'react-i18next'

import Link from '~/components/common/link/Link'

export interface FoodSupplementsSuppliesProps {
  categoryName: string
}

const FoodSupplementsSupplies = ({
  categoryName,
}: FoodSupplementsSuppliesProps) => (
  <Trans
    components={{ linkToInventories: <Link to="/admin/catalog/inventories" /> }}
    i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.FOOD_AND_SUPPLEMENTS.TEXT"
    values={{ categoryName }}
  />
)
export default FoodSupplementsSupplies
