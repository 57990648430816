import React from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, MenuList } from '@mui/material'
import {
  AppointmentEventType,
  Constant,
  PuiPopper,
  PuiPopperProps,
  Utils,
} from '@pbt/pbt-ui-components'

import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import { getEventType } from '~/store/reducers/constants'

interface StatusSelectActionPopperProps {
  anchorEl: PuiPopperProps['anchorEl']
  onClick: (value: Constant) => void
  onClose: PuiPopperProps['onClose']
}

const StatusSelectActionPopper = ({
  anchorEl,
  onClick,
  onClose,
}: StatusSelectActionPopperProps) => {
  const EventType = useSelector(getEventType)
  const AppointmentEvent: AppointmentEventType =
    Utils.findConstantByName('Appointment', EventType) || {}

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
      open={Boolean(anchorEl)}
      placement="right-start"
      onClose={onClose}
    >
      <MenuList disableListWrap>
        {AppointmentEvent.states.map((state) => (
          <MenuItem key={state.id} onClick={() => onClick(state)}>
            <AppointmentStateLabel item={{ state }} />
          </MenuItem>
        ))}
      </MenuList>
    </PuiPopper>
  )
}

export default StatusSelectActionPopper
