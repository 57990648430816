import React, {
  Dispatch,
  lazy,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import { Constant, Nil } from '@pbt/pbt-ui-components'

const Dymo = lazy(() => import('./dymo.sdk'))

interface DymoLabelPrintProps {
  activeLabel?: Constant
  activePrinterName?: string
  labelXML?: string | Nil
  labels?: Constant[]
  printTriggered?: boolean
  setActivePrinterName: Dispatch<SetStateAction<string>>
  setPrintTriggered: Dispatch<SetStateAction<boolean>>
  setPrinters: Dispatch<SetStateAction<never[]>>
}

const DymoLabelPrint = ({
  labels,
  labelXML,
  activeLabel,
  activePrinterName,
  setPrinters,
  printTriggered,
  setActivePrinterName,
  setPrintTriggered,
}: DymoLabelPrintProps) => {
  const handleDymoScriptLoaded = useCallback(() => {
    if (!window.dymo) {
      return
    }
    const dymoPrinters = window.dymo?.label?.framework?.getPrinters()
    if (dymoPrinters?.length > 0) {
      setPrinters(dymoPrinters)
      setActivePrinterName(dymoPrinters[0].name)
    }
  }, [])

  useEffect(() => {
    if (!printTriggered || !labelXML || !window.dymo || !activeLabel) {
      return
    }

    const dymoLabel = window.dymo?.label?.framework?.openLabelXml(labelXML)

    if (dymoLabel) {
      dymoLabel.print(activePrinterName)
    }
    setPrintTriggered(false)
  }, [printTriggered, labelXML, labels, activePrinterName, activeLabel?.id])

  return <Dymo onScriptLoaded={handleDymoScriptLoaded} />
}

export default DymoLabelPrint
