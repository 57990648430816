import { useSelector } from 'react-redux'

import { getBusiness } from '~/store/reducers/businesses'

export type BusinessNameCellItem = {
  businessId: string
}

const BusinessNameCell = (item: BusinessNameCellItem) => {
  const business = useSelector(getBusiness(item.businessId))
  return business?.internalName || business?.name || ''
}

export default BusinessNameCell
