import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { ImagingVendorLabel } from '~/constants/imaging'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import {
  getImagingOrderStatuses,
  getImagingVendors,
} from '~/store/reducers/constants'
import { ImagingOrder, Order } from '~/types'

import FinalizeOrdersRow from '../FinalizeOrdersRow'
import { FinalizeImagingOrdersActions } from './FinalizeImagingOrdersActions'

interface FinalizeImagingOrdersRowProps {
  imagingOrder?: ImagingOrder
  outstandingImagingOrder?: Order
}

export default function FinalizeImagingOrdersRow({
  imagingOrder,
  outstandingImagingOrder,
}: FinalizeImagingOrdersRowProps) {
  const { t } = useTranslation('Common')
  const ImagingOrdersStatusesList = useSelector(getImagingOrderStatuses)
  const ImagingVendors = useSelector(getImagingVendors)

  // imagingOrders are finalized, outstandingOrders are not
  const isFinalized = Boolean(imagingOrder)
  const order = isFinalized ? imagingOrder : outstandingImagingOrder
  /** Finalized ImagineOrders have a status already, however non-finalized `orders`
   * do not, so we mark them as `Created` orders as their status in the row to
   * follow a similar status path to lab orders.
   */
  const orderStatus = isFinalized
    ? LanguageUtils.getConstantTranslatedName(
        imagingOrder?.statusId,
        ImagingOrdersStatusesList,
      )
    : t('Common:CREATED')

  const vendorId = useGetIdexxImagingId()
  const vendorName = Utils.getConstantName(vendorId, ImagingVendors)
  const vendorLabel = ImagingVendorLabel[vendorName]

  return (
    <FinalizeOrdersRow
      actions={
        <FinalizeImagingOrdersActions
          finalizedOrder={imagingOrder}
          outstandingOrder={outstandingImagingOrder}
        />
      }
      orderNameDetails={{ orderName: order?.name }}
      showWarning={false}
      statusName={orderStatus}
      vendorName={vendorLabel}
    />
  )
}
