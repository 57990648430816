import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { NotificationSettings } from '~/types'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchNotificationSettings = (
  businessId: string,
  personId: string,
) =>
  request(
    getPreludeAPIPath('notifications/v1/settings'),
    { method: 'GET', params: { businessId, personId } },
    true,
  )

export const updateNotificationSettings = (
  businessId: string,
  personId: string,
  settings: NotificationSettings,
) =>
  request(
    getPreludeAPIPath('notifications/v1/settings'),
    { method: 'PUT', data: settings, params: { businessId, personId } },
    true,
  )

export const fetchNotificationsList = (
  businessId: string,
  personId: string,
  notificationAreaIds: string[] | Nil,
  notificationStyleId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getPreludeAPIPath(`notifications/v2/notifications/person/${personId}`),
    {
      method: 'GET',
      params: {
        businessId,
        notificationAreaIds: notificationAreaIds
          ? encodeURI(notificationAreaIds.join(','))
          : null,
        notificationStyleId,
        from,
        to,
      },
    },
    true,
    Schema.notificationItemsList,
  )

export const fetchNotificationsTotalCounts = (
  businessId: string,
  personId: string,
  menuStyleNotificationAreaIds: string[],
  listStyleNotificationAreaIds: string[],
) =>
  request(
    getPreludeAPIPath(
      `notifications/v1/notifications/person/${personId}/v2/totalCounts`,
    ),
    {
      method: 'GET',
      params: {
        businessId,
        menuStyleNotificationAreaIds: encodeURI(
          menuStyleNotificationAreaIds.join(','),
        ),
        listStyleNotificationAreaIds: encodeURI(
          listStyleNotificationAreaIds.join(','),
        ),
      },
    },
    true,
    Schema.notificationTotalCounts,
  )

export const changeNotificationIsRead = (
  businessId: string,
  notificationId: string,
  isRead: boolean,
) =>
  request(
    getPreludeAPIPath(
      `notifications/v1/notifications/${notificationId}/isRead`,
    ),
    { method: 'POST', params: { businessId, isRead } },
    true,
  )

export const markAllNotificationsAsRead = (
  businessId: string,
  personId: string,
  notificationAreaIds: string[] | null = null,
) =>
  request(
    getPreludeAPIPath(
      `notifications/v1/notifications/person/${personId}/allRead`,
    ),
    {
      method: 'POST',
      params: {
        businessId,
        notificationAreaIds: notificationAreaIds
          ? encodeURI(notificationAreaIds.join(','))
          : null,
      },
    },
    true,
  )

export const deleteNotification = (
  businessId: string,
  notificationId: string,
) =>
  request(
    getPreludeAPIPath(`notifications/v1/notifications/${notificationId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const deleteAllNotifications = (
  businessId: string,
  personId: string,
  notificationAreaIds: string[] | null = null,
) =>
  request(
    getPreludeAPIPath(`notifications/v1/notifications/person/${personId}`),
    {
      method: 'DELETE',
      params: {
        businessId,
        notificationAreaIds: notificationAreaIds
          ? encodeURI(notificationAreaIds.join(','))
          : null,
      },
    },
    true,
  )

export const markNotificationsAsRead = (
  businessId: string,
  personId: string,
  notificationIds: string[],
) =>
  request(
    getPreludeAPIPath(
      `notifications/v1/notifications/person/${personId}/isRead`,
    ),
    {
      method: 'POST',
      params: {
        businessId,
        notificationIds: encodeURI(notificationIds.join(',')),
        isRead: true,
      },
    },
    true,
  )
