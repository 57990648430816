import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Constant,
  Field,
  LanguageUtils,
  Nil,
  PuiSelect,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import PaymentProviders from '~/constants/paymentProviders'
import {
  getPaymentCardType,
  getPaymentMethod,
} from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    paymentProviderCard: {
      cursor: 'pointer',
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      borderRadius: 2,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: 71,
      height: 48,
    },
    paymentProviderIcon: {
      width: '100%',
      height: '100%',
    },
    activeCard: {
      border: `2px solid ${theme.colors.tabSelected}`,
    },
  }),
  { name: 'PaymentMethodSelect' },
)

export interface PaymentMethodSelectProps {
  field: Field
  label?: string
  onPaymentProviderChange: (id: string) => void
  paymentProvider: string | Nil
}

const PaymentMethodSelect = ({
  field,
  label,
  paymentProvider,
  onPaymentProviderChange,
  ...rest
}: PaymentMethodSelectProps) => {
  const classes = useStyles()
  const PaymentMethod: Constant[] = useSelector(getPaymentMethod)
  const PaymentCardType: Constant[] = useSelector(getPaymentCardType)
  const { t } = useTranslation('Common')
  const paymentMethodSelectLabel = label || t('Common:PAYMENTS.PAYMENT_METHOD')

  const DebitCard = Utils.findConstantIdByName('Debit card', PaymentMethod)
  const CreditCard = Utils.findConstantIdByName('Credit card', PaymentMethod)

  const isBankCard = field.value === DebitCard || field.value === CreditCard

  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor="payment-method-select">{`${paymentMethodSelectLabel}*`}</InputLabel>
        <PuiSelect
          fullWidth
          field={field}
          input={<Input id="payment-method-select" />}
          items={PaymentMethod}
          {...rest}
        />
      </FormControl>
      {isBankCard && (
        <Grid container item>
          {PaymentCardType.map((type) => {
            const { id, name } = type
            const Icon = PaymentProviders[name]
            return (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(classes.paymentProviderCard, {
                  [classes.activeCard]: paymentProvider === id,
                })}
                justifyContent="center"
                key={name}
                onClick={() => onPaymentProviderChange(id)}
              >
                {Icon ? (
                  <Icon className={classes.paymentProviderIcon} />
                ) : (
                  <Text variant="body2">
                    {LanguageUtils.getTranslatedFieldName(type)}
                  </Text>
                )}
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export default PaymentMethodSelect
