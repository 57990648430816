import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant as ConstantType,
  LanguageUtils,
  LinkButton,
  PermissionArea,
  SentenceFormatter,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import ControlledSubstanceLabel from '~/components/common/labels/ControlledSubstanceLabel'
import Link from '~/components/common/link/Link'
import Spacer from '~/components/common/Spacer'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getConstantTranslatedName,
  getDrugAdministrationFrequencies,
  getFeatureToggle,
  getInventoryProductSizeUnit,
  getPackageType,
  getPrescriptionProductSizeUnit,
  getProductSizeUnit,
} from '~/store/reducers/constants'
import { DrugTimelineItem } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

const getDrugQtdSentenceLocalized = (
  qtyAmount: number | null,
  unitRawName: string,
  Constant: ConstantType[],
) =>
  LanguageUtils.getSentence([
    qtyAmount,
    LanguageUtils.getTranslatedNameByRawName(unitRawName, Constant),
  ])

const getAdministrationQtdUnitLocalized = (
  admQty: string | null,
  {
    admQtdUnit,
    ProductSizeUnits,
  }: { ProductSizeUnits: ConstantType[]; admQtdUnit: string | null },
  {
    admFrequency,
    DrugAdministrationFrequencies,
  }: {
    DrugAdministrationFrequencies: ConstantType[]
    admFrequency: string | null
  },
) =>
  LanguageUtils.getSentence(
    [
      i18n.t<string>('Common:ADMINISTER'),
      admQty,
      admQtdUnit
        ? LanguageUtils.getTranslatedNameByRawName(admQtdUnit, ProductSizeUnits)
        : admQtdUnit,
      admFrequency
        ? LanguageUtils.getTranslatedNameByRawName(
            admFrequency,
            DrugAdministrationFrequencies,
          )
        : admFrequency,
    ],
    SentenceFormatter.LOWERCASE,
    ' ',
  )

const useStyles = makeStyles(
  () => ({
    substanceLabelRoot: {
      position: 'absolute',
      width: 165,
      right: 12,
      top: 12,
    },
    controlled: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'OrderedDrugCard' },
)

export interface OrderedDrugCardProps extends Omit<TimelineCardProps, 'title'> {
  clientId: string
  item: DrugTimelineItem
  patientId: string
}

const OrderedDrugCard = ({
  clientId,
  patientId,
  item,
  ...rest
}: OrderedDrugCardProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const {
    statusId,
    soapId,
    notes,
    quantity,
    quantityUnitResolved,
    administrationQuantity,
    administrationQuantityUnitResolved,
    administrationFrequencyResolved,
    controlledSubstance,
    invoiceId,
    vet,
  } = item

  const isContextItem = useIsCurrentContextItem(item)

  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const PackageType = useSelector(getPackageType)
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )
  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const status = useSelector(
    getConstantTranslatedName('InventoryStatuses', statusId),
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const ProductSizeUnits = R.uniqBy(
    R.propOr(R.prop('name'), 'nameTranslation'),
    [
      ...ProductSizeUnit,
      ...InventoryProductSizeUnit,
      ...PrescriptionProductSizeUnit,
      ...PackageType,
    ],
  )

  const quantitySentenceLocalized = getDrugQtdSentenceLocalized(
    quantity,
    quantityUnitResolved,
    ProductSizeUnits,
  )
  const administerSentenceLocalized = getAdministrationQtdUnitLocalized(
    administrationQuantity,
    { admQtdUnit: administrationQuantityUnitResolved, ProductSizeUnits },
    {
      admFrequency: administrationFrequencyResolved,
      DrugAdministrationFrequencies,
    },
  )

  const onViewInvoiceClick = useCallback(() => {
    openInvoice({
      clientId,
      patientId,
      invoiceId,
      soapId,
      logId:
        R.has('prescription', item) && R.hasPath(['prescription', 'id'], item)
          ? R.prop('id', item.prescription)
          : item?.id,
      logType: R.has('prescription', item)
        ? OrderType.PRESCRIPTION
        : OrderType.INVENTORY,
    })
  }, [clientId, patientId, item.invoiceId])

  return (
    <TimelineCard
      noMarginBottom
      originBusinessId={item.businessId}
      title={`${t(
        'Common:ADMINISTERED',
      )}—${LanguageUtils.getTranslatedFieldName(item)}`}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {controlledSubstance && (
          <ControlledSubstanceLabel
            classes={{
              root: classes.substanceLabelRoot,
              controlled: classes.controlled,
            }}
          />
        )}
        {Boolean(vet) && <Text strong>{Utils.getPersonString(vet)}</Text>}
        <Spacer />
        {Boolean(notes) && (
          <Text>
            {t('Common:DETAILS')}: {notes}
          </Text>
        )}
        {Boolean(quantity) && (
          <Text>
            {t('Common:QUANTITY')}: {quantitySentenceLocalized}
          </Text>
        )}
        {Boolean(administrationQuantity) && (
          <Text>
            {t('Common:ADMINISTRATION')}: {administerSentenceLocalized}
          </Text>
        )}
      </TimelineCardContent>
      <TimelineCardActions state={status} {...item}>
        {soapPermissions.read && soapId && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${soapId}`,
              isPatientSharingEnabled ? item.businessId : null,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
        {invoicePermissions.read && invoiceId && isContextItem && (
          <LinkButton onClick={onViewInvoiceClick}>
            {t('Common:VIEW_INVOICE')}
          </LinkButton>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default OrderedDrugCard
