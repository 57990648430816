import React, { forwardRef, useImperativeHandle } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CurrencyTextField, Text, useFields } from '@pbt/pbt-ui-components'

import { DataHandle } from '~/types'

const useStyles = makeStyles((theme) => ({
  root: {
    border: theme.constants.tableBorder,
    textAlign: 'left',
  },
}))

export interface InvalidLabTestPriceProps {
  title: string
}

export interface InvalidLabTestPriceHandle extends DataHandle<number> {}

const InvalidLabTestPrice = forwardRef<
  InvalidLabTestPriceHandle,
  InvalidLabTestPriceProps
>(function InvalidLabTestPrice({ title }, ref) {
  const classes = useStyles()

  const { fields, validate } = useFields(
    [
      {
        name: 'price',
        validators: ['required'],
        initialValue: null,
      },
    ],
    false,
  )

  useImperativeHandle(ref, () => ({
    get: () => fields.price.value,
    validate,
  }))

  return (
    <Grid className={classes.root} px={2} py={1}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={4}
      >
        <Grid item xs={9}>
          <Text>{title}</Text>
        </Grid>
        <Grid item xs={3}>
          <CurrencyTextField field={fields.price} />
        </Grid>
      </Grid>
    </Grid>
  )
})

export default InvalidLabTestPrice
