import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  ButtonWithLoader,
  PuiAlert,
} from '@pbt/pbt-ui-components'

import { USER_CANNOT_CREATE_A_NEW_PATIENT } from '~/constants/errorMessages'
import FeatureToggle from '~/constants/featureToggle'
import { useCreatePatient } from '~/store/hooks/patient'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientsIsLoading } from '~/store/reducers/patients'

import Patient, { PatientHandle } from './Patient'
import PatientFormTitle from './PatientFormTitle'

const useStyles = makeStyles(
  () => ({
    button: {
      width: 200,
    },
    buttonsContainer: {
      width: '100%',
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
    },
  }),
  { name: 'EditPatientPage' },
)

const EditPatientPage = () => {
  const classes = useStyles()
  const { patientId = '', clientId = '' } = useParams()
  const patientIsLoading = useSelector(getPatientsIsLoading)
  const { t } = useTranslation('Common')
  const { createPatient } = useCreatePatient()
  const [showAlert, setShowAlert] = useState(true)
  const navigate = useNavigate()

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )
  const showAddPatient = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const patientRef = useRef<PatientHandle>(null)

  const handleCloseAlert = () => {
    setShowAlert(false)
    navigate('/dashboard')
  }

  if (!patientId && !showAddPatient)
    return (
      <PuiAlert
        iconType={AlertIconType.WARN}
        message={USER_CANNOT_CREATE_A_NEW_PATIENT}
        okButtonText={t('Common:OK')}
        open={showAlert}
        onClose={handleCloseAlert}
        onOk={handleCloseAlert}
      />
    )

  return (
    <Grid container item direction="column">
      <PatientFormTitle patientId={patientId} pt={2} px={3} />
      <Patient
        clientId={clientId}
        createPatient={createPatient}
        patientId={patientId}
        ref={patientRef}
      />
      <Grid container item className={classes.buttonsContainer} px={3} py={2}>
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            disabled={patientIsLoading}
            loading={patientIsLoading}
            onClick={() => patientRef.current?.addPatient()}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditPatientPage
