import React, { useState } from 'react'
import { TableBody, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { findIndex, includes, propEq, update } from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { BatchInvoice, Invoice, InvoiceOrEstimate } from '~/types'

import { getIsBatchInvoice } from '../../invoiceUtils'
import InvoiceCollapsibleRow from './collapsible/InvoiceCollapsibleRow'
import InvoiceItemGroups, { InvoiceItemGroupsProps } from './InvoiceItemGroups'

const useStyles = makeStyles(
  (theme) => ({
    tableBody: {},
    tableBodyExpanded: {
      border: theme.constants.tableBorderSelected,
      borderStyle: 'double',
    },
    separator: {
      height: theme.spacing(1),
    },
  }),
  { name: 'InvoiceTableBody' },
)

export interface InvoiceTableBodyProps
  extends Omit<InvoiceItemGroupsProps, 'invoice' | 'updateInvoice'> {
  invoice: BatchInvoice | InvoiceOrEstimate
  updateInvoice: (invoice: BatchInvoice | Invoice) => void
}

const InvoiceTableBody = ({
  invoice,
  updateInvoice,
  onUnblockEditInvoice,
  ...rest
}: InvoiceTableBodyProps) => {
  const classes = useStyles()

  const [expandedInvoiceIds, setExpandedInvoiceIds] = useState([])

  const batchInvoice = invoice as BatchInvoice

  const toggleExpand = (invoiceId: string) => {
    setExpandedInvoiceIds(Utils.toggleListItem(invoiceId, expandedInvoiceIds))
  }

  const updateChildInvoice = (childInvoice: Invoice) => {
    const childInvoiceIndex = findIndex(
      propEq('id', childInvoice.id),
      batchInvoice.invoices,
    )
    const newInvoice = {
      ...batchInvoice,
      invoices: update(childInvoiceIndex, childInvoice, batchInvoice.invoices),
    }

    updateInvoice(newInvoice)
  }

  if (getIsBatchInvoice(invoice)) {
    return (
      <>
        {batchInvoice.invoices.map((childInvoice, index) => {
          const { id } = childInvoice
          const isExpanded = includes(id, expandedInvoiceIds)

          return (
            <React.Fragment key={id}>
              <TableBody
                className={classNames(classes.tableBody, {
                  [classes.tableBodyExpanded]: isExpanded,
                })}
              >
                <InvoiceCollapsibleRow
                  invoice={childInvoice}
                  isExpanded={isExpanded}
                  toggleExpand={toggleExpand}
                  updateInvoice={updateChildInvoice}
                  onUnblockEditInvoice={onUnblockEditInvoice}
                  {...rest}
                />
              </TableBody>
              {index < invoice.invoices.length - 1 && (
                <TableBody>
                  <TableRow className={classes.separator} />
                </TableBody>
              )}
            </React.Fragment>
          )
        })}
      </>
    )
  }

  return (
    <TableBody>
      <InvoiceItemGroups
        invoice={invoice as Invoice}
        updateInvoice={updateInvoice}
        onUnblockEditInvoice={onUnblockEditInvoice}
        {...rest}
      />
    </TableBody>
  )
}

export default InvoiceTableBody
