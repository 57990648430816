import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  FETCH_DISCOUNT_GROUPS,
  fetchDiscountGroups,
  fetchDiscountGroupsFailure,
  fetchDiscountGroupsSuccess,
} from '../duck/discountGroups'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchDiscountGroupsSaga({
  businessId,
}: ReturnType<typeof fetchDiscountGroups>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchDiscountGroups,
      businessId,
    )
    yield call(updateEntities, entities)
    yield put(fetchDiscountGroupsSuccess(result))
  } catch (error) {
    yield put(fetchDiscountGroupsFailure(error as ApiError))
  }
}

function* watchFetchDiscountGroups() {
  yield takeLatest(FETCH_DISCOUNT_GROUPS, fetchDiscountGroupsSaga)
}

export default function* discountGroupsSaga() {
  yield all([watchFetchDiscountGroups()])
}
