import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import PuiButtonGroup from '~/components/common/buttons/PuiButtonGroup'
import i18n from '~/locales/i18n'

import RecordsComponent from './records/RecordsComponent'
import SnapshotsComponent from './snapshots/SnapshotsComponent'

const useStyles = makeStyles(
  (theme) => ({
    switchComponentContainer: {
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
    },
  }),
  { name: 'SnapshotsAndRecordsComponent' },
)

const ActiveMode = {
  SNAPSHOTS: 'snapshots',
  RECORDS: 'records',
}

const SnapshotsAndRecordsStates = [
  {
    label: i18n.t('Clients:SNAPSHOTS_AND_RECORDS.PATIENT_SNAPSHOTS_LABEL'),
    component: SnapshotsComponent,
  },
  {
    label: i18n.t('Clients:SNAPSHOTS_AND_RECORDS.PATIENT_RECORDS_LABEL'),
    component: RecordsComponent,
  },
]

const ModeRegex =
  /^\/client\/([A-Za-z0-9]+)\/patient\/([A-Za-z0-9]+)\/?([A-Za-z0-9]+)?/
const getModeFromUrl = (pathname: string) => pathname.match(ModeRegex)?.[3]

interface SnapshotsAndRecordsComponentProps {
  clientId: string
  patientId: string
}

const SnapshotsAndRecordsComponent = ({
  clientId,
  patientId,
}: SnapshotsAndRecordsComponentProps) => {
  const classes = useStyles()

  const location = useLocation()
  const navigate = useNavigate()
  const activeTimelineType = getModeFromUrl(location.pathname)

  const setActiveMode = (mode: string) => {
    navigate(`/client/${clientId}/patient/${patientId}/${mode}`, {
      replace: true,
    })
  }

  useEffect(() => {
    if (
      !activeTimelineType ||
      !Object.values(ActiveMode).includes(activeTimelineType)
    ) {
      setActiveMode(ActiveMode.SNAPSHOTS)
    }
  }, [location.pathname, location.search])

  const activeState =
    activeTimelineType === ActiveMode.RECORDS
      ? SnapshotsAndRecordsStates[1]
      : SnapshotsAndRecordsStates[0]

  const Component = activeState.component

  return (
    <Grid container direction="column">
      <Grid
        container
        className={classes.switchComponentContainer}
        px={2}
        py={1}
      >
        <PuiButtonGroup
          items={SnapshotsAndRecordsStates}
          selectedItem={activeState}
          onItemSelected={() =>
            setActiveMode(
              activeTimelineType === ActiveMode.SNAPSHOTS
                ? ActiveMode.RECORDS
                : ActiveMode.SNAPSHOTS,
            )
          }
        />
      </Grid>
      {activeTimelineType && (
        <Component clientId={clientId} patientId={patientId} />
      )}
    </Grid>
  )
}

export default SnapshotsAndRecordsComponent
