import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Business, Defaults, Nil, User } from '@pbt/pbt-ui-components'

import { SoapWidget } from '~/api/graphql/generated/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  ADD_FAVORITE_BUSINESS,
  ADD_FAVORITE_BUSINESS_FAILURE,
  ADD_FAVORITE_BUSINESS_SUCCESS,
  CREATE_BUSINESS,
  CREATE_BUSINESS_FAILURE,
  CREATE_BUSINESS_LICENSE,
  CREATE_BUSINESS_LICENSE_FAILURE,
  CREATE_BUSINESS_LICENSE_SUCCESS,
  CREATE_BUSINESS_SSO_IDP_SETTINGS,
  CREATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  CREATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  CREATE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_GROUP,
  DELETE_BUSINESS_GROUP_FAILURE,
  DELETE_BUSINESS_GROUP_SUCCESS,
  DELETE_BUSINESS_LICENSE,
  DELETE_BUSINESS_LICENSE_FAILURE,
  DELETE_BUSINESS_LICENSE_SUCCESS,
  DELETE_BUSINESS_SSO_IDP_SETTINGS,
  DELETE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  DELETE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  EDIT_BUSINESS_BRANDING_CONFIGURATION,
  EDIT_BUSINESS_BRANDING_CONFIGURATION_FAILURE,
  EDIT_BUSINESS_BRANDING_CONFIGURATION_SUCCESS,
  EDIT_BUSINESS_LICENSE,
  EDIT_BUSINESS_LICENSE_FAILURE,
  EDIT_BUSINESS_LICENSE_SUCCESS,
  FETCH_ASSIGNED_PRACTICES,
  FETCH_ASSIGNED_PRACTICES_FAILURE,
  FETCH_ASSIGNED_PRACTICES_SUCCESS,
  FETCH_BUSINESS,
  FETCH_BUSINESS_BRANDING_CONFIGURATION,
  FETCH_BUSINESS_BRANDING_CONFIGURATION_FAILURE,
  FETCH_BUSINESS_BRANDING_CONFIGURATION_SUCCESS,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_GROUP_TAGS,
  FETCH_BUSINESS_GROUP_TAGS_FAILURE,
  FETCH_BUSINESS_GROUP_TAGS_SUCCESS,
  FETCH_BUSINESS_SOAP_WIDGETS,
  FETCH_BUSINESS_SOAP_WIDGETS_FAILURE,
  FETCH_BUSINESS_SOAP_WIDGETS_SUCCESS,
  FETCH_BUSINESS_SSO_IDP_SETTINGS,
  FETCH_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  FETCH_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESSES_LIST,
  FETCH_BUSINESSES_LIST_FAILURE,
  FETCH_BUSINESSES_LIST_SUCCESS,
  FETCH_LOCATIONS_LIST,
  FETCH_LOCATIONS_LIST_FAILURE,
  FETCH_LOCATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_SUCCESS,
  FETCH_SUGGESTIONS_LIST,
  FETCH_SUGGESTIONS_LIST_FAILURE,
  FETCH_SUGGESTIONS_LIST_SUCCESS,
  REMOVE_FAVORITE_BUSINESS,
  REMOVE_FAVORITE_BUSINESS_FAILURE,
  REMOVE_FAVORITE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_FAILURE,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESSES,
  UPDATE_COUNTRY_CATALOG_CODE,
  UPDATE_COUNTRY_CATALOG_CODE_FAILURE,
  UPDATE_COUNTRY_CATALOG_CODE_SUCCESS,
  UPDATE_FAVORITE_BUSINESS_LIST,
  UPDATE_LOCK_WORKING_HOURS_SETTINGS,
} from '../actions/types/businesses'
import type { RootState } from '../index'

export type BusinessesState = {
  assignedPracticesList: string[]
  error: string | null
  favoriteList: string[]
  isFetching: boolean
  isFetchingBusinessSoapWidgets: boolean
  isFetchingList: boolean
  isLoading: boolean
  isLocationsLoading: boolean
  isSaving: boolean
  isSuggestionsLoading: boolean
  list: string[]
  locationList: string[]
  locationTotalCount: number
  lockWorkingHoursSettings: User['lockWorkingHoursSettings']
  map: Record<string, Business>
  soapWidgets?: (SoapWidget & { id: string })[]
  suggestionList: string[]
  suggestionTotalCount: number
  supportedLanguageCodes: string[]
  totalCount: number
}

export const BUSINESSES_INITIAL_STATE: BusinessesState = {
  map: {},
  list: [],
  locationList: [],
  suggestionList: [],
  error: null,
  isLoading: false,
  isFetchingBusinessSoapWidgets: false,
  isFetchingList: false,
  isFetching: false,
  isSaving: false,
  isLocationsLoading: false,
  isSuggestionsLoading: false,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  locationTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  suggestionTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  lockWorkingHoursSettings: [],
  assignedPracticesList: [],
  favoriteList: [],
  supportedLanguageCodes: [],
}

const businesses = (
  state: BusinessesState = BUSINESSES_INITIAL_STATE,
  action: AnyAction,
): BusinessesState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return BUSINESSES_INITIAL_STATE
    case UPDATE_BUSINESSES:
      return { ...state, map: secondLevelMerge(state.map, action.businesses) }
    case FETCH_BUSINESSES_LIST:
      return {
        ...state,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        isLoading: true,
        isFetchingList: true,
      }
    case FETCH_BUSINESSES_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetchingList: false,
      }
    case FETCH_BUSINESSES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetchingList: false,
      }
    case FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case FETCH_LOCATIONS_LIST:
      return {
        ...state,
        locationList: [],
        locationTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        isLocationsLoading: true,
        error: null,
      }
    case FETCH_LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        locationList: R.uniq(action.locationList),
        locationTotalCount: action.locationTotalCount,
        isLocationsLoading: false,
      }
    case FETCH_LOCATIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLocationsLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST:
      return { ...state, isLocationsLoading: true, error: null }
    case FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLocationsLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        locationList: mergeArraysAtIndex(
          state.locationList,
          action.locationList,
          action.from,
        ),
        isLocationsLoading: false,
        locationTotalCount: action.locationTotalCount,
      }
    case FETCH_SUGGESTIONS_LIST:
      return {
        ...state,
        suggestionList: [],
        suggestionTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        isSuggestionsLoading: true,
        error: null,
      }
    case FETCH_SUGGESTIONS_LIST_SUCCESS:
      return {
        ...state,
        suggestionList: R.uniq(action.suggestionList),
        suggestionTotalCount: action.suggestionTotalCount,
        isSuggestionsLoading: false,
      }
    case FETCH_SUGGESTIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isSuggestionsLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST:
      return { ...state, isSuggestionsLoading: true, error: null }
    case FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isSuggestionsLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_SUCCESS:
      return {
        ...state,
        suggestionList: mergeArraysAtIndex(
          state.suggestionList,
          action.suggestionList,
          action.from,
        ),
        isSuggestionsLoading: false,
        suggestionTotalCount: action.suggestionTotalCount,
      }
    case FETCH_ASSIGNED_PRACTICES:
      return { ...state, assignedPracticesList: [], isLoading: true }
    case FETCH_ASSIGNED_PRACTICES_SUCCESS:
      return {
        ...state,
        assignedPracticesList: R.uniq(action.list),
        isLoading: false,
      }
    case FETCH_ASSIGNED_PRACTICES_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case UPDATE_FAVORITE_BUSINESS_LIST:
      return { ...state, favoriteList: R.uniq(action.favouriteBusinessIds) }
    case REMOVE_FAVORITE_BUSINESS:
    case ADD_FAVORITE_BUSINESS:
      return { ...state, isLoading: true }
    case REMOVE_FAVORITE_BUSINESS_SUCCESS:
    case ADD_FAVORITE_BUSINESS_SUCCESS:
      return {
        ...state,
        favoriteList: R.uniq(action.favouriteBusinessIds),
        isLoading: false,
      }
    case REMOVE_FAVORITE_BUSINESS_FAILURE:
    case ADD_FAVORITE_BUSINESS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CREATE_BUSINESS:
      return { ...state, isLoading: true }
    case CREATE_BUSINESS_SUCCESS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.businesses),
        isLoading: false,
      }
    case CREATE_BUSINESS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case UPDATE_BUSINESS:
      return { ...state, isLoading: true, isSaving: true }
    case UPDATE_BUSINESS_SUCCESS:
      return { ...state, isLoading: false, isSaving: false }
    case UPDATE_BUSINESS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isSaving: false,
      }
    case FETCH_BUSINESS:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_BUSINESS_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_BUSINESS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
      }
    case DELETE_BUSINESS_GROUP:
      return { ...state, isLoading: true, isSaving: true }
    case DELETE_BUSINESS_GROUP_SUCCESS:
      return { ...state, isLoading: false, isSaving: false }
    case DELETE_BUSINESS_GROUP_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isSaving: false,
      }
    case FETCH_BUSINESS_GROUP_TAGS:
      return { ...state, isLoading: true }
    case FETCH_BUSINESS_GROUP_TAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.groupId]: {
            ...state.map[action.groupId],
            groupTags: action.groupTags,
          },
        },
      }
    case FETCH_BUSINESS_GROUP_TAGS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CREATE_BUSINESS_LICENSE:
    case EDIT_BUSINESS_LICENSE:
    case DELETE_BUSINESS_LICENSE:
      return { ...state, isLoading: true, isSaving: true }
    case CREATE_BUSINESS_LICENSE_SUCCESS:
    case EDIT_BUSINESS_LICENSE_SUCCESS:
    case DELETE_BUSINESS_LICENSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        map: {
          ...state.map,
          [action.businessId]: {
            ...state.map[action.businessId],
            licenses: action.licenses,
          },
        },
      }
    case CREATE_BUSINESS_LICENSE_FAILURE:
    case EDIT_BUSINESS_LICENSE_FAILURE:
    case DELETE_BUSINESS_LICENSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        isSaving: false,
      }
    case UPDATE_LOCK_WORKING_HOURS_SETTINGS:
      return {
        ...state,
        lockWorkingHoursSettings: action.lockWorkingHoursSettings,
      }
    case FETCH_BUSINESS_BRANDING_CONFIGURATION:
    case EDIT_BUSINESS_BRANDING_CONFIGURATION:
      return { ...state, isLoading: true }
    case FETCH_BUSINESS_BRANDING_CONFIGURATION_SUCCESS:
    case EDIT_BUSINESS_BRANDING_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.businessId]: {
            ...state.map[action.businessId],
            ...action.brandingConfiguration,
          },
        },
      }
    case FETCH_BUSINESS_BRANDING_CONFIGURATION_FAILURE:
    case EDIT_BUSINESS_BRANDING_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_COUNTRY_CATALOG_CODE:
      return {
        ...state,
        isSaving: true,
      }
    case UPDATE_COUNTRY_CATALOG_CODE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        map: {
          ...state.map,
          [action.businessId]: {
            ...state.map[action.businessId],
            countryCatalogCode: action.code,
          },
        },
      }
    case UPDATE_COUNTRY_CATALOG_CODE_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_BUSINESS_SOAP_WIDGETS:
      return {
        ...state,
        isFetchingBusinessSoapWidgets: true,
        error: null,
      }
    case FETCH_BUSINESS_SOAP_WIDGETS_SUCCESS:
      return {
        ...state,
        isFetchingBusinessSoapWidgets: false,
        soapWidgets: action.soapWidgets.map((widget: SoapWidget) => ({
          id: `${widget.soapWidgetId}_${widget.marketplaceId}`,
          ...widget,
        })),
      }
    case FETCH_BUSINESS_SOAP_WIDGETS_FAILURE:
      return {
        ...state,
        isFetchingBusinessSoapWidgets: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_BUSINESS_SSO_IDP_SETTINGS:
    case UPDATE_BUSINESS_SSO_IDP_SETTINGS:
    case DELETE_BUSINESS_SSO_IDP_SETTINGS:
    case FETCH_BUSINESS_SSO_IDP_SETTINGS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case CREATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS:
    case UPDATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS:
    case FETCH_BUSINESS_SSO_IDP_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE:
    case UPDATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE:
    case DELETE_BUSINESS_SSO_IDP_SETTINGS_FAILURE:
    case FETCH_BUSINESS_SSO_IDP_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.businessId]: R.omit(
            ['ssoIdpSettings'],
            state.map[action.businessId],
          ),
        },
      }
    default:
      return state
  }
}

export default businesses
export const getBusinesses = (state: RootState): BusinessesState =>
  state.businesses
export const getBusinessesMap = (state: RootState) => getBusinesses(state).map
export const getBusinessesList = (state: RootState) => getBusinesses(state).list
export const getLocationsList = (state: RootState) =>
  getBusinesses(state).locationList
export const getSuggestionsList = (state: RootState) =>
  getBusinesses(state).suggestionList
export const getBusinessFavoriteList = (state: RootState) =>
  getBusinesses(state).favoriteList
export const getBusiness = (businessId: string | Nil) =>
  createSelector(getBusinessesMap, (map) =>
    businessId ? map[businessId] : undefined,
  )

export const getMultipleBusinesses = (ids: string[]) =>
  createSelector(getBusinessesMap, (map) =>
    R.props(ids || [], map).filter(Boolean),
  )

export const getMultipleBusinessMap = (ids: string[]) =>
  createSelector(getBusinessesMap, (map) => R.pick(ids, map))

export const getBusinessIsLoading = (state: RootState) =>
  getBusinesses(state).isLoading
export const getBusinessIsFetching = (state: RootState) =>
  getBusinesses(state).isFetching
export const getBusinessIsSaving = (state: RootState) =>
  getBusinesses(state).isSaving
export const getBusinessIsLocationsLoading = (state: RootState) =>
  getBusinesses(state).isLocationsLoading
export const getBusinessIsSuggestionsLoading = (state: RootState) =>
  getBusinesses(state).isSuggestionsLoading
export const getBusinessIsFetchingList = (state: RootState) =>
  getBusinesses(state).isFetchingList
export const getBusinessError = (state: RootState) => getBusinesses(state).error
export const getLockWorkingHoursSettings = (state: RootState) =>
  getBusinesses(state).lockWorkingHoursSettings
export const getBusinessesTotalCount = (state: RootState) =>
  getBusinesses(state).totalCount
export const getLocationsTotalCount = (state: RootState) =>
  getBusinesses(state).locationTotalCount
export const getSuggestionsTotalCount = (state: RootState) =>
  getBusinesses(state).suggestionTotalCount
export const getAssignedPracticesList = (state: RootState) =>
  getBusinesses(state).assignedPracticesList
export const getBusinessSupportedLanguageCodes = (businessId?: string) =>
  createSelector(
    getBusiness(businessId),
    (business) => R.prop('supportedLanguageCodes', business) || [],
  )
export const getBusinessCountryCatalogCode = (businessId?: string) =>
  createSelector(getBusiness(businessId), (business) =>
    R.prop('countryCatalogCode', business),
  )

export const getBusinessIsFetchingBusinessSoapWidgets = (state: RootState) =>
  getBusinesses(state).isFetchingBusinessSoapWidgets
export const getBusinessSoapWidgets = (state: RootState) =>
  getBusinesses(state).soapWidgets
