import React from 'react'
import { Text } from '@pbt/pbt-ui-components'

import { MembershipPlan } from '~/types'

interface TierCellProps {
  plan: MembershipPlan
}
const TierCell = ({ plan }: TierCellProps) => (
  <Text variant="body2">{plan.tier || null}</Text>
)

export default TierCell
