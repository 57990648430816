import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { includes } from 'ramda'

import {
  fetchWhiteboard,
  fetchWhiteboardTreatments,
} from '~/store/actions/whiteboard'
import {
  getWhiteboardColumns,
  getWhiteboardTeamSchedules,
} from '~/store/reducers/whiteboard'
import { getConstantFromMapOrDefault } from '~/utils'

import { TimetableContextProvider } from '../TimetableContext'
import TimetablePage from '../TimetablePage'
import WhiteboardDefaultView from './views/WhiteboardDefaultView'
import WhiteboardTeamMemberView from './views/WhiteboardTeamMemberView'
import WhiteboardTreatmentsView from './views/WhiteboardTreatmentsView'
import WhiteboardTypeView from './views/WhiteboardTypeView'
import WhiteboardHeader from './WhiteboardHeader'
import WhiteboardTreatmentsHeader from './WhiteboardTreatmentsHeader'
import { WhiteboardRailOnTopViews, WhiteboardViewType } from './whiteboardViews'

const ActionMap = {
  [WhiteboardViewType.DEFAULT]: fetchWhiteboard,
  [WhiteboardViewType.TEAM_MEMBER]: fetchWhiteboard,
  [WhiteboardViewType.TYPE]: fetchWhiteboard,
  [WhiteboardViewType.TREATMENTS]: fetchWhiteboardTreatments,
}

const ComponentsMap = {
  [WhiteboardViewType.DEFAULT]: WhiteboardDefaultView,
  [WhiteboardViewType.TEAM_MEMBER]: WhiteboardTeamMemberView,
  [WhiteboardViewType.TYPE]: WhiteboardTypeView,
  [WhiteboardViewType.TREATMENTS]: WhiteboardTreatmentsView,
}

const HeaderComponentsMap = {
  [WhiteboardViewType.DEFAULT]: WhiteboardHeader,
  [WhiteboardViewType.TEAM_MEMBER]: WhiteboardHeader,
  [WhiteboardViewType.TYPE]: WhiteboardHeader,
  [WhiteboardViewType.TREATMENTS]: WhiteboardTreatmentsHeader,
}

const WhiteboardPage = (props: any) => {
  const { view: viewProp = '' } = useParams()
  const view = getConstantFromMapOrDefault(
    viewProp,
    WhiteboardViewType,
    WhiteboardViewType.DEFAULT,
  )
  const whiteboardColumns = useSelector(getWhiteboardColumns)

  const [openCardId, setOpenCardId] = useState<string>()

  const columns = whiteboardColumns.map((name: string) => ({ name }))

  const Component = ComponentsMap[view]
  const HeaderComponent = HeaderComponentsMap[view]

  const fetchTimetablesAction = ActionMap[view]

  const onCardClick = useCallback((id: string) => {
    setOpenCardId(id)
  }, [])

  const onCardClose = useCallback(() => {
    setOpenCardId(undefined)
  }, [])

  return (
    <TimetableContextProvider>
      <TimetablePage
        fetchTimetablesAction={fetchTimetablesAction}
        railOnTop={includes(view, WhiteboardRailOnTopViews)}
        schedulesSelector={getWhiteboardTeamSchedules}
        {...props}
      >
        <Component
          HeaderComponent={HeaderComponent}
          columns={columns}
          openCardId={openCardId}
          selectedView={view}
          onCardClick={onCardClick}
          onCardClose={onCardClose}
        />
      </TimetablePage>
    </TimetableContextProvider>
  )
}

export default WhiteboardPage
