import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { updateAppointmentStatus } from '~/store/actions/timetable'
import { getEventType } from '~/store/reducers/constants'
import { getSchedulerBoardingSchedules } from '~/store/reducers/scheduler'
import { getTimetableEventsMap } from '~/store/reducers/timetable'
import { ScheduleSlot, TimetableEvent } from '~/types'

import { getTodayAppointmentsByType } from '../../../timetableUtils'
import BoardingCheckIn from './BoardingCheckIn'

const BoardingCheckInToday = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const schedules = useSelector(getSchedulerBoardingSchedules)
  const appointmentsMap = useSelector(getTimetableEventsMap)
  const EventType = useSelector(getEventType)

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const AppointmentStates = AppointmentEvent.states || []
  const ArrivedStatus =
    Utils.findConstantByName('Arrived', AppointmentStates) || {}
  const ScheduledStatusId = Utils.findConstantIdByName(
    'Scheduled',
    AppointmentStates,
  )
  const ConfirmedStatusId = Utils.findConstantIdByName(
    'Confirmed',
    AppointmentStates,
  )

  const appointments = getTodayAppointmentsByType(
    schedules,
    appointmentsMap,
    [ScheduledStatusId, ConfirmedStatusId],
    (slot: ScheduleSlot) => slot.interval.from,
  )

  const checkIn = (appointment: TimetableEvent) => {
    const newAppointment = {
      ...appointment,
      state: {
        ...appointment.state,
        id: ArrivedStatus.id,
        name: ArrivedStatus.name,
      },
    }

    dispatch(updateAppointmentStatus(newAppointment))
  }

  return (
    <BoardingCheckIn
      checkingIn
      appointments={appointments}
      buttonLabel={t('Common:CHECK-IN_ACTION')}
      onClick={checkIn}
    />
  )
}

export default BoardingCheckInToday
