import { Addendum } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const createAddendum = (
  businessId: string,
  soapId: string,
  patientId: string,
  addendum: Partial<Addendum>,
) =>
  request(
    getAPIPath(`soaps/${soapId}/patient/${patientId}/addendum`),
    { method: 'POST', data: addendum, params: { businessId } },
    true,
    Schema.addendum,
  )

export const editAddendum = (
  businessId: string,
  soapId: string,
  patientId: string,
  addendum: Addendum,
) =>
  request(
    getAPIPath(`soaps/${soapId}/patient/${patientId}/addendum/${addendum.id}`),
    { method: 'PUT', data: addendum, params: { businessId } },
    true,
    Schema.addendum,
  )

export const deleteAddendum = (
  businessId: string,
  soapId: string,
  patientId: string,
  addendumId: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/patient/${patientId}/addendum/${addendumId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )
