import { Nil } from '@pbt/pbt-ui-components'

import { MedicalHistoryCardConfig } from '~/types'

import { getAPIPath, request } from './core'

export const loadMedicalHistoryCardConfig = (
  businessId: string,
  patientId: string,
  from: string | Nil,
  to: string | Nil,
) =>
  request(
    getAPIPath('medicalhistory/config'),
    { method: 'GET', params: { businessId, patientId, from, to } },
    true,
  )

export const loadMedicalHistoryCardPreviewTemplate = (
  businessId: string,
  patientId: string,
  config: MedicalHistoryCardConfig,
  from: string | Nil,
  to: string | Nil,
) =>
  request(
    getAPIPath('medicalhistory/preview'),
    {
      method: 'POST',
      data: config,
      params: { businessId, patientId, from, to },
    },
    true,
  )
