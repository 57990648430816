import React from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  DocumentFile,
  PuiTextField,
  Utils,
} from '@pbt/pbt-ui-components'
import { Attachment } from '@pbt/pbt-ui-components/src/icons'

import PuiIconButton from '../PuiIconButton'
import RichEditAttachments, {
  RichEditAttachmentsProps,
} from './rich-edit/RichEditAttachments'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    button: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderRadius: 0,
    },
    icon: {
      color: theme.colors.disabledLabelText,
      '&:hover': {
        color: theme.colors.secondaryText,
      },
    },
    panel: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    stickyControlPanel: {
      position: 'sticky',
      top: 0,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
    plainTextField: {
      margin: 0,
    },
    plainTextFieldInput: {
      lineHeight: '1.5 !important',
      maxHeight: (props: UseStylesProps) => props.maxEditorHeight,
      minHeight: (props: UseStylesProps) => props.minEditorHeight,
      alignItems: 'flex-start',
      overflow: 'auto !important',
      '&:before, &:after': {
        border: 'none !important',
      },
    },
  }),
  { name: 'TextFieldWithOptions' },
)

export interface TextFieldWithOptionsProps extends Omit<GridProps, 'onChange'> {
  classes?: ClassesType<typeof useStyles>
  files?: DocumentFile['file'][]
  hidePanel?: boolean
  maxEditorHeight?: number
  maxLength?: number
  minEditorHeight?: number
  onAttachClick?: () => void
  onChange: (value: string) => void
  onDeleteFile?: RichEditAttachmentsProps['onDeleteFile']
  plainTextValue: string
  stickyControlPanel?: boolean
}

export interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
  maxEditorHeight?: number
  minEditorHeight?: number
}

const TextFieldWithOptions = ({
  plainTextValue,
  hidePanel = true,
  stickyControlPanel = true,
  onChange,
  maxEditorHeight,
  minEditorHeight,
  onAttachClick,
  onDeleteFile,
  maxLength,
  files,
  classes: classesProp,
  ...rest
}: TextFieldWithOptionsProps) => {
  const useStylesProps: UseStylesProps = {
    classes: classesProp,
    maxEditorHeight,
    minEditorHeight,
  }
  const classes = useStyles(useStylesProps)

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      wrap="nowrap"
      {...rest}
    >
      {!hidePanel && (
        <Grid
          className={classNames(classes.panel, {
            [classes.stickyControlPanel]: stickyControlPanel,
          })}
        >
          <PuiIconButton
            Icon={Attachment}
            className={classes.button}
            classes={{ iconButtonSvg: classes.icon }}
            onMouseDown={(event) => {
              event.preventDefault()
              if (onAttachClick) {
                onAttachClick()
              }
            }}
          />
        </Grid>
      )}
      <PuiTextField
        multiline
        className={classes.plainTextField}
        inputProps={{ className: classes.plainTextFieldInput, maxLength }}
        value={plainTextValue}
        onChange={Utils.handleFormTextInput(onChange)}
      />
      {files && (
        <RichEditAttachments files={files} onDeleteFile={onDeleteFile} />
      )}
    </Grid>
  )
}

export default TextFieldWithOptions
