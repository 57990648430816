export const CREATE_SOAP = 'soap/CREATE_SOAP'
export const CREATE_SOAP_FAILURE = 'soap/CREATE_SOAP_FAILURE'
export const CREATE_SOAP_SUCCESS = 'soap/CREATE_SOAP_SUCCESS'

export const ADD_SOAP = 'soap/ADD_SOAP'
export const ADD_SOAP_FAILURE = 'soap/ADD_SOAP_FAILURE'
export const ADD_SOAP_SUCCESS = 'soap/ADD_SOAP_SUCCESS'

export const FETCH_SOAP = 'soap/FETCH_SOAP'
export const FETCH_SOAP_FAILURE = 'soap/FETCH_SOAP_FAILURE'
export const FETCH_SOAP_SUCCESS = 'soap/FETCH_SOAP_SUCCESS'

export const SELECT_DOCTOR = 'soap/SELECT_DOCTOR'
export const SELECT_DOCTOR_FOR_CUSTOM_SOAP =
  'soap/SELECT_DOCTOR_FOR_CUSTOM_SOAP'

export const SELECT_DOCTOR_LOCALLY = 'soap/SELECT_DOCTOR_LOCALLY'
export const SELECT_TECH = 'soap/SELECT_TECH'
export const SELECT_TECH_LOCALLY = 'soap/SELECT_TECH_LOCALLY'

export const ADD_SOAP_VITAL_IDS = 'soap/ADD_SOAP_VITAL_IDS'
export const ADD_V2_SOAP_VITAL_ID = 'soap/ADD_V2_SOAP_VITAL_ID'
export const UPDATE_SOAP_VITAL_IDS = 'soap/UPDATE_SOAP_VITAL_IDS'

export const EDIT_FINDINGS_STATE = 'soap/EDIT_FINDINGS_STATE'
export const EDIT_FINDINGS_STATE_SUCCESS = 'soap/EDIT_FINDINGS_STATE_SUCCESS'
export const EDIT_FINDINGS_STATE_FAILURE = 'soap/EDIT_FINDINGS_STATE_FAILURE'

export const CLEAR_FINDING_LOGS_CRC_VALIDATION_ERROR =
  'soap/CLEAR_FINDING_LOGS_CRC_VALIDATION_ERROR'
export const UPDATE_FINDING_LOGS_CRC_SUCCESS =
  'soap/UPDATE_FINDING_LOGS_CRC_SUCCESS'
export const UPDATE_FINDING_LOGS_CRC_FAILURE =
  'soap/UPDATE_FINDING_LOGS_CRC_FAILURE'

export const FETCH_SOAP_FINDINGS_CONTAINER =
  'soap/FETCH_SOAP_FINDINGS_CONTAINER'
export const FETCH_SOAP_FINDINGS_CONTAINER_SUCCESS =
  'soap/FETCH_SOAP_FINDINGS_CONTAINER_SUCCESS'
export const FETCH_SOAP_FINDINGS_CONTAINER_FAILURE =
  'soap/FETCH_SOAP_FINDINGS_CONTAINER_FAILURE'

export const UPDATE_FINDING_TO_EXPAND = 'soap/UPDATE_FINDING_TO_EXPAND'

export const EDIT_DIFFERENTIAL_DIAGNOSES_STATE =
  'soap/EDIT_DIFFERENTIAL_DIAGNOSES_STATE'
export const EDIT_DIFFERENTIAL_DIAGNOSES_STATE_SUCCESS =
  'soap/EDIT_DIFFERENTIAL_DIAGNOSES_STATE_SUCCESS'
export const EDIT_DIFFERENTIAL_DIAGNOSES_STATE_FAILURE =
  'soap/EDIT_DIFFERENTIAL_DIAGNOSES_STATE_FAILURE'

export const UPDATE_MEDICAL_NOTES = 'soap/UPDATE_MEDICAL_NOTES'
export const UPDATE_MEDICAL_NOTES_COMPLETE =
  'soap/UPDATE_MEDICAL_NOTES_COMPLETE'

export const UPDATE_DISCHARGE_NOTES = 'soap/UPDATE_DISCHARGE_NOTES'
export const UPDATE_DISCHARGE_NOTES_COMPLETE =
  'soap/UPDATE_DISCHARGE_NOTES_COMPLETE'

export const CLOSE_SOAP = 'soap/CLOSE_SOAP'

export const SAVE_SOAP = 'soap/SAVE_SOAP'
export const SAVE_SOAP_FAILURE = 'soap/SAVE_SOAP_FAILURE'
export const SAVE_SOAP_SUCCESS = 'soap/SAVE_SOAP_SUCCESS'
export const SAVE_SOAP_ABOLISH = 'soap/SAVE_SOAP_ABOLISH'

export const SOAP_PARTIAL_UPDATE = 'soap/SOAP_PARTIAL_UPDATE'

export const UPLOAD_FILE = 'soap/UPLOAD_FILE'
export const UPLOAD_FILE_SUCCESS = 'soap/UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'soap/UPLOAD_FILE_FAILURE'

export const DELETE_FILE = 'soap/DELETE_FILE'
export const DELETE_FILE_SUCCESS = 'soap/DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'soap/DELETE_FILE_FAILURE'

export const UPDATE_INVOICE_ID = 'soap/UPDATE_INVOICE_ID'
export const UPDATE_SOAP = 'soap/UPDATE_SOAP'
export const UPDATE_SOAP_FILES = 'soap/UPDATE_SOAP_FILES'

export const FETCH_SOAP_ORDERS = 'soap/FETCH_SOAP_ORDERS'
export const FETCH_SOAP_ORDERS_SUCCESS = 'soap/FETCH_SOAP_ORDERS_SUCCESS'
export const FETCH_SOAP_ORDERS_FAILURE = 'soap/FETCH_SOAP_ORDERS_FAILURE'

export const FETCH_SOAP_ORDER_FILTERS = 'soap/FETCH_SOAP_ORDER_FILTERS'
export const FETCH_SOAP_ORDER_FILTERS_SUCCESS =
  'soap/FETCH_SOAP_ORDER_FILTERS_SUCCESS'
export const FETCH_SOAP_ORDER_FILTERS_FAILURE =
  'soap/FETCH_SOAP_ORDER_FILTERS_FAILURE'

export const ATTACH_DOCUMENTS_TO_SOAP = 'soap/ATTACH_DOCUMENTS_TO_SOAP'
export const ATTACH_DOCUMENTS_TO_SOAP_FAILURE =
  'soap/ATTACH_DOCUMENTS_TO_SOAP_FAILURE'
export const ATTACH_DOCUMENTS_TO_SOAP_SUCCESS =
  'soap/ATTACH_DOCUMENTS_TO_SOAP_SUCCESS'

export const UPDATE_DOCUMENT_ON_SOAP = 'soap/UPDATE_DOCUMENT_ON_SOAP'
export const UPDATE_DOCUMENT_ON_SOAP_FAILURE =
  'soap/UPDATE_DOCUMENT_ON_SOAP_FAILURE'
export const UPDATE_DOCUMENT_ON_SOAP_SUCCESS =
  'soap/UPDATE_DOCUMENT_ON_SOAP_SUCCESS'

export const SET_SOAP_ALERT = 'soap/SET_SOAP_ALERT'

export const NOTES_VALIDATION_ERROR = 'soap/NOTES_VALIDATION_ERROR'
export const CLEAR_NOTES_VALIDATION_ERROR = 'soap/CLEAR_NOTES_VALIDATION_ERROR'

export const ASSIGNED_DOCTOR_VALIDATION_ERROR =
  'soap/ASSIGNED_DOCTOR_VALIDATION_ERROR'
export const CLEAR_ASSIGNED_DOCTOR_VALIDATION_ERROR =
  'soap/CLEAR_ASSIGNED_DOCTOR_VALIDATION_ERROR'

export const FETCH_SOAP_FILES = 'soap/FETCH_SOAP_FILES'
export const FETCH_SOAP_FILES_SUCCESS = 'soap/FETCH_SOAP_FILES_SUCCESS'
export const FETCH_SOAP_FILES_FAILURE = 'soap/FETCH_SOAP_FILES_FAILURE'

export const NAVIGATE_TO_SOAP_CONVERSATION =
  'soap/NAVIGATE_TO_SOAP_CONVERSATION'
export const NAVIGATE_TO_SOAP_CONVERSATIONS_LIST =
  'soap/NAVIGATE_TO_SOAP_CONVERSATIONS_LIST'

export const EDIT_SOAP_FINALIZED_STATUS = 'soap/EDIT_SOAP_FINALIZED_STATUS'
export const UPDATE_SOAP_FINALIZED_STATUS = 'soap/UPDATE_SOAP_FINALIZED_STATUS'

export const CLEAR_DOCUMENT_VALIDATION_ERROR =
  'soap/CLEAR_DOCUMENT_VALIDATION_ERROR'

export const FETCH_USERS_ON_SOAP_COLLABORATION_SESSION =
  'soap/FETCH_USERS_ON_SOAP_COLLABORATION_SESSION'
export const FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_SUCCESS =
  'soap/FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_SUCCESS'
export const FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_FAILURE =
  'soap/FETCH_USERS_ON_SOAP_COLLABORATION_SESSION_FAILURE'

export const SET_MEDICAL_NOTES = 'soap/SET_MEDICAL_NOTES'
export const SET_DISCHARGE_NOTES = 'soap/SET_DISCHARGE_NOTES'

export const CLEAR_SOAP_ERROR = 'soap/CLEAR_SOAP_ERROR'

export const FETCH_SOAP_ACTIVE_TEMPLATE = 'soap/FETCH_SOAP_ACTIVE_TEMPLATE'
export const FETCH_SOAP_ACTIVE_TEMPLATE_SUCCESS =
  'soap/FETCH_SOAP_ACTIVE_TEMPLATE_SUCCESS'
export const FETCH_SOAP_ACTIVE_TEMPLATE_FAILURE =
  'soap/FETCH_SOAP_ACTIVE_TEMPLATE_FAILURE'

export const FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS =
  'soap/FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS'
export const FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS_SUCCESS =
  'soap/FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS_SUCCESS'
export const FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS_FAILURE =
  'soap/FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS_FAILURE'

export const RESET_SOAP_TEMPLATE_TO_DEFAULT =
  'soap/RESET_SOAP_TEMPLATE_TO_DEFAULT'
export const RESET_SOAP_TEMPLATE_TO_DEFAULT_SUCCESS =
  'soap/RESET_SOAP_TEMPLATE_TO_DEFAULT_SUCCESS'
export const RESET_SOAP_TEMPLATE_TO_DEFAULT_FAILURE =
  'soap/RESET_SOAP_TEMPLATE_TO_DEFAULT_FAILURE'

export const UPDATE_SOAP_TEMPLATE = 'soap/UPDATE_SOAP_TEMPLATE'
export const UPDATE_SOAP_TEMPLATE_SUCCESS = 'soap/UPDATE_SOAP_TEMPLATE_SUCCESS'
export const UPDATE_SOAP_TEMPLATE_FAILURE = 'soap/UPDATE_SOAP_TEMPLATE_FAILURE'
