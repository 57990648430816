import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CustomFieldValidatorState,
  ErrorTooltip,
  PuiCheckbox,
  PuiDialog,
  Text,
  useFields,
  Utils,
  WellnessPlan,
  WellnessPlanBenefit,
} from '@pbt/pbt-ui-components'

import { fetchWellnessPlanGlobalBenefitGroups } from '~/store/actions/wellnessPlans'
import { useGetPlanRequiredBenefitsCount } from '~/store/hooks/wellnessPlans'
import {
  getWellnessPlanGlobalBenefitGroups,
  getWellnessPlansIsFetching,
} from '~/store/reducers/wellnessPlans'

import {
  getBenefitIsAccessToBoop,
  getBenefitUniqueId,
  globalBenefitToSelected,
} from '../../wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    actions: {
      padding: theme.spacing(1, 3),
    },
    button: {
      width: 150,
    },
    row: {
      cursor: 'pointer',
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    progress: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      alignSelf: 'center',
    },
    benefitGroupContainer: {
      borderBottom: theme.constants.tabBorder,
      '&:not(:first-of-type)': {
        borderTop: theme.constants.tabBorder,
      },
    },
  }),
  { name: 'WellnessPlanBenefitsDialog' },
)

interface WellnessPlanBenefitsDialogProps extends BasePuiDialogProps {
  onSelected: (benefits: WellnessPlanBenefit[]) => void
  plan: WellnessPlan
}

const WellnessPlanBenefitsDialog = ({
  plan,
  onSelected,
  open,
  onClose,
}: WellnessPlanBenefitsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Plurals', 'WellnessPlans'])

  const isFetching = useSelector(getWellnessPlansIsFetching)
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)

  const requiredBenefitCount = useGetPlanRequiredBenefitsCount(plan)

  const templateBenefitsByGroup = R.groupBy(
    R.prop('groupId'),
    plan.benefits || [],
  )
  const filteredGroups = globalBenefitGroups
    .map((group) => ({
      ...group,
      benefits: R.pipe(
        R.concat(group.benefits || []),
        R.uniqBy(getBenefitUniqueId),
        R.filter<WellnessPlanBenefit>((benefit) => {
          const hasMatchingLevel =
            R.isNil(plan.level) || plan.level >= benefit.level
          const isAccessToBoop = getBenefitIsAccessToBoop(benefit)

          return hasMatchingLevel && !isAccessToBoop
        }),
      )(templateBenefitsByGroup[group.id] || []),
    }))
    .filter((group) => group.benefits.length > 0)

  const benefitCount = R.flatten(R.pluck('benefits', filteredGroups)).length
  const shouldDisableCountValidation = benefitCount < requiredBenefitCount

  const validateBenefits = ({
    state: { selectedBenefitIds },
  }: CustomFieldValidatorState) =>
    shouldDisableCountValidation ||
    selectedBenefitIds.length >= requiredBenefitCount

  const {
    fields: { selectedBenefitIds },
    validate,
  } = useFields(
    [
      {
        name: 'selectedBenefitIds',
        initialValue: R.pluck('globalBenefitId', plan.benefits || []),
        validators: [
          { validator: validateBenefits, validatorName: 'validBenefits' },
        ],
      },
    ],
    false,
  )

  const proceed = () => {
    if (validate()) {
      const benefits = selectedBenefitIds.value.map((id: string) => {
        const foundGroup = globalBenefitGroups.find((group) =>
          Utils.findById(id, group.benefits),
        )
        return foundGroup
          ? // we have found group, need to convert globalBenefit to selected one
            globalBenefitToSelected(
              Utils.findById(id, foundGroup.benefits),
              plan.level,
            )
          : // no group most likely means that this benefit was deprecated
            // thus look for it in plan benefits instead
            plan.benefits.find(({ globalBenefitId }) => globalBenefitId === id)
      })

      onSelected(benefits)
      if (onClose) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open && globalBenefitGroups.length === 0) {
      dispatch(fetchWellnessPlanGlobalBenefitGroups())
    }
  }, [open])

  const missingBenefitCount =
    requiredBenefitCount - selectedBenefitIds.value.length
  const dialogTitle = `${t('WellnessPlans:EDIT_PLAN_BENEFITS', {
    planName: plan.name,
  })} ${
    shouldDisableCountValidation
      ? ''
      : `(${t('Common:CHOOSE_AT_LEAST_NUMBER', {
          number: requiredBenefitCount,
        }).toLowerCase()})`
  }`

  return (
    <PuiDialog
      actions={
        <ErrorTooltip
          message={t(
            'Plurals:Z_ICU_WORKAROUND.PLEASE_SELECT_NUMBER_MORE_BENEFITS',
            { number: missingBenefitCount },
          )}
          open={selectedBenefitIds.open}
          validateTag={selectedBenefitIds.validateTag}
        >
          <ButtonWithLoader className={classes.button} onClick={proceed}>
            {t('Common:ADD_ACTION')}
          </ButtonWithLoader>
        </ErrorTooltip>
      }
      aria-labelledby="wellness-plan-benefit-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      open={open}
      title={dialogTitle}
      onClose={onClose}
    >
      <Grid container item direction="column">
        {isFetching ? (
          <CircularProgress className={classes.progress} />
        ) : (
          filteredGroups.map((group) => (
            <React.Fragment key={group.id}>
              <Grid
                item
                className={classes.benefitGroupContainer}
                px={3}
                py={0.75}
              >
                <Text strong variant="subheading3">
                  {group.name}
                </Text>
              </Grid>
              <Grid container item direction="column">
                {group.benefits.map((benefit) => {
                  const benefitId = getBenefitUniqueId(benefit)
                  const isChecked = selectedBenefitIds.value.includes(benefitId)
                  const disabled =
                    Boolean(benefit.globalBenefitId) && !isChecked
                  return (
                    <Grid
                      container
                      item
                      className={classes.row}
                      key={benefitId}
                      px={4}
                      onClick={(event) => {
                        event.preventDefault()
                        if (!disabled) {
                          selectedBenefitIds.setValue(
                            Utils.toggleListItem(
                              benefitId,
                              selectedBenefitIds.value,
                            ),
                          )
                        }
                      }}
                    >
                      <PuiCheckbox
                        checked={isChecked}
                        disabled={disabled}
                        label={benefit.name}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </React.Fragment>
          ))
        )}
      </Grid>
    </PuiDialog>
  )
}

export default WellnessPlanBenefitsDialog
