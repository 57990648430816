import React from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

import { splitDateAndTime } from '~/utils/time'
import { useCurrentBusinessTimezone } from '~/utils/useCurrentBusinessTimezone'

const useStyles = makeStyles(
  () => ({
    root: {
      width: 'auto',
    },
    date: {
      fontSize: '1.4rem',
    },
    time: {
      fontSize: '1rem',
      lineHeight: '1em',
    },
  }),
  { name: 'DateTimeLabel' },
)

export interface DateTimeLabelProps {
  classes?: ClassesType<typeof useStyles>
  dateTime: string
  direction: GridProps['direction']
}

const DateTimeLabel = ({
  classes: classesProp,
  dateTime,
  direction = 'row',
}: DateTimeLabelProps) => {
  const classes = useStyles({ classes: classesProp })
  const timezone = useCurrentBusinessTimezone()
  const { date, time } = splitDateAndTime(dateTime, timezone)

  return (
    <Grid
      container
      alignItems={direction === 'row' ? 'center' : 'flex-start'}
      className={classes.root}
      direction={direction}
    >
      <span className={classes.date}>{date}&nbsp;</span>
      <span className={classes.time}>{time}</span>
    </Grid>
  )
}

export default DateTimeLabel
