import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { InfiniteLoaderList, Text, Utils } from '@pbt/pbt-ui-components'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Eye as EyeIcon,
} from '@pbt/pbt-ui-components/src/icons'

import ActionsPopper from '~/components/common/ActionsPopper'
import DialogNames from '~/constants/DialogNames'
import {
  deleteReminder,
  editReminder,
  getIsFetchingList,
  getMultipleReminders,
} from '~/store/duck/reminders'
import { getEventState } from '~/store/reducers/constants'
import { getPatientName } from '~/store/reducers/patients'
import { Reminder } from '~/types'
import useDialog from '~/utils/useDialog'

import PatientRemindersListItem from './PatientRemindersListItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      height: 160,
      overflowY: 'auto',
      '& > *': {
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      backgroundColor: theme.colors.dialogHeaderBackground,
    },
    evenItem: {
      backgroundColor: theme.colors.divider,
    },
  }),
  { name: 'PatientRemindersList' },
)

export interface PatientRemindersListProps {
  list: string[]
  loadMoreItems: (from: number, to: number) => void
  patientId: string
  totalCount: number
}

const PatientRemindersList = ({
  patientId,
  list,
  totalCount,
  loadMoreItems,
}: PatientRemindersListProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isFetchingList = useSelector(getIsFetchingList)
  const patientName = useSelector(getPatientName(patientId))
  const reminders = useSelector(getMultipleReminders(list))
  const EventState = useSelector(getEventState)
  const { t } = useTranslation(['Common', 'Reminders'])

  const ResolvedStateId = Utils.findConstantIdByName('Resolved', EventState)
  const OpenStateId = Utils.findConstantIdByName('Open', EventState)

  const [openReminderDetailsDialog] = useDialog(DialogNames.REMINDER_DETAILS)

  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLElement | null>(
    null,
  )
  const [activeActionItem, setActiveActionItem] = useState<Reminder>()

  const handleView = (reminder: Reminder) => {
    openReminderDetailsDialog({ reminderId: reminder.id })
  }

  const handleUpdateState = (newReminder: Reminder) => {
    dispatch(editReminder(newReminder))
  }

  const handleDelete = (reminder: Reminder) => {
    dispatch(deleteReminder(reminder.id))
  }

  const getActions = (reminder: Reminder) => {
    const isResolved = reminder.state?.id === ResolvedStateId
    return [
      {
        id: 'view',
        label: t('Common:VIEW_DETAILS'),
        Icon: EyeIcon,
        onClick: () => handleView(reminder),
      },
      {
        id: 'markAsResolved',
        label: isResolved
          ? t('Common:MARK_AS_OPEN')
          : t('Common:MARK_AS_RESOLVED'),
        Icon: CheckIcon,
        onClick: () =>
          handleUpdateState({
            ...reminder,
            state: isResolved ? OpenStateId : ResolvedStateId,
          }),
      },
      {
        id: 'delete',
        label: t('Common:DELETE_REMINDER'),
        Icon: DeleteIcon,
        onClick: () => handleDelete(reminder),
      },
    ]
  }

  const handleToggleActions = (event: React.MouseEvent, item?: Reminder) => {
    const targetButton = (event.target as HTMLElement).closest('button')
    setActionsAnchorEl(actionsAnchorEl ? null : targetButton)
    setActiveActionItem(activeActionItem ? undefined : item)
  }

  const isItemLoaded = (index: number) => Boolean(reminders[index])

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      {!isFetchingList && list.length === 0 && (
        <Text m={2} variant="body2">
          {t('Reminders:REMINDERS_LIST.PATIENT_HAS_NO_REMINDERS', {
            patientName,
          })}
        </Text>
      )}
      <InfiniteLoaderList
        isItemLoaded={isItemLoaded}
        itemCount={totalCount}
        itemData={list}
        itemSpacing={0}
        loadMoreItems={loadMoreItems}
      >
        {(itemId, index) => (
          <PatientRemindersListItem
            className={classNames({
              [classes.evenItem]: index % 2,
            })}
            handleToggleActions={handleToggleActions}
            itemId={itemId}
          />
        )}
      </InfiniteLoaderList>
      <ActionsPopper
        actions={activeActionItem ? getActions(activeActionItem) : []}
        anchorEl={actionsAnchorEl}
        disablePortal={false}
        onClose={() => {
          setActionsAnchorEl(null)
          setActiveActionItem(undefined)
        }}
      />
    </Grid>
  )
}

export default PatientRemindersList
