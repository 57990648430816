import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AppointmentEventType,
  FilterButton,
  NamedEntity,
  PuiCheckbox,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { TimetableFilter } from '~/types'

import { getFilterGroupsBySelectedIds } from '../timetableUtils'

const useStyles = makeStyles(
  (theme) => ({
    filterContainer: {
      padding: theme.spacing(0, 3, 2, 2),
      borderBottom: theme.constants.tabBorder,
    },
    selectorContainer: {
      borderBottom: theme.constants.tabBorder,
    },
    filter: {
      marginTop: theme.spacing(2),
    },
    resetButton: {
      cursor: 'pointer',
    },
    switchLabel: {
      fontWeight: 500,
    },
    inactive: {
      opacity: 0.5,
    },
  }),
  { name: 'TimetableFilterTab' },
)

const getFilteredDataIds = (
  data: AppointmentEventType['subTypes'] | User[],
  filterArray: string[],
) =>
  R.pipe<(typeof data)[], string[], string[]>(
    R.pluck('id'),
    R.without(filterArray),
  )(data)

export interface TimetableFilterTabProps {
  children: (arg: any) => React.ReactNode
  data?: AppointmentEventType['subTypes'] | User[]
  filters: TimetableFilter<NamedEntity>[]
  hasFilterTypes?: boolean
  onFilteredDataChange: (arg: string[]) => void
  onFilteredGroupChange: (arg: string[]) => void
  selectedFilters: string[]
  selectedItems: string[]
}

const TimetableFilterTab = ({
  filters,
  data: dataProp,
  selectedItems,
  selectedFilters,
  children,
  hasFilterTypes = false,
  onFilteredDataChange = R.F,
  onFilteredGroupChange = R.F,
}: TimetableFilterTabProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const data = dataProp || []
  const updateSelectedItems = (newSelectedItems: string[]) => {
    if (!R.equals(newSelectedItems, selectedItems)) {
      onFilteredDataChange(getFilteredDataIds(data, newSelectedItems))
    }
  }

  const getDataWithOrLogic = (appliedFilters: TimetableFilter<NamedEntity>[]) =>
    (data as any[]).filter((item) =>
      appliedFilters.every((filter) =>
        filter.selected ? filter.matcher(item) : true,
      ),
    )

  const getDataWithRadioBehaviour = (
    appliedFilters: TimetableFilter<NamedEntity>[],
  ) =>
    (data as any[]).filter((item) =>
      appliedFilters.some((filter) =>
        filter.selected ? filter.matcher(item) : false,
      ),
    )

  const getFilteredData = (appliedFilters: TimetableFilter<NamedEntity>[]) => {
    if (!data) {
      return []
    }

    const hasAppliedFilters = appliedFilters.find(({ selected }) => selected)

    return hasAppliedFilters
      ? hasFilterTypes
        ? getDataWithOrLogic(appliedFilters)
        : getDataWithRadioBehaviour(appliedFilters)
      : data
  }

  const appliedFilters = getFilterGroupsBySelectedIds(filters, selectedFilters)
  const filteredData = getFilteredData(appliedFilters)
  const filteredItems = R.pluck('id', filteredData)

  const isSelectedAll =
    filteredData.length === selectedItems.length ||
    data.length === selectedItems.length

  const handleReset = () => {
    updateSelectedItems(filteredItems)
  }

  const getAppliedFiltersWithRadioBehaviour = (
    filter: TimetableFilter<NamedEntity>,
    wasSelected: boolean | undefined,
  ) => {
    const index = appliedFilters.indexOf(filter)
    const newFilter = { ...filter, selected: !wasSelected }
    return R.update(index, newFilter, appliedFilters)
  }

  const getAppliedFilterWithOrLogic = (
    filter: TimetableFilter<NamedEntity>,
    wasSelected: boolean | undefined,
  ) =>
    appliedFilters.map((appliedFilter) => ({
      ...appliedFilter,
      selected:
        appliedFilter === filter
          ? !wasSelected
          : appliedFilter.type === filter.type
            ? false
            : appliedFilter.selected,
    }))

  const onFilterClick = (filter: TimetableFilter<NamedEntity>) => {
    const wasSelected = filter.selected
    const newAppliedFilters = hasFilterTypes
      ? getAppliedFilterWithOrLogic(filter, wasSelected)
      : getAppliedFiltersWithRadioBehaviour(filter, wasSelected)

    updateSelectedItems(R.pluck('id', getFilteredData(newAppliedFilters)))
    onFilteredGroupChange(
      R.pipe<
        TimetableFilter<NamedEntity>[][],
        TimetableFilter<NamedEntity>[],
        string[]
      >(
        R.filter(R.propEq<string>('selected', true)),
        R.pluck('id'),
      )(newAppliedFilters),
    )
  }

  const onSwitchToggle = () => {
    updateSelectedItems(
      !isSelectedAll ? filteredItems : R.without(filteredItems, selectedItems),
    )
  }

  const onSelectedStateChange = (id: string) => {
    const newSelectedItems = Utils.toggleListItem(id, selectedItems)
    updateSelectedItems(newSelectedItems)
  }

  return (
    <>
      <Grid container className={classes.filterContainer}>
        {appliedFilters.map((filter) => (
          <FilterButton
            className={classes.filter}
            isSelected={filter.selected}
            key={filter.label}
            text={filter.label}
            onClick={() => onFilterClick(filter)}
          />
        ))}
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.selectorContainer}
        justifyContent="space-between"
        pl={2}
        pr={3}
      >
        <Grid item>
          <PuiSwitch
            checked={isSelectedAll}
            className={classes.switchLabel}
            label={t('Common:SELECT_ALL')}
            onChange={onSwitchToggle}
          />
        </Grid>
        <Grid item>
          <Text
            underline
            className={classes.resetButton}
            variant="lowAccent2"
            onClick={handleReset}
          >
            {t('Common:RESET')}
          </Text>
        </Grid>
      </Grid>
      <Grid container item direction="column" flex={1} pl={2} wrap="nowrap">
        {filteredData.map((item) => (
          <Grid item key={item.id}>
            <PuiCheckbox
              checked={selectedItems.includes(item.id)}
              className={
                item.deleted || item.active === false ? classes.inactive : ''
              }
              label={children(item)}
              onChange={() => onSelectedStateChange(item.id)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default TimetableFilterTab
