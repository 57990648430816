import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input, InputLabel, Link, MenuItem, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  NamedEntity,
  Utils,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import i18n from '~/locales/i18n'
import { getAgeUnits } from '~/store/reducers/constants'
import { FromToMap, StringMap } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    dropdownMenu: {
      maxHeight: 320,
    },
    input: {
      marginRight: theme.spacing(1.5),
      textOverflow: 'ellipsis',
    },
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
    selectorContainer: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      borderWidth: '0 0 1px 0',
      width: '100%',
    },
    linkButton: {
      textDecoration: 'underline',
      color: theme.colors.tabLabel,
      fontSize: '1.6rem',
    },
    activeLinkButton: {
      color: theme.colors.tabSelected,
    },
    label: {
      fontSize: '1.1rem',
      color: theme.colors.tabLabel,
    },
    placeholder: {
      opacity: 0.5,
    },
  }),
  { name: 'AgeSelect' },
)

const InventoryAgeRestrictionRange: FromToMap = {
  Week: { from: 1, to: 16 },
  Month: { from: 5, to: 12 },
  Year: { from: 1, to: 20 },
}

const PluralAgeUnitNameLabelMap: StringMap = {
  Week: i18n.t('Constants:TIME_UNITS.WEEK_OTHER'),
  Month: i18n.t('Constants:TIME_UNITS.MONTH_OTHER'),
  Year: i18n.t('Constants:TIME_UNITS.YEAR_OTHER'),
}

const SingularAgeUnitNameLabelMap: StringMap = {
  Week: i18n.t('Constants:TIME_UNITS.WEEK_ONE'),
  Month: i18n.t('Constants:TIME_UNITS.MONTH_ONE'),
  Year: i18n.t('Constants:TIME_UNITS.YEAR_OR_YEARS'),
}

const getLabel = (value: number, ageUnit: NamedEntity) =>
  value && ageUnit
    ? value === 1
      ? `${value} ${SingularAgeUnitNameLabelMap[ageUnit.name]}`
      : `${value} ${PluralAgeUnitNameLabelMap[ageUnit.name]}`
    : ''

const getItems = (AgeUnits: NamedEntity[], ageUnitId: string) => {
  const ageUnit = Utils.getConstantsValue(ageUnitId, AgeUnits)
  if (!ageUnit) {
    return []
  }
  const { from, to } = InventoryAgeRestrictionRange[ageUnit.name] || {}
  const rangeList = from && to ? R.range(from, to + 1) : []
  return rangeList.map((value) => ({
    id: value,
    name: getLabel(value, ageUnit),
  }))
}

export interface MenuHeadingProps {
  items: NamedEntity[]
  onChange: (id: string) => void
  selectedItem: string
}

const MenuHeading = ({ items, selectedItem, onChange }: MenuHeadingProps) => {
  const classes = useStyles()
  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.selectorContainer}
      justifyContent="space-between"
    >
      {items.map((item) => (
        <Grid item key={item.id}>
          <Link
            className={classNames(classes.linkButton, {
              [classes.activeLinkButton]: selectedItem === item.id,
            })}
            component="button"
            onClick={() => onChange(item.id)}
          >
            {LanguageUtils.getTranslatedFieldName(item)}
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}

type AgeSelectValue = {
  ageUnit: string
  value?: number
}

export interface AgeSelectProps {
  classes?: ClassesType<typeof useStyles>
  disabled: boolean
  label?: string
  onChange: (value: AgeSelectValue) => void
  value: AgeSelectValue
}

// eslint-disable-next-line react/no-multi-comp
const AgeSelect = ({
  classes: classesProp,
  value: { ageUnit, value },
  label,
  onChange,
  disabled,
}: AgeSelectProps) => {
  const classes = useStyles({ classes: classesProp })
  const AgeUnits = useSelector(getAgeUnits)
  const { t } = useTranslation('Common')

  const [selectedAgeUnit, setSelectedAgeUnit] = useState<string>(ageUnit)
  const [selectedItem, setSelectedItem] = useState(value || '')

  useEffect(() => {
    if (ageUnit) {
      setSelectedAgeUnit(ageUnit)
    }
  }, [ageUnit])

  useEffect(() => {
    if (!ageUnit && AgeUnits.length) {
      setSelectedAgeUnit((oldUnit) => oldUnit || AgeUnits[0].id)
    }
  }, [ageUnit, AgeUnits])

  useEffect(() => {
    if (value) {
      setSelectedItem(value)
    }
  }, [value])

  const handleItemChange = (newValue: number) => {
    setSelectedItem(newValue)
    onChange({ ageUnit: selectedAgeUnit, value: newValue })
  }

  const handleAgeUnitChange = (newAgeUnit: string) => {
    setSelectedAgeUnit(newAgeUnit)
    setSelectedItem('')
    onChange({ ageUnit: newAgeUnit, value: undefined })
  }

  const items = getItems(AgeUnits, selectedAgeUnit)

  const IconComponent = useCallback(
    () => <Toggle className={classes.toggleIcon} />,
    [],
  )

  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        displayEmpty
        IconComponent={IconComponent}
        MenuProps={{ PaperProps: { className: classes.dropdownMenu } }}
        disabled={disabled}
        input={<Input fullWidth />}
        value={selectedItem}
        onChange={Utils.handleFormSelectInput(handleItemChange)}
      >
        <MenuHeading
          items={AgeUnits}
          selectedItem={selectedAgeUnit}
          onChange={handleAgeUnitChange}
        />
        <MenuItem classes={{ root: classes.placeholder }} value="">
          {t('Common:NONE')}
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default AgeSelect
