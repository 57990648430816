import React from 'react'
import { useSelector } from 'react-redux'
import { Badge } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  ImageScalingUtils,
  Nil,
  PatientAvatar,
  PatientAvatarProps,
  PuiTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { AlertColorLevel, useGetAlertColor } from '~/constants/alertColors'
import { useAlertType } from '~/store/hooks/patient'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getPatientStatus } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    avatar: {},
    avatarDisabled: {
      opacity: 0.5,
    },
    icon: {
      fontSize: '1.2rem',
      fill: theme.colors.alertErrorText,
    },
    tooltip: {
      color: (props: UseStylesProps) => props.alertColor,
      borderColor: R.prop('alertColor'),
      marginLeft: 0,
      '&::before': {
        borderTopColor: R.prop('alertColor'),
      },
    },
    badge: {
      width: 20,
      height: 20,
      top: '18%',
      right: 8,
      borderRadius: '50%',
      backgroundColor: R.prop('alertColor'),
    },
  }),
  { name: 'PatientWarningAvatar' },
)

export interface PatientWarningAvatarProps extends PatientAvatarProps {
  classes?: ClassesType<typeof useStyles>
  patientId: string | Nil
}

interface UseStylesProps {
  alertColor: string
  classes?: ClassesType<typeof useStyles>
}

const PatientWarningAvatar = ({
  patientId,
  classes: classesProp,
  ...rest
}: PatientWarningAvatarProps) => {
  const Species = useSelector(getSpecies)
  const patient = useSelector(getPatient(patientId))

  const { alertsToRender } = useAlertType(patientId)
  const hasWarning = alertsToRender.length > 0

  const alertColor = useGetAlertColor(
    patient?.alertColorId,
    AlertColorLevel.PATIENT,
  )
  const patientStatus = getPatientStatus(patient)

  const classes = useStyles({ alertColor, classes: classesProp })
  const imageLongestDimensionSize = 72
  const src = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    imageLongestDimensionSize,
  )

  return (
    <Badge
      badgeContent={
        hasWarning ? (
          <PuiTooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            tooltipPlacement="top"
            tooltipText={alertsToRender.join(',')}
          >
            <WarningIcon className={classes.icon} />
          </PuiTooltip>
        ) : null
      }
      classes={{ badge: classes.badge }}
    >
      <PatientAvatar
        animal={Utils.getConstantName(patient?.species, Species)}
        className={classNames(classes.avatar, {
          [classes.avatarDisabled]: patientStatus.disabled,
        })}
        src={src}
        {...rest}
      />
    </Badge>
  )
}

export default PatientWarningAvatar
