import React from 'react'
import { StateLabel } from '@pbt/pbt-ui-components'
import { StateLabelProps } from '@pbt/pbt-ui-components/src/components/labels/StateLabel'

import { useChewyPrescriptionStateLabelProps } from '~/store/hooks/prescription'
import { Order, Prescription } from '~/types'

interface ChewyPrescriptionStateLabelProps extends StateLabelProps {
  prescription: Prescription | Order
}

const ChewyPrescriptionStateLabel = ({
  prescription,
  ...props
}: ChewyPrescriptionStateLabelProps) => {
  const stateLabelProps = useChewyPrescriptionStateLabelProps(prescription)

  return <StateLabel {...stateLabelProps} {...props} />
}

export default ChewyPrescriptionStateLabel
