import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { IntegrationArea } from '~/constants/integration'

import { RootState } from '../index'
import { getCurrentBusinessIsIdexxImagingEnabled } from '../reducers/auth'

const IntegrationAreaHooks: Record<
  IntegrationArea,
  (state: RootState) => boolean
> = {
  [IntegrationArea.IMAGING]: getCurrentBusinessIsIdexxImagingEnabled,
}

export const useIsAreaIntegrated = (integrationArea: IntegrationArea | Nil) => {
  // consider area is integrated if there is no area passed or selector specified for this area
  const selector = integrationArea
    ? IntegrationAreaHooks[integrationArea] || R.T
    : R.T

  return useSelector(selector)
}
