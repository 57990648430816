import { AnyAction } from 'redux'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import type { SoapAppointment } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'
import updateEntities from '../sagas/utils/updateEntities'
import { finishLoading, startLoading } from './progress'

export const FETCH_LAST_MONTH_APPOINTMENTS =
  'lastAppointments/FETCH_LAST_MONTH_APPOINTMENTS'
export const FETCH_LAST_MONTH_APPOINTMENTS_SUCCESS =
  'lastAppointments/FETCH_LAST_MONTH_APPOINTMENTS_SUCCESS'
export const FETCH_LAST_MONTH_APPOINTMENTS_FAILURE =
  'lastAppointments/FETCH_LAST_MONTH_APPOINTMENTS_FAILURE'

export const FETCH_LAST_APPOINTMENT = 'lastAppointments/FETCH_LAST_APPOINTMENT'
export const FETCH_LAST_APPOINTMENT_SUCCESS =
  'lastAppointments/FETCH_LAST_APPOINTMENT_SUCCESS'
export const FETCH_LAST_APPOINTMENT_FAILURE =
  'lastAppointments/FETCH_LAST_APPOINTMENTS_FAILURE'

export const CLEAN_UP_LAST_APPOINTMENTS =
  'lastAppointments/lastMonth/CLEAN_UP_LAST_APPOINTMENTS'

export const fetchLastMonthAppointments = (
  patientId: string,
  includeUpcoming: boolean,
) => ({
  type: FETCH_LAST_MONTH_APPOINTMENTS,
  patientId,
  includeUpcoming,
})
export const fetchLastMonthAppointmentsSuccess = (
  appointments: SoapAppointment[],
) => ({
  type: FETCH_LAST_MONTH_APPOINTMENTS_SUCCESS,
  appointments,
})
export const fetchLastMonthAppointmentsFailure = (error: ApiError) => ({
  type: FETCH_LAST_MONTH_APPOINTMENTS_FAILURE,
  error,
})

export const fetchLastAppointment = (patientId: string) => ({
  type: FETCH_LAST_APPOINTMENT,
  patientId,
})
export const fetchLastAppointmentSuccess = (appointmentId: string) => ({
  type: FETCH_LAST_APPOINTMENT_SUCCESS,
  appointmentId,
})
export const fetchLastAppointmentFailure = (error: ApiError) => ({
  type: FETCH_LAST_APPOINTMENT_FAILURE,
  error,
})

export const cleanUpLastAppointments = () => ({
  type: CLEAN_UP_LAST_APPOINTMENTS,
})

export type LastAppointmentsState = {
  error: string | null
  isLoading: boolean
  lastAppointmentId: string | null
  list: SoapAppointment[]
}

const INITIAL_STATE: LastAppointmentsState = {
  list: [],
  lastAppointmentId: null,
  isLoading: false,
  error: null,
}

export const lastAppointmentsReducer = (
  state: LastAppointmentsState = INITIAL_STATE,
  action: AnyAction,
): LastAppointmentsState => {
  switch (action.type) {
    case FETCH_LAST_MONTH_APPOINTMENTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_LAST_MONTH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        list: action.appointments,
        isLoading: false,
        error: null,
      }
    case FETCH_LAST_MONTH_APPOINTMENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_LAST_APPOINTMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_LAST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lastAppointmentId: action.appointmentId,
      }
    case FETCH_LAST_APPOINTMENT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CLEAN_UP_LAST_APPOINTMENTS:
      return INITIAL_STATE
    default:
      return state
  }
}

export const getLastAppointments = (state: RootState): LastAppointmentsState =>
  state.lastAppointments
export const getAppointmentsList = (state: RootState) =>
  getLastAppointments(state).list
export const getLastAppointmentId = (state: RootState) =>
  getLastAppointments(state).lastAppointmentId
export const getLastAppointmentsIsLoading = (state: RootState) =>
  getLastAppointments(state).isLoading
export const getLastAppointmentsError = (state: RootState) =>
  getLastAppointments(state).error

export function* fetchLastMonthAppointmentsSaga({
  patientId,
  includeUpcoming,
}: ReturnType<typeof fetchLastMonthAppointments>) {
  try {
    yield put(startLoading('lastMonthAppointments'))
    const appointments = yield* requestAPI(
      API.fetchLastMonthAppointments,
      patientId,
      includeUpcoming,
    )
    yield put(fetchLastMonthAppointmentsSuccess(appointments))
    yield put(finishLoading('lastMonthAppointments'))
  } catch (error) {
    yield put(fetchLastMonthAppointmentsFailure(error as ApiError))
    yield put(finishLoading('lastMonthAppointments'))
  }
}

export function* fetchLastAppointmentSaga({
  patientId,
}: ReturnType<typeof fetchLastAppointment>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.fetchLastAppointment,
      patientId,
    )
    yield call(updateEntities, entities)
    yield put(fetchLastAppointmentSuccess(result))
  } catch (error) {
    yield put(fetchLastAppointmentFailure(error as ApiError))
  }
}

function* watchFetchLastMonthAppointments() {
  yield takeLatest(
    FETCH_LAST_MONTH_APPOINTMENTS,
    fetchLastMonthAppointmentsSaga,
  )
}

function* watchFetchLastAppointment() {
  yield takeLatest(FETCH_LAST_APPOINTMENT, fetchLastAppointmentSaga)
}

export function* lastAppointmentsSaga() {
  yield all([watchFetchLastMonthAppointments(), watchFetchLastAppointment()])
}
