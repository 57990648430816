import {
  BaseSearchRequestParams,
  SearchClientsAndPatientsRequestParams,
  SearchPersonsRequestParams,
} from '~/types'

import { getAPIPath, getPreludeAPIPath, request } from './core'

export const searchPersons = (
  businessId: string,
  {
    query,
    max,
    roleIds,
    from,
    to,
    includeInactive,
    teamFilter,
    shorten,
  }: SearchPersonsRequestParams,
) =>
  request(
    getAPIPath('persons'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        roleIds,
        from: from || 0,
        to: to || max,
        includeInactive,
        shorten,
        teamFilter,
      },
    },
    true,
  )

// NOTE: keep roleIds argument to satisfy search endpoint interface
export const searchClientsAndPatients = (
  businessId: string,
  {
    max,
    query,
    fieldsQuery,
    includeInactive,
  }: SearchClientsAndPatientsRequestParams,
) =>
  request(
    getAPIPath('clients/search'),
    {
      method: 'GET',
      params: {
        businessId,
        query,
        max,
        includeInactive,
        fieldsQuery,
      },
    },
    true,
  )

export const searchReminderProtocols = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('admin/services/reminder-groups/search'),
    { method: 'GET', params: { businessId, query, max } },
    true,
  )

export const suggestImagingDashboard = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getPreludeAPIPath('idexx-imaging/v1/dashboard/suggest'),
    { method: 'GET', params: { businessId, query, max } },
    true,
  )

export const suggestOnHandCatalog = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('inventory/onhand/suggest'),
    { method: 'GET', params: { businessId, query, max } },
    true,
  )
