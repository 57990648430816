import React from 'react'
import { AppointmentEventType, EventTypeName } from '@pbt/pbt-ui-components'

import { useEventType } from '~/utils/useEventType'

import { FilterOption } from './BaseFilter'
import CheckboxListFilter from './CheckboxListFilter'

export interface AppointmentTypeFilterProps {
  anchorEl: any
  arrayFormat: boolean
  grouped: boolean
  includeSearch: boolean
  isLoading: boolean
  onChange: (filterOption: FilterOption) => void
  onClose: () => void
  open: boolean
  resetOnReOpen: boolean
  value: any
}

const AppointmentTypeFilter = (props: AppointmentTypeFilterProps) => {
  const AppointmentSubTypes: AppointmentEventType['subTypes'] = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )

  return <CheckboxListFilter items={AppointmentSubTypes} {...props} />
}

export default AppointmentTypeFilter
