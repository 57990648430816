import i18n from '~/locales/i18n'

export enum MarketplaceWorkflowNames {
  SOAP = 'SOAP',
  CLIENT_AND_PATIENT_DETAILS = 'Client/Patient details',
  SCHEDULER = 'Scheduler',
  WHITEBOARD = 'Whiteboard',
  INVENTORY = 'Inventory',
  NA = 'N/A',
}

export enum MarketplaceItemType {
  IFRAME = 'iFrame',
  FULL_INTEGRATION = 'Full integration',
  EXTERNAL = 'External',
}

export enum MarketplaceActivityFilterType {
  ALL = 'ALL',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export const MarketplaceActivityFilterLabel = {
  [MarketplaceActivityFilterType.ALL]: i18n.t('Common:ALL'),
  [MarketplaceActivityFilterType.INACTIVE]: i18n.t('Common:INACTIVE_ONE'),
  [MarketplaceActivityFilterType.ACTIVE]: i18n.t('Common:ACTIVE_ONE'),
}
