import React, { lazy, Suspense } from 'react'

import { PrintPDFComponentProps } from './PrintPDFComponent'

const PrintPDFComponent = lazy(() => import('./PrintPDFComponent'))

const PrintBrotherPrinterPDF = (props: PrintPDFComponentProps) => (
  <Suspense fallback={null}>
    <PrintPDFComponent {...props} />
  </Suspense>
)

export default PrintBrotherPrinterPDF
