import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Defaults, moment } from '@pbt/pbt-ui-components'

const parseToken = (token: string): JwtPayload | null => {
  try {
    return jwtDecode(token)
  } catch (error) {
    return null
  }
}

export const setTokenCookie = (token: string | null) => {
  const expirationUnixTimestamp = token ? parseToken(token)?.exp : undefined

  const cookieTtl = expirationUnixTimestamp
    ? Math.max(expirationUnixTimestamp - moment().unix(), 0)
    : Defaults.COOKIE_TTL

  document.cookie = `JWT=${token}; path=/; max-age=${cookieTtl}`
}
