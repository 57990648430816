import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { AlertIconType, PuiAlert, PuiAlertProps } from '@pbt/pbt-ui-components'

import i18nPortal from '~/locales/i18n'
import { getPosPayTransaction } from '~/store/reducers/payments'

interface POSPaymentFailureAlertDialogProps extends PuiAlertProps {
  hasPayment?: boolean
  onCancelRequested: () => void
  onResendTransaction: () => void
  setTransaction: (transaction: Record<string, any>) => void
  transaction: Record<string, any>
}

const DEFAULT_ERROR = i18nPortal.t('Errors:DEFAULT_ERROR')
const CONNECTION_ERROR = i18nPortal.t('Errors:CONNECTION_ERROR')

const POS_PAY_ERROR_MESSAGES_MAP = {
  CONNECTION_TIMEOUT: CONNECTION_ERROR,
  NO_DEVICE_CONNECTED: CONNECTION_ERROR,
  DEFAULT: DEFAULT_ERROR,
}

export const POSPaymentFailureAlertDialog = ({
  hasPayment = false,
  open,
  setTransaction,
  transaction,
  onCancelRequested,
  onResendTransaction,
}: POSPaymentFailureAlertDialogProps) => {
  const { t } = useTranslation('Common')

  const posPayTransaction = useSelector(getPosPayTransaction)

  const posPayFailureMessage =
    POS_PAY_ERROR_MESSAGES_MAP[
      transaction?.error?.rhError as keyof typeof POS_PAY_ERROR_MESSAGES_MAP
    ] || POS_PAY_ERROR_MESSAGES_MAP.DEFAULT

  useEffect(() => {
    if (!R.isEmpty(posPayTransaction)) {
      setTransaction(posPayTransaction)
    }
  }, [posPayTransaction])

  return (
    <PuiAlert
      cancelButtonText={
        !hasPayment ? t('Common:RECORD_MANUALLY') : t('Common:CLOSE_ACTION')
      }
      iconType={AlertIconType.WARN}
      message={posPayFailureMessage}
      okButtonText={t('Common:RE-SEND')}
      open={open}
      onCancel={onCancelRequested}
      onClose={() => setTransaction({})}
      onOk={onResendTransaction}
    />
  )
}
