import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { ImagingVendorName } from '~/constants/imaging'

import { getImagingVendors } from '../reducers/constants'

export const useGetIdexxImagingId = () => {
  const ImagingVendors = useSelector(getImagingVendors)
  const vendorName = ImagingVendorName.IDEXX

  return Utils.findConstantIdByName(vendorName, ImagingVendors)
}
