import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'

import UserSelect from '~/components/common/inputs/UserSelect'
import {
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceUpdateFieldCallback,
} from '~/types'

import { producerFieldChanged } from '../../invoiceUtils'

export interface ProductionCellBodyProps {
  className?: string
  group: InvoiceLineItemGroup
  isDeclined: boolean
  isGrouped: boolean
  isReadOnly: boolean
  item: InvoiceLineItem
  updateField: InvoiceUpdateFieldCallback
}

const ProductionCellBody = ({
  item,
  group,
  updateField,
  className,
  isGrouped,
  isDeclined,
  isReadOnly,
}: ProductionCellBodyProps) => {
  const { t } = useTranslation('Common')

  return (
    <TableCell className={className}>
      {!isGrouped && !isDeclined && (
        <UserSelect
          plain
          disabled={isReadOnly}
          // @ts-ignore
          field={{
            value: item.producerId,
            setValue: (event) => {
              updateField(
                event,
                'producerId',
                group,
                item,
                false,
                producerFieldChanged,
              )
            },
          }}
          label={t('Common:ASSIGN_TEAM_MEMBER')}
        />
      )}
    </TableCell>
  )
}

export default ProductionCellBody
