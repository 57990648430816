import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils, Text, User, Utils } from '@pbt/pbt-ui-components'

import TimelineCardContent from '../TimelineCardContent'

const useStyles = makeStyles(
  (theme) => ({
    detailsFooter: {
      padding: theme.spacing(1, 2),
      borderTop: theme.constants.tableBorder,
    },
    detailsFooterDate: {
      width: 88,
    },
    detailsFooterDispensed: {
      width: 172,
    },
    detailsFooterFilledBy: {
      maxWidth: 128,
    },
    detailsFooterAction: {
      marginLeft: 'auto',
    },
  }),
  { name: 'PrescriptionsCardDetails' },
)

interface PrescriptionsCardDetailsProps {
  cancelledRefills?: boolean
  children?: React.ReactNode
  className?: string
  date?: string
  inHousePrescription?: boolean
  refill?: boolean
  user?: User
}

const PrescriptionsCardDetails = ({
  date,
  user,
  refill = false,
  cancelledRefills = false,
  inHousePrescription = false,
  className,
  children,
}: PrescriptionsCardDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Clients', 'Plurals'])

  return (
    <TimelineCardContent
      noTypography
      className={classNames(classes.detailsFooter, className)}
    >
      <Grid container alignItems="center">
        <Grid item>
          <Text className={classes.detailsFooterDate} mr={4}>
            {DateUtils.formatDate(date)}
          </Text>
        </Grid>
        <Grid item>
          <Text className={classes.detailsFooterDispensed} mr={4}>
            {cancelledRefills
              ? t('Clients:TIMELINE.PRESCRIPTION_CARD.ALL_REFILLS_CANCELED')
              : refill
                ? t('Clients:TIMELINE.PRESCRIPTION_CARD.REFILL_DISPENSED')
                : inHousePrescription
                  ? t(
                      'Clients:TIMELINE.PRESCRIPTION_CARD.DISPENSED_FROM_INVENTORY',
                    )
                  : ''}
          </Text>
        </Grid>
        <Grid item>
          <Text noWrap className={classes.detailsFooterFilledBy}>
            {Utils.getPersonString(user)}
          </Text>
        </Grid>
        <Grid className={classes.detailsFooterAction}>{children}</Grid>
      </Grid>
    </TimelineCardContent>
  )
}

export default PrescriptionsCardDetails
