import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Nil, PuiSelect, Text, useFields, Utils } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import TaskTimeUnitsSelect from '~/components/dashboard/tasks-dashboard/TaskTimeUnitsSelect'
import {
  CustomDatePrepositions,
  CustomDatePrepositionsList,
} from '~/constants/taskConstants'
import {
  getTaskTemplateOptions,
  getTaskTimeUnits,
} from '~/store/reducers/constants'
import { DataHandleWithUnsavedChanges, Task, TaskCustomEndDate } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    select: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'BundleTaskCustomEndDateSettings' },
)

export interface BundleTaskCustomEndDateSettingsProps {
  className?: string
  endDateOptionId?: string
  task: Task | Nil
}

export interface BundleTaskCustomEndDateSettingsHandle
  extends DataHandleWithUnsavedChanges<TaskCustomEndDate> {}

const BundleTaskCustomEndDateSettings = forwardRef(
  function BundleTaskCustomEndDateSettings(
    {
      className,
      endDateOptionId: endDateOptionIdProp,
      task,
    }: BundleTaskCustomEndDateSettingsProps,
    ref: ForwardedRef<BundleTaskCustomEndDateSettingsHandle>,
  ) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const TaskTemplateOptions = useSelector(getTaskTemplateOptions)
    const TaskTimeUnits = useSelector(getTaskTimeUnits)

    const isContextItem = useIsCurrentContextItem(task)

    const endDateOptionId = endDateOptionIdProp || task?.endDateOptionId

    const CustomEndDate = Utils.findConstantIdByName(
      'Custom',
      TaskTemplateOptions.endDate || [],
    )
    const DayTimeUnit = Utils.findConstantIdByName('Day', TaskTimeUnits)

    const isCustomEndDate = endDateOptionId === CustomEndDate
    const amount = task?.customEndDateOffset?.amount || 1

    const { fields, validate, reset } = useFields(
      [
        { name: 'customEndDateAmount', initialValue: Math.abs(amount) },
        {
          name: 'customEndDateUnits',
          initialValue: task?.customEndDateOffset?.unit || DayTimeUnit,
        },
        {
          name: 'customEndDatePreposition',
          initialValue:
            amount >= 0
              ? CustomDatePrepositions.AFTER
              : CustomDatePrepositions.BEFORE,
        },
      ],
      false,
    )

    const {
      customEndDateAmount,
      customEndDateUnits,
      customEndDatePreposition,
    } = fields

    useEffect(() => {
      reset()
    }, [task])

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        customEndDateOffset: isCustomEndDate
          ? {
              amount:
                customEndDatePreposition.value === CustomDatePrepositions.AFTER
                  ? customEndDateAmount.value
                  : -customEndDateAmount.value,
              unit: customEndDateUnits.value,
            }
          : null,
      }),
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    if (!isCustomEndDate) {
      return null
    }

    return (
      <Grid
        container
        item
        alignItems="center"
        className={classNames(className, classes.root)}
        wrap="nowrap"
        xs={12}
      >
        <Grid item>
          <QuantityInput
            showControls
            disabled={!isContextItem}
            field={customEndDateAmount}
          />
        </Grid>
        <TaskTimeUnitsSelect
          amount={customEndDateAmount.value}
          className={classes.select}
          disabled={!isContextItem}
          field={customEndDateUnits}
        />
        <PuiSelect
          className={classes.select}
          disabled={!isContextItem}
          field={customEndDatePreposition}
          input={<Input id="task-every-select" />}
          items={CustomDatePrepositionsList}
          renderEmpty={false}
        />
        <Text variant="body">{t('Common:APPOINTMENT_DATE')}</Text>
      </Grid>
    )
  },
)

export default BundleTaskCustomEndDateSettings
