import { Nil } from '@pbt/pbt-ui-components'

import { DiagnoseHistoryItem } from '~/types'

import { getAPIPath, request } from './core'

export const fetchSoapDiagnosesFilters = (
  businessId: string,
  patientId: string,
) =>
  request(
    getAPIPath('diagnosis/filters'),
    { method: 'GET', params: { businessId, patientId } },
    true,
  )

export const fetchSoapDiagnoses = (
  businessId: string,
  patientId: string,
  categories: string,
  type: string,
) =>
  request(
    getAPIPath('diagnosis/filtered'),
    { method: 'GET', params: { businessId, patientId, categories, type } },
    true,
  )

export const searchDiagnoses = (
  businessId: string,
  query: string,
  patientId: string | Nil,
  from: number = 0,
  to: number = 999,
) =>
  request(
    getAPIPath('diagnosis/search'),
    {
      method: 'GET',
      params: { businessId, query, patientId, from, to },
    },
    true,
  )

export const deleteSoapDiagnosis = (
  businessId: string,
  soapId: string,
  diagnosisId: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/diagnoses/${diagnosisId}`),
    {
      method: 'DELETE',
      params: { businessId },
    },
    true,
  )

export const editSoapDiagnosis = (
  businessId: string,
  soapId: string | Nil,
  diagnosisId: string | Nil,
  diagnosis: Partial<DiagnoseHistoryItem>,
) =>
  request(
    getAPIPath(`soaps/${soapId}/diagnoses/${diagnosisId}`),
    {
      method: 'PATCH',
      params: { businessId },
      data: diagnosis,
    },
    true,
  )
