import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'

import SoapWrapUp from './SoapWrapUp'
import SoapWrapUpLocked from './SoapWrapUpLocked'

const WrapUpPage = () => {
  const SoapWrapUpComponent = useLockableComponent(SoapWrapUp, SoapWrapUpLocked)

  return <SoapWrapUpComponent />
}

export default WrapUpPage
