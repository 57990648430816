import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  PuiAlert,
} from '@pbt/pbt-ui-components'

import DetailsBackButton from '~/components/dashboard/clients/DetailsBackButton'
import DialogNames from '~/constants/DialogNames'
import { getDeleteConfirmMessage } from '~/utils'

import LeaveConfirmationDialog from '../dialog/LeaveConfirmationDialog'
import ItemHistoryLabel from '../labels/ItemHistoryLabel'
import LetUsKnowLink from '../LetUsKnowLink'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        flex: 1,
        paddingBottom: theme.spacing(1),
      },
    },
    container: {
      flex: 1,
      overflowY: 'auto',
      padding: theme.spacing(3),
      '& > *': {
        flexShrink: 0,
      },
    },
    buttonContainer: {
      flexShrink: 0,
      padding: theme.spacing(2, 3, 0),
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
    },
    button: {
      minWidth: 100,
      marginBottom: theme.spacing(2),
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        '&:not(:first-child)': {
          marginTop: theme.spacing(2),
        },
      },
    },
    letUsKnow: {
      alignSelf: 'center',
    },
    itemHistoryLabel: {
      marginBottom: theme.spacing(2),
    },
    overlay: {
      left: 0,
      top: 0,
    },
    backButton: {
      paddingLeft: theme.spacing(2),
    },
  }),
  { name: 'Expander' },
)

export interface ExpanderProps {
  additionalButtons?: (React.ReactElement | boolean)[]
  children: React.ReactNode
  className?: string
  expandedItemClass?: string
  getUnsavedData?: () => any
  hasUnsavedData?: boolean
  isCloning?: boolean
  isConfirmToDelete?: boolean
  isDeactivating?: boolean
  isDeleting?: boolean
  isFetching?: boolean
  isSaving?: boolean
  isUpdating?: boolean
  item?: any
  onBack?: () => void
  onCloneRequested?: () => void
  onDeactivateRequested?: () => void
  onDeleteRequested?: () => void
  onSaveRequested?: () => void
  onUpdateRequested?: () => void
  saveButtonId?: string
  saveButtonLabel?: string
  showButtons?: boolean
  showLetUsKnowLink?: boolean
  showOverlay?: boolean
}

const Expander = ({
  item,
  children,
  onSaveRequested,
  onDeleteRequested,
  onCloneRequested,
  onUpdateRequested,
  onDeactivateRequested,
  onBack,
  isFetching,
  isUpdating,
  isSaving,
  isDeleting,
  isCloning,
  isDeactivating,
  showLetUsKnowLink,
  className,
  expandedItemClass,
  isConfirmToDelete = false,
  showButtons = true,
  additionalButtons,
  getUnsavedData,
  hasUnsavedData,
  showOverlay,
  saveButtonId,
  saveButtonLabel,
}: ExpanderProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const actionInProgress =
    isSaving || isDeleting || isCloning || isUpdating || isDeactivating
  const { t } = useTranslation('Common')

  const deleteItem = () => {
    if (onDeleteRequested) {
      onDeleteRequested()
    }
    setDeleteDialogOpen(false)
  }

  const handleDelete = () => {
    if (isConfirmToDelete) {
      setDeleteDialogOpen(true)
    } else if (onDeleteRequested) {
      onDeleteRequested()
    }
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      data-testid="expander"
      direction="column"
    >
      <CircularProgressOverlay
        halfTransparent
        className={classes.overlay}
        open={isFetching || showOverlay}
      />
      {isMobile && onBack && (
        <DetailsBackButton
          fullWidth
          className={classes.backButton}
          onClick={onBack}
        >
          {t('Common:BACK_TO_ENTITY_LIST', { entity: expandedItemClass })}
        </DetailsBackButton>
      )}
      <Grid
        container
        item
        className={classNames(className, classes.container)}
        direction="column"
        wrap="nowrap"
      >
        {children}
      </Grid>
      {showButtons && (
        <Grid
          container
          item
          className={classes.buttonContainer}
          direction="column"
          wrap="nowrap"
        >
          <ItemHistoryLabel className={classes.itemHistoryLabel} item={item} />
          <Grid container item direction={isMobile ? 'column' : 'row'}>
            {onSaveRequested && (
              <ButtonWithLoader
                className={classes.button}
                disabled={actionInProgress || !(hasUnsavedData ?? true)}
                id={saveButtonId}
                loading={isSaving}
                onClick={onSaveRequested}
              >
                {saveButtonLabel || t('Common:SAVE_ACTION')}
              </ButtonWithLoader>
            )}
            {onUpdateRequested && (
              <ButtonWithLoader
                className={classes.button}
                disabled={actionInProgress}
                loading={isUpdating}
                onClick={onUpdateRequested}
              >
                {t('Common:ADD_ACTION')}
              </ButtonWithLoader>
            )}
            {onDeleteRequested && (
              <ButtonWithLoader
                className={classes.button}
                color="secondary"
                disabled={actionInProgress}
                loading={isDeleting}
                onClick={handleDelete}
              >
                {t('Common:DELETE_ACTION')}
              </ButtonWithLoader>
            )}
            {onCloneRequested && (
              <ButtonWithLoader
                className={classes.button}
                color="secondary"
                disabled={actionInProgress}
                loading={isCloning}
                onClick={onCloneRequested}
              >
                {t('Common:CLONE_ACTION')}
              </ButtonWithLoader>
            )}
            {onDeactivateRequested && (
              <ButtonWithLoader
                className={classes.button}
                color="inverted"
                disabled={actionInProgress}
                loading={isDeactivating}
                onClick={onDeactivateRequested}
              >
                {t('Common:DEACTIVATE_ACTION')}
              </ButtonWithLoader>
            )}
            {showLetUsKnowLink && (
              <LetUsKnowLink className={classes.letUsKnow} />
            )}
            {(additionalButtons || []).filter(Boolean).map((button, index) =>
              React.cloneElement(button as React.ReactElement, {
                className: classes.button,
                // eslint-disable-next-line react/no-array-index-key
                key: index,
              }),
            )}
          </Grid>
        </Grid>
      )}
      <PuiAlert
        cancelButtonText={t('Common:NO_KEEP')}
        message={getDeleteConfirmMessage(expandedItemClass)}
        okButtonText={t('Common:YES_DELETE')}
        open={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onClose={() => setDeleteDialogOpen(false)}
        onOk={deleteItem}
      />
      {(getUnsavedData || hasUnsavedData) && (
        <LeaveConfirmationDialog
          navigateOnCancel
          dialogName={DialogNames.EXPANDER_CONFIRM_CLOSE}
          getUnsavedData={getUnsavedData}
          hasUnsavedData={hasUnsavedData}
          navigateOnProceed={false}
          onProceed={onSaveRequested}
        />
      )}
    </Grid>
  )
}

export default Expander
