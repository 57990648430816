import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Input, InputLabel } from '@mui/material'
import { Constant, Field, PuiSelect } from '@pbt/pbt-ui-components'

import { getSize } from '~/store/reducers/constants'
import { getRequiredLabel } from '~/utils'

export interface PatientSizeSelectProps {
  field: Field
  hideLabel?: boolean
  required?: boolean
}

const PatientSizeSelect = ({
  field,
  required,
  hideLabel = false,
}: PatientSizeSelectProps) => {
  const Size: Constant[] = useSelector(getSize)
  const { t } = useTranslation('Common')

  return (
    <FormControl fullWidth error={!field.valid}>
      {!hideLabel && (
        <InputLabel htmlFor="patient-size-select">
          {getRequiredLabel(t('Common:SIZE'), required)}
        </InputLabel>
      )}
      <PuiSelect
        field={field}
        input={<Input id="patient-size-select" />}
        items={Size}
      />
    </FormControl>
  )
}

export default PatientSizeSelect
