import React from 'react'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    note: {
      fontStyle: 'italic',
    },
    button: {
      minWidth: 144,
      height: 40,
      margin: theme.spacing(0, 1),
    },
    goBackText: {
      textTransform: 'none',
    },
    paper: {
      width: 425,
    },
  }),
  { name: 'VitalsConfirmation' },
)

export interface VitalsConfirmationDialogProps extends BasePuiDialogProps {
  onCancel: () => void
  onProceed: () => void
}

const VitalsConfirmationDialog = ({
  open,
  onProceed,
  onCancel,
  onClose,
}: VitalsConfirmationDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  return (
    <PuiDialog
      aria-labelledby="vitals-confirmation-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      scroll="body"
      onClose={onClose}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        pb={5}
        pt={4}
        px={5}
      >
        <Grid item>
          <Text strong align="center">
            {t('Soap:VITALS_CONFIRMATION.SAVE_VITALS')}
          </Text>
        </Grid>
        <Grid item>
          <Text
            align="center"
            className={classes.note}
            pt={1.25}
            variant="body"
          >
            {t('Soap:VITALS_CONFIRMATION.AVOID_MESSAGE')}
          </Text>
        </Grid>
        <Grid item mb={4} mt={2}>
          <Button onClick={onClose}>
            <ArrowBackIcon />
            <Text strong className={classes.goBackText} variant="subheading3">
              {t('Common:GO_BACK')}
            </Text>
          </Button>
        </Grid>
        <Grid container item justifyContent="center" rowSpacing={2}>
          <Grid item>
            <Fab
              className={classes.button}
              color="inherit"
              variant="extended"
              onClick={onProceed}
            >
              {t('Common:SAVE_VITALS')}
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              className={classes.button}
              color="primary"
              variant="extended"
              onClick={onCancel}
            >
              {t('Common:DO_NOT_SAVE')}
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default VitalsConfirmationDialog
