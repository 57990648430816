import React from 'react'
import { Trans } from 'react-i18next'

import Link from '~/components/common/link/Link'

const Procedures = () => (
  <Trans
    components={{ linkToProcedures: <Link to="/admin/catalog/procedures" /> }}
    i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.PROCEDURES.TEXT"
  />
)
export default Procedures
