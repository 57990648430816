import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, SelectChangeEvent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Constant, Utils } from '@pbt/pbt-ui-components'

import PuiStateSelect, {
  PuiStateSelectProps,
} from '~/components/common/inputs/PuiStateSelect'
import FeatureToggle from '~/constants/featureToggle'
import {
  LabTestStateEasterEgg,
  LabTestStateIsDone,
  LabTestStateWithIssue,
} from '~/constants/SOAPStates'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import {
  getFeatureToggle,
  getSelectableLabTestsStates,
} from '~/store/reducers/constants'
import { EasterEggEvents } from '~/types'

const useStyles = makeStyles(
  () => ({
    stateSelect: {
      width: 182,
    },
  }),
  { name: 'LabTestsStateSelect' },
)

export interface LabTestsStateSelectProps extends PuiStateSelectProps {
  statusesToShow?: string[]
}

const LabTestsStateSelect = ({
  statusesToShow,
  onChange,
  ...props
}: LabTestsStateSelectProps) => {
  const classes = useStyles(props)
  const LabTestStates: Constant[] = useSelector(getSelectableLabTestsStates)
  const dispatch = useDispatch()

  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )

  const labTestStates = LabTestStates.filter(
    ({ id }) => !statusesToShow || statusesToShow.includes(id),
  ).map(({ id, name, nameTranslation }) => ({
    id,
    name,
    nameTranslation,
    hasIssue: LabTestStateWithIssue.includes(name),
    isDone: LabTestStateIsDone.includes(name),
  }))

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    const eventValue = event.target.value as string
    const statusName = Utils.getConstantName(eventValue, LabTestStates)
    if (isEasterEggEnabled && LabTestStateEasterEgg.includes(statusName)) {
      dispatch(
        queueEasterEggEvent({
          actionType: EasterEggEvents.LAB_WORK_STATUS_UPDATE,
          actionSubtype: Utils.getConstantName(statusName, LabTestStates),
        }),
      )
    }

    return onChange && onChange(event, child)
  }

  return (
    <PuiStateSelect
      fullWidth
      classes={{ root: classes.stateSelect }}
      input={<Input disableUnderline />}
      items={labTestStates}
      renderEmpty={false}
      onChange={handleChange}
      {...props}
    />
  )
}

export default LabTestsStateSelect
