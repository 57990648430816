import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  LanguageUtils,
  PermissionArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import { ColorVariant } from '~/constants/colors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { deleteDiagnosisHistory } from '~/store/actions/medicalHistory'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getDiagnosisProblemStates,
  getDiagnosisStatus,
  getFeatureToggle,
  getLocationDisplayName,
} from '~/store/reducers/constants'
import { DiagnosisTimelineItem, DiagnosisType } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import ProblemsDiagnosesTableDiagnosis from '../appointment/problems-diagnoses-table/ProblemsDiagnosesTableDiagnosis'
import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

export interface DiagnosisCardProps extends Partial<TimelineCardProps> {
  clientId: string
  item: DiagnosisTimelineItem
  patientId: string
}

const DiagnosisCard = ({
  item,
  clientId,
  patientId,
  ...rest
}: DiagnosisCardProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Clients'])

  const isProblemDiagnosis = item.type === DiagnosisType.PROBLEM_DIAGNOSIS

  const permissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const DiagnosisStatus = useSelector(getDiagnosisStatus)
  const DiagnosisProblemStates = useSelector(getDiagnosisProblemStates)
  const DiagnosisStatusesConstant = isProblemDiagnosis
    ? DiagnosisProblemStates
    : DiagnosisStatus
  const locationName = useSelector(getLocationDisplayName(item.locationId))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const statusId = item.status
  const statusName = LanguageUtils.getConstantTranslatedName(
    statusId,
    DiagnosisStatusesConstant,
  )
  const activeStatus = Utils.findById(statusId, DiagnosisStatusesConstant) || {}

  const isHistoryRecord = !item.soapId
  const stateName = isHistoryRecord
    ? t('Clients:TIMELINE.DIAGNOSIS_CARD.RECORD_FROM_PLACE', {
        diagnosedIn: item.diagnosedIn || t('Common:PREVIOUS_CLINIC'),
      })
    : statusName

  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)

  const onDelete = () => {
    dispatch(deleteDiagnosisHistory(item.id, patientId))
  }

  const onEdit = () => {
    openDiagnosisHistoryDialog({ clientId, patientId, diagnosis: item })
  }

  return (
    <TimelineCard
      originBusinessId={item.businessId}
      title={LanguageUtils.getTranslatedFieldName(item, 'description')}
      onDelete={
        permissions.update && isHistoryRecord && item.id ? onDelete : undefined
      }
      onEdit={
        permissions.update && isHistoryRecord && item.id ? onEdit : undefined
      }
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {locationName && (
          <Text>{`${t('Common:LOCATION')}: ${locationName}`}</Text>
        )}
        {item?.vet && <Text strong>{Utils.getPersonString(item.vet)}</Text>}
        {item.notes && !isProblemDiagnosis && (
          <Text>{`${t('Common:NOTES')}: ${item.notes}`}</Text>
        )}
        {isProblemDiagnosis && (
          <ProblemsDiagnosesTableDiagnosis
            fromDiagnosisCard
            key={item.description + item.date}
            log={item}
          />
        )}
      </TimelineCardContent>
      <TimelineCardActions
        {...item}
        disabledState={activeStatus.colorVariant === ColorVariant.DISABLED}
        state={stateName}
        warningState={activeStatus.colorVariant === ColorVariant.IMPORTANT}
      >
        {permissions.read && item.soapId && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${item.soapId}`,
              isPatientSharingEnabled ? item.businessId : null,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default DiagnosisCard
