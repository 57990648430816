import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getImagingModalities } from '~/store/reducers/constants'

export interface ModalityFilterProps extends CheckboxListFilterProps {}

const ModalityFilter = (props: ModalityFilterProps) => {
  const ImagingModalities = useSelector(getImagingModalities)

  return <CheckboxListFilter arrayFormat items={ImagingModalities} {...props} />
}

export default ModalityFilter
