import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import {
  ButtonWithLoader,
  DateFormat,
  moment,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import ActionsButton, {
  ActionsButtonProps,
} from '~/components/common/buttons/ActionsButton'
import { Soap } from '~/types'

export interface OpenSoapButtonProps
  extends Omit<ActionsButtonProps, 'classes'> {
  onClick: (id: string) => void
  soaps?: Soap[]
}

const OpenSoapButton = ({
  soaps: soapsProp,
  onClick,
  ...rest
}: OpenSoapButtonProps) => {
  const { t } = useTranslation('Common')
  const soaps = soapsProp || []

  if (soaps.length <= 1) {
    return (
      <ButtonWithLoader onClick={() => onClick(soaps[0]?.id)} {...rest}>
        {t('Common:OPEN_SOAP')}
      </ButtonWithLoader>
    )
  }

  const actions = soaps.map((soap, index) => ({
    id: soap.id,
    onClick: () => onClick(soap.id),
    content: (
      <Grid
        container
        item
        columnSpacing={2}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          <Text variant="body">{`${t('Common:SOAP')} ${index + 1}`}</Text>
        </Grid>
        <Grid item>
          <Text variant="body">
            {moment(soap.date).format(DateFormat.FULL_TIME_WITH_MERIDIAN)}
          </Text>
        </Grid>
        <Grid item xs>
          <Text variant="body">{Utils.getPersonString(soap.assignedVet)}</Text>
        </Grid>
      </Grid>
    ),
  }))

  return (
    <ActionsButton useNormalButton actions={actions} {...rest}>
      {t('Common:OPEN_SOAP')}
    </ActionsButton>
  )
}

export default OpenSoapButton
