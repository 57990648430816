import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import PrintLabelDialog from '~/components/common/dialog/PrintLabelDialog'
import { DymoLabelType } from '~/constants/dymo'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { fetchOrderDetailsGrouped } from '~/store/actions/orders'
import {
  useGetInHousePrescriptionLabel,
  useIsFood,
  useIsGlobalInventoryItem,
} from '~/store/hooks/orders'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getOrderDetailsGrouped,
  getOrdersIsGroupedDetailsReceiving,
} from '~/store/reducers/orders'
import { PrescriptionPrintInfo } from '~/types'
import { getPrescriptionGlobalInventoryId } from '~/utils/prescription'

export interface PrintInHouseOrderLabelDialogProps extends BasePuiDialogProps {
  printInfo: PrescriptionPrintInfo
}

const PrintInHouseOrderLabelDialog = ({
  open,
  printInfo,
  onClose,
}: PrintInHouseOrderLabelDialogProps) => {
  const dispatch = useDispatch()

  const orderDetailsGrouped = useSelector(getOrderDetailsGrouped)
  const orderDetailsLoading = useSelector(getOrdersIsGroupedDetailsReceiving)
  const currentBusiness = useSelector(getCurrentBusiness)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  useEffect(() => {
    const globalInventoryCommonOrMappingId = getPrescriptionGlobalInventoryId(
      printInfo,
      isFoodCatalogEnabled,
    )
    if (
      !printInfo.variation?.name &&
      printInfo.inventory?.id &&
      globalInventoryCommonOrMappingId
    ) {
      dispatch(
        fetchOrderDetailsGrouped({
          businessItemId: printInfo.inventory?.id,
          globalInventoryCommonOrMappingId,
        }),
      )
    }
  }, [printInfo.variation])

  const orderDetails = orderDetailsGrouped.find(
    ({ variation }) => variation && variation.id === printInfo.variation?.id,
  )

  const printInfoEnriched = {
    ...printInfo,
    bottomWarning: currentBusiness?.prescriptionLabelDisclaimer || '',
    ...(orderDetails && !printInfo.variation?.name ? orderDetails : {}),
  }

  const isGlobalItem = useIsGlobalInventoryItem(printInfo)
  const isFood = useIsFood(printInfo)

  const { description, labelLines, name, formattedData } =
    useGetInHousePrescriptionLabel(printInfoEnriched, {
      isGlobalItem,
      isFood,
    })

  return (
    <PrintLabelDialog
      customPrintSize
      editable
      showFormattedLabels
      showHtmlLabels
      description={description}
      formattedLabelData={formattedData}
      labelLines={labelLines}
      labelType={DymoLabelType.PRESCRIPTION}
      loading={orderDetailsLoading}
      open={open}
      showCopyToClipboard={isGlobalItem}
      showPdfPrint={isGlobalItem}
      showPrintLabelContent={isGlobalItem}
      showSimpleTextLabels={isGlobalItem}
      title={i18n.t('Common:PRESCRIBE_SOMETHING', { entity: name })}
      onClose={onClose}
    />
  )
}

export default PrintInHouseOrderLabelDialog
