import { ApiError } from '@pbt/pbt-ui-components'

import {
  AddNewProblemBodySystemLogPayload,
  AddNewProblemLogPayload,
  AddProblemLogEnumValuePayload,
  Problem,
  ProblemLogBodySystemContainer,
  ProblemLogHistory,
  ProblemLogProblemContainer,
  UpdateProblemBodySystemLogPayload,
  UpdateProblemLogEnumValuePayload,
  UpdateProblemLogPayload,
} from '~/types'

import {
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_FAILURE,
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_SUCCESS,
  CLEAN_PROBLEMS_VALIDATION_ERROR,
  CREATE_PROBLEM_BODY_SYSTEM_LOG,
  CREATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  CREATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  CREATE_PROBLEM_LOG,
  CREATE_PROBLEM_LOG_ENUM_VALUE,
  CREATE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  CREATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  CREATE_PROBLEM_LOG_FAILURE,
  CREATE_PROBLEM_LOG_SUCCESS,
  DELETE_PROBLEM_BODY_SYSTEM_LOG,
  DELETE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  DELETE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  DELETE_PROBLEM_LOG_ENUM_VALUE,
  DELETE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  DELETE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  DISABLE_TOAST_SHOWING,
  DISABLE_TOAST_SHOWING_FAILURE,
  DISABLE_TOAST_SHOWING_SUCCESS,
  FETCH_LOGS,
  FETCH_LOGS_FAILURE,
  FETCH_LOGS_SUCCESS,
  FETCH_MORE_PROBLEM_LOG_HISTORY,
  FETCH_MORE_PROBLEM_LOG_HISTORY_FAILURE,
  FETCH_MORE_PROBLEM_LOG_HISTORY_SUCCESS,
  FETCH_PROBLEM_CATALOG,
  FETCH_PROBLEM_CATALOG_FAILURE,
  FETCH_PROBLEM_CATALOG_SUCCESS,
  FETCH_PROBLEM_LOG_HISTORY,
  FETCH_PROBLEM_LOG_HISTORY_FAILURE,
  FETCH_PROBLEM_LOG_HISTORY_SUCCESS,
  SEARCH_MORE_PROBLEMS,
  SEARCH_MORE_PROBLEMS_FAILURE,
  SEARCH_MORE_PROBLEMS_SUCCESS,
  SEARCH_PROBLEMS,
  SEARCH_PROBLEMS_FAILURE,
  SEARCH_PROBLEMS_SUCCESS,
  SET_PROBLEM_TO_OPEN_IN_RAIL,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG_VALIDATION_FAILURE,
  UPDATE_PROBLEM_LOG,
  UPDATE_PROBLEM_LOG_ENUM_VALUE,
  UPDATE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  UPDATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  UPDATE_PROBLEM_LOG_FAILURE,
  UPDATE_PROBLEM_LOG_SUCCESS,
  UPDATE_PROBLEM_LOG_VALIDATION_FAILURE,
} from './types/problems'

export const fetchProblemCatalog = (patientId: string) => ({
  type: FETCH_PROBLEM_CATALOG,
  patientId,
})
export const fetchProblemCatalogSuccess = (catalog: unknown) => ({
  type: FETCH_PROBLEM_CATALOG_SUCCESS,
  catalog,
})
export const fetchProblemCatalogFailure = (error: ApiError) => ({
  type: FETCH_PROBLEM_CATALOG_FAILURE,
  error,
})

export const fetchProblemLogs = (soapId: string) => ({
  type: FETCH_LOGS,
  soapId,
})
export const fetchProblemLogsSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
  showAddedProblemLogsToast: boolean | null,
) => ({
  type: FETCH_LOGS_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
  showAddedProblemLogsToast,
})
export const fetchProblemLogsFailure = (error: ApiError) => ({
  type: FETCH_LOGS_FAILURE,
  error,
})

export const createProblemBodySystemLog = (
  soapId: string,
  problemBodySystemLog: AddNewProblemBodySystemLogPayload,
) => ({
  type: CREATE_PROBLEM_BODY_SYSTEM_LOG,
  soapId,
  problemBodySystemLog,
})
export const createProblemBodySystemLogSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
) => ({
  type: CREATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
})
export const createProblemBodySystemLogFailure = (error: ApiError) => ({
  type: CREATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  error,
})

export const deleteProblemBodySystemLog = (
  soapId: string,
  problemBodySystemLogId: string,
) => ({
  type: DELETE_PROBLEM_BODY_SYSTEM_LOG,
  soapId,
  problemBodySystemLogId,
})
export const deleteProblemBodySystemLogSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
) => ({
  type: DELETE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
})
export const deleteProblemBodySystemLogFailure = (error: ApiError) => ({
  type: DELETE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  error,
})

export const updateProblemBodySystemLog = (
  soapId: string,
  problemBodySystemLogId: string,
  problemBodySystemLog: UpdateProblemBodySystemLogPayload,
) => ({
  type: UPDATE_PROBLEM_BODY_SYSTEM_LOG,
  soapId,
  problemBodySystemLogId,
  problemBodySystemLog,
})
export const updateProblemBodySystemLogSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
) => ({
  type: UPDATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
})
export const updateProblemBodySystemLogFailure = (error: ApiError) => ({
  type: UPDATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE,
  error,
})
export const updateProblemBodySystemLogValidationFailure = (
  error: ApiError,
) => ({
  type: UPDATE_PROBLEM_BODY_SYSTEM_LOG_VALIDATION_FAILURE,
  error,
})

export const cleanProblemValidationError = () => ({
  type: CLEAN_PROBLEMS_VALIDATION_ERROR,
})

export const createProblemLog = (
  soapId: string,
  bodySystemLogId: string,
  problemLog: AddNewProblemLogPayload,
) => ({
  type: CREATE_PROBLEM_LOG,
  soapId,
  bodySystemLogId,
  problemLog,
})
export const createProblemLogSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
) => ({
  type: CREATE_PROBLEM_LOG_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
})
export const createProblemLogFailure = (error: ApiError) => ({
  type: CREATE_PROBLEM_LOG_FAILURE,
  error,
})

export const updateProblemLog = (
  soapId: string,
  logId: string,
  problemLogRequest: UpdateProblemLogPayload,
) => ({
  type: UPDATE_PROBLEM_LOG,
  soapId,
  logId,
  problemLogRequest,
})
export const updateProblemLogSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
) => ({
  type: UPDATE_PROBLEM_LOG_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
})
export const updateProblemLogFailure = (error: ApiError) => ({
  type: UPDATE_PROBLEM_LOG_FAILURE,
  error,
})
export const updateProblemLogValidationFailure = (error: ApiError) => ({
  type: UPDATE_PROBLEM_LOG_VALIDATION_FAILURE,
  error,
})

export const searchProblems = (
  query: string,
  patientId: string,
  from: number,
  to: number,
) => ({
  type: SEARCH_PROBLEMS,
  query,
  patientId,
  from,
  to,
})
export const searchProblemsSuccess = (
  searchResultList: string[],
  searchResultMap: Record<string, Problem>,
  totalCount: number,
) => ({
  type: SEARCH_PROBLEMS_SUCCESS,
  searchResultList,
  searchResultMap,
  totalCount,
})
export const searchProblemsFailure = (error: ApiError) => ({
  type: SEARCH_PROBLEMS_FAILURE,
  error,
})

export const searchMoreProblems = (
  query: string,
  patientId: string,
  from: number,
  to: number,
) => ({
  type: SEARCH_MORE_PROBLEMS,
  query,
  patientId,
  from,
  to,
})
export const searchMoreProblemsSuccess = (
  searchResultList: string[],
  searchResultMap: Record<string, Problem>,
  totalCount: number,
  from: number,
) => ({
  type: SEARCH_MORE_PROBLEMS_SUCCESS,
  searchResultList,
  searchResultMap,
  totalCount,
  from,
})
export const searchMoreProblemsFailure = (error: ApiError) => ({
  type: SEARCH_MORE_PROBLEMS_FAILURE,
  error,
})

export const fetchProblemLogHistory = (
  problemId: string,
  patientId: string,
  soapId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_PROBLEM_LOG_HISTORY,
  problemId,
  patientId,
  soapId,
  from,
  to,
})
export const fetchProblemLogHistorySuccess = (
  logHistoryList: string[],
  logHistoryMap: Record<string, ProblemLogHistory>,
  totalCount: number,
) => ({
  type: FETCH_PROBLEM_LOG_HISTORY_SUCCESS,
  logHistoryList,
  logHistoryMap,
  totalCount,
})
export const fetchProblemLogHistoryFailure = (error: ApiError) => ({
  type: FETCH_PROBLEM_LOG_HISTORY_FAILURE,
  error,
})

export const fetchMoreProblemLogHistory = (
  problemId: string,
  patientId: string,
  soapId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_PROBLEM_LOG_HISTORY,
  problemId,
  patientId,
  soapId,
  from,
  to,
})
export const fetchMoreProblemLogHistorySuccess = (
  logHistoryList: string[],
  logHistoryMap: Record<string, ProblemLogHistory>,
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_PROBLEM_LOG_HISTORY_SUCCESS,
  logHistoryList,
  logHistoryMap,
  totalCount,
  from,
})
export const fetchMoreProblemLogHistoryFailure = (error: ApiError) => ({
  type: FETCH_MORE_PROBLEM_LOG_HISTORY_FAILURE,
  error,
})

export const disableToastShowing = (soapId: string) => ({
  type: DISABLE_TOAST_SHOWING,
  soapId,
})
export const disableToastShowingSuccess = () => ({
  type: DISABLE_TOAST_SHOWING_SUCCESS,
})
export const disableToastShowingFailure = (error: ApiError) => ({
  type: DISABLE_TOAST_SHOWING_FAILURE,
  error,
})

export const changeStateForAllBodySystems = (
  soapId: string,
  stateId: string,
  bodySystemIds: string[],
) => ({
  type: CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
  soapId,
  bodySystemLogBatchRequest: { stateId, bodySystemIds },
})
export const changeStateForAllBodySystemsSuccess = (
  bodySystemLogsMap: Record<string, ProblemLogBodySystemContainer>,
  problemLogsMap: Record<string, ProblemLogProblemContainer>,
) => ({
  type: CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_SUCCESS,
  bodySystemLogsMap,
  problemLogsMap,
})
export const changeStateForAllBodySystemsFailure = (error: ApiError) => ({
  type: CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_FAILURE,
  error,
})

export const createProblemLogEnumValue = (
  problemLogId: string,
  problemEnumLogRequest: AddProblemLogEnumValuePayload,
) => ({
  type: CREATE_PROBLEM_LOG_ENUM_VALUE,
  problemLogId,
  problemEnumLogRequest,
})
export const createProblemLogEnumValueSuccess = (
  problemLog: ProblemLogProblemContainer,
) => ({
  type: CREATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  problemLog,
})
export const createProblemLogEnumValueFailure = (error: ApiError) => ({
  type: CREATE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  error,
})

export const updateProblemLogEnumValue = (
  problemLogId: string,
  problemEnumLogId: string,
  problemEnumLogRequest: UpdateProblemLogEnumValuePayload,
) => ({
  type: UPDATE_PROBLEM_LOG_ENUM_VALUE,
  problemLogId,
  problemEnumLogId,
  problemEnumLogRequest,
})
export const updateProblemLogEnumValueSuccess = (
  problemLog: ProblemLogProblemContainer,
) => ({
  type: UPDATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  problemLog,
})
export const updateProblemLogEnumValueFailure = (error: ApiError) => ({
  type: UPDATE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  error,
})

export const deleteProblemLogEnumValue = (
  problemLogId: string,
  problemEnumLogId: string,
  crc: number,
) => ({
  type: DELETE_PROBLEM_LOG_ENUM_VALUE,
  problemLogId,
  problemEnumLogId,
  crc,
})
export const deleteProblemLogEnumValueSuccess = (
  problemLog: ProblemLogProblemContainer,
) => ({
  type: DELETE_PROBLEM_LOG_ENUM_VALUE_SUCCESS,
  problemLog,
})
export const deleteProblemLogEnumValueFailure = (error: ApiError) => ({
  type: DELETE_PROBLEM_LOG_ENUM_VALUE_FAILURE,
  error,
})

export const setProblemToOpenInRail = (problem: Problem | null) => ({
  type: SET_PROBLEM_TO_OPEN_IN_RAIL,
  problem,
})
