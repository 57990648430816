import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertIconType, PuiAlert } from '@pbt/pbt-ui-components'

import { closeTimezoneAlert } from '~/store/actions/journal'
import { getHasClosedTimezoneAlert } from '~/store/reducers/journal'
import { useCurrentBusinessTimezoneWarning } from '~/utils/useCurrentBusinessTimezone'

const TimezoneAlert = () => {
  const dispatch = useDispatch()
  const hasClosedTimezoneAlert: boolean = useSelector(getHasClosedTimezoneAlert)

  const message: React.ReactNode = useCurrentBusinessTimezoneWarning()

  if (!message) {
    return null
  }

  return (
    <PuiAlert
      data-testid="timezone-pui-alert"
      iconType={AlertIconType.WARN}
      message={message}
      open={!hasClosedTimezoneAlert}
      onClose={() => dispatch(closeTimezoneAlert())}
    />
  )
}

export default TimezoneAlert
