import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Nil, PuiPopper, Text } from '@pbt/pbt-ui-components'

import OrderStateLabel from '~/components/common/labels/OrderStateLabel'
import { PrescriptionStateLabel } from '~/components/common/labels/PrescriptionStateLabel'
import WarningRowLabel from '~/components/common/labels/WarningRowLabel'
import PuiIconButton from '~/components/common/PuiIconButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      padding: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    softText: {
      color: theme.colors.lowAccentText,
    },
    paper: {
      width: 230,
      overflow: 'auto',
      boxShadow: theme.constants.blockShadow,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'above'),
    },
    stateLabel: {
      minWidth: 55,
      maxHeight: 12,
    },
    puiIconButton: {
      backgroundColor: 'inherit',
      boxShadow: 'none',
    },
    leftInfoContainer: {
      width: 'auto',
    },
    statusContainer: {
      minWidth: 96,
      display: 'flex',
      marginRight: 'auto',
    },
  }),
  { name: 'FinalizeOrdersRow' },
)

interface OrderNameDetails {
  orderName: string | Nil
  softText?: boolean
}

interface BaseFinalizeOrdersRowProps {
  actions?: React.ReactNode
  labOrderId?: string | Nil
  orderNameDetails: OrderNameDetails | OrderNameDetails[] | Nil
  showWarning: boolean
  vendorName?: string | Nil
}

interface FinalizePrescription extends BaseFinalizeOrdersRowProps {
  stateId: string
  statusName?: never
}

interface FinalizeOrder extends BaseFinalizeOrdersRowProps {
  stateId?: never
  statusName: string | Nil
}

type FinalizeOrdersRowProps = FinalizePrescription | FinalizeOrder

const FinalizeOrdersRow = ({
  actions,
  labOrderId,
  orderNameDetails,
  showWarning,
  statusName,
  stateId,
  vendorName,
}: FinalizeOrdersRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const ref = useRef<HTMLButtonElement>(null)
  const popperAnchor = ref.current

  const [popperOpen, setPopperOpen] = useState(false)

  const orderNameRender = Array.isArray(orderNameDetails) ? (
    orderNameDetails.map((details, i) => (
      <Text
        className={details.softText ? classes.softText : undefined}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        mb={0.5}
      >
        {details.orderName}
      </Text>
    ))
  ) : (
    <Text className={orderNameDetails?.softText ? classes.softText : undefined}>
      {orderNameDetails?.orderName}
    </Text>
  )

  return (
    <Grid container className={classes.root} direction="column">
      <Grid container item xs alignItems="center">
        <Grid
          container
          item
          xs
          alignItems="center"
          className={classes.leftInfoContainer}
          datatest-id="finalize-orders-row-name"
        >
          {vendorName ? (
            <Text strong pr={1}>
              {vendorName}
              {labOrderId ? `: ${labOrderId}` : ''}
            </Text>
          ) : (
            <Grid container item>
              {orderNameRender}
            </Grid>
          )}
          {showWarning && (
            <WarningRowLabel
              message={t('Soap:MAKE_SURE_TO_COMPLETE_ORDER_WARNING_MESSAGE', {
                vendorName,
              })}
            />
          )}
        </Grid>
        <Grid
          item
          className={classes.statusContainer}
          datatest-id="finalize-orders-row-status"
          xs={2}
        >
          {statusName && (
            <OrderStateLabel
              alignSelf="center"
              className={classes.stateLabel}
              orderStatus={statusName}
              variant="small"
            >
              {statusName}
            </OrderStateLabel>
          )}
          {stateId && (
            <PrescriptionStateLabel stateId={stateId} variant="small" />
          )}
        </Grid>
        {actions && (
          <Grid
            container
            item
            datatest-id="finalize-orders-row-actions"
            justifyContent="flex-end"
            xs={1}
          >
            <PuiIconButton
              Icon={MoreHorizIcon}
              className={classes.puiIconButton}
              ref={ref}
              onClick={() => setPopperOpen(true)}
            />
            <PuiPopper
              hideCloseButton
              anchorEl={popperAnchor}
              classes={{
                paper: classes.paper,
              }}
              modifiers={[
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    padding: 8,
                  },
                },
              ]}
              open={popperOpen}
              placement="bottom-end"
              onClose={() => setPopperOpen(false)}
            >
              <Grid container flexDirection="column">
                {actions}
              </Grid>
            </PuiPopper>
          </Grid>
        )}
      </Grid>
      {vendorName && (
        <Grid container item>
          {orderNameRender}
        </Grid>
      )}
    </Grid>
  )
}

export default FinalizeOrdersRow
