import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import EstimateStateLabel from '~/components/dashboard/invoices/EstimateStateLabel'

const useStyles = makeStyles(
  () => ({
    status: {
      width: 'fit-content',
      fontSize: '1.2rem',
    },
  }),
  { name: 'EstimateTableStatusCell' },
)

interface EstimateTableStatusCellProps {
  estimate: Pick<GraphqlEstimate, 'stateId' | 'state'>
}

export const EstimateTableStatusCell = ({
  estimate,
}: EstimateTableStatusCellProps) => {
  const classes = useStyles()

  return (
    <Box alignItems="center" display="flex">
      <EstimateStateLabel className={classes.status} estimate={estimate} />
    </Box>
  )
}
