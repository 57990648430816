import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { QuestionMode } from '@pbt/pbt-ui-components'

import { getSoapQuestions } from '~/store/reducers/questions'
import { getSoapId } from '~/store/reducers/soap'

import SoapWidget from '../SoapWidget'
import QuestionWidgetActions from './actions/QuestionWidgetActions'
import QuestionsTabs from './tabs/QuestionsTabs'

const QuestionsWidgetUnlocked = () => {
  const { t } = useTranslation('Common')

  const soapId = useSelector(getSoapId)
  const soapQuestions = useSelector(getSoapQuestions)

  const hasNoQuestions =
    soapId && soapQuestions[soapId] ? soapQuestions[soapId].length === 0 : false

  const [mode, setMode] = useState<QuestionMode>('edit')

  const toggleMode = () => {
    setMode(mode === 'edit' ? 'read-only' : 'edit')
  }

  return (
    <SoapWidget
      actions={
        hasNoQuestions ? null : (
          <QuestionWidgetActions mode={mode} toggleMode={toggleMode} />
        )
      }
      id="questions-widget"
      title={t('Common:QUESTION_OTHER')}
    >
      <QuestionsTabs hasNoQuestions={hasNoQuestions} mode={mode} />
    </SoapWidget>
  )
}

export default QuestionsWidgetUnlocked
