import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useBeforeUnload = (getOverrideCustomValue?: () => boolean) => {
  const { t } = useTranslation('Utils')
  const CUSTOM_MESSAGE = t(
    'Utils:BEFORE_UNLOAD.UNFINISHED_ITEMS_CUSTOM_MESSAGE',
  )

  const overrideCustomValue = getOverrideCustomValue?.()
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      event.stopImmediatePropagation()

      if (getOverrideCustomValue?.()) {
        if (event) {
          event.returnValue = CUSTOM_MESSAGE
        }
        return CUSTOM_MESSAGE
      }
      return null
    }
    window.addEventListener('beforeunload', handleBeforeunload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [overrideCustomValue])
}

export default useBeforeUnload
