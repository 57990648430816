import { useDispatch } from 'react-redux'

import { clearLogOutTime, logout } from '~/store/actions/auth'
import { wsDisconnect } from '~/store/duck/websocket'
import { resetGainsightUser } from '~/utils/gainsight'

const usePreLogoutCleanup = () => {
  const dispatch = useDispatch()

  return () => {
    resetGainsightUser()
    dispatch(logout())
    dispatch(wsDisconnect())
    dispatch(clearLogOutTime())
  }
}

export default usePreLogoutCleanup
