import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, Tooltip } from '@mui/material'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { TimetableEvent } from '~/types'

import BusinessShareIcon from '../icons/BusinessShareIcon'

export interface AppointmentTypeLabelProps {
  item: TimetableEvent
}

const AppointmentTypeLabel = ({ item }: AppointmentTypeLabelProps) => {
  const name = LanguageUtils.getTranslatedFieldName(
    item?.businessAppointmentType,
  )

  return (
    <Grid container item wrap="nowrap">
      <Tooltip placement="top" title={name}>
        <Grid item>
          <Dotdotdot clamp={1}>
            <Text strong variant="body2">
              {name}
            </Text>
          </Dotdotdot>
        </Grid>
      </Tooltip>
      <BusinessShareIcon
        businessIds={item.businessId ? [item.businessId] : []}
      />
    </Grid>
  )
}

export default AppointmentTypeLabel
