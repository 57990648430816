import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { AppSelector } from '~/store'
import { TeamMemberLicense } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import License, { LicenseHandle } from './License'

const useStyles = makeStyles(
  (theme) => ({
    dialogContentRoot: {
      padding: theme.spacing(2, 3),
    },
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    button: {
      width: 144,
      height: 40,
    },
  }),
  { name: 'LicenseDialog' },
)

export interface LicenseDialogProps extends BasePuiDialogProps {
  getIsLoading: AppSelector<boolean>
  license?: TeamMemberLicense
  onCreateLicense: (license: TeamMemberLicense) => void
  onEditLicense: (license: TeamMemberLicense) => void
}

const LicenseDialog = ({
  open,
  onClose,
  license,
  getIsLoading,
  onCreateLicense,
  onEditLicense,
}: LicenseDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')
  const isLoading = useSelector(getIsLoading)

  const isCreate = !license

  const licenseRef = useRef<LicenseHandle>(null)

  const setCloseAfterCreationOn = useCloseAfterCreation(onClose, getIsLoading)

  const save = () => {
    if (licenseRef.current?.validate()) {
      const newLicense = licenseRef.current.get()

      setCloseAfterCreationOn()

      if (isCreate) {
        onCreateLicense(newLicense)
      } else {
        onEditLicense(newLicense)
      }
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: save,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          name="btnSaveLicense"
          type="submit"
          onClick={save}
        >
          {isCreate ? t('Common:ADD_ACTION') : t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="license-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() => licenseRef.current?.hasUnsavedChanges() || false}
      open={open}
      title={
        license
          ? t('Admin:MEMBER.LICENSE.ADD_LICENSE_ACTION')
          : t('Admin:MEMBER.LICENSE.EDIT_LICENSE_ACTION')
      }
      onClose={onClose}
    >
      <License license={license} ref={licenseRef} />
    </PuiDialog>
  )
}

export default LicenseDialog
