import React, { HTMLAttributes, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { Autocomplete, FormControl, Grid, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  CopyToClipboard,
  Defaults,
  Field,
  FieldProp,
  PuiTextField,
  PuiTextFieldProps,
  PuiTooltip,
} from '@pbt/pbt-ui-components'
import SelectableMenuItem from '@pbt/pbt-ui-components/src/components/inputs/SelectableMenuItem'

import { SpaceMonitor } from '~/api/graphql/generated/types'
import PuiIconButton from '~/components/common/PuiIconButton'
import {
  fetchAssignedToSpaceMonitors,
  fetchUnassignedMonitors,
  getMonitorsLoading,
  updateMonitorsAssignments,
} from '~/store/duck/monitor'

import useOpenMonitorApp from './hooks/useOpenMonitorApp'
import { Device, DeviceItems, MonitorType } from './monitorTypes'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      padding: theme.spacing(0, 1, 0, 0),
      margin: 0,
      minWidth: 0,
      justifyContent: 'flex-end',
    },
    label: {
      color: 'black',
    },
    icon: {
      color: theme.colors.disabledLabelText,
    },
    iconButton: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }),
  { name: 'SpaceMonitor' },
)

type SpaceMonitorsProps = {
  devices: Device[]
  field: Field
  monitorUrl: Field
  reset: (newFields?: FieldProp[] | undefined) => void
  spaceId: string
  tabletUrl: Field
  updateDisabled: boolean
}

const SpaceMonitors = ({
  field,
  updateDisabled,
  monitorUrl,
  tabletUrl,
  devices,
  spaceId,
  reset,
}: SpaceMonitorsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const openMonitor = useOpenMonitorApp(monitorUrl.value, spaceId)
  const loading = useSelector(getMonitorsLoading)

  const handleChange = useDebouncedCallback(
    (selectedDevices: DeviceItems[]) => {
      if (selectedDevices) {
        const combinedItems = R.pipe(R.pluck('items'), R.flatten)(devices)
        const deviceNames = R.pluck('deviceName', selectedDevices)
        const updatedItems = R.pipe(
          R.filter(
            ({
              name,
              assigned,
            }: SpaceMonitor & {
              id: string
              name: string
              spaceId?: string
            }) =>
              (deviceNames.includes(name) && !assigned) ||
              (!deviceNames.includes(name) && assigned),
          ),
          R.map((itm) =>
            deviceNames.includes(itm.name) && !itm.assigned
              ? { ...itm, assigned: true }
              : { ...itm, assigned: false },
          ),
        )(combinedItems)

        if (!R.isEmpty(updatedItems)) {
          dispatch(
            updateMonitorsAssignments({
              input: R.map(
                (item) => ({
                  ...R.pick(['spaceId', 'token', 'assigned'], item),
                }),
                updatedItems,
              ),
            }),
          )
        }
      }
    },
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  useEffect(() => {
    reset()
  }, [devices])

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: DeviceItems,
    { selected }: any,
  ) => (
    <SelectableMenuItem
      {...props}
      disabled={loading}
      key={option.id}
      name={option.name}
      selected={
        selected ||
        Boolean(
          R.find(
            R.propEq('deviceName', R.prop('deviceName', option)),
            field.value,
          ),
        )
      }
      value={option.name}
    />
  )

  const onChange = (_: any, newValue: DeviceItems[]) => {
    field.set(newValue)
    handleChange(newValue)
  }

  const onOpen = () => {
    dispatch(fetchAssignedToSpaceMonitors(spaceId))
    dispatch(fetchUnassignedMonitors())
  }

  const renderInput = (
    params: Omit<PuiTextFieldProps, 'fullWidth' | 'label' | 'variant'>,
  ) => (
    <PuiTextField
      {...params}
      fullWidth
      label={field.label}
      variant="standard"
    />
  )

  const groupBy = (option: DeviceItems) =>
    R.propEq('monitorType', MonitorType.IN_ROOM, option)
      ? t('Common:IN_ROOM')
      : t('Common:TABLET')

  const isOptionEqualToValue = (option: DeviceItems, value: DeviceItems) =>
    R.propEq('deviceName', R.prop('deviceName', value), option)

  return (
    <Grid container item lg={12} md={12} xs={12}>
      <FormControl fullWidth margin="normal">
        <Autocomplete
          disableClearable
          disableCloseOnSelect
          multiple
          openOnFocus
          getOptionLabel={(option) => R.propOr(option, 'deviceName', option)}
          groupBy={groupBy}
          isOptionEqualToValue={isOptionEqualToValue}
          options={R.flatten(R.pluck('items', devices))}
          renderInput={renderInput}
          renderOption={renderOption}
          value={field.value}
          onChange={onChange}
          onOpen={onOpen}
        />
      </FormControl>
      <PuiTextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PuiTooltip
                tooltipPlacement="top"
                tooltipText={t('Common:OPEN_IN_ROOM_MONITOR')}
              >
                <div>
                  <PuiIconButton
                    Icon={FullscreenIcon}
                    IconProps={{ size: 40 }}
                    classes={{
                      icon: classes.icon,
                      iconButton: classes.iconButton,
                    }}
                    onClick={() => openMonitor({})}
                  />
                </div>
              </PuiTooltip>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <strong className={classes.label}>{monitorUrl.label}</strong>
            </InputAdornment>
          ),
        }}
        disabled={updateDisabled}
        field={monitorUrl}
        id="space-monitor-url"
      />
      <PuiTextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PuiTooltip
                tooltipPlacement="top"
                tooltipText={t('Common:COPY_TABLE_URL')}
              >
                <div>
                  <CopyToClipboard
                    classes={{ link: classes.link }}
                    label={<ContentCopyIcon color="action" />}
                    text={tabletUrl.value}
                  />
                </div>
              </PuiTooltip>
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start" variant="outlined">
              <strong className={classes.label}>{tabletUrl.label}</strong>
            </InputAdornment>
          ),
        }}
        disabled={updateDisabled}
        field={tabletUrl}
        id="space-tablet-url"
      />
    </Grid>
  )
}

export default SpaceMonitors
