import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DocumentFile } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { Document } from '~/types'
import useDialog from '~/utils/useDialog'

import Attachment, { AttachmentProps } from '../attachment/Attachment'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      paddingBottom: theme.spacing(1),
    },
    attachment: {
      backgroundColor: theme.colors.tableOddRowBackground,
      margin: theme.spacing(1, 1, 0),
    },
  }),
  { name: 'RichEditAttachments' },
)

export interface RichEditAttachmentsProps {
  files: DocumentFile['file'][]
  onDeleteFile?: AttachmentProps['onDeleteRequested']
}

const RichEditAttachments = ({
  files,
  onDeleteFile,
}: RichEditAttachmentsProps) => {
  const classes = useStyles()

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  const onPreviewRequested = (file: DocumentFile['file']) => {
    openDocumentDialog({
      previewOnly: true,
      document: file as Document,
      PreviewProps: {
        hideTopButtons: true,
        view: true,
        showChangeFile: false,
      },
      step: DocumentDialogStates.PREVIEW,
    })
  }

  return (
    <Grid container alignItems="flex-end" className={classes.root}>
      {files.map((file, idx) => (
        <Grid item className={classes.attachment} key={file.id || idx}>
          <Attachment
            hideDownload
            file={file}
            key={file.id}
            onDeleteRequested={onDeleteFile}
            onPreviewRequested={onPreviewRequested}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default RichEditAttachments
