import { Problem } from '~/types'

type ProblemKeyFields = Pick<
  Problem,
  'bodySystemId' | 'primaryId' | 'secondaryId' | 'tertiaryId' | 'id' | 'level'
>

export const getProblemKey = ({
  bodySystemId,
  primaryId,
  secondaryId,
  tertiaryId,
  id,
  level,
}: ProblemKeyFields) =>
  `${bodySystemId}-${primaryId ?? 0}-${secondaryId ?? 0}-${
    tertiaryId ?? 0
  }-${id}-${level}`

export const getLogKeyFromCatalog = (value: Problem) =>
  `${value?.bodySystemId}-${value?.id ?? 0}`

export const getLogKeyFromLogs = (id: string, bodySystemId: string) =>
  `${bodySystemId}-${id}`

export const getEnumValueKey = (
  parentEnumValueKey: string | undefined,
  enumValueId: string,
): string =>
  parentEnumValueKey ? `${parentEnumValueKey}-${enumValueId}` : enumValueId
