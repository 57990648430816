import * as R from 'ramda'
import { Task } from 'redux-saga'
import {
  all,
  call,
  cancel,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ImagingOrder, TableFilter } from '~/types'

import { updateSearchHighlights } from '../actions/search'
import {
  actualizeDashboardDetails,
  ASSIGN_ORDER,
  assignOrder,
  assignOrderFailure,
  assignOrderSuccess,
  COMPLETE_ORDER_WITHIN_DASHBOARD,
  completeOrderWithinDashboard,
  EDIT_ORDER_WITHIN_DASHBOARD,
  editOrderWithinDashboard,
  FETCH_DASHBOARD_DETAILS,
  FETCH_DASHBOARD_PAGE,
  FETCH_DASHBOARD_RECORDS,
  fetchDashboardDetails,
  fetchDashboardDetailsFailure,
  fetchDashboardDetailsSuccess,
  fetchDashboardPage,
  fetchDashboardPageFailure,
  fetchDashboardPageSuccess,
  fetchDashboardRecords,
  fetchDashboardRecordsFailure,
  fetchDashboardRecordsSuccess,
  MESSAGE_ASSIGNMENT_SUCCEED,
  REFRESH_DASHBOARD_PAGE,
} from '../duck/imagingDashboard'
import {
  COMPLETE_ORDER,
  completeOrderSaga,
  EDIT_ORDER,
  editOrderSaga,
} from '../duck/imagingOrders'
import { notifyNetworkError, notifyNetworkSuccess } from '../duck/uiAlerts'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

const serializeFilters = (filters: Record<string, TableFilter>) => {
  const filterValues = Object.keys(filters).reduce(
    (acc, key) => ({
      ...acc,
      [key]: filters[key]?.value,
    }),
    {} as Record<string, TableFilter['value']>,
  )
  const [orderFromDate, orderToDate] = (filterValues.date || []) as string[]

  return {
    ...R.omit(['date'], filterValues),
    orderFromDate,
    orderToDate,
  }
}

export function* fetchDashboardPageSagaCancelable({
  search,
  from,
  to,
  filters = {},
  reset,
}: ReturnType<typeof fetchDashboardPage>) {
  try {
    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield* requestAPI(
      API.fetchImagingDashboardPage,
      from,
      to,
      serializeFilters(filters),
      search,
    )
    yield put(updateSearchHighlights(highlights, totalCount))
    yield call(updateEntities, entities)
    yield put(fetchDashboardPageSuccess(list, totalCount, reset))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(fetchDashboardPageFailure(error))
  }
}

export function* fetchDashboardPageSaga(
  params: ReturnType<typeof fetchDashboardPage>,
) {
  const task: Task = yield fork(fetchDashboardPageSagaCancelable, params)
  yield take(REFRESH_DASHBOARD_PAGE)
  yield cancel(task)
}

export function* fetchDashboardDetailsSaga({
  itemId,
}: ReturnType<typeof fetchDashboardDetails>) {
  try {
    const { result: resultId, entities } = yield* requestAPI(
      API.fetchImagingDashboardDetails,
      itemId,
    )
    yield call(updateEntities, entities)
    yield put(fetchDashboardDetailsSuccess(resultId))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(fetchDashboardDetailsFailure(error))
  }
}

export function* fetchDashboardRecordsSaga({
  recordIds,
}: ReturnType<typeof fetchDashboardRecords>) {
  try {
    const { entities } = yield* requestAPI(
      API.fetchImagingDashboardRecords,
      recordIds,
    )
    yield call(updateEntities, entities)
    yield put(fetchDashboardRecordsSuccess())
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(fetchDashboardRecordsFailure(error))
  }
}

export function* assignOrderSaga({
  orderId,
  vendorId,
  eventId,
  vetId,
  selectedClientId,
  selectedPatientId,
}: ReturnType<typeof assignOrder>) {
  try {
    const { entities } = yield* requestAPI(
      API.assignOrderResults,
      orderId,
      vendorId,
      eventId,
      vetId,
      selectedClientId,
      selectedPatientId,
    )
    yield call(updateEntities, entities)
    yield notifyNetworkSuccess(MESSAGE_ASSIGNMENT_SUCCEED)
    yield put(assignOrderSuccess())
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(assignOrderFailure(error))
  }
}

export function* editOrderWithinDashboardSaga({
  order,
  dashboardRecordId,
}: ReturnType<typeof editOrderWithinDashboard>) {
  const updatedOrder: ImagingOrder = yield call(editOrderSaga, {
    type: EDIT_ORDER,
    order,
  })
  yield put(actualizeDashboardDetails(updatedOrder))
  yield put(fetchDashboardRecords([dashboardRecordId]))
}

export function* completeOrderWithinDashboardSaga({
  orderId,
  dashboardRecordId,
}: ReturnType<typeof completeOrderWithinDashboard>) {
  const completedOrder: ImagingOrder = yield call(completeOrderSaga, {
    type: COMPLETE_ORDER,
    orderId,
  })
  yield put(actualizeDashboardDetails(completedOrder))
  yield put(fetchDashboardRecords([dashboardRecordId]))
}

function* watchFetchDashboardPage() {
  yield takeEvery(
    [FETCH_DASHBOARD_PAGE, REFRESH_DASHBOARD_PAGE],
    fetchDashboardPageSaga,
  )
}

function* watchFetchDashboardDetails() {
  yield takeEvery(FETCH_DASHBOARD_DETAILS, fetchDashboardDetailsSaga)
}

function* watchFetchDashboardRecords() {
  yield takeEvery(FETCH_DASHBOARD_RECORDS, fetchDashboardRecordsSaga)
}

function* watchAssignOrder() {
  yield takeLatest(ASSIGN_ORDER, assignOrderSaga)
}

function* watchEditOrderWithinDashboard() {
  yield takeLatest(EDIT_ORDER_WITHIN_DASHBOARD, editOrderWithinDashboardSaga)
}

function* watchCompleteOrderWithinDashboard() {
  yield takeLatest(
    COMPLETE_ORDER_WITHIN_DASHBOARD,
    completeOrderWithinDashboardSaga,
  )
}

export default function* imagingDashboardSaga() {
  yield all([
    watchFetchDashboardPage(),
    watchFetchDashboardDetails(),
    watchFetchDashboardRecords(),
    watchAssignOrder(),
    watchEditOrderWithinDashboard(),
    watchCompleteOrderWithinDashboard(),
  ])
}
