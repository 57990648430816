import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import {
  getAdjustByPercent,
  getAdjustRoundup,
  getAdjustValue,
  getManualPriceChange,
} from '~/store/duck/bulkPrices'

const addPercent = (percent: number) => (value: number) =>
  (value || 0) * (1 + percent / 100)
const addFixed = (fixed: number) => (value: number) => value + fixed

export const useRecalculatePrice = (
  priceId: string | Nil,
  initialPrice: number,
) => {
  const roundup = useSelector(getAdjustRoundup)
  const adjustByPercent = useSelector(getAdjustByPercent)
  const adjustValue = useSelector(getAdjustValue) || 0
  const manualPriceValue = useSelector(getManualPriceChange(priceId))

  const preparedInitialPrice = R.pipe(
    adjustByPercent ? addPercent(adjustValue) : addFixed(adjustValue),
    roundup ? Math.ceil : R.identity,
  )(initialPrice || 0)

  return manualPriceValue ?? preparedInitialPrice
}
