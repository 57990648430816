import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import LabVendorNames from '~/constants/labVendorName'
import { LabTestState } from '~/constants/SOAPStates'
import { useGetLabTestStateId } from '~/store/hooks/orders'
import { getLabOrderStatuses, getLabVendors } from '~/store/reducers/constants'
import { LabOrder, Order } from '~/types'

import { LabTestGroupKeys } from '../../../soap/wrap-up/lab-orders/labTestUtils'
import FinalizeOrdersRow from '../FinalizeOrdersRow'
import { FinalizeLabOrdersActions } from './FinalizeLabOrdersActions'

interface FinalizeLabOrdersRowProps {
  currentOrderLabTestMap: Record<string, Record<string, Order[]>>
  handleCancel: () => void
  handleEdit: () => void
  handlePrintLabel: () => void
  hasIntegrationMap: Record<string, boolean>
  keysToPick: LabTestGroupKeys[]
  order: LabOrder
}

function FinalizeLabOrdersRow({
  order,
  currentOrderLabTestMap,
  keysToPick,
  hasIntegrationMap,
  handleEdit,
  handlePrintLabel,
  handleCancel,
}: FinalizeLabOrdersRowProps) {
  const LabOrdersStatusesList = useSelector(getLabOrderStatuses)

  const LabVendors = useSelector(getLabVendors)
  const getLabTestStateId = useGetLabTestStateId()
  const { t } = useTranslation(['Common'])

  const { id: orderId, labOrderId, statusId, vendorId } = order

  const orderLabTests = currentOrderLabTestMap[vendorId]
    ? orderId
      ? R.pathOr([], [vendorId, orderId], currentOrderLabTestMap)
      : R.pipe(
          R.values,
          R.flatten,
        )(R.pick(keysToPick, currentOrderLabTestMap[vendorId]))
    : []
  const vendorName = LanguageUtils.getConstantTranslatedName(
    vendorId,
    LabVendors,
  )
  const orderStatus = LanguageUtils.getConstantTranslatedName(
    statusId,
    LabOrdersStatusesList,
  )

  const hasIntegration = hasIntegrationMap[vendorId]
  const isInternalVendor = vendorName === LabVendorNames.INTERNAL

  const declineLabel = `(${t('Common:DECLINED')})`
  const orderNameDetails =
    orderLabTests.length > 0
      ? orderLabTests.map((orderLabTest: Order) => {
          if (orderLabTest.stateId === getLabTestStateId(LabTestState.DECLINED))
            return {
              orderName: `${orderLabTest.name} ${declineLabel}`,
              softText: true,
            }
          return { orderName: orderLabTest.name, softText: false }
        })
      : undefined

  return (
    <FinalizeOrdersRow
      actions={
        <FinalizeLabOrdersActions
          handleCancel={handleCancel}
          handleEdit={handleEdit}
          handlePrintLabel={handlePrintLabel}
          order={order}
          orderLabTests={orderLabTests}
          vendorId={vendorId}
        />
      }
      labOrderId={labOrderId}
      orderNameDetails={orderNameDetails}
      showWarning={!hasIntegration && !isInternalVendor}
      statusName={orderStatus}
      vendorName={vendorName}
    />
  )
}

export default FinalizeLabOrdersRow
