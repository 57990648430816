import React, { forwardRef, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  NamedEntity,
  PuiSelect,
  PuiTextField,
  PuiTextFieldProps,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import { getAgeUnits } from '~/store/reducers/constants'
import {
  getOneTimeUnitPluralized,
  getSingularOrPluralTimeUnits,
} from '~/utils/time'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import AgeInput from './AgeInput'
import SelectorPopper from './time-selector/SelectorPopper'

const useStyles = makeStyles(
  (theme) => ({
    selectContainer: {
      minWidth: 124,
      marginBottom: theme.spacing(0.5),
    },
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
    clearButton: {
      cursor: 'pointer',
      paddingRight: theme.spacing(1),
    },
  }),
  { name: 'AgeRangeSelector' },
)

export type AgeRangeSelectorProps = PuiTextFieldProps & {
  disabled?: boolean
  endUnit?: string
  endValue?: number
  fromLabel?: string
  hasClearButton?: boolean
  minValue?: number
  onClose?: () => void
  onEndChange?: (value?: number) => void
  onEndUnitChange?: (value: string) => void
  onOpen?: () => void
  onStartChange: (value?: number) => void
  onStartUnitChange: (value: string) => void
  range?: boolean
  startUnit: string
  startValue: number
  toLabel?: string
}

const AgeRangeSelector = forwardRef<HTMLDivElement, AgeRangeSelectorProps>(
  function AgeRangeSelector(
    {
      disabled,
      startValue,
      endValue,
      startUnit,
      endUnit,
      fromLabel,
      toLabel,
      onStartChange,
      onStartUnitChange,
      onEndChange,
      onEndUnitChange,
      range = true,
      minValue = 0,
      hasClearButton,
      onOpen = R.F,
      onClose = R.F,
      ...rest
    },
    ref,
  ) {
    const classes = useStyles()
    const AgeUnits: NamedEntity[] = useSelector(getAgeUnits)
    const { t } = useTranslation('Common')

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>()

    const selectorRef = useRef<HTMLDivElement>(null)

    const pluralAgeUnits = getSingularOrPluralTimeUnits(AgeUnits)
    const defaultAgeUnit = pluralAgeUnits[0]?.id

    const handleClose = (event?: React.KeyboardEvent<HTMLDivElement>) => {
      const target = event?.target as Node
      if (
        target &&
        selectorRef.current &&
        selectorRef.current.contains(target)
      ) {
        return
      }
      setAnchorEl(undefined)
    }

    const clearRange = () => {
      onStartChange(undefined)
      if (onEndChange) {
        onEndChange(undefined)
      }
      handleClose()
    }

    useEffectExceptOnMount(() => {
      if (anchorEl) {
        onOpen()
      } else {
        onClose()
      }
    }, [anchorEl])

    const startUnitName = Utils.getConstantName(
      startUnit,
      AgeUnits,
      '',
    ).toLowerCase()
    const endUnitName = Utils.getConstantName(
      endUnit,
      AgeUnits,
      '',
    ).toLowerCase()

    const startFormatted = R.is(Number, startValue)
      ? t('Common:INPUTS.AGE_RANGE.START_AGE', {
          startValue,
          startUnitName: getOneTimeUnitPluralized(startUnitName, startValue),
        })
      : ''
    const endFormatted = R.is(Number, endValue)
      ? ` ${t('Common:INPUTS.AGE_RANGE.END_AGE', {
          endValue,
          endUnitName: getOneTimeUnitPluralized(endUnitName, endValue),
        })}`
      : ''

    const formattedAge = startFormatted + endFormatted

    const onStartValueChange = (value: number) => {
      onStartChange(value)
      if (!startUnit) {
        onStartUnitChange(defaultAgeUnit)
      }
    }

    const onEndValueChange = (value: number) => {
      if (onEndChange) {
        onEndChange(value)
      }

      if (!endUnit && onEndUnitChange) {
        onEndUnitChange(defaultAgeUnit)
      }
    }

    return (
      <>
        <PuiTextField
          shrink
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Toggle className={classes.toggleIcon} />
              </InputAdornment>
            ),
          }}
          disabled={disabled}
          ref={ref}
          value={formattedAge || t('Common:ALL')}
          onClick={({ currentTarget }) =>
            !disabled && setAnchorEl(currentTarget)
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleClose()
            }
          }}
          {...rest}
        />
        <SelectorPopper
          BottomControls={
            hasClearButton && (
              <Grid container justifyContent="flex-end">
                <Text
                  className={classes.clearButton}
                  variant="caption"
                  onClick={clearRange}
                >
                  {t('Common:INPUTS.AGE_RANGE.CLEAR_AGE_RANGE')}
                </Text>
              </Grid>
            )
          }
          FromInput={
            <Grid item xs>
              <AgeInput
                minValue={minValue}
                value={startValue}
                onChange={onStartValueChange}
              />
            </Grid>
          }
          FromSelect={
            <PuiSelect
              input={<Input id="age-unit-start-select" />}
              items={pluralAgeUnits}
              renderEmpty={false}
              value={startUnit || defaultAgeUnit}
              onChange={Utils.handleFormSelectInput(onStartUnitChange)}
            />
          }
          ToInput={
            <Grid item xs>
              <AgeInput
                minValue={minValue}
                value={endValue}
                onChange={onEndValueChange}
              />
            </Grid>
          }
          ToSelect={
            <PuiSelect
              input={<Input id="age-unit-end-select" />}
              items={pluralAgeUnits}
              renderEmpty={false}
              value={endUnit || defaultAgeUnit}
              onChange={
                onEndUnitChange
                  ? Utils.handleFormSelectInput(onEndUnitChange)
                  : undefined
              }
            />
          }
          anchorEl={anchorEl}
          classes={{
            selectContainer: classes.selectContainer,
          }}
          fromLabel={fromLabel || (range && t('Common:INPUTS.AGE_RANGE.FROM'))}
          open={Boolean(anchorEl)}
          range={range}
          ref={selectorRef}
          toLabel={toLabel || t('Common:INPUTS.AGE_RANGE.TO')}
          onClose={handleClose}
        />
      </>
    )
  },
)

export default AgeRangeSelector
