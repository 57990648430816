import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddButton,
  ClassesType,
  Defaults,
  LanguageUtils,
  moment,
  Nil,
  TextInteractive,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { fetchAppointment } from '~/store/actions/timetable'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { TimetableEvent } from '~/types'
import { addOriginalBusinessId } from '~/utils'

import Link from '../link/Link'

const useStyles = makeStyles(
  (theme) => ({
    appointmentInfo: {
      border: theme.constants.tableBorder,
    },
    editIcon: {
      color: theme.colors.editIconColor,
      padding: theme.spacing(0.5),
    },
    addText: {
      fontWeight: 400,
    },
    link: {
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'AddToAppointmentCard' },
)

export interface AddToAppointmentCardProps {
  appointmentId?: string | Nil
  appointmentProp?: TimetableEvent
  classes?: ClassesType<typeof useStyles>
  onAddToAppointmentRequested: () => void
  onEdit?: () => void
  readOnly?: boolean
}

const AddToAppointmentCard = ({
  appointmentProp,
  appointmentId,
  classes: classesProp,
  onAddToAppointmentRequested,
  onEdit,
  readOnly,
}: AddToAppointmentCardProps) => {
  const dispatch = useDispatch()
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const eventId = appointmentId || appointmentProp?.id
  const appointmentFromState = useSelector(getTimetableEvent(appointmentId))
  const appointment = appointmentProp || appointmentFromState
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const appointmentTypeName = LanguageUtils.getTranslatedFieldName(
    appointment?.businessAppointmentType,
  )

  const isLoading = appointmentId !== undefined && !appointmentFromState

  useEffect(() => {
    if (isLoading && appointmentId) {
      dispatch(fetchAppointment(appointmentId))
    }
  }, [isLoading])

  const soaps = appointment?.soaps || []
  const firstSoapId = soaps.length > 0 ? soaps[0].id : undefined

  const text = `${appointmentTypeName} ${moment(
    appointment?.scheduledStartDatetime,
  ).format(Defaults.DATE_FORMAT)}`
  const soapLink = addOriginalBusinessId(
    `/soap/${firstSoapId}`,
    isPatientSharingEnabled ? appointment?.businessId : null,
  )

  return (
    <Grid container item>
      {eventId ? (
        <Grid
          container
          item
          alignItems="center"
          className={classes.appointmentInfo}
          px={1}
          py={0.5}
        >
          <Grid item xs>
            <TextInteractive isLoading={isLoading}>
              {t('Common:ADDED_TO')}:{' '}
              {firstSoapId ? (
                <Link className={classes.link} to={soapLink}>
                  {text}
                </Link>
              ) : (
                text
              )}
            </TextInteractive>
          </Grid>
          {!readOnly && (
            <IconButton
              className={classes.editIcon}
              disabled={isLoading}
              size="large"
              onClick={onEdit}
            >
              <EditIcon />
            </IconButton>
          )}
        </Grid>
      ) : readOnly ? null : (
        <AddButton
          addText={t('Common:ADD_TO_APPOINTMENT')}
          classes={{ addText: classes.addText }}
          onAdd={onAddToAppointmentRequested}
        />
      )}
    </Grid>
  )
}

export default AddToAppointmentCard
