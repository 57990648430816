import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import {
  BasePuiDialogProps,
  DateUtils,
  Defaults,
  Nil,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import EmailDialogWithSections from '~/components/common/dialog/email/EmailDialogWithSections'
import EmailAppointmentFlow from '~/constants/EmailAppointmentFlow'
import {
  clearEmailAppointmentConfig,
  clearEmailAppointmentTemplate,
  emailAppointment,
  emailAppointmentConfirmation,
  emailZoomLink,
  fetchEmailAppointmentConfig,
  fetchEmailAppointmentConfirmationConfig,
  fetchEmailAppointmentConfirmationTemplate,
  fetchEmailAppointmentTemplate,
  fetchEmailZoomLinkConfig,
  fetchEmailZoomLinkTemplate,
  getEmailAppointmentConfig,
  getEmailAppointmentIsLoading,
  getEmailAppointmentIsSendingEmail,
  getEmailAppointmentTemplate,
  getEmailAppointmentTemplateIsReceiving,
} from '~/store/duck/emailAppointment'
import { getPatient } from '~/store/reducers/patients'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { EmailEntityConfig, TimetableEvent } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

interface EmailAppointmentDialogProps extends BasePuiDialogProps {
  appointment?: TimetableEvent | Nil
  appointmentId: string | Nil
  flow?: EmailAppointmentFlow
  onViewConversation?: () => void
}

const EmailAppointmentDialog = ({
  open,
  onClose,
  onViewConversation,
  appointmentId,
  appointment: appointmentProp,
  flow = EmailAppointmentFlow.INFO,
}: EmailAppointmentDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const EMAIL_APPOINTMENT_INFO_FLOW_CONFIG = {
    fetchConfigAction: fetchEmailAppointmentConfig,
    fetchTemplateAction: fetchEmailAppointmentTemplate,
    emailAction: emailAppointment,
    dialogTitle: t('Dialogs:EMAIL_APPOINTMENT.TITLE'),
  }

  const configByFlow = {
    [EmailAppointmentFlow.CONFIRMATION]: {
      fetchConfigAction: fetchEmailAppointmentConfirmationConfig,
      fetchTemplateAction: fetchEmailAppointmentConfirmationTemplate,
      emailAction: emailAppointmentConfirmation,
      dialogTitle: t('Dialogs:EMAIL_APPOINTMENT.CONFIRMATION_TITLE'),
    },
    [EmailAppointmentFlow.ZOOM_LINK]: {
      fetchConfigAction: fetchEmailZoomLinkConfig,
      fetchTemplateAction: fetchEmailZoomLinkTemplate,
      emailAction: emailZoomLink,
      dialogTitle: t('Dialogs:EMAIL_APPOINTMENT.ZOOM_LINK_TITLE'),
    },
    [EmailAppointmentFlow.INFO]: EMAIL_APPOINTMENT_INFO_FLOW_CONFIG,
  }

  const config = useSelector(getEmailAppointmentConfig)
  const emailTemplate = useSelector(getEmailAppointmentTemplate)
  const templateIsReceiving = useSelector(
    getEmailAppointmentTemplateIsReceiving,
  )
  const appointment =
    useSelector(getTimetableEvent(appointmentId)) || appointmentProp
  const patient = useSelector(getPatient(appointment?.patient))
  const configIsLoading = useSelector(getEmailAppointmentIsLoading)

  const allowedTransports = Object.values(ConversationTransport)

  const flowConfig = configByFlow[flow] || EMAIL_APPOINTMENT_INFO_FLOW_CONFIG

  const date =
    appointment?.scheduledStartDatetime &&
    DateUtils.formatDate(appointment.scheduledStartDatetime)
  const subjectDate = date
    ? t('Dialogs:EMAIL_APPOINTMENT.ON_DATE', { date })
    : ''

  const defaultSubject = t('Dialogs:EMAIL_APPOINTMENT.DEFAULT_SUBJECT', {
    patientName: patient?.name || t('Common:PATIENT').toLowerCase(),
    date: subjectDate,
  })

  const fetchConfig = (transport?: ConversationTransport) => {
    if (appointmentId) {
      dispatch(flowConfig.fetchConfigAction(appointmentId, transport))
    }
  }

  useEffect(() => {
    if (open && !config) {
      fetchConfig()
    }
  }, [open, config])

  const handleClose = () => {
    dispatch(clearEmailAppointmentTemplate())
    dispatch(clearEmailAppointmentConfig())
    if (onClose) {
      onClose()
    }
  }

  const fetchEmailTemplate = (newConfig: EmailEntityConfig | Nil) => {
    if (open && newConfig?.sections && appointmentId) {
      dispatch(flowConfig.fetchTemplateAction(appointmentId, newConfig))
    }
  }

  const fetchEmailTemplateDebounced = useDebouncedCallback(
    fetchEmailTemplate,
    Defaults.DEBOUNCE_ACTION_TIME,
  )
  const fetchEmailTemplateWhenConfigWillBeLoaded = useCloseAfterCreation(
    () => fetchEmailTemplate(config),
    getEmailAppointmentIsLoading,
  )

  const handleConfigChange = (newConfig: EmailEntityConfig) => {
    if (!open) {
      return
    }

    const isTransportUpdated = config?.transport !== newConfig?.transport
    if (isTransportUpdated) {
      fetchEmailTemplateWhenConfigWillBeLoaded()
      fetchConfig(newConfig?.transport)
    } else {
      fetchEmailTemplateDebounced(newConfig)
    }
  }

  const handleSendEmail = (configToSend: EmailEntityConfig) => {
    if (appointmentId) {
      dispatch(flowConfig.emailAction(appointmentId, configToSend))
    }
  }

  return (
    <EmailDialogWithSections
      allowedTransports={allowedTransports}
      cardEntityName={t('Common:APPOINTMENT_ONE').toLowerCase()}
      clientId={appointment?.client}
      config={config}
      configIsLoading={configIsLoading}
      defaultSubject={defaultSubject}
      dialogHeader={flowConfig.dialogTitle}
      emailOnlyTransport={false}
      emailTemplate={emailTemplate}
      eventId={appointmentId}
      isSendingEmailSelector={getEmailAppointmentIsSendingEmail}
      open={open}
      patient={patient}
      templateIsReceiving={templateIsReceiving || configIsLoading}
      onChange={handleConfigChange}
      onClearPrint={clearEmailAppointmentTemplate}
      onClose={handleClose}
      onSendEmail={handleSendEmail}
      onViewConversation={onViewConversation}
    />
  )
}

export default EmailAppointmentDialog
