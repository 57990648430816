import { useSelector } from 'react-redux'
import {
  AppointmentEventType,
  EventTypeAppointmentRole,
  EventTypeName,
} from '@pbt/pbt-ui-components'

import RoleName, { StaffRoleWhitelist } from '~/constants/roleNames'
import { useEventType } from '~/utils/useEventType'

import { getAppointmentType } from '../reducers/appointmentTypes'

export const useGetAppointmentStaffRoles = (
  appointmentTypeId: string | undefined,
) => {
  const appointment = useSelector(getAppointmentType(appointmentTypeId))

  const AppointmentEventSubTypes: AppointmentEventType['subTypes'] =
    useEventType(EventTypeName.Appointment, 'subTypes')

  if (!appointmentTypeId) {
    return []
  }

  const allRoles: EventTypeAppointmentRole[] =
    AppointmentEventSubTypes.find(
      (subType) => subType.id === appointment?.eventTypeId,
    )?.roles || []

  return allRoles.filter(({ name }) => StaffRoleWhitelist.has(name as RoleName))
}

export const useGetAppointmentResponsibilities = (
  appointmentTypeId: string | undefined,
) => {
  const appointment = useSelector(getAppointmentType(appointmentTypeId))

  const AppointmentEventSubTypes: AppointmentEventType['subTypes'] =
    useEventType(EventTypeName.Appointment, 'subTypes')

  if (!appointmentTypeId) {
    return []
  }

  return (
    AppointmentEventSubTypes.find(
      (subType) => subType.id === appointment?.eventTypeId,
    )?.responsibilities || []
  )
}
