import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getProcedureCategory } from '~/store/reducers/constants'

export interface ProceduresCategoryFilterProps
  extends Omit<CheckboxListFilterProps, 'arrayFormat'> {}

const ProceduresCategoryFilter = (props: ProceduresCategoryFilterProps) => {
  const ProcedureCategory = useSelector(getProcedureCategory)
  return <CheckboxListFilter arrayFormat items={ProcedureCategory} {...props} />
}

export default ProceduresCategoryFilter
