import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, PuiCheckbox } from '@pbt/pbt-ui-components'

export interface SaveAsDefaultFieldProps {
  field: Field
}

const SaveAsDefaultField = ({ field }: SaveAsDefaultFieldProps) => {
  const { t } = useTranslation('Common')

  return <PuiCheckbox field={field} label={t('Common:SAVE_AS_DEFAULT')} />
}

export default SaveAsDefaultField
