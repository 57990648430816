import { gql } from '@apollo/client/core'

export const LINK_CHEWY_PETS = gql`
  mutation LinkChewyPets(
    $clientId: ID!
    $petPatientMatchInput: [PetProfileMatchInput!]!
  ) {
    linkChewyPets(clientId: $clientId, petPatientMatches: $petPatientMatchInput)
  }
`

export const LINK_CHEWY_USER = gql`
  mutation LinkChewyUser($businessId: ID!, $clientId: ID!, $email: String!) {
    linkChewyUser(businessId: $businessId, clientId: $clientId, email: $email)
  }
`
