import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty } from 'ramda'
import { TextInteractive } from '@pbt/pbt-ui-components'

import ImageLogo from '~/components/common/logos/ImageLogo'
import { MarketplaceItem } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    logo: {
      margin: theme.spacing(1, 2, 1, 0),
    },
  }),
  { name: 'MarketplaceItemLogoCell' },
)

const MarketplaceItemLogoCell = (item: MarketplaceItem) => {
  const classes = useStyles()
  const { logoUrl, name } = item
  const isLoading = isEmpty(item)

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <ImageLogo
        className={classes.logo}
        isLoading={isLoading}
        logo={logoUrl}
        name={name}
      />
      <TextInteractive strong isLoading={isLoading}>
        {name}
      </TextInteractive>
    </Grid>
  )
}

export default MarketplaceItemLogoCell
