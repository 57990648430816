import { ApiError, User } from '@pbt/pbt-ui-components'

import { FetchSupportUsersOptions } from '~/types'

import {
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST,
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_SUCCESS,
  FETCH_SUPPORT_USERS_LIST,
  FETCH_SUPPORT_USERS_LIST_FAILURE,
  FETCH_SUPPORT_USERS_LIST_SUCCESS,
  UPDATE_SUPPORT_USER_ROLES,
  UPDATE_SUPPORT_USER_ROLES_FAILURE,
  UPDATE_SUPPORT_USER_ROLES_SUCCESS,
} from './types/supportUsers'

export const fetchSupportUsersList = (options: FetchSupportUsersOptions) => ({
  type: FETCH_SUPPORT_USERS_LIST,
  ...options,
})

export const fetchSupportUsersListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_SUPPORT_USERS_LIST_SUCCESS,
  list,
  totalCount,
})

export const fetchSupportUsersListFailure = (error: ApiError) => ({
  type: FETCH_SUPPORT_USERS_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForSupportUsersList = (
  options: FetchSupportUsersOptions,
) => ({
  type: FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST,
  ...options,
})

export const fetchMoreItemsForSupportUsersListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})

export const fetchMoreItemsForSupportUsersListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_FAILURE,
  error,
})

export const updateSupportUserRoles = (
  personId: string,
  businessToRoleList: User['businessToRoleList'],
) => ({
  type: UPDATE_SUPPORT_USER_ROLES,
  personId,
  businessToRoleList,
})

export const updateSupportUserRolesSuccess = () => ({
  type: UPDATE_SUPPORT_USER_ROLES_SUCCESS,
})

export const updateSupportUserRolesFailure = (error: ApiError) => ({
  type: UPDATE_SUPPORT_USER_ROLES_FAILURE,
  error,
})
