import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { CurrencyTextField, NumberUtils } from '@pbt/pbt-ui-components'

import ApplierPopper from '~/components/common/inputs/ApplierPopper'
import PercentTextField from '~/components/common/inputs/PercentTextField'
import { Invoice } from '~/types'
import { handleNumberInput } from '~/utils'

import { getIsBatchInvoice } from '../../../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    noBorderCell: {
      borderRight: 'none !important',
    },
    amountCell: {
      minWidth: 85,
    },
  }),
  { name: 'DiscountCellSubHead' },
)

export interface DiscountCellSubHeadProps {
  className?: string
  invoice: Invoice
  onPopperApplyToAll: (fieldName: string, value: number) => void
  onPopperApplyToTotal: (amount: number) => void
  onPopperClose: (event: React.MouseEvent<HTMLDivElement>) => void
  openPopperId: string | null
  toggleApplierPopperOpen: (event: React.MouseEvent<HTMLDivElement>) => void
}

const DiscountCellSubHead = ({
  openPopperId,
  invoice,
  className,
  onPopperApplyToAll,
  onPopperApplyToTotal,
  onPopperClose,
  toggleApplierPopperOpen,
}: DiscountCellSubHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const [discountAmount, setDiscountAmount] = useState<number>(
    invoice.additionalDiscount,
  )
  const [discountPercent, setDiscountPercent] = useState<number>(0)

  const isBatchInvoice = getIsBatchInvoice(invoice)

  useEffect(() => {
    setDiscountAmount(invoice.additionalDiscount)
  }, [invoice.additionalDiscount])

  return (
    <>
      <ApplierPopper
        Component={
          <PercentTextField
            fullWidth
            placeholder={t('Invoices:TABLE.SUB_HEAD.PERCENT_DISCOUNT')}
            value={discountPercent || ''}
            onChange={setDiscountPercent}
          />
        }
        applyLabel={t('Common:APPLY_TO_ALL')}
        condition={!isBatchInvoice}
        id="discount-percent-cell"
        open={openPopperId === 'discount-percent-cell'}
        onApply={() =>
          onPopperApplyToAll('discountPerc', (discountPercent || 0) / 100)
        }
        onClick={toggleApplierPopperOpen}
        onClose={onPopperClose}
      >
        <TableCell className={classNames(className, classes.noBorderCell)}>
          %
        </TableCell>
      </ApplierPopper>
      <ApplierPopper
        Component={
          <CurrencyTextField
            placeholder={t('Invoices:TABLE.SUB_HEAD.DISCOUNT_AMOUNT', {
              currencySymbol: NumberUtils.getCurrencySymbol(),
            })}
            value={discountAmount || ''}
            onChange={handleNumberInput(setDiscountAmount, 7, 2, true)}
          />
        }
        applyLabel={t('Common:APPLY_TO_ALL')}
        condition={!isBatchInvoice}
        id="discount-amount-cell"
        open={openPopperId === 'discount-amount-cell'}
        onApply={() => onPopperApplyToTotal(discountAmount || 0)}
        onClick={toggleApplierPopperOpen}
        onClose={onPopperClose}
      >
        <TableCell
          align="right"
          className={classNames(className, classes.amountCell)}
        >
          {t('Common:AMOUNT')}
        </TableCell>
      </ApplierPopper>
    </>
  )
}

export default DiscountCellSubHead
