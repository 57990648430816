import React from 'react'
import { useSelector } from 'react-redux'
import { Constant } from '@pbt/pbt-ui-components'

import TimeUnitsSelect, {
  TimeUnitsSelectProps,
} from '~/components/common/TimeUnitsSelect'
import { getTaskTimeUnits } from '~/store/reducers/constants'

const TaskTimeUnitsSelect = ({ amount, ...rest }: TimeUnitsSelectProps) => {
  const TaskTimeUnits: Constant[] = useSelector(getTaskTimeUnits)

  return (
    <TimeUnitsSelect amount={Number(amount)} items={TaskTimeUnits} {...rest} />
  )
}

export default TaskTimeUnitsSelect
