import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getInventoryUploadItemMatchReasons } from '~/store/reducers/constants'

const SourceCell = ({ sourceId }: { sourceId: string }) => {
  const inventoryUploadItemMatchReasons = useSelector(
    getInventoryUploadItemMatchReasons,
  )

  return LanguageUtils.getConstantTranslatedName(
    sourceId,
    inventoryUploadItemMatchReasons,
  )
}

export default SourceCell
