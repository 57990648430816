import React from 'react'

import InputWithArrows from './InputWithArrows'

export interface AgeInputProps {
  minValue?: number
  onChange: (value: number) => void
  value?: string | number
}

const AgeInput = ({ value, minValue = 0, onChange }: AgeInputProps) => {
  const numberValue = value ? Number(value) : minValue
  const increment = () => onChange(numberValue + 1)
  const decrement = () => onChange(Math.max(numberValue - 1, minValue))

  const onTextFieldChange = (newValue: string) => {
    onChange(Number(newValue || minValue))
  }

  return (
    <InputWithArrows
      decrement={decrement}
      increment={increment}
      value={value}
      onTextFieldChange={onTextFieldChange}
    />
  )
}

export default AgeInput
