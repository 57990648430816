import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { renderMultiPathRoute } from '../routes/utils'
import CreateWellnessPlanVersionPage from './CreateWellnessPlanVersionPage'
import WellnessPlansListPage from './WellnessPlansListPage'

const WellnessPlansPage = () => (
  <Routes>
    {/* root path: /admin/catalog/wellness-plans */}
    {renderMultiPathRoute({
      path: [
        'clone/:wellnessPlanVersionId',
        'edit/:wellnessPlanVersionId',
        'create',
      ],
      element: <CreateWellnessPlanVersionPage />,
    })}
    {renderMultiPathRoute({
      path: [':wellnessPlanVersionId', '/'],
      element: <WellnessPlansListPage />,
    })}
    <Route element={<Navigate replace to="/" />} path="*" />
  </Routes>
)

export default WellnessPlansPage
