import React from 'react'
import { Grid, GridDirection } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Property } from 'csstype'
import { LanguageUtils, NamedEntity, Text } from '@pbt/pbt-ui-components'

import { Order } from '~/types'

import OrderMembershipIcon from '../../order/OrderMembershipIcon'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      position: 'relative',
      padding: theme.spacing(1, 2, 2, 0),
    },
    clickableTitle: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    titleContainer: {
      position: 'relative',
    },
    membershipIcon: {
      position: 'absolute',
      top: 0,
      left: -28,
    },
    actionsContainer: {
      flex: 1,
      minWidth: 112,
      maxWidth: 116,
    },
  }),
  { name: 'ItemWithBadges' },
)

export interface ItemWithBadgesProps {
  actions: React.ReactNode
  alignItems?: Property.AlignItems
  buttonSection?: React.ReactNode
  children?: React.ReactNode
  direction?: GridDirection
  onNameClick?: () => void
  order: Order | NamedEntity
}

const ItemWithBadges = ({
  children,
  onNameClick,
  order,
  actions,
  buttonSection,
  direction = 'row',
  alignItems = 'center',
}: ItemWithBadgesProps) => {
  const classes = useStyles()

  const getId = (arg: typeof order) => ('logId' in arg ? arg.logId! : arg.id)

  return (
    <div className={classes.root} id={getId(order)}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        wrap="nowrap"
      >
        <Grid item className={classes.titleContainer}>
          <OrderMembershipIcon
            className={classes.membershipIcon}
            order={order as Order}
          />
          <Text
            className={classNames(onNameClick && classes.clickableTitle)}
            variant="h5"
            onClick={onNameClick || (() => {})}
          >
            {LanguageUtils.getTranslatedFieldName(order)}
          </Text>
        </Grid>
        <Grid container item className={classes.actionsContainer}>
          {actions}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={alignItems}
        direction={direction}
        wrap="nowrap"
      >
        {children}
      </Grid>
      {buttonSection}
    </div>
  )
}

export default ItemWithBadges
