import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      padding: theme.spacing(0, 2),
      marginTop: theme.spacing(2),
    },
    topSeparator: {
      borderTop: theme.constants.tableBorder,
    },
    titleContainer: {
      paddingTop: theme.spacing(2),
    },
  }),
  { name: 'SectionWithTitle' },
)

export interface SectionWithTitleProps {
  children: React.ReactNode
  classes?: ClassesType<typeof useStyles>
  showTitle?: boolean
  showTopSeparator?: boolean
  title: string
}

const SectionWithTitle = ({
  showTitle = true,
  showTopSeparator = true,
  title,
  classes: classesProp,
  children,
}: SectionWithTitleProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      item
      className={classNames(classes.root, {
        [classes.topSeparator]: showTopSeparator,
      })}
    >
      {showTitle && (
        <Grid item className={classes.titleContainer}>
          <Text variant="h4">{title}</Text>
        </Grid>
      )}
      <Grid container item direction="column">
        {children}
      </Grid>
    </Grid>
  )
}

export default SectionWithTitle
