import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import { fetchPausedPatientMembershipsList } from '~/api'

import {
  fetchPausedPatientMemberships,
  fetchPausedPatientMembershipsFailure,
  fetchPausedPatientMembershipsSuccess,
} from '../duck/pausedPatientMemberships'
import requestAPI from './utils/requestAPI'

export function* fetchPausedPatientMembershipsSaga({
  payload,
}: ReturnType<typeof fetchPausedPatientMemberships>) {
  try {
    const result = yield* requestAPI(
      fetchPausedPatientMembershipsList,
      payload.clientId,
    )

    yield put(fetchPausedPatientMembershipsSuccess(result))
  } catch (error) {
    yield put(
      fetchPausedPatientMembershipsFailure({ error: error as ApiError }),
    )
  }
}

function* watchFetchPausedPatientMemberships() {
  yield takeLatest(
    fetchPausedPatientMemberships.type,
    fetchPausedPatientMembershipsSaga,
  )
}

export default function* pausedPatientMembershipsSaga() {
  yield all([watchFetchPausedPatientMemberships()])
}
