import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import { MigrationSteps } from '~/constants/migrationV2'
import i18n from '~/locales/i18n'
import { resestMigrationState } from '~/store/duck/migrationV2'

import DataStandardization from '../steps/DataStandardization'
import ImportRequestOverview from '../steps/ImportRequestOverview'
import ImportRequestPicker from '../steps/ImportRequestPicker'
import ImportTaskCreation from '../steps/ImportTaskCreation'
import SessionCovetrusConfig from '../steps/SessionCovetrusConfig'
import SessionCreation from '../steps/SessionCreation'
import SessionFilesUpload from '../steps/SessionFilesUpload'
import SessionFilesView from '../steps/SessionFilesView'
import SessionOverview from '../steps/SessionOverview'
import SessionPicker from '../steps/SessionPicker'
import {
  MigrationStep,
  useMigrationStepController,
} from './migration-step/useMigrationStepController'

const MigrationStepToComponent = {
  [MigrationSteps.SESSION_PICKER]: SessionPicker,
  [MigrationSteps.SESSION_CREATION]: SessionCreation,
  [MigrationSteps.SESSION_OVERVIEW]: SessionOverview,
  [MigrationSteps.COVETRUS_CONFIG]: SessionCovetrusConfig,
  [MigrationSteps.SESSION_FILE_VIEW]: SessionFilesView,
  [MigrationSteps.SESSION_FILE_UPLOAD]: SessionFilesUpload,
  [MigrationSteps.IMPORT_REQUEST_PICKER]: ImportRequestPicker,
  [MigrationSteps.IMPORT_REQUEST_OVERVIEW]: ImportRequestOverview,
  [MigrationSteps.IMPORT_TASK_CREATION]: ImportTaskCreation,
  [MigrationSteps.DATA_STANDARDIZATION]: DataStandardization,
}

const MigrationStepToTitle = {
  [MigrationSteps.SESSION_PICKER]: i18n.t(
    'Businesses:MIGRATION.SELECT_SESSION',
  ),
  [MigrationSteps.SESSION_CREATION]: i18n.t(
    'Businesses:MIGRATION.CREATE_NEW_SESSION',
  ),
  [MigrationSteps.SESSION_OVERVIEW]: i18n.t(
    'Businesses:MIGRATION.SESSION_OVERVIEW',
  ),
  [MigrationSteps.COVETRUS_CONFIG]: i18n.t(
    'Businesses:MIGRATION.COVETRUS_CONFIG',
  ),
  [MigrationSteps.SESSION_FILE_VIEW]: i18n.t(
    'Businesses:MIGRATION.SESSION_FILES_VIEW_AND_EDIT',
  ),
  [MigrationSteps.SESSION_FILE_UPLOAD]: i18n.t(
    'Businesses:MIGRATION.UPLOAD_ADDITIONAL_FILES',
  ),
  [MigrationSteps.IMPORT_REQUEST_PICKER]: i18n.t(
    'Businesses:MIGRATION.SELECT_IMPORT_REQUEST',
  ),
  [MigrationSteps.IMPORT_REQUEST_OVERVIEW]: i18n.t(
    'Businesses:MIGRATION.IMPORT_REQUEST_OVERVIEW',
  ),
  [MigrationSteps.IMPORT_TASK_CREATION]: i18n.t(
    'Businesses:MIGRATION.CREATE_IMPORT_TASK',
  ),
  [MigrationSteps.DATA_STANDARDIZATION]: i18n.t(
    'Businesses:MIGRATION.DATA_STANDARDIZATION',
  ),
}

const useStyles = makeStyles({
  content: {
    position: 'relative',
  },
})

interface MigrationV2DialogProps extends BasePuiDialogProps {
  businessId: string
  steps: MigrationStep[]
}

const MigrationV2Dialog = ({
  businessId,
  open,
  onClose,
  steps,
}: MigrationV2DialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const stepController = useMigrationStepController(steps)

  const handleClose = useCallback(() => {
    dispatch(resestMigrationState())
    if (onClose) {
      onClose()
    }
  }, [onClose])

  // Close if there is no set migration step
  useEffect(() => {
    if (open && !stepController.currentStep) {
      handleClose()
    }
  }, [stepController.currentStep])

  if (!stepController.currentStep) {
    return null
  }

  const StepComponent = MigrationStepToComponent[stepController.currentStep]
  const dialogTitle = MigrationStepToTitle[stepController.currentStep]

  return (
    <PuiDialog
      aria-labelledby="core-migration-dialog"
      classes={{
        dialogContentRoot: classes.content,
      }}
      maxWidth="lg"
      open={open}
      scroll="paper"
      title={dialogTitle}
      onClose={handleClose}
    >
      {StepComponent && (
        <StepComponent
          businessId={businessId}
          key={stepController.currentStep}
          stepController={stepController}
          {...stepController.props}
        />
      )}
    </PuiDialog>
  )
}

export default MigrationV2Dialog
