import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'
import { useFields } from '@pbt/pbt-ui-components'

import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import { useProblemLogUpdate } from '~/store/hooks/problems'
import { getIsLoadingLogs } from '~/store/reducers/problems'
import {
  Problem,
  ProblemLogBodySystemContainer,
  ProblemLogProblemContainer,
} from '~/types'

interface IdentifiedProblemDetailsProps {
  log: ProblemLogBodySystemContainer | ProblemLogProblemContainer
  problem: Problem
}

const IdentifiedProblemDetailsNotes = ({
  log,
  problem,
}: IdentifiedProblemDetailsProps) => {
  const { t } = useTranslation('Common')
  const isLogsLoading = useSelector(getIsLoadingLogs)

  const {
    fields: { notes },
  } = useFields([
    {
      name: 'notes',
      initialValue: log.entity.notes ?? '',
    },
  ])

  // Setting debounce time to 10 seconds due to loss of focus issues per RHAP-646
  // https://chewyinc.atlassian.net/browse/RHAP-646
  const DEBOUNCE_TIME = 10000

  const [debouncedNotes] = useDebounce(notes.value, DEBOUNCE_TIME)

  useProblemLogUpdate({ problem, log, newNotes: debouncedNotes })
  useProblemLogUpdate({
    problem,
    log,
    newNotes: notes.value,
    onUnmountOnly: true,
  })

  return (
    <NotesTemplateInput
      hidePanel
      isSoap
      field={notes}
      isLoading={isLogsLoading}
      maxEditorHeight={160}
      maxHeight={160}
      minEditorHeight={80}
      minHeight={80}
      placeholder={t('Common:NOTES')}
    />
  )
}

export default IdentifiedProblemDetailsNotes
