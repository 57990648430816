import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, GridProps } from '@mui/material'
import * as R from 'ramda'
import {
  DateUtils,
  HtmlNotesPreview,
  LanguageUtils,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { useIsDrug, useIsFood } from '~/store/hooks/orders'
import { useCompoundingReasonLabelById } from '~/store/hooks/prescription'
import {
  getDrugAdministrationFrequencies,
  getDrugDeliveryMethod,
  getPrescriptionProductSizeUnit,
  getProductSizeUnit,
} from '~/store/reducers/constants'
import { Prescription } from '~/types'
import { getPrescriptionType } from '~/utils/prescription'

import {
  getLengthOfCourseString,
  getPrescriptionQuantityUnits,
} from '../../soap/utils/prescriptionUtils'

export interface PrescriptionDetailsProps {
  ContainerProps?: GridProps
  prescription: Prescription
  showDispensed?: boolean
  showRefills?: boolean
  simpleInstructions?: boolean
}

const PrescriptionDetails = ({
  ContainerProps,
  prescription,
  showRefills,
  showDispensed,
  simpleInstructions,
}: PrescriptionDetailsProps) => {
  const { t } = useTranslation(['Common', 'Plurals', 'Clients'])

  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )

  const {
    prescriptionType,
    administrationQuantity = '',
    administrationRouteId,
    administrationFrequencyId,
    administrationCourseType,
    administrationCourseLength,
    dispenseReason,
    notes = '',
    totalQuantity = '',
    quantityDrugExpirationDate,
    quantityPerRefill = '',
    quantityNumbRefills = '',
    refillRemains,
    cancelledBy,
    quantityRefillExpirationDate,
    administrationFrequencyNotes,
    compoundingReason,
    compoundingReasonId,
  } = prescription

  const isDrug = useIsDrug(prescription)
  const isFood = useIsFood(prescription)
  const { isScriptOut } = getPrescriptionType(prescriptionType)

  const { quantityUnit, quantityPerRefillUnit, administrationQuantityUnit } =
    getPrescriptionQuantityUnits(prescription, {
      PrescriptionProductSizeUnit,
      ProductSizeUnit,
    })

  const administerStr =
    administrationQuantity && administrationQuantityUnit
      ? `${t(
          'Common:ADMINISTER_ACTION',
        )} ${administrationQuantity} ${administrationQuantityUnit}`
      : ''

  const routeName = LanguageUtils.getConstantTranslatedName(
    administrationRouteId,
    DrugDeliveryMethod,
  )
  const frequencyName =
    Utils.getConstantName(
      administrationFrequencyId,
      DrugAdministrationFrequencies,
      '',
    ) || ''
  const frequencyDisplayName = LanguageUtils.getConstantTranslatedName(
    administrationFrequencyId,
    DrugAdministrationFrequencies,
  )
  const lengthOfCourse =
    getLengthOfCourseString({
      administrationCourseType,
      administrationCourseLength,
    }) || ''
  const drugExpirationDate = DateUtils.formatDate(quantityDrugExpirationDate)
  const refillExpirationDate = DateUtils.formatDate(
    quantityRefillExpirationDate,
  )
  const administrationFrequency =
    frequencyName === 'Other'
      ? administrationFrequencyNotes || ''
      : frequencyDisplayName
  const canceledRefills = Boolean(cancelledBy)

  const refillsStr =
    quantityPerRefill && quantityPerRefillUnit
      ? `${quantityPerRefill} ${quantityPerRefillUnit}`
      : ''

  const drugTypeExpirationLabel = isScriptOut
    ? t('Common:PRESCRIPTION_EXPIRATION')
    : t('Common:DRUG_EXPIRATION')

  const expirationLabel = R.cond([
    [R.always(isDrug), R.always(drugTypeExpirationLabel)],
    [R.always(isFood), R.always(t('Common:FOOD_EXPIRATION'))],
    [R.T, R.always(t('Common:EXPIRATION_DATE'))],
  ])()

  const compoundingReasonLabel =
    useCompoundingReasonLabelById(compoundingReasonId)

  const hasInstructions = R.any(Boolean, [
    notes,
    administerStr,
    routeName,
    administrationFrequency,
    lengthOfCourse,
  ])

  return (
    <Grid container direction="column" spacing={3} {...ContainerProps}>
      <Grid container item columnSpacing={3} wrap="nowrap">
        {hasInstructions && (
          <Grid item xs>
            <Text strong mt={2} variant="subheading3">
              {t('Common:INSTRUCTIONS')}
            </Text>
            {simpleInstructions ? (
              <Text variant="body2">{notes}</Text>
            ) : (
              <>
                {administerStr && <Text variant="body2">{administerStr}</Text>}
                {routeName && (
                  <Text variant="body2">
                    {t(
                      'Clients:TIMELINE.PRESCRIPTION_CARD.ADMINISTRATION_ROUTE',
                      {
                        routeName,
                      },
                    )}
                  </Text>
                )}
                {administrationFrequency && (
                  <Text variant="body2">{`${t(
                    'Common:FREQUENCY_ONE',
                  )}: ${administrationFrequency}`}</Text>
                )}
                {lengthOfCourse && (
                  <Text variant="body2">
                    {t('Common:LENGTH_OF_COURSE_DURATION', {
                      lengthOfCourse,
                    })}
                  </Text>
                )}
                {notes && (
                  <HtmlNotesPreview
                    includeLabel
                    fontSize="1.4rem"
                    label={t(
                      'Clients:TIMELINE.PRESCRIPTION_CARD.ADMINISTRATION_NOTES',
                    )}
                    notes={notes}
                  />
                )}
              </>
            )}
          </Grid>
        )}
        <Grid item xs>
          {showDispensed && (
            <>
              <Text strong mt={2} variant="subheading3">
                {t('Common:DISPENSED')}
              </Text>
              <Text variant="body2">{`${totalQuantity} ${quantityUnit}`}</Text>
              {drugExpirationDate && (
                <Text variant="body2">{`${expirationLabel}: ${drugExpirationDate}`}</Text>
              )}
              {dispenseReason && (
                <Text variant="body2">
                  {t('Common:REASON_DISPENSED')}: {dispenseReason}
                </Text>
              )}
            </>
          )}
          {showRefills &&
            (quantityNumbRefills ? (
              <>
                <Text strong mt={2} variant="subheading3">
                  {t('Common:REFILL_OTHER')}
                </Text>
                {refillsStr && <Text variant="body2">{refillsStr}</Text>}
                <Text variant="body2">
                  {canceledRefills
                    ? t('Clients:TIMELINE.PRESCRIPTION_CARD.REFILL_CANCELED')
                    : t('Plurals:Z_ICU_WORKAROUND.REFILL_QUANTITY', {
                        quantityNumbRefills,
                        refillRemains,
                      })}
                </Text>
                {refillExpirationDate && (
                  <Text variant="body2">
                    {t('Clients:TIMELINE.PRESCRIPTION_CARD.REFILL_BY', {
                      refillExpirationDate,
                    })}
                  </Text>
                )}
              </>
            ) : (
              <Text mt={showDispensed ? 2 : 0} variant="body2">
                {t('Clients:TIMELINE.PRESCRIPTION_CARD.NO_REFILLS')}
              </Text>
            ))}
        </Grid>
      </Grid>
      {compoundingReasonLabel && (
        <Grid item>
          <Text strong mt={2} variant="subheading3">
            {t('Common:COMPOUNDED')}
          </Text>
          <Text variant="body2">
            {t('Common:REASON_FOR_COMPOUNDING.REASON_FOR_COMPOUNDING')}:{' '}
            {compoundingReasonLabel}
          </Text>
          {compoundingReason && (
            <Text variant="body2">
              {t('Common:REASON_FOR_COMPOUNDING.REASON_FOR_COMPOUNDING_OTHER')}:{' '}
              {compoundingReason}
            </Text>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default PrescriptionDetails
