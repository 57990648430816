import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableVirtuoso } from 'react-virtuoso'
import { Grid, TableBody, TableHead, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { vitalsTableConfig } from '~/components/dashboard/soapV2/vitals/config/vitalsTableConfig'
import { getSoapVitals } from '~/store/reducers/soap'
import { VitalConfigItem } from '~/types'

import SoapVitalsLockedTable from '../../soap/vitals/SoapVitalsLockedTable'
import SoapVitalsLockedTableHeader from '../../soap/vitals/SoapVitalsLockedTableHeader'
import SoapVitalsLockedTableRow from '../../soap/vitals/SoapVitalsLockedTableRow'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleColumn: {
      borderRight: theme.constants.tableBorder,
    },
    table: {
      overflowX: 'auto',
      borderRight: theme.constants.tableBorder,
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'SoapVitalsWidgetLocked' },
)

const SoapVitalsWidgetLocked = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Time', 'Soap'])

  const vitals = useSelector(getSoapVitals)

  const itemContent = useCallback(
    (index: number, item: VitalConfigItem) => (
      <SoapVitalsLockedTableRow item={item} />
    ),
    [],
  )

  return (
    <Grid container className={classes.container} wrap="nowrap">
      <Grid item className={classes.titleColumn} p={2} xs={2}>
        <Text variant="h4">{t('Common:VITALS')}</Text>
      </Grid>
      <Grid container item xs direction="column" px={2} py={2} wrap="nowrap">
        {vitals.length > 0 ? (
          <TableVirtuoso
            className={classes.table}
            components={{
              Table: SoapVitalsLockedTable,
              TableHead,
              TableRow,
              TableBody,
            }}
            data={vitalsTableConfig}
            fixedHeaderContent={SoapVitalsLockedTableHeader}
            itemContent={itemContent}
            overscan={300}
            style={{ height: 500 }}
          />
        ) : (
          <Text variant="body">
            {t('Soap:NO_VITALS_WERE_RECORDER_IN_THIS_SOAP')}.
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

export default SoapVitalsWidgetLocked
