import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

const NoSpacesScreen = () => {
  const [openSpaceDialog] = useDialog(DialogNames.SPACE)
  const { t } = useTranslation('Admin')

  return (
    <NoItemsScreen
      action={() => openSpaceDialog()}
      actionMessage={t('Admin:SPACE.NEW_SPACE')}
      title={t('Admin:SPACE.SPACES')}
    >
      <Text>{t('Admin:SPACE.SET_UP_SPACES')}</Text>
    </NoItemsScreen>
  )
}

export default NoSpacesScreen
