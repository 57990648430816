import * as R from 'ramda'

import {
  EnabledState,
  NotificationSyntheticAreas,
  NotificationTypeName,
} from '~/constants/notifications'
import SnackNotificationType from '~/constants/SnackNotificationType'
import {
  MapFunction,
  Notification,
  NotificationSetting,
  TotalCountEntryByAreaIdMap,
} from '~/types'

export const selectSubListBySettingType = (
  settingTypeId: string,
  settingList: NotificationSetting[],
) => settingList.filter(R.propEq('typeId', settingTypeId))

export const selectSubListByNotificationsType = (
  notificationTypeId: string,
  settingList: NotificationSetting[],
) => settingList.filter(R.propEq('notificationTypeId', notificationTypeId))

export const mapSubListWithSettingsOfType = (
  settingTypeId: string,
  settingList: NotificationSetting[],
  map: MapFunction<NotificationSetting>,
) => {
  const subListWithSettingsOfType = selectSubListBySettingType(
    settingTypeId,
    settingList,
  )
  return settingList.map((it) =>
    subListWithSettingsOfType.includes(it) ? map(it) : it,
  )
}

export const mapSubListWithSettingsOfNotificationType = (
  notificationTypeId: string,
  settingList: NotificationSetting[],
  map: MapFunction<NotificationSetting>,
) => {
  const subListWithSettingsOfType = selectSubListByNotificationsType(
    notificationTypeId,
    settingList,
  )
  return settingList.map((it) =>
    subListWithSettingsOfType.includes(it) ? map(it) : it,
  )
}

export const areAllEnabled = (
  settingTypeId: string,
  settingList: NotificationSetting[],
) =>
  R.none(R.propEq('enabledState', EnabledState.DISABLED))(
    selectSubListBySettingType(settingTypeId, settingList),
  )

export const anyEnabled = (
  settingTypeId: string,
  settingList: NotificationSetting[],
) =>
  R.any(R.propEq('enabledState', EnabledState.ENABLED))(
    selectSubListBySettingType(settingTypeId, settingList),
  )

export const sameSetting = (
  setting: NotificationSetting,
  settingTypeId: string,
  notificationTypeId: string,
) =>
  setting.typeId === settingTypeId &&
  setting.notificationTypeId === notificationTypeId

const booleanToEnabledState = (enabled: boolean) =>
  enabled ? EnabledState.ENABLED : EnabledState.DISABLED

export const updateSettingEnabledState = (
  setting: NotificationSetting,
  isEnabled: boolean,
) => ({
  ...setting,
  enabledState:
    setting.enabledState === EnabledState.NOT_APPLICANT
      ? setting.enabledState
      : booleanToEnabledState(isEnabled),
})

export const updateSettingStateInList = (
  settings: NotificationSetting[],
  settingTypeId: string,
  notificationTypeId: string,
  checked: boolean,
) =>
  settings.map((it) =>
    sameSetting(it, settingTypeId, notificationTypeId)
      ? updateSettingEnabledState(it, checked)
      : it,
  )

export const getNotificationsTotalCountForArea = (
  areaId: string,
  totalCountEntryByAreaIdMap: TotalCountEntryByAreaIdMap = {},
) =>
  areaId === NotificationSyntheticAreas.ALL.id
    ? R.sum(R.pluck('totalCount', Object.values(totalCountEntryByAreaIdMap)))
    : totalCountEntryByAreaIdMap[areaId]?.totalCount

export const findNotificationSettingInList = (
  typeId: string,
  notificationTypeId: string,
  notifyForItemSettingList: NotificationSetting[],
) =>
  R.find(
    (it) =>
      it.typeId === typeId && it.notificationTypeId === notificationTypeId,
    notifyForItemSettingList,
  )

export const getSettingsWithChangedEnabledState = (
  newSettingList: NotificationSetting[],
  settingList: NotificationSetting[],
) =>
  newSettingList.filter((newSetting) => {
    const setting = findNotificationSettingInList(
      newSetting.typeId,
      newSetting.notificationTypeId,
      settingList,
    )
    return setting?.enabledState !== newSetting.enabledState
  })

const mapCommunicationToUiNotification = (notification: Notification) => ({
  id: notification.id,
  conversationId: notification.conversationId,
  title: notification.title,
  message: notification.message,
  type: SnackNotificationType.COMMUNICATION,
})

const mapTaskToUiNotification = (notification: Notification) => ({
  id: notification.id,
  title: notification.title,
  message: notification.message,
  type: SnackNotificationType.TASK,
  taskId: notification.taskId,
})

const mapPriceBulkToUiNotification = (notification: Notification) => ({
  id: notification.id,
  title: notification.title,
  message: notification.message,
  type: SnackNotificationType.PRICING_UPDATE,
})

const mapToUiNotifications = {
  [NotificationTypeName.UNREAD_COMMUNICATIONS]:
    mapCommunicationToUiNotification,
  [NotificationTypeName.COMMUNICATIONS_ASSIGNED]:
    mapCommunicationToUiNotification,
  [NotificationTypeName.NEW_TASKS]: mapTaskToUiNotification,
  [NotificationTypeName.TASKS_ASSIGNED]: mapTaskToUiNotification,
  [NotificationTypeName.PRICE_BULK_UPDATE_COMPLETED]:
    mapPriceBulkToUiNotification,
}

export const prepareUiNotificationByType = (
  notification: Notification,
  type: NotificationTypeName,
) => {
  const mapToUiNotification = mapToUiNotifications[type]
  return mapToUiNotification && mapToUiNotification(notification)
}
