import { Constant, LanguageUtils, moment, Utils } from '@pbt/pbt-ui-components'

import { Range } from '~/types'

export const getTimeOffsetUnitByAgeUnit = (
  ageUnitId: string,
  AgeUnits: Constant[],
) => `${Utils.getConstantName(ageUnitId, AgeUnits, '')}s`.toUpperCase()

export const getAgeUnitIdByTimeOffsetUnit = (
  timeOffsetUnit: string,
  AgeUnits: Constant[],
) => {
  const ageUnitName = LanguageUtils.capitalize(
    timeOffsetUnit.slice(0, -1).toLowerCase(),
  )
  return Utils.findConstantIdByName(ageUnitName, AgeUnits)
}

export const checkAgeRangeValidity = (
  { start, end }: Range,
  AgeUnits: Constant[],
) => {
  const startUnitName = Utils.getConstantName(start.unit, AgeUnits)
  const endUnitName = Utils.getConstantName(end.unit, AgeUnits)

  return moment()
    .add(start.value, startUnitName)
    .isBefore(moment().add(end.value, endUnitName))
}
