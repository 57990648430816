import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { AlertIconType, Defaults } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { AttachmentGroup } from '~/types'
import useDialog from '~/utils/useDialog'

import { FileListHandle } from './FileList'
import ImageEditor from './ImageEditor'
import MultiAttachmentDetails, {
  MultiAttachmentDetailsHandle,
} from './MultiAttachmentDetails'
import MultiAttachmentFiles from './MultiAttachmentFiles'

export type InitialFilesMetadata = {
  fileName: string
  fileType: string
  url: string
}

export interface MultiAttachmentProps {
  closeEdit: () => void
  date?: string
  fileIndexForImageEdit: number | null
  initialFiles?: File[]
  initialSource?: string
  initialTitle?: string
  isSaving: boolean
  onFilesUpload: (group: AttachmentGroup) => void
  preexistingFileCount?: number
  setFileIndexForImageEdit: (index: number) => void
}

const MultiAttachment = ({
  onFilesUpload,
  closeEdit,
  fileIndexForImageEdit,
  setFileIndexForImageEdit,
  date,
  initialFiles,
  initialTitle,
  initialSource,
  isSaving,
  preexistingFileCount,
}: MultiAttachmentProps) => {
  const { t } = useTranslation('Common')

  const [files, setFiles] = useState<File[]>(initialFiles || [])

  const detailsRef = useRef<MultiAttachmentDetailsHandle>(null)
  const fileListRef = useRef<FileListHandle>(null)

  const [openFileAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const onAttachmentFilesSelected = (newFiles: File[]) => {
    const combinedFiles = [...files, ...newFiles]

    if (combinedFiles.length + (preexistingFileCount ?? 0) > 10) {
      openFileAlert({
        iconType: AlertIconType.WARN,
        message: (
          <>
            {t('Common:INPUTS.FILE_SELECTOR.ALERT_MESSAGE_COUNT')}
            &nbsp;
            <a href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
              {Defaults.SUPPORT_EMAIL}
            </a>
            .
          </>
        ),
      })
    } else {
      setFiles(combinedFiles)
    }
  }

  const handleImageSave = (file: File) => {
    if (!R.isNil(fileIndexForImageEdit)) {
      setFiles(R.update(fileIndexForImageEdit, file, files))
      closeEdit()
    }
  }

  const handleDelete = (index: number) => {
    const newFilesList: File[] = R.remove(index, 1, files)
    setFiles(newFilesList)
  }

  const handleFileChange = (file: File, index: number) => {
    setFiles(R.update(index, file, files))
  }

  const showImageEditor = fileIndexForImageEdit !== null

  return (
    <>
      {showImageEditor && (
        <ImageEditor
          file={files[fileIndexForImageEdit]}
          onCancel={closeEdit}
          onSave={handleImageSave}
        />
      )}
      <Box sx={{ display: showImageEditor ? 'none' : 'initial' }}>
        <MultiAttachmentFiles
          files={files}
          handleDelete={handleDelete}
          handleFileChange={handleFileChange}
          isSaving={isSaving}
          ref={fileListRef}
          setFileIndexForImageEdit={setFileIndexForImageEdit}
          onAdd={() => {
            if (
              detailsRef.current?.validate() &&
              fileListRef.current?.validate()
            ) {
              onFilesUpload({ files, ...detailsRef.current.getDetails() })
            }
          }}
          onAttachmentFilesSelected={onAttachmentFilesSelected}
        >
          <MultiAttachmentDetails
            initialDate={date}
            initialSource={initialSource}
            initialTitle={initialTitle}
            ref={detailsRef}
          />
        </MultiAttachmentFiles>
      </Box>
    </>
  )
}

export default MultiAttachment
