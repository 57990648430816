import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Grid,
  Hidden,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DialogNames from '~/constants/DialogNames'
import { editPatient } from '~/store/actions/patients'
import { useCreatePatient } from '~/store/hooks/patient'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

import WellnessPlanMemberButton from '../../wellness-plans/WellnessPlanMemberButton'
import PatientDetailsList from './PatientDetailsList'
import PatientMainDetails from './PatientMainDetails'

const useStyles = makeStyles(
  (theme) => ({
    patientDetailsContainer: {
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        height: 224,
        backgroundColor: theme.colors.filterBorderColor,
      },
      padding: theme.spacing(2, 2, 0, 2),
    },
    expandDetailsButtonContainer: {
      marginTop: theme.spacing(0.5),
      paddingLeft: theme.spacing(11.5),
    },
    expandDetailsButton: {
      textTransform: 'none',
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
      fontWeight: 500,
    },
    collapse: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      width: '100%',
      padding: 0,
      backgroundColor: '#EDECEC',
    },
    patientDetailsListContainer: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: '#EDECEC',
      },
    },
    additionalInfoContainer: {
      marginLeft: theme.spacing(10),
    },
  }),
  { name: 'PatientDetails' },
)

interface PatientDetailsProps {
  clientId: string
  patientId: string
}

const PatientDetails = ({ clientId, patientId }: PatientDetailsProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { createPatient } = useCreatePatient()
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isPhone = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const [patientDetailsExpanded, setPatientDetailsExpanded] =
    useState<boolean>(false)
  const [editedField, setEditedField] = useState<string | null>(null)

  const [openPatientDialog] = useDialog(DialogNames.PATIENT)

  // if client does not have this patient - redirect to client details
  if (!client?.patients?.includes(patientId)) {
    return <Navigate replace to={`/client/${clientId}`} />
  }

  const onUpdate = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    field: string,
    isBool?: boolean,
  ) => {
    if (patient?.id) {
      const newValue = isBool ? target?.checked : target.value
      setEditedField(field)
      dispatch(editPatient(clientId, { ...patient, [field]: newValue }))
    }
  }

  const goToEditPatient = () => {
    if (isPhone) {
      navigate(`/client/${clientId}/patient/${patientId}/edit`)
    } else {
      openPatientDialog({
        clientId,
        patientId: patient?.id,
        createPatient,
      })
    }
  }

  return (
    <>
      <Grid
        container
        item
        className={classes.patientDetailsContainer}
        direction="column"
        wrap="nowrap"
      >
        <Grid container item wrap={isPhone ? 'wrap' : 'nowrap'}>
          <PatientMainDetails
            editedField={editedField}
            patientId={patientId}
            onEdit={goToEditPatient}
            onUpdate={onUpdate}
          />
          <Grid item className={classes.additionalInfoContainer}>
            <WellnessPlanMemberButton
              clientId={clientId}
              patientId={patientId}
            />
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item className={classes.expandDetailsButtonContainer}>
            <Button
              className={classes.expandDetailsButton}
              onClick={() => setPatientDetailsExpanded(!patientDetailsExpanded)}
            >
              {patientDetailsExpanded
                ? t('Common:HIDE_PATIENT_DETAILS')
                : t('Common:MORE_PATIENT_DETAILS')}
              {patientDetailsExpanded ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item className={classes.patientDetailsListContainer}>
        <Hidden mdUp>
          <PatientDetailsList
            clientId={clientId}
            editedField={editedField}
            patientId={patient?.id}
            onUpdate={onUpdate}
          />
        </Hidden>
        <Hidden mdDown>
          <Collapse className={classes.collapse} in={patientDetailsExpanded}>
            <PatientDetailsList
              clientId={clientId}
              editedField={editedField}
              patientId={patient?.id}
              onUpdate={onUpdate}
            />
          </Collapse>
        </Hidden>
      </Grid>
    </>
  )
}

export default PatientDetails
