import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Defaults, LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import TimezoneWarningLabel from '~/components/dashboard/alerts/TimezoneWarningLabel'
import {
  fetchAvailabilityRulesList,
  fetchMoreItemsForAvailabilityRulesList,
} from '~/store/actions/availabilityRules'
import {
  getAvailabilityRuleIsFetchingList,
  getAvailabilityRulesList,
  getAvailabilityRulesTotalCount,
} from '~/store/reducers/availabilityRules'
import { getAvailabilityRuleType } from '~/store/reducers/constants'

import AvailabilityRuleDetails from './AvailabilityRuleDetails'
import AvailabilityRulesTable from './AvailabilityRulesTable'
import NoRulesScreen from './NoRulesScreen'

export interface AvailabilityRulesTableComponentProps {
  headerButtons: React.ReactNode
  onDetailsClose: () => void
  ruleId?: string
}

const AvailabilityRulesTableComponent = ({
  ruleId,
  headerButtons,
  onDetailsClose,
}: AvailabilityRulesTableComponentProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const AvailabilityRuleType = useSelector(getAvailabilityRuleType)
  const list = useSelector(getAvailabilityRulesList)
  const totalCount = useSelector(getAvailabilityRulesTotalCount)
  const isFetchingList = useSelector(getAvailabilityRuleIsFetchingList)
  const { t } = useTranslation('Common')

  useEffect(() => {
    dispatch(
      fetchAvailabilityRulesList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT),
    )
  }, [])

  const businessType =
    Utils.findConstantByName('Hours of operation', AvailabilityRuleType) || {}

  const availabilityRules = list.map((group) => ({
    ...group,
    name: group?.person
      ? Utils.getPersonString(group.person)
      : LanguageUtils.getTranslatedFieldName(businessType),
  }))

  const navigateToMember = (id: string) => {
    navigate(`/admin/scheduling/availability/${id}`)
  }

  const isItemLoaded = (index: number) => Boolean(availabilityRules[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForAvailabilityRulesList(startIndex, endIndex))
  }

  return (
    <ExpandableTable
      Expander={AvailabilityRuleDetails}
      NoItemsScreen={NoRulesScreen}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={ruleId}
      list={availabilityRules}
      title={
        <TimezoneWarningLabel iconPlacement="right" variant="h1">
          {t('Common:SHIFTS_AND_PRACTICE_HOURS')}
        </TimezoneWarningLabel>
      }
      onClose={onDetailsClose}
      onSelected={navigateToMember}
    >
      <AvailabilityRulesTable
        availabilityRules={availabilityRules}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default AvailabilityRulesTableComponent
