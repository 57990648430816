import React from 'react'
import { makeStyles } from '@mui/styles'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    overlay: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.headerTopBar, 'below'),
    },
  }),
  { name: 'SoapPageLoader' },
)

export interface SoapPageLoaderProps {
  open: boolean
}

const SoapPageLoader = ({ open }: SoapPageLoaderProps) => {
  const classes = useStyles()

  return <CircularProgressOverlay className={classes.overlay} open={open} />
}

export default SoapPageLoader
