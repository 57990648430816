import { LanguageUtils } from '@pbt/pbt-ui-components'

import { useTaskTypes } from '~/store/hooks/tasks'

const TaskTypeCell = (item: { typeId: string }) => {
  const TaskTypes = useTaskTypes()

  return LanguageUtils.getConstantTranslatedName(item.typeId, TaskTypes)
}

export default TaskTypeCell
