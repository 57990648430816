import { all, put, take, takeLatest } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { UserMatch } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import SnackNotificationType from '~/constants/SnackNotificationType'

import { linkPatientsToChewyAccount } from '../actions/patients'
import { linkUserToChewyAccount } from '../actions/users'
import {
  addFailedEmails,
  fetchEmailData,
  fetchEmailDataFailure,
  fetchEmailDataSuccess,
  getMatchChewyUserByEmail,
  getMatchChewyUserByEmailFailure,
  getMatchChewyUserByEmailSuccess,
  getPetMatchData,
  getPetMatchDataFailure,
  getPetMatchDataSuccess,
  linkChewyUser,
  linkChewyUserFailure,
  linkChewyUserSuccess,
  linkPets,
  linkPetsFailure,
  linkPetsSuccess,
} from '../duck/chewyPets'
import { openDialog } from '../duck/dialogs'
import { addUiNotification } from '../duck/uiNotifications'
import requestAPI from './utils/requestAPI'

export function* fetchClientChewyEmailsSaga({
  payload,
}: ReturnType<typeof fetchEmailData>) {
  try {
    const userMatches = yield* requestAPI(API.fetchClientChewyEmails, {
      personId: payload.clientId,
    })
    yield put(fetchEmailDataSuccess({ emails: userMatches }))
  } catch (error) {
    yield put(fetchEmailDataFailure({ error: error as ApiError }))
  }
}

export function* linkChewyUserSaga({
  payload,
}: ReturnType<typeof linkChewyUser>) {
  try {
    const clientId = yield* requestAPI(API.linkChewyUser, {
      clientId: payload.clientId,
      email: payload.email,
    })
    yield put(linkChewyUserSuccess())
    yield put(linkUserToChewyAccount(clientId))
    yield put(getPetMatchData({ clientId }))
    yield take(getPetMatchDataSuccess.type)
    yield put(
      openDialog({
        name: DialogNames.MATCH_PET_PATIENT_DIALOG,
        id: uuid(),
        props: {
          clientId,
        },
      }),
    )
  } catch (error) {
    yield put(linkChewyUserFailure({ error: error as ApiError }))
  }
}

export function* linkPetsSaga({ payload }: ReturnType<typeof linkPets>) {
  try {
    yield* requestAPI(API.linkChewyPets, {
      clientId: payload.clientId,
      petPatientMatches: payload.petPatientMatches,
    })
    yield put(linkPetsSuccess())
    const patientIds = payload.petPatientMatches.map(
      (item) => item.rhapsodyPatient.rhapsodyId,
    )
    yield put(linkPatientsToChewyAccount(patientIds))
    yield put(
      addUiNotification({
        id: uuid(),
        type: SnackNotificationType.LINK_CHEWY_ACCOUNT,
      }),
    )
  } catch (error) {
    yield put(linkPetsFailure({ error: error as ApiError }))
  }
}

export function* getPetMatchDataSaga({
  payload,
}: ReturnType<typeof getPetMatchData>) {
  try {
    const { entities, result } = yield requestAPI(
      API.getPetMatchData,
      payload.clientId,
    )
    yield put(
      getPetMatchDataSuccess({
        ...entities,
        matchedPetsList: result.matchedPets,
        unmatchedPatientsList: result.unmatchedPatients,
        unmatchedPetsList: result.unmatchedPets,
      }),
    )
  } catch (error) {
    yield put(getPetMatchDataFailure({ error: error as ApiError }))
  }
}

export function* getMatchChewyUserByEmailSaga({
  payload,
}: ReturnType<typeof getMatchChewyUserByEmail>) {
  try {
    const result: Omit<UserMatch, 'email'>[] = yield requestAPI(
      API.matchChewyUserByEmail,
      { email: payload.email },
    )
    // search by email can only yield one result
    const hasKyriosId = result.length > 0 ? result[0].hasKyriosId : false
    yield put(
      getMatchChewyUserByEmailSuccess({
        isEmailMatch: hasKyriosId,
        email: payload.email,
      }),
    )
    if (hasKyriosId) {
      return
    }

    yield put(addFailedEmails([payload.email]))
  } catch (error) {
    yield put(getMatchChewyUserByEmailFailure({ error: error as ApiError }))
  }
}

function* watchFetchClientChewyEmailsSaga() {
  yield takeLatest(fetchEmailData.type, fetchClientChewyEmailsSaga)
}

function* watchLinkChewyUserSaga() {
  yield takeLatest(linkChewyUser.type, linkChewyUserSaga)
}

function* watchLinkChewyPetsSaga() {
  yield takeLatest(linkPets.type, linkPetsSaga)
}

function* watchGetPetMatchDataSaga() {
  yield takeLatest(getPetMatchData.type, getPetMatchDataSaga)
}

function* watchGetMatchChewyUserByEmailSaga() {
  yield takeLatest(getMatchChewyUserByEmail.type, getMatchChewyUserByEmailSaga)
}

export default function* chewyPetsSaga() {
  yield all([
    watchFetchClientChewyEmailsSaga(),
    watchLinkChewyUserSaga(),
    watchGetPetMatchDataSaga(),
    watchGetMatchChewyUserByEmailSaga(),
    watchLinkChewyPetsSaga(),
  ])
}
