import React from 'react'
import { Skeleton } from '@mui/material'
import * as R from 'ramda'

import ImagingStatusLabel from '../../imaging-procedures/ImagingStatusLabel'

export type ImagingStatusCellItem = {
  statusId: string
}

const ImagingStatusCell = (item: ImagingStatusCellItem) => {
  const isLoading = R.isEmpty(item)

  if (isLoading) {
    return <Skeleton height={20} variant="text" width={60} />
  }

  return <ImagingStatusLabel statusId={item?.statusId} />
}

export default ImagingStatusCell
