import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ClassesType,
  ColorPicker,
  PermissionArea,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import i18n from '~/locales/i18n'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeBrandingConfigurationFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import KioskPreview from '../kiosk-configuration/KioskPreview'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
    shadow: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      backgroundColor: theme.colors.tableBackground,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.5,
    },
    picker: {
      marginTop: theme.spacing(2),
      justifyContent: 'space-between',
      width: 245,
    },
    kioskPreview: {
      maxWidth: '100%',
      padding: 0,
      height: 265,
      width: 330,
      zIndex: theme.zIndex.base,
    },
    kioskPreviewPracticeLogo: {
      width: 80,
      height: 42,
      marginBottom: theme.spacing(2),
    },
    kioskPreviewContent: {
      justifyContent: 'center',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(6)})`,
    },
    kioskPreviewButton: {
      alignSelf: 'auto',
    },
  }),
  { name: 'BrandingConfiguration' },
)

export interface BrandingConfigurationProps
  extends BasePracticeDetailsSectionProps {
  classes?: ClassesType<typeof useStyles>
  customColorsLabel?: string
  showCallOut?: boolean
  showSwitch?: boolean
  tooltipText?: string
}

const BrandingConfiguration = ({
  showSwitch = true,
  customColorsLabel = i18n.t(
    'Admin:PRACTICE.USE_CUSTOM_COLORS_ON_EXTERNAL_LINKS',
  ),
  tooltipText,
  business,
  showCallOut,
  classes: classesProp,
}: BrandingConfigurationProps) => {
  const theme = useTheme()
  const { t } = useTranslation(['Admin', 'Mock'])
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'customColorsEnabled',
        type: 'toggle',
        initialValue: business.customColorsEnabled || false,
      },
      {
        name: 'backgroundColor',
        label: t('Admin:PRACTICE.DARK_COLOR_BACKGROUND'),
        initialValue: business.backgroundColor || theme.colors.title,
      },
      {
        name: 'buttonsColor',
        label: t('Admin:PRACTICE.DARK_COLOR_BUTTON'),
        initialValue: business.buttonsColor || theme.colors.styledButton,
      },
    ],
    false,
  )

  usePracticeFieldsSection<PracticeBrandingConfigurationFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.BRANDING_CONFIGURATION,
    validate,
    reset,
  })

  const { customColorsEnabled, backgroundColor, buttonsColor } = fields

  const classes = useStyles({
    buttonsColor: buttonsColor.value,
    classes: classesProp,
  })

  const resetColors = () => {
    backgroundColor.setValue(theme.colors.title)
    buttonsColor.setValue(theme.colors.styledButton)
  }

  return (
    <>
      {showSwitch && (
        <PuiSwitch
          disabled={!permissions.update}
          field={customColorsEnabled}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={
                tooltipText || t('Admin:PRACTICE.USE_COLORS_FOR_KIOSK_AND_MORE')
              }
              variant="body"
            >
              {customColorsLabel}
            </TextWithTooltip>
          }
        />
      )}
      <Grid
        container
        item
        className={classes.container}
        justifyContent="space-between"
      >
        {!customColorsEnabled.value && showSwitch && (
          <Box
            className={classes.shadow}
            role="button"
            onClick={(event) => {
              event.stopPropagation()
            }}
          />
        )}
        <Grid item>
          <ColorPicker
            className={classes.picker}
            field={backgroundColor}
            label={`${backgroundColor.label}:`}
          />
          <ColorPicker
            className={classes.picker}
            field={buttonsColor}
            label={`${buttonsColor.label}:`}
          />
          <Text link mt={2} onClick={resetColors}>
            {t('Admin:PRACTICE.RESET_TO_DEFAULT_COLORS')}
          </Text>
        </Grid>
        <Grid item sm={6} xs={12}>
          <KioskPreview
            backgroundColor={backgroundColor.value}
            business={business}
            buttonText={t('Mock:BUTTON_TEXT')}
            buttonsColor={buttonsColor.value}
            classes={{
              root: classes.kioskPreview,
              practiceLogo: classes.kioskPreviewPracticeLogo,
              content: classes.kioskPreviewContent,
              button: classes.kioskPreviewButton,
            }}
            showCallOut={showCallOut}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default memo(BrandingConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
