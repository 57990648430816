import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  StateLabel,
  StateLabelProps,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import {
  CONVERSATION_STATUS_NAME,
  ConversationStatus,
} from '~/constants/communications'
import { NotificationAreaNames } from '~/constants/notifications'

import LinkedItemNotificationsBadge from '../../notifications/LinkedItemNotificationsBadge'

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'inline',
    },
  }),
  { name: 'ConversationStatusLabel' },
)

export interface ConversationStatusLabelProps {
  conversationId?: string
  hideNotifications?: boolean
  status?: ConversationStatus
  variant?: StateLabelProps['variant']
}

const ConversationStatusLabel = ({
  conversationId,
  status,
  variant = 'small',
  hideNotifications = false,
}: ConversationStatusLabelProps) => {
  const classes = useStyles()

  return status ? (
    <Grid container alignItems="center" wrap="nowrap">
      {conversationId && !hideNotifications && (
        <LinkedItemNotificationsBadge
          areaName={NotificationAreaNames.COMMUNICATIONS}
          itemId={conversationId}
        />
      )}
      <Grid container item xs>
        <StateLabel
          className={classes.root}
          disabled={status === ConversationStatus.ARCHIVED}
          variant={variant}
          warning={
            status === ConversationStatus.UNREAD ||
            status === ConversationStatus.DELIVERY_FAILED
          }
        >
          {LanguageUtils.getReadableEnumName(CONVERSATION_STATUS_NAME[status])}
        </StateLabel>
      </Grid>
    </Grid>
  ) : (
    <TextInteractive isLoading variant="body2" />
  )
}

export default ConversationStatusLabel
