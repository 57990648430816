import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Question, QuestionView, Text } from '@pbt/pbt-ui-components'

import PuiPopup, { PuiPopupProps } from '~/components/common/PuiPopup'
import { getAnswerInputTypes } from '~/store/reducers/constants'

const useStyles = makeStyles(
  () => ({
    container: {
      width: 600,
    },
  }),
  { name: 'PreviewQuestionPopup' },
)

export interface PreviewQuestionPopupProps extends PuiPopupProps {
  question: Question | Nil
}

const PreviewQuestionPopup = ({
  question,
  ...rest
}: PreviewQuestionPopupProps) => {
  const classes = useStyles()
  const AnswerInputTypes = useSelector(getAnswerInputTypes)
  const { t } = useTranslation('Admin')

  return (
    <PuiPopup {...rest}>
      <Grid container className={classes.container} direction="column" p={3}>
        <Grid item>
          <Text variant="h2">
            {t('Admin:CATALOG.PREVIEW_QUESTION_POPUP.HEADER')}
          </Text>
        </Grid>
        <QuestionView AnswerInputTypes={AnswerInputTypes} question={question} />
      </Grid>
    </PuiPopup>
  )
}

export default PreviewQuestionPopup
