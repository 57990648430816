import { RefObject, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  Business,
  Constant,
  Field,
  Nil,
  Patient,
  PatientMembership,
  Utils,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanPrice,
  WellnessPlans,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import {
  BenefitNames,
  BenefitPlanType,
  WellnessPlanCoverAppointmentTypes,
  WellnessPlanLevels,
} from '~/constants/wellnessPlansConstants'
import i18n from '~/locales/i18n'
import { fetchClient } from '~/store/actions/clients'
import { getWellnessPlanType } from '~/store/reducers/constants'
import { getPatientsList } from '~/store/reducers/patients'
import { getPatientsForUser } from '~/store/reducers/users'

enum WPGroups {
  EXTRAS = 'extras',
  PACKAGES = 'packages',
  TIERS = 'tiers',
}

export const splitBenefits = (benefits: WellnessPlanBenefit[] = []) =>
  R.partition(R.propEq('planType', BenefitPlanType.BASE), benefits)

export const getPlanIsOfLevel = (plan: WellnessPlan, level: number) =>
  plan.level === level

export const getBenefitIndex = (plan: WellnessPlan, benefitId?: string) =>
  plan.benefits.findIndex(
    ({ globalBenefitId }) => globalBenefitId === benefitId,
  )

export const getBenefitUniqueId = (benefit: WellnessPlanBenefit) =>
  benefit.globalBenefitId || benefit.id

export const getBenefitFromPlan = (plan: WellnessPlan, benefitId?: string) =>
  plan?.benefits.find(({ globalBenefitId }) => globalBenefitId === benefitId)

export const getPlanHasBenefit = (
  plan: WellnessPlan,
  benefit: WellnessPlanBenefit,
) => Boolean(getBenefitFromPlan(plan, getBenefitUniqueId(benefit)))

export const removeBenefitFromPlan = (
  plan: WellnessPlan,
  benefit: WellnessPlanBenefit,
) =>
  plan.benefits.filter(
    ({ globalBenefitId }) => globalBenefitId !== benefit.globalBenefitId,
  )

export const getGlobalBenefit = (
  benefit: WellnessPlanBenefit,
  GlobalBenefits: WellnessPlanBenefit[] = [],
) =>
  GlobalBenefits.find(
    (globalBenefit) => globalBenefit.id === benefit.globalBenefitId,
  )

export const getPlanByLevel = (level: number, plans: WellnessPlan[] = []) =>
  plans.find((plan) => getPlanIsOfLevel(plan, level))

export const getPlanIndexByLevel = (level: number, plans = []) =>
  plans.findIndex((plan) => getPlanIsOfLevel(plan, level))

export const isLevelEnabled = (
  level: number,
  wellnessPlanVersion: WellnessPlanVersion,
) => {
  const isBaseLevel = level === WellnessPlanLevels.BASE

  return isBaseLevel
    ? !wellnessPlanVersion.basePlanHidden
    : Boolean(getPlanByLevel(level, wellnessPlanVersion?.plans))
}

export const globalBenefitToSelected = (
  globalBenefit: WellnessPlanBenefit,
  level?: number,
) => ({
  ...R.omit(['id'], globalBenefit),
  globalBenefitId: getBenefitUniqueId(globalBenefit),
  level: level ?? globalBenefit.level,
  cover: globalBenefit.cover || [],
  appointmentTypeIds: globalBenefit.appointmentTypeIds || [],
})

export const getBenefitIsTelehealth = (benefit: WellnessPlanBenefit) =>
  benefit?.coverAppointmentType === WellnessPlanCoverAppointmentTypes.TELEHEALTH

export const getBenefitIsAccessToBoop = (benefit: WellnessPlanBenefit) =>
  benefit?.name === BenefitNames.ACCESS_TO_MOBILE_APP

export const getBenefitCoversAppointmentTypes = (
  benefit: WellnessPlanBenefit,
) =>
  benefit?.coverAppointmentType &&
  benefit?.coverAppointmentType !== WellnessPlanCoverAppointmentTypes.NONE

export const getBenefitCoversCatalog = (benefit: WellnessPlanBenefit) =>
  Boolean(benefit?.coverCatalog)

export const getBenefitHasCoveredItems = (benefit: WellnessPlanBenefit) =>
  benefit.cover?.length > 0 || benefit.appointmentTypeIds?.length > 0

export const getEnabledPlans = (wellnessPlanVersion: WellnessPlanVersion) =>
  (wellnessPlanVersion?.plans || []).filter((plan) => {
    const isBaseLevel = plan.level === WellnessPlanLevels.BASE
    return !isBaseLevel || !wellnessPlanVersion.basePlanHidden
  })

export const updatePlanBenefits = (
  plan: WellnessPlan,
  benefits: WellnessPlanBenefit[],
  wellnessPlanVersion: WellnessPlanVersion,
) => {
  const plans = wellnessPlanVersion?.plans || []
  const planIndex = plans.indexOf(plan)
  // if benefit is already present in plan, use it so we do not remove already added cover options
  const updatedBenefits = benefits.map(
    (benefit: WellnessPlanBenefit) =>
      getBenefitFromPlan(plan, benefit?.globalBenefitId) || benefit,
  )
  const deletedBenefitIds = (plan.benefits || [])
    .filter((benefit) =>
      benefits.every(
        ({ globalBenefitId }) => globalBenefitId !== benefit.globalBenefitId,
      ),
    )
    .map((benefit) => benefit.id)
    .filter(Boolean) as string[]

  const newPlan = {
    ...plan,
    benefits: updatedBenefits,
    deletedBenefitIds: R.uniq(
      (plan.deletedBenefitIds || []).concat(deletedBenefitIds),
    ),
  }
  const updatedWellnessPlans = R.update(planIndex, newPlan, plans)

  return {
    ...wellnessPlanVersion,
    plans: updatedWellnessPlans,
  }
}

const fieldsToDrop = [
  'activePatients',
  'id',
  'benefitId',
  'versionId',
  'planId',
  'versionNumber',
  'createdBy',
  'createdById',
  'creationDate',
  'modificationDate',
  'modifiedById',
  'modifiedBy',
]

export const dropIdsFromPlan = (plan: WellnessPlan) =>
  ({
    ...R.omit(fieldsToDrop, plan),
    benefits: plan.benefits.map((benefit: WellnessPlanBenefit) => ({
      ...R.omit(fieldsToDrop, benefit),
      cover: benefit.cover.map((cover) => R.omit(fieldsToDrop, cover)),
    })),
  }) as WellnessPlan

const isPlanActive = (plan: PatientMembership) =>
  plan.wplanLogId && plan.wplanStatus === WellnessPlans.MembershipStatus.ACTIVE
const isPlanInactive = (plan: PatientMembership) =>
  !plan.wplanLogId ||
  plan.wplanStatus === WellnessPlans.MembershipStatus.CANCELLED
const isPlanCancelled = (plan: PatientMembership) =>
  Boolean(plan.wplanCancellationDate) && plan.wplanStatus
export const isPlanPaused = (plan: PatientMembership) =>
  plan?.wplanStatus === WellnessPlans.MembershipStatus.PAUSED
export const getPlanNames = (plans: PatientMembership[] | Nil) =>
  R.map(
    (plan) =>
      isPlanPaused(plan)
        ? `${plan.wplanName} (${i18n.t('Common:PAUSED').toLowerCase()})`
        : plan.wplanName,
    plans || [],
  )
export const getPlanNamesWithOriginatingBusiness = (
  plans: PatientMembership[] | Nil,
  businessesMap: Record<string, Business>,
) =>
  R.map((plan: PatientMembership) => {
    const businessName = businessesMap[plan.wplanBusinessId]?.name
    const name = businessName
      ? `${plan.wplanName} [${businessName}]`
      : plan.wplanName
    return isPlanPaused(plan)
      ? `${name} (${i18n.t('Common:PAUSED').toLowerCase()})`
      : name
  }, plans || [])
export const getPatientHasMembership = (patient: Patient | Nil) =>
  Boolean(patient?.plans?.some(isPlanActive))
export const getPatientHasNoMembership = (patient: Patient | Nil) =>
  !patient?.plans || patient.plans?.every(isPlanInactive)
export const getAllPatientPlansCancelled = (patient: Patient | Nil) => {
  if (!patient?.plans || patient.plans.length === 0) {
    return false
  }
  return patient.plans.every(isPlanCancelled)
}

export const getPatientHasPausedPlan = (patient: Patient | Nil) =>
  Boolean(patient?.plans?.some(isPlanPaused))

export const useClientMembershipStatus = (clientId: string) => {
  const patientIds = useSelector(getPatientsForUser(clientId))
  const patients = useSelector(getPatientsList(patientIds))
  const dispatch = useDispatch()

  useEffect(() => {
    if (patients.length === 0 && clientId) {
      dispatch(fetchClient({ clientId }))
    }
  }, [patients, clientId])

  if (patients.length === 0 || !clientId) {
    return WellnessPlans.MembershipStatus.CANCELLED
  }
  if (
    patients.every(
      (patient) =>
        getPatientHasPausedPlan(patient) ||
        getAllPatientPlansCancelled(patient),
    )
  ) {
    return WellnessPlans.MembershipStatus.PAUSED
  }
  if (patients.some((patient) => getPatientHasMembership(patient))) {
    return WellnessPlans.MembershipStatus.ACTIVE
  }
  return WellnessPlans.MembershipStatus.CANCELLED
}

export const useGroupedWellnessPlans = <
  T extends { planTypeId?: string; wplanTypeId?: string },
>(
  plans: T[] = [],
) => {
  const WellnessPlanType = useSelector(getWellnessPlanType)
  const PackageTypeId: string = Utils.findConstantIdByName(
    'Package',
    WellnessPlanType,
  )
  const ExtraTypeId: string = Utils.findConstantIdByName(
    'Extra',
    WellnessPlanType,
  )

  const names = {
    [ExtraTypeId]: WPGroups.EXTRAS,
    [PackageTypeId]: WPGroups.PACKAGES,
  }

  return R.groupBy(
    (plan) =>
      (plan?.planTypeId && names[plan.planTypeId]) ||
      (plan?.wplanTypeId && names[plan.wplanTypeId]) ||
      WPGroups.TIERS,
    plans,
  )
}

const propEqOr = R.curry(
  (prop1, prop2, value, item) =>
    R.propEq(prop1, value, item) || R.propEq(prop2, value, item),
)

export const useSortedWellnessPlans = <
  T extends PatientMembership | WellnessPlan,
>(
  plans: T[] | undefined = [],
) => {
  const WellnessPlanType: Constant[] = useSelector(getWellnessPlanType)
  const PackageTypeId: string = Utils.findConstantIdByName(
    'Package',
    WellnessPlanType,
  )
  const ExtraTypeId: string = Utils.findConstantIdByName(
    'Extra',
    WellnessPlanType,
  )

  return R.sortWith<T>([
    R.ascend(propEqOr('planTypeId', 'wplanTypeId', PackageTypeId)),
    R.ascend(propEqOr('planTypeId', 'wplanTypeId', ExtraTypeId)),
  ])(plans)
}

export const getUpdatedPlan = (
  plan: WellnessPlan | Nil,
  name: Field,
  isBaseLevel: boolean,
  priceTypeControlRef: RefObject<{
    get: () => WellnessPlanPrice[]
    getDeletedPriceIds: () => string[]
  }>,
) => {
  const updatedPlan = {
    ...(plan || {}),
    name: name?.value,
  }

  if (!isBaseLevel) {
    updatedPlan.prices = priceTypeControlRef.current?.get()

    const deletedPrices = priceTypeControlRef.current?.getDeletedPriceIds()
    if (!R.isEmpty(deletedPrices)) {
      updatedPlan.deletedPriceIds = deletedPrices
    }
  }

  return updatedPlan as WellnessPlan
}

export const useWellnessPlanCancelReasons = () => {
  const { t } = useTranslation(['Common', 'Memberships'])

  return [
    t('Memberships:CANCEL_PLAN.REASONS.PLAN_TOO_EXPENSIVE'),
    t('Memberships:CANCEL_PLAN.REASONS.CLIENT_FIND_PLAN_NOT_USEFUL'),
    t('Memberships:CANCEL_PLAN.REASONS.CLIENT_HAS_ANOTHER_WELLNESS_COVERAGE'),
    t('Memberships:CANCEL_PLAN.REASONS.CLIENT_MOVING_OUT'),
    t(
      'Memberships:CANCEL_PLAN.REASONS.CLIENT_NO_LONGER_COMES_TO_THIS_VETERINARIAN',
    ),
    t('Memberships:CANCEL_PLAN.REASONS.CLIENT_PREFERS_INSURANCE'),
    t('Common:OTHER'),
  ]
}
