import { PuiTab } from '~/components/common/PuiTabs'

import { CRUDOrderOptions, Order, OrderCallbackWithTypeCheck } from './orders'

export interface UseHandleOrderRefill {
  handleRefill: (prescription: Order) => void
  handleSavePrescription: (
    prescription: Order,
    options: CRUDOrderOptions,
    closeAfterSaving: boolean,
  ) => void
  logItemStateGetter: (order: Order, state?: string | undefined) => Order | null
  openPrescriptionDialog: (params: any) => void
}

export interface BaseChargeTabProps extends UseHandleOrderRefill {
  disabled: boolean
  handleEditListItem: OrderCallbackWithTypeCheck
}

export type ChargesPanelTab<T> = {
  Component: React.FC<T>
  id: ChargeTabId
  props: Partial<T>
}

export enum ChargeTabId {
  BUNDLES = 'bundles',
  PREPAID = 'prepaid',
  REFILLS = 'refills',
  REMINDERS = 'reminders',
  SEARCH = 'search',
  WELLNESS = 'wellness',
}

export enum ChargeQueryParams {
  CHARGE_SEARCH_QUERY = 'chargeQuery',
  CHARGE_TAB = 'chargeTab',
}

export type ChargeTab = Omit<PuiTab, 'id'> & { id: ChargeTabId }
