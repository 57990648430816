import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'

import NumericInput from '../../common/inputs/NumericInput'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '50px',
  alignItems: 'center',
  padding: theme.spacing(0.5),
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: theme.constants.fabBorder,
  padding: theme.spacing(1),
  borderRadius: 0,
  transition: 'background-color 0.3s, transform 0.1s',
  '&:first-of-type': {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  '&:last-of-type': {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
}))

const InputWrapper = styled(Box)(({ theme }) => ({
  borderTop: theme.constants.fabBorder,
  borderBottom: theme.constants.fabBorder,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))

const StyledNumericInput = styled(NumericInput)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  width: '30px',
  '& input': {
    textAlign: 'center',
  },
}))

interface NumberStepperProps {
  onChange: (newValue: string | number) => void
  value: number
}

export const NumberStepper = ({ value, onChange }: NumberStepperProps) => {
  const handleDecrement = () => {
    onChange(Math.max(0, value - 1))
  }

  const handleIncrement = () => {
    onChange(value + 1)
  }

  return (
    <Root>
      <StyledIconButton onClick={handleDecrement}>
        <RemoveIcon />
      </StyledIconButton>
      <InputWrapper>
        <StyledNumericInput
          allowDecimal
          min={0}
          value={value}
          onChange={onChange}
        />
      </InputWrapper>
      <StyledIconButton onClick={handleIncrement}>
        <AddIcon />
      </StyledIconButton>
    </Root>
  )
}
