import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'

import useGetBodySystemStatus from '~/components/dashboard/soap/rail/summary/utils/useGetBodySystemStatus'
import { BodySystemState } from '~/constants/SOAPStates'
import { setProblemToOpenInRail } from '~/store/actions/problems'
import { getProblemBodySystemStates } from '~/store/reducers/constants'
import {
  getBodySystemLogsWithProblems,
  getIsLoadingLogs,
  getProblemLogsWithProblems,
} from '~/store/reducers/problems'
import { Problem } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    noteIconButton: {
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
    },
  }),
  { name: 'Problems' },
)

export interface ProblemsProps {
  className?: string
}

const Problems = ({ className }: ProblemsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Soap'])

  const dispatch = useDispatch()

  const isLoading = useSelector(getIsLoadingLogs)
  const logsWithProblems = useSelector(getProblemLogsWithProblems) || []
  const systemLogsWithProblems =
    useSelector(getBodySystemLogsWithProblems) || []
  const bodySystemConstants = useSelector(getProblemBodySystemStates)

  const getBodySystemStatus = useGetBodySystemStatus()

  const systemLogsToDisplay = systemLogsWithProblems.filter((slwp) => {
    const hasNotes = Boolean(slwp.bodySystemLog?.entity?.notes?.length)
    const isONL =
      slwp.bodySystemLog?.entity?.stateId ===
      findConstantIdByName(BodySystemState.ONL, bodySystemConstants)

    return hasNotes || isONL
  })

  const problemsByState = [
    {
      title: t('Soap:EXAMINATION.OUTSIDE_NORMAL_LIMITS'),
      logWithProblem: logsWithProblems.filter(
        ({ problem }) =>
          getBodySystemStatus(problem?.key) === BodySystemState.ONL,
      ),
    },
    {
      title: t('Soap:EXAMINATION.WITHIN_NORMAL_LIMITS'),
      logWithProblem: logsWithProblems.filter(
        ({ problem }) =>
          getBodySystemStatus(problem?.key) === BodySystemState.WNL,
      ),
    },
    {
      title: t('Soap:EXAMINATION.NOT_CHECKED'),
      logWithProblem: logsWithProblems.filter(
        ({ problem }) =>
          getBodySystemStatus(problem?.key) === BodySystemState.NC,
      ),
    },
  ].filter(({ logWithProblem }) => logWithProblem.length)

  const handleProblemClick = (problem: Problem) =>
    dispatch(setProblemToOpenInRail(problem))

  return (
    <Grid item className={className}>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Grid container direction="column" gap={0.5}>
          {systemLogsToDisplay.length > 0 && (
            <>
              <Text variant="h4">{t('Soap:PROBLEMS.BODY_SYSTEMS')}</Text>
              <Grid container direction="column" gap={0.5} pl={1}>
                {systemLogsToDisplay.map(({ problem, bodySystemLog }) => (
                  <Grid container key={problem.id}>
                    <Text strong variant="body2">
                      {problem.nameTranslation}
                    </Text>
                    <IconButton
                      className={classes.noteIconButton}
                      size="large"
                      onClick={() => handleProblemClick(problem)}
                    >
                      <AddNote filled={Boolean(bodySystemLog.entity.notes)} />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {problemsByState.length > 0 && (
            <>
              <Text variant="h4">{t('Soap:PROBLEMS.PROBLEMS')}</Text>
              <Grid pl={1}>
                {problemsByState.map(({ title, logWithProblem }) => (
                  <Grid key={title}>
                    <Text strong mb={0.5} variant="subheading2">
                      {title}
                    </Text>
                    <Grid container direction="column" gap={0.5} pl={1}>
                      {logWithProblem.map(({ problem, problemLog }) => (
                        <Grid container key={problem.id}>
                          <Text strong variant="body2">
                            {problem.nameTranslation}
                          </Text>
                          <IconButton
                            className={classes.noteIconButton}
                            size="large"
                            onClick={() => handleProblemClick(problem)}
                          >
                            <AddNote
                              filled={Boolean(problemLog.entity.notes)}
                            />
                          </IconButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default Problems
