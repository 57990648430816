import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  ConversationFile,
  ConversationMessage,
  UploadedAttachment,
} from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  ATTACH_DOCUMENTS_TO_MESSAGE,
  ATTACH_DOCUMENTS_TO_MESSAGE_FAILURE,
  CLEAR_FILE_TO_SEND,
  CLEAR_FILES_TO_SEND,
  CLEAR_MESSAGE_FILES,
  CLOSE_MESSAGE_FORMATTING_AREA,
  DELETE_FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  DELETE_MESSAGE,
  DELETE_MESSAGE_FAILURE,
  DELETE_MESSAGE_SUCCESS,
  EDIT_MESSAGE,
  FETCH_ALL_CONVERSATION_FILES,
  FETCH_ALL_CONVERSATION_FILES_FAILURE,
  FETCH_ALL_CONVERSATION_FILES_SUCCESS,
  MESSAGE_FORMATTING_AREA_FOCUSED,
  SEND_CONVERSATION_DRAFT_MESSAGE,
  SEND_CONVERSATION_MESSAGE,
  SEND_CONVERSATION_MESSAGE_FAILURE,
  SEND_CONVERSATION_MESSAGE_SUCCESS,
  SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE,
  SEND_GENERATED_MESSAGE,
  SEND_GENERATED_MESSAGE_FAILURE,
  SEND_GENERATED_MESSAGE_SUCCESS,
  UPDATE_CONVERSATION_FILES,
  UPDATE_CONVERSATION_MESSAGES,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_FAILURE,
  UPDATE_MESSAGE_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_LIST,
  UPLOAD_FILE_LIST_FAILURE,
  UPLOAD_FILE_LIST_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  WAIT_FOR_ATTACHED_DOCUMENT,
} from '../actions/types/conversationMessages'
import {
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_VALIDATION_FAILURE,
  FETCH_CONVERSATION,
} from '../actions/types/conversations'
import {
  GENERATE_DOCUMENT_INSTANCES_FAILURE,
  GENERATE_DOCUMENT_INSTANCES_SUCCESS,
} from '../actions/types/documents'
import type { RootState } from '../index'
import { getUsersMap } from './users'

export type ConversationMessagesState = {
  attachmentsToSend: ConversationFile[]
  currentConversationId: string | null
  editMessageId: string | null
  error: string | null
  fileByIdMap: Record<string, ConversationFile>
  isAttachingDocuments: boolean
  isLoading: boolean
  isSending: boolean
  isUploadingFile: boolean
  isWaitingForAttachedDocument: boolean
  map: Record<string, ConversationMessage>
  message: ConversationMessage | {}
  messageFormattingAreaExpanded: boolean
  totalCount: number
}

export const CONVERSATION_MESSAGES_INITIAL_STATE: ConversationMessagesState = {
  map: {},
  isLoading: false,
  isUploadingFile: false,
  isAttachingDocuments: false,
  isSending: false,
  error: null,
  totalCount: Defaults.CONVERSATION_MESSAGES_BATCH_LOAD_COUNT,
  editMessageId: null,
  message: {},
  fileByIdMap: {},
  attachmentsToSend: [],
  currentConversationId: null,
  messageFormattingAreaExpanded: false,
  isWaitingForAttachedDocument: false,
}

const conversationMessages = (
  state: ConversationMessagesState = CONVERSATION_MESSAGES_INITIAL_STATE,
  action: AnyAction,
): ConversationMessagesState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return CONVERSATION_MESSAGES_INITIAL_STATE

    case UPDATE_CONVERSATION_MESSAGES:
      return { ...state, map: secondLevelMerge(state.map, action.messages) }
    case UPDATE_CONVERSATION_FILES: {
      const files = R.map(
        R.assoc('conversationId', action.conversationId),
        action.files || {},
      )
      return {
        ...state,
        fileByIdMap: secondLevelMerge(state.fileByIdMap, files),
      }
    }

    case UPDATE_MESSAGE:
      return {
        ...state,
        map: {
          ...state.map,
          [action.messageId]: {
            ...state.map[action.messageId],
            status: action.status,
            body: action.text || state.map[action.messageId].body,
          },
        },
        editMessageId: null,
        messageFormattingAreaExpanded: false,
      }
    case UPDATE_MESSAGE_SUCCESS:
      return { ...state, attachmentsToSend: [] }

    case EDIT_MESSAGE: {
      const files = state.map[action.messageId]?.files?.map(
        (it) => state.fileByIdMap[it],
      )
      return {
        ...state,
        editMessageId: action.messageId,
        attachmentsToSend: files,
        messageFormattingAreaExpanded: true,
      }
    }
    case DELETE_MESSAGE:
      return { ...state, editMessageId: null }

    case UPDATE_MESSAGE_FAILURE:
    case DELETE_MESSAGE_FAILURE:
    case CREATE_CONVERSATION_FAILURE:
    case CREATE_CONVERSATION_VALIDATION_FAILURE:
    case SEND_CONVERSATION_MESSAGE_FAILURE:
    case SEND_CONVERSATION_MESSAGE_VALIDATION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        attachmentsToSend: [],
      }

    case CREATE_CONVERSATION:
      return { ...state, message: action.message.body, isLoading: true }
    case SEND_CONVERSATION_DRAFT_MESSAGE:
    case SEND_CONVERSATION_MESSAGE:
      return {
        ...state,
        message: action.message,
        isLoading: true,
        messageFormattingAreaExpanded: false,
      }

    case CREATE_CONVERSATION_SUCCESS:
    case SEND_CONVERSATION_MESSAGE_SUCCESS:
      return {
        ...state,
        message: {},
        isLoading: false,
        attachmentsToSend: [],
        error: null,
      }

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: R.omit([action.messageId], state.map),
        error: null,
      }

    case UPLOAD_FILE:
    case UPLOAD_FILE_LIST:
      return { ...state, isUploadingFile: true }
    case UPLOAD_FILE_SUCCESS: {
      const file = { ...action.file, id: action.file.fileUrl }
      return {
        ...state,
        isUploadingFile: false,
        attachmentsToSend: R.insert(0, file, state.attachmentsToSend),
        error: null,
      }
    }
    case UPLOAD_FILE_LIST_SUCCESS: {
      const files = action.files.map((file: UploadedAttachment) => ({
        ...file,
        id: file.fileUrl,
      }))
      return {
        ...state,
        isUploadingFile: false,
        attachmentsToSend: R.insertAll(0, files, state.attachmentsToSend),
        error: null,
      }
    }
    case FETCH_ALL_CONVERSATION_FILES_FAILURE:
    case UPLOAD_FILE_LIST_FAILURE:
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isUploadingFile: false,
        error: getErrorMessage(action.error),
      }

    case ATTACH_DOCUMENTS_TO_MESSAGE:
      return { ...state, isAttachingDocuments: true }

    case GENERATE_DOCUMENT_INSTANCES_SUCCESS:
      const documentFiles = (action.instances || []).map(
        (file: ConversationFile) => ({ ...file, id: file.fileUrl }),
      )
      return {
        ...state,
        isAttachingDocuments: false,
        isWaitingForAttachedDocument: false,
        attachmentsToSend: state.isWaitingForAttachedDocument
          ? R.insertAll(0, documentFiles, state.attachmentsToSend)
          : state.attachmentsToSend,
        error: null,
      }
    case ATTACH_DOCUMENTS_TO_MESSAGE_FAILURE:
    case GENERATE_DOCUMENT_INSTANCES_FAILURE:
      return {
        ...state,
        isAttachingDocuments: false,
        isWaitingForAttachedDocument: false,
        error: getErrorMessage(action.error),
      }

    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        fileByIdMap: R.omit([action.fileId], state.fileByIdMap),
        error: null,
      }
    case DELETE_FILE_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }

    case CLEAR_FILES_TO_SEND:
      return { ...state, attachmentsToSend: [] }
    case CLEAR_FILE_TO_SEND:
      return {
        ...state,
        attachmentsToSend: state.attachmentsToSend.filter(
          (f) => f.id !== action.fileId,
        ),
      }
    case CLEAR_MESSAGE_FILES:
      return {
        ...state,
        map: {
          ...state.map,
          [action.messageId]: {
            ...state.map[action.messageId],
            files: [],
          },
        },
        editMessageId: null,
        messageFormattingAreaExpanded: false,
      }

    case FETCH_ALL_CONVERSATION_FILES:
      return { ...state, isUploadingFile: true }
    case FETCH_ALL_CONVERSATION_FILES_SUCCESS: {
      const files = R.map(
        R.assoc('conversationId', action.conversationId),
        action.files || {},
      )
      return {
        ...state,
        isUploadingFile: false,
        fileByIdMap: secondLevelMerge(state.fileByIdMap, files),
        error: null,
      }
    }

    case FETCH_CONVERSATION:
      return {
        ...state,
        currentConversationId: action.conversationId,
        editMessageId:
          action.conversationId === state.currentConversationId
            ? state.editMessageId
            : null,
      }

    case CLOSE_MESSAGE_FORMATTING_AREA:
      return { ...state, messageFormattingAreaExpanded: false }
    case MESSAGE_FORMATTING_AREA_FOCUSED:
      return { ...state, messageFormattingAreaExpanded: true }

    case WAIT_FOR_ATTACHED_DOCUMENT:
      return { ...state, isWaitingForAttachedDocument: true }

    case SEND_GENERATED_MESSAGE:
      return { ...state, isSending: true, error: null }
    case SEND_GENERATED_MESSAGE_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentConversationId: action.conversationId,
      }
    case SEND_GENERATED_MESSAGE_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }

    default:
      return state
  }
}

export default conversationMessages
export const getConversationMessages = (
  state: RootState,
): ConversationMessagesState => state.conversationMessages
export const getConversationMessagesMap = (state: RootState) =>
  getConversationMessages(state).map
export const getConversationMessagesCurrentConversationId = (
  state: RootState,
) => getConversationMessages(state).currentConversationId
export const getAttachmentsToSend = (state: RootState) =>
  getConversationMessages(state).attachmentsToSend
export const getFileByIdMap = (state: RootState) =>
  getConversationMessages(state).fileByIdMap
export const getConversationMessagesError = (state: RootState) =>
  getConversationMessages(state).error
export const getConversationMessagesIsLoading = (state: RootState) =>
  getConversationMessages(state).isLoading
export const getConversationMessagesIsSending = (state: RootState) =>
  getConversationMessages(state).isSending
export const getMessage = (id: string | Nil) =>
  createSelector(getConversationMessagesMap, (map) =>
    id ? map[id] : undefined,
  )
export const getEditMessageId = (state: RootState) =>
  getConversationMessages(state).editMessageId
export const getEditMessage = (state: RootState) =>
  getMessage(getEditMessageId(state))(state)
export const getMessageFormattingAreaExpanded = (state: RootState) =>
  getConversationMessages(state).messageFormattingAreaExpanded
export const getIsUploadingFile = (state: RootState) =>
  getConversationMessages(state).isUploadingFile
export const getIsAttachingDocuments = (state: RootState) =>
  getConversationMessages(state).isAttachingDocuments
export const getFilesForMessageIds = (messageIds: string[]) =>
  createSelector(
    [getConversationMessagesMap, getFileByIdMap],
    (messagesMap, filesMap) =>
      (messageIds || [])
        .filter(Boolean)
        .reduce(
          (acc, id) => [
            ...acc,
            ...R.props(messagesMap[id].files || [], filesMap),
          ],
          [] as ConversationFile[],
        ),
  )
export const getChatMessages = (ids: string[]) =>
  createSelector(
    [getConversationMessagesMap, getFileByIdMap, getUsersMap],
    (messagesMap, filesMap, userMap) =>
      (ids || []).map((id) => {
        const message = messagesMap[id]
        return {
          ...message,
          attachments: R.props(message.files || [], filesMap),
          creator: userMap[message.creatorId],
        }
      }),
  )
