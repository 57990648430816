import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  EventTypeName,
  LanguageUtils,
  ReminderEventType,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { REMINDER_CATEGORY } from '~/constants/reminderConstants'
import { Reminder } from '~/types'
import { useEventType } from '~/utils/useEventType'

const StatusNameMapping = {
  [REMINDER_CATEGORY.OPEN]: {},
  [REMINDER_CATEGORY.OVERDUE]: { warning: true },
  [REMINDER_CATEGORY.RESOLVED]: { success: true },
  [REMINDER_CATEGORY.DECLINED]: { disabled: true },
  [REMINDER_CATEGORY.EXPIRED]: { disabled: true },
}

export interface ReminderStateLabelProps extends StateLabelProps {
  reminder?: Reminder
  stateName?: string
}

const ReminderStateLabel = ({
  reminder,
  stateName: stateNameProp,
  ...rest
}: ReminderStateLabelProps) => {
  const { t } = useTranslation('Common')
  const States: ReminderEventType['states'] = useEventType(
    EventTypeName.Reminder,
    'states',
  )

  const stateName =
    stateNameProp || Utils.getConstantName(reminder?.state?.id, States)
  const stateDisplayName =
    stateNameProp ||
    LanguageUtils.getConstantTranslatedName(reminder?.state?.id, States)
  const labelProps = StatusNameMapping[stateName] || {}

  return (
    <StateLabel display="inline" {...labelProps} {...rest}>
      {reminder?.new ? t('Common:NEW') : stateDisplayName}
    </StateLabel>
  )
}

export default ReminderStateLabel
