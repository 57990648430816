import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  BasePuiDialogProps,
  ClassesType,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import AddInventoryItem, { AddInventoryItemHandle } from './AddInventoryItem'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      overflowY: 'hidden',
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 32px) !important',
        margin: '16px auto !important',
      },
    },
    paperSmall: {
      width: 650,
      maxWidth: 650,
    },
    paperLarge: {
      width: 1024,
      maxWidth: 1024,
    },
  }),
  { name: 'InventoryDialog' },
)

interface InventoryDialogProps extends BasePuiDialogProps {
  classes?: ClassesType<typeof useStyles>
  duplicateInventoryId: string | Nil
}

const InventoryDialog = ({
  open,
  onClose,
  duplicateInventoryId,
  classes: classesProp,
}: InventoryDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles({ classes: classesProp })

  const inventoryRef = useRef<AddInventoryItemHandle>(null)
  const [isLargeDialog, setIsLargeDialog] = useState(false)

  const handleClose = (inventoryId: string | Nil) => {
    if (inventoryId) {
      navigate(`/admin/catalog/inventories/catalog/${inventoryId}`)
    }
    if (onClose) {
      onClose()
    }
  }

  const handleVariationFocus = useCallback((variationKey: string | Nil) => {
    setIsLargeDialog(Boolean(variationKey))
  }, [])

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => inventoryRef.current?.confirmSave(),
      }}
      aria-labelledby="inventory-dialog"
      classes={{
        paper: classNames(classes.paper, {
          [classes.paperSmall]: !isLargeDialog,
          [classes.paperLarge]: isLargeDialog,
        }),
      }}
      hasUnsavedChanges={() =>
        Boolean(inventoryRef.current?.hasUnsavedChanges())
      }
      open={open}
      onClose={onClose}
    >
      <AddInventoryItem
        duplicateInventoryId={duplicateInventoryId}
        ref={inventoryRef}
        onClose={handleClose}
        onVariationFocus={handleVariationFocus}
      />
    </PuiDialog>
  )
}

export default InventoryDialog
