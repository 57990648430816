import React from 'react'
import { useSelector } from 'react-redux'
import {
  LanguageUtils,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { Invoice as GraphqlInvoice } from '~/api/graphql/generated/types'
import { getInvoiceStates } from '~/store/reducers/constants'
import { BatchInvoice, InvoiceOrEstimate } from '~/types'

import { INVOICE_CHARGE_SHEET_TYPE } from '../charge-sheet/constants'

export interface InvoiceStatusLabelProps extends StateLabelProps {
  invoice: BatchInvoice | InvoiceOrEstimate | GraphqlInvoice
}

const InvoiceStatusLabel = ({ invoice, ...rest }: InvoiceStatusLabelProps) => {
  const InvoiceStates = useSelector(getInvoiceStates)

  if (!invoice?.stateId && invoice.type !== INVOICE_CHARGE_SHEET_TYPE) {
    return null
  }

  const State = Utils.findById(invoice.stateId, InvoiceStates)
  const displayStatusName = LanguageUtils.getConstantTranslatedName(
    invoice.stateId,
    InvoiceStates,
    invoice.state,
  )

  const props = State ? { [State.color || State.colorVariant]: true } : {}

  return (
    <StateLabel variant="big" {...props} {...rest}>
      {displayStatusName}
    </StateLabel>
  )
}

export default InvoiceStatusLabel
