import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PuiDialog,
  PuiDialogProps,
} from '@pbt/pbt-ui-components'

import { BenchmarkingKeyType } from '~/constants/benchmarkingsTypes'
import {
  createBenchmarkingKey,
  getBenchmarkingLastCreatedKeyId,
  getIsBenchmarkingItemCreating,
} from '~/store/duck/benchmarking'
import { DataHandleWithUnsavedChanges } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import BenchmarkingActivationKeyForm from './BenchmarkingActivationKeyForm'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogContentRoot: {
      padding: theme.spacing(0, 3, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2, 2),
      },
    },
    button: {
      width: 150,
    },
  }),
  { name: 'NewBenchmarkingActivationKeyDialog' },
)

interface BenchmarkingActivationKeyDialogProps extends PuiDialogProps {}

const BenchmarkingActivationKeyDialog = ({
  open,
  onClose,
}: BenchmarkingActivationKeyDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isCreating = useSelector(getIsBenchmarkingItemCreating)
  const lastCreatedKeyId = useSelector(getBenchmarkingLastCreatedKeyId)

  const afterCreation = () => {
    if (lastCreatedKeyId) {
      navigate(`/admin/general/benchmarking/${lastCreatedKeyId}`)
    }
    if (onClose) {
      onClose()
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    afterCreation,
    getIsBenchmarkingItemCreating,
  )

  const activationKeyFormRef = useRef<DataHandleWithUnsavedChanges>()

  const create = () => {
    if (activationKeyFormRef.current?.validate()) {
      setCloseAfterCreationOn()
      dispatch(
        createBenchmarkingKey({
          ...activationKeyFormRef.current.get(),
          keyType: BenchmarkingKeyType.BENCHMARKING_LITE,
        }),
      )
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: create,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={isCreating}
          loading={isCreating}
          onClick={create}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="benchmarking-new-activation-key-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      hasUnsavedChanges={() =>
        Boolean(activationKeyFormRef.current?.hasUnsavedChanges())
      }
      open={open}
      title={t('Admin:BENCHMARK.NEW_ACTIVATION_KEY')}
      onClose={onClose}
    >
      <BenchmarkingActivationKeyForm ref={activationKeyFormRef} />
    </PuiDialog>
  )
}

export default BenchmarkingActivationKeyDialog
