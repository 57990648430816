import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Nil, Patient } from '@pbt/pbt-ui-components'

import { MembershipPlan } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  CREATE_PATIENT,
  CREATE_PATIENT_CALLBACK,
  CREATE_PATIENT_FAILURE,
  CREATE_PATIENT_SUCCESS,
  EDIT_PATIENT,
  EDIT_PATIENT_FAILURE,
  EDIT_PATIENT_SUCCESS,
  LINK_PATIENTS_TO_CHEWY_ACCOUNT,
  UNLINK_PATIENTS_FROM_CHEWY_ACCOUNT,
  UPDATE_PATIENT_PARENT,
  UPDATE_PATIENT_PARENT_FAILURE,
  UPDATE_PATIENT_PARENT_SUCCESS,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_FAILURE,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_SUCCESS,
  UPDATE_PATIENTS,
} from '../actions/types/patients'
import {
  CANCEL_WELLNESS_PLAN_OLD_SUCCESS,
  CANCEL_WELLNESS_PLAN_SUCCESS,
} from '../actions/types/wellnessPlans'
import type { RootState } from '../index'

export type PatientsState = {
  currentPatientId: string | null
  error: string | null
  isLoading: boolean
  map: Record<string, Patient>
}

export const PATIENTS_INITIAL_STATE: PatientsState = {
  map: {},
  isLoading: false,
  currentPatientId: null,
  error: null,
}

const patients = (
  state: PatientsState = PATIENTS_INITIAL_STATE,
  action: AnyAction,
): PatientsState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return PATIENTS_INITIAL_STATE
    case UPDATE_PATIENTS:
      return { ...state, map: secondLevelMerge(state.map, action.patients) }
    case CREATE_PATIENT:
    case CREATE_PATIENT_CALLBACK:
      return { ...state, isLoading: true }
    case CREATE_PATIENT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CREATE_PATIENT_SUCCESS:
      return { ...state, isLoading: false, currentPatientId: action.patientId }
    case EDIT_PATIENT:
      return { ...state, isLoading: true }
    case EDIT_PATIENT_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_PATIENT_PARENT:
      return { ...state, isLoading: true }
    case UPDATE_PATIENT_PARENT_SUCCESS:
      return { ...state, isLoading: false }
    case UPDATE_PATIENT_PARENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT:
      return { ...state, isLoading: true }
    case UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_SUCCESS:
      return { ...state, isLoading: false }
    case UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    // TODO: check for WP_CANCEL_REFUND feature
    case CANCEL_WELLNESS_PLAN_SUCCESS:
      const remainingPlanIds = R.pluck('planLogId')(action.plans || [])
      const updatedPlans = !R.isEmpty(remainingPlanIds)
        ? state.map[action.patientId].plans?.filter((plan) =>
            remainingPlanIds.includes(plan.wplanLogId),
          )
        : null
      return {
        ...state,
        map: {
          ...state.map,
          [action.patientId]: {
            ...state.map[action.patientId],
            plans: updatedPlans,
          },
        },
      }
    case CANCEL_WELLNESS_PLAN_OLD_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.patientId]: {
            ...state.map[action.patientId],
            plans: state.map[action.patientId].plans?.map((plan) => {
              const updatedPlan = action.plans.find(
                (item: MembershipPlan) => item.planLogId === plan.wplanLogId,
              )

              return updatedPlan
                ? {
                    ...plan,
                    wplanStatus: updatedPlan.state,
                    wplanCancellationDate: updatedPlan.cancellationDate,
                  }
                : plan
            }),
          },
        },
      }
    case LINK_PATIENTS_TO_CHEWY_ACCOUNT:
      const updatedMap = { ...state.map }

      action.patientIds.forEach((patientId: string) => {
        if (updatedMap[patientId]) {
          updatedMap[patientId] = {
            ...updatedMap[patientId],
            hasKyriosId: true,
          }
        }
      })

      return {
        ...state,
        map: updatedMap,
      }

    case UNLINK_PATIENTS_FROM_CHEWY_ACCOUNT:
      const newMap = { ...state.map }

      action.patientIds.forEach((patientId: string) => {
        if (newMap[patientId]) {
          newMap[patientId] = {
            ...newMap[patientId],
            hasKyriosId: false,
          }
        }
      })

      return {
        ...state,
        map: newMap,
      }
    default:
      return state
  }
}

export default patients
export const getPatients = (state: RootState): PatientsState => state.patients
export const getPatientsIsLoading = (state: RootState) =>
  getPatients(state).isLoading
export const getCurrentPatientId = (state: RootState) =>
  getPatients(state).currentPatientId
export const getPatientsMap = (state: RootState) => getPatients(state).map
export const getPatient = (id: string | Nil) =>
  createSelector(getPatientsMap, (map) => (id ? map[id] : undefined))
export const getPatientName = (id: string | Nil) =>
  createSelector(getPatient(id), (patient) => patient?.name)
export const getPatientsList = (ids: string[] | Nil) =>
  createSelector(getPatientsMap, (map) =>
    R.props(ids || [], map).filter(Boolean),
  )
