import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import * as R from 'ramda'
import { Defaults } from '@pbt/pbt-ui-components'

import { clearSearchOrders, searchOrders } from '~/store/actions/orders'
import { getAppliedPatientRestrictions } from '~/store/reducers/orders'
import {
  getAppointmentId,
  getClientId,
  getOrderFilters,
  getPatientId,
} from '~/store/reducers/soap'
import { ChargeQueryParams, OrderListType } from '~/types'

interface UseChargeInlineSearch {
  patientId?: string
  validate: (() => boolean) | undefined
}

export const useChargeInlineSearch = ({
  validate,
  patientId: patientIdProp,
}: UseChargeInlineSearch) => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const appointmentId = useSelector(getAppointmentId)
  const clientId = useSelector(getClientId)
  const orderFilters = useSelector(getOrderFilters)
  const appliedPatientRestrictions = useSelector(getAppliedPatientRestrictions)
  const soapPatientId = useSelector(getPatientId)

  const patientId = soapPatientId || patientIdProp

  const [searchTerm, setSearchTerm] = useState(
    searchParams.get(ChargeQueryParams.CHARGE_SEARCH_QUERY) ?? '',
  )
  const [searchDisabled, setSearchDisabled] = useState(false)

  const onSearchChange = useCallback(
    (value: string) => {
      if (!clientId || !patientId) {
        return
      }

      searchParams.set(ChargeQueryParams.CHARGE_SEARCH_QUERY, value)
      setSearchParams(searchParams)
      setSearchTerm(value)

      if (value.length < Defaults.MIN_SEARCH_LENGTH) {
        dispatch(clearSearchOrders())
        return
      }

      dispatch(
        searchOrders({
          applyPatientRestriction: appliedPatientRestrictions,
          filters: R.pluck('type', orderFilters),
          clientId,
          eventId: appointmentId,
          forShipments: false,
          listType: OrderListType.FULL,
          patientId,
          searchTerm: value,
          withTasks: true,
        }),
      )
    },
    [
      appointmentId,
      clientId,
      appliedPatientRestrictions,
      orderFilters,
      patientId,
      searchParams,
    ],
  )

  const onSearchBlur = useCallback(() => {
    setSearchDisabled(false)
  }, [])
  const onSearchFocus = useCallback(() => {
    setSearchDisabled(!validate?.())
  }, [])

  return {
    onSearchChange,
    onSearchBlur,
    onSearchFocus,
    searchDisabled,
    searchTerm,
  }
}
