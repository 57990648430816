import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BaseEventType,
  EventTypeName,
  PermissionArea,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import PuiSwitch from '~/components/common/PuiSwitch'
import TimeUnitsSelect from '~/components/common/TimeUnitsSelect'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getEventType, getFinalizeTimeUnits } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeSoapConfigurationFields,
} from '~/types'
import { getConstantsList, isBool } from '~/utils'
import useGetBusinessSupportedAppointments from '~/utils/useGetBusinessSupportedAppointments'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  () => ({
    numericInput: {
      width: 50,
    },
  }),
  { name: 'SoapConfigurationSection' },
)

const SoapConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const FinalizeTimeUnits = useSelector(getFinalizeTimeUnits)
  const EventType = useSelector(getEventType)

  const DayTimeUnit = Utils.findConstantIdByName('Day', FinalizeTimeUnits)
  const AppointmentEvent: BaseEventType<EventTypeName.Appointment> =
    Utils.findConstantByName('Appointment', EventType) || {}
  const AppointmentEventSubTypes = AppointmentEvent.subTypes || []

  const businessSupportedAppointments = useGetBusinessSupportedAppointments(
    AppointmentEventSubTypes,
    business,
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'soapAutoFinalizeEnabled',
        type: 'toggle',
        initialValue: isBool(business.soapAutoFinalizeEnabled)
          ? business.soapAutoFinalizeEnabled
          : false,
      },
      {
        name: 'soapAutoFinalizeOffset',
        initialValue: business.soapAutoFinalizeOffset ?? 30,
      },
      {
        name: 'soapAutoFinalizeTimeUnitId',
        initialValue: business.soapAutoFinalizeTimeUnitId ?? DayTimeUnit,
      },
      {
        name: 'soapAutoTaskCreationEnabled',
        type: 'toggle',
        initialValue: isBool(business.soapAutoTaskCreationEnabled)
          ? business.soapAutoTaskCreationEnabled
          : false,
      },
      {
        name: 'soapFinalizedAppointmentTypeIds',
        type: 'select',
        initialValue: getConstantsList(
          business.soapFinalizedAppointmentTypeIds,
          AppointmentEvent.subTypes,
        ),
      },
    ],
    false,
  )

  const {
    soapAutoFinalizeEnabled,
    soapAutoFinalizeOffset,
    soapAutoFinalizeTimeUnitId,
    soapAutoTaskCreationEnabled,
    soapFinalizedAppointmentTypeIds,
  } = fields

  usePracticeFieldsSection<PracticeSoapConfigurationFields>({
    business,
    fields,
    parsedFields: {
      soapAutoFinalizeEnabled: soapAutoFinalizeEnabled.value,
      soapAutoFinalizeOffset: soapAutoFinalizeOffset.value,
      soapAutoFinalizeTimeUnitId: soapAutoFinalizeTimeUnitId.value,
      soapAutoTaskCreationEnabled: soapAutoTaskCreationEnabled.value,
      soapFinalizedAppointmentTypeIds: R.pluck(
        'id',
        soapFinalizedAppointmentTypeIds.value as { id: string }[],
      ),
    },
    sectionName: PracticeDetailsPanels.SOAP_CONFIGURATION,
    validate,
    reset,
  })

  return (
    <>
      <PuiSwitch
        disabled={!permissions.update}
        field={soapAutoFinalizeEnabled}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={t(
              'Businesses:SOAP_CONFIGURATION.SPECIFY_LENGTH_OF_TIME_AFTER_WHICH_SOAP_WILL_BE_LOCKED',
            )}
            variant="body"
          >
            {t(
              'Businesses:SOAP_CONFIGURATION.AUTOMATICALLY_FINALIZE_SOAP_AFTER_SET_TIME',
            )}
          </TextWithTooltip>
        }
      />
      {soapAutoFinalizeEnabled.value && (
        <Grid
          container
          item
          alignItems="center"
          pl={6}
          spacing={1}
          wrap="nowrap"
        >
          <Grid item>
            <Text variant="body">{t('Common:FINALIZE_ACTION')}</Text>
          </Grid>
          <Grid item>
            <NumericInput
              className={classes.numericInput}
              field={soapAutoFinalizeOffset}
              min={0}
            />
          </Grid>
          <Grid item>
            <TimeUnitsSelect
              fullWidth
              amount={soapAutoFinalizeOffset.value}
              disabled={!permissions.update}
              field={soapAutoFinalizeTimeUnitId}
              items={FinalizeTimeUnits}
            />
          </Grid>
          <Grid item>
            <Text variant="body">
              {t(
                'Businesses:SOAP_CONFIGURATION.AFTER_APPOINTMENT_END_DATE',
              ).toLowerCase()}
            </Text>
          </Grid>
        </Grid>
      )}
      <PuiSwitch
        disabled={!permissions.update}
        field={soapAutoTaskCreationEnabled}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={t(
              'Businesses:SOAP_CONFIGURATION.SPECIFY_APPOINTMENT_TYPES_AND_WE_WILL_AUTOMATICALLY_CREATE_TASK',
            )}
            variant="body"
          >
            {t(
              'Businesses:SOAP_CONFIGURATION.AUTOMATICALLY_CREATE_TASK_TO_REVIEW_SOAP',
            )}
          </TextWithTooltip>
        }
      />
      {soapAutoTaskCreationEnabled.value && (
        <Grid
          container
          item
          alignItems="center"
          pl={6}
          spacing={1}
          wrap="nowrap"
        >
          <Grid item xs>
            <PuiSelectAll
              disabled={!permissions.update}
              field={soapFinalizedAppointmentTypeIds}
              items={businessSupportedAppointments}
              noneSelectedText={t(
                'Businesses:SOAP_CONFIGURATION.CREATE_TASKS_FOR_APPOINTMENT_TYPES',
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default memo(SoapConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
