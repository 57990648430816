import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  RhapsodyExporterTypes,
  SupportedPims,
} from '../../types/entities/migrationV2'
import {
  clearMigrationExporterUrl,
  fetchMigrationExporterUrl,
  getExporterUrl,
} from '../duck/migrationV2'

const PimsToExporterType: Record<
  SupportedPims,
  RhapsodyExporterTypes | undefined
> = {
  [SupportedPims.Avimark]: RhapsodyExporterTypes.RE_AVIMARK,
  [SupportedPims.Cornerstone]: RhapsodyExporterTypes.RE_CORNERSTONE,
  [SupportedPims.ImproMed]: RhapsodyExporterTypes.RE_IMPROMED_INFINITY,
  [SupportedPims.IntraVet]: RhapsodyExporterTypes.RE_INTRAVET_CLASSIC,
  [SupportedPims.Covetrus]: undefined,
}

export const useRhapsodyExporter = (exporterPimsName?: string) => {
  const dispatch = useDispatch()
  const exporterUrl = useSelector(getExporterUrl)
  const rhapsodyExporterType = exporterPimsName
    ? PimsToExporterType[exporterPimsName as SupportedPims]
    : null

  // Download exporter from url and reset value
  useEffect(() => {
    if (exporterUrl) {
      window.location.href = exporterUrl
      dispatch(clearMigrationExporterUrl())
    }
  }, [exporterUrl])

  const downloadExporter = () => {
    if (!rhapsodyExporterType) {
      return
    }

    dispatch(
      fetchMigrationExporterUrl({
        exporterType: rhapsodyExporterType,
      }),
    )
  }

  return {
    downloadExporter,
    rhapsodyExporterType,
  }
}
