import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Adjustment, InventoryItem } from '~/types'

import { updateVariationSuccess } from '../actions/variations'
import {
  BULK_CREATE_ADJUSTMENTS,
  bulkCreateAdjustments,
  bulkCreateAdjustmentsFailure,
  bulkCreateAdjustmentsSuccess,
  CREATE_ADJUSTMENT,
  createAdjustment,
  createAdjustmentFailure,
  createAdjustmentSuccess,
  DELETE_ADJUSTMENT,
  deleteAdjustment,
  deleteAdjustmentFailure,
  deleteAdjustmentSuccess,
  EDIT_ADJUSTMENT,
  editAdjustment,
  editAdjustmentFailure,
  editAdjustmentSuccess,
  FETCH_ADJUSTMENT,
  FETCH_ADJUSTMENTS,
  FETCH_MORE_ADJUSTMENTS,
  FETCH_VARIATION_ADJUSTMENTS,
  fetchAdjustment,
  fetchAdjustmentFailure,
  fetchAdjustments,
  fetchAdjustmentsFailure,
  fetchAdjustmentsSuccess,
  fetchAdjustmentSuccess,
  fetchMoreAdjustments,
  fetchMoreAdjustmentsFailure,
  fetchMoreAdjustmentsSuccess,
  fetchVariationAdjustments,
  fetchVariationAdjustmentsFailure,
  fetchVariationAdjustmentsSuccess,
} from '../duck/inventoryAdjustments'
import { clearHistory as clearOnHandHistory } from '../duck/onHandCatalog'
import { getInventory } from '../reducers/inventories'
import {
  refreshOnHandCatalog,
  refreshVariationInfo as refreshOnHandVariationInfo,
} from './onHandCatalog'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchAdjustmentsSaga({
  from,
  to,
}: ReturnType<typeof fetchAdjustments>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(API.fetchAdjustments, from, to)
    yield call(updateEntities, entities)
    yield put(fetchAdjustmentsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchAdjustmentsFailure(error as ApiError))
  }
}

export function* fetchMoreAdjustmentsSaga({
  from,
  to,
}: ReturnType<typeof fetchMoreAdjustments>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(API.fetchAdjustments, from, to)
    yield call(updateEntities, entities)
    yield put(fetchMoreAdjustmentsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreAdjustmentsFailure(error as ApiError))
  }
}

export function* fetchAdjustmentSaga({
  adjustmentId,
}: ReturnType<typeof fetchAdjustment>) {
  try {
    const { result, entities } = yield requestAPI(
      API.fetchAdjustment,
      adjustmentId,
    )
    yield call(updateEntities, entities)
    yield put(fetchAdjustmentSuccess(result))
  } catch (error) {
    yield put(fetchAdjustmentFailure(error as ApiError))
  }
}

function* propagateVariationUpdates(adjustment: Adjustment) {
  if (adjustment.variation && adjustment.variation?.inventoryItemId) {
    const inventory: InventoryItem = yield select(
      getInventory(adjustment.variation?.inventoryItemId),
    )
    if (inventory?.variations) {
      yield put(
        updateVariationSuccess(
          adjustment.variation?.inventoryItemId,
          adjustment.variation,
        ),
      )
    }
  }
  const variationId = adjustment.variation?.id
  if (variationId) {
    yield refreshOnHandVariationInfo(variationId)
  }
}

export function* createAdjustmentSaga({
  adjustment,
}: ReturnType<typeof createAdjustment>) {
  try {
    const { result, entities } = yield requestAPI(
      API.createAdjustment,
      adjustment,
    )
    yield call(updateEntities, entities)
    yield propagateVariationUpdates(entities.adjustments[result])
    yield put(clearOnHandHistory())
    yield put(createAdjustmentSuccess(result))
  } catch (error) {
    yield put(createAdjustmentFailure(error as ApiError))
  }
}

export function* bulkCreateAdjustmentsSaga({
  adjustments,
}: ReturnType<typeof bulkCreateAdjustments>) {
  try {
    yield requestAPI(API.bulkCreateAdjustments, adjustments)
    yield refreshOnHandCatalog()
    yield put(bulkCreateAdjustmentsSuccess())
  } catch (error) {
    yield put(bulkCreateAdjustmentsFailure(error as ApiError))
  }
}

// It won't be used from PR-1132
export function* editAdjustmentSaga({
  adjustment,
}: ReturnType<typeof editAdjustment>) {
  try {
    const { result, entities } = yield requestAPI(
      API.editAdjustment,
      adjustment,
    )
    yield call(updateEntities, entities)
    yield propagateVariationUpdates(entities.adjustments[result])
    yield put(clearOnHandHistory())
    yield put(editAdjustmentSuccess(result))
  } catch (error) {
    yield put(editAdjustmentFailure(error as ApiError))
  }
}

// It won't be used from PR-1132
export function* deleteAdjustmentSaga({
  adjustmentId,
}: ReturnType<typeof deleteAdjustment>) {
  try {
    yield requestAPI(API.deleteAdjustment, adjustmentId)
    yield put(deleteAdjustmentSuccess(adjustmentId))
  } catch (error) {
    yield put(deleteAdjustmentFailure(error as ApiError))
  }
}

export function* fetchVariationAdjustmentsSaga({
  variationId,
}: ReturnType<typeof fetchVariationAdjustments>) {
  try {
    const {
      result: { data: list },
      entities,
    } = yield requestAPI(API.fetchVariationAdjustments, variationId, 0, 100)
    yield call(updateEntities, entities)
    yield put(fetchVariationAdjustmentsSuccess(list))
  } catch (error) {
    yield put(fetchVariationAdjustmentsFailure(error as ApiError))
  }
}

function* watchFetchVariationAdjustments() {
  yield takeLatest(FETCH_VARIATION_ADJUSTMENTS, fetchVariationAdjustmentsSaga)
}

function* watchFetchAdjustments() {
  yield takeLatest(FETCH_ADJUSTMENTS, fetchAdjustmentsSaga)
}

function* watchFetchMoreAdjustments() {
  yield takeLatest(FETCH_MORE_ADJUSTMENTS, fetchMoreAdjustmentsSaga)
}

function* watchFetchAdjustment() {
  yield takeLatest(FETCH_ADJUSTMENT, fetchAdjustmentSaga)
}

function* watchCreateAdjustment() {
  yield takeLatest(CREATE_ADJUSTMENT, createAdjustmentSaga)
}

function* watchBulkCreateAdjustments() {
  yield takeLatest(BULK_CREATE_ADJUSTMENTS, bulkCreateAdjustmentsSaga)
}

function* watchEditAdjustment() {
  yield takeLatest(EDIT_ADJUSTMENT, editAdjustmentSaga)
}

function* watchDeleteAdjustment() {
  yield takeLatest(DELETE_ADJUSTMENT, deleteAdjustmentSaga)
}

export default function* inventoryAdjustmentsSaga() {
  yield all([
    watchFetchVariationAdjustments(),
    watchFetchAdjustments(),
    watchFetchMoreAdjustments(),
    watchFetchAdjustment(),
    watchCreateAdjustment(),
    watchBulkCreateAdjustments(),
    watchEditAdjustment(),
    watchDeleteAdjustment(),
  ])
}
