import { includes, pluck } from 'ramda'
import { PracticeCloningItemsConstant, Utils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { PracticeCloningSession } from '~/types/entities/practiceCloning'

export const findDependencies = (
  dependencies: string[] = [],
  haystack: PracticeCloningItemsConstant[] = [],
) => haystack.filter((candidate) => includes(candidate?.id, dependencies))

export const getNames = (items: { name: string }[]) =>
  pluck('name', items || []).join(', ')

export const extendEntityName = (
  name: string,
  dependencyConfigs: PracticeCloningItemsConstant[],
) =>
  i18n.t('Businesses:MIGRATION.PRACTICE_CLONING_ENTITY_NAME', {
    name,
    necessaryNames: getNames(dependencyConfigs),
  })

export const buildPracticeCloningEntity = (
  config: PracticeCloningItemsConstant,
  index: number,
  configs: PracticeCloningItemsConstant[],
) => {
  const { dependencies, name } = config
  const dependencyConfigs = dependencies?.length
    ? findDependencies(dependencies, configs)
    : null
  const extendedName = dependencyConfigs
    ? extendEntityName(name, dependencyConfigs)
    : name

  return {
    ...config,
    name: extendedName,
  }
}

export const enrichExportEntitiesStatus = (
  PracticeCloningItems: PracticeCloningItemsConstant[],
  session: PracticeCloningSession,
) =>
  (session?.items || []).map((itemId) => {
    const item = Utils.findById(itemId, PracticeCloningItems)
    const itemStatus = Utils.findByProp(
      itemId,
      session.processedItems || [],
      'itemId',
    )

    return {
      ...item,
      done: itemStatus?.done || 0,
    }
  })
