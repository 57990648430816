import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { uniq } from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import ConfusedDog from '~/components/common/images/ConfusedDog.svg'
import Link from '~/components/common/link/Link'
import { getCurrentUser } from '~/store/reducers/auth'
import { readFromStorage } from '~/utils/recentList'

import ErrorContactForm from './ErrorContactForm'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      top: `${(theme.mixins.toolbar.minHeight as number) + 100}px`,
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above'),
      maxWidth: 729,
      marginBottom: theme.spacing(3),
    },
    splash: {
      borderRadius: '0 2px 2px 2px',
      boxShadow: theme.constants.blockShadow,
      marginBottom: theme.spacing(3),
    },
    header: {
      position: 'relative',
      padding: theme.spacing(5, 9, 2, 24),
      backgroundColor: theme.colors.tableBackground,
    },
    footer: {
      padding: theme.spacing(3, 9, 3, 24),
      borderRadius: '0 0 2px 0',
      background: theme.colors.splashScreenBackground,
    },
    recentItem: {
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
      marginTop: theme.spacing(0.5),
    },
    dogImage: {
      position: 'absolute',
      left: 44,
      bottom: -13,
    },
    detailsText: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
    },
    letUsKnow: {
      cursor: 'pointer',
      color: theme.colors.title,
      textDecoration: 'underline',
    },
  }),
  { name: 'UnexpectedErrorSplashScreen' },
)

const RECENT_ITEMS_LIMIT = 5

export interface UnexpectedErrorSplashScreenProps {
  componentStack: string
  message: string
  stack: string
}

const UnexpectedErrorSplashScreen = ({
  componentStack,
  message,
  stack,
}: UnexpectedErrorSplashScreenProps) => {
  const classes = useStyles()
  const location = useLocation()
  const currentUser = useSelector(getCurrentUser)
  const { t } = useTranslation(['Common', 'Errors'])

  const [detailsExpanded, setDetailsExpanded] = useState(false)

  const recentListItems = uniq(
    currentUser ? readFromStorage(currentUser.id) : [],
  )
    // filter current page url so user doesn't get this error once again
    .filter(({ url }) => url !== location.pathname + location.search)
    .slice(0, RECENT_ITEMS_LIMIT)

  const hasRecentItems = recentListItems.length > 0

  return (
    <Grid container item className={classes.root} direction="column">
      <Grid container item className={classes.splash} direction="column">
        <Grid container item className={classes.header} direction="column">
          <img
            alt={t('Common:CONFUSED_DOG')}
            className={classes.dogImage}
            src={ConfusedDog}
          />
          <ErrorContactForm
            detailsExpanded={detailsExpanded}
            error={`${message}</br>${stack}</br>${componentStack}`}
            message={
              <>
                {t(
                  'Errors:UNEXPECTED_ERROR_SPLASH_SCREEN.PAGE_CANNOT_BE_REACHED',
                )}
                &nbsp;
              </>
            }
            setDetailsExpanded={setDetailsExpanded}
          />
        </Grid>
        {hasRecentItems && (
          <Grid container item className={classes.footer} direction="column">
            <Text strong>
              {t('Errors:UNEXPECTED_ERROR_SPLASH_SCREEN.BACK_TO')}
            </Text>
            {recentListItems.map(({ title, url }) => (
              <Link
                className={classes.recentItem}
                key={`${title}-${url}`}
                to={url}
              >
                {title}
              </Link>
            ))}
          </Grid>
        )}
      </Grid>
      <Collapse in={detailsExpanded}>
        <Text className={classes.detailsText} variant="body3">
          {message}
        </Text>
        <Text className={classes.detailsText} variant="body3">
          {stack}
        </Text>
        <Text className={classes.detailsText} variant="body3">
          {componentStack}
        </Text>
      </Collapse>
    </Grid>
  )
}

export default UnexpectedErrorSplashScreen
