import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Calendar,
  DateUtils,
  NamedEntity,
  Nil,
  PuiTextField,
  Text,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import InfoList, { InfoListItem } from '~/components/common/InfoList'
import ReminderStateSelect from '~/components/common/selects/ReminderStateSelect'
import { DataHandle, Reminder } from '~/types'
import { useReminderDetailsFields } from '~/utils/useReminderDetailsFields'

import { addAnyPrefixIfNeeded } from '../admin/catalog/reminder-protocol-groups/reminderProtocolUtils'
import { getReminderTriggerLabel } from './reminderUtils'

const getReminderListLabel = (list: NamedEntity[]) =>
  list.map(addAnyPrefixIfNeeded).filter(Boolean).join('\n\n')

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    editIcon: {
      color: theme.colors.editIconColor,
    },
    editableField: {
      paddingRight: theme.spacing(5),
    },
    list: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'ReminderDetailsDialog' },
)

export interface ReminderDetailsHandle extends DataHandle {}

interface ReminderDetailsProps {
  disabled: boolean
  reminder: Reminder | Nil
}

const ReminderDetails = forwardRef<ReminderDetailsHandle, ReminderDetailsProps>(
  function ReminderDetails({ disabled, reminder }, ref) {
    const classes = useStyles()
    const { t } = useTranslation(['Common', 'Reminders', 'Time', 'Validations'])

    const {
      fields: {
        name,
        dueDatetime: dueDate,
        expireDatetime: expireDate,
        notes,
        stateId: state,
      },
      validate,
    } = useReminderDetailsFields(reminder)

    const [nameEditMode, setNameEditMode] = useState(false)
    const [dueDateEditMode, setDueDateEditMode] = useState(false)
    const [expireDateEditMode, setExpireDateEditMode] = useState(false)

    const reminderInfo = [
      {
        name: name.label,
        value: !nameEditMode && reminder?.name,
        component: nameEditMode ? (
          <PuiTextField
            className={classes.editableField}
            disabled={disabled}
            field={name}
          />
        ) : (
          <IconButton
            className={classes.editIcon}
            disabled={disabled}
            size="large"
            onClick={disabled ? undefined : () => setNameEditMode(true)}
          >
            <EditIcon />
          </IconButton>
        ),
      },
      {
        name: dueDate.label,
        value: !dueDateEditMode && DateUtils.formatDate(reminder?.dueDatetime),
        component: dueDateEditMode ? (
          <Calendar
            fullWidth
            className={classes.editableField}
            disabled={disabled}
            field={dueDate}
          />
        ) : (
          <IconButton
            className={classes.editIcon}
            disabled={disabled}
            size="large"
            onClick={disabled ? undefined : () => setDueDateEditMode(true)}
          >
            <EditIcon />
          </IconButton>
        ),
      },
      {
        name: expireDate.label,
        value:
          !expireDateEditMode && DateUtils.formatDate(reminder?.expireDatetime),
        component: expireDateEditMode ? (
          <Calendar
            fullWidth
            className={classes.editableField}
            disabled={disabled}
            field={expireDate}
          />
        ) : (
          <IconButton
            className={classes.editIcon}
            disabled={disabled}
            size="large"
            onClick={disabled ? undefined : () => setExpireDateEditMode(true)}
          >
            <EditIcon />
          </IconButton>
        ),
      },
      {
        name: t('Common:STATUS'),
        component: <ReminderStateSelect disabled={disabled} field={state} />,
      },
      reminder?.triggeredBy && {
        name: t('Reminders:REMINDER_DETAILS.TRIGGERED_BY'),
        value: getReminderTriggerLabel(reminder?.triggeredBy),
      },
      reminder?.resolvedBy && {
        name: t('Reminders:REMINDER_DETAILS.RESOLVED_BY'),
        value: getReminderTriggerLabel(reminder?.resolvedBy),
      },
      reminder?.due?.length && {
        name: t('Reminders:LABEL.DUE_OR_EXPECTED_PERIOD_AMOUNT'),
        value: getReminderListLabel(reminder?.due),
      },
      reminder?.resolvesWith?.length && {
        name: t('Common:WHAT_RESOLVES_IT'),
        value: getReminderListLabel(reminder?.resolvesWith),
      },
    ].filter(Boolean) as InfoListItem[]

    const getReminder = () => ({
      ...reminder,
      patientId: reminder?.patientId || reminder?.patient,
      state: state.value,
      notes: notes.value,
      name: nameEditMode ? name.value : reminder?.name,
      dueDatetime: dueDateEditMode ? dueDate.value : reminder?.dueDatetime,
      expireDatetime: expireDateEditMode
        ? expireDate.value
        : reminder?.expireDatetime,
    })

    useEffect(() => {
      if (dueDate.valid === false) {
        setDueDateEditMode(true)
      }

      if (expireDate.valid === false) {
        setExpireDateEditMode(true)
      }
    }, [dueDate.valid, expireDate.valid])

    useImperativeHandle(ref, () => ({
      get: getReminder,
      validate,
    }))

    return (
      <Grid container className={classes.root} direction="column" p={2}>
        <InfoList className={classes.list} items={reminderInfo} />
        <Text strong pt={2} variant="subheading3">
          {t('Common:NOTES')}
        </Text>
        <PuiTextField
          multiline
          disabled={disabled}
          field={notes}
          margin="none"
          minRows={3}
          variant="outlined"
        />
      </Grid>
    )
  },
)

export default ReminderDetails
