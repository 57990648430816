import { gql } from '@apollo/client'

export const PREVIEW_LIST_ITEM_FIELDS = gql`
  fragment PreviewListItemFields on PreviewListItemDto {
    id
    name
    prices {
      id
      name
      newPrice
      currentPrice
      difference
      percentDifference
      active
      deleted
      markupPercent
    }
  }
`

export const PRICE_DTO_FIELDS = gql`
  fragment PriceDtoFields on PriceDto {
    id
    name
    value
    unitId
    speciesId
    genderId
    updatedAt
    priceUnitsSize
    packageTypeId
    perPackageUnitsId
    timeUnitId
    timeUnitSize
    minTimeUnitSize
    maxTimeUnitSize
  }
`
