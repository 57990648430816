import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import AccentLabel, {
  AccentLabelProps,
} from '~/components/common/labels/AccentLabel'
import { getEventState } from '~/store/reducers/constants'

const useStyles = makeStyles(
  () => ({
    stateLabel: {
      maxWidth: 'fit-content',
    },
    stateLabelSize: {
      fontSize: '1.5rem',
    },
  }),
  { name: 'TaskStateLabel' },
)

export interface TaskStateLabelProps extends AccentLabelProps {
  stateId?: string
}

const TaskStateLabel = ({
  className,
  stateId,
  ...rest
}: TaskStateLabelProps) => {
  const classes = useStyles()
  const EventState = useSelector(getEventState)

  const taskState = Utils.findById(stateId, EventState) || {}
  const taskStateDisplayName = LanguageUtils.getConstantTranslatedName(
    stateId,
    EventState,
  )

  return (
    <AccentLabel
      className={classNames(className, classes.stateLabel, {
        [classes.stateLabelSize]: !stateId,
      })}
      colorVariant={taskState.colorVariant}
      name={taskStateDisplayName}
      {...R.omit(['colorVariant', 'className', 'name'], rest)}
    />
  )
}

export default TaskStateLabel
