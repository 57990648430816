import React from 'react'
import { Box, CircularProgress, Divider, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType } from '@pbt/pbt-ui-components'

import PuiTabs, { PuiTab } from './PuiTabs'

const useStyles = makeStyles(
  (theme) => ({
    dividerContainer: {
      marginTop: -1,
    },
    tabPanelContainer: {
      maxWidth: '100%',
    },
    tabContainer: {
      padding: 0,
      marginTop: 0,
      '&:first-of-type': {
        marginLeft: theme.spacing(1),
      },
    },
    tabsContainer: {
      borderBottom: 1,
      borderColor: 'divider',
    },
    tabRoot: {
      minHeight: 27,
      padding: theme.spacing(0, 1),
    },
  }),
  { name: 'PuiTabsPanel' },
)

type Tab = PuiTab
type TabPanel<T> = T

export interface PuiTabsPanelProps<T> {
  actions?: React.ReactNode
  children: (
    { tabPanel }: { tabPanel: TabPanel<T> },
    index: number,
  ) => React.ReactNode
  classes?: ClassesType<typeof useStyles>
  internalDividerMargin?: number
  isLoading?: boolean
  onChange: (value: number) => void
  panels: TabPanel<T>[]
  tabValue: number | boolean
  tabs: Tab[]
}

type PuiTabsPanelComponent = <T>(props: PuiTabsPanelProps<T>) => JSX.Element

const PuiTabsPanel: PuiTabsPanelComponent = ({
  actions,
  classes: classesProp,
  tabs,
  tabValue,
  children,
  isLoading = false,
  onChange,
  panels,
}) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid container item direction="column">
      <PuiTabs
        actions={actions}
        classes={{
          tabs: classes.tabContainer,
          tabRoot: classes.tabRoot,
        }}
        selectedTab={tabValue}
        tabs={tabs}
        variant="standard"
        onSelectedTabChange={onChange}
      />
      <Grid className={classes.dividerContainer}>
        <Divider />
      </Grid>
      {isLoading ? (
        <Box
          alignItems="center"
          display="flex"
          height={120}
          justifyContent="center"
        >
          <CircularProgress size={48} />
        </Box>
      ) : (
        <>
          {panels.map((tabPanel, index) => {
            const currentTab = tabs[index]
            const tabPanelId = currentTab.id?.toString() || currentTab.label

            return (
              <Box
                aria-labelledby={tabPanelId}
                className={classes.tabPanelContainer}
                hidden={tabValue !== index || currentTab.hide}
                id={tabPanelId}
                key={tabPanelId}
                role="tabpanel"
              >
                {tabValue === index && (
                  <Box>{children({ tabPanel }, index)}</Box>
                )}
              </Box>
            )
          })}
        </>
      )}
    </Grid>
  )
}

export default PuiTabsPanel
