import * as R from 'ramda'

import { ChargeSheetItemSection, InvoiceLineItem } from '~/types'

export const getClientChargesFromSubItemsMap = (
  items: Record<string, ChargeSheetItemSection>,
  ids: string[],
) =>
  R.pipe<
    (typeof items)[],
    ChargeSheetItemSection[],
    InvoiceLineItem[][],
    InvoiceLineItem[],
    InvoiceLineItem[],
    string[]
  >(
    R.values,
    R.pluck('groupedItems'),
    R.flatten,
    R.filter(({ id }: InvoiceLineItem) => R.includes(id, ids)),
    R.pluck('name'),
  )(items)
