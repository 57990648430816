import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AlertIconType, PuiAlertProps, Text } from '@pbt/pbt-ui-components'

import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import {
  addPreventShowAgainDialog,
  getPreventShowAgainDialog,
} from '~/store/duck/userSettings'
import { getCurrentUserId } from '~/store/reducers/auth'
import useDialog from '~/utils/useDialog'

interface OpenConfirmAlertProps extends Omit<PuiAlertProps, 'open'> {
  applyCustomMessage?: boolean
  hideOkayButton?: boolean
  onConfirm: (proceed: boolean, key?: React.Key | null) => void
}

type UseConfirmAlertReturnType = [
  (props: OpenConfirmAlertProps) => void,
  () => void,
  boolean,
]

type UseConfirmAlert = {
  preventShowAgainCheckBox?: boolean
  type: ConfirmAlertType
}

const useConfirmAlert = ({
  preventShowAgainCheckBox: preventShowAgainCheckBoxProp,
  type,
}: UseConfirmAlert): UseConfirmAlertReturnType => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const currentUserId = useSelector(getCurrentUserId)
  const preventConfig = useSelector(getPreventShowAgainDialog(type))

  const [openAlert, closeAlert, isOpen] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const onConfirmAlertClose = ({
    proceed,
    preventShowingAgain = false,
    onConfirm,
    key,
  }: {
    key?: React.Key | null
    onConfirm: OpenConfirmAlertProps['onConfirm']
    preventShowingAgain?: boolean
    proceed: boolean
  }) => {
    if (preventShowingAgain && type && currentUserId) {
      dispatch(addPreventShowAgainDialog(currentUserId, type, key))
    }

    closeAlert()
    onConfirm(proceed, key)
  }

  const openConfirmAlert = ({
    applyCustomMessage,
    message,
    onConfirm,
    hideOkayButton = false,
    okButtonText,
    ...rest
  }: OpenConfirmAlertProps) => {
    if (preventConfig?.prevent) {
      onConfirm(true, preventConfig?.selectedOption)
    } else {
      openAlert({
        preventShowAgainCheckBox: preventShowAgainCheckBoxProp || true,
        content: !applyCustomMessage && (
          <Text strong variant="subheading2">
            {message}
          </Text>
        ),
        iconType: AlertIconType.WARN,
        message: applyCustomMessage
          ? message
          : t('Dialogs:DISMISSIBLE_ALERT.ARE_YOU_SURE'),
        okButtonText: okButtonText || t('Common:REMOVE_ACTION'),
        onCancel: () => onConfirmAlertClose({ onConfirm, proceed: false }),
        onClose: () => onConfirmAlertClose({ onConfirm, proceed: false }),
        onOk: hideOkayButton
          ? undefined
          : (preventShowingAgain: boolean) =>
              onConfirmAlertClose({
                onConfirm,
                proceed: true,
                preventShowingAgain,
              }),
        onAdditionalButtonClick: (
          preventShowingAgain: boolean,
          key: React.Key | null,
        ) => {
          onConfirmAlertClose({
            onConfirm,
            proceed: true,
            preventShowingAgain,
            key,
          })
        },
        ...rest,
      })
    }
  }

  return [openConfirmAlert, closeAlert, isOpen]
}

export default useConfirmAlert
