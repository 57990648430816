import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Nil, Text } from '@pbt/pbt-ui-components'

import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import RxRequestUnlinkedLabel from '~/components/dashboard/tasks-dashboard/rx-request-task/RxRequestUnlinkedLabel'
import FeatureToggle from '~/constants/featureToggle'
import { useGetTaskIsUnlinkedRxRequest } from '~/store/hooks/tasks'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientName } from '~/store/reducers/patients'
import { getTask } from '~/store/reducers/tasks'
import { Order, Prescription } from '~/types'
import {
  getPrescriptionName,
  getPrescriptionTypeLabel,
} from '~/utils/prescription'

import ClientAndPatient from '../../soap/ClientAndPatient'

interface PrescriptionHeaderProps {
  clientId: string | Nil
  patientId: string
  prescription: Order | Prescription
  showTitlePrefix?: boolean
  taskId?: string | Nil
}

const PrescriptionHeader = ({
  clientId,
  patientId,
  prescription,
  showTitlePrefix,
  taskId,
}: PrescriptionHeaderProps) => {
  const patientName = useSelector(getPatientName(patientId))
  const task = useSelector(getTask(taskId))
  const isReactiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REACTIVE_RX),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const prescriptionTypeLabel = getPrescriptionTypeLabel({
    prescriptionType: prescription.prescriptionType,
    origin: prescription.origin,
  })
  const titlePrefix = showTitlePrefix ? `${prescriptionTypeLabel}–` : ''
  const name = getPrescriptionName(prescription, isFoodCatalogEnabled)
  const title = `${titlePrefix}${name}`

  const isUnlinkedRxRequest = useGetTaskIsUnlinkedRxRequest()(task)

  return (
    <>
      <Text mb={1} variant="h2">
        {title}
      </Text>
      <Grid container item alignItems="center" wrap="nowrap">
        {isUnlinkedRxRequest ? (
          <RxRequestUnlinkedLabel showWarningIcon taskId={taskId} />
        ) : isReactiveRxEnabled ? (
          <ClientAndPatient
            loadClient
            clientId={clientId}
            patientId={patientId}
            textVariant="body2"
          />
        ) : (
          <>
            {patientName && (
              <Grid item mr={0.5}>
                <Text variant="body2">{patientName}:</Text>
              </Grid>
            )}
            <Grid item>
              <PatientInfoLabel patientId={patientId} variant="body2" />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default PrescriptionHeader
