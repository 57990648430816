import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import { getBusinessSoapWidgets } from '~/store/reducers/businesses'
import { convertToKebabCase } from '~/utils'

export interface MarketplaceDeactivatedLinkProps {
  id: string
  marketplaceId: string
}

const MarketplaceDeactivatedLink = ({
  id,
  marketplaceId,
}: MarketplaceDeactivatedLinkProps) => {
  const BusinessSoapWidgets = useSelector(getBusinessSoapWidgets)!

  const marketplaceItemName = LanguageUtils.getConstantTranslatedName(
    id,
    BusinessSoapWidgets,
  )

  return (
    <Trans
      components={{
        linkComponent: (
          <Link
            id={`deactivated-${convertToKebabCase(marketplaceItemName)}-link`}
            to={`/admin/general/marketplace/${marketplaceId}`}
          />
        ),
      }}
      i18nKey="Admin:SOAP_CUSTOMIZATION.DEACTIVATED_MARKETPLACE"
      values={{ marketplaceItemName }}
    />
  )
}

export default MarketplaceDeactivatedLink
