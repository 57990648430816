import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Bundle } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchBundles = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
) =>
  request(
    getAPIPath('admin/services/bundles'),
    { method: 'GET', params: { businessId, from, to, query } },
    true,
    Schema.bundlesList,
  )

export const fetchBundle = (businessId: string, bundleId: string) =>
  request(
    getAPIPath(`admin/services/bundles/${bundleId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.bundle,
  )

export const createBundle = (businessId: string, bundle: Bundle) =>
  request(
    getAPIPath('admin/services/bundles'),
    { method: 'POST', data: bundle, params: { businessId } },
    true,
    Schema.bundle,
  )

export const editBundle = (businessId: string, bundle: Bundle) =>
  request(
    getAPIPath(`admin/services/bundles/${bundle.id}`),
    { method: 'PUT', data: bundle, params: { businessId } },
    true,
    Schema.bundle,
  )

export const deleteBundle = (businessId: string, bundleId: string) =>
  request(
    getAPIPath(`admin/services/bundles/${bundleId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )
