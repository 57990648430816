import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Defaults, InfiniteLoaderList, Text } from '@pbt/pbt-ui-components'

import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import {
  fetchInventoryBulkPricesPreview,
  fetchLabTestsBulkPricesPreview,
  fetchProceduresBulkPricesPreview,
  getActiveSessionByEntityTypeId,
  getBulkPricesPreviewList,
  getBulkPricesPreviewTotalCount,
} from '~/store/duck/bulkPrices'

import BulkPricesReviewListHeader from './BulkPricesReviewListHeader'
import BulkPricesReviewRow from './BulkPricesReviewRow'

const fetchBulkPricesPreviewMap = {
  [BulkPriceEntityTypes.INVENTORY]: fetchInventoryBulkPricesPreview,
  [BulkPriceEntityTypes.PROCEDURE]: fetchProceduresBulkPricesPreview,
  [BulkPriceEntityTypes.LAB_TEST]: fetchLabTestsBulkPricesPreview,
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      height: '100%',
      borderRadius: 2,
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      '& div[data-viewport-type="element"]': {
        height: 'fit-content !important',
      },
      '& div[data-viewport-type="element"] > div:first-child': {
        position: 'sticky',
        top: 0,
        zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      },
    },
  }),
  { name: 'BulkPricesReviewStep' },
)

interface BulkPricesReviewStepProps {
  entityType: BulkPriceEntityTypes
}

const BulkPricesReviewStep = ({ entityType }: BulkPricesReviewStepProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const totalCount = useSelector(getBulkPricesPreviewTotalCount)
  const list = useSelector(getBulkPricesPreviewList)
  const sessionId = useSelector(getActiveSessionByEntityTypeId(entityType))
  const { t } = useTranslation(['Common', 'Prices'])

  useEffect(() => {
    if (sessionId) {
      dispatch(
        fetchBulkPricesPreviewMap[entityType]({
          from: 0,
          to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          sessionId,
          entityType,
        }),
      )
    }
  }, [sessionId])

  const loadMoreItems = (from: number, to: number) => {
    dispatch(
      fetchBulkPricesPreviewMap[entityType]({
        from,
        to,
        sessionId,
        entityType,
      }),
    )
  }
  const isItemLoaded = (index: number) => Boolean(list[index])

  return (
    <Grid container direction="column" px={3} py={2} wrap="nowrap">
      <Text mb={2} variant="h2">
        {t('Prices:REVIEW_AND_CONFIRM_PRICE_CHANGES')}
      </Text>
      <Grid
        container
        item
        className={classes.table}
        direction="column"
        wrap="nowrap"
      >
        <InfiniteLoaderList
          // eslint-disable-next-line react/no-unstable-nested-components
          Header={() => <BulkPricesReviewListHeader entityType={entityType} />}
          isItemLoaded={isItemLoaded}
          itemCount={totalCount}
          itemData={list}
          itemSpacing={0}
          loadMoreItems={loadMoreItems}
        >
          {(itemId) => <BulkPricesReviewRow itemId={itemId} />}
        </InfiniteLoaderList>
      </Grid>
    </Grid>
  )
}

export default BulkPricesReviewStep
