import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Business, ClassesType, Nil, Text } from '@pbt/pbt-ui-components'

import ClinicLogo from '~/components/common/logos/ClinicLogo'
import { BusinessDto } from '~/store/dto/Business'

const useStyles = makeStyles(
  (theme) => ({
    imageRoot: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        height: 85,
        width: 149,
        margin: '0 auto',
      },
    },
    fullWidth: {
      width: '100%',
      height: '100%',
    },
    uploadLink: {
      marginTop: 10,
      color: theme.colors.sideText,
      fontSize: 14,
      lineHeight: '17px',
    },
    pointer: {
      cursor: 'pointer',
    },
  }),
  { name: 'BusinessLogoPaper' },
)

interface BusinessLogoPaperProps {
  allowUpload?: boolean
  business: Business | BusinessDto | Nil
  classes?: ClassesType<typeof useStyles>
  triggerUpload?: () => void
}

const BusinessLogoPaper = ({
  business,
  allowUpload = false,
  triggerUpload,
  classes: classesProp,
}: BusinessLogoPaperProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Registration')

  return (
    <>
      <Paper
        className={classNames(classes.imageRoot, {
          [classes.fullWidth]: !allowUpload,
          [classes.pointer]: allowUpload && !business?.logo,
        })}
      >
        <ClinicLogo
          allowUpload={allowUpload}
          clinic={business}
          onUploadRequested={triggerUpload}
        />
      </Paper>
      {allowUpload && business && business.logo && (
        <Text
          strong
          align="center"
          className={classNames(classes.uploadLink, classes.pointer)}
          onClick={triggerUpload}
        >
          {t('Registration:BUSINESS_LOGO_PAPER.UPLOAD_NEW_LOGO')}
        </Text>
      )}
    </>
  )
}

export default BusinessLogoPaper
