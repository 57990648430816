import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

const NoRulesScreen = () => {
  const [openNewRuleDialog] = useDialog(DialogNames.AVAILABILITY_RULE)
  const { t } = useTranslation(['Admin', 'Common'])

  return (
    <NoItemsScreen
      action={() => openNewRuleDialog()}
      actionMessage={t('Common:ADD_HOURS')}
      title={t('Admin:SCHEDULING.NO_RULES_SCREEN.TITLE')}
    >
      <Text>{t('Admin:SCHEDULING.NO_RULES_SCREEN.DESCRIPTION')}</Text>
    </NoItemsScreen>
  )
}

export default NoRulesScreen
