import React from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PermissionArea } from '@pbt/pbt-ui-components'

import NoPermissionPage from '~/components/common/NoPermissionPage'
import { getAuthIsFetching, getCRUDByArea } from '~/store/reducers/auth'
import { getRolesMap } from '~/store/reducers/roles'

const useStyles = makeStyles(
  () => ({
    spinnerWrapper: {
      height: '100%',
    },
  }),
  { name: 'ProtectedRoute' },
)

export interface ProtectedRouteProps {
  component: React.JSXElementConstructor<any>
  permissions?: PermissionArea
}

const ProtectedRoute = ({
  component: Component,
  permissions: permissionsProp,
}: ProtectedRouteProps) => {
  const classes = useStyles()
  const roles = useSelector(getRolesMap)
  const authIsFetching = useSelector(getAuthIsFetching)
  const permissions = useSelector(getCRUDByArea(permissionsProp), R.equals)

  if (permissionsProp && (authIsFetching || R.isEmpty(roles))) {
    return (
      <Grid
        container
        alignItems="center"
        className={classes.spinnerWrapper}
        justifyContent="center"
      >
        <CircularProgress />
      </Grid>
    )
  }

  return permissionsProp && !permissions.read ? (
    <NoPermissionPage />
  ) : (
    <Component />
  )
}

export default ProtectedRoute
