import { Moment } from 'moment'
import * as R from 'ramda'
import { DateFormat, DateUtils, moment, Nil } from '@pbt/pbt-ui-components'

import {
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import i18n from '~/locales/i18n'
import { Task, WidgetColumn, WidgetColumnRow } from '~/types'
import { isToday, isTomorrow, isYesterday } from '~/utils/time'

export const DEFAULT_WIDGET_SIZE_PROPS = {
  minW: 2,
  maxW: 6,
  minH: 3,
  maxH: 21,
}

export const TODAYS_APPOINTMENT_WIDGET_SIZE_PROPS = {
  minW: 2,
  maxW: 6,
  minH: 6,
  maxH: 6,
}

export const TIME_TRACKING_WIDGET_SIZE_PROPS = {
  minW: 2,
  maxW: 6,
  minH: 6,
  maxH: 21,
}

export const CUSTOMER_SATISFACTION_WIDGET_PROPS = {
  minW: 2,
  maxW: 6,
  minH: 3,
  maxH: 21,
}

const SPECIAL_WIDGET_NAME_SIZE_PROPS_MAP = {
  [LandingWidgetName.TODAY_APPOINTMENTS]: TODAYS_APPOINTMENT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.TIME_TRACKING]: TIME_TRACKING_WIDGET_SIZE_PROPS,
  [LandingWidgetName.ADMITTED]: DEFAULT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.UPCOMING]: DEFAULT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.COMMUNICATIONS]: DEFAULT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.MY_TASKS]: DEFAULT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.LABS]: DEFAULT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.OPEN_SOAPS]: DEFAULT_WIDGET_SIZE_PROPS,
  [LandingWidgetName.CUSTOMER_SATISFACTION]: CUSTOMER_SATISFACTION_WIDGET_PROPS,
}

export const getWidgetSizeProps = (widgetName: LandingWidgetName) =>
  SPECIAL_WIDGET_NAME_SIZE_PROPS_MAP[widgetName] || DEFAULT_WIDGET_SIZE_PROPS

export const LOCKED_WIDGET_PROPS = {
  isResizable: false,
  isDraggable: false,
}

export const UNLOCKED_WIDGET_PROPS = {
  isResizable: true,
  isDraggable: true,
}

export const getNewWidget = (
  widgetKey: string,
  widgetName: LandingWidgetName,
) => ({
  i: widgetKey,
  x: 0,
  y: 0,
  w: 6,
  h: 6,
  ...getWidgetSizeProps(widgetName),
  ...UNLOCKED_WIDGET_PROPS,
})

export const WIDGET_LOCAL_PROPS = [
  'minW',
  'maxW',
  'minH',
  'maxH',
  'isResizable',
  'isDraggable',
]

export const formatDateRange = (
  startDate: string | Nil,
  endDate: string | Nil,
) => {
  const momentStartDate = moment(startDate)
  const momentEndDate = moment(endDate)

  const isSameDay = momentStartDate.isSame(momentEndDate, 'day')
  const format = (momentDate: Moment) =>
    `${DateUtils.formatTime(momentDate.toISOString())} ${momentDate.format(
      DateFormat.FULL_DATE_YEAR_ABBREV,
    )}`

  return isSameDay
    ? `${DateUtils.formatTime(
        momentStartDate.toISOString(),
      )}—${DateUtils.formatTime(momentEndDate.toISOString())}`
    : `${format(momentStartDate)}—${format(momentEndDate)}`
}

export const formatWidgetDate = (widgetDate: string, withSuffix = false) =>
  R.cond([
    [R.isNil, R.always(null)],
    [
      isYesterday,
      R.always(
        withSuffix
          ? `${i18n.t('Time:LABEL.YESTERDAY')} ${DateUtils.formatTime(
              widgetDate,
            )}`
          : i18n.t('Time:LABEL.YESTERDAY'),
      ),
    ],
    [
      isToday,
      R.always(
        withSuffix
          ? `${i18n.t('Time:LABEL.TODAY')} ${DateUtils.formatTime(widgetDate)}`
          : i18n.t('Time:LABEL.TODAY'),
      ),
    ],
    [
      isTomorrow,
      R.always(
        withSuffix
          ? `${i18n.t('Time:LABEL.TOMORROW')} ${DateUtils.formatTime(
              widgetDate,
            )}`
          : i18n.t('Time:LABEL.TOMORROW'),
      ),
    ],
    [
      R.T,
      (date: string) =>
        moment(date).format(DateFormat.DAY_OF_WEEK_ABBREV_COMMA_MONTH_DAY),
    ],
  ])(widgetDate)

export const getVisibleWidgetColumns = <T extends WidgetColumnRow>(
  columns: WidgetColumn<T>[],
  widthType: WidgetWidthType,
): WidgetColumn<T>[] =>
  R.pipe(
    R.filter<WidgetColumn<T>>((column) =>
      Boolean(
        column.widthTypes?.includes(widthType) || column.widthMap?.[widthType],
      ),
    ),
    R.map(({ widthMap, ...column }) =>
      widthMap
        ? { ...column, widthTypes: [widthType], width: widthMap[widthType] }
        : column,
    ),
  )(columns)

const isLeftDateClosestToNow = (leftDate: Task, rightDate: Task) => {
  const now = moment()

  const leftDiff = Math.abs(moment(leftDate.dueDatetime).diff(now, 'minutes'))
  const rightDiff = Math.abs(moment(rightDate.dueDatetime).diff(now, 'minutes'))

  return leftDiff < rightDiff
}

export const getVisibleTasks = (tasks: Task[] = [], rowsCount: number) => {
  if (tasks.length < rowsCount) {
    return tasks
  }

  const centerTaskIndex = tasks.reduce(
    (accIndex, current, curIndex) =>
      isLeftDateClosestToNow(current, tasks[accIndex]) ? curIndex : accIndex,
    0,
  )

  const visibleTasks = [tasks[centerTaskIndex]]
  let leftIndex = centerTaskIndex - 1
  let rightIndex = centerTaskIndex + 1

  const isValidIndex = (index: number) => index >= 0 && index < tasks.length

  while (visibleTasks.length < rowsCount) {
    const addLeft =
      (isValidIndex(leftIndex) &&
        isValidIndex(rightIndex) &&
        isLeftDateClosestToNow(tasks[leftIndex], tasks[rightIndex])) ||
      !isValidIndex(rightIndex)

    if (addLeft) {
      visibleTasks.unshift(tasks[leftIndex])
      leftIndex--
    } else {
      visibleTasks.push(tasks[rightIndex])
      rightIndex++
    }
  }

  return visibleTasks
}
