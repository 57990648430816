import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  ClassesType,
  Field,
  PuiTextField,
} from '@pbt/pbt-ui-components'
import { Delete } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    container: {},
    addButton: {
      marginLeft: theme.spacing(1.5),
    },
    deleteButton: {
      padding: 0,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'CountiesEditableList' },
)

export interface CountiesEditableListProps {
  classes?: ClassesType<typeof useStyles>
  disabled: boolean
  field: Field
  fieldLabel?: string
}

const CountiesEditableList = ({
  classes: classesProp,
  field,
  disabled,
  fieldLabel,
}: CountiesEditableListProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const handleCountyAdd = () => {
    field.setValue([...field.value, ''])
  }

  const handleCountyDelete = (index: number) => () => {
    const newCounties = [...field.value]

    if (index === 0 && field.value.length === 1) {
      newCounties.splice(index, 1, '')
    } else {
      newCounties.splice(index, 1)
    }

    field.setValue(newCounties)
  }

  const handleCountyChange =
    (index: number) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      field.setValue(R.update(index, target.value, field.value))
    }

  return (
    <Grid container className={classes.container}>
      {field.value.map((county: string, index: number) => {
        const hasAddButton = index === field.value.length - 1 && !disabled
        const hasDeleteButton =
          index > 0 || (index === 0 && Boolean(county.length))

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid container item alignItems="center" key={index}>
            <Grid item md={5} xs={12}>
              <PuiTextField
                disabled={disabled}
                inputProps={{ maxLength: 100 }}
                label={fieldLabel}
                value={county}
                onChange={handleCountyChange(index)}
              />
            </Grid>
            {hasDeleteButton && (
              <IconButton
                className={classes.deleteButton}
                size="large"
                onClick={handleCountyDelete(index)}
              >
                <Delete />
              </IconButton>
            )}
            {hasAddButton && (
              <AddButton
                inline
                addText={t('Common:ADD_OTHER_COUNTY')}
                classes={{ addItem: classes.addButton }}
                disabled={disabled}
                onAdd={handleCountyAdd}
              />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default CountiesEditableList
