import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { Bundle } from '~/types'

import {
  CREATE_BUNDLE,
  CREATE_BUNDLE_FAILURE,
  CREATE_BUNDLE_SUCCESS,
  DELETE_BUNDLE,
  DELETE_BUNDLE_FAILURE,
  DELETE_BUNDLE_SUCCESS,
  EDIT_BUNDLE,
  EDIT_BUNDLE_FAILURE,
  EDIT_BUNDLE_SUCCESS,
  FETCH_BUNDLE,
  FETCH_BUNDLE_FAILURE,
  FETCH_BUNDLE_SUCCESS,
  FETCH_BUNDLES_LIST,
  FETCH_BUNDLES_LIST_FAILURE,
  FETCH_BUNDLES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_SUCCESS,
  UPDATE_BUNDLES,
} from './types/bundles'

export const fetchBundlesList = (
  from?: number,
  to?: number,
  query?: string | Nil,
) => ({
  type: FETCH_BUNDLES_LIST,
  from,
  to,
  query,
})
export const fetchBundlesListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_BUNDLES_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchBundlesListFailure = (error: ApiError) => ({
  type: FETCH_BUNDLES_LIST_FAILURE,
  error,
})

export const fetchBundle = (id: string) => ({ type: FETCH_BUNDLE, id })
export const fetchBundleFailure = (error: ApiError) => ({
  type: FETCH_BUNDLE_FAILURE,
  error,
})
export const fetchBundleSuccess = () => ({ type: FETCH_BUNDLE_SUCCESS })

export const fetchMoreItemsForBundlesList = (
  from: number,
  to: number,
  query?: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_BUNDLES_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForBundlesListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForBundlesListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_BUNDLES_LIST_FAILURE,
  error,
})

export const createBundle = (bundle: Bundle) => ({
  type: CREATE_BUNDLE,
  bundle,
})
export const createBundleSuccess = (bundleId: string) => ({
  type: CREATE_BUNDLE_SUCCESS,
  bundleId,
})
export const createBundleFailure = (error: ApiError) => ({
  type: CREATE_BUNDLE_FAILURE,
  error,
})

export const editBundle = (bundle: Bundle) => ({ type: EDIT_BUNDLE, bundle })
export const editBundleSuccess = () => ({ type: EDIT_BUNDLE_SUCCESS })
export const editBundleFailure = (error: ApiError) => ({
  type: EDIT_BUNDLE_FAILURE,
  error,
})

export const deleteBundle = (bundleId: string) => ({
  type: DELETE_BUNDLE,
  bundleId,
})
export const deleteBundleSuccess = (bundleId: string) => ({
  type: DELETE_BUNDLE_SUCCESS,
  bundleId,
})
export const deleteBundleFailure = (error: ApiError) => ({
  type: DELETE_BUNDLE_FAILURE,
  error,
})

export const updateBundles = (bundles: Record<string, Bundle>) => ({
  type: UPDATE_BUNDLES,
  bundles,
})
