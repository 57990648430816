import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { SoapFile } from '~/types'

import BusinessShareIcon from '../icons/BusinessShareIcon'

const useStyles = makeStyles(
  () => ({
    text: {
      wordBreak: 'break-word',
    },
  }),
  { name: 'AttachmentBody' },
)

export interface AttachmentBodyProps {
  attachment: SoapFile
  enableBusinessShareIcon?: boolean
}

const AttachmentBody = ({
  attachment: { name, extension, description, businessId },
  enableBusinessShareIcon,
}: AttachmentBodyProps) => {
  const classes = useStyles()

  return (
    <Grid container item direction="column">
      <Grid container item alignItems="center" wrap="nowrap">
        <Text strong className={classes.text}>
          {`${name}.${extension}`}
        </Text>
        {enableBusinessShareIcon && (
          <BusinessShareIcon businessIds={businessId ? [businessId] : []} />
        )}
      </Grid>
      <Grid item>
        <Text className={classes.text} variant="body2">
          {description}
        </Text>
      </Grid>
    </Grid>
  )
}

export default AttachmentBody
