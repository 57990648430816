import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AlertIconType, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  getIsResettingSoapTemplate,
  resetSoapTemplateToDefault,
} from '~/store/reducers/soapV2'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

export interface ResetToDefaultButtonProps {
  itemId: string | undefined
}

const ResetToDefaultButton = ({ itemId }: ResetToDefaultButtonProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const [openResetToDefaultAlert, closeResetToDefaultAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const closeOnReset = useCloseAfterCreation(
    closeResetToDefaultAlert,
    getIsResettingSoapTemplate,
  )

  const handleReset = () => {
    if (!itemId) {
      return
    }

    openResetToDefaultAlert({
      iconType: AlertIconType.WARN,
      message: t('Admin:SOAP_CUSTOMIZATION.RESET_TO_DEFAULT_MESSAGE'),
      cancelButtonText: t('Common:GO_BACK'),
      okButtonText: t('Admin:SOAP_CUSTOMIZATION.RESET_TO_DEFAULT'),
      onCancel: () => {
        closeResetToDefaultAlert()
      },
      onOk: () => {
        closeOnReset()
        dispatch(resetSoapTemplateToDefault({ id: itemId }))
      },
    })
  }

  return (
    <Text
      link
      fontSize="1.4rem"
      id="soap-template-reset-button"
      onClick={handleReset}
    >
      {t('Admin:SOAP_CUSTOMIZATION.RESET_DEFAULT_TABS_AND_CONTENT')}
    </Text>
  )
}

export default ResetToDefaultButton
