import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DialogActions } from '@mui/material'
import {
  AlertIconType,
  ButtonWithLoader,
  Patient,
  PuiAlert,
  PuiAlertProps,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

export interface PetParentChangedDialogProps extends PuiAlertProps {
  client: User
  newClient: User
  patient: Patient
}

const PetParentChangedDialog = ({
  open,
  patient,
  client,
  newClient,
  onClose,
}: PetParentChangedDialogProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['Common', 'Soap'])

  const goToPatientPage = () => {
    if (onClose) {
      onClose()
    }
    navigate(`/patient/${patient.id}`)
  }

  return (
    <PuiAlert
      hideCloseButton
      content={
        <DialogActions>
          <ButtonWithLoader onClick={goToPatientPage}>
            {t('Common:GO_TO_PATIENTS_RECORD', { patientName: patient?.name })}
          </ButtonWithLoader>
          <ButtonWithLoader color="secondary" onClick={onClose}>
            {t('Common:CONTINUE_ACTION')}
          </ButtonWithLoader>
        </DialogActions>
      }
      iconType={AlertIconType.WARN}
      message={t('Soap:PATIENT_HAS_NEW_PET_PARENT_NEW_CLIENT', {
        patientName: patient.name,
        newClient: Utils.getPersonString(newClient),
        previousClient: Utils.getPersonString(client),
      })}
      open={open}
    />
  )
}

export default PetParentChangedDialog
