import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Star from '@mui/icons-material/Star'
import StarOutline from '@mui/icons-material/StarBorder'
import { Grid, Skeleton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  Defaults,
  InfiniteLoaderList,
  Text,
  TextInteractive,
} from '@pbt/pbt-ui-components'
import { Lock as LockIcon } from '@pbt/pbt-ui-components/src/icons'

import PracticeStatusLabel from '~/components/dashboard/admin/general/practices/PracticeStatusLabel'
import {
  addFavoriteBusiness,
  fetchLocationsList,
  fetchMoreItemsForLocationsList,
  fetchMoreItemsForSuggestionsList,
  fetchSuggestionsList,
  removeFavoriteBusiness,
} from '~/store/actions/businesses'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getBusinessFavoriteList,
  getBusinessIsLocationsLoading,
  getBusinessIsSuggestionsLoading,
  getLocationsList,
  getLocationsTotalCount,
  getMultipleBusinesses,
  getSuggestionsList,
  getSuggestionsTotalCount,
} from '~/store/reducers/businesses'
import { BusinessFiltersRecord } from '~/types'
import {
  getFieldsQueryMergeObject,
  getStringifyFieldsQueryPairs,
} from '~/utils'

import BusinessFilter from './BusinessFilter'
import BusinessTypeIconGroup from './BusinessTypeIconGroup'

const ICON_WIDTH = 20
const COLLAPSE_HEIGHT = 368

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: 'white',
    },
    stateLabel: {
      width: 'fit-content',
      height: 'fit-content',
    },
    activeListItem: {
      cursor: 'pointer',
    },
    listItem: {
      position: 'relative',
      padding: theme.spacing(1.5, 1.5, 1, 1.75),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        cursor: 'pointer',
      },
    },
    circular: {
      // css animation diff size
      marginRight: theme.spacing(1.65),
    },
    favoriteIcon: {
      marginRight: theme.spacing(1.5),
      width: ICON_WIDTH,
      color: theme.colors.filterBorderColor,
    },
    favoriteIconActive: {
      color: theme.colors.tabSelected,
    },
    disabledItemContainer: {
      color: theme.colors.tabLabelDisabled,
      opacity: 0.5,
    },
    lock: {
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'BusinessSelect' },
)

export interface BusinessSelectProps {
  enableFavoriteButtons?: boolean
  ignoreRolesInSearch?: boolean
  isGroup?: boolean
  isLocationType?: boolean
  lockedBusinesses?: Record<string, boolean>
  lockedMessage?: string
  onBusinessSelect: (businessId: string) => void
  showBusinessNumber?: boolean
  showCurrentBusiness?: boolean
}

const BusinessSelect = ({
  ignoreRolesInSearch = false,
  lockedBusinesses,
  lockedMessage = '',
  showCurrentBusiness,
  showBusinessNumber,
  enableFavoriteButtons,
  isLocationType = false,
  onBusinessSelect = R.F,
  isGroup = false,
}: BusinessSelectProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Tooltips'])

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const favoriteBusinesses = useSelector(getBusinessFavoriteList)
  const isLoading = useSelector(
    isLocationType
      ? getBusinessIsLocationsLoading
      : getBusinessIsSuggestionsLoading,
  )
  const businessesList = useSelector(
    isLocationType ? getLocationsList : getSuggestionsList,
  )
  const businesses = useSelector(getMultipleBusinesses(businessesList))
  const businessesTotalCount = useSelector(
    isLocationType ? getLocationsTotalCount : getSuggestionsTotalCount,
  )

  const [selectQuery, setSelectQuery] = useState('')
  const [selectFieldsQuery, setSelectFieldsQuery] = useState('')

  const searchParameters = {
    query: selectQuery,
    fieldsQuery: selectFieldsQuery,
    ignoreRolesInSearch,
  }

  const renderFavoriteButton = (id: string) =>
    isLoading ? (
      <Skeleton
        className={classes.circular}
        height={ICON_WIDTH}
        variant="circular"
        width={ICON_WIDTH}
      />
    ) : R.includes(id, favoriteBusinesses) ? (
      <Star
        className={classNames(classes.favoriteIcon, classes.favoriteIconActive)}
        onClick={() => dispatch(removeFavoriteBusiness(id))}
      />
    ) : (
      <StarOutline
        className={classes.favoriteIcon}
        onClick={() => dispatch(addFavoriteBusiness(id))}
      />
    )

  const handleBusinessSelect = (businessId: string) => {
    onBusinessSelect(businessId)
  }

  const isItemLoaded = (index: number) => Boolean(businesses[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (startIndex === 0) {
      return
    }
    const fetchMoreBusinessesAction = isLocationType
      ? fetchMoreItemsForLocationsList
      : fetchMoreItemsForSuggestionsList

    dispatch(
      fetchMoreBusinessesAction(
        startIndex,
        endIndex,
        searchParameters,
        isGroup,
      ),
    )
  }

  useEffect(() => {
    const fetchBusinessesAction = isLocationType
      ? fetchLocationsList
      : fetchSuggestionsList

    dispatch(
      fetchBusinessesAction(
        0,
        Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        searchParameters,
        isGroup,
      ),
    )
  }, [selectQuery, selectFieldsQuery, ignoreRolesInSearch, isGroup])

  const handleFilterChange = (newFilters: BusinessFiltersRecord) => {
    const newFieldsQuery = getStringifyFieldsQueryPairs(R.toPairs(newFilters))

    setSelectFieldsQuery(newFieldsQuery)
  }

  const handleSearchChange = (newQuery: string) => {
    setSelectQuery(newQuery)
  }

  return (
    <Grid item className={classes.root}>
      <BusinessFilter
        initialFilters={
          getFieldsQueryMergeObject(selectFieldsQuery) as BusinessFiltersRecord
        }
        initialSearch={selectQuery}
        onFilterChange={handleFilterChange}
        onSearchChange={handleSearchChange}
      />
      <InfiniteLoaderList
        isItemLoaded={isItemLoaded}
        itemCount={businessesTotalCount || businesses?.length}
        itemData={businesses}
        itemSpacing={0}
        loadMoreItems={loadMoreItems}
        style={{ height: COLLAPSE_HEIGHT }}
      >
        {({ name, internalName, id, statusId, practiceTypeIds } = {}) => (
          <Grid
            container
            alignItems="center"
            className={classes.listItem}
            justifyContent="space-between"
            key={id}
            wrap="nowrap"
          >
            {enableFavoriteButtons && renderFavoriteButton(id)}
            <Grid
              container
              item
              className={classNames({
                [classes.activeListItem]: !lockedBusinesses?.[id],
              })}
              wrap="nowrap"
              onClick={
                !lockedBusinesses?.[id]
                  ? () => handleBusinessSelect(id)
                  : undefined
              }
            >
              <Grid container item alignItems="center" wrap="nowrap" xs={6}>
                {lockedBusinesses?.[id] ? (
                  <Tooltip placement="top" title={lockedMessage}>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.disabledItemContainer}
                      wrap="nowrap"
                    >
                      <LockIcon className={classes.lock} />
                      <Dotdotdot clamp={1}>
                        <TextInteractive
                          strong
                          isLoading={isLoading}
                          variant="body2"
                        >
                          {internalName || name}
                        </TextInteractive>
                      </Dotdotdot>
                      {!isLoading && showBusinessNumber && (
                        <Text strong variant="body2">
                          {id && ` (${id})`}
                        </Text>
                      )}
                    </Grid>
                  </Tooltip>
                ) : (
                  <Grid container>
                    <Dotdotdot clamp={1}>
                      <TextInteractive
                        strong
                        isLoading={isLoading}
                        variant="body2"
                      >
                        {internalName || name}
                      </TextInteractive>
                    </Dotdotdot>
                    {!isLoading && showBusinessNumber && (
                      <Text strong variant="body2">
                        {id && ` (${id})`}
                      </Text>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                item
                alignItems="center"
                justifyContent="flex-end"
                xs={6}
              >
                <Grid item xs={4}>
                  {showCurrentBusiness && currentBusinessId === id && (
                    <Text mr={0.5} variant="body3">
                      {t('Common:CURRENT').toLowerCase()}
                    </Text>
                  )}
                </Grid>
                <Grid item xs={7}>
                  <BusinessTypeIconGroup
                    ids={practiceTypeIds}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={1}>
                  <PracticeStatusLabel
                    short
                    className={classes.stateLabel}
                    isLoading={isLoading}
                    statusId={statusId}
                    variant="big"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </InfiniteLoaderList>
    </Grid>
  )
}

export default BusinessSelect
