import * as R from 'ramda'
import { AnyAction } from 'redux'
import { ApiError } from '@pbt/pbt-ui-components'

import { Department } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const UPDATE_DEPARTMENTS = 'departments/UPDATE_DEPARTMENTS'

export const FETCH_DEPARTMENTS = 'departments/FETCH_DEPARTMENTS'
export const FETCH_DEPARTMENTS_SUCCESS = 'departments/FETCH_DEPARTMENTS_SUCCESS'
export const FETCH_DEPARTMENTS_FAILURE = 'departments/FETCH_DEPARTMENTS_FAILURE'

export const CREATE_DEPARTMENT = 'departments/CREATE_DEPARTMENT'
export const CREATE_DEPARTMENT_SUCCESS = 'departments/CREATE_DEPARTMENT_SUCCESS'
export const CREATE_DEPARTMENT_FAILURE = 'departments/CREATE_DEPARTMENT_FAILURE'

export const TOGGLE_DEPARTMENT_STATE = 'departments/TOGGLE_DEPARTMENT_STATE'
export const TOGGLE_DEPARTMENT_STATE_SUCCESS =
  'departments/TOGGLE_DEPARTMENT_STATE_SUCCESS'
export const TOGGLE_DEPARTMENT_STATE_FAILURE =
  'departments/TOGGLE_DEPARTMENT_STATE_FAILURE'

export const updateDepartments = (departments: Record<string, Department>) => ({
  type: UPDATE_DEPARTMENTS,
  departments,
})

export const fetchDepartments = (
  businessId: string,
  includeInactive: boolean,
) => ({
  type: FETCH_DEPARTMENTS,
  businessId,
  includeInactive,
})
export const fetchDepartmentsSuccess = (list: string[]) => ({
  type: FETCH_DEPARTMENTS_SUCCESS,
  list,
})
export const fetchDepartmentsFailure = (error: ApiError) => ({
  type: FETCH_DEPARTMENTS_FAILURE,
  error,
})

export const createDepartment = (
  businessId: string,
  newDepartment: Omit<Department, 'id'>,
) => ({
  type: CREATE_DEPARTMENT,
  businessId,
  newDepartment,
})
export const createDepartmentSuccess = (departmentId: string) => ({
  type: CREATE_DEPARTMENT_SUCCESS,
  departmentId,
})
export const createDepartmentFailure = (error: ApiError) => ({
  type: CREATE_DEPARTMENT_FAILURE,
  error,
})

export const toggleDepartmentState = (
  businessId: string,
  departmentId: string,
) => ({
  type: TOGGLE_DEPARTMENT_STATE,
  businessId,
  departmentId,
})
export const toggleDepartmentStateSuccess = (departmentId: string) => ({
  type: TOGGLE_DEPARTMENT_STATE_SUCCESS,
  departmentId,
})
export const toggleDepartmentStateFailure = (error: ApiError) => ({
  type: TOGGLE_DEPARTMENT_STATE_FAILURE,
  error,
})

export type DepartmentsState = {
  error: string | null
  isFetching: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, Department>
}

export const INITIAL_STATE: DepartmentsState = {
  list: [],
  map: {},
  isLoading: false,
  isFetching: false,
  error: null,
}

export const departmentsReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
      }
    case FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        isLoading: false,
        isFetching: false,
      }
    case FETCH_DEPARTMENTS:
      return { ...state, isLoading: true, isFetching: true }
    case UPDATE_DEPARTMENTS:
      return { ...state, map: secondLevelMerge(state.map, action.departments) }
    case CREATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case CREATE_DEPARTMENT_SUCCESS:
      return { ...state, isLoading: false }
    case CREATE_DEPARTMENT:
      return { ...state, isLoading: true }
    case TOGGLE_DEPARTMENT_STATE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case TOGGLE_DEPARTMENT_STATE_SUCCESS:
      return { ...state, isLoading: false }
    case TOGGLE_DEPARTMENT_STATE:
      return { ...state, isLoading: true }
    default:
      return state
  }
}

export const getDepartments = (state: RootState): DepartmentsState =>
  state.departments
export const getDepartmentsList = (state: RootState) =>
  getDepartments(state).list
export const getDepartmentsMap = (state: RootState) => getDepartments(state).map
export const getMultipleDepartments = (ids: string[]) =>
  R.pipe(getDepartmentsMap, R.props(ids))
export const getDepartment = R.curry(
  (id, state) => getDepartments(state).map[id],
)
export const getDepartmentsIsFetching = (state: RootState) =>
  getDepartments(state).isFetching
export const getDepartmentsIsLoading = (state: RootState) =>
  getDepartments(state).isLoading
