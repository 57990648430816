import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Defaults,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 460,
      maxWidth: 460,
    },
    warnIcon: {
      marginBottom: theme.spacing(1),
      color: theme.colors.alertWarning,
    },
    detailsContainer: {
      borderTop: theme.constants.tableBorder,
    },
    errorDetails: {
      fontSize: '1.2rem',
    },
    link: {
      textDecoration: 'underline',
      color: theme.colors.link,
      cursor: 'pointer',
    },
  }),
  { name: 'DymoErrorDialog' },
)

const ARTICLE_URL =
  '/en/articles/3819066-how-do-i-set-up-my-dymo-printer-to-print-labels'

interface DymoErrorDialogProps extends BasePuiDialogProps {
  error?: Error
}

const DymoErrorDialog = ({ error, open, onClose }: DymoErrorDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  return (
    <PuiDialog
      aria-labelledby="print-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid container item direction="column">
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          pb={4}
          pt={3}
          px={3}
        >
          <WarnAlert className={classes.warnIcon} />
          <Text align="center">
            {t('Dialogs:DYMO_ERROR_DIALOG.SOMETHING_WENT_WRONG_EXPRESSION')}
          </Text>
          <Text align="center">
            {t('Dialogs:DYMO_ERROR_DIALOG.LOOKS_LIKE_AN_ISSUE')}
          </Text>
          <Text align="center" mt={1}>
            {t('Dialogs:DYMO_ERROR_DIALOG.MORE_INFO_01')}&nbsp;
            <Link
              className={classes.link}
              href={Defaults.SUPPORT_URL + ARTICLE_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('Dialogs:DYMO_ERROR_DIALOG.MORE_INFO_02_LINK')}
            </Link>
            {t('Dialogs:DYMO_ERROR_DIALOG.MORE_INFO_03')}&nbsp;
            <Link
              className={classes.link}
              href={`mailto:${Defaults.SUPPORT_EMAIL}`}
            >
              {Defaults.SUPPORT_EMAIL}
            </Link>
            .
          </Text>
        </Grid>
        {error && (
          <Grid
            container
            item
            className={classes.detailsContainer}
            direction="column"
            px={3}
            py={2}
          >
            <Text strong variant="subheading3">
              {t('Dialogs:DYMO_ERROR_DIALOG.ERROR_DETAILS')}
            </Text>
            <Text className={classes.errorDetails} mt={1}>
              {error.toString()}
            </Text>
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default DymoErrorDialog
