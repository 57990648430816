import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AddButton, ButtonWithLoader, Nil, Text } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import MachinesSelect from '~/components/common/inputs/MachinesSelect'
import LabVendorNames from '~/constants/labVendorName'
import {
  getDevicesByVendorId,
  getLabOrderIsPlacing,
} from '~/store/duck/labOrders'
import { getFinanceIsSaving } from '~/store/reducers/finance'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 54,
    },
    button: {
      minWidth: 150,
      height: 40,
      marginLeft: theme.spacing(2),
    },
    addButton: {
      marginRight: theme.spacing(1),
      marginLeft: 'auto',
    },
    warnContainer: {
      marginLeft: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.colors.alertWarning,
      width: 16,
      height: 16,
      marginRight: theme.spacing(0.5),
    },
    deviceSelect: {
      width: 184,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'LabOrderToolRow' },
)

export interface LabOrderToolRowProps {
  canPlaceOrder?: boolean
  hasIntegration?: boolean
  labName: string
  onAddLab?: (soapId: string | Nil) => void
  onPlaceLabOrder?: (deviceSerialNumber: string | undefined) => void
  showDevices?: boolean
  soapId?: string | Nil
  vendorId: string
}

const LabOrderToolRow = ({
  labName,
  vendorId,
  canPlaceOrder = false,
  hasIntegration = false,
  showDevices = false,
  soapId,
  onAddLab,
  onPlaceLabOrder,
}: LabOrderToolRowProps) => {
  const classes = useStyles()
  const isPlacingOrder = useSelector(getLabOrderIsPlacing)
  const isInvoiceSaving = useSelector(getFinanceIsSaving)
  const devices = useSelector(getDevicesByVendorId(vendorId))
  const { t } = useTranslation('Common')

  const devicesWithUniqueNames = devices.map(
    ({ displayName, deviceSerialNumber }) => ({
      deviceSerialNumber,
      displayName: `${displayName || ''} ${deviceSerialNumber || ''}`.trim(),
    }),
  )

  const [deviceSerialNumber, setDeviceSerialNumber] = useState<string>()

  useEffect(() => {
    if (!deviceSerialNumber && devicesWithUniqueNames.length === 1) {
      setDeviceSerialNumber(devicesWithUniqueNames[0].deviceSerialNumber)
    }
  }, [devicesWithUniqueNames.length])

  const isInternalVendor = labName === LabVendorNames.INTERNAL
  const onAddInternal = !R.isNil(onAddLab) ? () => onAddLab(soapId) : undefined

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="space-between"
    >
      {showDevices && (
        <MachinesSelect
          classes={{ root: classes.deviceSelect }}
          items={devicesWithUniqueNames}
          value={deviceSerialNumber}
          onChange={setDeviceSerialNumber}
        />
      )}
      {hasIntegration && canPlaceOrder && (
        <ButtonWithLoader
          className={classes.button}
          disabled={
            isPlacingOrder ||
            isInvoiceSaving ||
            (showDevices && !deviceSerialNumber)
          }
          loading={isPlacingOrder}
          onClick={() => {
            if (onPlaceLabOrder) {
              onPlaceLabOrder(deviceSerialNumber)
            }
          }}
        >
          {t('Common:PLACE_ORDER')}
        </ButtonWithLoader>
      )}
      {!hasIntegration && !isInternalVendor && (
        <Grid className={classes.warnContainer}>
          <WarningIcon className={classes.icon} />
          <Text>
            {t(
              'Dialogs:ORDER_MANAGEMENT_DIALOG.COMPLETE_ORDER_WARNING_MESSAGE',
              {
                labName,
              },
            )}
          </Text>
        </Grid>
      )}
      {onAddLab && (
        <Grid item className={classes.addButton}>
          <AddButton addText={t('Common:ADD_LAB')} onAdd={onAddInternal} />
        </Grid>
      )}
    </Grid>
  )
}

export default LabOrderToolRow
