import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'

import NoItemsScreen from '../../NoItemsScreen'

const NoLabTestsScreen = () => {
  const { t } = useTranslation(['LabTests'])

  return (
    <NoItemsScreen wideButton title={t('LabTests:NO_LAB_TESTS_SCREEN.TITLE')}>
      <Text variant="body2">
        <Trans
          components={{
            linkToLabTests: <Link to="/admin/catalog/lab-tests" />,
            linkToPractices: <Link to="/admin/general/practices" />,
          }}
          i18nKey="LabTests:NO_LAB_TESTS_SCREEN.TEXT"
        />
      </Text>
    </NoItemsScreen>
  )
}

export default NoLabTestsScreen
