import { NavigateFunction } from 'react-router-dom'

export const registrationNavigationDependingOnIsActivationFlow = (
  navigate: NavigateFunction,
  isActivationFlow: boolean,
) => {
  if (isActivationFlow) {
    navigate('/register/manager')
  } else {
    navigate('/register')
  }
}
