import React from 'react'
import { Nil } from '@pbt/pbt-ui-components'

import { LabTestDashboardOrder, LabTestDashboardTest } from '~/types'

import LabOrderStatusLabel, {
  LabOrderStatusLabelProps,
} from './LabOrderStatusLabel'
import LabTestsStatusLabel from './LabTestsStatusLabel'

export interface LabStatusLabelProps
  extends Omit<LabOrderStatusLabelProps, 'order' | 'stateId'> {
  order: LabTestDashboardOrder | Nil
  tests: LabTestDashboardTest[]
}

const LabStatusLabel = ({ order, tests, ...rest }: LabStatusLabelProps) =>
  order ? (
    <LabOrderStatusLabel stateId={order.statusId} {...rest} />
  ) : (
    <LabTestsStatusLabel tests={tests} {...rest} />
  )

export default LabStatusLabel
