import React from 'react'
import { Constant } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'

interface WidgetsFilterProps extends CheckboxListFilterProps {
  widgets: Constant[]
}

const WidgetsFilter = ({ widgets, ...rest }: WidgetsFilterProps) => (
  <CheckboxListFilter
    arrayFormat
    includeSearch={false}
    items={widgets}
    {...rest}
  />
)

export default WidgetsFilter
