import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  DateFormat,
  moment,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import useGetPreferredContactMethod from '~/components/dashboard/clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { getPatientName } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { TimetableEvent } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    primaryText: {
      color: '#00284B',
    },
    button: {
      minWidth: 94,
      maxWidth: 175,
    },
    contactMethodValue: {
      wordBreak: 'break-word',
    },
  }),
  { name: 'BoardingCheckInCard' },
)

export interface BoardingCheckInCardProps {
  appointment: TimetableEvent
  buttonLabel: string
  checkingIn?: boolean
  onClick: (appointment: TimetableEvent) => void
}

const BoardingCheckInCard = ({
  buttonLabel,
  checkingIn,
  appointment,
  onClick,
}: BoardingCheckInCardProps) => {
  const {
    scheduledStartDatetime,
    client: clientId,
    patient: patientId,
  } = appointment || {}
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))
  const patientName = useSelector(getPatientName(patientId))

  const time = moment(scheduledStartDatetime)
  const preferredContactMethod = useGetPreferredContactMethod({ client })

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.container}
      p={1}
      wrap="nowrap"
    >
      <Grid container item direction="column" pr={1}>
        <Text variant="body2">
          {`${
            checkingIn ? t('Common:ARRIVING') : t('Common:READY_TO_GO')
          } ${time.format(DateFormat.FULL_TIME_WITH_MERIDIAN)}`}
        </Text>
        <Text strong className={classes.primaryText} variant="subheading2">
          <Link className={classes.primaryText} to={`/patient/${patientId}`}>
            {patientName}
          </Link>
          {' | '}
          <Link className={classes.primaryText} to={`/client/${clientId}`}>
            {Utils.getPersonString(client)}
          </Link>
        </Text>
        <Text className={classes.contactMethodValue} variant="body2">
          {preferredContactMethod.value}
        </Text>
      </Grid>
      <Grid item>
        <ButtonWithLoader
          className={classes.button}
          color="secondary"
          onClick={() => onClick(appointment)}
        >
          {buttonLabel}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default BoardingCheckInCard
