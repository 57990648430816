import React from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'

import FeatureToggle from '~/constants/featureToggle'
import { getChargesLoading } from '~/store/duck/charges'
import { getFeatureToggle } from '~/store/reducers/constants'

const PrescriptionSnapShotLoadingOrIcon = (Icon: any) => {
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )
  const isLoading = useSelector(getChargesLoading)
  return isChargeSheetEnabled && isLoading ? (
    <Grid mr={1}>
      <CircularProgress size={20} />
    </Grid>
  ) : (
    <Icon.Icon className={Icon.className} />
  )
}

export default PrescriptionSnapShotLoadingOrIcon
