export enum ConnectionState {
  DISCONNECTED = 'DISCONNECTED',
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  WAITING_RECONNECT_DELAY = 'WAITING_RECONNECT_DELAY',
}

export enum TopicContext {
  GENERAL = 'GENERAL',
  BUSINESS = 'BUSINESS',
  BUSINESS_PERSON = 'BUSINESS_PERSON',
  BUSINESS_SOAP = 'BUSINESS_SOAP',
  BUSINESS_PATIENT = 'BUSINESS_PATIENT',
  CLIENT = 'CLIENT',
  INVOICE = 'INVOICE',
  REFUND_INVOICE = 'REFUND_INVOICE',
  CLIENT_SOAP = 'CLIENT_SOAP',
}

export enum WsAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum WsReportAggregationAction {
  REPORT_CARD_PDF_CREATE = 'REPORT_CARD_PDF_CREATE',
  REPORT_CARD_EMAIL_CREATE = 'REPORT_CARD_EMAIL_CREATE',
  MEDICAL_HISTORY_PDF_CREATE = 'MEDICAL_HISTORY_PDF_CREATE',
  MEDICAL_HISTORY_EMAIL_CREATE = 'MEDICAL_HISTORY_EMAIL_CREATE',
}

export const RECONNECT_DELAY = 15000
export const WS_MESSAGE_BUFFER_SIZE = 10
export const WS_AUTH_CONNECT_RETRIES = 3
