import * as R from 'ramda'

import { PaymentTypesLabel } from '~/constants/paymentTypes'
import { BalanceListEntryItem, ExtendPayment } from '~/types'

const PaymentDescriptionCell = (item: BalanceListEntryItem) => {
  if (R.has('description', item)) {
    return item.description
  }

  const payment = item as ExtendPayment

  if (!payment.paymentMethod) {
    return null
  }

  return PaymentTypesLabel[payment.paymentMethod] || payment.paymentMethod
}

export default PaymentDescriptionCell
