import React, { useCallback, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AppliedFilter,
  ClassesType,
  InfiniteLoaderList,
  LanguageUtils,
  PermissionArea,
  Role,
  Text,
} from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getMultipleBusinessMap } from '~/store/reducers/businesses'
import { BusinessRoleItem } from '~/types'
import { arrayToMap } from '~/utils'
import { getBusinessName } from '~/utils/businessUtils'
import { isPracticeAdminRole } from '~/utils/roleUtils'

import RolesFilter from './RolesFilter'

const MAX_ROLE_LIST_HEIGHT = 280
const ROLE_ITEM_HEIGHT = 38
const BUSINESS_COLUMN_WIDTH = 265
const EDIT_ICON_WIDTH = 30

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
    },
    contentTable: {
      borderTop: 'none',
    },
    headingCell: {
      padding: theme.spacing(0.5, 1),
      borderRight: theme.constants.tableBorder,
      '&:last-of-type': {
        borderRightWidth: 0,
      },
    },
    businessColumnWidth: {
      minWidth: BUSINESS_COLUMN_WIDTH,
      width: BUSINESS_COLUMN_WIDTH,
    },
    businessNameCell: {
      padding: 0,
      border: theme.constants.tableBorder,
      verticalAlign: 'top',
      borderWidth: '0 1px 1px 0',
    },
    businessNameCellLast: {
      borderWidth: '0 1px 0 0',
    },
    cell: {
      padding: theme.spacing(0, 1),
      borderBottomWidth: 0,
    },
    row: {
      height: ROLE_ITEM_HEIGHT - 1, // border difference
      borderBottom: theme.constants.tableBorder,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    editIconButton: {
      margin: theme.spacing(0.25),
    },
    editIcon: {
      color: theme.colors.link,
    },
    roleContainer: {
      padding: theme.spacing(1),
    },
    filterContainer: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'MemberRolesSection' },
)

const notEmpty = R.compose(R.not, R.isEmpty)

interface MemberRolesSectionProps {
  businessRoleList: BusinessRoleItem[]
  classes: ClassesType<typeof useStyles>
  disabled: boolean
  isGroup?: boolean
  onDeleteBusinessRole: ({ businessId, roleId }: BusinessRoleItem) => void
  onEditBusinessRoles: (businessId: string) => void
  roleList: Role[]
  title?: string
}

const MemberRolesSection = ({
  title,
  roleList,
  businessRoleList,
  isGroup,
  classes: classesProp,
  disabled,
  onEditBusinessRoles,
  onDeleteBusinessRole,
}: MemberRolesSectionProps) => {
  const classes = useStyles({ classes: classesProp })

  const [selectedRoles, setSelectedRoles] = useState<string[]>([])

  const businessRolesMap = R.groupBy(R.prop('businessId'), businessRoleList)

  const businessesMap = useSelector(
    getMultipleBusinessMap(R.keys(businessRolesMap)),
  )
  const supportPermissions = useSelector(getCRUDByArea(PermissionArea.SUPPORT))

  const { t } = useTranslation('Common')

  const rolesMap = arrayToMap(roleList, R.prop('id'), R.identity)
  const availableRolesIds = R.pluck('roleId', businessRoleList)

  const isRoleVisible = (roleId: string) =>
    rolesMap[roleId] &&
    (!selectedRoles?.length || selectedRoles.includes(roleId))

  const visibleBusinessRolesMap = R.pipe<
    any,
    any,
    Record<string, BusinessRoleItem[]>
  >(
    R.map(R.filter(({ roleId }) => isRoleVisible(roleId))),
    R.filter(notEmpty),
  )(businessRolesMap)

  const rolesFilter = {
    humanReadable: selectedRoles
      .map((id) =>
        isGroup && isPracticeAdminRole(rolesMap[id])
          ? t('Common:GROUP_ADMINISTATOR')
          : LanguageUtils.getTranslatedFieldName(rolesMap[id]),
      )
      .join(', '),
  }

  const businessComparator = (id1: string, id2: string) =>
    businessesMap[id1]?.name?.localeCompare(businessesMap[id2]?.name)
  const rolesComparator = (item1: BusinessRoleItem, item2: BusinessRoleItem) =>
    rolesMap[item1.roleId]?.name?.localeCompare(rolesMap[item2.roleId]?.name)

  const businessToRender = R.pipe(
    R.keys,
    R.sort(businessComparator),
  )(visibleBusinessRolesMap)

  const getRolesRenderByBusinessId = useCallback(
    (businessId: string) =>
      R.pipe(
        R.sort(rolesComparator),
        R.map(({ roleId }) => (
          <TableRow className={classes.row} key={roleId}>
            <TableCell className={classes.cell}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Dotdotdot clamp={1}>
                  <Text variant="body2">
                    {isGroup && isPracticeAdminRole(rolesMap[roleId])
                      ? t('Common:GROUP_ADMINISTATOR')
                      : LanguageUtils.getTranslatedFieldName(rolesMap[roleId])}
                  </Text>
                </Dotdotdot>
                {!disabled && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => onDeleteBusinessRole({ businessId, roleId })}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        )),
      )(visibleBusinessRolesMap[businessId]),
    [roleList, selectedRoles, businessRoleList],
  )

  const getBusinessNameById = (businessId: string) =>
    getBusinessName(businessesMap[businessId])

  const visibleRolesTotalCount = R.pipe<any, any, any, number>(
    R.values,
    R.pluck('length'),
    R.sum,
  )(visibleBusinessRolesMap)

  const roleListHeightByItemLength =
    visibleRolesTotalCount && visibleRolesTotalCount * ROLE_ITEM_HEIGHT
  const roleListHeight = Math.min(
    MAX_ROLE_LIST_HEIGHT,
    roleListHeightByItemLength,
  )

  return (
    <Grid container className={classes.root} flexDirection="column">
      {title && (
        <Text strong mb={1} variant="subheading2">
          {title}
        </Text>
      )}
      {selectedRoles.length > 0 && (
        <Grid container className={classes.filterContainer}>
          <AppliedFilter
            filter={rolesFilter}
            onClick={() => setSelectedRoles([])}
          />
        </Grid>
      )}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classNames(
                classes.headingCell,
                classes.businessColumnWidth,
              )}
            >
              <Text strong variant="lowAccent2">
                {t('Common:PRACTICE')}
              </Text>
            </TableCell>
            <TableCell className={classes.headingCell}>
              <RolesFilter
                groupByRhapsodyAnalytics
                isGroup={isGroup}
                roleIds={availableRolesIds}
                selectedRoles={selectedRoles}
                onSelectedRolesChange={setSelectedRoles}
              />
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Grid
        className={classNames(classes.table, classes.contentTable)}
        flexGrow={1}
      >
        {/* TODO: roles pagination list */}
        <InfiniteLoaderList
          isItemLoaded={R.F}
          itemCount={businessToRender?.length}
          itemData={businessToRender}
          itemSpacing={0}
          loadMoreItems={R.F}
          style={{ height: '100%', minHeight: roleListHeight }}
        >
          {(businessId = '', index) => (
            <Grid container key={businessId} wrap="nowrap">
              <Grid
                container
                item
                className={classNames(
                  classes.businessNameCell,
                  classes.businessColumnWidth,
                  {
                    [classes.businessNameCellLast]:
                      index + 1 === businessToRender?.length,
                  },
                )}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="nowrap"
                >
                  <Grid
                    container
                    item
                    maxWidth={
                      disabled
                        ? BUSINESS_COLUMN_WIDTH
                        : BUSINESS_COLUMN_WIDTH - EDIT_ICON_WIDTH
                    }
                    px={1}
                    py={0.5}
                    wrap="nowrap"
                  >
                    <Text noWrap strong variant="body">
                      {getBusinessNameById(businessId)}
                    </Text>
                    {supportPermissions.read && (
                      <Text strong variant="body">
                        {` (${businessId})`}
                      </Text>
                    )}
                  </Grid>
                  <Grid item alignSelf="flex-start">
                    {!disabled && (
                      <IconButton
                        className={classNames(
                          classes.iconButton,
                          classes.editIconButton,
                        )}
                        onClick={() => onEditBusinessRoles(businessId)}
                      >
                        <EditIcon className={classes.editIcon} />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={classNames(classes.businessNameCell, {
                  [classes.businessNameCellLast]:
                    index + 1 === businessToRender?.length,
                })}
              >
                <Table>
                  <TableBody>
                    {getRolesRenderByBusinessId(businessId)}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )}
        </InfiniteLoaderList>
      </Grid>
    </Grid>
  )
}

export default MemberRolesSection
