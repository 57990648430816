import React from 'react'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Field, Utils } from '@pbt/pbt-ui-components'
import {
  OrderedWeekConstants,
  WeekDays,
} from '@pbt/pbt-ui-components/src/localization'

const useStyles = makeStyles(
  (theme) => ({
    toggleButton: {
      textTransform: 'none',
      boxShadow: 'none',
      height: 33,
      fontSize: '1.4rem',
      fontWeight: 400,
      padding: theme.spacing(0, 1),
      border: '1px solid transparent',
    },
    inactiveToggleButton: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.inactiveBorder,
      color: theme.colors.sideText,
    },
  }),
  { name: 'WeekdaysButtonGroup' },
)

export interface WeekdaysButtonGroupProps {
  className?: string
  disabled?: boolean
  field: Field
}

const WeekdaysButtonGroup = ({
  className,
  field,
  disabled,
}: WeekdaysButtonGroupProps) => {
  const classes = useStyles()

  return (
    <Grid container item className={className} spacing={1}>
      {Object.values(OrderedWeekConstants).map((weekDay) => {
        const isSelected = field.value.includes(weekDay)
        return (
          <Grid item key={weekDay}>
            <Fab
              className={classNames(classes.toggleButton, {
                [classes.inactiveToggleButton]: !isSelected,
              })}
              disabled={disabled}
              type="button"
              variant="extended"
              onClick={() =>
                field.setValue(Utils.toggleListItem(weekDay, field.value))
              }
            >
              {WeekDays[weekDay]}
            </Fab>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default WeekdaysButtonGroup
