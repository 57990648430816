import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonWithLoader, Text } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { OtherPaymentMethodButton } from '~/components/dashboard/invoices/payment/buttons/OtherPaymentMethodButton'
import { SubmitPosPaymentOrRecordManuallyButton } from '~/components/dashboard/invoices/payment/buttons/SubmitPosPaymentOrRecordManuallyButton'
import { MorePaymentOptionsMenu } from '~/components/dashboard/invoices/payment/MorePaymentOptionsMenu'
import { PaymentTypes } from '~/constants/financeConstants'
import { getPaymentsIsLinkingUnappliedPayments } from '~/store/reducers/payments'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      padding: theme.spacing(1, 5),
    },
    warningContainer: {
      borderRadius: 2,
      backgroundColor: theme.colors.actionNeededBackground,
    },
    warningIcon: {
      fontSize: '2.4rem',
      marginRight: 2,
      color: theme.colors.important,
    },
    warningText: {
      color: theme.colors.important,
    },
  }),
  { name: 'AddClientPaymentDialogActions' },
)

interface AddClientPaymentDialogActionsProps {
  hasApplyPaymentsEnabled: boolean
  isGoAvailableForPractice: boolean
  isLoading: boolean
  isOtherPaymentType: boolean
  isSaving: boolean
  onApplyPayments: () => void
  onPaymentTypeSelected: (value?: PaymentTypes) => void
  onRecordManually: () => void
  onSendToTerminal: () => void
  paymentAmount: number
  totalDueToPay: number
}

export const AddClientPaymentDialogActions = ({
  hasApplyPaymentsEnabled,
  isGoAvailableForPractice,
  isLoading,
  isOtherPaymentType,
  isSaving,
  paymentAmount,
  totalDueToPay,
  onApplyPayments,
  onPaymentTypeSelected,
  onRecordManually,
  onSendToTerminal,
}: AddClientPaymentDialogActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isLinkingUnappliedPayments = useSelector(
    getPaymentsIsLinkingUnappliedPayments,
  )

  if (hasApplyPaymentsEnabled && totalDueToPay <= 0) {
    return (
      <Box display="flex" flexDirection="column">
        {totalDueToPay < 0 && (
          <Box
            alignItems="center"
            className={classes.warningContainer}
            display="flex"
            mb={1}
            mt={-1}
            pr={1}
          >
            <InfoIcon className={classes.warningIcon} />
            <Text strong className={classes.warningText} variant="body2">
              {t('Payments:PARTIALLY_APPLIED_TO_TOTAL_DUE')}
            </Text>
          </Box>
        )}
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading || isLinkingUnappliedPayments}
          loading={isLinkingUnappliedPayments}
          onClick={onApplyPayments}
        >
          {t('Common:APPLY_PAYMENTS')}
        </ButtonWithLoader>
      </Box>
    )
  }

  if (isOtherPaymentType) {
    return (
      <ButtonWithLoader
        className={classes.button}
        disabled={isLoading}
        loading={isLoading}
        onClick={() => onPaymentTypeSelected(PaymentTypes.OTHER)}
      >
        {t('Common:NEXT')}
      </ButtonWithLoader>
    )
  }

  return (
    <>
      <SubmitPosPaymentOrRecordManuallyButton
        className={classes.button}
        isLoading={isSaving}
        paymentAmount={paymentAmount}
        recordManually={onRecordManually}
        submitPosPayment={onSendToTerminal}
      />
      <MorePaymentOptionsMenu
        isGoAvailableForPractice={isGoAvailableForPractice}
        isLoading={isLoading}
        paymentAmount={paymentAmount}
        onPaymentTypeSelected={onPaymentTypeSelected}
      />
      <OtherPaymentMethodButton
        isGoAvailableForPractice={isGoAvailableForPractice}
        isLoading={isLoading}
        recordManually={onRecordManually}
      />
    </>
  )
}
