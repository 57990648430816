import { Variation } from '~/types'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

const MaxQuantityCell = (item: Variation) => {
  const getInStockAmount = useGetInStockAmount()

  return getInStockAmount({
    amount: item.maxQuantity,
    amountUnitsId: item.maxQuantityUnitId,
    perPackageUnitsId: item.perPackageUnitsId,
    packageTypeId: item.packageTypeId,
  })
}

export default MaxQuantityCell
