import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { PermissionArea } from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import { fetchAvailabilityRule } from '~/store/actions/availabilityRules'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getAvailabilityRule,
  getAvailabilityRuleIsFetching,
  getAvailabilityRuleIsLoading,
} from '~/store/reducers/availabilityRules'
import { createDeepEqualityComparator } from '~/utils'

import AvailabilityRule, { AvailabilityRuleHandle } from './AvailabilityRule'

const getIdPropOrItself = (object: any) => object?.id || object
const compareAvailabilityRules = createDeepEqualityComparator({
  ignoreProperties: ['timezone'],
  propertyPickers: {
    business: getIdPropOrItself,
    recurrenceType: getIdPropOrItself,
    person: getIdPropOrItself,
    type: getIdPropOrItself,
  },
})

export interface AvailabilityRuleDetailsProps {
  itemId: string
  onClose: () => void
}

const AvailabilityRuleDetails = ({
  itemId,
  onClose,
}: AvailabilityRuleDetailsProps) => {
  const dispatch = useDispatch()
  const availabilityRule = useSelector(getAvailabilityRule(itemId))
  const isLoading = useSelector(getAvailabilityRuleIsLoading)
  const isFetching = useSelector(getAvailabilityRuleIsFetching)
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_AVAILABILITY),
  )
  const [isRuleChanged, setIsRuleChanged] = useState(false)
  const { t } = useTranslation('Common')

  useEffect(() => {
    if (itemId) {
      dispatch(fetchAvailabilityRule(itemId))
    }
  }, [itemId])

  const ruleRef = useRef<AvailabilityRuleHandle>(null)

  const onDeleteRequested = () => {
    if (ruleRef.current) {
      ruleRef.current.deleteRule()
    }
  }

  const onSaveRequested = () => {
    if (ruleRef.current) {
      ruleRef.current.updateRule()
    }
  }

  const getUnsavedData = () => {
    const newRule = ruleRef.current?.get()
    return (
      Boolean(availabilityRule) &&
      !compareAvailabilityRules(availabilityRule, newRule)
    )
  }

  const onChange = () => {
    setIsRuleChanged(getUnsavedData())
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:RULE_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isRuleChanged}
      isFetching={isFetching}
      isSaving={isLoading}
      showButtons={permissions.update}
      onBack={onClose}
      onDeleteRequested={onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <AvailabilityRule
        ref={ruleRef}
        rule={availabilityRule}
        onChange={onChange}
        onOk={onClose}
      />
    </Expander>
  )
}

export default AvailabilityRuleDetails
