import { AnyAction } from 'redux'

import * as AuthActions from '../actions/types/auth'
import {
  CLOSE_RECOMMENDED_BROWSERS_DIALOG,
  SHOW_RECOMMENDED_BROWSERS_DIALOG,
} from '../actions/types/loginSessionSettings'
import type { RootState } from '../index'

export type LoginSessionSettingsState = {
  shouldShowBrowserNotice: boolean
}

const INITIAL_STATE: LoginSessionSettingsState = {
  shouldShowBrowserNotice: true,
}

const loginSessionSettings = (
  state: LoginSessionSettingsState = INITIAL_STATE,
  action: AnyAction,
): LoginSessionSettingsState => {
  switch (action.type) {
    case AuthActions.LOGOUT:
    case SHOW_RECOMMENDED_BROWSERS_DIALOG:
      return { ...state, shouldShowBrowserNotice: true }
    case CLOSE_RECOMMENDED_BROWSERS_DIALOG:
      return { ...state, shouldShowBrowserNotice: false }
    default:
      return state
  }
}

export const getLoginSessionSettings = (
  state: RootState,
): LoginSessionSettingsState => state.loginSessionSettings
export const getShouldShowBrowserNotice = (state: RootState) =>
  getLoginSessionSettings(state).shouldShowBrowserNotice

export default loginSessionSettings
