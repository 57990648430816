import { gql } from '@apollo/client'

import { SPACE_MONITOR } from '../fragments/monitors'

export const FETCH_BUSINESS_UNASSIGNED_MONITOR_DEVICES = gql`
  ${SPACE_MONITOR}
  query Business($id: ID!) {
    business(id: $id) {
      id
      unassignedDevices {
        ...SpaceMonitorFields
      }
    }
  }
`
export const FETCH_SPACE_ASSIGNED_DEVICES = gql`
  ${SPACE_MONITOR}
  query Space($id: ID!) {
    space(id: $id) {
      id
      assignedDevices {
        ...SpaceMonitorFields
      }
    }
  }
`

export const GET_IN_ROOM_SPACE_MONITOR = gql`
  query InRoomSpaceMonitor($spaceId: ID!) {
    getInRoomSpaceMonitor(spaceId: $spaceId) {
      token
    }
  }
`
