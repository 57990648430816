import React from 'react'
import { useTranslation } from 'react-i18next'
import { Utils } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { useTaskEvent } from '~/store/hooks/tasks'

export interface TasksStatusFilterProps extends CheckboxListFilterProps {}

const TasksStatusFilter = (props: TasksStatusFilterProps) => {
  const { t } = useTranslation('Tasks')

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states || []

  const items = [
    { label: t('Tasks:LABEL.STATUS.OPEN'), key: 'Open' },
    { label: t('Tasks:LABEL.STATUS.DONE'), key: 'Done' },
    { label: t('Tasks:LABEL.STATUS.OVERDUE'), key: 'Overdue' },
  ].map((item) => ({
    id: Utils.findConstantIdByName(item.key, TaskStates),
    name: item.label,
  }))

  return (
    <CheckboxListFilter
      arrayFormat
      includeSearch={false}
      items={items}
      {...props}
    />
  )
}

export default TasksStatusFilter
