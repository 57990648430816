import React from 'react'
import { Trans } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'

export interface DrugsProps {
  onFullList: () => void
}

const Drugs = ({ onFullList }: DrugsProps) => (
  <>
    <Trans
      components={{
        linkToInventory: <Link to="/admin/catalog/inventories" />,
      }}
      i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.DRUGS.TEXT"
    />
    {onFullList && (
      <Trans
        components={{
          linkFullList: (
            <Text inline link fontSize="1.4rem" onClick={onFullList} />
          ),
        }}
        i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.DRUGS.HAS_FULL_LIST_TEXT"
      />
    )}
  </>
)

export default Drugs
