import { gql } from '@apollo/client'

import {
  BIG_DECIMAL_VITAL_FIELDS,
  CONSTANT_ENTITY_VITAL_FIELDS,
  INTEGER_VITAL_FIELDS,
} from '../fragments/vitals'

export const FETCH_VITALS_RECORDS = gql`
  ${BIG_DECIMAL_VITAL_FIELDS}
  ${INTEGER_VITAL_FIELDS}
  ${CONSTANT_ENTITY_VITAL_FIELDS}
  query Vitals(
    $patientId: ID!
    $businessId: ID!
    $includeDeleted: Boolean
    $offset: Int!
    $limit: Int!
  ) {
    vitals(
      patientId: $patientId
      businessId: $businessId
      includeDeleted: $includeDeleted
      offset: $offset
      limit: $limit
    ) {
      vitalsPage {
        data {
          id
          soapId
          businessId
          patientId
          finalized
          recordedById
          recordedDate
          deleted
          creatorId
          modifierId
          updatedAt
          createdAt
          weight {
            ...BigDecimalVitalValueFields
          }
          temperature {
            ...BigDecimalVitalValueFields
          }
          heartRate {
            ...IntegerVitalValueFields
          }
          respirationRate {
            ...IntegerVitalValueFields
          }
          capillaryRefillTime {
            ...ConstantEntityVitalValueFields
          }
          mucousMembraneColorId {
            ...ConstantEntityVitalValueFields
          }
          mucousMembraneMoistureId {
            ...ConstantEntityVitalValueFields
          }
          skinTurgorId {
            ...ConstantEntityVitalValueFields
          }
          bodyConditionScore {
            ...IntegerVitalValueFields
          }
          fearAnxietyScore {
            ...IntegerVitalValueFields
          }
          painScore {
            ...IntegerVitalValueFields
          }
          dyspnea {
            value
            notes
          }
          mentationId {
            ...ConstantEntityVitalValueFields
          }
          pulseCharacterId {
            ...ConstantEntityVitalValueFields
          }
          bloodPressure {
            ...BigDecimalVitalValueFields
          }
          spo2 {
            ...BigDecimalVitalValueFields
          }
          etco2 {
            ...BigDecimalVitalValueFields
          }
          o2Level {
            ...BigDecimalVitalValueFields
          }
          isoSevoLevel {
            ...BigDecimalVitalValueFields
          }
          recumbencyId {
            ...ConstantEntityVitalValueFields
          }
          muscleMassScore {
            ...IntegerVitalValueFields
          }
        }
        totalCount
      }
      persons {
        id
        firstName
        lastName
      }
    }
  }
`
