import { IntRange } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

type AutoshipFrequencyWeekOption = {
  uom: 'Week'
  value: IntRange<1, 17> // means 1~16
}

type AutoshipvalueMonthOption = {
  uom: 'Month'
  value: IntRange<5, 9> // means 5~8
}

export type AutoshipFrequencyOption =
  | AutoshipFrequencyWeekOption
  | AutoshipvalueMonthOption

/*
 * @deprecated Please use AUTOSHIP_FREQUENCY_OPTIONS_MAP instead.
 */
export const AUTOSHIP_FREQUENCY_OPTIONS: AutoshipFrequencyOption[] = [
  { uom: 'Week', value: 1 },
  { uom: 'Week', value: 2 },
  { uom: 'Week', value: 3 },
  { uom: 'Week', value: 4 },
  { uom: 'Week', value: 5 },
  { uom: 'Week', value: 6 },
  { uom: 'Week', value: 7 },
  { uom: 'Week', value: 8 },
  { uom: 'Week', value: 9 },
  { uom: 'Week', value: 10 },
  { uom: 'Week', value: 11 },
  { uom: 'Week', value: 12 },
  { uom: 'Week', value: 13 },
  { uom: 'Week', value: 14 },
  { uom: 'Week', value: 15 },
  { uom: 'Week', value: 16 },
  { uom: 'Month', value: 5 },
  { uom: 'Month', value: 6 },
  { uom: 'Month', value: 7 },
  { uom: 'Month', value: 8 },
]

export const AUTOSHIP_FREQUENCY_OPTIONS_MAP = AUTOSHIP_FREQUENCY_OPTIONS.map(
  (option) => {
    let frequencyLabel
    if (option.value === 1 && option.uom === 'Week') {
      frequencyLabel = i18n.t(`Constants:TIME_UNITS.WEEK_ONE`)
    } else if (option.uom === 'Week') {
      frequencyLabel = i18n.t(`Constants:TIME_UNITS.WEEK_OTHER`)
    } else if (option.uom === 'Month') {
      frequencyLabel = i18n.t(`Constants:TIME_UNITS.MONTH_OTHER`)
    }

    return {
      id: `${option.uom}-${option.value}`,
      name: `${i18n.t('Common:EVERY')} ${option.value} ${frequencyLabel}`,
      option,
    }
  },
)
