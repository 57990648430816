import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import { Reminder } from '~/types'

const useStyles = makeStyles(
  () => ({
    text: {
      width: '100%',
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'OneReminder' },
)

interface OneReminderProps {
  reminder: Reminder
}

const OneReminder = ({ reminder }: OneReminderProps) => {
  const classes = useStyles()
  return (
    <>
      {reminder.notes && <Text className={classes.text}>{reminder.notes}</Text>}
      {reminder.state?.id && <ReminderStateLabel reminder={reminder} />}
    </>
  )
}

export default OneReminder
