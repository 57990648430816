import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import ListSearchFilterPanel from '~/components/common/lists/ListSearchFilterPanel'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getLabTestsIsLoading,
  getLabTestsTotalCount,
} from '~/store/reducers/labTests'
import { addSearch } from '~/utils'

import LabTestBulkPricingLink from './LabTestBulkPricingLink'
import LabTestDetails from './LabTestDetails'
import LabTestsTableComponent from './LabTestsTableComponent'

const LabTestsPage = () => {
  const location = useLocation()
  const { labTestId } = useParams()
  const navigate = useNavigate()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const totalCount = useSelector(getLabTestsTotalCount)
  const isLoading = useSelector(getLabTestsIsLoading)
  const bulkPricePermissions = useSelector(
    getCRUDByArea(PermissionArea.LAB_TEST_BULK_PRICE),
  )
  const isBulkPriceAvailable = bulkPricePermissions.create

  const onDetailsClose = () => {
    navigate(addSearch(location, '/admin/catalog/lab-tests'))
  }

  if (isMobile && typeof labTestId !== 'undefined') {
    return <LabTestDetails itemId={labTestId} onClose={onDetailsClose} />
  }

  const headerButtons = (
    <Grid container justifyContent="space-between" px={3} py={2}>
      <Grid item>
        <ListSearchFilterPanel isLoading={isLoading} searchCount={totalCount} />
      </Grid>
      <Grid item>{isBulkPriceAvailable && <LabTestBulkPricingLink />}</Grid>
    </Grid>
  )

  return (
    <Grid container flex={1} wrap="nowrap">
      <LabTestsTableComponent
        headerButtons={headerButtons}
        labTestId={labTestId}
        onDetailsClose={onDetailsClose}
      />
    </Grid>
  )
}

export default LabTestsPage
