import React from 'react'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { HtmlNotesPreview, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'NotesWidgetLockedItem' },
)

export interface NotesWidgetLockedItemProps {
  label: string
  value: string
}

const NotesWidgetLockedItem = ({
  label,
  value,
}: NotesWidgetLockedItemProps) => {
  const classes = useStyles()

  return (
    <Grid container item className={classes.root}>
      <Grid item className={classes.titleContainer} px={1.5} py={2} xs={2}>
        <Text variant="h4">{label}</Text>
      </Grid>
      <Grid container item xs direction="column" pb={3} pt={1.5} px={2}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <HtmlNotesPreview notes={value} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}

export default NotesWidgetLockedItem
