import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import NoItemsScreen from '../NoItemsScreen'

export interface NoTasksScreenProps {
  headerButtons: React.ReactNode
}

const NoTasksScreen = ({ headerButtons }: NoTasksScreenProps) => {
  const [openTaskDialog] = useDialog(DialogNames.TASK)
  const { t } = useTranslation('Tasks')

  return (
    <NoItemsScreen
      action={() => openTaskDialog({ redirectOnCreated: true })}
      actionMessage={t('Tasks:LABEL.NEW_TASK')}
      title={headerButtons}
    >
      <Text>{t('Tasks:NO_TASKS_SCREEN.NO_TASKS_IN_QUEUE')}</Text>
    </NoItemsScreen>
  )
}

export default NoTasksScreen
