import { gql } from '@apollo/client'

const PRICE_TYPE = gql`
  fragment PriceTypeFields on PriceType {
    id
    name
    displayName
    displayName2
    shortName
  }
`

export const WP_PRICE = gql`
  ${PRICE_TYPE}
  fragment WPlanPriceFields on WPlanPrice {
    id
    businessId
    versionId
    planId
    price
    priceType {
      ...PriceTypeFields
    }
    priceTypeId
    stripePriceId
    prevStripePriceId
    creationDate
    createdById
    modifiedById
  }
`

export const BENEFIT_FIELDS = gql`
  fragment BenefitFields on Benefit {
    id
    order
    businessId
    versionId
    planId
    globalBenefitId
    groupId
    name
    description
    type
    planType
    level
    coverCatalog
    coverAppointmentType
    limitCustomizable
    limit
  }
`

export const WELLNESS_PLAN_FIELDS = gql`
  ${WP_PRICE}
  ${BENEFIT_FIELDS}
  fragment WellnessPlanFields on WellnessPlan {
    id
    businessId
    versionId
    name
    hidden
    level
    planType
    prices {
      ...WPlanPriceFields
    }
    loyaltyRedeemRate
    loyaltyAccumulateRate
    benefits {
      ...BenefitFields
    }
    stripeProductId
    statementDescriptor
    stripePriceId
    creationDate
    createdById
    modificationDate
    modifiedById
    autoRenewal
    autoRenewalModificationDatetime
    priceTypeId
    priceType {
      ...PriceTypeFields
    }
    planTypeId
    price
  }
`
