import { Field, FieldObject } from '@pbt/pbt-ui-components'

import { getPluckedFieldsByFieldKey } from '.'

export type ParsedField = Record<string, any>

export const getParsedFields = <T extends object>(
  fields: FieldObject,
  parsedFields?: ParsedField,
  value: keyof Field = 'value',
): T | ParsedField => {
  const rawValues = getPluckedFieldsByFieldKey<T>(fields, value)

  if (!parsedFields) {
    return rawValues
  }

  return parsedFields
}
