import { AnyAction } from 'redux'

import type { RootState } from '..'

export const OPEN_INTERCOM = 'intercom/OPEN_INTERCOM'
export const CLOSE_INTERCOM = 'intercom/CLOSE_INTERCOM'
export const ON_INTERCOM_CLOSED = 'intercom/ON_INTERCOM_CLOSED'
export const SEND_INTERCOM_FAILURE = 'intercom/SEND_INTERCOM_FAILURE'

export const openIntercom = () => ({ type: OPEN_INTERCOM })
export const closeIntercom = () => ({ type: CLOSE_INTERCOM })
export const onIntercomClosed = () => ({ type: ON_INTERCOM_CLOSED })
export const sendIntercomFailure = (error: Error) => ({
  type: SEND_INTERCOM_FAILURE,
  error,
})

export type IntercomState = {
  isOpen: boolean
}

const INITIAL_STATE: IntercomState = {
  isOpen: false,
}

export const intercomReducer = (
  state: IntercomState = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case OPEN_INTERCOM:
      return { ...state, isOpen: true }
    case CLOSE_INTERCOM:
      return { ...state, isOpen: false }
    case ON_INTERCOM_CLOSED:
      return { ...state, isOpen: false }
    default:
      return state
  }
}

export const getIntercom = (state: RootState): IntercomState => state.intercom
export const getIsIntercomOpen = (state: RootState) => getIntercom(state).isOpen
