import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Business, ButtonWithLoader, Text } from '@pbt/pbt-ui-components'

import SearchInput from '~/components/common/inputs/SearchInput'
import ClinicLogo from '~/components/common/logos/ClinicLogo'
import { fetchAssignedPractices } from '~/store/actions/businesses'
import {
  getAssignedPracticesList,
  getBusinessIsLoading,
  getMultipleBusinesses,
} from '~/store/reducers/businesses'

const useStyles = makeStyles(
  (theme) => ({
    groupContainer: {
      padding: theme.spacing(2, 4),
      backgroundColor: '#FAFAFA',
    },
    searchInput: {
      marginTop: 0,
    },
    practicesContainer: {
      border: '1px solid #E5E5E5',
      height: 320,
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        height: 394,
      },
    },
    button: {
      minWidth: 160,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    buttonContainer: {
      minWidth: 160,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    buttonContainerLeft: {
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
        marginRight: 0,
      },
    },
    practiceLogo: {
      padding: 0,
      width: 100,
      minWidth: 100,
      height: 56,
      boxShadow:
        '1px 1px 2px 0 rgba(60,56,56,0.14), 0 1px 4px 0 rgba(60,56,56,0.12)',
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        marginLeft: theme.spacing(1),
      },
    },
    name: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        padding: 0,
        paddingLeft: 10,
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        maxHeight: 35,
      },
      whiteSpace: 'nowrap',
      wordWrap: 'normal',
      fontSize: '2rem',
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
      },
    },
    practiceItem: {
      display: 'flex',
      alignItems: 'center',
      height: 56,
      marginBottom: theme.spacing(2),
    },
    checkboxClasses: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5),
      },
    },
  }),
  { name: 'GroupPracticeSelect' },
)

export interface GroupPracticeSelectProps {
  actionLabel?: string
  onAddNewPractice: () => void
  onSelect: (businesses: Business[]) => void
  selectedPractices?: string[]
}

const GroupPracticeSelect = ({
  actionLabel: actionLabelProp,
  onSelect,
  onAddNewPractice,
  selectedPractices,
}: GroupPracticeSelectProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Admin'])
  const actionLabel = actionLabelProp || t('Common:ADD_SELECTED')
  const dispatch = useDispatch()

  const list = useSelector(getAssignedPracticesList) || []
  const businesses = useSelector(getMultipleBusinesses(list), R.equals)
  const isLoading = useSelector(getBusinessIsLoading)

  useEffect(() => {
    dispatch(fetchAssignedPractices())
  }, [])

  const [checkedPractices, setCheckedPractices] = useState(
    selectedPractices || [],
  )

  const practices = businesses.filter(
    (practice) => !practice.children || practice.children.length === 0,
  )

  const [search, setSearch] = useState('')

  const addSelected = () => {
    const filteredBusinesses = practices.filter(({ id }) =>
      checkedPractices.includes(id),
    )
    onSelect(filteredBusinesses)
  }

  const filteredPractices = search
    ? practices.filter((practice) =>
        practice.name.toLowerCase().includes(search.toLowerCase()),
      )
    : practices

  return (
    <Grid container direction="column">
      <SearchInput
        className={classes.searchInput}
        label={t('Admin:PRACTICE.SEARCH_PRACTICE')}
        value={search}
        onChange={setSearch}
      />
      <Grid
        container
        className={classes.practicesContainer}
        mt={1}
        px={4}
        py={2}
      >
        {isLoading ? (
          <Grid container alignItems="center" justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          filteredPractices.map((practice) => (
            <Grid
              item
              className={classes.practiceItem}
              key={practice.id}
              xs={12}
            >
              <Checkbox
                checked={checkedPractices.includes(practice.id) || false}
                className={classes.checkboxClasses}
                value={practice.id.toString()}
                onChange={({ target: { checked } }) => {
                  setCheckedPractices(
                    checked
                      ? checkedPractices.concat(practice.id)
                      : R.without([practice.id], checkedPractices),
                  )
                }}
              />
              <ClinicLogo className={classes.practiceLogo} clinic={practice} />
              <Dotdotdot clamp={2}>
                <Text
                  noWrap
                  strong
                  align="left"
                  className={classes.name}
                  ml={3}
                  variant="h3"
                >
                  {practice.name}
                </Text>
              </Dotdotdot>
            </Grid>
          ))
        )}
      </Grid>
      <Grid container item alignItems="center" mt={2} xs={12}>
        <Grid
          item
          className={classNames(
            classes.buttonContainer,
            classes.buttonContainerLeft,
          )}
          sm={1}
          xs={12}
        >
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            onClick={addSelected}
          >
            {actionLabel}
          </ButtonWithLoader>
        </Grid>
        {onAddNewPractice && (
          <Grid item className={classes.buttonContainer} sm={1} xs={12}>
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              onClick={onAddNewPractice}
            >
              {t('Admin:PRACTICE.ADD_NEW_PRACTICE')}
            </ButtonWithLoader>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default GroupPracticeSelect
