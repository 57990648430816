import React from 'react'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from './filters/CheckboxListFilter'

export interface ChooseColumnsPopperProps extends CheckboxListFilterProps {
  possibleColumns: string[]
}

const ChooseColumnsPopper = ({
  arrayFormat = true,
  possibleColumns,
  ...rest
}: ChooseColumnsPopperProps) => {
  const items = possibleColumns.map((name) => ({ id: name, name }))

  return (
    <CheckboxListFilter
      arrayFormat={arrayFormat}
      includeSearch={false}
      items={items}
      {...rest}
    />
  )
}

export default ChooseColumnsPopper
