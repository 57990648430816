import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  AddButton,
  BusinessLicense,
  CustomFieldValidatorState,
  PermissionArea,
  PuiTextField,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getBusinessIsLoading } from '~/store/reducers/businesses'
import {
  BasePracticeDetailsSectionProps,
  PracticeLicensesFields,
} from '~/types'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import LicensesSectionTable from '../../../members/LicensesSectionTable'
import { PracticeDetailsPanels } from '../../practices'

const PDMP_PROVIDER_ID_LENGTH = 7
const PDMP_NPI_LENGTH = 10

const LicensesSection = ({ business }: BasePracticeDetailsSectionProps) => {
  const { t } = useTranslation(['Abbreviations', 'Businesses'])

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  const [openLicenseDialog] = useDialog(DialogNames.LICENSE)

  const validatePdmpProviderId = ({
    state: { pdmpProviderId },
  }: CustomFieldValidatorState) =>
    R.isNil(pdmpProviderId) || pdmpProviderId.length <= PDMP_PROVIDER_ID_LENGTH
  const validatePdmpNpi = ({ state: { pdmpNpi } }: CustomFieldValidatorState) =>
    R.isNil(pdmpNpi) || pdmpNpi.length <= PDMP_NPI_LENGTH

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'licenses',
        initialValue: business.licenses || [],
        type: 'select',
      },
      {
        name: 'pdmpProviderId',
        label: t('Abbreviations:ACRONYMS.NCPDP_NABP.PDMP_PROVIDER'),
        initialValue: business.pdmpProviderId || '',
        messages: {
          pdmpProviderId: t(
            'Abbreviations:ACRONYMS.NCPDP_NABP.PDMP_PROVIDER_FIELD_MESSAGE',
            {
              length: PDMP_PROVIDER_ID_LENGTH,
            },
          ),
        },
        validators: [
          {
            validator: validatePdmpProviderId,
            validatorName: 'pdmpProviderId',
          },
        ],
      },
      {
        name: 'pdmpNpi',
        label: t('Abbreviations:ACRONYMS.NATIONAL_PROVIDER_IDENTIFIER.LABEL'),
        initialValue: business.pdmpNpi || '',
        messages: {
          pdmpNpi: t(
            'Abbreviations:ACRONYMS.NATIONAL_PROVIDER_IDENTIFIER.FIELD_MESSAGE',
            { length: PDMP_NPI_LENGTH },
          ),
        },
        validators: [{ validator: validatePdmpNpi, validatorName: 'pdmpNpi' }],
      },
    ],
    false,
  )

  const { pdmpProviderId, pdmpNpi, licenses } = fields

  usePracticeFieldsSection<PracticeLicensesFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.LICENSES,
    validate,
    reset,
  })

  const getLicenseIndex = (licenseId: string | undefined) =>
    R.findIndex(R.propEq('id', licenseId), licenses.value)

  const onCreateLicense = (newLicense: BusinessLicense) => {
    licenses.setValue([...licenses.value, newLicense])
  }

  const onEditLicense = (newLicense: BusinessLicense) => {
    const index = getLicenseIndex(newLicense.id)
    const newLicenses = R.update(index, newLicense, licenses.value)
    licenses.setValue(newLicenses)
  }

  const handleLicenseAdd = () => {
    openLicenseDialog({
      getIsLoading: getBusinessIsLoading,
      onCreateLicense,
      onEditLicense,
    })
  }

  const handleLicenseEdit = (licenseId: string) => {
    openLicenseDialog({
      getIsLoading: getBusinessIsLoading,
      license: Utils.findById(licenseId, licenses.value),
      onCreateLicense,
      onEditLicense,
    })
  }

  const handleLicenseDelete = (licenseId: string) => {
    const index = getLicenseIndex(licenseId)
    licenses.setValue(R.remove(index, 1, licenses.value))
  }

  return (
    <>
      <Grid item mb={2}>
        <TextWithTooltip
          allowWrap
          strong
          tooltipText={t(
            'Abbreviations:ACRONYMS.PDMP.CONTROLLED_SUBSTANCE_REQUIRED_LICENSES_TOOLTIP',
          )}
          variant="subheading3"
        >
          {t('Businesses:LICENSES.CONTROLLED_SUBSTANCE_REQUIRED_LICENSES')}
        </TextWithTooltip>
      </Grid>
      <Grid container item alignItems="center" mb={2} spacing={3}>
        <Grid item xs={6}>
          <PuiTextField
            disabled={!permissions.update}
            field={pdmpNpi}
            inputProps={{ maxLength: 100 }}
            label={pdmpNpi.label}
          />
        </Grid>
        <Grid item xs={6}>
          <PuiTextField
            disabled={!permissions.update}
            field={pdmpProviderId}
            inputProps={{ maxLength: 100 }}
            label={pdmpProviderId.label}
            margin="none"
          />
        </Grid>
      </Grid>
      <LicensesSectionTable
        deleteDisabled={!permissions.update}
        editDisabled={!permissions.update}
        licenses={licenses.value}
        onDelete={handleLicenseDelete}
        onEdit={handleLicenseEdit}
      />
      {permissions.update && (
        <Grid item mt={2}>
          <AddButton
            addText={t('Businesses:LICENSES.ADD_LICENSE')}
            onAdd={handleLicenseAdd}
          />
        </Grid>
      )}
    </>
  )
}

export default memo(LicensesSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
