import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'

interface SubmitPosPaymentOrRecordManuallyButtonProps {
  className?: string
  isLoading: boolean
  paymentAmount: number
  recordManually: () => void
  submitPosPayment: () => void
}

export const SubmitPosPaymentOrRecordManuallyButton = ({
  className,
  isLoading,
  paymentAmount,
  recordManually,
  submitPosPayment,
}: SubmitPosPaymentOrRecordManuallyButtonProps) => {
  const { t } = useTranslation(['Common', 'Invoices'])
  const currentBusiness = useSelector(getCurrentBusiness)

  const { posPayEnabled } = currentBusiness || {}

  return (
    <ButtonWithLoader
      className={className}
      disabled={isLoading || !Number(paymentAmount)}
      loading={isLoading}
      onClick={posPayEnabled ? submitPosPayment : recordManually}
    >
      {posPayEnabled
        ? t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.SEND_TO_TERMINAL')
        : t('Common:CONTINUE_ACTION')}
    </ButtonWithLoader>
  )
}
