import i18n from '~/locales/i18n'

import { ColorVariant } from './colors'

export const InventoryOrderStatusName = {
  ORDERED: i18n.t('Constants:INVENTORY_ORDER_STATUS_NAME.ORDERED'),
  BACKORDERED: i18n.t('Constants:INVENTORY_ORDER_STATUS_NAME.BACKORDERED'),
  TIME_TO_REORDER: i18n.t(
    'Constants:INVENTORY_ORDER_STATUS_NAME.TIME_TO_REORDER',
  ),
  NONE: i18n.t('Constants:INVENTORY_ORDER_STATUS_NAME.NONE'),
}

export const InventoryOrderStatusColor = {
  [InventoryOrderStatusName.ORDERED]: ColorVariant.NORMAL,
  [InventoryOrderStatusName.BACKORDERED]: ColorVariant.IMPORTANT,
  [InventoryOrderStatusName.TIME_TO_REORDER]: ColorVariant.IMPORTANT,
  [InventoryOrderStatusName.NONE]: ColorVariant.DISABLED,
}

export const NotSelectableInventoryAdjustmentReasons = [
  'Refund Restock',
  'Refund Restock Reversal',
]
