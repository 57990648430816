import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  Question as QuestionType,
  UnsavedQuestion,
} from '@pbt/pbt-ui-components'

import { createQuestion } from '~/store/actions/questions'
import {
  getLastCreatedQuestionId,
  getQuestionsIsLoading,
} from '~/store/reducers/questions'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import Question, { QuestionHandle } from './Question'
import QuestionSettings, { QuestionSettingsHandle } from './QuestionSettings'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'QuestionDialog' },
)

interface QuestionDialogProps extends BasePuiDialogProps {
  question?: QuestionType
}

const QuestionDialog = ({
  question: questionProp,
  open,
  onClose,
}: QuestionDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const lastCreatedQuestionId = useSelector(getLastCreatedQuestionId)
  const { t } = useTranslation('Common')

  const [showSettings, setShowSettings] = useState(false)
  const [questionCandidate, setQuestionCandidate] = useState<
    QuestionType | Nil
  >(null)

  const questionRef = useRef<QuestionHandle>(null)
  const questionSettingsRef = useRef<QuestionSettingsHandle>(null)

  const handleClose = () => {
    setShowSettings(false)
    setQuestionCandidate(null)
    if (onClose) {
      onClose()
    }
  }

  const onQuestionCreated = () => {
    handleClose()
    if (lastCreatedQuestionId) {
      navigate(`/admin/catalog/questions/${lastCreatedQuestionId}`)
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onQuestionCreated,
    getQuestionsIsLoading,
  )

  const onQuestionOk = (newQuestion: QuestionType) => {
    setQuestionCandidate(newQuestion)
    setShowSettings(true)
  }

  const onBackFromSettings = () => {
    setShowSettings(false)
  }

  const onProceed = (question: Partial<UnsavedQuestion>) => {
    setCloseAfterCreationOn()
    dispatch(createQuestion(question))
  }

  const question = questionCandidate || questionProp

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () =>
          showSettings
            ? questionSettingsRef.current?.add()
            : questionRef.current?.proceed(),
      }}
      aria-labelledby="question-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={() =>
        showSettings
          ? Boolean(questionSettingsRef.current?.hasUnsavedChanges())
          : Boolean(questionRef.current?.hasUnsavedChanges())
      }
      open={open}
      title={`${t('Common:NEW_QUESTION')}${
        showSettings ? `: ${questionCandidate?.questionText}` : ''
      }`}
      onClose={handleClose}
    >
      {showSettings ? (
        <QuestionSettings
          question={question}
          ref={questionSettingsRef}
          onBack={onBackFromSettings}
          onProceed={onProceed}
        />
      ) : (
        <Question question={question} ref={questionRef} onOk={onQuestionOk} />
      )}
    </PuiDialog>
  )
}

export default QuestionDialog
