import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import FinalizeSection from '~/components/common/buttons/FinalizeSection'
import LeaveConfirmationDialog from '~/components/common/dialog/LeaveConfirmationDialog'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { finalizeSoap } from '~/store/actions/soap'
import {
  getHasOutstandingOrders,
  getImagingIsLoading,
} from '~/store/duck/imagingOrders'
import { useShowOutstandingLabOrdersSection } from '~/store/hooks/labOrders'
import {
  getCRUDByArea,
  getCurrentBusinessIsIdexxImagingEnabled,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'

import ImagingOrdersSection from '../../imaging-procedures/ImagingOrdersSection'
import ClientSurveyWidget from '../../soapV2/finalize-orders/ClientSurveyWidget'
import FinalizeOrdersWidget from '../../soapV2/finalize-orders/FinalizeOrdersWidget'
import MedicalDischargeNotesWidgetUnlocked from '../../soapV2/medical-discharge-notes/MedicalDischargeNotesWidgetUnlocked'
import useConfirmLockSoapAlert from '../useConfirmLockSoapAlert'
import LabOrders from './lab-orders/LabOrders'
import SOAPScheduler from './SOAPScheduler'

const UNSAVED_DATA = {
  LAB_ORDER: i18n.t('Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.LAB_ORDER'),
  IMAGING_ORDER: i18n.t(
    'Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.IMAGING_ORDER',
  ),
  MEDICAL_NOTES: i18n.t(
    'Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.MEDICAL_NOTES',
  ),
  DISCHARGE_NOTES: i18n.t(
    'Soap:THERE_ARE_UNFINISHED_ITEMS_ON_THIS_PAGE.DISCHARGE_NOTES',
  ),
}

const SoapWrapUp = () => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const hasOutstandingImagingOrders = useSelector(getHasOutstandingOrders)
  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const imagingOrdersListLoading = useSelector(getImagingIsLoading)
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )

  const { t } = useTranslation('Common')

  const { showOutstandingLabOrdersSection } =
    useShowOutstandingLabOrdersSection()

  const [openConfirmLockSoapAlert] = useConfirmLockSoapAlert()

  const handleLockSoap = () =>
    openConfirmLockSoapAlert(() => dispatch(finalizeSoap()))

  const isFinalizeWidgetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FINALIZE_WIDGET),
  )

  const isQualtricsIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.QUALTRICS_INTEGRATION),
  )
  const isOmniChannel = useSelector(getCurrentBusinessIsOmniChannel)

  return (
    <Grid container item direction="column">
      <Grid container>
        <MedicalDischargeNotesWidgetUnlocked />
      </Grid>

      {isFinalizeWidgetEnabled ? (
        <Grid container item mt={2}>
          <FinalizeOrdersWidget />
        </Grid>
      ) : (
        <>
          <LabOrders soapBusinessId={soapBusinessId} soapId={soapId} />
          {isImagingIntegrated && <ImagingOrdersSection />}
        </>
      )}

      {SOAPPermissions.update && (
        <FinalizeSection
          buttonLabel={t('Common:FINALIZE_SOAP')}
          title={t('Common:OPEN_SOAP')}
          onClick={handleLockSoap}
        />
      )}
      <Grid container item mt={2}>
        <SOAPScheduler />
      </Grid>
      {isQualtricsIntegrationEnabled && isOmniChannel && (
        <Grid container item mt={2}>
          <ClientSurveyWidget />
        </Grid>
      )}
      <LeaveConfirmationDialog
        navigateOnCancel
        dialogName={DialogNames.SOAP_WRAP_UP_LEAVE}
        getUnsavedData={() => {
          const unsavedData = []

          if (showOutstandingLabOrdersSection) {
            unsavedData.push(UNSAVED_DATA.LAB_ORDER)
          }

          if (
            isImagingIntegrated &&
            hasOutstandingImagingOrders &&
            !imagingOrdersListLoading
          ) {
            unsavedData.push(UNSAVED_DATA.IMAGING_ORDER)
          }

          return unsavedData
        }}
        navigateOnProceed={false}
      />
    </Grid>
  )
}

export default SoapWrapUp
