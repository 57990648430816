import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import DialogNames from '~/constants/DialogNames'
import { getPatient } from '~/store/reducers/patients'
import { getAppointmentId, getPetParents } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

export const usePetParentChangeAlert = () => {
  const petParents = useSelector(getPetParents)
  const newClientId = petParents && petParents[0]

  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const patient = useSelector(getPatient(appointment?.patient))
  const client = useSelector(getUser(appointment?.client))
  const newClient = useSelector(getUser(newClientId))

  const [openPetParentChangedAlertDialog] = useDialog(
    DialogNames.PET_PARENT_CHANGED_DIALOG,
  )

  const [appointmentWithChangedClient, setAppointmentWithChangedClient] =
    useState<string>()

  useEffect(() => {
    if (
      appointmentId &&
      appointmentWithChangedClient !== appointmentId &&
      client &&
      patient &&
      newClientId &&
      newClient &&
      client.id !== newClient.id
    ) {
      openPetParentChangedAlertDialog({ patient, client, newClient })
      setAppointmentWithChangedClient(appointmentId)
    }
  }, [appointmentId, client, newClient, patient])
}
