import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { PuiTextField, useFields } from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import { BenchmarkingKey } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'

interface BenchmarkingActivationKeyFormProps {
  item?: BenchmarkingKey
  onChange?: () => void
}

const BenchmarkingActivationKeyForm = forwardRef(
  function BenchmarkingActivationKeyForm(
    { item, onChange = () => {} }: BenchmarkingActivationKeyFormProps,
    ref,
  ) {
    const { t } = useTranslation(['Admin', 'Common'])

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'practiceName',
          label: t('Admin:BENCHMARK.PRACTICE_NAME'),
          validators: ['required'],
          initialValue: item?.practiceName || '',
        },
        {
          name: 'contactFirstName',
          label: t('Admin:BENCHMARK.CONTACT_FIRST_NAME'),
          validators: ['required'],
          initialValue: item?.contactFirstName || '',
        },
        {
          name: 'contactLastName',
          label: t('Admin:BENCHMARK.CONTACT_LAST_NAME'),
          validators: ['required'],
          initialValue: item?.contactLastName || '',
        },
        {
          name: 'contactEmail',
          label: t('Common:CONTACT_EMAIL'),
          validators: ['required', 'email'],
          initialValue: item?.contactEmail || '',
        },
      ],
      false,
    )

    const { practiceName, contactFirstName, contactLastName, contactEmail } =
      fields

    const get = () => ({
      ...(item || {}),
      practiceName: practiceName.value,
      contactFirstName: contactFirstName.value,
      contactLastName: contactLastName.value,
      contactEmail: contactEmail.value,
    })

    useImperativeHandle(ref, () => ({
      validate,
      get,
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    useFieldsChanged(() => {
      onChange()
    }, fields)

    useEffect(() => {
      reset()
    }, [item])

    return (
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <PuiTextField
            field={practiceName}
            inputProps={{ maxLength: 100 }}
            label={`${practiceName.label}*`}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PuiTextField
            field={contactFirstName}
            inputProps={{ maxLength: 100 }}
            label={`${contactFirstName.label}*`}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PuiTextField
            field={contactLastName}
            inputProps={{ maxLength: 100 }}
            label={`${contactLastName.label}*`}
          />
        </Grid>
        <Grid item xs={12}>
          <PuiTextField
            autoComplete="email"
            field={contactEmail}
            inputProps={{ maxLength: 100 }}
            label={`${contactEmail.label}*`}
            margin="none"
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
    )
  },
)

export default BenchmarkingActivationKeyForm
