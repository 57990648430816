import React from 'react'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import i18n from '~/locales/i18n'

const Options = [
  {
    id: 'true',
    name: i18n.t('Admin:APPOINTMENT_CONFIGURATION.CLIENT_SCHEDULING_ALLOWED'),
  },
  {
    id: 'false',
    name: i18n.t(
      'Admin:APPOINTMENT_CONFIGURATION.CLIENT_SCHEDULING_NOT_ALLOWED',
    ),
  },
]

export interface AppointmentTypeClientSchedulingFilterProps
  extends CheckboxListFilterProps {}

const AppointmentTypeClientSchedulingFilter = (
  props: AppointmentTypeClientSchedulingFilterProps,
) => (
  <CheckboxListFilter
    clearEmptyFilters
    singleSelection
    includeSearch={false}
    items={Options}
    {...props}
  />
)

export default AppointmentTypeClientSchedulingFilter
