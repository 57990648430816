import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '@mui/material'
import { flatten, identity, pluck } from 'ramda'

import FeatureToggle from '~/constants/featureToggle'
import {
  getInvoiceSelectedColumns,
  updateInvoiceSelectedColumns,
} from '~/store/duck/userSettings'
import { useGetEstimateState } from '~/store/hooks/estimate'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Invoice } from '~/types'

import { isEveryGroupOfSingleQuantity } from '../invoiceUtils'
import InvoiceTableBody, {
  InvoiceTableBodyProps,
} from './body/InvoiceTableBody'
import InvoiceTableHead from './head/InvoiceTableHead'
import { getInvoiceColumns } from './invoiceTableConstants'

export interface InvoiceTableProps
  extends Omit<
    InvoiceTableBodyProps,
    'prePaidEnabled' | 'rangeEnabled' | 'selectedColumns'
  > {}

const InvoiceTable = ({
  isEstimate,
  isReadOnly,
  invoice,
  updateInvoice,
  onUnblockEditInvoice,
  ...rest
}: InvoiceTableProps) => {
  const dispatch = useDispatch()
  const selectedColumnsFromState = useSelector(getInvoiceSelectedColumns)
  const currentUserId = useSelector(getCurrentUserId) as string

  const isNewEstimateStatusFlowEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  const selectedColumns = getInvoiceColumns(
    selectedColumnsFromState,
    isEstimate,
  )

  const [rangeEnabled, setRangeEnabled] = useState(false)

  const groups = (invoice && invoice.groups) || []

  useEffect(() => {
    if (!isEveryGroupOfSingleQuantity(groups)) {
      setRangeEnabled(true)
    }
  }, [invoice])

  const prepaidMarkers = groups.map(({ groupedItems }) =>
    groupedItems.map((item) =>
      item.items ? item.items && pluck('prepaid', item.items) : item.prepaid,
    ),
  )

  const prePaidEnabled = flatten(prepaidMarkers).some(identity)

  const updateSelectedColumns = (columns: string[]) => {
    const updatedColumns = getInvoiceColumns(columns, isEstimate)

    dispatch(updateInvoiceSelectedColumns(currentUserId, updatedColumns))
  }

  const estimateState = useGetEstimateState()(invoice.stateId)

  return (
    <Table>
      <InvoiceTableHead
        invoice={invoice as Invoice}
        isEstimate={isEstimate}
        isReadOnly={isReadOnly}
        prePaidEnabled={prePaidEnabled}
        rangeEnabled={rangeEnabled}
        selectedColumns={selectedColumns}
        setRangeEnabled={setRangeEnabled}
        setSelectedColumns={updateSelectedColumns}
        updateInvoice={updateInvoice}
      />
      <InvoiceTableBody
        invoice={invoice}
        isEstimate={isEstimate}
        isReadOnly={
          isReadOnly ||
          (isEstimate &&
            isNewEstimateStatusFlowEnabled &&
            (estimateState.isApproved || estimateState.isDeclined))
        }
        prePaidEnabled={prePaidEnabled}
        rangeEnabled={rangeEnabled}
        selectedColumns={selectedColumns}
        updateInvoice={updateInvoice}
        onUnblockEditInvoice={onUnblockEditInvoice}
        {...rest}
      />
    </Table>
  )
}

export default InvoiceTable
