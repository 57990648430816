import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NamedEntity, Text } from '@pbt/pbt-ui-components'

// @ts-ignore
import { addAnyPrefixIfNeeded } from './reminderProtocolUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderTop: theme.constants.tableBorder,
    },
    block: {
      display: 'block',
    },
  }),
  { name: 'ReminderProtocolListTableSection' },
)

export interface ReminderProtocolListTableSectionProps {
  items?: NamedEntity[]
  label: string
  placeholder: string
}

const ReminderProtocolListTableSection = ({
  label,
  items: itemsProp,
  placeholder,
}: ReminderProtocolListTableSectionProps) => {
  const items = itemsProp || []
  const classes = useStyles()

  return (
    <Grid container item className={classes.root}>
      <Grid item xs={3}>
        <Text strong px={1} py={2} variant="lowAccent2">
          {label}
        </Text>
      </Grid>
      <Grid item xs={9}>
        {items?.length > 0 ? (
          <Text px={1} py={2} variant="body2">
            {items.map((item, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <span className={classes.block} key={index}>
                {addAnyPrefixIfNeeded(item)}
              </span>
            ))}
          </Text>
        ) : (
          <Text px={1} py={2} variant="lowAccent2">
            {placeholder}
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

export default ReminderProtocolListTableSection
