import { ApiError, User } from '@pbt/pbt-ui-components'

import {
  CREATE_COPARENT,
  CREATE_COPARENT_FAILURE,
  CREATE_COPARENT_SUCCESS,
  DELETE_COPARENT,
  DELETE_COPARENT_FAILURE,
  DELETE_COPARENT_SUCCESS,
  UPDATE_COPARENT,
  UPDATE_COPARENT_FAILURE,
  UPDATE_COPARENT_SUCCESS,
} from './types/coparents'

export const createCoparent = (clientId: string, coparent: Partial<User>) => ({
  type: CREATE_COPARENT,
  clientId,
  coparent,
})
export const createCoparentSuccess = (clientId?: string) => ({
  type: CREATE_COPARENT_SUCCESS,
  clientId,
})
export const createCoparentFailure = (error: ApiError) => ({
  type: CREATE_COPARENT_FAILURE,
  error,
})
export const updateCoparent = (clientId: string, coparent: Partial<User>) => ({
  type: UPDATE_COPARENT,
  clientId,
  coparent,
})
export const updateCoparentSuccess = (clientId?: string) => ({
  type: UPDATE_COPARENT_SUCCESS,
  clientId,
})
export const updateCoparentFailure = (error: ApiError) => ({
  type: UPDATE_COPARENT_FAILURE,
  error,
})
export const deleteCoparent = (clientId: string, coparentId: string) => ({
  type: DELETE_COPARENT,
  clientId,
  coparentId,
})
export const deleteCoparentSuccess = () => ({ type: DELETE_COPARENT_SUCCESS })
export const deleteCoparentFailure = (error: ApiError) => ({
  type: DELETE_COPARENT_FAILURE,
  error,
})
