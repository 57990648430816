import React, { useSelector } from 'react-redux'
import {
  PrimitiveTable as PrimitiveTableWithoutSearchHighlights,
  PrimitiveTableProps,
} from '@pbt/pbt-ui-components'

import { getSearchHighlights } from '~/store/reducers/search'

const PrimitiveTableWithSearchHighlights = (props: PrimitiveTableProps) => {
  const { RowComponentProps = {}, ...rest } = props
  const searchHighlights = useSelector(getSearchHighlights)

  return (
    <PrimitiveTableWithoutSearchHighlights
      RowComponentProps={{
        searchHighlights,
        ...RowComponentProps,
      }}
      {...rest}
    />
  )
}

export default PrimitiveTableWithSearchHighlights
