import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  DocumentFile,
  FileTemplate,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import { matchImageExtension } from '~/constants/extensions'
import { getOriginalFileName } from '~/utils'

import ImagePreview, { ImagePreviewHandle } from '../../../preview/ImagePreview'
import RequiredFieldsNotice from '../../RequiredFieldsNotice'
import AttachmentDetails from './AttachmentDetails'

const useStyles = makeStyles(
  (theme) => ({
    cropper: {
      width: '100%',
      height: '100%',
    },
    button: {
      marginLeft: theme.spacing(2),
      minWidth: 150,
      height: 40,
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'AttachmentPreview' },
)

export interface AttachmentPreviewProps {
  DetailsComponent?: React.JSXElementConstructor<any>
  file: DocumentFile['file']
  isLoading?: boolean
  onFileSelected: (file: FileTemplate) => void
  onProceed: (file: DocumentFile['file']) => void
}

const AttachmentPreview = ({
  file,
  onFileSelected,
  onProceed,
  isLoading,
  DetailsComponent = AttachmentDetails,
}: AttachmentPreviewProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [blob, setBlob] = useState<Blob>()
  const [isImage, setIsImage] = useState(false)
  const [uploadOnUpdate, setUploadOnUpdate] = useState(false)

  const [fileDetails, setFileDetails] = useState<any>({})

  const detailsRef = useRef<ValidateHandle>(null)
  const imagePreviewRef = useRef<ImagePreviewHandle>(null)

  useEffect(() => {
    setIsImage(matchImageExtension(file.extension))
  }, [file])

  const upload = () => {
    const newFile = {
      ...file,
      ...fileDetails,
      originalFileName: getOriginalFileName(file),
      description: fileDetails.description || '',
      file: blob || (file && file.raw),
    }
    onProceed(newFile)
  }

  useEffect(() => {
    if (blob && uploadOnUpdate) {
      upload()
      setUploadOnUpdate(false)
    }
  }, [blob])

  const handleChangeFile = () => {
    imagePreviewRef.current?.open()
  }

  const cropAndUpload = () => {
    if (detailsRef.current?.validate()) {
      if (imagePreviewRef.current?.getIsCropping()) {
        setUploadOnUpdate(true)
      } else {
        upload()
      }
    }
  }

  return (
    <Grid container direction="column">
      <Grid container item pb={3} pt={2} px={4}>
        <Grid
          container
          item
          className={classNames({
            [classes.hidden]: !isImage,
          })}
        >
          <ImagePreview
            file={file}
            ref={imagePreviewRef}
            onFileChange={setBlob}
            onFileSelected={onFileSelected}
          />
        </Grid>
        <Grid container direction="column">
          <DetailsComponent
            isPreview
            file={file}
            isImage={isImage}
            ref={detailsRef}
            onUpdateItem={setFileDetails}
          />
          <Grid item>
            <RequiredFieldsNotice />
          </Grid>
          <Grid container item justifyContent="flex-end" mt={2}>
            <Grid item>
              <Fab
                className={classes.button}
                color="primary"
                disabled={isLoading}
                variant="extended"
                onClick={handleChangeFile}
              >
                {t('Common:CHANGE_FILE')}
              </Fab>
            </Grid>
            <Grid item>
              <ButtonWithLoader
                className={classes.button}
                disabled={isLoading}
                loading={isLoading}
                onClick={cropAndUpload}
              >
                {t('Common:ADD_ACTION')}
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AttachmentPreview
