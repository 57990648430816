import * as R from 'ramda'
import { AnyAction } from 'redux'

import { SlotType } from '~/constants/schedulerConstants'
import { BoardingSchedule, Schedule } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  FETCH_BOARDING_SCHEDULES,
  FETCH_BOARDING_SCHEDULES_FAILURE,
  FETCH_BOARDING_SCHEDULES_SUCCESS,
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_FAILURE,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_UPCOMING_EVENTS,
  FETCH_UPCOMING_EVENTS_FAILURE,
  FETCH_UPCOMING_EVENTS_SUCCESS,
} from '../actions/types/scheduler'
import {
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_BUSY_TIME_SUCCESS,
} from '../actions/types/timetable'
import { DELETE_REMINDER_SUCCESS } from '../duck/reminders'
import type { RootState } from '../index'

export type SchedulerState = {
  boardingColumns: string[]
  boardingSchedules: BoardingSchedule[]
  businessWorkingHours: {
    from?: string
    to?: string
  }
  error: string | null
  hasNoUpcomingAppointments: boolean
  hasNoUpcomingReminders: boolean
  isLoading: boolean
  lastNotSilentFetchTimestamp: number | null
  loadingDate: string | null
  schedules: Schedule[]
  upcomingAppointmentsList: string[]
  upcomingRemindersList: string[]
}

export const INITIAL_STATE: SchedulerState = {
  isLoading: false,
  error: null,
  businessWorkingHours: {},
  schedules: [],
  upcomingAppointmentsList: [],
  upcomingRemindersList: [],
  hasNoUpcomingAppointments: false,
  hasNoUpcomingReminders: false,
  boardingColumns: [],
  boardingSchedules: [],
  loadingDate: null,
  lastNotSilentFetchTimestamp: null,
}

const scheduler = (
  state: SchedulerState = INITIAL_STATE,
  action: AnyAction,
): SchedulerState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return INITIAL_STATE
    case FETCH_SCHEDULES_FAILURE:
    case FETCH_BOARDING_SCHEDULES_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        loadingDate: null,
      }
    case FETCH_SCHEDULES_SUCCESS:
      return {
        ...state,
        businessWorkingHours: R.equals(
          state.businessWorkingHours,
          action.businessWorkingHours,
        )
          ? state.businessWorkingHours
          : action.businessWorkingHours || {},
        isLoading: false,
        schedules: R.equals(state.schedules, action.schedules)
          ? state.schedules
          : action.schedules,
        loadingDate: null,
      }
    case FETCH_BOARDING_SCHEDULES_SUCCESS:
      return {
        ...state,
        businessWorkingHours: R.equals(
          state.businessWorkingHours,
          action.businessWorkingHours,
        )
          ? state.businessWorkingHours
          : action.businessWorkingHours || {},
        isLoading: false,
        boardingSchedules: R.equals(state.boardingSchedules, action.schedules)
          ? state.boardingSchedules
          : action.schedules,
        boardingColumns: action.columns,
      }
    case FETCH_SCHEDULES:
    case FETCH_BOARDING_SCHEDULES:
      return {
        ...state,
        isLoading: action.silent ? state.isLoading : true,
        loadingDate: action.date || null,
        ...(action.silent ? {} : { lastNotSilentFetchTimestamp: Date.now() }),
      }
    case FETCH_UPCOMING_EVENTS:
      return {
        ...state,
        isLoading: true,
        hasNoUpcomingAppointments: false,
        hasNoUpcomingReminders: false,
      }
    case FETCH_UPCOMING_EVENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        hasNoUpcomingAppointments: true,
        hasNoUpcomingReminders: true,
      }
    case FETCH_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        upcomingAppointmentsList: action.appointmentsList || [],
        upcomingRemindersList: action.remindersList || [],
        hasNoUpcomingAppointments: action.appointmentsList.length === 0,
        hasNoUpcomingReminders: action.remindersList.length === 0,
      }
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        upcomingAppointmentsList: R.without(
          [action.appointmentId],
          state.upcomingAppointmentsList,
        ),
        schedules: state.schedules.map((schedule) => ({
          ...schedule,
          slots: schedule.slots.filter(
            (slot) =>
              slot.type !== SlotType.APPOINTMENT ||
              slot.appointment !== action.appointmentId,
          ),
        })),
        boardingSchedules: state.boardingSchedules.map((boardingSchedule) => ({
          ...boardingSchedule,
          items: boardingSchedule.items.map((item) => ({
            ...item,
            slots: item.slots.filter(
              (slot) =>
                slot.type !== SlotType.APPOINTMENT ||
                slot.appointment !== action.appointmentId,
            ),
          })),
        })),
      }
    case DELETE_BUSY_TIME_SUCCESS:
      return {
        ...state,
        schedules: state.schedules.map((schedule) => ({
          ...schedule,
          slots: schedule.slots.filter(
            (slot) =>
              slot.type !== SlotType.BUSY ||
              slot.busyTime !== action.busyTimeId,
          ),
        })),
      }
    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        upcomingRemindersList: R.without(
          [action.reminderId],
          state.upcomingRemindersList,
        ),
      }
    default:
      return state
  }
}

export default scheduler
export const getScheduler = (state: RootState): SchedulerState =>
  state.scheduler
export const getSchedulerIsLoading = (state: RootState) =>
  getScheduler(state).isLoading
export const getSchedulerError = (state: RootState) => getScheduler(state).error
export const getSchedulerBusinessWorkingHours = (state: RootState) =>
  getScheduler(state).businessWorkingHours
export const getSchedules = (state: RootState) => getScheduler(state).schedules
export const getSchedulerUpcomingAppointmentsList = (state: RootState) =>
  getScheduler(state).upcomingAppointmentsList
export const getSchedulerUpcomingRemindersList = (state: RootState) =>
  getScheduler(state).upcomingRemindersList
export const getSchedulerHasNoUpcomingAppointments = (state: RootState) =>
  getScheduler(state).hasNoUpcomingAppointments
export const getSchedulerHasNoUpcomingReminders = (state: RootState) =>
  getScheduler(state).hasNoUpcomingReminders
export const getSchedulerBoardingSchedules = (state: RootState) =>
  getScheduler(state).boardingSchedules
export const getSchedulerBoardingColumns = (state: RootState) =>
  getScheduler(state).boardingColumns
export const getSchedulerLoadingDate = (state: RootState) =>
  getScheduler(state).loadingDate
export const getSchedulerLastFetchTimestamp = (state: RootState) =>
  getScheduler(state).lastNotSilentFetchTimestamp
