import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

const NoQuestionsScreen = () => {
  const { t } = useTranslation(['Admin', 'Common'])

  const [openNewQuestionDialog] = useDialog(DialogNames.QUESTION)

  return (
    <NoItemsScreen
      wideButton
      action={() => openNewQuestionDialog()}
      actionMessage={t('Common:ADD_QUESTION')}
      title={t('Common:QUESTIONS_CATALOG')}
    >
      <Text>{t('Admin:CATALOG.NO_QUESTIONS_SCREEN.DESCRIPTION')}</Text>
    </NoItemsScreen>
  )
}

export default NoQuestionsScreen
