import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Text, Utils } from '@pbt/pbt-ui-components'

import ScrollAnchor, {
  ScrollAnchorHandle,
} from '~/components/common/ScrollAnchor'
import {
  CONVERSATION_MESSAGES_FIRST_FETCH_COUNT,
  MessageWay,
} from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import { NotificationAreaNames } from '~/constants/notifications'
import { BaseRoute } from '~/constants/routes'
import { fetchConversation } from '~/store/actions/conversations'
import { markAllNotificationsAsReadForLinkedItem } from '~/store/actions/notifications'
import { getNotificationArea } from '~/store/reducers/constants'
import {
  getConversationById,
  getConversationMessagesLoading,
  getConversationsValidationError,
} from '~/store/reducers/conversations'
import { replaceHtmlPairedTagWithAnother } from '~/utils'
import {
  getRecipientNameWithCommunicationAddress,
  isPlainTextTransport,
} from '~/utils/communicationsUtils'
import useDialog from '~/utils/useDialog'
import useErrorAlert from '~/utils/useErrorAlert'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import ChatMessage from './ChatMessage'
import { MessageVariant } from './message/MessageCard'
import { useConversationChat } from './useConversationChat'

const useStyles = makeStyles(
  (theme) => ({
    loader: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'Chat' },
)

const replacePreTagsWithDiv = (content: string) =>
  replaceHtmlPairedTagWithAnother(content, 'pre', 'div')

export interface ChatProps {
  conversationId: string
  variant?: MessageVariant
}

const Chat = ({
  conversationId,
  variant = MessageVariant.PRIMARY,
}: ChatProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const NotificationAreas = useSelector(getNotificationArea)
  const communicationsNotificationAreaId = Utils.findConstantIdByName(
    NotificationAreaNames.COMMUNICATIONS,
    NotificationAreas,
  )
  const conversation = useSelector(getConversationById(conversationId))
  const areMessagesLoading = useSelector(getConversationMessagesLoading)

  const [isInitialized, setIsInitialized] = useState(false)

  const scrollAnchorRef = useRef<ScrollAnchorHandle>(null)

  const [openDeleteDialog] = useDialog(DialogNames.CONVERSATION_DELETE_DIALOG)

  const { loadMoreItems, messages } = useConversationChat({ conversationId })

  const isContextItem = useIsCurrentContextItem(conversation)

  const { from: businessName, recipient = {}, transport } = conversation || {}

  const to = recipient.name || t('Common:UNKNOWN')
  const recipientNameWithAddress = getRecipientNameWithCommunicationAddress(
    transport,
    recipient,
  )
  const recipientName =
    variant === MessageVariant.MINI ? to : recipientNameWithAddress

  const chatMessages = messages.map((message) => ({
    ...message,
    content: replacePreTagsWithDiv(message.body),
    sendingDate: message.timestamp,
    creator: Utils.getPersonString(message.creator),
    from: message.way === MessageWay.OUTBOUND ? businessName : recipientName,
    to: message.way === MessageWay.OUTBOUND ? recipientName : businessName,
  }))

  const totalCount =
    conversation?.totalCount ?? CONVERSATION_MESSAGES_FIRST_FETCH_COUNT
  const hasMore = (totalCount ?? 0) > messages.length

  const loader = (
    <Grid container className={classes.loader} justifyContent="center" key={0}>
      <Text variant="body2">{t('Common:LOADING')}...</Text>
    </Grid>
  )

  const messagesInitialized =
    !R.isNil(conversation?.totalCount) && !areMessagesLoading

  useEffect(() => {
    if (messagesInitialized && messages?.length === 0) {
      openDeleteDialog({ conversationId })
    }
  }, [messages?.length, messagesInitialized])

  useEffect(() => {
    if (!isInitialized && messages.length && scrollAnchorRef.current) {
      scrollAnchorRef.current.show()
      setIsInitialized(true)
    }
  }, [isInitialized, messages, scrollAnchorRef.current])

  useEffect(() => {
    if (
      conversation?.notifications &&
      conversation?.notifications?.length > 0
    ) {
      dispatch(
        markAllNotificationsAsReadForLinkedItem(
          conversationId,
          communicationsNotificationAreaId,
        ),
      )
    }
  }, [conversation?.notifications?.length])

  useErrorAlert({
    errorSelector: getConversationsValidationError,
    onOpenHandler: () => {
      navigate(BaseRoute.COMMUNICATIONS)
    },
    onCloseHandler: () => {
      dispatch(fetchConversation(conversationId))
    },
  })

  return (
    <InfiniteScroll
      isReverse
      data-testid="chat-container"
      hasMore={hasMore}
      initialLoad={false}
      loadMore={loadMoreItems}
      loader={loader}
      threshold={100}
      useWindow={false}
    >
      {chatMessages.map((message) => (
        <ChatMessage
          isPlainTextTransport={isPlainTextTransport(transport)}
          key={message.id}
          message={message}
          readOnly={!isContextItem}
          variant={variant}
        />
      ))}
      <ScrollAnchor ref={scrollAnchorRef} />
    </InfiniteScroll>
  )
}

export default Chat
