import { all, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  changeStateForAllBodySystems,
  changeStateForAllBodySystemsFailure,
  changeStateForAllBodySystemsSuccess,
  createProblemBodySystemLog,
  createProblemBodySystemLogFailure,
  createProblemBodySystemLogSuccess,
  createProblemLog,
  createProblemLogEnumValue,
  createProblemLogEnumValueFailure,
  createProblemLogEnumValueSuccess,
  createProblemLogFailure,
  createProblemLogSuccess,
  deleteProblemBodySystemLog,
  deleteProblemBodySystemLogFailure,
  deleteProblemBodySystemLogSuccess,
  deleteProblemLogEnumValue,
  deleteProblemLogEnumValueFailure,
  deleteProblemLogEnumValueSuccess,
  disableToastShowing,
  disableToastShowingFailure,
  disableToastShowingSuccess,
  fetchMoreProblemLogHistory,
  fetchMoreProblemLogHistoryFailure,
  fetchMoreProblemLogHistorySuccess,
  fetchProblemCatalog,
  fetchProblemCatalogFailure,
  fetchProblemCatalogSuccess,
  fetchProblemLogHistory,
  fetchProblemLogHistoryFailure,
  fetchProblemLogHistorySuccess,
  fetchProblemLogs,
  fetchProblemLogsFailure,
  fetchProblemLogsSuccess,
  searchMoreProblems,
  searchMoreProblemsFailure,
  searchMoreProblemsSuccess,
  searchProblems,
  searchProblemsFailure,
  searchProblemsSuccess,
  updateProblemBodySystemLog,
  updateProblemBodySystemLogFailure,
  updateProblemBodySystemLogSuccess,
  updateProblemBodySystemLogValidationFailure,
  updateProblemLog,
  updateProblemLogEnumValue,
  updateProblemLogEnumValueFailure,
  updateProblemLogEnumValueSuccess,
  updateProblemLogFailure,
  updateProblemLogSuccess,
  updateProblemLogValidationFailure,
} from '../actions/problems'
import {
  CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
  CREATE_PROBLEM_BODY_SYSTEM_LOG,
  CREATE_PROBLEM_LOG,
  CREATE_PROBLEM_LOG_ENUM_VALUE,
  DELETE_PROBLEM_BODY_SYSTEM_LOG,
  DELETE_PROBLEM_LOG_ENUM_VALUE,
  DISABLE_TOAST_SHOWING,
  FETCH_LOGS,
  FETCH_MORE_PROBLEM_LOG_HISTORY,
  FETCH_PROBLEM_CATALOG,
  FETCH_PROBLEM_LOG_HISTORY,
  SEARCH_MORE_PROBLEMS,
  SEARCH_PROBLEMS,
  UPDATE_PROBLEM_BODY_SYSTEM_LOG,
  UPDATE_PROBLEM_LOG,
  UPDATE_PROBLEM_LOG_ENUM_VALUE,
} from '../actions/types/problems'
import { getCurrentUserLanguage } from '../duck/userSettings'
import requestAPI from './utils/requestAPI'

export function* fetchProblemCatalogSaga({
  patientId,
}: ReturnType<typeof fetchProblemCatalog>) {
  try {
    const { entities, result } = yield* requestAPI(
      API.fetchProblemCatalog,
      patientId,
    )
    yield put(
      fetchProblemCatalogSuccess({
        ...entities,
        bodySystemsList: result.problems,
      }),
    )
  } catch (error) {
    yield put(fetchProblemCatalogFailure(error as ApiError))
  }
}

export function* fetchLogsSaga({
  soapId,
}: ReturnType<typeof fetchProblemLogs>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
      result: { showAddedProblemLogsToast },
    } = yield* requestAPI(API.fetchLogs, soapId)
    yield put(
      fetchProblemLogsSuccess(
        bodySystemLogsMap,
        problemLogsMap,
        showAddedProblemLogsToast,
      ),
    )
  } catch (error) {
    yield put(fetchProblemLogsFailure(error as ApiError))
  }
}

export function* createProblemBodySystemLogSaga({
  soapId,
  problemBodySystemLog,
}: ReturnType<typeof createProblemBodySystemLog>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
    } = yield* requestAPI(
      API.createProblemBodySystemLog,
      soapId,
      problemBodySystemLog,
    )
    yield put(
      createProblemBodySystemLogSuccess(bodySystemLogsMap, problemLogsMap),
    )
  } catch (error) {
    yield put(createProblemBodySystemLogFailure(error as ApiError))
  }
}

export function* updateProblemBodySystemLogSaga({
  soapId,
  problemBodySystemLogId,
  problemBodySystemLog,
}: ReturnType<typeof updateProblemBodySystemLog>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
    } = yield* requestAPI(
      API.updateProblemBodySystemLog,
      soapId,
      problemBodySystemLogId,
      problemBodySystemLog,
    )

    yield put(
      updateProblemBodySystemLogSuccess(bodySystemLogsMap, problemLogsMap),
    )
  } catch (e) {
    const error = e as ApiError
    if (error?.status === 409) {
      yield put(updateProblemBodySystemLogValidationFailure(error as ApiError))
    } else {
      yield put(updateProblemBodySystemLogFailure(error as ApiError))
    }
  }
}

export function* deleteProblemBodySystemLogSaga({
  soapId,
  problemBodySystemLogId,
}: ReturnType<typeof deleteProblemBodySystemLog>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
    } = yield* requestAPI(
      API.deleteProblemBodySystemLog,
      soapId,
      problemBodySystemLogId,
    )

    yield put(
      deleteProblemBodySystemLogSuccess(bodySystemLogsMap, problemLogsMap),
    )
  } catch (error) {
    yield put(deleteProblemBodySystemLogFailure(error as ApiError))
  }
}

export function* createProblemLogSaga({
  soapId,
  bodySystemLogId,
  problemLog,
}: ReturnType<typeof createProblemLog>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
    } = yield* requestAPI(
      API.createProblemLog,
      soapId,
      bodySystemLogId,
      problemLog,
    )
    yield put(createProblemLogSuccess(bodySystemLogsMap, problemLogsMap))
  } catch (error) {
    yield put(createProblemLogFailure(error as ApiError))
  }
}

export function* updateProblemLogSaga({
  soapId,
  logId,
  problemLogRequest,
}: ReturnType<typeof updateProblemLog>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
    } = yield* requestAPI(
      API.updateProblemLog,
      soapId,
      logId,
      problemLogRequest,
    )
    yield put(updateProblemLogSuccess(bodySystemLogsMap, problemLogsMap))
  } catch (e) {
    const error = e as ApiError
    if (error?.status === 409) {
      yield put(updateProblemLogValidationFailure(error as ApiError))
    } else {
      yield put(updateProblemLogFailure(error as ApiError))
    }
  }
}

export function* searchProblemsSaga({
  query,
  patientId,
  from,
  to,
}: ReturnType<typeof searchProblems>) {
  try {
    const locale: string = yield select(getCurrentUserLanguage)
    const {
      entities: { foundedProblems },
      result: { data, totalCount },
    } = yield* requestAPI(
      API.searchProblems,
      query,
      locale,
      patientId,
      from,
      to,
    )
    yield put(searchProblemsSuccess(data, foundedProblems, totalCount))
  } catch (error) {
    yield put(searchProblemsFailure(error as ApiError))
  }
}

export function* searchMoreProblemsSaga({
  query,
  patientId,
  from,
  to,
}: ReturnType<typeof searchMoreProblems>) {
  try {
    const locale: string = yield select(getCurrentUserLanguage)
    const {
      entities: { foundedProblems },
      result: { data, totalCount },
    } = yield* requestAPI(
      API.searchProblems,
      query,
      locale,
      patientId,
      from,
      to,
    )
    yield put(
      searchMoreProblemsSuccess(data, foundedProblems, totalCount, from),
    )
  } catch (error) {
    yield put(searchMoreProblemsFailure(error as ApiError))
  }
}

export function* fetchProblemLogHistorySaga({
  problemId,
  patientId,
  soapId,
  from,
  to,
}: ReturnType<typeof fetchProblemLogHistory>) {
  try {
    const {
      entities: { problemLogHistory },
      result: { data, totalCount },
    } = yield* requestAPI(
      API.fetchProblemLogHistory,
      problemId,
      patientId,
      soapId,
      from,
      to,
    )
    yield put(
      fetchProblemLogHistorySuccess(data, problemLogHistory, totalCount),
    )
  } catch (error) {
    yield put(fetchProblemLogHistoryFailure(error as ApiError))
  }
}

export function* fetchMoreProblemLogHistorySaga({
  problemId,
  patientId,
  soapId,
  from,
  to,
}: ReturnType<typeof fetchMoreProblemLogHistory>) {
  try {
    const {
      entities: { problemLogHistory },
      result: { data, totalCount },
    } = yield* requestAPI(
      API.fetchProblemLogHistory,
      problemId,
      patientId,
      soapId,
      from,
      to,
    )
    yield put(
      fetchMoreProblemLogHistorySuccess(
        data,
        problemLogHistory,
        totalCount,
        from,
      ),
    )
  } catch (error) {
    yield put(fetchMoreProblemLogHistoryFailure(error as ApiError))
  }
}

export function* disableToastShowingSaga({
  soapId,
}: ReturnType<typeof disableToastShowing>) {
  try {
    yield* requestAPI(API.disableToastShowing, soapId)
    yield put(disableToastShowingSuccess())
  } catch (error) {
    yield put(disableToastShowingFailure(error as ApiError))
  }
}

export function* changeStateForAllBodySystemsSaga({
  soapId,
  bodySystemLogBatchRequest,
}: ReturnType<typeof changeStateForAllBodySystems>) {
  try {
    const {
      entities: { bodySystemLogsMap, problemLogsMap },
    } = yield* requestAPI(
      API.changeStateForAllBodySystems,
      soapId,
      bodySystemLogBatchRequest,
    )
    yield put(
      changeStateForAllBodySystemsSuccess(bodySystemLogsMap, problemLogsMap),
    )
  } catch (error) {
    yield put(changeStateForAllBodySystemsFailure(error as ApiError))
  }
}

export function* addProblemLogEnumValueSaga({
  problemLogId,
  problemEnumLogRequest,
}: ReturnType<typeof createProblemLogEnumValue>) {
  try {
    const { problemLog } = yield* requestAPI(
      API.createProblemLogEnumValue,
      problemLogId,
      problemEnumLogRequest,
    )
    yield put(createProblemLogEnumValueSuccess(problemLog))
  } catch (error) {
    yield put(createProblemLogEnumValueFailure(error as ApiError))
  }
}

export function* updateProblemLogEnumValueSaga({
  problemLogId,
  problemEnumLogId,
  problemEnumLogRequest,
}: ReturnType<typeof updateProblemLogEnumValue>) {
  try {
    const { problemLog } = yield* requestAPI(
      API.updateProblemLogEnumValue,
      problemLogId,
      problemEnumLogId,
      problemEnumLogRequest,
    )

    yield put(updateProblemLogEnumValueSuccess(problemLog))
  } catch (error) {
    yield put(updateProblemLogEnumValueFailure(error as ApiError))
  }
}

export function* deleteProblemLogEnumValueSaga({
  problemLogId,
  problemEnumLogId,
  crc,
}: ReturnType<typeof deleteProblemLogEnumValue>) {
  try {
    const { problemLog } = yield* requestAPI(
      API.deleteProblemLogEnumValue,
      problemLogId,
      problemEnumLogId,
      crc,
    )

    yield put(deleteProblemLogEnumValueSuccess(problemLog))
  } catch (error) {
    yield put(deleteProblemLogEnumValueFailure(error as ApiError))
  }
}

function* watchFetchProblemCatalog() {
  yield takeLeading(FETCH_PROBLEM_CATALOG, fetchProblemCatalogSaga)
}

function* watchFetchLogsSaga() {
  yield takeLeading(FETCH_LOGS, fetchLogsSaga)
}

function* watchAddNewProblemBodySystemLog() {
  yield takeLeading(
    CREATE_PROBLEM_BODY_SYSTEM_LOG,
    createProblemBodySystemLogSaga,
  )
}

function* watchUpdateProblemBodySystemLog() {
  yield takeLeading(
    UPDATE_PROBLEM_BODY_SYSTEM_LOG,
    updateProblemBodySystemLogSaga,
  )
}

function* watchDeleteProblemBodySystemLog() {
  yield takeLeading(
    DELETE_PROBLEM_BODY_SYSTEM_LOG,
    deleteProblemBodySystemLogSaga,
  )
}

function* watchAddNewProblemLog() {
  yield takeLeading(CREATE_PROBLEM_LOG, createProblemLogSaga)
}

function* watchUpdateProblemLog() {
  yield takeLeading(UPDATE_PROBLEM_LOG, updateProblemLogSaga)
}

function* watchSearchProblems() {
  yield takeLeading(SEARCH_PROBLEMS, searchProblemsSaga)
}

function* watchSearchMoreProblems() {
  yield takeLeading(SEARCH_MORE_PROBLEMS, searchMoreProblemsSaga)
}

function* watchFetchProblemLogHistory() {
  yield takeLatest(FETCH_PROBLEM_LOG_HISTORY, fetchProblemLogHistorySaga)
}

function* watchFetchMoreProblemLogHistory() {
  yield takeLatest(
    FETCH_MORE_PROBLEM_LOG_HISTORY,
    fetchMoreProblemLogHistorySaga,
  )
}

function* watchDisableToastShowing() {
  yield takeLeading(DISABLE_TOAST_SHOWING, disableToastShowingSaga)
}

function* watchChangeStateForAllBodySystems() {
  yield takeLeading(
    CHANGE_STATE_FOR_ALL_BODY_SYSTEMS,
    changeStateForAllBodySystemsSaga,
  )
}

function* watchAddProblemLogEnumValue() {
  yield takeLeading(CREATE_PROBLEM_LOG_ENUM_VALUE, addProblemLogEnumValueSaga)
}

function* watchUpdateProblemLogEnumValue() {
  yield takeLeading(
    UPDATE_PROBLEM_LOG_ENUM_VALUE,
    updateProblemLogEnumValueSaga,
  )
}

function* watchDeleteProblemLogEnumValue() {
  yield takeLeading(
    DELETE_PROBLEM_LOG_ENUM_VALUE,
    deleteProblemLogEnumValueSaga,
  )
}

export function* problemSaga() {
  yield all([
    watchFetchProblemCatalog(),
    watchFetchLogsSaga(),
    watchAddNewProblemBodySystemLog(),
    watchUpdateProblemBodySystemLog(),
    watchDeleteProblemBodySystemLog(),
    watchAddNewProblemLog(),
    watchUpdateProblemLog(),
    watchSearchProblems(),
    watchSearchMoreProblems(),
    watchFetchProblemLogHistory(),
    watchFetchMoreProblemLogHistory(),
    watchDisableToastShowing(),
    watchChangeStateForAllBodySystems(),
    watchAddProblemLogEnumValue(),
    watchDeleteProblemLogEnumValue(),
    watchUpdateProblemLogEnumValue(),
  ])
}
