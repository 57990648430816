import React from 'react'

import { DocumentCreation } from './DocumentCreation'
import { DocumentEdit } from './DocumentEdit'

export interface DocumentDetailsContainerProps {
  itemId: string
  onClose: () => void
}

export const DocumentDetailsContainer = ({
  itemId,
  onClose,
}: DocumentDetailsContainerProps) => {
  const isDocumentToCreate = itemId === 'new'

  return isDocumentToCreate ? (
    <DocumentCreation onClose={onClose} />
  ) : (
    <DocumentEdit itemId={itemId} onClose={onClose} />
  )
}
