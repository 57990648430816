import React from 'react'
import { Input, MenuItemProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Field, Nil, PuiSelect } from '@pbt/pbt-ui-components'

import { useGetContactMethodWithDisabledFlag } from '~/store/hooks/clients'

const useStyles = makeStyles(
  () => ({
    selectItem: {
      whiteSpace: 'pre-line',
    },
  }),
  { name: 'ContactMethodSelect' },
)

export interface ContactMethodSelectProps {
  clientId: string | Nil
  communicationSmsOptInValue?: boolean
  field: Field
  inputId: string
}

export const ContactMethodSelect = ({
  clientId,
  communicationSmsOptInValue,
  field,
  inputId,
}: ContactMethodSelectProps) => {
  const classes = useStyles()

  const { ContactMethodWithDisabledFlag } = useGetContactMethodWithDisabledFlag(
    clientId,
    communicationSmsOptInValue,
  )

  return (
    <PuiSelect
      field={field}
      input={<Input id={inputId} />}
      items={ContactMethodWithDisabledFlag}
      margin="none"
      selectItemProps={
        { classes: { root: classes.selectItem } } as MenuItemProps
      }
    />
  )
}
