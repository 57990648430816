import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Nil,
  PuiDialog,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import { BaseRoute } from '~/constants/routes'
import { clearFilesToSend } from '~/store/actions/conversationMessages'
import { clearConversationEmailPreview } from '~/store/actions/conversations'
import { getContact } from '~/store/duck/contacts'
import { getConversationMessagesIsLoading } from '~/store/reducers/conversationMessages'
import { getConversationsIsCreating } from '~/store/reducers/conversations'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { ContactSlot } from '~/types'
import useDialog from '~/utils/useDialog'

import { CreatedConversationFlow } from '../common/CreatedConversationsInfoDialog'
import NewConversation from './NewConversation'
import NewMessage from './NewMessage'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 655,
      maxWidth: 655,
      overflowY: 'visible',
    },
  }),
  { name: 'NewConversationWithStepsDialog' },
)

enum Steps {
  NEW_CONVERSATION = 'NEW_CONVERSATION',
  NEW_MESSAGE = 'NEW_MESSAGE',
}

const StepComponents = {
  [Steps.NEW_CONVERSATION]: NewConversation,
  [Steps.NEW_MESSAGE]: NewMessage,
}

interface NewConversationWithStepsDialogProps extends BasePuiDialogProps {
  appointmentId?: string
  clientId: string | Nil
  handleConversationCreated?: (flow: CreatedConversationFlow) => void
  initialSubject?: string
  patientId?: string | Nil
}

const NewConversationWithStepsDialog = ({
  open,
  onClose,
  clientId = null,
  patientId = null,
  appointmentId,
  initialSubject,
  handleConversationCreated: onConversationCreated,
}: NewConversationWithStepsDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const isMessageLoading = useSelector(getConversationMessagesIsLoading)
  const isLoading = useSelector(getConversationsIsCreating) || isMessageLoading

  const [step, setStep] = useState(Steps.NEW_CONVERSATION)
  const [contactSlot, setContactSlot] = useState<ContactSlot>({
    clientId,
    patientId,
    contactId: null,
  })
  const [transport, setTransport] = useState<ConversationTransport>()

  const [openCreatedConversationsInfoDialog] = useDialog(
    DialogNames.CREATED_CONVERSATIONS_INFO_DIALOG,
  )

  const {
    patientId: selectedPatientId,
    contactId: selectedContactId,
    clientId: selectedClientId,
  } = contactSlot
  const patient = useSelector(getPatient(selectedPatientId))
  const contact = useSelector(getContact(selectedContactId))
  const users = useSelector(getUser(selectedClientId))

  const userName = Utils.getPersonString(users)
  const patientName = patient?.name
  const contactName = contact?.name

  const forPatientString = t(
    'Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.FOR_PATIENT',
    { patientName },
  )
  const selectedRecipientName =
    contactName || `${userName}${patientName ? `, ${forPatientString}` : ''}`

  const transportNames = {
    [ConversationTransport.Email]: t('Common:EMAIL'),
    [ConversationTransport.Sms]: t('Common:TEXT_MESSAGE'),
    [ConversationTransport.Boop]: `${t('Common:BOOP_SYSTEM_NAME')} ${t(
      'Common:CONVERSATION',
    )}`,
    [ConversationTransport.LogPhoneCall]: t('Common:LOG_PHONE_CALL'),
  }

  const title =
    step === Steps.NEW_CONVERSATION
      ? t('Common:NEW_CLIENT_CONVERSATION')
      : t('Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.CUSTOM_TITLE', {
          transportName: transport && transportNames[transport],
          selectedRecipientName,
        })

  const StepComponent = StepComponents[step]

  const handleAddConversation = () => setStep(Steps.NEW_MESSAGE)

  const handleClose = () => {
    dispatch(clearConversationEmailPreview())
    if (onClose) {
      onClose()
    }
  }

  const onConversationCreatedDefault = (flow: CreatedConversationFlow) => {
    openCreatedConversationsInfoDialog({
      flow,
      handleViewConversation: (id: string) =>
        navigate(`${BaseRoute.COMMUNICATIONS}/${id}`),
    })
  }

  const handleConversationCreated = (isDraft: boolean) => {
    const flow =
      transport === ConversationTransport.LogPhoneCall
        ? CreatedConversationFlow.LOG_PHONE_CALL
        : isDraft
          ? CreatedConversationFlow.DRAFT_MESSAGE_SENT
          : CreatedConversationFlow.PLAIN_MESSAGE_SENT

    handleClose()
    if (onConversationCreated) {
      onConversationCreated(flow)
    } else {
      onConversationCreatedDefault(flow)
    }
  }

  const handleBack = () => setStep(Steps.NEW_CONVERSATION)

  const stepsProps = {
    isLoading,
    transport,
    contactSlot,
    setTransport,
    handleAddConversation,
    handleBack,
    handleConversationCreated,
    setContactSlot,
    initialSubject,
    appointmentId,
  }

  useEffect(() => {
    dispatch(clearFilesToSend())
  }, [clientId, patientId])

  return (
    <PuiDialog
      aria-labelledby="conversation-with-steps-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={title}
      onClose={handleClose}
    >
      <StepComponent {...stepsProps} />
    </PuiDialog>
  )
}

export default NewConversationWithStepsDialog
