import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'

import type { DialogComponentMapType } from '~/components/dialogs-manager/dialog-components'
import DialogNames from '~/constants/DialogNames'

import type { RootState } from '../index'

export const OPEN_DIALOG = 'dialogs/OPEN_DIALOG'
export const CLOSE_DIALOG = 'dialogs/CLOSE_DIALOG'
export const MODIFY_PROPS = 'dialogs/MODIFY_PROPS'
export const MODIFY_BASE_PROPS = 'dialogs/MODIFY_BASE_PROPS'

type OpenDialogParams<K extends DialogNames> = {
  id: string
  name: K
  props?: Partial<Omit<Parameters<DialogComponentMapType[K]>[0], 'open'>>
  unique?: boolean
}

export const openDialog = <K extends DialogNames>(
  params: OpenDialogParams<K>,
) => ({ type: OPEN_DIALOG, ...params })
export const closeDialog = (id: string) => ({ type: CLOSE_DIALOG, id })
export const modifyProps = (id: string, props: any) => ({
  type: MODIFY_PROPS,
  id,
  props,
})
export const modifyBaseProps = (id: string, props: any) => ({
  type: MODIFY_BASE_PROPS,
  id,
  props,
})

type DialogStateListEntry = {
  id: string
  name: DialogNames
  props: any
}

export type DialogsState = {
  baseProps: Record<string, any>
  list: DialogStateListEntry[]
}

export const INITIAL_STATE: DialogsState = {
  baseProps: {},
  list: [],
}

export const dialogsReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case OPEN_DIALOG:
      if (
        action.unique &&
        state.list.some(({ name }) => name === action.name)
      ) {
        return state
      }

      return {
        ...state,
        list: [
          ...state.list,
          {
            id: action.id,
            name: action.name,
            props: action.props,
          },
        ],
      }
    case CLOSE_DIALOG:
      return {
        ...state,
        list: R.reject(R.propEq('id', action.id), state.list),
      }
    case MODIFY_PROPS:
      return {
        ...state,
        list: state.list.map((_) =>
          _.id === action.id
            ? {
                ..._,
                props: action.props,
              }
            : _,
        ),
      }
    case MODIFY_BASE_PROPS:
      return {
        ...state,
        baseProps: {
          ...state.baseProps,
          [action.id]: action.props,
        },
      }
    default:
      return state
  }
}

export const getDialogs = (state: RootState): DialogsState =>
  state.dialogs || INITIAL_STATE
export const getDialogsList = (state: RootState) => getDialogs(state).list
export const getDialogsListByName = (name: string) =>
  createSelector(getDialogsList, (list) => list.filter(R.propEq('name', name)))
export const getDialogIds = createSelector(getDialogsList, (list) =>
  R.pluck('id', list),
)
export const getDialog = (id: string) =>
  createSelector(getDialogsList, (list) => R.find(R.propEq('id', id), list))
export const getDialogName = (id: string) =>
  createSelector(getDialog(id), (entry) => entry?.name)
export const getDialogProps = (id: string) =>
  createSelector(getDialog(id), (entry) => entry?.props)
export const getBaseProps = (id: string) => (state: RootState) =>
  getDialogs(state).baseProps[id] || {}
export const isDialogOpen = (id: string) =>
  createSelector(getDialog(id), (entry) => Boolean(entry))
export const getHasOpenDialogs = (state: RootState) =>
  !R.isEmpty(getDialogsList(state))
export const getHasOpenDialogsByName = (name: DialogNames) =>
  createSelector(getDialogsListByName(name), (list) => !R.isEmpty(list))
