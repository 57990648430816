import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BackButton,
  ButtonWithLoader,
  FileTemplate,
  Utils,
} from '@pbt/pbt-ui-components'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import UploadButton from '~/components/common/buttons/UploadButton'
import SingleFileSelector from '~/components/common/inputs/SingleFileSelector'
import { getDocumentTypes } from '~/store/reducers/constants'
import { getDocumentsIsSending } from '~/store/reducers/documents'
import { Document } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      minWidth: 155,
      height: 40,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(3),
    },
  }),
  { name: 'DocumentEditorActions' },
)

export interface DocumentEditorActionsProps {
  document: Document
  onBack: () => void
  onFileSelected: (fileTemplate: FileTemplate) => void
  onPreview: () => void
  onProceed: () => void
}

const DocumentEditorActions = ({
  document,
  onBack,
  onFileSelected,
  onProceed,
  onPreview,
}: DocumentEditorActionsProps) => {
  const classes = useStyles()
  const isSending = useSelector(getDocumentsIsSending)
  const DocumentTypes = useSelector(getDocumentTypes)
  const { t } = useTranslation(['Admin', 'Common'])

  const NotesTemplateType = Utils.findConstantIdByName(
    'Notes template',
    DocumentTypes,
  )
  const CommunicationTemplateType = Utils.findConstantIdByName(
    'Communication template',
    DocumentTypes,
  )

  const proceed = () => {
    onProceed()
  }

  const preview = () => {
    onPreview()
  }

  const isNotesTemplate = document.type === NotesTemplateType
  const isCommunicationTemplate = document.type === CommunicationTemplateType

  return (
    <Grid container item alignItems="center">
      <Grid item>
        <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
      </Grid>
      <ButtonWithLoader
        className={classes.button}
        disabled={isSending}
        loading={isSending}
        onClick={proceed}
      >
        {isCommunicationTemplate
          ? t('Admin:CATALOG.DOCUMENT_EDITOR_ACTIONS.CREATE_TEMPLATE')
          : t('Admin:CATALOG.DOCUMENT_EDITOR_ACTIONS.CREATE_DOCUMENT')}
      </ButtonWithLoader>
      <Grid item mr={5}>
        <PreviewButton onClick={preview} />
      </Grid>
      {!isCommunicationTemplate && !isNotesTemplate && (
        <Grid item>
          <SingleFileSelector onSelected={onFileSelected}>
            <UploadButton
              label={t(
                'Admin:CATALOG.DOCUMENT_EDITOR_ACTIONS.UPLOAD_EXISTING_DOCUMENT',
              )}
            />
          </SingleFileSelector>
        </Grid>
      )}
    </Grid>
  )
}

export default DocumentEditorActions
