import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, GridProps, Paper, PaperProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import diggingDog from '~/components/common/images/diggingDog.svg'

const useStyles = makeStyles(() => ({
  diggingDogImage: {
    maxWidth: 138,
    width: '100%',
  },
}))

const xSpacing: GridProps['px'] = { xs: 1, md: 2 }

interface RxRequestCatalogItemNotLinkedProps extends PaperProps {}

const RxRequestCatalogItemNotLinked = ({
  ...props
}: RxRequestCatalogItemNotLinkedProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Paper {...props}>
      <Grid
        container
        flexWrap={{ md: 'nowrap' }}
        gap={xSpacing}
        justifyContent={{ xs: 'center' }}
        px={xSpacing}
        py={1}
      >
        <Grid flexGrow={1} py={{ xs: 0, sm: 0.5, md: 2 }}>
          <Text strong component="h2" pb={1} variant="subheading3">
            {t('Common:PRESCRIPTION_MATCHING_TITLE')}
          </Text>
          <Text variant="body">
            {t('Common:PRESCRIPTION_MATCHING_MESSAGE')}
          </Text>
        </Grid>
        <img
          alt={t('Common:DIGGING_DOG')}
          className={classes.diggingDogImage}
          src={diggingDog}
        />
      </Grid>
    </Paper>
  )
}

export default RxRequestCatalogItemNotLinked
