import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

export interface ItemsErrorsFormatterProps {
  error: any
}

const ItemsErrorsFormatter = ({ error }: ItemsErrorsFormatterProps) => {
  const { t } = useTranslation('Common')

  if (!error?.errors) {
    return error?.message || ''
  }

  return (
    <ul>
      {error?.errors.map((subError: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <Text>{t('Common:FOLLOWING_ITEMS')}:</Text>
          <ul>
            {subError.items.map((item: any) => (
              <li key={item.id}>
                <Text>{item.name}</Text>
              </li>
            ))}
          </ul>
          <Text>{subError.error.friendlyErrorDescription}</Text>
        </li>
      ))}
    </ul>
  )
}

export default ItemsErrorsFormatter
