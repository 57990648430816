import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TextInteractive } from '@pbt/pbt-ui-components'

import { getPreviewVariation } from '~/store/duck/bulkPrices'

import BulkPricesReviewRowSubItem from './BulkPricesReviewRowSubItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderBottom: theme.constants.tableBorder,
    },
    mainColumn: {
      borderRight: theme.constants.tableBorder,
    },
    oddItem: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
  }),
  { name: 'BulkPricesReviewRow' },
)

interface BulkPricesReviewRowProps {
  itemId: string
}

const BulkPricesReviewRow = ({ itemId }: BulkPricesReviewRowProps) => {
  const classes = useStyles()
  const item = useSelector(getPreviewVariation(itemId))
  const isLoading = !item

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid
        container
        item
        className={classes.mainColumn}
        px={2}
        py={1.5}
        wrap="nowrap"
        xs={4}
      >
        <TextInteractive isLoading={isLoading} loaderWidth={120} variant="body">
          {item?.name}
        </TextInteractive>
      </Grid>
      <Grid container item xs direction="column">
        {item?.prices.map((priceId: string, priceIndex: number) => (
          <BulkPricesReviewRowSubItem
            className={classNames({
              [classes.oddItem]: priceIndex % 2,
            })}
            key={priceId}
            priceId={priceId}
          />
        ))}
        {isLoading && <BulkPricesReviewRowSubItem priceId="" />}
      </Grid>
    </Grid>
  )
}

export default BulkPricesReviewRow
