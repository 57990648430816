import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { HtmlNotesPreview, Text, Utils } from '@pbt/pbt-ui-components'

import { useIsGlobalInventoryItem } from '~/store/hooks/orders'
import {
  getDrugAdministrationFrequencies,
  getDrugDeliveryMethod,
} from '~/store/reducers/constants'
import { Order } from '~/types'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'InventoryItemContent' },
)

export interface InventoryItemContentProps {
  className?: string
  order: Order
}

const InventoryItemContent = ({
  order,
  className,
}: InventoryItemContentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)

  const isGlobalItem = useIsGlobalInventoryItem(order.inventory)

  const frequency = Utils.getConstantName(
    order.administrationFrequencyId,
    DrugAdministrationFrequencies,
  )
  const frequencyString =
    frequency === 'Other' ? order.administrationFrequencyNotes || '' : frequency

  const route = Utils.getConstantName(
    order.administrationRouteId,
    DrugDeliveryMethod,
  )

  return (
    <Grid item className={classNames(className, classes.root)} mb={1} xs={12}>
      {order.notes && (
        <HtmlNotesPreview
          fontSize="1.4rem"
          notes={`${t('Common:NOTES')}: ${order.notes}`}
        />
      )}
      {isGlobalItem && route && frequency && (
        <Text variant="body2">{`${t(
          'Common:ADMINISTRATION',
        )}: ${route} ${frequencyString}`}</Text>
      )}
    </Grid>
  )
}

export default InventoryItemContent
