import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.colors.tabSelected,
    },
    button: {
      color: theme.colors.profileText,
      textTransform: 'none',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&:not(:first-of-type)': {
        marginLeft: theme.spacing(1),
      },
    },
    closeIcon: {
      color: theme.colors.profileText,
      padding: theme.spacing(1),
    },
    text: {
      color: theme.colors.badgeColor,
    },
  }),
  { name: 'ListActions' },
)

export interface ListActionsProps {
  children: React.ReactNode
  onClear: () => void
  text?: string
}

const ListActions = ({ children, onClear, text }: ListActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const childrenArray = React.Children.toArray(children) as ReactElement[]

  return (
    <Grid container item alignItems="center" className={classes.root}>
      {childrenArray.map((child) =>
        React.cloneElement(child, { className: classes.button }),
      )}
      <Grid item xs>
        {text && (
          <Text className={classes.text} ml={2} variant="h4">
            {text}
          </Text>
        )}
      </Grid>
      <IconButton
        aria-label={t('Common:CLOSE_ACTION')}
        className={classes.closeIcon}
        size="large"
        onClick={onClear}
      >
        <CloseIcon />
      </IconButton>
    </Grid>
  )
}

export default ListActions
