import React, { forwardRef } from 'react'
import { Link, LinkProps } from '@mui/material'
import { Defaults } from '@pbt/pbt-ui-components'

export interface LeftMenuSupportButtonProps extends LinkProps {}

const LeftMenuSupportButton = forwardRef<
  HTMLAnchorElement,
  LeftMenuSupportButtonProps
>(function LeftMenuSupportButton(props, ref) {
  return (
    <Link
      href={Defaults.SUPPORT_URL}
      ref={ref}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    />
  )
})

export default LeftMenuSupportButton
