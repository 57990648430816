import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Fab, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { BackButton, Constant, NumberUtils, Text } from '@pbt/pbt-ui-components'

import { RHAPSODY_PAY_RATE } from '~/constants/financeConstants'
import { RhapsodyPayConfig } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0, 3, 1, 3),
    },
    text: {
      lineHeight: '2.2rem',
    },
    percentageChoiceWrapper: {
      boxShadow: theme.constants.blockShadow,
      margin: theme.spacing(2, 0, 4, 0),
      borderRadius: 5,
    },
    button: {
      width: 240,
      height: 40,
      margin: theme.spacing(2, 0),
    },
    splitBlock: {
      padding: theme.spacing(2, 3),
    },
    radioHint: {
      position: 'relative',
      lineHeight: '1.7rem',
      top: theme.spacing(-1),
      left: theme.spacing(4),
    },
    tableRow: {
      padding: theme.spacing(1),
      borderBottom: theme.constants.filterBorder,
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
    withoutBorder: {
      border: 'none',
    },
    percentageTable: {
      background: 'linear-gradient(181.33deg, #FFFCF9 0%, #F6F0EB 100%)',
    },
    lastTableRow: {
      padding: theme.spacing(1),
      borderTop: '1px solid #454141',
    },
  }),
  { name: 'RhapsodyPayCardProcessing' },
)

interface RhapsodyPayCardProcessingProps {
  onBack: () => void
  onPercentageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onProceed: () => void
  payPassConfigList: Constant[]
  posPayConfig: RhapsodyPayConfig
}

const RhapsodyPayCardProcessing = ({
  onBack,
  onProceed,
  onPercentageChange,
  posPayConfig,
  payPassConfigList,
}: RhapsodyPayCardProcessingProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Businesses'])

  const currentPercentage =
    payPassConfigList.find(({ id }) => id === posPayConfig.passOption) ||
    payPassConfigList[0]

  const optionLabelByServiceFee = {
    0.04: `(${t('Businesses:RHAPSODY_PAY.RECOMMENDED').toLowerCase()})`,
    0: `(${t(
      'Businesses:RHAPSODY_PAY.I_OPT_OUT_OF_RECOUPING_MY_PROCESSING_FEE',
    )})`,
  }

  const invoiceTotal = 100
  const serviceFeeAmount = Number(currentPercentage.name) * invoiceTotal
  const serviceFeeToPractice = serviceFeeAmount * 0.7
  const CCProcessingFee =
    ((invoiceTotal + serviceFeeAmount) * RHAPSODY_PAY_RATE) / 100
  const totalToPractice = invoiceTotal + serviceFeeToPractice - CCProcessingFee

  return (
    <Grid container className={classes.root} direction="column">
      <Grid container item direction="column">
        <Text align="left">
          <Trans
            components={{
              boldText: <Text inline strong component="span" variant="body2" />,
            }}
            i18nKey="Businesses:RHAPSODY_PAY.ANY_BUSINESS_PAYS_PROCESSING_FEE"
          />
        </Text>
      </Grid>
      <Grid container item className={classes.percentageChoiceWrapper}>
        <Grid
          container
          item
          className={classes.splitBlock}
          direction="column"
          md={6}
        >
          <Text strong my={2} variant="subheading3">
            {t('Businesses:RHAPSODY_PAY.ADJUST_YOUR_PERCENTAGE_CHOICE')}
          </Text>
          <RadioGroup
            aria-label="service-fee"
            name="service-fee"
            value={posPayConfig.passOption}
            onChange={onPercentageChange}
          >
            {payPassConfigList.map(({ id, name: serviceFeeIndex }) => {
              const optionLabel =
                optionLabelByServiceFee[
                  Number(
                    serviceFeeIndex,
                  ) as keyof typeof optionLabelByServiceFee
                ]
              return (
                <Grid container direction="column" key={id}>
                  <FormControlLabel
                    classes={{
                      label: classes.text,
                    }}
                    control={<Radio />}
                    label={t('Businesses:RHAPSODY_PAY.PERCENT_SERVICE_FEE', {
                      percent: Number(serviceFeeIndex) * 100,
                    })}
                    value={id}
                  />
                  {optionLabel && (
                    <Text className={classes.radioHint} variant="lowAccent3">
                      {optionLabel}
                    </Text>
                  )}
                </Grid>
              )
            })}
          </RadioGroup>
        </Grid>
        <Grid
          container
          item
          className={classNames(classes.splitBlock, classes.percentageTable)}
          direction="column"
          justifyContent="center"
          md={6}
        >
          <Grid
            container
            item
            className={classes.tableRow}
            justifyContent="space-between"
          >
            <Text variant="body3">{t('Common:INVOICE_TOTAL')}</Text>
            <Text variant="body3">{NumberUtils.formatMoney(invoiceTotal)}</Text>
          </Grid>
          <Grid
            container
            item
            className={classes.tableRow}
            justifyContent="space-between"
          >
            <Text variant="body3">
              {t('Businesses:RHAPSODY_PAY.PERCENT_SERVICE_FEE', {
                percent: Number(currentPercentage.name) * 100,
              })}
            </Text>
            <Text variant="body3">
              {NumberUtils.formatMoney(serviceFeeAmount)}
            </Text>
          </Grid>
          <Grid
            container
            item
            className={classes.tableRow}
            justifyContent="space-between"
          >
            <Text variant="body3">
              {t('Businesses:RHAPSODY_PAY.CHARGED_TO_CLIENT')}
            </Text>
            <Text variant="body3">
              {NumberUtils.formatMoney(invoiceTotal + serviceFeeAmount)}
            </Text>
          </Grid>
          <Grid
            container
            item
            className={classNames(classes.tableRow, classes.withoutBorder)}
            justifyContent="space-between"
          >
            <Text variant="body3">
              {t('Businesses:RHAPSODY_PAY.SERVICE_FEE_TO_PRACTICE_PERCENT', {
                percent: 70,
              })}
            </Text>
            <Text variant="body3">
              {NumberUtils.formatMoney(serviceFeeToPractice)}
            </Text>
          </Grid>
          <Grid
            container
            item
            className={classes.tableRow}
            justifyContent="space-between"
          >
            <Text variant="body3">
              {t('Businesses:RHAPSODY_PAY.CC_PROCESSING_FEE_PERCENT', {
                percent: RHAPSODY_PAY_RATE,
              })}
            </Text>
            <Text variant="body3">
              {NumberUtils.formatMoney(-CCProcessingFee)}
            </Text>
          </Grid>
          <Grid
            container
            item
            className={classes.lastTableRow}
            justifyContent="space-between"
          >
            <Text strong variant="body3">
              {t('Businesses:RHAPSODY_PAY.TOTAL_TO_PRACTICE')}
            </Text>
            <Text strong variant="body3">
              {NumberUtils.formatMoney(totalToPractice)}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justifyContent="space-between">
        <Grid item>
          <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
        </Grid>
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={onProceed}
        >
          {t('Common:CONTINUE_ACTION')}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default RhapsodyPayCardProcessing
