import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ErrorTooltip,
  FieldProp,
  PermissionArea,
  PuiTextField,
  useFields,
  Validators,
} from '@pbt/pbt-ui-components'
import { CustomFieldValidator } from '@pbt/pbt-ui-components/src/types/validation'

import {
  AdministrationCourseType,
  AdministrationCourseTypeStr,
} from '~/constants/prescription'
import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandle, Prescription } from '~/types'
import { handleNumberInput } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

const useStyles = makeStyles(
  (theme) => ({
    radioLabel: {
      marginRight: theme.spacing(3),
    },
    disabledRadioInput: {
      pointerEvents: 'none',
      cursor: 'pointer',
    },
    customLengthInput: {
      marginTop: -20,
      maxWidth: 85,
    },
    text: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'LengthOfCourseSection' },
)

const useGetFields = () => {
  const { t } = useTranslation('Constants')

  const administrationCourseLengthValidator: CustomFieldValidator['validator'] =
    ({ state, value }) => {
      const isCustomCourse =
        state.administrationCourseType === AdministrationCourseType.CUSTOM
      return !isCustomCourse || Validators.notEmptyFormField(value)
    }

  return (prescription: Prescription, isRequired: boolean): FieldProp[] => [
    {
      name: 'administrationCourseType',
      initialValue: prescription.administrationCourseType,
      label: t('Dialogs:PRESCRIPTION_DIALOG.PERIOD_OF_TIME'),
      ...(isRequired ? { validators: ['required'] } : {}),
    },
    {
      name: 'administrationCourseLength',
      initialValue: prescription.administrationCourseLength || '',
      label: t('Constants:TIME_UNITS.DAY_OTHER'),
      type: 'number',
      ...(isRequired
        ? {
            validators: [
              {
                validator: administrationCourseLengthValidator,
                validatorName: 'required',
              },
            ],
          }
        : {}),
    },
  ]
}

export interface LengthOfCourseSectionProps {
  isRequired?: boolean
  onRefChange?: () => void
  prescription: Prescription
}

export interface LengthOfCourseSectionHandle
  extends DataHandle<{
    administrationCourseLength: number
    administrationCourseType: string
  }> {}

const LengthOfCourseSection = forwardRef<
  LengthOfCourseSectionHandle,
  LengthOfCourseSectionProps
>(function LengthOfCourseSection(
  { isRequired = false, onRefChange, prescription },
  ref,
) {
  const classes = useStyles()

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))

  const getFields = useGetFields()

  const {
    fields: { administrationCourseType, administrationCourseLength },
    reset,
    validate,
  } = useFields(getFields(prescription, isRequired), false)

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [administrationCourseType, administrationCourseLength])

  useEffectExceptOnMount(() => {
    reset(getFields(prescription, isRequired))
  }, [prescription, isRequired])

  useImperativeHandle(ref, () => ({
    get: () => ({
      administrationCourseType: administrationCourseType.value || null,
      administrationCourseLength:
        administrationCourseLength.value &&
        Number(administrationCourseLength.value),
    }),
    validate,
  }))

  const isCustomCourse =
    administrationCourseType.value === AdministrationCourseType.CUSTOM

  return (
    <Grid container>
      <ErrorTooltip
        disablePortal
        message={administrationCourseType.message}
        open={administrationCourseType.open && !administrationCourseType.valid}
        validateTag={administrationCourseType.validateTag}
      >
        <RadioGroup
          row
          value={administrationCourseType.value}
          onChange={(event, value) => administrationCourseType.setValue(value)}
        >
          <FormControlLabel
            className={classes.radioLabel}
            control={<Radio disableRipple color="secondary" />}
            label={
              <PuiTextField
                className={classes.customLengthInput}
                classes={{
                  root: classNames({
                    [classes.disabledRadioInput]: !isCustomCourse,
                  }),
                }}
                disabled={!permissions.update || !isCustomCourse}
                field={{
                  ...administrationCourseLength,
                  set: handleNumberInput(
                    administrationCourseLength.setValue,
                    10,
                    0,
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                label={`${administrationCourseLength.label}${
                  isRequired && isCustomCourse ? '*' : ''
                }`}
                margin="none"
              />
            }
            value={AdministrationCourseType.CUSTOM}
          />
          <FormControlLabel
            className={classes.radioLabel}
            classes={{ label: classes.text }}
            control={<Radio disableRipple color="secondary" />}
            disabled={!permissions.update}
            label={AdministrationCourseTypeStr.UNTIL_GONE}
            value={AdministrationCourseType.UNTIL_GONE}
          />
          <FormControlLabel
            className={classes.radioLabel}
            classes={{ label: classes.text }}
            control={<Radio disableRipple color="secondary" />}
            disabled={!permissions.update}
            label={AdministrationCourseTypeStr.AS_NEEDED}
            value={AdministrationCourseType.AS_NEEDED}
          />
        </RadioGroup>
      </ErrorTooltip>
    </Grid>
  )
})

export const copyLengthOfCourse = ({
  administrationCourseType,
  administrationCourseLength,
}: Prescription) => ({
  administrationCourseType,
  administrationCourseLength,
})

export default LengthOfCourseSection
