import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  CurrencyTextField,
  Nil,
  NumberUtils,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { fetchUnpaidInvoices } from '~/store/actions/finance'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import { getCurrentBusiness } from '~/store/reducers/auth'
import {
  getFinanceIsFetching,
  getFinanceUnpaidInvoiceIds,
  getMultipleInvoices,
} from '~/store/reducers/finance'
import { handleNumberInput } from '~/utils'

import { getInvoiceDueToPayNoFee } from '../invoiceUtils'
import PaymentInvoiceRow from './PaymentInvoiceRow'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    header: {
      borderBottom: theme.constants.tabBorder,
    },
    loaderContainer: {
      height: 250,
      position: 'relative',
    },
    paymentTitle: {},
    currencyTextField: {
      fontWeight: 500,
      textAlign: 'right',
    },
    list: {
      height: 200,
      overflow: 'auto',
    },
    noInvoicesText: {
      fontStyle: 'italic',
    },
    button: {
      minWidth: 150,
    },
    footer: {
      borderTop: theme.constants.tabBorder,
    },
    currencyFieldWrapper: {
      maxWidth: 100,
      margin: theme.spacing(0),
    },
    amountColumn: {
      paddingLeft: theme.spacing(3),
    },
    amountColumnNonEditable: {
      paddingRight: theme.spacing(4),
    },
  }),
  { name: 'PaymentInvoiceSelection' },
)

interface PaymentInvoiceSelectionProps {
  canEditAmount?: boolean
  clientId: string | Nil
  continueButtonText: string
  initialPaymentAmount?: number
  invoiceIds: string[]
  isLoading?: boolean
  onContinue: () => void
  paymentAmount: number
  setInvoiceIds: (ids: string[]) => void
  updatePaymentAmount?: (amount: number) => void
}

const PaymentInvoiceSelection = ({
  canEditAmount = true,
  initialPaymentAmount,
  clientId,
  paymentAmount,
  updatePaymentAmount,
  invoiceIds,
  setInvoiceIds,
  continueButtonText: continueButtonTextProp,
  onContinue,
  isLoading,
}: PaymentInvoiceSelectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices'])

  const isFetching = useSelector(getFinanceIsFetching)
  const unpaidInvoiceIds = useSelector(getFinanceUnpaidInvoiceIds)
  const currentBusiness = useSelector(getCurrentBusiness)
  const rhapsodyPayInitialConfig = useSelector(
    getRhapsodyPayConfig(currentBusiness?.id),
  )
  const invoices = useSelector(getMultipleInvoices(invoiceIds))

  const continueButtonText = continueButtonTextProp || t('Common:NEXT')

  const { posPayEnabled, id: businessId } = currentBusiness || {}

  const {
    fields: { amount },
    validate,
  } = useFields([
    {
      name: 'amount',
      initialValue: paymentAmount || '',
      validators: ['greaterThanZero'],
    },
  ])

  const invoiceSum =
    R.sum(
      R.map((invoice) => getInvoiceDueToPayNoFee(invoice) as number, invoices),
    ) || 0
  const serviceFeeAmount = rhapsodyPayInitialConfig?.serviceFee
    ? Utils.round(amount.value * rhapsodyPayInitialConfig.serviceFee, 2)
    : 0
  const amountWithServiceFee =
    posPayEnabled && serviceFeeAmount
      ? (Utils.round(amount.value + serviceFeeAmount, 2) ?? 0)
      : 0

  useEffect(() => {
    if (clientId) {
      dispatch(fetchUnpaidInvoices(clientId))
    }
  }, [clientId])

  useEffect(() => {
    if (businessId) {
      dispatch(fetchRhapsodyPayConfig(businessId))
    }
  }, [businessId])

  useEffect(() => {
    if (invoiceSum) {
      amount.setValue(invoiceSum)
    }
  }, [invoiceSum])

  useEffect(() => {
    if (amount.value && canEditAmount && updatePaymentAmount) {
      updatePaymentAmount(amount.value)
    }
  }, [amount.value])

  useEffect(() => {
    setInvoiceIds(unpaidInvoiceIds)
  }, [unpaidInvoiceIds])

  if (isFetching) {
    return (
      <Grid container item className={classes.loaderContainer}>
        <CircularProgressOverlay
          open
          preloaderText={t('Common:LOADING_INVOICES')}
        />
      </Grid>
    )
  }

  const onCheck = (invoiceId: string) => {
    const newInvoiceIds = Utils.toggleListItem(invoiceId, invoiceIds)

    setInvoiceIds(newInvoiceIds)
    if (newInvoiceIds.length === 0) {
      amount.setValue(initialPaymentAmount)
    }
  }

  const onProceed = () => {
    if (validate()) {
      onContinue()
    }
  }

  const hasUnpaidInvoices = unpaidInvoiceIds.length > 0

  return (
    <Grid container item className={classes.root}>
      <Grid container item>
        <Grid
          container
          item
          alignItems="center"
          className={classes.header}
          p={1.5}
        >
          <Grid item className={classes.paymentTitle} pl={1.5} xs={3}>
            <Text strong variant="body">
              {`${
                canEditAmount
                  ? t('Common:PAYMENTS.PAYMENT')
                  : t('Common:PAYMENTS.PAID')
              }:`}
            </Text>
          </Grid>
          <Grid
            item
            alignItems={canEditAmount ? 'center' : undefined}
            className={classNames(classes.amountColumn, {
              [classes.amountColumnNonEditable]: !canEditAmount,
            })}
            container={canEditAmount}
            wrap={canEditAmount ? 'nowrap' : undefined}
            xs={canEditAmount ? true : 3}
          >
            {canEditAmount ? (
              <>
                <CurrencyTextField
                  className={classes.currencyFieldWrapper}
                  field={{
                    ...amount,
                    set: handleNumberInput(
                      (value: string) => {
                        const formattedValue = parseFloat(value) || 0
                        amount.setValue(formattedValue)
                      },
                      7,
                      2,
                      true,
                    ),
                  }}
                  inputProps={{
                    className: classes.currencyTextField,
                  }}
                />
                {amountWithServiceFee > 0 && (
                  <Text ml={0.5} variant="body2">
                    (
                    {t(
                      'Invoices:PAYMENTS.PAYMENT_INVOICE_SELECTION.WITH_SERVICE_FEE',
                      {
                        amount: NumberUtils.formatMoney(amountWithServiceFee),
                      },
                    )}
                    )
                  </Text>
                )}
              </>
            ) : (
              <Text align="right" variant="body">
                {NumberUtils.formatMoney(paymentAmount)}
              </Text>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignContent={hasUnpaidInvoices ? 'flex-start' : 'center'}
          className={classes.list}
          justifyContent={hasUnpaidInvoices ? 'flex-start' : 'center'}
        >
          {hasUnpaidInvoices ? (
            unpaidInvoiceIds.map((invoiceId) => {
              const isChecked = R.includes(invoiceId, invoiceIds)

              return (
                <PaymentInvoiceRow
                  invoiceId={invoiceId}
                  isChecked={isChecked}
                  key={invoiceId}
                  onCheck={onCheck}
                />
              )
            })
          ) : (
            <Text className={classes.noInvoicesText} variant="body">
              {t(
                'Invoices:PAYMENTS.PAYMENT_INVOICE_SELECTION.NO_UNPAID_INVOICES',
              )}
            </Text>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classes.footer}
        px={3}
        py={2}
      >
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          onClick={onProceed}
        >
          {continueButtonText}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default PaymentInvoiceSelection
