import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  PuiDialog,
  Text,
  useInterval,
} from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import PuiCheckboxList from '~/components/common/PuiCheckboxList'
import Spacer from '~/components/common/Spacer'
import { MIGRATION_STATUS_POLLING_INTERVAL } from '~/constants/migration'
import i18n from '~/locales/i18n'
import {
  practiceExportActions,
  practiceExportSelectors,
  // @ts-ignore
} from '~/store/duck/practiceExport'
import { getPracticeCloningItems } from '~/store/reducers/constants'
import { BaseMigrationDialogProps } from '~/types/entities/migration'
import { PracticeCloningSession } from '~/types/entities/practiceCloning'

import EntitiesStatusLog from './EntitiesStatusLog'
import {
  buildPracticeCloningEntity,
  enrichExportEntitiesStatus,
} from './practiceCloningUtils'

const { fetchSession, fetchActiveSession, createSession, cleanActiveSession } =
  practiceExportActions
const { getActiveSessionId, getSession, getIsLoading } = practiceExportSelectors

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(3, 2),
    },
    successIcon: {
      width: 48,
      height: 48,
      color: theme.colors.alertSuccess,
    },
    actionButton: {
      width: 135,
    },
  }),
  { name: 'PracticeCloningExportDialog' },
)

enum DialogStep {
  PRELOAD = 'PRELOAD',
  CONFIGURE = 'CONFIGURE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

const DialogStepToActionName = {
  [DialogStep.PRELOAD]: null,
  [DialogStep.CONFIGURE]: i18n.t('Common:EXPORT_ACTION'),
  [DialogStep.IN_PROGRESS]: i18n.t('Common:DOWNLOAD_ACTION'),
  [DialogStep.DONE]: i18n.t('Common:DOWNLOAD_ACTION'),
}

const DialogStepToActionDisabledState = {
  [DialogStep.PRELOAD]: false,
  [DialogStep.CONFIGURE]: false,
  [DialogStep.IN_PROGRESS]: true,
  [DialogStep.DONE]: false,
}

const PracticeCloningExportDialog = ({
  open,
  onClose,
  businessId,
}: BaseMigrationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const PracticeCloningItems = useSelector(getPracticeCloningItems)
  const activeSessionId: string = useSelector(getActiveSessionId)
  const activeSession: PracticeCloningSession = useSelector(
    getSession(activeSessionId),
  )
  const isLoading: boolean = useSelector(getIsLoading)

  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [step, setStep] = useState(DialogStep.PRELOAD)

  useEffect(() => {
    dispatch(fetchActiveSession(businessId))
    return () => dispatch(cleanActiveSession())
  }, [])

  useEffect(() => {
    const actualState = activeSession?.state
    if (actualState) {
      const dialogStep =
        actualState === DialogStep.IN_PROGRESS
          ? DialogStep.IN_PROGRESS
          : DialogStep.DONE

      setStep(dialogStep)
    }
  }, [activeSession?.state])

  useEffect(() => {
    if (!activeSessionId && !isLoading && step === DialogStep.PRELOAD) {
      setStep(DialogStep.CONFIGURE)
    }
  }, [activeSessionId, isLoading])

  useInterval(() => {
    if (step === DialogStep.IN_PROGRESS) {
      dispatch(fetchSession(activeSessionId))
    }
  }, MIGRATION_STATUS_POLLING_INTERVAL)

  const exportEntitiesStatus = enrichExportEntitiesStatus(
    PracticeCloningItems,
    activeSession,
  )

  const actionTitle = DialogStepToActionName[step]
  const actionDisabled =
    isLoading ||
    Boolean(DialogStepToActionDisabledState[step]) ||
    (step === DialogStep.CONFIGURE && selectedOptions.length === 0)

  const handleAction = () => {
    if (step === DialogStep.CONFIGURE) {
      const session = {
        businessId,
        items: selectedOptions,
      }
      dispatch(createSession(session))
    } else if (step === DialogStep.DONE) {
      window.open(activeSession.zipFileUrl)
    }
  }

  const entities = PracticeCloningItems.map(buildPracticeCloningEntity)

  return (
    <PuiDialog
      fullWidth
      actions={
        actionTitle && (
          <ButtonWithLoader
            className={classes.actionButton}
            disabled={actionDisabled}
            loading={isLoading}
            onClick={handleAction}
          >
            {actionTitle}
          </ButtonWithLoader>
        )
      }
      aria-labelledby="practice-cloning-export-dialog"
      classes={{
        dialogContentRoot: classes.content,
      }}
      maxWidth="sm"
      open={open}
      title={t('Businesses:MIGRATION.EXPORT_CATALOG_AND_SETTINGS')}
      onClose={onClose}
    >
      {step === DialogStep.PRELOAD && (
        <Grid
          container
          alignItems="center"
          direction="column"
          justifyContent="center"
          p={4}
        >
          <CircularProgress size={32} />
          <Spacer spacing={1} />
          <Text variant="body">
            {t('Businesses:MIGRATION.CHECKING_ACTIVE_SESSIONS')}
          </Text>
        </Grid>
      )}
      {step === DialogStep.CONFIGURE && (
        <PuiCheckboxList
          hideAllButton
          initialState={selectedOptions}
          items={entities}
          onChange={(items: string[]) => {
            setSelectedOptions(items)
          }}
        />
      )}
      {step === DialogStep.IN_PROGRESS && (
        <Grid container alignItems="center" direction="column" py={4}>
          <CircularProgress size={32} />
          <Spacer spacing={2} />
          <Text variant="lowAccent">
            {t('Businesses:MIGRATION.EXPORT_IN_PROGRESS')}.
          </Text>
          <Spacer spacing={1} />
          <EntitiesStatusLog entitiesStatus={exportEntitiesStatus} />
        </Grid>
      )}
      {step === DialogStep.DONE && (
        <Grid container alignItems="center" direction="column" py={4}>
          <SuccessAlert className={classes.successIcon} />
          <Spacer spacing={2} />
          <Text variant="lowAccent">
            {t('Businesses:MIGRATION.FILES_WERE_GENERATED')}.
          </Text>
          <Spacer spacing={1} />
          <Text variant="lowAccent">
            {t(
              'Businesses:MIGRATION.YOU_CAN_CLOSE_SESSION_OR_CLICK_TO_DOWNLOAD',
            )}
          </Text>
          <Text variant="lowAccent">
            ({t('Businesses:MIGRATION.NEEDED_FOR_MIGRATION').toLowerCase()})
          </Text>
        </Grid>
      )}
    </PuiDialog>
  )
}

export default PracticeCloningExportDialog
