import React from 'react'
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'

type MigrationExceptionTableFooterProps = {
  disableNext: boolean
  disablePrev: boolean
  onNext: () => void
  onPrev: () => void
}

export const MigrationExceptionTableFooter = ({
  disableNext,
  disablePrev,
  onPrev,
  onNext,
}: MigrationExceptionTableFooterProps) => (
  <Box display="flex" justifyContent="space-between" width="inherit">
    <IconButton disabled={disablePrev} onClick={onPrev}>
      <ArrowBackIcon />
    </IconButton>
    <IconButton disabled={disableNext} onClick={onNext}>
      <ArrowForwardIcon />
    </IconButton>
  </Box>
)
