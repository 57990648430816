import React from 'react'
import { useTranslation } from 'react-i18next'

import ToggleLabel from '../ToggleLabel'

export interface CalendarTextLabelProps {
  InputProps: {
    endAdornment: React.ReactElement
  }
  inputProps: {
    value: string
  }
  inputRef: React.Ref<HTMLDivElement>
}

const CalendarTextLabel = ({
  inputProps: { value },
  InputProps: { endAdornment },
  inputRef,
}: CalendarTextLabelProps) => {
  const { t } = useTranslation('Common')

  return (
    <ToggleLabel
      ref={inputRef}
      text={value || t('Common:ADD_WHAT_IS_DUE_OR_EXPECTED')}
      onClick={endAdornment?.props?.children?.props?.onClick}
    />
  )
}

export default CalendarTextLabel
