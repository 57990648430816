import { all, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  createSpace,
  createSpaceFailure,
  createSpaceSuccess,
  deleteSpace,
  deleteSpaceFailure,
  deleteSpaceSuccess,
  editSpace,
  editSpaceFailure,
  editSpaceSuccess,
  fetchMoreItemsForSpacesList,
  fetchMoreItemsForSpacesListFailure,
  fetchMoreItemsForSpacesListSuccess,
  fetchSpace,
  fetchSpaceFailure,
  fetchSpacesList,
  fetchSpacesListFailure,
  fetchSpacesListForAppointment,
  fetchSpacesListForAppointmentFailure,
  fetchSpacesListForAppointmentSuccess,
  fetchSpacesListSuccess,
  fetchSpacesListWithType,
  fetchSpacesListWithTypeFailure,
  fetchSpacesListWithTypeSuccess,
  fetchSpaceSuccess,
  updateSpaces,
} from '../actions/spaces'
import {
  CREATE_SPACE,
  DELETE_SPACE,
  EDIT_SPACE,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST,
  FETCH_SPACE,
  FETCH_SPACES_LIST,
  FETCH_SPACES_LIST_FOR_APPOINTMENT,
  FETCH_SPACES_LIST_WITH_TYPE,
} from '../actions/types/spaces'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'

export function* fetchSpacesListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchSpacesList>) {
  try {
    yield put(startLoading('spaces'))
    const {
      result: { data: list, totalCount },
      entities: { spaces = {} },
    } = yield* requestAPI(API.fetchSpaces, from, to, query)
    yield put(updateSpaces(spaces))
    yield put(fetchSpacesListSuccess(list, totalCount))
    yield put(finishLoading('spaces'))
  } catch (error) {
    yield put(fetchSpacesListFailure(error as ApiError))
    yield put(finishLoading('spaces'))
  }
}

export function* fetchSpacesListWithTypeSaga({
  typeId,
}: ReturnType<typeof fetchSpacesListWithType>) {
  try {
    yield put(startLoading('spaces'))
    const resp = yield* requestAPI(API.fetchAllSpacesWithType, typeId)
    const {
      result: list,
      entities: { spaces = {} },
    } = resp
    yield put(updateSpaces(spaces))
    yield put(fetchSpacesListWithTypeSuccess(list))
    yield put(finishLoading('spaces'))
  } catch (error) {
    yield put(fetchSpacesListWithTypeFailure(error as ApiError))
    yield put(finishLoading('spaces'))
  }
}

export function* fetchMoreItemsForSpacesListSaga({
  from,
  to,
  query,
}: ReturnType<typeof fetchMoreItemsForSpacesList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities: { spaces },
    } = yield* requestAPI(API.fetchSpaces, from, to, query)
    yield put(updateSpaces(spaces))
    yield put(fetchMoreItemsForSpacesListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForSpacesListFailure(error as ApiError))
  }
}

export function* fetchSpaceSaga({ id }: ReturnType<typeof fetchSpace>) {
  try {
    const {
      entities: { spaces },
    } = yield* requestAPI(API.fetchSpace, id)
    yield put(updateSpaces(spaces))
    yield put(fetchSpaceSuccess())
  } catch (error) {
    yield put(fetchSpaceFailure(error as ApiError))
  }
}

export function* createSpaceSaga({ space }: ReturnType<typeof createSpace>) {
  try {
    const {
      result,
      entities: { spaces },
    } = yield* requestAPI(API.createSpace, space)
    yield put(updateSpaces(spaces))
    yield put(fetchSpacesList())
    yield put(createSpaceSuccess(result))
  } catch (error) {
    yield put(createSpaceFailure(error as ApiError))
  }
}

export function* editSpaceSaga({ space }: ReturnType<typeof editSpace>) {
  try {
    const {
      entities: { spaces },
    } = yield* requestAPI(API.editSpace, space)
    yield put(updateSpaces(spaces))
    yield put(editSpaceSuccess())
  } catch (error) {
    yield put(editSpaceFailure(error as ApiError))
  }
}

export function* deleteSpaceSaga({ spaceId }: ReturnType<typeof deleteSpace>) {
  try {
    yield* requestAPI(API.deleteSpace, spaceId)
    yield put(deleteSpaceSuccess(spaceId))
  } catch (error) {
    yield put(deleteSpaceFailure(error as ApiError))
  }
}

export function* fetchSpacesListForAppointmentSaga({
  appointmentTypeId,
  patientId,
}: ReturnType<typeof fetchSpacesListForAppointment>) {
  try {
    const {
      result: list,
      entities: { spaces = {} },
    } = yield* requestAPI(
      API.fetchSpacesListForAppointment,
      appointmentTypeId,
      patientId,
    )
    yield put(updateSpaces(spaces))
    yield put(fetchSpacesListForAppointmentSuccess(list))
  } catch (error) {
    yield put(fetchSpacesListForAppointmentFailure(error as ApiError))
  }
}

function* watchFetchSpacesList() {
  yield takeLeading(FETCH_SPACES_LIST, fetchSpacesListSaga)
}

function* watchFetchSpacesListWithType() {
  yield takeLeading(FETCH_SPACES_LIST_WITH_TYPE, fetchSpacesListWithTypeSaga)
}

function* watchFetchMoreItemsForSpacesList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_SPACES_LIST,
    fetchMoreItemsForSpacesListSaga,
  )
}

function* watchFetchSpace() {
  yield takeLeading(FETCH_SPACE, fetchSpaceSaga)
}

function* watchCreateSpace() {
  yield takeLeading(CREATE_SPACE, createSpaceSaga)
}

function* watchEditSpace() {
  yield takeLeading(EDIT_SPACE, editSpaceSaga)
}

function* watchDeleteSpace() {
  yield takeLeading(DELETE_SPACE, deleteSpaceSaga)
}

function* watchFetchSpacesListForAppointment() {
  yield takeLeading(
    FETCH_SPACES_LIST_FOR_APPOINTMENT,
    fetchSpacesListForAppointmentSaga,
  )
}

export default function* spacesSaga() {
  yield all([
    watchFetchSpacesList(),
    watchFetchSpacesListWithType(),
    watchFetchMoreItemsForSpacesList(),
    watchFetchSpace(),
    watchCreateSpace(),
    watchEditSpace(),
    watchDeleteSpace(),
    watchFetchSpacesListForAppointment(),
  ])
}
