import React from 'react'
import {
  PuiHighlight,
  TextInteractive,
  TextInteractiveProps,
} from '@pbt/pbt-ui-components'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

export interface PracticeTableNameProps extends TextInteractiveProps {
  highlights?: string[]
  name: string
}

const PracticeTableName = ({
  name,
  highlights,
  ...rest
}: PracticeTableNameProps) => (
  <TextInteractive strong variant="body" {...rest}>
    {name && (
      <PuiHighlight page searchTerm={getHighlightedText(name, highlights)}>
        {name}
      </PuiHighlight>
    )}
  </TextInteractive>
)

export default PracticeTableName
