import { useSelector } from 'react-redux'

import { getGender } from '~/store/reducers/constants'

import { getGenderString } from '../../../inputs/gender/genderUtils'

export type GenderCellItem = {
  genderId: string
}

const GenderCell = (item: GenderCellItem) => {
  const Gender = useSelector(getGender)

  return getGenderString(item.genderId, Gender)
}

export default GenderCell
