import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Document, DocumentContext, DocumentToSend } from '~/types'

import { objectToFormData } from '../utils'
import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchDocumentsList = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
) =>
  request(
    getAPIPath('admin/services/documents'),
    { method: 'GET', params: { businessId, from, to, query } },
    true,
    Schema.documentsList,
  )

export const fetchDocument = (businessId: string, documentId: string) =>
  request(
    getAPIPath(`admin/services/documents/${documentId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.document,
  )

export const fetchDocuments = (
  businessId: string,
  documentIds: string[] = [],
) =>
  request(
    getAPIPath('admin/services/documents'),
    { method: 'GET', params: { businessId, ids: documentIds.join(',') } },
    true,
    Schema.documentsArray,
  )

export const createDocument = (businessId: string, document: Document) =>
  request(
    getAPIPath('admin/services/documents'),
    { method: 'POST', data: document, params: { businessId } },
    true,
    Schema.document,
  )

export const createDocumentWithTemplate = (
  businessId: string,
  document: DocumentToSend,
) =>
  request(
    getAPIPath('admin/services/documents/upload'),
    {
      method: 'POST',
      data: objectToFormData(document),
      params: { businessId },
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    true,
    Schema.document,
  )

export const editDocument = (businessId: string, document: Document) =>
  request(
    getAPIPath(`admin/services/documents/${document.id}`),
    { method: 'PUT', data: document, params: { businessId } },
    true,
    Schema.document,
  )

export const editDocumentWithTemplate = (
  businessId: string,
  document: DocumentToSend,
) =>
  request(
    getAPIPath(`admin/services/documents/upload/${document.id}`),
    {
      method: 'PUT',
      data: objectToFormData(document),
      params: { businessId },
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    true,
    Schema.document,
  )

export const deleteDocument = (businessId: string, documentId: string) =>
  request(
    getAPIPath(`admin/services/documents/${documentId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchResolvedDocumentBody = (businessId: string, body: string) =>
  request(
    getAPIPath('admin/services/documents/preview'),
    {
      method: 'POST',
      data: { body },
      params: { businessId },
    },
    true,
  )

export const fetchDocumentsByType = (
  businessId: string,
  type: string | Nil,
  patientId: string | Nil,
  eventId: string | Nil,
  skipFileTemplates?: boolean,
) =>
  request(
    getAPIPath('admin/services/documents/filtered'),
    {
      method: 'GET',
      params: { businessId, type, patientId, eventId, skipFileTemplates },
    },
    true,
    Schema.documentsArray,
  )

export const fetchResolvedDocuments = (
  businessId: string,
  documentIds: string[],
  context: DocumentContext = {},
  convertToTextDocument: boolean,
) =>
  request(
    getAPIPath('admin/services/documents/enrich'),
    {
      method: 'POST',
      data: { ids: documentIds },
      params: { businessId, convertToTextDocument, ...context },
    },
    true,
    Schema.documentsArray,
  )

export const generateDocumentTemplateFile = (
  businessId: string,
  documentId: string,
) =>
  request(
    getAPIPath(`admin/services/documents/download/${documentId}`),
    { method: 'POST', params: { businessId } },
    true,
  )

export const generateDocumentInstances = (
  businessId: string,
  documentIds: string[],
  context: DocumentContext = {},
) =>
  request(
    getAPIPath('admin/services/documents/download'),
    {
      method: 'POST',
      params: { businessId, ...context },
      data: { ids: documentIds },
    },
    true,
  )
