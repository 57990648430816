import React from 'react'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { Invoice, InvoiceLineItem } from '~/types'

import { formatMoneyRange, getTotal } from '../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    subTotalCell: {
      fontWeight: 500,
      minWidth: 90,
      maxWidth: 92,
    },
  }),
  { name: 'ItemTotalCellBody' },
)

export interface ItemTotalCellBodyProps {
  className?: string
  invoice: Invoice
  isDeclined: boolean
  item: InvoiceLineItem
}

const ItemTotalCellBody = ({
  item,
  invoice,
  isDeclined,
  className,
}: ItemTotalCellBodyProps) => {
  const classes = useStyles()

  const subTotal = getTotal(item, invoice)

  return (
    <TableCell
      align="right"
      className={classNames(className, classes.subTotalCell)}
    >
      {!isDeclined && formatMoneyRange(subTotal)}
    </TableCell>
  )
}

export default ItemTotalCellBody
