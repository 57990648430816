import React from 'react'
import {
  AppointmentEventType,
  EventTypeName,
  Field,
} from '@pbt/pbt-ui-components'

import EnumSelect, {
  EnumSelectProps,
} from '~/components/common/inputs/EnumSelect'
import { AppointmentStateColorMap } from '~/constants/event'
import { useEventType } from '~/utils/useEventType'

export interface AppointmentStatusSelectProps
  extends Omit<EnumSelectProps, 'field' | 'accent' | 'Constant'> {
  field: Field
}

const AppointmentStatusSelect = ({
  field,
  ...rest
}: AppointmentStatusSelectProps) => {
  const AppointmentStatuses: AppointmentEventType['states'] = useEventType(
    EventTypeName.Appointment,
    'states',
  )

  return (
    <EnumSelect
      accent
      Constant={AppointmentStatuses}
      colorVariantMap={AppointmentStateColorMap}
      field={field}
      {...rest}
    />
  )
}

export default AppointmentStatusSelect
