import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'

import { getDischargeNotes, getMedicalNotes } from '~/store/reducers/soap'

import NotesWidgetLockedItem from './NotesWidgetLockedItem'

const MedicalDischargeNotesWidgetLocked = () => {
  const { t } = useTranslation('Common')

  const medicalNotes = useSelector(getMedicalNotes)
  const dischargeNotes = useSelector(getDischargeNotes)

  const NOTES = [
    ...(medicalNotes
      ? [
          {
            id: 'medical-notes',
            label: t('Common:MEDICAL_NOTES'),
            value: medicalNotes,
          },
        ]
      : []),
    ...(dischargeNotes
      ? [
          {
            id: 'discharge-notes',
            label: t('Common:DISCHARGE_NOTES'),
            value: dischargeNotes,
          },
        ]
      : []),
  ].filter(Boolean)

  if (NOTES.length === 0) {
    return <></>
  }

  return (
    <Stack spacing={2}>
      {NOTES.map(({ id, label, value }) => (
        <NotesWidgetLockedItem key={id} label={label} value={value} />
      ))}
    </Stack>
  )
}

export default MedicalDischargeNotesWidgetLocked
