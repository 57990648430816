import { MutationCreateOrUpdateClientPreferenceArgs } from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { SAVE_CLIENT_PREFERENCES } from './graphql/mutations/clientPreferences'
import { FETCH_CLIENT_PREFERENCES } from './graphql/queries'

export const fetchClientPreferences = requestQuery({
  query: FETCH_CLIENT_PREFERENCES,
  variablesHandler: (_, clientId: string) => ({ clientId }),
})

export const saveClientPreferences = requestMutation({
  mutation: SAVE_CLIENT_PREFERENCES,
  variablesHandler: (_, args: MutationCreateOrUpdateClientPreferenceArgs) =>
    args,
})
