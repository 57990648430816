import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { Checkbox, FormControlLabel, Skeleton, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ButtonWithLoader, Utils } from '@pbt/pbt-ui-components'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { StatusError } from '~/components/elements/Status/Status'
import Typography from '~/components/elements/Typography/Typography'
import { getApolloErrorMessage } from '~/utils/errors'

const StyledButtonWithLoader = styled(ButtonWithLoader)`
  && {
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;

  & .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: ${({ theme }) => theme.spacing(1)};
    margin: 0;
  }
`

const minPriceWidth = 55

const StyledBalanceGrid = styled('div')`
  display: grid;
  grid-template-columns: [label] max-content [value] max-content;
  & > * {
    display: contents;
    & > :nth-child(2n) {
      justify-self: end;
      padding-left: ${({ theme }) => theme.spacing(1.5)};
      min-width: ${minPriceWidth}px;
    }
    & > label {
      cursor: pointer;
    }
  }
`
const PriceWithSkeleton = ({
  loading,
  price,
}: {
  loading: boolean
  price: number
}) =>
  loading ? (
    <Skeleton animation="wave" variant="text" width={minPriceWidth} />
  ) : (
    <>{formatMoney(price)}</>
  )

export type ChewyPaymentFooterProps = {
  accountBalance?: number
  canPlaceChewyOrder?: boolean
  clientName: string
  isPaymentRequest?: boolean
  loading?: boolean
  loadingError?: ApolloError
  moveToCartError?: ApolloError
  moveToCartLoading: boolean
  onMoveToCart: () => void
  onPlaceOrder: (applyAccountBalance: boolean) => void
  placeOrderError?: ApolloError
  placeOrderLoading: boolean
  recalculatingPrice?: boolean
  total: number
}

// eslint-disable-next-line react/no-multi-comp
export const ChewyPaymentFooter = ({
  accountBalance,
  canPlaceChewyOrder = false,
  clientName,
  isPaymentRequest = false,
  loading = false,
  loadingError,
  moveToCartError,
  moveToCartLoading,
  onMoveToCart,
  onPlaceOrder,
  placeOrderError,
  placeOrderLoading,
  recalculatingPrice = false,
  total,
}: ChewyPaymentFooterProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [applyAccountBalance, setApplyAccountBalance] = useState(true)

  const totalDue = !applyAccountBalance
    ? total
    : Math.max(0, Utils.round(total - (accountBalance ?? 0), 2) ?? 0)

  const showAccountBalance = accountBalance && accountBalance > 0

  return (
    <Stack gap={1}>
      {loading ? (
        <Stack gap={1}>
          <Typography.H3 as="span">
            <Skeleton animation="wave" variant="text" width={175} />
          </Typography.H3>
          <Typography.Paragraph as="span">
            <Skeleton animation="wave" variant="text" width={450} />
          </Typography.Paragraph>
        </Stack>
      ) : (
        <Stack gap={1}>
          {isPaymentRequest && (
            <StatusError>
              {t('Dialogs:CHEWY_PAYMENT_DIALOG.PAYMENT_REQUEST_WARNING')}
            </StatusError>
          )}
          {showAccountBalance ? (
            <StyledBalanceGrid>
              {!canPlaceChewyOrder ? (
                <>
                  <Typography.H3>
                    <div>
                      {t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_ORDER_TOTAL')}
                    </div>
                    <div>
                      <PriceWithSkeleton
                        loading={recalculatingPrice}
                        price={total}
                      />
                    </div>
                  </Typography.H3>
                  <Typography.Paragraph as="span">
                    <div>
                      {t(
                        'Dialogs:CHEWY_PAYMENT_DIALOG.APPLY_CHEWY_ACCOUNT_BALANCE',
                      )}
                    </div>
                    <div>
                      <PriceWithSkeleton
                        loading={recalculatingPrice}
                        price={-accountBalance}
                      />
                    </div>
                  </Typography.Paragraph>
                </>
              ) : (
                <>
                  <Typography.Paragraph>
                    <div>
                      {t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_ORDER_TOTAL')}
                    </div>
                    <div>
                      <PriceWithSkeleton
                        loading={recalculatingPrice}
                        price={total}
                      />
                    </div>
                  </Typography.Paragraph>
                  <Typography.Paragraph as="span">
                    {/* TODO: M1 switch to Design Systems Checkbox */}
                    <StyledFormControlLabel
                      disableTypography
                      control={
                        <Checkbox
                          checked={applyAccountBalance}
                          inputProps={{
                            'aria-label': 'apply-balance',
                            id: 'apply-balance-checkbox',
                          }}
                          onChange={(event) =>
                            setApplyAccountBalance(event.target.checked)
                          }
                        />
                      }
                      label={t(
                        'Dialogs:CHEWY_PAYMENT_DIALOG.APPLY_CHEWY_ACCOUNT_BALANCE',
                      )}
                    />
                    {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
                    <label htmlFor="apply-balance-checkbox">
                      <div>
                        <PriceWithSkeleton
                          loading={recalculatingPrice}
                          price={-accountBalance}
                        />
                      </div>
                    </label>
                  </Typography.Paragraph>
                  <Typography.H3>
                    <div>
                      {t('Dialogs:CHEWY_PAYMENT_DIALOG.CHEWY_TOTAL_DUE')}
                    </div>
                    <div>
                      <PriceWithSkeleton
                        loading={recalculatingPrice}
                        price={totalDue}
                      />
                    </div>
                  </Typography.H3>
                </>
              )}
            </StyledBalanceGrid>
          ) : (
            <Typography.H3
              color="brandSecondary"
              sx={{ display: 'flex', gap: 1 }}
            >
              <span>{t('Common:CHEWY_TOTAL')}: </span>
              <PriceWithSkeleton loading={recalculatingPrice} price={total} />
            </Typography.H3>
          )}

          <Typography.Paragraph>
            {canPlaceChewyOrder
              ? t(
                  'Dialogs:CHEWY_PAYMENT_DIALOG.BEFORE_PROCEEDING_CONFIRM_PET_PARENT_AUTHORIZES_CHARGE',
                )
              : t(
                  'Dialogs:CHEWY_PAYMENT_DIALOG.YOU_CANNOT_PROCEED_WITH_NO_PAYMENT_METHOD_ON_FILE',
                  { clientName },
                )}
          </Typography.Paragraph>

          {placeOrderError !== undefined && (
            <StatusError>
              {t('Dialogs:CHEWY_PAYMENT_DIALOG.GENERAL_PAYMENT_FAILURE')}
            </StatusError>
          )}

          {moveToCartError !== undefined && (
            <StatusError>{getApolloErrorMessage(moveToCartError)}</StatusError>
          )}
        </Stack>
      )}

      <Stack direction="row" gap={2}>
        {canPlaceChewyOrder && (
          <StyledButtonWithLoader
            disabled={
              loading ||
              Boolean(loadingError) ||
              moveToCartLoading ||
              recalculatingPrice
            }
            loading={placeOrderLoading}
            onClick={() => onPlaceOrder(applyAccountBalance)}
          >
            {t('Dialogs:CHEWY_PAYMENT_DIALOG.PLACE_CHEWY_ORDER')}
          </StyledButtonWithLoader>
        )}
        <StyledButtonWithLoader
          color={
            isPaymentRequest || !canPlaceChewyOrder ? undefined : 'secondary'
          }
          disabled={loading || Boolean(loadingError) || placeOrderLoading}
          loading={moveToCartLoading}
          onClick={onMoveToCart}
        >
          {t('Dialogs:CHEWY_PAYMENT_DIALOG.MOVE_ITEMS_TO_CHEWY_CART')}
        </StyledButtonWithLoader>
      </Stack>
    </Stack>
  )
}
