import Bowser from 'bowser'

/**
 * Finds device type of user.Need to use user-agent for development and testing
 * @returns {isMobileDevice: boolean, isTabletDevice: boolean, isDesktopDevice: boolean}
 */
export function getDeviceType() {
  const platform = Bowser.getParser(
    window.navigator.userAgent,
  ).getPlatformType()
  return {
    isMobileDevice: platform === 'mobile',
    isTabletDevice: platform === 'tablet',
    isDesktopDevice: platform === 'desktop',
  }
}
