import { Nil } from '@pbt/pbt-ui-components'

import { LabOrderLabTest, LabOrderOptions } from '~/types'

import { getAPIPath, request } from './core'

export const fetchLabOrders = (
  currentBusinessId: string,
  soapId: string | Nil,
  invoiceId: string | Nil,
) =>
  request(
    getAPIPath('integration/lab/orders'),
    {
      method: 'GET',
      params: { businessId: currentBusinessId, soapId, invoiceId },
    },
    true,
  )

export const placeLabOrder = (
  businessId: string,
  soapId: string | Nil,
  vendorId: string,
  labTests: LabOrderLabTest[],
  { deviceSerialNumber, znLabsProviderIdentifier }: LabOrderOptions,
) =>
  request(
    getAPIPath('integration/lab/orders/place'),
    {
      method: 'POST',
      params: { businessId, vendorId },
      data: {
        soapId,
        orderedLabTests: labTests,
        deviceSerialNumber,
        znLabsProviderIdentifier,
      },
    },
    true,
  )

export const placeAllLabOrders = (
  businessId: string,
  vendorId: string,
  labTestGroups: any,
) =>
  request(
    getAPIPath('integration/lab/orders/place/all'),
    {
      method: 'POST',
      params: { businessId, vendorId },
      data: labTestGroups,
    },
    true,
  )

export const cancelLabOrder = (
  currentBusinessId: string,
  vendorId: string | Nil,
  orderId: string,
) =>
  request(
    getAPIPath(`integration/lab/orders/${orderId}`),
    { method: 'DELETE', params: { businessId: currentBusinessId, vendorId } },
    true,
  )

export const finalizeLabOrder = (
  currentBusinessId: string,
  vendorId: string | Nil,
  orderId: string,
) =>
  request(
    getAPIPath(`integration/lab/orders/${orderId}`),
    { method: 'POST', params: { businessId: currentBusinessId, vendorId } },
    true,
  )

export const completeLabOrder = (
  currentBusinessId: string,
  vendorId: string | Nil,
  orderId: string,
) =>
  request(
    getAPIPath(`integration/lab/orders/complete/${orderId}`),
    { method: 'PUT', params: { businessId: currentBusinessId, vendorId } },
    true,
  )

export const declineLabOrder = (
  currentBusinessId: string,
  vendorId: string | Nil,
  orderId: string,
) =>
  request(
    getAPIPath(`integration/lab/orders/decline/${orderId}`),
    { method: 'PUT', params: { businessId: currentBusinessId, vendorId } },
    true,
  )

export const fetchDevices = (
  businessId: string,
  targetBusinessId: string | Nil,
  idexxRefresh?: boolean,
) =>
  request(
    getAPIPath(`integration/lab/${targetBusinessId || businessId}/devices`),
    { method: 'GET', params: { businessId, force: idexxRefresh } },
    true,
  )

export const fetchLabVendorProviders = (businessId: string) =>
  request(getAPIPath('integration/lab/providers'), {
    method: 'GET',
    params: { businessId },
  })

export const getLabTestResultFrame = (
  businessId: string,
  resultIdentifier: string,
) =>
  request(
    getAPIPath('integration/lab/resultIframeUrl'),
    { method: 'GET', params: { businessId, resultIdentifier } },
    true,
  )
