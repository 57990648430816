import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  initializeChannelId,
  restoreCollaborationSession,
} from '~/store/duck/ckeditor'
import {
  EditorDocumentType,
  FeatureElements,
  PuiCKEditorVariant,
} from '~/types'

import PuiCKEditorClassic from '../PuiCKEditor'
import { isMedicalOrDischargeNoteType } from '../utils/CKEditorUtils'
import { OnInitializeChannelId } from '../utils/useChannelId'
import { useGetEditorConfigByVariant } from '../utils/useGetEditorConfigByVariant'
import { OnRestoreCollaborationSession } from '../utils/useOnEditorError'

export interface PuiCKEditorMedicalDischargeNotesProps {
  disabled: boolean
  hideElements: FeatureElements[] | undefined
  id: string
  noteType: EditorDocumentType.SOAP_DISCHARGE | EditorDocumentType.SOAP_MEDICAL
  onChange: (value: any) => void
  soapId: string | undefined
}

const PuiCKEditorMedicalDischargeNotes = ({
  disabled,
  hideElements,
  id,
  noteType,
  onChange,
  soapId,
}: PuiCKEditorMedicalDischargeNotesProps) => {
  const dispatch = useDispatch()

  const { toolbar, removePlugins } = useGetEditorConfigByVariant(
    PuiCKEditorVariant.MEDICAL_OR_DISCHARGE,
  )

  const onInitializeChannelId = useCallback<OnInitializeChannelId>(
    (editorId, type, bundleType, restore) => {
      if (!soapId) {
        return
      }

      if (isMedicalOrDischargeNoteType(type)) {
        dispatch(
          initializeChannelId(
            type,
            { soapId },
            {
              bundleType,
              editorId,
              restore,
            },
          ),
        )
      }
    },
    [soapId],
  )

  const onRestoreCollaborationSession =
    useCallback<OnRestoreCollaborationSession>(
      (editorId, type, bundleType) => {
        if (!soapId) {
          return
        }

        if (isMedicalOrDischargeNoteType(type)) {
          dispatch(
            restoreCollaborationSession(
              type,
              { soapId },
              {
                bundleType,
                editorId,
              },
            ),
          )
        }
      },
      [soapId],
    )

  return (
    <PuiCKEditorClassic
      withRealTimeCollaboration
      disabled={disabled}
      editorHeight={380}
      hideElements={hideElements}
      id={id}
      noteType={noteType}
      removePlugins={removePlugins}
      toolbar={toolbar}
      onDangerousChange={onChange}
      onInitializeChannelId={onInitializeChannelId}
      onRestoreCollaborationSession={onRestoreCollaborationSession}
    />
  )
}

export default PuiCKEditorMedicalDischargeNotes
