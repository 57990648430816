import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Price, Procedure, TableFilter } from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CLEAR_SELECTED_PROCEDURE_ID,
  CREATE_PRICE,
  CREATE_PRICE_FAILURE,
  CREATE_PRICE_SUCCESS,
  DELETE_PRICE,
  DELETE_PRICE_FAILURE,
  DELETE_PRICE_SUCCESS,
  EDIT_PRICE,
  EDIT_PRICE_FAILURE,
  EDIT_PRICE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_SUCCESS,
  FETCH_PROCEDURE,
  FETCH_PROCEDURE_FAILURE,
  FETCH_PROCEDURE_SUCCESS,
  FETCH_PROCEDURES_LIST,
  FETCH_PROCEDURES_LIST_FAILURE,
  FETCH_PROCEDURES_LIST_SUCCESS,
  RESET_PROCEDURES,
  SET_FILTERS,
  SET_SELECTED_PROCEDURE_ID,
  UPDATE_PROCEDURES,
} from '../actions/types/procedures'
import type { RootState } from '../index'
import { getSpecies } from './constants'

export type ProceduresState = {
  currentProcedureId: string | undefined
  error: string | null
  filters: Record<string, TableFilter>
  isLoading: boolean
  list: string[]
  map: Record<string, Procedure>
  totalCount: number
}

export const INITIAL_STATE: ProceduresState = {
  list: [],
  map: {},
  isLoading: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: {},
  currentProcedureId: undefined,
}

const procedures = (
  state: ProceduresState = INITIAL_STATE,
  action: AnyAction,
): ProceduresState => {
  switch (action.type) {
    case RESET_PROCEDURES:
      return INITIAL_STATE
    case FETCH_PROCEDURES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_PROCEDURES_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_PROCEDURES_LIST:
      return {
        ...state,
        isLoading: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
      }
    case FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_PROCEDURES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_PROCEDURES:
      const updatedProcedures = action.procedures
        ? R.map(
            (p: Procedure) => ({
              ...p,
              priced: p.prices?.some((price) => price.active) ?? p.priced,
            }),
            action.procedures,
          )
        : {}
      return {
        ...state,
        map: R.mergeDeepRight(state.map, updatedProcedures),
      }
    case FETCH_PROCEDURE:
      return { ...state, isLoading: true }
    case FETCH_PROCEDURE_SUCCESS:
      return { ...state, isLoading: false }
    case FETCH_PROCEDURE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_PRICE:
    case EDIT_PRICE:
    case DELETE_PRICE:
      return { ...state, isLoading: true }
    case CREATE_PRICE_SUCCESS:
    case EDIT_PRICE_SUCCESS:
    case DELETE_PRICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.procedure.id]: {
            ...action.procedure,
            priced: Boolean(
              action.procedure.prices?.some((price: Price) => price.active),
            ),
          },
        },
      }
    case CREATE_PRICE_FAILURE:
    case EDIT_PRICE_FAILURE:
    case DELETE_PRICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case SET_SELECTED_PROCEDURE_ID:
      return { ...state, currentProcedureId: action.procedureId }
    case CLEAR_SELECTED_PROCEDURE_ID:
      return { ...state, currentProcedureId: undefined }
    case SET_FILTERS:
      return { ...state, filters: action.filters }
    default:
      return state
  }
}

export default procedures
export const getProcedures = (state: RootState): ProceduresState =>
  state.procedures
export const getProceduresList = (state: RootState) => getProcedures(state).list
export const getProceduresMap = (state: RootState) => getProcedures(state).map
export const getProceduresIsLoading = (state: RootState) =>
  getProcedures(state).isLoading
export const getProceduresTotalCount = (state: RootState) =>
  getProcedures(state).totalCount
export const getProcedure = (id: string | Nil) =>
  createSelector(getProceduresMap, (map) => (id ? map[id] : undefined))
export const getAvailableSpeciesForProcedure = (id: string | Nil) =>
  createSelector(getSpecies, getProcedure(id), (Species, procedure) => {
    const availableSpecies = procedure?.speciesIds?.length
      ? Species.filter((specie) => procedure.speciesIds.includes(specie.id))
      : Species

    return availableSpecies
  })
export const getMultipleProcedures = (ids: string[]) =>
  createSelector(getProceduresMap, R.props(ids))
export const getSelectedProcedureId = (state: RootState) =>
  getProcedures(state).currentProcedureId
export const getProceduresFilters = (state: RootState) =>
  getProcedures(state).filters
