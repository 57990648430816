import { Nil } from '@pbt/pbt-ui-components'

const imageExtensions = {
  PNG: 'png',
  JPEG: 'jpeg',
  JPG: 'jpg',
  TIFF: 'tiff',
  JFIF: 'jfif',
  GIF: 'gif',
  SVG: 'svg',
}

export const PDF = 'pdf'
export const HTML = 'html'

export const fileFormat = {
  UNSUPPORTED_FORMAT: 'UNSUPPORTED_FORMAT',
  IMAGE: 'IMAGE',
  PDF: 'PDF',
  HTML: 'HTML',
}

export const matchImageExtension = (extension: string | Nil) => {
  if (!extension) {
    return false
  }
  return Object.values(imageExtensions).includes(extension.toLowerCase())
}

export const getFileFormat = (extension: string) => {
  if (matchImageExtension(extension)) {
    return fileFormat.IMAGE
  }
  if (extension === PDF) {
    return fileFormat.PDF
  }
  if (extension === HTML) {
    return fileFormat.HTML
  }
  return fileFormat.UNSUPPORTED_FORMAT
}
