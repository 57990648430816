import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextArea,
  useFields,
} from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import { DateAndTimePickerState, DatePickerState } from '~/types'

interface AddDateTimePickerDialogProps extends BasePuiDialogProps {
  documentElement?: DatePickerState | DateAndTimePickerState
  isDateOnlyDialog?: Boolean
  onSave: (data: DatePickerState) => void
}

export const AddDateTimePickerDialog = ({
  open,
  onClose,
  onSave,
  documentElement,
  isDateOnlyDialog,
}: AddDateTimePickerDialogProps) => {
  const { t } = useTranslation('Dialogs')

  const { fields, validate, reset } = useFields([
    {
      name: 'title',
      validators: ['required'],
      label: t('Dialogs:ADD_DATE_TIME_PICKER_DIALOG.TITLE_QUESTION'),
      initialValue: documentElement?.label || '',
    },
  ])

  const { title } = fields

  const handleAddToForm = () => {
    if (validate()) {
      onSave({
        label: title.value,
      })
      reset()
      onClose?.()
    }
  }

  const dialogHeader = isDateOnlyDialog
    ? t('Dialogs:ADD_DATE_TIME_PICKER_DIALOG.USER_CAN_CHOOSE_DATE')
    : t('Dialogs:ADD_DATE_TIME_PICKER_DIALOG.USER_CAN_CHOOSE_DATE_AND_TIME')

  const dialogTitle = isDateOnlyDialog
    ? t('Dialogs:ADD_DATE_TIME_PICKER_DIALOG.ADD_DATE_ENTRY')
    : t('Dialogs:ADD_DATE_TIME_PICKER_DIALOG.ADD_DATE_AND_TIME_ENTRY')

  return (
    <PuiDialog
      fullWidth
      actions={
        <Grid width={150}>
          <ButtonWithLoader fullWidth onClick={handleAddToForm}>
            {t('Dialogs:ADD_DATE_TIME_PICKER_DIALOG.ADD_TO_FORM')}
          </ButtonWithLoader>
        </Grid>
      }
      header={
        <Grid container>
          <Grid item xs={12}>
            <Typography.Paragraph>{dialogHeader}</Typography.Paragraph>
          </Grid>
        </Grid>
      }
      maxWidth="xl"
      open={open}
      title={dialogTitle}
      onClose={onClose}
    >
      <Grid container p={2}>
        <Grid item xs={12}>
          <Typography.H3>{title.label}</Typography.H3>
        </Grid>
        <Grid item xs={12}>
          <PuiTextArea
            field={title}
            minRows={2}
            placeholder={t(
              'Dialogs:ADD_DATE_TIME_PICKER_DIALOG.TYPE_YOUR_TITLE_OR_QUESTION_HERE',
            )}
          />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}
