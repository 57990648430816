import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BaseUser,
  ClassesType,
  CurrencyTextField,
  CustomFieldValidatorState,
  NumberUtils,
  PuiTextField,
  Text,
  useFields,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import PaymentMethodSelect from '~/components/common/inputs/PaymentMethodSelect'
import SelectionField from '~/components/common/inputs/SelectionField'
import { ConfirmAlertType } from '~/constants/DialogNames'
import PaymentTypeEnum from '~/constants/paymentTypes'
import { createPayment } from '~/store/actions/payments'
import { getCurrentUser } from '~/store/reducers/auth'
import { getPaymentMethod, getPaymentType } from '~/store/reducers/constants'
import { getFinanceIsLoading } from '~/store/reducers/finance'
import { UnsavedExtendPayment } from '~/types'
import { handleNumberInput } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import { ManualPaymentActions } from '../ManualPaymentActions'

const useStyles = makeStyles(
  (theme) => ({
    footer: {
      borderTop: theme.constants.tabBorder,
    },
    root: {},
  }),
  { name: 'ManualPayment' },
)

interface ManualPaymentWithCallback {
  onCreateManualPayment: (payment: any) => void
  onOk?: never
}

interface ManualPaymentWithProceed {
  onCreateManualPayment?: never
  onOk: () => void
}

type ManualPaymentCallback =
  | ManualPaymentWithProceed
  | ManualPaymentWithCallback

export type ManualPaymentProps = ManualPaymentCallback & {
  assignedInvoiceId?: string
  classes?: ClassesType<typeof useStyles>
  clientId: string
  invoiceIds: string[]
  isLoading: boolean
  isLoadingSearchResults: boolean
  onBack?: () => void
  onSearchQueryChange: (query: string, userChanged?: boolean) => void
  paymentAmount: number
  paymentTypeName?: PaymentTypeEnum
  searchList: Partial<User>[]
  searchQuery: string
  updatePaymentAmount: (amount: number) => void
}

const ManualPayment = ({
  classes: classesProp,
  clientId,
  paymentAmount,
  onCreateManualPayment,
  updatePaymentAmount,
  paymentTypeName = PaymentTypeEnum.PAYMENT,
  assignedInvoiceId,
  invoiceIds,
  isLoading,
  onBack,
  onOk,
  onSearchQueryChange,
  isLoadingSearchResults,
  searchList,
  searchQuery,
}: ManualPaymentProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const PaymentType = useSelector(getPaymentType)
  const PaymentMethod = useSelector(getPaymentMethod)
  const currentUser = useSelector(getCurrentUser)
  const { t } = useTranslation(['Common', 'Invoices', 'Validations'])

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onOk,
    getFinanceIsLoading,
  )

  const CashPaymentMethod = Utils.findConstantIdByName('Cash', PaymentMethod)
  const DebitCardPaymentMethod = Utils.findConstantIdByName(
    'Debit card',
    PaymentMethod,
  )
  const CreditCardPaymentMethod = Utils.findConstantIdByName(
    'Credit card',
    PaymentMethod,
  )

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.BANK_CARD_WARNING,
  })

  const validateChangeGiven = ({
    state: { changeGiven, cashReceived, method },
  }: CustomFieldValidatorState) =>
    method !== CashPaymentMethod || !changeGiven || changeGiven < cashReceived

  const validateCashReceived = ({
    state: { cashReceived, method },
  }: CustomFieldValidatorState) =>
    method !== CashPaymentMethod || cashReceived > 0

  const {
    fields: { paidBy, method, notes, cashReceived, changeGiven },
    validate,
  } = useFields(
    [
      {
        name: 'paidBy',
        label: t('Common:PAYMENTS.PAID_BY'),
        validators: ['required'],
        initialValue: '',
      },
      {
        name: 'method',
        label: t('Common:PAYMENTS.PAYMENT_METHOD'),
        validators: ['required'],
        type: 'select',
      },
      {
        name: 'notes',
        label: t('Common:PAYMENTS.PAYMENT_NOTES'),
        initialValue: '',
      },
      {
        name: 'cashReceived',
        label: t('Common:PAYMENTS.CASH_RECEIVED'),
        initialValue: 0,
        validators: [
          { validator: validateCashReceived, validatorName: 'cashReceived' },
        ],
        messages: {
          cashReceived: t('Validations:CASH_RECEIVED'),
        },
      },
      {
        name: 'changeGiven',
        label: t('Common:CHANGE_GIVEN'),
        initialValue: 0,
        validators: [
          { validator: validateChangeGiven, validatorName: 'changeGiven' },
        ],
        messages: {
          changeGiven: t('Validations:CHANGE_GIVEN'),
        },
      },
    ],
    false,
  )

  const [paymentProvider, setPaymentProvider] = useState<string | null>(null)
  const [changeDue, setChangeDue] = useState(0)

  const initialPaymentAmount = useMemo(() => paymentAmount, [])

  const isCash = method.value === CashPaymentMethod
  const isBankCard =
    method.value === DebitCardPaymentMethod ||
    method.value === CreditCardPaymentMethod

  const onSelected = (user: BaseUser) => {
    onSearchQueryChange(Utils.getPersonString(user))
    paidBy.setValue(user)
  }

  const recordPayment = () => {
    if (!validate()) {
      return
    }

    const payment = {
      paymentTypeId: Utils.findConstantIdByName(paymentTypeName, PaymentType),
      amount: Number(paymentAmount),
      paymentMethodId: method.value,
      paymentCardTypeId: paymentProvider,
      notes: notes.value,
    } as UnsavedExtendPayment

    if (typeof paidBy.value === 'string') {
      payment.paidByPersonName = paidBy.value
    } else {
      payment.paidByPersonId = paidBy.value.id
    }

    if (assignedInvoiceId) {
      payment.invoiceId = assignedInvoiceId
    }

    if (invoiceIds && !onCreateManualPayment) {
      payment.invoiceIds = invoiceIds
    }

    if (isCash) {
      payment.cashReceived = cashReceived.value
      payment.cashChangeGiven = changeGiven.value
      payment.paymentCardTypeId = null
    }

    if (onCreateManualPayment) {
      onCreateManualPayment(payment)
    } else {
      setCloseAfterCreationOn()
      dispatch(createPayment(clientId, payment))
    }
  }

  const onRecordPaymentButtonClick = () => {
    if (validate()) {
      if (isBankCard) {
        openConfirmAlert({
          applyCustomMessage: true,
          message: t(
            'Invoices:PAYMENTS.MANUAL_PAYMENTS.CONFIRM_DIALOG_MESSAGE',
          ),
          onConfirm: (proceed) => proceed && recordPayment(),
          okButtonText: t('Common:RECORD_ACTION'),
          cancelButtonText: t('Common:GO_BACK'),
        })
      } else {
        recordPayment()
      }
    }
  }

  const handleCashReceivedChange = (value: string) => {
    const formattedValue = parseFloat(value) || 0
    cashReceived.setValue(formattedValue)

    if (formattedValue > initialPaymentAmount) {
      const updatedChangeValue = Number(
        (formattedValue - initialPaymentAmount).toFixed(2),
      )
      updatePaymentAmount(initialPaymentAmount)
      changeGiven.setValue(updatedChangeValue)
      setChangeDue(updatedChangeValue)
    } else {
      updatePaymentAmount(formattedValue)
      changeGiven.setValue(0)
      setChangeDue(0)
    }
  }

  const handleChangeGivenChange = (value: string) => {
    const formattedValue = parseFloat(value) || 0
    changeGiven.setValue(formattedValue)

    const updatedPaymentAmount = cashReceived.value - formattedValue
    if (updatedPaymentAmount > 0) {
      updatePaymentAmount(updatedPaymentAmount)
    } else {
      updatePaymentAmount(0)
    }
  }

  return (
    <>
      <Grid container className={classes.root} direction="column" pb={2} px={3}>
        <Grid item>
          <Text strong mt={1} pl={0.5} variant="body">
            {`${t('Common:TOTAL_CHARGE')}: ${NumberUtils.formatMoney(
              isCash ? initialPaymentAmount : paymentAmount,
            )}`}
          </Text>
        </Grid>
        <Grid item xs={6}>
          <SelectionField
            closeOnItemClick
            hideSearchIcon
            field={{
              ...paidBy,
              setValue: (query) => {
                onSearchQueryChange(query, true)
                paidBy.setValue(query)
              },
            }}
            isLoading={isLoadingSearchResults}
            label={`${paidBy.label}*`}
            list={searchList}
            margin="dense"
            searchQuery={searchQuery}
            onItemClick={onSelected}
          >
            {(person) => (
              <Text noWrap color="secondary" variant="body2">
                {Utils.getPersonString(person)}
              </Text>
            )}
          </SelectionField>
        </Grid>
        {!R.isEmpty(currentUser) && (
          <Grid item xs={6}>
            <Text mt={1} pl={0.5} variant="body">
              {`${t('Common:PAYMENTS.RECORDED_BY')}: ${Utils.getPersonString(
                currentUser,
              )}`}
            </Text>
          </Grid>
        )}
        <Grid item mt={1} xs={6}>
          <PaymentMethodSelect
            field={method}
            label={method.label}
            paymentProvider={paymentProvider}
            onPaymentProviderChange={setPaymentProvider}
          />
        </Grid>
        {isCash && (
          <Grid container maxWidth={300} mt={1.5} rowSpacing={1.5}>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Text pl={0.5} variant="body">
                  {cashReceived.label}:
                </Text>
              </Grid>
              <Grid item width={120}>
                <CurrencyTextField
                  disabled={isLoading}
                  field={{
                    ...cashReceived,
                    set: handleNumberInput(
                      handleCashReceivedChange,
                      7,
                      2,
                      true,
                    ),
                  }}
                  margin="none"
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Text pl={0.5} variant="body">
                  {t('Common:CHANGE_DUE')}:
                </Text>
              </Grid>
              <Grid item width={120}>
                <CurrencyTextField
                  disabled
                  InputProps={{
                    disableUnderline: true,
                  }}
                  margin="none"
                  value={changeDue}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Text pl={0.5} variant="body">
                  {changeGiven.label}:
                </Text>
              </Grid>
              <Grid item width={120}>
                <CurrencyTextField
                  disabled={
                    isLoading ||
                    !cashReceived.value ||
                    paymentAmount > cashReceived.value
                  }
                  field={{
                    ...changeGiven,
                    set: handleNumberInput(handleChangeGivenChange, 7, 2, true),
                  }}
                  margin="none"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item mt={3}>
          <InputLabel htmlFor="payment-notes-input">
            <Text inline strong variant="body2">
              {notes.label}:
            </Text>
          </InputLabel>
          <PuiTextField
            multiline
            field={notes}
            id="payment-notes-input"
            inputProps={{
              maxLength: 1000,
            }}
            margin="none"
            minRows={3}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box
        alignItems="center"
        className={classes.footer}
        display="container"
        flexDirection="row"
        px={3}
        py={2}
      >
        <ManualPaymentActions
          isLoading={isLoading}
          onBack={onBack}
          onRecordPaymentButtonClick={onRecordPaymentButtonClick}
        />
      </Box>
    </>
  )
}

export default ManualPayment
