import React, { forwardRef } from 'react'
import { IconButton, IconButtonProps, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    iconButton: {
      width: 38,
      height: 38,
      padding: 7,
      backgroundColor: theme.colors.iconBackground,
      boxShadow:
        '1px 1px 2px 0 rgba(123,119,119,0.15), 0 1px 3px 0 rgba(123,119,119,0.12)',
    },
    iconButtonSvg: {
      color: theme.colors.iconText,
      fontSize: '2.4rem',
    },
    icon: {},
  }),
  { name: 'PuiIconButton' },
)

export interface PuiIconButtonProps extends IconButtonProps {
  Icon: React.JSXElementConstructor<any>
  IconProps?: React.ComponentProps<any>
  classes?: ClassesType<typeof useStyles>
  isLoading?: boolean
}

const PuiIconButton = forwardRef<HTMLButtonElement, PuiIconButtonProps>(
  function PuiIconButton(
    {
      Icon,
      className,
      isLoading,
      classes: classesProp,
      IconProps = {},
      ...props
    },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })

    if (isLoading) {
      return <Skeleton height={38} variant="circular" width={38} />
    }

    return (
      <IconButton
        className={classNames(classes.iconButton, className)}
        ref={ref}
        size="large"
        {...props}
      >
        <Icon
          className={classNames(classes.iconButtonSvg, classes.icon)}
          {...IconProps}
        />
      </IconButton>
    )
  },
)

export default PuiIconButton
