import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import {
  PermissionArea,
  PuiAlert,
  PuiDialog,
  Question as QuestionType,
  Text,
} from '@pbt/pbt-ui-components'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import {
  clearQuestionValidationError,
  createQuestion,
  deleteQuestion,
  editQuestion,
  fetchQuestion,
} from '~/store/actions/questions'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getQuestion,
  getQuestionsIsDeleting,
  getQuestionsIsFetching,
  getQuestionsIsLoading,
  getQuestionsValidationError,
} from '~/store/reducers/questions'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import PreviewQuestionPopup from './PreviewQuestionPopup'
import Question, { QuestionHandle } from './Question'
import QuestionSettings, { QuestionSettingsHandle } from './QuestionSettings'

const useStyles = makeStyles(
  (theme) => ({
    questionRoot: {
      padding: 0,
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'QuestionDetails' },
)

export interface QuestionDetailsProps {
  itemId?: string
  onClose: () => void
}

const QuestionDetails = ({ itemId, onClose }: QuestionDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])

  const dispatch = useDispatch()
  const isLoading = useSelector(getQuestionsIsLoading)
  const isDeleting = useSelector(getQuestionsIsDeleting)
  const isFetching = useSelector(getQuestionsIsFetching)
  const question = useSelector(getQuestion(itemId))
  const validationError = useSelector(getQuestionsValidationError)
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))

  const setCloseOnDelete = useCloseAfterCreation(
    onClose,
    getQuestionsIsDeleting,
  )

  const [openQuestionDialog] = useDialog(DialogNames.QUESTION)

  const [previewPopupOpen, setPreviewPopupOpen] = useState(false)
  const [confirmQuestionChangeDialogOpen, setConfirmQuestionChangeDialogOpen] =
    useState(false)
  const [questionCandidate, setQuestionCandidate] = useState(question)
  const [isQuestionChanged, setIsQuestionChanged] = useState(false)

  const setCloseAfterCreate = useCloseAfterCreation(() => {
    dispatch(clearQuestionValidationError())
    setConfirmQuestionChangeDialogOpen(false)
  }, getQuestionsIsLoading)

  const setCloseAfterUpdate = useCloseAfterCreation(() => {
    if (!validationError) {
      setConfirmQuestionChangeDialogOpen(false)
    }
  }, getQuestionsIsLoading)

  const questionRef = useRef<QuestionHandle>(null)
  const questionSettingsRef = useRef<QuestionSettingsHandle>(null)

  const createQuestionCandidate = () => ({
    ...questionRef.current?.get(),
    ...questionSettingsRef.current?.get(),
  })

  const revertChangesForCurrentQuestion = () => {
    setQuestionCandidate(question)
  }

  useEffect(() => {
    setQuestionCandidate(question)
    setIsQuestionChanged(false)
  }, [question])

  useEffect(() => {
    if (itemId) {
      dispatch(fetchQuestion(itemId))
    }
  }, [itemId])

  const validate = () => {
    const isQuestionValid = questionRef.current?.validate()
    const isQuestionSettingsValid = questionSettingsRef.current?.validate()

    return isQuestionValid && isQuestionSettingsValid
  }

  const onSaveRequested = () => {
    if (validate()) {
      const candidate = createQuestionCandidate()
      const textChanged = candidate.questionText !== question?.questionText
      const answersChanged = !deepEqual(candidate.answers, question?.answers)
      const hasChanges = textChanged || answersChanged

      setQuestionCandidate(candidate)

      if (hasChanges) {
        dispatch(clearQuestionValidationError())
        setConfirmQuestionChangeDialogOpen(true)
      } else {
        dispatch(editQuestion(candidate))
      }
    }
  }

  const onSaveAsNewRequested = () => {
    if (questionCandidate) {
      setCloseAfterCreate()
      revertChangesForCurrentQuestion()
      dispatch(createQuestion(questionCandidate))
    }
  }

  const onDeleteRequested = () => {
    setCloseOnDelete()

    if (itemId) {
      dispatch(deleteQuestion(itemId))
    }
  }

  const onCloneRequested = () => {
    openQuestionDialog({
      question: {
        ...question,
        name: `${question?.name}_copy`,
      } as QuestionType,
    })
  }

  const onUpdateRequested = () => {
    if (questionCandidate) {
      setCloseAfterUpdate()
      dispatch(editQuestion(questionCandidate))
    }
  }

  const openPreview = () => {
    if (validate()) {
      setQuestionCandidate(createQuestionCandidate())
      setPreviewPopupOpen(true)
    }
  }

  const getUnsavedData = () => {
    const newQuestion = createQuestionCandidate()
    return (
      Boolean(question) &&
      !R.isEmpty(question) &&
      !deepEqual(question, newQuestion)
    )
  }

  const onChange = () => {
    setIsQuestionChanged(getUnsavedData())
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:QUESTION_ONE').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isQuestionChanged}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isLoading}
      showButtons={permissions.update}
      onBack={onClose}
      onCloneRequested={onCloneRequested}
      onDeleteRequested={onDeleteRequested}
      onSaveRequested={onSaveRequested}
    >
      <Grid container item alignItems="center">
        <Text strong variant="subheading3">
          {t('Common:QUESTION_ONE')}
        </Text>
        <Grid item ml={1}>
          <PreviewButton onClick={openPreview} />
        </Grid>
      </Grid>
      <Question
        view
        classes={{
          root: classes.questionRoot,
        }}
        question={questionCandidate}
        ref={questionRef}
        onChange={onChange}
      />
      <QuestionSettings
        view
        classes={{
          root: classes.questionRoot,
        }}
        question={questionCandidate}
        ref={questionSettingsRef}
        onChange={onChange}
      />
      <PreviewQuestionPopup
        Component={PuiDialog}
        open={previewPopupOpen}
        question={questionCandidate}
        onClose={() => setPreviewPopupOpen(false)}
      />
      <PuiAlert
        CancelButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
        OkButtonProps={{
          loading: isLoading,
          disabled: isLoading,
        }}
        cancelButtonText={t('Common:UPDATE_ACTION')}
        message={
          validationError || t('Admin:CATALOG.QUESTION_DETAILS.ALERT_MESSAGE')
        }
        okButtonText={t('Common:SAVE_AS_NEW')}
        open={confirmQuestionChangeDialogOpen}
        onCancel={validationError ? undefined : onUpdateRequested}
        onClose={() => setConfirmQuestionChangeDialogOpen(false)}
        onOk={onSaveAsNewRequested}
      />
    </Expander>
  )
}

export default QuestionDetails
