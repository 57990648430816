import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  InfiniteLoaderList,
  InfiniteLoaderListProps,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import { useInitializeReminders } from '../soapV2/reminders/utils/useInitializeReminders'
import { useReminderErrorAlert } from '../soapV2/reminders/utils/useReminderErrorAlert'
import RemindersListGroup from './RemindersListGroup'
import RemindersListItem from './RemindersListItem'

const useStyles = makeStyles(
  (theme) => ({
    evenItem: {
      backgroundColor: theme.colors.tableEvenItem,
    },
  }),
  { name: 'RemindersListGroup' },
)

export interface RemindersListProps extends Partial<InfiniteLoaderListProps> {
  patientId: string | Nil
}

const RemindersList = ({ patientId, ...rest }: RemindersListProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Reminders')

  const {
    groups,
    groupsList,
    isItemLoaded,
    loadMoreItems,
    remindersList,
    totalCount,
  } = useInitializeReminders(patientId)

  useReminderErrorAlert(patientId)

  const groupCounts = groups.map((group) => group.reminders.length)

  if (totalCount === 0) {
    return (
      <Text align="center" m={2} variant="body2">
        {t('Reminders:REMINDERS_LIST.NO_REMINDERS')}
      </Text>
    )
  }

  return (
    <InfiniteLoaderList
      useGroupedMode
      /* eslint-disable-next-line react/no-unstable-nested-components */
      groupContent={(index: number) => (
        <RemindersListGroup
          className={classNames({
            [classes.evenItem]: index % 2,
          })}
          groupId={groupsList[index]}
        />
      )}
      groupCounts={groupCounts}
      isItemLoaded={isItemLoaded}
      /* eslint-disable-next-line react/no-unstable-nested-components */
      itemContent={(index, groupIndex) => (
        <RemindersListItem
          className={classNames({
            [classes.evenItem]: groupIndex % 2,
          })}
          reminderId={remindersList[index]}
        />
      )}
      itemCount={totalCount}
      itemData={remindersList}
      loadMoreItems={loadMoreItems}
      {...rest}
    />
  )
}

export default RemindersList
