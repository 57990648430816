import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PermissionArea, Text } from '@pbt/pbt-ui-components'

import { useCanSendSmsToClient } from '~/store/hooks/clients'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getUser } from '~/store/reducers/users'

import useGetPreferredContactMethod from './useGetPreferredContactMethod'

const useStyles = makeStyles(
  () => ({
    contact: {
      wordBreak: 'break-word',
    },
    contactClickable: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
  { name: 'PreferredContactMethod' },
)

interface PreferredContactMethodProps {
  clientId: string
  onClick: () => void
}

const PreferredContactMethod = ({
  clientId,
  onClick,
}: PreferredContactMethodProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))
  const { read: canCommunicate } = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )

  const { value, label } = useGetPreferredContactMethod({ client })
  const canSendSmsToClient = useCanSendSmsToClient(clientId)

  if (!value) {
    return null
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid container alignItems="center">
        <Text variant="body2">{`${label}:`}</Text>
        &nbsp;
        <Text
          className={classNames(
            classes.contact,
            canCommunicate && classes.contactClickable,
          )}
          variant="body2"
          onClick={canCommunicate ? onClick : undefined}
        >
          {value}
        </Text>
      </Grid>
      {canSendSmsToClient && client?.preferredContactMethodId && (
        <Text variant="lowAccent3">{t('Common:PREFERRED')}</Text>
      )}
    </Grid>
  )
}

export default PreferredContactMethod
