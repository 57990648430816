import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'

import { fetchClient } from '~/store/actions/clients'
import { getUserByPatient } from '~/store/reducers/users'
import { isNilOrEmpty } from '~/utils'

const PatientPage = () => {
  const { patientId = '' } = useParams()
  const dispatch = useDispatch()

  const client = useSelector(getUserByPatient(patientId, undefined))

  useEffect(() => {
    if (isNilOrEmpty(client) && patientId) {
      dispatch(fetchClient({ clientId: undefined, patientId }))
    }
  }, [patientId])

  return client?.id ? (
    <Navigate replace to={`/client/${client.id}/patient/${patientId}`} />
  ) : null
}

export default PatientPage
