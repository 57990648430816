import i18n from '~/locales/i18n'
import type { Schedule } from '~/types'

export enum SlotType {
  BUSY = 'BUSY',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  APPOINTMENT = 'APPOINTMENT',
}

export const DEFAULT_APPOINTMENT_NAME = 'Test'

export enum TimetableArea {
  SCHEDULER = 'Scheduler',
  WHITEBOARD = 'Whiteboard',
}

export enum SpecialTimetableColumn {
  UNASSIGNED = 'Unassigned',
  BOARDING = 'Boarding',
  GROOMING = 'Grooming',
  CANCELLED_NO_SHOW = 'Cancelled / No show',
  OTC_SALE = 'OTC Sale(s)',
}

export const SpecialTimetableColumnNames: Record<
  SpecialTimetableColumn,
  string
> = {
  [SpecialTimetableColumn.UNASSIGNED]: i18n.t('TimeTable:COLUMNS.UNASSIGNED'),
  [SpecialTimetableColumn.BOARDING]: i18n.t('TimeTable:COLUMNS.BOARDING'),
  [SpecialTimetableColumn.GROOMING]: i18n.t('TimeTable:COLUMNS.GROOMING'),
  [SpecialTimetableColumn.CANCELLED_NO_SHOW]: i18n.t(
    'TimeTable:COLUMNS.CANCELLED_NO_SHOW',
  ),
  [SpecialTimetableColumn.OTC_SALE]: i18n.t(
    'Abbreviations:ACRONYMS.OVER_THE_COUNTER.OTC_SALE_ONE_OR_OTHER',
  ),
}

export enum SchedulerColumnType {
  PERSON = 'PERSON',
  UNASSIGNED = 'UNASSIGNED',
  EVENT_TYPE = 'EVENT_TYPE',
  EVENT_STATE = 'EVENT_STATE',
}

export const SchedulerColumnTypesToProp: Partial<
  Record<SchedulerColumnType, keyof Schedule>
> = {
  [SchedulerColumnType.PERSON]: 'personId',
  [SchedulerColumnType.EVENT_TYPE]: 'eventTypeId',
  [SchedulerColumnType.EVENT_STATE]: 'eventStateId',
}

export enum BoardingScheduleType {
  UNASSIGNED = 'UNASSIGNED',
  Cage = 'Cage',
  Exam = 'Exam room',
  Run = 'Run',
  Surgery = 'Surgery room',
  OTHER = 'OTHER',
  Other = 'Other',
}

export const BoardingScheduleTypeNames: Record<BoardingScheduleType, string> = {
  [BoardingScheduleType.UNASSIGNED]: i18n.t('Common:UNASSIGNED'),
  [BoardingScheduleType.Cage]: i18n.t(
    'TimeTable:BOARDING_SCHEDULE_TYPES.CAGE_TAB',
  ),
  [BoardingScheduleType.Exam]: i18n.t(
    'TimeTable:BOARDING_SCHEDULE_TYPES.EXAM_TAB',
  ),
  [BoardingScheduleType.Run]: i18n.t(
    'TimeTable:BOARDING_SCHEDULE_TYPES.RUN_TAB',
  ),
  [BoardingScheduleType.Surgery]: i18n.t(
    'TimeTable:BOARDING_SCHEDULE_TYPES.SURGERY_ROOM',
  ),
  [BoardingScheduleType.OTHER]: i18n.t('Common:OTHER').toUpperCase(),
  [BoardingScheduleType.Other]: i18n.t('Common:OTHER'),
}
