import { AnyAction } from 'redux'

import { Filter } from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  CLEAR_CLIENTS_ERROR,
  CLEAR_CURRENT_CLIENT_INFO,
  CLIENT_PATIENT_CREATED,
  CREATE_CLIENT,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_SUCCESS,
  FETCH_CLIENT,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENTS_LIST,
  FETCH_CLIENTS_LIST_FAILURE,
  FETCH_CLIENTS_LIST_SUCCESS,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS_FAILURE,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_SUCCESS,
  FIND_CLIENTS_SUCCESS,
  PARTIAL_UPDATE_CLIENT,
  SEND_CLIENT_EMAIL_VERIFICATION,
  SEND_CLIENT_EMAIL_VERIFICATION_FAILURE,
  SEND_CLIENT_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_ACTIVITY_STATE,
  UPDATE_CLIENT_ACTIVITY_STATE_FAILURE,
  UPDATE_CLIENT_ACTIVITY_STATE_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  VALIDATE_CLIENT_EMAIL,
  VALIDATE_CLIENT_EMAIL_FAILURE,
  VALIDATE_CLIENT_EMAIL_SUCCESS,
} from '../actions/types/clients'
import type { RootState } from '../index'

export type ClientsState = {
  currentClientId: string | null
  duplicateClients: string[]
  error: string | null
  errorStatus: number | null
  hasNoClients: boolean
  isCreating: boolean
  isEmailVerifying: boolean
  isFetchingFilters: boolean
  isFetchingList: boolean
  isLoading: boolean
  isSearching: boolean
  isUpdatingClientActivityState: boolean
  list: string[]
  membershipFilters: Filter[]
  totalCount: number
}

export const INITIAL_STATE: ClientsState = {
  list: [],
  isLoading: false,
  isFetchingList: false,
  isCreating: false,
  isFetchingFilters: false,
  isEmailVerifying: false,
  error: null,
  errorStatus: null,
  currentClientId: null,
  totalCount: 0,
  hasNoClients: false,
  isSearching: false,
  isUpdatingClientActivityState: false,
  membershipFilters: [],
  duplicateClients: [],
}

const clients = (
  state: ClientsState = INITIAL_STATE,
  action: AnyAction,
): ClientsState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return INITIAL_STATE
    case FETCH_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetchingList: false,
        hasNoClients: false,
        isSearching: false,
      }
    case FETCH_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        list: action.list,
        isLoading: false,
        isFetchingList: false,
        totalCount: action.totalCount,
        hasNoClients: action.list.length === 0,
      }
    case FETCH_CLIENTS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetchingList: true,
        list: [],
        hasNoClients: false,
        isSearching: Boolean(action.query || action.fieldsQuery),
      }
    case FETCH_MORE_ITEMS_FOR_CLIENTS_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case CREATE_CLIENT:
      return {
        ...state,
        isLoading: true,
        isCreating: true,
        error: null,
        errorStatus: null,
      }
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        currentClientId: action.clientId,
        error: null,
        errorStatus: null,
      }
    case CREATE_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        error: getErrorMessage(action.error),
        errorStatus: action.error?.status,
      }
    case CLIENT_PATIENT_CREATED:
      return {
        ...state,
        currentClientId: action.clientId,
      }
    case CLEAR_CURRENT_CLIENT_INFO:
      return {
        ...state,
        currentClientId: null,
      }
    case UPDATE_CLIENT_FAILURE:
    case FETCH_CLIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
        errorStatus: action.error?.status,
      }
    case FIND_CLIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        duplicateClients: action.duplicateClients,
      }
    case UPDATE_CLIENT:
    case PARTIAL_UPDATE_CLIENT:
    case FETCH_CLIENT:
      return { ...state, isLoading: true }
    case UPDATE_CLIENT_SUCCESS:
    case FETCH_CLIENT_SUCCESS:
      return { ...state, isLoading: false }
    case FETCH_CLIENTS_MEMBERSHIP_FILTERS:
      return { ...state, isLoading: true, isFetchingFilters: true }
    case FETCH_CLIENTS_MEMBERSHIP_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetchingFilters: false,
        membershipFilters: action.filters,
      }
    case FETCH_CLIENTS_MEMBERSHIP_FILTERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetchingFilters: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_CLIENT_ACTIVITY_STATE:
      return { ...state, isLoading: true, isUpdatingClientActivityState: true }
    case UPDATE_CLIENT_ACTIVITY_STATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdatingClientActivityState: false,
      }
    case UPDATE_CLIENT_ACTIVITY_STATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdatingClientActivityState: false,
        error: getErrorMessage(action.error),
      }
    case VALIDATE_CLIENT_EMAIL:
      return { ...state, isEmailVerifying: true, error: null }
    case VALIDATE_CLIENT_EMAIL_SUCCESS:
      return { ...state, isEmailVerifying: false }
    case VALIDATE_CLIENT_EMAIL_FAILURE:
      return {
        ...state,
        isEmailVerifying: false,
        error: getErrorMessage(action.error),
      }
    case SEND_CLIENT_EMAIL_VERIFICATION:
      return { ...state, isEmailVerifying: true, error: null }
    case SEND_CLIENT_EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        isEmailVerifying: false,
        error: getErrorMessage(action.error),
      }
    case SEND_CLIENT_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, isEmailVerifying: false }
    case CLEAR_CLIENTS_ERROR:
      return { ...state, error: null }
    default:
      return state
  }
}

export default clients
export const getClients = (state: RootState): ClientsState => state.clients
export const getClientsError = (state: RootState) => getClients(state).error
export const getClientsErrorStatus = (state: RootState) =>
  getClients(state).errorStatus
export const getClientIsLoading = (state: RootState) =>
  getClients(state).isLoading
export const getClientIsFetchingList = (state: RootState) =>
  getClients(state).isFetchingList
export const getClientIsCreating = (state: RootState) =>
  getClients(state).isCreating
export const getClientIsEmailVerifying = (state: RootState) =>
  getClients(state).isEmailVerifying
export const getClientsList = (state: RootState) => getClients(state).list
export const getCurrentClientId = (state: RootState) =>
  getClients(state).currentClientId
export const getTotalClientCount = (state: RootState) =>
  getClients(state).totalCount
export const getHasNoClients = (state: RootState) =>
  getClients(state).hasNoClients
export const getClientIsSearching = (state: RootState) =>
  getClients(state).isSearching
export const getClientsMembershipFilters = (state: RootState) =>
  getClients(state).membershipFilters
export const getClientsIsFetchingFilters = (state: RootState) =>
  getClients(state).isFetchingFilters
export const getClientsMembershipFiltersListItems = (state: RootState) =>
  getClients(state).membershipFilters.map((item: Filter) => ({
    ...item,
    id: `${item.param}=${item.value}`,
  }))
export const getIsUpdatingClientActivityState = (state: RootState) =>
  getClients(state).isUpdatingClientActivityState
export const getDuplicateClients = (state: RootState) =>
  getClients(state).duplicateClients
