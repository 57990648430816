import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, ButtonWithLoaderProps } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    button: {
      height: 40,
      minWidth: 100,
      marginRight: 10,
    },
  }),
  { name: 'MigrationStepActionButton' },
)

const MigrationStepActionButton = ({
  children,
  ...props
}: React.PropsWithChildren<ButtonWithLoaderProps>) => {
  const classes = useStyles()

  return (
    <ButtonWithLoader className={classes.button} {...props}>
      {children}
    </ButtonWithLoader>
  )
}

export default MigrationStepActionButton
