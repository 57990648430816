import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import {
  createProblemLogEnumValue,
  deleteProblemLogEnumValue,
} from '~/store/actions/problems'
import {
  getIsEnumLogsLoading,
  getProblemEnums,
} from '~/store/reducers/problems'
import { ProblemEnumReference, ProblemLogProblemContainer } from '~/types'
import { findEnumLog } from '~/utils/problems'
import usePrevious from '~/utils/usePrevious'

const useStyles = makeStyles(
  (theme) => ({
    nestedIndent: {
      width: theme.spacing(4),
    },
  }),
  { name: 'IdentifiedProblemDetailsEnumsBranch' },
)

interface IdentifiedProblemDetailsEnumsBranchProps {
  parentEnumId?: string
  parentEnumValueId?: string
  problemEnumRefs: ProblemEnumReference[]
  problemLog: ProblemLogProblemContainer
}

const IdentifiedProblemDetailsEnumsBranch = ({
  problemEnumRefs,
  parentEnumId,
  parentEnumValueId,
  problemLog,
}: IdentifiedProblemDetailsEnumsBranchProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [pendingEnumValueId, setPendingEnumValueId] = useState<string | null>(
    null,
  )
  const problemEnums = useSelector(
    getProblemEnums(problemEnumRefs, parentEnumId),
  )
  const isEnumLogsLoading = useSelector(getIsEnumLogsLoading)
  const prevIsEnumLogsLoading = usePrevious(isEnumLogsLoading)

  useEffect(() => {
    if (prevIsEnumLogsLoading && !isEnumLogsLoading) {
      setPendingEnumValueId(null)
    }
  }, [isEnumLogsLoading, prevIsEnumLogsLoading])

  const addEnumValue = (enumId: string, enumValueId: string) => {
    dispatch(
      createProblemLogEnumValue(problemLog.entity.id, {
        problemLogId: problemLog.entity.id,
        enumId,
        enumValueId,
        notes: '',
        parentEnumValueId: parentEnumValueId ?? null,
      }),
    )
  }

  const deleteEnumValue = (
    problemLogId: string,
    problemEnumLogId: string,
    crc: number,
  ) => {
    dispatch(deleteProblemLogEnumValue(problemLogId, problemEnumLogId, crc))
  }

  if (!problemEnums || problemEnums.length === 0) {
    return null
  }

  return (
    <>
      {problemEnums.map((problemEnum) => (
        <Grid key={problemEnum.id}>
          <Text key={problemEnum.id} variant="lowAccent2">
            {LanguageUtils.getTranslatedFieldName(problemEnum)}
          </Text>
          {problemEnum.values.map((problemEnumValue) => {
            const enumLog = findEnumLog({
              problemLog,
              problemEnum,
              problemEnumValue,
              parentEnumValueId,
            })

            const isPendingValue =
              isEnumLogsLoading && problemEnumValue.id === pendingEnumValueId
            const isCheckedValue = isPendingValue ? !enumLog : Boolean(enumLog)

            const handleSelectionChange = (
              event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              if (isEnumLogsLoading) {
                return
              }

              setPendingEnumValueId(problemEnumValue.id)

              if (event.target.checked) {
                addEnumValue(problemEnum.id, problemEnumValue.id)
              } else {
                deleteEnumValue(
                  problemLog.entity.id,
                  enumLog!.entity.id,
                  enumLog!.crc,
                )
              }
            }

            return (
              <Fragment key={problemEnumValue.id}>
                <Grid alignItems="center" display="flex">
                  <Checkbox
                    checked={isCheckedValue}
                    disabled={isPendingValue}
                    sx={{ paddingLeft: 0 }}
                    onChange={handleSelectionChange}
                  />
                  <Text>
                    {LanguageUtils.getTranslatedFieldName(problemEnumValue)}
                  </Text>
                  {isPendingValue && (
                    <CircularProgress size={16} sx={{ marginLeft: 1 }} />
                  )}
                </Grid>
                {enumLog && !isPendingValue && (
                  <>
                    <Grid container>
                      <Grid item className={classes.nestedIndent} />
                      <Grid item flex={1}>
                        <IdentifiedProblemDetailsEnumsBranch
                          parentEnumId={problemEnum.id}
                          parentEnumValueId={problemEnumValue.id}
                          problemEnumRefs={problemEnumRefs}
                          problemLog={problemLog}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Fragment>
            )
          })}
        </Grid>
      ))}
    </>
  )
}

export default IdentifiedProblemDetailsEnumsBranch
