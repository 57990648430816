export enum BenchmarkingColumnsForSorting {
  PRACTICE_NAME = 'PRACTICE_NAME',
  CONTACT_NAME = 'CONTACT_NAME',
}

export enum BenchmarkingKeyType {
  BENCHMARKING_LITE = 'BENCHMARKING_LITE',
}

export enum BenchmarkingKeyStatus {
  NOT_USED = 'NOT_USED',
  REGISTRATION_IN_PROGRESS = 'REGISTRATION_IN_PROGRESS',
  REGISTERED = 'REGISTERED',
}
