export const SET_CHAT_UNREAD_COUNT = 'chat/SET_CHAT_UNREAD_COUNT'

export const OAUTH_LOGIN_ROCKET_CHAT = 'chat/OAUTH_LOGIN_ROCKET_CHAT'
export const OAUTH_LOGIN_ROCKET_CHAT_SUCCESS =
  'chat/OAUTH_LOGIN_ROCKET_CHAT_SUCCESS'
export const OAUTH_LOGIN_ROCKET_CHAT_FAILURE =
  'chat/OAUTH_LOGIN_ROCKET_CHAT_FAILURE'

export const FETCH_CHAT_RECONCILATION_INFO =
  'chat/FETCH_CHAT_RECONCILATION_INFO'
export const FETCH_CHAT_RECONCILATION_INFO_SUCCESS =
  'chat/FETCH_CHAT_RECONCILATION_INFO_SUCCESS'
export const FETCH_CHAT_RECONCILATION_INFO_FAILURE =
  'chat/FETCH_CHAT_RECONCILATION_INFO_FAILURE'

export const START_CHAT_RECONCILATION = 'chat/START_CHAT_RECONCILATION'
export const START_CHAT_RECONCILATION_SUCCESS =
  'chat/START_CHAT_RECONCILATION_SUCCESS'
export const START_CHAT_RECONCILATION_FAILURE =
  'chat/START_CHAT_RECONCILATION_FAILURE'

export const FETCH_BUSINESS_CHAT_ONBOARD_INFO =
  'chat/FETCH_BUSINESS_CHAT_ONBOARD_INFO'
export const FETCH_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS =
  'chat/FETCH_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS'
export const FETCH_BUSINESS_CHAT_ONBOARD_INFO_FAILURE =
  'chat/FETCH_BUSINESS_CHAT_ONBOARD_INFO_FAILURE'

export const PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO =
  'chat/PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO'
export const PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS =
  'chat/PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_SUCCESS'
export const PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_FAILURE =
  'chat/PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO_FAILURE'
