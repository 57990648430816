import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Collapse, FormControl, Grid, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PermissionArea,
  PuiSelect,
  PuiTextField,
  Text,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'
import { CustomFieldValidator } from '@pbt/pbt-ui-components/src/types/validation'

import NumericInput from '~/components/common/inputs/NumericInput'
import ManualInputSelect from '~/components/common/ManualInputSelect'
import { usePrescriptionAdminInstructions } from '~/store/hooks/orders'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getDrugAdministrationFrequencies,
  getDrugDeliveryMethod,
  getPrescriptionProductSizeUnit,
} from '~/store/reducers/constants'
import {
  DataHandle,
  Prescription,
  PrescriptionAdministrationInfo,
} from '~/types'
import { handleNumberInput, isOtherUnitSelected } from '~/utils'
import { htmlDecode } from '~/utils/htmlUtils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useFieldsChanged from '~/utils/useFieldsChanged'

import AdministrationInstructions from './AdministrationInstructions'

const useStyles = makeStyles(
  (theme) => ({
    quantityInput: {
      maxWidth: 74,
      width: 74,
    },
    quantity: {
      width: 140,
    },
    orderSection: {
      border: theme.constants.tableBorderSelected,
      borderRadius: 2,
    },
  }),
  { name: 'AdministrationSection' },
)

export interface AdministrationSectionProps {
  isChewy?: boolean
  isOrder?: boolean
  isRxPrescription?: boolean
  isVetDiet?: boolean
  onActiveAdminFrequencyChange?: (value: string) => void
  onRefChange?: () => void
  prescription: Prescription
  simplified?: boolean
}

export interface AdministrationSectionHandle
  extends DataHandle<PrescriptionAdministrationInfo> {}

const AdministrationSection = forwardRef<
  AdministrationSectionHandle,
  AdministrationSectionProps
>(function AdministrationSection(
  {
    isChewy = false,
    isOrder = false,
    isRxPrescription = false,
    isVetDiet = false,
    onActiveAdminFrequencyChange,
    onRefChange,
    prescription,
    simplified,
  },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Common'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )

  const getAutofillInitialValue = () => {
    if (isRxPrescription) {
      return true
    }
    if (isOrder) {
      return false
    }
    return prescription.administrationInstructionsAutofill || false
  }

  const quantityCustomUnitFieldValidator: CustomFieldValidator['validator'] = ({
    state,
    value,
  }) => {
    const isOtherUnit = isOtherUnitSelected(
      { value: state.administrationQuantityUnitId },
      PrescriptionProductSizeUnit,
    )
    return !isOtherUnit || Validators.notEmptyFormField(value)
  }

  const mainFieldsRequired = isRxPrescription
  const mainFieldsLabelPostfix = mainFieldsRequired ? '*' : ''
  const instructionsRequired = isVetDiet

  const {
    fields: {
      administrationQuantity,
      administrationQuantityUnitId,
      administrationQuantityCustomUnit,
      administrationRouteId,
      administrationFrequencyId,
      administrationFrequencyNotes,
      notes,
      administrationInstructionsAutofill,
      totalQuantity,
    },
    reset,
    validate,
  } = useFields(
    [
      {
        name: 'administrationInstructionsAutofill',
        initialValue: getAutofillInitialValue(),
      },
      {
        name: 'administrationQuantity',
        label: `${t('Abbreviations:COMMON.QUANTITY')}${mainFieldsLabelPostfix}`,
        initialValue: prescription.administrationQuantity || '',
        ...(mainFieldsRequired ? { validators: ['required'] } : {}),
      },
      {
        name: 'administrationQuantityUnitId',
        label: `${t('Common:UNIT')}${mainFieldsLabelPostfix}`,
        type: 'select',
        initialValue: prescription.administrationQuantityUnitId,
        ...(mainFieldsRequired ? { validators: ['required'] } : {}),
      },
      {
        name: 'administrationQuantityCustomUnit',
        initialValue: prescription.administrationQuantityCustomUnit || '',
        ...(mainFieldsRequired
          ? {
              validators: [
                {
                  validator: quantityCustomUnitFieldValidator,
                  validatorName: 'required',
                },
              ],
            }
          : {}),
      },
      {
        name: 'administrationRouteId',
        label: `${t(
          'Common:ROUTE_OF_DRUG_ADMINISTRATION',
        )}${mainFieldsLabelPostfix}`,
        type: 'select',
        initialValue: prescription.administrationRouteId,
        ...(mainFieldsRequired ? { validators: ['required'] } : {}),
      },
      {
        name: 'administrationFrequencyId',
        label: `${t('Common:FREQUENCY_ONE')}${mainFieldsLabelPostfix}`,
        initialValue: prescription.administrationFrequencyId,
        ...(mainFieldsRequired ? { validators: ['required'] } : {}),
      },
      {
        name: 'administrationFrequencyNotes',
        initialValue: prescription.administrationFrequencyNotes,
      },
      {
        name: 'notes',
        label: `${t(
          'Dialogs:PRESCRIPTION_DIALOG.ADMINISTRATION_INSTRUCTIONS',
        )}${instructionsRequired ? '*' : ''}`,
        initialValue: htmlDecode(prescription.notes || ''),
        ...(instructionsRequired ? { validators: ['required'] } : {}),
      },
      {
        name: 'totalQuantity',
        label: t('Common:TOTAL_QUANTITY'),
        initialValue: prescription.totalQuantity,
      },
    ],
    false,
  )

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [
    administrationQuantity,
    administrationQuantityUnitId,
    administrationQuantityCustomUnit,
    administrationRouteId,
    administrationFrequencyId,
    administrationFrequencyNotes,
    notes,
    administrationInstructionsAutofill,
    totalQuantity,
  ])

  const newInstructions = usePrescriptionAdminInstructions({
    administrationQuantity: administrationQuantity?.value,
    administrationRouteId: administrationRouteId?.value,
    administrationFrequencyId: administrationFrequencyId?.value,
    administrationFrequencyNotes: administrationFrequencyNotes?.value,
    administrationQuantityUnitId: administrationQuantityUnitId?.value,
    administrationQuantityCustomUnit: administrationQuantityCustomUnit?.value,
    variation: prescription.variation,
  })

  useEffectExceptOnMount(() => {
    if (onActiveAdminFrequencyChange) {
      onActiveAdminFrequencyChange(administrationFrequencyId.value)
    }
  }, [administrationFrequencyId.value])

  useEffectExceptOnMount(() => {
    reset()
  }, [prescription])

  useImperativeHandle(ref, () => ({
    get: () => ({
      ...(!simplified || isChewy
        ? {
            administrationQuantity:
              administrationQuantity.value &&
              Number(administrationQuantity.value),
            administrationQuantityUnitId: administrationQuantityUnitId.value,
            administrationQuantityCustomUnit:
              administrationQuantityCustomUnit.value,
            administrationRouteId: administrationRouteId.value,
            administrationFrequencyId: administrationFrequencyId.value,
            administrationFrequencyNotes: administrationFrequencyNotes.value,
          }
        : {}),
      notes: notes.value,
      ...(!isOrder
        ? {
            administrationInstructionsAutofill:
              administrationInstructionsAutofill.value && Boolean(notes.value),
          }
        : {}),
      ...(isOrder && !simplified ? { totalQuantity: totalQuantity.value } : {}),
    }),
    validate,
  }))

  const administrationFrequencyNotesIsVisible =
    Utils.getConstantName(
      administrationFrequencyId.value,
      DrugAdministrationFrequencies,
    ) === 'Other'

  const updateInstructions = () => {
    if (administrationInstructionsAutofill?.value && !isOrder) {
      notes.setValue(newInstructions)
    }
  }

  useEffectExceptOnMount(updateInstructions, [prescription?.variation?.id])

  useEffect(() => {
    if (!simplified) {
      updateInstructions()
    }
  }, [administrationInstructionsAutofill.value])

  const onFieldsChanged = () => {
    if (!simplified) {
      updateInstructions()
    }
  }

  useFieldsChanged(onFieldsChanged, {
    administrationQuantity,
    administrationRouteId,
    administrationFrequencyId,
    administrationFrequencyNotes,
    administrationQuantityUnitId,
    administrationQuantityCustomUnit,
  })

  return (
    <Grid container>
      <Collapse in={!simplified} style={{ width: '100%' }}>
        <Grid
          container
          className={isOrder ? classes.orderSection : undefined}
          mb={isOrder ? 3 : 0}
          pb={isOrder ? 2 : 0}
          pt={isOrder ? 1 : 0}
          px={isOrder ? 2 : 0}
        >
          {!isChewy && (
            <Text strong mb={1} mt={0.5} variant="body2">
              {t('Common:ADMINISTRATION')}
            </Text>
          )}

          <Grid
            container
            item
            alignItems="flex-end"
            columnSpacing={3}
            md={12}
            wrap="nowrap"
          >
            <Grid item>
              <PuiTextField
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.quantityInput}
                disabled={!permissions.update}
                field={{
                  ...administrationQuantity,
                  set: handleNumberInput(
                    administrationQuantity.setValue,
                    10,
                    4,
                    true,
                  ),
                }}
                inputProps={{ maxLength: 10 }}
                label={administrationQuantity.label}
                margin="none"
              />
            </Grid>
            <ManualInputSelect
              disabled={!permissions.update}
              fields={{
                unitId: administrationQuantityUnitId,
                customUnit: administrationQuantityCustomUnit,
              }}
              label={administrationQuantityUnitId.label}
              options={PrescriptionProductSizeUnit}
            />
          </Grid>

          <Grid container alignItems="flex-end" spacing={3}>
            <Grid item md={administrationFrequencyNotesIsVisible ? 3 : 8}>
              <FormControl fullWidth margin="none">
                <InputLabel htmlFor="route-select">
                  {administrationRouteId.label}
                </InputLabel>
                <PuiSelect
                  disabled={!permissions.update}
                  field={administrationRouteId}
                  input={<Input id="route-select" />}
                  items={DrugDeliveryMethod}
                />
              </FormControl>
            </Grid>

            <ManualInputSelect
              disabled={!permissions.update}
              fields={{
                unitId: administrationFrequencyId,
                customUnit: administrationFrequencyNotes,
              }}
              label={administrationFrequencyId.label}
              options={DrugAdministrationFrequencies}
              unitGridWidth={4}
            />
          </Grid>
          {isOrder && (
            <Grid container alignItems="flex-end">
              <NumericInput
                allowDecimal
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.quantity}
                disabled={!permissions.update}
                field={{
                  ...totalQuantity,
                  setValue: totalQuantity.setValue,
                }}
                inputProps={{ maxLength: 10 }}
                label={totalQuantity.label}
                margin="none"
                min={0.001}
              />
            </Grid>
          )}
        </Grid>
      </Collapse>

      <Grid container mt={simplified ? 0 : 2}>
        {isOrder ? (
          <PuiTextField
            multiline
            disabled={!permissions.update}
            field={notes}
            inputProps={{ maxLength: 1000 }}
            margin="none"
            minRows={2}
            placeholder={notes.label}
            variant="outlined"
          />
        ) : (
          <AdministrationInstructions
            autofillField={administrationInstructionsAutofill}
            disabled={!permissions.update}
            instructionsField={notes}
            instructionsRequired={instructionsRequired}
            isChewy={isChewy}
            simplified={simplified}
          />
        )}
      </Grid>
    </Grid>
  )
})

export const copyAdministrationInstructions = ({
  administrationFrequencyId,
  administrationFrequencyNotes,
  administrationInstructionsAutofill,
  administrationQuantity,
  administrationQuantityCustomUnit,
  administrationQuantityUnitId,
  administrationRouteId,
  notes,
}: Prescription) => ({
  administrationFrequencyId,
  administrationFrequencyNotes,
  administrationInstructionsAutofill,
  administrationQuantity,
  administrationQuantityCustomUnit,
  administrationQuantityUnitId,
  administrationRouteId,
  notes,
})

export default AdministrationSection
