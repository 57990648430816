import React from 'react'
import { InputAdornment, StandardTextFieldProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType, Field } from '@pbt/pbt-ui-components'

import NumericInput, { NumericInputProps } from './NumericInput'

const useStyles = makeStyles(
  () => ({
    root: {
      width: 61,
    },
    adornment: {},
  }),
  { name: 'PercentTextField' },
)

export interface PercentTextFieldProps
  extends Omit<NumericInputProps, 'onChange'> {
  InputProps?: StandardTextFieldProps['InputProps']
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  field?: Field
  fullWidth?: boolean
  highLimit?: number
  inputRegex?: RegExp
  lowLimit?: number
  onChange?: (value: number) => void
  placeholder?: string
  value?: number | ''
}

const PercentTextField = ({
  InputProps,
  onChange: initialOnChange,
  value,
  field,
  placeholder = '##',
  highLimit = 100,
  lowLimit = 0,
  classes: classesProp,
  fullWidth,
  className,
  disabled,
  inputRegex = /.*/,
  ...rest
}: PercentTextFieldProps) => {
  const classes = useStyles({ classes: classesProp })
  const onChange = field?.setValue ?? initialOnChange

  const onInputChange = (event: any) => {
    const newValue = Number(event)
    if (
      inputRegex.test(newValue.toString()) &&
      onChange &&
      newValue >= lowLimit &&
      newValue <= highLimit
    ) {
      onChange(newValue)
    } else {
      event.preventDefault()
    }
  }

  return (
    <NumericInput
      allowDecimal
      InputProps={{
        ...InputProps,
        endAdornment: ((!R.isNil(field?.value) && field?.value !== '') ||
          !R.isNil(value) ||
          placeholder !== '') && (
          <InputAdornment className={classes.adornment} position="end">
            %
          </InputAdornment>
        ),
      }}
      className={classNames(
        {
          [classes.root]: !fullWidth,
        },
        className,
      )}
      disabled={disabled}
      field={field}
      fullWidth={fullWidth}
      max={highLimit}
      min={lowLimit}
      placeholder={placeholder}
      value={value}
      onChange={onInputChange}
      {...rest}
    />
  )
}

export default PercentTextField
