import i18n from '~/locales/i18n'

export const ChargeSheetEntityTypes = {
  SOAP: i18n.t('Common:ADD_ITEM_TO_OPEN_SOAP'),
  OTC: i18n.t('Common:ADD_ITEM_TO_OTC'),
}

export const INVOICE_CHARGE_SHEET_TYPE: string = 'ChargeSheet'

export const ChargeSheetNameToDisplay = {
  [INVOICE_CHARGE_SHEET_TYPE]: i18n.t('Common:CHARGE_SHEET'),
}
