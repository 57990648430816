import { all, put, select, takeEvery } from 'redux-saga/effects'
import { ApiError, CountryCode } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import FeatureToggle from '~/constants/featureToggle'

import {
  fetchGlobalItem,
  fetchGlobalItemFailure,
  fetchGlobalItemSuccess,
  fetchGlobalList,
  fetchGlobalListFailure,
  fetchGlobalListSuccess,
  fetchMoreGlobalList,
  fetchMoreGlobalListFailure,
  fetchMoreGlobalListSuccess,
  updateGlobalItems,
} from '../actions/globalInventoryCatalog'
import {
  FETCH_GLOBAL_ITEM,
  FETCH_GLOBAL_LIST,
  FETCH_MORE_GLOBAL_LIST,
} from '../actions/types/globaInventoryCatalog'
import { getCurrentBusinessCountryCatalogCode } from '../reducers/auth'
import { getFeatureToggle } from '../reducers/constants'
import requestAPI from './utils/requestAPI'

export function* fetchGlobalListSaga({
  from,
  to,
  query,
  categories,
}: ReturnType<typeof fetchGlobalList>) {
  try {
    const countryCode: CountryCode = yield select(
      getCurrentBusinessCountryCatalogCode,
    )
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const {
      result: { data: list, totalCount },
      entities: { globalInventoryItems },
    } = yield* requestAPI(
      API.fetchGlobalInventoryCatalog,
      categories,
      countryCode,
      query,
      from,
      to,
      isFoodCatalogEnabled,
    )
    yield put(updateGlobalItems(globalInventoryItems))
    yield put(fetchGlobalListSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchGlobalListFailure(error as ApiError))
  }
}

export function* fetchMoreGlobalListSaga({
  categories,
  query,
  from,
  to,
}: ReturnType<typeof fetchMoreGlobalList>) {
  try {
    const countryCode: CountryCode = yield select(
      getCurrentBusinessCountryCatalogCode,
    )
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )
    const {
      result: { data: list, totalCount },
      entities: { globalInventoryItems },
    } = yield* requestAPI(
      API.fetchGlobalInventoryCatalog,
      categories,
      countryCode,
      query,
      from,
      to,
      isFoodCatalogEnabled,
    )
    yield put(updateGlobalItems(globalInventoryItems))
    yield put(fetchMoreGlobalListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreGlobalListFailure(error as ApiError))
  }
}

export function* fetchGlobalItemSaga({
  id,
}: ReturnType<typeof fetchGlobalItem>) {
  try {
    const isFoodCatalogEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.FOOD_CATALOG),
    )

    const {
      entities: { globalInventoryItems },
    } = yield* requestAPI(
      isFoodCatalogEnabled ? API.fetchGlobalItemV2 : API.fetchGlobalItem,
      id,
    )
    yield put(updateGlobalItems(globalInventoryItems))
    yield put(fetchGlobalItemSuccess())
  } catch (error) {
    yield put(fetchGlobalItemFailure(error as ApiError))
  }
}

function* watchFetchGlobalList() {
  yield takeEvery(FETCH_GLOBAL_LIST, fetchGlobalListSaga)
}

function* watchMoreFetchGlobalList() {
  yield takeEvery(FETCH_MORE_GLOBAL_LIST, fetchMoreGlobalListSaga)
}

function* watchFetchGlobalItem() {
  yield takeEvery(FETCH_GLOBAL_ITEM, fetchGlobalItemSaga)
}

export default function* globalInventoryCatalogSaga() {
  yield all([
    watchFetchGlobalList(),
    watchMoreFetchGlobalList(),
    watchFetchGlobalItem(),
  ])
}
