import { AnyAction } from 'redux'
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ConversationTransport } from '~/api/graphql/generated/types'
import { EmailEntityConfig } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'
import updateEntities from '../sagas/utils/updateEntities'

export const FETCH_EMAIL_APPOINTMENT_CONFIG =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIG'
export const FETCH_EMAIL_APPOINTMENT_CONFIG_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIG_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_CONFIG_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIG_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG'
export const FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_TEMPLATE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_TEMPLATE'
export const FETCH_EMAIL_APPOINTMENT_TEMPLATE_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_TEMPLATE_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_TEMPLATE_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_FAILURE'

export const FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE'
export const FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_SUCCESS =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_FAILURE =
  'emailAppointment/FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_FAILURE'

export const CLEAR_EMAIL_APPOINTMENT_TEMPLATE =
  'emailAppointment/CLEAR_EMAIL_APPOINTMENT_TEMPLATE'
export const CLEAR_EMAIL_APPOINTMENT_CONFIG =
  'emailAppointment/CLEAR_EMAIL_APPOINTMENT_CONFIG'
export const CLEAR_EMAIL_APPOINTMENT_DUMMY_TEMPLATE =
  'emailAppointment/CLEAR_EMAIL_APPOINTMENT_DUMMY_TEMPLATE'
export const CLEAR_EMAIL_APPOINTMENT_DUMMY_CONFIG =
  'emailAppointment/CLEAR_EMAIL_APPOINTMENT_DUMMY_CONFIG'

export const EMAIL_APPOINTMENT = 'emailAppointment/EMAIL_APPOINTMENT'
export const EMAIL_APPOINTMENT_SUCCESS =
  'emailAppointment/EMAIL_APPOINTMENT_SUCCESS'
export const EMAIL_APPOINTMENT_FAILURE =
  'emailAppointment/EMAIL_APPOINTMENT_FAILURE'

export const EMAIL_APPOINTMENT_CONFIRMATION =
  'emailAppointment/EMAIL_APPOINTMENT_CONFIRMATION'
export const EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS =
  'emailAppointment/EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS'
export const EMAIL_APPOINTMENT_CONFIRMATION_FAILURE =
  'emailAppointment/EMAIL_APPOINTMENT_CONFIRMATION_FAILURE'

export const FETCH_EMAIL_ZOOM_LINK_CONFIG =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_CONFIG'
export const FETCH_EMAIL_ZOOM_LINK_CONFIG_SUCCESS =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_CONFIG_SUCCESS'
export const FETCH_EMAIL_ZOOM_LINK_CONFIG_FAILURE =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_CONFIG_FAILURE'

export const FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG'
export const FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_SUCCESS =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_SUCCESS'
export const FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_FAILURE =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_FAILURE'

export const FETCH_EMAIL_ZOOM_LINK_TEMPLATE =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_TEMPLATE'
export const FETCH_EMAIL_ZOOM_LINK_TEMPLATE_SUCCESS =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_ZOOM_LINK_TEMPLATE_FAILURE =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_TEMPLATE_FAILURE'

export const FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE'
export const FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_SUCCESS =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_SUCCESS'
export const FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_FAILURE =
  'emailAppointment/FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_FAILURE'

export const EMAIL_ZOOM_LINK = 'emailAppointment/EMAIL_ZOOM_LINK'
export const EMAIL_ZOOM_LINK_SUCCESS =
  'emailAppointment/EMAIL_ZOOM_LINK_SUCCESS'
export const EMAIL_ZOOM_LINK_FAILURE =
  'emailAppointment/EMAIL_ZOOM_LINK_FAILURE'

export const PRE_FILL_EMAIL_APPOINTMENT_CONFIG_WITH_DATA =
  'PRE_FILL_EMAIL_APPOINTMENT_CONFIG_WITH_DATA'

export const fetchEmailAppointmentConfig = (
  appointmentId: string,
  transport?: ConversationTransport,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIG,
  appointmentId,
  transport,
})
export const fetchEmailAppointmentConfigSuccess = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIG_SUCCESS,
  config,
})
export const fetchEmailAppointmentConfigFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIG_FAILURE,
  error,
})

export const fetchEmailAppointmentConfirmationConfig = (
  appointmentId: string,
  transport?: ConversationTransport,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG,
  appointmentId,
  transport,
})
export const fetchEmailAppointmentConfirmationConfigSuccess = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_SUCCESS,
  config,
})
export const fetchEmailAppointmentConfirmationConfigFailure = (
  error: ApiError,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_FAILURE,
  error,
})

export const fetchEmailAppointmentInfoDummyConfig = () => ({
  type: FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG,
})
export const fetchEmailAppointmentInfoDummyConfigSuccess = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_SUCCESS,
  config,
})
export const fetchEmailAppointmentInfoDummyConfigFailure = (
  error: ApiError,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_FAILURE,
  error,
})

export const fetchEmailAppointmentConfirmationDummyConfig = () => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG,
})
export const fetchEmailAppointmentConfirmationDummyConfigSuccess = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_SUCCESS,
  config,
})
export const fetchEmailAppointmentConfirmationDummyConfigFailure = (
  error: ApiError,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_FAILURE,
  error,
})

export const fetchEmailAppointmentTemplate = (
  appointmentId: string,
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_TEMPLATE,
  appointmentId,
  config,
})
export const fetchEmailAppointmentTemplateSuccess = (template: string) => ({
  type: FETCH_EMAIL_APPOINTMENT_TEMPLATE_SUCCESS,
  template,
})
export const fetchEmailAppointmentTemplateFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_APPOINTMENT_TEMPLATE_FAILURE,
  error,
})

export const fetchEmailAppointmentConfirmationTemplate = (
  appointmentId: string,
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE,
  appointmentId,
  config,
})
export const fetchEmailAppointmentConfirmationTemplateSuccess = (
  template: string,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_SUCCESS,
  template,
})
export const fetchEmailAppointmentConfirmationTemplateFailure = (
  error: ApiError,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_FAILURE,
  error,
})

export const fetchEmailAppointmentConfirmationDummyTemplate = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE,
  config,
})
export const fetchEmailAppointmentConfirmationDummyTemplateSuccess = (
  template: string,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_SUCCESS,
  template,
})
export const fetchEmailAppointmentConfirmationDummyTemplateFailure = (
  error: ApiError,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_FAILURE,
  error,
})

export const fetchEmailAppointmentDummyTemplate = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE,
  config,
})
export const fetchEmailAppointmentDummyTemplateSuccess = (
  template: string,
) => ({
  type: FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_SUCCESS,
  template,
})
export const fetchEmailAppointmentDummyTemplateFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_FAILURE,
  error,
})

export const clearEmailAppointmentTemplate = () => ({
  type: CLEAR_EMAIL_APPOINTMENT_TEMPLATE,
})
export const clearEmailAppointmentDummyTemplate = () => ({
  type: CLEAR_EMAIL_APPOINTMENT_DUMMY_TEMPLATE,
})
export const clearEmailAppointmentConfig = () => ({
  type: CLEAR_EMAIL_APPOINTMENT_CONFIG,
})
export const clearEmailAppointmentDummyConfig = () => ({
  type: CLEAR_EMAIL_APPOINTMENT_DUMMY_CONFIG,
})

export const emailAppointment = (
  appointmentId: string,
  config: EmailEntityConfig,
) => ({
  type: EMAIL_APPOINTMENT,
  appointmentId,
  config,
})
export const emailAppointmentSuccess = (conversationsList: string[]) => ({
  type: EMAIL_APPOINTMENT_SUCCESS,
  conversationsList,
})
export const emailAppointmentFailure = (error: ApiError) => ({
  type: EMAIL_APPOINTMENT_FAILURE,
  error,
})

export const emailAppointmentConfirmation = (
  appointmentId: string,
  config: EmailEntityConfig,
) => ({
  type: EMAIL_APPOINTMENT_CONFIRMATION,
  appointmentId,
  config,
})
export const emailAppointmentConfirmationSuccess = (
  conversationsList: string[],
) => ({
  type: EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS,
  conversationsList,
})
export const emailAppointmentConfirmationFailure = (error: ApiError) => ({
  type: EMAIL_APPOINTMENT_CONFIRMATION_FAILURE,
  error,
})

export const fetchEmailZoomLinkConfig = (
  appointmentId: string,
  transport?: ConversationTransport,
) => ({
  type: FETCH_EMAIL_ZOOM_LINK_CONFIG,
  appointmentId,
  transport,
})
export const fetchEmailZoomLinkConfigSuccess = (config: EmailEntityConfig) => ({
  type: FETCH_EMAIL_ZOOM_LINK_CONFIG_SUCCESS,
  config,
})
export const fetchEmailZoomLinkConfigFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_ZOOM_LINK_CONFIG_FAILURE,
  error,
})

export const fetchEmailZoomLinkDummyConfig = () => ({
  type: FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG,
})
export const fetchEmailZoomLinkDummyConfigSuccess = (
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_SUCCESS,
  config,
})
export const fetchEmailZoomLinkDummyConfigFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_FAILURE,
  error,
})

export const fetchEmailZoomLinkTemplate = (
  appointmentId: string,
  config: EmailEntityConfig,
) => ({
  type: FETCH_EMAIL_ZOOM_LINK_TEMPLATE,
  appointmentId,
  config,
})
export const fetchEmailZoomLinkTemplateSuccess = (template: string) => ({
  type: FETCH_EMAIL_ZOOM_LINK_TEMPLATE_SUCCESS,
  template,
})
export const fetchEmailZoomLinkTemplateFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_ZOOM_LINK_TEMPLATE_FAILURE,
  error,
})

export const fetchEmailZoomLinkDummyTemplate = (
  config: Partial<EmailEntityConfig>,
) => ({
  type: FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE,
  config,
})
export const fetchEmailZoomLinkDummyTemplateSuccess = (template: string) => ({
  type: FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_SUCCESS,
  template,
})
export const fetchEmailZoomLinkDummyTemplateFailure = (error: ApiError) => ({
  type: FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_FAILURE,
  error,
})

export const emailZoomLink = (
  appointmentId: string,
  config: EmailEntityConfig,
) => ({
  type: EMAIL_ZOOM_LINK,
  appointmentId,
  config,
})
export const emailZoomLinkSuccess = (conversationsList: string[]) => ({
  type: EMAIL_ZOOM_LINK_SUCCESS,
  conversationsList,
})
export const emailZoomLinkFailure = (error: ApiError) => ({
  type: EMAIL_ZOOM_LINK_FAILURE,
  error,
})

export const preFillEmailAppointmentConfigWithData = (
  preFillData: Partial<EmailEntityConfig>,
) => ({
  type: PRE_FILL_EMAIL_APPOINTMENT_CONFIG_WITH_DATA,
  preFillData,
})

export type EmailAppointmentState = {
  config: EmailEntityConfig | null
  configPreFillData: Partial<EmailEntityConfig> | null
  confirmationDummyConfig: EmailEntityConfig | null
  emailDummyTemplate: string | null
  emailTemplate: string | null
  error: string | null
  infoDummyConfig: EmailEntityConfig | null
  isLoading: boolean
  isReceivingTemplate: boolean
  isSendingEmail: boolean
  zoomLinkDummyConfig: EmailEntityConfig | null
}

export const INITIAL_STATE: EmailAppointmentState = {
  config: null,
  configPreFillData: null,
  confirmationDummyConfig: null,
  infoDummyConfig: null,
  zoomLinkDummyConfig: null,
  emailTemplate: null,
  emailDummyTemplate: null,
  isLoading: false,
  isReceivingTemplate: false,
  isSendingEmail: false,
  error: null,
}

const preFillEmailAppointmentConfig = (
  config: EmailEntityConfig | null,
  preFillData: Partial<EmailEntityConfig> | null,
) => {
  if (!config) {
    return null
  }

  return {
    ...config,
    transport: preFillData?.transport || config.transport,
    mailTo: preFillData?.mailTo || config.mailTo,
  }
}

export const emailAppointmentReducer = (
  state: EmailAppointmentState = INITIAL_STATE,
  action: AnyAction,
): EmailAppointmentState => {
  switch (action.type) {
    case FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG:
    case FETCH_EMAIL_ZOOM_LINK_CONFIG:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG:
    case FETCH_EMAIL_APPOINTMENT_CONFIG:
      return { ...state, isLoading: true }
    case FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_SUCCESS:
      return { ...state, isLoading: false, zoomLinkDummyConfig: action.config }
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        confirmationDummyConfig: action.config,
      }
    case FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG_SUCCESS:
      return { ...state, isLoading: false, infoDummyConfig: action.config }
    case FETCH_EMAIL_ZOOM_LINK_CONFIG_SUCCESS:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_SUCCESS:
    case FETCH_EMAIL_APPOINTMENT_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        config: preFillEmailAppointmentConfig(
          action.config,
          state.configPreFillData,
        ),
        emailTemplate: null,
        configPreFillData: null,
      }
    case FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE:
    case FETCH_EMAIL_ZOOM_LINK_TEMPLATE:
    case FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE:
    case FETCH_EMAIL_APPOINTMENT_TEMPLATE:
      return { ...state, isReceivingTemplate: true }
    case FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_SUCCESS:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_SUCCESS:
    case FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_SUCCESS:
      return {
        ...state,
        isReceivingTemplate: false,
        emailDummyTemplate: action.template,
      }
    case FETCH_EMAIL_ZOOM_LINK_TEMPLATE_SUCCESS:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_SUCCESS:
    case FETCH_EMAIL_APPOINTMENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isReceivingTemplate: false,
        emailTemplate: action.template,
      }
    case FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE_FAILURE:
    case FETCH_EMAIL_ZOOM_LINK_TEMPLATE_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE_FAILURE:
    case FETCH_EMAIL_APPOINTMENT_TEMPLATE_FAILURE:
      return {
        ...state,
        isReceivingTemplate: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_EMAIL_APPOINTMENT_TEMPLATE:
      return { ...state, emailTemplate: null }
    case CLEAR_EMAIL_APPOINTMENT_DUMMY_TEMPLATE:
      return { ...state, emailDummyTemplate: null }
    case CLEAR_EMAIL_APPOINTMENT_DUMMY_CONFIG:
      return {
        ...state,
        confirmationDummyConfig: null,
        infoDummyConfig: null,
        zoomLinkDummyConfig: null,
      }
    case CLEAR_EMAIL_APPOINTMENT_CONFIG:
      return { ...state, config: null, configPreFillData: null }
    case EMAIL_ZOOM_LINK:
    case EMAIL_APPOINTMENT_CONFIRMATION:
    case EMAIL_APPOINTMENT:
      return { ...state, isSendingEmail: true }
    case EMAIL_ZOOM_LINK_SUCCESS:
    case EMAIL_APPOINTMENT_CONFIRMATION_SUCCESS:
    case EMAIL_APPOINTMENT_SUCCESS:
      return { ...state, isSendingEmail: false }
    case EMAIL_ZOOM_LINK_FAILURE:
    case EMAIL_APPOINTMENT_CONFIRMATION_FAILURE:
    case EMAIL_APPOINTMENT_FAILURE:
      return {
        ...state,
        isSendingEmail: false,
        error: getErrorMessage(action.error),
      }
    case PRE_FILL_EMAIL_APPOINTMENT_CONFIG_WITH_DATA:
      return {
        ...state,
        config: preFillEmailAppointmentConfig(state.config, action.preFillData),
        configPreFillData: state.config ? null : action.preFillData,
      }
    default:
      return state
  }
}

export const getEmailAppointment = (state: RootState): EmailAppointmentState =>
  state.emailAppointment
export const getEmailAppointmentIsLoading = (state: RootState) =>
  getEmailAppointment(state).isLoading
export const getEmailAppointmentIsSendingEmail = (state: RootState) =>
  getEmailAppointment(state).isSendingEmail
export const getEmailAppointmentTemplateIsReceiving = (state: RootState) =>
  getEmailAppointment(state).isReceivingTemplate
export const getEmailAppointmentConfig = (state: RootState) =>
  getEmailAppointment(state).config
export const getEmailAppointmentTemplate = (state: RootState) =>
  getEmailAppointment(state).emailTemplate
export const getEmailAppointmentConfirmationDummyConfig = (state: RootState) =>
  getEmailAppointment(state).confirmationDummyConfig
export const getEmailAppointmentInfoDummyConfig = (state: RootState) =>
  getEmailAppointment(state).infoDummyConfig
export const getEmailZoomLinkDummyConfig = (state: RootState) =>
  getEmailAppointment(state).zoomLinkDummyConfig
export const getEmailAppointmentDummyTemplate = (state: RootState) =>
  getEmailAppointment(state).emailDummyTemplate

export function* fetchEmailAppointmentConfigSaga({
  appointmentId,
  transport,
}: ReturnType<typeof fetchEmailAppointmentConfig>) {
  try {
    const config = yield* requestAPI(
      API.loadEmailAppointmentConfig,
      appointmentId,
      transport,
    )
    yield put(fetchEmailAppointmentConfigSuccess(config))
  } catch (error) {
    yield put(fetchEmailAppointmentConfigFailure(error as ApiError))
  }
}

export function* fetchEmailAppointmentInfoDummyConfigSaga() {
  try {
    const config = yield* requestAPI(API.loadEmailAppointmentDummyConfig)
    yield put(fetchEmailAppointmentInfoDummyConfigSuccess(config))
  } catch (error) {
    yield put(fetchEmailAppointmentInfoDummyConfigFailure(error as ApiError))
  }
}

export function* fetchEmailAppointmentConfirmationConfigSaga({
  appointmentId,
  transport,
}: ReturnType<typeof fetchEmailAppointmentConfirmationConfig>) {
  try {
    const config = yield* requestAPI(
      API.loadEmailAppointmentConfirmationConfig,
      appointmentId,
      transport,
    )
    yield put(fetchEmailAppointmentConfirmationConfigSuccess(config))
  } catch (error) {
    yield put(fetchEmailAppointmentConfirmationConfigFailure(error as ApiError))
  }
}

export function* fetchEmailAppointmentConfirmationDummyConfigSaga() {
  try {
    const config = yield* requestAPI(
      API.loadDefaultEmailAppointmentConfirmationConfig,
    )
    yield put(fetchEmailAppointmentConfirmationDummyConfigSuccess(config))
  } catch (error) {
    yield put(
      fetchEmailAppointmentConfirmationDummyConfigFailure(error as ApiError),
    )
  }
}

export function* fetchEmailAppointmentTemplateSaga({
  appointmentId,
  config,
}: ReturnType<typeof fetchEmailAppointmentTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadEmailAppointmentTemplate,
      appointmentId,
      config,
    )
    yield put(fetchEmailAppointmentTemplateSuccess(html))
  } catch (error) {
    yield put(fetchEmailAppointmentTemplateFailure(error as ApiError))
  }
}

export function* fetchEmailAppointmentDummyTemplateSaga({
  config,
}: ReturnType<typeof fetchEmailAppointmentDummyTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadEmailAppointmentDummyTemplate,
      config,
    )
    yield put(fetchEmailAppointmentDummyTemplateSuccess(html))
  } catch (error) {
    yield put(fetchEmailAppointmentDummyTemplateFailure(error as ApiError))
  }
}

export function* fetchEmailAppointmentConfirmationTemplateSaga({
  appointmentId,
  config,
}: ReturnType<typeof fetchEmailAppointmentConfirmationTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadEmailAppointmentConfirmationTemplate,
      appointmentId,
      config,
    )
    yield put(fetchEmailAppointmentConfirmationTemplateSuccess(html))
  } catch (error) {
    yield put(
      fetchEmailAppointmentConfirmationTemplateFailure(error as ApiError),
    )
  }
}

export function* fetchEmailAppointmentConfirmationDummyTemplateSaga({
  config,
}: ReturnType<typeof fetchEmailAppointmentConfirmationDummyTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadEmailAppointmentDummyConfirmationTemplate,
      config,
    )
    yield put(fetchEmailAppointmentConfirmationDummyTemplateSuccess(html))
  } catch (error) {
    yield put(
      fetchEmailAppointmentConfirmationDummyTemplateFailure(error as ApiError),
    )
  }
}

export function* sendEmailAppointmentSaga({
  appointmentId,
  config,
}: ReturnType<typeof emailAppointment>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailAppointment,
      appointmentId,
      {
        ...config,
        sections: config?.sections || [],
      },
    )
    yield call(updateEntities, entities)
    yield put(emailAppointmentSuccess(conversationsList))
  } catch (error) {
    yield put(emailAppointmentFailure(error as ApiError))
  }
}

export function* emailAppointmentConfirmationSaga({
  appointmentId,
  config,
}: ReturnType<typeof emailAppointmentConfirmation>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailAppointmentConfirmation,
      appointmentId,
      {
        ...config,
        sections: config?.sections || [],
      },
    )
    yield call(updateEntities, entities)
    yield put(emailAppointmentConfirmationSuccess(conversationsList))
  } catch (error) {
    yield put(emailAppointmentConfirmationFailure(error as ApiError))
  }
}

export function* fetchEmailZoomLinkConfigSaga({
  appointmentId,
  transport,
}: ReturnType<typeof fetchEmailZoomLinkConfig>) {
  try {
    const config = yield* requestAPI(
      API.loadEmailZoomLinkConfig,
      appointmentId,
      transport,
    )
    yield put(fetchEmailZoomLinkConfigSuccess(config))
  } catch (error) {
    yield put(fetchEmailZoomLinkConfigFailure(error as ApiError))
  }
}

export function* fetchEmailZoomLinkDummyConfigSaga() {
  try {
    const config = yield* requestAPI(API.loadDefaultEmailZoomLinkConfig)
    yield put(fetchEmailZoomLinkDummyConfigSuccess(config))
  } catch (error) {
    yield put(fetchEmailZoomLinkDummyConfigFailure(error as ApiError))
  }
}

export function* fetchEmailZoomLinkTemplateSaga({
  appointmentId,
  config,
}: ReturnType<typeof fetchEmailZoomLinkTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadEmailZoomLinkTemplate,
      appointmentId,
      config,
    )
    yield put(fetchEmailZoomLinkTemplateSuccess(html))
  } catch (error) {
    yield put(fetchEmailZoomLinkTemplateFailure(error as ApiError))
  }
}

export function* fetchEmailZoomLinkDummyTemplateSaga({
  config,
}: ReturnType<typeof fetchEmailZoomLinkDummyTemplate>) {
  try {
    const html = yield* requestAPI(API.loadEmailZoomLinkDummyTemplate, config)
    yield put(fetchEmailZoomLinkDummyTemplateSuccess(html))
  } catch (error) {
    yield put(fetchEmailZoomLinkDummyTemplateFailure(error as ApiError))
  }
}

export function* emailZoomLinkSaga({
  appointmentId,
  config,
}: ReturnType<typeof emailZoomLink>) {
  try {
    const { result: conversationsList, entities } = yield* requestAPI(
      API.emailZoomLink,
      appointmentId,
      {
        ...config,
        sections: config?.sections || [],
      },
    )
    yield call(updateEntities, entities)
    yield put(emailZoomLinkSuccess(conversationsList))
  } catch (error) {
    yield put(emailZoomLinkFailure(error as ApiError))
  }
}

function* watchFetchEmailAppointmentConfig() {
  yield takeLeading(
    FETCH_EMAIL_APPOINTMENT_CONFIG,
    fetchEmailAppointmentConfigSaga,
  )
}

function* watchFetchEmailAppointmentInfoDummyConfig() {
  yield takeLeading(
    FETCH_EMAIL_APPOINTMENT_INFO_DUMMY_CONFIG,
    fetchEmailAppointmentInfoDummyConfigSaga,
  )
}

function* watchFetchEmailAppointmentConfirmationConfig() {
  yield takeLeading(
    FETCH_EMAIL_APPOINTMENT_CONFIRMATION_CONFIG,
    fetchEmailAppointmentConfirmationConfigSaga,
  )
}

function* watchFetchEmailAppointmentConfirmationDummyConfig() {
  yield takeLeading(
    FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_CONFIG,
    fetchEmailAppointmentConfirmationDummyConfigSaga,
  )
}

function* watchFetchEmailAppointmentTemplate() {
  yield takeLatest(
    FETCH_EMAIL_APPOINTMENT_TEMPLATE,
    fetchEmailAppointmentTemplateSaga,
  )
}

function* watchFetchEmailAppointmentDummyTemplate() {
  yield takeLatest(
    FETCH_EMAIL_APPOINTMENT_DUMMY_TEMPLATE,
    fetchEmailAppointmentDummyTemplateSaga,
  )
}

function* watchFetchEmailAppointmentConfirmationTemplate() {
  yield takeLatest(
    FETCH_EMAIL_APPOINTMENT_CONFIRMATION_TEMPLATE,
    fetchEmailAppointmentConfirmationTemplateSaga,
  )
}

function* watchFetchEmailAppointmentConfirmationDummyTemplate() {
  yield takeLatest(
    FETCH_EMAIL_APPOINTMENT_CONFIRMATION_DUMMY_TEMPLATE,
    fetchEmailAppointmentConfirmationDummyTemplateSaga,
  )
}

function* watchEmailAppointment() {
  yield takeLeading(EMAIL_APPOINTMENT, sendEmailAppointmentSaga)
}

function* watchSendEmailAppointmentConfirmation() {
  yield takeLeading(
    EMAIL_APPOINTMENT_CONFIRMATION,
    emailAppointmentConfirmationSaga,
  )
}

function* watchFetchEmailZoomLinkConfig() {
  yield takeLeading(FETCH_EMAIL_ZOOM_LINK_CONFIG, fetchEmailZoomLinkConfigSaga)
}

function* watchFetchEmailZoomLinkDummyConfig() {
  yield takeLeading(
    FETCH_EMAIL_ZOOM_LINK_DUMMY_CONFIG,
    fetchEmailZoomLinkDummyConfigSaga,
  )
}

function* watchFetchEmailZoomLinkTemplate() {
  yield takeLatest(
    FETCH_EMAIL_ZOOM_LINK_TEMPLATE,
    fetchEmailZoomLinkTemplateSaga,
  )
}

function* watchFetchEmailZoomLinkDummyTemplate() {
  yield takeLatest(
    FETCH_EMAIL_ZOOM_LINK_DUMMY_TEMPLATE,
    fetchEmailZoomLinkDummyTemplateSaga,
  )
}

function* watchSendEmailZoomLink() {
  yield takeLeading(EMAIL_ZOOM_LINK, emailZoomLinkSaga)
}

export function* emailAppointmentSaga() {
  yield all([
    watchFetchEmailAppointmentConfig(),
    watchFetchEmailAppointmentInfoDummyConfig(),
    watchFetchEmailAppointmentConfirmationConfig(),
    watchFetchEmailAppointmentConfirmationDummyConfig(),
    watchFetchEmailAppointmentTemplate(),
    watchFetchEmailAppointmentDummyTemplate(),
    watchFetchEmailAppointmentConfirmationTemplate(),
    watchFetchEmailAppointmentConfirmationDummyTemplate(),
    watchEmailAppointment(),
    watchSendEmailAppointmentConfirmation(),
    watchFetchEmailZoomLinkConfig(),
    watchFetchEmailZoomLinkDummyConfig(),
    watchFetchEmailZoomLinkTemplate(),
    watchFetchEmailZoomLinkDummyTemplate(),
    watchSendEmailZoomLink(),
  ])
}
