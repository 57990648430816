import i18n from '~/locales/i18n'

import { ColorVariant } from './colors'

export const RhapsodyPassStates = {
  SUBMITTED: 'Submitted',
  NOT_STARTED: 'Not Started',
  APPROVED: 'Approved',
  ACTIVE: 'Active',
}

export const RhapsodyPassStatesColor = {
  [RhapsodyPassStates.SUBMITTED]: ColorVariant.IMPORTANT,
  [RhapsodyPassStates.NOT_STARTED]: ColorVariant.IMPORTANT,
  [RhapsodyPassStates.APPROVED]: ColorVariant.NORMAL,
  [RhapsodyPassStates.ACTIVE]: ColorVariant.NORMAL,
}

export const TranslatedRhapsodyPassStates = {
  [RhapsodyPassStates.SUBMITTED]: i18n.t('Constants:PASS_STATES.SUBMITTED'),
  [RhapsodyPassStates.NOT_STARTED]: i18n.t('Constants:PASS_STATES.NOT_STARTED'),
  [RhapsodyPassStates.APPROVED]: i18n.t('Constants:PASS_STATES.APPROVED'),
  [RhapsodyPassStates.ACTIVE]: i18n.t('Constants:PASS_STATES.ACTIVE'),
}

export const RhapsodyGoConfigurationLabels = {
  INCLUDED: 'Included',
  NOT_INCLUDED: 'Not Included',
}

export const TranslatedRhapsodyGoConfigurationLabels = {
  [RhapsodyGoConfigurationLabels.INCLUDED]: i18n.t(
    'Constants:CONFIGURATION_LABELS.INCLUDED',
  ),
  [RhapsodyGoConfigurationLabels.NOT_INCLUDED]: i18n.t(
    'Constants:CONFIGURATION_LABELS.NOT_INCLUDED',
  ),
}
