import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CountryCode } from '@pbt/pbt-ui-components'

import PrintHtml from '~/components/common/print/PrintHtml'
import FeatureToggle from '~/constants/featureToggle'
import { clearPrescriptionForPrint } from '~/store/actions/orders'
import { useIsFood, useIsGlobalInventoryItem } from '~/store/hooks/orders'
import {
  getCurrentBusiness,
  getCurrentBusinessCountryCatalogCode,
} from '~/store/reducers/auth'
import {
  getBreed,
  getDrugAdministrationFrequencies,
  getDrugDeliveryMethod,
  getDrugStrengthUnit,
  getFeatureToggle,
  getGender,
  getLicenseTypes,
  getPrescriptionProductSizeUnit,
  getProductForm,
  getProductSizeUnit,
  getSpecies,
} from '~/store/reducers/constants'
import { Prescription } from '~/types'
import { getOrderPrintPageName } from '~/utils'

import PrescriptionPrintPage from './order/PrescriptionPrintPage'

export interface PrintOutsidePharmacyPrescriptionProps {
  prescription: Prescription
}

const PrintOutsidePharmacyPrescription = ({
  prescription,
}: PrintOutsidePharmacyPrescriptionProps) => {
  const dispatch = useDispatch()

  const Breed = useSelector(getBreed)
  const Gender = useSelector(getGender)
  const Species = useSelector(getSpecies)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const DrugAdministrationFrequencies = useSelector(
    getDrugAdministrationFrequencies,
  )
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )
  const LicenseTypes = useSelector(getLicenseTypes)
  const business = useSelector(getCurrentBusiness)
  const ProductForm = useSelector(getProductForm)

  const isExposeFlavorEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EXPOSE_FLAVOR),
  )
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const currentBusinessCountryCatalogCode = useSelector(
    getCurrentBusinessCountryCatalogCode,
  )

  const isFood = useIsFood(prescription)
  const isGlobalItem = useIsGlobalInventoryItem(prescription)

  const isSpain = currentBusinessCountryCatalogCode === CountryCode.ES

  const handleOnDone = () => {
    dispatch(clearPrescriptionForPrint())
  }

  return (
    <PrintHtml
      name={getOrderPrintPageName(prescription)}
      source={
        <PrescriptionPrintPage
          constants={{
            Breed,
            Gender,
            Species,
            ProductSizeUnit,
            PrescriptionProductSizeUnit,
            DrugStrengthUnit,
            DrugDeliveryMethod,
            DrugAdministrationFrequencies,
            LicenseTypes,
            ProductForm,
          }}
          isExposeFlavorEnabled={isExposeFlavorEnabled}
          isFood={isFood}
          isFoodCatalogEnabled={isFoodCatalogEnabled}
          isGlobalItem={isGlobalItem}
          isSpain={isSpain}
          printInfo={{
            ...prescription,
            business,
          }}
        />
      }
      onOk={handleOnDone}
    />
  )
}

export default PrintOutsidePharmacyPrescription
