import * as R from 'ramda'
import { Constant, Nil, Utils } from '@pbt/pbt-ui-components'

import {
  AdministrationCourseType,
  AdministrationCourseTypeStr,
} from '~/constants/prescription'
import i18n from '~/locales/i18n'
import {
  Order,
  Prescription,
  PrescriptionDrugInfo,
  PrescriptionPrintInfo,
  PrescriptionSnapshotItem,
  PrescriptionTimelineItem,
} from '~/types'
import { getManualInputSelectValue, getUrlSearchParam } from '~/utils'
import { getPrescriptionType } from '~/utils/prescription'

import { isRetailOrderLineItem } from '../../invoices/invoiceUtils'

// TODO: move to src/utils/prescription.ts

export const getLengthOfCourseString = ({
  administrationCourseType,
  administrationCourseLength,
}: Pick<
  Prescription,
  'administrationCourseType' | 'administrationCourseLength'
>) =>
  administrationCourseType === AdministrationCourseType.CUSTOM
    ? i18n.t('Plurals:Z_ICU_WORKAROUND.NUMBER_DAYS', {
        number: administrationCourseLength,
      })
    : AdministrationCourseTypeStr[administrationCourseType] || ''

const findOtherOptionId = (options: Constant[]) =>
  Utils.findByName('Other', options)?.id
const findName = (id: string, options: Constant[]) =>
  Utils.findById(id, options)?.name

export const getFallbackUnit = (
  perPackageUnitsId: string | Nil,
  actualOptions: Constant[],
  genericOptions: Constant[],
) => {
  const actualUnitId = perPackageUnitsId || ''
  const actualUnit = Utils.findById(actualUnitId, actualOptions)
  const otherOptionId = findOtherOptionId(actualOptions)

  return {
    id: actualUnit ? actualUnitId : otherOptionId,
    customUnit:
      !actualUnit && otherOptionId
        ? findName(actualUnitId, genericOptions)
        : '',
  }
}

export const getPrescriptionQuantityUnits = (
  prescription: Partial<Prescription>,
  {
    PrescriptionProductSizeUnit,
    ProductSizeUnit,
  }: {
    PrescriptionProductSizeUnit: Constant[]
    ProductSizeUnit: Constant[]
  },
) => {
  const {
    totalQuantityUnitId,
    totalQuantityCustomUnit,
    quantityPerRefillUnitId,
    quantityPerRefillCustomUnit,
    administrationQuantityUnitId,
    administrationQuantityCustomUnit,
    variation,
  } = prescription || {}
  const { id: fallbackUnitId, customUnit: fallbackCustomUnit } =
    getFallbackUnit(
      variation?.perPackageUnitsId,
      PrescriptionProductSizeUnit,
      ProductSizeUnit,
    )

  const quantityUnit =
    getManualInputSelectValue(
      ProductSizeUnit,
      totalQuantityUnitId || fallbackUnitId,
      totalQuantityCustomUnit,
    ) ||
    fallbackCustomUnit ||
    ''

  const quantityPerRefillUnit =
    getManualInputSelectValue(
      ProductSizeUnit,
      quantityPerRefillUnitId || fallbackUnitId,
      quantityPerRefillCustomUnit,
    ) ||
    fallbackCustomUnit ||
    ''

  const administrationQuantityUnit =
    getManualInputSelectValue(
      ProductSizeUnit,
      administrationQuantityUnitId || fallbackUnitId,
      administrationQuantityCustomUnit,
    ) ||
    fallbackCustomUnit ||
    ''

  return {
    quantityUnit,
    quantityPerRefillUnit,
    administrationQuantityUnit,
  }
}

export const getVariationField = (
  fieldName: keyof PrescriptionDrugInfo,
  data: PrescriptionPrintInfo,
  isFoodCatalogEnabled: boolean,
) =>
  R.path(['customCompound', fieldName], data.drugInfo)
    ? data.drugInfo?.[fieldName]
    : R.path(['variation', fieldName], data) ||
      R.path(
        [
          isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation',
          fieldName,
        ],
        data,
      )

export const hasRefillChangedFromParentPrescription = (
  oldPrescription = {} as Prescription,
  currentPrescription = {} as Prescription,
) =>
  (
    [
      'totalQuantity',
      'totalQuantityUnitId',
      'totalQuantityCustomUnit',
      'quantityDrugExpirationDate',
      'quantity',
      'priceId',
      'administrationQuantity',
      'administrationFrequencyId',
      'administrationCourseType',
      'administrationCourseLength',
      'administrationQuantityUnitId',
      'administrationRouteId',
      'administrationQuantityCustomUnit',
      'notes',
    ] as (keyof Prescription)[]
  ).some(
    (fieldName) =>
      oldPrescription[fieldName] !== currentPrescription[fieldName],
  )

export const getOrderName = (
  order: Prescription,
  ProductForm: Constant[],
  DrugStrengthUnit: Constant[],
  DrugDeliveryMethod: Constant[],
  isFoodCatalogEnabled: boolean,
) => {
  const {
    name = '',
    variation,
    globalVariation,
    globalVariationMapping,
    drugInfo,
    inventory,
    administrationRouteId,
  } = order
  const drugName = (inventory && inventory.name) || name
  const processingGlobalVariation = isFoodCatalogEnabled
    ? globalVariationMapping
    : globalVariation
  const { strength, strengthUnitsId, formId, customForm } =
    variation || processingGlobalVariation || drugInfo || {}

  const strengthStr = `${strength || ''}${Utils.getConstantName(
    strengthUnitsId,
    DrugStrengthUnit,
    '',
  )}`
  const formStr = getManualInputSelectValue(ProductForm, formId, customForm)
  const routeStr = Utils.getConstantName(
    administrationRouteId,
    DrugDeliveryMethod,
    '',
  )

  const details = `${strengthStr} ${formStr} ${routeStr}`.trim()

  return `${drugName} | ${details}`
}

export const getRefillsCount = (prescription: Prescription) =>
  prescription.refills?.length || 0

export const getRemainingRefillsCount = (prescription: Prescription) =>
  prescription?.quantityNumbRefills
    ? Math.max(
        prescription.quantityNumbRefills - getRefillsCount(prescription),
        0,
      )
    : 0

export const getPrescriptionCanBeRefilled = (prescription: Prescription) => {
  const canceledRefills = Boolean(prescription.cancelledBy)
  const { isInHouse } = getPrescriptionType(prescription.prescriptionType)
  const refillsRemaining = getRemainingRefillsCount(prescription)

  return isInHouse && !canceledRefills && refillsRemaining > 0
}

export const timelinePrescriptionToRegularPrescription = (
  prescription: Prescription,
  {
    name,
    masterName,
    prescription: prescriptionItem,
  }: PrescriptionSnapshotItem | PrescriptionTimelineItem,
  isFoodCatalogEnabled: boolean,
) =>
  ({
    ...prescription,
    name,
    inventory: {
      id: prescription.inventoryId,
      name: masterName || name,
      categoryId: prescriptionItem.categoryId,
    },
    variation: {
      id: prescription.variationId,
    },
    globalInventory: {
      id: prescription.globalInventoryId,
    },
    globalVariation: {
      id: prescription.globalVariationId,
    },
    ...(isFoodCatalogEnabled
      ? {
          globalInventoryMapping: {
            id: prescription.globalInventoryMappingId,
          },
          globalVariationMapping: {
            id: prescription.globalVariationMappingId,
          },
        }
      : {}),
  }) as Prescription

export const getGroupIdForPrescription = (
  searchQuery: string,
  unifiedOrder: Order | Nil,
) =>
  getUrlSearchParam('groupId', searchQuery) ??
  (isRetailOrderLineItem(unifiedOrder?.lineItem)
    ? null
    : unifiedOrder?.lineItem?.group)

export const getIdFromTimelineItem = (
  item: PrescriptionSnapshotItem | PrescriptionTimelineItem,
) => R.pathOr(item.invoiceId, ['invoice', 'id'], item)
