import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

export interface ListWithFiltersNoSearchResultProps {
  onDisableFiltering: () => void
  onFullList?: () => void
  query: string
  showFiltersToggle: boolean
}

const ListWithFiltersNoSearchResult = ({
  showFiltersToggle,
  query,
  onFullList,
  onDisableFiltering,
}: ListWithFiltersNoSearchResultProps) => {
  const { t } = useTranslation('Search')

  return (
    <>
      <Text px={2} variant="body">
        {t('Search:NO_SEARCH_RESULTS')} ‘
        <Text strong component="span">
          {query}
        </Text>
        ‘
        {!onFullList && (
          <>
            <br />
            {t('Search:TRY_TURNING_OFF_FILTERS')}
          </>
        )}
      </Text>
      {onFullList && (
        <Text px={2} variant="body">
          {showFiltersToggle ? (
            <Trans
              components={{
                fullListLink: (
                  <Text link component="span" onClick={onFullList} />
                ),
                filterLink: (
                  <Text link component="span" onClick={onDisableFiltering} />
                ),
              }}
              i18nKey="Search:NO_SEARCH_RESULTS_HINT_1"
            />
          ) : (
            <Trans
              components={{
                fullListLink: (
                  <Text link component="span" onClick={onFullList} />
                ),
              }}
              i18nKey="Search:NO_SEARCH_RESULTS_HINT_2"
            />
          )}
        </Text>
      )}
    </>
  )
}

export default ListWithFiltersNoSearchResult
