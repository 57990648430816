import React from 'react'
import { useSelector } from 'react-redux'
import { PersonOutlined as PersonOutlinedIcon } from '@mui/icons-material'
import { Grid, GridProps } from '@mui/material'
import {
  EventTypeAppointmentResponsibility,
  EventTypeAppointmentRole,
  EventTypeName,
  LanguageUtils,
  Nil,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import { AppointmentTypeName } from '~/constants/appointmentTypes'
import { getAppointmentType } from '~/store/reducers/appointmentTypes'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getTimetableIsLoading } from '~/store/reducers/timetable'
import { SchedulerSettings, TeamFilter } from '~/types'
import { useEventType } from '~/utils/useEventType'

import UserSelect, {
  UserSelectConstantFilterMap,
  UserSelectProps as UserSelectComponentProps,
} from '../inputs/UserSelect'
import CalendarSwitch, { CalendarSwitchProps } from './CalendarSwitch'

export interface AppointmentRoleItemProps
  extends Omit<CalendarSwitchProps, 'schedulerSettings'> {
  UserSelectContainerProps?: GridProps
  UserSelectProps?: Partial<UserSelectComponentProps>
  appointmentTypeId: string
  disabled?: boolean
  personIconClassName?: string
  role: EventTypeAppointmentRole | EventTypeAppointmentResponsibility
  schedulerSettings: SchedulerSettings | Nil
  showPersonIcon?: boolean
}

const AppointmentRoleItem = ({
  UserSelectContainerProps,
  UserSelectProps,
  appointmentTypeId,
  disabled,
  hasAppointmentTypeChanged,
  isEdit,
  isFirstRole,
  occupyTimeSlotField,
  personIconClassName,
  role,
  roleField,
  showPersonIcon = false,
  schedulerSettings,
}: AppointmentRoleItemProps) => {
  const isLoading = useSelector(getTimetableIsLoading)
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const businessAppoinmentType = useSelector(
    getAppointmentType(appointmentTypeId),
  )

  const AppointmentEventSubTypes = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )
  const OTCSaleId = Utils.findConstantIdByName(
    AppointmentTypeName.OTC_SALE,
    AppointmentEventSubTypes,
  )

  const eventTypeId = businessAppoinmentType?.eventTypeId

  const isOTCSale = eventTypeId === OTCSaleId

  return (
    <Grid container item wrap="nowrap">
      {showPersonIcon && (
        <Grid item mt={1}>
          <PersonOutlinedIcon className={personIconClassName} />
        </Grid>
      )}
      <Grid item xs {...UserSelectContainerProps}>
        <UserSelect
          {...UserSelectProps}
          disabled={!appointmentPermissions.update || disabled}
          field={roleField}
          filterScope={UserSelectConstantFilterMap[role.name]}
          label={LanguageUtils.getTranslatedFieldName(role)}
          teamFilter={TeamFilter.ALL}
        />
      </Grid>
      {!isOTCSale && (
        <CalendarSwitch
          hasAppointmentTypeChanged={hasAppointmentTypeChanged}
          isEdit={isEdit}
          isFirstRole={isFirstRole}
          isLoading={isLoading}
          occupyTimeSlotField={occupyTimeSlotField}
          roleField={roleField}
          schedulerSettings={schedulerSettings}
        />
      )}
    </Grid>
  )
}

export default AppointmentRoleItem
