import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import { createSpace } from '~/store/actions/spaces'
import {
  getLastCreatedSpaceId,
  getSpacesIsLoading,
} from '~/store/reducers/spaces'
import { Space, UnsavedSpace } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import SpaceComponent, { SpaceHandle } from './Space'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'SpaceDialog' },
)

export interface SpaceDialogProps extends BasePuiDialogProps {
  space: Space
}

const SpaceDialog = ({ space, open, onClose }: SpaceDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const lastCreatedSpaceId = useSelector(getLastCreatedSpaceId)
  const { t } = useTranslation('Admin')

  const spaceRef = useRef<SpaceHandle>(null)

  const [spaceCandidate, setSpaceCandidate] = useState<UnsavedSpace>(space)

  const handleClose = () => {
    setSpaceCandidate(space)
    if (onClose) {
      onClose()
    }
  }

  const onSpaceCreated = () => {
    handleClose()
    if (lastCreatedSpaceId) {
      navigate(`/admin/general/spaces/${lastCreatedSpaceId}`)
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onSpaceCreated,
    getSpacesIsLoading,
  )

  useEffect(() => {
    setSpaceCandidate(space)
  }, [space])

  const onProceed = (newSpace: UnsavedSpace) => {
    setSpaceCandidate(newSpace)
    setCloseAfterCreationOn()
    dispatch(createSpace(newSpace))
  }

  const confirmSavingSpace = () => {
    if (spaceRef.current?.validate()) {
      onProceed(spaceRef.current.get())
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: confirmSavingSpace,
      }}
      aria-labelledby="space-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={() => spaceRef.current?.hasUnsavedChanges() || false}
      open={open}
      title={t('Admin:SPACE.NEW_SPACE')}
      onClose={handleClose}
    >
      <SpaceComponent
        ref={spaceRef}
        space={spaceCandidate}
        onProceed={onProceed}
      />
    </PuiDialog>
  )
}

export default SpaceDialog
