import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  Field,
  Text,
  TextWithTooltip,
} from '@pbt/pbt-ui-components'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import BaseTemplateInput from '~/components/dashboard/template-inputs/BaseTemplateInput'
import { BaseTemplateInputHandle } from '~/components/dashboard/template-inputs/BaseTemplateInput/BaseTemplateInput'

const useStyles = makeStyles(
  (theme) => ({
    characterLimit: {
      fontSize: '1.4rem',
      width: 'max-content',
    },
    characterLimitReached: {
      color: theme.colors.errorColor,
    },
    richEditor: {},
    richEditorCharacterLimitReached: {
      borderColor: theme.colors.errorColor,
    },
  }),
  { name: 'MessageWithPreviewConfiguration' },
)

const DOCUMENT_TYPE_NAME = 'Communication template'

interface MessageWithPreviewConfigurationProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  field?: Field
  hidePlusButtonBlock?: boolean
  maxEditorHeight?: number
  maxLength?: number
  minEditorHeight?: number
  onChange?: () => void
  onPreview?: () => void
  previewDisabled?: boolean
  resetStateOnValueChange?: boolean
  showButtons?: boolean
  showDynamicText?: boolean
  showPreview?: boolean
  tooltipText?: string
  tooltipTitle?: string
  value?: string
}

const MessageWithPreviewConfiguration = forwardRef(
  function MessageWithPreviewConfiguration(
    {
      classes: classesProp,
      className,
      tooltipTitle,
      tooltipText,
      showPreview = true,
      onPreview = R.F,
      previewDisabled,
      maxLength = 0,
      field,
      hidePlusButtonBlock = true,
      value: initialValue,
      onChange: initialOnChange,
      showDynamicText = true,
      ...rest
    }: MessageWithPreviewConfigurationProps,
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })
    const inputRef = useRef<BaseTemplateInputHandle>(null)
    const { t } = useTranslation('Businesses')

    const value = field?.value ?? initialValue
    const onChange = field?.setValue ?? initialOnChange
    const inputLength = inputRef.current?.getPlainText?.()?.length ?? 0
    const characterLimitReached = inputLength >= maxLength

    useImperativeHandle(ref, () => inputRef.current)

    return (
      <Grid container item className={className} direction="column">
        <Grid container item justifyContent="space-between" wrap="nowrap">
          <Grid container item alignItems="center" wrap="nowrap">
            <Grid item>
              {tooltipText && tooltipTitle ? (
                <TextWithTooltip
                  allowWrap
                  strong
                  tooltipText={tooltipText}
                  variant="subheading3"
                >
                  {tooltipTitle}
                </TextWithTooltip>
              ) : (
                <Text strong variant="subheading3">
                  {tooltipTitle}
                </Text>
              )}
            </Grid>
            {showPreview && (
              <>
                <Divider flexItem orientation="vertical" />
                <Grid item>
                  <PreviewButton
                    disabled={previewDisabled}
                    onClick={onPreview}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {maxLength > 0 && (
            <Grid item>
              <Text
                className={classNames(classes.characterLimit, {
                  [classes.characterLimitReached]: characterLimitReached,
                })}
                variant="lowAccent"
              >
                {characterLimitReached
                  ? t(
                      'Businesses:WELLNESS_PLANS.NUMBER_CHARACTERS_LIMIT_REACHED',
                      {
                        number: `${inputLength}/${maxLength}`,
                      },
                    )
                  : t('Businesses:WELLNESS_PLANS.NUMBER_CHARACTERS', {
                      number: `${inputLength}/${maxLength}`,
                    })}
              </Text>
            </Grid>
          )}
        </Grid>

        <Grid container item>
          <BaseTemplateInput
            className={classNames(classes.richEditor, {
              [classes.richEditorCharacterLimitReached]: characterLimitReached,
            })}
            enrichDocuments={false}
            field={field}
            filter={DOCUMENT_TYPE_NAME}
            hidePlusButtonBlock={hidePlusButtonBlock}
            maxLength={maxLength}
            ref={inputRef}
            showDynamicText={showDynamicText}
            value={value}
            onChange={onChange}
            {...rest}
          />
        </Grid>
      </Grid>
    )
  },
)

export default MessageWithPreviewConfiguration
