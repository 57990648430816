import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(1),
      },
      height: 120,
      border: theme.constants.filterBorder,
      borderStyle: 'dashed',
      backgroundColor: '#FAFAFA',
    },
  }),
  { name: 'WhiteboardDroppablePlaceholder' },
)

const WhiteboardDroppablePlaceholder = () => {
  const classes = useStyles()

  return <Grid item className={classes.root} />
}

export default WhiteboardDroppablePlaceholder
