import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlertIconType,
  ConfirmCloseDialogProps,
  PuiAlert,
  PuiAlertProps,
} from '@pbt/pbt-ui-components'

export interface AppointmentConfigurationConfirmationDialogProps
  extends Omit<PuiAlertProps, 'onOk'> {
  onProceed: ConfirmCloseDialogProps['onOk']
}

export const AppointmentConfigurationConfirmationDialog = ({
  onProceed,
  open,
  onClose,
}: AppointmentConfigurationConfirmationDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])
  return (
    <PuiAlert
      iconType={AlertIconType.WARN}
      message={t('Dialogs:APPOINTMENT_CONFIRMATION_DIALOG.ALERT_MESSAGE')}
      okButtonText={t('Common:CONTINUE_ACTION')}
      open={open}
      onClose={onClose}
      onOk={onProceed}
    />
  )
}
