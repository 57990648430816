import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { ProposedColumn } from '~/types/entities/migrationV2'

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
  }),
  { name: 'MigrationExceptionColumns' },
)

type MigrationExceptionColumnsProps = {
  columns: ProposedColumn[]
}

export const MigrationExceptionColumns = ({
  columns,
}: MigrationExceptionColumnsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  return (
    <Grid
      container
      bgcolor={(theme) => theme.palette.grey[50]}
      className={classes.tableRow}
      position="sticky"
      top={0}
      zIndex={1}
    >
      {columns.map((column) => (
        <Grid item xs key={`${column.id}-header`}>
          <Text fontSize="1.6rem" fontWeight={500} py={1} variant="body">
            {column.name}
          </Text>
        </Grid>
      ))}
      <Grid item xs={1.5}>
        <Text fontSize="1.6rem" fontWeight={500} py={1} variant="body">
          {t('Businesses:MIGRATION.SCOPE')}
        </Text>
      </Grid>
      <Grid item p={0} xs={0.5} />
    </Grid>
  )
}
