import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertIconType, Defaults } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

import FileSelector, { FileSelectorProps } from './FileSelector'

const MaxFileSize = 5e7

interface MultiFileSelectorProps
  extends Omit<FileSelectorProps, 'processFiles' | 'renderInput'> {
  onSelected?: (files: File[]) => void
}

const MultiFileSelector = forwardRef(function MultiFileSelector(
  { onSelected, ...rest }: MultiFileSelectorProps,
  ref,
) {
  const { t } = useTranslation('Common')

  const [openFileAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const processFiles = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLInputElement>,
  ) => {
    const { files }: { files: FileList } =
      // @ts-ignore
      event.dataTransfer || event.target || {}
    if (!files || !files.length) {
      return
    }
    const filesArr = Array.from(files)
    const isExceedMaxFileSize = filesArr.some(
      (file: File) => file.size > MaxFileSize,
    )

    if (isExceedMaxFileSize) {
      openFileAlert({
        iconType: AlertIconType.WARN,
        message: (
          <>
            {t('Common:INPUTS.FILE_SELECTOR.ALERT_MESSAGE_MULTI', {
              maxFileSize: MaxFileSize / 1e6,
            })}
            &nbsp;
            <a href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
              {Defaults.SUPPORT_EMAIL}
            </a>
            .
          </>
        ),
      })
    } else if (onSelected) {
      onSelected(filesArr)
    }
  }

  return (
    <FileSelector multiple processFiles={processFiles} ref={ref} {...rest} />
  )
})

export default MultiFileSelector
