import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Fab, FabProps, Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  AlertIconType,
  BackButton,
  ClassesType,
  HtmlNotesPreview,
  MarketingStylesButton,
  PuiAlert,
  Text,
} from '@pbt/pbt-ui-components'

import { ALERT_TYPES } from '~/constants/alertTypes'
import { clearUserAlreadyExists } from '~/store/actions/registration'
import { getIsUserAlreadyExist } from '~/store/reducers/registration'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        float: 'none',
        marginLeft: '5%',
        marginRight: '5%',
      },
      minWidth: 300,
      height: 50,
    },
    submit: {
      float: 'right',
    },
    root: {
      height: '100%',
      [theme.breakpoints.down('md')]: {
        borderRadius: '4px 4px 0 0',
      },
    },
    header: {
      lineHeight: '52px',
      fontSize: 44,
    },
    text: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        marginRight: 20,
        marginLeft: 20,
      },
      marginRight: -20,
      paddingBottom: 15,
    },
    headerContainer: {
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
      flexWrap: 'nowrap',
    },
    body: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(5),
      },
    },
    buttonContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '40px !important',
      },
    },
    textContainer: {
      width: '100%',
    },
    additionalInfoContainer: {
      height: '100%',
      overflowY: 'auto',
    },
    rightButtonContainer: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },
    mainContainer: {
      minHeight: 0,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  }),
  { name: 'RegistrationPage' },
)

interface RegistrationPageProps {
  additionalInfo?: React.ReactNode
  backLabel?: string
  buttonColor?: FabProps['color']
  children: React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  header: React.ReactNode
  onBack?: () => void
  onReject?: () => void
  onSubmit: () => void
  rejectLabel?: string
  subheader?: string
  submitLabel: string
}

const RegistrationPage = ({
  additionalInfo,
  backLabel,
  children,
  disabled,
  header,
  onBack,
  onReject,
  onSubmit,
  rejectLabel = '',
  subheader = '',
  submitLabel,
  className,
  buttonColor,
  classes: classesProp,
}: RegistrationPageProps) => {
  const classes = useStyles({ classes: classesProp })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isUserAlreadyExist = useSelector(getIsUserAlreadyExist)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { t } = useTranslation('Registration')

  const handleCancelAlert = () => {
    dispatch(clearUserAlreadyExists())
  }

  const handleOkAlert = () => {
    dispatch(clearUserAlreadyExists())

    const redirectUrl = `/admin/general/practices?alert=${ALERT_TYPES.ADD_PRACTICE}`
    navigate(`/auth/login?redirectTo=${encodeURIComponent(redirectUrl)}`)
  }

  return (
    <Grid
      container
      className={classNames(className, classes.root)}
      direction="column"
      wrap="nowrap"
    >
      {isUserAlreadyExist && (
        <PuiAlert
          cancelButtonText={t(
            'Registration:REGISTRATION_PAGE.ALERT_CANCEL_BUTTON_TEXT',
          )}
          iconType={AlertIconType.WARN}
          message={t('Registration:REGISTRATION_PAGE.ALERT_MESSAGE')}
          okButtonText={t(
            'Registration:REGISTRATION_PAGE.ALERT_OK_BUTTON_TEXT',
          )}
          open={isUserAlreadyExist}
          onCancel={handleCancelAlert}
          onClose={handleCancelAlert}
          onOk={handleOkAlert}
        />
      )}
      <Grid container item className={classes.mainContainer} wrap="nowrap">
        <Grid
          container
          item
          className={classes.headerContainer}
          direction="column"
          md={3}
          xs={12}
        >
          <Grid item className={classes.textContainer}>
            <Text
              strong
              className={classNames(classes.header, classes.text)}
              variant="hero"
            >
              {header}
            </Text>
          </Grid>
          {subheader && (
            <Grid item className={classes.textContainer}>
              <HtmlNotesPreview
                classes={{ text: classes.text }}
                fontSize="2rem"
                notes={subheader}
                variant="h3"
              />
            </Grid>
          )}
          {additionalInfo && (
            <Grid
              container
              item
              className={classNames(
                classes.textContainer,
                classes.additionalInfoContainer,
              )}
              justifyContent="center"
            >
              {additionalInfo}
            </Grid>
          )}
        </Grid>
        <Grid container item className={classes.body} md={9} xs={12}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          justifyContent="center"
          md={3}
          xs={12}
        >
          {backLabel && <BackButton label={backLabel} onClick={onBack} />}
        </Grid>
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          className={classes.buttonContainer}
          justifyContent="center"
          md={9}
          xs={12}
        >
          <Grid
            container
            item
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            md={6}
            xs={12}
          >
            {rejectLabel && (
              <MarketingStylesButton
                className={classes.button}
                disabled={disabled}
                onClick={() => onReject && onReject()}
              >
                {rejectLabel}
              </MarketingStylesButton>
            )}
          </Grid>
          <Grid
            container
            item
            alignContent="center"
            alignItems="center"
            className={classes.rightButtonContainer}
            justifyContent={isMobile ? 'center' : 'flex-end'}
            md={6}
            xs={12}
          >
            {buttonColor ? (
              <Fab
                autoFocus
                className={classNames(classes.button, classes.submit)}
                color={buttonColor}
                disabled={disabled}
                type="submit"
                variant="extended"
                onClick={onSubmit}
              >
                {submitLabel}
              </Fab>
            ) : (
              <MarketingStylesButton
                className={classNames(classes.button, classes.submit)}
                disabled={disabled}
                type="submit"
                onClick={onSubmit}
              >
                {submitLabel}
              </MarketingStylesButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RegistrationPage
