import { gql } from '@apollo/client'

export const CREATE_INVOICE_DISCOUNT_REASON = gql`
  mutation CreateInvoiceDiscountReason($input: InvoiceDiscountReasonInput!) {
    createInvoiceDiscountReason(input: $input) {
      id
      business {
        id
      }
      name
      active
      common
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_INVOICE_DISCOUNT_REASON_STATUS = gql`
  mutation UpdateStatusInvoiceDiscountReason(
    $businessId: ID!
    $discountReasonId: ID!
    $active: Boolean!
  ) {
    updateStatusInvoiceDiscountReason(
      businessId: $businessId
      discountReasonId: $discountReasonId
      active: $active
    ) {
      id
      business {
        id
      }
      name
      active
      common
      createdAt
      updatedAt
    }
  }
`

export const DELETE_INVOICE_DISCOUNT_REASON = gql`
  mutation DeleteInvoiceDiscountReason($id: ID!) {
    deleteInvoiceDiscountReason(discountReasonId: $id)
  }
`
