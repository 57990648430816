import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Nil, PermissionArea, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { getCRUDByArea } from '~/store/reducers/auth'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    root: {},
    text: {},
    button: {
      minWidth: 150,
      height: 40,
    },
  }),
  { name: 'RemindersSectionHeader' },
)

export interface RemindersSectionHeaderProps {
  classes?: ClassesType<typeof useStyles>
  patientId: string | Nil
}

const RemindersSectionHeader = ({
  patientId,
  classes: classesProp,
}: RemindersSectionHeaderProps) => {
  const classes = useStyles({ classes: classesProp })
  const permissions = useSelector(getCRUDByArea(PermissionArea.REMINDERS))
  const { t } = useTranslation('Common')

  const [openAddReminderDialog] = useDialog(DialogNames.ADD_REMINDER)

  const handleAdd = () => {
    openAddReminderDialog({ patientId })
  }

  return (
    <Grid container item className={classes.root} p={2}>
      <Grid item xs>
        <Text className={classes.text} pr={2} variant="h4">
          {t('Common:REMINDERS')}
        </Text>
      </Grid>
      <Grid item>
        <Fab
          className={classes.button}
          color="inherit"
          disabled={!permissions.create}
          variant="extended"
          onClick={handleAdd}
        >
          {t('Common:ADD_REMINDER')}
        </Fab>
      </Grid>
    </Grid>
  )
}

export default RemindersSectionHeader
