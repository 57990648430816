import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CurrencyTextField,
  DateUtils,
  Nil,
  NumberUtils,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import {
  clearManualPriceException,
  getManualPriceChange,
  isAllPricesSelected,
  setManualPriceException,
  setManualSelection,
} from '~/store/duck/bulkPrices'
import { getConstantTranslatedName } from '~/store/reducers/constants'
import { BulkPriceEdit } from '~/types'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import { useRecalculatePrice } from './bulkPricesUtils'

const useStyles = makeStyles(
  (theme) => ({
    control: {
      padding: theme.spacing(0.5),
    },
    warnIcon: {
      fontSize: '1.4rem',
      color: theme.colors.alertWarning,
    },
  }),
  { name: 'BulkPricesEditSubItem' },
)

interface BulkPricesEditSubItemProps {
  className?: string
  price: BulkPriceEdit | Nil
}

const BulkPricesEditSubItem = ({
  className,
  price,
}: BulkPricesEditSubItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const {
    id: priceId,
    name,
    speciesId,
    genderId,
    updatedAt,
    value: initialPrice = 0,
  } = price || {}

  const manualPriceValue = useSelector(getManualPriceChange(priceId))
  const genderRestrictions =
    useSelector(getConstantTranslatedName('Gender', genderId)) ||
    t('Common:ALL')
  const speciesRestrictions =
    useSelector(getConstantTranslatedName('Species', speciesId)) ||
    t('Common:ALL')
  const isSelected = useSelector(isAllPricesSelected([priceId as string]))
  const recalculatedPrice = useRecalculatePrice(priceId, initialPrice)
  const priceUnit = useGetFormattedPriceUnit(price)

  const hasChangedPrice = !R.isNil(manualPriceValue)

  const handleManualPriceUpdate = (value: number) => {
    const newValue = Number(value)

    if (priceId) {
      if (newValue === initialPrice) {
        dispatch(clearManualPriceException({ priceId }))
      } else {
        dispatch(setManualPriceException({ priceId, value: newValue }))
      }
    }
  }

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    dispatch(setManualSelection({ priceIds: [priceId], state: !isSelected }))
  }

  return (
    <Grid container className={className} px={0.5} py={1}>
      <Grid container item alignItems="center" columns={{ xs: 10 }}>
        <Grid container item justifyContent="flex-end" width={70}>
          <Checkbox
            checked={isSelected}
            className={classes.control}
            sx={{ mr: 1 }}
            onChange={handleSelectionChange}
          />
        </Grid>
        <Grid item xs pl={1} py={0.5}>
          <Text disabled={!isSelected} variant="body">
            {name || t('Common:DEFAULT')}
          </Text>
        </Grid>
        <Grid container item justifyContent="flex-end" pl={1} pr={2} xs={1}>
          {hasChangedPrice && <WarnAlert className={classes.warnIcon} />}
        </Grid>
        <Grid item my={-0.5} pl={1} pr={3} py={0.5} xs={1}>
          <CurrencyTextField
            shrink
            disabled={!isSelected}
            margin="none"
            value={recalculatedPrice < 0 ? 0 : recalculatedPrice}
            onChange={Utils.handleFormNumberInput(handleManualPriceUpdate)}
          />
        </Grid>
        <Grid item pl={1} py={0.5} xs={1}>
          <Text disabled={!isSelected} variant="body">
            {NumberUtils.formatMoney(initialPrice)}
          </Text>
        </Grid>
        <Grid item pl={1} py={0.5} xs={1}>
          <Text disabled={!isSelected} variant="body">
            {priceUnit}
          </Text>
        </Grid>
        <Grid item pl={1} py={0.5} xs={1}>
          <Text
            disabled={!isSelected}
            sx={{ overflowWrap: 'break-word' }}
            variant="body"
          >
            {DateUtils.formatDate(updatedAt)}
          </Text>
        </Grid>
        <Grid item pl={1} py={0.5} xs={1}>
          <Text disabled={!isSelected} variant="body">
            {speciesRestrictions}
          </Text>
        </Grid>
        <Grid item pl={1} py={0.5} xs={1}>
          <Text disabled={!isSelected} variant="body">
            {genderRestrictions}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BulkPricesEditSubItem
