import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import { useCreatePatient } from '~/store/hooks/patient'
import { getPatientsIsLoading } from '~/store/reducers/patients'

import ClientDetails from '../details/ClientDetails'
import DetailsBackButton from '../DetailsBackButton'
import Patient, { PatientHandle } from '../patient/Patient'
import PatientFormTitle from '../patient/PatientFormTitle'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    formContainer: {
      backgroundColor: theme.colors.tableBackground,
      [theme.breakpoints.up('lg')]: {
        maxWidth: 650,
      },
    },
    button: {
      width: 200,
    },
    buttonsContainer: {
      width: '100%',
      padding: theme.spacing(2, 3),
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
    },
  }),
  { name: 'NewClientPatientPage' },
)

const NewClientPatientPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { clientId = '', patientId = '' } = useParams()
  const { t } = useTranslation(['Common', 'Clients'])
  const { createPatient } = useCreatePatient()
  const patientIsLoading = useSelector(getPatientsIsLoading)

  const patientRef = useRef<PatientHandle>(null)

  const onBackButtonClick = () => {
    navigate('/clients')
  }

  return (
    <Grid container className={classes.root}>
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Clients:NEW_CLIENT_PATIENT_PAGE.BACK_TO_CLIENTS_AND_PATIENTS_LIST')}
      </DetailsBackButton>
      <Grid container item xs={12}>
        <Hidden mdDown>
          <Grid container item direction="column" lg={3} md={5}>
            <ClientDetails
              disablePatientNavigation
              hideAddPatient
              hideAddPetFriend
              hideBalance
              patientId={patientId}
            />
          </Grid>
        </Hidden>
        <Grid
          container
          item
          className={classes.formContainer}
          direction="column"
          lg={6}
          md={7}
          sm={12}
          xs={12}
        >
          <PatientFormTitle patientId={patientId} pt={2} px={3} />
          <Patient
            clientId={clientId}
            createPatient={createPatient}
            patientId={patientId}
            ref={patientRef}
            onOk={() => navigate(`/client/${clientId}/patient/${patientId}`)}
          />
          <Grid container item className={classes.buttonsContainer}>
            <Grid item>
              <ButtonWithLoader
                className={classes.button}
                disabled={patientIsLoading}
                loading={patientIsLoading}
                onClick={() => patientRef.current?.addPatient()}
              >
                {t('Common:SAVE_ACTION')}
              </ButtonWithLoader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewClientPatientPage
