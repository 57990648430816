import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ErrorTooltip, PuiTextField } from '@pbt/pbt-ui-components'

import AddList from '~/components/common/AddList'
import ClinicLogo from '~/components/common/logos/ClinicLogo'
import {
  removeBusiness,
  updateBusiness,
  updateBusinessCandidate,
} from '~/store/actions/registration'
import Business, { BusinessDto } from '~/store/dto/Business'
import { getRegistrationBusinesses } from '~/store/reducers/registration'

import RegistrationPage from './RegistrationPage'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    clinicsSection: {
      border: theme.constants.fabBorder,
      borderRadius: 4,
      marginBottom: 2,
      height: 402,
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        maxHeight: 502,
        marginBottom: 25,
      },
    },
    groupNameContainer: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: 'rgba(229, 229, 229, 0.2)',
      borderRadius: '4px 4px 0 0',
      height: 62,
    },
    imageRoot: {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
      width: 108,
      height: 61,
      margin: 2,
    },
  }),
  { name: 'ClinicsList' },
)

const ClinicsList = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const businesses = useSelector(getRegistrationBusinesses)
  const { t } = useTranslation(['Common', 'Registration', 'Tooltips'])

  const [touched, setTouched] = useState(true)

  const businessesArray = Object.values(businesses || {})

  const clinics = businessesArray.filter((clinic) => !clinic.isGroup)

  const clinicsMap = clinics.reduce(
    (acc, clinic) => ({ ...acc, [clinic.id]: clinic }),
    {} as Record<string, BusinessDto>,
  )

  const GetImageComponent = useCallback(
    (id: string) => (
      <ClinicLogo
        classes={{ root: classes.imageRoot }}
        clinic={clinicsMap[id]}
      />
    ),
    [clinicsMap],
  )

  if (businessesArray.length === 0) {
    return <Navigate replace to="/register/clinic/select" />
  }

  const getGroup = () => {
    const groups = businessesArray.filter((_) => _.isGroup)
    return groups.length ? groups[0] : null
  }

  const getGroupName = () => {
    const group = getGroup()
    return group ? group.name : ''
  }

  const updateGroupName = (name: string) => {
    if (businessesArray.length > 1) {
      const group = getGroup()
      if (group) {
        group.name = name
        dispatch(updateBusiness(group))
      } else {
        dispatch(
          updateBusiness(new Business({ name, isGroup: true } as BusinessDto)),
        )
      }
    }
  }

  const onSubmit = () => {
    setTouched(false)
    if (businessesArray.length > 1) {
      if (!getGroupName()) {
        return
      }
    }
    navigate('/register/manager')
  }

  return (
    <RegistrationPage
      className={classes.root}
      header={t('Registration:CLINICS_LIST.HEADER')}
      subheader={t('Registration:CLINICS_LIST.SUB_HEADER')}
      submitLabel={t('Registration:CLINICS_LIST.SUBMIT_LABEL')}
      onSubmit={onSubmit}
    >
      <Grid
        container
        item
        className={classes.clinicsSection}
        direction="column"
      >
        {clinics.length > 1 && (
          <div className={classes.groupNameContainer}>
            <ErrorTooltip
              message={t('Tooltips:PLEASE_ENTER_GROUP_NAME')}
              open={!touched && !getGroupName()}
            >
              <PuiTextField
                error={!touched && !getGroupName()}
                label={`${t('Registration:CLINICS_LIST.ENTER_GROUP_NAME')}*`}
                value={getGroupName()}
                onChange={(event) => {
                  setTouched(true)
                  updateGroupName(event.target.value)
                }}
              />
            </ErrorTooltip>
          </div>
        )}
        <AddList
          addText={t('Common:ADD_ANOTHER_PRACTICE')}
          getImage={GetImageComponent}
          items={clinics.map(({ id, name }) => ({ id, label: name }))}
          onAdd={() => navigate('/register/clinic/select')}
          onDelete={(businessId) => dispatch(removeBusiness(businessId))}
          onEdit={(id) => {
            dispatch(updateBusinessCandidate(businesses[id]))
            navigate('/register/clinic/edit')
          }}
        />
      </Grid>
    </RegistrationPage>
  )
}

export default ClinicsList
