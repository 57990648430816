import React from 'react'
import { useSelector } from 'react-redux'
import { LanguageUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { getResponsibilities } from '~/store/reducers/constants'
import { getRole } from '~/store/reducers/roles'
import {
  TimetableEventPersonResponsibility,
  TimetableEventPersonRole,
} from '~/types'

export interface WhiteboardTreatmentsAssignedCellProps {
  personResponsibility?: TimetableEventPersonResponsibility
  personRole?: TimetableEventPersonRole
}

const WhiteboardTreatmentsAssignedCell = ({
  personRole,
  personResponsibility,
}: WhiteboardTreatmentsAssignedCellProps) => {
  const role = useSelector(getRole(personRole?.roleId || ''))
  const responsibilities = useSelector(getResponsibilities)
  const responsibility = Utils.findConstantByName(
    personResponsibility?.responsibility.name || '',
    responsibilities,
  )

  return (
    <Text
      key={
        responsibility
          ? personResponsibility?.responsibilityId
          : personRole?.roleId
      }
      variant="body2"
    >
      {`${LanguageUtils.getTranslatedFieldName(
        responsibility || role,
      )}: ${Utils.getPersonString(
        responsibility ? personResponsibility?.person : personRole?.person,
      )}`}
    </Text>
  )
}

export default WhiteboardTreatmentsAssignedCell
