import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import {
  AlertIconType,
  Defaults,
  PuiAlert,
  PuiDialogProps,
} from '@pbt/pbt-ui-components'

const ReportUnavailableDialog = ({ open, onClose }: PuiDialogProps) => {
  const { t } = useTranslation('Errors')
  const navigate = useNavigate()

  const emailHrefWithMessage = `mailto:${Defaults.SUPPORT_EMAIL}?subject=${t(
    'Errors:REPORT_UNAVAILABLE.SUBJECT',
  )}`

  const onOk = () => {
    onClose?.()
    navigate('/')
  }

  return (
    <PuiAlert
      disableClose
      hideCloseButton
      content={
        <Link href={emailHrefWithMessage}>{Defaults.SUPPORT_EMAIL}</Link>
      }
      iconType={AlertIconType.WARN}
      message={t('Errors:REPORT_UNAVAILABLE.MESSAGE')}
      okButtonText={t('Errors:REPORT_UNAVAILABLE.BUTTON_HOMEPAGE')}
      open={open}
      onOk={onOk}
    />
  )
}

export default ReportUnavailableDialog
