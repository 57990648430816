import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getLabTestTypes } from '~/store/reducers/constants'

export interface LabTestTypeFilterProps extends CheckboxListFilterProps {}

const LabTestTypeFilter = (props: LabTestTypeFilterProps) => {
  const LabTestTypes = useSelector(getLabTestTypes)

  return <CheckboxListFilter arrayFormat items={LabTestTypes} {...props} />
}

export default LabTestTypeFilter
