import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { UnsavedVital, VitalRecord } from '~/types'

const emptyStringValueToNull = (record?: VitalRecord<any> | Nil) =>
  record?.notes
    ? {
        ...record,
        value: !record?.value ? null : record?.value,
      }
    : record

const valueToNumber = (record?: VitalRecord | Nil) => {
  if (record?.value && !R.is(Number, record.value)) {
    return {
      ...record,
      value: Number(record.value),
    }
  }
  return emptyStringValueToNull(record)
}

const nonExitingValueToNull = (
  isNew: boolean,
  handler: (record?: VitalRecord | Nil) => VitalRecord | Nil,
  record?: VitalRecord<any> | null,
) => (isNew ? emptyStringValueToNull(record) : handler(record) || null)

export const vitalInputDTO = (
  isNew: boolean,
  record: UnsavedVital,
  soapId?: string | Nil,
  patientId?: string | Nil,
) => ({
  recordedDate: record.recordedDate || null,
  recordedById: record.recordedById || null,
  patientId: patientId || record.patientId || null,
  soapId: soapId || record.soapId || null,
  weight: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.weight as VitalRecord,
  ),
  temperature: nonExitingValueToNull(isNew, valueToNumber, record.temperature),
  heartRate: nonExitingValueToNull(isNew, valueToNumber, record.heartRate),
  respirationRate: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.respirationRate,
  ),
  capillaryRefillTime: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.capillaryRefillTime,
  ),
  mucousMembraneColorId: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.mucousMembraneColorId,
  ),
  mucousMembraneMoistureId: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.mucousMembraneMoistureId,
  ),
  skinTurgorId: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.skinTurgorId,
  ),
  bodyConditionScore: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.bodyConditionScore,
  ),
  fearAnxietyScore: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.fearAnxietyScore,
  ),
  painScore: nonExitingValueToNull(isNew, valueToNumber, record.painScore),
  dyspnea: nonExitingValueToNull(isNew, emptyStringValueToNull, record.dyspnea),
  mentationId: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.mentationId,
  ),
  pulseCharacterId: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.pulseCharacterId,
  ),
  bloodPressure: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.bloodPressure,
  ),
  spo2: nonExitingValueToNull(isNew, valueToNumber, record.spo2),
  etco2: nonExitingValueToNull(isNew, valueToNumber, record.etco2),
  o2Level: nonExitingValueToNull(isNew, valueToNumber, record.o2Level),
  isoSevoLevel: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.isoSevoLevel,
  ),
  recumbencyId: nonExitingValueToNull(
    isNew,
    emptyStringValueToNull,
    record.recumbencyId,
  ),
  muscleMassScore: nonExitingValueToNull(
    isNew,
    valueToNumber,
    record.muscleMassScore,
  ),
})
