import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Field,
  PuiTextField,
  PuiTextFieldProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { getRequiredLabel } from '~/utils'

import { partialMatch } from './ndcUtils'

const isDigitOrHyphens = (char: string) => Utils.isDigit(char) || char === '-'

const handleNationalDrugCodeChange = (
  value = '',
  setValue: (value: string) => void,
) => {
  const validValue =
    value.split('').every(isDigitOrHyphens) && partialMatch(value)

  if (validValue) {
    setValue(value)
  }
}

export type NdcNumberInputProps = PuiTextFieldProps & {
  field: Field
}

const NdcNumberInput = ({ field, required, ...rest }: NdcNumberInputProps) => {
  const { t } = useTranslation('Abbreviations')

  return (
    <PuiTextField
      field={{
        ...field,
        set: Utils.handleFormTextInput((value) =>
          handleNationalDrugCodeChange(value, field.setValue),
        ),
      }}
      inputProps={{ maxLength: 13 }}
      label={getRequiredLabel(
        field.label || t('Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.NUMBER'),
        required,
      )}
      {...rest}
    />
  )
}

export default NdcNumberInput
