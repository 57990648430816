import {
  FileTemplate,
  GenderRestriction,
  Nil,
  User,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

export enum DocumentElementType {
  RICH_TEXT_WITH_PLACEHOLDERS = 'RICH_TEXT_WITH_PLACEHOLDERS',
  PAGE_BREAK = 'PAGE_BREAK',
  DATE_PICKER = 'DATE_PICKER',
  DATE_TIME_PICKER = 'DATE_TIME_PICKER',
  RADIO_GROUP = 'RADIO_GROUP',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  CHECKBOX = 'CHECKBOX',
  SIGNATURE_BOX = 'SIGNATURE_BOX',
  TEXT_FIELD = 'TEXT_FIELD',
}

export enum InternalDispalyPreference {
  AS_WRITTEN = 'AS_WRITTEN',
  SUMMARIZED = 'SUMMARIZED',
  DO_NOT_DISPLAY = 'DO_NOT_DISPLAY',
}

export const internalDispalyPreferenceLabel = {
  [InternalDispalyPreference.AS_WRITTEN]: i18n.t('Common:AS_WRITTEN'),
  [InternalDispalyPreference.SUMMARIZED]: i18n.t('Common:SUMMARIZED'),
  [InternalDispalyPreference.DO_NOT_DISPLAY]: i18n.t('Common:DO_NOT_DISPLAY'),
}

export const internalDispalyPreferenceSelectItems = [
  {
    id: InternalDispalyPreference.AS_WRITTEN,
    name: internalDispalyPreferenceLabel[InternalDispalyPreference.AS_WRITTEN],
  },
  {
    id: InternalDispalyPreference.SUMMARIZED,
    name: internalDispalyPreferenceLabel[InternalDispalyPreference.SUMMARIZED],
  },
  {
    id: InternalDispalyPreference.DO_NOT_DISPLAY,
    name: internalDispalyPreferenceLabel[
      InternalDispalyPreference.DO_NOT_DISPLAY
    ],
  },
]

export type DocumentElementStateItem =
  | RichTextWithPlaceholderState
  | CheckboxGroupState
  | CheckboxState
  | RadioGroupState
  | TextEntryState
  | PageBreakState
  | DatePickerState
  | DateAndTimePickerState

type DocumentElementItemEdit = {
  creationDate: string
  creatorId: string
  id: number
  modificationDate: string
  modifierId: string
  sequenceNumber: number
}

type RichTextWithPlaceholderCreate = {
  contentWithPlaceholders: string
  sequenceNumber: number
  type: DocumentElementType.RICH_TEXT_WITH_PLACEHOLDERS
}

type RichTextWithPlaceholderEdit = DocumentElementItemEdit & {
  contentWithPlaceholders: string
  type: DocumentElementType.RICH_TEXT_WITH_PLACEHOLDERS
}

export type RichTextWithPlaceholderState = RichTextWithPlaceholderCreate & {
  id: string
}

export type PageBreakState = {
  id?: string
  page: number
  sequenceNumber?: number
  totalPages: number
  type?: DocumentElementType.PAGE_BREAK
}

type SelectionOptionCreate = {
  internalDisplayPreference: InternalDispalyPreference
  internalName?: string
  name: string
  sequenceNumber: number
}

type CheckboxOptionCreate = {
  inputAction?: string
  internalDisplayPreference: InternalDispalyPreference
  internalName?: string
  name: string
  sequenceNumber: number
}

type CheckboxOptionEdit = DocumentElementItemEdit & CheckboxOptionCreate
type RadioOptionEdit = DocumentElementItemEdit & RadioOptionCreate

export type CheckboxOptionState = CheckboxOptionCreate & {
  id?: number
}

type CheckboxGroupCreate = {
  label: string
  options: CheckboxOptionCreate[]
  sequenceNumber: number
  type: DocumentElementType.CHECKBOX_GROUP
}

type CheckboxGroupEdit = DocumentElementItemEdit & {
  label: string
  options: CheckboxOptionEdit[]
  type: DocumentElementType.CHECKBOX_GROUP
}

type RadioGroupEdit = DocumentElementItemEdit & {
  label: string
  options: RadioOptionEdit[]
  type: DocumentElementType.RADIO_GROUP
}

export type CheckboxGroupState = Omit<
  CheckboxGroupCreate,
  'sequenceNumber' | 'type' | 'option'
> & {
  id?: string
  options: CheckboxOptionState[]
  sequenceNumber?: number
  type?: DocumentElementType.CHECKBOX_GROUP
}

export type CheckboxCreate = {
  label?: string
  option: CheckboxOptionCreate
  sequenceNumber: number
  type: DocumentElementType.CHECKBOX
}

export type CheckboxState = Omit<
  CheckboxCreate,
  'sequenceNumber' | 'option' | 'type'
> & {
  id?: string
  option: CheckboxOptionState
  sequenceNumber?: number
  type?: DocumentElementType.CHECKBOX
}

type CheckboxEdit = DocumentElementItemEdit & {
  label?: string
  option: CheckboxOptionEdit
  type: DocumentElementType.CHECKBOX
}

export type DatePickerCreate = {
  label: string
  type: DocumentElementType.DATE_PICKER
}

export type DateAndTimePickerCreate = {
  label: string
  type: DocumentElementType.DATE_TIME_PICKER
}

type DatePickerEdit = DocumentElementItemEdit & {
  label: string
  type: DocumentElementType.DATE_PICKER
}

type DateAndTimePickerEdit = DocumentElementItemEdit & {
  label: string
  type: DocumentElementType.DATE_TIME_PICKER
}

export type DatePickerState = Omit<
  DatePickerCreate,
  'sequenceNumber' | 'type'
> & {
  id?: string
  sequenceNumber?: number
  type?: DocumentElementType.DATE_PICKER
}

export type DateAndTimePickerState = Omit<
  DateAndTimePickerCreate,
  'sequenceNumber' | 'type'
> & {
  id?: string
  sequenceNumber?: number
  type?: DocumentElementType.DATE_TIME_PICKER
}

type RadioOptionCreate = SelectionOptionCreate

export type SelectionOptionState = SelectionOptionCreate & {
  id?: number
}

export type RadioOptionState = SelectionOptionState

type RadioGroupCreate = {
  label: string
  options: RadioOptionCreate[]
  sequenceNumber: number
  type: DocumentElementType.RADIO_GROUP
}

export type RadioGroupState = Omit<
  RadioGroupCreate,
  'sequenceNumber' | 'type' | 'option'
> & {
  id?: string
  options: RadioOptionCreate[]
  sequenceNumber?: number
  type?: DocumentElementType.RADIO_GROUP
}

type PageBreakEdit = DocumentElementItemEdit & {
  type: DocumentElementType.PAGE_BREAK
}

type SignatureBoxEdit = DocumentElementItemEdit & {
  type: DocumentElementType.SIGNATURE_BOX
}

export type TextEntryCreate = {
  label: string
  type: DocumentElementType.TEXT_FIELD
}

export type TextEntryState = Omit<
  TextEntryCreate,
  'sequenceNumber' | 'type'
> & {
  id?: string
  sequenceNumber?: number
  type?: DocumentElementType.TEXT_FIELD
}

type TextEntryEdit = DocumentElementItemEdit & {
  label: string
  type: DocumentElementType.TEXT_FIELD
}

type DocumentElementEdit =
  | RichTextWithPlaceholderEdit
  | PageBreakEdit
  | CheckboxEdit
  | CheckboxGroupEdit
  | RadioGroupEdit
  | SignatureBoxEdit
  | TextEntryEdit
  | DatePickerEdit
  | DateAndTimePickerEdit

export type ExpirationPeriodSpecifier = 'Y' | 'M' | 'D'

// For this use case, we only consider periods with one specifier
// Ex: 'P1Y' or 'P3M' but not 'P1Y3M'
export type ExpirationPeriod = `P${number}${ExpirationPeriodSpecifier}`

export type Document = {
  active?: boolean
  appointmentTypeIds?: string[]
  author?: User
  businessId?: string
  creationDate?: string
  displayLocationIds?: string[]
  expirationDateOffset?: ExpirationPeriod | Nil
  extension?: string | Nil
  file?: FileTemplate
  fileUrl?: string | Nil
  genderRestrictions?: GenderRestriction[]
  id: string
  includeInKiosk?: boolean
  name: string
  required?: boolean
  soapId?: string | Nil
  speciesIds?: string[]
  subject?: string
  template?: {
    body?: string
    documentElements?: DocumentElementEdit[]
    extension?: string | Nil
    file?: any
    fileName?: string | Nil
    fileUrl?: string | Nil
  }
  type?: string
}

export type DocumentContext = {
  clientId?: string | Nil
  eventId?: string | Nil
  patientId?: string | Nil
  soapId?: string | Nil
}

export type DocumentToSend = {
  document: Blob
  file: any
  id: string
}
