import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateFormat,
  LanguageUtils,
  moment,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import AppointmentStateLabel from '~/components/common/labels/AppointmentStateLabel'
import { AssignedSoapDetail, EstimateEvent } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    label: {
      wordBreak: 'break-word',
      margin: theme.spacing(0, 1, 0, 2),
    },
  }),
  { name: 'EyePopperRow' },
)

interface EyePopperRowProps {
  assignedSoapDetails: AssignedSoapDetail[] | Nil
  event: EstimateEvent
}

const EyePopperRow = ({ event, assignedSoapDetails }: EyePopperRowProps) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const soapTypeName = LanguageUtils.getTranslatedFieldName(event.type)
  const soapScheduledDate = moment(event.scheduledStartDatetime).format(
    DateFormat.FULL_DATE_YEAR_ABBREV,
  )
  const soapId = assignedSoapDetails?.find(
    (soap: AssignedSoapDetail) => soap?.eventId === event.id,
  )?.soapId

  const navigateToSoap = () => {
    if (soapId) {
      navigate(`/soap/${soapId}`)
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      display="flex"
      justifyContent="space-between"
      maxWidth={420}
      minWidth={350}
      p={1}
    >
      <Text
        inline
        link={Boolean(soapId)}
        variant="body2"
        onClick={navigateToSoap}
      >
        {soapTypeName} {soapScheduledDate}
      </Text>
      <AppointmentStateLabel className={classes.label} item={event} />
    </Grid>
  )
}

export default EyePopperRow
