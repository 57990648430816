import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Nil, Text, useFields } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import SoapWidget from '../SoapWidget'

export interface ReasonForVisitWidgetLockedProps {
  appointmentNotes: string | Nil
}

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'ReasonForVisitWidgetLocked' },
)

const ReasonForVisitWidgetLocked = ({
  appointmentNotes,
}: ReasonForVisitWidgetLockedProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isRichTextNotesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_DIALOG_ADDITIONAL_FIELDS),
  )

  const { fields } = useFields([
    {
      name: 'notes',
      label: t('Common:REASON_FOR_VISIT'),
      initialValue: appointmentNotes || '',
    },
  ])

  if (!appointmentNotes) {
    return null
  }

  return (
    <SoapWidget
      classes={{ content: classes.content }}
      title={t('Common:REASON_FOR_VISIT')}
    >
      {isRichTextNotesEnabled ? (
        <NotesTemplateInput
          disabled
          singleLine
          field={fields.notes}
          maxHeight={150}
          minHeight={30}
        />
      ) : (
        <Text>{appointmentNotes}</Text>
      )}
    </SoapWidget>
  )
}

export default ReasonForVisitWidgetLocked
