import React from 'react'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'inline-flex',
      cursor: 'pointer',
    },
    iconContainer: {
      marginRight: theme.spacing(1),
      backgroundColor: '#9F9B9B',
      '&&&&:hover': {
        backgroundColor: '#9F9B9B',
      },
      width: 20,
      height: 20,
      padding: 0,
    },
    icon: {
      color: '#FFFFFF',
      width: 16,
      height: 16,
    },
    label: {
      userSelect: 'none',
      color: '#9F9B9B',
      fontWeight: 500,
    },
  }),
  { name: 'TinyActionButton' },
)

export interface TinyActionButtonProps {
  Icon: React.JSXElementConstructor<any>
  label: string
  onClick?: () => void
}

const TinyActionButton = ({ label, onClick, Icon }: TinyActionButtonProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} role="button" onClick={onClick}>
      <IconButton disableRipple className={classes.iconContainer} size="large">
        <Icon className={classes.icon} />
      </IconButton>
      <Text className={classes.label} variant="body2">
        {label}
      </Text>
    </Box>
  )
}

export default TinyActionButton
