import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFields } from '@pbt/pbt-ui-components'

import { AppointmentState } from '~/constants/appointmentStates'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { patchAppointment } from '~/store/actions/timetable'
import { getCurrentBusinessIsAppointmentCancellationReasonEnabled } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { TimetableEvent } from '~/types'
import useDialog from '~/utils/useDialog'
import { useAppointmentStateId } from '~/utils/useEventType'

export const useAppointmentStatusChange = (
  appointment: TimetableEvent | undefined,
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const isAppointmentCancellationReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_CANCELLATION_REASON),
  )
  const isCurrentBusinessIsAppointmentCancellationReasonEnabled = useSelector(
    getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  )

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)

  const [openAppointmentCancellationReasonDialog] = useDialog(
    DialogNames.APPOINTMENT_CANCELLATION_REASON,
  )

  const {
    fields: { appointmentStatus },
    reset,
  } = useFields(
    [
      {
        name: 'appointmentStatus',
        label: t('Common:APPOINTMENT_STATUS'),
        initialValue: appointment?.state?.id || '',
      },
    ],
    false,
  )

  const onHandleStatusChange = (state: string) => {
    if (appointment?.id) {
      if (
        isAppointmentCancellationReasonEnabled &&
        isCurrentBusinessIsAppointmentCancellationReasonEnabled &&
        state === cancelledStateId
      ) {
        openAppointmentCancellationReasonDialog({
          onProceed: (
            cancellationReasonId: string,
            cancellationReason: string,
          ) => {
            dispatch(
              patchAppointment({
                id: appointment?.id,
                state,
                cancellationReasonId,
                cancellationReason,
              }),
            )
          },
          onCancel: reset,
        })
      } else {
        dispatch(
          patchAppointment({
            id: appointment?.id,
            state,
          }),
        )
      }
    }
  }

  useEffect(() => {
    reset()
  }, [appointment])

  useEffect(() => {
    if (appointmentStatus.value !== appointmentStatus.initialValue) {
      onHandleStatusChange(appointmentStatus.value)
    }
  }, [appointmentStatus.value])

  return { onHandleStatusChange, appointmentStatus }
}
