import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Collapse } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea, Text } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getMemberIsLoading } from '~/store/reducers/members'

import type { PrescriptionBodyProps } from './PrescriptionBody'

const useStyles = makeStyles(
  (theme) => ({
    warning: {
      color: 'white',
      backgroundColor: theme.colors.alertLabelError,
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: 3,
      gap: theme.spacing(1),
    },
    warningIcon: {
      width: '1.4rem',
      height: '1.4rem',
    },
    button: {
      textDecoration: 'underline',
      color: 'inherit',
      padding: 0,
      textTransform: 'initial',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      minWidth: 'auto',
      lineHeight: 'inherit',
      verticalAlign: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'DoctorLicenseWarning' },
)

interface DoctorLicenseWarningPros
  extends Pick<PrescriptionBodyProps, 'currentDoctorHasDvmLicense'> {
  doctorId: string
}

const DoctorLicenseWarning = ({
  currentDoctorHasDvmLicense,
  doctorId,
}: DoctorLicenseWarningPros) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation(['Common'])

  const memberIsLoading = useSelector(getMemberIsLoading)
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))

  const navigateToAdminLicensesSection = () => {
    navigate(`/admin/general/members/${doctorId}`)
  }

  const isHidden = currentDoctorHasDvmLicense !== false || memberIsLoading

  return (
    <Collapse in={!isHidden}>
      <Text
        strong
        className={classes.warning}
        mb={2}
        px={1}
        py={0.25}
        variant="body2"
      >
        <WarningIcon className={classes.warningIcon} />
        <span>{t('Common:NO_LICENSE_ON_FILE')} </span>
        {personPermissions.update && (
          <Button
            className={classes.button}
            size="small"
            onClick={navigateToAdminLicensesSection}
          >
            {t('Common:ADD_NOW')}
          </Button>
        )}
      </Text>
    </Collapse>
  )
}

export default DoctorLicenseWarning
