import React from 'react'
import { Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import {
  calculatePrepaidColumns,
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import { OrderType } from '~/constants/SOAPStates'
import {
  InvoiceLineItem,
  InvoiceLineItemPayload,
  RetailOrderLineItemPayload,
} from '~/types'

import ChargeDetailsFieldWrapper from './ChargeDetailsFieldWrapper'

export interface ChargeFieldQuantityProps {
  className?: string
  disabled: boolean
  inputClassName?: string
  item: InvoiceLineItem | RetailOrderLineItem
  onEditInvoiceLineItem: (payload: InvoiceLineItemPayload) => void
  onEditRetailOrderLineItem: (payload: RetailOrderLineItemPayload) => void
  quantityField: Field
  remainingQuantityField: Field
}

const ChargeFieldQuantity = ({
  className: classNameProp,
  disabled: disabledProp,
  inputClassName,
  item,
  onEditInvoiceLineItem,
  onEditRetailOrderLineItem,
  quantityField,
  remainingQuantityField,
}: ChargeFieldQuantityProps) => {
  const disabled = disabledProp || isGroupedInvoiceItem(item)
  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)

  return (
    <ChargeDetailsFieldWrapper disabled={disabled} label={quantityField.label}>
      <QuantityInput
        disableUnderline
        showControls
        allowDecimal={item.logType !== OrderType.LAB_TEST}
        className={classNameProp}
        disabled={disabled}
        field={{
          ...quantityField,
          setValue: (value: number) => {
            quantityField.setValue(value)

            if (!isItemRetailOrderLineItem && item.prepaid) {
              const newItem = { ...item, quantity: value }
              calculatePrepaidColumns(item, newItem, false)
              remainingQuantityField.setValue(newItem.prepaidRemaining)
            }

            if (isItemRetailOrderLineItem) {
              onEditRetailOrderLineItem({ quantity: value })
            } else {
              onEditInvoiceLineItem({ quantity: value })
            }
          },
        }}
        inputProps={{
          'aria-labelledby': 'charge-field-quantity',
          className: inputClassName,
        }}
        min={item.logType !== OrderType.LAB_TEST ? 0.01 : 1}
      />
    </ChargeDetailsFieldWrapper>
  )
}

export default ChargeFieldQuantity
