import React from 'react'
import { useSelector } from 'react-redux'
import {
  Constant,
  SpayedNeuteredField as SpayedNeuteredFieldComponent,
  SpayedNeuteredFieldProps,
} from '@pbt/pbt-ui-components'

import { getGender } from '~/store/reducers/constants'

const SpayedNeuteredField = (
  props: Omit<SpayedNeuteredFieldProps, 'Gender'>,
) => {
  const Gender: Constant[] = useSelector(getGender)

  return <SpayedNeuteredFieldComponent Gender={Gender} {...props} />
}

export default SpayedNeuteredField
