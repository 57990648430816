import React from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      paddingTop: theme.spacing(2),
    },
  }),
  { name: 'MigrationStepActions' },
)

const MigrationStepActions = ({
  children,
  ...props
}: React.PropsWithChildren<GridProps>) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.container} wrap="nowrap" {...props}>
      {children}
    </Grid>
  )
}

export default MigrationStepActions
