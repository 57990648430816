import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { LogoutComponentLocationState } from '~/components/auth/LogoutComponent'
import { invalidateToken } from '~/store/actions/auth'

import { auth0Enabled } from './index'
import usePreLogoutCleanup from './usePreLogoutCleanup'

const useGoAway = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handlePreLogoutCleanup = usePreLogoutCleanup()

  return (url: string) => {
    if (auth0Enabled) {
      handlePreLogoutCleanup()
      navigate('/auth/logout', {
        state: {
          doLogout: url ?? window.location.origin,
        } as LogoutComponentLocationState,
      })
    } else {
      dispatch(invalidateToken())
      window.open(url, '_self')
    }
  }
}

export default useGoAway
