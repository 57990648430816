import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { GlobalInventoryCatalogItem, InventoryItem, TableFilter } from '~/types'

import {
  CREATE_INVENTORY,
  CREATE_INVENTORY_FAILURE,
  CREATE_INVENTORY_SUCCESS,
  DELETE_INVENTORY,
  DELETE_INVENTORY_FAILURE,
  DELETE_INVENTORY_SUCCESS,
  EDIT_INVENTORY,
  EDIT_INVENTORY_FAILURE,
  EDIT_INVENTORY_SUCCESS,
  FETCH_INVENTORIES_LIST,
  FETCH_INVENTORIES_LIST_FAILURE,
  FETCH_INVENTORIES_LIST_SUCCESS,
  FETCH_INVENTORY,
  FETCH_INVENTORY_FAILURE,
  FETCH_INVENTORY_SUCCESS,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_SUCCESS,
  RESET_INVENTORIES,
  SET_INVENTORIES_LIST_FILTERS,
  UPDATE_INVENTORIES,
} from './types/inventories'

export const createInventory = (
  inventory: Partial<InventoryItem> | Partial<GlobalInventoryCatalogItem>,
) => ({
  type: CREATE_INVENTORY,
  inventory,
})
export const createInventorySuccess = (inventory: InventoryItem) => ({
  type: CREATE_INVENTORY_SUCCESS,
  inventory,
})
export const createInventoryFailure = (error: ApiError) => ({
  type: CREATE_INVENTORY_FAILURE,
  error,
})

export const editInventory = (inventory: InventoryItem) => ({
  type: EDIT_INVENTORY,
  inventory,
})
export const editInventorySuccess = (inventoryId: string) => ({
  type: EDIT_INVENTORY_SUCCESS,
  inventoryId,
})
export const editInventoryFailure = (error: ApiError) => ({
  type: EDIT_INVENTORY_FAILURE,
  error,
})

export const updateInventories = (
  inventories: Record<string, InventoryItem>,
) => ({
  type: UPDATE_INVENTORIES,
  inventories,
})
export const resetInventories = () => ({ type: RESET_INVENTORIES })

export const fetchInventory = (inventoryId: string) => ({
  type: FETCH_INVENTORY,
  inventoryId,
})
export const fetchInventorySuccess = (inventoryId: string) => ({
  type: FETCH_INVENTORY_SUCCESS,
  inventoryId,
})
export const fetchInventoryError = (error: ApiError) => ({
  type: FETCH_INVENTORY_FAILURE,
  error,
})

export const fetchInventoriesList = (
  from?: number,
  to?: number,
  query?: string | Nil,
) => ({
  type: FETCH_INVENTORIES_LIST,
  from,
  to,
  query,
})
export const fetchInventoriesListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_INVENTORIES_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchInventoriesListFailure = (error: ApiError) => ({
  type: FETCH_INVENTORIES_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForInventoriesList = (
  from: number,
  to: number,
  query?: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForInventoriesListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForInventoriesListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_INVENTORIES_LIST_FAILURE,
  error,
})

export const deleteInventory = (inventoryId: string) => ({
  type: DELETE_INVENTORY,
  inventoryId,
})
export const deleteInventoryFailure = (error: ApiError) => ({
  type: DELETE_INVENTORY_FAILURE,
  error,
})
export const deleteInventorySuccess = (inventoryId: string) => ({
  type: DELETE_INVENTORY_SUCCESS,
  inventoryId,
})

export const setInventoriesListFilters = (
  filters: Record<string, TableFilter>,
) => ({
  type: SET_INVENTORIES_LIST_FILTERS,
  filters,
})
