import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Nil } from '@pbt/pbt-ui-components'
import { LanguageCodes } from '@pbt/pbt-ui-components/src/localization'

import { PreselectedColumns as InvoicePreselectedColumns } from '~/components/dashboard/invoices/table/invoiceTableConstants'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { PreselectedColumns as OnHandCatalogPreselectedColumns } from '~/constants/onHandConstants'
import i18n from '~/locales/i18n'
import { UserSettingsRecord } from '~/types'

import type { RootState } from '../index'
import { getCurrentUserId } from '../reducers/auth'

export const UPDATE_INVOICE_SELECTED_COLUMNS =
  'userSettings/UPDATE_INVOICE_SELECTED_COLUMNS'
export const UPDATE_ON_HAND_CATALOG_SELECTED_COLUMNS =
  'userSettings/UPDATE_ON_HAND_CATALOG_SELECTED_COLUMNS'
export const ADD_PREVENT_SHOW_AGAIN_DIALOG =
  'userSettings/ADD_PREVENT_SHOW_AGAIN_DIALOG'

export const UPDATE_TIMETABLE_CONFIGURED_COLUMNS =
  'userSettings/UPDATE_TIMETABLE_CONFIGURED_COLUMNS'

export const PIN_MARKETPLACE_PANEL = 'userSettings/PIN_MARKETPLACE_PANEL'
export const UNPIN_MARKETPLACE_PANEL = 'userSettings/UNPIN_MARKETPLACE_PANEL'

export const ADD_FAVORITE_BUSINESS = 'userSettings/ADD_FAVORITE_BUSINESS'
export const REMOVE_FAVORITE_BUSINESS = 'userSettings/REMOVE_FAVORITE_BUSINESS'

export const UPDATE_CURRENT_LANGUAGE = 'userSettings/UPDATE_CURRENT_LANGUAGE'
export const UPDATE_SHOW_OTC_EVENTS = 'userSettings/UPDATE_SHOW_OTC_EVENTS'

export const updateInvoiceSelectedColumns = (
  userId: string,
  columns: string[],
) => ({
  type: UPDATE_INVOICE_SELECTED_COLUMNS,
  userId,
  columns,
})
export const updateOnHandCatalogSelectedColumns = (
  userId: string,
  columns: string[],
) => ({
  type: UPDATE_ON_HAND_CATALOG_SELECTED_COLUMNS,
  userId,
  columns,
})
export const addPreventShowAgainDialog = (
  userId: string,
  dialogName: ConfirmAlertType,
  selectedOption: React.Key | Nil,
) => ({
  type: ADD_PREVENT_SHOW_AGAIN_DIALOG,
  userId,
  dialogName,
  selectedOption,
})

export const updateTimetableConfiguredColumns = (
  userId: string,
  businessId: string,
  area: string,
  columns: string[],
) => ({
  type: UPDATE_TIMETABLE_CONFIGURED_COLUMNS,
  userId,
  businessId,
  area,
  columns,
})

export const pinMarketplacePanel = (userId: string) => ({
  type: PIN_MARKETPLACE_PANEL,
  userId,
})
export const unpinMarketplacePanel = (userId: string) => ({
  type: UNPIN_MARKETPLACE_PANEL,
  userId,
})

export const addFavoriteBusiness = (userId: string, businessId: string) => ({
  type: ADD_FAVORITE_BUSINESS,
  userId,
  businessId,
})
export const removeFavoriteBusiness = (userId: string, businessId: string) => ({
  type: REMOVE_FAVORITE_BUSINESS,
  userId,
  businessId,
})

export const updateCurrentUserLanguage = (
  userId: string,
  languageCode = LanguageCodes['en-US'],
) => ({
  type: UPDATE_CURRENT_LANGUAGE,
  userId,
  languageCode,
})

export const updateShowOtcSaleEvents = (
  userId: string,
  showOtcSaleEvents: boolean = false,
) => ({
  type: UPDATE_SHOW_OTC_EVENTS,
  userId,
  showOtcSaleEvents,
})

export type UserSettingsState = {
  map: Record<string, UserSettingsRecord>
}

const INITIAL_STATE: UserSettingsState = {
  map: {},
}

const USER_DEFAULTS = {
  invoiceSelectedColumns: InvoicePreselectedColumns,
  onHandCatalogSelectedColumns: OnHandCatalogPreselectedColumns,
}

export const userSettingsReducer = (
  state: UserSettingsState = INITIAL_STATE,
  action: AnyAction,
): UserSettingsState => {
  switch (action.type) {
    case UPDATE_INVOICE_SELECTED_COLUMNS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            invoiceSelectedColumns: action.columns,
          },
        },
      }
    case UPDATE_ON_HAND_CATALOG_SELECTED_COLUMNS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            onHandCatalogSelectedColumns: action.columns,
          },
        },
      }
    case UPDATE_TIMETABLE_CONFIGURED_COLUMNS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            timetableConfiguredColumns: {
              ...state.map[action.userId]?.timetableConfiguredColumns,
              [action.area]: {
                ...state.map[action.userId]?.timetableConfiguredColumns?.[
                  action.area
                ],
                [action.businessId]: action.columns,
              },
            },
          },
        },
      }
    case ADD_PREVENT_SHOW_AGAIN_DIALOG:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            preventShowAgainDialogsMap: {
              ...state.map[action.userId]?.preventShowAgainDialogsMap,
              [action.dialogName]: {
                prevent: true,
                selectedOption: action.selectedOption,
              },
            },
          },
        },
      }
    case PIN_MARKETPLACE_PANEL:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            marketplacePanelPinned: true,
          },
        },
      }
    case UNPIN_MARKETPLACE_PANEL:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            marketplacePanelPinned: false,
          },
        },
      }
    case ADD_FAVORITE_BUSINESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            favoriteBusinesses: state.map[action.userId]?.favoriteBusinesses
              ? R.uniq([
                  ...state.map[action.userId].favoriteBusinesses,
                  action.businessId,
                ])
              : [action.businessId],
          },
        },
      }
    case REMOVE_FAVORITE_BUSINESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            favoriteBusinesses: state.map[action.userId]?.favoriteBusinesses
              ? R.without(
                  [action.businessId],
                  state.map[action.userId].favoriteBusinesses,
                )
              : [],
          },
        },
      }
    case UPDATE_CURRENT_LANGUAGE:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            language: action.languageCode,
          },
        },
      }
    case UPDATE_SHOW_OTC_EVENTS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.userId]: {
            ...state.map[action.userId],
            showOtcSaleEvents: action.showOtcSaleEvents,
          },
        },
      }
    default:
      return state
  }
}

export const getUserSettings = (state: RootState): UserSettingsState =>
  state.userSettings
export const getUserSettingsMap = (state: RootState) =>
  getUserSettings(state).map
export const getCurrentUserSettings = createSelector(
  [getUserSettingsMap, getCurrentUserId],
  (map, userId) => (userId ? map[userId] : undefined),
)
export const getInvoiceSelectedColumns = (state: RootState) =>
  getCurrentUserSettings(state)?.invoiceSelectedColumns ||
  USER_DEFAULTS.invoiceSelectedColumns
export const getOnHandCatalogSelectedColumns = (state: RootState) =>
  getCurrentUserSettings(state)?.onHandCatalogSelectedColumns ||
  USER_DEFAULTS.onHandCatalogSelectedColumns
export const getTimetableConfiguredColumns = (state: RootState) =>
  getCurrentUserSettings(state)?.timetableConfiguredColumns
export const getTimetableConfiguredColumnsForArea = (
  businessId: string,
  area: string,
) =>
  createSelector(
    getTimetableConfiguredColumns,
    (columns) => columns?.[area]?.[businessId],
  )
export const getFavoriteBusinesses = (state: RootState) =>
  getCurrentUserSettings(state)?.favoriteBusinesses || []
export const getPreventShowAgainDialog = (dialogName: ConfirmAlertType) =>
  createSelector(
    getCurrentUserSettings,
    (settings) => settings?.preventShowAgainDialogsMap?.[dialogName],
  )
export const getIsMarketplacePanelPinned = (state: RootState) =>
  getCurrentUserSettings(state)?.marketplacePanelPinned || false
export const getCurrentUserLanguage = (state: RootState) =>
  getCurrentUserSettings(state)?.language ||
  i18n.language ||
  LanguageCodes['en-US']
export const getShowOtcSaleEvents = (state: RootState) =>
  getCurrentUserSettings(state)?.showOtcSaleEvents || false
