import { ApiError } from '@pbt/pbt-ui-components'

import { GlobalInventoryCatalogItem } from '~/types'

import {
  FETCH_GLOBAL_ITEM,
  FETCH_GLOBAL_ITEM_FAILURE,
  FETCH_GLOBAL_ITEM_SUCCESS,
  FETCH_GLOBAL_LIST,
  FETCH_GLOBAL_LIST_FAILURE,
  FETCH_GLOBAL_LIST_SUCCESS,
  FETCH_MORE_GLOBAL_LIST,
  FETCH_MORE_GLOBAL_LIST_FAILURE,
  FETCH_MORE_GLOBAL_LIST_SUCCESS,
  UPDATE_GLOBAL_ITEMS,
} from './types/globaInventoryCatalog'

export const updateGlobalItems = (
  items: Record<string, GlobalInventoryCatalogItem>,
) => ({
  type: UPDATE_GLOBAL_ITEMS,
  items,
})

export const fetchGlobalList = (
  from: number,
  to: number,
  query: string,
  categories: string[],
) => ({
  type: FETCH_GLOBAL_LIST,
  from,
  to,
  query,
  categories,
})
export const fetchGlobalListFailure = (error: ApiError) => ({
  type: FETCH_GLOBAL_LIST_FAILURE,
  error,
})
export const fetchGlobalListSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_GLOBAL_LIST_SUCCESS,
  list,
  totalCount,
})

export const fetchMoreGlobalList = (
  from: number,
  to: number,
  query: string,
  categories: string[],
) => ({
  type: FETCH_MORE_GLOBAL_LIST,
  from,
  to,
  query,
  categories,
})
export const fetchMoreGlobalListFailure = (error: ApiError) => ({
  type: FETCH_MORE_GLOBAL_LIST_FAILURE,
  error,
})
export const fetchMoreGlobalListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_GLOBAL_LIST_SUCCESS,
  list,
  totalCount,
  from,
})

export const fetchGlobalItem = (id: string) => ({
  type: FETCH_GLOBAL_ITEM,
  id,
})
export const fetchGlobalItemSuccess = () => ({
  type: FETCH_GLOBAL_ITEM_SUCCESS,
})
export const fetchGlobalItemFailure = (error: ApiError) => ({
  type: FETCH_GLOBAL_ITEM_FAILURE,
  error,
})
