import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import LoginPage from '~/components/common/auth/LoginPage'
import {
  clearInvitationAlreadyAccepted,
  fetchInvitation,
  savePerson,
} from '~/store/actions/registration'
import { createPerson } from '~/store/dto/Person'
import {
  getIsInvitationAlreadyAcceptedEmail,
  getRegistrationError,
  getRegistrationIsLoading,
  getRegistrationPerson,
} from '~/store/reducers/registration'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

import RegisterByInviteError from './RegisterByInviteError'
import RegisterByInviteForm from './RegisterByInviteForm'

const RegistrationByInvite = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const dispatch = useDispatch()
  const person = useSelector(getRegistrationPerson)
  const error = useSelector(getRegistrationError)
  const isLoading = useSelector(getRegistrationIsLoading)
  const invitationAlreadyAcceptedEmail = useSelector(
    getIsInvitationAlreadyAcceptedEmail,
  )

  const { isAuthenticated } = useIsAuthenticated()

  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(fetchInvitation(token))
    }
  }, [token, isAuthenticated])

  useEffect(() => {
    if (invitationAlreadyAcceptedEmail) {
      dispatch(clearInvitationAlreadyAccepted())
      navigate('/auth/login', {
        state: { email: invitationAlreadyAcceptedEmail },
      })
    }
  }, [invitationAlreadyAcceptedEmail])

  const onFormSubmit = (password: string) => {
    if (!person) {
      return
    }
    dispatch(
      savePerson(
        createPerson({
          email: person.email,
          firstName: person.firstName || '',
          lastName: person.lastName || '',
          phone: person.phone,
          dea: person.dea,
          password,
        }),
      ),
    )
    navigate('/register/profile')
  }

  return (
    <LoginPage>
      {error ? (
        <RegisterByInviteError />
      ) : (
        <RegisterByInviteForm
          email={person?.email}
          isFetching={isLoading}
          onSubmit={onFormSubmit}
        />
      )}
    </LoginPage>
  )
}

export default RegistrationByInvite
