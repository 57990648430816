import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Business as BusinessType, Nil } from '@pbt/pbt-ui-components'

import { createBusiness } from '~/store/actions/businesses'
import Business, { BusinessDto } from '~/store/dto/Business'
import { getBusinessIsLoading } from '~/store/reducers/businesses'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import ClinicEdit from '../../../../registration/ClinicEdit'
import ClinicLogoUpload from '../../../../registration/ClinicLogoUpload'
import ClinicsSelector from '../../../../registration/ClinicsSelector'
import ClinicView from '../../../../registration/ClinicView'

const useStyles = makeStyles(
  (theme) => ({
    clinicSelectorRoot: {
      paddingTop: 0,
    },
    header: {
      lineHeight: '2.6rem',
      fontSize: '2rem',
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        paddingLeft: theme.spacing(2),
        textAlign: 'left',
      },
    },
    subHeader: {
      fontSize: '1.8rem',
      color: theme.colors.editFormPrimaryText,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
      },
    },
    clinicViewRoot: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 6,
      },
      paddingBottom: theme.spacing(3),
      paddingLeft: 0,
      paddingRight: 0,
    },
    clinicViewMainBlock: {
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
    },
    clinicViewHeadingTextBlock: {
      padding: 0,
    },
    clinicEditRegistrationRoot: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 6,
      },
      paddingBottom: theme.spacing(3),
    },
    clinicEditForm: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 48,
      },
      paddingBottom: 0,
    },
    clinicEditRegistrationBody: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      paddingBottom: 0,
    },
    clinicViewButtonGrid: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(14.75),
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    imageRoot: {
      [theme.breakpoints.down('md')]: {
        width: 174,
        height: 92,
      },
    },
    clinicLogoUpload: {
      paddingBottom: 5,
    },
  }),
  { name: 'CreatePractice' },
)

export interface CreatePracticeProps {
  creatorRoleId: string | Nil
  onCreated?: () => void
}

const CreatePractice = ({ onCreated, creatorRoleId }: CreatePracticeProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])
  const isLoading = useSelector(getBusinessIsLoading)
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const dispatch = useDispatch()

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onCreated,
    getBusinessIsLoading,
  )

  const [businessCandidate, setBusinessCandidate] = useState<
    BusinessType | BusinessDto | null
  >(null)
  const [edit, setEdit] = useState(false)
  const [uploadLogo, setUploadLogo] = useState(false)

  const onBusinessLoaded = (clinic: BusinessDto) => {
    setBusinessCandidate(clinic)
  }

  const onBackToMapClick = () => {
    setBusinessCandidate(null)
    setEdit(false)
  }

  const onEditClick = () => {
    setEdit(true)
  }

  const onViewSubmit = () => {
    setCloseAfterCreationOn()
    dispatch(createBusiness(businessCandidate!, creatorRoleId))
  }

  const onEditSubmit = (candidate: BusinessType | BusinessDto) => {
    setBusinessCandidate(candidate)
    setCloseAfterCreationOn()
    dispatch(createBusiness(candidate, creatorRoleId))
  }

  const onUploadTrigger = (candidate: BusinessType | BusinessDto) => {
    setBusinessCandidate(candidate)
    setUploadLogo(true)
  }

  const onUploaded = (logo: string) => {
    setBusinessCandidate({ ...businessCandidate!, logo })
    setUploadLogo(false)
  }

  const onCancel = () => {
    setUploadLogo(false)
  }

  const onAddNewClinicRequested = () => {
    setBusinessCandidate(new Business())
    setEdit(true)
  }

  const headerText = isMobile
    ? t('Admin:PRACTICE.NEW_PRACTICE')
    : t('Common:ADD_PRACTICE')

  return businessCandidate ? (
    edit ? (
      uploadLogo ? (
        <ClinicLogoUpload
          className={classes.clinicLogoUpload}
          onCancel={onCancel}
          onUploaded={onUploaded}
        />
      ) : (
        <ClinicEdit
          buttonColor="inherit"
          classes={{
            form: classes.clinicEditForm,
            header: classes.header,
            registrationRoot: classes.clinicEditRegistrationRoot,
            registrationBody: classes.clinicEditRegistrationBody,
            imageRoot: classes.imageRoot,
          }}
          clinic={businessCandidate}
          headerText={headerText}
          submitLabel={t('Common:ADD_ACTION')}
          onBackToMapClick={onBackToMapClick}
          onSubmit={onEditSubmit}
          onUploadTrigger={onUploadTrigger}
        />
      )
    ) : (
      <ClinicView
        buttonColor="primary"
        classes={{
          root: classes.clinicViewRoot,
          header: classes.header,
          subHeader: classes.subHeader,
          mainBlock: classes.clinicViewMainBlock,
          headingTextBlock: classes.clinicViewHeadingTextBlock,
          buttonGridOuter: classes.clinicViewButtonGrid,
        }}
        clinic={businessCandidate}
        confirmButtonLoading={isLoading}
        confirmButtonText={t('Common:ADD_ACTION')}
        headerText={headerText}
        subheaderText={t('Admin:PRACTICE.IS_THIS_INFO_CORRECT')}
        onBackToMapClick={onBackToMapClick}
        onEditClick={onEditClick}
        onSubmit={onViewSubmit}
      />
    )
  ) : (
    <ClinicsSelector
      classes={{
        root: classes.clinicSelectorRoot,
        header: classes.header,
        subHeader: classes.subHeader,
      }}
      headerText={headerText}
      subheaderText={
        !isMobile ? t('Admin:PRACTICE.CLICK_ON_PRACTICE_TO_ADD') : undefined
      }
      onAddNewClinicRequested={onAddNewClinicRequested}
      onBusinessLoaded={onBusinessLoaded}
    />
  )
}

export default CreatePractice
