import { gql } from '@apollo/client/core'

export const START_NEW_PRICE_BULK_UPDATE_SESSION = gql`
  mutation StartNewPriceBulkUpdateSession(
    $entityType: EntityType!
    $force: Boolean
  ) {
    startNewPriceBulkUpdateSession(entityType: $entityType, force: $force)
  }
`

export const DELETE_PRICE_BULK_UPDATE_SESSION = gql`
  mutation DeletePriceBulkUpdateSession($id: ID!) {
    deletePriceBulkUpdateSession(id: $id)
  }
`

export const SAVE_PRICE_BULK_UPDATE_MANUAL_CHANGES = gql`
  mutation SavePriceBulkUpdateManualChanges(
    $entityType: EntityType!
    $sessionId: ID!
    $prices: [InputPriceDto]!
  ) {
    savePriceBulkUpdateManualChanges(
      entityType: $entityType
      sessionId: $sessionId
      prices: $prices
    )
  }
`

export const START_PRICE_BULK_UPDATE = gql`
  mutation StartPriceBulkUpdate($sessionId: ID!) {
    startPriceBulkUpdate(sessionId: $sessionId)
  }
`

export const SAVE_INVENTORY_PRICE_BULK_UPDATE_CHANGE = gql`
  mutation SaveInventoryPriceBulkUpdateChange(
    $sessionId: ID!
    $categoryId: ID!
    $subcategoryId: ID!
    $incrementValue: BigDecimal!
    $incrementType: IncrementType!
    $roundUp: Boolean!
    $includePrices: Boolean!
    $priceIds: [ID]
  ) {
    saveInventoryPriceBulkUpdateChange(
      sessionId: $sessionId
      categoryId: $categoryId
      subcategoryId: $subcategoryId
      incrementValue: $incrementValue
      incrementType: $incrementType
      roundUp: $roundUp
      includePrices: $includePrices
      priceIds: $priceIds
    )
  }
`

export const SAVE_LAB_TEST_PRICE_BULK_UPDATE_CHANGE = gql`
  mutation SaveLabTestPriceBulkUpdateChange(
    $sessionId: ID!
    $labVendorId: ID!
    $incrementValue: BigDecimal!
    $incrementType: IncrementType!
    $roundUp: Boolean!
    $includePrices: Boolean!
    $priceIds: [ID]
  ) {
    saveLabTestPriceBulkUpdateChange(
      sessionId: $sessionId
      labVendorId: $labVendorId
      incrementValue: $incrementValue
      incrementType: $incrementType
      roundUp: $roundUp
      includePrices: $includePrices
      priceIds: $priceIds
    )
  }
`

export const SAVE_PROCEDURE_PRICE_BULK_UPDATE_CHANGE = gql`
  mutation SaveProcedurePriceBulkUpdateChange(
    $sessionId: ID!
    $categoryId: ID!
    $incrementValue: BigDecimal!
    $incrementType: IncrementType!
    $roundUp: Boolean!
    $includePrices: Boolean!
    $priceIds: [ID]
  ) {
    saveProcedurePriceBulkUpdateChange(
      sessionId: $sessionId
      categoryId: $categoryId
      incrementValue: $incrementValue
      incrementType: $incrementType
      roundUp: $roundUp
      includePrices: $includePrices
      priceIds: $priceIds
    )
  }
`
