import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Contact, SearchContactsRequestParams } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchContacts = (
  businessId: string,
  searchTerm: string | Nil,
  max: number | Nil,
  roleIds?: string | Nil,
  fieldsQuery?: string | Nil,
  includeInactive?: boolean | Nil,
  from: number | undefined = 0,
  to: number | undefined = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  normalize?: boolean,
) =>
  request(
    getAPIPath('admin/services/contacts/all'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    normalize ? Schema.contactsList : undefined,
  )

export const searchContacts = (
  businessId: string,
  {
    from = 0,
    to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
    query,
    normalize,
  }: SearchContactsRequestParams,
) =>
  request(
    getAPIPath('admin/services/contacts/search'),
    {
      method: 'GET',
      params: {
        businessId,
        from,
        to,
        query,
      },
    },
    true,
    normalize ? Schema.contactsList : undefined,
  )

export const fetchContact = (businessId: string, contactId: string) =>
  request(
    getAPIPath(`admin/services/contacts/${contactId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.contact,
  )

export const createContact = (businessId: string, contact: Contact) =>
  request(
    getAPIPath('admin/services/contacts'),
    { method: 'POST', data: contact, params: { businessId } },
    true,
    Schema.contact,
  )

export const editContact = (businessId: string, contact: Contact) =>
  request(
    getAPIPath(`admin/services/contacts/${contact.id}`),
    { method: 'PUT', data: contact, params: { businessId } },
    true,
    Schema.contact,
  )

export const deleteContact = (businessId: string, contactId: string) =>
  request(
    getAPIPath(`admin/services/contacts/${contactId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchReferredByContacts = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('persons/referrals'),
    { method: 'GET', params: { businessId, from, to } },
    true,
  )
