import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Calendar,
  Constant,
  CurrencyTextField,
  LanguageUtils,
  NumberUtils,
  PermissionArea,
  PuiCheckbox,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  UserPermissions,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'
import { hasAtLeastSingleLatinChar } from '@pbt/pbt-ui-components/src/utils/validation'

import PreviewButton from '~/components/common/buttons/PreviewButton'
import PlanVersionSelect from '~/components/common/inputs/PlanVersionSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  editWellnessPlansSettings,
  fetchWellnessPlanBoopSignUpTemplate,
  fetchWellnessPlanWelcomeMessage,
} from '~/store/actions/wellnessPlans'
import { getCRUDByArea, getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getWellnessPlanCommunicationPromoteType,
  getWellnessPlanPriceType,
} from '~/store/reducers/constants'
import {
  getWellnessPlanBoopSignUpTemplate,
  getWellnessPlansIsLoading,
  getWellnessPlansSettings,
  getWellnessPlanWelcomeTemplate,
} from '~/store/reducers/wellnessPlans'
import { BasePracticeDetailsSectionProps, WellnessPlanSettings } from '~/types'
import { hasHtmlFieldChanged } from '~/utils/htmlUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import MessageWithPreviewConfiguration from './MessageWithPreviewConfiguration'

const STRIPE_DESCRIPTOR_TOOLTIP_TEXT = i18n.t(
  'Businesses:WELLNESS_PLANS.LABEL_WILL_APPEAR_ON_PET_PARENTS_STATEMENT_WHEN_BILLED',
)

const STRIPE_DESCRIPTOR_MAX_LENGTH = 22
const STRIPE_DESCRIPTOR_ERROR_MESSAGE = i18n.t(
  'Businesses:WELLNESS_PLANS.NUMBER_CHARACTERS',
  {
    number: STRIPE_DESCRIPTOR_MAX_LENGTH,
  },
)

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    tableCell: {
      border: 'none',
      padding: theme.spacing(1),
    },
    checkbox: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.secondaryText,
      margin: theme.spacing(0, 1),
    },
    switchLabel: {
      fontSize: '1.4rem',
    },
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      marginRight: theme.spacing(2),
    },
    priceInput: {
      width: 100,
    },
    switch: {
      marginLeft: theme.spacing(-1),
    },
    planVersionSelect: {
      width: 160,
    },
    richEdit: {
      marginTop: theme.spacing(2),
    },
    stripeDescriptorContainer: {
      maxWidth: 280,
    },
    stripeDescriptorTooltip: {
      height: 35,
      width: 'auto',
    },
  }),
  { name: 'WellnessPlanConfiguration' },
)

const WellnessPlanConfiguration = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const permissions: UserPermissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const supportPermissions: UserPermissions = useSelector(
    getCRUDByArea(PermissionArea.SUPPORT),
  )
  const WellnessPlanCommunicationPromoteType: Constant[] = useSelector(
    getWellnessPlanCommunicationPromoteType,
  )
  const WellnessPlanPriceType: Constant[] = useSelector(
    getWellnessPlanPriceType,
  )
  const settings: WellnessPlanSettings = useSelector(
    getWellnessPlansSettings(business.id),
  )
  const wpImportPhaseOneEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_IMPORT_PHASE_1),
  )
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const [openPreviewWellnessPromotionDialog] = useDialog(
    DialogNames.PREVIEW_WELLNESS_PROMOTION,
  )
  const [openEmailTemplatePreviewDialog] = useDialog(
    DialogNames.EMAIL_TEMPLATE_PREVIEW_DIALOG,
  )

  const AnnualPriceTypeId = Utils.findConstantIdByName(
    'Annual',
    WellnessPlanPriceType,
  )

  const stripeDescriptorValidate = (value: string) => {
    const isLengthValid = Validators.isLengthValid(
      0,
      STRIPE_DESCRIPTOR_MAX_LENGTH,
    )(value)
    return isLengthValid && hasAtLeastSingleLatinChar(value)
  }

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'openBoop',
        type: 'toggle',
        initialValue: settings?.openBoop || false,
      },
      {
        name: 'hideWellnessPromotion',
        type: 'toggle',
        initialValue: settings?.hideWellnessPromotion || false,
      },
      {
        name: 'basePriceTypeId',
        initialValue: settings?.basePriceTypeId || AnnualPriceTypeId,
      },
      {
        name: 'basePrice',
        validators: ['nonNegative'],
        initialValue: settings?.basePrice || 0,
      },
      {
        name: 'oneTimeFeeAmount',
        validators: ['nonNegative'],
        initialValue: settings?.oneTimeFeeAmount || 0,
      },
      {
        name: 'mapExistingPlans',
        label: t('Businesses:WELLNESS_PLANS.MAP_PRE_EXISTING_PLANS'),
        type: 'toggle',
        initialValue: settings?.mapExistingPlans || false,
      },
      {
        name: 'migrationCutoverDate',
        label: t('Businesses:WELLNESS_PLANS.CUTOVER_DATE'),
        validators: ['timestamp'],
        initialValue: settings?.migrationCutoverDate,
      },
      { name: 'mappedVersionId', initialValue: settings?.mappedVersionId },
      {
        name: 'stripeDescriptor',
        label: t('Businesses:WELLNESS_PLANS.STRIPE_DESCRIPTOR'),
        validators: [
          {
            validator: ({ value }) => stripeDescriptorValidate(value),
            validatorName: 'stripeDescriptor',
          },
        ],
        messages: { stripeDescriptor: STRIPE_DESCRIPTOR_ERROR_MESSAGE },
        initialValue: settings?.stripeDescriptor || '',
      },
      {
        name: 'welcomeMessage',
        initialValue: settings?.welcomeMessage || '',
      },
      {
        name: 'signUpInBoopMessage',
        initialValue: settings?.signUpInBoopMessage || '',
      },
      {
        name: 'selectedPromotes',
        type: 'select',
        initialValue: settings?.promotes || [],
      },
    ],
    false,
  )

  const {
    openBoop,
    hideWellnessPromotion,
    basePriceTypeId,
    basePrice,
    oneTimeFeeAmount,
    mapExistingPlans,
    migrationCutoverDate,
    mappedVersionId,
    stripeDescriptor,
    welcomeMessage,
    signUpInBoopMessage,
    selectedPromotes,
  } = fields

  const onSave = () => {
    const newSettings = {
      ...R.omit(['basePriceType'], settings),
      hideWellnessPromotion: hideWellnessPromotion.value,
      openBoop: openBoop.value,
      basePrice: basePrice.value || 0,
      basePriceTypeId: basePriceTypeId.value,
      oneTimeFeeAmount: oneTimeFeeAmount.value || 0,
      promotes: selectedPromotes.value,
      welcomeMessage: welcomeMessage.value,
      signUpInBoopMessage: signUpInBoopMessage.value,
      mapExistingPlans: mapExistingPlans.value,
      migrationCutoverDate: migrationCutoverDate.value,
      mappedVersionId: mappedVersionId.value,
      stripeDescriptor: stripeDescriptor.value,
      enabled: true,
    }
    dispatch(editWellnessPlansSettings(business.id, newSettings))
  }

  usePracticeFieldsSection({
    business,
    fields,
    onSave,
    sectionName:
      `${PracticeDetailsPanels.WELLNESS_PLANS}_CONFIG` as PracticeDetailsPanels,
    parentSectionName: PracticeDetailsPanels.WELLNESS_PLANS,
    validate,
    reset,
    resetDependencies: [business, settings],
    softUpdate: R.any(Boolean, [
      hasHtmlFieldChanged(welcomeMessage),
      hasHtmlFieldChanged(signUpInBoopMessage),
      openBoop.value !== openBoop.initialValue,
      hideWellnessPromotion.value !== hideWellnessPromotion.initialValue,
      basePriceTypeId.value !== basePriceTypeId.initialValue,
      basePrice.value !== basePrice.initialValue,
      oneTimeFeeAmount.value !== oneTimeFeeAmount.initialValue,
      mapExistingPlans.value !== mapExistingPlans.initialValue,
      migrationCutoverDate.value !== migrationCutoverDate.initialValue,
      mappedVersionId.value !== mappedVersionId.initialValue,
      stripeDescriptor.value !== stripeDescriptor.initialValue,
      selectedPromotes.value !== selectedPromotes.initialValue,
    ]),
  })

  useEffect(() => {
    selectedPromotes.setValue(settings?.promotes || [])
  }, [settings])

  const showWelcomeMessageConfig =
    !isBoopDisablementEnabled || !business.omniChannel
  const showBoopMessageConfig =
    !isBoopDisablementEnabled || Boolean(openBoop.value)

  const openPreview = () => {
    openPreviewWellnessPromotionDialog({
      business,
      showBoopDescription: showBoopMessageConfig,
    })
  }

  const openWelcomeMessagePreview = () => {
    openEmailTemplatePreviewDialog({
      title: t('Businesses:WELLNESS_PLANS.PREVIEW_WELCOME_MESSAGE'),
      subtitle: `${t(
        'Businesses:WELLNESS_PLANS.PREVIEW_INCLUDES_SAMPLE_COMMUNICATION',
      )}.`,
      fetchTemplate: () =>
        dispatch(
          fetchWellnessPlanWelcomeMessage(business.id, welcomeMessage.value),
        ),
      getTemplateSelector: getWellnessPlanWelcomeTemplate,
      getIsLoadingSelector: getWellnessPlansIsLoading,
    })
  }

  const openSignUpInBoopPreview = () => {
    openEmailTemplatePreviewDialog({
      title: t('Businesses:WELLNESS_PLANS.PREVIEW_SIGN_UP_IN_BOOP_MESSAGE'),
      subtitle: `${t(
        'Businesses:WELLNESS_PLANS.PREVIEW_INCLUDES_SAMPLE_COMMUNICATION',
      )}.`,
      fetchTemplate: () =>
        dispatch(
          fetchWellnessPlanBoopSignUpTemplate(
            business.id,
            signUpInBoopMessage.value,
          ),
        ),
      getTemplateSelector: getWellnessPlanBoopSignUpTemplate,
      getIsLoadingSelector: getWellnessPlansIsLoading,
    })
  }

  const togglePromote = (id: string, checked: boolean) => {
    const promote = Utils.findById(id, selectedPromotes.value)
    const index = selectedPromotes.value.indexOf(promote)
    const newPromotes = promote
      ? R.update(
          index,
          { ...promote, enabled: checked },
          selectedPromotes.value,
        )
      : selectedPromotes.value.concat({ id, enabled: checked })

    selectedPromotes.setValue(newPromotes)
  }

  const PriceTypeConstant = Utils.findById(
    basePriceTypeId.value,
    WellnessPlanPriceType,
  )
  const priceTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    PriceTypeConstant,
    'displayName',
    '',
  )

  const boopRestrictedDescription = isBoopDisablementEnabled
    ? ''
    : t('Businesses:WELLNESS_PLANS.BOOP_ACCESS_IS_RESTRICTED')

  const openBoopReadOnlyDescription = openBoop.value
    ? t('Businesses:WELLNESS_PLANS.ANY_CLIENT_CAN_ACCESS_BOOP')
    : boopRestrictedDescription

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      pb={6}
      pt={2}
      px={2}
    >
      <Grid item mb={1}>
        {supportPermissions.update ? (
          <PuiSwitch
            classes={{
              root: classes.switch,
            }}
            field={openBoop}
            label={
              <TextWithTooltip
                allowWrap
                tooltipText={t(
                  'Businesses:WELLNESS_PLANS.THIS_ALLOWS_ALL_CLIENTS_ACCESS_TO_RECORDS_IN_BOOP',
                )}
                variant="body"
              >
                {t(
                  'Businesses:WELLNESS_PLANS.ENABLE_BOOP_ACCESS_FOR_ALL_CLIENTS',
                )}
              </TextWithTooltip>
            }
          />
        ) : (
          <Text variant="body">{openBoopReadOnlyDescription}</Text>
        )}
      </Grid>
      {wpImportPhaseOneEnabled && (
        <>
          <PuiSwitch
            classes={{
              root: classes.switch,
            }}
            disabled={!supportPermissions.update}
            field={mapExistingPlans}
            label={mapExistingPlans.label}
          />
          {mapExistingPlans.value && (
            <Grid
              container
              item
              alignItems="center"
              pl={6.5}
              spacing={2}
              wrap="nowrap"
            >
              <Grid item>
                <Calendar
                  disabled={!supportPermissions.update}
                  field={migrationCutoverDate}
                  label={migrationCutoverDate.label}
                />
              </Grid>
              <Grid item>
                <PlanVersionSelect
                  businessId={business.id}
                  className={classes.planVersionSelect}
                  disabled={!supportPermissions.update}
                  field={mappedVersionId}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Grid container item direction="column" my={1}>
        {supportPermissions.update ? (
          <>
            <Text variant="body">
              {t('Businesses:WELLNESS_PLANS.BASE_PLAN_SET_UP')}
            </Text>
            <RadioGroup
              row
              aria-label="base-plan-price-section"
              name="basePlanPriceMode"
              value={basePriceTypeId.value}
              onChange={basePriceTypeId.set}
            >
              {WellnessPlanPriceType.map((planPrice) => (
                <FormControlLabel
                  classes={{
                    label: classes.radioLabel,
                  }}
                  control={<Radio />}
                  key={planPrice.id}
                  label={LanguageUtils.getTranslatedFieldName(planPrice)}
                  value={planPrice.id}
                />
              ))}
            </RadioGroup>
            <Grid container item alignItems="center" mt={1} wrap="nowrap">
              <CurrencyTextField
                className={classes.priceInput}
                field={basePrice}
                inputProps={{ maxLength: 100 }}
                margin="none"
              />
              <Text variant="body">{`/${priceTypeDisplayName}`}</Text>
            </Grid>
            <Text mt={2} variant="body">
              {t('Businesses:WELLNESS_PLANS.ONE_TIME_FEE')}
            </Text>
            <Grid container item mt={1}>
              <CurrencyTextField
                className={classes.priceInput}
                field={oneTimeFeeAmount}
                inputProps={{ maxLength: 100 }}
                margin="none"
              />
            </Grid>
          </>
        ) : (
          <>
            <Text variant="body">
              {`${t('Businesses:WELLNESS_PLANS.BASE_SETTINGS')}: `}
              {`${LanguageUtils.getTranslatedFieldName(PriceTypeConstant)} `}
              {`${NumberUtils.formatMoney(basePrice.value)}/`}
              {PriceTypeConstant?.displayName || ''}
            </Text>
            <Text mt={1} variant="body">
              {`${t('Businesses:WELLNESS_PLANS.ONE_TIME_FEE')}: `}
              {NumberUtils.formatMoney(oneTimeFeeAmount.value)}
            </Text>
          </>
        )}
      </Grid>

      {supportPermissions.update ? (
        <Grid
          container
          item
          alignItems="flex-end"
          className={classes.stripeDescriptorContainer}
          wrap="nowrap"
        >
          <PuiTextField
            field={stripeDescriptor}
            inputProps={{ maxLength: STRIPE_DESCRIPTOR_MAX_LENGTH }}
            label={`${stripeDescriptor.label} (${STRIPE_DESCRIPTOR_ERROR_MESSAGE})`}
          />
          <TextWithTooltip
            className={classes.stripeDescriptorTooltip}
            tooltipText={
              <span style={{ whiteSpace: 'pre-line' }}>
                {STRIPE_DESCRIPTOR_TOOLTIP_TEXT}
              </span>
            }
          />
        </Grid>
      ) : (
        stripeDescriptor.value && (
          <Text mt={2} variant="body">
            {t('Businesses:WELLNESS_PLANS.STRIPE_DESCRIPTOR')}:{' '}
            {stripeDescriptor.value}
          </Text>
        )
      )}

      {showWelcomeMessageConfig && (
        <MessageWithPreviewConfiguration
          className={classes.richEdit}
          field={welcomeMessage}
          tooltipText={t(
            'Businesses:WELLNESS_PLANS.THIS_EMAIL_WILL_BE_SENT_TO_ANY_CLIENTS_THAT_SIGN_UP',
          )}
          tooltipTitle={t('Businesses:WELLNESS_PLANS.WELCOME_MESSAGE')}
          onPreview={openWelcomeMessagePreview}
        />
      )}

      {showBoopMessageConfig && (
        <MessageWithPreviewConfiguration
          className={classes.richEdit}
          field={signUpInBoopMessage}
          tooltipText={t(
            'Businesses:WELLNESS_PLANS.THIS_EMAIL_WILL_BE_SET_IF_YOU_SEND_CLIENT_LINK_TO_SIGN_UP',
          )}
          tooltipTitle={t('Businesses:WELLNESS_PLANS.SIGN_UP_IN_BOOP')}
          onPreview={openSignUpInBoopPreview}
        />
      )}

      <Grid container item mt={2} wrap="nowrap">
        <Grid item>
          <TextWithTooltip
            allowWrap
            strong
            tooltipText={t(
              'Businesses:WELLNESS_PLANS.IF_PATIENT_IS_NOT_ENROLLED_IN_MEMBERSHIP',
            )}
            variant="subheading3"
          >
            {t(
              'Businesses:WELLNESS_PLANS.PROMOTE_WELLNESS_PLANS_THROUGH_FOLLOWING_CHANNELS',
            )}
          </TextWithTooltip>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid item>
          <PreviewButton onClick={openPreview} />
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <TableBody>
          {WellnessPlanCommunicationPromoteType.map(
            (communicationPromoteType) => {
              const promoteSelected = Utils.findById(
                communicationPromoteType.id,
                selectedPromotes.value,
              )

              return (
                <TableRow
                  className={classes.tableRow}
                  key={communicationPromoteType.id}
                >
                  <TableCell className={classes.tableCell}>
                    <PuiCheckbox
                      checkboxClasses={{
                        root: classes.checkbox,
                      }}
                      checked={promoteSelected?.enabled}
                      className={classes.switchLabel}
                      disabled={!permissions.update}
                      label={LanguageUtils.getTranslatedFieldName(
                        communicationPromoteType,
                      )}
                      onChange={Utils.handleFormCheckboxInput((checked) =>
                        togglePromote(communicationPromoteType.id, checked),
                      )}
                    />
                  </TableCell>
                </TableRow>
              )
            },
          )}
          {showBoopMessageConfig && (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <PuiCheckbox
                  checkboxClasses={{
                    root: classes.checkbox,
                  }}
                  checked={!hideWellnessPromotion.value}
                  className={classes.switchLabel}
                  disabled={!permissions.update}
                  label={t('Common:MOBILE_APP')}
                  onChange={Utils.handleFormCheckboxInput((checked) =>
                    hideWellnessPromotion.setValue(!checked),
                  )}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  )
}

export default memo(WellnessPlanConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
