import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  fetchBusinessChatOnboardInfo,
  fetchBusinessChatOnboardInfoFailure,
  fetchBusinessChatOnboardInfoSuccess,
  fetchChatReconcilationInfo,
  fetchChatReconcilationInfoFailure,
  fetchChatReconcilationInfoSuccess,
  oauthLoginRocketChatFailure,
  oauthLoginRocketChatSuccess,
  partialEditBusinessChatOnboardInfo,
  partialEditBusinessChatOnboardInfoFailure,
  partialEditBusinessChatOnboardInfoSuccess,
  startChatReconcilationFailure,
  startChatReconcilationSuccess,
} from '../actions/chat'
import {
  FETCH_BUSINESS_CHAT_ONBOARD_INFO,
  FETCH_CHAT_RECONCILATION_INFO,
  OAUTH_LOGIN_ROCKET_CHAT,
  PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO,
  START_CHAT_RECONCILATION,
} from '../actions/types/chat'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* oauthLoginRocketChatSaga() {
  try {
    const {
      data: { userId, authToken },
    } = yield* requestAPI(API.oauthLoginRocketChat)

    yield put(oauthLoginRocketChatSuccess(userId, authToken))
  } catch (error) {
    yield put(oauthLoginRocketChatFailure(error as ApiError))
  }
}

export function* fetchChatReconcilationInfoSaga({
  businessId,
  count,
}: ReturnType<typeof fetchChatReconcilationInfo>) {
  try {
    const { data } = yield* requestAPI(
      API.fetchChatReconcilationInfo,
      businessId,
      count,
    )

    yield put(fetchChatReconcilationInfoSuccess(data))
  } catch (error) {
    yield put(fetchChatReconcilationInfoFailure(error as ApiError))
  }
}

export function* startChatReconcilationSaga({
  businessId,
}: ReturnType<typeof fetchChatReconcilationInfo>) {
  try {
    const { data } = yield* requestAPI(API.startChatReconcilation, businessId)

    yield put(startChatReconcilationSuccess(data))
  } catch (error) {
    yield put(startChatReconcilationFailure(error as ApiError))
  }
}

export function* fetchBusinessChatOnboardInfoSaga({
  businessId,
}: ReturnType<typeof fetchBusinessChatOnboardInfo>) {
  try {
    const { entities, result } = yield* requestAPI(
      API.fetchBusinessChatOnboardInfo,
      businessId,
    )

    yield call(updateEntities, entities)
    yield put(fetchBusinessChatOnboardInfoSuccess(result))
  } catch (e) {
    const error = e as ApiError
    yield put(fetchBusinessChatOnboardInfoFailure(error))
  }
}

export function* partialEditBusinessChatOnboardInfoSaga({
  businessId,
  chatUiEnabled,
}: ReturnType<typeof partialEditBusinessChatOnboardInfo>) {
  try {
    const { entities, result } = yield* requestAPI(
      API.partialEditBusinessChatOnboardInfo,
      businessId,
      chatUiEnabled,
    )

    yield call(updateEntities, entities)
    yield put(partialEditBusinessChatOnboardInfoSuccess(result))
  } catch (e) {
    const error = e as ApiError
    yield put(partialEditBusinessChatOnboardInfoFailure(error))
  }
}

function* watchOauthLoginRocketChatSaga() {
  yield takeLeading(OAUTH_LOGIN_ROCKET_CHAT, oauthLoginRocketChatSaga)
}

function* watchFetchReconcilationInfoSaga() {
  yield takeLatest(
    FETCH_CHAT_RECONCILATION_INFO,
    fetchChatReconcilationInfoSaga,
  )
}

function* watchStartChatReconcilationSaga() {
  yield takeLatest(START_CHAT_RECONCILATION, startChatReconcilationSaga)
}

function* watchFetchBusinessChatOnboardInfo() {
  yield takeLeading(
    FETCH_BUSINESS_CHAT_ONBOARD_INFO,
    fetchBusinessChatOnboardInfoSaga,
  )
}

function* watchPartialEditBusinessChatOnboardInfo() {
  yield takeLeading(
    PARTIAL_EDIT_BUSINESS_CHAT_ONBOARD_INFO,
    partialEditBusinessChatOnboardInfoSaga,
  )
}

export default function* chatSaga() {
  yield all([
    watchOauthLoginRocketChatSaga(),
    watchFetchReconcilationInfoSaga(),
    watchStartChatReconcilationSaga(),
    watchFetchBusinessChatOnboardInfo(),
    watchPartialEditBusinessChatOnboardInfo(),
  ])
}
