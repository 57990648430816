import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import { groupLabTestsByPatients } from '~/components/dashboard/soap/wrap-up/lab-orders/labTestUtils'
import MultiPatientOrderManagementDialog from '~/components/dashboard/soap/wrap-up/lab-orders/order-management-dialog/MultiPatientOrderManagementDialog'
import {
  fetchClientFinanceCharges,
  getChargeSheet,
  getMultipleChargeSheetSubItems,
} from '~/store/duck/clientFinanceData'
import {
  cancelLabOrder,
  clearDevices,
  clearLabOrders,
  fetchDevices,
  fetchLabOrders,
  getLabOrders,
} from '~/store/duck/labOrders'
import { useGetActiveLabVendorsMap } from '~/store/hooks/labVendorConfig'
import { getLabTestsStates } from '~/store/reducers/constants'
import {
  fetchInvoiceV3,
  getInvoiceV3SubItemsMap,
} from '~/store/reducers/invoiceV3'
import { getSoapId } from '~/store/reducers/soap'
import { LabOrder } from '~/types'
import { arrayToMap } from '~/utils'

const flattenLabOrders = R.pipe(
  R.map(({ orders }: LabOrder) => orders),
  R.flatten,
)

export interface ChargeSheetLabOrderManagementDialogProps
  extends BasePuiDialogProps {
  clientId: string
  invoiceId?: string
}

const ChargeSheetLabOrderManagementDialog = ({
  open,
  onClose,
  clientId,
  invoiceId,
}: ChargeSheetLabOrderManagementDialogProps) => {
  const dispatch = useDispatch()

  const chargeSheet = useSelector(getChargeSheet)
  const LabTestsStatesList = useSelector(getLabTestsStates)
  const labIntegration = useSelector(getLabOrders)
  const soapId = useSelector(getSoapId)
  const chargeSheetSections = useSelector(
    getMultipleChargeSheetSubItems(chargeSheet?.sections || []),
  )
  const invoiceSections = useSelector(getInvoiceV3SubItemsMap)

  const ordersMap = arrayToMap(flattenLabOrders(labIntegration), R.prop('id'))

  const isInvoice = Boolean(invoiceId)
  const chargeSheetId = chargeSheet?.id

  const labTestsMap = invoiceId
    ? groupLabTestsByPatients(
        LabTestsStatesList,
        Object.values(invoiceSections),
      )
    : groupLabTestsByPatients(LabTestsStatesList, chargeSheetSections)

  const activeLabVendors = useGetActiveLabVendorsMap()

  useEffect(() => {
    if (chargeSheetId || invoiceId) {
      dispatch(fetchLabOrders(undefined, isInvoice ? invoiceId : chargeSheetId))
      dispatch(fetchDevices())
    }
    return () => {
      // This component can be opened inside an actual SOAP by opening an invoice dialog, in such case
      // we don't want to clear lab orders status, as this would remove the LabOrdersSection component
      if (chargeSheetId && !soapId) {
        dispatch(clearLabOrders())
        dispatch(clearDevices())
      }
    }
  }, [chargeSheetId, invoiceId, soapId])

  useEffect(() => {
    if (invoiceId) {
      dispatch(fetchInvoiceV3({ id: invoiceId }))
    }
    if (clientId) {
      dispatch(fetchClientFinanceCharges({ id: clientId }))
    }
  }, [])

  const onCancelOrder = (vendorId: string, orderId: string) => {
    dispatch(cancelLabOrder(vendorId, orderId))
  }

  return (
    <MultiPatientOrderManagementDialog
      clientId={clientId}
      hasIntegrationMap={activeLabVendors}
      invoiceId={invoiceId}
      labTestsMap={labTestsMap}
      open={open}
      ordersMap={ordersMap}
      onCancelOrder={onCancelOrder}
      onClose={onClose}
    />
  )
}

export default ChargeSheetLabOrderManagementDialog
