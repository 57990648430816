import { gql } from '@apollo/client/core'

import { SOAP_TEMPLATE_TAB_FIELDS } from '../fragments/soap'

export const CREATE_SOAP_FILE_GROUP = gql`
  mutation CreateSoapFileGroup($input: CreateSoapFileGroupInput!) {
    createSoapFileGroup(input: $input) {
      id
      soapFiles {
        id
        name
        extension
        originalFileName
        fileUrl
        required
        includeInKiosk
        dateSigned
        signer
        typeId
      }
    }
  }
`

export const UPDATE_SOAP_TEMPLATE = gql`
  ${SOAP_TEMPLATE_TAB_FIELDS}
  mutation UpdateSoapTemplate(
    $businessId: ID!
    $id: ID!
    $input: SoapTemplateInput!
  ) {
    updateSoapTemplate(businessId: $businessId, id: $id, input: $input) {
      id
      businessId
      name
      active
      tabs {
        ...SoapTemplateTabFields
      }
    }
  }
`

export const RESET_SOAP_TEMPLATE_TO_DEFAULT = gql`
  ${SOAP_TEMPLATE_TAB_FIELDS}
  mutation ResetSoapTemplateToDefault($businessId: ID!, $id: ID!) {
    resetSoapTemplateToDefault(businessId: $businessId, id: $id) {
      id
      businessId
      name
      active
      tabs {
        ...SoapTemplateTabFields
      }
    }
  }
`

export const FETCH_CLIENT_SURVEY_LINK = gql`
  mutation GenerateSurveyLink(
    $clientId: ID!
    $referenceEntityType: ReferenceEntityType!
    $referenceId: ID!
  ) {
    generateSurveyLink(
      referenceId: $referenceId
      referenceEntityType: $referenceEntityType
      clientId: $clientId
    ) {
      createdAt
      link
      surveyType
      expirationDate
      business {
        id
        name
      }
    }
  }
`
