import { gql } from '@apollo/client'

export const CREATE_LAB_TEST_LOG_FILE_GROUP = gql`
  mutation CreateLabTestLogFileGroup(
    $labTestLogId: ID!
    $input: CreateLabTestLogFileGroupInput!
  ) {
    createLabTestLogFileGroup(labTestLogId: $labTestLogId, input: $input) {
      id
      title
      description
      uploadDate
      creationDate
      diagnosedIn
      files {
        id
        name
        extension
        originalFileName
        fileUrl
      }
    }
  }
`

export const DELETE_LAB_TEST_LOG_FILE = gql`
  mutation DeleteLabTestLogFile(
    $labTestLogId: ID!
    $groupId: ID!
    $fileId: ID!
  ) {
    deleteLabTestLogFile(
      labTestLogId: $labTestLogId
      groupId: $groupId
      fileId: $fileId
    )
  }
`
