import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { LabTestTypes } from '~/constants/SOAPStates'
import { LabOrder, Order } from '~/types'

import LabOrderStatusRow from './LabOrderStatusRow'
import LabOrderToolRow from './LabOrderToolRow'
import LabTestRow from './LabTestRow'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
      '&:not(:last-of-type)': {
        marginBottom: theme.spacing(8),
      },
    },
  }),
  { name: 'LabOrderSection' },
)

const isInHouseLabTest = (order: Order) =>
  order?.labTest?.type === LabTestTypes.IN_HOUSE

const isInHouseOrder = (labTests: Order[]) => labTests.some(isInHouseLabTest)

export interface LabOrderSectionProps {
  hasIntegration: boolean
  invoiceId: string | Nil
  labName: string
  labTests: Order[]
  notReadyForOrder?: boolean
  onAddLab?: (soapId: string | Nil) => void
  onCancelOrder?: () => void
  onEmailResult?: () => void
  onFinalizeOrder?: () => void
  onPlaceLabOrder?: (deviceSerialNumber: string | undefined) => void
  onPrintLabel: () => void
  onPrintOrPreview?: (isResult: boolean, shouldPrint: boolean) => void
  order?: LabOrder
  readyForOrder?: boolean
  refetchChargeSheet?: boolean
  showDevices?: boolean
  vendorId: string
}

const LabOrderSection = ({
  invoiceId,
  hasIntegration,
  labName,
  labTests,
  refetchChargeSheet,
  onAddLab,
  onCancelOrder,
  onFinalizeOrder,
  onPlaceLabOrder,
  onPrintLabel,
  onPrintOrPreview,
  onEmailResult,
  order,
  vendorId,
  readyForOrder = false,
  notReadyForOrder = false,
  showDevices,
}: LabOrderSectionProps) => {
  const classes = useStyles()
  const ordered = !readyForOrder && !notReadyForOrder

  const { cancellable = true } = order || {}
  const showPreview = Boolean(order?.iframeUrl)
  const showResultsPreview = Boolean(
    order?.resultIdentifier || order?.resultIframeUrl,
  )
  const showOrderPrint = Boolean(order?.pdfUrl)
  const showResultsPrint = Boolean(order?.resultPdfUrl)
  const soapOrders = labTests.find((labOrder) => !R.isNil(labOrder.soapId))
  const soapId = soapOrders?.soapId
  const isSoap = labTests.some((labOrder) => labOrder.soapId)
  const showAddLab = !invoiceId

  return (
    <Grid container className={classes.root}>
      {labTests.map((labTest) => (
        <LabTestRow
          invoiceId={invoiceId}
          key={labTest.id}
          labTest={labTest}
          ordered={ordered}
          refetchChargeSheet={refetchChargeSheet}
        />
      ))}
      {ordered ? (
        <LabOrderStatusRow
          cancellable={cancellable}
          showOrderPrint={showOrderPrint}
          showPreview={showPreview}
          showResultsPreview={showResultsPreview}
          showResultsPrint={showResultsPrint}
          statusId={order?.statusId}
          onCancelOrder={onCancelOrder}
          onEmailResult={onEmailResult}
          onFinalizeOrder={onFinalizeOrder}
          onPrintLabel={onPrintLabel}
          onPrintOrPreview={onPrintOrPreview}
        />
      ) : (
        <LabOrderToolRow
          canPlaceOrder={!notReadyForOrder}
          hasIntegration={hasIntegration}
          labName={labName}
          showDevices={showDevices && isInHouseOrder(labTests)}
          soapId={soapId}
          vendorId={vendorId}
          onAddLab={isSoap && showAddLab ? onAddLab : undefined}
          onPlaceLabOrder={onPlaceLabOrder}
        />
      )}
    </Grid>
  )
}

export default LabOrderSection
