import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import {
  AddButton,
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextArea,
  useFields,
} from '@pbt/pbt-ui-components'

import Typography from '~/components/elements/Typography/Typography'
import {
  CheckboxGroupState,
  InternalDispalyPreference,
  RadioGroupState,
  SelectionOptionState,
} from '~/types'

import { SelectionOption, SelectionOptionHandle } from './SelectionOption'

const DEFAULT_OPTION_ID = 1

const DEFAULT_OPTIONS = {
  [DEFAULT_OPTION_ID]: {
    id: DEFAULT_OPTION_ID,
    name: '',
    sequenceNumber: 1,
    internalDisplayPreference: InternalDispalyPreference.AS_WRITTEN,
  },
}

interface SelectionGroupDetailsProps extends BasePuiDialogProps {
  InputComponent: React.JSXElementConstructor<any>
  documentElement?: CheckboxGroupState | RadioGroupState
  header: string
  onSave: ({
    label,
  }: {
    label: string
    options: SelectionOptionState[]
  }) => void
  subheader: string
}

const getInitialOptions = (options: SelectionOptionState[] | undefined) => {
  if (!options) return undefined
  return options.reduce(
    (acc: { [key: number]: SelectionOptionState }, option) => {
      const key = option.id || option.sequenceNumber
      acc[key as number] = option
      return acc
    },
    {},
  )
}

const getInitialOptionsList = (options: SelectionOptionState[] | undefined) => {
  if (!options) return undefined
  return options.map((option) => option.id || option.sequenceNumber)
}

export const SelectionGroupDetails = ({
  open,
  onClose,
  onSave,
  documentElement,
  InputComponent,
  header,
  subheader,
}: SelectionGroupDetailsProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])
  const [options, setOptions] = useState<Record<number, SelectionOptionState>>(
    getInitialOptions(documentElement?.options) || DEFAULT_OPTIONS,
  )
  const [optionsList, setOptionsList] = useState(
    getInitialOptionsList(documentElement?.options) || [DEFAULT_OPTION_ID],
  )
  const checkboxOptionRefs = useRef<SelectionOptionHandle[]>([])

  const { fields, validate, reset } = useFields([
    {
      name: 'title',
      label: t('Dialogs:MULTIPLE_SELECT_DIALOG.TITLE_QUESTION'),
      validators: ['required'],
      initialValue: documentElement?.label || '',
    },
  ])

  const { title } = fields

  const handleAddToForm = () => {
    const allOptionsValid = checkboxOptionRefs.current.every((ref) =>
      ref?.validate(),
    )
    if (validate() && allOptionsValid) {
      onSave({
        label: title.value,
        options: optionsList.map((id) => ({
          ...options[id],
          id: getInitialOptions(documentElement?.options)?.[id]
            ? id
            : undefined,
        })),
      })
      reset()
      onClose?.()
    }
  }

  const handleAddOption = () => {
    const id = Math.max(...optionsList) + 1
    setOptions({
      ...options,
      [id]: {
        id,
        name: '',
        sequenceNumber: id,
        internalDisplayPreference: InternalDispalyPreference.AS_WRITTEN,
      },
    })
    setOptionsList((prev) => [...prev, id])
  }

  const handleDeleteOption = (id: number) => {
    setOptions((prev) => {
      const updated = { ...prev }
      delete updated[id]
      return updated
    })
    setOptionsList((prev) => prev.filter((itemId) => itemId !== id))

    checkboxOptionRefs.current = checkboxOptionRefs.current.filter(
      (ref, index) => optionsList[index] !== id,
    )
  }

  const handleUpdateOption = (id: number, data: SelectionOptionState) => {
    setOptions((prevOptions) => ({ ...prevOptions, [id]: data }))
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <Grid width={150}>
          <ButtonWithLoader fullWidth onClick={handleAddToForm}>
            {t('Dialogs:MULTIPLE_SELECT_DIALOG.ADD_TO_FORM')}
          </ButtonWithLoader>
        </Grid>
      }
      header={
        <Grid container>
          <Grid item xs={12}>
            <Typography.H2>{header}</Typography.H2>
          </Grid>
          <Grid item xs={12}>
            <Typography.Paragraph>{subheader}</Typography.Paragraph>
          </Grid>
        </Grid>
      }
      maxWidth="xl"
      open={open}
      onClose={onClose}
    >
      <Grid container>
        <Grid item pt={1} px={2} xs={12}>
          <Typography.H3>{title.label}</Typography.H3>
        </Grid>
        <Grid item px={2} xs={12}>
          <PuiTextArea
            field={title}
            minRows={3}
            placeholder={t(
              'Dialogs:MULTIPLE_SELECT_DIALOG.TYPE_YOUR_QUESTION_HERE',
            )}
          />
        </Grid>
        <Grid item p={2} pb={1} xs={12}>
          <Typography.H3>
            {t('Dialogs:MULTIPLE_SELECT_DIALOG.OPTIONS_CHECKBOXES')}
          </Typography.H3>
        </Grid>
        {optionsList.map((id, index) => {
          const option = options[id] || {}
          return (
            <Grid
              container
              key={id}
              p={2}
              sx={{
                backgroundColor: (theme) =>
                  index % 2 ? '' : theme.colors.grayGray4,
              }}
            >
              <SelectionOption
                InputComponent={InputComponent}
                namePlaceholder={t(
                  'Dialogs:MULTIPLE_SELECT_DIALOG.TYPE_YOUR_OPTION_HERE',
                )}
                option={option}
                ref={(el) => {
                  if (el) {
                    checkboxOptionRefs.current[index] = el
                  }
                }}
                onChange={(data) => {
                  handleUpdateOption(id, data)
                }}
                onDelete={
                  index
                    ? () => {
                        handleDeleteOption(id)
                      }
                    : undefined
                }
              />
            </Grid>
          )
        })}
        <Grid p={2}>
          <AddButton
            addText={t('Dialogs:MULTIPLE_SELECT_DIALOG.ADD_OPTION')}
            onAdd={handleAddOption}
          />
        </Grid>
      </Grid>
    </PuiDialog>
  )
}
