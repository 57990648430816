import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  ButtonWithLoaderProps,
  ClassesType,
} from '@pbt/pbt-ui-components'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      color: theme.colors.tabLabel,
      cursor: 'pointer',
    },
    button: {
      height: 40,
      width: 40,
      minWidth: 40,
      borderRadius: 20,
    },
  }),
  { name: 'DownloadPdfContentButton' },
)

export interface DownloadPdfContentButtonProps extends ButtonWithLoaderProps {
  classes?: ClassesType<typeof useStyles>
}

const DownloadPdfContentButton = ({
  classes: classesProp,
  ...rest
}: DownloadPdfContentButtonProps) => {
  const classes = useStyles({ classes: classesProp })
  return (
    <>
      <ButtonWithLoader
        classes={{ button: classes.button }}
        color="secondary"
        id="download-pdf-content-button"
        {...rest}
      >
        <DownloadIcon className={classes.icon} />
      </ButtonWithLoader>
    </>
  )
}

export default DownloadPdfContentButton
