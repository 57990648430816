import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'

interface TimetableContextProps {
  selectedDate: string | null
  setSelectedDate: (newDate: string) => void
}

const TimetableContext = createContext({} as TimetableContextProps)

const TimetableContextProvider = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)

  const [selectedDate, setSelectedDate] = useState<string | null>(
    queryParams.get('date'),
  )

  const setDate = useCallback(
    (newDate: string) => {
      const newQueryParams = new URLSearchParams(location.search)
      setSelectedDate(newDate)

      newQueryParams.set('date', newDate)
      navigate(`${location.pathname}?${newQueryParams.toString()}`, {
        replace: true,
      })
    },
    [location.search],
  )

  useEffect(() => {
    if (!selectedDate) {
      setDate(moment().format('YYYY-MM-DD'))
    }
  }, [selectedDate, location.pathname])

  const contextValue = useMemo(
    () => ({
      selectedDate,
      setSelectedDate: setDate,
    }),
    [selectedDate, setDate],
  )

  return (
    <TimetableContext.Provider value={contextValue}>
      {children}
    </TimetableContext.Provider>
  )
}

export { TimetableContext, TimetableContextProvider }
