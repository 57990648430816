import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const GENERATE_REPORT = 'pdmpReport/GENERATE_REPORT'
export const GENERATE_REPORT_SUCCESS = 'pdmpReport/GENERATE_REPORT_SUCCESS'
export const GENERATE_REPORT_FAILURE = 'pdmpReport/GENERATE_REPORT_FAILURE'
export const CLEAN_UP = 'pdmpReport/CLEAN_UP_LAST_APPOINTMENTS'

export const generateReport = (
  startDate: string,
  endDate: string,
  format: string,
) => ({
  type: GENERATE_REPORT,
  startDate,
  endDate,
  format,
})
export const generateReportSuccess = (reportUrl: string) => ({
  type: GENERATE_REPORT_SUCCESS,
  reportUrl,
})
export const generateReportFailure = (error: ApiError) => ({
  type: GENERATE_REPORT_FAILURE,
  error,
})
export const cleanUp = () => ({ type: CLEAN_UP })

export type PdmpReportState = {
  error: string | null
  isLoading: boolean
  reportUrl: string | null
}

const INITIAL_STATE: PdmpReportState = {
  isLoading: false,
  reportUrl: null,
  error: null,
}

export const pdmpReportReducer = (
  state: PdmpReportState = INITIAL_STATE,
  action: AnyAction,
): PdmpReportState => {
  switch (action.type) {
    case GENERATE_REPORT:
      return {
        ...state,
        isLoading: true,
        reportUrl: null,
      }
    case GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        reportUrl: action.reportUrl,
        isLoading: false,
      }
    case GENERATE_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CLEAN_UP:
      return {
        ...state,
        reportUrl: null,
        isLoading: false,
      }
    default:
      return state
  }
}

const getPDMPReport = (state: RootState): PdmpReportState => state.pdmpReport
export const getIsLoading = (state: RootState) => getPDMPReport(state).isLoading
export const getReportUrl = (state: RootState) => getPDMPReport(state).reportUrl

export function* generateReportSaga({
  startDate,
  endDate,
  format,
}: ReturnType<typeof generateReport>) {
  try {
    const { reportUrl } = yield* requestAPI(
      API.generatePDMPReport,
      startDate,
      endDate,
      format,
    )
    yield put(generateReportSuccess(reportUrl))
  } catch (error) {
    yield put(generateReportFailure(error as ApiError))
  }
}

function* watchGenerateReport() {
  yield takeLatest(GENERATE_REPORT, generateReportSaga)
}

export function* pdmpReportSaga() {
  yield all([watchGenerateReport()])
}
