import * as R from 'ramda'
import { AnyAction } from 'redux'
import { Defaults } from '@pbt/pbt-ui-components'

import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage, getErrorMessageDescription } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  ADD_MEMBER_TO_CURRENT_BUSINESS,
  CLEAR_ERROR,
  CREATE_TEAM,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  CREATE_TEAM_MEMBER_LICENSE,
  CREATE_TEAM_MEMBER_LICENSE_FAILURE,
  CREATE_TEAM_MEMBER_LICENSE_SUCCESS,
  CREATE_TEAM_SUCCESS,
  DELETE_MEMBER_FROM_BUSINESSES,
  DELETE_MEMBER_FROM_BUSINESSES_FAILURE,
  DELETE_MEMBER_FROM_BUSINESSES_SUCCESS,
  DELETE_MEMBER_FROM_CURRENT_BUSINESS,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  DELETE_TEAM_MEMBER_LICENSE,
  DELETE_TEAM_MEMBER_LICENSE_FAILURE,
  DELETE_TEAM_MEMBER_LICENSE_SUCCESS,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID_FAILURE,
  EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS,
  EDIT_TEAM_MEMBER_LICENSE,
  EDIT_TEAM_MEMBER_LICENSE_FAILURE,
  EDIT_TEAM_MEMBER_LICENSE_SUCCESS,
  FETCH_MEMBER,
  FETCH_MEMBER_FAILURE,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBERS_FOR_SELECT_LIST,
  FETCH_MEMBERS_FOR_SELECT_LIST_FAILURE,
  FETCH_MEMBERS_FOR_SELECT_LIST_SUCCESS,
  FETCH_MEMBERS_LIST,
  FETCH_MEMBERS_LIST_FAILURE,
  FETCH_MEMBERS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_SUCCESS,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST_FAILURE,
  FETCH_MORE_MEMBERS_FOR_SELECT_LIST_SUCCESS,
  FETCH_TEAMS_LIST,
  FETCH_TEAMS_LIST_FAILURE,
  FETCH_TEAMS_LIST_SUCCESS,
  INVITE_MEMBER,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_SUCCESS,
  UPDATE_ACTIVE_STATUS,
  UPDATE_ACTIVE_STATUS_FAILURE,
  UPDATE_ACTIVE_STATUS_SUCCESS,
  UPDATE_MEMBER,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_ROLES,
  UPDATE_MEMBER_ROLES_FAILURE,
  UPDATE_MEMBER_ROLES_SUCCESS,
  UPDATE_MEMBER_SUCCESS,
} from '../actions/types/members'
import type { RootState } from '../index'

export type MembersState = {
  error: string | null
  isActivating: boolean
  isDeleting: boolean
  isFetchingList: boolean
  isFetchingMember: boolean
  isFetchingSelectList: boolean
  isFetchingTeamsList: boolean
  isInviting: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  selectList: string[]
  selectTotalCount: number
  teamsList: string[]
  totalCount: number
}

export const MEMBERS_INITIAL_STATE: MembersState = {
  list: [],
  selectList: [],
  teamsList: [],
  isLoading: false,
  isFetchingList: false,
  isFetchingSelectList: false,
  isFetchingTeamsList: false,
  isUpdating: false,
  isActivating: false,
  isDeleting: false,
  isInviting: false,
  isFetchingMember: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  selectTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
}

const members = (
  state: MembersState = MEMBERS_INITIAL_STATE,
  action: AnyAction,
): MembersState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return MEMBERS_INITIAL_STATE
    case FETCH_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetchingList: false,
      }
    case FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetchingList: false,
      }
    case FETCH_MORE_ITEMS_FOR_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case FETCH_MEMBERS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetchingList: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case FETCH_MORE_ITEMS_FOR_MEMBERS_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_TEAMS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetchingTeamsList: true,
        teamsList: [],
      }
    case FETCH_TEAMS_LIST_SUCCESS:
      return {
        ...state,
        teamsList: R.uniq(action.list),
        isLoading: false,
        isFetchingTeamsList: false,
      }
    case FETCH_TEAMS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetchingTeamsList: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MEMBERS_FOR_SELECT_LIST:
      return {
        ...state,
        isLoading: true,
        isFetchingSelectList: true,
        selectList: [],
        selectTotalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case FETCH_MORE_MEMBERS_FOR_SELECT_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MEMBERS_FOR_SELECT_LIST_SUCCESS:
      return {
        ...state,
        selectList: R.uniq(action.list),
        isLoading: false,
        isFetchingSelectList: false,
        selectTotalCount: action.totalCount,
      }
    case FETCH_MORE_MEMBERS_FOR_SELECT_LIST_SUCCESS:
      return {
        ...state,
        selectList: mergeArraysAtIndex(
          state.selectList,
          action.list,
          action.from,
        ),
        isLoading: false,
        selectTotalCount: action.totalCount,
      }
    case FETCH_MEMBERS_FOR_SELECT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetchingSelectList: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_MEMBERS_FOR_SELECT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_TEAM_MEMBER_EMPLOYEE_ID:
    case EDIT_TEAM_MEMBER_EMPLOYEE_ID:
    case DELETE_TEAM_MEMBER_EMPLOYEE_ID:
    case CREATE_TEAM_MEMBER_LICENSE:
    case EDIT_TEAM_MEMBER_LICENSE:
    case DELETE_TEAM_MEMBER_LICENSE:
    case CREATE_TEAM:
    case UPDATE_MEMBER:
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        error: null,
      }
    case DELETE_MEMBER_FROM_BUSINESSES:
      return {
        ...state,
        isLoading: true,
        isDeleting: true,
        error: null,
      }
    case DELETE_MEMBER_FROM_BUSINESSES_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isLoading: false,
      }
    case DELETE_MEMBER_FROM_BUSINESSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_MEMBER_ROLES: {
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        error: null,
      }
    }
    case UPDATE_MEMBER_ROLES_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
      }
    }
    case UPDATE_MEMBER_ROLES_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    }
    case ADD_MEMBER_TO_CURRENT_BUSINESS:
      return {
        ...state,
        list: [action.memberId, ...state.list],
      }
    case DELETE_MEMBER_FROM_CURRENT_BUSINESS:
      return {
        ...state,
        list: R.without([action.memberId], state.list),
      }
    case UPDATE_ACTIVE_STATUS:
      return {
        ...state,
        isLoading: true,
        isActivating: true,
        error: null,
      }
    case INVITE_MEMBER:
      return {
        ...state,
        isLoading: true,
        isInviting: true,
        error: null,
      }
    case CREATE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS:
    case EDIT_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS:
    case DELETE_TEAM_MEMBER_EMPLOYEE_ID_SUCCESS:
    case CREATE_TEAM_MEMBER_LICENSE_SUCCESS:
    case EDIT_TEAM_MEMBER_LICENSE_SUCCESS:
    case DELETE_TEAM_MEMBER_LICENSE_SUCCESS:
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
      }
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
        teamsList: state.teamsList.concat(action.teamId),
      }
    case UPDATE_ACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        isActivating: false,
        isLoading: false,
      }
    case INVITE_MEMBER_SUCCESS:
      return {
        ...state,
        isInviting: false,
        isLoading: false,
      }
    case CREATE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE:
    case EDIT_TEAM_MEMBER_EMPLOYEE_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: getErrorMessageDescription(action.error),
      }
    case DELETE_TEAM_MEMBER_EMPLOYEE_ID_FAILURE:
    case CREATE_TEAM_MEMBER_LICENSE_FAILURE:
    case EDIT_TEAM_MEMBER_LICENSE_FAILURE:
    case CREATE_TEAM_FAILURE:
    case UPDATE_MEMBER_FAILURE:
    case DELETE_TEAM_MEMBER_LICENSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_ACTIVE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isActivating: false,
        error: getErrorMessage(action.error),
      }
    case INVITE_MEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isInviting: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_ERROR:
      return { ...state, error: null }

    case FETCH_MEMBER:
      return { ...state, isFetchingMember: true, isLoading: true }
    case FETCH_MEMBER_FAILURE:
      return {
        ...state,
        isFetchingMember: false,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        isFetchingMember: false,
        isLoading: false,
      }
    default:
      return state
  }
}

export default members
export const getMembers = (state: RootState): MembersState => state.members
export const getMembersList = (state: RootState) => getMembers(state).list
export const getMembersSelectList = (state: RootState) =>
  getMembers(state).selectList
export const getTeamsList = (state: RootState) => getMembers(state).teamsList
export const getMemberIsLoading = (state: RootState) =>
  getMembers(state).isLoading
export const getMembersIsFetchingList = (state: RootState) =>
  getMembers(state).isFetchingList
export const getMembersIsFetchingSelectList = (state: RootState) =>
  getMembers(state).isFetchingSelectList
export const getMembersIsFetchingTeamsList = (state: RootState) =>
  getMembers(state).isFetchingTeamsList
export const getMembersIsUpdating = (state: RootState) =>
  getMembers(state).isUpdating
export const getMembersIsActivating = (state: RootState) =>
  getMembers(state).isActivating
export const getMembersIsDeleting = (state: RootState) =>
  getMembers(state).isDeleting
export const getMembersIsInviting = (state: RootState) =>
  getMembers(state).isInviting
export const getMembersIsFetchingMember = (state: RootState) =>
  getMembers(state).isFetchingMember
export const getMembersError = (state: RootState) => getMembers(state).error
export const getMembersTotalCount = (state: RootState) =>
  getMembers(state).totalCount
export const getMembersSelectTotalCount = (state: RootState) =>
  getMembers(state).selectTotalCount
