import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { FormControl, Input, InputAdornment, InputLabel } from '@mui/material'
import { Utils } from '@pbt/pbt-ui-components'

export interface SearchInputProps {
  autoFocus?: boolean
  className?: string
  label: string
  onChange: (value: string) => void
  value: string
}

const SearchInput = ({
  value,
  label,
  className,
  onChange,
  autoFocus,
}: SearchInputProps) => (
  <FormControl fullWidth className={className}>
    <InputLabel htmlFor="search">{label}</InputLabel>
    <Input
      autoComplete="off"
      autoFocus={autoFocus}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      id="search"
      inputProps={{ maxLength: 100 }}
      type="text"
      value={value}
      onChange={Utils.handleFormTextInput(onChange)}
    />
  </FormControl>
)

export default SearchInput
