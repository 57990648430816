import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { Charges } from '~/api/graphql/generated/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export type ChargesState = {
  charges: Charges | Nil
  error: string | Nil
  isLoading: boolean
}

export const CHARGES_INITIAL_STATE: ChargesState = {
  error: null,
  isLoading: false,
  charges: null,
}

const chargesSlice = createSlice({
  name: 'Charges',
  initialState: CHARGES_INITIAL_STATE,
  reducers: {
    fetchSoapCharges: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string
        includeDeleted?: boolean
        soapId: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
      state.charges = null
    },
    fetchSoapChargesSuccess: (state, action: PayloadAction<Charges>) => {
      state.isLoading = false
      state.charges = action.payload
    },
    fetchSoapChargesFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchChargesByLog: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        logId: string
        logType: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
      state.charges = null
    },
    fetchChargesByLogSuccess: (state, action: PayloadAction<Charges>) => {
      state.isLoading = false
      state.charges = action.payload
    },
    fetchChargesByLogFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
  },
})

const { actions, reducer } = chargesSlice

export const {
  fetchSoapCharges,
  fetchSoapChargesSuccess,
  fetchSoapChargesFailure,
  fetchChargesByLog,
  fetchChargesByLogSuccess,
  fetchChargesByLogFailure,
} = actions

export default reducer

export const getChargesState = (state: RootState): ChargesState => state.charges
export const getChargesLoading = (state: RootState) =>
  getChargesState(state).isLoading
export const getCharges = (state: RootState) => getChargesState(state).charges
export const getChargesError = (state: RootState) =>
  getChargesState(state).error
