import { User } from '@pbt/pbt-ui-components'

import {
  EmployeeIdInfoToCreate,
  FetchMembersOptions,
  TeamFilter,
  TeamMemberLicense,
} from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchPerson = (
  businessId: string,
  personId: string,
  includeDeleted = false,
) =>
  request(
    getAPIPath(`persons/${personId}`),
    { method: 'GET', params: { businessId, includeDeleted } },
    true,
    Schema.user,
  )

export const createPerson = (businessId: string, person: Omit<User, 'id'>) =>
  request(
    getAPIPath('persons'),
    { method: 'POST', data: person, params: { businessId } },
    true,
    Schema.user,
  )

export const updatePerson = (businessId: string, person: User) =>
  request(
    getAPIPath(`persons/${person.id}`),
    { method: 'PUT', data: person, params: { businessId } },
    true,
    Schema.user,
  )

export const inviteMember = (businessId: string, person: User) =>
  request(
    getAPIPath('persons/invite'),
    { method: 'POST', data: person, params: { businessId } },
    true,
  )

export const fetchPersons = (
  currentBusinessId: string,
  {
    businessId = currentBusinessId,
    from = 0,
    to = 100,
    includeInactive,
    shorten,
    roleIds,
    noRolesInResult,
    includeInvitationStatus,
    teamFilter,
  }: FetchMembersOptions & { teamFilter?: TeamFilter },
) =>
  request(
    getAPIPath('persons'),
    {
      method: 'GET',
      params: {
        businessId,
        from,
        to,
        includeInactive,
        shorten,
        roleIds: roleIds && roleIds.join(','),
        noRolesInResult,
        includeInvitationStatus,
        teamFilter,
      },
    },
    true,
    Schema.membersList,
  )

export const createTeamMemberEmployeeId = (
  businessId: string,
  teamMemberId: string,
  newEmployeeIdInfo: EmployeeIdInfoToCreate,
) =>
  request(
    getAPIPath(`persons/${teamMemberId}/employeeIdInfo`),
    { method: 'PUT', data: newEmployeeIdInfo, params: { businessId } },
    true,
  )

export const deleteTeamMemberEmployeeId = (
  businessId: string,
  teamMemberId: string,
  employeeIdInfoId: string,
) =>
  request(
    getAPIPath(`persons/${teamMemberId}/employeeIdInfo/${employeeIdInfoId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const createTeamMemberLicense = (
  businessId: string,
  teamMemberId: string,
  license: TeamMemberLicense,
) =>
  request(
    getAPIPath(`persons/${teamMemberId}/license`),
    { method: 'PUT', data: license, params: { businessId } },
    true,
  )

export const deleteTeamMemberLicense = (
  businessId: string,
  teamMemberId: string,
  licenseId: string,
) =>
  request(
    getAPIPath(`persons/${teamMemberId}/license/${licenseId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const updateMemberBusinessScope = (
  businessId: string,
  id: string,
  businessScope: any,
) =>
  request(
    getAPIPath(`persons/${id}/businessScope`),
    { method: 'PUT', data: businessScope, params: { businessId } },
    true,
    Schema.user,
  )

export const deleteMemberFromBusinesses = (
  businessId: string,
  memberId: string,
  businessesIds: string[],
) =>
  request(
    getAPIPath(`role/person/${memberId}/businesses/${businessesIds.join(',')}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const updateMemberRoles = (
  businessId: string,
  memberId: string,
  businessToRoleList: User['businessToRoleList'],
) =>
  request(
    getAPIPath(`role/person/${memberId}`),
    { method: 'PATCH', params: { businessId }, data: businessToRoleList },
    true,
  )
