import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BackButton, ButtonWithLoader } from '@pbt/pbt-ui-components'

import { getPaymentsIsLoading } from '~/store/reducers/payments'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 220,
    },
  }),
  { name: 'BillingAddressesActions' },
)

interface BillingAddressesActionsProps {
  onBack?: () => void
  onProcessBillingAddresses: () => void
}

export const BillingAddressesActions = ({
  onBack,
  onProcessBillingAddresses,
}: BillingAddressesActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const isLoading = useSelector(getPaymentsIsLoading)

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      {onBack && (
        <Box mr={3}>
          <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
        </Box>
      )}
      <Box>
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={isLoading}
          type="submit"
          onClick={onProcessBillingAddresses}
        >
          {t('Invoices:PAYMENTS.BILLING_ADDRESSES.CONTINUE_TO_CARD_ENTRY')}
        </ButtonWithLoader>
      </Box>
    </Box>
  )
}
