import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import FeatureToggle from '~/constants/featureToggle'
import { fetchBusinessSoapWidgets } from '~/store/actions/businesses'
import { addSOAP, createSOAP, fetchSoap } from '~/store/actions/soap'
import { clearSoapTasks } from '~/store/actions/tasks'
import { fetchModality } from '~/store/duck/imagingOrders'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getError,
  getSoapBusinessId,
  getSoapId,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { addOriginalBusinessId } from '~/utils'

export const useInitializeSoap = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {
    soapId: urlSoapId,
    appointmentId: urlAppointmentId,
    tabName: urlTabName,
  } = useParams()
  const location = useLocation()

  const currentBusinessId = useSelector(getCurrentBusinessId)
  const soapId = useSelector(getSoapId)
  const isLoading = useSelector(getSOAPisLoading)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const error = useSelector(getError)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const [soapWasCreated, setSoapWasCreated] = useState(false)
  const [soapCreation, setSoapCreation] = useState(false)

  const originalBusinessId = searchParams.get('originalBusinessId')

  useEffect(() => {
    if (soapBusinessId) {
      dispatch(fetchBusinessSoapWidgets(soapBusinessId))
    }
  }, [soapBusinessId])

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchModality(currentBusinessId))
    }
  }, [currentBusinessId])

  useEffect(() => {
    if (urlSoapId) {
      dispatch(clearSoapTasks())
      if (!soapWasCreated || soapId !== urlSoapId) {
        dispatch(fetchSoap(urlSoapId, originalBusinessId))
      }
    }
  }, [urlSoapId])

  useEffect(() => {
    if (isLoading || urlSoapId) {
      return
    }

    if (soapId && soapCreation) {
      setSoapCreation(false)
      setSoapWasCreated(true)
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}`,
          isPatientSharingEnabled ? soapBusinessId : null,
        ),
        {
          replace: true,
        },
      )
    } else if (urlAppointmentId && !error) {
      setSoapCreation(true)
      if (location.pathname.startsWith('/soap/add/')) {
        dispatch(
          addSOAP(
            urlAppointmentId,
            isPatientSharingEnabled ? originalBusinessId : null,
          ),
        )
      } else {
        dispatch(
          createSOAP(
            urlAppointmentId,
            isPatientSharingEnabled ? originalBusinessId : null,
          ),
        )
      }
    } else {
      navigate('/')
    }
  }, [urlSoapId, soapId, isLoading, urlAppointmentId, soapCreation])

  useEffect(() => {
    if (soapId && !urlTabName) {
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}`,
          isPatientSharingEnabled ? soapBusinessId : null,
        ),
        {
          replace: true,
        },
      )
    }
  }, [soapId])
}
