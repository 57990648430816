import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Nil, Text } from '@pbt/pbt-ui-components'

import InvoiceType from '~/constants/InvoiceType'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getInvoiceV3Loading, voidInvoice } from '~/store/reducers/invoiceV3'
import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import { useNavigatePrevState } from '~/utils/useNavigatePrevState'

const useStyles = makeStyles(
  () => ({
    button: {
      height: 42,
      minWidth: 172,
    },
  }),
  { name: 'NoInvoiceCard' },
)

type InvoiceCardProps = {
  disabled?: boolean
  id: string | Nil
  invoiceName: string | Nil
}

export const InvoiceCard = ({
  invoiceName,
  id,
  disabled,
}: InvoiceCardProps) => {
  const classes = useStyles()
  const navigatePrevState = useNavigatePrevState()
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()

  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const client = useSelector(getUser(appointment?.client))
  const currentUserId = useSelector(getCurrentUserId)
  const isInvoiceLoading = useSelector(getInvoiceV3Loading)

  const openChargeSheet = () => navigatePrevState(`/chargesheet/${client?.id}`)

  const openInvoice = useOpenInvoice(client?.id)

  const openAfterUnPost = useCloseAfterCreation(
    openChargeSheet,
    getInvoiceV3Loading,
  )

  const reopenCharges = () => {
    if (id && currentUserId) {
      dispatch(voidInvoice({ invoiceId: id, voidedById: currentUserId }))
    }
    openAfterUnPost()
  }

  const openInvoiceSafe = () =>
    openInvoice({ invoiceId: id, chargesEntityType: InvoiceType.INVOICE })

  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="space-between"
      px={2}
      py={2}
    >
      <Grid container item alignItems="center" gap={1.5} width="auto">
        <Text strong>{invoiceName}</Text>
      </Grid>
      <Grid container item gap={1} width="auto">
        <ButtonWithLoader
          className={classes.button}
          color="secondary"
          disabled={disabled}
          loading={isInvoiceLoading}
          type="submit"
          onClick={reopenCharges}
        >
          {t('Common:RE-OPEN_CHARGES')}
        </ButtonWithLoader>
        <ButtonWithLoader
          className={classes.button}
          type="submit"
          onClick={openInvoiceSafe}
        >
          {t('Common:OPEN_INVOICE')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}
