import { AnyAction } from 'redux'
import { all, put, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { HandleChannelIdFn } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const INITIALIZE_CHANNEL_ID = 'ckeditor/INITIALIZE_CHANNEL_ID'
export const INITIALIZE_CHANNEL_ID_SUCCESS =
  'ckeditor/INITIALIZE_CHANNEL_ID_SUCCESS'
export const INITIALIZE_CHANNEL_ID_FAILURE =
  'ckeditor/INITIALIZE_CHANNEL_ID_FAILURE'

export const RESTORE_COLLABORATION_SESSION =
  'ckeditor/RESTORE_COLLABORATION_SESSION'
export const RESTORE_COLLABORATION_SESSION_SUCCESS =
  'ckeditor/RESTORE_COLLABORATION_SESSION_SUCCESS'
export const RESTORE_COLLABORATION_SESSION_FAILURE =
  'ckeditor/RESTORE_COLLABORATION_SESSION_FAILURE'

export const TOGGLE_TRACK_CHANGES = 'ckeditor/TOGGLE_TRACK_CHANGES'

export const SET_CURRENT_EDITOR_TAB = 'ckeditor/SET_CURRENT_EDITOR_TAB'

export const initializeChannelId: HandleChannelIdFn<AnyAction> = (
  documentType,
  data,
  { bundleType, restore, editorId },
) => ({
  type: INITIALIZE_CHANNEL_ID,
  documentType,
  bundleType,
  data,
  editorId,
  restore,
})
export const initializeChannelIdSuccess = (
  editorId: string,
  bundleVersion: string,
  channelId: string,
  webSocketUrl: string,
) => ({
  type: INITIALIZE_CHANNEL_ID_SUCCESS,
  editorId,
  bundleVersion,
  channelId,
  webSocketUrl,
})
export const initializeChannelIdFailure = (error: ApiError) => ({
  type: INITIALIZE_CHANNEL_ID_FAILURE,
  error,
})

export const restoreCollaborationSession: HandleChannelIdFn<AnyAction> = (
  documentType,
  data,
  { bundleType, editorId },
) => ({
  type: RESTORE_COLLABORATION_SESSION,
  documentType,
  bundleType,
  data,
  editorId,
})
export const restoreCollaborationSessionSuccess = (
  editorId: string,
  bundleVersion: string,
  channelId: string,
  webSocketUrl: string,
) => ({
  type: RESTORE_COLLABORATION_SESSION_SUCCESS,
  editorId,
  bundleVersion,
  channelId,
  webSocketUrl,
})
export const restoreCollaborationSessionFailure = (error: ApiError) => ({
  type: RESTORE_COLLABORATION_SESSION_FAILURE,
  error,
})

export const toggleTrackChanges = (editorId: string, enabled: boolean) => ({
  type: TOGGLE_TRACK_CHANGES,
  editorId,
  enabled,
})

export const setCurrentEditorTab = (value: number) => ({
  type: SET_CURRENT_EDITOR_TAB,
  value,
})

export type EditorConfig = {
  bundleVersion: string | undefined
  channelId: string | undefined
  enabledTrackChanges: boolean
  webSocketUrl: string | undefined
}

export type CKEditorState = {
  currentTab?: number
  editorMap: Record<string, EditorConfig> | null
  error: string | null
  isLoading: boolean
}

export const INITIAL_STATE: CKEditorState = {
  isLoading: false,
  editorMap: null,
  error: null,
}

export const ckeditorReducer = (
  state: CKEditorState = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case RESTORE_COLLABORATION_SESSION:
    case INITIALIZE_CHANNEL_ID:
      return { ...state, isLoading: true, error: null }
    case RESTORE_COLLABORATION_SESSION_SUCCESS:
    case INITIALIZE_CHANNEL_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editorMap: secondLevelMerge(state.editorMap || {}, {
          [action.editorId]: {
            bundleVersion: action.bundleVersion,
            channelId: action.channelId,
            webSocketUrl: action.webSocketUrl,
            enabledTrackChanges:
              state.editorMap?.[action.editorId]?.enabledTrackChanges || false,
          },
        }),
      }
    case RESTORE_COLLABORATION_SESSION_FAILURE:
    case INITIALIZE_CHANNEL_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case TOGGLE_TRACK_CHANGES:
      return {
        ...state,
        editorMap: secondLevelMerge(state.editorMap || {}, {
          [action.editorId]: {
            enabledTrackChanges: action.enabled,
          },
        }),
      }
    case SET_CURRENT_EDITOR_TAB:
      return { ...state, currentTab: action.value }
    default:
      return state
  }
}

export const getCKEditor = (state: RootState): CKEditorState => state.ckeditor
export const getCKEditorError = (state: RootState) => getCKEditor(state).error
export const getCKEditorMap = (state: RootState) => getCKEditor(state).editorMap
export const getCKEditorConfigById = (editorId: string) =>
  createSelector(getCKEditorMap, (map) => map?.[editorId] || null)
export const getCKEditorBundleVersion = (editorId: string) =>
  createSelector(
    getCKEditorConfigById(editorId),
    (config) => config?.bundleVersion || undefined,
  )
export const getCKEditorChannelId = (editorId: string) =>
  createSelector(
    getCKEditorConfigById(editorId),
    (config) => config?.channelId || undefined,
  )
export const getCKEditorWebSocketUrl = (editorId: string) =>
  createSelector(
    getCKEditorConfigById(editorId),
    (config) => config?.webSocketUrl || undefined,
  )
export const getCKEditorEnabledTrackChanges = (editorId: string) =>
  createSelector(
    getCKEditorConfigById(editorId),
    (config) => config?.enabledTrackChanges || false,
  )
export const getCKEditorCurrentEditorTab = (state: RootState) =>
  getCKEditor(state).currentTab

export function* initializeChannelIdSaga({
  editorId,
  documentType,
  bundleType,
  data,
  restore,
}: ReturnType<typeof initializeChannelId>) {
  try {
    const {
      bundleVersion,
      channelId,
      wssUrl: webSocketUrl,
    } = yield* requestAPI(
      API.initializeChannelId,
      documentType,
      bundleType,
      data,
      restore,
    )

    yield put(
      initializeChannelIdSuccess(
        editorId,
        bundleVersion,
        channelId,
        webSocketUrl,
      ),
    )
  } catch (error) {
    yield put(initializeChannelIdFailure(error as ApiError))
  }
}

export function* restoreCollaborationSessionSaga({
  editorId,
  documentType,
  bundleType,
  data,
}: ReturnType<typeof restoreCollaborationSession>) {
  try {
    const {
      bundleVersion,
      channelId,
      wssUrl: webSocketUrl,
    } = yield* requestAPI(
      API.restoreCollaborationSession,
      documentType,
      bundleType,
      data,
    )

    yield put(
      restoreCollaborationSessionSuccess(
        editorId,
        bundleVersion,
        channelId,
        webSocketUrl,
      ),
    )
  } catch (error) {
    yield put(restoreCollaborationSessionFailure(error as ApiError))
  }
}

function* watchInitializeChannelId() {
  yield takeLeading(INITIALIZE_CHANNEL_ID, initializeChannelIdSaga)
}

function* watchRestoreCollaborationSession() {
  yield takeLeading(
    RESTORE_COLLABORATION_SESSION,
    restoreCollaborationSessionSaga,
  )
}

export function* ckeditorSaga() {
  yield all([watchInitializeChannelId(), watchRestoreCollaborationSession()])
}
