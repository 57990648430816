import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { VetcoveConfig, VetcoveConfigMap } from '~/types/entities/vetcoveConfig'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '..'
import { getCurrentUserId } from '../reducers/auth'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_VETCOVE_CONFIG = 'vetcoveConfig/FETCH_VETCOVE_CONFIG'
export const FETCH_VETCOVE_CONFIG_SUCCESS =
  'vetcoveConfig/FETCH_VETCOVE_CONFIG_SUCCESS'
export const FETCH_VETCOVE_CONFIG_FAILURE =
  'vetcoveConfig/FETCH_VETCOVE_CONFIG_FAILURE'

export const EDIT_VETCOVE_CONFIG = 'vetcoveConfig/EDIT_VETCOVE_CONFIG'
export const EDIT_VETCOVE_CONFIG_SUCCESS =
  'vetcoveConfig/EDIT_VETCOVE_CONFIG_SUCCESS'
export const EDIT_VETCOVE_CONFIG_FAILURE =
  'vetcoveConfig/EDIT_VETCOVE_CONFIG_FAILURE'

export const fetchVetcoveConfig = (businessId: string) => ({
  type: FETCH_VETCOVE_CONFIG,
  businessId,
})

export const fetchVetcoveConfigSuccess = (config: VetcoveConfig) => ({
  type: FETCH_VETCOVE_CONFIG_SUCCESS,
  config,
})

export const fetchVetcoveConfigFailure = (error: ApiError) => ({
  type: FETCH_VETCOVE_CONFIG_FAILURE,
  error,
})

export const editVetcoveConfig = (config: VetcoveConfig) => ({
  config,
  type: EDIT_VETCOVE_CONFIG,
})

export const editVetcoveConfigSuccess = (config: VetcoveConfig) => ({
  config,
  type: EDIT_VETCOVE_CONFIG_SUCCESS,
})

export const editVetcoveConfigFailure = (error: ApiError) => ({
  error,
  type: EDIT_VETCOVE_CONFIG_FAILURE,
})

export type VetcoveConfigsState = {
  error: string | null
  isLoading: boolean
  map: VetcoveConfigMap
}

export const INITIAL_STATE = {
  map: {},
  isLoading: false,
  error: null,
}

export const vetcoveConfigsReducer = (
  state: VetcoveConfigsState = INITIAL_STATE,
  action: AnyAction,
): VetcoveConfigsState => {
  switch (action.type) {
    case FETCH_VETCOVE_CONFIG:
    case EDIT_VETCOVE_CONFIG:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_VETCOVE_CONFIG_SUCCESS:
    case EDIT_VETCOVE_CONFIG_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.config.businessId]: action.config,
        },
        isLoading: false,
        error: null,
      }
    case FETCH_VETCOVE_CONFIG_FAILURE:
    case EDIT_VETCOVE_CONFIG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getVetcoveConfigs = (state: RootState): VetcoveConfigsState =>
  state.vetcoveConfigs
export const getVetcoveConfigMap = (state: RootState) =>
  getVetcoveConfigs(state).map
export const getVetcoveConfig = (businessId: string) =>
  createSelector(getVetcoveConfigMap, (map) => R.prop(businessId, map))

export function* fetchConfigSaga({
  businessId,
}: ReturnType<typeof fetchVetcoveConfig>) {
  try {
    const currentUserId: string = yield select(getCurrentUserId)
    const config = yield* requestAPI(
      API.fetchVetcoveConfig,
      businessId,
      currentUserId,
    )
    yield put(fetchVetcoveConfigSuccess(config))
  } catch (error) {
    yield put(fetchVetcoveConfigFailure(error as ApiError))
  }
}

export function* editConfigSaga({
  config,
}: ReturnType<typeof editVetcoveConfig>) {
  try {
    const currentUserId: string = yield select(getCurrentUserId)
    yield* requestAPI(API.editVetcoveConfig, currentUserId, config)
    yield put(editVetcoveConfigSuccess(config))
  } catch (error) {
    yield put(editVetcoveConfigFailure(error as ApiError))
  }
}

function* watchFetchConfig() {
  yield takeEvery(FETCH_VETCOVE_CONFIG, fetchConfigSaga)
}

function* watchEditConfig() {
  yield takeLeading(EDIT_VETCOVE_CONFIG, editConfigSaga)
}

export function* vetcoveConfigSaga() {
  yield all([watchFetchConfig(), watchEditConfig()])
}
