import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'

import PuiTabs from '~/components/common/PuiTabs'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import { Modify, SoapTemplateTab } from '~/types'
import { addOriginalBusinessId } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    tabs: {
      marginTop: 0,
      height: 35,
    },
    tabRoot: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
  }),
  { name: 'SoapHeaderTabs' },
)

export interface SoapHeaderTabsProps {
  tabs: Modify<SoapTemplateTab, { id: string }>[] | undefined
}

const SoapHeaderTabs = ({ tabs }: SoapHeaderTabsProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { tabName: urlTabName } = useParams()

  const soapId = useSelector(getSoapId)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const soapBusinessId = useSelector(getSoapBusinessId)

  if (!tabs) {
    return null
  }

  const getSelectedTabIdx = () => {
    const index = R.findIndex((tab) => tab.url === urlTabName, tabs)
    return index !== -1 ? index : 0
  }

  const onStepperStepChangeIdx = (stepIdx: number) => {
    if (soapId && tabs[stepIdx]?.url) {
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}/tab/${tabs[stepIdx].url}`,
          isPatientSharingEnabled ? soapBusinessId : null,
        ),
      )
    }
  }

  return (
    <PuiTabs
      classes={{ tabs: classes.tabs, tabRoot: classes.tabRoot }}
      selectedTab={getSelectedTabIdx()}
      tabs={tabs}
      variant="standard"
      onSelectedTabChange={onStepperStepChangeIdx}
    />
  )
}

export default SoapHeaderTabs
