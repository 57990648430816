import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  HtmlNotesPreview,
  LanguageUtils,
  PermissionArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { deleteNoteHistory } from '~/store/actions/medicalHistory'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle, getNoteType } from '~/store/reducers/constants'
import { getUserName } from '~/store/reducers/users'
import { NoteTimelineItem } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

export interface NoteCardProps extends Omit<TimelineCardProps, 'title'> {
  clientId: string
  item: NoteTimelineItem
  patientId: string
}

const NoteCard = ({ item, clientId, patientId, ...rest }: NoteCardProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Clients'])

  const NoteType = useSelector(getNoteType)
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const personName = useSelector(getUserName(item.person))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const AddendumNoteTypeId = Utils.findConstantIdByName('Addendum', NoteType)

  const [openNoteHistoryDialog] = useDialog(DialogNames.NOTE_HISTORY)

  const isAddendum = item.noteTypeId === AddendumNoteTypeId

  const onEdit = () => {
    openNoteHistoryDialog({ clientId, patientId, note: item })
  }

  const onDelete = () => {
    dispatch(deleteNoteHistory(item.id, patientId))
  }

  const showSoapLink = item.soapId && soapPermissions.read
  const showActions = Boolean(item.practiceFrom) || showSoapLink

  return (
    <TimelineCard
      originBusinessId={item.businessId}
      title={
        isAddendum ? t('Clients:TIMELINE.NOTE_CARD.TITLE') : t('Common:NOTE')
      }
      onDelete={isAddendum ? undefined : onDelete}
      onEdit={isAddendum ? undefined : onEdit}
      {...item}
      {...rest}
    >
      <TimelineCardContent noTypography>
        {personName && (
          <Text strong mb={1} variant="body2">
            {LanguageUtils.lowercaseFirst(
              t('Common:ADDED_BY_PERSON_NAME', { personName }),
            )}
          </Text>
        )}
        <HtmlNotesPreview includeLabel={!isAddendum} notes={item.notes} />
      </TimelineCardContent>
      {showActions && (
        <TimelineCardActions
          state={
            item.practiceFrom &&
            t('Clients:TIMELINE.NOTE_CARD.RECORD_FROM_STATE', {
              practiceFrom: item.practiceFrom,
            })
          }
        >
          {showSoapLink && (
            <Link
              to={addOriginalBusinessId(
                `/soap/${item.soapId}`,
                isPatientSharingEnabled ? item.businessId : null,
              )}
            >
              {t('Common:VIEW_SOAP')}
            </Link>
          )}
        </TimelineCardActions>
      )}
    </TimelineCard>
  )
}

export default NoteCard
