import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'

const MonitorAppEnvironments: Partial<Record<Environment, string>> = {
  [Environment.PROD]: 'https://monitor.rhapsody.vet',
  [Environment.MASTER]: 'https://monitor.master.rhapsody.vet',
  [Environment.STAGE]: 'https://monitor.stage.rhapsody.vet',
  [Environment.LOCAL]: 'http://localhost:5173',
}

export const getMonitorDomain = (env: Environment) =>
  MonitorAppEnvironments[env] ||
  (MonitorAppEnvironments[Environment.LOCAL] as string)
