import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDebounce } from 'use-debounce'
import { Defaults, Text } from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import { PrescriptionStateLabel } from '~/components/common/labels/PrescriptionStateLabel'
import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionType } from '~/constants/prescription'
import { partialEditOrder } from '~/store/actions/orders'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useCreateOrUpdatePrescription } from '~/store/hooks/prescription'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Order } from '~/types'
import { calculateAvailableQuantity } from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

import OrderStatusSelect from '../../../../to-do/OrderStatusSelect'
import PrescriptionItemDeclineButton from '../buttons/PrescriptionItemDeclineButton'
import PrescriptionItemDispenseButton from '../buttons/PrescriptionItemDispenseButton'
import PrescriptionItemPrescribeButton from '../buttons/PrescriptionItemPrescribeButton'

const useStyles = makeStyles(
  () => ({
    stateLabel: {
      paddingLeft: 0,
      width: 'fit-content',
    },
    statusSelect: {
      width: 'fit-content',
      fontSize: '1rem',
    },
  }),
  { name: 'PrescriptionItemButtonSection' },
)

export interface PrescriptionItemButtonSectionProps {
  editDisabled?: boolean
  order: Order
  readOnly?: boolean
}

const PrescriptionItemButtonSection = ({
  order,
  editDisabled,
  readOnly,
}: PrescriptionItemButtonSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Abbreviations')

  const isChewyActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )
  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const createOrUpdatePrescription = useCreateOrUpdatePrescription(true)

  const availableQuantity = calculateAvailableQuantity(order)
  const priceUnits = useGetFormattedPriceUnit(order.price, order.variation)
  const { isInHouse, isScriptOut, isChewyActiveRx } = getPrescriptionType(
    order.prescriptionType,
    order.origin,
  )

  const quantityField =
    isScriptOut || isChewyActiveRx ? 'totalQuantity' : 'quantity'

  const [rawValue, setRawValue] = useState(order[quantityField])
  const [value] = useDebounce(rawValue, Defaults.DEBOUNCE_ACTION_TIME)

  useEffect(() => {
    const newValue = order[quantityField]
    if (newValue) {
      setRawValue(newValue)
    }
  }, [order[quantityField]])

  useEffect(() => {
    if (value !== order[quantityField]) {
      if (
        order?.prescriptionType &&
        order?.prescriptionType === PrescriptionType.CHEWY &&
        isChewyCheckoutEnabled
      ) {
        // RetailOrderLineItem path
        createOrUpdatePrescription(
          {
            ...order,
            autoshipUnitId: order?.retailOrderLineItem?.autoshipUnitId,
            autoshipFrequency: order?.retailOrderLineItem?.autoshipFrequency,
            [quantityField]: value || 1,
          },
          {
            isActiveRxSaveDraft: true,
          },
        )
      } else {
        // InvoiceLineItem path
        dispatch(
          partialEditOrder({
            id: order.id,
            type: order.type,
            [quantityField]: value || 1,
          }),
        )
      }
    }
  }, [value])

  return (
    <Box>
      {isChewyActiveRx && order.stateId && (
        <Box pb={2}>
          <PrescriptionStateLabel
            className={classes.stateLabel}
            stateId={order.stateId}
          />
        </Box>
      )}
      <Box
        alignItems="center"
        display="flex"
        flexWrap={isChewyActiveRxEnabled ? 'wrap' : 'nowrap'}
        ml={-2.5}
      >
        <Box pr={1} pt={0.5}>
          {readOnly ? (
            <Box display="flex" flexDirection="column" pl={2.5}>
              <Text variant="body2">
                {`${t('Abbreviations:COMMON.QUANTITY')}: ${
                  order.quantity ? `${order.quantity} ` : ''
                }${priceUnits}`}
              </Text>
              {!isChewyActiveRx && (
                <OrderStatusSelect
                  disabled
                  classes={{ statusSelect: classes.statusSelect }}
                  order={order}
                />
              )}
            </Box>
          ) : (
            <QuantityInput
              allowDecimal
              labelEnabled
              availableQuantity={availableQuantity}
              min={0.001}
              value={rawValue}
              onChange={setRawValue}
            />
          )}
        </Box>
        {!readOnly && (
          <Box>
            <Stack direction="row" spacing={1}>
              {isInHouse ? (
                <>
                  <PrescriptionItemDispenseButton
                    disabled={editDisabled}
                    order={order}
                  />
                  <PrescriptionItemDeclineButton
                    disabled={editDisabled}
                    order={order}
                  />
                </>
              ) : isChewyActiveRx ? (
                <PrescriptionItemDeclineButton
                  disabled={editDisabled}
                  order={order}
                />
              ) : (
                <>
                  <PrescriptionItemPrescribeButton
                    disabled={editDisabled}
                    order={order}
                  />
                  <PrescriptionItemDeclineButton
                    disabled={editDisabled}
                    order={order}
                  />
                </>
              )}
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PrescriptionItemButtonSection
