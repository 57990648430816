import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Field } from '@pbt/pbt-ui-components'

import { getPrescriptionChewyItems } from '~/store/duck/prescriptions'

export interface UseChewyItemOutOfStockProps {
  chewyItemField: Field
  isDraft: boolean
}

export const useChewyItemOutOfStock = ({
  chewyItemField,
  isDraft,
}: UseChewyItemOutOfStockProps) => {
  const chewyItems = useSelector(getPrescriptionChewyItems)

  const currentItem = chewyItems?.find(
    (item) => item.partNumber === chewyItemField.value,
  )

  // If a user selects a chewy item, saves draft, then opens a saved prescription, and the item becomes out-of-stock - unselect selected chewy item.
  useEffect(() => {
    if (isDraft && currentItem?.inStock === false) {
      chewyItemField.setValue(undefined)
    }
  }, [currentItem?.inStock, isDraft])
}
