import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CancelIcon from '@mui/icons-material/Cancel'
import { Chip, Grid, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Business,
  ClassesType,
  CommunicationsUtils,
  Coparent,
  Field,
  Nil,
  PlusButton,
  PuiInput,
  PuiTextField,
  Text,
} from '@pbt/pbt-ui-components'
import { TextProps } from '@pbt/pbt-ui-components/src/components/Text'

import { ConversationTransport } from '~/api/graphql/generated/types'
import { ContactSelectType } from '~/components/common/email/SelectContactDialog'
import DialogNames from '~/constants/DialogNames'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { Contact, EmailEntityConfigRecipient } from '~/types'
import {
  getBusinessToName,
  getClientToName,
  getContactToName,
} from '~/utils/communicationsUtils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    plusButtonIconContainer: {
      margin: theme.spacing(1, 0, 1),
    },
    container: {
      width: '100%',
    },
    toTextInput: {
      height: 40,
      padding: theme.spacing(0, 1),
    },
    phonesContainer: {
      minHeight: 40,
      border: theme.constants.tabBorder,
      borderRadius: 2,
      paddingRight: 13,
    },
    cancelIcon: {
      color: theme.colors.menuSubheader,
      width: 16,
      height: 16,
      '&:hover': {
        color: theme.colors.menuSubheader,
      },
    },
    chip: {
      margin: theme.spacing(0, 0.5, 0.5, 1),
    },
  }),
  { name: 'ToInput' },
)

const mapTransportToContactSelectType = (
  transport: ConversationTransport | Nil,
) => {
  switch (transport) {
    case ConversationTransport.Sms:
      return ContactSelectType.PHONE
    default:
      return ContactSelectType.EMAIL
  }
}

export type ToInputHandle = {
  formRecipients: () => EmailEntityConfigRecipient[]
}

export interface ToInputProps {
  classes?: ClassesType<typeof useStyles>
  clientId: string | Nil
  hide?: boolean
  initialBusinesses?: Business[]
  initialContacts?: Contact[]
  labelVariant?: TextProps['variant']
  patientId: string | Nil
  to?: Field
  transport: ConversationTransport | Nil
}

type ChipItem = {
  businessId?: string
  contactId?: string
  name: string
}

const ToInput = forwardRef<ToInputHandle, ToInputProps>(
  (
    {
      classes: classesProp,
      clientId,
      hide = false,
      initialBusinesses = [],
      initialContacts = [],
      labelVariant = 'body2',
      patientId,
      to,
      transport,
    },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp })
    const patient = useSelector(getPatient(patientId))
    const client = useSelector(getUser(clientId))
    const { t } = useTranslation('Common')

    const [selectedContacts, setSelectedContacts] = useState(initialContacts)
    // Will be used when PR-22 will be started for business select component
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedBusinesses, setSelectedBusinesses] =
      useState(initialBusinesses)

    const [openSelectContactDialog] = useDialog(DialogNames.SELECT_CONTACT)

    const boopTransport = transport === ConversationTransport.Boop
    const smsTransport = transport === ConversationTransport.Sms
    const logPhoneCallTransport =
      transport === ConversationTransport.LogPhoneCall

    const contacts = selectedContacts.map((contact) => ({
      contactId: contact.id,
      name: getContactToName(contact, transport),
    }))
    const businesses = selectedBusinesses.map((business) => ({
      businessId: business.id,
      name: getBusinessToName(business, transport),
    }))
    const clients = client ? [{ name: getClientToName(client, transport) }] : []

    const toChipsItems: ChipItem[] = [...clients, ...contacts, ...businesses]

    const handleSelectContact = (
      coparents: Coparent[],
      contactsProp: Contact[],
    ) => {
      const contactsToEntries = R.pluck(
        smsTransport ? 'phone' : 'email',
        contactsProp,
      )
      const coparentsToEntries = R.pluck(
        smsTransport ? 'mobilePhone' : 'email',
        coparents,
      )
      const currentToEntries = (to?.value || '').split(',')
      const allToEntries = R.uniq([
        ...currentToEntries,
        ...contactsToEntries,
        ...coparentsToEntries,
      ])
        .filter(Boolean)
        .map((toEntry) => toEntry.trim())
        .join(', ')

      to?.setValue(allToEntries)
      if (setSelectedContacts) {
        const coparentAsContact = coparents.map(
          ({ mobilePhone, ...coparent }): Contact => ({
            ...coparent,
            phone: mobilePhone ?? '',
            name: getClientToName(coparent, transport),
          }),
        )

        setSelectedContacts(
          R.uniq([...selectedContacts, ...contactsProp, ...coparentAsContact]),
        )
      }
    }

    const onContactRemove = (contactId: string) => {
      setSelectedContacts(R.reject(R.propEq('id', contactId), selectedContacts))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onBusinessRemove = (businessId: string) => {
      // TODO: Refactor with when PR-22 will be started
    }

    const handleChipDelete = (chipItem: ChipItem) => {
      if (chipItem.contactId) {
        onContactRemove(chipItem.contactId)
      } else if (chipItem.businessId) {
        onBusinessRemove(chipItem.businessId)
      }
    }

    useImperativeHandle(ref, () => ({
      formRecipients: () =>
        CommunicationsUtils.formRecipients(
          {
            client,
            patient,
            contacts: selectedContacts,
            businesses: selectedBusinesses,
          },
          to?.value,
          transport,
        ),
    }))

    // TODO: Refactor with add contact/business button when PR-22 will be started
    const addContactButton = (
      <InputAdornment position="end">
        <PlusButton
          classes={{
            iconContainer: classes.plusButtonIconContainer,
          }}
          onMouseDown={() =>
            openSelectContactDialog({
              clientId,
              onAdd: handleSelectContact,
              contactSelectType: mapTransportToContactSelectType(transport),
            })
          }
        />
      </InputAdornment>
    )

    if (hide || logPhoneCallTransport) {
      return null
    }

    return (
      <Grid item className={classes.container}>
        <Text strong mb={1} variant={labelVariant}>
          {t('Common:EMAIL_FORM.TO')}*:
        </Text>
        <Grid container direction="column">
          {smsTransport || boopTransport ? (
            <PuiInput field={to}>
              <>
                <Grid
                  container
                  alignItems="center"
                  className={classes.phonesContainer}
                  justifyContent="space-between"
                >
                  <Grid container item xs pt={0.5}>
                    {toChipsItems.map((chipItem) => (
                      <Chip
                        className={classes.chip}
                        deleteIcon={
                          <CancelIcon className={classes.cancelIcon} />
                        }
                        key={chipItem.contactId || chipItem.name}
                        label={chipItem.name}
                        size="small"
                        onDelete={() => handleChipDelete(chipItem)}
                      />
                    ))}
                  </Grid>
                  <Grid item>{smsTransport && addContactButton}</Grid>
                </Grid>
              </>
            </PuiInput>
          ) : (
            <PuiTextField
              InputProps={{
                inputProps: { className: classes.toTextInput },
                readOnly: smsTransport,
                endAdornment: addContactButton,
              }}
              aria-label={t('Common:EMAIL_FORM.TO')}
              field={to}
              margin="none"
              maxRows={1}
              minRows={1}
              variant="outlined"
            />
          )}
        </Grid>
      </Grid>
    )
  },
)

export default ToInput
