import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

import DetailsBackButton from '../DetailsBackButton'
import EmergencyContact from './EmergencyContact'

const EmergencyContactPage = () => {
  const { clientId = '' } = useParams()
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const { t } = useTranslation('Common')

  const onBackButtonClick = () => {
    goBack()
  }

  return (
    <>
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Common:BACK_TO_CLIENT_SUMMARY')}
      </DetailsBackButton>
      <Grid container item p={3}>
        <EmergencyContact showTitle clientId={clientId} />
      </Grid>
    </>
  )
}

export default EmergencyContactPage
