import * as R from 'ramda'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, Business, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import i18n from '~/locales/i18n'

import { fetchCurrentUser } from '../actions/auth'
import { updateBusinesses } from '../actions/businesses'
import { addBusinessToJournal, addUserToJournal } from '../actions/journal'
import { unlinkPatientsFromChewyAccount } from '../actions/patients'
import {
  ACCEPT_EULA,
  FETCH_BALANCE,
  UNLINK_USER_FROM_CHEWY_ACCOUNT,
  UPDATE_AVATAR,
  UPDATE_CURRENT_USER_ON_SERVER,
  UPDATE_CURRENT_USERS_BUSINESS_ID,
  UPDATE_USER_SIGNATURE,
  UPDATE_USERS,
  UPDATE_ZN_LAB_PROVIDER_ID,
} from '../actions/types/users'
import {
  acceptEulaFailure,
  acceptEulaSuccess,
  fetchBalance,
  fetchBalanceFailure,
  fetchBalanceSuccess,
  unlinkChewyAccount,
  unlinkChewyAccountFailure,
  unlinkChewyAccountSuccess,
  updateAvatar,
  updateCurrentUserOnServer,
  updateCurrentUserOnServerSuccess,
  updateUsers,
  updateUserSignature,
  updateUserSignatureFailure,
  updateUserSignatureSuccess,
  updateZnLabProviderId,
  updateZnLabProviderIdFailure,
  updateZnLabProviderIdSuccess,
  usersUpdateFailure,
} from '../actions/users'
import { registerSuccessAlert } from '../duck/uiAlerts'
// TODO API is not ready yet
import {
  getCurrentBusiness,
  getCurrentUser,
  getCurrentUserId,
} from '../reducers/auth'
import { getUser } from '../reducers/users'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* updateCurrentUserOnServerSaga({
  diff,
}: ReturnType<typeof updateCurrentUserOnServer>) {
  try {
    const { entities } = yield call(API.updateCurrentUser, diff)
    yield call(updateEntities, entities)
    yield put(updateCurrentUserOnServerSuccess())
  } catch (error) {
    yield put(usersUpdateFailure(error as ApiError))
  }
}

export function* updateUserSignatureSaga({
  signature,
  userId,
}: ReturnType<typeof updateUserSignature>) {
  try {
    const { signatureUrl } = yield* requestAPI(
      API.updateUserSignature,
      signature,
      userId,
    )
    yield put(updateUserSignatureSuccess(userId, signatureUrl))
  } catch (error) {
    yield put(updateUserSignatureFailure(error as ApiError))
  }
}

export function* updateUsersSaga({ users }: ReturnType<typeof updateUsers>) {
  try {
    const currentUserId: string = yield select(getCurrentUserId)
    if (users && !R.isNil(currentUserId)) {
      const updatedCurrentUser = users[currentUserId]
      const business: Business = yield select(getCurrentBusiness)
      if (updatedCurrentUser) {
        yield put(addUserToJournal(updatedCurrentUser))
      }
      if (business) {
        yield put(addBusinessToJournal(business))
      }
    }
  } catch (error) {
    // empty
  }
}

export function* updateCurrentUsersBusinessIdSaga() {
  try {
    yield put(fetchCurrentUser())

    const currentUser: User = yield select(getCurrentUser)
    const business: Business = yield select(getCurrentBusiness)

    if (currentUser) {
      yield put(addUserToJournal(currentUser))
      if (business) {
        yield put(addBusinessToJournal(business))
      }
    }
  } catch (error) {
    // empty
  }
}

export function* updateAvatarSaga({
  userId,
  blob,
}: ReturnType<typeof updateAvatar>) {
  try {
    const {
      entities: { users, businesses },
    } = yield* requestAPI(API.uploadAvatar, userId, blob)
    yield put(updateUsers(users))
    yield put(updateBusinesses(businesses))
  } catch (error) {
    // empty
  }
}

export function* acceptEulaSaga() {
  try {
    const userId: string = yield select(getCurrentUserId)
    yield* requestAPI(API.acceptEula, userId)
    const currentUser: User = yield select(getCurrentUser)
    yield put(
      updateUsers({
        [currentUser.id]: {
          ...currentUser,
          eulaAcceptanceDate: new Date().toISOString(),
        },
      }),
    )
    yield put(acceptEulaSuccess())
  } catch (error) {
    yield put(acceptEulaFailure(error as ApiError))
  }
}

function* watchUpdateCurrentUserOnServer() {
  yield takeLatest(UPDATE_CURRENT_USER_ON_SERVER, updateCurrentUserOnServerSaga)
}

function* watchUpdateUserSignature() {
  yield takeLatest(UPDATE_USER_SIGNATURE, updateUserSignatureSaga)
}

function* watchUsersUpdate() {
  yield takeEvery(UPDATE_USERS, updateUsersSaga)
}

function* watchUpdateCurrentBusinessId() {
  yield takeEvery(
    UPDATE_CURRENT_USERS_BUSINESS_ID,
    updateCurrentUsersBusinessIdSaga,
  )
}

export function* updateZnLabProviderIdSaga({
  memberId,
  providerId,
}: ReturnType<typeof updateZnLabProviderId>) {
  try {
    yield* requestAPI(API.updateZnLabProviderId, memberId, providerId)
    yield put(updateZnLabProviderIdSuccess(memberId, providerId))
  } catch (error) {
    yield put(updateZnLabProviderIdFailure(error as ApiError))
  }
}

export function* fetchBalanceSaga({
  clientId,
}: ReturnType<typeof fetchBalance>) {
  try {
    const balance = yield* requestAPI(API.fetchBalance, clientId)
    yield put(fetchBalanceSuccess(balance, clientId))
  } catch (error) {
    yield put(fetchBalanceFailure(error as ApiError))
  }
}

export function* unlinkChewyAccountSaga({
  clientId,
}: ReturnType<typeof unlinkChewyAccount>) {
  try {
    yield* requestAPI(API.unlinkChewyUserAndPets, clientId)
    yield put(
      registerSuccessAlert(
        i18n.t('Dialogs:LINK_CHEWY_ACCOUNT_DIALOG.CHEWY_ACCOUNT_DISCONNECTED'),
      ),
    )
    yield put(unlinkChewyAccountSuccess(clientId))
    const client: User = yield select(getUser(clientId))
    yield put(unlinkPatientsFromChewyAccount(client?.patients || []))
  } catch (error) {
    yield put(unlinkChewyAccountFailure(error as ApiError, clientId))
  }
}

function* watchUpdateAvatar() {
  yield takeLatest(UPDATE_AVATAR, updateAvatarSaga)
}

function* watchAcceptEula() {
  yield takeLatest(ACCEPT_EULA, acceptEulaSaga)
}

function* watchUpdateZnLabProviderId() {
  yield takeLeading(UPDATE_ZN_LAB_PROVIDER_ID, updateZnLabProviderIdSaga)
}

function* watchFetchBalance() {
  yield takeLeading(FETCH_BALANCE, fetchBalanceSaga)
}

function* watchUnlinkChewyAccountSaga() {
  yield takeLatest(UNLINK_USER_FROM_CHEWY_ACCOUNT, unlinkChewyAccountSaga)
}

export default function* usersSaga() {
  yield all([
    watchUpdateCurrentUserOnServer(),
    watchUpdateUserSignature(),
    watchUsersUpdate(),
    watchUpdateAvatar(),
    watchUpdateCurrentBusinessId(),
    watchAcceptEula(),
    watchUpdateZnLabProviderId(),
    watchFetchBalance(),
    watchUnlinkChewyAccountSaga(),
  ])
}
