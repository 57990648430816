import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text, TextProps } from '@pbt/pbt-ui-components'
import { Upload as UploadIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  () => ({
    icon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'UploadButton' },
)

export interface UploadButtonProps extends TextProps {
  classes?: ClassesType<typeof useStyles>
  label?: React.ReactNode
}

const UploadButton = ({
  label,
  classes: classesProp,
  ...rest
}: UploadButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Text link fontSize="1.4rem" {...rest}>
      <UploadIcon className={classes.icon} />
      {label}
    </Text>
  )
}

export default UploadButton
