import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import { LabTestLogFile } from '~/api/graphql/generated/types'
import InlineAttachment from '~/components/common/attachments/InlineAttachment'
import DialogNames from '~/constants/DialogNames'
import { print } from '~/store/duck/print'
import { IdentifiedAttachmentOrigin } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useDialog from '~/utils/useDialog'

export type LabTestLogFileMapped = LabTestLogFile & {
  groupId: string
}

export interface LabTestAttachmentListProps {
  clientId: string
  files: LabTestLogFileMapped[]
  onDelete: (groupId: string, fileId: string) => void
  patientId: string
}

const LabTestAttachmentList = ({
  files,
  clientId,
  patientId,
  onDelete,
}: LabTestAttachmentListProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const [openEmailAttachmentDialog] = useDialog(
    DialogNames.EMAIL_ATTACHMENT_DIALOG,
  )

  const handlePrint = (file: IdentifiedAttachmentOrigin) => {
    dispatch(print(file?.fileUrl))
  }

  const handleEmail = (file: IdentifiedAttachmentOrigin) => {
    openEmailAttachmentDialog({
      selectedDocuments: [file.id],
      scopedAttachments: [file],
      clientId,
      patientId,
    })
  }

  const handleDelete = (file: LabTestLogFileMapped) => {
    openDeleteAttachmentAlert({
      message: getDeleteConfirmMessage('attachment'),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteAttachmentAlert(),
      onOk: () => {
        onDelete(file.groupId, file.id)
        closeDeleteAttachmentAlert()
      },
    })
  }

  return (
    <Grid container item direction="column" px={2}>
      {files?.map((file) => (
        <InlineAttachment
          attachment={file}
          key={file.id}
          onDelete={() => handleDelete(file)}
          onEmail={() => handleEmail(file)}
          onPrint={() => handlePrint(file)}
        />
      ))}
    </Grid>
  )
}

export default LabTestAttachmentList
