import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import { useGetLabResultPreviewActions } from '~/store/hooks/labTestsDashboard'
import { LabOrder, LabTestDashboardOrder } from '~/types'

const useStyles = makeStyles(
  () => ({
    root: {},
    label: {},
  }),
  { name: 'LabResultPreviewButtons' },
)

export interface LabResultPreviewButtonsProps {
  classes?: ClassesType<typeof useStyles>
  clientId: string | Nil
  hidePreview?: boolean
  order: LabTestDashboardOrder | LabOrder | Nil
  patientId: string | Nil
  soapId: string | Nil
  vendorId: string | Nil
}

const LabResultPreviewButtons = ({
  classes: classesProp,
  order,
  vendorId,
  clientId,
  patientId,
  soapId,
  hidePreview,
}: LabResultPreviewButtonsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const getLabResultPreviewActions = useGetLabResultPreviewActions()

  const availableActions = getLabResultPreviewActions({
    order,
    vendorId,
    clientId,
    patientId,
    soapId,
  })

  if (!availableActions) {
    return null
  }

  const showSomething =
    (!hidePreview && availableActions.preview) ||
    availableActions.email ||
    availableActions.print

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      width="auto"
      wrap="nowrap"
    >
      {showSomething && (
        <Text className={classes.label}>{t('Common:RESULTS')}:</Text>
      )}
      <ControlButtonGroup
        buttons={[
          availableActions.print && {
            name: ControlButtonGroupName.PRINT,
            onClick: availableActions.print.handle,
          },
          availableActions.email && {
            name: ControlButtonGroupName.EMAIL,
            onClick: availableActions.email.handle,
          },
          !hidePreview &&
            availableActions.preview && {
              name: ControlButtonGroupName.PREVIEW,
              onClick: availableActions.preview.handle,
            },
        ]}
      />
    </Grid>
  )
}

export default LabResultPreviewButtons
