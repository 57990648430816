import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { TimetableEvent } from '~/types'

interface AppointmentTypeCellProps {
  item?: TimetableEvent
}

const AppointmentTypeCell = ({ item }: AppointmentTypeCellProps) => {
  const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    item?.businessAppointmentType,
  )

  return <Dotdotdot clamp={1}>{appointmentTypeDisplayName}</Dotdotdot>
}

export default AppointmentTypeCell
