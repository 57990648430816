import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import PatientSearchResult from '../orphans-assignment/PatientSearchResult'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 'calc(100% - 64px)',
      maxWidth: 'calc(100% - 64px)',
      verticalAlign: 'top',
    },
    dialogTitle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }),
  { name: 'ClientSearchDialog' },
)

export interface ClientSearchDialogProps extends BasePuiDialogProps {
  actionBtnTitle: string
  additionalParams: [string, string][] | Nil
  fieldsQuery: string | Nil
  onClick: (clientId: string, patientId: string | Nil) => void
  resultsTitle: string | Nil
  searchTerm: string
}

const ClientSearchDialog = ({
  actionBtnTitle,
  open,
  fieldsQuery,
  additionalParams,
  searchTerm,
  onClose,
  onClick,
  resultsTitle,
}: ClientSearchDialogProps) => {
  const classes = useStyles()

  const searchResultsActionButtons = [
    {
      disabled: false,
      title: actionBtnTitle,
      onClick: R.identity,
    },
  ]

  const handleOnItemClick = (clientId: string, patientId: string | Nil) => {
    if (clientId && patientId) {
      onClick(clientId, patientId)
      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <PuiDialog
      aria-labelledby="clients-search-dialog"
      classes={{
        dialogTitle: classes.dialogTitle,
        paper: classes.paper,
      }}
      open={open}
      title={resultsTitle}
      onClose={onClose}
    >
      <PatientSearchResult
        actionButtons={searchResultsActionButtons}
        additionalParams={additionalParams}
        fieldsQuery={fieldsQuery}
        hideFlags={false}
        searchTerm={searchTerm}
        onItemClick={handleOnItemClick}
        onSearchCancelled={onClose}
      />
    </PuiDialog>
  )
}

export default ClientSearchDialog
