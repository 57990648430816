import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import * as VaccinationAPI from '~/api'
import FeatureToggle from '~/constants/featureToggle'
import {
  PatientFile,
  RabiesVaccination,
  Vaccinations,
  VaccinationSignature,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import { fetchSoapFiles } from '../actions/soap'
import type { RootState } from '../index'
import { getFeatureToggle } from '../reducers/constants'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_VACCINATIONS = 'vaccination/FETCH_VACCINATIONS'
export const FETCH_VACCINATIONS_SUCCESS =
  'vaccination/FETCH_VACCINATIONS_SUCCESS'
export const FETCH_VACCINATIONS_FAILURE =
  'vaccination/FETCH_VACCINATIONS_FAILURE'

export const FETCH_RABIES_VACCINATIONS = 'vaccination/FETCH_RABIES_VACCINATIONS'
export const FETCH_RABIES_VACCINATIONS_SUCCESS =
  'vaccination/FETCH_RABIES_VACCINATIONS_SUCCESS'
export const FETCH_RABIES_VACCINATIONS_FAILURE =
  'vaccination/FETCH_RABIES_VACCINATIONS_FAILURE'

export const GENERATE_PROOF_OF_VACCINATIONS =
  'vaccination/GENERATE_PROOF_OF_VACCINATIONS'
export const GENERATE_PROOF_OF_VACCINATIONS_SUCCESS =
  'vaccination/GENERATE_PROOF_OF_VACCINATIONS_SUCCESS'
export const GENERATE_PROOF_OF_VACCINATIONS_FAILURE =
  'vaccination/GENERATE_PROOF_OF_VACCINATIONS_FAILURE'

export const GENERATE_RABIES_CERTIFICATE =
  'vaccination/GENERATE_RABIES_CERTIFICATE'
export const GENERATE_RABIES_CERTIFICATE_SUCCESS =
  'vaccination/GENERATE_RABIES_CERTIFICATE_SUCCESS'
export const GENERATE_RABIES_CERTIFICATE_FAILURE =
  'vaccination/GENERATE_RABIES_CERTIFICATE_FAILURE'

export const UPDATE_PATIENT_FILES = 'vaccination/UPDATE_PATIENT_FILES'
export const SET_GENERATED_ATTACHMENT_ID =
  'vaccination/SET_GENERATED_ATTACHMENT_ID'

export const fetchVaccinations = (patientId: string, soapId?: string) => ({
  type: FETCH_VACCINATIONS,
  patientId,
  soapId,
})
export const fetchVaccinationsSuccess = (
  patientId: string,
  vaccinations: Vaccinations,
  soapId?: string,
) => ({
  type: FETCH_VACCINATIONS_SUCCESS,
  patientId,
  soapId,
  vaccinations,
})
export const fetchVaccinationsFailure = (error: ApiError) => ({
  type: FETCH_VACCINATIONS_FAILURE,
  error,
})

export const fetchRabiesVaccinations = (
  patientId: string,
  soapId?: string,
) => ({
  type: FETCH_RABIES_VACCINATIONS,
  patientId,
  soapId,
})
export const fetchRabiesVaccinationsSuccess = (
  patientId: string,
  vaccinations: RabiesVaccination[],
  soapId?: string,
) => ({
  type: FETCH_RABIES_VACCINATIONS_SUCCESS,
  patientId,
  soapId,
  vaccinations,
})
export const fetchRabiesVaccinationsFailure = (error: ApiError) => ({
  type: FETCH_RABIES_VACCINATIONS_FAILURE,
  error,
})

export const generateProofOfVaccinations = (
  patientId: string,
  vaccinationIds: string[],
  signature: VaccinationSignature,
  soapId: string | Nil,
  soapBusinessId: string | Nil,
) => ({
  type: GENERATE_PROOF_OF_VACCINATIONS,
  patientId,
  vaccinationIds,
  signature,
  soapId,
  soapBusinessId,
})
export const generateProofOfVaccinationsSuccess = () => ({
  type: GENERATE_PROOF_OF_VACCINATIONS_SUCCESS,
})
export const generateProofOfVaccinationsFailure = (error: ApiError) => ({
  type: GENERATE_PROOF_OF_VACCINATIONS_FAILURE,
  error,
})

export const generateRabiesCertificate = (
  patientId: string,
  vaccinationIds: string[],
  soapId: string | Nil,
  soapBusinessId: string | Nil,
) => ({
  type: GENERATE_RABIES_CERTIFICATE,
  patientId,
  vaccinationIds,
  soapId,
  soapBusinessId,
})
export const generateRabiesCertificateSuccess = () => ({
  type: GENERATE_RABIES_CERTIFICATE_SUCCESS,
})
export const generateRabiesCertificateFailure = (error: ApiError) => ({
  type: GENERATE_RABIES_CERTIFICATE_FAILURE,
  error,
})

export const setGeneratedAttachmentId = (attachmentId: string | null) => ({
  type: SET_GENERATED_ATTACHMENT_ID,
  attachmentId,
})
export const clearGeneratedAttachmentId = () => setGeneratedAttachmentId(null)
export const updatePatientFiles = (
  files: PatientFile[],
  patientId: string,
) => ({
  type: UPDATE_PATIENT_FILES,
  files,
  patientId,
})

export type VaccinationState = {
  error: string | null
  filesMap: Record<string, PatientFile[]>
  isLoading: boolean
  lastGeneratedAttachmentId: string | null
  rabiesVaccinationsMap: Record<string, RabiesVaccination[]>
  vaccinationsMap: Record<string, Vaccinations>
}

const INITIAL_STATE = {
  vaccinationsMap: {},
  rabiesVaccinationsMap: {},
  lastGeneratedAttachmentId: null,
  filesMap: {},
  error: null,
  isLoading: false,
}

export const vaccinationReducer = (
  state: VaccinationState = INITIAL_STATE,
  action: AnyAction,
): VaccinationState => {
  switch (action.type) {
    case SET_GENERATED_ATTACHMENT_ID:
      return {
        ...state,
        lastGeneratedAttachmentId: action.attachmentId,
      }
    case FETCH_VACCINATIONS:
      return {
        ...state,
        vaccinationsMap: {},
        error: null,
        isLoading: true,
      }
    case FETCH_VACCINATIONS_SUCCESS:
      return {
        ...state,
        vaccinationsMap: {
          ...state.vaccinationsMap,
          [action.patientId]: action.vaccinations,
        },
        error: null,
        isLoading: false,
      }
    case FETCH_VACCINATIONS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_RABIES_VACCINATIONS:
      return {
        ...state,
        rabiesVaccinationsMap: {},
        error: null,
        isLoading: true,
      }
    case FETCH_RABIES_VACCINATIONS_SUCCESS:
      return {
        ...state,
        rabiesVaccinationsMap: {
          ...state.rabiesVaccinationsMap,
          [action.patientId]: action.vaccinations,
        },
        error: null,
        isLoading: false,
      }
    case FETCH_RABIES_VACCINATIONS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case GENERATE_PROOF_OF_VACCINATIONS:
      return {
        ...state,
        lastGeneratedAttachmentId: null,
        isLoading: true,
      }
    case GENERATE_PROOF_OF_VACCINATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case GENERATE_PROOF_OF_VACCINATIONS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case UPDATE_PATIENT_FILES:
      return {
        ...state,
        filesMap: {
          ...state.filesMap,
          [action.patientId]: action.files,
        },
      }
    default:
      return state
  }
}

export const getVaccination = (state: RootState): VaccinationState =>
  state.vaccination
export const getVaccinationIsLoading = (state: RootState) =>
  getVaccination(state).isLoading
export const getVaccinationError = (state: RootState) =>
  getVaccination(state).error
export const getLastGeneratedAttachmentId = (state: RootState) =>
  getVaccination(state).lastGeneratedAttachmentId
export const getVaccinationsMap = (state: RootState) =>
  getVaccination(state).vaccinationsMap
export const getRabiesVaccinationsMap = (state: RootState) =>
  getVaccination(state).rabiesVaccinationsMap
export const getPatientFilesMap = (state: RootState) =>
  getVaccination(state).filesMap
export const getVaccinations = (patientId: string | Nil) =>
  createSelector(getVaccinationsMap, (map) =>
    patientId ? map[patientId] : undefined,
  )
export const getRabiesVaccinations = (patientId: string | Nil) =>
  createSelector(getRabiesVaccinationsMap, (map) =>
    patientId ? map[patientId] || [] : [],
  )
export const getPatientFiles = (patientId: string) =>
  createSelector(getPatientFilesMap, (map) => R.prop(patientId, map) || [])

export function* fetchVaccinationsSaga({
  patientId,
  soapId,
}: ReturnType<typeof fetchVaccinations>) {
  try {
    const vaccinations = yield* requestAPI(
      VaccinationAPI.fetchVaccinations,
      patientId,
      soapId,
    )
    yield put(fetchVaccinationsSuccess(patientId, vaccinations, soapId))
  } catch (error) {
    yield put(fetchVaccinationsFailure(error as ApiError))
  }
}

export function* fetchRabiesVaccinationsSaga({
  patientId,
  soapId,
}: ReturnType<typeof fetchRabiesVaccinations>) {
  try {
    const rabiesVaccinations = yield* requestAPI(
      VaccinationAPI.fetchRabiesVaccinations,
      patientId,
      soapId,
    )
    yield put(
      fetchRabiesVaccinationsSuccess(patientId, rabiesVaccinations, soapId),
    )
  } catch (error) {
    yield put(fetchRabiesVaccinationsFailure(error as ApiError))
  }
}

export function* generateProofOfVaccinationsSaga({
  patientId,
  vaccinationIds,
  signature,
  soapId,
  soapBusinessId,
}: ReturnType<typeof generateProofOfVaccinations>) {
  try {
    const isPatientSharingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )
    const response = yield* requestAPI(
      isPatientSharingEnabled
        ? VaccinationAPI.generateProofOfVaccinationsByScope
        : VaccinationAPI.generateProofOfVaccinations,
      patientId,
      vaccinationIds,
      signature,
      soapId,
    )
    const { soapFileId } = response || {}
    yield put(generateProofOfVaccinationsSuccess())
    yield put(setGeneratedAttachmentId(soapFileId))
    if (soapId) {
      yield put(fetchSoapFiles(soapId, soapBusinessId))
    } else {
      const patientFiles = yield* requestAPI(
        VaccinationAPI.getPatientVaccinations,
        patientId,
      )
      yield put(updatePatientFiles(patientFiles, patientId))
    }
  } catch (error) {
    yield put(generateProofOfVaccinationsFailure(error as ApiError))
  }
}

export function* generateRabiesCertificateSaga({
  soapId,
  soapBusinessId,
  patientId,
  vaccinationIds,
}: ReturnType<typeof generateRabiesCertificate>) {
  try {
    const isPatientSharingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )
    const response = yield* requestAPI(
      isPatientSharingEnabled
        ? VaccinationAPI.generateRabiesCertificateByScope
        : VaccinationAPI.generateRabiesCertificate,
      patientId,
      vaccinationIds,
      soapId,
    )
    const { soapFileId } = response || {}
    yield put(generateRabiesCertificateSuccess())
    yield put(setGeneratedAttachmentId(soapFileId))
    if (soapId) {
      yield put(fetchSoapFiles(soapId, soapBusinessId))
    } else {
      const patientFiles = yield* requestAPI(
        VaccinationAPI.getPatientVaccinations,
        patientId,
      )
      yield put(updatePatientFiles(patientFiles, patientId))
    }
  } catch (error) {
    yield put(generateRabiesCertificateFailure(error as ApiError))
  }
}

function* watchFetchVaccinations() {
  yield takeLatest(FETCH_VACCINATIONS, fetchVaccinationsSaga)
}

function* watchFetchRabiesVaccinations() {
  yield takeLatest(FETCH_RABIES_VACCINATIONS, fetchRabiesVaccinationsSaga)
}

function* watchGenerateProofOfVaccinations() {
  yield takeLatest(
    GENERATE_PROOF_OF_VACCINATIONS,
    generateProofOfVaccinationsSaga,
  )
}

function* watchGenerateRabiesCertificateSaga() {
  yield takeLatest(GENERATE_RABIES_CERTIFICATE, generateRabiesCertificateSaga)
}

export function* vaccinationSaga() {
  yield all([
    watchFetchVaccinations(),
    watchFetchRabiesVaccinations(),
    watchGenerateProofOfVaccinations(),
    watchGenerateRabiesCertificateSaga(),
  ])
}
