/* eslint-disable max-lines */
import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import type { RootState } from '~/store'
import {
  FetchOrdersOptions,
  Order,
  OrderDetails,
  OrderFilter,
  OrderSearchResult,
  Prescription,
} from '~/types'
import { mergeArraysAtIndex, removeByIdList, updateAllById } from '~/utils'
import {
  detectAPIErrorType,
  getErrorMessage,
  getServerValidationError,
} from '~/utils/errors'

import {
  EMAIL_PRESCRIPTION,
  EMAIL_PRESCRIPTION_FAILURE,
  EMAIL_PRESCRIPTION_SUCCESS,
  GENERATE_PDF_FOR_PRESCRIPTION,
  GENERATE_PDF_FOR_PRESCRIPTION_FAILURE,
  GENERATE_PDF_FOR_PRESCRIPTION_SUCCESS,
} from '../actions/types/communications'
import {
  APPLY_PATIENT_RESTRICTIONS,
  CANCEL_REFILLS_FAILURE,
  CLEAR_CURRENT_ORDER_DETAILS,
  CLEAR_CURRENT_UNIFIED_ORDER,
  CLEAR_ITEM_EXIST_ERROR,
  CLEAR_ITEM_STATUS_ERROR,
  CLEAR_ORDER_CAN_NOT_BE_REMOVED_MESSAGE,
  CLEAR_ORDER_VALIDATION_ERROR_MESSAGE,
  CLEAR_ORDERS,
  CLEAR_PRESCRIPTION_EMAIL_TEMPLATE,
  CLEAR_PRESCRIPTION_FOR_EMAIL,
  CLEAR_PRESCRIPTION_FOR_PRINT,
  CLEAR_REMOUNT_WEBSOCKET_CHARGES_WIDGET,
  CLEAR_SEARCH_ORDERS,
  CREATE_ORDER,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  CREATE_UNIFIED_ORDER,
  CREATE_UNIFIED_ORDER_FAILURE,
  CREATE_UNIFIED_ORDER_SUCCESS,
  DECLINE_LAB_ORDER,
  DECLINE_LAB_ORDER_ABOLISH,
  DECLINE_LAB_ORDER_FAILURE,
  DECLINE_LAB_ORDER_SUCCESS,
  EDIT_ORDER,
  EDIT_ORDER_ABOLISH,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDERS,
  EDIT_ORDERS_FAILURE,
  EDIT_ORDERS_STATUSES,
  EDIT_ORDERS_STATUSES_FAILURE,
  EDIT_ORDERS_STATUSES_SUCCESS,
  EDIT_ORDERS_SUCCESS,
  FETCH_MORE_FOR_SEARCH,
  FETCH_MORE_FOR_SEARCH_FAILURE,
  FETCH_MORE_FOR_SEARCH_SUCCESS,
  FETCH_MORE_ORDERS,
  FETCH_MORE_ORDERS_FAILURE,
  FETCH_MORE_ORDERS_SUCCESS,
  FETCH_ORDER_COUNTS_BY_TYPE,
  FETCH_ORDER_COUNTS_BY_TYPE_FAILURE,
  FETCH_ORDER_COUNTS_BY_TYPE_SUCCESS,
  FETCH_ORDER_DETAILS,
  FETCH_ORDER_DETAILS_FAILURE,
  FETCH_ORDER_DETAILS_GROUPED,
  FETCH_ORDER_DETAILS_GROUPED_FAILURE,
  FETCH_ORDER_DETAILS_GROUPED_SUCCESS,
  FETCH_ORDER_DETAILS_SUCCESS,
  FETCH_ORDERS,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_FILTERS,
  FETCH_ORDERS_FILTERS_FAILURE,
  FETCH_ORDERS_FILTERS_SUCCESS,
  FETCH_ORDERS_SUCCESS,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE_FAILURE,
  FETCH_PRESCRIPTION_EMAIL_TEMPLATE_SUCCESS,
  FETCH_PRESCRIPTION_PRINT_INFO,
  FETCH_PRESCRIPTION_PRINT_INFO_FAILURE,
  FETCH_UNIFIED_ORDER,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_FAILURE,
  FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_SUCCESS,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_FAILURE,
  FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_SUCCESS,
  FETCH_UNIFIED_ORDER_FAILURE,
  FETCH_UNIFIED_ORDER_SUCCESS,
  ORDER_BUNDLE,
  ORDER_BUNDLE_FAILURE,
  ORDER_BUNDLE_SUCCESS,
  PARTIAL_EDIT_ORDER,
  PARTIAL_EDIT_ORDER_ABOLISH,
  PARTIAL_EDIT_ORDER_FAILURE,
  PARTIAL_EDIT_ORDER_SUCCESS,
  REMOUNT_WEBSOCKET_CHARGES_WIDGET,
  REMOVE_LAB_ORDER,
  REMOVE_LAB_ORDER_ABOLISH,
  REMOVE_MULTIPLE_ORDERS,
  REMOVE_ORDER,
  REMOVE_ORDER_FAILURE,
  REMOVE_ORDER_SUCCESS,
  REMOVE_ORDER_UPDATE_PATIENT,
  REMOVE_ORDER_UPDATE_PATIENT_FAILURE,
  REMOVE_ORDER_UPDATE_PATIENT_SUCCESS,
  REMOVE_ORDERS,
  REMOVE_ORDERS_FAILURE,
  REMOVE_ORDERS_SUCCESS,
  REMOVE_UNIFIED_ORDER,
  REMOVE_UNIFIED_ORDER_FAILURE,
  REMOVE_UNIFIED_ORDER_SUCCESS,
  SEARCH_ORDERS,
  SEARCH_ORDERS_FAILURE,
  SEARCH_ORDERS_SUCCESS,
  SET_ORDER_CAN_NOT_BE_REMOVED_MESSAGE,
  SET_ORDER_VALIDATION_ERROR_MESSAGE,
  SET_PRESCRIPTION_FOR_EMAIL,
  SET_PRESCRIPTION_FOR_PRINT,
  SIGN_PRESCRIPTION,
  SIGN_PRESCRIPTION_FAILURE,
  SIGN_PRESCRIPTION_SUCCESS,
  SUBTRACT_REFILL_REMAINS,
  UNORDER_BUNDLE,
  UNORDER_BUNDLE_FAILURE,
  UNORDER_BUNDLE_SUCCESS,
  UNORDER_BUNDLE_WITHOUT_ORDER,
  UNORDER_BUNDLE_WITHOUT_ORDER_FAILURE,
  UNORDER_BUNDLE_WITHOUT_ORDER_SUCCESS,
  UPDATE_CURRENT_ORDER_DETAILS,
  UPDATE_FULL_LOG,
  UPDATE_LOG_FAILURE,
  UPDATE_LOG_SUCCESS,
  UPDATE_MULTIPLE_ORDERS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_UNIFIED_ORDER,
  UPDATE_UNIFIED_ORDER_FAILURE,
  UPDATE_UNIFIED_ORDER_SUCCESS,
} from '../actions/types/orders'

export type OrdersState = {
  appliedPatientRestrictions: boolean
  createdOrder: Order | null
  currentOrderDetails: Prescription | null
  currentOrderDetailsGrouped: OrderDetails[]
  error: string | null
  errorType: string | Nil
  isCreating: boolean
  isDetailsReceiving: boolean
  isEmail: boolean
  isFetchingOrdersCountByType: boolean
  isGeneratingPrescriptionPdf: boolean
  isGroupedDetailsReceiving: boolean
  isLoading: boolean
  isPrescriptionEmailSending: boolean
  isPrescriptionEmailTemplateReceiving: boolean
  isPrint: boolean
  isReceiving: boolean
  isSending: boolean
  itemExistError: string | null
  itemStatusError: string | null
  lastRequestedOrderFilters: FetchOrdersOptions | {}
  lastSearchOrderFilters: FetchOrdersOptions | {}
  orderCanNotBeRemovedMessage: string
  orderIsSigning: boolean
  orders: Order[]
  ordersFilters: OrderFilter[]
  pendingSelectionOrders: Order[]
  pendingUnSelectionOrders: Order[]
  prescriptionEmailTemplate: string | undefined
  prescriptionForEmail: Prescription | undefined
  prescriptionForPrint: Prescription | undefined
  remount: boolean
  searchResults: OrderSearchResult[]
  searchResultsTotalCount: number | undefined
  selectedOrders: Order[]
  totalCount: number
  totalCountByType: Record<OrderType, number> | null
  unifiedOrder: Order | undefined
  unifiedOrderIsLoading: boolean
  unifiedOrderLoadingId: string | undefined
  validationErrorMessage: string
}

export const ORDERS_INITIAL_STATE: OrdersState = {
  appliedPatientRestrictions: true,
  orders: [],
  totalCount: 0,
  totalCountByType: null,
  selectedOrders: [],
  createdOrder: null,
  pendingSelectionOrders: [],
  pendingUnSelectionOrders: [],
  currentOrderDetails: null,
  currentOrderDetailsGrouped: [],
  error: null,
  errorType: null,
  isLoading: false,
  isSending: false,
  isReceiving: false,
  ordersFilters: [],
  isDetailsReceiving: false,
  isFetchingOrdersCountByType: false,
  isGroupedDetailsReceiving: false,
  prescriptionForPrint: undefined,
  prescriptionForEmail: undefined,
  searchResults: [],
  searchResultsTotalCount: undefined,
  isPrescriptionEmailTemplateReceiving: false,
  isPrescriptionEmailSending: false,
  isGeneratingPrescriptionPdf: false,
  prescriptionEmailTemplate: undefined,
  validationErrorMessage: '',
  orderCanNotBeRemovedMessage: '',
  unifiedOrder: undefined,
  unifiedOrderLoadingId: undefined,
  unifiedOrderIsLoading: false,
  isPrint: false,
  isEmail: false,
  lastRequestedOrderFilters: {},
  lastSearchOrderFilters: {},
  isCreating: false,
  orderIsSigning: false,
  itemExistError: null,
  itemStatusError: null,
  remount: false,
}

const orders = (
  state: OrdersState = ORDERS_INITIAL_STATE,
  action: AnyAction,
): OrdersState => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        orders: [],
        totalCount: 0,
        isReceiving: true,
      }
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        lastRequestedOrderFilters: action.filters,
        orders: action.orders,
        totalCount: action.totalCount,
        isReceiving: false,
      }
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReceiving: false,
      }
    case FETCH_MORE_ORDERS:
      return { ...state, isReceiving: true }
    case FETCH_MORE_ORDERS_SUCCESS:
      return {
        ...state,
        lastRequestedOrderFilters: action.filters,
        orders: mergeArraysAtIndex(state.orders, action.orders, action.from),
        isReceiving: false,
      }
    case FETCH_MORE_ORDERS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReceiving: false,
      }
    case UPDATE_FULL_LOG:
      return { ...state, selectedOrders: action.log }
    case UPDATE_LOG_SUCCESS:
      return { ...state, isSending: false }
    case UPDATE_LOG_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isSending: false,
      }
    case CLEAR_ORDERS:
      return { ...state, orders: [], lastRequestedOrderFilters: {} }
    case UPDATE_CURRENT_ORDER_DETAILS:
      return {
        ...state,
        currentOrderDetails: {
          ...state.currentOrderDetails,
          ...action.currentOrder,
        },
        isDetailsReceiving: false,
      }
    case CLEAR_CURRENT_ORDER_DETAILS:
      return { ...state, currentOrderDetails: null, createdOrder: null }
    case FETCH_ORDER_DETAILS:
      return { ...state, currentOrderDetails: null, isDetailsReceiving: true }
    case FETCH_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        currentOrderDetails: action.orderDetails,
        isDetailsReceiving: false,
      }
    case FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isDetailsReceiving: false,
      }
    case FETCH_ORDER_DETAILS_GROUPED:
      return {
        ...state,
        currentOrderDetailsGrouped: [],
        isGroupedDetailsReceiving: true,
      }
    case FETCH_ORDER_DETAILS_GROUPED_SUCCESS:
      return {
        ...state,
        currentOrderDetailsGrouped: action.orderDetails,
        isGroupedDetailsReceiving: false,
      }
    case FETCH_ORDER_DETAILS_GROUPED_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isGroupedDetailsReceiving: false,
      }
    case FETCH_ORDERS_FILTERS:
      return { ...state, isReceiving: true }
    case FETCH_ORDERS_FILTERS_SUCCESS:
      return {
        ...state,
        isReceiving: false,
        ordersFilters: action.ordersFilters,
      }
    case FETCH_ORDERS_FILTERS_FAILURE:
      return {
        ...state,
        isReceiving: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_ORDER:
    case CREATE_UNIFIED_ORDER:
      return {
        ...state,
        isSending: true,
        isCreating: true,
        isPrint: Boolean(action.options?.print),
        isEmail: Boolean(action.options?.email),
        pendingSelectionOrders: state.pendingSelectionOrders.concat(
          action.order,
        ),
        pendingUnSelectionOrders: R.without(
          [action.order],
          state.pendingUnSelectionOrders,
        ),
        errorType: null,
        error: null,
      }
    case ORDER_BUNDLE:
      const items = action.order?.items || []
      return {
        ...state,
        isSending: true,
        pendingSelectionOrders: state.pendingSelectionOrders.concat(...items),
        pendingUnSelectionOrders: R.without(
          items,
          state.pendingUnSelectionOrders,
        ),
      }
    case CREATE_ORDER_SUCCESS:
    case CREATE_UNIFIED_ORDER_SUCCESS:
      const creatdOrderIndex = state.selectedOrders.findIndex(
        R.propEq('id', action.createdOrder.id),
      )
      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        isCreating: false,
        selectedOrders:
          creatdOrderIndex >= 0
            ? R.update(
                creatdOrderIndex,
                action.createdOrder,
                state.selectedOrders,
              )
            : state.selectedOrders.concat(action.createdOrder),
        pendingSelectionOrders: R.without(
          [action.order],
          state.pendingSelectionOrders,
        ),
        createdOrder: action.createdOrder,
      }
    case ORDER_BUNDLE_SUCCESS:
      return {
        ...state,
        isSending: false,
        pendingSelectionOrders: R.without(
          action.order?.items || [],
          state.pendingSelectionOrders,
        ),
      }
    case CREATE_ORDER_FAILURE:
    case CREATE_UNIFIED_ORDER_FAILURE:
      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        isCreating: false,
        error: getErrorMessage(action.error),
        errorType: detectAPIErrorType(action.error.responseBody),
        pendingSelectionOrders: R.without(
          [action.order],
          state.pendingSelectionOrders,
        ),
      }
    case ORDER_BUNDLE_FAILURE:
      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        error: getErrorMessage(action.error),
        pendingSelectionOrders: R.without(
          [action.order],
          state.pendingSelectionOrders,
        ),
      }
    case EDIT_ORDERS:
      return {
        ...state,
        isSending: true,
        selectedOrders: Utils.updateByIds(action.orders, state.selectedOrders),
      }
    case EDIT_ORDERS_STATUSES:
      return {
        ...state,
        isSending: true,
        selectedOrders: Utils.partialUpdateByIds(
          action.orders,
          state.selectedOrders,
        ),
      }
    case PARTIAL_EDIT_ORDER:
      const newOrder = R.mergeDeepRight(
        Utils.findById(action.order.id, state.selectedOrders),
        action.order,
      )

      return {
        ...state,
        isSending: true,
        isPrint: Boolean(action.options?.print),
        isEmail: Boolean(action.options?.email),
        selectedOrders: Utils.updateById(newOrder, state.selectedOrders),
      }
    case EDIT_ORDER:
    case UPDATE_UNIFIED_ORDER:
    case DECLINE_LAB_ORDER:
      return {
        ...state,
        isSending: true,
        isPrint: Boolean(action.options?.print),
        isEmail: Boolean(action.options?.email),
        selectedOrders: Utils.updateById(action.order, state.selectedOrders),
      }
    case EDIT_ORDERS_SUCCESS:
      return {
        ...state,
        isSending: false,
        selectedOrders: Utils.updateByIds(action.orders, state.selectedOrders),
      }
    case EDIT_ORDERS_STATUSES_SUCCESS:
      return {
        ...state,
        isSending: false,
        selectedOrders: Utils.partialUpdateByIds(
          action.orders,
          state.selectedOrders,
        ),
      }
    case PARTIAL_EDIT_ORDER_SUCCESS:
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        selectedOrders: Utils.updateById(action.order, state.selectedOrders),
      }
    case UPDATE_UNIFIED_ORDER_SUCCESS: {
      const index = state.selectedOrders.findIndex(
        R.propEq('id', action.order.id),
      )

      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        createdOrder: action.updatedOrder,
        selectedOrders:
          action.order.id !== action.updatedOrder.id
            ? R.update(index, action.updatedOrder, state.selectedOrders)
            : Utils.updateById(action.updatedOrder, state.selectedOrders),
      }
    }
    case EDIT_ORDERS_FAILURE:
    case EDIT_ORDERS_STATUSES_FAILURE:
    case PARTIAL_EDIT_ORDER_FAILURE:
    case EDIT_ORDER_FAILURE:
    case UPDATE_UNIFIED_ORDER_FAILURE:
    case DECLINE_LAB_ORDER_FAILURE:
      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        error: R.includes(action?.error?.status, [409, 410])
          ? null
          : getErrorMessage(action.error),
        itemExistError:
          action.error?.status === 410
            ? getServerValidationError(action.error)
            : null,
        itemStatusError:
          action.error?.status === 409
            ? getServerValidationError(action.error)
            : null,
      }
    case EDIT_ORDER_ABOLISH:
    case PARTIAL_EDIT_ORDER_ABOLISH:
    case DECLINE_LAB_ORDER_ABOLISH:
    case REMOVE_LAB_ORDER_ABOLISH:
    case DECLINE_LAB_ORDER_SUCCESS:
      return {
        ...state,
        isSending: false,
        isPrint: false,
        isEmail: false,
        error: null,
        itemExistError: null,
        itemStatusError: null,
      }
    case UPDATE_MULTIPLE_ORDERS:
      return {
        ...state,
        selectedOrders: updateAllById(state.selectedOrders, action.orders),
      }
    case UPDATE_ORDER_STATUS:
      return { ...state, isSending: true }
    case UPDATE_ORDER_STATUS_SUCCESS:
      return { ...state, isSending: false }
    case UPDATE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case REMOVE_ORDERS:
      return {
        ...state,
        isSending: true,
        pendingUnSelectionOrders: state.pendingUnSelectionOrders.concat(
          action.orders,
        ),
        pendingSelectionOrders: R.without(
          action.orders,
          state.pendingSelectionOrders,
        ),
      }
    case REMOVE_ORDER:
    case REMOVE_LAB_ORDER:
    case REMOVE_ORDER_UPDATE_PATIENT:
    case REMOVE_UNIFIED_ORDER:
    case UNORDER_BUNDLE:
      return {
        ...state,
        isSending: true,
        pendingUnSelectionOrders: state.pendingUnSelectionOrders.concat(
          action.order,
        ),
        pendingSelectionOrders: R.without(
          [action.order],
          state.pendingSelectionOrders,
        ),
      }
    case UNORDER_BUNDLE_WITHOUT_ORDER:
      return {
        ...state,
        isSending: true,
      }
    case REMOVE_ORDERS_SUCCESS:
      const orderIds = R.pluck('id', action.orders as Order[])
      return {
        ...state,
        isSending: false,
        selectedOrders: Utils.removeByIds(orderIds, state.selectedOrders),
        pendingUnSelectionOrders: Utils.removeByIds(
          orderIds,
          state.pendingUnSelectionOrders,
        ),
      }
    case REMOVE_ORDER_SUCCESS:
    case REMOVE_ORDER_UPDATE_PATIENT_SUCCESS:
    case REMOVE_UNIFIED_ORDER_SUCCESS:
      return {
        ...state,
        isSending: false,
        selectedOrders: Utils.removeById(action.orderId, state.selectedOrders),
        pendingUnSelectionOrders: Utils.removeById(
          action.orderId,
          state.pendingUnSelectionOrders,
        ),
      }
    case UNORDER_BUNDLE_SUCCESS:
      return {
        ...state,
        isSending: false,
        selectedOrders: removeByIdList(
          action.removedLogItems || [],
          state.selectedOrders,
        ),
        pendingUnSelectionOrders: R.without(
          [action.order],
          state.pendingUnSelectionOrders,
        ),
      }
    case UNORDER_BUNDLE_WITHOUT_ORDER_SUCCESS:
      return {
        ...state,
        isSending: false,
        selectedOrders: removeByIdList(
          action.removedLogItems || [],
          state.selectedOrders,
        ),
      }
    case REMOVE_ORDERS_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
        pendingUnSelectionOrders: Utils.removeByIds(
          R.pluck('id', action.orders as Order[]),
          state.pendingUnSelectionOrders,
        ),
      }
    case REMOVE_ORDER_FAILURE:
    case REMOVE_ORDER_UPDATE_PATIENT_FAILURE:
    case REMOVE_UNIFIED_ORDER_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
        pendingUnSelectionOrders: Utils.removeById(
          action.order.id,
          state.pendingUnSelectionOrders,
        ),
      }
    case UNORDER_BUNDLE_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
        pendingUnSelectionOrders: R.without(
          [action.order],
          state.pendingUnSelectionOrders,
        ),
      }
    case UNORDER_BUNDLE_WITHOUT_ORDER_FAILURE:
      return {
        ...state,
        isSending: false,
        error: getErrorMessage(action.error),
      }
    case SET_ORDER_CAN_NOT_BE_REMOVED_MESSAGE:
      return {
        ...state,
        orderCanNotBeRemovedMessage: action.errorMessage || '',
      }
    case CLEAR_ORDER_CAN_NOT_BE_REMOVED_MESSAGE:
      return {
        ...state,
        orderCanNotBeRemovedMessage: '',
        errorType: null,
      }
    case REMOVE_MULTIPLE_ORDERS:
      return {
        ...state,
        selectedOrders: state.selectedOrders.filter(
          (order) => !Utils.findById(order.id, action.orders),
        ),
      }
    case SET_PRESCRIPTION_FOR_PRINT:
      return {
        ...state,
        prescriptionForPrint: action.prescription,
        isReceiving: false,
      }
    case CLEAR_PRESCRIPTION_FOR_PRINT:
      return { ...state, prescriptionForPrint: undefined }
    case SET_PRESCRIPTION_FOR_EMAIL:
      return {
        ...state,
        prescriptionForEmail: action.prescription,
        isReceiving: false,
      }
    case CLEAR_PRESCRIPTION_FOR_EMAIL:
      return { ...state, prescriptionForEmail: undefined }
    case FETCH_PRESCRIPTION_PRINT_INFO:
      return { ...state, isReceiving: true }
    case FETCH_PRESCRIPTION_PRINT_INFO_FAILURE:
      return {
        ...state,
        isReceiving: false,
        itemExistError: getServerValidationError(action.error),
      }
    case CLEAR_ITEM_EXIST_ERROR: {
      return { ...state, itemExistError: null }
    }
    case CLEAR_ITEM_STATUS_ERROR: {
      return { ...state, itemStatusError: null }
    }
    case SEARCH_ORDERS:
      return {
        ...state,
        isReceiving: true,
        lastSearchOrderFilters: action,
      }
    case SEARCH_ORDERS_SUCCESS:
      return {
        ...state,
        searchResults: action.searchResults || [],
        searchResultsTotalCount: action.totalCount,
        isReceiving: false,
        lastRequestedOrderFilters: {},
      }
    case SEARCH_ORDERS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isReceiving: false,
      }
    case FETCH_MORE_FOR_SEARCH:
      return {
        ...state,
        isReceiving: true,
      }
    case FETCH_MORE_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: mergeArraysAtIndex(
          state.searchResults,
          action.searchResults,
          action.from || 0,
        ),
        searchResultsTotalCount: action.totalCount,
        isReceiving: false,
      }
    case FETCH_MORE_FOR_SEARCH_FAILURE:
      return {
        ...state,
        isReceiving: false,
      }
    case CLEAR_SEARCH_ORDERS:
      return {
        ...state,
        searchResults: [],
        searchResultsTotalCount: undefined,
        lastSearchOrderFilters: {},
      }
    case FETCH_PRESCRIPTION_EMAIL_TEMPLATE:
      return { ...state, isPrescriptionEmailTemplateReceiving: true }
    case FETCH_PRESCRIPTION_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPrescriptionEmailTemplateReceiving: false,
        prescriptionEmailTemplate: action.template,
      }
    case FETCH_PRESCRIPTION_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isPrescriptionEmailTemplateReceiving: false,
      }
    case CLEAR_PRESCRIPTION_EMAIL_TEMPLATE:
      return { ...state, prescriptionEmailTemplate: undefined }
    case EMAIL_PRESCRIPTION:
      return { ...state, isPrescriptionEmailSending: true }
    case EMAIL_PRESCRIPTION_SUCCESS:
      return { ...state, isPrescriptionEmailSending: false }
    case EMAIL_PRESCRIPTION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isPrescriptionEmailSending: false,
      }

    case GENERATE_PDF_FOR_PRESCRIPTION:
      return { ...state, isGeneratingPrescriptionPdf: true }
    case GENERATE_PDF_FOR_PRESCRIPTION_SUCCESS:
      return { ...state, isGeneratingPrescriptionPdf: false }
    case GENERATE_PDF_FOR_PRESCRIPTION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isGeneratingPrescriptionPdf: false,
      }
    case SIGN_PRESCRIPTION: {
      return {
        ...state,
        orderIsSigning: true,
      }
    }
    case SIGN_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        selectedOrders: Utils.updateById(action.order, state.selectedOrders),
        orderIsSigning: false,
      }
    case SIGN_PRESCRIPTION_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        orderIsSigning: false,
      }
    case SET_ORDER_VALIDATION_ERROR_MESSAGE:
      return {
        ...state,
        validationErrorMessage: action.errorMessage || '',
      }
    case CLEAR_ORDER_VALIDATION_ERROR_MESSAGE:
      return {
        ...state,
        validationErrorMessage: '',
        errorType: null,
      }
    case FETCH_UNIFIED_ORDER:
    case FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE:
    case FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID:
      return {
        ...state,
        unifiedOrderLoadingId: action.id,
        unifiedOrderIsLoading: true,
        error: null,
      }
    case FETCH_UNIFIED_ORDER_SUCCESS:
    case FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_SUCCESS:
    case FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_SUCCESS:
      return {
        ...state,
        unifiedOrderLoadingId: undefined,
        unifiedOrderIsLoading: false,
        unifiedOrder: action.order,
      }
    case FETCH_UNIFIED_ORDER_FAILURE:
    case FETCH_UNIFIED_ORDER_BY_ENTITY_TYPE_FAILURE:
    case FETCH_UNIFIED_ORDER_BY_TYPE_AND_LOG_ID_FAILURE:
      return {
        ...state,
        unifiedOrderLoadingId: undefined,
        unifiedOrderIsLoading: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_CURRENT_UNIFIED_ORDER:
      return {
        ...state,
        unifiedOrder: undefined,
      }
    case SUBTRACT_REFILL_REMAINS:
      return {
        ...state,
        orders: state.orders
          .map((order) =>
            order.parentId === action.order.parentId
              ? order.refillRemains && order.refillRemains > 1
                ? {
                    ...order,
                    refillRemains: order.refillRemains - 1,
                  }
                : undefined
              : order,
          )
          .filter(Boolean) as Order[],
      }
    case CANCEL_REFILLS_FAILURE:
      return { ...state, error: action.error }
    case FETCH_ORDER_COUNTS_BY_TYPE:
      return { ...state, isFetchingOrdersCountByType: true, error: null }
    case FETCH_ORDER_COUNTS_BY_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingOrdersCountByType: false,
        totalCountByType: action.totalCountByType,
      }
    case FETCH_ORDER_COUNTS_BY_TYPE_FAILURE:
      return {
        ...state,
        isFetchingOrdersCountByType: false,
        error: getErrorMessage(action.error),
      }
    case REMOUNT_WEBSOCKET_CHARGES_WIDGET:
      return { ...state, remount: true }
    case CLEAR_REMOUNT_WEBSOCKET_CHARGES_WIDGET:
      return { ...state, remount: false }
    case APPLY_PATIENT_RESTRICTIONS:
      return { ...state, appliedPatientRestrictions: action.applied }
    default:
      return state
  }
}

const getOrders = (state: RootState): OrdersState => state.orders
export const getOrdersList = (state: RootState) => getOrders(state).orders
export const getOrdersTotalCount = (state: RootState) =>
  getOrders(state).totalCount
export const getOrdersError = (state: RootState) => getOrders(state).error
export const getSelectedOrders = (state: RootState) =>
  getOrders(state).selectedOrders
export const getCreatedOrder = (state: RootState) =>
  getOrders(state).createdOrder
export const getPendingSelectionOrders = (state: RootState) =>
  getOrders(state).pendingSelectionOrders
export const getPendingUnSelectionOrders = (state: RootState) =>
  getOrders(state).pendingUnSelectionOrders
export const getSelectedOrder = (type: OrderType | Nil, id: string | Nil) =>
  createSelector(getSelectedOrders, (selectedOrders) =>
    selectedOrders.find((order) => order.type === type && order.id === id),
  )
export const getOrdersIsReceiving = (state: RootState) =>
  getOrders(state).isReceiving
export const getOrdersIsLoading = (state: RootState) =>
  getOrders(state).isLoading
export const getOrderIsCreating = (state: RootState) =>
  getOrders(state).isCreating
export const getOrdersIsDetailsReceiving = (state: RootState) =>
  getOrders(state).isDetailsReceiving
export const getOrdersIsFetchingOrdersCountByType = (state: RootState) =>
  getOrders(state).isFetchingOrdersCountByType
export const getOrdersIsGroupedDetailsReceiving = (state: RootState) =>
  getOrders(state).isGroupedDetailsReceiving
export const getOrdersIsSending = (state: RootState) =>
  getOrders(state).isSending
export const getOrderDetails = (state: RootState) =>
  getOrders(state).currentOrderDetails
export const getOrderDetailsGrouped = (state: RootState) =>
  getOrders(state).currentOrderDetailsGrouped
export const getOrdersFilters = (state: RootState) =>
  getOrders(state).ordersFilters
export const getPrescriptionForPrint = (state: RootState) =>
  getOrders(state).prescriptionForPrint
export const getPrescriptionForEmail = (state: RootState) =>
  getOrders(state).prescriptionForEmail
export const getOrdersSearchResults = (state: RootState) =>
  getOrders(state).searchResults
export const getOrdersSearchResultsTotalCount = (state: RootState) =>
  getOrders(state).searchResultsTotalCount
export const getIsPrescriptionEmailTemplateReceiving = (state: RootState) =>
  getOrders(state).isPrescriptionEmailTemplateReceiving
export const getIsPrescriptionEmailSending = (state: RootState) =>
  getOrders(state).isPrescriptionEmailSending
export const getPrescriptionEmailTemplate = (state: RootState) =>
  getOrders(state).prescriptionEmailTemplate
export const getOrderValidationErrorMessage = (state: RootState) =>
  getOrders(state).validationErrorMessage
export const getOrderCanNotBeRemovedErrorMessage = (state: RootState) =>
  getOrders(state).orderCanNotBeRemovedMessage
export const getUnassignedDoctorOrderError = (state: RootState) =>
  getOrders(state).errorType === 'RX_HAS_NO_DOCTOR_ID'
export const getUnifiedOrder = (state: RootState) =>
  getOrders(state).unifiedOrder
export const getUnifiedOrderIsLoading = (state: RootState) =>
  getOrders(state).unifiedOrderIsLoading
export const getUnifiedOrderLoadingId = (state: RootState) =>
  getOrders(state).unifiedOrderLoadingId
export const getPrescriptionIsPrint = (state: RootState) =>
  getOrders(state).isPrint
export const getPrescriptionIsEmail = (state: RootState) =>
  getOrders(state).isEmail
export const getLastRequestedOrderFilters = (state: RootState) =>
  getOrders(state).lastRequestedOrderFilters
export const getLastSearchOrderFilters = (state: RootState) =>
  getOrders(state).lastSearchOrderFilters
export const getOrdersIsSendingOrReceiving = (state: RootState) =>
  getOrdersIsSending(state) || getOrdersIsReceiving(state)
export const getPrescriptionIsGeneratingPdf = (state: RootState) =>
  getOrders(state).isGeneratingPrescriptionPdf
export const getOrderIsSigning = (state: RootState) =>
  getOrders(state).orderIsSigning
export const getItemExistError = (state: RootState) =>
  getOrders(state).itemExistError
export const getItemStatusError = (state: RootState) =>
  getOrders(state).itemStatusError
export const getTotalCountByType = (state: RootState) =>
  getOrders(state).totalCountByType
export const getRemountChargesWidget = (state: RootState) =>
  getOrders(state).remount
export const getAppliedPatientRestrictions = (state: RootState) =>
  getOrders(state).appliedPatientRestrictions
export const getOrderHasSingleLabTest = (orderId?: string) =>
  createSelector(
    getSelectedOrders,
    (selectedOrders) =>
      selectedOrders.filter((order) => order.labTest?.labOrderId === orderId)
        .length === 1,
  )

export default orders
