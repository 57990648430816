import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'

import { SchedulerColumnType } from '~/constants/schedulerConstants'
import { getTimetableFilteredPersons } from '~/store/reducers/timetable'
import { getWhiteboardSchedules } from '~/store/reducers/whiteboard'
import { WhiteboardSchedule } from '~/types'

import WhiteboardColumns, { WhiteboardColumnsProps } from '../WhiteboardColumns'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      borderBottom: theme.constants.filterBorder,
    },
    container: {
      '&:not(:last-child)': {
        borderRight: theme.constants.filterBorder,
        paddingRight: theme.spacing(1),
      },
      '&:first-child': {
        paddingLeft: 0,
        borderRight: 'none',
      },
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    columnHeader: {
      height: '100%',
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'WhiteboardHorizontalView' },
)

export interface WhiteboardHorizontalViewProps
  extends Omit<WhiteboardColumnsProps, 'slots'> {
  RowHeaderComponent: React.JSXElementConstructor<any>
  RowHeaderProps?: any
  getUniqueId: (schedule: WhiteboardSchedule) => string
  selectedView: string
}

const WhiteboardHorizontalView = ({
  RowHeaderComponent,
  RowHeaderProps,
  getUniqueId,
  selectedView,
  ...rest
}: WhiteboardHorizontalViewProps) => {
  const classes = useStyles()
  const schedules = useSelector(getWhiteboardSchedules)
  const currentSchedules = schedules[selectedView] || []
  const filteredPersons = useSelector(getTimetableFilteredPersons)
  const filteredSchedules = currentSchedules.filter(
    ({ personId }) => !personId || !filteredPersons.includes(personId),
  )

  return (
    <>
      {filteredSchedules.map((schedule: WhiteboardSchedule) => {
        const scheduleKey = getUniqueId(schedule)
        return (
          <Grid container item className={classes.row} key={scheduleKey}>
            <Grid item xs className={classes.container}>
              <RowHeaderComponent
                className={classes.columnHeader}
                column={{
                  ...schedule,
                  columnType: schedule.personId
                    ? SchedulerColumnType.PERSON
                    : SchedulerColumnType.UNASSIGNED,
                  slots: R.flatten(Object.values(schedule.slots)),
                }}
                {...RowHeaderProps}
              />
            </Grid>
            <WhiteboardColumns
              classes={{
                container: classes.container,
              }}
              id={scheduleKey}
              slots={schedule.slots}
              {...rest}
            />
          </Grid>
        )
      })}
    </>
  )
}

export default WhiteboardHorizontalView
