import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, PermissionArea, Text } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getSchedulerIsLoading } from '~/store/reducers/scheduler'
import { getClientId, getPatientId } from '~/store/reducers/soap'

import useConfirmLeaveSoapForAssignmentAlert from '../../useConfirmLeaveSoapForAssignment'

const useStyles = makeStyles(
  () => ({
    root: {},
    button: {
      minWidth: 80,
      height: 40,
    },
    text: {},
  }),
  { name: 'UpcomingAppointmentsHeader' },
)

export interface UpcomingAppointmentsHeaderProps {
  buttonLabel?: string
  classes?: ClassesType<typeof useStyles>
  hasAppointments?: boolean
}

const UpcomingAppointmentsHeader = ({
  classes: classesProp,
  buttonLabel = i18n.t('Common:ADD_ACTION'),
  hasAppointments = true,
}: UpcomingAppointmentsHeaderProps) => {
  const classes = useStyles({ classes: classesProp })

  const { t } = useTranslation('Common')

  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const appointmentPermission = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const isLoading = useSelector(getSchedulerIsLoading)

  const [openConfirmLeaveSoapForAssignmentAlert] =
    useConfirmLeaveSoapForAssignmentAlert()

  const openAddDialog = () => {
    openConfirmLeaveSoapForAssignmentAlert()
  }

  return (
    <Grid container item className={classes.root} px={2} wrap="nowrap">
      <Grid item xs>
        <Text className={classes.text} pr={2} variant="h4">
          {t('Common:UPCOMING_APPOINTMENTS')}
        </Text>
      </Grid>
      <Grid item>
        {appointmentPermission.create && hasAppointments && !isLoading && (
          <Fab
            className={classes.button}
            color="inherit"
            disabled={!clientId || !patientId}
            variant="extended"
            onClick={openAddDialog}
          >
            {buttonLabel}
          </Fab>
        )}
      </Grid>
    </Grid>
  )
}

export default UpcomingAppointmentsHeader
