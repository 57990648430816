import axios, { AxiosRequestConfig } from 'axios'
import { normalize, Schema } from 'normalizr'
import * as R from 'ramda'
import { ErrorTypes, getSessionId, Utils } from '@pbt/pbt-ui-components'

import { tokenHolder } from './utils/token'

export const getAPIPath = (path: string) => `/apigw-rhapsody/${path}`
export const getPreludeAPIPath = (path: string) => `/apigw-prelude/${path}`

axios.interceptors.request.use(
  (config) => ({
    ...config,
    metadata: { startTime: new Date() },
  }),
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response: any) => {
    if (response?.config?.metadata) {
      response.config.metadata.endTime = new Date()
      response.config.metadata.duration =
        response.config.metadata.endTime - response.config.metadata.startTime
    }

    return response
  },
  (error) => {
    if (error?.config?.metadata) {
      error.config.metadata.endTime = new Date()
      error.config.metadata.duration =
        error.config.metadata.endTime - error.config.metadata.startTime
    }

    return Promise.reject(error)
  },
)

export const request = async (
  path: string,
  params: AxiosRequestConfig = {},
  auth = true,
  schema?: Schema,
) => {
  const requestParameters: AxiosRequestConfig = { ...params }
  if (auth) {
    const token = await tokenHolder.getToken()

    requestParameters.headers = {
      ...(requestParameters.headers || {}),
      Authorization: `Bearer ${token}`,
      'X-PBT-SessionId': getSessionId(),
    }
  }

  if (Utils.isLocalEnvironment()) {
    requestParameters.headers = {
      ...(requestParameters.headers || {}),
      'X-PBT-DevEnv': window.pbt.getDevEnv(),
      'X-PBT-Region': window.pbt.getDevRegion(),
    }
  }

  return axios({
    ...requestParameters,
    url: path,
  })
    .then((response) => {
      const { data } = response || {}

      return schema ? normalize(data || {}, schema) : data
    })
    .catch((error) => {
      const contentType = R.path(['headers', 'Content-Type'], requestParameters)
      throw new ErrorTypes.ApiError(
        error,
        contentType === 'multipart/form-data'
          ? 'multipart/form-data'
          : requestParameters.data,
      )
    })
}
