import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  DateFormat,
  DateUtils,
  LinkButton,
  Nil,
  PermissionArea,
  Text,
  WellnessPlans,
} from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import TooltipChip from '~/components/common/TooltipChip'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  patientMembershipsActions,
  // @ts-ignore
} from '~/store/duck/patientMemberships'
import {
  getCRUDByArea,
  getCurrentBusinessHasActiveWellnessPlans,
  getCurrentBusinessHasWellnessPackages,
  getCurrentBusinessWellnessPlansEnabled,
} from '~/store/reducers/auth'
import { getBusinessesMap } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getPatientStatus } from '~/utils'
import useDialog from '~/utils/useDialog'

import PatientMembershipIcon from '../clients/patient/PatientMembershipIcon'
import {
  getAllPatientPlansCancelled,
  getPatientHasNoMembership,
  getPlanNames,
  getPlanNamesWithOriginatingBusiness,
  useGroupedWellnessPlans,
} from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      width: '100%',
      height: '100%',
    },
    button: {
      padding: theme.spacing(1),
      fontSize: '1.4rem',
    },
  }),
  { name: 'WellnessPlanMemberChip' },
)

export interface WellnessPlanMemberChipProps {
  className?: string
  clientId: string | Nil
  patientId: string | Nil
}

const WellnessPlanMemberChip = ({
  clientId,
  className,
  patientId,
}: WellnessPlanMemberChipProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()

  const patient = useSelector(getPatient(patientId))
  const businessesMap = useSelector(getBusinessesMap)
  const wellnessPlansEnabled = useSelector(
    getCurrentBusinessWellnessPlansEnabled,
  )
  const hasActiveWellnessPlans = useSelector(
    getCurrentBusinessHasActiveWellnessPlans,
  )
  const hasWellnessPackages = useSelector(getCurrentBusinessHasWellnessPackages)
  const {
    create: wellnessPlanCreatePermissions,
    read: wellnessPlanReadPermissions,
  } = useSelector(getCRUDByArea(PermissionArea.WELLNESS_PLAN))

  const isWellnessplanSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WELLNESSPLAN_SHARING),
  )

  const [open, setOpen] = useState(false)

  const [openMembershipSignUpDialog] = useDialog(DialogNames.MEMBERSHIP_SIGN_UP)
  const [openPatientMembershipDialog] = useDialog(
    DialogNames.PATIENT_MEMBERSHIP,
  )

  const notCancelledPlans = R.filter(
    (plan) => plan.wplanStatus !== WellnessPlans.MembershipStatus.CANCELLED,
    patient?.plans || [],
  )

  const {
    packages = [],
    extras = [],
    tiers = [],
  } = useGroupedWellnessPlans(notCancelledPlans)

  const hasNoMembership = getPatientHasNoMembership(patient)

  const shouldHideSignUp =
    hasNoMembership &&
    (getPatientStatus(patient).disabled || !hasActiveWellnessPlans)

  if (!patientId || !wellnessPlansEnabled || shouldHideSignUp) {
    return null
  }

  const isCancelled = getAllPatientPlansCancelled(patient)

  const handleEyeClick = () => {
    setOpen(false)
    openPatientMembershipDialog({ clientId, patientId })
  }

  const addMembershipCallback = () => {
    dispatch(patientMembershipsActions.fetchItem(clientId, patientId))
  }

  const handleAddPlan = () => {
    setOpen(false)
    openMembershipSignUpDialog({
      clientId,
      patientId,
      onSuccess: addMembershipCallback,
    })
  }

  const showAddPlanButton =
    hasActiveWellnessPlans &&
    (hasWellnessPackages || hasNoMembership) &&
    wellnessPlanCreatePermissions
  const showPlanView = !R.isEmpty(patient?.plans || [])

  const cancelledLabel = isCancelled
    ? `${t('Common:PLAN_MEMBER')} (${t(
        'Common:CANCELLED',
      ).toLowerCase()} ${DateUtils.formatDate(
        patient?.wplanCancellationDate,
        true,
        DateFormat.SHORT_DATE_YEAR_ABBREV,
      )})`
    : ''

  const tierNames = isWellnessplanSharingEnabled
    ? getPlanNamesWithOriginatingBusiness(tiers, businessesMap)
    : getPlanNames(tiers)
  const extrasNames = getPlanNames(extras)
  const packagesNames = getPlanNames(packages)

  const hasTiers = tierNames.length > 0
  const hasExtras = extrasNames.length > 0
  const hasPackages = packagesNames.length > 0

  return (
    <TooltipChip
      disableIconBranding
      disableTooltipBranding
      className={className}
      icon={
        <PatientMembershipIcon className={classes.icon} patientId={patientId} />
      }
      open={open}
      tooltipPlacement="bottom-start"
      tooltipText={
        <Box>
          {showPlanView && (
            <Grid container item alignItems="center" wrap="nowrap">
              <Grid p={1}>
                {cancelledLabel ? (
                  <Text variant="body2">{cancelledLabel}</Text>
                ) : (
                  <>
                    {hasTiers && (
                      <Text variant="body2">{`${t(
                        'Common:PLAN',
                      )}: ${tierNames.join(', ')}`}</Text>
                    )}
                    {hasExtras && (
                      <Text variant="body2">{`${t(
                        'Common:EXTRAS',
                      )}: ${extrasNames.join(', ')}`}</Text>
                    )}
                    {hasPackages && (
                      <Text variant="body2">{`${t(
                        'Common:SUBSCRIBE_AND_SAVES',
                      )}: ${packagesNames.join(', ')}`}</Text>
                    )}
                  </>
                )}
              </Grid>
              <IconButton
                disabled={!wellnessPlanReadPermissions}
                onClick={
                  wellnessPlanReadPermissions ? handleEyeClick : undefined
                }
              >
                <EyeIcon />
              </IconButton>
            </Grid>
          )}
          {showAddPlanButton && (
            <LinkButton className={classes.button} onClick={handleAddPlan}>
              {t('Common:ADD_PLAN')}
            </LinkButton>
          )}
        </Box>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}

export default WellnessPlanMemberChip
