import {
  ApiError,
  Nil,
  Question,
  UnsavedQuestion,
} from '@pbt/pbt-ui-components'

import {
  CLEAR_QUESTION_VALIDATION_ERROR,
  CREATE_QUESTION,
  CREATE_QUESTION_FAILURE,
  CREATE_QUESTION_SUCCESS,
  DELETE_QUESTION,
  DELETE_QUESTION_FAILURE,
  DELETE_QUESTION_SUCCESS,
  EDIT_QUESTION,
  EDIT_QUESTION_FAILURE,
  EDIT_QUESTION_SUCCESS,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_SUCCESS,
  FETCH_QUESTION,
  FETCH_QUESTION_FAILURE,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTIONS_FOR_SOAP,
  FETCH_QUESTIONS_FOR_SOAP_FAILURE,
  FETCH_QUESTIONS_FOR_SOAP_SUCCESS,
  FETCH_QUESTIONS_LIST,
  FETCH_QUESTIONS_LIST_FAILURE,
  FETCH_QUESTIONS_LIST_SUCCESS,
  QUESTION_VALIDATION_ERROR,
  SAVE_QUESTIONS_FOR_SOAP,
  SAVE_QUESTIONS_FOR_SOAP_FAILURE,
  SAVE_QUESTIONS_FOR_SOAP_SUCCESS,
  UPDATE_QUESTIONS,
} from './types/questions'

export const fetchQuestionsList = (
  from?: number,
  to?: number,
  query?: string | Nil,
) => ({
  type: FETCH_QUESTIONS_LIST,
  from,
  to,
  query,
})
export const fetchQuestionsListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_QUESTIONS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchQuestionsListFailure = (error: ApiError) => ({
  type: FETCH_QUESTIONS_LIST_FAILURE,
  error,
})

export const fetchQuestion = (id: string) => ({ type: FETCH_QUESTION, id })
export const fetchQuestionFailure = (error: ApiError) => ({
  type: FETCH_QUESTION_FAILURE,
  error,
})
export const fetchQuestionSuccess = () => ({ type: FETCH_QUESTION_SUCCESS })

export const fetchQuestionsForSoap = (soapId: string) => ({
  type: FETCH_QUESTIONS_FOR_SOAP,
  soapId,
})
export const fetchQuestionsForSoapFailure = (error: ApiError) => ({
  type: FETCH_QUESTIONS_FOR_SOAP_FAILURE,
  error,
})
export const fetchQuestionsForSoapSuccess = (
  soapId: string,
  list: string[],
) => ({
  type: FETCH_QUESTIONS_FOR_SOAP_SUCCESS,
  soapId,
  list,
})

export const saveQuestionsForSoap = (soapId: string, log: Question[]) => ({
  type: SAVE_QUESTIONS_FOR_SOAP,
  soapId,
  log,
})
export const saveQuestionsForSoapFailure = (error: ApiError) => ({
  type: SAVE_QUESTIONS_FOR_SOAP_FAILURE,
  error,
})
export const saveQuestionsForSoapSuccess = (
  soapId: string,
  list: string[],
) => ({
  type: SAVE_QUESTIONS_FOR_SOAP_SUCCESS,
  soapId,
  list,
})

export const fetchMoreItemsForQuestionsList = (
  from: number,
  to: number,
  query?: string | null,
) => ({
  type: FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForQuestionsListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForQuestionsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_QUESTIONS_LIST_FAILURE,
  error,
})

export const createQuestion = (question: Partial<UnsavedQuestion>) => ({
  type: CREATE_QUESTION,
  question,
})
export const createQuestionSuccess = (questionId: string) => ({
  type: CREATE_QUESTION_SUCCESS,
  questionId,
})
export const createQuestionFailure = (error: ApiError) => ({
  type: CREATE_QUESTION_FAILURE,
  error,
})

export const editQuestion = (question: Question) => ({
  type: EDIT_QUESTION,
  question,
})
export const editQuestionSuccess = () => ({ type: EDIT_QUESTION_SUCCESS })
export const editQuestionFailure = (error: ApiError) => ({
  type: EDIT_QUESTION_FAILURE,
  error,
})

export const deleteQuestion = (questionId: string) => ({
  type: DELETE_QUESTION,
  questionId,
})
export const deleteQuestionSuccess = (questionId: string) => ({
  type: DELETE_QUESTION_SUCCESS,
  questionId,
})
export const deleteQuestionFailure = (error: ApiError) => ({
  type: DELETE_QUESTION_FAILURE,
  error,
})

export const updateQuestions = (questions: Record<string, Question>) => ({
  type: UPDATE_QUESTIONS,
  questions,
})

export const questionValidationError = (error: ApiError) => ({
  type: QUESTION_VALIDATION_ERROR,
  error,
})
export const clearQuestionValidationError = () => ({
  type: CLEAR_QUESTION_VALIDATION_ERROR,
})
