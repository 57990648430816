import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Link, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PuiTextField, Text, TextWithTooltip } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import { SoapTemplateInput } from '~/api/graphql/generated/types'
import Expander from '~/components/common/lists/Expander'
import { getBusinessIsFetchingBusinessSoapWidgets } from '~/store/reducers/businesses'
import {
  getIsFetchingSoapTemplate,
  getIsResettingSoapTemplate,
  getIsUpdatingSoapTemplate,
  updateSoapTemplate,
} from '~/store/reducers/soapV2'
import { SoapTemplate, SoapTemplateTab } from '~/types'

import ResetToDefaultButton from '../dialog/ResetToDefaultButton'
import SoapTemplateTabsTable from './table/SoapTemplateTabsTable'
import { useSoapTemplateFields } from './utils/useSoapTemplateFields'

const useStyles = makeStyles(
  () => ({
    name: {
      maxWidth: 603,
    },
    tooltip: {
      minWidth: 395,
    },
  }),
  { name: 'SoapCustomizationDetails' },
)

export interface SoapCustomizationDetailsProps {
  item: SoapTemplate | undefined
  onClose: () => void
}

const SoapCustomizationDetails = ({
  item,
  onClose,
}: SoapCustomizationDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const isFetchingSoapTemplate = useSelector(getIsFetchingSoapTemplate)
  const isFetchingBusinessSoapWidgets = useSelector(
    getBusinessIsFetchingBusinessSoapWidgets,
  )
  const isUpdatingSoapTemplate = useSelector(getIsUpdatingSoapTemplate)
  const isResetting = useSelector(getIsResettingSoapTemplate)

  const { fieldName, fieldTabs, handleReset, hasUnsavedData, validate } =
    useSoapTemplateFields(item)

  const isFetching = isFetchingSoapTemplate || isFetchingBusinessSoapWidgets
  const isUpdating = isUpdatingSoapTemplate || isResetting

  const handleSave = () => {
    if (!validate() || !item?.id) {
      return
    }

    const newTemplate = {
      active: true,
      name: fieldName.value,
      tabs: R.map(
        (tab: SoapTemplateTab) => ({
          ...R.omit(['label', 'url', 'tabElement'], tab),
          widgets: R.map(
            R.omit(['active', 'id', 'name', 'nameTranslation']),
            tab.widgets,
          ),
        }),
        fieldTabs.value,
      ),
    } as SoapTemplateInput

    dispatch(updateSoapTemplate({ id: item.id, input: newTemplate }))
  }

  return (
    <Expander
      hasUnsavedData={hasUnsavedData}
      isFetching={isFetching}
      isSaving={isUpdating}
      saveButtonId="soap-customization-save-button"
      onBack={onClose}
      onSaveRequested={handleSave}
    >
      <Stack id="soap-customization-details" spacing={3}>
        <PuiTextField
          className={classes.name}
          field={fieldName}
          id="soap-template-name-input"
          inputProps={{ maxLength: 100 }}
          label={fieldName.label}
        />
        <Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={0.5}
          >
            <TextWithTooltip
              strong
              Icon={InfoIcon}
              TooltipProps={{ classes: { tooltip: classes.tooltip } }}
              alignItems="center"
              tooltipText={
                <Text variant="body2">
                  <Trans
                    components={{
                      linkComponent: (
                        <Link
                          aria-label={t('Admin:SOAP_CUSTOMIZATION.FAQ_LINK')}
                          href="https://help.rhapsody.vet/en/articles/8423770-how-do-i-customize-the-soap"
                          rel="help noopener"
                          target="_blank"
                          underline="always"
                        />
                      ),
                    }}
                    i18nKey="Admin:SOAP_CUSTOMIZATION.TOOLTIP"
                  />
                </Text>
              }
              variant="subheading3"
            >
              {t('Admin:SOAP_CUSTOMIZATION.TEMPLATE_TABS_AND_CONTENT')}
            </TextWithTooltip>
            <ResetToDefaultButton itemId={item?.id} />
          </Box>
          {fieldTabs.value && (
            <SoapTemplateTabsTable
              fieldTabs={fieldTabs}
              isFetching={isFetching}
              onReset={handleReset}
            />
          )}
        </Box>
      </Stack>
    </Expander>
  )
}

export default SoapCustomizationDetails
