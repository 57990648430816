import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { LanguageUtils, Nil, PluralUtils, Utils } from '@pbt/pbt-ui-components'

// @ts-ignore
import { getPluralPackageLabel } from '~/components/dashboard/admin/catalog/inventory/inventoryUtils'
import i18n from '~/locales/i18n'
import {
  getPackageType,
  getPriceUnits,
  getProductSizeUnit,
  getTimeUnits,
} from '~/store/reducers/constants'
import { BulkPriceEdit, Price, Variation } from '~/types'

import { normalizeWhiteSpaces } from '.'
import { getOneTimeUnitPluralized } from './time'

export const useGetFormattedPriceUnit = (
  priceOrList: BulkPriceEdit | Price | Price[] | Nil,
  variation?: Variation,
) => {
  const PriceUnits = useSelector(getPriceUnits)
  const TimeUnits = useSelector(getTimeUnits)
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PackageType = useSelector(getPackageType)

  const {
    packageTypeId: variationPackageTypeId,
    perPackageUnitsId: variationPerPackageUnitsId,
    saleUnitOfMeasure,
    count,
  } = variation || {}

  const formatPriceUnitString = (price: BulkPriceEdit | Price | Nil) => {
    if (!price) {
      return ''
    }

    const {
      priceUnitsSize,
      minTimeUnitSize,
      maxTimeUnitSize,
      timeUnitSize,
      unitId,
      timeUnitId,
      packageTypeId = variationPackageTypeId,
      perPackageUnitsId = variationPerPackageUnitsId,
    } = price

    const TimeBasedId = Utils.findConstantIdByName('Time-based', PriceUnits)
    const priceUnitName = Utils.getConstantName(unitId, PriceUnits, '')
    const priceUnitDisplayName = LanguageUtils.getConstantTranslatedName(
      unitId,
      PriceUnits,
    )
    const timeUnitName = Utils.getConstantName(timeUnitId, TimeUnits, '')

    if (price.unitId !== TimeBasedId) {
      const productSizeUnitName = LanguageUtils.getConstantTranslatedName(
        perPackageUnitsId,
        ProductSizeUnit,
      )
      const isPricePlural = price.priceUnitsSize
        ? price.priceUnitsSize > 1
        : false

      const packageName = LanguageUtils.getConstantTranslatedName(
        packageTypeId,
        PackageType,
      )
      const pluralPackageName = getPluralPackageLabel(
        packageTypeId,
        PackageType,
      )

      const packageSaleUnitOfMeasure =
        count && saleUnitOfMeasure ? `${count} ${saleUnitOfMeasure}` : ''

      return R.cond([
        [R.always(!productSizeUnitName), R.always(priceUnitDisplayName)],
        [
          R.equals('Per X package units'),
          R.always(
            i18n.t('Common:PER_X_UNIT_NAME', {
              x: priceUnitsSize || 'X',
              unitName: saleUnitOfMeasure || productSizeUnitName.toLowerCase(),
            }),
          ),
        ],
        [
          R.always(R.equals('Per X packages') && isPricePlural),
          R.always(
            i18n.t('Common:PER_X_UNIT_NAME', {
              x: priceUnitsSize || 'X',
              unitName: packageSaleUnitOfMeasure
                ? `${packageSaleUnitOfMeasure} ${pluralPackageName}`
                : pluralPackageName,
            }),
          ),
        ],
        [
          R.always(R.equals('Per X packages') && !isPricePlural),
          R.always(
            normalizeWhiteSpaces(
              i18n.t('Common:PER_X_UNIT_NAME', {
                x: priceUnitsSize && '',
                unitName: packageSaleUnitOfMeasure
                  ? `${packageSaleUnitOfMeasure} ${packageName}`
                  : packageName,
              }),
            ),
          ),
        ],
        [R.T, R.always(priceUnitDisplayName)],
      ])(priceUnitName)
    }

    const isRange = Boolean(minTimeUnitSize || maxTimeUnitSize)
    const isActualRange = isRange && minTimeUnitSize !== maxTimeUnitSize
    const unitSize = isRange
      ? isActualRange
        ? `${minTimeUnitSize}-${maxTimeUnitSize}`
        : minTimeUnitSize
      : timeUnitSize

    const translatedTimeUnitName = getOneTimeUnitPluralized(
      timeUnitName,
      timeUnitSize || maxTimeUnitSize || 0,
    )

    return i18n.t('Common:PER_X_UNIT_NAME', {
      x: unitSize,
      unitName: translatedTimeUnitName,
    })
  }

  return Array.isArray(priceOrList)
    ? priceOrList.map(formatPriceUnitString)
    : formatPriceUnitString(priceOrList)
}

export const useGetPriceUnitsDisplayName = (
  priceUnitsName: string | undefined,
) => {
  const PriceUnits = useSelector(getPriceUnits)
  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const PackageType = useSelector(getPackageType)

  const Constants = [...PriceUnits, ...ProductSizeUnit, ...PackageType]

  if (!priceUnitsName) {
    return ''
  }

  const words = priceUnitsName.split(' ')
  if (words.length === 1) {
    return LanguageUtils.getTranslatedNameByRawName(priceUnitsName, Constants)
  }

  const [, qty, unitNameSingularOrPlural] = words
  const unitName = unitNameSingularOrPlural.replace('(s)', '')
  if (unitName === 'Each' && Number(qty) === 1) {
    return LanguageUtils.getTranslatedNameByRawName('Each', Constants)
  }

  const unitNameTranslation = LanguageUtils.getTranslatedNameByRawName(
    unitName,
    Constants,
  ).toLowerCase()
  // Right now, we don't have a map with one (singular) or other (plural) for all constants
  // so it's better to use this generic function
  const pluralSentence = PluralUtils.pluralizeOneOrOther(unitNameTranslation)

  return i18n.t('Common:PER_X_UNIT_NAME', {
    x: qty,
    unitName: PluralUtils.getPluralForm(Number(qty), {
      singular: unitNameTranslation,
      plural: pluralSentence,
      singularOrPlural: pluralSentence,
      fallback: unitNameTranslation,
    }),
  })
}
