import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'

export interface ProductionCellHeadProps {
  className?: string
}

const ProductionCellHead = ({ className }: ProductionCellHeadProps) => {
  const { t } = useTranslation('Common')

  return <TableCell className={className}>{t('Common:PRODUCTION')}</TableCell>
}

export default ProductionCellHead
