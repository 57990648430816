import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import {
  GroupedAttachmentTimelineItem,
  GroupedAttachmentTimelineItemEntity,
} from '~/types'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardContent from '../TimelineCardContent'
import GroupedAttachmentItem from './GroupedAttachmentItem'

interface AttachmentCardProps extends TimelineCardProps {
  clientId: string
  expanded?: boolean
  item: GroupedAttachmentTimelineItem
  patientId: string
}

const AttachmentCard = ({
  item,
  patientId,
  clientId,
  expanded,
  ...rest
}: AttachmentCardProps) => {
  const { t } = useTranslation('Common')

  return (
    <TimelineCard
      {...item}
      {...rest}
      noHours
      date={item.date}
      title={t('Common:ATTACHMENTS')}
    >
      <TimelineCardContent noTypography>
        <Grid container item direction="column">
          {item.entries.map((entry: GroupedAttachmentTimelineItemEntity) => (
            <GroupedAttachmentItem
              clientId={clientId}
              entry={entry}
              key={entry.id}
              patientId={patientId}
            />
          ))}
        </Grid>
      </TimelineCardContent>
    </TimelineCard>
  )
}

export default AttachmentCard
