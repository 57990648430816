import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps } from '@pbt/pbt-ui-components'

import ValidationError, { ValidationErrorProps } from './ValidationError'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 415,
      maxWidth: 415,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'ValidationErrorDialog' },
)

export interface ValidationErrorDialogProps
  extends ValidationErrorProps,
    PuiDialogProps {}

const ValidationErrorDialog = ({
  open,
  onBack,
  onClose,
  onProceed,
  error,
}: ValidationErrorDialogProps) => {
  const classes = useStyles()

  const onBackOrClose = () => {
    onBack()
    if (onClose) {
      onClose()
    }
  }

  const onProceedButton = () => {
    onProceed()
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="validation-error-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onBackOrClose}
    >
      <ValidationError
        error={error}
        onBack={onBackOrClose}
        onProceed={onProceedButton}
      />
    </PuiDialog>
  )
}

export default ValidationErrorDialog
