import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { Business, CRUD, PermissionArea } from '@pbt/pbt-ui-components'
import {
  Analytics,
  Calendar,
  Chat,
  ClientAndPatient,
  Communications,
  Home,
  Imaging,
  Lab,
  Question,
  RhapsodyChat,
  RocketChat,
  Settings,
  TasksDashboard,
  Whiteboard,
} from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { NotificationAreaNames } from '~/constants/notifications'
import {
  getCurrentBusiness,
  getCurrentBusinessChatEnabled,
  getCurrentBusinessIsIdexxImagingEnabled,
  getHasUserAccessToBenchmarkKeys,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

import { useGeneralAnalyticsMenu } from './configs/useGeneralAnalyticsMenu'
import { useOmnichannelAnalyticsMenu } from './configs/useOmnichannelAnalyticsMenu'
import { MenuItem } from './LeftMenu'
import LeftMenuHelpButton from './LeftMenuHelpButton'
import LeftMenuSupportButton from './LeftMenuSupportButton'

const getMenu = R.pipe(R.filter(Boolean), R.filter(R.propOr(true, 'enabled')))

const useLeftMenuConfig = () => {
  const isRCIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_INTEGRATION),
  )
  const isSoapCustomizationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SOAP_CUSTOMIZATION),
  )
  const isRCPhase2IntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.RC_PHASE_2_INTEGRATION),
  )
  const isSsoPaymentTerminalsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SSO_PAYMENT_TERMINALS),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const isOmnichannelAnalyticsMenuEnabled = useSelector(
    getFeatureToggle(FeatureToggle.OMNICHANNEL_ANALYTICS_MENU),
  )

  const currentBusiness = useSelector(getCurrentBusiness)
  const currentBusinessChatEnabled = useSelector(getCurrentBusinessChatEnabled)
  const hasUserAccessToBenchmarkKeys = useSelector(
    getHasUserAccessToBenchmarkKeys(currentBusiness),
  )
  const { t } = useTranslation(['Common', 'Menu'])

  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )

  const generalAnalyticsMenu = useGeneralAnalyticsMenu()
  const omnichannelAnalyticsMenu = useOmnichannelAnalyticsMenu()

  const AdminMenuGeneral: MenuItem[] = [
    ...(isSsoPaymentTerminalsEnabled && currentBusiness?.posPayEnabled
      ? [
          {
            id: uuid(),
            text: t('Menu:ADMIN_GENERAL.CONNECTED_DEVICES'),
            url: '/admin/general/connected-devices',
            permissionArea: !isCvcRolesEnabled
              ? PermissionArea.PERSON
              : undefined,
            permissionAreas: isCvcRolesEnabled
              ? [PermissionArea.PAYMENT, PermissionArea.PAYMENT_DEVICE]
              : undefined,
          },
        ]
      : []),
    {
      id: uuid(),
      text: t('Menu:ADMIN_GENERAL.APPOINTMENT_CONFIGURATION'),
      url: '/admin/general/appointment-configuration',
      permissionArea: PermissionArea.BUSINESS_APPOINTMENT_TYPE,
    },
    {
      id: uuid(),
      text: t('Menu:ADMIN_GENERAL.CONTACTS'),
      url: '/admin/general/contacts',
      permissionArea: PermissionArea.CONTACT,
    },
    {
      id: uuid(),
      text: t('Menu:ADMIN_GENERAL.MARKETPLACE_AND_INTEGRATIONS'),
      url: '/admin/general/marketplace',
      permissionArea: isCvcRolesEnabled
        ? PermissionArea.MARKETPLACE_AND_INTEGRATIONS
        : PermissionArea.BUSINESS,
    },
    {
      id: uuid(),
      text: t('Common:PRACTICE_OTHER'),
      url: '/admin/general/practices',
      permissionArea: PermissionArea.PRACTICES,
    },
    {
      id: uuid(),
      text: t('Common:TEAM_MEMBERS'),
      url: '/admin/general/members',
      permissionArea: PermissionArea.PERSON,
    },
    ...(isSoapCustomizationEnabled
      ? [
          {
            id: uuid(),
            text: t('Common:SOAP_CUSTOMIZATION'),
            url: '/admin/general/soap-customization',
            permissionArea: PermissionArea.SOAP_TEMPLATE,
          },
        ]
      : []),
    {
      id: uuid(),
      text: t('Common:SHIFTS_AND_PRACTICE_HOURS'),
      url: '/admin/scheduling/availability',
      permissionArea: PermissionArea.EVENT_AVAILABILITY,
    },
    {
      id: uuid(),
      text: t('Common:SPACE_OTHER'),
      url: '/admin/general/spaces',
      permissionArea: PermissionArea.SPACE,
    },
    ...(hasUserAccessToBenchmarkKeys
      ? [
          {
            id: uuid(),
            text: t('Common:BENCHMARKING_API_ACCESS'),
            url: '/admin/general/benchmarking',
          },
        ]
      : []),
  ]

  const AdminMenuSupport: MenuItem[] = [
    {
      id: uuid(),
      text: t('Menu:ADMIN_SUPPORT.ROLES_MANAGEMENT'),
      url: '/admin/support/roles',
      permissionArea: PermissionArea.SUPPORT,
    },
  ]

  const AdminMenuCatalog: MenuItem[] = [
    {
      id: uuid(),
      text: t('Menu:ADMIN_CATALOG.BUNDLES'),
      url: '/admin/catalog/bundles',
      permissionArea: isCvcRolesEnabled
        ? PermissionArea.BUNDLE
        : PermissionArea.SOAP,
      permissionAction: isCvcRolesEnabled ? CRUD.UPDATE : undefined,
    },
    {
      id: uuid(),
      text: t('Common:DOCUMENTS_AND_TEMPLATES'),
      url: '/admin/catalog/documents',
      permissionArea: PermissionArea.FORM,
    },
    {
      id: uuid(),
      text: t('Common:INVENTORY'),
      url: '/admin/catalog/inventories',
      permissionArea: PermissionArea.INVENTORY,
    },
    {
      id: uuid(),
      text: t('Common:LAB_TESTS'),
      url: '/admin/catalog/lab-tests',
      permissionArea: isCvcRolesEnabled
        ? PermissionArea.LAB_TEST_PRICE
        : PermissionArea.LAB_TEST,
    },
    {
      id: uuid(),
      text: t(
        'Menu:ADMIN_CATALOG.PRESCRIPTION_DRUG_MONITORING_PROGRAM_FILE_EXPORT',
      ),
      url: '/admin/catalog/pdmp-file-export',
      permissionArea: PermissionArea.INVENTORY,
    },
    {
      id: uuid(),
      text: t('Common:PROCEDURE_OTHER'),
      url: '/admin/catalog/procedures',
      permissionArea: isCvcRolesEnabled
        ? PermissionArea.PROCEDURE_PRICE
        : PermissionArea.PROCEDURE,
    },
    {
      id: uuid(),
      text: t('Common:QUESTION_OTHER'),
      url: '/admin/catalog/questions',
      permissionArea: isCvcRolesEnabled
        ? PermissionArea.CUSTOM_QUESTION
        : undefined,
    },
    {
      id: uuid(),
      text: t('Common:REMINDER_PROTOCOLS'),
      url: '/admin/catalog/reminder-protocols',
      permissionArea: PermissionArea.REMINDER_PROTOCOLS,
    },
    {
      id: uuid(),
      text: t('Common:WELLNESS_PLANS'),
      url: '/admin/catalog/wellness-plans',
      disabled: (business: Business | undefined) =>
        !business?.wellnessPlansEnabled,
      permissionArea: isCvcRolesEnabled
        ? PermissionArea.WELLNESS_PLAN_CONFIG
        : PermissionArea.WELLNESS_PLAN,
    },
  ]

  const AdminMenu: MenuItem[] = [
    {
      id: uuid(),
      text: t('Menu:ADMIN.GENERAL'),
      expanded: true,
      children: getMenu(AdminMenuGeneral),
    },
    {
      id: uuid(),
      text: t('Common:CATALOG'),
      expanded: true,
      children: getMenu(AdminMenuCatalog),
    },
    {
      id: uuid(),
      text: t('Menu:ADMIN.SUPPORT'),
      hideIfNoPermissions: true,
      expanded: true,
      permissionArea: PermissionArea.SUPPORT,
      children: getMenu(AdminMenuSupport),
    },
  ]

  const MainMenu: MenuItem[] = [
    {
      id: uuid(),
      text: t('Menu:MAIN.DASHBOARD'),
      icon: Home,
      url: '/landing',
      permissionArea: PermissionArea.BUSINESS,
    },
    {
      id: uuid(),
      text: t('Common:CLIENTS_AND_PATIENTS'),
      icon: ClientAndPatient,
      url: '/clients',
      permissionArea: PermissionArea.PATIENT,
    },
    {
      id: uuid(),
      text: t('Menu:MAIN.SCHEDULER'),
      icon: Calendar,
      url: '/scheduler',
      permissionArea: PermissionArea.EVENT_APPOINTMENT,
    },
    {
      id: uuid(),
      text: t('Menu:MAIN.WHITEBOARD'),
      icon: Whiteboard,
      url: '/whiteboard',
      permissionArea: PermissionArea.EVENT_APPOINTMENT,
    },
    {
      id: uuid(),
      text: t('Common:TASKS'),
      icon: TasksDashboard,
      url: '/tasks-dashboard',
      permissionArea: PermissionArea.TASK,
      notificationArea: NotificationAreaNames.TASKS,
    },
    {
      id: uuid(),
      text: t('Common:LAB_TESTS'),
      icon: Lab,
      url: '/lab-tests-dashboard',
      permissionArea: PermissionArea.LAB_TEST,
    },
    ...(isImagingIntegrated
      ? [
          {
            id: uuid(),
            text: t('Common:IMAGING_REQUESTS'),
            icon: Imaging,
            url: '/imaging-dashboard',
            permissionArea: PermissionArea.LAB_TEST,
          },
        ]
      : []),
    {
      id: uuid(),
      text: t('Common:COMMUNICATIONS'),
      icon: Communications,
      url: '/communications',
      permissionArea: PermissionArea.COMMUNICATION,
      notificationArea: NotificationAreaNames.COMMUNICATIONS,
    },
    ...(isRCPhase2IntegrationEnabled && currentBusinessChatEnabled
      ? [
          {
            id: uuid(),
            text: t('Menu:MAIN.INTERNAL_CHAT_AND_LOGS'),
            icon: RhapsodyChat,
            url: '/chat',
            isChatItem: true,
          },
        ]
      : []),
    {
      id: uuid(),
      text: t('Menu:MAIN.ADMIN'),
      icon: Settings,
      doNotRenderChildren: true,
      hideIfNoPermissions: true,
      children: getMenu(AdminMenu),
    },
    {
      id: uuid(),
      text: t('Menu:MAIN.ANALYTICS_AND_REPORTS'),
      icon: Analytics,
      permissionArea: PermissionArea.REPORTING,
      hideIfNoPermissions: true,
      doNotRenderChildren: true,
      children: getMenu(
        currentBusiness?.omniChannel && isOmnichannelAnalyticsMenuEnabled
          ? omnichannelAnalyticsMenu
          : generalAnalyticsMenu,
      ),
    },
    {
      id: uuid(),
      text: t('Menu:MAIN.TUTORIALS_AND_FAQS'),
      icon: Question,
      Component: LeftMenuSupportButton,
    },
    {
      id: uuid(),
      text: t('Menu:MAIN.CHAT_WITH_SUPPORT'),
      icon: Chat,
      Component: LeftMenuHelpButton,
    },
    ...(isRCIntegrationEnabled && !isRCPhase2IntegrationEnabled
      ? [
          {
            id: uuid(),
            text: t('Menu:MAIN.ROCKET_CHAT'),
            icon: RocketChat,
            url: '/chat',
            isChatItem: true,
          },
        ]
      : []),
  ]

  return getMenu(MainMenu)
}

export default useLeftMenuConfig
