import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

interface EntitiesStatusLogProps {
  entitiesStatus: {
    done: boolean
    id: string
  }[]
}

const EntitiesStatusLog = ({ entitiesStatus }: EntitiesStatusLogProps) => {
  const { t } = useTranslation('Businesses')
  return (
    <>
      {entitiesStatus.map((entity) => {
        const { id, done } = entity
        return (
          <Text key={id} variant="lowAccent">
            {t('Businesses:MIGRATION.DONE_RECORDS', {
              done,
              name: LanguageUtils.getTranslatedFieldName(entity),
            })}
          </Text>
        )
      })}
    </>
  )
}

export default EntitiesStatusLog
