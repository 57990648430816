/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, StateLabel, Utils } from '@pbt/pbt-ui-components'
import {
  Environment,
  Region,
} from '@pbt/pbt-ui-components/src/constants/environment'
import { SupportedLngCodesList } from '@pbt/pbt-ui-components/src/localization'

import i18nPortal from '~/locales/i18n'
import { updateCurrentUserLanguage } from '~/store/duck/userSettings'
import { getCurrentUserId } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.max,
      position: 'fixed',
      top: 0,
      right: 0,
      cursor: 'pointer',
      opacity: 0.8,
    },
    button: {
      padding: theme.spacing(0, 1),
      minWidth: 0,
      textTransform: 'none',
    },
    devEnvLabel: {},
  }),
  { name: 'WindowVariableInjector' },
)

const devEnvs = [Environment.STAGE, Environment.PROD, Environment.MASTER]

const devRegions = [Region.US, Region.EU]

const setDevEnvironment = (env: Environment) => {
  if (devEnvs.includes(env)) {
    localStorage.setItem('devEnv', env)
    localStorage.removeItem('state')
    window.location.reload()
  } else {
    console.error(
      `Invalid dev environment ${env}, allowed options are: ${devEnvs.join(
        ', ',
      )}`,
    )
  }
}

export const getDevEnvironment = () =>
  (localStorage.getItem('devEnv') as Environment) || Environment.STAGE

const setDevRegion = (region: Region) => {
  if (devRegions.includes(region)) {
    localStorage.setItem('devRegion', region)
    window.location.reload()
  } else {
    console.error(
      `Invalid dev region ${region}, allowed options are: ${devRegions.join(
        ', ',
      )}`,
    )
  }
}

const getDevRegion = () => localStorage.getItem('devRegion') || Region.US

const getLastBusinessId = () => {
  const state = localStorage.getItem('state')
  const id = JSON.parse(state as string)?.journal?.lastBusiness?.id
  return id
}

const getBusinessesForFT = (FTname: string) => {
  const valueFromStorage = localStorage.getItem(
    'devFeatureTogglesBusinessesIds',
  )
  const value = valueFromStorage ? JSON.parse(valueFromStorage) : {}

  return FTname ? value[FTname] : []
}

const getFTValue = (name?: string) => {
  const valueFromStorage = localStorage.getItem('devFeatureToggles')
  const value = valueFromStorage ? JSON.parse(valueFromStorage) || {} : {}
  const lastBusiness = getLastBusinessId()
  const FTBusinessIds = getBusinessesForFT(name as string)
  const isFtEnabledForThisBusiness = FTBusinessIds?.includes(lastBusiness)
  return name ? isFtEnabledForThisBusiness || value[name] : value
}

const setFTValue = (name: string, value: any) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans are allowed`)
    return
  }

  const data = {
    ...getFTValue(),
    [name]: value,
  }

  localStorage.setItem('devFeatureToggles', JSON.stringify(data))
  window.location.reload()
}

const clearFTValues = () => {
  localStorage.removeItem('devFeatureToggles')
  window.location.reload()
}

const setDebugI18n = (value: any) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans are allowed`)
    return
  }

  // @ts-ignore
  localStorage.setItem('enabledI18nDebug', value)
  window.location.reload()
}

const setDebugCKEditor = (value: any) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans are allowed`)
    return
  }

  // @ts-ignore
  localStorage.setItem('enabledCKEditorDebug', value)
  window.location.reload()
}

const getBusinessesForFTMap = () => {
  const valueFromStorage = localStorage.getItem(
    'devFeatureTogglesBusinessesIds',
  )
  const value = valueFromStorage ? JSON.parse(valueFromStorage) : {}

  return value || {}
}

const setBusinessesForFT = (FTname: string, businessIdsArray: string[]) => {
  const data = {
    ...getBusinessesForFTMap(),
    [FTname]: businessIdsArray,
  }

  localStorage.setItem('devFeatureTogglesBusinessesIds', JSON.stringify(data))
  window.location.reload()
}

const clearBusinessesForFT = () => {
  localStorage.removeItem('devFeatureTogglesBusinessesIds')
  window.location.reload()
}

const setEasterEggOdds = (value: any) => {
  if (typeof value !== 'number') {
    console.error(`Invalid value ${value}, only numbers are allowed`)
    return
  }

  localStorage.setItem('devEasterEggOdds', JSON.stringify(value))
  window.location.reload()
}

const getEasterEggOdds = () => {
  const valueFromStorage = localStorage.getItem('devEasterEggOdds')
  const value = valueFromStorage ? JSON.parse(valueFromStorage) : undefined

  return value
}

const setAuth0DebugDisabled = (value: any) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans are allowed`)
    return
  }

  // @ts-ignore
  localStorage.setItem('auth0DebugDisabled', value)
  window.location.reload()
}

const getAuth0DebugDisabled = () => {
  const valueFromStorage = localStorage.getItem('auth0DebugDisabled')
  const value = valueFromStorage ? JSON.parse(valueFromStorage) : undefined

  return value
}

const setLocalPIMSDebugEnabled = (value: any) => {
  if (typeof value !== 'boolean') {
    console.error(`Invalid value ${value}, only booleans are allowed`)
    return
  }

  // @ts-ignore
  localStorage.setItem('localPIMSDebugEnabled', value)
  window.location.reload()
}

const getLocalPIMSDebugEnabled = () => {
  const valueFromStorage = localStorage.getItem('localPIMSDebugEnabled')
  const value = valueFromStorage ? JSON.parse(valueFromStorage) : undefined

  return value
}

const WindowVariableInjector = () => {
  const classes = useStyles()
  const userId = useSelector(getCurrentUserId)
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)

  const setLanguage = (value: string) => {
    if (userId) {
      dispatch(updateCurrentUserLanguage(userId, value))
    }
    LanguageUtils.updateLanguage({
      languageValue: value,
      i18n: i18nPortal,
      SupportedLngsValuesList: SupportedLngCodesList,
    })
  }

  useEffect(() => {
    window.pbt = {
      version: process.env.REACT_APP_SENTRY_RELEASE || '0.0.0',
      getDevEnv: Utils.isProduction() ? undefined : getDevEnvironment,
      setDevEnv: Utils.isProduction() ? undefined : setDevEnvironment,
      getDevRegion: Utils.isProduction() ? undefined : getDevRegion,
      setDevRegion: Utils.isProduction() ? undefined : setDevRegion,
      setFTValue:
        Utils.isProduction() && !Utils.isPlayEnvironment()
          ? undefined
          : setFTValue,
      getFTValue:
        Utils.isProduction() && !Utils.isPlayEnvironment()
          ? undefined
          : getFTValue,
      clearFTValues:
        Utils.isProduction() && !Utils.isPlayEnvironment()
          ? undefined
          : clearFTValues,
      setLanguage: Utils.isProduction() ? undefined : setLanguage,
      setDebugI18n: Utils.isProduction() ? undefined : setDebugI18n,
      setDebugCKEditor: Utils.isProduction() ? undefined : setDebugCKEditor,
      getBusinessesForFT:
        Utils.isProduction() && !Utils.isPlayEnvironment()
          ? undefined
          : getBusinessesForFT,
      setBusinessesForFT:
        Utils.isProduction() && !Utils.isPlayEnvironment()
          ? undefined
          : setBusinessesForFT,
      clearBusinessesForFT:
        Utils.isProduction() && !Utils.isPlayEnvironment()
          ? undefined
          : clearBusinessesForFT,
      setEasterEggOdds: Utils.isProduction() ? undefined : setEasterEggOdds,
      getEasterEggOdds: Utils.isProduction() ? undefined : getEasterEggOdds,
      setAuth0DebugDisabled: Utils.isProduction()
        ? undefined
        : setAuth0DebugDisabled,
      getAuth0DebugDisabled: Utils.isProduction()
        ? undefined
        : getAuth0DebugDisabled,
      setLocalPIMSDebugEnabled: Utils.isProduction()
        ? undefined
        : setLocalPIMSDebugEnabled,
      getLocalPIMSDebugEnabled: Utils.isProduction()
        ? undefined
        : getLocalPIMSDebugEnabled,
    }
  }, [])

  if (Utils.isProduction()) {
    return null
  }

  const devEnv = getDevEnvironment()
  const devRegion = getDevRegion()
  const devFeatureToggles = getFTValue()
  const devFeatureTogglesBusinessIds = getBusinessesForFTMap()
  const auth0DebugDisabled = getAuth0DebugDisabled()
  const localPIMSDebugEnabled = getLocalPIMSDebugEnabled()

  const showDevLabel = devEnv !== Environment.STAGE
  const showDevRegion = devRegion !== Region.US

  return (
    <Grid
      container
      item
      alignItems="flex-end"
      className={classes.root}
      direction="column"
      width="auto"
    >
      {collapsed && (
        <Button
          className={classes.button}
          size="small"
          variant="contained"
          onClick={() => setCollapsed(false)}
        >
          Dev vars
        </Button>
      )}
      <Collapse in={!collapsed} onClick={() => setCollapsed(true)}>
        <>
          {auth0DebugDisabled && (
            <StateLabel warning className={classes.devEnvLabel}>
              Auth0 disabled debug
            </StateLabel>
          )}
          {localPIMSDebugEnabled && (
            <StateLabel warning className={classes.devEnvLabel}>
              PIMS debug
            </StateLabel>
          )}
          {showDevLabel && (
            <StateLabel
              className={classes.devEnvLabel}
              warning={devEnv === Environment.PROD}
            >
              {devEnv}
            </StateLabel>
          )}
          {showDevRegion && (
            <StateLabel
              className={classes.devEnvLabel}
              warning={devRegion !== Region.US}
            >
              {`${devRegion} region`}
            </StateLabel>
          )}
          {Object.keys(devFeatureToggles).map((key) => (
            <StateLabel warning className={classes.devEnvLabel} key={key}>
              {`${key}: ${devFeatureToggles[key]}`}
            </StateLabel>
          ))}
          {Object.keys(devFeatureTogglesBusinessIds).map((key) => (
            <StateLabel completed className={classes.devEnvLabel} key={key}>
              {`${key}: ${devFeatureTogglesBusinessIds[key]}`}
            </StateLabel>
          ))}
        </>
      </Collapse>
    </Grid>
  )
}

export default WindowVariableInjector
