import i18n from '~/locales/i18n'

export enum PaymentTypes {
  CREDIT_CARD = 'CREDIT_CARD',
  PRE_AUTHORIZATION = 'PRE_AUTHORIZATION',
  OTHER = 'OTHER',
}

export enum RefundPaymentMethods {
  CARD = 'CARD',
  CASH = 'CASH',
  CHECK = 'CHECK',
  ON_ACCOUNT = 'ON_ACCOUNT',
}

export const ADJUSTMENT_TYPES = [
  {
    paymentType: 'Credit',
    label: i18n.t('Constants:FINANCE_CONSTANTS.ADJUSTMENT_TYPE_CREDIT'),
  },
  {
    paymentType: 'Debit',
    label: i18n.t('Constants:FINANCE_CONSTANTS.ADJUSTMENT_TYPE_DEBIT'),
  },
]

export const PAYMENT_TYPE_OPTIONS = [
  {
    type: PaymentTypes.CREDIT_CARD,
    label: i18n.t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.CREDIT_DEBIT_TYPE'),
  },
  { type: PaymentTypes.OTHER, label: i18n.t('Common:OTHER') },
]

export const RHAPSODY_PAY_RATE = 2.93
