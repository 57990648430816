import { gql } from '@apollo/client'

const SOAP_TEMPLATE_WIDGET_FIELDS = gql`
  fragment SoapTemplateWidgetFields on SoapTemplateWidget {
    active
    id
    soapWidgetId
    marketplaceId
    order
  }
`

export const SOAP_TEMPLATE_TAB_FIELDS = gql`
  ${SOAP_TEMPLATE_WIDGET_FIELDS}
  fragment SoapTemplateTabFields on SoapTemplateTab {
    id
    name
    order
    widgets {
      ...SoapTemplateWidgetFields
    }
  }
`
