import { ApiError, ConstantMap } from '@pbt/pbt-ui-components'

import {
  FETCH_CONSTANTS,
  FETCH_CONSTANTS_FAILURE,
  FETCH_CONSTANTS_SUCCESS,
  FETCH_SECURE_CONSTANTS_FAILURE,
  FETCH_SECURE_CONSTANTS_SUCCESS,
} from './types/constants'

export const fetchConstants = () => ({ type: FETCH_CONSTANTS })
export const fetchConstantsSuccess = (constants: ConstantMap) => ({
  type: FETCH_CONSTANTS_SUCCESS,
  constants,
})
export const fetchConstantsFailure = (error: ApiError) => ({
  type: FETCH_CONSTANTS_FAILURE,
  error,
})

export const fetchSecureConstantsSuccess = (constants: ConstantMap) => ({
  type: FETCH_SECURE_CONSTANTS_SUCCESS,
  constants,
})
export const fetchSecureConstantsFailure = (error: ApiError) => ({
  type: FETCH_SECURE_CONSTANTS_FAILURE,
  error,
})
