import { BlobWithName, Defaults } from '@pbt/pbt-ui-components'

import { objectToFormData } from '../utils'
import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const importShipment = (
  businessId: string,
  document: { csvFile: BlobWithName | undefined },
) =>
  request(
    getPreludeAPIPath('inventory-integration/shipment/upload'),
    {
      method: 'POST',
      data: objectToFormData(document),
      params: { businessId, fileTypeId: 'PL1J8Oa3' },
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    true,
  )

export const fetchShipmentImportItems = (
  businessId: string,
  sessionId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getPreludeAPIPath(
      `inventory-integration/shipment/upload/${sessionId}/lines`,
    ),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.shipmentImportItemList,
  )

export const matchShipmentImportItem = (
  businessId: string,
  sessionId: string,
  businessVariationId: string,
  lineItemId: string,
) =>
  request(
    getPreludeAPIPath(
      `inventory-integration/shipment/upload/${sessionId}/match`,
    ),
    { method: 'POST', params: { businessId, businessVariationId, lineItemId } },
    true,
  )

export const fetchPreliminaryShipment = (
  businessId: string,
  sessionId: string,
) =>
  request(
    getPreludeAPIPath(`inventory/shipment/upload/${sessionId}`),
    { method: 'GET', params: { businessId } },
    true,
  )
