import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CircularProgressOverlay,
  MarketingStylesButton,
  Text,
} from '@pbt/pbt-ui-components'

import LoginPage from '../common/auth/LoginPage'

const useStyles = makeStyles(
  () => ({
    loginButton: {
      width: 185,
    },
  }),
  { name: 'LogoutComponent' },
)

export type LogoutComponentLocationState = { doLogout: string } | null

const LogoutComponent = () => {
  const { search, state } = useLocation()
  const classes = useStyles()
  const { t } = useTranslation()

  const { loginWithRedirect, logout } = useAuth0()

  const searchParams = new URLSearchParams(search)
  const { doLogout } = (state as LogoutComponentLocationState) || {}

  const redirectTo = searchParams.get('redirectTo') || '/'
  const lockedUser = searchParams.get('lock') || ''

  useEffect(() => {
    if (doLogout) {
      // Auth0 logout flow should be initiated beyond withAuthenticationRequired context to avoid issues with race conditions with silent authentication
      // the topic - https://community.auth0.com/t/logout-triggering-silent-authentication-in-react-sdk/99197
      logout({
        logoutParams: {
          returnTo: doLogout,
        },
      })
    }
  }, [doLogout])

  if (lockedUser && !doLogout) {
    loginWithRedirect({
      authorizationParams: {
        login_hint: lockedUser,
      },
    })
  }

  if (doLogout || lockedUser) {
    return <CircularProgressOverlay open />
  }

  return (
    <LoginPage showTermsAndConditions WrapperProps={{ maxWidth: 400 }}>
      <Grid
        container
        alignItems="center"
        direction="column"
        justifyContent="center"
        py={9}
      >
        <Text mb={4.5} textAlign="center" variant="hero2">
          {t('Common:YOU_HAVE_BEEN_LOGGED_OUT')}
        </Text>
        <MarketingStylesButton
          className={classes.loginButton}
          onClick={() => {
            loginWithRedirect({
              appState: {
                returnTo: redirectTo,
              },
            })
          }}
        >
          {t('Common:LOG_IN_ACTION')}
        </MarketingStylesButton>
      </Grid>
    </LoginPage>
  )
}

export default LogoutComponent
