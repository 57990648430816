import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  Calendar,
  Defaults,
  moment,
  PuiTextField,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import PuiInformationPanel from '~/components/common/PuiInformationPanel'
import ReminderStateSelect from '~/components/common/selects/ReminderStateSelect'
import { getReminderTriggerLabel } from '~/components/dashboard/reminders/reminderUtils'
import { partialUpdateReminder } from '~/store/duck/reminders'
import { getEventState } from '~/store/reducers/constants'
import { Reminder, ReminderOrderListHandlers } from '~/types'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useFieldsChanged, {
  FieldCache,
  haveFieldsChanged,
} from '~/utils/useFieldsChanged'
import { useReminderDetailsFields } from '~/utils/useReminderDetailsFields'

import ReminderOrderList from '../list/ReminderOrderList'

const useStyles = makeStyles(
  (theme) => ({
    panel: {
      padding: theme.spacing(2, 1),
    },
  }),
  { name: 'RemindersInformationPanel' },
)

export interface RemindersInformationPanelProps
  extends ReminderOrderListHandlers {
  isLoading: boolean
  isOpened: boolean
  item: Reminder
  onClose: () => void
}

const RemindersInformationPanel = ({
  isOpened,
  onClose,
  isLoading,
  item,
  isCheckedItem,
  onUncheckItem,
  onCheckItem,
}: RemindersInformationPanelProps) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const { t } = useTranslation(['Common', 'Dialogs', 'Reminders'])

  const EventState = useSelector(getEventState)

  const { fields, reset, validate } = useReminderDetailsFields(item)
  const { stateId, notes, name, dueDatetime, expireDatetime } = fields

  const [fieldsToUpdate, setFieldsToUpdate] = useState<Record<string, any>>()

  useEffectExceptOnMount(() => {
    reset()
  }, [item])

  const debounceOnFieldsChange = useDebouncedCallback(() => {
    if (fieldsToUpdate) {
      dispatch(partialUpdateReminder(item.id, fieldsToUpdate))
      setFieldsToUpdate(undefined)
    }
  }, Defaults.DEBOUNCE_ACTION_TIME)

  useFieldsChanged((changedFields: FieldCache) => {
    const fieldsChanged = haveFieldsChanged(fields)

    if (!fieldsChanged || isLoading) {
      return
    }

    if (validate()) {
      const normalizedFields = R.mergeAll(
        changedFields.map(({ name: fieldName, value }) => {
          const openStateId = Utils.findConstantIdByName('Open', EventState)
          const overdueStateId = Utils.findConstantIdByName(
            'Overdue',
            EventState,
          )
          const isOverdueDate = moment(dueDatetime.value).isBefore(moment())

          if (
            fieldName === 'stateId' &&
            isOverdueDate &&
            value === openStateId
          ) {
            stateId.setValue(overdueStateId)
            return {
              [fieldName]: overdueStateId,
            }
          }

          return {
            [fieldName]: value,
          }
        }),
      )
      const inputData = R.mergeRight(fieldsToUpdate ?? {}, normalizedFields)
      setFieldsToUpdate(inputData)
      debounceOnFieldsChange()
    }
  }, fields)

  return (
    <PuiInformationPanel
      classes={{ informationPanel: classes.panel }}
      isLoading={isLoading}
      isOpened={isOpened}
      onClose={onClose}
    >
      <Stack spacing={1}>
        <Text fontWeight={500} variant="subheading3">
          {t('Dialogs:REMINDER_DETAILS_DIALOG.TITLE')}
        </Text>
        <ReminderStateSelect disabled={isLoading} field={stateId} />
        <PuiTextField
          multiline
          disabled={isLoading}
          field={notes}
          margin="none"
          minRows={6}
          placeholder={notes.label}
          variant="outlined"
        />
      </Stack>
      <Stack spacing={2}>
        <PuiTextField
          disabled={isLoading}
          field={name}
          label={name.label}
          margin="none"
        />
        <Stack alignItems="center" direction="row" spacing={2}>
          <Calendar
            fullWidth
            disabled={isLoading}
            field={dueDatetime}
            label={dueDatetime.label}
          />
          <Calendar
            fullWidth
            disabled={isLoading}
            field={expireDatetime}
            label={expireDatetime.label}
          />
        </Stack>
        {item.triggeredBy && (
          <Box pt={1}>
            <Text variant="lowAccent3">
              {t('Reminders:REMINDER_DETAILS.TRIGGERED_BY')}
            </Text>
            <Text variant="body2">
              {getReminderTriggerLabel(item.triggeredBy)}
            </Text>
          </Box>
        )}
        {item.resolvedBy && (
          <Box>
            <Text variant="lowAccent3">
              {t('Reminders:REMINDER_DETAILS.RESOLVED_BY')}
            </Text>
            <Text variant="body2">
              {getReminderTriggerLabel(item.resolvedBy)}
            </Text>
          </Box>
        )}
        {!R.isEmpty(item.soapChargeOrderItems) && (
          <Box>
            <ReminderOrderList
              disabled={isLoading}
              isCheckedItem={isCheckedItem}
              reminder={item}
              title={
                <Text variant="lowAccent3">{t('Common:WHAT_RESOLVES_IT')}</Text>
              }
              onCheckItem={onCheckItem}
              onUncheckItem={onUncheckItem}
            />
          </Box>
        )}
      </Stack>
    </PuiInformationPanel>
  )
}

export default RemindersInformationPanel
