import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { BusinessRole } from '@pbt/pbt-ui-components'

import { getAssignedLocationIds } from '~/store/reducers/auth'

const useGetAssignedRoles = () => {
  const assignedLocationIds: string[] = useSelector(getAssignedLocationIds)

  return (roles: BusinessRole[]) =>
    roles.filter(({ business }) => R.includes(business, assignedLocationIds))
}

export default useGetAssignedRoles
