import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LockForm from '~/components/common/auth/LockForm'
import RegisterButton from '~/components/common/auth/RegisterButton'
import TermsAndCondition from '~/components/common/auth/TermsAndCondition'
import { loginRequest } from '~/store/actions/auth'
import { getAuthError, getAuthIsFetching } from '~/store/reducers/auth'
import {
  getJournalMap,
  getLastBusiness,
  getLastUserId,
} from '~/store/reducers/journal'

interface LockScreenComponentProps {
  showRegisterButton?: boolean
  showTermsAndConditions?: boolean
}

const LockScreenComponent = ({
  showRegisterButton = false,
  showTermsAndConditions = true,
}: LockScreenComponentProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isFetching = useSelector(getAuthIsFetching)
  const error = useSelector(getAuthError)
  const journal = useSelector(getJournalMap)
  const lastUserId = useSelector(getLastUserId) as string
  const lastBusiness = useSelector(getLastBusiness)

  const onNewEmailSubmit = (email: string) => {
    navigate('/auth/login', { state: { email } })
  }

  const onSubmit = (email: string, password: string) => {
    dispatch(loginRequest(email, password))
  }

  return (
    <>
      <LockForm
        error={error}
        isFetching={isFetching}
        journal={journal}
        lastBusiness={lastBusiness}
        lastUserId={lastUserId}
        onNewEmailSubmit={onNewEmailSubmit}
        onSubmit={onSubmit}
      />
      {showRegisterButton && <RegisterButton />}
      {showTermsAndConditions && <TermsAndCondition />}
    </>
  )
}

export default LockScreenComponent
