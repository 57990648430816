import React from 'react'
import { EventTypeName } from '@pbt/pbt-ui-components'

import CheckboxListFilter from '~/components/common/filters/CheckboxListFilter'
import { TableFilter } from '~/types'
import { useEventType } from '~/utils/useEventType'

export interface ReminderStatusFilterProps {
  anchorEl: any
  filterValue: string[]
  onChange: (option: TableFilter<string[]>) => void
  onClear?: () => void
  onClose: (event?: any) => void
  open: boolean
}

const ReminderStatusFilter = ({
  anchorEl,
  filterValue,
  open,
  onClear,
  onClose,
  onChange,
}: ReminderStatusFilterProps) => {
  const filters = useEventType(EventTypeName.Reminder, 'states')

  return (
    <CheckboxListFilter
      arrayFormat
      anchorEl={anchorEl}
      includeSearch={false}
      items={filters}
      open={open}
      value={filterValue}
      onChange={onChange}
      onClear={onClear}
      onClose={onClose}
    />
  )
}

export default ReminderStatusFilter
