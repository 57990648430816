import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
} from '@pbt/pbt-ui-components'

import Attachment from '~/components/common/inputs/attachment/Attachment'
import DialogNames from '~/constants/DialogNames'
import {
  deleteSessionFile,
  fetchSessionFiles,
  fetchSessionFileUrl,
  startPreprocessing,
} from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getIsLoading,
  getSessionFiles,
} from '~/store/reducers/migration'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      padding: theme.spacing(3),
    },
    container: {
      border: theme.constants.tableBorder,
      height: 320,
      overflow: 'auto',
      position: 'relative',
      padding: theme.spacing(1),
    },
    button: {
      height: 40,
      minWidth: 150,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
    attachment: {
      marginTop: theme.spacing(1),
      backgroundColor: theme.colors.tableOddRowBackground,
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    attachmentDisabled: {
      pointerEvents: 'none',
      opacity: 0.5,
    },
  }),
  { name: 'StepUploadAdditionalFiles' },
)

interface StepUploadAdditionalFilesProps {
  isUploader?: boolean
}

const StepUploadAdditionalFiles = ({
  isUploader,
}: StepUploadAdditionalFilesProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const [openUploadSessionFilesDialog] = useDialog(
    DialogNames.UPLOAD_SESSION_FILES,
  )

  const currentSessionId = useSelector(getCurrentSessionId)
  const isLoading = useSelector(getIsLoading)
  const sessionFiles = useSelector(getSessionFiles)

  useEffect(() => {
    if (currentSessionId) {
      dispatch(fetchSessionFiles(currentSessionId))
    }
  }, [currentSessionId])

  const handleDownload = (name: string, extension: string) => () => {
    dispatch(fetchSessionFileUrl(currentSessionId, name, extension))
  }

  const handleDelete = (name: string, extension: string) => () => {
    dispatch(deleteSessionFile(currentSessionId, name, extension))
  }

  const handleUpload = () => {
    openUploadSessionFilesDialog({
      sessionFiles,
      sessionId: currentSessionId,
    })
  }

  const handleProceed = () => {
    dispatch(startPreprocessing(currentSessionId))
  }

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.container} direction="column">
        <CircularProgressOverlay
          open={isLoading && !sessionFiles.length}
          preloaderText={t(
            'Businesses:MIGRATION.LOADING_AVAILABLE_SESSION_FILES',
          )}
        />
        {sessionFiles.map(({ name, extension, exists }) => (
          <Grid
            className={classNames(classes.attachment, {
              [classes.attachmentDisabled]: !exists,
            })}
            key={name}
          >
            <Attachment
              hidePreview
              file={{
                name: `${name}.${extension}`,
                extension,
              }}
              onDeleteRequested={handleDelete(name, extension)}
              onDownloadRequested={handleDownload(name, extension)}
            />
          </Grid>
        ))}
      </Grid>
      {!isUploader && (
        <ButtonWithLoader
          className={classes.button}
          disabled={isLoading}
          loading={Boolean(sessionFiles.length && isLoading)}
          onClick={handleProceed}
        >
          {t('Businesses:MIGRATION.START_PROCESSING')}
        </ButtonWithLoader>
      )}
      <Fab
        className={classes.button}
        color={isUploader ? 'inherit' : 'primary'}
        disabled={isLoading}
        variant="extended"
        onClick={handleUpload}
      >
        {t('Common:UPLOAD_FILES')}
      </Fab>
    </Grid>
  )
}

export default StepUploadAdditionalFiles
