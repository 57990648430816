import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WarningIcon from '@mui/icons-material/Warning'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Text } from '@pbt/pbt-ui-components'

import { getAppointmentId } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { useNavigatePrevState } from '~/utils/useNavigatePrevState'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 42,
      minWidth: 172,
    },
    noInvoice: {
      color: theme.colors.important,
      flexDirection: 'row',
    },
    link: {
      color: theme.colors.link,
    },
  }),
  { name: 'NoInvoiceCard' },
)

const NoInvoiceCard = () => {
  const { t } = useTranslation(['Common', 'Soap', 'Payments'])
  const classes = useStyles()
  const navigatePrevState = useNavigatePrevState()

  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const client = useSelector(getUser(appointment?.client))

  const openChargeSheet = () => navigatePrevState(`/chargesheet/${client?.id}`)

  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="space-between"
      px={1}
      py={2}
    >
      <Grid container item alignItems="center" gap={1.5} width="auto">
        <WarningIcon color="warning" />
        <Text inline strong className={classes.noInvoice} variant="body2">
          {t('Common:NO_INVOICE_CARD_TEXT')}
          <Text inline variant="body2">
            {t('Common:NO_INVOICE_CARD_CREATE_TEXT')}
            <Text
              inline
              link
              className={classes.link}
              variant="body2"
              onClick={openChargeSheet}
            >
              {t('Payments:CHARGES')}
            </Text>
          </Text>
        </Text>
      </Grid>
      <Grid container item width="auto">
        <ButtonWithLoader
          className={classes.button}
          color="important"
          type="submit"
          onClick={openChargeSheet}
        >
          {t('Common:VIEW_CHARGES')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default NoInvoiceCard
