import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import {
  CircularProgressOverlay,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  getCurrentSession,
  getImportSettings,
  getIsLoadingImportSessionDetails,
  updateMigrationSessionSettings,
} from '~/store/duck/migrationV2'
import { BaseStepComponentProps } from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const MAX_INPUT_LENGTH = 100

const SessionCovetrusConfig = ({
  stepController,
  onNext,
}: BaseStepComponentProps) => {
  const { t } = useTranslation(['Common', 'Businesses'])
  const dispatch = useDispatch()

  const currentSession = useSelector(getCurrentSession)
  const currentSessionSettings = useSelector(getImportSettings)
  const isLoading = useSelector(getIsLoadingImportSessionDetails)

  const {
    fields: { covetrusUser, covetrusPassword, covetrusInstallationId },
    validate,
  } = useFields(
    [
      {
        name: 'covetrusUser',
        label: t('Businesses:MIGRATION.COVETRUS_USER'),
        validators: ['required'],
        initialValue: currentSessionSettings?.covetrusUser,
      },
      {
        name: 'covetrusPassword',
        label: t('Businesses:MIGRATION.COVETRUS_PASSWORD'),
        validators: ['required'],
        initialValue: currentSessionSettings?.covetrusPassword,
      },
      {
        name: 'covetrusInstallationId',
        label: t('Businesses:MIGRATION.COVETRUS_INSTALLATION_ID'),
        validators: ['required'],
        initialValue: currentSessionSettings?.covetrusInstallationId,
      },
    ],
    false,
  )

  const handleProceed = () => {
    if (onNext) {
      onNext()
    } else {
      stepController.next()
    }
  }

  const handleSave = () => {
    if (!validate() || !currentSession || !currentSessionSettings) {
      return
    }

    dispatch(
      updateMigrationSessionSettings({
        sessionId: currentSession?.id,
        settings: {
          ...currentSessionSettings,
          covetrusInstallationId: covetrusInstallationId.value,
          covetrusUser: covetrusUser.value,
          covetrusPassword:
            covetrusPassword.value || currentSessionSettings.covetrusPassword,
          covetrusEnabled: true,
        },
      }),
    )

    handleProceed()
  }

  return (
    <MigrationStep>
      <MigrationStepContent>
        {isLoading ? (
          <CircularProgressOverlay
            open
            preloaderText={t('Businesses:MIGRATION.LOADING_CONFIG')}
          />
        ) : (
          <Grid container direction="row" p={1}>
            <Grid item md={6} px={2}>
              <PuiTextField
                autoComplete="off"
                field={covetrusUser}
                inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                label={covetrusUser.label}
              />
            </Grid>
            <Grid item md={6} px={2}>
              <PuiTextField
                autoComplete="off"
                field={covetrusPassword}
                inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                label={covetrusPassword.label}
                placeholder="∗∗∗∗∗∗∗∗∗∗∗∗∗"
                type="password"
              />
            </Grid>
            <Grid item md={12} px={2}>
              <PuiTextField
                autoComplete="off"
                field={covetrusInstallationId}
                inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                label={covetrusInstallationId.label}
              />
            </Grid>
          </Grid>
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:CANCEL_ACTION')}
        </MigrationStepActionButton>
        <MigrationStepActionButton onClick={handleSave}>
          {t('Common:SAVE_ACTION')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default SessionCovetrusConfig
