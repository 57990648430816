import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import ItemWithBadgesActions from '~/components/dashboard/soap/rail/summary/item-with-badges/ItemWithBadgesActions'
import { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import InvoiceType from '~/constants/InvoiceType'
import { saveInvoice } from '~/store/actions/finance'
import { unOrderBundleWithoutOrder } from '~/store/actions/orders'
import {
  deleteChargeSheetItems,
  editChargeSheetItem,
  getClientFinanceClientId,
} from '~/store/duck/clientFinanceData'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getSoapId } from '~/store/reducers/soap'
import { InvoiceLineItem, InvoiceOrEstimate, UnsavedInvoice } from '~/types'

export interface ChargeBundleItemButtonsActionsProps {
  disabled: boolean
  invoice: InvoiceOrEstimate | Nil
  item: InvoiceLineItem
  loading?: boolean
  onDelete: () => void
  onUpdateItem: (item: InvoiceLineItem) => void
}

const ChargeBundleItemButtonsActions = ({
  disabled,
  invoice,
  item,
  loading,
  onDelete: onDeleteProp,
  onUpdateItem,
}: ChargeBundleItemButtonsActionsProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('Soap')

  const soapId = useSelector(getSoapId)
  const modifierId = useSelector(getCurrentUserId)
  const clientId = useSelector(getClientFinanceClientId)
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_ORDER,
  })
  const onDeleteBundle = () => {
    if ((item.group && soapId) || item.items) {
      openConfirmAlert({
        message: t('Soap:SOAP_ORDERS.CONFIRM_ALERT_MESSAGE'),
        onConfirm: (proceed) => {
          if (proceed) {
            const { items } = item
            if (soapId && item.group) {
              dispatch(unOrderBundleWithoutOrder(item.group, soapId))
            } else if (items && clientId && modifierId) {
              const deleteItems = items.map((itm) => ({
                id: itm.id,
                expectedModificationDate: itm.modificationDate,
              }))
              dispatch(
                deleteChargeSheetItems({
                  items: deleteItems,
                  modifierId,
                  clientId,
                }),
              )
            }
            onDeleteProp()
          }
        },
      })
    }
  }

  // For now we will be using this endpoint to update the decline status
  const onDeclineBundle = () => {
    if (!invoice) {
      return
    }

    const newInvoice = {
      id: invoice.id,
      type: InvoiceType.INVOICE,
      clientId: invoice.client || invoice.clientId,
      patientId: invoice.patient || invoice.patientId,
      deletedItems: null,
      // We'll be passing just the minimal necessary payload to avoid any bugs
      groups: invoice.groups
        .map((group) => {
          if (group.soap?.id === soapId) {
            return {
              ...(group.soap && { soap: { id: group.soap.id } }),
              groupedItems: group.groupedItems
                .map((groupedItem) => {
                  if (groupedItem.group === item.group && groupedItem.items) {
                    return {
                      group: item.group,
                      declined: !groupedItem.declined,
                      items: groupedItem.items.map((childItem) => ({
                        id: childItem.id,
                        declined: !groupedItem.declined,
                      })),
                    }
                  }
                  return null
                })
                .filter(Boolean),
            }
          }
          return null
        })
        .filter(Boolean),
    } as unknown as UnsavedInvoice

    dispatch(saveInvoice(newInvoice, false))

    onUpdateItem({
      ...item,
      declined: !item.declined,
      items: item.items?.map((it) => ({
        ...it,
        declined: !item.declined,
      })),
    })
  }

  const onDeclineBundleNew = () => {
    dispatch(
      editChargeSheetItem({
        updateItemInput: (item?.items || []).map((inlineItem) => ({
          id: inlineItem.id,
          expectedModification: inlineItem.modificationDate,
          expectedSoapLogModification: inlineItem.soapLogModificationDate,
          declined: !item.declined,
        })),
      }),
    )
    onUpdateItem({
      ...item,
      declined: !item.declined,
      items: item.items?.map((it) => ({
        ...it,
        declined: !item.declined,
      })),
    })
  }

  return (
    <ItemWithBadgesActions
      hideDivider
      align="flex-start"
      declined={item.declined}
      editDisabled={disabled}
      loading={loading}
      size="large"
      onDecline={isChargeSheetEnabled ? onDeclineBundleNew : onDeclineBundle}
      onDelete={onDeleteBundle}
    />
  )
}

export default ChargeBundleItemButtonsActions
