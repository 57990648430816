import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Skeleton, Stack } from '@mui/material'
import { LanguageUtils, PermissionArea } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { getPrescriptionIsUpdating } from '~/store/duck/prescriptions'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getOrderIsCreating,
  getOrdersIsSending,
  getSelectedOrder,
} from '~/store/reducers/orders'
import { PrescriptionV2 } from '~/types'

import FinalizeOrdersRow from '../FinalizeOrdersRow'
import { FinalizeChewyActiveRxActions } from './FinalizeChewyActiveRxActions'

export interface FinalizeChewyActiveRxRowProps {
  context: 'Charge-sheet' | 'SOAP'
  prescription: PrescriptionV2
}

export const FinalizeChewyActiveRxRow = ({
  context,
  prescription,
}: FinalizeChewyActiveRxRowProps) => {
  const { t } = useTranslation('Common')

  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const prescriptionPermissions = useSelector(
    getCRUDByArea(PermissionArea.PRESCRIPTION),
  )
  const order = useSelector(
    getSelectedOrder(OrderType.PRESCRIPTION, prescription.id),
  )
  const isSendingOrder = useSelector(getOrdersIsSending)
  const isCreatingOrder = useSelector(getOrderIsCreating)
  const isUpdatingPrescription = useSelector(getPrescriptionIsUpdating)

  const isLoading = isSendingOrder || isCreatingOrder || isUpdatingPrescription
  const stateId = prescription.state?.id || order?.stateId
  const isSOAPContext = context === 'SOAP'
  const name = LanguageUtils.getTranslatedFieldName(
    prescription,
    'name',
    order?.name ?? '',
  )

  if (isLoading) {
    return (
      <Stack
        alignItems="center"
        direction="row"
        display="flex"
        px={2}
        role="status"
        spacing={8}
      >
        <Skeleton height={48} sx={{ minWidth: 480 }} width="100%" />
        <Skeleton height={15} width={40} />
        <Skeleton height={40} width={40} />
      </Stack>
    )
  }

  if (!stateId) {
    return null
  }

  return (
    <FinalizeOrdersRow
      actions={
        <FinalizeChewyActiveRxActions
          context={context}
          disabled={
            isSOAPContext
              ? !SOAPPermissions.update
              : !prescriptionPermissions.update
          }
          prescription={prescription}
          stateId={stateId}
        />
      }
      orderNameDetails={{
        orderName: `${t('Common:CHEWY_BRAND_NAME')}—${name}`,
      }}
      showWarning={false}
      stateId={stateId}
    />
  )
}
