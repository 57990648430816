import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { Grid, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'

import InvoicePostedLabel from '../../../InvoicePostedLabel'
import InvoiceStatusLabel from '../../../InvoiceStatusLabel'
import { CollapsibleColumnComponents } from '../../invoiceTableConstants'
import InvoiceItemGroups, { InvoiceItemGroupsProps } from '../InvoiceItemGroups'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    invoiceTitle: {
      userSelect: 'none',
      minWidth: 270,
    },
    fullRowCell: {
      padding: theme.spacing(1),
    },
    tableCell: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      '& > *': {
        verticalAlign: 'middle',
      },
      paddingTop: 2,
      paddingBottom: 2,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'InvoiceCollapsibleRow' },
)

export interface InvoiceCollapsibleRowProps
  extends Omit<InvoiceItemGroupsProps, 'showSummary'> {
  isExpanded: boolean
  toggleExpand: (invoiceId: string) => void
}

const InvoiceCollapsibleRow = ({
  invoice,
  selectedColumns,
  isExpanded,
  toggleExpand,
  isReadOnly,
  onUnblockEditInvoice,
  ...rest
}: InvoiceCollapsibleRowProps) => {
  const classes = useStyles()
  const patient = useSelector(getPatient(invoice.patient))
  const { t } = useTranslation('Common')

  const isPosted = invoice.posted

  return (
    <React.Fragment key={invoice.id}>
      <TableRow
        className={classes.root}
        onClick={() => toggleExpand(invoice.id)}
      >
        <TableCell className={classes.fullRowCell}>
          <Grid container item alignItems="center" wrap="nowrap">
            {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            <Text strong className={classes.invoiceTitle} variant="subheading2">
              {`${t('Common:INVOICE')} ${invoice.invoiceNo}: ${patient?.name}`}
            </Text>
            <InvoiceStatusLabel invoice={invoice} />
            <InvoicePostedLabel invoice={invoice} />
          </Grid>
        </TableCell>
        {selectedColumns.map((column) => {
          const CellComponent = CollapsibleColumnComponents[column]

          if (!CellComponent) {
            return <TableCell key={column} />
          }

          return (
            <CellComponent
              className={classes.tableCell}
              invoice={invoice}
              isExpanded={isExpanded}
              key={column}
              {...rest}
            />
          )
        })}
      </TableRow>
      {isExpanded && (
        <InvoiceItemGroups
          showSummary
          invoice={invoice}
          isReadOnly={isReadOnly || isPosted}
          selectedColumns={selectedColumns}
          onUnblockEditInvoice={onUnblockEditInvoice}
          {...rest}
        />
      )}
    </React.Fragment>
  )
}

export default InvoiceCollapsibleRow
