import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ImageScalingUtils } from '@pbt/pbt-ui-components'
import { DefaultAvatar } from '@pbt/pbt-ui-components/src/icons'

import Avatar from '~/components/common/Avatar'
import { getCurrentUser } from '~/store/reducers/auth'

const AVATAR_SIZE = 108

const useStyles = makeStyles(
  () => ({
    profilePicContainer: {
      position: 'absolute',
      bottom: -40,
    },
    profilePic: {
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
    },
    defaultAvatar: {
      fontSize: '7.4rem',
    },
  }),
  { name: 'ProfileAvatarHeader' },
)

export interface ProfileAvatarHeaderProps {
  onAvatarEditClick: () => void
}

const ProfileAvatarHeader = ({
  onAvatarEditClick,
}: ProfileAvatarHeaderProps) => {
  const classes = useStyles()
  const user = useSelector(getCurrentUser)

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    user.avatar,
    user.avatarThumbnail,
    AVATAR_SIZE,
  )

  return (
    <Grid item className={classes.profilePicContainer}>
      <Avatar
        className={classes.profilePic}
        person={user}
        size="normal"
        onEditClick={onAvatarEditClick}
      >
        {avatarSrc ? null : <DefaultAvatar className={classes.defaultAvatar} />}
      </Avatar>
    </Grid>
  )
}

export default ProfileAvatarHeader
