import React from 'react'
import { Trans } from 'react-i18next'

import Link from '~/components/common/link/Link'

const Bundles = () => (
  <Trans
    components={{ linkToBundle: <Link to="/admin/catalog/bundles" /> }}
    i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.BUNDLES.TEXT"
  />
)
export default Bundles
