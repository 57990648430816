import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  ArrowForward as ArrowForwardIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  FileTemplate,
  PuiSelect,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import AttachmentUploadIcon from '~/components/common/inputs/attachment/attachment-dialog/AttachmentUploadIcon'
import FileInput from '~/components/common/inputs/file-input/FileInput'
import {
  getCurrentSession,
  getSessionFiles,
  uploadMigrationSessionFile,
} from '~/store/duck/migrationV2'
import {
  BaseStepComponentProps,
  MigrationSessionFile,
} from '~/types/entities/migrationV2'

import MigrationStep from '../core-migration/migration-step/MigrationStep'
import MigrationStepActionButton from '../core-migration/migration-step/MigrationStepActionButton'
import MigrationStepActions from '../core-migration/migration-step/MigrationStepActions'
import MigrationStepContent from '../core-migration/migration-step/MigrationStepContent'

const useStyles = makeStyles(
  () => ({
    logoArea: {
      height: 320,
      border: 'none',
    },
    arrowLogo: {
      margin: '0px 16px',
    },
  }),
  { name: 'SessionFilesUpload' },
)

const toFileNamedEntity = ({ name, extension }: MigrationSessionFile) => ({
  id: name,
  name,
  nameTranslation: `${name}.${extension}`,
})

const SessionFilesUpload = ({
  stepController,
  onNext,
}: BaseStepComponentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const [selectedFile, setSelectedFile] = useState<FileTemplate | null>(null)

  const currentSession = useSelector(getCurrentSession)
  const sessionFiles = useSelector(getSessionFiles)

  // Valid file names based on selected file extension
  const validFileNames = R.pipe(
    R.filter(
      R.either(
        R.always(!selectedFile),
        R.propEq('extension', selectedFile?.extension),
      ),
    ),
    R.map(toFileNamedEntity),
  )(sessionFiles)

  // Unique array of possible extensions
  const validExtensions = R.pipe(R.pluck('extension'), R.uniq)(sessionFiles)
  // Valid extensions formatted for file input param
  const fileInputAccept = R.pipe(
    R.map(R.concat('.')),
    R.join(','),
  )(validExtensions)
  // Is selected file extension valid
  const isValidFileExtension = Boolean(
    validExtensions.find((extension) => extension === selectedFile?.extension),
  )

  const {
    fields: { selectedFileName },
  } = useFields(
    [
      {
        name: 'selectedFileName',
        initialValue: validFileNames[0]?.name,
        type: 'select',
        validators: [
          {
            validator: () => isValidFileExtension,
            validatorName: 'validSelectedFile',
          },
        ],
      },
    ],
    false,
  )

  const handleProceed = () => {
    if (onNext) {
      onNext()
      return
    }
    stepController.next()
  }

  const handleUpload = () => {
    if (!selectedFile || !currentSession) {
      return
    }
    // Upload new file
    dispatch(
      uploadMigrationSessionFile({
        sessionFile: {
          ...(selectedFile as MigrationSessionFile),
          name: selectedFileName.value,
        },
        sessionId: currentSession?.id,
      }),
    )
    handleProceed()
  }

  const clearSelectedFile = () => {
    setSelectedFile(null)
  }

  return (
    <MigrationStep>
      <MigrationStepContent direction="column">
        {selectedFile ? (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            p={2}
          >
            <Box
              alignItems="center"
              display="flex"
              flex={0.5}
              flexDirection="row"
            >
              <Text>{`${selectedFile.name}.${selectedFile.extension}`}</Text>
              <ArrowForwardIcon className={classes.arrowLogo} />
              <PuiSelect
                fullWidth
                field={selectedFileName}
                items={validFileNames}
              />
            </Box>
            <IconButton onClick={clearSelectedFile}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <FileInput
            forceButtonsHidden
            Icon={AttachmentUploadIcon}
            accept={fileInputAccept}
            classes={{
              logoArea: classes.logoArea,
            }}
            text={t('Businesses:MIGRATION.CLICK_HERE_TO_UPLOAD_FILE')}
            uploadButtonColor="primary"
            onFileSelected={setSelectedFile}
          />
        )}
      </MigrationStepContent>
      <MigrationStepActions>
        <MigrationStepActionButton onClick={handleProceed}>
          {t('Common:CANCEL_ACTION')}
        </MigrationStepActionButton>
        <MigrationStepActionButton
          disabled={!selectedFile || !isValidFileExtension}
          onClick={handleUpload}
        >
          {t('Common:SAVE_ACTION')}
        </MigrationStepActionButton>
      </MigrationStepActions>
    </MigrationStep>
  )
}

export default SessionFilesUpload
