import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Report as ReportIcon,
  Verified as VerifiedIcon,
  Warning as WarningIcon,
} from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { PuiTooltip, Text } from '@pbt/pbt-ui-components'
import {
  CustomEmailValidationType,
  EmailValidationType,
} from '@pbt/pbt-ui-components/src/constants/users'

import {
  partialUpdateClient,
  sendClientEmailVerification,
} from '~/store/actions/clients'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import {
  getClientIsEmailVerifying,
  getClientIsLoading,
} from '~/store/reducers/clients'
import { getUser } from '~/store/reducers/users'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      display: 'block',
    },
    emailVerificationIcon: {
      marginTop: theme.spacing(-0.25),
    },
    emailReportIcon: {
      color: theme.colors.alertLabelError,
    },
    emailWarningIcon: {
      color: theme.colors.alertWarning,
    },
    emailVerifiedIcon: {
      color: theme.colors.success,
    },
    suggestionButton: {
      display: 'inline',
    },
  }),
  { name: 'EmailVerificationAlert' },
)

interface EmailVerificationAlertProps {
  clientId: string
}

const EmailVerificationAlert = ({ clientId }: EmailVerificationAlertProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Clients')
  const dispatch = useDispatch()

  const [emailVerificationSent, setEmailVerificationSent] = useState(false)

  const client = useSelector(getUser(clientId))
  const isVerifying = useSelector(getClientIsEmailVerifying)
  const isLoading = useSelector(getClientIsLoading)
  const isOmnichannelBusiness = useSelector(getCurrentBusinessIsOmniChannel)

  useEffect(() => {
    setEmailVerificationSent(false)
  }, [client])

  const handleEmailVerificationSent = () => {
    setEmailVerificationSent(true)
  }

  const setCallbackOnEmailVerificationSent = useCloseAfterCreation(
    handleEmailVerificationSent,
    getClientIsEmailVerifying,
  )

  if (!client?.email || isOmnichannelBusiness) {
    return null
  }

  if (isLoading || isVerifying) {
    return (
      <CircularProgress className={classes.emailVerificationIcon} size={20} />
    )
  }

  const isValidType =
    client.emailValidationResultType === EmailValidationType.VALID
  const isUnverifiedByUserType = isValidType && !client.emailVerificationDate

  const validationType = isUnverifiedByUserType
    ? CustomEmailValidationType.UNVERIFIED_BY_USER
    : client.emailValidationSuggestion
      ? CustomEmailValidationType.SUGGESTION
      : client.emailValidationResultType

  const handleResendEmailVerification = (
    event: React.MouseEvent<HTMLSpanElement>,
  ) => {
    event.stopPropagation()
    setCallbackOnEmailVerificationSent()
    dispatch(sendClientEmailVerification(clientId))
  }

  const handleUpdateAndValidateEmail = (
    event: React.MouseEvent<HTMLSpanElement>,
  ) => {
    event.stopPropagation()
    const newEmail = client.emailValidationSuggestion || client.email
    if (newEmail) {
      dispatch(partialUpdateClient({ id: clientId, email: newEmail }))
    }
  }

  const validationTypeProps = {
    [EmailValidationType.VALID]: {
      message: t('Clients:EMAIL_VERIFICATION.EMAIL_VERIFIED'),
      Icon: VerifiedIcon,
      className: classes.emailVerifiedIcon,
    },
    [EmailValidationType.INVALID]: {
      message: t('Clients:EMAIL_VERIFICATION.EMAIL_INVALID'),
      Icon: ReportIcon,
      className: classes.emailReportIcon,
    },
    [EmailValidationType.WARNING]: {
      message: t('Clients:EMAIL_VERIFICATION.EMAIL_WARNING'),
      Icon: ReportIcon,
      className: classes.emailReportIcon,
    },
    [CustomEmailValidationType.SUSPICIOUS]: {
      message: t('Clients:EMAIL_VERIFICATION.SUSPICIOUS_EMAIL'),
      Icon: ReportIcon,
      className: classes.emailReportIcon,
    },
    [CustomEmailValidationType.SUGGESTION]: {
      message: (
        <Trans
          components={{
            innerLink: (
              <Text
                link
                className={classes.suggestionButton}
                fontSize="1.4rem"
                onClick={handleUpdateAndValidateEmail}
              />
            ),
          }}
          i18nKey="Clients:EMAIL_VERIFICATION.DID_YOU_MEAN_EMAIL"
          values={{
            suggestedEmail: client.emailValidationSuggestion,
            email: client.email,
          }}
        />
      ),
      Icon: ReportIcon,
      className: classes.emailReportIcon,
    },
    [CustomEmailValidationType.UNVERIFIED_BY_USER]: {
      message: emailVerificationSent ? (
        t('Clients:EMAIL_VERIFICATION.EMAIL_VERIFICATION_SENT')
      ) : (
        <>
          <Text variant="body2">
            {t('Clients:EMAIL_VERIFICATION.EMAIL_PENDING_VERIFICATION')}
          </Text>
          <Text link fontSize="1.4rem" onClick={handleResendEmailVerification}>
            {t('Clients:EMAIL_VERIFICATION.RE_SEND_VERIFICATION_EMAIL')}
          </Text>
        </>
      ),
      Icon: WarningIcon,
      className: classes.emailWarningIcon,
    },
    [CustomEmailValidationType.ERROR]: {
      message: t('Clients:EMAIL_VERIFICATION.EMAIL_VERIFICATION_ERROR'),
      Icon: ReportIcon,
      className: classes.emailReportIcon,
    },
  }

  const validationProps = validationType
    ? validationTypeProps[validationType]
    : {
        message: (
          <>
            <Text variant="body2">
              {t('Clients:EMAIL_VERIFICATION.EMAIL_WAS_NOT_VALIDATED')}
            </Text>
            <Text link fontSize="1.4rem" onClick={handleUpdateAndValidateEmail}>
              {t('Clients:EMAIL_VERIFICATION.VALIDATE_EMAIL')}
            </Text>
          </>
        ),
        Icon: WarningIcon,
        className: classes.emailWarningIcon,
      }

  const AlertIcon = validationProps.Icon

  return (
    <PuiTooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      tooltipText={validationProps.message}
    >
      <AlertIcon
        className={classNames(
          classes.emailVerificationIcon,
          validationProps.className,
        )}
        fontSize="small"
      />
    </PuiTooltip>
  )
}

export default EmailVerificationAlert
