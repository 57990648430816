import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, PermissionArea, PuiSelect } from '@pbt/pbt-ui-components'

import {
  fetchDepartments,
  getDepartmentsIsFetching,
  getDepartmentsList,
  getMultipleDepartments,
} from '~/store/duck/departments'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'

const useStyles = makeStyles(
  () => ({
    root: {
      width: 315,
    },
  }),
  { name: 'TableSelect' },
)

interface MemberDepartmentSectionProps {
  departmentId: Field
}

const MemberDepartmentSection = ({
  departmentId,
}: MemberDepartmentSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const departmentsList = useSelector(getDepartmentsList)
  const departments = useSelector(getMultipleDepartments(departmentsList))
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const isFetching = useSelector(getDepartmentsIsFetching)

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchDepartments(currentBusinessId, false))
    }
  }, [currentBusinessId])

  if (isFetching) {
    return <CircularProgress color="primary" size={32} />
  }

  return (
    <PuiSelect
      fullWidth
      className={classes.root}
      disabled={!personPermissions.update}
      field={departmentId}
      input={<Input id="user-department-select" />}
      items={departments}
      placeholder={t('Common:THERE_IS_NO_ASSIGNED_DEPARTMENT')}
    />
  )
}

export default MemberDepartmentSection
