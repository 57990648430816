import * as R from 'ramda'

const REPLACEMENTS_FOR_HTML: Record<string, string> = {
  '<': '&lt;',
  '>': '&gt;',
  '\n': '<br/>',
}

export const normalizeTextForHtml = (text = '') =>
  R.reduce(
    (str: string, keyToReplace: string) =>
      str.replace(
        new RegExp(keyToReplace, 'g'),
        REPLACEMENTS_FOR_HTML[keyToReplace],
      ),
    text,
    Object.keys(REPLACEMENTS_FOR_HTML),
  )

export const replaceWithTargetPlaceholder = (
  message: string,
  uiPlaceholders: Record<string, string>,
  targetBackendPlaceholder: string,
) => {
  const placeholdersToReplace = [
    ...Object.values(uiPlaceholders).map((placeholder) => `<<${placeholder}>>`),
    ...Object.values(uiPlaceholders).map(
      (placeholder) => `&lt;&lt;${placeholder}&gt;&gt;`,
    ),
  ]
  const placeholderRegexString = placeholdersToReplace.join('|')
  return message.replace(
    new RegExp(placeholderRegexString, 'g'),
    targetBackendPlaceholder,
  )
}
