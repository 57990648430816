import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import { editTasks } from '~/store/actions/tasks'
import { getTasksIsLoading } from '~/store/reducers/tasks'
import { Task } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

// @ts-ignore
import MultipleTasksTable from './MultipleTasksTable'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
      overflowY: 'visible',
    },
    dialogContentRoot: {
      padding: theme.spacing(3),
    },
  }),
  { name: 'ReassignTasksDialog' },
)

interface AssignTasksDialogProps extends BasePuiDialogProps {
  tasks: Task[]
}

const AssignTasksDialog = ({
  tasks,
  open,
  onClose,
}: AssignTasksDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const setCloseAfterEditOn = useCloseAfterCreation(onClose, getTasksIsLoading)

  const onProceed = (tasksToEdit: Task[]) => {
    setCloseAfterEditOn()
    dispatch(editTasks(tasksToEdit))
  }

  const isReassign = tasks.every((item) => item?.assigned)
  const actionText = isReassign
    ? t('Dialogs:ASSIGN_TASKS_DIALOG.TITLE_RE-ASSIGN_TASKS')
    : t('Dialogs:ASSIGN_TASKS_DIALOG.TITLE_ASSIGN_TASKS')

  return (
    <PuiDialog
      aria-labelledby="reassign-tasks-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={actionText}
      onClose={onClose}
    >
      <MultipleTasksTable
        proceedButtonLabel={actionText}
        showDueDate={false}
        showDueTime={false}
        showRepeat={false}
        showType={false}
        tasks={tasks}
        onProceed={onProceed}
      />
    </PuiDialog>
  )
}

export default AssignTasksDialog
