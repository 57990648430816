import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import {
  Constant,
  LanguageUtils,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import ScrollableDataTable from '~/components/common/lists/ScrollableDataTable'
import PuiSwitch from '~/components/common/PuiSwitch'
import i18n from '~/locales/i18n'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getCommunicationTransportBackupOption } from '~/store/reducers/constants'
import { ReminderTemplate } from '~/types'

import ReminderSetUpDueDateOffsetCell from './ReminderSetUpDueDateOffsetCell'

const headerRow = [
  { label: i18n.t('Common:SEND_AS') },
  { label: i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.PRIMARY_BACK_UP') },
  { label: i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.SECONDARY_BACK_UP') },
  { label: i18n.t('Businesses:APPOINTMENT_COMMUNICATIONS.TERTIARY_BACK_UP') },
]

interface ReminderTemplatesTableProps {
  onChangeActive: (index: number, isActive: boolean) => void
  onDelete: (index: number) => void
  onEdit: (template: ReminderTemplate, index: number) => void
  onPreview: (template: ReminderTemplate) => void
  templates: ReminderTemplate[]
}

const ExtendedReminderTemplatesTable = ({
  templates,
  onChangeActive,
  onEdit,
  onPreview,
  onDelete,
}: ReminderTemplatesTableProps) => {
  const { t } = useTranslation('Common')

  const CommunicationTransportBackupOption: Constant[] = useSelector(
    getCommunicationTransportBackupOption,
  )
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )

  if (!templates?.length) {
    return null
  }

  const mainColumns = [
    {
      label: t('Common:ON'),
      // eslint-disable-next-line react/no-unstable-nested-components
      prop: (item: ReminderTemplate, index: number) => (
        <PuiSwitch
          checked={item.active}
          onChange={() => onChangeActive(index, !item.active)}
        />
      ),
      width: 30,
    },
    {
      label: t('Common:WHEN'),
      // eslint-disable-next-line react/no-unstable-nested-components
      prop: (item: ReminderTemplate) => (
        <ReminderSetUpDueDateOffsetCell dueDateOffset={item.dueDateOffset} />
      ),
      width: 300,
    },
  ]

  return (
    <ScrollableDataTable
      useIndexAsId
      data={templates}
      deleteDisabled={!permissions.delete}
      editDisabled={!permissions.update}
      headerRow={headerRow}
      mainColumns={mainColumns}
      onDelete={onDelete}
      onEdit={(index: number) => onEdit(templates[index], index)}
      onPreview={(index: number) => onPreview(templates[index])}
    >
      {({ item: template, tableCellClassName }) => (
        <>
          <TableCell className={tableCellClassName}>
            <Text fontSize="1.4rem">
              {LanguageUtils.getConstantTranslatedName(
                template?.communicationMethodIds?.[0],
                CommunicationTransportBackupOption,
              )}
            </Text>
          </TableCell>
          <TableCell className={tableCellClassName}>
            <Text fontSize="1.4rem">
              {LanguageUtils.getConstantTranslatedName(
                template?.communicationMethodIds?.[1],
                CommunicationTransportBackupOption,
              )}
            </Text>
          </TableCell>
          <TableCell className={tableCellClassName}>
            <Text fontSize="1.4rem">
              {LanguageUtils.getConstantTranslatedName(
                template?.communicationMethodIds?.[2],
                CommunicationTransportBackupOption,
              )}
            </Text>
          </TableCell>
          <TableCell className={tableCellClassName}>
            <Text fontSize="1.4rem">
              {LanguageUtils.getConstantTranslatedName(
                template?.communicationMethodIds?.[3],
                CommunicationTransportBackupOption,
              )}
            </Text>
          </TableCell>
        </>
      )}
    </ScrollableDataTable>
  )
}

export default ExtendedReminderTemplatesTable
