import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import ConnectChewyAccount from '~/components/dashboard/link-chewy-account/tooltips/ConnectChewyAccount'
import FeatureToggle from '~/constants/featureToggle'
import {
  PrescriptionWorkflowName,
  PrescriptionWorkflowType,
} from '~/constants/PrescriptionWorkflow'
import {
  getGlobalInventoryMapping,
  getIsActiveRxWorkflowAllowanceLoading,
  getIsFetchingChewyItems,
  getLinkedToChewy,
  getPrescriptionChewyItems,
} from '~/store/duck/prescriptions'
import { getFeatureToggle } from '~/store/reducers/constants'

import WorkflowSectionItem from './WorkflowSectionItem'

type DisabledState = {
  disabled: boolean
  id: string
  priority: number
  tooltip: string | React.ReactNode
}

export interface ChewyActiveRxWorkflowProps {
  activeWorkflow: PrescriptionWorkflowType
  clientId: string
  disabled: boolean
  isChewyReactiveRx: boolean
  isEdit: boolean
}

const chewyActiveWorkflow = PrescriptionWorkflowType.CHEWY_ACTIVE_RX
const label = PrescriptionWorkflowName[chewyActiveWorkflow]
const getDisabled = (state: DisabledState) => state.disabled

const ChewyActiveRxWorkflow = ({
  activeWorkflow,
  clientId,
  disabled: disabledProp,
  isChewyReactiveRx,
  isEdit,
}: ChewyActiveRxWorkflowProps) => {
  const { t } = useTranslation('Tooltips')

  const isActiveRxEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ACTIVE_RX),
  )
  const chewyAccountLinker = useSelector(
    getFeatureToggle(FeatureToggle.CHEWY_ACCOUNT_LINKER),
  )

  const { business, client, patient } = useSelector(getLinkedToChewy) || {}
  const chewyItems = useSelector(getPrescriptionChewyItems)
  const globalInventoryMapping = useSelector(getGlobalInventoryMapping)
  const isFetchingChewyItems = useSelector(getIsFetchingChewyItems)
  const isActiveRxWorkflowAllowanceLoading = useSelector(
    getIsActiveRxWorkflowAllowanceLoading,
  )

  const DisabledStates: DisabledState[] = [
    {
      id: 'MISSING_KYRIOS_ID',
      disabled: !business,
      priority: 1,
      tooltip: t('Tooltips:CHEWY_ACTIVE_RX'),
    },
    {
      id: 'CHEWY_ACCOUNT_NOT_LINKED',
      disabled: !client || !patient,
      priority: 2,
      tooltip: chewyAccountLinker ? (
        <ConnectChewyAccount
          clientId={clientId}
          tooltipText={t('Tooltips:CONNECT_CLIENT_CHEWY_ACCOUNT')}
        />
      ) : (
        t('Tooltips:CHEWY_ACCOUNT_NOT_LINKED')
      ),
    },
    {
      id: 'NOT_AVAILABLE_ON_CHEWY_CATALOG',
      disabled:
        !isFetchingChewyItems &&
        (R.isNil(chewyItems) || Boolean(chewyItems && R.isEmpty(chewyItems))),
      priority: 3,
      tooltip: t('Tooltips:ITEM_NOT_AVAILABLE_ON_CHEWY_CATALOG'),
    },
    {
      id: 'ITEM_CANNOT_BE_PRESCRIBED_THROUGH_CHEWY',
      disabled:
        !isFetchingChewyItems &&
        globalInventoryMapping?.requiresPrescription === false,
      priority: 4,
      tooltip: t('Tooltips:ITEM_CANNOT_BE_PRESCRIBED_THROUGH_CHEWY'),
    },
    // RHAP-1710 - Until this ticket is done, we will disable switch workflow temporarily
    {
      id: 'DISABLE_EDITION_WORKFLOW_TRANSFORMATION',
      disabled: isEdit && activeWorkflow !== chewyActiveWorkflow,
      priority: 5,
      tooltip: '',
    },
  ]

  const isDisabled = DisabledStates.some(getDisabled)
  const disabled =
    disabledProp ||
    isDisabled ||
    isActiveRxWorkflowAllowanceLoading ||
    isFetchingChewyItems
  const disabledTooltip = DisabledStates.find(getDisabled)?.tooltip ?? ''

  if (!isActiveRxEnabled || isChewyReactiveRx) {
    return null
  }

  return (
    <WorkflowSectionItem
      disabled={disabled}
      disabledTooltip={disabled ? disabledTooltip : ''}
      label={label}
      workflow={chewyActiveWorkflow}
    />
  )
}

export default ChewyActiveRxWorkflow
