import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Add as AddIcon } from '@mui/icons-material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { PrepaidItemSnapshotItem, WidgetColumn } from '~/types'

import Snapshot from './Snapshot'

const columns: WidgetColumn<PrepaidItemSnapshotItem>[] = [
  {
    id: 'name',
    label: '',
    prop: 'name',
    width: 10,
  },
]

const PrepaidItemsSnapshot = () => {
  const { t } = useTranslation(['Abbreviations', 'Common', 'Clients'])

  const { update: appointmentUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const { update: invoiceUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddToAppointment = (item: PrepaidItemSnapshotItem) => {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddToInvoice = (item: PrepaidItemSnapshotItem) => {}

  // Review permissions when implementing this Feature
  const getActions = (item: PrepaidItemSnapshotItem) => [
    {
      id: 'addToAppointment',
      label: t('Common:ADD_TO_APPOINTMENT'),
      Icon: AddIcon,
      onClick: appointmentUpdatePermissions
        ? () => handleAddToAppointment(item)
        : undefined,
      disabled: !appointmentUpdatePermissions,
    },
    {
      id: 'addToInvoice',
      label: t('Abbreviations:ACRONYMS.OVER_THE_COUNTER.ADD_TO_OTC_INVOICE'),
      Icon: AddIcon,
      onClick: invoiceUpdatePermissions
        ? () => handleAddToInvoice(item)
        : undefined,
      disabled: !invoiceUpdatePermissions,
    },
  ]

  return (
    <Snapshot
      noItems
      columns={columns}
      data={[]}
      getActions={getActions}
      isLoading={false}
      noRecentItems={false}
      title={t('Clients:SNAPSHOTS_AND_RECORDS.PREPAID_ITEMS')}
    />
  )
}

export default PrepaidItemsSnapshot
