/**
 * Build array from regex parts from NDC parts
 * @param {Number} arr array with NDC parts sizes
 */
export const getRegexArray = (arr: number[] = []) => {
  const safeArray = arr.filter((item) => Number.isInteger(item))
  if (safeArray.length === 0) {
    return []
  }

  return arr
    .map((n) => Array(n).fill('\\d'))
    .reduce((acc, item) => [...acc, '-', ...item])
}

/**
 * Get RetExp from array of Regex parts
 * @param {String} arr array of Regex parts
 */
export const getRegex = (arr: RegExp[] | string[] = []) =>
  new RegExp(`^${arr.join('')}$`)

export type NdcFormat = {
  format: RegExp[]
  to11Digits: (str: string) => string
}

const NDC_FORMATS: NdcFormat[] = [
  {
    format: getRegexArray([4, 4, 2]),
    to11Digits: (str) => `0${str}`,
  },
  {
    format: getRegexArray([5, 3, 2]),
    to11Digits: (str) => `${str.substr(0, 6)}0${str.substr(6)}`,
  },
  {
    format: getRegexArray([5, 4, 1]),
    to11Digits: (str) => `${str.substr(0, 11)}0${str.substr(11)}`,
  },
  {
    format: getRegexArray([5, 4, 2]),
    to11Digits: (str) => str,
  },
  {
    format: getRegexArray([11]),
    to11Digits: (str) =>
      `${str.substr(0, 5)}-${str.substr(5, 4)}-${str.substr(9)}`,
  },
]

/**
 * Partially matching provided string using its length
 * @param {String} str string to match
 * @param {Array} formats list of formats with regex to match with
 */
export const partialMatch = (str = '', formats = NDC_FORMATS) => {
  if (str.length === 0) {
    return true
  }

  return formats.some(({ format }) => {
    const subFormat = format.slice(0, str.length)
    return getRegex(subFormat).test(str)
  })
}

/**
 * Convert NDC number to 11 digits 5-4-2 format
 * @param {String} str NDC number to convert
 * @param {*} formats NDC number formats we support
 */
export const getNdc11DigitsFormat = (
  str: string,
  formats: NdcFormat[] = NDC_FORMATS,
) => {
  const index = formats.findIndex(({ format }) => getRegex(format).test(str))

  if (index === -1) {
    return null
  }

  const { to11Digits } = formats[index]
  return to11Digits(str)
}
