import React from 'react'
import { useSelector } from 'react-redux'
import {
  PrimitiveTableRow,
  PrimitiveTableRowProps,
} from '@pbt/pbt-ui-components'

import { getImagingDashboardRecord } from '~/store/duck/imagingDashboard'

export interface ImagingTableRowProps extends PrimitiveTableRowProps {
  item: string
}

const ImagingTableRow = ({
  item: imagingRecordId,
  ...rest
}: ImagingTableRowProps) => {
  const item = useSelector(getImagingDashboardRecord(imagingRecordId))

  return <PrimitiveTableRow item={item} {...rest} />
}

export default ImagingTableRow
