import { useSelector } from 'react-redux'

import { ContextAvailabilityMap } from '~/components/dashboard/header/headerUtils'
import SearchContext from '~/constants/searchContext'

import { getCRUDByArea } from '../reducers/auth'

export const useSearchEnabled = (context: SearchContext) => {
  const permissionArea = ContextAvailabilityMap[context]?.permission || null
  const readPermissions = useSelector(getCRUDByArea(permissionArea)).read
  return permissionArea ? readPermissions : true
}
