import { ApiError } from '@pbt/pbt-ui-components'

import {
  Conversation,
  ConversationEmailPreviewConfig,
  ConversationRecipientContext,
  TableFilter,
  UnsavedConversation,
  UnsavedConversationMessage,
} from '~/types'

import {
  CLEAR_CONVERSATION_EMAIL_PREVIEW,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_VALIDATION_FAILURE,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_FAILURE,
  DELETE_CONVERSATION_SUCCESS,
  EDIT_CONVERSATION,
  EDIT_CONVERSATION_FAILURE,
  EDIT_CONVERSATION_SUCCESS,
  FETCH_CLIENT_CONVERSATIONS_LIST,
  FETCH_CLIENT_CONVERSATIONS_LIST_FAILURE,
  FETCH_CLIENT_CONVERSATIONS_LIST_SUCCESS,
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_EMAIL_PREVIEW,
  FETCH_CONVERSATION_EMAIL_PREVIEW_FAILURE,
  FETCH_CONVERSATION_EMAIL_PREVIEW_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  FETCH_CONVERSATION_MESSAGES_LIST,
  FETCH_CONVERSATION_MESSAGES_LIST_FAILURE,
  FETCH_CONVERSATION_MESSAGES_LIST_SUCCESS,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATIONS_LIST,
  FETCH_CONVERSATIONS_LIST_FAILURE,
  FETCH_CONVERSATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST,
  FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_SUCCESS,
  SET_ASSIGNEE_FOR_CONVERSATION,
  SET_CONVERSATIONS_LIST_FILTERS,
  SET_IS_READ_FOR_CONVERSATION,
  TOGGLE_ARCHIVE_FOR_CONVERSATION,
  UPDATE_CONVERSATION_RECIPIENT_CONTEXT,
  UPDATE_CONVERSATIONS,
} from './types/conversations'

export const fetchConversation = (conversationId: string) => ({
  type: FETCH_CONVERSATION,
  conversationId,
})
export const fetchConversationSuccess = (conversationId: string) => ({
  type: FETCH_CONVERSATION_SUCCESS,
  conversationId,
})
export const fetchConversationFailure = (error: ApiError) => ({
  type: FETCH_CONVERSATION_FAILURE,
  error,
})

export const fetchConversationsList = (from: number, to: number) => ({
  type: FETCH_CONVERSATIONS_LIST,
  from,
  to,
})
export const fetchConversationsListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_CONVERSATIONS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchConversationsListFailure = (error: ApiError) => ({
  type: FETCH_CONVERSATIONS_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForConversationsList = (
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST,
  from,
  to,
})
export const fetchMoreItemsForConversationsListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForConversationsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_CONVERSATIONS_LIST_FAILURE,
  error,
})

export const updateConversations = (
  conversations: Record<string, Conversation>,
) => ({
  type: UPDATE_CONVERSATIONS,
  conversations,
})

export const fetchConversationMessagesList = (
  from: number,
  to: number,
  conversationId: string,
) => ({
  type: FETCH_CONVERSATION_MESSAGES_LIST,
  from,
  to,
  conversationId,
})

export const fetchConversationMessagesListSuccess = (
  conversationId: string,
  messages: string[],
  totalCount: number,
) => ({
  type: FETCH_CONVERSATION_MESSAGES_LIST_SUCCESS,
  conversationId,
  messages,
  totalCount,
})
export const fetchConversationMessagesListFailure = (error: ApiError) => ({
  type: FETCH_CONVERSATION_MESSAGES_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForConversationMessagesList = (
  from: number,
  to: number,
  conversationId: string,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST,
  from,
  to,
  conversationId,
})

export const fetchMoreItemsForConversationMessagesListSuccess = (
  conversationId: string,
  messages: string[],
  totalCount: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_SUCCESS,
  conversationId,
  messages,
  totalCount,
})

export const fetchMoreItemsForConversationMessagesListFailure = (
  error: ApiError,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CONVERSATION_MESSAGES_LIST_FAILURE,
  error,
})

export const createConversation = (
  conversation: UnsavedConversation,
  message: UnsavedConversationMessage,
) => ({
  type: CREATE_CONVERSATION,
  conversation,
  message,
})
export const createConversationSuccess = (conversationsList: string[]) => ({
  type: CREATE_CONVERSATION_SUCCESS,
  conversationsList,
})
export const createConversationFailure = (error: ApiError) => ({
  type: CREATE_CONVERSATION_FAILURE,
  error,
})
export const createConversationValidationFailure = (error: ApiError) => ({
  type: CREATE_CONVERSATION_VALIDATION_FAILURE,
  error,
})

export const deleteConversation = (conversationId: string) => ({
  type: DELETE_CONVERSATION,
  conversationId,
})
export const deleteConversationSuccess = (conversationId: string) => ({
  type: DELETE_CONVERSATION_SUCCESS,
  conversationId,
})
export const deleteConversationFailure = (error: ApiError) => ({
  type: DELETE_CONVERSATION_FAILURE,
  error,
})

export const updateConversationRecipientContext = (
  conversationId: string,
  origin: ConversationRecipientContext,
) => ({
  type: UPDATE_CONVERSATION_RECIPIENT_CONTEXT,
  conversationId,
  origin,
})
export const setAssigneeForConversation = (
  conversationId: string,
  assigneeId: string,
) => ({
  type: SET_ASSIGNEE_FOR_CONVERSATION,
  conversationId,
  assigneeId,
})
export const toggleArchiveForConversation = (conversationId: string) => ({
  type: TOGGLE_ARCHIVE_FOR_CONVERSATION,
  conversationId,
})
export const setIsReadForConversation = (
  conversationId: string,
  isRead: boolean,
) => ({
  type: SET_IS_READ_FOR_CONVERSATION,
  conversationId,
  isRead,
})

export const editConversation = (
  conversationId: string,
  conversation: Partial<Conversation>,
) => ({
  type: EDIT_CONVERSATION,
  conversationId,
  conversation,
})
export const editConversationSuccess = () => ({
  type: EDIT_CONVERSATION_SUCCESS,
})
export const editConversationFailure = (error: ApiError) => ({
  type: EDIT_CONVERSATION_FAILURE,
  error,
})

export const fetchClientConversationsList = (
  clientId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_CLIENT_CONVERSATIONS_LIST,
  clientId,
  from,
  to,
})
export const fetchClientConversationsListSuccess = (
  clientId: string,
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_CLIENT_CONVERSATIONS_LIST_SUCCESS,
  clientId,
  list,
  totalCount,
})
export const fetchClientConversationsListFailure = (error: ApiError) => ({
  type: FETCH_CLIENT_CONVERSATIONS_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForClientConversationsList = (
  clientId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST,
  clientId,
  from,
  to,
})
export const fetchMoreItemsForClientConversationsListSuccess = (
  clientId: string,
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_SUCCESS,
  clientId,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForClientConversationsListFailure = (
  error: ApiError,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CLIENT_CONVERSATIONS_LIST_FAILURE,
  error,
})

export const fetchConversationEmailPreview = (
  config: ConversationEmailPreviewConfig,
) => ({
  type: FETCH_CONVERSATION_EMAIL_PREVIEW,
  config,
})
export const fetchConversationEmailPreviewSuccess = (template: string) => ({
  type: FETCH_CONVERSATION_EMAIL_PREVIEW_SUCCESS,
  template,
})
export const fetchConversationEmailPreviewFailure = (error: ApiError) => ({
  type: FETCH_CONVERSATION_EMAIL_PREVIEW_FAILURE,
  error,
})

export const clearConversationEmailPreview = () => ({
  type: CLEAR_CONVERSATION_EMAIL_PREVIEW,
})

export const setConversationsListFilters = (
  filters: Record<string, TableFilter>,
) => ({
  type: SET_CONVERSATIONS_LIST_FILTERS,
  filters,
})
