import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import { fetchEstimates } from '~/store/actions/finance'

import EstimatesWidget from '../soapV2/estimates/EstimatesWidget'

const useStyles = makeStyles(
  () => ({
    estimatesWidgetWrapper: {
      minWidth: '850px',
      maxWidth: 'none',
    },
    estimatesWidgetRoot: {
      maxWidth: '900px',
    },
  }),
  { name: 'EstimatesListDialog' },
)

export interface EstimatesListDialogProps extends BasePuiDialogProps {
  patientId: string | Nil
}

const EstimatesListDialog = ({
  onClose,
  open,
  patientId,
}: EstimatesListDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    if (open && patientId) {
      dispatch(fetchEstimates(patientId))
    }
  }, [open, patientId])

  return (
    <PuiDialog
      className={classes.estimatesWidgetWrapper}
      classes={{
        paper: classes.estimatesWidgetWrapper,
      }}
      open={Boolean(open)}
      onClose={onClose}
    >
      <EstimatesWidget
        classes={{
          root: classes.estimatesWidgetRoot,
        }}
      />
    </PuiDialog>
  )
}

export default EstimatesListDialog
