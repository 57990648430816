import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { ControlButtonGroupName, Nil } from '@pbt/pbt-ui-components'
import {
  Download as DownloadIcon,
  Email as EmailIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import {
  Invoice,
  InvoiceEmailDto,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import { PopperAction } from '~/components/common/ActionsPopper'
import ActionsButton from '~/components/common/buttons/ActionsButton'
import { GeneratingPdfContentKind } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import i18n from '~/locales/i18n'
import { emailInvoiceV2 } from '~/store/actions/communications'
import { getExpandedGroups } from '~/store/duck/clientFinanceData'
import { useDownloadContentAsPdf } from '~/store/hooks/finance'
import { InvoiceV3 } from '~/types/entities/invoiceV3'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    dotsButton: {
      width: 40,
      height: 40,
      border: theme.constants.filterBorder,
      color: theme.colors.searchButton,
      boxShadow: theme.constants.buttonBlockShadow,
    },
  }),
  { name: 'InvoiceFinanceDropdownActions' },
)

interface InvoiceFinanceDropdownActionsProps {
  actions: PopperAction[]
  includeServiceFee?: boolean
  invoice: Invoice | InvoiceV3 | RefundInvoice | Nil
}

const InvoiceFinanceDropdownActions = ({
  actions,
  invoice,
  includeServiceFee,
}: InvoiceFinanceDropdownActionsProps) => {
  const classes = useStyles()
  const expandedGroups = useSelector(getExpandedGroups)

  const [openEmailPostedInvoiceDialog] = useDialog(
    DialogNames.EMAIL_POSTED_INVOICE,
  )
  const [openPrintPostedInvoiceDialog] = useDialog(
    DialogNames.PRINT_POSTED_INVOICE,
  )

  const [generatePdf, isGenerating] = useDownloadContentAsPdf(
    GeneratingPdfContentKind.INVOICE_V2,
  )

  const defaultActions: PopperAction = {
    id: 'finance-drop-down-actions-default',
    isGroup: true,
    title: i18n.t('Common:MANAGE'),
    items: [
      {
        id: ControlButtonGroupName.DOWNLOAD,
        label: i18n.t('Common:DOWNLOAD_ACTION'),
        Icon: DownloadIcon,
        onClick: () => {
          if (invoice) {
            generatePdf({
              invoiceId: invoice.id,
              expandedGroups,
              includeServiceFee,
            })
          }
        },
      },
      {
        id: ControlButtonGroupName.EMAIL,
        label: i18n.t('Common:EMAIL_ACTION'),
        Icon: EmailIcon,
        onClick: () => {
          if (invoice) {
            openEmailPostedInvoiceDialog({
              clientId:
                'clientId' in invoice ? invoice.clientId : invoice.client.id,
              includeServiceFee,
              expandedGroups,
              isGenerating,
              isEstimate: false,
              downloadAction: () =>
                generatePdf({
                  invoiceId: invoice.id,
                  expandedGroups,
                  includeServiceFee,
                }),
              sendAction: (emailData: InvoiceEmailDto) =>
                emailInvoiceV2({
                  ...emailData,
                  invoiceId: invoice.id,
                }),
            })
          }
        },
      },
      {
        id: ControlButtonGroupName.PRINT,
        label: i18n.t('Common:PRINT_ACTION'),
        Icon: PrintIcon,
        onClick: () => {
          if (invoice) {
            openPrintPostedInvoiceDialog({
              invoiceId: invoice.id,
              expandedGroups,
              includeServiceFee,
            })
          }
        },
      },
    ],
  }

  return (
    <ActionsButton
      actions={[...actions, { ...defaultActions }]}
      className={classes.dotsButton}
      isLoading={isGenerating}
      placement="bottom-end"
    />
  )
}

export default InvoiceFinanceDropdownActions
