import React, { useRef, useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import WithDotDotDot from '~/components/common/hoc/WithDotDotDot'
import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import { ImportedHistoryTimelineItemEntry } from '~/types'

import ImportedHistoryPreview from './ImportedHistoryPreview'

const useStyles = makeStyles(
  () => ({
    container: {
      cursor: 'pointer',
    },
  }),
  { name: 'OneImportedHistory' },
)

const SHRINKED_TEXT_HEIGHT = 24

interface OneImportedHistoryProps {
  enableBusinessShareIcon?: boolean
  importedHistory: ImportedHistoryTimelineItemEntry
}

const OneImportedHistory = ({
  importedHistory,
  enableBusinessShareIcon,
}: OneImportedHistoryProps) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const textRef = useRef<HTMLDivElement>(null)
  const nameRef = useRef<HTMLSpanElement>(null)

  const expandable = Boolean(
    (textRef.current?.scrollHeight || 0) > SHRINKED_TEXT_HEIGHT ||
      (nameRef.current &&
        nameRef.current.offsetHeight < nameRef.current.scrollHeight),
  )

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <Grid
      container
      className={classNames({
        [classes.container]: expandable,
      })}
      direction="column"
      pl={0.5}
      spacing={1}
      onClick={expandable ? toggleExpanded : undefined}
    >
      <Grid container item justifyContent="space-between" wrap="nowrap">
        <Grid container item alignItems="center" wrap="nowrap">
          <WithDotDotDot clamp={2} condition={!expanded}>
            <Text strong ref={nameRef} variant="body2">
              {importedHistory.name}
            </Text>
          </WithDotDotDot>
          {enableBusinessShareIcon && (
            <BusinessShareIcon businessIds={[importedHistory.businessId]} />
          )}
        </Grid>
        {expandable && (expanded ? <ExpandLess /> : <ExpandMore />)}
      </Grid>
      <Collapse collapsedSize={SHRINKED_TEXT_HEIGHT} in={expanded}>
        <Grid container pl={0.5} ref={textRef}>
          <ImportedHistoryPreview importedHistory={importedHistory} />
        </Grid>
      </Collapse>
    </Grid>
  )
}

export default OneImportedHistory
