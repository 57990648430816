import React from 'react'

import { useLockableComponent } from '~/store/hooks/soap'

import SoapExamination from './SoapExamination'
import SoapExaminationLocked from './SoapExaminationLocked'

export interface ExaminationPageProps {
  editDisabled?: boolean
}

const ExaminationPage = ({ editDisabled }: ExaminationPageProps) => {
  const SoapExaminationComponent = useLockableComponent(
    SoapExamination,
    SoapExaminationLocked,
  )

  return <SoapExaminationComponent editDisabled={editDisabled} />
}

export default ExaminationPage
