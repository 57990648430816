import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  CurrencyTextField,
  ErrorTooltip,
  Field,
  NumberUtils,
  Text,
} from '@pbt/pbt-ui-components'

import {
  PAYMENT_TYPE_OPTIONS,
  PaymentTypes,
} from '~/constants/financeConstants'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getUser } from '~/store/reducers/users'
import { handleNumberInput } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.tableLeftColumnBackground,
      borderTop: theme.constants.tabBorder,
      width: `calc(100% + ${theme.spacing(4)})`,
    },
    currencyTextContainer: {
      width: 88,
      margin: 0,
    },
    currencyTextField: {
      textAlign: 'right',
    },
    formLabel: {
      margin: 0,
    },
    radio: {
      height: 28,
      padding: 0,
      margin: theme.spacing(0, 1),
    },
  }),
  { name: 'PaymentSection' },
)

interface PaymentSectionProps {
  clientId: string
  hasApplyPaymentsEnabled: boolean
  isLoading: boolean
  isOtherPaymentType: boolean
  paymentAmountField: Field
  paymentTypeField: Field
  serviceFeeAmount: number
}

export const PaymentSection = ({
  clientId,
  hasApplyPaymentsEnabled,
  isLoading,
  isOtherPaymentType,
  paymentTypeField,
  paymentAmountField,
  serviceFeeAmount,
}: PaymentSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))
  const currentBusiness = useSelector(getCurrentBusiness)

  const isDisabled =
    isLoading || (hasApplyPaymentsEnabled && paymentAmountField.value <= 0)

  useEffectExceptOnMount(() => {
    if (!hasApplyPaymentsEnabled) {
      return
    }

    if (paymentAmountField.value <= 0) {
      paymentTypeField.setValue('')
    } else if (paymentTypeField.value === undefined) {
      paymentTypeField.setValue(PaymentTypes.CREDIT_CARD)
    }
  }, [
    hasApplyPaymentsEnabled,
    paymentAmountField.value,
    paymentTypeField.value,
  ])

  return (
    <Box className={classes.container} display="flex" mx={-2} px={2}>
      <Box flex={1} pb={2} pt={1}>
        <FormControl component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            <Text mb={1} variant="h4">
              {t('Common:PAYMENTS.PAYMENT')}
            </Text>
          </FormLabel>
          <ErrorTooltip
            message={t('Validations:REQUIRED_FIELD')}
            open={paymentTypeField.open && paymentTypeField.errors.length > 0}
            placement="top-end"
          >
            <RadioGroup
              aria-label="payment types"
              name="payments"
              value={paymentTypeField.value}
              onChange={(_, value) => paymentTypeField.setValue(value)}
            >
              {PAYMENT_TYPE_OPTIONS.map(({ type, label }) => (
                <FormControlLabel
                  control={<Radio className={classes.radio} />}
                  disabled={isDisabled}
                  key={type}
                  label={label}
                  value={type}
                />
              ))}
            </RadioGroup>
          </ErrorTooltip>
        </FormControl>
        <Stack maxWidth={250} spacing={1}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text disabled={isDisabled} mr={1} variant="body2">
              {t('Common:PAYMENT_AMOUNT')}:
            </Text>
            <CurrencyTextField
              className={classes.currencyTextContainer}
              disabled={isDisabled}
              field={{
                ...paymentAmountField,
                set: handleNumberInput(
                  (value: string) => {
                    const formattedValue = parseFloat(value) || 0
                    paymentAmountField.setValue(formattedValue)
                  },
                  7,
                  2,
                  true,
                ),
              }}
              fullWidth={false}
              inputProps={{ className: classes.currencyTextField }}
            />
          </Box>
          {currentBusiness?.posPayEnabled && !isOtherPaymentType && (
            <>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text disabled={isDisabled} variant="body2">
                  {t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.SERVICE_FEE')}:
                </Text>
                <Text disabled={isDisabled} variant="body2">
                  {NumberUtils.formatMoney(serviceFeeAmount)}
                </Text>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text strong disabled={isDisabled} variant="body2">
                  {t('Common:TOTAL_CHARGE')}:
                </Text>
                <Text strong disabled={isDisabled} variant="body2">
                  {NumberUtils.formatMoney(
                    (paymentAmountField.value || 0) + serviceFeeAmount,
                  )}
                </Text>
              </Box>
            </>
          )}
        </Stack>
      </Box>
      <Divider flexItem orientation="vertical" />
      <Box flex={1} ml={1.5} pb={2} pt={1}>
        <Text strong variant="subheading3">
          {t('Common:CLIENT_NOTES')}
        </Text>
        <Text variant="body2">{client?.notes}</Text>
      </Box>
    </Box>
  )
}
