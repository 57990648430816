import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchMoreVitals } from '~/store/duck/vitals'
import { useLockableComponent } from '~/store/hooks/soap'
import { getPatientId } from '~/store/reducers/soap'

import SoapVitalsWidgetLocked from './SoapVitalsWidgetLocked'
import SoapVitalsWidgetUnlocked from './SoapVitalsWidgetUnlocked'

const SoapVitalsWidget = () => {
  const dispatch = useDispatch()
  const patientId = useSelector(getPatientId)

  useEffect(() => {
    if (patientId) {
      // TO DO: add 15 to constants as first vitals loading
      dispatch(
        fetchMoreVitals({
          patientId,
          includeDeleted: false,
          from: 0,
          to: 15,
        }),
      )
    }
  }, [patientId])
  const SoapVitalsComponent = useLockableComponent(
    SoapVitalsWidgetUnlocked,
    SoapVitalsWidgetLocked,
  )

  return <SoapVitalsComponent />
}

export default SoapVitalsWidget
