import React from 'react'
import { useTranslation } from 'react-i18next'

import { UserSelectFilterScope } from '../inputs/UserSelect'
import { FilterOption } from './BaseFilter'
import PersonFilter from './PersonFilter'

export interface ClientsFilterProps {
  anchorEl: any
  displayEmpty?: boolean
  hasSelectedOption?: boolean
  humanReadable: string
  includeSearch: boolean
  isLoading: boolean
  onChange: (filterOption: FilterOption) => void
  onClose: () => void
  open: boolean
  searchTerm?: string
  serialize: boolean
  setSearchTerm: (term: string) => void
  value: any
}

const ClientsFilter = (props: ClientsFilterProps) => {
  const { t } = useTranslation('Search')

  return (
    <PersonFilter
      filterScope={UserSelectFilterScope.Clients}
      label={t('Search:CLIENTS')}
      {...props}
    />
  )
}

export default ClientsFilter
