import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  HtmlNotesPreview,
  LinkButton,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { DiagnosisSnapshotItem } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import DiagnosisStatusCell from './DiagnosisStatusCell'
import ProblemHistoryEnumInfo from './ProblemHistoryEnumInfo'

interface ProblemHistoryPanelProps {
  clientId: string
  patientId: string
  problemLogs: DiagnosisSnapshotItem[]
}

const useStyles = makeStyles(
  (theme) => ({
    linkButton: {
      color: theme.colors.link,
      fontSize: theme.typography.pxToRem(14),
      height: 21,
      padding: '0 8px 2px',
      minWidth: 0,
    },
    dateText: {
      color: theme.colors.inactiveBackground,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.5,
      marginLeft: 8,
    },
    descriptionText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
    dividerBorder: {
      borderBottom: `1px solid ${theme.colors.signIconBackground}`,
      paddingBottom: 8,
    },
    statusUpdate: {
      '&:not(:last-child)': {
        marginBottom: 24,
      },
      width: '100%',
    },
    emptyStatusUpdate: {
      '&:not(:last-child)': {
        marginBottom: 8,
      },
    },
    logEntry: {
      marginLeft: 24,
      marginRight: 16,
      marginTop: 8,
    },
  }),
  { name: 'ProblemHistoryEntry' },
)

interface ProblemHistoryEntryProps {
  clientId: string
  logs: DiagnosisSnapshotItem[]
  patientId: string
}

const ProblemHistoryEntry = ({
  logs,
  clientId,
  patientId,
}: ProblemHistoryEntryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const [openDiagnosisHistoryDialog] = useDialog(DialogNames.DIAGNOSIS_HISTORY)
  const { read: diagnoseReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.DIAGNOSE),
  )

  const isSingleEntry = logs.length === 1

  const { read: soapReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.SOAP),
  )
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const goToSoap = (item: DiagnosisSnapshotItem) => {
    navigate(
      addOriginalBusinessId(
        `/soap/${item.soapId}`,
        isPatientSharingEnabled ? item.businessId : null,
      ),
    )
  }

  const handleView = (item?: DiagnosisSnapshotItem) => {
    openDiagnosisHistoryDialog({ clientId, patientId, diagnosis: item })
  }

  const createActionButton = (log: DiagnosisSnapshotItem) => (
    <>
      {log.soapId
        ? soapReadPermissions && (
            <LinkButton
              classes={{ button: classes.linkButton }}
              onClick={() => goToSoap(log)}
            >
              {t('Common:VIEW_SOAP')}
            </LinkButton>
          )
        : diagnoseReadPermissions && (
            <LinkButton
              classes={{ button: classes.linkButton }}
              onClick={() => handleView(log)}
            >
              {t('Common:VIEW_ACTION')}
            </LinkButton>
          )}
    </>
  )

  return (
    <Grid
      container
      item
      className={classNames(classes.statusUpdate, {
        [classes.emptyStatusUpdate]: !R.pipe(
          R.last,
          R.prop('notes'),
          Boolean,
        )(logs),
      })}
    >
      <Grid
        container
        alignItems="baseline"
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="flex-start"
        width="100%"
      >
        <Grid item flex="0 0 auto" maxWidth="120px">
          <DiagnosisStatusCell inline shorten item={logs[0]} />
        </Grid>
        <Grid item flex="0 0 auto">
          <Text className={classes.dateText}>
            {isSingleEntry
              ? DateUtils.formatDate(logs[0].date)
              : `${DateUtils.formatDate(
                  R.last(logs)?.date,
                )}-${DateUtils.formatDate(logs[0].date)}`}
          </Text>
        </Grid>
        {isSingleEntry && (
          <Grid item flex="0 0 auto">
            {createActionButton(logs[0])}
          </Grid>
        )}
      </Grid>
      <Grid container>
        {logs.map((log, index) => (
          <React.Fragment key={log.logId || log.id}>
            {(log.notes || !isSingleEntry) && (
              <Grid
                container
                className={classNames(classes.logEntry, {
                  [classes.dividerBorder]:
                    !isSingleEntry &&
                    index !== logs.length - 1 &&
                    (log.notes || (log.enums && log.enums.length > 0)),
                })}
              >
                {!isSingleEntry && (
                  <Grid
                    container
                    alignItems="baseline"
                    flexDirection="row"
                    marginLeft="-8px"
                  >
                    <Text className={classes.dateText}>
                      {DateUtils.formatDate(log.date)}
                    </Text>
                    {createActionButton(log)}
                  </Grid>
                )}
                {log.notes && (
                  <Grid className={classes.descriptionText}>
                    <HtmlNotesPreview
                      inlineLabel
                      strong
                      fontSize="1.4rem"
                      notes={log.notes}
                      variant="body2"
                    />
                  </Grid>
                )}
                <ProblemHistoryEnumInfo log={log} />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

// eslint-disable-next-line react/no-multi-comp
const ProblemHistoryPanel = ({
  problemLogs,
  clientId,
  patientId,
}: ProblemHistoryPanelProps) => {
  const statusEntries = R.pipe(
    R.groupBy((problem: DiagnosisSnapshotItem) =>
      problem.soapId
        ? `${problem.statusId}${problem.type}`
        : problem.diagnosedIn,
    ),
    R.values,
  )(problemLogs)

  return (
    <Grid container flexDirection="column">
      {statusEntries.map((entry) => (
        <ProblemHistoryEntry
          clientId={clientId}
          key={entry?.[0].logId || entry?.[0].id}
          logs={entry}
          patientId={patientId}
        />
      ))}
    </Grid>
  )
}

export default ProblemHistoryPanel
