import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import { Text } from '@pbt/pbt-ui-components'

import {
  fetchDevices,
  getDevicesByVendorId,
  getDevicesIsLoading,
} from '~/store/duck/labOrders'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      border: theme.constants.tableBorder,
      marginTop: theme.spacing(2),
    },
    tableCellHead: {
      padding: theme.spacing(0.5, 1),
    },
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    tableCell: {
      padding: theme.spacing(1.5, 1),
    },
    button: {
      minWidth: 150,
      height: 40,
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'LabMachines' },
)

const LabMachinesTable = ({ active, businessId, labName, vendorId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const devices = useSelector(getDevicesByVendorId(vendorId))
  const devicesIsLoading = useSelector(getDevicesIsLoading)
  const devicesWithUniqueNames = devices.map(
    ({ displayName, deviceSerialNumber }) => ({
      deviceSerialNumber,
      displayName: `${displayName || ''} ${deviceSerialNumber || ''}`.trim(),
    }),
  )
  const devicesEmpty = !active || !devicesWithUniqueNames.length

  return (
    <Grid container>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCellHead}>
              <Text variant="lowAccent2">
                {t('Businesses:INTEGRATION.NAME_MACHINES', { name: labName })}
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devicesEmpty ? (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Text variant="body2">
                  {t('Businesses:INTEGRATION.NO_MACHINES_FOUND')}
                </Text>
              </TableCell>
            </TableRow>
          ) : (
            devicesWithUniqueNames.map(
              ({ deviceSerialNumber, displayName }) => (
                <TableRow className={classes.tableRow} key={deviceSerialNumber}>
                  <TableCell className={classes.tableCell}>
                    <Text variant="body2">{displayName}</Text>
                  </TableCell>
                </TableRow>
              ),
            )
          )}
        </TableBody>
      </Table>
      {active && (
        <Fab
          className={classes.button}
          color="inherit"
          disabled={devicesIsLoading}
          variant="extended"
          onClick={() => dispatch(fetchDevices(businessId, true))}
        >
          {devicesIsLoading
            ? `${t('Common:REFRESHING')}...`
            : t('Common:REFRESH_LIST')}
        </Fab>
      )}
    </Grid>
  )
}

LabMachinesTable.propTypes = {
  active: PropTypes.bool.isRequired,
  businessId: PropTypes.string.isRequired,
  vendorId: PropTypes.string.isRequired,
}

export default LabMachinesTable
