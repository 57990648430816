import React from 'react'
import { useDispatch } from 'react-redux'

import { removeOrder } from '~/store/actions/orders'
import { Order } from '~/types'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'
import { useHandleDelete } from '../../utils/useHandleDelete'

export interface TaskOrderActionsProps extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
}

const TaskOrderActions = ({
  onDelete: onDeleteProp,
  order,
  ...rest
}: TaskOrderActionsProps) => {
  const dispatch = useDispatch()

  const handleDelete = useHandleDelete(
    order,
    () => dispatch(removeOrder(order)),
    onDeleteProp,
  )

  return <ItemWithBadgesActions onDelete={handleDelete} {...rest} />
}

export default TaskOrderActions
