import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import { getIsLoading, getMappingsIsLoading } from '~/store/reducers/migration'
import { MigrationEntity } from '~/types/entities/migration'

import CoreMigrationProgressTable from './CoreMigrationProgressTable'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      padding: theme.spacing(3),
    },
    container: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      padding: theme.spacing(2),
      height: 320,
      overflow: 'auto',
    },
    mainSpinner: {
      position: 'absolute',
      left: '50%',
      top: '50%',
    },
    button: {
      height: 40,
      width: 150,
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'CoreMigrationProgressComponent' },
)

interface CoreMigrationProgressComponentProps {
  entities: MigrationEntity[]
  finished: boolean
  onOk?: () => void
}

const CoreMigrationProgressComponent = ({
  entities,
  finished,
  onOk,
}: CoreMigrationProgressComponentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getIsLoading)
  const isMappingsLoading = useSelector(getMappingsIsLoading)

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        className={classes.container}
        direction="column"
        wrap="nowrap"
      >
        {R.isEmpty(entities) ? (
          <CircularProgress className={classes.mainSpinner} size={32} />
        ) : (
          <CoreMigrationProgressTable rows={entities || []} />
        )}
      </Grid>
      {onOk && (
        <ButtonWithLoader
          className={classes.button}
          disabled={!finished}
          loading={isLoading || isMappingsLoading}
          onClick={onOk}
        >
          {t('Common:DONE')}
        </ButtonWithLoader>
      )}
    </Grid>
  )
}

export default CoreMigrationProgressComponent
