import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { moment } from '@pbt/pbt-ui-components'

import SnackNotificationType from '~/constants/SnackNotificationType'
import {
  getNewVersionAvailable,
  getUpdateNotificationLastShownDate,
  setNewVersionAvailable,
  setUpdateNotificationLastShownDate,
} from '~/store/duck/serviceWorker'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

// @ts-ignore
import * as serviceWorker from '../../../serviceWorkerRegistration'
import { SnackNotificationProps } from '../notifications/SnackNotification'

const UPDATE_NOTIFIER_KEY = 'UPDATE_NOTIFICATION'
const NOTIFICATION_SHOW_PERIOD = 7 * 24 * 60 * 60 // once every 7 days

const useUpdateNotificationWatcher = (): [
  notificationVisible: boolean,
  setNotificationVisible: (visible: boolean) => void,
  notificationProps: Partial<SnackNotificationProps> & {
    type: SnackNotificationType
  },
] => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Watcher'])

  const newVersionAvailable = useSelector(getNewVersionAvailable)
  const lastShownDate = useSelector(getUpdateNotificationLastShownDate)

  const { isAuthenticated } = useIsAuthenticated()

  const [notificationVisible, setNotificationVisible] = useState(false)
  const [wasLoggedInWhenUpdateFound, setWasLoggedInWhenUpdateFound] =
    useState(false)

  const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    if (registration.waiting) {
      registration.unregister()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      dispatch(setNewVersionAvailable(true))
      setWasLoggedInWhenUpdateFound(isAuthenticated)
    }
  }

  const now = moment()
  const shouldShowNotification =
    newVersionAvailable && // when new version is available ofc
    !notificationVisible && // and is not already shown
    (!lastShownDate ||
      now.diff(lastShownDate, 's') >= NOTIFICATION_SHOW_PERIOD) && // did not show before or it's time to show
    isAuthenticated && // show only if user is logged in
    wasLoggedInWhenUpdateFound // and was also logged in when update found to prevent notification blink

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      try {
        serviceWorker.register({ onUpdate: onServiceWorkerUpdate })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Failed to register sw', error)
      }
    }
  }, [])

  useEffect(() => {
    if (shouldShowNotification) {
      dispatch(setUpdateNotificationLastShownDate(now.toISOString()))
      setNotificationVisible(true)
    }
  }, [shouldShowNotification])

  const onUpdateServiceWorker = () => {
    window.location.reload()
  }

  return [
    notificationVisible,
    setNotificationVisible,
    {
      id: UPDATE_NOTIFIER_KEY,
      title: `${t('Watcher:THERE_IS_NEW_VERSION')}!`,
      message: t('Watcher:REFRESH_YOUR_PAGE_TO_ENSURE_YOU_ARE_UP_TO_DATE'),
      type: SnackNotificationType.UPDATE,
      actionTitle: t('Common:REFRESH_PAGE'),
      onAction: onUpdateServiceWorker,
    },
  ]
}

export default useUpdateNotificationWatcher
