import React from 'react'
import { useSelector } from 'react-redux'
import { Text, TextProps, Utils } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'

export interface RoleMemberLabelProps extends TextProps {
  personId?: string
  roleName: string
}

const RoleMemberLabel = ({
  personId,
  roleName,
  ...rest
}: RoleMemberLabelProps) => {
  const person = useSelector(getUser(personId))

  if (!person) {
    return null
  }

  return (
    <Text variant="body2" {...rest}>
      {roleName}: {Utils.getPersonString(person)}
    </Text>
  )
}

export default RoleMemberLabel
