import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import {
  ApiError,
  Constant,
  ConstantMap,
  Constants,
  ConstantWithColors,
  CountryCode,
  FeatureTogglesConstant,
  LabelTemplatesHtmlConstant,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import { ColorVariant } from '~/constants/colors'
import { DiagnosisStatusColor } from '~/constants/diagnosis'
import { DymoLabelHtmlTypes, DymoLabelType } from '~/constants/dymo'
import FeatureToggle from '~/constants/featureToggle'
import { ImagingOrderStatusColor } from '~/constants/imaging'
import {
  InventoryOrderStatusColor,
  NotSelectableInventoryAdjustmentReasons,
} from '~/constants/inventory'
import {
  EstimateStateColor,
  RefundInvoiceStateColor,
  RetailOrderStatesColor,
} from '~/constants/invoice'
import { PrescriptionStatesColor } from '~/constants/prescription'
import { RhapsodyPassStatesColor } from '~/constants/RhapsodyPassStates'
import {
  ShipmentItemStatusColor,
  ShipmentStatusColor,
} from '~/constants/shipments'
import {
  includesState,
  NotSelectableLabTestStates,
  ReadyToOrderLabStatuses,
} from '~/constants/SOAPStates'
import type { ColorVariantMap } from '~/types'
import { findAllByName } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { FETCH_CURRENT_USER } from '../actions/types/auth'
import {
  FETCH_CONSTANTS,
  FETCH_CONSTANTS_FAILURE,
  FETCH_CONSTANTS_SUCCESS,
  FETCH_SECURE_CONSTANTS_FAILURE,
  FETCH_SECURE_CONSTANTS_SUCCESS,
} from '../actions/types/constants'
import type { RootState } from '../index'
import { getCurrentBusinessId, getCurrentBusinessIsOmniChannel } from './auth'

const colorToColorVariant = (constantEntries: ConstantWithColors[]) =>
  constantEntries.map((entry) => ({
    ...entry,
    colorVariant: entry.color,
  }))

const enrichWithColorValue =
  (map: ColorVariantMap) => (constantEntries: Constant[]) =>
    constantEntries.map((entry) => ({
      ...entry,
      colorVariant: map[entry.name] || ColorVariant.NORMAL,
    }))

const enrichWithVariant =
  (map: Record<string, string>) => (constantEntries: Constant[]) =>
    constantEntries.map((entry) => ({
      ...entry,
      variant: map[entry.name] || 'normal',
    }))

const enrichWithId = (constantEntries: string[]): Constant[] =>
  constantEntries.map((name) => ({
    name,
    id: name,
  }))

const enrichNestedWithId = <K extends string>(
  constantEntries: Record<K, string[]>,
): Record<K, Constant[]> =>
  Object.keys(constantEntries).reduce(
    (acc, value) => ({
      ...acc,
      [value]: enrichWithId(constantEntries[value as K]),
    }),
    {} as Record<K, Constant[]>,
  )

const enrichWithLocalizedOthers = Utils.normalizeConstantWithOthers

const ConstantAdapters = {
  BusinessStatus: colorToColorVariant,
  EstimateStates: enrichWithColorValue(EstimateStateColor),
  DiagnosisStatus: enrichWithColorValue(DiagnosisStatusColor),
  DiagnosisProblemStates: enrichWithColorValue(DiagnosisStatusColor),
  ImagingOrderStatuses: enrichWithColorValue(ImagingOrderStatusColor),
  InventoryOrderStatus: enrichWithColorValue(InventoryOrderStatusColor),
  InventoryShipmentItemStatus: enrichWithColorValue(ShipmentItemStatusColor),
  InventoryShipmentStatus: enrichWithColorValue(ShipmentStatusColor),
  PayStatusList: enrichWithColorValue(RhapsodyPassStatesColor),
  PrescriptionStates: enrichWithColorValue(PrescriptionStatesColor),
  InsuranceProvidersPerCountry: enrichNestedWithId,
  EntityTypes: enrichWithId,
  InventoryDistributors: enrichWithLocalizedOthers,
  InventoryManufacturers: enrichWithLocalizedOthers,
  LabVendors: enrichWithLocalizedOthers,
  RefundInvoiceState: enrichWithColorValue(RefundInvoiceStateColor),
  RetailOrderStates: enrichWithVariant(RetailOrderStatesColor),
}

type ConstantAdapterKeys = keyof typeof ConstantAdapters

const enrichConstants = (constants: Record<ConstantAdapterKeys, any>) =>
  (Object.entries(constants) as [ConstantAdapterKeys, any][]).reduce(
    (acc, [key, value]) => {
      const adapter = ConstantAdapters[key]
      acc[key] = adapter ? adapter(value) : value
      return acc
    },
    {} as Pick<ConstantMap, ConstantAdapterKeys>,
  )

export type ConstantsState = {
  error: ApiError | string | Nil
  isLoading: boolean
  map: ConstantMap
}

export const CONSTANTS_INITIAL_STATE: ConstantsState = {
  map: {
    AcquiredType: [],
    ActivityLevel: [],
    AgeUnits: [],
    AlertColor: [],
    AlertType: [],
    AnimalEnvironment: [],
    AnswerInputTypes: [],
    AntechLabLocations: [],
    AppointmentCancellationReason: [],
    AppointmentCommunicationInitializationType: [],
    AppointmentCommunicationOffsetPoint: [],
    AppointmentCommunicationTimeUnits: [],
    AppointmentCommunicationTriggeringStatus: [],
    AppointmentTypes: [],
    AppointmentsWidgetAssignedMode: {},
    AutoshipUnit: [],
    AvailabilityRuleRecurrenceType: [],
    AvailabilityRuleType: [],
    BoopVariations: [],
    Breed: {},
    BusinessRootId: '',
    BusinessStatus: [],
    CapillaryRefillTime: {},
    ClientPatientSnapshotWidgets: [],
    ClientRoleId: '',
    ClockoutReason: [],
    CommunicationTransportBackupOption: [],
    CommunicationsWidgetAssignedMode: {},
    ContactMethod: [],
    ContactTypes: [],
    ControlledSubstanceSchedules: [],
    CountryList: [],
    CreditAdjustmentReason: [],
    CurrencyList: [],
    CustomQuestionFrequencies: [],
    DashboardLabTestStates: [],
    DepartmentTypes: [],
    DiagnosisProblemStates: [],
    DiagnosisStatus: [],
    DictionaryScope: [],
    Dimensions: {},
    DiscountSource: [],
    DisplayLocations: [],
    DocumentInputAction: [],
    DocumentTypes: [],
    DrugAdministrationFrequencies: [],
    DrugDeliveryMethod: [],
    DrugDosageType: [],
    DrugStrengthColor: [],
    DrugStrengthUnit: [],
    EntityTypes: [],
    EstimateStates: [],
    EventState: [],
    EventType: [],
    EventTypeResponsibility: [],
    ExpirationTimeUnits: [],
    FearAnxietyScore: [],
    FeatureToggles: [],
    FinalizeTimeUnits: [],
    FindingProblemStates: [],
    FullAlertType: [],
    Gender: [],
    GroupSharingModes: [],
    ImagingModalities: [],
    ImagingOrderStatuses: [],
    ImagingVendors: [],
    InsuranceProvider: [],
    InsuranceProvidersPerCountry: {},
    InventoryAdjustmentReasons: [],
    InventoryCategory: [],
    InventoryCostTypes: [],
    InventoryDistributors: [],
    InventoryInStockUnit: [],
    InventoryManufacturers: [],
    InventoryOnHandChangeType: [],
    InventoryOnHandStatus: [],
    InventoryOrderStatus: [],
    InventoryPriceUnit: [],
    InventoryProductSizeUnit: [],
    InventoryShipmentItemStatus: [],
    InventoryShipmentSource: [],
    InventoryShipmentStatus: [],
    InventoryStatuses: [],
    InventorySubCategory: {},
    InventoryUploadItemMatchReasons: [],
    InvoiceStates: [],
    LabOrderStatuses: [],
    LabTestStates: [],
    LabTestTypes: [],
    LabVendors: [],
    LabelTemplates: [],
    LabelTemplatesFormatted: [],
    LabelTemplatesHtml: [],
    LabsWidgetAssignedMode: {},
    LandingWidgets: [],
    Languages: [],
    LicenseTypes: [],
    Locations: {},
    MarketplaceCategory: [],
    MarketplaceType: [],
    MarketplaceWorkflow: [],
    MeasurementTypes: [],
    MentationTypes: {},
    MucousMembraneColors: {},
    MucousMembraneMoistures: {},
    NoteType: [],
    NotificationArea: [],
    NotificationNotifyForItem: [],
    NotificationStyle: [],
    NotificationType: [],
    OnboardingPracticeType: [],
    OrderFilters: [],
    PDMPReportVersions: [],
    PackageType: [],
    PainScore: [],
    PayGoOptionsList: [],
    PayPassOptionsList: [],
    PayStatusList: [],
    PaymentCardType: [],
    PaymentMethod: [],
    PaymentSourceType: [],
    PaymentTransactionState: [],
    PaymentType: [],
    Pims: [],
    PimsFieldToConstants: [],
    PimsFull: [],
    Placeholders: [],
    PracticeCloningItems: [],
    PracticeTypes: [],
    PreferenceAttitudeTowardsStaff: [],
    PreferenceCadenceOfVisits: [],
    PreferenceCautions: [],
    PreferenceDayOfWeek: [],
    PreferenceDrugs: [],
    PreferenceExtentOfCare: [],
    PreferenceFinancialComfort: [],
    PreferenceGenderIdentity: [],
    PreferenceLabWorkFrequencies: [],
    PreferenceClientPerformedTreatmentsLocations: [],
    PreferenceMedicalAlerts: [],
    PreferenceNeedsExtendedAppointmentTime: [],
    PreferencePaymentMethod: [],
    PreferencePatientPerformedTreatmentsLocations: [],
    PreferencePremedicationVaccineReactions: [],
    PreferenceSedations: [],
    PreferenceServicePreferences: [],
    PreferenceTimeOfDay: [],
    PreferenceVenipunctures: [],
    PrescriptionCompoundingReason: [],
    PrescriptionProductSizeUnit: [],
    PrescriptionStates: [],
    PriceUnits: [],
    ProblemBodySystemStates: [],
    ProblemBodySystems: [],
    ProblemEnums: [],
    ProcedureCategory: [],
    ProcedurePriceUnit: [],
    ProcedurePriceUnits: [],
    ProcedureStatus: [],
    ProductForm: [],
    ProductSizeUnit: [],
    PulseCharacterTypes: {},
    RPCommunicationOptionsDefaultReminderMessage: '',
    RabiesTagDefaultExpiration: [],
    RefundDisabledReason: [],
    RefundInvoiceState: [],
    RefundReason: [],
    ReminderProtocolTimeUnits: [],
    ReminderTemplateTimeUnits: [],
    RetailOrderStates: [],
    ScheduleColumnNames: [],
    ServiceAnimal: [],
    Size: [],
    SkinTurgors: {},
    SoapAttachmentType: [],
    SpaceType: [],
    SpayedNeuteredStatus: [],
    Species: [],
    StaticSoapWidget: [],
    Tag: [],
    TaskTemplateOptions: {},
    TaskTimeUnits: [],
    TasksWidgetAssignedMode: {},
    TemperatureUnits: [],
    TimeOffsets: [],
    TimeUnits: [],
    TimezoneGroups: [],
    Timezones: [],
    VaccineDefaultAmount: [],
    VaccineDefaultDeliveryLocation: [],
    VaccineDefaultDoseType: [],
    VaccineDefaultDurationOfImmunity: [],
    VaccineDeliveryMethod: [],
    VaccineType: [],
    VitalRecumbency: [],
    WeightUnits: [],
    WorkFlowTypes: [],
    WplanCommunicationPromoteType: [],
    WplanCoverAppointmentType: [],
    WplanLimitType: [],
    WplanPriceType: [],
    WplanType: [],
    inventoryAdjustmentMethod: [],
  },
  isLoading: false,
  error: null,
}

const constants = (
  state = CONSTANTS_INITIAL_STATE,
  action: AnyAction,
): ConstantsState => {
  switch (action.type) {
    case FETCH_CONSTANTS:
    case FETCH_CURRENT_USER:
      return {
        ...state,
        error: null,
        isLoading: true,
      }
    case FETCH_CONSTANTS_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          ...action.constants,
        },
        isLoading: false,
      }
    case FETCH_SECURE_CONSTANTS_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          ...enrichConstants(action.constants),
        },
        isLoading: false,
      }
    case FETCH_CONSTANTS_FAILURE:
    case FETCH_SECURE_CONSTANTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    default:
      return state
  }
}

export default constants
export const getConstants = (state: RootState): ConstantsState =>
  state.constants
export const getConstantMap = (state: RootState) => getConstants(state).map
export const getConstant = <K extends keyof Constants>(name: K) =>
  createSelector(getConstantMap, (constant) => constant[name])
export const getConstantsIsLoading = (state: RootState) =>
  getConstants(state).isLoading
export const getConstantsError = (state: RootState) => getConstants(state).error

export const getAcquiredType = getConstant('AcquiredType')
export const getActivityLevel = getConstant('ActivityLevel')
export const getAgeUnits = getConstant('AgeUnits')
export const getAlertColor = getConstant('AlertColor')
export const getAlertType = getConstant('AlertType')
export const getAnimalEnvironment = getConstant('AnimalEnvironment')
export const getAnswerInputTypes = getConstant('AnswerInputTypes')
export const getAntechLabLocations = getConstant('AntechLabLocations')
export const getAppointmentCancellationReason = getConstant(
  'AppointmentCancellationReason',
)
export const getAppointmentCommunicationInitializationType = getConstant(
  'AppointmentCommunicationInitializationType',
)
export const getAppointmentCommunicationOffsetPoint = getConstant(
  'AppointmentCommunicationOffsetPoint',
)
export const getAppointmentCommunicationTimeUnits = getConstant(
  'AppointmentCommunicationTimeUnits',
)
export const getAppointmentCommunicationTriggeringStatus = getConstant(
  'AppointmentCommunicationTriggeringStatus',
)
export const getAppointmentTypes = getConstant('AppointmentTypes')
export const getAppointmentsWidgetAssignedMode = getConstant(
  'AppointmentsWidgetAssignedMode',
)
export const getAvailabilityRuleRecurrenceType = getConstant(
  'AvailabilityRuleRecurrenceType',
)
export const getAvailabilityRuleType = getConstant('AvailabilityRuleType')
export const getBoopVariations = getConstant('BoopVariations')
export const getBreed = getConstant('Breed')
export const getBusinessRootId = getConstant('BusinessRootId')
export const getBusinessStatus = getConstant('BusinessStatus')
export const getClientPatientSnapshotWidgets = getConstant(
  'ClientPatientSnapshotWidgets',
)
export const getClientRoleId = getConstant('ClientRoleId')
export const getClockOutReasons = getConstant('ClockoutReason')
export const getCommunicationTransportBackupOption = getConstant(
  'CommunicationTransportBackupOption',
)
export const getCommunicationsWidgetAssignedMode = getConstant(
  'CommunicationsWidgetAssignedMode',
)
export const getContactMethod = getConstant('ContactMethod')
export const getAutoshipUnit = getConstant('AutoshipUnit')
export const getContactTypes = getConstant('ContactTypes')
export const getControlledSubstanceSchedules = getConstant(
  'ControlledSubstanceSchedules',
)
export const getCountryList = getConstant('CountryList')
export const getCreditAdjustmentReason = getConstant('CreditAdjustmentReason')
export const getCurrencyList = getConstant('CurrencyList')
export const getCustomQuestionFrequencies = getConstant(
  'CustomQuestionFrequencies',
)
export const getDashboardLabTestStates = getConstant('DashboardLabTestStates')
export const getDepartmentTypes = getConstant('DepartmentTypes')
export const getDiagnosisProblemStates = getConstant('DiagnosisProblemStates')
export const getDiagnosisStatus = getConstant('DiagnosisStatus')
export const getDictionaryScope = getConstant('DictionaryScope')
export const getDimensions = getConstant('Dimensions')
export const getDiscountSource = getConstant('DiscountSource')
export const getDisplayLocations = getConstant('DisplayLocations')
export const getDocumentInputAction = getConstant('DocumentInputAction')
export const getDocumentTypes = getConstant('DocumentTypes')
export const getDrugAdministrationFrequencies = getConstant(
  'DrugAdministrationFrequencies',
)
export const getDrugDeliveryMethod = getConstant('DrugDeliveryMethod')
export const getDrugDosageType = getConstant('DrugDosageType')
export const getDrugStrengthColor = getConstant('DrugStrengthColor')
export const getDrugStrengthUnit = getConstant('DrugStrengthUnit')
export const getEntityTypes = getConstant('EntityTypes')
export const getEstimateStates = getConstant('EstimateStates')
export const getEventState = getConstant('EventState')
export const getEventType = getConstant('EventType')
export const getExpirationTimeUnits = getConstant('ExpirationTimeUnits')
export const getFearAnxietyScore = getConstant('FearAnxietyScore')
export const getFeatureToggles = getConstant('FeatureToggles')
export const getFinalizeTimeUnits = getConstant('FinalizeTimeUnits')
export const getFullAlertType = getConstant('FullAlertType')
export const getGender = getConstant('Gender')
export const getGroupSharingModes = getConstant('GroupSharingModes')
export const getImagingModalities = getConstant('ImagingModalities')
export const getImagingOrderStatuses = getConstant('ImagingOrderStatuses')
export const getImagingVendors = getConstant('ImagingVendors')
export const getInsuranceProvidersPerCountry = getConstant(
  'InsuranceProvidersPerCountry',
)
export const getCountryInsuranceProviderByCode = (
  countryCatalogCode: CountryCode | undefined,
) =>
  createSelector(
    getInsuranceProvidersPerCountry,
    (InsuranceProvidersPerCountry) =>
      (InsuranceProvidersPerCountry[
        countryCatalogCode || CountryCode.US
      ] as Constant[]) || [],
  )
export const getInventoryAdjustmentMethod = getConstant(
  'inventoryAdjustmentMethod',
)
export const getInventoryAdjustmentReasons = getConstant(
  'InventoryAdjustmentReasons',
)
export const getSelectableInventoryAdjustmentReasons = createSelector(
  getInventoryAdjustmentReasons,
  (inventoryAdjustmentReasons) =>
    inventoryAdjustmentReasons.filter(
      (reason) =>
        !includesState(reason.name, NotSelectableInventoryAdjustmentReasons),
    ),
)
export const getInventoryCategory = getConstant('InventoryCategory')
export const getInventoryCostTypes = getConstant('InventoryCostTypes')
export const getInventoryDistributors = getConstant('InventoryDistributors')
export const getInventoryInStockUnit = getConstant('InventoryInStockUnit')
export const getInventoryManufacturers = getConstant('InventoryManufacturers')
export const getInventoryOnHandChangeType = getConstant(
  'InventoryOnHandChangeType',
)
export const getInventoryOnHandStatus = getConstant('InventoryOnHandStatus')
export const getInventoryOrderStatus = getConstant('InventoryOrderStatus')
export const getInventoryPriceUnit = getConstant('InventoryPriceUnit')
export const getInventoryProductSizeUnit = getConstant(
  'InventoryProductSizeUnit',
)
export const getInventoryShipmentItemStatus = getConstant(
  'InventoryShipmentItemStatus',
)
export const getInventoryShipmentSource = getConstant('InventoryShipmentSource')
export const getInventoryShipmentStatus = getConstant('InventoryShipmentStatus')
export const getInventoryStatuses = getConstant('InventoryStatuses')
export const getInventorySubCategory = getConstant('InventorySubCategory')
export const getInventoryUploadItemMatchReasons = getConstant(
  'InventoryUploadItemMatchReasons',
)
export const getInvoiceStates = getConstant('InvoiceStates')
export const getLabOrderStatuses = getConstant('LabOrderStatuses')
export const getLabTestTypes = getConstant('LabTestTypes')
export const getLabTestsStates = getConstant('LabTestStates')
export const getLabVendors = getConstant('LabVendors')
export const getLabelTemplates = getConstant('LabelTemplates')
export const getLabelTemplatesFormatted = getConstant('LabelTemplatesFormatted')
export const getLabelTemplatesHtml = getConstant('LabelTemplatesHtml')
export const getLabsWidgetAssignedMode = getConstant('LabsWidgetAssignedMode')
export const getLandingWidgets = getConstant('LandingWidgets')
export const getLanguages = getConstant('Languages')
export const getLicenseTypes = getConstant('LicenseTypes')
export const getLocations = getConstant('Locations')
export const getMarketplaceCategory = getConstant('MarketplaceCategory')
export const getMarketplaceType = getConstant('MarketplaceType')
export const getMarketplaceWorkflow = getConstant('MarketplaceWorkflow')
export const getMeasurementTypes = getConstant('MeasurementTypes')
export const getMentationTypes = getConstant('MentationTypes')
export const getMucousMembraneColors = getConstant('MucousMembraneColors')
export const getMucousMembraneMoistures = getConstant('MucousMembraneMoistures')
export const getNoteType = getConstant('NoteType')
export const getNotificationArea = getConstant('NotificationArea')
export const getNotificationNotifyForItem = getConstant(
  'NotificationNotifyForItem',
)
export const getNotificationStyle = getConstant('NotificationStyle')
export const getNotificationType = getConstant('NotificationType')
export const getOnboardingPracticeType = getConstant('OnboardingPracticeType')
export const getOrderFilters = getConstant('OrderFilters')
export const getPDMPReportVersions = getConstant('PDMPReportVersions')
export const getPackageType = getConstant('PackageType')
export const getPainScore = getConstant('PainScore')
export const getPayGoConfigList = getConstant('PayGoOptionsList')
export const getPayPassConfigList = getConstant('PayPassOptionsList')
export const getPayStatusList = getConstant('PayStatusList')
export const getPaymentCardType = getConstant('PaymentCardType')
export const getPaymentMethod = getConstant('PaymentMethod')
export const getPaymentSourceType = getConstant('PaymentSourceType')
export const getPaymentTransactionState = getConstant('PaymentTransactionState')
export const getPaymentType = getConstant('PaymentType')
export const getPims = getConstant('Pims')
export const getPimsFieldToConstants = getConstant('PimsFieldToConstants')
export const getPimsFull = getConstant('PimsFull')
export const getPlaceholders = getConstant('Placeholders')
export const getPracticeCloningItems = getConstant('PracticeCloningItems')
export const getPracticeTypes = getConstant('PracticeTypes')
export const getPreferenceAttitudeTowardsStaff = getConstant(
  'PreferenceAttitudeTowardsStaff',
)
export const getPreferenceCadenceOfVisits = getConstant(
  'PreferenceCadenceOfVisits',
)
export const getPreferenceCautions = getConstant('PreferenceCautions')
export const getPreferenceDayOfWeek = getConstant('PreferenceDayOfWeek')
export const getPreferenceDrugs = getConstant('PreferenceDrugs')
export const getPreferenceExtentOfCare = getConstant('PreferenceExtentOfCare')
export const getPreferenceFinancialComfort = getConstant(
  'PreferenceFinancialComfort',
)
export const getPreferenceGenderIdentity = getConstant(
  'PreferenceGenderIdentity',
)
export const getPreferenceLabWorkFrequencies = getConstant(
  'PreferenceLabWorkFrequencies',
)
export const getPreferenceClientPerformedTreatmentsLocations = getConstant(
  'PreferenceClientPerformedTreatmentsLocations',
)
export const getPreferenceMedicalAlerts = getConstant('PreferenceMedicalAlerts')
export const getPreferenceNeedsExtendedAppointmentTime = getConstant(
  'PreferenceNeedsExtendedAppointmentTime',
)
export const getPreferencePaymentMethod = getConstant('PreferencePaymentMethod')
export const getPreferencePatientPerformedTreatmentsLocations = getConstant(
  'PreferencePatientPerformedTreatmentsLocations',
)
export const getPreferencePremedicationVaccineReactions = getConstant(
  'PreferencePremedicationVaccineReactions',
)
export const getPreferenceSedations = getConstant('PreferenceSedations')
export const getPreferenceServicePreferences = getConstant(
  'PreferenceServicePreferences',
)
export const getPreferenceTimeOfDay = getConstant('PreferenceTimeOfDay')
export const getPreferenceVenipunctures = getConstant('PreferenceVenipunctures')
export const getPrescriptionCompoundingReason = getConstant(
  'PrescriptionCompoundingReason',
)
export const getPrescriptionProductSizeUnit = getConstant(
  'PrescriptionProductSizeUnit',
)
export const getPrescriptionStates = getConstant('PrescriptionStates')
export const getPriceUnits = getConstant('PriceUnits')
export const getProblemBodySystemStates = getConstant('ProblemBodySystemStates')
export const getProblemBodySystemONLState = createSelector(
  getProblemBodySystemStates,
  R.find(R.propEq('name', 'ONL')),
)
export const getProblemBodySystems = getConstant('ProblemBodySystems')
export const getProblemEnums = getConstant('ProblemEnums')
export const getProcedureCategory = getConstant('ProcedureCategory')
export const getProcedurePriceUnits = getConstant('ProcedurePriceUnits')
export const getProcedureStatus = getConstant('ProcedureStatus')
export const getProductForm = getConstant('ProductForm')
export const getProductSizeUnit = getConstant('ProductSizeUnit')
export const getPulseCharacterTypes = getConstant('PulseCharacterTypes')
export const getCapillaryRefillTime = getConstant('CapillaryRefillTime')
export const getRabiesTagDefaultExpiration = getConstant(
  'RabiesTagDefaultExpiration',
)
export const getRefundDisabledReason = getConstant('RefundDisabledReason')
export const getRefundInvoiceState = getConstant('RefundInvoiceState')
export const getRefundReason = getConstant('RefundReason')
export const getReminderProtocolTimeUnits = getConstant(
  'ReminderProtocolTimeUnits',
)
export const getReminderTemplateTimeUnits = getConstant(
  'ReminderTemplateTimeUnits',
)
export const getResponsibilities = getConstant('EventTypeResponsibility')
export const getRetailOrderStates = getConstant('RetailOrderStates')
export const getScheduleColumnNames = getConstant('ScheduleColumnNames')
export const getServiceAnimal = getConstant('ServiceAnimal')
export const getSize = getConstant('Size')
export const getSkinTurgors = getConstant('SkinTurgors')
export const getSoapAttachmentType = getConstant('SoapAttachmentType')
export const getSpaceType = getConstant('SpaceType')
export const getSpayedNeuteredStatus = getConstant('SpayedNeuteredStatus')
export const getSpecies = getConstant('Species')
export const getStaticSoapWidget = getConstant('StaticSoapWidget')
export const getTag = getConstant('Tag')
export const getTaskTemplateOptions = getConstant('TaskTemplateOptions')
export const getTaskTimeUnits = getConstant('TaskTimeUnits')
export const getTasksWidgetAssignedMode = getConstant('TasksWidgetAssignedMode')
export const getTemperatureUnits = getConstant('TemperatureUnits')
export const getTimeOffsets = getConstant('TimeOffsets')
export const getTimeUnits = getConstant('TimeUnits')
export const getTimezoneGroups = getConstant('TimezoneGroups')
export const getTimezones = getConstant('Timezones')
export const getVaccineDefaultAmount = getConstant('VaccineDefaultAmount')
export const getVaccineDefaultDeliveryLocation = getConstant(
  'VaccineDefaultDeliveryLocation',
)
export const getVaccineDefaultDoseType = getConstant('VaccineDefaultDoseType')
export const getVaccineDefaultDurationOfImmunity = getConstant(
  'VaccineDefaultDurationOfImmunity',
)
export const getVaccineDeliveryMethod = getConstant('VaccineDeliveryMethod')
export const getVaccineType = getConstant('VaccineType')
export const getVitalRecumbency = getConstant('VitalRecumbency')
export const getWeightUnits = getConstant('WeightUnits')
export const getWellnessPlanCommunicationPromoteType = getConstant(
  'WplanCommunicationPromoteType',
)
export const getWellnessPlanCoverAppointmentType = getConstant(
  'WplanCoverAppointmentType',
)
export const getWellnessPlanLimitType = getConstant('WplanLimitType')
export const getWellnessPlanPriceType = getConstant('WplanPriceType')
export const getWellnessPlanType = getConstant('WplanType')
export const getWorkFlowTypes = getConstant('WorkFlowTypes')

export const getSupportedLabelTemplatesHtml =
  (labelType: DymoLabelType | DymoLabelHtmlTypes, deviceType: string) =>
  (state: RootState) => {
    const LabelTemplatesHtml: LabelTemplatesHtmlConstant[] =
      getLabelTemplatesHtml(state)
    return LabelTemplatesHtml?.filter(({ supportedTypes }) =>
      supportedTypes?.includes(labelType),
    )?.filter(
      ({ supportedDeviceTypes }) =>
        !deviceType || supportedDeviceTypes?.includes(deviceType),
    )
  }

export const getReadyForOrderLabStates = (state: RootState) => {
  const LabTestStates = getLabTestsStates(state)
  return findAllByName(LabTestStates, ReadyToOrderLabStatuses)
}

export const getSelectableLabTestsStates = createSelector(
  getLabTestsStates,
  (labTestsStates) =>
    labTestsStates.filter(
      (item) => !includesState(item.name, NotSelectableLabTestStates),
    ),
)

export const getConstantById =
  (constantName: keyof Constants, id: string | Nil) => (state: RootState) => {
    const constant = getConstant(constantName)(state) as Constant[]

    return R.find(R.propEq('id', id))(constant)
  }

export const getConstantName =
  (constantName: keyof Constants, id: string | Nil) => (state: RootState) =>
    id
      ? (getConstantById(constantName, id)(state) as Constant)?.name
      : undefined

export const getConstantTranslatedName =
  (constantName: keyof Constants, id: string | Nil) => (state: RootState) => {
    const constant = getConstantById(constantName, id)(state) as Constant
    return constant?.nameTranslation || constant?.name
  }

export const getLocationDisplayName = (id: string | Nil) =>
  createSelector(getLocations, (locations) =>
    id ? locations[id]?.nameTranslation || '' : '',
  )

const getFeatureToggleObject = (featureName: FeatureToggle | Nil) =>
  createSelector(getFeatureToggles, (FeatureToggles) => {
    if (!featureName) {
      return null
    }

    return Utils.findConstantByName(
      featureName,
      FeatureToggles,
    ) as FeatureTogglesConstant
  })

const getFeatureToggleValueForBusinessId = (
  FeatureToggleObject: FeatureTogglesConstant | null,
  businessId: string | undefined,
) => {
  if (!Utils.isProduction() && window.pbt) {
    const value = window.pbt.getFTValue(FeatureToggleObject?.name) as boolean

    if (!R.isNil(value)) {
      return value
    }
  }

  if (!FeatureToggleObject) {
    return false
  }
  const { businessIds = [], enabled } = FeatureToggleObject

  const isFTBusinessEmpty = R.isEmpty(businessIds)
  const isFTEnabledForCurrentBusiness = businessId
    ? businessIds.includes(businessId)
    : false
  return isFTBusinessEmpty ? enabled : isFTEnabledForCurrentBusiness && enabled
}

export const getFeatureToggle = (featureName: FeatureToggle | Nil) =>
  createSelector(
    getFeatureToggleObject(featureName),
    getCurrentBusinessId,
    (FeatureToggleObject, currentBusinessId) =>
      getFeatureToggleValueForBusinessId(
        FeatureToggleObject,
        currentBusinessId,
      ),
  )

export const getFeatureToggleForBusiness = (
  featureName: FeatureToggle | Nil,
  businessId: string | undefined,
) =>
  createSelector(getFeatureToggleObject(featureName), (FeatureToggleObject) =>
    getFeatureToggleValueForBusinessId(FeatureToggleObject, businessId),
  )

export const getFeatureToggleBusinessesIds = (
  featureName: FeatureToggle | Nil,
) =>
  createSelector(getFeatureToggleObject(featureName), (FeatureToggleObject) => {
    if (!Utils.isProduction() && window.pbt) {
      const value = window.pbt.getBusinessesForFT(featureName) as string

      if (!R.isNil(value)) {
        return value
      }
    }
    return FeatureToggleObject ? FeatureToggleObject?.businessIds : []
  })

export const getFindingProblemStates = createSelector(
  getConstant('FindingProblemStates'),
  getFeatureToggle(FeatureToggle.PERSIST_EXAM_FINDINGS_FOR_SOAP),
  (states, isPersistExamFindingsEnabled) =>
    isPersistExamFindingsEnabled
      ? states
      : states.filter(
          (state) => state.name !== 'Chronic' && state.name !== 'Resolved',
        ),
)

export const getInternalCancellationReasons = createSelector(
  getAppointmentCancellationReason,
  (appointmentCancellationReason) =>
    appointmentCancellationReason.filter((item) => !item.clientFacing),
)

export const getChewyCheckoutEnabled = createSelector(
  getCurrentBusinessIsOmniChannel,
  getFeatureToggle(FeatureToggle.IPO_M1_CHECKOUT),
  (isOmniChannelClinic, isIpoM1CheckoutEnabled) =>
    isOmniChannelClinic && isIpoM1CheckoutEnabled,
)
