import { useSelector } from 'react-redux'
import { moment, User } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'
import { Order, Prescription } from '~/types'

import { PrescriptionBodyProps } from '../PrescriptionBody'

interface UsePrescriptionSignatureProps {
  currentDoctor: User | undefined
  isChewyActiveRx: boolean
  prescription: Prescription
  prescriptionDetails: Prescription | null
  soapId: string | undefined
  unifiedOrder: Order | undefined
}

export const usePrescriptionSignature = ({
  currentDoctor,
  isChewyActiveRx,
  prescription,
  prescriptionDetails,
  soapId,
  unifiedOrder,
}: UsePrescriptionSignatureProps) => {
  const currentBusiness = useSelector(getCurrentBusiness)

  const getIsUserSignatureAreaEnabled = () => {
    if (isChewyActiveRx) {
      return currentBusiness?.useDefaultSignature?.chewyPrescription
    }
    if (soapId) {
      return currentBusiness?.useDefaultSignature?.soap
    }
    return currentBusiness?.useDefaultSignature?.otc
  }

  const isUserSignatureAreaEnabled = getIsUserSignatureAreaEnabled()

  const canApplySavedSignature =
    isUserSignatureAreaEnabled && Boolean(currentDoctor?.signatureUrl)
  const savedSignatureSigner = canApplySavedSignature
    ? currentDoctor
    : undefined
  const savedSignatureDate = canApplySavedSignature
    ? moment().toISOString()
    : undefined

  const signatureData: PrescriptionBodyProps['signatureData'] = {
    signerId:
      prescriptionDetails?.signerId ||
      prescription.signerId ||
      savedSignatureSigner?.id ||
      unifiedOrder?.signerId,
    signer:
      prescriptionDetails?.signer ||
      prescription.signer ||
      savedSignatureSigner ||
      unifiedOrder?.signer,
    signatureUrl:
      prescriptionDetails?.signatureUrl ||
      prescription.signatureUrl ||
      savedSignatureSigner?.signatureUrl ||
      unifiedOrder?.signatureUrl,
    signedDate:
      prescriptionDetails?.signedDate ||
      prescription.signedDate ||
      savedSignatureDate ||
      unifiedOrder?.signedDate,
  }

  return {
    signatureData,
    isUserSignatureAreaEnabled,
  }
}
