import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  LanguageUtils,
  SentenceFormatter,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { Wings as WingsIcon } from '@pbt/pbt-ui-components/src/icons'

import { getGenderString } from '~/components/common/inputs/gender/genderUtils'
import { getPetToMatchById } from '~/store/duck/chewyPets'
import { getGender, getSpecies } from '~/store/reducers/constants'
import { getAge } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    wingsIcon: {
      fontSize: '3.5rem',
      color: theme.colors.title,
    },
    textContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'ChewyPetMenuItem' },
)

interface ChewyPetMenuItemProps {
  patientId: string
}

export const ChewyPetMenuItem = ({ patientId }: ChewyPetMenuItemProps) => {
  const { t } = useTranslation('Common')
  const classes = useStyles()
  const patient = useSelector(getPetToMatchById(patientId))

  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)

  const { dateOfBirth, name, isDeceased, gender, species } = patient
  const age = getAge(dateOfBirth)
  const patientGender = getGenderString(gender, Gender)
  const patientSpecies = Utils.getConstantsValue(species, Species)?.name

  return (
    <>
      <Box className={classes.textContainer}>
        <Text inline strong variant="body2">{`${name}: `}</Text>
        <Text inline px={0.5} variant="body2">
          {LanguageUtils.getSentence(
            [age, patientGender, patientSpecies],
            SentenceFormatter.REGULAR,
            ', ',
          )}
        </Text>
      </Box>
      <>
        {isDeceased && (
          <>
            <WingsIcon className={classes.wingsIcon} />
            <StateLabel disabled m={0.5} variant="small">
              {t('Common:DECEASED')}
            </StateLabel>
          </>
        )}
      </>
    </>
  )
}
