import React, { ForwardedRef, forwardRef } from 'react'

import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'

import { getPlanByLevel } from '../../wellnessPlanUtils'
import WellnessPlanCustomization, {
  WellnessPlanCustomizationHandle,
  WellnessPlanCustomizationProps,
} from './WellnessPlanCustomization'

export interface WellnessPlanLevelBaseProps
  extends Omit<WellnessPlanCustomizationProps, 'plan'> {}

const WellnessPlanLevelBase = forwardRef(function WellnessPlanLevelBase(
  { wellnessPlanVersion, ...rest }: WellnessPlanLevelBaseProps,
  ref: ForwardedRef<WellnessPlanCustomizationHandle>,
) {
  const { plans = [] } = wellnessPlanVersion
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, plans)!

  return (
    <WellnessPlanCustomization
      plan={baseLevelPlan}
      ref={ref}
      wellnessPlanVersion={wellnessPlanVersion}
      {...rest}
    />
  )
})

export default WellnessPlanLevelBase
