import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import * as R from 'ramda'
import {
  LanguageUtils,
  PuiAutocomplete,
  PuiSelect,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import {
  getAllowedRoles,
  getAssignedLocations,
  getCurrentBusiness,
  getCurrentBusinessId,
} from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getStaffRolesList } from '~/store/reducers/roles'
import { isAnalyticsRole, isFieldValuesChanged } from '~/utils'
import { businessSortingFunction } from '~/utils/businessUtils'
import { isCurrentBusinessRole } from '~/utils/roleUtils'

export interface TeamMemberProps {
  isRhapsodyAnalytics?: boolean
  onlyAllowedRoles?: boolean
  showTimeTracking?: boolean
}

const TeamMember = forwardRef(function TeamMember(
  {
    isRhapsodyAnalytics,
    showTimeTracking = true,
    onlyAllowedRoles,
  }: TeamMemberProps,
  ref,
) {
  const { t } = useTranslation(['Admin', 'Common'])
  const rolesList = useSelector(getStaffRolesList)
  const AssignedLocations = useSelector(getAssignedLocations).sort(
    businessSortingFunction,
  )
  const allowedRoles = useSelector(getAllowedRoles)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const currentBusiness = useSelector(getCurrentBusiness)

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const rolesFilter = isRhapsodyAnalytics ? isAnalyticsRole : R.T
  const roleCategoryFilter = isCurrentBusinessRole(
    currentBusiness?.omniChannel && isCvcRolesEnabled,
  )

  const { fields, validate } = useFields(
    [
      {
        name: 'firstName',
        label: t('Common:FIRST_NAME'),
        validators: ['required'],
        initialValue: '',
      },
      {
        name: 'lastName',
        label: t('Common:LAST_NAME'),
        validators: ['required'],
        initialValue: '',
      },
      {
        name: 'email',
        label: t('Common:EMAIL'),
        validators: ['required', 'email'],
        initialValue: '',
      },
      {
        name: 'role',
        label: t('Admin:MEMBER.ROLE.ROLE'),
        type: 'select',
        validators: ['required'],
        initialValue: [],
      },
      {
        name: 'assignedLocation',
        label: t('Admin:MEMBER.ASSIGNED_LOCATIONS'),
        type: 'select',
        validators: ['required'],
        initialValue: onlyAllowedRoles
          ? [currentBusinessId]
          : AssignedLocations.length === 1
            ? [AssignedLocations[0].id]
            : [],
      },
      {
        name: 'timeTrackingEnabled',
        label: t('Admin:MEMBER.TURN_TIME_TRACKING_ON'),
        type: 'toggle',
        initialValue: false,
      },
      {
        name: 'lockOutsideWorkingHours',
        label: t('Admin:MEMBER.LOCK_ACCESS_OUTSIDE_PRACTICE'),
        type: 'toggle',
        initialValue: false,
      },
    ],
    false,
  )

  const {
    firstName,
    lastName,
    email,
    role,
    assignedLocation,
    timeTrackingEnabled,
    lockOutsideWorkingHours,
  } = fields

  const roles = onlyAllowedRoles ? allowedRoles : rolesList

  const filteredRoles = roles.filter(rolesFilter).filter(roleCategoryFilter)
  const sortedRoles = R.sortBy(
    LanguageUtils.getTranslatedFieldName,
    filteredRoles,
  )

  const get = () => {
    const businessToRoleList = assignedLocation.value.reduce(
      (acc: { business: string; role: string }[], id: string) => {
        const businessWithRoles = role.value.map((roleValue: string) => ({
          business: id,
          role: roleValue,
        }))
        return [...acc, ...businessWithRoles]
      },
      [],
    )

    return {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      businessToRoleList,
      timeTrackingEnabled: timeTrackingEnabled.value,
      lockOutsideWorkingHours: lockOutsideWorkingHours.value,
    }
  }

  useImperativeHandle(ref, () => ({
    validate,
    get,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <PuiTextField
          field={firstName}
          inputProps={{ maxLength: 100 }}
          label={`${firstName.label}*`}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <PuiTextField
          field={lastName}
          inputProps={{ maxLength: 100 }}
          label={`${lastName.label}*`}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <PuiTextField
          autoComplete="email"
          field={email}
          inputProps={{ maxLength: 100 }}
          label={`${email.label}*`}
          margin="none"
          type="email"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <PuiAutocomplete
          multiple
          field={role}
          label={`${role.label}*`}
          options={sortedRoles}
        />
      </Grid>
      {!onlyAllowedRoles && (
        <Grid item md={12} xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="assigned-location-select">{`${assignedLocation.label}*`}</InputLabel>
            <PuiSelect
              multiple
              field={assignedLocation}
              input={<Input id="assigned-location-select" />}
              items={AssignedLocations}
            />
          </FormControl>
        </Grid>
      )}
      {showTimeTracking && (
        <>
          <Grid item md={12} xs={12}>
            <PuiSwitch
              field={timeTrackingEnabled}
              label={timeTrackingEnabled.label}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <PuiSwitch
              field={lockOutsideWorkingHours}
              label={lockOutsideWorkingHours.label}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <RequiredFieldsNotice />
      </Grid>
    </Grid>
  )
})

export default TeamMember
