import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType } from '@pbt/pbt-ui-components'

import { useFilteredSlots } from '~/store/hooks/whiteboard'
import { getTimetableEventsMap } from '~/store/reducers/timetable'
import { WhiteboardSlot } from '~/types'

import {
  getSlotId,
  getSlotIdForPlaceholder,
  realIdToDragDrop,
} from '../timetableUtils'
import WhiteboardDroppablePlaceholder from './WhiteboardDroppablePlaceholder'
import WhiteboardEventCard from './WhiteboardEventCard'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      '&:not(:last-child)': {
        borderRight: theme.constants.filterBorder,
        paddingRight: theme.spacing(1),
      },
    },
    containerWithItems: {
      minHeight: 126,
    },
    columnHeader: {
      height: '100%',
    },
  }),
  { name: 'WhiteboardColumns' },
)

export interface WhiteboardColumnsProps {
  EmptyColumnComponent?: React.JSXElementConstructor<any>
  classes?: ClassesType<typeof useStyles>
  columns: {
    id?: string
    name: string
  }[]
  draggedItemId?: string
  id?: string
  onCardClick: (id: string) => void
  onCardClose: () => void
  openCardId?: string
  slots: Record<string, WhiteboardSlot[]>
}

const WhiteboardColumns = ({
  EmptyColumnComponent,
  openCardId,
  id: idProp = '',
  slots,
  columns,
  draggedItemId,
  onCardClick,
  onCardClose,
  classes: classesProp,
}: WhiteboardColumnsProps) => {
  const classes = useStyles({ classes: classesProp })
  const appointmentsMap = useSelector(getTimetableEventsMap)

  const slotFilter = useFilteredSlots()

  const disableDnD = Boolean(openCardId)

  const draggedAppointment = draggedItemId
    ? appointmentsMap[draggedItemId]
    : undefined

  return (
    <>
      {columns.map(({ name }) => {
        const items = slotFilter(slots[name] || [])
        const slotIdForPlaceholder = getSlotIdForPlaceholder(
          items,
          draggedAppointment,
          appointmentsMap,
        )
        const id = realIdToDragDrop(name, idProp)

        return (
          <Droppable
            droppableId={id}
            isDropDisabled={disableDnD}
            key={id}
            type={idProp}
          >
            {(droppableProvided, droppableSnapshot) => {
              const hasItems = items.length > 0
              const { isDraggingOver } = droppableSnapshot

              return (
                <Grid
                  container
                  item
                  xs
                  className={classNames(classes.container, {
                    [classes.containerWithItems]: hasItems,
                  })}
                  direction="column"
                  id={id}
                  key={name}
                  pl={1}
                  pt={1}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {hasItems ? (
                    items.map((slot, index) => {
                      const slotId = getSlotId(slot)

                      const shouldShowStartPlaceholder =
                        isDraggingOver && slotIdForPlaceholder === slotId
                      const shouldShowEndPlaceholder =
                        isDraggingOver &&
                        !slotIdForPlaceholder &&
                        index === items.length - 1
                      const noTransform =
                        isDraggingOver && slotId !== draggedItemId

                      return (
                        <WhiteboardEventCard
                          appointmentId={slotId!}
                          canOpenPopup={!openCardId || slotId === openCardId}
                          draggedItemId={draggedItemId}
                          index={index}
                          isDragDisabled={disableDnD}
                          isDraggingOver={isDraggingOver}
                          key={slotId}
                          noTransform={noTransform}
                          shouldShowEndPlaceholder={shouldShowEndPlaceholder}
                          shouldShowStartPlaceholder={
                            shouldShowStartPlaceholder
                          }
                          slot={slot}
                          type={idProp}
                          onClick={(cardId) => onCardClick(cardId!)}
                          onClose={onCardClose}
                        />
                      )
                    })
                  ) : isDraggingOver ? (
                    <WhiteboardDroppablePlaceholder />
                  ) : (
                    EmptyColumnComponent && <EmptyColumnComponent />
                  )}
                  <div style={{ display: 'none' }}>
                    {droppableProvided.placeholder}
                  </div>
                </Grid>
              )
            }}
          </Droppable>
        )
      })}
    </>
  )
}

export default WhiteboardColumns
