import React from 'react'
import {
  AppointmentEventType,
  EventTypeName,
  LanguageUtils,
  StateLabel,
  StateLabelProps,
} from '@pbt/pbt-ui-components'

import { getAppointmentColorState } from '~/components/dashboard/timetable/timetableUtils'
import { useEventType } from '~/utils/useEventType'

export interface AppointmentStateLabelProps extends StateLabelProps {
  item?: {
    state?: {
      id: string
      name: string
    }
  }
}

const AppointmentStateLabel = ({
  item,
  ...rest
}: AppointmentStateLabelProps) => {
  const AppointmentStatuses: AppointmentEventType['states'] = useEventType(
    EventTypeName.Appointment,
    'states',
  )

  const displayName = LanguageUtils.getConstantTranslatedName(
    item?.state?.id,
    AppointmentStatuses,
  )

  return (
    <StateLabel
      display="inline"
      {...{ [getAppointmentColorState(item?.state?.name)]: true }}
      {...rest}
    >
      {displayName}
    </StateLabel>
  )
}

export default AppointmentStateLabel
