import { AtLeast, Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  BaseSearchRequestParams,
  GlobalInventoryCatalogItem,
  InventoryItem,
  TableFilter,
  Variation,
} from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const searchInventory = (
  businessId: string,
  { query, max }: BaseSearchRequestParams,
) =>
  request(
    getAPIPath('inventory/items/search'),
    { method: 'GET', params: { businessId, query, max } },
    true,
  )

export const createInventory = (
  businessId: string,
  inventory: Partial<InventoryItem> | Partial<GlobalInventoryCatalogItem>,
) =>
  request(
    getAPIPath('inventory/items'),
    { method: 'POST', data: inventory, params: { businessId } },
    true,
    Schema.inventory,
  )

export const createInventoryV2 = (
  businessId: string,
  inventory: Partial<InventoryItem> | Partial<GlobalInventoryCatalogItem>,
) =>
  request(
    getAPIPath('v2/inventory/items'),
    { method: 'POST', data: inventory, params: { businessId } },
    true,
    Schema.inventory,
  )

export const editInventory = (businessId: string, inventory: InventoryItem) =>
  request(
    getAPIPath(`inventory/items/${inventory.id}`),
    { method: 'PUT', data: inventory, params: { businessId } },
    true,
    Schema.inventory,
  )

export const editInventoryV2 = (businessId: string, inventory: InventoryItem) =>
  request(
    getAPIPath(`v2/inventory/items/${inventory.id}`),
    { method: 'PUT', data: inventory, params: { businessId } },
    true,
    Schema.inventory,
  )

export const fetchInventory = (businessId: string, inventoryId: string) =>
  request(
    getAPIPath(`inventory/items/${inventoryId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.inventory,
  )

export const fetchInventoryV2 = (businessId: string, inventoryId: string) =>
  request(
    getAPIPath(`v2/inventory/items/${inventoryId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.inventory,
  )

export const fetchInventories = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  query: string | Nil,
  filters: Record<string, TableFilter['value']>[],
) =>
  request(
    getAPIPath('inventory/items'),
    { method: 'GET', params: { businessId, from, to, query, ...filters } },
    true,
    Schema.inventoriesList,
  )

export const deleteInventory = (businessId: string, inventoryId: string) =>
  request(
    getAPIPath(`inventory/items/${inventoryId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const createVariation = (
  businessId: string,
  inventoryId: string,
  variation: Variation,
) =>
  request(
    getAPIPath(`inventory/items/${inventoryId}/variation`),
    { method: 'POST', data: variation, params: { businessId } },
    true,
    Schema.variation,
  )

export const createVariations = (
  businessId: string,
  inventoryId: string,
  variations: Variation[],
) =>
  request(
    getAPIPath(`inventory/items/${inventoryId}/variations`),
    { method: 'POST', data: variations, params: { businessId } },
    true,
    Schema.variationsList,
  )

export const createVariationsV2 = (
  businessId: string,
  inventoryId: string,
  variations: Variation[],
) =>
  request(
    getAPIPath(`v2/inventory/items/${inventoryId}/variations`),
    { method: 'POST', data: variations, params: { businessId } },
    true,
    Schema.variationsList,
  )

export const updateVariation = (
  businessId: string,
  inventoryId: string,
  variation: AtLeast<Variation, 'id'>,
) =>
  request(
    getAPIPath(`inventory/items/${inventoryId}/variation/${variation.id}`),
    { method: 'PUT', data: variation, params: { businessId } },
    true,
    Schema.variation,
  )

export const partialUpdateVariation = (
  businessId: string,
  variation: AtLeast<Variation, 'id'>,
) =>
  request(
    getAPIPath(`inventory/items/variation/${variation.id}`),
    { method: 'PATCH', data: variation, params: { businessId } },
    true,
    Schema.variation,
  )

export const deleteVariation = (
  businessId: string,
  inventoryId: string,
  variationId: string,
) =>
  request(
    getAPIPath(`inventory/items/${inventoryId}/variation/${variationId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchVariationCalculatedCost = (
  businessId: string,
  itemId: string,
  variationId: string,
) =>
  request(
    getAPIPath(`inventory/items/${itemId}/variation/${variationId}/cost`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const fetchVariationPartNumberDetails = (
  businessId: string,
  globalVariationId: string,
) =>
  request(
    getAPIPath(`v2/inventory/global/variation/${globalVariationId}`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const fetchOnHandCatalog = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters: Record<string, TableFilter['value']>,
) =>
  request(
    getAPIPath('inventory/onhand'),
    { method: 'GET', params: { businessId, from, to, ...filters } },
    true,
    Schema.onHandVariationsList,
  )
