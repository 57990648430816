import React from 'react'
import { Text } from '@pbt/pbt-ui-components'

import { MembershipPlan } from '~/types'
import { formatMembershipPeriod } from '~/utils/membershipPaymentUtils'

interface PlanPeriodCellProps {
  plan: MembershipPlan
}

const PlanPeriodCell = ({ plan }: PlanPeriodCellProps) => (
  <Text variant="body2">{formatMembershipPeriod(plan)}</Text>
)

export default PlanPeriodCell
