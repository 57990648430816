import * as R from 'ramda'
import { AnyAction } from 'redux'
import { Defaults } from '@pbt/pbt-ui-components'

import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { SILENT_LOGIN_SUCCESS } from '../actions/types/auth'
import {
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST,
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_SUCCESS,
  FETCH_SUPPORT_USERS_LIST,
  FETCH_SUPPORT_USERS_LIST_FAILURE,
  FETCH_SUPPORT_USERS_LIST_SUCCESS,
  UPDATE_SUPPORT_USER_ROLES,
  UPDATE_SUPPORT_USER_ROLES_FAILURE,
  UPDATE_SUPPORT_USER_ROLES_SUCCESS,
} from '../actions/types/supportUsers'
import type { RootState } from '../index'

export type SupportUsersState = {
  error: string | null
  isFetchingList: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  totalCount: number
}

export const INITIAL_STATE: SupportUsersState = {
  list: [],
  isLoading: false,
  isFetchingList: false,
  isUpdating: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
}

const supportUsers = (
  state: SupportUsersState = INITIAL_STATE,
  action: AnyAction,
): SupportUsersState => {
  switch (action.type) {
    case SILENT_LOGIN_SUCCESS:
      return INITIAL_STATE
    case FETCH_SUPPORT_USERS_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetchingList: false,
      }
    case FETCH_SUPPORT_USERS_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetchingList: false,
      }
    case FETCH_SUPPORT_USERS_LIST:
      return {
        ...state,
        isLoading: true,
        isFetchingList: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        error: null,
      }
    case FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case UPDATE_SUPPORT_USER_ROLES: {
      return {
        ...state,
        isLoading: true,
        isUpdating: true,
        error: null,
      }
    }
    case UPDATE_SUPPORT_USER_ROLES_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
      }
    }
    case UPDATE_SUPPORT_USER_ROLES_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    }
    default:
      return state
  }
}

export default supportUsers
export const getSupportUsers = (state: RootState): SupportUsersState =>
  state.supportUsers
export const getSupportUsersList = (state: RootState) =>
  getSupportUsers(state).list
export const getSupportUserIsLoading = (state: RootState) =>
  getSupportUsers(state).isLoading
export const getSupportUsersIsFetchingList = (state: RootState) =>
  getSupportUsers(state).isFetchingList
export const getSupportUsersIsUpdating = (state: RootState) =>
  getSupportUsers(state).isUpdating
export const getSupportUsersError = (state: RootState) =>
  getSupportUsers(state).error
export const getSupportUsersTotalCount = (state: RootState) =>
  getSupportUsers(state).totalCount
