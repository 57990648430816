import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  BillingAddress,
  Defaults,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import {
  createWellnessPlanPaymentMethod,
  getWellnessPlanPaymentMethod,
  replaceWellnessPlanPaymentMethod,
} from '~/store/actions/wellnessPlans'
import { getProcessedBillingAddress } from '~/store/reducers/payments'
import {
  getWellnessPlanPendingPaymentMethod,
  getWellnessPlanPublicKey,
  getWellnessPlansIsLoading,
} from '~/store/reducers/wellnessPlans'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import BillingAddresses from '../invoices/BillingAddresses'
import StripePaymentCardDetails from '../invoices/payment/StripePaymentCardDetails'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'PatientMembershipPaymentInfoDialog' },
)

enum Steps {
  BILLING_ADDRESSES = 'BILLING_ADDRESSES',
  CARD_DETAILS = 'CARD_DETAILS',
}

interface PatientMembershipPaymentInfoDialogProps extends BasePuiDialogProps {
  billingAddress?: BillingAddress
  clientId: string | Nil
  membershipId: string
}

const PatientMembershipPaymentInfoDialog = ({
  billingAddress: defaultBillingAddress,
  membershipId,
  clientId,
  open,
  onClose,
}: PatientMembershipPaymentInfoDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const pendingPaymentMethod = useSelector(getWellnessPlanPendingPaymentMethod)
  const publicKey = useSelector(getWellnessPlanPublicKey)
  const billingAddress = useSelector(getProcessedBillingAddress)
  const { t } = useTranslation('Common')

  const StepHeaders = {
    [Steps.BILLING_ADDRESSES]: t('Common:PAYMENTS.BILLING_ADDRESS'),
    [Steps.CARD_DETAILS]: t('Common:PAYMENTS.PAYMENT_INFORMATION'),
  }

  const [isLoading, setIsLoading] = useState(false)
  const [pollingInterval, setPollingInterval] = useState<number>()
  const [step, setStep] = useState(Steps.BILLING_ADDRESSES)
  const [prevStep, setPrevStep] = useState(Steps.BILLING_ADDRESSES)

  const onReplaced = () => {
    setIsLoading(false)
    if (onClose) {
      onClose()
    }
  }

  const setCloseAfterReplacedOn = useCloseAfterCreation(
    onReplaced,
    getWellnessPlansIsLoading,
  )

  useEffect(() => {
    if (pendingPaymentMethod?.confirmed) {
      clearInterval(pollingInterval)
      setCloseAfterReplacedOn()
      dispatch(
        replaceWellnessPlanPaymentMethod(
          membershipId,
          pendingPaymentMethod?.id,
        ),
      )
    }
  }, [pendingPaymentMethod?.confirmed])

  useEffect(() => {
    if (clientId) {
      dispatch(createWellnessPlanPaymentMethod(clientId))
    }
  }, [clientId])

  useEffect(() => () => clearInterval(pollingInterval), [])

  const startPolling = () => {
    setPollingInterval(
      window.setInterval(() => {
        if (pendingPaymentMethod?.id) {
          dispatch(getWellnessPlanPaymentMethod(pendingPaymentMethod.id))
        }
      }, Defaults.DEFAULT_UPDATE_INTERVAL),
    )
  }

  const goToStep = (newStep: Steps) => {
    setPrevStep(step)
    setStep(newStep)
  }

  const onBack = () => {
    setStep(prevStep)
  }

  return (
    <PuiDialog
      aria-labelledby="patient-membership-payment-info-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={StepHeaders[step]}
      onClose={onClose}
    >
      {step === Steps.BILLING_ADDRESSES && (
        <BillingAddresses
          clientId={clientId}
          currentBillingAddress={defaultBillingAddress}
          onProceed={() => goToStep(Steps.CARD_DETAILS)}
        />
      )}
      {step === Steps.CARD_DETAILS && (
        <StripePaymentCardDetails
          billingAddress={billingAddress}
          buttonLabel={t('Common:SAVE_ACTION')}
          clientId={clientId}
          clientSecret={pendingPaymentMethod?.setupIntent?.client_secret}
          isLoading={isLoading}
          publicKey={publicKey}
          setIsLoading={setIsLoading}
          onBack={onBack}
          onProceed={startPolling}
        />
      )}
    </PuiDialog>
  )
}

export default PatientMembershipPaymentInfoDialog
