import {
  moment,
  NamedEntity,
  Role,
  RoleBusinessCategory,
  User,
} from '@pbt/pbt-ui-components'

import RoleName from '~/constants/roleNames'
import i18n from '~/locales/i18n'
import { Schedule } from '~/types'
import { getHasRole } from '~/utils/roleUtils'

import {
  activeUserFilterMatcher,
  workingTodayFilterMatcher,
} from './timetableFilterUtils'

export const SCHEDULE_WORKING_NOW = 'SCHEDULE_WORKING_NOW'
export const SCHEDULE_WORKING_TODAY = 'SCHEDULE_WORKING_TODAY'
export const ROLE_DOCTORS = 'ROLE_DOCTORS'
export const ROLE_TECHS = 'ROLE_TECHS'
export const INACTIVE_ACTIVE_USERS = 'INACTIVE_ACTIVE_USERS'

export const APPOINTMNT_EXAM = 'APPOINTMNT_EXAM'
export const APPOINTMNT_SURGERY = 'APPOINTMNT_SURGERY'
export const APPOINTMNT_OTHER = 'APPOINTMNT_OTHER'

export const getTeamMembersFilters = (
  schedules: Schedule[],
  roles: Record<string, Role>,
  businessId: string,
  businessType?: RoleBusinessCategory,
) => [
  {
    id: SCHEDULE_WORKING_NOW,
    label: i18n.t('Time:LABEL.WORKING_NOW'),
    matcher: (candidate: User) => {
      const { workingHours } =
        schedules.find((schedule) => schedule.personId === candidate.id) || {}
      return (
        Boolean(workingHours) &&
        moment().isBetween(workingHours?.from, workingHours?.to, 'minute', '[]')
      )
    },
    type: 'Schedule',
  },
  {
    id: SCHEDULE_WORKING_TODAY,
    label: i18n.t('Time:LABEL.WORKING_TODAY'),
    matcher: (candidate: User) =>
      workingTodayFilterMatcher(candidate, schedules),
    type: 'Schedule',
  },
  {
    id: ROLE_DOCTORS,
    label: i18n.t('Common:DOCTORS'),
    matcher: (candidate: User) =>
      getHasRole({
        rolesMap: roles,
        businessId,
        roleNames: RoleName.Veterinarian,
        user: candidate,
        businessType,
      }),
    type: 'Role',
  },
  {
    id: ROLE_TECHS,
    label: i18n.t('Common:TECHS'),
    matcher: (candidate: User) =>
      getHasRole({
        rolesMap: roles,
        businessId,
        roleNames: RoleName.VetTech,
        user: candidate,
        businessType,
      }),
    type: 'Role',
  },
  {
    id: INACTIVE_ACTIVE_USERS,
    label: i18n.t('Common:ACTIVE_USERS'),
    matcher: (candidate: User) => activeUserFilterMatcher(candidate, schedules),
    type: 'Inactive',
  },
]

const examMatcher = (candidate: NamedEntity) =>
  candidate.name.indexOf('Exam') === 0 || candidate.name === 'Procedure'
const surgeryMatcher = (candidate: NamedEntity) =>
  candidate.name.indexOf('Surgery') === 0

export const getAppointmentTypesFilters = () => [
  {
    id: APPOINTMNT_EXAM,
    label: i18n.t('Common:EXAM_NOUN'),
    matcher: examMatcher,
  },
  {
    id: APPOINTMNT_SURGERY,
    label: i18n.t('Common:SURGERY'),
    matcher: surgeryMatcher,
  },
  {
    id: APPOINTMNT_OTHER,
    label: i18n.t('Common:OTHER'),
    matcher: (candidate: NamedEntity) =>
      !examMatcher(candidate) && !surgeryMatcher(candidate),
  },
]
