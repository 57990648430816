import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Defaults,
  PuiDialog,
  useFields,
} from '@pbt/pbt-ui-components'

import { MessageType } from '~/constants/communications'
import { sendConversationMessage } from '~/store/actions/conversationMessages'
import { fetchMoreItemsForConversationMessagesList } from '~/store/actions/conversations'
import {
  getAttachmentsToSend,
  getConversationMessagesIsLoading,
} from '~/store/reducers/conversationMessages'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import InternalNote from './InternalNote'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'ConversationInternalNoteDialog' },
)

export interface ConversationInternalNoteDialogProps
  extends BasePuiDialogProps {
  conversationId: string
}

const ConversationInternalNoteDialog = ({
  open,
  onClose,
  conversationId,
}: ConversationInternalNoteDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const isMessageLoading = useSelector(getConversationMessagesIsLoading)
  const isLoading =
    useSelector(getConversationMessagesIsLoading) || isMessageLoading
  const files = useSelector(getAttachmentsToSend)

  const {
    fields: { messageField },
    validate,
    reset,
  } = useFields([
    {
      name: 'messageField',
      label: t('Common:MESSAGE'),
      validators: ['required'],
      initialValue: '',
    },
  ])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    reset()
  }

  const closeAfterMessageSent = useCloseAfterCreation(
    handleClose,
    getConversationMessagesIsLoading,
  )

  const onInternalNoteSent = () => {
    dispatch(
      fetchMoreItemsForConversationMessagesList(
        0,
        Defaults.CONVERSATION_MESSAGES_BATCH_LOAD_COUNT,
        conversationId,
      ),
    )
    closeAfterMessageSent()
  }

  const addInternalNote = useCloseAfterCreation(
    onInternalNoteSent,
    getConversationMessagesIsLoading,
  )

  const handleAddNote = () => {
    if (validate()) {
      dispatch(
        sendConversationMessage(
          conversationId,
          MessageType.INTERNAL_NOTE,
          messageField.value,
          files,
        ),
      )
      addInternalNote()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="conversation-internal-note-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={t('Common:ADD_INTERNAL_NOTE')}
      onClose={handleClose}
    >
      <InternalNote
        conversationId={conversationId}
        handleAddNote={handleAddNote}
        isLoading={isLoading}
        messageField={messageField}
      />
    </PuiDialog>
  )
}

export default ConversationInternalNoteDialog
