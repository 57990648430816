import {
  MutationApproveActiveRxArgs,
  MutationApproveReactiveRxArgs,
  MutationDeclineActiveRxArgs,
  MutationDeclineReactiveRxArgs,
  MutationDeleteActiveRxArgs,
  MutationLinkReactiveRxArgs,
  MutationSaveActiveRxArgs,
  MutationSaveReactiveRxArgs,
  MutationSubmitAllActiveRxForChargeSheetArgs,
  MutationSubmitAllActiveRxForSoapArgs,
  MutationUndeclineActiveRxArgs,
  QueryActiveRxWorkflowAllowanceArgs,
  QueryAllPendingActiveRxForSoapArgs,
  QueryGetAllActiveRxForSoapArgs,
  QueryGetAllPendingActiveRxForChargeSheetArgs,
  QueryGlobalItemVariationArgs,
  QueryGlobalItemVariationsArgs,
  QueryReactiveRxUnlinkedClientDetailsArgs,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  APPROVE_ACTIVE_RX_MUTATION,
  APPROVE_REACTIVE_RX_MUTATION,
  DECLINE_ACTIVE_RX,
  DECLINE_REACTIVE_RX_MUTATION,
  DELETE_ACTIVE_RX_MUTATION,
  LINK_REACTIVE_RX_MUTATION,
  SAVE_ACTIVE_RX_MUTATION,
  SAVE_REACTIVE_RX_MUTATION,
  SUBMIT_ACTIVE_RX_MUTATION,
  SUBMIT_ALL_ACTIVE_RX_FOR_CHARGE_SHEET,
  SUBMIT_ALL_ACTIVE_RX_FOR_SOAP,
  UNDECLINE_ACTIVE_RX,
} from './graphql/mutations'
import {
  FETCH_ACTIVE_RX_WORKFLOW_ALLOWANCE,
  FETCH_ALL_PENDING_ACTIVE_RX_FOR_SOAP,
  FETCH_GLOBAL_ITEM_VARIATION,
  FETCH_GLOBAL_ITEM_VARIATIONS,
  FETCH_PRESCRIPTION,
  GET_ALL_ACTIVE_RX_FOR_SOAP,
  GET_ALL_PENDING_ACTIVE_RX_FOR_CHARGE_SHEET,
  REACTIVE_RX_UNLINKED_CLIENT_DETAILS,
} from './graphql/queries'
import * as Schema from './schemas'

export const fetchPrescription = requestQuery({
  query: FETCH_PRESCRIPTION,
  variablesHandler: (businessId, prescriptionId: string) => ({
    businessId,
    prescriptionId,
  }),
  schema: Schema.prescriptionV2,
})

export const saveReactiveRx = requestMutation({
  mutation: SAVE_REACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationSaveReactiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const approveReactiveRx = requestMutation({
  mutation: APPROVE_REACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationApproveReactiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const declineReactiveRx = requestMutation({
  mutation: DECLINE_REACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationDeclineReactiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const linkReactiveRx = requestMutation({
  mutation: LINK_REACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationLinkReactiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const reactiveRxUnlinkedClientDetails = requestQuery({
  query: REACTIVE_RX_UNLINKED_CLIENT_DETAILS,
  variablesHandler: (
    businessId,
    args: QueryReactiveRxUnlinkedClientDetailsArgs,
  ) => args,
})

export const fetchActiveRxAllowance = requestQuery({
  query: FETCH_ACTIVE_RX_WORKFLOW_ALLOWANCE,
  variablesHandler: (
    _,
    { businessId, clientId, patientId }: QueryActiveRxWorkflowAllowanceArgs,
  ) => ({
    businessId,
    clientId,
    patientId,
  }),
})

export const fetchGlobalItemVariation = requestQuery({
  query: FETCH_GLOBAL_ITEM_VARIATION,
  variablesHandler: (_, { id }: QueryGlobalItemVariationArgs) => ({
    id,
  }),
})

export const fetchGlobalItemVariations = requestQuery({
  query: FETCH_GLOBAL_ITEM_VARIATIONS,
  variablesHandler: (_, { ids }: QueryGlobalItemVariationsArgs) => ({
    ids,
  }),
})

export const saveActiveRx = requestMutation({
  mutation: SAVE_ACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationSaveActiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const approveActiveRx = requestMutation({
  mutation: APPROVE_ACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationApproveActiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const submitActiveRx = requestMutation({
  mutation: SUBMIT_ACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationApproveActiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const deleteActiveRx = requestMutation({
  mutation: DELETE_ACTIVE_RX_MUTATION,
  variablesHandler: (businessId, args: MutationDeleteActiveRxArgs) => args,
})

export const declineActiveRx = requestMutation({
  mutation: DECLINE_ACTIVE_RX,
  variablesHandler: (businessId, args: MutationDeclineActiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const undeclineActiveRx = requestMutation({
  mutation: UNDECLINE_ACTIVE_RX,
  variablesHandler: (businessId, args: MutationUndeclineActiveRxArgs) => args,
  schema: Schema.prescriptionV2,
})

export const getAllActiveRxForSoap = requestQuery({
  query: GET_ALL_ACTIVE_RX_FOR_SOAP,
  variablesHandler: (_, args: QueryGetAllActiveRxForSoapArgs) => args,
})

export const submitAllActiveRxForSoap = requestMutation({
  mutation: SUBMIT_ALL_ACTIVE_RX_FOR_SOAP,
  variablesHandler: (_, args: MutationSubmitAllActiveRxForSoapArgs) => args,
})

export const submitAllActiveRxForChargeSheet = requestMutation({
  mutation: SUBMIT_ALL_ACTIVE_RX_FOR_CHARGE_SHEET,
  variablesHandler: (
    businessId,
    args: Omit<MutationSubmitAllActiveRxForChargeSheetArgs, 'businessId'>,
  ) => ({ businessId, ...args }),
})

export const getAllPendingActiveRxForChargeSheet = requestQuery({
  query: GET_ALL_PENDING_ACTIVE_RX_FOR_CHARGE_SHEET,
  variablesHandler: (_, args: QueryGetAllPendingActiveRxForChargeSheetArgs) =>
    args,
})

export const getAllPendingActiveRxForSoap = requestQuery({
  query: FETCH_ALL_PENDING_ACTIVE_RX_FOR_SOAP,
  variablesHandler: (_, args: QueryAllPendingActiveRxForSoapArgs) => args,
})
