import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import { Nil, User } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { partialUpdateClient } from '~/store/actions/clients'
import { getClientIsLoading } from '~/store/reducers/clients'
import { EmailEntityConfigRecipient } from '~/types'

import { getFirstEmailIfIsDirect } from './communicationsUtils'
import useCloseAfterCreation from './useCloseAfterCreation'
import useDialog from './useDialog'

export function useSaveEmailAndSendMessage(
  handleSendEmail: () => void,
  client: User | Nil,
  composeRecipients: () => EmailEntityConfigRecipient[] = R.always([]),
  toFieldEmails: string,
) {
  const dispatch = useDispatch()
  const sendMessageAfterSavingClientEmail = useCloseAfterCreation(
    handleSendEmail,
    getClientIsLoading,
  )
  const [openSaveClientEmailDialog] = useDialog(
    DialogNames.COMMUNICATION_SAVE_AS_CLIENT_EMAIL,
  )

  const handleSaveEmailForClientAndSend = (email: string) => {
    dispatch(partialUpdateClient({ id: client?.id as string, email }))
    sendMessageAfterSavingClientEmail()
  }

  return () => {
    const firstEmail = getFirstEmailIfIsDirect(
      composeRecipients(),
      toFieldEmails,
    )
    if (firstEmail && client?.id && !client?.email) {
      openSaveClientEmailDialog({
        email: firstEmail,
        handleSaveEmailAndSend: () =>
          handleSaveEmailForClientAndSend(firstEmail),
        handleSendEmail,
      })
    } else {
      handleSendEmail()
    }
  }
}
