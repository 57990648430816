import { useSelector } from 'react-redux'

import { getIsAreaLoading } from '~/store/duck/progress'
import {
  getSOAPisFetching,
  getSOAPisFetchingUsersOnCollaboration,
} from '~/store/reducers/soap'
import { getIsFetchingSoapTemplate } from '~/store/reducers/soapV2'

export const useIsSoapPageLoading = () => {
  const isFetching = useSelector(getSOAPisFetching)
  const isFetchingUsersOnCollaboration = useSelector(
    getSOAPisFetchingUsersOnCollaboration,
  )
  const isFinalizing = useSelector(getIsAreaLoading('soap-lock'))
  const isFetchingSoapTabs = useSelector(getIsFetchingSoapTemplate)

  const isLoading =
    isFetching ||
    isFetchingUsersOnCollaboration ||
    isFinalizing ||
    isFetchingSoapTabs

  return isLoading
}
