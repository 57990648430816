import { useSelector } from 'react-redux'
import { Constant, Nil, Utils } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import PrintLabOrderType from '~/constants/PrintLabOrderType'
import { LabOrdersStatus, orderHasResults } from '~/constants/SOAPStates'
import { LabOrder, LabTestDashboardOrder } from '~/types'
import useDialog from '~/utils/useDialog'

import { getLabOrderStatuses } from '../reducers/constants'

export const useGetOrderPreviewActions = (options?: {
  renderPrintDropdown: (
    cb: (printType: string) => void,
  ) => (button: JSX.Element) => React.ReactNode
}) => {
  const [openFinalizeLabOrderDialog] = useDialog(DialogNames.FINALIZE_LAB_ORDER)
  const [openOrderResultsDialog] = useDialog(DialogNames.ORDER_RESULTS)

  const LabOrderStatuses: Constant[] = useSelector(getLabOrderStatuses)

  return ({
    onEdit,
    onLabelPrint,
    order,
    soapId,
    soapBusinessId,
    vendorId,
  }: {
    onEdit?: (
      order: LabTestDashboardOrder | LabOrder | Nil,
      vendorId: string | Nil,
    ) => void
    onLabelPrint?: () => void
    order: LabTestDashboardOrder | LabOrder | Nil
    soapBusinessId: string | Nil
    soapId: string | Nil
    vendorId: string | Nil
  }) => {
    const { id: orderId, iframeUrl, pdfUrl, statusId } = order || {}

    const canComplete =
      Utils.getConstantName(statusId, LabOrderStatuses) ===
      LabOrdersStatus.RESULTS_RECEIVED

    const showPreview = Boolean(iframeUrl)
    const showPrint = Boolean(pdfUrl)
    const showEdit = Boolean(onEdit)

    const handleEdit = () => {
      if (onEdit) {
        onEdit(order, vendorId)
      }
    }

    const handlePrint = () => {
      openOrderResultsDialog({
        pdfUrl,
        vendorId,
        orderId,
        canComplete,
        hasResults: false,
      })
    }

    const handlePreview = () => {
      openFinalizeLabOrderDialog({
        orderId,
        soapId,
        soapBusinessId,
        url: iframeUrl,
        vendorId,
      })
    }

    const handlePrintDropdownSelect = (printType: string) => {
      if (printType === PrintLabOrderType.Order) {
        handlePrint()
      } else if (printType === PrintLabOrderType.Label && onLabelPrint) {
        onLabelPrint()
      }
    }

    const showSomething =
      (showPreview || showPrint || showEdit) && vendorId && orderId
    if (!showSomething) {
      return null
    }

    return {
      edit: showEdit && {
        handle: handleEdit,
      },
      print: showPrint && {
        handle: onLabelPrint ? undefined : handlePrint,
        renderer: onLabelPrint
          ? options?.renderPrintDropdown(handlePrintDropdownSelect)
          : undefined,
      },
      preview: showPreview && {
        handle: handlePreview,
      },
    }
  }
}

export const useGetLabResultPreviewActions = () => {
  const [openOrderResultsEmailDialog] = useDialog(
    DialogNames.EMAIL_LAB_ORDER_RESULT,
  )
  const [openOrderResultsPreviewDialog] = useDialog(
    DialogNames.VIEW_ORDER_RESULTS_FRAME,
  )
  const [openOrderResultsDialog] = useDialog(DialogNames.ORDER_RESULTS)

  const LabOrderStatusesList = useSelector(getLabOrderStatuses)
  const LabOrderStatuses = useSelector(getLabOrderStatuses)

  return ({
    order,
    vendorId,
    clientId,
    patientId,
    soapId,
  }: {
    clientId: string | Nil
    order: LabTestDashboardOrder | LabOrder | Nil
    patientId: string | Nil
    soapId: string | Nil
    vendorId: string | Nil
  }) => {
    const {
      id: orderId,
      resultPdfUrl: pdfUrl,
      resultIdentifier,
      resultIframeUrl,
      statusId,
    } = order || {}

    const state = Utils.getConstantName(statusId, LabOrderStatusesList)
    const hasResults = orderHasResults(state)

    const canComplete =
      Utils.getConstantName(statusId, LabOrderStatuses) ===
      LabOrdersStatus.RESULTS_RECEIVED

    const showPreview =
      hasResults && Boolean(resultIdentifier || resultIframeUrl)
    const showPrint = Boolean(pdfUrl)
    const showEmail = Boolean(pdfUrl)

    const handlePrint = () => {
      openOrderResultsDialog({
        pdfUrl,
        vendorId,
        orderId,
        canComplete,
        hasResults: true,
      })
    }

    const handlePreview = () => {
      openOrderResultsPreviewDialog({
        pdfUrl,
        vendorId,
        orderId,
        canComplete,
        resultIdentifier,
        resultFrameUrl: resultIframeUrl,
      })
    }

    const handleEmail = () => {
      openOrderResultsEmailDialog({
        clientId,
        patientId,
        orderId,
        vendorId,
        soapId,
      })
    }

    const showSomething =
      (showPreview || showPrint || showEmail) && vendorId && orderId

    if (!showSomething) {
      return null
    }

    return {
      print: showPrint && {
        handle: handlePrint,
      },
      email: showEmail && {
        handle: handleEmail,
      },
      preview: showPreview && {
        handle: handlePreview,
      },
    }
  }
}
