import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ResponsiveRoute from '../../../routes/ResponsiveRoute'
import { renderMultiPathRoute } from '../../../routes/utils'
import PricePage from './PricePage'
import ProceduresBulkPricesPage from './ProceduresBulkPricesPage'
import ProceduresList from './ProceduresList'

const ProceduresPage = () => (
  <Routes>
    {/* root path: /admin/catalog/procedures/ */}
    <Route
      element={
        <ResponsiveRoute
          redirectWithReplace
          xs
          redirectTo="/admin/catalog/procedures/:procedureId"
        >
          <PricePage />
        </ResponsiveRoute>
      }
      path=":procedureId/prices/create"
    />
    <Route
      element={
        <ResponsiveRoute
          redirectWithReplace
          xs
          redirectTo="/admin/catalog/procedures/:procedureId"
        >
          <PricePage />
        </ResponsiveRoute>
      }
      path=":procedureId/prices/:priceId"
    />
    <Route element={<ProceduresBulkPricesPage />} path="prices" />
    {renderMultiPathRoute({
      element: <ProceduresList />,
      path: [':procedureId', '/'],
    })}
    <Route element={<Navigate replace to="/" />} path="*" />
  </Routes>
)

export default ProceduresPage
