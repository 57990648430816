export const CREATE_VARIATION = 'variations/CREATE_VARIATION'
export const CREATE_VARIATION_FAILURE = 'variations/CREATE_VARIATION_FAILURE'
export const CREATE_VARIATION_SUCCESS = 'variations/CREATE_VARIATION_SUCCESS'

export const CREATE_VARIATIONS = 'variations/CREATE_VARIATIONS'
export const CREATE_VARIATIONS_FAILURE = 'variations/CREATE_VARIATIONS_FAILURE'
export const CREATE_VARIATIONS_SUCCESS = 'variations/CREATE_VARIATIONS_SUCCESS'

export const UPDATE_VARIATION = 'variations/UPDATE_VARIATION'
export const UPDATE_VARIATION_FAILURE = 'variations/UPDATE_VARIATION_FAILURE'
export const UPDATE_VARIATION_SUCCESS = 'variations/UPDATE_VARIATION_SUCCESS'

export const PARTIAL_UPDATE_VARIATION = 'variations/PARTIAL_UPDATE_VARIATION'

export const DELETE_VARIATION = 'variations/DELETE_VARIATION'
export const DELETE_VARIATION_FAILURE = 'variations/DELETE_VARIATION_FAILURE'
export const DELETE_VARIATION_SUCCESS = 'variations/DELETE_VARIATION_SUCCESS'

export const FETCH_VARIATION_CALCULATED_COST =
  'variation/FETCH_VARIATION_CALCULATED_COST'
export const FETCH_VARIATION_CALCULATED_COST_SUCCESS =
  'variation/FETCH_VARIATION_CALCULATED_COST_SUCCESS'
export const FETCH_VARIATION_CALCULATED_COST_FAILURE =
  'variation/FETCH_VARIATION_CALCULATED_COST_FAILURE'

export const CLEAR_VARIATION_CALCULATED_COST =
  'variation/CLEAR_VARIATION_CALCULATED_COST'

export const FETCH_VARIATION_PART_NUMBER_DETAILS =
  'variation/FETCH_VARIATION_PART_NUMBER_DETAILS'
export const FETCH_VARIATION_PART_NUMBER_DETAILS_SUCCESS =
  'variation/FETCH_VARIATION_PART_NUMBER_DETAILS_SUCCESS'
export const FETCH_VARIATION_PART_NUMBER_DETAILS_FAILURE =
  'variation/FETCH_VARIATION_PART_NUMBER_DETAILS_FAILURE'

export const CLEAR_VARIATION_PART_NUMBER_DETAILS =
  'variation/CLEAR_VARIATION_PART_NUMBER_DETAILS'
