import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    itemTotalCell: {
      minWidth: 95,
    },
  }),
  { name: 'ItemTotalCellHead' },
)

export interface ItemTotalCellHeadProps {
  className?: string
}

const ItemTotalCellHead = ({ className }: ItemTotalCellHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <TableCell
      align="right"
      className={classNames(className, classes.itemTotalCell)}
    >
      {t('Common:ITEM_TOTAL')}
    </TableCell>
  )
}

export default ItemTotalCellHead
