import * as R from 'ramda'
import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, Defaults, Patient, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { InvoiceLineItem } from '~/api/graphql/generated/types'
import AssetDestination from '~/constants/AssetDestination'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import { openDialog } from '~/store/duck/dialogs'
import { PatientUpdateReason } from '~/types'

import { clientPatientCreated, createClient } from '../actions/clients'
import {
  createPatient,
  createPatientFailure,
  createPatientSuccess,
  createPatientWithCallback,
  editPatient,
  editPatientFailure,
  editPatientSuccess,
  updatePatientParent,
  updatePatientParentFailure,
  updatePatientParentSuccess,
  updatePatientParentWithNewClient,
  updatePatientParentWithNewClientFailure,
  updatePatientParentWithNewClientSuccess,
  updatePatients,
  wsUpdatedPatientStatus,
} from '../actions/patients'
import { fetchTimeline } from '../actions/timeline'
import {
  CREATE_PATIENT,
  CREATE_PATIENT_CALLBACK,
  EDIT_PATIENT,
  UPDATE_PATIENT_PARENT,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT,
  WS_UPDATED_PATIENT_STATUS,
} from '../actions/types/patients'
import { updateUser } from '../actions/users'
import { addNewPatient } from '../duck/chewyPets'
import { registerWarnAlert } from '../duck/uiAlerts'
import { getCurrentClientId } from '../reducers/clients'
import { getFeatureToggle } from '../reducers/constants'
import { getPatient } from '../reducers/patients'
import { getUser } from '../reducers/users'
import { createClientSaga } from './clients'
import { uploadPatientAvatar } from './utils/patients'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* createPatientSaga({
  clientId,
  patient,
  avatarBlob,
}: ReturnType<typeof createPatient>) {
  try {
    const newPatient = yield* uploadPatientAvatar({
      patient,
      blob: avatarBlob,
      destination: AssetDestination.PERSON,
      payload: {
        personId: clientId,
        isPublic: true,
      },
    })

    const { result, entities } = yield* requestAPI(
      API.createPatient,
      clientId,
      newPatient,
    )
    yield call(updateEntities, entities)
    yield put(clientPatientCreated(clientId))
    yield put(createPatientSuccess(clientId, result))
  } catch (error) {
    yield put(createPatientFailure(error as ApiError))
  }
}

export function* createPatientWithCallbackSaga({
  clientId,
  patient,
  callback,
  avatarBlob,
}: ReturnType<typeof createPatientWithCallback>) {
  try {
    const newPatient = yield* uploadPatientAvatar({
      patient,
      blob: avatarBlob,
      destination: AssetDestination.PERSON,
      payload: {
        personId: clientId,
        isPublic: true,
      },
    })

    const { result, entities } = yield* requestAPI(
      API.createPatient,
      clientId,
      newPatient,
    )
    yield call(updateEntities, entities)
    yield put(clientPatientCreated(clientId))
    yield put(createPatientSuccess(clientId, result))
    yield put(addNewPatient({ patient: entities.patients[result] }))
    yield call(callback, result)
  } catch (error) {
    yield put(createPatientFailure(error as ApiError))
  }
}

export function* editPatientSaga({
  clientId,
  patient,
  avatarBlob,
}: ReturnType<typeof editPatient>) {
  const isCancelAppointmentsAndWplanForDeceasedPetEnabled: boolean =
    yield select(
      getFeatureToggle(
        FeatureToggle.CANCEL_APPOINTMENTS_AND_WPLAN_FOR_DECEASED_PET,
      ),
    )
  yield delay(Defaults.DEBOUNCE_ACTION_TIME)
  try {
    const newPatient = yield* uploadPatientAvatar({
      patient,
      blob: avatarBlob,
      destination: AssetDestination.PATIENT,
      payload: {
        personId: clientId,
        patientId: patient.id,
        isPublic: true,
      },
    })

    const oldPatient: Patient = yield select(getPatient(patient.id))
    const isPatientGotDeceased = !oldPatient.deceased && patient.deceased
    const isPatientGotInctive = oldPatient.active && patient.active === false
    const { entities } = yield* requestAPI(API.editPatient, clientId, {
      ...oldPatient,
      ...newPatient,
    })
    if (isPatientGotDeceased || isPatientGotInctive) {
      if (isCancelAppointmentsAndWplanForDeceasedPetEnabled) {
        yield put(
          openDialog({
            name: DialogNames.CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP,
            id: uuid(),
            props: {
              clientId,
              patientId: patient.id,
              patientName: patient.name!,
            },
          }),
        )
      }
    }
    if (isPatientGotDeceased) {
      yield put(fetchTimeline())
    }
    yield call(updateEntities, entities)
    yield put(editPatientSuccess(clientId, patient.id))
  } catch (err) {
    const error = err as ApiError
    if (error?.status === 409) {
      const errorMessage = i18n.t('Errors:API_ERROR.PATIENT_EDIT_CONFLICT')
      yield put(registerWarnAlert(errorMessage))
    }
    yield put(editPatientFailure(error))
  }
}

export function* updatePatientParentSaga({
  currentClientId,
  newClientId,
  patientId,
}: ReturnType<typeof updatePatientParent>) {
  try {
    const {
      result,
      entities: { users },
    } = yield* requestAPI(API.updatePatientParent, newClientId, patientId)
    const prevUser: User = yield select(getUser(currentClientId))
    const newUser = users[result]
    yield put(updateUser(newUser))
    yield put(
      updateUser({
        ...prevUser,
        patients: R.without([patientId], prevUser.patients || []),
      }),
    )
    yield put(updatePatientParentSuccess())
  } catch (error) {
    yield put(updatePatientParentFailure(error as ApiError))
  }
}

export function* updatePatientParentWithNewClientSaga({
  currentClientId,
  clientCandidate,
  patientId,
}: ReturnType<typeof updatePatientParentWithNewClient>) {
  try {
    yield createClientSaga(createClient(clientCandidate))
    const createdClientId: string = yield select(getCurrentClientId)
    yield updatePatientParentSaga(
      updatePatientParent(currentClientId, createdClientId, patientId),
    )
    yield put(updatePatientParentWithNewClientSuccess())
  } catch (error) {
    yield put(updatePatientParentWithNewClientFailure(error as ApiError))
  }
}

export function* updatedPatientStatusSaga({
  body,
}: ReturnType<typeof wsUpdatedPatientStatus>) {
  try {
    const { reason, revert, procedureLogId, patientId } = body || {}
    if (!reason) {
      return
    }
    const updatedPatient = yield* requestAPI(API.fetchPatient, patientId)

    const lineItem: InvoiceLineItem = yield requestAPI(
      API.fetchLineItemByLogId,
      procedureLogId,
      OrderType.PROCEDURE,
    )

    yield put(updatePatients({ [updatedPatient.id]: updatedPatient }))
    yield put(
      openDialog({
        name: DialogNames.AUTOMATED_PATIENT_UPDATE_ALERT,
        id: uuid(),
        unique: true,
        props: {
          patientId,
          clientId: updatedPatient?.clientId,
          patientName: updatedPatient?.name,
          patientUpdateReason: reason as PatientUpdateReason,
          patientUpdateReverted: revert,
          name: lineItem ? lineItem.name : '',
        },
      }),
    )
    yield put(updatePatientParentWithNewClientSuccess())
  } catch (error) {
    yield put(updatePatientParentFailure(error as ApiError))
  }
}

function* watchUpdatePetParentWithNewClient() {
  yield takeLeading(
    UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT,
    updatePatientParentWithNewClientSaga,
  )
}

function* watchUpdatePetParent() {
  yield takeLeading(UPDATE_PATIENT_PARENT, updatePatientParentSaga)
}

function* watchCreatePatient() {
  yield takeLeading(CREATE_PATIENT, createPatientSaga)
}

function* watchCreatePatientWithCallback() {
  yield takeLeading(CREATE_PATIENT_CALLBACK, createPatientWithCallbackSaga)
}

function* watchEditPatient() {
  yield takeLatest(EDIT_PATIENT, editPatientSaga)
}

function* watchUpdatedPatientStatus() {
  yield takeLeading(WS_UPDATED_PATIENT_STATUS, updatedPatientStatusSaga)
}

export default function* patientsSaga() {
  yield all([
    watchCreatePatient(),
    watchCreatePatientWithCallback(),
    watchEditPatient(),
    watchUpdatePetParent(),
    watchUpdatePetParentWithNewClient(),
    watchUpdatedPatientStatus(),
  ])
}
