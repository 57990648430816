import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TextWithTooltip } from '@pbt/pbt-ui-components'
import { Note } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    infoIcon: {
      cursor: 'pointer',
      position: 'absolute',
      left: 130,
      top: 0,
    },
    cellText: {
      whiteSpace: 'nowrap',
    },
    dataItem: {
      // width: 160 SCROLLABLE_ITEM_WIDTH - 32px cell padding,
      width: 128,
      padding: theme.spacing(0, 2.5, 0, 1.5),
    },
  }),
  { name: 'VitalsDataTableCell' },
)

export interface VitalsDataTableCellProps {
  id: string
  notes?: string
  value: string
}

const VitalsDataTableCell = ({
  value,
  notes,
  id,
}: VitalsDataTableCellProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      className={classes.dataItem}
      datatest-id={id}
      id="vitals-data-container-table-body-cell"
      wrap="nowrap"
    >
      <Grid container item direction="column">
        {value.split('\n').map((text) => (
          <span className={classes.cellText} key={text}>
            {text}
          </span>
        ))}
      </Grid>
      {notes && (
        <TextWithTooltip
          Icon={Note}
          classes={{ infoIcon: classes.infoIcon }}
          tooltipText={notes}
        />
      )}
    </Grid>
  )
}

export default VitalsDataTableCell
