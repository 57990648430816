import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import i18nPortal from '~/locales/i18n'

const {
  CREATION_DATE,
  DISCOUNT,
  PRODUCER,
  QUANTITY,
  REFUNDED,
  SUBTOTAL,
  TAX,
  UNIT_PRICE,
} = FINANCE_CELL_WIDTH

export const CHARGES_COLUMNS = [
  {
    id: 1,
    label: i18nPortal.t('Invoices:ORDERED_ON'),
    width: CREATION_DATE,
  },
  { id: 2, label: i18nPortal.t('Common:ITEM') },
  {
    id: 3,
    label: i18nPortal.t('Common:PRODUCER_FINANCIAL'),
    width: PRODUCER,
  },
  {
    id: 4,
    label: i18nPortal.t('Common:QUANTITY'),
    width: QUANTITY,
    subLabels: [
      { label: i18nPortal.t('Common:PAYMENTS.PAID') },
      { label: i18nPortal.t('Common:USED') },
      { label: i18nPortal.t('Common:REMAINING') },
    ],
  },
  {
    id: 5,
    label: i18nPortal.t('Common:UNIT'),
    width: UNIT_PRICE,
  },
  {
    id: 6,
    label: i18nPortal.t('Common:TOTAL'),
    width: SUBTOTAL,
  },
]

export const INVOICE_COLUMNS = [
  { id: 1, label: i18nPortal.t('Common:ITEM') },
  {
    id: 2,
    label: i18nPortal.t('Common:PRODUCER_FINANCIAL'),
    width: PRODUCER,
  },
  {
    id: 3,
    label: i18nPortal.t('Common:QUANTITY'),
    width: QUANTITY,
    subLabels: [
      { label: i18nPortal.t('Common:PAYMENTS.PAID') },
      { label: i18nPortal.t('Common:USED') },
      { label: i18nPortal.t('Common:REMAINING') },
    ],
  },
  { id: 4, label: i18nPortal.t('Common:TAX'), width: TAX },
  {
    id: 5,
    label: i18nPortal.t('Common:DISCOUNT'),
    width: DISCOUNT,
  },
  {
    id: 6,
    label: i18nPortal.t('Common:TOTAL'),
    width: SUBTOTAL,
  },
]

export const INVOICE_COLUMNS_WITH_REFUND = [
  { id: 1, label: i18nPortal.t('Common:ITEM') },
  {
    id: 2,
    label: i18nPortal.t('Common:PAYMENTS.REFUNDED'),
    width: REFUNDED,
  },
  {
    id: 3,
    label: i18nPortal.t('Common:PRODUCER_FINANCIAL'),
    width: PRODUCER,
  },
  {
    id: 4,
    label: i18nPortal.t('Common:QUANTITY'),
    width: QUANTITY,
    subLabels: [
      { label: i18nPortal.t('Common:PAYMENTS.PAID') },
      { label: i18nPortal.t('Common:USED') },
      { label: i18nPortal.t('Common:REMAINING') },
    ],
  },
  { id: 5, label: i18nPortal.t('Common:TAX'), width: TAX },
  {
    id: 6,
    label: i18nPortal.t('Common:DISCOUNT'),
    width: DISCOUNT,
  },
  {
    id: 7,
    label: i18nPortal.t('Common:TOTAL'),
    width: SUBTOTAL,
  },
]

export const SOAP_CHARGES_COLUMNS = [
  {
    label: i18nPortal.t('Invoices:ORDERED_ON'),
    width: CREATION_DATE,
  },
  { label: i18nPortal.t('Common:ITEM') },
  {
    label: i18nPortal.t('Common:PRODUCER_FINANCIAL'),
    width: PRODUCER,
  },
  {
    label: i18nPortal.t('Common:QUANTITY'),
    width: QUANTITY,
  },
  { label: i18nPortal.t('Common:UNIT'), width: UNIT_PRICE },
  { label: i18nPortal.t('Common:TOTAL'), width: SUBTOTAL },
]
