/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ErrorInfo } from 'react'
import { Trans } from 'react-i18next'
import { Defaults } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import { ErrorsBoundaryProps, ErrorsBoundaryState } from './ErrorsBoundary'

const emailHrefWithMessage = `mailto:${Defaults.SUPPORT_EMAIL}?subject=${i18n.t(
  'Errors:LAST_GATE_ERRORS_BOUNDARY.EMAIL_SUBJECT_MESSAGE',
)}`

// eslint-disable-next-line react/require-optimization
class LastGateErrorsBoundary extends React.Component<
  ErrorsBoundaryProps,
  ErrorsBoundaryState
> {
  constructor(props: ErrorsBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      info: null,
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // eslint-disable-next-line react/no-set-state
    this.setState({ error, info })
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h1>
            <Trans
              components={{
                homePageLink: <a href="/" />,
                mailTo: (
                  <a href={emailHrefWithMessage}>{Defaults.SUPPORT_EMAIL}</a>
                ),
              }}
              i18nKey="Errors:LAST_GATE_ERRORS_BOUNDARY.MESSAGE"
            />
          </h1>
          <h2>{this.state.error.message}</h2>
          <h2>{this.state.error.stack}</h2>
          <h3>{this.state.info!.componentStack}</h3>
        </div>
      )
    }
    return this.props.children || null
  }
}

export default LastGateErrorsBoundary
