import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TextWithTooltip } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    panel: {
      width: '100%',
    },
    panelExpanded: {
      border: theme.constants.activeFieldBorder,
    },
    panelSummary: {
      padding: theme.spacing(0, 2),
      color: theme.colors.primaryText,
    },
    panelSummaryExpanded: {
      color: theme.colors.title,
    },
    panelDetails: {
      padding: theme.spacing(0, 2, 2, 2),
      flexDirection: 'column',
    },
    invalid: {
      border: theme.constants.errorBorder,
    },
  }),
  { name: 'VariationSection' },
)

export interface VariationSectionProps {
  children: React.ReactNode
  defaultExpanded?: boolean
  isValid?: boolean
  title: string
  tooltipText?: string
}

const VariationSection = ({
  children,
  defaultExpanded,
  isValid = true,
  tooltipText,
  title,
  ...rest
}: VariationSectionProps) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(Boolean(defaultExpanded))
  const [isTouched, setIsTouched] = useState(false)

  useEffect(() => {
    if (!isValid && !isTouched) {
      setExpanded(true)
      setIsTouched(true)
    }
  }, [isValid])

  const handleChange = (_: any, value: boolean) => {
    setExpanded(value)
    setIsTouched(true)
  }

  return (
    <Accordion
      classes={{
        root: classNames(classes.panel, {
          [classes.invalid]: !isValid,
        }),
        expanded: classes.panelExpanded,
      }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={handleChange}
      {...rest}
    >
      <AccordionSummary
        classes={{
          root: classes.panelSummary,
          expanded: classes.panelSummaryExpanded,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <TextWithTooltip strong tooltipText={tooltipText} variant="body">
          {title}
        </TextWithTooltip>
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default VariationSection
