import React from 'react'

import { UserSelectFilterScope } from '../inputs/UserSelect'
import PersonFilter, { PersonFilterProps } from './PersonFilter'

interface TeamMemberFilterProps
  extends Omit<PersonFilterProps, 'filterScope'> {}

const TeamMemberFilter = (props: TeamMemberFilterProps) => (
  <PersonFilter filterScope={UserSelectFilterScope.Staff} {...props} />
)

export default TeamMemberFilter
