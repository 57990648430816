import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as R from 'ramda'
import { PrimitiveTable } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import i18nPortal from '~/locales/i18n'
import {
  getIsFetchingSoapTemplate,
  getSoapActiveTemplate,
} from '~/store/reducers/soapV2'

import SoapCustomizationDetails from './details/SoapCustomizationDetails'

const columns = [
  {
    label: i18nPortal.t('Common:NAME'),
    prop: 'name',
    width: 4,
  },
]

const SoapCustomizationTable = () => {
  const { templateId: urlTemplateId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation('Common')

  const isFetching = useSelector(getIsFetchingSoapTemplate)
  const template = useSelector(getSoapActiveTemplate)

  // For this PR we will just have one template per business, but this could change in the future
  const templates = [template]

  const handleSelected = (id: string) => {
    navigate(`/admin/general/soap-customization/${id}`)
  }

  const handleClose = () => {
    navigate('/admin/general/soap-customization')
  }

  useEffect(() => {
    if (urlTemplateId !== template?.id) {
      handleClose()
    }
  }, [urlTemplateId, template?.id])

  return (
    <ExpandableTable
      Expander={SoapCustomizationDetails}
      isLoading={isFetching}
      item={R.head(templates)}
      itemId={urlTemplateId}
      list={templates}
      title={t('Common:SOAP_CUSTOMIZATION')}
      onClose={handleClose}
      onSelected={handleSelected}
    >
      <PrimitiveTable
        useWindowScroll
        columns={columns}
        isItemLoaded={(index) => Boolean(templates[index])}
        loadMoreItems={() => {}}
        totalCount={1}
      />
    </ExpandableTable>
  )
}

export default SoapCustomizationTable
