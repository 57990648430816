import { useSelector } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  InvoiceLineItem,
  InvoiceLineItemProducerInput,
  MutationUpdateInvoiceStateAndInternalNoteArgs,
  MutationVoidInvoiceArgs,
  PostInvoiceInput,
  RetailOrderLineItem,
  RetailOrderLineItemProducerInput,
} from '~/api/graphql/generated/types'
import { isAnyItemPrepaid } from '~/components/dashboard/invoices/invoiceUtils'
import { ChargeSheetItemSection } from '~/types/entities/chargesSheet'
import {
  InvoiceV3,
  InvoiceV3Events,
  InvoiceV3ItemSection,
} from '~/types/entities/invoiceV3'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export type InvoiceV3State = {
  error: string | Nil
  eventsMap: Record<string, InvoiceV3Events>
  id: string
  invoicesMap: Record<string, InvoiceV3>
  isLoading: boolean
  isNotestOrStatusLoading: boolean
  subItemsMap: Record<string, InvoiceV3ItemSection>
}

export const INITIAL_STATE: InvoiceV3State = {
  error: null,
  isLoading: false,
  id: '',
  invoicesMap: {},
  subItemsMap: {},
  eventsMap: {},
  isNotestOrStatusLoading: false,
}

const invoiceSlice = createSlice({
  name: 'invoiceV3',
  initialState: INITIAL_STATE,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    postInvoice: (state, action: PayloadAction<PostInvoiceInput>) => {
      state.isLoading = true
      state.error = null
    },
    postInvoiceSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.id = action.payload
    },
    postInvoiceFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateInvoice: (state, action: PayloadAction<any>) => {
      state.invoicesMap = action.payload
    },
    updateSubItems: (state, action: PayloadAction<any>) => {
      state.subItemsMap = action.payload
    },
    fetchInvoiceV3: (
      state,
      action: PayloadAction<{
        id: string
        includeDeleted?: boolean
      }>,
    ) => {
      state.isLoading = true
      state.id = action.payload?.id || state.id
      state.error = null
    },
    fetchInvoiceV3Success: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.id = action.payload
    },
    fetchInvoiceV3Failure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateInvoiceLineItemProducerBatch: (
      state,
      action: PayloadAction<InvoiceLineItemProducerInput[]>,
    ) => {
      const mapedPayload = R.pipe<
        (typeof state.subItemsMap)[],
        InvoiceV3ItemSection[],
        InvoiceV3ItemSection[],
        [string, ChargeSheetItemSection][],
        Record<string, ChargeSheetItemSection>
      >(
        R.values,
        R.map(({ groupedItems, ...rest }: InvoiceV3ItemSection) => ({
          groupedItems: R.chain(
            (item) =>
              R.map(
                (payload) =>
                  payload.id === item.id ? R.mergeRight(item, payload) : item,
                action.payload,
              ),
            groupedItems,
          ),
          ...rest,
        })),
        R.map((item: ChargeSheetItemSection) => [
          item.id || `${item.soapId}_${item.patientId}`,
          item,
        ]),
        R.fromPairs,
      )(state.subItemsMap)

      state.isLoading = true
      state.error = null
      state.subItemsMap = mapedPayload
    },
    updateInvoiceLineItemProducerSuccess: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<InvoiceLineItem[]>,
    ) => {
      state.isLoading = false
      state.error = null
    },
    updateInvoiceLineItemProducerFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateRetailOrderLineItemProducerBatch: (
      state,
      action: PayloadAction<RetailOrderLineItemProducerInput[]>,
    ) => {
      const mapedPayload = R.pipe<
        (typeof state.subItemsMap)[],
        InvoiceV3ItemSection[],
        InvoiceV3ItemSection[],
        [string, ChargeSheetItemSection][],
        Record<string, ChargeSheetItemSection>
      >(
        R.values,
        R.map(({ groupedItems, ...rest }: InvoiceV3ItemSection) => ({
          groupedItems: R.chain(
            (item) =>
              R.map(
                (payload) =>
                  payload.id === item.id ? R.mergeRight(item, payload) : item,
                action.payload,
              ),
            groupedItems,
          ),
          ...rest,
        })),
        R.map((item: ChargeSheetItemSection) => [
          item.id || `${item.soapId}_${item.patientId}`,
          item,
        ]),
        R.fromPairs,
      )(state.subItemsMap)

      state.isLoading = true
      state.error = null
      state.subItemsMap = mapedPayload
    },
    updateRetailOrderLineItemProducerBatchSuccess: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<RetailOrderLineItem[]>,
    ) => {
      state.isLoading = false
      state.error = null
    },
    updateRetailOrderLineItemProducerBatchFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    voidInvoice: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationVoidInvoiceArgs>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    voidInvoiceSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.id = action.payload
    },
    voidInvoiceFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    updateInvoiceNotesOrStatus: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationUpdateInvoiceStateAndInternalNoteArgs>,
    ) => {
      state.isNotestOrStatusLoading = true
      state.error = null
    },
    updateInvoiceNotesOrStatusSuccess: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.isNotestOrStatusLoading = false
      state.id = action.payload
    },
    updateInvoiceNotesOrStatusFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isNotestOrStatusLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    resetInvoiceState: () => INITIAL_STATE,
  },
})

const { actions, reducer } = invoiceSlice

export const {
  postInvoice,
  postInvoiceSuccess,
  postInvoiceFailure,
  updateInvoice,
  updateSubItems,
  fetchInvoiceV3,
  fetchInvoiceV3Success,
  fetchInvoiceV3Failure,
  updateInvoiceLineItemProducerBatch,
  updateInvoiceLineItemProducerSuccess,
  updateInvoiceLineItemProducerFailure,
  updateRetailOrderLineItemProducerBatch,
  updateRetailOrderLineItemProducerBatchSuccess,
  updateRetailOrderLineItemProducerBatchFailure,
  voidInvoice,
  voidInvoiceSuccess,
  voidInvoiceFailure,
  updateInvoiceNotesOrStatus,
  updateInvoiceNotesOrStatusSuccess,
  updateInvoiceNotesOrStatusFailure,
  resetInvoiceState,
} = actions

export default reducer

export const getInvoiceV3State: (state: RootState) => InvoiceV3State = (
  state: RootState,
) => state.invoiceV3
export const getInvoiceV3Id = (state: RootState) => getInvoiceV3State(state).id
export const getInvoiceV3Loading = (state: RootState) =>
  getInvoiceV3State(state).isLoading
export const getInvoiceV3Error = (state: RootState) =>
  getInvoiceV3State(state).error
export const getInvoicesV3Map = (state: RootState) =>
  getInvoiceV3State(state).invoicesMap
export const getInvoiceV3 = (id: string | Nil) =>
  createSelector(getInvoicesV3Map, (map: Record<string, InvoiceV3> | null) =>
    id ? map?.[id] : null,
  )
export const getInvoiceV3Current = createSelector(
  getInvoicesV3Map,
  getInvoiceV3Id,
  (map: Record<string, InvoiceV3> | null, id: string) =>
    id ? map?.[id] : null,
)
export const getInvoiceV3SubItemsMap = (state: RootState) =>
  getInvoiceV3State(state).subItemsMap
export const getInvoiceV3SubItemById = (id: string | Nil) =>
  createSelector(
    getInvoiceV3SubItemsMap,
    (map: Record<string, InvoiceV3ItemSection> | null) =>
      id ? map?.[id] : null,
  )
export const getInvoiceV3EventsMap = (state: RootState) =>
  getInvoiceV3State(state).eventsMap
export const getInvoiceV3EventsById = (ids: string[] | Nil) =>
  createSelector(getInvoiceV3EventsMap, (map) =>
    R.props(ids || [], map).filter(Boolean),
  )

export const useIsAnyPrepaid = (): boolean => {
  const invoiceSections = useSelector(getInvoiceV3SubItemsMap)
  const invoiceLineItems = Object.values(invoiceSections)
    .map((section) => section.groupedItems)
    .flatMap((ilis) => ilis)
  return isAnyItemPrepaid(invoiceLineItems)
}
