import { gql } from '@apollo/client'

export const REMINDER_FIELDS = gql`
  fragment ReminderFields on Reminder {
    id
    state {
      id
      name
    }
    name
    notes
    dueDatetime
    expireDatetime
    deleted
  }
`
