import { gql } from '@apollo/client/core'

import { PREVIEW_LIST_ITEM_FIELDS, PRICE_DTO_FIELDS } from '../fragments'

export const ACTIVE_PRICE_BULK_UPDATE_SESSION = gql`
  query ActivePriceBulkUpdateSession($entityType: EntityType!) {
    activePriceBulkUpdateSession(entityType: $entityType) {
      id
      creator {
        id
        firstName
        lastName
      }
    }
  }
`

export const PRICED_PROCEDURES = gql`
  ${PRICE_DTO_FIELDS}
  query PricedProcedures($categoryId: ID!, $from: Int!, $to: Int!) {
    pricedProcedures(categoryId: $categoryId, from: $from, to: $to) {
      totalCount
      data {
        id
        name
        speciesIds
        genderId
        prices {
          ...PriceDtoFields
        }
      }
    }
  }
`

export const PRICED_LAB_TESTS = gql`
  ${PRICE_DTO_FIELDS}
  query PricedLabTests($labVendorId: ID!, $from: Int!, $to: Int!) {
    pricedLabTests(labVendorId: $labVendorId, from: $from, to: $to) {
      totalCount
      data {
        id
        name
        typeId
        prices {
          ...PriceDtoFields
        }
      }
    }
  }
`

export const PRICED_INVENTORY_VARIATIONS = gql`
  ${PRICE_DTO_FIELDS}
  query PricedInventoryVariations(
    $categoryId: ID!
    $subcategoryId: ID!
    $from: Int!
    $to: Int!
  ) {
    pricedInventoryVariations(
      categoryId: $categoryId
      subcategoryId: $subcategoryId
      from: $from
      to: $to
    ) {
      totalCount
      data {
        id
        name
        speciesIds
        prices {
          ...PriceDtoFields
        }
      }
    }
  }
`

export const INVENTORY_PREVIEW = gql`
  ${PREVIEW_LIST_ITEM_FIELDS}
  query InventoryPreview($sessionId: ID!, $from: Int!, $to: Int!) {
    inventoryPreview(sessionId: $sessionId, from: $from, to: $to) {
      totalCount
      data {
        ...PreviewListItemFields
      }
    }
  }
`

export const PROCEDURE_PREVIEW = gql`
  ${PREVIEW_LIST_ITEM_FIELDS}
  query ProcedurePreview($sessionId: ID!, $from: Int!, $to: Int!) {
    procedurePreview(sessionId: $sessionId, from: $from, to: $to) {
      totalCount
      data {
        ...PreviewListItemFields
      }
    }
  }
`

export const LAB_TEST_PREVIEW = gql`
  ${PREVIEW_LIST_ITEM_FIELDS}
  query LabTestsPreview($sessionId: ID!, $from: Int!, $to: Int!) {
    labTestsPreview(sessionId: $sessionId, from: $from, to: $to) {
      totalCount
      data {
        ...PreviewListItemFields
      }
    }
  }
`
