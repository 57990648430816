import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { PuiDialog } from '@pbt/pbt-ui-components'

import { SessionsTypes } from '~/constants/migration'
import { chooseImportSession } from '~/store/actions/migration'
import { getCurrentSession } from '~/store/reducers/migration'
import { BaseMigrationDialogProps } from '~/types/entities/migration'

import StepSelectSession from './StepSelectSession'
import StepUploadAdditionalFiles from './StepUploadAdditionalFiles'

const UploadAdditionalFilesDialog = ({
  open,
  onClose,
  businessId,
}: BaseMigrationDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const currentSession = useSelector(getCurrentSession) || {}

  const sessionsFilterTypes = [SessionsTypes.RHAPSODY_ENTERPRISE]
  const isSessionSelected = !R.isEmpty(currentSession)

  const dialogTitle = isSessionSelected
    ? t('Common:UPLOAD_FILES')
    : t('Businesses:MIGRATION.SELECT_SESSION_TO_UPLOAD_FILES')
  const StepComponent = isSessionSelected
    ? StepUploadAdditionalFiles
    : StepSelectSession

  const handleClose = useCallback(() => {
    dispatch(chooseImportSession(null))
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="upload-additional-files-dialog"
      maxWidth="sm"
      open={open}
      title={dialogTitle}
      onClose={handleClose}
    >
      <StepComponent
        allowDisabledSessions
        isUploader
        businessId={businessId}
        sessionsFilterTypes={sessionsFilterTypes}
      />
    </PuiDialog>
  )
}

export default UploadAdditionalFilesDialog
