import React from 'react'
import { useSelector } from 'react-redux'
import {
  DashboardLabTestStateConstant,
  LanguageUtils,
  StateLabel,
} from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { ColorNames } from '~/constants/colors'
import { LabOrdersStatus } from '~/constants/SOAPStates'
import { getDashboardLabTestStates } from '~/store/reducers/constants'

import { getLabTestComplexId } from './labTestUtils'

export interface StatusFilterProps extends CheckboxListFilterProps {}

const StatusFilter = (props: StatusFilterProps) => {
  const DashboardLabTestStates: DashboardLabTestStateConstant[] = useSelector(
    getDashboardLabTestStates,
  )

  const checkboxOptions = DashboardLabTestStates.map((complexState) => ({
    ...complexState,
    id: getLabTestComplexId(complexState),
    component: (
      <StateLabel
        completed={complexState.name === LabOrdersStatus.COMPLETED}
        deleted={complexState.color === ColorNames.RED}
        disabled={complexState.color === ColorNames.GREY}
        success={
          complexState.color === ColorNames.GREEN &&
          complexState.name !== LabOrdersStatus.COMPLETED
        }
        warning={complexState.color === ColorNames.YELLOW}
      >
        {LanguageUtils.getTranslatedFieldName(complexState)}
      </StateLabel>
    ),
  }))

  return <CheckboxListFilter arrayFormat items={checkboxOptions} {...props} />
}

export default StatusFilter
