import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MultiAttachment, {
  InitialFilesMetadata,
} from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachment'
import MultiAttachmentDialog, {
  MultiAttachmentDialogProps,
} from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentDialog'
import {
  createLabTestAttachmentFileGroup,
  getAllLabTestsIsLoading,
} from '~/store/duck/labTestsDashboard'
import { AttachmentGroup, FilesWithDetails } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useInitialFiles = (initialFilesMetadata?: InitialFilesMetadata[]) => {
  const [files, setFiles] = useState<File[]>()

  useEffect(() => {
    const fetchAndCreateFiles = async () => {
      if (!initialFilesMetadata) return

      const filesPromises = initialFilesMetadata.map(async (fileData) => {
        const response = await fetch(fileData.url)
        const blob = await response.blob()
        return new File([blob], fileData.fileName, {
          type: fileData.fileType,
        })
      })

      const loadedFiles = await Promise.all(filesPromises)
      setFiles(loadedFiles)
    }

    fetchAndCreateFiles()
  }, [initialFilesMetadata, setFiles])
  return files
}

export interface LabTestAttachmentDialogProps
  extends Omit<MultiAttachmentDialogProps, 'renderMultiAttachment'> {
  clientId: string
  initialFilesMetadata: InitialFilesMetadata[]
  initialSource?: string
  initialTitle?: string
  labTestIdentifier: string
  labTestLogId: string
  patientId: string
  preexistingFileCount: number
}

const LabTestAttachmentDialog = ({
  onClose,
  open,
  initialFilesMetadata,
  initialTitle,
  initialSource,
  labTestLogId,
  clientId,
  patientId,
  labTestIdentifier,
  preexistingFileCount,
}: LabTestAttachmentDialogProps) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getAllLabTestsIsLoading)

  const setCloseAfterSavedOn = useCloseAfterCreation(
    onClose,
    getAllLabTestsIsLoading,
  )

  const onFilesUpload = (group: AttachmentGroup) => {
    setCloseAfterSavedOn()
    dispatch(
      createLabTestAttachmentFileGroup({
        labTestLogId,
        attachmentGroup: group as FilesWithDetails,
        clientId,
        patientId,
        labTestIdentifier,
      }),
    )
  }

  const initialFiles = useInitialFiles(initialFilesMetadata)

  const areInitialFilesPending = initialFilesMetadata && !initialFiles

  if (areInitialFilesPending) {
    return null
  }

  return (
    <MultiAttachmentDialog
      open={open}
      renderMultiAttachment={({
        closeEdit,
        fileIndexForImageEdit,
        setFileIndexForImageEdit,
      }) => (
        <MultiAttachment
          closeEdit={closeEdit}
          fileIndexForImageEdit={fileIndexForImageEdit}
          initialFiles={initialFiles}
          initialSource={initialSource}
          initialTitle={initialTitle}
          isSaving={isLoading}
          preexistingFileCount={preexistingFileCount}
          setFileIndexForImageEdit={setFileIndexForImageEdit}
          onFilesUpload={onFilesUpload}
        />
      )}
      onClose={onClose}
    />
  )
}

export default LabTestAttachmentDialog
