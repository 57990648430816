import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Field,
  PuiTextField,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import WellnessPlanPriceTextField from '../../WellnessPlanPriceTextField'

const useStyles = makeStyles(
  (theme) => ({
    nameInput: {
      width: 265,
    },
    priceTextField: {
      marginLeft: theme.spacing(3),
    },
  }),
  { name: 'WellnessPlanName' },
)

export interface WellnessPlanNameProps {
  isBaseLevel: boolean
  name: Field
  planDefaultName: string
  price: Field
  wellnessPlan: WellnessPlan
  wellnessPlanVersion: WellnessPlanVersion
}

export const WellnessPlanName = ({
  isBaseLevel,
  name,
  planDefaultName,
  price,
  wellnessPlan,
  wellnessPlanVersion,
}: WellnessPlanNameProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Grid container item alignItems="flex-end" wrap="nowrap">
      <PuiTextField
        className={classes.nameInput}
        field={name}
        inputProps={{ maxLength: 100 }}
        label={t('Common:SOMETHING_NAME', {
          something: planDefaultName,
        })}
        margin="none"
        placeholder={t('Common:NAME_SOMETHING', {
          something: planDefaultName,
        })}
      />
      {isBaseLevel && (
        <WellnessPlanPriceTextField
          className={classes.priceTextField}
          field={price}
          plan={wellnessPlan}
          wellnessPlanVersion={wellnessPlanVersion}
        />
      )}
    </Grid>
  )
}
