import { requestMutation } from './graphql/graphqlCore'
import { UNLINK_CHEWY_USER_AND_PETS } from './graphql/mutations'

export const unlinkChewyUserAndPets = requestMutation({
  mutation: UNLINK_CHEWY_USER_AND_PETS,
  variablesHandler: (businessId, clientId) => ({
    businessId,
    clientId,
  }),
})
