import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LinkButton, PermissionArea, Utils } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { OrderType } from '~/constants/SOAPStates'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { SurgeryTimelineItem } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import { isSoapOutsidePharmacyOrder } from '~/utils/orderUtils'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

export interface SurgeryCardProps extends Omit<TimelineCardProps, 'title'> {
  clientId?: string
  item: SurgeryTimelineItem
  patientId?: string
}

const SurgeryCard = ({
  clientId,
  item,
  patientId,
  ...rest
}: SurgeryCardProps) => {
  const { t } = useTranslation(['Common', 'Clients'])

  const permissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)

  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)

  const handleOpenInvoice = () => {
    openInvoice({
      clientId,
      patientId,
      invoiceId: item.invoiceId,
      soapId: item.soapId,
      logId: item.id,
      logType: OrderType.PROCEDURE,
    })
  }

  const showInvoiceLink = item.invoiceId && clientId && patientId

  const isContextItem = useIsCurrentContextItem(item)

  return (
    <TimelineCard
      originBusinessId={item.businessId}
      title={item.description}
      {...item}
      {...rest}
    >
      <TimelineCardContent>
        {item.vet &&
          t('Clients:TIMELINE.SURGERY_CARD.WITH_VETERINARY', {
            veterinary: Utils.getPersonString(item.vet),
          })}
      </TimelineCardContent>
      <TimelineCardActions {...item}>
        {permissions.read && isSoapOutsidePharmacyOrder(item) && (
          <Link
            to={addOriginalBusinessId(
              `/soap/${item.soapId}`,
              isPatientSharingEnabled ? item.businessId : null,
            )}
          >
            {t('Common:VIEW_SOAP')}
          </Link>
        )}
        {invoicePermissions.read && showInvoiceLink && isContextItem && (
          <LinkButton onClick={handleOpenInvoice}>
            {t('Common:VIEW_INVOICE')}
          </LinkButton>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default SurgeryCard
