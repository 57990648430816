import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  AlertIconType,
  ButtonWithLoader,
  ClassesType,
  PuiAlert,
  PuiTextArea,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import {
  getErrorsIsLoading,
  reportUsersErrorFeedback,
} from '~/store/duck/errors'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  () => ({
    root: {},
    button: {
      width: 100,
    },
  }),
  { name: 'ErrorContactForm' },
)

export interface ErrorContactFormProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  detailsExpanded: boolean
  error: string
  message?: React.ReactNode
  onAlertClose?: () => void
  setDetailsExpanded: (detailsExpanded: boolean) => void
}

const ErrorContactForm = ({
  classes: classesProp,
  error,
  className,
  detailsExpanded,
  setDetailsExpanded,
  message,
  onAlertClose,
}: ErrorContactFormProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const isLoading = useSelector(getErrorsIsLoading)
  const { t } = useTranslation(['Common', 'Errors'])

  const [emailSentAlertOpen, setEmailSentAlertOpen] = useState(false)

  const setOpenAlertAfterSuccessOn = useCloseAfterCreation(() => {
    setEmailSentAlertOpen(true)
  }, getErrorsIsLoading)

  const {
    fields: { description },
    validate,
  } = useFields(
    [
      {
        name: 'description',
        label: t('Common:DESCRIPTION'),
        validators: ['required'],
      },
    ],
    false,
  )

  const send = () => {
    if (validate()) {
      setOpenAlertAfterSuccessOn()
      dispatch(
        reportUsersErrorFeedback({
          description: description.value,
          error,
        }),
      )
    }
  }

  const toggleDetails = () => {
    setDetailsExpanded(!detailsExpanded)
  }

  return (
    <Grid
      container
      item
      className={classNames(className, classes.root)}
      direction="column"
    >
      <Text strong variant="body">
        {t('Errors:ERROR_CONTACT_FORM.MESSAGE')}
      </Text>
      {message && <Text variant="body">{message}</Text>}
      <PuiTextArea
        field={description}
        placeholder={t(
          'Errors:ERROR_CONTACT_FORM.DESCRIPTION_FIELD_PLACEHOLDER',
        )}
      />
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <ButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={send}
        >
          {t('Common:SEND_ACTION')}
        </ButtonWithLoader>
        <Text link variant="body2" onClick={toggleDetails}>
          {detailsExpanded
            ? t('Common:HIDE_DETAILS')
            : t('Common:VIEW_DETAILS')}
        </Text>
      </Grid>
      <PuiAlert
        iconType={AlertIconType.SUCCESS}
        message={t('Errors:ERROR_CONTACT_FORM.SUCCESS_MESSAGE')}
        open={emailSentAlertOpen}
        onClose={() => {
          if (onAlertClose) {
            onAlertClose()
          }
          setEmailSentAlertOpen(false)
        }}
      />
    </Grid>
  )
}

export default ErrorContactForm
