import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDebouncedCallback } from 'use-debounce'
import {
  Defaults,
  PuiTextField,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { getFileExtensionFromType, getFileName } from '~/utils/file'

const fileTypeForEdit = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  TIFF: 'image/tiff',
  GIF: 'image/gif',
}

const matchFileTypeForEdit = (type: string) =>
  Object.values(fileTypeForEdit).includes(type)

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
    },
    row: {
      backgroundColor: theme.colors.panelRow,
    },
    editIcon: {
      color: theme.colors.editIconColor,
    },
    input: {
      '&::before': {
        borderBottom: 'none',
      },
    },
  }),
  { name: 'FileListItem' },
)

export type FileListItemHandle = ValidateHandle

interface FileListItemProps {
  file: File
  onChange: (file: File) => void
  onDelete: () => void
  onEdit: () => void
}

const FileListItem = forwardRef<FileListItemHandle, FileListItemProps>(
  function FileListItem({ file, onDelete, onEdit, onChange }, ref) {
    const classes = useStyles()

    const isEditable = matchFileTypeForEdit(file.type)

    const debouncedOnChange = useDebouncedCallback(
      onChange,
      Defaults.DEBOUNCE_ACTION_TIME,
    )

    const {
      fields: { name },
      validate,
    } = useFields([
      {
        name: 'name',
        validators: ['required'],
        initialValue: getFileName(file.name),
      },
    ])

    useEffect(() => {
      const newFileName = `${name.value}.${getFileExtensionFromType(file.type)}`
      debouncedOnChange(new File([file], newFileName, { type: file.type }))
    }, [name.value])

    useImperativeHandle(ref, () => ({
      validate,
    }))

    return (
      <Grid py={0.5}>
        <Grid
          container
          alignItems="center"
          className={classes.row}
          justifyContent="space-between"
          px={1}
          py={2}
          wrap="nowrap"
        >
          <Grid container item px={1}>
            <PuiTextField
              InputProps={{
                classes: {
                  root: classes.input,
                },
              }}
              field={name}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: 100 }}
              margin="none"
            />
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            width="fit-content"
            wrap="nowrap"
          >
            {isEditable && (
              <IconButton
                className={classes.editIcon}
                size="small"
                onClick={onEdit}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton size="small" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default FileListItem
