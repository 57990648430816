import { PermissionArea } from '@pbt/pbt-ui-components'

import { IntegrationArea } from '~/constants/integration'
import SearchContext from '~/constants/searchContext'
import { ContextAvailability } from '~/types'

export const ContextAvailabilityMap: Record<
  SearchContext,
  ContextAvailability
> = {
  [SearchContext.CLIENT_PATIENTS]: {
    permission: PermissionArea.PATIENT,
  },
  [SearchContext.CONTACTS]: {
    permission: PermissionArea.CONTACT,
  },
  [SearchContext.ON_HAND_CATALOG]: {
    permission: PermissionArea.INVENTORY,
  },
  [SearchContext.INVENTORY]: {
    permission: PermissionArea.INVENTORY,
  },
  [SearchContext.LAB_TESTS]: {
    permission: PermissionArea.LAB_TEST,
  },
  [SearchContext.MARKETPLACE]: {
    permission: PermissionArea.BUSINESS,
  },
  [SearchContext.PROCEDURES]: {
    permission: PermissionArea.PROCEDURE,
  },
  [SearchContext.REMINDER_PROTOCOLS]: {
    permission: null,
  },
  [SearchContext.TASK_DASHBOARD]: {
    permission: PermissionArea.TASK,
  },
  [SearchContext.IMAGING_DASHBOARD]: {
    integration: IntegrationArea.IMAGING,
    permission: PermissionArea.LAB_TEST,
  },
  [SearchContext.PRACTICES]: {
    permission: PermissionArea.BUSINESS,
  },
}
