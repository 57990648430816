/* eslint-disable max-lines */
import { Location } from 'react-router-dom'
import { Theme } from '@mui/material'
import deepEqual from 'fast-deep-equal'
import { MomentInput } from 'moment'
import { unitOfTime } from 'moment/moment'
import { stringify } from 'query-string'
import * as R from 'ramda'
import {
  Animal,
  BaseRole,
  BreedConstant,
  Constant,
  DocumentFile,
  Field,
  FieldObject,
  LanguageUtils,
  moment,
  Nil,
  Patient,
  Primitive,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { Environment } from '@pbt/pbt-ui-components/src/constants/environment'
import { Config as LanguageConfig } from '@pbt/pbt-ui-components/src/localization'

import { getSpayedNeuteredIntactWithGenderString } from '~/components/common/inputs/gender/genderUtils'
import { LicenseTypes } from '~/constants/LicenseTypes'
import i18n from '~/locales/i18n'
import {
  AttachmentOrigin,
  FieldsQueryPair,
  Prescription,
  PrescriptionShort,
} from '~/types'

export function createArrayOf(length: number, item?: any) {
  if (!length) {
    return []
  }
  return Array.from(Array(length), (_, key) => item || key)
}

export function mergeArraysAtIndex(
  arr1: any[] = [],
  arr2: any[] = [],
  index = 0,
) {
  if (index < 0) {
    return arr1
  }

  const spaceBetweenArrays = new Array(Math.max(0, index - arr1.length))
  return [
    ...arr1.slice(0, index),
    ...spaceBetweenArrays,
    ...arr2,
    ...arr1.slice(index + arr2.length),
  ]
}

export function isMobile(width: string) {
  return width === 'sm' || width === 'xs'
}

export const handleNumberInput = R.curry(
  (proceed, integerPartLength = 4, fractionPartLength = 4, zero, negative) =>
    (event: any) => {
      const { target: { value = '' } = {} } = event
      const number = value ? Number(value) : ''
      const isValidLength = (
        str: string,
        intLength = integerPartLength,
        fractionLength = fractionPartLength,
      ) => {
        const [integerPart, fractionPart] = str.split('.')
        return (
          (!integerPart || integerPart.length <= intLength) &&
          (!fractionPart || fractionPart.length <= fractionLength)
        )
      }
      if (
        (number > 0 || (zero && number === 0) || (negative && number < 0)) &&
        isValidLength(value)
      ) {
        proceed(value)
      } else if (negative && value === '-') {
        proceed('-')
      } else if (value === '.') {
        proceed('0.')
      } else if (!value) {
        proceed('')
      }
    },
)

export const clean = (object: Record<string, any>): Record<string, any> =>
  R.pickBy((value) => typeof value !== 'undefined', object)

export function secondLevelMerge(
  mapA: Record<string, any> = {},
  mapB: Record<string, any> = {},
) {
  const result = R.mergeAll(
    Object.keys({ ...mapA, ...mapB })
      .filter((key) => key !== 'null')
      .map((key) => {
        const newValue = R.mergeRight(mapA[key], clean(mapB[key]))
        return { [key]: R.equals(mapA[key], newValue) ? mapA[key] : newValue }
      }),
  )
  return R.equals(mapA, result) ? mapA : result
}

const AgeBreakPointsMonths: Record<string, number> = {
  weeks: 5, // age <= 5 months - use "weeks" as age
  months: 12, // age > 5 months <= 12 months - use "months" as age
  years: Number.MAX_SAFE_INTEGER, // age > 12 months - use "years" as age
}

const AgeTimeUnitMap: Record<string, string> = {
  week: i18n.t('Constants:TIME_UNITS.WEEK_ONE').toLowerCase(),
  weeks: i18n.t('Constants:TIME_UNITS.WEEK_OTHER').toLowerCase(),
  week_short: i18n.t('Constants:TIME_UNITS.WEEK_SHORT').toLowerCase(),
  month: i18n.t('Constants:TIME_UNITS.MONTH_ONE').toLowerCase(),
  months: i18n.t('Constants:TIME_UNITS.MONTH_OTHER').toLowerCase(),
  month_short: i18n.t('Constants:TIME_UNITS.MONTH_SHORT').toLowerCase(),
  year: i18n.t('Constants:TIME_UNITS.YEAR_ONE').toLowerCase(),
  years: i18n.t('Constants:TIME_UNITS.YEAR_OTHER').toLowerCase(),
  year_short: i18n.t('Constants:TIME_UNITS.YEAR_SHORT').toLowerCase(),
}

export function getAge(
  birthDateString: MomentInput | Nil,
  deceasedDateString?: MomentInput | Nil,
  short?: boolean,
) {
  if (moment(birthDateString).isAfter(moment(), 'day')) {
    return i18n.t('Utils:NOT_BORN_YET')
  }

  const birthday = moment(birthDateString).startOf('day')
  const endDate = deceasedDateString
    ? moment(deceasedDateString)
    : moment().startOf('day')
  const differenceInMonths = endDate.diff(birthday, 'month', true)
  const timeUnit =
    Object.keys(AgeBreakPointsMonths).find(
      (key) => differenceInMonths <= AgeBreakPointsMonths[key],
    ) || ''
  const difference = endDate.diff(birthday, timeUnit as unitOfTime.Diff)
  const unitKey = difference === 1 ? timeUnit.slice(0, -1) : timeUnit
  const displayTimeUnit = short
    ? AgeTimeUnitMap[`${timeUnit.slice(0, -1)}_short`]
    : AgeTimeUnitMap[unitKey]

  const defaultAge = birthDateString
    ? moment(birthDateString).fromNow(true)
    : ''

  return difference > 0 ? `${difference}\u00A0${displayTimeUnit}` : defaultAge
}

export function getPatientAge(patient: Patient | Nil, short?: boolean) {
  return getAge(patient?.dateOfBirth, patient?.deceasedDate, short)
}

export const formatPrice = (price: number) => price.toFixed(2)

export function objectToFormData(object: any) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => formData.append(key, object[key]))
  return formData
}

export function blobToFormData({
  name = 'file',
  blob,
}: {
  blob: Blob
  name?: string
}) {
  return objectToFormData({ [name]: blob })
}

export const getOriginalFileName = (file: DocumentFile['file']) =>
  [file?.name || file?.fileName, file?.extension].filter(Boolean).join('.')

export const shouldRenderAddress = ({
  address,
  suite,
  city,
  state,
  zip,
  zipcode,
}: any) =>
  R.any(R.compose(R.not, R.isEmpty))(
    R.filter(R.compose(R.not, R.isNil), [
      address,
      suite,
      city,
      state,
      zip,
      zipcode,
    ]),
  )

export function getConstantsList(ids: string[] = [], constant: Constant[]) {
  return ids.map((id) => Utils.getConstantsValue(id, constant)).filter(Boolean)
}

export function getConstantIdStringByNameString(
  string: string,
  constant: Constant[],
) {
  return R.pipe(
    R.split(','),
    R.filter(Boolean),
    R.map((item) => Utils.findConstantIdByName(R.trim(item), constant)),
    R.join(','),
  )(string)
}

export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n) // eslint-disable-line
}

export const resolveValueOrGetter = (item: any, valueOrGetter: any) =>
  typeof valueOrGetter === 'function' ? valueOrGetter(item) : valueOrGetter

export function arrayToMap<
  T extends any = any,
  K extends Primitive = Primitive,
  V extends object = object,
>(
  arr: T[] = [],
  keyOrGetKey: K | ((a: T) => K) = (arg: T) => arg as unknown as K,
  valueOrGetValue: V | ((a: T) => V) | boolean = (arg: T) =>
    arg as unknown as V,
) {
  if (!arr?.length) {
    return {}
  }

  return (arr as T[]).reduce(
    (acc, cur) => {
      const key = resolveValueOrGetter(cur, keyOrGetKey)
      acc[key] = resolveValueOrGetter(cur, valueOrGetValue)
      return acc
    },
    {} as Record<string, V extends T[keyof T] ? V : any>,
  )
}

export function boolToYesNoString(boolVal: boolean | Nil) {
  return boolVal ? i18n.t('Common:YES') : i18n.t('Common:NO')
}

export function getConstantsStringWithAll(
  value: string | string[] | Nil,
  constant: Constant[],
) {
  const array = Array.isArray(value) ? value : value ? [value] : []
  return (
    R.map(
      (item) => LanguageUtils.getConstantTranslatedName(item, constant),
      array,
    )
      .filter(Boolean)
      .join(', ') || i18n.t('Common:ALL')
  )
}

export const isBool = (x: any) => typeof x === 'boolean'

export function getFullBreedName(
  species: string | Nil,
  breeds: string[] | Nil,
  Breed: BreedConstant,
  joinSeparator = '/',
) {
  return species && breeds
    ? breeds
        .map(
          (id) =>
            LanguageUtils.getConstantTranslatedName(id, Breed[species]) || '',
        )
        .join(joinSeparator)
    : ''
}

export function getAAHACodeString(aahaCode?: string) {
  return (
    aahaCode &&
    i18n.t(
      'Abbreviations:ACRONYMS.AMERICAN_ANIMAL_HOSPITAL_ASSOCIATION.AAHA_CODE',
      { code: aahaCode },
    )
  )
}

const matchAny = (items: (string | Nil)[], searchTerm: string) =>
  items.some((_) => _ && _.indexOf(searchTerm) > -1)

export function filterUsers(
  userArray: Partial<User>[],
  searchTerm: string,
  userToOmit?: User,
  excludeSso?: boolean,
) {
  return userArray.filter((user) => {
    const { id, firstName, lastName, email, ssoUser } = user
    return (
      (!userToOmit || id !== userToOmit.id) &&
      matchAny(
        [firstName, lastName, Utils.getPersonString(user), email],
        searchTerm,
      ) &&
      // ssoUser needs to exist and be false - if it does not exist,
      // then the user session might be from before we recorded this
      // var in the journal and user might be SSO
      (!excludeSso || ssoUser === false)
    )
  })
}

export const removeByIdList = (itemIds: string[], obj: any) => {
  const idsSet = new Set(itemIds)

  return R.filter(({ id }) => !idsSet.has(id), obj)
}

export const updateAllByProperty = (
  keyProperty: string,
  oldList: any[],
  newList: any[],
) => {
  const oldMap = arrayToMap(oldList, R.prop(keyProperty))
  const newMap = arrayToMap(newList, R.prop(keyProperty))
  const allKeys = [...Object.keys(oldMap), ...Object.keys(newMap)]
  const uniqueKeys = Array.from(new Set(allKeys))

  return uniqueKeys.map((key) => ({ ...oldMap[key], ...newMap[key] }))
}

export const updateAllById = R.curry(updateAllByProperty)('id')

export const updateByIdWithFunc = (
  newItemId: string,
  obj: any,
  updateFunc: (item: any) => any,
) => R.map((item) => (item.id === newItemId ? updateFunc(item) : item), obj)

export const addIdToList = (itemId: string, list: string[] = []) =>
  list.includes(itemId) ? list : [...list, itemId]

export const getUrlSearchParam = (param: string, search: string) =>
  new URLSearchParams(search).get(param)

const getUrlSearchParams = (
  url: string,
  search: string,
  withoutParams: string[] = [],
) => {
  if (!search || search.length === 0) {
    return ''
  }

  const params = search.substring(1).split('&')

  const filtered = params.filter(
    (item) => !withoutParams.some((param) => item.indexOf(param) === 0),
  )

  const joinChar = url.indexOf('?') > 0 ? '&' : '?'
  return filtered.length > 0 ? `${joinChar}${filtered.join('&')}` : ''
}

export const addSearch = (
  location: Location,
  url: string,
  withoutParams?: string[],
) => `${url}${getUrlSearchParams(url, location.search, withoutParams)}`

/** TODO: this implementation was reconsidered in PR-1327
 * and origin id will be most likely to be removed from client transmission */
export const addOriginalBusinessId = (
  url: string,
  originalBusinessId: string | Nil,
  location?: Pick<Location, 'search'> | Nil,
) => {
  const search =
    location && getUrlSearchParams(url, location.search, ['originalBusinessId'])

  if (search) {
    return `${url}${search}${
      originalBusinessId ? `&originalBusinessId=${originalBusinessId}` : ''
    }`
  }

  return `${url}${
    originalBusinessId ? `?originalBusinessId=${originalBusinessId}` : ''
  }`
}

export function isNumber(obj: any) {
  return typeof obj === 'number'
}

const speciesBSAMap: Partial<Record<Animal, (w: number) => number>> = {
  [Animal.CAT]: (weight: number) =>
    weight <= 2.5 ? 0.11 * weight ** 0.67 : 0.143 * weight ** 0.4,
  [Animal.DOG]: (weight: number) => 0.105 * weight ** 0.67,
}

/**
 * Approximate animal body surface from its weight
 * more details: https://www.avma.org/News/Journals/Collections/Documents/javma_225_5_689.pdf
 * @param weightProp - animal weight in kg
 * @param speciesName - species name, Canine or Feline
 * @returns - body surface in m^2
 */
export function getSquareFromWeight(weightProp: number, speciesName: Animal) {
  const getBSA = speciesBSAMap[speciesName]
  return getBSA ? getBSA(weightProp) : undefined
}

export function getSoapContainerHeight(theme: Theme, noSearch?: boolean) {
  const paddings = parseInt(theme.spacing(5), 10)
  const allTopContainerHeight =
    (theme.mixins.toolbar.minHeight as number) +
    theme.constants.soapStatusBarHeight +
    theme.constants.soapStepperHeight +
    (noSearch ? 0 : theme.constants.soapInlineSearchHeight) +
    paddings

  return `calc(100vh - ${allTopContainerHeight}px)`
}

export function joinString(items: (string | Nil)[] = [], joinChar = ', ') {
  return items.filter(R.identity).join(joinChar).trim()
}

export function getEnvironment() {
  return Utils.isProduction() ? Environment.PROD : Environment.STAGE
}

export function isFirefox() {
  return navigator.userAgent.indexOf('Firefox') !== -1
}

export const getOrderPrintPageName = (
  prescriptionInfo: Prescription | PrescriptionShort,
) => {
  const name = [
    moment(new Date()).format('YYYY_MM_DD'),
    R.path(['client', 'lastName'], prescriptionInfo),
    R.path(['patient', 'name'], prescriptionInfo),
    R.path(['inventory', 'name'], prescriptionInfo),
    R.path(['prescriptionType'], prescriptionInfo),
    'Prescription',
  ]
    .filter(Boolean)
    .join('_')
  return `${name}.pdf`
}

const arrowKeyCodes = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight']
export const isArrowKey = (keyName: string) =>
  arrowKeyCodes.indexOf(keyName) > -1

export function parse(obj: any) {
  try {
    return JSON.parse(obj)
  } catch (err) {
    return undefined
  }
}

export function updateByPath(path: string[], newValue: any, obj: any) {
  return R.set(R.lensPath(path), newValue, obj)
}

export function getDeleteConfirmMessage(itemClass?: string) {
  return i18n.t('Utils:DELETE_CONFIRM_MESSAGE', { itemClass })
}

export const getPatientDetails = (
  patient: Patient | Nil,
  Gender: Constant[],
) => {
  if (!patient) {
    return ''
  }

  const { dateOfBirth, gender, spayedNeutered } = patient || {}
  const details = []

  if (dateOfBirth) {
    details.push(getPatientAge(patient))
  }

  if (gender) {
    const fullGenderString = getSpayedNeuteredIntactWithGenderString(
      gender,
      spayedNeutered,
      Gender,
    )
    details.push(`${fullGenderString}`.trim())
  }

  return details.join(' | ')
}

export const getRehydratedState = (initialState: any, state: any) =>
  state.hydrated ? state : { ...initialState, ...state, hydrated: true }

// :TODO we need to refactor - it doesn't work properly for other languages
export const getQuantityStr = (quantity: number, str: string) =>
  quantity > 1 ? `${str}s` : str

export const getKioskDomain = () => {
  const { hostname } = window.location
  const domain = Utils.isLocalEnvironment()
    ? 'stage.rhapsody.vet'
    : hostname.replace(/(portal|pims|nva)\./, '')
  return `https://kiosk.${domain}`
}

export const getKioskUrl = (path = '', params = {}) => {
  const queue = stringify({
    ...params,
    [LanguageConfig.LookupQueryString]: i18n.language,
  })
  return `${getKioskDomain()}/${path}?${queue}`
}

const getLicensesNumbersByType = (
  doctor: PrescriptionShort['doctor'] | Nil,
  LicenseTypesConst: Constant[],
  type: string,
) => {
  const license = Utils.findByName(type, LicenseTypesConst)
  const doctorsLicenses = license
    ? doctor?.licenses?.filter(
        (doctorLicense) => doctorLicense?.typeId === license.id,
      )
    : []
  const doctorsLicensesNumbers = (doctorsLicenses || [])
    .map(R.propOr(undefined, 'number'))
    .filter(Boolean)
  return doctorsLicensesNumbers.join(', ')
}

export const getDVMLicensesNumbers = (
  doctor: PrescriptionShort['doctor'] | Nil,
  LicenseTypesConst: Constant[],
) => getLicensesNumbersByType(doctor, LicenseTypesConst, LicenseTypes.DVM)

export const getDEALicensesNumbers = (
  doctor: PrescriptionShort['doctor'] | Nil,
  LicenseTypesConst: Constant[],
) => getLicensesNumbersByType(doctor, LicenseTypesConst, LicenseTypes.DEA)

export const getOtherLicensesNumbers = (
  doctor: PrescriptionShort['doctor'] | Nil,
  LicenseTypesConst: Constant[],
) => getLicensesNumbersByType(doctor, LicenseTypesConst, LicenseTypes.OTHER)

export const findAllByName = <T>(list: T[] = [], names: string[] = []) =>
  names.reduce((acc, name) => {
    const finding = Utils.findByName(name, list)
    if (finding) {
      acc.push(finding)
    }
    return acc
  }, [] as T[])

export const replaceLineBreaksWithTag = (string: string) =>
  string.replace(/(?:\r\n|\r|\n)/g, '<br />')

export const stringDivider = (str = '', length = 0) =>
  str
    .split(/(?:\r\n|\r|\n|\s)/g)
    .reduce((lines, word) => {
      const curLine: string = lines[lines.length - 1] || ''

      if (curLine.length + word.length + 1 <= length) {
        lines[Math.max(lines.length - 1, 0)] = curLine
          ? `${curLine} ${word}`
          : word
        return lines
      }

      return lines.concat(word)
    }, [] as string[])
    .join('\n')

export const removeEmojiFromString = (text: string) =>
  text?.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  )

export const getCurrentEnvironmentString = () =>
  Utils.isLocalEnvironment()
    ? Environment.DEV
    : Utils.isProduction()
      ? Environment.PROD
      : Environment.STAGE

export const isOtherUnitSelected = (
  unitField: { value: any },
  constant: Constant[] | Nil,
) => {
  const { value } = unitField
  if (!value || !constant) {
    return false
  }

  const otherUnit = Utils.findByName('Other', constant)

  return value === otherUnit?.id
}

export const getPatientStatus = (patient: Patient | Nil) => {
  const { active, deceased } = patient || {}
  const inactive = active === false
  return {
    disabled: inactive || deceased,
    reasons: [
      deceased ? i18n.t<string>('Common:DECEASED') : null,
      inactive ? i18n.t<string>('Common:INACTIVE_ONE') : null,
    ].filter(R.is(String)),
  }
}

export const getConstantFromMapOrDefault = (
  constant: string,
  map: Record<string, string>,
  defaultValue: string,
) => (Object.values(map).includes(constant) ? constant : defaultValue)

export const getManualInputSelectValue = (
  constant: Constant[],
  valueId: string | Nil,
  customValue: string | number | Nil,
) => {
  const constantDisplayName = LanguageUtils.getConstantTranslatedName(
    valueId,
    constant,
  )
  const constantName = Utils.getConstantName(valueId, constant)

  return (constantName === 'Other' ? customValue : constantDisplayName) || ''
}

export const getInsertIndex = (
  items: any[],
  item: any,
  prop: string,
  asc = true,
) => {
  const compare = asc ? R.lte : R.gte
  for (let i = 0; i < items.length; ++i) {
    if (items[i] && compare(item[prop], items[i][prop])) {
      return i
    }
  }

  return items.length
}

export const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty])

export const equalsRespectEmpty = (a: any, b: any) =>
  (isNilOrEmpty(a) && isNilOrEmpty(b)) || a === b

export const getRequiredLabel = (label: string | Nil, condition?: boolean) =>
  condition ? `${label}*` : label

export const asFlags = (values: string[] = []) =>
  values.reduce(
    (acc, value) => {
      acc[value] = true
      return acc
    },
    {} as Record<string, boolean>,
  )

export const replaceHtmlPairedTagWithAnother = (
  text: string,
  targetTag: string,
  newTag: string,
) => {
  const openTargetTagPattern = new RegExp(`<${targetTag}[^>]*>`, 'g')
  const closeTargetTagPattern = new RegExp(`</${targetTag}>`, 'g')
  return text
    .replace(openTargetTagPattern, `<${newTag}>`)
    .replace(closeTargetTagPattern, `</${newTag}>`)
}

export const sortPersons = R.sortWith([
  R.ascend(({ deleted, active }: User) => deleted || !active),
  R.ascend(({ firstName, lastName }: User) =>
    `${firstName || ''} ${lastName || ''}`.toLocaleLowerCase(),
  ),
])

export const isActivePerson = (person?: User) =>
  person && person.active !== false && !person.deleted

export const getPetFriendsString = (
  client: User | Nil,
  coparents: User[] | undefined = [],
) => {
  const { firstName, lastName } = client?.emergencyContact || {}
  const contacts = firstName && lastName ? [{ firstName, lastName }] : []
  return [...contacts, ...coparents]
    .map(Utils.getPersonString)
    .join(', ')
    .trim()
}

const logDeepEquality = (a: object, b: object) => {
  if (!deepEqual(a, b)) {
    const equalKeys = R.uniq([...R.keys(a), ...R.keys(b)])

    equalKeys.forEach((key) => {
      if (!deepEqual(a[key], b[key])) {
        // eslint-disable-next-line no-console
        console.log(
          `The property ${key} values do not match:\n`,
          a[key],
          b[key],
        )
      }
    })
  } else {
    // eslint-disable-next-line no-console
    console.log('Equality is successful')
  }
}

const isObject = R.is(Object)
const mapEntries =
  (mappers: Record<string, (value: any) => any>) => (object: object) =>
    Object.entries(object).reduce(
      (acc, [key, value]) => {
        const mapper = mappers[key]
        acc[key] = mapper ? mapper(value) : value
        return acc
      },
      {} as Record<string, any>,
    )

export const createDeepEqualityComparator =
  ({
    ignoreProperties,
    propertyPickers = {},
    intersectionOnly,
    debug = false,
  }: {
    debug?: boolean
    ignoreProperties?: string[]
    intersectionOnly?: boolean
    propertyPickers?: Record<string, (value: any) => any>
  } = {}) =>
  (a: any, b: any) => {
    if (!isObject(a) || !isObject(b)) {
      return a === b
    }

    const normalize = R.pipe(
      // @ts-ignore
      ...[
        intersectionOnly && R.pick(R.intersection(R.keys(a), R.keys(b))),
        Array.isArray(ignoreProperties) && R.omit(ignoreProperties),
        Object.values(propertyPickers)?.length && mapEntries(propertyPickers),
        R.identity,
      ].filter(Boolean),
    )

    const normalizedA = normalize(a)
    const normalizedB = normalize(b)

    if (debug && Utils.isLocalEnvironment()) {
      logDeepEquality(normalizedA, normalizedB)
    }

    return deepEqual(normalizedA, normalizedB)
  }

export const isFieldValuesChanged = (fields: Record<string, Field>) =>
  Object.values(fields).some(
    ({ value, initialValue }) => !deepEqual(value, initialValue),
  )

export const getFieldsQueryPairs = (query: string | null) =>
  (query
    ?.split(';')
    .filter((pair) => pair !== '')
    .map((pair) => pair.split('=')) || []) as [string, string][]

export const getFieldsQueryMergeObject = (query: string | null) =>
  R.pipe(
    getFieldsQueryPairs,
    R.map(([key, val]) => ({ [`${key}`]: val })),
    R.mergeAll,
  )(query)

export const getStringifyPair = ([key, value]: FieldsQueryPair) =>
  `${key}=${
    R.is(Array, value)
      ? value.map((item) => item.replace(/;/g, ''))
      : value.replace(/;/g, '')
  }`

export const getStringifyFieldsQueryPairs = (array: FieldsQueryPair[]) =>
  array
    ?.filter(([key, value]) => key !== '' && value !== '')
    .map(getStringifyPair)
    .join(';') || ''

export const setStringifyFieldsQueryParam = (
  query: string | null,
  param: string,
  value: string,
) =>
  getStringifyFieldsQueryPairs(
    R.toPairs({
      ...getFieldsQueryMergeObject(query),
      [param]: value,
    }),
  )

export const normalizeInputAsString = R.cond<any, string>([
  [R.is(String), R.identity],
  [R.isNil, R.always('')],
  [R.T, R.toString],
])

export const getFieldsQueryWithFieldsFixes = (
  fieldsQuery: string,
  fieldsToFix: Record<string, [string, Constant[]]>,
) =>
  fieldsQuery
    .split(';')
    .reduce((result, fieldPair) => {
      const [key, value] = fieldPair.split('=')

      if (fieldsToFix[key]) {
        const [fieldKey, fieldConstants] = fieldsToFix[key]

        const splitValues = value.split(',')
        const ids = splitValues
          .map((v) => Utils.findConstantIdByName(v.trim(), fieldConstants))
          .join(',')

        if (ids) {
          return [...result, fieldPair, `${fieldKey}=${ids}`]
        }
      }
      return [...result, fieldPair]
    }, [] as string[])
    .join(';')

export const localPIMSDebugEnabled = JSON.parse(
  localStorage?.getItem('localPIMSDebugEnabled') || 'false',
)

export const getRhapsodyDomain = () =>
  Utils.isLocalEnvironment()
    ? `https://${localPIMSDebugEnabled ? 'pims' : 'portal'}.stage.rhapsody.vet`
    : window.location.origin

// AAHA branded analytics site - eg aaha.petabyte.technology
export const isAAHA = window.location.host.includes('aaha')
export const isPIMS =
  window.location.host.includes('pims') || localPIMSDebugEnabled
export const isNVA = window.location.host.includes('nva')
// PBT branded analytics site - eg analytics.rhapsody.vet
export const isAnalytics = window.location.host.includes('analytics')
// Chewy branded analytics site - eg benchmarking.rhapsody.vet
export const isBenchmarking = window.location.host.includes('benchmarking')

// pseudo-FT for RHAP-5498
export const auth0FT = true

export const auth0DebugDisabled = JSON.parse(
  localStorage?.getItem('auth0DebugDisabled') || 'false',
)

export const portalAuth0Enabled =
  window.location.host.includes('portal') && auth0FT

export const analyticsAuth0Enabled = isAnalytics && auth0FT

export const localAuth0Enabled =
  Utils.isLocalEnvironment() && auth0FT && !auth0DebugDisabled

export const auth0Enabled =
  isNVA ||
  isPIMS ||
  isBenchmarking ||
  analyticsAuth0Enabled ||
  portalAuth0Enabled ||
  localAuth0Enabled

export const testAndMatch = (path: string, regExp: RegExp | Nil) => {
  const match = regExp ? regExp.test(path) : false
  const matches = (regExp && path.match(regExp)) || []
  return {
    match,
    matches: matches.slice(1),
  }
}

export const getSpeciesFromFieldsQuery = (
  fieldsQuery: string,
  Species: Constant[],
) => {
  const speciesPair = getFieldsQueryPairs(fieldsQuery).filter(
    ([key] = ['', '']) => key === 'patients.species',
  )
  const speciesName = R.path([0, 1], speciesPair) as string
  const speciesId = Utils.findConstantIdByName(speciesName, Species)

  return { speciesId, speciesName }
}

export const normalizeWhiteSpaces = (string: string) =>
  R.is(String) && string.replace(/\s{1,}/g, ' ')

export const getPluckedFieldsByFieldKey = <T extends object>(
  fields: FieldObject,
  fieldKey: keyof Field,
): T => R.pluck<any, any>(fieldKey, fields as any) as unknown as T

export const getObjectDifference = (obj1: any, obj2: any) => {
  const differenceKeys = Object.keys(obj2).filter(
    (key) => !R.equals(obj1[key], obj2[key]),
  )

  return R.pick(differenceKeys, obj2)
}

export const isAnalyticsRole = (role: BaseRole) =>
  Boolean(role?.rhapsodyAnalytics)

export const cleanFilesToSend = (
  files: (AttachmentOrigin & Record<string, any>)[],
): AttachmentOrigin[] =>
  files.map(({ name, description, extension, fileUrl }) => ({
    description,
    extension,
    fileUrl,
    name,
  }))

export const convertNumberToPx = (value: string | number) =>
  typeof value === 'number' ? `${value}px` : value

export function mergeRightItemsWithKey<
  T extends { children?: T[]; id: string },
  K extends keyof T,
>(arr1: T[], arr2: T[], key: K): T[] {
  if (!arr1 || !arr2) {
    return arr1
  }

  return arr1.map((item) => {
    const concurrentItem = arr2.find((item2) => item2.id === item.id)
    const mergeKeyValue = concurrentItem?.[key] ?? item?.[key]
    if (item?.children && concurrentItem?.children) {
      return {
        ...item,
        children: mergeRightItemsWithKey(
          item.children,
          concurrentItem.children,
          key,
        ),
        [key]: mergeKeyValue,
      }
    }
    return { ...item, [key]: mergeKeyValue }
  })
}

export const isEditItem = <T extends { id: string }, U extends { id?: Nil }>(
  item: T | U,
): item is T => Boolean(item?.id)

export const nullOrValue = <T>(value: T | undefined): T | null =>
  (typeof value === 'string' && value === '') || value === undefined
    ? null
    : value

export const numberOrNull = (value: any): number | null => {
  if (nullOrValue(value) === null) {
    return null
  }
  return Number.isNaN(Number(value)) ? null : Number(value)
}

type PropFromFirstItemOfProp = {
  data: any
  parentProp: string
  secondaryProp: string
}

export const getPropFromFirstItemOfProp = ({
  parentProp,
  secondaryProp,
  data,
}: PropFromFirstItemOfProp) =>
  R.pipe(R.prop(parentProp), R.head, R.prop(secondaryProp))(data)

export const convertToKebabCase = (text: string) =>
  text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')

export const swapElements = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number,
): T[] => {
  ;[array[fromIndex], array[toIndex]] = [array[toIndex], array[fromIndex]]
  return array
}
