import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, PuiPopper, PuiPopperProps, Text } from '@pbt/pbt-ui-components'

import SearchContext from '~/constants/searchContext'

import ClientSelector from '../timetable/scheduler/appointment/ClientSelector'
import BaseSearch from './BaseSearch'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(3),
      width: 550,
    },
  }),
  { name: 'BaseSearchPopper' },
)

export interface BaseSearchPopperProps extends PuiPopperProps {
  clientAdvancedSearch?: boolean
  description?: React.ReactNode
  onSelected: (itemId: string, subItemId: string | Nil) => void
  placeholder: string
  searchContext: SearchContext
  text: string
  visibleResults?: number
}

const BaseSearchPopper = ({
  clientAdvancedSearch,
  onSelected,
  searchContext,
  text,
  description,
  placeholder,
  visibleResults,
  ...rest
}: BaseSearchPopperProps) => {
  const classes = useStyles()

  return (
    <PuiPopper disableClickAwayListener {...rest}>
      <Grid container item className={classes.root} direction="column">
        <Text strong variant="h2">
          {text}
        </Text>
        {description && <Text variant="body2">{description}</Text>}
        {clientAdvancedSearch ? (
          <ClientSelector
            maxSearchValuesInList={visibleResults}
            resultsTitle={text}
            onSelected={onSelected}
          />
        ) : (
          <BaseSearch
            autoFocus
            placeholder={placeholder}
            searchContext={searchContext}
            visibleResults={visibleResults}
            onItemClick={onSelected}
          />
        )}
      </Grid>
    </PuiPopper>
  )
}

export default BaseSearchPopper
