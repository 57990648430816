import React from 'react'
import { useSelector } from 'react-redux'

import {
  getClientId,
  getPatientId,
  getSelectedConversationId,
  getSoapId,
} from '~/store/reducers/soap'

import ChatContainer from './ChatContainer'
import ChatTable from './ChatTable'

export interface ChatTabComponentProps {
  editDisabled?: boolean
  expanded: boolean
}

const ChatTabComponent = ({
  expanded,
  editDisabled,
}: ChatTabComponentProps) => {
  const soapId = useSelector(getSoapId)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const conversationId = useSelector(getSelectedConversationId)

  return conversationId ? (
    <ChatContainer
      conversationId={conversationId}
      editDisabled={editDisabled}
      expanded={expanded}
    />
  ) : (
    <ChatTable
      clientId={clientId}
      editDisabled={editDisabled}
      expanded={expanded}
      patientId={patientId}
      soapId={soapId}
    />
  )
}

export default ChatTabComponent
