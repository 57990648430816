/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import { ArrowForward, MoreHoriz } from '@mui/icons-material'
import { Grid, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import ActionsPopper, { PopperAction } from '~/components/common/ActionsPopper'
import StaticTable from '~/components/common/lists/table/StaticTable'
import { WidgetColumn, WidgetColumnRow } from '~/types'

const ROW_HEIGHT = 40

export interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
  dataRowsCount: number
}

const generateTableClassname = (rowsCount: number) => `table-${rowsCount}rows`

const useStyles = makeStyles(
  (theme) => ({
    table: ({ dataRowsCount }: UseStylesProps) => ({
      [`&.${generateTableClassname(dataRowsCount)}`]: {
        height: ROW_HEIGHT * dataRowsCount,
        tableLayout: 'fixed',
      },
    }),
    header: {
      height: 28,
      minHeight: 28,
    },
    titleCell: {
      padding: theme.spacing(0.5, 1),
      border: 'none',
      borderBottom: theme.constants.fabBorder,
      color: theme.colors.lowAccentText,
      fontSize: '1.4rem',
      fontWeight: '500',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tableRow: {
      height: ROW_HEIGHT,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    tableCell: {
      border: 'none',
      overflow: 'hidden',
      padding: theme.spacing(0.25, 1),
    },
  }),
  { name: 'TableWidget' },
)

const useActionCellStyles = makeStyles(
  (theme) => ({
    iconButton: {
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  }),
  { name: 'ActionCell' },
)

interface ActionCellProps {
  navigateToItemDetailsTooltip?: string
  onNavigateForward?: (event: React.MouseEvent) => void
  onShowActions?: (event: React.MouseEvent) => void
  showNavigateForward?: boolean
}

const ActionCell = ({
  showNavigateForward = true,
  onNavigateForward = R.F,
  navigateToItemDetailsTooltip = '',
  onShowActions,
}: ActionCellProps) => {
  const classes = useActionCellStyles()

  return (
    <Grid container justifyContent="flex-end" wrap="nowrap">
      {showNavigateForward && (
        <Tooltip placement="top" title={navigateToItemDetailsTooltip}>
          <IconButton
            className={classes.iconButton}
            size="large"
            onClick={onNavigateForward}
          >
            <ArrowForward />
          </IconButton>
        </Tooltip>
      )}
      {onShowActions && (
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={onShowActions}
        >
          <MoreHoriz />
        </IconButton>
      )}
    </Grid>
  )
}

const getPlaceHolders = (count: number) => new Array(count).fill({})

interface ActionCellComponentProps<T = any> {
  canNavigateToItemDetails: (item: T) => boolean
  handleToggleActions: (event: React.MouseEvent, item: T) => void
  item: T
  navigateToItemDetailsTooltip?: string
  onNavigateToItemDetails: (item: T) => void
}

const ActionCellComponent = ({
  item,
  canNavigateToItemDetails,
  navigateToItemDetailsTooltip,
  onNavigateToItemDetails,
  handleToggleActions,
  ...rest
}: ActionCellComponentProps) => (
  <ActionCell
    navigateToItemDetailsTooltip={navigateToItemDetailsTooltip}
    showNavigateForward={canNavigateToItemDetails(item)}
    onNavigateForward={() => onNavigateToItemDetails(item)}
    onShowActions={(event) => handleToggleActions(event, item)}
    {...rest}
  />
)

interface TableWidgetProps<T extends WidgetColumnRow> {
  canNavigateToItemDetails?: (item: T) => boolean
  columns: WidgetColumn<T>[]
  data: T[]
  getActions?: (item?: T, refetchEntity?: boolean) => PopperAction[]
  isLoading?: boolean
  navigateToItemDetailsTooltip?: string
  onNavigateToItemDetails?: (item: T) => void
  rowsCount?: number
  showActions?: boolean
  showHeaderTitles: boolean
}

const TableWidget = <T extends WidgetColumnRow>({
  showHeaderTitles,
  data,
  isLoading = false,
  columns: columnsProp,
  rowsCount,
  getActions,
  showActions = true,
  canNavigateToItemDetails = R.T,
  onNavigateToItemDetails = R.F,
  navigateToItemDetailsTooltip = '',
}: TableWidgetProps<T>) => {
  const columns = columnsProp || []
  const rows = !data || isLoading ? getPlaceHolders(rowsCount || 1) : data
  const classes = useStyles({ dataRowsCount: rows.length })

  const [actionsAnchorEl, setActionsAnchorEl] =
    useState<HTMLButtonElement | null>()
  const [activeActionItem, setActiveActionItem] = useState<T>()

  const getShowActionsSelect = (item: T) =>
    getActions && getActions(item, true)?.length > 0

  const handleToggleActions = (event: React.MouseEvent, item: T) => {
    if (!getShowActionsSelect(item)) {
      return
    }
    const targetButton = (event.target as HTMLAnchorElement)?.closest('button')
    setActionsAnchorEl(actionsAnchorEl ? undefined : targetButton)
    setActiveActionItem(activeActionItem ? undefined : item)
  }

  const actionCell = {
    id: 'action',
    component: ActionCellComponent,
    componentProps: {
      handleToggleActions,
      navigateToItemDetailsTooltip,
      canNavigateToItemDetails,
      onNavigateToItemDetails,
    },
    width: 1.5,
  }

  const columnsWithActions = showActions ? [...columns, actionCell] : columns

  return (
    <Grid container>
      <StaticTable
        showHeaderTooltip
        clamp={1}
        classes={{
          table: classNames(classes.table, generateTableClassname(rows.length)),
          row: classes.tableRow,
          cell: classes.tableCell,
          header: classes.header,
          titleCell: classes.titleCell,
        }}
        columns={columnsWithActions}
        emptyValue="—"
        hideHeader={!showHeaderTitles}
        rows={rows}
      />
      <ActionsPopper
        actions={getActions ? getActions(activeActionItem) : []}
        anchorEl={actionsAnchorEl}
        disablePortal={false}
        onClose={() => {
          setActionsAnchorEl(undefined)
          setActiveActionItem(undefined)
        }}
      />
    </Grid>
  )
}

export default TableWidget
