import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import { Adjustment, UnsavedAdjustment } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_ADJUSTMENTS = 'inventoryAdjustment/FETCH_ADJUSTMENTS'
export const FETCH_ADJUSTMENTS_SUCCESS =
  'inventoryAdjustment/FETCH_ADJUSTMENTS_SUCCESS'
export const FETCH_ADJUSTMENTS_FAILURE =
  'inventoryAdjustment/FETCH_ADJUSTMENTS_FAILURE'

export const FETCH_MORE_ADJUSTMENTS =
  'inventoryAdjustment/FETCH_MORE_ADJUSTMENTS'
export const FETCH_MORE_ADJUSTMENTS_SUCCESS =
  'inventoryAdjustment/FETCH_MORE_ADJUSTMENTS_SUCCESS'
export const FETCH_MORE_ADJUSTMENTS_FAILURE =
  'inventoryAdjustment/FETCH_MORE_ADJUSTMENTS_FAILURE'

export const FETCH_ADJUSTMENT = 'inventoryAdjustment/FETCH_ADJUSTMENT'
export const FETCH_ADJUSTMENT_SUCCESS =
  'inventoryAdjustment/FETCH_ADJUSTMENT_SUCCESS'
export const FETCH_ADJUSTMENT_FAILURE =
  'inventoryAdjustment/FETCH_ADJUSTMENT_FAILURE'

export const FETCH_VARIATION_ADJUSTMENTS =
  'inventoryAdjustment/FETCH_VARIATION_ADJUSTMENTS'
export const FETCH_VARIATION_ADJUSTMENTS_SUCCESS =
  'inventoryAdjustment/FETCH_VARIATION_ADJUSTMENTS_SUCCESS'
export const FETCH_VARIATION_ADJUSTMENTS_FAILURE =
  'inventoryAdjustment/FETCH_VARIATION_ADJUSTMENTS_FAILURE'

export const CREATE_ADJUSTMENT = 'inventoryAdjustment/CREATE_ADJUSTMENT'
export const CREATE_ADJUSTMENT_SUCCESS =
  'inventoryAdjustment/CREATE_ADJUSTMENT_SUCCESS'
export const CREATE_ADJUSTMENT_FAILURE =
  'inventoryAdjustment/CREATE_ADJUSTMENT_FAILURE'

export const BULK_CREATE_ADJUSTMENTS =
  'inventoryAdjustment/BULK_CREATE_ADJUSTMENTS'
export const BULK_CREATE_ADJUSTMENTS_SUCCESS =
  'inventoryAdjustment/BULK_CREATE_ADJUSTMENTS_SUCCESS'
export const BULK_CREATE_ADJUSTMENTS_FAILURE =
  'inventoryAdjustment/BULK_CREATE_ADJUSTMENTS_FAILURE'

export const EDIT_ADJUSTMENT = 'inventoryAdjustment/EDIT_ADJUSTMENT'
export const EDIT_ADJUSTMENT_SUCCESS =
  'inventoryAdjustment/EDIT_ADJUSTMENT_SUCCESS'
export const EDIT_ADJUSTMENT_FAILURE =
  'inventoryAdjustment/EDIT_ADJUSTMENT_FAILURE'

export const DELETE_ADJUSTMENT = 'inventoryAdjustment/DELETE_ADJUSTMENT'
export const DELETE_ADJUSTMENT_SUCCESS =
  'inventoryAdjustment/DELETE_ADJUSTMENT_SUCCESS'
export const DELETE_ADJUSTMENT_FAILURE =
  'inventoryAdjustment/DELETE_ADJUSTMENT_FAILURE'

export const UPDATE_ADJUSTMENTS = 'inventoryAdjustment/UPDATE_ADJUSTMENTS'

export const fetchAdjustments = (from: number, to: number) => ({
  type: FETCH_ADJUSTMENTS,
  from,
  to,
})
export const fetchAdjustmentsSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_ADJUSTMENTS_SUCCESS,
  list,
  totalCount,
})
export const fetchAdjustmentsFailure = (error: ApiError) => ({
  type: FETCH_ADJUSTMENTS_FAILURE,
  error,
})

export const fetchMoreAdjustments = (from: number, to: number) => ({
  type: FETCH_MORE_ADJUSTMENTS,
  from,
  to,
})
export const fetchMoreAdjustmentsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ADJUSTMENTS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreAdjustmentsFailure = (error: ApiError) => ({
  type: FETCH_MORE_ADJUSTMENTS_FAILURE,
  error,
})

export const fetchAdjustment = (adjustmentId: string) => ({
  type: FETCH_ADJUSTMENT,
  adjustmentId,
})
export const fetchAdjustmentSuccess = (adjustmentId: string) => ({
  type: FETCH_ADJUSTMENT_SUCCESS,
  adjustmentId,
})
export const fetchAdjustmentFailure = (error: ApiError) => ({
  type: FETCH_ADJUSTMENT_FAILURE,
  error,
})

export const fetchVariationAdjustments = (variationId: string) => ({
  type: FETCH_VARIATION_ADJUSTMENTS,
  variationId,
})
export const fetchVariationAdjustmentsSuccess = (list: string[]) => ({
  type: FETCH_VARIATION_ADJUSTMENTS_SUCCESS,
  list,
})
export const fetchVariationAdjustmentsFailure = (error: ApiError) => ({
  type: FETCH_VARIATION_ADJUSTMENTS_FAILURE,
  error,
})

export const createAdjustment = (adjustment: UnsavedAdjustment) => ({
  type: CREATE_ADJUSTMENT,
  adjustment,
})
export const createAdjustmentSuccess = (adjustmentId: string) => ({
  type: CREATE_ADJUSTMENT_SUCCESS,
  adjustmentId,
})
export const createAdjustmentFailure = (error: ApiError) => ({
  type: CREATE_ADJUSTMENT_FAILURE,
  error,
})

export const bulkCreateAdjustments = (adjustments: Adjustment[]) => ({
  type: BULK_CREATE_ADJUSTMENTS,
  adjustments,
})
export const bulkCreateAdjustmentsSuccess = () => ({
  type: BULK_CREATE_ADJUSTMENTS_SUCCESS,
})
export const bulkCreateAdjustmentsFailure = (error: ApiError) => ({
  type: BULK_CREATE_ADJUSTMENTS_FAILURE,
  error,
})

export const editAdjustment = (adjustment: Adjustment) => ({
  type: EDIT_ADJUSTMENT,
  adjustment,
})
export const editAdjustmentSuccess = (adjustmentId: string) => ({
  type: EDIT_ADJUSTMENT_SUCCESS,
  adjustmentId,
})
export const editAdjustmentFailure = (error: ApiError) => ({
  type: EDIT_ADJUSTMENT_FAILURE,
  error,
})

export const deleteAdjustment = (adjustmentId: string) => ({
  type: DELETE_ADJUSTMENT,
  adjustmentId,
})
export const deleteAdjustmentSuccess = (adjustmentId: string) => ({
  type: DELETE_ADJUSTMENT_SUCCESS,
  adjustmentId,
})
export const deleteAdjustmentFailure = (error: ApiError) => ({
  type: DELETE_ADJUSTMENT_FAILURE,
  error,
})

export const updateAdjustments = (adjustments: Record<string, Adjustment>) => ({
  type: UPDATE_ADJUSTMENTS,
  adjustments,
})

export type InventoryAdjustmentsState = {
  error: string | null
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  map: Record<string, Adjustment>
  totalCount: number
}

const INITIAL_STATE: InventoryAdjustmentsState = {
  list: [],
  map: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isLoading: false,
  isUpdating: false,
  isDeleting: false,
  isFetching: false,
  isFetchingList: false,
  error: null,
}

export const inventoryAdjustmentsReducer = (
  state: InventoryAdjustmentsState = INITIAL_STATE,
  action: AnyAction,
): InventoryAdjustmentsState => {
  switch (action.type) {
    case FETCH_ADJUSTMENTS:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        error: null,
      }
    case FETCH_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        list: action.list,
        totalCount: action.totalCount,
      }
    case FETCH_ADJUSTMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_ADJUSTMENTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_ADJUSTMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_ADJUSTMENT:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        error: null,
      }
    case FETCH_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
      }
    case FETCH_ADJUSTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_VARIATION_ADJUSTMENTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_VARIATION_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.list,
      }
    case FETCH_VARIATION_ADJUSTMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_ADJUSTMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case CREATE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: [action.adjustmentId, ...state.list],
        totalCount: state.totalCount + 1,
      }
    case CREATE_ADJUSTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case BULK_CREATE_ADJUSTMENTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case BULK_CREATE_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case BULK_CREATE_ADJUSTMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_ADJUSTMENTS:
      return { ...state, map: secondLevelMerge(state.map, action.adjustments) }
    case EDIT_ADJUSTMENT:
      return {
        ...state,
        isUpdating: true,
        error: null,
      }
    case EDIT_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    case EDIT_ADJUSTMENT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_ADJUSTMENT:
      return {
        ...state,
        isDeleting: true,
        error: null,
      }
    case DELETE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        list: R.without([action.adjustmentId], state.list),
        map: R.dissoc(action.adjustmentId, state.map),
        totalCount: state.totalCount - 1,
      }
    case DELETE_ADJUSTMENT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

const getAdjustments = (state: RootState): InventoryAdjustmentsState =>
  state.inventoryAdjustments
export const getAdjustmentsIsLoading = (state: RootState) =>
  getAdjustments(state).isLoading
export const getAdjustmentsIsUpdating = (state: RootState) =>
  getAdjustments(state).isUpdating
export const getAdjustmentsIsDeleting = (state: RootState) =>
  getAdjustments(state).isDeleting
export const getAdjustmentsIsFetching = (state: RootState) =>
  getAdjustments(state).isFetching
export const getAdjustmentsIsFetchingList = (state: RootState) =>
  getAdjustments(state).isFetchingList
export const getAdjustmentsIsSaving = (isEdit: boolean) =>
  isEdit ? getAdjustmentsIsUpdating : getAdjustmentsIsLoading
export const getAdjustmentsTotalCount = (state: RootState) =>
  getAdjustments(state).totalCount
export const getAdjustmentsList = (state: RootState) =>
  getAdjustments(state).list
export const getAdjustmentsMap = (state: RootState) => getAdjustments(state).map
export const getAdjustment = (adjustmentId: string) =>
  createSelector(getAdjustmentsMap, (map) => R.prop(adjustmentId, map))
export const getMultipleAdjustments = (ids: string[]) =>
  createSelector(getAdjustmentsMap, (map) => R.props(ids, map))
