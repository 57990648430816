import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Comment as CommentIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

const EDITOR_BORDER_STYLE = '1px solid var(--ck-color-toolbar-border)'
const EDITOR_BACKGROUND_COLOR = 'var(--ck-color-toolbar-background)'
const EDITOR_HEADER_PADDING =
  'calc(var(--ck-spacing-tiny) + var(--ck-spacing-small)) var(--ck-spacing-standard)'

const useStyles = makeStyles(
  () => ({
    sidebarContainer: {
      backgroundColor: EDITOR_BACKGROUND_COLOR,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
    sidebarHeaderContainer: {
      backgroundColor: EDITOR_BACKGROUND_COLOR,
      padding: EDITOR_HEADER_PADDING,
      top: 0,
    },
    hide: {
      display: 'none',
    },
  }),
  { name: 'SidebarCommentsArea' },
)

export interface SidebarCommentsAreaProps {
  hide: boolean
  isEditorReady: boolean
}

const SidebarCommentsArea = forwardRef<
  HTMLDivElement,
  SidebarCommentsAreaProps
>(function SidebarCommentsArea({ isEditorReady, hide }, ref) {
  const classes = useStyles()

  const { t } = useTranslation(['Common'])

  return (
    <Grid
      container
      item
      border={EDITOR_BORDER_STYLE}
      className={classNames({ [classes.hide]: hide })}
      direction="column"
      xs={4}
    >
      {isEditorReady && (
        <Grid
          container
          item
          borderBottom={EDITOR_BORDER_STYLE}
          className={classes.sidebarHeaderContainer}
        >
          <CommentIcon fontSize="small" />
          <Text strong lineHeight={1.45} pl={0.5} variant="body2">
            {t('Common:COMMENTS_AREA')}
          </Text>
        </Grid>
      )}
      <Grid
        item
        className={classes.sidebarContainer}
        flexGrow={1}
        id="sidebar"
        ref={ref}
      />
    </Grid>
  )
})

export default SidebarCommentsArea
