import React from 'react'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import { StaticTableColumn } from '~/components/common/lists/table/StaticTable'
import { TableAmountCell } from '~/components/dashboard/clients/balance/table/common/TableAmountCell'
import { TableDateCell } from '~/components/dashboard/clients/balance/table/common/TableDateCell'
import { TableTextNavigateCell } from '~/components/dashboard/clients/balance/table/common/TableTextNavigateCell'
import { TableTextWithClickCell } from '~/components/dashboard/clients/balance/table/common/TableTextWithClickCell'
import { EstimateTableStatusCell } from '~/components/dashboard/clients/balance/table/estimates/EstimateTableStatusCell'
import i18nPortal from '~/locales/i18n'

import { ClientBillingActivityTable } from '../common/ClientBillingActivityTable'

type RowItem = GraphqlEstimate

const useStyles = makeStyles(
  (theme) => ({
    titleCell: {
      color: theme.colors.disabledLabelText,
    },
  }),
  { name: 'UseGetEstimateBillingActivityColumns' },
)

export const useGetEstimateBillingActivityColumns = (
  key: 'prop' | 'value',
  onClickEstimate: (data: GraphqlEstimate) => void,
): (PuiDataTableColumn | StaticTableColumn<RowItem>)[] => {
  const classes = useStyles()

  return [
    {
      id: 'date',
      label: i18nPortal.t<string>('Common:DATE_TIME'),
      [key]: (row: RowItem) => <TableDateCell date={row.invoiceDate} />,
    },
    {
      id: 'name',
      label: i18nPortal.t<string>('Common:ESTIMATE_BUDGET'),
      [key]: (row: RowItem) => (
        <TableTextWithClickCell
          value={row.name!}
          onClick={() => onClickEstimate(row)}
        />
      ),
    },
    {
      id: 'patientName',
      label: i18nPortal.t<string>('Common:PATIENT'),
      [key]: (row: RowItem) => (
        <TableTextNavigateCell
          openOnTheSameTab
          to={`/patient/${row.patient?.id}`}
          value={row.patient?.name!}
        />
      ),
    },
    {
      id: 'amountLow',
      label: (
        <Text
          strong
          className={classes.titleCell}
          fontSize="1.4rem"
          textAlign="right"
        >
          {i18nPortal.t<string>('Common:LOW_TOTAL')}
        </Text>
      ),
      [key]: (row: RowItem) => <TableAmountCell amount={row.subtotal?.low} />,
    },
    {
      id: 'amountHigh',
      label: (
        <Text
          strong
          className={classes.titleCell}
          fontSize="1.4rem"
          textAlign="right"
        >
          {i18nPortal.t<string>('Common:HIGH_TOTAL')}
        </Text>
      ),
      [key]: (row: RowItem) => <TableAmountCell amount={row.subtotal?.high} />,
    },
    {
      id: 'amountDeposit',
      label: (
        <Text
          strong
          className={classes.titleCell}
          fontSize="1.4rem"
          textAlign="right"
        >
          {i18nPortal.t<string>('Payments:PAYMENT_TYPES.DEPOSIT')}
        </Text>
      ),
      [key]: (row: RowItem) => <TableAmountCell amount={row.paidAmount} />,
    },
    {
      id: 'status',
      label: i18nPortal.t<string>('Common:STATUS'),
      [key]: (row: RowItem) => <EstimateTableStatusCell estimate={row} />,
    },
  ]
}

interface EstimateBillingActivityTableProps {
  estimates: GraphqlEstimate[]
  estimatesPerPage: number
  isLoading: boolean
  onEstimateNameClick: (data: GraphqlEstimate) => void
}

export const EstimateBillingActivityTable = ({
  estimates,
  estimatesPerPage,
  isLoading,
  onEstimateNameClick,
}: EstimateBillingActivityTableProps) => {
  const columns = useGetEstimateBillingActivityColumns(
    'prop',
    onEstimateNameClick,
  ) as StaticTableColumn<RowItem>[]

  return (
    <ClientBillingActivityTable
      columns={columns}
      isLoading={isLoading}
      itemsPerPage={estimatesPerPage}
      rowItems={estimates}
    />
  )
}
