import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import Typography from '~/components/elements/Typography/Typography'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface SignatureBoxProps {
  isDragging?: boolean
  onDelete?: () => void
}

export const SignatureBox = ({ onDelete, isDragging }: SignatureBoxProps) => {
  const { t } = useTranslation('Common')
  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={t('Common:SIGNATURE')}
        onDelete={onDelete}
      >
        <Grid
          container
          sx={{
            border: (theme) => theme.constants.tableBorder,
            borderRadius: '4px',
            maxWidth: 300,
            marginY: 1,
            marginX: 2,
            height: 45,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            sx={{
              borderRight: (theme) => theme.constants.tableBorder,
              height: '100%',
              paddingY: 1,
              paddingX: 1.5,
            }}
          >
            <Typography.H2 sx={{ flexGrow: 1 }}>
              {t('Common:SIGNATURE')}
            </Typography.H2>
          </Grid>
          <Grid item>
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DocumentElementAccordion>
    </Box>
  )
}
