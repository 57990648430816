import { IdObject } from '@pbt/pbt-ui-components'

import { SoapDiagnoseSearchResult } from '~/types'

export const composeSearchResultsWithSelectedItems = (
  searchResults: SoapDiagnoseSearchResult[],
  selectedItems: IdObject[],
) =>
  searchResults.reduce((acc, searchResult) => {
    const preselectedItem = selectedItems.find(
      ({ id }) => id === searchResult.item?.id,
    )
    return preselectedItem
      ? [
          ...acc,
          {
            ...searchResult,
            item: { ...searchResult.item, ...preselectedItem },
          },
        ]
      : [...acc, searchResult]
  }, [] as SoapDiagnoseSearchResult[])
