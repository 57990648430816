import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { removeOrder } from '~/store/actions/orders'
import {
  useGetIsOrderDeletable,
  useLabTestItemDecline,
  useUpdateOrderNotes,
} from '~/store/hooks/orders'
import { getOrderHasSingleLabTest } from '~/store/reducers/orders'
import { Order } from '~/types'
import useDialog from '~/utils/useDialog'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'

export interface LabTestActionsProps extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
}

const LabTestActions = ({
  editDisabled,
  onDelete: onDeleteProp,
  order,
  ...rest
}: LabTestActionsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const handleUpdateNotes = useUpdateOrderNotes(order)
  const orderHasSingleLabTest = useSelector(
    getOrderHasSingleLabTest(order.labTest?.labOrderId),
  )
  const { declineDisabled } = useLabTestItemDecline(order, editDisabled)
  const getIsDeletable = useGetIsOrderDeletable()
  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_RAIL_ORDER,
  })
  const [openRemoveFinalizedLabTestDialog, closeRemoveFinalizedLabTestDialog] =
    useDialog(DialogNames.REMOVE_FINALIZED_LAB_TEST)
  const [openRemoveLabOrderDialog, closeRemoveLabOrderDialog] = useDialog(
    DialogNames.REMOVE_LAB_ORDER,
  )
  const isDeletable = getIsDeletable(order)
  const isUnderOrder = Boolean(order.labTest?.labOrderId)

  const openRemoveOrderAlert = () => {
    openConfirmAlert({
      message: t('Common:YOU_WILL_REMOVE_SOMETHING', { something: order.name }),
      onConfirm: (proceed) => {
        if (proceed) {
          dispatch(removeOrder(order))
          onDeleteProp?.()
        }
      },
    })
  }

  const handleDeleteForFinalizedOrder = () => {
    if (orderHasSingleLabTest) {
      openRemoveLabOrderDialog({
        vendorId: order.labTest!.vendorId!,
        labOrderId: order.labTest!.labOrderId!,
        onClose: closeRemoveLabOrderDialog,
      })
      return
    }
    openRemoveFinalizedLabTestDialog({
      order,
      onClose: closeRemoveFinalizedLabTestDialog,
    })
  }

  const handleDelete = () => {
    if (isUnderOrder) {
      handleDeleteForFinalizedOrder()
      return
    }
    openRemoveOrderAlert()
  }

  return (
    <ItemWithBadgesActions
      declineDisabled={declineDisabled}
      hasNotes={Boolean(order.notes)}
      tooltip={<OrderTooltip order={order} />}
      onDelete={isDeletable ? handleDelete : undefined}
      onUpdateNotes={handleUpdateNotes}
      {...rest}
    />
  )
}

export default LabTestActions
