import * as R from 'ramda'

import { Task } from '~/types'
import { isNilOrEmpty } from '~/utils'

const isBothNilOrEqual = (a: any, b: any) =>
  (isNilOrEmpty(a) && isNilOrEmpty(b)) || R.equals(a, b)
const isDatesBothNilOrEqual = (a: any, b: any) =>
  (isNilOrEmpty(a) && isNilOrEmpty(b)) ||
  new Date(a).getTime() === new Date(b).getTime()

const TaskFields: (keyof Task)[] = [
  'assigneeId',
  'clientId',
  'patientId',
  'contactId',
  'appointmentId',
  'recurrencePeriod',
  'instructions',
  'name',
  'typeId',
  'notes',
  'recurrenceModeId',
  'occurrencesCount',
]

const TaskDateFields: (keyof Task)[] = ['dueDate', 'recurrenceEndDatetime']

const isAssigneeEqual = (initialTask: Task, newTask: Task) => {
  // because of the denormalization and different prop names we need to check both of them
  const initialAssigned = initialTask.assigned || initialTask.assigneeId
  const newAssigned = newTask.assigned || newTask.assigneeId
  return isBothNilOrEqual(initialAssigned, newAssigned)
}

export const getTaskChanges = (initialTask: Task, newTask: Task) => {
  const getPropsWithChanges = [
    ...TaskDateFields.filter(
      (key) => !isDatesBothNilOrEqual(initialTask[key], newTask[key]),
    ),
    ...TaskFields.filter(
      (key) => !isBothNilOrEqual(initialTask[key], newTask[key]),
    ),
  ]

  const changes = R.pick(getPropsWithChanges, newTask)

  if (!isAssigneeEqual(initialTask, newTask)) {
    changes.assigneeId = newTask.assigneeId
  }

  if (R.has('appointmentId', changes)) {
    changes.soapId = null
    changes.orderId = null
    changes.orderType = null
  }

  return changes
}
