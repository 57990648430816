import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'

import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import { PaymentTypes } from '~/constants/financeConstants'

const useStyles = makeStyles(
  (theme) => ({
    paymentTypesMenu: {
      width: 220,
    },
    paymentTypes: {
      marginLeft: theme.spacing(2),
    },
    dropdownButton: {
      color: theme.colors.link,
    },
  }),
  { name: 'MorePaymentOptionsMenu' },
)

interface MorePaymentOptionsMenuProps {
  isGoAvailableForPractice: boolean
  isLoading: boolean
  onPaymentTypeSelected: (value: PaymentTypes) => void
  paymentAmount: any
}

export const MorePaymentOptionsMenu = ({
  isGoAvailableForPractice,
  isLoading,
  onPaymentTypeSelected,
  paymentAmount,
}: MorePaymentOptionsMenuProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Invoices')

  if (!isGoAvailableForPractice) {
    return null
  }

  return (
    <MenuDropdown
      linkButton
      className={classes.paymentTypes}
      classes={{
        menu: classes.paymentTypesMenu,
        button: classes.dropdownButton,
      }}
      disabled={isLoading || !Number(paymentAmount)}
      items={[
        {
          name: t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.ENTER_CREDIT_CARD'),
          value: PaymentTypes.CREDIT_CARD,
        },
        {
          name: t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.PRE-AUTHORIZATION'),
          value: PaymentTypes.PRE_AUTHORIZATION,
        },
      ]}
      title={t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.MORE_PAYMENT_OPTIONS')}
      onSelected={onPaymentTypeSelected}
    />
  )
}
