import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  CustomFieldValidatorState,
  Nil,
  Text,
  TextWithTooltip,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import NdcNumberInput, { NdcNumberInputProps } from './NdcNumberInput'
import { getNdc11DigitsFormat } from './ndcUtils'

const useStyles = makeStyles(
  () => ({
    title: {},
    titleContainer: {},
    ndc11Label: {},
  }),
  { name: 'NdcNumberForm' },
)

export interface NdcNumberFormProps {
  classes?: ClassesType<typeof useStyles>
  inputProps?: Partial<NdcNumberInputProps>
  nationalDrugCode: string | Nil
  required?: boolean
}

export interface NdcNumberFormHandle extends ValidateHandle {
  getNationalDrugCode: () => string
}

const NdcNumberForm = forwardRef<NdcNumberFormHandle, NdcNumberFormProps>(
  function NdcNumberForm(
    {
      required,
      classes: classesProp,
      nationalDrugCode: initialNationalDrugCode,
      inputProps = {},
    },
    ref,
  ) {
    const classes = useStyles({ classes: classesProp })
    const { t } = useTranslation(['Abbreviations', 'Common', 'Tooltips'])

    const validateNationalDrugCode = ({
      state: { nationalDrugCode },
    }: CustomFieldValidatorState) =>
      required
        ? Boolean(nationalDrugCode) &&
          Boolean(getNdc11DigitsFormat(nationalDrugCode))
        : !nationalDrugCode || Boolean(getNdc11DigitsFormat(nationalDrugCode))

    const {
      fields: { nationalDrugCode },
      validate,
    } = useFields(
      [
        {
          name: 'nationalDrugCode',
          label: t('Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.NUMBER'),
          initialValue: initialNationalDrugCode,
          messages: {
            required: t(
              'Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.PROVIDE_VALID_NATIONAL_DRUG_CODE_NUMBER',
            ),
          },
          validators: [
            { validator: validateNationalDrugCode, validatorName: 'ndc' },
          ],
        },
      ],
      false,
    )

    useImperativeHandle(ref, () => ({
      validate,
      getNationalDrugCode: () => nationalDrugCode.value,
    }))

    const nationalDrugCode11DigitsFormat = getNdc11DigitsFormat(
      nationalDrugCode.value,
    )

    return (
      <>
        <Grid item className={classes.titleContainer} md={12} xs={12}>
          <TextWithTooltip
            strong
            className={classes.title}
            tooltipText={t('Tooltips:RHAPSODY_WILL_USE_THESE_NUMBERS')}
          >
            {nationalDrugCode.label}
          </TextWithTooltip>
        </Grid>
        <Grid container item alignItems="flex-end" spacing={3}>
          <Grid item md={5} xs={6}>
            <NdcNumberInput
              field={nationalDrugCode}
              margin="none"
              required={required}
              {...inputProps}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <Text className={classes.ndc11Label}>
              {nationalDrugCode11DigitsFormat
                ? t('Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.11_DIGIT', {
                    nationalDrugCode11DigitsFormat,
                  })
                : ''}
            </Text>
          </Grid>
        </Grid>
      </>
    )
  },
)

export default NdcNumberForm
