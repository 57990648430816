import { all, debounce, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import {
  calculateRefundAmount as calculateRefundAmountAPI,
  fetchRefundInvoice as fetchRefundInvoiceAPI,
  postRefundInvoice as postRefundInvoiceAPI,
  voidRefundInvoice as voidRefundInvoiceAPI,
} from '~/api'
import { finishLoading, startLoading } from '~/store/duck/progress'

import {
  calculateRefundAmount,
  calculateRefundAmountFailure,
  calculateRefundAmountSuccess,
  fetchRefundInvoice,
  fetchRefundInvoiceFailure,
  fetchRefundInvoiceSuccess,
  getRefundsId,
  postRefundInvoice,
  postRefundInvoiceFailure,
  postRefundInvoiceSuccess,
  voidRefundInvoice,
  voidRefundInvoiceFailure,
  voidRefundInvoiceSuccess,
} from '../duck/refunds'
import requestAPI from './utils/requestAPI'

export function* calculateRefundAmountSaga({
  payload,
}: ReturnType<typeof calculateRefundAmount>) {
  try {
    yield put(startLoading('calculateRefundAmount'))
    const calculationResult = yield* requestAPI(
      calculateRefundAmountAPI,
      payload.input,
    )

    yield put(calculateRefundAmountSuccess(calculationResult))
    yield put(finishLoading('calculateRefundAmount'))
  } catch (error) {
    yield put(calculateRefundAmountFailure({ error: error as ApiError }))
    yield put(finishLoading('calculateRefundAmount'))
  }
}

export function* fetchRefundInvoiceSaga({
  payload,
}: ReturnType<typeof fetchRefundInvoice>) {
  try {
    const refundInvoiceId: string = yield select(getRefundsId)
    const result = yield* requestAPI(
      fetchRefundInvoiceAPI,
      payload?.id || refundInvoiceId,
    )

    yield put(fetchRefundInvoiceSuccess(result))
  } catch (error) {
    yield put(fetchRefundInvoiceFailure({ error: error as ApiError }))
  }
}

export function* postRefundInvoiceSaga({
  payload,
}: ReturnType<typeof postRefundInvoice>) {
  try {
    const result = yield* requestAPI(postRefundInvoiceAPI, payload.input)

    yield put(postRefundInvoiceSuccess(result.id))
  } catch (error) {
    yield put(postRefundInvoiceFailure({ error: error as ApiError }))
  }
}

export function* voidRefundInvoiceSaga({
  payload,
}: ReturnType<typeof voidRefundInvoice>) {
  try {
    const result = yield* requestAPI(voidRefundInvoiceAPI, payload.id)

    yield put(voidRefundInvoiceSuccess(result.id))
  } catch (error) {
    yield put(voidRefundInvoiceFailure({ error: error as ApiError }))
  }
}

function* watchCalculateRefundAmount() {
  yield debounce(
    Defaults.DEBOUNCE_ACTION_TIME,
    calculateRefundAmount.type,
    calculateRefundAmountSaga,
  )
}

function* watchFetchRefundInvoice() {
  yield takeLatest(fetchRefundInvoice.type, fetchRefundInvoiceSaga)
}

function* watchPostRefundInvoice() {
  yield takeLatest(postRefundInvoice.type, postRefundInvoiceSaga)
}

function* watchVoidRefundInvoice() {
  yield takeLatest(voidRefundInvoice.type, voidRefundInvoiceSaga)
}

export default function* refundsSaga() {
  yield all([
    watchCalculateRefundAmount(),
    watchFetchRefundInvoice(),
    watchPostRefundInvoice(),
    watchVoidRefundInvoice(),
  ])
}
