import { gql } from '@apollo/client/core'

import {
  BIG_DECIMAL_VITAL_FIELDS,
  CONSTANT_ENTITY_VITAL_FIELDS,
  INTEGER_VITAL_FIELDS,
} from '../fragments/vitals'

export const CREATE_VITAL_RECORD = gql`
  ${BIG_DECIMAL_VITAL_FIELDS}
  ${INTEGER_VITAL_FIELDS}
  ${CONSTANT_ENTITY_VITAL_FIELDS}
  mutation CreateVital($businessId: ID, $input: VitalInput!) {
    createVital(businessId: $businessId, input: $input) {
      id
      soapId
      businessId
      patientId
      finalized
      recordedById
      recordedDate
      deleted
      creatorId
      modifierId
      updatedAt
      createdAt
      weight {
        ...BigDecimalVitalValueFields
      }
      temperature {
        ...BigDecimalVitalValueFields
      }
      heartRate {
        ...IntegerVitalValueFields
      }
      respirationRate {
        ...IntegerVitalValueFields
      }
      capillaryRefillTime {
        ...ConstantEntityVitalValueFields
      }
      mucousMembraneColorId {
        ...ConstantEntityVitalValueFields
      }
      mucousMembraneMoistureId {
        ...ConstantEntityVitalValueFields
      }
      skinTurgorId {
        ...ConstantEntityVitalValueFields
      }
      bodyConditionScore {
        ...IntegerVitalValueFields
      }
      fearAnxietyScore {
        ...IntegerVitalValueFields
      }
      painScore {
        ...IntegerVitalValueFields
      }
      dyspnea {
        value
        notes
      }
      mentationId {
        ...ConstantEntityVitalValueFields
      }
      pulseCharacterId {
        ...ConstantEntityVitalValueFields
      }
      bloodPressure {
        ...BigDecimalVitalValueFields
      }
      spo2 {
        ...BigDecimalVitalValueFields
      }
      etco2 {
        ...BigDecimalVitalValueFields
      }
      o2Level {
        ...BigDecimalVitalValueFields
      }
      isoSevoLevel {
        ...BigDecimalVitalValueFields
      }
      recumbencyId {
        ...ConstantEntityVitalValueFields
      }
      muscleMassScore {
        ...IntegerVitalValueFields
      }
    }
  }
`

export const ADD_AND_MERGE_VITAL = gql`
  ${BIG_DECIMAL_VITAL_FIELDS}
  ${INTEGER_VITAL_FIELDS}
  ${CONSTANT_ENTITY_VITAL_FIELDS}
  mutation AddVital(
    $businessId: ID
    $soapId: ID
    $patientId: ID!
    $input: VitalInput!
  ) {
    addVital(
      businessId: $businessId
      soapId: $soapId
      patientId: $patientId
      input: $input
    ) {
      vital {
        id
        soapId
        businessId
        patientId
        finalized
        recordedById
        recordedDate
        deleted
        creatorId
        modifierId
        updatedAt
        createdAt
        weight {
          ...BigDecimalVitalValueFields
        }
        temperature {
          ...BigDecimalVitalValueFields
        }
        heartRate {
          ...IntegerVitalValueFields
        }
        respirationRate {
          ...IntegerVitalValueFields
        }
        capillaryRefillTime {
          ...ConstantEntityVitalValueFields
        }
        mucousMembraneColorId {
          ...ConstantEntityVitalValueFields
        }
        mucousMembraneMoistureId {
          ...ConstantEntityVitalValueFields
        }
        skinTurgorId {
          ...ConstantEntityVitalValueFields
        }
        bodyConditionScore {
          ...IntegerVitalValueFields
        }
        fearAnxietyScore {
          ...IntegerVitalValueFields
        }
        painScore {
          ...IntegerVitalValueFields
        }
        dyspnea {
          value
          notes
        }
        mentationId {
          ...ConstantEntityVitalValueFields
        }
        pulseCharacterId {
          ...ConstantEntityVitalValueFields
        }
        bloodPressure {
          ...BigDecimalVitalValueFields
        }
        spo2 {
          ...BigDecimalVitalValueFields
        }
        etco2 {
          ...BigDecimalVitalValueFields
        }
        o2Level {
          ...BigDecimalVitalValueFields
        }
        isoSevoLevel {
          ...BigDecimalVitalValueFields
        }
        recumbencyId {
          ...ConstantEntityVitalValueFields
        }
        muscleMassScore {
          ...IntegerVitalValueFields
        }
      }
    }
  }
`

export const UPDATE_VITAL_RECORD = gql`
  ${BIG_DECIMAL_VITAL_FIELDS}
  ${INTEGER_VITAL_FIELDS}
  ${CONSTANT_ENTITY_VITAL_FIELDS}
  mutation UpdateVital($businessId: ID, $id: ID!, $input: VitalInput!) {
    updateVital(businessId: $businessId, id: $id, input: $input) {
      id
      soapId
      businessId
      patientId
      finalized
      recordedById
      recordedDate
      deleted
      creatorId
      modifierId
      updatedAt
      createdAt
      weight {
        ...BigDecimalVitalValueFields
      }
      temperature {
        ...BigDecimalVitalValueFields
      }
      heartRate {
        ...IntegerVitalValueFields
      }
      respirationRate {
        ...IntegerVitalValueFields
      }
      capillaryRefillTime {
        ...ConstantEntityVitalValueFields
      }
      mucousMembraneColorId {
        ...ConstantEntityVitalValueFields
      }
      mucousMembraneMoistureId {
        ...ConstantEntityVitalValueFields
      }
      skinTurgorId {
        ...ConstantEntityVitalValueFields
      }
      bodyConditionScore {
        ...IntegerVitalValueFields
      }
      fearAnxietyScore {
        ...IntegerVitalValueFields
      }
      painScore {
        ...IntegerVitalValueFields
      }
      dyspnea {
        value
        notes
      }
      mentationId {
        ...ConstantEntityVitalValueFields
      }
      pulseCharacterId {
        ...ConstantEntityVitalValueFields
      }
      bloodPressure {
        ...BigDecimalVitalValueFields
      }
      spo2 {
        ...BigDecimalVitalValueFields
      }
      etco2 {
        ...BigDecimalVitalValueFields
      }
      o2Level {
        ...BigDecimalVitalValueFields
      }
      isoSevoLevel {
        ...BigDecimalVitalValueFields
      }
      recumbencyId {
        ...ConstantEntityVitalValueFields
      }
      muscleMassScore {
        ...IntegerVitalValueFields
      }
    }
  }
`

export const DELETE_VITAL_RECORD = gql`
  mutation DeleteVital($businessId: ID, $id: ID!) {
    deleteVital(businessId: $businessId, id: $id)
  }
`
