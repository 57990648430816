import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Layout, Responsive, ResponsiveProps } from 'react-grid-layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SizeMeProps, withSize } from 'react-sizeme'
import {
  Close as CloseIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  Defaults,
  FilterLabel,
  LandingWidgetsConstant,
  LanguageUtils,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import {
  LandingLayoutBreakpoints,
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import {
  clearLayout,
  clearWidgetsData,
  deleteLandingLayout,
  editLandingLayout,
  fetchLandingLayout,
  fetchWidgetsData,
  getLayout,
  getLayoutIsLoading,
  getSettings,
  getSettingsIsLoading,
  getWidgets,
} from '~/store/duck/landing'
import { getLandingWidgets } from '~/store/reducers/constants'
import { LandingLayout, LandingSettings, LandingSettingsItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import AdmittedWidget from './widgets/admitted/AdmittedWidget'
import TodayAppointmentsWidget from './widgets/appointments/TodayAppointmentsWidget'
import CommunicationWidget from './widgets/communication/CommunicationWidget'
import CustomerSatisfaction from './widgets/customerSatisfaction/CustomerSatisfaction'
import ExpandedWidget from './widgets/ExpandedWidget'
import LabsWidget from './widgets/labs/LabsWidget'
import OpenSoapWidget from './widgets/soap/OpenSoapWidget'
import TasksWidget from './widgets/tasks/TasksWidget'
import TimeTrackingWidget from './widgets/timeTracking/TimeTrackingWidget'
import UpcomingWidget from './widgets/upcoming/UpcomingWidget'
import WidgetsFilter from './widgets/WidgetsFilter'
import {
  getNewWidget,
  getWidgetSizeProps,
  LOCKED_WIDGET_PROPS,
  UNLOCKED_WIDGET_PROPS,
  WIDGET_LOCAL_PROPS,
} from './widgets/widgetUtils'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import './react-grid-layout-overrides.css'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    widget: {
      border: theme.constants.tableBorderSelected,
      backgroundColor: theme.colors.tableBackground,
      padding: theme.spacing(1),
      height: '100%',
    },
    toolbar: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    toolbarButton: {
      marginLeft: theme.spacing(2),
    },
    iconButton: {
      padding: theme.spacing(1),
    },
    widgetFilter: {
      height: 32,
    },
    hidden: {
      display: 'none',
    },
    closeButton: {
      minWidth: 'unset',
      padding: theme.spacing(1),
    },
  }),
  { name: 'LandingPage' },
)

type SizeHocProps = { size: SizeMeProps['size'] } & ResponsiveProps

const withSizeHOC = withSize()

const ResponsiveGridLayout = withSizeHOC(({ size, ...props }: SizeHocProps) => (
  <Responsive width={size.width || undefined} {...props} />
))

const LayoutWidthToWidgetWidthTypeMap = {
  6: WidgetWidthType.FULL_WIDTH,
  4: WidgetWidthType.TWO_THIRDS_WIDTH,
  3: WidgetWidthType.HALF_WIDTH,
  2: WidgetWidthType.THIRD_WIDTH,
}

const ColsMap = { lg: 6, md: 3, sm: 2, xs: 2, xxs: 2 }

const BreakpointsMap = { lg: 800, md: 600, sm: 400 }

const getBreakpointFromWidth = R.cond([
  [R.lte(BreakpointsMap.lg), R.always(LandingLayoutBreakpoints.LG)],
  [R.lte(BreakpointsMap.md), R.always(LandingLayoutBreakpoints.MD)],
  [R.T, R.always(LandingLayoutBreakpoints.SM)],
])

const LandingPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Landing'])

  const LandingWidgets: LandingWidgetsConstant[] =
    useSelector(getLandingWidgets)
  const availableWidgets = useSelector(getWidgets)
  const availableWidgetKeys = R.pluck('alias', availableWidgets)
  const initialLayout = useSelector(getLayout)
  const initialSettings = useSelector(getSettings)
  const isLayoutLoading = useSelector(getLayoutIsLoading)

  const [layouts, setLayouts] = useState<LandingLayout>(initialLayout)
  const [prevLayouts, setPrevLayouts] = useState<LandingLayout>()
  const [settings, setSettings] = useState(initialSettings)
  const [activeBreakpoint, setActiveBreakpoint] =
    useState<LandingLayoutBreakpoints>(LandingLayoutBreakpoints.LG)
  const [settingsMode, setSettingsMode] = useState(false)
  const [widgetsFilterOpen, setWidgetsFilterOpen] = useState(false)
  const [selectedWidgets, setSelectedWidgets] = useState<string[]>([])
  const [prevSelectedWidgets, setPrevSelectedWidgets] = useState<string[]>([])
  const [expandedWidgetKey, setExpandedWidgetKey] = useState<string>()
  const pollingIntervalRef = useRef<number | null>()

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.LANDING_LAYOUT_RESET_DEFAULT,
  })

  const isInitialLayoutLoading = isLayoutLoading && R.isEmpty(layouts)

  const findByAlias = (alias: string) =>
    Utils.findByProp(alias, LandingWidgets, 'alias')?.id as string
  const getLandingWidgetName = (id: string) =>
    LanguageUtils.getConstantTranslatedName(
      id,
      LandingWidgets,
    ) as LandingWidgetName
  const getLandingWidgetAlias = (id: string) =>
    Utils.getConstantsValue(id, LandingWidgets)?.alias

  const widgetsToLoad = availableWidgetKeys

  const widgetsToPoll = R.without(
    [LandingWidgetName.TIME_TRACKING],
    availableWidgetKeys,
  )

  const loadWidgetsData = (widgets: string[], quiet: boolean = false) => {
    const keysChunks = R.splitEvery(3, widgets)
    keysChunks.forEach((chunk) =>
      dispatch(
        fetchWidgetsData(chunk, {
          quiet,
          landingType: LandingType.LANDING_DASHBOARD,
        }),
      ),
    )
  }

  const stopPollingInterval = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef?.current)
      pollingIntervalRef.current = null
    }
  }

  const startPollingWidgetsData = () => {
    stopPollingInterval()
    pollingIntervalRef.current = window.setInterval(
      () => loadWidgetsData(widgetsToPoll, true),
      Defaults.REFRESH_INTERVAL,
    )
  }

  const loadWidgetsDataAndStartPolling = () => {
    loadWidgetsData(widgetsToLoad)
    startPollingWidgetsData()
  }

  const loadWidgetsDataAfterLayout = useCloseAfterCreation(
    loadWidgetsDataAndStartPolling,
    getLayoutIsLoading,
  )

  const updateWidgetsData = (
    prevSettings: LandingSettings,
    currentSettings: LandingSettings,
  ) => {
    const widgetsToUpdate = R.pipe(
      R.keys,
      R.filter(
        (key: string) =>
          !deepEqual(prevSettings?.[key], currentSettings?.[key]),
      ),
      R.map(getLandingWidgetAlias),
    )(currentSettings)

    loadWidgetsData(widgetsToUpdate, false)
  }

  const updateWidgetDataAfterSettingsChange = useCloseAfterCreation(
    updateWidgetsData,
    getSettingsIsLoading,
  )

  const gridLayoutRef = useRef<HTMLAnchorElement & { domEl: Element }>()

  const setInitialBreakpoint = () => {
    const initialWidth = gridLayoutRef.current?.domEl?.clientWidth
    const initialBreakpoint = initialWidth
      ? getBreakpointFromWidth(initialWidth)
      : LandingLayoutBreakpoints.LG
    setActiveBreakpoint(initialBreakpoint)
  }

  useEffect(() => {
    setSettings(initialSettings)
  }, [initialSettings])

  useEffect(() => {
    dispatch(fetchLandingLayout())
    loadWidgetsDataAfterLayout()
    setInitialBreakpoint()
  }, [])

  useEffect(
    () => () => {
      // cleanup
      stopPollingInterval()
      dispatch(clearLayout())
      dispatch(clearWidgetsData(LandingType.LANDING_DASHBOARD))
    },
    [],
  )

  useEffect(() => {
    if (initialLayout && !R.isEmpty(initialLayout) && initialLayout.lg) {
      const setLayoutWithDefaults = (layout: Layout) => ({
        ...layout,
        ...getWidgetSizeProps(getLandingWidgetAlias(layout.i)),
        ...LOCKED_WIDGET_PROPS,
      })

      const isWidgetAvailable = (layout: Layout) =>
        availableWidgetKeys?.includes(getLandingWidgetAlias(layout.i))

      const processLayout = R.pipe(
        R.filter(isWidgetAvailable),
        R.map(setLayoutWithDefaults),
      )

      setLayouts({
        ...initialLayout,
        sm: processLayout(initialLayout.sm || []),
        md: processLayout(initialLayout.md || []),
        lg: processLayout(initialLayout.lg || []),
      })

      const initialSelectedWidgets = R.pluck('i', initialLayout.lg)
      setSelectedWidgets(initialSelectedWidgets)
    }
  }, [initialLayout])

  const widgets = R.pipe(
    R.filter<LandingWidgetsConstant>(({ alias: key }) =>
      availableWidgetKeys?.includes(key),
    ),
    R.sortBy(R.propOr(undefined, 'nameTranslation')),
  )(LandingWidgets)

  const filterRef = useRef<HTMLDivElement>(null)

  const handleSettingsModeChange = (
    isSettingsMode: boolean,
    newLayouts: LandingLayout,
  ) => {
    if (isSettingsMode) {
      setPrevLayouts(newLayouts)
      setPrevSelectedWidgets(selectedWidgets)
    }

    const updateLayouts = (layout: Layout) => ({
      ...layout,
      ...(isSettingsMode ? UNLOCKED_WIDGET_PROPS : LOCKED_WIDGET_PROPS),
    })

    setLayouts({
      ...newLayouts,
      sm: newLayouts.sm?.map(updateLayouts),
      md: newLayouts.md?.map(updateLayouts),
      lg: newLayouts.lg?.map(updateLayouts),
    })

    setSettingsMode(isSettingsMode)
  }

  const handleLayoutChange = (_: Layout[], allLayouts: LandingLayout) => {
    setLayouts({
      ...layouts,
      sm: allLayouts.sm,
      md: allLayouts.md,
      lg: allLayouts.lg,
    })
  }

  const handleDeleteWidget = (i: string) => {
    const filterOut = (layout: Layout) => layout.i !== i

    setLayouts({
      ...layouts,
      sm: layouts.sm?.filter(filterOut),
      md: layouts.md?.filter(filterOut),
      lg: layouts.lg?.filter(filterOut),
    })
  }

  const handleResetToDefault = () => {
    dispatch(deleteLandingLayout())
    handleSettingsModeChange(false, layouts)
  }

  const handleCheckIfResetToDefault = () => {
    openConfirmAlert({
      applyCustomMessage: true,
      message: t('Landing:LANDING_PAGE.CONFIRM_ALERT_MESSAGE'),
      onConfirm: (proceed: boolean) => (proceed ? handleResetToDefault() : R.F),
      okButtonText: t('Common:YES'),
      cancelButtonText: t('Common:NO'),
    })
  }

  const handleSaveLayout = () => {
    const filterOut = (layout: Layout) => R.omit(WIDGET_LOCAL_PROPS, layout)
    const layout = {
      ...layouts,
      sm: layouts.sm?.filter(filterOut),
      md: layouts.md?.filter(filterOut),
      lg: layouts.lg?.filter(filterOut),
    }

    dispatch(editLandingLayout(layout, settings))
    updateWidgetDataAfterSettingsChange(initialSettings, settings)

    handleSettingsModeChange(false, layouts)
  }

  const handleCancelEditLayout = () => {
    setLayouts(prevLayouts as LandingLayout)
    setSelectedWidgets(prevSelectedWidgets)

    setSettingsMode(false)
  }

  const WidgetsMap = {
    [findByAlias(LandingWidgetName.TODAY_APPOINTMENTS)]:
      TodayAppointmentsWidget,
    [findByAlias(LandingWidgetName.ADMITTED)]: AdmittedWidget,
    [findByAlias(LandingWidgetName.UPCOMING)]: UpcomingWidget,
    [findByAlias(LandingWidgetName.OPEN_SOAPS)]: OpenSoapWidget,
    [findByAlias(LandingWidgetName.TIME_TRACKING)]: TimeTrackingWidget,
    [findByAlias(LandingWidgetName.COMMUNICATIONS)]: CommunicationWidget,
    [findByAlias(LandingWidgetName.MY_TASKS)]: TasksWidget,
    [findByAlias(LandingWidgetName.LABS)]: LabsWidget,
    [findByAlias(LandingWidgetName.CUSTOMER_SATISFACTION)]:
      CustomerSatisfaction,
  }

  const getWidgetWidthType = (widgetKey: string) => {
    const widgetLayout = layouts[activeBreakpoint]?.find(
      (widget) => widget.i === widgetKey,
    )

    return widgetLayout?.w
      ? LayoutWidthToWidgetWidthTypeMap[
          widgetLayout.w as keyof typeof LayoutWidthToWidgetWidthTypeMap
        ]
      : WidgetWidthType.FULL_WIDTH
  }

  const handleWidgetsFilterChange = ({
    value: newSelectedWidgets,
  }: {
    value: string[]
  }) => {
    const removed = R.difference(selectedWidgets, newSelectedWidgets)
    const added = R.difference(newSelectedWidgets, selectedWidgets)

    if (removed.length) {
      const filterOut = (layout: Layout) => !removed.includes(layout.i)

      setLayouts((stateLayouts: LandingLayout) => ({
        ...stateLayouts,
        sm: stateLayouts.sm?.filter(filterOut),
        md: stateLayouts.md?.filter(filterOut),
        lg: stateLayouts.lg?.filter(filterOut),
      }))
    }

    if (added.length) {
      const newWidgets = added.map((widgetKey) =>
        getNewWidget(widgetKey, getLandingWidgetName(widgetKey)),
      )
      setLayouts((stateLayouts: LandingLayout) => ({
        ...stateLayouts,
        sm: [...newWidgets, ...(stateLayouts.sm || [])],
        md: [...newWidgets, ...(stateLayouts.md || [])],
        lg: [...newWidgets, ...(stateLayouts.lg || [])],
      }))
    }

    setSelectedWidgets(newSelectedWidgets)
  }

  const handleExpand = (widgetKey: string) => {
    setExpandedWidgetKey(widgetKey)
  }

  const handleResize = (
    layout: Layout[],
    oldLayoutItem: Layout,
    layoutItem: Layout,
    placeholder: Layout,
  ) => {
    const allowedW = ColsMap[activeBreakpoint] - oldLayoutItem.x

    const newItemW = R.cond([
      [R.gte(2), R.always(2)],
      [R.gte(3), R.always(allowedW < 3 ? 2 : 3)],
      [R.gte(4), R.always(allowedW < 4 ? 3 : 4)],
      [R.T, R.always(allowedW < 6 ? 4 : 6)],
    ])(layoutItem.w)

    layoutItem.w = newItemW
    placeholder.w = newItemW
  }

  const handleChangeWidgetSettings = (
    widgetKey: string,
    newSettings: LandingSettingsItem,
  ) => {
    setSettings({ ...settings, [widgetKey]: newSettings })
  }

  return (
    <Grid className={classes.root}>
      <CircularProgressOverlay open={isInitialLayoutLoading} />
      {expandedWidgetKey && (
        <ExpandedWidget
          name={getLandingWidgetName(expandedWidgetKey)}
          widgetComponent={WidgetsMap[expandedWidgetKey]}
          onClose={() => setExpandedWidgetKey(undefined)}
        />
      )}
      <Grid
        container
        className={classes.toolbar}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid container item>
          <Text mr={1.5} variant="h1">
            {t('Common:DASHBOARD')}
          </Text>
          {settingsMode && (
            <>
              <Grid item ref={filterRef}>
                <FilterLabel
                  className={classes.widgetFilter}
                  filter="filter"
                  label={t('Landing:LANDING_PAGE.WIDGETS')}
                  open={widgetsFilterOpen}
                  onFilterClick={() => {
                    setWidgetsFilterOpen(!widgetsFilterOpen)
                  }}
                />
              </Grid>
              {filterRef && (
                <WidgetsFilter
                  resetOnReOpen
                  anchorEl={filterRef.current}
                  open={widgetsFilterOpen}
                  value={selectedWidgets}
                  widgets={widgets}
                  onChange={handleWidgetsFilterChange}
                  onClose={(event: ChangeEvent<HTMLDivElement>) => {
                    if (
                      !event?.target ||
                      !filterRef.current?.contains(event.target)
                    ) {
                      setWidgetsFilterOpen(false)
                    }
                  }}
                />
              )}
            </>
          )}
        </Grid>
        {settingsMode ? (
          <Grid container justifyContent="flex-end">
            <ButtonWithLoader
              className={classes.toolbarButton}
              color="secondary"
              onClick={handleCheckIfResetToDefault}
            >
              {t('Landing:LANDING_PAGE.RESET_DEFAULT_LAYOUT')}
            </ButtonWithLoader>
            <ButtonWithLoader
              className={classes.toolbarButton}
              color="primary"
              onClick={handleSaveLayout}
            >
              {t('Landing:LANDING_PAGE.SAVE_LAYOUT')}
            </ButtonWithLoader>
            <ButtonWithLoader
              className={classNames(classes.toolbarButton, classes.closeButton)}
              color="secondary"
              onClick={handleCancelEditLayout}
            >
              <CloseIcon />
            </ButtonWithLoader>
          </Grid>
        ) : (
          <IconButton
            className={classes.iconButton}
            size="large"
            onClick={() => handleSettingsModeChange(true, layouts)}
          >
            <SettingsIcon />
          </IconButton>
        )}
      </Grid>
      <ResponsiveGridLayout
        breakpoints={BreakpointsMap}
        className={expandedWidgetKey ? classes.hidden : undefined}
        cols={ColsMap}
        compactType={null}
        containerPadding={[6, 0]}
        layouts={layouts}
        margin={[0, 0]}
        // @ts-ignore
        ref={gridLayoutRef}
        rowHeight={40}
        onBreakpointChange={(breakpoint: LandingLayoutBreakpoints) => {
          setActiveBreakpoint(breakpoint)
        }}
        onLayoutChange={handleLayoutChange}
        onResize={handleResize}
      >
        {(layouts[activeBreakpoint] || [])
          .filter(({ i: widgetKey }) => WidgetsMap[widgetKey])
          .map(({ i: widgetKey, h }) => {
            const Widget = WidgetsMap[widgetKey]

            return (
              <div key={widgetKey}>
                <Widget
                  isSettingsMode={settingsMode}
                  name={getLandingWidgetName(widgetKey)}
                  // first row is widget heading, so we have n - 1 actual rows
                  rowsCount={h - 1}
                  settings={settings?.[widgetKey]}
                  widgetKey={widgetKey}
                  widthType={getWidgetWidthType(widgetKey)}
                  onChangeWidgetSettings={(
                    newSettings: LandingSettingsItem,
                  ) => {
                    handleChangeWidgetSettings(widgetKey, newSettings)
                  }}
                  onDeleteWidget={handleDeleteWidget}
                  onExpand={() => handleExpand(widgetKey)}
                />
              </div>
            )
          })}
      </ResponsiveGridLayout>
    </Grid>
  )
}

export default LandingPage
