import { useSelector } from 'react-redux'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import {
  ShipmentItemStatus,
  ShipmentStatus,
  ShipmentStatusToShipmentItemStatusMatch,
} from '~/constants/shipments'
import {
  getInventoryShipmentItemStatus,
  getInventoryShipmentStatus,
} from '~/store/reducers/constants'

const useGetBulkShipmentItemStatusIdByShipment = () => {
  const InventoryShipmentItemStatus = useSelector(
    getInventoryShipmentItemStatus,
  )
  const InventoryShipmentStatus = useSelector(getInventoryShipmentStatus)

  const DraftShipmentItemStatusId: string = Utils.findConstantIdByName(
    ShipmentItemStatus.DRAFT,
    InventoryShipmentItemStatus,
  )

  return (shipmentStatusId: string | Nil) => {
    const shipmentItemStatusName: ShipmentStatus = Utils.getConstantName(
      shipmentStatusId,
      InventoryShipmentStatus,
    )

    const shipmentItemStatusId: string | Nil = Utils.findConstantIdByName(
      ShipmentStatusToShipmentItemStatusMatch[shipmentItemStatusName],
      InventoryShipmentItemStatus,
    )

    return shipmentItemStatusId || DraftShipmentItemStatusId
  }
}

export default useGetBulkShipmentItemStatusIdByShipment
