import { isNil } from 'ramda'
import { TableFilter, ValueOf } from '@pbt/pbt-ui-components'

import { BusinessAppointmentType } from '~/types/entities/businessAppointmentType'

export enum Filters {
  COLOR = 'color',
  EVENT_TYPE_IDS = 'EventTypeIds',
  ENABLED = 'enabled',
  ONLINE_SCHEDULING_ENABLED = 'onlineSchedulingAllowed',
}
export enum Sortings {
  ORDER_ONLY_BY_NAME_ASCENDING = 'orderOnlyByNameAscending',
}

export type SortingsType = ValueOf<typeof Sortings>

export type FilterType = ValueOf<typeof Filters>

export const filterableProperties: Record<
  FilterType,
  keyof BusinessAppointmentType
> = {
  color: 'color',
  EventTypeIds: 'eventTypeId',
  enabled: 'enabled',
  onlineSchedulingAllowed: 'onlineSchedulingAllowed',
} as const

export const getFilterValueStrings = (filter: TableFilter): string[] =>
  String(filter.value)
    ?.split(',')
    .map((str) => str.trim()) || []

export const doesAppointmentTypeMatchFilters = (
  appointmentType: BusinessAppointmentType,
  filters: Record<FilterType, TableFilter>,
) => {
  const entries = Object.entries(filters) as [FilterType, TableFilter][]

  return entries.every(([filterType, filter]) => {
    if (isNil(filter?.humanReadable)) return true

    const valueStrings = getFilterValueStrings(filter)
    const value = String(appointmentType[filterableProperties[filterType]])
    return valueStrings.includes(value)
  })
}

export const getMapAsArray = (map: Record<string, BusinessAppointmentType>) =>
  Object.entries(map).map(([, appointmentType]) => appointmentType)

export const applyFilters = (
  items: BusinessAppointmentType[],
  filters: Record<FilterType, TableFilter>,
) => items.filter((cur) => doesAppointmentTypeMatchFilters(cur, filters))

export const applySortings = (
  items: BusinessAppointmentType[],
  sortings: Record<SortingsType, boolean>,
  eventTypeNames: Map<string, string>,
) =>
  items.sort((a, b) => {
    if (a.enabled === b.enabled) {
      if (sortings.orderOnlyByNameAscending === true) {
        return a.name.localeCompare(b.name)
      }

      if (sortings.orderOnlyByNameAscending === false) {
        return b.name.localeCompare(a.name)
      }

      // default sort should be type -> name A-Z
      const eventTypeNameA = eventTypeNames.get(a.eventTypeId) ?? ''
      const eventTypeNameB = eventTypeNames.get(b.eventTypeId) ?? ''
      return eventTypeNameA !== eventTypeNameB
        ? eventTypeNameA.localeCompare(eventTypeNameB)
        : a.name.localeCompare(b.name)
    }
    return a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1
  })
