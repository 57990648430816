import i18n from '~/locales/i18n'

export enum EnabledState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  NOT_APPLICANT = 'NOT_APPLICANT',
}

export const NotificationAreaNames = {
  COMMUNICATIONS: 'Communications',
  TASKS: 'Tasks',
  BULK_PRICE_UPDATES: 'Bulk price updates',
}

export const NotificationStyleNames = {
  MENU: 'Menu',
  LIST: 'List',
  POPUPS: 'Popups',
}

export const NotificationNotifyForNames = {
  ALL: 'All',
}

export const NotificationSyntheticAreas = {
  ALL: {
    id: 'NotificationHistoryArea/ALL',
    name: i18n.t('Common:ALL'),
  },
}

export enum NotificationTypeName {
  UNREAD_COMMUNICATIONS = 'Unread communications',
  COMMUNICATIONS_ASSIGNED = 'Communications assigned',
  NEW_TASKS = 'New tasks',
  TASKS_ASSIGNED = 'Tasks assigned',
  PRICE_BULK_UPDATE_COMPLETED = 'Price bulk update is completed',
}
