import { getAPIPath, request } from './core'
import * as Schema from './schemas'
import { tokenHolder } from './utils/token'

export const fetchCurrentUser = (businessId: string) =>
  request(getAPIPath('v2/persons/me'), { params: { businessId } }, true, {
    person: Schema.user,
    business: Schema.business,
    timeTrackingStatus: { currentEntry: Schema.timeEntity },
    groupBusinessList: Schema.businessesArray,
  })

export const logIn = (
  email: string,
  password: string,
  eulaAccepted?: boolean,
) =>
  request(
    getAPIPath('auth/login'),
    { method: 'POST', data: { email, password, eulaAccepted } },
    false,
  ).then((data) => {
    tokenHolder.setToken(data.tokenHolder.accessToken)
    return data
  })

export const refresh = (refreshToken: string) =>
  request(
    getAPIPath('auth/refresh'),
    { method: 'POST', data: { refreshToken } },
    true,
  ).then((data) => {
    tokenHolder.setToken(data.accessToken)
    return data
  })

export const updatePassword = (password: string) =>
  request(getAPIPath('persons/change_password'), {
    method: 'POST',
    data: password,
    headers: { 'Content-Type': 'text/plain' },
  })

export const requestResetPasswordEmail = (email: string) =>
  request(
    getAPIPath('auth/password/send'),
    { method: 'POST', data: email, headers: { 'Content-Type': 'text/plain' } },
    false,
  )

export const requestAuth0ResetPasswordEmail = (email: string) =>
  request(
    getAPIPath('auth/password/sendPasswordResetEmail'),
    {
      method: 'POST',
      data: { email },
      headers: { 'Content-Type': 'application/json' },
    },
    false,
  )

export const resetPassword = (token: string, password: string) =>
  request(
    getAPIPath('auth/password/reset'),
    { method: 'POST', data: { forgotPasswordToken: token, password } },
    false,
  )
