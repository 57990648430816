import { LanguageUtils, NamedEntity, Nil } from '@pbt/pbt-ui-components'

import {
  ProblemEnum,
  ProblemEnumValue,
  ProblemLogEnumValueContainer,
  ProblemLogProblemContainer,
  ProblemTreeNode,
} from '~/types'

export const populateProblemNodesVisibility = (
  problems: ProblemTreeNode[] | Nil,
): ProblemTreeNode[] =>
  (problems || []).map((problem) => {
    const preparedChildren = populateProblemNodesVisibility(problem.children)

    const hasVisibleChildProblems = preparedChildren.some(
      ({ removed, isHidden }) => !removed && !isHidden,
    )
    const isHidden = !problem.type && !hasVisibleChildProblems

    return {
      ...problem,
      isHidden,
      children: preparedChildren,
    }
  })

type GetEnumLogArgs = {
  parentEnumValueId: string | undefined
  problemEnum: ProblemEnum
  problemEnumValue: ProblemEnumValue
  problemLog: ProblemLogProblemContainer
}

export const findEnumLog = ({
  problemLog,
  problemEnum,
  problemEnumValue,
  parentEnumValueId,
}: GetEnumLogArgs): ProblemLogEnumValueContainer | undefined =>
  problemLog.entity.enumLogs.find(
    ({ entity }) =>
      entity.enumId === problemEnum.id &&
      entity.enumValueId === problemEnumValue.id &&
      (!parentEnumValueId || entity.parentEnumValueId === parentEnumValueId),
  )

const getEnumValueLabel = (
  enumItem?: NamedEntity,
  enumValue?: NamedEntity,
): string =>
  [
    LanguageUtils.getTranslatedFieldName(enumItem),
    LanguageUtils.getTranslatedFieldName(enumValue),
  ]
    .filter(Boolean)
    .join(': ')

export const getEnumValuePathLabel = (
  enumItem: NamedEntity,
  enumValue: NamedEntity,
  parentEnumItem?: NamedEntity,
  parentEnumValue?: NamedEntity,
): string =>
  [
    getEnumValueLabel(parentEnumItem, parentEnumValue),
    getEnumValueLabel(enumItem, enumValue),
  ]
    .filter(Boolean)
    .join(' | ')
