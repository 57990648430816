import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SentryUtils } from '@pbt/pbt-ui-components'

import { getCurrentBusiness, getCurrentUser } from '~/store/reducers/auth'
import { setGainsightUser } from '~/utils/gainsight'
import { resetLogRocketUser, setLogRocketUser } from '~/utils/LogRocket'
import useIsAuthenticated from '~/utils/useIsAuthenticated'

const MonitoringWidget = () => {
  const user = useSelector(getCurrentUser)
  const business = useSelector(getCurrentBusiness)

  const { isAuthenticated } = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      setLogRocketUser(user)
      SentryUtils.setContext(business, user)
      setGainsightUser(business, user)
    } else {
      resetLogRocketUser()
    }
  }, [user, isAuthenticated, business])

  return null
}

export default MonitoringWidget
