import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Hidden, Input, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AmountObj,
  CurrencyTextField,
  FieldsState,
  LanguageUtils,
  Nil,
  PuiSelect,
  PuiTextField,
  PuiTooltip,
  SpeciesConstant,
  Text,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import ActiveStateSwitch from '~/components/common/ActiveStateSwitch'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import TaxRateSelect from '~/components/common/inputs/TaxRateSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import {
  getHighValue,
  getLowValue,
  setHighValue,
  setLowValue,
} from '~/components/dashboard/invoices/invoiceUtils'
import {
  getProcedurePriceUnits,
  getTimeUnits,
} from '~/store/reducers/constants'
import { BasePrice, DataHandleWithUnsavedChanges, UnsavedPrice } from '~/types'
import { isFieldValuesChanged } from '~/utils'
import { getSingularOrPluralTimeUnits } from '~/utils/time'

const useStyles = makeStyles(
  (theme) => ({
    mobileActiveSwitchContainer: {
      borderBottom: theme.constants.tabBorder,
    },
    infoIcon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginTop: theme.spacing(3.5),
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'Price' },
)

export interface PriceProps {
  availableSpecies: SpeciesConstant[]
  className?: string
  price: BasePrice | Nil
}

export interface PriceHandle
  extends DataHandleWithUnsavedChanges<UnsavedPrice> {}

const Price = forwardRef<PriceHandle, PriceProps>(function Price(
  { availableSpecies, price, className },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const ProcedurePriceUnits = useSelector(getProcedurePriceUnits)
  const TimeUnits = useSelector(getTimeUnits)

  const Each = Utils.findConstantByName('Each', ProcedurePriceUnits) || {}

  const PerXRangeUnits = getSingularOrPluralTimeUnits(TimeUnits).map(
    (unit) => ({
      id: `fake${unit.id}`,
      name: `Per ${unit.name.toLowerCase()}`,
      nameTranslation: `${t(
        'Common:PER',
      )} ${LanguageUtils.getTranslatedFieldName(unit).toLowerCase()}`,
      originalId: unit.id,
      originalName: unit.name,
    }),
  )
  const TimeRange = {
    id: 'fakeTimeRangeId',
    name: t('Admin:CATALOG.PROCEDURES_TABLE_COMPONENT.PER_TIME_RANGE'),
  }

  const TimeBasedId = Utils.findConstantIdByName(
    'Time-based',
    ProcedurePriceUnits,
  )

  const CustomPriceUnits = [Each, ...PerXRangeUnits, TimeRange]

  const isCreate = !price

  const timeBasedFieldValidator = ({
    state,
    value,
  }: {
    state: FieldsState
    value: number | null
  }) => {
    const isTimeBased = PerXRangeUnits.some((unit) => unit.id === state.units)
    return !isTimeBased || Validators.notEmptyFormField(value)
  }

  const timeRangeFieldValidator = ({
    state,
    value,
  }: {
    state: FieldsState
    value: AmountObj | null
  }) => {
    const isTimeRange = TimeRange.id === state.units
    return !isTimeRange || Validators.notEmptyFormField(value)
  }

  const taxesValidator = ({
    state,
    value,
  }: {
    state: FieldsState
    value: string[]
  }) => !state.taxable || value.length > 0

  const isOriginalPriceTimeBased = !isCreate && price.unitId === TimeBasedId
  const isOriginalPricePerTimeRange =
    !isCreate && (price.minTimeUnitSize || price.maxTimeUnitSize)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        label: t('Common:PRICE_NAME'),
        validators: ['required'],
        initialValue: isCreate ? '' : price.name,
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: isCreate ? '' : price.speciesId,
      },
      {
        name: 'priceValue',
        label: t('Common:PRICE'),
        validators: ['required'],
        initialValue: isCreate ? '' : price.price,
      },
      {
        name: 'priceMinCharge',
        label: t('Common:MINIMUM_CHARGE'),
        initialValue:
          price?.minCharge === 0 ? price.minCharge : price?.minCharge || '',
      },
      {
        name: 'units',
        label: t('Common:UNITS'),
        validators: ['required'],
        type: 'select',
        initialValue: isCreate
          ? Each.id
          : isOriginalPricePerTimeRange
            ? TimeRange.id
            : isOriginalPriceTimeBased
              ? PerXRangeUnits.find(
                  (unit) => unit.originalId === price.timeUnitId,
                )?.id
              : price.unitId,
      },
      {
        name: 'discountAllowed',
        label: t('Common:ALLOW_DISCOUNT'),
        type: 'toggle',
        initialValue: isCreate ? true : price.discountAllowed,
      },
      {
        name: 'taxable',
        label: t('Common:TAXABLE'),
        type: 'toggle',
        initialValue: isCreate ? false : price.taxable,
      },
      {
        name: 'taxes',
        label: t('Common:SELECT_TAX_RATE'),
        type: 'select',
        validators: [
          { validator: taxesValidator, validatorName: 'taxesValidator' },
        ],
        messages: {
          taxesValidator: t('Validations:PLEASE_SELECT_TAX_RATE'),
        },
        initialValue: isCreate ? [] : price.taxes || [],
      },
      {
        name: 'active',
        type: 'toggle',
        initialValue: isCreate ? true : price.active,
      },
      {
        name: 'timeUnitSize',
        label: t('Common:PER'),
        validators: [
          { validator: timeBasedFieldValidator, validatorName: 'required' },
        ],
        initialValue: isCreate ? 1 : price.timeUnitSize,
      },
      {
        name: 'rangeTimeUnitSize',
        label: t('Common:RANGE_TIME_UNIT'),
        validators: [
          { validator: timeRangeFieldValidator, validatorName: 'required' },
        ],
        initialValue: isCreate
          ? { low: 1, high: 1 }
          : {
              low: price.minTimeUnitSize || 1,
              high: price.maxTimeUnitSize || 1,
            },
      },
      {
        name: 'timeUnits',
        label: t('Common:UNIT_OF_TIME'),
        validators: [
          { validator: timeRangeFieldValidator, validatorName: 'required' },
        ],
        type: 'select',
        initialValue: isCreate ? '' : price.timeUnitId,
      },
      {
        name: 'originalCode',
        label: t('Common:CODE'),
        initialValue: isCreate ? '' : price.originalCode,
      },
    ],
    false,
  )

  const {
    name,
    species,
    priceValue,
    priceMinCharge,
    units,
    discountAllowed,
    taxable,
    taxes,
    active,
    timeUnitSize,
    rangeTimeUnitSize,
    timeUnits,
    originalCode,
  } = fields

  useEffect(() => {
    reset()
  }, [price, ProcedurePriceUnits, TimeUnits])

  const isTimeBased = PerXRangeUnits.some((unit) => unit.id === units.value)
  const isPerTimeRange = units.value === TimeRange.id

  const getPrice = () => {
    const newPrice: UnsavedPrice = {
      name: name.value,
      speciesId: species.value,
      price: Number(priceValue.value),
      minCharge: Number(priceMinCharge.value),
      discountAllowed: discountAllowed.value,
      taxable: taxable.value,
      active: active.value,
      unitId: isTimeBased || isPerTimeRange ? TimeBasedId : units.value,
      timeUnitId: isTimeBased
        ? PerXRangeUnits.find((unit) => unit.id === units.value)?.originalId
        : isPerTimeRange
          ? timeUnits.value
          : null,
      timeUnitSize: isTimeBased ? timeUnitSize.value : null,
      minTimeUnitSize: isPerTimeRange ? rangeTimeUnitSize.value.low : null,
      maxTimeUnitSize: isPerTimeRange ? rangeTimeUnitSize.value.high : null,
      taxes: taxes.value,
      originalCode: originalCode.value,
    }

    return newPrice
  }

  useImperativeHandle(ref, () => ({
    get: getPrice,
    validate,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  return (
    <>
      <Hidden mdUp>
        <Grid item className={classes.mobileActiveSwitchContainer} pl={2}>
          <ActiveStateSwitch field={active} />
        </Grid>
      </Hidden>
      <Grid container className={className} direction="column" wrap="nowrap">
        <Grid container item alignItems="flex-end" columnSpacing={4}>
          <Grid item md={6} xs={12}>
            <PuiTextField
              field={name}
              inputProps={{ maxLength: 100 }}
              label={`${name.label}*`}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel shrink htmlFor="species-select">
                {species.label}
              </InputLabel>
              <PuiSelect
                renderEmpty
                field={species}
                input={<Input id="species-select" />}
                items={availableSpecies}
                placeholder={
                  availableSpecies?.length > 1 ? t('Common:ALL') : ''
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="flex-end"
          columnSpacing={1.5}
          md={6}
          xs={12}
        >
          <Grid item xs>
            <CurrencyTextField
              field={priceValue}
              label={`${priceValue.label}*`}
            />
          </Grid>
          <Grid item mb={1}>
            <Text variant="body">/</Text>
          </Grid>
          <Grid item xs>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="units-select">{units.label}*</InputLabel>
              <PuiSelect
                field={units}
                input={<Input id="units-select" />}
                items={CustomPriceUnits}
                renderEmpty={false}
              />
            </FormControl>
          </Grid>
        </Grid>
        {isTimeBased && (
          <Grid
            container
            item
            alignItems="center"
            columnSpacing={0.5}
            md={6}
            mt={1.5}
            xs={12}
          >
            <Grid item>
              <Text variant="body">{timeUnitSize.label}</Text>
            </Grid>
            <Grid item>
              <QuantityInput field={timeUnitSize} max={999} min={1} />
            </Grid>
            <Grid item>
              <Text variant="body">{`${
                Utils.findById(units.value, PerXRangeUnits).originalName
              }`}</Text>
            </Grid>
          </Grid>
        )}
        {isPerTimeRange && (
          <Grid container item alignItems="flex-end" md={6} xs={12}>
            <Grid item>
              <QuantityInput
                showControls
                label={t('Common:LOW')}
                max={999}
                min={1}
                value={getLowValue(rangeTimeUnitSize.value)}
                onChange={(value) =>
                  rangeTimeUnitSize.setValue(
                    setLowValue(rangeTimeUnitSize.value, value),
                  )
                }
              />
            </Grid>
            <Grid item>
              <QuantityInput
                showControls
                label={t('Common:HIGH')}
                max={999}
                min={1}
                value={getHighValue(rangeTimeUnitSize.value)}
                onChange={(value) =>
                  rangeTimeUnitSize.setValue(
                    setHighValue(rangeTimeUnitSize.value, value),
                  )
                }
              />
            </Grid>
            <Grid item xs>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="unit-of-time-select">
                  {timeUnits.label}*
                </InputLabel>
                <PuiSelect
                  field={timeUnits}
                  input={<Input id="unit-of-time-select" />}
                  items={TimeUnits}
                  renderEmpty={false}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container item>
          <Grid item md={3} xs={12}>
            <CurrencyTextField
              field={priceMinCharge}
              label={priceMinCharge.label}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item md={3} wrap="nowrap" xs={12}>
            <PuiTextField
              field={originalCode}
              inputProps={{ maxLength: 15 }}
              label={originalCode.label}
            />
            <PuiTooltip
              tooltipText={t(
                'Tooltips:ALPHANUMERIC_IDENTIFIER_TO_SEARCH_FOR_ITEMS',
              )}
            >
              <InfoIcon className={classes.infoIcon} />
            </PuiTooltip>
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction="column"
          mt={{ xs: 2, md: 4 }}
          rowSpacing={1}
        >
          <Grid item>
            <PuiSwitch field={discountAllowed} label={discountAllowed.label} />
          </Grid>
          <Grid
            container
            item
            alignItems="flex-end"
            columnSpacing={1}
            mb={1}
            minHeight={60}
            wrap="nowrap"
          >
            <Grid item>
              <PuiSwitch field={taxable} label={taxable.label} />
            </Grid>
            {taxable.value && (
              <Grid item width={300}>
                <TaxRateSelect field={taxes} taxableField={taxable} />
              </Grid>
            )}
          </Grid>
          <Hidden mdDown>
            <Grid item>
              <ActiveStateSwitch field={active} />
            </Grid>
          </Hidden>
        </Grid>
        <Grid item mt={3}>
          <RequiredFieldsNotice />
        </Grid>
      </Grid>
    </>
  )
})

export default Price
