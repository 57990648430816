import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import {
  LanguageUtils,
  StateLabel,
  StateLabelProps,
  Utils,
} from '@pbt/pbt-ui-components'

import { getBusinessStatus } from '~/store/reducers/constants'

const SKELETON_SHORT_SIZE = 20
const SKELETON_WIDE_SIZE = 50

export interface PracticeStatusLabelProps extends StateLabelProps {
  isLoading?: boolean
  short?: boolean
  statusId?: string
}

const PracticeStatusLabel = ({
  statusId,
  isLoading,
  short = false,
  ...rest
}: PracticeStatusLabelProps) => {
  const BusinessStatus = useSelector(getBusinessStatus)
  const businessStatus = Utils.findById(statusId, BusinessStatus)

  const businessStatusName = LanguageUtils.getTranslatedFieldName(
    businessStatus || {},
  )
  const businessStatusDisplayName = short
    ? businessStatusName?.charAt(0)
    : businessStatusName

  if (isLoading) {
    return short ? (
      <Skeleton
        height={SKELETON_SHORT_SIZE}
        variant="rectangular"
        width={SKELETON_SHORT_SIZE}
      />
    ) : (
      <Skeleton
        height={SKELETON_SHORT_SIZE}
        variant="rectangular"
        width={SKELETON_WIDE_SIZE}
      />
    )
  }

  return (
    <StateLabel
      disabled={businessStatus?.color === 'disabled'}
      success={businessStatus?.color === 'success'}
      warning={businessStatus?.color === 'important'}
      {...rest}
    >
      {businessStatusDisplayName}
    </StateLabel>
  )
}

export default PracticeStatusLabel
