import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil } from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'
import { useFutureAppointmentsAndMembership } from '~/utils/useFutureAppointmentsAndMembership'

const useStyles = makeStyles(
  () => ({
    alertStart: {
      alignItems: 'start',
    },
  }),
  { name: 'CancelFutureAppointmentsAndMembershipAlert' },
)

interface CancelFutureAppointmentsAndMembershipAlertProps {
  active: boolean | Nil
  alertColorId: string | Nil
  clientId: string
  deceased: boolean
  patientId: string
  patientName: string
}

export const CancelFutureAppointmentsAndMembershipAlert = ({
  alertColorId,
  clientId,
  patientId,
  patientName,
  deceased,
  active,
}: CancelFutureAppointmentsAndMembershipAlertProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Clients')

  const { hasFutureAppointments, hasMembership } =
    useFutureAppointmentsAndMembership({
      patientId,
      clientId,
      includePausedMemberships: true,
    })

  const [openCancelFutureAppointmentsAndMembershipDialog] = useDialog(
    DialogNames.CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP,
  )

  const getMessageKey = () => {
    if (hasFutureAppointments && hasMembership) {
      return t(
        'Clients:PATIENT_SECTION.ALERT.HAS_UPCOMING_APPOINTMENTS_AND_AN_ACTIVE_MEMBERSHIP',
      )
    }
    if (hasFutureAppointments) {
      return t('Clients:PATIENT_SECTION.ALERT.HAS_UPCOMING_APPOINTMENTS')
    }
    return t('Clients:PATIENT_SECTION.ALERT.HAS_AN_ACTIVE_MEMBERSHIP')
  }

  const shouldRenderAlert =
    (deceased && (hasMembership || hasFutureAppointments)) ||
    (active === false && (hasMembership || hasFutureAppointments))

  if (!shouldRenderAlert) {
    return null
  }

  return (
    <AlertLabel
      showIcon
      alertColorId={alertColorId}
      alertColorLevel={AlertColorLevel.PATIENT}
      clamp={Number.MAX_SAFE_INTEGER}
      classes={{
        root: classes.alertStart,
      }}
      message={
        <Trans
          components={{
            linkButton: (
              <Link
                color="inherit"
                component="button"
                underline="always"
                onClick={() => {
                  openCancelFutureAppointmentsAndMembershipDialog({
                    clientId,
                    patientId,
                    patientName,
                  })
                }}
              />
            ),
          }}
          i18nKey={getMessageKey()}
        />
      }
      py={0.5}
    />
  )
}
