import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, CheckboxProps, Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ClassesType,
  LanguageUtils,
  NumberUtils,
  StateLabel,
  Text,
  TextInteractive,
  TextProps,
  TextWithTooltip,
  ValidateHandle,
  VerticalDivider,
} from '@pbt/pbt-ui-components'
import {
  AddNote as AddNoteIcon,
  Edit as EditIcon,
  RX as RXIcon,
} from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { partialEditOrder } from '~/store/actions/orders'
import { useIsIntegratedModality } from '~/store/hooks/imagingOrders'
import {
  useIsDrug,
  useIsFood,
  useIsGlobalInventoryItem,
  useIsVetDiet,
} from '~/store/hooks/orders'
import { useGetPrescriptionStateType } from '~/store/hooks/prescription'
import { getCurrentBusinessIsIdexxImagingEnabled } from '~/store/reducers/auth'
import {
  getOrderIsCreating,
  getOrdersError,
  getOrdersIsSendingOrReceiving,
  getSelectedOrders,
} from '~/store/reducers/orders'
import { getSoapId } from '~/store/reducers/soap'
import { Order, OrderCallbackWithTypeCheck } from '~/types'
import {
  CheckboxColor,
  dropOrderPrepaidBox,
  findOrderItemByItem,
  getIsRabiesVaccine,
  getIsRefill,
  isImagingProcedure,
  isProcedure,
} from '~/utils/orderUtils'
import { getPrescriptionType } from '~/utils/prescription'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import OrderMembershipIcon from './OrderMembershipIcon'
import ReadonlyOrderTooltip from './ReadonlyOrderTooltip'

const useStyles = makeStyles(
  (theme) => ({
    listItemInnerContainer: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.soapListItemHover,
      },
    },
    zebraContainer: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
        '&:hover': {
          backgroundColor: theme.colors.soapListItemHover,
        },
      },
    },
    listItemName: {},
    checkbox: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      color: theme.colors.secondaryText,
      margin: theme.spacing(0, 1),
    },
    iconButton: {
      padding: 0,
    },
    editIcon: {
      color: theme.colors.editIconColor,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    classes: {
      color: theme.colors.lowAccentText,
    },
    divider: {
      margin: theme.spacing(0, 0.5),
    },
    itemContainer: {},
  }),
  { name: 'OrderListItem' },
)

type DetailsHandle = ValidateHandle & { updateNotes: (notes: string) => void }

export interface OrderListItemProps {
  DetailsComponent?: React.JSXElementConstructor<any>
  ItemTooltipComponent?: React.JSXElementConstructor<any>
  ListItemProps?: {
    onRefillSelect?: (prescription: Order) => void
    showFoodListItemActions?: boolean
    showListItemActions?: boolean
  }
  PriceTextProps?: TextProps
  classes?: ClassesType<typeof useStyles>
  clientId: string
  getItemColor?: (order: Order) => CheckboxProps['color']
  includePrice?: boolean
  isCheckedItem: (order: Order) => boolean
  isDisabled?: boolean
  onCheckItem: OrderCallbackWithTypeCheck
  onCreated?: OrderCallbackWithTypeCheck
  onEditItem?: OrderCallbackWithTypeCheck
  onUncheckItem: OrderCallbackWithTypeCheck
  onUpdateItem?: (order: Order) => void
  order: Order
  patientId: string
  searchTerm?: string
  showLabelState?: boolean
  showTooltip?: boolean
  useChargesWidgetView?: boolean
  useZebraItemsStyling?: boolean
}

const OrderListItem = forwardRef(function OrderListItem(
  {
    classes: classesProp,
    DetailsComponent,
    ListItemProps = {},
    PriceTextProps,
    showTooltip = false,
    order,
    searchTerm,
    isCheckedItem,
    isDisabled: isDisabledProp,
    ItemTooltipComponent,
    onCheckItem,
    onCreated: onCreatedProp,
    onUncheckItem,
    onUpdateItem,
    onEditItem,
    clientId,
    patientId,
    getItemColor,
    showLabelState = false,
    includePrice = false,
    useZebraItemsStyling = false,
    useChargesWidgetView = false,
  }: OrderListItemProps,
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()

  const [openNotesDialog] = useDialog(DialogNames.NOTES)
  const [openVaccineDetailsDialog] = useDialog(DialogNames.VACCINE_DETAILS)
  const [openImagingOrderDialog] = useDialog(DialogNames.IMAGING_ORDER)

  const [dialogNotes, setDialogNotes] = useState(order?.notes)

  useEffect(() => {
    setDialogNotes(order?.notes)
  }, [order])

  const isGlobalItem = useIsGlobalInventoryItem(order)
  const isDrug = useIsDrug(order)
  const isVetDiet = useIsVetDiet(order)
  const isFood = useIsFood(order)
  const isImagingProcedureOrder = isImagingProcedure(order)
  const isRefill = getIsRefill(order)
  const isProcedureOrder = isProcedure(order)
  const isVaccinations = order?.procedure?.category === 'Vaccinations'

  const soapId = useSelector(getSoapId)
  const selectedOrders = useSelector(getSelectedOrders)
  const error = useSelector(getOrdersError)
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )

  const isIntegratedModality = useIsIntegratedModality(
    order?.procedure?.modalityId,
  )

  const detailsRef = useRef<DetailsHandle>()

  useImperativeHandle(ref, () => ({
    validate: () =>
      detailsRef.current?.validate ? detailsRef.current.validate() : true,
  }))

  const { price, prepaidBox } = order
  const orderName = LanguageUtils.getTranslatedFieldName(order)

  const isPrescribable =
    isGlobalItem || (isFood && order.inventory?.requiresPrescription)
  const isCheckBoxChecked = isCheckedItem(order)
  const color =
    typeof getItemColor === 'function'
      ? getItemColor(order)
      : CheckboxColor.PRIMARY
  const isIntermediateState = color !== CheckboxColor.PRIMARY

  const remainsHint =
    prepaidBox &&
    `(${t('Common:QUANTITY_REMAINS', { quantity: prepaidBox.remaining })})`
  const displayName = [orderName, remainsHint].filter(Boolean).join(' ')

  const {
    showListItemActions = false,
    showFoodListItemActions = false,
    onRefillSelect,
  } = ListItemProps

  // Submitted chewy active RX selected items should be disabled
  const currentOrder = findOrderItemByItem(order, selectedOrders)
  const { isChewyActiveRx } = getPrescriptionType(
    currentOrder?.prescriptionType,
    currentOrder?.origin,
  )
  const { isSubmitted } = useGetPrescriptionStateType()(currentOrder?.stateId)
  const isDisabled =
    isDisabledProp || (isCheckedItem(order) && isChewyActiveRx && isSubmitted)

  const onCreated = (checkedOrder: Order) => {
    onCreatedProp?.(checkedOrder, { isPrescribable, isDrug, isFood })

    const isRabiesVaccine = getIsRabiesVaccine(order)

    if (showListItemActions && isRabiesVaccine && !error) {
      openVaccineDetailsDialog({
        clientId,
        order: checkedOrder,
        patientId,
      })
    }
  }

  const setProceedOnOrderCreated = useCloseAfterCreation(
    onCreated,
    getOrderIsCreating,
  )

  const handleSelectionChange = () => {
    if (isCheckBoxChecked && !isIntermediateState) {
      onUncheckItem(order, { isPrescribable, isDrug, isVetDiet, isFood })
    } else {
      const checkedOrder = isIntermediateState
        ? dropOrderPrepaidBox(order)
        : order

      setProceedOnOrderCreated(checkedOrder)
      onCheckItem(checkedOrder, { isPrescribable, isDrug, isVetDiet, isFood })
    }
  }

  const handleEditItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (onEditItem) {
      onEditItem(order, { isPrescribable: true, isDrug, isVetDiet, isFood })
    }
  }

  const handleAddNotes = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    openNotesDialog({
      name: order.name,
      notes: dialogNotes,
      onUpdateNotes: (notes: string) => {
        if (onUpdateItem) {
          if (isProcedureOrder) {
            dispatch(
              partialEditOrder({
                id: order.id,
                type: order.type,
                notes,
              }),
            )
          } else {
            // we omit quantity from order bcs it will be taken from fullOrder before updating on OrdersPage
            onUpdateItem({
              ...R.omit(['quantity'], order),
              notes,
            })
          }
        }

        if (detailsRef.current?.updateNotes) {
          detailsRef.current.updateNotes(notes)
        }
        setDialogNotes(notes)
      },
      isLoadingSelector: getOrdersIsSendingOrReceiving,
    })
  }

  const handleEditVaccine = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    openVaccineDetailsDialog({
      clientId,
      order,
      patientId,
    })
  }

  const handleEditImaging = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const orderItem = findOrderItemByItem(order, selectedOrders)
    openImagingOrderDialog({
      soapLog: orderItem,
      orderId: orderItem?.imagingOrderId,
      soapId,
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    if (isDisabled) {
      return
    }

    if (isRefill) {
      if (onRefillSelect) {
        onRefillSelect(order)
      }
    } else {
      handleSelectionChange() // In this case we change the status of checkbox
    }
  }

  const showRxButton =
    (showListItemActions || showFoodListItemActions) &&
    isCheckBoxChecked &&
    isFood &&
    !isIntermediateState &&
    onEditItem

  const showGlobalItemOrVaccineEditButton =
    showListItemActions &&
    isCheckBoxChecked &&
    ((isProcedureOrder && isVaccinations && !isIntermediateState) ||
      (isGlobalItem && soapId))

  const showImagingEditButton =
    showListItemActions &&
    isCheckBoxChecked &&
    isImagingIntegrated &&
    isIntegratedModality &&
    isImagingProcedureOrder

  const showAddNoteButton =
    showListItemActions &&
    isCheckBoxChecked &&
    !showImagingEditButton &&
    !isPrescribable &&
    !isIntermediateState &&
    !useChargesWidgetView

  const Icons = [
    showRxButton && (
      <IconButton
        className={classes.iconButton}
        disabled={isDisabled}
        size="large"
        onClick={handleEditItem}
      >
        <RXIcon />
      </IconButton>
    ),
    showAddNoteButton && (
      <IconButton
        className={classes.iconButton}
        disabled={isDisabled}
        size="large"
        onClick={handleAddNotes}
      >
        <AddNoteIcon filled={Boolean(dialogNotes)} />
      </IconButton>
    ),
    showGlobalItemOrVaccineEditButton && (
      <IconButton
        className={classes.iconButton}
        disabled={isDisabled}
        size="large"
        onClick={isPrescribable ? handleEditItem : handleEditVaccine}
      >
        <EditIcon className={classes.editIcon} />
      </IconButton>
    ),
    showImagingEditButton && (
      <IconButton
        className={classes.iconButton}
        disabled={isDisabled}
        size="large"
        onClick={handleEditImaging}
      >
        <EditIcon className={classes.editIcon} />
      </IconButton>
    ),
  ].filter(Boolean)

  const tooltipText = showTooltip ? (
    price ? (
      ItemTooltipComponent && <ItemTooltipComponent order={order} />
    ) : (
      <ReadonlyOrderTooltip order={order} />
    )
  ) : undefined

  return (
    <Grid
      container
      item
      className={classNames(classes.listItemInnerContainer, {
        [classes.zebraContainer]: useZebraItemsStyling,
      })}
      direction="column"
      wrap="nowrap"
    >
      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        px={0.75}
        py={0.5}
        wrap="nowrap"
        onClick={handleClick}
      >
        <Grid
          container
          item
          alignItems="center"
          className={classes.itemContainer}
          wrap="nowrap"
          xs={9}
        >
          <Grid item alignSelf="start">
            <Checkbox
              checked={isCheckBoxChecked}
              className={classes.checkbox}
              color={color}
              disabled={isDisabled}
              id={`order-list-item-checkbox-${order.type}_${order.name}_${order.id}_${order.logId}`}
            />
          </Grid>
          <OrderMembershipIcon className={classes.icon} order={order} />
          <TextWithTooltip
            allowWrap
            noIcon
            className={classes.listItemName}
            highlight={searchTerm}
            tooltipDelay={500}
            tooltipText={tooltipText}
            variant="body2"
          >
            {displayName}
          </TextWithTooltip>
          {showLabelState && isCheckBoxChecked && (
            <Grid item px={1}>
              <StateLabel success variant="small">
                {t('Common:ORDERED')}
              </StateLabel>
            </Grid>
          )}
          {price?.originalCode && (
            <Grid item px={1}>
              <Dotdotdot clamp={1}>
                <TextInteractive
                  strong
                  highlight={searchTerm}
                  variant="lowAccent2"
                >
                  {price.originalCode}
                </TextInteractive>
              </Dotdotdot>
            </Grid>
          )}
          {Icons.map((Icon, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {Icon}
              {index < Icons.length - 1 ? (
                <VerticalDivider classes={{ divider: classes.divider }} />
              ) : (
                <>&nbsp;</>
              )}
            </React.Fragment>
          ))}
        </Grid>
        {includePrice && !Number.isNaN(order.price?.price) && (
          <Text
            align="right"
            pr={2}
            variant="lowAccent2"
            width={128}
            {...PriceTextProps}
          >
            {NumberUtils.formatMoney(order.price?.price)}
          </Text>
        )}
      </Grid>
      {showListItemActions && isCheckBoxChecked && DetailsComponent && (
        <Grid item pl={6} pr={2}>
          <DetailsComponent
            clientId={clientId}
            isDisabled={isDisabled}
            order={order}
            patientId={patientId}
            ref={detailsRef}
            onUpdateItem={onUpdateItem}
          />
        </Grid>
      )}
    </Grid>
  )
})

export default OrderListItem
