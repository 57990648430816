import { all, call, put, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  EDIT_MARKETPLACE_ITEM,
  editMarketplaceItem,
  editMarketplaceItemFailure,
  editMarketplaceItemSuccess,
  FETCH_MARKETPLACE_IFRAMES,
  FETCH_MARKETPLACE_ITEMS,
  FETCH_MORE_MARKETPLACE_ITEMS,
  fetchMarketplaceIFramesFailure,
  fetchMarketplaceIFramesSuccess,
  fetchMarketplaceItems,
  fetchMarketplaceItemsFailure,
  fetchMarketplaceItemsSuccess,
  fetchMoreMarketplaceItems,
  fetchMoreMarketplaceItemsFailure,
  fetchMoreMarketplaceItemsSuccess,
} from '../duck/marketplace'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchMarketplaceItemsSaga({
  from,
  to,
  active,
  query,
}: ReturnType<typeof fetchMarketplaceItems>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchMarketplaceItems, from, to, active, query)
    yield call(updateEntities, entities)
    yield put(fetchMarketplaceItemsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchMarketplaceItemsFailure(error as ApiError))
  }
}

export function* fetchMoreMarketplaceItemsSaga({
  from,
  to,
  active,
  query,
}: ReturnType<typeof fetchMoreMarketplaceItems>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchMarketplaceItems, from, to, active, query)
    yield call(updateEntities, entities)
    yield put(fetchMoreMarketplaceItemsSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreMarketplaceItemsFailure(error as ApiError))
  }
}

export function* editMarketplaceItemSaga({
  id,
  active,
  rhapsodyApiKey,
  jsonParameters,
}: ReturnType<typeof editMarketplaceItem>) {
  try {
    yield* requestAPI(
      API.editMarketplaceItem,
      id,
      active,
      rhapsodyApiKey,
      jsonParameters,
    )
    yield put(
      editMarketplaceItemSuccess(id, active, rhapsodyApiKey, jsonParameters),
    )
  } catch (error) {
    yield put(editMarketplaceItemFailure(error as ApiError))
  }
}

export function* fetchMarketplaceIFramesSaga() {
  try {
    const {
      result: list,
      entities: { marketplaceIFrames = {} },
    } = yield* requestAPI(API.fetchMarketplaceIFrames)

    const workflowIFrameMap = list.reduce(
      (map: Record<string, string[]>, id: string) => {
        const iFrame = marketplaceIFrames[id]

        iFrame.workflowIds.forEach((workflowId: string) => {
          if (!map[workflowId]) {
            map[workflowId] = [id]
          } else {
            map[workflowId].push(id)
          }
        })

        return map
      },
      {},
    )

    yield put(
      fetchMarketplaceIFramesSuccess(marketplaceIFrames, workflowIFrameMap),
    )
  } catch (error) {
    yield put(fetchMarketplaceIFramesFailure(error as ApiError))
  }
}

function* watchFetchMarketplaceItems() {
  yield takeLeading(FETCH_MARKETPLACE_ITEMS, fetchMarketplaceItemsSaga)
}

function* watchFetchMoreMarketplaceItems() {
  yield takeLeading(FETCH_MORE_MARKETPLACE_ITEMS, fetchMoreMarketplaceItemsSaga)
}

function* watchEditMarketplaceItem() {
  yield takeLeading(EDIT_MARKETPLACE_ITEM, editMarketplaceItemSaga)
}

function* watchFetchMarketplaceIFrames() {
  yield takeLeading(FETCH_MARKETPLACE_IFRAMES, fetchMarketplaceIFramesSaga)
}

export default function* marketplaceSaga() {
  yield all([
    watchFetchMarketplaceItems(),
    watchFetchMoreMarketplaceItems(),
    watchEditMarketplaceItem(),
    watchFetchMarketplaceIFrames(),
  ])
}
