import React from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'
import { Grid, Link, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog, Text } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import {
  getClientSurveyLink,
  getError,
  getErrorCode,
  getIsFetchingClientSurveyLink,
} from '~/store/reducers/soapV2'

const useStyles = makeStyles(
  () => ({
    icon: {
      width: 50,
      height: 50,
      margin: 'auto',
    },
    link: {
      margin: 'auto',
      height: 200,
      width: 200,
    },
  }),
  { name: 'ClientSurvey' },
)

export interface ClientSurveyDialogProps extends BasePuiDialogProps {}

const ClientSurveyDialog = ({ open, onClose }: ClientSurveyDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Soap'])
  const isGeneratingQRCode = useSelector(getIsFetchingClientSurveyLink)
  const error = useSelector(getError)
  const errorCode = useSelector(getErrorCode)
  const surveyLink = useSelector(getClientSurveyLink)
  let content

  const ContentMap = {
    ERROR: {
      item: <WarnAlert className={classes.icon} />,
      info: errorCode === 409 ? error : t('Soap:CLIENT_SURVEY_ERROR'),
    },
    LOADING: {
      item: <Skeleton height={200} variant="rounded" width={200} />,
      info: t('Soap:CLIENT_SURVEY_GENERATING'),
    },
    SUCCESS: {
      item: (
        <Link
          className={classes.link}
          href={surveyLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <QRCode size={200} value={surveyLink || ''} />
        </Link>
      ),
      info: t('Soap:CLIENT_SURVEY_INSTRUCTIONS'),
    },
  }

  if (isGeneratingQRCode) {
    content = ContentMap.LOADING
  } else if (error) {
    content = ContentMap.ERROR
  } else {
    content = ContentMap.SUCCESS
  }

  return (
    <PuiDialog open={open} onClose={onClose}>
      <Grid container direction="column" mt={2}>
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          p={4}
          width={500}
        >
          <Text variant="hero2">{t('Soap:CLIENT_SURVEY_WIDGET_TITLE')}</Text>
          <Grid container item mt={2} width={200}>
            {content.item}
          </Grid>
          <Text align="center" mb={1} mt={2} sx={{ width: '80%' }}>
            {content.info}
          </Text>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default ClientSurveyDialog
