import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError, DateUtils } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { getUrlTimetableDate } from '~/components/dashboard/timetable/timetableUtils'
import { WhiteboardTreatmentsFilters } from '~/types'

import { setTimetableFilters } from '../actions/timetable'
import {
  FETCH_WHITEBOARD,
  FETCH_WHITEBOARD_TREATMENTS,
  SET_WHITEBOARD_TREATMENTS_FILTERS,
} from '../actions/types/whiteboard'
import {
  fetchWhiteboard,
  fetchWhiteboardFailure,
  fetchWhiteboardSuccess,
  fetchWhiteboardTreatmentsFailure,
  fetchWhiteboardTreatmentsIgnored,
  fetchWhiteboardTreatmentsSuccess,
} from '../actions/whiteboard'
import { finishLoading, startLoading } from '../duck/progress'
import { getWhiteboardTreatmentsFilters } from '../reducers/whiteboard'
import { cancelableByAppointment } from './timetable'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchWhiteboardCancelable({ silent }: { silent?: boolean }) {
  try {
    const selectedDate = getUrlTimetableDate()

    if (!selectedDate) {
      return
    }

    yield put(startLoading('whiteboard'))
    const { result, entities } = yield* requestAPI(
      API.fetchWhiteboard,
      DateUtils.serializeDate(selectedDate),
    )
    yield call(updateEntities, entities)
    yield put(fetchWhiteboardSuccess(result))
    if (!silent) {
      yield put(setTimetableFilters(true))
    }
  } catch (error) {
    yield put(fetchWhiteboardFailure(error as ApiError))
  } finally {
    yield put(finishLoading('whiteboard'))
  }
}

export function* fetchWhiteboardSaga(
  params: ReturnType<typeof fetchWhiteboard>,
) {
  yield call(cancelableByAppointment, fetchWhiteboardCancelable, params)
}

export function* fetchWhiteboardTreatmentsSaga({
  silent,
}: {
  silent?: boolean
  type: string
}) {
  try {
    const filters: WhiteboardTreatmentsFilters = yield select(
      getWhiteboardTreatmentsFilters,
    )
    const assignedTo = filters.assignedTo?.value
    const appointmentType = filters.appointmentType?.value

    const selectedDate = getUrlTimetableDate()

    if (!selectedDate) {
      yield put(fetchWhiteboardTreatmentsIgnored())
      return
    }

    yield put(startLoading('whiteboard'))
    const { result, entities } = yield* requestAPI(
      API.fetchWhiteboardTreatments,
      DateUtils.serializeDate(selectedDate),
      assignedTo as string,
      appointmentType as string,
    )
    yield call(updateEntities, entities)
    yield put(fetchWhiteboardTreatmentsSuccess(result))
    if (!silent) {
      yield put(setTimetableFilters(true))
    }
  } catch (error) {
    yield put(fetchWhiteboardTreatmentsFailure(error as ApiError))
  } finally {
    yield put(finishLoading('whiteboard'))
  }
}

function* watchFetchWhiteboard() {
  yield takeLatest(FETCH_WHITEBOARD, fetchWhiteboardSaga)
}

function* watchFetchWhiteboardTreatments() {
  yield takeLatest(
    [FETCH_WHITEBOARD_TREATMENTS, SET_WHITEBOARD_TREATMENTS_FILTERS],
    fetchWhiteboardTreatmentsSaga,
  )
}

export default function* whiteboardSaga() {
  yield all([watchFetchWhiteboard(), watchFetchWhiteboardTreatments()])
}
