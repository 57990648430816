import * as R from 'ramda'
import { AnyAction } from 'redux'
import { moment } from '@pbt/pbt-ui-components'

import { SlotType } from '~/constants/schedulerConstants'
import {
  TimetableEvent,
  WhiteboardSchedule,
  WhiteboardScheduleData,
  WhiteboardSlot,
  WhiteboardTreatmentsFilters,
  WhiteboardTreatmentsSchedule,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import {
  DELETE_TASK_SUCCESS,
  DELETE_TASKS_SUCCESS,
} from '../actions/types/tasks'
import {
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_BUSY_TIME_SUCCESS,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_APPOINTMENT_STATUS_FAILURE,
} from '../actions/types/timetable'
import {
  FETCH_WHITEBOARD,
  FETCH_WHITEBOARD_FAILURE,
  FETCH_WHITEBOARD_SUCCESS,
  FETCH_WHITEBOARD_TREATMENTS,
  FETCH_WHITEBOARD_TREATMENTS_FAILURE,
  FETCH_WHITEBOARD_TREATMENTS_SUCCESS,
  SET_WHITEBOARD_TREATMENTS_FILTERS,
} from '../actions/types/whiteboard'
import type { RootState } from '../index'

export type WhiteboardState = {
  appointments: string[]
  columns: string[]
  error: string | null
  isLoading: boolean
  schedules: WhiteboardScheduleData['schedules']
  treatmentsFilters: WhiteboardTreatmentsFilters
  treatmentsSchedules: WhiteboardTreatmentsSchedule[]
}

export const INITIAL_STATE: WhiteboardState = {
  isLoading: false,
  schedules: {},
  columns: [],
  appointments: [],
  treatmentsSchedules: [],
  treatmentsFilters: {},
  error: null,
}

const updateSchedules = (
  schedules: WhiteboardScheduleData['schedules'],
  appointment: TimetableEvent,
  oldStatus: string,
  newStatus: string,
) =>
  Object.keys(schedules).reduce(
    (acc, key) => ({
      ...acc,
      [key]: schedules[key].map((schedule: WhiteboardSchedule) => {
        const oldStatusSlots = schedule.slots[oldStatus]
        const newStatusSlots = schedule.slots[newStatus]
        const matchingSlot = oldStatusSlots.find(
          (slot) =>
            slot.appointment === appointment.id ||
            slot.busyTime === appointment.id,
        )

        if (!matchingSlot) {
          return schedule
        }

        const momentStart = moment(appointment.scheduledStartDatetime)
        const index = newStatusSlots.findIndex((slot) =>
          moment(slot.interval.from).isAfter(momentStart),
        )

        return {
          ...schedule,
          slots: {
            ...schedule.slots,
            [oldStatus]: R.without([matchingSlot], oldStatusSlots),
            [newStatus]: R.insert(index, matchingSlot, newStatusSlots),
          },
        }
      }),
    }),
    {},
  )

const removeEventFromSchedules = (
  eventType: SlotType,
  prop: keyof WhiteboardSlot,
  eventId: string,
  schedules: WhiteboardScheduleData['schedules'],
) =>
  Object.keys(schedules).reduce(
    (acc, key) => ({
      ...acc,
      [key]: schedules[key].map((schedule) => ({
        ...schedule,
        slots: Object.keys(schedule.slots).reduce(
          (slotAcc, slotKey) => ({
            ...slotAcc,
            [slotKey]: schedule.slots[slotKey].filter(
              (slot) => slot.type !== eventType || slot[prop] !== eventId,
            ),
          }),
          {},
        ),
      })),
    }),
    {},
  )

const whiteboard = (
  state: WhiteboardState = INITIAL_STATE,
  action: AnyAction,
): WhiteboardState => {
  switch (action.type) {
    case FETCH_WHITEBOARD_FAILURE:
    case FETCH_WHITEBOARD_TREATMENTS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_WHITEBOARD:
    case FETCH_WHITEBOARD_TREATMENTS:
      return { ...state, isLoading: action.silent ? state.isLoading : true }
    case FETCH_WHITEBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        columns: action.columns,
        schedules: action.schedules,
        appointments: action.appointments,
      }
    case FETCH_WHITEBOARD_TREATMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        treatmentsSchedules: action.schedules,
      }
    case UPDATE_APPOINTMENT_STATUS:
      const { oldStatus, newStatus, appointment } = action
      return {
        ...state,
        schedules:
          oldStatus && newStatus
            ? updateSchedules(
                state.schedules,
                appointment,
                oldStatus,
                newStatus,
              )
            : state.schedules,
      }
    case UPDATE_APPOINTMENT_STATUS_FAILURE:
      return {
        ...state,
        schedules:
          action.oldStatus && action.newStatus
            ? updateSchedules(
                state.schedules,
                action.appointment,
                action.newStatus,
                action.oldStatus,
              )
            : state.schedules,
      }
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        schedules: removeEventFromSchedules(
          SlotType.APPOINTMENT,
          'appointment',
          action.appointmentId,
          state.schedules,
        ),
      }
    case DELETE_BUSY_TIME_SUCCESS:
      return {
        ...state,
        schedules: removeEventFromSchedules(
          SlotType.BUSY,
          'busyTime',
          action.busyTimeId,
          state.schedules,
        ),
      }
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        treatmentsSchedules: state.treatmentsSchedules
          .map((schedule) => ({
            ...schedule,
            tasks: R.without([action.taskId], schedule.tasks),
          }))
          .filter((schedule) => schedule.tasks.length > 0),
      }
    case DELETE_TASKS_SUCCESS:
      return {
        ...state,
        treatmentsSchedules: state.treatmentsSchedules
          .map((schedule) => ({
            ...schedule,
            tasks: R.without(action.taskIds, schedule.tasks),
          }))
          .filter((schedule) => schedule.tasks.length > 0),
      }
    case SET_WHITEBOARD_TREATMENTS_FILTERS:
      return { ...state, treatmentsFilters: action.filters }
    default:
      return state
  }
}

export default whiteboard
export const getWhiteboard = (state: RootState): WhiteboardState =>
  state.whiteboard
export const getWhiteboardSchedules = (state: RootState) =>
  getWhiteboard(state).schedules
export const getWhiteboardTeamSchedules = (state: RootState) =>
  getWhiteboard(state).schedules.team || []
export const getWhiteboardIsLoading = (state: RootState) =>
  getWhiteboard(state).isLoading
export const getWhiteboardTreatmentsSchedules = (state: RootState) =>
  getWhiteboard(state).treatmentsSchedules
export const getWhiteboardTreatmentsFilters = (state: RootState) =>
  getWhiteboard(state).treatmentsFilters
export const getWhiteboardColumns = (state: RootState) =>
  getWhiteboard(state).columns
