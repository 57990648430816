import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { getSoapId } from '~/store/reducers/soap'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import ToDoDatePicker from '../../soap/to-do/ToDoDatePicker'
import ToDoTasksList from '../../soap/to-do/ToDoTasksList'
import SoapWidget from '../SoapWidget'
import { useIsSoapCustomizationEnabled } from '../utils/useIsSoapCustomizationEnabled'

const useStyles = makeStyles(
  (theme) => ({
    list: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'ToDoWidget' },
)

const ToDoWidget = () => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const taskPermissions = useSelector(getCRUDByArea(PermissionArea.TASK))
  const orderIsSending = useSelector(getOrdersIsSending)
  const soapId = useSelector(getSoapId)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  useWSTopic({
    wsTopic: WSTopics.TO_DO,
    context: {
      retrySubscription: true,
      topicParams: {
        soapId,
      },
    },
    unsubscribe: true,
    forceBreak: !isSoapCustomizationEnabled,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_WIDGET_GROUPED_ITEM,
    context: {
      retrySubscription: true,
      topicParams: {
        soapId,
      },
    },
    unsubscribe: true,
    forceBreak: !isSoapCustomizationEnabled,
  })

  return (
    <Box id="todo-widget">
      <ToDoDatePicker />
      <SoapWidget title={t('Common:TO_DO')}>
        <ToDoTasksList
          className={classes.list}
          editDisabled={!taskPermissions.update || orderIsSending}
        />
      </SoapWidget>
    </Box>
  )
}

export default ToDoWidget
