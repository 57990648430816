import React from 'react'
import { Badge, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&&:hover': {
        backgroundColor: 'transparent',
      },
      textTransform: 'none',
      fontSize: '1.2rem',
      color: theme.colors.sideText,
      padding: '4px 13px',
      borderRadius: 20,
      border: theme.constants.filterBorder,
      textIndent: theme.spacing(1),
    },
    selectedRoot: {
      '&&:hover': {
        backgroundColor: theme.colors.title,
      },
      borderColor: 'transparent',
      backgroundColor: theme.colors.title,
      color: theme.colors.badgeColor,
    },
    importantRoot: {
      '&&:hover': {
        backgroundColor: 'inherit',
      },
      padding: '6px 13px',
      border: `1px solid ${theme.colors.important}`,
      color: theme.colors.important,
      textIndent: 0,
    },
    mainRoot: {
      '&&:hover': {
        backgroundColor: 'inherit',
      },
      padding: '6px 13px',
      border: `1px solid ${theme.colors.filterBorderColor}`,
      color: theme.colors.menuSubheader,
      textIndent: 0,
      opacity: 0.5,
    },
    importantSelectedRoot: {
      '&&:hover': {
        backgroundColor: theme.colors.important,
      },
      backgroundColor: theme.colors.important,
      color: theme.colors.badgeColor,
    },
    mainSelectedRoot: {
      '&&:hover': {
        backgroundColor: theme.colors.menuSubheader,
      },
      backgroundColor: theme.colors.menuSubheader,
      color: theme.colors.badgeColor,
      opacity: 1,
    },
    importantBadge: {
      color: theme.colors.badgeColor,
      backgroundColor: theme.colors.important,
    },
    badge: {
      zIndex: 'auto',
      top: 5,
      right: 5,
    },
  }),
  { name: 'TimelineFilter' },
)

export interface TimelineFilterProps {
  icon?: React.JSXElementConstructor<any>
  important?: boolean
  label?: string
  main?: boolean
  onSelect: () => void
  selected: boolean
}

const TimelineFilter = ({
  selected,
  label,
  icon: IconComponent,
  important = false,
  main = false,
  onSelect,
}: TimelineFilterProps) => {
  const classes = useStyles()

  return (
    <Badge
      classes={{
        badge: classNames(classes.badge, {
          [classes.importantBadge]: important,
        }),
      }}
    >
      <Button
        classes={{
          root: classNames(classes.root, {
            [classes.importantRoot]: important,
            [classes.selectedRoot]: selected,
            [classes.importantSelectedRoot]: important && selected,
            [classes.mainRoot]: main,
            [classes.mainSelectedRoot]: main && selected,
          }),
        }}
        onClick={onSelect}
      >
        {IconComponent && <IconComponent />}
        {label}
      </Button>
    </Badge>
  )
}

export default TimelineFilter
