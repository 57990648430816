import { AnyAction } from 'redux'
import { UnitsState } from '@pbt/pbt-ui-components'

import { LocalizedTemperature, LocalizedWeight } from '~/constants/units'

import type { RootState } from '../index'

export const CHANGE_UNITS = 'settings/CHANGE_UNITS'

export const changeUnits = (units: Partial<UnitsState>) => ({
  type: CHANGE_UNITS,
  units,
})

export type SettingsState = {
  units: UnitsState
}

export const INITIAL_STATE: SettingsState = {
  units: {
    weight: LocalizedWeight,
    temperature: LocalizedTemperature,
  },
}

export const settingsReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
): SettingsState => {
  switch (action.type) {
    case CHANGE_UNITS:
      return { ...state, units: { ...state.units, ...action.units } }
    default:
      return state
  }
}

const getSettings = (state: RootState): SettingsState => state.settings
export const getUnitsState = (state: RootState) => getSettings(state).units
