import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const useRedirectTo = (
  path: string,
  redirectTo: string,
  condition: boolean,
) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (condition) {
      navigate(`${path}?redirectTo=${encodeURIComponent(redirectTo)}`)
    }
  }, [condition])
}
