import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Defaults,
  Nil,
  PuiTextField,
  PuiTextFieldProps,
  PuiTooltip,
  User,
} from '@pbt/pbt-ui-components'

export interface ClientEmailTextFieldProps
  extends Omit<PuiTextFieldProps, 'disabled' | 'inputProps'> {
  client: User | Nil
  disabled?: boolean
  label?: React.ReactNode
}

const ClientEmailTextField = ({
  client,
  disabled,
  label,
  ...rest
}: ClientEmailTextFieldProps) => {
  const { t } = useTranslation(['Common', 'Tooltips'])
  const emailUpdateDisabled = client?.isBoopUser && Boolean(client.email)

  return (
    <PuiTooltip
      disabled={!emailUpdateDisabled}
      tooltipText={t('Tooltips:CLIENT_EMAIL', {
        supportEmail: Defaults.SUPPORT_EMAIL,
      })}
    >
      <PuiTextField
        disabled={disabled || emailUpdateDisabled}
        inputProps={{ maxLength: 100 }}
        label={label || t('Common:EMAIL')}
        {...rest}
      />
    </PuiTooltip>
  )
}

export default ClientEmailTextField
