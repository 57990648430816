import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { DateUtils, LinkButton, Text, Utils } from '@pbt/pbt-ui-components'

import { getUser } from '~/store/reducers/users'
import { Prescription } from '~/types'

import PrescriptionDetails from './PrescriptionDetails'

export interface ParentPrescriptionSectionProps {
  className?: string
  disabled?: boolean
  onEdit: () => void
  prescription: Prescription
  showEditButton?: boolean
  simpleInstructions?: boolean
}

const ParentPrescriptionSection = ({
  className,
  prescription,
  showEditButton,
  disabled = false,
  simpleInstructions = false,
  onEdit,
}: ParentPrescriptionSectionProps) => {
  const { t } = useTranslation('Dialogs')

  const { creationDate } = prescription

  const prescriptionDate = DateUtils.formatDate(creationDate)
  const storeDoctor = useSelector(getUser(prescription.doctorId))
  const doctor = prescription.doctor || storeDoctor

  return (
    <Grid
      container
      alignItems="flex-start"
      className={className}
      direction="column"
    >
      <Grid item>
        <Text strong mt={2} variant="body2">
          {t('Dialogs:PRESCRIPTION_DIALOG.ORIGINAL_PRESCRIPTION_DATE', {
            date: prescriptionDate,
          })}
        </Text>
        <Text variant="body2">
          {t('Dialogs:PRESCRIPTION_DIALOG.PRESCRIBED_BY_DOCTOR', {
            doctor: Utils.getPersonString(doctor),
          })}
        </Text>
      </Grid>
      <PrescriptionDetails
        showDispensed
        showRefills
        prescription={prescription}
        simpleInstructions={simpleInstructions}
      />
      {showEditButton && (
        <LinkButton disabled={disabled} onClick={onEdit}>
          {t('Dialogs:PRESCRIPTION_DIALOG.CHANGE_INSTRUCTIONS_FOR_REFILL')}
        </LinkButton>
      )}
    </Grid>
  )
}

export default ParentPrescriptionSection
