import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { sanitize } from 'dompurify'
import { HtmlNotesPreview, LanguageUtils, Text } from '@pbt/pbt-ui-components'

import {
  DiagnosisTimelineItem,
  ProblemDiagnosisLog,
  ProblemLogEnumReference,
} from '~/types'

import ProblemsDiagnosesTableDiagnosisEnums from './ProblemsDiagnosesTableDiagnosisEnums'

interface ProblemsDiagnosesTableDiagnosisProps {
  fromDiagnosisCard?: boolean
  log: ProblemDiagnosisLog | DiagnosisTimelineItem
}

const ProblemsDiagnosesTableDiagnosis = ({
  log,
  fromDiagnosisCard = false,
}: ProblemsDiagnosesTableDiagnosisProps) => {
  const { t } = useTranslation('Common')
  const diagnosisName = LanguageUtils.getTranslatedFieldName({
    name: log?.description,
    nameTranslation: log?.descriptionTranslation || '',
  })

  const parentEnums = log.enums!.filter(
    (enumRef: ProblemLogEnumReference) => !enumRef.parentEnumValueId,
  )
  const childrenEnums = log.enums!.filter(
    (enumRef: ProblemLogEnumReference) => enumRef.parentEnumValueId,
  )
  return (
    <Grid container item direction="column" display="flex">
      {Boolean(!fromDiagnosisCard) && <Text strong>{diagnosisName}</Text>}
      {Boolean(log.notes) && (
        <HtmlNotesPreview
          fontSize="1.4rem"
          notes={sanitize(`${t('Common:NOTES')}: ${log.notes}`)}
        />
      )}
      {Boolean(parentEnums.length) &&
        parentEnums.map((parentEnum: ProblemLogEnumReference) => (
          <ProblemsDiagnosesTableDiagnosisEnums
            childrenEnums={childrenEnums.filter(
              (enumRef: ProblemLogEnumReference) =>
                enumRef.parentEnumValueId === parentEnum.enumValueId,
            )}
            key={parentEnum.enumId + parentEnum.enumValueId + parentEnum.id}
            parentEnum={parentEnum}
          />
        ))}
    </Grid>
  )
}

export default ProblemsDiagnosesTableDiagnosis
