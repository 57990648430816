import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import {
  AddButton,
  AlertIconType,
  ErrorTooltip,
  PermissionArea,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import Expander from '~/components/common/lists/Expander'
import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import {
  deleteReminderProtocolGroup,
  fetchReminderProtocolGroup,
  getReminderProtocolGroup,
  getReminderProtocolsIsFetching,
  getReminderProtocolsIsLoading,
  updateReminderProtocolGroup,
} from '~/store/duck/reminderProtocols'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  Order,
  ReminderProtocol,
  ReminderProtocolGroup,
  UnsavedReminderProtocol,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import ProtocolsLabel from './ProtocolsLabel'
import ReminderProtocolTable from './ReminderProtocolTable'
import { hasEmptyTrigger } from './reminderProtocolUtils'

export interface ReminderProtocolGroupDetailsProps {
  itemId: string
  onClose: () => void
}

const ReminderProtocolGroupDetails = ({
  itemId: groupId,
  onClose,
}: ReminderProtocolGroupDetailsProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const isLoading = useSelector(getReminderProtocolsIsLoading)
  const isFetching = useSelector(getReminderProtocolsIsFetching)
  const group = useSelector(getReminderProtocolGroup(groupId))
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.REMINDER_PROTOCOLS),
  )

  const [groupCandidate, setGroupCandidate] = useState(group)
  const [touched, setTouched] = useState(false)

  const closeOnDelete = useCloseAfterCreation(
    onClose,
    getReminderProtocolsIsLoading,
  )
  const [openReminderProtocolDialog] = useDialog(DialogNames.REMINDER_PROTOCOL)
  const [openAddReminderProtocolItems] = useDialog(
    DialogNames.ADD_REMINDER_PROTOCOL_ITEMS,
  )

  const [openEmptyTriggersAlert, closeEmptyTriggersAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const {
    fields: { name, active },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:REMINDER_GROUP_NAME'),
        validators: ['required'],
        initialValue: group?.name,
      },
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue: group?.active,
      },
    ],
    false,
  )

  useEffect(() => {
    if (groupId) {
      dispatch(fetchReminderProtocolGroup(groupId))
    }
  }, [groupId])

  useEffect(() => {
    setGroupCandidate(group)
    reset()
  }, [group])

  const getUpdatedGroup = () => ({
    ...(groupCandidate as ReminderProtocolGroup),
    name: name.value,
    active: active.value,
  })

  const handleSaveGroup = (force?: boolean) => {
    if (
      validate() &&
      groupCandidate?.protocols &&
      groupCandidate.protocols?.length > 0
    ) {
      if (
        !force &&
        groupCandidate?.protocols.some((protocol) => hasEmptyTrigger(protocol))
      ) {
        openEmptyTriggersAlert({
          iconType: AlertIconType.WARN,
          message: t(
            'Admin:CATALOG.REMINDER_PROTOCOL_GROUP_DETAILS.EMPTY_TRIGGERS_ALERT_MESSAGE',
            {
              whatIsDue: `"${t('Common:WHAT_IS_DUE_OR_EXPECTED')}""`,
              whatResolvesIt: `"${t('Common:WHAT_RESOLVES_IT')}"`,
            },
          ),
          onOk: () => {
            handleSaveGroup(true)
            closeEmptyTriggersAlert()
          },
          onCancel: () => closeEmptyTriggersAlert(),
        })
      } else {
        dispatch(updateReminderProtocolGroup(getUpdatedGroup()))
      }
    }
    setTouched(true)
  }

  const handleDeleteGroup = () => {
    closeOnDelete()
    dispatch(deleteReminderProtocolGroup(groupId))
  }

  const updateProtocol = (newProtocol: ReminderProtocol, index: number) => {
    const newProtocols = R.update(
      index,
      newProtocol,
      groupCandidate?.protocols || [],
    )
    setGroupCandidate({
      ...(groupCandidate as ReminderProtocolGroup),
      protocols: newProtocols,
    })
  }

  const addProtocol = (newProtocol: ReminderProtocol, index = 0) => {
    const newProtocols = R.insert(
      index,
      newProtocol,
      groupCandidate?.protocols || [],
    )
    setGroupCandidate({
      ...(groupCandidate as ReminderProtocolGroup),
      protocols: newProtocols,
    })
  }

  const addOrUpdateProtocol = (
    protocol: ReminderProtocol | UnsavedReminderProtocol,
    index: number,
    onSave: (protocol: ReminderProtocol, index: number) => void,
  ) => {
    openReminderProtocolDialog({
      groupName: name.value,
      protocol,
      onSave: (newProtocol: ReminderProtocol) => onSave(newProtocol, index),
    })
  }

  const handleUpdateProtocol = (protocol: ReminderProtocol, index: number) => {
    addOrUpdateProtocol(protocol, index, updateProtocol)
  }

  const handleCloneProtocol = (protocol: ReminderProtocol, index: number) => {
    addOrUpdateProtocol(R.omit(['id'], protocol), index, addProtocol)
  }

  const handleDeleteProtocol = (
    protocolToDelete: ReminderProtocol,
    index: number,
  ) => {
    const newProtocols = R.remove(index, 1, groupCandidate?.protocols || [])

    setGroupCandidate({
      ...(groupCandidate as ReminderProtocolGroup),
      protocols: newProtocols,
    })
  }

  const handleAddProtocol = () => {
    openAddReminderProtocolItems({
      title: t('Common:CHOOSE_WHAT_TRIGGERS_REMINDER'),
      onSave: (triggersArg: Partial<Order>[]) => {
        openReminderProtocolDialog({
          groupName: name.value,
          triggers: triggersArg as ReminderProtocol['triggersWith'],
          onSave: (newProtocol: ReminderProtocol) => {
            setGroupCandidate({
              ...(groupCandidate as ReminderProtocolGroup),
              protocols: [
                ...(groupCandidate?.protocols || []),
                { ...newProtocol, groupId },
              ],
            })
          },
        })
      },
    })
  }

  const showErrorMessage =
    touched && !groupCandidate?.protocols?.length && name.valid
  const groupHasNoChanges = deepEqual(getUpdatedGroup(), group)
  const hasUnsavedData = Boolean(group) && !groupHasNoChanges

  return (
    <Expander
      expandedItemClass={t('Common:REMINDER_PROTOCOL').toLowerCase()}
      hasUnsavedData={hasUnsavedData}
      isFetching={isFetching}
      isSaving={isLoading}
      showButtons={permissions.update}
      onBack={onClose}
      onDeleteRequested={handleDeleteGroup}
      onSaveRequested={() => handleSaveGroup()}
    >
      <Grid container item>
        <Grid item md={8} sm={12}>
          <PuiTextField
            disabled={!permissions.update}
            field={name}
            label={`${name.label}*`}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Grid item mt={2}>
          <ItemHistoryLabel item={groupCandidate} />
        </Grid>
        <Grid item mt={2}>
          <PuiSwitch
            disabled={!permissions.update}
            field={active}
            label={active.label}
          />
        </Grid>
        <Grid item my={2}>
          <ProtocolsLabel />
        </Grid>
        {groupCandidate?.protocols && groupCandidate.protocols?.length > 0 && (
          <ReminderProtocolTable
            protocols={groupCandidate?.protocols}
            onClone={handleCloneProtocol}
            onDelete={handleDeleteProtocol}
            onEdit={handleUpdateProtocol}
          />
        )}
        <ErrorTooltip
          message={t('Tooltips:AT_LEAST_ONE_REMINDER_SHOULD_BE_ADDED')}
          open={showErrorMessage}
        >
          <Grid item mt={1}>
            <AddButton
              inline
              addText={t('Common:ADD_PROTOCOL')}
              disabled={!permissions.update}
              onAdd={handleAddProtocol}
            />
          </Grid>
        </ErrorTooltip>
      </Grid>
    </Expander>
  )
}

export default ReminderProtocolGroupDetails
