import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { findClients } from '~/store/actions/clients'
import { getDuplicateClients } from '~/store/reducers/clients'
import useDialog from '~/utils/useDialog'

import ClientLink from './ClientLink'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 450,
      maxWidth: 650,
      padding: theme.spacing(4, 5, 1, 2),
    },
    actions: {
      justifyContent: 'center',
    },
    progress: {
      color: theme.colors.disabledLabelText,
      margin: theme.spacing(2),
    },
  }),
  { name: 'ClientDuplicatesDialog' },
)

interface ClientDuplicatesDialogProps extends BasePuiDialogProps {
  email: string | Nil
  expandPatients?: boolean
  onClientSelect?: (clientId: string) => void
  onCreate: () => void
  onPatientSelect: (clientId: string, patientId: string) => void
  phone: string | Nil
}

const ClientDuplicatesDialog = ({
  open,
  onClose,
  email,
  phone,
  onClientSelect,
  onPatientSelect,
  expandPatients,
  onCreate,
}: ClientDuplicatesDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clients = useSelector(getDuplicateClients)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [selectedClient, setSelectedClient] = useState<string>()
  const [selectedPatient, setSelectedPatient] = useState<string>()

  const [openPatientsListDialog] = useDialog(DialogNames.PATIENTS_LIST)

  useEffect(() => {
    dispatch(findClients(email, phone))
  }, [email, phone])

  const onCreateClick = () => {
    onCreate()
    if (onClose) {
      onClose()
    }
  }

  const onClientClick = (clientId: string, patientsIds: string[]) => {
    if (expandPatients && patientsIds?.length > 0) {
      setSelectedClient(clientId)
      openPatientsListDialog({
        patientsIds,
        onPatientClick: (patientId: string | undefined) =>
          setSelectedPatient(patientId),
        hideSearch: true,
      })
    } else {
      if (onClientSelect) {
        onClientSelect(clientId)
      }
      if (onClose) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (selectedClient && selectedPatient) {
      onPatientSelect(selectedClient, selectedPatient)
      if (onClose) {
        onClose()
      }
    }
  }, [selectedClient, selectedPatient])

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader color="secondary" onClick={onCreateClick}>
          {t('Dialogs:CLIENT_DUPLICATES_DIALOG.CREATE_ANYWAY')}
        </ButtonWithLoader>
      }
      aria-labelledby="client-duplicates-dialog"
      classes={{
        paper: classes.paper,
        actions: classes.actions,
      }}
      open={open}
      scroll="paper"
      onClose={onClose}
    >
      <Grid container direction="column">
        <Text align="center" variant="body">
          {t('Dialogs:CLIENT_DUPLICATES_DIALOG.DESCRIPTION')}
        </Text>
        {clients.length > 0 ? (
          <>
            <Text align="center" variant="body">
              {t('Common:GO_TO_CLIENT')}:
            </Text>
            {clients.map((clientId) => (
              <ClientLink
                clientId={clientId}
                disableWithNoPatients={!onClientSelect}
                key={clientId}
                onClick={onClientClick}
              />
            ))}
          </>
        ) : (
          <Grid container justifyContent="center">
            <CircularProgress
              disableShrink
              className={classes.progress}
              size={32}
            />
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default ClientDuplicatesDialog
