import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import {
  Estimate as GraphqlEstimate,
  Payment,
} from '~/api/graphql/generated/types'
import { StaticTableColumn } from '~/components/common/lists/table/StaticTable'

import { ClientBillingActivityTable } from '../common/ClientBillingActivityTable'
import { PaymentTableColumnWidthWithoutCheckbox } from '../common/tableConstants'
import { useGetPaymentBillingActivityColumns } from './columns'

const useStyles = makeStyles(
  () => ({
    cell: {
      '&:nth-child(1)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.FIRST,
        width: PaymentTableColumnWidthWithoutCheckbox.FIRST,
      },
      '&:nth-child(2)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.SECOND,
        width: PaymentTableColumnWidthWithoutCheckbox.SECOND,
      },
      '&:nth-child(3)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.THIRD,
        width: PaymentTableColumnWidthWithoutCheckbox.THIRD,
      },
      '&:nth-child(4)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.FOURTH,
        width: PaymentTableColumnWidthWithoutCheckbox.FOURTH,
      },
      '&:nth-child(5)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.FIFTH,
        width: PaymentTableColumnWidthWithoutCheckbox.FIFTH,
      },
      '&:nth-child(6)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.SIXTH,
        width: PaymentTableColumnWidthWithoutCheckbox.SIXTH,
      },
      '&:nth-child(7)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.SEVENTH,
        width: PaymentTableColumnWidthWithoutCheckbox.SEVENTH,
      },
      '&:nth-child(8)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.EIGHTH,
        width: PaymentTableColumnWidthWithoutCheckbox.EIGHTH,
      },
      '&:nth-child(9)': {
        minWidth: PaymentTableColumnWidthWithoutCheckbox.NINTH,
        width: PaymentTableColumnWidthWithoutCheckbox.NINTH,
      },
    },
  }),
  { name: 'PaymentBillingActivityTable' },
)

interface PaymentBillingActivityTableProps {
  isLoading: boolean
  onEstimateNameClick: (data: GraphqlEstimate) => void
  onExpand: () => void
  payments: Payment[]
  paymentsPerPage: number
}

export const PaymentBillingActivityTable = ({
  payments: paymentsProp,
  paymentsPerPage,
  isLoading,
  onEstimateNameClick,
  onExpand,
}: PaymentBillingActivityTableProps) => {
  const classes = useStyles()

  const multipleRowsLimit = 2

  const columns = useGetPaymentBillingActivityColumns({
    key: 'prop',
    onClickEstimate: onEstimateNameClick,
    multipleRowsLimit,
  }) as StaticTableColumn<Payment>[]
  const [payments, setPayments] = useState<Payment[]>()

  useEffect(() => {
    if (paymentsProp) {
      const paymentsWithExpandableSection = paymentsProp.reduce(
        (paymentsArray: any[], payment: Payment) => {
          if (R.isNil(payment)) {
            return paymentsArray
          }
          if ((payment.links || []).length > multipleRowsLimit) {
            paymentsArray.push(payment)
            paymentsArray.push({
              id: `skip-${uuid()}`,
              links: payment.links,
              onExpand,
            })
          } else {
            paymentsArray.push(payment)
          }
          return paymentsArray
        },
        [],
      )
      setPayments(paymentsWithExpandableSection)
    }
  }, [paymentsProp])

  return (
    <ClientBillingActivityTable
      classes={classes}
      columns={columns}
      isLoading={isLoading}
      itemsPerPage={paymentsPerPage}
      rowItems={payments}
    />
  )
}
