import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddressUtils,
  LanguageUtils,
  PhoneUtils,
  Text,
  TextInteractive,
  User,
} from '@pbt/pbt-ui-components'

import { getContactMethod } from '~/store/reducers/constants'
import { shouldRenderAddress } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      paddingBottom: 10,
    },
    notesLabelContainer: {
      marginBottom: '0 !important',
    },
    petFriendNotesContainer: {
      paddingTop: 10,
      paddingRight: 10,
    },
    notesTextContainer: {
      marginLeft: 40,
    },
    additionalPetFriendTextContainer: {
      marginLeft: 90,
      marginTop: 35,
    },
    nameText: {
      color: theme.colors.primaryText,
      fontSize: '2rem',
      fontWeight: 500,
    },
    petFriendName: {
      borderBottom: 0,
    },
    fallbackText: {
      opacity: 0.5,
    },
  }),
  { name: 'PetFriendDetails' },
)

interface PetFriendDetailsProps {
  isEmergencyContact: boolean
  petFriend: Partial<User>
}

const PetFriendDetails = ({
  petFriend,
  isEmergencyContact,
}: PetFriendDetailsProps) => {
  const classes = useStyles()
  const ContactMethod = useSelector(getContactMethod)
  const { t } = useTranslation(['Common', 'Clients'])

  const NO_DATA_TEXT = t('Clients:PET_FRIEND_DETAILS.NO_DATA_TEXT')

  return (
    <Grid container className={classes.root} direction="column">
      {!isEmergencyContact && (
        <>
          <Grid container item className={classes.petFriendNotesContainer}>
            <Grid item className={classes.notesLabelContainer}>
              <Text strong>{t('Common:NOTES')}:</Text>
            </Grid>
            <Grid item xs className={classes.notesTextContainer}>
              <Dotdotdot clamp={3}>
                <TextInteractive align="left">
                  {petFriend.notes || (
                    <span className={classes.fallbackText}>{NO_DATA_TEXT}</span>
                  )}
                </TextInteractive>
              </Dotdotdot>
            </Grid>
          </Grid>
          <Grid item className={classes.additionalPetFriendTextContainer}>
            <Text strong align="left" variant="subheading2">
              {t('Common:EMAIL')}:
            </Text>
            <TextInteractive align="left">
              {petFriend.email || (
                <span className={classes.fallbackText}>{NO_DATA_TEXT}</span>
              )}
            </TextInteractive>
          </Grid>
        </>
      )}
      <Grid item className={classes.additionalPetFriendTextContainer}>
        <Text strong align="left" variant="subheading2">
          {t('Common:MOBILE_PHONE')}:
        </Text>
        <TextInteractive align="left">
          {PhoneUtils.formatPhoneNumber(petFriend.mobilePhone) || (
            <span className={classes.fallbackText}>{NO_DATA_TEXT}</span>
          )}
        </TextInteractive>
      </Grid>
      {!isEmergencyContact && (
        <>
          <Grid item className={classes.additionalPetFriendTextContainer}>
            <Text strong align="left" variant="subheading2">
              {t('Common:ADDRESS')}:
            </Text>
            <TextInteractive align="left">
              {shouldRenderAddress(petFriend) ? (
                AddressUtils.formatAddress(petFriend, true)
              ) : (
                <span className={classes.fallbackText}>{NO_DATA_TEXT}</span>
              )}
            </TextInteractive>
          </Grid>
          <Grid item className={classes.additionalPetFriendTextContainer}>
            <Text strong align="left" variant="subheading2">
              {t('Common:PREFERRED_CONTACT_METHOD')}:
            </Text>
            <TextInteractive align="left">
              {petFriend.preferredContactMethodId ? (
                LanguageUtils.getConstantTranslatedName(
                  petFriend.preferredContactMethodId,
                  ContactMethod,
                )
              ) : (
                <span className={classes.fallbackText}>{NO_DATA_TEXT}</span>
              )}
            </TextInteractive>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default PetFriendDetails
