import React, { useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps } from '@pbt/pbt-ui-components'

import { useCanPrintOrEmailOrDownloadInvoice } from '~/store/hooks/finance'

import EmailInvoice, { EmailInvoiceProps } from './EmailInvoice'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'EmailInvoiceDialog' },
)

export interface EmailInvoiceDialogProps
  extends Partial<EmailInvoiceProps>,
    PuiDialogProps {
  invoiceIds: string[]
}

const EmailInvoiceDialog = ({
  open,
  onClose,
  onOk,
  invoiceIds,
  params: paramsProp,
  ...rest
}: EmailInvoiceDialogProps) => {
  const classes = useStyles()

  const {
    getCanPrintOrEmailOrDownloadInvoice: canEmailInvoice,
    isCompletedType,
  } = useCanPrintOrEmailOrDownloadInvoice(invoiceIds, true)

  const params = {
    ...paramsProp,
    complete: isCompletedType,
  }

  const handleOk = () => {
    if (onOk) {
      onOk()
    }
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (!canEmailInvoice() && onClose) {
      onClose()
    }
  }, [])

  if (!canEmailInvoice()) {
    return null
  }

  return (
    <PuiDialog
      aria-labelledby="email-invoice-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <EmailInvoice
        invoiceIds={invoiceIds}
        params={params}
        onOk={handleOk}
        {...rest}
      />
    </PuiDialog>
  )
}

export default EmailInvoiceDialog
