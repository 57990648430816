import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { PermissionArea, PuiCheckbox, useFields } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { DataHandle } from '~/types'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      paddingTop: theme.spacing(1),
    },
  }),
  { name: 'DiscussedWithClientField' },
)

export interface DiscussedWithClientFieldProps {
  discussedWithClient: boolean | undefined
  onRefChange?: () => void
}

export interface DiscussedWithClientFieldHandle extends DataHandle {}

export const DiscussedWithClientField = forwardRef<
  DiscussedWithClientFieldHandle,
  DiscussedWithClientFieldProps
>(function DiscussesWithClientField(
  { discussedWithClient: discussedWithClientProp, onRefChange },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))

  const {
    fields: { discussedWithClient },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'discussedWithClient',
        label: t('Dialogs:PRESCRIPTION_DIALOG.QUANTITY_SECTION.EXTRA_LABEL'),
        type: 'toggle',
        initialValue: discussedWithClientProp || false,
      },
    ],
    false,
  )

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [discussedWithClient])

  useEffectExceptOnMount(() => {
    reset()
  }, [discussedWithClientProp])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => ({
      discussedWithClient: discussedWithClient.value || false,
    }),
  }))

  return (
    <PuiCheckbox
      classes={{ labelRoot: classes.container }}
      disabled={!permissions.update}
      field={discussedWithClient}
      label={discussedWithClient.label}
    />
  )
})
