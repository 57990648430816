import React from 'react'
import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getAppointmentTypes } from '~/store/reducers/constants'

import WellnessPlanBenefitCoverageRow, {
  WellnessPlanBenefitCoverageRowProps,
} from './WellnessPlanBenefitCoverageRow'

export interface WellnessPlanBenefitAppointmentTypeRowProps
  extends Omit<WellnessPlanBenefitCoverageRowProps, 'coverage'> {
  appointmentTypeId: string
}

const WellnessPlanBenefitAppointmentTypeRow = ({
  appointmentTypeId,
  ...rest
}: WellnessPlanBenefitAppointmentTypeRowProps) => {
  const AppointmentTypes = useSelector(getAppointmentTypes)

  const appointmentTypeName = LanguageUtils.getConstantTranslatedName(
    appointmentTypeId,
    AppointmentTypes,
  )

  return (
    <WellnessPlanBenefitCoverageRow
      coverage={{
        id: appointmentTypeId,
        name: appointmentTypeName,
      }}
      {...rest}
    />
  )
}

export default WellnessPlanBenefitAppointmentTypeRow
