import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  BreedConstant,
  Constant,
  Field,
  LanguageUtils,
  PuiAutocomplete,
  PuiAutocompleteProps,
} from '@pbt/pbt-ui-components'

import { getBreed, getSpecies } from '~/store/reducers/constants'
import { getRequiredLabel } from '~/utils'

export interface BreedAutocompleteProps
  extends Omit<PuiAutocompleteProps, 'options'> {
  field: Field
  hideLabel?: boolean
  required?: boolean
  species?: string
}

const BreedAutocomplete = ({
  field,
  species,
  required,
  hideLabel = false,
  ...rest
}: BreedAutocompleteProps) => {
  const Breed: BreedConstant = useSelector(getBreed)
  const Species: Constant[] = useSelector(getSpecies)
  const { t } = useTranslation('Common')

  const isNoBreeds = !species || !Breed[species] || Breed[species].length === 0
  const label = !isNoBreeds
    ? t('Common:BREED')
    : species
      ? t('Common:INPUTS.BREED_AUTO_COMPLETE.HAS_SPECIES', {
          specie: LanguageUtils.getConstantTranslatedName(species, Species),
        })
      : t('Common:INPUTS.BREED_AUTO_COMPLETE.NO_SPECIES')

  return (
    <PuiAutocomplete
      multiple
      translateOptions
      disabled={isNoBreeds}
      field={field}
      label={hideLabel ? undefined : getRequiredLabel(label, required)}
      minSearchLength={1}
      options={species ? Breed[species] : []}
      {...rest}
    />
  )
}

export default BreedAutocomplete
