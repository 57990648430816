import React, { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useScrollbarSize from 'react-scrollbar-size'
import { Box, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import RightRail from '~/components/common/RightRail'
import { RxRequestPrimaryFilters } from '~/constants/taskConstants'
import { fetchTask } from '~/store/actions/tasks'
import { updateTimelineFilters } from '~/store/actions/timeline'
import { getTask, getTasksIsFetching } from '~/store/reducers/tasks'

import DetailsBackButton from '../../clients/DetailsBackButton'
import TimelineComponent from '../../clients/timeline/TimelineComponent'
import TopMenu from '../../header/top-menu/TopMenu'
import RxRequestPrescription from './RxRequestPrescription'

interface StyleProps {
  scrollbarWidth: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  task: {
    height: `calc(100vh - ${
      (theme.mixins.toolbar.minHeight as number) +
      theme.constants.progressBarHeight
    }px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    top: theme.mixins.toolbar.minHeight,
    WebkitOverflowScrolling: 'touch',
    zIndex: theme.utils.modifyZIndex(theme.zIndex.rightRail, 'below'),
    position: 'fixed',
    [theme.breakpoints.down('md')]: {
      top: 0,
      paddingTop:
        (theme.mixins.toolbar as any)[theme.breakpoints.down('md')].minHeight +
        parseInt(theme.spacing(1), 10),
      height: '100%',
    },
  },
  rail: {
    paddingRight: theme.spacing(2),
  },
  halfScreenWidth: {
    width: ({ scrollbarWidth }: StyleProps) =>
      `calc(50vw - ${theme.constants.leftMenuWidth + scrollbarWidth}px / 2)`,
    [theme.breakpoints.down('md')]: {
      width: ({ scrollbarWidth }: StyleProps) =>
        `calc(50vw - ${scrollbarWidth}px / 2)`,
    },
  },
}))

export interface RxRequestTaskDetailsProps extends PropsWithChildren {
  itemId: string
  onClose: () => void
}

const RxRequestTaskDetails = ({
  itemId,
  onClose,
}: RxRequestTaskDetailsProps) => {
  const { t } = useTranslation('Tasks')

  const { width: scrollbarWidth } = useScrollbarSize()

  const classes = useStyles({ scrollbarWidth })

  const dispatch = useDispatch()

  const task = useSelector(getTask(itemId))
  const tasksIsFetching = useSelector(getTasksIsFetching)

  useEffect(() => {
    if (itemId) {
      dispatch(fetchTask(itemId))
    }
  }, [itemId])

  useEffect(
    () => () => {
      dispatch(updateTimelineFilters([]))
    },
    [],
  )

  const clientId = task?.client
  const patientId = task?.patient

  const showTimeline = clientId && patientId

  return (
    <>
      <Box
        className={
          showTimeline && classNames(classes.task, classes.halfScreenWidth)
        }
      >
        <TopMenu>
          <DetailsBackButton fullWidth thin onClick={onClose}>
            {t('Tasks:TASKS_LIST')}
          </DetailsBackButton>
        </TopMenu>
        <Box pt={1}>
          <RxRequestPrescription
            task={task}
            tasksIsFetching={tasksIsFetching}
            onClose={onClose}
          />
        </Box>
      </Box>
      {showTimeline && (
        <RightRail
          visible
          className={classNames(classes.rail, classes.halfScreenWidth)}
        >
          <TimelineComponent
            filtersAlwaysOnTop
            clientId={clientId}
            filterProps={{
              filtersSelectedByDefault: RxRequestPrimaryFilters,
              collapseConfig: {
                primaryFilters: RxRequestPrimaryFilters,
                isExpandedByDefault: false,
              },
            }}
            patientId={patientId}
            useWindowScroll={false}
          />
        </RightRail>
      )}
    </>
  )
}

export default RxRequestTaskDetails
