import { Defaults } from '@pbt/pbt-ui-components'

import { AvailabilityRule, UnsavedAvailabilityRule } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const createAvailabilityRule = (
  businessId: string,
  availabilityRule: UnsavedAvailabilityRule,
) =>
  request(
    getAPIPath('availability-rules'),
    { method: 'POST', data: availabilityRule, params: { businessId } },
    true,
    Schema.availabilityRule,
  )

export const updateAvailabilityRule = (
  businessId: string,
  availabilityRule: AvailabilityRule,
) =>
  request(
    getAPIPath(`availability-rules/${availabilityRule.id}`),
    { method: 'PUT', data: availabilityRule, params: { businessId } },
    true,
    Schema.availabilityRule,
  )

export const deleteAvailabilityRule = (
  businessId: string,
  ruleId: string,
  data: any,
) =>
  request(
    getAPIPath(`availability-rules/${ruleId}`),
    { method: 'DELETE', data, params: { businessId } },
    true,
  )

export const fetchAvailabilityRule = (
  businessId: string,
  availabilityRuleId: string,
) =>
  request(
    getAPIPath(`availability-rules/${availabilityRuleId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.availabilityRule,
  )

export const fetchAvailabilityRules = (
  businessId: string,
  from = 0,
  to = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath('availability-rules/group'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.availabilityRulesList,
  )
