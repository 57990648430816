import { useEffect, useState } from 'react'

const BrowserVisibilityPropByHiddenPropName = {
  hidden: 'visibilitychange',
  msHidden: 'msvisibilitychange',
  webkitHidden: 'webkitvisibilitychange',
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  }
  if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  }
  if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }

  return 'hidden'
}

function getBrowserVisibilityProp() {
  return BrowserVisibilityPropByHiddenPropName[getBrowserDocumentHiddenProp()]
}

function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()]
}

const useTabVisibility = () => {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    document.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  return isVisible
}

export default useTabVisibility
