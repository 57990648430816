import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import { ReminderProtocol } from '~/api/graphql/generated/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export type ReminderProtocolsState = {
  error: string | null
  isLoading: boolean
  // key is priceId + procedureId
  map: Record<string, ReminderProtocol[]>
}

export const INITIAL_STATE: ReminderProtocolsState = {
  map: {},
  isLoading: false,
  error: null,
}

const reminderProtocolsSlice = createSlice({
  name: 'reminderProtocolsV2',
  initialState: INITIAL_STATE,
  reducers: {
    fetchReminderProtocols: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ priceId: string; procedureId: string }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    fetchReminderProtocolsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        priceId: string
        procedureId: string
        protocols: ReminderProtocol[]
      }>,
    ) => {
      state.isLoading = false
      state.map[payload.priceId + payload.procedureId] = payload.protocols
    },
    fetchReminderProtocolsFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(payload.error)
    },
  },
})

const { actions, reducer } = reminderProtocolsSlice

export const {
  fetchReminderProtocols,
  fetchReminderProtocolsFailure,
  fetchReminderProtocolsSuccess,
} = actions

export default reducer
export const getReminderProtocolsState = (
  state: RootState,
): ReminderProtocolsState => state.reminderProtocolsV2
export const getReminderProtocolsIsLoading = (state: RootState) =>
  getReminderProtocolsState(state).isLoading
export const getReminderProtocolsMap = (state: RootState) =>
  getReminderProtocolsState(state).map
export const getReminderProtocols = (priceId: string, procedureId: string) =>
  createSelector(
    getReminderProtocolsMap,
    (map) => map[priceId + procedureId] ?? [],
  )
