import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Question } from '@pbt/pbt-ui-components'

import { hasSelectedAnswers } from '~/components/dashboard/soap/utils/questionUtils'

import { saveQuestionsForSoap } from '../actions/questions'
import {
  getSoapQuestionsBySoapId,
  getSoapQuestionsWithWorkFlow,
} from '../reducers/questions'
import { getSoapId } from '../reducers/soap'

export const useGetSoapQuestionsCount = () => {
  const soapQuestionsWithWorkflow = useSelector(getSoapQuestionsWithWorkFlow)

  const allSoapQuestions = Object.values(soapQuestionsWithWorkflow).flat()
  const totalQuestions = allSoapQuestions.length
  const totalAnsweredQuestions = R.pipe(
    R.pluck('answers'),
    R.flatten,
    R.pluck('selected'),
    R.filter(Boolean),
    R.length,
  )(allSoapQuestions)

  const getTotalQuestions = useCallback(
    (workflowId: string) =>
      (soapQuestionsWithWorkflow[workflowId] || []).length,
    [totalQuestions],
  )

  const getAnsweredQuestions = useCallback(
    (workflowId: string) =>
      (soapQuestionsWithWorkflow[workflowId] || []).filter(({ answers }) =>
        hasSelectedAnswers(answers),
      ).length,
    [totalAnsweredQuestions],
  )

  return {
    soapQuestionsWithWorkflow,
    getTotalQuestions,
    getAnsweredQuestions,
  }
}

export const useSoapQuestions = () => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const soapQuestions = useSelector(getSoapQuestionsBySoapId)

  const onSaveQuestionLog = useCallback(
    (newQuestionLog: Question[]) => {
      if (soapId) {
        dispatch(saveQuestionsForSoap(soapId, newQuestionLog))
      }
    },
    [soapId],
  )

  return {
    onSaveQuestionLog,
    soapQuestions,
  }
}
