import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  List as ListIcon,
  LoveList as LoveListIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { OrderType } from '~/constants/SOAPStates'
import {
  getOrdersSearchResults,
  getOrdersSearchResultsTotalCount,
} from '~/store/reducers/orders'

import {
  deleteItemByPrice,
  mergeItemsWithQuantitySum,
} from '../../invoices/invoiceUtils'
import OrderListWithFilters from './OrderListWithFilters'
import SelectableListWithFilters from './SelectableListWithFilters'

const defaultGetUniqId = R.path(['price', 'id'])
const defaultAddItem = (oldItems, newItems) =>
  mergeItemsWithQuantitySum(oldItems, newItems, defaultGetUniqId)

const getAllowDecimalQuantity = (item) => item.type !== OrderType.LAB_TEST

const OrderSelectableListWithFilters = forwardRef(
  function OrderSelectableListWithFilters(
    {
      listTypes,
      getUniqId = defaultGetUniqId,
      addItem = defaultAddItem,
      deleteItem = deleteItemByPrice,
      enableFullFilter,
      ...rest
    },
    ref,
  ) {
    const searchResults = useSelector(getOrdersSearchResults)
    const searchResultsTotalCount =
      useSelector(getOrdersSearchResultsTotalCount) || 0
    const { t } = useTranslation('Common')

    const OrderListTypes = [
      {
        id: 'OUR',
        name: t('Common:OUR_LIST'),
        isDefault: true,
        Icon: LoveListIcon,
      },
      {
        id: 'FULL',
        name: t('Common:FULL_LIST'),
        Icon: ListIcon,
      },
    ].filter(Boolean)

    return (
      <SelectableListWithFilters
        ListWithFiltersComponent={OrderListWithFilters}
        addItem={addItem}
        deleteItem={deleteItem}
        getAllowDecimalQuantity={getAllowDecimalQuantity}
        getUniqId={getUniqId}
        listTypes={listTypes || OrderListTypes}
        ref={ref}
        searchResults={searchResults}
        searchResultsTotalCount={searchResultsTotalCount}
        {...rest}
      />
    )
  },
)

export default OrderSelectableListWithFilters
