import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Nil, Text } from '@pbt/pbt-ui-components'

import ClientSelector from '~/components/dashboard/timetable/scheduler/appointment/ClientSelector'

export interface AssignPatientSearchProps {
  actionBtnTitle?: string
  info?: string
  onExpand: (
    searchTerm: string,
    fieldsQuery?: string,
    additionalParams?: [string, string][],
  ) => void
  onItemClick: (id: string, subItemId: string | Nil) => void
}

const AssignPatientSearch = ({
  info = '',
  onExpand,
  onItemClick,
  actionBtnTitle: actionBtnTitleProps,
}: AssignPatientSearchProps) => {
  const { t } = useTranslation('Common')
  const actionBtnTitle = actionBtnTitleProps || t('Common:ASSIGN_ACTION')

  const lines = info.split('\n').map((line) => line.trim())

  return (
    <Grid container pb={3} pt={1} px={3}>
      <Grid container item direction="column">
        {lines.map((line) => (
          <Text key={line}>{line}</Text>
        ))}
      </Grid>
      <ClientSelector
        actionBtnTitle={actionBtnTitle}
        onExpand={onExpand}
        onSelected={onItemClick}
      />
    </Grid>
  )
}

export default AssignPatientSearch
