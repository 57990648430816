import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { GroupIdentifier } from '~/api/graphql/generated/types'
import PrintHtml from '~/components/common/print/PrintHtml'
import { clearInvoicePrintHtml, printInvoiceV2 } from '~/store/actions/finance'
import { getFinancePrintHtml } from '~/store/reducers/finance'

export interface PrintChargeSheetProps {
  expandedGroups?: GroupIdentifier[] | Nil
  includeServiceFee?: boolean
  invoiceId: string | Nil
  onClose?: () => void
}

const PrintPostedInvoice = ({
  onClose,
  invoiceId,
  expandedGroups,
  includeServiceFee,
}: PrintChargeSheetProps) => {
  const dispatch = useDispatch()
  const printHtml = useSelector(getFinancePrintHtml)

  useEffect(() => {
    if (invoiceId) {
      dispatch(printInvoiceV2(invoiceId, expandedGroups, includeServiceFee))
    }
  }, [invoiceId, expandedGroups])

  useEffect(() => {
    dispatch(clearInvoicePrintHtml())
  }, [])

  if (!printHtml) {
    return null
  }

  return <PrintHtml source={printHtml} onOk={onClose} />
}

export default PrintPostedInvoice
