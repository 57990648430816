import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  FieldType,
  NumberUtils,
  PermissionArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import TaxRateTextField from '~/components/common/inputs/TaxRateTextField'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeTaxConfigurationFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const useStyles = makeStyles(
  (theme) => ({
    taxRateContainerBordered: {
      borderBottom: theme.constants.filterBorder,
    },
    totalTaxRateContainer: {
      height: 45,
    },
  }),
  { name: 'TaxConfigurationSection' },
)

const TaxConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )

  const { fields, validate, reset } = useFields(
    R.flatten(
      (business.taxes || []).map((tax) => [
        {
          name: `taxName_${tax.id}`,
          initialValue: tax.name,
        },
        {
          name: `taxRateValue_${tax.id}`,
          initialValue: tax.rateValue,
          type: 'number' as FieldType,
        },
      ]),
    ),
    false,
  )

  const taxRateValueFields = Object.values(fields).filter((field) =>
    field.name.startsWith('taxRateValue'),
  )

  const totalTaxRate = R.sum(R.pluck('value', taxRateValueFields))

  usePracticeFieldsSection<PracticeTaxConfigurationFields>({
    business,
    fields,
    parsedFields: {
      taxes: (business.taxes || []).map((tax) => ({
        ...tax,
        name: fields[`taxName_${tax.id}`].value,
        rateValue: Number(fields[`taxRateValue_${tax.id}`].value),
      })),
    },
    sectionName: PracticeDetailsPanels.TAX_CONFIGURATION,
    validate,
    reset,
  })

  return (
    <>
      <Grid item mb={2}>
        <TextWithTooltip
          allowWrap
          strong
          tooltipText={t(
            'Businesses:TAX_CONFIGURATION.UNDER_ADMIN_MENU_YOU_CAN_MARK_ITEMS_AS_TAXABLE',
          )}
          variant="subheading3"
        >
          {t('Businesses:TAX_CONFIGURATION.TAX_CONFIGURATION')}
        </TextWithTooltip>
      </Grid>
      {(business.taxes || []).map((tax) => (
        <Grid
          container
          item
          alignItems="center"
          columnSpacing={2}
          justifyContent="space-between"
          key={tax.id}
          wrap="nowrap"
          xs={6}
        >
          <Grid item>
            <PuiTextField
              field={fields[`taxName_${tax.id}`]}
              inputProps={{ maxLength: 100 }}
              label={fields[`taxName_${tax.id}`].label}
            />
          </Grid>
          <Grid item>
            <TaxRateTextField
              disabled={!permissions.update}
              field={fields[`taxRateValue_${tax.id}`]}
            />
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        item
        alignItems="center"
        className={classes.totalTaxRateContainer}
        justifyContent="space-between"
        xs={6}
      >
        <Text strong variant="body">
          {t('Businesses:TAX_CONFIGURATION.TOTAL_TAX_RATE')}
        </Text>
        {totalTaxRate > 0 && (
          <Text strong sx={{ width: 100 }} variant="body">
            {NumberUtils.toPercentFormat(totalTaxRate, 2)}
          </Text>
        )}
      </Grid>
    </>
  )
}

export default memo(TaxConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
