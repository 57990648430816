import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { GroupIdentifier } from '~/api/graphql/generated/types'
import PrintHtml from '~/components/common/print/PrintHtml'
import {
  clearChargeSheetPrintHtml,
  getChargeSheetPrintHtml,
  printChargeSheet,
} from '~/store/duck/clientFinanceData'

export interface PrintChargeSheetProps {
  clientId: string | Nil
  expandedGroups?: GroupIdentifier[] | Nil
  onClose?: () => void
}

const PrintChargeSheet = ({
  onClose,
  clientId,
  expandedGroups,
}: PrintChargeSheetProps) => {
  const dispatch = useDispatch()
  const printHtml = useSelector(getChargeSheetPrintHtml)

  useEffect(() => {
    if (clientId) {
      dispatch(printChargeSheet({ clientId, expandedGroups }))
    }
  }, [clientId, expandedGroups])

  useEffect(() => {
    dispatch(clearChargeSheetPrintHtml())
  }, [])

  if (!printHtml) {
    return null
  }

  return <PrintHtml source={printHtml} onOk={onClose} />
}

export default PrintChargeSheet
