import { User } from '@pbt/pbt-ui-components'

import { blobToFormData } from '../utils'
import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const acceptEula = (businessId: string, userId: string) =>
  request(
    getAPIPath(`persons/${userId}/eula/accept`),
    { method: 'POST', params: { businessId } },
    true,
  )

export const updateCurrentUser = (data: Partial<User>) =>
  request(getAPIPath('persons/me'), { method: 'POST', data }, true, Schema.user)

export const uploadAvatar = (businessId: string, userId: string, blob: Blob) =>
  request(
    getAPIPath(`persons/${userId}/avatar`),
    {
      method: 'PUT',
      data: blobToFormData({ blob }),
      params: { businessId },
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    true,
    Schema.user,
  )

export const updateZnLabProviderId = (
  businessId: string,
  memberId: string,
  providerId: string,
) =>
  request(getAPIPath(`persons/${memberId}/znLabsProviderIdentifier`), {
    method: 'PUT',
    params: { businessId },
    data: { providerId: providerId.toString() },
  })

export const updateUserSignature = (
  businessId: string,
  signature: string,
  userId: string,
) =>
  request(
    getAPIPath('persons/signature'),
    {
      method: 'POST',
      params: { businessId },
      data: { signature, signerId: userId },
    },
    true,
  )
