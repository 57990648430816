import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  CustomClassicEditorType,
  CustomEventMap,
  CustomEventName,
} from '@pbt/pbt-ckeditor-ui'
import { Utils } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { matchImageExtension } from '~/constants/extensions'
import { getDocumentTypes } from '~/store/reducers/constants'
import {
  getAppointmentId,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'
import { Document } from '~/types'
import useDialog from '~/utils/useDialog'

const filter = 'Notes template'

export const useAddNotesTemplate = (editor: CustomClassicEditorType) => {
  const documentTypes = useSelector(getDocumentTypes)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const eventId = useSelector(getAppointmentId)

  const { t } = useTranslation('Common')

  const [openSelectAttachmentDialog] = useDialog(DialogNames.SELECT_ATTACHMENT)

  const templateType = Utils.findConstantByName(filter, documentTypes)

  const onDocumentsFetched = (documents: Document[]) => {
    if (documents.length > 0) {
      const [embeddableDocuments, fileDocuments] = R.partition(
        (document: Document) => Boolean(document.template?.body),
        documents,
      ) as [Document[], Document[]]

      const htmlBodyDocuments = embeddableDocuments.map(
        (document) => document.template?.body,
      )
      const imageDocuments = fileDocuments
        .filter(
          ({ template }) =>
            matchImageExtension(template?.extension) && template?.fileUrl,
        )
        .map(
          ({ template }) =>
            `<img src="${window.location.origin}${template?.fileUrl}" alt="${template?.fileName}" />`,
        )
      const bodies = [...htmlBodyDocuments, ...imageDocuments]

      if (!bodies.length) {
        return
      }

      // To inject the data while using real-time collaboration features, we manipulate the view/model with insertContent,
      // this way, this custom plugin is able to work while using TrackChanges real-time collaboration feature
      // https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/track-changes/track-changes-custom-features.html
      const htmlDP = editor.data.processor
      const viewFragment = htmlDP.toView(bodies.join('\n'))
      const modelFragment = editor.data.toModel(viewFragment)
      editor.model.insertContent(modelFragment)
    }
  }

  const onPlusButtonClick = () => {
    openSelectAttachmentDialog({
      documentTypes: [templateType],
      eventId,
      clientId,
      enrich: true,
      patientId,
      label: t('Common:NOTES_TEMPLATE').toLowerCase(),
      onDocumentsFetched,
      convertToTextDocument: false,
      skipFileTemplates: false,
    })
  }

  useEffect(() => {
    const handler = (evt: CustomEventMap['ADD_NOTES_TEMPLATE']) => {
      if (!editor) {
        return
      }

      evt.stopImmediatePropagation()

      if (evt.detail.open) {
        onPlusButtonClick()
      }
    }

    window.addEventListener(CustomEventName.ADD_NOTES_TEMPLATE, handler)

    return () =>
      window.removeEventListener(CustomEventName.ADD_NOTES_TEMPLATE, handler)
  }, [editor])
}
