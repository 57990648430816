import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getAppointmentTypes } from '~/store/reducers/constants'

export interface AppointmentEventTypeFilterProps
  extends CheckboxListFilterProps {}

const AppointmentEventTypeFilter = (props: AppointmentEventTypeFilterProps) => {
  const AppointmentEventTypes = useSelector(getAppointmentTypes)

  return (
    <CheckboxListFilter
      clearEmptyFilters
      items={AppointmentEventTypes}
      {...props}
    />
  )
}

export default AppointmentEventTypeFilter
