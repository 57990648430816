import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { clearInvoiceGroupExpanded } from '~/store/actions/finance'

export const useClearExpandedGroup = () => {
  const { soapId: urlSoapId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearInvoiceGroupExpanded())
  }, [urlSoapId])
}
