import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Calendar,
  CustomFieldValidatorState,
  moment,
  Nil,
  PuiSelect,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import QuantityInput from '~/components/common/inputs/QuantityInput'
import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import {
  RecurrenceOptions,
  RepeatMode,
  RepeatModeList,
} from '~/constants/taskConstants'
import { getTaskTimeUnits, getTimeOffsets } from '~/store/reducers/constants'
import { getTimetableEvent } from '~/store/reducers/timetable'
import {
  DataHandleWithUnsavedChanges,
  Task,
  TaskRecurrence,
  TaskRecurrencePeriod,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import { getNewTimeAfterDayChange } from '~/utils/time'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import TaskCustomRecurrencePeriodSettings, {
  TaskCustomRecurrencePeriodSettingsHandle,
} from './TaskCustomRecurrencePeriodSettings'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    quantityInput: {
      width: 'auto',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    labelRoot: {
      margin: 0,
      '&:not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
    },
    radioLabel: {
      fontSize: '1.6rem',
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: theme.spacing(0.5),
    },
    repeatSelect: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'TaskRecurrenceSettings' },
)

const getDueDateStartOfDay = (dueDate: string) =>
  moment(dueDate).utcOffset(0).set({ h: 0, m: 0 })

export interface TaskRecurrenceSettingsProps {
  allFieldsRequired?: boolean
  appointmentId?: string | Nil
  className?: string
  disabled?: boolean
  dueDate?: string | Nil
  initialRecurrence?: TaskRecurrence
  onRefChange?: () => void
  showRepeat?: boolean
  task: Task | Nil
}

export interface TaskRecurrenceSettingsHandle
  extends DataHandleWithUnsavedChanges<TaskRecurrence> {}

const TaskRecurrenceSettings = forwardRef(function TaskRecurrenceSettings(
  {
    allFieldsRequired,
    appointmentId,
    className,
    dueDate: dueDateProp,
    initialRecurrence,
    onRefChange,
    showRepeat = true,
    task,
    disabled,
  }: TaskRecurrenceSettingsProps,
  ref: ForwardedRef<TaskRecurrenceSettingsHandle>,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Validations', 'Tasks', 'Time'])

  const appointment = useSelector(getTimetableEvent(appointmentId))
  const TimeOffsets = useSelector(getTimeOffsets)
  const TaskTimeUnits = useSelector(getTaskTimeUnits)

  const isContextItem = useIsCurrentContextItem(task)

  const taskCustomRecurrencePeriodSettingsRef =
    useRef<TaskCustomRecurrencePeriodSettingsHandle>(null)

  const CustomTimeOffset = Utils.findConstantIdByName('Custom', TimeOffsets)

  const initialRecurrenceOption = task?.occurrencesCount
    ? RecurrenceOptions.OCCURRENCES
    : RecurrenceOptions.DATE_TIME
  const initialRepeatMode =
    task?.recurrenceModeId === CustomTimeOffset ||
    (!task?.recurrenceModeId &&
      initialRecurrence?.recurrenceModeId === CustomTimeOffset)
      ? RepeatMode.REPEAT
      : RepeatMode.DOES_NOT_REPEAT

  const initialDueDate = dueDateProp || task?.dueDate
  const initialDueTime = moment(
    initialDueDate || moment('18:00', 'HH:mm'),
  ).toISOString()
  const initialRecurrenceEndDate = task?.recurrenceEndDatetime
    ? moment(task.recurrenceEndDatetime).toISOString()
    : appointment?.scheduledEndDatetime
      ? moment
          .max(moment(appointment.scheduledEndDatetime), moment(initialDueDate))
          .toISOString()
      : initialDueTime
  const initialRecurrenceEndTime =
    initialRecurrenceEndDate && moment(initialRecurrenceEndDate).toISOString()

  const [recurrenceOption, setRecurrenceOption] = useState(
    initialRecurrenceOption,
  )
  const [repeatMode, setRepeatMode] = useState(initialRepeatMode)
  const [currentCustomRecurrenceType, setCurrentCustomRecurrenceType] =
    useState<string>()

  const DayTimeUnit = Utils.findConstantIdByName('Day', TaskTimeUnits)
  const isDayRecurrence = currentCustomRecurrenceType === DayTimeUnit

  const isRepeatMode = repeatMode === RepeatMode.REPEAT
  const isDateTimeRecurrence =
    isRepeatMode && recurrenceOption === RecurrenceOptions.DATE_TIME
  const isOccurrencesRecurrence =
    isRepeatMode && recurrenceOption === RecurrenceOptions.OCCURRENCES

  const endDateValidator = ({
    state: { recurrenceEndDate, dueDate },
  }: CustomFieldValidatorState) => {
    if (!isRepeatMode || isOccurrencesRecurrence) {
      return true
    }

    return moment(recurrenceEndDate).isSameOrAfter(dueDate, 'd')
  }

  const endTimeValidator = ({
    state: { recurrenceEndDate, dueDate, recurrenceEndTime, dueTime },
  }: CustomFieldValidatorState) => {
    if (!isRepeatMode || isOccurrencesRecurrence) {
      return true
    }

    return (
      moment(recurrenceEndDate).isAfter(dueDate, 'd') ||
      moment(recurrenceEndTime).isSameOrAfter(dueTime)
    )
  }

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'recurrenceEndDate',
        label: t('Tasks:LABEL.END_DATE'),
        initialValue: initialRecurrenceEndDate,
        validators: [
          { validator: endDateValidator, validatorName: 'endDateValidator' },
        ],
        messages: {
          endDateValidator: t('Validations:END_DATE_SHOULD_BE_AFTER_DUE_DATE'),
        },
      },
      {
        name: 'recurrenceEndTime',
        label: t('Tasks:LABEL.END_TIME'),
        validators: [
          { validator: endTimeValidator, validatorName: 'endTimeValidator' },
        ],
        messages: {
          endTimeValidator: t('Validations:END_TIME_SHOULD_BE_AFTER_DUE_DATE'),
        },
        initialValue: initialRecurrenceEndTime,
      },
      { name: 'occurrencesCount', initialValue: task?.occurrencesCount || 1 },
      {
        name: 'dueDate',
        label: isRepeatMode
          ? t('Tasks:LABEL.START_DATE')
          : t('Tasks:LABEL.DUE_DATE'),
        validators: allFieldsRequired ? ['required'] : [],
        initialValue:
          initialDueDate || moment().set({ m: 0, s: 0, ms: 0 }).toISOString(),
      },
      {
        name: 'dueTime',
        label: isRepeatMode
          ? t('Time:TIME_SELECTOR.START_TIME')
          : t('Tasks:LABEL.DUE_TIME'),
        validators: allFieldsRequired ? ['required'] : [],
        initialValue: initialDueTime,
      },
    ],
    false,
  )

  const {
    recurrenceEndDate,
    recurrenceEndTime,
    occurrencesCount,
    dueDate,
    dueTime,
  } = fields

  useEffectExceptOnMount(() => {
    onRefChange?.()
  }, [recurrenceEndDate, recurrenceEndTime, occurrencesCount, dueDate, dueTime])

  useEffect(() => {
    recurrenceEndDate.setValue(initialRecurrenceEndDate)
    recurrenceEndTime.setValue(initialRecurrenceEndTime)
    reset()
  }, [task, dueDateProp, appointment])

  useEffect(() => {
    setRecurrenceOption(initialRecurrenceOption)
  }, [initialRecurrenceOption])

  useEffect(() => {
    setRepeatMode(initialRepeatMode)
  }, [initialRepeatMode])

  const getDueDateFromRecurrencePeriodSettings = (
    customRecurrencePeriodSettings: TaskRecurrencePeriod,
  ) => {
    if (!isDayRecurrence || !customRecurrencePeriodSettings.timesOfDay?.[0]) {
      return dueTime.value
    }
    // for the day recurrence we need to set dueDateTime to be as first time of day
    // from settings - to properly create first (parent) task
    const [hours, minutes, seconds = 0] =
      customRecurrencePeriodSettings.timesOfDay[0]
        .split(':')
        .map((num) => Number.parseInt(num, 10))

    return moment(dueTime.value).set({ hours, minutes, seconds }).toISOString()
  }

  useImperativeHandle(ref, () => ({
    validate,
    get: () => {
      const customRecurrencePeriodSettings =
        taskCustomRecurrencePeriodSettingsRef.current!.get()
      const dueDateValue = getDueDateFromRecurrencePeriodSettings(
        customRecurrencePeriodSettings,
      )
      return {
        recurrenceModeId: isRepeatMode ? CustomTimeOffset : null,
        dueDate: isDayRecurrence
          ? moment(dueDateValue).startOf('day')
          : dueDateValue,
        occurrencesCount:
          isRepeatMode && isOccurrencesRecurrence
            ? occurrencesCount.value
            : null,
        recurrenceEndDatetime:
          isRepeatMode && isDateTimeRecurrence
            ? getNewTimeAfterDayChange(
                recurrenceEndDate.value,
                recurrenceEndTime.value,
              )
            : null,
        ...customRecurrencePeriodSettings,
      }
    },
    hasUnsavedChanges: () => {
      const fieldsChanged = isFieldValuesChanged(fields)
      const customRecurrenceChanged =
        taskCustomRecurrencePeriodSettingsRef.current?.hasUnsavedChanges() ??
        false

      return fieldsChanged || customRecurrenceChanged
    },
  }))

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(className, classes.root)}
    >
      <Grid container item alignItems="flex-end">
        {showRepeat && (
          <FormControl className={classes.repeatSelect} margin="normal">
            <InputLabel htmlFor="task-repeat-select">
              {t('Common:REPEAT_ACTION')}
            </InputLabel>
            <PuiSelect
              disabled={!isContextItem || disabled}
              input={<Input id="task-repeat-select" />}
              items={RepeatModeList}
              renderEmpty={false}
              value={repeatMode}
              onChange={Utils.handleFormSelectInput(setRepeatMode)}
            />
          </FormControl>
        )}
        <TaskCustomRecurrencePeriodSettings
          disabled={disabled}
          initialRecurrence={initialRecurrence}
          ref={taskCustomRecurrencePeriodSettingsRef}
          repeatMode={repeatMode}
          task={task}
          onRecurrencePeriodChange={setCurrentCustomRecurrenceType}
          onRefChange={onRefChange}
        />
      </Grid>
      {isRepeatMode && (
        <Grid item mt={2} xs={12}>
          <Text strong variant="body2">
            {t('Tasks:LABEL.START_NOUN')}
          </Text>
        </Grid>
      )}
      <Grid container item columnSpacing={3} wrap="nowrap">
        <Grid item xs={6}>
          <Calendar
            fullWidth
            disabled={!isContextItem || disabled}
            field={{
              ...dueDate,
              setValue: (value) => {
                const newEndTime = getNewTimeAfterDayChange(
                  value,
                  dueTime.value,
                )

                dueDate.setValue(value)
                dueTime.setValue(newEndTime)
              },
            }}
            label={`${dueDate.label}*`}
          />
        </Grid>
        {!isDayRecurrence && (
          <Grid item xs={6}>
            <TimeSelector
              fullWidth
              disabled={!isContextItem || disabled}
              label={`${dueTime.label}*`}
              startValue={dueTime.value}
              onStartChange={dueTime.setValue}
            />
          </Grid>
        )}
      </Grid>
      {isRepeatMode && (
        <>
          <Grid item mt={2} xs={12}>
            <Text strong variant="body2">
              {t('Tasks:LABEL.END_NOUN')}
            </Text>
          </Grid>
          {!disabled && (
            <RadioGroup
              row
              aria-label="endAfter"
              name="endAfter1"
              value={recurrenceOption}
              onChange={(_, value) => setRecurrenceOption(value)}
            >
              {Object.values(RecurrenceOptions).map((name) => (
                <FormControlLabel
                  classes={{
                    root: classes.labelRoot,
                    label: classes.radioLabel,
                  }}
                  control={<Radio className={classes.radio} />}
                  disabled={!isContextItem}
                  key={name}
                  label={name}
                  value={name}
                />
              ))}
            </RadioGroup>
          )}
          <Grid
            container
            item
            alignItems="center"
            columnSpacing={3}
            wrap="nowrap"
          >
            {isDateTimeRecurrence && (
              <>
                <Grid item xs={6}>
                  <Calendar
                    fullWidth
                    disabled={!isContextItem || disabled}
                    field={{
                      ...recurrenceEndDate,
                      setValue: (value) => {
                        const newEndTime = getNewTimeAfterDayChange(
                          value,
                          recurrenceEndTime.value,
                        )

                        recurrenceEndDate.setValue(value)
                        recurrenceEndTime.setValue(newEndTime)
                      },
                    }}
                    label={`${recurrenceEndDate.label}${
                      allFieldsRequired ? '*' : ''
                    }`}
                    minDate={getDueDateStartOfDay(dueDate.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimeSelector
                    fullWidth
                    disabled={!isContextItem || disabled}
                    field={recurrenceEndTime}
                    label={`${recurrenceEndTime.label}${
                      allFieldsRequired ? '*' : ''
                    }`}
                    startValue={recurrenceEndTime.value}
                    onStartChange={recurrenceEndTime.setValue}
                  />
                </Grid>
              </>
            )}
            {isOccurrencesRecurrence && (
              <Grid container item alignItems="center" wrap="nowrap">
                <Text>{t('Tasks:LABEL.END_AFTER')}</Text>
                <QuantityInput
                  showControls
                  className={classes.quantityInput}
                  disabled={!isContextItem}
                  field={occurrencesCount}
                  max={999}
                  min={1}
                />
                <Text>
                  {occurrencesCount.value === 1
                    ? t('Tasks:LABEL.OCCURRENCE_ONE').toLowerCase()
                    : t('Tasks:LABEL.OCCURRENCE_OTHER').toLowerCase()}
                </Text>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
})

export default TaskRecurrenceSettings
