import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import { Space } from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  CLEAR_SPACES_LIST_FOR_APPOINTMENT,
  CREATE_SPACE,
  CREATE_SPACE_FAILURE,
  CREATE_SPACE_SUCCESS,
  DELETE_SPACE,
  DELETE_SPACE_FAILURE,
  DELETE_SPACE_SUCCESS,
  EDIT_SPACE,
  EDIT_SPACE_FAILURE,
  EDIT_SPACE_SUCCESS,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_SPACES_LIST_SUCCESS,
  FETCH_SPACE,
  FETCH_SPACE_FAILURE,
  FETCH_SPACE_SUCCESS,
  FETCH_SPACES_LIST,
  FETCH_SPACES_LIST_FAILURE,
  FETCH_SPACES_LIST_FOR_APPOINTMENT,
  FETCH_SPACES_LIST_FOR_APPOINTMENT_FAILURE,
  FETCH_SPACES_LIST_FOR_APPOINTMENT_SUCCESS,
  FETCH_SPACES_LIST_SUCCESS,
  FETCH_SPACES_LIST_WITH_TYPE,
  FETCH_SPACES_LIST_WITH_TYPE_FAILURE,
  FETCH_SPACES_LIST_WITH_TYPE_SUCCESS,
  UPDATE_SPACES,
} from '../actions/types/spaces'
import type { RootState } from '../index'

export type SpacesState = {
  currentTypeId: string | null
  error: string | null
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  lastCreatedSpaceId: string | null
  list: string[]
  map: Record<string, Space>
  spacesListForAppointment: string[]
  totalCount: number
}

export const INITIAL_STATE: SpacesState = {
  list: [],
  map: {},
  isFetching: false,
  isFetchingList: false,
  isLoading: false,
  isDeleting: false,
  error: null,
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  lastCreatedSpaceId: null,
  spacesListForAppointment: [],
  currentTypeId: null,
}

const spaces = (state: SpacesState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case FETCH_SPACES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        currentTypeId: null,
      }
    case FETCH_SPACES_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        currentTypeId: null,
      }
    case FETCH_SPACES_LIST:
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        list: [],
        currentTypeId: null,
      }
    case FETCH_SPACES_LIST_WITH_TYPE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        currentTypeId: null,
      }
    case FETCH_SPACES_LIST_WITH_TYPE_SUCCESS:
      return {
        ...state,
        list: action.list,
        totalCount: action.totalCount,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
      }
    case FETCH_SPACES_LIST_WITH_TYPE:
      return {
        ...state,
        list: [],
        error: null,
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        currentTypeId: action.typeId,
      }
    case FETCH_MORE_ITEMS_FOR_SPACES_LIST:
      return {
        ...state,
        isLoading: true,
        error: null,
        currentTypeId: null,
      }
    case FETCH_MORE_ITEMS_FOR_SPACES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        currentTypeId: null,
      }
    case FETCH_MORE_ITEMS_FOR_SPACES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
        error: null,
        currentTypeId: null,
      }
    case UPDATE_SPACES:
      const updatedSpaces = action.spaces || {}
      return {
        ...state,
        map: R.mergeDeepRight(state.map, updatedSpaces),
        spacesListForAppointment: action.updateList
          ? state.spacesListForAppointment.concat(Object.keys(spaces))
          : state.spacesListForAppointment,
      }
    case FETCH_SPACE:
      return { ...state, isLoading: true, isFetching: true }
    case FETCH_SPACE_SUCCESS:
      return { ...state, isLoading: false, isFetching: false }
    case FETCH_SPACE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_SPACE:
    case EDIT_SPACE:
      return { ...state, isLoading: true }
    case EDIT_SPACE_SUCCESS:
      return { ...state, isLoading: false }
    case CREATE_SPACE_FAILURE:
    case EDIT_SPACE_FAILURE:
    case DELETE_SPACE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_SPACE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        map: R.omit([action.spaceId], state.map),
        list: R.without([action.spaceId], state.list),
        totalCount: Math.max(state.totalCount - 1, 0),
        spacesListForAppointment: R.without(
          [action.spaceId],
          state.spacesListForAppointment,
        ),
      }
    case DELETE_SPACE:
      return { ...state, isDeleting: true }
    case CREATE_SPACE_SUCCESS:
      return { ...state, isLoading: false, lastCreatedBundleId: action.spaceId }
    case FETCH_SPACES_LIST_FOR_APPOINTMENT:
      return { ...state, isLoading: true }
    case FETCH_SPACES_LIST_FOR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        spacesListForAppointment: action.list,
      }
    case FETCH_SPACES_LIST_FOR_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_SPACES_LIST_FOR_APPOINTMENT:
      return { ...state, spacesListForAppointment: [] }
    default:
      return state
  }
}

export default spaces
export const getSpaces = (state: RootState): SpacesState => state.spaces
export const getSpacesList = (state: RootState) => getSpaces(state).list
export const getSpacesMap = (state: RootState) => getSpaces(state).map
export const getMultipleSpaces = (ids: string[]) =>
  createSelector(getSpacesMap, (map: ReturnType<typeof getSpacesMap>) =>
    R.props(ids, map),
  )
export const getSpace = (id: string | Nil) =>
  createSelector(getSpacesMap, (map: ReturnType<typeof getSpacesMap>) =>
    id ? map[id] : undefined,
  )
export const getSpaceName = (spaceId: string | Nil) =>
  createSelector(getSpace(spaceId), (space) => space?.name)
export const getSpacesIsFetching = (state: RootState) =>
  getSpaces(state).isFetching
export const getSpacesIsFetchingList = (state: RootState) =>
  getSpaces(state).isFetchingList
export const getSpacesIsLoading = (state: RootState) =>
  getSpaces(state).isLoading
export const getSpacesIsDeleting = (state: RootState) =>
  getSpaces(state).isDeleting
export const getSpacesError = (state: RootState) => getSpaces(state).error
export const getSpacesTotalCount = (state: RootState) =>
  getSpaces(state).totalCount
export const getLastCreatedSpaceId = (state: RootState) =>
  getSpaces(state).lastCreatedSpaceId
export const getSpacesListForAppointment = (state: RootState) =>
  getSpaces(state).spacesListForAppointment
export const getSpaceCurrentTypeId = (state: RootState) =>
  getSpaces(state).currentTypeId
