import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'

import { fetchSoap } from '~/store/actions/soap'

import SoapPageLoader from './SoapPageLoader'
import {
  SoapV1StepsUrl,
  useSoapPageRedirect,
} from './utils/useSoapPageRedirect'
import { useSoapTemplateTabs } from './utils/useSoapTemplateTabs'

// This component will be responsible to redirect users that try to access the old urls directly
// But we need to remember that the new Soap will have dynamic named urls that could include or not the widgets
const SoapPageRedirect = () => {
  const dispatch = useDispatch()
  const { step: urlStep, soapId: urlSoapId } = useParams()
  const [searchParams] = useSearchParams()

  const originalBusinessId = searchParams.get('originalBusinessId')

  const tabs = useSoapTemplateTabs({ redirect: true })
  const redirectTo = useSoapPageRedirect(tabs)

  useEffect(() => {
    if (urlSoapId) {
      dispatch(fetchSoap(urlSoapId, originalBusinessId))
    }
  }, [urlSoapId, originalBusinessId])

  if (!redirectTo) {
    return <SoapPageLoader open />
  }

  return <Navigate replace to={redirectTo(urlStep as SoapV1StepsUrl)} />
}

export default SoapPageRedirect
