import { ApiError } from '@pbt/pbt-ui-components'

import { AvailabilityRule, UnsavedAvailabilityRule } from '~/types'

import {
  CLEAR_AVAILABILITY_RULE_VALIDATION_ERROR,
  CREATE_AVAILABILITY_RULE,
  CREATE_AVAILABILITY_RULE_FAILURE,
  CREATE_AVAILABILITY_RULE_SUCCESS,
  DELETE_AVAILABILITY_RULE,
  DELETE_AVAILABILITY_RULE_FAILURE,
  DELETE_AVAILABILITY_RULE_SUCCESS,
  EDIT_AVAILABILITY_RULE,
  EDIT_AVAILABILITY_RULE_FAILURE,
  EDIT_AVAILABILITY_RULE_SUCCESS,
  FETCH_AVAILABILITY_RULE,
  FETCH_AVAILABILITY_RULE_FAILURE,
  FETCH_AVAILABILITY_RULE_SUCCESS,
  FETCH_AVAILABILITY_RULES_LIST,
  FETCH_AVAILABILITY_RULES_LIST_FAILURE,
  FETCH_AVAILABILITY_RULES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_SUCCESS,
  UPDATE_AVAILABILITY_RULES,
} from './types/availabilityRules'

export const createAvailabilityRule = (rule: UnsavedAvailabilityRule) => ({
  type: CREATE_AVAILABILITY_RULE,
  rule,
})
export const createAvailabilityRuleSuccess = (ruleId: string) => ({
  type: CREATE_AVAILABILITY_RULE_SUCCESS,
  ruleId,
})
export const createAvailabilityRuleFailure = (error: ApiError) => ({
  type: CREATE_AVAILABILITY_RULE_FAILURE,
  error,
})

export const fetchAvailabilityRule = (ruleId: string) => ({
  type: FETCH_AVAILABILITY_RULE,
  ruleId,
})
export const fetchAvailabilityRuleSuccess = () => ({
  type: FETCH_AVAILABILITY_RULE_SUCCESS,
})
export const fetchAvailabilityRuleError = (error: ApiError) => ({
  type: FETCH_AVAILABILITY_RULE_FAILURE,
  error,
})

export const editAvailabilityRule = (rule: AvailabilityRule) => ({
  type: EDIT_AVAILABILITY_RULE,
  rule,
})
export const editAvailabilityRuleFailure = (error: ApiError) => ({
  type: EDIT_AVAILABILITY_RULE_FAILURE,
  error,
})
export const editAvailabilityRuleSuccess = () => ({
  type: EDIT_AVAILABILITY_RULE_SUCCESS,
})

export const updateAvailabilityRules = (
  rules: Record<string, AvailabilityRule>,
) => ({
  type: UPDATE_AVAILABILITY_RULES,
  rules,
})

export const fetchAvailabilityRulesList = (from?: number, to?: number) => ({
  type: FETCH_AVAILABILITY_RULES_LIST,
  from,
  to,
})
export const fetchAvailabilityRulesListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_AVAILABILITY_RULES_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchAvailabilityRulesListFailure = (error: ApiError) => ({
  type: FETCH_AVAILABILITY_RULES_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForAvailabilityRulesList = (
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST,
  from,
  to,
})
export const fetchMoreItemsForAvailabilityRulesListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForAvailabilityRulesListFailure = (
  error: ApiError,
) => ({
  type: FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST_FAILURE,
  error,
})

export const deleteAvailabilityRule = (rule: AvailabilityRule) => ({
  type: DELETE_AVAILABILITY_RULE,
  rule,
})
export const deleteAvailabilityRuleFailure = (error: ApiError) => ({
  type: DELETE_AVAILABILITY_RULE_FAILURE,
  error,
})
export const deleteAvailabilityRuleSuccess = (ruleId: string) => ({
  type: DELETE_AVAILABILITY_RULE_SUCCESS,
  ruleId,
})

export const clearAvailabilityRuleValidationError = () => ({
  type: CLEAR_AVAILABILITY_RULE_VALIDATION_ERROR,
})
