import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import {
  DateUtils,
  Defaults,
  LanguageUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import {
  fetchAdjustments,
  fetchMoreAdjustments,
  getAdjustmentsIsFetchingList,
  getAdjustmentsList,
  getAdjustmentsTotalCount,
  getMultipleAdjustments,
} from '~/store/duck/inventoryAdjustments'
import {
  getInventoryAdjustmentReasons,
  getInventoryProductSizeUnit,
  getPackageType,
} from '~/store/reducers/constants'
import { Adjustment } from '~/types'

// @ts-ignore
import { getOnHandAmount } from '../inventoryUtils'
import AdjustmentAmountCell from './AdjustmentAmountCell'
import AdjustmentDetails from './AdjustmentDetails'

export interface AdjustmentTableComponentProps {
  headerButtons?: React.ReactNode
  headerTabs?: React.ReactNode
  itemId?: string
  onDetailsClose: () => void
}

const AdjustmentTableComponent = ({
  itemId,
  headerButtons,
  headerTabs,
  onDetailsClose,
}: AdjustmentTableComponentProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const adjustmentsList = useSelector(getAdjustmentsList)
  const adjustments = useSelector(getMultipleAdjustments(adjustmentsList))
  const totalCount = useSelector(getAdjustmentsTotalCount)
  const isFetchingList = useSelector(getAdjustmentsIsFetchingList)

  const InventoryAdjustmentReasons = useSelector(getInventoryAdjustmentReasons)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const PackageType = useSelector(getPackageType)

  const getOnHandAmountLabel = getOnHandAmount(
    InventoryProductSizeUnit,
    PackageType,
  )

  const columns = [
    {
      label: t('Common:ADJUSTMENT_DATE'),
      prop: ({ creationDate }: Adjustment) =>
        DateUtils.formatDateWithHours(creationDate),
      width: 2,
    },
    {
      label: t('Common:INVENTORY_ITEM'),
      prop: R.path(['name']),
      width: 6,
    },
    {
      label: t('Common:ADJUSTMENT'),
      prop: AdjustmentAmountCell,
      width: 4,
    },
    {
      label: t('Common:ADJUSTMENT_REASON'),
      prop: ({ reasonId }: Adjustment) =>
        LanguageUtils.getConstantTranslatedName(
          reasonId,
          InventoryAdjustmentReasons,
        ),
      width: 3,
    },
    {
      label: t('Common:ADJUSTED_BY'),
      prop: ({ person }: Adjustment) => Utils.getPersonString(person),
      width: 3,
    },
    {
      label: t('Common:ON-HAND'),
      prop: ({ variation }: Adjustment) => getOnHandAmountLabel(variation),
      width: 4,
    },
  ]

  useEffect(() => {
    dispatch(fetchAdjustments(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT))
  }, [])

  const navigateToInventory = (id: string) => {
    navigate(`/admin/catalog/inventories/adjustments/${id}`)
  }

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreAdjustments(startIndex, endIndex))
  }

  const isItemLoaded = (index: number) => Boolean(adjustmentsList[index])

  return (
    <ExpandableTable
      Expander={AdjustmentDetails}
      headerButtons={headerButtons}
      headerTabs={headerTabs}
      isLoading={isFetchingList}
      itemId={itemId}
      list={adjustments}
      title={t('Common:ADJUSTMENTS')}
      onClose={onDetailsClose}
      onSelected={navigateToInventory}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default AdjustmentTableComponent
