import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import { TableFilter, Variation } from '~/types'
import { OnHandCatalogHistoryItem } from '~/types/entities/onHandCatalog'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '..'

export const FETCH_VARIATIONS = 'onHandCatalog/FETCH_VARIATIONS'
export const FETCH_VARIATIONS_SUCCESS = 'onHandCatalog/FETCH_VARIATIONS_SUCCESS'
export const FETCH_VARIATIONS_FAILURE = 'onHandCatalog/FETCH_VARIATIONS_FAILURE'

export const FETCH_MORE_VARIATIONS = 'onHandCatalog/FETCH_MORE_VARIATIONS'
export const FETCH_MORE_VARIATIONS_SUCCESS =
  'onHandCatalog/FETCH_MORE_VARIATIONS_SUCCESS'
export const FETCH_MORE_VARIATIONS_FAILURE =
  'onHandCatalog/FETCH_MORE_VARIATIONS_FAILURE'

export const SEARCH_VARIATIONS = 'onHandCatalog/SEARCH_VARIATIONS'
export const SEARCH_VARIATIONS_SUCCESS =
  'onHandCatalog/SEARCH_VARIATIONS_SUCCESS'
export const SEARCH_VARIATIONS_FAILURE =
  'onHandCatalog/SEARCH_VARIATIONS_FAILURE'

export const SEARCH_MORE_VARIATIONS = 'onHandCatalog/SEARCH_MORE_VARIATIONS'
export const SEARCH_MORE_VARIATIONS_SUCCESS =
  'onHandCatalog/SEARCH_MORE_VARIATIONS_SUCCESS'
export const SEARCH_MORE_VARIATIONS_FAILURE =
  'onHandCatalog/SEARCH_MORE_VARIATIONS_FAILURE'

export const FETCH_VARIATION = 'onHandCatalog/FETCH_VARIATION'
export const FETCH_VARIATION_SUCCESS = 'onHandCatalog/FETCH_VARIATION_SUCCESS'
export const FETCH_VARIATION_FAILURE = 'onHandCatalog/FETCH_VARIATION_FAILURE'

export const EDIT_VARIATION = 'onHandCatalog/EDIT_VARIATION'
export const EDIT_VARIATION_SUCCESS = 'onHandCatalog/EDIT_VARIATION_SUCCESS'
export const EDIT_VARIATION_FAILURE = 'onHandCatalog/EDIT_VARIATION_FAILURE'

export const FETCH_ON_HAND_HISTORY = 'onHandCatalog/FETCH_ON_HAND_HISTORY'
export const FETCH_ON_HAND_HISTORY_SUCCESS =
  'onHandCatalog/FETCH_ON_HAND_HISTORY_SUCCESS'
export const FETCH_ON_HAND_HISTORY_FAILURE =
  'onHandCatalog/FETCH_ON_HAND_HISTORY_FAILURE'

export const UPDATE_VARIATIONS = 'onHandCatalog/UPDATE_VARIATIONS'
export const CLEAR_FILTERS = 'onHandCatalog/CLEAR_FILTERS'
export const CLEAR_HISTORY = 'onHandCatalog/CLEAR_HISTORY'
export const UPDATE_HISTORY = 'onHandCatalog/UPDATE_HISTORY'
export const SET_FILTERS = 'onHandCatalog/SET_FILTERS'

export const fetchVariations = (from: number, to: number) => ({
  type: FETCH_VARIATIONS,
  from,
  to,
})
export const fetchVariationsSuccess = (
  from: number,
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_VARIATIONS_SUCCESS,
  from,
  list,
  totalCount,
})
export const fetchVariationsFailure = (error: ApiError) => ({
  type: FETCH_VARIATIONS_FAILURE,
  error,
})

export const fetchMoreVariations = (from: number, to: number) => ({
  type: FETCH_MORE_VARIATIONS,
  from,
  to,
})
export const fetchMoreVariationsSuccess = (
  from: number,
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_MORE_VARIATIONS_SUCCESS,
  from,
  list,
  totalCount,
})
export const fetchMoreVariationsFailure = (error: ApiError) => ({
  type: FETCH_MORE_VARIATIONS_FAILURE,
  error,
})

export const searchVariations = (from: number, to: number, query: string) => ({
  type: SEARCH_VARIATIONS,
  from,
  to,
  query,
})
export const searchVariationsSuccess = (
  from: number,
  list: string[],
  totalCount: number,
) => ({
  type: SEARCH_VARIATIONS_SUCCESS,
  from,
  list,
  totalCount,
})
export const searchVariationsFailure = (error: ApiError) => ({
  type: SEARCH_VARIATIONS_FAILURE,
  error,
})

export const searchMoreVariations = (
  from: number,
  to: number,
  query: string,
) => ({
  type: SEARCH_MORE_VARIATIONS,
  from,
  to,
  query,
})
export const searchMoreVariationsSuccess = (
  from: number,
  list: string[],
  totalCount: number,
) => ({
  type: SEARCH_MORE_VARIATIONS_SUCCESS,
  from,
  list,
  totalCount,
})
export const searchMoreVariationsFailure = (error: ApiError) => ({
  type: SEARCH_MORE_VARIATIONS_FAILURE,
  error,
})

export const fetchVariation = (variationId: string) => ({
  type: FETCH_VARIATION,
  variationId,
})
export const fetchVariationSuccess = () => ({ type: FETCH_VARIATION_SUCCESS })
export const fetchVariationFailure = (error: ApiError) => ({
  type: FETCH_VARIATION_FAILURE,
  error,
})

export const editVariation = (variation: Variation) => ({
  type: EDIT_VARIATION,
  variation,
})
export const editVariationSuccess = (variationId: string) => ({
  type: EDIT_VARIATION_SUCCESS,
  variationId,
})
export const editVariationFailure = (error: ApiError, variationId: string) => ({
  type: EDIT_VARIATION_FAILURE,
  error,
  variationId,
})

export const fetchOnHandHistory = (
  variationId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_ON_HAND_HISTORY,
  variationId,
  from,
  to,
})
export const fetchOnHandHistorySuccess = (
  from: number,
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_ON_HAND_HISTORY_SUCCESS,
  from,
  list,
  totalCount,
})
export const fetchOnHandHistoryFailure = (error: ApiError) => ({
  type: FETCH_ON_HAND_HISTORY_FAILURE,
  error,
})

export const updateOnHandVariations = (
  entities: Record<string, Variation>,
) => ({ type: UPDATE_VARIATIONS, entities })
export const updateOnHandHistories = (
  entities: Record<string, OnHandCatalogHistoryItem>,
) => ({
  type: UPDATE_HISTORY,
  entities,
})
export const clearFilters = () => ({ type: CLEAR_FILTERS })
export const clearHistory = () => ({ type: CLEAR_HISTORY })
export const setFilters = (filters: Record<string, TableFilter>) => ({
  type: SET_FILTERS,
  filters,
})

export type OnHandCatalogState = {
  error: string | null
  filters: Record<string, TableFilter>
  historyList: string[]
  historyMap: Record<string, OnHandCatalogHistoryItem>
  historyTotalCount: number
  isLoading: boolean
  isLoadingHistory: boolean
  lastSearchQuery: string | null
  list: string[]
  map: Record<string, Variation>
  totalCount: number
  updatingVariations: string[]
}

const INITIAL_STATE: OnHandCatalogState = {
  map: {},
  list: [],
  filters: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isLoading: false,
  updatingVariations: [],
  historyMap: {},
  historyList: [],
  historyTotalCount: 0,
  isLoadingHistory: false,
  lastSearchQuery: null,
  error: null,
}

export const onHandCatalogReducer = (
  state: OnHandCatalogState = INITIAL_STATE,
  action: AnyAction,
): OnHandCatalogState => {
  switch (action.type) {
    case FETCH_VARIATIONS:
      return {
        ...state,
        isLoading: true,
        lastSearchQuery: null,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        error: null,
      }
    case FETCH_VARIATIONS_SUCCESS:
      return {
        ...state,
        list: action.list,
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_VARIATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_VARIATIONS:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MORE_VARIATIONS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_MORE_VARIATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case SEARCH_VARIATIONS:
      return {
        ...state,
        isLoading: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        lastSearchQuery: action.query,
        error: null,
      }
    case SEARCH_VARIATIONS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case SEARCH_VARIATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case SEARCH_MORE_VARIATIONS:
      return {
        ...state,
        isLoading: true,
      }
    case SEARCH_MORE_VARIATIONS_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case SEARCH_MORE_VARIATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_VARIATION:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_VARIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case FETCH_VARIATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_VARIATION:
      return {
        ...state,
        updatingVariations: R.uniq([
          ...state.updatingVariations,
          action.variation?.id,
        ]),
        isLoading: true,
      }
    case EDIT_VARIATION_SUCCESS:
      return {
        ...state,
        updatingVariations: R.without(
          [action.variationId],
          state.updatingVariations,
        ),
        isLoading: false,
      }
    case EDIT_VARIATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        updatingVariations: R.without(
          [action.variationId],
          state.updatingVariations,
        ),
        error: getErrorMessage(action.error),
      }
    case UPDATE_VARIATIONS:
      return { ...state, map: secondLevelMerge(state.map, action.entities) }
    case FETCH_ON_HAND_HISTORY:
      return {
        ...state,
        isLoadingHistory: true,
      }
    case FETCH_ON_HAND_HISTORY_SUCCESS:
      return {
        ...state,
        historyList: mergeArraysAtIndex(
          state.historyList,
          action.list,
          action.from,
        ),
        historyTotalCount: action.totalCount,
        isLoadingHistory: false,
      }
    case FETCH_ON_HAND_HISTORY_FAILURE:
      return {
        ...state,
        isLoadingHistory: false,
        error: getErrorMessage(action.error),
      }
    case UPDATE_HISTORY:
      return {
        ...state,
        historyMap: secondLevelMerge(state.historyMap, action.entities),
      }
    case SET_FILTERS:
      return { ...state, list: [], filters: action.filters }
    case CLEAR_FILTERS:
      return { ...state, filters: {}, lastSearchQuery: null }
    case CLEAR_HISTORY:
      return { ...state, historyList: [] }
    default:
      return state
  }
}

const getOnHandCatalog = (state: RootState): OnHandCatalogState =>
  state.onHandCatalog
export const getIsLoading = (state: RootState) =>
  getOnHandCatalog(state).isLoading
export const getTotalCount = (state: RootState) =>
  getOnHandCatalog(state).totalCount
export const getFilters = (state: RootState) => getOnHandCatalog(state).filters
export const getVariationsList = (state: RootState) =>
  getOnHandCatalog(state).list
export const getVariationsMap = (state: RootState) =>
  getOnHandCatalog(state).map
export const getUpdatingVariations = (state: RootState) =>
  getOnHandCatalog(state).updatingVariations
export const getIsUpdatingVariation =
  (variationId: string) => (state: RootState) =>
    getUpdatingVariations(state).includes(variationId)
export const getVariation = (id: string | Nil) =>
  createSelector(getVariationsMap, (map) => (id ? map[id] : undefined))
export const getMultipleVariations = (ids: string[]) =>
  createSelector(getVariationsMap, (map) => R.props(ids, map))
export const getHistoryList = (state: RootState) =>
  getOnHandCatalog(state).historyList
export const getHistoryMap = (state: RootState) =>
  getOnHandCatalog(state).historyMap
export const getHistoryTotalCount = (state: RootState) =>
  getOnHandCatalog(state).historyTotalCount
export const getLastSearchQuery = (state: RootState) =>
  getOnHandCatalog(state).lastSearchQuery
export const getMultipleHistoryRecords = (ids: string[]) =>
  createSelector(getHistoryMap, (map) => R.props(ids, map))
