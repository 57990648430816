import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { PuiPopper, PuiPopperProps, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  getChargeSheetSubItemsMap,
  getClientFinanceClientId,
} from '~/store/duck/clientFinanceData'
import {
  ChargeSheetSecondLevelMenuItems,
  ChargeSheetSectionShort,
  useGetFootnoteMenuItems,
} from '~/store/hooks/chargeSheet'
import { getUser } from '~/store/reducers/users'
import { ChargeSheetItemSection } from '~/types/entities/chargesSheet'
import useDialog from '~/utils/useDialog'

import { ChargeSheetEntityTypes } from './constants'

const getChargeSheetItemIdByPatientId = (
  patientId: string,
  data: Record<string, ChargeSheetItemSection>,
) =>
  R.pipe<
    (typeof data)[],
    ChargeSheetItemSection[],
    ChargeSheetItemSection[],
    string[],
    string
  >(
    R.values,
    R.filter(
      (chargeSheetItem: ChargeSheetItemSection) =>
        patientId === chargeSheetItem.patientId,
    ),
    R.pluck('id'),
    R.head,
  )(data)

const MIN_NUMBER_OF_PETS_TO_SHOW_LINK = 8

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    menuItem: {
      padding: theme.spacing(1),
      margin: theme.spacing(0, 1),
    },
    group: {
      '&:not(:first-of-type)': {
        paddingTop: theme.spacing(1),
      },
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tableBorder,
        paddingBottom: theme.spacing(1),
      },
    },
    link: {
      color: theme.colors.link,
    },
  }),
  { name: 'ChargeSheetAddItemPopper' },
)

interface ChargeSheetAddItemPopperProps {
  anchorEl: PuiPopperProps['anchorEl']
  onClick: (value: ChargeSheetSectionShort) => void
  onClose: PuiPopperProps['onClose']
}

const ChargeSheetAddItemPopper = ({
  anchorEl,
  onClick,
  onClose,
}: ChargeSheetAddItemPopperProps) => {
  const { t } = useTranslation('Plurals')
  const classes = useStyles()

  const chargeSheetItemsMap = useSelector(getChargeSheetSubItemsMap)
  const chargeSheetClientId = useSelector(getClientFinanceClientId)
  const chargeSheetClient = useSelector(getUser(chargeSheetClientId))

  const patientsIds = chargeSheetClient?.patients || []

  const items = useGetFootnoteMenuItems()
  const [openPatientsListDialog] = useDialog(DialogNames.PATIENTS_LIST)

  const getChargeSheetItemId = (patientId?: string) =>
    !R.isNil(chargeSheetItemsMap) && !R.isNil(patientId)
      ? getChargeSheetItemIdByPatientId(patientId, chargeSheetItemsMap)
      : ''

  const handleShowAllPetsOnClick = () =>
    openPatientsListDialog({
      patientsIds,
      onPatientClick: (patientId?: string) =>
        onClick({
          id: getChargeSheetItemId(patientId),
          patientId,
        }),
    })

  const getItemId = ({
    id,
    patientId,
    isSoap,
  }: ChargeSheetSecondLevelMenuItems) =>
    patientId
      ? isSoap
        ? getChargeSheetItemId(patientId)
        : `null_${patientId}`
      : id

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
      open={Boolean(anchorEl)}
      placement="right-start"
      onClose={onClose}
    >
      {R.keys(items).map((key) => (
        <Grid className={classes.group} key={key}>
          <Text px={2} py={0.5} variant="body3">
            {key}
          </Text>
          {items[key].map((item) => (
            <MenuItem
              disabled={item.itemReadOnly}
              key={item.id}
              onClick={
                item.itemReadOnly
                  ? undefined
                  : () =>
                      onClick({
                        id: getItemId(item),
                        patientId: item.patientId,
                      })
              }
            >
              {item.item}
            </MenuItem>
          ))}
          {ChargeSheetEntityTypes.OTC === key &&
            items[ChargeSheetEntityTypes.OTC].length >
              MIN_NUMBER_OF_PETS_TO_SHOW_LINK && (
              <Text link px={2} py={0.5} onClick={handleShowAllPetsOnClick}>
                {t('Plurals:Z_ICU_WORKAROUND.SHOW_ALL_PETS', {
                  numResults: items[ChargeSheetEntityTypes.OTC].length,
                })}
              </Text>
            )}
        </Grid>
      ))}
    </PuiPopper>
  )
}

export default ChargeSheetAddItemPopper
