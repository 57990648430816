import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import RangeButton from '~/components/common/buttons/RangeButton'
import { Invoice } from '~/types'

import { updateItemsToUseSingleQuantity } from '../../../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    hidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'QuantityCellHead' },
)

export interface QuantityCellHeadProps {
  className?: string
  invoice: Invoice
  isEstimate: boolean
  prePaidEnabled: boolean
  rangeEnabled: boolean
  setRangeEnabled: (value: boolean) => void
  updateInvoice: (invoice: Invoice) => void
}

const QuantityCellHead = ({
  className,
  invoice,
  rangeEnabled,
  isEstimate,
  prePaidEnabled,
  updateInvoice,
  setRangeEnabled,
}: QuantityCellHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const groups = (invoice && invoice.groups) || []
  const hasItems = groups.some((group) => group.groupedItems.length > 0)
  const quantityColSpan = rangeEnabled
    ? 2
    : prePaidEnabled
      ? isEstimate
        ? 2
        : 3
      : 0

  const toggleRange = () => {
    if (rangeEnabled) {
      const newGroups = groups.map((group) => ({
        ...group,
        groupedItems: updateItemsToUseSingleQuantity(group.groupedItems),
      }))
      updateInvoice({ ...invoice, groups: newGroups })
    }
    setRangeEnabled(!rangeEnabled)
  }

  return (
    <TableCell align="center" className={className} colSpan={quantityColSpan}>
      <Grid container item alignItems="center" justifyContent="flex-start">
        {t('Common:QUANTITY')}
        {isEstimate && !prePaidEnabled && (
          <RangeButton
            classes={{
              rangeSpan: classNames({
                [classes.hidden]: !hasItems,
              }),
            }}
            isSingleQuantity={!rangeEnabled}
            toggleRange={toggleRange}
          />
        )}
      </Grid>
    </TableCell>
  )
}

export default QuantityCellHead
