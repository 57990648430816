import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps } from '@pbt/pbt-ui-components'

import EmailPostedInvoice, {
  EmailPostedInvoiceProps,
} from './EmailPostedInvoice'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
  }),
  { name: 'EmailPostedInvoiceDialog' },
)

export interface EmailPostedInvoiceDialogProps
  extends EmailPostedInvoiceProps,
    PuiDialogProps {}

const EmailPostedInvoiceDialog = ({
  open,
  onClose,
  onOk,
  ...rest
}: EmailPostedInvoiceDialogProps) => {
  const classes = useStyles()

  const handleOk = () => {
    if (onOk) {
      onOk()
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="email-posted-invoice-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <EmailPostedInvoice onOk={handleOk} {...rest} />
    </PuiDialog>
  )
}

export default EmailPostedInvoiceDialog
