import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Nil,
  PuiTextField,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { useCreatePatient } from '~/store/hooks/patient'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatientsIsLoading } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'

import PatientDialog from '../patient/PatientDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
      position: 'relative',
    },
    button: {
      marginTop: theme.spacing(2),
      height: 40,
      minWidth: 150,
    },
    input: {
      width: 160,
    },
  }),
  { name: 'NewPatient' },
)

const getPatientId = (client: User | Nil) => {
  if (client?.patients && client.patients.length > 0) {
    return client.patients[0]
  }
  return null
}

interface NewPatientProps {
  clientId: string
}

const NewPatient = ({ clientId }: NewPatientProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { createPatient } = useCreatePatient()
  const { t } = useTranslation(['Common', 'Clients'])

  const patientsIsLoading = useSelector(getPatientsIsLoading)
  const client = useSelector(getUser(clientId))

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )
  const showAddPatient = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const [name, setName] = useState('')
  const [patientDialogOpen, setPatientDialogOpen] = useState(false)
  const [patientCreation, setPatientCreation] = useState(false)

  useEffect(() => {
    const id = getPatientId(client)
    if (id) {
      if (patientCreation) {
        setPatientDialogOpen(true)
      } else {
        navigate(`/client/${clientId}/patient/${id}`)
      }
    } else {
      navigate(`/client/${clientId}/nopet`)
    }
  }, [client, patientCreation, patientDialogOpen, patientsIsLoading])

  return (
    <Grid container className={classes.root} p={5}>
      <Grid item xs={12}>
        <Text>
          {showAddPatient
            ? t('Clients:NEW_PATIENT.HAS_NO_PATIENT', {
                clientFirstName: client?.firstName,
              })
            : t('Clients:NO_PATIENTS', {
                clientFirstName: client?.firstName,
              })}
        </Text>
      </Grid>
      {showAddPatient && (
        <Grid item xs={12}>
          <PuiTextField
            className={classes.input}
            label={t('Common:PATIENT_NAME')}
            value={name}
            onChange={Utils.handleFormTextInput(setName)}
          />
        </Grid>
      )}
      {showAddPatient && (
        <Grid item xs={12}>
          <ButtonWithLoader
            className={classes.button}
            loading={patientsIsLoading}
            onClick={() => {
              setPatientCreation(true)
              createPatient(clientId, { name })
            }}
          >
            {t('Common:ADD_PATIENT')}
          </ButtonWithLoader>
        </Grid>
      )}
      {showAddPatient && (
        <PatientDialog
          clientId={clientId}
          createPatient={createPatient}
          open={patientDialogOpen}
          patientId={getPatientId(client) || undefined}
          onClose={() => {
            setPatientCreation(false)
            setPatientDialogOpen(false)
          }}
        />
      )}
    </Grid>
  )
}

export default NewPatient
