import {
  all,
  call,
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import {
  ApiError,
  BreedConstant,
  Constant,
  Defaults,
  Nil,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { CustomEmailValidationType } from '@pbt/pbt-ui-components/src/constants/users'

import * as API from '~/api'
import AssetDestination from '~/constants/AssetDestination'
import DialogNames from '~/constants/DialogNames'
import SnackNotificationType from '~/constants/SnackNotificationType'
import i18n from '~/locales/i18n'
import { openDialog } from '~/store/duck/dialogs'
import { getSpeciesFromFieldsQuery } from '~/utils'

import {
  createClient,
  createClientFailure,
  createClientSuccess,
  fetchClient,
  fetchClientError,
  fetchClientsList,
  fetchClientsListFailure,
  fetchClientsListSuccess,
  fetchClientsMembershipFiltersFailure,
  fetchClientsMembershipFiltersSuccess,
  fetchClientSuccess,
  fetchMoreItemsForClientsList,
  fetchMoreItemsForClientsListFailure,
  fetchMoreItemsForClientsListSuccess,
  findClients,
  findClientsFailure,
  findClientsSuccess,
  partialUpdateClient,
  sendClientEmailVerification,
  sendClientEmailVerificationFailure,
  sendClientEmailVerificationSuccess,
  updateClient,
  updateClientActivityState,
  updateClientActivityStateFailure,
  updateClientActivityStateSuccess,
  updateClientFailure,
  updateClientSuccess,
  validateClientEmail,
  validateClientEmailFailure,
  validateClientEmailSuccess,
} from '../actions/clients'
import { createPatientSuccess } from '../actions/patients'
import { updateSearchHighlights } from '../actions/search'
import {
  CREATE_CLIENT,
  FETCH_CLIENT,
  FETCH_CLIENTS_LIST,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST,
  FIND_CLIENTS,
  PARTIAL_UPDATE_CLIENT,
  SEND_CLIENT_EMAIL_VERIFICATION,
  UPDATE_CLIENT,
  UPDATE_CLIENT_ACTIVITY_STATE,
  VALIDATE_CLIENT_EMAIL,
} from '../actions/types/clients'
import { updateUser } from '../actions/users'
import { uploadImageAssetAndGenerateThumbnail } from '../duck/files'
import { finishLoading, startLoading } from '../duck/progress'
import { addUiNotification } from '../duck/uiNotifications'
import {
  getBreed,
  getGender,
  getServiceAnimal,
  getSpecies,
  getTag,
} from '../reducers/constants'
import { getUser } from '../reducers/users'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'
import { validateClientEmailWithCommonMistakes } from './utils/users'

export function* createClientSaga({
  client,
  verify,
}: ReturnType<typeof createClient>) {
  try {
    const { entities, result } = yield* requestAPI(
      API.createClient,
      client,
      verify,
    )
    yield call(updateEntities, entities)

    const clientId = result.entity || result
    const patientId = entities.patients && Object.keys(entities.patients)[0]

    if (patientId) {
      yield put(createPatientSuccess(clientId, patientId))
    }
    yield put(createClientSuccess(clientId))

    if (client.email) {
      yield call(validateClientEmailWithCommonMistakes, clientId)
    }
  } catch (error) {
    yield put(createClientFailure(error as ApiError))
  }
}

export function* findClientsSaga({
  email,
  mobilePhone,
}: ReturnType<typeof findClients>) {
  try {
    const { result, entities } = yield* requestAPI(API.findClients, {
      email: email || undefined,
      mobilePhone,
    })
    yield call(updateEntities, entities)
    yield put(findClientsSuccess(result))
  } catch (error) {
    yield put(findClientsFailure(error as ApiError))
  }
}

export function* updateClientSaga({
  client,
  avatarBlob,
}: ReturnType<typeof updateClient>) {
  yield delay(Defaults.DEBOUNCE_ACTION_TIME)
  try {
    if (avatarBlob) {
      const image = yield* uploadImageAssetAndGenerateThumbnail({
        blob: avatarBlob,
        destination: AssetDestination.PERSON,
        payload: {
          personId: client.id,
          isPublic: true,
        },
      })

      client.photo = image.imageUrl
      client.photoThumbnail = image.imageThumbnailUrl
    }

    const { entities } = yield* requestAPI(API.updateClient, client)
    yield call(updateEntities, entities)
    yield put(updateClientSuccess())

    if (client.email) {
      yield call(validateClientEmailWithCommonMistakes, client.id)
    }
  } catch (error) {
    yield put(updateClientFailure(error as ApiError))
  }
}

export function* partialUpdateClientSaga({
  client,
  avatarBlob,
}: ReturnType<typeof partialUpdateClient>) {
  try {
    if (avatarBlob) {
      const image = yield* uploadImageAssetAndGenerateThumbnail({
        blob: avatarBlob,
        destination: AssetDestination.PERSON,
        payload: {
          personId: client.id,
          isPublic: true,
        },
      })
      client.photo = image.imageUrl
      client.photoThumbnail = image.imageThumbnailUrl
    }

    const { entities } = yield* requestAPI(API.partialUpdateClient, client)
    yield call(updateEntities, entities)
    yield put(updateClientSuccess())

    if (client.email) {
      yield call(validateClientEmailWithCommonMistakes, client.id)
    }
  } catch (error) {
    yield put(updateClientFailure(error as ApiError))
  }
}

export function* updateClientActivityStateSaga({
  clientId,
  clientActivityState,
}: ReturnType<typeof updateClientActivityState>) {
  yield delay(Defaults.DEBOUNCE_ACTION_TIME)
  try {
    const { entities } = yield* requestAPI(
      API.updateClientActiveState,
      clientId,
      clientActivityState,
    )
    yield call(updateEntities, entities)
    yield put(updateClientActivityStateSuccess())
  } catch (error) {
    yield put(updateClientActivityStateFailure(error as ApiError))
  }
}

export function* fetchClientSaga({
  clientId,
  patientId,
  withGlobalClientPreferences = false,
}: ReturnType<typeof fetchClient>) {
  try {
    const { entities } = clientId
      ? yield* requestAPI(
          API.fetchClient,
          clientId,
          withGlobalClientPreferences,
        )
      : patientId
        ? yield* requestAPI(API.fetchClientByPatientId, patientId)
        : undefined

    yield call(updateEntities, entities)
    yield put(fetchClientSuccess())
  } catch (error) {
    const apiError = error as ApiError
    if (apiError.status === 403) {
      const customError = {
        response: {
          config: {
            url: '/landing/',
          },
        },
      } as ApiError

      yield put(
        openDialog({
          name: DialogNames.MISSING_RESOURCE,
          id: uuid(),
          props: {
            error: customError,
            customMessage: i18n.t('Clients:ERRORS.HAS_NO_ACCESS'),
          },
          unique: true,
        }),
      )
    }
    yield put(fetchClientError(apiError))
  }
}

// This function is implemented because of legacy aspects of search implementation
// on the backend side
// Here we need to add set of keys in order to resolve
// set of values passed to search query into the ids
function* fixSearchQuery(fieldsQuery: string | Nil) {
  if (!fieldsQuery) {
    return ''
  }
  const Species: Constant[] = yield select(getSpecies)
  const Gender: Constant[] = yield select(getGender)
  const BreedsMap: BreedConstant = yield select(getBreed)
  const ServiceAnimal: Constant[] = yield select(getServiceAnimal)
  const Tag: Constant[] = yield select(getTag)

  const { speciesId } = getSpeciesFromFieldsQuery(fieldsQuery, Species)
  // We need to use right list of breeds related to selected specie
  const Breeds = BreedsMap[speciesId]

  const fieldsToFix: Record<string, [string, Constant[]]> = {
    'patients.species': ['patients.speciesId', Species],
    'patients.gender': ['patients.genderId', Gender],
    'patients.serviceAnimalType': [
      'patients.serviceAnimalTypeId',
      ServiceAnimal,
    ],
    tag: ['tagId', Tag],
  }

  return fieldsQuery
    .split(';')
    .reduce((result, fieldPair) => {
      const [key, value] = fieldPair.split('=')
      const fixer = fieldsToFix[key]
      // We're resolving breeds into the list of breed ids
      if (key === 'patients.breeds' && Breeds) {
        const breedIds = value
          .split(',')
          .map((_) => Utils.findConstantIdByName(_, Breeds))
          .filter(Boolean)
        return [...result, fieldPair, `patients.breedIds=${breedIds.join(',')}`]
      }
      // Other fields are getting resolved into the single ids
      // according to the config provided in fieldsToFix map
      if (fieldsToFix[key]) {
        const id = Utils.findConstantIdByName(value, fixer[1])
        if (id) {
          return [...result, fieldPair, `${fixer[0]}=${id}`]
        }
      }
      return [...result, fieldPair]
    }, [] as string[])
    .join(';')
}

export function* fetchClientsListSaga({
  from,
  to,
  query,
  fieldsQuery,
  showLastAppointment,
  includeInactive,
  sharedOnly,
  groupSearchRequire,
}: ReturnType<typeof fetchClientsList>) {
  try {
    yield put(startLoading('clients'))
    const fixedQuery: string = yield fixSearchQuery(fieldsQuery)
    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield* requestAPI(
      API.fetchClients,
      from,
      to,
      query,
      fixedQuery,
      showLastAppointment,
      includeInactive,
      sharedOnly,
      groupSearchRequire,
    )

    yield call(updateEntities, entities)
    yield put(updateSearchHighlights(highlights, totalCount))
    yield put(fetchClientsListSuccess(list, totalCount))
    yield put(finishLoading('clients'))
  } catch (error) {
    yield put(fetchClientsListFailure(error as ApiError))
    yield put(finishLoading('clients'))
  }
}

export function* fetchMoreItemsForClientsListSaga({
  from,
  to,
  query,
  fieldsQuery,
  showLastAppointment,
  includeInactive,
  sharedOnly,
  groupSearchRequire,
}: ReturnType<typeof fetchMoreItemsForClientsList>) {
  try {
    const fixedQuery: string = yield fixSearchQuery(fieldsQuery)
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.fetchClients,
      from,
      to,
      query,
      fixedQuery,
      showLastAppointment,
      includeInactive,
      sharedOnly,
      groupSearchRequire,
    )

    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForClientsListSuccess(list, totalCount, from))
  } catch (error) {
    yield put(fetchMoreItemsForClientsListFailure(error as ApiError))
  }
}

export function* fetchClientsMembershipFiltersSaga() {
  try {
    const filters = yield* requestAPI(API.fetchClientsMembershipFilters)
    yield put(fetchClientsMembershipFiltersSuccess(filters))
  } catch (error) {
    yield put(fetchClientsMembershipFiltersFailure(error as ApiError))
  }
}

export function* validateClientEmailSaga({
  clientId,
}: ReturnType<typeof validateClientEmail>) {
  try {
    const { didYouMean, type } = yield* requestAPI(
      API.validateClientEmail,
      clientId,
    )

    yield put(
      updateUser({
        id: clientId,
        emailValidationResultType: type,
        emailValidationSuggestion: didYouMean,
      }),
    )
    yield put(validateClientEmailSuccess())
  } catch (error) {
    yield put(
      updateUser({
        id: clientId,
        emailValidationResultType: CustomEmailValidationType.ERROR,
      }),
    )
    yield put(validateClientEmailFailure(error as ApiError))
  }
}

function* showClientEmailVerificationSentNotification(clientId: string) {
  const client: User = yield select(getUser(clientId))
  yield put(
    addUiNotification({
      id: uuid(),
      message: i18n.t('Clients:SENT_CLIENT_EMAIL_VERIFICATION_LINK', {
        clientName: client?.firstName,
      }),
      type: SnackNotificationType.INFO,
    }),
  )
}

export function* sendClientEmailVerificationSaga({
  clientId,
}: ReturnType<typeof sendClientEmailVerification>) {
  try {
    yield* requestAPI(API.sendClientEmailVerification, clientId)
    yield showClientEmailVerificationSentNotification(clientId)
    yield put(sendClientEmailVerificationSuccess())
  } catch (error) {
    yield put(sendClientEmailVerificationFailure(error as ApiError))
  }
}

function* watchFetchClient() {
  yield takeLeading(FETCH_CLIENT, fetchClientSaga)
}

function* watchUpdateClient() {
  yield takeLatest(UPDATE_CLIENT, updateClientSaga)
}

function* watchPartialUpdateClient() {
  yield takeLatest(PARTIAL_UPDATE_CLIENT, partialUpdateClientSaga)
}

function* watchUpdateClientActivityStatus() {
  yield takeLatest(UPDATE_CLIENT_ACTIVITY_STATE, updateClientActivityStateSaga)
}

function* watchCreateClient() {
  yield takeLeading(CREATE_CLIENT, createClientSaga)
}

function* watchFindClients() {
  yield takeLeading(FIND_CLIENTS, findClientsSaga)
}

function* watchFetchClientsList() {
  yield takeLatest(FETCH_CLIENTS_LIST, fetchClientsListSaga)
}

function* watchFetchMoreItemsForClientsList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_CLIENTS_LIST,
    fetchMoreItemsForClientsListSaga,
  )
}

function* watchFetchClientsMembershipFilters() {
  yield takeLeading(
    FETCH_CLIENTS_MEMBERSHIP_FILTERS,
    fetchClientsMembershipFiltersSaga,
  )
}

function* watchValidateClientEmail() {
  yield takeLeading(VALIDATE_CLIENT_EMAIL, validateClientEmailSaga)
}

function* watchSendClientEmailVerification() {
  yield takeLatest(
    SEND_CLIENT_EMAIL_VERIFICATION,
    sendClientEmailVerificationSaga,
  )
}

export default function* clientsSaga() {
  yield all([
    watchCreateClient(),
    watchFindClients(),
    watchUpdateClient(),
    watchPartialUpdateClient(),
    watchFetchClient(),
    watchFetchClientsList(),
    watchFetchMoreItemsForClientsList(),
    watchUpdateClientActivityStatus(),
    watchFetchClientsMembershipFilters(),
    watchValidateClientEmail(),
    watchSendClientEmailVerification(),
  ])
}
