import React from 'react'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  ControlButtonGroupProps,
  DocumentFile,
} from '@pbt/pbt-ui-components'

import ControlDownloadButton from '../../buttons/ControlDownloadButton'

export interface AttachmentFileControlButtonsProps {
  classes?: ControlButtonGroupProps['classes']
  deleteDisabled?: boolean
  file: DocumentFile['file']
  hideDelete?: boolean
  hideDownload?: boolean
  hideEmail?: boolean
  hidePreview?: boolean
  hidePrint?: boolean
  onDeleteRequested?: () => void
  onDownloadRequested?: () => void
  onEmailRequested?: () => void
  onPreviewRequested?: () => void
  onPrintRequested?: () => void
}

const AttachmentFileControlButtons = ({
  onDownloadRequested,
  onDeleteRequested,
  onPreviewRequested,
  onPrintRequested,
  onEmailRequested,
  deleteDisabled,
  hideDownload = false,
  hideDelete = false,
  hidePreview = false,
  hidePrint = false,
  hideEmail = false,
  file,
  classes,
}: AttachmentFileControlButtonsProps) => (
  <ControlButtonGroup
    buttons={[
      !hidePreview &&
        onPreviewRequested && {
          name: ControlButtonGroupName.PREVIEW,
          onClick: onPreviewRequested,
        },
      !hideDownload &&
        onDownloadRequested &&
        Boolean(file?.fileUrl) && {
          name: ControlButtonGroupName.DOWNLOAD,
          renderer: (button) => (
            <ControlDownloadButton button={button} file={file} />
          ),
          onClick: onDownloadRequested,
        },
      !hidePrint &&
        onPrintRequested && {
          name: ControlButtonGroupName.PRINT,
          onClick: onPrintRequested,
        },
      !hideEmail &&
        onEmailRequested && {
          name: ControlButtonGroupName.EMAIL,
          onClick: onEmailRequested,
        },
      !hideDelete &&
        onDeleteRequested && {
          name: ControlButtonGroupName.DELETE,
          disabled: deleteDisabled,
          onClick: onDeleteRequested,
        },
    ]}
    classes={classes}
  />
)

export default AttachmentFileControlButtons
