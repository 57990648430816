import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCheckbox } from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'

import { getLocationDisplayName } from '~/store/reducers/constants'
import { DiagnoseLocation } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    checkboxLabel: {
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'DiffDxLocationItem' },
)

export interface DiffDxLocationItemProps {
  handleAddNotes: (location: DiagnoseLocation) => () => void
  locationId: string
  locations: DiagnoseLocation[]
  onLocationColumnItemClick: (locationId: string) => void
}

const DiffDxLocationItem = ({
  locationId,
  onLocationColumnItemClick,
  handleAddNotes,
  locations,
}: DiffDxLocationItemProps) => {
  const classes = useStyles()
  const locationName = useSelector(getLocationDisplayName(locationId))

  const getNotesValueByLocationId = (id: string) => {
    const currentLocation = locations.find((location) => location.id === id)
    return currentLocation ? currentLocation.notes : ''
  }

  const getLocationState = (id: string) =>
    locations.filter((locationState) => locationState.id === id).length > 0

  const notes = getNotesValueByLocationId(locationId)
  const isChecked = getLocationState(locationId)

  return (
    <Grid item>
      <PuiCheckbox
        checked={isChecked}
        className={classes.checkboxLabel}
        label={locationName}
        onChange={() => onLocationColumnItemClick(locationId)}
      />
      {isChecked && (
        <IconButton onClick={handleAddNotes({ id: locationId, notes })}>
          <AddNote filled={Boolean(notes)} />
        </IconButton>
      )}
    </Grid>
  )
}

export default DiffDxLocationItem
