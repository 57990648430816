import React from 'react'
import { TableCell } from '@mui/material'

export interface ItemTotalCellSubHeadProps {
  className?: string
}

const ItemTotalCellSubHead = ({ className }: ItemTotalCellSubHeadProps) => (
  <TableCell className={className} />
)

export default ItemTotalCellSubHead
