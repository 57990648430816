import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { POST_REFUND_INVOICE, VOID_REFUND_INVOICE } from './graphql/mutations'
import { CALCULATE_REFUND, FETCH_REFUND_INVOICE } from './graphql/queries'

export const calculateRefundAmount = requestQuery({
  query: CALCULATE_REFUND,
  variablesHandler: (_, input) => ({
    input,
  }),
})

export const fetchRefundInvoice = requestQuery({
  query: FETCH_REFUND_INVOICE,
  variablesHandler: (_, id) => ({
    id,
  }),
})

export const postRefundInvoice = requestMutation({
  mutation: POST_REFUND_INVOICE,
  variablesHandler: (_, input) => ({
    input,
  }),
})

export const voidRefundInvoice = requestMutation({
  mutation: VOID_REFUND_INVOICE,
  variablesHandler: (_, id) => ({
    id,
  }),
})
