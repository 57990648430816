import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  Business,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import PreviewWellnessPromotion from './PreviewWellnessPromotion'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 600,
      maxWidth: 600,
    },
    promotionContainer: {
      width: 400,
    },
    promotion: {
      zoom: 0.75,
    },
  }),
  { name: 'PreviewWellnessPromotionDialog' },
)

interface PreviewWellnessPromotionDialogProps extends BasePuiDialogProps {
  business: Business
  showBoopDescription: boolean
}

const PreviewWellnessPromotionDialog = ({
  business,
  showBoopDescription,
  open,
  onClose,
}: PreviewWellnessPromotionDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  return (
    <PuiDialog
      aria-labelledby="preview-wellness-promotion-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid container item direction="column" p={3}>
        <Text mb={1} variant="h2">
          {t('Businesses:WELLNESS_PLANS.PREVIEW_WELLNESS_PROMOTION')}
        </Text>
        <Text mb={2}>
          {t(
            'Businesses:WELLNESS_PLANS.PREVIEW_INCLUDES_SAMPLE_COMMUNICATION_ACTUAL_PROMOTIONAL_CONTENT_WILL_VARY',
          )}
        </Text>
        <Grid container item justifyContent="center">
          <Grid item className={classes.promotionContainer}>
            <PreviewWellnessPromotion
              business={business}
              className={classes.promotion}
              showBoopDescription={showBoopDescription}
            />
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default PreviewWellnessPromotionDialog
