import React, { useCallback, useState } from 'react'

import { MigrationSteps } from '~/constants/migrationV2'

export type MigrationStep = {
  migrationStep: MigrationSteps
  props?: any
}

export type MigrationStepController = {
  currentStep: MigrationSteps
  next: () => void
  props: any
  setCurrentStepOverride: React.Dispatch<
    React.SetStateAction<MigrationStep | undefined>
  >
}

export const useMigrationStepController = (steps: MigrationStep[]) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [currentStepOverride, setCurrentStepOverride] =
    useState<MigrationStep>()

  // Clears step override if there is any and moves to the next step index
  const next = useCallback(() => {
    if (currentStepOverride) {
      setCurrentStepOverride(undefined)
    }

    const nextStepIndex = currentStepIndex + 1
    if (nextStepIndex < steps.length) {
      setCurrentStepIndex(nextStepIndex)
    } else {
      setCurrentStepIndex(-1)
    }
  }, [steps, currentStepIndex, currentStepOverride])

  return {
    currentStep:
      currentStepOverride?.migrationStep ||
      steps[currentStepIndex]?.migrationStep,
    props: currentStepOverride?.props || {},
    setCurrentStepOverride,
    next,
  }
}
