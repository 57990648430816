import moment from 'moment'
import * as R from 'ramda'

import { Prescription } from '~/types'

import { getCreateDefaultQuantityDrugExpDate } from './orderUtils'

export const fixPrescriptionTemplate = (
  rawTemplate: Prescription,
  outsideSoap: boolean,
) => {
  const isQuantityDrugExpDateLessThanYear =
    moment(rawTemplate.quantityDrugExpirationDate).diff(
      moment(),
      'year',
      true,
    ) < 1

  const fixedTemplate: Prescription = {
    ...rawTemplate,
    quantityDrugExpirationDate: isQuantityDrugExpDateLessThanYear
      ? getCreateDefaultQuantityDrugExpDate()
      : rawTemplate.quantityDrugExpirationDate,
  }

  if (outsideSoap) {
    return R.omit(
      ['administrationQuantity', 'quantity', 'totalQuantity'],
      fixedTemplate,
    )
  }
  return R.omit(
    ['administrationQuantity', 'quantity', 'totalQuantity', 'doctorId'],
    fixedTemplate,
  )
}
