import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { BodySystemState } from '~/constants/SOAPStates'
import { getProblemBodySystemStates } from '~/store/reducers/constants'
import {
  getProblemCatalog,
  getProblemsBodySystemLogsMap,
} from '~/store/reducers/problems'

const useGetBodySystemStatus = () => {
  const catalog = useSelector(getProblemCatalog)
  const problemBodySystemStates = useSelector(getProblemBodySystemStates)
  const bodySystemLogs = useSelector(getProblemsBodySystemLogsMap)

  const catalogProblems = catalog?.problems

  return useCallback(
    (problemId: string): BodySystemState | null => {
      if (catalogProblems && catalogProblems[problemId]) {
        const problem = catalogProblems[problemId]
        const bodySystemLog = bodySystemLogs?.[problem.bodySystemId]

        if (bodySystemLog) {
          return Utils.findById(
            bodySystemLog.entity.stateId,
            problemBodySystemStates,
          ).name
        }
      }

      return null
    },
    [catalogProblems, bodySystemLogs, problemBodySystemStates],
  )
}

export default useGetBodySystemStatus
