import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ContentBlock, ContentState } from 'draft-js'

export const LINK = 'LINK'

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === LINK
    )
  }, callback)
}

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.colors.link,
    },
  }),
  { name: 'Link' },
)

export interface LinkProps {
  children: React.ReactNode
  contentState: ContentState
  entityKey: string
}

const Link = ({ children, entityKey, contentState }: LinkProps) => {
  const classes = useStyles()

  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <a className={classes.link} href={url}>
      {children}
    </a>
  )
}

export default {
  strategy: findLinkEntities,
  component: Link,
}
