import React from 'react'
import { useSelector } from 'react-redux'
import { Constant } from '@pbt/pbt-ui-components'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getBusinessStatus } from '~/store/reducers/constants'

import PracticeStatusLabel from './PracticeStatusLabel'

export interface PracticesStatusFilterProps extends CheckboxListFilterProps {}

const PracticesStatusFilter = (props: PracticesStatusFilterProps) => {
  const BusinessStatus: Constant[] = useSelector(getBusinessStatus)
  const filterStatusItems = BusinessStatus.map((item) => ({
    ...item,
    component: <PracticeStatusLabel statusId={item.id} variant="big" />,
  }))

  return <CheckboxListFilter items={filterStatusItems} {...props} />
}

export default PracticesStatusFilter
