import i18n from '~/locales/i18n'

export const WellnessPlanLevels = {
  BASE: 0,
  PLAN_ONE: 1,
  PLAN_TWO: 2,
  PLAN_THREE: 3,
}

export const WellnessPlanLevelsLabels = {
  [WellnessPlanLevels.BASE]: 'Base',
  [WellnessPlanLevels.PLAN_ONE]: 'Plan 1',
  [WellnessPlanLevels.PLAN_TWO]: 'Plan 2',
  [WellnessPlanLevels.PLAN_THREE]: 'Plan 3',
}

export const TranslatedWellnessPlanLevelsLabels = {
  [WellnessPlanLevels.BASE]: i18n.t('Common:BASE'),
  [WellnessPlanLevels.PLAN_ONE]: `${i18n.t('Common:PLAN')} 1`,
  [WellnessPlanLevels.PLAN_TWO]: `${i18n.t('Common:PLAN')} 2`,
  [WellnessPlanLevels.PLAN_THREE]: `${i18n.t('Common:PLAN')} 3`,
}

export enum WellnessPlanCoverAppointmentTypes {
  NONE = 'NONE',
  REGULAR = 'REGULAR',
  TELEHEALTH = 'TELEHEALTH',
}

export const REQUIRED_TIER_BENEFIT_COUNT = 1
export const REQUIRED_BASE_TIER_BENEFIT_COUNT = 1
export const REQUIRED_EXTRA_BENEFIT_COUNT = 1
export const REQUIRED_PACKAGE_BENEFIT_COUNT = 1

export enum BenefitPlanType {
  BASE = 'BASE',
  ADDON = 'ADDON',
}

export enum MembershipType {
  ADDON = 'ADDON',
  PACKAGE = 'PACKAGE',
  BASE = 'BASE',
  EXTRA = 'EXTRA',
}

export const MembershipAutoRenewalAllowed = {
  [MembershipType.ADDON]: true,
  [MembershipType.PACKAGE]: true,
  [MembershipType.BASE]: false,
  [MembershipType.EXTRA]: false,
}

export enum PointsType {
  EARN = 'Earn',
  REDEEM = 'Redeem',
}

export const MembershipFilters = {
  WELLNESS_PLAN_LEVEL: 'patients.wplanLevel',
  WELLNESS_PLAN_STATUS: 'patients.wplanStatus',
  WELLNESS_PLAN_CANCELLED: 'patients.wplanCancelled',
  MEMBERSHIP_STATUS: 'patients.wplanMember',
  WELLNESS_PLAN_VERSION: 'patients.wplanVersion',
  WELLNESS_PLAN_ID: 'patients.wplanId',
}

export const MembershipFiltersList = Object.values(MembershipFilters)

export const BenefitNames = {
  ACCESS_TO_MOBILE_APP: 'Access to mobile app',
  OFFICE_VISITS: 'Office visits',
}

export enum PlanTypeDisplay {
  SUBSCRIBE_AND_SAVE = 'Subscribe-and-save',
  EXTRA = 'Extra',
}
