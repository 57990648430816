import { FileLoader } from '@ckeditor/ckeditor5-upload/src/filerepository'
import axios from 'axios'
import { Utils } from '@pbt/pbt-ui-components'

import { reserveAssetUpload } from '~/api'
import AssetDestination from '~/constants/AssetDestination'
import { fileContentTypeHeaders } from '~/store/duck/files'

interface ReserveAssetUploadResponse {
  fileUrl: string | undefined
  uploadUrl: string | undefined
}
export class ImageUploadAdapter {
  loader: FileLoader

  businessId: string

  controller: AbortController

  constructor(loader: FileLoader, businessId: string) {
    // The file loader instance to use during the upload.
    this.loader = loader
    // controls which bucket we'll store the image data (assets/businesses/{businessId})
    this.businessId = businessId
    // This is used to abort a request
    this.controller = new AbortController()
  }

  private async reserveAssetUploadUrl() {
    try {
      const response: ReserveAssetUploadResponse = await reserveAssetUpload(
        this.businessId,
        AssetDestination.CKEDITOR,
        {
          isPublic: true,
          // @ts-ignore
          signal: this.controller.signal,
          ...(Utils.isLocalEnvironment() ? { prefix: 'text-ckeditor-' } : {}),
        },
      )
      return response
    } catch (error) {
      throw new Error(`Upload failed - ${(error as any).message}`)
    }
  }

  private async uploadFile(
    reserveAssetUploadResponse: ReserveAssetUploadResponse,
    file: File,
  ) {
    try {
      if (
        !reserveAssetUploadResponse.uploadUrl ||
        !reserveAssetUploadResponse.fileUrl
      ) {
        throw new Error(
          `Something went wrong reserving asset url for ${file.name}`,
        )
      }

      await axios.put(reserveAssetUploadResponse.uploadUrl, file, {
        headers: fileContentTypeHeaders(file),
        // This is important to get the progress and tell ckeditor its percentage
        onUploadProgress: (event: any) => {
          this.loader.uploadTotal = event.total
          this.loader.uploaded = event.loaded
        },
      })

      return { default: reserveAssetUploadResponse.fileUrl }
    } catch (error) {
      throw new Error(
        `Something went wrong while uploading ${file.name} with ${reserveAssetUploadResponse.uploadUrl}`,
      )
    }
  }

  private async reserveUrlAndUpload(
    file: File,
    resolve: (
      value: { default: string } | PromiseLike<{ default: string }>,
    ) => void,
    reject: (reason?: any) => void,
  ) {
    try {
      const reserveAssetUploadResponse = await this.reserveAssetUploadUrl()
      const finalResponse = await this.uploadFile(
        reserveAssetUploadResponse,
        file,
      )
      resolve(finalResponse)
    } catch (error) {
      reject(error)
    }
  }

  // Starts the upload process, in this step there're a few important points:
  // 1- The image being uploaded must contain an extension "some-image.jpeg"
  // 2- First we get a file url by reserving an url using "reserveAssetUpload" method
  // 3- Then we need to actually save the file using the uploadUrl returned from the methor above
  // 4- Finally we need to resolve the promise to actually save the fileUrl with CKEditor
  upload() {
    return this.loader.file.then(
      (file: File | null) =>
        new Promise<{ default: string }>((resolve, reject) => {
          const data = new FormData()

          if (!file) {
            reject('Missing file')
            return
          }

          data.append('upload', file)

          this.reserveUrlAndUpload(file, resolve, reject)
        }),
    )
  }

  // Aborts the upload process.
  abort() {
    this.controller.abort()
    // eslint-disable-next-line no-console
    console.error('Upload image has been aborted')
  }
}
