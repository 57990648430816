import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import {
  deleteItemByPrice,
  mergeItemsWithQuantitySum,
} from '~/components/dashboard/invoices/invoiceUtils'
import { createBundle } from '~/store/actions/bundles'
import {
  getBundlesIsCreating,
  getLastCreatedBundleId,
} from '~/store/reducers/bundles'
import { Bundle as BundleType, BundleItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import Bundle, { BundleHandle } from './Bundle'
import BundleItems, { BundleItemsHandle } from './BundleItems'
import FinalizeBundle, { FinalizeBundleHandle } from './FinalizeBundle'

const useStyles = makeStyles(
  () => ({
    defaultPaper: {
      width: 650,
      maxWidth: 650,
    },
    nonDefaultPaper: {
      width: 976,
      maxWidth: 976,
    },
    finalizePaper: {
      width: 'calc(100% - 96px)',
      maxWidth: 1308,
    },
  }),
  { name: 'BundleDialog' },
)

export enum Steps {
  INITIAL = 'INITIAL',
  ITEMS_LIST = 'ITEMS_LIST',
  FINALIZE = 'FINALIZE',
}

const isEmptyBundle = (bundle: BundleType | Nil) =>
  Object.values(R.omit(['active'], bundle)).every(
    (value) => !value || R.isEmpty(value),
  )

interface BundleDialogProps extends BasePuiDialogProps {
  bundle: BundleType
  finalizeProceedButtonLabel?: string
  isClone?: boolean
  onBundleReady?: (bundle: BundleType) => void
  step: Steps
}

const BundleDialog = ({
  bundle,
  open,
  onBundleReady: onBundleReadyProp,
  step: stepProp,
  onClose,
  finalizeProceedButtonLabel,
  isClone = false,
}: BundleDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const lastCreatedBundleId = useSelector(getLastCreatedBundleId)
  const { t } = useTranslation(['Common', 'Dialogs'])

  const [bundleCandidate, setBundleCandidate] = useState(bundle)
  const [step, setStep] = useState(stepProp || Steps.INITIAL)

  const handleClose = () => {
    setStep(stepProp || Steps.INITIAL)
    setBundleCandidate(bundle)
    if (onClose) {
      onClose()
    }
  }

  const onBundleCreated = () => {
    handleClose()
    if (lastCreatedBundleId) {
      navigate(`/admin/catalog/bundles/${lastCreatedBundleId}`)
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onBundleCreated,
    getBundlesIsCreating,
  )

  useEffect(() => {
    setStep(stepProp || Steps.INITIAL)
  }, [stepProp])

  useEffect(() => {
    setBundleCandidate(bundle)
  }, [bundle])

  const onBundleCandidateReady = (newBundle: BundleType) => {
    setBundleCandidate(newBundle)
    setStep(Steps.ITEMS_LIST)
  }

  const onBundleItemsProceedRequested = (items: BundleItem[]) => {
    const newBundle = {
      ...bundleCandidate,
      items: bundleCandidate?.id
        ? mergeItemsWithQuantitySum(
            bundleCandidate?.items || [],
            items,
            R.path(['price', 'id']),
          )
        : items,
    }
    setBundleCandidate(newBundle)
    setStep(Steps.FINALIZE)
  }

  const onBundleItemsBackRequested = () => {
    if (stepProp) {
      handleClose()
    } else {
      setStep(Steps.INITIAL)
    }
  }

  const onFinalizeBundleBackRequested = () => {
    setStep(Steps.ITEMS_LIST)
  }

  const onFinalizeBundleProceedRequested = (newBundle: BundleType) => {
    if (onBundleReadyProp) {
      onBundleReadyProp(newBundle)
      handleClose()
    } else {
      setCloseAfterCreationOn()
      dispatch(createBundle(newBundle))
    }
  }

  const bundleRef = useRef<BundleHandle>(null)
  const bundleItemsRef = useRef<BundleItemsHandle>(null)
  const finalizeBundleRef = useRef<FinalizeBundleHandle>(null)

  const confirmCloseDialogProps =
    step !== Steps.FINALIZE
      ? {
          title: t('Dialogs:BUNDLE_DIALOG.CONFIRM_CLOSE_DIALOG_TITLE'),
          okLabel: t('Common:CONTINUE_ACTION'),
          notOkLabel: t('Common:EXIT_ACTION'),
        }
      : {
          onOk: () => {
            const candidate = finalizeBundleRef.current?.getCandidate()
            if (candidate) {
              onFinalizeBundleProceedRequested(candidate)
            }
          },
        }

  const hasChangesMap = {
    [Steps.INITIAL]: () => {
      const currentBundle = bundleRef.current?.getBundle()
      const hasUnsavedChanges = bundleRef.current?.hasUnsavedChanges()

      return Boolean(
        bundleCandidate ? hasUnsavedChanges : !isEmptyBundle(currentBundle),
      )
    },
    [Steps.ITEMS_LIST]: () => {
      const currentBundleItems = bundleItemsRef.current?.getItems()
      return Boolean(
        bundleCandidate && !bundle
          ? !isEmptyBundle(bundleCandidate)
          : currentBundleItems?.length && currentBundleItems.length > 0,
      )
    },
    [Steps.FINALIZE]: R.T,
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={confirmCloseDialogProps}
      aria-labelledby="bundle-dialog"
      classes={{
        paper: classNames(classes.defaultPaper, {
          [classes.nonDefaultPaper]:
            step === Steps.ITEMS_LIST || step === Steps.FINALIZE,
          [classes.finalizePaper]: step === Steps.FINALIZE,
        }),
      }}
      hasUnsavedChanges={hasChangesMap[step]}
      open={open}
      title={
        <>
          {step === Steps.INITIAL && t('Dialogs:BUNDLE_DIALOG.TITLE_INITIAL')}
          {step === Steps.ITEMS_LIST &&
            t('Dialogs:BUNDLE_DIALOG.TITLE_ITEMS_LIST')}
          {step === Steps.FINALIZE && t('Dialogs:BUNDLE_DIALOG.TITLE_FINALIZE')}
        </>
      }
      onClose={handleClose}
    >
      {step === Steps.INITIAL && (
        <Bundle
          bundle={bundleCandidate}
          ref={bundleRef}
          onProceed={
            isClone
              ? (newBundle) => onFinalizeBundleProceedRequested(newBundle)
              : onBundleCandidateReady
          }
        />
      )}
      {step === Steps.ITEMS_LIST && (
        <BundleItems
          bundle={{
            ...bundleCandidate,
            items: bundleCandidate?.id
              ? deleteItemByPrice(bundle?.items, bundleCandidate?.items)
              : bundleCandidate.items,
          }}
          ref={bundleItemsRef}
          onBack={onBundleItemsBackRequested}
          onProceed={onBundleItemsProceedRequested}
        />
      )}
      {step === Steps.FINALIZE && (
        <FinalizeBundle
          bundle={bundleCandidate}
          originalBundle={bundle}
          proceedButtonLabel={finalizeProceedButtonLabel}
          ref={finalizeBundleRef}
          onBack={onFinalizeBundleBackRequested}
          onProceed={onFinalizeBundleProceedRequested}
        />
      )}
    </PuiDialog>
  )
}

export default BundleDialog
