import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  ControlButtonGroup,
  ControlButtonGroupName,
  FieldProp,
  Nil,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import UserSelect, {
  UserSelectFilterScope,
} from '~/components/common/inputs/UserSelect'
import { DataHandleWithReset, ImagingOrder as ImagingOrderType } from '~/types'

import { BaseTemplateInputHandle } from '../template-inputs/BaseTemplateInput/BaseTemplateInput'
import NotesTemplateInput from '../template-inputs/NotesTemplateInput'
import ImagingStatusSelect from './ImagingStatusSelect'
import ModalitySelect from './ModalitySelect'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'ImagingOrder' },
)

const getFields = (order: Partial<ImagingOrderType> | Nil): FieldProp[] => [
  {
    name: 'name',
    initialValue: order?.name,
  },
  {
    name: 'modality',
    type: 'select',
    validators: ['required'],
    initialValue: order?.modalityId,
  },
  {
    name: 'assignedVet',
    type: 'select',
    validators: ['required'],
    initialValue: order?.assignedVetId,
  },
  {
    name: 'notes',
    initialValue: order?.notes || '',
  },
  {
    name: 'status',
    type: 'select',
    initialValue: order?.statusId,
  },
]

export interface ImagingOrderHandle
  extends DataHandleWithReset<Partial<ImagingOrderType>> {}

interface ImagingOrderProps {
  classes?: ClassesType<typeof useStyles>
  inline?: boolean
  isSoapFinalized?: boolean
  onDelete?: () => void
  order: Partial<ImagingOrderType> | Nil
  vendorId?: string
}

const ImagingOrder = forwardRef<ImagingOrderHandle, ImagingOrderProps>(
  (
    {
      classes: classesProp,
      inline = false,
      isSoapFinalized = false,
      onDelete,
      vendorId,
      order,
    },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp })
    const { t } = useTranslation('Common')

    const notesRef = useRef<BaseTemplateInputHandle>(null)
    const isExistingOrder = Boolean(order?.id)

    const { fields, validate, reset } = useFields(getFields(order))
    const { name, modality, assignedVet, notes, status } = fields

    useEffect(() => {
      reset()
      if (notesRef.current) {
        notesRef.current.setInitialValue(order?.notes)
      }
    }, [isExistingOrder])

    useImperativeHandle(ref, () => ({
      get: () => ({
        name: name.value || '',
        notes: notes.value || '',
        assignedVetId: assignedVet.value || null,
        modalityId: modality.value || null,
        statusId: status.value || null,
        logId: order?.logId || null,
        procedureId: order?.procedureId || null,
        procedureCode: order?.procedureCode || null,
        soapId: order?.soapId || null,
      }),
      validate,
      reset,
    }))

    return (
      <Grid
        container
        className={classes.root}
        direction="column"
        p={1}
        spacing={2}
      >
        <Grid container item alignItems="center" spacing={2}>
          <Grid container item xs={inline ? 4 : 10}>
            <PuiTextField
              field={name}
              label={t('Common:IMAGING_REQUEST_NAME')}
              margin="none"
            />
          </Grid>
          <Grid item xs={inline ? 4 : 7}>
            <ModalitySelect field={modality} vendorId={vendorId} />
          </Grid>
          <Grid container item xs>
            <UserSelect
              displayEmpty
              field={assignedVet}
              filterScope={UserSelectFilterScope.Doctors}
              label={t('Common:ASSIGN_TEAM_MEMBER')}
              readOnly={isExistingOrder}
            />
          </Grid>
          <ControlButtonGroup
            buttons={[
              onDelete && {
                name: ControlButtonGroupName.DELETE,
                onClick: onDelete,
              },
            ]}
          />
        </Grid>
        <Grid container item>
          <NotesTemplateInput
            hidePanel
            field={notes}
            minHeight={inline ? 1 : 2}
            placeholder={t('Common:NOTES')}
            ref={notesRef}
          />
        </Grid>
        {isExistingOrder && (
          <Grid container item>
            <ImagingStatusSelect
              field={status}
              isSoapFinalized={isSoapFinalized}
            />
          </Grid>
        )}
      </Grid>
    )
  },
)

export default ImagingOrder
