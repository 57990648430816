import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PermissionArea } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import ConversationStatusLabel from '~/components/dashboard/communications/common/ConversationStatusLabel'
import TransportIcon from '~/components/dashboard/communications/TransportIcon'
import { BaseRoute } from '~/constants/routes'
import { getCRUDByArea } from '~/store/reducers/auth'
import { ConversationTimelineItem } from '~/types'
import { getConversationStatus } from '~/utils/communicationsUtils'

import TimelineCard, { TimelineCardProps } from '../TimelineCard'
import TimelineCardActions from '../TimelineCardActions'
import TimelineCardContent from '../TimelineCardContent'

const useStyles = makeStyles(
  (theme) => ({
    transportIcon: {
      color: theme.colors.disabledLabelText,
      marginRight: theme.spacing(1),
      paddingBottom: '2px',
    },
  }),
  { name: 'ConversationCard' },
)

interface ConversationCardProps extends TimelineCardProps {
  item: ConversationTimelineItem
}

const ConversationCard = ({
  item: conversation,
  ...rest
}: ConversationCardProps) => {
  const classes = useStyles()
  const permissions = useSelector(getCRUDByArea(PermissionArea.COMMUNICATION))
  const { t } = useTranslation(['Common', 'Clients'])

  const { recentActivity, transport, id: conversationId } = conversation

  return (
    <TimelineCard
      {...conversation}
      originBusinessId={conversation.businessId}
      {...rest}
    >
      <TimelineCardContent>
        {t('Clients:TIMELINE.CONVERSATION_CARD.RECENT_ACTIVITY', {
          recentActivity,
        })}
      </TimelineCardContent>
      <TimelineCardActions
        customStateControl={
          <Grid container alignItems="center">
            <TransportIcon
              classes={{ transportIcon: classes.transportIcon }}
              type={transport}
            />
            <ConversationStatusLabel
              status={getConversationStatus(conversation)}
            />
          </Grid>
        }
        {...conversation}
      >
        {permissions.update && (
          <Link to={`${BaseRoute.COMMUNICATIONS}/${conversationId}`}>
            {t('Common:VIEW_CONVERSATION')}
          </Link>
        )}
      </TimelineCardActions>
    </TimelineCard>
  )
}

export default ConversationCard
