import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ColorSwatch,
  PermissionArea,
  PrimitiveTable,
  PrimitiveTableColumn,
  TableFilter,
  Text,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  fetchAppointmentTypesList,
  setAppointmentTypesListFilters,
  setAppointmentTypesListSortings,
} from '~/store/actions/appointmentTypes'
import {
  getAppointmentTypesIsLoading,
  getAppointmentTypesListFilters,
  getAppointmentTypesListSortings,
  getAppointmentTypesMap,
} from '~/store/reducers/appointmentTypes'
import { getCRUDByArea, getCurrentBusinessId } from '~/store/reducers/auth'
import {
  getAppointmentTypes,
  getFeatureToggle,
} from '~/store/reducers/constants'

import AppointmentConfigurationRow, {
  NEW_TYPE_PLACEHOLDER_ID,
} from './AppointmentConfigurationRow'
import AppointmentEventTypeFilter from './AppointmentEventTypeFilter'
import AppointmentTypeClientSchedulingFilter from './AppointmentTypeClientSchedulingFilter'
import AppointmentTypeColorFilter from './AppointmentTypeColorFilter'
import AppointmentTypeEnabledFilter from './AppointmentTypeEnabledFilter'
import {
  applyFilters,
  applySortings,
  Filters,
  getFilterValueStrings,
  getMapAsArray,
  Sortings,
} from './utils'

const useStyles = makeStyles(
  (theme) => ({
    tableHeader: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    addButton: {
      minWidth: 120,
      height: 40,
    },
    rowContainer: {
      border: 'none',
    },
    rowContainerNoColoring: {
      borderTop: 'none',
      borderBottom: 'none',
    },
  }),
  { name: 'AppointmentConfigurationPage' },
)

const columns: PrimitiveTableColumn[] = [
  {
    label: i18n.t('Common:COLOR'),
    filter: Filters.COLOR,
    FilterComponent: AppointmentTypeColorFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    filter: Filters.EVENT_TYPE_IDS,
    FilterComponent: AppointmentEventTypeFilter,
    width: 2.5,
  },
  {
    label: i18n.t('Common:DISPLAY_NAME'),
    prop: 'procedureShortDescription',
    width: 3.5,
    sorting: Sortings.ORDER_ONLY_BY_NAME_ASCENDING,
  },
  {
    label: i18n.t('Common:DURATION'),
    sublabel: i18n.t('Time:LABEL.HOURS_MINUTES'),
    width: 2,
  },
  {
    label: i18n.t('Common:CLIENT_SCHEDULING'),
    filter: Filters.ONLINE_SCHEDULING_ENABLED,
    FilterComponent: AppointmentTypeClientSchedulingFilter,
    width: 2,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    filter: Filters.ENABLED,
    FilterComponent: AppointmentTypeEnabledFilter,
    width: 2,
  },
]

// Use the hex value for each color filter to generate a color swatch
const addComponentsToFilters = (
  filters: Record<string, TableFilter>,
): Record<string, TableFilter> => {
  const newFilters = R.clone(filters)

  if (!R.isNil(newFilters[Filters.COLOR]?.value)) {
    const colorStrings = getFilterValueStrings(newFilters[Filters.COLOR])
    newFilters[Filters.COLOR].component = (
      <Grid container item wrap="nowrap">
        {colorStrings.map((color) => (
          <ColorSwatch
            color={color}
            key={color}
            noColorValueEnabled={!color.length}
          />
        ))}
      </Grid>
    )
  }

  return newFilters
}

const AppointmentConfigurationPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const isLoading = useSelector(getAppointmentTypesIsLoading)
  const map = useSelector(getAppointmentTypesMap)
  const filters = useSelector(getAppointmentTypesListFilters)
  const sortings = useSelector(getAppointmentTypesListSortings)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const AppointmentEventTypes = useSelector(getAppointmentTypes)
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.BUSINESS_APPOINTMENT_TYPE),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const [showAddAppontmentTypeRow, setShowAddAppontmentTypeRow] =
    useState(false)

  // Due to reduced scope of RHAP-7 search filters are not currently applied on API call
  const handleFetchList = (businessId: string) => {
    dispatch(
      fetchAppointmentTypesList({
        businessId,
        searchFilter: {
          from: 0,
          to: 200,
        },
      }),
    )
  }

  const handleClearFilters = () => {
    dispatch(setAppointmentTypesListSortings({}))
    dispatch(setAppointmentTypesListFilters({}))
  }

  useEffect(
    () => () => {
      handleClearFilters()
    },
    [],
  )

  useEffect(() => {
    if (currentBusinessId) {
      handleFetchList(currentBusinessId)
    }
  }, [currentBusinessId])

  const preparedList: string[] = useMemo(() => {
    const eventTypeNames = new Map()
    AppointmentEventTypes.forEach((eventType) => {
      eventTypeNames.set(eventType.id, eventType.name)
    })

    const appointmentTypeArray = getMapAsArray(map)
    const filtered = applyFilters(appointmentTypeArray, filters)
    const sorted = applySortings(filtered, sortings, eventTypeNames)
    const list = sorted.map((cur) => cur.id)

    return showAddAppontmentTypeRow ? [NEW_TYPE_PLACEHOLDER_ID, ...list] : list
  }, [showAddAppontmentTypeRow, map, filters, sortings, AppointmentEventTypes])

  const isItemLoaded = (index: number) => Boolean(preparedList[index])

  const handleApplySorting = (sortingKey: string) => {
    setShowAddAppontmentTypeRow(false)
    switch (sortings[sortingKey]) {
      case true:
        dispatch(
          setAppointmentTypesListSortings({ ...sortings, [sortingKey]: false }),
        )
        break
      case false:
        dispatch(
          setAppointmentTypesListSortings({
            ...sortings,
            [sortingKey]: undefined,
          }),
        )
        break
      default:
        dispatch(
          setAppointmentTypesListSortings({ ...sortings, [sortingKey]: true }),
        )
    }
  }

  const handleClearSortingItem = (sorting: string) => {
    const newSortings = R.omit([sorting], { ...sortings })
    dispatch(setAppointmentTypesListSortings(newSortings))
  }

  const handleApplyFilter = (filter: string, value: TableFilter) => {
    setShowAddAppontmentTypeRow(false)
    dispatch(setAppointmentTypesListFilters({ ...filters, [filter]: value }))
  }

  const handleAddNewAppointmentType = () => {
    setShowAddAppontmentTypeRow(true)
  }

  const handleCreateItemCancel = (created?: boolean) => {
    setShowAddAppontmentTypeRow(false)
    if (created && currentBusinessId) {
      handleFetchList(currentBusinessId)
    }
  }

  return (
    <Grid container item direction="column" flex={1} px={3} wrap="nowrap">
      <Grid container item justifyContent="space-between" py={2}>
        <Text variant="h1">
          {t('Admin:APPOINTMENT_CONFIGURATION.APPOINTMENT_CONFIGURATION')}
        </Text>
        {(permissions.create || !isCvcRolesEnabled) && (
          <Fab
            className={classes.addButton}
            color="inherit"
            disabled={showAddAppontmentTypeRow || isLoading}
            variant="extended"
            onClick={handleAddNewAppointmentType}
          >
            {t('Admin:APPOINTMENT_CONFIGURATION.NEW_APPOINTMENT_TYPE')}
          </Fab>
        )}
      </Grid>
      <PrimitiveTable
        HeaderProps={{
          classes: {
            tableHeader: classes.tableHeader,
          },
          useNilAsEmpty: true,
        }}
        RowComponent={AppointmentConfigurationRow}
        RowComponentProps={{
          onCreateItemCancel: handleCreateItemCancel,
        }}
        columns={columns}
        filters={addComponentsToFilters(filters)}
        isItemLoaded={isItemLoaded}
        itemSpacing={0}
        list={preparedList}
        loadMoreItems={() => {}}
        sortings={sortings}
        totalCount={preparedList.length}
        onApplyFilter={handleApplyFilter}
        onApplySorting={handleApplySorting}
        onClearFilters={handleClearFilters}
        onClearSortingItem={handleClearSortingItem}
      />
    </Grid>
  )
}

export default AppointmentConfigurationPage
