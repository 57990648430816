import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CircularProgressOverlay,
  PuiCheckbox,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { PlanTypeDisplay } from '~/constants/wellnessPlansConstants'
import { fetchClient } from '~/store/actions/clients'
import { cancelFutureAppointments } from '~/store/actions/timetable'
import { fetchWpCancellationInfo } from '~/store/actions/wellnessPlans'
import { getTimetableIsDeleting } from '~/store/reducers/timetable'
import { getWellnessPlansIsLoading } from '~/store/reducers/wellnessPlans'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useFutureAppointmentsAndMembership } from '~/utils/useFutureAppointmentsAndMembership'

import PuiCheckboxList from '../PuiCheckboxList'

interface CancelFutureAppointmentsAndMembershipDialogProps
  extends BasePuiDialogProps {
  clientId: string
  patientId: string
  patientName: string
}

export const CancelFutureAppointmentsAndMembershipDialog = ({
  open,
  onClose,
  patientId,
  patientName,
  clientId,
}: CancelFutureAppointmentsAndMembershipDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])
  const dispatch = useDispatch()
  const [shouldCancelFutureAppointments, setShouldCancelFutureAppointments] =
    useState(true)
  const [selectedPlanCancelIds, setSelectedPlanCancelIds] = useState<string[]>(
    [],
  )

  const wellnessPlansIsLoading = useSelector(getWellnessPlansIsLoading)
  const isCancellingFutureAppointments = useSelector(getTimetableIsDeleting)

  const {
    isMembershipFetching,
    isAppointmentDataFetching,
    hasFutureAppointments,
    membership,
    hasMembership,
  } = useFutureAppointmentsAndMembership({
    patientId,
    clientId,
    includePausedMemberships: true,
  })

  useEffect(() => {
    const cancelIds = (membership?.plans || [])
      .filter((plan) => plan.planTypeDisplay !== PlanTypeDisplay.EXTRA)
      .map((plan) => plan.cancelId)
    setSelectedPlanCancelIds(cancelIds)
  }, [membership?.plans])

  const [openCancelMembershipDialog] = useDialog(DialogNames.CANCEL_MEMBERSHIP)

  const handleCancelFutureAppointments = () => {
    dispatch(cancelFutureAppointments(patientId))
  }

  const onCancelInfoFetched = (
    cancelId: string,
    onCancelMembership: () => void,
  ) => {
    openCancelMembershipDialog({
      clientId,
      patientId,
      cancelId,
      onCancelMembership,
    })
  }

  const setCallbackAfterFetchCancellationInfoOn = useCloseAfterCreation(
    onCancelInfoFetched,
    getWellnessPlansIsLoading,
  )

  const cancelPlan = (index: number, onCancelMembership: () => void) => {
    const cancelId: string = selectedPlanCancelIds[index]
    setCallbackAfterFetchCancellationInfoOn(cancelId, onCancelMembership)
    dispatch(fetchWpCancellationInfo(clientId, patientId, cancelId))
  }

  const refreshPatientWellnessPlans = () => {
    dispatch(fetchClient({ clientId }))
  }

  const handleCancelSelectedItems = () => {
    const finalizeWPCancellation = () => {
      if (hasFutureAppointments && shouldCancelFutureAppointments) {
        handleCancelFutureAppointments()
      }
      if (selectedPlanCancelIds.length) {
        refreshPatientWellnessPlans()
      }
      if (onClose) {
        onClose()
      }
    }

    const processNextPlan = (index: number) => {
      if (index < selectedPlanCancelIds.length) {
        cancelPlan(index, () => {
          processNextPlan(index + 1)
        })
      } else {
        finalizeWPCancellation()
      }
    }

    processNextPlan(0)
  }

  const isLoading = isMembershipFetching || isAppointmentDataFetching

  const membershipItems = useMemo(
    () =>
      (membership?.plans || [])
        .filter((plan) => plan.planTypeDisplay !== PlanTypeDisplay.EXTRA)
        .map(({ cancelId, planTypeDisplay, planName }) => ({
          id: cancelId,
          name: `${
            planTypeDisplay === PlanTypeDisplay.SUBSCRIBE_AND_SAVE
              ? t(
                  'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.SUBSCRIBE_AND_SAVE',
                )
              : t(
                  'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.MEMBERSHIP',
                )
          }: ${planName}`,
        })),
    [membership],
  )

  if (!hasMembership && !hasFutureAppointments) {
    return null
  }

  return (
    <PuiDialog
      actions={
        <Grid container columnSpacing={2} justifyContent="flex-start">
          <Grid item>
            <ButtonWithLoader color="secondary" onClick={onClose}>
              {t('Common:SKIP_ACTION')}
            </ButtonWithLoader>
          </Grid>
          <Grid item>
            <ButtonWithLoader
              loading={wellnessPlansIsLoading || isCancellingFutureAppointments}
              onClick={handleCancelSelectedItems}
            >
              {t(
                'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.CANCEL_SELECTED_ITEMS',
              )}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      }
      open={open}
      title={t(
        'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.CANCEL_ITEMS_FOR_PATIENT',
        { patientName },
      )}
      onClose={onClose}
    >
      <CircularProgressOverlay open={isLoading} />
      <Grid p={2}>
        <Text pb={2}>
          {t(
            'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.WE_RECOMMEND_THAT_YOU',
          )}
        </Text>

        {hasFutureAppointments ? (
          <PuiCheckbox
            checked={shouldCancelFutureAppointments}
            label={t(
              'Dialogs:CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP_DIALOG.ALL_UPCOMING_APPOINTMENTS',
            )}
            onChange={() =>
              setShouldCancelFutureAppointments((prevValue) => !prevValue)
            }
          />
        ) : null}
        {hasMembership && (
          <Grid ml={-2} mt={-1}>
            <PuiCheckboxList
              hideAllButton
              initialState={selectedPlanCancelIds}
              items={membershipItems}
              onChange={(items: string[]) => {
                setSelectedPlanCancelIds(items)
              }}
            />
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}
