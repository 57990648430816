import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { Nil } from '@pbt/pbt-ui-components'

import type { DialogComponentMapType } from '~/components/dialogs-manager/dialog-components'
import DialogNames from '~/constants/DialogNames'
import {
  closeDialog,
  isDialogOpen,
  modifyBaseProps,
  modifyProps,
  openDialog,
} from '~/store/duck/dialogs'

type DialogComponentParams<K extends DialogNames> = Parameters<
  DialogComponentMapType[K]
>

type UseDialogHook = {
  <K extends DialogNames>(
    name: K,
    onClose?: (() => void) | Nil,
    properties?: any,
    mergeProps?: false | undefined,
  ): [
    (props?: Omit<DialogComponentParams<K>[0], 'open'>) => void,
    () => void,
    boolean,
  ]

  <K extends DialogNames>(
    name: K,
    onClose?: (() => void) | Nil,
    properties?: any,
    mergeProps?: true,
  ): [
    (props?: Partial<Omit<DialogComponentParams<K>[0], 'open'>>) => void,
    () => void,
    boolean,
  ]
}

const useDialog: UseDialogHook = (name, onClose, properties, mergeProps) => {
  const idRef = useRef(uuid())
  const id = idRef.current

  const dispatch = useDispatch()
  const isOpen: boolean = useSelector(isDialogOpen(id))

  const [wasOpen, setWasOpen] = useState(false)
  const [oldProperties, setOldProperties] = useState(properties)

  useEffect(() => {
    if (onClose && wasOpen && !isOpen) {
      onClose()
    }
    setWasOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (mergeProps && properties) {
      dispatch(modifyBaseProps(id, properties))
    }
  }, [])

  useEffect(() => {
    if (properties && !R.equals(properties, oldProperties)) {
      setOldProperties(properties)
      if (mergeProps) {
        dispatch(modifyBaseProps(id, properties))
      } else {
        dispatch(modifyProps(id, properties))
      }
    }
  }, [properties])

  return [
    (props) =>
      isOpen
        ? // eslint-disable-next-line no-console
          console.warn(
            `Dialog ${name} is already open, please check the root cause of this unexpected behavior`,
          )
        : dispatch(openDialog<typeof name>({ name, id, props })),
    () => dispatch(closeDialog(id)),
    isOpen,
  ]
}

export default useDialog
