import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { File as FileIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    smallFilePlaceholder: {
      width: 50,
      height: 50,
    },
    largeFilePlaceholder: {
      width: 160,
      height: 104,
    },
    longFilePlaceholder: {
      width: 72,
      height: 48,
    },
    filePlaceholder: {
      backgroundColor: theme.colors.newLabel,
      border: theme.constants.controlBorder,
      borderRadius: 2,
    },
  }),
  { name: 'FilePlaceholder' },
)

export interface FilePlaceholderProps {
  variant?: 'small' | 'large' | 'long'
}

const FilePlaceholder = ({ variant = 'small' }: FilePlaceholderProps) => {
  const classes = useStyles()

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.filePlaceholder, {
        [classes.smallFilePlaceholder]: variant === 'small',
        [classes.largeFilePlaceholder]: variant === 'large',
        [classes.longFilePlaceholder]: variant === 'long',
      })}
      justifyContent="center"
    >
      <FileIcon />
    </Grid>
  )
}

export default FilePlaceholder
