import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { ImportedHistoryTimelineItem } from '~/types'

import OneImportedHistory from './OneImportedHistory'

const useStyles = makeStyles(
  (theme) => ({
    itemContainer: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
  }),
  { name: 'MultipleImportedHistories' },
)

interface MultipleImportedHistoriesProps {
  enableBusinessShareIcon?: boolean
  item: ImportedHistoryTimelineItem
}

const MultipleImportedHistories = ({
  item,
  enableBusinessShareIcon,
}: MultipleImportedHistoriesProps) => {
  const classes = useStyles()

  return (
    <>
      {item.entries.map((entry, index) => (
        <Grid
          item
          className={classes.itemContainer}
          // eslint-disable-next-line react/no-array-index-key
          key={`${entry.name} - ${index}`}
          p={2}
        >
          <OneImportedHistory
            enableBusinessShareIcon={enableBusinessShareIcon}
            importedHistory={entry}
          />
        </Grid>
      ))}
    </>
  )
}

export default MultipleImportedHistories
