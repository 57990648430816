import React from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import { Amount, Nil } from '@pbt/pbt-ui-components'

import { removeLineItems } from '~/store/actions/finance'
import { expandGroups } from '~/store/duck/clientFinanceData'
import {
  BatchInvoice,
  Invoice,
  InvoiceLineItem,
  InvoiceLineItemGroup,
  InvoiceOrEstimate,
  InvoiceUpdateFieldCallback,
} from '~/types'

import {
  getUsedQuantityBlocked,
  setHighValue,
  setLowValue,
} from '../../invoiceUtils'
import InvoiceGroupTitleRow from './InvoiceGroupTitleRow'
import InvoiceTableRow from './InvoiceTableRow'

const getIsGroupedItem = (item: InvoiceLineItem, group: InvoiceLineItemGroup) =>
  Boolean(item.group) &&
  group.groupedItems.some((groupItem) => groupItem?.items?.includes(item))

export interface InvoiceItemGroupProps {
  create?: boolean
  group: InvoiceLineItemGroup
  invoice: Invoice
  isEstimate: boolean
  isReadOnly: boolean
  onUnblockEditInvoice: (
    invoice: Invoice,
    soap: InvoiceLineItemGroup['soap'],
  ) => void
  onUpdatePrescriptionLineItem: (item: InvoiceLineItem) => void
  originalInvoice: InvoiceOrEstimate | BatchInvoice
  prePaidEnabled: boolean
  rangeEnabled: boolean
  selectedColumns: string[]
  updateInvoice: (invoice: Invoice) => void
}

const InvoiceItemGroup = ({
  isEstimate,
  isReadOnly,
  group,
  create,
  invoice,
  updateInvoice,
  originalInvoice,
  onUnblockEditInvoice,
  ...rest
}: InvoiceItemGroupProps) => {
  const dispatch = useDispatch()

  const groups = invoice?.groups || []

  const deleteItem = (item: InvoiceLineItem) => {
    const isGroupedItem = getIsGroupedItem(item, group)
    const isGroup = Boolean(item.items)

    const itemParent: InvoiceLineItem | null =
      isGroupedItem && !isGroup
        ? (group.groupedItems.find(
            (groupItem) => groupItem.items && groupItem.items.includes(item),
          ) as InvoiceLineItem)
        : null

    const itemIndex = itemParent ? group.groupedItems.indexOf(itemParent) : -1
    const groupIndex = groups.indexOf(group)

    const newParentItem: InvoiceLineItem | null =
      isGroupedItem && !isGroup
        ? {
            ...(itemParent as InvoiceLineItem),
            items: R.without([item], itemParent?.items || []),
          }
        : null

    const newGroup = {
      ...group,
      groupedItems:
        newParentItem && !newParentItem.items?.length
          ? R.without([itemParent], group.groupedItems)
          : newParentItem
            ? R.update(itemIndex, newParentItem, group.groupedItems)
            : R.without([item], group.groupedItems),
    } as InvoiceLineItemGroup

    const newGroups: InvoiceLineItemGroup[] =
      newGroup.groupedItems.length === 0
        ? R.without([group], groups)
        : R.update(groupIndex, newGroup, groups)

    const bundleAdditionalDiscount =
      invoice.bundleAdditionalDiscount - (item.additionalDiscount || 0)
    updateInvoice({ ...invoice, groups: newGroups, bundleAdditionalDiscount })

    if (invoice.id) {
      const idsToRemove = R.pluck('id', item.items || [item])

      dispatch(removeLineItems(idsToRemove, invoice.id))
    }
  }

  const getUpdatedField = (
    item: InvoiceLineItem,
    isGroup: boolean,
    isGroupedItem: boolean,
    prop: keyof InvoiceLineItem,
    updatedValue: any,
  ) =>
    isGroup
      ? {
          ...item,
          [prop]: updatedValue,
          items: item.items?.map((childItem) => ({
            ...childItem,
            [prop]: updatedValue,
          })),
        }
      : { ...item, [prop]: updatedValue }

  const updateField: InvoiceUpdateFieldCallback = (
    event,
    field,
    itemGroup,
    item,
    isBool,
    updateOtherFields,
    // eslint-disable-next-line max-params
  ) => {
    const isGroupedItem = getIsGroupedItem(item, itemGroup)
    const isGroup = Boolean(item.items)

    const itemParent =
      isGroupedItem && !isGroup
        ? (itemGroup.groupedItems.find(
            (groupItem) => groupItem.items && groupItem.items.includes(item),
          ) as InvoiceLineItem)
        : item

    const itemIndexInParent = isGroupedItem
      ? itemParent.items!.indexOf(item)
      : -1
    const itemIndex = itemGroup.groupedItems.indexOf(itemParent)
    const value = event.target ? event.target.value : event
    const newValue = isBool ? Number(value) !== 0 : value || 0
    const [prop, subProp] = field.split('.') as unknown as [
      keyof InvoiceLineItem,
      string,
    ]
    const itemProp = item[prop] as Amount | Nil
    const rangeValue =
      subProp &&
      (subProp === 'low'
        ? setLowValue(itemProp, newValue)
        : setHighValue(itemProp, newValue))
    const updatedValue = rangeValue || newValue

    const newItem = getUpdatedField(
      item,
      isGroup,
      isGroupedItem,
      prop,
      updatedValue,
    )

    const newParentItem =
      isGroupedItem && !isGroup
        ? prop === 'declined' && !newValue
          ? {
              ...itemParent,
              [prop]: updatedValue,
              items: R.update(
                itemIndexInParent,
                newItem,
                itemParent.items || [],
              ),
            }
          : {
              ...itemParent,
              items: R.update(
                itemIndexInParent,
                newItem,
                itemParent.items || [],
              ),
            }
        : newItem

    if (updateOtherFields) {
      updateOtherFields(item, newItem, isEstimate)
    }

    const invoiceGroupIndex = groups.indexOf(itemGroup)
    const newGroup = {
      ...itemGroup,
      groupedItems: R.update(itemIndex, newParentItem, itemGroup.groupedItems),
    }
    updateInvoice({
      ...invoice,
      groups: R.update(invoiceGroupIndex, newGroup, groups),
    })
  }

  const toggle = (item: InvoiceLineItem) => {
    const firstGroupedItem = item?.items?.find(
      (foundItem) => foundItem.patientId,
    )
    dispatch(
      expandGroups({
        groupId: {
          patientId: firstGroupedItem?.patientId ?? '',
          soapId: firstGroupedItem?.soapId,
          group: item.group,
        },
      }),
    )
  }
  return (
    <>
      {!create && !isEstimate && (
        <InvoiceGroupTitleRow group={group} invoice={invoice} />
      )}
      {group.groupedItems.map((item, index) => (
        <InvoiceTableRow
          blockedUsedQuantity={getUsedQuantityBlocked(originalInvoice, item)}
          deleteItem={deleteItem}
          group={group}
          index={index}
          invoice={invoice}
          isEstimate={isEstimate}
          isReadOnly={isReadOnly}
          item={item}
          key={item.group || item.id || item.priceId}
          soap={group.soap}
          updateField={updateField}
          onUnblockEditInvoice={onUnblockEditInvoice}
          {...rest}
          toggleExpand={toggle}
        />
      ))}
    </>
  )
}

export default InvoiceItemGroup
