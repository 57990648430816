import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  styled,
} from '@mui/material'
import { Text, TextProps } from '@pbt/pbt-ui-components'

const BorderlessAccordion = styled(Accordion)`
  box-shadow: none;
  background-color: inherit;
  &:before {
    content: none;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  padding: 0;
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

const Title = styled((props: TextProps) => (
  <Text strong variant="h4" {...props} />
))`
  color: ${({ theme }) => theme.colors.brandPrimary};
`

const PageAccordion = ({
  expanded,
  title,
  children,
  ...rest
}: AccordionProps) => (
  <BorderlessAccordion disableGutters expanded={expanded} {...rest}>
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
      <Title>{title}</Title>
    </StyledAccordionSummary>
    <StyledAccordionDetails>{children}</StyledAccordionDetails>
  </BorderlessAccordion>
)

export default PageAccordion
