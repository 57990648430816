import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  DiagnoseHistoryItem,
  SoapDiagnose,
  SoapDiagnoseSearchResult,
  SoapDiagnosesFilter,
} from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_DIAGNOSES_FILTERS = 'diagnoses/FETCH_DIAGNOSES_FILTERS'
export const FETCH_DIAGNOSES_FILTERS_SUCCESS =
  'diagnoses/FETCH_DIAGNOSES_FILTERS_SUCCESS'
export const FETCH_DIAGNOSES_FILTERS_FAILURE =
  'diagnoses/FETCH_DIAGNOSES_FILTERS_FAILURE'

export const FETCH_SOAP_DIAGNOSES = 'diagnoses/FETCH_SOAP_DIAGNOSES'
export const FETCH_SOAP_DIAGNOSES_SUCCESS =
  'diagnoses/FETCH_SOAP_DIAGNOSES_SUCCESS'
export const FETCH_SOAP_DIAGNOSES_FAILURE =
  'diagnoses/FETCH_SOAP_DIAGNOSES_FAILURE'

export const CLEAR_SOAP_DIAGNOSES_FILTERS =
  'diagnoses/CLEAR_SOAP_DIAGNOSES_FILTERS'
export const CLEAR_SOAP_DIAGNOSES = 'diagnoses/CLEAR_SOAP_DIAGNOSES'

export const SEARCH_DIAGNOSES = 'diagnoses/SEARCH_DIAGNOSES'
export const SEARCH_DIAGNOSES_SUCCESS = 'diagnoses/SEARCH_DIAGNOSES_SUCCESS'
export const SEARCH_DIAGNOSES_FAILURE = 'diagnoses/SEARCH_DIAGNOSES_FAILURE'

export const EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP =
  'diagnoses/EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP'
export const EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_SUCCESS =
  'diagnoses/EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_SUCCESS'
export const EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_FAILURE =
  'diagnoses/EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_FAILURE'

export const DELETE_DIAGNOSIS = 'diagnoses/DELETE_DIAGNOSIS'
export const DELETE_DIAGNOSIS_SUCCESS = 'diagnoses/DELETE_DIAGNOSIS_SUCCESS'
export const DELETE_DIAGNOSIS_FAILURE = 'diagnoses/DELETE_DIAGNOSIS_FAILURE'

export const fetchDiagnosesFilters = (patientId: string) => ({
  type: FETCH_DIAGNOSES_FILTERS,
  patientId,
})
export const fetchDiagnosesFiltersSuccess = (
  filters: SoapDiagnosesFilter[],
) => ({
  type: FETCH_DIAGNOSES_FILTERS_SUCCESS,
  filters,
})
export const fetchDiagnosesFiltersFailure = (error: ApiError) => ({
  type: FETCH_DIAGNOSES_FILTERS_FAILURE,
  error,
})

export const clearSoapDiagnosesFilters = () => ({
  type: CLEAR_SOAP_DIAGNOSES_FILTERS,
})
export const clearSoapDiagnoses = () => ({ type: CLEAR_SOAP_DIAGNOSES })

export const fetchSoapDiagnoses = (
  patientId: string,
  categories: string[],
  type: string,
) => ({
  type: FETCH_SOAP_DIAGNOSES,
  patientId,
  categories,
  entityType: type,
})
export const fetchSoapDiagnosesSuccess = (diagnoses: SoapDiagnose[]) => ({
  type: FETCH_SOAP_DIAGNOSES_SUCCESS,
  diagnoses,
})
export const fetchSoapDiagnosesFailure = (error: ApiError) => ({
  type: FETCH_SOAP_DIAGNOSES_FAILURE,
  error,
})

export const searchDiagnoses = (
  searchTerm: string,
  patientId: string | Nil,
) => ({
  type: SEARCH_DIAGNOSES,
  searchTerm,
  patientId,
})
export const searchDiagnosesSuccess = (
  searchResults: SoapDiagnoseSearchResult[],
) => ({
  type: SEARCH_DIAGNOSES_SUCCESS,
  searchResults,
})
export const searchDiagnosesFailure = (error: ApiError) => ({
  type: SEARCH_DIAGNOSES_FAILURE,
  error,
})

export const editDiagnosisStatusOutsideSoap = (
  diagnosis: DiagnoseHistoryItem,
  statusId: string,
) => ({
  type: EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP,
  diagnosis,
  statusId,
})
export const editDiagnosisStatusOutsideSoapSuccess = () => ({
  type: EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_SUCCESS,
})
export const editDiagnosisStatusOutsideSoapFailure = (error: ApiError) => ({
  type: EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_FAILURE,
  error,
})

export const deleteDiagnosis = (soapId: string, logId: string) => ({
  type: DELETE_DIAGNOSIS,
  soapId,
  logId,
})
export const deleteDiagnosisSuccess = (soapId: string, logId: string) => ({
  type: DELETE_DIAGNOSIS_SUCCESS,
  soapId,
  logId,
})
export const deleteDiagnosisFailure = (error: ApiError) => ({
  type: DELETE_DIAGNOSIS_FAILURE,
  error,
})

export type SoapDiagnosesState = {
  diagnoses: SoapDiagnose[]
  error: string | null
  filters: SoapDiagnosesFilter[]
  isLoading: boolean
  searchResults: SoapDiagnoseSearchResult[]
}

export const SOAP_DIAGNOSES_INITIAL_STATE: SoapDiagnosesState = {
  isLoading: false,
  error: null,
  filters: [],
  diagnoses: [],
  searchResults: [],
}

export const soapDiagnosesReducer = (
  state: SoapDiagnosesState = SOAP_DIAGNOSES_INITIAL_STATE,
  action: AnyAction,
): SoapDiagnosesState => {
  switch (action.type) {
    case FETCH_DIAGNOSES_FILTERS:
      return { ...state, isLoading: true, error: null }
    case FETCH_DIAGNOSES_FILTERS_SUCCESS:
      return { ...state, isLoading: false, filters: action.filters }
    case FETCH_DIAGNOSES_FILTERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_SOAP_DIAGNOSES:
      return { ...state, isLoading: true, error: null }
    case FETCH_SOAP_DIAGNOSES_SUCCESS:
      return { ...state, isLoading: false, diagnoses: action.diagnoses }
    case FETCH_SOAP_DIAGNOSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_SOAP_DIAGNOSES_FILTERS:
      return { ...state, filters: [] }
    case CLEAR_SOAP_DIAGNOSES:
      return { ...state, diagnoses: [] }
    case SEARCH_DIAGNOSES:
      return {
        ...state,
        isLoading: true,
        searchResults: [],
        diagnoses: [],
        error: null,
      }
    case SEARCH_DIAGNOSES_SUCCESS:
      return { ...state, isLoading: false, searchResults: action.searchResults }
    case SEARCH_DIAGNOSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP:
      return { ...state, isLoading: true, error: null }
    case EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_DIAGNOSIS:
      return { ...state, isLoading: true, error: null }
    case DELETE_DIAGNOSIS_SUCCESS:
      return { ...state, isLoading: false }
    case DELETE_DIAGNOSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getSoapDiagnoses = (state: RootState): SoapDiagnosesState =>
  state.soapDiagnoses
export const getDiagnosesFilters = (state: RootState) =>
  getSoapDiagnoses(state).filters
export const getDiagnosesData = (state: RootState) =>
  getSoapDiagnoses(state).diagnoses
export const getDiagnosesIsLoading = (state: RootState) =>
  getSoapDiagnoses(state).isLoading
export const getDiffDxSearchResults = (state: RootState) =>
  getSoapDiagnoses(state).searchResults

export function* fetchDiagnosesFiltersSaga({
  patientId,
}: ReturnType<typeof fetchDiagnosesFilters>) {
  try {
    const filters = yield* requestAPI(API.fetchSoapDiagnosesFilters, patientId)
    yield put(fetchDiagnosesFiltersSuccess(filters))
  } catch (error) {
    yield put(fetchDiagnosesFiltersFailure(error as ApiError))
  }
}

export function* fetchSoapDiagnosesSaga({
  patientId,
  categories,
  entityType,
}: ReturnType<typeof fetchSoapDiagnoses>) {
  try {
    const diagnoses = yield* requestAPI(
      API.fetchSoapDiagnoses,
      patientId,
      categories.join(','),
      entityType,
    )
    yield put(fetchSoapDiagnosesSuccess(diagnoses))
  } catch (error) {
    yield put(fetchSoapDiagnosesFailure(error as ApiError))
  }
}

export function* searchDiagnosesSaga({
  searchTerm,
  patientId,
}: ReturnType<typeof searchDiagnoses>) {
  try {
    const { data } = yield* requestAPI(
      API.searchDiagnoses,
      searchTerm,
      patientId,
    )
    yield put(searchDiagnosesSuccess(data))
  } catch (error) {
    yield put(searchDiagnosesFailure(error as ApiError))
  }
}

export function* editDiagnosisStatusOutsideSoapSaga({
  diagnosis,
  statusId,
}: ReturnType<typeof editDiagnosisStatusOutsideSoap>) {
  try {
    yield* requestAPI(
      API.editSoapDiagnosis,
      diagnosis.soapId,
      diagnosis.logId,
      { statusId },
    )
    yield put(editDiagnosisStatusOutsideSoapSuccess())
  } catch (error) {
    yield put(editDiagnosisStatusOutsideSoapFailure(error as ApiError))
  }
}

export function* deleteDiagnosisSaga({
  soapId,
  logId,
}: ReturnType<typeof deleteDiagnosis>) {
  try {
    yield* requestAPI(API.deleteSoapDiagnosis, soapId, logId)
    yield put(deleteDiagnosisSuccess(soapId, logId))
  } catch (error) {
    yield put(deleteDiagnosisFailure(error as ApiError))
  }
}

function* watchSearchDiagnoses() {
  yield takeLatest(SEARCH_DIAGNOSES, searchDiagnosesSaga)
}

function* watchFetchDiagnosesFilters() {
  yield takeLatest(FETCH_DIAGNOSES_FILTERS, fetchDiagnosesFiltersSaga)
}

function* watchFetchSoapDiagnoses() {
  yield takeLatest(FETCH_SOAP_DIAGNOSES, fetchSoapDiagnosesSaga)
}

function* watchEditDiagnosisStatusOutsideSoap() {
  yield takeLatest(
    EDIT_DIAGNOSIS_STATUS_OUTSIDE_SOAP,
    editDiagnosisStatusOutsideSoapSaga,
  )
}

function* watchDeleteDiagnosisOutsideSoap() {
  yield takeLatest(DELETE_DIAGNOSIS, deleteDiagnosisSaga)
}

export function* soapDiagnosesSaga() {
  yield all([
    watchFetchDiagnosesFilters(),
    watchFetchSoapDiagnoses(),
    watchSearchDiagnoses(),
    watchEditDiagnosisStatusOutsideSoap(),
    watchDeleteDiagnosisOutsideSoap(),
  ])
}
