import React from 'react'
import { Grid } from '@mui/material'

import VitalsTable from '../../soap/vitals/VitalsTable'

const SoapVitalsWidgetUnlocked = () => (
  <Grid container id="vitals-widget">
    <VitalsTable />
  </Grid>
)

export default SoapVitalsWidgetUnlocked
