import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  CopyToClipboard,
  PermissionArea,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  BusinessSettingsSections,
  getSectionFields,
} from '~/store/duck/businessSettings'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps } from '~/types'
import { getKioskUrl } from '~/utils'
import { hasHtmlFieldChanged } from '~/utils/htmlUtils'
import useDialog from '~/utils/useDialog'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import MessageWithPreviewConfiguration from '../wellness-plans/MessageWithPreviewConfiguration'

const useStyles = makeStyles(
  (theme) => ({
    kioskUrlSection: {
      position: 'relative',
    },
    kioskSection: {
      borderBottom: theme.constants.tabBorder,
    },
    copyButton: {
      width: 150,
    },
    shadow: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      backgroundColor: theme.colors.tableBackground,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.5,
    },
  }),
  { name: 'KioskConfiguration' },
)

const KioskConfiguration = ({ business }: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Admin', 'Common'])
  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const brandingConfigurationFields = useSelector(
    getSectionFields<
      BusinessSettingsSections['BRANDING_CONFIGURATION']['fields']
    >(business.id, PracticeDetailsPanels.BRANDING_CONFIGURATION),
  )

  const isAutoAssignEventEnabled = useSelector(
    getFeatureToggle(FeatureToggle.AUTO_ASSIGN_EVENT),
  )

  const [openKioskPreviewDialog] = useDialog(DialogNames.KIOSK_PREVIEW)

  const [onlineSchedulingTooltipOpen, setOnlineSchedulingTooltipOpen] =
    useState(false)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'kioskEnabled',
        type: 'toggle',
        initialValue: business.kioskEnabled || false,
      },
      {
        name: 'waitListEnabled',
        type: 'toggle',
        initialValue: business.waitListEnabled || false,
      },
      {
        name: 'estimatedWaitTimeInKioskEnabled',
        type: 'toggle',
        initialValue: business.estimatedWaitTimeInKioskEnabled || false,
      },
      {
        name: 'telemedicineEnabled',
        type: 'toggle',
        initialValue: business.telemedicineEnabled,
      },
      {
        name: 'telemedicineText',
        initialValue: business.telemedicineText || '',
      },
      {
        name: 'kioskLandingPageMessageEnabled',
        type: 'toggle',
        initialValue: business.kioskLandingPageMessageEnabled,
      },
      {
        name: 'kioskLandingPageMessage',
        initialValue: business.kioskLandingPageMessage || '',
      },
      {
        name: 'wellnessPlansInSchedulingEnabled',
        type: 'toggle',
        initialValue: business.wellnessPlansInSchedulingEnabled,
      },
      {
        name: 'wellnessPlansInKioskEnabled',
        type: 'toggle',
        initialValue: business.wellnessPlansInKioskEnabled,
      },
      {
        name: 'autoAssignEvent',
        type: 'toggle',
        initialValue: business?.autoAssignEvent ?? false,
      },
    ],
    false,
  )

  const {
    autoAssignEvent,
    kioskEnabled,
    waitListEnabled,
    estimatedWaitTimeInKioskEnabled,
    telemedicineEnabled,
    telemedicineText,
    kioskLandingPageMessageEnabled,
    kioskLandingPageMessage,
    wellnessPlansInSchedulingEnabled,
    wellnessPlansInKioskEnabled,
  } = fields

  usePracticeFieldsSection({
    business,
    fields,
    sectionName: PracticeDetailsPanels.KIOSK,
    parsedFields: {
      autoAssignEvent: autoAssignEvent.value,
      kioskEnabled: kioskEnabled.value,
      waitListEnabled: waitListEnabled.value,
      estimatedWaitTimeInKioskEnabled: estimatedWaitTimeInKioskEnabled.value,
      telemedicineEnabled: telemedicineEnabled.value,
      telemedicineText: telemedicineText.value,
      kioskLandingPageMessageEnabled: kioskLandingPageMessageEnabled.value,
      kioskLandingPageMessage: kioskLandingPageMessage.value,
      wellnessPlansInSchedulingEnabled: wellnessPlansInSchedulingEnabled.value,
      wellnessPlansInKioskEnabled: wellnessPlansInKioskEnabled.value,
    },
    reset,
    validate,
    softUpdate: R.any(Boolean, [
      hasHtmlFieldChanged(kioskLandingPageMessage),
      hasHtmlFieldChanged(telemedicineText),
      kioskLandingPageMessageEnabled.value !==
        kioskLandingPageMessageEnabled.initialValue,
      kioskEnabled.value !== kioskEnabled.initialValue,
      waitListEnabled.value !== waitListEnabled.initialValue,
      estimatedWaitTimeInKioskEnabled.value !==
        estimatedWaitTimeInKioskEnabled.initialValue,
      telemedicineEnabled.value !== telemedicineEnabled.initialValue,
      wellnessPlansInSchedulingEnabled.value !==
        wellnessPlansInSchedulingEnabled.initialValue,
      wellnessPlansInKioskEnabled.value !==
        wellnessPlansInKioskEnabled.initialValue,
      autoAssignEvent.value !== autoAssignEvent.initialValue,
    ]),
  })

  const embedUrl = getKioskUrl('', { embed: true, businessId: business.id })

  const openPreview = () => {
    const brandingConfiguration = brandingConfigurationFields || business
    openKioskPreviewDialog({
      business,
      buttonsColor: brandingConfiguration.customColorsEnabled
        ? brandingConfiguration.buttonsColor
        : undefined,
      backgroundColor: brandingConfiguration.customColorsEnabled
        ? brandingConfiguration.backgroundColor
        : undefined,
      buttonText: t('Common:SCHEDULE_NOW'),
      title: t('Admin:PRACTICE.TELEHEALTH'),
      text: telemedicineText.value,
    })
  }

  return (
    <>
      <PuiSwitch
        disabled={!permissions.update}
        field={kioskEnabled}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={t('Admin:PRACTICE.ENABLE_KIOSK_TOOLTIP')}
            variant="body"
          >
            {t('Admin:PRACTICE.ENABLE_KIOSK')}
          </TextWithTooltip>
        }
      />
      <PuiSwitch
        disabled={!permissions.update}
        field={waitListEnabled}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={t('Admin:PRACTICE.ENABLE_WAITLIST_TOOLTIP')}
          >
            {t('Admin:PRACTICE.ENABLE_WAITLIST')}
          </TextWithTooltip>
        }
      />
      {waitListEnabled.value && kioskEnabled.value && (
        <PuiSwitch
          disabled={!permissions.update}
          field={estimatedWaitTimeInKioskEnabled}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Admin:PRACTICE.ENABLE_KIOSK_WAITTIME_DISPLAY_TOOLTIP',
              )}
            >
              {t('Admin:PRACTICE.ENABLE_KIOSK_WAITTIME_DISPLAY')}
            </TextWithTooltip>
          }
        />
      )}
      {isAutoAssignEventEnabled && (
        <Box>
          <Text strong variant="subheading3">
            {t('Admin:PRACTICE.AUTO_ASSIGN_LABEL_TEXT')}
          </Text>
          <PuiSwitch
            checked={autoAssignEvent.value}
            disabled={!permissions.update}
            field={autoAssignEvent}
            label={
              <TextWithTooltip
                allowWrap
                tooltipText={t('Admin:PRACTICE.AUTO_ASSIGN_TOOLTIP_TEXT')}
              >
                {t('Admin:PRACTICE.AUTO_ASSIGN_TOGGLE_TEXT')}
              </TextWithTooltip>
            }
          />
        </Box>
      )}
      <Grid container item className={classes.kioskUrlSection} mt={2}>
        {!business.kioskEnabled && (
          <Box
            className={classes.shadow}
            role="button"
            onClick={(event) => {
              event.stopPropagation()
            }}
            onMouseEnter={() => setOnlineSchedulingTooltipOpen(true)}
            onMouseLeave={() => setOnlineSchedulingTooltipOpen(false)}
          />
        )}
        <Grid
          container
          item
          className={classes.kioskSection}
          columnSpacing={2}
          mb={2}
          pb={1}
          xs={12}
        >
          <Grid item>
            <TextWithTooltip
              allowWrap
              strong
              tooltipText={t('Admin:PRACTICE.ONLINE_SCHEDULING_URL_TOOLTIP')}
              variant="body2"
            >
              {t('Admin:PRACTICE.ONLINE_SCHEDULING_URL')}
            </TextWithTooltip>
          </Grid>
          <Tooltip
            open={onlineSchedulingTooltipOpen}
            placement="bottom"
            title={t('Admin:PRACTICE.ENABLE_KIOSK_AND_SAVE_BUSINESS_INFO')}
          >
            <Grid item>
              <Text variant="body2">{embedUrl}</Text>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item mb={2}>
          <CopyToClipboard
            isButton
            classes={{ button: classes.copyButton }}
            label={t('Common:COPY_URL')}
            text={embedUrl}
          />
        </Grid>
      </Grid>
      <>
        <Grid item>
          <PuiSwitch
            disabled={!permissions.update}
            field={kioskLandingPageMessageEnabled}
            label={
              <TextWithTooltip
                allowWrap
                tooltipText={t(
                  'Admin:PRACTICE.KIOSK_LANDING_PAGE_MESSAGE_TOOLTIP',
                )}
                variant="body"
              >
                {t('Admin:PRACTICE.KIOSK_LANDING_PAGE_MESSAGE')}
              </TextWithTooltip>
            }
          />
        </Grid>
        {kioskLandingPageMessageEnabled.value && (
          <Grid item mb={2}>
            <MessageWithPreviewConfiguration
              disabled={!permissions.update}
              field={kioskLandingPageMessage}
              maxLength={200}
              showDynamicText={false}
              showPreview={false}
              tooltipTitle={t('Admin:PRACTICE.DEFAULT_KIOSK_MESSAGE')}
            />
          </Grid>
        )}
      </>
      <Grid item>
        <PuiSwitch
          disabled={!permissions.update}
          field={telemedicineEnabled}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Admin:PRACTICE.PROMOTE_TELEHEALTH_APPOINTMENTS_TOOLTIP',
              )}
              variant="body"
            >
              {t('Admin:PRACTICE.PROMOTE_TELEHEALTH_APPOINTMENTS')}
            </TextWithTooltip>
          }
        />
      </Grid>
      {telemedicineEnabled.value && (
        <Grid item mb={2}>
          <NotesTemplateInput
            disabled={!permissions.update}
            field={telemedicineText}
            minHeight={200}
            title={t('Admin:PRACTICE.INCLUDE_EXPLANATION')}
            onPreviewClick={openPreview}
          />
        </Grid>
      )}
      <Grid item>
        <PuiSwitch
          disabled={!permissions.update}
          field={wellnessPlansInSchedulingEnabled}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Admin:PRACTICE.PROMOTE_WELLNESS_PLANS_ONLINE_TOOLTIP',
              )}
              variant="body"
            >
              {t('Admin:PRACTICE.PROMOTE_WELLNESS_PLANS_ONLINE')}
            </TextWithTooltip>
          }
        />
      </Grid>
      <Grid item>
        <PuiSwitch
          disabled={!permissions.update}
          field={wellnessPlansInKioskEnabled}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Admin:PRACTICE.PROMOTE_WELLNESS_PLANS_KIOSK_TOOLTIP',
              )}
              variant="body"
            >
              {t('Admin:PRACTICE.PROMOTE_WELLNESS_PLANS_KIOSK')}
            </TextWithTooltip>
          }
        />
      </Grid>
    </>
  )
}

export default KioskConfiguration
