import { gql } from '@apollo/client'

export const TEMPLATE_ITEM_FIELDS = gql`
  fragment TemplateItemFields on ReminderCommunicationTemplate {
    id
    active
    communicationMethodIds
    dueDateOffset {
      amount
      unit
    }
    message
    subject
    textMessage
  }
`
