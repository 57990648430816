import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import FeatureToggle from '~/constants/featureToggle'
import { fetchPrescriptionPrintInfo } from '~/store/actions/orders'
import {
  useIsFood,
  useIsGlobalInventoryItem,
  useOpenPrescriptionDialogAfterFetchUnifiedOrder,
} from '~/store/hooks/orders'
import {
  useDeletePrescription,
  useGetPrescriptionStateType,
  useIsDeclinableOrDeletable,
} from '~/store/hooks/prescription'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Order } from '~/types'
import { getPrescriptionType } from '~/utils/prescription'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'

export interface PrescriptionItemActionsLineItemProps
  extends ItemWithBadgesActionsProps {
  isInvoice: boolean
  isItemRetailOrderLineItem?: boolean
  isLineItem?: boolean
  onDelete?: () => void
  order: Order
}

const PrescriptionItemActionsLineItem = ({
  onDelete: onDeleteProp,
  order,
  isLineItem,
  isInvoice,
  isItemRetailOrderLineItem,
  ...rest
}: PrescriptionItemActionsLineItemProps) => {
  const dispatch = useDispatch()

  const isGlobalItem = useIsGlobalInventoryItem(order)
  const isFood = useIsFood(order)

  const id = isLineItem ? order.logId : order.id

  const isContextItem = useIsCurrentContextItem(order)

  const { isInHouse, isChewyActiveRx } = isItemRetailOrderLineItem
    ? { isInHouse: false, isChewyActiveRx: true }
    : getPrescriptionType(order.prescriptionType, order.origin)

  const { isDraft } = useGetPrescriptionStateType()(order.stateId)
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )

  const isChewyDisabled = isChewyActiveRx && !isDraft

  const isDeclinableOrDeletable = useIsDeclinableOrDeletable({
    orderType: order.type,
    stateId: order.stateId,
    prescriptionType: order.prescriptionType,
    origin: order.origin,
  })
  const { fetchUnifiedOrderAndOpenPrescription } =
    useOpenPrescriptionDialogAfterFetchUnifiedOrder({
      isChargeSheet: true,
      isInvoice,
    })

  const handleDelete = useDeletePrescription({
    hasRefills: Boolean(order.refills?.length),
    isFromSoap: Boolean(order.soapId),
    name: order.name,
    retailOrderLineItemLogId: isItemRetailOrderLineItem
      ? order.logId || undefined
      : undefined,
    order,
  })

  const openPrescription = () => {
    if (id) {
      fetchUnifiedOrderAndOpenPrescription(id)
    }
  }

  const handlePrint = () => {
    if (id) {
      dispatch(fetchPrescriptionPrintInfo({ ...order, id }))
    }
  }

  if (isEditPostedChargesEnabled) {
    return (
      <ItemWithBadgesActions
        tooltip={
          isItemRetailOrderLineItem ? null : (
            <OrderTooltip forceShowPrices order={order} />
          )
        }
        onAddRX={openPrescription}
        onDelete={handleDelete}
        onEdit={openPrescription}
        onPrint={handlePrint}
        onView={openPrescription}
        {...rest}
      />
    )
  }

  return (
    <ItemWithBadgesActions
      tooltip={
        isInHouse ? <OrderTooltip forceShowPrices order={order} /> : undefined
      }
      onAddRX={!isChewyDisabled && isFood ? openPrescription : undefined}
      onDelete={isDeclinableOrDeletable ? handleDelete : undefined}
      onEdit={!isChewyDisabled && isGlobalItem ? openPrescription : undefined}
      onPrint={!isChewyActiveRx && isContextItem ? handlePrint : undefined}
      onView={isChewyDisabled ? openPrescription : undefined}
      {...rest}
    />
  )
}

export default PrescriptionItemActionsLineItem
