import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { IdObject, Nil } from '@pbt/pbt-ui-components'

import MultiAttachmentDialog, {
  MultiAttachmentDialogProps,
} from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentDialog'
import MultiAttachmentFilters from '~/components/common/inputs/attachment/attachment-dialog/multi-attachment/MultiAttachmentFilters'
import { attachDocumentsToSoap } from '~/store/actions/soap'
import {
  createAttachmentFileList,
  getFilesIsCreating,
} from '~/store/duck/files'
import { getCurrentUserId } from '~/store/reducers/auth'
import { getAppointment, getIsAttachingDocuments } from '~/store/reducers/soap'
import { AttachmentGroup, FilesWithDetails } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

export interface SoapMultiAttachmentDialogProps
  extends Omit<MultiAttachmentDialogProps, 'renderMultiAttachment'> {
  clientId: string | Nil
  patientId: string | Nil
  soapBusinessId: string | Nil
  soapId: string | Nil
}

const SoapMultiAttachmentDialog = ({
  onClose,
  open,
  soapId,
  soapBusinessId,
  clientId,
  patientId,
}: SoapMultiAttachmentDialogProps) => {
  const dispatch = useDispatch()
  const { appointmentId, startDate } = useSelector(getAppointment) || {}
  const userId = useSelector(getCurrentUserId) as string

  const setCloseAfterAttachedOn = useCloseAfterCreation(
    onClose,
    getIsAttachingDocuments,
  )
  const setCloseAfterUploadedOn = useCloseAfterCreation(
    onClose,
    getFilesIsCreating,
  )

  const onDocumnentsUpload = (orderedItems: IdObject[]) => {
    const ids = R.pluck('id', orderedItems)
    setCloseAfterAttachedOn()
    if (soapId) {
      dispatch(attachDocumentsToSoap(ids, soapId, soapBusinessId, userId))
    }
  }

  const onFilesUpload = (group: AttachmentGroup) => {
    setCloseAfterUploadedOn()
    if (clientId && patientId && soapId && soapBusinessId) {
      dispatch(
        createAttachmentFileList(
          group as FilesWithDetails,
          clientId,
          patientId,
          soapId,
          soapBusinessId,
        ),
      )
    }
  }

  return (
    <MultiAttachmentDialog
      open={open}
      renderMultiAttachment={({
        closeEdit,
        fileIndexForImageEdit,
        setFileIndexForImageEdit,
      }) => (
        <MultiAttachmentFilters
          showDetails
          closeEdit={closeEdit}
          date={startDate}
          eventId={appointmentId}
          fileIndexForImageEdit={fileIndexForImageEdit}
          patientId={patientId}
          setFileIndexForImageEdit={setFileIndexForImageEdit}
          onDocumnentsUpload={onDocumnentsUpload}
          onFilesUpload={onFilesUpload}
        />
      )}
      onClose={onClose}
    />
  )
}

export default SoapMultiAttachmentDialog
