import React from 'react'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'

const { TAX } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  () => ({
    subtotalCell: {
      minWidth: TAX,
      width: TAX,
    },
  }),
  { name: 'FinanceItemTaxCellBody' },
)

export interface FinanceItemTaxCellBodyProps {
  isFullyRefunded?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  tableCellClassName: string
  tableCellWithBorder: string
}

const FinanceItemTaxCellBody = ({
  item,
  tableCellClassName,
  tableCellWithBorder,
  isFullyRefunded = false,
}: FinanceItemTaxCellBodyProps) => {
  const classes = useStyles()

  return (
    <TableCell
      align="right"
      className={classNames(
        tableCellClassName,
        tableCellWithBorder,
        classes.subtotalCell,
      )}
    >
      {!item.declined && Boolean(item.taxAmount) && (
        <Text variant={isFullyRefunded ? 'lowAccent2' : 'body2'}>
          {NumberUtils.formatMoney(item.taxAmount)}
        </Text>
      )}
    </TableCell>
  )
}

export default FinanceItemTaxCellBody
