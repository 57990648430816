import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { getUser } from '~/store/reducers/users'

import DetailsBackButton from '../DetailsBackButton'
import ClientDetails from './ClientDetails'

const ClientDetailsPage = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  const { clientId = '' } = useParams()
  const location = useLocation()
  const { patientId = '' } = (location.state || {}) as Record<string, string>
  const { t } = useTranslation('Common')

  const client = useSelector(getUser(clientId))

  const onBackButtonClick = () => {
    if (patientId) {
      goBack()
    } else if (client?.patients && client?.patients.length > 0) {
      navigate(`/client/${clientId}/patient/${client?.patients[0]}`)
    } else {
      navigate('/clients')
    }
  }

  return (
    <>
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Common:BACK_TO_CLIENT_AND_PATIENT_SUMMARY')}
      </DetailsBackButton>
      <ClientDetails />
    </>
  )
}

export default ClientDetailsPage
