export const FETCH_EXPORTER_URL = 'migration/FETCH_EXPORTER_URL'
export const FETCH_EXPORTER_URL_SUCCESS = 'migration/FETCH_EXPORTER_URL_SUCCESS'
export const FETCH_EXPORTER_URL_FAILURE = 'migration/FETCH_EXPORTER_URL_FAILURE'
export const CLEAR_EXPORTER_URL = 'migration/CLEAR_EXPORTER_URL'

export const FETCH_MIGRATION_SESSIONS = 'migration/FETCH_MIGRATION_SESSIONS'
export const FETCH_MIGRATION_SESSIONS_SUCCESS =
  'migration/FETCH_MIGRATION_SESSIONS_SUCCESS'
export const FETCH_MIGRATION_SESSIONS_FAILURE =
  'migration/FETCH_MIGRATION_SESSIONS_FAILURE'

export const UPDATE_SESSION_STATUS = 'migration/UPDATE_SESSION_STATUS'
export const UPDATE_SESSION_STATUS_SUCCESS =
  'migration/UPDATE_SESSION_STATUS_SUCCESS'
export const UPDATE_SESSION_STATUS_FAILURE =
  'migration/UPDATE_SESSION_STATUS_FAILURE'

export const START_MIGRATION_ANALYZE = 'migration/START_MIGRATION_ANALYZE'
export const START_MIGRATION_ANALYZE_SUCCESS =
  'migration/START_MIGRATION_ANALYZE_SUCCESS'
export const START_MIGRATION_ANALYZE_FAILURE =
  'migration/START_MIGRATION_ANALYZE_FAILURE'

export const FETCH_MIGRATION_ANALYZE_STATUS =
  'migration/FETCH_MIGRATION_ANALYZE_STATUS'
export const FETCH_MIGRATION_ANALYZE_STATUS_SUCCESS =
  'migration/FETCH_MIGRATION_ANALYZE_STATUS_SUCCESS'
export const FETCH_MIGRATION_ANALYZE_STATUS_FAILURE =
  'migration/FETCH_MIGRATION_ANALYZE_STATUS_FAILURE'

export const FETCH_MIGRATION_IMPORT_STATUS =
  'migration/FETCH_MIGRATION_IMPORT_STATUS'
export const FETCH_MIGRATION_IMPORT_STATUS_SUCCESS =
  'migration/FETCH_MIGRATION_IMPORT_STATUS_SUCCESS'
export const FETCH_MIGRATION_IMPORT_STATUS_FAILURE =
  'migration/FETCH_MIGRATION_IMPORT_STATUS_FAILURE'

export const START_MIGRATION = 'migration/START_MIGRATION'
export const START_MIGRATION_SUCCESS = 'migration/START_MIGRATION_SUCCESS'
export const START_MIGRATION_FAILURE = 'migration/START_MIGRATION_FAILURE'

export const FETCH_NOFIX_LOGS = 'migration/FETCH_NOFIX_LOGS'
export const FETCH_NOFIX_LOGS_SUCCESS = 'migration/FETCH_NOFIX_LOGS_SUCCESS'
export const FETCH_NOFIX_LOGS_FAILURE = 'migration/FETCH_NOFIX_LOGS_FAILURE'

export const EDIT_IMPORT_SETTINGS = 'migration/EDIT_IMPORT_SETTINGS'
export const EDIT_IMPORT_SETTINGS_SUCCESS =
  'migration/EDIT_IMPORT_SETTINGS_SUCCESS'
export const EDIT_IMPORT_SETTINGS_FAILURE =
  'migration/EDIT_IMPORT_SETTINGS_FAILURE'

export const FETCH_MAPPING_STRUCTURES = 'migration/FETCH_MAPPING_STRUCTURES'
export const FETCH_MAPPING_STRUCTURES_SUCCESS =
  'migration/FETCH_MAPPING_STRUCTURES_SUCCESS'
export const FETCH_MAPPING_STRUCTURES_FAILURE =
  'migration/FETCH_MAPPING_STRUCTURES_FAILURE'

export const FETCH_MORE_MAPPINGS = 'migration/FETCH_MORE_MAPPINGS'
export const FETCH_MORE_MAPPINGS_SUCCESS =
  'migration/FETCH_MORE_MAPPINGS_SUCCESS'
export const FETCH_MORE_MAPPINGS_FAILURE =
  'migration/FETCH_MORE_MAPPINGS_FAILURE'

export const CLEAR_MAPPINGS = 'migration/CLEAR_MAPPINGS'

export const EDIT_MAPPING = 'migration/EDIT_MAPPING'
export const EDIT_MAPPING_SUCCESS = 'migration/EDIT_MAPPING_SUCCESS'
export const EDIT_MAPPING_FAILURE = 'migration/EDIT_MAPPING_FAILURE'

export const CHOOSE_IMPORT_SESSION = 'migration/CHOOSE_IMPORT_SESSION'
export const ACCEPT_UPDATED_SESSION = 'migration/ACCEPT_UPDATED_SESSION'
export const CHECK_IMPORT_SETTINGS = 'migration/CHECK_IMPORT_SETTINGS'

export const APPLY_MAPPINGS = 'migration/APPLY_MAPPINGS'
export const APPLY_MAPPINGS_SUCCESS = 'migration/APPLY_MAPPINGS_SUCCESS'
export const APPLY_MAPPINGS_FAILURE = 'migration/APPLY_MAPPINGS_FAILURE'

export const FOCUS_MAPPING_ROW = 'migration/FOCUS_MAPPING_ROW'
export const FOCUS_MAPPING_STRUCTURE = 'migration/FOCUS_MAPPING_STRUCTURE'

export const FETCH_ACTIVATION_TOKEN = 'migration/FETCH_ACTIVATION_TOKEN'
export const FETCH_ACTIVATION_TOKEN_SUCCESS =
  'migration/FETCH_ACTIVATION_TOKEN_SUCCESS'
export const FETCH_ACTIVATION_TOKEN_FAILURE =
  'migration/FETCH_ACTIVATION_TOKEN_FAILURE'
export const FETCH_ACTIVATION_TOKEN_SILENT_FAILURE =
  'migration/FETCH_ACTIVATION_TOKEN_SILENT_FAILURE'

export const FETCH_SESSION_FILES = 'migration/FETCH_SESSION_FILES'
export const FETCH_SESSION_FILES_SUCCESS =
  'migration/FETCH_SESSION_FILES_SUCCESS'
export const FETCH_SESSION_FILES_FAILURE =
  'migration/FETCH_SESSION_FILES_FAILURE'
export const UPLOAD_SESSION_FILE = 'migration/UPLOAD_SESSION_FILE'
export const UPLOAD_SESSION_FILE_SUCCESS =
  'migration/UPLOAD_SESSION_FILE_SUCCESS'
export const UPLOAD_SESSION_FILE_FAILURE =
  'migration/UPLOAD_SESSION_FILE_FAILURE'
export const FETCH_SESSION_FILE_URL = 'migration/FETCH_SESSION_FILE_URL'
export const FETCH_SESSION_FILE_URL_SUCCESS =
  'migration/FETCH_SESSION_FILE_URL_SUCCESS'
export const FETCH_SESSION_FILE_URL_FAILURE =
  'migration/FETCH_SESSION_FILE_URL_FAILURE'
export const DELETE_SESSION_FILE = 'migration/DELETE_SESSION_FILE'
export const DELETE_SESSION_FILE_SUCCESS =
  'migration/DELETE_SESSION_FILE_SUCCESS'
export const DELETE_SESSION_FILE_FAILURE =
  'migration/DELETE_SESSION_FILE_FAILURE'

export const START_PREPROCESSING = 'migration/START_PREPROCESSING'
export const START_PREPROCESSING_SUCCESS =
  'migration/START_PREPROCESSING_SUCCESS'
export const START_PREPROCESSING_FAILURE =
  'migration/START_PREPROCESSING_FAILURE'
export const FETCH_PREPROCESSING_STATUS = 'migration/FETCH_PREPROCESSING_STATUS'
export const FETCH_PREPROCESSING_STATUS_SUCCESS =
  'migration/FETCH_PREPROCESSING_STATUS_SUCCESS'
export const FETCH_PREPROCESSING_STATUS_FAILURE =
  'migration/FETCH_PREPROCESSING_STATUS_FAILURE'

export const CREATE_IMPORT_SESSION = 'migration/CREATE_IMPORT_SESSION'
export const CREATE_IMPORT_SESSION_SUCCESS =
  'migration/CREATE_IMPORT_SESSION_SUCCESS'
export const CREATE_IMPORT_SESSION_FAILURE =
  'migration/CREATE_IMPORT_SESSION_FAILURE'

export const FETCH_IMPORT_SETTINGS = 'migration/FETCH_IMPORT_SETTINGS'
export const FETCH_IMPORT_SETTINGS_SUCCESS =
  'migration/FETCH_IMPORT_SETTINGS_SUCCESS'
export const FETCH_IMPORT_SETTINGS_FAILURE =
  'migration/FETCH_IMPORT_SETTINGS_FAILURE'

export const GENERATE_ACTIVATION_TOKEN = 'migration/GENERATE_ACTIVATION_TOKEN'
export const GENERATE_ACTIVATION_TOKEN_SUCCESS =
  'migration/GENERATE_ACTIVATION_TOKEN_SUCCESS'
export const GENERATE_ACTIVATION_TOKEN_FAILURE =
  'migration/GENERATE_ACTIVATION_TOKEN_FAILURE'

export const FETCH_SESSION_SUPPORTED_ENTITIES =
  'migration/FETCH_SESSION_SUPPORTED_ENTITIES'
export const FETCH_SESSION_SUPPORTED_ENTITIES_SUCCESS =
  'migration/FETCH_SESSION_SUPPORTED_ENTITIES_SUCCESS'
export const FETCH_SESSION_SUPPORTED_ENTITIES_FAILURE =
  'migration/FETCH_SESSION_SUPPORTED_ENTITIES_FAILURE'

export const FETCH_BUSINESS_RELATIONS_MAPPINGS =
  'migration/FETCH_BUSINESS_RELATIONS_MAPPINGS'
export const FETCH_BUSINESS_RELATIONS_MAPPINGS_SUCCESS =
  'migration/FETCH_BUSINESS_RELATIONS_MAPPINGS_SUCCESS'
export const FETCH_BUSINESS_RELATIONS_MAPPINGS_FAILURE =
  'migration/FETCH_BUSINESS_RELATIONS_MAPPINGS_FAILURE'

export const CLEAR_BUSINESS_RELATIONS_MAPPINGS =
  'migration/CLEAR_BUSINESS_RELATIONS_MAPPINGS'
