import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles(
  () => ({
    noBorderCell: {
      borderRight: 'none !important',
    },
  }),
  { name: 'QuantityCellSubHead' },
)

export interface QuantityCellSubHeadProps {
  className?: string
  isEstimate: boolean
  prePaidEnabled: boolean
  rangeEnabled: boolean
}

const QuantityCellSubHead = ({
  prePaidEnabled,
  rangeEnabled,
  isEstimate,
  className,
}: QuantityCellSubHeadProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  return (
    <>
      {prePaidEnabled && !isEstimate && (
        <>
          <TableCell className={classNames(className, classes.noBorderCell)}>
            {t('Common:PAYMENTS.PAID')}
          </TableCell>
          <TableCell className={classNames(className, classes.noBorderCell)}>
            {t('Common:USED')}
          </TableCell>
          <TableCell className={className}>
            {t('Invoices:TABLE.SUB_HEAD.REMAINS')}
          </TableCell>
        </>
      )}
      {prePaidEnabled && isEstimate && (
        <>
          <TableCell className={classNames(className, classes.noBorderCell)}>
            {t('Invoices:TABLE.SUB_HEAD.FIRST_VISIT')}
          </TableCell>
          <TableCell className={className}>{t('Common:TOTAL')}</TableCell>
        </>
      )}
      {!prePaidEnabled && <TableCell className={className} />}
      {rangeEnabled && !prePaidEnabled && <TableCell className={className} />}
    </>
  )
}

export default QuantityCellSubHead
