import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import MultiFileInput from '../../../file-input/MultiFileInput'
import FileList, { FileListHandle } from './FileList'

const useStyles = makeStyles(
  () => ({
    button: {
      minWidth: 196,
      height: 40,
    },
  }),
  { name: 'MultiAttachmentFiles' },
)

interface MultiAttachmentFilesProps {
  children?: JSX.Element
  files: File[]
  handleDelete: (index: number) => void
  handleFileChange: (file: File, index: number) => void
  isSaving?: boolean
  onAdd: () => void
  onAttachmentFilesSelected: (newFiles: File[]) => void
  setFileIndexForImageEdit: (index: number) => void
}

const MultiAttachmentFiles = forwardRef<
  FileListHandle,
  MultiAttachmentFilesProps
>(
  (
    {
      onAdd,
      onAttachmentFilesSelected,
      files,
      handleDelete,
      handleFileChange,
      children,
      setFileIndexForImageEdit,
      isSaving,
    },
    ref,
  ) => {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    return (
      <>
        <MultiFileInput onFilesSelected={onAttachmentFilesSelected} />
        {children}
        <FileList
          files={files}
          ref={ref}
          onDelete={handleDelete}
          onEdit={setFileIndexForImageEdit}
          onFileChange={handleFileChange}
        />
        <Grid item py={2}>
          <ButtonWithLoader
            className={classes.button}
            loading={isSaving}
            onClick={onAdd}
          >
            {t('Common:ADD_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </>
    )
  },
)

export default MultiAttachmentFiles
