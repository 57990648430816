import { gql } from '@apollo/client'

export const CONSTANT_ENTITY_FIELDS = gql`
  fragment ConstantEntityFields on ConstantEntity {
    id
    name
    createdAt
    updatedAt
  }
`
