import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import {
  BasePuiDialogProps,
  DateUtils,
  Defaults,
  Nil,
  Utils,
} from '@pbt/pbt-ui-components'

import EmailDialogWithSections, {
  EmailDialogWithSectionsProps,
  SuccessDialogKind,
} from '~/components/common/dialog/email/EmailDialogWithSections'
import { GeneratingPdfContentKind } from '~/constants/communications'
import { fetchClient } from '~/store/actions/clients'
import { emailReportCard } from '~/store/actions/communications'
import {
  clearReportCardEmailTemplate,
  clearReportCardPrintTemplate,
  fetchReportCardConfig,
  fetchReportCardEmailTemplate,
  fetchReportCardPrintTemplate,
  getReportCardConfig,
  getReportCardEmailTemplate,
  getReportCardIsLoading,
  getReportCardIsSendingEmail,
  getReportCardPrintTemplate,
  getReportCardTemplateIsReceiving,
} from '~/store/duck/reportCard'
import { getPatient } from '~/store/reducers/patients'
import {
  getAppointment,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { EmailEntityConfig } from '~/types'

interface AppointmentReportCardDialogProps
  extends BasePuiDialogProps,
    Pick<
      EmailDialogWithSectionsProps,
      | 'allowedTransports'
      | 'defaultEmailSentAlertMessage'
      | 'defaultMessage'
      | 'emailOnlyTransport'
      | 'onViewConversation'
      | 'successDialogKind'
    > {
  soapId: string | Nil
}

const AppointmentReportCardDialog = ({
  open,
  onClose,
  soapId,
  ...rest
}: AppointmentReportCardDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap', 'Dialogs'])

  const config = useSelector(getReportCardConfig)
  const configIsLoading = useSelector(getReportCardIsLoading)
  const emailTemplate = useSelector(getReportCardEmailTemplate)
  const printTemplate = useSelector(getReportCardPrintTemplate)
  const templateIsReceiving = useSelector(getReportCardTemplateIsReceiving)
  const appointment = useSelector(getAppointment)
  const patientId = useSelector(getPatientId)
  const patient = useSelector(getPatient(patientId))
  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))

  const date =
    appointment?.startDate && DateUtils.formatDate(appointment.startDate)
  const subjectName = patient?.name || t('Common:PATIENT')
  const defaultSubject = date
    ? t('Soap:REPORT_CARD_FOR_PATIENTS_VISIT_ON_DATE', {
        patientName: subjectName,
        onDate: date,
      })
    : t('Soap:REPORT_CARD_FOR_PATIENTS_VISIT', { patientName: subjectName })

  useEffect(() => {
    if (soapId) {
      dispatch(fetchReportCardConfig(soapId))
    }
  }, [soapId])

  useEffect(() => {
    if (clientId && !client?.email) {
      dispatch(fetchClient({ clientId }))
    }
  }, [clientId])

  const handleClose = () => {
    dispatch(clearReportCardEmailTemplate())
    if (onClose) {
      onClose()
    }
  }

  const fetchEmailTemplate = (emailConfig: EmailEntityConfig) => {
    if (open && emailConfig?.sections && soapId) {
      dispatch(fetchReportCardEmailTemplate(soapId, emailConfig))
    }
  }

  const fetchEmailTemplateDebounced = useDebouncedCallback(
    fetchEmailTemplate,
    Defaults.DEBOUNCE_ACTION_TIME,
  )

  const handleSendEmail = (emailConfig: EmailEntityConfig) => {
    dispatch(emailReportCard({ ...emailConfig, soapId }))
  }

  const handlePrint = (emailConfig: EmailEntityConfig) => {
    if (soapId) {
      dispatch(fetchReportCardPrintTemplate(soapId, emailConfig))
    }
  }

  const pdfFileConfig = { ...config, soapId }

  return (
    <EmailDialogWithSections
      clientId={clientId}
      config={config}
      configIsLoading={configIsLoading}
      defaultSubject={defaultSubject}
      dialogHeader={t('Dialogs:EMAIL_REPORT_CARD_DIALOG.HEADER')}
      emailTemplate={emailTemplate}
      isSendingEmailSelector={getReportCardIsSendingEmail}
      open={open}
      patient={patient}
      pdfContentKind={GeneratingPdfContentKind.REPORT_CARD}
      pdfFileConfig={pdfFileConfig}
      popupEmailSentMessageHandler={({ mailTo }) =>
        t('Dialogs:EMAIL_REPORT_CARD_DIALOG.EMAIL_SENT_MESSAGE', {
          patientName: patient?.name || t('Common:PATIENT'),
          clientName: Utils.getPersonString(client),
          mailTo: mailTo || client?.email,
        })
      }
      printTemplate={printTemplate}
      soapId={soapId}
      successDialogKind={SuccessDialogKind.POPUP}
      templateIsReceiving={templateIsReceiving}
      onChange={fetchEmailTemplateDebounced}
      onClearPrint={clearReportCardPrintTemplate}
      onClose={handleClose}
      onPrint={handlePrint}
      onSendEmail={handleSendEmail}
      {...rest}
    />
  )
}

export default AppointmentReportCardDialog
