import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import TransportIcon from '~/components/dashboard/communications/TransportIcon'
import { Conversation } from '~/types'

const useStyles = makeStyles(
  () => ({
    icon: {
      width: '0.75em',
      height: '0.75em',
    },
  }),
  { name: 'CommunicationTypeCell' },
)

interface CommunicationTypeCellProps {
  item: Conversation
}

const CommunicationTypeCell = ({ item }: CommunicationTypeCellProps) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center">
      <TransportIcon
        classes={{ transportIcon: classes.icon }}
        type={item.transport}
      />
    </Grid>
  )
}

export default CommunicationTypeCell
