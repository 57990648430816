import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CircularProgressOverlay,
  DateFormat,
  DebouncedInlineSearch,
  Defaults,
  Nil,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import {
  fetchMoreReminderProtocolsSearchList,
  fetchReminderProtocolsSearchList,
  geIstSearchItemsLoading,
  getSearchItems,
  getSearchTotalCount,
} from '~/store/duck/reminderProtocols'
import { getIsLoading } from '~/store/duck/reminders'
import { getUnitsState } from '~/store/duck/settings'
import { registerWarnAlert } from '~/store/duck/uiAlerts'
import { usePatientWeight } from '~/store/hooks/patient'
import { getAgeUnits, getWeightUnits } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { ReminderProtocol } from '~/types'

import {
  checkAgeRange,
  checkGender,
  checkSpecies,
  checkWeightRange,
} from '../reminderUtils'
import AddReminderDialogActions from './AddReminderDialogActions'
import ReminderProtocolRow from './ReminderProtocolRow'

const SPINNER_SIZE = 32

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    remindersContainer: {
      height: 440,
      position: 'relative',
    },
    buttons: {
      borderTop: theme.constants.tableBorder,
    },
    heading: {
      borderBottom: theme.constants.tableBorder,
    },
    spinner: {
      display: 'block',
      margin: `${theme.spacing(2)} auto`,
    },
  }),
  { name: 'StepAddReminderFromProtocol' },
)

export interface StepAddReminderFromProtocolProps {
  onBack?: () => void
  onProtocolSelected?: (protocol: ReminderProtocol) => void
  patientId: string | Nil
}

const StepAddReminderFromProtocol = ({
  onProtocolSelected,
  onBack,
  patientId,
}: StepAddReminderFromProtocolProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search', 'Time', 'Dialogs'])
  const unitsState = useSelector(getUnitsState)
  const WeightUnits = useSelector(getWeightUnits)

  const defaultUnitId = Utils.findConstantIdByName(
    unitsState[UnitTypes.WEIGHT],
    WeightUnits,
  )
  const kgUnitId = Utils.findConstantIdByName(WeightTypes.KG, WeightUnits)
  const items = useSelector(getSearchItems)
  const totalCount = useSelector(getSearchTotalCount)
  const isLoading = useSelector(geIstSearchItemsLoading)
  const isReminderLoading = useSelector(getIsLoading)
  const patient = useSelector(getPatient(patientId))
  const [selectedProtocol, setSelectedProtocol] = useState<ReminderProtocol>()
  const [searchTerm, setSearchTerm] = useState('')
  const AgeUnits = useSelector(getAgeUnits)
  const patientWeight = usePatientWeight(
    patientId,
    DateFormat.SHORT_DATE_YEAR_ABBREV,
  )
  const handleChange = (item: ReminderProtocol) => {
    setSelectedProtocol(item.id === selectedProtocol?.id ? undefined : item)
  }
  const patientSpecies = patient?.species
  const patientGender = patient?.gender
  useEffect(() => {
    dispatch(
      fetchReminderProtocolsSearchList(searchTerm || null, patient?.species),
    )
  }, [searchTerm])

  const handleLoadMore = () => {
    dispatch(
      fetchMoreReminderProtocolsSearchList(
        searchTerm || null,
        patient?.species,
        items.length,
        items.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      ),
    )
  }

  const handleAdd = () => {
    if (selectedProtocol && onProtocolSelected) {
      let isValid = true
      if (patientSpecies && selectedProtocol.speciesIds.length >= 1) {
        if (!checkSpecies(selectedProtocol, patientSpecies)) {
          isValid = false
          dispatch(
            registerWarnAlert(
              t('Dialogs:REMINDER_PROTOCOL_RESTRICTIONS.SPECIES'),
            ),
          )
        }
      }
      if (patientGender && selectedProtocol.genderRestrictions.length >= 1) {
        if (!checkGender(selectedProtocol, patientGender)) {
          isValid = false
          dispatch(
            registerWarnAlert(
              t('Dialogs:REMINDER_PROTOCOL_RESTRICTIONS.GENDER'),
            ),
          )
        }
      }

      if (
        patientWeight &&
        selectedProtocol.weightMin &&
        selectedProtocol.weightMax
      ) {
        if (
          !checkWeightRange(
            selectedProtocol,
            patientWeight,
            defaultUnitId,
            kgUnitId,
            unitsState,
          )
        ) {
          isValid = false
          dispatch(
            registerWarnAlert(
              t('Dialogs:REMINDER_PROTOCOL_RESTRICTIONS.WEIGHT'),
            ),
          )
        }
      }
      if (
        patient?.dateOfBirth &&
        (selectedProtocol.ageMax || selectedProtocol.ageMin)
      ) {
        if (
          !checkAgeRange(
            selectedProtocol,
            patient.dateOfBirth,
            patient.deceasedDate,
            AgeUnits,
          )
        ) {
          isValid = false
          dispatch(
            registerWarnAlert(t('Dialogs:REMINDER_PROTOCOL_RESTRICTIONS.AGE')),
          )
        }
      }
      if (isValid) {
        onProtocolSelected(selectedProtocol)
      }
    }
  }

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column">
        <Grid item pb={1} pt={2} px={3}>
          <DebouncedInlineSearch
            isLoading={isLoading}
            placeholder={t('Search:REMINDER_RULES')}
            onChange={setSearchTerm}
          />
        </Grid>
        <Grid container className={classes.heading} pb={1}>
          <Grid item pl={7} xs={5}>
            <Text strong variant="lowAccent2">
              {t('Common:REMINDER_NAME')}
            </Text>
          </Grid>
          <Grid item xs={2}>
            <Text strong variant="lowAccent2">
              {t('Time:LABEL.DUE_DATE')}
            </Text>
          </Grid>
          <Grid item xs={5}>
            <Text strong variant="lowAccent2">
              {t('Common:WHAT_IS_DUE_OR_EXPECTED')}
            </Text>
          </Grid>
        </Grid>
        <Grid className={classes.remindersContainer} id="reminders-container">
          <CircularProgressOverlay
            open={(totalCount === undefined && isLoading) || isReminderLoading}
          />
          <InfiniteScroll
            dataLength={items.length}
            hasMore={totalCount !== undefined && items.length < totalCount}
            height={440}
            loader={
              <CircularProgress
                className={classes.spinner}
                color="primary"
                size={SPINNER_SIZE}
              />
            }
            next={handleLoadMore}
            scrollableTarget="reminders-container"
          >
            {items.map((item) => (
              <ReminderProtocolRow
                checked={item.id === selectedProtocol?.id}
                item={item}
                key={item.id}
                onChange={() => handleChange(item)}
              />
            ))}
          </InfiniteScroll>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.buttons}
        justifyContent="space-between"
        px={3}
        py={2}
      >
        <AddReminderDialogActions
          isProceedDisabled={!selectedProtocol}
          proceedLabel={t('Common:ADD_ACTION')}
          onBack={onBack}
          onProceed={handleAdd}
        />
      </Grid>
    </Grid>
  )
}

export default StepAddReminderFromProtocol
