import React, { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { TextWithTooltip, useFields, Utils } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getGroupSharingModes } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeRecordSharingFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import RecordSharingModeNames from './RecordSharingModeNames'

const useStyles = makeStyles(
  (theme) => ({
    radioLabel: {
      padding: theme.spacing(0.5),
    },
  }),
  { name: 'RecordSharingConfigurationSection' },
)

const RecordSharingConfigurationSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Admin')

  const GroupSharingModes = useSelector(getGroupSharingModes)

  const groupSharingDisabledModeId = Utils.findConstantIdByName(
    RecordSharingModeNames.DISABLED,
    GroupSharingModes,
  )
  const groupSharingAdhocModeId = Utils.findConstantIdByName(
    RecordSharingModeNames.ADHOC,
    GroupSharingModes,
  )
  const groupSharingAutoModeId = Utils.findConstantIdByName(
    RecordSharingModeNames.AUTO,
    GroupSharingModes,
  )

  const { fields, reset } = useFields([
    {
      name: 'groupSharingModeId',
      initialValue: business.groupSharingModeId || groupSharingDisabledModeId,
    },
  ])

  const { groupSharingModeId } = fields

  usePracticeFieldsSection<PracticeRecordSharingFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.RECORD_SHARING,
    reset,
  })

  const isGroupSharingEnabled =
    groupSharingModeId.value !== groupSharingDisabledModeId

  return (
    <Grid container item direction="column">
      <PuiSwitch
        field={{
          ...groupSharingModeId,
          value: isGroupSharingEnabled,
          set: () => {
            groupSharingModeId.setValue(
              isGroupSharingEnabled
                ? groupSharingDisabledModeId
                : groupSharingAdhocModeId,
            )
          },
        }}
        label={
          <TextWithTooltip
            allowWrap
            tooltipText={
              <Trans
                components={{
                  ul: <ul />,
                  li: <li />,
                }}
                i18nKey="Admin:PRACTICE.RECORD_SHARING_INFO"
              />
            }
          >
            {t('Admin:PRACTICE.ENABLE_RECORD_SHARING')}
          </TextWithTooltip>
        }
      />
      {isGroupSharingEnabled && (
        <Grid item pl={7}>
          <RadioGroup
            aria-label="record-sharing-radio-group"
            name="record-sharing-radio-group"
            value={groupSharingModeId.value}
            onChange={(_, radioValue) =>
              groupSharingModeId.setValue(radioValue)
            }
          >
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              control={<Radio />}
              label={t('Admin:PRACTICE.RECORDS_CAN_BE_SHARED')}
              value={groupSharingAdhocModeId}
            />
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              control={<Radio />}
              label={t('Admin:PRACTICE.ALL_RECORDS_SHARED')}
              value={groupSharingAutoModeId}
            />
          </RadioGroup>
        </Grid>
      )}
    </Grid>
  )
}

export default memo(RecordSharingConfigurationSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
