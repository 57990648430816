import { AnyAction } from 'redux'

import type { RootState } from '../index'

export const SET_NEW_VERSION_AVAILABLE =
  'serviceWorker/SET_NEW_VERSION_AVAILABLE'
export const SET_UPDATE_NOTIFICATION_LAST_SHOWN_DATE =
  'serviceWorker/SET_UPDATE_NOTIFICATION_LAST_SHOWN_DATE'

export const setNewVersionAvailable = (available: boolean) => ({
  type: SET_NEW_VERSION_AVAILABLE,
  available,
})
export const setUpdateNotificationLastShownDate = (date: string) => ({
  type: SET_UPDATE_NOTIFICATION_LAST_SHOWN_DATE,
  date,
})

export type ServiceWorkerState = {
  newVersionAvailable: boolean
  updateNotificationLastShownDate: string | null
}

const INITIAL_STATE: ServiceWorkerState = {
  newVersionAvailable: false,
  updateNotificationLastShownDate: null,
}

export const serviceWorkerReducer = (
  state: ServiceWorkerState = INITIAL_STATE,
  action: AnyAction,
): ServiceWorkerState => {
  switch (action.type) {
    case SET_NEW_VERSION_AVAILABLE:
      return { ...state, newVersionAvailable: action.available }
    case SET_UPDATE_NOTIFICATION_LAST_SHOWN_DATE:
      return { ...state, updateNotificationLastShownDate: action.date }
    default:
      return state
  }
}

export const getServiceWorker = (state: RootState): ServiceWorkerState =>
  state.serviceWorker
export const getNewVersionAvailable = (state: RootState) =>
  getServiceWorker(state).newVersionAvailable
export const getUpdateNotificationLastShownDate = (state: RootState) =>
  getServiceWorker(state).updateNotificationLastShownDate
