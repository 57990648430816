import { User } from '@pbt/pbt-ui-components'

export interface ClientData extends Omit<User, 'patients' | 'id'> {
  patients?: { name: string }[]
}

export enum BillingActivitySectionName {
  INVOICES = 'Invoices',
  ESTIMATES = 'Estimates',
  PAYMENTS = 'Payments',
}

export type ClientBillingActivityPageType = 'INVOICE' | 'ESTIMATE' | 'PAYMENT'
