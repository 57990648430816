export const EMAIL_INVOICE = 'communications/EMAIL_INVOICE'
export const EMAIL_INVOICE_V2 = 'communications/EMAIL_INVOICE_V2'
export const EMAIL_INVOICE_SUCCESS = 'communications/EMAIL_INVOICE_SUCCESS'
export const EMAIL_INVOICE_V2_SUCCESS =
  'communications/EMAIL_INVOICE_V2_SUCCESS'
export const EMAIL_INVOICE_FAILURE = 'communications/EMAIL_INVOICE_FAILURE'

export const EMAIL_CHARGE_SHEET = 'communications/EMAIL_CHARGE_SHEET'
export const EMAIL_CHARGE_SHEET_SUCCESS =
  'communications/EMAIL_CHARGE_SHEET_SUCCESS'
export const EMAIL_CHARGE_SHEET_FAILURE =
  'communications/EMAIL_CHARGE_SHEET_FAILURE'

export const EMAIL_REPORT_CARD = 'communications/EMAIL_REPORT_CARD'
export const EMAIL_REPORT_CARD_SUCCESS =
  'communications/EMAIL_REPORT_CARD_SUCCESS'
export const EMAIL_REPORT_CARD_FAILURE =
  'communications/EMAIL_REPORT_CARD_FAILURE'

export const EMAIL_PAYMENT = 'communications/EMAIL_PAYMENT'
export const EMAIL_PAYMENT_SUCCESS = 'communications/EMAIL_PAYMENT_SUCCESS'
export const EMAIL_PAYMENT_FAILURE = 'communications/EMAIL_PAYMENT_FAILURE'

export const SEND_MEMBERSHIP_HYBRID_INVITE =
  'communications/SEND_MEMBERSHIP_HYBRID_INVITE'
export const SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS =
  'communications/SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS'
export const SEND_MEMBERSHIP_HYBRID_INVITE_FAILURE =
  'communications/SEND_MEMBERSHIP_HYBRID_INVITE_FAILURE'

export const EMAIL_PRESCRIPTION = 'communications/EMAIL_PRESCRIPTION'
export const EMAIL_PRESCRIPTION_SUCCESS =
  'communications/EMAIL_PRESCRIPTION_SUCCESS'
export const EMAIL_PRESCRIPTION_FAILURE =
  'communications/EMAIL_PRESCRIPTION_FAILURE'

export const EMAIL_LAB_RESULT = 'communications/EMAIL_LAB_RESULT'
export const EMAIL_LAB_RESULT_SUCCESS =
  'communications/EMAIL_LAB_RESULT_SUCCESS'
export const EMAIL_LAB_RESULT_FAILURE =
  'communications/EMAIL_LAB_RESULT_FAILURE'

export const EMAIL_MED_HISTORY = 'communications/EMAIL_MED_HISTORY'
export const EMAIL_MED_HISTORY_SUCCESS =
  'communications/EMAIL_MED_HISTORY_SUCCESS'
export const EMAIL_MED_HISTORY_FAILURE =
  'communications/EMAIL_MED_HISTORY_FAILURE'

export const GENERATE_PDF_FOR_INVOICE =
  'communications/GENERATE_PDF_FOR_INVOICE'
export const GENERATE_PDF_FOR_INVOICE_V2 =
  'communications/GENERATE_PDF_FOR_INVOICE_V2'
export const GENERATE_PDF_FOR_INVOICE_FAILURE =
  'communications/GENERATE_PDF_FOR_INVOICE_FAILURE'
export const GENERATE_PDF_FOR_INVOICE_SUCCESS =
  'communications/GENERATE_PDF_FOR_INVOICE_SUCCESS'

export const GENERATE_PDF_FOR_CHARGE_SHEET =
  'communications/GENERATE_PDF_FOR_CHARGE_SHEET'
export const GENERATE_PDF_FOR_CHARGE_SHEET_FAILURE =
  'communications/GENERATE_PDF_FOR_CHARGE_SHEET_FAILURE'
export const GENERATE_PDF_FOR_CHARGE_SHEET_SUCCESS =
  'communications/GENERATE_PDF_FOR_CHARGE_SHEET_SUCCESS'

export const GENERATE_PDF_FOR_REPORT_CARD =
  'communications/GENERATE_PDF_FOR_REPORT_CARD'
export const GENERATE_PDF_FOR_REPORT_CARD_SUCCESS =
  'communications/GENERATE_PDF_FOR_REPORT_CARD_SUCCESS'
export const GENERATE_PDF_FOR_REPORT_CARD_FAILURE =
  'communications/GENERATE_PDF_FOR_REPORT_CARD_FAILURE'

export const GENERATE_PDF_FOR_PRESCRIPTION =
  'communications/GENERATE_PDF_FOR_PRESCRIPTION'
export const GENERATE_PDF_FOR_PRESCRIPTION_SUCCESS =
  'communications/GENERATE_PDF_FOR_PRESCRIPTION_SUCCESS'
export const GENERATE_PDF_FOR_PRESCRIPTION_FAILURE =
  'communications/GENERATE_PDF_FOR_PRESCRIPTION_FAILURE'

export const GENERATE_PDF_FOR_MEDICAL_HISTORY =
  'communications/GENERATE_PDF_FOR_MEDICAL_HISTORY'
export const GENERATE_PDF_FOR_MEDICAL_HISTORY_SUCCESS =
  'communications/GENERATE_PDF_FOR_MEDICAL_HISTORY_SUCCESS'
export const GENERATE_PDF_FOR_MEDICAL_HISTORY_FAILURE =
  'communications/GENERATE_PDF_FOR_MEDICAL_HISTORY_FAILURE'

export const WS_REPORT_CARD_PDF_CREATE =
  'communications/WS_REPORT_CARD_PDF_CREATE'
export const WS_REPORT_CARD_PDF_CREATE_SUCCESS =
  'communications/WS_REPORT_CARD_PDF_CREATE_SUCCESS'
export const WS_REPORT_CARD_PDF_CREATE_FAILURE =
  'communications/WS_REPORT_CARD_PDF_CREATE_FAILURE'

export const WS_REPORT_CARD_EMAIL_CREATE =
  'communications/WS_REPORT_CARD_EMAIL_CREATE'
export const WS_REPORT_CARD_EMAIL_CREATE_SUCCESS =
  'communications/WS_REPORT_CARD_EMAIL_CREATE_SUCCESS'
export const WS_REPORT_CARD_EMAIL_CREATE_FAILURE =
  'communications/WS_REPORT_CARD_EMAIL_CREATE_FAILURE'

export const WS_MEDICAL_HISTORY_PDF_CREATE =
  'communications/WS_MEDICAL_HISTORY_PDF_CREATE'
export const WS_MEDICAL_HISTORY_PDF_CREATE_SUCCESS =
  'communications/WS_MEDICAL_HISTORY_PDF_CREATE_SUCCESS'
export const WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE =
  'communications/WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE'

export const WS_MEDICAL_HISTORY_EMAIL_CREATE =
  'communications/WS_MEDICAL_HISTORY_EMAIL_CREATE'
export const WS_MEDICAL_HISTORY_EMAIL_CREATE_SUCCESS =
  'communications/WS_MEDICAL_HISTORY_EMAIL_CREATE_SUCCESS'
export const WS_MEDICAL_HISTORY_EMAIL_CREATE_FAILURE =
  'communications/WS_MEDICAL_HISTORY_EMAIL_CREATE_FAILURE'
