import React from 'react'
import { useSelector } from 'react-redux'
import { Constant } from '@pbt/pbt-ui-components'

import TimeUnitsSelect, {
  TimeUnitsSelectProps,
} from '~/components/common/TimeUnitsSelect'
import { getAppointmentCommunicationTimeUnits } from '~/store/reducers/constants'

const AppointmentCommunicationTimeUnitsSelect = ({
  amount,
  ...rest
}: TimeUnitsSelectProps) => {
  const AppointmentCommunicationTimeUnits: Constant[] = useSelector(
    getAppointmentCommunicationTimeUnits,
  )

  return (
    <TimeUnitsSelect
      amount={Number(amount)}
      items={AppointmentCommunicationTimeUnits}
      {...rest}
    />
  )
}

export default AppointmentCommunicationTimeUnitsSelect
