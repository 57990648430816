import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text, TextProps } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      width: 16,
      height: 16,
      color: theme.colors.alertWarning,
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
    },
  }),
  { name: 'WarningRowLabel' },
)

export interface WarningRowLabelProps extends TextProps {
  classes?: ClassesType<typeof useStyles>
  message?: React.ReactNode
}

const WarningRowLabel = ({
  classes: classesProp,
  message,
  ...rest
}: WarningRowLabelProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Text variant="body2" {...rest}>
      <WarnAlert className={classes.icon} />
      {message}
    </Text>
  )
}

export default WarningRowLabel
