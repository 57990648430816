import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Text } from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import ContactTargetSlot, {
  getSlotId,
} from '~/components/common/ContactTargetSlot'
import { ContactSlot } from '~/types'

import ConversationTransportSelect from './ConversationTransportSelect'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      minHeight: 240,
    },
    addButton: {
      width: 150,
    },
    buttonsSection: {
      borderTop: theme.constants.tableBorder,
    },
  }),
  { name: 'NewConversation' },
)

const isPayloadSlot = (contactSlot: ContactSlot) =>
  Boolean(contactSlot.contactId) || Boolean(contactSlot.clientId)

export interface NewConversationProps {
  contactSlot: ContactSlot
  handleAddConversation: () => void
  isLoading?: boolean
  setContactSlot: (contactSlot: ContactSlot) => void
  setTransport: (transport: ConversationTransport) => void
  transport?: ConversationTransport
}

const NewConversation = ({
  isLoading,
  transport,
  setTransport,
  handleAddConversation,
  contactSlot,
  setContactSlot,
}: NewConversationProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const rootRef = useRef<HTMLDivElement>(null)

  const isContactChosen = isPayloadSlot(contactSlot)

  return (
    <Grid container item direction="column" ref={rootRef}>
      <Grid container className={classes.content} direction="column" px={2}>
        <Grid container item>
          <Text strong mb={2} pb={1} pt={2} variant="body2">
            {t('Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.SEARCH_TITLE')}
          </Text>
          <ContactTargetSlot
            showAddClient
            anchorRef={rootRef}
            key={getSlotId(contactSlot)}
            visibleResults={6}
            onSlotChange={(newContactSlot) => setContactSlot(newContactSlot)}
            {...contactSlot}
          />
        </Grid>
        <Grid mt={2}>
          {isContactChosen && (
            <ConversationTransportSelect
              contactSlot={contactSlot}
              label={t(
                'Dialogs:NEW_CONVERSATION_WITH_STEPS_DIALOG.TYPE_CONVERSATION',
              )}
              transport={transport}
              onChange={setTransport}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.buttonsSection} p={2}>
        <ButtonWithLoader
          className={classes.addButton}
          disabled={isLoading || !isContactChosen || !transport}
          loading={isLoading}
          onClick={handleAddConversation}
        >
          {t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default NewConversation
