import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import { getOrdersIsSendingOrReceiving } from '~/store/reducers/orders'
import { DiagnosesState } from '~/types'
import useDialog from '~/utils/useDialog'

import ItemWithBadgesActions from '../../item-with-badges/ItemWithBadgesActions'

export interface DifferentialDiagnoseActionsProps {
  diagnose: DiagnosesState
  editDisabled?: boolean
  onChange: (id: string) => void
  onNotesChange: (notes: string) => void
}

const DifferentialDiagnoseActions = ({
  diagnose,
  editDisabled,
  onNotesChange,
  onChange,
}: DifferentialDiagnoseActionsProps) => {
  const { t } = useTranslation('Common')
  const [openNotesDialog] = useDialog(DialogNames.NOTES)

  const handleUpdateNotes = () => {
    openNotesDialog({
      name: diagnose.name,
      notes: diagnose.notes,
      onUpdateNotes: onNotesChange,
      isLoadingSelector: getOrdersIsSendingOrReceiving,
    })
  }

  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_RAIL_DIFFERENTIAL_DIAGNOSE,
  })

  const handleDelete = () => {
    if (!editDisabled) {
      openConfirmAlert({
        message: t('Common:YOU_WILL_REMOVE_SOMETHING', {
          something: LanguageUtils.getTranslatedFieldName(diagnose),
        }),
        onConfirm: (proceed) => proceed && onChange(diagnose.id),
      })
    }
  }

  return (
    <ItemWithBadgesActions
      hasNotes={Boolean(diagnose.notes)}
      onDelete={handleDelete}
      onUpdateNotes={handleUpdateNotes}
    />
  )
}

export default DifferentialDiagnoseActions
