import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, NamedEntity } from '@pbt/pbt-ui-components'

import ColorPinIcon from '~/components/common/icons/ColorPinIcon'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getAppointmentTypeColor } from '~/utils/time'

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  { name: 'TimetableFilterAppointmentTypeItem' },
)

export interface TimetableFilterAppointmentTypeItemProps {
  disableColor?: boolean
  item: NamedEntity
}

const TimetableFilterAppointmentTypeItem = ({
  item,
  disableColor,
}: TimetableFilterAppointmentTypeItemProps) => {
  const classes = useStyles()

  const currentBusiness = useSelector(getCurrentBusiness)

  const pinColor = getAppointmentTypeColor(currentBusiness, item.id)

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.root}
      wrap="nowrap"
    >
      {!disableColor && (
        <ColorPinIcon color={pinColor || 'transparent'} mr={0.5} />
      )}
      {LanguageUtils.getTranslatedFieldName(item)}
    </Grid>
  )
}

export default TimetableFilterAppointmentTypeItem
