import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import WellnessPlan from '~/components/common/images/WellnessPlan.svg'
import WellnessPlanBackground from '~/components/common/images/WellnessPlanBackground.svg'
import Link from '~/components/common/link/Link'

import HelpButton from '../../../../../HelpButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2, 2, 6),
      backgroundImage: `url(${WellnessPlanBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%, auto',
    },
    helpButton: {
      marginTop: theme.spacing(1),
    },
    iconGroup: {
      marginLeft: theme.spacing(1),
      top: -30,
      width: 280,
      position: 'relative',
      overflow: 'hidden',
    },
    planIcon: {
      position: 'absolute',
    },
    planIconSmall: {
      left: 0,
      top: 22,
      width: 95,
      height: 110,
    },
    planIconMedium: {
      left: 35,
      top: 12,
      width: 115,
      height: 130,
    },
    planIconLarge: {
      left: 80,
      top: 0,
      width: 130,
      height: 150,
    },
  }),
  { name: 'WellnessPlanPromo' },
)

const WellnessPlanPromo = () => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid container item direction="column">
        <Text>
          <Trans
            components={{ strong: <strong /> }}
            i18nKey="Businesses:WELLNESS_PLANS.WITH_RHAPSODY_WELLNESS_PLANS_YOU_CAN_INCREASE"
          />
        </Text>
        <HelpButton
          Component={Link}
          ComponentProps={{
            to: '/',
          }}
          className={classes.helpButton}
        >
          {t('Businesses:WELLNESS_PLANS.CONTACT_US_TO_GET_STARTED')}
        </HelpButton>
      </Grid>
      <Grid item className={classes.iconGroup}>
        <img
          alt="wellness-plan-1"
          className={classNames(classes.planIcon, classes.planIconSmall)}
          src={WellnessPlan}
        />
        <img
          alt="wellness-plan-2"
          className={classNames(classes.planIcon, classes.planIconMedium)}
          src={WellnessPlan}
        />
        <img
          alt="wellness-plan-3"
          className={classNames(classes.planIcon, classes.planIconLarge)}
          src={WellnessPlan}
        />
      </Grid>
    </Grid>
  )
}

export default WellnessPlanPromo
