import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Defaults, moment, useInterval } from '@pbt/pbt-ui-components'

import { DEFAULT_CONVERSATION } from '~/constants/communications'
import { clearFilesToSend } from '~/store/actions/conversationMessages'
import {
  fetchConversation,
  fetchConversationMessagesList,
  fetchMoreItemsForConversationMessagesList,
} from '~/store/actions/conversations'
import { getConstantsIsLoading } from '~/store/reducers/constants'
import { getChatMessages } from '~/store/reducers/conversationMessages'
import { getConversationById } from '~/store/reducers/conversations'

export function useConversationChat({
  conversationId,
}: {
  conversationId: string
}) {
  const dispatch = useDispatch()
  const conversation =
    useSelector(getConversationById(conversationId)) || DEFAULT_CONVERSATION
  const isConstantsLoading = useSelector(getConstantsIsLoading)

  const isConversationDeleted = !conversation
  const { totalCount } = conversation
  const messages = useSelector(
    getChatMessages(conversation.messages || []),
  ).sort((a, b) => moment(a.timestamp).diff(b.timestamp))

  const loadItemsRange = (startIndex: number, endIndex: number) => {
    dispatch(
      fetchMoreItemsForConversationMessagesList(
        startIndex,
        endIndex,
        conversationId,
      ),
    )
  }

  const loadMoreItems = () => {
    const from = messages.length
    const to = messages.length + Defaults.CONVERSATION_MESSAGES_BATCH_LOAD_COUNT
    loadItemsRange(from, to)
  }

  useEffect(() => {
    if (isConstantsLoading || isConversationDeleted) {
      return
    }

    dispatch(fetchConversation(conversationId))
    dispatch(
      fetchConversationMessagesList(
        0,
        Defaults.CONVERSATION_MESSAGES_BATCH_LOAD_COUNT,
        conversationId,
      ),
    )
    dispatch(clearFilesToSend())
  }, [conversationId, isConstantsLoading])

  useInterval(() => {
    if (isConstantsLoading) {
      return
    }
    dispatch(fetchConversation(conversationId))
  }, Defaults.CONVERSATION_MESSAGES_UPDATE_INTERVAL)

  return { loadMoreItems, messages, totalCount }
}
