import { FileTemplate } from '@pbt/pbt-ui-components'

import { MutationSendGeneratedMessageArgs } from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  CREATE_CONVERSATION_ATTACHMENT,
  DELETE_CONVERSATION_ATTACHMENT,
  DELETE_CONVERSATION_MESSAGE,
  SEND_CONVERSATION_DRAFT_MESSAGE,
  SEND_CONVERSATION_MESSAGE,
  SEND_GENERATED_MESSAGE,
  UPDATE_MESSAGE,
} from './graphql/mutations'
import { FETCH_CONVERSATION_ATTACHMENTS } from './graphql/queries'
import * as Schema from './schemas'

export const sendConversationMessage = requestMutation({
  mutation: SEND_CONVERSATION_MESSAGE,
  variablesHandler: (businessId, conversationId, message) => ({
    conversationId,
    input: message,
  }),
  schema: Schema.message,
})

export const sendConversationDraftMessage = requestMutation({
  mutation: SEND_CONVERSATION_DRAFT_MESSAGE,
  variablesHandler: (businessId, conversationId, message) => ({
    conversationId,
    input: message,
  }),
  schema: Schema.message,
})

export const updateMessage = requestMutation({
  mutation: UPDATE_MESSAGE,
  variablesHandler: (businessId, messageId, message) => ({
    messageId,
    input: message,
  }),
  schema: Schema.message,
})

export const deleteMessage = requestMutation({
  mutation: DELETE_CONVERSATION_MESSAGE,
  variablesHandler: (businessId, messageId) => ({
    messageId,
  }),
})

export const uploadConversationFile = requestMutation({
  mutation: CREATE_CONVERSATION_ATTACHMENT,
  variablesHandler: (businessId, clientId, patientId, file: FileTemplate) => ({
    file: file.raw,
    clientId,
    patientId,
    name: file.name,
    description: file?.description,
    extension: file.extension,
  }),
})

export const deleteConversationFile = requestMutation({
  mutation: DELETE_CONVERSATION_ATTACHMENT,
  variablesHandler: (businessId, fileId) => ({
    fileId,
  }),
})

export const fetchAllConversationFiles = requestQuery({
  query: FETCH_CONVERSATION_ATTACHMENTS,
  variablesHandler: (businessId, conversationId) => ({
    conversationId,
  }),
  schema: Schema.conversationFileArray,
})

export const sendUpdatePaymentLink = requestMutation({
  mutation: SEND_GENERATED_MESSAGE,
  variablesHandler: (_, args: MutationSendGeneratedMessageArgs) => args,
  schema: Schema.conversationsArray,
})
