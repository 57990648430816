import React from 'react'
import { Field } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'

import ChargeDetailsCurrencyField from './ChargeDetailsCurrencyField'

export interface ChargeFieldShippingAmountProps {
  className?: string
  inputClassName?: string
  item: RetailOrderLineItem
  shippingAmountField: Field
}

const ChargeFieldShippingAmount = ({
  className: classNameProp,
  inputClassName,
  item,
  shippingAmountField,
}: ChargeFieldShippingAmountProps) => (
  <ChargeDetailsCurrencyField
    disabled
    className={classNameProp}
    field={shippingAmountField}
    inputClassName={inputClassName}
    item={item}
  />
)

export default ChargeFieldShippingAmount
