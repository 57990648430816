import * as R from 'ramda'
import { Business, User } from '@pbt/pbt-ui-components'

import { arrayToMap } from '~/utils'

const EmptyPatient = {
  species: undefined,
}
const EmptyBusiness = {
  children: undefined,
}

const getProcessEmptyStrategy = (emptyObject: any) => (value: any) => ({
  ...R.mergeDeepLeft(value, emptyObject),
})

export const getBusinessProcessStrategy = (value: Business) => {
  const processedEmpty = getProcessEmptyStrategy(EmptyBusiness)(value)

  if (processedEmpty?.appointmentConfiguration) {
    return {
      ...processedEmpty,
      appointmentConfigurationByTypeId: arrayToMap(
        processedEmpty.appointmentConfiguration,
        R.prop<string>('appointmentTypeId'),
        R.identity,
      ),
    }
  }
  return processedEmpty
}

// Handling cases with incorrect public-api DTOs
export const getPatientProcessStrategy = (value: any) =>
  getProcessEmptyStrategy(EmptyPatient)({
    ...value,
    breeds: value.breeds?.map((breed: { id: string } | string) =>
      R.has('id', breed) ? breed.id : breed,
    ),
    species: R.has('id', value.species) ? value.species.id : value.species,
    gender: R.has('id', value.gender) ? value.gender.id : value.gender,
  })

// Direct fields alertText and alertColorId are deprecated and should be considered to be deleted after PR-319
export const getUserProcessStrategy = (value: User) => ({
  ...value,
  alertText: value.alertText || value.personBusinessAlert?.alertText,
  alertColorId: value.alertColorId || value.personBusinessAlert?.alertColorId,
})
