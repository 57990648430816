import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  CircularProgressOverlay,
  ClassesType,
  Text,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import {
  getCurrentlyMatchingLineId,
  matchShipmentImportItem,
} from '~/store/duck/shipmentImport'
import { getConstantTranslatedName } from '~/store/reducers/constants'
import { ShipmentImportItem } from '~/types'

import type { OpenMatchDialogOptions } from '../ShipmentDialog'
import UnmatchedLine from './UnmatchedLine'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      borderBottom: theme.constants.tableBorder,
      borderRight: theme.constants.tableBorder,
      borderLeft: theme.constants.tableBorder,
    },
    editIcon: {
      fontSize: '2.2rem',
      color: theme.colors.editIconColor,
      cursor: 'pointer',
    },
    highlightCell: {
      borderRight: theme.constants.tableBorder,
    },
    controls: {
      width: 40,
    },
    cell: {
      padding: theme.spacing(1, 2),
    },
  }),
  { name: 'ShipmentImportTableRow' },
)

interface ShipmentImportTableRowProps {
  classes?: ClassesType<typeof useStyles>
  item: ShipmentImportItem
  openMatchDialog: (options: OpenMatchDialogOptions) => void
  sessionId: string | null
}

const ShipmentImportTableRow = ({
  classes: classesProp,
  sessionId,
  openMatchDialog,
  item,
}: ShipmentImportTableRowProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const currentlyLoading = useSelector(getCurrentlyMatchingLineId)
  const { t } = useTranslation('Admin')

  const {
    id: lineId,
    name: lineName,
    matchedVariations = [],
    resolvedVariation,
  } = item || {}

  const {
    id: matchedInventoryItemId,
    name: businessVariationName,
    matchReasonId,
  } = resolvedVariation || {}

  const isLoading = currentlyLoading === lineId
  const hasMultipleChoices = matchedVariations.length > 1
  const matchReasonText = useSelector(
    getConstantTranslatedName('InventoryUploadItemMatchReasons', matchReasonId),
  )
  const editMatch = () =>
    openMatchDialog({
      title: t('Admin:CATALOG.SHIPMENT_IMPORT_TABLE_ROW.MAP_LINE_NAME', {
        lineName,
      }),
      onProceed: ({ variation }: ShipmentImportItem) => {
        const variationId = variation?.id
        dispatch(
          matchShipmentImportItem(sessionId as string, lineId, variationId),
        )
      },
    })

  return (
    <Grid container alignItems="center" className={classes.root}>
      {isLoading && <CircularProgressOverlay open />}
      <Grid
        item
        className={classNames(classes.cell, classes.highlightCell)}
        xs={5}
      >
        <Text>{lineName}</Text>
      </Grid>
      <Grid item className={classes.cell} xs={4}>
        {matchedInventoryItemId ? (
          <Text>{businessVariationName}</Text>
        ) : (
          <UnmatchedLine
            hasMultipleChoices={hasMultipleChoices}
            onMatchRequested={editMatch}
          />
        )}
      </Grid>
      <Grid item xs className={classes.cell}>
        <Text>{matchReasonText}</Text>
      </Grid>
      <Grid container item className={classes.controls} justifyContent="center">
        <EditIcon className={classes.editIcon} onClick={editMatch} />
      </Grid>
    </Grid>
  )
}

export default ShipmentImportTableRow
