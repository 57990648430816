import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import { getBusinessScopeModalityList } from '../duck/imagingOrders'
import { useGetIdexxImagingId } from './constants'

export const useIsIntegratedModality = (modalityId: string | Nil) => {
  const vendorId = useGetIdexxImagingId()
  const businessScopeModalities = useSelector(
    getBusinessScopeModalityList(vendorId),
  )

  return R.includes(modalityId, businessScopeModalities)
}
