import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Skeleton, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  moment,
  PermissionArea,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import ReminderStateLabel from '~/components/common/labels/ReminderStateLabel'
import DialogNames from '~/constants/DialogNames'
import {
  deleteReminder,
  getIsDeletingReminder,
  getIsUpdatingReminder,
  getReminder,
  toggleReminderState,
} from '~/store/duck/reminders'
import { getCRUDByArea } from '~/store/reducers/auth'
import { ReminderStateName } from '~/types'
import useDialog from '~/utils/useDialog'

import { useIsReminderStateName } from './reminderUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    name: {
      fontSize: '1.6rem',
    },
    nameResolved: {
      color: theme.colors.tabLabel,
      textDecoration: 'line-through',
    },
    stateLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(4),
    },
    stateLabel: {
      fontSize: '1rem',
    },
    checkIcon: {
      color: theme.colors.tabLabel,
    },
    buttonsContainer: {
      width: 122,
    },
  }),
  { name: 'RemindersListItem' },
)

export interface RemindersListItemProps {
  className?: string
  reminderId?: string
}

const RemindersListItem = ({
  className,
  reminderId,
}: RemindersListItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const reminder = useSelector(getReminder(reminderId))
  const isDeleting = useSelector(getIsDeletingReminder(reminderId))
  const isUpdating = useSelector(getIsUpdatingReminder(reminderId))
  const permissions = useSelector(getCRUDByArea(PermissionArea.REMINDERS))

  const isLoading = !reminder

  const [openReminderDetailsDialog] = useDialog(DialogNames.REMINDER_DETAILS)
  const isResolved = useIsReminderStateName(
    reminder,
    ReminderStateName.RESOLVED,
  )

  const handleDelete = () => {
    if (reminderId) {
      dispatch(deleteReminder(reminderId))
    }
  }

  const handleCheck = () => {
    if (reminderId) {
      dispatch(toggleReminderState(reminderId))
    }
  }

  const handlePreview = () => {
    if (reminderId) {
      openReminderDetailsDialog({ reminderId })
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      className={classNames(className, classes.root)}
      id={reminderId}
      px={2}
    >
      <Grid item xs={3}>
        <TextInteractive isLoading={isLoading} variant="lowAccent">
          {moment(reminder?.dueDatetime).format('L')}
        </TextInteractive>
      </Grid>
      <Grid container item xs alignItems="center" wrap="nowrap">
        <TextInteractive
          className={classNames({
            [classes.nameResolved]: isResolved,
          })}
          isLoading={isLoading}
          loaderWidth={150}
          variant="body"
        >
          {reminder?.name}
        </TextInteractive>
        <BusinessShareIcon
          businessIds={reminder?.businessId ? [reminder.businessId] : []}
        />
      </Grid>
      <Grid container item justifyContent="flex-end" xs={2}>
        <Grid className={classes.stateLabelContainer}>
          {isLoading ? (
            <Skeleton width={50} />
          ) : (
            <ReminderStateLabel
              className={classes.stateLabel}
              reminder={reminder}
            />
          )}
        </Grid>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Grid container alignItems="center" justifyContent="flex-end">
          <ControlButtonGroup
            buttons={[
              permissions.read && {
                disabled: isDeleting || isUpdating,
                name: ControlButtonGroupName.PREVIEW,
                onClick: () => handlePreview(),
              },
              permissions.update && {
                disabled: isDeleting || isUpdating,
                isLoading: isUpdating,
                onClick: () => handleCheck(),
                ...(isResolved
                  ? {
                      name: ControlButtonGroupName.ROUNDED_CHECK,
                      IconProps: {
                        fillColor: theme.colors.tabLabel,
                      },
                    }
                  : {
                      name: ControlButtonGroupName.CHECK,
                      onClick: () => handleCheck(),
                    }),
              },
              permissions.delete && {
                disabled: isDeleting || isUpdating,
                isLoading: isDeleting,
                name: ControlButtonGroupName.DELETE,
                onClick: () => handleDelete(),
              },
            ]}
            classes={{
              checkIcon: classes.checkIcon,
            }}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default RemindersListItem
