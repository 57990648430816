import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
  PuiTextField,
  Text,
  UnsavedWellnessPlan,
  useFields,
  Utils,
  WellnessPlan,
  WellnessPlanPrice,
} from '@pbt/pbt-ui-components'

import {
  getWellnessPlanPriceType,
  getWellnessPlanType,
} from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 450,
      maxWidth: 450,
    },
    dialogContentRoot: {
      padding: theme.spacing(2, 2, 3),
    },
    input: {
      maxWidth: 240,
    },
    button: {
      width: 150,
    },
  }),
  { name: 'WellnessPlanTierDialog' },
)

export interface WellnessPlanTierDialogProps extends BasePuiDialogProps {
  onProceed: (tier: UnsavedWellnessPlan) => void
  tier?: WellnessPlan
}

const WellnessPlanTierDialog = ({
  open,
  tier,
  onClose,
  onProceed,
}: WellnessPlanTierDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const WellnessPlanType = useSelector(getWellnessPlanType)
  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)

  const AddOnTypeId = Utils.findConstantIdByName('AddOn', WellnessPlanType)
  const MonthlyPriceTypeId = Utils.findConstantIdByName(
    'Monthly',
    WellnessPlanPriceType,
  )

  const isEdit = Boolean(tier)

  const {
    fields: { name },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'name',
        label: t('Common:PLAN_NAME'),
        validators: ['required'],
        initialValue: tier?.name || '',
      },
    ],
    false,
  )

  useEffect(() => {
    reset()
  }, [tier])

  const handleSave = () => {
    if (validate()) {
      const newTier = {
        ...tier,
        name: name.value,
      }

      if (!isEdit) {
        newTier.prices = [
          {
            priceTypeId: MonthlyPriceTypeId,
            price: 0,
          } as WellnessPlanPrice,
        ]
        newTier.priceTypeId = MonthlyPriceTypeId
        newTier.planTypeId = AddOnTypeId
      }

      onProceed(newTier as UnsavedWellnessPlan)

      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          type="submit"
          onClick={handleSave}
        >
          {tier ? t('Common:SAVE_ACTION') : t('Common:ADD_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="wellness-plan-tier-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      title={
        tier ? t('Common:EDIT_WELLNESS_PLAN') : t('Common:ADD_WELLNESS_PLAN')
      }
      onClose={onClose}
    >
      <Text variant="body2">
        {t('WellnessPlans:PATIENTS_CAN_ONLY_ENROLL_IN_ONE_PLAN')}
      </Text>
      <PuiTextField
        className={classes.input}
        field={name}
        inputProps={{ maxLength: 100 }}
        label={`${name.label}*`}
      />
    </PuiDialog>
  )
}

export default WellnessPlanTierDialog
