export enum ReconcilationStatus {
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  WAITING = 'WAITING',
  ERROR = 'ERROR',
}

export const ReconcilationStatusColorMap = {
  [ReconcilationStatus.RUNNING]: 'normal',
  [ReconcilationStatus.DONE]: 'completed',
  [ReconcilationStatus.WAITING]: 'important',
  [ReconcilationStatus.ERROR]: 'error',
}
