/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant,
  Defaults,
  IdObject,
  LanguageUtils,
  LinkButton,
  PuiCloseButton,
  Utils,
} from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import PuiTabs, { PuiTab } from '~/components/common/PuiTabs'
import {
  NotificationAreaNames,
  NotificationStyleNames,
  NotificationSyntheticAreas,
} from '~/constants/notifications'
import { BaseRoute } from '~/constants/routes'
import i18n from '~/locales/i18n'
import {
  deleteAllNotifications,
  fetchMoreItemsForNotifications,
  fetchNotificationAreasTotalCounts,
  fetchNotifications,
  fetchNotificationSettings,
  markAllNotificationsAsRead,
  updateNotificationFilters,
} from '~/store/actions/notifications'
import { getCurrentUserId } from '~/store/reducers/auth'
import {
  getNotificationArea,
  getNotificationStyle,
  getNotificationType,
} from '~/store/reducers/constants'
import {
  getMultipleNotifications,
  getNotificationsAreFetching,
  getNotificationsList,
  getNotificationsListFilters,
  getNotificationsListTotalCounts,
  getNotificationsTotalCount,
  getNotificationsTotalCountsFetching,
  getNotificationStyleSettings,
} from '~/store/reducers/notifications'
import { Notification, TableFilter } from '~/types'
import { getNotificationsTotalCountForArea } from '~/utils/notifications'

import ActionsWithTimestampColumn from './ActionsWithTimestampColumn'
import NotificationHistoryTableMessageColumn from './NotificationHistoryTableMessageColumn'

const useStyles = makeStyles(
  (theme) => ({
    rootHeader: {
      position: 'relative',
      borderBottom: theme.constants.tableBorder,
    },
    closeButton: {
      position: 'absolute',
      top: 2,
      right: 18,
    },
    tableContainer: {
      padding: 0,
    },
    tableHeader: {
      padding: 0,
    },
    tabs: {
      border: 'none',
    },
    rowContainer: {
      border: 'none',
      '&:hover': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
  }),
  { name: 'NotificationHistoryTable' },
)

const columns = [
  {
    label: i18n.t('Common:MESSAGE'),
    component: (item: Notification) => (
      <NotificationHistoryTableMessageColumn
        showTypeIcon
        notification={item}
        prop="message"
      />
    ),
    width: 5.5,
  },
  {
    label: i18n.t('Common:TITLE'),
    component: (item: Notification) => (
      <NotificationHistoryTableMessageColumn notification={item} prop="title" />
    ),
    width: 8,
  },
  {
    label: i18n.t('Common:ACTION_OTHER'),
    component: (item: Notification) => (
      <ActionsWithTimestampColumn notification={item} />
    ),
    width: 5,
  },
]

const ITEM_SIZE = 40

const getNotificationsTotalCountTextForArea = (
  area: IdObject,
  totalCountsFetching: boolean,
  totalCountEntryByAreaIdMap = {},
) => {
  if (totalCountsFetching || R.isEmpty(totalCountEntryByAreaIdMap)) {
    return ''
  }

  const totalCount = getNotificationsTotalCountForArea(
    area?.id,
    totalCountEntryByAreaIdMap,
  )

  return `(${totalCount})`
}

interface HeaderProps {
  handleClearAll: () => void
  handleMarkAllAsRead: () => void
  handleNotificationAreaTabChanged: (tab: number) => void
  notificationAreaTabs: PuiTab[]
  onClose: () => void
  selectedTab: number
}

const Header = ({
  onClose,
  selectedTab,
  notificationAreaTabs,
  handleNotificationAreaTabChanged,
  handleMarkAllAsRead,
  handleClearAll,
}: HeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <Grid
      container
      item
      alignItems="flex-end"
      className={classes.rootHeader}
      pr={7}
      wrap="nowrap"
    >
      <PuiCloseButton
        classes={{ iconButton: classes.closeButton }}
        onClick={onClose}
      />
      <Grid item xs mr={2}>
        <PuiTabs
          classes={{ tabs: classes.tabs }}
          selectedTab={selectedTab}
          tabs={notificationAreaTabs}
          variant="standard"
          onSelectedTabChange={handleNotificationAreaTabChanged}
        />
      </Grid>
      <Grid item>
        <LinkButton onClick={handleMarkAllAsRead}>
          {t('Common:MARK_ALL_AS_READ')}
        </LinkButton>
        <LinkButton onClick={handleClearAll}>
          {t('Common:CLEAR_ALL')}
        </LinkButton>
      </Grid>
    </Grid>
  )
}

export interface NotificationHistoryTableProps {
  onClose: () => void
}

const NotificationHistoryTable = ({
  onClose,
}: NotificationHistoryTableProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()

  const NotificationAreas: Constant[] = useSelector(getNotificationArea)
  const NotificationStyles = useSelector(getNotificationStyle)
  const NotificationTypes = useSelector(getNotificationType)
  const listStyleId = Utils.findConstantIdByName(
    NotificationStyleNames.LIST,
    NotificationStyles,
  )

  const filters = useSelector(getNotificationsListFilters)
  const totalCountEntryByAreaIdMap = useSelector(
    getNotificationsListTotalCounts,
  )
  const totalCountsFetching = useSelector(getNotificationsTotalCountsFetching)
  const isNotificationsListFetching = useSelector(getNotificationsAreFetching)
  const notificationsList = useSelector(getNotificationsList)
  const notifications = useSelector(getMultipleNotifications(notificationsList))
  const totalCount = useSelector(getNotificationsTotalCount)
  const currentUserId = useSelector(getCurrentUserId)
  const styleSettings = useSelector(getNotificationStyleSettings(currentUserId))
  const areSettingsEmpty = !styleSettings

  const isLoading = isNotificationsListFetching || totalCountsFetching

  const notificationAreaTabs = R.prepend(
    NotificationSyntheticAreas.ALL,
    NotificationAreas,
  ).map((it) => {
    const labelPrefixName = LanguageUtils.getTranslatedFieldName(it)
    const labelPostfix = getNotificationsTotalCountTextForArea(
      it,
      totalCountsFetching,
      totalCountEntryByAreaIdMap,
    )
    return {
      ...it,
      label: `${labelPrefixName} ${labelPostfix}`,
    }
  })
  const selectedTab = Math.max(
    R.findIndex(R.propEq('id', filters?.area?.value), notificationAreaTabs),
    0,
  )

  const applyFilter = (filter: string, value: TableFilter) => {
    dispatch(updateNotificationFilters({ ...filters, [filter]: value }))
  }

  const handleMarkAllAsRead = () => {
    dispatch(markAllNotificationsAsRead())
  }

  const handleClearAll = () => {
    dispatch(deleteAllNotifications())
  }

  const handleNotificationAreaTabChanged = (tab: number) => {
    applyFilter('area', { value: notificationAreaTabs[tab].id })
  }

  const handleCommunicationsNotificationClick = (
    notification: Notification,
  ) => {
    navigate(`${BaseRoute.COMMUNICATIONS}/${notification.conversationId}`)
  }

  const handleTasksNotificationClick = (notification: Notification) => {
    navigate({ pathname: `${BaseRoute.TASKS}/${notification.taskId}` })
  }

  const notificationClickHandlerByNotificationAreaName = {
    [NotificationAreaNames.COMMUNICATIONS]:
      handleCommunicationsNotificationClick,
    [NotificationAreaNames.TASKS]: handleTasksNotificationClick,
  }

  const onNotificationSelected = (notificationId: string) => {
    const notification = R.find(
      R.propEq('id', notificationId),
      notifications,
    ) as Notification
    const notificationsType = Utils.findById(
      notification.typeId,
      NotificationTypes,
    )
    const notificationsArea = Utils.findById(
      notificationsType.notificationAreaId,
      NotificationAreas,
    )

    const handler =
      notificationClickHandlerByNotificationAreaName[notificationsArea.name]
    if (handler) {
      handler(notification)
    }

    onClose()
  }

  useEffect(() => {
    dispatch(
      fetchNotifications(
        0,
        Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        listStyleId,
      ),
    )
  }, [selectedTab])

  useEffect(() => {
    dispatch(fetchNotificationAreasTotalCounts())
  }, [])

  useEffect(() => {
    if (areSettingsEmpty && currentUserId) {
      dispatch(fetchNotificationSettings(currentUserId))
    }
  }, [areSettingsEmpty, currentUserId])

  const HeaderComponent = (
    <Header
      handleClearAll={handleClearAll}
      handleMarkAllAsRead={handleMarkAllAsRead}
      handleNotificationAreaTabChanged={handleNotificationAreaTabChanged}
      notificationAreaTabs={notificationAreaTabs}
      selectedTab={selectedTab}
      onClose={onClose}
    />
  )

  return (
    <ExpandableTable
      NoItemsScreenProps={{ Header: HeaderComponent }}
      classes={{
        tableContainer: classes.tableContainer,
        header: classes.tableHeader,
      }}
      headerButtons={HeaderComponent}
      isLoading={isLoading}
      list={notifications}
      onSelected={onNotificationSelected}
    >
      <PrimitiveTableWithSearchHighlights
        hideHeader
        RowComponentProps={{ clamp: 1 }}
        columns={columns}
        getItemSize={ITEM_SIZE}
        isItemLoaded={(index) => Boolean(notifications[index])}
        loadMoreItems={(startIndex, endIndex) => {
          dispatch(
            fetchMoreItemsForNotifications(startIndex, endIndex, listStyleId),
          )
        }}
        rowClasses={{ container: classes.rowContainer }}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default NotificationHistoryTable
