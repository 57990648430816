import React, { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab } from '@mui/material'

import {
  closeIntercom,
  getIsIntercomOpen,
  openIntercom,
} from '~/store/duck/intercom'

export interface HelpButtonProps<T = any> {
  Component?: string | React.JSXElementConstructor<T>
  ComponentProps?: T
  children?: React.ReactNode
  className?: string
}

const HelpButton = forwardRef(function HelpButton(
  { Component = Fab, ComponentProps, className, children }: HelpButtonProps,
  ref,
) {
  const dispatch = useDispatch()
  const isIntercomOpen = useSelector(getIsIntercomOpen)
  const { t } = useTranslation('Common')

  const onClick = useCallback(() => {
    dispatch(isIntercomOpen ? closeIntercom() : openIntercom())
  }, [isIntercomOpen])

  return (
    <Component
      ref={ref}
      {...ComponentProps}
      // following props must not be overwritten by ComponentProps
      aria-label={t('Common:GET_HELP')}
      className={className}
      onClick={onClick}
    >
      {children}
    </Component>
  )
})

export default HelpButton
