import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { Nil, Text } from '@pbt/pbt-ui-components'

import { editDifferentialDiagnosesState } from '~/store/actions/soap'
import { getDiagnosesState } from '~/store/reducers/soap'
import { DiagnosesState } from '~/types'

import DifferentialDiagnose from './DifferentialDiagnose'

export interface DifferentialDiagnosesProps {
  className?: string
  editDisabled?: boolean
}

const DifferentialDiagnoses = ({
  className,
  editDisabled,
}: DifferentialDiagnosesProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const diagnosesState = Object.values(useSelector(getDiagnosesState))

  const replaceNullOrUndefined = (name: string | Nil) =>
    R.isNil(name) ? '' : name
  const sortByNameCaseInsensitive = R.sortBy<DiagnosesState>(
    R.pipe(R.prop('name'), replaceNullOrUndefined, R.toLower),
  )
  const diagnosesSortedByName = sortByNameCaseInsensitive(diagnosesState)

  return (
    <Grid item className={className}>
      {diagnosesState.length > 0 && (
        <>
          <Text strong mt={0.75} variant="body2">
            {t('Common:DIFFERENTIAL_DIAGNOSES')}
          </Text>
          {diagnosesSortedByName.map((diagnose) => (
            <DifferentialDiagnose
              data={diagnose}
              editDisabled={editDisabled}
              key={diagnose.id}
              onChange={(id: string, data?: DiagnosesState) => {
                dispatch(editDifferentialDiagnosesState(id, data))
              }}
              onNotesChange={(notes: string, locationId?: string) => {
                if (locationId) {
                  const locations = diagnose.locations.map((location) =>
                    location.id === locationId
                      ? { ...location, notes }
                      : location,
                  )
                  dispatch(
                    editDifferentialDiagnosesState(diagnose.id, {
                      ...diagnose,
                      locations,
                    }),
                  )
                } else {
                  dispatch(
                    editDifferentialDiagnosesState(diagnose.id, {
                      ...diagnose,
                      notes,
                    }),
                  )
                }
              }}
            />
          ))}
        </>
      )}
    </Grid>
  )
}

export default DifferentialDiagnoses
