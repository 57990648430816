import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Nil } from '@pbt/pbt-ui-components'

import {
  SubmitChewyOrderMutationVariables,
  useSubmitChewyOrderMutation,
} from '~/api/graphql/generated/types'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

export const useSubmitChewyOrder = (
  invoice: InvoiceV3 | Nil,
  refetchClientChewyCheckoutInfo: () => void,
) => {
  const dispatch = useDispatch()

  const { t } = useTranslation(['Payments'])

  const [submitChewyOrder, { loading, error, reset }] =
    useSubmitChewyOrderMutation()

  return {
    placeOrderLoading: loading,
    placeOrderError: error,
    resetPlaceOrder: reset,
    submitChewyOrder: ({
      variables,
      onCompleted,
    }: {
      onCompleted?: () => void
      variables: Omit<SubmitChewyOrderMutationVariables, 'retailOrderId'>
    }) => {
      const { retailOrder, id: invoiceId } = invoice || {}
      if (retailOrder) {
        // TODO: M1 add shipping address
        submitChewyOrder({
          variables: {
            ...variables,
            retailOrderId: retailOrder.id,
          },
          onCompleted: () => {
            dispatch(
              addUiNotification({
                id: uuid(),
                message: t('Payments:PAYMENT_SUCCESSFUL_CHEWY_ORDER_PLACED'),
                type: SnackNotificationType.INFO,
              }),
            )
            if (variables.accountBalanceId) {
              refetchClientChewyCheckoutInfo()
              onCompleted?.()
            }
            if (invoiceId) {
              dispatch(fetchInvoiceV3({ id: invoiceId }))
            }
            onCompleted?.()
          },
        })
      }
    },
  }
}
