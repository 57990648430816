import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  LanguageUtils,
  PuiSelect,
  Text,
  Utils,
  WellnessPlanBenefit,
} from '@pbt/pbt-ui-components'

import NumericInput from '~/components/common/inputs/NumericInput'
import { getWellnessPlanLimitType } from '~/store/reducers/constants'
import { getWellnessPlanGlobalBenefitGroups } from '~/store/reducers/wellnessPlans'

import { getGlobalBenefit } from './wellnessPlanUtils'

const useStyles = makeStyles(
  () => ({
    root: {},
    limitInput: {
      width: 60,
    },
    limitTypeSelect: {
      width: 'fit-content',
    },
  }),
  { name: 'WellnessPlanBenefitLimitConfiguration' },
)

export interface WellnessPlanBenefitLimitConfigurationProps {
  benefit: WellnessPlanBenefit
  className?: string
  disableEdit?: boolean
  onUpdateBenefit: (benefit: WellnessPlanBenefit) => void
}

const WellnessPlanBenefitLimitConfiguration = ({
  disableEdit,
  className,
  benefit,
  onUpdateBenefit,
}: WellnessPlanBenefitLimitConfigurationProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Constants'])

  const WellnessPlanLimitType = useSelector(getWellnessPlanLimitType)
  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)

  if (!benefit.limitCustomizable && !benefit.limitTypeId) {
    return null
  }

  const LimitedTypeId = Utils.findConstantIdByName(
    'Limited',
    WellnessPlanLimitType,
  )
  const isLimited = benefit.limitTypeId === LimitedTypeId
  const group = Utils.findById(benefit.groupId, globalBenefitGroups)
  const globalBenefit = getGlobalBenefit(benefit, group?.benefits || [])

  return (
    <Grid container item className={classNames(className, classes.root)}>
      {benefit.limitCustomizable && !disableEdit ? (
        <PuiSelect
          className={classes.limitTypeSelect}
          items={WellnessPlanLimitType}
          renderEmpty={false}
          value={benefit.limitTypeId}
          onChange={Utils.handleFormSelectInput((limitTypeId) => {
            onUpdateBenefit({
              ...benefit,
              limitTypeId,
              limit:
                limitTypeId === LimitedTypeId
                  ? globalBenefit?.limit || 1
                  : null,
            })
          })}
        />
      ) : (
        <Text variant="body">
          {LanguageUtils.getConstantTranslatedName(
            benefit.limitTypeId,
            WellnessPlanLimitType,
          )}
        </Text>
      )}
      {isLimited && (
        <Grid container item alignItems="center" mt={0.75} wrap="nowrap">
          <Text mr={0.5} variant="body">{`${t(
            'Common:UP_TO_NUMBER_COUNT',
          )} `}</Text>
          {benefit.limitCustomizable && !disableEdit ? (
            <NumericInput
              className={classes.limitInput}
              value={benefit.limit}
              onChange={(limit) =>
                onUpdateBenefit({ ...benefit, limit: limit as number })
              }
            />
          ) : (
            <Text variant="body">{benefit.limit}</Text>
          )}
          <Text variant="body">
            /{t('Constants:TIME_UNITS.YEAR_ONE').toLowerCase()}
          </Text>
        </Grid>
      )}
    </Grid>
  )
}

export default WellnessPlanBenefitLimitConfiguration
