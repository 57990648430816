import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DragIndicator } from '@mui/icons-material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  CircularProgressOverlay,
  Constant,
  PuiDialog,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import FeatureToggle from '~/constants/featureToggle'
import {
  SpecialTimetableColumn,
  SpecialTimetableColumnNames,
  TimetableArea,
} from '~/constants/schedulerConstants'
import {
  fetchMembersForSelectList,
  fetchTeamsList,
} from '~/store/actions/members'
import {
  getShowOtcSaleEvents,
  getTimetableConfiguredColumnsForArea,
  updateShowOtcSaleEvents,
  updateTimetableConfiguredColumns,
} from '~/store/duck/userSettings'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import {
  getCurrentBusinessId,
  getCurrentBusinessIsAutoAssignEventEnabled,
  getCurrentBusinessIsOmniChannel,
  getCurrentUserId,
} from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getScheduleColumnNames,
} from '~/store/reducers/constants'
import {
  getMembersIsFetchingSelectList,
  getMembersSelectList,
  getTeamsList,
} from '~/store/reducers/members'
import { getMultipleUsers } from '~/store/reducers/users'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 420,
      maxWidth: 420,
    },
    row: {
      position: 'relative',
      backgroundColor: theme.colors.tableBackground,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      '&:hover': {
        zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
        boxShadow: '0 2px 10px 0 rgba(0,0,0,0.3)',
      },
    },
    rowDragged: {
      userSelect: 'none',
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorderSelected,
    },
    indicator: {
      color: theme.colors.selectedOption,
    },
    button: {
      width: 120,
    },
    switch: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'AdjustTimetableColumnsDialog' },
)

export interface AdjustTimetableColumnsDialogProps extends BasePuiDialogProps {
  area: string
}

const AdjustTimetableColumnsDialog = ({
  area = TimetableArea.SCHEDULER,
  open,
  onClose,
}: AdjustTimetableColumnsDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'TimeTable'])
  const dispatch = useDispatch()
  const list = useSelector(getMembersSelectList)
  const persons = useSelector(getMultipleUsers(list))
  const teamsList = useSelector(getTeamsList)
  const teams = useSelector(getMultipleUsers(teamsList))
  const isFetching = useSelector(getMembersIsFetchingSelectList)
  const currentBusinessId = useSelector(getCurrentBusinessId) as string
  const columnsFromStore =
    useSelector(
      getTimetableConfiguredColumnsForArea(currentBusinessId, area),
    ) || []
  const currentUserId = useSelector(getCurrentUserId) as string
  const ScheduleColumnNames: Constant[] = useSelector(getScheduleColumnNames)
  const showOtcSaleEvents = useSelector(getShowOtcSaleEvents)
  const businessId = useSelector(getCurrentBusinessId)
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)
  const isTeamsPhase2Enabled = useSelector(
    getFeatureToggle(FeatureToggle.TEAMS_PHASE_2),
  )

  const listWithTeams = [...list, ...R.pluck('id', teams)]
  const newItems = R.difference(
    isTeamsPhase2Enabled ? listWithTeams : list,
    columnsFromStore,
  )
  const items = [...newItems, ...columnsFromStore]

  const isAutoAssignEventEnabled = useSelector(
    getCurrentBusinessIsAutoAssignEventEnabled,
  )
  const RolesList = useMainStaffRoles()

  const getInitialColumns = () => {
    const hasUnassigned = R.includes(SpecialTimetableColumn.UNASSIGNED, items)

    const itemsWithSpecialColumns = [
      !hasUnassigned &&
        !(isAutoAssignEventEnabled && isOmnichannel) &&
        SpecialTimetableColumn.UNASSIGNED,
      ...items,
      ...R.pluck(
        'name',
        ScheduleColumnNames.filter(({ name }) => !R.includes(name, items)),
      ),
      !hasUnassigned &&
        isAutoAssignEventEnabled &&
        isOmnichannel &&
        SpecialTimetableColumn.UNASSIGNED,
    ].filter(Boolean) as string[]

    return itemsWithSpecialColumns
      .map((id) => {
        let person = Utils.findById(id, persons)
        if (!person) {
          person = Utils.findById(id, teams)
        }
        const label = person
          ? Utils.getPersonString(person)
          : SpecialTimetableColumnNames[id as SpecialTimetableColumn]

        return {
          id,
          label,
        }
      })
      .filter((column) => column.label)
  }

  const [columns, setColumns] = useState(getInitialColumns())

  useEffect(() => {
    if (open) {
      dispatch(
        fetchMembersForSelectList({
          from: 0,
          to: 1000,
          includeInactive: false,
          shorten: true,
          roleIds: RolesList.map((role) => role.id),
          noRolesInResult: true,
        }),
      )
      if (businessId && isTeamsPhase2Enabled) {
        dispatch(fetchTeamsList({ businessId, includeInactive: true }))
      }
    }
  }, [open, businessId])

  useEffect(() => {
    setColumns(getInitialColumns())
  }, [list, teamsList])

  const proceed = () => {
    const columnsToSave = R.pluck('id', columns)
    dispatch(
      updateTimetableConfiguredColumns(
        currentUserId,
        currentBusinessId,
        area,
        columnsToSave,
      ),
    )
    if (onClose) {
      onClose()
    }
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newColumns = R.move(
      result.source.index,
      result.destination.index,
      columns,
    )

    setColumns(newColumns)
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader className={classes.button} onClick={proceed}>
          {t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="adjust-timetable-columns-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      scroll="paper"
      title={t('TimeTable:ADJUST_COLUMN_ORDER')}
      onClose={onClose}
    >
      <PuiSwitch
        checked={showOtcSaleEvents}
        classes={{ root: classes.switch }}
        disabled={isFetching}
        label={t('TimeTable:LOAD_OTC_EVENTS')}
        onChange={() =>
          dispatch(updateShowOtcSaleEvents(currentUserId, !showOtcSaleEvents))
        }
      />
      <CircularProgressOverlay open={isFetching} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {columns.map((column, index) => (
                <Draggable
                  draggableId={column.id}
                  index={index}
                  key={column.id}
                >
                  {(changed, snapshot) => (
                    <Grid
                      container
                      item
                      alignItems="center"
                      className={classNames(classes.row, {
                        [classes.rowDragged]: snapshot.isDragging,
                      })}
                      px={2}
                      py={1}
                      ref={changed.innerRef}
                      {...changed.draggableProps}
                      {...changed.dragHandleProps}
                    >
                      <DragIndicator className={classes.indicator} />
                      <Text ml={1} variant="body2">
                        {column.label}
                      </Text>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </PuiDialog>
  )
}

export default AdjustTimetableColumnsDialog
