import React, { useRef, useState } from 'react'
import { Grid, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import { VitalChartItem } from '~/types'
import useWindowResize from '~/utils/useWindowResize'

import { getGridLines } from './utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    gridLine: {
      stroke: theme.colors.filterBorderColor,
    },
    scrollContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }),
  { name: 'VitalsChart' },
)

const getLines = (count: number) => new Array(count).fill(undefined)

interface VitalsChartProps {
  chartHeight?: number
  data: VitalChartItem[]
  gridLinesCount?: number
  paddingBottom?: number
  paddingLeft?: number
  paddingRight?: number
  paddingTop?: number
  pointRadius?: number
}

const VitalsChart = ({
  data,
  gridLinesCount = 4,
  chartHeight = 120,
  paddingTop = 24,
  paddingBottom = 20,
  paddingLeft = 16,
  paddingRight = 16,
  pointRadius = 6,
}: VitalsChartProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const pointsHorizontalPadding = pointRadius
  const legendHeight = 16.5
  const scrollHeight = 16
  const minDataPointSectionWidth = 70

  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const chartWidth = Math.max(
    Math.max(dimensions.width, minDataPointSectionWidth * data.length) - 1,
    0,
  )

  const updateContainerSize = () => {
    if (containerRef.current) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      })
    }
  }

  useWindowResize(updateContainerSize)

  const values = R.pluck('value', data)

  const reverseY = (y: number) => chartHeight - y

  const stepHeight =
    (chartHeight - paddingTop - paddingBottom) / (gridLinesCount - 1)

  const lines = getLines(gridLinesCount).map((_, index) => {
    const y = paddingBottom + index * stepHeight
    const x1 = paddingLeft
    const y1 = y
    const x2 = chartWidth - paddingRight
    const y2 = y

    return `M ${x1} ${y1} L${x2} ${y2}`
  })

  const pointStep =
    (chartWidth - paddingLeft - paddingRight - 2 * pointsHorizontalPadding) /
    Math.max(values.length - 1, 1)
  const gridLines = getGridLines(values, gridLinesCount)
  const pointsDelta =
    (chartHeight - (paddingTop + paddingBottom)) /
    (gridLines[gridLinesCount - 1] - gridLines[0])

  const points = values.map((value, index) => {
    const y = paddingBottom + (value - gridLines[0]) * pointsDelta
    return {
      x: pointsHorizontalPadding + paddingLeft + pointStep * index,
      y: reverseY(y),
      r: pointRadius,
    }
  })

  const path = points.reduce(
    (acc, { x, y }, index) => `${acc}${index === 0 ? 'M' : 'L'}${x} ${y}`,
    '',
  )

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      justifyContent="center"
      mx={2}
      ref={containerRef}
      style={{ height: chartHeight + legendHeight + scrollHeight }}
    >
      <Grid
        container
        className={classes.scrollContainer}
        style={{ width: chartWidth }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          {data.map(({ label }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={index} variant="body4">
              {label}
            </Text>
          ))}
        </Grid>
        <svg height={chartHeight} width={chartWidth}>
          {lines.map((line, index) => {
            const y = reverseY(paddingBottom + index * stepHeight)

            const x1 = paddingLeft
            const y1 = y
            const x2 = chartWidth - paddingRight
            const y2 = y

            return (
              <path
                className={classes.gridLine}
                d={`M ${x1} ${y1} L${x2} ${y2}`}
                fill="none"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                strokeWidth="0.5"
              />
            )
          })}

          <path
            d={path}
            fill="none"
            stroke={theme.colors.title}
            strokeWidth="2"
          />

          {points.map(({ x, y, r }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <g key={index}>
              <text
                dominantBaseline="middle"
                fill={theme.colors.title}
                fontSize={12}
                textAnchor="middle"
                x={x}
                y={y - 16}
              >
                {values[index]}
              </text>
              <circle
                cx={x}
                cy={y}
                fill={theme.colors.title}
                r={r}
                stroke={theme.colors.title}
                strokeWidth="2"
              />
            </g>
          ))}
        </svg>
      </Grid>
    </Grid>
  )
}

export default VitalsChart
