import React, { forwardRef, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  InfiniteLoaderList,
  PermissionArea,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  fetchDiscountReasons,
  getDiscountReasonsList,
} from '~/store/duck/discountReasons'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import {
  BasePracticeDetailsSectionProps,
  PracticeDiscountReasonConfigurationFields,
} from '~/types'
import { getBusinessIsGroup } from '~/utils/businessUtils'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'
import DiscountItem from './DiscountConfigurationItem'
import DiscountAdd from './DiscountsAdd'

const DISCOUNT_REASON_HEADER_HEIGHT = 30
const DISCOUNT_REASON_ITEM_HEIGHT = 40
const COUNT_VISIBLE_TEAMS = 10
const MAX_DISCOUNT_REASON_LIST_HEIGHT =
  COUNT_VISIBLE_TEAMS * DISCOUNT_REASON_ITEM_HEIGHT

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
    },
    tableHeader: {
      height: DISCOUNT_REASON_HEADER_HEIGHT,
      borderBottom: theme.constants.tableBorder,
    },
    tableAdd: {
      height: DISCOUNT_REASON_ITEM_HEIGHT,
    },
    tableItem: {
      height: DISCOUNT_REASON_ITEM_HEIGHT,
    },
    tableLastItem: {
      height: DISCOUNT_REASON_ITEM_HEIGHT - 1,
      borderBottom: 0,
    },
    addItem: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
    },
  }),
  { name: 'DiscountReasonConfiguration' },
)

const DiscountReasonConfiguration = forwardRef<
  HTMLDivElement,
  BasePracticeDetailsSectionProps
>(function DiscountReasonConfiguration({ business }, ref) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.REASON_FOR_DISCOUNT, business),
  )
  const businessId = business?.id
  const isGroup = getBusinessIsGroup(business)
  const discountReasonList = useSelector(getDiscountReasonsList)
  const [addDiscountReasonEnabled, setAddDiscountReasonEnabled] =
    useState(false)

  const discountReasonListHeightByItemLength =
    discountReasonList?.length &&
    discountReasonList.length * DISCOUNT_REASON_ITEM_HEIGHT
  const discountReasonListHeight = Math.min(
    MAX_DISCOUNT_REASON_LIST_HEIGHT,
    discountReasonListHeightByItemLength,
  )

  useEffect(() => {
    if (businessId) {
      dispatch(fetchDiscountReasons(businessId))
    }
  }, [businessId])

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'discountReasonMandatory',
        type: 'toggle',
        label: t('Admin:PRACTICE.DISCOUNT.REQUIRED_TOGGLE'),
        initialValue: business.discountReasonMandatory || false,
      },
    ],
    false,
  )

  const { discountReasonMandatory } = fields

  usePracticeFieldsSection<PracticeDiscountReasonConfigurationFields>({
    business,
    fields,
    parsedFields: {
      discountReasonMandatory: discountReasonMandatory.value,
    },
    sectionName: PracticeDetailsPanels.DISCOUNT_REASON_CONFIGURATION,
    validate,
    reset,
    resetDependencies: [business],
    softUpdate: R.any(Boolean, [
      discountReasonMandatory.value !== discountReasonMandatory.initialValue,
    ]),
  })

  return (
    <Grid className={classes.root} ref={ref}>
      {!isGroup && (
        <Box>
          <PuiSwitch
            checked={discountReasonMandatory.value}
            disabled={!permissions.update}
            field={discountReasonMandatory}
            label={
              <TextWithTooltip
                allowWrap
                tooltipText={t(
                  'Admin:PRACTICE.DISCOUNT.REQUIRED_TOGGLE_TOOLTIP',
                )}
              >
                {discountReasonMandatory.label}
              </TextWithTooltip>
            }
          />
        </Box>
      )}
      <TextWithTooltip strong variant="subheading3">
        {t('Admin:PRACTICE.DISCOUNT.REASON_FOR_DISCOUNT')}
      </TextWithTooltip>
      <Grid className={classes.table}>
        <Grid container item className={classes.tableHeader} wrap="nowrap">
          <Grid container item alignItems="center" px={1.5} xs={1}>
            <Text strong variant="lowAccent2">
              {t('Common:ACTIVE_ONE')}
            </Text>
          </Grid>
          <Grid container item alignItems="center" px={1.5}>
            <Text strong variant="lowAccent2">
              {t('Admin:PRACTICE.DISCOUNT.REASON_FOR_DISCOUNT')}
            </Text>
          </Grid>
        </Grid>
        <InfiniteLoaderList
          isItemLoaded={R.F}
          itemCount={discountReasonList.length}
          itemData={discountReasonList}
          itemSpacing={0}
          loadMoreItems={R.F}
          style={{ height: discountReasonListHeight }}
        >
          {(dr = {}, index) => (
            <DiscountItem
              business={business}
              className={classNames(classes.tableItem, {
                [classes.tableLastItem]:
                  index === discountReasonList.length - 1,
              })}
              key={dr.id}
              reason={dr}
            />
          )}
        </InfiniteLoaderList>
        {permissions.update && addDiscountReasonEnabled && (
          <DiscountAdd
            businessId={businessId}
            className={classes.tableAdd}
            onDiscard={() => setAddDiscountReasonEnabled(false)}
          />
        )}
      </Grid>
      {permissions.update && !addDiscountReasonEnabled && (
        <AddButton
          addText={t('Common:ADD_DISCOUNT')}
          classes={{ addItem: classes.addItem }}
          onAdd={() => setAddDiscountReasonEnabled(true)}
        />
      )}
    </Grid>
  )
})

export default memo(DiscountReasonConfiguration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
