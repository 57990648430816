import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, moment } from '@pbt/pbt-ui-components'

import { ShipmentItem } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_SHIPMENT_ITEMS_FOR_VARIATION =
  'shipmentItems/FETCH_SHIPMENT_ITEMS_FOR_VARIATION'
export const FETCH_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS =
  'shipmentItems/FETCH_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS'
export const FETCH_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE =
  'shipmentItems/FETCH_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE'

export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION'
export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS'
export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE'

export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE'
export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_SUCCESS =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_SUCCESS'
export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_FAILURE =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_FAILURE'

export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION'
export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_SUCCESS =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_SUCCESS'
export const FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_FAILURE =
  'shipmentItems/FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_FAILURE'

export const UPDATE_SHIPMENT_ITEMS = 'shipmentItems/UPDATE_SHIPMENT_ITEMS'

export const CLEAR_SHIPMENT_ITEMS = 'shipmentItems/CLEAR_SHIPMENT_ITEMS'

export const fetchShipmentItemsForVariation = (
  variationId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_SHIPMENT_ITEMS_FOR_VARIATION,
  variationId,
  from,
  to,
})
export const fetchShipmentItemsForVariationSuccess = (
  list: string[],
  from: number,
  totalCount: number,
) => ({
  type: FETCH_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS,
  list,
  from,
  totalCount,
})
export const fetchShipmentItemsForVariationFailure = (error: ApiError) => ({
  type: FETCH_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE,
  error,
})

export const fetchMoreShipmentItemsForVariation = (
  variationId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION,
  variationId,
  from,
  to,
})
export const fetchMoreShipmentItemsForVariationSuccess = (
  list: string[],
  from: number,
  totalCount: number,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS,
  list,
  from,
  totalCount,
})
export const fetchMoreShipmentItemsForVariationFailure = (error: ApiError) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE,
  error,
})

export const fetchMoreShipmentItemsForVaccine = (
  vaccinationId: string,
  from: number,
  to: number,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE,
  vaccinationId,
  from,
  to,
})
export const fetchMoreShipmentItemsForVaccineSuccess = (
  list: string[],
  from: number,
  totalCount: number,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_SUCCESS,
  list,
  totalCount,
})
export const fetchMoreShipmentItemsForVaccineFailure = (error: ApiError) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_FAILURE,
  error,
})

export const fetchMoreShipmentItemsForVaccineWithVariation = (
  vaccinationId: string,
  from: number,
  to: number,
  includeExpiredItems: boolean,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION,
  vaccinationId,
  from,
  to,
  includeExpiredItems,
})
export const fetchMoreShipmentItemsForVaccineWithVariationSuccess = (
  list: string[],
  from: number,
  totalCount: number,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_SUCCESS,
  list,
  totalCount,
})
export const fetchMoreShipmentItemsForVaccineWithVariationFailure = (
  error: ApiError,
) => ({
  type: FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_FAILURE,
  error,
})

export const updateShipmentItems = (
  shipmentItems: Record<string, ShipmentItem>,
) => ({
  type: UPDATE_SHIPMENT_ITEMS,
  shipmentItems,
})

export const clearShipmentItems = () => ({ type: CLEAR_SHIPMENT_ITEMS })

export type ShipmentItemsState = {
  error: string | null
  isLoading: boolean
  list: string[]
  map: Record<string, ShipmentItem>
  totalCount: number
}

const INITIAL_STATE: ShipmentItemsState = {
  list: [],
  map: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isLoading: false,
  error: null,
}

export const shipmentItemsReducer = (
  state: ShipmentItemsState = INITIAL_STATE,
  action: AnyAction,
): ShipmentItemsState => {
  switch (action.type) {
    case CLEAR_SHIPMENT_ITEMS:
      return {
        list: [],
        map: {},
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        isLoading: false,
        error: null,
      }
    case UPDATE_SHIPMENT_ITEMS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.shipmentItems),
      }
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE:
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_SUCCESS:
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: R.uniq([...state.list, ...action.list]),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_FAILURE:
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_SHIPMENT_ITEMS_FOR_VARIATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.list,
        totalCount: action.totalCount,
      }
    case FETCH_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: R.uniq([...state.list, ...action.list]),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

const getShipmentItems = (state: RootState): ShipmentItemsState =>
  state.shipmentItems
export const getIsLoading = (state: RootState) =>
  getShipmentItems(state).isLoading
export const getTotalCount = (state: RootState) =>
  getShipmentItems(state).totalCount
export const getShipmentItemsList = (state: RootState) =>
  getShipmentItems(state).list
export const getShipmentItemsMap = (state: RootState) =>
  getShipmentItems(state).map
export const getShipmentItem = (id: string) =>
  createSelector(getShipmentItemsMap, (map) => R.prop(id, map))
export const getMultipleShipmentItems = (ids: string[]) =>
  createSelector(getShipmentItemsMap, (map) => R.props(ids, map))

export const getShipmentItemsListSortedByExpirationDate = createSelector(
  [getShipmentItemsList, getShipmentItemsMap],
  (list, map) => {
    const items = list.map((id) => map[id])
    const sortedItems = R.sortBy(
      (item) =>
        item.expirationDate ? moment(item.expirationDate).valueOf() : 0,
      items,
    )
    return sortedItems.map((item) => item.id)
  },
)
