import React from 'react'
import { Grid, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EmployeeIdInfo, Text } from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import i18n from '~/locales/i18n'

import EmployeeIdDuplicateWarning from './EmployeeIdDuplicateWarning'

const useStyles = makeStyles(
  () => ({
    name: {
      whiteSpace: 'normal',
      fontSize: '1.4rem',
    },
  }),
  { name: 'EmployeeIdSectionTable' },
)

const headerRow = [
  { label: i18n.t('Admin:MEMBER.EMPLOYEE_ID.ISSUING_AUTHORITY') },
  { label: i18n.t('Common:ADDITIONAL_INFORMATION') },
]

type TableItem = Omit<EmployeeIdInfo, 'employeeIdNumber'> & {
  employeeIdNumber: JSX.Element
}

export interface EmployeeIdsInfoTableProps
  extends Omit<
    ScrollableDataTableProps<TableItem, false>,
    'data' | 'headerRow' | 'mainColumns'
  > {
  employeeIdInfos?: EmployeeIdInfo[]
}

const EmployeeIdSectionTable = ({
  employeeIdInfos,
  ...rest
}: EmployeeIdsInfoTableProps) => {
  const classes = useStyles()

  const mainColumns = [
    {
      label: i18n.t('Admin:MEMBER.EMPLOYEE_ID.EMPLOYEE_ID_TYPE'),
      prop: 'employeeIdType' as keyof EmployeeIdInfo,
      width: 100,
    },
    {
      label: i18n.t('Admin:MEMBER.EMPLOYEE_ID.EMPLOYEE_ID_NUMBER'),
      prop: 'employeeIdNumber' as keyof EmployeeIdInfo,
      width: 350,
    },
  ]

  const mappedData = employeeIdInfos?.map((item) => ({
    ...item,
    employeeIdNumber: (
      <Grid container alignItems="end">
        <Text className={classes.name} variant="body">
          {item.employeeIdNumber}
        </Text>
        {item.duplicate && (
          <Grid mb={0.5} ml={0.5}>
            <EmployeeIdDuplicateWarning />
          </Grid>
        )}
      </Grid>
    ),
  }))

  return (
    <ScrollableDataTable
      duplicateDisabled
      classes={classes}
      data={mappedData}
      headerRow={headerRow}
      mainColumns={mainColumns}
      {...rest}
    >
      {({ item: employeeIdInfo, tableCellClassName }) => (
        <>
          <TableCell className={tableCellClassName}>
            <Text fontSize="1.4rem">
              {employeeIdInfo?.issuingAuthority || ''}
            </Text>
          </TableCell>
          <TableCell className={tableCellClassName}>
            <Text fontSize="1.4rem">{employeeIdInfo?.notes || ''}</Text>
          </TableCell>
        </>
      )}
    </ScrollableDataTable>
  )
}

export default EmployeeIdSectionTable
