import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  RefundCalculation,
  RefundInvoice,
  RefundInvoiceInput,
} from '~/api/graphql/generated/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export type RefundsState = {
  error: string | Nil
  id: string
  isLoading: boolean
  refundCalculationMap: { [invoideId: string]: RefundCalculation | Nil }
  refundInvoiceMap: { [invoideId: string]: RefundInvoice | Nil }
}

export const INITIAL_STATE: RefundsState = {
  error: null,
  id: '',
  isLoading: false,
  refundCalculationMap: {},
  refundInvoiceMap: {},
}

const refundsSlice = createSlice({
  name: 'refunds',
  initialState: INITIAL_STATE,
  reducers: {
    calculateRefundAmount: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        input: RefundInvoiceInput
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    calculateRefundAmountSuccess: (
      state,
      { payload }: PayloadAction<RefundCalculation>,
    ) => {
      state.isLoading = false
      state.refundCalculationMap[payload.originalInvoice.id] = payload
    },
    calculateRefundAmountFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
    fetchRefundInvoice: (
      state,
      action: PayloadAction<{
        id: string
      }>,
    ) => {
      state.isLoading = true
      state.id = action.payload?.id || state.id
      state.error = null
    },
    fetchRefundInvoiceSuccess: (
      state,
      action: PayloadAction<RefundInvoice>,
    ) => {
      state.isLoading = false
      state.refundInvoiceMap[action.payload.id] = action.payload
      state.id = action.payload.id
    },
    fetchRefundInvoiceFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
      state.id = ''
    },
    postRefundInvoice: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        input: RefundInvoiceInput
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    postRefundInvoiceSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.id = action.payload
    },
    postRefundInvoiceFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
      state.id = ''
    },
    resetRefundState: () => INITIAL_STATE,
    voidRefundInvoice: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string
      }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    voidRefundInvoiceSuccess: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.id = action.payload
    },
    voidRefundInvoiceFailure: (
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
    },
  },
})

const { actions, reducer } = refundsSlice

export const {
  calculateRefundAmount,
  calculateRefundAmountSuccess,
  calculateRefundAmountFailure,
  fetchRefundInvoice,
  fetchRefundInvoiceSuccess,
  fetchRefundInvoiceFailure,
  postRefundInvoice,
  postRefundInvoiceSuccess,
  postRefundInvoiceFailure,
  resetRefundState,
  voidRefundInvoice,
  voidRefundInvoiceSuccess,
  voidRefundInvoiceFailure,
} = actions

export default reducer

const getRefundsState = (state: RootState): RefundsState => state.refunds
export const getRefundsLoading = (state: RootState) =>
  getRefundsState(state).isLoading
export const getRefundsId = (state: RootState) => getRefundsState(state).id
export const getRefundCalculationMap = (state: RootState) =>
  getRefundsState(state).refundCalculationMap
export const getRefundCalculation = (invoiceId: string | Nil) =>
  createSelector(getRefundCalculationMap, (map) =>
    invoiceId ? map[invoiceId] : undefined,
  )
export const getRefundInvoiceMap = (state: RootState) =>
  getRefundsState(state).refundInvoiceMap
export const getRefundInvoice = (invoiceId: string | Nil) =>
  createSelector(getRefundInvoiceMap, (map) =>
    invoiceId ? map[invoiceId] : undefined,
  )
export const getRefundsError = (state: RootState) =>
  getRefundsState(state).error
