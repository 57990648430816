import i18n from '~/locales/i18n'

enum SearchContext {
  CLIENT_PATIENTS = 'Client & patients',
  CONTACTS = 'Contacts',
  INVENTORY = 'Inventory',
  LAB_TESTS = 'Lab tests',
  MARKETPLACE = 'Marketplace',
  PROCEDURES = 'Procedures',
  REMINDER_PROTOCOLS = 'Reminder protocols',
  TASK_DASHBOARD = 'Task dashboard',
  IMAGING_DASHBOARD = 'Imaging dashboard',
  PRACTICES = 'Practices',
  ON_HAND_CATALOG = 'Inventory on-hand',
}

export const SearchLabel = {
  [SearchContext.CLIENT_PATIENTS]: i18n.t('Common:CLIENT_AND_PATIENTS'),
  [SearchContext.CONTACTS]: i18n.t('Common:CONTACT_OTHER'),
  [SearchContext.INVENTORY]: i18n.t('Common:INVENTORY'),
  [SearchContext.LAB_TESTS]: i18n.t('Common:LAB_TESTS'),
  [SearchContext.MARKETPLACE]: i18n.t('Common:MARKETPLACE'),
  [SearchContext.PROCEDURES]: i18n.t('Common:PROCEDURE_OTHER'),
  [SearchContext.REMINDER_PROTOCOLS]: i18n.t('Common:REMINDER_PROTOCOLS'),
  [SearchContext.TASK_DASHBOARD]: i18n.t(
    'Constants:SEARCH_CONTEXT.TASK_DASHBOARD',
  ),
  [SearchContext.IMAGING_DASHBOARD]: i18n.t(
    'Constants:SEARCH_CONTEXT.IMAGING_DASHBOARD',
  ),
  [SearchContext.PRACTICES]: i18n.t('Common:PRACTICE_OTHER'),
  [SearchContext.ON_HAND_CATALOG]: i18n.t(
    'Constants:SEARCH_CONTEXT.INVENTORY_ON-HAND',
  ),
}

export default SearchContext
