import React from 'react'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Nil, Text } from '@pbt/pbt-ui-components'
import { MembershipStatus } from '@pbt/pbt-ui-components/src/constants/wellnessPlans'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import { useClientMembershipStatus } from '~/components/dashboard/wellness-plans/wellnessPlanUtils'

import ClientInfoLinkItem from './ClientInfoLinkItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      borderRadius: 4,
      padding: theme.typography.pxToRem(8),
    },
    rootPaused: {
      backgroundColor: theme.colors.actionNeededBackground,
      color: theme.colors.important,
    },
    rootActive: {
      backgroundColor: theme.colors.success,
      color: theme.colors.profileText,
    },
    label: {
      color: 'inherit',
    },
    iconButton: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginLeft: 'auto',
    },
  }),
  { name: 'ClientMembershipLinkItem' },
)

interface ClientMembershipLinkItemProps {
  alertText?: string | Nil
  classes?: ClassesType<typeof useStyles>
  clientId: string
  highlighted?: boolean
  label: string
  onClick: () => void
  showDivider?: boolean
  subLabel?: string
}

const ClientMembershipLinkItem = (props: ClientMembershipLinkItemProps) => {
  const { classes: classesProp, label, highlighted, onClick, clientId } = props
  const classes = useStyles({ classes: classesProp })
  const membershipStatus = useClientMembershipStatus(clientId)

  if (membershipStatus === MembershipStatus.CANCELLED) {
    return <ClientInfoLinkItem {...props} />
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={classNames(classes.root, {
          [classes.rootPaused]: membershipStatus === MembershipStatus.PAUSED,
          [classes.rootActive]: membershipStatus === MembershipStatus.ACTIVE,
        })}
        my={0.5}
        onClick={onClick}
      >
        <Text
          className={classes.label}
          strong={highlighted}
          variant={highlighted ? 'h5' : 'body2'}
        >
          {label}
        </Text>
        <IconButton
          className={classes.iconButton}
          size="large"
          type="button"
          onClick={onClick}
        >
          <EyeIcon
            className={classNames({
              [classes.rootPaused]:
                membershipStatus === MembershipStatus.PAUSED,
              [classes.rootActive]:
                membershipStatus === MembershipStatus.ACTIVE,
            })}
          />
        </IconButton>
      </Grid>
    </>
  )
}

export default ClientMembershipLinkItem
