import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AlertIconType,
  BasePuiDialogProps,
  Nil,
  PuiAlert,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { PatientUpdateReason } from '~/types'
import useDialog from '~/utils/useDialog'

interface AutomatedPatientUpdateAlertDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  name: string
  patientId: string | Nil
  patientName?: string
  patientUpdateReason: PatientUpdateReason
  patientUpdateReverted?: boolean
}

const AutomatedPatientUpdateAlertDialog = ({
  onClose,
  open,
  patientName: patientNameProp,
  patientUpdateReason,
  patientUpdateReverted,
  name,
  patientId,
  clientId,
}: AutomatedPatientUpdateAlertDialogProps) => {
  const { t } = useTranslation(['Common', 'Clients', 'Utils'])

  const [openCancelFutureAppointmentsAndMembershipDialog] = useDialog(
    DialogNames.CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP,
  )

  const isCancelAppointmentsAndWplanForDeceasedPetEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.CANCEL_APPOINTMENTS_AND_WPLAN_FOR_DECEASED_PET,
    ),
  )

  const automatedPatientUpdateAlertName =
    name || t('Clients:AUTOMATED_PATIENT_UPDATE_ALERT.UNKNOWN_PROCEDURE')

  const PatientUpdateReasonLabel = {
    [PatientUpdateReason.SPAY]: t('Common:GENDER').toLowerCase(),
    [PatientUpdateReason.NEUTER]: t('Common:GENDER').toLowerCase(),
    [PatientUpdateReason.DECEASE]: t('Common:STATUS').toLowerCase(),
  }

  const PatientUpdateReasonValue = {
    [PatientUpdateReason.SPAY]: t(
      'Utils:GENDER_UTILS.SPAYED_FEMALE',
    ).toLowerCase(),
    [PatientUpdateReason.NEUTER]: t(
      'Utils:GENDER_UTILS.NEUTERED_MALE',
    ).toLowerCase(),
    [PatientUpdateReason.DECEASE]: t('Common:DECEASED').toLowerCase(),
  }

  const PatientUpdateReasonRevertedValue = {
    [PatientUpdateReason.SPAY]: t('Common:SPAYED').toLowerCase(),
    [PatientUpdateReason.NEUTER]: t('Common:NEUTERED').toLowerCase(),
    [PatientUpdateReason.DECEASE]: t('Common:DECEASED').toLowerCase(),
  }

  const updateReasonLabel =
    PatientUpdateReasonLabel[patientUpdateReason] ||
    t('Clients:AUTOMATED_PATIENT_UPDATE_ALERT.UPDATE_REASON_LABEL_FALLBACK')
  const updateReasonValue = patientUpdateReverted
    ? PatientUpdateReasonRevertedValue[patientUpdateReason] || ''
    : PatientUpdateReasonValue[patientUpdateReason] ||
      t('Clients:AUTOMATED_PATIENT_UPDATE_ALERT.UPDATE_REASON_VALUE_FALLBACK')
  const patientName = patientNameProp || t('Common:PATIENT').toLowerCase()

  const handleClose = () => {
    if (onClose) {
      onClose()
    }

    const isPatientGotDeceased =
      patientId &&
      clientId &&
      !patientUpdateReverted &&
      patientUpdateReason === PatientUpdateReason.DECEASE

    if (
      isCancelAppointmentsAndWplanForDeceasedPetEnabled &&
      isPatientGotDeceased
    ) {
      openCancelFutureAppointmentsAndMembershipDialog({
        clientId,
        patientId,
        patientName,
      })
    }
  }
  return (
    <PuiAlert
      iconType={AlertIconType.WARN}
      message={
        patientUpdateReverted ? (
          <Trans
            defaults="Patient is no longer marked as: <strong>{updateReasonValue}</strong>"
            i18nKey="Clients:AUTOMATED_PATIENT_UPDATE_ALERT.PATIENT_NO_LONGER_MARKED"
            values={{ updateReasonValue }}
          />
        ) : (
          <Trans
            defaults={
              'Since you’ve selected <strong>{name}</strong>,\u00a0{patientName}’s ' +
              '{updateReasonLabel} will be updated to <strong>{updateReasonValue}</strong>.'
            }
            i18nKey="Clients:AUTOMATED_PATIENT_UPDATE_ALERT.SINCE_YOU_HAVE_SELECTED_NAME"
            values={{
              name: automatedPatientUpdateAlertName,
              patientName,
              updateReasonLabel,
              updateReasonValue,
            }}
          />
        )
      }
      okButtonText={t('Common:GOT_IT')}
      open={open}
      onClose={handleClose}
      onOk={handleClose}
    />
  )
}

export default AutomatedPatientUpdateAlertDialog
