import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid, Tooltip } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import BusinessShareIcon from '~/components/common/icons/BusinessShareIcon'
import { DiagnosisSnapshotItem } from '~/types'

interface DiagnosisNameCellProps {
  item: DiagnosisSnapshotItem
}

const DiagnosisNameCell = ({ item }: DiagnosisNameCellProps) => {
  const { description, descriptionTranslation, name } = item
  const title = descriptionTranslation || description || name || ''

  return (
    <Grid container item justifyContent="space-between" wrap="nowrap">
      <Tooltip placement="top" title={title}>
        <Grid item>
          <Dotdotdot clamp={1}>
            <Text strong variant="body2">
              {title}
            </Text>
          </Dotdotdot>
        </Grid>
      </Tooltip>
      <BusinessShareIcon
        businessIds={item.businessId ? [item.businessId] : []}
      />
    </Grid>
  )
}

export default DiagnosisNameCell
