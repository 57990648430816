import React, { forwardRef } from 'react'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MomentInput } from 'moment'
import { moment, Text } from '@pbt/pbt-ui-components'

import Needle from '~/components/common/Needle'

const STEP_TIME_DIFFERENCE_ADJUSTMENT = 5
const STEP_TIME_INTERSEPTION_OFFSET = -3

const useStyles = makeStyles(
  (theme) => ({
    root: {
      pointerEvents: 'none',
      left: theme.spacing(12),
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'below'),
    },
    needleHorizontal: {
      width: `calc(100% - ${theme.spacing(1)})`,
      borderWidth: 2,
      borderColor: theme.colors.markerHighlighted,
    },
    needleHead: {
      display: 'none',
    },
    text: {
      top: 4,
      position: 'absolute',
      left: -32,
    },
  }),
  { name: 'WorkingHourNeedle' },
)

export interface WorkingHourNeedleProps {
  endDate: MomentInput
  label: string
  startDate: MomentInput
  stepInterval: number
  time?: string
}

const WorkingHourNeedle = forwardRef<HTMLDivElement, WorkingHourNeedleProps>(
  function TimeNeedle({ time, label, startDate, endDate, stepInterval }, ref) {
    const classes = useStyles()
    const theme = useTheme()

    const momentTime = moment(time)

    // no time provided
    // or day has not yet started
    // or has already ended
    if (
      !time ||
      momentTime.isBefore(startDate, 'minutes') ||
      momentTime.isAfter(endDate, 'minutes')
    ) {
      return null
    }

    const stepHeightKoef = theme.constants.schedulerRowHeight / stepInterval

    const needleTopOffset =
      momentTime.diff(startDate, 'minutes') * stepHeightKoef + 2

    const stepTimeDifference = momentTime.minutes() % stepInterval

    const isStepTimeInterseption = stepTimeDifference === 0

    const hasTextBottomOffset =
      !isStepTimeInterseption &&
      stepTimeDifference <= STEP_TIME_DIFFERENCE_ADJUSTMENT
    const hasTextTopOffset =
      !isStepTimeInterseption &&
      stepTimeDifference >= stepInterval - STEP_TIME_DIFFERENCE_ADJUSTMENT

    const textBottomOffset =
      hasTextBottomOffset &&
      STEP_TIME_DIFFERENCE_ADJUSTMENT - stepTimeDifference
    const textTopOffset =
      hasTextTopOffset &&
      stepInterval - STEP_TIME_DIFFERENCE_ADJUSTMENT - stepTimeDifference

    const defaultTextOffset = isStepTimeInterseption
      ? STEP_TIME_INTERSEPTION_OFFSET
      : 0

    const textOffset = textBottomOffset || textTopOffset || defaultTextOffset

    return (
      <Needle
        classes={{
          root: classes.root,
          needleHorizontal: classes.needleHorizontal,
          needleHead: classes.needleHead,
        }}
        direction="horizontal"
        ref={ref}
        style={{ top: needleTopOffset }}
      >
        <Text
          className={classes.text}
          fontSize="1rem"
          style={{ transform: `translateY(${textOffset * stepHeightKoef}px)` }}
          variant="h4"
        >
          {label}
        </Text>
      </Needle>
    )
  },
)

export default WorkingHourNeedle
