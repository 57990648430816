import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { ClassesType, TextInteractive } from '@pbt/pbt-ui-components'

import EditItemButtonGroup, {
  EditItemButtonGroupProps,
} from '../../buttons/EditItemButtonGroup'
import type { PuiDataTableColumn } from './puiDataTableType'

const DEFAULT_MAIN_COLUMN_WIDTH = 256

export const usePuiDataTableRowStyles = makeStyles(
  (theme) => ({
    row: {
      height: '100%',
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    filledBackground: {
      backgroundColor: theme.colors.tableOddRowBackground,
    },
    cell: {
      height: '100%',
    },
    mainColumn: {
      borderRight: theme.constants.tableBorder,
    },
    selectedRow: {
      border: theme.constants.tableBorderSelected,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    selectableRow: {
      cursor: 'pointer',
    },
    stretchCell: {
      height: 'auto',
    },
    stretchRow: {
      display: 'flex',
      alignItems: 'stretch',
      minHeight: ({ minRowHeight }: UseStylesProps) => minRowHeight,
    },
    withBorder: {
      borderRight: theme.constants.tabBorder,
      borderBottom: theme.constants.tabBorder,
      '&:last-child': {
        borderRight: 0,
      },
    },
  }),
  { name: 'PuiDataTableRow' },
)

interface UseStylesProps {
  classes?: ClassesType<typeof usePuiDataTableRowStyles>
  minRowHeight?: number
}

export interface PuiDataTableRowProps<T = any>
  extends UseStylesProps,
    Omit<Partial<EditItemButtonGroupProps>, 'disabled' | 'item'> {
  columns: PuiDataTableColumn[]
  dynamicSize?: boolean
  filledBackground: 'even' | 'odd'
  index: number
  item: T
  mainColumn?: PuiDataTableColumn
  onSelectRow?: (item: T) => void
  selectedRowId?: string
  withBorder?: boolean
}

type PuiDataTableRowComponent = <T extends { id: string }>(
  props: PuiDataTableRowProps<T>,
) => JSX.Element

const PuiDataTableRow: PuiDataTableRowComponent = ({
  classes: classesProp,
  columns,
  dynamicSize = false,
  filledBackground = 'even',
  index,
  item,
  mainColumn,
  minRowHeight,
  selectedRowId,
  onDelete,
  onDuplicate,
  onEdit,
  onPreview,
  onSelectRow,
  withBorder = false,
}) => {
  const classes = usePuiDataTableRowStyles({
    classes: classesProp,
    minRowHeight,
  } as UseStylesProps)

  const isLoading = R.isEmpty(item)
  const isEven = index % 2 === 0
  const fillBackground =
    (filledBackground === 'even' && isEven) ||
    (filledBackground === 'odd' && !isEven)

  const isRowSelected = selectedRowId ? selectedRowId === item.id : false

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.row, {
        [classes.filledBackground]: fillBackground,
        [classes.stretchRow]: dynamicSize,
        [classes.selectedRow]: isRowSelected,
        [classes.selectableRow]: Boolean(onSelectRow),
      })}
      wrap="nowrap"
      onClick={onSelectRow ? () => onSelectRow(item) : undefined}
    >
      {mainColumn && (
        <Grid
          container
          item
          alignItems="center"
          className={classNames(
            classes.cell,
            classes.mainColumn,
            mainColumn.classNameRow,
            {
              [classes.stretchCell]: dynamicSize,
              [classes.withBorder]: withBorder,
            },
          )}
          flexShrink={mainColumn.width ? 0 : 1}
          pl={2}
          style={{ width: mainColumn.width || DEFAULT_MAIN_COLUMN_WIDTH }}
        >
          <Grid item xs>
            {isLoading || mainColumn?.type === 'text' ? (
              <Dotdotdot clamp={2}>
                <TextInteractive isLoading={isLoading} variant="body2">
                  {mainColumn.value(item, isRowSelected)}
                </TextInteractive>
              </Dotdotdot>
            ) : (
              mainColumn.value(item, isRowSelected)
            )}
          </Grid>
          {!isLoading && (
            <EditItemButtonGroup
              item={item}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
              onEdit={onEdit}
              onPreview={onPreview}
            />
          )}
        </Grid>
      )}
      {columns.map(({ classNameRow, label, width, value, type }) => {
        const props = {
          className: classNames(classNameRow, classes.cell, {
            [classes.stretchCell]: dynamicSize,
            [classes.withBorder]: withBorder,
          }),
          style: width ? { width } : undefined,
          xs: width ? undefined : true,
        }

        return (
          <Grid
            container
            item
            alignItems="center"
            flexShrink={width ? 0 : 1}
            key={`row-${item.id}-${label}`}
            pl={2}
            {...props}
          >
            {isLoading || type === 'text' ? (
              <Dotdotdot clamp={2}>
                <TextInteractive isLoading={isLoading} variant="body2">
                  {value(item, isRowSelected)}
                </TextInteractive>
              </Dotdotdot>
            ) : (
              value(item, isRowSelected)
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default PuiDataTableRow
