import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  Nil,
  PermissionArea,
  PhoneUtils,
  User,
} from '@pbt/pbt-ui-components'

import { ConversationTransport, Type } from '~/api/graphql/generated/types'
import AddToAppointmentCard from '~/components/common/addToAppointment/AddToAppointmentCard'
import ClientCard from '~/components/common/ContactTargetSlot/ClientCard'
import ContactCard from '~/components/common/ContactTargetSlot/ContactCard'
import PatientCard from '~/components/common/ContactTargetSlot/PatientCard'
import WarningRowLabel from '~/components/common/labels/WarningRowLabel'
import RightRail, { RightRailProps } from '~/components/common/RightRail'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  editConversation,
  updateConversationRecipientContext,
} from '~/store/actions/conversations'
import { getContact } from '~/store/duck/contacts'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getFilesForMessageIds } from '~/store/reducers/conversationMessages'
import { getConversationById } from '~/store/reducers/conversations'
import { getUser } from '~/store/reducers/users'
import { Contact, ConversationRecipientContext } from '~/types'
import useDialog from '~/utils/useDialog'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import BusinessCard from './BusinessCard'
import DirectCommunicationCard from './DirectCommunicationCard'
import RightRailAttachments from './RightRailAttachments'
import SectionWithTitle from './SectionWithTitle'

const useStyles = makeStyles(
  (theme) => ({
    warningBlock: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    subSection: {
      padding: 0,
    },
    titleContainer: {
      padding: theme.spacing(2, 2, 0),
    },
    addClientPatient: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
  }),
  { name: 'ChatRightRail' },
)

const getRecipientChangedInfo = (
  recipientEmail: string | Nil,
  recipientPhone: string | Nil,
  savedEmail: string | Nil,
  savedPhone: string | Nil,
  transport: string | Nil,
  recipientTypeName: string | Nil,
  // eslint-disable-next-line max-params
) => {
  const isSms = transport === ConversationTransport.Sms
  const recipientCommunicationAddress = isSms ? savedPhone : savedEmail
  const contactCommunicationAddress = isSms ? recipientPhone : recipientEmail
  const communicationAddressTypeString = isSms
    ? i18n.t('Common:PHONE_NUMBER').toLowerCase()
    : i18n.t('Common:EMAIL').toLowerCase()

  const rawMessage = i18n
    .t(
      'Communications:CHAT_RIGHT_RAIL.CLIENT_OR_CONTACT_CHANGED_INFO_MESSAGE',
      {
        recipientTypeName,
        communicationAddressTypeString,
      },
    )
    .toLowerCase()

  const formattedMessage =
    rawMessage.charAt(0).toUpperCase() + rawMessage.slice(1)

  return {
    isChanged: recipientCommunicationAddress !== contactCommunicationAddress,
    message: formattedMessage,
  }
}

const getContactChangedInfo = (
  contact: Contact | Nil,
  transport: ConversationTransport | Nil,
  recipientEmail: string | Nil,
  recipientPhoneNumber: string | Nil,
) =>
  getRecipientChangedInfo(
    contact?.email,
    contact?.phone,
    recipientEmail,
    recipientPhoneNumber,
    transport,
    i18n.t('Common:CONTACT_ONE'),
  )

const getClientChangedInfo = (
  client: User | Nil,
  transport: string | Nil,
  recipientEmail: string | Nil,
  recipientPhoneNumber: string | Nil,
) =>
  getRecipientChangedInfo(
    client?.email,
    client?.mobilePhone,
    recipientEmail,
    recipientPhoneNumber,
    transport,
    i18n.t('Common:CLIENT'),
  )

interface ChatRightRailProps extends RightRailProps {
  conversationId: string
}

const ChatRightRail = ({ conversationId, ...rest }: ChatRightRailProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const conversation = useSelector(getConversationById(conversationId))
  const files = useSelector(getFilesForMessageIds(conversation?.messages || []))
  const { t } = useTranslation(['Common', 'Communications'])

  const eventAppointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const isContextItem = useIsCurrentContextItem(conversation)

  const recipient = conversation?.recipient
  const transport = conversation?.transport
  const clientId = conversation?.client
  const patientId = conversation?.patient
  const contactId = conversation?.contact

  const {
    email: recipientEmail,
    type: recipientType = Type.Direct,
    phoneNumber: recipientPhoneNumber,
  } = recipient || {}

  const client = useSelector(getUser(clientId))
  const contact = useSelector(getContact(contactId))

  const isLoading = !transport
  const coParents = client?.coparents

  const contactChangedInfo = getContactChangedInfo(
    contact,
    transport,
    recipientEmail,
    recipientPhoneNumber,
  )
  const clientChangedInfo = getClientChangedInfo(
    client,
    transport,
    recipientEmail,
    recipientPhoneNumber,
  )

  const isEmailOrSmsTransport =
    transport &&
    [ConversationTransport.Sms, ConversationTransport.Email].includes(transport)
  const isNotClientRecipientType = !isLoading && recipientType !== Type.Client
  const hasClientPatient = Boolean(clientId || patientId)
  const clientPatientInfoAsRegardingSection =
    hasClientPatient && isNotClientRecipientType

  const [openAddClientPatientToConversationDialog] = useDialog(
    DialogNames.ADD_CLIENT_PATIENT_TO_CONVERSATION,
  )
  const [openAddToAppointmentDialog, closeAddToAppointmentDialog] = useDialog(
    DialogNames.ADD_TO_APPOINTMENT,
  )

  const updateRecipientContext = (
    recipientContext: ConversationRecipientContext,
  ) => {
    dispatch(
      updateConversationRecipientContext(conversationId, recipientContext),
    )
  }

  const handleRemoveClient = () => updateRecipientContext({ clientId: null })
  const handleRemovePatient = () => updateRecipientContext({ patientId: null })

  const onAppointmentSelected = (appointmentId: string) => {
    closeAddToAppointmentDialog()
    dispatch(editConversation(conversationId, { eventId: appointmentId }))
  }

  const onAddToAppointmentRequested = () => {
    openAddToAppointmentDialog({
      clientId,
      patientId,
      onAdd: onAppointmentSelected,
    })
  }

  return (
    <RightRail {...rest}>
      <Grid container direction="column" ref={rootRef}>
        {recipientType === Type.Contact && contactId && (
          <Grid item mx={2} my={0.5}>
            <Grid item>
              <ContactCard contactId={contactId} />
            </Grid>
            {isEmailOrSmsTransport && contactChangedInfo.isChanged && (
              <Grid item>
                <WarningRowLabel
                  className={classes.warningBlock}
                  message={contactChangedInfo.message}
                />
              </Grid>
            )}
          </Grid>
        )}

        {recipientType === Type.Business && recipient?.businessId && (
          <Grid item mx={2} my={0.5}>
            <BusinessCard businessId={recipient.businessId} />
          </Grid>
        )}

        {recipientType === Type.Direct && (
          <Grid item mx={2} my={0.5}>
            <DirectCommunicationCard
              recipientEmail={recipientEmail}
              recipientPhoneNumber={PhoneUtils.formatPhoneNumber(
                recipientPhoneNumber,
              )}
            />
          </Grid>
        )}

        {hasClientPatient && (
          <SectionWithTitle
            classes={{
              root: classes.subSection,
              titleContainer: classNames({
                [classes.titleContainer]: clientPatientInfoAsRegardingSection,
              }),
            }}
            showTitle={clientPatientInfoAsRegardingSection}
            showTopSeparator={clientPatientInfoAsRegardingSection}
            title={t('Communications:CHAT_RIGHT_RAIL.REGARDING')}
          >
            {patientId && (
              <Grid item mx={2} my={0.5}>
                <PatientCard
                  clientId={clientId}
                  patientId={patientId}
                  onRemove={
                    clientPatientInfoAsRegardingSection && isContextItem
                      ? handleRemovePatient
                      : undefined
                  }
                />
              </Grid>
            )}

            {clientId && (
              <Grid item mx={2} my={0.5}>
                <Grid item>
                  <ClientCard
                    clientId={clientId}
                    title={patientId && t('Common:PET_PARENT')}
                    onRemove={
                      clientPatientInfoAsRegardingSection && isContextItem
                        ? handleRemoveClient
                        : undefined
                    }
                  />
                </Grid>
                {recipientType === Type.Client &&
                  isEmailOrSmsTransport &&
                  clientChangedInfo.isChanged && (
                    <Grid item>
                      <WarningRowLabel
                        className={classes.warningBlock}
                        message={clientChangedInfo.message}
                      />
                    </Grid>
                  )}
              </Grid>
            )}

            {patientId &&
              coParents &&
              coParents.map((id) => (
                <Grid item key={id} mx={2} my={0.5}>
                  <ClientCard
                    clientId={id}
                    showPreferencesChip={false}
                    title={t('Common:CO-PET_PARENT')}
                  />
                </Grid>
              ))}
          </SectionWithTitle>
        )}

        {!hasClientPatient && !isLoading && isContextItem && (
          <Grid className={classes.addClientPatient} mx={2} my={1} p={1}>
            <AddButton
              addText={t('Common:ADD_CLIENT_AND_PATIENT')}
              onAdd={() =>
                openAddClientPatientToConversationDialog({ conversationId })
              }
            />
          </Grid>
        )}

        {clientId && patientId && (
          <Grid mt={2} p={0} px={2}>
            <AddToAppointmentCard
              appointmentProp={conversation.event}
              readOnly={
                !isContextItem ||
                (isCvcRolesEnabled && !eventAppointmentPermissions.read)
              }
              onAddToAppointmentRequested={onAddToAppointmentRequested}
              onEdit={onAddToAppointmentRequested}
            />
          </Grid>
        )}

        {!R.isEmpty(files) && (
          <SectionWithTitle title={t('Common:ATTACHMENTS')}>
            <RightRailAttachments
              conversationId={conversationId}
              files={files}
            />
          </SectionWithTitle>
        )}
      </Grid>
    </RightRail>
  )
}

export default ChatRightRail
