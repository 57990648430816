import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import DialogNames from '~/constants/DialogNames'
import { clearOrderValidationErrorMessage } from '~/store/actions/orders'
import { fetchSOAPOrderFilters } from '~/store/actions/soap'
import {
  getOrderValidationErrorMessage,
  getUnassignedDoctorOrderError,
} from '~/store/reducers/orders'
import { getAppointmentId } from '~/store/reducers/soap'
import useDialog from '~/utils/useDialog'

export const useSoapOrderValidationErrorAlert = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const unassignedDoctorError = useSelector(getUnassignedDoctorOrderError)
  const orderValidationErrorMessage = useSelector(
    getOrderValidationErrorMessage,
  )
  const appointmentId = useSelector(getAppointmentId)

  const originalBusinessId = searchParams.get('originalBusinessId')

  const handleClose = () => {
    dispatch(clearOrderValidationErrorMessage())
    if (appointmentId) {
      fetchSOAPOrderFilters(appointmentId, originalBusinessId)
    }
  }

  const [opeSoapOrderValidationErrorDialog] = useDialog(
    DialogNames.SOAP_ORDER_VALIDATION_ERROR_ALERT,
    handleClose,
  )

  useEffect(() => {
    if (Boolean(orderValidationErrorMessage) && !unassignedDoctorError) {
      opeSoapOrderValidationErrorDialog({
        message: orderValidationErrorMessage,
      })
    }
  }, [orderValidationErrorMessage, unassignedDoctorError])
}
