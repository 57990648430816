import * as R from 'ramda'
import { AnyAction } from 'redux'

import type { RootState } from '../index'

export const START_LOADING = 'customLoading/START_LOADING'
export const STOP_LOADING = 'customLoading/STOP_LOADING'
export const DROP_ALL_LOADING = 'customLoading/DROP_ALL_LOADING'

export const startCustomLoading = (loadId: string) => ({
  type: START_LOADING,
  loadId,
})
export const stopCustomLoading = (loadId: string) => ({
  type: STOP_LOADING,
  loadId,
})
export const dropAllCustomLoadings = () => ({ type: DROP_ALL_LOADING })

export type CustomLoadingState = {
  activeLoadings: Record<string, boolean>
}

export const INITIAL_STATE: CustomLoadingState = {
  activeLoadings: {},
}

export const customLoadingReducer = (
  state: CustomLoadingState = INITIAL_STATE,
  action: AnyAction,
): CustomLoadingState => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        activeLoadings: {
          ...state.activeLoadings,
          [action.loadId]: true,
        },
      }
    case STOP_LOADING:
      return {
        ...state,
        activeLoadings: R.omit([action.loadId], state.activeLoadings),
      }
    case DROP_ALL_LOADING:
      return {
        ...state,
        activeLoadings: {},
      }
    default:
      return state
  }
}

export const getCustomLoading = (state: RootState): CustomLoadingState =>
  state.customLoading
export const getActiveLoadings = (state: RootState) =>
  getCustomLoading(state).activeLoadings
export const getIsLoading = R.curry((id, state) =>
  Boolean(R.prop(id, getActiveLoadings(state))),
)
export const getIsAnyLoading = R.curry((ids, state) =>
  R.any(Boolean, R.props(ids || [], getActiveLoadings(state))),
)
