import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Utils } from '@pbt/pbt-ui-components'

import { getStaticSoapWidget } from '~/store/reducers/constants'
import { getSoapUncoveredWidgetIds } from '~/store/reducers/soapV2'
import { SoapTemplateTab, SoapWidgetName } from '~/types'

export enum SoapV1StepsUrl {
  APPOINTMENT = 'appointment',
  ORDER = 'order',
  WRAP_UP = 'wrap_up',
  PROBLEMS = 'problems',
  QUESTIONS = 'questions',
  TO_DO = 'to_do',
  VITALS = 'vitals',
  MARKETPLACE = 'marketplace',
}

export const getFirstTabOccurrenceUrl = ({
  tabs,
  uncoveredWidgetIds,
  widgetId,
}: {
  tabs: SoapTemplateTab[] | undefined
  uncoveredWidgetIds: string[] | undefined
  widgetId: string
}) =>
  uncoveredWidgetIds?.includes(widgetId)
    ? 'uncovered-content'
    : tabs?.find((tab) =>
        tab.widgets.find((widget) => widget.soapWidgetId === widgetId),
      )?.url

const useGetSoapWidgetId = () => {
  const StaticSoapWidget = useSelector(getStaticSoapWidget)

  const attachmentsWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.ATTACHMENTS_WIDGET,
    StaticSoapWidget,
  )
  const chargesWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.CHARGES_WIDGET,
    StaticSoapWidget,
  )
  const estimatesWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.ESTIMATE_WIDGET,
    StaticSoapWidget,
  )
  const notesWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.NOTES_WIDGET,
    StaticSoapWidget,
  )
  const soapProblemsWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.PROBLEMS_WIDGET,
    StaticSoapWidget,
  )
  const questionsWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.QUESTIONS_WIDGET,
    StaticSoapWidget,
  )
  const reasonForVisitWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.REASON_FOR_VISIT_WIDGET,
    StaticSoapWidget,
  )
  const remindersWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.REMINDERS_WIDGET,
    StaticSoapWidget,
  )
  const toDoWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.TODO_WIDGET,
    StaticSoapWidget,
  )
  const soapVitalsWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.VITALS_WIDGET,
    StaticSoapWidget,
  )
  const marketplaceWidgetId = Utils.findConstantIdByName(
    SoapWidgetName.MARKETPLACE_WIDGET,
    StaticSoapWidget,
  )

  return {
    attachmentsWidgetId,
    chargesWidgetId,
    estimatesWidgetId,
    notesWidgetId,
    soapProblemsWidgetId,
    questionsWidgetId,
    reasonForVisitWidgetId,
    remindersWidgetId,
    toDoWidgetId,
    soapVitalsWidgetId,
    marketplaceWidgetId,
  }
}

const useGetSoapFullUrlTab = () => {
  const { soapId: urlSoapId } = useParams()
  const { search } = useLocation()

  const defaultUrl = `/soap/${urlSoapId}`

  return {
    getSoapFullUrlTab: (tab: string | undefined) =>
      tab ? `${defaultUrl}/tab/${tab}${search}` : `${defaultUrl}${search}`,
    defaultUrl,
  }
}

export const useSoapPageRedirect = (tabs: SoapTemplateTab[] | undefined) => {
  const uncoveredWidgetIds = useSelector(getSoapUncoveredWidgetIds)

  const { getSoapFullUrlTab, defaultUrl } = useGetSoapFullUrlTab()
  const {
    attachmentsWidgetId,
    chargesWidgetId,
    estimatesWidgetId,
    notesWidgetId,
    soapProblemsWidgetId,
    questionsWidgetId,
    reasonForVisitWidgetId,
    remindersWidgetId,
    toDoWidgetId,
    soapVitalsWidgetId,
    marketplaceWidgetId,
  } = useGetSoapWidgetId()

  const getAppointment = () =>
    getFirstTabOccurrenceUrl({
      uncoveredWidgetIds,
      widgetId: attachmentsWidgetId,
      tabs,
    }) ||
    getFirstTabOccurrenceUrl({
      uncoveredWidgetIds,
      widgetId: estimatesWidgetId,
      tabs,
    }) ||
    getFirstTabOccurrenceUrl({
      uncoveredWidgetIds,
      widgetId: reasonForVisitWidgetId,
      tabs,
    }) ||
    getFirstTabOccurrenceUrl({
      uncoveredWidgetIds,
      widgetId: remindersWidgetId,
      tabs,
    })

  const RedirectMap: Record<SoapV1StepsUrl, string> = {
    [SoapV1StepsUrl.APPOINTMENT]: getSoapFullUrlTab(getAppointment()),
    [SoapV1StepsUrl.ORDER]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: chargesWidgetId,
        tabs,
      }),
    ),
    [SoapV1StepsUrl.WRAP_UP]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: notesWidgetId,
        tabs,
      }),
    ),
    [SoapV1StepsUrl.PROBLEMS]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: soapProblemsWidgetId,
        tabs,
      }),
    ),
    [SoapV1StepsUrl.QUESTIONS]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: questionsWidgetId,
        tabs,
      }),
    ),
    [SoapV1StepsUrl.TO_DO]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: toDoWidgetId,
        tabs,
      }),
    ),
    [SoapV1StepsUrl.VITALS]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: soapVitalsWidgetId,
        tabs,
      }),
    ),
    [SoapV1StepsUrl.MARKETPLACE]: getSoapFullUrlTab(
      getFirstTabOccurrenceUrl({
        uncoveredWidgetIds,
        widgetId: marketplaceWidgetId,
        tabs,
      }),
    ),
  }

  // There could be a few use cases where the clinic didn't set one of the widgets so we need this fallback
  return tabs ? (step: SoapV1StepsUrl) => RedirectMap[step] ?? defaultUrl : null
}
