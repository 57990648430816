import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { useCloseDialogsCallback } from '~/store/hooks/dialogs'

import MissingCIFNumber from './MissingCIFNumber'
import MissingNIFNumber from './MissingNIFNumber'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 385,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'InvoiceMissingFieldsDialog' },
)

export enum InvoiceFromSpainMissingFields {
  BUSINESS_CIF_NUMBER = 'BUSINESS_CIF_NUMBER',
  CLIENT_NIF_OR_CIF_NUMBER = 'CLIENT_NIF_OR_CIF_NUMBER',
}

export interface InvoiceMissingFieldsDialogProps extends BasePuiDialogProps {
  clientId?: string | Nil
  missingFields: InvoiceFromSpainMissingFields[]
}

const MissingFieldsMap = {
  [InvoiceFromSpainMissingFields.BUSINESS_CIF_NUMBER]: MissingCIFNumber,
  [InvoiceFromSpainMissingFields.CLIENT_NIF_OR_CIF_NUMBER]: MissingNIFNumber,
}

const InvoiceMissingFieldsDialog = ({
  clientId,
  open,
  onClose,
  missingFields,
}: InvoiceMissingFieldsDialogProps) => {
  const classes = useStyles()

  // This is needed as this InvoiceMissingFieldsDialog can be opened by InvoiceDialog in other instances (e.g.: Invoice Preview)
  const closeInvoiceDialogs = useCloseDialogsCallback(DialogNames.INVOICE)
  const closeBatchInvoiceDialogs = useCloseDialogsCallback(
    DialogNames.BATCH_INVOICE,
  )

  const handleClose = () => {
    if (onClose) {
      onClose()
    }

    if (closeInvoiceDialogs) {
      closeInvoiceDialogs()
    }

    if (closeBatchInvoiceDialogs) {
      closeBatchInvoiceDialogs()
    }
  }

  return (
    <PuiDialog
      aria-labelledby="invoice-missing-fields-dialog"
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        className={classes.paper}
        m={0}
        p={3}
        pt={6}
        rowSpacing={2}
      >
        {missingFields.map((field) => {
          const Component = MissingFieldsMap[field]

          if (!Component) {
            return null
          }

          return (
            <Grid container item justifyContent="center" key={field}>
              <Component clientId={clientId} handleClose={handleClose} />
            </Grid>
          )
        })}
      </Grid>
    </PuiDialog>
  )
}

export default InvoiceMissingFieldsDialog
