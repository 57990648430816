import { all, call, put, takeEvery } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import { Vital } from '~/types'

import { fetchClient } from '../actions/clients'
import { addSoapVitalIds, addV2SoapVitalId } from '../actions/soap'
import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import { fetchWidgetsData } from '../duck/landing'
import {
  ADD_VITAL,
  addVital,
  addVitalFailure,
  addVitalSuccess,
  DELETE_VITAL,
  deleteVital,
  deleteVitalFailure,
  deleteVitalSuccess,
  EDIT_VITAL,
  editVital,
  editVitalFailure,
  editVitalSuccess,
  FETCH_VITALS,
  fetchMoreVitals,
  fetchMoreVitalsFailure,
  fetchMoreVitalsSuccess,
  updateVitals,
} from '../duck/vitals'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'
import { vitalInputDTO } from './utils/vitals'

export function* fetchMoreVitalsSaga({
  patientId,
  includeDeleted,
  from,
  to,
}: ReturnType<typeof fetchMoreVitals>) {
  try {
    const {
      result: {
        vitalsPage: { data: list, totalCount },
      },
      entities,
    } = yield* requestAPI(API.fetchVitals, patientId, includeDeleted, from, to)
    yield call(updateEntities, entities)
    yield put(addSoapVitalIds(list))
    yield put(fetchMoreVitalsSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchMoreVitalsFailure(error as ApiError))
  }
}

export function* addVitalSaga({
  vital,
  soapId,
  clientId,
  patientId,
}: ReturnType<typeof addVital>) {
  try {
    const fullVital = vitalInputDTO(true, vital, soapId, patientId) as Vital
    const { result: addedVitalId, entities } = yield* requestAPI(
      API.addAndMergeVital,
      soapId,
      patientId,
      fullVital,
    )
    yield call(updateEntities, entities)
    yield put(fetchTimelineAction())
    if (soapId) {
      yield put(addV2SoapVitalId(addedVitalId))
    } else {
      yield put(
        fetchWidgetsData([SnapshotsAliasTypes.Vitals], {
          quiet: false,
          landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
          patientId,
        }),
      )
    }
    // if vitals is weight we need to re-fetch patient to get updated weight measurement
    if (vital.weight && clientId && patientId) {
      yield put(fetchClient({ clientId, patientId }))
    }
    yield put(addVitalSuccess(addedVitalId))
  } catch (error) {
    yield put(addVitalFailure(error as ApiError, vital))
  }
}

export function* editVitalSaga({ vital }: ReturnType<typeof editVital>) {
  try {
    const fullVital = vitalInputDTO(false, vital) as Vital
    const {
      result,
      entities: { vitals },
    } = yield* requestAPI(API.editVital, vital.id, fullVital)
    yield put(updateVitals(vitals))
    yield put(editVitalSuccess(result))
  } catch (error) {
    yield put(editVitalFailure(error as ApiError))
  }
}

export function* deleteVitalSaga({ vitalId }: ReturnType<typeof deleteVital>) {
  try {
    yield* requestAPI(API.deleteVital, vitalId)
    yield put(deleteVitalSuccess(vitalId))
  } catch (error) {
    yield put(deleteVitalFailure(error as ApiError))
  }
}

function* watchFetchMoreVitals() {
  yield takeEvery(FETCH_VITALS, fetchMoreVitalsSaga)
}

function* watchAddVital() {
  yield takeEvery(ADD_VITAL, addVitalSaga)
}

function* watchEditVital() {
  yield takeEvery(EDIT_VITAL, editVitalSaga)
}

function* watchDeleteVital() {
  yield takeEvery(DELETE_VITAL, deleteVitalSaga)
}

export function* vitalsSaga() {
  yield all([
    watchFetchMoreVitals(),
    watchAddVital(),
    watchEditVital(),
    watchDeleteVital(),
  ])
}
