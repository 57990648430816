import { ApiError } from '@pbt/pbt-ui-components'

import { AtLeast, PartNumber, Variation } from '~/types'

import {
  CLEAR_VARIATION_CALCULATED_COST,
  CLEAR_VARIATION_PART_NUMBER_DETAILS,
  CREATE_VARIATION,
  CREATE_VARIATION_FAILURE,
  CREATE_VARIATION_SUCCESS,
  CREATE_VARIATIONS,
  CREATE_VARIATIONS_FAILURE,
  CREATE_VARIATIONS_SUCCESS,
  DELETE_VARIATION,
  DELETE_VARIATION_FAILURE,
  DELETE_VARIATION_SUCCESS,
  FETCH_VARIATION_CALCULATED_COST,
  FETCH_VARIATION_CALCULATED_COST_FAILURE,
  FETCH_VARIATION_CALCULATED_COST_SUCCESS,
  FETCH_VARIATION_PART_NUMBER_DETAILS,
  FETCH_VARIATION_PART_NUMBER_DETAILS_FAILURE,
  FETCH_VARIATION_PART_NUMBER_DETAILS_SUCCESS,
  PARTIAL_UPDATE_VARIATION,
  UPDATE_VARIATION,
  UPDATE_VARIATION_FAILURE,
  UPDATE_VARIATION_SUCCESS,
} from './types/variations'

export const createVariation = (inventoryId: string, variation: Variation) => ({
  type: CREATE_VARIATION,
  inventoryId,
  variation,
})
export const createVariationSuccess = (
  inventoryId: string,
  variation: Variation,
) => ({
  type: CREATE_VARIATION_SUCCESS,
  inventoryId,
  variation,
})
export const createVariationFailure = (error: ApiError) => ({
  type: CREATE_VARIATION_FAILURE,
  error,
})
export const createVariations = (
  inventoryId: string,
  variations: Variation[],
) => ({
  type: CREATE_VARIATIONS,
  inventoryId,
  variations,
})
export const createVariationsSuccess = (
  inventoryId: string,
  variations: Variation[],
) => ({
  type: CREATE_VARIATIONS_SUCCESS,
  inventoryId,
  variations,
})
export const createVariationsFailure = (error: ApiError) => ({
  type: CREATE_VARIATIONS_FAILURE,
  error,
})
export const updateVariation = (
  inventoryId: string,
  variation: AtLeast<Variation, 'id'>,
) => ({
  type: UPDATE_VARIATION,
  inventoryId,
  variation,
})
export const updateVariationSuccess = (
  inventoryId: string,
  variation: Variation,
) => ({
  type: UPDATE_VARIATION_SUCCESS,
  inventoryId,
  variation,
})
export const updateVariationFailure = (error: ApiError) => ({
  type: UPDATE_VARIATION_FAILURE,
  error,
})
export const partialUpdateVariation = (
  inventoryId: string,
  variation: AtLeast<Variation, 'id'>,
) => ({
  type: PARTIAL_UPDATE_VARIATION,
  inventoryId,
  variation,
})
export const deleteVariation = (inventoryId: string, variationId: string) => ({
  type: DELETE_VARIATION,
  inventoryId,
  variationId,
})
export const deleteVariationSuccess = (
  inventoryId: string,
  variationId: string,
) => ({
  type: DELETE_VARIATION_SUCCESS,
  inventoryId,
  variationId,
})
export const deleteVariationFailure = (error: ApiError) => ({
  type: DELETE_VARIATION_FAILURE,
  error,
})

export const fetchVariationCalculatedCost = (
  inventoryId: string,
  variationId: string,
) => ({
  type: FETCH_VARIATION_CALCULATED_COST,
  inventoryId,
  variationId,
})
export const fetchVariationCalculatedCostSuccess = (
  calculatedCostsMap: Record<string, Record<string, number | null>>,
) => ({ type: FETCH_VARIATION_CALCULATED_COST_SUCCESS, calculatedCostsMap })
export const fetchVariationCalculatedCostFailure = (error: ApiError) => ({
  type: FETCH_VARIATION_CALCULATED_COST_FAILURE,
  error,
})
export const clearVariationCalculatedCost = () => ({
  type: CLEAR_VARIATION_CALCULATED_COST,
})

export const fetchVariationPartNumberDetails = (globalVariationId: string) => ({
  type: FETCH_VARIATION_PART_NUMBER_DETAILS,
  globalVariationId,
})
export const fetchVariationPartNumberDetailsSuccess = (
  partNumberDetails: PartNumber[],
) => ({ type: FETCH_VARIATION_PART_NUMBER_DETAILS_SUCCESS, partNumberDetails })
export const fetchVariationPartNumberDetailsFailure = (error: ApiError) => ({
  type: FETCH_VARIATION_PART_NUMBER_DETAILS_FAILURE,
  error,
})
export const clearVariationPartNumberDetails = () => ({
  type: CLEAR_VARIATION_PART_NUMBER_DETAILS,
})
