import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { FieldProp, PermissionArea, useFields } from '@pbt/pbt-ui-components'

import WarningRowLabel from '~/components/common/labels/WarningRowLabel'
import Link from '~/components/common/link/Link'
import { getHighValue } from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { getChargesLoading } from '~/store/duck/charges'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getOrderDetailsGrouped } from '~/store/reducers/orders'
import { ChargeData, DataHandle, Prescription, Variation } from '~/types'
import { useGetAreChargesPostedAndEditable } from '~/utils/finance'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import ChargedPriceRadioGroup from './ChargedPriceRadioGroup'
import ChargedPriceRow from './ChargedPriceRow'

export interface ChargeSectionProps {
  onRefChange?: () => void
  prescription: Prescription
  readonly?: boolean
}

export interface ChargeSectionHandle extends DataHandle<ChargeData> {}

const ChargeSection = forwardRef<ChargeSectionHandle, ChargeSectionProps>(
  function ChargeSection({ onRefChange, prescription, readonly = false }, ref) {
    const { t } = useTranslation(['Common', 'Dialogs'])

    const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
    const chargesLoading = useSelector(getChargesLoading)
    const isEditPostedChargesEnabled = useSelector(
      getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
    )

    const areChargesPostedAndEditable = useGetAreChargesPostedAndEditable(
      prescription.type,
      prescription.id,
    )
    const isChargePostedAndCanBeEdit = Boolean(
      areChargesPostedAndEditable && prescription.id,
    )

    const getFields = (obj: Prescription): FieldProp[] => [
      {
        name: 'quantity',
        label: t('Common:QUANTITY'),
        validators: ['required', 'greaterThanZero'],
        initialValue: getHighValue(obj.quantity) || null,
      },
      {
        name: 'priceId',
        label: t('Common:PRICE'),
        validators: ['required'],
        initialValue: obj.price?.id || null,
      },
    ]

    const {
      fields: { quantity, priceId },
      validate,
      reset,
    } = useFields(getFields(prescription), false)

    useEffectExceptOnMount(() => {
      onRefChange?.()
    }, [quantity, priceId])

    useEffectExceptOnMount(() => {
      reset(getFields(prescription))
    }, [
      prescription.price?.id,
      prescription?.variation?.id,
      prescription?.inventory?.id,
    ])

    const variations = useSelector(getOrderDetailsGrouped)
    const prices =
      variations.find(
        ({ variation }) => variation?.id === prescription?.variation?.id,
      )?.prices || []

    useImperativeHandle(ref, () => ({
      validate,
      get: () => ({
        quantity: quantity.value && Number(quantity.value),
        priceId: priceId.value,
        price: prices.find(({ id }) => id === priceId.value),
      }),
    }))

    const variation = prescription?.variation as Variation
    const inventoryId = prescription?.inventory?.id

    useEffect(() => {
      // if there is no chosen price or it is not in the list of available prices, change it
      if (
        prices.length > 0 &&
        (!priceId.value ||
          prices.findIndex(({ id }) => id === priceId.value) === -1)
      ) {
        priceId.setValue(prices[0].id)
      }
    }, [prices])

    return (
      <Grid container py={1.5}>
        {prices.length === 0 ? (
          variations.length > 0 && (
            <WarningRowLabel
              message={
                <>
                  {`${t(
                    'Dialogs:PRESCRIPTION_DIALOG.CHARGE_SECTION.BEFORE_LINK',
                  )}`}
                  &nbsp;
                  <Link
                    to={`/admin/catalog/inventories/catalog/${inventoryId}`}
                  >
                    {t('Dialogs:PRESCRIPTION_DIALOG.CHARGE_SECTION.LINK')}
                  </Link>
                  &nbsp;
                  {`${t(
                    'Dialogs:PRESCRIPTION_DIALOG.CHARGE_SECTION.AFTER_LINK',
                  )}`}
                </>
              }
            />
          )
        ) : (
          <>
            {prices.length === 1 ? (
              <ChargedPriceRow
                isChosen
                disabled={
                  !permissions.update ||
                  readonly ||
                  isChargePostedAndCanBeEdit ||
                  (isEditPostedChargesEnabled && chargesLoading)
                }
                field={quantity}
                isLoading={isEditPostedChargesEnabled && chargesLoading}
                price={prices[0]}
                variation={variation}
              />
            ) : (
              <ChargedPriceRadioGroup
                disabled={
                  !permissions.update ||
                  readonly ||
                  isChargePostedAndCanBeEdit ||
                  (isEditPostedChargesEnabled && chargesLoading)
                }
                isLoading={isEditPostedChargesEnabled && chargesLoading}
                priceId={priceId.value}
                prices={prices}
                quantityField={quantity}
                variation={variation}
                onPriceIdChange={priceId.setValue}
              />
            )}
            {prescription?.price &&
              !prices.some(R.propEq('id', prescription.price.id)) && (
                <Grid item mt={1}>
                  <WarningRowLabel
                    message={t(
                      'Dialogs:PRESCRIPTION_DIALOG.CHARGE_SECTION.PRICE_REMOVED_BY_ADMINISTRATOR',
                    )}
                  />
                </Grid>
              )}
          </>
        )}
      </Grid>
    )
  },
)

export default ChargeSection
