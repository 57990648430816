import {
  LocalizedTemperatureMap,
  LocalizedWeightMap,
  UnitTypes,
  UnitTypesLabelMap,
} from '@pbt/pbt-ui-components/src/localization'

import i18n from '~/locales/i18n'

export const LocalizedTemperature = LocalizedTemperatureMap[i18n.language]
export const LocalizedWeight = LocalizedWeightMap[i18n.language]

export enum Numeral {
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  DECIMAL_TWO_DIGITS = 'decimalTwoDigits',
}

export const DEFAULT_INT_LENGTH = 4
export const DEFAULT_FRACTION_LENGTH = 4

const isValidInteger =
  (intLength = DEFAULT_INT_LENGTH) =>
  (str: string) => {
    const number = Number(str)
    return Number.isInteger(number) && str.length <= intLength
  }

const isValidDecimal =
  (intLength = DEFAULT_INT_LENGTH, fractionLength = DEFAULT_FRACTION_LENGTH) =>
  (str: string) => {
    const [integerPart, fractionPart] = str.split('.')
    return (
      Number(str) &&
      (!integerPart || integerPart.length <= intLength) &&
      (!fractionPart || fractionPart.length <= fractionLength)
    )
  }

const decimalFormatter = (str: string) => {
  const output = str.split('.')
  return output.shift() + (output.length ? `.${output.join('')}` : '')
}

export const NumeralFormatterValidators = {
  [Numeral.INTEGER]: isValidInteger(DEFAULT_INT_LENGTH),
  [Numeral.DECIMAL_TWO_DIGITS]: isValidDecimal(DEFAULT_INT_LENGTH, 2),
  [Numeral.DECIMAL]: isValidDecimal(
    DEFAULT_INT_LENGTH,
    DEFAULT_FRACTION_LENGTH,
  ),
}

export const NumeralFormatters = {
  [Numeral.INTEGER]: Number,
  [Numeral.DECIMAL_TWO_DIGITS]: decimalFormatter,
  [Numeral.DECIMAL]: decimalFormatter,
}

export const UnitsNumerals: Partial<Record<UnitTypes, Numeral>> = {
  [UnitTypes.BEATS_PER_MINUTE]: Numeral.INTEGER,
  [UnitTypes.BREATHS_PER_MINUTE]: Numeral.INTEGER,
  [UnitTypes.PERCENT]: Numeral.DECIMAL_TWO_DIGITS,
  [UnitTypes.LITRES_PER_MINUTE]: Numeral.DECIMAL,
  [UnitTypes.MILLIMETRE_OF_MERCURY]: Numeral.DECIMAL,
  [UnitTypes.MUSCLE_MASS_SCORE]: Numeral.INTEGER,
}

export const UnitsWithLabels = [
  UnitTypesLabelMap[UnitTypes.BEATS_PER_MINUTE],
  UnitTypesLabelMap[UnitTypes.BREATHS_PER_MINUTE],
  UnitTypesLabelMap[UnitTypes.MILLIMETRE_OF_MERCURY],
  UnitTypesLabelMap[UnitTypes.PERCENT],
  UnitTypesLabelMap[UnitTypes.LITRES_PER_MINUTE],
]
