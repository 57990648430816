import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { getSlotId } from '~/components/dashboard/timetable/timetableUtils'
import {
  SchedulerColumnType,
  SpecialTimetableColumn,
  SpecialTimetableColumnNames,
} from '~/constants/schedulerConstants'
import { Schedule, WhiteboardSlot } from '~/types'

import { getScheduleColumnNames } from '../reducers/constants'
import {
  getTimetableEventsMap,
  getTimetableFilteredAppointmentTypes,
  getTimetableFilteredPersons,
} from '../reducers/timetable'

export const useFilteredSlots = () => {
  const appointmentsMap = useSelector(getTimetableEventsMap)
  const filteredAppointmentTypes = useSelector(
    getTimetableFilteredAppointmentTypes,
  )
  const filteredPersons = useSelector(getTimetableFilteredPersons)
  return (slots: WhiteboardSlot[]) =>
    slots.filter((slot) => {
      const isBusyTime = !slot.appointment && slot.busyTime
      const slotId = getSlotId(slot)
      const appointment = slotId ? appointmentsMap[slotId] : undefined

      if (!appointment) {
        return true
      }

      const matchesAppointmentType =
        filteredAppointmentTypes.indexOf(appointment.type?.id || '') === -1

      const hasPersonResponsibilities = Boolean(
        appointment.personResponsibilities,
      )

      const hasPersonId = hasPersonResponsibilities
        ? R.isEmpty(appointment.personResponsibilities) ||
          appointment.personResponsibilities?.some(
            (personResponsibility) =>
              !filteredPersons.includes(personResponsibility.personId),
          )
        : !appointment.personRoles ||
          appointment.personRoles.length === 0 ||
          appointment.personRoles.some(
            (personRole) => !filteredPersons.includes(personRole.personId),
          )

      const matchesPerson = isBusyTime
        ? !appointment.personId ||
          !filteredPersons.includes(appointment.personId)
        : hasPersonId

      return matchesPerson && matchesAppointmentType
    })
}

export const useGetScheduleColumnNameTranslated = () => {
  const ScheduleColumnNames = useSelector(getScheduleColumnNames)

  return (column: Schedule) => {
    const isUnassigned = column.columnType === SchedulerColumnType.UNASSIGNED

    if (isUnassigned) {
      return SpecialTimetableColumnNames[SpecialTimetableColumn.UNASSIGNED]
    }

    return column.columnNameId
      ? LanguageUtils.getConstantTranslatedName(
          column.columnNameId,
          ScheduleColumnNames,
        )
      : column.personId
  }
}

export const useGetScheduleColumnId = () => {
  const ScheduleColumnNames = useSelector(getScheduleColumnNames)

  return (column: Schedule) => {
    const isUnassigned = column.columnType === SchedulerColumnType.UNASSIGNED

    if (isUnassigned) {
      return SpecialTimetableColumn.UNASSIGNED
    }

    return column.columnNameId
      ? Utils.getConstantName(column.columnNameId, ScheduleColumnNames)
      : column.personId
  }
}
