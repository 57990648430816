import { useSelector } from 'react-redux'

import { getVariationCalculatedCostsMap } from '../reducers/variations'

export const useGetAutoCalculatedPrice = ({
  costTypeId,
  unitId,
  priceUnitsSize,
}: {
  costTypeId: string
  priceUnitsSize: number
  unitId: string
}) => {
  const calculatedCostsMap = useSelector(getVariationCalculatedCostsMap)

  const [priceUnitId, priceUnitAmountMarker] = unitId?.split('_') || []
  const currentUnitCost = calculatedCostsMap[costTypeId]?.[priceUnitId] || 0
  const isPriceUnitMultiple = priceUnitAmountMarker === 'X'
  const currentUnitSize = isPriceUnitMultiple ? priceUnitsSize || 0 : 1

  return currentUnitCost * currentUnitSize
}
