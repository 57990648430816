import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import * as R from 'ramda'
import { Icons, Nil } from '@pbt/pbt-ui-components'
import {
  Boop as BoopIcon,
  Wellness as WellnessIcon,
} from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessHasOpenBoop } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'

import {
  getAllPatientPlansCancelled,
  getPatientHasNoMembership,
  getPatientHasPausedPlan,
  // @ts-ignore
} from '../../wellness-plans/wellnessPlanUtils'

export interface PatientMembershipIconProps {
  className: string
  hideIfNoMembership?: boolean
  isLoading?: boolean
  patientId: string | Nil
}

const PatientMembershipIcon = ({
  hideIfNoMembership,
  className,
  patientId,
  isLoading,
}: PatientMembershipIconProps) => {
  const patient = useSelector(getPatient(patientId))
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const hasNoMembership = getPatientHasNoMembership(patient)

  if (isLoading) {
    return (
      <Skeleton
        className={className}
        height={24}
        variant="circular"
        width={24}
      />
    )
  }

  if (hideIfNoMembership && hasNoMembership) {
    return null
  }

  const hasAllCancelledIconVariant = isBoopDisablementEnabled
    ? Icons.IconVariant.SUSPENSED
    : Icons.IconVariant.DISABLED

  const hasPausedIconVariant = isBoopDisablementEnabled
    ? Icons.IconVariant.SUSPENSED
    : Icons.IconVariant.WARNING

  const hasNoMembershipIconVariant = isBoopDisablementEnabled
    ? Icons.IconVariant.INACTIVE
    : Icons.IconVariant.SECONDARY

  const getIconVariant = R.cond([
    [getAllPatientPlansCancelled, R.always(hasAllCancelledIconVariant)],
    [getPatientHasPausedPlan, R.always(hasPausedIconVariant)],
    [getPatientHasNoMembership, R.always(hasNoMembershipIconVariant)],
    [R.T, R.always(Icons.IconVariant.PRIMARY)],
  ])

  const variant = getIconVariant(patient)
  const IconComponent =
    (openBoop && variant === 'primary') || isBoopDisablementEnabled
      ? WellnessIcon
      : BoopIcon

  return <IconComponent className={className} variant={variant} />
}

export default PatientMembershipIcon
