import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Link, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateUtils,
  StateLabel,
  TextInteractive,
  WellnessPlans,
} from '@pbt/pbt-ui-components'
import { Eye as EyeIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { Membership, MembershipPlan } from '~/types'
import { useEditPaymentInfo } from '~/utils/membershipPaymentUtils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    stateLabel: {
      marginRight: theme.spacing(0.5),
    },
    iconButton: {
      color: theme.colors.editIconColor,
      padding: theme.spacing(0.75),
    },
  }),
  { name: 'PaymentDueCell' },
)

interface PaymentDueCellProps {
  membership: Partial<Membership>
  plan: MembershipPlan
}

const PaymentDueCell = ({ membership, plan }: PaymentDueCellProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Memberships'])

  const isMedium = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))

  const [openCancelMembershipDetailsDialog] = useDialog(
    DialogNames.CANCEL_MEMBERSHIP_DETAILS,
  )

  const { canUpdate, onEditPaymentInfo } = useEditPaymentInfo({
    clientId: membership.clientId,
    patientId: membership.patient,
    withBillingAddress: false,
  })

  const onViewCancelMembershipDialog = (cancelledPlan: MembershipPlan) => {
    openCancelMembershipDetailsDialog({ plan: cancelledPlan })
  }

  const isMembershipPaused =
    plan.state === WellnessPlans.MembershipStatus.PAUSED
  const isCancelled = plan.alreadyCancelled

  return isMembershipPaused ? (
    <>
      <StateLabel
        warning
        className={classes.stateLabel}
        display="inline"
        variant="small"
      >
        {t('Memberships:PAYMENT_DUE_CELL.PAUSED')}
      </StateLabel>
      {canUpdate && (
        <Link
          color="inherit"
          component="button"
          underline="always"
          variant="body1"
          onClick={() => onEditPaymentInfo(plan)}
        >
          {t('Memberships:PAYMENT_DUE_CELL.EDIT_PAYMENT')}
        </Link>
      )}
    </>
  ) : isCancelled ? (
    <Grid container alignItems="center" wrap="nowrap">
      <StateLabel
        disabled
        className={classes.stateLabel}
        display="inline"
        variant="small"
      >
        {`${t('Common:CANCELLED')} ${DateUtils.formatDate(
          plan?.cancellationDate,
        )}`}
      </StateLabel>
      {!isMedium && (
        <IconButton
          className={classes.iconButton}
          size="large"
          onClick={() => onViewCancelMembershipDialog(plan)}
        >
          <EyeIcon />
        </IconButton>
      )}
    </Grid>
  ) : membership.member ? (
    <TextInteractive variant="body2">
      {plan?.nextPaymentDue
        ? DateUtils.formatDate(plan?.nextPaymentDue)
        : t('Abbreviations:COMMON.NOT_AVAILABLE')}
    </TextInteractive>
  ) : null
}

export default PaymentDueCell
