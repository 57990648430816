import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RoleName, Utils } from '@pbt/pbt-ui-components'

import { fetchMembersList } from '~/store/actions/members'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getMembersList } from '~/store/reducers/members'
import { getMultipleUsers } from '~/store/reducers/users'
import useHasRole from '~/utils/useHasRole'

export const useSignatureOptions = () => {
  const dispatch = useDispatch()

  const isChewyAuth = useHasRole(RoleName.ChewyAuthorization)
  const isVeterinarian1 = useHasRole(RoleName.Veterinarian)
  const isVeterinarian2 = useHasRole(RoleName.Veterinarian2)
  const isVeterinarian = isVeterinarian1 || isVeterinarian2
  const currentBusiness = useSelector(getCurrentBusiness)

  const list = useSelector(getMembersList)
  const vets = useSelector(getMultipleUsers(list))

  const mainStaffRoles = useMainStaffRoles()

  const doctorRoleId = Utils.findConstantIdByName(
    RoleName.Veterinarian,
    mainStaffRoles,
  )
  const doctorRoleId2 = Utils.findConstantIdByName(
    RoleName.Veterinarian2,
    mainStaffRoles,
  )

  useEffect(() => {
    if (!isVeterinarian && isChewyAuth) {
      dispatch(
        fetchMembersList({
          roleIds: [doctorRoleId, doctorRoleId2],
          from: 0,
          to: 100,
          shorten: true,
          includeInactive: true,
          includeInvitationStatus: true,
        }),
      )
    }
  }, [])

  return {
    signatureOptions: vets
      .filter((vet) => Boolean(vet.signatureUrl))
      .map((vet) => ({
        id: vet.id,
        name: Utils.getPersonString(vet),
        signature: vet.signatureUrl || '',
      })),
    canEdit:
      isVeterinarian || !currentBusiness?.autoGenerateVaccineCertificates,
    canSelect:
      isChewyAuth &&
      !isVeterinarian &&
      currentBusiness?.autoGenerateVaccineCertificates,
  }
}
