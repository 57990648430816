import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { find, propEq } from 'ramda'
import { ClassesType, Shadow } from '@pbt/pbt-ui-components'
import { Settings as SettingsIcon } from '@pbt/pbt-ui-components/src/icons'

import PuiButtonGroup, {
  PuiButtonGroupItem,
} from '~/components/common/buttons/PuiButtonGroup'
import DialogNames from '~/constants/DialogNames'
import { TimetableArea } from '~/constants/schedulerConstants'
import useDialog from '~/utils/useDialog'
import useTimetableDate from '~/utils/useTimetableDate'

import TimetableDateLabel from '../TimetableDateLabel'
import TimetableTag from '../TimetableTag'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        top: 0,
      },
      top: theme.mixins.toolbar.minHeight,
      position: 'sticky',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above', 2),
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.contentBackground,
    },
    headerShadow: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.searchShadow, 'above', 2),
    },
    button: {
      minWidth: '150px !important',
      [theme.breakpoints.down('md')]: {
        minWidth: '120px !important',
      },
    },
    switch: {
      margin: theme.spacing(1),
    },
  }),
  { name: 'TimetableHeader' },
)

export interface TimetableHeaderProps {
  area: TimetableArea
  children: React.ReactNode
  className?: string
  classes?: ClassesType<typeof useStyles>
  isLoading?: boolean
  openShadow: boolean
  selectedView: string
  showSettingsButton?: boolean
  views: {
    disabled?: boolean
    label: string
    name: string
  }[]
}

const TimetableHeader = ({
  classes: classesProp,
  showSettingsButton = false,
  area,
  className,
  children,
  openShadow,
  views,
  isLoading,
  selectedView,
}: TimetableHeaderProps) => {
  const classes = useStyles({ classes: classesProp })
  const location = useLocation()
  const navigate = useNavigate()

  const { selectedDate } = useTimetableDate()

  const [openAdjustTimetableColumnsDialog] = useDialog(
    DialogNames.ADJUST_TIMETABLE_COLUMNS,
  )

  const queryParams = new URLSearchParams(location.search)

  const viewsList = Object.values(views).filter(({ disabled }) => !disabled)

  const onViewChange = ({ name }: PuiButtonGroupItem) => {
    if (selectedDate) {
      queryParams.set('date', selectedDate)
    }
    navigate(`/${area.toLowerCase()}/${name}?${queryParams.toString()}`)
  }

  const onSettingsIconClick = () => {
    openAdjustTimetableColumnsDialog({ area })
  }
  return (
    <Grid container className={classNames(className, classes.root)}>
      <Grid
        container
        alignItems="center"
        justifyContent="start"
        p={2}
        wrap="nowrap"
      >
        <Grid item whiteSpace="nowrap">
          <PuiButtonGroup
            classes={{ button: classes.button }}
            items={viewsList}
            selectedItem={find(propEq('name', selectedView), viewsList)}
            onItemSelected={onViewChange}
          />
        </Grid>
        <Grid
          container
          alignItems="center"
          flexGrow={1}
          justifyContent="space-between"
          ml={2}
          width="auto"
          wrap="nowrap"
        >
          <Grid container>
            <Grid item>
              <TimetableDateLabel />
            </Grid>
            <Grid item ml={1}>
              <TimetableTag />
            </Grid>
          </Grid>
          {showSettingsButton && (
            <Grid item>
              <IconButton size="small" onClick={onSettingsIconClick}>
                <SettingsIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isLoading && children}
      <Shadow className={classes.headerShadow} open={openShadow} />
    </Grid>
  )
}

export default TimetableHeader
