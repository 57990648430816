import { gql } from '@apollo/client'

export const SPACE_MONITOR = gql`
  fragment SpaceMonitorFields on SpaceMonitor {
    deviceName
    monitorType
    token
    assigned
  }
`
