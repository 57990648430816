import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import * as R from 'ramda'
import { Calendar, PuiSelect, useFields } from '@pbt/pbt-ui-components'

import UserSelect from '~/components/common/inputs/UserSelect'
import { getCurrentBusinessCounties } from '~/store/reducers/auth'
import { getDoctorId } from '~/store/reducers/soap'
import { getUsersMap } from '~/store/reducers/users'
import { DataHandle, Order } from '~/types'

export interface MainVaccineDetailsProps {
  vaccine: Order
}

export interface MainVaccineDetailsHandle
  extends DataHandle<Order['vaccinationDetails']> {}

const MainVaccineDetails = forwardRef<
  MainVaccineDetailsHandle,
  MainVaccineDetailsProps
>(function MainVaccineDetails({ vaccine }, ref) {
  const soapDoctorId = useSelector(getDoctorId)
  const usersMap = useSelector(getUsersMap)
  const currentBusinessCounties = useSelector(getCurrentBusinessCounties) || []
  const { t } = useTranslation('Common')

  const countiesItems = currentBusinessCounties.map((county) => ({
    id: county,
    name: county,
  }))
  const defaultCounty = R.head(currentBusinessCounties)
  const producerId = R.prop('producerId', vaccine)
  const doctorId = producerId ? R.prop(producerId, usersMap) : soapDoctorId

  const { vaccinationDetails } = vaccine

  const {
    fields: { administeredBy, county, dateGiven },
    validate,
    reset,
  } = useFields(
    [
      {
        name: 'administeredBy',
        label: t('Common:ADMINISTERED_BY'),
        type: 'select',
        validators: ['required'],
        initialValue: vaccinationDetails?.administeredBy ?? doctorId,
      },
      {
        name: 'county',
        label: t('Common:COUNTY'),
        type: 'select',
        initialValue: vaccinationDetails?.county || defaultCounty || '',
      },
      {
        name: 'dateGiven',
        label: t('Common:DATE_GIVEN_VACCINE'),
        initialValue: vaccinationDetails?.dateGiven || '',
      },
    ],
    false,
  )

  useEffect(() => {
    if (vaccinationDetails) {
      reset()
    }
  }, [vaccinationDetails])

  useImperativeHandle(ref, () => ({
    validate,
    get: () => ({
      administeredBy: administeredBy.value,
      county: county.value || null,
      dateGiven: dateGiven.value || null,
    }),
  }))

  return (
    <Grid container item spacing={2}>
      <Grid item xs>
        <UserSelect field={administeredBy} label={`${administeredBy.label}*`} />
      </Grid>
      <Grid item xs>
        <FormControl fullWidth margin="none">
          <InputLabel htmlFor="counties-select">{county.label}</InputLabel>
          <PuiSelect
            fullWidth
            field={county}
            input={<Input id="counties-select" />}
            items={countiesItems}
            keyProp="name"
          />
        </FormControl>
      </Grid>
      <Grid item xs>
        <Calendar
          fullWidth
          field={dateGiven}
          label={dateGiven.label}
          margin="none"
        />
      </Grid>
    </Grid>
  )
})

export default MainVaccineDetails
