import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Grid, Hidden } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { fetchClient } from '~/store/actions/clients'
import { useShouldDisplaySmsConsent } from '~/store/hooks/clients'
import { getUrlSearchParam } from '~/utils'

import ClientAndPatientSection from '../clients/details/new-client-and-patient/ClientAndPatientSection'
import ClientInfoLinkType from '../clients/details/new-client-and-patient/ClientInfoLinkType'
import DetailsBackButton from '../clients/DetailsBackButton'
import ConversationsTableComponent from './table/ConversationsTableComponent'

const useStyles = makeStyles(
  (theme) => ({
    backButton: {
      paddingLeft: theme.spacing(2),
    },
    headerButtons: {
      padding: theme.spacing(0, 3, 1.5, 3),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1, 0),
      },
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.10)',
    },
  }),
  { name: 'ConversationsPage' },
)

const ConversationsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { clientId: clientIdParam } = useParams()
  const { t } = useTranslation(['Common'])

  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  const showNewConversationParam = getUrlSearchParam(
    'showNewConversation',
    location.search,
  )
  const showNewConversation = showNewConversationParam === 'true'

  const hasClient = Boolean(clientIdParam)
  const locationState = location.state as { patientId?: string }

  const onBackButtonClick = () => {
    const patientId = locationState?.patientId
    const url = patientId ? `/patient/${patientId}` : `/client/${clientIdParam}`

    navigate(url)
  }

  useEffect(() => {
    if (clientIdParam) {
      dispatch(
        fetchClient({
          clientId: clientIdParam,
          withGlobalClientPreferences: shouldDisplaySmsConsent,
        }),
      )
    }
  }, [clientIdParam, shouldDisplaySmsConsent])

  return (
    <Grid container item flex={1} wrap="nowrap">
      {clientIdParam && (
        <Hidden mdDown>
          <Grid container direction="column" width="auto">
            <ClientAndPatientSection
              clientId={clientIdParam}
              highlight={ClientInfoLinkType.COMMUNICATION}
            />
          </Grid>
        </Hidden>
      )}
      <Grid container direction="column" flex={1}>
        {clientIdParam && (
          <DetailsBackButton
            fullWidth
            thin
            className={classes.backButton}
            onClick={onBackButtonClick}
          >
            {t('Common:CLIENT_AND_PATIENT_DETAIL')}
          </DetailsBackButton>
        )}
        <Grid container item direction="column" flex={1}>
          <ConversationsTableComponent
            classes={{
              headerButtons: classNames({
                [classes.headerButtons]: hasClient,
              }),
            }}
            clientId={clientIdParam}
            showNewConversation={showNewConversation}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConversationsPage
