import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { ApiError, BusinessRole, User } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { BusinessRoleItem } from '~/types'

import {
  fetchMoreItemsForSupportUsersList,
  fetchMoreItemsForSupportUsersListFailure,
  fetchMoreItemsForSupportUsersListSuccess,
  fetchSupportUsersList,
  fetchSupportUsersListFailure,
  fetchSupportUsersListSuccess,
  updateSupportUserRoles,
  updateSupportUserRolesFailure,
  updateSupportUserRolesSuccess,
} from '../actions/supportUsers'
import {
  FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST,
  FETCH_SUPPORT_USERS_LIST,
  UPDATE_SUPPORT_USER_ROLES,
} from '../actions/types/supportUsers'
import { updateUser } from '../actions/users'
import { finishLoading, startLoading } from '../duck/progress'
import { getUser } from '../reducers/users'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* updateSupportUserRolesSaga({
  personId,
  businessToRoleList,
}: ReturnType<typeof updateSupportUserRoles>) {
  try {
    yield put(startLoading('supportUsers'))
    const response: BusinessRoleItem[] = yield* requestAPI(
      API.updateSupportUserRoles,
      personId,
      businessToRoleList?.map((btr) => ({
        businessId: btr.business,
        roleId: btr.role,
      })),
    )
    const supportUser: User = yield select(getUser(personId))
    const newBusinessToRoleList = response.map(({ businessId, roleId }) => ({
      business: businessId,
      role: roleId,
    })) as BusinessRole[]

    const supportUserWithRoles = {
      ...supportUser,
      businessToRoleList: newBusinessToRoleList,
    }
    yield put(updateSupportUserRolesSuccess())
    yield put(updateUser(supportUserWithRoles))
  } catch (error) {
    yield put(updateSupportUserRolesFailure(error as ApiError))
  } finally {
    yield put(finishLoading('supportUsers'))
  }
}

export function* fetchSupportUsersListSaga(
  options: ReturnType<typeof fetchSupportUsersList>,
) {
  try {
    yield put(startLoading('supportUsers'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchSupportUsers, options)
    yield call(updateEntities, entities)
    yield put(fetchSupportUsersListSuccess(list, totalCount))
  } catch (error) {
    yield put(fetchSupportUsersListFailure(error as ApiError))
  } finally {
    yield put(finishLoading('supportUsers'))
  }
}

export function* fetchMoreItemsForSupportUsersListSaga(
  options: ReturnType<typeof fetchMoreItemsForSupportUsersList>,
) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchSupportUsers, options)
    yield call(updateEntities, entities)
    yield put(
      fetchMoreItemsForSupportUsersListSuccess(
        list,
        totalCount,
        options.from as number,
      ),
    )
  } catch (error) {
    yield put(fetchMoreItemsForSupportUsersListFailure(error as ApiError))
  }
}

function* watchFetchSupportUsersList() {
  yield takeLatest(FETCH_SUPPORT_USERS_LIST, fetchSupportUsersListSaga)
}

function* watchFetchMoreItemsForSupportUsersList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_SUPPORT_USERS_LIST,
    fetchMoreItemsForSupportUsersListSaga,
  )
}

function* watchUpdateSupportUserRoles() {
  yield takeEvery(UPDATE_SUPPORT_USER_ROLES, updateSupportUserRolesSaga)
}

export default function* supportUsersSaga() {
  yield all([
    watchFetchSupportUsersList(),
    watchFetchMoreItemsForSupportUsersList(),
    watchUpdateSupportUserRoles(),
  ])
}
