import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Calendar, ClassesType, Nil } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    container: {},
    input: {},
  }),
  { name: 'DateRangeInput' },
)

export interface DateRangeInputProps {
  classes?: ClassesType<typeof useStyles>
  fromDate: string
  fromLabel: string
  onChange: ({
    fromDate,
    toDate,
  }: {
    fromDate: string | Nil
    toDate: string | Nil
  }) => void
  toDate: string
  toLabel: string
}

const DateRangeInput = ({
  fromLabel,
  toLabel,
  fromDate,
  toDate,
  onChange,
  classes: classesProp,
}: DateRangeInputProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="space-between"
    >
      <Grid item>
        <Calendar
          className={classes.input}
          label={fromLabel}
          shouldDisableDate={(date) => Boolean(toDate) && date.isAfter(toDate)}
          value={fromDate}
          onChange={(date) => {
            if (date !== fromDate) {
              onChange({ fromDate: date, toDate })
            }
          }}
        />
      </Grid>
      <Grid item>
        <Calendar
          className={classes.input}
          label={toLabel}
          shouldDisableDate={(date) =>
            Boolean(fromDate) && date.isBefore(fromDate)
          }
          value={toDate}
          onChange={(date) => {
            if (date !== toDate) {
              onChange({ fromDate, toDate: date })
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangeInput
