import React, { useEffect, useState } from 'react'
import Cropper from 'react-cropper'
import { useTranslation } from 'react-i18next'
import CropIcon from '@mui/icons-material/Crop'
import { Fab, Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'
import { RotateImage } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles((theme) => ({
  rotationButton: {
    width: 40,
    height: 40,
    top: 100,
    right: 35,
    position: 'absolute',
    backgroundColor: theme.colors.iconBackground,
    '&:hover': {
      backgroundColor: theme.colors.signIconBackground,
    },
  },
  cropButton: {
    backgroundColor: theme.colors.iconBackground,
    '&:hover': {
      backgroundColor: theme.colors.signIconBackground,
    },
    padding: theme.spacing(1),
    position: 'absolute',
    top: 150,
    right: 35,
  },
  button: {
    width: 150,
    height: 40,
  },
}))

interface ImageEditorProps {
  file: File
  onCancel: () => void
  onSave: (image: File) => void
}

const ImageEditor = ({ file, onCancel, onSave }: ImageEditorProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const [cropper, setCropper] = useState<Cropper>()
  const [source, setSource] = useState('')

  useEffect(() => {
    const reader = new FileReader()
    reader.onload = () => {
      setSource(reader.result as string)
    }
    reader.readAsDataURL(file)
  }, [])

  const onCropButtonClick = () => {
    setSource(cropper!.getCroppedCanvas().toDataURL())
  }

  const handleSave = () => {
    cropper!.getCroppedCanvas().toBlob((blob) => {
      if (blob) {
        onSave(new File([blob], file.name, { type: file.type }))
      }
    })
  }

  return (
    <>
      <Cropper responsive src={source} onInitialized={setCropper} />
      <IconButton
        className={classes.rotationButton}
        size="large"
        onClick={() => cropper && cropper.rotate(90)}
      >
        <RotateImage />
      </IconButton>
      <IconButton
        className={classes.cropButton}
        size="large"
        onClick={onCropButtonClick}
      >
        <CropIcon />
      </IconButton>
      <Grid container justifyContent="right" pt={2}>
        <Grid container justifyContent="space-between" width={330}>
          <Fab className={classes.button} color="primary" onClick={onCancel}>
            {t('Common:CANCEL_ACTION')}
          </Fab>
          <ButtonWithLoader
            className={classes.button}
            disabled={!cropper}
            onClick={handleSave}
          >
            {t('Common:SAVE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </>
  )
}

export default ImageEditor
