import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults, Nil, Utils } from '@pbt/pbt-ui-components'

import { getShipmentPriceInfo } from '~/components/dashboard/admin/catalog/inventory/shipments/shipment-item-utils'
import { Shipment, ShipmentItem, ShipmentToUpdate, TableFilter } from '~/types'
import { getInsertIndex, mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_SHIPMENTS = 'shipments/FETCH_SHIPMENTS'
export const FETCH_SHIPMENTS_SUCCESS = 'shipments/FETCH_SHIPMENTS_SUCCESS'
export const FETCH_SHIPMENTS_FAILURE = 'shipments/FETCH_SHIPMENTS_FAILURE'

export const FETCH_SHIPMENT = 'shipments/FETCH_SHIPMENT'
export const FETCH_SHIPMENT_SUCCESS = 'shipments/FETCH_SHIPMENT_SUCCESS'
export const FETCH_SHIPMENT_FAILURE = 'shipments/FETCH_SHIPMENT_FAILURE'

export const FETCH_MORE_SHIPMENTS = 'shipments/FETCH_MORE_SHIPMENTS'
export const FETCH_MORE_SHIPMENTS_SUCCESS =
  'shipments/FETCH_MORE_SHIPMENTS_SUCCESS'
export const FETCH_MORE_SHIPMENTS_FAILURE =
  'shipments/FETCH_MORE_SHIPMENTS_FAILURE'

export const CREATE_SHIPMENT = 'shipments/CREATE_SHIPMENT'
export const CREATE_SHIPMENT_SUCCESS = 'shipments/CREATE_SHIPMENT_SUCCESS'
export const CREATE_SHIPMENT_FAILURE = 'shipments/CREATE_SHIPMENT_FAILURE'

export const EDIT_SHIPMENT = 'shipments/EDIT_SHIPMENT'
export const EDIT_SHIPMENT_SUCCESS = 'shipments/EDIT_SHIPMENT_SUCCESS'
export const EDIT_SHIPMENT_FAILURE = 'shipments/EDIT_SHIPMENT_FAILURE'

export const DELETE_SHIPMENT = 'shipments/DELETE_SHIPMENT'
export const DELETE_SHIPMENT_SUCCESS = 'shipments/DELETE_SHIPMENT_SUCCESS'
export const DELETE_SHIPMENT_FAILURE = 'shipments/DELETE_SHIPMENT_FAILURE'

export const CREATE_SHIPMENT_ITEM = 'shipments/CREATE_SHIPMENT_ITEM'
export const CREATE_SHIPMENT_ITEM_SUCCESS =
  'shipments/CREATE_SHIPMENT_ITEM_SUCCESS'
export const CREATE_SHIPMENT_ITEM_FAILURE =
  'shipments/CREATE_SHIPMENT_ITEM_FAILURE'

export const EDIT_SHIPMENT_ITEM = 'shipments/EDIT_SHIPMENT_ITEM'
export const EDIT_SHIPMENT_ITEM_SUCCESS = 'shipments/EDIT_SHIPMENT_ITEM_SUCCESS'
export const EDIT_SHIPMENT_ITEM_FAILURE = 'shipments/EDIT_SHIPMENT_ITEM_FAILURE'

export const DELETE_SHIPMENT_ITEM = 'shipments/DELETE_SHIPMENT_ITEM'
export const DELETE_SHIPMENT_ITEM_SUCCESS =
  'shipments/DELETE_SHIPMENT_ITEM_SUCCESS'
export const DELETE_SHIPMENT_ITEM_FAILURE =
  'shipments/DELETE_SHIPMENT_ITEM_FAILURE'

export const UPDATE_SHIPMENTS = 'shipments/UPDATE_SHIPMENTS'

export const SET_FILTERS = 'shipments/SET_FILTERS'

export const fetchShipments = (from: number, to: number) => ({
  type: FETCH_SHIPMENTS,
  from,
  to,
})
export const fetchShipmentsSuccess = (list: string[], totalCount: number) => ({
  type: FETCH_SHIPMENTS_SUCCESS,
  list,
  totalCount,
})
export const fetchShipmentsFailure = (error: ApiError) => ({
  type: FETCH_SHIPMENTS_FAILURE,
  error,
})

export const fetchShipment = (shipmentId: string) => ({
  type: FETCH_SHIPMENT,
  shipmentId,
})
export const fetchShipmentSuccess = (shipmentId: string) => ({
  type: FETCH_SHIPMENT_SUCCESS,
  shipmentId,
})
export const fetchShipmentFailure = (error: ApiError) => ({
  type: FETCH_SHIPMENT_FAILURE,
  error,
})

export const fetchMoreShipments = (from: number, to: number) => ({
  type: FETCH_MORE_SHIPMENTS,
  from,
  to,
})
export const fetchMoreShipmentsSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_SHIPMENTS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreShipmentsFailure = (error: ApiError) => ({
  type: FETCH_MORE_SHIPMENTS_FAILURE,
  error,
})

export const updateShipments = (shipments: Record<string, Shipment>) => ({
  type: UPDATE_SHIPMENTS,
  shipments,
})

export const createShipment = (shipment: Shipment) => ({
  type: CREATE_SHIPMENT,
  shipment,
})
export const createShipmentSuccess = (shipmentId: string) => ({
  type: CREATE_SHIPMENT_SUCCESS,
  shipmentId,
})
export const createShipmentFailure = (error: ApiError) => ({
  type: CREATE_SHIPMENT_FAILURE,
  error,
})

export const editShipment = (
  shipment: ShipmentToUpdate,
  withItems?: boolean,
) => ({
  type: EDIT_SHIPMENT,
  shipment,
  withItems,
})
export const editShipmentSuccess = (shipmentId: string) => ({
  type: EDIT_SHIPMENT_SUCCESS,
  shipmentId,
})
export const editShipmentFailure = (error: ApiError) => ({
  type: EDIT_SHIPMENT_FAILURE,
  error,
})

export const deleteShipment = (shipmentId: string) => ({
  type: DELETE_SHIPMENT,
  shipmentId,
})
export const deleteShipmentSuccess = (shipmentId: string) => ({
  type: DELETE_SHIPMENT_SUCCESS,
  shipmentId,
})
export const deleteShipmentFailure = (error: ApiError) => ({
  type: DELETE_SHIPMENT_FAILURE,
  error,
})

export const createShipmentItem = (
  shipmentId: string,
  shipmentItem: ShipmentItem,
) => ({
  type: CREATE_SHIPMENT_ITEM,
  shipmentId,
  shipmentItem,
})
export const createShipmentItemSuccess = (
  shipmentId: string,
  shipmentItem: ShipmentItem,
) => ({
  type: CREATE_SHIPMENT_ITEM_SUCCESS,
  shipmentId,
  shipmentItem,
})
export const createShipmentItemFailure = (error: ApiError) => ({
  type: CREATE_SHIPMENT_ITEM_FAILURE,
  error,
})

export const editShipmentItem = (
  shipmentId: string,
  shipmentItem: ShipmentItem,
) => ({
  type: EDIT_SHIPMENT_ITEM,
  shipmentId,
  shipmentItem,
})
export const editShipmentItemSuccess = (
  shipmentId: string,
  shipmentItem: ShipmentItem,
) => ({
  type: EDIT_SHIPMENT_ITEM_SUCCESS,
  shipmentId,
  shipmentItem,
})
export const editShipmentItemFailure = (error: ApiError) => ({
  type: EDIT_SHIPMENT_ITEM_FAILURE,
  error,
})

export const deleteShipmentItem = (
  shipmentId: string,
  shipmentItemId: string,
) => ({
  type: DELETE_SHIPMENT_ITEM,
  shipmentId,
  shipmentItemId,
})
export const deleteShipmentItemSuccess = (
  shipmentId: string,
  shipmentItemId: string,
) => ({
  type: DELETE_SHIPMENT_ITEM_SUCCESS,
  shipmentId,
  shipmentItemId,
})
export const deleteShipmentItemFailure = (error: ApiError) => ({
  type: DELETE_SHIPMENT_ITEM_FAILURE,
  error,
})

export const setFilters = (filters: Record<string, TableFilter>) => ({
  type: SET_FILTERS,
  filters,
})

export type ShipmentsState = {
  error: string | null
  filters: Record<string, TableFilter>
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  map: Record<string, Shipment>
  totalCount: number
}

const INITIAL_STATE: ShipmentsState = {
  list: [],
  map: {},
  filters: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isLoading: false,
  isFetching: false,
  isFetchingList: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
}

const getShipmentTotalCost = (
  updatedItems: ShipmentItem[],
  currentShipment: Shipment,
) => {
  const { total } = getShipmentPriceInfo({
    ...currentShipment,
    items: updatedItems,
  })
  return total
}

const getNewShipmentInsertIndex = (
  list: string[],
  map: Record<string, Shipment>,
  shipmentId: string,
) => {
  const items = list.map((id) => map[id])
  return getInsertIndex(items, map[shipmentId], 'receivedDate', false)
}

export const shipmentsReducer = (
  state: ShipmentsState = INITIAL_STATE,
  action: AnyAction,
): ShipmentsState => {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      }
    }
    case UPDATE_SHIPMENTS:
      return { ...state, map: secondLevelMerge(state.map, action.shipments) }
    case FETCH_SHIPMENTS:
      return {
        ...state,
        list: [],
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        error: null,
      }
    case FETCH_SHIPMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        list: action.list,
        totalCount: action.totalCount,
      }
    case FETCH_SHIPMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_SHIPMENTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_SHIPMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_SHIPMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_SHIPMENT: {
      return {
        ...state,
        isLoading: true,
        isFetching: true,
        error: null,
      }
    }
    case FETCH_SHIPMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
      }
    }
    case FETCH_SHIPMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    }
    case CREATE_SHIPMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case CREATE_SHIPMENT_SUCCESS:
      const insertIndex = getNewShipmentInsertIndex(
        state.list,
        state.map,
        action.shipmentId,
      )
      return {
        ...state,
        isLoading: false,
        list: [
          ...state.list.slice(0, insertIndex),
          action.shipmentId,
          ...state.list.slice(insertIndex),
        ],
        totalCount: state.totalCount + 1,
      }
    case CREATE_SHIPMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_SHIPMENT:
      return {
        ...state,
        isUpdating: true,
        error: null,
      }
    case EDIT_SHIPMENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      }
    case EDIT_SHIPMENT_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_SHIPMENT:
      return {
        ...state,
        isDeleting: true,
        error: null,
      }
    case DELETE_SHIPMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        list: R.without([action.shipmentId], state.list),
        map: R.dissoc(action.shipmentId, state.map),
        totalCount: state.totalCount - 1,
      }
    case DELETE_SHIPMENT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    case CREATE_SHIPMENT_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case CREATE_SHIPMENT_ITEM_SUCCESS:
      const afterCreateItems = [
        ...state.map[action.shipmentId].items,
        action.shipmentItem,
      ]
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.shipmentId]: {
            ...state.map[action.shipmentId],
            costTotal: getShipmentTotalCost(
              afterCreateItems,
              state.map[action.shipmentId],
            ),
            items: afterCreateItems,
          },
        },
      }
    case CREATE_SHIPMENT_ITEM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_SHIPMENT_ITEM:
      return {
        ...state,
        isUpdating: true,
        error: null,
      }
    case EDIT_SHIPMENT_ITEM_SUCCESS:
      const afterUpdateItems = Utils.updateById(
        action.shipmentItem,
        state.map[action.shipmentId].items,
      )
      return {
        ...state,
        isUpdating: false,
        map: {
          ...state.map,
          [action.shipmentId]: {
            ...state.map[action.shipmentId],
            costTotal: getShipmentTotalCost(
              afterUpdateItems,
              state.map[action.shipmentId],
            ),
            items: afterUpdateItems,
          },
        },
      }
    case EDIT_SHIPMENT_ITEM_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: getErrorMessage(action.error),
      }
    case DELETE_SHIPMENT_ITEM:
      return {
        ...state,
        isDeleting: true,
        error: null,
      }
    case DELETE_SHIPMENT_ITEM_SUCCESS:
      const afterDeleteItems = Utils.removeById(
        action.shipmentItemId,
        state.map[action.shipmentId].items,
      )
      return {
        ...state,
        isDeleting: false,
        map: {
          ...state.map,
          [action.shipmentId]: {
            ...state.map[action.shipmentId],
            costTotal: getShipmentTotalCost(
              afterDeleteItems,
              state.map[action.shipmentId],
            ),
            items: afterDeleteItems,
          },
        },
      }
    case DELETE_SHIPMENT_ITEM_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getShipments = (state: RootState): ShipmentsState =>
  state.shipments
export const getShipmentsIsLoading = (state: RootState) =>
  getShipments(state).isLoading
export const getShipmentsIsFetching = (state: RootState) =>
  getShipments(state).isFetching
export const getShipmentsIsFetchingList = (state: RootState) =>
  getShipments(state).isFetchingList
export const getShipmentsIsUpdating = (state: RootState) =>
  getShipments(state).isUpdating
export const getShipmentsIsDeleting = (state: RootState) =>
  getShipments(state).isDeleting
export const getShipmentsTotalCount = (state: RootState) =>
  getShipments(state).totalCount
export const getShipmentsFilters = (state: RootState) =>
  getShipments(state).filters
export const getShipmentsList = (state: RootState) => getShipments(state).list
export const getShipmentsMap = (state: RootState) => getShipments(state).map
export const getShipment = (id: string | Nil) =>
  createSelector(getShipmentsMap, (map) => (id ? map[id] : undefined))
export const getMultipleShipments = (ids: string[]) =>
  createSelector(getShipmentsMap, (map) => R.props(ids, map))
