import React from 'react'

import DragAndDropView, { DragAndDropViewProps } from './DragAndDropView'
import WhiteboardHorizontalView, {
  WhiteboardHorizontalViewProps,
} from './WhiteboardHorizontalView'
import WhiteboardTypeViewRowHeader from './WhiteboardTypeViewRowHeader'

export interface WhiteboardTypeViewProps
  extends Omit<DragAndDropViewProps, 'children'>,
    Omit<WhiteboardHorizontalViewProps, 'RowHeaderComponent' | 'getUniqueId'> {}

const WhiteboardTypeView = (props: WhiteboardTypeViewProps) => (
  <DragAndDropView {...props}>
    <WhiteboardHorizontalView
      RowHeaderComponent={WhiteboardTypeViewRowHeader}
      getUniqueId={(schedule) => schedule.type}
      {...props}
    />
  </DragAndDropView>
)

export default WhiteboardTypeView
