import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { isEmpty } from 'ramda'
import { AlertIconType } from '@pbt/pbt-ui-components'

import { tokenHolder } from '~/api/utils/token'
import DialogNames from '~/constants/DialogNames'
import { fetchConstants } from '~/store/actions/constants'
import { getAuthorizationError } from '~/store/reducers/auth'
import { getJournalMap, getLastUserId } from '~/store/reducers/journal'
import { auth0Enabled } from '~/utils'
import {
  isRocketChatUnauthorized,
  isUserNotFound,
  isWorkingOutsidePracticeHours,
} from '~/utils/errors'
import useDialog from '~/utils/useDialog'
import useIsAuthenticated from '~/utils/useIsAuthenticated'
import useLogout from '~/utils/useLogout'
import { useRedirectTo } from '~/utils/useRedirectTo'

import isURLBlacklistedForRedirect from '../../error/isURLBlacklistedForRedirect'

const lockPath = '/auth/lock'
const loginPath = '/auth/login'

export interface AuthWatcherProps {
  setPasswordExpirationError: (error: boolean) => void
}

const AuthWatcher = ({ setPasswordExpirationError }: AuthWatcherProps) => {
  const dispatch = useDispatch()
  const { pathname = '' } = useLocation()
  const { t } = useTranslation(['Common', 'Watcher'])

  const authError = useSelector(getAuthorizationError)
  const journal = useSelector(getJournalMap)
  const lastUserId = useSelector(getLastUserId) as string
  const [searchParams] = useSearchParams()
  const queryParamError = searchParams.get('error')
  const queryParamErrorDescription = searchParams.get('error_description')

  const { isAuthenticated, isAuthenticating } = useIsAuthenticated()

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const logout = useLogout()

  const [openErrorDialog] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const hasAuthError = Boolean(authError)
  const hasQueryError = Boolean(queryParamError)
  const isPublicScope = !isAuthenticating && !isAuthenticated

  // As we're using selectors based on constants on other pages we should fetch in an upper level
  useEffect(() => {
    if (isPublicScope) {
      dispatch(fetchConstants())
    }
  }, [isPublicScope])

  // Setting sdk context token getter under auth0 flow
  useEffect(() => {
    if (auth0Enabled) {
      tokenHolder.setTokenGetter(getAccessTokenSilently)
    }
    return () => {
      if (auth0Enabled) {
        tokenHolder.setTokenGetter(null)
      }
    }
  }, [getAccessTokenSilently])

  // Handling auth errors with logout flow
  useEffect(() => {
    if (hasQueryError) {
      // This error description is set in Auth0 and given to us through query params
      // This is the default value
      if (
        queryParamErrorDescription ===
        'Your password has expired.  Please reset it.'
      ) {
        setPasswordExpirationError(true)
        openErrorDialog({
          hideCloseButton: true,
          preventShowAgainCheckBox: false,
          iconType: AlertIconType.WARN,
          message: t('Watcher:PASSWORD_EXPIRED_ERROR'),
          okButtonText: t('Watcher:GO_BACK_TO_LOGIN'),
          onOk: () =>
            loginWithRedirect({
              authorizationParams: {
                prompt: 'login',
              },
            }),
        })
      }
    }
    if (hasAuthError) {
      if (isWorkingOutsidePracticeHours(authError!)) {
        openErrorDialog({
          preventShowAgainCheckBox: false,
          iconType: AlertIconType.WARN,
          message: t('Watcher:OUT_OF_WORKING_HOURS_ERROR'),
          okButtonText: t('Common:LOG_OUT'),
          onOk: () => logout(),
        })
      } else if (isUserNotFound(authError!)) {
        openErrorDialog({
          preventShowAgainCheckBox: false,
          iconType: AlertIconType.WARN,
          message: t('Watcher:USER_NOT_FOUND'),
          okButtonText: t('Common:LOG_OUT'),
          onOk: () => logout(),
        })
      } else if (!isRocketChatUnauthorized(authError!)) {
        logout()
      }
    }
  }, [hasAuthError, hasQueryError])

  useRedirectTo(
    isEmpty(journal) || !journal[lastUserId] ? loginPath : lockPath,
    pathname,
    !auth0Enabled && !isAuthenticated && !isURLBlacklistedForRedirect(pathname),
  )

  return null
}

export default AuthWatcher
