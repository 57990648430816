import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps } from '@pbt/pbt-ui-components'

import PercentTextField from '~/components/common/inputs/PercentTextField'
import { InvoiceOrEstimate } from '~/types'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 450,
      maxWidth: 450,
    },
    button: {
      width: 126,
      height: 40,
    },
  }),
  { name: 'RequiredDepositPopper' },
)

export interface RequiredDepositDialogProps
  extends Omit<PuiDialogProps, 'onChange'> {
  estimate: InvoiceOrEstimate
  onChange: (estimate: InvoiceOrEstimate) => void
}

const RequiredDepositDialog = ({
  estimate,
  onChange,
  onClose,
  ...rest
}: RequiredDepositDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const [deposit, setDeposit] = useState(estimate.requiredDeposit)

  useEffect(() => {
    setDeposit(100 * estimate.requiredDeposit)
  }, [estimate.requiredDeposit])

  const save = () => {
    const newEstimate = { ...estimate, requiredDeposit: deposit / 100 }
    onChange(newEstimate)
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <Fab
          className={classes.button}
          color="inherit"
          type="submit"
          variant="extended"
          onClick={save}
        >
          {t('Common:SAVE_ACTION')}
        </Fab>
      }
      classes={{
        paper: classes.paper,
      }}
      title={t('Common:EDIT_REQUIRED_DEPOSIT')}
      onClose={onClose}
      {...rest}
    >
      <Grid container direction="column" p={3}>
        <PercentTextField
          autoFocus
          inputRegex={/^\d{0,3}(\.\d{0,2})?$/}
          value={deposit}
          onChange={setDeposit}
        />
      </Grid>
    </PuiDialog>
  )
}

export default RequiredDepositDialog
