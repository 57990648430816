import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { DiscountReason } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const CREATE_DISCOUNT_REASON = 'discountReasons/CREATE_DISCOUNT_REASON'
export const CREATE_DISCOUNT_REASON_SUCCESS =
  'discountReasons/CREATE_DISCOUNT_REASON_SUCCESS'
export const CREATE_DISCOUNT_REASON_FAILURE =
  'discountReasons/CREATE_DISCOUNT_REASON_FAILURE'

export const UPDATE_DISCOUNT_REASON_STATUS =
  'discountReasons/UPDATE_DISCOUNT_REASON_STATUS'
export const UPDATE_DISCOUNT_REASON_STATUS_SUCCESS =
  'discountReasons/UPDATE_DISCOUNT_REASON_STATUS_SUCCESS'
export const UPDATE_DISCOUNT_REASON_STATUS_FAILURE =
  'discountReasons/UPDATE_DISCOUNT_REASON_STATUS_FAILURE'

export const FETCH_DISCOUNT_REASONS = 'discountReasons/FETCH_DISCOUNT_REASONS'
export const FETCH_DISCOUNT_REASONS_SUCCESS =
  'discountReasons/FETCH_DISCOUNT_REASONS_SUCCESS'
export const FETCH_DISCOUNT_REASONS_FAILURE =
  'discountReasons/FETCH_DISCOUNT_REASONS_FAILURE'

export const DELETE_DISCOUNT_REASON = 'discountReasons/DELETE_DISCOUNT_REASON'
export const DELETE_DISCOUNT_REASON_SUCCESS =
  'discountReasons/DELETE_DISCOUNT_REASON_SUCCESS'
export const DELETE_DISCOUNT_REASON_FAILURE =
  'discountReasons/DELETE_DISCOUNT_REASON_FAILURE'

export const createDiscountReason = (name: string, businessId: string) => ({
  type: CREATE_DISCOUNT_REASON,
  name,
  businessId,
})
export const createDiscountReasonSuccess = (
  discountReason: DiscountReason,
) => ({
  type: CREATE_DISCOUNT_REASON_SUCCESS,
  discountReason,
})
export const createDiscountReasonFailure = (error: ApiError) => ({
  type: CREATE_DISCOUNT_REASON_FAILURE,
  error,
})

export const updateDiscountReasonStatus = (
  businessId: string,
  discountReasonId: string,
  active: boolean,
) => ({
  type: UPDATE_DISCOUNT_REASON_STATUS,
  discountReasonId,
  active,
  businessId,
})
export const updateDiscountReasonStatusSuccess = (
  id: string,
  discountReason: DiscountReason,
) => ({
  type: UPDATE_DISCOUNT_REASON_STATUS_SUCCESS,
  id,
  discountReason,
})
export const updateDiscountReasonStatusFailure = (error: ApiError) => ({
  type: UPDATE_DISCOUNT_REASON_STATUS_FAILURE,
  error,
})

export const fetchDiscountReasons = (businessId: string) => ({
  type: FETCH_DISCOUNT_REASONS,
  businessId,
})
export const fetchDiscountReasonsSuccess = (
  list: DiscountReason[],
  businessId: string,
) => ({
  type: FETCH_DISCOUNT_REASONS_SUCCESS,
  list,
  businessId,
})
export const fetchDiscountReasonsFailure = (error: ApiError) => ({
  type: FETCH_DISCOUNT_REASONS_FAILURE,
  error,
})

export const deleteDiscountReason = (businessId: string, id: string) => ({
  type: DELETE_DISCOUNT_REASON,
  businessId,
  id,
})
export const deleteDiscountReasonSuccess = (id: string) => ({
  type: DELETE_DISCOUNT_REASON_SUCCESS,
  id,
})
export const deleteDiscountReasonFailure = (error: ApiError) => ({
  type: DELETE_DISCOUNT_REASON_FAILURE,
  error,
})

export type DiscountReasonsState = {
  discountReasonMandatory: boolean
  error: string | null
  isLoading: boolean
  map: Record<string, DiscountReason>
}

const INITIAL_STATE = {
  map: {},
  isLoading: false,
  error: null,
  discountReasonMandatory: false,
}

export const discountReasonsReducer = (
  state: DiscountReasonsState = INITIAL_STATE,
  action: AnyAction,
): DiscountReasonsState => {
  switch (action.type) {
    case CREATE_DISCOUNT_REASON:
      return { ...state, isLoading: true, error: null }
    case CREATE_DISCOUNT_REASON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        map: {
          ...state.map,
          [action.discountReason.id]: action.discountReason,
        },
      }
    case CREATE_DISCOUNT_REASON_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case UPDATE_DISCOUNT_REASON_STATUS:
      return { ...state, isLoading: true, error: null }
    case UPDATE_DISCOUNT_REASON_STATUS_SUCCESS:
      return { ...state, isLoading: false }
    case UPDATE_DISCOUNT_REASON_STATUS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_DISCOUNT_REASONS:
      return { ...state, isLoading: true, error: null }
    case FETCH_DISCOUNT_REASONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        discountReasonMandatory: action.list.discountReasonMandatory,
        map: R.mergeAll(
          action.list.invoiceDiscountReasons.map((reason: DiscountReason) => ({
            [reason.id]: reason,
          })),
        ),
      }
    case FETCH_DISCOUNT_REASONS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case DELETE_DISCOUNT_REASON:
      return { ...state, isLoading: true, error: null }
    case DELETE_DISCOUNT_REASON_SUCCESS:
      return {
        ...state,
        map: R.omit([action.id], state.map),
        isLoading: false,
      }
    case DELETE_DISCOUNT_REASON_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    default:
      return state
  }
}

export const DISCOUNT_REASON_OTHER_NAME = 'Other'

const filterOtherReason = (dr: DiscountReason) =>
  dr.name !== DISCOUNT_REASON_OTHER_NAME

export const getDiscountReasons = (state: RootState): DiscountReasonsState =>
  state.discountReasons
export const getDiscountReasonsMap = (state: RootState) =>
  getDiscountReasons(state).map
export const getDiscountReason = (id: string | Nil) =>
  createSelector(getDiscountReasonsMap, (map) => (id ? map[id] : undefined))
export const getMultipleDiscountReasons = (ids: string[]) =>
  createSelector(getDiscountReasonsMap, (map) => R.props(ids, map))
export const getDiscountReasonsList = createSelector(
  getDiscountReasonsMap,
  (map) => {
    const allReasons = Object.values(map)
    return [
      ...allReasons.filter(filterOtherReason),
      ...allReasons.filter((dr) => !filterOtherReason(dr)),
    ]
  },
)
export const getActiveDiscountReasonsList = createSelector(
  getDiscountReasonsList,
  (list) => list.filter((reason) => reason.active),
)
export const getDiscountReasonsIsLoading = (state: RootState) =>
  getDiscountReasons(state).isLoading
export const getDiscountReasonIsMandatory = (state: RootState) =>
  getDiscountReasons(state).discountReasonMandatory
