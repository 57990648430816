import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { ConstantWithMeasurementsSystem, Utils } from '@pbt/pbt-ui-components'

import { getCurrentBusinessMeasurementTypeId } from '~/store/reducers/auth'
import { getMeasurementTypes } from '~/store/reducers/constants'

export const useGetBusinessEnabledMeasurementSystemsIds = (): string[] => {
  const currentBusinessMeasurementTypeId = useSelector(
    getCurrentBusinessMeasurementTypeId,
  )
  const MeasurementTypes = useSelector(getMeasurementTypes)

  const MeasurementType = Utils.findById(
    currentBusinessMeasurementTypeId,
    MeasurementTypes,
  )

  return MeasurementType?.includedMeasurementSystemsIds || []
}

export const useGetBusinessEnabledUnits = (
  UnitsConstant: ConstantWithMeasurementsSystem[] = [],
) => {
  const measurementSystemsIds = useGetBusinessEnabledMeasurementSystemsIds()

  if (measurementSystemsIds.length > 0) {
    return measurementSystemsIds
      .map((id) =>
        UnitsConstant.find(
          ({ measurementSystemId }) => measurementSystemId === id,
        ),
      )
      .filter(Boolean) as ConstantWithMeasurementsSystem[]
  }

  return UnitsConstant
}

export const findMatchingUnit = (
  measurementSystemsIds: string[],
  constantList: ConstantWithMeasurementsSystem[] = [],
) => {
  const primaryMeasurementSystem = R.head(measurementSystemsIds)

  const bestMatch = R.propEq('measurementSystemId', primaryMeasurementSystem)
  const anyMatch = R.pipe(
    (constant: ConstantWithMeasurementsSystem) => constant.measurementSystemId,
    R.includes(R.__, measurementSystemsIds),
  )

  const match = constantList.find(bestMatch) || constantList.find(anyMatch)

  return match?.name
}
