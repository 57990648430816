/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { NumberUtils, Utils } from '@pbt/pbt-ui-components'

import ControlledSubstanceLabel from '~/components/common/labels/ControlledSubstanceLabel'
import {
  getDrugDeliveryMethod,
  getDrugStrengthUnit,
  getProductForm,
  getProductSizeUnit,
} from '~/store/reducers/constants'
import { getIsCurrentContextSoap } from '~/store/reducers/soap'
import { Order } from '~/types'
import { getManualInputSelectValue } from '~/utils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: theme.spacing(0.5, 0),
    },
    price: {
      fontSize: '2rem',
      color: theme.colors.markerHighlighted,
      fontWeight: '500',
    },
    tooltipRow: {
      marginBottom: theme.spacing(1),
    },
    noMargin: {
      margin: 0,
    },
  }),
  { name: 'OrderTooltip' },
)

export interface OrderTooltipProps {
  forceShowPrices?: boolean
  order: Order
}

export interface OrderSpecificTooltipProps {
  order: Order
  showPrices?: boolean
}

const InventoryTooltip = ({
  order: { inventory, price, variation },
  showPrices,
}: OrderSpecificTooltipProps) => {
  const classes = useStyles()

  const ProductSizeUnit = useSelector(getProductSizeUnit)
  const DrugDeliveryMethod = useSelector(getDrugDeliveryMethod)
  const ProductForm = useSelector(getProductForm)
  const DrugStrengthUnit = useSelector(getDrugStrengthUnit)

  const priceUnit = useGetFormattedPriceUnit(price, variation)

  const deliveryMethod = Utils.getConstantName(
    variation?.deliveryMethodId,
    DrugDeliveryMethod,
    '',
  )
  const productForm = getManualInputSelectValue(
    ProductForm,
    variation?.formId,
    variation?.customForm,
  )
  const productSize = Utils.getConstantName(
    variation?.perPackageUnitsId,
    ProductSizeUnit,
    '',
  )
  const strengthUnits = Utils.getConstantName(
    variation?.strengthUnitsId,
    DrugStrengthUnit,
    '',
  )

  const strength =
    variation?.strength || strengthUnits
      ? `${variation?.strength}${strengthUnits}`
      : ''
  const form = productForm ? `${productForm}-` : ''

  return (
    <Grid container className={classes.container}>
      {inventory?.controlled && (
        <ControlledSubstanceLabel classes={{ root: classes.tooltipRow }} />
      )}
      {showPrices && (
        <Grid
          container
          className={classNames(classes.price, classes.tooltipRow)}
        >
          {NumberUtils.formatMoney(price?.price)}/{priceUnit}
        </Grid>
      )}
      <Grid>
        {`${deliveryMethod} ${form}${
          variation?.perPackageAmount
        } ${productSize} ${strength && `(${strength})`}`}
      </Grid>
    </Grid>
  )
}

const LabTestTooltip = ({
  order: { labTest, price },
  showPrices,
}: OrderSpecificTooltipProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const priceUnit = useGetFormattedPriceUnit(price)

  return (
    <Grid container className={classes.container}>
      {showPrices && (
        <Grid
          container
          className={classNames(classes.price, classes.tooltipRow)}
        >
          {NumberUtils.formatMoney(price?.price)}/{priceUnit}
        </Grid>
      )}
      <Grid>
        <p className={classes.noMargin}>
          <b>{t('Common:TYPE_ONE')}:</b> {labTest?.type}
        </p>
        <p className={classes.noMargin}>
          <b>
            {labTest?.vendorName} {t('Common:CODE').toLowerCase()}:
          </b>{' '}
          {labTest?.vendorCode}
        </p>
        <p className={classes.noMargin}>
          <b>{t('Common:TESTS_FOR')}:</b> {labTest?.testFor}
        </p>
        {labTest?.specimen && (
          <p className={classes.noMargin}>
            <b>{t('Common:SPECIMEN')}:</b> {labTest?.specimen}
          </p>
        )}
      </Grid>
    </Grid>
  )
}

const ProcedureTooltip = ({
  order: { procedure, price },
  showPrices,
}: OrderSpecificTooltipProps) => {
  const classes = useStyles()

  const priceUnit = useGetFormattedPriceUnit(price)

  return (
    <Grid container className={classes.container}>
      {showPrices && (
        <Grid
          container
          className={classNames(classes.price, classes.tooltipRow)}
        >
          {NumberUtils.formatMoney(price?.price)}/{priceUnit}
        </Grid>
      )}
      <Grid container>{procedure?.description}</Grid>
    </Grid>
  )
}

const OrderTypeTooltipMap: Record<
  string,
  React.JSXElementConstructor<OrderSpecificTooltipProps>
> = {
  INVENTORY: InventoryTooltip,
  LAB_TEST: LabTestTooltip,
  PROCEDURE: ProcedureTooltip,
  PRESCRIPTION: InventoryTooltip,
  REFILL: InventoryTooltip,
}

const OrderTooltip = ({ order, forceShowPrices }: OrderTooltipProps) => {
  const showPrices = useSelector(getIsCurrentContextSoap) || forceShowPrices

  const Tooltip = OrderTypeTooltipMap[order.type || order.logType]

  if (!Tooltip) {
    return null
  }

  return <Tooltip order={order} showPrices={showPrices} />
}

export default OrderTooltip
