import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OrderTooltip from '~/components/dashboard/soap/OrderTooltip'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import { removeOrder } from '~/store/actions/orders'
import {
  useIsDrug,
  useIsFood,
  useIsVetDiet,
  useNavigateOrderToPrescription,
  useUpdateOrderNotes,
} from '~/store/hooks/orders'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import { Order } from '~/types'

import ItemWithBadgesActions, {
  ItemWithBadgesActionsProps,
} from '../../item-with-badges/ItemWithBadgesActions'
import { useHandleDelete } from '../../utils/useHandleDelete'

export interface InventoryItemActionsProps extends ItemWithBadgesActionsProps {
  onDelete?: () => void
  order: Order
}

const InventoryItemActions = ({
  order,
  onDelete: onDeleteProp,
  ...rest
}: InventoryItemActionsProps) => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)

  const handleUpdateNotes = useUpdateOrderNotes(order)
  const isDrug = useIsDrug(order)
  const isVetDiet = useIsVetDiet(order)
  const isFood = useIsFood(order)
  const navigateToPrescriptionDialog = useNavigateOrderToPrescription({
    businessId: soapBusinessId,
    soapId,
  })

  const handleDelete = useHandleDelete(
    order,
    () => dispatch(removeOrder(order)),
    onDeleteProp,
  )

  const openDrugPrescriptionDialog = () =>
    navigateToPrescriptionDialog(InventoryCategoryName.DRUG, order)
  const openVetDietPrescriptionDialog = () =>
    navigateToPrescriptionDialog(InventoryCategoryName.VET_DIET, order)
  const openFoodPrescriptionDialog = () =>
    navigateToPrescriptionDialog(InventoryCategoryName.FOOD, order)

  return (
    <ItemWithBadgesActions
      hasNotes={Boolean(order.notes)}
      tooltip={<OrderTooltip order={order} />}
      onAddRX={isFood ? openFoodPrescriptionDialog : undefined}
      onDelete={handleDelete}
      onEdit={
        isDrug
          ? openDrugPrescriptionDialog
          : isVetDiet
            ? openVetDietPrescriptionDialog
            : undefined
      }
      onUpdateNotes={isDrug ? undefined : handleUpdateNotes}
      {...rest}
    />
  )
}

export default InventoryItemActions
