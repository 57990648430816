import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { isEmpty } from 'ramda'
import {
  DateFormat,
  LanguageUtils,
  moment,
  Text,
  TextWithTooltip,
  Utils,
} from '@pbt/pbt-ui-components'
import {
  DefaultTeamAvatar as TeamIcon,
  SwitchView as SwitchViewIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { SchedulerColumnType } from '~/constants/schedulerConstants'
import { useGetScheduleColumnNameTranslated } from '~/store/hooks/whiteboard'
import { getSchedulerBusinessWorkingHours } from '~/store/reducers/scheduler'
import { getUser } from '~/store/reducers/users'
import { Schedule } from '~/types'
import { isActivePerson } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      padding: theme.spacing(0.25, 1),
      border: theme.constants.tableBorder,
      minHeight: 40,
      marginLeft: theme.spacing(2),
    },
    unassignedRoot: {
      marginLeft: theme.spacing(1),
    },
    hours: {
      fontSize: '0.8rem',
    },
    statusIndicator: {
      marginTop: 6,
      marginBottom: 6,
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: '50%',
    },
    indicatorAvailable: {
      backgroundColor: theme.colors.success,
    },
    indicatorBusy: {
      backgroundColor: theme.colors.important,
    },
    indicatorNotWorking: {
      backgroundColor: theme.colors.selectedOption,
    },
    switchViewIconContainer: {
      color: theme.colors.sideText,
      paddingLeft: theme.spacing(1.5),
    },
    disabled: {
      opacity: 0.5,
    },
    teamIcon: {
      height: '100%',
    },
  }),
  { name: 'ScheduleColumnHeader' },
)

const SWITCH_VIEW_WIDTH = 96

export interface ScheduleColumnHeaderProps {
  allowWrap?: boolean
  className?: string
  column: Schedule
  switchView?: boolean
}

const ScheduleColumnHeader = ({
  className,
  column,
  switchView = false,
  allowWrap = false,
}: ScheduleColumnHeaderProps) => {
  const { columnType, personId, slots = [], workingHours, columnWidth } = column
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const person = useSelector(getUser(personId))
  const businessWorkingHours = useSelector(getSchedulerBusinessWorkingHours)

  const columnName = useGetScheduleColumnNameTranslated()(column)

  const businessHoursFrom = moment(businessWorkingHours.from)
  const businessHoursTo = moment(businessWorkingHours.to)

  const momentFrom = moment(workingHours && workingHours.from)
  const momentTo = moment(workingHours && workingHours.to)

  const businessHoursFromFormatted = businessHoursFrom.format(
    DateFormat.FULL_TIME_WITH_MERIDIAN,
  )
  const businessHoursToFormatted = businessHoursTo.format(
    DateFormat.FULL_TIME_WITH_MERIDIAN,
  )

  const fromHour = momentFrom.format(DateFormat.FULL_TIME_WITH_MERIDIAN)
  const toHour = momentTo.format(DateFormat.FULL_TIME_WITH_MERIDIAN)

  const isEventType = columnType === SchedulerColumnType.EVENT_TYPE
  const isEventState = columnType === SchedulerColumnType.EVENT_STATE
  const isUnassigned = columnType === SchedulerColumnType.UNASSIGNED
  const isPerson = !isUnassigned && !isEventType && !isEventState

  const showIndicator = isPerson && !switchView

  const isBusy = slots.some(({ interval: { from, to } }) =>
    moment().isBetween(moment(from), moment(to), 'minutes', '[]'),
  )

  const isNotWorking = !moment().isBetween(
    momentFrom,
    momentTo,
    'minutes',
    '[]',
  )

  const isAvailable = !isBusy && !isNotWorking

  const width = switchView ? SWITCH_VIEW_WIDTH : columnWidth

  const isInactive = isPerson && !isActivePerson(person)

  return (
    <Paper
      className={classNames(className, classes.root, {
        [classes.unassignedRoot]: isUnassigned,
        [classes.disabled]: person?.deleted || isInactive,
      })}
      elevation={0}
      style={{
        width,
        // ToDo: remove after proper implementation
        visibility: switchView ? 'hidden' : 'visible',
      }}
    >
      <Grid item>
        {showIndicator && (
          <div
            className={classNames(classes.statusIndicator, {
              [classes.indicatorAvailable]: isAvailable,
              [classes.indicatorBusy]: isBusy,
              [classes.indicatorNotWorking]: isNotWorking,
            })}
          />
        )}
      </Grid>
      {switchView ? (
        <Grid container item xs alignItems="center">
          <Grid item xs>
            <Text strong variant="lowAccent3">
              {t('Common:SWITCH_VIEW')}
            </Text>
          </Grid>
          <Grid item xs className={classes.switchViewIconContainer}>
            <SwitchViewIcon />
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs direction="column" pl={1} wrap="nowrap">
          <Grid container item alignItems="center" wrap="nowrap">
            <Dotdotdot clamp={2}>
              <TextWithTooltip
                allowWrap={allowWrap}
                tooltipText={
                  person?.deleted
                    ? t('Common:WAS_DELETED')
                    : isInactive
                      ? t('Common:INACTIVE_ONE')
                      : ''
                }
                variant="body2"
              >
                {personId
                  ? Utils.getPersonString(person)
                  : LanguageUtils.capitalize(columnName)}
              </TextWithTooltip>
            </Dotdotdot>
          </Grid>
          <Grid item>
            <Text className={classes.hours} variant="lowAccent3">
              {isUnassigned && !isEmpty(businessWorkingHours)
                ? `${t('Common:CLINIC_HOURS')}:
                        ${businessHoursFromFormatted}-${businessHoursToFormatted}
                      `
                : workingHours
                  ? `${fromHour}-${toHour}`
                  : ''}
            </Text>
          </Grid>
        </Grid>
      )}
      {person?.team && (
        <Grid item>
          <TeamIcon className={classes.teamIcon} />
        </Grid>
      )}
    </Paper>
  )
}

export default ScheduleColumnHeader
