import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import {
  editDiagnosisHistory,
  saveDiagnosisHistory,
} from '~/store/actions/medicalHistory'
import { fetchWidgetsData } from '~/store/duck/landing'
import {
  getDiagnosesIsLoading,
  getDiffDxSearchResults,
  searchDiagnoses,
} from '~/store/duck/soapDiagnoses'
import {
  getMedicalHistoryIsFetching,
  getMedicalHistoryIsSending,
} from '~/store/reducers/medicalHistory'
import { DiagnoseHistoryItem } from '~/types'
import { composeSearchResultsWithSelectedItems } from '~/utils/diagnosesUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import DiagnosisHistory from './DiagnosisHistory'
import DiagnosisListWithFilters, {
  DiagnosisSelectableListHandle,
} from './DiagnosisListWithFilters'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'DiagnosisHistoryDialog' },
)

interface DiagnosisHistoryDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  diagnosis?: DiagnoseHistoryItem | Nil
  patientId: string | Nil
}

const DiagnosisHistoryDialog = ({
  diagnosis,
  patientId,
  clientId,
  open,
  onClose,
}: DiagnosisHistoryDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSending = useSelector(getMedicalHistoryIsSending)
  const isFetching = useSelector(getMedicalHistoryIsFetching)
  const searchResults = useSelector(getDiffDxSearchResults)
  const isLoading = useSelector(getDiagnosesIsLoading)
  const { t } = useTranslation(['Common', 'Dialogs', 'Search'])

  const listRef = useRef<DiagnosisSelectableListHandle>(null)

  const refetchDiagnoses = () => {
    dispatch(
      fetchWidgetsData([SnapshotsAliasTypes.Diagnoses], {
        quiet: false,
        landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
        patientId,
      }),
    )
  }

  const closeAfterUpdate = () => {
    refetchDiagnoses()
    if (onClose) {
      onClose()
    }
  }

  const setCloseAfterCreationOn = useCloseAfterCreation(
    closeAfterUpdate,
    getMedicalHistoryIsSending,
  )

  const onProceed = (items: DiagnoseHistoryItem[]) => {
    setCloseAfterCreationOn()
    if (patientId) {
      if (diagnosis) {
        dispatch(editDiagnosisHistory(items, patientId))
      } else {
        dispatch(
          saveDiagnosisHistory(
            R.map(R.omit(['id']), items),
            patientId,
            clientId,
          ),
        )
      }
    }
  }

  const [searchResultsWithUpdatedFields, setSearchResultsWithUpdatedFields] =
    useState(searchResults)

  useEffect(() => {
    if (searchResults && listRef.current) {
      const selectedItems = listRef.current?.getItems()
      const updatedItems = composeSearchResultsWithSelectedItems(
        searchResults,
        selectedItems,
      )
      setSearchResultsWithUpdatedFields(updatedItems)
    } else {
      setSearchResultsWithUpdatedFields(searchResults)
    }
  }, [searchResults])

  return (
    <PuiDialog
      aria-labelledby="diagnosis-history-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={
        diagnosis
          ? t('Dialogs:DIAGNOSIS_HISTORY_DIALOG.TITLE_EDIT')
          : t('Common:ADD_DIAGNOSIS_HISTORY')
      }
      onClose={onClose}
    >
      <Grid container direction="column" p={2}>
        {diagnosis ? (
          <DiagnosisHistory diagnosis={diagnosis} onProceed={onProceed} />
        ) : (
          <DiagnosisListWithFilters
            isLoading={isSending}
            isReceivingListItems={isFetching || isLoading}
            patientId={patientId}
            ref={listRef}
            searchItems={({ searchTerm }) =>
              dispatch(searchDiagnoses(searchTerm, patientId))
            }
            searchPlaceholder={t('Search:DIFFERENTIAL_DIAGNOSES')}
            searchResults={searchResultsWithUpdatedFields}
            onProceed={onProceed}
          />
        )}
      </Grid>
    </PuiDialog>
  )
}

export default DiagnosisHistoryDialog
