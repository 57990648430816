import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  CustomFieldValidatorState,
  LanguageUtils,
  PhoneUtils,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import Expander from '~/components/common/lists/Expander'
import ImageLogo from '~/components/common/logos/ImageLogo'
import PuiSwitch from '~/components/common/PuiSwitch'
import { MarketplaceItemType } from '~/constants/marketplaceConstants'
import {
  editMarketplaceItem,
  getMarketplaceItem,
  getMarketplaceItemIsEditing,
} from '~/store/duck/marketplace'
import {
  getMarketplaceCategory,
  getMarketplaceType,
  getMarketplaceWorkflow,
} from '~/store/reducers/constants'
import { MarketplaceItemDetail } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    expander: {
      padding: 0,
    },
    logo: {
      marginRight: theme.spacing(2),
    },
    detailsRow: {
      minHeight: 40,
      [theme.breakpoints.up('md')]: {
        maxWidth: 600,
      },
    },
    detailsRowBorder: {
      borderBottom: theme.constants.tabBorder,
    },
    detailsLabel: {
      whiteSpace: 'initial',
      width: 184,
      minWidth: 184,
    },
    link: {
      fontSize: '1.4rem',
      padding: theme.spacing(1.5, 0),
    },
    credentialsContainer: {
      borderTop: theme.constants.tabBorder,
      borderBottom: theme.constants.tabBorder,
      height: 40,
    },
    active: {},
    field: {
      width: 300,
    },
    instructionLink: {},
  }),
  { name: 'MarketPlaceDetails' },
)

interface MarketPlaceDetailsProps {
  itemId: string
  onClose: () => void
}

const MarketPlaceDetails = ({
  itemId: marketplaceItemId,
  onClose,
}: MarketPlaceDetailsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const marketplaceItem =
    useSelector(getMarketplaceItem(marketplaceItemId)) || {}
  const isEditing = useSelector(getMarketplaceItemIsEditing)
  const MarketplaceCategory = useSelector(getMarketplaceCategory)
  const MarketplaceWorkflow = useSelector(getMarketplaceWorkflow)
  const MarketplaceType = useSelector(getMarketplaceType)

  const type = Utils.getConstantName(marketplaceItem.typeId, MarketplaceType)
  const isIFrameWidget = type === MarketplaceItemType.IFRAME
  const isFullIntegration = type === MarketplaceItemType.FULL_INTEGRATION

  const {
    name,
    logoUrl,
    categoryId,
    description,
    setupUrl,
    setupInstructions,
    jsonParameters,
    apiAccess,
    rhapsodyApiKey: rhapsodyApiKeyProp,
  } = marketplaceItem

  const processingJsonParams = jsonParameters || []

  const category = LanguageUtils.getConstantTranslatedName(
    categoryId,
    MarketplaceCategory,
    '',
  )

  const validateApiKey = ({
    state: { active, rhapsodyApiKey },
  }: CustomFieldValidatorState) =>
    !active || !(isIFrameWidget && apiAccess) || rhapsodyApiKey

  const {
    fields: { active, rhapsodyApiKey, ...jsonFields },
    reset,
    validate,
  } = useFields(
    [
      {
        name: 'active',
        label: t('Common:ACTIVE_ONE'),
        type: 'toggle',
        initialValue: marketplaceItem.active,
      },
      {
        name: 'rhapsodyApiKey',
        label: t(
          'Admin:MARKETPLACE.MARKETPLACE_ITEM_DETAILS.RHAPSODY_SYSTEM_API_KEY',
        ),
        validators: [{ validator: validateApiKey, validatorName: 'required' }],
        initialValue: rhapsodyApiKeyProp,
      },
      ...(processingJsonParams.map(({ field, value }) => ({
        name: field,
        initialValue: value,
      })) || []),
    ],
    false,
  )

  useEffect(() => {
    if (marketplaceItem?.id) {
      reset()
    }
  }, [marketplaceItem?.id])

  const workflows: string[] = (marketplaceItem.workflowIds || [])
    .map((id) => Utils.getConstantName(id, MarketplaceWorkflow, ''))
    .filter(Boolean)

  const details: MarketplaceItemDetail[] = [
    {
      label: t('Common:WEBSITE'),
      value: marketplaceItem.websiteUrl,
      link: true,
    },
    {
      label: t('Common:CUSTOMER_PORTAL'),
      value: marketplaceItem.customerPortalUrl,
      link: true,
    },
    { label: t('Common:SALES_EMAIL'), value: marketplaceItem.salesContact },
    { label: t('Common:SUPPORT_EMAIL'), value: marketplaceItem.supportEmail },
    {
      label: t('Common:SUPPORT_PHONE'),
      value: PhoneUtils.formatPhoneNumber(marketplaceItem.supportPhone),
    },
    {
      label: t(
        'Admin:MARKETPLACE.MARKETPLACE_ITEM_DETAILS.RHAPSODY_SYSTEM_WORKFLOW',
      ),
      value: workflows,
      tooltip: t('Tooltips:RHAPSODY_SYSTEM_WORKFLOWS'),
      borderVisible: Boolean(setupInstructions || setupUrl),
    },
  ]

  if (setupInstructions || setupUrl) {
    details.push({
      label: t(
        'Admin:MARKETPLACE.MARKETPLACE_ITEM_DETAILS.SET_UP_INSTRUCTIONS',
      ),
      value: (
        <>
          {setupInstructions}
          {setupUrl && (
            <Link
              className={classes.instructionLink}
              href={setupUrl}
              target="blank"
              underline="always"
            >
              {t(
                'Admin:MARKETPLACE.MARKETPLACE_ITEM_DETAILS.SET_UP_INSTRUCTIONS',
              )}
            </Link>
          )}
        </>
      ),
      borderVisible: false,
    })
  }

  const getFieldValue = (field: string) => jsonFields?.[field]?.value || ''

  const handleSave = () => {
    if (validate()) {
      const newJsonParameters = processingJsonParams.map((param) => ({
        ...param,
        value: getFieldValue(param.field),
      }))
      dispatch(
        editMarketplaceItem(
          marketplaceItem.id,
          active.value,
          rhapsodyApiKey.value,
          newJsonParameters,
        ),
      )
    }
  }

  return (
    <Expander
      className={classes.expander}
      expandedItemClass={t(
        'Admin:MARKETPLACE.MARKETPLACE_ITEM_DETAILS.MARKETPLACE_AND_INTEGRATION',
      ).toLowerCase()}
      isSaving={isEditing}
      showButtons={isIFrameWidget}
      onBack={onClose}
      onSaveRequested={handleSave}
    >
      <Grid container direction="column">
        <Grid container p={3} wrap="nowrap">
          <ImageLogo className={classes.logo} logo={logoUrl} name={name} />
          <Grid container direction="column">
            <Text strong variant="body">
              {name}
            </Text>
            <Text strong variant="lowAccent2">{`${t(
              'Common:CATEGORY',
            )}: ${category}`}</Text>
            <Text variant="body2">{description}</Text>
          </Grid>
        </Grid>
        <Grid container direction="column" px={3} py={2}>
          {details.map(
            ({ label, tooltip, value = '', link, borderVisible = true }) => (
              <Grid
                container
                item
                alignItems="center"
                className={classNames(classes.detailsRow, {
                  [classes.detailsRowBorder]: borderVisible,
                })}
                key={label}
                wrap="nowrap"
              >
                <TextWithTooltip
                  strong
                  className={classes.detailsLabel}
                  noIcon={!tooltip}
                  py={1.5}
                  tooltipText={tooltip}
                  variant="body2"
                >
                  {label}
                </TextWithTooltip>
                {link ? (
                  <Link
                    className={classes.link}
                    href={Utils.toExternalUrl(value)}
                    target="blank"
                    underline="always"
                  >
                    {value}
                  </Link>
                ) : Array.isArray(value) ? (
                  <Grid container direction="column" py={1.5}>
                    {value.map((item) => (
                      <Text key={item} variant="body2">
                        {item}
                      </Text>
                    ))}
                  </Grid>
                ) : (
                  <Text py={1.5} variant="body2">
                    {value}
                  </Text>
                )}
              </Grid>
            ),
          )}
        </Grid>
        {(isIFrameWidget || isFullIntegration) && (
          <Grid
            container
            alignItems="center"
            className={classes.credentialsContainer}
            mt={5}
            px={3}
            wrap="nowrap"
          >
            <PuiSwitch
              className={classes.active}
              disabled={isFullIntegration}
              field={active}
              label={active.label}
            />
          </Grid>
        )}
        {isIFrameWidget && apiAccess && (
          <Grid container pt={1} px={3}>
            <PuiTextField
              className={classes.field}
              field={rhapsodyApiKey}
              label={`${rhapsodyApiKey.label}*`}
              type="password"
            />
          </Grid>
        )}
        {isIFrameWidget && Boolean(processingJsonParams.length) && (
          <Grid container px={3} py={2}>
            <Grid container spacing={3}>
              {processingJsonParams.map(({ name: label, field: fieldName }) => (
                <Grid item key={fieldName}>
                  <PuiTextField
                    className={classes.field}
                    field={jsonFields[fieldName]}
                    label={label}
                    type={fieldName === 'password' ? fieldName : 'text'}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Expander>
  )
}

export default MarketPlaceDetails
