import React, { useEffect } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  StepIcon,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  LanguageUtils,
  moment,
  PermissionArea,
  Text,
  TextInteractive,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { Edit as EditIcon } from '@pbt/pbt-ui-components/src/icons'

import AlertLabel from '~/components/common/labels/AlertLabel'
import PatientInfoLabel from '~/components/common/labels/PatientInfoLabel'
import Link from '~/components/common/link/Link'
import LinkedChewyAccountContainer, {
  KyriosAccountType,
} from '~/components/dashboard/link-chewy-account/LinkedChewyAccountContainer'
import { useIsSoapCustomizationEnabled } from '~/components/dashboard/soapV2/utils/useIsSoapCustomizationEnabled'
import { SoapV1StepsUrl } from '~/components/dashboard/soapV2/utils/useSoapPageRedirect'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import WellnessPlanMemberButton from '~/components/dashboard/wellness-plans/WellnessPlanMemberButton'
import { AlertColorLevel } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { SOAPStep } from '~/constants/SOAPStates'
import { useGetIsZoomEnabled } from '~/store/hooks/conferencing'
import { useAlertType, useCreatePatient } from '~/store/hooks/patient'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import {
  getAppointmentId,
  getClientId,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
  getSOAPisFetching,
} from '~/store/reducers/soap'
import {
  getTimetableEvent,
  getTimeTableIsFetchingAppointment,
} from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { SoapTemplateTab } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import { useSummarySteps } from './utils/useSummarySteps'

const useStyles = makeStyles(
  (theme) => ({
    listRoot: {
      position: 'relative',
      width: '100%',
    },
    rightContainer: {
      borderLeft: theme.constants.tableBorderSelected,
      borderRadius: 2,
      boxShadow: '-2px 2px 4px 0 rgba(0,0,0,0.1)',
      minHeight: '100%',
      overflowY: 'auto',
    },
    title: {
      color: theme.colors.title,
      whiteSpace: 'pre-wrap',
    },
    selectedNode: {
      border: theme.constants.activeFieldBorder,
      borderRight: 'none',
      width: 'calc(100% + 1px)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above', 2),
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '-3px 2px 4px 0 rgba(0,0,0,0.1)',
    },
    subTitle: {
      fontWeight: 500,
      fontSize: '1.6rem',
      textDecoration: 'none',
    },
    button: {
      padding: 0,
    },
    icon: {
      width: 17,
      height: 17,
      marginRight: theme.spacing(1),
      color: theme.colors.sideText,
    },
    selectedIcon: {
      color: theme.colors.title,
    },
    emptyIcon: {
      borderRadius: '50%',
      border: `1px solid ${theme.colors.sideText}`,
      color: 'transparent',
    },
    selectedEmptyIcon: {
      border: `1px solid ${theme.colors.title}`,
    },
    listItem: {
      background:
        'linear-gradient(180deg, #FEFFFF 0%, #FEFFFF 84%, #FBFBFB 96%, #EFEDED 100%)',
      padding: theme.spacing(0, 4, 2, 5),
    },
    listItemRight: {
      padding: theme.spacing(2, 3),
    },
    collapse: {
      maxWidth: '100%',
      width: '100%',
    },
    clientPatientContainer: {
      border: theme.constants.tableBorder,
    },
    editIcon: {
      padding: theme.spacing(0.5),
    },
    neuteringLabel: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    planButton: {
      marginBottom: theme.spacing(1),
    },
    alertRoot: {
      minHeight: 24,
      marginBottom: theme.spacing(0.5),
      padding: theme.spacing(0.25, 1, 0.25, 0.5),
      alignSelf: 'flex-start',
    },
    alertIcon: {
      height: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    chewyIcon: {
      marginLeft: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }),
  { name: 'Summary' },
)

export interface SummaryProps {
  className?: string
  editDisabled?: boolean
  expanded?: boolean
  redirectTo?: ((step: SoapV1StepsUrl) => string) | null
  setSelectedTab: (tab: number) => void
  soapTabs?: SoapTemplateTab[]
}

const Summary = ({
  editDisabled,
  expanded,
  className,
  redirectTo,
  setSelectedTab,
  soapTabs,
}: SummaryProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const { createPatient } = useCreatePatient()
  const isSoapFetching = useSelector(getSOAPisFetching)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const isFetchingAppointment = useSelector(getTimeTableIsFetchingAppointment)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const isRichTextNotesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_DIALOG_ADDITIONAL_FIELDS),
  )

  const bundlePermissions = useSelector(getCRUDByArea(PermissionArea.BUNDLE))
  const patientPermissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))

  const isZoomEnabled = useGetIsZoomEnabled()
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  const primaryComplaint = appointment?.notes || ''

  const { fields, reset: resetFields } = useFields([
    {
      name: 'notes',
      label: t('Common:REASON_FOR_VISIT'),
      initialValue: primaryComplaint,
    },
  ])

  useEffect(() => {
    if (!isSoapFetching && !isFetchingAppointment) {
      resetFields()
    }
  }, [isSoapFetching, isFetchingAppointment])

  const [openClientDetailsEditDialog] = useDialog(
    DialogNames.CLIENT_DETAILS_EDIT,
  )
  const [openPatientDialog] = useDialog(DialogNames.PATIENT)

  const { alertsToRender } = useAlertType(patientId)
  const {
    SelectedItemComponent,
    activeStep,
    components,
    expandedItems,
    preparedSteps,
    setExpandedItems,
    selectedItem,
    selectedItemVisible,
    setSelectedItem,
    urlTabName,
  } = useSummarySteps({ expanded, soapTabs })

  const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    appointment?.businessAppointmentType,
  )

  const onCaretClick = (newStep: SOAPStep) => {
    if (expanded) {
      setSelectedItem(newStep)
    } else {
      setExpandedItems({ ...expandedItems, [newStep]: !expandedItems[newStep] })
    }
  }

  const getAppointmentStartEndDateString = () => {
    if (!appointment) {
      return ''
    }

    const isMultiDayAppointment = moment(
      appointment.scheduledEndDatetime,
    ).isAfter(moment(appointment.scheduledStartDatetime), 'date')
    const appointmentStartDate = DateUtils.formatDate(
      appointment.scheduledStartDatetime,
    )
    const appointmentEndDate = DateUtils.formatDate(
      appointment.scheduledEndDatetime,
    )

    return isMultiDayAppointment
      ? `\n${appointmentStartDate} - ${appointmentEndDate}`
      : appointmentStartDate
  }

  const showStartZoomMeeting = isZoomEnabled && appointment?.meetingLink

  const handleOpenZoomMeeting = () => {
    Utils.openInNewTab({ href: appointment?.meetingLink })
  }

  const handleNavigateToStep = (soapStep: SOAPStep) => {
    if (!soapId) {
      return
    }

    if (redirectTo) {
      const stepUrl = soapStep.toLowerCase() as SoapV1StepsUrl
      navigate(redirectTo(stepUrl))
    } else {
      navigate(
        addOriginalBusinessId(
          `/soap/${soapId}/${soapStep.toLowerCase()}`,
          isPatientSharingEnabled ? soapBusinessId : null,
        ),
        { replace: true },
      )
    }
  }

  return (
    <>
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        xs={expanded && selectedItemVisible && SelectedItemComponent ? 5 : 12}
      >
        <Grid
          className={classes.clientPatientContainer}
          mb={2}
          ml={1}
          mr={3}
          mt={1}
          pt={1}
          px={1}
        >
          <TextInteractive
            strong
            className={classes.title}
            isLoading={isFetchingAppointment || isSoapFetching}
            loaderHeight={27}
            loaderWidth={220}
            variant="subheading"
          >
            {appointmentId
              ? appointment
                ? `${appointmentTypeDisplayName} ${getAppointmentStartEndDateString()}`
                : ''
              : // TODO PR-172: add soap creation date when backend will provide it
                `${t('Common:SOAP')}`}
          </TextInteractive>
          {isRichTextNotesEnabled ? (
            <>
              <Text>{t('Common:REASON_FOR_VISIT')}:</Text>
              <NotesTemplateInput
                disabled
                singleLine
                field={fields.notes}
                isLoading={isSoapFetching}
                key={fields.notes.value}
                maxHeight={150}
                minHeight={30}
              />
            </>
          ) : (
            <TextInteractive
              isLoading={isSoapFetching}
              loaderWidth={200}
              variant="body2"
            >
              {primaryComplaint &&
                `${t('Common:REASON_FOR_VISIT')}: ${primaryComplaint}`}
            </TextInteractive>
          )}
          {showStartZoomMeeting && (
            <Text
              link
              mb={1}
              mt={0.5}
              variant="body2"
              onClick={handleOpenZoomMeeting}
            >
              {t('Common:LAUNCH_ZOOM_MEETING')}
            </Text>
          )}
          <Grid container item alignItems="center">
            <Dotdotdot clamp={1}>
              <TextInteractive
                isLoading={isSoapFetching}
                loaderWidth={50}
                variant="body2"
              >
                <Link className={classes.subTitle} to={`/patient/${patientId}`}>
                  {patient?.name || ''}
                </Link>
              </TextInteractive>
            </Dotdotdot>
            <LinkedChewyAccountContainer
              accountType={KyriosAccountType.PATIENT}
              className={classes.chewyIcon}
              clientId={clientId}
              patientId={patientId}
              tooltipPlacement="top-end"
            />
            {!isSoapFetching &&
              (!isCvcRolesEnabled || patientPermissions.update) && (
                <IconButton
                  className={classes.editIcon}
                  size="large"
                  onClick={() => {
                    openPatientDialog({
                      clientId,
                      patientId,
                      createPatient,
                    })
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
          </Grid>
          <Grid container item>
            <Grid container item direction="column">
              {!R.isEmpty(alertsToRender) && (
                <AlertLabel
                  showIcon
                  alertColorId={patient?.alertColorId}
                  alertColorLevel={AlertColorLevel.PATIENT}
                  alertTypes={alertsToRender}
                  clamp={Number.MAX_SAFE_INTEGER}
                  classes={{
                    root: classes.alertRoot,
                    icon: classes.alertIcon,
                  }}
                />
              )}
              <WellnessPlanMemberButton
                className={classes.planButton}
                clientId={clientId}
                isLoading={isSoapFetching}
                patientId={patientId}
              />
            </Grid>
            <PatientInfoLabel fontSize="1.4rem" patientId={patientId} />
          </Grid>
          <Grid container item alignItems="center" mt={1}>
            <Dotdotdot clamp={1}>
              <TextInteractive
                isLoading={isSoapFetching}
                loaderWidth={140}
                variant="body2"
              >
                <Link className={classes.subTitle} to={`/client/${clientId}`}>
                  {Utils.getPersonString(client)}
                </Link>
              </TextInteractive>
            </Dotdotdot>
            <LinkedChewyAccountContainer
              accountType={KyriosAccountType.CLIENT}
              className={classes.chewyIcon}
              clientId={clientId}
              tooltipPlacement="top-end"
            />
            {!isSoapFetching &&
              (!isCvcRolesEnabled || bundlePermissions.update) && (
                <IconButton
                  className={classes.editIcon}
                  size="large"
                  onClick={() => {
                    openClientDetailsEditDialog({
                      clientId,
                    })
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
          </Grid>
          <Grid container alignItems="flex-start" direction="column">
            {client?.alertText && (
              <AlertLabel
                showIcon
                alertColorId={client?.alertColorId}
                alertColorLevel={AlertColorLevel.CLIENT}
                clamp={Number.MAX_SAFE_INTEGER}
                classes={{
                  root: classes.alertRoot,
                  icon: classes.alertIcon,
                }}
                message={client?.alertText}
              />
            )}
          </Grid>
        </Grid>
        <div className={classNames(classes.listRoot, className)}>
          {preparedSteps.map((soapStep) => {
            const component = components[soapStep]

            if (!component) {
              return null
            }

            const { Component, name, visible, soapWidgetId } = component

            const NodeComponent = Component
            const soapTabUrl = soapTabs?.find((tab) =>
              tab.widgets.find(
                (widget) => widget.soapWidgetId === soapWidgetId,
              ),
            )?.url

            const isActiveStep = isSoapCustomizationEnabled
              ? soapTabUrl === urlTabName
              : activeStep === soapStep

            return (
              <Grid
                container
                className={classNames({
                  [classes.selectedNode]:
                    component.visible && expanded && selectedItem === soapStep,
                })}
                direction="column"
                key={component.name}
              >
                <Grid
                  container
                  alignItems="center"
                  pl={3}
                  pr={3.5}
                  wrap="nowrap"
                >
                  <Grid item xs onClick={() => handleNavigateToStep(soapStep)}>
                    <FormControlLabel
                      disableTypography
                      control={
                        <StepIcon
                          classes={{
                            root: classNames(classes.icon, {
                              [classes.selectedIcon]: isActiveStep,
                              [classes.emptyIcon]: !visible,
                              [classes.selectedEmptyIcon]:
                                !visible && isActiveStep,
                            }),
                          }}
                          icon=""
                        />
                      }
                      label={
                        <Text
                          my={0.75}
                          strong={visible}
                          variant={isActiveStep ? 'h4' : 'lowAccent'}
                        >
                          {name}
                        </Text>
                      }
                    />
                  </Grid>
                  <Grid item>
                    {visible && (
                      <IconButton
                        className={classes.button}
                        size="large"
                        onClick={() => visible && onCaretClick(soapStep)}
                      >
                        {expanded ? (
                          <ChevronRight />
                        ) : expandedItems[soapStep] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                {!expanded && (
                  <Collapse
                    className={classes.collapse}
                    in={visible && expandedItems[soapStep]}
                  >
                    {NodeComponent && (
                      <NodeComponent
                        className={classes.listItem}
                        editDisabled={editDisabled}
                        expandedItems={expandedItems}
                        setSelectedTab={setSelectedTab}
                      />
                    )}
                  </Collapse>
                )}
              </Grid>
            )
          })}
        </div>
      </Grid>
      {expanded && selectedItemVisible && SelectedItemComponent && (
        <Grid container item className={classes.rightContainer} xs={7}>
          <SelectedItemComponent
            className={classes.listItemRight}
            expandedItems={expandedItems}
            setSelectedTab={setSelectedTab}
          />
        </Grid>
      )}
    </>
  )
}

export default Summary
