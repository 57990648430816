export default {
  data: [
    {
      id: '123',
      clientId: 'nm1Kg1ZN',
      date: '2020-10-12T12:16:43.645Z',
      description: 'Exam—existing  |  Dr. Alice Stanley',
      invoiceId: 'W12jmJ1A',
      patient: {
        id: 'patientID',
        name: 'Indy',
      },
      type: 'Earn',
      amount: 123,
    },
    {
      id: '345',
      clientId: 'nm1Kg1ZN',
      date: '2020-10-12T12:16:43.645Z',
      description: 'OTC Sale',
      invoiceId: 'W12jmJ1A',
      patient: {
        id: 'patientID',
        name: 'Indy',
      },
      type: 'Redeem',
      amount: 123,
    },
  ],
  totalCount: 2,
}
