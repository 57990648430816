import { ApiError, User } from '@pbt/pbt-ui-components'

import { AtLeast } from '~/types'

import {
  ACCEPT_EULA,
  ACCEPT_EULA_FAILURE,
  ACCEPT_EULA_SUCCESS,
  FETCH_BALANCE,
  FETCH_BALANCE_FAILURE,
  FETCH_BALANCE_SUCCESS,
  LINK_USER_TO_CHEWY_ACCOUNT,
  UNLINK_USER_FROM_CHEWY_ACCOUNT,
  UNLINK_USER_FROM_CHEWY_ACCOUNT_FAILURE,
  UNLINK_USER_FROM_CHEWY_ACCOUNT_SUCCESS,
  UPDATE_AVATAR,
  UPDATE_CURRENT_USER_ON_SERVER,
  UPDATE_CURRENT_USER_ON_SERVER_SUCCESS,
  UPDATE_CURRENT_USERS_BUSINESS_ID,
  UPDATE_USER_SIGNATURE,
  UPDATE_USER_SIGNATURE_FAILURE,
  UPDATE_USER_SIGNATURE_SUCCESS,
  UPDATE_USERS,
  UPDATE_ZN_LAB_PROVIDER_ID,
  UPDATE_ZN_LAB_PROVIDER_ID_FAILURE,
  UPDATE_ZN_LAB_PROVIDER_ID_SUCCESS,
  USERS_UPDATE_FAILURE,
} from './types/users'

export const updateAvatar = (userId: string, blob: Blob) => ({
  type: UPDATE_AVATAR,
  userId,
  blob,
})
export const updateUsers = (users: Record<string, Partial<User>>) => ({
  type: UPDATE_USERS,
  users,
})
export const updateUser = (user: AtLeast<User, 'id'>, diff = {}) =>
  updateUsers({ [user.id]: { ...user, ...diff } })
/** @deprecated use useUpdateCurrentUsersBusinessId to sync with url */
export const updateCurrentUsersBusinessId = (
  userId: string,
  businessId: string,
) => ({
  type: UPDATE_CURRENT_USERS_BUSINESS_ID,
  userId,
  businessId,
})
export const usersUpdateFailure = (error: ApiError) => ({
  type: USERS_UPDATE_FAILURE,
  error,
})
export const updateCurrentUserOnServer = (diff: Partial<User>) => ({
  type: UPDATE_CURRENT_USER_ON_SERVER,
  diff,
})
export const updateCurrentUserOnServerSuccess = () => ({
  type: UPDATE_CURRENT_USER_ON_SERVER_SUCCESS,
})

export const acceptEula = () => ({ type: ACCEPT_EULA })
export const acceptEulaFailure = (error: ApiError) => ({
  type: ACCEPT_EULA_FAILURE,
  error,
})
export const acceptEulaSuccess = () => ({ type: ACCEPT_EULA_SUCCESS })

export const updateUserSignature = (userId: string, signature: string) => ({
  type: UPDATE_USER_SIGNATURE,
  userId,
  signature,
})
export const updateUserSignatureFailure = (error: ApiError) => ({
  type: UPDATE_USER_SIGNATURE_FAILURE,
  error,
})
export const updateUserSignatureSuccess = (
  userId: string,
  signatureUrl: string,
) => ({
  type: UPDATE_USER_SIGNATURE_SUCCESS,
  userId,
  signatureUrl,
})

export const updateZnLabProviderId = (
  memberId: string,
  providerId: string,
) => ({
  type: UPDATE_ZN_LAB_PROVIDER_ID,
  memberId,
  providerId,
})

export const updateZnLabProviderIdSuccess = (
  memberId: string,
  providerId: string,
) => ({
  type: UPDATE_ZN_LAB_PROVIDER_ID_SUCCESS,
  memberId,
  providerId,
})

export const updateZnLabProviderIdFailure = (error: ApiError) => ({
  type: UPDATE_ZN_LAB_PROVIDER_ID_FAILURE,
  error,
})

export const fetchBalance = (clientId: string) => ({
  type: FETCH_BALANCE,
  clientId,
})
export const fetchBalanceFailure = (error: ApiError) => ({
  type: FETCH_BALANCE_FAILURE,
  error,
})
export const fetchBalanceSuccess = (
  balanceAmount: number,
  clientId: string,
) => ({
  type: FETCH_BALANCE_SUCCESS,
  balanceAmount,
  clientId,
})

export const unlinkChewyAccount = (clientId: string) => ({
  type: UNLINK_USER_FROM_CHEWY_ACCOUNT,
  clientId,
})

export const unlinkChewyAccountSuccess = (clientId: string) => ({
  type: UNLINK_USER_FROM_CHEWY_ACCOUNT_SUCCESS,
  clientId,
})

export const unlinkChewyAccountFailure = (
  error: ApiError,
  clientId: string,
) => ({
  type: UNLINK_USER_FROM_CHEWY_ACCOUNT_FAILURE,
  clientId,
  error,
})

export const linkUserToChewyAccount = (clientId: string) => ({
  type: LINK_USER_TO_CHEWY_ACCOUNT,
  clientId,
})
