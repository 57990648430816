import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { matchImageExtension } from '~/constants/extensions'
import { deleteFile } from '~/store/actions/soap'
import { IdentifiedAttachmentOrigin } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useDialog from '~/utils/useDialog'

import AttachmentFileControlButtons from '../inputs/attachment/AttachmentFileControlButtons'
import AttachmentPreview from './AttachmentPreview'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderBottom: theme.constants.tabBorder,
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    attachmentContainer: {
      cursor: 'pointer',
    },
  }),
  { name: 'InlineAttachment' },
)

export interface InlineAttachmentProps {
  attachment: IdentifiedAttachmentOrigin
  hideDelete?: boolean
  onDelete?: (id: string) => void
  onEmail: () => void
  onPrint: () => void
}

const InlineAttachment = ({
  attachment,
  onDelete,
  onPrint,
  onEmail,
  hideDelete,
}: InlineAttachmentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { soapId } = useParams()
  const { t } = useTranslation('Common')

  const { extension = '', name } = attachment
  const isImage = matchImageExtension(extension)

  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const handlePreview = () => {
    openDocumentDialog({
      previewOnly: true,
      document: attachment,
      PreviewProps: {
        hideTopButtons: Boolean(soapId),
        view: true,
        isSOAP: Boolean(soapId),
        showChangeFile: false,
      },
      step: DocumentDialogStates.PREVIEW,
    })
  }

  const onDeleteAttachment = () => {
    if (typeof onDelete === 'function') {
      onDelete(attachment.id)
    } else {
      openDeleteAttachmentAlert({
        message: getDeleteConfirmMessage('attachment'),
        cancelButtonText: t('Common:NO_KEEP'),
        okButtonText: t('Common:YES_DELETE'),
        onCancel: () => closeDeleteAttachmentAlert(),
        onOk: () => {
          dispatch(deleteFile(attachment.id))
          closeDeleteAttachmentAlert()
        },
      })
    }
  }

  return (
    <Grid container item className={classes.root} py={0.5} wrap="nowrap">
      <Grid
        item
        className={classNames({
          [classes.attachmentContainer]: isImage,
        })}
        onClick={handlePreview}
      >
        <AttachmentPreview attachment={attachment} variant="long" />
      </Grid>
      <Grid
        container
        item
        xs
        alignItems="center"
        justifyContent="space-between"
        ml={2}
        wrap="nowrap"
      >
        <Grid item xs>
          <Text strong>{`${name}.${extension}`}</Text>
        </Grid>
        <Grid item>
          <AttachmentFileControlButtons
            file={attachment}
            hideDelete={hideDelete}
            onDeleteRequested={onDeleteAttachment}
            onEmailRequested={onEmail}
            onPreviewRequested={handlePreview}
            onPrintRequested={onPrint}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InlineAttachment
