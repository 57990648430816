import { ColorVariant } from './colors'

export enum ImagingOrderStatus {
  ORDERED = 'Ordered',
  PENDING = 'Pending',
  IN_PROGRESS = 'In progress',
  IMAGE_RECEIVED = 'Image(s) received',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export const ImagingOrderStatusColor = {
  [ImagingOrderStatus.CANCELLED]: ColorVariant.DISABLED,
  [ImagingOrderStatus.COMPLETED]: ColorVariant.SUCCESS,
  [ImagingOrderStatus.IMAGE_RECEIVED]: ColorVariant.SUCCESS,
}

export const ImagingVendorName = {
  IDEXX: 'Idexx',
}

export const ImagingVendorLabel = {
  [ImagingVendorName.IDEXX]: 'Idexx WebPacs',
}

export const ImagingStatusColor: Record<string, ColorVariant> = {
  [ImagingOrderStatus.CANCELLED]: ColorVariant.DISABLED,
  [ImagingOrderStatus.IMAGE_RECEIVED]: ColorVariant.SUCCESS,
  [ImagingOrderStatus.COMPLETED]: ColorVariant.SUCCESS,
}
