import { all, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  fetchClientPreferences,
  fetchClientPreferencesFailure,
  fetchClientPreferencesSuccess,
  saveClientPreferences,
  saveClientPreferencesFailure,
  saveClientPreferencesSuccess,
} from '../duck/clientPreferences'
import requestAPI from './utils/requestAPI'

export function* fetchClientPreferencesSaga({
  payload,
}: ReturnType<typeof fetchClientPreferences>) {
  try {
    const { preference: clientPreferences } = yield* requestAPI(
      API.fetchClientPreferences,
      payload.id,
    )

    yield put(
      fetchClientPreferencesSuccess({
        clientId: payload.id,
        preferences: clientPreferences,
      }),
    )
  } catch (error) {
    yield put(fetchClientPreferencesFailure({ error: error as ApiError }))
  }
}

export function* saveClientPreferencesSaga({
  payload,
}: ReturnType<typeof saveClientPreferences>) {
  try {
    const result = yield* requestAPI(API.saveClientPreferences, {
      ...payload,
    })
    yield put(
      saveClientPreferencesSuccess({
        clientId: payload.clientId,
        preferences: result,
      }),
    )
  } catch (error) {
    yield put(saveClientPreferencesFailure({ error: error as ApiError }))
  }
}

function* watchFetchClientPreferences() {
  yield takeLeading(fetchClientPreferences.type, fetchClientPreferencesSaga)
}

function* watchSaveClientPreferences() {
  yield takeLatest(saveClientPreferences.type, saveClientPreferencesSaga)
}

export default function* clientPreferencesSaga() {
  yield all([watchFetchClientPreferences(), watchSaveClientPreferences()])
}
