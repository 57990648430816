import React from 'react'

import { UserSelectFilterScope } from '../inputs/UserSelect'
import PersonFilter, { PersonFilterProps } from './PersonFilter'

export interface DoctorFilterProps
  extends Omit<PersonFilterProps, 'filterScope'> {}

const DoctorFilter = (props: DoctorFilterProps) => (
  <PersonFilter {...props} filterScope={UserSelectFilterScope.Doctors} />
)

export default DoctorFilter
