import { useSelector } from 'react-redux'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { getInventoryOnHandStatus } from '~/store/reducers/constants'
import { Variation } from '~/types'

const OnHandStatusCell = (item: Variation) => {
  const InventoryOnHandStatus = useSelector(getInventoryOnHandStatus)

  return LanguageUtils.getConstantTranslatedName(
    item.onHandStatusId,
    InventoryOnHandStatus,
  )
}

export default OnHandStatusCell
