import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PermissionArea,
  PuiDialog,
  Text,
} from '@pbt/pbt-ui-components'

import { PetProfileMatch } from '~/api/graphql/generated/types'
import SnackNotificationType from '~/constants/SnackNotificationType'
import {
  getIsPetsLoading,
  getMatchedPets,
  getMatchedPetsIds,
  getUnmatchedPatients,
  getUnmatchedPets,
  getUnmatchedPetsIds,
  linkPets,
} from '~/store/duck/chewyPets'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getUserFirstName } from '~/store/reducers/users'

import { ChewyPetSelect } from './ChewyPetSelect'
import { PatientSelect } from './PatientSelect'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1200,
      maxWidth: 1200,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    dialogTitle: {
      borderBottom: 'none',
    },
    table: {
      border: theme.constants.tableBorder,
    },
    cell: {
      borderRight: theme.constants.tableBorder,
      padding: theme.spacing(0, 2),
      width: 300,
      height: 50,
      maxWidth: 300,
    },
    button: {
      width: 150,
    },
  }),
  { name: 'MatchPetPatientDialog' },
)

interface MatchPetPatientDialogProps extends BasePuiDialogProps {
  clientId: string
}

export const MatchPetPatientDialog = ({
  open,
  onClose,
  clientId,
}: MatchPetPatientDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Dialogs', 'Common'])
  const dispatch = useDispatch()
  const clientName = useSelector(getUserFirstName(clientId))
  const isLoading = useSelector(getIsPetsLoading)
  const unmatchedPetsIds = useSelector(getUnmatchedPetsIds)
  const matchedPetsIds = useSelector(getMatchedPetsIds)

  const unmatchedPets = useSelector(getUnmatchedPets)
  const matchedPets = useSelector(getMatchedPets)
  const unmatchedPatients = useSelector(getUnmatchedPatients)
  const { update: patientUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.PATIENT),
  )

  const defaultMatchedData = useMemo(
    () =>
      matchedPetsIds.map((id) => ({
        patientId: id,
        chewyPetId: id,
        defaultPatientId: id,
        defaultChewyPetId: id,
      })),
    [matchedPetsIds],
  )
  const defaultUnmatchedData = useMemo(
    () =>
      unmatchedPetsIds.map(() => ({
        patientId: undefined,
        chewyPetId: undefined,
        defaultPatientId: undefined,
        defaultChewyPetId: undefined,
      })),
    [unmatchedPetsIds],
  )

  const [tableData, setTableData] = useState([
    ...defaultMatchedData,
    ...defaultUnmatchedData,
  ])

  const handlePatientSelect = (index: number, patientId: string) => {
    const updatedData = [...tableData]
    updatedData[index].patientId = patientId
    setTableData(updatedData)
  }

  const handleChewyPetSelect = (index: number, chewyPetId: string) => {
    const updatedData = [...tableData]
    updatedData[index].chewyPetId = chewyPetId
    setTableData(updatedData)
  }

  const submitHandler = () => {
    const petPatientMatches = tableData
      .map((item) => {
        if (item.chewyPetId && item.patientId) {
          const chewyPet =
            unmatchedPets[item.chewyPetId] ||
            matchedPets[item.chewyPetId].chewyPet
          const rhapsodyPatient =
            unmatchedPatients[item.patientId] ||
            matchedPets[item.patientId].rhapsodyPatient
          return {
            chewyPet: {
              name: chewyPet.name,
            },
            rhapsodyPatient: {
              name: rhapsodyPatient.name,
              rhapsodyId: rhapsodyPatient.rhapsodyId,
            },
          }
        }
        return null
      })
      .filter(Boolean) as PetProfileMatch[]

    dispatch(
      linkPets({
        clientId,
        petPatientMatches,
      }),
    )
    if (onClose) {
      onClose()
    }
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    dispatch(
      addUiNotification({
        id: uuid(),
        type: SnackNotificationType.PATIENT_MATCH_INCOMPLETE,
        params: {
          clientId,
        },
      }),
    )
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: patientUpdatePermissions ? submitHandler : undefined,
      }}
      classes={{
        dialogTitle: classes.dialogTitle,
        paper: classes.paper,
      }}
      hasUnsavedChanges={R.T}
      open={open}
      title={t('Dialogs:MATCH_PET_PATIENT_DIALOG.ACCOUNT_HAS_BEEN_CONNECTED', {
        clientName,
      })}
      onClose={handleClose}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" overflow="hidden" py={2}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Text pb={1} pl={2}>
            {t('Dialogs:MATCH_PET_PATIENT_DIALOG.IF_NO_CHEWY_MATCH_IS_FOUND')}
          </Text>
          <Box p={2}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Text strong variant="lowAccent2">
                      {t('Dialogs:MATCH_PET_PATIENT_DIALOG.RHAPSODY_PATIENT')}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text strong variant="lowAccent2">
                      {t('Dialogs:MATCH_PET_PATIENT_DIALOG.CHEWY_PET')}
                    </Text>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell className={classes.cell}>
                      <PatientSelect
                        chewyPetId={item.chewyPetId}
                        clientId={clientId}
                        defaultId={item.defaultPatientId}
                        id={item.patientId}
                        onSelect={(patientId) =>
                          handlePatientSelect(index, patientId)
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <ChewyPetSelect
                        defaultId={item.defaultChewyPetId}
                        id={item.chewyPetId}
                        onSelect={(patientId) =>
                          handleChewyPetSelect(index, patientId)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box pb={2} pl={2}>
            <ButtonWithLoader
              className={classes.button}
              color="primary"
              disabled={!patientUpdatePermissions}
              onClick={patientUpdatePermissions ? submitHandler : undefined}
            >
              {t('Common:FINISH_ACTION')}
            </ButtonWithLoader>
          </Box>
        </>
      )}
    </PuiDialog>
  )
}
