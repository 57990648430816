import React from 'react'
import { Trans } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { ClassesType, Text, TextWithTooltip } from '@pbt/pbt-ui-components'

import {
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import DialogNames from '~/constants/DialogNames'
import InvoiceType from '~/constants/InvoiceType'
import { useOpenInvoice } from '~/store/hooks/finance'
import { Invoice } from '~/types'
import { isRefundInvoice } from '~/utils/refundUtils'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    container: {},
    refundInvoice: {
      cursor: 'pointer',
      whiteSpace: 'break-spaces',
    },
    tooltipContainer: {
      flexWrap: 'wrap',
    },
  }),
  { name: 'InvoiceTableInvoiceNoCell' },
)

interface InvoiceTableInvoiceNoCellProps {
  classes?: ClassesType<typeof useStyles>
  invoice: Invoice | GraphqlInvoice | RefundInvoice
}

export const InvoiceTableInvoiceNoCell = ({
  classes: classesProp,
  invoice,
}: InvoiceTableInvoiceNoCellProps) => {
  const classes = useStyles({ classes: classesProp })

  const { id: invoiceId, invoiceNo } = invoice
  const isRefund = isRefundInvoice(invoice.invoiceNo)
  const clientId = 'clientId' in invoice ? invoice.clientId : invoice.client?.id

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog, false)

  const goToInvoice = (invoiceIdParam: string) => {
    openInvoice({
      clientId,
      invoiceId: invoiceIdParam,
      isEstimate: false,
      chargesEntityType: InvoiceType.INVOICE,
      id: invoiceIdParam,
    })
  }

  if (isRefund) {
    const refundInvoice = invoice as RefundInvoice
    const refundOriginalInvoice = refundInvoice.originalInvoice

    const navigateToRefundInvoice = () => {
      window.open(`/refund/${refundInvoice.id}`, '_self')
    }

    return (
      <TextWithTooltip
        underline
        ContainerProps={{ className: classes.tooltipContainer }}
        className={classNames(classes.container, classes.refundInvoice)}
        tooltipText={
          <Trans
            inline
            components={{
              span: <span />,
              linkToInvoice: (
                <Text
                  link
                  component="span"
                  variant="body2"
                  onClick={() => goToInvoice(refundOriginalInvoice.id)}
                />
              ),
            }}
            i18nKey="Invoices:REFUNDED_FROM_INVOICE_NO"
            values={{ invoiceNo: refundOriginalInvoice.invoiceNo }}
          />
        }
        variant="body2"
        onClick={navigateToRefundInvoice}
      >
        {invoiceNo}
      </TextWithTooltip>
    )
  }

  return (
    <Text
      link
      className={classes.container}
      variant="body2"
      onClick={() => goToInvoice(invoiceId)}
    >
      {invoiceNo}
    </Text>
  )
}
