import { all, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  FETCH_REPORT_CARD_CONFIG,
  FETCH_REPORT_CARD_EMAIL_TEMPLATE,
  FETCH_REPORT_CARD_PRINT_TEMPLATE,
  fetchReportCardConfig,
  fetchReportCardConfigFailure,
  fetchReportCardConfigSuccess,
  fetchReportCardEmailTemplate,
  fetchReportCardEmailTemplateFailure,
  fetchReportCardEmailTemplateSuccess,
  fetchReportCardPrintTemplate,
  fetchReportCardPrintTemplateFailure,
  fetchReportCardPrintTemplateSuccess,
} from '../duck/reportCard'
import requestAPI from './utils/requestAPI'

export function* fetchReportCardConfigSaga({
  soapId,
}: ReturnType<typeof fetchReportCardConfig>) {
  try {
    const config = yield* requestAPI(API.loadReportCardConfig, soapId)
    yield put(fetchReportCardConfigSuccess(config))
  } catch (error) {
    yield put(fetchReportCardConfigFailure(error as ApiError))
  }
}

export function* fetchReportCardEmailTemplateSaga({
  soapId,
  config,
}: ReturnType<typeof fetchReportCardEmailTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadReportCardEmailTemplate,
      soapId,
      config,
    )
    yield put(fetchReportCardEmailTemplateSuccess(html))
  } catch (error) {
    yield put(fetchReportCardEmailTemplateFailure(error as ApiError))
  }
}

export function* fetchReportCardPrintTemplateSaga({
  soapId,
  config,
}: ReturnType<typeof fetchReportCardPrintTemplate>) {
  try {
    const html = yield* requestAPI(
      API.loadReportCardPrintTemplate,
      soapId,
      config,
    )
    yield put(fetchReportCardPrintTemplateSuccess(html))
  } catch (error) {
    yield put(fetchReportCardPrintTemplateFailure(error as ApiError))
  }
}

function* watchLoadReportCardConfig() {
  yield takeLeading(FETCH_REPORT_CARD_CONFIG, fetchReportCardConfigSaga)
}

function* watchLoadReportCardEmailTemplate() {
  yield takeLatest(
    FETCH_REPORT_CARD_EMAIL_TEMPLATE,
    fetchReportCardEmailTemplateSaga,
  )
}

function* watchLoadReportCardPrintTemplate() {
  yield takeLatest(
    FETCH_REPORT_CARD_PRINT_TEMPLATE,
    fetchReportCardPrintTemplateSaga,
  )
}

export default function* reportCardSaga() {
  yield all([
    watchLoadReportCardConfig(),
    watchLoadReportCardEmailTemplate(),
    watchLoadReportCardPrintTemplate(),
  ])
}
