import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { BasePuiDialogProps } from '@pbt/pbt-ui-components'

import PrintLabelDialog from '~/components/common/dialog/PrintLabelDialog'
import { DymoLabelType } from '~/constants/dymo'
import i18n from '~/locales/i18n'
import { getCurrentBusiness } from '~/store/reducers/auth'

// @ts-ignore
import { buildPracticeAddressLabel } from './labelTemplates'

const practiceAddressLabelFields = [
  'name',
  'address',
  'city',
  'state',
  'zipcode',
  'phone',
]

interface PrintPracticeAddressLabelDialogProps extends BasePuiDialogProps {}

const PrintPracticeAddressLabelDialog = ({
  onClose,
  open,
}: PrintPracticeAddressLabelDialogProps) => {
  const business = useSelector(getCurrentBusiness)

  const labelOptions = R.pick(practiceAddressLabelFields, business || {})
  const labelLines = buildPracticeAddressLabel(labelOptions)

  return (
    <PrintLabelDialog
      labelLines={labelLines}
      labelType={DymoLabelType.PRACTICE_ADDRESS}
      open={open}
      title={`${i18n.t('Common:PRACTICE_ADDRESS_LABEL')}`}
      onClose={onClose}
    />
  )
}

export default PrintPracticeAddressLabelDialog
