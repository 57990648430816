import React, { forwardRef } from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    rootEnabled: {
      cursor: 'pointer',
    },
    toggleIcon: {
      color: theme.colors.toggleIcon,
    },
  }),
  { name: 'ToggleLabel' },
)

export interface ToggleLabelProps extends GridProps {
  disabled?: boolean
  icon?: React.ReactNode
  text: string
}

const ToggleLabel = forwardRef<HTMLDivElement, ToggleLabelProps>(
  function ToggleLabel({ icon, text, disabled, ...rest }, ref) {
    const classes = useStyles()

    return (
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.root, {
          [classes.rootEnabled]: !disabled,
        })}
        ref={ref}
        wrap="nowrap"
        {...rest}
      >
        <Text display="inline" variant={disabled ? 'lowAccent2' : 'body2'}>
          {text}
        </Text>
        {icon}
        {!disabled && <Toggle className={classes.toggleIcon} />}
      </Grid>
    )
  },
)

export default ToggleLabel
