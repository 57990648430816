import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateUtils, moment } from '@pbt/pbt-ui-components'

import {
  LandingType,
  LandingWidgetName,
  WidgetWidthType,
} from '~/constants/landingConstants'
import i18n from '~/locales/i18n'
import { getWidgetData, getWidgetDataIsLoading } from '~/store/duck/landing'
import { getMultipleTimeTrackerEntities } from '~/store/duck/timeTracker'
import { getCurrentUserId } from '~/store/reducers/auth'
import { TimeEntity, WidgetColumn } from '~/types'
import { getDateString, getHoursBetween } from '~/utils/time'

import LandingWidget, { LandingWidgetProps } from '../LandingWidget'
import TimeTrackingContent from './TimeTrackingContent'
import TimeTrackingHeader from './TimeTrackingHeader'

const columns: WidgetColumn<TimeEntity>[] = [
  {
    id: 'dateTime',
    label: i18n.t('Common:DATE_TIME'),
    prop: ({ clockinDatetime, clockoutDatetime }) =>
      clockoutDatetime
        ? getDateString(moment(clockinDatetime), moment(clockoutDatetime))
        : `${getDateString(moment(clockinDatetime))}`,
    width: 3,
  },
  {
    id: 'clockIn',
    label: i18n.t('Time:LABEL.CLOCK_IN'),
    prop: ({ clockinDatetime }) => DateUtils.formatTime(clockinDatetime),
    width: 2,
  },
  {
    id: 'clockOut',
    label: i18n.t('Time:LABEL.CLOCK_OUT'),
    prop: ({ clockoutDatetime }) =>
      clockoutDatetime ? DateUtils.formatTime(clockoutDatetime) : undefined,
    width: 2,
  },
  {
    id: 'reason',
    label: i18n.t('Common:REASON'),
    prop: ({ clockoutReason }) =>
      clockoutReason ? clockoutReason.name : undefined,
    width: 3,
  },
  {
    id: 'hourOther',
    label: i18n.t('Constants:TIME_UNITS.HOUR_OTHER'),
    prop: (item) =>
      item.clockoutDatetime
        ? getHoursBetween(item.clockinDatetime, item.clockoutDatetime)
        : undefined,
    width: 1,
  },
]

interface TimeTrackingWidgetProps extends LandingWidgetProps<TimeEntity> {
  rowsCount?: number
  widthType?: WidgetWidthType
}

const TimeTrackingWidget = ({
  widthType = WidgetWidthType.FULL_WIDTH,
  rowsCount = 10,
  ...rest
}: TimeTrackingWidgetProps) => {
  const navigate = useNavigate()
  const userId = useSelector(getCurrentUserId)
  const { t } = useTranslation('Landing')

  const timeTrackingIds = useSelector(
    getWidgetData(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.TIME_TRACKING,
    ),
  )
  const isLoading = useSelector(
    getWidgetDataIsLoading(
      LandingType.LANDING_DASHBOARD,
      LandingWidgetName.TIME_TRACKING,
    ),
  )
  const timeTrackingEntities = useSelector(
    getMultipleTimeTrackerEntities(timeTrackingIds),
  )

  const handleNavigateToItemDetails = () => {
    navigate(`/admin/general/members/${userId}`)
  }

  return (
    <LandingWidget
      canExpand={false}
      canNavigateToDetails={false}
      columns={columns}
      component={TimeTrackingContent}
      data={timeTrackingEntities}
      headerComponent={TimeTrackingHeader}
      isLoading={isLoading}
      navigateToItemDetailsTooltip={t(
        'Landing:TIME_TRACKING_WIDGET.GO_TO_PAGE',
      )}
      rowsCount={rowsCount}
      showSettings={false}
      widthType={widthType}
      onNavigateToItemDetails={handleNavigateToItemDetails}
      {...rest}
    />
  )
}

export default TimeTrackingWidget
