export const APPLY_PAYMENT_TO_INVOICE = 'payments/APPLY_PAYMENT_TO_INVOICE'
export const APPLY_PAYMENT_TO_INVOICE_FAILURE =
  'payments/APPLY_PAYMENT_TO_INVOICE_FAILURE'
export const APPLY_PAYMENT_TO_INVOICE_SUCCESS =
  'payments/APPLY_PAYMENT_TO_INVOICE_SUCCESS'

export const CHARGE_PAYMENT = 'payments/CHARGE_PAYMENT'
export const CHARGE_PAYMENT_FAILURE = 'payments/CHARGE_PAYMENT_FAILURE'
export const CHARGE_PAYMENT_SUCCESS = 'payments/CHARGE_PAYMENT_SUCCESS'

export const CLEAR_CLIENT_BILLING_ADDRESSES =
  'payments/CLEAR_CLIENT_BILLING_ADDRESSES'
export const CLEAR_PROCESSED_BILLING_ADDRESS =
  'payments/CLEAR_PROCESSED_BILLING_ADDRESS'
export const CLEAR_RHAPSODY_GO_ERROR = 'payments/CLEAR_RHAPSODY_GO_ERROR'
export const CLEAR_TRANSACTION_INFO = 'payments/CLEAR_TRANSACTION_INFO'

export const CREATE_BILLING_ADDRESS = 'payments/CREATE_BILLING_ADDRESS'
export const CREATE_BILLING_ADDRESS_FAILURE =
  'payments/CREATE_BILLING_ADDRESS_FAILURE'
export const CREATE_BILLING_ADDRESS_SUCCESS =
  'payments/CREATE_BILLING_ADDRESS_SUCCESS'

export const CREATE_PAYMENT = 'payments/CREATE_PAYMENT'
export const CREATE_PAYMENT_FAILURE = 'payments/CREATE_PAYMENT_FAILURE'
export const CREATE_PAYMENT_SUCCESS = 'payments/CREATE_PAYMENT_SUCCESS'

export const CREATE_MANUAL_PAYMENT = 'payments/CREATE_MANUAL_PAYMENT'
export const CREATE_MANUAL_PAYMENT_FAILURE =
  'payments/CREATE_MANUAL_PAYMENT_FAILURE'
export const CREATE_MANUAL_PAYMENT_SUCCESS =
  'payments/CREATE_MANUAL_PAYMENT_SUCCESS'

export const CREATE_PAYMENT_TRANSACTION = 'payments/CREATE_PAYMENT_TRANSACTION'
export const CREATE_PAYMENT_TRANSACTION_FAILURE =
  'payments/CREATE_PAYMENT_TRANSACTION_FAILURE'
export const CREATE_PAYMENT_TRANSACTION_SUCCESS =
  'payments/CREATE_PAYMENT_TRANSACTION_SUCCESS'

export const EDIT_GO_PAYMENT = 'payments/EDIT_GO_PAYMENT'
export const EDIT_GO_PAYMENT_FAILURE = 'payments/EDIT_GO_PAYMENT_FAILURE'
export const EDIT_GO_PAYMENT_SUCCESS = 'payments/EDIT_GO_PAYMENT_SUCCESS'

export const EDIT_PAYMENT = 'payments/EDIT_PAYMENT'
export const EDIT_PAYMENT_FAILURE = 'payments/EDIT_PAYMENT_FAILURE'
export const EDIT_PAYMENT_SUCCESS = 'payments/EDIT_PAYMENT_SUCCESS'

export const FETCH_BILLING_ADDRESSES = 'payments/FETCH_BILLING_ADDRESSES'
export const FETCH_BILLING_ADDRESSES_FAILURE =
  'payments/FETCH_BILLING_ADDRESSES_FAILURE'
export const FETCH_BILLING_ADDRESSES_SUCCESS =
  'payments/FETCH_BILLING_ADDRESSES_SUCCESS'

export const FETCH_GO_PAYMENT = 'payments/FETCH_GO_PAYMENT'
export const FETCH_GO_PAYMENT_FAILURE = 'payments/FETCH_GO_PAYMENT_FAILURE'
export const FETCH_GO_PAYMENT_SUCCESS = 'payments/FETCH_GO_PAYMENT_SUCCESS'

export const FETCH_PAYMENT = 'payments/FETCH_PAYMENT'
export const FETCH_PAYMENT_FAILURE = 'payments/FETCH_PAYMENT_FAILURE'
export const FETCH_PAYMENT_SUCCESS = 'payments/FETCH_PAYMENT_SUCCESS'

export const GET_TRANSACTION_STATUS = 'payments/GET_TRANSACTION_STATUS'
export const GET_TRANSACTION_STATUS_FAILURE =
  'payments/GET_TRANSACTION_STATUS_FAILURE'
export const GET_TRANSACTION_STATUS_SUCCESS =
  'payments/GET_TRANSACTION_STATUS_SUCCESS'

export const LINK_UNAPPLIED_PAYMENT = 'payments/LINK_UNAPPLIED_PAYMENT'
export const LINK_UNAPPLIED_PAYMENT_FAILURE =
  'payments/LINK_UNAPPLIED_PAYMENT_FAILURE'
export const LINK_UNAPPLIED_PAYMENT_SUCCESS =
  'payments/LINK_UNAPPLIED_PAYMENT_SUCCESS'

export const LINK_UNAPPLIED_PAYMENTS = 'payments/LINK_UNAPPLIED_PAYMENTS'
export const LINK_UNAPPLIED_PAYMENTS_FAILURE =
  'payments/LINK_UNAPPLIED_PAYMENTS_FAILURE'
export const LINK_UNAPPLIED_PAYMENTS_SUCCESS =
  'payments/LINK_UNAPPLIED_PAYMENTS_SUCCESS'

export const REFUND_RHAPSODY_GO_TRANSACTION =
  'payments/REFUND_RHAPSODY_GO_TRANSACTION'
export const REFUND_RHAPSODY_GO_TRANSACTION_FAILURE =
  'payments/REFUND_RHAPSODY_GO_TRANSACTION_FAILURE'
export const REFUND_RHAPSODY_GO_TRANSACTION_SUCCESS =
  'payments/REFUND_RHAPSODY_GO_TRANSACTION_SUCCESS'

export const REFUND_RHAPSODY_GO_STRIPE_TRANSACTION =
  'payments/REFUND_RHAPSODY_GO_STRIPE_TRANSACTION'
export const REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE =
  'payments/REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE'
export const REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS =
  'payments/REFUND_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS'

export const RHAPSODY_GO_AUTHORIZATION = 'payments/RHAPSODY_GO_AUTHORIZATION'
export const RHAPSODY_GO_AUTHORIZATION_FAILURE =
  'payments/RHAPSODY_GO_AUTHORIZATION_FAILURE'
export const RHAPSODY_GO_AUTHORIZATION_SUCCESS =
  'payments/RHAPSODY_GO_AUTHORIZATION_SUCCESS'

export const RHAPSODY_GO_APPLY_PAYMENT = 'payments/RHAPSODY_GO_APPLY_PAYMENT'
export const RHAPSODY_GO_APPLY_PAYMENT_FAILURE =
  'payments/RHAPSODY_GO_APPLY_PAYMENT_FAILURE'
export const RHAPSODY_GO_APPLY_PAYMENT_SUCCESS =
  'payments/RHAPSODY_GO_APPLY_PAYMENT_SUCCESS'

export const SETTLE_GO_TRANSACTION = 'payments/SETTLE_GO_TRANSACTION'
export const SETTLE_GO_TRANSACTION_FAILURE =
  'payments/SETTLE_GO_TRANSACTION_FAILURE'
export const SETTLE_GO_TRANSACTION_SUCCESS =
  'payments/SETTLE_GO_TRANSACTION_SUCCESS'

export const UNLINK_PAYMENT_TO_INVOICE = 'payments/UNLINK_PAYMENT_TO_INVOICE'
export const UNLINK_PAYMENT_TO_INVOICE_FAILURE =
  'payments/UNLINK_PAYMENT_TO_INVOICE_FAILURE'
export const UNLINK_PAYMENT_TO_INVOICE_SUCCESS =
  'payments/UNLINK_PAYMENT_TO_INVOICE_SUCCESS'

export const UPDATE_BILLING_ADDRESS = 'payments/UPDATE_BILLING_ADDRESS'
export const UPDATE_BILLING_ADDRESS_FAILURE =
  'payments/UPDATE_BILLING_ADDRESS_FAILURE'
export const UPDATE_BILLING_ADDRESS_SUCCESS =
  'payments/UPDATE_BILLING_ADDRESS_SUCCESS'

export const UPDATE_GO_PAYMENTS = 'payments/UPDATE_GO_PAYMENTS'
export const UPDATE_GO_STRIPE_PAYMENTS = 'payments/UPDATE_GO_STRIPE_PAYMENTS'
export const UPDATE_PAYMENTS = 'payments/UPDATE_PAYMENTS'
export const UPDATE_PENDING_PAYMENT_STATE =
  'payments/UPDATE_PENDING_PAYMENT_STATE'

export const VOID_RHAPSODY_GO_TRANSACTION =
  'payments/VOID_RHAPSODY_GO_TRANSACTION'
export const VOID_RHAPSODY_GO_TRANSACTION_FAILURE =
  'payments/VOID_RHAPSODY_GO_TRANSACTION_FAILURE'
export const VOID_RHAPSODY_GO_TRANSACTION_SUCCESS =
  'payments/VOID_RHAPSODY_GO_TRANSACTION_SUCCESS'

export const VOID_RHAPSODY_GO_STRIPE_TRANSACTION =
  'payments/VOID_RHAPSODY_GO_STRIPE_TRANSACTION'
export const VOID_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE =
  'payments/VOID_RHAPSODY_GO_STRIPE_TRANSACTION_FAILURE'
export const VOID_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS =
  'payments/VOID_RHAPSODY_GO_STRIPE_TRANSACTION_SUCCESS'

export const CREATE_STRIPE_PAYMENT = 'payments/CREATE_STRIPE_PAYMENT'
export const CREATE_STRIPE_PAYMENT_FAILURE =
  'payments/CREATE_STRIPE_PAYMENT_FAILURE'
export const CREATE_STRIPE_PAYMENT_SUCCESS =
  'payments/CREATE_STRIPE_PAYMENT_SUCCESS'

export const EDIT_GO_STRIPE_PAYMENT = 'payments/EDIT_GO_STRIPE_PAYMENT'
export const EDIT_GO_STRIPE_PAYMENT_FAILURE =
  'payments/EDIT_GO_STRIPE_PAYMENT_FAILURE'
export const EDIT_GO_STRIPE_PAYMENT_SUCCESS =
  'payments/EDIT_GO_STRIPE_PAYMENT_SUCCESS'

export const SETTLE_GO_STRIPE_TRANSACTION =
  'payments/SETTLE_GO_STRIPE_TRANSACTION'
export const SETTLE_GO_STRIPE_TRANSACTION_FAILURE =
  'payments/SETTLE_GO_STRIPE_TRANSACTION_FAILURE'
export const SETTLE_GO_STRIPE_TRANSACTION_SUCCESS =
  'payments/SETTLE_GO_STRIPE_TRANSACTION_SUCCESS'

export const CREATE_CREDIT_ADJUSTMENT = 'payments/CREATE_CREDIT_ADJUSTMENT'
export const CREATE_CREDIT_ADJUSTMENT_FAILURE =
  'payments/CREATE_CREDIT_ADJUSTMENT_FAILURE'
export const CREATE_CREDIT_ADJUSTMENT_SUCCESS =
  'payments/CREATE_CREDIT_ADJUSTMENT_SUCCESS'
