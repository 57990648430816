import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, PuiDialog } from '@pbt/pbt-ui-components'

import KioskPreview, { KioskPreviewProps } from './KioskPreview'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 860,
      maxWidth: 860,
    },
    kioskPreview: {
      height: 615,
    },
  }),
  { name: 'KioskPreviewDialog' },
)

export interface KioskPreviewDialogProps
  extends BasePuiDialogProps,
    Omit<KioskPreviewProps, 'className'> {}

const KioskPreviewDialog = ({
  open,
  onClose,
  ...rest
}: KioskPreviewDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <PuiDialog
      aria-labelledby="kiosk-preview-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={t('Common:PREVIEW_NOUN')}
      onClose={onClose}
    >
      <Grid container pb={3} pt={2} px={3}>
        <KioskPreview className={classes.kioskPreview} {...rest} />
      </Grid>
    </PuiDialog>
  )
}

export default KioskPreviewDialog
