import React from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import { ControlButtonGroupName, DocumentFile } from '@pbt/pbt-ui-components'
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Email as EmailIcon,
  Eye as EyeIcon,
  Print as PrintIcon,
} from '@pbt/pbt-ui-components/src/icons'

import { downloadFile } from '~/utils/file'

import { PopperAction } from '../../ActionsPopper'
import ActionsButton from '../../buttons/ActionsButton'

export interface AttachmentDropdownActionsProps {
  file: DocumentFile['file']
  hideDelete?: boolean
  hideDownload?: boolean
  hideEmail?: boolean
  hidePreview?: boolean
  hidePrint?: boolean
  onDeleteRequested?: () => void
  onDownloadRequested?: () => void
  onEmailRequested?: () => void
  onPreviewRequested?: () => void
  onPrintRequested?: () => void
}

const AttachmentDropdownActions = ({
  onDownloadRequested,
  onDeleteRequested,
  onPreviewRequested,
  onPrintRequested,
  onEmailRequested,
  hideDownload = false,
  hideDelete = false,
  hidePreview = false,
  hidePrint = false,
  hideEmail = false,
  file,
}: AttachmentDropdownActionsProps) => {
  const { t } = useTranslation('Common')

  const getActions = () => {
    const actions = [
      !hideDelete &&
        onDeleteRequested && {
          id: ControlButtonGroupName.DELETE,
          label: t('Common:DELETE_ACTION'),
          Icon: DeleteIcon,
          onClick: onDeleteRequested,
        },
      !hideDownload &&
        onDownloadRequested &&
        Boolean(file?.fileUrl) && {
          id: ControlButtonGroupName.DOWNLOAD,
          label: t('Common:DOWNLOAD'),
          Icon: DownloadIcon,
          onClick: () => {
            downloadFile({
              url: file?.fileUrl as string,
              name: file.fileName ?? file.name,
              extension: file.extension,
            })
            onDownloadRequested()
          },
        },
      !hidePreview &&
        onPreviewRequested && {
          id: ControlButtonGroupName.PREVIEW,
          label: t('Common:VIEW_ACTION'),
          Icon: EyeIcon,
          onClick: onPreviewRequested,
        },
      !hidePrint &&
        onPrintRequested && {
          id: ControlButtonGroupName.PRINT,
          label: t('Common:PRINT_ACTION'),
          Icon: PrintIcon,
          onClick: onPrintRequested,
        },
      !hideEmail &&
        onEmailRequested && {
          id: ControlButtonGroupName.EMAIL,
          label: t('Common:EMAIL'),
          Icon: EmailIcon,
          onClick: onEmailRequested,
        },
    ]
    return R.filter(Boolean, Object.values(actions)) as PopperAction[]
  }

  return <ActionsButton actions={getActions()} />
}

export default AttachmentDropdownActions
