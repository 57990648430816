import { ApiError } from '@pbt/pbt-ui-components'

import {
  ChargeSheetEmailDto,
  InvoiceEmailDto,
} from '~/api/graphql/generated/types'
import {
  EmailEntityConfig,
  EmailInvoiceData,
  EmailInvoiceParams,
  GeneratePdfForInvoiceData,
  GeneratePdfForInvoiceDataV2,
  ReportEmailCreateMessage,
  ReportPdfCreateMessage,
} from '~/types'

import {
  EMAIL_CHARGE_SHEET,
  EMAIL_CHARGE_SHEET_FAILURE,
  EMAIL_CHARGE_SHEET_SUCCESS,
  EMAIL_INVOICE,
  EMAIL_INVOICE_FAILURE,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_INVOICE_V2,
  EMAIL_INVOICE_V2_SUCCESS,
  EMAIL_LAB_RESULT,
  EMAIL_LAB_RESULT_FAILURE,
  EMAIL_LAB_RESULT_SUCCESS,
  EMAIL_MED_HISTORY,
  EMAIL_MED_HISTORY_FAILURE,
  EMAIL_MED_HISTORY_SUCCESS,
  EMAIL_PAYMENT,
  EMAIL_PAYMENT_FAILURE,
  EMAIL_PAYMENT_SUCCESS,
  EMAIL_PRESCRIPTION,
  EMAIL_PRESCRIPTION_FAILURE,
  EMAIL_PRESCRIPTION_SUCCESS,
  EMAIL_REPORT_CARD,
  EMAIL_REPORT_CARD_FAILURE,
  EMAIL_REPORT_CARD_SUCCESS,
  GENERATE_PDF_FOR_CHARGE_SHEET,
  GENERATE_PDF_FOR_CHARGE_SHEET_FAILURE,
  GENERATE_PDF_FOR_CHARGE_SHEET_SUCCESS,
  GENERATE_PDF_FOR_INVOICE,
  GENERATE_PDF_FOR_INVOICE_FAILURE,
  GENERATE_PDF_FOR_INVOICE_SUCCESS,
  GENERATE_PDF_FOR_INVOICE_V2,
  GENERATE_PDF_FOR_MEDICAL_HISTORY,
  GENERATE_PDF_FOR_MEDICAL_HISTORY_FAILURE,
  GENERATE_PDF_FOR_MEDICAL_HISTORY_SUCCESS,
  GENERATE_PDF_FOR_PRESCRIPTION,
  GENERATE_PDF_FOR_PRESCRIPTION_FAILURE,
  GENERATE_PDF_FOR_PRESCRIPTION_SUCCESS,
  GENERATE_PDF_FOR_REPORT_CARD,
  GENERATE_PDF_FOR_REPORT_CARD_FAILURE,
  GENERATE_PDF_FOR_REPORT_CARD_SUCCESS,
  SEND_MEMBERSHIP_HYBRID_INVITE,
  SEND_MEMBERSHIP_HYBRID_INVITE_FAILURE,
  SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS,
  WS_MEDICAL_HISTORY_EMAIL_CREATE,
  WS_MEDICAL_HISTORY_EMAIL_CREATE_FAILURE,
  WS_MEDICAL_HISTORY_EMAIL_CREATE_SUCCESS,
  WS_MEDICAL_HISTORY_PDF_CREATE,
  WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE,
  WS_MEDICAL_HISTORY_PDF_CREATE_SUCCESS,
  WS_REPORT_CARD_EMAIL_CREATE,
  WS_REPORT_CARD_EMAIL_CREATE_FAILURE,
  WS_REPORT_CARD_EMAIL_CREATE_SUCCESS,
  WS_REPORT_CARD_PDF_CREATE,
  WS_REPORT_CARD_PDF_CREATE_FAILURE,
  WS_REPORT_CARD_PDF_CREATE_SUCCESS,
} from './types/communications'

export const emailInvoice = (
  emailData: EmailInvoiceData,
  params: EmailInvoiceParams,
) => ({
  type: EMAIL_INVOICE,
  emailData,
  params,
})
export const emailInvoiceV2 = (emailData: InvoiceEmailDto) => ({
  type: EMAIL_INVOICE_V2,
  emailData,
})
export const emailInvoiceFailure = (error: ApiError) => ({
  type: EMAIL_INVOICE_FAILURE,
  error,
})
export const emailInvoiceSuccess = (conversationsList: string[]) => ({
  type: EMAIL_INVOICE_SUCCESS,
  conversationsList,
})
export const emailInvoiceSuccessV2 = (conversationsList: string[]) => ({
  type: EMAIL_INVOICE_V2_SUCCESS,
  conversationsList,
})

export const emailChargeSheet = (emailData: ChargeSheetEmailDto) => ({
  type: EMAIL_CHARGE_SHEET,
  emailData,
})
export const emailChargeSheetFailure = (error: ApiError) => ({
  type: EMAIL_CHARGE_SHEET_FAILURE,
  error,
})
export const emailChargeSheetSuccess = (conversationsList: string[]) => ({
  type: EMAIL_CHARGE_SHEET_SUCCESS,
  conversationsList,
})

export const emailReportCard = (emailData: EmailEntityConfig) => ({
  type: EMAIL_REPORT_CARD,
  emailData,
})
export const emailReportCardSuccess = (conversationsList: string[]) => ({
  type: EMAIL_REPORT_CARD_SUCCESS,
  conversationsList,
})
export const emailReportCardFailure = (error: ApiError) => ({
  type: EMAIL_REPORT_CARD_FAILURE,
  error,
})

export const emailPayment = (emailData: EmailEntityConfig) => ({
  type: EMAIL_PAYMENT,
  emailData,
})
export const emailPaymentSuccess = (conversationsList: string[]) => ({
  type: EMAIL_PAYMENT_SUCCESS,
  conversationsList,
})
export const emailPaymentFailure = (error: ApiError) => ({
  type: EMAIL_PAYMENT_FAILURE,
  error,
})

export const sendMembershipHybridInvite = (messageData: EmailEntityConfig) => ({
  type: SEND_MEMBERSHIP_HYBRID_INVITE,
  messageData,
})
export const sendMembershipHybridInviteSuccess = (
  conversationsList: string[],
) => ({
  type: SEND_MEMBERSHIP_HYBRID_INVITE_SUCCESS,
  conversationsList,
})
export const sendMembershipHybridInviteFailure = (error: ApiError) => ({
  type: SEND_MEMBERSHIP_HYBRID_INVITE_FAILURE,
  error,
})

export const emailPrescription = (emailData: EmailEntityConfig) => ({
  type: EMAIL_PRESCRIPTION,
  emailData,
})
export const emailPrescriptionSuccess = (conversationsList: string[]) => ({
  type: EMAIL_PRESCRIPTION_SUCCESS,
  conversationsList,
})
export const emailPrescriptionFailure = (error: ApiError) => ({
  type: EMAIL_PRESCRIPTION_FAILURE,
  error,
})

export const emailLabResult = (emailData: EmailEntityConfig) => ({
  type: EMAIL_LAB_RESULT,
  emailData,
})
export const emailLabResultSuccess = (conversationsList: string[]) => ({
  type: EMAIL_LAB_RESULT_SUCCESS,
  conversationsList,
})
export const emailLabResultFailure = (error: ApiError) => ({
  type: EMAIL_LAB_RESULT_FAILURE,
  error,
})

export const emailMedHistory = (emailData: EmailEntityConfig) => ({
  type: EMAIL_MED_HISTORY,
  emailData,
})
export const emailMedHistorySuccess = (conversationsList: string[]) => ({
  type: EMAIL_MED_HISTORY_SUCCESS,
  conversationsList,
})
export const emailMedHistoryFailure = (error: ApiError) => ({
  type: EMAIL_MED_HISTORY_FAILURE,
  error,
})

export const generatePdfForInvoice = (
  emailData: GeneratePdfForInvoiceData,
) => ({
  type: GENERATE_PDF_FOR_INVOICE,
  emailData,
})
export const generatePdfForInvoiceV2 = (data: GeneratePdfForInvoiceDataV2) => ({
  type: GENERATE_PDF_FOR_INVOICE_V2,
  data,
})
export const generatePdfForInvoiceSuccess = () => ({
  type: GENERATE_PDF_FOR_INVOICE_SUCCESS,
})
export const generatePdfForInvoiceFailure = (error: ApiError) => ({
  type: GENERATE_PDF_FOR_INVOICE_FAILURE,
  error,
})

export const generatePdfForChargeSheet = (emailData: EmailEntityConfig) => ({
  type: GENERATE_PDF_FOR_CHARGE_SHEET,
  emailData,
})
export const generatePdfForChargeSheetSuccess = () => ({
  type: GENERATE_PDF_FOR_CHARGE_SHEET_SUCCESS,
})
export const generatePdfForChargeSheetFailure = (error: ApiError) => ({
  type: GENERATE_PDF_FOR_CHARGE_SHEET_FAILURE,
  error,
})

export const generatePdfForReportCard = (emailData: EmailEntityConfig) => ({
  type: GENERATE_PDF_FOR_REPORT_CARD,
  emailData,
})
export const generatePdfForReportCardSuccess = () => ({
  type: GENERATE_PDF_FOR_REPORT_CARD_SUCCESS,
})
export const generatePdfForReportCardFailure = (error: ApiError | string) => ({
  type: GENERATE_PDF_FOR_REPORT_CARD_FAILURE,
  error,
})

export const generatePdfForPrescription = (emailData: EmailEntityConfig) => ({
  type: GENERATE_PDF_FOR_PRESCRIPTION,
  emailData,
})
export const generatePdfForPrescriptionSuccess = () => ({
  type: GENERATE_PDF_FOR_PRESCRIPTION_SUCCESS,
})
export const generatePdfForPrescriptionFailure = (error: ApiError) => ({
  type: GENERATE_PDF_FOR_PRESCRIPTION_FAILURE,
  error,
})

export const generatePdfForMedicalHistory = (emailData: EmailEntityConfig) => ({
  type: GENERATE_PDF_FOR_MEDICAL_HISTORY,
  emailData,
})
export const generatePdfForMedicalHistorySuccess = () => ({
  type: GENERATE_PDF_FOR_MEDICAL_HISTORY_SUCCESS,
})
export const generatePdfForMedicalHistoryFailure = (error: ApiError) => ({
  type: GENERATE_PDF_FOR_MEDICAL_HISTORY_FAILURE,
  error,
})

export const wsReportCardPdfCreate = (body: ReportPdfCreateMessage) => ({
  type: WS_REPORT_CARD_PDF_CREATE,
  body,
})
export const wsReportCardPdfCreateSuccess = () => ({
  type: WS_REPORT_CARD_PDF_CREATE_SUCCESS,
})
export const wsReportCardPdfCreateFailure = (error: string) => ({
  type: WS_REPORT_CARD_PDF_CREATE_FAILURE,
  error,
})

export const wsReportCardEmailCreate = (body: ReportEmailCreateMessage) => ({
  type: WS_REPORT_CARD_EMAIL_CREATE,
  body,
})
export const wsReportCardEmailCreateSuccess = () => ({
  type: WS_REPORT_CARD_EMAIL_CREATE_SUCCESS,
})
export const wsReportCardEmailCreateFailure = (error: string) => ({
  type: WS_REPORT_CARD_EMAIL_CREATE_FAILURE,
  error,
})

export const wsMedicalHistoryPdfCreate = (body: ReportPdfCreateMessage) => ({
  type: WS_MEDICAL_HISTORY_PDF_CREATE,
  body,
})
export const wsMedicalHistoryPdfCreateSuccess = () => ({
  type: WS_MEDICAL_HISTORY_PDF_CREATE_SUCCESS,
})
export const wsMedicalHistoryPdfCreateFailure = (error: string) => ({
  type: WS_MEDICAL_HISTORY_PDF_CREATE_FAILURE,
  error,
})

export const wsMedicalHistoryEmailCreate = (
  body: ReportEmailCreateMessage,
) => ({
  type: WS_MEDICAL_HISTORY_EMAIL_CREATE,
  body,
})
export const wsMedicalHistoryEmailCreateSuccess = () => ({
  type: WS_MEDICAL_HISTORY_EMAIL_CREATE_SUCCESS,
})
export const wsMedicalHistoryEmailCreateFailure = (error: string) => ({
  type: WS_MEDICAL_HISTORY_EMAIL_CREATE_FAILURE,
  error,
})
