import { useTranslation } from 'react-i18next'
import {
  CustomFieldValidatorState,
  EventTypeName,
  moment,
  Nil,
  useFields,
} from '@pbt/pbt-ui-components'

import { Reminder } from '~/types'

import { useEventType } from './useEventType'

export const useReminderDetailsFields = (reminder: Reminder | Nil) => {
  const { t } = useTranslation(['Common', 'Time', 'Validations'])

  const reminderStates = useEventType(EventTypeName.Reminder, 'states')

  const validateExpireDate = ({
    state: { dueDatetime, expireDatetime },
  }: CustomFieldValidatorState) =>
    expireDatetime && dueDatetime && moment(expireDatetime).isAfter(dueDatetime)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        label: t('Common:REMINDER_NAME'),
        initialValue: reminder?.name,
        validators: ['required'],
      },
      {
        name: 'dueDatetime',
        label: t('Time:LABEL.DUE_DATE'),
        initialValue: reminder?.dueDatetime,
        validators: ['required', 'timestamp'],
      },
      {
        name: 'expireDatetime',
        label: t('Common:EXPIRE_DATE'),
        initialValue: reminder?.expireDatetime,
        validators: [
          'timestamp',
          { validator: validateExpireDate, validatorName: 'required' },
        ],
        messages: {
          required: t('Validations:EXPIRE_DATE_AFTER_DUE_DATE'),
        },
      },
      {
        name: 'notes',
        initialValue: reminder?.notes || '',
        label: t('Common:NOTE'),
      },
      {
        name: 'stateId',
        initialValue: reminder?.state?.id || reminderStates[0]?.id,
      },
    ],
    false,
  )

  return { fields, reset, validate }
}
