import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FeatureToggle from '~/constants/featureToggle'
import {
  clearAppointmentInvoiceItems,
  fetchAppointmentInvoiceItems,
  fetchInvoice,
} from '~/store/actions/finance'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getAppointmentLineItemsMap } from '~/store/reducers/finance'
import { getAppointmentId, getInvoiceId } from '~/store/reducers/soap'

export const useFetchLineItems = () => {
  const dispatch = useDispatch()

  const appointmentId = useSelector(getAppointmentId)
  const appointmentLineItemsMap = useSelector(getAppointmentLineItemsMap)
  const invoiceId = useSelector(getInvoiceId)
  const isChargeSheetEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CHARGE_SHEET),
  )

  const shouldFetchLineItems = appointmentLineItemsMap === null

  useEffect(() => {
    if (!isChargeSheetEnabled) {
      dispatch(clearAppointmentInvoiceItems())
    }
  }, [isChargeSheetEnabled])

  useEffect(() => {
    if (invoiceId && !isChargeSheetEnabled) {
      dispatch(fetchInvoice(invoiceId))
    }
  }, [invoiceId, isChargeSheetEnabled])

  useEffect(() => {
    if (appointmentId && shouldFetchLineItems && !isChargeSheetEnabled) {
      dispatch(fetchAppointmentInvoiceItems(appointmentId))
    }
  }, [appointmentId, shouldFetchLineItems, isChargeSheetEnabled])
}
