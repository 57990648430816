import { gql } from '@apollo/client'

export const PERSON_SHORTEN_WITH_ALERT_DTO_FIELDS = gql`
  fragment PersonShortenWithAlertDtoFields on PersonShortenWithAlertDto {
    id
    firstName
    lastName
    email
    mobilePhone
    photo
    photoThumbnail
    alertColorId
    alertText
  }
`

export const CONTACT_FIELDS = gql`
  fragment ContactFields on Contact {
    id
    name
    type
    inReferralList
    phone
    email
    website
    address
    suite
    city
    country
    state
    zip
    notes
  }
`
