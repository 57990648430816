import React from 'react'
import { useSelector } from 'react-redux'
import {
  BasePuiDialogProps,
  DateUtils,
  moment,
  Nil,
  PhoneUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import PrintLabelDialog from '~/components/common/dialog/PrintLabelDialog'
import {
  getGenderString,
  getSpayedNeuteredIntactWithGenderString,
} from '~/components/common/inputs/gender/genderUtils'
import { DymoLabelType } from '~/constants/dymo'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { useAlertType } from '~/store/hooks/patient'
import {
  getBreed,
  getFeatureToggle,
  getGender,
  getSpecies,
} from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getAppointmentId, getRoom } from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { getFullBreedName, getPatientAge } from '~/utils'

import { joinLineParts } from './labelsUtils'

const getAppointmentStartEndTime = (
  startDate: string | Nil,
  endDate: string | Nil,
) => {
  if (!startDate || !endDate) {
    return null
  }
  const isMultiDay = moment(endDate).isAfter(startDate, 'date')

  const appointmentStartDate = DateUtils.formatDate(startDate)
  const appointmentEndDate = DateUtils.formatDate(endDate)
  const appointmentStartTime = DateUtils.formatTime(startDate)
  const appointmentEndTime = DateUtils.formatTime(endDate)

  return isMultiDay
    ? `${appointmentStartDate} ${appointmentStartTime}-${appointmentEndDate} ${appointmentEndTime}`
    : `${appointmentStartDate} ${appointmentStartTime}-${appointmentEndTime}`
}

export interface PrintCageLabelDialogProps extends BasePuiDialogProps {
  appointmentId?: string
  clientId: string | Nil
  patientId: string | Nil
}

const PrintCageLabelDialog = ({
  open,
  onClose,
  patientId,
  clientId,
  appointmentId,
}: PrintCageLabelDialogProps) => {
  const patient = useSelector(getPatient(patientId))
  const client = useSelector(getUser(clientId))
  const space = useSelector(getRoom) || ''
  const soapAppointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(
    getTimetableEvent(appointmentId || soapAppointmentId),
  )
  const isCageCardPatientAlertsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CAGE_CARD_PATIENT_ALERTS),
  )

  const primaryComplaint = appointment?.notes || ''

  const { scheduledStartDatetime, scheduledEndDatetime } = appointment || {}

  const Breed = useSelector(getBreed)
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)

  const { alertsToRender } = useAlertType(patientId)
  const alertLine = alertsToRender.join(', ').toUpperCase()

  const clientName = Utils.getPersonString(client)
  const clientPhone = PhoneUtils.formatPhoneNumber(client?.mobilePhone)

  const clientAndPatientNameLine = joinLineParts([
    patient?.name,
    clientName,
    clientPhone,
  ])

  const fullGenderName = patient?.gender
    ? getSpayedNeuteredIntactWithGenderString(
        patient?.gender,
        patient?.spayedNeutered,
        Gender,
      )
    : getGenderString(patient?.gender, Gender)

  const speciesName = Utils.getConstantName(patient?.species, Species, '')
  const breedFullName = getFullBreedName(
    patient?.species,
    patient?.breeds,
    Breed,
  )

  const alertString = patient?.alertTypes?.join(', ')

  const dateOfBirthString = patient?.dateOfBirth && getPatientAge(patient)
  const patientInfo = joinLineParts([
    dateOfBirthString,
    fullGenderName,
    speciesName,
    breedFullName,
    patient?.color,
  ])

  const spaceName = space ?? ''

  const appointmentTimeInfo = getAppointmentStartEndTime(
    scheduledStartDatetime,
    scheduledEndDatetime,
  )
  const appointmentTimeLabel =
    appointmentTimeInfo &&
    `${i18n.t('Common:APPOINTMENT_ONE')}: ${appointmentTimeInfo}`

  const reasonForVisit =
    primaryComplaint &&
    `${i18n.t('Common:REASON_FOR_VISIT')}: ${primaryComplaint}`
  const patientDiet =
    patient?.diet && `${i18n.t('Common:DIET_PATIENT')}: ${patient?.diet}`

  const labelLines = [
    alertLine,
    clientAndPatientNameLine,
    patientInfo,
    spaceName || appointmentTimeLabel || reasonForVisit || patientDiet
      ? ' '
      : '',
    spaceName,
    appointmentTimeLabel,
    reasonForVisit,
    patientDiet,
  ].filter(Boolean) as string[]

  const formattedLabelData = {
    patientName: patient?.name,
    visitReason: reasonForVisit,
    age: dateOfBirthString,
    gender: fullGenderName,
    breed: breedFullName,
    color: patient?.color,
    clientName,
    clientPhone,
    alert: alertString,
    apptType: appointment?.businessAppointmentType?.name,
  }

  return (
    <PrintLabelDialog
      showHtmlLabels
      formattedLabelData={formattedLabelData}
      labelLines={labelLines}
      labelType={
        isCageCardPatientAlertsEnabled
          ? DymoLabelType.CAGE_CARD_ALERT
          : DymoLabelType.CAGE_CARD
      }
      open={open}
      removeEmptyLabelSections={isCageCardPatientAlertsEnabled}
      title={i18n.t('Common:CAGE_CARD_LABEL')}
      onClose={onClose}
    />
  )
}

export default PrintCageLabelDialog
