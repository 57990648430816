import { Nil } from '@pbt/pbt-ui-components'

import { DiagnosesState, FindingState, Soap, SoapFile } from '~/types'

import { objectToFormData } from '../utils'
import { getAPIPath, request } from './core'
import { requestMutation } from './graphql/graphqlCore'
import { CREATE_SOAP_FILE_GROUP } from './graphql/mutations/soap'
import * as Schema from './schemas'
// @ts-ignore
import { serializeFindingsStateWithConcurrency } from './utils/serializeSOAPData'

export const createSOAP = (
  businessId: string,
  appointmentId: string | Nil,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath('soaps'),
    {
      method: 'POST',
      params: { businessId, originalBusinessId },
      data: { appointmentId },
    },
    true,
    Schema.SOAP,
  )

export const getOrCreateSOAP = (
  businessId: string,
  appointmentId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath('soaps/getOrCreate'),
    {
      method: 'POST',
      params: { businessId, originalBusinessId },
      data: { appointmentId },
    },
    true,
    Schema.SOAP,
  )

export const fetchSoap = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/${soapId}`),
    { method: 'GET', params: { businessId, originalBusinessId } },
    true,
    Schema.SOAP,
  )

export const fetchSoapFindingsContainer = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/${soapId}/findingsContainer`),
    { method: 'GET', params: { businessId, originalBusinessId } },
    true,
  )

export const editSOAP = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
  data: Soap,
  force?: boolean,
) =>
  request(
    getAPIPath(`soaps/${soapId}`),
    { method: 'PUT', params: { businessId, originalBusinessId, force }, data },
    true,
    Schema.SOAP,
  )

export const editFindingsState = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
  findingsState: Record<string, FindingState>,
  crc: number,
) =>
  request(
    getAPIPath(`soaps/${soapId}/findingsContainer`),
    {
      method: 'PUT',
      params: { businessId, originalBusinessId },
      data: serializeFindingsStateWithConcurrency(findingsState, crc),
    },
    true,
  )

export const fetchSOAPOrders = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/${soapId}/orders`),
    { method: 'GET', params: { businessId, originalBusinessId } },
    true,
  )

export const fetchSOAPOrderFilters = (
  businessId: string,
  appointmentId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath('soaps/orderFilters'),
    {
      method: 'GET',
      params: { businessId, appointmentId, originalBusinessId },
    },
    true,
  )

export const fetchSOAPFiles = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/${soapId}/files`),
    { method: 'GET', params: { businessId, originalBusinessId } },
    true,
  )

export const uploadFile = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
  file: SoapFile,
) =>
  request(
    getAPIPath(`soaps/${soapId}/files`),
    {
      method: 'POST',
      data: objectToFormData(file),
      params: { businessId, originalBusinessId },
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'file',
      },
    },
    true,
  )

export const deleteFile = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
  fileId: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/files/${fileId}`),
    { method: 'DELETE', params: { businessId, originalBusinessId } },
    true,
  )

export const attachDocumentsToSoap = (
  businessId: string,
  documentIds: string[],
  soapId: string,
  originalBusinessId: string | Nil,
  authorId: string,
) =>
  request(
    getAPIPath(`soaps/${soapId}/files/documents`),
    {
      method: 'POST',
      data: { ids: documentIds },
      params: { businessId, originalBusinessId, authorId },
    },
    true,
  )

export const updateDocumentOnSoap = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
  document: SoapFile,
) =>
  request(
    getAPIPath(`soaps/${soapId}/files/html/${document.id}`),
    {
      method: 'PUT',
      params: { businessId, originalBusinessId },
      data: {
        body: document?.file?.body,
        signer: document.signer,
        sign: document.sign,
      },
    },
    true,
  )

export const finalizeSoap = (
  businessId: string,
  soapId: string,
  finalized: boolean,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/${soapId}/finalize`),
    { method: 'PATCH', params: { businessId, finalized, originalBusinessId } },
    true,
    Schema.SOAP,
  )

export const editDiagnosesState = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
  diagnosesMap: Record<string, DiagnosesState>,
) =>
  request(
    getAPIPath(`soaps/${soapId}/diagnoses`),
    {
      method: 'PUT',
      params: { businessId, originalBusinessId },
      data: diagnosesMap,
    },
    true,
  )

export const fetchUsersOnSoapCollaborationSession = (
  businessId: string,
  soapId: string,
  originalBusinessId: string | Nil,
) =>
  request(
    getAPIPath(`soaps/${soapId}/usersOnCollaborationSession`),
    { method: 'GET', params: { businessId, originalBusinessId } },
    true,
  )

export const saveSoapFileGroup = requestMutation({
  mutation: CREATE_SOAP_FILE_GROUP,
  variablesHandler: (businessId, soapFileGroupInput) => ({
    input: soapFileGroupInput,
  }),
})
