import React from 'react'
import { useSelector } from 'react-redux'
import {
  BreedConstant,
  Constant,
  DateFormat,
  Nil,
  TextInteractiveProps,
} from '@pbt/pbt-ui-components'

import { usePatientWeight } from '~/store/hooks/patient'
import { getBreed, getGender } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getFullBreedName, getPatientAge, getPatientStatus } from '~/utils'

import { getShortGenderString } from '../inputs/gender/genderUtils'
import DetailsSeparatedLabel, { DetailsItem } from './DetailsSeparatedLabel'

export interface PatientInfoLabelProps extends TextInteractiveProps {
  patientId: string | Nil
}

const PatientInfoLabel = ({ patientId, ...rest }: PatientInfoLabelProps) => {
  const patient = useSelector(getPatient(patientId))
  const Gender: Constant[] = useSelector(getGender)
  const Breed: BreedConstant = useSelector(getBreed)

  const patientWeight = usePatientWeight(
    patientId,
    DateFormat.SHORT_DATE_YEAR_ABBREV,
  )
  const patientStatus = getPatientStatus(patient)

  const details = [
    Boolean(patient?.dateOfBirth) && {
      id: 'dob',
      text: `${patient?.approximateAge ? '~' : ''}${getPatientAge(
        patient,
        true,
      )}`,
    },
    {
      id: 'gender',
      text: getShortGenderString(
        patient?.gender,
        patient?.spayedNeutered,
        Gender,
      ),
    },
    patient?.breeds &&
      patient?.breeds.length > 0 && {
        id: 'breed',
        text: getFullBreedName(patient.species, patient.breeds, Breed),
      },
    patient?.color && {
      id: 'color',
      text: patient?.color,
    },
    patientWeight && {
      id: 'weight',
      text: patientWeight,
    },
  ].filter(Boolean) as DetailsItem[]

  return (
    <DetailsSeparatedLabel
      details={details}
      disabled={patientStatus.disabled}
      {...rest}
    />
  )
}

export default PatientInfoLabel
