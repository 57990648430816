import React, { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  InfiniteLoaderList,
  PermissionArea,
  Text,
} from '@pbt/pbt-ui-components'

import {
  fetchDepartments,
  getDepartmentsList,
  getMultipleDepartments,
} from '~/store/duck/departments'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { BasePracticeDetailsSectionProps } from '~/types'

import DepartmentAdd from './DepartmentAdd'
import DepartmentItem from './DepartmentItem'

const DEPARTMENT_HEADER_HEIGHT = 30
const DEPARTMENT_ITEM_HEIGHT = 40
const COUNT_VISIBLE_DEPARTMENTS = 10
const MAX_DEPARTMENT_LIST_HEIGHT =
  COUNT_VISIBLE_DEPARTMENTS * DEPARTMENT_ITEM_HEIGHT

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    table: {
      border: theme.constants.tableBorder,
    },
    tableHeader: {
      height: DEPARTMENT_HEADER_HEIGHT,
      borderBottom: theme.constants.tableBorder,
    },
    tableAdd: {
      height: DEPARTMENT_ITEM_HEIGHT,
    },
    tableItem: {
      height: DEPARTMENT_ITEM_HEIGHT,
    },
    tableLastItem: {
      height: DEPARTMENT_ITEM_HEIGHT - 1,
      borderBottom: 0,
    },
    addItem: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
    },
  }),
  { name: 'DepartmentSection' },
)

const DepartmentSection = forwardRef<
  HTMLDivElement,
  BasePracticeDetailsSectionProps
>(function DepartmentSection({ business }, ref) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const [addDepartmentEnabled, setAddDepartmentEnabled] = useState(false)

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const departmentsList = useSelector(getDepartmentsList)
  const departments = useSelector(getMultipleDepartments(departmentsList))

  const businessId = business?.id

  useEffect(() => {
    if (businessId) {
      dispatch(fetchDepartments(businessId, true))
    }
  }, [businessId])

  const departmentListHeightByItemLength =
    departments?.length && departments.length * DEPARTMENT_ITEM_HEIGHT
  const departmentListHeight = Math.min(
    MAX_DEPARTMENT_LIST_HEIGHT,
    departmentListHeightByItemLength,
  )

  return (
    <Grid className={classes.root} ref={ref}>
      <Grid className={classes.table}>
        <Grid container item className={classes.tableHeader} wrap="nowrap">
          <Grid container item alignItems="center" px={1.5} xs={2}>
            <Text strong variant="lowAccent2">
              {t('Common:ACTIVE_ONE')}
            </Text>
          </Grid>
          <Grid container item alignItems="center" px={1.5} xs={4}>
            <Text strong variant="lowAccent2">
              {t('Common:NAME')}
            </Text>
          </Grid>
          <Grid container item alignItems="center" px={1.5} xs={3}>
            <Text strong variant="lowAccent2">
              {t('Common:TYPE_ONE_OR_OTHER')}
            </Text>
          </Grid>
          <Grid container item px={1.5} xs={3} />
        </Grid>
        {/* TODO: departments pagination list */}
        <InfiniteLoaderList
          isItemLoaded={R.F}
          itemCount={departments.length}
          itemData={departments}
          itemSpacing={0}
          loadMoreItems={R.F}
          style={{ height: departmentListHeight }}
        >
          {(department = {}, index) => (
            <DepartmentItem
              className={classNames(classes.tableItem, {
                [classes.tableLastItem]: index === departments.length - 1,
              })}
              department={department}
              editable={permissions.update}
              key={department.id}
            />
          )}
        </InfiniteLoaderList>
        {permissions.update && addDepartmentEnabled && (
          <DepartmentAdd
            businessId={businessId}
            className={classes.tableAdd}
            onDiscard={() => setAddDepartmentEnabled(false)}
          />
        )}
      </Grid>
      {permissions.update && !addDepartmentEnabled && (
        <AddButton
          addText={t('Common:ADD_DEPARTMENT')}
          classes={{ addItem: classes.addItem }}
          onAdd={() => setAddDepartmentEnabled(true)}
        />
      )}
    </Grid>
  )
})

export default DepartmentSection
