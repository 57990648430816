import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Grid, Hidden, IconButton, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AddButton,
  Defaults,
  LanguageUtils,
  PermissionArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import DetailsList, {
  DetailsListItem,
} from '~/components/common/lists/DetailsList'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import {
  deletePrice as deletePriceAction,
  fetchProcedure,
} from '~/store/actions/procedures'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import {
  getGender,
  getImagingModalities,
  getSpecies,
} from '~/store/reducers/constants'
import { getProcedure } from '~/store/reducers/procedures'
import {
  getAAHACodeString,
  getConstantsStringWithAll,
  getDeleteConfirmMessage,
} from '~/utils'
import useDialog from '~/utils/useDialog'

import PricesTable from './PricesTable'

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.colors.link,
    },
    priceListItem: {
      borderBottom: theme.constants.tabBorder,
    },
    addPriceContainer: {
      [theme.breakpoints.down('md')]: {
        borderBottom: theme.constants.tabBorder,
      },
    },
  }),
  { name: 'ProcedureDetails' },
)

export interface ProcedureDetailsProps {
  itemId: string
  onClose: () => void
}

const ProcedureDetails = ({ itemId, onClose }: ProcedureDetailsProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const procedure = useSelector(getProcedure(itemId))
  const business = useSelector(getCurrentBusiness)
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)
  const ImagingModalities = useSelector(getImagingModalities)
  const permissions = useSelector(getCRUDByArea(PermissionArea.PROCEDURE_PRICE))

  const [openPriceDialog] = useDialog(DialogNames.PRICE)
  const [openDeletePriceAlertDialog, closeDeletePriceAlertDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )

  const onEditPriceButtonClick = (id: string) => {
    navigate(`/admin/catalog/procedures/${itemId}/prices/${id}`)
  }

  const onAddRequested = () => {
    if (isMobile) {
      navigate(`/admin/catalog/procedures/${itemId}/prices/create`)
    } else {
      openPriceDialog({
        procedureId: itemId,
      })
    }
  }

  const { prices } = procedure || {}

  useEffect(() => {
    if (itemId) {
      dispatch(fetchProcedure(itemId))
    }
  }, [itemId])

  if (typeof prices === 'undefined') {
    return null
  }

  const deletePrice = (priceId: string) => {
    dispatch(deletePriceAction(itemId, priceId))
    closeDeletePriceAlertDialog()
  }

  const handleDeletePrice = (id: string) => {
    openDeletePriceAlertDialog({
      message: getDeleteConfirmMessage(t('Common:PRICE').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeletePriceAlertDialog(),
      onOk: () => deletePrice(id),
    })
  }

  const handleEditPrice = (id: string) => {
    openPriceDialog({
      price: Utils.findById(id, prices),
      procedureId: itemId,
    })
  }

  return (
    <Expander
      expandedItemClass={t('Common:PROCEDURE').toLowerCase()}
      showButtons={false}
      onBack={onClose}
    >
      <Grid item>
        <Text strong variant={isMobile ? 'subheading2' : 'h2'}>
          {LanguageUtils.getTranslatedFieldName(procedure)}
        </Text>
      </Grid>
      <Grid item mt={{ xs: 2, md: 4 }}>
        <Text strong variant={isMobile ? 'subheading2' : 'subheading3'}>
          {`${business?.name} ${t('Common:PRICE_OR_PRICES').toLowerCase()}`}
        </Text>
      </Grid>
      {prices.length > 0 && (
        <>
          <Hidden mdDown>
            <Grid item mt={1}>
              <PricesTable
                createDisabled={!permissions.create}
                deleteDisabled={!permissions.delete}
                editDisabled={!permissions.update}
                prices={prices}
                onDelete={handleDeletePrice}
                onEdit={handleEditPrice}
              />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            {prices.map((price) => (
              <Grid
                container
                item
                alignItems="center"
                className={classes.priceListItem}
                key={price.id}
                pl={3}
                onClick={() => onEditPriceButtonClick(price.id)}
              >
                <Grid item xs>
                  <Text variant={isMobile ? 'body' : 'body2'}>
                    {price.name}
                  </Text>
                </Grid>
                <Grid item>
                  <IconButton aria-label={t('Common:EDIT_PRICE')} size="large">
                    <KeyboardArrowRight />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Hidden>
        </>
      )}
      {permissions.create && (
        <Grid
          item
          className={classes.addPriceContainer}
          mt={2}
          pb={{ xs: 2, md: 0 }}
          px={{ xs: 3, md: 0 }}
        >
          <AddButton addText={t('Common:ADD_PRICE')} onAdd={onAddRequested} />
        </Grid>
      )}
      <Grid item mt={{ xs: 4, md: 6 }}>
        <DetailsList
          data={
            [
              {
                label: t('Common:CODE'),
                value: procedure?.procedureCode,
              },
              procedure?.modalityId && {
                label: t('Common:MODALITY'),
                value: getConstantsStringWithAll(
                  procedure?.modalityId,
                  ImagingModalities,
                ),
              },
              {
                label: t('Common:CATEGORY'),
                value:
                  LanguageUtils.getTranslatedFieldName(procedure, 'category') +
                  (procedure?.aahaCodeSub
                    ? ` (${getAAHACodeString(procedure?.aahaCodeSub)})`
                    : ''),
              },
              {
                label: t('Admin:CATALOG.PROCEDURE_DETAILS.REVENUE_CENTER'),
                value:
                  (procedure?.revenueCenter || '') +
                  (procedure?.aahaCodeMain
                    ? ` (${getAAHACodeString(procedure?.aahaCodeMain)})`
                    : ''),
              },
              {
                label: t('Common:SPECIES'),
                value: getConstantsStringWithAll(
                  procedure?.speciesIds,
                  Species,
                ),
              },
              {
                label: t('Common:GENDER'),
                value: getConstantsStringWithAll(procedure?.genderId, Gender),
              },
              {
                label: t('Common:TAGS'),
                value: procedure?.tags,
              },
              {
                label: t('Common:DESCRIPTION'),
                value: LanguageUtils.getTranslatedFieldName(
                  procedure,
                  'description',
                ),
              },
            ].filter(Boolean) as DetailsListItem[]
          }
          headerText={t('Admin:CATALOG.PROCEDURE_DETAILS.HEADER_TEXT')}
          headerTooltipText={
            <span>
              {t('Tooltips:RHAPSODY_USES_STANDARDIZED_LIST_OF_ALL_PROCEDURES')}
              &nbsp;
              <a
                className={classes.link}
                href={`mailto:${Defaults.SUPPORT_EMAIL}`}
              >
                {Defaults.SUPPORT_EMAIL}
              </a>
            </span>
          }
        />
      </Grid>
    </Expander>
  )
}

export default ProcedureDetails
