import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { ProblemEnumValueConstant, Text, Utils } from '@pbt/pbt-ui-components'

import { getProblemEnums } from '~/store/reducers/constants'
import { ProblemLogEnumReference } from '~/types'
import { getEnumValuePathLabel } from '~/utils/problems'

interface ProblemsDiagnosesTableDiagnosisEnumsProps {
  childrenEnums: ProblemLogEnumReference[]
  parentEnum: ProblemLogEnumReference
}

const ProblemsDiagnosesTableDiagnosisEnums = ({
  childrenEnums,
  parentEnum,
}: ProblemsDiagnosesTableDiagnosisEnumsProps) => {
  const { t } = useTranslation('Common')
  const ProblemsEnums = useSelector(getProblemEnums)
  const ParentEnumInfo = Utils.findById(parentEnum.enumId, ProblemsEnums)
  const ParentEnumValueInfo = ParentEnumInfo.values.find(
    (value: ProblemEnumValueConstant) => value.id === parentEnum.enumValueId,
  )
  const parentEnumLabel = getEnumValuePathLabel(
    ParentEnumInfo,
    ParentEnumValueInfo,
  )

  return (
    <Grid container item direction="column" display="flex" pl={1.5}>
      {Boolean(parentEnum.notes) && (
        <Text>{`${parentEnumLabel} | ${t('Common:NOTES')}: ${
          parentEnum.notes
        }`}</Text>
      )}
      {Boolean(childrenEnums.length) &&
        childrenEnums.map((childEnumRef) => {
          const ChildEnumInfo = Utils.findById(
            childEnumRef.enumId,
            ProblemsEnums,
          )
          const ChildEnumValueInfo = ChildEnumInfo.values.find(
            (value: ProblemEnumValueConstant) =>
              value.id === childEnumRef.enumValueId,
          )
          const childEnumLabel = getEnumValuePathLabel(
            ChildEnumInfo,
            ChildEnumValueInfo,
            ParentEnumInfo,
            ParentEnumValueInfo,
          )
          return (
            <Grid
              key={
                parentEnum.enumId +
                parentEnum.enumValueId +
                parentEnum.id +
                childEnumRef.id
              }
            >
              {Boolean(childEnumRef.notes) && (
                <Text>{`${childEnumLabel} | ${t('Common:NOTES')}: ${
                  childEnumRef.notes
                }`}</Text>
              )}
            </Grid>
          )
        })}
    </Grid>
  )
}

export default ProblemsDiagnosesTableDiagnosisEnums
