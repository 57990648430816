import { useSelector } from 'react-redux'
import { DurationInputArg2, Moment } from 'moment'
import * as R from 'ramda'
import {
  DateUtils,
  LanguageUtils,
  moment,
  Nil,
  UnitUtils,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import {
  getEventState,
  getReminderProtocolTimeUnits,
} from '~/store/reducers/constants'
import {
  AmountWithUnitId,
  Reminder,
  ReminderByUser,
  ReminderGroup,
  ReminderProtocol,
  ReminderStateName,
} from '~/types'
import { getTimeUnitListPluralized } from '~/utils/time'

export const useGetDueDateString = (
  offset: {
    amount: number
    unit: string
  } | null,
) => {
  const TimeUnits = useSelector(getReminderProtocolTimeUnits)
  const LocalizedTimeUnits = getTimeUnitListPluralized(
    TimeUnits,
    offset?.amount || 0,
  )
  const unitName = LanguageUtils.getConstantTranslatedName(
    offset?.unit,
    LocalizedTimeUnits,
    '',
  ).toLowerCase()

  return offset?.amount
    ? `+${offset?.amount} ${unitName}`.toLowerCase().trim()
    : ''
}

export const dateOffsetToDate = (
  amount: any,
  unitName: DurationInputArg2,
  base?: Moment,
) => moment(base || new Date()).add(amount, unitName)

export const getReminderGroupName = (group: ReminderGroup) => {
  const type = group.type || group.itemType

  return group.month ? `${type}-${group.month}` : type
}

export const useIsReminderStateName = (
  reminder: Reminder | Nil,
  state: ReminderStateName,
) => {
  const EventState = useSelector(getEventState)

  return reminder?.state?.id === Utils.findConstantIdByName(state, EventState)
}

export const getReminderTriggerLabel = (triggeredBy: ReminderByUser) => {
  const { itemName, datetime, person } = triggeredBy

  return [
    itemName,
    datetime && DateUtils.formatDate(datetime),
    person && Utils.getPersonString(person),
  ]
    .filter(Boolean)
    .join(' | ')
}

const convertedWeight = (
  weight: any,
  defaultUnitId: any,
  kgUnitId: any,
  unitsState: any,
) =>
  weight
    ? {
        ...weight,
        amount:
          weight.unitId === defaultUnitId
            ? weight.amount
            : weight.unitId === kgUnitId
              ? UnitUtils.kgToLbs(weight.amount)
              : UnitUtils.convertUnits(
                  UnitTypes.WEIGHT,
                  weight.amount,
                  unitsState,
                ),
        unitId: defaultUnitId,
      }
    : null

const getDisplayName = (unitId: string, AgeUnits: any) =>
  LanguageUtils.getConstantTranslatedName(unitId, AgeUnits).toLowerCase()

export const checkWeightRange = (
  selectedProtocol: ReminderProtocol,
  patientWeight: string,
  defaultUnitId: any,
  kgUnitId: any,
  unitsState: any,
) => {
  if (patientWeight != null) {
    const weightParts = patientWeight.toString().split(' ')
    const weight = parseInt(weightParts[0], 10)
    const weightMin = convertedWeight(
      selectedProtocol.weightMin,
      defaultUnitId,
      kgUnitId,
      unitsState,
    )
    const weightMax = convertedWeight(
      selectedProtocol.weightMax,
      defaultUnitId,
      kgUnitId,
      unitsState,
    )
    const weightMaxValue = weightMax.amount
    const weightMinValue = weightMin.amount

    if (weightMin && weightMax) {
      return weightMinValue <= weight && weight <= weightMaxValue
    }
  }
  return false
}
export const checkSpecies = (
  selectedProtocol: ReminderProtocol,
  species: string,
) => {
  if (species) {
    return selectedProtocol.speciesIds.includes(species)
  }
  return false
}

export const checkGender = (
  selectedProtocol: ReminderProtocol,
  gender: string,
) => {
  if (gender) {
    return selectedProtocol.genderRestrictions.some(
      (genderObj) => genderObj.genderId === gender,
    )
  }
  return false
}

export const ageUnitsToDays = (
  age: AmountWithUnitId,
  AgeUnits: any,
  displayNameFunc: (unitId: string, AgeUnits: any) => string,
) => {
  const units = displayNameFunc(age.unitId, AgeUnits)

  if (units.includes('year')) {
    return age.amount * 365
  }

  if (units.includes('month')) {
    return (age.amount * 365) / 12
  }

  if (units.includes('week')) {
    return age.amount * 7
  }

  return age.amount
}

// Return true if patient age is defined and within range, otherwise false
export const checkAgeRange = (
  selectedProtocol: ReminderProtocol,
  patientDOB: string,
  patientDeceasedDate: string | undefined,
  AgeUnits: any,
  displayNameFunc: (unitId: string, AgeUnits: any) => string = getDisplayName, // for unit tests
) => {
  if (R.isNil(patientDOB)) {
    return false
  }

  const startDate = moment(patientDOB)
  const endDate = patientDeceasedDate
    ? moment(patientDeceasedDate)
    : moment().startOf('day')
  const patientAgeDays = endDate.diff(startDate, 'day', true)

  const { ageMin, ageMax } = selectedProtocol
  const ageMinDays = ageMin
    ? ageUnitsToDays(ageMin, AgeUnits, displayNameFunc)
    : 0
  const ageMaxDays = ageMax
    ? ageUnitsToDays(ageMax, AgeUnits, displayNameFunc)
    : Number.MAX_SAFE_INTEGER

  return ageMinDays <= patientAgeDays && ageMaxDays >= patientAgeDays
}
