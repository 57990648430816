import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import {
  MutationCreateOrUpdatePatientPreferenceArgs,
  QueryPatientArgs,
} from '~/api/graphql/generated/types'
import { PatientPreferences } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export type PatientPreferencesState = {
  error: string | null
  isLoading: boolean
  isSaving: boolean
  map: Record<string, PatientPreferences>
}

export const INITIAL_STATE: PatientPreferencesState = {
  map: {},
  isLoading: false,
  isSaving: false,
  error: null,
}

const patientPreferencesSlice = createSlice({
  name: 'patientPreferences',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPatientPreferences: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<QueryPatientArgs>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    fetchPatientPreferencesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ patientId: string; preferences: PatientPreferences }>,
    ) => {
      state.isLoading = false
      state.map[payload.patientId] = payload.preferences
    },
    fetchPatientPreferencesFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isLoading = false
      state.error = getErrorMessage(payload.error)
    },
    savePatientPreferences: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<MutationCreateOrUpdatePatientPreferenceArgs>,
    ) => {
      state.isSaving = true
      state.error = null
    },
    savePatientPreferencesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ patientId: string; preferences: PatientPreferences }>,
    ) => {
      state.isSaving = false
      state.map[payload.patientId] = payload.preferences
    },
    savePatientPreferencesFailure: (
      state,
      { payload }: PayloadAction<{ error: ApiError }>,
    ) => {
      state.isSaving = false
      state.error = getErrorMessage(payload.error)
    },
  },
})

const { actions, reducer } = patientPreferencesSlice

export const {
  fetchPatientPreferences,
  fetchPatientPreferencesFailure,
  fetchPatientPreferencesSuccess,
  savePatientPreferences,
  savePatientPreferencesFailure,
  savePatientPreferencesSuccess,
} = actions

export default reducer
export const getPatientPreferencesState = (
  state: RootState,
): PatientPreferencesState => state.patientPreferences
export const getPatientPreferencesIsLoading = (state: RootState) =>
  getPatientPreferencesState(state).isLoading
export const getPatientPreferencesIsSaving = (state: RootState) =>
  getPatientPreferencesState(state).isSaving
export const getPatientPreferencesMap = (state: RootState) =>
  getPatientPreferencesState(state).map
export const getPatientPreferences = (id: string | Nil) =>
  createSelector(getPatientPreferencesMap, (map) => (id ? map[id] : undefined))
