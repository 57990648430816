import React from 'react'
import { DateFormat, moment } from '@pbt/pbt-ui-components'

import useTimetableDate from '~/utils/useTimetableDate'

import TimezoneWarningLabel, {
  TimezoneWarningLabelProps,
} from '../alerts/TimezoneWarningLabel'

export interface TimetableDateLabelProps extends TimezoneWarningLabelProps {}

const TimetableDateLabel = (props: TimetableDateLabelProps) => {
  const { selectedDate } = useTimetableDate()

  return (
    <TimezoneWarningLabel strong variant="subheading4" {...props}>
      {moment(selectedDate).format(DateFormat.DAY_OF_WEEK_MONTH_DAY_COMMA_YEAR)}
    </TimezoneWarningLabel>
  )
}

export default TimetableDateLabel
