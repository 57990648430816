import React from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageUtils } from '@pbt/pbt-ui-components'

import { CSSPropertiesMap } from '~/types'
import { PrintFormattedData } from '~/types/entities/print'

const styles: CSSPropertiesMap = {
  container: {
    border: '1px solid #D8D8D8',
    width: 600,
    boxSizing: 'border-box',
  },
  text: {
    fontFamily: 'sans-serif',
    color: '#3C3838',
  },
  oneLineText: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  patientRow: {
    padding: '8px 16px',
    textTransform: 'uppercase',
    fontSize: 16,
    lineHeight: 'normal',
  },
  drugRow: {
    padding: '8px 16px',
    backgroundColor: '#E5E5E5',
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: 'normal',
    WebkitPrintColorAdjust: 'exact',
    textAlign: 'center',
  },
  drugName: {
    fontWeight: 'bold',
  },
  formRxNumberDateRow: {
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    fontSize: 13,
    lineHeight: 'normal',
  },
  horizontalLine: {
    width: '100%',
    border: '1px solid #D8D8D8',
    borderWidth: '1px 0 0 0',
  },
  quantityRow: {
    padding: '4px 16px',
    lineHeight: 'normal',
  },
  instructionsRow: {
    padding: '8px 16px',
    minHeight: 66,
    boxSizing: 'content-box',
    fontSize: 18,
    lineHeight: '24px',
    overflow: 'hidden',
  },
  businessNameRow: {
    padding: '8px 16px 0',
    lineHeight: 'normal',
  },
  businessName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  doctorAddressPhoneRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px',
    fontSize: 14,
    lineHeight: '21px',
  },
  warningRow: {
    padding: '8px 16px',
    fontSize: 12,
    lineHeight: 'normal',
  },
}

interface FormattedLabelProps {
  data: PrintFormattedData
}

const FormattedLabel = ({ data }: FormattedLabelProps) => {
  const { t } = useTranslation(['Common', 'Utils', 'Abbreviations'])

  const {
    clientName,
    patientNameAndSpecies,
    drugName,
    drugStrength,
    drugStrengthColor,
    drugStrengthCombo,
    drugForm,
    prescriptionNumber,
    expirationDateString,
    instructions,
    quantityRow,
    businessName,
    businessAddress,
    businessPhone,
    doctorName,
    bottomWarning,
    ndc,
  } = data

  return (
    <div style={styles.container}>
      <div style={styles.patientRow}>
        <span style={{ ...styles.text, ...styles.oneLineText }}>
          {`${t('Common:PATIENT')}: ${patientNameAndSpecies} ${t(
            'Common:CLIENT',
          )}: ${clientName} `}
        </span>
      </div>
      <div style={styles.drugRow}>
        <span style={{ ...styles.text, ...styles.drugName }}>
          {LanguageUtils.getSentence([
            drugName,
            drugStrength || drugStrengthCombo,
            drugStrengthColor,
          ])}
        </span>
      </div>
      <div style={styles.formRxNumberDateRow}>
        <span style={styles.text}>{drugForm}</span>
        <span style={styles.text}>{prescriptionNumber}</span>
        <span style={styles.text}>{expirationDateString}</span>
        {ndc && (
          <span style={styles.text}>
            {t('Abbreviations:ACRONYMS.NATIONAL_DRUG_CODE.LABEL_ABBREVIATION')}:{' '}
            {ndc}
          </span>
        )}
      </div>
      <div style={styles.horizontalLine} />
      <div style={styles.instructionsRow}>
        <span style={styles.text}>{instructions}</span>
      </div>
      <div style={styles.horizontalLine} />
      <div style={styles.quantityRow}>
        <span style={{ ...styles.text, ...styles.oneLineText }}>
          {quantityRow}
        </span>
      </div>
      <div style={styles.horizontalLine} />
      <div style={styles.businessNameRow}>
        <span
          style={{
            ...styles.text,
            ...styles.oneLineText,
            ...styles.businessName,
          }}
        >
          {businessName}
        </span>
      </div>
      <div style={styles.doctorAddressPhoneRow}>
        <span style={{ ...styles.text, ...styles.oneLineText }}>
          {doctorName}
        </span>
        <span style={styles.text}>{businessAddress}</span>
        <span style={{ ...styles.text, ...styles.oneLineText }}>
          {t('Common:PHONE')}: {businessPhone}
        </span>
      </div>
      <div style={styles.horizontalLine} />
      <div style={styles.warningRow}>
        <span style={styles.text}>{bottomWarning}</span>
      </div>
    </div>
  )
}

export default FormattedLabel
