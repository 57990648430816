import * as R from 'ramda'
import { all, put } from 'redux-saga/effects'

import { updateAppointmentTypes } from '~/store/actions/appointmentTypes'
import { updateAvailabilityRules } from '~/store/actions/availabilityRules'
import { updateBundles } from '~/store/actions/bundles'
import { updateBusinesses } from '~/store/actions/businesses'
import { updateConversations } from '~/store/actions/conversations'
import { updateDocuments } from '~/store/actions/documents'
import { updateInvoices } from '~/store/actions/finance'
import { updateInventories } from '~/store/actions/inventories'
import { updateNotifications } from '~/store/actions/notifications'
import { updateMultipleOrders } from '~/store/actions/orders'
import { updatePatients } from '~/store/actions/patients'
import {
  updateGoPayments,
  updateGoStripePayments,
  updatePayments,
} from '~/store/actions/payments'
import { updateQuestions } from '~/store/actions/questions'
import { updateRoles } from '~/store/actions/roles'
import { updateSpaces } from '~/store/actions/spaces'
import { updateTasks } from '~/store/actions/tasks'
import { updateEvents } from '~/store/actions/timetable'
import { updateUsers } from '~/store/actions/users'
import { updateWellnessPlansVersions } from '~/store/actions/wellnessPlans'
import { updateAddendums } from '~/store/duck/addendums'
import {
  updateBulkPrices,
  updateBulkPricesPreview,
  updateBulkVariations,
  updateBulkVariationsPreview,
} from '~/store/duck/bulkPrices'
import { updateChargeSheetSubItems } from '~/store/duck/clientFinanceData'
import { updateConnectedDevices } from '~/store/duck/connectedDevices'
import { updateContacts } from '~/store/duck/contacts'
import { updateDepartments } from '~/store/duck/departments'
import { updateDiscountGroups } from '~/store/duck/discountGroups'
import { updateImagingDashboardRecords } from '~/store/duck/imagingDashboard'
import { updateImagingOrders } from '~/store/duck/imagingOrders'
import { updateAdjustments } from '~/store/duck/inventoryAdjustments'
import { updateLabTestsDashboard } from '~/store/duck/labTestsDashboard'
import { updateMarketplaceItems } from '~/store/duck/marketplace'
import { updateMembershipPayments } from '~/store/duck/membershipPayments'
import {
  updateOnHandHistories,
  updateOnHandVariations,
} from '~/store/duck/onHandCatalog'
import { updatePrescriptions } from '~/store/duck/prescriptions'
import { updateReminderProtocolGroups } from '~/store/duck/reminderProtocols'
import { updateReminders, updateRemindersGroups } from '~/store/duck/reminders'
import { updateRhapsodyPayConfigs } from '~/store/duck/rhapsodyPay'
import { updateShipmentItems } from '~/store/duck/shipmentItems'
import { updateShipments } from '~/store/duck/shipments'
import { updateTimeTrackerEntities } from '~/store/duck/timeTracker'
import { updateVitals } from '~/store/duck/vitals'

import { updateInvoice, updateSubItems } from '../../reducers/invoiceV3'

const map = {
  // order is important sometimes
  appointmentTypes: updateAppointmentTypes,
  roles: updateRoles,
  patients: updatePatients,
  users: updateUsers,
  events: updateEvents,
  payments: updatePayments,
  goPayments: updateGoPayments,
  invoices: updateInvoices,
  invoicesV3: updateInvoice,
  invoiceV3SubItems: updateSubItems,
  contacts: updateContacts,
  spaces: updateSpaces,
  tasks: updateTasks,
  conversations: updateConversations,
  orders: R.pipe(R.values, updateMultipleOrders),
  imagingOrder: updateImagingOrders,
  reminders: updateReminders,
  remindersGroups: updateRemindersGroups,
  wellnessPlansVersions: updateWellnessPlansVersions,
  bundles: updateBundles,
  businesses: updateBusinesses,
  vitals: updateVitals,
  addendums: updateAddendums,
  documents: updateDocuments,
  notificationItem: updateNotifications,
  goStripePayments: updateGoStripePayments,
  labTestsDashboardRecords: updateLabTestsDashboard,
  timeEntities: updateTimeTrackerEntities,
  bulkVariations: updateBulkVariations,
  bulkVariationsPreview: updateBulkVariationsPreview,
  bulkPrices: updateBulkPrices,
  bulkPricesPreview: updateBulkPricesPreview,
  discountGroups: updateDiscountGroups,
  reminderProtocolGroups: updateReminderProtocolGroups,
  membershipPayments: updateMembershipPayments,
  adjustments: updateAdjustments,
  availabilityRules: updateAvailabilityRules,
  departments: updateDepartments,
  connectedDevices: updateConnectedDevices,
  shipments: updateShipments,
  shipmentItems: updateShipmentItems,
  imagingDashboardRecord: updateImagingDashboardRecords,
  marketplaceItems: updateMarketplaceItems,
  onHandVariations: updateOnHandVariations,
  onHandHistories: updateOnHandHistories,
  questions: updateQuestions,
  inventories: updateInventories,
  rhapsodyPayConfigs: updateRhapsodyPayConfigs,
  chargeSheetSubItems: updateChargeSheetSubItems,
  prescriptionsV2: updatePrescriptions,
}

const mapKeys = Object.keys(map)

type EntitiesMapKey = keyof typeof map

export type EntitiesToUpdate = {
  [Property in EntitiesMapKey]?: Parameters<(typeof map)[Property]>[0]
}

type EntitiesToUpdateParams = {
  [Property in EntitiesMapKey]?: any
}

export default function* updateEntities(
  entities: EntitiesToUpdate = {},
  params: EntitiesToUpdateParams = {},
) {
  const entitiesWithMapping = R.pick(mapKeys, entities)
  yield all(
    Object.entries(entitiesWithMapping)
      .map(([key, entity = {}]) => {
        if (
          Object.prototype.hasOwnProperty.call(
            entity,
            undefined as unknown as PropertyKey,
          )
        ) {
          const { undefined: omit, ...validEntities } = entity
          return [key, { ...validEntities }]
        }
        return [key, entity]
      })
      .filter(([, entity]) => !R.isEmpty(entity))
      .map(([key, entity]) => {
        const updateFunction = map[key as EntitiesMapKey]
        const additionalParams = params[key as EntitiesMapKey] || []
        return put(updateFunction(entity, ...additionalParams))
      }),
  )
}
