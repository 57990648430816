import React from 'react'
import { Grid, Table, TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { FieldValidator, Text, TextWithTooltip } from '@pbt/pbt-ui-components'
import { formatMoney } from '@pbt/pbt-ui-components/src/utils/numberUtils'

import { ConfirmAlertType } from '~/constants/DialogNames'

import EditItemDiscountPoper from './EditItemDiscountPoper'

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiTableCell-sizeMedium': {
        padding: 0,
        borderBottom: 'none',
      },
      '& .MuiTableCell-footer': {
        padding: 0,
        borderBottom: 'none',
      },
    },
    tooltip: {
      backgroundColor: 'black',
      color: 'white',
      borderColor: 'black',
      '&::before, &::after': {
        display: 'none',
      },
    },
  }),
  { name: 'DiscountItems' },
)

type ItemDiscount = {
  InputProps?: {
    endAdornment?: React.JSX.Element
    startAdornment?: React.JSX.Element
  }
  confirmationDialogText?: string
  customValidators?: FieldValidator[]
  description?: string
  editable: boolean
  id: ConfirmAlertType | string
  name: string
  save?: (discountPerc: number) => void
  tooltipText: string | null
  value: string | number
}

type DiscountItemsProps = {
  discounts: ItemDiscount[]
  sectionContainsInvoiceLineItems: boolean
}

const DiscountItems = ({
  discounts,
  sectionContainsInvoiceLineItems,
}: DiscountItemsProps) => {
  const classes = useStyles()

  return (
    <Table aria-label="discounts" className={classes.root}>
      {discounts.map(
        ({
          id,
          name,
          value,
          editable,
          tooltipText,
          description,
          confirmationDialogText,
          InputProps,
          save,
          customValidators,
        }: (typeof discounts)[0]) =>
          value ||
          (R.includes(id, [
            // Fields should always display, even if 0, when ILI present
            ConfirmAlertType.ADDITIONAL_DISCOUNT,
            ConfirmAlertType.ITEM_DISCOUNT,
          ]) &&
            sectionContainsInvoiceLineItems) ? (
            <TableRow key={id}>
              <TableCell>
                <TextWithTooltip
                  noIcon
                  TooltipProps={{
                    classes: {
                      tooltip: classes.tooltip,
                    },
                  }}
                  tooltipText={tooltipText}
                  variant="body2"
                >
                  {name}
                </TextWithTooltip>
              </TableCell>
              <TableCell colSpan={2}>
                <Grid container justifyContent="flex-end">
                  <Text mr={editable ? 0 : 2.5} variant="body2">
                    {value === 0 ? formatMoney(value) : value}
                    {/* ^^^  Format ConfirmAlertType.ADDITIONAL_DISCOUNT, ConfirmAlertType.ITEM_DISCOUNT */}
                  </Text>
                  {editable && confirmationDialogText && InputProps && save && (
                    <EditItemDiscountPoper
                      InputProps={InputProps}
                      confirmDialogText={confirmationDialogText}
                      customValidators={customValidators}
                      description={description}
                      id={id as ConfirmAlertType}
                      save={save}
                    />
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ) : null,
      )}
    </Table>
  )
}

export default DiscountItems
