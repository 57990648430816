import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Utils } from '@pbt/pbt-ui-components'

import Bundles from './empty-list-placeholders/Bundles'
import Drugs from './empty-list-placeholders/Drugs'
import FoodSupplementsSupplies from './empty-list-placeholders/FoodSupplementsSupplies'
import LabTests from './empty-list-placeholders/LabTests'
import Procedures from './empty-list-placeholders/Procedures'
import Questions from './empty-list-placeholders/Questions'
import Vaccines from './empty-list-placeholders/Vaccines'

const placeholdersMap = [
  { name: 'supplies', component: FoodSupplementsSupplies },
  { name: 'food & supplements', component: FoodSupplementsSupplies },
  { name: 'drugs', component: Drugs },
  { name: 'lab tests', component: LabTests },
  { name: 'vaccines', component: Vaccines },
  { name: 'procedures', component: Procedures },
  { name: 'bundles', component: Bundles },
  { name: 'questions', component: Questions },
]

export interface EmptyListPlaceholderProps {
  categoryName: string
  categoryType: string
  className?: string
  onFullList?: () => void
}

const EmptyListPlaceholder = ({
  className,
  categoryName,
  categoryType,
  onFullList,
}: EmptyListPlaceholderProps) => {
  const { t } = useTranslation('Common')

  const placeholder = Utils.findByName(categoryType, placeholdersMap)
  const MessageComponent = placeholder && placeholder.component

  return (
    <Text className={className} p={1} variant="body2">
      {t('Common:EMPTY_LIST_PLACEHOLDER', {
        categoryName,
      })}
      {MessageComponent ? (
        <MessageComponent categoryName={categoryName} onFullList={onFullList} />
      ) : (
        '.'
      )}
    </Text>
  )
}
export default EmptyListPlaceholder
