import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  Constant,
  FieldProp,
  LanguageUtils,
  useFields,
  WellnessPlan,
  WellnessPlanPrice,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import { getWellnessPlanPriceType } from '~/store/reducers/constants'
import { DataHandleWithUnsavedChanges } from '~/types'
import { isFieldValuesChanged } from '~/utils'

import WellnessPlanMultiPriceTextField from './WellnessPlanMultiPriceTextField'

const useStyles = makeStyles(
  (theme) => ({
    group: {
      marginBottom: theme.spacing(2),
    },
    radioLabel: {
      fontSize: '1.6rem',
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: theme.spacing(0.5),
    },
    labelRoot: {
      margin: 0,
      '&:not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
    },
  }),
  { name: 'WellnessPlanPriceTypeControl' },
)

export interface WellnessPlanPriceTypeControlProps {
  disableEdit?: boolean
  plan: WellnessPlan
  wellnessPlanVersion: WellnessPlanVersion
}

export interface WellnessPlanPriceTypeControlHandle
  extends DataHandleWithUnsavedChanges<WellnessPlanPrice[]> {
  getDeletedPriceIds: () => string[]
}

const WellnessPlanPriceTypeControl = forwardRef<
  WellnessPlanPriceTypeControlHandle,
  WellnessPlanPriceTypeControlProps
>(function WellnessPlanPriceTypeControl(
  { plan, disableEdit, wellnessPlanVersion },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const WellnessPlanPriceType: Constant[] = useSelector(
    getWellnessPlanPriceType,
  )

  const { fields, validate } = useFields(
    R.pipe(
      R.map<Constant, FieldProp[]>(({ id: priceId }) => [
        {
          name: `${priceId}_price`,
          validators: ['nonNegative'],
          type: 'number',
          initialValue: plan.prices
            ? plan.prices.find((price) => price.priceTypeId === priceId)
                ?.price || 0
            : plan.price,
        },
        {
          name: `${priceId}_priceEnabled`,
          type: 'toggle',
          initialValue: plan.prices
            ? plan.prices.some((price) => price.priceTypeId === priceId)
            : priceId === plan.priceTypeId,
        },
      ]),
      R.flatten,
    )(WellnessPlanPriceType),
  )

  const priceEnabledFields = Object.values(fields).filter((field) =>
    R.endsWith('priceEnabled', field.name),
  )
  const selectedPriceEnabledFields = priceEnabledFields.filter(
    (field) => field.value,
  )
  const allPriceEnabledSelected =
    selectedPriceEnabledFields.length === priceEnabledFields.length

  const handlePricesChange = (value: string) => {
    const isAll = value === 'all'

    priceEnabledFields.forEach((field) => {
      if (isAll) {
        field.setValue(true)
      } else {
        field.setValue(R.startsWith(value, field.name))
      }
    })
  }

  useImperativeHandle(ref, () => ({
    validate,
    get: () =>
      priceEnabledFields
        .filter((field) => field.value)
        .map((field) => {
          const [priceId] = field.name.split('_')
          const oldPrice =
            (plan.prices || []).find(
              (price) => price.priceTypeId === priceId,
            ) || {}

          return {
            ...oldPrice,
            price: Number(fields[`${priceId}_price`].value),
            priceTypeId: priceId,
          } as WellnessPlanPrice
        }),
    getDeletedPriceIds: () => {
      const filteredPrices = (plan.prices || []).filter(
        (price) => !fields[`${price.priceTypeId}_priceEnabled`].value,
      )

      return R.pluck('id', filteredPrices)
    },
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  return (
    <>
      <RadioGroup
        row
        aria-label="plan-price-type-group"
        className={classes.group}
        name="plan-price-type-group-selection"
        value={
          allPriceEnabledSelected
            ? 'all'
            : selectedPriceEnabledFields[0]?.name.split('_')[0] || ''
        }
        onChange={(_, value) => handlePricesChange(value)}
      >
        {WellnessPlanPriceType.map((priceType) => (
          <FormControlLabel
            classes={{
              root: classes.labelRoot,
              label: classes.radioLabel,
            }}
            control={<Radio className={classes.radio} disabled={disableEdit} />}
            key={priceType.id}
            label={LanguageUtils.getTranslatedFieldName(priceType)}
            value={priceType.id}
          />
        ))}
        <FormControlLabel
          classes={{
            root: classes.labelRoot,
            label: classes.radioLabel,
          }}
          control={<Radio className={classes.radio} disabled={disableEdit} />}
          label={t('Common:BOTH')}
          value="all"
        />
      </RadioGroup>
      <WellnessPlanMultiPriceTextField
        disableEdit={disableEdit}
        fields={fields}
        plan={plan}
        wellnessPlanVersion={wellnessPlanVersion}
      />
    </>
  )
})

export default WellnessPlanPriceTypeControl
