const getStorageKey = (userId: string) => `RECENT_LIST_${userId}`
const MAX_STORAGE_ITEMS = 20

function readFromStorage(userId: string): HistoryItem[] {
  const str = localStorage.getItem(getStorageKey(userId))
  return str ? JSON.parse(str) : []
}

function writeToStorage(userId: string, items: HistoryItem[] = []) {
  localStorage.setItem(getStorageKey(userId), JSON.stringify(items))
}

export type HistoryItem = {
  title: string
  url: string
}

function addToRecentList(userId: string, historyItem: HistoryItem) {
  const items = readFromStorage(userId)
  const trimmedHistoryItem = { ...historyItem, title: historyItem.title.trim() }
  const newItems =
    items.length === 0 || items[0].title !== trimmedHistoryItem.title
      ? [trimmedHistoryItem, ...items]
      : items

  const newCutItems =
    newItems.length > MAX_STORAGE_ITEMS
      ? newItems.slice(0, MAX_STORAGE_ITEMS)
      : newItems

  writeToStorage(userId, newCutItems)
}

export { addToRecentList, readFromStorage, writeToStorage }
