import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getInventoryOnHandStatus } from '~/store/reducers/constants'

export interface OnHandStatusFilterProps extends CheckboxListFilterProps {}

const OnHandStatusFilter = (props: OnHandStatusFilterProps) => {
  const InventoryOnHandStatus = useSelector(getInventoryOnHandStatus)

  return <CheckboxListFilter items={InventoryOnHandStatus} {...props} />
}

export default OnHandStatusFilter
