import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiPopper, PuiPopperProps } from '@pbt/pbt-ui-components'

import EmailPreview from '~/components/common/emailPreview/EmailPreview'
import {
  clearConversationEmailPreview,
  fetchConversationEmailPreview,
} from '~/store/actions/conversations'
import { getConversationEmailPreview } from '~/store/reducers/conversations'

const useStyles = makeStyles(
  () => ({
    root: {
      width: 450,
    },
  }),
  { name: 'EmailPreviewPopper' },
)

export interface EmailPreviewPopperProps extends PuiPopperProps {
  content: string
  includeHeaderAndFooter?: boolean
}

const EmailPreviewPopper = ({
  open,
  content,
  includeHeaderAndFooter,
  ...rest
}: EmailPreviewPopperProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const previewTemplate = useSelector(getConversationEmailPreview)

  useEffect(() => {
    if (open && !previewTemplate) {
      const config = { includeHeaderAndFooter, content }
      dispatch(fetchConversationEmailPreview(config))
    }
  }, [previewTemplate, open])

  useEffect(() => {
    dispatch(clearConversationEmailPreview())
  }, [includeHeaderAndFooter, content])

  return (
    <PuiPopper open={open} {...rest}>
      <Grid container item className={classes.root} direction="column" p={3}>
        <EmailPreview emailTemplate={previewTemplate} />
      </Grid>
    </PuiPopper>
  )
}

export default EmailPreviewPopper
