import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, TextWithTooltip } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    toolTip: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'ListItem' },
)

interface ObsoleteListItemProps {
  classes?: ClassesType<typeof useStyles>
  name: string
}

const ObsoleteListItem = ({
  classes: classesProp,
  name,
}: ObsoleteListItemProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common'])

  return (
    <>
      <TextWithTooltip
        disabled
        noWrap
        className={classes.toolTip}
        iconPlacement="left"
        tooltipText={t('Common:OBSOLETE')}
        variant="body2"
      >
        {name}
      </TextWithTooltip>
    </>
  )
}

export default ObsoleteListItem
