import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { Nil, Utils } from '@pbt/pbt-ui-components'
import { Edit, Eye } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import { removeOrder } from '~/store/actions/orders'
import { cancelOrder } from '~/store/duck/imagingOrders'
import { useIsIntegratedModality } from '~/store/hooks/imagingOrders'
import { getCurrentBusinessIsIdexxImagingEnabled } from '~/store/reducers/auth'
import { getImagingOrderStatuses } from '~/store/reducers/constants'
import { getSoapId } from '~/store/reducers/soap'
import { ImagingOrder, Order } from '~/types'
import useDialog from '~/utils/useDialog'

import { isCompletedImagingStatus } from '../../../imaging-procedures/imagingProceduresUtils'
import { FinalizeOrdersActionButton } from '../actions/FinalizeOrdersActionButton'

interface FinalizeImagingOrdersActionsProps {
  finalizedOrder: ImagingOrder | Nil
  outstandingOrder: Order | Nil
}

export const FinalizeImagingOrdersActions = ({
  finalizedOrder,
  outstandingOrder,
}: FinalizeImagingOrdersActionsProps) => {
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()
  const [openImagingOrderDialog] = useDialog(DialogNames.IMAGING_ORDER)

  const soapId = useSelector(getSoapId)
  const ImagingOrderStatuses = useSelector(getImagingOrderStatuses)
  const isImagingIntegrated = useSelector(
    getCurrentBusinessIsIdexxImagingEnabled,
  )

  const { statusId } = finalizedOrder || {}
  const orderStatusName = Utils.getConstantName(statusId, ImagingOrderStatuses)

  const isIntegratedModality = useIsIntegratedModality(
    finalizedOrder?.procedure?.modalityId,
  )
  const canEdit = isImagingIntegrated && isIntegratedModality
  const canViewResults =
    finalizedOrder && isCompletedImagingStatus(orderStatusName)
  const canCancel = !canViewResults

  const handleEdit = () => {
    if (finalizedOrder?.id) {
      openImagingOrderDialog({
        orderId: finalizedOrder?.id,
        soapId,
      })
    } else if (outstandingOrder?.id) {
      openImagingOrderDialog({
        soapLog: outstandingOrder,
        orderId: outstandingOrder?.imagingOrderId,
        soapId,
      })
    }
  }

  const handleView = () => {
    const resultsUrl = finalizedOrder?.resultsUrl
    if (resultsUrl) {
      window.open(resultsUrl)
    }
  }

  const handleCancel = () => {
    if (finalizedOrder?.id) {
      dispatch(cancelOrder(finalizedOrder?.id))
    } else if (outstandingOrder?.id) {
      dispatch(removeOrder(outstandingOrder))
    }
  }

  // There will always be at least one action: view and cancel are mutually exclusive
  return (
    <>
      {canEdit && (
        <FinalizeOrdersActionButton
          handler={handleEdit}
          icon={<Edit />}
          text={t('Common:EDIT_ACTION')}
        />
      )}
      {canViewResults && (
        <FinalizeOrdersActionButton
          handler={handleView}
          icon={<Eye />}
          text={t('Common:VIEW_IMAGES')}
        />
      )}
      {canCancel && (
        <FinalizeOrdersActionButton
          handler={handleCancel}
          icon={<CloseIcon />}
          text={t('Common:CANCEL_ORDER')}
        />
      )}
    </>
  )
}
