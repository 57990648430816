import React from 'react'
import { TableCell } from '@mui/material'

export interface TaxCellEstimateRangeProps {
  className?: string
}

const TaxCellEstimateRange = ({ className }: TaxCellEstimateRangeProps) => (
  <TableCell className={className} colSpan={2} />
)

export default TaxCellEstimateRange
