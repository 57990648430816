import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  AtLeast,
  BasePuiDialogProps,
  DateUtils,
  LanguageUtils,
  Nil,
  NumberUtils,
} from '@pbt/pbt-ui-components'

import ListWithChildrenDialog, {
  ListWithChildrenDialogProps,
} from '~/components/common/dialog/ListWithChildrenDialog'
import DialogNames from '~/constants/DialogNames'
import { addEstimateToSOAPOrEvent } from '~/store/actions/finance'
import { fetchUpcomingEvents } from '~/store/actions/scheduler'
import {
  getFinanceInvoicesMap,
  getFinanceIsLoading,
} from '~/store/reducers/finance'
import {
  getSchedulerIsLoading,
  getSchedulerUpcomingAppointmentsList,
} from '~/store/reducers/scheduler'
import {
  getMultipleTimetableEvents,
  getTimeTableIsCreatingAppointment,
  getTimetableLastAppointmentId,
} from '~/store/reducers/timetable'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

export interface AppointmentsListDialogProps
  extends AtLeast<ListWithChildrenDialogProps, keyof BasePuiDialogProps> {
  autoCopyItems?: boolean
  clientId: string | Nil
  estimateId: string
  onChangeAttachingToSoapEstimateId?: (estimateId: string) => void
  onChangeSoapToAttachEstimateId?: (soapId: string) => void
  patientId: string | Nil
}

const AppointmentsListDialog = ({
  estimateId,
  onClose,
  open,
  patientId,
  clientId,
  autoCopyItems = false,
  onChangeAttachingToSoapEstimateId,
  onChangeSoapToAttachEstimateId,
  ...rest
}: AppointmentsListDialogProps) => {
  const dispatch = useDispatch()
  const eventsList = useSelector(getSchedulerUpcomingAppointmentsList)
  const events = useSelector(getMultipleTimetableEvents(eventsList))
  const invoicesMap = useSelector(getFinanceInvoicesMap)
  const isFetching = useSelector(getSchedulerIsLoading)
  const isSaving = useSelector(getFinanceIsLoading)
  const lastAppointmentId = useSelector(getTimetableLastAppointmentId)
  const { t } = useTranslation(['Common', 'Invoices'])

  const fetchUpcomingEventsIfNeeded = () => {
    if (open && clientId && patientId) {
      dispatch(fetchUpcomingEvents(clientId, patientId, true))
    }
  }

  const [openEventDialog] = useDialog(
    DialogNames.EVENT,
    fetchUpcomingEventsIfNeeded,
  )

  const setCloseAfterCreation = useCloseAfterCreation(
    onClose,
    getFinanceIsLoading,
  )

  const linkEstimateToAppointment = (eventId: string, allowNewSoap = false) => {
    onChangeAttachingToSoapEstimateId?.(estimateId)
    onChangeSoapToAttachEstimateId?.(eventId)

    dispatch(
      addEstimateToSOAPOrEvent({
        allowNewSoap,
        estimateId,
        soapId: null,
        eventId,
        copyItems: autoCopyItems,
        cloningOptions: {
          allowClone: false,
          checkInactiveLineItems: true,
        },
      }),
    )
    setCloseAfterCreation()
  }

  const afterAppointmentCreated = useCloseAfterCreation(() => {
    if (lastAppointmentId) {
      linkEstimateToAppointment(lastAppointmentId, true)
    }
  }, getTimeTableIsCreatingAppointment)

  const openAppointmentDialog = useCallback(() => {
    openEventDialog({
      clientId,
      patientId,
      onOk: () => {
        afterAppointmentCreated()
      },
    })
  }, [clientId, patientId])

  useEffect(() => {
    fetchUpcomingEventsIfNeeded()
  }, [open, clientId, patientId])

  return (
    <ListWithChildrenDialog
      addButtonLabel={t('Common:ADD_TO_APPOINTMENT')}
      createButtonLabel={t('Common:CREATE_NEW_APPOINTMENT')}
      emptyPlaceholder={t('Invoices:APPOINTMENTS_LIST.EMPTY_PLACEHOLDER')}
      getChildName={(childId) => {
        const estimate = invoicesMap[childId]
        return (
          estimate &&
          `${estimate.name} (${t(
            'Common:TOTAL',
          ).toLowerCase()}: ${NumberUtils.formatMoney(estimate.amount)})`
        )
      }}
      getName={(appointment) => {
        const appointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
          appointment?.businessAppointmentType,
        )

        return `${appointmentTypeDisplayName} ${DateUtils.formatDate(
          appointment.scheduledStartDatetime,
        )}`
      }}
      isFetching={isFetching}
      isSaving={isSaving}
      items={events}
      open={open}
      propWithChildren="estimates"
      subTitle={t('Invoices:APPOINTMENTS_LIST.SUBTITLE')}
      title={t('Invoices:APPOINTMENTS_LIST.TITLE')}
      tooltipText={t('Invoices:APPOINTMENTS_LIST.TOOLTIP_TEXT')}
      onAddClick={linkEstimateToAppointment}
      onClose={onClose}
      onCreateClick={() => openAppointmentDialog()}
      {...rest}
    />
  )
}

export default AppointmentsListDialog
