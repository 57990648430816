import { PluginName, ToolbarItem } from '@pbt/pbt-ckeditor-ui'

import { PuiCKEditorVariant } from '~/types'

const ToolbarMap: Record<PuiCKEditorVariant, ToolbarItem[]> = {
  [PuiCKEditorVariant.MEDICAL_OR_DISCHARGE]: [
    'heading',
    'importNotesTemplate',
    '|',
    'bold',
    'italic',
    'underline',
    'link',
    'bulletedList',
    'numberedList',
    'todoList',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'insertTable',
    'imageUpload',
    'undo',
    'redo',
    'comment',
    'trackChanges',
  ],
  [PuiCKEditorVariant.ORDER]: [
    'importNotesTemplate',
    'bold',
    'italic',
    'bulletedList',
    'numberedList',
    'todoList',
  ],
}

const RemovePluginsMap: Record<PuiCKEditorVariant, PluginName[]> = {
  [PuiCKEditorVariant.MEDICAL_OR_DISCHARGE]: ['Placeholder'],
  [PuiCKEditorVariant.ORDER]: [
    'Placeholder',
    'TrackChanges',
    'RealTimeCollaborativeTrackChanges',
    'Comments',
    'RealTimeCollaborativeComments',
  ],
}

export const useGetEditorConfigByVariant = (variant: PuiCKEditorVariant) => ({
  toolbar: ToolbarMap[variant],
  removePlugins: RemovePluginsMap[variant],
})
