import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { ConnectedDevice } from '~/types'

import {
  DELETE_CONNECTED_DEVICE,
  deleteConnectedDevice,
  deleteConnectedDeviceFailure,
  deleteConnectedDeviceSuccess,
  FETCH_CONNECTED_DEVICES,
  fetchConnectedDevicesFailure,
  fetchConnectedDevicesSuccess,
  getConnectedDevice,
} from '../duck/connectedDevices'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchConnectedDevicesSaga() {
  try {
    const { result, entities } = yield* requestAPI(API.fetchConnectedDevices)

    yield call(updateEntities, entities)
    yield put(fetchConnectedDevicesSuccess(result))
  } catch (error) {
    yield put(fetchConnectedDevicesFailure(error as ApiError))
  }
}

export function* deleteConnectedDeviceSaga({
  deviceId,
}: ReturnType<typeof deleteConnectedDevice>) {
  try {
    const device: ConnectedDevice = yield select(getConnectedDevice(deviceId))

    yield* requestAPI(API.deleteConnectedDevice, device.auth0Id)

    yield put(deleteConnectedDeviceSuccess(deviceId))
  } catch (error) {
    yield put(deleteConnectedDeviceFailure(error as ApiError))
  }
}

function* watchFetchConnectedDevices() {
  yield takeLatest(FETCH_CONNECTED_DEVICES, fetchConnectedDevicesSaga)
}

function* watchDeleteConnectedDevice() {
  yield takeLatest(DELETE_CONNECTED_DEVICE, deleteConnectedDeviceSaga)
}

export default function* connectedConnectedDevicesSaga() {
  yield all([watchFetchConnectedDevices(), watchDeleteConnectedDevice()])
}
