import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ImageScalingUtils,
  LanguageUtils,
  PatientAvatar,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import ApproximateAgeLabel from '~/components/common/labels/ApproximateAgeLabel'
import BreedLabel from '~/components/common/labels/BreedLabel'
import NeuteringLabel from '~/components/common/labels/NeuteringLabel'
import { AlertColorLevel } from '~/constants/alertColors'
import { useAlertType } from '~/store/hooks/patient'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getPatientAge } from '~/utils'

import useGetPreferredContactMethod from '../../clients/details/new-client-and-patient/useGetPreferredContactMethod'
import WellnessPlanMemberButton from '../../wellness-plans/WellnessPlanMemberButton'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: 330,
      backgroundColor: theme.colors.tableBackground,
    },
    avatar: {
      width: 56,
      height: 56,
    },
    planButton: {
      margin: theme.spacing(1, 0),
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 0.75),
    },
    planButtonLoading: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    alertRoot: {
      minHeight: theme.spacing(3),
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0.25, 0.5, 0.25, 1),
      justifyContent: 'flex-start',
      borderRadius: theme.spacing(0.5),
    },
    clientAlert: {
      marginTop: theme.spacing(0.5),
    },
    alertText: {
      fontSize: '1.4rem',
    },
    alertIcon: {
      fontSize: '1.7rem',
    },
    label: {
      fontWeight: 500,
    },
    detailsString: {
      display: 'flex',
    },
    contactPhone: {
      lineHeight: '2.4rem',
    },
    spacer: {
      padding: theme.spacing(0, 0.5),
    },
    patientDetails: {
      marginTop: theme.spacing(-0.5),
      lineHeight: '20px',
    },
  }),
  { name: 'ClientAndPatientTooltip' },
)

interface ClientAndPatientTooltipProps {
  clientId?: string
  patientId?: string
}

const ClientAndPatientTooltip = ({
  clientId,
  patientId,
}: ClientAndPatientTooltipProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const Species = useSelector(getSpecies)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))

  const isLoadingClient = !client?.id
  const isLoadingPatient = !patient?.id
  const loading = isLoadingPatient || isLoadingClient

  const { value: preferredPhoneNumber } = useGetPreferredContactMethod({
    client,
    phoneOnly: true,
  })

  const { alertsToRender } = useAlertType(patientId)
  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  const isApproximateAge = Boolean(
    patient?.dateOfBirth && patient?.approximateAge,
  )

  return (
    <Grid container className={classes.container} direction="column" p={2}>
      <Grid container wrap="nowrap">
        <Grid item mr={1}>
          <PatientAvatar
            small
            animal={Utils.getConstantName(patient?.species, Species)}
            className={classes.avatar}
            isLoading={isLoadingPatient}
            showBoopBadge={patient?.isBoop}
            src={avatarSrc}
          />
        </Grid>

        <Grid
          container
          item
          className={classes.patientDetails}
          direction="column"
        >
          <Grid item>
            <Text variant="h4">{patient?.name}</Text>
          </Grid>
          <Grid container item>
            <Grid item>
              <ApproximateAgeLabel
                noWrap
                align="left"
                isApproximate={isApproximateAge}
                isLoading={isLoadingPatient}
                loaderHeight={24}
              >
                {patient?.dateOfBirth ? `${getPatientAge(patient)} ` : ''}
              </ApproximateAgeLabel>
            </Grid>
            {isLoadingPatient ? (
              <Grid container direction="column">
                <Skeleton height={16} variant="rectangular" width="100%" />
                <Box m={0.5} />
                <Skeleton height={16} variant="rectangular" width="100%" />
              </Grid>
            ) : (
              <>
                <span className={classes.detailsString}>
                  {patient?.dateOfBirth && patient?.gender && (
                    <span className={classes.spacer}>{' | '}</span>
                  )}
                  <NeuteringLabel patient={patient} />
                </span>
                {patient?.species && (
                  <span className={classes.detailsString}>
                    <span className={classes.spacer}>{' | '}</span>
                    {LanguageUtils.getConstantTranslatedName(
                      patient.species,
                      Species,
                    )}
                  </span>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            <BreedLabel
              isLoading={isLoadingPatient}
              loaderHeight={24}
              loaderWidth={120}
              patient={patient}
            />
          </Grid>
        </Grid>
      </Grid>

      {!R.isEmpty(alertsToRender) && (
        <AlertLabel
          showIcon
          alertColorId={patient?.alertColorId}
          alertColorLevel={AlertColorLevel.PATIENT}
          alertTypes={alertsToRender}
          clamp={Number.MAX_SAFE_INTEGER}
          classes={{
            root: classes.alertRoot,
            text: classes.alertText,
            icon: classes.alertIcon,
          }}
        />
      )}

      <WellnessPlanMemberButton
        readonly
        className={classNames(classes.planButton, {
          [classes.planButtonLoading]: loading,
        })}
        clientId={clientId}
        isLoading={loading}
        patientId={patientId}
      />

      {patient?.notes && (
        <Text variant="body2">
          <span className={classes.label}>{t('Common:PATIENT_NOTES')}: </span>
          {patient?.notes}
        </Text>
      )}

      <Grid container justifyContent="space-between" mt={3}>
        <Grid item>
          <Text variant="h4">{Utils.getPersonString(client)}</Text>
        </Grid>
        <Text strong>{preferredPhoneNumber}</Text>
      </Grid>

      {client?.alertText && (
        <AlertLabel
          showIcon
          alertColorId={client?.alertColorId}
          alertColorLevel={AlertColorLevel.CLIENT}
          clamp={Number.MAX_SAFE_INTEGER}
          classes={{
            root: classNames(classes.alertRoot, classes.clientAlert),
            text: classes.alertText,
            icon: classes.alertIcon,
          }}
          message={client.alertText}
        />
      )}

      {client?.notes && (
        <Text variant="body2">
          <span className={classes.label}>{t('Common:CLIENT_NOTES')}: </span>
          {client.notes}
        </Text>
      )}
    </Grid>
  )
}

export default ClientAndPatientTooltip
