import React from 'react'
import { BasePuiDialogProps, Nil } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { Order, OrderVaccinationDetails } from '~/types'

import BaseValidationDialog, { FieldNames } from './BaseValidationDialog'

const TITLE = i18n.t(
  'Validations:YOU_ARE_MISSING_SOME_FIELDS_TO_COMPLETE_YOUR_VACCINE_CERTIFICATE',
)

const fieldsList = [
  FieldNames.DOCTOR,
  FieldNames.PATIENT_DATE_OF_BIRTH,
  FieldNames.PATIENT_MICROCHIP_NUMBER,
  FieldNames.PATIENT_SPECIES,
  FieldNames.PATIENT_BREED,
  FieldNames.PATIENT_COLOR,
  FieldNames.PATIENT_MARKINGS_TATTOO,
  FieldNames.PATIENT_LICENSE,
  FieldNames.PATIENT_RABIES_TAG,
  FieldNames.PATIENT_RABIES_TAG_EXPIRATION,
  FieldNames.PATIENT_GENDER,
  FieldNames.PATIENT_SPAYED_NEUTERED_STATUS,
  {
    name: FieldNames.CLIENT_FIRST_NAME,
    required: true,
  },
  {
    name: FieldNames.CLIENT_LAST_NAME,
    required: true,
  },
  FieldNames.CLIENT_ADDRESS,
  FieldNames.CLIENT_CITY,
  FieldNames.CLIENT_STATE,
  FieldNames.CLIENT_ZIPCODE,
]

interface VaccineCertificateValidationDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  defaultValues?: Record<string, any>
  disableVaccineUpdate?: boolean
  onOk?: (vaccinationDetails?: OrderVaccinationDetails) => void
  patientId: string | Nil
  requireRabiesUpdates?: boolean
  showSkipButton?: boolean
  vaccines?: Order[]
}

const VaccineCertificateValidationDialog = ({
  vaccines,
  showSkipButton = true,
  ...rest
}: VaccineCertificateValidationDialogProps) => (
  <BaseValidationDialog
    fieldsList={fieldsList}
    showSkipButton={showSkipButton}
    title={TITLE}
    vaccines={vaccines || []}
    {...rest}
  />
)

export default VaccineCertificateValidationDialog
