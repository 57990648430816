import React, { forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Stack } from '@mui/material'
import {
  Calendar,
  PuiTextArea,
  PuiTextField,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import { DiagnoseHistoryItem } from '~/types'
import useFieldsChanged from '~/utils/useFieldsChanged'

export interface DiagnosisHistoryExpandedDetailsProps {
  diagnosis: DiagnoseHistoryItem
  edit: boolean
  onUpdateItem: (item: DiagnoseHistoryItem) => void
  order?: DiagnoseHistoryItem
}

export interface DiagnosisHistoryDetailsHandle extends ValidateHandle {
  updateNotes: (notes: string) => void
}

const DiagnosisHistoryExpandedDetails = forwardRef<
  DiagnosisHistoryDetailsHandle,
  DiagnosisHistoryExpandedDetailsProps
>(function DiagnosisHistoryExpandedDetails(
  { edit, diagnosis: diagnosisProp, order, onUpdateItem },
  ref,
) {
  const diagnosis = diagnosisProp || order
  const { t } = useTranslation(['Common', 'MedicalHistory'])

  const { fields, validate } = useFields(
    [
      {
        name: 'date',
        label: t(
          'MedicalHistory:DIAGNOSIS_HISTORY_EXPANDED_DETAILS.DIAGNOSIS_DATE',
        ),
        validators: ['required'],
        initialValue: diagnosis?.date || '',
      },
      {
        name: 'diagnosedBy',
        label: t(
          'MedicalHistory:DIAGNOSIS_HISTORY_EXPANDED_DETAILS.DIAGNOSED_BY',
        ),
        initialValue: diagnosis?.diagnosedBy || diagnosis?.vet?.firstName || '',
      },
      {
        name: 'diagnosedIn',
        label: t(
          'MedicalHistory:DIAGNOSIS_HISTORY_EXPANDED_DETAILS.PRACTICE_WHERE_DIAGNOSED',
        ),
        initialValue: diagnosis?.diagnosedIn || '',
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: diagnosis?.notes || '',
      },
    ],
    false,
  )

  const { date, diagnosedBy, diagnosedIn, notes } = fields

  useFieldsChanged(() => {
    onUpdateItem({
      ...diagnosis,
      date: date.value,
      diagnosedBy: diagnosedBy.value,
      diagnosedIn: diagnosedIn.value,
      notes: notes.value,
      diagnosisId: diagnosis.diagnosisId || diagnosis.id,
    })
  }, fields)

  useImperativeHandle(ref, () => ({
    validate,
    updateNotes: (updatedNotes) => notes.setValue(updatedNotes),
  }))

  return (
    <Grid container item direction="column">
      <Stack alignItems="center" direction="row" spacing={2}>
        <Grid item>
          <Calendar field={date} label={`${date.label}*`} />
        </Grid>
        <Grid item xs>
          <PuiTextField
            fullWidth
            field={diagnosedBy}
            label={diagnosedBy.label}
          />
        </Grid>
      </Stack>
      <Grid item>
        <PuiTextField fullWidth field={diagnosedIn} label={diagnosedIn.label} />
      </Grid>
      {edit && (
        <Grid item>
          <PuiTextArea field={notes} placeholder={notes.label} />
        </Grid>
      )}
    </Grid>
  )
})

export default DiagnosisHistoryExpandedDetails
