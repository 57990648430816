export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE'
export const SEND_RESET_PASSWORD_EMAIL = 'auth/SEND_RESET_PASSWORD_EMAIL'
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS =
  'auth/SEND_RESET_PASSWORD_EMAIL_SUCCESS'
export const SEND_RESET_PASSWORD_EMAIL_FAILURE =
  'auth/SEND_RESET_PASSWORD_EMAIL_FAILURE'
export const UPDATE_PASSWORD = 'auth/UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'auth/UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'auth/UPDATE_PASSWORD_FAILURE'
export const FETCH_CURRENT_USER = 'auth/FETCH_CURRENT_USER'
export const FETCH_CURRENT_USER_FAILURE = 'auth/FETCH_CURRENT_USER_FAILURE'
export const FETCH_CURRENT_USER_SUCCESS = 'auth/FETCH_CURRENT_USER_SUCCESS'
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE'
export const SET_AUTHORIZATION_ERROR = 'auth/SET_AUTHORIZATION_ERROR'
export const CLEAR_AUTHORIZATION_ERROR = 'auth/CLEAR_AUTHORIZATION_ERROR'
export const SILENT_LOGIN_FAILURE = 'auth/SILENT_LOGIN_FAILURE'
export const SILENT_LOGIN_SUCCESS = 'auth/SILENT_LOGIN_SUCCESS'
export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST'
export const SILENT_LOGIN_REQUEST = 'auth/SILENT_LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const LOGOUT = 'auth/LOGOUT'
export const INVALIDATE_TOKEN = 'auth/INVALIDATE_TOKEN'
export const SET_LOG_OUT_TIME = 'auth/SET_LOG_OUT_TIME'
export const CLEAR_LOG_OUT_TIME = 'auth/CLEAR_LOG_OUT_TIME'
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN'
export const REFRESH_TOKEN_FAILURE = 'auth/REFRESH_TOKEN_FAILURE'
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS'
