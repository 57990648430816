import React from 'react'
import { Nil } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { BaseChargeTabProps, OrderListType } from '~/types'

import ChargeListWithFilters from '../ChargeListWithFilters'

export interface PrepaidChargeTabProps extends BaseChargeTabProps {
  appointmentId?: string
  clientId: string | Nil
  patientId?: string
}

const PrepaidChargeTab = (props: BaseChargeTabProps) => (
  <ChargeListWithFilters
    preselectedFilter={OrderType.PREPAID}
    preselectedListType={OrderListType.OUR}
    {...props}
  />
)

export default PrepaidChargeTab
