import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  CircularProgressOverlay,
  Nil,
  PuiDialog,
  StateLabel,
  Text,
} from '@pbt/pbt-ui-components'

import {
  patientMembershipsActions,
  patientMembershipsSelectors,
  // @ts-ignore
} from '~/store/duck/patientMemberships'
import { getPatient } from '~/store/reducers/patients'
import { Membership } from '~/types'
import { formatMembershipPeriod } from '~/utils/membershipPaymentUtils'

import { getBenefitIsAccessToBoop } from '../wellness-plans/wellnessPlanUtils'
import PatientMembershipDetails from './PatientMembershipDetails'
import PatientMembershipTable from './PatientMembershipTable'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
    },
    dialogTitle: {
      padding: 0,
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
    },
    dialogContentRoot: {
      minHeight: 150,
    },
    actions: {
      padding: theme.spacing(2),
    },
    stateLabel: {
      marginTop: theme.spacing(0.25),
      width: 'fit-content',
    },
  }),
  { name: 'PatientMembershipDialog' },
)

const getBenefits = R.pipe(
  R.pluck('benefits'),
  R.flatten,
  R.reject(getBenefitIsAccessToBoop),
)

interface PatientMembershipDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
}

const PatientMembershipDialog = ({
  clientId,
  patientId,
  open,
  onClose,
}: PatientMembershipDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patient = useSelector(getPatient(patientId))
  const isFetching: boolean = useSelector(
    patientMembershipsSelectors.getIsFetching,
  )
  const membership: Membership = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )
  const { t } = useTranslation(['Common', 'Dialogs', 'Tooltips'])

  useEffect(() => {
    if (clientId && patientId) {
      dispatch(patientMembershipsActions.fetchItem(clientId, patientId))
    }
  }, [clientId, patientId])

  const benefits = getBenefits(membership?.plans || [])
  const hasMigratedUsages = benefits.some((benefit) =>
    (benefit?.usages || []).some((usage) => !usage?.invoiceId),
  )

  return (
    <PuiDialog
      aria-labelledby="patient-membership-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        dialogTitle: classes.dialogTitle,
        paper: classes.paper,
        actions: classes.actions,
      }}
      header={
        <>
          <CircularProgressOverlay open={isFetching} />
          <Grid item pb={1} pt={2} px={3}>
            <Text variant="h2">
              {t('Dialogs:PATIENT_MEMBERSHIP_DIALOG.TITLE', {
                patientName: patient?.name,
              })}
            </Text>
          </Grid>
          {patientId && (
            <Grid item pb={2} px={3}>
              <PatientMembershipTable
                clientId={clientId}
                patientId={patientId}
                onClose={onClose}
              />
            </Grid>
          )}
          {benefits?.length > 0 && (
            <>
              <Text strong mb={2} mt={3} mx={3} variant="subheading3">
                {t('Dialogs:PATIENT_MEMBERSHIP_DIALOG.PLAN_DETAILS')}
              </Text>
              <Grid container item>
                <Grid item pb={1} pl={3} xs={3}>
                  <Text strong variant="lowAccent2">
                    {t('Common:BENEFIT')}
                  </Text>
                </Grid>
                <Grid item pb={1} pl={2} xs={2}>
                  <Text strong variant="lowAccent2">
                    {t('Common:PLAN')}
                  </Text>
                </Grid>
                <Grid item pb={1} pl={2} xs={4}>
                  <Text strong variant="lowAccent2">
                    {t(
                      'Dialogs:PATIENT_MEMBERSHIP_DIALOG.PLAN_USED_FOR_PERIOD',
                    )}
                    &nbsp;
                    {formatMembershipPeriod(membership)}
                  </Text>
                  {hasMigratedUsages && (
                    <StateLabel
                      disabled
                      className={classes.stateLabel}
                      variant="small"
                    >
                      {t(
                        'Dialogs:PATIENT_MEMBERSHIP_DIALOG.SOME_USAGE_STATE_LABEL',
                      )}
                    </StateLabel>
                  )}
                </Grid>
                <Grid item xs pb={1} pl={2}>
                  <Text strong variant="lowAccent2">
                    {t('Dialogs:PATIENT_MEMBERSHIP_DIALOG.WHAT_IS_COVERED')}
                  </Text>
                </Grid>
              </Grid>
            </>
          )}
        </>
      }
      open={open}
      scroll="paper"
      onClose={onClose}
    >
      {!isFetching && patientId && (
        <PatientMembershipDetails patientId={patientId} />
      )}
    </PuiDialog>
  )
}

export default PatientMembershipDialog
