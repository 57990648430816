import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { BackButton, ButtonWithLoader } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  getBundlesIsCreating,
  getBundlesIsLoading,
} from '~/store/reducers/bundles'
import { Bundle, BundleItem, Task } from '~/types'
import useDialog from '~/utils/useDialog'

import BundleItemsTable, { BundleItemsTableHandle } from './BundleItemsTable'
import BundleTasksTable from './BundleTasksTable'

const useStyles = makeStyles(
  () => ({
    root: {},
    button: {
      minWidth: 196,
      height: 40,
    },
  }),
  { name: 'FinalizeBundle' },
)

export interface FinalizeBundleHandle {
  getCandidate: () => Bundle
}

interface FinalizeBundleProps {
  bundle: Bundle
  onBack: () => void
  onProceed: (bundle: Bundle) => void
  originalBundle: Bundle
  proceedButtonLabel?: string
}

const FinalizeBundle = forwardRef<FinalizeBundleHandle, FinalizeBundleProps>(
  function FinalizeBundle(
    {
      originalBundle,
      proceedButtonLabel: proceedButtonLabelProp,
      bundle,
      onBack,
      onProceed,
    },
    ref,
  ) {
    const classes = useStyles()
    const isCreating = useSelector(getBundlesIsCreating)
    const isLoading = useSelector(getBundlesIsLoading)
    const { t } = useTranslation('Common')
    const proceedButtonLabel =
      proceedButtonLabelProp || t('Common:CREATE_BUNDLE')

    const bundleItemsTableRef = useRef<BundleItemsTableHandle>(null)

    const [openBundleTaskDialog] = useDialog(DialogNames.BUNDLE_TASK)

    const [bundleCandidate, setBundleCandidate] = useState(bundle)

    useEffect(() => {
      setBundleCandidate(bundle)
    }, [bundle])

    const deleteItem = (item: BundleItem) => {
      setBundleCandidate({
        ...bundleCandidate,
        items: R.without([item], bundleCandidate.items || []),
      })
    }

    const onBundleTaskDialogProceed = (task: Task, index?: number) => {
      const oldTasks = bundleCandidate.tasks || []
      const newTasks = R.isNil(index)
        ? oldTasks.concat(task)
        : R.update(index, task, oldTasks)

      setBundleCandidate({
        ...bundleCandidate,
        tasks: newTasks,
      })
    }

    const onEditTask = (index: number) => {
      const task = bundleCandidate?.tasks?.[index]

      openBundleTaskDialog({
        task,
        onProceed: (proceededTask: Task) =>
          onBundleTaskDialogProceed(proceededTask, index),
      })
    }

    const onDeleteTask = (index: number) => {
      setBundleCandidate({
        ...bundleCandidate,
        tasks: R.remove(index, 1, bundleCandidate?.tasks || []),
      })
    }

    const onAddTask = () => {
      openBundleTaskDialog({
        onProceed: onBundleTaskDialogProceed,
      })
    }

    const validate = () => bundleItemsTableRef.current?.validate()

    const tryProceed = () => {
      if (validate()) {
        onProceed(bundleCandidate)
      }
    }

    useImperativeHandle(ref, () => ({
      getCandidate: () => bundleCandidate,
    }))

    return (
      <Grid container item className={classes.root} px={3} py={2}>
        <BundleItemsTable
          bundle={bundleCandidate}
          originalBundle={originalBundle}
          ref={bundleItemsTableRef}
          onBundleChange={setBundleCandidate}
          onDelete={deleteItem}
        />
        <BundleTasksTable
          bundle={bundleCandidate}
          onAdd={onAddTask}
          onDelete={onDeleteTask}
          onEdit={onEditTask}
        />
        <Grid container item alignItems="flex-end" columnSpacing={3} mt={2}>
          <Grid item>
            <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
          </Grid>
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading || isCreating}
              loading={isLoading || isCreating}
              onClick={tryProceed}
            >
              {proceedButtonLabel}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default FinalizeBundle
