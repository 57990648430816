import * as R from 'ramda'
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { ApiError, Business } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import FeatureToggle from '~/constants/featureToggle'
import { ensureBusinessSearchParametersByGroupType } from '~/utils/businessUtils'

import {
  addFavoriteBusiness,
  addFavoriteBusinessFailure,
  addFavoriteBusinessSuccess,
  createBusiness,
  createBusinessFailure,
  createBusinessLicense,
  createBusinessLicenseFailure,
  createBusinessLicenseSuccess,
  createBusinessSsoIdpSettings,
  createBusinessSsoIdpSettingsFailure,
  createBusinessSsoIdpSettingsSuccess,
  createBusinessSuccess,
  deleteBusinessGroup,
  deleteBusinessGroupFailure,
  deleteBusinessGroupSuccess,
  deleteBusinessLicense,
  deleteBusinessLicenseFailure,
  deleteBusinessLicenseSuccess,
  deleteBusinessSsoIdpSettings,
  deleteBusinessSsoIdpSettingsFailure,
  deleteBusinessSsoIdpSettingsSuccess,
  editBusinessBrandingConfiguration,
  editBusinessBrandingConfigurationFailure,
  editBusinessBrandingConfigurationSuccess,
  editBusinessLicense,
  editBusinessLicenseFailure,
  editBusinessLicenseSuccess,
  fetchAssignedPracticesFailure,
  fetchAssignedPracticesSuccess,
  fetchBusiness,
  fetchBusinessBrandingConfiguration,
  fetchBusinessBrandingConfigurationFailure,
  fetchBusinessBrandingConfigurationSuccess,
  fetchBusinessesList,
  fetchBusinessesList as fetchBusinessesListAction,
  fetchBusinessesListFailure,
  fetchBusinessesListSuccess,
  fetchBusinessFailure,
  fetchBusinessGroupTags,
  fetchBusinessGroupTagsFailure,
  fetchBusinessGroupTagsSuccess,
  fetchBusinessSoapWidgets,
  fetchBusinessSoapWidgetsFailure,
  fetchBusinessSoapWidgetsSuccess,
  fetchBusinessSsoIdpSettings,
  fetchBusinessSsoIdpSettingsFailure,
  fetchBusinessSsoIdpSettingsSuccess,
  fetchBusinessSuccess,
  fetchLocationsList,
  fetchLocationsListFailure,
  fetchLocationsListSuccess,
  fetchMoreItemsForBusinessesList,
  fetchMoreItemsForBusinessesListFailure,
  fetchMoreItemsForBusinessesListSuccess,
  fetchMoreItemsForLocationsList,
  fetchMoreItemsForLocationsListFailure,
  fetchMoreItemsForLocationsListSuccess,
  fetchMoreItemsForSuggestionsList,
  fetchMoreItemsForSuggestionsListFailure,
  fetchMoreItemsForSuggestionsListSuccess,
  fetchSuggestionsList,
  fetchSuggestionsListFailure,
  fetchSuggestionsListSuccess,
  removeFavoriteBusiness,
  removeFavoriteBusinessFailure,
  removeFavoriteBusinessSuccess,
  updateBusiness,
  updateBusinesses,
  updateBusinessFailure,
  updateBusinessSsoIdpSettings,
  updateBusinessSsoIdpSettingsFailure,
  updateBusinessSsoIdpSettingsSuccess,
  updateBusinessSuccess,
  updateCountryCatalogCode,
  updateCountryCatalogCodeFailure,
  updateCountryCatalogCodeSuccess,
} from '../actions/businesses'
import { updateSearchHighlights } from '../actions/search'
import {
  ADD_FAVORITE_BUSINESS,
  CREATE_BUSINESS,
  CREATE_BUSINESS_LICENSE,
  CREATE_BUSINESS_SSO_IDP_SETTINGS,
  DELETE_BUSINESS_GROUP,
  DELETE_BUSINESS_LICENSE,
  DELETE_BUSINESS_SSO_IDP_SETTINGS,
  EDIT_BUSINESS_BRANDING_CONFIGURATION,
  EDIT_BUSINESS_LICENSE,
  FETCH_ASSIGNED_PRACTICES,
  FETCH_BUSINESS,
  FETCH_BUSINESS_BRANDING_CONFIGURATION,
  FETCH_BUSINESS_GROUP_TAGS,
  FETCH_BUSINESS_SOAP_WIDGETS,
  FETCH_BUSINESS_SSO_IDP_SETTINGS,
  FETCH_BUSINESSES_LIST,
  FETCH_LOCATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST,
  FETCH_SUGGESTIONS_LIST,
  REMOVE_FAVORITE_BUSINESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS,
  UPDATE_COUNTRY_CATALOG_CODE,
} from '../actions/types/businesses'
import { updateUsers } from '../actions/users'
import { finishLoading, startLoading } from '../duck/progress'
import { notifyNetworkError } from '../duck/uiAlerts'
import { getBusiness, getBusinessFavoriteList } from '../reducers/businesses'
import { getFeatureToggle } from '../reducers/constants'
import {
  fixBusinessSearchQuery,
  sortBusinessQueryParameter,
} from './utils/businesses'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchBusinessesListSaga({
  from,
  to,
  query,
  fieldsQuery,
}: ReturnType<typeof fetchBusinessesList>) {
  try {
    const fixedQuery: string = yield fixBusinessSearchQuery(fieldsQuery)

    yield put(startLoading('businesses'))
    const {
      result: { data: list, totalCount, highlights },
      entities,
    } = yield* requestAPI(
      API.searchBusinesses,
      from,
      to,
      query,
      fixedQuery,
      true,
    )
    yield call(updateEntities, entities)
    yield put(updateSearchHighlights(highlights, totalCount))
    yield put(fetchBusinessesListSuccess(list, totalCount))
    yield put(finishLoading('businesses'))
  } catch (error) {
    yield put(fetchBusinessesListFailure(error as ApiError))
    yield put(finishLoading('businesses'))
  }
}

export function* fetchLocationsListSaga({
  from,
  to,
  parameters,
  isGroup,
}: ReturnType<typeof fetchLocationsList>) {
  try {
    const isPatientSharingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )

    const { query, fieldsQuery, ignoreRolesInSearch, sharingResult } =
      isPatientSharingEnabled
        ? ensureBusinessSearchParametersByGroupType(parameters, isGroup)
        : parameters

    const fixedQuery: string = yield fixBusinessSearchQuery(fieldsQuery)

    const {
      result: { data: locationList, totalCount: locationTotalCount },
      entities,
    } = yield* requestAPI(
      API.searchBusinesses,
      from,
      to,
      query,
      fixedQuery,
      Boolean(isPatientSharingEnabled && isGroup),
      sortBusinessQueryParameter,
      !ignoreRolesInSearch,
      sharingResult,
    )
    yield call(updateEntities, entities)
    yield put(fetchLocationsListSuccess(locationList, locationTotalCount))
  } catch (error) {
    yield put(fetchLocationsListFailure(error as ApiError))
  }
}

export function* fetchSuggestionsListSaga({
  from,
  to,
  parameters,
  isGroup,
}: ReturnType<typeof fetchSuggestionsList>) {
  try {
    const isPatientSharingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )
    const { query, fieldsQuery, ignoreRolesInSearch, sharingResult } =
      isPatientSharingEnabled
        ? ensureBusinessSearchParametersByGroupType(parameters, isGroup)
        : parameters
    const fixedQuery: string = yield fixBusinessSearchQuery(fieldsQuery)

    const {
      result: { data: businessList, totalCount: businessTotalCount },
      entities,
    } = yield* requestAPI(
      API.searchBusinesses,
      from,
      to,
      query,
      fixedQuery,
      Boolean(isPatientSharingEnabled && isGroup),
      sortBusinessQueryParameter,
      !ignoreRolesInSearch,
      sharingResult,
    )
    yield call(updateEntities, entities)
    yield put(fetchSuggestionsListSuccess(businessList, businessTotalCount))
  } catch (error) {
    yield put(fetchSuggestionsListFailure(error as ApiError))
  }
}

export function* fetchAssignedPracticesSaga() {
  try {
    const {
      result: { data: list },
      entities,
    } = yield* requestAPI(API.fetchAssignedPractices)
    yield call(updateEntities, entities)
    yield put(fetchAssignedPracticesSuccess(list))
  } catch (error) {
    yield put(fetchAssignedPracticesFailure(error as ApiError))
  }
}

export function* fetchMoreItemsForBusinessesListSaga({
  from,
  to,
  query,
  fieldsQuery,
}: ReturnType<typeof fetchMoreItemsForBusinessesList>) {
  try {
    const fixedQuery: string = yield fixBusinessSearchQuery(fieldsQuery)

    yield put(startLoading('businesses'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(
      API.searchBusinesses,
      from,
      to,
      query,
      fixedQuery,
      true,
    )
    yield call(updateEntities, entities)
    yield put(fetchMoreItemsForBusinessesListSuccess(list, totalCount, from))
    yield put(finishLoading('businesses'))
  } catch (error) {
    yield put(fetchMoreItemsForBusinessesListFailure(error as ApiError))
    yield put(finishLoading('businesses'))
  }
}

export function* fetchMoreItemsForLocationsListSaga({
  from,
  to,
  parameters,
  isGroup,
}: ReturnType<typeof fetchMoreItemsForLocationsList>) {
  try {
    const isPatientSharingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )
    const { query, fieldsQuery, ignoreRolesInSearch, sharingResult } =
      isPatientSharingEnabled
        ? ensureBusinessSearchParametersByGroupType(parameters, isGroup)
        : parameters
    const fixedQuery: string = yield fixBusinessSearchQuery(fieldsQuery)

    const {
      result: { data: locationList, totalCount: locationTotalCount },
      entities,
    } = yield* requestAPI(
      API.searchBusinesses,
      from,
      to,
      query,
      fixedQuery,
      Boolean(isPatientSharingEnabled && isGroup),
      sortBusinessQueryParameter,
      !ignoreRolesInSearch,
      sharingResult,
    )
    yield call(updateEntities, entities)
    yield put(
      fetchMoreItemsForLocationsListSuccess(
        locationList,
        locationTotalCount,
        from,
      ),
    )
  } catch (error) {
    yield put(fetchMoreItemsForLocationsListFailure(error as ApiError))
  }
}

export function* fetchMoreItemsForSuggestionsListSaga({
  from,
  to,
  parameters,
  isGroup,
}: ReturnType<typeof fetchMoreItemsForSuggestionsList>) {
  try {
    const isPatientSharingEnabled: boolean = yield select(
      getFeatureToggle(FeatureToggle.PATIENT_SHARING),
    )
    const { query, fieldsQuery, ignoreRolesInSearch, sharingResult } =
      isPatientSharingEnabled
        ? ensureBusinessSearchParametersByGroupType(parameters, isGroup)
        : parameters
    const fixedQuery: string = yield fixBusinessSearchQuery(fieldsQuery)

    const {
      result: { data: businessList, totalCount: businessTotalCount },
      entities,
    } = yield* requestAPI(
      API.searchBusinesses,
      from,
      to,
      query,
      fixedQuery,
      Boolean(isGroup),
      sortBusinessQueryParameter,
      !ignoreRolesInSearch,
      sharingResult,
    )
    yield call(updateEntities, entities)
    yield put(
      fetchMoreItemsForSuggestionsListSuccess(
        businessList,
        businessTotalCount,
        from,
      ),
    )
  } catch (error) {
    yield put(fetchMoreItemsForSuggestionsListFailure(error as ApiError))
  }
}

export function* fetchCurrentUser() {
  const {
    entities: { users, businesses },
  } = yield* requestAPI(API.fetchCurrentUser)
  yield put(updateBusinesses(businesses))
  yield put(updateUsers(users))
}

export function* removeFavoriteBusinessSaga({
  businessId,
}: ReturnType<typeof removeFavoriteBusiness>) {
  try {
    const businessFavoriteList: string[] = yield select(getBusinessFavoriteList)
    const newBusinessFavoriteList = R.without(
      [businessId],
      businessFavoriteList,
    )
    const favoriteData = yield* requestAPI(
      API.editFavoriteBusinessList,
      newBusinessFavoriteList.join(','),
    )
    yield put(removeFavoriteBusinessSuccess(favoriteData))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(removeFavoriteBusinessFailure(error))
  }
}

export function* addFavoriteBusinessSaga({
  businessId,
}: ReturnType<typeof addFavoriteBusiness>) {
  try {
    const businessFavoriteList: string[] = yield select(getBusinessFavoriteList)
    const newBusinessFavoriteList = [...businessFavoriteList, businessId]
    const favoriteData = yield* requestAPI(
      API.editFavoriteBusinessList,
      newBusinessFavoriteList.join(','),
    )
    yield put(addFavoriteBusinessSuccess(favoriteData))
  } catch (e) {
    const error = e as ApiError
    yield notifyNetworkError(error)
    yield put(addFavoriteBusinessFailure(error))
  }
}

export function* createBusinessSaga({
  business,
  creatorRoleId,
}: ReturnType<typeof createBusiness>) {
  try {
    const {
      result,
      entities: { businesses },
    } = yield* requestAPI(API.createBusiness, business, creatorRoleId)
    yield put(updateBusinesses(businesses))
    yield put(fetchBusinessesListAction())
    yield fetchCurrentUser()
    yield put(createBusinessSuccess(result))
  } catch (error) {
    yield put(createBusinessFailure(error as ApiError))
  }
}

export function* updateBusinessSaga({
  business: newBusiness,
}: ReturnType<typeof updateBusiness>) {
  try {
    const oldBusiness: Business = yield select(getBusiness(newBusiness.id!))
    if (
      newBusiness.statusId &&
      newBusiness.statusId !== oldBusiness?.statusId
    ) {
      yield* requestAPI(
        API.updateBusinessStatus,
        newBusiness.id,
        newBusiness.statusId,
      )
    }
    const {
      entities: { businesses },
    } = yield* requestAPI(API.updateBusiness, newBusiness)
    yield put(updateBusinesses(businesses))
    yield put(updateBusinessSuccess())
  } catch (error) {
    yield put(updateBusinessFailure(error as ApiError))
  }
}

export function* fetchBusinessGroupTagsSaga({
  groupId,
}: ReturnType<typeof fetchBusinessGroupTags>) {
  try {
    const { data } = yield* requestAPI(API.fetchBusinessGroupTags, groupId)
    yield put(fetchBusinessGroupTagsSuccess(groupId, data))
  } catch (error) {
    yield put(fetchBusinessGroupTagsFailure(error as ApiError))
  }
}

export function* fetchBusinessSaga({
  businessId: businessIdToFetch,
  includeChildren,
  includeSignatureCheck,
}: ReturnType<typeof fetchBusiness>) {
  try {
    const { entities } = yield* requestAPI(
      API.fetchBusiness,
      businessIdToFetch,
      includeChildren,
      includeSignatureCheck,
    )
    yield call(updateEntities, entities)
    yield put(fetchBusinessSuccess(businessIdToFetch))
  } catch (error) {
    yield put(fetchBusinessFailure(error as ApiError))
  }
}

export function* deleteBusinessGroupSaga({
  businessId,
}: ReturnType<typeof deleteBusinessGroup>) {
  try {
    const groupId = yield* requestAPI(API.deleteBusinessGroup, businessId)

    yield put(deleteBusinessGroupSuccess(groupId))
  } catch (error) {
    yield put(deleteBusinessGroupFailure(error as ApiError))
  }
}

export function* createBusinessLicenseSaga({
  businessId,
  license,
}: ReturnType<typeof createBusinessLicense>) {
  try {
    const licenses = yield* requestAPI(
      API.createBusinessLicense,
      businessId,
      license,
    )
    yield put(createBusinessLicenseSuccess(businessId, licenses))
  } catch (error) {
    yield put(createBusinessLicenseFailure(error as ApiError))
  }
}

export function* editBusinessLicenseSaga({
  businessId,
  license,
}: ReturnType<typeof editBusinessLicense>) {
  try {
    const licenses = yield* requestAPI(
      API.createBusinessLicense,
      businessId,
      license,
    )
    yield put(editBusinessLicenseSuccess(businessId, licenses))
  } catch (error) {
    yield put(editBusinessLicenseFailure(error as ApiError))
  }
}

export function* deleteBusinessLicenseSaga({
  businessId,
  licenseId,
}: ReturnType<typeof deleteBusinessLicense>) {
  try {
    const licenses = yield* requestAPI(
      API.deleteBusinessLicense,
      businessId,
      licenseId,
    )
    yield put(deleteBusinessLicenseSuccess(businessId, licenses))
  } catch (error) {
    yield put(deleteBusinessLicenseFailure(error as ApiError))
  }
}

export function* fetchBusinessBrandingConfigurationSaga({
  businessId,
}: ReturnType<typeof fetchBusinessBrandingConfiguration>) {
  try {
    const result = yield* requestAPI(
      API.fetchBusinessBrandingConfiguration,
      businessId,
    )
    yield put(fetchBusinessBrandingConfigurationSuccess(businessId, result))
  } catch (error) {
    yield put(fetchBusinessBrandingConfigurationFailure(error as ApiError))
  }
}

export function* editBusinessBrandingConfigurationSaga({
  businessId,
  brandingConfiguration,
}: ReturnType<typeof editBusinessBrandingConfiguration>) {
  try {
    const result = yield* requestAPI(
      API.editBusinessBrandingConfiguration,
      businessId,
      brandingConfiguration,
    )
    yield put(editBusinessBrandingConfigurationSuccess(businessId, result))
  } catch (error) {
    yield put(editBusinessBrandingConfigurationFailure(error as ApiError))
  }
}

export function* updateCountryCatalogCodeSaga({
  businessId,
  code,
}: ReturnType<typeof updateCountryCatalogCode>) {
  try {
    yield* requestAPI(API.updateCountryCatalogCode, businessId, code)
    yield put(updateCountryCatalogCodeSuccess(businessId, code))
  } catch (error) {
    yield put(updateCountryCatalogCodeFailure(error as ApiError))
  }
}

export function* fetchBusinessSoapWidgetsSaga({
  businessId,
}: ReturnType<typeof fetchBusinessSoapWidgets>) {
  try {
    const { soapWidgets } = yield* requestAPI(API.fetchBusinessSoapWidgets, {
      id: businessId,
    })
    yield put(fetchBusinessSoapWidgetsSuccess(soapWidgets))
  } catch (error) {
    yield put(fetchBusinessSoapWidgetsFailure(error as ApiError))
  }
}

export function* fetchBusinessSsoIdpSettingsSaga({
  businessId,
}: ReturnType<typeof fetchBusinessSsoIdpSettings>) {
  try {
    const ssoIdpSettings = yield* requestAPI(
      API.fetchBusinessSsoIdpSettings,
      businessId,
    )
    yield put(
      updateBusinesses({
        [businessId]: { ssoIdpSettings },
      }),
    )
    yield put(fetchBusinessSsoIdpSettingsSuccess(businessId, ssoIdpSettings))
  } catch (error) {
    yield put(fetchBusinessSsoIdpSettingsFailure(error as ApiError))
  }
}

export function* createBusinessSsoIdpSettingsSaga({
  businessId,
  input,
}: ReturnType<typeof createBusinessSsoIdpSettings>) {
  try {
    const ssoIdpSettings = yield* requestAPI(
      API.createBusinessSsoIdpSettings,
      businessId,
      input,
    )
    yield put(
      updateBusinesses({
        [businessId]: { ssoIdpSettings },
      }),
    )
    yield put(createBusinessSsoIdpSettingsSuccess(businessId, ssoIdpSettings))
  } catch (error) {
    yield put(createBusinessSsoIdpSettingsFailure(error as ApiError))
  }
}

export function* updateBusinessSsoIdpSettingsSaga({
  businessId,
  input,
}: ReturnType<typeof updateBusinessSsoIdpSettings>) {
  try {
    const ssoIdpSettings = yield* requestAPI(
      API.updateBusinessSsoIdpSettings,
      businessId,
      input,
    )
    yield put(
      updateBusinesses({
        [businessId]: { ssoIdpSettings },
      }),
    )
    yield put(updateBusinessSsoIdpSettingsSuccess(businessId, ssoIdpSettings))
  } catch (error) {
    yield put(updateBusinessSsoIdpSettingsFailure(error as ApiError))
  }
}

export function* deleteBusinessSsoIdpSettingsSaga({
  businessId,
  ssoIdpSettingsId,
}: ReturnType<typeof deleteBusinessSsoIdpSettings>) {
  try {
    yield* requestAPI(
      API.deleteBusinessSsoIdpSettings,
      businessId,
      ssoIdpSettingsId,
    )

    yield put(deleteBusinessSsoIdpSettingsSuccess(businessId, ssoIdpSettingsId))
  } catch (error) {
    yield put(deleteBusinessSsoIdpSettingsFailure(error as ApiError))
  }
}

function* watchFetchBusinessesList() {
  yield takeLatest(FETCH_BUSINESSES_LIST, fetchBusinessesListSaga)
}

function* watchFetchLocationsList() {
  yield takeLatest(FETCH_LOCATIONS_LIST, fetchLocationsListSaga)
}

function* watchFetchSuggestionsList() {
  yield takeLatest(FETCH_SUGGESTIONS_LIST, fetchSuggestionsListSaga)
}

function* watchFetchAssignedPractices() {
  yield takeLatest(FETCH_ASSIGNED_PRACTICES, fetchAssignedPracticesSaga)
}

function* watchFetchMoreItemsForBusinessesList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST,
    fetchMoreItemsForBusinessesListSaga,
  )
}

function* watchFetchMoreItemsForLocationsList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST,
    fetchMoreItemsForLocationsListSaga,
  )
}

function* watchFetchMoreItemsForSuggestionsList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST,
    fetchMoreItemsForSuggestionsListSaga,
  )
}

function* watchCreateBusiness() {
  yield takeLeading(CREATE_BUSINESS, createBusinessSaga)
}

function* watchUpdateBusiness() {
  yield takeLeading(UPDATE_BUSINESS, updateBusinessSaga)
}

function* watchFetchBusiness() {
  yield takeEvery(FETCH_BUSINESS, fetchBusinessSaga)
}

function* watchDeleteBusinessGroup() {
  yield takeEvery(DELETE_BUSINESS_GROUP, deleteBusinessGroupSaga)
}

function* watchFetchBusinessGroupTags() {
  yield takeEvery(FETCH_BUSINESS_GROUP_TAGS, fetchBusinessGroupTagsSaga)
}

function* watchCreateBusinessLicense() {
  yield takeLeading(CREATE_BUSINESS_LICENSE, createBusinessLicenseSaga)
}

function* watchEditBusinessLicense() {
  yield takeLeading(EDIT_BUSINESS_LICENSE, editBusinessLicenseSaga)
}

function* watchDeleteBusinessLicense() {
  yield takeLeading(DELETE_BUSINESS_LICENSE, deleteBusinessLicenseSaga)
}

function* watchFetchBusinessBrandingConfiguration() {
  yield takeLeading(
    FETCH_BUSINESS_BRANDING_CONFIGURATION,
    fetchBusinessBrandingConfigurationSaga,
  )
}

function* watchEditBusinessBrandingConfiguration() {
  yield takeLeading(
    EDIT_BUSINESS_BRANDING_CONFIGURATION,
    editBusinessBrandingConfigurationSaga,
  )
}

function* watchRemoveFavoriteBusiness() {
  yield takeLeading(REMOVE_FAVORITE_BUSINESS, removeFavoriteBusinessSaga)
}

function* watchAddFavoriteBusiness() {
  yield takeLeading(ADD_FAVORITE_BUSINESS, addFavoriteBusinessSaga)
}

function* watchUpdateCountryCatalogCode() {
  yield takeLeading(UPDATE_COUNTRY_CATALOG_CODE, updateCountryCatalogCodeSaga)
}

function* watchFetchBusinessSoapWidgets() {
  yield takeLeading(FETCH_BUSINESS_SOAP_WIDGETS, fetchBusinessSoapWidgetsSaga)
}

function* watchFetchBusinessSsoIdpSettings() {
  yield takeLeading(
    FETCH_BUSINESS_SSO_IDP_SETTINGS,
    fetchBusinessSsoIdpSettingsSaga,
  )
}

function* watchCreateBusinessSsoIdpSettings() {
  yield takeLeading(
    CREATE_BUSINESS_SSO_IDP_SETTINGS,
    createBusinessSsoIdpSettingsSaga,
  )
}

function* watchUpdateBusinessSsoIdpSettings() {
  yield takeLeading(
    UPDATE_BUSINESS_SSO_IDP_SETTINGS,
    updateBusinessSsoIdpSettingsSaga,
  )
}

function* watchDeleteBusinessSsoIdpSettings() {
  yield takeLeading(
    DELETE_BUSINESS_SSO_IDP_SETTINGS,
    deleteBusinessSsoIdpSettingsSaga,
  )
}

export default function* businessesSaga() {
  yield all([
    watchFetchBusinessesList(),
    watchFetchMoreItemsForBusinessesList(),
    watchCreateBusiness(),
    watchUpdateBusiness(),
    watchFetchBusiness(),
    watchCreateBusinessLicense(),
    watchEditBusinessLicense(),
    watchDeleteBusinessLicense(),
    watchFetchBusinessBrandingConfiguration(),
    watchEditBusinessBrandingConfiguration(),
    watchFetchAssignedPractices(),
    watchFetchBusinessGroupTags(),
    watchDeleteBusinessGroup(),
    watchRemoveFavoriteBusiness(),
    watchAddFavoriteBusiness(),
    watchFetchLocationsList(),
    watchFetchMoreItemsForLocationsList(),
    watchFetchSuggestionsList(),
    watchFetchMoreItemsForSuggestionsList(),
    watchUpdateCountryCatalogCode(),
    watchFetchBusinessSoapWidgets(),
    watchFetchBusinessSsoIdpSettings(),
    watchCreateBusinessSsoIdpSettings(),
    watchUpdateBusinessSsoIdpSettings(),
    watchDeleteBusinessSsoIdpSettings(),
  ])
}
