import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { BulkPriceEntityTypes } from '~/constants/bulkPrices'
import {
  fetchProceduresBulkPrices,
  startProceduresBulkPricesSession,
} from '~/store/duck/bulkPrices'

import { BulkPricesCategoryConstant } from '../bulk-prices/BulkPricesEditFilters'
import BulkPricesPage from '../bulk-prices/BulkPricesPage'

const ProceduresBulkPricesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const loadItems = (from: number, to: number, category: string | number) => {
    dispatch(fetchProceduresBulkPrices({ from, to, category }))
  }

  const startNewSession = () => {
    dispatch(startProceduresBulkPricesSession({}))
  }

  return (
    <BulkPricesPage
      categoryName={BulkPricesCategoryConstant.PROCEDURE}
      entityType={BulkPriceEntityTypes.PROCEDURE}
      loadItems={loadItems}
      startNewSession={startNewSession}
      title={t('Admin:CATALOG.PROCEDURES_TABLE_COMPONENT.PROCEDURE_CATALOG')}
    />
  )
}

export default ProceduresBulkPricesPage
