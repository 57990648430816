import { gql } from '@apollo/client/core'

export const SAVE_CLIENT_PREFERENCES = gql`
  mutation CreateOrUpdateClientPreference(
    $clientId: ID!
    $input: ClientPreferenceInput!
  ) {
    createOrUpdateClientPreference(clientId: $clientId, input: $input) {
      clientId
      genderIdentity {
        id
      }
      attitudeTowardsStaff {
        id
      }
      topicsToAvoid
      paymentMethod {
        id
      }
      financialComfort {
        id
      }
      concernForNonpayment
      extentOfCare {
        id
      }
      servicePreference {
        id
      }
      locationOfPerformedTreatment {
        id
      }
      timeOfDay {
        id
      }
      clientChronicallyLate
      clientChronicallyNoShow
      needsExtendedAppointmentTime {
        id
      }
      cadenceOfVisit {
        id
      }
      dayOfWeek {
        id
      }
      doctors {
        id
        firstName
        lastName
      }
      spaces {
        id
        name
      }
      financialComment
      careComment
      schedulingComment
    }
  }
`
