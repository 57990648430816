import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError } from '@apollo/client'
import { Skeleton, Stack, StackProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isNil } from 'ramda'
import { PuiTooltip } from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

import {
  CardPaymentMethod,
  PayPalPaymentMethod,
  ShippingAddress,
} from '~/api/graphql/generated/types'
import { ChewyMissingEntity } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyMissingEntity/ChewyMissingEntity'
import { StatusError } from '~/components/elements/Status/Status'
import { CardAndPaypalPaymentMethods } from '~/utils/cvcClient'
import { getApolloErrorMessage } from '~/utils/errors'

import { ChewyPaymentMethodSelect } from '../ChewyPaymentMethodSelect/ChewyPaymentMethodSelect'
import { ChewyShippingAddressSelect } from '../ChewyShippingAddressSelect/ChewyShippingAddressSelect'

const selectWidth = 295

const StyledSelectContainerStack = styled(Stack)`
  align-items: flex-start;

  & .MuiFormControl-root {
    width: ${selectWidth}px;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

export type ChewyPaymentBodyProps = StackProps & {
  cardAndPaypalPaymentMethods: CardAndPaypalPaymentMethods[]
  clientName: string
  loading?: boolean
  loadingError?: ApolloError
  paymentMethodValue: CardPaymentMethod | PayPalPaymentMethod | null
  setPaymentMethodValue: Dispatch<
    SetStateAction<CardPaymentMethod | PayPalPaymentMethod | null>
  >
  setShippingAddressValue: (shippingAddress: ShippingAddress | null) => void
  shippingAddressValue: ShippingAddress | null
  shippingAddresses: ShippingAddress[]
}

export const ChewyPaymentBody = ({
  cardAndPaypalPaymentMethods,
  clientName,
  loading = false,
  loadingError,
  paymentMethodValue,
  setPaymentMethodValue,
  setShippingAddressValue,
  shippingAddressValue,
  shippingAddresses,
  ...props
}: ChewyPaymentBodyProps) => {
  const { t } = useTranslation(['Common'])

  const valuesNotInitialized =
    (cardAndPaypalPaymentMethods.length > 0 && isNil(paymentMethodValue)) ||
    (shippingAddresses.length > 0 && isNil(shippingAddressValue))

  return (
    <Stack gap={2} {...props} alignItems="flex-start">
      {loadingError !== undefined && (
        <StatusError>{getApolloErrorMessage(loadingError)}</StatusError>
      )}
      {loading || valuesNotInitialized ? (
        <>
          <Skeleton height={46} variant="rectangular" width={selectWidth} />
          <Skeleton height={115} variant="rectangular" width={selectWidth} />
        </>
      ) : (
        <>
          {paymentMethodValue === null ||
          cardAndPaypalPaymentMethods.length === 0 ? (
            <ChewyMissingEntity
              message={t('Common:PAYMENTS.NEEDS_TO_ADD_PAYMENT_METHOD', {
                clientName,
              })}
              tooltip={t('Common:PAYMENTS.NEEDS_TO_ADD_PAYMENT_METHOD_TOOLTIP')}
            />
          ) : (
            <StyledSelectContainerStack direction="row" gap={1}>
              <ChewyPaymentMethodSelect
                borderlessWhenDisabled
                disabled={cardAndPaypalPaymentMethods.length === 1}
                options={cardAndPaypalPaymentMethods}
                value={paymentMethodValue}
                onChange={setPaymentMethodValue}
              />
              <PuiTooltip
                tooltipText={t(
                  'Common:PAYMENTS.NEEDS_TO_ADD_PAYMENT_METHOD_TOOLTIP',
                )}
              >
                <InfoIcon />
              </PuiTooltip>
            </StyledSelectContainerStack>
          )}

          {shippingAddressValue === null || shippingAddresses.length === 0 ? (
            <ChewyMissingEntity
              message={t('Common:PAYMENTS.NEEDS_TO_ADD_SHIPPING_ADDRESS', {
                clientName,
              })}
              tooltip={t(
                'Common:PAYMENTS.NEEDS_TO_ADD_SHIPPING_ADDRESS_TOOLTIP',
              )}
            />
          ) : (
            <StyledSelectContainerStack direction="row" gap={1}>
              <ChewyShippingAddressSelect
                borderlessWhenDisabled
                disabled={shippingAddresses.length === 1}
                options={shippingAddresses}
                value={shippingAddressValue}
                onChange={setShippingAddressValue}
              />
              <PuiTooltip
                tooltipText={t(
                  'Common:PAYMENTS.NEEDS_TO_ADD_SHIPPING_ADDRESS_TOOLTIP',
                )}
              >
                <InfoIcon />
              </PuiTooltip>
            </StyledSelectContainerStack>
          )}
        </>
      )}
    </Stack>
  )
}
