import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import MobilePhonePreview, {
  MobilePhonePreviewProps,
} from './MobilePhonePreview'

interface MobilePhoneChatPreviewProps
  extends Pick<MobilePhonePreviewProps, 'classes'> {
  children: React.ReactNode
  sender?: string
}

const useStyles = makeStyles(
  (theme) => ({
    textContainer: {
      width: 'calc(100% - 44px)',
      alignSelf: 'flex-end',
      padding: theme.spacing(1, 1, 1),
      margin: theme.spacing(1.5, 1.5, 1, 0),
      backgroundColor: theme.colors.tableBackground,
      borderRadius: '16px 16px 16px 0',
    },
    sender: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5),
    },
    logo: {
      paddingTop: 5,
      fontSize: '1.1rem',
      fontWeight: 500,
      textAlign: 'center',
      height: 25,
      width: 25,
      color: theme.colors.badgeColor,
      borderRadius: '50%',
      backgroundColor: '#3559E3',
    },
    senderName: {
      marginLeft: theme.spacing(0.5),
    },
  }),
  { name: 'MobilePhoneChatPreview' },
)

const MobilePhoneChatPreview = ({
  children,
  sender,
  classes: classesProp,
  ...rest
}: MobilePhoneChatPreviewProps) => {
  const classes = useStyles()

  return (
    <MobilePhonePreview classes={classesProp} {...rest}>
      <div className={classes.textContainer}>{children}</div>
      {sender && (
        <div className={classes.sender}>
          <div className={classes.logo}>
            {sender
              .split(' ')
              .map((i) => i.charAt(0))
              .join('')
              .toUpperCase()}
          </div>
          <Text className={classes.senderName} display="inline" variant="body3">
            {sender}
          </Text>
        </div>
      )}
    </MobilePhonePreview>
  )
}

export default MobilePhoneChatPreview
