import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AlertIconType,
  ConfirmCloseDialogProps,
  PuiAlert,
  PuiAlertProps,
} from '@pbt/pbt-ui-components'

interface BulkPricesCloseDialogProps extends Omit<PuiAlertProps, 'onOk'> {
  onProceed: ConfirmCloseDialogProps['onOk']
}

const BulkPricesCloseDialog = ({
  onProceed,
  ...rest
}: BulkPricesCloseDialogProps) => {
  const { t } = useTranslation(['Common', 'Dialogs'])
  return (
    <PuiAlert
      iconType={AlertIconType.WARN}
      message={t('Dialogs:CONFIRM_ALERT_DIALOG.BULK_PRICES.MESSAGE')}
      okButtonText={t('Common:YES')}
      onOk={onProceed}
      {...rest}
    />
  )
}

export default BulkPricesCloseDialog
