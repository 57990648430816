import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { NumberUtils, Text } from '@pbt/pbt-ui-components'
import { getConstantTranslatedName } from '@pbt/pbt-ui-components/src/utils/languageUtils'

import { RefundInvoiceLineItem } from '~/api/graphql/generated/types'
import { formatMoneyRange } from '~/components/dashboard/invoices/invoiceUtils'
import FinanceItemNameCellBody from '~/components/dashboard/soapV2/charges/table/body/FinanceItemNameCellBody'
import {
  REFUND_AMOUNT_PAID_CELL_WIDTH,
  REFUND_DISCOUNT_CELL_WIDTH,
  REFUND_PATIENT_CELL_WIDTH,
  REFUND_REASON_SUMMARY_CELL_WIDTH,
  REFUND_REFUND_AMOUNT_CELL_WIDTH,
  REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
  REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
  REFUND_TAX_CELL_WIDTH,
} from '~/constants/refund'
import { getRefundReason } from '~/store/reducers/constants'
import { InvoiceLineItem } from '~/types'
import { useGetRefundAndRestock } from '~/utils/refundUtils'

import { RefundContext } from './RefundContext'

const useStyles = makeStyles(
  (theme) => ({
    creationDate: {
      opacity: '50%',
    },
    groupedCell: {
      padding: 0,
    },
    tableFirstCell: {
      paddingLeft: theme.spacing(3),
    },
    tableCellWithBorder: {
      borderRight: theme.constants.tableBorder,
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      alignSelf: 'center',
    },
    patientCell: {
      minWidth: REFUND_PATIENT_CELL_WIDTH,
      width: REFUND_PATIENT_CELL_WIDTH,
    },
    reasonCell: {
      maxWidth: REFUND_REASON_SUMMARY_CELL_WIDTH,
      minWidth: REFUND_REASON_SUMMARY_CELL_WIDTH,
      width: REFUND_REASON_SUMMARY_CELL_WIDTH,
    },
    refundQtyCell: {
      minWidth: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
      width: REFUND_REFUND_QTY_SUMMARY_CELL_WIDTH,
    },
    restockQtyCell: {
      minWidth: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
      width: REFUND_RESTOCK_QTY_SUMMARY_CELL_WIDTH,
    },
    amountPaidCell: {
      minWidth: REFUND_AMOUNT_PAID_CELL_WIDTH,
      width: REFUND_AMOUNT_PAID_CELL_WIDTH,
    },
    taxCell: {
      minWidth: REFUND_TAX_CELL_WIDTH,
      width: REFUND_TAX_CELL_WIDTH,
    },
    discountCell: {
      minWidth: REFUND_DISCOUNT_CELL_WIDTH,
      width: REFUND_DISCOUNT_CELL_WIDTH,
    },
    refundAmountCell: {
      minWidth: REFUND_REFUND_AMOUNT_CELL_WIDTH,
      width: REFUND_REFUND_AMOUNT_CELL_WIDTH,
    },
  }),
  { name: 'RefundInvoiceSingleItemRow' },
)

export interface RefundInvoiceSingleItemRowProps {
  isNested?: boolean
  item: RefundInvoiceLineItem
  tableCellClassName: string
}

export const RefundInvoiceSingleItemRow = ({
  item,
  tableCellClassName,
  isNested,
}: RefundInvoiceSingleItemRowProps) => {
  const classes = useStyles()

  const { onSelectItem, selectedItem } = useContext(RefundContext)
  const refundReasonItems = useSelector(getRefundReason)

  const {
    restockedAsPackage,
    subTotal,
    discount,
    taxAmount,
    refundQty,
    refundReason,
    restockQty,
    patient,
    originalLineItem,
  } = item

  const { getRestockQuantity, refundPackageName, restockPackageName } =
    useGetRefundAndRestock({
      originalLineItem,
      restockedAsPackage,
    })

  const refundReasonName = getConstantTranslatedName(
    refundReason.id,
    refundReasonItems,
  )

  return (
    <>
      <FinanceItemNameCellBody
        isReadOnly
        handleSelectItem={onSelectItem}
        isExpanded={false}
        isGrouped={false}
        item={
          {
            wplanLogId: originalLineItem.wplanLogId,
            id: item.id,
            name: item.name,
            groupName: item.name,
          } as InvoiceLineItem
        }
        nested={isNested}
        selectedItem={selectedItem}
        tableCellClassName={classNames(
          tableCellClassName,
          classes.tableCellWithBorder,
          {
            [classes.tableFirstCell]: !isNested,
          },
        )}
        toggleExpand={() => {}}
      />
      <TableCell
        className={classNames(
          tableCellClassName,
          classes.tableCellWithBorder,
          classes.patientCell,
        )}
      >
        <Text variant="body2">{patient?.name}</Text>
      </TableCell>
      <TableCell
        className={classNames(
          tableCellClassName,
          classes.tableCellWithBorder,
          classes.reasonCell,
        )}
      >
        <Text variant="body2">{refundReasonName}</Text>
      </TableCell>
      <TableCell
        className={classNames(
          tableCellClassName,
          classes.tableCellWithBorder,
          classes.refundQtyCell,
        )}
      >
        <Text variant="body2">
          {refundQty} {refundPackageName}
        </Text>
      </TableCell>
      <TableCell
        className={classNames(
          tableCellClassName,
          classes.tableCellWithBorder,
          classes.restockQtyCell,
        )}
      >
        <Text variant="body2">
          {getRestockQuantity(restockQty)} {restockPackageName}
        </Text>
      </TableCell>
      <TableCell
        className={classNames(
          classes.tableCellWithBorder,
          classes.amountPaidCell,
        )}
      >
        <Text variant="body2">
          {NumberUtils.formatMoney(item.originalLineItem.subTotal)}
        </Text>
      </TableCell>
      <TableCell
        className={classNames(classes.tableCellWithBorder, classes.taxCell)}
      >
        <Text variant="body2">{NumberUtils.formatMoney(taxAmount)}</Text>
      </TableCell>
      <TableCell
        className={classNames(
          classes.tableCellWithBorder,
          classes.discountCell,
        )}
      >
        <Text variant="body2">{NumberUtils.formatMoney(discount)}</Text>
      </TableCell>
      <TableCell
        align="right"
        className={classNames(
          classes.tableCellWithBorder,
          classes.refundAmountCell,
        )}
      >
        <Text variant="body2">{formatMoneyRange(subTotal)}</Text>
      </TableCell>
    </>
  )
}
