import { useSelector } from 'react-redux'

import { getConversationById } from '~/store/reducers/conversations'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import {
  getTimelineClientId,
  getTimelinePatientId,
} from '~/store/reducers/timeline'

const useUserContext = ({ conversationId }: { conversationId: string }) => {
  const soapClientId = useSelector(getClientId)
  const timelineClientId = useSelector(getTimelineClientId)
  const conversation = useSelector(getConversationById(conversationId))
  const conversationClientId = conversation?.client
  const clientId = soapClientId || timelineClientId || conversationClientId
  const soapPatientId = useSelector(getPatientId)
  const timelinePatientId = useSelector(getTimelinePatientId)
  const conversationPatientId = conversation?.patient
  const patientId = soapPatientId || timelinePatientId || conversationPatientId

  return [clientId, patientId]
}

export default useUserContext
