import React from 'react'
import {
  PuiDialog,
  PuiDialogProps,
  PuiPopperProps,
} from '@pbt/pbt-ui-components'

export interface PuiPopupProps {
  Component: React.JSXElementConstructor<any>
  DialogProps?: Partial<PuiDialogProps>
  PopperProps?: Partial<PuiPopperProps>
  children?: React.ReactNode
  onClose?: () => void
  open: boolean
}

const PuiPopup = ({
  Component,
  DialogProps,
  PopperProps,
  ...rest
}: PuiPopupProps) => {
  const ComponentProps = Component === PuiDialog ? DialogProps : PopperProps

  return <Component {...ComponentProps} {...rest} />
}

export default PuiPopup
