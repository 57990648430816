import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiPopper, PuiPopperProps } from '@pbt/pbt-ui-components'

import { Task, TaskRecurrence } from '~/types'

import TaskRecurrenceSettings, {
  TaskRecurrenceSettingsHandle,
} from './TaskRecurrenceSettings'

const useStyles = makeStyles(
  (theme) => ({
    popper: {
      zIndex: theme.zIndex.modal,
    },
    container: {
      width: 400,
    },
    endAfterSettings: {
      marginTop: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(2),
      width: 115,
      height: 40,
    },
  }),
  { name: 'RepeatTaskPopper' },
)

export interface RepeatTaskPopperProps extends PuiPopperProps {
  onApply: (data: TaskRecurrence) => void
  task: Task
}

const RepeatTaskPopper = ({
  task,
  onApply,
  ...rest
}: RepeatTaskPopperProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const taskRecurrenceSettingsRef = useRef<TaskRecurrenceSettingsHandle>(null)

  const apply = () => {
    if (taskRecurrenceSettingsRef.current?.validate()) {
      onApply({
        recurrenceModeId: task.recurrenceModeId,
        ...taskRecurrenceSettingsRef.current?.get(),
      })
    }
  }

  return (
    <PuiPopper classes={{ popper: classes.popper }} {...rest}>
      <Grid container className={classes.container} px={3} py={2}>
        <TaskRecurrenceSettings
          appointmentId={task?.appointment}
          className={classes.endAfterSettings}
          ref={taskRecurrenceSettingsRef}
          showRepeat={false}
          task={task}
        />
        <Fab
          className={classes.button}
          color="inherit"
          variant="extended"
          onClick={apply}
        >
          {t('Common:APPLY_ACTION')}
        </Fab>
      </Grid>
    </PuiPopper>
  )
}

export default RepeatTaskPopper
