/* eslint-disable react/no-danger */
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { sanitize } from 'dompurify'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    subtitle: {},
    text: {},
  }),
  { name: 'TermsAndConditionsItems' },
)

export interface TermsAndConditionsItem {
  id: string | number
  subtitle?: string
  text: string
}

export interface TermsAndConditionsItemsProps {
  classes: ClassesType<typeof useStyles>
  list: Array<TermsAndConditionsItem>
}

const TermsAndConditionsItems = ({
  list,
  classes,
}: TermsAndConditionsItemsProps) => (
  <>
    {list.map((item) => (
      <React.Fragment key={item.id}>
        {item.subtitle && (
          <Text
            strong
            align="left"
            className={classes.subtitle}
            variant="subheading"
          >
            {item.subtitle}
          </Text>
        )}
        <Text className={classes.text}>
          <span
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: sanitize(item.text) }}
          />
        </Text>
      </React.Fragment>
    ))}
  </>
)

export default TermsAndConditionsItems
