import React from 'react'
import { useSelector } from 'react-redux'

import { getPatientName } from '~/store/reducers/patients'

interface MembershipCellProps {
  patientId: string
}

const MembershipPatientCell = ({ patientId }: MembershipCellProps) => {
  const patientName = useSelector(getPatientName(patientId))

  return <span>{patientName || ''}</span>
}

export default MembershipPatientCell
