import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WellnessPlans } from '@pbt/pbt-ui-components'

import { LandingType } from '~/constants/landingConstants'
import SnapshotsAliasTypes from '~/constants/SnapshotsAliasTypes'
import {
  fetchWidgetsData,
  getWidgetData,
  getWidgetDataIsLoading,
} from '~/store/duck/landing'
import {
  patientMembershipsActions,
  patientMembershipsSelectors,
  // @ts-ignore
} from '~/store/duck/patientMemberships'
import { Membership } from '~/types'

interface UseFutureAppointmentsAndMembershipProps {
  clientId: string
  includePausedMemberships?: boolean
  patientId: string
}

export const useFutureAppointmentsAndMembership = ({
  patientId,
  clientId,
  includePausedMemberships = false,
}: UseFutureAppointmentsAndMembershipProps) => {
  const dispatch = useDispatch()

  const futureAppointmentsIds = useSelector(
    getWidgetData(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Appointments,
    ),
  )
  const isMembershipFetching: boolean = useSelector(
    patientMembershipsSelectors.getIsFetching,
  )
  const isAppointmentDataFetching = useSelector(
    getWidgetDataIsLoading(
      LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
      SnapshotsAliasTypes.Appointments,
    ),
  )

  const membership: Membership | undefined = useSelector(
    patientMembershipsSelectors.getItem(patientId),
  )

  const hasFutureAppointments = Boolean(futureAppointmentsIds?.length)

  useEffect(() => {
    if (!hasFutureAppointments) {
      dispatch(
        fetchWidgetsData([SnapshotsAliasTypes.Appointments], {
          landingType: LandingType.CLIENT_AND_PATIENT_SNAPSHOTS,
          quiet: false,
          patientId,
        }),
      )
    }
  }, [patientId, hasFutureAppointments])

  useEffect(() => {
    if (!membership) {
      dispatch(patientMembershipsActions.fetchItem(clientId, patientId))
    }
  }, [patientId, membership, clientId])

  const hasMembership =
    Boolean(membership?.member) ||
    (includePausedMemberships &&
      membership?.state === WellnessPlans.MembershipStatus.PAUSED)

  return {
    isMembershipFetching,
    isAppointmentDataFetching,
    hasFutureAppointments: Boolean(futureAppointmentsIds?.length),
    hasMembership,
    membership,
  }
}
