import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Table, TableBody } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import { fetchQuestionsForSoap } from '~/store/actions/questions'
import {
  getSoapQuestionsBySoapId,
  getSoapQuestionsWithWorkFlow,
} from '~/store/reducers/questions'
import { getSoapId } from '~/store/reducers/soap'
import useWorkflowTypes from '~/utils/useWorkflowTypes'

import { hasSelectedAnswers } from '../../soap/utils/questionUtils'
import QuestionsListWidgetLockedItem from './QuestionsListWidgetLockedItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
    workflowContainer: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(3),
      },
    },
  }),
  { name: 'QuestionsListWidgetLocked' },
)

const QuestionsListWidgetLocked = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const soapId = useSelector(getSoapId)
  const soapQuestionsWithWorkflow = useSelector(getSoapQuestionsWithWorkFlow)
  const soapQuestionsBySoapId = useSelector(getSoapQuestionsBySoapId)
  const WorkFlowTypes = useWorkflowTypes()
  const { t } = useTranslation(['Common', 'Soap'])

  useEffect(() => {
    if (soapId) {
      dispatch(fetchQuestionsForSoap(soapId))
    }
  }, [soapId])

  const questionsByWorkflowType = WorkFlowTypes.map((type) => ({
    type,
    questions: soapQuestionsBySoapId
      .filter((question) => question.workflowIds.indexOf(type.id) > -1) // matches type
      .filter((question) => question.answers?.some(({ selected }) => selected)), // has answers
  })).filter(({ questions }) => questions.length > 0)

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item className={classes.titleContainer} px={1.5} py={2} xs={2}>
        <Text variant="h4">{t('Common:QUESTION_OTHER')}</Text>
        {WorkFlowTypes.map((workflow) => {
          const questionsOfType = soapQuestionsWithWorkflow[workflow.id]
          const answeredQuestionsOfType = questionsOfType.filter(
            ({ answers }) => hasSelectedAnswers(answers),
          )
          const answeredQuestionsStr = t(
            'Soap:QUESTIONS_LIST_LOCKED.X_OF_Y_QUESTIONS_ANSWERED',
            {
              x: answeredQuestionsOfType.length,
              y: questionsOfType.length,
            },
          )

          if (questionsOfType.length === 0) {
            return null
          }

          return (
            <Text key={workflow.id} variant="body2">
              {`${LanguageUtils.getTranslatedFieldName(
                workflow,
                'shortName',
              )}: ${answeredQuestionsStr}`}
            </Text>
          )
        })}
      </Grid>
      <Grid container item xs direction="column" pb={3} pt={1.5} px={3}>
        {questionsByWorkflowType.length > 0 ? (
          questionsByWorkflowType.map(({ type, questions }) => (
            <Grid
              container
              item
              className={classes.workflowContainer}
              key={type.id}
            >
              <Text strong mb={1} variant="subheading3">
                {LanguageUtils.getTranslatedFieldName(type, 'shortName')}
              </Text>
              <Table className={classes.table}>
                <TableBody>
                  {questions.map((question) => (
                    <QuestionsListWidgetLockedItem
                      key={question.id}
                      question={question}
                    />
                  ))}
                </TableBody>
              </Table>
            </Grid>
          ))
        ) : (
          <Text>
            <Trans
              components={{ linkTo: <Link to="/admin/catalog/questions" /> }}
              i18nKey="Soap:QUESTIONS_LIST_LOCKED.NO_QUESTIONS"
            />
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

export default QuestionsListWidgetLocked
