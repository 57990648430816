import { Nil } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchSchedulesV2 = (
  businessId: string,
  date: string | Nil,
  showOtcSaleEvents: boolean,
) =>
  request(
    getAPIPath('v2/schedules'),
    {
      method: 'GET',
      params: { businessId, date, language: i18n.language, showOtcSaleEvents },
    },
    true,
    Schema.schedules,
  )

export const fetchBoardingSchedules = (
  businessId: string,
  date: string | Nil,
) =>
  request(
    getAPIPath('boarding'),
    { method: 'GET', params: { businessId, date, language: i18n.language } },
    true,
    Schema.schedules,
  )

export const fetchUpcomingEvents = (
  businessId: string,
  clientId: string,
  patientId: string,
  startDate: string | Nil,
  filterByClient: boolean | Nil,
) =>
  request(
    getAPIPath(`clients/${clientId}/patients/${patientId}/events`),
    {
      method: 'GET',
      params: {
        businessId,
        startDate,
        filterByClient,
        language: i18n.language,
      },
    },
    true,
    Schema.events,
  )
