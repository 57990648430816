import { NavigateFunction, useNavigate } from 'react-router-dom'

// eslint-disable-next-line import/no-mutable-exports
export let globalNavigate: NavigateFunction

const GlobalHistory = () => {
  globalNavigate = useNavigate()

  return null
}

export default GlobalHistory
