import React from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import { FINANCE_TABLE_PADDING_X_SPACING_VALUE } from '~/constants/financeTable'

import {
  INVOICE_COLUMNS,
  INVOICE_COLUMNS_WITH_REFUND,
} from '../../soapV2/charges/table/Columns'

const useStyles = makeStyles(
  (theme) => ({
    tableHead: {
      borderBottom: theme.constants.tabBorder,
    },
    tableTitle: {
      color: theme.colors.tabLabel,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.4rem',
      height: theme.spacing(4),
      padding: theme.spacing(0, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: ({ useInvoiceCVCLayout }: UseStylesProps) =>
          theme.spacing(
            FINANCE_TABLE_PADDING_X_SPACING_VALUE +
              (useInvoiceCVCLayout ? -1 : 1.5),
          ),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    tableSubTitle: {
      padding: theme.spacing(0.5, 1),
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: ({ useInvoiceCVCLayout }: UseStylesProps) =>
          theme.spacing(
            FINANCE_TABLE_PADDING_X_SPACING_VALUE +
              (useInvoiceCVCLayout ? -1 : 1.5),
          ),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'InvoiceTableHeader' },
)

interface UseStylesProps {
  useInvoiceCVCLayout: boolean
}

interface InvoiceTableHeaderProps {
  hasRefunds: boolean
  showPaidUsedRemainingLabel: boolean
  useInvoiceCVCLayout: boolean
}

export const InvoiceTableHeader = ({
  hasRefunds,
  showPaidUsedRemainingLabel,
  useInvoiceCVCLayout,
}: InvoiceTableHeaderProps) => {
  const classes = useStyles({ useInvoiceCVCLayout })
  const columns = hasRefunds ? INVOICE_COLUMNS_WITH_REFUND : INVOICE_COLUMNS

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {columns.map(({ label, width, id }) => (
          <TableCell
            className={classNames(classes.tableTitle)}
            key={id}
            width={width}
          >
            <Text inline strong variant="lowAccent2">
              {label}
            </Text>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {columns.map(({ subLabels, width, id }) => (
          <TableCell className={classes.tableSubTitle} key={id} width={width}>
            {showPaidUsedRemainingLabel && subLabels && (
              <Text variant="lowAccent5">
                {subLabels.map((subLabel) => subLabel.label).join(' | ')}
              </Text>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
