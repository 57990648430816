import React from 'react'
import { useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { getLabTestsStates } from '~/store/reducers/constants'
import { Order } from '~/types'

import ItemWithBadges from '../ItemWithBadges'
import LabTestActions from './actions/LabTestActions'
import LabTestButtonSection from './button-sections/LabTestButtonSection'
import LabTestContent from './content/LabTestContent'

export interface LabTestProps {
  data: Order
}

const LabTest = ({ data, ...rest }: LabTestProps) => {
  const LabTestStates = useSelector(getLabTestsStates)

  const currentTest = Utils.findById(data.stateId, LabTestStates)

  return (
    <ItemWithBadges
      actions={<LabTestActions order={data} {...rest} />}
      buttonSection={<LabTestButtonSection order={data} {...rest} />}
      order={data}
    >
      {currentTest && <LabTestContent order={data} />}
    </ItemWithBadges>
  )
}
export default LabTest
