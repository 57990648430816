import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { Nil } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { RootState } from '~/store'

import useDialog from './useDialog'

export interface UseErrorAlertProps {
  errorSelector: (state: RootState) => string | null
  onCloseAction?: AnyAction | Nil
  onCloseHandler?: () => void
  onOpenAction?: AnyAction | Nil
  onOpenHandler?: () => void
}

const useErrorAlert = ({
  errorSelector,
  onOpenAction,
  onOpenHandler,
  onCloseAction,
  onCloseHandler,
}: UseErrorAlertProps) => {
  const { t } = useTranslation('Common')

  const errorMessage = useSelector(errorSelector)
  const dispatch = useDispatch()
  const [openDeletedTaskAlert, closeDeletedTaskAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    () => {
      if (onCloseAction) {
        dispatch(onCloseAction)
      }
      if (onCloseHandler) {
        onCloseHandler()
      }
    },
  )

  useEffect(() => {
    if (errorMessage) {
      openDeletedTaskAlert({
        message: errorMessage,
        okButtonText: t('Common:OK'),
        onOk: () => {
          closeDeletedTaskAlert()
        },
      })
      if (onOpenAction) {
        dispatch(onOpenAction)
      }
      if (onOpenHandler) {
        onOpenHandler()
      }
    }
  }, [errorMessage])
}

export default useErrorAlert
