import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  Field,
  LanguageUtils,
  NamedEntity,
  SentenceFormatter,
  Utils,
} from '@pbt/pbt-ui-components'

import { getPrescriptionChewyItems } from '~/store/duck/prescriptions'
import { getPrescriptionProductSizeUnit } from '~/store/reducers/constants'

export const useChewyActiveQuantityUnitField = ({
  fallbackUnitLabel,
  isChewyActiveRx,
  selectedChewyItemPartNumber,
  totalQuantityUnitIdField,
}: {
  fallbackUnitLabel?: string
  isChewyActiveRx?: boolean
  selectedChewyItemPartNumber: string | undefined
  totalQuantityUnitIdField: Field
}) => {
  const { t } = useTranslation('Common')

  const chewyItems = useSelector(getPrescriptionChewyItems)
  const PrescriptionProductSizeUnit = useSelector(
    getPrescriptionProductSizeUnit,
  )

  const selectedChewyItem = R.find(
    R.propEq('partNumber', selectedChewyItemPartNumber),
    chewyItems || [],
  )

  const selectedChewyItemUnitLabel =
    selectedChewyItem?.count || fallbackUnitLabel

  const displayChewyName = LanguageUtils.getSentence(
    [t('Common:UNIT'), selectedChewyItem ? selectedChewyItemUnitLabel : ''],
    SentenceFormatter.REGULAR,
    ' - ',
  )

  const ModifiedPrescriptionProductSizeUnit = R.map(
    R.when(
      R.propEq('name', 'Unit'),
      R.assoc('nameTranslation', displayChewyName),
    ),
    PrescriptionProductSizeUnit,
  ) as NamedEntity[]

  useEffect(() => {
    // Autofill total quantity unit id based on chewy selected item
    const autoFillUnitId = Utils.findConstantIdByName(
      'Unit',
      PrescriptionProductSizeUnit,
    )

    if (isChewyActiveRx && totalQuantityUnitIdField.value !== autoFillUnitId) {
      totalQuantityUnitIdField.setValue(autoFillUnitId)
    }
  }, [selectedChewyItem])

  return {
    ModifiedPrescriptionProductSizeUnit,
  }
}
