import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateUtils,
  HtmlNotesPreview,
  LanguageUtils,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import { deserializeFindingsState } from '~/api/utils/serializeSOAPData'
import Conferencing from '~/components/dashboard/soap/appointment/Conferencing'
import FindingsTable from '~/components/dashboard/soap/examination/FindingsTable'
import { getMultipleAddendums } from '~/store/duck/addendums'
import { useGetIsZoomEnabled } from '~/store/hooks/conferencing'
import { getMultipleUsers } from '~/store/reducers/users'
import {
  AppointmentTimelineItem,
  AppointmentTimelineItemEntity,
  FindingState,
} from '~/types'

import EventProductsTable from './EventProductsTable'
import ProblemFindingsTables from './ProblemFindingsTables'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& > *:not(:first-child)': {
        marginTop: theme.spacing(1.5),
      },
    },
    zoomDetailsContainer: {
      border: 'none',
      margin: theme.spacing(0, -1),
    },
    meetingDetails: {
      border: 'none',
    },
  }),
  { name: 'SingleSoapAppointment' },
)

interface SingleSoapAppointmentProps {
  appointment?: AppointmentTimelineItemEntity
  className?: string
  clientId: string
  item?: AppointmentTimelineItem
  patientId: string
  showPersons?: boolean
}

const SingleSoapAppointment = ({
  className,
  showPersons = true,
  appointment: defaultAppointment,
  clientId,
  patientId,
  item = {} as AppointmentTimelineItem,
}: SingleSoapAppointmentProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Clients'])

  const {
    id: appointmentId,
    personRoles,
    personResponsibilities,
    notes,
    dialIn,
    meetingLink,
    meetingNotes,
  } = item

  const appointment = {
    ...(defaultAppointment || item.entries?.[0] || {}),
    id: appointmentId,
    client: clientId,
    patient: patientId,
    dialIn,
    meetingLink,
    meetingNotes,
  }

  const {
    findingLogs,
    medicalNotes,
    dischargeNotes,
    products,
    addendums: addendumIds,
    problemFindingLogs,
  } = appointment

  const addendums = useSelector(getMultipleAddendums(addendumIds || []))
  const addendumPersonIds = R.pluck('person', addendums).filter(R.is(String))
  const addendumPersons = useSelector(getMultipleUsers(addendumPersonIds))

  const shouldUseResponsibilities =
    personResponsibilities && personResponsibilities?.length > 0

  const isZoomEnabled = useGetIsZoomEnabled()

  const findings: FindingState[] = Object.values(
    deserializeFindingsState(findingLogs || []),
  )

  const showZoomDetails = isZoomEnabled && meetingLink

  return (
    <Grid item className={classNames(className, classes.root)}>
      {showPersons &&
        shouldUseResponsibilities &&
        personResponsibilities.map((personResponsibility) => (
          <Grid
            item
            key={`${personResponsibility.responsibilityId}-${personResponsibility.personId}`}
          >
            <Text strong>
              {LanguageUtils.getTranslatedFieldName(
                personResponsibility.responsibility,
              )}
            </Text>
            <Text>{Utils.getPersonString(personResponsibility.person)}</Text>
          </Grid>
        ))}
      {showPersons &&
        !shouldUseResponsibilities &&
        personRoles.map((personRole) => (
          <Grid item key={`${personRole.roleId}-${personRole.personId}`}>
            <Text strong>
              {LanguageUtils.getTranslatedFieldName(personRole.role)}
            </Text>
            <Text>{Utils.getPersonString(personRole.person)}</Text>
          </Grid>
        ))}
      {notes && (
        <Grid container direction="column">
          <Text strong>{t('Common:REASON_FOR_VISIT')}</Text>
          <HtmlNotesPreview notes={notes} />
        </Grid>
      )}
      {showZoomDetails && (
        <Conferencing
          deleteDisabled
          editDisabled
          appointment={appointment}
          classes={{
            zoomLinkInnerContainer: classes.zoomDetailsContainer,
            meetingDetails: classes.meetingDetails,
          }}
          expandZoomDetailsLabel={t(
            'Common:ZOOM_CONFERENCING_DETAILS',
          ).toLowerCase()}
          title={t('Common:ZOOM_CONFERENCING_DETAILS')}
        />
      )}
      {findings?.length > 0 && (
        <Grid item>
          <Text strong>
            {t(
              'Clients:TIMELINE.SINGLE_SOAP_APPOINTMENT.PHYSICAL_EXAM_FINDING',
            )}
          </Text>
          <FindingsTable findingsState={findings} />
        </Grid>
      )}
      {problemFindingLogs?.length && (
        <Grid item>
          <Text strong>
            {t(
              'Clients:TIMELINE.SINGLE_SOAP_APPOINTMENT.PROBLEMS_DIAGNOSES_NOTES',
            )}
          </Text>
          <ProblemFindingsTables problemFindingLogs={problemFindingLogs} />
        </Grid>
      )}
      {medicalNotes && (
        <>
          <Text strong>{t('Common:MEDICAL_NOTES')}:</Text>
          <HtmlNotesPreview notes={medicalNotes} />
        </>
      )}
      {dischargeNotes && (
        <>
          <Text strong>{t('Common:DISCHARGE_NOTES')}:</Text>
          <HtmlNotesPreview notes={dischargeNotes} />
        </>
      )}
      {addendums?.map((addendum, index) => (
        <React.Fragment key={addendum.id}>
          <Text strong>
            {t('Common:ADDENDUM')}
            &nbsp;
            {DateUtils.formatDateWithHours(addendum.date)}
            &nbsp;
            {Utils.getPersonString(addendumPersons[index])}
          </Text>
          <HtmlNotesPreview notes={addendum.notes} />
        </React.Fragment>
      ))}
      {Boolean(products?.length) && (
        <>
          <Text strong>
            {t('Clients:TIMELINE.SINGLE_SOAP_APPOINTMENT.PRODUCT_AND_SERVICES')}
          </Text>
          <EventProductsTable products={products} />
        </>
      )}
    </Grid>
  )
}

export default SingleSoapAppointment
