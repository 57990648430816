import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Skeleton } from '@mui/material'

import { PrescriptionV2 } from '~/types'

import FinalizeOrdersRow from '../FinalizeOrdersRow'
import { FinalizeChewyActiveRxRow } from './FinalizeChewyActiveRxRow'

export interface FinalizeChewyActiveRxTableProps {
  context: 'Charge-sheet' | 'SOAP'
  data: PrescriptionV2[]
  isLoading?: boolean
}

export const FinalizeChewyActiveRxTable = ({
  context,
  data,
  isLoading = false,
}: FinalizeChewyActiveRxTableProps) => {
  const { t } = useTranslation(['Soap'])

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <Box datatest-id="finalize-chewy-active-rx-table">
      {data.length > 0 ? (
        <>
          {data.map((prescription) => (
            <FinalizeChewyActiveRxRow
              context={context}
              key={prescription.id}
              prescription={prescription}
            />
          ))}
        </>
      ) : (
        <FinalizeOrdersRow
          orderNameDetails={{
            orderName: t('Soap:FINALIZE_CHEWY_ACTIVE_RX_NO_ORDERS'),
          }}
          showWarning={false}
          statusName={undefined}
        />
      )}
    </Box>
  )
}
