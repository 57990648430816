import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults, PhoneUtils } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import i18n from '~/locales/i18n'
import {
  fetchContacts,
  fetchMoreItemsForContacts,
  getContactsIsFetchingList,
  getContactsList,
  getContactsTotalCount,
  getMultipleContacts,
} from '~/store/duck/contacts'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'

import ContactDetails from './ContactDetails'
import ContactTypeCell from './ContactTypeCell'
import NoContactsScreen from './NoContactsScreen'

const columns = [
  {
    label: i18n.t('Common:NAME'),
    highlight: true,
    prop: 'name',
    width: 4,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    prop: ContactTypeCell,
    width: 3,
  },
  {
    label: i18n.t('Common:EMAIL'),
    prop: 'email',
    width: 4,
    wrap: 'wrap',
  },
  {
    label: i18n.t('Common:PHONE'),
    prop: ({ phone }: { phone: string }) => PhoneUtils.getPhoneNumber(phone),
    width: 3,
  },
  {
    label: i18n.t('Admin:CONTACT.IN_REFERRAL_LIST'),
    prop: ({ inReferralList }: { inReferralList: boolean }) =>
      boolToYesNoString(inReferralList),
    width: 2,
    wrap: 'wrap',
  },
]

export interface ContactsTableComponentProps {
  contactId?: string
  headerButtons: React.ReactNode
  onDetailsClose: () => void
}

const ContactsTableComponent = ({
  contactId,
  headerButtons,
  onDetailsClose,
}: ContactsTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const list = useSelector(getContactsList)
  const totalCount = useSelector(getContactsTotalCount)
  const contacts = useSelector(getMultipleContacts(list))
  const contactsIsFetchingList = useSelector(getContactsIsFetchingList)
  const { t } = useTranslation('Admin')

  const search = getUrlSearchParam('query', location.search)

  useEffect(() => {
    dispatch(
      fetchContacts(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search || ''),
    )
  }, [search])

  const navigateToContact = (id: string) => {
    navigate(addSearch(location, `/admin/general/contacts/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(contacts[index])

  const loadMoreItems = useCallback(
    (startIndex: number, endIndex: number) => {
      dispatch(fetchMoreItemsForContacts(startIndex, endIndex, search || ''))
    },
    [search],
  )

  return (
    <ExpandableTable
      Expander={ContactDetails}
      NoItemsScreen={NoContactsScreen}
      headerButtons={headerButtons}
      isLoading={contactsIsFetchingList}
      itemId={contactId}
      list={contacts}
      searchTerm={search}
      title={t('Admin:CONTACT.CONTACT_LIST')}
      onClose={onDetailsClose}
      onSelected={navigateToContact}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default ContactsTableComponent
