import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import { Field, PuiSelect, PuiTextField } from '@pbt/pbt-ui-components'

import {
  useGetCompoundingReasonTypeById,
  useGetIsCompoundingReasonDetailsRequired,
} from '~/store/hooks/prescription'
import { getPrescriptionCompoundingReason } from '~/store/reducers/constants'

interface CompoundingReasonProps {
  compoundingReasonField: Field
  compoundingReasonIdField: Field
}

const CompoundingReason = ({
  compoundingReasonField,
  compoundingReasonIdField,
}: CompoundingReasonProps) => {
  const { t } = useTranslation('Common')

  const prescriptionCompoundingReason = useSelector(
    getPrescriptionCompoundingReason,
  )

  const { isAllergiesIntolerances } = useGetCompoundingReasonTypeById()(
    compoundingReasonIdField.value,
  )
  const isCompoundingReasonDetailsRequired =
    useGetIsCompoundingReasonDetailsRequired()(compoundingReasonIdField.value)

  return (
    <Grid
      container
      item
      alignItems="center"
      direction="column"
      pl={2}
      spacing={2}
    >
      <FormControl fullWidth margin="normal">
        <InputLabel>
          {t('Common:REASON_FOR_COMPOUNDING.REASON_FOR_COMPOUNDING')}*
        </InputLabel>
        <PuiSelect
          field={compoundingReasonIdField}
          items={prescriptionCompoundingReason}
        />
      </FormControl>
      {isCompoundingReasonDetailsRequired && (
        <PuiTextField
          field={compoundingReasonField}
          label={`${
            isAllergiesIntolerances
              ? t(
                  'Common:REASON_FOR_COMPOUNDING.INPUT_LABEL_ALLERGIES_INTOLERANCES',
                )
              : t('Common:REASON_FOR_COMPOUNDING.INPUT_LABEL_OTHER')
          }*`}
        />
      )}
    </Grid>
  )
}

export default CompoundingReason
