import { gql } from '@apollo/client/core'

export const SAVE_PATIENT_PREFERENCES = gql`
  mutation CreateOrUpdatePatientPreference(
    $patientId: ID!
    $input: PatientPreferenceInput!
  ) {
    createOrUpdatePatientPreference(patientId: $patientId, input: $input) {
      patientId
      caution {
        id
      }
      medicalAlert {
        id
      }
      treats
      namePronunciation
      drugPreference {
        id
      }
      staffGender {
        id
      }
      venipuncture {
        id
      }
      premedicationVaccineReaction {
        id
      }
      performedTreatmentsLocation {
        id
      }
      sedation {
        id
      }
      labWorkFrequency {
        id
      }
      generalComment
      importantComment
      medicalComment
    }
  }
`
