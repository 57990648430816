import React from 'react'
import { DateUtils, Text } from '@pbt/pbt-ui-components'

import { MembershipPlan } from '~/types'

interface MemberSinceCellProps {
  plan: MembershipPlan
}

const MemberSinceCell = ({ plan }: MemberSinceCellProps) => (
  <Text variant="body2">{DateUtils.formatDate(plan.creationDate)}</Text>
)

export default MemberSinceCell
