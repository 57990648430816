import React, { ForwardedRef, forwardRef } from 'react'

import BaseTemplateInput from './BaseTemplateInput'
import {
  BaseTemplateInputHandle,
  BaseTemplateInputProps,
} from './BaseTemplateInput/BaseTemplateInput'
import SoapBaseTemplateInput from './SoapBaseTemplateInput'

export interface WrappedBaseTemplateInputProps extends BaseTemplateInputProps {
  id?: string
  isSoap?: boolean
}

const WrappedBaseTemplateInput = forwardRef(function WrappedBaseTemplateInput(
  { isSoap = false, ...rest }: WrappedBaseTemplateInputProps,
  ref: ForwardedRef<BaseTemplateInputHandle>,
) {
  const Component = isSoap ? SoapBaseTemplateInput : BaseTemplateInput

  return <Component ref={ref} {...rest} />
})

export default WrappedBaseTemplateInput
