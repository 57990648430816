import React from 'react'
import { Button, ButtonGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, LanguageUtils } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      textTransform: 'none',
      border: theme.constants.tabBorder,
      borderRadius: 4,
      backgroundColor: theme.colors.contentBackground,
      color: theme.colors.tabLabel,
      fontWeight: 400,
      padding: theme.spacing(0.5, 1),
      minWidth: '150px !important',
      [theme.breakpoints.down('md')]: {
        minWidth: '99px !important',
      },
      '&:hover': {
        backgroundColor: theme.colors.contentBackground,
      },
    },
    selectedButton: {
      backgroundColor: theme.colors.title,
      color: theme.colors.profileText,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: theme.colors.title,
      },
    },
  }),
  { name: 'PuiButtonGroup' },
)

export type PuiButtonGroupItem = {
  icon?: React.ReactNode
  label?: string
  name?: string
  value?: string
} & {
  [key: string]: any
}

export interface PuiButtonGroupProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  items: PuiButtonGroupItem[]
  onItemSelected: (item: PuiButtonGroupItem) => void
  selectedItem?: PuiButtonGroupItem | string
}

const PuiButtonGroup = ({
  className,
  classes: classesProp,
  selectedItem,
  items,
  onItemSelected,
}: PuiButtonGroupProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <ButtonGroup
      aria-label="outlined primary button group"
      className={className}
      color="primary"
    >
      {items.map((item) => (
        <Button
          className={classNames(classes.button, {
            [classes.selectedButton]:
              selectedItem === item.label ||
              selectedItem === item.name ||
              selectedItem === item,
          })}
          key={item.label || item.name}
          onClick={() => onItemSelected(item)}
        >
          {item.icon ? <>{item.icon} &nbsp;</> : null}
          {item.label ||
            LanguageUtils.getTranslatedFieldName(item, 'name', item.label)}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default PuiButtonGroup
