import * as R from 'ramda'
import { PlaceholderConstant } from '@pbt/pbt-ui-components'

import { DYNAMIC_TEXT_MARKER } from './decorator/placeholder'

export type PlaceholderString = `<<${string}>>`
export type PlaceholderHTML = `&lt;&lt;${string}&gt;&gt;`

export const deserializeLocalePlaceholder = (
  Placeholders: PlaceholderConstant[],
  name: string | null,
): string =>
  R.pipe(
    R.pluck('items'),
    R.flatten,
    R.find(R.propEq('name', name)),
    R.propOr(name, 'nameTranslation'),
  )(Placeholders) as string

export const serializePlaceholder = (
  Placeholders: PlaceholderConstant[],
  name: string | null,
): string =>
  R.pipe(
    R.pluck('items'),
    R.flatten,
    R.find(R.propEq('nameTranslation', name)),
    R.propOr(name, 'name'),
  )(Placeholders) as string

export const getStringFromPlaceholder = (text: PlaceholderString) =>
  text.replace('<<', '').replace('>>', '')

export const getPlaceholderString = (placeholder: string): PlaceholderString =>
  `<<${placeholder}>>`
export const getPlaceholderHtml = (placeholder: string): PlaceholderHTML =>
  `&lt;&lt;${placeholder}&gt;&gt;`

export const getLocalizedPlaceholders = (
  initialHtml: string,
  Placeholders: PlaceholderConstant[],
) => {
  const arr = [...initialHtml.matchAll(/&lt;&lt;(.*?)&gt;&gt;/g)]
  const normalizedArr = R.pluck(1, arr)
  return [
    normalizedArr.map((item) =>
      deserializeLocalePlaceholder(Placeholders, item),
    ),
    normalizedArr,
  ]
}

export const includePlaceholderChars = (
  placeholder?: PlaceholderHTML | string,
) => placeholder?.includes('&lt;&lt;') || false

export const placeholderHasSpanTag = (
  htmlMessage: string,
  originalPlaceholder: string,
) =>
  Boolean(
    htmlMessage.match(
      new RegExp(
        `<span ${DYNAMIC_TEXT_MARKER}>${getPlaceholderHtml(
          originalPlaceholder,
        )}</span>`,
      ),
    ),
  )

export const injectSpanInPlaceholder = (
  htmlMessage: string,
  originalPlaceholder: string,
  localizedPlaceholder: string,
) =>
  htmlMessage.replace(
    getPlaceholderHtml(originalPlaceholder),
    `<span ${DYNAMIC_TEXT_MARKER}>${getPlaceholderHtml(
      localizedPlaceholder,
    )}</span>`,
  )
