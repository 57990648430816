import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'

import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import { DocumentElementStateItem, TextEntryState } from '~/types'
import useDialog from '~/utils/useDialog'

import { DocumentElementAccordion } from './DocumentElementAccordion'

interface TextEntryProps {
  documentElement: TextEntryState
  isDragging?: boolean
  onDelete?: () => void
  onUpdate?: (documentElement: DocumentElementStateItem) => void
}

export const TextEntry = ({
  documentElement,
  isDragging,
  onUpdate,
  onDelete,
}: TextEntryProps) => {
  const { t } = useTranslation('Common')

  const [openTextEntryDialog] = useDialog(DialogNames.TEXT_ENTRY)

  return (
    <Box mb={2} px={3}>
      <DocumentElementAccordion
        isDragging={isDragging}
        title={t('Common:TEXT_ENTRY')}
        onDelete={onDelete}
        onEdit={
          onUpdate
            ? () =>
                openTextEntryDialog({
                  onSave: onUpdate,
                  documentElement,
                })
            : undefined
        }
      >
        <Grid container p={1}>
          <Grid
            item
            borderBottom={(theme) => theme.constants.outlinedInputBorder}
            ml={2}
            mt={1}
            pb={0}
            xs={12}
          >
            <Typography.ParagraphLg color="grayGray2">
              {documentElement.label || ''}
            </Typography.ParagraphLg>
          </Grid>
        </Grid>
      </DocumentElementAccordion>
    </Box>
  )
}
