import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Field, PermissionArea } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { getCRUDByArea } from '~/store/reducers/auth'

interface MemberLockAccessSectionProps {
  lockOutsideWorkingHours: Field
}

const MemberLockAccessSection = ({
  lockOutsideWorkingHours,
}: MemberLockAccessSectionProps) => {
  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const { t } = useTranslation('Common')

  const label = lockOutsideWorkingHours.value ? t('Common:ON') : t('Common:OFF')

  return (
    <Grid container item>
      <Grid item sm={12}>
        <PuiSwitch
          disabled={!personPermissions.update}
          field={lockOutsideWorkingHours}
          label={label}
          name="lockAccessSwitch"
        />
      </Grid>
    </Grid>
  )
}

export default MemberLockAccessSection
