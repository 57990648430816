import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  EnabledState,
  NotificationSyntheticAreas,
} from '~/constants/notifications'
import {
  Notification,
  NotificationSettings,
  NotificationsTotalCounts,
  NotificationStyleSettings,
  NotifyForItemsSettings,
  TableFilter,
} from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import * as AuthActions from '../actions/types/auth'
import {
  CLOSE_NOTIFICATIONS_HISTORY_POPUP,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_FAILURE,
  FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATION_SETTINGS,
  FETCH_NOTIFICATION_SETTINGS_FAILURE,
  FETCH_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_FAILURE,
  FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  NEW_LIST_NOTIFICATION,
  NEW_WS_NOTIFICATION_FAILURE,
  NOTIFICATIONS_CHANGE_IS_READ,
  NOTIFICATIONS_CHANGE_IS_READ_FAILURE,
  NOTIFICATIONS_CHANGE_IS_READ_SUCCESS,
  NOTIFICATIONS_DELETE,
  NOTIFICATIONS_DELETE_ALL,
  NOTIFICATIONS_DELETE_ALL_FAILURE,
  NOTIFICATIONS_DELETE_ALL_SUCCESS,
  NOTIFICATIONS_DELETE_FAILURE,
  NOTIFICATIONS_DELETE_SUCCESS,
  NOTIFICATIONS_MARK_ALL_AS_READ,
  NOTIFICATIONS_MARK_ALL_AS_READ_FAILURE,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_FAILURE,
  NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_SUCCESS,
  NOTIFICATIONS_MARK_ALL_AS_READ_SUCCESS,
  OPEN_NOTIFICATIONS_HISTORY_POPUP,
  SET_NOTIFICATIONS_MAP,
  UPDATE_FILTERS,
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM_MAP,
  UPDATE_NOTIFICATIONS_TOTAL_COUNTS,
  UPDATE_NOTIFICATIONS_TOTAL_COUNTS_FAILURE,
} from '../actions/types/notifications'
import type { RootState } from '../index'

export type NotificationsState = {
  error: string | null
  filters: Record<string, TableFilter>
  isHistoryTablePopupOpen: boolean
  isLoading: boolean
  isSaving: boolean
  list: string[]
  map: Record<string, Notification>
  newNotificationPendingIds: string[]
  notificationDeleting: boolean
  notificationIdsForAreaByLinkedItemId: Record<string, Record<string, string[]>>
  notificationStyleSettingsByPersonId: Record<
    string,
    NotificationStyleSettings[]
  >
  notificationUpdating: boolean
  notificationsFetching: boolean
  notificationsTotalCountsFetching: boolean
  notifyForItemSettingsByPersonId: Record<string, NotifyForItemsSettings[]>
  totalCount: number
  totalCounts: NotificationsTotalCounts
}

export const NOTIFICATIONS_INITIAL_STATE: NotificationsState = {
  map: {},
  list: [],
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  notifyForItemSettingsByPersonId: {},
  notificationStyleSettingsByPersonId: {},
  totalCounts: {
    menuTotalCountsForAreas: {},
    listTotalCountsForAreas: {},
  },
  notificationIdsForAreaByLinkedItemId: {},
  isLoading: false,
  isSaving: false,
  notificationsTotalCountsFetching: false,
  notificationsFetching: false,
  notificationUpdating: false,
  notificationDeleting: false,
  error: null,
  filters: {
    area: { value: NotificationSyntheticAreas.ALL.id },
  },
  isHistoryTablePopupOpen: false,
  newNotificationPendingIds: [],
}

function saveSettings(
  personId: string,
  notificationSettings: NotificationSettings,
  state: NotificationsState,
) {
  return {
    notifyForItemSettingsByPersonId: {
      ...state.notifyForItemSettingsByPersonId,
      [personId]: notificationSettings.notifyForItemsSettings,
    },
    notificationStyleSettingsByPersonId: {
      ...state.notificationStyleSettingsByPersonId,
      [personId]: notificationSettings.styleSettings,
    },
  }
}

export const notificationsReducer = (
  state: NotificationsState = NOTIFICATIONS_INITIAL_STATE,
  action: AnyAction,
): NotificationsState => {
  switch (action.type) {
    case FETCH_NOTIFICATION_SETTINGS:
      return { ...state, isLoading: true }
    case FETCH_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...saveSettings(action.personId, action.notificationSettings, state),
      }
    case FETCH_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }

    case UPDATE_NOTIFICATION_SETTINGS:
      return { ...state, isSaving: true }
    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        ...saveSettings(action.personId, action.notificationSettings, state),
      }
    case UPDATE_NOTIFICATION_SETTINGS_FAILURE:
      return { ...state, isSaving: false, error: getErrorMessage(action.error) }

    case UPDATE_NOTIFICATIONS_LINKED_TO_AREA_LIST_ITEM_MAP:
      return {
        ...state,
        notificationIdsForAreaByLinkedItemId: {
          ...state.notificationIdsForAreaByLinkedItemId,
          [action.areaId]: {
            ...state.notificationIdsForAreaByLinkedItemId[action.areaId],
            ...action.notificationIdsByLinkedItemId,
          },
        },
      }
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.notifications),
      }
    case SET_NOTIFICATIONS_MAP:
      return { ...state, map: action.notificationsMap }

    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notificationsFetching: true,
        list: [],
        totalCount: NOTIFICATIONS_INITIAL_STATE.totalCount,
      }
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsFetching: false,
        list: action.list,
        totalCount: action.totalCount,
        newNotificationPendingIds: [],
      }
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsFetching: false,
        error: getErrorMessage(action.error),
        totalCount: state.totalCount + state.newNotificationPendingIds.length,
        list: [...state.newNotificationPendingIds, ...state.list],
        newNotificationPendingIds: [],
      }

    case FETCH_MORE_ITEMS_FOR_NOTIFICATIONS:
      return { ...state, notificationsFetching: true }
    case FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        list: [
          ...state.newNotificationPendingIds,
          ...mergeArraysAtIndex(state.list, action.list, action.from),
        ],
        notificationsFetching: false,
        totalCount: action.totalCount,
        newNotificationPendingIds: [],
      }
    case FETCH_MORE_ITEMS_FOR_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsFetching: false,
        error: getErrorMessage(action.error),
        totalCount: state.totalCount + state.newNotificationPendingIds.length,
        list: [...state.newNotificationPendingIds, ...state.list],
        newNotificationPendingIds: [],
      }

    case UPDATE_FILTERS:
      return {
        ...state,
        filters: R.isEmpty(action.filters)
          ? NOTIFICATIONS_INITIAL_STATE.filters
          : action.filters,
      }
    case CLOSE_NOTIFICATIONS_HISTORY_POPUP:
      return { ...state, isHistoryTablePopupOpen: false }
    case OPEN_NOTIFICATIONS_HISTORY_POPUP:
      return { ...state, isHistoryTablePopupOpen: true }

    case FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS:
      return { ...state, notificationsTotalCountsFetching: true }
    case FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_SUCCESS:
      return { ...state, notificationsTotalCountsFetching: false }
    case FETCH_NOTIFICATIONS_AREAS_TOTAL_COUNTS_FAILURE:
      return {
        ...state,
        notificationsTotalCountsFetching: false,
        error: getErrorMessage(action.error),
      }

    case UPDATE_NOTIFICATIONS_TOTAL_COUNTS:
      return { ...state, totalCounts: action.totalCounts }
    case UPDATE_NOTIFICATIONS_TOTAL_COUNTS_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }

    case NOTIFICATIONS_CHANGE_IS_READ:
      return {
        ...state,
        notificationUpdating: true,
        map: {
          ...state.map,
          [action.notificationId]: {
            ...state.map[action.notificationId],
            read: action.isRead,
            previousIsRead: state.map[action.notificationId].read,
          },
        },
      }
    case NOTIFICATIONS_CHANGE_IS_READ_SUCCESS:
      return { ...state, notificationUpdating: false }
    case NOTIFICATIONS_CHANGE_IS_READ_FAILURE:
      return {
        ...state,
        notificationUpdating: false,
        error: getErrorMessage(action.error),
        map: {
          ...state.map,
          [action.notificationId]: {
            ...state.map[action.notificationId],
            read: state.map[action.notificationId].previousIsRead,
          },
        },
      }

    case NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM:
      return { ...state, notificationUpdating: true }
    case NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_SUCCESS:
      return { ...state, notificationUpdating: false }
    case NOTIFICATIONS_MARK_ALL_AS_READ_FOR_LINKED_ITEM_FAILURE:
      return {
        ...state,
        notificationUpdating: false,
        error: getErrorMessage(action.error),
      }

    case NOTIFICATIONS_MARK_ALL_AS_READ:
      return { ...state, notificationUpdating: true }
    case NOTIFICATIONS_MARK_ALL_AS_READ_SUCCESS:
      return { ...state, notificationUpdating: false }
    case NOTIFICATIONS_MARK_ALL_AS_READ_FAILURE:
      return {
        ...state,
        notificationUpdating: false,
        error: getErrorMessage(action.error),
      }

    case NOTIFICATIONS_DELETE:
      return {
        ...state,
        notificationDeleting: true,
      }
    case NOTIFICATIONS_DELETE_SUCCESS:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        list: R.without([action.notificationId], state.list),
        notificationDeleting: false,
      }
    case NOTIFICATIONS_DELETE_FAILURE: {
      return {
        ...state,
        notificationDeleting: false,
        error: getErrorMessage(action.error),
      }
    }

    case NOTIFICATIONS_DELETE_ALL:
      return { ...state, notificationDeleting: true }
    case NOTIFICATIONS_DELETE_ALL_SUCCESS:
      return { ...state, notificationDeleting: false, list: [], totalCount: 0 }
    case NOTIFICATIONS_DELETE_ALL_FAILURE:
      return { ...state, notificationDeleting: false }

    case NEW_WS_NOTIFICATION_FAILURE:
      return { ...state, error: getErrorMessage(action.error) }

    case NEW_LIST_NOTIFICATION: {
      return state.notificationsFetching
        ? {
            ...state,
            newNotificationPendingIds: [
              action.notificationId,
              ...state.newNotificationPendingIds,
            ],
          }
        : {
            ...state,
            list: [action.notificationId, ...state.list],
            totalCount: state.totalCount + 1,
          }
    }

    case AuthActions.FETCH_CURRENT_USER:
      return NOTIFICATIONS_INITIAL_STATE

    default:
      return state
  }
}

export const getNotification = (state: RootState): NotificationsState =>
  state.notification
export const getNotificationIsLoading = (state: RootState) =>
  getNotification(state).isLoading
export const getNotificationIsSaving = (state: RootState) =>
  getNotification(state).isSaving
export const getNotificationsAreFetching = (state: RootState) =>
  getNotification(state).notificationsFetching
export const getNotificationsTotalCountsFetching = (state: RootState) =>
  getNotification(state).notificationsTotalCountsFetching
export const getNotificationsMap = (state: RootState) =>
  getNotification(state).map
export const getNotificationsList = (state: RootState) =>
  getNotification(state).list
export const getNotificationsItem = (id: string) =>
  createSelector(getNotificationsMap, R.propOr({}, id))
export const getMultipleNotifications = (ids: string[]) =>
  createSelector(getNotificationsMap, (map) => R.props(ids, map))
export const getNotificationIdsForAreaByLinkedItemIdMap = (state: RootState) =>
  getNotification(state).notificationIdsForAreaByLinkedItemId
export const getNotificationIdsByLinkedItemIdMap = (areaId: string) =>
  createSelector(
    getNotificationIdsForAreaByLinkedItemIdMap,
    (map) => R.prop(areaId, map) || {},
  )
export const getNotificationIdsForLinkedItemId = (
  areaId: string,
  itemId: string,
) =>
  createSelector(
    getNotificationIdsByLinkedItemIdMap(areaId),
    (map) => R.prop(itemId, map) || [],
  )
export const getNotificationsForLinkedItemId = R.curry(
  (areaId, itemId, state) => {
    const ids = getNotificationIdsForLinkedItemId(areaId, itemId)(state)
    return getMultipleNotifications(ids)(state)
  },
)

export const getNotificationsTotalCount = (state: RootState) =>
  getNotification(state).totalCount
export const getNotificationsTotalCounts = (state: RootState) =>
  getNotification(state).totalCounts
export const getNotificationsListTotalCounts = (state: RootState) =>
  getNotificationsTotalCounts(state).listTotalCountsForAreas || {}
export const getNotificationsMenuTotalCounts = (state: RootState) =>
  getNotificationsTotalCounts(state).menuTotalCountsForAreas || {}
export const getNotificationsListTotalCountsForArea = (areaId: string) =>
  createSelector(
    getNotificationsListTotalCounts,
    R.pipe(R.prop(areaId), R.propOr(0, 'totalCount')),
  )
export const getNotificationsMenuTotalCountsForArea = (areaId: string) =>
  createSelector(
    getNotificationsMenuTotalCounts,
    R.pipe(R.prop(areaId), R.propOr(0, 'totalCount')),
  )
export const getNotificationsHistoryButtonTotalCount = (state: RootState) =>
  R.sum(
    R.pluck(
      'totalCount',
      Object.values(getNotificationsListTotalCounts(state)),
    ),
  )
export const getNotificationsListFilters = (state: RootState) =>
  getNotification(state).filters
export const getNotificationsListAreaFilter = (state: RootState) =>
  getNotificationsListFilters(state).area

export const getNotificationNotifyForItemSettingsMap = (state: RootState) =>
  getNotification(state).notifyForItemSettingsByPersonId
export const getNotificationStyleSettingsMap = (state: RootState) =>
  getNotification(state).notificationStyleSettingsByPersonId
export const getNotificationsIsHistoryTablePopupOpen = (state: RootState) =>
  getNotification(state).isHistoryTablePopupOpen
export const getNotificationNotifyForItemSettings = (personId: string | Nil) =>
  createSelector(getNotificationNotifyForItemSettingsMap, (map) =>
    personId ? map[personId] : undefined,
  )
export const getNotificationStyleSettings = (personId: string | Nil) =>
  createSelector(getNotificationStyleSettingsMap, (map) =>
    personId ? map[personId] : undefined,
  )
export const getNotificationEnabledStyleSettingIds = R.curry(
  (notificationTypeId, personId, state) =>
    (getNotificationStyleSettings(personId)(state) || [])
      .filter(R.propEq('notificationTypeId', notificationTypeId))
      .filter(R.propEq('enabledState', EnabledState.ENABLED))
      .map(R.prop('typeId')),
)
