import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import { getInventoryShipmentStatus } from '~/store/reducers/constants'

export interface ShipmentStatusFilterProps extends CheckboxListFilterProps {}

const ShipmentStatusFilter = (props: ShipmentStatusFilterProps) => {
  const InventoryShipmentStatus = useSelector(getInventoryShipmentStatus)

  return <CheckboxListFilter items={InventoryShipmentStatus} {...props} />
}

export default ShipmentStatusFilter
