import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

import UploadButton from '~/components/common/buttons/UploadButton'
import DialogNames from '~/constants/DialogNames'
import { BaseMigrationProps } from '~/types/entities/migration'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    icon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'CatalogAndSettings' },
)

const CatalogAndSettings = ({ businessId }: BaseMigrationProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const [openPracticeCloningExportDialog] = useDialog(
    DialogNames.PRACTICE_CLONING_EXPORT_DIALOG,
  )
  const [openPracticeCloningImportDialog] = useDialog(
    DialogNames.PRACTICE_CLONING_IMPORT_DIALOG,
  )

  const handlePracticeCloningExport = () =>
    openPracticeCloningExportDialog({ businessId })

  const handlePracticeCloningImport = () =>
    openPracticeCloningImportDialog({ businessId })

  return (
    <Grid>
      <Text
        link
        fontSize="1.4rem"
        my={0.5}
        onClick={handlePracticeCloningExport}
      >
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.EXPORT_CATALOG_AND_SETTINGS')}
      </Text>
      <UploadButton
        label={t('Businesses:MIGRATION.IMPORT_CATALOG_AND_SETTINGS')}
        my={0.5}
        onClick={handlePracticeCloningImport}
      />
    </Grid>
  )
}

export default CatalogAndSettings
