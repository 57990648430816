import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Nil } from '@pbt/pbt-ui-components'

import { fetchVaccines } from '~/store/actions/medicalHistory'
import {
  getMedicalHistoryIsFetching,
  getMedicalHistoryIsSending,
  getMedicalHistoryVaccines,
} from '~/store/reducers/medicalHistory'
import { VaccineHistoryItem } from '~/types'

// @ts-ignore
import SelectableListWithFilters from '../../soap/list-with-filters/SelectableListWithFilters'
import VaccineHistoryExpandedDetails from './VaccineHistoryExpandedDetails'

const useStyles = makeStyles(
  () => ({
    selectableListContainer: {
      padding: 0,
    },
  }),
  { name: 'AttachmentListWithFilters' },
)

interface VaccineListWithFiltersProps {
  onProceed: (items: VaccineHistoryItem[]) => void
  patientId: string | Nil
}

const VaccineListWithFilters = ({
  patientId,
  ...rest
}: VaccineListWithFiltersProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search'])

  const isSending = useSelector(getMedicalHistoryIsSending)
  const isFetching = useSelector(getMedicalHistoryIsFetching)
  const vaccines = useSelector(getMedicalHistoryVaccines)

  const loadNewItems = () => {
    if (patientId) {
      dispatch(fetchVaccines(patientId))
    }
  }

  return (
    <SelectableListWithFilters
      hideFilters
      DetailsComponent={VaccineHistoryExpandedDetails}
      ListItemProps={{
        showListItemActions: true,
      }}
      classes={{
        container: classes.selectableListContainer,
      }}
      isLoading={isSending}
      isReceivingListItems={isFetching}
      items={vaccines}
      loadNewItems={loadNewItems}
      proceedButtonLabel={t('Common:ADD_TO_TIMELINE')}
      searchPlaceholder={t('Search:VACCINES')}
      {...rest}
    />
  )
}

export default VaccineListWithFilters
