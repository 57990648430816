import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse, Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import CoPetParent, { CoPetParentHandle } from './CoPetParent'
import EmergencyContact, { EmergencyContactHandle } from './EmergencyContact'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      color: theme.colors.iconColor,
      '&:hover': {
        color: theme.colors.iconHover,
      },
    },
    button: {
      padding: theme.spacing(0, 1),
      minWidth: 200,
      textTransform: 'none',
      boxShadow: 'none',
    },
    buttonInactive: {
      backgroundColor: '#FFFFFF',
      border: theme.constants.inactiveBorder,
      color: theme.colors.sideText,
    },
  }),
  { name: 'PetFriend' },
)

enum ButtonType {
  CoPetParentType = 'co-pet-parent',
  EmergencyContactType = 'emergency-contact',
}

export interface PetFriendHandle {
  getHasUnsavedChanges: () => boolean
  save: () => void
}

interface PetFriendProps {
  clientId: string
  onOk?: () => void
}

const PetFriend = forwardRef<PetFriendHandle, PetFriendProps>(
  function PetFriend({ clientId, onOk }, ref) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const [activeButton, setActiveButton] = useState<ButtonType | null>(null)
    const coPetParentRef = useRef<CoPetParentHandle>(null)
    const emergencyContactRef = useRef<EmergencyContactHandle>(null)

    useImperativeHandle(ref, () => ({
      save: () => {
        if (activeButton === ButtonType.CoPetParentType) {
          coPetParentRef.current?.save()
        }
        if (activeButton === ButtonType.EmergencyContactType) {
          emergencyContactRef.current?.save()
        }
      },
      getHasUnsavedChanges: () => {
        if (activeButton === ButtonType.CoPetParentType) {
          return Boolean(coPetParentRef.current?.getHasUnsavedChanges())
        }
        if (activeButton === ButtonType.EmergencyContactType) {
          return Boolean(emergencyContactRef.current?.getHasUnsavedChanges())
        }

        return false
      },
    }))

    return (
      <Grid container direction="column">
        <Grid
          container
          item
          columnSpacing={{ xs: 0, md: 3 }}
          direction={{ xs: 'column', md: 'row' }}
          mb={2}
          rowSpacing={{ xs: 3, md: 0 }}
        >
          <Grid item>
            <Fab
              className={classNames(classes.button, {
                [classes.buttonInactive]:
                  activeButton !== ButtonType.CoPetParentType,
              })}
              type="button"
              variant="extended"
              onClick={() => setActiveButton(ButtonType.CoPetParentType)}
            >
              {t('Common:CO-PET_PARENT')}
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              className={classNames(classes.button, {
                [classes.buttonInactive]:
                  activeButton !== ButtonType.EmergencyContactType,
              })}
              type="button"
              variant="extended"
              onClick={() => setActiveButton(ButtonType.EmergencyContactType)}
            >
              {t('Common:EMERGENCY_CONTACT')}
            </Fab>
          </Grid>
        </Grid>
        <Collapse in={Boolean(activeButton)}>
          {activeButton === ButtonType.CoPetParentType && (
            <CoPetParent clientId={clientId} ref={coPetParentRef} onOk={onOk} />
          )}
          {activeButton === ButtonType.EmergencyContactType && (
            <EmergencyContact
              clientId={clientId}
              ref={emergencyContactRef}
              onOk={onOk}
            />
          )}
        </Collapse>
      </Grid>
    )
  },
)

export default PetFriend
