import React from 'react'
import { HtmlNotesPreview, TextWithTooltip } from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'

import { Task } from '~/types'

interface TaskDetailsCellProps {
  item: Task
}

const TaskDetailsCell = ({ item }: TaskDetailsCellProps) => (
  <TextWithTooltip
    noWrap
    Icon={AddNote}
    IconProps={{
      filled: true,
    }}
    tooltipText={item.notes && <HtmlNotesPreview notes={item.notes} />}
  >
    {item.name}
  </TextWithTooltip>
)

export default TaskDetailsCell
