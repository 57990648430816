import React, { useState } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Button,
  ClickAwayListener,
  Fab,
  Grid,
  Menu,
  MenuItem,
  MenuItemClasses,
  MenuList,
  MenuProps,
  Paper,
  PopoverProps,
  Popper,
  PopperProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ClassesType,
  LanguageUtils,
  Primitive,
  Text,
} from '@pbt/pbt-ui-components'

import MenuItemWithTags from './MenuItemWithTags'

const useStyles = makeStyles(
  (theme) => ({
    linkButton: {
      fontWeight: 400,
      fontSize: '1.4rem',
      textTransform: 'none',
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
    fab: {
      height: 40,
    },
    groupHeader: {
      paddingLeft: theme.spacing(1),
    },
    groupHeaderText: {
      color: theme.colors.groupHeader,
    },
    button: {},
    menu: {},
    buttonSpan: {},
    popperMenu: {
      zIndex: theme.zIndex.headerTopBar,
    },
    colorPin: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
      minWidth: 16,
    },
    menuItemWithPin: {
      paddingLeft: theme.spacing(1),
    },
  }),
  { name: 'MenuDropdown' },
)

interface OptionalMenuItemProps {
  classes?: Partial<MenuItemClasses>
  disabled?: boolean
  pinColor?: string
  subItems?: {
    value: string
  }[]
  tags?: React.ReactNode[]
}

interface DefaultMenuItemProps<T = string> extends OptionalMenuItemProps {
  group?: never
  items?: never
  name: string
  value: T
}

interface GroupMenuItemProps extends OptionalMenuItemProps {
  group: string
  items: {
    disabled?: boolean
    name: string
    nameTranslation?: string
  }[]
  name?: never
  value?: never
}

export type MenuDropdownItem<T> = DefaultMenuItemProps<T> | GroupMenuItemProps

export interface MenuDropdownProps<T = string> {
  Button?: React.ReactElement
  ItemComponent?: React.JSXElementConstructor<any>
  anchorOrigin?: PopoverProps['anchorOrigin']
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  forceClosed?: boolean
  items: MenuDropdownItem<T>[]
  linkButton?: boolean
  menuProps?:
    | Partial<MenuProps>
    | (Partial<PopperProps> & { openOnHover?: true })
    | (Partial<MenuProps> & { openOnHover?: false })
  onSelected: (value: T, displayValue?: string) => void
  openOnHover?: boolean
  title?: string
}

const MenuDropdown = <T extends Primitive>({
  Button: CustomButtonComponent,
  anchorOrigin,
  className,
  items,
  disabled,
  onSelected,
  title,
  linkButton = false,
  openOnHover = false,
  forceClosed = false,
  classes: classesProp,
  menuProps,
  ItemComponent,
}: MenuDropdownProps<T>) => {
  const classes = useStyles({ classes: classesProp })

  const [estimateAnchorElement, setEstimateAnchorElement] =
    useState<HTMLElement>()

  const expandMenuOpen = Boolean(estimateAnchorElement)

  const expandMenu = (event: React.MouseEvent<HTMLLIElement>) => {
    setEstimateAnchorElement(event.currentTarget)
  }

  const closeMenu = () => {
    setEstimateAnchorElement(undefined)
  }

  const onItemClick = (value: T, displayValue?: string) => {
    if (onSelected) {
      onSelected(value, displayValue)
    }
    closeMenu()
  }

  const isCustomButton = Boolean(CustomButtonComponent)
  const isFab = !linkButton && !isCustomButton
  const ButtonComponent: React.JSXElementConstructor<any> = linkButton
    ? Button
    : Fab

  const dropDownProps = {
    'aria-controls': 'menu-dropdown',
    'aria-haspopup': true,
    onMouseEnter: openOnHover ? expandMenu : undefined,
  }

  const hasPins = items.some((item) => item.pinColor)

  const elements = items.filter(Boolean).map((item) => {
    const {
      group,
      value,
      disabled: itemDisabled,
      items: childItems = [],
      tags,
      classes: itemClasses,
      pinColor,
    } = item

    const PuiMenuItem =
      tags?.length && !ItemComponent ? MenuItemWithTags : MenuItem

    return [
      group ? (
        <Grid item>
          <Grid item className={classes.groupHeader}>
            <Text className={classes.groupHeaderText} variant="body2">
              {LanguageUtils.getTranslatedFieldName(item, 'group', group)}
            </Text>
          </Grid>
          {childItems.map((childItem) => (
            <PuiMenuItem
              classes={itemClasses}
              disabled={childItem.disabled}
              key={childItem.name}
              tags={tags}
              onClick={() =>
                onItemClick(childItem.name as T, childItem.nameTranslation)
              }
            >
              {LanguageUtils.getTranslatedFieldName(childItem)}
            </PuiMenuItem>
          ))}
        </Grid>
      ) : (
        <PuiMenuItem
          classes={itemClasses}
          disabled={itemDisabled || disabled}
          tags={tags}
          onClick={() => onItemClick(value!)}
        >
          {ItemComponent ? (
            <ItemComponent {...item} />
          ) : (
            <>
              {hasPins && (
                <Grid bgcolor={pinColor} className={classes.colorPin} />
              )}
              {LanguageUtils.getTranslatedFieldName(item)}
            </>
          )}
        </PuiMenuItem>
      ),
    ]
  })

  return (
    <>
      {CustomButtonComponent ? (
        React.cloneElement(CustomButtonComponent, {
          ...dropDownProps,
          onClick: expandMenu,
          disabled,
        })
      ) : (
        <ButtonComponent
          {...dropDownProps}
          className={classNames(className, classes.button, {
            [classes.linkButton]: linkButton,
            [classes.fab]: isFab,
          })}
          disabled={disabled}
          onClick={expandMenu}
          {...(isFab ? { color: 'inherit', variant: 'extended' } : {})}
        >
          <span className={classNames(classes.buttonSpan)}>{title}</span>
          {expandMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ButtonComponent>
      )}
      {openOnHover ? (
        <Popper
          // @ts-ignore
          anchorEl={estimateAnchorElement}
          className={classes.popperMenu}
          open={expandMenuOpen && !forceClosed}
          placement="left-start"
          {...menuProps}
        >
          <Paper>
            <ClickAwayListener onClickAway={closeMenu}>
              <MenuList
                aria-labelledby="composition-button"
                id="composition-menu"
              >
                {elements}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      ) : (
        <Menu
          disableScrollLock
          // @ts-ignore
          anchorEl={estimateAnchorElement}
          anchorOrigin={
            anchorOrigin || { vertical: 'bottom', horizontal: 'left' }
          }
          classes={{ paper: classes.menu }}
          id="menu-dropdown"
          open={expandMenuOpen}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={closeMenu}
          {...menuProps}
        >
          {elements}
        </Menu>
      )}
    </>
  )
}

export default MenuDropdown
