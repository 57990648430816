import { UnsavedVital, Vital } from '~/types'

import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  ADD_AND_MERGE_VITAL,
  CREATE_VITAL_RECORD,
  DELETE_VITAL_RECORD,
  UPDATE_VITAL_RECORD,
} from './graphql/mutations/vitals'
import { FETCH_VITALS_RECORDS } from './graphql/queries/vitals'
import * as Schema from './schemas'

export const fetchVitals = requestQuery({
  query: FETCH_VITALS_RECORDS,
  variablesHandler: (
    currentBusinessId,
    patientId,
    includeDeleted,
    offset,
    limit,
  ) => ({
    patientId,
    businessId: currentBusinessId,
    includeDeleted,
    offset,
    limit,
  }),
  schema: { vitalsPage: Schema.vitalsList, persons: Schema.userArray },
})

export const addVital = requestMutation({
  mutation: CREATE_VITAL_RECORD,
  variablesHandler: (currentBusinessId: string, vital: Vital) => ({
    businessId: currentBusinessId,
    input: vital,
  }),
  schema: Schema.vital,
})

export const addAndMergeVital = requestMutation({
  mutation: ADD_AND_MERGE_VITAL,
  variablesHandler: (
    currentBusinessId: string,
    soapId: string,
    patientId: string,
    vital: UnsavedVital,
  ) => ({
    businessId: currentBusinessId,
    soapId,
    patientId,
    input: vital,
  }),
  schema: Schema.vital,
})

export const editVital = requestMutation({
  mutation: UPDATE_VITAL_RECORD,
  variablesHandler: (
    currentBusinessId: string,
    vitalId: string,
    vital: Vital,
  ) => ({
    businessId: currentBusinessId,
    id: vitalId,
    input: vital,
  }),
  schema: Schema.vital,
})

export const deleteVital = requestMutation({
  mutation: DELETE_VITAL_RECORD,
  variablesHandler: (currentBusinessId: string, vitalId: string) => ({
    businessId: currentBusinessId,
    id: vitalId,
  }),
})
