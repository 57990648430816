import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiError } from '@pbt/pbt-ui-components'

import type { RootState } from '~/store'
import { PausedMembership } from '~/types'
import { getErrorMessage } from '~/utils/errors'

export interface PausedPatientMembershipsState {
  error: string | null
  isLoading: boolean
  pausedMemberships: PausedMembership[]
}

export const INITIAL_STATE: PausedPatientMembershipsState = {
  error: null,
  isLoading: false,
  pausedMemberships: [],
}

const pausedPatientMembershipsSlice = createSlice({
  name: 'pausedPatientMemberships',
  initialState: INITIAL_STATE,
  reducers: {
    fetchPausedPatientMemberships(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
      }>,
    ) {
      state.isLoading = true
      state.error = null
      state.pausedMemberships = []
    },
    fetchPausedPatientMembershipsSuccess(
      state,

      action: PayloadAction<PausedMembership[]>,
    ) {
      state.isLoading = false
      state.error = null
      state.pausedMemberships = action.payload
    },
    fetchPausedPatientMembershipsFailure(
      state,
      action: PayloadAction<{
        error: ApiError
      }>,
    ) {
      state.isLoading = false
      state.error = getErrorMessage(action.payload.error)
      state.pausedMemberships = []
    },
  },
})

const { actions, reducer } = pausedPatientMembershipsSlice

export const {
  fetchPausedPatientMemberships,
  fetchPausedPatientMembershipsSuccess,
  fetchPausedPatientMembershipsFailure,
} = actions

export default reducer

export const getPausedPatientMembershipsState = (
  state: RootState,
): PausedPatientMembershipsState => state.pausedPatientMemberships
export const getPrescriptionIsLoading = (state: RootState) =>
  getPausedPatientMembershipsState(state).isLoading
export const getPausedPatientMembershipsError = (state: RootState) =>
  getPausedPatientMembershipsState(state).error
export const getPausedPatientMemberships = (state: RootState) =>
  getPausedPatientMembershipsState(state).pausedMemberships
