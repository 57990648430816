import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import MembershipSignUpIframe, { SignUpStep } from './MembershipSignUpIframe'

const useStyles = makeStyles(
  () => ({
    frameWrapperPaper: {
      height: '100%',
      width: 1024,
      maxWidth: 1024,
    },
    dialogContentRoot: {
      position: 'relative',
    },
    frame: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      width: '100%',
      height: '100%',
    },
  }),
  { name: 'MembershipSignUpDialog' },
)

interface MembershipSignUpDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  onSuccess?: () => void
  patientId?: string | Nil
  showFullDetails?: boolean
}

const MembershipSignUpDialog = ({
  showFullDetails,
  clientId,
  patientId,
  open,
  onClose,
  onSuccess,
}: MembershipSignUpDialogProps) => {
  const classes = useStyles()

  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  const [signUpFinished, setSignUpFinished] = useState(false)

  const handleStateChange = (state: SignUpStep) => {
    if (state === SignUpStep.SUCCESS) {
      if (isBoopDisablementEnabled) {
        setSignUpFinished(true)
      }
      if (onSuccess) {
        onSuccess()
      }
    }
  }

  return (
    <PuiDialog
      aria-labelledby="membership-sign-up-dialog"
      classes={{
        paper: signUpFinished ? undefined : classes.frameWrapperPaper,
        dialogContentRoot: classes.dialogContentRoot,
      }}
      open={open}
      scroll="paper"
      onClose={onClose}
    >
      <MembershipSignUpIframe
        className={classes.frame}
        clientId={clientId}
        patientId={patientId}
        showFullDetails={showFullDetails}
        onClose={onClose}
        onStateChange={handleStateChange}
      />
    </PuiDialog>
  )
}

export default MembershipSignUpDialog
