import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { AddButton, Defaults, Nil, Utils } from '@pbt/pbt-ui-components'

import ConstantValueCell from '~/components/common/lists/primitive-table/cells/ConstantValueCell'
import DateCell from '~/components/common/lists/primitive-table/cells/DateCell'
import PuiDataTable from '~/components/common/lists/pui-data-table/PuiDataTable'
import DialogNames from '~/constants/DialogNames'
import { OnHandChangeType } from '~/constants/onHandConstants'
import { OrderType } from '~/constants/SOAPStates'
import i18n from '~/locales/i18n'
import {
  clearHistory as clearOnHandHistory,
  fetchOnHandHistory,
  getHistoryList,
  getHistoryTotalCount,
  getMultipleHistoryRecords,
  getVariation as getOnHandVariation,
} from '~/store/duck/onHandCatalog'
import { fetchShipment } from '~/store/duck/shipments'
import { useOpenInvoice } from '~/store/hooks/finance'
import { useCreateShipmentFromOnHand } from '~/store/hooks/onHandCatalog'
import { getInventoryOnHandChangeType } from '~/store/reducers/constants'
import { OnHandCatalogHistoryItem } from '~/types/entities/onHandCatalog'
import useDialog from '~/utils/useDialog'

import HistoryQuantityCell from './HistoryQuantityCell'

const mainColumn = {
  label: i18n.t('Common:DATE_TIME'),
  type: 'text',
  value: DateCell,
  width: 160,
}

const columns = [
  {
    label: i18n.t('Common:QUANTITY'),
    type: 'text',
    value: HistoryQuantityCell,
    width: 108,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    type: 'text',
    value: ({ changeTypeId }: OnHandCatalogHistoryItem) => (
      <ConstantValueCell
        constant="InventoryOnHandChangeType"
        value={changeTypeId}
      />
    ),
    width: 108,
  },
  {
    label: i18n.t('Common:TEAM_MEMBER'),
    type: 'text',
    value: R.prop('personName'),
    width: 150,
  },
]

export interface OnHandHistoryTableProps {
  variationId: string | Nil
}

const OnHandHistoryTable = ({ variationId }: OnHandHistoryTableProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('Common')
  const onHandVariation = useSelector(getOnHandVariation(variationId))
  const InventoryOnHandChangeType = useSelector(getInventoryOnHandChangeType)

  const OnHandChangeTypeUseId = Utils.findConstantIdByName(
    OnHandChangeType.USE,
    InventoryOnHandChangeType,
  )
  const OnHandChangeTypeAdjustmentId = Utils.findConstantIdByName(
    OnHandChangeType.ADJUSTMENT,
    InventoryOnHandChangeType,
  )
  const OnHandChangeTypeShipmentId = Utils.findConstantIdByName(
    OnHandChangeType.SHIPMENT,
    InventoryOnHandChangeType,
  )

  const totalCount = useSelector(getHistoryTotalCount)
  const list = useSelector(getHistoryList)
  const historyRecords = useSelector(getMultipleHistoryRecords(list))

  const openShipmentDialog = useCreateShipmentFromOnHand(
    onHandVariation && variationId ? [variationId] : [],
  )
  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openAdjustmentDialog] = useDialog(DialogNames.ADJUSTMENT_DIALOG)

  const openInvoice = useOpenInvoice(null, openInvoiceDialog)

  const loadMoreItems = (from: number, to: number) => {
    if (variationId) {
      dispatch(fetchOnHandHistory(variationId, from, to))
    }
  }

  const handleHistoricalItemPreview = R.cond([
    [
      R.propEq('changeTypeId', OnHandChangeTypeUseId),
      ({ clientId, patientId, entityId, logId }: OnHandCatalogHistoryItem) => {
        openInvoice({
          clientId,
          patientId,
          invoiceId: entityId,
          logId,
          logType: OrderType.INVENTORY,
        })
      },
    ],
    [
      R.propEq('changeTypeId', OnHandChangeTypeAdjustmentId),
      ({ entityId }: OnHandCatalogHistoryItem) => {
        navigate(`/admin/catalog/inventories/adjustments/${entityId}`)
      },
    ],
    [
      R.propEq('changeTypeId', OnHandChangeTypeShipmentId),
      ({ entityId }: OnHandCatalogHistoryItem) => {
        dispatch(fetchShipment(entityId))
        navigate(`/admin/catalog/inventories/shipments/${entityId}`)
      },
    ],
  ])

  const getItemPreviewEnabled = (item: OnHandCatalogHistoryItem) =>
    item.changeTypeId === OnHandChangeTypeUseId || Boolean(item.entityId)

  const addAdjustment = () => {
    openAdjustmentDialog({
      adjustment: { variation: onHandVariation },
      onSave: () => {
        loadMoreItems(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT)
      },
    })
  }

  const addShipment = () => {
    openShipmentDialog({
      onSave: () => {
        loadMoreItems(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT)
      },
    })
  }

  useEffect(() => {
    if (variationId) {
      loadMoreItems(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT)
    }
  }, [variationId])

  useEffect(
    () => () => {
      dispatch(clearOnHandHistory())
    },
    [],
  )

  return (
    <Grid container>
      <PuiDataTable
        densed
        columns={columns}
        getItemPreviewEnabled={getItemPreviewEnabled}
        items={historyRecords}
        loadMoreItems={loadMoreItems}
        mainColumn={mainColumn}
        totalCount={totalCount}
        onPreview={handleHistoricalItemPreview}
      />
      <Grid container item mt={1}>
        {onHandVariation && (
          <AddButton
            inline
            addText={t('Common:NEW_ADJUSTMENT')}
            onAdd={addAdjustment}
          />
        )}
        <AddButton
          inline
          addText={t('Common:NEW_SHIPMENT')}
          onAdd={addShipment}
        />
      </Grid>
    </Grid>
  )
}

export default OnHandHistoryTable
