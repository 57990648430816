import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    vertical: (props: UseStylesProps) => ({
      height: '100%',
      marginLeft: theme.spacing(props.spacing),
    }),
    horizontal: (props: UseStylesProps) => ({
      marginTop: theme.spacing(props.spacing),
      width: '100%',
    }),
  }),
  { name: 'Spacer' },
)

export interface SpacerProps {
  orientation?: 'vertical' | 'horizontal'
  spacing?: number
}

interface UseStylesProps {
  spacing: number
}

const Spacer = ({ spacing = 1, orientation = 'horizontal' }: SpacerProps) => {
  const useStylesProps: UseStylesProps = {
    spacing,
  }
  const classes = useStyles(useStylesProps)

  return <div className={classes[orientation]} />
}

export default Spacer
