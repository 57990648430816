import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import DetailsBackButton from '../DetailsBackButton'
import PetFriend from './PetFriend'

const PetFriendPage = () => {
  const navigate = useNavigate()
  const { clientId = '' } = useParams()
  const { t } = useTranslation('Common')

  const onBackButtonClick = () => {
    navigate(`/client/${clientId}`)
  }

  return (
    <Grid container item direction="column">
      <DetailsBackButton onClick={onBackButtonClick}>
        {t('Common:BACK_TO_CLIENT_AND_PATIENT_SUMMARY')}
      </DetailsBackButton>
      <Text mt={2} px={2} variant="hero2">
        {t('Common:NEW_PET_FRIEND')}
      </Text>
      <Grid container item direction="column" p={3}>
        <PetFriend clientId={clientId} />
      </Grid>
    </Grid>
  )
}

export default PetFriendPage
