import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import i18n from '~/locales/i18n'
import {
  fetchLabTestsList,
  fetchMoreItemsForLabTestsList,
  updateLabTestFilters,
} from '~/store/actions/labTests'
import {
  getLabTestFilters,
  getLabTestsIsFetchingList,
  getLabTestsList,
  getLabTestsTotalCount,
  getMultipleLabTests,
} from '~/store/reducers/labTests'
import { LabTest, TableFilter } from '~/types'
import { addSearch, boolToYesNoString, getUrlSearchParam } from '~/utils'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import LabTestDetails from './LabTestDetails'
import LabTestsVendorFilter from './LabTestsVendorFilter'

const columns = [
  {
    label: i18n.t('Common:NAME'),
    prop: 'name',
    highlight: true,
    width: 5,
  },
  {
    label: i18n.t('Common:TYPE_ONE'),
    prop: 'type',
    width: 4,
  },
  {
    label: i18n.t('Common:VENDOR'),
    prop: 'vendor',
    filter: 'labVendorIds',
    FilterComponent: LabTestsVendorFilter,
    width: 5,
  },
  {
    label: i18n.t('Common:ACTIVE_ONE'),
    prop: (item: LabTest) => boolToYesNoString(item.priced),
    width: 2,
  },
]

export interface LabTestsTableComponentProps {
  headerButtons: React.ReactNode
  labTestId?: string
  onDetailsClose: () => void
}

const LabTestsTableComponent = ({
  headerButtons,
  labTestId,
  onDetailsClose,
}: LabTestsTableComponentProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const list = useSelector(getLabTestsList)
  const labTests = useSelector(getMultipleLabTests(list))
  const totalCount = useSelector(getLabTestsTotalCount)
  const filters = useSelector(getLabTestFilters)
  const isFetchingList = useSelector(getLabTestsIsFetchingList)
  const { t } = useTranslation('Common')

  const search = getUrlSearchParam('query', location.search)

  useEffectExceptOnMount(() => {
    dispatch(
      fetchLabTestsList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search, filters])

  useEffect(() => {
    dispatch(updateLabTestFilters({}))
  }, [])

  const navigateToLabTest = (id: string) => {
    navigate(addSearch(location, `/admin/catalog/lab-tests/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(labTests[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForLabTestsList(startIndex, endIndex, search))
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(updateLabTestFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(updateLabTestFilters({}))
  }

  const getDisabled = (item: LabTest) => !item.priced

  return (
    <ExpandableTable
      Expander={LabTestDetails}
      hasSelectedFilters={!R.isEmpty(filters)}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={labTestId}
      list={labTests}
      searchTerm={search}
      title={t('Common:LAB_TEST_CATALOG')}
      onClose={onDetailsClose}
      onSelected={navigateToLabTest}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onClearFilters={onClearFilters}
      />
    </ExpandableTable>
  )
}

export default LabTestsTableComponent
