import { useSelector } from 'react-redux'
import { Nil, RoleBusinessCategory, User } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessId, getCurrentUser } from '~/store/reducers/auth'
import { getBusiness } from '~/store/reducers/businesses'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getRolesMap } from '~/store/reducers/roles'

import { getHasRole } from './roleUtils'

const useHasRole = (
  roleNames: string[] | string,
  userProp?: User | Nil,
  businessIdProp?: string,
) => {
  const currentUser = useSelector(getCurrentUser)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const roles = useSelector(getRolesMap)
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const user = userProp || currentUser || {}
  const businessId = businessIdProp || currentBusinessId || ''

  const business = useSelector(getBusiness(businessId))
  const businessCategory = business?.omniChannel
    ? RoleBusinessCategory.CVC
    : RoleBusinessCategory.THIRD_PARTY

  return getHasRole({
    rolesMap: roles,
    businessId,
    roleNames,
    user,
    businessType: isCvcRolesEnabled ? businessCategory : undefined,
  })
}

export default useHasRole
