import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Nil } from '@pbt/pbt-ui-components'

import DownloadButton from '~/components/common/buttons/DownloadButton'
import {
  completeLabOrderWithCallback,
  getLabOrderIsCompleting,
} from '~/store/duck/labOrders'
import { print } from '~/store/duck/print'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      minWidth: 150,
      height: 40,
      margin: theme.spacing(0, 1),
      whiteSpace: 'nowrap',
    },
    downloadButton: {
      marginLeft: 'auto',
      margin: theme.spacing(0, 1),
    },
  }),
  { name: 'OrderResultsActions' },
)

export interface OrderResultsActionsProps {
  canComplete?: boolean
  orderId: string | Nil
  pdfUrl: string | Nil
  vendorId: string | Nil
}

const OrderResultsActions = ({
  orderId,
  vendorId,
  pdfUrl,
  canComplete = false,
}: OrderResultsActionsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isCompleting = useSelector(getLabOrderIsCompleting)
  const { t } = useTranslation('Common')
  const [completed, setCompleted] = useState(!canComplete)

  const base = window.location.origin
  const pdfDownloadUrl = new URL(pdfUrl || '', base)
  pdfDownloadUrl.searchParams.set('download', 'true')

  const completeOrderCallback = () => setCompleted(true)

  const handleMarkAsReviewed = () => {
    if (orderId) {
      dispatch(
        completeLabOrderWithCallback(vendorId, orderId, completeOrderCallback),
      )
    }
  }

  return (
    <Grid container item alignItems="center">
      {!completed && (
        <ButtonWithLoader
          className={classes.button}
          disabled={isCompleting}
          loading={isCompleting}
          onClick={handleMarkAsReviewed}
        >
          {t('Common:MARK_AS_REVIEWED')}
        </ButtonWithLoader>
      )}
      <ButtonWithLoader
        className={classes.button}
        onClick={() => {
          if (pdfUrl) {
            dispatch(print(pdfUrl))
          }
        }}
      >
        {t('Common:PRINT_ACTION')}
      </ButtonWithLoader>
      <DownloadButton
        round
        classes={{
          link: classes.downloadButton,
        }}
        href={pdfDownloadUrl.href}
      />
    </Grid>
  )
}

export default OrderResultsActions
