import * as R from 'ramda'
import { all, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import { finishLoading, startLoading } from '../duck/progress'
import {
  fetchClientSurveyLink,
  fetchClientSurveyLinkFailure,
  fetchClientSurveyLinkSuccess,
  fetchSoapActiveTemplate,
  fetchSoapActiveTemplateFailure,
  fetchSoapActiveTemplateSuccess,
  fetchUncoveredSoapTemplateWidgets,
  fetchUncoveredSoapTemplateWidgetsFailure,
  fetchUncoveredSoapTemplateWidgetsSuccess,
  resetSoapTemplateToDefault,
  resetSoapTemplateToDefaultFailure,
  resetSoapTemplateToDefaultSuccess,
  updateSoapTemplate,
  updateSoapTemplateFailure,
  updateSoapTemplateSuccess,
} from '../reducers/soapV2'
import requestAPI from './utils/requestAPI'

export function* fetchSoapActiveTemplateSaga({
  payload,
}: ReturnType<typeof fetchSoapActiveTemplate>) {
  try {
    yield put(startLoading('soap-tabs'))
    const template = yield* requestAPI(API.fetchSoapActiveTemplate, payload)
    yield put(fetchSoapActiveTemplateSuccess(template))
  } catch (error) {
    yield put(fetchSoapActiveTemplateFailure({ error: error as ApiError }))
  } finally {
    yield put(finishLoading('soap-tabs'))
  }
}

export function* fetchUncoveredSoapTemplateWidgetsSaga({
  payload,
}: ReturnType<typeof fetchUncoveredSoapTemplateWidgets>) {
  try {
    const widgets = yield* requestAPI(
      API.fetchUncoveredSoapTemplateWidgets,
      payload,
    )
    const widgetIds = R.pluck<'id', Record<string, string>>('id', widgets)
    yield put(fetchUncoveredSoapTemplateWidgetsSuccess(widgetIds))
  } catch (error) {
    yield put(
      fetchUncoveredSoapTemplateWidgetsFailure({ error: error as ApiError }),
    )
  }
}

export function* fetchClientSurveyLinkSaga({
  payload,
}: ReturnType<typeof fetchClientSurveyLink>) {
  try {
    const surveyLink = yield* requestAPI(API.fetchClientSurveyLink, payload)
    const { link } = surveyLink
    yield put(fetchClientSurveyLinkSuccess(link))
  } catch (error) {
    yield put(fetchClientSurveyLinkFailure({ error: error as ApiError }))
  }
}

export function* resetSoapTemplateToDefaultSaga({
  payload,
}: ReturnType<typeof resetSoapTemplateToDefault>) {
  try {
    const { id } = payload
    const template = yield* requestAPI(API.resetSoapTemplateToDefault, { id })
    yield put(resetSoapTemplateToDefaultSuccess(template))
  } catch (error) {
    yield put(resetSoapTemplateToDefaultFailure({ error: error as ApiError }))
  }
}
export function* updateSoapTemplateSaga({
  payload,
}: ReturnType<typeof updateSoapTemplate>) {
  try {
    const { id, input } = payload
    const template = yield* requestAPI(API.updateSoapTemplate, { id, input })
    yield put(updateSoapTemplateSuccess(template))
  } catch (error) {
    yield put(updateSoapTemplateFailure({ error: error as ApiError }))
  }
}

function* watchFetchSoapActiveTemplate() {
  yield takeLeading(fetchSoapActiveTemplate.type, fetchSoapActiveTemplateSaga)
}

function* watchFetchUncoveredSoapTemplateWidgets() {
  yield takeLeading(
    fetchUncoveredSoapTemplateWidgets.type,
    fetchUncoveredSoapTemplateWidgetsSaga,
  )
}

function* watchFetchClientSurveyLink() {
  yield takeLeading(fetchClientSurveyLink.type, fetchClientSurveyLinkSaga)
}

function* watchResetSoapTemplateToDefault() {
  yield takeLatest(
    resetSoapTemplateToDefault.type,
    resetSoapTemplateToDefaultSaga,
  )
}

function* watchUpdateSoapTemplate() {
  yield takeLatest(updateSoapTemplate.type, updateSoapTemplateSaga)
}

export default function* soapSaga() {
  yield all([
    watchFetchSoapActiveTemplate(),
    watchFetchUncoveredSoapTemplateWidgets(),
    watchResetSoapTemplateToDefault(),
    watchUpdateSoapTemplate(),
    watchFetchClientSurveyLink(),
  ])
}
