import { Adjustment, UnsavedAdjustment } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const createAdjustment = (
  businessId: string,
  adjustment: UnsavedAdjustment,
) =>
  request(
    getAPIPath('inventory/adjustment'),
    { method: 'POST', params: { businessId }, data: adjustment },
    true,
    Schema.adjustment,
  )

export const bulkCreateAdjustments = (
  businessId: string,
  adjustments: Adjustment[],
) =>
  request(
    getAPIPath('inventory/adjustment/bulk'),
    { method: 'POST', params: { businessId }, data: adjustments },
    true,
  )

export const fetchAdjustment = (businessId: string, adjustmentId: string) =>
  request(
    getAPIPath(`inventory/adjustment/${adjustmentId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.adjustment,
  )

export const editAdjustment = (businessId: string, adjustment: Adjustment) =>
  request(
    getAPIPath(`inventory/adjustment/${adjustment.id}`),
    { method: 'PUT', params: { businessId }, data: adjustment },
    true,
    Schema.adjustment,
  )

export const deleteAdjustment = (businessId: string, adjustmentId: string) =>
  request(
    getAPIPath(`inventory/adjustment/${adjustmentId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchAdjustments = (
  businessId: string,
  from: number,
  to: number,
) =>
  request(
    getAPIPath('inventory/adjustment/byBusiness'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.adjustmentList,
  )

export const fetchVariationAdjustments = (
  businessId: string,
  inventoryVariationId: string,
  from: number,
  to: number,
) =>
  request(
    getAPIPath('inventory/adjustment/byVariation'),
    { method: 'GET', params: { businessId, inventoryVariationId, from, to } },
    true,
    Schema.adjustmentList,
  )
