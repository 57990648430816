import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  moment,
  Nil,
  PuiDialog,
  SignatureView,
  Text,
} from '@pbt/pbt-ui-components'

import CertificatePreviewGenerateTypes from '~/constants/CertificatePreviewGenerateTypes'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Vaccinations, VaccinationSignature } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import useDialog from '~/utils/useDialog'

import VaccinationsList, { VaccinationsListHandle } from './VaccinationsList'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 800,
      maxWidth: 800,
    },
    button: {
      width: 130,
    },
  }),
  { name: 'ProofOfVaccinationDialog' },
)

export interface ProofOfVaccinationDialogProps extends BasePuiDialogProps {
  clientId?: string | Nil
  patientId: string | Nil
  selectAll?: boolean
  soapBusinessId?: string | Nil
  soapId?: string
  vaccinations: Vaccinations | Nil
}

const ProofOfVaccinationDialog = ({
  open,
  soapId,
  soapBusinessId,
  clientId,
  patientId,
  vaccinations,
  selectAll,
  onClose,
}: ProofOfVaccinationDialogProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    appointment: appointmentVaccinations = [],
    other: otherVaccinations = [],
  } = vaccinations || {}
  const { t } = useTranslation(['Common', 'Dialogs'])

  const vaccinationsListRef = useRef<VaccinationsListHandle>(null)

  const [openVaccineCertificatePreviewDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_PREVIEW,
  )
  const [openSignatureDialog] = useDialog(DialogNames.DOCTOR_SIGNATURE_DIALOG)

  const [signatureState, setSignatureState] = useState<VaccinationSignature>({})

  const currentBusiness = useSelector(getCurrentBusiness)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const handleGenerate = () => {
    const vaccinationIds =
      vaccinationsListRef.current?.getSelectedVaccinationsIds() || []

    if (vaccinationIds.length) {
      openVaccineCertificatePreviewDialog({
        vaccinationIds,
        signatureState,
        soapId,
        soapBusinessId,
        clientId,
        patientId,
        generateType: CertificatePreviewGenerateTypes.PROOF_OF_VACCINATIONS,
      })

      if (onClose) {
        onClose()
      }
    }
  }

  const handleSign = () => {
    openSignatureDialog({
      enableSignerSignature:
        currentBusiness?.useDefaultSignature?.proofOfVaccination,
      outputFormat: 'png',
      onSign: (signerId: string, signature: string) => {
        setSignatureState({
          signature,
          signerId,
          signedDate: moment().toISOString(),
        })
      },
    })
  }

  const handleNavigateToSoap = (vaccinationSoapId: string) => {
    if (onClose) {
      onClose()
    }

    navigate(
      addOriginalBusinessId(
        `/soap/${vaccinationSoapId}`,
        isPatientSharingEnabled ? soapBusinessId : null,
      ),
    )
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <Grid container item>
          <ButtonWithLoader className={classes.button} onClick={handleGenerate}>
            {t('Common:GENERATE_ACTION')}
          </ButtonWithLoader>
        </Grid>
      }
      aria-labelledby="proof-of-vaccination-dialog"
      classes={{ paper: classes.paper }}
      open={open}
      scroll="paper"
      title={t('Common:GENERATE_PROOF_OF_VACCINATION')}
      onClose={onClose}
    >
      <Grid container px={1} py={2}>
        <Grid item px={2}>
          <Text>{t('Dialogs:PROOF_OF_VACCINATION_DIALOG.DESCRIPTION')}</Text>
        </Grid>
        <VaccinationsList
          appointmentVaccinations={appointmentVaccinations}
          ref={vaccinationsListRef}
          selectAll={selectAll}
          vaccinations={otherVaccinations}
          onNavigateToSoap={handleNavigateToSoap}
        />
        {!currentBusiness?.autoGenerateVaccineCertificates && (
          <Grid mt={1} mx={2}>
            <SignatureView
              signatureString={signatureState.signature || ''}
              onEdit={handleSign}
            />
          </Grid>
        )}
      </Grid>
    </PuiDialog>
  )
}

export default ProofOfVaccinationDialog
