import React from 'react'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { InvoiceLineItem } from '~/types'

import { formatMoneyRange, getExtendedPrice } from '../../invoiceUtils'

const useStyles = makeStyles(
  () => ({
    extendedCell: {
      width: 120,
    },
  }),
  { name: 'ExtendedPriceCellBody' },
)

export interface ExtendedPriceCellBodyProps {
  className?: string
  isDeclined: boolean
  item: InvoiceLineItem
}

const ExtendedPriceCellBody = ({
  isDeclined,
  item,
  className,
}: ExtendedPriceCellBodyProps) => {
  const classes = useStyles()

  const extendedPrice = getExtendedPrice(item)

  return (
    <TableCell
      align="right"
      className={classNames(className, classes.extendedCell)}
    >
      {!isDeclined && formatMoneyRange(extendedPrice)}
    </TableCell>
  )
}

export default ExtendedPriceCellBody
