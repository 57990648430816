import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'
import { DataHandle } from '~/types'

import WellnessPlanCustomization, {
  WellnessPlanCustomizationHandle,
  WellnessPlanCustomizationProps,
} from './WellnessPlanCustomization'

export interface WellnessPlanChargeSetUpProps
  extends Omit<WellnessPlanCustomizationProps, 'plan'> {}

export interface WellnessPlanChargeSetUpHandle extends DataHandle {
  onBack: () => void
  onProceed: () => void
}

const WellnessPlanChargeSetUp = forwardRef(function WellnessPlanChargeSetUp(
  {
    wellnessPlanVersion,
    onValidStateChange,
    ...rest
  }: WellnessPlanChargeSetUpProps,
  ref: ForwardedRef<WellnessPlanChargeSetUpHandle>,
) {
  const { plans = [] } = wellnessPlanVersion
  const nonBasePlans = plans.filter(
    (plan) => plan.level !== WellnessPlanLevels.BASE,
  )

  const [planIndex, setPlanIndex] = useState(0)

  const isLastPlan = planIndex === nonBasePlans.length - 1

  const customizationRef = useRef<WellnessPlanCustomizationHandle>(null)

  useImperativeHandle(ref, () => ({
    onProceed: () => {
      setPlanIndex(Math.min(planIndex + 1, nonBasePlans.length - 1))

      return planIndex > nonBasePlans.length - 2
    },
    onBack: () => {
      setPlanIndex(Math.max(planIndex - 1, 0))

      return planIndex <= 0
    },
    // need to be careful with those, if you don't add a wrapper the function inside will not be recreated
    // when customization is re-rendered (i.e., one of the fields change)
    validate: () => customizationRef.current?.validate() ?? true,
    get: () => customizationRef.current?.get(),
  }))

  const onLevelValidStateChange = (isValid: boolean) => {
    if (isLastPlan || !isValid) {
      onValidStateChange(isValid)
    }
  }

  return (
    <WellnessPlanCustomization
      key={planIndex}
      plan={nonBasePlans[planIndex]}
      ref={customizationRef}
      wellnessPlanVersion={wellnessPlanVersion}
      onValidStateChange={onLevelValidStateChange}
      {...rest}
    />
  )
})

export default WellnessPlanChargeSetUp
