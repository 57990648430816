import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Nil } from '@pbt/pbt-ui-components'

import { useMoveItemsToChewyCartMutation } from '~/api/graphql/generated/types'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { fetchInvoiceV3 } from '~/store/reducers/invoiceV3'
import { InvoiceV3 } from '~/types/entities/invoiceV3'

export const useMoveItemsToChewyCart = (invoice: InvoiceV3 | Nil) => {
  const dispatch = useDispatch()

  const { t } = useTranslation(['Payments'])

  const [moveItemsToChewyCart, { loading, error, reset }] =
    useMoveItemsToChewyCartMutation()

  return {
    moveToCartLoading: loading,
    moveToCartError: error,
    resetMoveToCart: reset,
    moveToCart: (onCompleted?: () => void) => {
      const { retailOrder, id: invoiceId } = invoice || {}
      if (retailOrder) {
        moveItemsToChewyCart({
          variables: { retailOrderId: retailOrder.id },
          onCompleted: () => {
            dispatch(
              addUiNotification({
                id: uuid(),
                message: t('Payments:SUCCESSFULLY_MOVED_ITEMS_TO_CHEWY_CART'),
                type: SnackNotificationType.INFO,
              }),
            )
            if (invoiceId) {
              dispatch(fetchInvoiceV3({ id: invoiceId }))
            }
            onCompleted?.()
          },
        })
      }
    },
  }
}
