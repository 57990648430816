import { gql } from '@apollo/client'

import { SPACE_MONITOR } from '../fragments/monitors'

export const UPDATE_MONITORS_ASSIGNMENTS = gql`
  ${SPACE_MONITOR}
  mutation UpdateMonitorsAssignments($input: [UpdateAssignmentsDto!]!) {
    updateMonitorsAssignments(input: $input) {
      ...SpaceMonitorFields
    }
  }
`
