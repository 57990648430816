import { Nil } from '@pbt/pbt-ui-components'
import {
  getBrowserName,
  getBrowserVersion,
  getOSName,
} from '@pbt/pbt-ui-components/src/utils/browserUtils'

export const joinLineParts = (parts: (string | Nil)[]) =>
  parts.filter(Boolean).join(' | ')

const tryParseChromeVersion = (version: string) => {
  try {
    const [major, minor, build, patch] = version.split('.')
    return {
      major: Number.parseInt(major, 10),
      minor: Number.parseInt(minor, 10),
      build: Number.parseInt(build, 10),
      patch: Number.parseInt(patch, 10),
    }
  } catch (err) {
    return undefined
  }
}

export const getIsDymoSupported = () => {
  const browserName = getBrowserName()
  const browserVersion = getBrowserVersion()
  const parsedVersion =
    browserName === 'Chrome' ? tryParseChromeVersion(browserVersion) : undefined
  const osName = getOSName()

  // Reasoning: https://github.com/dymosoftware/dymo-connect-framework/issues/51
  // At this moment Dymo is not working with latest Chrome versions starting from version 97 and only on Mac os
  return (
    browserName !== 'Chrome' ||
    osName !== 'macOS' ||
    !parsedVersion ||
    parsedVersion?.major <= 96
  )
}
