import React from 'react'
import {
  ConfirmCloseDialog,
  ConfirmCloseDialogProps,
} from '@pbt/pbt-ui-components'

export interface ExpanderConfirmCloseDialogProps
  extends Omit<ConfirmCloseDialogProps, 'onNotOk' | 'onOk'> {
  onCancel: ConfirmCloseDialogProps['onNotOk']
  onProceed: ConfirmCloseDialogProps['onOk']
  unsavedData: any
}

const ExpanderConfirmCloseDialog = ({
  unsavedData,
  onProceed,
  onCancel,
  ...rest
}: ExpanderConfirmCloseDialogProps) => (
  <ConfirmCloseDialog onNotOk={onCancel} onOk={onProceed} {...rest} />
)

export default ExpanderConfirmCloseDialog
