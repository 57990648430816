import React from 'react'
import { useSelector } from 'react-redux'

import CheckboxListFilter, {
  CheckboxListFilterProps,
} from '~/components/common/filters/CheckboxListFilter'
import FeatureToggle from '~/constants/featureToggle'
import { InventoryCategoryName } from '~/constants/inventoryCategory'
import {
  getFeatureToggle,
  getInventoryCategory,
} from '~/store/reducers/constants'

export interface InventoryTableCategoryFilterProps
  extends CheckboxListFilterProps {}

const InventoryTableCategoryFilter = (
  props: InventoryTableCategoryFilterProps,
) => {
  const InventoryCategory = useSelector(getInventoryCategory)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const allowedFilters = InventoryCategory.filter(
    (category) =>
      isFoodCatalogEnabled || category.name !== InventoryCategoryName.VET_DIET,
  )

  return <CheckboxListFilter items={allowedFilters} {...props} />
}

export default InventoryTableCategoryFilter
