import React from 'react'
import { useTranslation } from 'react-i18next'

import SearchContext from '~/constants/searchContext'

import BaseSearchPopper, { BaseSearchPopperProps } from './BaseSearchPopper'

export interface ContactSearchPopperProps
  extends Omit<
    BaseSearchPopperProps,
    'placeholder' | 'searchContext' | 'text'
  > {}

const ContactSearchPopper = (props: ContactSearchPopperProps) => {
  const { t } = useTranslation(['Common', 'Search'])

  return (
    <BaseSearchPopper
      placeholder={t('Search:CONTACTS')}
      searchContext={SearchContext.CONTACTS}
      text={t('Common:ADD_CONTACT')}
      {...props}
    />
  )
}

export default ContactSearchPopper
