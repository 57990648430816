import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import RegisterButton from '~/components/common/auth/RegisterButton'
import ResetPasswordForm from '~/components/common/auth/ResetPasswordForm'
import TermsAndCondition from '~/components/common/auth/TermsAndCondition'
import { resetPassword } from '~/store/actions/auth'
import { getAuthError, getAuthIsResettingPassword } from '~/store/reducers/auth'

interface ResetPasswordComponentProps {
  showRegisterButton?: boolean
  showTermsAndConditions?: boolean
}

const ResetPasswordComponent = ({
  showRegisterButton = false,
  showTermsAndConditions = true,
}: ResetPasswordComponentProps) => {
  const dispatch = useDispatch()
  const { hash } = useLocation()
  const { token } = useParams()
  const email = (hash || '').split('#email=')[1] || ''
  const error = useSelector(getAuthError)

  const isFetching = useSelector(getAuthIsResettingPassword)

  const onSubmit = (password: string) => {
    if (token) {
      dispatch(resetPassword(token, password, email))
    }
  }

  return (
    <>
      <ResetPasswordForm
        email={email}
        errorMessage={error?.message.slice(error.message.indexOf(':') + 1)}
        isFetching={isFetching}
        onSubmit={onSubmit}
      />
      {showRegisterButton && <RegisterButton />}
      {showTermsAndConditions && <TermsAndCondition />}
    </>
  )
}

export default ResetPasswordComponent
