import React from 'react'
import { useTranslation } from 'react-i18next'

import UploadPage, { UploadPageProps } from './UploadPage'

export interface ClinicLogoUploadProps extends UploadPageProps {}

const ClinicLogoUpload = (props: ClinicLogoUploadProps) => {
  const { t } = useTranslation('Registration')

  return (
    <UploadPage
      aspectRatio={16 / 9}
      mobileText={t('Registration:CLINIC_LOGO_UPLOAD.MOBILE_TEXT')}
      text={t('Registration:CLINIC_LOGO_UPLOAD.TEXT')}
      {...props}
    />
  )
}

export default ClinicLogoUpload
