import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getCurrentBusinessTimeZone } from '~/store/reducers/auth'
import { getLastBusiness } from '~/store/reducers/journal'

const Timezone = () => {
  const currentBusinessTimeZone = useSelector(getCurrentBusinessTimeZone)
  const lastBusiness = useSelector(getLastBusiness)

  useEffect(() => {
    if (lastBusiness?.timeZone) {
      moment.tz.setDefault(lastBusiness.timeZone)
    }
  }, [])

  useEffect(() => {
    if (currentBusinessTimeZone) {
      moment.tz.setDefault(currentBusinessTimeZone)
    }
  }, [currentBusinessTimeZone])

  return null
}

export default Timezone
