import React from 'react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { Nil, Utils } from '@pbt/pbt-ui-components'

import {
  InventoryItem,
  Shipment,
  ShipmentItem,
  UnsavedShipmentItem,
  Variation,
} from '~/types'

import { ShipmentItemHandle } from './ShipmentItem'

export type DraftShipmentItem = {
  expanded: boolean
  key: string
  shipmentItem: ShipmentItem | UnsavedShipmentItem | Nil
}

export type ShipmentItemsContext = {
  drafts: DraftShipmentItem[]
  refs: Record<string, ShipmentItemHandle>
  setDrafts: React.Dispatch<React.SetStateAction<DraftShipmentItem[]>>
}

export const collapseDraft = (draft: DraftShipmentItem) => ({
  ...draft,
  expanded: false,
})
export const createDraft = (shipmentItem: UnsavedShipmentItem | Nil) => ({
  key: uuid(),
  expanded: true,
  shipmentItem,
})

export const deleteItem =
  ({ drafts, setDrafts, refs }: ShipmentItemsContext) =>
  (key: string) => {
    const index = R.findIndex(R.propEq('key', key), drafts)
    const newState = R.remove(index, 1, drafts)
    delete refs[key]
    setDrafts(newState)
  }

// refs would be handled by ref property of ShipmentItem component
export const addItems =
  ({ drafts, setDrafts }: ShipmentItemsContext) =>
  (shipmentItems: ShipmentItem[]) => {
    const recentDrafts = shipmentItems.map(createDraft)
    const oldCollapsedDrafts = drafts.map(collapseDraft)
    setDrafts([...oldCollapsedDrafts, ...recentDrafts])
  }

export const cloneItem =
  ({ drafts, setDrafts }: ShipmentItemsContext) =>
  (key: string) => {
    const targetDraftIndex = R.findIndex(R.propEq('key', key), drafts)
    const draftToClone = drafts[targetDraftIndex]
    const cloneShipmentItem =
      draftToClone.shipmentItem && R.omit(['id'], draftToClone.shipmentItem)
    const cloneDraft = createDraft(cloneShipmentItem)
    const oldCollapsedDrafts = drafts.map(collapseDraft)
    const newState = R.insert(targetDraftIndex, cloneDraft, oldCollapsedDrafts)
    setDrafts(newState)
  }

export const toggleItem =
  ({ drafts, setDrafts }: ShipmentItemsContext) =>
  (key: string) => {
    const targetDraftIndex = R.findIndex(R.propEq('key', key), drafts)
    const targetDraft = drafts[targetDraftIndex]
    const updatedDraft = { ...targetDraft, expanded: !targetDraft.expanded }
    const newState = R.update(targetDraftIndex, updatedDraft, drafts)
    setDrafts(newState)
  }

export const updateItem =
  ({ drafts, setDrafts, refs }: ShipmentItemsContext) =>
  (key: string) => {
    const targetDraftIndex = R.findIndex(R.propEq('key', key), drafts)
    const targetDraft = drafts[targetDraftIndex]
    const updatedDraft = {
      ...targetDraft,
      shipmentItem: refs[key].get(),
    }

    setDrafts(R.update(targetDraftIndex, updatedDraft, drafts))
  }

export const inventoryRecordToShipmentItem = ({
  inventory,
  variation,
  statusId,
}: {
  inventory: InventoryItem | Nil
  statusId: string | Nil
  variation: Variation | Nil
}) =>
  ({
    inventoryVariationId: variation?.id,
    inventoryVariation: variation,
    name: [inventory?.name?.trim(), variation?.name?.trim()]
      .filter(Boolean)
      .join(' | '),
    perPackageAmount: variation?.perPackageAmount,
    perPackageUnitsId: variation?.perPackageUnitsId,
    packageTypeId: variation?.packageTypeId,
    inventoryCategoryId: inventory?.categoryId,
    statusId,
  }) as ShipmentItem

export const getSumByProp =
  (propName: 'costTotal' | 'tax') =>
  (sum: number, { [propName]: sumProp }: ShipmentItem) =>
    sum + (Number.parseFloat(String(sumProp)) || 0)

export const getShipmentPriceInfo = ({
  items,
  customTotalTax = 0,
  shippingAndHandling = 0,
}: Pick<Shipment, 'items' | 'customTotalTax' | 'shippingAndHandling'>) => {
  const subTotal = items.reduce(getSumByProp('costTotal'), 0)
  const taxes = customTotalTax || items.reduce(getSumByProp('tax'), 0)

  return {
    subTotal,
    taxes,
    total: Utils.round(subTotal + taxes + shippingAndHandling, 2),
  }
}
