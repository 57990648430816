import React, { ChangeEvent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Grid } from '@mui/material'
import * as R from 'ramda'
import {
  PuiTextField,
  useFields,
  ValidateOptions,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import { IdexxLabCredentials, LabProps } from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../../practices'
import VendorFormContainer from '../VendorFormContainer'
// @ts-ignore
import LabMachinesTable from './LabMachinesTable'

const IdexxLab = ({
  initiallyActive,
  business,
  credentials,
  status,
  name,
  isTesting,
  vendorId,
  onToggle,
  onTest,
}: LabProps<IdexxLabCredentials>) => {
  const { t } = useTranslation('Common')

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'active',
        label: name,
        type: 'toggle',
        initialValue: initiallyActive || false,
      },
      {
        name: 'username',
        label: t('Common:USERNAME'),
        validators: ['required'],
        initialValue: credentials.username || '',
      },
      {
        name: 'password',
        label: t('Common:PASSWORD'),
        initialValue: credentials.password || '',
      },
    ],
    false,
  )

  const { active, username, password } = fields

  usePracticeFieldsSection({
    business,
    fields,
    sectionName:
      `${PracticeDetailsPanels.LAB_INTEGRATIONS}_IDEXX` as PracticeDetailsPanels,
    parentSectionName: PracticeDetailsPanels.LAB_INTEGRATIONS,
    validate: (options?: ValidateOptions | undefined): boolean => {
      if (active.value) {
        return validate(options)
      }
      return true
    },
    reset,
    resetDependencies: [initiallyActive, business],
  })

  return (
    <>
      <PuiSwitch
        field={{
          ...active,
          set: (event: ChangeEvent<HTMLInputElement>) =>
            onToggle(event.target?.checked, () => active.set(event)),
        }}
        label={active.label}
      />
      {active?.value && (
        <>
          <Grid container direction="column" pb={3} pl={6}>
            <VendorFormContainer
              Footer={
                <LabMachinesTable
                  active={active.value}
                  businessId={business.id}
                  labName={name}
                  vendorId={vendorId}
                />
              }
              isTesting={isTesting}
              status={status}
              onTest={() =>
                onTest({
                  active: active.value,
                  password: password.value,
                  username: username.value,
                  vendorId,
                })
              }
            >
              <Grid item md={6} px={1.5} py={1}>
                <PuiTextField
                  autoComplete="off"
                  field={username}
                  inputProps={{ maxLength: 100 }}
                  label={username.label}
                />
              </Grid>
              <Grid item md={6} px={1.5} py={1}>
                <PuiTextField
                  shrink
                  autoComplete="off"
                  field={password}
                  inputProps={{ maxLength: 100 }}
                  label={password.label}
                  placeholder={
                    active.value
                      ? `<${t('Common:USE_STORED_PASSWORD').toLowerCase()}>`
                      : '∗∗∗∗∗∗∗∗∗∗∗∗∗'
                  }
                  type="password"
                />
              </Grid>
            </VendorFormContainer>
          </Grid>
          <Divider />
        </>
      )}
    </>
  )
}

export default memo(IdexxLab, (prevProps, nextProps) =>
  R.equals(prevProps, nextProps),
)
