import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Fab, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, Text } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { fetchUpcomingEvents } from '~/store/actions/scheduler'
import {
  getSchedulerIsLoading,
  getSchedulerUpcomingAppointmentsList,
} from '~/store/reducers/scheduler'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { TimetableEvent } from '~/types'
import useDialog from '~/utils/useDialog'

import useConfirmLeaveSoapForAssignmentAlert from '../../useConfirmLeaveSoapForAssignment'
import UpcomingAppointmentsContentItem from './UpcomingAppointmentsContentItem'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      width: 80,
      height: 40,
    },
    noDataContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    noDataButtonContainer: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'UpcomingAppointmentsContent' },
)

export interface UpcomingAppointmentsContentProps {
  hideAddButton?: boolean
  setHasAppointments?: (value: boolean) => void
}

const UpcomingAppointmentsContent = ({
  setHasAppointments,
  hideAddButton,
}: UpcomingAppointmentsContentProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const appointmentsList = useSelector(getSchedulerUpcomingAppointmentsList)
  const isLoading = useSelector(getSchedulerIsLoading)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)

  const [openConfirmLeaveSoapForAssignmentAlert] =
    useConfirmLeaveSoapForAssignmentAlert()

  const fetchEvents = () => {
    if (clientId && patientId) {
      dispatch(fetchUpcomingEvents(clientId, patientId))
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [clientId, patientId])

  const [openAppointmentDialog] = useDialog(DialogNames.EVENT, fetchEvents)

  const hasData = appointmentsList.length > 0

  useEffect(() => {
    if (setHasAppointments) {
      setHasAppointments(hasData)
    }
  }, [hasData])

  const handleEditAppointment = (appointment: TimetableEvent | Nil) => {
    openAppointmentDialog({
      clientId,
      patientId,
      appointment,
    })
  }

  const handleAddAppointment = () => {
    openConfirmLeaveSoapForAssignmentAlert()
  }

  return (
    <Grid container item direction="column">
      {hasData ? (
        appointmentsList.map((appointmentId) => (
          <UpcomingAppointmentsContentItem
            appointmentId={appointmentId}
            key={appointmentId}
            onEdit={handleEditAppointment}
          />
        ))
      ) : (
        <Grid
          container
          item
          alignItems="center"
          className={classes.noDataContainer}
          direction="column"
        >
          <Grid item>
            <Text>
              {isLoading
                ? `${t('Common:LOADING')}...`
                : t('Common:NOTHING_IS_SCHEDULED')}
            </Text>
          </Grid>
          {!hideAddButton && (
            <Grid item className={classes.noDataButtonContainer}>
              {!isLoading && (
                <Fab
                  className={classes.button}
                  color="inherit"
                  disabled={!clientId || !patientId}
                  variant="extended"
                  onClick={handleAddAppointment}
                >
                  {t('Common:ADD_ACTION')}
                </Fab>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default UpcomingAppointmentsContent
