import { useState } from 'react'
import { FileTemplate, Nil } from '@pbt/pbt-ui-components'

import { matchImageExtension } from '~/constants/extensions'

const useFileInputHelper = (
  onFileSelected: ((file: FileTemplate) => void) | Nil,
  cropperEnable?: (src?: string) => void,
) => {
  const [imageSource, setImageSource] = useState<string | undefined>(undefined)
  const [isError, setIsError] = useState(false)

  const updateSource = (src?: string) => {
    if (src !== imageSource) {
      setImageSource(src)
      if (cropperEnable) {
        cropperEnable(src)
      }
    }
  }

  const handleImageSelect = (file: FileTemplate) => {
    setIsError(false)
    const image = new Image()
    image.src = file.data || ''
    image.onload = () => {
      updateSource(file.data)
      if (onFileSelected) {
        onFileSelected(file)
      }
    }
    image.onerror = () => {
      setIsError(true)
    }
  }

  const handleDocumentSelect = (file: FileTemplate) => {
    updateSource(file.data)
    if (onFileSelected) {
      onFileSelected(file)
    }
  }

  const onSelected = (file: FileTemplate) => {
    const isImage = matchImageExtension(file.extension)
    if (isImage) {
      handleImageSelect(file)
    } else {
      handleDocumentSelect(file)
    }
  }

  return {
    onSelected,
    isError,
    setIsError,
    updateSource,
    imageSource,
    setImageSource,
  }
}

export default useFileInputHelper
