import { useSelector } from 'react-redux'
import * as R from 'ramda'

import type { RootState } from '~/store'

type LocalizedConstantArrayOfStringsProps = {
  LocalizedMap: Record<string, string>
  RawConstantMap: Record<string, string>
  selector: (state: RootState) => string[]
}

export const useGetLocalizedConstantArrayOfStrings = ({
  RawConstantMap,
  LocalizedMap,
  selector,
}: LocalizedConstantArrayOfStringsProps) => {
  const ConstantTypeArr = useSelector(selector) // this could come localized
  const ConstantTypeList = Object.values(RawConstantMap) // this must not be localized

  if (!R.is(Function, selector)) {
    // eslint-disable-next-line no-console
    console.error('Selector should be a function')
    return []
  }

  if (!R.is(Object, RawConstantMap) || !R.is(Object, LocalizedMap)) {
    // eslint-disable-next-line no-console
    console.error('ConstantMap and LocalizedMap should be objects')
    return []
  }

  return (ConstantTypeArr || []).map((name, index) => {
    const rawKey = ConstantTypeList[index]
    return {
      id: rawKey,
      name: rawKey,
      nameTranslation: LocalizedMap[rawKey] || name,
    }
  })
}
