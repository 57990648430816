import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'

const useStyles = makeStyles(
  {
    resolvedMappingsSwitch: {
      marginLeft: 'auto',
    },
  },
  { name: 'MigrationExceptionTableHeader' },
)

type MigrationExceptionTableHeaderProps = {
  header?: string
  isLoading: boolean
  onToggleWithResolved: () => void
  withResolved: boolean
}

export const MigrationExceptionTableHeader = ({
  header,
  isLoading,
  withResolved,
  onToggleWithResolved,
}: MigrationExceptionTableHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Businesses'])

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      width="inherit"
    >
      {header && <Text variant="h1">{header}</Text>}
      <PuiSwitch
        checked={withResolved}
        classes={{ root: classes.resolvedMappingsSwitch }}
        disabled={isLoading}
        label={t('Businesses:MIGRATION.INCLUDE_RESOLVED')}
        onChange={onToggleWithResolved}
      />
    </Box>
  )
}
