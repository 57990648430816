import {
  MutationLinkChewyPetsArgs,
  MutationLinkChewyUserArgs,
  QueryMatchChewyUserByEmailArgs,
  QueryMatchChewyUserByPersonIdArgs,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import { LINK_CHEWY_PETS, LINK_CHEWY_USER } from './graphql/mutations'
import {
  FETCH_CLIENT_CHEWY_EMAILS,
  FETCH_PETMATCH_DATA,
  MATCH_CHEWY_UESR_BY_EMAIL,
} from './graphql/queries/chewyPets'
import * as Schema from './schemas'

export const fetchClientChewyEmails = requestQuery({
  query: FETCH_CLIENT_CHEWY_EMAILS,
  variablesHandler: (_, args: QueryMatchChewyUserByPersonIdArgs) => args,
})

export const linkChewyUser = requestMutation({
  mutation: LINK_CHEWY_USER,
  variablesHandler: (businessId, args: MutationLinkChewyUserArgs) => ({
    ...args,
    businessId,
  }),
})

export const linkChewyPets = requestMutation({
  mutation: LINK_CHEWY_PETS,
  variablesHandler: (_, args: MutationLinkChewyPetsArgs) => ({
    clientId: args.clientId,
    petPatientMatchInput: args.petPatientMatches,
  }),
})

export const getPetMatchData = requestQuery({
  query: FETCH_PETMATCH_DATA,
  variablesHandler: (_, clientId) => ({
    clientId,
  }),
  schema: Schema.matchChewyPetsSchema,
})

export const matchChewyUserByEmail = requestQuery({
  query: MATCH_CHEWY_UESR_BY_EMAIL,
  variablesHandler: (businessId, args: QueryMatchChewyUserByEmailArgs) => ({
    ...args,
    businessId,
  }),
})
