import { useSelector } from 'react-redux'
import { Business, NamedEntity } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '~/store/reducers/auth'

const useGetBusinessSupportedAppointments = <T extends NamedEntity | string>(
  appointmentTypes: T[] = [],
  businessProp?: Business,
) => {
  const businessFromStore = useSelector(getCurrentBusiness)
  const { appointmentConfigurationByTypeId = {} } =
    businessProp || businessFromStore || {}

  return appointmentTypes.filter((type) => {
    const id = typeof type === 'string' ? type : type?.id
    const matchingConfiguration = appointmentConfigurationByTypeId[id]
    return !matchingConfiguration || matchingConfiguration.enabled
  })
}

export default useGetBusinessSupportedAppointments
