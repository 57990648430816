import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { useFields, Utils } from '@pbt/pbt-ui-components'

import EnumRadioGroup from '~/components/common/inputs/EnumRadioGroup'
import { getMeasurementTypes } from '~/store/reducers/constants'
import {
  BasePracticeDetailsSectionProps,
  PracticeTaxConfigurationFields,
} from '~/types'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../../practices'

const UnitsOfMeasurementSection = ({
  business,
}: BasePracticeDetailsSectionProps) => {
  const MeasurementTypes = useSelector(getMeasurementTypes)

  const MixedTypeId = Utils.findConstantIdByName('Mixed', MeasurementTypes)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'measurementTypeId',
        type: 'select',
        initialValue: business.measurementTypeId ?? MixedTypeId,
      },
    ],
    false,
  )

  const { measurementTypeId } = fields

  usePracticeFieldsSection<PracticeTaxConfigurationFields>({
    business,
    fields,
    sectionName: PracticeDetailsPanels.UNITS_OF_MEASUREMENT,
    validate,
    reset,
  })

  return (
    <Grid container item direction="column">
      <EnumRadioGroup
        inline
        Constant={MeasurementTypes}
        field={measurementTypeId}
      />
    </Grid>
  )
}

export default memo(UnitsOfMeasurementSection, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
