import React from 'react'
import { useSelector } from 'react-redux'
import {
  AddressUtils,
  BasePuiDialogProps,
  Nil,
  PhoneUtils,
  Utils,
} from '@pbt/pbt-ui-components'

import PrintLabelDialog from '~/components/common/dialog/PrintLabelDialog'
import {
  getGenderString,
  getSpayedNeuteredIntactWithGenderString,
} from '~/components/common/inputs/gender/genderUtils'
import { DymoLabelType } from '~/constants/dymo'
import i18n from '~/locales/i18n'
import { getBreed, getGender, getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { getFullBreedName, getPatientAge } from '~/utils'

import { joinLineParts } from './labelsUtils'

export interface PrintFolderLabelDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
}

const PrintFolderLabelDialog = ({
  open,
  onClose,
  patientId,
  clientId,
}: PrintFolderLabelDialogProps) => {
  const Breed = useSelector(getBreed)
  const Species = useSelector(getSpecies)
  const Gender = useSelector(getGender)
  const patient = useSelector(getPatient(patientId))
  const client = useSelector(getUser(clientId))

  const clientNameAndPhoneLine = joinLineParts([
    Utils.getPersonString(client),
    PhoneUtils.formatPhoneNumber(client?.mobilePhone),
  ])

  const addressLine = AddressUtils.formatAddress(client)

  const fullGenderName = patient?.gender
    ? getSpayedNeuteredIntactWithGenderString(
        patient?.gender,
        patient?.spayedNeutered,
        Gender,
      )
    : getGenderString(patient?.gender, Gender)

  const dateOfBirthString = patient?.dateOfBirth && getPatientAge(patient)
  const patientNameAgeAndGenderLine = joinLineParts([
    patient?.name,
    dateOfBirthString,
    fullGenderName,
  ])

  const speciesName = Utils.getConstantName(patient?.species, Species, '')
  const breedFullName = getFullBreedName(
    patient?.species,
    patient?.breeds,
    Breed,
  )
  const patientSpeciesAndBreedLine = joinLineParts([speciesName, breedFullName])

  const labelLines = [
    clientNameAndPhoneLine,
    addressLine,
    patientNameAgeAndGenderLine,
    patientSpeciesAndBreedLine,
  ].filter(Boolean)

  return (
    <PrintLabelDialog
      labelLines={labelLines}
      labelType={DymoLabelType.FOLDER}
      open={open}
      title={i18n.t('Common:FOLDER_LABEL')}
      onClose={onClose}
    />
  )
}

export default PrintFolderLabelDialog
