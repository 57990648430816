import { Defaults } from '@pbt/pbt-ui-components'

import { Variation } from '~/types'

import { getAPIPath, request } from './core'
import * as Schema from './schemas'

export const fetchOnHandCatalogItem = (
  businessId: string,
  variationId: string,
) =>
  request(
    getAPIPath(`inventory/onhand/${variationId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.onHandVariation,
  )

export const editOnHandCatalogItem = (
  businessId: string,
  variation: Variation,
) =>
  request(
    getAPIPath(`inventory/onhand/${variation.id}`),
    {
      method: 'PUT',
      params: { businessId, orderStatusId: variation.orderStatusId },
    },
    true,
    Schema.onHandVariation,
  )

export const fetchOnHandHistory = (
  businessId: string,
  variationId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
) =>
  request(
    getAPIPath(`inventory/onhand/history/${variationId}`),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.onHandHistoryList,
  )

export const searchOnHandCatalog = (
  businessId: string,
  from: number = 0,
  to: number = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  filters = {},
  query: string = '',
) =>
  request(
    getAPIPath('inventory/onhand/search'),
    {
      method: 'GET',
      params: { businessId, from, to, ...filters, query },
    },
    true,
    Schema.onHandVariationsList,
  )
