import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

const NoInventoriesScreen = () => {
  const { t } = useTranslation(['Admin', 'Common'])
  const [openNewInventoryDialog] = useDialog(DialogNames.INVENTORY)

  return (
    <NoItemsScreen
      wideButton
      action={() => openNewInventoryDialog()}
      actionMessage={t('Common:ADD_INVENTORY_ITEM')}
      title={t('Admin:CATALOG.NO_INVENTORIES_SCREEN.TITLE')}
    >
      <Text variant="body">
        {t('Admin:CATALOG.NO_INVENTORIES_SCREEN.DESCRIPTION')}
      </Text>
    </NoItemsScreen>
  )
}

export default NoInventoriesScreen
