import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  ErrorTooltip,
  MarketingStylesButton,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'

import TermsAndConditionsContent from './TermsAndConditionsContent'

const useStyles = makeStyles(
  (theme) => ({
    termsContainer: {
      overflowY: 'scroll',
      height: 350,
      border: theme.constants.tableBorder,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(4),
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('lg')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: 440,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    acceptButton: {
      width: 300,
      height: 48,
      marginTop: theme.spacing(2),
    },
    acceptButtonContainer: {
      height: 115,
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        height: 95,
      },
    },
  }),
  { name: 'TermsAndConditionsWithAccept' },
)

export interface TermsAndConditionsWithAcceptProps {
  disableAcceptButton?: boolean
  error?: string | Nil
  isLoading?: boolean
  isRegistration?: boolean
  onAccept: () => void
}

const TermsAndConditionsWithAccept = ({
  onAccept,
  disableAcceptButton,
  error,
  isRegistration,
  isLoading,
}: TermsAndConditionsWithAcceptProps) => {
  const classes = useStyles()
  const isEulaV3Enabled = useSelector(getFeatureToggle(FeatureToggle.EULA_V3))
  const TERMS_AND_CONDITIONS_TRANSLATION_PREFIX = isEulaV3Enabled
    ? 'TermsAndConditionsUSAEulaV3'
    : 'TermsAndConditionsUSAEulaV2'
  const { t } = useTranslation([
    'Common',
    TERMS_AND_CONDITIONS_TRANSLATION_PREFIX,
  ])

  const titleTranslation = isEulaV3Enabled
    ? t('TermsAndConditionsUSAEulaV3:TERMS_AND_CONDITIONS_WITH_ACCEPT.TITLE')
    : t('TermsAndConditionsUSAEulaV2:TERMS_AND_CONDITIONS_WITH_ACCEPT.TITLE')

  const [scrolled, setScrolled] = useState(false)

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement

    if (target.scrollTop > target.scrollHeight - target.clientHeight - 20) {
      setScrolled(true)
    }
  }

  return (
    <Grid container direction="column">
      <Text align="center" className={classes.title} variant="hero">
        {titleTranslation}
      </Text>
      <Grid
        item
        className={classes.termsContainer}
        data-testid="terms-and-conditions-scroll-container"
        onScroll={onScroll}
      >
        <TermsAndConditionsContent />
      </Grid>
      <Grid
        container
        item
        className={classes.acceptButtonContainer}
        justifyContent="flex-end"
      >
        <ErrorTooltip message={error} open={Boolean(error)}>
          <Grid item>
            {isRegistration ? (
              <MarketingStylesButton
                className={classes.acceptButton}
                disabled={!scrolled || disableAcceptButton}
                onClick={onAccept}
              >
                {t('Common:ACCEPT_ACTION')}
              </MarketingStylesButton>
            ) : (
              <ButtonWithLoader
                className={classes.acceptButton}
                color="legacy"
                disabled={!scrolled || disableAcceptButton}
                loading={isLoading}
                onClick={onAccept}
              >
                {t('Common:ACCEPT_ACTION')}
              </ButtonWithLoader>
            )}
          </Grid>
        </ErrorTooltip>
      </Grid>
    </Grid>
  )
}

export default TermsAndConditionsWithAccept
