import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, RadioGroup, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ErrorTooltip, Field, Text } from '@pbt/pbt-ui-components'

import {
  getIsFetchingChewyItems,
  getPrescriptionChewyItems,
} from '~/store/duck/prescriptions'

import ChewyItem from './ChewyItem'
import { ChewyItemSkeleton } from './ChewyItemSkeleton'

const useStyles = makeStyles(
  (theme) => ({
    heading: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    errorLabel: {
      color: theme.colors.alertLabelError,
    },
    infoIcon: {
      color: theme.palette.primary.main,
      margin: -4,
    },
    radioGroup: {
      display: 'inline-flex',
    },
  }),
  { name: 'ChewyItem' },
)

interface ChewyItemsProps {
  chewyItemField: Field
}

const ChewyItems = ({ chewyItemField }: ChewyItemsProps) => {
  const classes = useStyles()

  const { t } = useTranslation('Common')

  const chewyItems = useSelector(getPrescriptionChewyItems)
  const isFetchingChewyItems = useSelector(getIsFetchingChewyItems)

  const isLoading = !chewyItems || isFetchingChewyItems

  if (isLoading) {
    return (
      <Stack direction="column" spacing={0.5}>
        <ChewyItemSkeleton />
        <ChewyItemSkeleton />
        <ChewyItemSkeleton />
      </Stack>
    )
  }

  return (
    <Grid>
      <Text
        strong
        className={classes.heading}
        mb={1}
        mt={2}
        variant="subheading3"
      >
        <span className={chewyItemField.valid ? undefined : classes.errorLabel}>
          {t('Common:CHEWY_ITEM')}
        </span>
      </Text>
      <ErrorTooltip
        disablePortal
        message={chewyItemField.message}
        open={!chewyItemField.valid}
        validateTag={chewyItemField.validateTag}
      >
        <RadioGroup
          aria-label="label-section"
          className={classes.radioGroup}
          name="chewyItem"
          value={chewyItemField.value}
          onChange={(_, value) => chewyItemField.setValue(value)}
        >
          {chewyItems?.map((chewyItem) => (
            <ChewyItem item={chewyItem} key={chewyItem.name} />
          ))}
        </RadioGroup>
      </ErrorTooltip>
    </Grid>
  )
}

export default ChewyItems
