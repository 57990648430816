import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import deepEqual from 'fast-deep-equal'
import * as R from 'ramda'
import {
  Business,
  ButtonWithLoader,
  PermissionArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
} from '@pbt/pbt-ui-components'
import { ZoomSettings } from '@pbt/pbt-ui-components/src/types/entities'

import PuiSwitch from '~/components/common/PuiSwitch'
import {
  getZoomIsTestingConnection,
  getZoomSettings,
  testZoomConnection,
  updateZoomSettings,
} from '~/store/duck/conferencing'
import { getCRUDByAreaForBusiness } from '~/store/reducers/auth'
import { usePracticeFieldsSection } from '~/utils/usePracticeFieldsSection'

import { PracticeDetailsPanels } from '../practices'

interface ZoomIntegrationProps {
  business: Business
  setShowResultAfterConnectionTesting: () => void
  setShowResultAfterSettingsUpdate: () => void
}

const ZoomIntegration = ({
  setShowResultAfterSettingsUpdate,
  setShowResultAfterConnectionTesting,
  business,
}: ZoomIntegrationProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const permissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, business),
  )
  const isTestingZoomConnection = useSelector(getZoomIsTestingConnection)
  const initialZoomSettings = useSelector(getZoomSettings)

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'includeLinkInKioskCheckin',
        label: t(
          'Businesses:INTEGRATION.INCLUDE_ZOOM_LINK_AT_END_OF_KIOSK_CHECK_IN',
        ),
        type: 'toggle',
        validators: ['required'],
        initialValue: initialZoomSettings.includeLinkInKioskCheckin || false,
      },
      {
        name: 'zoomAccountId',
        label: t('Common:ACCOUNT_ID'),
        validators: ['required'],
        initialValue: initialZoomSettings.zoomAccountId,
      },
      {
        name: 'zoomClientId',
        label: t('Common:CLIENT_ID'),
        validators: ['required'],
        initialValue: initialZoomSettings.zoomClientId,
      },
      {
        name: 'zoomClientSecret',
        label: t('Common:CLIENT_SECRET'),
        validators: ['required'],
        initialValue: initialZoomSettings.zoomClientSecret,
      },
    ],
    false,
  )

  const {
    includeLinkInKioskCheckin,
    zoomAccountId,
    zoomClientId,
    zoomClientSecret,
  } = fields

  const zoomSettings: ZoomSettings = {
    businessId: business.id,
    includeLinkInKioskCheckin: includeLinkInKioskCheckin.value,
    integrationEnabled: true,
    zoomAccountId: zoomAccountId.value,
    zoomClientId: zoomClientId.value,
    zoomClientSecret: zoomClientSecret.value,
  }

  const onSave = () => {
    if (!deepEqual(initialZoomSettings, zoomSettings)) {
      setShowResultAfterSettingsUpdate()
      dispatch(updateZoomSettings(zoomSettings))
    }
  }

  usePracticeFieldsSection({
    business,
    fields,
    onSave,
    sectionName:
      `${PracticeDetailsPanels.TELEHEALTH_INTEGRATION}_ZOOM` as PracticeDetailsPanels,
    parentSectionName: PracticeDetailsPanels.TELEHEALTH_INTEGRATION,
    validate,
    reset,
  })

  const handleTestZoomIntegration = () => {
    if (validate()) {
      setShowResultAfterConnectionTesting()
      dispatch(
        testZoomConnection(
          zoomAccountId.value,
          zoomClientId.value,
          zoomClientSecret.value,
        ),
      )
    }
  }

  return (
    <Grid item pl={6}>
      <Grid container item direction="column">
        <Text strong mt={2} variant="subheading3">
          {t('Businesses:INTEGRATION.LINK_YOUR_ZOOM')}
        </Text>
        <Grid container columnSpacing={2}>
          <Grid item xs>
            <PuiTextField
              disabled={!permissions.update}
              field={zoomAccountId}
              label={zoomAccountId.label}
            />
          </Grid>
          <Grid item xs>
            <PuiTextField
              disabled={!permissions.update}
              field={zoomClientId}
              label={zoomClientId.label}
            />
          </Grid>
          <Grid item xs>
            <PuiTextField
              autoComplete="new-password"
              disabled={!permissions.update}
              field={zoomClientSecret}
              label={zoomClientSecret.label}
              type="password"
            />
          </Grid>
        </Grid>
        <Grid item mt={1}>
          <ButtonWithLoader
            disabled={
              isTestingZoomConnection ||
              !permissions.update ||
              !zoomAccountId.value ||
              !zoomClientId.value ||
              !zoomClientSecret.value
            }
            loading={isTestingZoomConnection}
            onClick={handleTestZoomIntegration}
          >
            {isTestingZoomConnection
              ? `${t('Common:TESTING')}...`
              : t('Common:TEST_CONNECTION')}
          </ButtonWithLoader>
        </Grid>
        <Text strong mt={3.5} variant="subheading3">
          {t('Businesses:INTEGRATION.ZOOM_KIOSK_CONFIGURATION')}
        </Text>
        <PuiSwitch
          disabled={!permissions.update}
          field={includeLinkInKioskCheckin}
          label={
            <TextWithTooltip
              allowWrap
              tooltipText={t(
                'Businesses:INTEGRATION.THIS_ALLOW_YOUR_CLIENTS_TO_JOIN_ZOOM_APPOINTMENTS_FROM_KIOSK',
              )}
              variant="body"
            >
              {includeLinkInKioskCheckin.label}
            </TextWithTooltip>
          }
        />
      </Grid>
    </Grid>
  )
}

export default memo(ZoomIntegration, (prevProps, nextProps) =>
  R.equals(prevProps.business, nextProps.business),
)
