import i18n from '~/locales/i18n'
import { getAcquiredType } from '~/store/reducers/constants'
import { useGetLocalizedConstantArrayOfStrings } from '~/utils/useGetLocalizedConstantArrayOfStrings'

export enum AcquiredType {
  SHELTER_RESCUE = 'Shelter / Rescue',
  BREEDER = 'Breeder',
  FRIEND_FAMILY = 'Friend / Family',
  STRAY = 'Stray',
}

export const AcquiredTypeNames: Record<AcquiredType, string> = {
  [AcquiredType.SHELTER_RESCUE]: i18n.t(
    'Constants:ACQUIRED_TYPE.SHELTER_RESCUE',
  ),
  [AcquiredType.BREEDER]: i18n.t('Constants:ACQUIRED_TYPE.BREEDER'),
  [AcquiredType.FRIEND_FAMILY]: i18n.t('Constants:ACQUIRED_TYPE.FRIEND_FAMILY'),
  [AcquiredType.STRAY]: i18n.t('Constants:ACQUIRED_TYPE.STRAY'),
}

export const useGetAcquiredTypeList = () => {
  const ConstantTypeItemsList = useGetLocalizedConstantArrayOfStrings({
    RawConstantMap: AcquiredType,
    LocalizedMap: AcquiredTypeNames,
    selector: getAcquiredType,
  })

  return { AcquiredTypeItemsList: ConstantTypeItemsList }
}
