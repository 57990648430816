import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Defaults, PrimitiveTableColumn } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import AssignedDevices from '~/components/common/lists/primitive-table/cells/AssignedDevices'
import SpaceTypeCell from '~/components/common/lists/primitive-table/cells/SpaceTypeCell'
import SpeciesCell from '~/components/common/lists/primitive-table/cells/SpeciesCell'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  fetchMoreItemsForSpacesList,
  fetchSpacesList,
} from '~/store/actions/spaces'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getMultipleSpaces,
  getSpacesIsFetchingList,
  getSpacesList,
  getSpacesTotalCount,
} from '~/store/reducers/spaces'
import { Space } from '~/types'
import { addSearch, getUrlSearchParam } from '~/utils'

import NoSpacesScreen from './NoSpacesScreen'
import SpaceDetails from './SpaceDetails'

export interface SpacesTableComponentProps {
  headerButtons: React.ReactNode
  onDetailsClose: () => void
  spaceId?: string
}

const SpacesTableComponent = ({
  headerButtons,
  spaceId,
  onDetailsClose,
}: SpacesTableComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const list = useSelector(getSpacesList)
  const spaces = useSelector(getMultipleSpaces(list))
  const totalCount = useSelector(getSpacesTotalCount)
  const isFetchingList = useSelector(getSpacesIsFetchingList)
  const { t } = useTranslation('Admin')

  const spaceMonitorManagmentEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SPACE_MONITOR_MANAGEMENT),
  )
  const search = getUrlSearchParam('query', location.search)

  const columns = [
    {
      label: i18n.t('Common:NAME'),
      highlight: true,
      prop: 'name',
      width: 4,
    },
    {
      label: i18n.t('Common:SPECIES'),
      prop: SpeciesCell,
      width: 6,
    },
    {
      label: i18n.t('Common:TYPE_ONE'),
      prop: SpaceTypeCell,
      width: 4,
    },
    spaceMonitorManagmentEnabled && {
      label: i18n.t('Common:ASSIGNED_DEVICES'),
      prop: AssignedDevices,
      width: 8,
    },
    {
      label: i18n.t('Common:CAPACITY'),
      prop: 'capacity',
      width: 2,
    },
  ]

  useEffect(() => {
    dispatch(
      fetchSpacesList(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT, search),
    )
  }, [search])

  const navigateToSpace = (id: string) => {
    navigate(addSearch(location, `/admin/general/spaces/${id}`))
  }

  const isItemLoaded = (index: number) => Boolean(spaces[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreItemsForSpacesList(startIndex, endIndex, search))
  }

  const getDisabled = (item: Space) => !item.active

  return (
    <ExpandableTable
      Expander={SpaceDetails}
      NoItemsScreen={NoSpacesScreen}
      headerButtons={headerButtons}
      isLoading={isFetchingList}
      itemId={spaceId}
      list={spaces}
      searchTerm={search}
      title={t('Admin:SPACE.SPACES')}
      onClose={onDetailsClose}
      onSelected={navigateToSpace}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns.filter(Boolean) as PrimitiveTableColumn[]}
        getDisabled={getDisabled}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        totalCount={totalCount}
      />
    </ExpandableTable>
  )
}

export default SpacesTableComponent
