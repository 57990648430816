import React from 'react'
import { useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'

export type SimplePatientCellProps = {
  patient?: string | Nil
}

const SimplePatientCell = ({ patient: patientId }: SimplePatientCellProps) => {
  const patient = useSelector(getPatient(patientId))

  return <>{patient?.name || ''}</>
}

export default SimplePatientCell
