import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Checkbox, Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'
import { CheckboxFilled as CheckboxFilledIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      padding: theme.spacing(0.5),
      cursor: 'pointer',
    },
    title: {
      overflow: 'hidden',
      paddingRight: '0 !important',
      lineHeight: '16px',
    },
    checkbox: {
      alignItems: 'flex-start',
      padding: 0,
      color: theme.colors.secondaryText,
    },
    checkboxChecked: {
      color: theme.colors.important,
    },
    selectedNode: {
      left: 0,
      top: 0,
      bottom: 0,
      position: 'absolute',
      border: '1px solid #E48736',
      borderRadius: 2,
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      pointerEvents: 'none',
    },
    selectedNodeInner: {
      width: 267,
      height: '100%',
      backgroundColor: 'transparent',
      border: '1px solid #FFFFFF',
      borderRightWidth: 18,
    },
    selectedNodeInnerLevel0: {
      width: 331,
    },
    selectedNodeInnerLevel1: {
      width: 299,
    },
    selectedNodeInnerLevel2: {
      width: 267,
    },
    tooltip: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'CheckboxNode' },
)

export interface CheckboxNodeProps {
  checked?: boolean
  hasItems?: boolean
  level: 0 | 1 | 2
  onCheckChange: (nodeRef: React.RefObject<HTMLDivElement>) => void
  onSelectChange: (nodeRef: MutableRefObject<HTMLDivElement | null>) => void
  selected?: boolean
  setRef: (nodeRef: MutableRefObject<HTMLDivElement | null>) => void
  title: string
}

const CheckboxNode = ({
  checked,
  title,
  selected,
  level,
  hasItems,
  onSelectChange,
  onCheckChange,
  setRef,
}: CheckboxNodeProps) => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLSpanElement>(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const onTooltipOpen = () => {
    const titleCropped =
      titleRef?.current && titleRef.current.innerHTML !== title
    if (titleCropped) {
      setTooltipOpen(true)
    }
  }

  const onTooltipClose = () => {
    setTooltipOpen(false)
  }

  useEffect(() => {
    setRef(ref)
  }, [ref])

  const levelClass = classes[`selectedNodeInnerLevel${level}`]

  return (
    <Grid
      className={classes.root}
      ref={ref}
      onClick={() => onSelectChange(ref)}
    >
      <Checkbox
        checked={checked}
        checkedIcon={<CheckboxFilledIcon className={classes.checkboxChecked} />}
        className={classes.checkbox}
        onChange={(event) => {
          event.stopPropagation()
          onCheckChange(ref)
        }}
      />
      <Dotdotdot clamp={5}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          className={classes.tooltip}
          open={tooltipOpen}
          title={title}
          onClose={onTooltipClose}
          onOpen={onTooltipOpen}
        >
          <Text
            className={classes.title}
            ml={1}
            pt={0.5}
            ref={titleRef}
            variant="body2"
          >
            {title}
          </Text>
        </Tooltip>
      </Dotdotdot>
      {selected && hasItems && (
        <div className={classes.selectedNode}>
          <div className={classNames(classes.selectedNodeInner, levelClass)} />
        </div>
      )}
    </Grid>
  )
}

export default CheckboxNode
