import {
  MutationGenerateSurveyLinkArgs,
  MutationResetSoapTemplateToDefaultArgs,
  MutationUpdateSoapTemplateArgs,
  QuerySoapActiveTemplateArgs,
  QueryUncoveredSoapTemplateWidgetsArgs,
  ReferenceEntityType,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  FETCH_CLIENT_SURVEY_LINK,
  RESET_SOAP_TEMPLATE_TO_DEFAULT,
  UPDATE_SOAP_TEMPLATE,
} from './graphql/mutations/soap'
import {
  FETCH_SOAP_ACTIVE_TEMPLATE,
  FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS,
} from './graphql/queries/soap'

export const fetchSoapActiveTemplate = requestQuery({
  query: FETCH_SOAP_ACTIVE_TEMPLATE,
  variablesHandler: (_, { businessId }: QuerySoapActiveTemplateArgs) => ({
    businessId,
  }),
})

export const fetchUncoveredSoapTemplateWidgets = requestQuery({
  query: FETCH_UNCOVERED_SOAP_TEMPLATE_WIDGETS,
  variablesHandler: (
    _,
    { searchFilter }: QueryUncoveredSoapTemplateWidgetsArgs,
  ) => ({
    searchFilter,
  }),
})

export const fetchClientSurveyLink = requestMutation({
  mutation: FETCH_CLIENT_SURVEY_LINK,
  variablesHandler: (
    _,
    { clientId, referenceId }: MutationGenerateSurveyLinkArgs,
  ) => ({
    clientId,
    referenceEntityType: ReferenceEntityType.Soap,
    referenceId,
  }),
})

export const resetSoapTemplateToDefault = requestMutation({
  mutation: RESET_SOAP_TEMPLATE_TO_DEFAULT,
  variablesHandler: (
    businessId,
    { id }: MutationResetSoapTemplateToDefaultArgs,
  ) => ({
    businessId,
    id,
  }),
})

export const updateSoapTemplate = requestMutation({
  mutation: UPDATE_SOAP_TEMPLATE,
  variablesHandler: (
    businessId,
    { id, input }: MutationUpdateSoapTemplateArgs,
  ) => ({
    businessId,
    id,
    input,
  }),
})
