import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Checkbox, Grid, Input, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Nil, PuiSelect, useFields } from '@pbt/pbt-ui-components'

import ThreeStepsToggle from '~/components/common/inputs/ThreeStepsToggle'
import { ExceptionColumnType } from '~/constants/migration'
import { getExceptionDraft } from '~/store/duck/migrationV2'
import { getDictionaryScope } from '~/store/reducers/constants'
import { MigrationConstant } from '~/types'
import { ProposedColumn } from '~/types/entities/migration'
import {
  MigrationException,
  MigrationExceptionDictionaryScope,
  MigrationExceptionProposedColumn,
} from '~/types/entities/migrationV2/migrationExceptions'
import useFieldsChanged from '~/utils/useFieldsChanged'

import MigrationExceptionAutocomplete from './MigrationExceptionAutocomplete'
import {
  buildScopeToggleProps,
  extractExceptionRow,
  getColumnOptions,
  getFields,
  getRhapsodyValues,
} from './utils'

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
    },
    tableCell: {
      border: theme.constants.tableBorder,
      color: theme.colors.secondaryText,
    },
    autocomplete: {
      width: '100%',
    },
  }),
  { name: 'MigrationExceptionsTableRow' },
)

interface MigrationExceptionsTableRowProps {
  columns: ProposedColumn[]
  exception: MigrationException
  onExceptionChange: (
    importExceptionId: string,
    exception: MigrationException,
  ) => void
  proposedColumns: MigrationExceptionProposedColumn[]
}

const MigrationExceptionsTableRow = ({
  columns,
  proposedColumns,
  exception: initialException,
  onExceptionChange,
}: MigrationExceptionsTableRowProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')

  const draftException = useSelector(getExceptionDraft(initialException.id))
  const DictionaryScope = useSelector(getDictionaryScope)

  const exception = R.mergeRight(initialException, draftException)
  const { fields } = useFields(getFields(proposedColumns, exception))
  const scopeToggleProps = buildScopeToggleProps(DictionaryScope)
  const row = extractExceptionRow(exception)

  const handleExceptionUpdate = () => {
    const rhapsodyValues = getRhapsodyValues(fields, proposedColumns)
    const updatedException = {
      ...exception,
      rhapsodyValue1: rhapsodyValues[0],
      rhapsodyValue2: rhapsodyValues[1],
      resolved: true,
    }

    onExceptionChange(updatedException.id, updatedException)
  }

  const handleCheckboxChange = () => {
    const updatedException = {
      ...exception,
      resolved: !exception.resolved,
    }

    onExceptionChange(updatedException.id, updatedException)
  }

  const handleScopeChanged = (dictionaryScope: string | Nil) => {
    const updatedException = {
      ...exception,
      dictionaryScope:
        dictionaryScope?.toUpperCase() as MigrationExceptionDictionaryScope,
    }
    onExceptionChange(updatedException.id, updatedException)
  }

  useFieldsChanged(handleExceptionUpdate, fields)

  const resolvedStateHint = exception?.resolved
    ? t('Businesses:MIGRATION.RESOLVED')
    : t('Businesses:MIGRATION.CLICK_TO_RESOLVE_AS_IS')

  return (
    <Grid container className={classes.tableRow}>
      {columns.map((column, columnIndex) => (
        <Grid
          container
          item
          xs
          alignItems="center"
          className={classes.tableCell}
          key={column.id}
          px={2}
        >
          {column.type === ExceptionColumnType.STRING && (
            <Dotdotdot clamp={1}>{row[columnIndex]}</Dotdotdot>
          )}
          {column.type === ExceptionColumnType.SELECT && (
            <PuiSelect
              fullWidth
              renderEmpty
              field={fields[column.id]}
              input={<Input />}
              items={getColumnOptions(fields, column) as MigrationConstant[]}
            />
          )}
          {column.type === ExceptionColumnType.AUTOCOMPLETE && (
            <MigrationExceptionAutocomplete
              className={classes.autocomplete}
              field={fields[column.id]}
              options={getColumnOptions(fields, column) as MigrationConstant[]}
            />
          )}
        </Grid>
      ))}
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.tableCell)}
        px={2}
        xs={1.5}
      >
        <ThreeStepsToggle
          {...scopeToggleProps}
          value={exception?.dictionaryScope as string}
          onStateChange={handleScopeChanged}
        />
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        className={classNames(classes.tableCell)}
        justifyContent="center"
        xs={0.5}
      >
        <Tooltip placement="top" title={resolvedStateHint}>
          <Checkbox
            checked={exception?.resolved}
            onChange={handleCheckboxChange}
          />
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export default MigrationExceptionsTableRow
