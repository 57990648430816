import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import PuiIconButton from '~/components/common/PuiIconButton'

const useStyles = makeStyles(
  (theme) => ({
    iconButton: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    iconButtonSvg: {
      color: theme.colors.lowAccentText,
    },
    managerHeadline: {
      backgroundColor: theme.colors.dialogHeaderBackground,
    },
  }),
  { name: 'BulkPageHeadline' },
)

interface BulkPageHeadlineProps {
  onClose?: () => void
  title: string
}

const BulkPageHeadline = ({ title, onClose }: BulkPageHeadlineProps) => {
  const { t } = useTranslation('Prices')
  const classes = useStyles()

  return (
    <Stack
      alignItems="center"
      className={classes.managerHeadline}
      direction="row"
      justifyContent="space-between"
      px={3}
      py={1}
    >
      <Text inline variant="h1">
        {title}: {t('Prices:BULK_EDIT_PRICING')}
      </Text>
      {onClose && (
        <PuiIconButton
          Icon={CloseIcon}
          classes={{
            iconButton: classes.iconButton,
            iconButtonSvg: classes.iconButtonSvg,
          }}
          onMouseDown={(event) => {
            event.preventDefault()
            onClose()
          }}
        />
      )}
    </Stack>
  )
}

export default BulkPageHeadline
