import React from 'react'
import { useSelector } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateUtils,
  Question,
  QuestionAnswer,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { getAnswerInputTypes } from '~/store/reducers/constants'

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
      width: '50%',
    },
  }),
  { name: 'QuestionsListWidgetLockedItem' },
)

export interface QuestionsListWidgetLockedItemProps {
  question: Question
}

const QuestionsListWidgetLockedItem = ({
  question,
}: QuestionsListWidgetLockedItemProps) => {
  const classes = useStyles()
  const AnswerInputTypes = useSelector(getAnswerInputTypes)

  const DateSelectorAnswerInputType = Utils.findConstantIdByName(
    'Date selector',
    AnswerInputTypes,
  )

  const selectedAnswer =
    question.answers?.find(({ selected }) => selected) || ({} as QuestionAnswer)

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        {question.questionText}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Text variant="body2">{selectedAnswer.text}</Text>
        {selectedAnswer.inputs?.map((input) => {
          const isDate = input.inputTypeId === DateSelectorAnswerInputType

          return (
            <Text key={input.id} variant="body2">
              {isDate ? DateUtils.formatDate(input.value) : input.value}
            </Text>
          )
        })}
      </TableCell>
    </TableRow>
  )
}

export default QuestionsListWidgetLockedItem
