import { AutoReplyMessageConfig } from '~/types'

import { getPreludeAPIPath, request } from './core'

/** @deprecated It can be removed after https://chewyinc.atlassian.net/browse/RHAP-2684,
 *  has been on prod for all clinics for at least a month */
export const loadBoopSLAMessagesDummyTemplate = (
  businessId: string,
  config: AutoReplyMessageConfig,
) =>
  request(
    getPreludeAPIPath(
      `clients-communication/v1/settings/${businessId}/boop/sla-preview`,
    ),
    { method: 'POST', data: config, params: { businessId } },
    true,
  )
