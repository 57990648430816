import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Stepper as MuiStepper, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { ClassesType } from '@pbt/pbt-ui-components'

import useWindowResize from '~/utils/useWindowResize'

import Step from './Step'
import StepperNavigationButton from './StepperNavigationButton'

const useStyles = makeStyles(
  (theme) => ({
    stepper: {
      width: '100%',
      overflow: 'hidden',
      flex: 1,
      padding: theme.spacing(1, 2.5),
      height: theme.constants.soapStepperHeight,
    },
  }),
  { name: 'Stepper' },
)

export interface StepperProps {
  activeStep: string
  classes?: ClassesType<typeof useStyles>
  components: any
  id: string
  onLeftArrowClick?: () => void
  onRightArrowClick?: () => void
  onStepChange: (step: string) => void
  paddingMultiplier?: number
  steps: string[]
}

export interface StepperHandle {
  handleResize: () => void
}

const Stepper = forwardRef<StepperHandle, StepperProps>(function Stepper(
  {
    components,
    id,
    steps,
    activeStep,
    onStepChange,
    onLeftArrowClick: onLeftArrowClickProp,
    onRightArrowClick: onRightArrowClickProp,
    paddingMultiplier = 2,
    classes: classesProp,
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })

  const [stepperWidth, setStepperWidth] = useState<number>()

  const activeStepIndex = steps.indexOf(activeStep)

  const stepperRef = useRef<HTMLDivElement>(null)

  const theme = useTheme()
  const paddings = paddingMultiplier * parseInt(theme.spacing(3), 10)

  const handleResize = () => {
    const newStepperWidth = stepperRef.current?.offsetWidth ?? 0
    if (newStepperWidth !== stepperWidth) {
      setStepperWidth(newStepperWidth)
    }
  }

  const goToFirstAvailableStep = (nextSteps: string[]) => {
    const nextStep = nextSteps.find(
      (step) =>
        !components[step].disabled && !components[step].disabledByValidation,
    )
    if (nextStep) {
      onStepChange(nextStep)
    }
  }

  const goToPrevStep = () => {
    const nextSteps = steps.slice(0, activeStepIndex)
    goToFirstAvailableStep(R.reverse(nextSteps))
  }

  const goToNextStep = () => {
    const nextSteps = steps.slice(activeStepIndex + 1)
    goToFirstAvailableStep(nextSteps)
  }

  useImperativeHandle(ref, () => ({
    handleResize,
  }))

  useWindowResize(handleResize)

  return (
    <MuiStepper
      nonLinear
      activeStep={activeStepIndex}
      className={classes.stepper}
      connector={null}
      id={id}
      ref={stepperRef}
    >
      <StepperNavigationButton
        disabled={activeStepIndex === 0}
        onClick={onLeftArrowClickProp || goToPrevStep}
      />
      {steps.map((step, index) => {
        const { label, tooltipText, disabled, disabledByValidation } =
          components[step]
        return (
          <Step
            active={Boolean(activeStep === step)}
            containerWidth={
              stepperWidth ? stepperWidth - paddings : stepperWidth
            }
            count={steps.length}
            disabled={disabled || disabledByValidation}
            first={index === 0}
            key={label}
            label={label}
            last={index === steps.length - 1}
            tooltip={tooltipText}
            onClick={() => onStepChange(step)}
          />
        )
      })}
      <StepperNavigationButton
        right
        disabled={activeStepIndex === steps.length - 1}
        onClick={onRightArrowClickProp || goToNextStep}
      />
    </MuiStepper>
  )
})

export default Stepper
