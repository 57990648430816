import { useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

const useIsCurrentContextItem = (item: { businessId?: string | Nil } | Nil) => {
  const { businessId } = item || {}
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const currentBusinessId = useSelector(getCurrentBusinessId)

  if (!isPatientSharingEnabled || !businessId) {
    return true
  }

  return businessId === currentBusinessId
}

export default useIsCurrentContextItem
