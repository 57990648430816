import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import { getProblemBodySystemONLState } from '~/store/reducers/constants'
import { getBodySystemAndProblemLogsWithProblems } from '~/store/reducers/problems'

import { ProblemsContext } from '../ProblemsContext'
import IdentifiedProblemListItem from './IdentifiedProblemListItem'

const useStyles = makeStyles(
  () => ({
    listHeaderLabel: {
      fontWeight: 500,
    },
  }),
  { name: 'IdentifiedProblemList' },
)

const IdentifiedProblemList = () => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  const { selectedProblemId } = useContext(ProblemsContext)

  const logsWithProblems = useSelector(getBodySystemAndProblemLogsWithProblems)

  // filter out all logs that are NC or WNL and have no notes
  const ONLState = useSelector(getProblemBodySystemONLState) as Record<
    string,
    any
  >
  const filteredLogsWithProblems = logsWithProblems.filter(
    (log) =>
      'problemLog' in log ||
      log.bodySystemLog.entity.stateId === ONLState?.id ||
      (!R.isEmpty(log.bodySystemLog.entity.notes) &&
        !R.isNil(log.bodySystemLog.entity.notes)),
  )

  if (filteredLogsWithProblems.length === 0) {
    return (
      <Text m={2} variant="lowAccent2">
        {t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.NO_ITEMS')}
      </Text>
    )
  }

  return (
    <>
      <Grid container mx={2} my={1} px={2} width="auto">
        <Grid item md={7.25} xs={6.5}>
          <Text className={classes.listHeaderLabel} variant="lowAccent2">
            {t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.ITEM')}
          </Text>
        </Grid>
        <Grid item xs={2}>
          <Text className={classes.listHeaderLabel} variant="lowAccent2">
            {t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.ADDED_BY')}
          </Text>
        </Grid>
        <Grid item xs={1.5}>
          <Text className={classes.listHeaderLabel} variant="lowAccent2">
            {t('Soap:PROBLEMS.IDENTIFIED_PROBLEMS.STATUS')}
          </Text>
        </Grid>
      </Grid>
      {filteredLogsWithProblems.map((logWithProblem) => {
        const { problem } = logWithProblem
        const log =
          'problemLog' in logWithProblem
            ? logWithProblem.problemLog
            : logWithProblem.bodySystemLog

        return (
          <IdentifiedProblemListItem
            isSelected={selectedProblemId === problem?.id}
            key={log.entity.id}
            log={log}
            problem={problem}
          />
        )
      })}
    </>
  )
}

export default IdentifiedProblemList
