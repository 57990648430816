import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'
import { Problem } from '~/types'

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: '440px',
    },
  }),
  { name: 'IdentifiedProblemDetailsBreadcrumbs' },
)

interface IdentifiedProblemDetailsBreadcrumbsProps {
  problem: Problem
}

const IdentifiedProblemDetailsBreadcrumbs = ({
  problem,
}: IdentifiedProblemDetailsBreadcrumbsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')

  if (!problem.type) {
    return null
  }

  const typeLabel =
    problem.type === ProblemType.DIAGNOSIS
      ? t('Soap:PROBLEMS.DIAGNOSIS')
      : t('Soap:PROBLEMS.FINDING')

  const breadcrumbsText = `${typeLabel}: ${LanguageUtils.getTranslatedFieldName(
    problem,
    'breadcrumb',
  )}`

  return (
    <Text className={classes.root} variant="lowAccent2">
      {breadcrumbsText}
    </Text>
  )
}

export default IdentifiedProblemDetailsBreadcrumbs
