import * as R from 'ramda'
import {
  ConstantMap,
  FieldObject,
  NamedEntity,
  Utils,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  MigrationConstant,
  MigrationException,
  MigrationExceptionColumnType,
  MigrationExceptionEntityToField,
  MigrationExceptionExternalColumn,
  MigrationExceptionField,
  MigrationExceptionProposedColumn,
  RhapsodyFieldUiLabels,
} from '~/types/entities/migrationV2/migrationExceptions'

const isNotNil = R.complement(R.isNil)

export const extractExceptionRow = R.pipe(
  R.props([
    'externalValue1',
    'externalValue2',
    'rhapsodyValue1',
    'rhapsodyValue2',
  ]),
  R.filter(isNotNil),
)

export const extractExceptionValues = R.pipe(
  R.props(['rhapsodyValue1', 'rhapsodyValue2']),
  R.filter(isNotNil),
)

export const buildCellId = (columnId: string, rowIndex: number) =>
  `${columnId}-${rowIndex}`

const getOptionSubTypesById = (id: string, options: MigrationConstant[]) => {
  const targetOption = R.find(R.propEq('id', id), options)

  return targetOption?.subTypes
}

export const getColumnOptions = (
  fields: FieldObject,
  column: MigrationExceptionProposedColumn,
) => {
  if (!column.groupColumn) {
    return column.options
  }

  const field = fields[column.groupColumn]

  if (column.isGroupColumnSubTypes) {
    const subTypes = getOptionSubTypesById(
      field?.value,
      column?.options as MigrationConstant[],
    )

    if (subTypes) {
      return subTypes
    }
  }
  return R.path([field?.value], column?.options)
}

// @TODO: temporary add this do do case-insensitive search
const findIdByName = (
  criteria: string | null,
  haystack: MigrationConstant[] = [],
) => {
  const needle = R.find(
    ({ name }) => name.toLowerCase() === criteria?.toLowerCase(),
    haystack,
  )
  return needle?.id
}

export const getInitialCellOptions = (
  column: MigrationExceptionProposedColumn,
  columns: MigrationExceptionProposedColumn[],
  value: string | null,
  valuesList: Record<string, string | null>,
) => {
  if (!column.groupColumn) {
    return column.options || []
  }

  const parentColumnIndex = R.findIndex(
    R.propEq('id', column.groupColumn),
    columns,
  )
  const parentOptions = columns[parentColumnIndex]
    .options as MigrationConstant[]
  const parentValueId =
    parentOptions && findIdByName(valuesList[parentColumnIndex], parentOptions)

  if (column.isGroupColumnSubTypes && parentValueId) {
    const subTypes = getOptionSubTypesById(
      parentValueId,
      column?.options as MigrationConstant[],
    )

    if (subTypes) {
      return subTypes
    }
  }

  if (parentValueId) {
    return Array.isArray(column?.options)
      ? column?.options?.[Number(parentValueId)]
      : column?.options?.[parentValueId]
  }

  return []
}

export const buildField = ({
  proposedColumns,
  column,
  columnIndex,
  valuesList,
}: {
  column: MigrationExceptionProposedColumn
  columnIndex: number
  proposedColumns: MigrationExceptionProposedColumn[]
  valuesList: Record<string, string>
}) => {
  const value = valuesList[columnIndex] || null
  const options = getInitialCellOptions(
    column,
    proposedColumns,
    value,
    valuesList,
  )
  const initialValue =
    findIdByName(value, options as MigrationConstant[]) || null

  return {
    name: column.id,
    initialValue,
  }
}

export const getFields = (
  proposedColumns: MigrationExceptionProposedColumn[] = [],
  exception: MigrationException,
) => {
  if (!exception) {
    return []
  }

  return proposedColumns.map((column, columnIndex) => {
    const valuesList = extractExceptionValues(exception)
    return buildField({ proposedColumns, column, columnIndex, valuesList })
  })
}

export const getColumnValue = R.curry((fields, column) => {
  const idValue = fields[column.id]?.value
  const caredOptions = getColumnOptions(fields, column)

  return (
    Utils.findById(idValue, caredOptions as MigrationConstant[])?.name ?? null
  )
})

export const getRhapsodyValues = (
  fields: FieldObject,
  proposedColumns: MigrationExceptionProposedColumn[],
) => {
  const getColumnValueForFields = getColumnValue(fields)
  return proposedColumns.map(getColumnValueForFields)
}

const getSubtypesByName = (constant: MigrationConstant[], name: string) =>
  R.find(R.propEq('name', name), constant)?.subTypes

export const chooseOptionsForEntityType = (
  type: string,
  Constants: ConstantMap,
  isObjectExpected?: boolean,
) => {
  const [constantName, subtypeName] = type?.split('.') || []
  const constant = (Constants[constantName as keyof typeof Constants] ||
    []) as MigrationConstant[]
  const constantOptions =
    Array.isArray(constant) || isObjectExpected ? constant : R.values(constant)

  return subtypeName
    ? getSubtypesByName(constant, subtypeName)
    : constantOptions
}

export const filterSupportedEntityOptions = (
  options: MigrationConstant[],
  typesIds: string[],
) => options.filter(({ id }) => typesIds.includes(id))

export const buildScopeToggleProps = (DictionaryScope: NamedEntity[] = []) => {
  const SessionScope = Utils.findByName('Session', DictionaryScope) || {}
  const PIMSScope = Utils.findByName('PIMS', DictionaryScope) || {}
  const GenericScope = Utils.findByName('Generic', DictionaryScope) || {}

  return {
    noneLabel: SessionScope.name?.[0],
    noneValue: SessionScope.name,
    onLabel: PIMSScope.name?.[0],
    onValue: PIMSScope.name,
    offLabel: GenericScope.name?.[0],
    offValue: GenericScope.name,
  }
}

const externalPIMSString = i18n.t(
  'Businesses:MIGRATION.EXTERNAL_EXCEPTION_VALUE',
)

export const getActualColumns = ({
  field,
  entity,
  humanReadableName,
}: MigrationExceptionEntityToField): MigrationExceptionExternalColumn[] => {
  if (field === MigrationExceptionField.BREED) {
    return [
      {
        id: 'BREED-Species-Pims',
        name: `${externalPIMSString}: ${i18n.t(
          'Businesses:MIGRATION.PATIENT_SPECIES',
        )}`,
        type: MigrationExceptionColumnType.STRING,
      },
      {
        id: 'BREED-Breed-Pims',
        name: `${externalPIMSString}: ${i18n.t(
          'Businesses:MIGRATION.PATIENT_BREED',
        )}`,
        type: MigrationExceptionColumnType.STRING,
      },
    ]
  }

  if (field === MigrationExceptionField.SUBTYPE) {
    return [
      {
        id: 'SUBTYPE-Types-Pims',
        name: `${externalPIMSString}: ${i18n.t('Businesses:MIGRATION.TYPES')}`,
        type: MigrationExceptionColumnType.STRING,
      },
      {
        id: 'SUBTYPE-Subtypes-Pims',
        name: `${externalPIMSString}: ${i18n.t(
          'Businesses:MIGRATION.SUBTYPES',
        )}`,
        type: MigrationExceptionColumnType.STRING,
      },
    ]
  }

  return [
    {
      id: `${entity}-${humanReadableName}`,
      name: `${externalPIMSString}: ${humanReadableName}`,
      type: MigrationExceptionColumnType.STRING,
    },
  ]
}

export const getProposedColumns = (
  {
    field,
    entity,
    humanReadableName,
    constantName,
    supportedTypeIds,
  }: MigrationExceptionEntityToField,
  Constants: ConstantMap,
): MigrationExceptionProposedColumn[] => {
  const columnType = `${field}_${entity}`

  if (field === MigrationExceptionField.BREED) {
    return [
      {
        id: 'BREED-Species',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.SPECIES',
        )}`,
        type: MigrationExceptionColumnType.SELECT,
        options: chooseOptionsForEntityType('Species', Constants),
      },
      {
        id: 'BREED-Breed',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.BREEDS',
        )}`,
        type: MigrationExceptionColumnType.AUTOCOMPLETE,
        options: chooseOptionsForEntityType('Breed', Constants, true),
        groupColumn: 'BREED-Species',
      },
    ]
  }

  if (field === MigrationExceptionField.SUBTYPE) {
    const options = chooseOptionsForEntityType(
      'EventType',
      Constants,
    ) as MigrationConstant[]

    return [
      {
        id: 'SUBTYPE-Types',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.TYPES',
        )}`,
        type: MigrationExceptionColumnType.SELECT,
        options: filterSupportedEntityOptions(options, supportedTypeIds || []),
      },
      {
        options,
        id: 'SUBTYPE-Subtypes',
        name: `${i18n.t('Businesses:MIGRATION.PROPOSED_VALUE')}: ${i18n.t(
          'Businesses:MIGRATION.SUBTYPES',
        )}`,
        type: MigrationExceptionColumnType.AUTOCOMPLETE,
        groupColumn: 'SUBTYPE-Types',
        isGroupColumnSubTypes: true,
      },
    ]
  }

  return [
    {
      id: `${entity}-${columnType}`,
      name: `${i18n.t(
        'Businesses:MIGRATION.PROPOSED_VALUE',
      )}: ${humanReadableName}`,
      type: MigrationExceptionColumnType.SELECT,
      options: chooseOptionsForEntityType(constantName, Constants),
    },
  ]
}

/**
 * Creates an array of unique Entity -> Field pairs that map directly to the
 * RhapsodyFieldUiLabels
 */
export const getExceptionEntityFields = R.pipe(
  R.map(R.props(['entityName', 'fieldName'])), // Create array of entityName -> fieldName tuples
  // @ts-ignore TS is not playing well with ramda
  R.uniq, // Remove any dupes
  // @ts-ignore TS is not playing well with ramda
  R.map(R.path(R.__, RhapsodyFieldUiLabels)), // Use the tuples as paths to the RhapsodyFieldUiLabels object
  R.reject(R.isNil), // Remove any that we don't have entries for
)
