import { FallbackLanguageCode } from '@pbt/pbt-ui-components/src/localization'

import i18n from '~/locales/i18n'

// Checking just decimals is enough to understand the number format
// e.g. from Spain - ES - 1.000,00
// e.g. from USA - EN - 1,000.00
export const getFormattedDecimalLocalized = () =>
  Number(1).toLocaleString(i18n.language ?? FallbackLanguageCode, {
    minimumFractionDigits: 2,
  })

export const getDotOrComma = () => {
  const formattedValue = getFormattedDecimalLocalized()
  const hasComma = /,/g.test(formattedValue)

  return {
    decimalIsComma: hasComma,
    decimalIsDot: !hasComma,
    decimalOperator: hasComma ? ',' : '.',
    thousandOperator: !hasComma ? ',' : '.',
  }
}

export const toInteger = (value: number) =>
  Math.sign(value) * Math.floor(Math.abs(value))
