import React, { useState } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AttachmentUploadIcon from '../attachment/attachment-dialog/AttachmentUploadIcon'
import MultiFileSelector from '../MultiFileSelector'
import FileInputArea from './FileInputArea'

const useStyles = makeStyles(
  (theme) => ({
    logoArea: {
      [theme.breakpoints.down('md')]: {
        height: 420,
      },
      height: 118,
      border: theme.constants.handwritingBorder,
      borderRadius: 4,
    },
    text: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'MultiFileInput' },
)

export interface MultiFileInputProps {
  onFilesSelected?: (files: File[]) => void
}

const MultiFileInput = ({ onFilesSelected }: MultiFileInputProps) => {
  const classes = useStyles()

  const [isDragOver, setIsDragOver] = useState(false)

  return (
    <Grid container>
      <Grid
        container
        item
        alignItems="center"
        className={classes.logoArea}
        direction="column"
        justifyContent="center"
      >
        <MultiFileSelector
          onDragOverChange={setIsDragOver}
          onSelected={onFilesSelected}
        >
          <Grid item>
            <FileInputArea
              Icon={AttachmentUploadIcon}
              classes={{
                text: classes.text,
              }}
              isDragOver={isDragOver}
            />
          </Grid>
        </MultiFileSelector>
      </Grid>
    </Grid>
  )
}

export default MultiFileInput
