import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { StateLabel, Text } from '@pbt/pbt-ui-components'

import { AttachmentProps } from './Attachment'
import AttachmentDropdownActions from './AttachmentDropdownActions'

const useStyles = makeStyles(
  (theme) => ({
    itemRow: {
      '&:not(:last-child)': {
        height: '42px',
        borderBottom: theme.constants.tableBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    stateLabel: {
      fontSize: '1rem',
      maxWidth: '55px',
    },
    filenameContainer: {
      alignItems: 'center',
      height: '100%',
      borderRight: theme.constants.tableBorder,
    },
    checkboxContainer: {
      minWidth: 42,
    },
  }),
  { name: 'AttachmentSoap' },
)

const AttachmentSoap = ({
  file,
  hideDownload = false,
  hideDelete = false,
  hidePreview = false,
  deleteDisabled,
  onDownloadRequested,
  onPreviewRequested,
  onDeleteRequested,
  classes: classesProp,
  selected = false,
  onSelectionToggle,
}: AttachmentProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const fileId = file.id as string

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.itemRow}
      id={fileId}
      px={onSelectionToggle ? 0 : 2}
      wrap="nowrap"
    >
      {onSelectionToggle && (
        <Grid item className={classes.checkboxContainer}>
          <Checkbox
            checked={selected}
            onChange={(event, value) => onSelectionToggle(fileId, value)}
          />
        </Grid>
      )}
      <Grid container item className={classes.filenameContainer}>
        <Text noWrap>{file.name}</Text>
      </Grid>
      <Grid item minWidth={200} px={2}>
        {file.required && (
          <StateLabel warning className={classes.stateLabel}>
            {t('Common:REQUIRED')}
          </StateLabel>
        )}
      </Grid>
      <Grid item>
        <AttachmentDropdownActions
          file={file}
          hideDelete={hideDelete || deleteDisabled}
          hideDownload={hideDownload}
          hidePreview={hidePreview}
          onDeleteRequested={() => {
            if (onDeleteRequested) {
              onDeleteRequested(file)
            }
          }}
          onDownloadRequested={() =>
            onDownloadRequested && onDownloadRequested(file)
          }
          onPreviewRequested={() =>
            onPreviewRequested && onPreviewRequested(file)
          }
        />
      </Grid>
    </Grid>
  )
}

export default AttachmentSoap
