import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useSelector } from 'react-redux'
import { Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import {
  PermissionArea,
  TextWithTooltip,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanCoverItem,
} from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import { getCRUDByArea } from '~/store/reducers/auth'

const useStyles = makeStyles(
  (theme) => ({
    coverageRow: {
      flex: 1,
      padding: theme.spacing(1),
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    coverageName: {
      wordBreak: 'break-all',
    },
    deleteIconButton: {
      padding: 0,
    },
  }),
  { name: 'WellnessPlanBenefitCover' },
)

export interface WellnessPlanBenefitCoverProps {
  benefit: WellnessPlanBenefit
  coverage: WellnessPlanCoverItem
  hasActivePatients: boolean
  updateBenefit: (benefit: WellnessPlanBenefit) => WellnessPlan
}

export const WellnessPlanBenefitCover = ({
  benefit: benefitProp,
  coverage: coverageProp,
  hasActivePatients,
  updateBenefit,
}: WellnessPlanBenefitCoverProps) => {
  const classes = useStyles()

  const permissions = useSelector(getCRUDByArea(PermissionArea.WELLNESS_PLAN))

  const removeCoverageFromBenefit = (
    coverage: WellnessPlanCoverItem,
    benefit: WellnessPlanBenefit,
  ) => {
    updateBenefit({
      ...benefit,
      cover: R.without([coverage], benefit.cover),
      deletedCoverIds: (benefit.deletedCoverIds || []).concat(coverage.id!),
    })
  }

  const coverageName = coverageProp.displayName || coverageProp.name

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classes.coverageRow}
      justifyContent="space-between"
      key={coverageProp.itemId}
      wrap="nowrap"
    >
      <TextWithTooltip
        allowWrap
        noIcon
        TooltipProps={{ tooltipPlacement: 'top-end' }}
        className={classes.coverageName}
        tooltipText={coverageName}
        variant="body2"
      >
        <Dotdotdot clamp={1}>{coverageName}</Dotdotdot>
      </TextWithTooltip>
      {permissions.update && (!coverageProp.id || !hasActivePatients) && (
        <IconButton
          aria-label="delete"
          className={classes.deleteIconButton}
          size="large"
          onClick={() => removeCoverageFromBenefit(coverageProp, benefitProp)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  )
}
