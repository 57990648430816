import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import { Prescription, PrescriptionTemplateFilters } from '~/types'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_PRESCRIPTION_TEMPLATES =
  'prescriptionTemplates/FETCH_PRESCRIPTION_TEMPLATES'
export const FETCH_PRESCRIPTION_TEMPLATES_SUCCESS =
  'prescriptionTemplates/FETCH_PRESCRIPTION_TEMPLATES_SUCCESS'
export const FETCH_PRESCRIPTION_TEMPLATES_FAILURE =
  'prescriptionTemplates/FETCH_PRESCRIPTION_TEMPLATES_FAILURE'

export const CLEAR_PRESCRIPTION_TEMPLATES =
  'prescriptionTemplates/CLEAR_PRESCRIPTION_TEMPLATES'

export const fetchPrescriptionTemplates = (
  filters: PrescriptionTemplateFilters,
) => ({
  type: FETCH_PRESCRIPTION_TEMPLATES,
  filters,
})
export const fetchPrescriptionTemplatesSuccess = (
  templates: Prescription[],
) => ({
  type: FETCH_PRESCRIPTION_TEMPLATES_SUCCESS,
  templates,
})
export const fetchPrescriptionTemplatesFailure = (error: ApiError) => ({
  type: FETCH_PRESCRIPTION_TEMPLATES_FAILURE,
  error,
})

export const clearPrescriptionTemplates = () => ({
  type: CLEAR_PRESCRIPTION_TEMPLATES,
})

export type PrescriptionTemplatesState = {
  error: string | null
  isFetching: boolean
  templates: Prescription[]
}

const INITIAL_STATE: PrescriptionTemplatesState = {
  isFetching: false,
  templates: [],
  error: null,
}

export const prescriptionTemplatesReducer = (
  state: PrescriptionTemplatesState = INITIAL_STATE,
  action: AnyAction,
): PrescriptionTemplatesState => {
  switch (action.type) {
    case FETCH_PRESCRIPTION_TEMPLATES:
      return {
        ...state,
        isFetching: true,
        templates: [],
        error: null,
      }
    case FETCH_PRESCRIPTION_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        isFetching: false,
      }
    case FETCH_PRESCRIPTION_TEMPLATES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: getErrorMessage(action.error),
      }
    case CLEAR_PRESCRIPTION_TEMPLATES:
      return {
        ...state,
        templates: [],
      }
    default:
      return state
  }
}

const getPrescriptionTemplates = (
  state: RootState,
): PrescriptionTemplatesState => state.prescriptionTemplates
export const getIsFetchingPrescriptionTemplates = (state: RootState) =>
  getPrescriptionTemplates(state).isFetching
export const getTemplates = (state: RootState) =>
  getPrescriptionTemplates(state).templates

export function* fetchPrescriptionTemplatesSaga({
  filters,
}: ReturnType<typeof fetchPrescriptionTemplates>) {
  try {
    const { data: templates } = yield* requestAPI(
      API.fetchPrescriptionTemplates,
      filters,
    )
    yield put(fetchPrescriptionTemplatesSuccess(templates))
  } catch (error) {
    yield put(fetchPrescriptionTemplatesFailure(error as ApiError))
  }
}

function* watchFetchPrescriptionTemplates() {
  yield takeLatest(FETCH_PRESCRIPTION_TEMPLATES, fetchPrescriptionTemplatesSaga)
}

export function* prescriptionTemplatesSaga() {
  yield all([watchFetchPrescriptionTemplates()])
}
