import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Radio, Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { NumberUtils, StateLabel } from '@pbt/pbt-ui-components'

import { ChewyCatalogItem } from '~/api/graphql/generated/types'

const useStyles = makeStyles(
  () => ({
    stateLabel: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      marginTop: 4,
    },
    radio: {
      paddingTop: 4,
      paddingBottom: 4,
    },
  }),
  { name: 'ChewyItem' },
)

export interface ChewyItemProps {
  item: ChewyCatalogItem
}

const ChewyItem = ({ item }: ChewyItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const outOfStock = !item.inStock

  return (
    <FormControlLabel
      control={<Radio className={classes.radio} />}
      disabled={outOfStock}
      key={`${item.name}_${item.partNumber}`}
      label={
        <Stack alignItems="flex-start" direction="row" gap={1.5}>
          <span>
            {item.name}
            {R.isNil(item.price)
              ? ''
              : ` | ${NumberUtils.formatMoney(item.price)}`}
          </span>
          {outOfStock && (
            <StateLabel warning className={classes.stateLabel} variant="small">
              {t('Common:OUT_OF_STOCK')}
            </StateLabel>
          )}
        </Stack>
      }
      value={item.partNumber}
    />
  )
}

export default ChewyItem
