import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Divider, Stack, StackProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import {
  Question,
  QuestionMode,
  QuestionView,
  QuestionViewProps,
} from '@pbt/pbt-ui-components'

import EmptyListPlaceholder from '~/components/dashboard/soap/list-with-filters/EmptyListPlaceholder'
import { getAnswerInputTypes } from '~/store/reducers/constants'
import { getQuestionsIsLoading } from '~/store/reducers/questions'

const useStyles = makeStyles(
  () => ({
    placeholder: {
      fontSize: '1.6rem',
      alignSelf: 'flex-start',
    },
  }),
  { name: 'QuestionListView' },
)

export interface QuestionListViewProps
  extends Pick<Partial<QuestionViewProps>, 'classes'>,
    Pick<QuestionViewProps, 'allAnswersExpanded' | 'renderPriorAnswers'> {
  StackProps?: StackProps
  className?: string
  editDisabled?: boolean
  mode?: QuestionMode
  onSaveQuestionLog: (log: Question[]) => void
  questionsLog: Question[]
  showNoQuestionsMessage?: boolean
  type: string
  useDivider?: boolean
}

const QuestionListView = ({
  StackProps: StackComponentProps,
  allAnswersExpanded,
  classes: classesProp,
  className: classNameProp,
  editDisabled,
  mode,
  onSaveQuestionLog,
  questionsLog: questionsLogProp,
  renderPriorAnswers,
  showNoQuestionsMessage = false,
  type,
  useDivider = false,
}: QuestionListViewProps) => {
  const classes = useStyles()
  const isLoading = useSelector(getQuestionsIsLoading)
  const AnswerInputTypes = useSelector(getAnswerInputTypes)
  const { t } = useTranslation('Common')

  const [pendingQuestionId, setPendingQuestionId] = useState<string>()
  const [questionsLog, setQuestionsLog] = useState<Question[]>(questionsLogProp)

  const debouncedSave = useDebouncedCallback(onSaveQuestionLog, 100)

  useEffect(() => {
    setQuestionsLog(questionsLogProp)
  }, [questionsLogProp])

  useEffect(() => {
    if (pendingQuestionId && !isLoading) {
      setPendingQuestionId(undefined)
    }
  }, [isLoading])

  const onQuestionAnswered = (newQuestion: Question) => {
    const oldQuestionIndex = questionsLog.findIndex(
      ({ id }) => id === newQuestion.id,
    )
    const newQuestionsLog = R.update(
      oldQuestionIndex,
      newQuestion,
      questionsLog,
    )

    setPendingQuestionId(newQuestion.id)
    setQuestionsLog(newQuestionsLog)
    debouncedSave(newQuestionsLog)
  }

  const filteredLog = questionsLog.filter(
    (question) => question.workflowIds.indexOf(type) > -1,
  )

  return filteredLog.length > 0 || !showNoQuestionsMessage ? (
    <Stack
      divider={useDivider ? <Divider flexItem /> : null}
      spacing={4}
      {...StackComponentProps}
    >
      {filteredLog.map((question) => (
        <QuestionView
          AnswerInputTypes={AnswerInputTypes}
          className={classNameProp}
          classes={classesProp}
          disabled={editDisabled}
          isLoading={question.id === pendingQuestionId}
          key={question.id}
          mode={mode}
          question={question}
          {...(R.is(Boolean, renderPriorAnswers) &&
          R.is(Boolean, allAnswersExpanded)
            ? {
                allAnswersExpanded,
                renderPriorAnswers:
                  renderPriorAnswers &&
                  (question.priorAnswers || [])?.length > 0,
              }
            : { allAnswersExpanded: undefined, renderPriorAnswers: undefined })}
          onAnswered={onQuestionAnswered}
        />
      ))}
    </Stack>
  ) : (
    <EmptyListPlaceholder
      categoryName={t('Common:QUESTION_OTHER').toLowerCase()}
      categoryType="questions"
      className={classes.placeholder}
    />
  )
}

export default QuestionListView
