import React from 'react'
import { useSelector } from 'react-redux'

import { getWellnessCoverage } from '~/store/reducers/finance'
import {
  getAppointmentId,
  getClientId,
  getPatientId,
} from '~/store/reducers/soap'

import MembershipSavingsBanner, {
  MembershipSavingsBannerProps,
} from '../../membership/MembershipSavingsBanner'

const SoapSavingsBanner = (props: MembershipSavingsBannerProps) => {
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const wellnessCoverage = useSelector(getWellnessCoverage)
  const appointmentId = useSelector(getAppointmentId)

  return (
    <MembershipSavingsBanner
      clientId={clientId}
      patientId={patientId}
      savingsDialogProps={{
        appointmentId,
      }}
      wellnessCoverage={wellnessCoverage}
      {...props}
    />
  )
}

export default SoapSavingsBanner
