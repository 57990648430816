import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  createAvailabilityRule,
  createAvailabilityRuleFailure,
  createAvailabilityRuleSuccess,
  deleteAvailabilityRule,
  deleteAvailabilityRuleFailure,
  deleteAvailabilityRuleSuccess,
  editAvailabilityRule,
  editAvailabilityRuleFailure,
  editAvailabilityRuleSuccess,
  fetchAvailabilityRule,
  fetchAvailabilityRuleError,
  fetchAvailabilityRulesList,
  fetchAvailabilityRulesList as fetchAvailabilityRulesListAction,
  fetchAvailabilityRulesListFailure,
  fetchAvailabilityRulesListSuccess,
  fetchAvailabilityRuleSuccess,
  fetchMoreItemsForAvailabilityRulesList,
  fetchMoreItemsForAvailabilityRulesListFailure,
  fetchMoreItemsForAvailabilityRulesListSuccess,
} from '../actions/availabilityRules'
import {
  CREATE_AVAILABILITY_RULE,
  DELETE_AVAILABILITY_RULE,
  EDIT_AVAILABILITY_RULE,
  FETCH_AVAILABILITY_RULE,
  FETCH_AVAILABILITY_RULES_LIST,
  FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST,
} from '../actions/types/availabilityRules'
import { finishLoading, startLoading } from '../duck/progress'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* createAvailabilityRuleSaga({
  rule,
}: ReturnType<typeof createAvailabilityRule>) {
  try {
    const { result, entities } = yield* requestAPI(
      API.createAvailabilityRule,
      rule,
    )
    yield call(updateEntities, entities)
    yield put(fetchAvailabilityRulesListAction())
    yield put(createAvailabilityRuleSuccess(result))
  } catch (error) {
    yield put(createAvailabilityRuleFailure(error as ApiError))
  }
}

export function* editAvailabilityRuleSaga({
  rule,
}: ReturnType<typeof editAvailabilityRule>) {
  try {
    const { entities } = yield* requestAPI(API.updateAvailabilityRule, rule)
    yield call(updateEntities, entities)
    yield put(fetchAvailabilityRulesListAction())
    yield put(editAvailabilityRuleSuccess())
  } catch (error) {
    yield put(editAvailabilityRuleFailure(error as ApiError))
  }
}

export function* deleteAvailabilityRuleSaga({
  rule,
}: ReturnType<typeof deleteAvailabilityRule>) {
  try {
    const data = rule.bypassValidation ? { bypassValidation: true } : undefined
    yield* requestAPI(API.deleteAvailabilityRule, rule.id, data)
    yield put(deleteAvailabilityRuleSuccess(rule.id))
    yield put(fetchAvailabilityRulesListAction())
  } catch (error) {
    yield put(deleteAvailabilityRuleFailure(error as ApiError))
  }
}

export function* fetchAvailabilityRuleSaga({
  ruleId,
}: ReturnType<typeof fetchAvailabilityRule>) {
  try {
    const { entities } = yield* requestAPI(API.fetchAvailabilityRule, ruleId)
    yield call(updateEntities, entities)
    yield put(fetchAvailabilityRuleSuccess())
  } catch (error) {
    yield put(fetchAvailabilityRuleError(error as ApiError))
  }
}

export function* fetchAvailabilityRulesListSaga({
  from,
  to,
}: ReturnType<typeof fetchAvailabilityRulesList>) {
  try {
    yield put(startLoading('availabilityRules'))
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchAvailabilityRules, from, to)
    yield call(updateEntities, entities)
    yield put(fetchAvailabilityRulesListSuccess(list, totalCount))
    yield put(finishLoading('availabilityRules'))
  } catch (error) {
    yield put(fetchAvailabilityRulesListFailure(error as ApiError))
    yield put(finishLoading('availabilityRules'))
  }
}

export function* fetchMoreItemsForAvailabilityRulesListSaga({
  from,
  to,
}: ReturnType<typeof fetchMoreItemsForAvailabilityRulesList>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield* requestAPI(API.fetchAvailabilityRules, from, to)
    yield call(updateEntities, entities)
    yield put(
      fetchMoreItemsForAvailabilityRulesListSuccess(list, totalCount, from),
    )
  } catch (error) {
    yield put(fetchMoreItemsForAvailabilityRulesListFailure(error as ApiError))
  }
}

function* watchFetchAvailabilityRule() {
  yield takeLeading(FETCH_AVAILABILITY_RULE, fetchAvailabilityRuleSaga)
}

function* watchUpdateAvailabilityRule() {
  yield takeLeading(EDIT_AVAILABILITY_RULE, editAvailabilityRuleSaga)
}

function* watchDeleteAvailabilityRule() {
  yield takeLeading(DELETE_AVAILABILITY_RULE, deleteAvailabilityRuleSaga)
}

function* watchCreateAvailabilityRule() {
  yield takeLeading(CREATE_AVAILABILITY_RULE, createAvailabilityRuleSaga)
}

function* watchFetchAvailabilityRulesList() {
  yield takeLeading(
    FETCH_AVAILABILITY_RULES_LIST,
    fetchAvailabilityRulesListSaga,
  )
}

function* watchFetchMoreItemsForAvailabilityRulesList() {
  yield takeLatest(
    FETCH_MORE_ITEMS_FOR_AVAILABILITY_RULES_LIST,
    fetchMoreItemsForAvailabilityRulesListSaga,
  )
}

export default function* availabilityRulesSaga() {
  yield all([
    watchCreateAvailabilityRule(),
    watchUpdateAvailabilityRule(),
    watchDeleteAvailabilityRule(),
    watchFetchAvailabilityRule(),
    watchFetchAvailabilityRulesList(),
    watchFetchMoreItemsForAvailabilityRulesList(),
  ])
}
