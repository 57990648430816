import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Constant, LanguageUtils, Text, Utils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import {
  InventoryCategoryDisplayOrder,
  InventoryCategoryName,
} from '~/constants/inventoryCategory'
import {
  getFeatureToggle,
  getInventoryCategory,
} from '~/store/reducers/constants'
import { findAllByName } from '~/utils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    radio: {
      padding: theme.spacing(1, 2),
      '&&&&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'CategorySelector' },
)

export interface CategorySelectorProps {
  category: string
  onCategorySet: (category: string) => void
}

const CategorySelector = ({
  category,
  onCategorySet,
  ...rest
}: CategorySelectorProps) => {
  const classes = useStyles(rest)
  const InventoryCategory: Constant[] = useSelector(getInventoryCategory)
  const { t } = useTranslation('Common')

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const orderedCategories = findAllByName(
    InventoryCategory,
    InventoryCategoryDisplayOrder,
  ).filter(
    (inventoryCategory) =>
      isFoodCatalogEnabled ||
      inventoryCategory.name !== InventoryCategoryName.VET_DIET,
  )

  return (
    <RadioGroup
      aria-label={t('Common:SELECT_A_CATEGORY')}
      className={classes.root}
      name="category"
      value={category || ''}
      onChange={Utils.handleFormSelectInput(onCategorySet)}
    >
      <Text strong variant="subheading3">
        {t('Common:SELECT_A_CATEGORY')}
      </Text>
      <Grid container columns={isFoodCatalogEnabled ? 3 : 2}>
        {orderedCategories.map((item) => (
          <Grid item key={item.id} xs={1}>
            <FormControlLabel
              control={<Radio className={classes.radio} />}
              label={LanguageUtils.getTranslatedFieldName(item)}
              value={item.id}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  )
}

export default CategorySelector
