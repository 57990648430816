import React from 'react'
import { useSelector } from 'react-redux'
import { TableBody } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { ClassesType, Field } from '@pbt/pbt-ui-components'

import { SoapTemplateWidget } from '~/api/graphql/generated/types'
import { getBusinessSoapWidgets } from '~/store/reducers/businesses'
import { SoapTemplateTab } from '~/types'

import { SoapTemplateTabDialogProps } from '../../dialog/SoapTemplateTabDialog'
import SoapTemplateTabsTableRow from './SoapTemplateTabsTableRow'
import SoapWidgetsTableCell from './SoapWidgetsTableCell'

const useStyles = makeStyles(
  () => ({
    cell: {},
    mainColumn: {},
  }),
  { name: 'SoapTemplateTabsTableBody' },
)

type Row = {
  id: string
  name: string
  widgets: React.JSX.Element[] | null
}

export interface SoapTemplateTabsTableBodyProps {
  classes: ClassesType<typeof useStyles>
  fieldTabs: Field
  isFetching: boolean
  onOpenSoapTemplateTabDialog: (
    props: Omit<SoapTemplateTabDialogProps, 'open'>,
  ) => void
}

const SoapTemplateTabsTableBody = ({
  classes: classesProp,
  fieldTabs,
  isFetching,
  onOpenSoapTemplateTabDialog,
}: SoapTemplateTabsTableBodyProps) => {
  const classes = useStyles({ classes: classesProp })

  const BusinessSoapWidgets = useSelector(getBusinessSoapWidgets)

  const handleDelete = (soapTab: SoapTemplateTab) => {
    const updatedTabs = R.pipe(
      R.reject(R.propEq('id', soapTab.id)),
      (tabs: SoapTemplateTab[]) =>
        tabs.map((tab, index) => ({ ...tab, order: index + 1 })),
    )(fieldTabs.value)

    fieldTabs.setValue(updatedTabs)
  }

  const handleEdit = (soapTab: SoapTemplateTab) => {
    onOpenSoapTemplateTabDialog({
      tab: soapTab,
      onSave: ({ templateId, name, widgets }) => {
        const tabs = fieldTabs.value
        const tabIndex = R.findIndex(R.propEq('id', templateId))(tabs)
        const newTab = {
          ...tabs[tabIndex],
          name,
          label: name,
          widgets,
        }
        const updatedTabs = R.update(tabIndex, newTab, tabs)

        fieldTabs.setValue(updatedTabs)
      },
    })
  }

  const rows: Row[] = fieldTabs.value.map(
    (tab: SoapTemplateTab, index: number) => ({
      id: `${tab.name}_${index}`,
      name: R.propOr('', 'name')(tab),
      widgets:
        tab && BusinessSoapWidgets
          ? tab.widgets.map(
              ({ active, marketplaceId, soapWidgetId }: SoapTemplateWidget) => {
                const id = `${soapWidgetId}_${marketplaceId}`

                return (
                  <SoapWidgetsTableCell
                    active={active}
                    id={id}
                    key={id}
                    marketplaceId={marketplaceId}
                  />
                )
              },
            )
          : null,
    }),
  )

  return (
    <TableBody>
      {rows.map(({ id, name, widgets }, index) => {
        const currentTab = fieldTabs.value[index]
        const isEven = index % 2 === 0

        return (
          <SoapTemplateTabsTableRow
            classes={{
              cell: classes.cell,
              mainColumn: classes.mainColumn,
            }}
            isEven={isEven}
            isFetching={isFetching}
            key={id}
            name={name}
            tab={currentTab}
            widgets={widgets}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )
      })}
    </TableBody>
  )
}

export default SoapTemplateTabsTableBody
