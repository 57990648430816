/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Close as CloseIcon } from '@mui/icons-material'
import { Grid, IconButton, Snackbar, SnackbarProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ButtonWithLoader,
  ClassesType,
  DateUtils,
  LinkButton,
  moment,
  StringWithMeridian,
  Text,
} from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

import TimezoneWarningLabel from '../alerts/TimezoneWarningLabel'

export enum AutoHideDurationType {
  FAST = 'FAST',
  SLOW = 'SLOW',
}

// duration in ms
const autoHideDurationMap = {
  [AutoHideDurationType.FAST]: 5000,
  [AutoHideDurationType.SLOW]: 60000,
}

export const useSnackNotificationStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      marginTop: theme.spacing(2),
      left: 'unset',
      right: 'unset',
      bottom: 'unset',
      top: 'unset',
      transform: 'unset',
      padding: 0,
    },
    snackRoot: {
      minWidth: 'unset',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 -2px 10px 0 rgba(0,0,0,0.2), 0 2px 10px 0 rgba(0,0,0,0.2)',
      border: `2px solid ${theme.colors.title}`,
      borderRadius: 2,
      padding: 0,
      pointerEvents: 'all',
      overflow: 'hidden',
    },
    snackMessage: {
      flex: 1,
      display: 'flex',
      padding: 0,
      overflow: 'hidden',
    },
    snackAction: {
      paddingLeft: theme.spacing(1),
      margin: 0,
    },
    content: {},
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    truncated: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    message: {},
    button: {
      marginTop: theme.spacing(1),
      minWidth: 150,
    },
    linkButton: {
      fontWeight: 500,
      alignSelf: 'flex-start',
      height: 'auto',
      minWidth: 0,
      padding: 0,
    },
    alignRight: {
      alignSelf: 'flex-end',
      marginLeft: 0,
    },
    iconContainer: {
      borderRadius: '50%',
    },
    '@keyframes progress': {
      '0%': {
        transform: 'translateX(0)',
      },
      '100%': {
        transform: 'translateX(-100%)',
      },
    },
    progressBar: {
      width: '100%',
      height: 4,
      backgroundColor: theme.colors.title,
    },
    animateFast: {
      animation: '5s linear 0.1s forwards $progress',
    },
    animateSlow: {
      animation: '60s linear 0.1s forwards $progress',
    },
    warningTooltipPopper: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.tooltip, 'above', 2),
    },
  }),
  { name: 'SnackNotification' },
)

const formatTaskNotificationTitle = (
  titleWithMeridian: StringWithMeridian | string,
) => {
  // @ts-ignore
  const { useMeridian } = moment.localeData()._custom || {}
  const formattedTime = DateUtils.convertMeridianTimeTo24(titleWithMeridian)

  if (!formattedTime || useMeridian) {
    return titleWithMeridian
  }

  const titleWithoutTime = titleWithMeridian
    .replace(DateUtils.TimeMeridianRegexAM, '')
    .replace(DateUtils.TimeMeridianRegexPM, '')
    .replace('Due', i18n.t('Common:DUE_DATE'))

  return `${titleWithoutTime}${formattedTime}`
}

export interface SnackNotificationProps extends Omit<SnackbarProps, 'style'> {
  Icon?: React.JSXElementConstructor<any>
  actionTitle?: string
  autoHideDurationType?: AutoHideDurationType
  classes?: ClassesType<typeof useSnackNotificationStyles>
  linkButton?: boolean
  message?: string
  onAction?: () => void
  onClose: () => void
  onExited?: () => void
  open: boolean
  preventClamp?: boolean
  showClose?: boolean
  showTimezoneWarning?: boolean
  title?: StringWithMeridian | string
}

const SnackNotification = ({
  title,
  message,
  Icon,
  open: openProp,
  actionTitle,
  linkButton = false,
  preventClamp = false,
  onAction,
  onClose,
  onExited,
  classes: classesProp,
  autoHideDurationType,
  showClose = true,
  showTimezoneWarning = false,
  ...rest
}: SnackNotificationProps) => {
  const classes = useSnackNotificationStyles({ classes: classesProp })

  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (openProp && !open) {
      setOpen(true)
      setVisible(true)
    } else if (!openProp && open) {
      setOpen(false)
    }
  }, [openProp])

  const handleOnExited = () => {
    setVisible(false)
    if (onExited) {
      onExited()
    }
  }

  const autoClose = () => {
    setOpen(false)
    onClose()
  }

  const autoHideDuration =
    autoHideDurationType && autoHideDurationMap[autoHideDurationType]

  useEffect(() => {
    let timer: number
    if (autoHideDuration) {
      timer = window.setTimeout(autoClose, autoHideDuration)
    }

    return () => window.clearTimeout(timer)
  }, [autoHideDuration])

  if (!visible) {
    return null
  }

  return (
    <Snackbar
      ContentProps={{
        classes: {
          root: classes.snackRoot,
          message: classes.snackMessage,
          action: classes.snackAction,
        },
      }}
      TransitionProps={{
        onExited: handleOnExited,
      }}
      action={
        showClose && (
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
      className={classes.root}
      message={
        <Grid container direction="column">
          {autoHideDurationType && (
            <Grid
              className={classNames(classes.progressBar, {
                [classes.animateFast]:
                  autoHideDurationType === AutoHideDurationType.FAST,
                [classes.animateSlow]:
                  autoHideDurationType === AutoHideDurationType.SLOW,
              })}
            />
          )}
          <Grid container alignItems="center" px={1.5} py={1}>
            {Icon && (
              <Grid
                container
                item
                alignItems="center"
                className={classes.iconContainer}
                justifyContent="center"
                mr={1}
                width="auto"
              >
                <Icon />
              </Grid>
            )}
            <Grid
              container
              item
              xs
              className={classes.content}
              direction="column"
              sx={{ minWidth: 0 }}
            >
              {title && (
                <Grid item whiteSpace="nowrap" width="inherit">
                  <Dotdotdot clamp={preventClamp ? false : 1}>
                    <Text
                      className={classes.title}
                      fontSize="1.6rem"
                      variant="h1"
                    >
                      {formatTaskNotificationTitle(title)}
                    </Text>
                  </Dotdotdot>
                </Grid>
              )}
              {showTimezoneWarning && (
                <TimezoneWarningLabel
                  TooltipProps={{
                    classes: {
                      popper: classes.warningTooltipPopper,
                    },
                  }}
                />
              )}
              {message && (
                <Grid item width="inherit">
                  <Dotdotdot clamp={preventClamp ? false : 1}>
                    <Text className={classes.message} variant="body2">
                      {message}
                    </Text>
                  </Dotdotdot>
                </Grid>
              )}
              {onAction && (
                <Grid item>
                  {linkButton ? (
                    <LinkButton
                      className={classNames(classes.linkButton, {
                        [classes.alignRight]: !message,
                      })}
                      onClick={onAction}
                    >
                      <span className={classes.truncated}>{actionTitle}</span>
                    </LinkButton>
                  ) : (
                    <ButtonWithLoader
                      className={classes.button}
                      onClick={onAction}
                    >
                      <span className={classes.truncated}>{actionTitle}</span>
                    </ButtonWithLoader>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      }
      open={open}
      {...rest}
    />
  )
}

export default SnackNotification
