import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fab, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Defaults,
  ErrorTooltip,
  PasswordAdornment,
  PuiTextField,
  Text,
  Validators,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2, 3, 6),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    placeholder: {
      height: parseInt(theme.spacing(3), 10) - 1,
    },
    submit: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      border: 'none',
      color: theme.colors.loginButtonText,
      marginTop: theme.spacing(3),
      width: '100%',
      height: '46px',
      background: theme.colors.loginButtonBackground,
    },
  }),
  { name: 'ResetPasswordForm' },
)

interface ResetPasswordFormProps {
  email?: string
  errorMessage?: string
  isFetching?: boolean
  onSubmit: (password: string) => void
}

const ResetPasswordForm = ({
  onSubmit,
  email,
  errorMessage = '',
  isFetching = false,
}: ResetPasswordFormProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Auth', 'Common', 'Validations'])

  const [touched, setTouched] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [emailValidationError, setEmailValidationError] = useState('')
  const [passwordValidationError, setPasswordValidationError] = useState('')
  const [confirmationValidationError, setConfirmationValidationError] =
    useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    setEmailValidationError('')
    setPasswordValidationError('')
    setConfirmationValidationError('')
    if (!isFetching) {
      if (!email) {
        setEmailValidationError(t('Validations:WRONG_INVITATION'))
      } else if (!password) {
        setPasswordValidationError(t('Validations:ENTER_PASSWORD'))
      } else if (!Validators.isPasswordValid(password)) {
        setPasswordValidationError(
          t('Validations:PASSWORD_MIN_LENGTH', {
            minLength: Defaults.REQUIRED_PASSWORD_LENGTH,
          }),
        )
      } else if (!confirmation) {
        setConfirmationValidationError(t('Validations:REQUIRED_FIELD'))
      } else if (
        !Validators.isPasswordConfirmationValid(password, confirmation)
      ) {
        setConfirmationValidationError(t('Validations:PASSWORD_DOES_NOT_MATCH'))
      } else {
        onSubmit(password)
      }
    }
    return false
  }

  const getMessage = () => {
    if (errorMessage && !touched) {
      return errorMessage
    }
    if (isFetching) {
      return `${t('Common:LOADING')}...`
    }
    if (confirmation && confirmation === password) {
      return t('Auth:RESET_PASSWORD_FORM.RESET_PASSWORD')
    }
    if (password && !confirmation) {
      return t('Auth:RESET_PASSWORD_FORM.CONFIRMATION')
    }
    return t('Auth:RESET_PASSWORD_FORM.NEW_PASSWORD')
  }

  return (
    <form noValidate className={classes.form} onSubmit={handleSubmit}>
      <Paper className={classes.paper}>
        <Text align="center" variant="hero2">
          {t('Common:WELCOME')}
        </Text>
        <ErrorTooltip
          message={emailValidationError}
          open={Boolean(!touched && emailValidationError)}
        >
          <PuiTextField
            disabled
            shrink
            error={Boolean(!touched && emailValidationError)}
            label={t('Common:EMAIL_ADDRESS')}
            value={email || ''}
          />
        </ErrorTooltip>
        <ErrorTooltip
          message={passwordValidationError}
          open={Boolean(
            !touched && !emailValidationError && passwordValidationError,
          )}
        >
          <PuiTextField
            autoFocus
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  condition={Validators.isPasswordValid(password)}
                  currentLength={password.length}
                />
              ),
            }}
            autoComplete="password"
            disabled={isFetching}
            error={Boolean(!touched && passwordValidationError)}
            id="password"
            label={t('Auth:RESET_PASSWORD_FORM.RESET_PASSWORD')}
            type="password"
            value={password}
            onChange={(event) => {
              setTouched(true)
              setPassword(event.target.value)
            }}
          />
        </ErrorTooltip>
        <ErrorTooltip
          message={confirmationValidationError}
          open={Boolean(
            !touched &&
              !emailValidationError &&
              !passwordValidationError &&
              confirmationValidationError,
          )}
        >
          <PuiTextField
            InputProps={{
              endAdornment: (
                <PasswordAdornment
                  condition={Validators.isPasswordConfirmationValid(
                    password,
                    confirmation,
                  )}
                />
              ),
            }}
            autoComplete="password"
            disabled={isFetching}
            error={Boolean(!touched && confirmationValidationError)}
            id="confirmation"
            label={t('Common:CONFIRM_PASSWORD')}
            type="password"
            value={confirmation}
            onChange={(event) => {
              setTouched(true)
              setConfirmation(event.target.value)
            }}
          />
        </ErrorTooltip>
      </Paper>
      <div className={classes.placeholder} />
      <Fab
        className={classes.submit}
        color="primary"
        type="submit"
        variant="extended"
      >
        {getMessage()}
      </Fab>
    </form>
  )
}

export default ResetPasswordForm
