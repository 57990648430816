import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { PuiTextField, PuiTextFieldProps } from '@pbt/pbt-ui-components'

type EmailTextFieldProps = Omit<PuiTextFieldProps, 'disabled'>

const EmailTextField = (props: EmailTextFieldProps) => {
  const { t } = useTranslation('Tooltips')

  return (
    <Tooltip title={t('Tooltips:EMAIL_TEXT_FIELD') || ''}>
      <PuiTextField disabled {...props} />
    </Tooltip>
  )
}

export default EmailTextField
