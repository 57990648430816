import { ApiError, BlobWithName, Nil, User } from '@pbt/pbt-ui-components'

import { AtLeast, Filter } from '~/types'
import { ClientData } from '~/types/entities/clients'

import {
  CLEAR_CLIENTS_ERROR,
  CLEAR_CURRENT_CLIENT_INFO,
  CLIENT_PATIENT_CREATED,
  CREATE_CLIENT,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_SUCCESS,
  FETCH_CLIENT,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENTS_LIST,
  FETCH_CLIENTS_LIST_FAILURE,
  FETCH_CLIENTS_LIST_SUCCESS,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS_FAILURE,
  FETCH_CLIENTS_MEMBERSHIP_FILTERS_SUCCESS,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_SUCCESS,
  FIND_CLIENTS,
  FIND_CLIENTS_FAILURE,
  FIND_CLIENTS_SUCCESS,
  PARTIAL_UPDATE_CLIENT,
  SEND_CLIENT_EMAIL_VERIFICATION,
  SEND_CLIENT_EMAIL_VERIFICATION_FAILURE,
  SEND_CLIENT_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_ACTIVITY_STATE,
  UPDATE_CLIENT_ACTIVITY_STATE_FAILURE,
  UPDATE_CLIENT_ACTIVITY_STATE_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  VALIDATE_CLIENT_EMAIL,
  VALIDATE_CLIENT_EMAIL_FAILURE,
  VALIDATE_CLIENT_EMAIL_SUCCESS,
} from './types/clients'

export const createClient = (client: ClientData, verify?: boolean) => ({
  type: CREATE_CLIENT,
  client,
  verify,
})
export const createClientSuccess = (clientId: string) => ({
  type: CREATE_CLIENT_SUCCESS,
  clientId,
})
export const createClientFailure = (error: ApiError) => ({
  type: CREATE_CLIENT_FAILURE,
  error,
})

export const findClients = (
  email: string | Nil,
  mobilePhone: string | Nil,
) => ({
  type: FIND_CLIENTS,
  email,
  mobilePhone,
})
export const findClientsSuccess = (duplicateClients: string[]) => ({
  type: FIND_CLIENTS_SUCCESS,
  duplicateClients,
})
export const findClientsFailure = (error: ApiError) => ({
  type: FIND_CLIENTS_FAILURE,
  error,
})

export const clientPatientCreated = (clientId: string) => ({
  type: CLIENT_PATIENT_CREATED,
  clientId,
})

export const fetchClient = ({
  clientId,
  patientId,
  withGlobalClientPreferences = false,
}: {
  clientId: string | Nil
  patientId?: string | Nil
  withGlobalClientPreferences?: boolean
}) => ({
  type: FETCH_CLIENT,
  clientId,
  patientId,
  withGlobalClientPreferences,
})
export const fetchClientSuccess = () => ({ type: FETCH_CLIENT_SUCCESS })
export const fetchClientError = (error: ApiError) => ({
  type: FETCH_CLIENT_FAILURE,
  error,
})

export const fetchClientsList = ({
  from,
  to,
  query,
  fieldsQuery,
  showLastAppointment,
  includeInactive,
  sharedOnly,
  groupSearchRequire,
}: {
  fieldsQuery?: string | Nil
  from: number
  groupSearchRequire?: boolean
  includeInactive?: boolean
  query: string | Nil
  sharedOnly?: boolean
  showLastAppointment?: boolean
  to: number
}) => ({
  type: FETCH_CLIENTS_LIST,
  from,
  to,
  query,
  fieldsQuery,
  showLastAppointment,
  includeInactive,
  sharedOnly,
  groupSearchRequire,
})
export const fetchClientsListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_CLIENTS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchClientsListFailure = (error: ApiError) => ({
  type: FETCH_CLIENTS_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForClientsList = ({
  from,
  to,
  query,
  fieldsQuery,
  showLastAppointment,
  includeInactive,
  sharedOnly,
  groupSearchRequire,
}: {
  fieldsQuery?: string | Nil
  from: number
  groupSearchRequire?: boolean
  includeInactive?: boolean
  query: string | Nil
  sharedOnly?: boolean
  showLastAppointment?: boolean
  to: number
}) => ({
  type: FETCH_MORE_ITEMS_FOR_CLIENTS_LIST,
  from,
  to,
  query,
  fieldsQuery,
  showLastAppointment,
  includeInactive,
  sharedOnly,
  groupSearchRequire,
})
export const fetchMoreItemsForClientsListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForClientsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_FAILURE,
  error,
})

export const updateClient = (
  client: AtLeast<User, 'id'>,
  avatarBlob?: BlobWithName | Nil,
) => ({
  type: UPDATE_CLIENT,
  client,
  avatarBlob,
})
export const updateClientSuccess = () => ({ type: UPDATE_CLIENT_SUCCESS })
export const updateClientFailure = (error: ApiError) => ({
  type: UPDATE_CLIENT_FAILURE,
  error,
})

export const partialUpdateClient = (
  client: AtLeast<User, 'id'>,
  avatarBlob?: BlobWithName | Nil,
) => ({
  type: PARTIAL_UPDATE_CLIENT,
  client,
  avatarBlob,
})

export const updateClientActivityState = (
  clientId: string,
  clientActivityState: boolean,
) => ({
  type: UPDATE_CLIENT_ACTIVITY_STATE,
  clientId,
  clientActivityState,
})
export const updateClientActivityStateSuccess = () => ({
  type: UPDATE_CLIENT_ACTIVITY_STATE_SUCCESS,
})
export const updateClientActivityStateFailure = (error: ApiError) => ({
  type: UPDATE_CLIENT_ACTIVITY_STATE_FAILURE,
  error,
})

export const clearCurrentClientInfo = () => ({
  type: CLEAR_CURRENT_CLIENT_INFO,
})

export const fetchClientsMembershipFilters = () => ({
  type: FETCH_CLIENTS_MEMBERSHIP_FILTERS,
})
export const fetchClientsMembershipFiltersSuccess = (filters: Filter[]) => ({
  type: FETCH_CLIENTS_MEMBERSHIP_FILTERS_SUCCESS,
  filters,
})
export const fetchClientsMembershipFiltersFailure = (error: ApiError) => ({
  type: FETCH_CLIENTS_MEMBERSHIP_FILTERS_FAILURE,
  error,
})

export const validateClientEmail = (clientId: string) => ({
  type: VALIDATE_CLIENT_EMAIL,
  clientId,
})
export const validateClientEmailSuccess = () => ({
  type: VALIDATE_CLIENT_EMAIL_SUCCESS,
})
export const validateClientEmailFailure = (error: ApiError) => ({
  type: VALIDATE_CLIENT_EMAIL_FAILURE,
  error,
})

export const sendClientEmailVerification = (clientId: string) => ({
  type: SEND_CLIENT_EMAIL_VERIFICATION,
  clientId,
})
export const sendClientEmailVerificationFailure = (error: ApiError) => ({
  type: SEND_CLIENT_EMAIL_VERIFICATION_FAILURE,
  error,
})
export const sendClientEmailVerificationSuccess = () => ({
  type: SEND_CLIENT_EMAIL_VERIFICATION_SUCCESS,
})

export const clearClientsError = () => ({ type: CLEAR_CLIENTS_ERROR })
