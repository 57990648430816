import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  CircularProgressOverlay,
  DocumentFile,
  PermissionArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import AttachmentSoap from '~/components/common/inputs/attachment/AttachmentSoap'
import ListActions from '~/components/common/lists/ListActions'
import CertificatePreviewGenerateTypes from '~/constants/CertificatePreviewGenerateTypes'
import DialogNames from '~/constants/DialogNames'
import DocumentDialogStates from '~/constants/DocumentDialogStates'
import { deleteFile } from '~/store/actions/soap'
import { getPrintIsPrinting, print } from '~/store/duck/print'
import {
  fetchRabiesVaccinations,
  fetchVaccinations,
  getRabiesVaccinations,
  getVaccinationIsLoading,
  getVaccinations,
} from '~/store/duck/vaccination'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getSoapAttachmentType } from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  getClientId,
  getFiles,
  getIsCurrentContextSoap,
  getIsFinalized,
  getPatientId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { Document, SoapFile } from '~/types'
import { getDeleteConfirmMessage } from '~/utils'
import useDialog from '~/utils/useDialog'

import SoapWidget from '../SoapWidget'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      borderTop: theme.constants.tableBorder,
    },
    addItem: {
      width: 'auto',
      padding: theme.spacing(1, 1, 1, 2),
    },
  }),
  { name: 'AttachmentsWidgetUnlocked' },
)

const AttachmentsWidgetUnlocked = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const files = useSelector(getFiles)
  const soapAttachmentType = useSelector(getSoapAttachmentType)
  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const vaccinations = useSelector(getVaccinations(patientId))
  const rabiesVaccinations = useSelector(getRabiesVaccinations(patientId))
  const selectedOrders = useSelector(getSelectedOrders)
  const vaccinationsLoading = useSelector(getVaccinationIsLoading)
  const printIsPrinting = useSelector(getPrintIsPrinting)
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )

  const editDisabled = !appointmentPermissions.update
  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const [selectedAttachments, setSelectedAttachments] = useState<string[]>([])
  const [showBulkActions, setShowBulkActions] = useState(false)
  const [attachmentsForPrint, setAttachmentsForPrint] = useState<SoapFile[]>([])

  const { appointment = [], other = [] } = vaccinations || {}

  const generalAttachmentTypeId = Utils.findConstantIdByName(
    'General',
    soapAttachmentType,
  )
  const rabiesCertificateTypeId = Utils.findConstantIdByName(
    'Rabies certificate',
    soapAttachmentType,
  )

  const rabiesCertificate = files.find(
    (file) => file?.typeId === rabiesCertificateTypeId,
  )
  const isProofOfVaccinationPossible = Boolean(
    appointment.length || other.length,
  )
  const isRabiesCertificatePossible =
    Boolean(rabiesVaccinations?.length) && !rabiesCertificate

  const [openSoapAttachmentDialog] = useDialog(
    DialogNames.SOAP_MULTI_ATTACHMENT,
  )
  const [openDocumentDialog] = useDialog(DialogNames.DOCUMENT)
  const [openProofOfVaccinationDialog] = useDialog(
    DialogNames.PROOF_OF_VACCINATION,
  )
  const [openRabiesVaccinationsDialog] = useDialog(
    DialogNames.RABIES_VACCINATIONS,
  )
  const [openEmailAttachmentDialog] = useDialog(
    DialogNames.EMAIL_ATTACHMENT_DIALOG,
  )
  const [openDeleteAttachmentAlert, closeDeleteAttachmentAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )
  const [openVaccineCertificatePreviewDialog] = useDialog(
    DialogNames.VACCINE_CERTIFICATE_PREVIEW,
  )

  useEffect(() => {
    if (soapId && patientId) {
      dispatch(fetchVaccinations(patientId, soapId))
      dispatch(fetchRabiesVaccinations(patientId, soapId))
    }
  }, [soapId, patientId, selectedOrders.length])

  const onToggleAppointment = (appointmentId: string, isSelected: boolean) => {
    const newSelectedAppointments = isSelected
      ? R.uniq([...selectedAttachments, appointmentId])
      : R.without([appointmentId], selectedAttachments)

    setShowBulkActions(Boolean(newSelectedAppointments.length))
    setSelectedAttachments(newSelectedAppointments)
  }

  const clearSelectedAttachments = () => {
    setShowBulkActions(false)
    setSelectedAttachments([])
  }

  const bulkEmail = () => {
    openEmailAttachmentDialog({
      selectedDocuments: selectedAttachments,
      soapId,
      clientId,
      patientId,
      scopedAttachments: files,
    })
  }

  const bulkPrint = () => {
    setAttachmentsForPrint(
      files.filter(({ id }) => R.includes(id, selectedAttachments)),
    )
  }

  useEffect(() => {
    if (attachmentsForPrint.length > 0 && attachmentsForPrint[0].fileUrl) {
      dispatch(print(attachmentsForPrint[0].fileUrl))
    }
  }, [attachmentsForPrint])

  useEffect(() => {
    if (!printIsPrinting && attachmentsForPrint.length > 0) {
      setAttachmentsForPrint(
        attachmentsForPrint.slice(1, attachmentsForPrint.length),
      )
    }
  }, [printIsPrinting])

  const onPreviewRequested = (file: DocumentFile['file']) => {
    if (file.typeId === generalAttachmentTypeId) {
      openDocumentDialog({
        previewOnly: true,
        document: file as Document,
        step: DocumentDialogStates.PREVIEW,
        disableCloseAfterUpdate: true,
        PreviewProps: {
          hideTopButtons: true,
          view: true,
          isSOAP: true,
          showChangeFile: false,
        },
      })
    } else {
      openVaccineCertificatePreviewDialog({
        soapId,
        soapBusinessId,
        patientId,
        document: file as SoapFile,
      })
    }
  }

  const onDeleteRequested = (file: DocumentFile['file']) => {
    openDeleteAttachmentAlert({
      message: getDeleteConfirmMessage(t('Common:ATTACHMENT').toLowerCase()),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteAttachmentAlert(),
      onOk: () => {
        if (file.id) {
          dispatch(deleteFile(file.id))
        }
        closeDeleteAttachmentAlert()
      },
    })
  }

  const onAddRequested = () => {
    openSoapAttachmentDialog({ clientId, patientId, soapId, soapBusinessId })
  }

  const handleGenerateProofOfVaccination = () => {
    openProofOfVaccinationDialog({ soapId, patientId, vaccinations })
  }

  const handleGenerateRabiesCertificate = () => {
    if (rabiesVaccinations?.length > 1) {
      openRabiesVaccinationsDialog({
        soapBusinessId,
        soapId,
        patientId,
        vaccinations: rabiesVaccinations,
        showSkipButton: false,
      })
    } else if (rabiesVaccinations?.length === 1) {
      const rabiesVaccine = R.head(rabiesVaccinations)

      if (rabiesVaccine) {
        openVaccineCertificatePreviewDialog({
          vaccinationIds: [rabiesVaccine.id],
          soapId,
          soapBusinessId,
          patientId,
          generateType: CertificatePreviewGenerateTypes.RABIES,
          showSkipButton: false,
        })
      }
    }
  }

  return (
    <SoapWidget id="attachments-widget" title={t('Common:ATTACHMENTS')}>
      <Grid container item className={classes.content} direction="column">
        <CircularProgressOverlay
          open={Boolean(attachmentsForPrint.length)}
          preloaderText={t('Soap:APPOINTMENT_ATTACHMENTS.PRINT_IN_PROGRESS')}
        />
        {files.length > 0 || !isReadOnly ? (
          files.map((file) => (
            <AttachmentSoap
              deleteDisabled={editDisabled}
              file={file}
              hideDelete={isReadOnly}
              key={file.id}
              selected={R.includes(file.id, selectedAttachments)}
              onDeleteRequested={onDeleteRequested}
              onPreviewRequested={onPreviewRequested}
              onSelectionToggle={onToggleAppointment}
            />
          ))
        ) : (
          <Text p={2}>{t('Soap:APPOINTMENT_ATTACHMENTS.NO_ATTACHMENTS')}</Text>
        )}
        {showBulkActions ? (
          <ListActions onClear={clearSelectedAttachments}>
            <Button onClick={bulkEmail}>{t('Common:EMAIL')}</Button>
            <Button onClick={bulkPrint}>{t('Common:PRINT_ACTION')}</Button>
          </ListActions>
        ) : (
          <Grid container item mt="auto">
            {!isReadOnly && (
              <AddButton
                addText={t('Common:ADD_ATTACHMENT')}
                classes={{ addItem: classes.addItem }}
                disabled={editDisabled}
                onAdd={onAddRequested}
              />
            )}
            {isRabiesCertificatePossible && (
              <AddButton
                addText={t(
                  'Soap:APPOINTMENT_ATTACHMENTS.GENERATE_RABIES_CERTIFICATE',
                )}
                classes={{ addItem: classes.addItem }}
                disabled={editDisabled || vaccinationsLoading}
                onAdd={handleGenerateRabiesCertificate}
              />
            )}
            {isProofOfVaccinationPossible && (
              <AddButton
                addText={t('Common:GENERATE_PROOF_OF_VACCINATION')}
                classes={{ addItem: classes.addItem }}
                disabled={editDisabled || vaccinationsLoading}
                onAdd={handleGenerateProofOfVaccination}
              />
            )}
          </Grid>
        )}
      </Grid>
    </SoapWidget>
  )
}

export default AttachmentsWidgetUnlocked
