import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { EditorState } from 'draft-js'
import {
  ClassesType,
  DocumentFile,
  IFrameEvent,
  Nil,
  Text,
} from '@pbt/pbt-ui-components'

import { HTML, matchImageExtension, PDF } from '~/constants/extensions'
import { getDeviceType } from '~/utils/deviceUtils'

import FilePlaceholder from '../attachments/FilePlaceholder'
import RichEdit from '../inputs/rich-edit/RichEdit'
import PdfPreview from '../pdf/PdfPreview'
import ImagePreview from './ImagePreview'

const useStyles = makeStyles(
  (theme) => ({
    previewContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    previewContainerWithShadow: {
      boxShadow: theme.constants.blockShadow,
    },
    previewContainerPadding: {
      paddingBottom: theme.spacing(3),
    },
    pdfPreviewContainer: {
      padding: theme.spacing(2),
      ...theme.constants.scrollbar,
    },
    htmlPreviewContainer: {
      width: `calc(100% - ${theme.spacing(4)})`,
      minHeight: 792,
      padding: 0,
      margin: theme.spacing(2),
    },
    imagePreviewContainer: {
      minHeight: 100,
      padding: theme.spacing(2),
    },
    richEditRoot: {
      height: 400,
    },
    richEditEditor: {
      border: 'none !important',
    },
    iframe: {
      flex: 1,
    },
    iframeFullscreen: {
      width: `calc(100vw - 100px)`,
    },
  }),
  { name: 'FilePreview' },
)

interface UseStylesProps {
  classes?: ClassesType<typeof useStyles>
}

interface FilePreviewProps extends UseStylesProps {
  editable: boolean
  editorState: EditorState | Nil
  file: DocumentFile['file']
  fullScreen?: boolean
  onEditorStateChange: (editorState: EditorState) => void
  onFileChange: (value: Blob) => void
  onIFrameLoad?: (event: IFrameEvent) => void
  setIsLoading?: (isLoading: boolean) => void
}

const FilePreview = ({
  editable,
  file,
  onFileChange,
  editorState,
  onEditorStateChange,
  classes: classesProp,
  onIFrameLoad,
  setIsLoading,
  fullScreen,
}: FilePreviewProps) => {
  const useStylesProps: UseStylesProps = {
    classes: classesProp,
  }
  const classes = useStyles(useStylesProps)
  const [iframeContentHeight, setIframeContentHeight] = useState<number>()

  const extension = (file?.extension || '').toLowerCase()
  const name: string | Nil = file?.fileName || file?.name
  const isImage = matchImageExtension(extension)
  const isPDF = extension === PDF
  const isHTML = extension === HTML
  const isUnsupportedFormat = !isImage && !isPDF && !isHTML

  const iframeRef = useRef<HTMLIFrameElement>(null)

  const updateIframeContent = () => {
    const body = iframeRef.current?.contentDocument?.body
    if (!body) {
      return
    }
    if (file && !file.fileUrl) {
      body.innerHTML = file.body || ''
    }
    setIframeContentHeight(body.scrollHeight)
  }

  const onIframeLoaded = (event: IFrameEvent) => {
    updateIframeContent()
    if (onIFrameLoad) {
      onIFrameLoad(event)
    }
  }

  useEffect(() => {
    updateIframeContent()
  }, [file?.body])

  const deviceTypes = getDeviceType()
  const isMobileOrTablet =
    deviceTypes.isMobileDevice || deviceTypes.isTabletDevice

  return (
    <Grid
      container
      item
      className={classNames(classes.previewContainer, {
        [classes.previewContainerWithShadow]:
          !isUnsupportedFormat && !isImage && !isPDF,
        [classes.previewContainerPadding]: isUnsupportedFormat || isImage,
        [classes.imagePreviewContainer]: isImage,
        [classes.pdfPreviewContainer]: isPDF,
        [classes.htmlPreviewContainer]: isHTML,
      })}
      direction="column"
      style={{ height: iframeContentHeight ?? '100%' }}
    >
      {isPDF && (
        <PdfPreview
          file={file}
          fullScreen={fullScreen}
          setIsLoading={setIsLoading}
        />
      )}
      {isImage && (
        <ImagePreview
          file={file}
          fullScreen={fullScreen}
          onFileChange={onFileChange}
        />
      )}
      {isHTML &&
        (editable ? (
          <RichEdit
            stickyControlPanel
            classes={{
              root: classes.richEditRoot,
              editor: classes.richEditEditor,
            }}
            editorState={editorState!}
            initialHTML={file?.body}
            setEditorState={onEditorStateChange}
            wrapHeaderText={false}
          />
        ) : (
          // eslint-disable-next-line react/iframe-missing-sandbox
          <iframe
            className={
              fullScreen && isMobileOrTablet
                ? classes.iframeFullscreen
                : classes.iframe
            }
            frameBorder="0"
            height="100%"
            ref={iframeRef}
            src={file.fileUrl || 'about:blank'}
            title="preview-iframe"
            onLoad={onIframeLoaded}
          />
        ))}
      {name && extension && isUnsupportedFormat && (
        <Grid container item alignItems="center">
          <FilePlaceholder />
          <Text ml={2}>{`${name}.${extension}`}</Text>
        </Grid>
      )}
    </Grid>
  )
}

export default FilePreview
