import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { LabTest, Price, TableFilter } from '~/types'

import {
  CREATE_LAB_TEST_PRICE,
  CREATE_LAB_TEST_PRICE_FAILURE,
  CREATE_LAB_TEST_PRICE_SUCCESS,
  DELETE_LAB_TEST_PRICE,
  DELETE_LAB_TEST_PRICE_FAILURE,
  DELETE_LAB_TEST_PRICE_SUCCESS,
  EDIT_LAB_TEST_PRICE,
  EDIT_LAB_TEST_PRICE_FAILURE,
  EDIT_LAB_TEST_PRICE_SUCCESS,
  EDIT_MULTIPLE_LAB_TEST_PRICES,
  FETCH_LAB_TEST,
  FETCH_LAB_TEST_FAILURE,
  FETCH_LAB_TEST_SUCCESS,
  FETCH_LAB_TESTS_LIST,
  FETCH_LAB_TESTS_LIST_FAILURE,
  FETCH_LAB_TESTS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_SUCCESS,
  RESET_LAB_TESTS,
  UPDATE_LAB_TEST_FILTERS,
  UPDATE_LAB_TESTS,
} from './types/labTests'

export const fetchLabTestsList = (
  from: number,
  to: number,
  query: string | Nil,
) => ({
  type: FETCH_LAB_TESTS_LIST,
  from,
  to,
  query,
})
export const fetchLabTestsListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_LAB_TESTS_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchLabTestsListFailure = (error: ApiError) => ({
  type: FETCH_LAB_TESTS_LIST_FAILURE,
  error,
})

export const fetchLabTest = (id: string) => ({ type: FETCH_LAB_TEST, id })
export const fetchLabTestFailure = (error: ApiError) => ({
  type: FETCH_LAB_TEST_FAILURE,
  error,
})
export const fetchLabTestSuccess = () => ({ type: FETCH_LAB_TEST_SUCCESS })

export const fetchMoreItemsForLabTestsList = (
  from: number,
  to: number,
  query: string | Nil,
) => ({
  type: FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST,
  from,
  to,
  query,
})
export const fetchMoreItemsForLabTestsListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForLabTestsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_LAB_TESTS_LIST_FAILURE,
  error,
})

export const updateLabTests = (labTests: Record<string, LabTest>) => ({
  type: UPDATE_LAB_TESTS,
  labTests,
})
export const resetLabTests = () => ({ type: RESET_LAB_TESTS })

export const createLabTestPrice = (labTestId: string, price: Price) => ({
  type: CREATE_LAB_TEST_PRICE,
  labTestId,
  price,
})
export const createLabTestPriceSuccess = () => ({
  type: CREATE_LAB_TEST_PRICE_SUCCESS,
})
export const createLabTestPriceFailure = (error: ApiError) => ({
  type: CREATE_LAB_TEST_PRICE_FAILURE,
  error,
})

export const editLabTestPrice = (labTestId: string, price: Price) => ({
  type: EDIT_LAB_TEST_PRICE,
  labTestId,
  price,
})
export const editMultipleLabTestPrices = (
  updates: { labTestId: string | Nil; price: Price }[],
) => ({
  type: EDIT_MULTIPLE_LAB_TEST_PRICES,
  updates,
})
export const editLabTestPriceSuccess = () => ({
  type: EDIT_LAB_TEST_PRICE_SUCCESS,
})
export const editLabTestPriceFailure = (error: ApiError) => ({
  type: EDIT_LAB_TEST_PRICE_FAILURE,
  error,
})

export const deleteLabTestPrice = (labTestId: string, priceId: string) => ({
  type: DELETE_LAB_TEST_PRICE,
  labTestId,
  priceId,
})
export const deleteLabTestPriceSuccess = () => ({
  type: DELETE_LAB_TEST_PRICE_SUCCESS,
})
export const deleteLabTestPriceFailure = (error: ApiError) => ({
  type: DELETE_LAB_TEST_PRICE_FAILURE,
  error,
})

export const updateLabTestFilters = (filters: Record<string, TableFilter>) => ({
  type: UPDATE_LAB_TEST_FILTERS,
  filters,
})
