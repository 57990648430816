import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import { Addendum, UnsavedAddendum } from '~/types'
import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const CREATE_ADDENDUM = 'addendums/CREATE_ADDENDUM'
export const CREATE_ADDENDUM_SUCCESS = 'addendums/CREATE_ADDENDUM_SUCCESS'
export const CREATE_ADDENDUM_FAILURE = 'addendums/CREATE_ADDENDUM_FAILURE'

export const UPDATE_ADDENDUMS = 'addendums/UPDATE_ADDENDUMS'

export const EDIT_ADDENDUM = 'addendums/EDIT_ADDENDUM'
export const EDIT_ADDENDUM_SUCCESS = 'addendums/EDIT_ADDENDUM_SUCCESS'
export const EDIT_ADDENDUM_FAILURE = 'addendums/EDIT_ADDENDUM_FAILURE'

export const DELETE_ADDENDUM = 'addendums/DELETE_ADDENDUM'
export const DELETE_ADDENDUM_SUCCESS = 'addendums/DELETE_ADDENDUM_SUCCESS'
export const DELETE_ADDENDUM_FAILURE = 'addendums/DELETE_ADDENDUM_FAILURE'

export const createAddendum = (
  soapId: string,
  patientId: string,
  addendum: UnsavedAddendum,
) => ({
  type: CREATE_ADDENDUM,
  soapId,
  patientId,
  addendum,
})
export const createAddendumSuccess = (
  soapId: string,
  patientId: string,
  addendumId: string,
) => ({
  type: CREATE_ADDENDUM_SUCCESS,
  soapId,
  patientId,
  addendumId,
})
export const createAddendumFailure = (error: ApiError) => ({
  type: CREATE_ADDENDUM_FAILURE,
  error,
})

export const updateAddendums = (addendums: Record<string, Addendum>) => ({
  type: UPDATE_ADDENDUMS,
  addendums,
})

export const editAddendum = (
  soapId: string,
  patientId: string,
  addendum: Addendum,
) => ({
  type: EDIT_ADDENDUM,
  soapId,
  patientId,
  addendum,
})
export const editAddendumSuccess = (addendumId: string) => ({
  type: EDIT_ADDENDUM_SUCCESS,
  addendumId,
})
export const editAddendumFailure = (error: ApiError) => ({
  type: EDIT_ADDENDUM_FAILURE,
  error,
})

export const deleteAddendum = (
  soapId: string,
  patientId: string,
  addendumId: string,
) => ({
  type: DELETE_ADDENDUM,
  soapId,
  patientId,
  addendumId,
})
export const deleteAddendumSuccess = (addendumId: string) => ({
  type: DELETE_ADDENDUM_SUCCESS,
  addendumId,
})
export const deleteAddendumFailure = (error: ApiError) => ({
  type: DELETE_ADDENDUM_FAILURE,
  error,
})

export type AddendumsState = {
  error: string | null
  isLoading: boolean
  map: Record<string, Addendum>
}

const INITIAL_STATE = {
  map: {},
  isLoading: false,
  error: null,
}

export const addendumsReducer = (
  state: AddendumsState = INITIAL_STATE,
  action: AnyAction,
): AddendumsState => {
  switch (action.type) {
    case CREATE_ADDENDUM:
      return { ...state, isLoading: true }
    case CREATE_ADDENDUM_SUCCESS:
      return { ...state, isLoading: false }
    case CREATE_ADDENDUM_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case UPDATE_ADDENDUMS:
      return { ...state, map: secondLevelMerge(state.map, action.addendums) }
    case EDIT_ADDENDUM:
      return { ...state, isLoading: true }
    case EDIT_ADDENDUM_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_ADDENDUM_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case DELETE_ADDENDUM:
      return { ...state, isLoading: true }
    case DELETE_ADDENDUM_SUCCESS:
      return {
        ...state,
        map: R.omit([action.addendumId], state.map),
        isLoading: false,
      }
    case DELETE_ADDENDUM_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    default:
      return state
  }
}

export const getAddendums = (state: RootState): AddendumsState =>
  state.addendums
export const getAddendumsMap = (state: RootState) => getAddendums(state).map
export const getAddendum = (id: string | Nil) =>
  createSelector(getAddendumsMap, (map) => (id ? map[id] : undefined))
export const getMultipleAddendums = (ids: string[]) =>
  createSelector(getAddendumsMap, (map) => R.props(ids, map))
export const getAddendumsIsLoading = (state: RootState) =>
  getAddendums(state).isLoading
