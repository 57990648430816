import { gql } from '@apollo/client'

export const CREATE_BUSINESS_SSO_IDP_SETTINGS = gql`
  mutation CreateSsoIdpSettings($input: SsoIdpSettingsInput!) {
    createSsoIdpSettings(ssoIdpSettings: $input) {
      id
      ssoIdpDomain
      ssoIdpAppId
      ssoDefaultRoleId
    }
  }
`

export const UPDATE_BUSINESS_SSO_IDP_SETTINGS = gql`
  mutation UpdateSsoIdpSettings($input: SsoIdpSettingsInput!) {
    updateSsoIdpSettings(ssoIdpSettings: $input) {
      id
      ssoIdpDomain
      ssoIdpAppId
      ssoDefaultRoleId
    }
  }
`

export const DELETE_BUSINESS_SSO_IDP_SETTINGS = gql`
  mutation DeleteSsoIdpSettings($id: ID!) {
    deleteSsoIdpSettings(id: $id)
  }
`
