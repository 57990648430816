import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Business,
  ButtonWithLoader,
  Defaults,
  PuiDialog,
  PuiSelect,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import { useUpdateCurrentUsersBusinessId } from '~/store/hooks/user'
import {
  getAssignedLocations,
  getCurrentBusiness,
  getCurrentUserId,
} from '~/store/reducers/auth'
import { businessSortingFunction } from '~/utils/businessUtils'
import useLogout from '~/utils/useLogout'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.contentBackground,
      padding: theme.spacing(3),
      overflowY: 'hidden',
    },
    button: {
      height: 40,
      padding: theme.spacing(0, 6),
    },
    warningIcon: {
      color: theme.colors.alertWarning,
      fontSize: '2.6rem',
    },
  }),
  { name: 'LostAccessToClinicAlertDialog' },
)

export interface LostAccessToClinicAlertDialogProps {
  onClose: () => void
  open: boolean
}

const LostAccessToClinicAlertDialog = ({
  open,
  onClose,
}: LostAccessToClinicAlertDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])

  const currentUserId = useSelector(getCurrentUserId)
  const currentBusiness = useSelector(getCurrentBusiness)
  const assignedBusinesses = useSelector(getAssignedLocations)

  const logout = useLogout()
  const updateCurrentUsersBusinessId = useUpdateCurrentUsersBusinessId()

  const fields = [
    {
      name: 'clinic',
      label: t('Common:CLINIC'),
      initialValue: null,
    },
  ]

  const { id: currentBusinessId, name: currentBusinessName } =
    currentBusiness || ({} as Business)

  const notSelectedBusinesses = assignedBusinesses
    .filter(({ id }) => id !== currentBusinessId)
    .sort(businessSortingFunction)
  const anotherBusinessExists = Boolean(notSelectedBusinesses?.length)

  const {
    fields: { clinic },
  } = useFields(fields, false)

  useEffect(() => {
    clinic.setValue(notSelectedBusinesses[0]?.id)
  }, [notSelectedBusinesses?.length])

  const applyChangedBusiness = () => {
    if (!anotherBusinessExists) {
      logout()
      onClose()
      return
    }
    const businessId = clinic.value

    if (currentUserId) {
      updateCurrentUsersBusinessId({ businessId, userId: currentUserId })
    }
    onClose()
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="lost-access-to-clinic-alert-dialog"
      maxWidth="sm"
      open={open}
      onClose={applyChangedBusiness}
    >
      <Grid
        container
        alignItems="center"
        className={classes.root}
        direction="column"
        spacing={3}
      >
        <Grid item>
          <WarningIcon className={classes.warningIcon} />
        </Grid>
        <Grid item>
          <Text align="center">
            {t('Dialogs:LOST_ACCESS_TO_CLINIC_ALERT_DIALOG.LOST_ACCESS', {
              currentBusinessName,
            })}
          </Text>
          {anotherBusinessExists ? (
            <Text align="center">
              {t('Dialogs:LOST_ACCESS_TO_CLINIC_ALERT_DIALOG.SWITCH_TO')}
            </Text>
          ) : (
            <Text align="center">
              {t('Dialogs:LOST_ACCESS_TO_CLINIC_ALERT_DIALOG.WE_ARE_SORRY')}
            </Text>
          )}
        </Grid>
        {anotherBusinessExists && (
          <Grid item>
            <PuiSelect
              field={clinic}
              input={<Input />}
              items={notSelectedBusinesses}
              renderEmpty={false}
            />
          </Grid>
        )}
        <Grid item>
          <Text>
            {t('Dialogs:LOST_ACCESS_TO_CLINIC_ALERT_DIALOG.CONTACT_US_MESSAGE')}
            &nbsp;
            <Link href={`mailto:${Defaults.SUPPORT_EMAIL}`}>
              {Defaults.SUPPORT_EMAIL}
            </Link>
          </Text>
        </Grid>
        <Grid item>
          <ButtonWithLoader
            className={classes.button}
            onClick={applyChangedBusiness}
          >
            {t('Common:GOT_IT')}!
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default LostAccessToClinicAlertDialog
