import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { getTasksIsLoading } from '~/store/reducers/tasks'
import { Task } from '~/types'

import BundleTask, { BundleTaskHandle } from './BundleTask'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflowY: 'unset',
    },
    button: {
      width: 150,
    },
  }),
  { name: 'BundleTaskDialog' },
)

interface BundleTaskDialogProps extends BasePuiDialogProps {
  onProceed: (task: Task, index?: number) => void
  task?: Task
}

const BundleTaskDialog = ({
  task,
  open,
  onClose,
  onProceed,
}: BundleTaskDialogProps) => {
  const classes = useStyles()
  const isLoading = useSelector(getTasksIsLoading)
  const { t } = useTranslation('Tasks')

  const bundleTaskRef = useRef<BundleTaskHandle>(null)

  const isCreate = !task?.id

  const proceed = () => {
    if (bundleTaskRef.current?.validate()) {
      onProceed(bundleTaskRef.current?.get())

      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: proceed,
      }}
      actions={
        <ButtonWithLoader
          className={classes.button}
          loading={isLoading}
          onClick={proceed}
        >
          {isCreate ? t('Common:ADD_ACTION') : t('Common:SAVE_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="bundle-task-dialog"
      classes={{
        paper: classes.paper,
      }}
      hasUnsavedChanges={() =>
        bundleTaskRef.current?.hasUnsavedChanges() ?? false
      }
      open={open}
      title={task ? t('Tasks:LABEL.EDIT_TASK') : t('Tasks:LABEL.NEW_TASK')}
      onClose={onClose}
    >
      <BundleTask ref={bundleTaskRef} task={task} />
    </PuiDialog>
  )
}

export default BundleTaskDialog
