import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { moment, PermissionArea, UserPermissions } from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import { PracticeDetailsPanels } from '~/components/dashboard/admin/general/practices/practices'
import { BaseRoute } from '~/constants/routes'
import {
  getCRUDByAreaForBusiness,
  getCurrentBusiness,
} from '~/store/reducers/auth'

export function useCurrentBusinessTimezone() {
  const currentBusiness = useSelector(getCurrentBusiness)

  return currentBusiness?.timeZone || moment.tz.guess()
}

const defaultGetChangeTimezoneLink = (id: string | undefined) =>
  `${BaseRoute.PRACTICES}/${id}?panels=${PracticeDetailsPanels.GENERAL_INFORMATION}`

interface UseCurrentBusinessTimezoneWarningProps {
  getChangeTimezoneLink?: (id: string | undefined) => string
}

export function useCurrentBusinessTimezoneWarning({
  getChangeTimezoneLink = defaultGetChangeTimezoneLink,
}: UseCurrentBusinessTimezoneWarningProps = {}) {
  const currentBusiness = useSelector(getCurrentBusiness)
  const permissions: UserPermissions = useSelector(
    getCRUDByAreaForBusiness(PermissionArea.BUSINESS, currentBusiness),
  )

  const userTimezone = moment.tz.guess()
  const businessTimezone = useCurrentBusinessTimezone()
  const { t } = useTranslation('Utils')

  const currentTime = new Date().getTime()
  const userTimezoneOffset = moment.tz
    .zone(userTimezone)
    ?.utcOffset(currentTime)
  const businessTimezoneOffset = moment.tz
    .zone(businessTimezone)
    ?.utcOffset(currentTime)

  if (businessTimezoneOffset === userTimezoneOffset) {
    return null
  }

  return (
    <>
      {t('Utils:CURRENT_BUSINESS_TIMEZONE.INTRO_TEXT', {
        userTimezone,
        businessTimezone,
      })}
      &nbsp;
      {permissions.update && (
        <>
          {t('Utils:CURRENT_BUSINESS_TIMEZONE.BEFORE_LINK_TEXT')}&nbsp;
          <Link to={getChangeTimezoneLink(currentBusiness?.id)}>
            {t('Utils:CURRENT_BUSINESS_TIMEZONE.LINK_TEXT')}
          </Link>
        </>
      )}
    </>
  )
}
