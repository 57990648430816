import { gql } from '@apollo/client/core'

export const FETCH_INVOICE_DISCOUNT_REASONS = gql`
  query InvoiceDiscountReason($businessId: ID!) {
    invoiceDiscountReason(businessId: $businessId) {
      discountReasonMandatory
      invoiceDiscountReasons {
        id
        business {
          id
          name
        }
        name
        active
        common
        createdAt
        updatedAt
      }
    }
  }
`
