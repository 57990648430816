import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  updateBusiness,
  updateBusinessCandidate,
} from '~/store/actions/registration'
import {
  getIsActivationKeyFlow,
  getRegistrationBusinessCandidate,
  getRegistrationIsLoading,
} from '~/store/reducers/registration'
import { registrationNavigationDependingOnIsActivationFlow } from '~/utils/registrationNavigationDependingOnIsActivationFlow'

import ClinicView from './ClinicView'

const ClinicViewComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clinic: any = useSelector(getRegistrationBusinessCandidate)
  const isLoading = useSelector(getRegistrationIsLoading)
  const isActivationFow = useSelector(getIsActivationKeyFlow)

  if (isLoading) {
    return null
  }
  if (!clinic) {
    return <Navigate replace to="/register" />
  }
  if (!clinic.isFilled()) {
    return <Navigate replace to="/register/clinic/edit" />
  }

  const onBackToMapClick = () => {
    dispatch(updateBusinessCandidate(null))
    navigate('/register/clinic/select')
  }

  const onSubmit = () => {
    dispatch(updateBusiness(clinic))
    registrationNavigationDependingOnIsActivationFlow(navigate, isActivationFow)
  }

  const onEditClick = () => {
    navigate('/register/clinic/edit')
  }

  return (
    <ClinicView
      clinic={clinic}
      onBackToMapClick={onBackToMapClick}
      onEditClick={onEditClick}
      onSubmit={onSubmit}
    />
  )
}

export default ClinicViewComponent
