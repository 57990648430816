import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text, TextProps } from '@pbt/pbt-ui-components'

import BusinessTypeIcon from './icons/BusinessTypeIcon'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'BusinessTypeLabel' },
)

export interface BusinessTypeLabelProps extends TextProps {
  classes?: ClassesType<typeof useStyles>
  icon?: string
}

const BusinessTypeLabel = ({
  children,
  classes: classesProp,
  icon,
  ...rest
}: BusinessTypeLabelProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Text {...rest}>
      <BusinessTypeIcon className={classes.icon} iconName={icon} />
      {children}
    </Text>
  )
}

export default BusinessTypeLabel
