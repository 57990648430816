import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Patient,
  PuiDialog,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

import BreedAutocomplete from '~/components/common/inputs/BreedAutocomplete'
import GenderField from '~/components/common/inputs/GenderField'
import SpayedNeuteredField from '~/components/common/inputs/SpayedNeuteredField'
import SpeciesSelect from '~/components/common/inputs/SpeciesSelect'
import DateOfBirthSelector from '~/components/common/selects/DateOfBirthSelector'
import { editPatient } from '~/store/actions/patients'
import { getPatientsIsLoading } from '~/store/reducers/patients'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
    },
    button: {
      minWidth: 150,
      height: 40,
    },
    icon: {
      color: theme.colors.alertWarning,
    },
  }),
  { name: 'PatientMissingFieldsDialog' },
)

export interface PatientMissingFieldsDialogProps extends BasePuiDialogProps {
  clientId: string
  patient: Patient
}

const PatientMissingFieldsDialog = ({
  open,
  clientId,
  patient,
  onClose,
}: PatientMissingFieldsDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Abbreviations', 'Common', 'Soap'])

  const isLoading = useSelector(getPatientsIsLoading)

  const {
    fields: { species, breed, gender, spayedNeutered, dob, approximateAge },
  } = useFields(
    [
      { name: 'species', type: 'select', initialValue: patient.species },
      { name: 'breed', type: 'select', initialValue: patient.breeds || [] },
      { name: 'gender', initialValue: patient.gender },
      { name: 'spayedNeutered', initialValue: patient.spayedNeutered },
      {
        name: 'dob',
        validators: ['timestamp'],
        initialValue: patient.dateOfBirth,
      },
      {
        name: 'approximateAge',
        type: 'toggle',
        initialValue: patient.approximateAge,
      },
    ],
    false,
  )

  const setCloseOnSave = useCloseAfterCreation(onClose, getPatientsIsLoading)

  const missingSpecies = !patient.species
  const missingBreed = !patient.breeds || patient.breeds.length === 0
  const missingGender = !patient.gender
  const missingSpayedNeutered = R.isNil(patient.spayedNeutered)
  const missingDob = !patient.dateOfBirth

  const onSave = () => {
    setCloseOnSave()
    dispatch(
      editPatient(clientId, {
        ...patient,
        species: species.value,
        breeds: breed.value,
        gender: gender.value,
        spayedNeutered: spayedNeutered.value,
        dateOfBirth: dob.value,
        approximateAge: approximateAge.value,
      }),
    )
  }

  return (
    <PuiDialog
      actions={
        <Grid container columnSpacing={2} justifyContent="flex-end">
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              loading={isLoading}
              onClick={onSave}
            >
              {t('Common:SAVE_AND_CONTINUE_ACTION')}
            </ButtonWithLoader>
          </Grid>
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              disabled={isLoading}
              onClick={onClose}
            >
              {t('Common:SKIP_ACTION')}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      }
      aria-labelledby="patient-missing-fields-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        pb={3}
        pt={4}
        px={3}
      >
        <Grid item>
          <WarningIcon className={classes.icon} />
        </Grid>
        <Grid item mb={2} mt={1}>
          <Text variant="body">
            {t(
              'Soap:NOTE_THIS_PATIENT_DOES_NOT_HAVE_ASSIGNED_SPECIES_GENDER_DATE',
            )}
          </Text>
          <Grid container alignItems="center" spacing={2}>
            {missingSpecies && (
              <Grid item md={6} xs={12}>
                <SpeciesSelect field={species} />
              </Grid>
            )}
            {missingBreed && (
              <Grid item md={6} xs={12}>
                <BreedAutocomplete field={breed} species={species.value} />
              </Grid>
            )}
            {(missingGender || missingSpayedNeutered) && (
              <Grid container item alignItems="center" columnSpacing={2} mt={1}>
                {missingGender && (
                  <Grid item md={6} xs={12}>
                    <GenderField field={gender} />
                  </Grid>
                )}
                {missingSpayedNeutered && (
                  <Grid item md={6} xs={12}>
                    <SpayedNeuteredField
                      field={spayedNeutered}
                      gender={gender.value}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {missingDob && (
              <Grid item md={6} xs={12}>
                <DateOfBirthSelector
                  approximateAge={approximateAge}
                  dob={dob}
                  label={t('Abbreviations:COMMON.DATE_OF_BIRTH')}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default PatientMissingFieldsDialog
