import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Text, Utils } from '@pbt/pbt-ui-components'

import VitalValueLabel from '~/components/dashboard/clients/timeline/vital/VitalValueLabel'
import { vitalsTableConfig } from '~/components/dashboard/soapV2/vitals/config/vitalsTableConfig'
import { getLastSoapVital } from '~/store/reducers/soap'
import { VitalValue } from '~/types'

export interface VitalsProps {
  className?: string
}

const Vitals = ({ className }: VitalsProps) => {
  const vital = useSelector(getLastSoapVital)
  const { t } = useTranslation('Common')

  return (
    <Grid item className={className}>
      {Boolean(vital) &&
        Object.keys(vital).map((key) => {
          const configItem = Utils.findById(key, vitalsTableConfig)
          const vitalName = configItem ? configItem.humanReadable : ''
          const notes = vital[key as keyof VitalValue]?.notes

          return (
            <React.Fragment key={key}>
              <Text variant="body2">
                {`${vitalName}: `}
                <VitalValueLabel
                  singleUnit
                  component="span"
                  variant="body2"
                  vital={vital}
                  vitalConfigItem={configItem}
                />
              </Text>
              {notes && (
                <Text ml={2} variant="body2">
                  {`${t('Common:NOTES')}: ${notes}`}
                </Text>
              )}
            </React.Fragment>
          )
        })}
    </Grid>
  )
}

export default Vitals
