import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ErrorTooltip,
  MarketingStylesButton,
  PasswordInput,
  PuiTextField,
  Validators,
} from '@pbt/pbt-ui-components'

import { clearAuthorizationError, loginRequest } from '~/store/actions/auth'
import {
  getAuthError,
  getAuthIsFetching,
  getAuthorizationError,
} from '~/store/reducers/auth'
import { getErrorMessage, removeServerErrorPrefix } from '~/utils/errors'

import ForgotPasswordLink from '../link/ForgotPasswordLink'
import AuthorizationErrorTooltip from './AuthorizationErrorTooltip'
import RegisterButton from './RegisterButton'
import TermsAndCondition from './TermsAndCondition'

const useStyles = makeStyles(
  (theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
      },
    },
    submit: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      width: 'calc(100% - 16px)',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: '100%',
      },
    },
    passwordContainer: {
      paddingTop: theme.spacing(2),
    },
  }),
  { name: 'LoginForm' },
)

interface LoginFormProps {
  headerComponent?: React.ReactElement
  showRegisterButton?: boolean
  showTermsAndConditions?: boolean
}

interface LoginFormLocationState {
  email?: string
}

const LoginForm = ({
  headerComponent,
  showRegisterButton = false,
  showTermsAndConditions = true,
}: LoginFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const { email: emailProp } = (location.state as LoginFormLocationState) || {}
  const isFetching: boolean | undefined = useSelector(getAuthIsFetching)
  const error = useSelector(getAuthError)
  const authorizationError = useSelector(getAuthorizationError)
  const hasAuthorizationError = Boolean(authorizationError)
  const { t } = useTranslation(['Auth', 'Common', 'Validations'])

  const [email, setEmail] = useState(emailProp || '')
  const [password, setPassword] = useState('')
  const [touched, setTouched] = useState(false)
  const [emailValidationError, setEmailValidationError] = useState('')
  const [passwordValidationError, setPasswordValidationError] = useState('')
  const [loginButtonTitle, setLoginButtonTitle] = useState<string>(
    t('Common:LOGIN'),
  )
  const [interacted, setInteracted] = useState(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setTouched(false)
    setEmailValidationError('')
    setPasswordValidationError('')
    setInteracted(false)
    if (!isFetching) {
      if (!email) {
        setEmailValidationError(t('Validations:NO_EMAIL'))
      } else if (!Validators.isEmailValid(email)) {
        setEmailValidationError(t('Validations:EMAIL_INVALID'))
      } else if (!password) {
        setPasswordValidationError(t('Validations:ENTER_PASSWORD'))
      } else {
        dispatch(loginRequest(email, password))
      }
    }
    return false
  }

  useEffect(() => {
    const emailValid = Validators.isEmailValid(email)
    if (isFetching) {
      setLoginButtonTitle(t('Common:LOGGING_IN'))
      return
    }
    if (
      error &&
      !touched &&
      !passwordValidationError &&
      !hasAuthorizationError
    ) {
      const passwordValidationErrorUpdater: SetStateAction<string> =
        removeServerErrorPrefix(getErrorMessage(error) as string) || ''
      setPasswordValidationError(passwordValidationErrorUpdater)
    }
    if (!emailValid && !password) {
      setLoginButtonTitle(t('Auth:LOGIN_FORM.ENTER_EMAIL_AND_PASSWORD'))
      return
    }
    if (!password) {
      setLoginButtonTitle(t('Common:ENTER_PASSWORD'))
      return
    }
    if (!emailValid) {
      setLoginButtonTitle(t('Common:ENTER_EMAIL'))
      return
    }
    setLoginButtonTitle(t('Common:LOGIN'))
  }, [email, error, password, touched])

  useEffect(() => {
    if (hasAuthorizationError && (email || password || touched) && interacted) {
      dispatch(clearAuthorizationError())
    }
  }, [email, password, touched])

  return (
    <>
      <form noValidate className={classes.form} onSubmit={handleSubmit}>
        {headerComponent}
        <ErrorTooltip
          message={emailValidationError}
          open={Boolean(!touched && emailValidationError)}
        >
          <PuiTextField
            autoComplete="email"
            autoFocus={!email}
            disabled={isFetching}
            error={Boolean(!touched && emailValidationError)}
            id="email"
            label={t('Common:EMAIL')}
            type="email"
            value={email}
            onChange={(event) => {
              setTouched(true)
              setEmail(event.target.value.trim())
            }}
            onMouseDown={() => setInteracted(true)}
          />
        </ErrorTooltip>
        <ErrorTooltip
          message={passwordValidationError}
          open={Boolean(
            !touched && !emailValidationError && passwordValidationError,
          )}
        >
          <Grid
            container
            alignItems="center"
            className={classes.passwordContainer}
            wrap="nowrap"
          >
            <PasswordInput
              autoFocus={Boolean(email)}
              disabled={isFetching}
              error={Boolean(!touched && passwordValidationError)}
              password={password}
              onChange={(event) => {
                setTouched(true)
                setPassword(event.target.value)
              }}
              onMouseDown={() => setInteracted(true)}
            />
          </Grid>
        </ErrorTooltip>
        <ForgotPasswordLink />
        <AuthorizationErrorTooltip error={authorizationError}>
          <MarketingStylesButton className={classes.submit} type="submit">
            {loginButtonTitle}
          </MarketingStylesButton>
        </AuthorizationErrorTooltip>
      </form>
      {showRegisterButton && <RegisterButton />}
      {showTermsAndConditions && <TermsAndCondition />}
    </>
  )
}

export default LoginForm
