import { BillingAddress, Nil } from '@pbt/pbt-ui-components'

import PaymentType from '~/constants/paymentTypes'
import {
  ExtendPayment,
  GoTransaction,
  PaymentPayload,
  UnsavedExtendPayment,
} from '~/types'

import { getAPIPath, request } from './core'
import {
  MutationLinkUnappliedPaymentArgs,
  MutationLinkUnappliedPaymentsArgs,
  MutationUnApplyInvoicePaymentArgs,
} from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import * as Mutation from './graphql/mutations/payments'
import {
  FETCH_ALL_PAYMENT_ENTITIES,
  FETCH_UNAPPLIED_PAYMENTS_BY_CLIENT,
} from './graphql/queries/payments'
import * as Schema from './schemas'

export const fetchBillingAddresses = (businessId: string, clientId: string) =>
  request(
    getAPIPath(`persons/${clientId}/lastBillingAddresses`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const createBillingAddress = (
  businessId: string,
  address: BillingAddress,
  clientId: string,
) =>
  request(
    getAPIPath(`persons/${clientId}/billingAddress`),
    { method: 'POST', data: address, params: { businessId } },
    true,
  )

export const updateBillingAddress = (
  businessId: string,
  address: BillingAddress,
  clientId: string,
) =>
  request(
    getAPIPath(`persons/${clientId}/billingAddress/${address.id}`),
    { method: 'PUT', data: address, params: { businessId } },
    true,
  )

export const chargePayment = (
  businessId: string,
  paymentData: ExtendPayment,
  transactionType: PaymentType,
) =>
  request(
    getAPIPath(`finance/cnp/${transactionType}`),
    { method: 'POST', data: paymentData, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const rhapsodyGoApplyPayment = (
  businessId: string,
  payload: {
    auth: boolean
    paymentData: PaymentPayload
  },
) =>
  request(
    getAPIPath(`finance/cnp/${payload.auth ? 'ApplyAndAuth' : 'ApplyAndSale'}`),
    { method: 'POST', data: payload.paymentData, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const rhapsodyGoAuthorization = (
  businessId: string,
  paymentData: ExtendPayment,
) =>
  request(
    getAPIPath('finance/cnp/Auth'),
    { method: 'POST', data: paymentData, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const refundRhapsodyGoTransaction = (
  businessId: string,
  paymentToRefund: ExtendPayment,
  transactionType: PaymentType,
) =>
  request(
    getAPIPath(`finance/cnp/${transactionType}`),
    { method: 'POST', data: paymentToRefund, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const refundRhapsodyGoStripeTransaction = (
  businessId: string,
  paymentToRefund: ExtendPayment,
  transactionType: PaymentType,
) =>
  request(
    getAPIPath(`finance/stripe/${transactionType}`),
    { method: 'POST', data: paymentToRefund, params: { businessId } },
    true,
    Schema.goStripePayment,
  )

export const voidRhapsodyGoTransaction = (
  businessId: string,
  paymentToVoid: ExtendPayment,
  transactionType: PaymentType,
) =>
  request(
    getAPIPath(`finance/cnp/${transactionType}`),
    { method: 'POST', data: paymentToVoid, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const voidRhapsodyGoStripeTransaction = (
  businessId: string,
  paymentToVoid: ExtendPayment,
  transactionType: PaymentType,
) =>
  request(
    getAPIPath(`finance/stripe/${transactionType}`),
    { method: 'POST', data: paymentToVoid, params: { businessId } },
    true,
    Schema.goStripePayment,
  )

export const settleGoTransaction = (
  businessId: string,
  transactionToSettle: GoTransaction,
) =>
  request(
    getAPIPath('finance/cnp/Settle'),
    { method: 'POST', data: transactionToSettle, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const settleGoStripeTransaction = (
  businessId: string,
  transactionToSettle: GoTransaction,
) =>
  request(
    getAPIPath('finance/stripe/Settle'),
    { method: 'POST', data: transactionToSettle, params: { businessId } },
    true,
    Schema.goStripePayment,
  )

export const fetchPayment = (businessId: string, paymentId: string) =>
  request(
    getAPIPath(`finance/payment/${paymentId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.payment,
  )

export const fetchGoPayment = (businessId: string, goPaymentId: string) =>
  request(
    getAPIPath(`finance/cnp/${goPaymentId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.goPayment,
  )

export const createPayment = (
  businessId: string,
  clientId: string | Nil,
  payment: UnsavedExtendPayment,
) =>
  request(
    getAPIPath('finance/payment'),
    { method: 'POST', data: payment, params: { businessId, clientId } },
    true,
    Schema.payment,
  )

export const createManualPayment = (
  businessId: string,
  clientId: string | Nil,
  payload: PaymentPayload,
) =>
  request(
    getAPIPath('finance/payment/with-unapplied'),
    { method: 'POST', data: payload, params: { businessId, clientId } },
    true,
    Schema.payment,
  )

export const createCreditAdjustment = requestMutation({
  mutation: Mutation.CREATE_CREDIT_ADJUSTMENT,
  variablesHandler: (businessId, clientId, input) => ({
    businessId,
    clientId,
    input,
  }),
})

export const createPaymentTransaction = (
  businessId: string,
  clientId: string,
  payment: ExtendPayment,
  withUnapplied = false,
) =>
  request(
    getAPIPath(`finance/pay${withUnapplied ? '/with-unapplied' : ''}`),
    { method: 'POST', data: payment, params: { businessId, clientId } },
    true,
    Schema.payment,
  )

export const getTransactionStatus = (
  businessId: string,
  transactionId: string,
) =>
  request(
    getAPIPath(`finance/pay/${transactionId}`),
    { method: 'GET', params: { businessId } },
    true,
    Schema.posTransaction,
  )

export const applyPaymentToInvoice = (
  businessId: string,
  paymentId: string,
  invoiceIds: string[],
) =>
  request(
    getAPIPath(`finance/payment/${paymentId}/link`),
    { method: 'PUT', params: { businessId, invoiceIds: invoiceIds.join(',') } },
    true,
    Schema.payment,
  )

export const linkUnappliedPayment = requestMutation({
  mutation: Mutation.LINK_UNAPPLIED_PAYMENT,
  variablesHandler: (_, args: MutationLinkUnappliedPaymentArgs) => ({
    invoiceIds: args.invoiceIds,
    paymentId: args.paymentId,
  }),
})

export const unlinkPaymentToInvoice = requestMutation({
  mutation: Mutation.UNAPPLY_INVOICE_PAYMENT,
  variablesHandler: (_, args: MutationUnApplyInvoicePaymentArgs) => ({
    paymentId: args.paymentId,
    invoiceId: args.invoiceId,
  }),
})

export const editPayment = (
  businessId: string,
  paymentId: string,
  data: Partial<ExtendPayment>,
) =>
  request(
    getAPIPath(`finance/payment/${paymentId}`),
    { method: 'PATCH', data, params: { businessId } },
    true,
    Schema.payment,
  )

export const editGoPayment = (
  businessId: string,
  paymentId: string,
  data: Partial<ExtendPayment>,
) =>
  request(
    getAPIPath(`finance/cnp/${paymentId}`),
    { method: 'PATCH', data, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const createStripePayment = (
  businessId: string,
  paymentData: ExtendPayment,
  transactionType: PaymentType,
) =>
  request(
    getAPIPath(`finance/stripe/${transactionType}`),
    {
      method: 'POST',
      data: paymentData,
      params: { businessId },
    },
    true,
  )

export const editGoStripePayment = (
  businessId: string,
  paymentId: string,
  data: Partial<ExtendPayment>,
) =>
  request(
    getAPIPath(`finance/stripe/${paymentId}`),
    { method: 'PATCH', data, params: { businessId } },
    true,
    Schema.goPayment,
  )

export const fetchUnappliedPaymentByClient = requestQuery({
  query: FETCH_UNAPPLIED_PAYMENTS_BY_CLIENT,
  variablesHandler: (currentBusinessId, clientId, from, to) => ({
    businessId: currentBusinessId,
    clientId,
    offset: from,
    limit: to,
  }),
})

export const fetchAllPaymentEntities = requestQuery({
  query: FETCH_ALL_PAYMENT_ENTITIES,
  variablesHandler: (
    businessId: string,
    clientId: string,
    from?: number,
    to?: number,
  ) => ({
    businessId,
    clientId,
    offset: from,
    limit: to,
  }),
})

export const linkUnappliedPayments = requestMutation({
  mutation: Mutation.LINK_UNAPPLIED_PAYMENTS,
  variablesHandler: (_, args: MutationLinkUnappliedPaymentsArgs) => ({
    ...args,
  }),
})
