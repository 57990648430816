import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, PuiDialogProps, Text } from '@pbt/pbt-ui-components'

import ListPreview from '~/components/common/images/notificationStyleListPreview.png'
import MenuPreview from '~/components/common/images/notificationStyleMenuPreview.png'
import PopUpPreview from '~/components/common/images/notificationStylePopUpPreview.png'
import i18n from '~/locales/i18n'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 1020,
      maxWidth: 1020,
    },
    img: {
      width: 313,
    },
    dialogTitle: {
      borderBottom: 'none',
    },
  }),
  { name: 'NotificationStylesPreviewDialog' },
)

const stylesPreview = [
  { title: i18n.t('Common:POP_UPS'), src: PopUpPreview },
  { title: i18n.t('Common:MENU'), src: MenuPreview },
  { title: i18n.t('Common:LIST'), src: ListPreview },
]

export interface NotificationStylesPreviewDialogProps extends PuiDialogProps {}

const NotificationStylesPreviewDialog = ({
  open,
  onClose,
}: NotificationStylesPreviewDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Notifications')

  return (
    <PuiDialog
      aria-labelledby="notification-styles-preview-dialog"
      classes={{
        paper: classes.paper,
        dialogTitle: classes.dialogTitle,
      }}
      open={open}
      title={t(
        'Notifications:NOTIFICATION_SETTINGS_COMBINATION_TABLE.NOTIFICATION_STYLES',
      )}
      onClose={onClose}
    >
      <Grid container columnSpacing={2} pb={17} pt={6} px={2} wrap="nowrap">
        {stylesPreview.map((stylePreview) => (
          <Grid container item direction="column" key={stylePreview.title}>
            <Text strong mb={2} ml={1} variant="body2">
              {stylePreview.title}
            </Text>
            <img
              alt={stylePreview.title}
              className={classes.img}
              src={stylePreview.src}
            />
          </Grid>
        ))}
      </Grid>
    </PuiDialog>
  )
}

export default NotificationStylesPreviewDialog
