import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { List, ListItemText } from '@mui/material'
import { Text } from '@pbt/pbt-ui-components'

import NoItemsScreen from '~/components/dashboard/NoItemsScreen'
import DialogNames from '~/constants/DialogNames'
import useDialog from '~/utils/useDialog'

const NoDocumentsScreen = () => {
  const { t } = useTranslation(['Admin', 'Common'])
  const [openNewDocumentDialog] = useDialog(DialogNames.DOCUMENT)

  return (
    <NoItemsScreen
      action={() =>
        openNewDocumentDialog({
          PreviewProps: { hideEmail: true, hidePrint: true },
        })
      }
      actionMessage={t('Common:ADD_NEW')}
      title={t('Common:DOCUMENTS_AND_TEMPLATES')}
    >
      <>
        <Text variant="body">
          {t('Admin:CATALOG.NO_DOCUMENTS_SCREEN.DESCRIPTION')}
        </Text>
        <List disablePadding sx={{ pl: 2, mt: 2 }}>
          <ListItemText>
            <Text variant="body">
              <Trans
                components={{
                  boldSpan: <Text strong component="span" variant="body" />,
                }}
                i18nKey="Admin:CATALOG.NO_DOCUMENTS_SCREEN.LIST_ITEM_01"
              />
            </Text>
          </ListItemText>
          <ListItemText>
            <Text variant="body">
              <Trans
                components={{
                  boldSpan: <Text strong component="span" variant="body" />,
                }}
                i18nKey="Admin:CATALOG.NO_DOCUMENTS_SCREEN.LIST_ITEM_02"
              />
            </Text>
          </ListItemText>
          <ListItemText>
            <Text variant="body">
              <Trans
                components={{
                  boldSpan: <Text strong component="span" variant="body" />,
                }}
                i18nKey="Admin:CATALOG.NO_DOCUMENTS_SCREEN.LIST_ITEM_03"
              />
            </Text>
          </ListItemText>
          <ListItemText>
            <Text variant="body">
              <Trans
                components={{
                  boldSpan: <Text strong component="span" variant="body" />,
                }}
                i18nKey="Admin:CATALOG.NO_DOCUMENTS_SCREEN.LIST_ITEM_04"
              />
            </Text>
          </ListItemText>
        </List>
      </>
    </NoItemsScreen>
  )
}

export default NoDocumentsScreen
