import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import SnackNotification, { SnackNotificationProps } from './SnackNotification'

const useStyles = makeStyles(
  () => ({
    snackRoot: {
      width: 380,
    },
  }),
  { name: 'NetworkErrorSnackNotification' },
)

export interface NetworkErrorSnackNotificationProps
  extends Omit<SnackNotificationProps, 'classes'> {}

const NetworkErrorSnackNotification = (
  props: NetworkErrorSnackNotificationProps,
) => {
  const classes = useStyles()

  return (
    <SnackNotification
      preventClamp
      classes={{
        snackRoot: classes.snackRoot,
      }}
      {...props}
    />
  )
}

export default NetworkErrorSnackNotification
