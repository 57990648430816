import { ConversationTransport } from '~/api/graphql/generated/types'
import i18n from '~/locales/i18n'
import { PartialRecord } from '~/types'

export const CONVERSATION_MESSAGES_FIRST_FETCH_COUNT = 3

export enum MessageType {
  PLAIN = 'PLAIN',
  INTERNAL_NOTE = 'INTERNAL_NOTE',
}

export enum MessageWay {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum MessageStatus {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  DRAFT = 'DRAFT',
  FORMING_FAILED = 'FORMING_FAILED',
}

export const DEFAULT_CONVERSATION = {
  messages: [],
  totalCount: CONVERSATION_MESSAGES_FIRST_FETCH_COUNT,
}

export enum ConversationStatus {
  OPEN = 'OPEN',
  UNREAD = 'UNREAD',
  ARCHIVED = 'ARCHIVED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
}

export const CONVERSATION_STATUS_NAME = {
  [ConversationStatus.OPEN]: i18n
    .t('Communications:STATUS.CONVERSATION_OPEN')
    .toUpperCase(),
  [ConversationStatus.UNREAD]: i18n
    .t('Communications:STATUS.CONVERSATION_UNREAD')
    .toUpperCase(),
  [ConversationStatus.ARCHIVED]: i18n
    .t('Communications:STATUS.CONVERSATION_ARCHIVED')
    .toUpperCase(),
  [ConversationStatus.DELIVERY_FAILED]: i18n
    .t('Communications:STATUS.CONVERSATION_DELIVERY_FAILED')
    .toUpperCase(),
}

export enum ConversationCategory {
  INBOX = 'INBOX',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
}

export const CONVERSATION_CATEGORY_NAME = {
  [ConversationCategory.INBOX]: i18n.t('Common:INBOX'),
  [ConversationCategory.DRAFT]: i18n.t('Common:DRAFT'),
  [ConversationCategory.SENT]: i18n.t('Common:SENT'),
}

export enum DraftFilter {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  NOT_DRAFT = 'NOT_DRAFT',
}

export enum GeneratingPdfContentKind {
  INVOICE = 'INVOICE',
  INVOICE_V2 = 'INVOICE_V2',
  CHARGE_SHEET = 'CHARGE_SHEET',
  MEDICAL_HISTORY = 'MEDICAL_HISTORY',
  REPORT_CARD = 'REPORT_CARD',
  PRESCRIPTION = 'PRESCRIPTION',
  NONE = 'NONE',
}

export const MEMBERSHIP_INVITE_LINK_UI_PLACEHOLDER = i18n.t(
  'Constants:COMMUNICATIONS.MEMBERSHIP_INVITE_LINK_UI_PLACEHOLDER',
)

export const BOOP_GOOGLE_PLAY_DOWNLOAD_LINK = '<<Google play>>'
export const BOOP_APP_STORE_DOWNLOAD_LINK = '<<Apple App store>>'

export const MESSAGE_MAX_LENGTH_BY_TRANSPORT: PartialRecord<
  ConversationTransport,
  number
> = {
  [ConversationTransport.Sms]: 1600,
  [ConversationTransport.Boop]: 1600,
}

export const TEXT_MESSAGE_MAX_LENGTH_BUFFER_FOR_DYNAMIC_TEXT = 200

export const MESSAGE_TRANSPORT_NAMES: Record<ConversationTransport, string> = {
  [ConversationTransport.Sms]: i18n.t('Common:SMS'),
  [ConversationTransport.Email]: i18n.t('Common:EMAIL'),
  [ConversationTransport.LogPhoneCall]: i18n.t('Common:LOG_PHONE_CALL'),
  [ConversationTransport.Boop]: i18n.t('Common:BOOP_SYSTEM_NAME'),
}

export const INITIAL_COMMUNICATION_FILTERS = {
  category: { value: ConversationCategory.INBOX },
  status: {
    value: [
      ConversationStatus.DELIVERY_FAILED,
      ConversationStatus.UNREAD,
      ConversationStatus.OPEN,
    ].join(','),
  },
}
