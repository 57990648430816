import React, { useState } from 'react'
import { Autocomplete } from '@mui/material'
import { Field, PuiTextField } from '@pbt/pbt-ui-components'

import { MigrationConstant } from '~/types'

interface MigrationExceptionAutocompleteProps {
  className: string
  field: Field
  options: MigrationConstant[]
}

const MigrationExceptionAutocomplete = ({
  field,
  options,
  className,
}: MigrationExceptionAutocompleteProps) => {
  const [open, setOpen] = useState(false)
  if (!field) {
    return null
  }

  return (
    <Autocomplete
      disableClearable
      className={className}
      getOptionLabel={(option) => option?.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      open={open}
      options={options || []}
      renderInput={(params) => (
        <PuiTextField {...params} InputProps={{ ...params.InputProps }} />
      )}
      value={options?.find((option) => option.id === field.value) || undefined}
      onChange={(event, option) => {
        const newValue = option?.id
        if (newValue && field.value !== newValue) {
          field.setValue(newValue)
        }
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}

export default MigrationExceptionAutocomplete
