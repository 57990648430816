import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiDialog, Text } from '@pbt/pbt-ui-components'

import EmailPreview from '~/components/common/emailPreview/EmailPreview'
import {
  fetchCurrentReminderSettingsTemplate,
  fetchReminderSettingsTemplate,
  getIsTemplateLoading,
  getSettingsTemplate,
} from '~/store/duck/reminderProtocolSettings'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      flex: 1,
      padding: theme.spacing(2, 3, 3),
    },
    paper: {
      width: 680,
      maxWidth: 680,
    },
    spinner: {},
  }),
  { name: 'PreviewReminderTemplateDialog' },
)

interface PreviewReminderTemplateDialogProps {
  businessId: string
  onClose?: () => void
  open?: boolean
  templateId?: string
  templateText?: string
}

const PreviewReminderTemplateDialog = ({
  open,
  businessId,
  templateText,
  templateId,
  onClose,
}: PreviewReminderTemplateDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  useEffect(() => {
    if (templateId) {
      dispatch(fetchReminderSettingsTemplate(businessId, templateId))
    } else {
      dispatch(
        fetchCurrentReminderSettingsTemplate(
          businessId,
          templateText || '<p></p>',
        ),
      )
    }
  }, [])

  const reminderTemplate = useSelector(getSettingsTemplate)
  const isLoading = useSelector(getIsTemplateLoading)

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="reminder-preview-template-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={Boolean(open)}
      scroll="paper"
      title={t('Businesses:REMINDER_SET_UP.PREVIEW_REMINDER_COMMUNICATION')}
      onClose={onClose}
    >
      <Grid container className={classes.container} direction="column">
        <Text mb={2}>
          {t('Businesses:REMINDER_SET_UP.PREVIEW_INCLUDES_SAMPLE_REMINDER')}
        </Text>
        {isLoading ? (
          <Grid container justifyContent="center">
            <CircularProgress
              className={classes.spinner}
              color="primary"
              size={32}
            />
          </Grid>
        ) : (
          <EmailPreview emailTemplate={reminderTemplate} />
        )}
      </Grid>
    </PuiDialog>
  )
}

export default PreviewReminderTemplateDialog
