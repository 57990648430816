import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

export const oauthLoginRocketChat = (currentBusinessId: string) =>
  request(
    getPreludeAPIPath('chat/oauth/login'),
    { method: 'POST', params: { businessId: currentBusinessId } },
    true,
  )

export const fetchChatReconcilationInfo = (
  _: string,
  businessId: string,
  count: number,
) =>
  request(
    getPreludeAPIPath('chat/reconcile/status'),
    { method: 'GET', params: { businessId, count } },
    true,
  )

export const startChatReconcilation = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getPreludeAPIPath('chat/reconcile'),
    {
      method: 'POST',
      params: { businessId: currentBusinessId },
      data: { businessIds: [businessId] },
    },
    true,
  )

export const fetchBusinessChatOnboardInfo = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getPreludeAPIPath(`chat/onboard/${businessId}`),
    {
      method: 'GET',
      params: { businessId: currentBusinessId },
    },
    true,
    {
      data: Schema.businessOnboardInfo,
    },
  )

export const partialEditBusinessChatOnboardInfo = (
  currentBusinessId: string,
  businessId: string,
  chatUiEnabled: boolean,
) =>
  request(
    getPreludeAPIPath(`chat/onboard/${businessId}`),
    {
      method: 'PATCH',
      params: { businessId: currentBusinessId },
      data: {
        businessId,
        chatUiEnabled,
      },
    },
    true,
    {
      data: Schema.businessOnboardInfo,
    },
  )
