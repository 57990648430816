import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  ControlButtonGroup,
  ControlButtonGroupName,
  FileTemplate,
  InfiniteLoaderList,
  PuiDialog,
  Text,
  useInterval,
} from '@pbt/pbt-ui-components'
import { SuccessAlert } from '@pbt/pbt-ui-components/src/icons'

import AttachmentUploadIcon from '~/components/common/inputs/attachment/attachment-dialog/AttachmentUploadIcon'
import FileInput from '~/components/common/inputs/file-input/FileInput'
import Spacer from '~/components/common/Spacer'
import { MIGRATION_STATUS_POLLING_INTERVAL } from '~/constants/migration'
import i18n from '~/locales/i18n'
import { fetchBusiness } from '~/store/actions/businesses'
import {
  practiceExportActions,
  practiceExportSelectors,
  // @ts-ignore
} from '~/store/duck/practiceExport'
import {
  practiceImportActions,
  practiceImportSelectors,
  // @ts-ignore
} from '~/store/duck/practiceImport'
import { fetchRemindersSettings } from '~/store/duck/reminderProtocolSettings'
import { getPracticeCloningItems } from '~/store/reducers/constants'
import { BaseMigrationDialogProps } from '~/types/entities/migration'
import { PracticeCloningSession } from '~/types/entities/practiceCloning'

import EntitiesStatusLog from './EntitiesStatusLog'
import { enrichExportEntitiesStatus } from './practiceCloningUtils'
import PracticeExportSessionLabel from './PracticeExportSessionLabel'

const {
  fetchSession,
  fetchActiveSession,
  createSession,
  createSessionFromFile,
  cleanActiveSession,
} = practiceImportActions
const { getActiveSessionId, getSession, getIsLoading } = practiceImportSelectors

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(3),
      backgroundColor: theme.colors.contentBackground,
    },
    well: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      minHeight: 256,
      maxHeight: 432,
    },
    successIcon: {
      width: 48,
      height: 48,
      color: theme.colors.alertSuccess,
    },
    actionButton: {
      padding: theme.spacing(0, 4),
      margin: theme.spacing(1, 0, 1, 2),
      cursor: 'pointer',
    },
    imageInputLogoArea: {
      backgroundColor: theme.colors.tableBackground,
      height: 116,
    },
    selectedFileContainer: {
      borderRadius: 2,
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    radioGroup: {
      width: '100%',
    },
  }),
  { name: 'PracticeCloningImportDialog' },
)

enum DialogStep {
  PRELOAD = 'PRELOAD',
  CONFIGURE_FROM_EXPORT = 'CONFIGURE_FROM_EXPORT',
  CONFIGURE_FROM_FILE = 'CONFIGURE_FROM_FILE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

const DialogStepToActionName = {
  [DialogStep.PRELOAD]: undefined,
  [DialogStep.CONFIGURE_FROM_EXPORT]: i18n.t('Common:IMPORT_ACTION'),
  [DialogStep.CONFIGURE_FROM_FILE]: i18n.t('Common:IMPORT_ACTION'),
  [DialogStep.IN_PROGRESS]: undefined,
  [DialogStep.DONE]: undefined,
}

const PracticeCloningImportDialog = ({
  open,
  onClose,
  businessId,
}: BaseMigrationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Businesses'])

  const PracticeCloningItems = useSelector(getPracticeCloningItems)
  const activeSessionId: string = useSelector(getActiveSessionId)
  const activeSession: PracticeCloningSession = useSelector(
    getSession(activeSessionId),
  )
  const exportSessionIds: string[] =
    useSelector(practiceExportSelectors.getSessionsList) || []
  const totalCount: number =
    useSelector(practiceExportSelectors.getTotalCount) || 0
  const exportSessions: PracticeCloningSession[] =
    useSelector(
      practiceExportSelectors.getMultipleSessions(exportSessionIds),
    ) || []
  const isLoading: boolean = useSelector(getIsLoading)

  const [selectedExport, setSelectedExport] = useState<string | null>(null)
  const [step, setStep] = useState<DialogStep>()
  const [file, setFile] = useState<FileTemplate | null>(null)

  const exportEntitiesStatus = enrichExportEntitiesStatus(
    PracticeCloningItems,
    activeSession,
  )
  const actionTitle = step && DialogStepToActionName[step]
  const actionDisabled = (!selectedExport && !file?.data) || isLoading

  useEffect(() => {
    dispatch(fetchActiveSession(businessId))
    setStep(DialogStep.PRELOAD)
    return () => dispatch(cleanActiveSession())
  }, [])

  useEffect(() => {
    const actualState = activeSession?.state
    if (actualState) {
      const dialogStep =
        actualState === DialogStep.IN_PROGRESS
          ? DialogStep.IN_PROGRESS
          : DialogStep.DONE

      setStep(dialogStep)
    }
  }, [activeSession?.state])

  useEffect(() => {
    if (businessId && step === DialogStep.DONE) {
      dispatch(fetchBusiness(businessId))
      dispatch(fetchRemindersSettings(businessId))
    }
  }, [step])

  useEffect(() => {
    if (!activeSessionId && !isLoading && step === DialogStep.PRELOAD) {
      setStep(DialogStep.CONFIGURE_FROM_EXPORT)
      dispatch(practiceExportActions.fetchSessionsForBusiness(businessId))
    }
  }, [activeSessionId, isLoading])

  useInterval(() => {
    if (step === DialogStep.IN_PROGRESS) {
      dispatch(fetchSession(activeSessionId))
    }
  }, MIGRATION_STATUS_POLLING_INTERVAL)

  const handleAction = () => {
    if (step === DialogStep.CONFIGURE_FROM_EXPORT) {
      const session = {
        businessId,
        exportId: selectedExport,
      }
      dispatch(createSession(session))
    } else if (step === DialogStep.CONFIGURE_FROM_FILE) {
      dispatch(createSessionFromFile(businessId, file))
    }
  }

  const goToFileUpload = () => {
    setSelectedExport(null)
    setStep(DialogStep.CONFIGURE_FROM_FILE)
  }

  const loadMoreItems = (from: number, to: number) => {
    dispatch(
      practiceExportActions.fetchSessionsForBusiness(businessId, from, to),
    )
  }

  return (
    <PuiDialog
      fullWidth
      actions={
        <>
          {actionTitle && (
            <ButtonWithLoader
              className={classes.actionButton}
              disabled={actionDisabled}
              loading={isLoading}
              onClick={handleAction}
            >
              {actionTitle}
            </ButtonWithLoader>
          )}
          {step === DialogStep.CONFIGURE_FROM_EXPORT && (
            <Link className={classes.actionButton} onClick={goToFileUpload}>
              {t('Common:UPLOAD_FILE')}
            </Link>
          )}
        </>
      }
      aria-labelledby="practice-cloning-import-dialog"
      classes={{
        dialogContentRoot: classes.content,
      }}
      maxWidth="sm"
      open={open}
      title={t('Businesses:MIGRATION.IMPORT_CATALOG_AND_SETTINGS')}
      onClose={onClose}
    >
      {step === DialogStep.PRELOAD && (
        <Grid
          container
          alignItems="center"
          direction="column"
          justifyContent="center"
          p={4}
        >
          <CircularProgress size={32} />
          <Spacer spacing={1} />
          <Text variant="body">
            {t('Businesses:MIGRATION.CHECKING_ACTIVE_SESSIONS')}
          </Text>
        </Grid>
      )}
      {step === DialogStep.CONFIGURE_FROM_EXPORT && (
        <Grid container className={classes.well} pl={2}>
          <RadioGroup
            className={classes.radioGroup}
            value={selectedExport}
            onChange={(_, value) => setSelectedExport(value)}
          >
            <InfiniteLoaderList
              firstItemMargin={8}
              isItemLoaded={(index) => Boolean(exportSessions[index])}
              itemCount={totalCount}
              itemData={exportSessions}
              loadMoreItems={loadMoreItems}
            >
              {(session = {}) => (
                <FormControlLabel
                  control={<Radio />}
                  key={session.exportId}
                  label={<PracticeExportSessionLabel session={session} />}
                  value={session.exportId}
                />
              )}
            </InfiniteLoaderList>
          </RadioGroup>
        </Grid>
      )}
      {step === DialogStep.CONFIGURE_FROM_FILE &&
        (file?.data ? (
          <Grid
            container
            item
            alignItems="center"
            className={classes.selectedFileContainer}
            mb={8.5}
            pl={2}
            pr={1}
          >
            <Grid item xs alignItems="center">
              <Text align="left" variant="body">
                {`${file?.name}.${file?.extension}`}
              </Text>
            </Grid>
            <Grid item>
              <ControlButtonGroup
                buttons={[
                  {
                    name: ControlButtonGroupName.DELETE,
                    onClick: () => setFile(null),
                  },
                ]}
              />
            </Grid>
          </Grid>
        ) : (
          <FileInput
            clearOnCancel
            forceButtonsHidden
            Icon={AttachmentUploadIcon}
            accept=".zip"
            classes={{
              logoAreaClassName: classes.imageInputLogoArea,
            }}
            text={t('Businesses:MIGRATION.CLICK_HERE_TO_UPLOAD_FILE')}
            uploadButtonColor="primary"
            onFileSelected={setFile}
          />
        ))}
      {step === DialogStep.IN_PROGRESS && (
        <Grid container alignItems="center" direction="column" py={4}>
          <CircularProgress size={32} />
          <Spacer spacing={2} />
          <Text variant="body">
            {t('Businesses:MIGRATION.MIGRATION_IN_PROGRESS')}.
          </Text>
          <Spacer spacing={1} />
          <EntitiesStatusLog entitiesStatus={exportEntitiesStatus} />
        </Grid>
      )}
      {step === DialogStep.DONE && (
        <Grid container alignItems="center" direction="column" py={4}>
          <SuccessAlert className={classes.successIcon} />
          <Spacer spacing={2} />
          <Text variant="body">
            {t('Businesses:MIGRATION.MIGRATION_IS_COMPLETE')}
          </Text>
          <Spacer spacing={1} />
          <EntitiesStatusLog entitiesStatus={exportEntitiesStatus} />
        </Grid>
      )}
    </PuiDialog>
  )
}

export default PracticeCloningImportDialog
