import React from 'react'
import { SignatureDialog } from '@pbt/pbt-ui-components'
import { SignatureDialogProps } from '@pbt/pbt-ui-components/src/components/signature/SignatureDialog'

import DoctorSignature, {
  DoctorSignatureProps,
} from './signature/DoctorSignature'

interface DoctorSignatureDialogProps
  extends SignatureDialogProps,
    DoctorSignatureProps {}

const DoctorSignatureDialog = (props: DoctorSignatureDialogProps) => (
  <SignatureDialog SignatureComponent={DoctorSignature} {...props} />
)

export default DoctorSignatureDialog
