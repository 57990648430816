export const CREATE_CLIENT = 'clients/CREATE_CLIENT'
export const CREATE_CLIENT_FAILURE = 'clients/CREATE_CLIENT_FAILURE'
export const CREATE_CLIENT_SUCCESS = 'clients/CREATE_CLIENT_SUCCESS'
export const CLIENT_PATIENT_CREATED = 'clients/CLIENT_PATIENT_CREATED'

export const FIND_CLIENTS = 'clients/FIND_CLIENTS'
export const FIND_CLIENTS_SUCCESS = 'clients/FIND_CLIENTS_SUCCESS'
export const FIND_CLIENTS_FAILURE = 'clients/FIND_CLIENTS_FAILURE'

export const FETCH_CLIENT = 'clients/FETCH_CLIENT'
export const FETCH_CLIENT_FAILURE = 'clients/FETCH_CLIENT_FAILURE'
export const FETCH_CLIENT_SUCCESS = 'clients/FETCH_CLIENT_SUCCESS'

export const FETCH_CLIENTS_LIST = 'clients/FETCH_CLIENTS_LIST'
export const FETCH_CLIENTS_LIST_FAILURE = 'clients/FETCH_CLIENTS_LIST_FAILURE'
export const FETCH_CLIENTS_LIST_SUCCESS = 'clients/FETCH_CLIENTS_LIST_SUCCESS'

export const FETCH_MORE_ITEMS_FOR_CLIENTS_LIST =
  'clients/FETCH_MORE_ITEMS_FOR_CLIENTS_LIST'
export const FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_SUCCESS =
  'clients/FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_SUCCESS'
export const FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_FAILURE =
  'clients/FETCH_MORE_ITEMS_FOR_CLIENTS_LIST_FAILURE'

export const UPDATE_CLIENT = 'clients/UPDATE_CLIENT'
export const UPDATE_CLIENT_FAILURE = 'clients/UPDATE_CLIENT_FAILURE'
export const UPDATE_CLIENT_SUCCESS = 'clients/UPDATE_CLIENT_SUCCESS'

export const PARTIAL_UPDATE_CLIENT = 'users/PARTIAL_UPDATE_CLIENT'

export const UPDATE_CLIENT_ACTIVITY_STATE =
  'clients/UPDATE_CLIENT_ACTIVITY_STATE'
export const UPDATE_CLIENT_ACTIVITY_STATE_SUCCESS =
  'clients/UPDATE_CLIENT_ACTIVITY_STATE_SUCCESS'
export const UPDATE_CLIENT_ACTIVITY_STATE_FAILURE =
  'clients/UPDATE_CLIENT_ACTIVITY_STATE_FAILURE'

export const CLEAR_CURRENT_CLIENT_INFO = 'clients/CLEAR_CURRENT_CLIENT_INFO'

export const FETCH_CLIENTS_MEMBERSHIP_FILTERS =
  'clients/FETCH_CLIENTS_MEMBERSHIP_FILTERS'
export const FETCH_CLIENTS_MEMBERSHIP_FILTERS_SUCCESS =
  'clients/FETCH_CLIENTS_MEMBERSHIP_FILTERS_SUCCESS'
export const FETCH_CLIENTS_MEMBERSHIP_FILTERS_FAILURE =
  'clients/FETCH_CLIENTS_MEMBERSHIP_FILTERS_FAILURE'

export const VALIDATE_CLIENT_EMAIL = 'clients/VALIDATE_CLIENT_EMAIL'
export const VALIDATE_CLIENT_EMAIL_SUCCESS =
  'clients/VALIDATE_CLIENT_EMAIL_SUCCESS'
export const VALIDATE_CLIENT_EMAIL_FAILURE =
  'clients/VALIDATE_CLIENT_EMAIL_FAILURE'

export const SEND_CLIENT_EMAIL_VERIFICATION =
  'clients/SEND_CLIENT_EMAIL_VERIFICATION'
export const SEND_CLIENT_EMAIL_VERIFICATION_FAILURE =
  'clients/SEND_CLIENT_EMAIL_VERIFICATION_FAILURE'
export const SEND_CLIENT_EMAIL_VERIFICATION_SUCCESS =
  'clients/SEND_CLIENT_EMAIL_VERIFICATION_SUCCESS'

export const CLEAR_CLIENTS_ERROR = 'clients/CLEAR_CLIENTS_ERROR'
