import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import { AddButton, PermissionArea, User, Utils } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  createTeamMemberLicense,
  deleteTeamMemberLicense,
  editTeamMemberLicense,
} from '~/store/actions/members'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getMembersIsUpdating } from '~/store/reducers/members'
import { TeamMemberLicense } from '~/types'
import useDialog from '~/utils/useDialog'

import LicensesSectionTable from '../LicensesSectionTable'

interface MemberLicensesSectionProps {
  onLicenseChange: () => void
  teamMember: User
}

const MemberLicensesSection = ({
  teamMember,
  onLicenseChange = R.F,
}: MemberLicensesSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const [openLicenseDialog] = useDialog(DialogNames.LICENSE)

  const personPermissions = useSelector(getCRUDByArea(PermissionArea.PERSON))
  const isUpdating = useSelector(getMembersIsUpdating)

  const onCreateLicense = (newLicense: TeamMemberLicense) => {
    dispatch(createTeamMemberLicense(teamMember.id, newLicense))
    onLicenseChange()
  }

  const onEditLicense = (newLicense: TeamMemberLicense) => {
    dispatch(editTeamMemberLicense(teamMember.id, newLicense))
    onLicenseChange()
  }

  const onLicenseDeleteRequested = (licenseId: string | number) => {
    dispatch(deleteTeamMemberLicense(teamMember.id, licenseId as string))
    onLicenseChange()
  }

  const onAddLicenseRequested = () => {
    openLicenseDialog({
      getIsLoading: getMembersIsUpdating,
      onCreateLicense,
      onEditLicense,
    })
  }

  const onLicenseEditRequested = (licenseId: string) => {
    openLicenseDialog({
      getIsLoading: getMembersIsUpdating,
      license: Utils.findById(licenseId, teamMember.licenses || []),
      onCreateLicense,
      onEditLicense,
    })
  }

  return (
    <Grid container item>
      <LicensesSectionTable
        deleteDisabled={!personPermissions.update}
        editDisabled={!personPermissions.update}
        isDeleting={isUpdating}
        licenses={teamMember.licenses}
        useIndexAsId={false}
        onDelete={onLicenseDeleteRequested}
        onEdit={onLicenseEditRequested}
      />
      {personPermissions.update && (
        <Grid item mt={2}>
          <AddButton
            addText={t('Admin:MEMBER.LICENSE.ADD_LICENSE_ACTION')}
            onAdd={onAddLicenseRequested}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default MemberLicensesSection
