import { Utils } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import { TaskTypesType } from '~/types'

import { TimelineEntryType } from './timelineConstants'

export const TaskState = {
  OPEN: 'Open',
  OVERDUE: 'Overdue',
  DONE: 'Done',
}

export const RecurrenceOptions = {
  DATE_TIME: i18n.t('Constants:TASK_CONSTANTS.DATE_TIME'),
  OCCURRENCES: i18n.t('Constants:TASK_CONSTANTS.OCCURRENCE_ONE_OR_OTHER'),
}

export const CustomDatePrepositions = {
  BEFORE: 'Before',
  AFTER: 'After',
}

export const CustomDatePostfixes = {
  START: 'Start',
  END: 'End',
}

export const CustomDatePrepositionsList = [
  {
    id: CustomDatePrepositions.BEFORE,
    name: CustomDatePrepositions.BEFORE,
    nameTranslation: i18n.t('Time:LABEL.BEFORE'),
  },
  {
    id: CustomDatePrepositions.AFTER,
    name: CustomDatePrepositions.AFTER,
    nameTranslation: i18n.t('Time:LABEL.AFTER'),
  },
]
export const CustomDatePostfixesList = [
  {
    id: CustomDatePostfixes.START,
    name: CustomDatePostfixes.START,
    nameTranslation: i18n.t('Tasks:LABEL.START_ACTION'),
  },
  {
    id: CustomDatePostfixes.END,
    name: CustomDatePostfixes.END,
    nameTranslation: i18n.t('Tasks:LABEL.END_ACTION'),
  },
]

export const RepeatMode = {
  DOES_NOT_REPEAT: i18n.t('Constants:TASK_CONSTANTS.DOES_NOT_REPEAT'),
  REPEAT: i18n.t('Common:REPEAT_ACTION'),
}

export const RepeatModeList = Utils.enumToSelectableList(RepeatMode)

export enum TaskContext {
  SOAP = 'SOAP',
  WHITEBOARD = 'WHITEBOARD',
}

export const RX_REQUEST_TASK_TYPE: TaskTypesType = 'Rx Request'
export const VET_DIET_REQUEST_TASK_TYPE: TaskTypesType = 'Vet Diet Request'

export const FullScreenTasks: TaskTypesType[] = [
  RX_REQUEST_TASK_TYPE,
  VET_DIET_REQUEST_TASK_TYPE,
]

export const UncreatableTasks: TaskTypesType[] = [
  RX_REQUEST_TASK_TYPE,
  VET_DIET_REQUEST_TASK_TYPE,
]

export const RxRequestPrimaryFilters = [
  TimelineEntryType.DIAGNOSIS,
  TimelineEntryType.PRESCRIPTION,
  TimelineEntryType.LAB_TEST,
]
