import axios from 'axios'

import { ENVIRONMENT_VARIABLES } from '~/constants/environmentVariables'

import { getEnvironment } from '.'

const API = Symbol('API')
const searchEngine = Symbol('searchEngine')

const getPlaceTimezone = ({ lat, lng }) =>
  axios
    .get('https://maps.googleapis.com/maps/api/timezone/json', {
      params: {
        location: `${lat},${lng}`,
        timestamp: new Date().getTime() / 1000,
        key: ENVIRONMENT_VARIABLES[getEnvironment()].GOOGLE_API_KEY,
      },
    })
    .then(({ data }) => data)

const getPlaceAPIDetails = (searchEngine, { placeId, type = '' }) =>
  new Promise((resolve, reject) => {
    searchEngine.getDetails(
      {
        placeId,
        fields: [
          'name',
          'geometry',
          'formatted_address',
          'website',
          'address_component',
          'international_phone_number',
          'icon',
          'place_id',
        ],
        type,
      },
      (place, status) => {
        if (status === 'OK') {
          resolve(place)
        } else {
          reject()
        }
      },
    )
  })

class GoogleMapHelper {
  constructor(api, map) {
    this[API] = api
    this[searchEngine] = new this[API].places.PlacesService(map)
  }

  destroy() {
    this[API] = undefined
    this[searchEngine] = undefined
  }

  searchPlaces({ query, area, type = '', fallback = false }) {
    if (!this[searchEngine]) {
      return Promise.reject()
    }
    const request = {
      [area ? 'keyword' : 'query']: query,
      fields: ['name', 'geometry', 'formatted_address'],
      type,
      language: 'en',
    }
    if (area) {
      request.bounds = new this[API].LatLngBounds(area.sw, area.ne)
    }
    return new Promise((resolve, reject) => {
      this[searchEngine][area ? 'nearbySearch' : 'textSearch'](
        request,
        (points) => {
          if (!this[searchEngine]) {
            reject()
          }
          if (fallback && area && (!points || !points.length)) {
            this.searchPlaces({ query, type }).then(resolve, reject)
          } else {
            resolve(points || [])
          }
        },
      )
    })
  }

  getPlaceDetails(params) {
    if (!this[searchEngine]) {
      return Promise.reject()
    }
    return Promise.all([
      getPlaceAPIDetails(this[searchEngine], params),
      getPlaceTimezone(params),
    ])
  }
}

export default GoogleMapHelper
export const SearchTypes = {
  VETERINARY_CARE: 'veterinary_care',
}
