import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronDownIcon from '@mui/icons-material/ExpandMore'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      width: 24,
      height: 24,
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      borderRadius: 4,
    },
    icon: {
      color: theme.colors.lowAccentText,
    },
  }),
  { name: 'ExpandButton' },
)

export interface ExpandButtonProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  disabled?: boolean
  expanded?: boolean
  onClick?: (expanded: boolean) => void
}

const ExpandButton = ({
  disabled,
  className,
  classes: classesProp,
  expanded = false,
  onClick,
}: ExpandButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  const Icon = expanded ? ChevronDownIcon : ChevronRightIcon

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(!expanded)
      e.stopPropagation()
    }
  }

  return (
    <IconButton
      className={classNames(className, classes.button)}
      disabled={disabled}
      onClick={handleClick}
    >
      <Icon className={classes.icon} />
    </IconButton>
  )
}

export default ExpandButton
