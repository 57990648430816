import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchSoapFiles } from '~/store/actions/soap'
import { useLockableComponent } from '~/store/hooks/soap'
import {
  getAppointmentId,
  getSoapBusinessId,
  getSoapId,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import AttachmentsWidgetLocked from './AttachmentsWidgetLocked'
import AttachmentsWidgetUnlocked from './AttachmentsWidgetUnlocked'

const AttachmentsWidget = () => {
  const dispatch = useDispatch()

  const soapId = useSelector(getSoapId)
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const soapBusinessId = useSelector(getSoapBusinessId)

  const AttachmentsWidgetComponent = useLockableComponent(
    AttachmentsWidgetUnlocked,
    AttachmentsWidgetLocked,
  )

  useEffectExceptOnMount(
    () => {
      if (appointment?.type?.id && soapId) {
        dispatch(fetchSoapFiles(soapId, soapBusinessId))
      }
    },
    [appointment?.type?.id],
    true,
  )

  return <AttachmentsWidgetComponent />
}

export default AttachmentsWidget
