import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Nil } from '@pbt/pbt-ui-components'

import { GlobalInventoryCatalogItem } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import {
  FETCH_GLOBAL_ITEM,
  FETCH_GLOBAL_ITEM_FAILURE,
  FETCH_GLOBAL_ITEM_SUCCESS,
  FETCH_GLOBAL_LIST,
  FETCH_GLOBAL_LIST_FAILURE,
  FETCH_GLOBAL_LIST_SUCCESS,
  FETCH_MORE_GLOBAL_LIST,
  FETCH_MORE_GLOBAL_LIST_FAILURE,
  FETCH_MORE_GLOBAL_LIST_SUCCESS,
  UPDATE_GLOBAL_ITEMS,
} from '../actions/types/globaInventoryCatalog'
import type { RootState } from '../index'

export type GlobalInventoryCatalogState = {
  error: string | null
  isItemLoading: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, GlobalInventoryCatalogItem>
  totalCount: number
}

export const INITIAL_STATE: GlobalInventoryCatalogState = {
  map: {},
  list: [],
  error: null,
  totalCount: 0,
  isLoading: false,
  isItemLoading: false,
}

const globalInventoryCatalog = (
  state: GlobalInventoryCatalogState = INITIAL_STATE,
  action: AnyAction,
): GlobalInventoryCatalogState => {
  switch (action.type) {
    case UPDATE_GLOBAL_ITEMS:
      return { ...state, map: secondLevelMerge(state.map, action.items) }
    case FETCH_GLOBAL_LIST:
      return { ...state, isLoading: true, list: [], totalCount: 0 }
    case FETCH_GLOBAL_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_GLOBAL_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        totalCount: action.totalCount,
        list: action.list,
      }
    case FETCH_MORE_GLOBAL_LIST:
      return { ...state, isLoading: true }
    case FETCH_MORE_GLOBAL_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_MORE_GLOBAL_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case FETCH_GLOBAL_ITEM:
      return {
        ...state,
        isItemLoading: true,
      }
    case FETCH_GLOBAL_ITEM_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isItemLoading: false,
      }
    case FETCH_GLOBAL_ITEM_SUCCESS:
      return {
        ...state,
        isItemLoading: false,
      }
    default:
      return state
  }
}

export default globalInventoryCatalog
export const getGlobalInventoryCatalog = (
  state: RootState,
): GlobalInventoryCatalogState => state.globalInventoryCatalog
export const getGlobalInventoryMap = (state: RootState) =>
  getGlobalInventoryCatalog(state).map
export const getGlobalInventoryList = (state: RootState) =>
  getGlobalInventoryCatalog(state).list
export const getGlobalInventoryListTotalCount = (state: RootState) =>
  getGlobalInventoryCatalog(state).totalCount
export const getIsLoading = (state: RootState) =>
  getGlobalInventoryCatalog(state).isLoading
export const getIsItemLoading = (state: RootState) =>
  getGlobalInventoryCatalog(state).isItemLoading
export const getGlobalInventoryItem = (id: string | Nil) =>
  createSelector(getGlobalInventoryMap, (map) => (id ? map[id] : undefined))
