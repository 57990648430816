import { getAPIPath, request } from './core'

export const generatePDMPReport = (
  businessId: string,
  startDate: string,
  endDate: string,
  format: string,
) =>
  request(getAPIPath('inventory/pdmp/reports'), {
    method: 'POST',
    data: {
      reportFormat: format,
      fromDate: startDate,
      toDate: endDate,
    },
    params: { businessId },
  })
