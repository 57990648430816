import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AnyAction } from 'redux'
import {
  Calendar,
  CustomFieldValidatorState,
  DateUtils,
  moment,
  PermissionArea,
  PuiSelect,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { OrderedWeekConstants } from '@pbt/pbt-ui-components/src/localization'

import WeekdaysButtonGroup from '~/components/common/buttons/WeekdaysButtonGroup'
import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import TimeSelector from '~/components/common/inputs/time-selector/TimeSelector'
import UserSelect from '~/components/common/inputs/UserSelect'
import PuiSwitch from '~/components/common/PuiSwitch'
import TimezoneWarningLabel from '~/components/dashboard/alerts/TimezoneWarningLabel'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import RecurrenceType from '~/constants/recurrenceType'
import {
  clearAvailabilityRuleValidationError,
  createAvailabilityRule,
  deleteAvailabilityRule,
  editAvailabilityRule,
} from '~/store/actions/availabilityRules'
import {
  getAssignedLocations,
  getCRUDByArea,
  getCurrentBusinessId,
} from '~/store/reducers/auth'
import {
  getAvailabilityRuleIsLoading,
  getAvailabilityRuleValidationError,
} from '~/store/reducers/availabilityRules'
import { getBusinessesMap } from '~/store/reducers/businesses'
import {
  getAvailabilityRuleRecurrenceType,
  getAvailabilityRuleType,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getUser, getUsersMap } from '~/store/reducers/users'
import {
  AvailabilityRule,
  DataHandleWithUnsavedChanges,
  TeamFilter,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import { serializeTimeTo24hStr } from '~/utils/time'
import useDialog from '~/utils/useDialog'
import useFieldsChanged from '~/utils/useFieldsChanged'

import { getShortLocaleDaysString } from './availabilityRulesUtils'

const useStyles = makeStyles(
  () => ({
    radioLabel: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'AvailabilityRuleComponent' },
)

export interface AvailabilityRuleProps {
  onChange?: () => void
  onOk: () => void
  rule?: AvailabilityRule
}

export interface AvailabilityRuleHandle extends DataHandleWithUnsavedChanges {
  createRule: () => void
  deleteRule: () => void
  updateRule: () => void
}

const AvailabilityRuleComponent = forwardRef<
  AvailabilityRuleHandle,
  AvailabilityRuleProps
>(function AvailabilityRuleComponent(
  {
    rule = {
      person: {},
      business: {},
      type: {},
      recurrenceType: {},
    } as AvailabilityRule,
    onOk,
    onChange = () => {},
  },
  ref,
) {
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const { t } = useTranslation(['Admin', 'Common'])

  const AvailabilityRuleTypeLabel = {
    TEAM_MEMBER: t('Admin:SCHEDULING.AVAILABILITY_RULE.TEAM_MEMBER_LABEL'),
    PRACTICE: t('Common:PRACTICE_ONE'),
  }

  const users = useSelector(getUsersMap)
  const businessesMap = useSelector(getBusinessesMap)
  const assignedLocations = useSelector(getAssignedLocations)
  const isLoading = useSelector(getAvailabilityRuleIsLoading)
  const AvailabilityRuleType = useSelector(getAvailabilityRuleType)
  const AvailabilityRuleRecurrenceType = useSelector(
    getAvailabilityRuleRecurrenceType,
  )
  const validationError = useSelector(getAvailabilityRuleValidationError)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const permissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_AVAILABILITY),
  )
  const isTeamsPhase2Enabled = useSelector(
    getFeatureToggle(FeatureToggle.TEAMS_PHASE_2),
  )

  const [openValidationErrorDialog] = useDialog(DialogNames.VALIDATION_ERROR)

  const OnceRecurrence = Utils.findConstantIdByName(
    'Once',
    AvailabilityRuleRecurrenceType,
  )
  const DailyRecurrence = Utils.findConstantIdByName(
    'Daily',
    AvailabilityRuleRecurrenceType,
  )
  const SingleRecurrence = OnceRecurrence || DailyRecurrence
  const WeeklyRecurrence = Utils.findConstantIdByName(
    'Weekly',
    AvailabilityRuleRecurrenceType,
  )

  const isPracticeProp =
    rule.type.id ===
    Utils.findConstantIdByName('Hours of operation', AvailabilityRuleType)
  const repeatsWeeklyProp = rule.recurrenceType.id
    ? rule.recurrenceType.id === WeeklyRecurrence
    : true

  const [isPractice, setIsPractice] = useState(isPracticeProp)
  const [repeatsWeekly, setRepeatsWeekly] = useState(repeatsWeeklyProp)
  const [neverEnds, setNeverEnds] = useState(!rule.endDate)
  const [closeAfterCreation, setCloseAfterCreation] = useState(false)
  const [successAction, setSuccessAction] = useState<
    ((rule: AvailabilityRule) => AnyAction) | null
  >(null)
  const [pendingAvailabilityRule, setPendingAvailabilityRule] =
    useState<AvailabilityRule | null>(null)

  const endTimeValidator = ({
    state: { scheduledEndTime, scheduledStartTime, startDate, endDate },
  }: CustomFieldValidatorState) =>
    neverEnds || moment(endDate).isSame(startDate, 'd')
      ? moment(scheduledEndTime).isAfter(scheduledStartTime, 'm')
      : true

  const endDateValidator = ({
    state: { endDate, startDate },
  }: CustomFieldValidatorState) => moment(endDate).isSameOrAfter(startDate, 'd')

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'name',
        label: t('Admin:SCHEDULING.AVAILABILITY_RULE.FIELD_LABEL_RULE_NAME'),
        validators: ['required'],
        initialValue: rule.name || '',
      },
      {
        name: 'teamMember',
        label: t('Common:TEAM_MEMBER'),
        validators: isPractice ? [] : ['required'],
        initialValue: rule.person?.id || '',
      },
      {
        name: 'practice',
        label: t('Common:PRACTICE_ONE'),
        validators: ['required'],
        initialValue: rule.business?.id || currentBusinessId,
      },
      {
        name: 'days',
        initialValue: rule.recurrenceParams?.days || [],
      },
      {
        name: 'scheduledStartTime',
        label: t(
          'Admin:SCHEDULING.AVAILABILITY_RULE.FIELD_LABEL_SCHEDULED_START_TIME',
        ),
        type: 'none',
        validators: ['required', 'timestamp'],
        initialValue: moment(
          rule.scheduledStartTime || '09:00',
          'HH:mm',
          rule.timezone,
        ).toISOString(),
      },
      {
        name: 'scheduledEndTime',
        label: t(
          'Admin:SCHEDULING.AVAILABILITY_RULE.FIELD_LABEL_SCHEDULED_END_TIME',
        ),
        type: 'none',
        validators: [
          'required',
          'timestamp',
          { validator: endTimeValidator, validatorName: 'endTimeValidator' },
        ],
        messages: {
          endTimeValidator: t(
            'Validations:END_TIME_SHOULD_BE_AFTER_START_TIME',
          ),
        },
        initialValue: moment(
          rule.scheduledEndTime || '17:00',
          'HH:mm',
          rule.timezone,
        ).toISOString(),
      },
      {
        name: 'startDate',
        label: repeatsWeekly
          ? t('Admin:SCHEDULING.AVAILABILITY_RULE.FIELD_LABEL_RULE_START_DATE')
          : t('Common:DATE_TIME'),
        validators: ['required'],
        initialValue: rule.startDate || moment().startOf('day').toISOString(),
      },
      {
        name: 'endDate',
        label: t(
          'Admin:SCHEDULING.AVAILABILITY_RULE.FIELD_LABEL_RULE_END_DATE',
        ),
        validators: neverEnds
          ? []
          : [
              'required',
              {
                validator: endDateValidator,
                validatorName: 'endDateValidator',
              },
            ],
        initialValue: rule.endDate || '',
        messages: {
          endDateValidator: t(
            'Validations:END_DATE_SHOULD_BE_SAME_OR_AFTER_START_DATE',
          ),
        },
      },
      {
        name: 'notes',
        label: t('Common:NOTES'),
        initialValue: rule.notes || '',
      },
      {
        name: 'shownInKiosk',
        label: t('Common:SHOW_IN_KIOSK'),
        initialValue: rule.shownInKiosk,
        type: 'toggle',
      },
    ],
    false,
  )

  useFieldsChanged(() => {
    onChange()
  }, fields)

  const {
    name,
    teamMember,
    practice,
    days,
    scheduledStartTime,
    scheduledEndTime,
    startDate,
    endDate,
    notes,
    shownInKiosk,
  } = fields

  const selectedTeamMember = useSelector(getUser(teamMember.value))

  const handleNavigate = () => {
    if (onOk) {
      onOk()
    }
  }

  const proceed = () => {
    if (pendingAvailabilityRule) {
      pendingAvailabilityRule.bypassValidation = true
    }
    setCloseAfterCreation(true)
    if (successAction && pendingAvailabilityRule) {
      dispatch(successAction(pendingAvailabilityRule))
    }
    setPendingAvailabilityRule(null)
    setSuccessAction(null)
  }

  const validateOnServer = (
    availabilityRule: AvailabilityRule,
    action: (rule: AvailabilityRule) => AnyAction,
  ) => {
    setSuccessAction(() => action)
    dispatch(action(availabilityRule))
  }

  const getNewRulePerson = () => (isPractice ? null : teamMember.value)
  const getNewRuleRecurrenceParams = () =>
    repeatsWeekly
      ? {
          days:
            days.value.length > 0 ? days.value : R.values(OrderedWeekConstants),
        }
      : null

  const getRepeatsAndNeverEnds = () => repeatsWeekly && neverEnds
  const getRepeatsAndAlwaysEnds = () => repeatsWeekly && !neverEnds

  const getRule = () => {
    const getNewRuleEndDate = R.cond([
      [getRepeatsAndNeverEnds, R.always(null)],
      [
        getRepeatsAndAlwaysEnds,
        R.always(DateUtils.serializeDate(endDate.value)),
      ],
      [R.T, R.always(DateUtils.serializeDate(startDate.value))],
    ])

    const getNewRuleRecurrenceType = () =>
      repeatsWeekly ? WeeklyRecurrence : SingleRecurrence

    const getNewRuleType = () =>
      isPractice
        ? Utils.findConstantIdByName('Hours of operation', AvailabilityRuleType)
        : Utils.findConstantIdByName(
            'Staff working hours',
            AvailabilityRuleType,
          )

    const newRule: AvailabilityRule = {
      id: rule.id as string,
      name: name.value,
      notes: notes.value,
      business: practice.value,
      scheduledStartTime: serializeTimeTo24hStr(
        scheduledStartTime.value,
      ) as string,
      scheduledEndTime: serializeTimeTo24hStr(scheduledEndTime.value) as string,
      startDate: DateUtils.serializeDate(startDate.value) as string,
      person: getNewRulePerson(),
      recurrenceParams:
        getNewRuleRecurrenceParams() as AvailabilityRule['recurrenceParams'],
      endDate: getNewRuleEndDate() as string,
      recurrenceType: getNewRuleRecurrenceType(),
      type: getNewRuleType(),
      shownInKiosk: shownInKiosk.value,
    }

    return newRule
  }

  const updateRule = () => {
    if (validate()) {
      const newRule = getRule()
      setPendingAvailabilityRule(newRule)
      validateOnServer(newRule, editAvailabilityRule)
    }
  }

  const createRule = () => {
    if (validate()) {
      const { id, ...newRule } = getRule()
      setCloseAfterCreation(true)
      dispatch(createAvailabilityRule(newRule))
    }
  }

  const deleteRule = () => {
    setPendingAvailabilityRule(rule)
    validateOnServer(rule, deleteAvailabilityRule)
  }

  useImperativeHandle(ref, () => ({
    validate,
    get: getRule,
    deleteRule,
    updateRule,
    createRule,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
  }))

  useEffect(
    () => () => {
      dispatch(clearAvailabilityRuleValidationError())
    },
    [],
  )

  useEffect(() => {
    if (validationError) {
      openValidationErrorDialog({
        error: validationError,
        proceedButtonActionName:
          successAction === deleteAvailabilityRule
            ? t('Common:DELETE_ACTION')
            : t('Common:EDIT_ACTION'),
        onBack: () => {
          dispatch(clearAvailabilityRuleValidationError())
          setSuccessAction(null)
        },
        onProceed: proceed,
      })
    }
  }, [validationError])

  useEffect(() => {
    if (!rule.id) {
      const nameString = isPractice
        ? R.path([practice.value, 'name'], businessesMap) || ''
        : Utils.getPersonString(users[teamMember.value])
      const daysString = repeatsWeekly
        ? getShortLocaleDaysString(days.value)
        : (startDate.value && DateUtils.formatDate(startDate.value)) || ''

      name.setValue(
        nameString && daysString ? `${nameString} | ${daysString}` : '',
      )
    }
  }, [
    teamMember.value,
    practice.value,
    days.value,
    startDate.value,
    isPractice,
    repeatsWeekly,
    users,
    businessesMap,
  ])

  useEffect(() => {
    if (rule.name) {
      setIsPractice(isPracticeProp)
      setRepeatsWeekly(repeatsWeeklyProp)
      setNeverEnds(!rule.endDate)
      reset()
    }
  }, [rule])

  useEffect(() => {
    if (
      ((successAction && !validationError) || closeAfterCreation) &&
      !isLoading
    ) {
      setCloseAfterCreation(false)
      handleNavigate()
    }
  }, [closeAfterCreation, isLoading, validationError])

  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item>
        <Text strong variant="body2">
          {t('Common:TYPE_ONE')}:
        </Text>
      </Grid>
      <Grid
        container
        item
        columnSpacing={3}
        direction={isMobile ? 'column' : 'row'}
        my={1}
      >
        <Grid item>
          <FormControlLabel
            classes={{
              label: classes.radioLabel,
            }}
            control={
              <Radio
                checked={!isPractice}
                disabled={!permissions.update}
                id="team-member"
                name="type"
                onChange={() => setIsPractice(false)}
              />
            }
            label={AvailabilityRuleTypeLabel.TEAM_MEMBER}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            classes={{
              label: classes.radioLabel,
            }}
            control={
              <Radio
                checked={isPractice}
                disabled={!permissions.update}
                id="practice"
                name="type"
                onChange={() => setIsPractice(true)}
              />
            }
            label={AvailabilityRuleTypeLabel.PRACTICE}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center">
        {!isPractice && (
          <>
            <Grid item xs>
              <FormControl fullWidth>
                <UserSelect
                  plainInput
                  preload
                  disabled={!permissions.update}
                  field={teamMember}
                  label={`${teamMember.label}*`}
                  preloadUser={
                    !R.isEmpty(rule?.person) ? rule?.person : undefined
                  }
                  teamFilter={isTeamsPhase2Enabled ? TeamFilter.ALL : undefined}
                />
              </FormControl>
            </Grid>
            <Grid item pt={2} px={2}>
              <Text strong align="center" variant="body2">
                {t('Common:TEAM_MEMBER_AT_PRACTICE')}
              </Text>
            </Grid>
          </>
        )}
        <Grid item xs>
          <FormControl fullWidth>
            <InputLabel htmlFor="practice-select">{practice.label}*</InputLabel>
            <PuiSelect
              fullWidth
              disabled={!permissions.update}
              field={practice}
              inputProps={{
                id: 'practice-select',
              }}
              items={assignedLocations}
            />
          </FormControl>
        </Grid>
      </Grid>
      {selectedTeamMember?.team && isTeamsPhase2Enabled && (
        <Grid container item alignItems="center">
          <Grid item xs>
            <PuiSwitch
              field={shownInKiosk}
              label={
                <TextWithTooltip
                  strong
                  tooltipText={
                    <Grid item maxWidth={220}>
                      {t('Common:SHOW_IN_KIOSK_TOOLTIP')}
                    </Grid>
                  }
                  variant="subheading3"
                >
                  {shownInKiosk.label}
                </TextWithTooltip>
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid item mt={{ xs: 4, md: 6 }}>
        <Text strong variant="body2">
          {t('Common:REPEATS')}:
        </Text>
      </Grid>
      <Grid container item columnSpacing={3} my={1}>
        <Grid item>
          <FormControlLabel
            classes={{
              label: classes.radioLabel,
            }}
            control={
              <Radio
                checked={repeatsWeekly}
                disabled={!permissions.update}
                id={RecurrenceType.WEEKLY}
                name="type"
                onChange={() => setRepeatsWeekly(true)}
              />
            }
            label={RecurrenceType.WEEKLY}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            classes={{
              label: classes.radioLabel,
            }}
            control={
              <Radio
                checked={!repeatsWeekly}
                disabled={!permissions.update}
                id={RecurrenceType.ONCE}
                name="type"
                onChange={() => setRepeatsWeekly(false)}
              />
            }
            label={RecurrenceType.ONCE}
          />
        </Grid>
      </Grid>
      {repeatsWeekly && (
        <Grid item mb={2}>
          <WeekdaysButtonGroup disabled={!permissions.update} field={days} />
        </Grid>
      )}
      {!repeatsWeekly && (
        <Grid item xs mb={2} md={6}>
          <Calendar
            fullWidth
            disabled={!permissions.update}
            field={startDate}
            label={`${startDate.label}*`}
          />
        </Grid>
      )}
      <Grid
        container
        item
        alignItems="center"
        columnSpacing={3}
        mb={2}
        wrap="nowrap"
      >
        <Grid item justifyContent="center">
          <TimezoneWarningLabel />
        </Grid>
        <Grid item md>
          <TimeSelector
            fullWidth
            disabled={!permissions.update}
            field={scheduledStartTime}
            fromLabel={null}
            label={`${scheduledStartTime.label}*`}
            startValue={scheduledStartTime.value}
            onStartChange={scheduledStartTime.setValue}
          />
        </Grid>
        <Grid item md>
          <TimeSelector
            fullWidth
            disabled={!permissions.update}
            field={scheduledEndTime}
            fromLabel={null}
            label={`${scheduledEndTime.label}*`}
            startValue={scheduledEndTime.value}
            onStartChange={scheduledEndTime.setValue}
          />
        </Grid>
      </Grid>
      {repeatsWeekly && (
        <Grid
          container
          item
          alignItems={isMobile ? 'stretch' : 'flex-end'}
          columnSpacing={3}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid item md={5}>
            <Calendar
              fullWidth
              disabled={!permissions.update}
              field={startDate}
              label={`${startDate.label}*`}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              classes={{
                label: classes.radioLabel,
              }}
              control={
                <Radio
                  checked={neverEnds}
                  disabled={!permissions.update}
                  id="never-ends"
                  name="end-date"
                  onChange={() => setNeverEnds(true)}
                />
              }
              label={t('Common:NEVER_ENDS')}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              classes={{
                label: classes.radioLabel,
              }}
              control={
                <Radio
                  checked={!neverEnds}
                  disabled={!permissions.update}
                  id="ends-on-date"
                  name="end-date"
                  onChange={() => setNeverEnds(false)}
                />
              }
              label={t('Common:ENDS_ON_DATE')}
            />
          </Grid>
          {!neverEnds && (
            <>
              <Grid item md={6} />
              <Grid item md={6}>
                <Calendar
                  fullWidth
                  disabled={!permissions.update}
                  field={endDate}
                  label={`${endDate.label}*`}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      <Grid item mt={2}>
        <InputLabel htmlFor="notes-input">
          <Text strong variant="body2">
            {notes.label}:
          </Text>
        </InputLabel>
        <PuiTextField
          multiline
          disabled={!permissions.update}
          field={notes}
          id="notes-input"
          inputProps={{ maxLength: 1000 }}
          minRows={3}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={!isMobile}>
        <PuiTextField
          disabled={!permissions.update}
          field={name}
          inputProps={{ maxLength: 100 }}
          label={`${name.label}*`}
        />
      </Grid>
      <Grid item>
        <RequiredFieldsNotice />
      </Grid>
    </Grid>
  )
})

export default AvailabilityRuleComponent
