import {
  CLOSE_RECOMMENDED_BROWSERS_DIALOG,
  SHOW_RECOMMENDED_BROWSERS_DIALOG,
} from './types/loginSessionSettings'

export const closeRecommendedBrowsersDialog = () => ({
  type: CLOSE_RECOMMENDED_BROWSERS_DIALOG,
})
export const showRecommendedBrowsersDialog = () => ({
  type: SHOW_RECOMMENDED_BROWSERS_DIALOG,
})
