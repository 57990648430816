import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateFormat,
  DateUtils,
  moment,
  PuiCloseButton,
  TextInteractive,
} from '@pbt/pbt-ui-components'

import {
  changeNotificationIsRead,
  deleteNotification,
} from '~/store/actions/notifications'
import { Notification } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    notificationCircleContainer: {
      width: 14,
    },
    notificationCircle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      marginRight: theme.spacing(2),
      cursor: 'pointer',
      '&:hover': {
        boxShadow: ` 0 0 0 1px #FFFFFF, 0 0 0 3px ${theme.colors.filterBorderColor}`,
      },
    },
    notificationUnreadCircle: {
      backgroundColor: theme.colors.important,
    },
    closeIconButton: {
      position: 'static',
      paddingRight: 0,
    },
    message: {
      width: 65,
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
    },
    bold: {
      fontWeight: 500,
    },
  }),
  { name: 'ActionsWithTimestampColumn' },
)

const formatNotificationDate = (date: string) => {
  const dateMoment = moment(date)

  return dateMoment.isBetween(moment().startOf('day'), moment().endOf('day'))
    ? DateUtils.formatTime(date)
    : dateMoment.format(DateFormat.MONTH_AND_DAY)
}

export interface ActionsWithTimestampColumnProps {
  notification: Notification
}

const ActionsWithTimestampColumn = ({
  notification,
}: ActionsWithTimestampColumnProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const handleDeleteNotification = (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(deleteNotification(notification.id))
  }

  const handleChangeIsRead = (e: React.MouseEvent, isRead: boolean) => {
    e.stopPropagation()
    dispatch(changeNotificationIsRead(notification.id, !isRead))
  }

  return (
    <Grid container alignItems="center" justifyContent="flex-end" wrap="nowrap">
      <Dotdotdot clamp={1}>
        <TextInteractive
          className={classNames(classes.message, {
            [classes.bold]: !notification.read,
          })}
          isLoading={R.isEmpty(notification)}
          ml={2}
          mr={1}
          variant="body"
        >
          {formatNotificationDate(notification.creationDate)}
        </TextInteractive>
      </Dotdotdot>
      {!R.isEmpty(notification) && (
        <Tooltip
          placement="top"
          title={
            notification.read
              ? t('Common:MARK_AS_UNREAD')
              : t('Common:MARK_AS_READ')
          }
        >
          <Grid
            container
            item
            xs
            className={classes.notificationCircleContainer}
            justifyContent="center"
          >
            <Grid
              item
              className={classNames(classes.notificationCircle, {
                [classes.notificationUnreadCircle]: !notification.read,
              })}
              data-testid={`notification-circle-${notification.id}`}
              onClick={(e) => handleChangeIsRead(e, notification.read)}
            />
          </Grid>
        </Tooltip>
      )}
      {!R.isEmpty(notification) && (
        <PuiCloseButton
          classes={{ iconButton: classes.closeIconButton }}
          onClick={handleDeleteNotification}
        />
      )}
    </Grid>
  )
}
export default ActionsWithTimestampColumn
