import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'

import { getPatientId } from '~/store/reducers/soap'

import RemindersSection from './reminders/RemindersSection'
import UpcomingAppointments from './upcoming-appointments/UpcomingAppointments'

const SOAPScheduler = () => {
  const patientId = useSelector(getPatientId)

  return (
    <>
      <Grid item pr={1.5} xs={4}>
        <UpcomingAppointments />
      </Grid>
      <Grid item pl={1.5} xs={8}>
        <RemindersSection patientId={patientId} />
      </Grid>
    </>
  )
}

export default SOAPScheduler
