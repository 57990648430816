import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { NumberUtils } from '@pbt/pbt-ui-components'

import ListActions from '~/components/common/lists/ListActions'
import DialogNames from '~/constants/DialogNames'
import { fetchCheckoutInvoices } from '~/store/actions/finance'
import { getMultipleInvoices } from '~/store/reducers/finance'
import { getAppointmentId, getClientId } from '~/store/reducers/soap'
import { BatchInvoice } from '~/types'
import useDialog from '~/utils/useDialog'

import { getInvoiceDueToPayNoFee } from '../../invoices/invoiceUtils'

export interface SoapClientInvoiceListActionsProps {
  checkedItems: string[]
  onClear: () => void
}

const SoapClientInvoiceListActions = ({
  checkedItems,
  onClear,
}: SoapClientInvoiceListActionsProps) => {
  const dispatch = useDispatch()
  const invoices = useSelector(getMultipleInvoices(checkedItems))
  const clientId = useSelector(getClientId)
  const appointmentId = useSelector(getAppointmentId)
  const { t } = useTranslation(['Common', 'Soap'])

  const onDialogClose = () => {
    onClear()
    if (appointmentId) {
      dispatch(fetchCheckoutInvoices(appointmentId))
    }
  }

  const [openBatchInvoiceDialog] = useDialog(
    DialogNames.BATCH_INVOICE,
    onDialogClose,
  )
  const [openAddPaymentDialog] = useDialog(
    DialogNames.ADD_PAYMENT,
    onDialogClose,
  )
  const [openPrintInvoiceDialog] = useDialog(DialogNames.PRINT_INVOICE)
  const [openEmailInvoiceDialog] = useDialog(DialogNames.EMAIL_INVOICE)

  if (checkedItems.length === 0) {
    return null
  }

  const batchInvoice = { invoices } as BatchInvoice

  const print = () => {
    openPrintInvoiceDialog({
      invoiceIds: checkedItems,
    })
  }

  const email = () => {
    openEmailInvoiceDialog({
      invoiceIds: checkedItems,
    })
  }

  const view = () => {
    openBatchInvoiceDialog({
      clientId,
      invoiceIds: checkedItems,
    })
  }

  const recordPayment = () => {
    openAddPaymentDialog({
      clientId,
      ComponentProps: {
        invoiceIds: checkedItems,
        invoiceAmount: getInvoiceDueToPayNoFee(batchInvoice),
      },
    })
  }

  return (
    <ListActions
      text={t('Soap:SOAP_CLIENT_INVOICE_LIST_ACTIONS.SELECTED_TOTAL_VALUE', {
        value: NumberUtils.formatMoney(getInvoiceDueToPayNoFee(batchInvoice)),
      })}
      onClear={onClear}
    >
      <Button onClick={print}>{t('Common:PRINT_ACTION')}</Button>
      <Button onClick={email}>{t('Common:EMAIL')}</Button>
      <Button onClick={view}>{t('Common:VIEW_ACTION')}</Button>
      <Button onClick={recordPayment}>{t('Common:RECORD_PAYMENT')}</Button>
    </ListActions>
  )
}

export default SoapClientInvoiceListActions
