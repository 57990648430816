import React from 'react'
import { Grid, Hidden, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, InfiniteLoaderList } from '@pbt/pbt-ui-components'

import Needle from '~/components/common/Needle'
import { TimelineItem } from '~/types'

import TimelineFilters, { TimelineFiltersProps } from './TimelineFilters'
import TimelinePrettyEmptyPanel from './TimelinePrettyEmptyPanel'
import TimelineTableRow from './TimelineTableRow'
import TimelineTimeframe from './TimelineTimeframe'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.only('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
        borderTop: theme.constants.tableBorder,
      },
    },
    timelineRoot: {
      height: '100%',
      position: 'relative',
    },
    timelineHeader: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    cardsRoot: {
      flex: 1,
      paddingTop: 10,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    contentRoot: {
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1.5),
      },
    },
  }),
  { name: 'Timeline' },
)

export interface TimelineProps {
  className?: string
  classes?: ClassesType<typeof useStyles>
  clientId: string
  data: TimelineItem[]
  expanded?: boolean
  filterProps?: TimelineFiltersProps
  filtersAlwaysOnTop?: boolean
  isItemLoaded: (index: number) => boolean
  loadMoreItems: (startIndex: number, endIndex: number) => void
  patientId: string
  totalCount: number
  useWindowScroll?: boolean
}

const Timeline = ({
  className,
  classes: classesProp,
  clientId,
  data,
  expanded,
  filterProps = {},
  filtersAlwaysOnTop = false,
  isItemLoaded,
  loadMoreItems,
  patientId,
  totalCount,
  useWindowScroll = true,
  ...rest
}: TimelineProps) => {
  const classes = useStyles({ classes: classesProp })

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const isHorizontalLayout =
    useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg')) ||
    filtersAlwaysOnTop

  return (
    <Grid
      container
      className={classNames(className, classes.root)}
      direction="column"
      wrap="nowrap"
    >
      {isHorizontalLayout && (
        <TimelineFilters {...filterProps} isHorizontalLayout />
      )}
      <Grid
        container
        item
        alignItems="center"
        className={classes.timelineHeader}
      >
        <TimelineTimeframe />
      </Grid>
      <Grid
        container
        item
        className={classes.cardsRoot}
        direction="row-reverse"
        minHeight={totalCount ? 500 : 'initial'}
      >
        {!isHorizontalLayout && <TimelineFilters {...filterProps} />}
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          xs={isHorizontalLayout ? 0 : 8}
        >
          <Hidden lgDown>
            <TimelinePrettyEmptyPanel
              clientId={clientId}
              itemData={data}
              patientId={patientId}
            />
          </Hidden>
          <Grid
            container
            item
            className={classes.timelineRoot}
            justifyContent="center"
          >
            <Hidden smDown>{totalCount > 0 && <Needle twoWay />}</Hidden>
            <Grid item xs className={classes.contentRoot}>
              <InfiniteLoaderList
                disableInitialAutoScroll
                firstItemMargin={isMobile ? 24 : 0}
                isItemLoaded={isItemLoaded}
                itemCount={totalCount}
                itemData={data}
                itemSpacing={24}
                loadMoreItems={loadMoreItems}
                useWindowScroll={useWindowScroll}
                {...rest}
              >
                {(item) => (
                  <TimelineTableRow
                    clientId={clientId}
                    expanded={expanded}
                    item={item}
                    patientId={patientId}
                  />
                )}
              </InfiniteLoaderList>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Timeline
