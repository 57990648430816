import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TodayOutlined as TodayOutlinedIcon } from '@mui/icons-material'
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import * as R from 'ramda'
import {
  AlertIconType,
  ButtonWithLoader,
  Calendar,
  FieldProp,
  LanguageUtils,
  Nil,
  Patient,
  PuiDialog,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import ConversationMessageFormattingArea, {
  ConversationMessageFormattingAreaHandle,
  EditAreaConfig,
} from '~/components/dashboard/communications/ConversationMessageFormattingArea'
import ConversationTransportSelect from '~/components/dashboard/communications/new-conversation-dialog/ConversationTransportSelect'
import CommunicationsPreview from '~/components/dashboard/communications/preview/CommunicationsPreview'
import { updateChecked } from '~/components/dashboard/soap/utils/reportCardUtils'
import { GeneratingPdfContentKind } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import { RootState } from '~/store'
import { resetDocuments } from '~/store/actions/documents'
import { useCreatedConversationsInfo } from '~/store/hooks/conversations'
import { useDownloadContentAsPdf } from '~/store/hooks/finance'
import { getDocumentsError } from '~/store/reducers/documents'
import { getUser } from '~/store/reducers/users'
import { EmailEntityConfig, EmailEntityConfigSection } from '~/types'
import { mergeRightItemsWithKey } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'
import { useSaveEmailAndSendMessage } from '~/utils/useSaveEmailAndSendMessage'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import DownloadPdfContentButton from '../../buttons/DownloadPdfContentButton'
import PrintHtml from '../../print/PrintHtml'
import EmailDialogSection from './EmailDialogSection'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 1024,
      maxWidth: 1024,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    root: {
      backgroundColor: theme.colors.contentBackground,
    },
    icon: {
      color: theme.colors.selectedOption,
      marginRight: theme.spacing(1),
    },
    button: {
      width: 168,
      marginRight: theme.spacing(3),
    },
    form: {
      width: 504,
      height: '100%',
    },
    previewContainer: {
      height: '100%',
    },
    progress: {
      position: 'absolute',
      left: `calc(50% - ${theme.spacing(2)})`,
      right: `calc(50% - ${theme.spacing(2)})`,
      top: theme.spacing(5),
    },
    configPlaceholder: {
      width: '100%',
      position: 'relative',
      '@supports (-moz-appearance:none)': {
        top: 467,
        right: 455,
      },
      minHeight: 60,
    },
  }),
  { name: 'EmailDialogWithSections' },
)

export enum SuccessDialogKind {
  VIEW_CONVERSATIONS = 'VIEW_CONVERSATIONS',
  POPUP = 'POPUP',
}

export enum DateRangeMode {
  ENTIRE = 'ENTIRE',
  DATE_RANGE = 'DATE_RANGE',
}

export interface EmailDialogWithSectionsProps {
  allowedTransports?: ConversationTransport[]
  cardEntityName?: string
  children?: any[]
  clientId?: string | Nil
  config?: EmailEntityConfig | Nil
  configIsLoading?: boolean
  defaultDateRangeLabel?: string
  defaultEmailSentAlertMessage?: string
  defaultMessage?: string
  defaultSubject: string
  dialogHeader?: React.ReactNode
  emailOnlyTransport?: boolean
  emailTemplate?: string | Nil
  eventId?: string | Nil
  isSendingEmailSelector: (state: RootState) => boolean
  onChange: (config: EmailEntityConfig) => void
  onClearPrint: () => void
  onClose: () => void
  onMedicalHistoryDateRangeChange?: (config: EmailEntityConfig) => void
  onPrint?: (config: EmailEntityConfig) => void
  onSendEmail: (config: EmailEntityConfig) => void
  onViewConversation?: () => void
  open: boolean
  patient?: Patient
  pdfContentKind?: GeneratingPdfContentKind
  pdfFileConfig?: object
  popupEmailSentMessageHandler?: (newConfig: EmailEntityConfig) => string
  printTemplate?: string
  soapId?: string | Nil
  successDialogKind?: string
  templateIsReceiving: boolean
}

const EmailDialogWithSections = ({
  open,
  config,
  clientId,
  patient,
  eventId,
  soapId,
  cardEntityName,
  onPrint,
  onClose,
  children,
  onChange,
  onMedicalHistoryDateRangeChange,
  onSendEmail,
  dialogHeader,
  onClearPrint,
  emailTemplate,
  printTemplate,
  defaultSubject,
  defaultMessage = '',
  defaultEmailSentAlertMessage,
  isSendingEmailSelector,
  templateIsReceiving,
  configIsLoading,
  pdfContentKind = GeneratingPdfContentKind.NONE,
  pdfFileConfig: pdfFileBaseConfig,
  successDialogKind = SuccessDialogKind.VIEW_CONVERSATIONS,
  popupEmailSentMessageHandler,
  emailOnlyTransport = true,
  allowedTransports,
  onViewConversation = R.F,
  defaultDateRangeLabel,
  ...rest
}: EmailDialogWithSectionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs', 'Time'])

  const client = useSelector(getUser(clientId))
  const isSendingEmail = useSelector(isSendingEmailSelector)
  const hasDocumentError = useSelector(getDocumentsError)

  const [showPrint, setShowPrint] = useState(false)
  const [sections, setSections] = useState<
    EmailEntityConfigSection[] | undefined
  >(undefined)
  const [transport, setTransport] = useState<ConversationTransport>()
  const [dateRangeMode, setDateRangeMode] = useState<DateRangeMode>(
    DateRangeMode.ENTIRE,
  )

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const messageFormattingAreaRef =
    useRef<ConversationMessageFormattingAreaHandle>(null)

  const fields: FieldProp[] = [
    {
      name: 'from',
      initialValue: moment().startOf('day').toISOString(),
      validators: ['timestamp'],
    },
    {
      name: 'to',
      initialValue: moment().endOf('day').toISOString(),
      validators: ['timestamp'],
    },
  ]

  const {
    fields: { from, to },
  } = useFields(fields)

  const initialMessage = config?.message || `<p>${defaultMessage}</p>`

  const emailDialogWithSectionsCardEntityName =
    cardEntityName || t('Dialogs:EMAIL_DIALOG_WITH_SECTIONS.CARD_ENTITY_NAME')

  const showSections = transport !== ConversationTransport.LogPhoneCall

  const isMedicalHistoryContentKind =
    pdfContentKind === GeneratingPdfContentKind.MEDICAL_HISTORY

  const dateRange =
    dateRangeMode === DateRangeMode.DATE_RANGE
      ? { from: from.value, to: to.value }
      : null

  const {
    fields: { subject, message, to: mailTo },
    validate,
    reset,
  } = useNewConversationValidationForm(
    { transport, client },
    {
      initialSubject: defaultSubject,
      initialMessage,
      initialTo: config?.mailTo,
    },
  )

  // Bug: https://chewyinc.atlassian.net/browse/RHAP-3557
  // Ensure to reset stored documents state whenever some error has happened to avoid the bug above
  useEffect(() => {
    if (hasDocumentError) {
      resetDocuments()
    }
  }, [hasDocumentError])

  useEffect(() => {
    // the medical history flow has constant config update by date range and shouldn't be reset
    if (isMedicalHistoryContentKind) {
      return
    }
    reset()
  }, [config, defaultSubject])

  const formRecipients = () =>
    messageFormattingAreaRef.current?.formRecipients() || []

  const getNewConfig = (): EmailEntityConfig => ({
    mailTo: mailTo.value,
    subject: subject.value,
    message: message.value,
    recipients: formRecipients(),
    sections,
    transport,
    ...dateRange,
  })

  const updateMessage = (text: string) => {
    message.setValue(text)
    messageFormattingAreaRef.current?.resetMessageState()
  }

  useEffect(() => {
    const newSections = config?.sections?.filter<EmailEntityConfigSection>(
      R.is(Object),
    )
    setSections(
      newSections
        ? mergeRightItemsWithKey(newSections, sections || [], 'checked')
        : undefined,
    )
    messageFormattingAreaRef.current?.resetMessageState()
  }, [config?.sections, open])

  useEffect(() => {
    onChange(getNewConfig())
  }, [message.value, sections, open, transport])

  useEffect(() => {
    if (onMedicalHistoryDateRangeChange) {
      onMedicalHistoryDateRangeChange(getNewConfig())
    }
  }, [dateRangeMode, from.value, to.value])

  useEffect(() => {
    updateMessage(initialMessage)
  }, [transport])

  const handleClose = () => {
    reset()
    setSections(undefined)
    closeAlert()

    onClose()
  }

  const handleViewConversation = () => {
    handleClose()
    onViewConversation()
  }

  const handleCheckedChange = (id: string, checked: boolean) => {
    setSections(updateChecked(id, sections, checked))
  }

  const titleMessageName = t(
    'Dialogs:EMAIL_DIALOG_WITH_SECTIONS.TITLE_MESSAGE_NAME',
    {
      patientName: patient?.name || t('Common:PATIENT'),
      cardEntityName: emailDialogWithSectionsCardEntityName,
    },
  )

  const displayConversationViewConversations = useCreatedConversationsInfo({
    getIsConversationCreating: isSendingEmailSelector,
    onConversationCreationSuccess: handleClose,
    onViewConversation: handleViewConversation,
    createdInfoDialogProps: { titleMessageName },
  })

  const defaultEmailSentMessage = t(
    'Dialogs:EMAIL_DIALOG_WITH_SECTIONS.ALERT_MESSAGE',
    {
      titleMessageName,
      mailTo: mailTo.value,
    },
  )

  const openEmailSentAlert = () => {
    handleClose()
    openAlert({
      iconType: AlertIconType.SUCCESS,
      message: popupEmailSentMessageHandler
        ? popupEmailSentMessageHandler(getNewConfig())
        : defaultEmailSentMessage,
    })
  }

  const setCloseOnEmailSucceed = useCloseAfterCreation(
    openEmailSentAlert,
    isSendingEmailSelector,
  )

  const displayEmailResultDialogAfterSend = () => {
    switch (successDialogKind) {
      case SuccessDialogKind.VIEW_CONVERSATIONS:
        return displayConversationViewConversations()
      default:
        return setCloseOnEmailSucceed()
    }
  }

  const handleSendEmail = () => {
    if (validate()) {
      displayEmailResultDialogAfterSend()
      onSendEmail(getNewConfig())
    }
  }

  const handlePrint = () => {
    setShowPrint(true)
    if (onPrint) {
      onPrint(getNewConfig())
    }
  }

  const askForSaveEmailAndSendEmail = useSaveEmailAndSendMessage(
    handleSendEmail,
    client,
    formRecipients,
    mailTo.value,
  )

  const handleSendEmailClick = () => validate() && askForSaveEmailAndSendEmail()

  const [generatePdf, isGenerating] = useDownloadContentAsPdf(pdfContentKind)

  const handleGeneratePdfClick = () => {
    generatePdf({
      ...(pdfFileBaseConfig || {}),
      message: message.value,
      sections,
      ...dateRange,
    })
  }

  useWSTopic({ wsTopic: WSTopics.REPORT_AGGREGATION })

  const renderConfig = () =>
    (sections || []).map((section: Omit<EmailEntityConfigSection, 'name'>) => (
      <EmailDialogSection
        key={section.id}
        name={LanguageUtils.getTranslatedFieldName(section)}
        onCheckedChange={handleCheckedChange}
        {...section}
      />
    ))

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader
            className={classes.button}
            disabled={
              templateIsReceiving ||
              isSendingEmail ||
              configIsLoading ||
              !emailTemplate
            }
            loading={isSendingEmail}
            onClick={handleSendEmailClick}
          >
            {t('Common:SEND_ACTION')}
          </ButtonWithLoader>
          {onPrint && (
            <ButtonWithLoader
              className={classes.button}
              disabled={templateIsReceiving || !emailTemplate}
              loading={showPrint && templateIsReceiving}
              onClick={handlePrint}
            >
              {t('Common:PRINT_ACTION')}
            </ButtonWithLoader>
          )}
          {pdfContentKind !== GeneratingPdfContentKind.NONE && (
            <Grid container item xs flex={1} justifyContent="flex-end">
              <DownloadPdfContentButton
                loading={isGenerating}
                onClick={handleGeneratePdfClick}
              />
            </Grid>
          )}
        </>
      }
      aria-labelledby="email-dialog-with-sections"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      scroll="paper"
      title={
        dialogHeader ||
        `${t('Common:EMAIL')} ${emailDialogWithSectionsCardEntityName}`
      }
      onClose={handleClose}
      {...rest}
    >
      <Grid
        container
        className={classes.root}
        columnSpacing={3}
        px={2}
        py={3}
        wrap="nowrap"
      >
        <Grid container item className={classes.form} direction="column">
          {!emailOnlyTransport && (
            <ConversationTransportSelect
              allowedTransports={allowedTransports}
              configTransport={config?.transport}
              contactSlot={{ clientId }}
              disabled={configIsLoading}
              transport={transport}
              onChange={setTransport}
            />
          )}
          <ConversationMessageFormattingArea
            clientId={clientId}
            editAreaConfig={EditAreaConfig.RICH_EDIT}
            eventId={eventId}
            isLoading={configIsLoading}
            maxEditorHeight={480}
            messageField={message}
            minEditorHeight={240}
            patientId={patient?.id}
            ref={messageFormattingAreaRef}
            soapId={soapId}
            subjectField={subject}
            to={mailTo}
            transport={transport}
          />
          {isMedicalHistoryContentKind && (
            <>
              <Text strong mt={2} variant="subheading3">
                {t('Common:DATE_RANGE')}:
              </Text>
              <RadioGroup
                aria-label="date-range-section"
                name="date-range-section"
                value={dateRangeMode}
                onChange={(_, radioValue) => {
                  setDateRangeMode(radioValue as DateRangeMode)
                }}
              >
                <FormControlLabel
                  control={<Radio />}
                  label={defaultDateRangeLabel || t('Common:DEFAULT')}
                  value={DateRangeMode.ENTIRE}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t('Common:SELECT_DATES')}
                  value={DateRangeMode.DATE_RANGE}
                />
              </RadioGroup>
              {dateRangeMode === DateRangeMode.DATE_RANGE && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  pl={4}
                  wrap="nowrap"
                >
                  <TodayOutlinedIcon className={classes.icon} />
                  <Grid item width={100}>
                    <Calendar
                      field={{
                        ...from,
                        setValue: (isoDate) => {
                          from.setValue(
                            moment(isoDate).startOf('day').toISOString(),
                          )
                        },
                      }}
                    />
                  </Grid>
                  <Grid item mx={2}>
                    <Text>{t('Time:TIME_SELECTOR.TO').toLowerCase()}</Text>
                  </Grid>
                  <TodayOutlinedIcon className={classes.icon} />
                  <Grid item width={100}>
                    <Calendar
                      field={{
                        ...to,
                        setValue: (isoDate) => {
                          to.setValue(
                            moment(isoDate).endOf('day').toISOString(),
                          )
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {(config?.sections || sections) && showSections && (
            <>
              <Text strong mt={2} variant="subheading3">
                {t('Common:INCLUDE_NOUN')}:
              </Text>
              <Grid item className={classes.configPlaceholder}>
                {sections ? (
                  renderConfig()
                ) : (
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                    size={32}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs
          className={classes.previewContainer}
          justifyContent="center"
        >
          <CommunicationsPreview
            template={emailTemplate}
            transport={config?.transport}
          />
        </Grid>
      </Grid>
      {showPrint && printTemplate && (
        <PrintHtml
          pageMargins="20px 0"
          source={printTemplate}
          onOk={() => {
            setShowPrint(false)
            onClearPrint()
          }}
        />
      )}
    </PuiDialog>
  )
}

export default EmailDialogWithSections
