import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import {
  BasePuiDialogProps,
  ButtonWithLoader,
  Nil,
  PermissionArea,
  PuiDialog,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { ConversationTransport } from '~/api/graphql/generated/types'
import FeatureToggle from '~/constants/featureToggle'
import { sendGeneratedMessage } from '~/store/actions/conversationMessages'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getConversationMessagesIsSending } from '~/store/reducers/conversationMessages'
import { getPatient } from '~/store/reducers/patients'
import { getUser } from '~/store/reducers/users'
import { MembershipPlan } from '~/types'
import { formatMembershipPeriod } from '~/utils/membershipPaymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import { useNewConversationValidationForm } from '~/utils/useNewConversationValidationForm'

import ToInput, { ToInputHandle } from '../communications/common/ToInput'
import ConversationTransportSelect from '../communications/new-conversation-dialog/ConversationTransportSelect'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 450,
      maxWidth: 450,
    },
    body: {
      padding: theme.spacing(2),
    },
    text: {
      lineHeight: '2.2rem',
    },
    button: {
      minWidth: 262,
    },
  }),
  { name: 'PatientMembershipPaymentLinkDialog' },
)

interface PatientMembershipPaymentLinkDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
  plan: MembershipPlan
}

export const PatientMembershipPaymentLinkDialog = ({
  open,
  onClose,
  patientId,
  clientId,
  plan,
}: PatientMembershipPaymentLinkDialogProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Dialogs')

  const [transport, setTransport] = useState<ConversationTransport>(
    ConversationTransport.Email,
  )
  const toInputRef = useRef<ToInputHandle>(null)

  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const isSending = useSelector(getConversationMessagesIsSending)
  const isMembershipLinkEnabled = useSelector(
    getFeatureToggle(FeatureToggle.MEMBERSHIP_PAYMENT_LINK_PERMISSIONS),
  )
  const { update: wellnessPlanUpdatePermissions } = useSelector(
    getCRUDByArea(PermissionArea.WELLNESS_PLAN),
  )
  const { send } = useSelector(getCRUDByArea(PermissionArea.MEMBERSHIP_LINK))
  const membershipPaymentLinkSendPermissions = send && isMembershipLinkEnabled

  const isM2bBraintreeSignupEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M2B_BRAINTREE_SIGNUP),
  )

  const clientName = Utils.getPersonString(client)

  const {
    fields: { to: mailTo },
    validate,
    reset,
  } = useNewConversationValidationForm(
    { transport, client },
    {},
    {
      fieldsToValidate: ['to'],
    },
  )

  const handleTransportChange = (newTransport: ConversationTransport) => {
    setTransport(newTransport)
  }

  const closeAfterSending = useCloseAfterCreation(() => {
    reset()
    onClose?.()
  }, getConversationMessagesIsSending)

  const handleSendUpdateLink = () => {
    const recipients = toInputRef.current?.formRecipients()

    if (validate() && clientId && recipients) {
      closeAfterSending()
      dispatch(
        sendGeneratedMessage({
          transport,
          recipients,
          input: {
            updateMembershipPayment: {
              clientId,
              wplanLogId: plan.planLogId,
            },
          },
        }),
      )
    }
  }

  return (
    <PuiDialog
      actions={
        <ButtonWithLoader
          className={classes.button}
          disabled={
            !(
              wellnessPlanUpdatePermissions ||
              membershipPaymentLinkSendPermissions
            ) || isSending
          }
          loading={isSending}
          onClick={handleSendUpdateLink}
        >
          {t('Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.SEND_ACTION')}
        </ButtonWithLoader>
      }
      aria-labelledby="patient-membership-payment-link-dialog"
      classes={{
        paper: classes.paper,
        dialogContentRoot: classes.body,
      }}
      open={open}
      title={t('Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Text strong mb={0.25} variant="subheading3">
        {t('Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.SEND_ACTION')}:
      </Text>
      <Text className={classes.text} mb={3} variant="body2">
        {t(
          'Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.CLIENT_AND_PATIENT_NAMES',
          {
            clientName,
            patientName: patient?.name || '',
          },
        )}
        <br />
        {t('Common:PLAN')}: {plan.tier}
        <br />
        {t('Common:PLAN_PERIOD')}: {formatMembershipPeriod(plan)}
      </Text>
      <ConversationTransportSelect
        allowedTransports={[
          ConversationTransport.Email,
          ConversationTransport.Sms,
        ]}
        contactSlot={{
          clientId,
        }}
        labelVariant="subheading3"
        transport={transport}
        onChange={handleTransportChange}
      />
      <ToInput
        clientId={clientId}
        labelVariant="subheading3"
        patientId={patientId}
        ref={toInputRef}
        to={mailTo}
        transport={transport}
      />
      <Text className={classes.text} mt={2} variant="body2">
        {isM2bBraintreeSignupEnabled
          ? t(
              'Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.DESCRIPTION_BRAINTREE',
            )
          : t('Dialogs:PATIENT_MEMBERSHIP_PAYMENT_LINK_DIALOG.DESCRIPTION')}
      </Text>
    </PuiDialog>
  )
}
