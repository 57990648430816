import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import classNames from 'classnames'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { useAppointmentTypes } from '~/components/common/appointments/useAppointmentTypes'
import { closeSoap } from '~/store/actions/soap'
import { getExpandedIFrame } from '~/store/duck/marketplace'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getIsCurrentContextSoap, getIsFinalized } from '~/store/reducers/soap'

import SoapRecordSection from '../soap/appointment/SoapRecordSection'
import SoapRail from '../soap/rail/SoapRail'
import ExpandedMarketplaceIFrame from './marketplace/ExpandedMarketplaceIFrame'
import SoapMenu from './soap-menu/SoapMenu'
import SoapPageLoader from './SoapPageLoader'
import SoapWidgetItem from './SoapWidgetItem'
import { useInitializeSoap } from './utils/useInitializeSoap'
import { useIsSoapPageLoading } from './utils/useIsSoapPageLoading'
import { useOpenMissingClientPatientMissingFields } from './utils/useOpenMissingClientPatientMissingFields'
import { usePetParentChangeAlert } from './utils/usePetParentChangedAlert'
import { useSoapAlert } from './utils/useSoapAlert'
import { useSoapOrderValidationErrorAlert } from './utils/useSoapOrderValidationErrorAlert'
import { useSoapPageRedirect } from './utils/useSoapPageRedirect'
import { useSoapRail } from './utils/useSoapRail'
import { useSoapTemplateTabs } from './utils/useSoapTemplateTabs'

const SoapPageV2 = () => {
  const dispatch = useDispatch()
  const { tabName: urlTabName } = useParams()

  const SOAPPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)
  const expandedIFrame = useSelector(getExpandedIFrame)

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  const soapTabs = useSoapTemplateTabs({ redirect: false })
  const redirectTo = useSoapPageRedirect(soapTabs)
  const isLoading = useIsSoapPageLoading()
  const {
    expandedRail,
    hideSoapRail,
    rightRailVisible,
    selectedTab,
    setExpandedRail,
    setRightRailVisible,
    setSelectedTab,
    soapRailClasses,
  } = useSoapRail(isReadOnly)

  // Responsible to fetch soap and handle initial states and navigation urls
  useInitializeSoap()
  // Whenever another user has changed pet parent we need to display an alert message
  usePetParentChangeAlert()
  // Open missing field dialog whenever there're information missing from patient or client
  useOpenMissingClientPatientMissingFields()
  // Triggered by server error handleOrderRemovalFailureCustomMessage
  useSoapAlert()
  // Triggered whenever there's some conflict with orders
  useSoapOrderValidationErrorAlert()
  useAppointmentTypes()
  // Reset soap to initial state on unmount
  useEffect(
    () => () => {
      dispatch(closeSoap())
    },
    [],
  )

  const currentTab =
    soapTabs?.find((tab) => tab.url === urlTabName) ?? soapTabs?.[0]

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      id="soap-page-content"
      position="relative"
      width="100%"
    >
      <SoapPageLoader open={isLoading || !soapTabs} />
      {expandedIFrame ? (
        <ExpandedMarketplaceIFrame iFrame={expandedIFrame} />
      ) : (
        <>
          <SoapMenu tabs={soapTabs} />
          <Box
            className={classNames({
              [soapRailClasses.contentWithoutRail]: !rightRailVisible,
              [soapRailClasses.contentWithRail]: rightRailVisible,
            })}
            id="soap-page-wrapper"
            p={2}
          >
            <SoapRecordSection />
            <Stack spacing={2}>
              {currentTab?.widgets.map(
                ({ active, id, marketplaceId, soapWidgetId }) => (
                  <SoapWidgetItem
                    active={active}
                    key={id}
                    marketplaceId={marketplaceId}
                    soapWidgetId={soapWidgetId}
                    onHideSoapRail={hideSoapRail}
                  />
                ),
              )}
            </Stack>
          </Box>
          <SoapRail
            className={classNames(soapRailClasses.rightRail, {
              [soapRailClasses.expandedRail]: expandedRail,
              [soapRailClasses.hidden]: !rightRailVisible,
            })}
            editDisabled={!SOAPPermissions.update}
            expanded={expandedRail}
            redirectTo={redirectTo}
            selectedTab={selectedTab}
            setExpanded={setExpandedRail}
            setSelectedTab={setSelectedTab}
            soapTabs={soapTabs}
            visible={rightRailVisible}
            onVisibilityChange={isReadOnly ? undefined : setRightRailVisible}
          />
        </>
      )}
    </Box>
  )
}

export default SoapPageV2
