import * as R from 'ramda'
import {
  BaseRole,
  EventTypeAppointmentRole,
  Role,
  RoleBusinessCategory,
  RoleName,
  RoleType,
  User,
} from '@pbt/pbt-ui-components'

type GetHasRoleProps = {
  businessId: string
  businessType?: RoleBusinessCategory
  roleNames: string[] | string
  rolesMap: Record<string, Role>
  user: User
}

export const getHasRole = R.curry(
  ({
    rolesMap,
    businessId,
    roleNames,
    user = {} as User,
    businessType,
  }: GetHasRoleProps) => {
    if (!roleNames || R.isEmpty(roleNames)) {
      return true
    }

    const rolesCriteria = Array.isArray(roleNames) ? roleNames : [roleNames]
    const businessTypeCriteria = [businessType, RoleBusinessCategory.ALL]

    if (
      R.isEmpty(user) ||
      R.isNil(user?.businessToRoleList) ||
      R.isEmpty(rolesMap)
    ) {
      return false
    }

    const currentBusinessRoles = Object.values(
      user.businessToRoleList || [],
    ).filter(({ business }) => business === businessId)

    return currentBusinessRoles.some(
      (userRole) =>
        R.includes(rolesMap[userRole.role]?.name, rolesCriteria) &&
        (businessType === undefined ||
          R.includes(
            rolesMap[userRole.role]?.businessCategory,
            businessTypeCriteria,
          )),
    )
  },
)

export const isStaffRole = (role: BaseRole) =>
  role?.type === RoleType.STAFF_ROLE
export const isClientRole = (role: BaseRole) =>
  role?.type === RoleType.CLIENT_ROLE
export const isPracticeAdminRole = (role: BaseRole) =>
  role?.name === RoleName.PracticeAdministrator
export const isInventoryManagementRole = (role: BaseRole) =>
  role?.name === RoleName.InventoryManagement
export const isCVCRole = (role: BaseRole) =>
  role?.businessCategory === RoleBusinessCategory.CVC
export const isAllBusinessRole = (role: BaseRole) =>
  role?.businessCategory === RoleBusinessCategory.ALL
export const is3PRole = (role: BaseRole) =>
  role?.businessCategory === RoleBusinessCategory.THIRD_PARTY
export const isCurrentBusinessRole =
  (isBusinessCVC: boolean | undefined) => (role: BaseRole) =>
    isAllBusinessRole(role) ||
    (isBusinessCVC && isCVCRole(role)) ||
    (!isBusinessCVC && is3PRole(role))
export const getEquivalentRolesForBusinessType = (
  roleId: string,
  roles: BaseRole[],
) => {
  // Some roles have CVC and 3P equivalents, but we might
  // want one field to be able to work for both CVC and 3P
  const roleName = roles.find((role) => role.id === roleId)?.name
  const matchingRoleIds = roles
    .filter((role) => role.name === roleName)
    .map((role) => role.id)

  return matchingRoleIds
}

export const filterCVCRoles = (role: BaseRole | EventTypeAppointmentRole) =>
  Object.prototype.hasOwnProperty.call(role, 'businessCategory')
    ? (role as BaseRole).businessCategory !== RoleBusinessCategory.CVC
    : true

export const filterVeterinarian2Role = (
  role: BaseRole | EventTypeAppointmentRole,
) => role.name !== RoleName.Veterinarian2
