import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { includes } from 'ramda'
import {
  ButtonWithLoader,
  CircularProgressOverlay,
  DateUtils,
  NumberUtils,
  PermissionArea,
  Text,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'

import Link from '~/components/common/link/Link'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { fetchCheckoutInvoices } from '~/store/actions/finance'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getFinanceCheckoutInvoicesData,
  getFinanceIsFetchingSoapInvoices,
} from '~/store/reducers/finance'
import {
  getAppointmentId,
  getClientId,
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import useDialog from '~/utils/useDialog'

import SoapClientInvoiceListActions from './SoapClientInvoiceListActions'
import SoapClientInvoiceListRow from './SoapClientInvoiceListRow'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 950,
      position: 'relative',
    },
    tableRoot: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    invoiceContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    item: {
      padding: theme.spacing(1, 2),
      borderRight: theme.constants.tableBorder,
    },
    emptyItem: {
      borderColor: 'transparent',
    },
    priorBalanceContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    currentBalanceContainer: {
      borderBottom: theme.constants.tableBorder,
    },
    link: {
      color: theme.colors.secondaryText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    headerColumn: {
      minWidth: 180,
      maxWidth: 180,
    },
    buttonsColumn: {
      minWidth: 120,
      maxWidth: 120,
    },
    labelColumn: {
      minWidth: 61,
      maxWidth: 61,
    },
    dateColumn: {
      minWidth: 155,
      maxWidth: 155,
    },
    eventTypeColumn: {
      minWidth: 230,
      maxWidth: 230,
    },
    overlay: {
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(4),
    },
  }),
  { name: 'SoapClientInvoiceList' },
)

const SoapClientInvoiceList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const invoicesData = useSelector(getFinanceCheckoutInvoicesData)
  const isFetchingInvoices = useSelector(getFinanceIsFetchingSoapInvoices)
  const isLoadingSoap = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const paymentPermissions = useSelector(getCRUDByArea(PermissionArea.PAYMENT))
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const appointmentDateInvoiceIds = invoicesData.forDate
  const otherInvoiceIds = invoicesData.others
  const initiallySelectedInvoices = [
    ...appointmentDateInvoiceIds,
    ...otherInvoiceIds,
  ]

  const [openPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)

  const [checkedInvoices, setCheckedInvoices] = useState([])

  useEffect(() => {
    if (appointmentId && invoicePermissions.read) {
      dispatch(fetchCheckoutInvoices(appointmentId))
    }
  }, [appointmentId, invoicePermissions.read])

  const onCheckInvoice = (invoiceId: string) => {
    setCheckedInvoices(Utils.toggleListItem(invoiceId, checkedInvoices))
  }

  if (!invoicePermissions.read) {
    return null
  }

  return (
    <Grid container item className={classes.root}>
      <CircularProgressOverlay
        className={classes.overlay}
        open={isFetchingInvoices || isLoadingSoap || isLoadingSoapOrders}
        preloaderText={t('Common:LOADING_INVOICES')}
      />
      <Grid container item wrap="nowrap">
        <Grid
          item
          className={classNames(
            classes.headerColumn,
            classes.item,
            classes.emptyItem,
          )}
        />
        <Grid container item direction="column">
          <SoapClientInvoiceListActions
            checkedItems={checkedInvoices}
            onClear={() => setCheckedInvoices([])}
          />
        </Grid>
      </Grid>
      <Grid container item className={classes.tableRoot} direction="column">
        <Grid container item className={classes.invoiceContainer} wrap="nowrap">
          <Grid item className={classNames(classes.headerColumn, classes.item)}>
            <TextInteractive strong variant="h5">
              {t('Soap:SOAP_CLIENT_INVOICE_LIST.INVOICES_FOR_DATE', {
                date: DateUtils.formatDate(appointment?.scheduledStartDatetime),
              })}
            </TextInteractive>
          </Grid>
          <Grid container item direction="column">
            {appointmentDateInvoiceIds.length > 0 ? (
              appointmentDateInvoiceIds.map((invoiceId) => (
                <SoapClientInvoiceListRow
                  checked={includes(invoiceId, checkedInvoices)}
                  invoiceId={invoiceId}
                  key={invoiceId}
                  onCheck={onCheckInvoice}
                />
              ))
            ) : (
              <Text p={2} variant="body">
                {t('Soap:SOAP_CLIENT_INVOICE_LIST.NO_INVOICES_DATE', {
                  date: DateUtils.formatDate(
                    appointment?.scheduledStartDatetime,
                  ),
                })}
              </Text>
            )}
          </Grid>
        </Grid>
        {otherInvoiceIds.length > 0 && (
          <Grid
            container
            item
            className={classes.priorBalanceContainer}
            wrap="nowrap"
          >
            <Grid
              item
              className={classNames(classes.headerColumn, classes.item)}
            >
              <TextInteractive strong variant="h5">
                {appointmentDateInvoiceIds.length > 0
                  ? t('Soap:SOAP_CLIENT_INVOICE_LIST.OTHER_UNPAID_INVOICES')
                  : t('Soap:SOAP_CLIENT_INVOICE_LIST.UNPAID_INVOICES')}
              </TextInteractive>
            </Grid>
            <Grid container item direction="column">
              {otherInvoiceIds.map((invoiceId) => (
                <SoapClientInvoiceListRow
                  checked={includes(invoiceId, checkedInvoices)}
                  invoiceId={invoiceId}
                  key={invoiceId}
                  onCheck={onCheckInvoice}
                />
              ))}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          item
          className={classes.currentBalanceContainer}
          wrap="nowrap"
        >
          <Grid item className={classNames(classes.headerColumn, classes.item)}>
            <TextInteractive strong variant="h5">
              {t('Soap:SOAP_CLIENT_INVOICE_LIST.CURRENT_BALANCE')}
            </TextInteractive>
          </Grid>
          <Grid container item alignItems="center" wrap="nowrap">
            <Grid item className={classes.dateColumn} />
            <Grid item xs={2}>
              <Text strong data-testid="current-balance" variant="body">
                {NumberUtils.formatMoney(client?.balance)}
              </Text>
            </Grid>
            <Grid item className={classes.eventTypeColumn}>
              {clientId && paymentPermissions.read && (
                <Link className={classes.link} to={`/balance/${clientId}`}>
                  {t('Soap:SOAP_CLIENT_INVOICE_LIST.VIEW_BALANCE_HISTORY')}
                </Link>
              )}
            </Grid>
            <Grid item xs />
            <Grid item className={classes.labelColumn} />
            <Grid item className={classes.buttonsColumn}>
              {isPatientSharingEnabled && (
                <Text variant="lowAccent3">
                  {t('Soap:SOAP_CLIENT_INVOICE_LIST.NO_SHARED_INVOICES')}
                </Text>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item px={2} py={1}>
          {client?.balance && client?.balance > 0 ? (
            <ButtonWithLoader
              onClick={() =>
                openPaymentDialog({
                  ComponentProps: {
                    invoiceIds: initiallySelectedInvoices,
                    forceInvoiceSelection: true,
                  },
                  clientId,
                })
              }
            >
              {t('Common:RECORD_PAYMENT')}
            </ButtonWithLoader>
          ) : (
            <Text variant="body">
              {t('Soap:SOAP_CLIENT_INVOICE_LIST.NO_BALANCE_DUE')}
            </Text>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SoapClientInvoiceList
