import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, Nil, Utils } from '@pbt/pbt-ui-components'

import ItemHistoryLabel from '~/components/common/labels/ItemHistoryLabel'
import FeatureToggle from '~/constants/featureToggle'
import { useTaskEvent } from '~/store/hooks/tasks'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getTasksIsDeleting, getTasksIsLoading } from '~/store/reducers/tasks'
import { Task } from '~/types'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      minWidth: 150,
      '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2),
      },
    },
    itemHistoryLabel: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'TaskDialogActions' },
)

export interface TaskDialogActionsProps {
  onDelete: () => void
  onProceed: (stateId?: string) => void
  task: Task | Nil
}

const TaskDialogActions = ({
  task,
  onProceed,
  onDelete,
}: TaskDialogActionsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Tasks'])
  const isTaskListOracleIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_LIST_ORACLE_INTEGRATION),
  )

  const isLoading = useSelector(getTasksIsLoading)
  const isDeleting = useSelector(getTasksIsDeleting)

  const isContextItem = useIsCurrentContextItem(task)

  const TaskType = useTaskEvent()
  const TaskStates = TaskType.states
  const OpenState = Utils.findConstantIdByName('Open', TaskStates)
  const DoneState = Utils.findConstantIdByName('Done', TaskStates)
  const isDone = task?.stateId === DoneState
  const isCreate = !task?.id
  const shouldUseVCRBehavior =
    isTaskListOracleIntegrationEnabled && task?.forChewyVcr

  const markAsOpen = () => onProceed(OpenState)

  const markAsDone = () => onProceed(DoneState)

  return (
    <Grid container item px={1}>
      <ItemHistoryLabel className={classes.itemHistoryLabel} item={task} />
      <ButtonWithLoader
        className={classes.button}
        disabled={isLoading}
        loading={isLoading}
        onClick={() => onProceed()}
      >
        {isCreate ? t('Common:ADD_ACTION') : t('Common:SAVE_ACTION')}
      </ButtonWithLoader>
      {!isCreate && (
        <>
          {isDone && (
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              disabled={isLoading}
              loading={isLoading}
              onClick={markAsOpen}
            >
              {t('Tasks:LABEL.MARK_AS_TO-DO')}
            </ButtonWithLoader>
          )}
          {!isDone && (
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading || shouldUseVCRBehavior}
              loading={isLoading}
              onClick={markAsDone}
            >
              {t('Tasks:LABEL.MARK_AS_DONE')}
            </ButtonWithLoader>
          )}
          {isContextItem && (
            <ButtonWithLoader
              className={classes.button}
              color="secondary"
              disabled={isLoading || isDeleting}
              loading={isDeleting}
              onClick={onDelete}
            >
              {t('Common:DELETE_ACTION')}
            </ButtonWithLoader>
          )}
        </>
      )}
    </Grid>
  )
}

export default TaskDialogActions
