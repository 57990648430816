import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { Typography } from '@mui/material/styles/createTypography'
import makeStyles from '@mui/styles/makeStyles'
import {
  ClassesType,
  LinkButton,
  PuiPopper,
  PuiTextField,
  TextInteractive,
} from '@pbt/pbt-ui-components'
import { Toggle } from '@pbt/pbt-ui-components/src/icons'

import { fetchDevices, getDevicesIsLoading } from '~/store/duck/labOrders'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { LabOrderDevice } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      maxWidth: theme.spacing(23),
    },
    toggleIcon: {
      color: theme.colors.toggleIcon,
    },
    popperRoot: {
      width: theme.spacing(25.5),
    },
    textFieldInput: {
      cursor: 'pointer',
      ...theme.utils.getPlaceholder(
        theme.typography.body2 as unknown as Typography,
      ),
    },
    container: {
      padding: theme.spacing(2, 1.5, 2, 2),
    },
    containerMachines: {
      padding: theme.spacing(1, 0),
      borderBottom: theme.constants.tabBorder,
    },
    list: {
      flex: 1,
      maxHeight: theme.spacing(25),
      overflowY: 'auto',
    },
    listItem: {
      padding: theme.spacing(1, 1.5, 1, 2),
    },
    listItemTextRoot: {
      margin: 0,
    },
    listItemTextPrimary: {
      margin: 0,
      ...theme.typography.body2,
    },
    linkButton: {
      color: theme.colors.link,
      marginLeft: theme.spacing(-1),
    },
  }),
  { name: 'MachinesSelect' },
)

export interface MachinesSelectProps {
  classes?: ClassesType<typeof useStyles>
  items: LabOrderDevice[]
  onChange: (deviceSerialNumber?: string) => void
  value?: string
}

const MachinesSelect = ({
  classes: classesProp,
  items = [],
  value,
  onChange,
}: MachinesSelectProps) => {
  const classes = useStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const machinesEmpty = !items.length
  const footerText = machinesEmpty
    ? t('Common:INPUTS.MACHINE_SELECT.NOT_FOUND')
    : t('Common:INPUTS.MACHINE_SELECT.HAS_MACHINE')

  const devicesIsLoading = useSelector(getDevicesIsLoading)
  const businessId = useSelector(getCurrentBusinessId)

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [selectedMachine, setSelectedMachine] = useState<LabOrderDevice>({})

  useEffect(() => {
    if (selectedMachine.deviceSerialNumber !== value) {
      const machine =
        items.find(({ deviceSerialNumber }) => deviceSerialNumber === value) ||
        {}
      setSelectedMachine(machine)
    }
  }, [items, value])

  const handleChange = (deviceSerialNumber?: string) => () => {
    onChange(deviceSerialNumber)
    setAnchorEl(undefined)
  }

  return (
    <Grid item className={classes.root}>
      <PuiTextField
        disabled
        InputProps={{
          classes: { input: classes.textFieldInput },
          endAdornment: (
            <InputAdornment position="end">
              {devicesIsLoading ? (
                <CircularProgress size={16} />
              ) : (
                <Toggle className={classes.toggleIcon} />
              )}
            </InputAdornment>
          ),
        }}
        placeholder={t('Common:INPUTS.MACHINE_SELECT.PLACEHOLDER')}
        value={selectedMachine.displayName || ''}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      />
      <PuiPopper
        hideCloseButton
        anchorEl={anchorEl}
        disablePortal={false}
        open={Boolean(anchorEl)}
        placement="bottom-start"
        onClose={() => setAnchorEl(undefined)}
      >
        <Grid container className={classes.popperRoot}>
          {!machinesEmpty && (
            <Grid container className={classes.containerMachines}>
              <List disablePadding className={classes.list} component="div">
                {items.map(({ deviceSerialNumber, displayName }) => (
                  <ListItem
                    button
                    disableGutters
                    className={classes.listItem}
                    key={deviceSerialNumber}
                    onClick={handleChange(deviceSerialNumber)}
                  >
                    <ListItemText
                      classes={{
                        root: classes.listItemTextRoot,
                        primary: classes.listItemTextPrimary,
                      }}
                      primary={displayName}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
          <Grid container className={classes.container}>
            <TextInteractive>{footerText}</TextInteractive>
            <LinkButton
              classes={{ button: classes.linkButton }}
              onClick={() => dispatch(fetchDevices(businessId, true))}
            >
              {t('Common:REFRESH_LIST').toLowerCase()}
            </LinkButton>
          </Grid>
        </Grid>
      </PuiPopper>
    </Grid>
  )
}

export default MachinesSelect
