import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  LanguageUtils,
  NumberUtils,
  Text,
  TextProps,
  Utils,
  WellnessPlan,
} from '@pbt/pbt-ui-components'

import { getWellnessPlanPriceType } from '~/store/reducers/constants'

export interface WellnessPlanPriceLabelProps extends TextProps {
  plan: WellnessPlan
}

const WellnessPlanPriceLabel = ({
  plan,
  ...rest
}: WellnessPlanPriceLabelProps) => {
  const { t } = useTranslation('Common')

  const WellnessPlanPriceType = useSelector(getWellnessPlanPriceType)

  const prices = plan?.prices || []

  return (
    <Text variant="body" {...rest}>
      {prices.map((price, index) => {
        const formattedPrice = NumberUtils.formatMoney(price.price)
        const formattedPriceType = LanguageUtils.getTranslatedFieldName(
          Utils.findById(price.priceTypeId, WellnessPlanPriceType),
          'displayName',
        )

        return (
          <React.Fragment key={price.priceTypeId}>
            {index > 0 && ` ${t('Common:OR')} `}
            {`${formattedPrice}/${formattedPriceType}`}
          </React.Fragment>
        )
      })}
    </Text>
  )
}

export default WellnessPlanPriceLabel
