import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { getInfoAboutEnums } from '~/store/reducers/problems'
import { DiagnosisSnapshotItem, ProblemLogEnumReference } from '~/types'

interface ProblemHistoryEnumInfoProps {
  log: DiagnosisSnapshotItem
}

const ProblemHistoryEnumInfo = ({ log }: ProblemHistoryEnumInfoProps) => {
  const parentHistoryEnums =
    log.enums?.filter((enumLog) => !enumLog.parentEnumValueId) || []
  const parentHistoryEnumsIds = parentHistoryEnums.map((item) => item.enumId)

  const childrenHistoryEnums =
    log.enums?.filter((enumLog: ProblemLogEnumReference) =>
      Boolean(enumLog.parentEnumValueId),
    ) || []
  const childrenHistoryEnumsIds = childrenHistoryEnums.map(
    (item) => item.enumId,
  )

  const parentHistoryEnumsInfo = useSelector(
    getInfoAboutEnums(parentHistoryEnumsIds),
  )

  const childrenHistoryEnumsInfo = useSelector(
    getInfoAboutEnums(childrenHistoryEnumsIds),
  )

  const getEnumInfo = (enumRef: ProblemLogEnumReference, isChild = false) => {
    const infoArray = isChild
      ? childrenHistoryEnumsInfo
      : parentHistoryEnumsInfo
    return {
      problem: LanguageUtils.getTranslatedFieldName(
        infoArray[enumRef.enumId].enumProblem,
      ),
      problemValue: LanguageUtils.getTranslatedFieldName(
        infoArray[enumRef.enumId].enumProblemValuesMap[enumRef.enumValueId],
      ),
      problemNotes: enumRef.notes,
      children: isChild
        ? []
        : childrenHistoryEnums.filter(
            (enumLog: ProblemLogEnumReference) =>
              Boolean(enumLog.parentEnumValueId) &&
              enumLog.parentEnumValueId === enumRef.enumValueId,
          ),
    }
  }

  return (
    <>
      {Boolean(parentHistoryEnums?.length) && (
        <>
          {parentHistoryEnums.map((enumRef: ProblemLogEnumReference) => {
            const { problem, problemValue, problemNotes, children } =
              getEnumInfo(enumRef)
            return (
              <Grid key={`${problem}-${problemValue}`} width="100%">
                <Text strong component="span" variant="body2">
                  {problem}:
                </Text>
                <Text component="span" ml={0.5} variant="body2">
                  {problemValue}
                </Text>
                <Text ml={2} variant="body2">
                  {problemNotes}
                </Text>
                {children.map((childEnum: ProblemLogEnumReference) => {
                  const {
                    problem: childProblem,
                    problemValue: childProblemValue,
                    problemNotes: childProblemNotes,
                  } = getEnumInfo(childEnum, true)
                  return (
                    <Grid
                      key={`${childProblem}-${childProblemValue}`}
                      ml={2}
                      width="100%"
                    >
                      <Text strong component="span" variant="body2">
                        {childProblem}:
                      </Text>
                      <Text component="span" ml={0.5} variant="body2">
                        {childProblemValue}
                      </Text>
                      <Text ml={2} variant="body2">
                        {childProblemNotes}
                      </Text>
                    </Grid>
                  )
                })}
              </Grid>
            )
          })}
        </>
      )}
    </>
  )
}

export default ProblemHistoryEnumInfo
