import React, { forwardRef } from 'react'
import { MenuItem, MenuItemProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { StateLabel } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    menuItem: {
      minHeight: 40,
      padding: theme.spacing(1, 2),
      '&&&&:hover': {
        backgroundColor: '#EBEBEB',
      },
    },
    menuItemSelected: {
      backgroundColor: '#DBDBDB !important',
    },
    label: {
      width: '100%',
      padding: theme.spacing(0.75, 0.5),
      lineHeight: '12px',
      fontSize: '1.6rem',
    },
  }),
  { name: 'PuiStateSelectItem' },
)

export interface PuiStateSelectItemProps extends MenuItemProps {
  hasIssue?: boolean
  isDone?: boolean
}

const PuiStateSelectItem = forwardRef<HTMLLIElement, PuiStateSelectItemProps>(
  function PuiStateSelectItem({ children, isDone, hasIssue, ...rest }, ref) {
    const classes = useStyles()
    return (
      <MenuItem
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        ref={ref}
        {...rest}
      >
        <StateLabel
          className={classes.label}
          // @ts-ignore
          component="div"
          success={isDone || false}
          warning={hasIssue || false}
        >
          {children}
        </StateLabel>
      </MenuItem>
    )
  },
)

export default PuiStateSelectItem
