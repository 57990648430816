import { gql } from '@apollo/client'

import { TEMPLATE_ITEM_FIELDS } from '../fragments/reminderSettings'

export const UPDATE_REMINDER_SETTINGS = gql`
  ${TEMPLATE_ITEM_FIELDS}
  mutation UpdateReminderSettings(
    $businessId: ID
    $settings: ReminderBusinessSettingsInput!
  ) {
    updateReminderSettings(businessId: $businessId, settings: $settings) {
      active
      templates {
        ...TemplateItemFields
      }
      defaultCommunicationMessage
      defaultReminderExpirationOffset {
        amount
        unit
      }
    }
  }
`
