import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton, PermissionArea } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { unlinkChewyAccount } from '~/store/actions/users'
import { registerWarnAlert } from '~/store/duck/uiAlerts'
import { getCRUDByArea } from '~/store/reducers/auth'

interface DisconnectChewyAccountProps {
  className?: string
  clientId: string
  onOpenDialog?: () => void
}

const DisconnectChewyAccount = ({
  clientId,
  className,
  onOpenDialog,
}: DisconnectChewyAccountProps) => {
  const { t } = useTranslation(['Tooltips', 'Dialogs', 'Common'])
  const dispatch = useDispatch()

  const { update: connectChewyAccountUpdatePermission } = useSelector(
    getCRUDByArea(PermissionArea.CONNECT_CHEWY_ACCOUNT),
  )

  const [openConfirmDisconnectAlert] = useConfirmAlert({
    type: ConfirmAlertType.DISCONNECT_CHEWY_ACCOUNT,
  })

  const handleOpenDialog = () => {
    onOpenDialog?.()
    openConfirmDisconnectAlert({
      onConfirm: (proceed) => {
        if (proceed) {
          dispatch(unlinkChewyAccount(clientId))
        } else {
          dispatch(registerWarnAlert(t('Common:NO_CHANGES_SAVED')))
        }
      },
      okButtonText: t('Common:YES'),
    })
  }

  return (
    <LinkButton
      className={className}
      disabled={!connectChewyAccountUpdatePermission}
      onClick={
        connectChewyAccountUpdatePermission ? handleOpenDialog : undefined
      }
    >
      {t('Tooltips:DISCONNECT_CHEWY_ACCOUNT')}
    </LinkButton>
  )
}

export default DisconnectChewyAccount
