import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { Defaults, Nil, TableFilter } from '@pbt/pbt-ui-components'

import { BusinessAppointmentType } from '~/types/entities/businessAppointmentType'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { detectAPIErrorType, getErrorMessage } from '~/utils/errors'

import {
  CLEAR_APPOINTMENT_TYPE_ERROR,
  CREATE_APPOINTMENT_TYPE,
  CREATE_APPOINTMENT_TYPE_FAILURE,
  CREATE_APPOINTMENT_TYPE_SUCCESS,
  FETCH_APPOINTMENT_TYPES_LIST,
  FETCH_APPOINTMENT_TYPES_LIST_FAILURE,
  FETCH_APPOINTMENT_TYPES_LIST_SUCCESS,
  FETCH_MORE_APPOINTMENT_TYPES_LIST,
  FETCH_MORE_APPOINTMENT_TYPES_LIST_FAILURE,
  FETCH_MORE_APPOINTMENT_TYPES_LIST_SUCCESS,
  SET_APPOINTMENT_TYPES_LIST_FILTERS,
  SET_APPOINTMENT_TYPES_LIST_SORTINGS,
  UPDATE_APPOINTMENT_TYPE,
  UPDATE_APPOINTMENT_TYPE_FAILURE,
  UPDATE_APPOINTMENT_TYPE_SUCCESS,
  UPDATE_APPOINTMENT_TYPES,
} from '../actions/types/appointmentTypes'
import type { RootState } from '../index'

export type AppointmentTypesState = {
  error: string | null
  errorType: string | Nil
  filters: Record<string, TableFilter>
  isCreating: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  map: Record<string, BusinessAppointmentType>
  sortings: Record<string, boolean>
  totalCount: number
  updatingAppointmentTypeId: string | Nil
}

export const INITIAL_STATE: AppointmentTypesState = {
  error: null,
  errorType: null,
  map: {},
  list: [],
  isCreating: false,
  isLoading: false,
  isUpdating: false,
  totalCount: 0,
  filters: {},
  sortings: {},
  updatingAppointmentTypeId: null,
}

const appointmentTypes = (
  state: AppointmentTypesState = INITIAL_STATE,
  action: AnyAction,
): AppointmentTypesState => {
  switch (action.type) {
    case UPDATE_APPOINTMENT_TYPES:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.appointmentTypes),
      }
    case FETCH_APPOINTMENT_TYPES_LIST:
      return {
        ...state,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        isLoading: true,
        error: null,
        errorType: null,
      }
    case FETCH_APPOINTMENT_TYPES_LIST_SUCCESS:
      return {
        ...state,
        list: R.uniq(action.list),
        totalCount: action.totalCount,
        isLoading: false,
      }
    case FETCH_APPOINTMENT_TYPES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        errorType: detectAPIErrorType(action.error.responseBody),
        isLoading: false,
      }
    case FETCH_MORE_APPOINTMENT_TYPES_LIST:
      return { ...state, isLoading: true, error: null }
    case FETCH_MORE_APPOINTMENT_TYPES_LIST_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        errorType: detectAPIErrorType(action.error.responseBody),
        isLoading: false,
      }
    case FETCH_MORE_APPOINTMENT_TYPES_LIST_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case CREATE_APPOINTMENT_TYPE:
      return { ...state, isCreating: true, error: null, errorType: null }
    case CREATE_APPOINTMENT_TYPE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        errorType: detectAPIErrorType(action.error.responseBody),
        isCreating: false,
      }
    case CREATE_APPOINTMENT_TYPE_SUCCESS:
      return {
        ...state,
        isCreating: false,
      }
    case UPDATE_APPOINTMENT_TYPE:
      return {
        ...state,
        isUpdating: true,
        updatingAppointmentTypeId: action.id,
        error: null,
        errorType: null,
      }
    case UPDATE_APPOINTMENT_TYPE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        errorType: detectAPIErrorType(action.error.responseBody),
        isUpdating: false,
        updatingAppointmentTypeId: null,
      }
    case CLEAR_APPOINTMENT_TYPE_ERROR:
      return {
        ...state,
        error: null,
        errorType: null,
      }
    case UPDATE_APPOINTMENT_TYPE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updatingAppointmentTypeId: null,
      }
    case SET_APPOINTMENT_TYPES_LIST_FILTERS:
      return {
        ...state,
        filters: action.filters || {},
      }
    case SET_APPOINTMENT_TYPES_LIST_SORTINGS:
      return {
        ...state,
        sortings: action.sortings || {},
      }
    default:
      return state
  }
}

export default appointmentTypes
export const getAppointmentTypes = (state: RootState): AppointmentTypesState =>
  state.appointmentTypes
export const getAppointmentTypesMap = (state: RootState) =>
  getAppointmentTypes(state).map
export const getAppointmentTypesList = (state: RootState) =>
  getAppointmentTypes(state).list
export const getAppointmentType = (appointmentTypeId: string | Nil) =>
  createSelector(getAppointmentTypesMap, (map) =>
    appointmentTypeId ? map[appointmentTypeId] : undefined,
  )
export const getMultipleAppointmentTypes = (ids: string[]) =>
  createSelector(getAppointmentTypesMap, (map) =>
    R.props(ids || [], map).filter(Boolean),
  )
export const appointmentColorsList = createSelector(
  getAppointmentTypesMap,
  (map) => Object.values(map).map(({ color }) => color),
)
export const getAppointmentTypesIsCreating = (state: RootState) =>
  getAppointmentTypes(state).isCreating
export const getAppointmentTypesIsLoading = (state: RootState) =>
  getAppointmentTypes(state).isLoading
export const getAppointmentTypesTotalCount = (state: RootState) =>
  getAppointmentTypes(state).totalCount
export const getAppointmentTypesListFilters = (state: RootState) =>
  getAppointmentTypes(state).filters
export const getAppointmentTypesListSortings = (state: RootState) =>
  getAppointmentTypes(state).sortings
export const getAppointmentTypesError = (state: RootState) =>
  getAppointmentTypes(state).error
export const getAppointmentTypesErrorType = (state: RootState) =>
  getAppointmentTypes(state).errorType
export const getAppointmentTypesUpdatingId = (state: RootState) =>
  getAppointmentTypes(state).updatingAppointmentTypeId
