/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Virtuoso } from 'react-virtuoso'
import ShareIcon from '@mui/icons-material/Share'
import { Button, Divider, Grid, Grow, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, Icons, PuiHighlight, Text } from '@pbt/pbt-ui-components'
import {
  ForwardArrow,
  Wellness as WellnessIcon,
} from '@pbt/pbt-ui-components/src/icons'
import { getHighlightedText } from '@pbt/pbt-ui-components/src/utils'

import FeatureToggle from '~/constants/featureToggle'
import { getCurrentBusinessIsOmniChannel } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'
import { useGetGroupSharingEnabled } from '~/utils/groupSharingUtils'

import BusinessShareIcon from '../icons/BusinessShareIcon'
import AlertLabel from '../labels/AlertLabel'
import NoSearchResults from './NoSearchResults'

const useStyles = makeStyles(
  (theme) => ({
    dropDown: {
      position: 'absolute',
      top: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: {
        top: theme.spacing(8),
        left: 0,
      },
      width: '100%',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.base, 'above'),
      boxShadow: theme.constants.blockShadow,
      padding: theme.spacing(3.5, 0, 0),
      borderRadius: '8px 8px 2px 2px',
    },
    searchResultItem: {
      padding: theme.spacing(0.5, 2),
      cursor: 'pointer',
      '&&&&:hover': {
        background: '#E5E5E5',
        boxShadow: theme.constants.selectItemSelectedShadow,
      },
    },
    results: {
      maxHeight: 25,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    searchItem: {
      maxWidth: 470,
    },
    searchItemWithSub: {
      maxWidth: 225,
    },
    searchSubItem: {
      display: 'flex',
      flex: 1,
      marginLeft: theme.spacing(2),
    },
    alert: {
      marginRight: theme.spacing(2),
      flex: 0,
    },
    text: {
      fontSize: '1.6rem',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      height: theme.spacing(3),
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    wpIconContainer: {
      marginLeft: theme.spacing(0.5),
      overflow: 'visible',
      height: theme.spacing(3),
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    wpIcon: {
      overflow: 'visible',
      height: theme.spacing(2),
      marginTop: 3,
    },
    highlightText: {
      color: theme.colors.link,
    },
    italic: {
      fontStyle: 'italic',
    },
    buttonWrapper: {
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(2),
    },
    disabledItem: {
      color: theme.colors.tabLabelDisabled,
    },
    forwardArrow: {
      color: theme.colors.selectedItem,
    },
    clientState: {
      width: 95,
    },
  }),
  { name: 'SearchResults' },
)

const useSubItemIconStyles = makeStyles(
  (theme) => ({
    sharedIconPlaceholder: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    sharedIconContainer: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.colors.link,
    },
    shareIcon: {
      width: '0.5em',
      height: '0.5em',
      marginRight: 1.5,
      marginTop: 1,
      color: theme.colors.tableBackground,
    },
  }),
  { name: 'SubItemIcon' },
)

const DEFAULT_ITEM_SIZE = 32

interface SubItemIconProps {
  shared: boolean
}

const SubItemIcon = ({ shared }: SubItemIconProps) => {
  const classes = useSubItemIconStyles()

  return shared ? (
    <Grid
      container
      item
      alignItems="center"
      className={classes.sharedIconContainer}
      justifyContent="center"
      mr={1}
    >
      <ShareIcon className={classes.shareIcon} />
    </Grid>
  ) : (
    <Grid className={classes.sharedIconPlaceholder} />
  )
}

export interface SearchResultsProps {
  allowNoSubItemsSelection?: boolean
  classes?: ClassesType<typeof useStyles>
  clientOnly?: boolean
  hasSubItems?: boolean
  hideNoSearchResults?: boolean
  itemSize?: number
  listItemHeight?: number
  onItemClick: (id: string, subItemId: string, item: any) => void
  onSuggestionClick?: (fieldsQuery: string) => void
  open?: boolean
  openSearchResults?: () => void
  results: any[]
  searchTerm: string
  showContextIds?: boolean
  suggestionsEnabled?: boolean
  visibleResults?: number
}

const SearchResults = ({
  showContextIds = false,
  hideNoSearchResults = false,
  allowNoSubItemsSelection = true,
  open,
  results,
  onItemClick,
  itemSize = DEFAULT_ITEM_SIZE,
  listItemHeight,
  visibleResults = 10,
  hasSubItems,
  searchTerm,
  suggestionsEnabled,
  openSearchResults,
  classes: classesProp,
  onSuggestionClick,
  clientOnly,
}: SearchResultsProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Common', 'Search'])

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isGroupClientSharingFeatureEnabled = useSelector(
    getFeatureToggle(FeatureToggle.GROUP_CLIENT_SHARING),
  )
  const isWPSurfaceClientStatusEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_SURFACE_CLIENT_STATUS),
  )
  const groupSharingEnabled = useGetGroupSharingEnabled()
  const isOmnichannel = useSelector(getCurrentBusinessIsOmniChannel)

  if (!open) {
    return null
  }

  const searchHighlights = searchTerm.split(' ')

  return (
    <Grow in={open}>
      <Paper className={classes.dropDown}>
        {results.length > 0 ? (
          <>
            {openSearchResults && (
              <>
                <Button
                  fullWidth
                  classes={{ root: classes.buttonWrapper }}
                  onClick={openSearchResults}
                >
                  <Text mr={1} variant="body">
                    {t('Search:SEE_ALL_RESULTS')}
                  </Text>
                  <ForwardArrow className={classes.forwardArrow} />
                </Button>
                <Divider />
              </>
            )}
            <Virtuoso
              data={results}
              defaultItemHeight={itemSize}
              /* eslint-disable-next-line react/no-unstable-nested-components */
              itemContent={(index, itemProp) => {
                const {
                  id,
                  item,
                  subItem,
                  subItemId,
                  subItemAlert,
                  noSubItemsText,
                  itemActive,
                  shared = true,
                  itemContextIds,
                  wpActive,
                  subItemWpActive,
                } = itemProp
                const isDisabled = !allowNoSubItemsSelection && !subItem

                return (
                  <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.searchResultItem}
                    onClick={() => {
                      if (!isDisabled) {
                        onItemClick(id, subItemId, item)
                      }
                    }}
                  >
                    <Grid
                      container
                      item
                      md
                      alignItems="center"
                      className={
                        hasSubItems
                          ? classes.searchItemWithSub
                          : classes.searchItem
                      }
                    >
                      <span className={classes.results}>
                        <PuiHighlight
                          classes={{
                            root: classNames(classes.text, {
                              [classes.disabledItem]: isDisabled,
                            }),
                            text: classes.text,
                          }}
                          searchTerm={getHighlightedText(
                            item,
                            searchHighlights,
                          )}
                        >
                          <span className={classes.text}>{item}</span>
                          {isOmnichannel &&
                            wpActive &&
                            isWPSurfaceClientStatusEnabled && (
                              <span className={classes.wpIconContainer}>
                                <WellnessIcon
                                  className={classes.wpIcon}
                                  height={16}
                                  variant={Icons.IconVariant.PRIMARY}
                                />
                              </span>
                            )}
                        </PuiHighlight>
                      </span>
                      {showContextIds && item && itemContextIds && (
                        <BusinessShareIcon businessIds={itemContextIds} />
                      )}
                    </Grid>
                    <Grid item className={classes.clientState}>
                      {itemActive === false && (
                        <AlertLabel
                          classes={{
                            root: classes.alert,
                          }}
                          message={t('Common:INACTIVE_ONE')}
                          variant="disabled"
                        />
                      )}
                    </Grid>

                    {(subItem || noSubItemsText) && (
                      <Grid
                        container
                        className={classNames(
                          classes.results,
                          classes.searchSubItem,
                        )}
                        justifyContent="space-between"
                        wrap="nowrap"
                      >
                        <Grid container item alignItems="center" wrap="nowrap">
                          <SubItemIcon
                            shared={
                              !isPatientSharingEnabled &&
                              isGroupClientSharingFeatureEnabled &&
                              groupSharingEnabled &&
                              shared
                            }
                          />
                          <PuiHighlight
                            classes={{
                              root: classNames(classes.text, {
                                [classes.italic]: !subItem && noSubItemsText,
                                [classes.disabledItem]: isDisabled,
                              }),
                              text: classes.highlightText,
                            }}
                            searchTerm={getHighlightedText(
                              subItem || noSubItemsText,
                              searchHighlights,
                            )}
                          >
                            <span className={classes.text}>
                              {subItem || noSubItemsText}
                            </span>
                            {isOmnichannel &&
                              subItemWpActive &&
                              isWPSurfaceClientStatusEnabled && (
                                <span className={classes.wpIconContainer}>
                                  <WellnessIcon
                                    className={classes.wpIcon}
                                    height={16}
                                    variant={Icons.IconVariant.PRIMARY}
                                  />
                                </span>
                              )}
                          </PuiHighlight>
                        </Grid>
                        {subItemAlert && (
                          <AlertLabel
                            classes={{
                              root: classes.alert,
                            }}
                            message={subItemAlert}
                            variant="disabled"
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                )
              }}
              overscan={300}
              style={{
                height:
                  listItemHeight ||
                  Math.min(results.length, visibleResults) * itemSize,
              }}
              totalCount={results.length}
            />
          </>
        ) : (
          !hideNoSearchResults && (
            <NoSearchResults
              clientOnly={clientOnly}
              query={searchTerm}
              suggestionsEnabled={suggestionsEnabled}
              onSuggestionClick={onSuggestionClick}
            />
          )
        )}
      </Paper>
    </Grow>
  )
}

export default SearchResults
