import { JSXElementConstructor } from 'react'
import { always } from 'ramda'

import { OrderType } from '~/constants/SOAPStates'

import InventoryItemActions from './actions/InventoryItemActions'
import InventoryItemActionsLineItem from './actions/InventoryItemActionsLineItem'
import LabTestActions from './actions/LabTestActions'
import LabTestActionsLineItem from './actions/LabTestActionsLineItem'
import PrescriptionItemActions from './actions/PrescriptionItemActions'
import PrescriptionItemActionsLineItem from './actions/PrescriptionItemActionsLineItem'
import ProcedureActions from './actions/ProcedureActions'
import ProcedureActionsLineItem from './actions/ProcedureActionsLineItem'
import TaskOrderActions from './actions/TaskOrderActions'
import InventoryItemContent from './content/InventoryItemContent'
import LabTestContent from './content/LabTestContent'
import PrescriptionItemContent from './content/PrescriptionItemContent'
import ProcedureContent from './content/ProcedureContent'
import InventoryItem from './InventoryItem'
import LabTest from './LabTest'
import PrescriptionItem from './PrescriptionItem'
import Procedure from './Procedure'
import TaskOrder from './TaskOrder'

export const OrderComponents: Partial<
  Record<OrderType, JSXElementConstructor<any>>
> = {
  [OrderType.INVENTORY]: InventoryItem,
  [OrderType.PROCEDURE]: Procedure,
  [OrderType.LAB_TEST]: LabTest,
  [OrderType.PRESCRIPTION]: PrescriptionItem,
  [OrderType.TASK]: TaskOrder,
}

export const OrderComponentsActions: Partial<
  Record<OrderType, JSXElementConstructor<any>>
> = {
  [OrderType.INVENTORY]: InventoryItemActions,
  [OrderType.PROCEDURE]: ProcedureActions,
  [OrderType.LAB_TEST]: LabTestActions,
  [OrderType.PRESCRIPTION]: PrescriptionItemActions,
  [OrderType.TASK]: TaskOrderActions,
}

export const OrderComponentsContents: Partial<
  Record<OrderType, JSXElementConstructor<any>>
> = {
  [OrderType.INVENTORY]: InventoryItemContent,
  [OrderType.PROCEDURE]: ProcedureContent,
  [OrderType.LAB_TEST]: LabTestContent,
  [OrderType.PRESCRIPTION]: PrescriptionItemContent,
  [OrderType.TASK]: always(null),
}

export const LineItemComponentsActions: Partial<
  Record<OrderType, JSXElementConstructor<any>>
> = {
  [OrderType.INVENTORY]: InventoryItemActionsLineItem,
  [OrderType.PROCEDURE]: ProcedureActionsLineItem,
  [OrderType.LAB_TEST]: LabTestActionsLineItem,
  [OrderType.PRESCRIPTION]: PrescriptionItemActionsLineItem,
}
