import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  AlertIconType,
  LanguageUtils,
  PuiAlert,
  PuiAlertProps,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { cancelLabOrder } from '~/store/duck/labOrders'
import { getFeatureToggle, getLabVendors } from '~/store/reducers/constants'

export interface RemoveLabOrderDialogProps extends PuiAlertProps {
  labOrderId: string
  onClose: () => void
  vendorId: string
}

const RemoveLabOrderDialog = ({
  vendorId,
  labOrderId,
  onClose,
  ...rest
}: RemoveLabOrderDialogProps) => {
  const dispatch = useDispatch()
  const LabVendors = useSelector(getLabVendors)
  const { t } = useTranslation(['Soap', 'Common'])
  const isDeleteFinalizedLabTestsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DELETE_FINALIZED_LAB_TESTS),
  )

  const handleRemove = () => {
    dispatch(cancelLabOrder(vendorId, labOrderId))
    onClose()
  }

  return (
    <PuiAlert
      cancelButtonText={t('Common:NO_KEEP_ORDER')}
      iconType={AlertIconType.WARN}
      message={
        isDeleteFinalizedLabTestsEnabled
          ? t('Soap:ARE_YOU_SURE_YOU_WANT_TO_DELETE_LAB_ORDER', {
              orderName: LanguageUtils.getConstantTranslatedName(
                vendorId,
                LabVendors,
              ),
            })
          : t('Soap:ARE_YOU_SURE_YOU_WANT_TO_CANCEL_LAB_ORDER', {
              orderName: LanguageUtils.getConstantTranslatedName(
                vendorId,
                LabVendors,
              ),
            })
      }
      okButtonText={
        isDeleteFinalizedLabTestsEnabled
          ? t('Common:YES_DELETE_ORDER')
          : t('Common:YES_CANCEL_ORDER')
      }
      onCancel={onClose}
      onClose={onClose}
      onOk={handleRemove}
      {...rest}
    />
  )
}

export default RemoveLabOrderDialog
