import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BillingAddress,
  BillingAddressForm,
  BillingAddressFormHandle,
  Nil,
} from '@pbt/pbt-ui-components'

import { fetchBillingAddresses } from '~/store/actions/payments'
import { useProcessBillingAddress } from '~/store/hooks/finance'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { getBillingAddresses } from '~/store/reducers/payments'
import { getUser } from '~/store/reducers/users'

import { BillingAddressesActions } from './BillingAddressesActions'

const useStyles = makeStyles(
  (theme) => ({
    footer: {
      borderTop: theme.constants.tabBorder,
    },
  }),
  { name: 'BillingAddresses' },
)

export interface BillingAddressesProps {
  clientId: string | Nil
  currentBillingAddress: BillingAddress | Nil
  onBack?: () => void
  onProceed: () => void
}

const BillingAddresses = ({
  currentBillingAddress,
  clientId,
  onProceed,
  onBack,
}: BillingAddressesProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const business = useSelector(getCurrentBusiness)
  const billingAddresses = useSelector(getBillingAddresses)
  const client = useSelector(getUser(clientId))

  const billingAddressRef = useRef<BillingAddressFormHandle>(null)

  useEffect(() => {
    if (clientId) {
      dispatch(fetchBillingAddresses(clientId))
    }
  }, [clientId])

  const onProcessBillingAddresses = useProcessBillingAddress({
    billingAddressRef,
    clientId,
    onProceed,
  })

  return (
    <>
      <BillingAddressForm
        billingAddresses={billingAddresses}
        business={business}
        client={client}
        currentBillingAddress={currentBillingAddress}
        ref={billingAddressRef}
      />
      <Box className={classes.footer} px={3} py={2}>
        <BillingAddressesActions
          onBack={onBack}
          onProcessBillingAddresses={onProcessBillingAddresses}
        />
      </Box>
    </>
  )
}

export default BillingAddresses
