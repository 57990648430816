import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import {
  AlertIconType,
  PuiAlert,
  PuiBaseTooltip,
  Text,
} from '@pbt/pbt-ui-components'

import {
  getBulkPricesError,
  getBulkPricesErrorType,
  getBulkPricesIsStarting,
  resetBulkPrices,
} from '~/store/duck/bulkPrices'
import { BulkPricesErrors } from '~/types'

export interface BulkPricingLinkProps {
  startNewSession: (force?: boolean) => void
}

const BulkPricingLink = ({ startNewSession }: BulkPricingLinkProps) => {
  const { t } = useTranslation('Prices')
  const dispatch = useDispatch()
  const bulkPricesIsStarting = useSelector(getBulkPricesIsStarting)
  const bulkPricesError = useSelector(getBulkPricesError)
  const bulkPricesErrorType = useSelector(getBulkPricesErrorType)

  const anotherSessionInProgress =
    bulkPricesErrorType ===
    BulkPricesErrors.ANOTHER_SESSION_HAS_IN_PROGRESS_STATUS_ERROR_TYPE
  const anotherSessionCreated =
    bulkPricesErrorType ===
    BulkPricesErrors.ANOTHER_SESSION_HAS_CREATED_STATUS_ERROR_TYPE

  const reset = () => {
    dispatch(resetBulkPrices())
  }

  return (
    <>
      <Grid container justifyContent="end" minWidth={140}>
        {bulkPricesIsStarting && (
          <Grid height="1.4rem" px={1}>
            <CircularProgress size={12} />
          </Grid>
        )}
        <PuiBaseTooltip
          open={anotherSessionInProgress}
          tooltipPlacement="top-end"
          tooltipText={bulkPricesError}
          onMouseLeave={anotherSessionInProgress ? reset : undefined}
        >
          <Text
            link
            variant="body2"
            onClick={() => {
              startNewSession()
            }}
          >
            {t('Prices:BULK_EDIT_PRICING')}
          </Text>
        </PuiBaseTooltip>
      </Grid>
      <PuiAlert
        iconType={AlertIconType.WARN}
        message={t('Prices:PRICING_UPDATE_SESSION_OPENED')}
        open={anotherSessionCreated}
        onCancel={reset}
        onClose={reset}
        onOk={() => {
          startNewSession(true)
        }}
      />
    </>
  )
}

export default BulkPricingLink
