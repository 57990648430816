import React from 'react'
import { Trans } from 'react-i18next'

import Link from '~/components/common/link/Link'

const Questions = () => (
  <Trans
    components={{ linkToQuestions: <Link to="/admin/catalog/questions" /> }}
    i18nKey="Soap:EMPTY_LIST_PLACEHOLDERS.QUESTIONS.TEXT"
  />
)
export default Questions
