import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, NumberUtils } from '@pbt/pbt-ui-components'

import ScrollableDataTable, {
  ScrollableDataTableProps,
} from '~/components/common/lists/ScrollableDataTable'
import { getSpecies } from '~/store/reducers/constants'
import { Price } from '~/types'
import { boolToYesNoString } from '~/utils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      height: 50,
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    leftColumn: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    tableTitle: {
      height: 38,
    },
  }),
  { name: 'PricesTable' },
)

export interface PricesTableProps
  extends ScrollableDataTableProps<Price, false> {
  prices: Price[]
}

const PricesTable = ({ prices, ...rest }: PricesTableProps) => {
  const classes = useStyles()
  const Species = useSelector(getSpecies)
  const { t } = useTranslation('Common')

  const priceUnits = useGetFormattedPriceUnit(prices)

  const headerRow = [
    { label: t('Common:SPECIES') },
    { label: t('Common:PRICE') },
    { label: t('Common:UNITS') },
    { label: t('Common:MINIMUM_CHARGE') },
    { label: t('Common:ALLOW_DISCOUNT') },
    { label: t('Common:TAXABLE') },
    { label: t('Common:ACTIVE_ONE') },
  ]

  return (
    <ScrollableDataTable
      duplicateDisabled
      classes={classes}
      data={prices}
      headerRow={headerRow}
      {...rest}
    >
      {({ item: price, tableCellClassName }, index) => (
        <>
          <TableCell
            className={classNames(classes.tableCell, tableCellClassName)}
          >
            {LanguageUtils.getConstantTranslatedName(price.speciesId, Species)}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {NumberUtils.formatMoney(price.price)}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {priceUnits[index]}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {NumberUtils.formatMoney(price.minCharge)}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {boolToYesNoString(price.discountAllowed)}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {boolToYesNoString(price.taxable)}
          </TableCell>
          <TableCell className={tableCellClassName}>
            {boolToYesNoString(price.active)}
          </TableCell>
        </>
      )}
    </ScrollableDataTable>
  )
}

export default PricesTable
