import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    warningIcon: {
      width: '0.75em',
      height: '0.75em',
      fill: theme.colors.important,
      marginRight: theme.spacing(0.5),
    },
  }),
  { name: 'NotAssignedPatient' },
)

export interface NotAssignedPatientProps {
  classes?: ClassesType<typeof useStyles>
}

const NotAssignedPatient = ({
  classes: classesProp,
}: NotAssignedPatientProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  return (
    <Grid container alignItems="center" className={classes.root} wrap="nowrap">
      <WarningIcon className={classes.warningIcon} />
      <Dotdotdot clamp={1}>
        <Text variant="body2">{t('Common:UNASSIGNED')}</Text>
      </Dotdotdot>
    </Grid>
  )
}

export default NotAssignedPatient
