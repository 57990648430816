import { Nil } from '@pbt/pbt-ui-components'

import { LabTestDashboardItem, TableFilter } from '~/types'

import { getAPIPath, request } from './core'
import { CreateLabTestLogFileGroupInput } from './graphql/generated/types'
import { requestMutation, requestQuery } from './graphql/graphqlCore'
import {
  CREATE_LAB_TEST_LOG_FILE_GROUP,
  DELETE_LAB_TEST_LOG_FILE,
} from './graphql/mutations/labTestLogFileGroup'
import { FETCH_LAB_TEST_LOG_FILE_GROUPS } from './graphql/queries/labTestLogFileGroup'
import * as Schema from './schemas'

export const fetchLabTestsForDashboard = (
  businessId: string,
  filters: Record<string, TableFilter['value']>,
  from: number,
  to: number,
  addOrphans: boolean = true,
) =>
  request(
    getAPIPath('labtest/dashboard/page'),
    {
      method: 'POST',
      params: { businessId, from, to, addOrphans },
      data: filters,
    },
    true,
    Schema.labTestsDashboardRecordsList,
  )

export const assignLabResult = (
  businessId: string,
  orderId: string | Nil,
  resultId: string | Nil,
  vendorId: string | Nil,
  vetId: string,
  patientId: string,
  clientId: string,
  eventId?: string,
) =>
  request(
    getAPIPath('labtest/dashboard/result/assign'),
    {
      method: 'PUT',
      params: { businessId },
      data: {
        eventId,
        orderId,
        resultId,
        vendorId,
        vetId,
        patientId,
        clientId,
      },
    },
    true,
  )

export const saveLabOrder = (
  businessId: string,
  labOrder: Omit<LabTestDashboardItem, 'client' | 'patient'>,
) =>
  request(
    getAPIPath('labtest/dashboard'),
    { method: 'PUT', params: { businessId }, data: labOrder },
    true,
    Schema.labTestsDashboardRecord,
  )

export const fetchLabTestDetails = (
  businessId: string,
  vendorId: string | Nil,
  orderId: string | Nil,
  soapId: string | Nil,
  invoiceId: string | Nil,
  patientId: string | Nil,
  vetId: string | Nil,
) =>
  request(
    getAPIPath('labtest/dashboard'),
    {
      method: 'GET',
      params: {
        businessId,
        vendorId,
        orderId,
        soapId,
        invoiceId,
        patientId,
        vetId,
      },
    },
    true,
    Schema.labTestsDashboardRecord,
  )

export const saveLabTestFileGroup = requestMutation({
  mutation: CREATE_LAB_TEST_LOG_FILE_GROUP,
  variablesHandler: (
    businessId: string,
    labTestLogId: string,
    labTestFileGroupInput: CreateLabTestLogFileGroupInput,
  ) => ({
    labTestLogId,
    input: labTestFileGroupInput,
  }),
})

export const deleteLabTestFile = requestMutation({
  mutation: DELETE_LAB_TEST_LOG_FILE,
  variablesHandler: (
    businessId: string,
    labTestLogId: string,
    groupId: string,
    fileId: string,
  ) => ({
    labTestLogId,
    groupId,
    fileId,
  }),
})

export const fetchLabTestLogFileGroups = requestQuery({
  query: FETCH_LAB_TEST_LOG_FILE_GROUPS,
  variablesHandler: (businessId: string, labTestLogId: string) => ({
    labTestLogId,
  }),
})
