import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Table, TableBody } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Defaults, Text, useInterval } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import PrescriptionEditPostedModal from '~/components/dashboard/soapV2/charges/PrescriptionEditPostedModal'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { getCharges } from '~/store/duck/charges'
import {
  fetchChargeSheetLineItemByLogId,
  fetchChargeSheetRetailOrderLineItem,
  getChargeSheetLineItemLoading,
  getCurrentChargeSheetLineItem,
  resetEditingLineItemState,
} from '~/store/duck/clientFinanceData'
import {
  clearDevices,
  clearLabOrders,
  fetchDevices,
  fetchLabOrders,
  getLabOrders,
} from '~/store/duck/labOrders'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getFinanceInvoice } from '~/store/reducers/finance'
import { getSelectedOrders } from '~/store/reducers/orders'
import {
  getClientId,
  getInvoiceId,
  getIsFinalized,
  getPatientId,
  getSoapId,
} from '~/store/reducers/soap'
import { Invoice, InvoiceLineItem, LabOrder, Order } from '~/types'
import { isChargesInvoiceType } from '~/utils/finance'
import useDialog from '~/utils/useDialog'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import { isGroupedInvoiceItem } from '../../invoices/invoiceUtils'
import InvoicePostedBanner from '../../soap/order/InvoicePostedBanner'
import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import ChargesWidgetLockedItem from './ChargesWidgetLockedItem'
import ChargeInformationPanel from './information-panel/ChargeInformationPanel'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    invoicePostedBanner: {
      marginBottom: theme.spacing(2),
    },
    titleContainer: {
      borderRight: theme.constants.tabBorder,
    },
    table: {
      border: theme.constants.tabBorder,
      borderRadius: 2,
    },
  }),
  { name: 'ChargesWidgetLocked' },
)

const ChargesWidgetLocked = () => {
  const { t } = useTranslation(['Common', 'Soap'])
  const classes = useStyles()
  const dispatch = useDispatch()

  const selectedOrders = useSelector(getSelectedOrders)
  const clientId = useSelector(getClientId)
  const patientId = useSelector(getPatientId)
  const soapId = useSelector(getSoapId)
  const labOrders = useSelector(getLabOrders)
  const isLoading = useSelector(getChargeSheetLineItemLoading)
  const invoiceId = useSelector(getInvoiceId)
  const invoice = useSelector(getFinanceInvoice(invoiceId))
  const currentChargeSheetLineItem = useSelector(getCurrentChargeSheetLineItem)
  const charges = useSelector(getCharges)
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const isFinalized = useSelector(getIsFinalized)
  const areChargesPostedAndEditable =
    isEditPostedChargesEnabled && isChargesInvoiceType(charges) && !isFinalized

  const [selectedItem, setSelectedItem] = useState<Order>()
  const [isChargeInformationPanelOpened, setIsChargeInformationPanelOpened] =
    useState(false)

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )

  useEffect(() => {
    if (soapId) {
      dispatch(fetchLabOrders(soapId))
      dispatch(fetchDevices())
    }
    return () => {
      dispatch(clearLabOrders())
      dispatch(clearDevices())
    }
  }, [soapId])

  useInterval(() => {
    dispatch(fetchLabOrders(soapId))
  }, Defaults.LAB_ORDERS_UPDATE_INTERVAL)

  useWSTopic({
    wsTopic: WSTopics.CHARGES_UNPOSTED_ON_SOAP,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
        clientId,
      },
    },
    unsubscribe: true,
  })

  useWSTopic({
    wsTopic: WSTopics.CHARGES_SPECIAL_PROCEDURE,
    context: {
      retrySubscription: false,
      topicParams: {
        patientId,
      },
    },
    unsubscribe: true,
  })

  const getLabOrder = (orderId: string | undefined, vendorId?: string) =>
    R.pipe(
      R.find<LabOrder>(R.propEq('vendorId', vendorId)),
      R.propOr([], 'orders'),
      R.find<LabOrder>(R.propEq('id', orderId)),
    )(labOrders)

  const handlePrintLabel = (order: Order, vendorId?: string) => {
    const labOrder = getLabOrder(order?.labTest?.labOrderId, vendorId)

    openPrintLabOrderLabelDialog({
      clientId,
      patientId,
      labOrderId: labOrder?.labOrderId,
      orderDate: labOrder?.date,
      vendorId,
    })
  }

  const closeChargeInformationPanel = () => {
    setIsChargeInformationPanelOpened(false)
    setSelectedItem(undefined)
    dispatch(resetEditingLineItemState())
  }
  const getBundleItemId = (item: Order) =>
    item.id ? item.id : `charges-table-${item.soapId}_${item.group}`

  const onSelectItem = (
    item: InvoiceLineItem | RetailOrderLineItem | Order,
  ) => {
    const order = item as Order
    setIsChargeInformationPanelOpened(true)

    if ('retailOrderLineItem' in order && isChewyCheckoutEnabled) {
      dispatch(
        fetchChargeSheetRetailOrderLineItem({
          id: order.retailOrderLineItem!.id,
        }),
      )
    } else {
      dispatch(
        fetchChargeSheetLineItemByLogId({
          logId: order.id,
          logType: order.type || order.logType,
        }),
      )
    }

    if (!isGroupedInvoiceItem(order)) {
      setSelectedItem(order)
    } else {
      const newItem = { ...order, id: getBundleItemId(order) }
      setSelectedItem(newItem)
    }
  }

  return (
    <>
      {isEditPostedChargesEnabled && (
        <PrescriptionEditPostedModal
          areChargesPostedAndEditable={areChargesPostedAndEditable}
        />
      )}
      {selectedItem && (
        <ChargeInformationPanel
          editDisabled
          isInvoice
          isSoap
          ComponentsActions={LineItemComponentsActions}
          classes={classes}
          invoice={invoice as Invoice}
          isLoading={isLoading}
          isOpened={isChargeInformationPanelOpened}
          item={currentChargeSheetLineItem}
          onClose={closeChargeInformationPanel}
          onSelectItem={onSelectItem}
        />
      )}
      <InvoicePostedBanner className={classes.invoicePostedBanner} />
      <Grid container item className={classes.root} wrap="nowrap">
        <Grid item className={classes.titleContainer} px={1.5} py={2} xs={2}>
          <Text variant="h4">{t('Common:ORDER')}</Text>
        </Grid>
        <Grid item xs pb={3} pt={1.5} px={3}>
          {selectedOrders.length > 0 ? (
            <Table className={classes.table}>
              <TableBody>
                {selectedOrders.map((order, index) => (
                  <ChargesWidgetLockedItem
                    areChargesPostedAndEditable={areChargesPostedAndEditable}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    order={order}
                    onPrint={handlePrintLabel}
                    onSelectItem={onSelectItem}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Text variant="body">{t('Soap:SOAP_ORDERS_LOCKED.NO_ITEMS')}</Text>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ChargesWidgetLocked
