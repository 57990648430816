import * as R from 'ramda'

import {
  MigrationImportRequestType,
  MigrationSessionFile,
  MigrationSessionSettings,
  MigrationSessionStage,
  MigrationSessionType,
  MigrationTaskType,
  RhapsodyExporterTypes,
} from '~/types/entities/migrationV2'
import { MigrationException } from '~/types/entities/migrationV2/migrationExceptions'
import { objectToFormData } from '~/utils'

import { getPreludeAPIPath, request } from './core'
import * as Schema from './schemas'

const DEFAULT_PAGE_SIZE = 50

export const fetchV3ImportSessionsByBusinessId = (
  currentBusinessId: string,
  businessId: string,
) =>
  request(
    getPreludeAPIPath('import-api/sessions/v3'),
    {
      method: 'GET',
      params: { business: businessId, businessId: currentBusinessId },
    },
    true,
    Schema.importSessionArray,
  )

export const fetchV3ImportSessionDetails = (
  currentBusinessId: string,
  sessionId: string,
) =>
  request(
    getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/details`),
    {
      method: 'GET',
      params: { businessId: currentBusinessId },
    },
    true,
  )

export const createV3ImportSession = (
  currentBusinessId: string,
  businessId: string,
  exporterPimsId: string,
  sourcePimsId: string,
) =>
  request(
    getPreludeAPIPath('import-api/sessions/v3'),
    {
      method: 'POST',
      data: {
        sourcePimsId,
        exporterPimsId,
        businessId,
        type: MigrationSessionType.RHAPSODY_ENTERPRISE,
        stage: MigrationSessionStage.NEW,
      },
      params: { businessId: currentBusinessId },
    },
    true,
  )

export const updateV3ImportSessionStage = (
  currentBusinessId: string,
  userEmail: string,
  sessionId: string,
  newStage: MigrationSessionStage,
) =>
  request(getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/stage`), {
    method: 'PUT',
    data: {
      stageTo: newStage,
      username: userEmail,
    },
    params: { businessId: currentBusinessId },
  })

export const updateV3ImportSessionSettings = (
  currentBusinessId: string,
  userEmail: string,
  sessionId: string,
  settings: MigrationSessionSettings,
) =>
  request(getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/settings`), {
    method: 'PUT',
    data: {
      ...settings,
      username: userEmail,
    },
    params: { businessId: currentBusinessId },
  })

export const fetchV3ImportSessionFiles = (
  currentBusinessId: string,
  sessionId: string,
) =>
  request(getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/files`), {
    method: 'GET',
    params: { businessId: currentBusinessId },
  })

export const fetchV3ImportSessionFileUrl = (
  currentBusinessId: string,
  sessionId: string,
  fileName: string,
  fileExtension: string,
) =>
  request(getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/files/url`), {
    method: 'GET',
    params: {
      businessId: currentBusinessId,
      name: fileName,
      extension: fileExtension,
    },
  })

export const uploadV3ImportSessionFile = (
  currentBusinessId: string,
  sessionId: string,
  file: MigrationSessionFile,
) =>
  request(getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/files`), {
    method: 'POST',
    params: { businessId: currentBusinessId },
    data: objectToFormData({ ...file, file: file.raw }),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const deleteV3ImportSessionFile = (
  currentBusinessId: string,
  sessionId: string,
  fileName: string,
  fileExtension: string,
) =>
  request(getPreludeAPIPath(`import-api/sessions/v3/${sessionId}/files`), {
    method: 'DELETE',
    params: {
      businessId: currentBusinessId,
      name: fileName,
      extension: fileExtension,
    },
  })

export const fetchV3RhapsodyExporterUrl = (
  currentBusinessId: string,
  type: RhapsodyExporterTypes,
) =>
  request(getPreludeAPIPath('import-api/enterprise/v3/exporter'), {
    method: 'GET',
    params: {
      businessId: currentBusinessId,
      type,
    },
  })

export const fetchV3ImportRequests = (
  currentBusinessId: string,
  importSessionId: string,
  from = 0,
) =>
  request(
    getPreludeAPIPath('import-api/requests/v3'),
    {
      method: 'GET',
      params: {
        businessId: currentBusinessId,
        types: MigrationImportRequestType.FULL,
        importSessionId,
        from,
        to: R.isNil(from) ? undefined : from + DEFAULT_PAGE_SIZE,
      },
    },
    true,
    Schema.importRequest,
  )

export const fetchV3ImportTasks = (
  currentBusinessId: string,
  importRequestId: string,
  from = 0,
) =>
  request(
    getPreludeAPIPath(`import-api/requests/v3/${importRequestId}/tasks`),
    {
      method: 'GET',
      params: {
        businessId: currentBusinessId,
        from,
        to: R.isNil(from) ? undefined : from + DEFAULT_PAGE_SIZE,
      },
    },
    true,
    Schema.importTask,
  )

export const fetchV3ImportSupportedEntities = (
  currentBusinessId: string,
  sessionId: string,
) =>
  request(getPreludeAPIPath('import-api/tasks/v3/available'), {
    method: 'GET',
    params: {
      businessId: currentBusinessId,
      sessionId,
    },
  })

export const createV3ImportTask = (
  currentBusinessId: string,
  requestId: string,
  type: MigrationTaskType,
  entities?: string[],
) =>
  request(getPreludeAPIPath('import-api/tasks/v3/start'), {
    method: 'POST',
    params: {
      businessId: currentBusinessId,
    },
    data: {
      requestId,
      type,
      entities,
    },
  })

export const fetchV3ImportExceptions = (
  currentBusinessId: string,
  importSessionId: string,
  withResolved: boolean = false,
) =>
  request(
    getPreludeAPIPath('import-mapping/v3/mappings'),
    {
      method: 'GET',
      params: {
        businessId: currentBusinessId,
        importSessionId,
        withResolved,
      },
    },
    true,
    [Schema.importMapping],
  )

export const updateV3ImportExceptions = (
  currentBusinessId: string,
  importExceptionId: string,
  exception: Partial<MigrationException>,
) =>
  request(
    getPreludeAPIPath(`import-mapping/v3/mappings/${importExceptionId}`),
    {
      method: 'PATCH',
      data: exception,
      params: {
        businessId: currentBusinessId,
      },
    },
  )
