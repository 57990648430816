export const ColorNames = {
  BLUE: 'BLUE',
  YELLOW: 'YELLOW',
  GREY: 'GREY',
  GREEN: 'GREEN',
  RED: 'RED',
}

export enum ColorVariant {
  NORMAL = 'normal',
  IMPORTANT = 'important',
  DISABLED = 'disabled',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const ColorToStatusName = {
  [ColorNames.BLUE]: ColorVariant.NORMAL,
  [ColorNames.YELLOW]: ColorVariant.IMPORTANT,
  [ColorNames.GREY]: ColorVariant.DISABLED,
  [ColorNames.GREEN]: ColorVariant.SUCCESS,
}
