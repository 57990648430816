import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  BlobWithName,
  Defaults,
  LanguageUtils,
  moment,
  NamedEntity,
  Nil,
  Patient,
  UnitUtils,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import FeatureToggle from '~/constants/featureToggle'

import { createPatient as createPatientAction } from '../actions/patients'
import { getUnitsState } from '../duck/settings'
import { getCurrentBusinessId } from '../reducers/auth'
import { getFeatureToggle, getFullAlertType } from '../reducers/constants'
import { getPatient } from '../reducers/patients'

interface UseAlertTypeReturnType {
  alertsEntities: NamedEntity[] | []
  alertsIds: string[] | []
  alertsToRender: string[] | []
  customAlert: string | null
}

export const useAlertType = (
  patientId?: string | Nil,
): UseAlertTypeReturnType => {
  const patient = useSelector(getPatient(patientId))
  const FullAlertTypes: NamedEntity[] = useSelector(getFullAlertType)

  if (!patient) {
    return {
      alertsToRender: [],
      alertsEntities: [],
      alertsIds: [],
      customAlert: '',
    }
  }

  if (R.isEmpty(patient.alertTypeIds)) {
    const alerts: NamedEntity[] = FullAlertTypes.filter((type) =>
      patient.alertTypes?.includes(type.name),
    )
    const alertTypeIds: string[] = R.pluck('id', alerts).filter(Boolean)
    const customAlertType: string =
      patient.alertTypes?.find(
        (alert) => !FullAlertTypes.some(R.propEq('name', alert)),
      ) || ''
    const alertsToRender: string[] = [
      ...alerts.map((alert: NamedEntity) =>
        LanguageUtils.getConstantTranslatedName(alert?.id, alerts),
      ),
      customAlertType,
    ].filter(Boolean)

    return {
      alertsToRender,
      alertsEntities: alerts,
      alertsIds: alertTypeIds,
      customAlert: patient.customAlertType || customAlertType,
    }
  }

  const alerts: NamedEntity[] = FullAlertTypes.filter((type) =>
    patient.alertTypeIds?.includes(type.id),
  )
  const alertsToRender: string[] = [
    ...alerts.map((alert: NamedEntity) =>
      LanguageUtils.getConstantTranslatedName(alert?.id, alerts),
    ),
    patient.customAlertType || '',
  ].filter(Boolean)

  return {
    alertsToRender,
    alertsEntities: alerts,
    alertsIds: patient.alertTypeIds || [],
    customAlert: patient.customAlertType || '',
  }
}

export const usePatientWeight = (
  patientId: string | Nil,
  format = Defaults.DATE_FORMAT,
) => {
  const unitsState = useSelector(getUnitsState)
  const patient = useSelector(getPatient(patientId))

  if (patient?.weight) {
    const value = UnitUtils.convertUnitsForDisplay(
      UnitTypes.WEIGHT,
      patient.weight.value,
      unitsState,
    )
    return `${value}\u00A0${unitsState.weight}\u00A0(${moment(
      patient.weight.date,
    ).format(format)})`
  }
  return undefined
}

export const usePatientGoodToKnow = (patientId: string | Nil) => {
  const patient = useSelector(getPatient(patientId))
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const { patientScopeGoodToKnows, goodToKnow: patientGoodToKnow } =
    patient || {}

  const contextGoodToKnow = patientScopeGoodToKnows?.find(
    (item) => item.businessId === currentBusinessId,
  )?.goodToKnow

  const goodToKnow = isPatientSharingEnabled
    ? contextGoodToKnow || patientScopeGoodToKnows?.[0]?.goodToKnow
    : patientGoodToKnow

  const goodToKnowEditable = isPatientSharingEnabled
    ? Boolean(
        contextGoodToKnow ||
          !patientScopeGoodToKnows ||
          R.isEmpty(patientScopeGoodToKnows),
      )
    : true

  return {
    goodToKnow,
    goodToKnowEditable,
  }
}

export const useCreatePatient = () => {
  const dispatch = useDispatch()

  const createPatient = (
    client: string,
    patient: Omit<Patient, 'id'>,
    avatarBlob?: BlobWithName | Nil,
  ) => {
    dispatch(createPatientAction(client, patient, avatarBlob))
  }

  return {
    createPatient,
  }
}
