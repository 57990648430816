import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  AppointmentEventType,
  EventTypeName,
  LanguageUtils,
  SelectEntity,
} from '@pbt/pbt-ui-components'

import { fetchAppointmentTypesList } from '~/store/actions/appointmentTypes'
import {
  getAppointmentTypesList,
  getAppointmentTypesMap,
} from '~/store/reducers/appointmentTypes'
import { getCurrentBusiness } from '~/store/reducers/auth'
import { useEventType } from '~/utils/useEventType'

export const useAppointmentTypes = () => {
  const dispatch = useDispatch()
  const currentBusiness = useSelector(getCurrentBusiness)
  const appointmentTypesIds = useSelector(getAppointmentTypesList)
  const appointmentTypes = useSelector(getAppointmentTypesMap)
  const AppointmentEventSubTypes: AppointmentEventType['subTypes'] =
    useEventType(EventTypeName.Appointment, 'subTypes')

  useEffect(() => {
    if (!appointmentTypesIds.length && currentBusiness?.id) {
      dispatch(
        fetchAppointmentTypesList({
          businessId: currentBusiness.id,
          searchFilter: {
            from: 0,
            to: 200,
          },
        }),
      )
    }
  }, [appointmentTypesIds.length, currentBusiness?.id])

  const createNestedAppointmentTypes = useCallback(() => {
    const appointmentTypeMap = new Map<string, SelectEntity>()

    appointmentTypesIds.forEach((id) => {
      const item = appointmentTypes[id]
      if (!item.enabled) {
        return
      }
      const baseType = LanguageUtils.getConstantTranslatedName(
        item.eventTypeId,
        AppointmentEventSubTypes,
      )
      const subtype = item.name

      if (!appointmentTypeMap.has(baseType)) {
        appointmentTypeMap.set(baseType, {
          name: baseType,
          nameTranslation: baseType,
          id: baseType,
          pinColor: subtype ? undefined : item.color,
          subitems: [],
        })
      }

      if (subtype) {
        const parent = appointmentTypeMap.get(baseType)
        if (parent?.subitems) {
          parent.subitems.push({
            pinColor: item.color,
            name: subtype,
            value: item.id,
            id: item.id,
          })
        }
      }
    })
    return R.pipe(
      (map) => R.values(Object.fromEntries(map)),
      R.map((item: SelectEntity) =>
        item.subitems && item.subitems.length === 1 ? item.subitems[0] : item,
      ),
      R.sortBy(R.prop('name')),
    )(appointmentTypeMap)
  }, [appointmentTypesIds])

  return createNestedAppointmentTypes()
}
