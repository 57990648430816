import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { Text, TextProps } from '@pbt/pbt-ui-components'

import { getPatient } from '~/store/reducers/patients'

interface PatientFormTitleProps extends Omit<TextProps, 'variant'> {
  patientId?: string
}

const PatientFormTitle = ({ patientId, ...rest }: PatientFormTitleProps) => {
  const patient = useSelector(getPatient(patientId))
  const { t } = useTranslation(['Common', 'Clients'])

  const isEdit = !R.isNil(patient)
  const patientTitle = isEdit
    ? t('Clients:PATIENT_SECTION.BUTTON.ADD_INFORMATION_ABOUT_PATIENT', {
        patientName: patient.name,
      })
    : t('Common:NEW_PATIENT')

  return (
    <Text variant="hero2" {...rest}>
      {patientTitle}
    </Text>
  )
}

export default PatientFormTitle
