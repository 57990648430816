import { EditorType } from '@pbt/pbt-ckeditor-ui'

import { EditorDocumentType } from '~/types'

import { getPreludeAPIPath, request } from './core'

export const fetchCKEditorTokenURL = (businessId: string) =>
  request(
    getPreludeAPIPath('ckeditor/v1/ckeditor/token'),
    {
      method: 'GET',
      params: { businessId },
    },
    true,
  )

export const initializeChannelId = (
  businessId: string,
  documentType: EditorDocumentType,
  bundleType: EditorType,
  data: any,
  restore = false,
) =>
  request(
    getPreludeAPIPath('ckeditor/v1/ckeditor/channel/init'),
    {
      method: 'POST',
      params: { businessId },
      data: {
        documentType,
        bundleType,
        ...data,
        restore,
      },
    },
    true,
  )

export const restoreCollaborationSession = (
  businessId: string,
  documentType: EditorDocumentType,
  bundleType: EditorType,
  data: any,
) =>
  request(
    getPreludeAPIPath('ckeditor/v1/ckeditor/channel/restore'),
    {
      method: 'PUT',
      params: { businessId },
      data: {
        documentType,
        bundleType,
        ...data,
      },
    },
    true,
  )
