import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ControlButtonGroup,
  ControlButtonGroupName,
  Nil,
  NumberUtils,
  PermissionArea,
  Text,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import InfoList, { InfoListItem } from '~/components/common/InfoList'
import EnumSelect from '~/components/common/inputs/EnumSelect'
import { ShipmentItemStatus } from '~/constants/shipments'
import { usePackagingValue } from '~/store/hooks/inventories'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getInventoryProductSizeUnit,
  getInventoryShipmentItemStatus,
  getPackageType,
} from '~/store/reducers/constants'
import { DataHandle, ShipmentItem, Variation } from '~/types'
import useGetInStockAmount from '~/utils/useGetInStockAmount'

import {
  getOnHandAmount,
  // @ts-ignore
} from '../inventoryUtils'
import ShipmentItemComponent, { ShipmentItemHandle } from './ShipmentItem'
import { ShipmentItemsTableName } from './ShipmentItemsTableName'

const useStyles = makeStyles(
  (theme) => ({
    accordion: {
      maxWidth: '100%',
      '&:before': {
        display: 'none',
      },
      '&&$expanded': {
        margin: '0 0 8px',
      },
      margin: '0 0 8px',
      boxShadow: 'none',
      border: theme.constants.tableBorder,
    },
    expanded: {},
    accordionSummary: {
      padding: 0,
      minHeight: 'unset',
      '&&$expanded': {
        minHeight: 'unset',
      },
    },
    accordionSummaryExpandedBorder: {
      borderBottom: theme.constants.tableBorder,
    },
    accordionSummaryContent: {
      maxWidth: '100%',
      margin: 0,
      '&&$expanded': {
        margin: 0,
      },
    },
    icons: {
      marginLeft: 'auto',
      minWidth: 168,
    },
    reorderHint: {
      padding: theme.spacing(1),
    },
    reorderHintTitle: {
      width: 140,
      minWidth: 140,
    },
    truncated: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'ShipmentItemAccordion' },
)

export interface ShipmentItemAccordionProps {
  defaultExpanded: boolean
  expanded: boolean
  onChange: () => void
  onClone: (event: React.MouseEvent) => void
  onDelete: (event: React.MouseEvent) => void
  onToggle: () => void
  shipmentItem: ShipmentItem | Nil
  taxDisabled?: boolean
  variation: Variation | Nil
}

export interface ShipmentItemAccordionHandle extends DataHandle {}

const ShipmentItemAccordion = forwardRef(function ShipmentItemAccordion(
  {
    shipmentItem,
    variation,
    defaultExpanded,
    expanded,
    onDelete,
    onClone,
    onToggle,
    onChange,
    taxDisabled,
  }: ShipmentItemAccordionProps,
  ref: ForwardedRef<ShipmentItemAccordionHandle>,
) {
  const classes = useStyles()
  const permissions = useSelector(getCRUDByArea(PermissionArea.SHIPMENTS))
  const PackageType = useSelector(getPackageType)
  const InventoryProductSizeUnit = useSelector(getInventoryProductSizeUnit)
  const InventoryShipmentItemStatus = useSelector(
    getInventoryShipmentItemStatus,
  )
  const { t } = useTranslation('Common')

  const DraftStatusId = Utils.findConstantIdByName(
    ShipmentItemStatus.DRAFT,
    InventoryShipmentItemStatus,
  )

  const shipmentItemRef = useRef<ShipmentItemHandle>(null)

  const {
    fields: { statusId },
    validate,
    reset,
  } = useFields([
    {
      name: 'statusId',
      type: 'select',
      label: t('Common:STATUS'),
      validators: ['required'],
      initialValue: shipmentItem?.statusId || DraftStatusId,
    },
  ])

  useEffect(() => {
    reset()
  }, [shipmentItem?.statusId])

  useImperativeHandle(ref, () => ({
    validate: () => {
      const valid = validate() && (shipmentItemRef.current?.validate() ?? true)
      if (!valid && !expanded) {
        onToggle()
      }
      return valid
    },
    get: () => ({
      ...shipmentItemRef.current?.get(),
      statusId: statusId.value,
    }),
  }))

  const getInStockAmount = useGetInStockAmount()
  const packagingValue = usePackagingValue(shipmentItem)

  const getOnHandAmountLabel = getOnHandAmount(
    InventoryProductSizeUnit,
    PackageType,
  )
  const onHandAmount = getOnHandAmountLabel(variation)

  const tooltipItems = [
    variation?.reorderPoint && {
      name: t('Common:INVENTORY_RE-ORDER_POINT'),
      value: getInStockAmount({
        amount: variation.reorderPoint,
        amountUnitsId: variation.reorderPointUnitId,
        perPackageUnitsId: variation.perPackageUnitsId,
        packageTypeId: variation.packageTypeId,
      }),
    },
    variation?.reorderQuantity && {
      name: t('Common:INVENTORY_RE-ORDER_QUANTITY'),
      value: getInStockAmount({
        amount: variation.reorderQuantity,
        amountUnitsId: variation.reorderQuantityUnitId,
        perPackageUnitsId: variation.perPackageUnitsId,
        packageTypeId: variation.packageTypeId,
      }),
    },
    variation?.maxQuantity && {
      name: t('Common:MAXIMUM_QUANTITY'),
      value: getInStockAmount({
        amount: variation.maxQuantity,
        amountUnitsId: variation.maxQuantityUnitId,
        perPackageUnitsId: variation.perPackageUnitsId,
        packageTypeId: variation.packageTypeId,
      }),
    },
    {
      name: t('Common:ON-HAND'),
      value: onHandAmount,
    },
  ].filter(Boolean) as InfoListItem[]

  const stockAmount = getInStockAmount(shipmentItem)
  return (
    <Accordion
      classes={{
        root: classes.accordion,
        expanded: classes.expanded,
      }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummary,
          content: classNames(
            classes.accordionSummaryContent,
            expanded && classes.accordionSummaryExpandedBorder,
          ),
          expanded: classes.expanded,
        }}
        onClick={onToggle}
      >
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item zeroMinWidth xs={expanded ? 11 : 5}>
            {shipmentItem?.name && (
              <ShipmentItemsTableName
                ml={2}
                name={shipmentItem.name}
                variant="body2"
              />
            )}
          </Grid>
          {!expanded && (
            <>
              <Grid item xs={2}>
                <Text variant="body2">
                  {NumberUtils.formatMoney(shipmentItem?.costTotal)}
                </Text>
              </Grid>
              <Grid item xs={2}>
                <Text variant="body2">{stockAmount}</Text>
              </Grid>
              <Grid item xs={2}>
                <Text variant="body2">{packagingValue}</Text>
              </Grid>
            </>
          )}
          <Grid item xs={2}>
            <EnumSelect
              accent
              Constant={InventoryShipmentItemStatus}
              field={statusId}
            />
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            className={classes.icons}
            justifyContent="flex-end"
            xs={1}
          >
            <ControlButtonGroup
              buttons={[
                permissions.delete && {
                  name: ControlButtonGroupName.DELETE,
                  onClick: onDelete,
                },
                {
                  name: ControlButtonGroupName.INFO,
                  tooltip: (
                    <InfoList
                      className={classes.reorderHint}
                      classes={{ title: classes.reorderHintTitle }}
                      items={tooltipItems}
                    />
                  ),
                },
                permissions.create && {
                  name: ControlButtonGroupName.DUPLICATE,
                  onClick: onClone,
                },
                expanded
                  ? {
                      name: ControlButtonGroupName.COLLAPSE,
                      onClick: onToggle,
                    }
                  : {
                      name: ControlButtonGroupName.EXPAND,
                      onClick: onToggle,
                    },
              ]}
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <ShipmentItemComponent
          ref={shipmentItemRef}
          shipmentItem={shipmentItem}
          taxDisabled={taxDisabled}
          onChange={onChange}
        />
      </AccordionDetails>
    </Accordion>
  )
})

export default ShipmentItemAccordion
