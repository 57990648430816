import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { Grid } from '@mui/material'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import ColorPinIcon from '~/components/common/icons/ColorPinIcon'
import { TimetableEvent } from '~/types'

import { formatDateRange } from './widgetUtils'

interface DateRangeCellProps {
  item?: TimetableEvent
}

const DateRangeCell = ({ item }: DateRangeCellProps) => {
  const color = item?.businessAppointmentType?.color

  const name = LanguageUtils.getTranslatedFieldName(
    item?.businessAppointmentType,
  )

  return (
    <Grid container item alignItems="center" wrap="nowrap">
      <ColorPinIcon color={color} mr={1} tooltipText={name} />
      <Dotdotdot clamp={1}>
        <Text strong variant="body2">
          {formatDateRange(
            item?.scheduledStartDatetime,
            item?.scheduledEndDatetime,
          )}
        </Text>
      </Dotdotdot>
    </Grid>
  )
}

export default DateRangeCell
