import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { LanguageUtils, Text } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import {
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import { useIsGlobalInventoryItem } from '~/store/hooks/orders'
import {
  getFeatureToggle,
  getInventoryCategory,
  getProcedureCategory,
} from '~/store/reducers/constants'
import { InvoiceLineItem } from '~/types'
import { getInventoryItemCategoryId } from '~/utils/orderUtils'

export interface ChargeItemTitleProps {
  item: InvoiceLineItem | RetailOrderLineItem
}

const ChargeItemTitle = ({ item }: ChargeItemTitleProps) => {
  const { t } = useTranslation('Common')

  const InventoryCategory = useSelector(getInventoryCategory)
  const ProcedureCategory = useSelector(getProcedureCategory)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const isItemRetailOrderLineItem = isRetailOrderLineItem(item)
  const isGlobalItem = useIsGlobalInventoryItem(
    isItemRetailOrderLineItem ? null : item,
  )

  const chargeName = isGlobalItem
    ? LanguageUtils.getConstantTranslatedName(
        getInventoryItemCategoryId(item, isFoodCatalogEnabled),
        InventoryCategory,
      )
    : !isItemRetailOrderLineItem && item.procedureCategoryId
      ? LanguageUtils.getConstantTranslatedName(
          item.procedureCategoryId,
          ProcedureCategory,
        )
      : ''

  return (
    <Grid mb={0.5} mt={1}>
      {chargeName && (
        <Text variant="lowAccent3">
          {t('Common:CHARGE_ACTION')}: {chargeName}
        </Text>
      )}
      <Text fontWeight={500} maxWidth={310}>
        {isGroupedInvoiceItem(item) && !isItemRetailOrderLineItem
          ? item.groupName
          : LanguageUtils.getTranslatedFieldName(item)}
      </Text>
    </Grid>
  )
}

export default ChargeItemTitle
