import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
      borderRadius: 2,
      backgroundColor: 'rgba(224,108,0,0.2)',
    },
    text: {
      color: theme.colors.important,
    },
    link: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { name: 'WellnessPlanBenefitFilledNotice' },
)

export interface WellnessPlanBenefitFilledNoticeProps {
  className?: string
  onClear: () => void
}

const WellnessPlanBenefitFilledNotice = ({
  className,
  onClear,
}: WellnessPlanBenefitFilledNoticeProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  return (
    <Grid container item className={classNames(className, classes.root)}>
      <Text strong className={classes.text} variant="subheading2">
        {t('WellnessPlans:FILLED_FROM_PREVIOUS_PLAN_ADJUST_AS_NEEDED')}
      </Text>
      <Text
        link
        className={classNames(classes.text, classes.link)}
        onClick={onClear}
      >
        <CloseIcon />
        {t('Common:CLEAR_ACTION')}
      </Text>
    </Grid>
  )
}

export default WellnessPlanBenefitFilledNotice
