import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { includes } from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import EmailInvoiceSelectableListItem from './EmailinvoiceSelectableListItem'

export interface EmailInvoiceSelectableListProps {
  className?: string
  invoiceIds: string[]
  selectedItems: string[]
  toggleItem: (invoiceId: string) => void
}

const EmailInvoiceSelectableList = ({
  className,
  invoiceIds,
  selectedItems,
  toggleItem,
}: EmailInvoiceSelectableListProps) => {
  const { t } = useTranslation('Invoices')

  return (
    <Grid container item className={className} direction="column">
      <Text strong variant="subheading3">
        {t('Invoices:INCLUDE_INVOICES')}:
      </Text>
      {invoiceIds.map((invoiceId) => (
        <EmailInvoiceSelectableListItem
          invoiceId={invoiceId}
          isChecked={includes(invoiceId, selectedItems)}
          key={invoiceId}
          toggleItem={toggleItem}
        />
      ))}
    </Grid>
  )
}

export default EmailInvoiceSelectableList
