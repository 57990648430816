import { Business, User } from '@pbt/pbt-ui-components'

import {
  ADD_BUSINESS_TO_JOURNAL,
  ADD_USER_TO_JOURNAL,
  CLOSE_CLIENTS_SPLASH_SCREEN,
  CLOSE_TIMEZONE_ALERT,
} from './types/journal'

export const addUserToJournal = (user: Partial<User>) => ({
  type: ADD_USER_TO_JOURNAL,
  user,
})
export const addBusinessToJournal = (business: Business) => ({
  type: ADD_BUSINESS_TO_JOURNAL,
  business,
})
export const closeClientsSplashScreen = () => ({
  type: CLOSE_CLIENTS_SPLASH_SCREEN,
})
export const closeTimezoneAlert = () => ({ type: CLOSE_TIMEZONE_ALERT })
