import React, { ReactNode } from 'react'
import { Grid, GridProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    item: {
      borderBottom: theme.constants.tabBorder,
      '&:not(:first-of-type)': {
        paddingTop: theme.spacing(1),
      },
      paddingBottom: theme.spacing(1),
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
    label: {
      fontWeight: 500,
    },
  }),
  { name: 'PaymentDetailsItem' },
)

type PaymentDetailsItemProps = GridProps & {
  label: ReactNode
}

export const PaymentDetailsItem = ({
  label,
  children,
}: PaymentDetailsItemProps) => {
  const classes = useStyles()

  return (
    <Grid container item className={classes.item} gap={1.5} xs={12}>
      <Grid item className={classes.label} xs={5}>
        {label}
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  )
}
