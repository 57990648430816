import React from 'react'
import { TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { Invoice } from '~/types'

import { SubHeadColumnComponents } from '../../../invoiceTableConstants'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      height: 32,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    subHeaderCell: {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
    },
    borderCell: {
      borderRight: theme.constants.tabBorder,
    },
    amountCell: {
      minWidth: 85,
    },
  }),
  { name: 'InvoiceTableSubHeadRow' },
)

export interface InvoiceTableSubHeadRowProps {
  invoice: Invoice
  isEstimate: boolean
  onPopperApplyToAll: (fieldName: string, value: number) => void
  onPopperApplyToTotal: (amount: number) => void
  onPopperClose: (event: React.MouseEvent<HTMLDivElement>) => void
  openPopperId: string | null
  prePaidEnabled: boolean
  rangeEnabled: boolean
  selectedColumns: string[]
  toggleApplierPopperOpen: (event: React.MouseEvent<HTMLDivElement>) => void
}

const InvoiceTableSubHeadRow = ({
  selectedColumns,
  invoice,
  ...rest
}: InvoiceTableSubHeadRowProps) => {
  const classes = useStyles()

  return (
    <TableRow>
      {selectedColumns.map((column) => {
        const CellComponent = SubHeadColumnComponents[column]

        if (!CellComponent) {
          return null
        }

        return (
          <CellComponent
            className={classNames(
              classes.tableCell,
              classes.subHeaderCell,
              classes.borderCell,
            )}
            invoice={invoice}
            key={column}
            {...rest}
          />
        )
      })}
    </TableRow>
  )
}

export default InvoiceTableSubHeadRow
