import { ApiError, BlobWithName, Nil, Patient } from '@pbt/pbt-ui-components'

import { WsPatientUpdate } from '~/types'
import { ClientData } from '~/types/entities/clients'

import {
  CREATE_PATIENT,
  CREATE_PATIENT_CALLBACK,
  CREATE_PATIENT_FAILURE,
  CREATE_PATIENT_SUCCESS,
  EDIT_PATIENT,
  EDIT_PATIENT_FAILURE,
  EDIT_PATIENT_SUCCESS,
  LINK_PATIENTS_TO_CHEWY_ACCOUNT,
  UNLINK_PATIENTS_FROM_CHEWY_ACCOUNT,
  UPDATE_PATIENT_PARENT,
  UPDATE_PATIENT_PARENT_FAILURE,
  UPDATE_PATIENT_PARENT_SUCCESS,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_FAILURE,
  UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_SUCCESS,
  UPDATE_PATIENTS,
  WS_UPDATED_PATIENT_STATUS,
} from './types/patients'

export const updatePatients = (patients: Record<string, Patient>) => ({
  type: UPDATE_PATIENTS,
  patients,
})

export const createPatient = (
  clientId: string,
  patient: Omit<Patient, 'id'>,
  avatarBlob?: BlobWithName | Nil,
) => ({
  type: CREATE_PATIENT,
  clientId,
  patient,
  avatarBlob,
})

export const createPatientWithCallback = (
  clientId: string,
  patient: Omit<Patient, 'id'>,
  callback: (id: string) => void,
  avatarBlob?: BlobWithName | Nil,
) => ({
  type: CREATE_PATIENT_CALLBACK,
  clientId,
  patient,
  avatarBlob,
  callback,
})

export const createPatientSuccess = (clientId: string, patientId: string) => ({
  type: CREATE_PATIENT_SUCCESS,
  clientId,
  patientId,
})
export const createPatientFailure = (error: ApiError) => ({
  type: CREATE_PATIENT_FAILURE,
  error,
})

export const editPatient = (
  clientId: string,
  patient: Partial<Patient> & { id: string },
  avatarBlob?: BlobWithName | Nil,
) => ({
  type: EDIT_PATIENT,
  clientId,
  patient,
  avatarBlob,
})
export const editPatientSuccess = (clientId: string, patientId: string) => ({
  type: EDIT_PATIENT_SUCCESS,
  clientId,
  patientId,
})
export const editPatientFailure = (error: ApiError) => ({
  type: EDIT_PATIENT_FAILURE,
  error,
})

export const updatePatientParent = (
  currentClientId: string,
  newClientId: string,
  patientId: string,
) => ({
  type: UPDATE_PATIENT_PARENT,
  currentClientId,
  newClientId,
  patientId,
})
export const updatePatientParentSuccess = () => ({
  type: UPDATE_PATIENT_PARENT_SUCCESS,
})
export const updatePatientParentFailure = (error: ApiError) => ({
  type: UPDATE_PATIENT_PARENT_FAILURE,
  error,
})

export const updatePatientParentWithNewClient = (
  currentClientId: string,
  clientCandidate: Partial<ClientData>,
  patientId: string,
) => ({
  type: UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT,
  currentClientId,
  clientCandidate,
  patientId,
})
export const updatePatientParentWithNewClientSuccess = () => ({
  type: UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_SUCCESS,
})
export const updatePatientParentWithNewClientFailure = (error: ApiError) => ({
  type: UPDATE_PATIENT_PARENT_WITH_NEW_CLIENT_FAILURE,
  error,
})

export const linkPatientsToChewyAccount = (patientIds: string[]) => ({
  type: LINK_PATIENTS_TO_CHEWY_ACCOUNT,
  patientIds,
})

export const unlinkPatientsFromChewyAccount = (patientIds: string[]) => ({
  type: UNLINK_PATIENTS_FROM_CHEWY_ACCOUNT,
  patientIds,
})

export const wsUpdatedPatientStatus = (body: WsPatientUpdate) => ({
  type: WS_UPDATED_PATIENT_STATUS,
  body,
})
