import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LanguageUtils,
  PermissionArea,
  StateLabel,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { ColorNames } from '~/constants/colors'
import FeatureToggle from '~/constants/featureToggle'
import { LabTestState } from '~/constants/SOAPStates'
import {
  deleteLabTestAttachmentFile,
  getLabTestLogFileGroups,
} from '~/store/duck/labTestsDashboard'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle, getLabTestsStates } from '~/store/reducers/constants'
import { LabTestDashboardTest } from '~/types'

import NotesTemplateInput from '../../template-inputs/NotesTemplateInput'
import { LabTestAddAttachment } from './LabTestAddAttachment'
import LabTestAttachmentList, {
  LabTestLogFileMapped,
} from './LabTestAttachmentList'
import LabTestsStateSelect from './LabTestsStateSelect'

const useStyles = makeStyles(
  (theme) => ({
    testItem: {
      border: theme.constants.tableBorder,
      '&:not(:first-of-type)': {
        borderTop: 'none',
      },
    },
    divider: {
      width: '100%',
    },
  }),
  { name: 'LabTestItem' },
)

export interface LabTestItemProps {
  clientId: string
  labTestIdentifier: string
  onNotesChange: (value: string, test: LabTestDashboardTest) => void
  onStateChange?: (value: string, test: LabTestDashboardTest) => void
  patientId: string
  showStatusSelect?: boolean
  statusesToShow?: string[]
  test: LabTestDashboardTest
  vendorId: string
}

const LabTestItem = ({
  showStatusSelect = false,
  test,
  clientId,
  patientId,
  onNotesChange,
  onStateChange,
  statusesToShow,
  vendorId,
  labTestIdentifier,
}: LabTestItemProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const permissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const LabTestStates = useSelector(getLabTestsStates)
  const files = useSelector(getLabTestLogFileGroups(labTestIdentifier, test.id))
  const { t } = useTranslation(['Common'])

  const isAttachingFilesToLabOrdersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ATTACH_FILES_TO_LAB_ORDERS),
  )

  const currentState = Utils.findById(test.statusId, LabTestStates) || {}
  const showAttachmentList = Boolean(files?.length)

  return (
    <Grid container item className={classes.testItem} px={2} py={1}>
      <Grid container item mb={1}>
        <Grid item xs>
          <Text>{test.name}</Text>
        </Grid>
        {showStatusSelect ? (
          <Grid item>
            <LabTestsStateSelect
              statusesToShow={statusesToShow}
              value={test.statusId}
              onChange={Utils.handleFormSelectInput((value) => {
                if (onStateChange) {
                  onStateChange(value, test)
                }
              })}
            />
          </Grid>
        ) : (
          <StateLabel
            completed={currentState.name === LabTestState.COMPLETED}
            success={
              currentState.color === ColorNames.GREEN &&
              currentState.name !== LabTestState.COMPLETED
            }
            warning={currentState.color === ColorNames.YELLOW}
          >
            {LanguageUtils.getTranslatedFieldName(currentState)}
          </StateLabel>
        )}
      </Grid>
      <NotesTemplateInput
        hidePanel
        clientId={clientId}
        disabled={!permissions.update}
        patientId={patientId}
        placeholder={t('Common:NOTES')}
        value={test.notes}
        onChange={(value) => onNotesChange(value, test)}
      />
      {isAttachingFilesToLabOrdersEnabled ? (
        <>
          {showAttachmentList && (
            <>
              <LabTestAttachmentList
                clientId={clientId}
                files={files as LabTestLogFileMapped[]}
                patientId={patientId}
                onDelete={(groupId: string, fileId: string) => {
                  dispatch(
                    deleteLabTestAttachmentFile({
                      labTestIdentifier,
                      labTestLogId: test.id,
                      groupId,
                      fileId,
                    }),
                  )
                }}
              />
              <Grid container px={2}>
                <Divider className={classes.divider} />
              </Grid>
            </>
          )}
          <LabTestAddAttachment
            clientId={clientId}
            fileCount={files?.length ?? 0}
            labTestIdentifier={labTestIdentifier}
            labTestLogId={test.id}
            labTestName={test.name}
            patientId={patientId}
            vendorId={vendorId}
          />
        </>
      ) : null}
    </Grid>
  )
}

export default LabTestItem
