import { gql } from '@apollo/client'

export const BUSINESS_APPOINTMENT_TYPE_FIELDS = gql`
  fragment BusinessAppointmentTypeFields on BusinessAppointmentType {
    id
    businessId
    name
    defaultDuration
    enabled
    onlineSchedulingAllowed
    color
    creationDate
    modificationDate
    creatorId
    modifierId
    eventTypeId
    defaultType
  }
`
