import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel } from '@mui/material'
import { pluck } from 'ramda'
import { Constant, Field, PuiSelect } from '@pbt/pbt-ui-components'

import { getImagingVendorModality } from '~/store/duck/imagingOrders'
import { useGetIdexxImagingId } from '~/store/hooks/constants'
import { getImagingModalities } from '~/store/reducers/constants'

export interface ModalitySelectProps {
  field: Field
  vendorId?: string
}

const ModalitySelect = ({
  field,
  vendorId: vendorIdProp,
}: ModalitySelectProps) => {
  const ImagingModalities: Constant[] = useSelector(getImagingModalities)
  const idexxVendorId = useGetIdexxImagingId()
  const vendorId = vendorIdProp || idexxVendorId
  const businessScopeModalities =
    useSelector(getImagingVendorModality(vendorId)) || []
  const businessScopeModalityIds = pluck('modalityId', businessScopeModalities)
  const { t } = useTranslation('Common')

  const modalityOptions = ImagingModalities.filter(({ id }) =>
    businessScopeModalityIds.includes(id),
  )

  return (
    <FormControl fullWidth margin="none">
      <InputLabel>{t('Common:MODALITY')}</InputLabel>
      <PuiSelect field={field} items={modalityOptions} />
    </FormControl>
  )
}

export default ModalitySelect
