import { getConstantsStringWithAll } from '~/utils'
import useWorkflowTypes from '~/utils/useWorkflowTypes'

export type WorkflowCellItem = {
  workflowIds: string[]
}

const WorkflowCell = (item: WorkflowCellItem) => {
  const WorkFlowTypes = useWorkflowTypes()

  return getConstantsStringWithAll(item.workflowIds, WorkFlowTypes)
}

export default WorkflowCell
