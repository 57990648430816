import React, { ForwardRefExoticComponent, RefAttributes } from 'react'
import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTooltip } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  () => ({
    icon: {
      padding: 0,
    },
    tooltip: {
      maxWidth: 'none',
    },
  }),
  { name: 'ItemWithBadgesActions' },
)

type DisableButtonProps = {
  Icon: ForwardRefExoticComponent<
    Omit<SvgIconProps, 'ref'> & RefAttributes<SVGSVGElement>
  >
  size?: IconButtonProps['size']
  title: string
}

const DisableIconButtonWithTooltip = ({
  size,
  title,
  Icon,
}: DisableButtonProps) => {
  const classes = useStyles()
  return (
    <PuiTooltip
      classes={{ tooltip: classes.tooltip }}
      placement="top-start"
      tooltipText={title}
    >
      <span>
        <IconButton
          disabled
          className={classes.icon}
          data-testid="item-with-badges-actions-delete"
          size={size}
        >
          <Icon fontSize={size} />
        </IconButton>
      </span>
    </PuiTooltip>
  )
}

export default DisableIconButtonWithTooltip
