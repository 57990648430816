import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Moment } from 'moment'
import { moment } from '@pbt/pbt-ui-components'

import { useAlertType } from '~/store/hooks/patient'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { ScheduleSlot } from '~/types'

import TimetableEventCard, {
  TimetableEventCardProps,
} from '../../../TimetableEventCard'

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
    },
  }),
  { name: 'BoardingAppointmentCard' },
)

const MINUTES_IN_DAY = 24 * 60

export interface BoardingAppointmentCardProps extends TimetableEventCardProps {
  offsetPosition: number
  slot: ScheduleSlot
  startDate: Moment
  stepInterval?: number
  steps?: string[]
}

const BoardingAppointmentCard = ({
  steps,
  stepInterval,
  startDate,
  offsetPosition,
  appointmentId,
  slot: slotProp,
  ...rest
}: BoardingAppointmentCardProps) => {
  const classes = useStyles()
  const theme = useTheme()

  const appointment = useSelector(getTimetableEvent(appointmentId))
  const { constants } = theme

  const momentStart = moment(slotProp?.interval?.from)
  const momentEnd = moment(slotProp?.interval?.to)

  const isStartDay = momentStart.isSame(startDate, 'day')
  const isBeforeStart = momentStart.isBefore(startDate)

  const cutOffDuration = startDate.diff(momentStart, 'minutes')
  const duration = momentEnd.diff(momentStart, 'minutes')
  const durationToStart = momentStart.diff(startDate, 'minutes')

  const { alertsToRender } = useAlertType(appointment?.patient)
  const showAlertLabel = alertsToRender.length > 0
  const alertLabelWidth = showAlertLabel ? parseInt(theme.spacing(2.5), 10) : 0

  const themeSpacing = parseInt(theme.spacing(1), 10)

  const durationToPx = (value: number) =>
    (value * (constants?.boardingColumnWidth ?? 0 + themeSpacing)) /
    MINUTES_IN_DAY

  const pxDuration = durationToPx(duration)
  const pxDurationToStart = durationToPx(durationToStart)

  // forbid the correction for too short duration width
  const allowCorrection = pxDuration > themeSpacing

  // yes, on current design first column is 8px smaller than all the other columns
  const firstColumnCorrection = isStartDay && allowCorrection ? themeSpacing : 0

  const height = parseInt(theme.spacing(7), 10)
  const width = pxDuration - firstColumnCorrection
  const left = pxDurationToStart + firstColumnCorrection
  const top =
    themeSpacing + Math.max(offsetPosition, 0) * (height + themeSpacing)
  const paddingLeft = isBeforeStart
    ? durationToPx(cutOffDuration) - alertLabelWidth
    : undefined

  const slot = {
    ...slotProp,
    interval: {
      from: momentStart.toISOString(),
      to: momentEnd.toISOString(),
    },
  }

  return (
    <TimetableEventCard
      {...rest}
      appointmentId={appointmentId}
      classes={classes}
      contentStyle={{ paddingLeft }}
      rootStyle={{ top, height, width, left }}
      showAppointmentType={false}
      slot={slot}
    />
  )
}

export default BoardingAppointmentCard
