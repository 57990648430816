import {
  ApiError,
  AtLeast,
  Business,
  BusinessBrandingConfiguration,
  BusinessLicense,
  IdObject,
  Nil,
  User,
} from '@pbt/pbt-ui-components'

import {
  Business as BusinessType,
  SsoIdpSettings,
  SsoIdpSettingsInput,
} from '~/api/graphql/generated/types'
import { BusinessSearchParameters } from '~/types'

import { BusinessDto } from '../dto/Business'
import {
  ADD_FAVORITE_BUSINESS,
  ADD_FAVORITE_BUSINESS_FAILURE,
  ADD_FAVORITE_BUSINESS_SUCCESS,
  CLEAR_REMINDER_TEMPLATE,
  CLEAR_REMINDER_TEMPLATE_ERROR,
  CREATE_BUSINESS,
  CREATE_BUSINESS_FAILURE,
  CREATE_BUSINESS_LICENSE,
  CREATE_BUSINESS_LICENSE_FAILURE,
  CREATE_BUSINESS_LICENSE_SUCCESS,
  CREATE_BUSINESS_SSO_IDP_SETTINGS,
  CREATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  CREATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  CREATE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_GROUP,
  DELETE_BUSINESS_GROUP_FAILURE,
  DELETE_BUSINESS_GROUP_SUCCESS,
  DELETE_BUSINESS_LICENSE,
  DELETE_BUSINESS_LICENSE_FAILURE,
  DELETE_BUSINESS_LICENSE_SUCCESS,
  DELETE_BUSINESS_SSO_IDP_SETTINGS,
  DELETE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  DELETE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  EDIT_BUSINESS_BRANDING_CONFIGURATION,
  EDIT_BUSINESS_BRANDING_CONFIGURATION_FAILURE,
  EDIT_BUSINESS_BRANDING_CONFIGURATION_SUCCESS,
  EDIT_BUSINESS_LICENSE,
  EDIT_BUSINESS_LICENSE_FAILURE,
  EDIT_BUSINESS_LICENSE_SUCCESS,
  FETCH_ASSIGNED_PRACTICES,
  FETCH_ASSIGNED_PRACTICES_FAILURE,
  FETCH_ASSIGNED_PRACTICES_SUCCESS,
  FETCH_BUSINESS,
  FETCH_BUSINESS_BRANDING_CONFIGURATION,
  FETCH_BUSINESS_BRANDING_CONFIGURATION_FAILURE,
  FETCH_BUSINESS_BRANDING_CONFIGURATION_SUCCESS,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_GROUP_TAGS,
  FETCH_BUSINESS_GROUP_TAGS_FAILURE,
  FETCH_BUSINESS_GROUP_TAGS_SUCCESS,
  FETCH_BUSINESS_SOAP_WIDGETS,
  FETCH_BUSINESS_SOAP_WIDGETS_FAILURE,
  FETCH_BUSINESS_SOAP_WIDGETS_SUCCESS,
  FETCH_BUSINESS_SSO_IDP_SETTINGS,
  FETCH_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  FETCH_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESSES_LIST,
  FETCH_BUSINESSES_LIST_FAILURE,
  FETCH_BUSINESSES_LIST_SUCCESS,
  FETCH_LOCATIONS_LIST,
  FETCH_LOCATIONS_LIST_FAILURE,
  FETCH_LOCATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_SUCCESS,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_FAILURE,
  FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_SUCCESS,
  FETCH_SUGGESTIONS_LIST,
  FETCH_SUGGESTIONS_LIST_FAILURE,
  FETCH_SUGGESTIONS_LIST_SUCCESS,
  REMOVE_FAVORITE_BUSINESS,
  REMOVE_FAVORITE_BUSINESS_FAILURE,
  REMOVE_FAVORITE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_FAILURE,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  UPDATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESSES,
  UPDATE_COUNTRY_CATALOG_CODE,
  UPDATE_COUNTRY_CATALOG_CODE_FAILURE,
  UPDATE_COUNTRY_CATALOG_CODE_SUCCESS,
  UPDATE_FAVORITE_BUSINESS_LIST,
  UPDATE_LOCK_WORKING_HOURS_SETTINGS,
} from './types/businesses'

export const fetchBusinessGroupTags = (groupId: string) => ({
  type: FETCH_BUSINESS_GROUP_TAGS,
  groupId,
})
export const fetchBusinessGroupTagsSuccess = (
  groupId: string,
  groupTags: IdObject[],
) => ({
  type: FETCH_BUSINESS_GROUP_TAGS_SUCCESS,
  groupId,
  groupTags,
})
export const fetchBusinessGroupTagsFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_GROUP_TAGS_FAILURE,
  error,
})

export const updateBusinesses = (
  businesses: Record<string, Partial<Business>>,
) => ({
  type: UPDATE_BUSINESSES,
  businesses,
})

export const fetchBusinessesList = (
  from?: number,
  to?: number,
  query?: string | null,
  fieldsQuery?: string | null,
) => ({
  type: FETCH_BUSINESSES_LIST,
  from,
  to,
  query,
  fieldsQuery,
})
export const fetchBusinessesListSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_BUSINESSES_LIST_SUCCESS,
  list,
  totalCount,
})
export const fetchBusinessesListFailure = (error: ApiError) => ({
  type: FETCH_BUSINESSES_LIST_FAILURE,
  error,
})

export const fetchLocationsList = (
  from: number,
  to: number,
  parameters: BusinessSearchParameters,
  isGroup: boolean,
) => ({
  type: FETCH_LOCATIONS_LIST,
  from,
  to,
  parameters,
  isGroup,
})
export const fetchLocationsListSuccess = (
  locationList: string[],
  locationTotalCount: number,
) => ({
  type: FETCH_LOCATIONS_LIST_SUCCESS,
  locationList,
  locationTotalCount,
})
export const fetchLocationsListFailure = (error: ApiError) => ({
  type: FETCH_LOCATIONS_LIST_FAILURE,
  error,
})

export const fetchSuggestionsList = (
  from: number,
  to: number,
  parameters: BusinessSearchParameters,
  isGroup: boolean,
) => ({
  type: FETCH_SUGGESTIONS_LIST,
  from,
  to,
  parameters,
  isGroup,
})
export const fetchSuggestionsListSuccess = (
  suggestionList: string[],
  suggestionTotalCount: number,
) => ({
  type: FETCH_SUGGESTIONS_LIST_SUCCESS,
  suggestionList,
  suggestionTotalCount,
})
export const fetchSuggestionsListFailure = (error: ApiError) => ({
  type: FETCH_SUGGESTIONS_LIST_FAILURE,
  error,
})

export const fetchAssignedPractices = () => ({ type: FETCH_ASSIGNED_PRACTICES })
export const fetchAssignedPracticesSuccess = (list: string[]) => ({
  type: FETCH_ASSIGNED_PRACTICES_SUCCESS,
  list,
})
export const fetchAssignedPracticesFailure = (error: ApiError) => ({
  type: FETCH_ASSIGNED_PRACTICES_FAILURE,
  error,
})

export const fetchMoreItemsForBusinessesList = (
  from: number,
  to: number,
  query?: string | null,
  fieldsQuery?: string | null,
) => ({
  type: FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST,
  from,
  to,
  query,
  fieldsQuery,
})
export const fetchMoreItemsForBusinessesListSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreItemsForBusinessesListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_BUSINESSES_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForLocationsList = (
  from: number,
  to: number,
  parameters: BusinessSearchParameters,
  isGroup: boolean,
) => ({
  type: FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST,
  from,
  to,
  parameters,
  isGroup,
})
export const fetchMoreItemsForLocationsListSuccess = (
  locationList: string[],
  locationTotalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_SUCCESS,
  locationList,
  locationTotalCount,
  from,
})
export const fetchMoreItemsForLocationsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_LOCATIONS_LIST_FAILURE,
  error,
})

export const fetchMoreItemsForSuggestionsList = (
  from: number,
  to: number,
  parameters: BusinessSearchParameters,
  isGroup: boolean,
) => ({
  type: FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST,
  from,
  to,
  parameters,
  isGroup,
})
export const fetchMoreItemsForSuggestionsListSuccess = (
  suggestionList: string[],
  suggestionTotalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_SUCCESS,
  suggestionList,
  suggestionTotalCount,
  from,
})
export const fetchMoreItemsForSuggestionsListFailure = (error: ApiError) => ({
  type: FETCH_MORE_ITEMS_FOR_SUGGESTIONS_LIST_FAILURE,
  error,
})

export const createBusiness = (
  business: Business | BusinessDto,
  creatorRoleId?: string | Nil,
) => ({
  type: CREATE_BUSINESS,
  business,
  creatorRoleId,
})
export const createBusinessSuccess = (businessId: string) => ({
  type: CREATE_BUSINESS_SUCCESS,
  businessId,
})
export const createBusinessFailure = (error: ApiError) => ({
  type: CREATE_BUSINESS_FAILURE,
  error,
})

export const updateBusiness = (business: AtLeast<Business, 'id'>) => ({
  type: UPDATE_BUSINESS,
  business,
})
export const updateBusinessSuccess = () => ({ type: UPDATE_BUSINESS_SUCCESS })
export const updateBusinessFailure = (error: ApiError) => ({
  type: UPDATE_BUSINESS_FAILURE,
  error,
})

export const updateFavoriteBusinessList = (favouriteBusinessIds: string[]) => ({
  type: UPDATE_FAVORITE_BUSINESS_LIST,
  favouriteBusinessIds,
})

export const removeFavoriteBusiness = (businessId: string) => ({
  type: REMOVE_FAVORITE_BUSINESS,
  businessId,
})
export const removeFavoriteBusinessSuccess = (
  favouriteBusinessIds: string[],
) => ({
  type: REMOVE_FAVORITE_BUSINESS_SUCCESS,
  favouriteBusinessIds,
})
export const removeFavoriteBusinessFailure = (error: ApiError) => ({
  type: REMOVE_FAVORITE_BUSINESS_FAILURE,
  error,
})

export const addFavoriteBusiness = (businessId: string) => ({
  type: ADD_FAVORITE_BUSINESS,
  businessId,
})
export const addFavoriteBusinessSuccess = (favouriteBusinessIds: string[]) => ({
  type: ADD_FAVORITE_BUSINESS_SUCCESS,
  favouriteBusinessIds,
})
export const addFavoriteBusinessFailure = (error: ApiError) => ({
  type: ADD_FAVORITE_BUSINESS_FAILURE,
  error,
})

export const fetchBusiness = (
  businessId: string,
  includeChildren?: boolean,
  includeSignatureCheck?: boolean,
) => ({
  type: FETCH_BUSINESS,
  businessId,
  includeChildren,
  includeSignatureCheck,
})
export const fetchBusinessSuccess = (businessId: string) => ({
  type: FETCH_BUSINESS_SUCCESS,
  businessId,
})
export const fetchBusinessFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_FAILURE,
  error,
})

export const deleteBusinessGroup = (businessId: string) => ({
  type: DELETE_BUSINESS_GROUP,
  businessId,
})
export const deleteBusinessGroupSuccess = (groupId: string) => ({
  type: DELETE_BUSINESS_GROUP_SUCCESS,
  groupId,
})
export const deleteBusinessGroupFailure = (error: ApiError) => ({
  type: DELETE_BUSINESS_GROUP_FAILURE,
  error,
})

export const createBusinessLicense = (
  businessId: string,
  license: BusinessLicense,
) => ({
  type: CREATE_BUSINESS_LICENSE,
  businessId,
  license,
})
export const createBusinessLicenseSuccess = (
  businessId: string,
  licenses: BusinessLicense[],
) => ({
  type: CREATE_BUSINESS_LICENSE_SUCCESS,
  businessId,
  licenses,
})
export const createBusinessLicenseFailure = (error: ApiError) => ({
  type: CREATE_BUSINESS_LICENSE_FAILURE,
  error,
})
export const editBusinessLicense = (
  businessId: string,
  license: BusinessLicense,
) => ({
  type: EDIT_BUSINESS_LICENSE,
  businessId,
  license,
})
export const editBusinessLicenseSuccess = (
  businessId: string,
  licenses: BusinessLicense[],
) => ({
  type: EDIT_BUSINESS_LICENSE_SUCCESS,
  businessId,
  licenses,
})
export const editBusinessLicenseFailure = (error: ApiError) => ({
  type: EDIT_BUSINESS_LICENSE_FAILURE,
  error,
})

export const deleteBusinessLicense = (
  businessId: string,
  licenseId: string,
) => ({
  type: DELETE_BUSINESS_LICENSE,
  businessId,
  licenseId,
})
export const deleteBusinessLicenseSuccess = (
  businessId: string,
  licenses: BusinessLicense[],
) => ({
  type: DELETE_BUSINESS_LICENSE_SUCCESS,
  businessId,
  licenses,
})
export const deleteBusinessLicenseFailure = (error: ApiError) => ({
  type: DELETE_BUSINESS_LICENSE_FAILURE,
  error,
})

export const clearReminderTemplate = () => ({ type: CLEAR_REMINDER_TEMPLATE })
export const clearReminderTemplateError = () => ({
  type: CLEAR_REMINDER_TEMPLATE_ERROR,
})

export const updateLockWorkingHoursSettings = (
  lockWorkingHoursSettings: User['lockWorkingHoursSettings'],
) => ({
  type: UPDATE_LOCK_WORKING_HOURS_SETTINGS,
  lockWorkingHoursSettings,
})

export const fetchBusinessBrandingConfiguration = (businessId: string) => ({
  type: FETCH_BUSINESS_BRANDING_CONFIGURATION,
  businessId,
})
export const fetchBusinessBrandingConfigurationSuccess = (
  businessId: string,
  brandingConfiguration: BusinessBrandingConfiguration,
) => ({
  type: FETCH_BUSINESS_BRANDING_CONFIGURATION_SUCCESS,
  businessId,
  brandingConfiguration,
})
export const fetchBusinessBrandingConfigurationFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_BRANDING_CONFIGURATION_FAILURE,
  error,
})

export const editBusinessBrandingConfiguration = (
  businessId: string,
  brandingConfiguration: BusinessBrandingConfiguration,
) => ({
  type: EDIT_BUSINESS_BRANDING_CONFIGURATION,
  businessId,
  brandingConfiguration,
})
export const editBusinessBrandingConfigurationSuccess = (
  businessId: string,
  brandingConfiguration: BusinessBrandingConfiguration,
) => ({
  type: EDIT_BUSINESS_BRANDING_CONFIGURATION_SUCCESS,
  businessId,
  brandingConfiguration,
})
export const editBusinessBrandingConfigurationFailure = (error: ApiError) => ({
  type: EDIT_BUSINESS_BRANDING_CONFIGURATION_FAILURE,
  error,
})

export const updateCountryCatalogCode = (businessId: string, code: string) => ({
  type: UPDATE_COUNTRY_CATALOG_CODE,
  businessId,
  code,
})
export const updateCountryCatalogCodeSuccess = (
  businessId: string,
  code: string,
) => ({
  type: UPDATE_COUNTRY_CATALOG_CODE_SUCCESS,
  businessId,
  code,
})
export const updateCountryCatalogCodeFailure = (error: ApiError) => ({
  type: UPDATE_COUNTRY_CATALOG_CODE_FAILURE,
  error,
})

export const fetchBusinessSoapWidgets = (businessId: string) => ({
  type: FETCH_BUSINESS_SOAP_WIDGETS,
  businessId,
})
export const fetchBusinessSoapWidgetsSuccess = (
  soapWidgets: Pick<BusinessType, 'soapWidgets'>,
) => ({
  type: FETCH_BUSINESS_SOAP_WIDGETS_SUCCESS,
  soapWidgets,
})
export const fetchBusinessSoapWidgetsFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_SOAP_WIDGETS_FAILURE,
  error,
})

export const fetchBusinessSsoIdpSettings = (businessId: string) => ({
  type: FETCH_BUSINESS_SSO_IDP_SETTINGS,
  businessId,
})
export const fetchBusinessSsoIdpSettingsSuccess = (
  businessId: string,
  ssoIdpSettings: SsoIdpSettings,
) => ({
  type: FETCH_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  businessId,
  ssoIdpSettings,
})
export const fetchBusinessSsoIdpSettingsFailure = (error: ApiError) => ({
  type: FETCH_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  error,
})

export const createBusinessSsoIdpSettings = (
  businessId: string,
  input: SsoIdpSettingsInput,
) => ({
  type: CREATE_BUSINESS_SSO_IDP_SETTINGS,
  businessId,
  input,
})
export const createBusinessSsoIdpSettingsSuccess = (
  businessId: string,
  ssoIdpSettings: SsoIdpSettings,
) => ({
  type: CREATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  businessId,
  ssoIdpSettings,
})
export const createBusinessSsoIdpSettingsFailure = (error: ApiError) => ({
  type: CREATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  error,
})

export const updateBusinessSsoIdpSettings = (
  businessId: string,
  input: SsoIdpSettingsInput,
) => ({
  type: UPDATE_BUSINESS_SSO_IDP_SETTINGS,
  businessId,
  input,
})
export const updateBusinessSsoIdpSettingsSuccess = (
  businessId: string,
  ssoIdpSettings: SsoIdpSettings,
) => ({
  type: UPDATE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  businessId,
  ssoIdpSettings,
})
export const updateBusinessSsoIdpSettingsFailure = (error: ApiError) => ({
  type: UPDATE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  error,
})

export const deleteBusinessSsoIdpSettings = (
  businessId: string,
  ssoIdpSettingsId: string,
) => ({
  type: DELETE_BUSINESS_SSO_IDP_SETTINGS,
  businessId,
  ssoIdpSettingsId,
})
export const deleteBusinessSsoIdpSettingsSuccess = (
  businessId: string,
  ssoIdpSettingsId: string,
) => ({
  type: DELETE_BUSINESS_SSO_IDP_SETTINGS_SUCCESS,
  businessId,
  ssoIdpSettingsId,
})
export const deleteBusinessSsoIdpSettingsFailure = (error: ApiError) => ({
  type: DELETE_BUSINESS_SSO_IDP_SETTINGS_FAILURE,
  error,
})
