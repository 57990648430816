import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid, Theme, useMediaQuery } from '@mui/material'

import LabTestDetails from './lab-tests-table/LabTestDetails'
import LabTestsTableComponent from './lab-tests-table/LabTestsTableComponent'

const LabTestsDashboardPage = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const { labTestIdentifier } = useParams()

  const onDetailsClose = () => {
    navigate('/lab-tests-dashboard')
  }

  if (isMobile && labTestIdentifier) {
    return (
      <LabTestDetails itemId={labTestIdentifier} onClose={onDetailsClose} />
    )
  }

  return (
    <Grid container flex={1} wrap="nowrap">
      <LabTestsTableComponent labTestIdentifier={labTestIdentifier} />
    </Grid>
  )
}

export default LabTestsDashboardPage
