import * as R from 'ramda'
import { put, select } from 'redux-saga/effects'
import { Utils } from '@pbt/pbt-ui-components'

import { updateNotificationsLinkedToAreaListItem } from '../../actions/notifications'
import { getNotificationArea } from '../../reducers/constants'

export function* updateNotificationsAndTotalCountsForArea({
  notificatedEntities = {},
  unreadNotificationsTotalCount = null,
  notificationAreaName,
}) {
  const NotificationAreas = yield select(getNotificationArea)
  const areaId = Utils.findConstantIdByName(
    notificationAreaName,
    NotificationAreas,
  )
  const notificationIdsByLinkedItemId = R.pickBy(
    R.pipe(R.isNil, R.not),
    R.pluck('notifications', notificatedEntities),
  )

  yield put(
    updateNotificationsLinkedToAreaListItem(
      notificationIdsByLinkedItemId,
      unreadNotificationsTotalCount,
      areaId,
    ),
  )
}

export function* updateNotificationsForArea({
  notificatedEntities = {},
  notificationAreaName,
}) {
  yield updateNotificationsAndTotalCountsForArea({
    notificatedEntities,
    notificationAreaName,
  })
}
