import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { AlertIconType, Text } from '@pbt/pbt-ui-components/src'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import {
  clearBusinessRelationsMappings,
  fetchBusinessRelationsMappings,
} from '~/store/actions/migration'
import {
  getIsLoadingRelationsMappings,
  getRelationsMappings,
} from '~/store/reducers/migration'
import { BaseMigrationProps } from '~/types/entities/migration'
import useDialog from '~/utils/useDialog'

const downloadMappingsCsv = (mappings: object[]) => {
  const firstMapping = R.head(mappings) as object
  const fileHeader = Object.keys(firstMapping).join(',')
  const fileContent = mappings
    .map((mapping) => Object.values(mapping).join(','))
    .join('\n')
  const csvContent = `data:text/csv;charset=utf-8,${fileHeader}\n${fileContent}`
  const encodedURI = encodeURI(csvContent)
  const linkEl = document.createElement('a')
  linkEl.setAttribute('href', encodedURI)
  linkEl.setAttribute('download', 'patient_to_rhapsody_patient_mapping.csv')
  linkEl.click()
}

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      verticalAlign: 'middle',
    },
    loader: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'Mappings' },
)

const Mappings = ({ businessId }: BaseMigrationProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')
  const dispatch = useDispatch()

  const [openAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const isLoading = useSelector(getIsLoadingRelationsMappings)
  const relationMappings = useSelector(getRelationsMappings)

  useEffect(() => {
    if (relationMappings && relationMappings.length > 0) {
      downloadMappingsCsv(relationMappings)
    } else if (relationMappings?.length === 0) {
      openAlert({
        iconType: AlertIconType.WARN,
        message: t('Businesses:MIGRATION.MAPPINGS_NOT_FOUND'),
      })
    }

    dispatch(clearBusinessRelationsMappings())
  }, [relationMappings])

  return (
    <Text
      link
      disabled={isLoading}
      fontSize="1.4rem"
      my={0.5}
      onClick={() =>
        !isLoading &&
        dispatch(
          fetchBusinessRelationsMappings(
            businessId,
            'PIMS_PATIENT_TO_RHAPSODY_PATIENT',
          ),
        )
      }
    >
      <DownloadIcon className={classes.icon} />
      {t('Businesses:MIGRATION.PATIENT_IDS')}
      {isLoading && <CircularProgress className={classes.loader} size={13} />}
    </Text>
  )
}

export default Mappings
