import React from 'react'
import * as R from 'ramda'

interface SwitchingViewProps {
  activeIndex?: number
  children?: React.ReactElement[]
}

const SwitchingView = ({
  children: childrenProp,
  activeIndex = 0,
}: SwitchingViewProps) => {
  const children = childrenProp || []
  return R.flatten(children)[activeIndex] || null
}

export default SwitchingView
