import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import { Nil } from '@pbt/pbt-ui-components'

import {
  applyReminderFilters,
  clearReminderFilters,
  fetchMoreReminders,
  fetchReminders,
  getMultipleReminders,
  getMultipleRemindersGroups,
  getReminderFilters,
  getRemindersGroupsList,
  getTotalCount,
} from '~/store/duck/reminders'

export const useInitializeReminders = (
  patientId: string | Nil,
  initialStateFilter?: string[],
) => {
  const dispatch = useDispatch()

  const groupsList = useSelector(getRemindersGroupsList)
  const groups = useSelector(getMultipleRemindersGroups(groupsList))
  const totalCount = useSelector(getTotalCount)
  const remindersList = R.pipe(R.pluck('reminders'), R.flatten)(groups)
  const reminders = useSelector(getMultipleReminders(remindersList))
  const filters = useSelector(getReminderFilters)

  const isItemLoaded = (index: number) => Boolean(reminders[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (patientId) {
      dispatch(
        fetchMoreReminders({
          patientId,
          from: startIndex,
          to: endIndex,
          stateIds: filters.stateIds ?? initialStateFilter,
        }),
      )
    }
  }

  const onApplyFilter = (stateIds: string[]) => {
    if (patientId) {
      dispatch(fetchReminders({ patientId, stateIds }))
      dispatch(applyReminderFilters({ stateIds }))
    }
  }

  const onRemoveFilter = () => {
    dispatch(clearReminderFilters())
  }

  useEffect(() => {
    if (patientId) {
      dispatch(
        fetchReminders({ patientId, stateIds: initialStateFilter ?? [] }),
      )
    }
  }, [patientId])

  useEffect(() => {
    if (initialStateFilter) {
      dispatch(applyReminderFilters({ stateIds: initialStateFilter }))
    }
  }, [])

  useEffect(
    () => () => {
      onRemoveFilter()
    },
    [],
  )

  return {
    groups,
    groupsList,
    isItemLoaded,
    loadMoreItems,
    onApplyFilter,
    onRemoveFilter,
    reminders,
    remindersList,
    totalCount,
  }
}
