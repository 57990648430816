import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { equals } from 'ramda'
import { BasePuiDialogProps, Nil, PuiDialog } from '@pbt/pbt-ui-components'

import {
  PaymentMethod,
  ShippingAddress,
  SubmitChewyOrderMutationVariables,
} from '~/api/graphql/generated/types'
import {
  ChewyPaymentBody,
  ChewyPaymentBodyProps,
} from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentBody/ChewyPaymentBody'
import { useNoCardOrAddressDialog } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentDialog/useNoCardOrAddressDialog'
import {
  ChewyPaymentFooter,
  ChewyPaymentFooterProps,
} from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentFooter/ChewyPaymentFooter'
import { ChewyPaymentHeader } from '~/components/dashboard/invoices/payment/payment-details-dialog/chewy-payment/elements/ChewyPaymentHeader/ChewyPaymentHeader'
import {
  getCardAndPaypalPaymentMethods,
  getChewyAccountBalancePaymentMethod,
  getPrimary,
} from '~/utils/cvcClient'

const StyledPuiDialog = styled(PuiDialog)`
  .MuiPaper-root {
    width: 650px;
    max-width: 650px;
  }
`

export type ChewyPaymentDialogProps = BasePuiDialogProps &
  Pick<ChewyPaymentBodyProps, 'clientName' | 'shippingAddresses' | 'loading'> &
  Pick<
    ChewyPaymentFooterProps,
    | 'isPaymentRequest'
    | 'loadingError'
    | 'moveToCartError'
    | 'moveToCartLoading'
    | 'onMoveToCart'
    | 'placeOrderError'
    | 'placeOrderLoading'
    | 'total'
    | 'recalculatingPrice'
  > & {
    activeStep?: number
    defaultAddressId?: string | Nil
    hideStepper?: boolean
    onAddressChange: (shippingAddress: ShippingAddress) => void
    onPlaceOrder: (
      variables: Omit<SubmitChewyOrderMutationVariables, 'retailOrderId'>,
    ) => void
    paymentMethods: PaymentMethod[]
  }

export const ChewyPaymentDialog = ({
  activeStep = 0,
  clientName,
  defaultAddressId,
  hideStepper,
  isPaymentRequest,
  loading = false,
  loadingError,
  moveToCartError,
  moveToCartLoading,
  onAddressChange,
  onClose,
  onMoveToCart,
  onPlaceOrder,
  open,
  paymentMethods,
  placeOrderError,
  placeOrderLoading,
  recalculatingPrice = false,
  shippingAddresses,
  total,
}: ChewyPaymentDialogProps) => {
  const { t } = useTranslation(['Common'])

  const canPlaceChewyOrder =
    paymentMethods.length > 0 && shippingAddresses.length > 0

  const cardAndPaypalPaymentMethods =
    getCardAndPaypalPaymentMethods(paymentMethods)

  const chewyAccountBalancePaymentMethod =
    getChewyAccountBalancePaymentMethod(paymentMethods)

  // TODO: M1 update codegen - BigDecimal should be number, not any
  // (BigDecimal: { input: any; output: any; })
  const accountBalance = chewyAccountBalancePaymentMethod?.balance as
    | number
    | undefined

  const [paymentMethodValue, setPaymentMethodValue] = useState(
    getPrimary(cardAndPaypalPaymentMethods),
  )

  const [shippingAddressValue, setShippingAddressValue] = useState(
    getPrimary(shippingAddresses, defaultAddressId),
  )

  useNoCardOrAddressDialog({
    loading,
    onMoveToCart,
    hasPaymentMethods: Boolean(cardAndPaypalPaymentMethods.length),
    hasShippingAddress: Boolean(shippingAddresses.length),
  })

  useEffect(() => {
    const newPrimaryPaymentMethod = getPrimary(cardAndPaypalPaymentMethods)
    if (!equals(paymentMethodValue, newPrimaryPaymentMethod)) {
      setPaymentMethodValue(newPrimaryPaymentMethod)
    }
  }, [paymentMethods])

  useEffect(() => {
    const newShippingAddresses = getPrimary(shippingAddresses, defaultAddressId)
    if (!equals(shippingAddressValue, newShippingAddresses)) {
      setShippingAddressValue(newShippingAddresses)
    }
  }, [shippingAddresses, defaultAddressId])

  const handlePlaceOrder: ChewyPaymentFooterProps['onPlaceOrder'] = (
    applyAccountBalance,
  ) => {
    if (paymentMethodValue && shippingAddressValue) {
      onPlaceOrder({
        accountBalanceId:
          applyAccountBalance && accountBalance && accountBalance > 0
            ? chewyAccountBalancePaymentMethod?.id
            : // TODO: M1 revert to undefined after BE fix
              '',
        paymentMethodId: paymentMethodValue.id,
        // TODO: M1
        // shippingAddressId: shippingAddressValue.id,
      })
    }
  }

  const handleAddressChange = (shippingAddress: ShippingAddress | null) => {
    setShippingAddressValue(shippingAddress)
    if (shippingAddress) {
      onAddressChange(shippingAddress)
    }
  }

  return (
    <StyledPuiDialog
      actions={
        <ChewyPaymentFooter
          accountBalance={accountBalance}
          canPlaceChewyOrder={canPlaceChewyOrder}
          clientName={clientName}
          isPaymentRequest={isPaymentRequest}
          loading={loading}
          loadingError={loadingError}
          moveToCartError={moveToCartError}
          moveToCartLoading={moveToCartLoading}
          placeOrderError={placeOrderError}
          placeOrderLoading={placeOrderLoading}
          recalculatingPrice={recalculatingPrice}
          total={total}
          onMoveToCart={onMoveToCart}
          onPlaceOrder={handlePlaceOrder}
        />
      }
      header={
        <ChewyPaymentHeader activeStep={activeStep} showStepper={!hideStepper}>
          {t('Common:PAYMENTS.PAYMENT')}
        </ChewyPaymentHeader>
      }
      open={open}
      onClose={onClose}
    >
      <ChewyPaymentBody
        cardAndPaypalPaymentMethods={cardAndPaypalPaymentMethods}
        clientName={clientName}
        loading={loading}
        loadingError={loadingError}
        p={2}
        paymentMethodValue={paymentMethodValue}
        setPaymentMethodValue={setPaymentMethodValue}
        setShippingAddressValue={handleAddressChange}
        shippingAddressValue={shippingAddressValue}
        shippingAddresses={shippingAddresses}
      />
    </StyledPuiDialog>
  )
}
