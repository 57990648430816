import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { flatten, groupBy, isNil, pluck, uniq, update } from 'ramda'
import {
  AddButton,
  ErrorTooltip,
  LanguageUtils,
  PermissionArea,
  PuiTextArea,
  PuiTextField,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
  ValidateOptions,
  WellnessPlan,
  WellnessPlanBenefit,
  WellnessPlanCoverItem,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import {
  TranslatedWellnessPlanLevelsLabels,
  WellnessPlanLevels,
} from '~/constants/wellnessPlansConstants'
import i18n from '~/locales/i18n'
import {
  useGetBenefitShouldShowCoverColumn,
  useValidatePlanBenefits,
} from '~/store/hooks/wellnessPlans'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getWellnessPlanGlobalBenefitGroups } from '~/store/reducers/wellnessPlans'
import { DataHandle } from '~/types'
import useDialog from '~/utils/useDialog'
import useFieldsChanged from '~/utils/useFieldsChanged'

import WellnessPlanAutoRenewalToggle from '../../WellnessPlanAutoRenewalToggle'
import WellnessPlanBenefitLimitConfiguration from '../../WellnessPlanBenefitLimitConfiguration'
import WellnessPlanPriceTextField from '../../WellnessPlanPriceTextField'
import WellnessPlanPriceTypeControl, {
  WellnessPlanPriceTypeControlHandle,
} from '../../WellnessPlanPriceTypeControl'
import {
  getBenefitCoversAppointmentTypes,
  getBenefitCoversCatalog,
  getBenefitFromPlan,
  getBenefitHasCoveredItems,
  getBenefitIndex,
  getBenefitIsAccessToBoop,
  getGlobalBenefit,
  getUpdatedPlan,
  globalBenefitToSelected,
  updatePlanBenefits,
} from '../../wellnessPlanUtils'
import WellnessPlanBenefitAppointmentTypeRow from './WellnessPlanBenefitAppointmentTypeRow'
import WellnessPlanBenefitCoverageRow from './WellnessPlanBenefitCoverageRow'
import WellnessPlanBenefitFilledNotice from './WellnessPlanBenefitFilledNotice'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
      backgroundColor: theme.colors.tableBackground,
    },
    header: {
      flexShrink: 0,
      padding: theme.spacing(3),
    },
    nameInput: {
      width: 285,
    },
    content: {
      flexShrink: 0,
    },
    contentHeader: {
      borderTop: theme.constants.tabBorder,
      borderBottom: theme.constants.tabBorder,
    },
    contentHeaderColumn: {
      padding: theme.spacing(1),
      '&:first-of-type': {
        paddingLeft: theme.spacing(3),
      },
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tabBorder,
      },
    },
    contentBodyRow: {
      borderBottom: theme.constants.tabBorder,
    },
    contentBodyColumn: {
      padding: theme.spacing(1),
      '&:first-of-type': {
        paddingLeft: theme.spacing(3),
      },
      '&:not(:last-of-type)': {
        borderRight: theme.constants.tabBorder,
      },
    },
    benefitColumn: {
      width: 200,
      [theme.breakpoints.down('md')]: {
        width: 150,
      },
    },
    limitsColumn: {
      width: 200,
      [theme.breakpoints.down('md')]: {
        width: 170,
      },
    },
    descriptionColumn: {
      width: 245,
    },
    coveredColumn: {
      padding: 0,
    },
    coveredSection: {
      padding: theme.spacing(1),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    coveredSectionNoBorder: {
      '&:not(:last-of-type)': {
        borderBottom: 'none',
      },
    },
    coveredItemsSection: {
      padding: 0,
    },
    addText: {
      fontWeight: 400,
    },
    addTextError: {
      fontWeight: 500,
      color: theme.colors.errorColor,
    },
    plusButtonError: {
      backgroundColor: theme.colors.errorColor,
    },
    textAreaRoot: {
      height: '100% !important',
      margin: 0,
    },
    notice: {
      marginTop: theme.spacing(2),
    },
    priceTextField: {
      marginLeft: theme.spacing(3),
    },
    planPriceStructureText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tooltipText: {
      whiteSpace: 'normal',
    },
    autoRenewalToggle: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }),
  { name: 'WellnessPlanCustomization' },
)

const DESCRIPTION_HINT = i18n.t(
  'WellnessPlans:THIS_INFO_WILL_BE_DISPLAYED_TO_CLIENTS_DURING_SIGN_UP',
)

const groupPlansByLevel = (level: number, plans: WellnessPlan[]) =>
  groupBy((plan) => {
    if (isNil(plan.level)) {
      return 'noLevelPlans'
    }

    if (plan.level > level) {
      return 'higherLevelPlans'
    }

    if (plan.level < level) {
      return 'lowerLevelPlans'
    }

    return 'other'
  }, plans)

export interface WellnessPlanCustomizationProps {
  isClone?: boolean
  isEdit?: boolean
  onValidStateChange: (valid: boolean) => void
  plan: WellnessPlan
  setWellnessPlanVersion: (wellnessPlanVersion: WellnessPlanVersion) => void
  wellnessPlanVersion: WellnessPlanVersion
}

export interface WellnessPlanCustomizationHandle extends DataHandle {}

const WellnessPlanCustomization = forwardRef(function WellnessPlanCustomization(
  {
    plan,
    wellnessPlanVersion,
    setWellnessPlanVersion,
    onValidStateChange,
    isClone,
    isEdit,
  }: WellnessPlanCustomizationProps,
  ref: ForwardedRef<WellnessPlanCustomizationHandle>,
) {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const globalBenefitGroups = useSelector(getWellnessPlanGlobalBenefitGroups)
  const permissions = useSelector(getCRUDByArea(PermissionArea.WELLNESS_PLAN))

  const [openWellnessPlanBenefitsDialog] = useDialog(
    DialogNames.WELLNESS_PLAN_BENEFITS,
  )
  const [openSelectBenefitAppointmentTypeDialog] = useDialog(
    DialogNames.SELECT_BENEFIT_APPOINTMENT_TYPE,
  )
  const [openAddWellnessPlanCoverageItemsDialog] = useDialog(
    DialogNames.ADD_WELLNESS_PLAN_COVERAGE_ITEMS,
  )

  const [hasCoverItemsValidationError, setHasCoverItemsValidationError] =
    useState(false)

  const priceTypeControlRef = useRef<WellnessPlanPriceTypeControlHandle>(null)

  const { plans = [], activePatients = 0 } = wellnessPlanVersion
  const hasActivePatients = activePatients > 0
  const disableEdit = !permissions.update || hasActivePatients
  const planIndex = plans.indexOf(plan)
  const benefits = plan.benefits.filter(
    (benefit) => !getBenefitIsAccessToBoop(benefit),
  )
  const isBaseLevel = plan.level === WellnessPlanLevels.BASE

  const validatePlanBenefits = useValidatePlanBenefits()
  const getBenefitShouldShowCoverColumn = useGetBenefitShouldShowCoverColumn()

  const { fields, validate } = useFields([
    {
      name: 'name',
      label: t('Common:NAME'),
      initialValue: plan?.name || '',
    },
    {
      name: 'price',
      validators: ['nonNegative'],
      initialValue: plan?.price,
    },
  ])

  const { name, price } = fields

  const [isValid, setIsValid] = useState(false)

  const updateValidState = (newValid: boolean) => {
    if (isValid !== newValid) {
      onValidStateChange(newValid)
    }
    setIsValid(newValid)
  }

  const validateStep = (options?: ValidateOptions) => {
    const fieldsValid = validate(options)
    const priceTypeControlValid =
      priceTypeControlRef.current?.validate?.(options) ?? true

    if (!fieldsValid || !priceTypeControlValid) {
      updateValidState(false)
      return false
    }

    const benefitsValid = validatePlanBenefits(plan)

    if (!options?.silent) {
      setHasCoverItemsValidationError(!benefitsValid)
    }

    updateValidState(benefitsValid)

    return benefitsValid
  }

  useEffect(() => {
    validateStep({ silent: true })
  }, [wellnessPlanVersion, hasCoverItemsValidationError])

  useFieldsChanged(() => {
    validateStep({ silent: true })
  }, fields)

  useImperativeHandle(ref, () => ({
    validate: validateStep,
    get: () => {
      const updatedPlan = getUpdatedPlan(
        plan,
        name,
        isBaseLevel,
        priceTypeControlRef,
      )

      if (isNil(plan.level) || isClone || isEdit) {
        // no benefit copying for existing versions, since it is confusing for user
        return {
          ...wellnessPlanVersion,
          plans: update(planIndex, updatedPlan, wellnessPlanVersion.plans),
        }
      }

      const {
        higherLevelPlans = [],
        lowerLevelPlans = [],
        noLevelPlans = [],
      } = groupPlansByLevel(plan.level, wellnessPlanVersion.plans)

      // copy benefits to higher level plans
      const updatedHigherLevelPlans = higherLevelPlans.map(
        (higherLevelPlan) => ({
          ...higherLevelPlan,
          benefits: higherLevelPlan.benefits.map((benefit) => {
            const planBenefit = getBenefitFromPlan(
              plan,
              benefit.globalBenefitId || benefit.id,
            )
            const isAccessToBoop = getBenefitIsAccessToBoop(benefit)
            const matchesFillingLevel =
              isNil(benefit.filledFromLevel) ||
              benefit.filledFromLevel < plan.level
            const shouldFill =
              planBenefit &&
              !isAccessToBoop &&
              !benefit.id &&
              matchesFillingLevel

            return shouldFill
              ? {
                  ...planBenefit,
                  level: higherLevelPlan.level,
                  filledFromLevel: planBenefit.level,
                  shouldShowFilledLabel: true,
                }
              : benefit
          }),
        }),
      )

      const updatedWellnessPlans = [
        ...lowerLevelPlans,
        updatedPlan,
        ...updatedHigherLevelPlans,
        ...noLevelPlans,
      ]

      return {
        ...wellnessPlanVersion,
        plans: updatedWellnessPlans,
      }
    },
  }))

  const updatePlan = (newPlan: WellnessPlan) => {
    const updatedWellnessPlans = update(planIndex, newPlan, plans)

    setWellnessPlanVersion({
      ...wellnessPlanVersion,
      plans: updatedWellnessPlans,
    })
  }

  const updateBenefit = (benefit: WellnessPlanBenefit) => {
    const newBenefit = {
      ...benefit,
      shouldShowFilledLabel: false,
    }
    const benefitIndex = getBenefitIndex(plan, newBenefit.globalBenefitId)
    const updatedBenefits = update(benefitIndex, newBenefit, plan.benefits)
    const updatedPlan = { ...plan, benefits: updatedBenefits }

    updatePlan(updatedPlan)

    return updatedPlan
  }

  const handleChangeBenefits = () => {
    openWellnessPlanBenefitsDialog({
      plan,
      onSelected: (newBenefits: WellnessPlanBenefit[]) => {
        setWellnessPlanVersion(
          updatePlanBenefits(plan, newBenefits, wellnessPlanVersion),
        )
      },
    })
  }

  const handleAddAppointmentTypes = (benefit: WellnessPlanBenefit) => {
    openSelectBenefitAppointmentTypeDialog({
      benefit,
      onSelected: (appointmentTypeIds: string[]) => {
        const updatedPlan = updateBenefit({
          ...benefit,
          appointmentTypeIds,
        })

        if (hasCoverItemsValidationError) {
          const benefitsValid = validatePlanBenefits(updatedPlan)

          setHasCoverItemsValidationError(!benefitsValid)
        }
      },
    })
  }

  const handleAddCatalogItem = (benefit: WellnessPlanBenefit) => {
    openAddWellnessPlanCoverageItemsDialog({
      title: t('WellnessPlans:ADD_WHAT_IS_COVERED_FOR_BENEFIT', {
        benefitName: benefit.name,
      }),
      onSave: (cover: WellnessPlanCoverItem[]) => {
        const updatedPlan = updateBenefit({
          ...benefit,
          cover: uniq(benefit.cover.concat(cover)),
        })

        if (hasCoverItemsValidationError) {
          const benefitsValid = validatePlanBenefits(updatedPlan)

          setHasCoverItemsValidationError(!benefitsValid)
        }
      },
    })
  }

  const removeAppointmentTypeFromBenefit = (
    typeId: string,
    benefit: WellnessPlanBenefit,
  ) => {
    const updatedAppointmentTypeIds = benefit.appointmentTypeIds.filter(
      (id) => id !== typeId,
    )

    updateBenefit({
      ...benefit,
      appointmentTypeIds: updatedAppointmentTypeIds,
    })
  }

  const removeCoverageFromBenefit = (
    coverage: WellnessPlanCoverItem,
    benefit: WellnessPlanBenefit,
  ) => {
    const updatedCoverages = benefit.cover.filter(
      ({ itemId }) => itemId !== coverage.itemId,
    )

    updateBenefit({
      ...benefit,
      cover: updatedCoverages,
      deletedCoverIds: coverage.id
        ? (benefit.deletedCoverIds || []).concat(coverage.id)
        : benefit.deletedCoverIds,
    })
  }

  const onBenefitFilledNoticeClear = (benefit: WellnessPlanBenefit) => {
    const GlobalBenefits = flatten(pluck('benefits', globalBenefitGroups))
    const globalBenefit = getGlobalBenefit(
      benefit,
      GlobalBenefits,
    ) as WellnessPlanBenefit
    const emptyBenefit = globalBenefitToSelected(globalBenefit)

    updateBenefit(emptyBenefit)
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      <Grid
        container
        item
        className={classes.header}
        direction="column"
        wrap="nowrap"
      >
        <Text strong fontSize="1.8rem" variant="h1">
          {t('Common:CUSTOMIZE_SOMETHING', {
            something:
              plan.name ||
              TranslatedWellnessPlanLevelsLabels[plan.level]?.toLowerCase(),
          })}
        </Text>
        <Grid container item alignItems="baseline" wrap="nowrap">
          {disableEdit ? (
            <Text variant="body">{name.value}</Text>
          ) : (
            <PuiTextField
              className={classes.nameInput}
              field={name}
              inputProps={{ maxLength: 100 }}
              label={`${name.label} ${
                plan.name || TranslatedWellnessPlanLevelsLabels[plan.level]
              }`}
            />
          )}
          {isBaseLevel && (
            <WellnessPlanPriceTextField
              className={classes.priceTextField}
              disableEdit={disableEdit}
              field={price}
              plan={plan}
              wellnessPlanVersion={wellnessPlanVersion}
            />
          )}
        </Grid>
        {!isBaseLevel && (
          <>
            <Text className={classes.planPriceStructureText} variant="body">
              {t('Common:PLAN_PRICE_STRUCTURE')}
            </Text>
            <WellnessPlanPriceTypeControl
              disableEdit={disableEdit}
              plan={plan}
              ref={priceTypeControlRef}
              wellnessPlanVersion={wellnessPlanVersion}
            />
          </>
        )}
      </Grid>
      <WellnessPlanAutoRenewalToggle
        className={classes.autoRenewalToggle}
        plan={plan}
      />
      <Grid container item className={classes.content}>
        <Grid container item className={classes.contentHeader} wrap="nowrap">
          <Grid
            item
            className={classNames(
              classes.contentHeaderColumn,
              classes.benefitColumn,
            )}
          >
            <Text strong variant="lowAccent2">
              {t('Common:BENEFIT')}
            </Text>
            {!disableEdit && (
              <Text link variant="body2" onClick={handleChangeBenefits}>
                {t('Common:CHANGE_BENEFITS')}
              </Text>
            )}
          </Grid>
          <Grid
            item
            className={classNames(
              classes.contentHeaderColumn,
              classes.limitsColumn,
            )}
          >
            <Text strong variant="lowAccent2">
              {t('Common:LIMITS')}
            </Text>
          </Grid>
          <Grid
            item
            className={classNames(
              classes.contentHeaderColumn,
              classes.descriptionColumn,
            )}
          >
            <Text strong variant="lowAccent2">
              {t('Common:DESCRIPTION_OF_BENEFIT')}
            </Text>
            <TextWithTooltip
              className={classes.tooltipText}
              tooltipText={DESCRIPTION_HINT}
              variant="body2"
            >
              {t('Common:DISPLAYED_TO_CLIENTS_AT_SIGN_UP')}
            </TextWithTooltip>
          </Grid>
          <Grid item xs className={classes.contentHeaderColumn}>
            <ErrorTooltip
              message={t(
                'WellnessPlans:MAKE_SURE_TO_ADD_WHAT_ITEMS_ARE_COVERED_FOR_BENEFIT',
              )}
              open={hasCoverItemsValidationError}
              placement="top"
              validateTag={Math.random()}
            >
              <Text strong variant="lowAccent2">
                {t('Common:COVERED')}
              </Text>
            </ErrorTooltip>
          </Grid>
        </Grid>
        <Grid container item>
          {benefits.map((benefit) => {
            const group = Utils.findById(benefit.groupId, globalBenefitGroups)
            const GlobalBenefit = Utils.findById(
              benefit.globalBenefitId,
              group.benefits,
            )
            const globalBenefitHasAppointmentCover =
              getBenefitCoversAppointmentTypes(GlobalBenefit)
            const globalBenefitHasCatalogCover =
              getBenefitCoversCatalog(GlobalBenefit)
            const hasItems = getBenefitHasCoveredItems(benefit)

            const showLimitConfigurationColumn =
              GlobalBenefit?.limitCustomizable || benefit.limitTypeId
            const showCoverColumn = getBenefitShouldShowCoverColumn(benefit)
            const hasError = hasCoverItemsValidationError && !hasItems

            return (
              <Grid
                container
                item
                className={classes.contentBodyRow}
                key={benefit.globalBenefitId}
                wrap="nowrap"
              >
                <Grid
                  item
                  className={classNames(
                    classes.contentBodyColumn,
                    classes.benefitColumn,
                  )}
                >
                  <Text strong variant="subheading2">
                    {LanguageUtils.getTranslatedFieldName(benefit)}
                  </Text>
                  {benefit.shouldShowFilledLabel && (
                    <WellnessPlanBenefitFilledNotice
                      className={classes.notice}
                      onClear={() => onBenefitFilledNoticeClear(benefit)}
                    />
                  )}
                </Grid>
                <Grid
                  container
                  item
                  className={classNames(
                    classes.contentBodyColumn,
                    classes.limitsColumn,
                  )}
                  direction="column"
                >
                  {showLimitConfigurationColumn && (
                    <WellnessPlanBenefitLimitConfiguration
                      benefit={benefit}
                      disableEdit={disableEdit}
                      onUpdateBenefit={updateBenefit}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  className={classNames(
                    classes.contentBodyColumn,
                    classes.descriptionColumn,
                  )}
                >
                  <PuiTextArea
                    InputProps={{
                      classes: {
                        root: classes.textAreaRoot,
                      },
                    }}
                    className={classes.textAreaRoot}
                    classes={{
                      input: classes.textAreaRoot,
                    }}
                    margin="none"
                    value={benefit.description || ''}
                    onChange={Utils.handleFormTextInput((value) =>
                      updateBenefit({
                        ...benefit,
                        description: value,
                      }),
                    )}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs
                  className={classNames(
                    classes.contentBodyColumn,
                    classes.coveredColumn,
                  )}
                  direction="column"
                >
                  {showCoverColumn && (
                    <>
                      {hasItems && (
                        <Grid
                          container
                          item
                          className={classNames(
                            classes.coveredSection,
                            classes.coveredItemsSection,
                          )}
                          direction="column"
                        >
                          {benefit.appointmentTypeIds?.map((typeId) => (
                            <WellnessPlanBenefitAppointmentTypeRow
                              appointmentTypeId={typeId}
                              key={typeId}
                              wellnessPlanVersion={wellnessPlanVersion}
                              onDelete={() =>
                                removeAppointmentTypeFromBenefit(
                                  typeId,
                                  benefit,
                                )
                              }
                            />
                          ))}
                          {benefit.cover?.map((coverage) => (
                            <WellnessPlanBenefitCoverageRow
                              coverage={coverage}
                              key={coverage.itemId}
                              wellnessPlanVersion={wellnessPlanVersion}
                              onDelete={() =>
                                removeCoverageFromBenefit(coverage, benefit)
                              }
                            />
                          ))}
                        </Grid>
                      )}
                      <Grid container item>
                        {globalBenefitHasAppointmentCover && (
                          <Grid
                            item
                            className={classNames(
                              classes.coveredSection,
                              classes.coveredSectionNoBorder,
                            )}
                          >
                            <AddButton
                              addText={`${t('Common:ADD_APPOINTMENT_TYPES')}${
                                hasError ? '*' : ''
                              }`}
                              classes={{
                                addText: classNames(classes.addText, {
                                  [classes.addTextError]: hasError,
                                }),
                                plusButton: classNames({
                                  [classes.plusButtonError]: hasError,
                                }),
                              }}
                              onAdd={() => handleAddAppointmentTypes(benefit)}
                            />
                          </Grid>
                        )}
                        {globalBenefitHasCatalogCover && (
                          <Grid
                            item
                            className={classNames(
                              classes.coveredSection,
                              classes.coveredSectionNoBorder,
                            )}
                          >
                            <AddButton
                              addText={`${t('Common:ADD_CATALOG_ITEM')}${
                                hasError ? '*' : ''
                              }`}
                              classes={{
                                addText: classNames(classes.addText, {
                                  [classes.addTextError]: hasError,
                                }),
                                plusButton: classNames({
                                  [classes.plusButtonError]: hasError,
                                }),
                              }}
                              onAdd={() => handleAddCatalogItem(benefit)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
})

export default WellnessPlanCustomization
