/* eslint-disable react/iframe-missing-sandbox */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Nil, PuiDialog } from '@pbt/pbt-ui-components'

import { VideoItem } from '~/types/entities/analytics'

import EducationalVideoLink from './EducationalVideoLink'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 990,
      maxWidth: 990,
    },
    rightPanel: {
      backgroundColor: theme.colors.patientEditIcon,
    },
    item: {
      marginBottom: theme.spacing(3),
    },
  }),
  { name: 'EducationalVideoDialog' },
)

interface EducationalVideoDialogProps {
  items: VideoItem[]
  onClose: () => void
  open: boolean
  value: string | Nil
}

const EducationalVideoDialog = ({
  open,
  onClose,
  items,
  value: valueProp,
}: EducationalVideoDialogProps) => {
  const classes = useStyles()

  const [selectedVideoItem, setSelectedVideoItem] = useState<VideoItem>()

  const getVideoItemByValue = (value: string | Nil) =>
    R.find(R.propEq('value', value), items)

  useEffect(() => {
    setSelectedVideoItem(getVideoItemByValue(valueProp))
  }, [valueProp, items])

  return (
    <PuiDialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
      <Grid container wrap="nowrap">
        <Grid item p={4}>
          <iframe
            height="360"
            src={selectedVideoItem?.videoLink}
            title={selectedVideoItem?.value}
            width="645"
          />
        </Grid>
        <Grid item xs className={classes.rightPanel} pt={10} px={2}>
          {items.map((item) => (
            <EducationalVideoLink
              classes={{
                root: classes.item,
              }}
              key={item.value}
              value={item.value}
              onClick={(value: string) => {
                setSelectedVideoItem(getVideoItemByValue(value))
              }}
            />
          ))}
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default EducationalVideoDialog
