import React from 'react'
import { Input } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { PuiSelect, PuiSelectProps } from '@pbt/pbt-ui-components'

import { getTimeUnitListPluralized } from '~/utils/time'

const useStyles = makeStyles(
  () => ({
    container: {
      minWidth: 94,
    },
  }),
  { name: 'TimeUnitsSelect' },
)

export interface TimeUnitsSelectProps extends PuiSelectProps {
  amount?: number
}

const TimeUnitsSelect = ({ amount, items, ...rest }: TimeUnitsSelectProps) => {
  const classes = useStyles()

  const LocalizedTimeUnits = R.isNil(amount)
    ? items
    : getTimeUnitListPluralized(items || [], amount)

  return (
    <PuiSelect
      className={classes.container}
      input={<Input id="time-units-select" />}
      items={LocalizedTimeUnits}
      renderEmpty={false}
      {...rest}
    />
  )
}

export default TimeUnitsSelect
