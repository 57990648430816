import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  ImageScalingUtils,
  NumberUtils,
  PatientAvatar,
  Text,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'

import AlertLabel from '~/components/common/labels/AlertLabel'
import ApproximateAgeLabel from '~/components/common/labels/ApproximateAgeLabel'
import BreedLabel from '~/components/common/labels/BreedLabel'
import NeuteringLabel from '~/components/common/labels/NeuteringLabel'
import Link from '~/components/common/link/Link'
import { AlertColorLevel } from '~/constants/alertColors'
import { useAlertType } from '~/store/hooks/patient'
import { getSpecies } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getClientId, getPatientId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import { getPatientAge } from '~/utils'

import useGetPreferredContactMethod from '../../clients/details/new-client-and-patient/useGetPreferredContactMethod'
import WellnessPlanMemberButton from '../../wellness-plans/WellnessPlanMemberButton'

const AVATAR_SIZE = 56

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.tableBackground,
      border: theme.constants.tableBorder,
    },
    titleContainer: {
      padding: theme.spacing(2, 1.5),
      borderRight: theme.constants.tabBorder,
    },
    clientContainer: {
      padding: theme.spacing(2),
      borderRight: theme.constants.tableBorder,
    },
    clientNameText: {
      fontWeight: 500,
      fontSize: '1.6rem',
      marginBottom: theme.spacing(1),
    },
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      marginRight: theme.spacing(1.5),
    },
    ageLabelText: {
      whiteSpace: 'pre',
    },
    alertLabel: {
      maxWidth: 300,
      width: 'max-content',
    },
    patientButtonsContainer: {
      marginLeft: theme.spacing(1),
      width: 'fit-content',
    },
    alertLabelRoot: {
      maxHeight: 24,
      marginTop: theme.spacing(1),
    },
    alertLabelIcon: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'SoapClientAndPatientMainDetailsLocked' },
)

const SoapClientAndPatientMainDetailsLocked = () => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const patientId = useSelector(getPatientId)
  const clientId = useSelector(getClientId)
  const client = useSelector(getUser(clientId))
  const patient = useSelector(getPatient(patientId))
  const Species = useSelector(getSpecies)

  const preferredContactMethod = useGetPreferredContactMethod({ client })

  const isLoadingClient = !client?.id
  const isLoadingPatient = !patient?.id

  const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
    patient?.photo,
    patient?.photoThumbnail,
    AVATAR_SIZE,
  )

  const { alertsToRender } = useAlertType(patientId)

  return (
    <Grid container item className={classes.root} wrap="nowrap">
      <Grid item className={classes.titleContainer} xs={2}>
        <Text variant="h4">{t('Common:CLIENT_AND_PATIENT')}</Text>
      </Grid>
      <Grid container item xs wrap="nowrap">
        <Grid
          container
          item
          xs
          className={classes.clientContainer}
          wrap="nowrap"
        >
          <Grid container item direction="column">
            <TextInteractive
              isLoading={isLoadingClient}
              loaderHeight={32}
              loaderWidth={50}
              variant="body2"
            >
              <Link
                className={classes.clientNameText}
                to={`/client/${clientId}`}
              >
                {Utils.getPersonString(client)}
              </Link>
            </TextInteractive>
            {!R.isEmpty(preferredContactMethod) && (
              <TextInteractive
                isLoading={isLoadingClient}
                loaderHeight={24}
                variant="body2"
              >
                {`${preferredContactMethod.label}: ${preferredContactMethod.value}`}
                &nbsp;
              </TextInteractive>
            )}
            <TextInteractive
              isLoading={isLoadingClient}
              loaderHeight={24}
              loaderWidth={120}
              variant="body2"
            >
              {`${t('Common:BALANCE')}: ${NumberUtils.formatMoney(
                client?.balance,
              )}`}
            </TextInteractive>
            <TextInteractive
              isLoading={isLoadingClient}
              loaderHeight={24}
              loaderWidth={120}
              variant="body2"
            >
              {client?.notes ? `${t('Common:NOTES')}: ${client.notes}` : ''}
            </TextInteractive>
          </Grid>
          <Grid item>
            {client?.active && client?.alertText && (
              <AlertLabel
                alertColorId={client.alertColorId}
                alertColorLevel={AlertColorLevel.CLIENT}
                className={classes.alertLabel}
                message={client.alertText}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item xs p={2} wrap="nowrap">
          <PatientAvatar
            small
            animal={Utils.getConstantName(patient?.species, Species)}
            className={classes.avatar}
            showBoopBadge={patient?.isBoop}
            src={avatarSrc}
          />
          <Grid container item xs direction="column">
            <TextInteractive
              isLoading={isLoadingPatient}
              loaderHeight={32}
              loaderWidth={50}
              variant="body2"
            >
              <Link
                className={classes.clientNameText}
                to={`/patient/${patientId}`}
              >
                {patient?.name}
              </Link>
            </TextInteractive>
            <Grid container item wrap="nowrap">
              <ApproximateAgeLabel
                noWrap
                showTooltip
                align="left"
                classes={{ text: classes.ageLabelText }}
                isApproximate={
                  Boolean(patient?.dateOfBirth) && patient?.approximateAge
                }
                isLoading={isLoadingPatient}
                loaderHeight={24}
                variant="body2"
              >
                {patient?.dateOfBirth ? `${getPatientAge(patient)} | ` : ''}
              </ApproximateAgeLabel>
              {!isLoadingPatient && (
                <NeuteringLabel patient={patient} variant="body2" />
              )}
            </Grid>
            <Grid container item>
              <BreedLabel
                isLoading={isLoadingPatient}
                loaderHeight={24}
                loaderWidth={120}
                patient={patient}
                variant="body2"
              />
            </Grid>
            <TextInteractive
              isLoading={isLoadingClient}
              loaderHeight={24}
              loaderWidth={120}
              variant="body2"
            >
              {patient?.notes ? `${t('Common:NOTES')}: ${patient.notes}` : ''}
            </TextInteractive>
          </Grid>
          <Grid
            container
            item
            className={classes.patientButtonsContainer}
            direction="column"
          >
            <WellnessPlanMemberButton
              clientId={clientId}
              patientId={patientId}
            />
            {!R.isEmpty(alertsToRender) && (
              <AlertLabel
                showIcon
                alertColorId={patient?.alertColorId}
                alertColorLevel={AlertColorLevel.PATIENT}
                alertTypes={alertsToRender}
                classes={{
                  root: classes.alertLabelRoot,
                  icon: classes.alertLabelIcon,
                }}
                variant="attention"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SoapClientAndPatientMainDetailsLocked
