import React from 'react'
import { useSelector } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { RoleName, Utils } from '@pbt/pbt-ui-components'

import { getSoapStaffInfo } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'

import { getRolePropertyOfSoap } from './appointmentMemberUtils'

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      height: 40,
      border: theme.constants.tabBorder,
    },
    titleCell: {
      width: 130,
    },
    tableCell: {
      padding: theme.spacing(1, 2),
      border: theme.constants.tabBorder,
      color: theme.colors.secondaryText,
      fontSize: '1.4rem',
    },
  }),
  { name: 'AssignedToSoapDetailsLockedItem' },
)

export interface AssignedToSoapDetailsLockedItemProps {
  displayName: string
  name: RoleName
}

const AssignedToSoapDetailsLockedItem = ({
  name,
  displayName,
}: AssignedToSoapDetailsLockedItemProps) => {
  const classes = useStyles()
  const soapStaffInfo = useSelector(getSoapStaffInfo)
  const person = useSelector(
    getUser(getRolePropertyOfSoap(soapStaffInfo, name)),
  )

  if (!person) {
    return null
  }

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classNames(classes.tableCell, classes.titleCell)}>
        {displayName || name}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {Utils.getPersonString(person)}
      </TableCell>
    </TableRow>
  )
}

export default AssignedToSoapDetailsLockedItem
