import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'
import { Check as CheckIcon } from '@pbt/pbt-ui-components/src/icons'

import { MigrationEntity } from '~/types/entities/migration'

const MigrationStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEED: 'SUCCEED',
  FAILED: 'FAILED',
}

const useStyles = makeStyles(
  (theme) => ({
    spinner: {
      marginRight: theme.spacing(0.5),
    },
    check: {
      color: theme.colors.link,
    },
    cross: {
      color: theme.colors.alertLabelError,
    },
    icon: {
      width: theme.spacing(6),
      marginRight: theme.spacing(1),
    },
    count: {
      minWidth: 40,
    },
    name: {
      fontSize: '1.6rem',
    },
    tableCell: {
      padding: theme.spacing(0, 1),
      border: 'none',
    },
    takeMostSpace: {
      width: '100%',
    },
  }),
  { name: 'CoreMigrationProgressTable' },
)

interface CoreMigrationProgressTableProps {
  countKey?: keyof MigrationEntity
  nameKey?: keyof MigrationEntity
  rows: MigrationEntity[]
}

const CoreMigrationProgressTable = ({
  countKey = 'importedCount',
  nameKey = 'name',
  rows,
}: CoreMigrationProgressTableProps) => {
  const classes = useStyles()

  return (
    <Table>
      <TableBody>
        {rows?.map((row) => (
          <TableRow key={row[nameKey]}>
            {row.status && (
              <TableCell
                className={classNames(classes.tableCell, classes.icon)}
              >
                {row.status === MigrationStatus.IN_PROGRESS && (
                  <CircularProgress className={classes.spinner} size={20} />
                )}
                {row.status === MigrationStatus.SUCCEED && (
                  <CheckIcon className={classes.check} />
                )}
                {row.status === MigrationStatus.FAILED && (
                  <CloseIcon className={classes.cross} />
                )}
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <Text className={classes.count}>{row[countKey]}</Text>
            </TableCell>
            <TableCell
              className={classNames(classes.tableCell, classes.takeMostSpace)}
            >
              <Text>{row[nameKey]}</Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CoreMigrationProgressTable
