import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  BackButton,
  ButtonWithLoader,
  ClassesType,
  Constant,
  ErrorTooltip,
  GenderRestriction,
  IdObject,
  LanguageUtils,
  PermissionArea,
  PuiCheckbox,
  Question,
  Text,
  TextWithTooltip,
  UnitUtils,
  UnsavedQuestion,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { UnitTypes } from '@pbt/pbt-ui-components/src/localization'

import AgeRangeSelector from '~/components/common/inputs/AgeRangeSelector'
import GenderRestrictionSelect from '~/components/common/inputs/gender/GenderRestrictionSelect'
import PuiSelectAll from '~/components/common/inputs/PuiSelectAll'
import QuantityInput from '~/components/common/inputs/QuantityInput'
import WeightRangeInput from '~/components/common/inputs/WeightRangeInput'
import { getUnitsState } from '~/store/duck/settings'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getCustomQuestionFrequencies,
  getEventType,
  getSpecies,
} from '~/store/reducers/constants'
import { getQuestionsIsLoading } from '~/store/reducers/questions'
import { DataHandleWithUnsavedChanges } from '~/types'
import { getConstantsList, isFieldValuesChanged } from '~/utils'
import useFieldsChanged from '~/utils/useFieldsChanged'
import useWorkflowTypes from '~/utils/useWorkflowTypes'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    radioGroup: {
      gridColumnGap: theme.spacing(4),
    },
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: theme.spacing(0.5),
    },
    labelRoot: {
      margin: 0,
    },
    button: {
      width: 150,
    },
  }),
  { name: 'QuestionSettings' },
)

export interface QuestionSettingsProps {
  classes?: ClassesType<typeof useStyles>
  onBack?: () => void
  onChange?: () => void
  onProceed?: (question: Partial<UnsavedQuestion>) => void
  question?: Question
  view?: boolean
}

export interface QuestionSettingsHandle extends DataHandleWithUnsavedChanges {
  add: () => void
}

const QuestionSettings = forwardRef<
  QuestionSettingsHandle,
  QuestionSettingsProps
>(function QuestionSettings(
  {
    classes: classesProp,
    question,
    view,
    onBack,
    onProceed,
    onChange = () => {},
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const isLoading = useSelector(getQuestionsIsLoading)
  const EventType = useSelector(getEventType)
  const Species = useSelector(getSpecies)
  const WorkFlowTypes = useWorkflowTypes()
  const CustomQuestionFrequencies: Constant[] = useSelector(
    getCustomQuestionFrequencies,
  )
  const unitsState = useSelector(getUnitsState)
  const permissions = useSelector(getCRUDByArea(PermissionArea.BUSINESS))
  const { t } = useTranslation(['Admin', 'Common', 'Tooltips'])

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const DefaultFrequencyId = Utils.findConstantIdByName(
    'Ask every time',
    CustomQuestionFrequencies,
  )
  const DefaultWorkflowId = Utils.findConstantIdByName(
    'SOAP: History',
    WorkFlowTypes,
  )

  const { fields, validate, reset } = useFields(
    [
      {
        name: 'frequencyId',
        label: t('Common:FREQUENCY_ONE'),
        validators: ['required'],
        initialValue: question?.frequencyId || DefaultFrequencyId,
      },
      {
        name: 'workflow',
        label: t('Common:WORKFLOW_ONE'),
        validators: ['required'],
        initialValue:
          (question?.workflowIds && question.workflowIds[0]) ||
          DefaultWorkflowId,
      },
      {
        name: 'rank',
        label: t('Common:RANK'),
        initialValue: question?.rank || 1,
      },
      {
        name: 'patientIsNew',
        label: t('Common:PATIENT_IS_NEW'),
        type: 'toggle',
        initialValue: question?.patientIsNew || false,
      },
      {
        name: 'appointmentTypeIds',
        label: t('Common:APPOINTMENT_TYPES'),
        type: 'select',
        initialValue: getConstantsList(
          question?.appointmentTypeIds,
          AppointmentEvent.subTypes,
        ),
      },
      {
        name: 'species',
        label: t('Common:SPECIES'),
        type: 'select',
        initialValue: getConstantsList(question?.speciesIds, Species) || '',
      },
      {
        name: 'genderRestrictions',
        label: t('Common:GENDER'),
        type: 'select',
        initialValue: question?.genderRestrictions || [],
      },
      { name: 'ageRangeMin', initialValue: question?.ageRangeMin ?? '' },
      {
        name: 'ageRangeMinUnitId',
        initialValue: question?.ageRangeMinUnitId || '',
      },
      { name: 'ageRangeMax', initialValue: question?.ageRangeMax ?? '' },
      {
        name: 'ageRangeMaxUnitId',
        initialValue: question?.ageRangeMaxUnitId || '',
      },
      {
        name: 'weightMin',
        initialValue:
          UnitUtils.convertUnits(
            UnitTypes.WEIGHT,
            question?.weightMin,
            unitsState,
          ) || '',
      },
      {
        name: 'weightMax',
        initialValue:
          UnitUtils.convertUnits(
            UnitTypes.WEIGHT,
            question?.weightMax,
            unitsState,
          ) || '',
      },
    ],
    false,
  )

  const {
    frequencyId,
    workflow,
    rank,
    patientIsNew,
    appointmentTypeIds,
    species,
    genderRestrictions,
    ageRangeMin,
    ageRangeMinUnitId,
    ageRangeMax,
    ageRangeMaxUnitId,
    weightMin,
    weightMax,
  } = fields

  useFieldsChanged(() => {
    onChange()
  }, fields)

  useEffect(() => {
    if (question) {
      reset()
    }
  }, [question, EventType, unitsState])

  const createQuestionObject = () => {
    const newQuestion: Partial<UnsavedQuestion> = {
      frequencyId: frequencyId.value,
      workflowIds: [workflow.value],
      patientIsNew: patientIsNew.value,
      appointmentTypeIds: R.pluck(
        'id',
        (appointmentTypeIds.value || []) as IdObject[],
      ),
      speciesIds: R.pluck('id', species.value as IdObject[]) || [],
      genderRestrictions: (
        (genderRestrictions.value || []) as GenderRestriction[]
      ).map((restriction) =>
        R.pick(['genderId', 'spayedNeuteredStatusId'], restriction),
      ),
    }

    if (Number.isInteger(ageRangeMin.value)) {
      newQuestion.ageRangeMin = ageRangeMin.value
      newQuestion.ageRangeMinUnitId = ageRangeMinUnitId.value
    }

    if (Number.isInteger(ageRangeMax.value)) {
      newQuestion.ageRangeMax = ageRangeMax.value
      newQuestion.ageRangeMaxUnitId = ageRangeMaxUnitId.value
    }

    if (Number.isInteger(rank.value)) {
      newQuestion.rank = rank.value
    }

    newQuestion.weightMin = UnitUtils.serializeWeightUnit({
      field: weightMin,
      unitsState,
      initialValue: Number(question?.weightMin),
      fallback: 0,
    })

    newQuestion.weightMax = UnitUtils.serializeWeightUnit({
      field: weightMax,
      unitsState,
      initialValue: Number(question?.weightMax),
      fallback: 0,
    })

    return newQuestion
  }

  const add = () => {
    if (onProceed && validate()) {
      onProceed({ ...question, ...createQuestionObject() })
    }
  }

  useImperativeHandle(ref, () => ({
    validate,
    get: createQuestionObject,
    hasUnsavedChanges: () => isFieldValuesChanged(fields),
    add,
  }))

  return (
    <Grid container className={classes.root} direction="column" pb={3} px={3}>
      <Grid item>
        <FormControl component="fieldset">
          <ErrorTooltip
            message={t('Tooltips:CHOOSE_FREQUENCY')}
            open={!frequencyId.valid}
            placement="top"
          >
            <FormLabel component="legend">
              <TextWithTooltip
                strong
                tooltipText={t('Tooltips:QUESTION_FREQUENCY_SHOWN')}
                variant="subheading3"
              >
                {frequencyId.label}*
              </TextWithTooltip>
            </FormLabel>
          </ErrorTooltip>
          <RadioGroup
            row
            aria-label={t('Common:FREQUENCY_OTHER')}
            className={classes.radioGroup}
            name="frequencies1"
            value={frequencyId.value}
            onChange={frequencyId.set}
          >
            {CustomQuestionFrequencies.map((frequency) => (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classes.radioLabel,
                }}
                control={
                  <Radio
                    className={classes.radio}
                    disabled={!permissions.update}
                  />
                }
                key={frequency.id}
                label={LanguageUtils.getTranslatedFieldName(frequency)}
                value={frequency.id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item mt={2}>
        <FormControl component="fieldset">
          <ErrorTooltip
            message={t('Tooltips:CHOOSE_WORKFLOW')}
            open={frequencyId.valid && !workflow.valid}
            placement="top"
          >
            <FormLabel component="legend">
              <TextWithTooltip
                strong
                tooltipText={
                  <span>
                    <Trans
                      components={{
                        boldSpan: (
                          <Text strong component="span" variant="body" />
                        ),
                        emphasisElement: <em />,
                      }}
                      defaults={`
                          Determines where in an appointment workflow the question will be shown.
                          <br /><br />
                          <boldSpan>Check in: </boldSpan>
                          Shown on the scheduler, once the patient is marked as <emphasisElement>Arrived.</emphasisElement>
                          <br />
                          <boldSpan>History: </boldSpan>Shown on the <emphasisElement>History</emphasisElement> section of a SOAP
                        </span>
                      `}
                      i18nKey="Tooltips:QUESTION_WORKFLOW"
                    />
                  </span>
                }
                variant="subheading3"
              >
                {workflow.label}*
              </TextWithTooltip>
            </FormLabel>
          </ErrorTooltip>
          <RadioGroup
            row
            aria-label={t('Common:WORKFLOW_OTHER')}
            className={classes.radioGroup}
            name="workflows1"
            value={workflow.value}
            onChange={workflow.set}
          >
            {WorkFlowTypes.map((type) => (
              <FormControlLabel
                classes={{
                  root: classes.labelRoot,
                  label: classes.radioLabel,
                }}
                control={
                  <Radio
                    className={classes.radio}
                    disabled={!permissions.update}
                  />
                }
                key={type.id}
                label={LanguageUtils.getTranslatedFieldName(type)}
                value={type.id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item mt={2}>
        <TextWithTooltip
          strong
          tooltipText={t('Tooltips:QUESTIONS_APPEAR_ORDER')}
          variant="subheading3"
        >
          {rank.label}
        </TextWithTooltip>
        <QuantityInput
          showControls
          disabled={!permissions.update}
          field={rank}
          max={1000}
          min={1}
        />
      </Grid>
      <Grid item mt={2}>
        <TextWithTooltip
          strong
          tooltipText={t(
            'Tooltips:IF_PATIENT_OR_APPOINTMENT_DOES_NOT_MEET_CRITERIA_QUESTION',
          )}
          variant="subheading3"
        >
          {t('Admin:CATALOG.QUESTION_SETTINGS.SHOW_QUESTION')}
        </TextWithTooltip>
      </Grid>
      <Grid item>
        <PuiCheckbox
          disabled={!permissions.update}
          field={patientIsNew}
          label={patientIsNew.label}
        />
      </Grid>
      <PuiSelectAll
        disabled={!permissions.update}
        field={appointmentTypeIds}
        items={AppointmentEvent.subTypes}
        label={appointmentTypeIds.label}
      />
      <Grid container item columnSpacing={2} mt={2}>
        <Grid item xs>
          <PuiSelectAll
            disabled={!permissions.update}
            field={species}
            items={Species}
            label={species.label}
          />
        </Grid>
        <Grid item xs>
          <GenderRestrictionSelect
            disabled={!permissions.update}
            field={genderRestrictions}
          />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={2} mt={3}>
        <Grid container item alignItems="center" xs={5}>
          <Grid item>
            <Text display="inline" variant="body">
              {t('Common:AGE_RANGE_LABEL')}:
            </Text>
          </Grid>
          <Grid item xs ml={1}>
            <AgeRangeSelector
              fullWidth
              disabled={!permissions.update}
              endUnit={ageRangeMaxUnitId.value}
              endValue={ageRangeMax.value}
              startUnit={ageRangeMinUnitId.value}
              startValue={ageRangeMin.value}
              onEndChange={ageRangeMax.setValue}
              onEndUnitChange={ageRangeMaxUnitId.setValue}
              onStartChange={ageRangeMin.setValue}
              onStartUnitChange={ageRangeMinUnitId.setValue}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" xs={7}>
          <WeightRangeInput
            disabled={!permissions.update}
            maxWeightField={weightMax}
            minWeightField={weightMin}
          />
        </Grid>
      </Grid>
      {!view && (
        <Grid container item columnSpacing={1.5} mt={2}>
          <Grid item>
            <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
          </Grid>
          <Grid item>
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading}
              loading={isLoading}
              type="submit"
              onClick={add}
            >
              {t('Common:ADD_ACTION')}
            </ButtonWithLoader>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
})

export default QuestionSettings
