import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, Text } from '@pbt/pbt-ui-components'
import { Calculator as CalculatorIcon } from '@pbt/pbt-ui-components/src/icons'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { VitalMeasurement } from '~/constants/vitals'
import { useGetVariationDescriptionString } from '~/store/hooks/orders'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getPatient } from '~/store/reducers/patients'
import { getLastVitalMeasurement } from '~/store/reducers/soap'
import { OrderDetails, Prescription, Variation } from '~/types'

import DosageCalculator from './DosageCalculator'
import { DrugCompoundInfoHandle } from './DrugCompoundInfo'
import VariationsTable from './VariationsTable'

const useStyles = makeStyles(
  (theme) => ({
    expandCollapse: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    calculatorIcon: {
      margin: theme.spacing(-0.25, 0, 0, -0.5),
    },
    variationsTable: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'DrugInfoSection' },
)

const getLastWeightMeasurement = getLastVitalMeasurement(
  VitalMeasurement.WEIGHT,
)

export interface DrugInfoSectionProps {
  activeWorkflow?: PrescriptionWorkflowType
  compoundingReasonField: Field
  compoundingReasonIdField: Field
  disabled?: boolean
  drugCompoundInfoRef: React.Ref<DrugCompoundInfoHandle>
  isChewyReactiveRx?: boolean
  isCustomCompound?: boolean
  onRefChange?: () => void
  onVariationChange: (
    priceVariation: OrderDetails | undefined,
    isCustom?: boolean,
  ) => void
  outsideSoap: boolean
  patientId: string
  prescription: Prescription
}

const DrugInfoSection = ({
  activeWorkflow,
  compoundingReasonField,
  compoundingReasonIdField,
  disabled,
  drugCompoundInfoRef,
  isChewyReactiveRx,
  isCustomCompound,
  onRefChange,
  onVariationChange,
  outsideSoap,
  patientId,
  prescription,
}: DrugInfoSectionProps) => {
  const classes = useStyles()

  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const variation = isChewyReactiveRx
    ? (prescription.drugInfo as Variation)
    : prescription.variation ||
      prescription[
        isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation'
      ]

  const drugDescription = useGetVariationDescriptionString(
    variation,
    prescription?.drugInfo?.flavor,
  )
  const { t } = useTranslation(['Common', 'Dialogs'])

  const soapWeight = useSelector(getLastWeightMeasurement)
  // Outside soap we do no have Examinations
  const weight = outsideSoap ? undefined : soapWeight
  const patient = useSelector(getPatient(patientId))

  const [dosageAndVariationExpanded, setDosageAndVariationExpanded] =
    useState(isCustomCompound)

  useEffect(() => {
    if (!compoundingReasonIdField.valid || !compoundingReasonField.valid) {
      setDosageAndVariationExpanded(true)
    }
  }, [compoundingReasonField.valid, compoundingReasonIdField.valid])

  return (
    <>
      <Text variant="body2">
        {isCustomCompound ? t('Common:CUSTOM_DRUG_COMPOUND') : drugDescription}
      </Text>
      <Text
        strong
        className={classes.expandCollapse}
        variant="h5"
        onClick={() =>
          setDosageAndVariationExpanded(!dosageAndVariationExpanded)
        }
      >
        <CalculatorIcon className={classes.calculatorIcon} />
        {dosageAndVariationExpanded
          ? t('Dialogs:PRESCRIPTION_DIALOG.HIDE_DOSAGE_AND_VARIATIONS')
          : t('Dialogs:PRESCRIPTION_DIALOG.SHOW_DOSAGE_AND_VARIATIONS')}
        {dosageAndVariationExpanded ? (
          <KeyboardArrowUp />
        ) : (
          <KeyboardArrowDown />
        )}
      </Text>
      <Collapse in={dosageAndVariationExpanded}>
        <DosageCalculator
          speciesId={patient?.species}
          weight={weight as number}
        />
        <VariationsTable
          activeWorkflow={activeWorkflow}
          classes={{ root: classes.variationsTable }}
          disabled={disabled}
          drugCompoundInfoRef={drugCompoundInfoRef}
          isCustomCompound={isCustomCompound}
          outsideSoap={outsideSoap}
          prescription={prescription}
          onRefChange={onRefChange}
          onVariationChange={onVariationChange}
        />
      </Collapse>
    </>
  )
}

export default DrugInfoSection
