import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, InfiniteLoaderList, Text } from '@pbt/pbt-ui-components'

import { ShipmentItem } from '~/types'

import ChooseShipmentItemTableRow from './ChooseShipmentItemTableRow'

const useStyles = makeStyles(
  (theme) => ({
    table: {
      border: theme.constants.tableBorder,
    },
    headingRow: {
      borderBottom: theme.constants.tableBorder,
    },
  }),
  { name: 'ChooseShipmentItemTable' },
)

export interface ChooseShipmentItemTableProps {
  chosenItemField: Field
  isMultiSelect: boolean
  loadMoreItems: (from: number, to: number) => void
  options: ShipmentItem[]
  totalCount: number
}

const ChooseShipmentItemTable = ({
  options,
  loadMoreItems,
  totalCount,
  chosenItemField,
  isMultiSelect,
}: ChooseShipmentItemTableProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isItemLoaded = (index: number) => Boolean(options[index])

  return (
    <Grid container className={classes.table} direction="column">
      <Grid container className={classes.headingRow}>
        <Grid item py={1} width={48} />
        <Grid item py={1} width={200}>
          <Text strong variant="lowAccent2">
            {t('Common:NAME')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:RECEIVED_ON')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:MANUFACTURER')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:LOT_SHIPMENT_NUMBER')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:SERIAL_NUMBER_SIGN')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:EXPIRATION_DATE')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:PRACTICE_REFERENCE')}
          </Text>
        </Grid>
        <Grid item xs py={1}>
          <Text strong variant="lowAccent2">
            {t('Common:STORAGE_LOCATION')}
          </Text>
        </Grid>
      </Grid>
      <InfiniteLoaderList
        isItemLoaded={isItemLoaded}
        itemCount={totalCount}
        itemData={options}
        loadMoreItems={loadMoreItems}
        style={{ height: 600 }}
      >
        {(shipmentItem = {}) => (
          <ChooseShipmentItemTableRow
            chosenItemField={chosenItemField}
            isMultiSelect={isMultiSelect}
            key={shipmentItem.id}
            shipmentItem={shipmentItem}
          />
        )}
      </InfiniteLoaderList>
    </Grid>
  )
}

export default ChooseShipmentItemTable
