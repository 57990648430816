import { gql } from '@apollo/client'

import {
  INVOICE_LINE_ITEM_ARRANGEMENT_INPUT_FIELDS,
  INVOICE_LINE_ITEM_FIELDS,
  RETAIL_ORDER_LINE_ITEM_FIELDS,
} from '../fragments'

export const UPDATE_INVOICE_LINE_ITEM = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  mutation UpdateInvoiceLineItem(
    $id: ID!
    $input: UpdateInvoiceLineItemInput!
  ) {
    updateInvoiceLineItem(id: $id, input: $input) {
      ...InvoiceLineItemFields
    }
  }
`

export const REARRANGE_INVOICE_LINE_ITEMS = gql`
  ${INVOICE_LINE_ITEM_ARRANGEMENT_INPUT_FIELDS}
  mutation RearrangeInvoiceLineItems(
    $invoiceId: ID!
    $input: [InvoiceLineItemArrangementInput]!
  ) {
    # eslint-disable-next-line @graphql-eslint/no-deprecated
    rearrangeInvoiceLineItems(invoiceId: $invoiceId, input: $input) {
      ...InvoiceLineItemArrangementFields
    }
  }
`

export const REARRANGE_GROUPED_LINE_ITEMS = gql`
  mutation RearrangeGroupedLineItems(
    $invoiceId: ID!
    $input: [GroupedLineItemArrangementInput]!
  ) {
    rearrangeGroupedLineItems(invoiceId: $invoiceId, input: $input) {
      lineItemId
      lineItemType
      viewOrderNumber
    }
  }
`

export const UPDATE_RETAIL_ORDER_LINE_ITEM = gql`
  ${RETAIL_ORDER_LINE_ITEM_FIELDS}
  mutation UpdateRetailOrderLineItem($input: UpdateRetailOrderLineItemInput!) {
    updateRetailOrderLineItem(input: $input) {
      ...RetailOrderLineItemFields
    }
  }
`
