import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { Text } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import { isRetailOrderLineItem } from '~/components/dashboard/invoices/invoiceUtils'
import { FINANCE_CELL_WIDTH } from '~/constants/financeTable'
import { InvoiceLineItem } from '~/types'
import { useGetPriceUnitsDisplayName } from '~/utils/priceUtils'

const { UNIT_PRICE } = FINANCE_CELL_WIDTH

const useStyles = makeStyles(
  () => ({
    priceCell: {
      minWidth: UNIT_PRICE,
      width: UNIT_PRICE,
    },
  }),
  { name: 'FinanceItemUnitPriceCellBody' },
)

export interface FinanceItemUnitPriceCellBodyProps {
  isFullyRefunded?: boolean
  item: InvoiceLineItem | RetailOrderLineItem
  tableCellClassName: string
  tableCellWithBorder: string
}

const FinanceItemUnitPriceCellBody = ({
  item,
  isFullyRefunded = false,
  tableCellClassName,
  tableCellWithBorder,
}: FinanceItemUnitPriceCellBodyProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const priceUnitsDisplayName = useGetPriceUnitsDisplayName(
    isRetailOrderLineItem(item) ? t('Common:EACH') : item.priceUnits,
  )

  return (
    <TableCell
      align="left"
      className={classNames(
        tableCellClassName,
        tableCellWithBorder,
        classes.priceCell,
      )}
    >
      {!item.declined && (
        <Text variant={isFullyRefunded ? 'lowAccent2' : 'body2'}>
          {priceUnitsDisplayName}
        </Text>
      )}
    </TableCell>
  )
}

export default FinanceItemUnitPriceCellBody
