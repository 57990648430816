import React from 'react'

import { UserSelectFilterScope } from '../inputs/UserSelect'
import PersonFilter, { PersonFilterProps } from './PersonFilter'

interface MainTeamMemberFilterProps
  extends Omit<PersonFilterProps, 'filterScope'> {}

const MainTeamMemberFilter = (props: MainTeamMemberFilterProps) => (
  <PersonFilter filterScope={UserSelectFilterScope.MainStaff} {...props} />
)

export default MainTeamMemberFilter
