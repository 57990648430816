import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchMigrationAnalyzeStatus,
  startReviewExceptions,
} from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getProgressEntities,
} from '~/store/reducers/migration'

import CoreMigrationProgressComponent from './CoreMigrationProgressComponent'

const StepAnalysisDone = () => {
  const dispatch = useDispatch()
  const sessionId = useSelector(getCurrentSessionId)
  const entities = useSelector(getProgressEntities) || []

  useEffect(() => {
    if (sessionId) {
      dispatch(fetchMigrationAnalyzeStatus(sessionId))
    }
  }, [sessionId])

  return (
    <CoreMigrationProgressComponent
      finished
      entities={entities}
      onOk={() => dispatch(startReviewExceptions(sessionId))}
    />
  )
}

export default StepAnalysisDone
