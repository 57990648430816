import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Utils } from '@pbt/pbt-ui-components'

import { updateAppointmentStatus } from '~/store/actions/timetable'
import { getEventType } from '~/store/reducers/constants'
import { getSchedulerBoardingSchedules } from '~/store/reducers/scheduler'
import { getTimetableEventsMap } from '~/store/reducers/timetable'
import { ScheduleSlot, TimetableEvent } from '~/types'

import { getTodayAppointmentsByType } from '../../../timetableUtils'
import BoardingCheckIn from './BoardingCheckIn'

const BoardingCheckOutToday = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const schedules = useSelector(getSchedulerBoardingSchedules)
  const appointmentsMap = useSelector(getTimetableEventsMap)
  const EventType = useSelector(getEventType)

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const AppointmentStates = AppointmentEvent.states || []
  const DischargedStatus =
    Utils.findConstantByName('Discharged', AppointmentStates) || {}
  const ReadyToGoStatusId = Utils.findConstantIdByName(
    'Ready-to-go',
    AppointmentStates,
  )

  const appointments = getTodayAppointmentsByType(
    schedules,
    appointmentsMap,
    [ReadyToGoStatusId],
    (slot: ScheduleSlot) => slot.interval.to,
  )

  const checkOut = (appointment: TimetableEvent) => {
    const newAppointment = {
      ...appointment,
      state: {
        ...appointment.state,
        id: DischargedStatus.id,
        name: DischargedStatus.name,
      },
    }

    dispatch(updateAppointmentStatus(newAppointment))
  }

  return (
    <BoardingCheckIn
      appointments={appointments}
      buttonLabel={t('Common:DISCHARGE')}
      onClick={checkOut}
    />
  )
}

export default BoardingCheckOutToday
