import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Input } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Animal,
  Defaults,
  DosageType,
  DrugDosageTypeConstant,
  PuiSelect,
  PuiTextField,
  SquareCalcTypeDosageUnits,
  Text,
  UnitUtils,
  Utils,
  WeightCalcTypeDosageUnits,
  WeightCalcTypeToWeightUnitMap,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import { getUnitsState } from '~/store/duck/settings'
import {
  getDrugDosageType,
  getSpecies,
  getWeightUnits,
} from '~/store/reducers/constants'
import { getSquareFromWeight, handleNumberInput } from '~/utils'
import { useGetBusinessEnabledUnits } from '~/utils/measurementUnitsUtils'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      borderRadius: 4,
      backgroundColor: theme.colors.dosageCalculator,
      boxShadow: theme.constants.dosageCalculatorShadow,
    },
    signContainer: {
      width: 16,
      height: 16,
      borderRadius: 8,
      backgroundColor: theme.colors.signIconBackground,
      textAlign: 'center',
      margin: theme.spacing(1.5, 2.5, 0, 2.5),
      alignSelf: 'center',
    },
    signContainerSmallSpace: {
      margin: theme.spacing(1.5, 1, 0, 1),
    },
    sign: {
      lineHeight: '1.6rem',
    },
    calculated: {
      alignSelf: 'center',
      whiteSpace: 'nowrap',
    },
    calculatedPlaceholder: {
      color: '#C6C0C0',
    },
    inputLabel: {
      width: '100%',
    },
    dosageInput: {
      width: 68,
    },
    dosageUnitInput: {
      width: 100,
      marginLeft: theme.spacing(1),
    },
    weightInput: {
      width: 68,
    },
    weightUnitsInput: {
      marginLeft: theme.spacing(1),
    },
    square: {
      whiteSpace: 'nowrap',
    },
    squareText: {
      fontSize: '1.6rem',
    },
    brackets: {
      fontSize: '2.2rem',
    },
    bsaLink: {
      fontSize: '0.8rem',
      margin: 0,
      marginBottom: -12,
      textAlign: 'center',
      display: 'block',
      color: theme.colors.markerHighlighted,
    },
  }),
  { name: 'DosageCalculator' },
)

export interface DosageCalculatorProps {
  className?: string
  dosage?: number
  speciesId?: string
  suggestion?: string
  weight?: number
}

const DosageCalculator = ({
  className,
  dosage: initialDosage,
  weight: initialWeight,
  suggestion,
  speciesId,
}: DosageCalculatorProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Dialogs'])
  const Species = useSelector(getSpecies)
  const WeightUnits = useSelector(getWeightUnits)
  const DrugDosageType: DrugDosageTypeConstant[] =
    useSelector(getDrugDosageType)
  const unitsState = useSelector(getUnitsState)

  const WeightItems = useGetBusinessEnabledUnits(WeightUnits)

  const initialDosageUnit = Utils.findConstantIdByName(
    DosageType.MG_KG,
    DrugDosageType,
  )

  const [dosage, setDosage] = useState<number | ''>(initialDosage || '')
  const [dosageUnit, setDosageUnit] = useState(initialDosageUnit)
  const [weight, setWeight] = useState(
    UnitUtils.convertUnits(UnitTypes.WEIGHT, initialWeight, unitsState),
  )
  const [convertedWeight, setConvertedWeight] = useState<number>()
  const [weightUnits, setWeightUnits] = useState(unitsState[UnitTypes.WEIGHT])
  const [calculatedDose, setCalculatedDose] = useState<number | ''>('')

  const speciesName = Utils.getConstantName(speciesId, Species)
  const isCatOrDog = speciesName === Animal.CAT || speciesName === Animal.DOG

  const ApplicableDosageUnits = DrugDosageType.filter(
    ({ name }) =>
      WeightCalcTypeDosageUnits.has(name) ||
      (SquareCalcTypeDosageUnits.has(name) && isCatOrDog),
  )

  const getDosageWeightUnit = () => {
    const name = Utils.getConstantName(dosageUnit, DrugDosageType) as DosageType
    return WeightCalcTypeToWeightUnitMap[name]
  }

  const getDosageDrugUnit = () => {
    const name = Utils.getConstantName(dosageUnit, DrugDosageType)

    return name.indexOf('/') >= 0 ? name.split('/')[0] : undefined
  }

  const handleWeightUnitChange = (newUnit: string) => {
    if (weight) {
      if (newUnit === WeightTypes.LBS) {
        setWeight(Utils.round(UnitUtils.kgToLbs(weight), 4))
      } else {
        setWeight(Utils.round(UnitUtils.lbsToKg(weight), 4))
      }
    }
    setWeightUnits(newUnit)
  }

  const weightInKg =
    weightUnits === WeightTypes.LBS ? UnitUtils.lbsToKg(weight) : weight
  const isSquareUnit = SquareCalcTypeDosageUnits.has(
    Utils.getConstantName(dosageUnit, DrugDosageType),
  )
  const square = isSquareUnit
    ? getSquareFromWeight(weightInKg, speciesName)
    : undefined

  const drugUnitName = getDosageDrugUnit()

  useEffect(() => {
    if (dosage !== undefined && weight !== undefined) {
      const dosageWeightUnit = getDosageWeightUnit()
      const dosageNumber = dosage as number
      const squareNumber = square as number
      const weightNumber = weight as number

      if (isSquareUnit) {
        setCalculatedDose(dosageNumber * squareNumber)
      } else {
        // eslint-disable-next-line no-lonely-if
        if (
          dosageWeightUnit === WeightTypes.KG &&
          weightUnits === WeightTypes.LBS
        ) {
          const kgs = UnitUtils.lbsToKg(weightNumber) as number

          setCalculatedDose(Utils.round(dosageNumber * kgs, 4) as number)
          setConvertedWeight(
            Utils.round(UnitUtils.lbsToKg(weight), 4) as number,
          )
        } else if (
          dosageWeightUnit === WeightTypes.LBS &&
          weightUnits === WeightTypes.KG
        ) {
          const lbs = UnitUtils.kgToLbs(weight) as number

          setConvertedWeight(Utils.round(lbs, 4) as number)
          setCalculatedDose(Utils.round(dosageNumber * lbs, 4) as number)
        } else {
          setCalculatedDose(Utils.round(dosageNumber * weight, 4) as number)
          setConvertedWeight(undefined)
        }
      }
    }
  }, [dosage, weight, dosageUnit, weightUnits])

  const hasConvertedWeight = (convertedWeight || 0) > 0

  return (
    <Grid
      container
      className={classNames(classes.container, className)}
      px={2}
      py={1}
    >
      <Text strong>
        {t('Dialogs:PRESCRIPTION_DIALOG.DOSAGE_CALCULATOR.TITLE')}
      </Text>
      <Grid container item alignItems="flex-end" wrap="nowrap">
        <PuiTextField
          shrink
          InputLabelProps={{
            className: classes.inputLabel,
          }}
          className={classes.dosageInput}
          inputProps={{ maxLength: 10 }}
          label={t(
            'Dialogs:PRESCRIPTION_DIALOG.DOSAGE_CALCULATOR.DOSAGE_LABEL',
          )}
          margin="none"
          value={dosage}
          onChange={handleNumberInput(setDosage, 10, 10, true)}
        />
        <PuiSelect
          className={classes.dosageUnitInput}
          input={<Input id="dosage-unit-select" />}
          items={ApplicableDosageUnits}
          renderEmpty={false}
          value={dosageUnit}
          onChange={Utils.handleFormSelectInput(setDosageUnit)}
        />
        <div
          className={classNames(
            classes.signContainer,
            (isSquareUnit || convertedWeight) &&
              classes.signContainerSmallSpace,
          )}
        >
          <Text className={classes.sign}>&#215;</Text>
        </div>
        {(isSquareUnit || hasConvertedWeight) && (
          <Text className={classes.brackets} mr={1}>
            (
          </Text>
        )}
        <PuiTextField
          shrink
          InputLabelProps={{
            className: classes.inputLabel,
          }}
          className={classes.weightInput}
          inputProps={{ maxLength: 10 }}
          label={t('Common:WEIGHT')}
          margin="none"
          value={weight}
          onChange={handleNumberInput(setWeight, 10, 10, true)}
        />
        <PuiSelect
          className={classes.weightUnitsInput}
          input={<Input id="weight-unit-select" />}
          items={WeightItems}
          keyProp="name"
          renderEmpty={false}
          value={weightUnits}
          onChange={Utils.handleFormSelectInput(handleWeightUnitChange)}
        />
        {isSquareUnit && (
          <Text className={classes.square} ml={1} variant="body2">
            {t('Common:IS')}&nbsp;
            <span className={classes.squareText}>
              {(square || 0).toFixed(2)}
            </span>
            m<sup>2</sup>
            <a
              className={classes.bsaLink}
              href={Defaults.BSA_ARTICLE_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              BSA
            </a>
          </Text>
        )}
        {convertedWeight && (
          <Text className={classes.square} ml={1} variant="body2">
            {t('Common:IS')}&nbsp;
            <span className={classes.squareText}>
              {convertedWeight.toFixed(2)}
            </span>
            {weightUnits === WeightTypes.LBS ? WeightTypes.KG : WeightTypes.LBS}
          </Text>
        )}
        {(isSquareUnit || hasConvertedWeight) && (
          <Text className={classes.brackets} ml={1}>
            )
          </Text>
        )}
        <div
          className={classNames(
            classes.signContainer,
            isSquareUnit && classes.signContainerSmallSpace,
          )}
        >
          <Text className={classes.sign}>&#61;</Text>
        </div>
        {calculatedDose ? (
          <Text strong className={classes.calculated} mt={1.5}>
            {`${calculatedDose.toFixed(2)} ${drugUnitName}`}
          </Text>
        ) : (
          <Text
            className={classNames(
              classes.calculated,
              classes.calculatedPlaceholder,
            )}
            mt={1.5}
          >
            {t('Dialogs:PRESCRIPTION_DIALOG.DOSAGE_CALCULATOR.CALCULATED_DOSE')}
          </Text>
        )}
      </Grid>
      <Grid item mt={2}>
        <Text variant="body2">{suggestion}&nbsp;</Text>
      </Grid>
    </Grid>
  )
}

export default DosageCalculator
