import i18n from '~/locales/i18n'

import { ColorVariant } from './colors'

export enum PrescriptionType {
  CHEWY = 'CHEWY',
  IN_HOUSE = 'IN_HOUSE',
  SCRIPT_OUT = 'SCRIPT_OUT',
}

export enum AdministrationCourseType {
  CUSTOM = 'CUSTOM',
  UNTIL_GONE = 'UNTIL_GONE',
  AS_NEEDED = 'AS_NEEDED',
}

export const AdministrationCourseTypeStr: Record<
  AdministrationCourseType,
  string
> = {
  CUSTOM: i18n.t('Common:CUSTOM'),
  UNTIL_GONE: i18n.t('Constants:PRESCRIPTION.UNTIL_GONE_PRESCRIPTION'),
  AS_NEEDED: i18n.t('Constants:PRESCRIPTION.AS_NEEDED_PRESCRIPTION'),
}

export const MESSAGE_CAN_NOT_REMOVE_PARENT_PRESCRIPTION = i18n.t(
  'Constants:PRESCRIPTION.MESSAGE_CAN_NOT_REMOVE_PARENT_PRESCRIPTION',
)

export enum PrescriptionStates {
  APPROVED = 'Approved',
  CANCELED = 'Canceled',
  DECLINED = 'Declined',
  DRAFT = 'Draft',
  ORDERED = 'Ordered',
  PRESCRIBED = 'Prescribed',
  SUBMITTED = 'Submitted',
}

export const PrescriptionStatesColor = {
  [PrescriptionStates.APPROVED]: ColorVariant.NORMAL,
  [PrescriptionStates.CANCELED]: ColorVariant.ERROR,
  [PrescriptionStates.DECLINED]: ColorVariant.DISABLED,
  [PrescriptionStates.DRAFT]: ColorVariant.IMPORTANT,
  [PrescriptionStates.ORDERED]: ColorVariant.NORMAL,
  [PrescriptionStates.PRESCRIBED]: ColorVariant.NORMAL,
  [PrescriptionStates.SUBMITTED]: ColorVariant.NORMAL,
}
