import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from '@mui/material'
import { Defaults, moment, Text } from '@pbt/pbt-ui-components'

import { getPracticeCloningItems } from '~/store/reducers/constants'

import { findDependencies, getNames } from './practiceCloningUtils'

interface PracticeExportSessionLabelProps {
  session: {
    businessName: string
    creationDate: string
    items: any[]
    zipFileUrl: string
  }
}

const PracticeExportSessionLabel = ({
  session,
}: PracticeExportSessionLabelProps) => {
  const { t } = useTranslation('Common')

  const PracticeCloningItems = useSelector(getPracticeCloningItems)

  const { businessName, zipFileUrl, creationDate, items = [] } = session || {}

  const dependencies = findDependencies(items, PracticeCloningItems)

  const label = [
    moment(creationDate).format(Defaults.DATE_FORMAT),
    moment(creationDate).format(Defaults.HOURS_FORMAT),
    businessName,
    getNames(dependencies),
  ]
    .filter(Boolean)
    .join(' | ')

  const handleDownload = () => {
    window.open(zipFileUrl)
  }

  return (
    <Text variant="body2">
      {label} (<Link onClick={handleDownload}>{t('Common:DOWNLOAD')}</Link>)
    </Text>
  )
}

export default PracticeExportSessionLabel
