import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  DateFormat,
  HtmlNotesPreview,
  LinkButton,
  moment,
  Nil,
  RoleName,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'
import { ChevronDown, ChevronUp } from '@pbt/pbt-ui-components/src/icons'

import Link from '~/components/common/link/Link'
import Conferencing from '~/components/dashboard/soap/appointment/Conferencing'
import FeatureToggle from '~/constants/featureToggle'
import { useGetIsZoomEnabled } from '~/store/hooks/conferencing'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import {
  getFeatureToggle,
  getResponsibilities,
} from '~/store/reducers/constants'
import { AppointmentTimelineItem, TimetableEvent } from '~/types'
import { addOriginalBusinessId } from '~/utils'
import {
  getLogIdFromTimelineAppoitmentEntry,
  getLogTypeFromTimelineAppoitmentEntry,
} from '~/utils/timeline'

import SingleSoapAppointment from './SingleSoapAppointment'

const useStyles = makeStyles(
  (theme) => ({
    tableCellRoot: {
      borderBottom: 'none',
    },
    firstColumn: {
      fontSize: '1.4rem',
      paddingLeft: theme.spacing(2),
    },
    tableCellHead: {
      fontSize: '1.4rem',
      color: theme.colors.sideText,
    },
    tableCellBody: {
      fontSize: '1.4rem',
      color: theme.colors.secondaryText,
    },
    tableRow: {
      height: 48,
      cursor: 'pointer',
    },
    tableRowDense: {
      height: 20,
    },
    striped: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    soapLink: {
      cursor: 'pointer',
      fontSize: '1.6rem',
      textDecoration: 'underline',
    },
    chevronIcon: {
      verticalAlign: 'middle',
    },
    expandContainer: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    titleText: {
      whiteSpace: 'pre-wrap',
    },
    reasonForVisit: {
      color: theme.colors.secondaryText,
    },
    zoomDetailsContainer: {
      border: 'none',
      padding: theme.spacing(0, 1),
    },
    meetingDetails: {
      border: 'none',
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'MultipleSoapAppointment' },
)

type OpenInvoiceProps = {
  clientId?: string
  invoiceId?: string
  logId?: string | Nil
  logType?: string | Nil
  patientId?: string
  soapId?: string | Nil
}

interface MultipleSoapAppointmentProps {
  clientId: string
  item: AppointmentTimelineItem
  openInvoice?: (props: OpenInvoiceProps) => void
  patientId: string
  striped?: boolean
}

const MultipleSoapAppointment = ({
  item,
  striped = false,
  clientId,
  patientId,
  openInvoice,
}: MultipleSoapAppointmentProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const isAllowVetAssistantAssignmentToAppointmentsEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.ALLOW_VET_ASSISTANT_ASSIGNMENT_TO_APPOINTMENTS,
    ),
  )
  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )
  const mainStaffRolesList = useMainStaffRoles()

  const isZoomEnabled = useGetIsZoomEnabled()

  const doctorRoleId = Utils.findConstantIdByName(
    RoleName.Veterinarian,
    mainStaffRolesList,
  )
  const techRoleId = Utils.findConstantIdByName(
    RoleName.VetTech,
    mainStaffRolesList,
  )

  const assistantRoleId = Utils.findConstantIdByName(
    RoleName.VetAssistant,
    mainStaffRolesList,
  )

  const responsibilities = useSelector(getResponsibilities)

  const doctorResponsibilityId = Utils.findConstantIdByName(
    ResponsibilityName.Veterinarian,
    responsibilities,
  )
  const techResponsibilityId = Utils.findConstantIdByName(
    ResponsibilityName.VetTech,
    responsibilities,
  )

  const assistantResponsibilityId = Utils.findConstantIdByName(
    ResponsibilityName.VetAssistant,
    responsibilities,
  )

  const { entries, notes, personRoles, personResponsibilities } = item
  const columns = [
    t('Common:TIME_COLUMN'),
    t('Common:DOCTOR'),
    t('Common:VET_TECH'),
    isAllowVetAssistantAssignmentToAppointmentsEnabled
      ? t('Common:VET_ASSISTANT')
      : undefined,
  ]

  const findPersonNameByRoleId = (id: string) => {
    const { firstName, lastName } =
      personRoles.find((personRole) => personRole.roleId === id)?.person || {}
    if (firstName || lastName) {
      return `${firstName} ${lastName}`
    }
    return undefined
  }

  const findPersonNameByResponsibilityId = (id: string) => {
    if (
      !personResponsibilities ||
      personResponsibilities.length === 0 ||
      !isCvcRolesEnabled
    )
      return undefined
    const { firstName, lastName } =
      personResponsibilities.find(
        (personResponsibility) => personResponsibility.responsibilityId === id,
      )?.person || {}
    if (firstName || lastName) {
      return `${firstName} ${lastName}`
    }
    return undefined
  }

  const appointment: TimetableEvent = {
    ...(item.entries?.[0] || {}),
    id: item.id,
    client: clientId,
    patient: patientId,
    dialIn: item.dialIn,
    meetingLink: item.meetingLink,
    meetingNotes: item.meetingNotes,
  }

  const [expandedRows, setExpandedRows] = useState<string[]>([])

  const toggleExpanded = (rowId: string) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(R.without([rowId], expandedRows))
    } else {
      setExpandedRows(expandedRows.concat(rowId))
    }
  }

  const showZoomDetails = isZoomEnabled && item.meetingLink

  return (
    <>
      <Grid
        container
        className={classes.reasonForVisit}
        direction="column"
        mb={2}
        px={2}
      >
        {notes && (
          <>
            <Text strong className={classes.titleText}>
              {t('Common:REASON_FOR_VISIT')}
            </Text>
            <HtmlNotesPreview notes={notes} />
          </>
        )}
        {showZoomDetails && (
          <Conferencing
            deleteDisabled
            editDisabled
            appointment={appointment}
            classes={{
              zoomLinkInnerContainer: classes.zoomDetailsContainer,
              meetingDetails: classes.meetingDetails,
            }}
            expandZoomDetailsLabel={t(
              'Common:ZOOM_CONFERENCING_DETAILS',
            ).toLowerCase()}
            title={t('Common:ZOOM_CONFERENCING_DETAILS')}
          />
        )}
      </Grid>
      <Table padding="none">
        <TableHead>
          <TableRow
            className={classNames(classes.tableRow, classes.tableRowDense)}
          >
            {columns.map((title, index) => (
              <TableCell
                classes={{
                  head: classNames(
                    index === 0 && classes.firstColumn,
                    classes.tableCellHead,
                  ),
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {title}
              </TableCell>
            ))}
            <TableCell className={classes.tableCellHead} colSpan={3} />
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry) => {
            const {
              date,
              soapId,
              assignedVet,
              assignedVetTech,
              assignedVetAssistant,
              findingLogs,
              medicalNotes,
              dischargeNotes,
              products,
            } = entry
            const momentDate = moment(date)
            const isDifferentDay = !momentDate.isSame(moment(), 'day')
            const formattedDate = momentDate.format(
              DateFormat.FULL_TIME_WITH_MERIDIAN,
            )
            const isRowExpanded = soapId ? expandedRows.includes(soapId) : false
            const hasFindingLogs = findingLogs && findingLogs?.length > 0
            const hasProducts = products && products?.length > 0
            const hasContentToExpand =
              hasFindingLogs || medicalNotes || dischargeNotes || hasProducts
            const logId = getLogIdFromTimelineAppoitmentEntry(
              entry,
            ) as unknown as string | Nil
            const logType = getLogTypeFromTimelineAppoitmentEntry(
              entry,
            ) as unknown as string | Nil

            const doctorName =
              Utils.getPersonString(assignedVet) ||
              findPersonNameByResponsibilityId(doctorResponsibilityId) ||
              findPersonNameByRoleId(doctorRoleId)
            const vetTechName =
              Utils.getPersonString(assignedVetTech) ||
              findPersonNameByResponsibilityId(techResponsibilityId) ||
              findPersonNameByRoleId(techRoleId)
            const vetAssistantName =
              Utils.getPersonString(assignedVetAssistant) ||
              findPersonNameByResponsibilityId(assistantResponsibilityId) ||
              findPersonNameByRoleId(assistantRoleId)
            return (
              <React.Fragment key={date}>
                <TableRow
                  className={classNames(classes.tableRow, {
                    [classes.striped]: striped,
                  })}
                  onClick={() => soapId && toggleExpanded(soapId)}
                >
                  <TableCell
                    classes={{
                      root: classNames(
                        classes.tableCellRoot,
                        classes.firstColumn,
                      ),
                      body: classes.tableCellBody,
                    }}
                  >
                    {isDifferentDay && (
                      <Text strong variant="subheading5">
                        {momentDate.format(DateFormat.DATE_WITHOUT_YEAR)}
                      </Text>
                    )}
                    {formattedDate}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot,
                      body: classes.tableCellBody,
                    }}
                  >
                    {doctorName}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot,
                      body: classes.tableCellBody,
                    }}
                  >
                    {vetTechName}
                  </TableCell>
                  {isAllowVetAssistantAssignmentToAppointmentsEnabled && (
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot,
                        body: classes.tableCellBody,
                      }}
                    >
                      {vetAssistantName}
                    </TableCell>
                  )}
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot,
                      body: classes.tableCellBody,
                    }}
                  >
                    {soapId && (
                      <Link
                        className={classNames(
                          classes.soapLink,
                          classes.tableCellBody,
                        )}
                        to={addOriginalBusinessId(
                          `/soap/${soapId}`,
                          isPatientSharingEnabled ? item.businessId : null,
                        )}
                      >
                        {t('Common:VIEW_SOAP')}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot,
                      body: classes.tableCellBody,
                    }}
                  >
                    <LinkButton
                      onClick={() =>
                        openInvoice &&
                        openInvoice({
                          clientId,
                          patientId,
                          invoiceId: item.invoiceId,
                          soapId,
                          logId,
                          logType,
                        })
                      }
                    >
                      {t('Common:VIEW_INVOICE')}
                    </LinkButton>
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot,
                      body: classes.tableCellBody,
                    }}
                  >
                    {hasContentToExpand ? (
                      isRowExpanded ? (
                        <ChevronUp className={classes.chevronIcon} />
                      ) : (
                        <ChevronDown className={classes.chevronIcon} />
                      )
                    ) : null}
                  </TableCell>
                </TableRow>
                {hasContentToExpand ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Collapse in={isRowExpanded}>
                        <SingleSoapAppointment
                          appointment={entry}
                          className={classes.expandContainer}
                          clientId={clientId}
                          item={R.omit(['notes'], item)}
                          patientId={patientId}
                          showPersons={false}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </React.Fragment>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default MultipleSoapAppointment
