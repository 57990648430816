import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { Order } from '~/types'

export const useHandleDelete = (
  order: Order,
  deleteAction: Function | undefined,
  deleteCallback: Function | undefined,
) => {
  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.SOAP_RAIL_ORDER,
  })

  const { t } = useTranslation('Common')

  const handleDelete = useCallback(() => {
    openConfirmAlert({
      message: t('Common:YOU_WILL_REMOVE_SOMETHING', {
        something: order.name,
      }),
      onConfirm: (proceed: boolean) => {
        if (proceed) {
          deleteAction?.()
          deleteCallback?.()
        }
      },
    })
  }, [order.name, order.modificationDate])

  return deleteAction || deleteCallback ? handleDelete : undefined
}
