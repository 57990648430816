import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { Defaults, Nil } from '@pbt/pbt-ui-components'

import {
  CreatedConversationFlow,
  CreatedConversationsInfoDialogProps,
} from '~/components/dashboard/communications/common/CreatedConversationsInfoDialog'
import { INITIAL_COMMUNICATION_FILTERS } from '~/constants/communications'
import DialogNames from '~/constants/DialogNames'
import { BaseRoute } from '~/constants/routes'
import { TableFilter } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import { RootState } from '..'
import {
  fetchClientConversationsList,
  fetchConversationsList,
  fetchMoreItemsForClientConversationsList,
  fetchMoreItemsForConversationsList,
  setConversationsListFilters,
} from '../actions/conversations'
import { getConstantsIsLoading } from '../reducers/constants'
import {
  getAreConversationsCreated,
  getConversationsError,
  getConversationsListFilters,
} from '../reducers/conversations'

export function useConversationList({
  clientId,
  patientId,
}: {
  clientId?: string | Nil
  patientId?: string | Nil
}) {
  const dispatch = useDispatch()

  const filters = useSelector(getConversationsListFilters)
  const isConstantsLoading = useSelector(getConstantsIsLoading)

  const applyFilter = (filterName: string, filterValue: TableFilter) => {
    dispatch(
      setConversationsListFilters(
        filterValue.value
          ? { ...filters, [filterName]: filterValue }
          : R.omit([filterName], filters),
      ),
    )
  }

  const clearFilters = () => {
    dispatch(setConversationsListFilters(INITIAL_COMMUNICATION_FILTERS))
  }

  useEffect(() => {
    dispatch(
      setConversationsListFilters({
        ...INITIAL_COMMUNICATION_FILTERS,
        ...filters,
      }),
    )
  }, [])

  useEffectExceptOnMount(() => {
    const action = clientId
      ? patientId
        ? // TODO: add endpoint for fetching client/patient conversations
          fetchClientConversationsList(
            clientId,
            0,
            Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
          )
        : fetchClientConversationsList(
            clientId,
            0,
            Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
          )
      : fetchConversationsList(0, Defaults.CONVERSATIONS_BATCH_LOAD_COUNT)

    dispatch(action)
  }, [filters, clientId, isConstantsLoading])

  const loadMoreItems = (startIndex: number) => {
    const action = clientId
      ? fetchMoreItemsForClientConversationsList(
          clientId,
          startIndex,
          Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
        )
      : fetchMoreItemsForConversationsList(
          startIndex,
          Defaults.CONVERSATIONS_BATCH_LOAD_COUNT,
        )
    dispatch(action)
  }

  return { loadMoreItems, clearFilters, applyFilter, filters }
}

export function useCreatedConversationsInfo({
  getIsConversationCreating,
  onConversationCreationSuccess,
  onConversationCreationFailure,
  onViewConversation = R.F,
  displayDefaultSuccessResult = true,
  createdInfoDialogProps = {},
}: {
  createdInfoDialogProps?: Partial<CreatedConversationsInfoDialogProps>
  displayDefaultSuccessResult?: boolean
  getIsConversationCreating: (state: RootState) => boolean
  onConversationCreationFailure?: (error: string) => void
  onConversationCreationSuccess?: () => void
  onViewConversation?: (conversationId: string) => void
}) {
  const navigate = useNavigate()
  const areConversationsCreated = useSelector(getAreConversationsCreated)
  const latestConversationError = useSelector(getConversationsError)
  const [openCreatedConversationsInfoDialog] = useDialog(
    DialogNames.CREATED_CONVERSATIONS_INFO_DIALOG,
  )

  const handleViewConversation = (conversationId: string) => {
    navigate(`${BaseRoute.COMMUNICATIONS}/${conversationId}`)
    onViewConversation(conversationId)
  }

  const conversationCreationSuccess = () => {
    if (displayDefaultSuccessResult) {
      openCreatedConversationsInfoDialog({
        ...createdInfoDialogProps,
        flow: CreatedConversationFlow.PLAIN_MESSAGE_SENT,
        handleViewConversation,
      })
    }

    if (onConversationCreationSuccess) {
      onConversationCreationSuccess()
    }
  }

  const conversationCreationFail = () => {
    if (onConversationCreationFailure) {
      onConversationCreationFailure(latestConversationError as string)
    }
  }

  const onConversationCreated = () => {
    if (areConversationsCreated) {
      conversationCreationSuccess()
    } else {
      conversationCreationFail()
    }
  }

  return useCloseAfterCreation(onConversationCreated, getIsConversationCreating)
}
