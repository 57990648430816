import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'

import { fetchBusinessSoapWidgets } from '~/store/actions/businesses'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { fetchSoapActiveTemplate } from '~/store/reducers/soapV2'

import SoapCustomizationTable from './SoapCustomizationTable'

const SoapCustomizationPage = () => {
  const dispatch = useDispatch()

  const businessId = useSelector(getCurrentBusinessId)

  useEffect(() => {
    if (businessId) {
      dispatch(fetchSoapActiveTemplate({ businessId }))
      dispatch(fetchBusinessSoapWidgets(businessId))
    }
  }, [businessId])

  return (
    <Box display="flex" flex={1} id="soap-customization-page">
      <SoapCustomizationTable />
    </Box>
  )
}

export default SoapCustomizationPage
