import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CKEditorError, EditorType } from '@pbt/pbt-ckeditor-ui'
import { AlertIconType, Utils } from '@pbt/pbt-ui-components'

import { fetchSoap } from '~/api/soap'
import DialogNames from '~/constants/DialogNames'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import { EditorDocumentType } from '~/types'
import { hasSomeErrorMessage } from '~/utils/errors'
import useDialog from '~/utils/useDialog'

import { OnInitializeChannelId } from './useChannelId'

const CRITICAL_ERRORS = [
  // Error thrown that can make a user lost data during collaboration session
  'model-document-history-addoperation-incorrect-version',
  "TypeError: Cannot read properties of undefined (reading 'decompress')",
  'An error happened during the editor destroying.',
]

const MUST_RESTORE_ERRORS = [
  // Error thrown when some data has been corrupted by some random reason
  'cloud-services-server-error: The document cannot be accessed in the current state. Some operations could not be saved properly.',
]

const INITIALIZATION_ERRORS = [
  'realtimecollaborationclient-init-connection-failed',
]

// Error thrown when the SOAP has been finalized and another user is trying to edit
const COLLABORATIVE_SESSION_DOES_NOT_EXIST_REGEX =
  /cloud-services-server-error: Document[\s\S*]*.does not exist.[\s\S*]*./

type SoapDataError = {
  finalized: boolean
  finalizedBy: string
}

type CKEditorErrorReason = {
  code: string
  message: string
  name: string
  traceId: string
}

export type OnRestoreCollaborationSession = (
  editorId: string,
  noteType: EditorDocumentType,
  bundleType: EditorType,
) => void

export type CKEditorErrorDetails = {
  phase: 'runtime' | 'initialization'
  willEditorRestart: boolean
}

interface CKEditorUnhandledRejection extends PromiseRejectionEvent {
  readonly reason: CKEditorErrorReason
}

interface UseOnEditorError {
  bundleType: EditorType
  editorId: string
  noteType: EditorDocumentType
  onInitializeChannelId?: OnInitializeChannelId
  onRestoreCollaborationSession?: OnRestoreCollaborationSession
}

const handleCriticalError = (errorMessage: string) => {
  if (hasSomeErrorMessage(CRITICAL_ERRORS, errorMessage)) {
    throw new Error(errorMessage)
  }
}

export const useOnEditorError = ({
  editorId,
  noteType,
  bundleType,
  onInitializeChannelId,
  onRestoreCollaborationSession,
}: UseOnEditorError) => {
  const { t } = useTranslation(['Dialogs'])

  const soapId = useSelector(getSoapId)
  const soapBusinessId = useSelector(getSoapBusinessId)
  const businessId = useSelector(getCurrentBusinessId)

  const [isFetchingSoapOnError, setIsFetchingSoapOnError] = useState(false)
  const [soapDataError, setSoapDataError] = useState<SoapDataError>()
  const [error, setError] = useState<CKEditorError | null>(null)
  const [unhandledErrorMessage, setUnhandledErrorMessage] = useState<
    string | null
  >(null)

  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])
  const [openSoapHasBeenLockedWithCollaborationSessionDialog] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    handleReload,
  )

  const onError = (err: CKEditorError) => {
    setError(err)
  }

  // If we throw an error directly from CKEditor prop onError, it won't get caught properly by our ErrorBoundary
  useEffect(() => {
    if (error) {
      const errorData = error.data as any
      const errorMessage =
        errorData?.originalError?.message || errorData?.message || error.message

      handleCriticalError(errorMessage)

      if (hasSomeErrorMessage(INITIALIZATION_ERRORS, errorMessage)) {
        onInitializeChannelId?.(editorId, noteType, bundleType, true)
        setError(null)
        return
      }

      // We must try to restore collaboration session data when it got corrupted
      if (hasSomeErrorMessage(MUST_RESTORE_ERRORS, errorMessage) && soapId) {
        onRestoreCollaborationSession?.(editorId, noteType, bundleType)
        setError(null)
        return
      }

      // As we're facing many issues regarding data loss and some bugs from CKEditor,
      // we will override the watchdog behavior by using our own Editor ErrorBoundary.
      // This way, we enforce the editor to crash as soon as the editor has any error
      throw new Error(errorMessage)
    } else {
      setError(null)
    }
  }, [
    error,
    soapId,
    noteType,
    bundleType,
    editorId,
    onRestoreCollaborationSession,
    onInitializeChannelId,
  ])

  // There're a few errors that sometimes do not get caught by their watchdog and our ErrorBoundary
  // This is a last fallback to force an editor crash.
  useEffect(() => {
    const handleLastGateForCriticalCKEditorError = (event: ErrorEvent) => {
      handleCriticalError(event.message)
    }

    window.addEventListener('error', handleLastGateForCriticalCKEditorError)

    return () => {
      window.removeEventListener(
        'error',
        handleLastGateForCriticalCKEditorError,
      )
    }
  }, [])

  // There're a few errors that are unhandled, so we need to intercept by listening to  'unhandledrejection' event
  useEffect(() => {
    const handleUnhandledRejection = async (
      ckeditorError: CKEditorUnhandledRejection,
    ) => {
      ckeditorError.preventDefault()

      const errorMessage = ckeditorError.reason?.message

      handleCriticalError(errorMessage)

      if (!COLLABORATIVE_SESSION_DOES_NOT_EXIST_REGEX.test(errorMessage)) {
        throw new Error(errorMessage)
      }

      // We must check SOAP finalized status and be sure to alert user what happened without reloading the whole component
      // if we were using SAGAS, it would remove this component from React tree) and do not inform what happened to the user
      // 1. another user has finalized the SOAP showing user's name
      // 2. whether the session has been reset by BE code
      if (soapId && businessId) {
        try {
          setIsFetchingSoapOnError(true)
          const { result } = await fetchSoap(businessId, soapId, soapBusinessId)
          setIsFetchingSoapOnError(false)
          setSoapDataError({
            finalized: result?.soapData?.soap.finalized,
            finalizedBy: Utils.getPersonString(
              result?.soapData?.soap.finalizedBy,
            ),
          })
        } catch (err) {
          setIsFetchingSoapOnError(false)
          throw new Error((err as any).message)
        }
      }

      setUnhandledErrorMessage(ckeditorError.reason.message)
    }

    window.addEventListener('unhandledrejection', handleUnhandledRejection)

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection)
    }
  }, [soapId, businessId])

  useEffect(() => {
    if (unhandledErrorMessage) {
      openSoapHasBeenLockedWithCollaborationSessionDialog({
        iconType: AlertIconType.WARN,
        message: soapDataError?.finalized
          ? t(
              'Dialogs:SOAP_HAS_BEEN_LOCKED_WITH_COLLABORATION_SESSION.MESSAGE',
              {
                user: soapDataError?.finalizedBy,
              },
            )
          : t(
              'Dialogs:SOAP_HAS_BEEN_LOCKED_WITH_COLLABORATION_SESSION.SESSION_RESET_MESSAGE',
            ),
        onOk: handleReload,
        okButtonText: t('Common:OK'),
      })
    }
  }, [unhandledErrorMessage, soapDataError])

  return { onError, isFetchingSoapOnError }
}
