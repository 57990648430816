export const FETCH_SCHEDULES = 'scheduler/FETCH_SCHEDULES'
export const FETCH_SCHEDULES_FAILURE = 'scheduler/FETCH_SCHEDULES_FAILURE'
export const FETCH_SCHEDULES_SUCCESS = 'scheduler/FETCH_SCHEDULES_SUCCESS'

export const FETCH_BOARDING_SCHEDULES = 'scheduler/FETCH_BOARDING_SCHEDULES'
export const FETCH_BOARDING_SCHEDULES_FAILURE =
  'scheduler/FETCH_BOARDING_SCHEDULES_FAILURE'
export const FETCH_BOARDING_SCHEDULES_SUCCESS =
  'scheduler/FETCH_BOARDING_SCHEDULES_SUCCESS'

export const FETCH_UPCOMING_EVENTS = 'scheduler/FETCH_UPCOMING_EVENTS'
export const FETCH_UPCOMING_EVENTS_FAILURE =
  'scheduler/FETCH_UPCOMING_EVENTS_FAILURE'
export const FETCH_UPCOMING_EVENTS_SUCCESS =
  'scheduler/FETCH_UPCOMING_EVENTS_SUCCESS'
