import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FeatureToggle from '~/constants/featureToggle'
import { fetchIsZoomEnabled, getIsZoomEnabled } from '~/store/duck/conferencing'
import { getCurrentBusinessId } from '~/store/reducers/auth'
import { getFeatureToggle } from '~/store/reducers/constants'

export const useGetIsZoomEnabled = () => {
  const dispatch = useDispatch()

  const isZoomEnabled = useSelector(getIsZoomEnabled)
  const currentBusinessId = useSelector(getCurrentBusinessId)
  const isZoomIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.ZOOM_INTEGRATION),
  )

  useEffect(() => {
    if (currentBusinessId) {
      dispatch(fetchIsZoomEnabled(currentBusinessId))
    }
  }, [currentBusinessId])

  return isZoomIntegrationEnabled && isZoomEnabled
}
