import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  PrimitiveTableRow,
  PrimitiveTableRowProps,
} from '@pbt/pbt-ui-components'

import {
  getIsUpdatingVariation,
  getVariation,
} from '~/store/duck/onHandCatalog'

export interface OnHandCatalogTableRowProps {
  data: PrimitiveTableRowProps['data']
  item: string
}

const OnHandCatalogTableRow = ({
  item: variationId,
  data,
  ...rest
}: OnHandCatalogTableRowProps) => {
  const item = useSelector(getVariation(variationId))
  const isUpdatingVariation = useSelector(getIsUpdatingVariation(variationId))
  const updatedData = isUpdatingVariation ? { ...data, getDisabled: R.T } : data

  return <PrimitiveTableRow data={updatedData} item={item} {...rest} />
}

export default OnHandCatalogTableRow
