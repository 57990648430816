import React from 'react'
import { LanguageUtils, SentenceFormatter, Text } from '@pbt/pbt-ui-components'

import { ProblemEnumLogInfo, ProblemLogEnumReference } from '~/types'

interface IdentifiedProblemDetailsHistoryEnumProps {
  childrenHistoryEnums: ProblemLogEnumReference[]
  childrenHistoryEnumsInfo: Record<string, ProblemEnumLogInfo>
  parentHistoryEnum: ProblemLogEnumReference
  parentHistoryEnumsInfo: Record<string, ProblemEnumLogInfo>
}

const IdentifiedProblemDetailsHistoryEnum = ({
  childrenHistoryEnums,
  childrenHistoryEnumsInfo,
  parentHistoryEnum,
  parentHistoryEnumsInfo,
}: IdentifiedProblemDetailsHistoryEnumProps) => {
  const parentNote = LanguageUtils.getSentence(
    [
      LanguageUtils.getTranslatedFieldName(
        parentHistoryEnumsInfo[parentHistoryEnum.enumId].enumProblem,
      ),
      LanguageUtils.getTranslatedFieldName(
        parentHistoryEnumsInfo[parentHistoryEnum.enumId].enumProblemValuesMap[
          parentHistoryEnum.enumValueId
        ],
      ),
      parentHistoryEnum.notes,
    ],
    SentenceFormatter.LOWERCASE,
    ' | ',
  )
  const thisParentChildren = childrenHistoryEnums.filter(
    (item) => item.parentEnumValueId === parentHistoryEnum.enumValueId,
  )
  const childrenNotes = thisParentChildren.map((item) =>
    LanguageUtils.getSentence(
      [
        LanguageUtils.getTranslatedFieldName(
          childrenHistoryEnumsInfo[item.enumId].enumProblem,
        ),
        LanguageUtils.getTranslatedFieldName(
          childrenHistoryEnumsInfo[item.enumId].enumProblemValuesMap[
            item.enumValueId
          ],
        ),
        item.notes,
      ],
      SentenceFormatter.LOWERCASE,
      ' | ',
    ),
  )
  return (
    <>
      {`${parentNote}:`}
      {childrenNotes.map((note) => (
        <Text key={`${parentNote}-${note}`} variant="body2">
          {`${note};`}
        </Text>
      ))}
    </>
  )
}

export default IdentifiedProblemDetailsHistoryEnum
