import { AnyAction } from 'redux'
import { put, race, take } from 'redux-saga/effects'

export function* waitForSubsequentCall({
  action,
  successType,
  failureType,
  ignoredType, // optional parameter
}: {
  action: AnyAction
  failureType: string
  ignoredType?: string
  successType: string
}) {
  yield put(action)
  const { failure } = yield race({
    success: take(successType),
    failure: take(failureType),
    ignored: ignoredType ? take(ignoredType) : undefined,
  })
  if (failure) {
    throw failure.error
  }
}
