import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import StaticTable from '~/components/common/lists/table/StaticTable'
import DialogNames from '~/constants/DialogNames'
import {
  dismissAlert,
  getIsOpen,
  getLastAlert,
  showAlert,
} from '~/store/duck/uiAlerts'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    tableAlertPaper: {
      width: 746,
      maxWidth: 746,
    },
    table: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'AlertsPool' },
)

const AlertsPool = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT, () =>
    dispatch(dismissAlert()),
  )
  const { t } = useTranslation('Common')

  const isOpened = useSelector(getIsOpen)
  const alert = useSelector(getLastAlert)

  useEffect(() => {
    if (!isOpened && alert) {
      const { message, level, columns, rows } = alert

      const hasTable = Boolean(columns?.length)
      const content = hasTable ? (
        <StaticTable
          classes={{ table: classes.table }}
          columns={columns}
          rows={rows}
        />
      ) : null

      openAlert({
        content,
        message,
        iconType: level,
        okButtonText: hasTable ? t('Common:CLOSE_ACTION') : undefined,
        onOk: hasTable ? closeAlert : undefined,
        classes: {
          paper: classNames({ [classes.tableAlertPaper]: hasTable }),
        },
      })
      dispatch(showAlert())
    }
  }, [isOpened, alert])

  return null
}

export default AlertsPool
