import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  DocumentFile,
  PuiTextField,
  Text,
  useFields,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import useFieldsChanged from '~/utils/useFieldsChanged'

const useStyles = makeStyles(
  () => ({
    nameInput: {
      width: '85%',
    },
  }),
  { name: 'AttachmentDetails' },
)

export interface AttachmentDetailsProps {
  file: DocumentFile['file']
  isImage: boolean
  onUpdateItem: (file: DocumentFile['file']) => void
}

const AttachmentDetails = forwardRef<ValidateHandle, AttachmentDetailsProps>(
  function AttachmentDetails({ file, isImage, onUpdateItem }, ref) {
    const classes = useStyles()
    const { t } = useTranslation('Common')

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'name',
          label: t('Common:ATTACHMENT_NAME'),
          validators: ['required'],
          initialValue: file.name || file.fileName,
        },
        {
          name: 'description',
          label: t('Common:DESCRIPTION'),
          initialValue: '',
        },
      ],
      false,
    )

    const { name, description } = fields

    useEffect(() => {
      reset()
    }, [file])

    useFieldsChanged(() => {
      onUpdateItem({
        extension: file.extension,
        description: description.value,
        name: name.value,
      })
    }, fields)

    useImperativeHandle(ref, () => ({
      validate,
    }))

    return (
      <>
        <Grid container item alignItems="center" mt={1.5}>
          <Grid item xs>
            <PuiTextField
              autoFocus
              className={classNames({
                [classes.nameInput]: isImage,
              })}
              field={name}
              inputProps={{ maxLength: 100 }}
              label={`${name.label}*`}
            />
          </Grid>
        </Grid>
        <Grid item mt={1.5}>
          <InputLabel htmlFor="description-input">
            <Text strong variant="subheading3">
              {description.label}:
            </Text>
          </InputLabel>
          <PuiTextField
            multiline
            field={description}
            id="description-input"
            inputProps={{ maxLength: 1000 }}
            minRows={3}
            variant="outlined"
          />
        </Grid>
      </>
    )
  },
)

export default AttachmentDetails
