import React from 'react'
import { useSelector } from 'react-redux'
import { Constant, Utils } from '@pbt/pbt-ui-components'

import EnumSelect, {
  EnumSelectProps,
} from '~/components/common/inputs/EnumSelect'
import { ImagingOrderStatus } from '~/constants/imaging'
import { getImagingOrderStatuses } from '~/store/reducers/constants'

import { isCompletedImagingStatus } from './imagingProceduresUtils'

const isAppropriateStatus =
  (canCancel: boolean, hasResult: boolean) =>
  ({ name }: Constant) => {
    switch (name) {
      case ImagingOrderStatus.CANCELLED:
        return canCancel
      case ImagingOrderStatus.IN_PROGRESS:
      case ImagingOrderStatus.ORDERED:
      case ImagingOrderStatus.PENDING:
        return !hasResult
      default:
        return true
    }
  }

const filterImagingStatusOptions = (
  ImagingOrderStatuses: Constant[],
  isSoapFinalized: boolean,
  actualStatus: ImagingOrderStatus,
) => {
  const hasResult = isCompletedImagingStatus(actualStatus)
  const canCancel = !isSoapFinalized && !hasResult

  return ImagingOrderStatuses.filter(isAppropriateStatus(canCancel, hasResult))
}

export interface ImagingStatusSelectProps
  extends Omit<EnumSelectProps, 'accent' | 'Constant'> {
  isSoapFinalized: boolean
}

const ImagingStatusSelect = ({
  field,
  isSoapFinalized,
  ...rest
}: ImagingStatusSelectProps) => {
  const ImagingOrderStatuses = useSelector(getImagingOrderStatuses)
  const initialStatusName = Utils.getConstantName(
    field.initialValue,
    ImagingOrderStatuses,
  )
  const options = filterImagingStatusOptions(
    ImagingOrderStatuses,
    isSoapFinalized,
    initialStatusName,
  )

  return <EnumSelect accent Constant={options} field={field} {...rest} />
}

export default ImagingStatusSelect
