export const FETCH_PROBLEM_CATALOG = 'problems/FETCH_PROBLEMS_CATALOG'
export const FETCH_PROBLEM_CATALOG_SUCCESS =
  'problems/FETCH_PROBLEMS_CATALOG_SUCCESS'
export const FETCH_PROBLEM_CATALOG_FAILURE =
  'problems/FETCH_PROBLEMS_CATALOG_FAILURE'

export const FETCH_LOGS = 'problems/FETCH_LOGS'
export const FETCH_LOGS_SUCCESS = 'problems/FETCH_LOGS_SUCCESS '
export const FETCH_LOGS_FAILURE = 'problems/FETCH_LOGS_FAILURE'

export const CREATE_PROBLEM_BODY_SYSTEM_LOG =
  'problems/CREATE_PROBLEM_BODY_SYSTEM_LOG'
export const CREATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS =
  'problems/CREATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS'
export const CREATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE =
  'problems/CREATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE'

export const DELETE_PROBLEM_BODY_SYSTEM_LOG =
  'problems/DELETE_PROBLEM_BODY_SYSTEM_LOG'
export const DELETE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS =
  'problems/DELETE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS'
export const DELETE_PROBLEM_BODY_SYSTEM_LOG_FAILURE =
  'problems/DELETE_PROBLEM_BODY_SYSTEM_LOG_FAILURE'

export const UPDATE_PROBLEM_BODY_SYSTEM_LOG =
  'problems/UPDATE_PROBLEM_BODY_SYSTEM_LOG'
export const UPDATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS =
  'problems/UPDATE_PROBLEM_BODY_SYSTEM_LOG_SUCCESS'
export const UPDATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE =
  'problems/UPDATE_PROBLEM_BODY_SYSTEM_LOG_FAILURE'
export const UPDATE_PROBLEM_BODY_SYSTEM_LOG_VALIDATION_FAILURE =
  'problems/UPDATE_PROBLEM_BODY_SYSTEM_LOG_VALIDATION_FAILURE'

export const CREATE_PROBLEM_LOG = 'problems/CREATE_PROBLEM_LOG'
export const CREATE_PROBLEM_LOG_SUCCESS = 'problems/CREATE_PROBLEM_LOG_SUCCESS'
export const CREATE_PROBLEM_LOG_FAILURE = 'problems/CREATE_PROBLEM_LOG_FAILURE'

export const UPDATE_PROBLEM_LOG = 'problems/UPDATE_PROBLEM_LOG'
export const UPDATE_PROBLEM_LOG_SUCCESS = 'problems/UPDATE_PROBLEM_LOG_SUCCESS'
export const UPDATE_PROBLEM_LOG_FAILURE = 'problems/UPDATE_PROBLEM_LOG_FAILURE'
export const UPDATE_PROBLEM_LOG_VALIDATION_FAILURE =
  'problems/UPDATE_PROBLEM_LOG_VALIDATION_FAILURE'

export const CLEAN_PROBLEMS_VALIDATION_ERROR =
  'problems/CLEAN_PROBLEMS_VALIDATION_ERROR'

export const SEARCH_PROBLEMS = 'problems/SEARCH_PROBLEMS'
export const SEARCH_PROBLEMS_SUCCESS = 'problems/SEARCH_PROBLEMS_SUCCESS'
export const SEARCH_PROBLEMS_FAILURE = 'problems/SEARCH_PROBLEMS_FAILURE'

export const SEARCH_MORE_PROBLEMS = 'problems/SEARCH_MORE_PROBLEMS'
export const SEARCH_MORE_PROBLEMS_SUCCESS =
  'problems/SEARCH_MORE_PROBLEMS_SUCCESS'
export const SEARCH_MORE_PROBLEMS_FAILURE =
  'problems/SEARCH_MORE_PROBLEMS_FAILURE'

export const FETCH_PROBLEM_LOG_HISTORY = 'problems/FETCH_PROBLEM_LOG_HISTORY'
export const FETCH_PROBLEM_LOG_HISTORY_SUCCESS =
  'problems/FETCH_PROBLEM_LOG_HISTORY_SUCCESS'
export const FETCH_PROBLEM_LOG_HISTORY_FAILURE =
  'problems/FETCH_PROBLEM_LOG_HISTORY_FAILURE'

export const FETCH_MORE_PROBLEM_LOG_HISTORY =
  'problems/FETCH_MORE_PROBLEM_LOG_HISTORY'
export const FETCH_MORE_PROBLEM_LOG_HISTORY_SUCCESS =
  'problems/FETCH_MORE_PROBLEM_LOG_HISTORY_SUCCESS'
export const FETCH_MORE_PROBLEM_LOG_HISTORY_FAILURE =
  'problems/FETCH_MORE_PROBLEM_LOG_HISTORY_FAILURE'

export const DISABLE_TOAST_SHOWING = 'problems/DISABLE_TOAST_SHOWING'
export const DISABLE_TOAST_SHOWING_SUCCESS =
  'problems/DISABLE_TOAST_SHOWING_SUCCESS'
export const DISABLE_TOAST_SHOWING_FAILURE =
  'problems/DISABLE_TOAST_SHOWING_FAILURE'

export const CHANGE_STATE_FOR_ALL_BODY_SYSTEMS =
  'problems/CHANGE_STATE_FOR_ALL_BODY_SYSTEMS'
export const CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_SUCCESS =
  'problems/CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_SUCCESS'
export const CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_FAILURE =
  'problems/CHANGE_STATE_FOR_ALL_BODY_SYSTEMS_FAILURE'

export const CREATE_PROBLEM_LOG_ENUM_VALUE =
  'problems/CREATE_PROBLEM_LOG_ENUM_VALUE'
export const CREATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS =
  'problems/CREATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS'
export const CREATE_PROBLEM_LOG_ENUM_VALUE_FAILURE =
  'problems/CREATE_PROBLEM_LOG_ENUM_VALUE_FAILURE'

export const UPDATE_PROBLEM_LOG_ENUM_VALUE =
  'problems/UPDATE_PROBLEM_LOG_ENUM_VALUE'
export const UPDATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS =
  'problems/UPDATE_PROBLEM_LOG_ENUM_VALUE_SUCCESS'
export const UPDATE_PROBLEM_LOG_ENUM_VALUE_FAILURE =
  'problems/UPDATE_PROBLEM_LOG_ENUM_VALUE_FAILURE'

export const DELETE_PROBLEM_LOG_ENUM_VALUE =
  'problems/DELETE_PROBLEM_LOG_ENUM_VALUE'
export const DELETE_PROBLEM_LOG_ENUM_VALUE_SUCCESS =
  'problems/DELETE_PROBLEM_LOG_ENUM_VALUE_SUCCESS'
export const DELETE_PROBLEM_LOG_ENUM_VALUE_FAILURE =
  'problems/DELETE_PROBLEM_LOG_ENUM_VALUE_FAILURE'

export const SET_PROBLEM_TO_OPEN_IN_RAIL =
  'problems/SET_PROBLEM_TO_OPEN_IN_RAIL'
