import { ColorVariant } from './colors'

export enum DiagnosisStatusName {
  SUSPECTED = 'Suspected',
  RESOLVED = 'Resolved',
  RULED_OUT = 'Ruled Out',
  CONFIRMED = 'Confirmed',
  CHRONIC = 'Chronic',
  REMOVED = 'Removed',
}

export const DiagnosisStatusColor = {
  [DiagnosisStatusName.RULED_OUT]: ColorVariant.DISABLED,
  [DiagnosisStatusName.RESOLVED]: ColorVariant.DISABLED,
  [DiagnosisStatusName.SUSPECTED]: ColorVariant.IMPORTANT,
  [DiagnosisStatusName.CONFIRMED]: ColorVariant.NORMAL,
  [DiagnosisStatusName.CHRONIC]: ColorVariant.NORMAL,
  [DiagnosisStatusName.REMOVED]: ColorVariant.DISABLED,
}
