import React from 'react'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { NumberUtils } from '@pbt/pbt-ui-components'

import { Invoice } from '~/types'

import { getInvoiceTotals } from '../../../invoiceUtils'

const useStyles = makeStyles(
  (theme) => ({
    totalCell: {
      fontWeight: 500,
      borderRight: theme.constants.tableBorder,
    },
    expandedCell: {
      borderRight: theme.constants.tableBorderSelected,
    },
  }),
  { name: 'ItemTotalCellCollapsible' },
)

export interface ItemTotalCellCollapsibleProps {
  className?: string
  invoice: Invoice
  isExpanded: boolean
}

const ItemTotalCellCollapsible = ({
  isExpanded,
  className,
  invoice,
}: ItemTotalCellCollapsibleProps) => {
  const classes = useStyles()

  const totals = getInvoiceTotals(invoice)

  return (
    <TableCell
      align="right"
      className={classNames(className, classes.totalCell, {
        [classes.expandedCell]: isExpanded,
      })}
    >
      {NumberUtils.formatMoney(totals.totalWithoutFee)}
    </TableCell>
  )
}

export default ItemTotalCellCollapsible
