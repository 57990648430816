import { all, put, takeLatest } from 'redux-saga/effects'

import * as API from '~/api/practiceCloning'
import { getErrorMessage } from '~/utils/errors'

import requestAPI from '../sagas/utils/requestAPI'
import createDuck from './duck-generators/createDuck'
import practiceCloningBuilder, {
  INITIAL_STATE,
} from './duck-generators/practiceCloning'

export function* createSessionFromFileSaga(duck, { file, businessId }) {
  try {
    const documentToSend = { file: file?.raw }
    const {
      entities: { practiceCloningSession },
      result: createdSessionId,
    } = yield requestAPI(
      API.createImportSessionFromFile,
      businessId,
      documentToSend,
    )
    yield put(duck.actions.updateSessionEntities(practiceCloningSession))
    yield put(duck.actions.createSessionFromFileSuccess(createdSessionId))
  } catch (error) {
    yield put(duck.actions.createSessionFromFileFailure(error))
  }
}

const practiceImportDuck = createDuck({
  name: 'practiceImport',
  duck: practiceCloningBuilder,
  types: {
    CREATE_SESSION_FROM_FILE: 'CREATE_SESSION_FROM_FILE',
    CREATE_SESSION_FROM_FILE_SUCCESS: 'CREATE_SESSION_FROM_FILE_SUCCESS',
    CREATE_SESSION_FROM_FILE_FAILURE: 'CREATE_SESSION_FROM_FILE_FAILURE',
  },
  reducer: (state = INITIAL_STATE, action, duck) => {
    switch (action.type) {
      case duck.types.CREATE_SESSION_FROM_FILE:
        return {
          ...state,
          isLoading: true,
          error: null,
        }
      case duck.types.CREATE_SESSION_FROM_FILE_SUCCESS:
        return {
          ...state,
          activeSessionId: action.sessionId,
          isLoading: false,
        }
      case duck.types.CREATE_SESSION_FROM_FILE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: getErrorMessage(action.error),
        }
      default:
        return state
    }
  },
  actions: (duck) => ({
    createSessionFromFile: (businessId, file) => ({
      type: duck.types.CREATE_SESSION_FROM_FILE,
      businessId,
      file,
    }),
    createSessionFromFileSuccess: (sessionId) => ({
      type: duck.types.CREATE_SESSION_FROM_FILE_SUCCESS,
      sessionId,
    }),
    createSessionFromFileFailure: (error) => ({
      type: duck.types.CREATE_SESSION_FROM_FILE_FAILURE,
      error,
    }),
  }),
  saga: (duck) =>
    function* saga() {
      yield all([
        yield takeLatest(
          duck.types.CREATE_SESSION_FROM_FILE,
          createSessionFromFileSaga,
          duck,
        ),
      ])
    },
  apiEndpoints: (types) => ({
    [types.CREATE_SESSION]: API.createImportSession,
    [types.FETCH_SESSION]: API.fetchImportSession,
    [types.FETCH_ACTIVE_SESSION]: API.fetchActiveImportSession,
  }),
})

export const practiceImportReducer = practiceImportDuck.reducer
export const practiceImportSaga = practiceImportDuck.saga
export const practiceImportSelectors = practiceImportDuck.selectors
export const practiceImportActions = practiceImportDuck.actions
