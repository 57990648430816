import React, { forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Utils } from '@pbt/pbt-ui-components'

import ImagingVendorName from '~/constants/labVendorName'
import {
  fetchImagingVendorConfigs,
  getImagingConfigs,
} from '~/store/duck/imagingVendorConfig'
import { getLabVendors as getImagingVendors } from '~/store/reducers/constants'
import { BasePracticeDetailsSectionProps } from '~/types'

import ImagingVendorIntegration from './ImagingVendorIntegration'
import IdexxImagingForm from './vendors/IdexxImagingForm'

const useStyles = makeStyles(
  (theme) => ({
    separatedSection: {
      margin: theme.spacing(0, -2),
      padding: theme.spacing(0, 2),
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
  }),
  { name: 'ImagingIntegrations' },
)

const ImagingIntegrations = forwardRef<
  HTMLDivElement,
  BasePracticeDetailsSectionProps
>(function ImagingIntegrations({ business }, ref) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const ImagingVendors = useSelector(getImagingVendors)

  const VendorFormsMap: Record<string, React.JSXElementConstructor<any>> = {
    [ImagingVendorName.IDEXX]: IdexxImagingForm,
  }

  const businessId = business.id

  const imagingConfigs = useSelector(getImagingConfigs(businessId))

  useEffect(() => {
    if (businessId) {
      dispatch(fetchImagingVendorConfigs(businessId))
    }
  }, [businessId])

  return (
    <Grid container direction="column" ref={ref}>
      {imagingConfigs.map((config) => {
        const vendorName = Utils.getConstantName(
          config.vendorId,
          ImagingVendors,
        )
        const FormComponent = VendorFormsMap[vendorName]

        return (
          <ImagingVendorIntegration
            FormComponent={FormComponent}
            businessId={businessId}
            classes={{ root: classes.separatedSection }}
            config={config}
            key={config.vendorId}
          />
        )
      })}
    </Grid>
  )
})

export default ImagingIntegrations
