import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Text, TextWithTooltip, UnitsState } from '@pbt/pbt-ui-components'
import { Note } from '@pbt/pbt-ui-components/src/icons'

import { getUnitsState } from '~/store/duck/settings'
import { getConstantMap } from '~/store/reducers/constants'
import { getSoapVitals } from '~/store/reducers/soap'
import { VitalConfigItem } from '~/types'

import { vitalsTableConfig } from '../../soapV2/vitals/config/vitalsTableConfig'
// @ts-ignore
import { getTableData } from './vitalUtils'

const useStyles = makeStyles(
  (theme) => ({
    cell: {
      padding: theme.spacing(1.5, 1),
      borderRight: theme.constants.tableBorder,
      width: 100,
      minWidth: 100,
    },
    leftCell: {
      borderLeft: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
      minWidth: 150,
      width: 150,
      position: 'sticky',
      left: 0,
    },
  }),
  { name: 'SoapVitalsLockedTableRow' },
)

export interface SoapVitalsLockedTableRowProps {
  item: VitalConfigItem
}

const SoapVitalsLockedTableRow = ({ item }: SoapVitalsLockedTableRowProps) => {
  const classes = useStyles()
  const constantMap = useSelector(getConstantMap)
  const unitsState = useSelector(getUnitsState)
  const vitals = useSelector(getSoapVitals)
  const { t } = useTranslation('Common')

  const tableData = getTableData({
    config: vitalsTableConfig,
    vitals,
    unitsState,
    constantMap,
    withUnits: false,
  })

  const units = item.formInput?.units
  const unitLabel = unitsState[item.id as keyof UnitsState] || units
  const label = unitLabel ? ` (${unitLabel})` : ''

  return (
    <>
      <TableCell className={classNames(classes.leftCell, classes.cell)}>
        <Text strong variant="body2">
          {`${item.name}${label}`}
        </Text>
      </TableCell>
      {tableData[item.id].map(({ value = '-', notes = '' }, index: number) => (
        <TableCell
          className={classes.cell}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <TextWithTooltip
            allowWrap
            Icon={Note}
            tooltipText={
              notes && (
                <>
                  <Text strong variant="body2">
                    {t('Common:NOTES')}
                  </Text>
                  {notes}
                </>
              )
            }
            variant="body2"
          >
            {value}
          </TextWithTooltip>
        </TableCell>
      ))}
    </>
  )
}

export default SoapVitalsLockedTableRow
