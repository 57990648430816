import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import {
  DateUtils,
  PermissionArea,
  SignatureView,
  Utils,
} from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getCRUDByArea } from '~/store/reducers/auth'
import { getFeatureToggle, getLicenseTypes } from '~/store/reducers/constants'
import { SignatureData } from '~/types'
import { getDVMLicensesNumbers } from '~/utils'
import { usePrescriptionLoading } from '~/utils/usePrescriptionLoading'

export interface PrescriptionSignatureProps {
  onSignatureEdit: () => void
  outsideSoap: boolean
  signatureData: SignatureData
}

export const PrescriptionSignature = ({
  outsideSoap,
  signatureData,
  onSignatureEdit,
}: PrescriptionSignatureProps) => {
  const permissions = useSelector(getCRUDByArea(PermissionArea.PRESCRIPTION))
  const LicenseTypes = useSelector(getLicenseTypes)

  const isCvcRolesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.CVC_ROLES),
  )

  const { isLoading } = usePrescriptionLoading(outsideSoap)

  return (
    <Grid mt={3} width="fit-content">
      <SignatureView
        disabled={
          (isCvcRolesEnabled && !permissions.sign) ||
          (!isCvcRolesEnabled && !permissions.update) ||
          isLoading
        }
        signatureDate={DateUtils.formatDateWithHours(signatureData.signedDate)}
        signatureString={signatureData.signatureUrl}
        signerLicence={getDVMLicensesNumbers(
          signatureData.signer,
          LicenseTypes,
        )}
        signerName={
          signatureData.signer && Utils.getPersonString(signatureData.signer)
        }
        onEdit={onSignatureEdit}
      />
    </Grid>
  )
}
