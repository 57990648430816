import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import {
  fetchMoreReminders,
  fetchReminders,
  getMultipleRemindersGroups,
  getRemindersGroupsList,
  getTotalCount,
} from '~/store/duck/reminders'

import PatientRemindersList from './PatientRemindersList'

export interface AllRemindersProps {
  patientId: string
}

const AllReminders = ({ patientId }: AllRemindersProps) => {
  const dispatch = useDispatch()
  const groupsList = useSelector(getRemindersGroupsList)
  const totalCount = useSelector(getTotalCount)
  const groups = useSelector(getMultipleRemindersGroups(groupsList))
  const remindersList = R.pipe(R.pluck('reminders'), R.flatten)(groups)

  useEffect(() => {
    if (patientId) {
      dispatch(
        fetchReminders({
          patientId,
        }),
      )
    }
  }, [patientId])

  const loadMoreItems = (from: number, to: number) => {
    dispatch(
      fetchMoreReminders({
        patientId,
        from,
        to,
      }),
    )
  }

  return (
    <PatientRemindersList
      list={remindersList}
      loadMoreItems={loadMoreItems}
      patientId={patientId}
      totalCount={totalCount}
    />
  )
}

export default AllReminders
