import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import { savePerson } from '~/store/actions/registration'
import { createPerson } from '~/store/dto/Person'
import { getRegistrationPerson } from '~/store/reducers/registration'
import useIsAuthenticated from '~/utils/useIsAuthenticated'
import useLogout from '~/utils/useLogout'

import AvatarUploadPage from './AvatarUploadPage'
import ClinicEditComponent from './ClinicEditComponent'
import ClinicLogoUploadComponent from './ClinicLogoUploadComponent'
import ClinicsList from './ClinicsList'
import ClinicsSelectorComponent from './ClinicsSelectorComponent'
import ClinicViewComponent from './ClinicViewComponent'
import FinalQuestionsForActivationComponent from './FinalQuestionsForActivationComponent'
import RegistrationByInvite from './invite/RegistrationByInvite'
import ManagerDetails from './ManagerDetails'
import MembersList from './MembersList'
import ProfileEdit from './ProfileEdit'
import RegistrationWrapper from './RegistrationWrapper'
import TermsAndConditionsComponent from './TermsAndConditionsComponent'

const Registration = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const person = useSelector(getRegistrationPerson)

  const { isAuthenticated } = useIsAuthenticated()
  const logout = useLogout()

  useEffect(() => {
    if (isAuthenticated) {
      logout()
    }
    // it should work only after first open. So, empty dependencies list is expected here.
  }, [])

  useEffect(() => {
    const { email } = parse(location.search)

    if (email && email !== person?.email) {
      dispatch(savePerson(createPerson({ email: email as string })))
    }
  }, [location.search])

  return (
    <Routes>
      {/* root path: /register */}
      <Route
        element={
          <RegistrationWrapper
            noPadding
            component={FinalQuestionsForActivationComponent}
          />
        }
        path="/activation/questions"
      />
      <Route
        element={
          <RegistrationWrapper noPadding component={ClinicsSelectorComponent} />
        }
        path="/clinic/select"
      />
      <Route
        element={<RegistrationWrapper component={ClinicViewComponent} />}
        path="/clinic/confirm"
      />
      <Route
        element={
          <RegistrationWrapper
            noPadding
            component={ClinicLogoUploadComponent}
          />
        }
        path="/clinic/logoUpload"
      />
      <Route
        element={<RegistrationWrapper component={ClinicEditComponent} />}
        path="/clinic/edit"
      />
      <Route
        element={<RegistrationWrapper component={ManagerDetails} />}
        path="/manager"
      />
      <Route
        element={<RegistrationWrapper component={MembersList} />}
        path="/members"
      />
      <Route
        element={<RegistrationWrapper noPadding component={ProfileEdit} />}
        path="/profile"
      />
      <Route
        element={<RegistrationWrapper noPadding component={AvatarUploadPage} />}
        path="/avatarUpload"
      />
      <Route
        element={
          <RegistrationWrapper
            noPadding
            component={TermsAndConditionsComponent}
          />
        }
        path="/termsAndConditions"
      />
      <Route element={<RegistrationByInvite />} path="/:token" />
      <Route
        element={<RegistrationWrapper component={ClinicsList} />}
        path="/"
      />
    </Routes>
  )
}

export default Registration
