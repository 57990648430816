import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Nil, TextUtils } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { PrescriptionType } from '~/constants/prescription'
import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { Prescription, PrescriptionTemplateFilters } from '~/types'
import { getWorkflowType } from '~/utils/prescription'
import { fixPrescriptionTemplate } from '~/utils/prescriptionTemplatesUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

import {
  clearPrescriptionTemplates,
  fetchPrescriptionTemplates,
  getIsFetchingPrescriptionTemplates,
  getTemplates,
} from '../duck/prescriptionTemplates'
import { getFeatureToggle } from '../reducers/constants'
import { getOrderDetails } from '../reducers/orders'
import { getPatient } from '../reducers/patients'

export const useFetchPrescriptionTemplate = ({
  patientId,
  doctorId,
  onTemplateFetched,
  activeWorkflow,
}: {
  activeWorkflow?: PrescriptionWorkflowType
  doctorId?: string | Nil
  onTemplateFetched: (
    template: Prescription | undefined,
    prescription: Prescription,
  ) => void
  patientId: string | Nil
}) => {
  const dispatch = useDispatch()

  const patient = useSelector(getPatient(patientId))
  const templates = useSelector(getTemplates)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  useEffect(
    () => () => {
      // clear templates for the next dialog opening
      dispatch(clearPrescriptionTemplates())
    },
    [],
  )

  const selectTemplate = (prescription: Prescription) => {
    const { isInHouseRx } = getWorkflowType(activeWorkflow)

    const filterTemplates = ({
      prescriptionType,
      globalVariationId,
      globalVariationMappingId,
      variationId,
      doctorId: templateDoctorId,
    }: Prescription) => {
      const matchesWorkflow =
        prescriptionType ===
        (isInHouseRx ? PrescriptionType.IN_HOUSE : PrescriptionType.SCRIPT_OUT)
      const matchesDoctor = templateDoctorId === doctorId
      const matchesProcessingGlobalVariation = isFoodCatalogEnabled
        ? globalVariationMappingId === prescription.globalVariationMapping?.id
        : globalVariationId === prescription.globalVariation?.id

      const matchesVariation = variationId === prescription.variation?.id

      return (
        (!activeWorkflow || matchesWorkflow) &&
        (!doctorId || matchesDoctor) &&
        matchesProcessingGlobalVariation &&
        matchesVariation
      )
    }

    const suitableTemplate = templates.find(filterTemplates)

    onTemplateFetched(
      suitableTemplate
        ? {
            ...suitableTemplate,
            notes: TextUtils.removeDigits(suitableTemplate.notes || ''),
          }
        : undefined,
      prescription,
    )
  }

  const setCallbackAfterTemplatesFetched = useCloseAfterCreation(
    selectTemplate,
    getIsFetchingPrescriptionTemplates,
  )

  const loadTemplates = (prescription: Prescription) => {
    if (
      !prescription[
        isFoodCatalogEnabled ? 'globalInventoryMapping' : 'globalInventory'
      ]?.id
    ) {
      onTemplateFetched(undefined, prescription)
      return
    }

    const filters: PrescriptionTemplateFilters = {
      globalInventoryId: prescription.globalInventory?.id,
      ...(isFoodCatalogEnabled
        ? {
            globalInventoryMappingId: prescription.globalInventoryMapping?.id,
          }
        : {}),
      inventoryId: prescription.inventory?.id,
      speciesId: patient?.species,
    }

    setCallbackAfterTemplatesFetched(prescription)
    dispatch(fetchPrescriptionTemplates(filters))
  }

  return [loadTemplates, selectTemplate]
}

export const usePrescriptionTemplates = ({
  prescription,
  patientId,
  activeWorkflow,
  doctorId,
  outsideSoap,
  onSetPrescription,
  onTemplateFetched,
}: {
  activeWorkflow?: PrescriptionWorkflowType
  doctorId: string | Nil
  onSetPrescription: (prescription: Prescription) => void
  onTemplateFetched: (prescription: Prescription | undefined) => void
  outsideSoap: boolean
  patientId: string
  prescription: Prescription
}) => {
  const templates = useSelector(getTemplates)
  const templatesIsLoading = useSelector(getIsFetchingPrescriptionTemplates)
  const isFoodCatalogEnabled = useSelector(
    getFeatureToggle(FeatureToggle.FOOD_CATALOG),
  )

  const [canReselectTemplate, setCanReselectTemplate] = useState(false)

  const processingGlobalVariationId =
    prescription[
      isFoodCatalogEnabled ? 'globalVariationMapping' : 'globalVariation'
    ]?.id

  const [loadPrescriptionTemplates, reselectPrescriptionTemplate] =
    useFetchPrescriptionTemplate({
      patientId,
      doctorId,
      activeWorkflow,
      onTemplateFetched: (rawTemplate) => {
        onTemplateFetched(rawTemplate)
        setCanReselectTemplate(true)
        if (rawTemplate) {
          const preparedTemplate = fixPrescriptionTemplate(
            rawTemplate,
            outsideSoap,
          )
          onSetPrescription({ ...prescription, ...preparedTemplate })
        }
      },
    })

  const prescriptionDetails = useSelector(getOrderDetails)

  const isEdit = Boolean(prescription?.id || prescriptionDetails?.id)
  const { isOrder } = getWorkflowType(activeWorkflow)

  useEffect(() => {
    if (
      activeWorkflow &&
      !isEdit &&
      !isOrder &&
      !templates?.length &&
      !templatesIsLoading
    ) {
      loadPrescriptionTemplates(prescription)
    }
  }, [activeWorkflow])

  // change template when changing variation or workflow after mount templates fetched
  useEffect(() => {
    if (canReselectTemplate) {
      reselectPrescriptionTemplate(prescription)
    }
  }, [processingGlobalVariationId, prescription.variation?.id, activeWorkflow])
}
