import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, Grid, Input, InputLabel } from '@mui/material'
import {
  Field,
  PuiSelect,
  Utils,
  WeightUnitsConstant,
} from '@pbt/pbt-ui-components'
import { UnitTypes, WeightTypes } from '@pbt/pbt-ui-components/src/localization'

import { changeUnits, getUnitsState } from '~/store/duck/settings'
import { getWeightUnits } from '~/store/reducers/constants'
import { useGetBusinessEnabledUnits } from '~/utils/measurementUnitsUtils'

import NumericInput from './NumericInput'

export interface WeightInputProps {
  disabled: boolean
  label?: string
  unitField?: Field
  weightField: Field
}

const WeightInput = ({
  label,
  disabled,
  unitField,
  weightField,
}: WeightInputProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common'])

  const unitsState = useSelector(getUnitsState)
  const WeightUnits: WeightUnitsConstant[] = useSelector(getWeightUnits)

  const unit = unitField
    ? unitField.value
    : unitsState[UnitTypes.WEIGHT] || WeightTypes.LBS

  const items = useGetBusinessEnabledUnits(WeightUnits)

  const setUnit = (newUnit: WeightTypes) => {
    if (unitField) {
      unitField.setValue(newUnit)
    } else {
      dispatch(changeUnits({ [UnitTypes.WEIGHT]: newUnit }))
    }
  }

  return (
    <Grid container item alignItems="flex-end" columnSpacing={3} wrap="nowrap">
      <Grid item mx={0.5} xs={8}>
        <NumericInput
          allowDecimal
          nullable
          disabled={disabled}
          field={weightField}
          inputProps={{ maxLength: 100 }}
          label={label}
          margin="none"
          min={0}
          placeholder={t('Common:NONE')}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth margin="none">
          <InputLabel htmlFor="weight-units-select" />
          <PuiSelect
            disabled={disabled}
            input={<Input id="weight-units-select" />}
            items={items}
            keyProp={unitField ? undefined : 'name'}
            renderEmpty={false}
            value={unit}
            onChange={Utils.handleFormSelectInput(setUnit)}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default WeightInput
