import React, { useSelector } from 'react-redux'

import { getGender, getSpayedNeuteredStatus } from '~/store/reducers/constants'

import { getFullGenderString } from '../../../inputs/gender/genderUtils'

export type FullGenderCellItem = {
  genderId: string
  spayedNeuteredId?: string
  spayedNeuteredStatusId: string
}

const FullGenderCell = (item: FullGenderCellItem) => {
  const Gender = useSelector(getGender)
  const SpayedNeuteredStatus = useSelector(getSpayedNeuteredStatus)

  const spayedNeuteredId =
    item?.spayedNeuteredId || item?.spayedNeuteredStatusId

  return (
    <>
      {getFullGenderString(
        item?.genderId,
        Gender,
        spayedNeuteredId,
        SpayedNeuteredStatus,
      )}
    </>
  )
}

export default FullGenderCell
