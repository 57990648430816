import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Nil } from '@pbt/pbt-ui-components'

import type { RootState } from '~/store'

import usePrevious from './usePrevious'

const useCloseAfterCreation = (
  callback: ((...args: any[]) => void) | Nil,
  loadingSelector: (state: RootState) => boolean,
  strict = false,
) => {
  const isLoading = useSelector(loadingSelector)

  const prevIsLoading = usePrevious(isLoading)

  const [closeAfterCreation, setCloseAfterCreation] = useState(false)
  const [callbackArguments, setCallbackArguments] = useState<any[]>([])

  useEffect(() => {
    if (closeAfterCreation && !isLoading && (!strict || prevIsLoading)) {
      setCloseAfterCreation(false)
      if (callback) {
        callback(...callbackArguments)
      }
    }
  }, [callbackArguments, closeAfterCreation, isLoading])

  return (...args: any[]) => {
    setCallbackArguments(args)
    setCloseAfterCreation(true)
  }
}

export default useCloseAfterCreation
