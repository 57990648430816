import React, { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BaseSignature } from '@pbt/pbt-ui-components'
import {
  BaseSignatureHandle,
  BaseSignatureProps,
} from '@pbt/pbt-ui-components/src/components/signature/BaseSignature'

import UserSelect, {
  UserSelectFilterScope,
} from '~/components/common/inputs/UserSelect'
import { getMemberIsLoading } from '~/store/reducers/members'
import { getUser } from '~/store/reducers/users'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

export interface DoctorSignatureProps extends BaseSignatureProps {
  disabled?: boolean
  enableSignerSignature?: boolean
}

const DoctorSignature = forwardRef<BaseSignatureHandle, DoctorSignatureProps>(
  function DoctorSignature(
    {
      initialSignerValue,
      initialSignatureUrl,
      disabled,
      enableSignerSignature,
      ...rest
    },
    ref,
  ) {
    const { t } = useTranslation('Common')

    const [currentSignerId, setCurrentSignerId] = useState(initialSignerValue)
    const [currentSignatureUrl, setCurrentSignatureUrl] =
      useState(initialSignatureUrl)

    const isLoading = useSelector(getMemberIsLoading)
    const signer = useSelector(getUser(currentSignerId))

    useEffectExceptOnMount(() => {
      if (enableSignerSignature) {
        setCurrentSignatureUrl(signer?.signatureUrl || undefined)
      }
    }, [signer?.id, enableSignerSignature])

    return (
      <BaseSignature
        initialSignatureUrl={currentSignatureUrl}
        initialSignerValue={initialSignerValue}
        isLoading={isLoading}
        ref={ref}
        type="select"
        {...rest}
      >
        {({ field }) => (
          <UserSelect
            plainInput
            disabled={disabled}
            field={{
              ...field,
              setValue: (value) => {
                field.setValue(value)
                setCurrentSignerId(value)
              },
            }}
            filterScope={UserSelectFilterScope.Doctors}
            label={`${t('Common:ASSIGNED_DOCTOR')}*`}
          />
        )}
      </BaseSignature>
    )
  },
)

export default DoctorSignature
