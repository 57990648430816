import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentBusiness } from '../../../../store/reducers/auth'
import { getPatient } from '../../../../store/reducers/patients'
import { getAppointmentId, getSoapId } from '../../../../store/reducers/soap'
import { getTimetableEvent } from '../../../../store/reducers/timetable'
import { getUser } from '../../../../store/reducers/users'
import { IFrame } from '../../../../types'
import MarketplaceItemIframe from '../../../common/marketplaceIframes/MarkeplaceItemIframe'

export interface ExpandedMarketplaceIFrameProps {
  iFrame: IFrame
}

const ExpandedMarketplaceIFrame = ({
  iFrame,
}: ExpandedMarketplaceIFrameProps) => {
  const business = useSelector(getCurrentBusiness)
  const appointmentId = useSelector(getAppointmentId)
  const appointment = useSelector(getTimetableEvent(appointmentId))
  const patient = useSelector(getPatient(appointment?.patient))
  const client = useSelector(getUser(appointment?.client))
  const soapId = useSelector(getSoapId)

  return (
    <MarketplaceItemIframe
      ContainerProps={{ width: 'auto' }}
      id={iFrame.id}
      params={{
        businessId: business?.id,
        soapId,
        appointmentId,
        clientId: client?.id,
        patientId: patient?.id,
      }}
      title={iFrame.name}
      url={iFrame.expandedUrl}
    />
  )
}

export default ExpandedMarketplaceIFrame
