import React from 'react'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { TaskTimelineItem } from '~/types'

import OneTask, { OneTaskProps } from './OneTask'

const useStyles = makeStyles(
  (theme) => ({
    task: {
      '&:not(:last-of-type)': {
        paddingBottom: theme.spacing(1),
        borderBottom: theme.constants.tabBorder,
      },
    },
  }),
  { name: 'MultipleTasks' },
)

export interface MultipleTasksProps {
  enableBusinessShareIcon?: boolean
  item: TaskTimelineItem
  onEdit?: OneTaskProps['onEdit']
}

const MultipleTasks = ({
  item,
  onEdit,
  enableBusinessShareIcon,
}: MultipleTasksProps) => {
  const classes = useStyles()

  return (
    <>
      {item.entries.map((taskId) => (
        <Grid item className={classes.task} key={taskId}>
          <OneTask
            enableBusinessShareIcon={enableBusinessShareIcon}
            taskId={taskId}
            onEdit={onEdit}
          />
        </Grid>
      ))}
    </>
  )
}

export default MultipleTasks
