import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import * as R from 'ramda'
import {
  AddButton,
  BasePuiDialogProps,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import OrderSelectableListWithFilters from '~/components/dashboard/soap/list-with-filters/OrderSelectableListWithFilters'
import { OrderType } from '~/constants/SOAPStates'
import { fetchOrdersFilters } from '~/store/actions/orders'
import {
  getOrdersFilters,
  getOrdersIsReceiving,
  getOrdersList,
  getOrdersTotalCount,
} from '~/store/reducers/orders'
import { InventoryItem, Order, Variation } from '~/types'
import { arrayToMap } from '~/utils'

import AddInventoryItem from '../catalog/AddInventoryItem'

const getUniqId = (item: Order) =>
  `${R.path(['inventory', 'id'], item)}_${R.path(['variation', 'id'], item)}`
const addItem = (oldItems: Order[], newItems: Order[]) => [
  ...oldItems,
  ...newItems,
]
const deleteItem = (oldItems: Order[], newItems: Order[]) => {
  const removeMap = arrayToMap(newItems, getUniqId, true)
  return oldItems.filter((item) => !removeMap[getUniqId(item)])
}

enum FlowVariant {
  CHOOSE = 'CHOOSE',
  CREATE = 'CREATE',
}

interface AddShipmentItemDialogProps extends BasePuiDialogProps {
  onProceed: (...args: any) => void
  singleItemOnly?: boolean
  title?: string
}

const AddShipmentItemDialog = ({
  open,
  onClose,
  onProceed,
  singleItemOnly,
  title: titleProp,
}: AddShipmentItemDialogProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Search'])
  const title = titleProp || t('Common:ADD_ITEM')

  const isReceivingOrders = useSelector(getOrdersIsReceiving)
  const ordersFilters = useSelector(getOrdersFilters)
  const orders = useSelector(getOrdersList)
  const ordersIsReceiving = useSelector(getOrdersIsReceiving)
  const ordersTotalCount = useSelector(getOrdersTotalCount)

  const [flowVariant, setFlowVariant] = useState(FlowVariant.CHOOSE)

  useEffect(() => {
    if (!isReceivingOrders) {
      dispatch(fetchOrdersFilters({ allowedTypes: [OrderType.INVENTORY] }))
    }
  }, [])

  const proceedAndClose = (...args: any) => {
    onProceed(...args)
    if (onClose) {
      onClose()
    }
  }

  const onAddInventoryItem = () => {
    setFlowVariant(FlowVariant.CREATE)
  }

  const onInventoryCreated = (
    _: string | Nil,
    inventory: InventoryItem | Nil,
  ) => {
    if (inventory) {
      const { variations = [] } = inventory
      const variation = R.last(variations) || ({} as Variation)
      const result = singleItemOnly
        ? { inventory, variation }
        : [{ inventory, variation }]

      onProceed(result)
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="add-shipment-item-dialog"
      maxWidth="md"
      open={open}
      title={title}
      onClose={onClose}
    >
      <Grid pt={2}>
        {flowVariant === FlowVariant.CHOOSE && (
          <OrderSelectableListWithFilters
            forShipments
            addItem={addItem}
            deleteItem={deleteItem}
            filters={ordersFilters}
            getUniqId={getUniqId}
            hasMore={orders.length < ordersTotalCount}
            headerAddon={
              <AddButton
                addText={t('Common:ADD_A_NEW_INVENTORY')}
                onAdd={onAddInventoryItem}
              />
            }
            isLoading={isReceivingOrders}
            isReceivingListItems={ordersIsReceiving}
            isSelectableItem={R.T}
            listItems={orders}
            proceedButtonLabel={t('Common:NEXT')}
            searchPlaceholder={t('Search:CATALOG')}
            showTooltip={false}
            singleItemOnly={singleItemOnly}
            onProceed={proceedAndClose}
          />
        )}
        {flowVariant === FlowVariant.CREATE && (
          <AddInventoryItem
            singleItemOnly={singleItemOnly}
            onClose={onInventoryCreated}
          />
        )}
      </Grid>
    </PuiDialog>
  )
}

export default AddShipmentItemDialog
