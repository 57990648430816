import React, { useRef, useState } from 'react'
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { includes } from 'ramda'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { updateAppointmentStatus } from '~/store/actions/timetable'
import { getEventType } from '~/store/reducers/constants'
import { getTimetableEventsMap } from '~/store/reducers/timetable'
import { getWhiteboardIsLoading } from '~/store/reducers/whiteboard'
import { getConstantFromMapOrDefault } from '~/utils'

import Timetable from '../../Timetable'
import { dragDropIdToReal } from '../../timetableUtils'
import { WhiteboardColumnsProps } from '../WhiteboardColumns'
import {
  WhiteboardHorizontalViews,
  WhiteboardViewType,
} from '../whiteboardViews'

const useStyles = makeStyles(
  (theme) => ({
    content: {
      padding: theme.spacing(0, 2, 0, 1),
    },
  }),
  { name: 'DragAndDropView' },
)

export interface DragAndDropViewProps
  extends Omit<WhiteboardColumnsProps, 'onCardClick' | 'slots'> {
  HeaderComponent: React.JSXElementConstructor<any>
  children: JSX.Element
}

const DragAndDropView = ({
  HeaderComponent,
  openCardId,
  columns,
  children,
  onCardClose,
}: DragAndDropViewProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { view: viewProp } = useParams()
  const view = getConstantFromMapOrDefault(
    viewProp as string,
    WhiteboardViewType,
    WhiteboardViewType.DEFAULT,
  )

  const appointmentsMap = useSelector(getTimetableEventsMap)
  const EventType = useSelector(getEventType)
  const isLoading = useSelector(getWhiteboardIsLoading)
  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}

  const contentRef = useRef<HTMLDivElement>(null)

  const [draggedItemId, setDraggedItemId] = useState<string | null>(null)

  const onDragStart = (data: DragStart) => {
    onCardClose()
    setDraggedItemId(dragDropIdToReal(data.draggableId))
  }

  const onDragEnd = (result: DropResult) => {
    setDraggedItemId(null)

    const { source, destination, draggableId } = result

    if (!destination || source.droppableId === destination.droppableId) {
      return
    }

    const appointmentId = dragDropIdToReal(draggableId)
    const oldStatus = dragDropIdToReal(source.droppableId)
    const newStatus = dragDropIdToReal(destination.droppableId)
    const appointment = appointmentsMap[appointmentId]
    // This is needed here because `Unconfirmed` is not a real status per se but
    // instead it's everything that is not CONFIRMED nor CANCELLED
    // so in such case we just set the status to default - Scheduled
    const newStatusString =
      newStatus === 'unconfirmed'
        ? 'Scheduled'
        : LanguageUtils.capitalize(newStatus)

    const newAppointment = {
      ...appointment,
      state: {
        ...appointment.state,
        id: Utils.findConstantIdByName(
          newStatusString,
          AppointmentEvent.states,
        ),
        name: newStatusString,
      },
    }

    dispatch(updateAppointmentStatus(newAppointment, oldStatus, newStatus))
  }

  const isHorizontalView = includes(view, WhiteboardHorizontalViews)
  const headerColumns = isHorizontalView
    ? [{ id: 'empty' }, ...columns]
    : columns

  return (
    <Timetable
      HeaderComponent={HeaderComponent}
      classes={{
        content: classes.content,
      }}
      columns={headerColumns}
      contentRef={contentRef}
      isLoading={isLoading}
      openShadow={Boolean(openCardId)}
      row={isHorizontalView}
    >
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            draggedItemId,
          }),
        )}
      </DragDropContext>
    </Timetable>
  )
}

export default DragAndDropView
