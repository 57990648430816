import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Nil, PuiDialog, PuiDialogProps, Utils } from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import { createTask, deleteTask, partialEditTask } from '~/store/actions/tasks'
import { getEventType, getFeatureToggle } from '~/store/reducers/constants'
import {
  getLastCreatedTaskId,
  getTask,
  getTasksIsCreating,
  getTasksIsDeleting,
  getTasksIsLoading,
  getTasksValidationError,
} from '~/store/reducers/tasks'
import { EasterEggEvents, Task as TaskType } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useIsCurrentContextItem from '~/utils/useIsCurrentContextItem'

import Task, { TaskHandle } from './Task'
import TaskDialogActions from './TaskDialogActions'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      overflowY: 'unset',
    },
    dialogContentRoot: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
  { name: 'TaskDialog' },
)

export interface TaskDialogProps extends PuiDialogProps {
  appointmentId?: string
  clientId?: string | Nil
  dueDate?: string | Nil
  forClientActive?: boolean
  onTaskCreated?: () => void
  patientId?: string | Nil
  redirectOnCreated?: boolean
  shortTaskFlow?: boolean
  soapId?: string
  taskId?: string
}

const TaskDialog = ({
  redirectOnCreated,
  forClientActive,
  appointmentId,
  clientId,
  patientId,
  taskId,
  soapId,
  shortTaskFlow = false,
  dueDate,
  open,
  onClose,
  onTaskCreated: onTaskCreatedProp = R.F,
}: TaskDialogProps) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Tasks')

  const task = useSelector(getTask(taskId))
  const lastCreatedTaskId = useSelector(getLastCreatedTaskId)
  const tasksValidationError = useSelector(getTasksValidationError)
  const EventType = useSelector(getEventType)
  const isEasterEggEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EASTER_EGG),
  )
  const isTaskListOracleIntegrationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.TASK_LIST_ORACLE_INTEGRATION),
  )

  const TaskEvent = Utils.findConstantByName('Task', EventType) || {}
  const TaskStates = TaskEvent.states

  const onTaskCreated = () => {
    if (onClose) {
      onClose()
    }
    onTaskCreatedProp()
    if (redirectOnCreated && lastCreatedTaskId) {
      navigate(`/tasks-dashboard/${lastCreatedTaskId}`)
    }
  }

  const taskRef = useRef<TaskHandle>(null)

  const setCloseAfterCreationOn = useCloseAfterCreation(
    onTaskCreated,
    getTasksIsCreating,
  )
  const setCloseAfterEditOn = useCloseAfterCreation(onClose, getTasksIsLoading)
  const setCloseAfterDeleteOn = useCloseAfterCreation(
    onClose,
    getTasksIsDeleting,
  )

  const isContextItem = useIsCurrentContextItem(task)

  const [openConfirmCompleteAlert] = useConfirmAlert({
    type: ConfirmAlertType.COMPLETE_TASK,
  })

  const DoneState = Utils.findConstantIdByName('Done', TaskStates)

  const saveTask = (newTask: TaskType) => {
    if (newTask.id) {
      setCloseAfterEditOn()
      dispatch(partialEditTask(newTask))
    } else {
      setCloseAfterCreationOn()
      dispatch(createTask(newTask))
    }
  }

  const onProceed = (newStateId?: string) => {
    if (taskRef.current?.validate()) {
      const newTask = taskRef.current.get()

      if (!newStateId) {
        saveTask(newTask)
        return
      }

      const newStatedTask = {
        ...newTask,
        stateId: newStateId,
      }

      if (newStateId === DoneState && isEasterEggEnabled) {
        dispatch(queueEasterEggEvent({ actionType: EasterEggEvents.TASK_DONE }))
      }

      if (newStateId === DoneState && !isContextItem) {
        openConfirmCompleteAlert({
          message: t('Tasks:COMPLETE_OUTSIDE_CONFIRMATION_DIALOG.MESSAGE'),
          applyCustomMessage: true,
          preventShowAgainCheckBox: false,
          okButtonText: t(
            'Tasks:COMPLETE_OUTSIDE_CONFIRMATION_DIALOG.OK_BUTTON',
          ),
          cancelButtonText: t(
            'Tasks:COMPLETE_OUTSIDE_CONFIRMATION_DIALOG.CANCEL_BUTTON',
          ),
          onConfirm: (proceed) => {
            if (proceed) {
              saveTask(newStatedTask)
            }
          },
        })
      } else {
        saveTask(newStatedTask)
      }
    }
  }

  const onDelete = () => {
    setCloseAfterDeleteOn()
    if (taskId) {
      dispatch(deleteTask(taskId))
    }
  }

  useEffect(() => {
    if (tasksValidationError && onClose) {
      onClose()
    }
  }, [tasksValidationError])

  return (
    <PuiDialog
      confirmSaveOnClose
      ConfirmCloseDialogProps={{
        onOk: () => onProceed(),
      }}
      actions={
        <TaskDialogActions
          task={task}
          onDelete={onDelete}
          onProceed={onProceed}
        />
      }
      aria-labelledby="task-dialog"
      classes={{
        dialogContentRoot: classes.dialogContentRoot,
        paper: classes.paper,
      }}
      hasUnsavedChanges={() =>
        !tasksValidationError && (taskRef.current?.hasUnsavedChanges() ?? false)
      }
      open={open}
      title={task ? t('Tasks:LABEL.EDIT_TASK') : t('Tasks:LABEL.NEW_TASK')}
      onClose={onClose}
    >
      <Task
        appointmentId={appointmentId}
        clientId={clientId}
        dueDate={dueDate}
        forClientActive={forClientActive}
        patientId={patientId}
        ref={taskRef}
        shortTaskFlow={shortTaskFlow}
        shouldUseVCRBehavior={
          isTaskListOracleIntegrationEnabled && task?.forChewyVcr
        }
        soapId={soapId}
        task={task}
      />
    </PuiDialog>
  )
}

export default TaskDialog
