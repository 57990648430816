import { Task } from '@redux-saga/types'
import { AnyAction } from 'redux'
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'

import * as API from '~/api'
// @ts-ignore
import { deserializeFindingsState } from '~/api/utils/serializeSOAPData'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import {
  ApiFindingsContainerData,
  DiagnosesState,
  FindingState,
  SoapData,
} from '~/types'
import { getIsFinalizedError, getServerValidationError } from '~/utils/errors'

import { fetchInvoiceByEventId } from '../actions/finance'
import { updateFullLog } from '../actions/orders'
import {
  addSOAP,
  addSOAPFailure,
  addSOAPSuccess,
  assignedDoctorValidationError,
  attachDocumentsToSoap,
  attachDocumentsToSoapFailure,
  attachDocumentsToSoapSuccess,
  createSOAP,
  createSOAPFailure,
  createSOAPSuccess,
  deleteFile,
  deleteFileFailure,
  deleteFileSuccess,
  editDifferentialDiagnosesStateFailure,
  editDifferentialDiagnosesStateSuccess,
  editFindingsStateFailure,
  editFindingsStateSuccess,
  fetchSoap,
  fetchSoapFailure,
  fetchSoapFiles,
  fetchSoapFilesFailure,
  fetchSoapFilesSuccess,
  fetchSoapFindingsContainer,
  fetchSoapFindingsContainerFailure,
  fetchSoapFindingsContainerSuccess,
  fetchSOAPOrderFilters,
  fetchSOAPOrderFiltersFailure,
  fetchSOAPOrderFiltersSuccess,
  fetchSOAPOrders,
  fetchSOAPOrdersFailure,
  fetchSOAPOrdersSuccess,
  fetchSoapSuccess,
  fetchUsersOnSoapCollaborationSession,
  fetchUsersOnSoapCollaborationSessionFailure,
  fetchUsersOnSoapCollaborationSessionSuccess,
  finalizeSoap,
  notesValidationError,
  reOpenSoap,
  saveSOAP,
  saveSOAPFailure,
  saveSOAPSuccess,
  selectDoctor,
  selectDoctorForCustomSoap,
  selectTech,
  soapPartialUpdate,
  updateDischargeNotes,
  updateDischargeNotesComplete,
  updateDocumentOnSoap,
  updateDocumentOnSoapFailure,
  updateDocumentOnSoapSuccess,
  updateFindingLogsCrcFailure,
  updateFindingLogsCrcSuccess,
  updateMedicalNotes,
  updateMedicalNotesComplete,
  updateSoapFiles,
  uploadFile,
  uploadFileFailure,
  uploadFileSuccess,
} from '../actions/soap'
import { fetchTimeline as fetchTimelineAction } from '../actions/timeline'
import { addEventSoap } from '../actions/timetable'
import * as SOAPType from '../actions/types/soap'
import { startCustomLoading, stopCustomLoading } from '../duck/customLoading'
import { isDialogOpen, openDialog } from '../duck/dialogs'
import { finishLoading, startLoading } from '../duck/progress'
import {
  getCurrentBusinessId,
  getCurrentGroupBusinessIds,
  getCurrentUserId,
} from '../reducers/auth'
import { getFeatureToggle } from '../reducers/constants'
import {
  getClientId,
  getDiagnosesState,
  getDischargeNotesCrc,
  getFindingLogsCrc,
  getFindingsState,
  getMedicalNotesCrc,
  getSoapBusinessId,
  getSoapId,
} from '../reducers/soap'
import requestAPI from './utils/requestAPI'
import updateEntities, { EntitiesToUpdate } from './utils/updateEntities'

const getShortSoapFromSoapData = ({ soapData }: SoapData) => ({
  id: soapData?.id,
  assignedVet: soapData?.soap?.assignedVetId
    ? {
        id: soapData.soap.assignedVetId,
      }
    : undefined,
  assignedVetTech: soapData?.soap?.assignedVetTechId
    ? {
        id: soapData?.soap?.assignedVetTechId,
      }
    : undefined,
})

/**
 *  Deprecated
 *  UI doesn't let user to update Appointment type to Busy and Reserved,
 *  that led to fetch soap conflict
 */
function* handlePossibleSoapAppointmentTypeConflict(error: ApiError) {
  if (error?.response?.status === 409) {
    const isAlreadyOpen: boolean = yield select(
      isDialogOpen(DialogNames.MISSING_RESOURCE),
    )
    if (!isAlreadyOpen) {
      yield put(
        openDialog({
          name: DialogNames.MISSING_RESOURCE,
          id: uuid(),
          props: {
            error,
            customMessage: getServerValidationError(error),
          },
          unique: true,
        }),
      )
    }
  }
}

function* validateSoapBusinessContext() {
  const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
  const currentBusinessId: string | Nil = yield select(getCurrentBusinessId)
  const currentGroupBusinessIds: string[] = yield select(
    getCurrentGroupBusinessIds,
  )
  const isPatientSharingEnabled: boolean = yield select(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )

  const soapFromAnotherContext = isPatientSharingEnabled
    ? soapBusinessId && !currentGroupBusinessIds.includes(soapBusinessId)
    : soapBusinessId && soapBusinessId !== currentBusinessId

  // TODO: PR-319 consider to remove such processing at all if validation will be moved to api side
  if (soapFromAnotherContext) {
    const isAlreadyOpen: boolean = yield select(
      isDialogOpen(DialogNames.MISSING_RESOURCE),
    )
    const customError = {
      response: {
        config: {
          url: '/soaps/',
        },
      },
    } as ApiError
    if (!isAlreadyOpen) {
      yield put(
        openDialog({
          name: DialogNames.MISSING_RESOURCE,
          id: uuid(),
          props: {
            error: customError,
            customMessage: i18n.t('Soap:BUSINESS_HAS_NO_ACCESS_TO_SOAP'),
          },
          unique: true,
        }),
      )
    }
  }
}

export function* processSOAPData(data: SoapData) {
  if (data.orders) {
    yield put(updateFullLog(data.orders))
  }
}

export function* createSOAPSaga({
  appointmentId,
  originalBusinessId,
}: ReturnType<typeof createSOAP>) {
  try {
    yield put(startLoading('soap-create'))
    const { result, entities } = yield* requestAPI(
      API.getOrCreateSOAP,
      appointmentId,
      originalBusinessId,
    )
    const appointmentSoap = getShortSoapFromSoapData(result)
    yield call(updateEntities, entities)
    yield put(addEventSoap(appointmentId, appointmentSoap))
    yield put(createSOAPSuccess(result))
    yield put(updateFullLog(result.orders || []))
    yield put(finishLoading('soap-create'))
    if (result.autocharged) {
      yield put(
        openDialog({
          name: DialogNames.SOAP_AUTOCHARGE_ALERT,
          id: uuid(),
          unique: true,
        }),
      )

      const clientId: string | null = yield select(getClientId)

      if (clientId) {
        yield put(fetchInvoiceByEventId(clientId, appointmentId))
      }
    }
  } catch (error) {
    yield put(createSOAPFailure(error as ApiError))
    yield put(finishLoading('soap-create'))
  }
}

export function* addSOAPSaga({
  appointmentId,
  originalBusinessId,
}: ReturnType<typeof addSOAP>) {
  try {
    yield put(startLoading('add-create'))
    const { result, entities } = yield* requestAPI(
      API.createSOAP,
      appointmentId,
      originalBusinessId,
    )
    const appointmentSoap = getShortSoapFromSoapData(result)
    yield call(updateEntities, entities)
    yield put(addSOAPSuccess(result))
    yield put(addEventSoap(appointmentId, appointmentSoap))
    yield put(updateFullLog(result.orders || []))
    yield put(finishLoading('add-create'))
  } catch (error) {
    yield put(addSOAPFailure(error as ApiError))
    yield put(finishLoading('add-create'))
  }
}

export function* fetchSoapCancelable({
  id,
  originalBusinessId,
}: ReturnType<typeof fetchSoap>) {
  try {
    yield put(startLoading('soap'))
    const {
      result,
      entities,
    }: { entities: EntitiesToUpdate; result: SoapData } = yield* requestAPI(
      API.fetchSoap,
      id,
      originalBusinessId,
    )
    yield call(updateEntities, entities)
    yield processSOAPData(result)
    yield put(fetchSoapSuccess(result))
    yield put(finishLoading('soap'))
    yield call(validateSoapBusinessContext)
  } catch (e) {
    const error = e as ApiError
    yield call(handlePossibleSoapAppointmentTypeConflict, error)
    yield put(fetchSoapFailure(error))
    yield put(finishLoading('soap'))
  }
}

export function* fetchSoapSaga(params: ReturnType<typeof fetchSoap>) {
  const task: Task = yield fork(fetchSoapCancelable, params)
  yield take(SOAPType.CLOSE_SOAP)
  yield cancel(task)
  yield put(finishLoading('soap'))
}

export function* fetchSoapFindingsContainerSaga({
  soapId,
  originalBusinessId,
}: ReturnType<typeof fetchSoapFindingsContainer>) {
  try {
    const { crc: newCrc, findingLogs }: ApiFindingsContainerData =
      yield* requestAPI(
        API.fetchSoapFindingsContainer,
        soapId,
        originalBusinessId,
      )
    yield put(
      fetchSoapFindingsContainerSuccess(
        newCrc,
        deserializeFindingsState(findingLogs),
      ),
    )
  } catch (error) {
    yield put(fetchSoapFindingsContainerFailure(error as ApiError))
  }
}

export function* editFindingsStateSaga() {
  const soapId: string = yield select(getSoapId)
  const soapBusinessId: string = yield select(getSoapBusinessId)
  const findingsState: Record<string, FindingState> =
    yield select(getFindingsState)
  const crc: number = yield select(getFindingLogsCrc)

  yield delay(Defaults.DEBOUNCE_ACTION_TIME)

  try {
    yield put(saveSOAP())
    const { crc: newCrc } = yield* requestAPI(
      API.editFindingsState,
      soapId,
      soapBusinessId,
      findingsState,
      crc,
    )
    yield put(updateFindingLogsCrcSuccess(newCrc))
    yield put(saveSOAPSuccess())
    yield put(editFindingsStateSuccess())
  } catch (e) {
    const error = e as ApiError
    if (
      error.response?.status === 409 &&
      !getIsFinalizedError(error.response?.data?.error?.type)
    ) {
      yield put(updateFindingLogsCrcFailure(error))
    } else {
      yield put(saveSOAPFailure(error))
      yield put(editFindingsStateFailure(error))
    }
  }
}

export function* uploadFileSaga({ file }: ReturnType<typeof uploadFile>) {
  try {
    const soapId: string = yield select(getSoapId)
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
    const authorId: string = yield select(getCurrentUserId)
    const result = yield* requestAPI(API.uploadFile, soapId, soapBusinessId, {
      ...file,
      authorId,
    })
    yield put(updateSoapFiles([result]))
    yield put(uploadFileSuccess())
  } catch (error) {
    yield put(uploadFileFailure(error as ApiError))
  }
}

export function* deleteFileSaga({
  id: fileId,
  soapId: soapIdProp,
}: ReturnType<typeof deleteFile>) {
  try {
    const soapId: string = yield soapIdProp || select(getSoapId)
    const soapBusinessId: string | Nil = yield soapIdProp ||
      select(getSoapBusinessId)
    yield* requestAPI(API.deleteFile, soapId, soapBusinessId, fileId)
    yield put(fetchTimelineAction())
    yield put(deleteFileSuccess(fileId))
  } catch (error) {
    yield put(deleteFileFailure(error as ApiError))
  }
}

export function* attachDocumentsToSoapSaga({
  documentIds,
  soapId,
  soapBusinessId,
  authorId,
}: ReturnType<typeof attachDocumentsToSoap>) {
  try {
    const files = yield* requestAPI(
      API.attachDocumentsToSoap,
      documentIds,
      soapId,
      soapBusinessId,
      authorId,
    )
    yield put(updateSoapFiles(files))
    yield put(attachDocumentsToSoapSuccess())
  } catch (error) {
    yield put(attachDocumentsToSoapFailure(error as ApiError))
  }
}

export function* updateDocumentOnSoapSaga({
  soapId,
  soapBusinessId,
  file,
}: ReturnType<typeof updateDocumentOnSoap>) {
  try {
    const updatedFile = yield* requestAPI(
      API.updateDocumentOnSoap,
      soapId,
      soapBusinessId,
      file,
    )
    yield put(updateSoapFiles([updatedFile]))
    yield put(updateDocumentOnSoapSuccess())
  } catch (error) {
    yield put(updateDocumentOnSoapFailure(error as ApiError))
  }
}

export function* fetchSoapOrdersSaga({
  soapId,
  soapBusinessId,
}: ReturnType<typeof fetchSOAPOrders>) {
  try {
    const orders = yield* requestAPI(
      API.fetchSOAPOrders,
      soapId,
      soapBusinessId,
    )
    yield put(updateFullLog(orders || []))
    yield put(fetchSOAPOrdersSuccess())
  } catch (error) {
    yield put(fetchSOAPOrdersFailure(error as ApiError))
  }
}

export function* fetchSoapOrderFiltersSaga({
  appointmentId,
  originalBusinessId,
}: ReturnType<typeof fetchSOAPOrderFilters>) {
  try {
    const { orderFilters } = yield* requestAPI(
      API.fetchSOAPOrderFilters,
      appointmentId,
      originalBusinessId,
    )
    yield put(fetchSOAPOrderFiltersSuccess(orderFilters))
  } catch (error) {
    yield put(fetchSOAPOrderFiltersFailure(error as ApiError))
  }
}

function* soapPartialUpdateSaga(
  data: any,
  errorAction?: (e: ApiError) => AnyAction,
  force?: boolean,
) {
  try {
    const soapId: string = yield select(getSoapId)
    const soapBusinessId: string | Nil = yield select(getSoapBusinessId)

    if (soapId) {
      yield put(saveSOAP())
      const { result, entities } = yield* requestAPI(
        API.editSOAP,
        soapId,
        soapBusinessId,
        data,
        force,
      )
      yield call(updateEntities, entities)
      yield processSOAPData(result)
      yield put(saveSOAPSuccess(result))
    }
  } catch (e) {
    const error = e as ApiError
    if (
      errorAction &&
      error?.response?.status === 409 &&
      !getIsFinalizedError(error.response.data?.error?.type)
    ) {
      yield put(
        errorAction(error.response.data?.error || error.response.data?.message),
      )
    } else {
      yield put(saveSOAPFailure(error))
    }
  }
}

function* customSoapPartialUpdate(
  soapId: string,
  soapBusinessId: string | Nil,
  data: any,
  errorAction?: (e: ApiError) => AnyAction,
  force?: boolean,
) {
  const currentSoapId: string = yield select(getSoapId)

  try {
    yield put(startCustomLoading(soapId))
    const { result, entities } = yield* requestAPI(
      API.editSOAP,
      soapId,
      soapBusinessId,
      data,
      force,
    )
    yield call(updateEntities, entities)
    if (soapId === currentSoapId) {
      yield processSOAPData(result)
      yield put(saveSOAPSuccess(result))
    }
  } catch (e) {
    const error = e as ApiError
    if (errorAction && error?.response?.status === 409) {
      yield put(
        errorAction(error.response.data?.error || error.response.data?.message),
      )
    } else {
      yield put(saveSOAPFailure(error))
    }
  } finally {
    yield put(stopCustomLoading(soapId))
  }
}

export function* selectDoctorForCustomSoapSaga({
  soapId,
  soapBusinessId,
  doctorId,
  force,
}: ReturnType<typeof selectDoctorForCustomSoap>) {
  yield call(
    customSoapPartialUpdate,
    soapId,
    soapBusinessId,
    { assignedVetId: doctorId },
    assignedDoctorValidationError,
    force,
  )
}

export function* fetchSoapFilesSaga({
  soapId,
  originalBusinessId,
}: ReturnType<typeof fetchSoapFiles>) {
  try {
    const files = yield* requestAPI(
      API.fetchSOAPFiles,
      soapId,
      originalBusinessId,
    )
    yield put(fetchSoapFilesSuccess(files))
  } catch (error) {
    yield put(fetchSoapFilesFailure(error as ApiError))
  }
}

export function* editSoapFinalizedStatusSaga({
  finalized,
  soapId: soapIdProp,
  originalBusinessId,
}: ReturnType<typeof finalizeSoap> | ReturnType<typeof reOpenSoap>) {
  try {
    yield put(startLoading('soap-lock'))
    yield put(saveSOAP())
    const soapId: string = yield soapIdProp || select(getSoapId)
    const soapBusinessId: string = yield originalBusinessId ||
      select(getSoapBusinessId)
    const { result, entities } = yield* requestAPI(
      API.finalizeSoap,
      soapId,
      finalized,
      soapBusinessId,
    )
    yield call(updateEntities, entities)
    yield processSOAPData(result)
    yield put(saveSOAPSuccess(result))
  } catch (error) {
    yield put(saveSOAPFailure(error as ApiError))
  } finally {
    yield put(finishLoading('soap-lock'))
  }
}

export function* fetchUsersOnSoapCollaborationSessionSaga({
  soapId,
  soapBusinessId,
}: ReturnType<typeof fetchUsersOnSoapCollaborationSession>) {
  try {
    yield put(fetchUsersOnSoapCollaborationSession(soapId, soapBusinessId))
    const users = yield* requestAPI(
      API.fetchUsersOnSoapCollaborationSession,
      soapId,
      soapBusinessId,
    )
    yield put(fetchUsersOnSoapCollaborationSessionSuccess(users))
  } catch (error) {
    yield put(fetchUsersOnSoapCollaborationSessionFailure(error as ApiError))
  }
}

function* watchFetchSoap() {
  yield takeLatest(SOAPType.FETCH_SOAP, fetchSoapSaga)
}

function* watchFetchSoapFindingsContainer() {
  yield takeLatest(
    SOAPType.FETCH_SOAP_FINDINGS_CONTAINER,
    fetchSoapFindingsContainerSaga,
  )
}

function* watchCreateSoap() {
  yield takeLeading(SOAPType.CREATE_SOAP, createSOAPSaga)
}

function* watchAddSoap() {
  yield takeLeading(SOAPType.ADD_SOAP, addSOAPSaga)
}

function* watchEditFindingsState() {
  yield takeLatest(SOAPType.EDIT_FINDINGS_STATE, editFindingsStateSaga)
}

function* watchUploadFile() {
  yield takeLeading(SOAPType.UPLOAD_FILE, uploadFileSaga)
}

function* watchDeleteFile() {
  yield takeLeading(SOAPType.DELETE_FILE, deleteFileSaga)
}

function* watchAttachDocumentsToSoap() {
  yield takeLeading(
    SOAPType.ATTACH_DOCUMENTS_TO_SOAP,
    attachDocumentsToSoapSaga,
  )
}

function* watchUpdateDocumentOnSoap() {
  yield takeLeading(SOAPType.UPDATE_DOCUMENT_ON_SOAP, updateDocumentOnSoapSaga)
}

function* watchFetchSoapOrdersSaga() {
  yield takeLatest(SOAPType.FETCH_SOAP_ORDERS, fetchSoapOrdersSaga)
}

function* watchFetchSoapOrderFilterSaga() {
  yield takeLeading(
    SOAPType.FETCH_SOAP_ORDER_FILTERS,
    fetchSoapOrderFiltersSaga,
  )
}

export function* updateMedicalNotesSaga({
  notes,
  forceUpdate,
}: ReturnType<typeof updateMedicalNotes>) {
  const medicalNotesCrc: number = yield select(getMedicalNotesCrc)
  const data = {
    medicalNotes: notes,
    medicalNotesCrc: forceUpdate ? null : medicalNotesCrc,
  }

  yield call(soapPartialUpdateSaga, data, notesValidationError)
  yield put(updateMedicalNotesComplete())
}

export function* updateDischargeNotesSaga({
  notes,
  forceUpdate,
}: ReturnType<typeof updateDischargeNotes>) {
  const dischargeNotesCrc: number = yield select(getDischargeNotesCrc)
  const data = {
    dischargeNotes: notes,
    dischargeNotesCrc: forceUpdate ? null : dischargeNotesCrc,
  }

  yield call(soapPartialUpdateSaga, data, notesValidationError)
  yield put(updateDischargeNotesComplete())
}

export function* selectDoctorSaga({
  id,
  force,
}: ReturnType<typeof selectDoctor>) {
  yield call(
    soapPartialUpdateSaga,
    { assignedVetId: id },
    assignedDoctorValidationError,
    force,
  )
}

export function* selectTechSaga({ id }: ReturnType<typeof selectTech>) {
  yield call(soapPartialUpdateSaga, { assignedVetTechId: id })
}

export function* soapPartialUpdateWatcher({
  data,
}: ReturnType<typeof soapPartialUpdate>) {
  yield call(soapPartialUpdateSaga, data)
}

export function* editDiagnosesState() {
  const soapId: string = yield select(getSoapId)
  const soapBusinessId: string | Nil = yield select(getSoapBusinessId)
  const diagnosesState: Record<string, DiagnosesState> =
    yield select(getDiagnosesState)

  yield delay(Defaults.DEBOUNCE_ACTION_TIME)
  try {
    yield put(saveSOAP())
    const newStateMap = yield* requestAPI(
      API.editDiagnosesState,
      soapId,
      soapBusinessId,
      diagnosesState,
    )
    yield put(saveSOAPSuccess())
    yield put(editDifferentialDiagnosesStateSuccess(newStateMap))
  } catch (e) {
    const error = e as ApiError
    yield put(saveSOAPFailure(error))
    yield put(editDifferentialDiagnosesStateFailure(error))
  }
}

function* watchUpdateMedicalNotes() {
  yield takeLeading(SOAPType.UPDATE_MEDICAL_NOTES, updateMedicalNotesSaga)
}

function* watchUpdateDischargeNotes() {
  yield takeLeading(SOAPType.UPDATE_DISCHARGE_NOTES, updateDischargeNotesSaga)
}

function* watchSelectDoctor() {
  yield takeLeading(SOAPType.SELECT_DOCTOR, selectDoctorSaga)
}

function* watchSelectDoctorForCustomSoap() {
  yield takeLeading(
    SOAPType.SELECT_DOCTOR_FOR_CUSTOM_SOAP,
    selectDoctorForCustomSoapSaga,
  )
}

function* watchSelectTech() {
  yield takeLeading(SOAPType.SELECT_TECH, selectTechSaga)
}

function* watchFetchSoapFiles() {
  yield takeLatest(SOAPType.FETCH_SOAP_FILES, fetchSoapFilesSaga)
}

function* watchEditSoapFinalizedStatus() {
  yield takeLatest(
    SOAPType.EDIT_SOAP_FINALIZED_STATUS,
    editSoapFinalizedStatusSaga,
  )
}

function* watchUpdateDiagnosesState() {
  yield takeLatest(
    SOAPType.EDIT_DIFFERENTIAL_DIAGNOSES_STATE,
    editDiagnosesState,
  )
}

function* watchSoapPartialUpdate() {
  yield takeLeading(SOAPType.SOAP_PARTIAL_UPDATE, soapPartialUpdateWatcher)
}

function* watchFetchUsersOnSoapCollaborationSession() {
  yield takeLeading(
    SOAPType.FETCH_USERS_ON_SOAP_COLLABORATION_SESSION,
    fetchUsersOnSoapCollaborationSessionSaga,
  )
}

export default function* soapSaga() {
  yield all([
    watchCreateSoap(),
    watchAddSoap(),
    watchFetchSoap(),
    watchSoapPartialUpdate(),
    watchFetchSoapFindingsContainer(),
    watchEditFindingsState(),
    watchUploadFile(),
    watchAttachDocumentsToSoap(),
    watchDeleteFile(),
    watchUpdateDocumentOnSoap(),
    watchFetchSoapOrdersSaga(),
    watchFetchSoapOrderFilterSaga(),
    watchUpdateMedicalNotes(),
    watchUpdateDischargeNotes(),
    watchSelectDoctor(),
    watchSelectTech(),
    watchFetchSoapFiles(),
    watchSelectDoctorForCustomSoap(),
    watchEditSoapFinalizedStatus(),
    watchUpdateDiagnosesState(),
    watchFetchUsersOnSoapCollaborationSession(),
  ])
}
