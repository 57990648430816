import { gql } from '@apollo/client/core'

import {
  CONVERSATION_DTO_FIELDS,
  DOWNLOAD_ATTACHMENT_DTO_FIELDS,
  SHORT_CONVERSATION_DTO_FIELDS,
} from '../fragments'

export const SEND_EMAIL_PAYMENT = gql`
  ${CONVERSATION_DTO_FIELDS}
  mutation SendEmailPayment($input: PaymentEmail!) {
    sendEmailPayment(input: $input) {
      ...ConversationDtoFields
    }
  }
`

export const SEND_BOOP_SIGN_UP_MESSAGE = gql`
  mutation SendBoopSignUpMessage($message: MessageOrigin!) {
    sendBoopSignUpMessage(message: $message) {
      createdConversations
    }
  }
`

export const SEND_EMAIL_PRESCRIPTION = gql`
  ${SHORT_CONVERSATION_DTO_FIELDS}
  mutation EmailPrescription($input: PrescriptionEmailDto!) {
    emailPrescription(input: $input) {
      ...ShortConversationDtoFields
    }
  }
`

export const SEND_EMAIL_PRESCRIPTION_OUTSIDE_SOAP = gql`
  ${SHORT_CONVERSATION_DTO_FIELDS}
  mutation EmailOutsideSoapPrescription($input: PrescriptionEmailDto!) {
    emailPrescription: emailOutsideSoapPrescription(input: $input) {
      ...ShortConversationDtoFields
    }
  }
`

export const GENERATE_PDF_CHARGE_SHEET = gql`
  ${DOWNLOAD_ATTACHMENT_DTO_FIELDS}
  mutation GeneratePdfForChargeSheet(
    $businessId: ID!
    $clientId: ID!
    $expandedGroups: [GroupIdentifier!]
  ) {
    generatePdfForChargeSheet(
      businessId: $businessId
      clientId: $clientId
      expandedGroups: $expandedGroups
    ) {
      ...DownloadAttachmentDtoFields
    }
  }
`
export const GENERATE_PDF_INVOICE = gql`
  ${DOWNLOAD_ATTACHMENT_DTO_FIELDS}
  mutation GeneratePdfForInvoice(
    $businessId: ID
    $invoiceId: ID
    $expandedGroups: [GroupIdentifier!]
    $includeServiceFee: Boolean
  ) {
    generatePdfForInvoice(
      businessId: $businessId
      invoiceId: $invoiceId
      expandedGroups: $expandedGroups
      includeServiceFee: $includeServiceFee
    ) {
      ...DownloadAttachmentDtoFields
    }
  }
`

export const GENERATE_PDF_PRESCRIPTION = gql`
  ${DOWNLOAD_ATTACHMENT_DTO_FIELDS}
  mutation PdfFilePrescription($input: PrescriptionAttachmentDto!) {
    pdfFilePrescription(input: $input) {
      ...DownloadAttachmentDtoFields
    }
  }
`

export const EMAIL_CHARGE_SHEET = gql`
  ${SHORT_CONVERSATION_DTO_FIELDS}
  mutation SendChargeSheetEmail($input: ChargeSheetEmailDto!) {
    sendChargeSheetEmail(chargeSheetEmailDto: $input) {
      ...ShortConversationDtoFields
    }
  }
`

export const EMAIL_INVOICE = gql`
  ${SHORT_CONVERSATION_DTO_FIELDS}
  mutation SendInvoiceEmail($input: InvoiceEmailDto!) {
    sendInvoiceEmail(invoiceEmailDto: $input) {
      ...ShortConversationDtoFields
    }
  }
`

export const GENERATE_PDF_REPORT_CARD = gql`
  mutation GeneratePdfForReportCard($input: ReportCardAttachmentDto!) {
    generatePdfForReportCard(input: $input)
  }
`

export const EMAIL_REPORT_CARD = gql`
  mutation SendReportCardEmail($input: ReportCardEmailDto!) {
    sendReportCardEmail(input: $input)
  }
`

export const GENERATE_PDF_MEDICAL_HISTORY = gql`
  mutation GeneratePdfForMedicalHistory($input: MedicalHistoryAttachmentDto!) {
    generatePdfForMedicalHistory(input: $input)
  }
`

export const EMAIL_MEDICAL_HISTORY = gql`
  mutation SendMedicalHistoryEmail($input: MedicalHistoryEmailDto!) {
    sendMedicalHistoryEmail(input: $input)
  }
`

export const EMAIL_LAB_RESULT = gql`
  ${SHORT_CONVERSATION_DTO_FIELDS}
  mutation EmailLabResult($input: LabTestResultEmailDto!) {
    emailLabResult(input: $input) {
      ...ShortConversationDtoFields
    }
  }
`
