import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'

import type { RootState } from '../index'

export const START_LOADING = 'progress/START_LOADING'
export const FINISH_LOADING = 'progress/FINISH_LOADING'

export const startLoading = (area: string) => ({ type: START_LOADING, area })
export const finishLoading = (area: string) => ({ type: FINISH_LOADING, area })

export type ProgressState = {
  loadingAreas: string[]
}

const INITIAL_STATE: ProgressState = {
  loadingAreas: [],
}

export const progressReducer = (
  state: ProgressState = INITIAL_STATE,
  action: AnyAction,
): ProgressState => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loadingAreas: R.uniq([...state.loadingAreas, action.area]),
      }
    case FINISH_LOADING:
      return {
        ...state,
        loadingAreas: R.without([action.area], state.loadingAreas),
      }
    default:
      return state
  }
}

export const getProgress = (state: RootState): ProgressState => state.progress
export const getIsLoading = (state: RootState) =>
  getProgress(state).loadingAreas.length > 0
export const getIsAreaLoading = (area: string) =>
  createSelector(getProgress, ({ loadingAreas }) => loadingAreas.includes(area))
