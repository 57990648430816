import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'

import { getConstantMap } from '~/store/reducers/constants'
import {
  MigrationException,
  MigrationExceptionEntityToField,
} from '~/types/entities/migrationV2/migrationExceptions'

import { MigrationExceptionColumns } from './MigrationExceptionColumns'
import MigrationExceptionsTableRow from './MigrationExceptionTableRow'
import { getActualColumns, getProposedColumns } from './utils'

interface MigrationExceptionsTableProps {
  exceptions: MigrationException[]
  migrationEntityToField: MigrationExceptionEntityToField
  onExceptionChange: (
    importExceptionId: string,
    exception: MigrationException,
  ) => void
}

export const MigrationExceptionsTable = ({
  exceptions,
  migrationEntityToField,
  onExceptionChange,
}: MigrationExceptionsTableProps) => {
  const Constants = useSelector(getConstantMap)
  const { entity } = migrationEntityToField

  const actualColumns = getActualColumns(migrationEntityToField)
  const proposedColumns = getProposedColumns(migrationEntityToField, Constants)

  const columns = [...actualColumns, ...proposedColumns]

  return (
    <Grid container direction="column" p={1}>
      <Grid container direction="column">
        <MigrationExceptionColumns columns={columns} />
        {exceptions.map((exception) => (
          <MigrationExceptionsTableRow
            columns={columns}
            exception={exception}
            key={`${entity}-row-${exception.id}`}
            proposedColumns={proposedColumns}
            onExceptionChange={onExceptionChange}
          />
        ))}
      </Grid>
    </Grid>
  )
}
