import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { PuiAlert, PuiDialogProps } from '@pbt/pbt-ui-components'

import {
  getFinanceError,
  getFinanceInvoice,
  getFinanceIsSaving,
  getFinanceTemplate,
} from '~/store/reducers/finance'
import { InvoiceLineItem } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'

export interface SaveInvoiceAlertProps extends PuiDialogProps {
  handleUpdatePrescriptionLineItem: (item: InvoiceLineItem) => void
  item: InvoiceLineItem
  save: () => void
}

const SaveInvoiceAlert = ({
  open,
  onClose,
  save,
  item,
  handleUpdatePrescriptionLineItem,
}: SaveInvoiceAlertProps) => {
  const { t } = useTranslation(['Invoices', 'Common'])

  const isSaving = useSelector(getFinanceIsSaving)
  const template = useSelector(getFinanceTemplate)
  const error = useSelector(getFinanceError)
  const invoice = useSelector(getFinanceInvoice(item?.invoiceId))

  const closeAfterSave = useCloseAfterCreation(() => {
    if (!error) {
      const targetInvoice = item.invoiceId && invoice ? invoice : template

      const allGroups = R.flatten(
        targetInvoice?.groups.map(R.prop('groupedItems')) || [],
      )
      const itemGroup = allGroups.find(({ group }) => group === item.bundleId)
      const foundItem = itemGroup?.items?.find(
        (groupItem) => groupItem.name === item.name,
      )
      if (foundItem) {
        handleUpdatePrescriptionLineItem(foundItem)
      }
    }
    onClose?.()
  }, getFinanceIsSaving)

  const saveAndEditItem = () => {
    closeAfterSave()
    save()
  }

  return (
    <PuiAlert
      CancelButtonProps={{ disabled: isSaving }}
      OkButtonProps={{ loading: isSaving }}
      message={t('Invoices:YOU_MUST_SAVE_THE_INVOICE')}
      okButtonText={t('Common:SAVE_ACTION')}
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onOk={saveAndEditItem}
    />
  )
}

export default SaveInvoiceAlert
