import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCheckbox, Text } from '@pbt/pbt-ui-components'

import DayScrollableColumns from '~/components/common/lists/scrollable-columns/DayScrollableColumns'
import { getIsCurrentContextSoap, getIsFinalized } from '~/store/reducers/soap'

const useStyles = makeStyles(
  (theme) => ({
    header: {
      border: '1px solid transparent',
    },
    headerText: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    dueDateColumn: {
      minWidth: 100,
    },
    selectAllCheckbox: {
      marginLeft: 19,
      marginRight: theme.spacing(1),
      padding: 0,
    },
  }),
  { name: 'ToDoTasksListHeader' },
)

export interface ToDoTasksListHeaderProps {
  contentRefs: React.RefObject<HTMLDivElement>[]
  handleSelectAll: () => void
  hasRecurringTasks?: boolean
  hasTasksToSelect?: boolean
  isAllTasksSelected?: boolean
  onScrollOffsetChange: (offset: number) => void
}

const ToDoTasksListHeader = ({
  onScrollOffsetChange,
  contentRefs,
  hasRecurringTasks,
  isAllTasksSelected,
  handleSelectAll,
  hasTasksToSelect,
}: ToDoTasksListHeaderProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Time'])

  const isSoapFinalized = useSelector(getIsFinalized)
  const isCurrentContextSoap = useSelector(getIsCurrentContextSoap)

  const isReadOnly = isSoapFinalized || !isCurrentContextSoap

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        className={classes.header}
        mb={1}
      >
        <Grid item xs>
          <PuiCheckbox
            checkboxClasses={{
              root: classes.selectAllCheckbox,
            }}
            checked={isAllTasksSelected}
            className={classes.headerText}
            disabled={!hasTasksToSelect}
            label={t('Common:SELECT_ALL')}
            onChange={handleSelectAll}
          />
        </Grid>
        <Grid item xs={2}>
          <Text strong variant="lowAccent2">
            {t('Common:STATUS')}
          </Text>
        </Grid>
        <Grid
          item
          className={classes.dueDateColumn}
          xs={hasRecurringTasks ? 1 : 3}
        >
          <Text strong variant="lowAccent2">
            {t('Time:LABEL.DUE_DATE')}
          </Text>
        </Grid>
        {!isReadOnly && (
          <Grid container item justifyContent="flex-end" pr={1} xs={1}>
            <Text strong align="right" variant="lowAccent2">
              {t('Common:TASK')}
            </Text>
          </Grid>
        )}
        {hasRecurringTasks && (
          <Grid item xs={5}>
            <DayScrollableColumns
              hasLoaded
              additionalRefs={contentRefs}
              onScrollOffsetChange={onScrollOffsetChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default ToDoTasksListHeader
