import * as R from 'ramda'
import { AnyAction } from 'redux'
import { ApiError, NamedEntity, Nil } from '@pbt/pbt-ui-components'

import { secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_DISCOUNT_GROUPS = 'discountGroups/FETCH_DISCOUNT_GROUPS'
export const FETCH_DISCOUNT_GROUPS_SUCCESS =
  'discountGroups/FETCH_DISCOUNT_GROUPS_SUCCESS'
export const FETCH_DISCOUNT_GROUPS_FAILURE =
  'discountGroups/FETCH_DISCOUNT_GROUPS_FAILURE'

export const UPDATE_DISCOUNT_GROUPS = 'discountGroups/UPDATE_DISCOUNT_GROUPS'

export const fetchDiscountGroups = (businessId: string) => ({
  type: FETCH_DISCOUNT_GROUPS,
  businessId,
})
export const fetchDiscountGroupsSuccess = (list: string[]) => ({
  type: FETCH_DISCOUNT_GROUPS_SUCCESS,
  list,
})
export const fetchDiscountGroupsFailure = (error: ApiError) => ({
  type: FETCH_DISCOUNT_GROUPS_FAILURE,
  error,
})

export const updateDiscountGroups = (
  discountGroups: Record<string, NamedEntity>,
) => ({
  type: UPDATE_DISCOUNT_GROUPS,
  discountGroups,
})

export type DiscountGroupsState = {
  error: string | Nil
  isFetching: boolean
  isLoading: boolean
  list: string[]
  map: Record<string, NamedEntity>
}

const INITIAL_STATE = {
  list: [],
  map: {},
  isFetching: false,
  isLoading: false,
  error: undefined,
}

export const discountGroupsReducer = (
  state: DiscountGroupsState = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_DISCOUNT_GROUPS:
      return {
        ...state,
        list: [],
        isLoading: true,
        isFetching: true,
      }
    case FETCH_DISCOUNT_GROUPS_SUCCESS:
      return {
        ...state,
        list: action.list,
        isLoading: false,
        isFetching: false,
      }
    case FETCH_DISCOUNT_GROUPS_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
        isFetching: false,
      }
    case UPDATE_DISCOUNT_GROUPS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.discountGroups),
      }
    default:
      return state
  }
}

export const getDiscountGroups = (state: RootState): DiscountGroupsState =>
  state.discountGroups
export const getDiscountGroupsList = (state: RootState) =>
  getDiscountGroups(state).list
export const getDiscountGroupsMap = (state: RootState) =>
  getDiscountGroups(state).map
export const getDiscountGroupsIsLoading = (state: RootState) =>
  getDiscountGroups(state).isLoading
export const getDiscountGroupsIsFetching = (state: RootState) =>
  getDiscountGroups(state).isFetching
export const getDiscountGroup = R.curry(
  (id, state) => getDiscountGroupsMap(state)[id],
)
export const getMultipleDiscountGroups = R.curry((ids, state) =>
  R.props(ids, getDiscountGroupsMap(state)),
)
