import { gql } from '@apollo/client'

export const FETCH_REMINDER_PROTOCOLS_FOR_PROCEDURE = gql`
  query ReminderProtocols($priceId: ID!, $procedureId: ID!) {
    reminderProtocols(
      orderItemOptions: [
        { orderItemId: $priceId, orderItemType: PRICE, optionType: TRIGGER }
        {
          orderItemId: $procedureId
          orderItemType: PROCEDURE
          optionType: TRIGGER
        }
      ]
      includeDeleted: false
      limit: 2
      offset: 0
    ) {
      data {
        id
        businessId
        reminderName
        dueDateOffset {
          amount
          unit
        }
        orderItemOptions {
          orderItemId
          optionType
          orderItemType
        }
        deleted
      }
      totalCount
    }
  }
`
