import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AddButton,
  ControlButtonGroup,
  ControlButtonGroupName,
  ErrorTooltip,
  LanguageUtils,
  PuiTextField,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import PuiSwitch from '~/components/common/PuiSwitch'
import DialogNames from '~/constants/DialogNames'
import { createTeam } from '~/store/actions/members'
import { getMemberIsLoading } from '~/store/reducers/members'
import { getRolesMap } from '~/store/reducers/roles'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  (theme) => ({
    tableAdd: {
      borderTop: theme.constants.tableBorder,
    },
  }),
  { name: 'TeamConfigurationAdd' },
)

interface TeamConfigurationAddProps {
  businessId: string
  className?: string
  onDiscard?: () => void
}

const TeamConfigurationAdd = ({
  className,
  businessId: businessIdProp,
  onDiscard = R.F,
}: TeamConfigurationAddProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const rolesMap = useSelector(getRolesMap)
  const isLoading = useSelector(getMemberIsLoading)

  const [openRolesDialog] = useDialog(DialogNames.ROLES)

  const {
    validate,
    reset,
    fields: { active, name, roleIds },
  } = useFields(
    [
      {
        name: 'active',
        type: 'toggle',
        validators: ['required'],
        initialValue: true,
      },
      { name: 'name', validators: ['required'], initialValue: '' },
      {
        name: 'roleIds',
        validators: ['required'],
        initialValue: [],
      },
    ],
    false,
  )

  const handleDiscard = () => {
    reset()
    onDiscard()
  }

  const resetFieldsAfterCreationOn = useCloseAfterCreation(
    reset,
    getMemberIsLoading,
  )

  const handleUpdateRoles = (_: string, newRoleIds: string[]) => {
    roleIds.setValue(newRoleIds)
  }

  const handleAddRoles = () => {
    openRolesDialog({
      businessId: businessIdProp,
      selectedRoleList: roleIds.value.map((roleId: string) => ({
        roleId,
        businessId: businessIdProp,
        isGroup: false,
      })),
      onSave: handleUpdateRoles,
    })
  }

  const handleAdd = () => {
    if (businessIdProp && validate()) {
      dispatch(
        createTeam({
          active: active.value,
          firstName: name.value,
          businessToRoleList: roleIds.value.map((roleId: string) => ({
            business: businessIdProp,
            role: roleId,
          })),
        }),
      )
      resetFieldsAfterCreationOn()
    }
  }

  const rolesString = (roleIds.value as string[])
    .map((roleId) => LanguageUtils.getTranslatedFieldName(rolesMap[roleId]))
    .join(', ')

  return (
    <Grid
      container
      item
      className={classNames(classes.tableAdd, className)}
      wrap="nowrap"
    >
      <Grid container item alignItems="center" px={1.5} xs={2}>
        <PuiSwitch field={active} />
      </Grid>
      <Grid container item alignItems="center" px={1.5} xs={3}>
        <PuiTextField
          autoFocus
          disabled={isLoading}
          field={name}
          inputProps={{ maxLength: 100 }}
          margin="none"
        />
      </Grid>
      <Grid container item alignItems="center" px={1.5} wrap="nowrap" xs={5}>
        {R.isEmpty(roleIds.value) ? (
          <ErrorTooltip
            disablePortal
            message={roleIds.message}
            open={roleIds.open && !roleIds.valid}
            validateTag={roleIds.validateTag}
          >
            <Grid>
              <AddButton
                addText={t('Common:ADD_ROLES')}
                disabled={isLoading}
                onAdd={handleAddRoles}
              />
            </Grid>
          </ErrorTooltip>
        ) : (
          <>
            <Text variant="body2">{rolesString}</Text>
            <ControlButtonGroup
              buttons={[
                {
                  name: ControlButtonGroupName.EDIT,
                  onClick: handleAddRoles,
                  isLoading,
                },
              ]}
            />
          </>
        )}
      </Grid>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="flex-end"
        px={1.5}
        xs={3}
      >
        <ControlButtonGroup
          buttons={[
            {
              name: ControlButtonGroupName.CHECK,
              onClick: handleAdd,
              isLoading,
            },
            {
              name: ControlButtonGroupName.REMOVE,
              onClick: handleDiscard,
              isLoading,
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default TeamConfigurationAdd
