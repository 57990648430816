import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'

import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import { InvoiceViewStates } from '~/constants/refund'
import {
  expandGroups,
  getGroupIdIsExpanded,
} from '~/store/duck/clientFinanceData'
import { InvoiceLineItem } from '~/types'

import InvoiceTableSubHeader from './InvoiceTableSubHeader'
import { RefundContext } from './RefundContext'
import { RefundTableRow } from './RefundTableRow'

const useStyles = makeStyles(
  (theme) => ({
    tableCell: {
      marginBottom: theme.spacing(2),
    },
    footerCell: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
    selectedRow: {
      outline: `1px solid ${theme.colors.tabSelected}`,
      backgroundColor: `${theme.colors.tableLeftColumnBackground} !important`,
    },
    table: {
      border: 'none',
      backgroundColor: theme.colors.contentBackground,
    },
    tableTitle: {
      height: theme.spacing(4),
      padding: `0 ${theme.spacing(0, 1)}`,
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    tableRow: {
      backgroundColor: theme.colors.tableBackground,
      '&:hover': {
        backgroundColor: theme.colors.panelRow,
        outline: theme.constants.hoverBorder,
        boxShadow:
          '0 2px 4px 0 rgba(0,0,0,0.1), 3px 3px 20px 0 rgba(168,163,163,0.2)',
      },
      '&:first-of-type': {
        borderTop: theme.constants.tabBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'inherit',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    summaryTableCell: {
      height: FINANCE_TABLE_CELL_HEIGHT,
      '&:last-of-type': {
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    landingTableCell: {
      height: FINANCE_TABLE_CELL_HEIGHT,
      '&:first-of-type': {
        padding: 0,
      },
      '&:nth-of-type(2)': {
        paddingLeft: theme.spacing(1),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'RefundLandingTable' },
)

export interface RefundLandingTableProps {
  groupId: string
  items: InvoiceLineItem[]
}

export const RefundLandingTable = ({
  items = [],
  groupId,
}: RefundLandingTableProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { getRowRefs, itemsMarkedForRefund, viewState } =
    useContext(RefundContext)
  const summaryView = viewState === InvoiceViewStates.REFUND_SUMMARY

  const isExpandedSelector = (item: InvoiceLineItem) => {
    const { patientId, soapId: soapIdProp, id: group } = item
    return getGroupIdIsExpanded({ patientId, soapId: soapIdProp, group })
  }

  const toggle = (item: InvoiceLineItem) =>
    dispatch(
      expandGroups({
        groupId: {
          patientId: item.patientId,
          soapId: item.soapId,
          group: item.id,
        },
      }),
    )

  const filter = (id: string) => itemsMarkedForRefund.has(id)

  const visibleItems = summaryView
    ? items.filter(
        (i) =>
          filter(i.id) ||
          R.any(
            filter,
            R.map((item) => item.id, i.items || []),
          ),
      )
    : items

  return (
    <Table className={classes.tableCell}>
      <TableHead>
        <InvoiceTableSubHeader currentItemId={groupId} />
      </TableHead>
      <TableBody>
        {visibleItems.map((item) => (
          <TableRow className={classes.tableRow} key={item.id}>
            <RefundTableRow
              isExpandedSelector={isExpandedSelector}
              item={item}
              ref={(node) => {
                if (node) {
                  getRowRefs().set(item.id, node)
                }
              }}
              tableCellClassName={
                summaryView
                  ? classes.summaryTableCell
                  : classes.landingTableCell
              }
              toggleExpand={toggle}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
