import React from 'react'
import { useSelector } from 'react-redux'
import { Field, Utils } from '@pbt/pbt-ui-components'

import EnumSelect from '~/components/common/inputs/EnumSelect'
import { getDiagnosisStatus } from '~/store/reducers/constants'
import { DiagnoseLocation, DiagnosesState } from '~/types'

export interface DiagnoseStatusSelectProps {
  currentItem: DiagnoseLocation
  diagnoseData: DiagnosesState
  disabled?: boolean
  onChange?: (id: string, payload: DiagnosesState) => void
}

const DiagnoseStatusSelect = ({
  diagnoseData,
  currentItem,
  disabled,
  onChange,
}: DiagnoseStatusSelectProps) => {
  const DiagnosisStatus = useSelector(getDiagnosisStatus)

  const setStatus = (item: DiagnoseLocation, statusId: string) => {
    const payload = { ...diagnoseData }
    if (diagnoseData.locations.length) {
      payload.locations = Utils.updateById(
        { ...item, statusId },
        payload.locations,
      )
    } else {
      payload.statusId = statusId
    }

    if (onChange) {
      onChange(diagnoseData.id, payload)
    }
  }

  return (
    <EnumSelect
      accent
      Constant={DiagnosisStatus}
      disabled={disabled}
      field={
        {
          value: currentItem.statusId,
          set: ({ target: { value } }) => setStatus(currentItem, value),
        } as Field
      }
    />
  )
}

export default DiagnoseStatusSelect
