import { Constant } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'

export enum MigrationExceptionDictionaryScope {
  PIMS = 'PIMS',
  SESSION = 'SESSION',
  GENERIC = 'GENERIC',
}

export enum MigrationExceptionField {
  ACTIVE = 'ACTIVE',
  ADDED_DATE = 'ADDED_DATE',
  ADDRESS_STATE = 'ADDRESS_STATE',
  AGE_MAXIMUM = 'AGE_MAXIMUM',
  AGE_MINIMUM = 'AGE_MINIMUM',
  AMOUNT_PAID = 'AMOUNT_PAID',
  APPOINTMENT_ROLE = 'APPOINTMENT_ROLE',
  APPROVED_DATE = 'APPROVED_DATE',
  BALANCE = 'BALANCE',
  BILLABLE_ENTITY_TYPE = 'BILLABLE_ENTITY_TYPE',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  BODY_CONDITION_SCORE = 'BODY_CONDITION_SCORE',
  BREED = 'BREED',
  BUCKET = 'BUCKET',
  BUSINESS_SCOPED_DATA = 'BUSINESS_SCOPED_DATA',
  CAPILLARY_REFILL_TIME = 'CAPILLARY_REFILL_TIME',
  CARD_NUMBER_DIGITS = 'CARD_NUMBER_DIGITS',
  CATALOG_CODE = 'CATALOG_CODE',
  CATALOG_PRICE = 'CATALOG_PRICE',
  CATEGORY = 'CATEGORY',
  CLIENT_ID = 'CLIENT_ID',
  COLOR = 'COLOR',
  CONTACT_METHOD = 'CONTACT_METHOD',
  COST = 'COST',
  COUNT = 'COUNT',
  COUNTRY = 'COUNTRY',
  CREATED_BY_ID = 'CREATED_BY_ID',
  CREATION_DATE = 'CREATION_DATE',
  DATE = 'DATE',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  DECEASED = 'DECEASED',
  DECEASED_DATE = 'DECEASED_DATE',
  DELETED = 'DELETED',
  DELETED_DATE = 'DELETED_DATE',
  DIAGNOSIS = 'DIAGNOSIS',
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  DISPENSING_FEE = 'DISPENSING_FEE',
  DUE_DATE = 'DUE_DATE',
  DURATION = 'DURATION',
  DYSPNEA = 'DYSPNEA',
  EMAIL = 'EMAIL',
  END_TIME = 'END_TIME',
  EVENT_STATE = 'EVENT_STATE',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  FEAR_ANXIETY = 'FEAR_ANXIETY',
  FILE_EXTENSION = 'FILE_EXTENSION',
  FIRST_VISIT_DATE = 'FIRST_VISIT_DATE',
  GENDER = 'GENDER',
  GLOBAL_CATALOG_ITEM_CODE = 'GLOBAL_CATALOG_ITEM_CODE',
  GLOBAL_CATALOG_VARIATION_CODE = 'GLOBAL_CATALOG_VARIATION_CODE',
  HAS_DISPENSING_FEE = 'HAS_DISPENSING_FEE',
  HAS_PATIENTS = 'HAS_PATIENTS',
  HEART_RATE = 'HEART_RATE',
  HIDE = 'HIDE',
  HISTORY_ADDED_DATE = 'HISTORY_ADDED_DATE',
  ID = 'ID',
  INVOICE_ID = 'INVOICE_ID',
  IS_AFTER_HOURS = 'IS_AFTER_HOURS',
  IS_DECLINED = 'IS_DECLINED',
  IS_DISCOUNT = 'IS_DISCOUNT',
  IS_DISCOUNT_ALLOWED = 'IS_DISCOUNT_ALLOWED',
  IS_DOCTOR = 'IS_DOCTOR',
  IS_LOCKED = 'IS_LOCKED',
  IS_OPEN = 'IS_OPEN',
  IS_PAID = 'IS_PAID',
  IS_POSTED = 'IS_POSTED',
  IS_PREPAYMENT = 'IS_PREPAYMENT',
  IS_REFUNDED = 'IS_REFUNDED',
  IS_RETURNED = 'IS_RETURNED',
  IS_SIGNED = 'IS_SIGNED',
  IS_TAXABLE = 'IS_TAXABLE',
  IS_WRITE_OFF = 'IS_WRITE_OFF',
  LAST_PAID_AMOUNT = 'LAST_PAID_AMOUNT',
  LAST_PAID_DATE = 'LAST_PAID_DATE',
  LOCKED_DATE = 'LOCKED_DATE',
  MARKUP_PERCENT = 'MARKUP_PERCENT',
  MENTATION_ID = 'MENTATION_ID',
  MIGRATED_BENEFIT_ID = 'MIGRATED_BENEFIT_ID',
  MIGRATED_EXTRA_PLAN_ID = 'MIGRATED_EXTRA_PLAN_ID',
  MIGRATED_EXTRA_PLAN_VERSION_ID = 'MIGRATED_EXTRA_PLAN_VERSION_ID',
  MIGRATED_PLAN_ID = 'MIGRATED_PLAN_ID',
  MIGRATED_PLAN_VERSION_ID = 'MIGRATED_PLAN_VERSION_ID',
  MIN_CHARGE = 'MIN_CHARGE',
  MODIFICATION_DATE = 'MODIFICATION_DATE',
  MODIFIED_BY_ID = 'MODIFIED_BY_ID',
  MUCOUS_MEMBRANE_COLOR = 'MUCOUS_MEMBRANE_COLOR',
  MUCOUS_MEMBRANE_MOISTURE = 'MUCOUS_MEMBRANE_MOISTURE',
  NAME = 'NAME',
  NEXT_PAYMENT_DATE = 'NEXT_PAYMENT_DATE',
  NO_CHARGE = 'NO_CHARGE',
  PAID_AMOUNT = 'PAID_AMOUNT',
  PAIN_SCORE = 'PAIN_SCORE',
  PATIENT_ID = 'PATIENT_ID',
  PAYMENT_CARD_TYPE = 'PAYMENT_CARD_TYPE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  PER_PACKAGE_AMOUNT = 'PER_PACKAGE_AMOUNT',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PLAN_CODE = 'PLAN_CODE',
  POSTED_BY = 'POSTED_BY',
  POSTED_DATE = 'POSTED_DATE',
  PRICE = 'PRICE',
  PRICE_STRUCTURE = 'PRICE_STRUCTURE',
  PRICE_UNIT = 'PRICE_UNIT',
  PRICE_UNIT_SIZE = 'PRICE_UNIT_SIZE',
  PROCEDURE = 'PROCEDURE',
  PROPERTIES_STRUCTURE = 'PROPERTIES_STRUCTURE',
  PULSE = 'PULSE',
  QUANTITY = 'QUANTITY',
  RABIES_TAG_EXPIRATION = 'RABIES_TAG_EXPIRATION',
  RESPIRATION = 'RESPIRATION',
  ROLE = 'ROLE',
  SEND_REMINDERS = 'SEND_REMINDERS',
  SEQUENCE_NUMBER = 'SEQUENCE_NUMBER',
  SERVICE_FEE = 'SERVICE_FEE',
  SIGNED_DATE = 'SIGNED_DATE',
  SKIN_TURGOR = 'SKIN_TURGOR',
  SPAYED_NEUTERED_STATUS = 'SPAYED_NEUTERED_STATUS',
  SPECIES = 'SPECIES',
  START_TIME = 'START_TIME',
  STATE = 'STATE',
  STRIPE_CLIENT_ID = 'STRIPE_CLIENT_ID',
  SUBTYPE = 'SUBTYPE',
  SUB_CATEGORY = 'SUB_CATEGORY',
  SUB_TOTAL = 'SUB_TOTAL',
  SYNTHETIC = 'SYNTHETIC',
  TAG = 'TAG',
  TAX = 'TAX',
  TEMPERATURE = 'TEMPERATURE',
  TERM = 'TERM',
  TIME_UNIT = 'TIME_UNIT',
  TIME_UNIT_SIZE = 'TIME_UNIT_SIZE',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  TYPE = 'TYPE',
  VARIATION_DELIVERY_METHOD = 'VARIATION_DELIVERY_METHOD',
  VARIATION_FORM = 'VARIATION_FORM',
  VARIATION_PACKAGE_TYPE = 'VARIATION_PACKAGE_TYPE',
  VARIATION_PACKAGE_UNIT = 'VARIATION_PACKAGE_UNIT',
  VARIATION_STRENGTH_COLOR = 'VARIATION_STRENGTH_COLOR',
  VARIATION_STRUCTURE = 'VARIATION_STRUCTURE',
  VARIATION_UNIT = 'VARIATION_UNIT',
  VOIDED = 'VOIDED',
  WEIGHT = 'WEIGHT',
  WEIGHT_MAXIMUM = 'WEIGHT_MAXIMUM',
  WEIGHT_MINIMUM = 'WEIGHT_MINIMUM',
  WRITE_OFF_AMOUNT = 'WRITE_OFF_AMOUNT',
  WRITE_OFF_DATE = 'WRITE_OFF_DATE',
  _RECORD_ = '_RECORD_',
}

export enum MigrationExceptionEntity {
  ACCOUNT = 'ACCOUNT',
  ACCOUNTING_PERIOD_END_OF_DAY = 'ACCOUNTING_PERIOD_END_OF_DAY',
  ACCOUNTING_PERIOD_END_OF_MONTH = 'ACCOUNTING_PERIOD_END_OF_MONTH',
  ACCOUNTING_PERIOD_END_OF_YEAR = 'ACCOUNTING_PERIOD_END_OF_YEAR',
  APPOINTMENT = 'APPOINTMENT',
  APPOINTMENT_USERS = 'APPOINTMENT_USERS',
  APPOINTMENTS_METADATA_PA = 'APPOINTMENTS_METADATA_PA',
  BALANCE = 'BALANCE',
  BALANCE_HISTORY = 'BALANCE_HISTORY',
  BALANCE_HISTORY_PA = 'BALANCE_HISTORY_PA',
  BALANCE_STREAM_HISTORY = 'BALANCE_STREAM_HISTORY',
  BALANCE_STREAM_HISTORY_PA = 'BALANCE_STREAM_HISTORY_PA',
  BALANCE_STREAM_HISTORY_PIMS = 'BALANCE_STREAM_HISTORY_PIMS',
  CATALOG_FEATURE_CODES_HISTORY_PA = 'CATALOG_FEATURE_CODES_HISTORY_PA',
  CLIENT = 'CLIENT',
  CLIENT_FEATURE_CODES_HISTORY = 'CLIENT_FEATURE_CODES_HISTORY',
  CLIENT_FEATURE_CODES_HISTORY_PA = 'CLIENT_FEATURE_CODES_HISTORY_PA',
  CLIENT_HISTORY_PA = 'CLIENT_HISTORY_PA',
  CLINIC_INFO_PA = 'CLINIC_INFO_PA',
  COMPANY_HISTORY_PA = 'COMPANY_HISTORY_PA',
  CONTACT = 'CONTACT',
  CONTACT_HISTORY_PA = 'CONTACT_HISTORY_PA',
  DIAGNOSIS = 'DIAGNOSIS',
  DIAGNOSIS_HISTORY_PA = 'DIAGNOSIS_HISTORY_PA',
  ENTRY = 'ENTRY',
  ESTIMATE_HISTORY = 'ESTIMATE_HISTORY',
  ESTIMATE_HISTORY_PA = 'ESTIMATE_HISTORY_PA',
  ESTIMATE_LINE_HISTORY = 'ESTIMATE_LINE_HISTORY',
  ESTIMATE_LINE_HISTORY_PA = 'ESTIMATE_LINE_HISTORY_PA',
  EVENT = 'EVENT',
  EVENT_HISTORY_PA = 'EVENT_HISTORY_PA',
  EVENT_UNMAPPED = 'EVENT_UNMAPPED',
  EVENT_UNMAPPED_HISTORY_PA = 'EVENT_UNMAPPED_HISTORY_PA',
  HISTORY = 'HISTORY',
  HISTORY_PA = 'HISTORY_PA',
  INVENTORY = 'INVENTORY',
  INVENTORY_HISTORY = 'INVENTORY_HISTORY',
  INVENTORY_ITEM_HISTORY = 'INVENTORY_ITEM_HISTORY',
  INVENTORY_ITEM_HISTORY_PA = 'INVENTORY_ITEM_HISTORY_PA',
  INVENTORY_ORDER_HISTORY = 'INVENTORY_ORDER_HISTORY',
  INVENTORY_ORDER_HISTORY_PA = 'INVENTORY_ORDER_HISTORY_PA',
  INVENTORY_ORDER_LINE_HISTORY = 'INVENTORY_ORDER_LINE_HISTORY',
  INVENTORY_ORDER_LINE_HISTORY_PA = 'INVENTORY_ORDER_LINE_HISTORY_PA',
  INVENTORY_PROPERTIES_HISTORY = 'INVENTORY_PROPERTIES_HISTORY',
  INVOICE_HISTORY = 'INVOICE_HISTORY',
  INVOICE_HISTORY_PA = 'INVOICE_HISTORY_PA',
  INVOICE_HISTORY_PG = 'INVOICE_HISTORY_PG',
  INVOICE_LINE_ITEM_HISTORY = 'INVOICE_LINE_ITEM_HISTORY',
  INVOICE_LINE_ITEM_HISTORY_PA = 'INVOICE_LINE_ITEM_HISTORY_PA',
  INVOICE_LINE_ITEM_HISTORY_PG = 'INVOICE_LINE_ITEM_HISTORY_PG',
  ITEM = 'ITEM',
  ITEM_VARIATION = 'ITEM_VARIATION',
  LAB_HISTORY_PA = 'LAB_HISTORY_PA',
  LAB_LINE_HISTORY_PA = 'LAB_LINE_HISTORY_PA',
  LAB_TEST = 'LAB_TEST',
  PATIENT = 'PATIENT',
  PATIENT_ATTACHMENT = 'PATIENT_ATTACHMENT',
  PATIENT_FEATURE_CODES_HISTORY = 'PATIENT_FEATURE_CODES_HISTORY',
  PATIENT_FEATURE_CODES_HISTORY_PA = 'PATIENT_FEATURE_CODES_HISTORY_PA',
  PATIENT_HISTORY_PA = 'PATIENT_HISTORY_PA',
  PAYMENT = 'PAYMENT',
  PAYMENT_HISTORY = 'PAYMENT_HISTORY',
  PAYMENT_HISTORY_PA = 'PAYMENT_HISTORY_PA',
  PAYMENT_HISTORY_PG = 'PAYMENT_HISTORY_PG',
  PRESCRIPTION = 'PRESCRIPTION',
  PRESCRIPTION_HISTORY = 'PRESCRIPTION_HISTORY',
  PRESCRIPTION_HISTORY_PA = 'PRESCRIPTION_HISTORY_PA',
  PRICE = 'PRICE',
  PROCEDURE = 'PROCEDURE',
  REFERRAL_HISTORY = 'REFERRAL_HISTORY',
  REFERRAL_HISTORY_PA = 'REFERRAL_HISTORY_PA',
  REMINDER = 'REMINDER',
  REMINDER_HISTORY_PA = 'REMINDER_HISTORY_PA',
  STRIPE_MAPPINGS = 'STRIPE_MAPPINGS',
  TASK_HISTORY = 'TASK_HISTORY',
  TASK_HISTORY_PA = 'TASK_HISTORY_PA',
  TIME_CARD_HISTORY_PA = 'TIME_CARD_HISTORY_PA',
  TRANSACTION_STREAM_HISTORY_PA = 'TRANSACTION_STREAM_HISTORY_PA',
  USER = 'USER',
  USER_HISTORY_PA = 'USER_HISTORY_PA',
  VACCINATION = 'VACCINATION',
  VACCINATION_HISTORY_PA = 'VACCINATION_HISTORY_PA',
  VISIT_HISTORY = 'VISIT_HISTORY',
  VISIT_HISTORY_PA = 'VISIT_HISTORY_PA',
  VITAL = 'VITAL',
  VITAL_HISTORY_PA = 'VITAL_HISTORY_PA',
  WPLAN_FEATURE_USE_ACTIVE = 'WPLAN_FEATURE_USE_ACTIVE',
  WPLAN_MARKETING_SUMMARY = 'WPLAN_MARKETING_SUMMARY',
}

export type MigrationExceptionEntityToField = {
  constantName: string
  entity: string
  field: string
  humanReadableName: string
  supportedTypeIds?: string[]
}

export type MigrationException = {
  dictionaryScope: MigrationExceptionDictionaryScope
  entityName: MigrationExceptionEntity
  externalValue1: string
  externalValue2: string
  fieldName: MigrationExceptionField
  id: string
  importSessionId: string
  pimsId: string
  resolved: boolean
  rhapsodyValue1: string
  rhapsodyValue2: string
}

export type MigrationExceptionMap = Record<string, MigrationException>

const rhapsodyFieldUiLabel = (
  entity: MigrationExceptionEntity,
  field: MigrationExceptionField,
  constantName: string,
  humanReadableName: string,
  supportedTypeIds?: string[],
): MigrationExceptionEntityToField => ({
  entity,
  field,
  constantName,
  humanReadableName,
  supportedTypeIds,
})

enum EventTypeIds {
  APPOINTMENT_EVENT_TYPE_ID = '1',
  BUSY_EVENT_TYPE_ID = '2',
}

export const RhapsodyFieldUiLabels = {
  [MigrationExceptionEntity.CLIENT]: {
    [MigrationExceptionField.CONTACT_METHOD]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.CLIENT,
      MigrationExceptionField.CONTACT_METHOD,
      'ContactMethod',
      i18n.t(
        'Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.CLIENT_CONTACT_METHODS',
      ),
    ),
    [MigrationExceptionField.TAG]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.CLIENT,
      MigrationExceptionField.TAG,
      'Tag',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.CLIENT_TAGS'),
    ),
  },
  [MigrationExceptionEntity.USER]: {
    [MigrationExceptionField.ROLE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.USER,
      MigrationExceptionField.ROLE,
      'Role',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.USER_ROLES'),
    ),
  },
  [MigrationExceptionEntity.PATIENT]: {
    [MigrationExceptionField.SPECIES]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.PATIENT,
      MigrationExceptionField.SPECIES,
      'Species',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.SPECIES'),
    ),
    [MigrationExceptionField.BREED]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.PATIENT,
      MigrationExceptionField.BREED,
      'Breed',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.BREEDS'),
    ),
    [MigrationExceptionField.GENDER]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.PATIENT,
      MigrationExceptionField.GENDER,
      'Gender',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.GENDERS'),
    ),
    [MigrationExceptionField.SPAYED_NEUTERED_STATUS]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.PATIENT,
      MigrationExceptionField.SPAYED_NEUTERED_STATUS,
      'SpayedNeuteredStatus',
      i18n.t(
        'Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.SPAYED_NEUTERED_STATUSES',
      ),
    ),
  },
  [MigrationExceptionEntity.APPOINTMENT]: {
    [MigrationExceptionField.TYPE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.APPOINTMENT,
      MigrationExceptionField.TYPE,
      'EventType',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.APPOINTMENT_TYPES'),
    ),
    [MigrationExceptionField.SUBTYPE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.APPOINTMENT,
      MigrationExceptionField.SUBTYPE,
      'EventType.Appointment',
      i18n.t(
        'Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.APPOINTMENT_SUB_TYPES',
      ),
    ),
    [MigrationExceptionField.STATE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.APPOINTMENT,
      MigrationExceptionField.STATE,
      'EventState',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.APPOINTMENT_STATES'),
    ),
    [MigrationExceptionField.ROLE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.APPOINTMENT,
      MigrationExceptionField.ROLE,
      'EventPersonResponsibility',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.APPOINTMENT_ROLES'),
    ),
  },
  [MigrationExceptionEntity.REMINDER]: {
    [MigrationExceptionField.TYPE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.REMINDER,
      MigrationExceptionField.TYPE,
      'EventType',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.REMINDER_TYPES'),
    ),
    [MigrationExceptionField.SUBTYPE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.REMINDER,
      MigrationExceptionField.SUBTYPE,
      'EventType.Reminder',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.REMINDER_SUB_TYPES'),
    ),
  },
  [MigrationExceptionEntity.EVENT]: {
    [MigrationExceptionField.STATE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.REMINDER,
      MigrationExceptionField.STATE,
      'EventState',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.EVENT_STATES'),
    ),
    // We support only Appointment and busy types of events
    [MigrationExceptionField.SUBTYPE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.REMINDER,
      MigrationExceptionField.SUBTYPE,
      'EventType',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.EVENT_SUBTYPES'),
      [EventTypeIds.APPOINTMENT_EVENT_TYPE_ID, EventTypeIds.BUSY_EVENT_TYPE_ID],
    ),
  },
  [MigrationExceptionEntity.VITAL]: {
    [MigrationExceptionField.MUCOUS_MEMBRANE_MOISTURE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.VITAL,
      MigrationExceptionField.MUCOUS_MEMBRANE_MOISTURE,
      'MucousMembraneMoistures',
      i18n.t(
        'Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.MUCOUS_MEMBRANE_MOISTURES',
      ),
    ),
    [MigrationExceptionField.MUCOUS_MEMBRANE_COLOR]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.VITAL,
      MigrationExceptionField.MUCOUS_MEMBRANE_COLOR,
      'MucousMembraneColors',
      i18n.t(
        'Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.MUCOUS_MEMBRANE_COLORS',
      ),
    ),
    [MigrationExceptionField.MENTATION_ID]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.VITAL,
      MigrationExceptionField.MENTATION_ID,
      'MentationTypes',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.MENTATION'),
    ),
    [MigrationExceptionField.PULSE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.VITAL,
      MigrationExceptionField.PULSE,
      'PulseCharacterTypes',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.PULSE'),
    ),
    [MigrationExceptionField.SKIN_TURGOR]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.VITAL,
      MigrationExceptionField.SKIN_TURGOR,
      'SkinTurgors',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.SKIN_TURGORS'),
    ),
  },
  [MigrationExceptionEntity.CONTACT]: {
    [MigrationExceptionField.TYPE]: rhapsodyFieldUiLabel(
      MigrationExceptionEntity.CONTACT,
      MigrationExceptionField.TYPE,
      'ContactTypes',
      i18n.t('Businesses:MIGRATION.EXCEPTIONS_FIELD_LABELS.CONTACT_TYPES'),
    ),
  },
}

export type MigrationConstant = Constant & {
  subTypes: string[]
}

export enum MigrationExceptionColumnType {
  STRING = 'STRING',
  SELECT = 'SELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}

export type MigrationExceptionExternalColumn = {
  id: string
  name: string
  type: string
}

export type MigrationExceptionProposedColumn =
  MigrationExceptionExternalColumn & {
    groupColumn?: string
    isGroupColumnSubTypes?: boolean
    options?:
      | MigrationConstant[]
      | string[]
      | Record<string, MigrationConstant[]>
  }
