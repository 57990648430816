import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Chip, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { ProblemType } from '~/constants/problems'
import {
  getDiagnosisProblemStates,
  getFindingProblemStates,
} from '~/store/reducers/constants'
import { getIsLoadingLogs, getProblemsLogsMap } from '~/store/reducers/problems'
import { Problem, ProblemStates } from '~/types'

const useStyles = makeStyles(
  (theme) => ({
    crossOut: {
      textDecoration: 'line-through',
    },
    root: {
      margin: theme.spacing(0.5, 0.5, 0.5, 0.5),
    },
    disabledText: {
      color: theme.colors.tabLabel,
    },
  }),
  { name: 'Chip' },
)

interface ChipProps {
  addLog: (problem: Problem) => void
  problem: Problem
}

const ChipFindingsAndDiagnoses = ({ problem, addLog }: ChipProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')
  const problemLogs = useSelector(getProblemsLogsMap) || {}
  const isLoading = useSelector(getIsLoadingLogs)
  const DiagnosisStates = useSelector(getDiagnosisProblemStates)
  const FindingsStates = useSelector(getFindingProblemStates)
  const statusConstants =
    problem.type === ProblemType.DIAGNOSIS ? DiagnosisStates : FindingsStates
  const thisProblemLog = problem?.logKey && problemLogs?.[problem.logKey]
  const problemState = thisProblemLog
    ? Utils.findById(thisProblemLog?.entity.stateId, statusConstants)
    : { name: ProblemStates.DEFAULT }

  type ChipStylesMapType = {
    crossOut: boolean
    disabledText: boolean
    variant: 'outlined' | 'filled'
  }

  const chipStylesMap: Record<string, ChipStylesMapType> = {
    [ProblemStates.REMOVED]: {
      disabledText: true,
      variant: 'outlined',
      crossOut: false,
    },
    [ProblemStates.RESOLVED]: {
      disabledText: true,
      variant: 'outlined',
      crossOut: true,
    },
    [ProblemStates.RULED_OUT]: {
      disabledText: true,
      variant: 'outlined',
      crossOut: true,
    },
    [ProblemStates.DEFAULT]: {
      disabledText: true,
      variant: 'outlined',
      crossOut: false,
    },
    [ProblemStates.SUSPECTED]: {
      disabledText: false,
      variant: 'filled',
      crossOut: false,
    },
    [ProblemStates.CONFIRMED]: {
      disabledText: false,
      variant: 'filled',
      crossOut: false,
    },
    [ProblemStates.CHRONIC]: {
      disabledText: false,
      variant: 'filled',
      crossOut: false,
    },
    [ProblemStates.ADDED]: {
      disabledText: false,
      variant: 'filled',
      crossOut: false,
    },
  }

  const translatedTooltip = {
    [ProblemStates.REMOVED]: t('Soap:PROBLEMS.STATES.REMOVED'),
    [ProblemStates.RESOLVED]: t('Soap:PROBLEMS.STATES.RESOLVED'),
    [ProblemStates.RULED_OUT]: t('Soap:PROBLEMS.STATES.RULED_OUT'),
    [ProblemStates.SUSPECTED]: t('Soap:PROBLEMS.STATES.SUSPECTED'),
    [ProblemStates.CONFIRMED]: t('Soap:PROBLEMS.STATES.CONFIRMED'),
    [ProblemStates.CHRONIC]: t('Soap:PROBLEMS.STATES.CHRONIC'),
    [ProblemStates.ADDED]: t('Soap:PROBLEMS.STATES.ADDED'),
    [ProblemStates.DEFAULT]: '',
  }

  if (problem.removed) {
    return null
  }

  return (
    <Tooltip placement="top" title={translatedTooltip[problemState.name]}>
      <Chip
        className={classNames(classes.root, {
          [classes.crossOut]: chipStylesMap[problemState?.name].crossOut,
          [classes.disabledText]:
            chipStylesMap[problemState?.name].disabledText,
        })}
        disabled={isLoading}
        key={problem.id}
        label={LanguageUtils.getTranslatedFieldName(problem)}
        variant={chipStylesMap[problemState?.name].variant}
        onClick={() => addLog(problem)}
      />
    </Tooltip>
  )
}

export default ChipFindingsAndDiagnoses
