import { ApolloError } from '@apollo/client'
import * as R from 'ramda'
import { uniq } from 'ramda'
import { ApiError, Defaults, Nil } from '@pbt/pbt-ui-components'
import { GraphQLError } from '@pbt/pbt-ui-components/src/utils/errorTypes'

import ErrorTypes from '~/constants/apiErrorTypes'
import { CustomErrorCodes } from '~/constants/customErrorCodes'

export const NetworkErrorMessage = 'Network Error'
export const TimeoutErrorMessage = 'timeout of 0ms exceeded'

export const extractFirstAmericanErrorMessage = (errorString: string) => {
  const regex1 = /"errorMessages":\["([^"]+)"(?:,|])/ // format 1: "errorMessages":["..."]
  const regex2 = /errors=\[([^\]]+)\]/ // format 2: errors=[...]
  if (!errorString) {
    return null
  }

  let match = errorString.match(regex2)
  if (match && match[1]) {
    return match[1]
  }

  match = errorString.match(regex1)
  if (match && match[1]) {
    return match[1]
  }

  return null
}

export const getApolloErrorMessage = (error: ApolloError): string => {
  if (error.networkError) {
    return `Network error: ${error.networkError.message}`
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return uniq(error.graphQLErrors.map(({ message }) => message)).join(', ')
  }

  return error.message || 'An unexpected error occurred.'
}

export const getErrorMessage = (
  error?: ApiError | string | null,
  defaultMessage?: string,
): string =>
  extractFirstAmericanErrorMessage(JSON.stringify(error)) ||
  R.path(['response', 'data', 'error', 'message'], error) ||
  R.path(['response', 'data', 'error'], error) ||
  R.path(['response', 'data', 'description'], error) ||
  R.path(['message'], error) ||
  R.path(['originalMessage'], error) ||
  error?.toString?.() ||
  defaultMessage ||
  Defaults.DEFAULT_RESPONSE_ERROR_MESSAGE

export const getErrorMessageDescription = (
  error?: ApiError | string | null,
  defaultMessage?: string,
): string =>
  R.path(['response', 'data', 'description'], error) ||
  getErrorMessage(error, defaultMessage)

export function getServerValidationError(error: ApiError): string | null {
  const { data = {} } = error?.response || {}
  return R.includes(error?.response?.status, [409, 410])
    ? data.message || data.description || data.error?.message
    : null
}

export function getServerValidationErrorType(error: ApiError): string | null {
  const { data = {} } = error?.response || {}
  return R.includes(error?.response?.status, [409, 410]) ? data.type : null
}

export function removeServerErrorPrefix(
  errorMessage: string,
): string | undefined {
  return R.last(errorMessage.split(/Code \d+:(\s\w*[:])?/))?.trim()
}

export const isRocketChatUnauthorized = (error: ApiError): boolean =>
  R.path(['response', 'data', 'descriptionId'], error) ===
  CustomErrorCodes.ROCKET_CHAT_UNAUTHORIZED
export const isWorkingOutsidePracticeHours = (error: ApiError): boolean =>
  R.path(['response', 'data', 'descriptionId'], error) ===
  CustomErrorCodes.WORKING_OUTSIDE_PRACTICE_HOURS
export const isUserNotFound = (error: ApiError): boolean =>
  R.path(['response', 'data', 'descriptionId'], error) ===
  CustomErrorCodes.USER_NOT_FOUND

export const isNetworkError = (error: { originalMessage: string }): boolean =>
  error?.originalMessage === NetworkErrorMessage ||
  error?.originalMessage === TimeoutErrorMessage

const NoRolesErrorPrefix = 'No any role'
export const detectAPIErrorType = (
  responseBody: ApiError['responseBody'] = {},
): string | Nil => {
  const description = responseBody.description || ''
  if (description.startsWith(NoRolesErrorPrefix)) {
    return ErrorTypes.BUSINESS_ROLES_MISSING
  }

  return responseBody.type || responseBody.error?.type
}

export const hasSomeErrorMessage = (errors: string[], message: string) =>
  errors.some((error) => message.includes(error))

export const getIsFinalizedError = (errorType: string | Nil) => {
  if (!errorType) {
    return false
  }
  return [
    ErrorTypes.SOAP_IS_FINALIZED,
    ErrorTypes.FINALIZED_SOAP_ITEM_MODIFICATION,
  ].includes(errorType)
}

export const getIsCloneError = (errorType: string | Nil) => {
  if (!errorType) {
    return false
  }
  return [
    ErrorTypes.ESTIMATE_HAS_NO_ACTIVE_ITEMS,
    ErrorTypes.ESTIMATE_HAS_SOME_INACTIVE_ITEMS,
    ErrorTypes.ESTIMATE_HAS_BEEN_ASSIGNED_TO_ANOTHER_EVENT,
  ].includes(errorType)
}

/**
 * Only for test purposes
 */
export const throwGraphQLError = (
  message: string,
  type: string,
  status = 409,
) => {
  throw new GraphQLError(
    {
      message,
      locations: [],
      extensions: {
        type,
        status,
        message,
      },
    },
    {
      definitions: [
        {
          operation: 'OperationName',
          name: {
            value: 'Definition value',
          },
        },
      ],
    },
    {},
  )
}
