import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { TextWithTooltip } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { getSoapBusinessId, getSoapId } from '~/store/reducers/soap'
import {
  fetchSoapActiveTemplate,
  fetchUncoveredSoapTemplateWidgets,
  getSoapActiveTemplateTabs,
  getSoapUncoveredWidgetIds,
} from '~/store/reducers/soapV2'
import { SoapTemplateTab } from '~/types'
import { convertToKebabCase } from '~/utils'

import { useGetReminderInitialFilters } from '../reminders/utils/useGetReminderInitialFilters'
import { useIsSoapCustomizationEnabled } from './useIsSoapCustomizationEnabled'

type UseStylesProps = {
  selectedTab: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      color: theme.colors.alertWarning,
      fontSize: '1.6rem',
    },
    label: {
      color: (props: UseStylesProps) =>
        props.selectedTab
          ? theme.colors.tabSelected
          : theme.colors.secondaryText,
      display: 'flex',
      alignItems: 'flex-start',
    },
    tooltip: {
      minWidth: 'fit-content',
      whiteSpace: 'nowrap',
      border: `2px solid ${theme.colors.alertWarning}`,
      '&::before': {
        borderTopColor: theme.colors.alertWarning,
        marginLeft: 5,
      },
      '&::after': {
        marginLeft: 8,
      },
    },
  }),
  { name: 'UncoveredTabLabel' },
)

interface UncoveredTabLabelProps {
  label: string
}

const UncoveredTabLabel = ({ label }: UncoveredTabLabelProps) => {
  const { tabName: urlTabName } = useParams()

  const selectedTab = urlTabName === convertToKebabCase(label)

  const classes = useStyles({ selectedTab })
  const { t } = useTranslation('Tooltips')

  return (
    <TextWithTooltip
      strong
      Icon={WarnAlert}
      TooltipProps={{
        classes: {
          tooltip: classes.tooltip,
        },
      }}
      className={classes.label}
      classes={{
        infoIcon: classes.icon,
      }}
      iconPlacement="left"
      tooltipText={t('Tooltips:SOAP_UNCOVERED_CONTENT')}
      variant="body2"
    >
      {label}
    </TextWithTooltip>
  )
}

const useUncoveredSoapWidgets = ({ redirect }: { redirect: boolean }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('Common')

  const uncoveredWidgets = useSelector(getSoapUncoveredWidgetIds)
  const soapId = useSelector(getSoapId)
  const tabs = useSelector(getSoapActiveTemplateTabs)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  const reminderStateIds = useGetReminderInitialFilters()

  useEffect(() => {
    if (!soapId) {
      return
    }

    if (redirect ? isSoapCustomizationEnabled && !tabs?.length : true) {
      dispatch(
        fetchUncoveredSoapTemplateWidgets({
          searchFilter: {
            soapId,
            stateIds: reminderStateIds,
          },
        }),
      )
    }
  }, [soapId])

  const label = t('Common:UNCOVERED_CONTENT')
  const uncoveredTab: SoapTemplateTab | undefined = uncoveredWidgets?.length
    ? {
        id: 'UNCOVERED_TAB',
        label,
        name: label,
        order: (tabs?.length ?? 0) + 1,
        url: convertToKebabCase(label),
        tabElement: <UncoveredTabLabel label={label} />,
        widgets: uncoveredWidgets.map((uncoveredWidget, index) => ({
          active: true,
          id: `${uncoveredWidget}_null`,
          marketplaceId: null,
          order: index + 1,
          soapWidgetId: uncoveredWidget,
        })),
      }
    : undefined

  return uncoveredTab
}

type UseSoapTemplateTabs = ({
  redirect,
}: {
  redirect: boolean
}) => SoapTemplateTab[] | undefined

const useGetSoapActiveTemplateTabs: UseSoapTemplateTabs = ({ redirect }) => {
  const dispatch = useDispatch()

  const soapBusinessId = useSelector(getSoapBusinessId)
  const tabs = useSelector(getSoapActiveTemplateTabs)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  useEffect(() => {
    if (!soapBusinessId) {
      return
    }

    if (redirect ? isSoapCustomizationEnabled && !tabs?.length : true) {
      dispatch(fetchSoapActiveTemplate({ businessId: soapBusinessId }))
    }
  }, [
    soapBusinessId,
    ...(redirect ? [tabs?.length, isSoapCustomizationEnabled] : []),
  ])

  return tabs?.map((tab) => ({
    ...tab,
    tabElement: (
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'inline-block',
          width: '-webkit-fill-available',
          whiteSpace: 'nowrap',
        }}
      >
        {tab.name}
      </span>
    ),
  }))
}

export const useSoapTemplateTabs: UseSoapTemplateTabs = ({ redirect }) => {
  const uncoveredWidgets = useUncoveredSoapWidgets({ redirect })
  const coveredTabs = useGetSoapActiveTemplateTabs({ redirect })

  const soapTabs =
    uncoveredWidgets && coveredTabs
      ? [...coveredTabs, uncoveredWidgets]
      : coveredTabs

  return soapTabs
}
