import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'
import { Download as DownloadIcon } from '@pbt/pbt-ui-components/src/icons'

import UploadButton from '~/components/common/buttons/UploadButton'
import DialogNames from '~/constants/DialogNames'
import { MigrationSteps } from '~/constants/migrationV2'
import { BaseMigrationProps } from '~/types/entities/migrationV2'
import useDialog from '~/utils/useDialog'

const useStyles = makeStyles(
  () => ({
    icon: {
      verticalAlign: 'middle',
    },
  }),
  { name: 'ContinuousMigration' },
)

const ContinuousMigration = ({ businessId }: BaseMigrationProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Businesses')
  const [openMigrationV2Dialog] = useDialog(DialogNames.MIGRATION_V2_DIALOG)

  const onSetupImportSession = () => {
    openMigrationV2Dialog({
      businessId,
      steps: [
        { migrationStep: MigrationSteps.SESSION_PICKER },
        { migrationStep: MigrationSteps.SESSION_OVERVIEW },
      ],
    })
  }

  const onImportData = () => {
    openMigrationV2Dialog({
      businessId,
      steps: [
        { migrationStep: MigrationSteps.SESSION_PICKER },
        { migrationStep: MigrationSteps.IMPORT_REQUEST_PICKER },
        { migrationStep: MigrationSteps.IMPORT_REQUEST_OVERVIEW },
      ],
    })
  }

  const onDataStandardization = () => {
    openMigrationV2Dialog({
      businessId,
      steps: [
        { migrationStep: MigrationSteps.SESSION_PICKER },
        { migrationStep: MigrationSteps.DATA_STANDARDIZATION },
      ],
    })
  }

  return (
    <Grid>
      <Text link fontSize="1.4rem" my={0.5} onClick={onSetupImportSession}>
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.SETUP_IMPORT_SESSION')}
      </Text>
      <Text link fontSize="1.4rem" my={0.5} onClick={onImportData}>
        <DownloadIcon className={classes.icon} />
        {t('Businesses:MIGRATION.IMPORT_DATA')}
      </Text>
      <UploadButton
        label={t('Businesses:MIGRATION.DATA_STANDARDIZATION')}
        onClick={onDataStandardization}
      />
    </Grid>
  )
}

export default ContinuousMigration
