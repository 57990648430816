import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as R from 'ramda'
import { Utils } from '@pbt/pbt-ui-components'

import { useIsSoapCustomizationEnabled } from '~/components/dashboard/soapV2/utils/useIsSoapCustomizationEnabled'
import FeatureToggle from '~/constants/featureToggle'
import { SOAPStep } from '~/constants/SOAPStates'
import {
  getFeatureToggle,
  getStaticSoapWidget,
} from '~/store/reducers/constants'
import { getSelectedOrders } from '~/store/reducers/orders'
import { getHasSoapProblemLogs } from '~/store/reducers/problems'
import { getSoapQuestionsWithWorkFlow } from '~/store/reducers/questions'
import {
  getAppointmentId,
  getDiagnosesState,
  getFindingsState,
  getShowAppointment,
  getShowDiffDx,
  getShowExamination,
  getShowToDo,
  getShowWrapUp,
  getVitalIds,
  isCurrentSOAPVitals,
} from '~/store/reducers/soap'
import { SoapTemplateTab, SoapWidgetName } from '~/types'

import { hasQuestionWithSelectedAnswer } from '../../../utils/questionUtils'
import Appointment from '../Appointment'
import DifferentialDiagnoses from '../DifferentialDiagnoses'
import Examination from '../Examination'
import Order from '../orders/Order'
import Problems from '../Problems'
import Questions from '../Questions'
import ToDo from '../ToDo'
import Vitals from '../Vitals'
import WrapUp from '../WrapUp'

type SoapComponent = {
  Component: React.JSXElementConstructor<any>
  name: string
  soapWidgetId?: string
  visible?: boolean
}

type UseSummarySteps = {
  expanded: boolean | undefined
  soapTabs?: SoapTemplateTab[]
}

export const useSummarySteps = ({ expanded, soapTabs }: UseSummarySteps) => {
  const { step = SOAPStep.APPOINTMENT, tabName: urlTabName } = useParams()

  const { t } = useTranslation(['Abbreviations', 'Common'])

  const findingsState = useSelector(getFindingsState)
  const diagnosesState = useSelector(getDiagnosesState)
  const ordersLog = useSelector(getSelectedOrders)
  const vitalIds = useSelector(getVitalIds)
  const showExamination = useSelector(getShowExamination)
  const showAppointment = useSelector(getShowAppointment)
  const showVitals = useSelector(isCurrentSOAPVitals)
  const showDiffDx = useSelector(getShowDiffDx)
  const isProblemWidgetFTEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PROBLEMS_SOAP_WIDGET),
  )
  const showProblems = useSelector(getHasSoapProblemLogs)
  const showWrapUp = useSelector(getShowWrapUp)
  const showToDo = useSelector(getShowToDo)
  const StaticSoapWidget = useSelector(getStaticSoapWidget)
  const soapQuestionsWithWorkFlow = useSelector(getSoapQuestionsWithWorkFlow)
  const appointmentId = useSelector(getAppointmentId)
  const isSoapCustomizationEnabled = useIsSoapCustomizationEnabled()

  const activeStep = step.toUpperCase() as SOAPStep
  const [expandedItems, setExpandedItems] = useState({ [activeStep]: true })
  const [selectedItem, setSelectedItem] = useState<SOAPStep>(activeStep)

  const showOrder = ordersLog.length > 0

  const showQuestions = Object.values(soapQuestionsWithWorkFlow).some(
    hasQuestionWithSelectedAnswer,
  )

  const components: Partial<Record<Partial<SOAPStep>, SoapComponent>> = {
    [SOAPStep.APPOINTMENT]: {
      name: appointmentId ? t('Common:APPOINTMENT_ONE') : t('Common:REASON'),
      Component: Appointment,
      visible: showAppointment,
    },
    [SOAPStep.QUESTIONS]: {
      name: t('Common:QUESTION_OTHER'),
      Component: Questions,
      soapWidgetId: Utils.findConstantIdByName(
        SoapWidgetName.QUESTIONS_WIDGET,
        StaticSoapWidget,
      ),
      visible: showQuestions,
    },
    [SOAPStep.VITALS]: {
      name: t('Common:VITALS'),
      Component: Vitals,
      soapWidgetId: Utils.findConstantIdByName(
        SoapWidgetName.VITALS_WIDGET,
        StaticSoapWidget,
      ),
      visible: showVitals,
    },
    [SOAPStep.PROBLEMS]: {
      name: isSoapCustomizationEnabled
        ? `${t('Common:EXAM_NOUN')}/${t(
            'Abbreviations:ACRONYMS.DIFFERENTIAL_DIAGNOSES',
          )}`
        : t('Soap:PROBLEMS.PROBLEMS'),
      Component: Problems,
      soapWidgetId: Utils.findConstantIdByName(
        SoapWidgetName.PROBLEMS_WIDGET,
        StaticSoapWidget,
      ),
      visible: showProblems,
    },
    [SOAPStep.EXAMINATION]: {
      name: t('Common:EXAMINATION'),
      Component: Examination,
      visible: showExamination,
    },
    [SOAPStep.DIFFERENTIAL_DX]: {
      name: t('Common:DIFFERENTIAL_DIAGNOSES'),
      Component: DifferentialDiagnoses,
      visible: showDiffDx,
    },
    [SOAPStep.ORDER]: {
      name: t('Common:ORDER'),
      Component: Order,
      soapWidgetId: Utils.findConstantIdByName(
        SoapWidgetName.CHARGES_WIDGET,
        StaticSoapWidget,
      ),
      visible: showOrder,
    },
    [SOAPStep.TO_DO]: {
      name: t('Common:TO_DO'),
      Component: ToDo,
      soapWidgetId: Utils.findConstantIdByName(
        SoapWidgetName.TODO_WIDGET,
        StaticSoapWidget,
      ),
      visible: showToDo,
    },
    [SOAPStep.WRAP_UP]: {
      name: t('Common:WRAP_UP'),
      Component: WrapUp,
      soapWidgetId: Utils.findConstantIdByName(
        SoapWidgetName.NOTES_WIDGET,
        StaticSoapWidget,
      ),
      visible: showWrapUp,
    },
  }

  const { Component: SelectedItemComponent, visible: selectedItemVisible } =
    components[selectedItem] || {}
  const steps = Object.keys(components) as SOAPStep[]
  const stepsToExclude: SOAPStep[] = [
    ...(isProblemWidgetFTEnabled
      ? [SOAPStep.EXAMINATION, SOAPStep.DIFFERENTIAL_DX]
      : [SOAPStep.PROBLEMS]),
  ]
  const preparedSteps = R.without(stepsToExclude, steps)

  useEffect(() => {
    if (expanded) {
      setExpandedItems({})
      setSelectedItem(activeStep)
    }
  }, [expanded])

  useEffect(() => {
    if (step === SOAPStep.VITALS) {
      setExpandedItems({ [SOAPStep.VITALS]: true })
    }
  }, [vitalIds])

  useEffect(() => {
    if (step === SOAPStep.EXAMINATION) {
      setExpandedItems({ [SOAPStep.EXAMINATION]: true })
    }
  }, [findingsState])

  useEffect(() => {
    if (step === SOAPStep.DIFFERENTIAL_DX) {
      setExpandedItems({ [SOAPStep.DIFFERENTIAL_DX]: true })
    }
  }, [diagnosesState])

  useEffect(() => {
    if (step === SOAPStep.ORDER) {
      setExpandedItems({ [SOAPStep.ORDER]: true })
    }
  }, [ordersLog])

  useEffect(() => {
    if (isSoapCustomizationEnabled) {
      return
    }
    setExpandedItems({ [activeStep]: true })
    setSelectedItem(activeStep)
  }, [activeStep])

  useEffect(() => {
    if (isSoapCustomizationEnabled && soapTabs && urlTabName) {
      const currentSoapTab =
        soapTabs.find((tab) => tab.url === urlTabName) ?? soapTabs[0]

      const currentTabSoapWidgetIds = R.pluck<any, string>(
        'soapWidgetId',
        currentSoapTab?.widgets as any,
      )

      const currentWidgetsInSummary = R.pipe<any, any, any>(
        R.filter((v) => R.includes(v, currentTabSoapWidgetIds)),
        R.map(R.T),
        // @ts-ignore
      )(R.pluck('soapWidgetId', components)) as Record<SOAPStep, boolean>

      if (R.isEmpty(currentWidgetsInSummary)) {
        setExpandedItems({ [SOAPStep.APPOINTMENT]: true })
        setSelectedItem(SOAPStep.APPOINTMENT)
      } else {
        setExpandedItems(currentWidgetsInSummary)
        setSelectedItem(R.head(R.keys(currentWidgetsInSummary))!)
      }
    }
  }, [urlTabName, soapTabs?.length])

  return {
    SelectedItemComponent,
    activeStep,
    components,
    expandedItems,
    preparedSteps,
    setExpandedItems,
    selectedItem,
    selectedItemVisible,
    setSelectedItem,
    urlTabName,
  }
}
