import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TextProps } from '@pbt/pbt-ui-components'

import useLogout from '~/utils/useLogout'

interface LogOutLinkProps extends TextProps {
  className?: string
}

const LogOutLink = ({ className, ...rest }: LogOutLinkProps) => {
  const { t } = useTranslation('Common')

  const logout = useLogout()

  return (
    <Text
      inline
      link
      className={className}
      variant="body2"
      onClick={() => logout('/auth/login')}
      {...rest}
    >
      {t('Common:LOG_OUT')}
    </Text>
  )
}

export default LogOutLink
