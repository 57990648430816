import React from 'react'

import { Order } from '~/types'

import ItemWithBadges from '../ItemWithBadges'
import TaskOrderActions from './actions/TaskOrderActions'

export interface TaskOrderProps {
  data: Order
}

const TaskOrder = ({ data, ...rest }: TaskOrderProps) => (
  <ItemWithBadges
    actions={<TaskOrderActions order={data} {...rest} />}
    order={data}
  />
)

export default TaskOrder
