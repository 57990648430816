import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    warningIcon: {
      color: theme.colors.alertWarning,
      fontSize: '1.6rem',
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'UnmatchedLine' },
)

interface UnmatchedLineProps {
  classes?: ClassesType<typeof useStyles>
  hasMultipleChoices: boolean
  onMatchRequested: () => void
}

const UnmatchedLine = ({
  classes: classesProp,
  onMatchRequested,
  hasMultipleChoices,
}: UnmatchedLineProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  return (
    <Grid container alignItems="center" className={classes.root}>
      <WarningIcon className={classes.warningIcon} />
      <Text inline>
        {hasMultipleChoices
          ? t('Common:MULTIPLE_MATCHES_FOUND')
          : t('Common:NOT_FOUND')}
      </Text>
      <Text inline link ml={1} onClick={onMatchRequested}>
        {hasMultipleChoices ? t('Common:CHOOSE_ONE') : t('Common:FIND_ACTION')}
      </Text>
    </Grid>
  )
}

export default UnmatchedLine
