import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { partition, propEq } from 'ramda'
import { Text } from '@pbt/pbt-ui-components'

import { OrderType } from '~/constants/SOAPStates'
import { useGetInvoicePosted } from '~/store/hooks/soap'
import {
  getPendingSelectionOrders,
  getPendingUnSelectionOrders,
  getSelectedOrders,
} from '~/store/reducers/orders'
import { Order } from '~/types'
import { findOrderItemByItem } from '~/utils/orderUtils'

import InvoiceIsPostedWarning from '../InvoiceIsPostedWarning'
import { OrderComponents } from './orderSummaryUtils'

const getKey = (order: Order) =>
  `${order.logId}_${order.id}_${order.name}_${order.type}`

export interface OrderComponentProps {
  className?: string
  editDisabled?: boolean
}

const OrderComponent = ({ className, editDisabled }: OrderComponentProps) => {
  const { t } = useTranslation('Common')

  const selectedOrders = useSelector(getSelectedOrders)
  const pendingSelectionOrders = useSelector(getPendingSelectionOrders)
  const pendingUnSelectionOrders = useSelector(getPendingUnSelectionOrders)
  const isInvoicePosted = useGetInvoicePosted()

  const [tasks, orders] = partition(
    propEq('type', OrderType.TASK),
    selectedOrders,
  )

  const getDisabled = (item: Order) =>
    findOrderItemByItem(item, pendingSelectionOrders) !== undefined ||
    findOrderItemByItem(item, pendingUnSelectionOrders) !== undefined

  return (
    <Grid item className={className}>
      {isInvoicePosted && <InvoiceIsPostedWarning />}
      {orders.map((data) => {
        const Component = OrderComponents[data.type]

        if (!Component) {
          return null
        }

        return (
          <Component
            data={data}
            editDisabled={editDisabled || getDisabled(data)}
            key={getKey(data)}
            readOnly={isInvoicePosted}
          />
        )
      })}
      {tasks.length > 0 && (
        <>
          <Text variant="body2">{t('Common:TASKS')}</Text>
          {tasks.map((task) => {
            const Component = OrderComponents[task.type]

            if (!Component) {
              return null
            }

            return (
              <Component
                data={task}
                editDisabled={editDisabled}
                key={getKey(task)}
                readOnly={isInvoicePosted}
              />
            )
          })}
        </>
      )}
    </Grid>
  )
}
export default OrderComponent
