import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { Defaults, Text, useInterval } from '@pbt/pbt-ui-components'

import { PuiPagination } from '~/components/common/lists/table/PuiPagination'
import {
  fetchInvoicePage,
  getClientBillingActivityInvoiceError,
  getDefaultInvoiceStatusesIds,
  getDefaultRefundInvoiceStatusesIds,
  getInvoiceLoading,
  getInvoicePageData,
  getInvoiceTotalCount,
  getIsPollingInvoices,
  getShouldRefreshData,
} from '~/store/duck/clientBillingActivityData'

import { CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS } from '../../table/common/tableConstants'
import { InvoiceBillingActivityTable } from '../../table/invoices/InvoiceBillingActivityTable'

interface InvoicesWidgetProps {
  isAddClientPaymentDialogOpened: boolean
  isExpanded: boolean
  onExpand: () => void
}

export const InvoicesWidget = ({
  isAddClientPaymentDialogOpened,
  isExpanded,
  onExpand,
}: InvoicesWidgetProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const { clientId } = useParams()
  const [offsetValue, setOffsetValue] = useState(0)

  const refresh = useSelector(getShouldRefreshData)
  const invoicesPageData = useSelector(getInvoicePageData)
  const invoicesTotalCount = useSelector(getInvoiceTotalCount)
  const isFetchingInvoice = useSelector(getInvoiceLoading)
  const isPolling = useSelector(getIsPollingInvoices)
  const error = useSelector(getClientBillingActivityInvoiceError)
  const invoiceStateIds = useSelector(getDefaultInvoiceStatusesIds())
  const refundStateIds = useSelector(getDefaultRefundInvoiceStatusesIds())

  const isLoading = isPolling ? false : isFetchingInvoice

  const shouldFetchInitialData = Boolean(
    clientId && !isAddClientPaymentDialogOpened,
  )

  useEffect(() => {
    if (refresh && clientId) {
      dispatch(
        fetchInvoicePage({
          clientId,
          offset: offsetValue,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
          refundStateIds,
          stateIds: invoiceStateIds,
          isRefreshing: true,
        }),
      )
    }
  }, [clientId, refresh])

  useInterval(() => {
    if (clientId) {
      dispatch(
        fetchInvoicePage({
          clientId,
          offset: offsetValue,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
          refundStateIds,
          stateIds: invoiceStateIds,
          isPolling: true,
        }),
      )
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  useEffect(() => {
    if (shouldFetchInitialData) {
      dispatch(
        fetchInvoicePage({
          clientId,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
          offset: 0,
          refundStateIds,
          stateIds: invoiceStateIds,
        }),
      )
    }
  }, [shouldFetchInitialData])

  const handleFetchMore = (offset: number) => {
    if (clientId) {
      setOffsetValue(offset)
      dispatch(
        fetchInvoicePage({
          clientId,
          offset,
          limit: CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS,
          refundStateIds,
          stateIds: invoiceStateIds,
        }),
      )
    }
  }

  if (error) {
    return (
      <Text px={2} py={1} variant="body2">
        {error}
      </Text>
    )
  }

  if (!isLoading && R.isEmpty(invoicesPageData)) {
    return (
      <Text px={2} py={1} variant="body2">
        {t('Common:NO_INVOICES')}
      </Text>
    )
  }

  return (
    <>
      <InvoiceBillingActivityTable
        invoices={invoicesPageData}
        invoicesPerPage={CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS}
        isLoading={isLoading}
        onExpand={onExpand}
      />
      {!isExpanded && (
        <Box display="flex" justifyContent="flex-end" p={1}>
          <PuiPagination
            itemsPerPage={CLIENT_BILLING_ACTIVITY_VISIBLE_ROWS}
            reset={shouldFetchInitialData}
            totalItems={invoicesTotalCount}
            onFetchMore={handleFetchMore}
          />
        </Box>
      )}
    </>
  )
}
