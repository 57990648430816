import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import {
  Constant,
  EstimateStatesConstant,
  SelectEntity,
  Utils,
} from '@pbt/pbt-ui-components'

import EnumSelect, {
  EnumSelectProps,
} from '~/components/common/inputs/EnumSelect'
import { getEstimateStates } from '~/store/reducers/constants'

export interface EstimateStateSelectProps
  extends Omit<EnumSelectProps, 'Constant'> {}

const EstimateStateSelect = ({
  accent = true,
  field,
  ...rest
}: EstimateStateSelectProps) => {
  const EstimateStates: EstimateStatesConstant[] =
    useSelector(getEstimateStates)

  const FieldState: Constant = Utils.findById(field.value, EstimateStates)
  const FieldConstant: SelectEntity[] = R.isNil(FieldState)
    ? []
    : [{ ...FieldState, disabled: true }]

  const FilteredStates: SelectEntity[] = R.pipe(
    R.filter(
      (EstimateState: EstimateStatesConstant) => !EstimateState.forbidSelect,
    ),
    R.map(R.dissoc('forbidSelect')),
  )(EstimateStates)
  const AllStates = FilteredStates.concat(FieldConstant)
  const UniqStates = R.uniqBy(R.prop('id'), AllStates)

  return (
    <EnumSelect Constant={UniqStates} accent={accent} field={field} {...rest} />
  )
}

export default EstimateStateSelect
