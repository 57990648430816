import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { values } from 'ramda'
import {
  BasePuiDialogProps,
  DateFormat,
  moment,
  Nil,
  PuiDialog,
} from '@pbt/pbt-ui-components'

import { MigrationStatuses, SessionsTypes } from '~/constants/migration'
import i18n from '~/locales/i18n'
import { chooseImportSession } from '~/store/actions/migration'
import { getCurrentSession } from '~/store/reducers/migration'
import { MigrationSession } from '~/types/entities/migration'

import StepAnalysisDone from './StepAnalysisDone'
import StepAnalysisInProgress from './StepAnalysisInProgress'
import StepConfirmTimezones from './StepConfirmTimezones'
import StepDataReady from './StepDataReady'
import StepImportDone from './StepImportDone'
import StepImportInProgress from './StepImportInProgress'
import StepImportReady from './StepImportReady'
import StepPreprocessingInProgress from './StepPreprocessingInProgress'
import StepReviewExceptions from './StepReviewExceptions'
import StepSelectSession from './StepSelectSession'
import StepUploadAdditionalFiles from './StepUploadAdditionalFiles'

const MigrationStatusToComponent = {
  [MigrationStatuses.ANALYSIS_IN_PROGRESS]: StepAnalysisInProgress,
  [MigrationStatuses.ANALYSIS_DONE]: StepAnalysisDone,
  [MigrationStatuses.REVIEW_EXCEPTIONS]: StepReviewExceptions,
  [MigrationStatuses.IMPORT_IS_READY]: StepImportReady,
  [MigrationStatuses.CONFIRM_IMPORT_SETTINGS]: StepConfirmTimezones,
  [MigrationStatuses.IMPORT_IN_PROGRESS]: StepImportInProgress,
  [MigrationStatuses.IMPORT_DONE]: StepImportDone,
  [MigrationStatuses.EXPORT_DONE]: StepUploadAdditionalFiles,
  [MigrationStatuses.PREPROCESSING_IN_PROGRESS]: StepPreprocessingInProgress,
  [MigrationStatuses.PREPROCESSING_DONE]: StepPreprocessingInProgress,
  [MigrationStatuses.PREPROCESSING_FAILED]: StepUploadAdditionalFiles,
  [MigrationStatuses.DATA_IS_READY]: StepDataReady,
  [MigrationStatuses.UPLOADER]: StepUploadAdditionalFiles,
  [MigrationStatuses.EXPORTER_DOWNLOADED]: undefined,
  [MigrationStatuses.STOPPED]: undefined,
}

const DefaultMigrationStatusToComponent = StepSelectSession

const buildReviewExceptionsTitle = (session: MigrationSession | Nil) =>
  [
    i18n.t('Businesses:MIGRATION.STEPS.REVIEW_EXCEPTIONS'),
    session?.date &&
      moment(session?.date).format(DateFormat.FULL_DATE_TIME_SHORT_WITH_PIPE),
  ].join(' | ')

const MigrationStepTitleBuilders = {
  [MigrationStatuses.ANALYSIS_IN_PROGRESS]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.ANALYSIS_IN_PROGRESS'),
  [MigrationStatuses.ANALYSIS_DONE]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.ANALYSIS_DONE'),
  [MigrationStatuses.REVIEW_EXCEPTIONS]: buildReviewExceptionsTitle,
  [MigrationStatuses.IMPORT_IS_READY]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.IMPORT_IS_READY'),
  [MigrationStatuses.CONFIRM_IMPORT_SETTINGS]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.CONFIRM_IMPORT_SETTINGS'),
  [MigrationStatuses.IMPORT_IN_PROGRESS]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.IMPORT_IN_PROGRESS'),
  [MigrationStatuses.IMPORT_DONE]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.IMPORT_DONE'),
  [MigrationStatuses.EXPORT_DONE]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.EXPORT_DONE'),
  [MigrationStatuses.PREPROCESSING_IN_PROGRESS]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.PREPROCESSING_IN_PROGRESS'),
  [MigrationStatuses.PREPROCESSING_DONE]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.PREPROCESSING_DONE'),
  [MigrationStatuses.PREPROCESSING_FAILED]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.PREPROCESSING_FAILED'),
  [MigrationStatuses.DATA_IS_READY]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.DATA_IS_READY'),
  [MigrationStatuses.UPLOADER]: () =>
    i18n.t('Businesses:MIGRATION.STEPS.UPLOADER'),
  [MigrationStatuses.EXPORTER_DOWNLOADED]: undefined,
  [MigrationStatuses.STOPPED]: undefined,
}

const DefaultMigrationStepTitleBuilder = () =>
  i18n.t('Businesses:MIGRATION.STEPS.DEFAULT')

const MigrationStepSizes: Record<MigrationStatuses, any> = {
  [MigrationStatuses.REVIEW_EXCEPTIONS]: 'lg',
  [MigrationStatuses.EXPORTER_DOWNLOADED]: undefined,
  [MigrationStatuses.DATA_IS_READY]: undefined,
  [MigrationStatuses.ANALYSIS_IN_PROGRESS]: undefined,
  [MigrationStatuses.ANALYSIS_DONE]: undefined,
  [MigrationStatuses.IMPORT_IS_READY]: undefined,
  [MigrationStatuses.CONFIRM_IMPORT_SETTINGS]: undefined,
  [MigrationStatuses.IMPORT_IN_PROGRESS]: undefined,
  [MigrationStatuses.IMPORT_DONE]: undefined,
  [MigrationStatuses.STOPPED]: undefined,
  [MigrationStatuses.EXPORT_DONE]: undefined,
  [MigrationStatuses.PREPROCESSING_IN_PROGRESS]: undefined,
  [MigrationStatuses.PREPROCESSING_DONE]: undefined,
  [MigrationStatuses.PREPROCESSING_FAILED]: undefined,
  [MigrationStatuses.UPLOADER]: undefined,
}

const DefaultMigrationStepSize = 'sm'

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  content: {
    position: 'relative',
  },
}))

interface CoreMigrationDialogProps extends BasePuiDialogProps {
  businessId: string
  enterpriseStatuses?: MigrationStatuses[]
  isEnterprise?: boolean
  sessionsExcludePimsIds?: string[]
  sessionsFilterTypes: SessionsTypes[]
}

const CoreMigrationDialog = ({
  open,
  onClose,
  businessId,
  isEnterprise: isEnterpriseProp,
  enterpriseStatuses = values(MigrationStatuses),
  sessionsExcludePimsIds,
  sessionsFilterTypes,
}: CoreMigrationDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentSession =
    useSelector(getCurrentSession) || ({} as MigrationSession)
  const sessionStatus = currentSession.status

  const buildDialogTitle =
    MigrationStepTitleBuilders[sessionStatus] ||
    DefaultMigrationStepTitleBuilder
  const dialogSize =
    MigrationStepSizes[sessionStatus] || DefaultMigrationStepSize
  const StepComponent =
    MigrationStatusToComponent[sessionStatus] ||
    DefaultMigrationStatusToComponent
  const isEnterprise = Boolean(
    isEnterpriseProp &&
      enterpriseStatuses.find((status) => status === sessionStatus),
  )

  const handleClose = useCallback(() => {
    dispatch(chooseImportSession(null))
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <PuiDialog
      fullWidth
      aria-labelledby="core-migration-dialog"
      classes={{
        dialogContentRoot: classes.content,
        paper: classes.paper,
      }}
      maxWidth={dialogSize}
      open={open}
      scroll="paper"
      title={buildDialogTitle(currentSession)}
      onClose={handleClose}
    >
      <StepComponent
        businessId={businessId}
        isEnterprise={isEnterprise}
        sessionsExcludePimsIds={sessionsExcludePimsIds}
        sessionsFilterTypes={sessionsFilterTypes}
      />
    </PuiDialog>
  )
}

export default CoreMigrationDialog
