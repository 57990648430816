import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useCanSubmitApprovedChewyActiveRx } from '~/components/dashboard/prescription/prescription-editor/hooks/useCanSubmitApprovedChewyActiveRx'
import {
  FinalizeChewyActiveRxTable,
  FinalizeChewyActiveRxTableProps,
} from '~/components/dashboard/soapV2/finalize-orders/chewy-active-rx/FinalizeChewyActiveRxTable'
import {
  getIsSubmittingAllActiveRx,
  getSubmittingAllActiveRxError,
  submitAllActiveRxForSoap,
} from '~/store/duck/prescriptions'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { useHasChewyActiveRxApproved } from '~/store/hooks/prescription'
import { removeServerErrorPrefix } from '~/utils/errors'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import FinalizeOrdersSection from '../FinalizeOrdersSection'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderTop: theme.constants.tabBorder,
    },
  }),
  { name: 'FinalizeSoapChewyActiveRxSection' },
)

export interface FinalizeChewyActiveRxSectionProps
  extends FinalizeChewyActiveRxTableProps {
  isLoading: boolean
  soapId?: string
}

export const FinalizeChewyActiveRxSection = ({
  context,
  data,
  isLoading,
  soapId,
}: FinalizeChewyActiveRxSectionProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Soap'])

  const error = useSelector(getSubmittingAllActiveRxError)
  const isSubmittingAllActiveRx = useSelector(getIsSubmittingAllActiveRx)

  const isChewyCheckoutEnabled = useIsChewyCheckoutEnabled()
  const canSubmitApprovedChewyActiveRx = useCanSubmitApprovedChewyActiveRx()
  const hasChewyActiveRxApproved = useHasChewyActiveRxApproved(data)

  const canFinalize = isChewyCheckoutEnabled
    ? false
    : canSubmitApprovedChewyActiveRx && hasChewyActiveRxApproved

  const handleFinalize = () => {
    if (soapId) {
      dispatch(submitAllActiveRxForSoap({ soapId }))
    }
  }

  useWSTopic({
    wsTopic: WSTopics.FINALIZE_OUTSTANDING_CHEWY_ACTIVE_RX,
    context: {
      retrySubscription: false,
      topicParams: {
        soapId,
      },
      actionParams: {
        soapId,
      },
    },
    unsubscribe: true,
  })

  return (
    <Box className={classes.root} width="100%">
      <FinalizeOrdersSection
        buttonLabel={t('Common:SUBMIT_TO_CHEWY')}
        description={t('Soap:FINALIZE_CHEWY_ACTIVE_RX_DESCRIPTION')}
        isFinalized={!canFinalize}
        isLoading={isLoading}
        isSubmitting={isSubmittingAllActiveRx}
        title={t('Soap:FINALIZE_CHEWY_ACTIVE_RX_TITLE')}
        onClick={handleFinalize}
      >
        <FinalizeChewyActiveRxTable context={context} data={data} />
      </FinalizeOrdersSection>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          {removeServerErrorPrefix(error)}
        </Alert>
      )}
    </Box>
  )
}
