import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Theme, useMediaQuery } from '@mui/material'
import classNames from 'classnames'
import { ClassesType } from '@pbt/pbt-ui-components'

import { PuiDataTableColumn } from '~/components/common/lists/pui-data-table/puiDataTableType'
import { Reminder, ReminderOrderListHandlers } from '~/types'

import ReminderAddToChargesCell from '../table/cells/ReminderAddToChargesCell'
import ReminderDueDateTimeCell from '../table/cells/ReminderDueDateTimeCell'
import ReminderNameCell from '../table/cells/ReminderNameCell'
import ReminderStatusCell from '../table/cells/ReminderStatusCell'
import ReminderStatusFilter from '../table/cells/ReminderStatusFilter'

export interface UseRemindersColumns extends ReminderOrderListHandlers {
  actionsAnchorElement: HTMLElement | null
  activeActionItem: Reminder | null
  classes: ClassesType<any>
  initialFilter: string[]
  onApplyFilter?: (value: string[]) => void
  onRemoveFilter?: () => void
  setActionsAnchorElement: (element: HTMLElement | null) => void
  setActiveActionItem: (item: Reminder | null) => void
}

export const useRemindersColumns = ({
  classes,
  actionsAnchorElement,
  activeActionItem,
  initialFilter,
  onApplyFilter,
  onRemoveFilter,
  setActionsAnchorElement,
  setActiveActionItem,
  isCheckedItem,
  onCheckItem,
  onUncheckItem,
}: UseRemindersColumns) => {
  const { t } = useTranslation('Common')

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const mainColumn = {
    label: t('Common:DUE_DATE'),
    value: (reminder: Reminder) => (
      <Box className={classes.cellWrapper}>
        <ReminderDueDateTimeCell reminder={reminder} />
      </Box>
    ),
    width: isMobile ? '15%' : '20%',
    classNameRow: classes.stretchCell,
    classNameHeader: classes.withoutBorder,
  }

  const columns: PuiDataTableColumn[] = [
    {
      label: t('Common:STATUS'),
      value: (reminder: Reminder) => (
        <Box className={classes.cellWrapper}>
          <ReminderStatusCell reminder={reminder} />
        </Box>
      ),
      classNameHeader: classes.spacingCell,
      classNameRow: classNames(classes.reminderCell, classes.stretchCell),
      width: isMobile ? '7.5%' : '10%',
      filter: {
        Component: ReminderStatusFilter,
        iconClassName: classes.filter,
        initialFilter,
        onApplyFilter,
        onRemoveFilter,
        withFilterCounter: true,
      },
    },
    {
      label: t('Common:REMINDER'),
      value: (reminder: Reminder, isRowSelected?: boolean) => (
        <Box className={classes.cellWrapper}>
          <ReminderNameCell isRowSelected={isRowSelected} reminder={reminder} />
        </Box>
      ),
      classNameHeader: classes.spacingCell,
      classNameRow: classNames(classes.reminderCell, classes.stretchCell),
      width: isMobile ? '15%' : '20%',
    },
    {
      label: t('Common:ADD_TO_CHARGES'),
      classNameHeader: classes.spacingCell,
      classNameRow: classes.chargesCell,
      width: isMobile ? '62.5%' : '50%',
      value: (reminder: Reminder) => (
        <ReminderAddToChargesCell
          actionsAnchorElement={actionsAnchorElement}
          activeActionItem={activeActionItem}
          isCheckedItem={isCheckedItem}
          reminder={reminder}
          setActionsAnchorElement={setActionsAnchorElement}
          setActiveActionItem={setActiveActionItem}
          onCheckItem={onCheckItem}
          onUncheckItem={onUncheckItem}
        />
      ),
    },
  ]

  return { mainColumn, columns }
}
