import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GridProps } from '@mui/material'
import * as R from 'ramda'
import {
  DateUtils,
  Nil,
  Text,
  TextProps,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import { getConstantName } from '@pbt/pbt-ui-components/src/utils'

import { AppointmentState } from '~/constants/appointmentStates'
import { getAppointmentCancellationReason } from '~/store/reducers/constants'
import { getUser } from '~/store/reducers/users'
import { useAppointmentStateId } from '~/utils/useEventType'

import ItemHistoryLabel, { HistoryLabelItem } from './ItemHistoryLabel'

export type HistoryLabelAppointment = HistoryLabelItem & {
  cancellationReason?: string | Nil
  cancellationReasonId?: string | Nil
  stateId?: string | Nil
}

export interface AppointmentHistoryLabelProps extends Omit<GridProps, 'item'> {
  item?: HistoryLabelAppointment | Nil
  textVariant?: TextProps['variant']
}

export const AppointmentHistoryLabel = ({
  item,
  textVariant = 'lowAccent2',
  ...rest
}: AppointmentHistoryLabelProps) => {
  const { t } = useTranslation('Common')

  const AppointmentCancellationReason = useSelector(
    getAppointmentCancellationReason,
  )

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)

  const {
    cancellationReason,
    cancellationReasonId,
    modificationDate,
    modifiedBy,
    modifiedById,
    modifierId,
    stateId,
  } = item || {}

  const modifier = modifierId || modifiedById || modifiedBy

  const modifierUserFromStore = useSelector(getUser(modifier as string))

  const cancellationReasonName =
    cancellationReason ||
    getConstantName(cancellationReasonId, AppointmentCancellationReason)

  const modifiedByUser = (
    R.is(Object, modifier) ? modifier : modifierUserFromStore
  ) as User

  const isCancelled = stateId === cancelledStateId

  return (
    <ItemHistoryLabel item={item} {...rest}>
      {isCancelled ? (
        <Text variant={textVariant}>
          {`${t(
            'Common:APPOINTMENT_CANCELED',
          )}: ${DateUtils.formatDateWithHours(modificationDate)}`}
          {modifier && ` | ${Utils.getPersonString(modifiedByUser)}`}
          {cancellationReasonName &&
            ` | ${t('Common:REASON')}: ${cancellationReasonName}`}
        </Text>
      ) : undefined}
    </ItemHistoryLabel>
  )
}
