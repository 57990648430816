import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { PuiPopper, PuiPopperProps } from '@pbt/pbt-ui-components'

import ValidationError, { ValidationErrorProps } from './ValidationError'

const useStyles = makeStyles(
  () => ({
    paper: {
      width: 415,
    },
  }),
  { name: 'ValidationErrorPopper' },
)

export interface ValidationErrorPopperProps
  extends ValidationErrorProps,
    Omit<PuiPopperProps, 'onClose'> {}

const ValidationErrorPopper = ({
  anchorEl,
  placement,
  open,
  onBack,
  ...rest
}: ValidationErrorPopperProps) => {
  const classes = useStyles()

  return (
    <PuiPopper
      disableClickAwayListener
      disablePortal
      anchorEl={anchorEl}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      placement={placement}
      onClose={onBack}
    >
      <ValidationError onBack={onBack} {...rest} />
    </PuiPopper>
  )
}

export default ValidationErrorPopper
