import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import {
  Divider,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { LanguageUtils, Nil, Text } from '@pbt/pbt-ui-components'
import { AddNote } from '@pbt/pbt-ui-components/src/icons'
import { getIsScrollSmoothSupported } from '@pbt/pbt-ui-components/src/utils/browserUtils'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import ThreeStepsToggle from '~/components/common/inputs/ThreeStepsToggle'
import useGetBodySystemStatus from '~/components/dashboard/soap/rail/summary/utils/useGetBodySystemStatus'
import { ConfirmAlertType } from '~/constants/DialogNames'
import { BodySystemState } from '~/constants/SOAPStates'
import {
  getIsLoadingLogs,
  getIsLogsAndCatalogLoaded,
  getProblemCatalog,
  getProblemsBodySystemLogsMap,
} from '~/store/reducers/problems'
import { Problem } from '~/types'
import { getProblems } from '~/utils/soapProblemsWidget'

import CommonFindingsAndDiagnosesComponent from './chips/CommonFindingsAndDiagnosesComponent'
import ProblemsTree from './ProblemsTree'

const useStyles = makeStyles(
  (theme) => ({
    row: {
      backgroundColor: theme.colors.tableBackground,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
    },
    mainProblemWrapper: {
      height: 'fit-content',
    },
    mainProblemWrapperSelected: {
      borderLeft: `2px solid ${theme.palette.warning.main}`,
      borderTop: `2px solid ${theme.palette.warning.main}`,
      borderBottom: `2px solid ${theme.palette.warning.main}`,
      position: 'relative',
      '&:before': {
        pointerEvents: 'none',
        position: 'absolute',
        content: '""',
        width: 3,
        height: '100%',
        right: '-3px',
        top: 0,
        backgroundColor: theme.palette.grey[300],
      },
    },
    treeWrapper: {
      border: `2px solid ${theme.palette.warning.main}`,
    },
    iconButtonRoot: {
      padding: theme.spacing(1, 1, 0.5, 0),
    },
  }),
  { name: 'ProblemBodySystemItem' },
)

interface ProblemBodySystemItemProps {
  isActive?: boolean
  onAddLog: (problem: Problem, bodySystemState?: BodySystemState) => void
  onBrowse: (problemId: string) => void
  onStateChange: (
    problemId: string,
    newValue: string | Nil,
    fromEvent?: boolean,
  ) => void
  problemId: string
}

const ProblemBodySystemItem = ({
  problemId: problemIdProp,
  isActive,
  onBrowse,
  onAddLog,
  onStateChange,
}: ProblemBodySystemItemProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Soap')
  const theme = useTheme()
  const isMobile = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('md'),
  )

  const catalog = useSelector(getProblemCatalog)
  const bodySystemLogs = useSelector(getProblemsBodySystemLogsMap)
  const isLogsAndCatalogLoaded = useSelector(getIsLogsAndCatalogLoaded)
  const isLogsLoading = useSelector(getIsLoadingLogs)

  const [openConfirmCategoryAlert] = useConfirmAlert({
    type: ConfirmAlertType.EXAMINATION_CATEGORY,
  })
  const getBodySystemStatus = useGetBodySystemStatus()

  const ref = useRef<HTMLDivElement>(null)

  const catalogProblems = catalog?.problems
  const currentProblem = catalogProblems?.[problemIdProp]

  if (!currentProblem || currentProblem.removed) {
    return null
  }

  const isScrollSmoothSupported = getIsScrollSmoothSupported()

  const confirmCategoryDiscard = (
    categories: Problem[],
    discardCallback: (confirmed: boolean) => void,
  ) => {
    openConfirmCategoryAlert({
      onConfirm: discardCallback,
      message: t('Soap:SOAP_EXAMINATION.CATEGORY_DISCARD_MESSAGE', {
        categories: LanguageUtils.getTranslatedFieldName(R.head(categories)),
      }),
    })
  }

  const isBodySystemLogHasNotes = (problemId: string): boolean => {
    if (!catalog) {
      return false
    }

    const bodySystemId = catalog.problems[problemId].id!
    const bodySystemLog = bodySystemLogs?.[bodySystemId]

    return Boolean(bodySystemLog?.entity?.notes)
  }

  const handleBrowse = () => {
    onBrowse(problemIdProp)
    const element = ref.current
    if (element) {
      setTimeout(() => {
        const offsetTop = element.getBoundingClientRect().top + window.scrollY
        const fixedHeaderHeight = isMobile
          ? theme.constants.soapHeaderHeight +
            theme.constants.soapProblemsSearchHeight
          : theme.constants.soapHeaderHeight +
            theme.constants.soapProblemsSearchHeight +
            theme.constants.appHeader.heightMdUp
        window.scrollTo({
          top: offsetTop - fixedHeaderHeight,
          behavior: isScrollSmoothSupported ? 'smooth' : 'auto',
        })
      }, 0)
    }
  }

  const getThisCategoryLogs = (problemId: string) => {
    if (!catalog || !bodySystemLogs) {
      return []
    }
    const problem = catalog.problems[problemId]
    const bodySystemLog = bodySystemLogs[problem.bodySystemId]
    const allCategoryProblems = getProblems(
      catalog?.problems,
      problem?.children,
    )
    return allCategoryProblems.filter((id: string) =>
      bodySystemLog?.entity.problemLogs.includes(
        catalog?.problems[id]?.logKey as string,
      ),
    )
  }

  const thisCategoryLogs = getThisCategoryLogs(problemIdProp)

  return (
    <Grid container className={classes.row} direction="column" ref={ref}>
      <Grid container>
        <Grid
          container
          item
          className={classNames(classes.mainProblemWrapper, {
            [classes.mainProblemWrapperSelected]: isActive,
          })}
          flex={3}
          flexWrap="nowrap"
          minWidth={300}
          pl={2}
          pr={1}
          py={1}
        >
          <Grid
            container
            item
            alignItems="flex-start"
            direction="row"
            flex={1}
            flexWrap="nowrap"
            justifyContent="flex-start"
          >
            <ThreeStepsToggle
              confirmStateChange={(callback) => {
                if (thisCategoryLogs.length) {
                  confirmCategoryDiscard([currentProblem], (confirmed) => {
                    callback(confirmed)
                  })
                } else {
                  callback(true)
                }
              }}
              disabled={isLogsLoading}
              noneLabel={t('Abbreviations:COMMON.NOT_CHECKED')}
              noneValue={BodySystemState.NC}
              offLabel={t('Abbreviations:COMMON.OUTSIDE_NORMAL_LIMITS')}
              offValue={BodySystemState.ONL}
              value={
                !isLogsAndCatalogLoaded
                  ? BodySystemState.NC
                  : getBodySystemStatus(problemIdProp) || BodySystemState.NC
              }
              onLabel={t('Abbreviations:COMMON.WITHIN_NORMAL_LIMITS')}
              onStateChange={(newValue, fromEvent) =>
                onStateChange(problemIdProp, newValue, fromEvent)
              }
              onValue={BodySystemState.WNL}
            />
            <Grid
              item
              alignItems="center"
              display="flex"
              onClick={handleBrowse}
            >
              <IconButton size="small">
                {isActive ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
              <Text sx={{ cursor: 'pointer' }} variant="body2">
                {LanguageUtils.getTranslatedFieldName(currentProblem)}
              </Text>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              classes={{ root: classes.iconButtonRoot }}
              size="large"
              onClick={() => {
                // Check: https://chewyinc.atlassian.net/browse/RHAP-6553
                const normalizeProblem: Problem = {
                  ...currentProblem,
                  id: null,
                }
                onAddLog(
                  normalizeProblem,
                  getBodySystemStatus(problemIdProp) || BodySystemState.NC,
                )
              }}
            >
              <AddNote filled={isBodySystemLogHasNotes(problemIdProp)} />
            </IconButton>
          </Grid>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid
          className={classNames({
            [classes.treeWrapper]: isActive,
          })}
          flex={9}
        >
          {isActive ? (
            <ProblemsTree
              problemId={problemIdProp}
              onProblemCheckboxClick={onAddLog}
            />
          ) : (
            <CommonFindingsAndDiagnosesComponent
              addLog={onAddLog}
              bodySystemId={problemIdProp}
            />
          )}
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  )
}

export default ProblemBodySystemItem
