import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClassesType, Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      height: 24,
      width: 27,
      color: theme.colors.profileText,
      lineHeight: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'TimeTrackerButtonLabel' },
)

export interface TimeTrackerButtonLabelProps {
  classes?: ClassesType<typeof useStyles>
}

const TimeTrackerButtonLabel = ({
  classes: classesProp,
}: TimeTrackerButtonLabelProps) => {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation(['Time'])

  return (
    <Tooltip placement="bottom" title={t('Time:LABEL.CLOCK_IN_ACTION')}>
      <Text strong align="center" className={classes.text} variant="body5">
        {t('Time:LABEL.CLOCK_IN_ACTION_WITH_LIMITED_SPACE')}
      </Text>
    </Tooltip>
  )
}

export default TimeTrackerButtonLabel
