import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'

import {
  FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE,
  FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION,
  FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION,
  FETCH_SHIPMENT_ITEMS_FOR_VARIATION,
  fetchMoreShipmentItemsForVaccine,
  fetchMoreShipmentItemsForVaccineFailure,
  fetchMoreShipmentItemsForVaccineSuccess,
  fetchMoreShipmentItemsForVaccineWithVariation,
  fetchMoreShipmentItemsForVariation,
  fetchMoreShipmentItemsForVariationFailure,
  fetchMoreShipmentItemsForVariationSuccess,
  fetchShipmentItemsForVariation,
  fetchShipmentItemsForVariationFailure,
  fetchShipmentItemsForVariationSuccess,
} from '../duck/shipmentItems'
import requestAPI from './utils/requestAPI'
import updateEntities from './utils/updateEntities'

export function* fetchShipmentItemsForVariationSaga({
  variationId,
  from,
  to,
}: ReturnType<typeof fetchShipmentItemsForVariation>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(API.fetchVariationShipmentItems, variationId, from, to)
    yield call(updateEntities, entities)
    yield put(fetchShipmentItemsForVariationSuccess(list, from, totalCount))
  } catch (error) {
    yield put(fetchShipmentItemsForVariationFailure(error as ApiError))
  }
}

export function* fetchMoreShipmentItemsForVariationSaga({
  variationId,
  from,
  to,
}: ReturnType<typeof fetchMoreShipmentItemsForVariation>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(API.fetchVariationShipmentItems, variationId, from, to)
    yield call(updateEntities, entities)
    yield put(fetchMoreShipmentItemsForVariationSuccess(list, from, totalCount))
  } catch (error) {
    yield put(fetchMoreShipmentItemsForVariationFailure(error as ApiError))
  }
}

export function* fetchMoreShipmentItemsForVaccineSaga({
  vaccinationId,
  from,
  to,
}: ReturnType<typeof fetchMoreShipmentItemsForVaccine>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(
      API.fetchShipmentItemsForVaccine,
      vaccinationId,
      from,
      to,
    )
    yield call(updateEntities, entities)
    yield put(fetchMoreShipmentItemsForVaccineSuccess(list, from, totalCount))
  } catch (error) {
    yield put(fetchMoreShipmentItemsForVaccineFailure(error as ApiError))
  }
}

export function* fetchMoreShipmentItemsForVaccineWithVariationSaga({
  vaccinationId,
  from,
  to,
  includeExpiredItems,
}: ReturnType<typeof fetchMoreShipmentItemsForVaccineWithVariation>) {
  try {
    const {
      result: { data: list, totalCount },
      entities,
    } = yield requestAPI(
      API.fetchShipmentItemsForVaccineWithVariation,
      vaccinationId,
      from,
      to,
      includeExpiredItems,
    )
    yield call(updateEntities, entities)
    yield put(fetchMoreShipmentItemsForVaccineSuccess(list, from, totalCount))
  } catch (error) {
    yield put(fetchMoreShipmentItemsForVaccineFailure(error as ApiError))
  }
}

export default function* shipmentItemsSaga() {
  yield all([
    takeLatest(
      FETCH_SHIPMENT_ITEMS_FOR_VARIATION,
      fetchShipmentItemsForVariationSaga,
    ),
    takeLatest(
      FETCH_MORE_SHIPMENT_ITEMS_FOR_VARIATION,
      fetchMoreShipmentItemsForVariationSaga,
    ),
    takeLatest(
      FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE,
      fetchMoreShipmentItemsForVaccineSaga,
    ),
    takeLatest(
      FETCH_MORE_SHIPMENT_ITEMS_FOR_VACCINE_WITH_VARIATION,
      fetchMoreShipmentItemsForVaccineWithVariationSaga,
    ),
  ])
}
