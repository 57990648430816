import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Nil, Text } from '@pbt/pbt-ui-components'

import {
  fetchShipmentItemsForVariation,
  getIsLoading,
  getMultipleShipmentItems,
  getShipmentItemsList,
} from '~/store/duck/shipmentItems'

import ShipmentItemsTable from '../shipments/ShipmentItemsTable'

interface VariationShipmentsSectionProps {
  variationId: string | Nil
}

const VariationShipmentsSection = ({
  variationId,
}: VariationShipmentsSectionProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Admin')

  const shipmentItemIds = useSelector(getShipmentItemsList)
  const shipmentItems = useSelector(getMultipleShipmentItems(shipmentItemIds))
  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    if (variationId) {
      // TODO: add pagination when needed
      dispatch(fetchShipmentItemsForVariation(variationId, 0, 100))
    }
  }, [])

  return shipmentItems.length > 0 || isLoading ? (
    <ShipmentItemsTable
      deleteDisabled
      duplicateDisabled
      editDisabled
      showDistributor
      isLoading={isLoading}
      shipmentItems={shipmentItems}
    />
  ) : (
    <Text>{t('Admin:CATALOG.VARIATION_SHIPMENTS_SECTION.NO_SHIPMENTS')}</Text>
  )
}

export default VariationShipmentsSection
