import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import {
  DateUtils,
  LanguageUtils,
  RoleName,
  TextInteractive,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'

import {
  fetchLastAppointment,
  getLastAppointmentId,
  getLastAppointmentsIsLoading,
} from '~/store/duck/lastAppointments'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getResponsibilities } from '~/store/reducers/constants'
import { getTimetableEvent } from '~/store/reducers/timetable'
import { getUserName } from '~/store/reducers/users'

const useStyles = makeStyles(
  () => ({
    lastVisitText: {
      lineHeight: '2rem',
    },
  }),
  { name: 'AppointmentLastVisitInfoLabel' },
)

export interface AppointmentLastVisitInfoLabelProps {
  patientId: string
}

const AppointmentLastVisitInfoLabel = ({
  patientId,
}: AppointmentLastVisitInfoLabelProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const lastAppointmentId = useSelector(getLastAppointmentId)
  const isLoadingLastAppointment = useSelector(getLastAppointmentsIsLoading)
  const lastAppointment = useSelector(getTimetableEvent(lastAppointmentId))

  const mainStaffRoles = useMainStaffRoles()
  const responsibilities = useSelector(getResponsibilities)
  const shouldUseResponsibilities =
    lastAppointment?.personResponsibilities &&
    lastAppointment?.personResponsibilities.length > 0
  const lastDoctorId = shouldUseResponsibilities
    ? lastAppointment.personResponsibilities?.find(
        (responsibility) =>
          responsibility.responsibilityId ===
          Utils.findConstantIdByName(
            ResponsibilityName.Veterinarian,
            responsibilities,
          ),
      )?.personId
    : lastAppointment?.personRoles?.find(
        (role) =>
          role.roleId ===
          Utils.findConstantIdByName(RoleName.Veterinarian, mainStaffRoles),
      )?.personId
  const firstPersonId = shouldUseResponsibilities
    ? lastAppointment?.personResponsibilities?.[0]?.personId
    : lastAppointment?.personRoles?.[0]?.personId
  const lastDoctorName = useSelector(getUserName(lastDoctorId || firstPersonId))
  const { t } = useTranslation('Common')
  useEffect(() => {
    if (patientId) {
      dispatch(fetchLastAppointment(patientId))
    }
  }, [patientId])

  const lastAppointmentTypeDisplayName = LanguageUtils.getTranslatedFieldName(
    lastAppointment?.businessAppointmentType,
  )

  return (
    <>
      <TextInteractive
        className={classes.lastVisitText}
        isLoading={isLoadingLastAppointment}
        variant="body2"
      >
        {lastAppointmentId && lastAppointment ? (
          `${t('Common:LAST_VISIT')}: ${DateUtils.formatDate(
            lastAppointment.scheduledStartDatetime,
          )}`
        ) : (
          <>&nbsp;</>
        )}
      </TextInteractive>
      <TextInteractive
        className={classes.lastVisitText}
        isLoading={isLoadingLastAppointment}
        loaderWidth={120}
        variant="body2"
      >
        {lastAppointmentId && lastAppointment ? (
          <>
            {lastAppointmentTypeDisplayName}
            {lastAppointment.personRoles &&
              lastAppointment.personRoles?.length > 0 &&
              ` ${t('Common:WITH').toLowerCase()} ${lastDoctorName}`}
          </>
        ) : (
          <>&nbsp;</>
        )}
      </TextInteractive>
    </>
  )
}

export default AppointmentLastVisitInfoLabel
