import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AlertIconType,
  Nil,
  PuiDialog,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import { LabTestGroupKeys } from '~/components/dashboard/soap/wrap-up/lab-orders/labTestUtils'
import DialogNames from '~/constants/DialogNames'
import LabVendorNames from '~/constants/labVendorName'
import { LabOrdersStatus } from '~/constants/SOAPStates'
import {
  editChargeSheetProducerItemBatch,
  getChargeSheetSubItemsMap,
  getClientFinanceLoading,
  refetchChargeSheet,
} from '~/store/duck/clientFinanceData'
import {
  getLabOrderIsPlacing,
  getLabOrdersError,
  getLabOrdersIsLoading,
  getLogIdsForInvoiceWithNoProducer,
  getSoapsWithNoDoctor,
  placeLabOrder,
  setLogIdsForInvoiceWithNoProducer,
  setSoapsWithNoDoctor,
} from '~/store/duck/labOrders'
import { getLabOrderStatuses, getLabVendors } from '~/store/reducers/constants'
import {
  getInvoiceV3Loading,
  getInvoiceV3SubItemsMap,
  updateInvoiceLineItemProducerBatch,
} from '~/store/reducers/invoiceV3'
import { getDoctorId } from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import {
  InvoiceLineItemProducerInput2,
  LabOrder,
  LabOrderLabTest,
  LabOrderOptions,
  Order,
} from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import LabSection from './LabSection'
import OrderNameTitle from './OrderNameTitle'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 976,
      maxWidth: 976,
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
    vendorName: {
      color: theme.colors.title,
    },
  }),
  { name: 'MultiPatientOrderManagementDialog' },
)

type LabOrderProps = {
  labTests: LabOrderLabTest[]
  options: LabOrderOptions
  vendorId: string
}

interface MultiPatientOrderManagementDialogProps {
  clientId: string
  hasIntegrationMap: Record<string, boolean>
  invoiceId?: string
  labTestsMap: Record<
    string,
    Record<string, Record<LabTestGroupKeys | string, Order[]>>
  >
  onCancelOrder: (vendorId: string, orderId: string) => void
  onClose?: () => void
  open: boolean
  ordersMap: Record<string, LabOrder>
}

const MultiPatientOrderManagementDialog = ({
  invoiceId,
  labTestsMap,
  ordersMap,
  hasIntegrationMap,
  open,
  clientId,
  onClose,
  onCancelOrder,
}: MultiPatientOrderManagementDialogProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Dialogs', 'Errors'])

  const doctorId = useSelector(getDoctorId)
  const doctor = useSelector(getUser(doctorId))
  const LabVendors = useSelector(getLabVendors)
  const soapsWithNoDoctor = useSelector(getSoapsWithNoDoctor)
  const logIdsForInvoiceWithNoProducer = useSelector(
    getLogIdsForInvoiceWithNoProducer,
  )
  const LabOrdersStatusesList = useSelector(getLabOrderStatuses)
  const error = useSelector(getLabOrdersError)
  const isLoading = useSelector(getLabOrdersIsLoading)
  const isChargeSheetLoading = useSelector(getClientFinanceLoading)
  const isInvoiceLoading = useSelector(getInvoiceV3Loading)
  const chargeSheetSubItemsMap = useSelector(getChargeSheetSubItemsMap)
  const invoiceSections = useSelector(getInvoiceV3SubItemsMap)

  const [vendorIdAfterPlacingToFinalize, setVendorIdAfterPlacingToFinalize] =
    useState<string>()
  const [createdOrderId, setCreatedOrderId] = useState<string | null>(null)
  const [ordersHash, setOrdersHash] = useState('')
  const [shouldFinalizeOrder, setShouldFinalizeOrder] = useState(false)
  const [labOrderProps, setLabOrderProps] = useState<LabOrderProps>()

  const [openSoapValidationDialog] = useDialog(DialogNames.SOAP_VALIDATION)
  const [openOTCValidationDialog] = useDialog(DialogNames.OTC_VALIDATION)
  const [openLabVendorProvidersDialog] = useDialog(
    DialogNames.LAB_VENDOR_PROVIDERS_DIALOG,
  )
  const [openAddNewLabTestDialog] = useDialog(DialogNames.ADD_NEW_LAB_TEST)
  const [openOrderResultsPreviewDialog] = useDialog(
    DialogNames.VIEW_ORDER_RESULTS_FRAME,
  )
  const [openOrderResultsEmailDialog] = useDialog(
    DialogNames.EMAIL_LAB_ORDER_RESULT,
  )
  const [openPrintLabOrderLabelDialog] = useDialog(
    DialogNames.PRINT_LAB_ORDER_LABEL,
  )
  const [openLabOrderAssignmentDialog] = useDialog(
    DialogNames.ASSIGNED_VET_VALIDATION,
  )
  const [openOrderResultsDialog] = useDialog(DialogNames.ORDER_RESULTS)
  const [openFinalizeLabOrderDialog] = useDialog(
    DialogNames.FINALIZE_LAB_ORDER,
    onClose,
  )
  const [openErrorAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const actualOrderIds = Object.keys(ordersMap || {})
  const actualOrdersHash = actualOrderIds.sort().join('|')

  useEffect(() => {
    if (actualOrdersHash && ordersHash !== actualOrdersHash) {
      const oldOrderIds = ordersHash.split('|')
      const idsDiff = R.symmetricDifference(actualOrderIds, oldOrderIds)
      setCreatedOrderId(idsDiff[0])
      setOrdersHash(actualOrdersHash)
    }
  }, [actualOrdersHash])

  const finalizeAfterPlacing = useCloseAfterCreation(() => {
    const shouldFinalize = logIdsForInvoiceWithNoProducer.length === 0
    if (error) {
      openErrorAlert({
        iconType: AlertIconType.WARN,
        message: t('Errors:THE_ORDER_CANNOT_BE_PLACED'),
      })
    } else if (shouldFinalize) {
      setShouldFinalizeOrder(true)
      dispatch(refetchChargeSheet())
    }
  }, getLabOrderIsPlacing)

  const setProducerIdToInvoiceLineItemCallback = useCallback(
    (producerId?: string, logIds?: string[]) => {
      if (R.isNil(logIds)) {
        return
      }

      const ordersTobeUp = Object.values(labTestsMap)
        .flatMap((innerRecor) => Object.values(innerRecor))
        .flatMap((innerRecor) => Object.values(innerRecor))
        .flatMap((array) => array)
        .filter((order) => R.includes(order.logId, logIds))
        .map((order) => ({
          expectedModification: order.lineItem?.modificationDate,
          id: order.lineItem?.id,
          producerId,
        })) as InvoiceLineItemProducerInput2[]
      if (invoiceId) {
        dispatch(updateInvoiceLineItemProducerBatch(ordersTobeUp))
      } else {
        dispatch(
          editChargeSheetProducerItemBatch({
            clientId,
            updateItemProducerInput: ordersTobeUp,
          }),
        )
      }
    },
    [logIdsForInvoiceWithNoProducer.length],
  )

  const handlePlaceOrder = (props: LabOrderProps | undefined) => {
    const { vendorId, labTests, options } = props || {}
    if (vendorId && labTests && options) {
      dispatch(placeLabOrder(undefined, vendorId, labTests, options))
      finalizeAfterPlacing()
    }
  }

  useEffect(() => {
    if (soapsWithNoDoctor.length > 0) {
      openLabOrderAssignmentDialog({
        soapsWithNoDoctor,
        onRetry: () => {
          handlePlaceOrder(labOrderProps)
        },
      })
    }

    return () => {
      dispatch(setSoapsWithNoDoctor([]))
    }
  }, [soapsWithNoDoctor.length])

  useEffect(() => {
    if (logIdsForInvoiceWithNoProducer.length > 0) {
      openLabOrderAssignmentDialog({
        logIdsForInvoiceWithNoProducer,
        onRetry: (producerId?: string, logIds?: string[]) =>
          setProducerIdToInvoiceLineItemCallback(producerId, logIds),
      })
    }
    return () => {
      dispatch(setLogIdsForInvoiceWithNoProducer([]))
    }
  }, [logIdsForInvoiceWithNoProducer.length])

  const onPlaceLabOrder = (
    vendorId: string,
    labTests: LabOrderLabTest[],
    options: LabOrderOptions,
  ) => {
    dispatch(setSoapsWithNoDoctor([]))
    const props = { vendorId, labTests, options }
    setLabOrderProps(props)
    handlePlaceOrder(props)
  }

  const handleAddLabTest = (
    vendorId: string,
    state: string,
    soapId?: string | Nil,
  ) => {
    openAddNewLabTestDialog({
      vendorId,
      state,
      soapId: !R.isNil(soapId) ? soapId : undefined,
    })
  }

  const onFinalizeOrder = ({
    soapId: orderSoapId,
    vendorId,
    id,
    statusId,
    iframeUrl,
    businessId,
  }: LabOrder) => {
    openFinalizeLabOrderDialog({
      soapId: orderSoapId,
      soapBusinessId: businessId,
      vendorId,
      orderId: id,
      statusId,
      url: iframeUrl,
      invoiceId,
    })
  }

  useEffect(() => {
    if (
      !vendorIdAfterPlacingToFinalize ||
      !shouldFinalizeOrder ||
      !createdOrderId
    ) {
      return
    }

    const createdOrder = ordersMap?.[createdOrderId] || {}
    const { iframeUrl, statusId } = createdOrder

    setVendorIdAfterPlacingToFinalize(undefined)
    setShouldFinalizeOrder(false)

    if (iframeUrl) {
      onFinalizeOrder(createdOrder)
    } else if (statusId && !iframeUrl && onClose) {
      onClose()
    }
  }, [shouldFinalizeOrder, createdOrderId])

  useEffect(() => {
    if (logIdsForInvoiceWithNoProducer.length === 0 && shouldFinalizeOrder) {
      if (onClose) {
        onClose()
      }
    }
  }, [logIdsForInvoiceWithNoProducer.length, shouldFinalizeOrder])

  const handlePlaceLabOrder = (
    vendorId: string,
    labTests: Order[],
    deviceSerialNumber: string | undefined,
  ) => {
    const vendorName = Utils.getConstantName(vendorId, LabVendors)
    const isZnLabVendor = vendorName === LabVendorNames.ZN_LABS

    const setZnLabVendorIfNeeded = (
      onOk: (znlabsProviderIdentifier?: string) => void,
    ) => {
      if (isZnLabVendor) {
        if (doctor?.znlabsProviderIdentifier) {
          onOk(doctor.znlabsProviderIdentifier)
        } else {
          openLabVendorProvidersDialog({ onOk })
        }
      } else {
        onOk()
      }
    }

    const proceedToPlace = (znLabsProviderIdentifier?: string) => {
      const options: LabOrderOptions = { deviceSerialNumber }

      if (znLabsProviderIdentifier) {
        options.znLabsProviderIdentifier = znLabsProviderIdentifier
      }

      onPlaceLabOrder(vendorId, labTests, options)
      setCreatedOrderId(null)
      setVendorIdAfterPlacingToFinalize(vendorId)
    }
    const labTestOrder = labTests[0]
    const { patientId } = labTestOrder

    if (!labTestOrder.soapId) {
      openOTCValidationDialog({
        clientId,
        patientId,
        onOk: () => setZnLabVendorIfNeeded(proceedToPlace),
      })
    } else {
      openSoapValidationDialog({
        clientId,
        patientId,
        onOk: () => setZnLabVendorIfNeeded(proceedToPlace),
      })
    }
  }

  const onPrintLabel = (
    order: LabOrder | undefined,
    vendorId: string,
    sectionId?: string,
  ) => {
    openPrintLabOrderLabelDialog({
      clientId,
      patientId: sectionId
        ? invoiceId
          ? invoiceSections[sectionId]?.patientId
          : chargeSheetSubItemsMap[sectionId]?.patientId
        : null,
      labOrderId: order?.labOrderId,
      orderDate: order?.date,
      vendorId,
    })
  }

  const onArtifactsPreview = (
    isResult: boolean,
    order: LabOrder,
    vendorId: string,
  ) => {
    if (!isResult && !R.isEmpty(order) && !R.isNil(order)) {
      return onFinalizeOrder(order)
    }

    const orderStatus = Utils.getConstantName(
      order.statusId,
      LabOrdersStatusesList,
    )

    return openOrderResultsPreviewDialog({
      pdfUrl: order.resultPdfUrl,
      vendorId,
      orderId: order.id,
      canComplete: orderStatus === LabOrdersStatus.RESULTS_RECEIVED,
      resultIdentifier: order.resultIdentifier,
      resultFrameUrl: order.resultIframeUrl,
    })
  }

  const onArtifactsPrint = (
    isResult: boolean,
    order: LabOrder,
    vendorId: string,
  ) => {
    const artifactUrl = isResult ? order.resultPdfUrl : order.pdfUrl

    openOrderResultsDialog({
      pdfUrl: artifactUrl,
      vendorId,
      orderId: order.id,
      canComplete: false,
      hasResults: isResult,
    })
  }

  const onPrintOrPreview = (
    isResult: boolean,
    shouldPrint: boolean,
    order: LabOrder,
    vendorId: string,
  ) =>
    shouldPrint
      ? onArtifactsPrint(isResult, order, vendorId)
      : onArtifactsPreview(isResult, order, vendorId)

  const onEmailResult = (order: LabOrder, vendorId: string) => {
    openOrderResultsEmailDialog({
      clientId,
      patientId: null,
      orderId: order?.id,
      vendorId,
      soapId: order?.soapId,
    })
  }

  return (
    <PuiDialog
      aria-labelledby="order-management-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={t('Dialogs:ORDER_MANAGEMENT_DIALOG.TITLE')}
      onClose={onClose}
    >
      <Grid container alignItems="center" direction="column" p={2}>
        {isLoading || isChargeSheetLoading || isInvoiceLoading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : (
          Object.keys(labTestsMap).map((vendorId) => {
            const vendorLabTests = labTestsMap[vendorId]
            const labVendorName = Utils.getConstantName(vendorId, LabVendors)
            return (
              <Grid
                container
                alignItems="left"
                direction="column"
                key={vendorId}
                p={2}
              >
                <Text strong className={classes.vendorName} mb={1}>
                  {labVendorName}
                </Text>
                {Object.keys(vendorLabTests).map((sectionId) => {
                  const {
                    [LabTestGroupKeys.READY_FOR_ORDER]: readyForOrder,
                    [LabTestGroupKeys.NOT_READY_FOR_ORDER]: notReadyForOrder,
                    ...ordersLabTests
                  } = vendorLabTests[sectionId]
                  const onAddLab = (stateId: string, soapId?: string | Nil) =>
                    handleAddLabTest(vendorId, stateId, soapId)

                  const isIdexx =
                    labVendorName === LabVendorNames.IDEXX ||
                    labVendorName === LabVendorNames.IDEXX_ES
                  const hasIntegration = hasIntegrationMap[vendorId]
                  return (
                    <Grid
                      container
                      alignItems="left"
                      direction="column"
                      key={sectionId}
                    >
                      <OrderNameTitle sectionId={sectionId} />
                      <LabSection
                        hideName
                        refetchChargeSheet
                        hasIntegration={hasIntegration}
                        invoiceId={invoiceId}
                        key={vendorId}
                        labTestsMap={ordersLabTests as Record<string, Order[]>}
                        name={labVendorName}
                        notReadyForOrder={notReadyForOrder}
                        ordersMap={ordersMap}
                        readyForOrder={readyForOrder}
                        showDevices={isIdexx && hasIntegration}
                        vendorId={vendorId}
                        onAddLab={onAddLab}
                        onCancelOrder={(orderId: string) =>
                          onCancelOrder(vendorId, orderId)
                        }
                        onEmailResult={onEmailResult}
                        onFinalizeOrder={onFinalizeOrder}
                        onPlaceLabOrder={(
                          labTests: Order[],
                          deviceSerialNumber: string | undefined,
                        ) =>
                          handlePlaceLabOrder(
                            vendorId,
                            labTests,
                            deviceSerialNumber,
                          )
                        }
                        onPrintLabel={(order?: LabOrder) =>
                          onPrintLabel(order, vendorId, sectionId)
                        }
                        onPrintOrPreview={onPrintOrPreview}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )
          })
        )}
      </Grid>
    </PuiDialog>
  )
}

export default MultiPatientOrderManagementDialog
