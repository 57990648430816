import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertIconType } from '@pbt/pbt-ui-components'

import DialogNames from '~/constants/DialogNames'
import { clearOrderCanNotBeRemovedMessage } from '~/store/actions/orders'
import { getOrderCanNotBeRemovedErrorMessage } from '~/store/reducers/orders'
import useDialog from '~/utils/useDialog'

export const useChargeOrderAlert = () => {
  const dispatch = useDispatch()

  const orderCanNotBeRemovedErrorMessage = useSelector(
    getOrderCanNotBeRemovedErrorMessage,
  )

  const [openOrderCanNotBeRemoved] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  useEffect(() => {
    const handleOrderCanNotBeRemoved = () => {
      dispatch(clearOrderCanNotBeRemovedMessage)
    }

    if (orderCanNotBeRemovedErrorMessage) {
      openOrderCanNotBeRemoved({
        iconType: AlertIconType.WARN,
        message: orderCanNotBeRemovedErrorMessage,
        onOk: handleOrderCanNotBeRemoved,
        onClose: handleOrderCanNotBeRemoved,
      })
    }
  }, [orderCanNotBeRemovedErrorMessage])
}
