import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { StateLabel } from '@pbt/pbt-ui-components'

import i18n from '~/locales/i18n'
import {
  ProblemLogBodySystemContainer,
  ProblemLogProblemContainer,
} from '~/types'
import { isToday } from '~/utils/time'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.colors.success,
      color: theme.palette.common.white,
    },
  }),
  { name: 'IdentifiedProblemListItemTag' },
)

const getTagTitle = (
  log: ProblemLogProblemContainer | ProblemLogBodySystemContainer,
): string | null => {
  if (log.entity.isCopied) {
    return null
  }

  if (isToday(log.entity.creationDate)) {
    return i18n.t('Soap:PROBLEMS.TAGS.NEW')
  }

  if (isToday(log.entity.modificationDate)) {
    return i18n.t('Soap:PROBLEMS.TAGS.UPDATED_TODAY')
  }

  return null
}

interface IdentifiedProblemListItemTagProps {
  log: ProblemLogProblemContainer | ProblemLogBodySystemContainer
}

const IdentifiedProblemListItemTag = ({
  log,
}: IdentifiedProblemListItemTagProps) => {
  const classes = useStyles()
  const tagTitle = getTagTitle(log)

  if (!tagTitle) {
    return null
  }

  return (
    <StateLabel success className={classes.root} ml={1} variant="small">
      {tagTitle}
    </StateLabel>
  )
}

export default IdentifiedProblemListItemTag
