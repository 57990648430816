import i18n from '~/locales/i18n'

export enum PrescriptionWorkflowType {
  PRESCRIBE = 'Prescribe',
  ORDER = 'Order',
  OUTSIDE_PHARMACY = 'Outside pharmacy',
  CHEWY_REACTIVE_RX = 'Chewy Reactive Rx',
  CHEWY_ACTIVE_RX = 'Chewy Active Rx',
}

export const PrescriptionWorkflowName = {
  [PrescriptionWorkflowType.PRESCRIBE]: i18n.t('Common:PRESCRIBE_ACTION'),
  [PrescriptionWorkflowType.ORDER]: i18n.t('Common:ORDER_ACTION'),
  [PrescriptionWorkflowType.OUTSIDE_PHARMACY]: i18n.t(
    'Common:OUTSIDE_PHARMACY',
  ),
  [PrescriptionWorkflowType.CHEWY_REACTIVE_RX]: i18n.t(
    'Common:CHEWY_BRAND_NAME',
  ),
  [PrescriptionWorkflowType.CHEWY_ACTIVE_RX]: i18n.t('Common:CHEWY_BRAND_NAME'),
}

export enum PrescriptionDeclineReasonType {
  PET_NEEDS_TO_BE_SEEN = 'Pet needs to be seen',
  WRONG_PRODUCT = 'Wrong product (Item or Dosage)',
  WRONG_PET = 'Wrong Pet',
  NOT_A_CLINIC_PATIENT = 'Not a clinic patient',
  OTHER = 'Other',
}

export const PrescriptionDeclineReasonName = {
  [PrescriptionDeclineReasonType.PET_NEEDS_TO_BE_SEEN]: i18n.t(
    'Common:DECLINE_REASONS.PET_NEEDS_TO_BE_SEEN',
  ),
  [PrescriptionDeclineReasonType.WRONG_PRODUCT]: i18n.t(
    'Common:DECLINE_REASONS.WRONG_PRODUCT',
  ),
  [PrescriptionDeclineReasonType.WRONG_PET]: i18n.t(
    'Common:DECLINE_REASONS.WRONG_PET',
  ),
  [PrescriptionDeclineReasonType.NOT_A_CLINIC_PATIENT]: i18n.t(
    'Common:DECLINE_REASONS.NOT_A_CLINIC_PATIENT',
  ),
  [PrescriptionDeclineReasonType.OTHER]: i18n.t('Common:DECLINE_REASONS.OTHER'),
}
