import * as R from 'ramda'
import { AnyAction } from 'redux'
import { all, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { ApiError } from '@pbt/pbt-ui-components'

import * as API from '~/api'
import {
  ImagingVendorConfig,
  ImagingVendorIntegrationTesting,
} from '~/types/entities/imagingVendorConfig'
import { updateByPath } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'
import requestAPI from '../sagas/utils/requestAPI'

export const FETCH_IMAGING_VENDOR_CONFIGS =
  'imagingVendorConfig/FETCH_IMAGING_VENDOR_CONFIGS'
export const FETCH_IMAGING_VENDOR_CONFIGS_SUCCESS =
  'imagingVendorConfig/FETCH_IMAGING_VENDOR_CONFIGS_SUCCESS'
export const FETCH_IMAGING_VENDOR_CONFIGS_FAILURE =
  'imagingVendorConfig/FETCH_IMAGING_VENDOR_CONFIGS_FAILURE'

export const EDIT_IMAGING_VENDOR_CONFIG =
  'imagingVendorConfig/EDIT_IMAGING_VENDOR_CONFIG'
export const EDIT_IMAGING_VENDOR_CONFIG_SUCCESS =
  'imagingVendorConfig/EDIT_IMAGING_VENDOR_CONFIG_SUCCESS'
export const EDIT_IMAGING_VENDOR_CONFIG_FAILURE =
  'imagingVendorConfig/EDIT_IMAGING_VENDOR_CONFIG_FAILURE'

export const fetchImagingVendorConfigs = (businessId: string) => ({
  type: FETCH_IMAGING_VENDOR_CONFIGS,
  businessId,
})

export const fetchImagingVendorConfigsSuccess = (
  businessId: string,
  configs: Record<string, ImagingVendorConfig>,
  list: string[],
) => ({
  type: FETCH_IMAGING_VENDOR_CONFIGS_SUCCESS,
  businessId,
  configs,
  list,
})

export const fetchImagingVendorConfigsFailure = (error: ApiError) => ({
  type: FETCH_IMAGING_VENDOR_CONFIGS_FAILURE,
  error,
})

export const editImagingVendorConfig = (
  businessId: string,
  vendorId: string,
  config: ImagingVendorConfig,
) => ({
  businessId,
  vendorId,
  config,
  type: EDIT_IMAGING_VENDOR_CONFIG,
})

export const editImagingVendorConfigSuccess = (
  businessId: string,
  config: ImagingVendorConfig,
  success: boolean,
  status: string,
) => ({
  businessId,
  config,
  success,
  status,
  type: EDIT_IMAGING_VENDOR_CONFIG_SUCCESS,
})

export const editImagingVendorConfigFailure = (
  error: ApiError,
  businessId: string,
  vendorId: string,
  config: ImagingVendorConfig,
) => ({
  error,
  businessId,
  vendorId,
  config,
  type: EDIT_IMAGING_VENDOR_CONFIG_FAILURE,
})

export const IMAGING_VENDOR_CONFIG_SENSITIVE_ACTIONS = [
  {
    type: EDIT_IMAGING_VENDOR_CONFIG,
    sensitiveData: ['config'],
  },
  {
    type: EDIT_IMAGING_VENDOR_CONFIG_SUCCESS,
    sensitiveData: ['config'],
  },
  {
    type: EDIT_IMAGING_VENDOR_CONFIG_FAILURE,
    sensitiveData: ['config'],
  },
  {
    type: FETCH_IMAGING_VENDOR_CONFIGS_SUCCESS,
    sensitiveData: ['configs'],
  },
]

export type ImagingVendorConfigState = {
  error: string | null
  integrationTesting: Record<
    string,
    Record<string, ImagingVendorIntegrationTesting>
  >
  isReceiving: boolean
  isSending: boolean
  listByBusiness: Record<string, string[]>
  map: Record<string, Record<string, ImagingVendorConfig>>
}

const INITIAL_STATE = {
  map: {},
  listByBusiness: {},
  integrationTesting: {},
  isReceiving: false,
  isSending: false,
  error: null,
}

export const imagingVendorConfigReducer = (
  state: ImagingVendorConfigState = INITIAL_STATE,
  action: AnyAction,
): ImagingVendorConfigState => {
  switch (action.type) {
    case FETCH_IMAGING_VENDOR_CONFIGS:
      return {
        ...state,
        isReceiving: true,
      }
    case FETCH_IMAGING_VENDOR_CONFIGS_SUCCESS:
      return {
        ...state,
        map: {
          ...state.map,
          [action.businessId]: action.configs,
        },
        listByBusiness: {
          ...state.listByBusiness,
          [action.businessId]: action.list,
        },
        isReceiving: false,
      }
    case FETCH_IMAGING_VENDOR_CONFIGS_FAILURE:
      return {
        ...state,
        isReceiving: false,
        error: getErrorMessage(action.error),
      }
    case EDIT_IMAGING_VENDOR_CONFIG:
      return {
        ...state,
        integrationTesting: updateByPath(
          [action.businessId, action.config.vendorId],
          { testing: true, success: undefined, status: undefined },
          state.integrationTesting,
        ),
        isSending: true,
      }
    case EDIT_IMAGING_VENDOR_CONFIG_SUCCESS:
      return {
        ...state,
        integrationTesting: updateByPath(
          [action.businessId, action.config.vendorId],
          { testing: false, success: action.success, status: action.status },
          state.integrationTesting,
        ),
        map: {
          ...state.map,
          [action.businessId]: {
            ...state.map[action.businessId],
            [action.config.vendorId]: action.config,
          },
        },
        isSending: false,
      }
    case EDIT_IMAGING_VENDOR_CONFIG_FAILURE:
      return {
        ...state,
        integrationTesting: {
          ...state.integrationTesting,
          [action.businessId]: R.omit(
            [action.config.vendorId],
            state.integrationTesting[action.businessId],
          ),
        },
        isSending: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getImagingConfig = (state: RootState): ImagingVendorConfigState =>
  state.imagingVendorConfig
export const getImagingConfigIsReceiving = (state: RootState) =>
  getImagingConfig(state).isReceiving
export const getIntegrationTesting = (state: RootState) =>
  getImagingConfig(state).integrationTesting
export const getIntegrationTestingForBusiness = (businessId: string) =>
  createSelector(
    getIntegrationTesting,
    (integrationTesting) => integrationTesting[businessId] || {},
  )
export const getIntegrationTestingForVendor = (
  businessId: string,
  vendorId: string,
) =>
  createSelector(
    getIntegrationTestingForBusiness(businessId),
    (integrationTestingForBusiness) =>
      integrationTestingForBusiness[vendorId] || {},
  )
export const getImagingConfigsMap = (state: RootState) =>
  getImagingConfig(state).map
export const getImagingConfigsMapForBusiness = (businessId: string) =>
  createSelector(
    getImagingConfigsMap,
    (mapByBusiness) => mapByBusiness[businessId] || {},
  )
export const getImagingConfigsList = (state: RootState) =>
  getImagingConfig(state).listByBusiness
export const getImagingConfigsListForBusiness = (businessId: string) =>
  createSelector(
    getImagingConfigsList,
    (listByBusiness) => listByBusiness[businessId] || [],
  )
export const getMultipleImagingConfigs = (ids: string[], businessId: string) =>
  createSelector(
    getImagingConfigsMapForBusiness(businessId),
    (imagingConfigsMap) => R.props(ids, imagingConfigsMap),
  )
export const getImagingConfigs = (businessId: string) =>
  createSelector(
    [
      getImagingConfigsMapForBusiness(businessId),
      getImagingConfigsListForBusiness(businessId),
    ],
    (imagingConfigsMap, list) => list.map((id) => imagingConfigsMap[id]),
  )

export const getImagingVendorSanitized = (state: RootState) => ({
  ...state.imagingVendorConfig,
  map: R.map(
    (map) =>
      R.map(
        (key) =>
          key.password || key.locationToken
            ? { ...key, password: '*', locationToken: '*' }
            : key,
        map,
      ),
    state.imagingVendorConfig.map ?? {},
  ),
})

export function* fetchConfigsSaga({
  businessId,
}: ReturnType<typeof fetchImagingVendorConfigs>) {
  try {
    const {
      result: list,
      entities: { imagingVendorConfigs = {} },
    } = yield* requestAPI(API.fetchImagingVendorConfigs, businessId)
    yield put(
      fetchImagingVendorConfigsSuccess(businessId, imagingVendorConfigs, list),
    )
  } catch (error) {
    yield put(fetchImagingVendorConfigsFailure(error as ApiError))
  }
}

export function* editConfigSaga({
  businessId,
  vendorId,
  config,
}: ReturnType<typeof editImagingVendorConfig>) {
  try {
    const { status } = yield* requestAPI(
      API.editImagingVendorConfig,
      businessId,
      vendorId,
      config,
    )
    yield put(
      editImagingVendorConfigSuccess(
        businessId,
        config,
        status === 'OK',
        status,
      ),
    )
  } catch (error) {
    yield put(
      editImagingVendorConfigFailure(
        error as ApiError,
        businessId,
        vendorId,
        config,
      ),
    )
  }
}

function* watchFetchConfigs() {
  yield takeEvery(FETCH_IMAGING_VENDOR_CONFIGS, fetchConfigsSaga)
}

function* watchEditConfig() {
  yield takeLeading(EDIT_IMAGING_VENDOR_CONFIG, editConfigSaga)
}

export function* imagingVendorConfigSaga() {
  yield all([watchFetchConfigs(), watchEditConfig()])
}
