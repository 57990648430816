export const EasterEggEvents = {
  RESOLVE_CHRONIC_PROBLEM: 'chronicProblemResolved',
  ADD_APPOINTMENT: 'appointmentAdded',
  TASK_DONE: 'taskCompleted',
  LAB_WORK_STATUS_UPDATE: 'labWorkStatusUpdated',
  ARCHIVE_CONVERSATION: 'conversationArchived',
  UPDATE_CLIENT_DETAILS: 'clientDetailsUpdated',
  UPDATE_PATIENT_DETAILS: 'patientDetailsUpdated',
  SAVE_INVENTORY: 'inventorySaved',
}
