import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { PermissionArea } from '@pbt/pbt-ui-components'

import { getCRUDByArea } from '~/store/reducers/auth'
import { CommunicationSnapshotItem, WidgetColumn } from '~/types'

import Snapshot from './Snapshot'

const columns: WidgetColumn<CommunicationSnapshotItem>[] = [
  {
    id: 'name',
    label: '',
    prop: 'name',
    width: 5.5,
  },
]

const CommunicationsSnapshot = () => {
  const { t } = useTranslation('Common')

  const { read: communicationReadPermissions } = useSelector(
    getCRUDByArea(PermissionArea.COMMUNICATION),
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleGoToConversation = (item: CommunicationSnapshotItem) => {}

  const getActions = (item: CommunicationSnapshotItem) => [
    {
      id: 'goToConversation',
      label: t('Common:GO_TO_CONVERSATION'),
      Icon: ArrowForwardIcon,
      onClick: communicationReadPermissions
        ? () => handleGoToConversation(item)
        : undefined,
      disabled: !communicationReadPermissions,
    },
  ]

  return (
    <Snapshot
      noItems
      addActionName={t('Common:SEND_A_MESSAGE')}
      columns={columns}
      data={[] as CommunicationSnapshotItem[]}
      getActions={getActions}
      isLoading={false}
      noRecentItems={false}
      title={t('Common:COMMUNICATIONS')}
    />
  )
}

export default CommunicationsSnapshot
