import React, { useState } from 'react'
import { TableHead } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { Invoice, InvoiceLineItem, InvoiceLineItemGroup } from '~/types'

import { discountPercToAmount, isRange } from '../../invoiceUtils'
import EstimateRangeRow from './rows/estimate-range/EstimateRangeRow'
import InvoiceTableHeadRow from './rows/head/InvoiceTableHeadRow'
import InvoiceTableSubHeadRow from './rows/sub-head/InvoiceTableSubHeadRow'

const useStyles = makeStyles(
  (theme) => ({
    tableHead: {
      borderTop: theme.constants.tabBorder,
      borderBottom: theme.constants.tabBorder,
    },
    readOnly: {
      pointerEvents: 'none',
    },
  }),
  { name: 'InvoiceTableHead' },
)

export interface InvoiceTableHeadProps {
  invoice: Invoice
  isEstimate: boolean
  isReadOnly: boolean
  prePaidEnabled: boolean
  rangeEnabled: boolean
  selectedColumns: string[]
  setRangeEnabled: (rangeEnabled: boolean) => void
  setSelectedColumns: (columns: string[]) => void
  updateInvoice: (invoice: Invoice) => void
}

const InvoiceTableHead = ({
  selectedColumns,
  setSelectedColumns,
  invoice,
  isEstimate,
  updateInvoice,
  prePaidEnabled,
  isReadOnly,
  rangeEnabled,
  setRangeEnabled,
}: InvoiceTableHeadProps) => {
  const classes = useStyles()

  const [openPopperId, setOpenPopperId] = useState<string | null>(null)

  const groups = (invoice && invoice.groups) || []

  const toggleApplierPopperOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element
    setOpenPopperId(target.id === openPopperId ? null : target.id)
  }

  const onPopperClose = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element
    if (target.id !== openPopperId) {
      setOpenPopperId(null)
    }
  }

  const getUpdatedItem = (
    item: InvoiceLineItem,
    field: string,
    value: number | boolean | '',
  ) => {
    if (field === 'discountPerc') {
      if (item.discountAllowed) {
        const newItem = { ...item, discountPerc: value as number }
        const discountAmount = isRange(item.quantity)
          ? null
          : discountPercToAmount(newItem)
        return { ...newItem, discountAmount }
      }
      return item
    }
    if (field === 'taxed' && !item.priceTaxable) {
      return item
    }
    return { ...item, [field]: value }
  }

  const onPopperApplyToAll = (field: string, value: number | boolean | '') => {
    const newGroups: InvoiceLineItemGroup[] = groups.map((group) => {
      const newGroupedItems = group.groupedItems.map((item) => {
        if (item.items) {
          const newItems = item.items.map((childItem) =>
            getUpdatedItem(childItem, field, value),
          )
          return { ...item, items: newItems }
        }
        return getUpdatedItem(item, field, value)
      })
      return { ...group, groupedItems: newGroupedItems }
    })

    updateInvoice({ ...invoice, groups: newGroups })

    setOpenPopperId(null)
  }

  const onPopperApplyToTotal = (value: number) => {
    updateInvoice({ ...invoice, additionalDiscount: value })
    setOpenPopperId(null)
  }

  return (
    <TableHead
      className={classNames(classes.tableHead, {
        [classes.readOnly]: isReadOnly,
      })}
    >
      <InvoiceTableHeadRow
        invoice={invoice}
        isEstimate={isEstimate}
        openPopperId={openPopperId}
        prePaidEnabled={prePaidEnabled}
        rangeEnabled={rangeEnabled}
        selectedColumns={selectedColumns}
        setRangeEnabled={setRangeEnabled}
        setSelectedColumns={setSelectedColumns}
        toggleApplierPopperOpen={toggleApplierPopperOpen}
        updateInvoice={updateInvoice}
        onPopperApplyToAll={onPopperApplyToAll}
        onPopperClose={onPopperClose}
      />
      <EstimateRangeRow
        isEstimate={isEstimate}
        rangeEnabled={rangeEnabled}
        selectedColumns={selectedColumns}
      />
      <InvoiceTableSubHeadRow
        invoice={invoice}
        isEstimate={isEstimate}
        openPopperId={openPopperId}
        prePaidEnabled={prePaidEnabled}
        rangeEnabled={rangeEnabled}
        selectedColumns={selectedColumns}
        toggleApplierPopperOpen={toggleApplierPopperOpen}
        onPopperApplyToAll={onPopperApplyToAll}
        onPopperApplyToTotal={onPopperApplyToTotal}
        onPopperClose={onPopperClose}
      />
    </TableHead>
  )
}

export default InvoiceTableHead
