import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'

import { startMigrationAnalyze } from '~/store/actions/migration'
import {
  getCurrentSessionId,
  getIsStartingImport,
} from '~/store/reducers/migration'

import EntityTypesSelectList from './EntityTypesSelectList'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      padding: theme.spacing(0, 3),
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'StepDataReady' },
)

const StepDataReady = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Businesses')

  const [selectedEntities, setSelectedEntities] = useState<string[]>([])

  const isLoading = useSelector(getIsStartingImport)
  const sessionId = useSelector(getCurrentSessionId)

  const isAnyEntitySelected = Boolean(selectedEntities.length)

  const handleStartAnalysis = useCallback(() => {
    dispatch(startMigrationAnalyze(sessionId, undefined, selectedEntities))
  }, [sessionId, selectedEntities])

  return (
    <Grid container>
      <EntityTypesSelectList onSelectedEntitiesChanged={setSelectedEntities} />
      <Grid container justifyContent="flex-start" px={2} py={1}>
        <ButtonWithLoader
          className={classes.button}
          color="primary"
          disabled={isLoading || !isAnyEntitySelected}
          onClick={handleStartAnalysis}
        >
          {t('Businesses:MIGRATION.START_ANALYSIS')}
        </ButtonWithLoader>
      </Grid>
    </Grid>
  )
}

export default StepDataReady
