import React from 'react'
import { useSelector } from 'react-redux'
import { LanguageUtils, Utils } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import AccentLabel, {
  AccentLabelProps,
} from '~/components/common/labels/AccentLabel'
import { getEstimateStates } from '~/store/reducers/constants'
import { Estimate } from '~/types'

export interface EstimateStateLabelProps extends AccentLabelProps {
  estimate: Pick<Estimate | GraphqlEstimate, 'stateId' | 'state'>
}

const EstimateStateLabel = ({ estimate, ...rest }: EstimateStateLabelProps) => {
  const EstimateStates = useSelector(getEstimateStates)
  const StateConstant = Utils.findById(estimate.stateId, EstimateStates)
  const state = LanguageUtils.getConstantTranslatedName(
    estimate.stateId,
    EstimateStates,
  )

  return (
    <AccentLabel
      colorVariant={StateConstant?.colorVariant}
      name={state ?? estimate?.state}
      {...rest}
    />
  )
}

export default EstimateStateLabel
