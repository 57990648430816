import React from 'react'
import { useSelector } from 'react-redux'
import { Nil, TextInteractiveProps } from '@pbt/pbt-ui-components'

import useGetPreferredContactMethod from '~/components/dashboard/clients/details/new-client-and-patient/useGetPreferredContactMethod'
import { getUser } from '~/store/reducers/users'

import DetailsSeparatedLabel, { DetailsItem } from './DetailsSeparatedLabel'

export interface ClientInfoLabelProps extends TextInteractiveProps {
  clientId: string | Nil
}

const ClientInfoLabel = ({ clientId, ...rest }: ClientInfoLabelProps) => {
  const client = useSelector(getUser(clientId))

  const preferredContactMethod = useGetPreferredContactMethod({ client })

  const details = [
    Boolean(preferredContactMethod?.value) && {
      id: 'dob',
      text: preferredContactMethod?.value,
    },
  ].filter(Boolean) as DetailsItem[]

  return <DetailsSeparatedLabel details={details} {...rest} />
}

export default ClientInfoLabel
