import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import { MembershipType } from '~/constants/wellnessPlansConstants'
import { MembershipPlan } from '~/types'

interface PlanTypeCellProps {
  plan: MembershipPlan
}

const PlanTypeCell = ({ plan }: PlanTypeCellProps) => {
  const { t } = useTranslation('Common')

  const PlanDisplayNames = {
    [MembershipType.ADDON]: t('Common:PLAN'),
    [MembershipType.PACKAGE]: t('Common:SUBSCRIBE_AND_SAVES'),
    [MembershipType.BASE]: t('Common:PLAN'),
    [MembershipType.EXTRA]: t('Common:EXTRA'),
  }

  return <Text variant="body2">{PlanDisplayNames[plan.planType] || ''}</Text>
}

export default PlanTypeCell
