import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { EditorState, Modifier } from 'draft-js'
import {
  ButtonWithLoader,
  PuiPopper,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import { LINK } from './decorator/link'

interface LinkPopperProps {
  anchorEl: HTMLElement | null
  editorState: EditorState
  onClose: () => void
  onFocus: () => void
  open: boolean
  setEditorState: (editorState: EditorState) => void
}

export const LinkPopper: React.FC<LinkPopperProps> = ({
  anchorEl,
  open,
  onClose,
  editorState,
  setEditorState,
  onFocus,
}) => {
  const { t } = useTranslation('Common')
  const { fields, validate, reset } = useFields([
    {
      name: 'link',
      label: t('Common:LINK'),
      validators: ['required', 'website'],
      initialValue: '',
    },
    {
      name: 'text',
      label: t('Common:DISPLAY_TEXT_OPTIONAL'),
      initialValue: '',
    },
  ])

  const { link, text } = fields

  const handleClose = () => {
    onClose()
    reset()
    setTimeout(onFocus, 100)
  }

  const onAddLinkClick = () => {
    if (validate()) {
      const currentContent = editorState.getCurrentContent()
      const selection = editorState.getSelection()

      const contentStateWithEntity = currentContent.createEntity(
        LINK,
        'IMMUTABLE',
        {
          url: link.value,
        },
      )

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

      const textWithEntity = Modifier.replaceText(
        currentContent,
        selection,
        text.value || link.value,
        undefined,
        entityKey,
      )
      setEditorState(
        EditorState.push(editorState, textWithEntity, 'insert-characters'),
      )
      handleClose()
    }
  }

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      open={open}
      placement="bottom-start"
      sx={{ maxWidth: 300 }}
      onClose={handleClose}
    >
      <Grid container p={1}>
        <Grid item xs={12}>
          <PuiTextField field={link} label={link.label} sx={{ margin: 0 }} />
        </Grid>
        <Grid item xs={12}>
          <PuiTextField field={text} label={text.label} sx={{ margin: 0 }} />
        </Grid>
        <Grid item pt={1} xs={12}>
          <ButtonWithLoader fullWidth onClick={onAddLinkClick}>
            {t('Common:INSERT_LINK')}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </PuiPopper>
  )
}
