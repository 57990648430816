import React from 'react'
import { useSelector } from 'react-redux'
import { Constant, Patient, Text, TextProps } from '@pbt/pbt-ui-components'

import { getGender } from '~/store/reducers/constants'

import { getSpayedNeuteredIntactWithGenderString } from '../inputs/gender/genderUtils'

export interface NeuteringLabelProps extends TextProps {
  patient?: Patient
}

const NeuteringLabel = ({ patient, ...rest }: NeuteringLabelProps) => {
  const Gender: Constant[] = useSelector(getGender)

  if (!patient) {
    return null
  }

  const label = getSpayedNeuteredIntactWithGenderString(
    patient.gender,
    patient.spayedNeutered,
    Gender,
  )

  return (
    <Text noWrap align="left" {...rest}>
      {label}
    </Text>
  )
}

export default NeuteringLabel
