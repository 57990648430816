import React from 'react'
import { Box, BoxProps, Grid, Theme } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { Text } from '@pbt/pbt-ui-components'

type LabeledSectionProps = BoxProps & {
  labelText: string
  variant: 'warning' | 'success' | 'info'
}

const handleVariants = (
  labelVariant: LabeledSectionProps['variant'],
  theme: Theme,
) => {
  if (labelVariant === 'warning') {
    return css`
      color: ${theme.colors.important};
      background-color: ${theme.colors.actionNeededBackground};
    `
  }
  if (labelVariant === 'success') {
    return css`
      color: ${theme.colors.success};
      background-color: ${theme.colors.successBackground};
    `
  }
  return css`
    color: ${theme.colors.lowAccentText};
    background-color: ${theme.colors.tableOddRowBackground};
  `
}

type LabelVariantProps = {
  labelVariant: LabeledSectionProps['variant']
}

const StyledWrapperBox = styled(Box)`
  border: ${({ theme }) => theme.constants.tabBorder};
`

const StyledHeaderBox = styled(Box)<LabelVariantProps>`
  border-bottom: ${({ theme }) => theme.constants.tabBorder};
  ${({ labelVariant, theme }) => handleVariants(labelVariant, theme)}
`

const StyledLabelText = styled(Text)<LabelVariantProps>`
  color: inherit !important;
  font-size: 1.6rem;
`

export const LabeledSection = ({
  labelText,
  children,
  variant = 'info',
  ...props
}: LabeledSectionProps) => (
  <StyledWrapperBox
    display="flex"
    flexDirection="column"
    flexWrap="nowrap"
    {...props}
  >
    <StyledHeaderBox display="flex" labelVariant={variant} px={2} py={1}>
      <StyledLabelText labelVariant={variant}>{labelText}</StyledLabelText>
    </StyledHeaderBox>
    <Grid p={2}>{children}</Grid>
  </StyledWrapperBox>
)
