import { AnyAction } from 'redux'
import { Defaults } from '@pbt/pbt-ui-components'

import { TimelineEntryType } from '~/constants/timelineConstants'
import {
  GroupedAttachmentTimelineItemEntity,
  LabTestTimelineItem,
  TimelineItem,
} from '~/types'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import { DELETE_ESTIMATE_SUCCESS } from '../actions/types/finance'
import {
  FETCH_MORE_ITEMS_FOR_TIMELINE,
  FETCH_MORE_ITEMS_FOR_TIMELINE_FAILURE,
  FETCH_MORE_ITEMS_FOR_TIMELINE_SUCCESS,
  FETCH_TIMELINE,
  FETCH_TIMELINE_FAILURE,
  FETCH_TIMELINE_SUCCESS,
  OPTIMISTIC_ATTACHMENT_DELETE,
  OPTIMISTIC_LAB_TEST_FILE_DELETE_FROM_LAB_TEST_CARD,
  RESET_TIMELINE,
  UPDATE_TIMELINE_CLIENT_DATA,
  UPDATE_TIMELINE_DATE_RANGE,
  UPDATE_TIMELINE_FILTERS,
} from '../actions/types/timeline'
import { DELETE_REMINDER_SUCCESS } from '../duck/reminders'
import { DELETE_VITAL_SUCCESS } from '../duck/vitals'
import type { RootState } from '../index'

export type TimelineState = {
  clientId: string | null
  dateRange: string[]
  error: string | null
  filters: TimelineEntryType[]
  isLoading: boolean
  list: TimelineItem[]
  patientId: string | null
  totalCount: number
}

export const INITIAL_STATE: TimelineState = {
  list: [],
  error: null,
  isLoading: false,
  filters: [],
  clientId: null,
  patientId: null,
  dateRange: [],
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
}

const removeItemFromTimeline = (
  state: TimelineState,
  idProp: string,
  typeProp: TimelineEntryType,
) => {
  const list = state.list
    .map((listEntry) => ({
      ...listEntry,
      entries: listEntry
        ? listEntry.type === typeProp
          ? ((listEntry.entries || []) as string[]).filter(
              (id) => id !== idProp,
            )
          : listEntry.entries
        : [],
    }))
    .filter(
      (listEntry) =>
        !listEntry || listEntry.type !== typeProp || listEntry.entries?.length,
    ) as TimelineItem[]

  return {
    list,
    totalCount: state.totalCount - state.list.length + list.length,
  }
}

const removeFileFromAttachmentGroup = (
  state: TimelineState,
  idProp: string,
) => {
  const list = state.list
    .map((timelineItem) => {
      if (timelineItem.type !== TimelineEntryType.ATTACHMENT_GROUP) {
        return timelineItem
      }
      return {
        ...timelineItem,
        entries: (timelineItem.entries as GroupedAttachmentTimelineItemEntity[])
          .map((entryItem) => ({
            ...entryItem,
            files: entryItem.files?.filter(
              (fileItem) => fileItem?.id !== idProp,
            ),
          }))
          .filter((entryItem) => entryItem.files?.length),
      }
    })
    .filter(
      (timelineItem) =>
        timelineItem.type !== TimelineEntryType.ATTACHMENT_GROUP ||
        timelineItem.entries?.length,
    )
  return {
    list,
    totalCount: state.totalCount - state.list.length + list.length,
  }
}

const removeFileFromFileGroup = (state: TimelineState, fileId: string) =>
  state.list.map((timelineItem) => {
    if (timelineItem.type !== TimelineEntryType.LAB_TEST) {
      return timelineItem
    }
    return {
      ...timelineItem,
      entries: (timelineItem as LabTestTimelineItem).entries?.map(
        (entryItem) => ({
          ...entryItem,
          fileGroups: entryItem.fileGroups.map((groupItem) => ({
            ...groupItem,
            files: groupItem.files.filter((fileItem) => fileItem.id !== fileId),
          })),
        }),
      ),
    }
  })

const timeline = (
  state: TimelineState = INITIAL_STATE,
  action: AnyAction,
): TimelineState => {
  switch (action.type) {
    case FETCH_TIMELINE_FAILURE:
    case FETCH_MORE_ITEMS_FOR_TIMELINE_FAILURE:
      return {
        ...state,
        error: getErrorMessage(action.error),
        isLoading: false,
      }
    case FETCH_TIMELINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.list,
        totalCount: action.totalCount,
      }
    case FETCH_TIMELINE:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MORE_ITEMS_FOR_TIMELINE:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MORE_ITEMS_FOR_TIMELINE_SUCCESS:
      return {
        ...state,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        isLoading: false,
        totalCount: action.totalCount,
      }
    case UPDATE_TIMELINE_FILTERS:
      return {
        ...state,
        filters: action.filters || [],
        isLoading: true,
        list: [],
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case UPDATE_TIMELINE_DATE_RANGE:
      return { ...state, dateRange: action.dateRange, isLoading: true }
    case UPDATE_TIMELINE_CLIENT_DATA:
      const { clientId, patientId } = action.clientData
      return {
        ...state,
        clientId,
        patientId,
        isLoading: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
      }
    case OPTIMISTIC_ATTACHMENT_DELETE: {
      return {
        ...state,
        ...removeFileFromAttachmentGroup(state, action.attachmentId),
      }
    }
    case OPTIMISTIC_LAB_TEST_FILE_DELETE_FROM_LAB_TEST_CARD: {
      return {
        ...state,
        list: removeFileFromFileGroup(state, action.fileId),
      }
    }
    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        ...removeItemFromTimeline(
          state,
          action.reminderId,
          TimelineEntryType.REMINDER,
        ),
      }
    case DELETE_ESTIMATE_SUCCESS:
      const newList = state.list.filter(
        ({ type, id }) =>
          type !== TimelineEntryType.ESTIMATE || id !== action.estimateId,
      )

      const removedCount = state.list.length - newList.length
      return {
        ...state,
        list: newList,
        totalCount: state.totalCount - removedCount,
      }
    case DELETE_VITAL_SUCCESS:
      return {
        ...state,
        ...removeItemFromTimeline(
          state,
          action.vitalId,
          TimelineEntryType.VITAL,
        ),
      }
    case RESET_TIMELINE:
      return INITIAL_STATE
    default:
      return state
  }
}

export default timeline
export const getTimeline = (state: RootState): TimelineState => state.timeline
export const getTimelineList = (state: RootState) => getTimeline(state).list
export const getTimelineFilters = (state: RootState) =>
  getTimeline(state).filters
export const getTimelineIsLoading = (state: RootState) =>
  getTimeline(state).isLoading
export const getTimelineClientId = (state: RootState) =>
  getTimeline(state).clientId
export const getTimelinePatientId = (state: RootState) =>
  getTimeline(state).patientId
export const getTimelineDateRange = (state: RootState) =>
  getTimeline(state).dateRange
export const getTimelineTotalCount = (state: RootState) =>
  getTimeline(state).totalCount
