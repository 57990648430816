import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Text } from '@pbt/pbt-ui-components'

import { ConversationCategory } from '~/constants/communications'

import NoItemsScreen from '../../NoItemsScreen'

export interface NoConversationsScreenProps {
  Header: React.ReactNode
  categoryFilter: ConversationCategory
  onNewConversationClick: () => void
  onSentClick: () => void
}

const NoConversationsScreen = ({
  Header,
  categoryFilter,
  onSentClick,
  onNewConversationClick,
}: NoConversationsScreenProps) => {
  const { t } = useTranslation(['Common', 'Communications'])

  const mainMessage = {
    [ConversationCategory.DRAFT]: t(
      'Communications:NO_CONVERSATION_SCREEN.MAIN_MESSAGE_DRAFT',
    ),
    [ConversationCategory.SENT]: t(
      'Communications:NO_CONVERSATION_SCREEN.MAIN_MESSAGE_SENT',
    ),
    [ConversationCategory.INBOX]: (
      <Trans
        components={{
          textMessage: (
            <Text
              inline
              link
              component="span"
              variant="body"
              onClick={onSentClick}
            />
          ),
        }}
        i18nKey="Communications:NO_CONVERSATION_SCREEN.MAIN_MESSAGE_INBOX"
      />
    ),
  }

  return (
    <NoItemsScreen
      wideButton
      action={onNewConversationClick}
      actionMessage={t('Common:NEW_CLIENT_CONVERSATION')}
      title={Header}
    >
      <Text variant="body">
        {mainMessage[categoryFilter]}{' '}
        {t('Communications:NO_CONVERSATION_SCREEN.LOOKING_FOR_SOMETHING')}
      </Text>
    </NoItemsScreen>
  )
}

export default NoConversationsScreen
