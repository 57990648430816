import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  OutlinedSelect,
  OutlinedSelectProps,
} from '~/components/elements/OutlinedSelect/OutlinedSelect'
import {
  CardAndPaypalPaymentMethods,
  formatPaymentMethod,
} from '~/utils/cvcClient'

type ChewyPaymentMethodSelectProps = Omit<
  OutlinedSelectProps<CardAndPaypalPaymentMethods>,
  'label' | 'id'
>

export const ChewyPaymentMethodSelect = (
  props: ChewyPaymentMethodSelectProps,
) => {
  const { t } = useTranslation(['Common'])

  return (
    <OutlinedSelect
      getOptionLabel={formatPaymentMethod}
      id="chewy-payment-method-select"
      label={t('Common:PAYMENTS.PAYMENT_METHOD')}
      {...props}
    />
  )
}
