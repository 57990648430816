import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as R from 'ramda'
import { Defaults } from '@pbt/pbt-ui-components'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import FeatureToggle from '~/constants/featureToggle'
import {
  fetchMembersList,
  fetchMoreItemsForMembersList,
} from '~/store/actions/members'
import { fetchUsedRoles } from '~/store/actions/roles'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getMembersIsFetchingList,
  getMembersList,
  getMembersTotalCount,
} from '~/store/reducers/members'
import {
  getRolesIsLoading,
  getUsedStaffRolesList,
} from '~/store/reducers/roles'
import { getMultipleUsers } from '~/store/reducers/users'

import MembersTable from './MembersTable'
import TeamMemberDetails from './TeamMemberDetails'

interface MembersTableComponentProps {
  headerButtons: React.ReactNode
  memberId?: string
  onDetailsClose: () => void
}

const MembersTableComponent = ({
  memberId,
  headerButtons,
  onDetailsClose,
}: MembersTableComponentProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const list = useSelector(getMembersList)
  const members = useSelector(getMultipleUsers(list))
  const isFetchingList = useSelector(getMembersIsFetchingList)
  const isRolesLoading = useSelector(getRolesIsLoading)
  const totalCount = useSelector(getMembersTotalCount)
  const usedStaffRoles = useSelector(getUsedStaffRolesList)
  const isRemoteUsersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REMOTE_MEMBERS),
  )

  const businessRoleIds = R.pluck('id', usedStaffRoles)
  const initialSelectedRoleIds = useMemo(
    () =>
      R.pluck(
        'id',
        usedStaffRoles.filter((role) => !role.remote),
      ),
    [usedStaffRoles],
  )

  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    initialSelectedRoleIds,
  )

  const isLoading = isFetchingList || isRolesLoading

  useEffect(() => {
    if (isRemoteUsersEnabled) {
      dispatch(fetchUsedRoles())
    }
  }, [isRemoteUsersEnabled])

  const fetchMembers = (roles: string[]) => {
    dispatch(
      fetchMembersList({
        roleIds: isRemoteUsersEnabled ? roles : undefined,
        from: 0,
        to: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        shorten: true,
        includeInactive: true,
        includeInvitationStatus: true,
      }),
    )
  }

  const updateSelectedRoles = (roles: string[]) => {
    if (!R.equals(roles, selectedRoles)) {
      setSelectedRoles(roles)
      fetchMembers(roles)
    }
  }

  useEffect(() => {
    updateSelectedRoles(initialSelectedRoleIds)
  }, [initialSelectedRoleIds])

  const navigateToMember = (id: string) => {
    navigate(`/admin/general/members/${id}`)
  }

  const isItemLoaded = (index: number) => Boolean(members[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    if (startIndex !== 0) {
      dispatch(
        fetchMoreItemsForMembersList({
          roleIds: isRemoteUsersEnabled ? selectedRoles : undefined,
          from: startIndex,
          to: endIndex,
          shorten: true,
          includeInactive: true,
          includeInvitationStatus: true,
        }),
      )
    }
  }

  return (
    <ExpandableTable
      Expander={TeamMemberDetails}
      headerButtons={headerButtons}
      isLoading={isLoading}
      itemId={memberId}
      title={t('Common:TEAM_MEMBERS')}
      onClose={onDetailsClose}
      onSelected={navigateToMember}
    >
      <MembersTable
        filterRoleIds={businessRoleIds}
        filterSelectedRoles={selectedRoles}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        members={members}
        totalCount={totalCount}
        onFilterSelectedRolesChange={updateSelectedRoles}
      />
    </ExpandableTable>
  )
}

export default MembersTableComponent
